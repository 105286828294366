/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Wrestling1.jpeg";
import Image2 from "assets/images/Sports_Images/Wrestling2.webp";
import Image3 from "assets/images/Sports_Images/Wrestling3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Wrestling-Stipendien in den USA: Wie das Studieren und Ringen in Amerika Ihre Träume wahr werden lässt', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapitel 1: Die Geschichte und Entwicklung des Ringens in Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Frühe Jahre', isSubheader: true },
    { id: '1.2', title: '1.2 Wachstum und Entwicklung', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapitel 2: Das College-Ringen heute', isSubheader: false },
    { id: '2.1', title: '2.1 Wichtige Wettbewerbe und Konferenzen', isSubheader: true },
    { id: '2.2', title: '2.2 Top-Programme und angesehene Teams', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapitel 3: Vom College bis zum olympischen Niveau und professionellen Ligen', isSubheader: false },
    { id: '3.1', title: '3.1 Der Weg an die Spitze', isSubheader: true },
    { id: '3.2', title: '3.2 Professionelles Ringen und die Olympischen Spiele', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapitel 4: Internationale Einflüsse und Wettbewerbe', isSubheader: false },
    { id: '4.1', title: '4.1 Fremde Ringstile und Traditionen', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikaner auf der Weltbühne', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapitel 5: Sportstipendien und die Zukunft der Athleten', isSubheader: false },
    { id: '5.1', title: '5.1 Die Bedeutung von Stipendien', isSubheader: true },
    { id: '5.2', title: '5.2 Wie Sportstipendien Amerika helfen können', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhaltsverzeichnis
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verstecken</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Anzeigen</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Wrestling-Stipendien in den USA: Wie das Studieren und Ringen in Amerika Ihre Träume wahr werden lässt
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Luftaufnahme eines voll besetzten Stadions bei einem College-Ringen."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Die nationale Meisterschaft
      </figcaption>
    </figure>
    Ringen ist eine Sportart, die seit Tausenden von Jahren weltweit praktiziert wird. Es ist eine Form des physischen Kampfes, bei dem zwei Gegner versuchen, sich durch Haltegriffe, Würfe, Festhalterungen und andere Techniken zu dominieren. Ringen erfordert nicht nur körperliche Stärke, Schnelligkeit, Ausdauer und Flexibilität, sondern auch geistige Schärfe, Strategie, Disziplin und Ausdauer.
    <br />
    <br />
    In den Vereinigten Staaten ist Ringen einer der meist praktizierten und beobachteten Sportarten. Es hat eine lange und faszinierende Geschichte, die bis in die koloniale Zeit zurückreicht. Seitdem hat es sich zu einem nationalen Phänomen mit Millionen von Fans und Praktizierenden entwickelt. Das amerikanische College-Ringen repräsentiert den Höhepunkt dieser Entwicklung. Es ist das Level, auf dem sich die besten Ringer des Landes in spannenden Wettkämpfen treffen, die oft als Sprungbrett für professionelle oder olympische Karrieren dienen.
    <br />
    <br />
    Die Bedeutung des College-Ringens für die Entwicklung von Athleten darf nicht unterschätzt werden. College-Ringer erhalten nicht nur die Möglichkeit, ihre athletischen Talente zu präsentieren und zu verbessern, sondern auch an renommierten Universitäten zu studieren, wo sie wertvolle akademische Fähigkeiten und Kenntnisse erwerben können. Darüber hinaus können sie von Stipendien profitieren, die einen erheblichen Teil oder alle ihre Studiengebühren abdecken können.
    <br />
    <br />
    Dennoch ist es keine leichte Aufgabe, ein Ringerstipendium zu erhalten. Es erfordert viel Vorbereitung, Hingabe und Anleitung. Glücklicherweise müssen Sie das nicht alleine tun. Sportbeursamerika.nl ist eine Organisation, die sich darauf spezialisiert hat, jungen Ringtalenten zu helfen, ihren Traum vom Studieren und Ringen in Amerika zu verwirklichen. Ich biete Ihnen professionelle Beratung, persönliche Unterstützung und Zugang zu meinem umfangreichen Netzwerk von Kontakten in der amerikanischen Ringwelt.
    Möchten Sie mehr darüber erfahren, wie ich Ihnen helfen kann, ein Ringerstipendium in den USA zu erhalten? Lesen Sie weiter! In diesem Artikel werde ich Ihnen mehr darüber erzählen:
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>

{/* Hauptkapitel und Unterkapitel mit Platzhaltertext */}
<MKBox id="Kapitel 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Die Geschichte und Entwicklung des Ringens in Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Ringen ist eine Sportart, die seit der Antike in verschiedenen Kulturen praktiziert wird. Es ist eine der ältesten Formen des organisierten Wettbewerbs, die in der griechischen Mythologie und der Bibel erwähnt wird. Es war auch eine der ersten Sportarten, die in den antiken Olympischen Spielen enthalten war.
    <br />
    <br />
    In den Vereinigten Staaten hat das Ringen eine eigene Geschichte und Entwicklung, die eng mit dem sozialen und politischen Kontext des Landes verbunden ist. In diesem Kapitel werden wir einen Überblick über die Hauptphasen und Ereignisse in der amerikanischen Ringgeschichte geben und wie sie zur Bildung des modernen College-Ringens beigetragen haben.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Frühe Jahre
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Die Anfänge des Ringens in Amerika lassen sich bis in die Kolonialzeit zurückverfolgen, als europäische Siedler ihre eigenen Ringstile und Traditionen auf den neuen Kontinent brachten. Diese Stile basierten hauptsächlich auf dem englischen Catch-as-Catch-Can-Ringen, bei dem Haltegriffe an allen Körperteilen außer dem Kopf erlaubt waren. Es war eine beliebte Form der Unterhaltung und Herausforderung unter den Siedlern, die oft an Feiertagen oder Markttagen Wettkämpfe organisierten.
      <br />
      <br />
      Einer der frühesten bekannten Ringer in Amerika war George Washington, der als junger Mann für seine Ringfähigkeiten bekannt war. Er besiegte einmal an einem Tag während eines Wettkampfs in Virginia sieben Gegner. Ein weiterer berühmter Ringer aus dieser Zeit war Abraham Lincoln, der als Teenager in Illinois mehrere Wettkämpfe gewann.
      <br />
      <br />
      Das erste College-Ringteam wurde 1780 an der Yale University gegründet, aber es dauerte mehr als ein Jahrhundert, bis das College-Ringen wirklich Fahrt aufnahm. Der erste offizielle interkollegiale Wettkampf fand 1889 zwischen Yale und der Columbia University statt, wobei Yale 5-0 gewann. 1892 wurde die erste nationale Meisterschaft in New York City ausgetragen, an der acht Teams aus verschiedenen Bundesstaaten teilnahmen. Der Gewinner war die Cornell University, die alle sieben Wettkämpfe gewann.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Wachstum und Entwicklung
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ende des 19. und Anfang des 20. Jahrhunderts begann sich das Ringen in Amerika zu verbreiten und zu diversifizieren. Es entstanden verschiedene regionale Stile, wie zum Beispiel das Iowa-Style-Ringen, das Geschwindigkeit und Wendigkeit betonte, oder das Oklahoma-Style-Ringen, das sich auf Kraft und Kontrolle konzentrierte. Auch neue Regeln wurden eingeführt, um das Ringen sicherer und fairer zu machen, wie das Verbot gefährlicher Griffe oder die Einführung von Zeitlimits.
      <br />
      <br />
      Das College-Ringen erfreute sich ebenfalls wachsender Beliebtheit und Ansehen, mit mehr Teams, Wettbewerben und Fans. 1928 wurde die National Collegiate Athletic Association (NCAA) gegründet, um das College-Ringen zu regulieren und zu fördern. Die NCAA organisierte jährliche nationale Meisterschaften für verschiedene Divisionen, die auf der Größe und dem Niveau der Universitäten basierten. Die angesehenste Division war Division I, die ungefähr 80 Teams umfasste.
      <br />
      <br />
      Das College-Ringen brachte auch viele Stars und Legenden hervor, die sowohl nationalen als auch internationalen Erfolg erzielten. Einige Beispiele sind:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Dan Gable: Einer der größten Ringer aller Zeiten, der sowohl als Athlet als auch als Trainer dominierte. Er gewann drei NCAA-Titel für die Iowa State University, eine Goldmedaille bei den Olympischen Spielen 1972, ohne einen einzigen Punkt zu verlieren, und 15 NCAA-Titel als Trainer für die University of Iowa.
        </li>
        <li>
          John Smith: Sechsmaliger Weltmeister und zweimaliger Olympiasieger, bekannt für seine innovativen Techniken und Taktiken. Er gewann zwei NCAA-Titel für die Oklahoma State University und wurde später Cheftrainer des gleichen Teams.
        </li>
        <li>
          Cael Sanderson: Der einzige Ringer, der vier NCAA-Titel ohne eine einzige Niederlage gewann. Er hatte eine makellose Bilanz von 159-0 für die Iowa State University und gewann auch eine Goldmedaille bei den Olympischen Spielen 2004. Er ist derzeit Cheftrainer an der Penn State University, wo er acht NCAA-Titel gewonnen hat.
        </li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dies sind nur einige Beispiele für das Wachstum und die Entwicklung des Ringens in Amerika, die zur aktuellen College-Ringlandschaft geführt haben, die als eine der wettbewerbsfähigsten und aufregendsten Sportarten des Landes gilt.
    </MKTypography>
  </MKBox>
</MKBox>




                                  







<MKBox id="Kapitel 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Das College-Ringen heute
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Das College-Ringen ist heute eine der beliebtesten und angesehensten Sportarten in Amerika. Es zieht Millionen von Fans und Zuschauern an, die die spannenden Wettkämpfe im Fernsehen, im Internet oder live in den Arenen verfolgen. Das College-Ringen bietet auch zahlreiche Möglichkeiten für Studenten-Athleten, die von Stipendien, akademischer Unterstützung und professioneller Anleitung profitieren können.
    <br />
    <br />
    In diesem Kapitel werden wir Ihnen mehr über die wichtigsten Wettbewerbe und Konferenzen im College-Ringen sowie die Top-Programme und angesehenen Teams, die den Sport dominieren, erzählen.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Wichtige Wettbewerbe und Konferenzen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Zwei College-Ringer in einem intensiven Duell auf der Matte."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Zwei Ringer in Aktion
        </figcaption>
      </figure>
      Das College-Ringen wird von der NCAA organisiert, die drei Divisionen hat: Division I, Division II und Division III. Jede Division hat ihre eigenen Regeln, Kriterien und Meisterschaften. Die wettbewerbsfähigste und bekannteste Division ist Division I, die aus ungefähr 80 Teams aus verschiedenen Bundesstaaten besteht.
      <br />
      <br />
      Die Teams in Division I sind in verschiedene Konferenzen unterteilt, die regionale oder thematische Gruppen bilden. Die Konferenzen organisieren ihre eigenen Wettkämpfe und Meisterschaften, die als Qualifikationen für die nationale Meisterschaft dienen. Zu den prominentesten Konferenzen im College-Ringen gehören:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Big Ten Conference: Die größte und stärkste Konferenz im College-Ringen, bestehend aus 14 Teams aus den Regionen Midwest und Northeast des Landes. Die Big Ten hat die meisten nationalen Meister hervorgebracht, darunter Penn State, Iowa, Ohio State, Michigan und Minnesota.
        </li>
        <li>
          Big 12 Conference: Die zweitgrößte und stärkste Konferenz im College-Ringen, bestehend aus 12 Teams aus den Regionen South und West des Landes. Die Big 12 hat ebenfalls viele nationale Meister hervorgebracht, darunter Oklahoma State, Oklahoma, Iowa State und Missouri.
        </li>
        <li>
          Atlantic Coast Conference (ACC): Eine aufstrebende Konferenz im College-Ringen, bestehend aus sechs Teams aus der Region Southeast des Landes. Die ACC hat in letzter Zeit mehr Aufmerksamkeit erregt, da Teams wie Virginia Tech, North Carolina State und North Carolina gute Leistungen gezeigt haben.
        </li>
        <li>
          Eastern Intercollegiate Wrestling Association (EIWA): Eine historische Konferenz im College-Ringen, bestehend aus 16 Teams aus der Region Eastern des Landes. Die EIWA ist die älteste Ringkonferenz in Amerika, gegründet im Jahr 1905. Die EIWA hat ebenfalls einige nationale Meister hervorgebracht, darunter Cornell, Lehigh, Princeton und Navy.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dies sind nur einige der vielen Konferenzen im College-Ringen, jede mit ihren eigenen Rivalitäten, Traditionen und Fans. Konferenzen sind jedoch nicht der einzige Faktor, der darüber entscheidet, wie erfolgreich ein Team ist. Es gibt auch andere Aspekte, die eine Rolle im Erfolg eines Ringprogramms spielen.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Top-Programme und angesehene Teams
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ein Ringprogramm ist ein Begriff, der verwendet wird, um die Organisation und Struktur eines Universitäts-Ringteams zu bezeichnen. Ein Ringprogramm umfasst Aspekte wie den Trainer, das Personal, die Einrichtungen, die Rekrutierung, das Training, die Finanzierung und die Kultur des Teams.
      <br />
      <br />
      Ein gutes Ringprogramm kann einen erheblichen Unterschied in der Entwicklung von Studenten-Athleten machen. Ein gutes Ringprogramm kann Studenten-Athleten bieten:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Einen erfahrenen und qualifizierten Trainer, der ihnen beibringen kann, ihre Technik, Taktik, Kondition und Mentalität zu verbessern.
        </li>
        <li>
          Ein engagiertes und professionelles Personal, das sie in Bereichen wie Ernährung, Verletzungsprävention, Erholung, akademischer Beratung und Karriereplanung unterstützen kann.
        </li>
        <li>
          Moderne und gut ausgestattete Einrichtungen, in denen sie auf hohem Niveau mit geeigneter Ausrüstung und Materialien trainieren können.
        </li>
        <li>
          Eine effektive und ethische Rekrutierungsstrategie, die ihnen helfen kann, talentierte und motivierte Ringer anzuziehen und zu halten.
        </li>
        <li>
          Einen intensiven und abwechslungsreichen Trainingsplan, der sie herausfordern und auf verschiedene Wettkämpfe und Situationen vorbereiten kann.
        </li>
        <li>
          Ausreichende und stabile Finanzierung, die ihnen helfen kann, die Kosten für das Ringen zu decken, wie Reisen, Ausrüstung, Stipendien und andere Ausgaben.
        </li>
        <li>
          Eine positive und inspirierende Kultur, die sie motivieren und ermutigen kann, ihr Potenzial auszuschöpfen, ihre Ziele zu verfolgen und ihre Leidenschaft für den Sport zu teilen.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Es gibt viele Universitäten in Amerika, die ausgezeichnete Ringprogramme haben, die seit Jahren im College-Ringen erfolgreich sind. Einige dieser Programme sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Penn State: Das derzeit beste Team im College-Ringen, das seit 2011 achtmal die nationale Meisterschaft gewonnen hat. Penn State ist bekannt für seinen aggressiven und spektakulären Ringstil, der von Trainer Cael Sanderson, einem der größten Ringer aller Zeiten, geleitet wird.
        </li>
        <li>
          Iowa: Das erfolgreichste Team in der Geschichte des College-Ringen, das seit 1975 über 20 Mal die nationale Meisterschaft gewonnen hat. Iowa ist bekannt für seinen aggressiven und dominanten Ringstil, der von Trainer Tom Brands, einem ehemaligen Olympiasieger, geleitet wird.
        </li>
        <li>
          Oklahoma State: Das zweiterfolgreichste Team in der Geschichte des College-Ringen, das über 20 Mal die nationale Meisterschaft gewonnen hat. Oklahoma State ist bekannt für seinen technischen und taktischen Ringstil, der von Trainer John Smith, einem der größten Ringer aller Zeiten, geleitet wird.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dies sind nur einige der vielen Top-Programme und angesehenen Teams im College-Ringen, jede mit ihren eigenen Merkmalen, Erfolgen und Fans. Es ist jedoch nicht einfach, Teil dieser Programme und Teams zu sein. Es erfordert viel Talent, harte Arbeit und Engagement, um ein Ringstipendium zu erhalten.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  







<MKBox id="Kapitel 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Vom College bis zum olympischen Niveau und professionellen Ligen
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Das College-Ringen ist für viele Studenten-Athleten, die von einer Karriere im Sport träumen, nicht das Endziel. Es ist vielmehr ein Sprungbrett zu höheren Ebenen, auf denen sie mit den besten Ringern der Welt konkurrieren können. In diesem Kapitel werden wir Ihnen mehr über den Weg an die Spitze im professionellen oder olympischen Ringen und die Erfolge und Herausforderungen amerikanischer Ringer auf der Weltbühne erzählen.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Der Weg an die Spitze
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Das College-Ringen ist eine der wettbewerbsfähigsten und anspruchsvollsten Sportarten in Amerika. Es erfordert viel Talent, harte Arbeit und Hingabe, um ein Ringstipendium zu erhalten, und noch mehr, um in nationalen Wettbewerben zu glänzen. Aber selbst wenn Sie zu den besten College-Ringern gehören, ist das keine Garantie für Erfolg auf professionellem oder olympischem Niveau.
      <br />
      <br />
      Es gibt mehrere Faktoren, die den Übergang vom College-Ringen zum professionellen oder olympischen Ringen erschweren. Einige dieser Faktoren sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Unterschiede im Stil: Das College-Ringen wird nach Folkstyle-Regeln praktiziert, die einzigartig für Amerika sind. Diese Regeln konzentrieren sich darauf, den Gegner zu kontrollieren und zu dominieren, wobei Punkte fürs Reiten, Entkommen, Umkehren und nahe Stürze vergeben werden. Professionelles oder olympisches Ringen hingegen wird nach Freistil- oder griechisch-römischen Regeln praktiziert, die international verwendet werden. Diese Regeln betonen das Punkten durch Würfe, Neigungen, Expositionen und Pins. Das bedeutet, dass College-Ringer ihre Technik, Taktik und Strategie anpassen müssen, um mit Ringern aus anderen Ländern zu konkurrieren, die mehr Erfahrung mit diesen Stilen haben.
        </li>
        <li>
          Altersunterschiede: Das College-Ringen ist ein Sport für junge Athleten, normalerweise zwischen 18 und 23 Jahren. Professionelles oder olympisches Ringen ist jedoch ein Sport für erwachsene Athleten, normalerweise zwischen 25 und 35 Jahren. Das bedeutet, dass College-Ringer mit älteren und erfahreneren Ringern konkurrieren müssen, die über eine größere physische Stärke, geistige Reife und technische Raffinesse verfügen.
        </li>
        <li>
          Finanzielle Unterschiede: Das College-Ringen ist ein Sport, der von Universitäten unterstützt wird, die Studenten-Athleten Stipendien, Einrichtungen, Training und andere Vorteile bieten. Professionelles oder olympisches Ringen hingegen erhält wenig finanzielle Unterstützung von der Regierung oder Sponsoren. Das bedeutet, dass College-Ringer andere Einnahmequellen suchen müssen, um ihr Training, Reisen, Ausrüstung und Lebenshaltungskosten zu decken.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dies sind nur einige der vielen Herausforderungen, denen College-Ringer gegenüberstehen müssen, um ihren Traum von einer professionellen oder olympischen Karriere zu verfolgen. Trotz dieser Herausforderungen gibt es jedoch auch viele Erfolge und inspirierende Geschichten von amerikanischen Ringern, die es an die Spitze geschafft haben.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professionelles Ringen und die Olympischen Spiele
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Professionelles Ringen oder olympisches Ringen ist die Spitze des Sports, wo die besten Ringer der Welt in prestigeträchtigen Wettbewerben und Veranstaltungen antreten. Es gibt zwei Hauptformen des professionellen oder olympischen Ringens: Freistil und griechisch-römisch.
      <br />
      <br />
      Freistilringen ist eine Form des Ringens, bei der alle Arten von Griffen am gesamten Körper erlaubt sind, solange sie nicht gefährlich oder unangemessen sind. Freistilringen wird sowohl von Männern als auch von Frauen in verschiedenen Gewichtsklassen praktiziert. Freistilringen ist eine der ältesten Sportarten bei den Olympischen Spielen, die seit 1904 für Männer und seit 2004 für Frauen ausgetragen wird.
      <br />
      <br />
      Das griechisch-römische Ringen ist eine Form des Ringens, bei der nur Griffe über der Taille erlaubt sind und die Verwendung der Beine, um den Gegner zu greifen oder zu werfen, verboten ist. Das griechisch-römische Ringen wird nur von Männern in verschiedenen Gewichtsklassen praktiziert. Das griechisch-römische Ringen ist die älteste Form des Ringens bei den Olympischen Spielen, das seit 1896 ausgetragen wird.
      <br />
      <br />
      Amerikanische Ringer haben viele Erfolge sowohl im Freistil- als auch im griechisch-römischen Ringen erzielt, bei den Olympischen Spielen sowie bei anderen internationalen Wettbewerben wie den Weltmeisterschaften, den Panamerikanischen Spielen oder dem Weltcup. Einige der erfolgreichsten und bekanntesten amerikanischen Ringer sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Jordan Burroughs: Ein fünffacher Weltmeister und Olympiasieger im Freistilringen, bekannt für seinen explosiven und dynamischen Stil. Er gewann eine Goldmedaille bei den Olympischen Spielen 2012 in London und vier Weltmeistertitel in den Jahren 2011, 2013, 2015 und 2017. Er gewann auch zwei NCAA-Titel für die University of Nebraska.
        </li>
        <li>
          Kyle Snyder: Ein dreifacher Weltmeister und Olympiasieger im Freistilringen, bekannt für seine Stärke und Technik. Er gewann eine Goldmedaille bei den Olympischen Spielen 2016 in Rio de Janeiro und drei Weltmeistertitel in den Jahren 2015, 2017 und 2019. Er gewann auch drei NCAA-Titel für die Ohio State University.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dies sind nur einige der vielen Erfolge und inspirierenden Geschichten amerikanischer Ringer auf professionellem oder olympischem Niveau. Sie zeigen, dass es möglich ist, seinen Traum zu verwirklichen, wenn man bereit ist, hart zu arbeiten, zu lernen und zu wachsen.
    </MKTypography>
  </MKBox>
</MKBox>
                                






                                  






<MKBox id="Kapitel 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationale Einflüsse und Wettbewerbe
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Ringen ist ein universeller Sport, der in vielen Ländern und Kulturen praktiziert und geschätzt wird. Ringen hat auch viele Stile und Traditionen, die in verschiedenen Regionen und Epochen entstanden und entwickelt wurden. In diesem Kapitel werden wir Ihnen mehr über die internationalen Einflüsse und Wettbewerbe im Ringen erzählen und wie sie amerikanische Ringer beeinflussen und herausfordern.
  </MKTypography>
  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Fremde Ringstile und Traditionen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Luftaufnahme eines NCAA-Ringerkampfes in einem vollbesetzten Stadion."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Ein Kampf im NCAA-Turnier
        </figcaption>
      </figure>
      Ringen ist eine Sportart, die seit der Antike in verschiedenen Teilen der Welt praktiziert wird. Es ist eine der ältesten Formen körperlichen Ausdrucks, oft verbunden mit religiösen, kulturellen oder sozialen Bedeutungen. Es gibt viele Arten von Ringen, jede mit ihren eigenen Merkmalen, Regeln und Techniken. Einige Beispiele sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Sumo: Ein traditioneller japanischer Ringstil, der als Nationalsport und heiliges Ritual gilt. Sumo wird von schweren und starken Ringern praktiziert, die versuchen, sich gegenseitig aus einem kreisförmigen Ring zu drängen oder zu Boden zu werfen.
        </li>
        <li>
          Mongolisches Ringen: Ein traditioneller mongolischer Ringstil, der als Symbol für Mut und Stärke gilt. Mongolisches Ringen wird von muskulösen und agilen Ringern praktiziert, die versuchen, sich gegenseitig durch Griffe am Oberkörper oder an den Beinen zu Boden zu bringen.
        </li>
        <li>
          Türkisches Ölringen: Ein traditioneller türkischer Ringstil, der als Erbe und Kunst gilt. Türkisches Ölringen wird von geölten und muskulösen Ringern praktiziert, die versuchen, sich gegenseitig durch Griffe an der Hose oder am Gürtel zu besiegen.
        </li>
        <li>
          Senegalesisches Ringen: Ein traditioneller senegalesischer Ringstil, der als beliebter und lukrativer Sport gilt. Senegalesisches Ringen wird von athletischen und farbenfrohen Ringern praktiziert, die versuchen, sich gegenseitig durch Würfe, Schläge oder Tritte zu Boden zu bringen.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dies sind nur einige der vielen fremden Ringstile und Traditionen, jede mit ihrer eigenen Geschichte, Kultur und Fans. Diese Stile haben auch das amerikanische College-Ringen beeinflusst, indem sie neue Techniken, Taktiken oder Herausforderungen eingeführt haben. Viele amerikanische College-Ringer haben in anderen Ländern gereist oder trainiert, um ihren Horizont zu erweitern und ihre Fähigkeiten zu verbessern.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikaner auf der Weltbühne
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Das amerikanische College-Ringen konzentriert sich nicht nur auf nationale Wettbewerbe, sondern auch auf internationale Wettbewerbe. Es gibt viele internationale Veranstaltungen, an denen amerikanische College-Ringer teilnehmen können, um mit den besten Ringern der Welt zu konkurrieren. Einige dieser Veranstaltungen sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Die Olympischen Spiele: Die größte und prestigeträchtigste Veranstaltung im professionellen oder olympischen Ringen, die alle vier Jahre stattfindet. Die Olympischen Spiele sind das ultimative Ziel vieler College-Ringer, die davon träumen, eine Goldmedaille für ihr Land zu gewinnen.
        </li>
        <li>
          Die Weltmeisterschaften: Die zweitgrößte und prestigeträchtigste Veranstaltung im professionellen oder olympischen Ringen, die jährlich stattfindet. Die Weltmeisterschaften sind eine bedeutende Gelegenheit für College-Ringer, sich für die Olympischen Spiele zu qualifizieren oder ihren Ruf auf der Weltbühne zu festigen.
        </li>
        <li>
          Der Weltcup: Die zweitgrößte und prestigeträchtigste Veranstaltung im regionalen Ringen, die jährlich stattfindet. Der Weltcup ist eine bedeutende Gelegenheit für College-Ringer, Teil einer Nationalmannschaft zu sein, die gegen andere Nationalmannschaften im Teamformat antritt.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dies sind nur einige der vielen internationalen Veranstaltungen, an denen amerikanische College-Ringer teilnehmen können, um ihre Talente zu präsentieren und ihre Träume zu verfolgen. Diese Veranstaltungen sind jedoch nicht nur eine Quelle der Ehre und des Ruhmes, sondern auch eine Quelle der Herausforderung und Schwierigkeit. College-Ringer müssen gegen Ringer aus anderen Ländern antreten, die oft mehr Erfahrung, Stil oder Motivation haben.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  



                                
<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Sportstipendien und die Zukunft der Athleten
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sportstipendien bieten zahlreiche Möglichkeiten für junge Athleten, ihr Talent zu zeigen und ihre Fähigkeiten zu entwickeln. Eine der bedeutendsten Möglichkeiten ist der Erhalt eines Sportstipendiums, um auf Hochschulebene zu studieren und zu konkurrieren. In diesem Kapitel werden wir die Bedeutung von Sportstipendien diskutieren und wie Sportstipendien Amerika Ihnen helfen kann, Ihren Traum zu verwirklichen.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Die Bedeutung von Stipendien
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ein Sportstipendium ist finanzielle Unterstützung, die von einer Universität einem Studenten-Athleten zur Verfügung gestellt wird, um Studium und Sport zu kombinieren. Es kann die Kosten für Studiengebühren, Bücher, Unterkunft, Mahlzeiten und andere Ausgaben abdecken. Darüber hinaus kann es auch Vorteile wie akademische Nachhilfe, medizinische Versorgung, Trainingsmöglichkeiten und Ausrüstung bieten.
      <br />
      <br />
      Die Erlangung eines Sportstipendiums ist wettbewerbsfähig, wobei viele Faktoren eine Rolle im Zuteilungsprozess spielen, einschließlich akademischer Leistungen, sportlicher Leistung, Persönlichkeit, Motivation, Führung und Anpassungsfähigkeit. Daher ist es wichtig, sich gut vorzubereiten und sich von der Konkurrenz abzuheben.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Wie Sportstipendien Amerika helfen können
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo von Sportstipendien Amerika, das Sportausrüstung und eine Abschlusskappe zeigt, die den Fokus auf Sportstipendien für Studenten-Athleten repräsentiert."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo von Sportstipendien Amerika
        </figcaption>
      </figure>
      Sportstipendien Amerika ist eine Organisation, die sich auf die Anleitung junger Sporttalente zu Sportstipendien spezialisiert hat. Mit leidenschaftlichen und erfahrenen Experten unterstützt Sportstipendien Amerika Studenten-Athleten auf jedem Schritt der Reise, von der ersten Orientierung bis zur endgültigen Platzierung. Ihre Dienstleistungen umfassen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Ein persönliches Aufnahmegespräch, um die Wünsche, Möglichkeiten und Erwartungen des Studenten-Athleten zu besprechen.</li>
        <li>Eine professionelle Bewertung des sportlichen und akademischen Niveaus des Studenten-Athleten.</li>
        <li>Ein umfangreiches Netzwerk von Kontakten zu Trainern, Universitäten und Hochschulen.</li>
        <li>Effektive Werbung für das Profil und die Videos des Studenten-Athleten bei potenziellen Trainern.</li>
        <li>Expertenberatung zu den besten Optionen basierend auf den Vorlieben, Zielen und dem Budget des Studenten-Athleten.</li>
        <li>Verhandlung der besten Stipendienangebote im Namen des Studenten-Athleten.</li>
        <li>Hilfe bei der Beantragung der erforderlichen Dokumente wie Visum, Zeugnis, Diplom und Zulassungstests.</li>
        <li>Vorbereitung auf das Leben des Studenten-Athleten während seines Stipendiums, einschließlich Kultur, Sprache, Regeln und Tipps.</li>
        <li>Nachsorge und Follow-up während der Stipendiumszeit.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Möchten Sie Stipendienmöglichkeiten erkunden und Ihre sportlichen und akademischen Ziele erreichen? <Link to="/de/Dienstleistungen-Produkte">Sehen Sie sich die vollständige Palette</Link> der von Sportstipendien Amerika angebotenen Dienstleistungen an und vereinbaren Sie noch heute eine Beratung.
    </MKTypography>
  </MKBox>
</MKBox>



         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;