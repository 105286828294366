import React, { useState, useRef, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import BlogCard from './BlogCard';
import Covid from 'assets/images/CompanyHistoryIcons/Corona.png';
import Company from 'assets/images/CompanyHistoryIcons/Oprichting.png';
import Partnership from 'assets/images/CompanyHistoryIcons/Partnership.png';
import Hundred from 'assets/images/CompanyHistoryIcons/HondertstePlaatsing.png';
import One from 'assets/images/CompanyHistoryIcons/EerstePlaatsing.png';
import Fifty from 'assets/images/CompanyHistoryIcons/VijftigstePlaatsing.png';
import Sportbeurs from 'assets/images/Logos/SmallSB.png';







// Import other icons as needed

const TimelineLine = ({ isThick, color }) => (
  <Box
    sx={{
      height: isThick ? '4px' : '2px', // Ajuster l'épaisseur de la ligne en fonction de vos besoins
      bgcolor: color ? color : 'grey.400', // Utiliser la couleur fournie si disponible, sinon gris
      width: '130px',
      alignSelf: 'center',
      flexShrink: 0,
    }}
  />
);

const HorizontalTimeline = () => {
  const timelinePoints = [
    {
      id: 1,
      label: 'Janv 2020',
      title: 'Fondation',
      content: "Lancement officiel de mon entreprise après une planification minutieuse et la création d'un plan d'affaires détaillé",
      image: Company,
    },
    {
      id: 2,
      label: 'Fév 2020',
      title: 'Partenariat',
      content: (
        <>
          Collaboration stratégique avec{' '}
          <Typography
            component="a"
            href="https://www.instagram.com/prospect2player/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: 'inherit', fontSize: 'inherit', fontWeight: 'bold' }}
          >
            Prospect2Player
          </Typography>
          , des experts dans l'orientation des joueurs de football aux États-Unis, pour améliorer mes services.
        </>
      ),
      image: Partnership,
    },
    {
      id: 3,
      label: 'Fév 2020',
      title: 'Début de la Covid-19',
      content: "J'ai changé de focus des étudiants internationaux vers les étudiants américains en réponse à la réduction de la disponibilité des bourses sportives due à la Covid-19.",
      image: Covid,
    },
    {
      id: 4,
      label: 'Avr 2021',
      title: 'Première Placement',
      content: "Atteinte d'une étape importante avec mon premier étudiant que j'ai guidé avec succès vers une bourse sportive.",
      image: One,
    },
    {
      id: 5,
      label: 'Juil 2021',
      title: '50e Placement',
      content: "Célébration du succès continu avec le 50e étudiant que j'ai guidé avec succès vers une bourse sportive.",
      image: Fifty,
    },
    {
      id: 6,
      label: 'Avr 2022',
      title: '100e Placement',
      content: "J'ai doublé mon succès avec mon 100e placement, démontrant ma compétence et mon impact croissants.",
      image: Hundred,
    },
    {
      id: 7,
      label: 'Oct 2023',
      title: 'Sport Scholarships America',
      content: "J'ai évolué vers un focus spécifique sur les étudiants internationaux, avec une marque renouvelée et des services améliorés.",
      image: Sportbeurs,
    },
  ];

  // Le reste du code pour votre composant

  // Rest of the component...


  const [activeIndex, setActiveIndex] = useState(0);
  const timelineRef = useRef(null);

  const prevPoint = () => {
    if (activeIndex > 0) {
      scrollToCenter(activeIndex - 1);
    }
  };

  const nextPoint = () => {
    if (activeIndex < timelinePoints.length - 1) {
      scrollToCenter(activeIndex + 1);
    }
  };

  const centerTimelinePoint = (index) => {
    if (timelineRef.current) {
      const timelineWidth = timelineRef.current.offsetWidth;
      const targetElement = timelineRef.current.children[index * 2];
      const targetElementOffset = targetElement.offsetLeft;
      const targetElementWidth = targetElement.offsetWidth;

      const offset = targetElementOffset + targetElementWidth / 2 - timelineWidth / 2;
      const newTransform = `translateX(${-offset}px)`;

      if (timelineRef.current.style.transform !== newTransform) {
        timelineRef.current.style.transform = newTransform;
      }
    }
  };

  const debounce = (fn, ms) => {
    let timer;
    return function() {
      clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        fn.apply(this, arguments);
      }, ms);
    };
  };

  useEffect(() => {
    centerTimelinePoint(activeIndex); // Center the point at the active index when the component mounts
  
    const handleResize = debounce(() => centerTimelinePoint(activeIndex), 250);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [activeIndex]); // Make sure to include activeIndex in the dependency array

  const scrollToCenter = (index) => {
    setActiveIndex(index);
    centerTimelinePoint(index);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          overflow: 'visible',
          position: 'relative',
          width: '100%',
          minHeight: '350px',
          paddingTop: '20px',
        }}
      >
       {timelinePoints.map((point, index) => {
          const translateValue = (index - activeIndex) * 30; // Adjusted for spacing
          const zIndexValue = timelinePoints.length - Math.abs(index - activeIndex);
          const scaleValue = 1 - 0.1 * Math.abs(index - activeIndex);
          const blurValue = Math.abs(index - activeIndex) * 2;

          return (
            <Box
              key={point.id}
              sx={{
                position: 'absolute',
                left: '50%',
                transform: `translateX(calc(${translateValue}% - 160px)) scale(${scaleValue})`,
                transition: 'transform 1s ease-in-out, opacity 1s ease-in-out, filter 1s ease-in-out',
                opacity: 1 - 0.1 * Math.abs(index - activeIndex),
                zIndex: zIndexValue,
                filter: `blur(${blurValue}px)`,
                height: '300px', // Fixed height for each card
                
                display: 'flex',
                alignItems: 'center',
                 // Centers the BlogCard vertically within the Box
              }}
            >
              <BlogCard
                title={point.title}
                description={point.content}
                image={point.image} 
                isCircular={point.image === Sportbeurs} // Check if the image is Sportbeurs              
                additionalContent={point.additionalContent}
              />
            </Box>
          );
        })}
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          p: 2,
          position: 'relative',
          overflow: 'visible',
          transition: 'transform 0.5s ease-in-out',
        }}
        ref={timelineRef}
      >
        {timelinePoints.map((point, index) => (
          <React.Fragment key={point.id}>
             {index > 0 && (
              <TimelineLine isThick={index <= activeIndex} 
              color={index <= activeIndex ? 'primary.main' : undefined} // Change color for thick lines
              />
            )}
            <Button
              onClick={() => scrollToCenter(index)}
              sx={{
                mx: 1,
                p: 1,
                color: index === activeIndex ? 'primary.main' : 'text.primary',
                display: 'flex',
                minWidth: '100px',
                textAlign: 'center',
                borderRadius: 1,
                border: 0,
                transform: index === activeIndex ? 'scale(1.2)' : 'none',
                transition: 'transform 0.3s',
                flexShrink: 0,
              }}
            >
              {point.label}
            </Button>
          </React.Fragment>
        ))}
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '20px',
        }}
      >
        <Button onClick={prevPoint} disabled={activeIndex === 0} sx={{ marginRight: '0px' }}>
          <ArrowBackIosIcon />
        </Button>
        <Button onClick={nextPoint} disabled={activeIndex === timelinePoints.length - 1} sx={{ marginLeft: '0px' }}>
          <ArrowForwardIosIcon />
        </Button>
      </Box>
    </>
  );
};

export default HorizontalTimeline;
