/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import Image1 from "assets/images/Sports_Images/Tennis1.jpg";
import Image2 from "assets/images/Sports_Images/Tennis2.jpeg";
import Image3 from "assets/images/Sports_Images/Tennis3.jpg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Bourses de Tennis aux États-Unis : Comment Étudier et Jouer au Tennis en Amérique', isSubheader: false },
  
    { id: 'Chapitre 1', title: 'Chapitre 1 : Histoire et Développement du Tennis en Amérique', isSubheader: false },
    { id: '1.1', title: '1.1 Premières Années et l\'Influence des Universités', isSubheader: true },
    { id: '1.2', title: '1.2 Croissance et Professionnalisation', isSubheader: true },
  
    { id: 'Chapitre 2', title: 'Chapitre 2 : Le Tennis Universitaire Aujourd\'hui', isSubheader: false },
    { id: '2.1', title: '2.1 Principales Compétitions et Conférences', isSubheader: true },
    { id: '2.2', title: '2.2 Programmes de Premier Plan et Équipes Prestigieuses', isSubheader: true },
  
    { id: 'Chapitre 3', title: 'Chapitre 3 : Du Collège aux Pros', isSubheader: false },
    { id: '3.1', title: '3.1 Le Chemin vers le Professionnalisme', isSubheader: true },
    { id: '3.2', title: '3.2 Circuits de Tennis Professionnels', isSubheader: true },
  
    { id: 'Chapitre 4', title: 'Chapitre 4 : Influences Internationales', isSubheader: false },
    { id: '4.1', title: '4.1 Joueurs Étrangers dans la Scène Universitaire', isSubheader: true },
    { id: '4.2', title: '4.2 Américains sur la Scène Internationale', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Chapitre 5 : Bourses Sportives et l\'Avenir des Athlètes', isSubheader: false },
    { id: '5.1', title: '5.1 L\'Importance des Bourses', isSubheader: true },
    { id: '5.2', title: '5.2 Comment Sport Scholarships America Peut Aider', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Sommaire
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Cacher</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Afficher</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Bourses de Tennis aux États-Unis : Comment Étudier et Jouer au Tennis en Amérique
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Complexe de tennis de Géorgie avec des tribunes pleines lors d'un match universitaire."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Tribunes pleines au complexe de tennis de Géorgie.
      </figcaption>
    </figure>
    Le tennis est un sport qui se pratique aux États-Unis depuis plus d'un siècle. Il a commencé comme une activité récréative pour l'élite mais est rapidement devenu un sport compétitif et professionnel qui a attiré des millions de fans et de joueurs dans le monde entier. Un des facteurs clés contribuant à la croissance et au développement du tennis en Amérique est le système universitaire. Les universités offrent non seulement une éducation de haute qualité, mais aussi d'excellentes installations, des entraîneurs, des conseils et des bourses pour les joueurs de tennis talentueux. Jouer au tennis au niveau universitaire permet aux joueurs d'améliorer leurs compétences, d'élargir leur réseau, d'augmenter leurs chances d'une carrière professionnelle et d'obtenir simultanément un diplôme précieux.
    <br />
    <br />
    Mais comment un jeune joueur de tennis des Pays-Bas ou de Belgique peut-il aller en Amérique pour étudier et jouer au tennis ? La réponse est avec une bourse de tennis. Une bourse de tennis est un soutien financier accordé par une université à un joueur qui répond à certains critères académiques et sportifs. Avec une bourse de tennis, vous pouvez couvrir ou réduire vos frais de scolarité, vos livres, votre logement, vos repas et autres dépenses. Une bourse de tennis peut aller d'une bourse complète, couvrant tous les coûts, à une bourse partielle, couvrant un pourcentage des coûts.
    <br />
    <br />
    Mais comment obtenir une bourse de tennis ? C'est là que Sportbeursamerika.nl peut vous aider. Sportbeursamerika.nl est une plateforme qui aide les jeunes joueurs de tennis à réaliser leur rêve d'aller en Amérique. Sportbeursamerika.nl propose des conseils, un accompagnement, une médiation et un soutien pour trouver la meilleure université et la meilleure bourse pour vous. Sportbeursamerika.nl dispose d'une équipe d'experts qui comprennent comment fonctionne le système universitaire américain et qui ont des contacts avec des centaines d'entraîneurs et d'universités. Sportbeursamerika.nl a déjà aidé de nombreux joueurs à atteindre leur potentiel et à changer leur vie.
    <br />
    <br />
    Vous voulez en savoir plus sur le tennis en Amérique et sur la manière dont Sportbeursamerika.nl peut vous aider ? Continuez à lire !
  </MKTypography>
</MKBox>

<MKBox id="Chapitre 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 1 : Histoire et Développement du Tennis en Amérique
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le tennis est un sport qui a vu le jour en Europe mais s'est rapidement répandu dans d'autres parties du monde, en particulier aux États-Unis. Dans ce chapitre, nous explorerons comment le tennis s'est développé aux États-Unis et comment les universités ont joué un rôle significatif dans l'évolution et la professionnalisation du sport.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Premières Années et l'Influence des Universités
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Le tennis a été introduit aux États-Unis au début du XIXe siècle par des Américains fortunés qui avaient appris le jeu lors de leurs voyages en Europe. Il est rapidement devenu une activité récréative populaire pour l'élite, qui construisait des terrains de tennis sur leurs domaines et dans des clubs. Le premier match de tennis officiel aux États-Unis a été joué en 1874 entre deux frères anglais, James Dwight et Fred Renshaw, tous deux étudiants à l'Université Harvard. Ils ont joué sur un court de tennis qu'ils avaient construit sur le domaine de leur oncle à Nahant, dans le Massachusetts. Les premiers championnats nationaux, alors connus sous le nom de Championnat des Hommes en Simple de l'Association Nationale de Tennis sur Gazon des États-Unis, ont eu lieu en 1881 à Newport, dans le Rhode Island.
      <br />
      <br />
      Les universités ont été parmi les premières institutions à embrasser le tennis comme un sport compétitif. Elles ont organisé des tournois intercollégiaux et formé des équipes pour se concurrencer. Les premiers championnats intercollégiaux de tennis ont eu lieu en 1883, avec Harvard, Yale, Princeton et Columbia, et en 1885, le premier championnat intercollégial officiel de tennis a été reconnu. Ces universités sont rapidement devenues connues sous le nom de "Big Four" du tennis collégial américain. Elles ont dominé le sport jusqu'au début du XXe siècle, lorsque d'autres universités comme Stanford, California, Chicago et Cornell les ont rejointes.
      <br />
      <br />
      Les universités ont également joué un rôle significatif dans la promotion et la diffusion du tennis parmi différents groupes de personnes. Elles ont offert des opportunités aux femmes, aux Afro-Américains, aux immigrants et à d'autres minorités pour pratiquer et exceller dans le sport. L'un des exemples les plus remarquables est Althea Gibson, qui en 1950 est devenue la première femme afro-américaine à être admise au championnat national. Elle a remporté son premier titre du Grand Chelem aux Championnats de France en 1956 après avoir obtenu son diplôme de l'Université Florida A&M et rejoint l'Association Américaine de Tennis, une organisation pour les joueurs de tennis noirs. Elle a ensuite remporté Wimbledon deux fois et l'US Open deux fois.
    </MKTypography>
  </MKBox>

  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Croissance et Professionnalisation
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La seconde moitié du XXe siècle a été le témoin d'une croissance significative et d'une professionnalisation du tennis en Amérique. Cela a été rendu possible, en partie, par l'essor de la télévision, qui a donné plus de visibilité et de popularité au sport. De plus, de nouvelles technologies et de nouveaux matériaux ont été introduits, rendant le jeu plus rapide et plus excitant. En outre, de nouvelles organisations et circuits ont été créés pour réguler et promouvoir le tennis, notamment la Fédération Internationale de Tennis (FIT), l'Association des Professionnels du Tennis (ATP) et l'Association des Femmes du Tennis (WTA).
      <br />
      <br />
      Ces changements ont également affecté le tennis universitaire, qui a dû s'adapter à la nouvelle réalité du tennis professionnel. Les universités ont dû rivaliser avec d'autres options pour attirer et conserver des joueurs talentueux. Elles ont également dû améliorer leurs programmes pour préparer les joueurs à une éventuelle carrière professionnelle. Une des étapes clés que les universités ont franchies a été la création de la National Collegiate Athletic Association (NCAA), une organisation globale responsable de l'organisation et de la régulation des sports universitaires aux États-Unis. La NCAA divise les universités en trois divisions en fonction de leur taille, de leur budget et de leur niveau de compétition. La NCAA organise des championnats nationaux annuels pour chaque division, tant pour les équipes que pour les individus.
      <br />
      <br />
      Une autre conséquence de la professionnalisation du tennis a été que de nombreux joueurs américains se sont illustrés au plus haut niveau du sport. Certains d'entre eux avaient un parcours universitaire, prouvant que le tennis universitaire peut fournir une base solide pour une carrière professionnelle réussie. Certains des joueurs de tennis américains les plus connus qui ont joué au niveau universitaire incluent :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Arthur Ashe, qui a étudié à l'UCLA et a été le premier homme afro-américain à remporter Wimbledon, l'US Open et l'Open d'Australie.
        </li>
        <li>
          John McEnroe, qui a fréquenté Stanford et a remporté sept titres du Grand Chelem en simple et neuf en double.
        </li>
        <li>
          Jimmy Connors, qui a étudié à l'UCLA et a remporté huit titres du Grand Chelem en simple et deux en double.
        </li>
        <li>
          Billie Jean King, qui a fréquenté la California State University, Los Angeles, et a remporté 12 titres du Grand Chelem en simple et 16 en double. Elle a également été une pionnière dans la lutte pour l'égalité des sexes dans le sport.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>



                                              





                                  







<MKBox id="Chapitre 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 2 : Le Tennis Universitaire Aujourd'hui
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le tennis reste l'un des sports les plus populaires et prestigieux du système universitaire américain. Chaque année, des milliers de joueurs participent à diverses compétitions et conférences, montrant le meilleur de ce sport. Dans ce chapitre, nous explorerons les principales compétitions et conférences du tennis universitaire, ainsi que les meilleurs programmes et équipes prestigieuses qui dominent ce sport.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Principales Compétitions et Conférences
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Cameron Norrie en action pour TCU en tennis universitaire."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Cameron Norrie en action en tant que joueur de TCU
        </figcaption>
      </figure>
      Comme nous l'avons vu dans le chapitre précédent, la NCAA est l'organisation principale responsable de l'organisation et de la régulation des sports universitaires aux États-Unis. La NCAA divise les universités en trois divisions : Division I, Division II et Division III. Chaque division a ses propres règles, exigences et championnats. Pour le tennis, la NCAA organise deux compétitions majeures : le championnat par équipe et le championnat individuel.
      <br />
      <br />
      Le championnat par équipe est un tournoi à élimination directe joué entre les meilleures équipes de chaque division. Les équipes sont sélectionnées en fonction de leur performance pendant la saison, de leur classement et de leurs résultats régionaux. Le championnat par équipe se compose de deux phases : la phase régionale et la phase nationale. La phase régionale se joue entre les équipes de la même région, qui sont divisées en quatre zones géographiques : Nord-Est, Sud-Est, Midwest et Ouest. Les vainqueurs de chaque région passent à la phase nationale, qui se joue entre 16 équipes de chaque division. Le championnat par équipe suit le format de la Coupe Davis, ce qui signifie que chaque match se compose de trois matchs en double et six matchs en simple.
      <br />
      <br />
      Le championnat individuel est un tournoi à élimination directe joué entre les meilleurs joueurs de chaque division. Les joueurs sont sélectionnés en fonction de leur classement, de leur performance dans le championnat par équipe et de leurs résultats dans d'autres tournois. Le championnat individuel se compose de deux catégories : simple et double. Chaque catégorie compte 64 joueurs en Division I, 48 joueurs en Division II et 32 joueurs en Division III.
      <br />
      <br />
      En plus des championnats de la NCAA, il existe également d'autres compétitions jouées entre des universités appartenant à la même conférence. Une conférence est un groupe d'universités qui collaborent dans les domaines académique, sportif et administratif. Il existe plus de 30 conférences aux États-Unis, chacune avec ses propres règles, calendriers et championnats. Certaines des conférences les plus connues incluent :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          La Conférence de la Côte Atlantique (ACC), composée de 15 universités de la côte Est, telles que Duke, North Carolina, Virginia et Florida State.
        </li>
        <li>
          La Conférence Big Ten, composée de 14 universités du Midwest, telles que Michigan, Ohio State, Illinois et Wisconsin.
        </li>
        <li>
          La Conférence Pac-12 (Pac-12), composée de 12 universités de la côte Ouest, telles que Stanford, California, UCLA et USC.
        </li>
        <li>
          La Conférence du Sud-Est (SEC), composée de 14 universités du Sud-Est, telles que Georgia, Florida, Kentucky et Vanderbilt.
        </li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ces conférences sont importantes non seulement pour la rivalité sportive entre les universités, mais aussi pour leur réputation académique et leur prestige. De plus, elles offrent plus d'opportunités aux joueurs pour exceller et se qualifier pour les championnats nationaux.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programmes de Premier Plan et Équipes Prestigieuses
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Le niveau de compétition dans le tennis universitaire est extrêmement élevé, ce qui signifie que seuls les meilleurs joueurs et équipes peuvent prospérer et réussir. Cependant, certaines universités ont construit une longue tradition et une forte réputation dans le tennis, menant à des programmes de premier plan qui obtiennent d'excellents résultats année après année. Ces universités disposent non seulement des meilleures installations, entraîneurs et bourses, mais excellent également dans le recrutement, la formation et l'encadrement de leurs joueurs. Certains des programmes universitaires de tennis les plus réussis et prestigieux incluent :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          L'Université de Stanford, qui a remporté 21 titres de championnat par équipe de la NCAA en tennis féminin et 17 en tennis masculin, plus que toute autre université. Stanford a également remporté 25 titres individuels de la NCAA en tennis féminin et 14 en tennis masculin. Certains des joueurs les plus connus qui ont joué pour Stanford incluent John McEnroe, Bob et Mike Bryan, Nicole Gibbs et Mallory Burdette.
        </li>
        <li>
          USC (University of Southern California), qui a remporté presque autant de titres de championnat par équipe de la NCAA que Stanford en tennis masculin et quelques-uns en tennis féminin. USC a également remporté plus de titres individuels de la NCAA que toute autre université en tennis masculin et quelques-uns en tennis féminin. Certains des joueurs les plus connus qui ont joué pour USC incluent Stan Smith, Alex Olmedo, Steve Johnson et Danielle Collins.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  







<MKBox id="Chapitre 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 3 : Du Collège aux Pros
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    L'un des plus grands rêves de nombreux jeunes joueurs de tennis est de poursuivre une carrière professionnelle et de briller sur la scène mondiale. Mais comment pouvez-vous réaliser ce rêve ? Le tennis universitaire est-il une bonne préparation pour le circuit professionnel ? Dans ce chapitre, nous examinerons le chemin vers le professionnalisme et comment le tennis universitaire peut aider les joueurs à franchir ce cap.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Le Chemin vers le Professionnalisme
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Devenir joueur de tennis professionnel n'est pas une tâche facile. Cela nécessite non seulement beaucoup de talent, mais aussi de la dédication, de la discipline, du travail acharné et des sacrifices. Cela nécessite également de prendre des décisions importantes qui peuvent influencer votre avenir. L'une des décisions les plus cruciales que vous devez prendre est de savoir si vous devez aller à l'université ou non.
      <br />
      <br />
      Il y a des avantages et des inconvénients aux deux options. Si vous choisissez de devenir professionnel immédiatement après le lycée, vous pouvez vous concentrer entièrement sur votre tennis et essayer de sécuriser une place dans les classements le plus rapidement possible. Vous pouvez également profiter de votre jeunesse, de votre énergie et de votre potentiel. Cependant, vous pouvez également rencontrer de nombreux risques, tels que les blessures, le burn-out, des problèmes financiers ou un manque de résultats. De plus, vous devrez peut-être renoncer ou reporter votre éducation académique, ce qui limite vos options si le tennis ne fonctionne pas.
      <br />
      <br />
      Si vous choisissez d'aller à l'université, vous pouvez bénéficier des nombreux avantages que propose le tennis universitaire. Non seulement vous pouvez recevoir une éducation de haute qualité qui peut vous aider à obtenir un diplôme et avoir une carrière alternative, mais vous pouvez également profiter d'une expérience de vie fantastique qui peut vous aider à grandir en tant que personne. Vous pouvez également profiter des excellentes installations, de l'encadrement, de l'orientation et des bourses d'études que fournissent les universités. Cependant, vous pouvez également rencontrer des inconvénients, tels que perdre du temps et des opportunités pour devenir professionnel, être en retard par rapport à d'autres joueurs qui ont commencé plus tôt, ou trouver difficile de passer du collège aux pros.
      <br />
      <br />
      Il n'y a pas de réponse unique à la question de savoir si vous devez aller à l'université ou non. Cela dépend de votre situation personnelle, de vos objectifs, de vos préférences et de vos opportunités. Certains joueurs ont réussi en devenant professionnels immédiatement, tandis que d'autres ont bénéficié de leur expérience universitaire. Le plus important est de prendre une décision éclairée qui vous convienne.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Circuits de Tennis Professionnels
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Si vous décidez de devenir joueur de tennis professionnel, vous devez comprendre comment fonctionnent les circuits de tennis professionnels et comment y parvenir. Il existe différents circuits pour les hommes et les femmes, chacun avec ses propres organisations, règles et tournois.
      <br />
      <br />
      Pour les hommes, il existe deux circuits majeurs : le circuit ATP et le circuit ITF World Tennis Tour. Le circuit ATP est le plus haut niveau du tennis professionnel masculin, où les meilleurs joueurs du monde se disputent des titres prestigieux et de l'argent. Le circuit ATP se compose de quatre catégories de tournois : les Grands Chelems, les ATP Finals, les ATP Masters 1000 et les ATP 250/500. Pour participer à ces tournois, vous devez avoir un classement élevé, qui est déterminé par le nombre de points que vous gagnez en remportant des matchs.
      <br />
      <br />
      Le circuit ITF World Tennis Tour est le niveau inférieur du tennis professionnel masculin, où les joueurs visent à améliorer leur classement et à se qualifier pour le circuit ATP. Le circuit ITF World Tennis Tour se compose de trois catégories de tournois : l'ITF World Tennis Tour M25/M15, l'ITF World Tennis Tour M25/M15+H et l'ITF World Tennis Tour M15/M15+H. Pour participer à ces tournois, vous devez avoir un classement ITF, qui est déterminé par le nombre de points que vous gagnez en remportant des matchs.
      <br />
      <br />
      Pour les femmes, il existe également deux circuits majeurs : le circuit WTA et le circuit ITF World Tennis Tour. Le circuit WTA est le plus haut niveau du tennis professionnel féminin, où les meilleures joueuses du monde se disputent des titres prestigieux et de l'argent. Le circuit WTA se compose de quatre catégories de tournois : les Grands Chelems, les WTA Finals, les WTA Premier et les WTA International. Pour participer à ces tournois, vous devez avoir un classement élevé, qui est déterminé par le nombre de points que vous gagnez en remportant des matchs.
      <br />
      <br />
      Le circuit ITF World Tennis Tour est le niveau inférieur du tennis professionnel féminin, où les joueuses visent à améliorer leur classement et à se qualifier pour le circuit WTA. Le circuit ITF World Tennis Tour se compose de trois catégories de tournois : l'ITF World Tennis Tour W100/W60/W25/W15, l'ITF World Tennis Tour W100/W60/W25/W15+H et l'ITF World Tennis Tour W15/W15+H. Pour participer à ces tournois, vous devez avoir un classement ITF, qui est déterminé par le nombre de points que vous gagnez en remportant des matchs.
      <br />
      <br />
      Pour passer du tennis universitaire au tennis professionnel, vous devez d'abord construire un classement dans le circuit ITF World Tennis Tour, puis essayer de vous qualifier pour le circuit ATP ou WTA. Cela peut être un processus difficile et long qui nécessite beaucoup d'efforts, de patience et de détermination. Cependant, ce n'est pas impossible, et il existe de nombreux exemples de joueurs qui ont réussi à faire la transition et qui brillent maintenant sur le circuit professionnel.
    </MKTypography>
  </MKBox>
</MKBox>
      






                                  






<MKBox id="Chapitre 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 4 : Influences Internationales
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le tennis est un sport mondial qui rassemble des personnes de différentes nationalités, cultures et origines. Cela est également vrai pour le tennis universitaire en Amérique, qui bénéficie d'une diversité significative et d'une richesse d'influences internationales. Dans ce chapitre, nous explorerons comment les joueurs étrangers ont impacté la scène collégiale et comment les joueurs américains ont atteint la scène internationale.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Joueurs Étrangers dans la Scène Universitaire
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Joueurs de l'Université de Virginie célébrant le championnat national de tennis universitaire."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Joueurs de l'Université de Virginie célébrant le championnat national
        </figcaption>
      </figure>
      L'une des caractéristiques les plus remarquables du tennis universitaire en Amérique est le pourcentage élevé de joueurs étrangers participant au sport. Selon l'Intercollegiate Tennis Association (ITA), en 2020, il y avait plus de 3 000 joueurs étrangers qui compétitionnaient pour des universités américaines, ce qui représentait environ 40 % du nombre total de joueurs. Ces joueurs venaient de plus de 100 pays, les pays les plus courants étant : le Royaume-Uni, l'Allemagne, la France, l'Espagne, l'Australie, le Canada, le Brésil, le Japon, la Chine et l'Inde.
      <br />
      <br />
      Les raisons pour lesquelles les joueurs étrangers choisissent de venir en Amérique pour étudier et jouer au tennis sont diverses. Certains recherchent une meilleure éducation académique qu'ils ne peuvent trouver dans leur propre pays. D'autres recherchent de meilleures opportunités de développement sportif qui ne sont pas disponibles dans leur pays d'origine. D'autres encore cherchent une nouvelle expérience de vie qu'ils ne peuvent avoir dans leur propre pays. Quelle que soit leur motivation, les joueurs étrangers apportent de nombreux avantages au tennis universitaire américain.
      <br />
      <br />
      L'un des avantages est que les joueurs étrangers élèvent le niveau et la qualité du jeu. Ils apportent de nouvelles compétences, styles, tactiques et stratégies qui rendent le jeu plus diversifié et intéressant. Ils mettent également au défi les joueurs américains de faire de leur mieux et de s'adapter à différentes situations. De plus, ils contribuent au prestige et à la réputation des universités pour lesquelles ils jouent, attirant ainsi plus d'attention et de reconnaissance.
      <br />
      <br />
      Un autre avantage est que les joueurs étrangers enrichissent la culture et l'atmosphère du jeu. Ils apportent de nouvelles perspectives, idées, valeurs et traditions qui rendent le jeu plus ouvert et inclusif. Ils créent également un sentiment de communauté et d'amitié entre les joueurs de différents horizons qui collaborent et se soutiennent mutuellement. De plus, ils apprennent les uns des autres et partagent leurs expériences, élargissant leurs horizons et favorisant la croissance personnelle.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Américains sur la Scène Internationale
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Alors que les joueurs étrangers ont un impact significatif sur le tennis universitaire américain, les joueurs américains ont également eu une influence majeure sur le tennis international. De nombreux joueurs américains qui ont joué à l'université ont ensuite connu le succès sur le circuit professionnel et ont représenté leur pays sur la scène mondiale. Certains de ces joueurs comprennent :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          John Isner, qui a fréquenté l'Université de Géorgie et a été quatre fois All-American. Il est connu pour son service puissant et ses longs matchs, comme le match le plus long de l'histoire contre Nicolas Mahut à Wimbledon en 2010. Il a remporté 15 titres ATP et atteint la quatrième position au classement mondial.
        </li>
        <li>
          Kevin Anderson, qui a fréquenté l'Université de l'Illinois et a été deux fois All-American. Il est connu pour son jeu de fond de court solide et ses performances constantes. Il a remporté six titres ATP et atteint deux finales de Grand Chelem : l'US Open en 2017 et Wimbledon en 2018.
        </li>
        <li>
          Danielle Collins, qui a fréquenté l'Université de Virginie et a été deux fois championne de la NCAA. Elle est connue pour son style de jeu agressif et expressif et sa détermination. Elle a remporté un titre WTA et atteint les demi-finales de l'Open d'Australie en 2019.
        </li>
        <li>
          Jennifer Brady, qui a fréquenté UCLA et a été une fois All-American. Elle est connue pour ses coups puissants et son amélioration de mouvement. Elle a remporté un titre WTA et atteint la finale de l'Open d'Australie en 2021.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>



                                              






                                  

<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 5 : Bourses Sportives et l'Avenir des Athlètes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Les bourses sportives offrent de nombreuses opportunités aux jeunes athlètes de montrer leur talent et de développer leurs compétences. L'une des opportunités les plus significatives est d'obtenir une bourse sportive pour étudier et compétitionner au niveau collégial. Dans ce chapitre, nous discuterons de l'importance des bourses sportives et de comment Sport Scholarships America peut vous aider à réaliser votre rêve.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 L'Importance des Bourses
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Une bourse sportive est un soutien financier fourni par une université à un étudiant-athlète pour combiner études et sport. Elle peut couvrir les frais de scolarité, les livres, le logement, les repas et autres dépenses. De plus, elle peut offrir des avantages tels que du tutorat académique, des soins médicaux, des installations d'entraînement et du matériel.
      <br />
      <br />
      Obtenir une bourse sportive est compétitif, avec de nombreux facteurs qui influent sur le processus d'attribution, notamment les performances académiques, sportives, la personnalité, la motivation, le leadership et l'adaptabilité. Il est donc important de bien se préparer et de se démarquer de la concurrence.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Comment Sport Scholarships America Peut Aider
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo de Sport Scholarships America, représentant du matériel sportif et une casquette de graduation, symbolisant le focus sur les bourses sportives pour les étudiants-athlètes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America est une organisation spécialisée dans l'orientation des jeunes talents sportifs vers des bourses sportives. Avec des experts passionnés et expérimentés, Sport Scholarships America soutient les étudiants-athlètes à chaque étape du parcours, de l'orientation initiale à la placement final. Leurs services comprennent :
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Un entretien personnel pour discuter des souhaits, des possibilités et des attentes de l'étudiant-athlète.</li>
        <li>Une évaluation professionnelle du niveau académique et sportif de l'étudiant-athlète.</li>
        <li>Un vaste réseau de contacts avec des entraîneurs, des universités et des collèges.</li>
        <li>Promotion efficace du profil et des vidéos de l'étudiant-athlète auprès des entraîneurs potentiels.</li>
        <li>Conseils d'experts sur les meilleures options en fonction des préférences, des objectifs et du budget de l'étudiant-athlète.</li>
        <li>Négociation des meilleures offres de bourses au nom de l'étudiant-athlète.</li>
        <li>Aide à la demande de documents nécessaires, tels que visa, relevé de notes, diplôme et tests d'admission.</li>
        <li>Préparation à la vie d'étudiant-athlète pendant sa bourse, y compris culture, langue, règles et conseils.</li>
        <li>Suivi et accompagnement pendant la période de la bourse.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vous voulez explorer les opportunités de bourses et atteindre vos objectifs sportifs et académiques ? <Link to="/fr/Services-Produits">Consultez la gamme complète</Link> des services offerts par Sport Scholarships America et planifiez une consultation dès aujourd'hui.
    </MKTypography>
  </MKBox>
</MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;