import PropTypes from "prop-types";
import MKBox from "components/MKBox";

function ExampleCard({ image, alt, applyGradient = true, applyPopOut = true, selected = false }) {
  return (
    <MKBox
      position="relative"
      bgColor="#851f32"
      borderRadius="15px"
      shadow="lg"
      sx={{
        overflow: "hidden",
        aspectRatio: '1/1',  // This maintains a square shape.
        transform: applyPopOut ? "perspective(999px) rotateX(0deg) translate3d(0, 0, 0)" : "none",
        transformOrigin: "50% 0",
        backfaceVisibility: "hidden",
        willChange: "transform, box-shadow",
        transition: "transform 200ms ease-out",
        "&:hover": {
          transform: applyPopOut ? "perspective(999px) rotateX(7deg) translate3d(0px, -4px, 5px)" : "none",
          cursor: 'pointer'
        },
        "&::before": {
          content: applyGradient || selected ? '""' : 'none',
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          borderRadius: "inherit",
          backgroundColor: "rgba(255, 255, 255, 0.5)", // Soft white overlay
          opacity: selected ? 1 : 0,
          transition: "opacity 200ms ease-out",
        },
        "&:hover::before": {
          opacity: applyGradient ? 1 : 0
        },
      }}
    >
      <MKBox component="img" src={image} alt={alt} width="100%" height="100%" objectFit="cover" my="auto" />
    </MKBox>
  );
}

ExampleCard.propTypes = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired, // Add alt prop and make it required
  applyGradient: PropTypes.bool,
  applyPopOut: PropTypes.bool,
  selected: PropTypes.bool
};

export default ExampleCard;
