/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import Image1 from "assets/images/Sports_Images/Ice Hockey1.jpeg";
import Image2 from "assets/images/Sports_Images/Ice Hockey2.jpg";
import Image3 from "assets/images/Sports_Images/Ice Hockey3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images

import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Ice Hockey Scholarship USA: How to Study and Play Ice Hockey in America', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Chapter 1: The History and Development of Ice Hockey in America', isSubheader: false },
    { id: '1.1', title: '1.1 Early Years and the Role of Colleges', isSubheader: true },
    { id: '1.2', title: '1.2 Growth and Professionalization', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Chapter 2: College Ice Hockey Today', isSubheader: false },
    { id: '2.1', title: '2.1 Major Leagues and Conferences', isSubheader: true },
    { id: '2.2', title: '2.2 Top Programs and Prestigious Teams', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Chapter 3: From College to the Pros', isSubheader: false },
    { id: '3.1', title: '3.1 The Path to Professionalism', isSubheader: true },
    { id: '3.2', title: '3.2 The NHL Draft and Other Opportunities', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Chapter 4: International Influences', isSubheader: false },
    { id: '4.1', title: '4.1 Foreign Players in College Ice Hockey', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Chapter 5: Sports Scholarships and the Future', isSubheader: false },
    { id: '5.1', title: '5.1 The Importance of Scholarships', isSubheader: true },
    { id: '5.2', title: '5.2 How Sport Scholarships America Can Help', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Content
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Hide</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Show</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>

























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Ice Hockey Scholarship USA: How to Study and Play Ice Hockey in America
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Aerial view of a packed stadium at a college ice hockey game."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        A packed stadium during a college game
      </figcaption>
    </figure>
    Ice hockey is one of the most popular sports in America. It is a fast, physical, and exciting sport that attracts millions of fans. Ice hockey is played at various levels, from amateur to professional. But one of the most important and prestigious levels is college ice hockey.
    <br />
    <br />
    College ice hockey is the version of ice hockey played by students of universities and colleges in America. It is a part of the American education system, combining sports and academics. College ice hockey offers young players the opportunity to develop both academically and athletically while enjoying student life.
    <br />
    <br />
    College ice hockey is also a stepping stone to the professional world. Many players who play in the NHL (National Hockey League) or other professional leagues have first played college ice hockey. College ice hockey is an excellent way to prepare for a career in the sport.
    <br />
    <br />
    But how can a young ice hockey player qualify for college ice hockey? That's where Sportbeursamerika.nl can help you. I specialize in guiding student-athletes who want to get a sports scholarship in America. A sports scholarship is a financial reward you receive from a university or college to study and play sports there. With a sports scholarship, you can study and play ice hockey in America without incurring high costs.
    <br />
    <br />
    In this article, we will provide you with more information about college ice hockey in America, its history, development, leagues, teams, opportunities, international influences, and, of course, scholarships. I will also tell you how I can help you make your dream come true. Are you ready to learn more about ice hockey scholarships in the USA? Read on!
  </MKTypography>
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 1: The History and Development of Ice Hockey in America
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Ice hockey is a sport that has existed in America for over a century. It has a rich and fascinating history closely tied to the role of colleges. In this chapter, we delve deeper into the origins, growth, and professionalization of ice hockey in America.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Early Years and the Role of Colleges
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ice hockey originated in Canada, where it was first played in the 19th century. The game quickly spread to the United States, becoming particularly popular in the northeastern states. The first university teams were formed in the late 19th and early 20th centuries, including Yale, Harvard, Princeton, and Dartmouth.
      <br />
      <br />
      These teams played intercollegiate matches, often attracting large crowds. They also played against Canadian teams, which were usually stronger. To elevate the level of play, some universities began recruiting professional players who enrolled as students. This practice raised controversy and criticism as it compromised the amateur status of the sport.
      <br />
      <br />
      To put an end to this practice, the Intercollegiate Hockey Association (IHA) was founded in 1898, the first collection of college ice hockey programs in America. The IHA, sometimes referred to as the Intercollegiate Hockey League, included teams like Brown, Columbia, the University of Pennsylvania, and Yale. This organization was the sole championship authority for college ice hockey at the time, determining the de facto national champion based on season performance. The IHA continued to exist until 1913.
      <br />
      <br />
      After the dissolution of the IHA, the Intercollegiate Hockey League (IHL) was established in 1912 by Harvard, Princeton, and Yale. This league was a response to the need for top college teams to continue competing against each other. The IHL existed until 1917 when activities were suspended due to World War I.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Growth and Professionalization
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Throughout the 20th century, college ice hockey grew into a national sport, played by hundreds of teams in various divisions and conferences. College ice hockey was also increasingly influenced by the rise of professional ice hockey leagues, such as the NHL (founded in 1917) and the World Hockey Association (founded in 1972).
      <br />
      <br />
      These professional leagues provided opportunities for talented college players to advance to a higher level. Many college players were selected in the NHL Draft, the annual event where NHL teams choose new players. The first NHL draft in which a college player was selected took place in 1967. Al Karlander, a player from Michigan Tech, was selected by Detroit as the 17th overall pick that year. Since then, over a thousand college players have been drafted by NHL teams.
      <br />
      <br />
      College ice hockey also witnessed significant moments in the history of the sport. One of the most memorable was the "Miracle on Ice" in 1980 when the American Olympic team, primarily consisting of college players, defeated the Soviet Union in a historic game during the Winter Olympics in Lake Placid. This was a remarkable achievement as the Soviets were considered the best ice hockey team in the world at the time.
      <br />
      <br />
      College ice hockey, therefore, has a long and impressive history, bearing witness to the passion and talent of American players. College ice hockey has also contributed to the development and innovation of the sport by introducing new rules, tactics, and styles.
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  








<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 2: College Ice Hockey Today
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College ice hockey is one of the most competitive and exciting sports in America today. It is played by over 400 teams, divided into three NCAA divisions, various conferences, and regional zones. In this chapter, we will provide an overview of the major leagues and conferences, top programs and prestigious teams, and the structure of the season and championship.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Major Leagues and Conferences
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Players from Massachusetts celebrating a victory in college ice hockey."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Players from Massachusetts celebrating a victory
        </figcaption>
      </figure>
      The NCAA (National Collegiate Athletic Association) is the organization that regulates college ice hockey. The NCAA divides the teams into three divisions based on the level, budget, and number of scholarships they offer. The divisions are:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Division I: This is the highest division where the best teams and players compete. Division I comprises 60 teams, divided into six conferences: Atlantic Hockey, Big Ten, ECAC Hockey, Hockey East, NCHC (National Collegiate Hockey Conference), and WCHA (Western Collegiate Hockey Association). These conferences organize their own competitions and tournaments throughout the season.
        </li>
        <li>
          Division II: This is an intermediate division with fewer teams and players. There are only seven teams in Division II, and they do not have their own conference. They usually compete against teams from Division III or other leagues.
        </li>
        <li>
          Division III: This is the lowest division where most teams and players participate. Division III consists of 84 teams, divided into seven conferences: CCC (Commonwealth Coast Conference), MASCAC (Massachusetts State Collegiate Athletic Conference), MIAC (Minnesota Intercollegiate Athletic Conference), NCHA (Northern Collegiate Hockey Association), NEHC (New England Hockey Conference), SUNYAC (State University of New York Athletic Conference), and UCHC (United Collegiate Hockey Conference). These conferences also organize their own competitions and tournaments during the season.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      In addition to the NCAA divisions and conferences, there are also other leagues and organizations that offer college ice hockey.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Top Programs and Prestigious Teams
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      College ice hockey has several top programs and prestigious teams that stand out due to their success, tradition, reputation, and influence. Some of these programs and teams include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          University of North Dakota: One of the most successful college ice hockey programs in America, competing in the NCHC conference in Division I. The university has won multiple NCAA championships and has produced numerous players who have played in the NHL, including well-known names like Jonathan Toews, Zach Parise, and T.J. Oshie.
        </li>
        <li>
          Boston College: A prominent college ice hockey program in America, competing in the Hockey East conference in Division I. Boston College has several national championships to its name. The program is also known for developing over 80 NHL players, with notable alumni such as Johnny Gaudreau, Patrick Eaves, and Brian Gionta.
        </li>
        <li>
          University of Minnesota: This historic college ice hockey program, competing in the Big Ten conference in Division I, has achieved an impressive number of national championships. It is known for producing a record number of players who have played in the NHL, including stars like Phil Kessel, Paul Martin, and Blake Wheeler.
        </li>
        <li>
          University of Wisconsin: One of the most prestigious college ice hockey programs in America, competing in the Big Ten conference in Division I. Wisconsin has won multiple national championships and has also trained a significant number of players who have played in the NHL, including Ryan Suter, Joe Pavelski, and Dany Heatley.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      These programs and teams are just a few examples of the many that college ice hockey has to offer. There are many more teams that have their own story, culture, and identity. College ice hockey is a diverse and dynamic sport with much to offer players and fans.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  









<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 3: From College to the Pros
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College ice hockey is not just a sport; it's also a learning ground. Many players who participate in college ice hockey have ambitions of advancing to the professional world. In this chapter, we will discuss how college ice hockey can serve as a stepping stone to the NHL or other professional leagues, which alumni have made it in the professional ice hockey world, and what opportunities exist beyond the NHL.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 The Path to Professionalism
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      College ice hockey is an excellent way to prepare for a career in sports. By playing college ice hockey, you can improve your skills, knowledge, and experience while earning a degree. College ice hockey also provides you with the opportunity to compete against other talented players who challenge and inspire you.
      <br />
      <br />
      One of the key advantages of college ice hockey is that it increases your visibility and exposure. Playing college ice hockey can attract the attention of scouts, coaches, and managers from professional teams who are looking for new players. You can also participate in showcases, camps, and clinics to showcase your skills to potential employers.
      <br />
      <br />
      Another advantage of college ice hockey is that it offers flexibility and options. By playing college ice hockey, you can delay making a decision about your future. You can choose to transition to a professional team after your first, second, third, or fourth year, depending on your situation and preference. You can also opt to complete your studies before turning professional.
      <br />
      <br />
      College ice hockey has produced many players who have succeeded in the professional ice hockey world. Some of these players include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Jack Eichel: He played one season for Boston University in the Hockey East conference in Division I. He won the Hobey Baker Award as the best player of the year in 2015. He was selected second overall in the 2015 NHL Draft by the Buffalo Sabres, where he became the captain and star player. In 2023, he won the Stanley Cup with the Vegas Golden Knights.
        </li>
        <li>
          Cale Makar: He played two seasons for UMass Amherst in the Hockey East conference in Division I. He also won the Hobey Baker Award as the best player of the year in 2019. He was selected fourth overall in the 2017 NHL Draft by the Colorado Avalanche, where he currently plays as one of the top defensemen in the league.
        </li>
        <li>
          Hilary Knight: Hilary Knight played four seasons for the University of Wisconsin in the WCHA conference in Division I. She was a highly talented player and was nominated as a top-10 finalist for the Patty Kazmaier Memorial Award in 2009, 2011, and 2012. Although she didn't win the award, she was a two-time First Team All-American. Knight later played for the Boston Pride in the NWHL (National Women’s Hockey League) and the U.S. national team, with which she won two Olympic silver medals and multiple world championships.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      These players are just a few examples of the many who have turned their college ice hockey careers into professional careers. College ice hockey is a proven path to professionalism for many players.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 The NHL Draft and Other Opportunities
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      The NHL (National Hockey League) is the premier professional ice hockey league in the world. It's the dream of many young players to one day play in the NHL. One of the ways to achieve that is through the NHL Draft, the annual event where NHL teams select new players.
      <br />
      <br />
      The NHL Draft is an exciting and significant moment for many college players. It's an opportunity to be chosen by an NHL team, which offers you a contract to play for them. It also acknowledges your talent, potential, and hard work.
      <br />
      <br />
      The NHL Draft consists of seven rounds in which each NHL team takes turns selecting a player. The order of selection is determined by a lottery, with teams that had the worst results in the previous season having a better chance of picking first. The NHL Draft usually takes place in June or July after the end of the college ice hockey season.
      <br />
      <br />
      To be eligible for the NHL Draft, you must meet certain criteria. You must be a North American player between the ages of 18 and 20 or an international player between the ages of 18 and 21. You must not have been previously drafted by an NHL team or have given up your rights.
      <br />
      <br />
      Being drafted by an NHL team doesn't mean you have to immediately play for them. You can choose to return to your college team and complete your studies while retaining the rights of the NHL team. You can also choose to join the NHL team or their minor league affiliate, where you can further develop and train.
      <br />
      <br />
      However, the NHL Draft is not the only way to make it to the NHL. There are other possibilities, such as free agency. This is when you, as a player, do not have a contract with an NHL team and are free to negotiate with any team you wish. This can happen if you were not drafted or if your contract with an NHL team expires or is terminated. As a free agent, you can try to secure a contract with an NHL team that has interest in you.
      <br />
      <br />
      Nevertheless, the NHL is not the only professional ice hockey league in the world. There are other leagues where you can play as a player and earn a living. Some of these leagues include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          KHL (Kontinental Hockey League): This is the premier professional ice hockey league in Europe and Asia. It consists of 24 teams from eight countries: Russia, Finland, Belarus, Kazakhstan, China, Latvia, Slovakia, and the Czech Republic. It offers a high level of play and salary, attracting players from various countries.
        </li>
        <li>
          SHL (Swedish Hockey League): This is the premier professional ice hockey league in Sweden. It consists of 14 teams competing for the national championship. It is known for its fast and technical play and has produced many players who have played in the NHL.
        </li>
        <li>
          Liiga (Finnish Hockey League): This is the premier professional ice hockey league in Finland. It consists of 15 teams competing for the national championship. It is known for its physical and tactical play and has also produced many players who have played in the NHL.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      These leagues are just a few examples of the many leagues around the world. There are many more leagues in other countries, including Switzerland, Germany, France, Norway, Denmark, Austria, Poland, Japan, and Australia. These leagues offer different levels of play, salaries, and lifestyles to players.
      <br />
      <br />
      College ice hockey, therefore, is not the end of the road for many players. Instead, it is the beginning of an exciting journey into the professional world. College ice hockey offers many opportunities and options for players pursuing their dreams.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  








<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 4: International Influences
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College ice hockey is a sport that is not only American but also international. College ice hockey is influenced by the presence and performance of foreign players in American college competitions. College ice hockey also influences the opportunities and development of American players abroad. In this chapter, we will discuss the role and impact of international players in college ice hockey and the opportunities for American players to play internationally.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Foreign Players in College Ice Hockey
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Quinnipiac ice hockey players celebrating a goal."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Quinnipiac players celebrating a goal
        </figcaption>
      </figure>
      College ice hockey is a sport that is open to players from all over the world. There are many foreign players who come to America to play college ice hockey, with various backgrounds, cultures, and nationalities. Some of these players include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Daryl Watts: She is a Canadian ice hockey player who played for both Boston College and Wisconsin in NCAA Division I for two seasons. She won the Patty Kazmaier Award in 2018 as a freshman, making her the first freshman to win the award. Watts then played for the Toronto Six in the Premier Hockey Federation (PHF), formerly known as the National Women’s Hockey League (NWHL), and signed a record contract with the Toronto Six in 2023.
        </li>
        <li>
          Rod Brind'Amour: Born in Canada, he played college ice hockey at Michigan State University before moving on to the NHL. He had a highly successful professional career, with highlights including winning the Stanley Cup.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      These players are just a few examples of the many foreign players who play or have played college ice hockey. There are many more players from other countries such as Sweden, Russia, the Czech Republic, Germany, France, Switzerland, Denmark, Japan, and Australia.
      <br />
      <br />
      These foreign players have an important role and impact in college ice hockey. They bring their own style, skills, and experience to American college competitions. They enrich the level and diversity of the game. They also create more interest and attention for college ice hockey in their own countries and regions.
      <br />
      <br />
      College ice hockey is a sport that benefits from international influences. It is a sport that welcomes and appreciates players from all over the world.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 5: Sports Scholarships and the Future of Athletes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sports scholarships offer numerous opportunities for young athletes to showcase their talent and develop their skills. One of the most significant opportunities is obtaining a sports scholarship to study and compete at the college level. In this chapter, we will discuss the importance of sports scholarships and how Sport Scholarships America can help you make your dream come true.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 The Importance of Scholarships
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A sports scholarship is financial support provided by a university to a student-athlete to combine studies and sports. It can cover the costs of tuition, books, housing, meals, and other expenses. Additionally, it may offer benefits such as academic tutoring, medical care, training facilities, and equipment.
      <br />
      <br />
      Obtaining a sports scholarship is competitive, with many factors playing a role in the awarding process, including academic achievements, athletic performance, personality, motivation, leadership, and adaptability. Therefore, it's essential to prepare well and distinguish yourself from the competition.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 How Sport Scholarships America Can Help
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo of Sport Scholarships America, featuring sports equipment and a graduation cap, representing the focus on sports scholarships for student-athletes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo of Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America is an organization specialized in guiding young sports talents to sports scholarships. With passionate and experienced experts, Sport Scholarships America supports student-athletes at every step of the journey, from initial orientation to final placement. Their services include:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>A personal intake interview to discuss the student-athlete's wishes, possibilities, and expectations.</li>
        <li>A professional evaluation of the student-athlete's athletic and academic level.</li>
        <li>An extensive network of contacts with coaches, universities, and colleges.</li>
        <li>Effective promotion of the student-athlete's profile and videos to potential coaches.</li>
        <li>Expert advice on the best options based on the student-athlete's preferences, goals, and budget.</li>
        <li>Negotiation of the best scholarship offers on behalf of the student-athlete.</li>
        <li>Assistance in applying for necessary documents, such as visa, transcript, diploma, and admission tests.</li>
        <li>Preparation for student-athletes' life during their scholarship, including culture, language, rules, and tips.</li>
        <li>Aftercare and follow-up during the scholarship period.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Want to explore scholarship opportunities and achieve your athletic and academic goals? <Link to="/Services-Products">View the full range</Link> of services offered by Sport Scholarships America and schedule a consultation today.
    </MKTypography>
  </MKBox>
</MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;