/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Página do Produto: https://www.creative-tim.com/product/material-kit-pro-react
* Direitos Autorais 2023 Creative Tim (https://www.creative-tim.com)

Codificado por www.creative-tim.com

=========================================================

* O aviso de direitos autorais acima e este aviso de permissão devem ser incluídos em todas as cópias ou partes substanciais do software.
*/

// Componentes do Material-UI
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Componentes do Material Kit 2 PRO React
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Imagens
import post1 from "assets/images/Jeugd.png";
import post3 from "assets/images/Deal.png";
import "../../../../H1asH3.css";


function Perfil() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center"></MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox 
                  display="flex" 
                  flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} 
                  justifyContent="space-between" 
                  alignItems="center" 
                  mb={1}
              >
                  <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Parte 2: Meu Tempo na América</MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text">
  <br />
    Meu tempo na América foi como uma jornada fascinante, repleta de experiências inesquecíveis e crescimento pessoal. 
    Graças ao futebol, viajei de costa a costa, acompanhado por um grupo de colegas de equipe que rapidamente se tornaram alguns dos meus melhores amigos. 
    Nossos dias começavam cedo com sessões de treinamento intensivas na academia, algo a que não estava acostumado na Holanda. 
    Em seguida, íamos rapidamente para a universidade para aulas e voltávamos para o treinamento à tarde. Era um compromisso diário que me desafiava tanto fisicamente quanto mentalmente.
  <br /><br />
    Os jogos eram destaques para os quais milhares de fãs compareciam, e toda vez parecia que estávamos jogando por algo maior do que nós mesmos. 
    A adrenalina de entrar em campo na frente de uma multidão tão apaixonada é indescritível. Além disso, as viagens que fizemos para os jogos foram inesquecíveis. 
    No avião, muitas vezes passávamos horas jogando cartas, e nos saguões dos hotéis, compartilhávamos momentos hilários e conversas empolgantes. 
    Restaurantes com pratos deliciosos se tornaram nossos locais temporários para refeições, e essas aventuras culinárias adicionavam uma dimensão extra às nossas viagens. 
    Tudo era meticulosamente organizado, desde as cartas no avião até os restaurantes onde jantávamos. 
    Essas experiências me permitiram ter um vislumbre da grandeza da América.
  <br /><br />
    Além disso, tive a oportunidade de estagiar em clubes profissionais líderes, incluindo o Seattle Sounders. 
    Compartilhar o campo com jogadores como o maior artilheiro de todos os tempos dos Estados Unidos, Clint Dempsey, foi simplesmente surreal, um destaque que sempre vou valorizar. 
    Mas, como em toda aventura, essa jornada teve seus desafios. 
    No meu último ano, enfrentei uma grave lesão nos músculos isquiotibiais, que temporariamente interrompeu minhas ambições no futebol. 
    Foi um período de reflexão pessoal e resiliência, do qual emergi mais forte.
  <br /><br />
    Além disso, minha jornada acadêmica na América foi igualmente importante. Apesar da intensa programação de treinamento de futebol e das extensas viagens, 
    recebi orientação perfeita para continuar meus estudos. Não foi uma tarefa fácil, pois muitas vezes eu faltava às aulas devido ao meu envolvimento com a equipe de futebol. 
    No entanto, eu estava determinado a ter sucesso, e o fiz com orgulho, formando-me com as maiores honras, magna cum laude. 
    Esse sucesso acadêmico foi um testemunho da minha dedicação tanto ao esporte quanto à minha educação.
  <br /><br />
    Durante minha aventura na América, vivi festas americanas típicas que pareciam saídas de um filme. 
    Essas reuniões sociais vibrantes, repletas de música, dança e risadas altas, me ofereceram a oportunidade de crescer, não apenas como atleta e estudante, 
    mas também como um entusiasta social. Essas festas adicionaram uma dimensão extra à minha vida estudantil e enriqueceram minhas experiências sociais.
  <br /><br />
    No entanto, a América significou mais para mim do que apenas futebol, jogos, eventos sociais e conquistas acadêmicas. 
    Foi uma oportunidade de expandir meu círculo social e fazer amizades para toda a vida. 
    Graças a essas amizades, pude descobrir o país de novas maneiras. 
    Desde as férias de primavera no México, onde aproveitamos o sol, o mar e a praia, até a celebração do Dia de Ação de Graças com as famílias dos amigos, 
    a quem agora posso chamar de minha família americana. Sua calorosa recepção realmente me fez fazer parte de suas vidas, 
    e essas experiências tornaram meu tempo na América ainda mais rico.
  <br /><br />
  Esse período da minha vida aprofundou meu amor tanto pelo futebol quanto pela educação e me levou ao caminho que sigo hoje para ajudar os outros a alcançarem seus sonhos na América. 
  Fique atento, pois na Parte 3 compartilharei como meu retorno à Holanda e a fundação da minha empresa deram uma nova direção à minha vida.
</MKTypography>
              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="Parte 1: Crescendo na Holanda"
                    action={{
                      type: "internal",
                      route: "/pt/Sobre-mim/Crescendo",
                      color: "info",
                      label: "saiba mais",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="Parte 3: De Volta à Holanda"
                    action={{
                      type: "internal",
                      route: "/pt/Sobre-mim/O-meu-regresso-à-Holanda",           
                      color: "info",
                      label: "saiba mais",
                    }}
                  />
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Perfil;
