/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Gymnastics1.jpeg";
import Image2 from "assets/images/Sports_Images/Gymnastics2.jpg";
import Image3 from "assets/images/Sports_Images/Gymnastics3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Gymnastik och Studier i Amerika: En Unik Möjlighet för Unga Gymnaster', isSubheader: false },
  
    { id: 'Hoofdstuk 1', title: 'Kapitel 1: Historia och Utveckling av Gymnastik i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Tidiga År och Universitetens Roll', isSubheader: true },
    { id: '1.2', title: '1.2 Tillväxt och Professionalisering', isSubheader: true },
  
    { id: 'Hoofdstuk 2', title: 'Kapitel 2: Collegiate Gymnastik Idag', isSubheader: false },
    { id: '2.1', title: '2.1 Stora Tävlingar och Konferenser', isSubheader: true },
    { id: '2.2', title: '2.2 Topprogram och Prestigefyllda Lag', isSubheader: true },
  
    { id: 'Hoofdstuk 3', title: 'Kapitel 3: Från College till Proffs', isSubheader: false },
    { id: '3.1', title: '3.1 Vägen till Professionalism', isSubheader: true },
    { id: '3.2', title: '3.2 Professionella Karriärer och Möjligheter', isSubheader: true },
  
    { id: 'Hoofdstuk 4', title: 'Kapitel 4: Internationella Influenser', isSubheader: false },
    { id: '4.1', title: '4.1 Utländska Influenser och Utbyte', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikaner Utomlands', isSubheader: true },
  
    { id: 'Hoofdstuk 5', title: 'Kapitel 5: Idrottsstipendier och Framtiden för Idrottare', isSubheader: false },
    { id: '5.1', title: '5.1 Vikten av Stipendier', isSubheader: true },
    { id: '5.2', title: '5.2 Hur Sport Scholarships America Kan Hjälpa', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innehåll
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Dölj</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Visa</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>





















          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Gymnastik och Studier i Amerika: En Unik Möjlighet för Unga Gymnaster
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Utsikt från läktaren vid en gymnastiktävling på college i en stor arena."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        En stor arena under en NCAA-gymnastiktävling
      </figcaption>
    </figure>
    Gymnastik har en lång och rik historia i USA. Sedan 1800-talet har gymnastik utövats vid universitet, där gymnaster utvecklar sina färdigheter och visar upp sina talanger. Gymnastik är inte bara en sport; det är också en konstform, en vetenskap och en livsstil. Gymnaster lär sig disciplin, uthållighet, kreativitet och teamwork. De tränar hårt men har också mycket kul.
    <br />
    <br />
    Att tävla i gymnastik på collegenivå är en unik möjlighet för unga gymnaster att förverkliga sina drömmar. Genom att tjäna ett idrottsstipendium kan du studera vid ett prestigefyllt universitet samtidigt som du deltar i spännande tävlingar mot andra toppgymnaster. Du blir en del av en tight gemenskap av studentidrottare som stöttar och motiverar varandra. Du får tillgång till de bästa faciliteterna, tränarna och mentorerna som hjälper dig att nå din fulla potential. Och du får en oförglömlig upplevelse som förbereder dig för din framtida karriär.
    <br />
    <br />
    Men hur kvalificerar du dig för ett idrottsstipendium? Vilka är kraven och fördelarna? Hur hittar du rätt universitet för dig? Och hur är livet som student-gymnast? Dessa är alla frågor som Sportbeursamerika.nl kan svara på för dig. Sportbeursamerika.nl är en plattform som hjälper unga gymnaster att hitta sin väg till Amerika. Jag erbjuder personlig vägledning, råd och stöd genom hela ansöknings-, urvals-, förberedelse- och avresningsprocessen. Jag har kontakter med hundratals universitet och tränare som letar efter gymnastiktalang. Jag känner till ins and outs av det amerikanska utbildningssystemet och collegianska gymnastik. Och jag har personlig erfarenhet av idrott och studier i Amerika.
    <br />
    <br />
    Är du nyfiken på möjligheterna att kombinera gymnastik och studier i Amerika? Läs vidare och upptäck allt du behöver veta om denna unika möjlighet.
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>

<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Historia och Utveckling av Gymnastik i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Gymnastik är en av de äldsta sporterna i världen, med sina rötter i antika civilisationer som Grekland, Rom och Kina. Gymnastik användes som ett sätt att träna kroppen, skärpa sinnet och upphöja själen. Det sågs också som en form av uttryck, konst och skönhet.
    <br />
    <br />
    I USA började gymnastiken utvecklas på 1800-talet när europeiska invandrare förde med sig sin kunskap och sina färdigheter. Gymnastiken blev snabbt populär i skolor, klubbar och föreningar, där den sågs som ett sätt att främja hälsa, karaktär och medborgarskap. Gymnastik blev också en källa till nationell stolthet, särskilt efter självständighetskriget.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidiga År och Universitetens Roll
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      De första universitetsgymnastiklagen bildades i slutet av 1800-talet när studenter etablerade sina egna klubbar och organiserade interkollegiala tävlingar. Den första officiella universitetsgymnastiktävlingen ägde rum 1897 när Yale University mötte Springfield College. Springfield College anses vara födelseplatsen för amerikansk gymnastik eftersom det var den första skolan som erbjöd gymnastik som ett akademiskt ämne. Många välkända gymnastiktränare och ledare studerade eller undervisade vid Springfield College.
      <br />
      <br />
      I början av 1900-talet fortsatte universitetsgymnastiken att växa med etableringen av regionala och nationella organisationer som Eastern Intercollegiate Gymnastics League (EIGL) 1902 och National Collegiate Athletic Association (NCAA) 1906. NCAA började organisera nationella mästerskap för män 1938 och för kvinnor 1982. NCAA är fortfarande den primära organisationen för collegian gymnastik i Amerika.
    </MKTypography>
  </MKBox>

  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Tillväxt och Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Populariteten och nivån på universitetsgymnastiken ökade efter andra världskriget när många veteraner återvände till universitet och satsade på sina idrottsliga ambitioner. Inflytandet från europeiska tränare och idrottare, som kom till Amerika för att fly från kommunismen eller nazismen, bidrog också till utvecklingen av universitetsgymnastiken. En sådan person var George Gulack, en ungersk gymnast som vann guld vid OS 1936 i Berlin. Gulack spelade en betydande roll i den olympiska kommittén och amerikanska och internationella gymnastikorganisationer, och tjänade på den amerikanska olympiska kommittén från 1934 till 1958.
      <br />
      <br />
      På 1950- och 60-talen började professionell gymnastik att skilja sig från universitetsgymnastiken, med uppkomsten av internationella stjärnor som Larisa Latynina, Olga Korbut och Nadia Comaneci. Dessa gymnaster inspirerade många unga amerikaner att gå med i privata klubbar och fokusera på individuella prestationer. En av dem var Cathy Rigby, som blev den första amerikanska kvinnan att vinna en medalj vid ett världsmästerskap 1970. Hon blev senare en mycket framgångsrik skådespelerska.
      <br />
      <br />
      Professionell gymnastik nådde sin topp 1978 när den amerikanske gymnasten Kurt Thomas blev den första mannen att vinna ett guldmedalj vid ett världsmästerskap. Han studerade vid Indiana State University och blev senare skådespelare. Samma år gjorde Mary Lou Retton sin debut på den nationella scenen. Hon blev den första amerikanska kvinnan att vinna guld i all-around vid OS 1984.
      <br />
      <br />
      Universitetsgymnastiken förblev dock relevant och inflytelserik, särskilt för kvinnor. Många kvinnliga gymnaster valde att fortsätta sina karriärer på collegenivån eftersom de fick fler möjligheter och fördelar där än i den professionella cirkeln.
      <br />
      <br />
      Universitetsgymnastiken spelade därför en betydande roll i historien och utvecklingen av gymnastik i Amerika. Den producerade många idrottare som inte bara utmärkte sig inom sin sport utan också i sina studier och senare karriärer. Den bidrog också till spridningen och främjandet av gymnastik som en sport för alla, oavsett ålder, kön eller bakgrund. Universitetsgymnastiken är fortfarande en levande och dynamisk sport som lockar tusentals fans och åskådare varje år.
    </MKTypography>
  </MKBox>
</MKBox>
                                  





                                  






<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Collegiate Gymnastik Idag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Gymnastik är en av de mest populära och konkurrenskraftiga sporterna på collegenivå i Amerika. Varje år tävlar hundratals lag och tusentals idrottare om de eftertraktade titlarna och troféerna. Collegian gymnastik erbjuder en unik kombination av atletik, spektakel och akademisk excellens. Det är en sport som kräver mycket men ger också mycket tillbaka.
  </MKTypography>
  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Stora Tävlingar och Konferenser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="En gymnast från Auburn utför en barrutin under en college-tävling."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          En gymnast från Auburn i aktion
        </figcaption>
      </figure>
      Collegian gymnastik styrs av NCAA, som fastställer regler, kvalifikationer och mästerskap. NCAA delar upp collegian gymnastik i tre divisioner baserat på antalet stipendier, budget och lagnivå. Division I är den högsta divisionen, med de flesta toppgymnaster och lag som deltar. Division II och III är lägre divisioner med färre erbjudna stipendier och mer fokus på akademisk prestation.
      <br />
      <br />
      Inom varje division finns det olika konferenser som bildar regionala eller tematiska grupper av universitet. Konferenser organiserar sina egna tävlingar och mästerskap, som fungerar som förkvalificeringar för de nationella mästerskapen. De stora konferenserna för collegian gymnastik är:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          The Big Ten Conference, bestående av 14 universitet i Mellanvästern och nordöstra USA. Big Ten är en av de äldsta och mest prestigefyllda konferenserna, känd för sin akademiska och idrottsliga excellens. Big Ten har några av de bästa gymnastikprogrammen för män och kvinnor, inklusive Michigan, Minnesota, Nebraska och Penn State.
        </li>
        <li>
          The Southeastern Conference (SEC), bestående av 14 universitet i sydöstra USA. SEC är en av de mest dominerande och inflytelserika konferenserna, känd för sina passionerade och lojala fans. SEC har några av de bästa gymnastikprogrammen för kvinnor, som Alabama, Florida, Georgia och LSU.
        </li>
        <li>
          The Pac-12 Conference, bestående av 12 universitet i västra USA. Pac-12 är en av de mest innovativa och mångsidiga konferenserna, känd för sin kreativitet och kultur. Pac-12 har några av de bästa gymnastikprogrammen för män och kvinnor, inklusive California, Stanford, UCLA och Utah.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      De nationella mästerskapen är höjdpunkten på collegian gymnastik säsongen, där de bästa lagen och individerna tävlar om ära och berömmelse. De nationella mästerskapen hålls varje år i april på olika platser över hela landet. De nationella mästerskapen består av två omgångar: regionala mästerskap och nationella finaler. De regionala mästerskapen är kvalomgångarna, där 36 lag för män och 36 lag för kvinnor delas in i sex regioner. De två bästa lagen från varje region går vidare till de nationella finalerna. De nationella finalerna är de avgörande omgångarna, där 12 lag för män och 12 lag för kvinnor delas in i två sessioner. De fyra bästa lagen från varje session går vidare till den superfinalen (Super Six), där de tävlar om den nationella lagmästerskapstiteln. Dessutom delas individuella titlar ut för allroundtävlingen och apparattävlingarna.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Topprogram och Prestigefyllda Lag
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Collegian gymnastik har producerat flera toppprogram som utmärks av sin konsistens, kvalitet och tradition. Dessa program har vunnit flera nationella titlar, tränat många talangfulla idrottare och lockat talrika fans. Några av dessa program inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          UCLA Bruins: UCLA-gymnastikprogrammet är ett av de mest framgångsrika och respekterade programmen i landet. UCLA-gymnastikprogrammet har vunnit över 20 nationella titlar, inklusive flera titlar för män och majoriteten för kvinnor. Programmet har också producerat talrika olympiska och världsmästare, som Peter Vidmar, Tim Daggett, Jamie Dantzscher, Mohini Bhardwaj, Tasha Schwikert, Samantha Peszek och Kyla Ross.
        </li>
        <li>
          Oklahoma Sooners: Oklahoma gymnastikprogram är ett av de mest dominerande och imponerande programmen i nationen. Oklahoma gymnastikprogram har vunnit över 10 nationella titlar, inklusive flera titlar för män och nästan lika många för kvinnor. Programmet har också producerat många NCAA-mästare, inklusive Jonathan Horton, Jake Dalton, Maggie Nichols, Brenna Dowell och Anastasia Webb.
        </li>
        <li>
          Utah Utes: Utah-gymnastikprogrammet är ett av de mest historiska och lojala programmen i landet. Utah-gymnastikprogrammet har vunnit över 10 nationella titlar, alla för kvinnor. Programmet har också producerat legendariska tränare och idrottare, inklusive Greg Marsden, Megan Marsden, Missy Marlowe, Theresa Kulikowski, Ashley Postell och MyKayla Skinner.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Collegian gymnastik erbjuder en unik möjlighet för unga gymnaster att utöva sin sport på högsta nivå samtidigt som de får en värdefull utbildning. Collegian gymnastik är en sport som ger många utmaningar och belöningar, både på och utanför golvet. Collegian gymnastik är en sport som kan förändra ditt liv.
    </MKTypography>
  </MKBox>
</MKBox>
                                              






                                  






<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Från College till Proffs
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Gymnastik är en sport som kräver mycket talang, engagemang och uthållighet. Gymnaster börjar ofta träna i ung ålder och drömmer om en professionell karriär. Men hur gör du övergången från collegian gymnastik till professionell gymnastik? Och vilka möjligheter och utmaningar väntar dig?
  </MKTypography>
  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Vägen till Professionalism
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Collegian gymnastik är utmärkt förberedelse för professionell gymnastik eftersom det ger dig möjlighet att förbättra dina färdigheter, få erfarenhet och utvidga ditt nätverk. Många collegian gymnaster har deltagit i professionella tävlingar som Olympiska Spelen, världsmästerskapen och Världscupen. Vissa av dem har till och med vunnit medaljer.
      <br />
      <br />
      För att delta i professionella tävlingar måste collegian gymnaster dock följa vissa regler och villkor. De måste behålla sin amatörstatus, vilket innebär att de inte kan tjäna pengar på sin sport. De får heller inte ha sponsorer eller agenter om de inte får tillstånd från NCAA. De måste också uppfylla universitetets akademiska krav, vilket ibland kan vara svårt att balansera med sin träningsplan.
      <br />
      <br />
      Dessutom måste collegian gymnaster också ta hänsyn till sin ålder och fysiska kondition. Gymnastik är en sport som ställer mycket krav på kroppen och leder ofta till skador. Många gymnaster når sin topp runt tjugoårsåldern och kan ha svårt att behålla sin nivå därefter. Vissa gymnaster väljer att ge upp sina professionella ambitioner och fokusera på sina studier eller karriärer utanför sporten.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professionella Karriärer och Möjligheter
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      För de som bestämmer sig för att fortsätta med professionell gymnastik finns det olika möjligheter och chanser att följa sin passion. En av de mest prestigefyllda och lukrativa alternativen är att tävla i Olympiska Spelen, som hålls vart fjärde år. OS är höjdpunkten på vilken idrottskarriär som helst och erbjuder chansen att representera sitt land, tävla mot världens bästa gymnaster och vinna en medalj. OS ger också berömmelse och erkännande som kan leda till sponsoravtal, medial uppmärksamhet och andra fördelar.
      <br />
      <br />
      Ett annat alternativ är att delta i världsmästerskapen, som hålls varje år (utom under olympiska år). Världsmästerskapen är en betydande referenspunkt för nivån och utvecklingen av gymnastik över hela världen. Världsmästerskapen erbjuder också möjligheten att kvalificera sig för OS eller få en wildcard-inbjudan. Världsmästerskapen är en källa till stolthet och prestige som kan leda till prispengar, stipendier och andra belöningar.
      <br />
      <br />
      Ett tredje alternativ är att tävla i Världscupen, som består av en serie individuella tävlingar runt om i världen. Världscupen är ett attraktivt alternativ för gymnaster som letar efter mer flexibilitet och variation i sin idrottskarriär. Världscupen erbjuder också chansen att utforska olika länder och kulturer, skapa nya vänskaper och bredda dina vyer. Världscupen är också en källa till skoj och äventyr som kan leda till reseersättningar, bonusar och andra incitament.
      <br />
      <br />
      Förutom dessa internationella tävlingar finns det andra professionella möjligheter för gymnaster, såsom:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Genomföra kliniker, workshops eller demonstrationer där du delar med dig av din kunskap och erfarenhet med andra gymnaster, tränare eller fans.
        </li>
        <li>
          Arbeta som tränare, tränare eller rådgivare och hjälpa andra gymnaster att uppnå sina mål, förbättra sin teknik eller lösa deras problem.
        </li>
        <li>
          Arbeta som kommentator, analytiker eller journalist och erbjuda din åsikt och insikt om gymnastik, tävlingar eller aktuella händelser.
        </li>
        <li>
          Arbeta som modell, skådespelare eller influencer och använda din utseende, personlighet eller popularitet för att marknadsföra produkter, tjänster eller varumärken.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Professionell gymnastik är en spännande och utmanande karriär som erbjuder många möjligheter och möjligheter. Det är dock också en krävande och osäker karriär som kommer med risker och hinder. Att välja en karriär inom professionell gymnastik kräver både ditt hjärta och ditt huvud.
    </MKTypography>
  </MKBox>
</MKBox>
                                     






                                  





<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationella Influenser
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Gymnastik är en sport utan gränser. Gymnastik är en sport som samlar människor från hela världen, inspirerar och berikar dem. Gymnastik är en sport påverkad av olika kulturer, stilar och traditioner. I detta kapitel kommer vi att titta på de internationella influenserna på collegian gymnastik i Amerika och hur dessa influenser har format och omvandlat collegian gymnastik.
  </MKTypography>
  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Utländska Influenser och Utbyte
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Stanford gymnast in action on the pommel horse at a college competition."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          En gymnast från Stanford i aktion
        </figcaption>
      </figure>
      I den fascinerande världen av collegian gymnastik i Amerika spelar internationellt utbyte en avgörande roll. Visste du att utländska gymnaster bidrar med en unik dynamik och tekniker som höjer tävlingsnivån? Dessa idrottare för med sig innovativa stilar och metoder som inspirerar och utmanar deras amerikanska motparter. Detta gör inte bara sporten mer mångsidig utan också mer konkurrenskraftig.
      <br />
      <br />
      Ett exempel på detta är påverkan av gymnaster från Östeuropa på 1980- och 1990-talet. Deras ankomst till USA förde med sig nya tekniker och träningsmetoder, vilket ledde till en betydande utveckling inom amerikansk gymnastik. Dessa influenser är fortfarande synliga idag i stilen och tekniken hos många toppgymnaster.
      <br />
      <br />
      Mångfalden som internationella gymnaster bidrar med berikar sportskulturen på campus. Det skapar en smältdegel av kulturer och idéer som bara gynnar sporten. Dessutom erbjuder det amerikanska studenter och tränare möjligheten att lära sig från olika kulturella perspektiv och slipa sina egna färdigheter och taktiker.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikaner Utomlands
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Å andra sidan har amerikanska gymnaster också haft en betydande inverkan på den internationella gymnastikvärlden. Många amerikanska studentidrottare har uppnått internationell framgång efter sina collegianska karriärer. Deras prestationer vid världsmästerskap och Olympiska Spelen beror ofta på den träning och erfarenhet de fått inom collegianska kretsen.
      <br />
      <br />
      Dessa gymnaster fungerar som ambassadörer för amerikansk gymnastikkultur. Deras framgångar inspirerar unga gymnaster över hela världen att sträva efter att vara en del av ett amerikanskt universitetsteam. Detta skapar en positiv cykel där talang och kunskap utbyts över gränserna.
      <br />
      <br />
      De vägar dessa gymnaster har tagit visar hur collegian gymnastik kan vara en utmärkt grund för en internationell karriär. De erfarenheter de får under sina studier - från att delta i högnivåtävlingar till att träna under topptränare - förbereder dem för utmaningarna i den professionella idrottsvärlden.
    </MKTypography>
  </MKBox>
</MKBox>






                                  







<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Idrottsstipendier och Framtiden för Idrottare
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrottsstipendier erbjuder många möjligheter för unga idrottare att visa upp sin talang och utveckla sina färdigheter. En av de mest betydelsefulla möjligheterna är att få ett idrottsstipendium för att studera och tävla på collegenivå. I det här kapitlet kommer vi att diskutera vikten av idrottsstipendier och hur Sport Scholarships America kan hjälpa dig att förverkliga din dröm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vikten av Stipendier
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ett idrottsstipendium är ekonomiskt stöd som tillhandahålls av ett universitet till en idrottare för att kombinera studier och idrott. Det kan täcka kostnaderna för undervisning, böcker, boende, måltider och andra utgifter. Dessutom kan det erbjuda fördelar som akademisk handledning, sjukvård, träningsanläggningar och utrustning.
      <br />
      <br />
      Att få ett idrottsstipendium är konkurrenskraftigt, med många faktorer som spelar en roll i tilldelningsprocessen, inklusive akademiska prestationer, idrottsprestationer, personlighet, motivation, ledarskap och anpassningsförmåga. Därför är det viktigt att förbereda sig väl och skilja sig från konkurrensen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hur Sport Scholarships America Kan Hjälpa
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotyp för Sport Scholarships America, med sportutrustning och en studentmössa, som representerar fokus på idrottsstipendier för idrottare."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotyp för Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America är en organisation specialiserad på att vägleda unga idrottstalanger till idrottsstipendier. Med passionerade och erfarna experter stöder Sport Scholarships America idrottare i varje steg av resan, från initial orientering till slutlig placering. Deras tjänster inkluderar:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intagningsintervju för att diskutera idrottarens önskemål, möjligheter och förväntningar.</li>
        <li>En professionell utvärdering av idrottarens akademiska och idrottsliga nivå.</li>
        <li>Ett omfattande nätverk av kontakter med tränare, universitet och högskolor.</li>
        <li>Effektiv marknadsföring av idrottarens profil och videor till potentiella tränare.</li>
        <li>Expertrådgivning om de bästa alternativen baserat på idrottarens preferenser, mål och budget.</li>
        <li>Förhandling om de bästa stipendieerbjudandena för idrottaren.</li>
        <li>Hjälp med att ansöka om nödvändiga dokument, såsom visum, betyg, examensbevis och antagningsprov.</li>
        <li>Förberedelse för idrottarens liv under stipendieperioden, inklusive kultur, språk, regler och tips.</li>
        <li>Uppföljning och uppföljning under stipendieperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vill du utforska möjligheter till stipendier och uppnå dina idrottsliga och akademiska mål? <Link to="/sv/Tjänster-Produkter">Se det fullständiga utbudet</Link> av tjänster som erbjuds av Sport Scholarships America och boka en konsultation idag.
    </MKTypography>
  </MKBox>
</MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;