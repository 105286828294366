/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


// @mui material components
import React, { useState } from 'react'; // Step 1: Import useState


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Logos/NCAA.png";
import post3 from "assets/images/Logos/NAIA.png";



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {

  const articleStructure = [
    { id: 'intro', title: 'NJCAA: La Puerta de Entrada a Grandes Universidades y la NCAA', isSubheader: false },
  
    { id: 'Capítulo 1', title: 'Origen y Crecimiento de la NJCAA', isSubheader: true },
    { id: '1.1', title: 'Las Tres Divisiones de la NJCAA', isSubheader: true },
    { id: '1.2', title: 'Transición a Grandes Universidades y la NCAA', isSubheader: true },
  
    { id: 'Capítulo 2', title: 'El Papel de los Académicos', isSubheader: true },
    { id: '2.1', title: 'Finanzas y Visibilidad', isSubheader: true },
    { id: '2.2', title: 'Desafíos y el Futuro', isSubheader: true },
    { id: '2.3', title: 'Conclusión', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
     <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contents
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Ocultar</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="intro" mb={3}>

<MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>NJCAA: La Puerta de Entrada a Grandes Universidades y la NCAA</MKTypography>
</MKBox>

<MKBox id="Capítulo 1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Origen y Crecimiento de la NJCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
La Asociación Nacional Deportiva Junior Universitaria (NJCAA, por sus siglas en inglés) fue fundada en 1938 y desde entonces se ha convertido en un jugador destacado en el panorama deportivo universitario estadounidense. La organización brinda oportunidades deportivas para colegios comunitarios, instituciones más pequeñas de dos años que ofrecen a los estudiantes una vía hacia la educación superior y la excelencia atlética.

<br />
<br />
En sus primeros años, la NJCAA tenía un alcance limitado, pero ha crecido y se ha adaptado para satisfacer las necesidades de un cuerpo estudiantil diverso y en evolución. El enfoque está en proporcionar acceso a la competencia atlética al tiempo que se promueve el progreso académico.

</MKTypography>

<MKBox id="1.1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Las Tres Divisiones de la NJCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
La NJCAA está dividida en tres divisiones, cada una diseñada para satisfacer las diferentes necesidades y oportunidades de los estudiantes-atletas:

<br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
División I:{' '}
</MKTypography>
Esta división incluye escuelas capaces de ofrecer becas atléticas completas, lo que resulta en un nivel más alto de competencia. Los estudiantes-atletas aquí tienen la oportunidad de desarrollar sus habilidades y competir a un nivel intensivo, a menudo como un trampolín hacia las divisiones de la NCAA.

<br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
División II:{' '}
</MKTypography>
Aquí encontramos un equilibrio entre la competencia atlética y las responsabilidades académicas, con escuelas que pueden ofrecer becas parciales. Esto asegura un equilibrio saludable y brinda a los estudiantes-atletas la oportunidad de destacarse en su deporte mientras se preparan para el éxito académico.   <br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
División III:{' '}
</MKTypography>
En esta división, el énfasis está en proporcionar oportunidades deportivas competitivas sin becas atléticas. Los estudiantes-atletas tienen la oportunidad de participar en deportes mientras participan plenamente en la vida académica, lo que resulta en una experiencia universitaria integral.  </MKTypography>


<MKBox id="1.2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Transición a Grandes Universidades y la NCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
La NJCAA juega un papel clave como trampolín para los estudiantes-atletas que desean continuar sus carreras académicas y deportivas en instituciones más grandes de cuatro años, incluidas aquellas en la NCAA. Muchos estudiantes-atletas comienzan su viaje en la NJCAA para desarrollar sus habilidades, mejorar las calificaciones académicas y destacarse ante reclutadores de programas más grandes.


<br />
<br />
Al demostrar su valía en el entorno competitivo de la NJCAA, los estudiantes-atletas pueden atraer la atención de las universidades de la NCAA y potencialmente ganar becas para continuar su educación y carrera deportiva. Este proceso no solo es beneficioso para los atletas mismos, sino que también contribuye a fortalecer los programas deportivos en todo el país.


</MKTypography>



                                  







<MKBox id="Capítulo 2" mb={3}>
  
  
  <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              El Papel de los Académicos
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              La NJCAA está firmemente comprometida con el éxito académico y el desarrollo de sus estudiantes atletas. Hay rigurosos estándares académicos, y las escuelas afiliadas brindan orientación esencial para garantizar que los atletas sobresalgan tanto en el aula como en su deporte. Esto ilustra el papel crucial de un enfoque equilibrado dentro del panorama deportivo universitario, aunque el nivel puede ser ligeramente inferior al de algunas otras instituciones educativas.
<br />
                <br />
                </MKTypography>

                <MKBox id="2.1" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Finanzas y Visibilidad
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              A pesar de que los recursos financieros son más limitados en comparación con la NCAA, la NJCAA juega un papel crucial en el apoyo a los programas atléticos y en la promoción de oportunidades para los estudiantes atletas. La organización también trabaja en aumentar su visibilidad y alcance con el objetivo de llegar e inspirar a más estudiantes y atletas.


<br />
                </MKTypography>

                <MKBox id="2.2" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Desafíos y el Futuro
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              La NJCAA, al igual que otras organizaciones deportivas, enfrenta desafíos que incluyen navegar por cuestiones de igualdad en el deporte y apoyar la salud mental de los estudiantes atletas. Sin embargo, con su compromiso de promover oportunidades y apoyar a los estudiantes atletas, la organización está bien posicionada para seguir teniendo un impacto positivo.



                </MKTypography>

                <MKBox id="2.3" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Conclusión
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              La Asociación Atlética Junior de Colegios Nacionales es un componente esencial del panorama deportivo universitario estadounidense, proporcionando oportunidades únicas para que los estudiantes atletas compitan, aprendan y crezcan. Con su compromiso con la excelencia académica y atlética, la NJCAA sigue siendo una fuerza influyente en la formación del futuro de los jóvenes atletas en Estados Unidos.








<br />


                </MKTypography>


              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="NCAA"
                    description="NCAA: El Corazón del Deporte Universitario en Estados Unidos"
                    action={{
                      type: "internal",
                      route: "/es/NCAA",
                      color: "info",
                      label: "Leer Más",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="NAIA"
                    description="La NAIA: Una Ruta Alternativa en el Deporte Universitario en América"
                    action={{
                      type: "internal",
                      route: "/es/NAIA",
                      color: "info",
                      label: "Leer Más",
                     }}
                    />
                  </Grid>
                </Grid>
  
              </Grid>
          </Grid>
        </Container>
      </MKBox>
    );
  }
  
  export default Beschrijving;