/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Waterpol1.jpeg";
import Image2 from "assets/images/Sports_Images/Waterpolo2.jpeg";
import Image3 from "assets/images/Sports_Images/Waterpolo3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Water Polo Scholarships in America: How to Study and Play in the Land of Stars and Stripes', isSubheader: false },
  
    { id: 'Chapter 1', title: '1. History and Development of Water Polo in America', isSubheader: false },
    { id: '1.1', title: '1.1 Early Years', isSubheader: true },
    { id: '1.2', title: '1.2 Growth and Professionalization', isSubheader: true },
  
    { id: 'Chapter 2', title: '2. University Water Polo Today', isSubheader: false },
    { id: '2.1', title: '2.1 Key Competitions and Conferences', isSubheader: true },
    { id: '2.2', title: '2.2 Top Programs and Prestigious Teams', isSubheader: true },
  
    { id: 'Chapter 3', title: '3. From College to the Pros', isSubheader: false },
    { id: '3.1', title: '3.1 The Path to Professionalism', isSubheader: true },
    { id: '3.2', title: '3.2 Professional Leagues', isSubheader: true },
  
    { id: 'Chapter 4', title: '4. International Influences and Competitions', isSubheader: false },
    { id: '4.1', title: '4.1 Foreign Influences', isSubheader: true },
    { id: '4.2', title: '4.2 Americans in International Competitions', isSubheader: true },
  
    { id: 'Chapter 5', title: '5. Water Polo Scholarships and the Future', isSubheader: false },
    { id: '5.1', title: '5.1 The Importance of Scholarships', isSubheader: true },
    { id: '5.2', title: '5.2 How Sport Scholarships America Can Help', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Content
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Hide</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Show</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>

























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Water Polo Scholarships in America: How to Study and Play in the Land of Stars and Stripes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Stadium full of fans awaiting a college water polo match."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Calm before a match
      </figcaption>
    </figure>
    Water polo originated in Europe in the late 19th century as a form of water rugby and quickly gained popularity in various countries, including America. The first official match in America was played in 1888 between two clubs in New York. Since then, water polo has developed significantly in America, both at the professional and university levels.
    <br />
    <br />
    University water polo is one of the primary factors in the development of water polo players in America. Many players begin their careers in high school and then move on to college, where they have the opportunity to compete against the best teams and players in the country. University water polo also provides an excellent academic education, allowing players to combine their passion for the sport with their educational goals.
    <br />
    <br />
    But how can you, as a Dutch student, study and play in America? That's where Sportbeursamerika.nl can help. Sportbeursamerika.nl is an organization specialized in assisting students in obtaining water polo scholarships in America. Sportbeursamerika.nl has a team of experts who can guide you through every step of the process, from finding the right university to handling all the practical matters.
    <br />
    <br />
    If you are interested in obtaining a water polo scholarship in America, read on to learn more about this exciting opportunity.
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 1: History and Development of Water Polo in America
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Water polo has a long and rich history in America. It is one of the oldest Olympic sports, first played in the 1900 Olympic Games in Paris, where America also participated. Since then, America has competed in every Olympic water polo competition, except in 1976 and 1980 when it withdrew in protest against the political situations in South Africa and the Soviet Union, respectively.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Early Years
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      The first water polo clubs in America were established in New York and Boston in the late 19th century. They played according to the English rules, which were much rougher and more violent than the modern rules. The sport quickly became popular among immigrants from Europe, especially from Ireland and Scotland. The first national championship was held in 1890 and was won by the Sydenham Swimmers Club.
      <br />
      <br />
      The first university teams were formed in the early 20th century, especially on the East Coast. The Ivy League universities such as Harvard, Yale, and Princeton dominated the sport until the 1920s. The first intercollegiate competition was organized in 1912 and was won by Princeton.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Growth and Professionalization
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      In the 1920s, water polo began to spread to other parts of the country, especially to California. A new style of play, focused on speed, agility, and shooting rather than physical contact, developed there. California teams quickly became the best in the country, winning most national and intercollegiate titles.
      <br />
      <br />
      In the 1930s, the first professional leagues in America emerged, such as the American Water Polo League and the Pacific Coast Water Polo League. These leagues attracted many spectators and media attention, raising the level and popularity of the sport. Many professional players were also university players or graduates who continued their careers after their studies.
      <br />
      <br />
      One of the key figures in the history of American water polo was James "Jimmy" Smith, considered the "father of modern water polo." He was a player, coach, referee, organizer, and promoter of the sport. He played for various clubs and universities, including UCLA, where he later became a coach. He led the American team to two Olympic bronze medals in 1924 and 1932. He was also one of the founders of the International Swimming Hall of Fame, where he was inducted in 1965.
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  









<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 2: University Water Polo Today
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    University water polo is one of the most prestigious and competitive sports in America. It offers a unique opportunity for students to combine their academic and athletic ambitions and to compete against the best players in the country. University water polo is also a significant source of talent for the national team and professional leagues.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Key Competitions and Conferences
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Action in the water: player shoots at the goal during a water polo match."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Players in action
        </figcaption>
      </figure>
      University water polo is regulated by the National Collegiate Athletic Association (NCAA), which divides the sport into three divisions based on the level and number of scholarships available. Division I is the highest division, consisting of approximately 30 teams, primarily from California. Division II and III each have around 20 teams, more spread across the country.
      <br />
      <br />
      Each year, a national championship is held for each division, with the best teams from each conference competing for the title. A conference is a group of universities that are geographically or historically connected and regularly compete against each other. The major conferences for water polo include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          The Mountain Pacific Sports Federation (MPSF), which consists of eight teams from California, including UCLA, Stanford, USC, and Berkeley. This conference is considered the strongest and most prestigious in the country and has won the most national titles.
        </li>
        <li>
          The Western Water Polo Association (WWPA), which consists of nine teams from California, Colorado, Utah, and Hawaii. This conference is the second strongest in the country and has also won some national titles.
        </li>
        <li>
          The Collegiate Water Polo Association (CWPA), which consists of 18 teams from the East Coast, including Harvard, Princeton, Brown, and Navy. This conference is the third strongest in the country and has occasionally won a national title.
        </li>
        <li>
          The Southern California Intercollegiate Athletic Conference (SCIAC), which consists of nine teams from Southern California, including Pomona-Pitzer, Claremont-Mudd-Scripps, and Occidental. This conference is the fourth strongest in the country and primarily competes in Division III.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Top Programs and Prestigious Teams
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      University water polo has several leading programs known for their excellent performances, both in sports and academics. These programs attract many talented players who benefit from the high-quality facilities, coaching, and education they provide. Some of these programs are:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          The University of California, Los Angeles (UCLA) is known for its exceptional water polo program, which ranks among the most successful in history. With an impressive number of national titles for both men and women's teams, the program holds a prominent place in the sport. The matches are played at the Spieker Aquatics Center, with a capacity of 2,500 spectators. The UCLA team is famous for its fast and dynamic style of play and has produced a significant number of Olympic medalists.
        </li>
        <li>
          Stanford University also boasts a prestigious water polo program with numerous national titles for both men's and women's teams. The teams play their home games at the Avery Aquatic Center, which accommodates 2,530 spectators. Stanford's water polo teams are known for their technical and tactical skills and have also contributed to the development of many Olympic medalists.
        </li>
        <li>
          The University of Southern California (USC) also has a strong water polo program with multiple national championships for both men and women. Their home base is the Uytengsu Aquatics Center, with a capacity for 2,500 spectators. The USC team is characterized by a physical and aggressive style of play and has also trained a significant number of Olympic medalists.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  









<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 3: From College to the Pros
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    University water polo is not only a great way to study and play in America but also a possible stepping stone to a professional career. Many university players go on to play in professional leagues, both in America and abroad, after graduating. Some of them are also selected for the national team, which participates in international tournaments such as the Olympics, World Championships, and the World League.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 The Path to Professionalism
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      While water polo is a popular and competitive sport in America, it is still not as commercial and lucrative as some other sports like basketball, football, or baseball. Therefore, there are not many professional leagues or teams in America, and salaries and prize money are relatively low. Most professional players also have another job or source of income alongside their water polo career.
      <br />
      <br />
      The main professional league in America is the National Water Polo League (NWPL), which was established in 2018 as a successor to the American Water Polo League (AWPL). The NWPL consists of eight teams, each composed of 15 players. The teams play a regular season of 14 games, followed by a playoff tournament to determine the champion. The current champion is the Los Angeles Water Polo Club, which won the title in 2022.
      <br />
      <br />
      Most players in the NWPL are former university players or graduates who continue their careers after their studies. Some of them are also members of the national team, which regularly trains and plays at the Olympic Training Center in Colorado Springs. The national team is coached by Dejan Udovicic, a former Serbian player and coach who has been at the helm since 2013.
      <br />
      <br />
      One of the most successful alumni of university water polo is Tony Azevedo, who is considered one of the greatest players of all time. He played for Stanford University, where he was named NCAA Player of the Year four times. He also played for several professional teams in Europe and America, including Brescia (Italy), Olympiacos (Greece), and Long Beach Shore (America). He also competed in five Olympics, winning a silver medal in 2008. He is often referred to as "The Savior" because he often scored decisive goals for his team.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professional Leagues
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      In addition to the NWPL, there are other professional leagues in America, such as the Major League Water Polo (MLWP) and the Premier Water Polo League (PWPL). However, these leagues are less established and less popular than the NWPL and have fewer teams and players. They are also not recognized by USA Water Polo, the national federation responsible for organizing and regulating water polo in America.
      <br />
      <br />
      Many American players, therefore, choose to continue their professional careers abroad, where there are more opportunities and better conditions. Europe is the primary destination for American water polo players because the continent boasts some of the strongest and most prestigious leagues in the world. Some of these leagues include:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          The LEN Champions League, considered the top club competition in Europe. It consists of 16 teams from various countries competing for the European title. The current champion is Pro Recco (Italy), who won the title in 2021.
        </li>
        <li>
          The LEN Euro Cup, regarded as the second club competition in Europe. It comprises 32 teams from different countries vying for a spot in the Champions League. The current champion is Orvosegyetem SC (Hungary), who won the title in 2021.
        </li>
        <li>
          National leagues organized by the respective federations of each country. Some of the strongest and most popular national leagues include Serie A1 (Italy), Liga Premaat (Spain), A1 Ethniki (Greece), and OB I (Hungary).
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  






<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 4: International Influences and Competitions
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Water polo is a global sport played and followed by millions of people around the world. It is also a sport that constantly evolves and innovates, thanks to the influence of various cultures, styles, and traditions. American water polo is no exception, as it has learned and benefited from international water polo, both at the club and national levels.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Foreign Influences
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="NCAA water polo championship match"
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          A match for the NCAA championship
        </figcaption>
      </figure>
      As we have seen, water polo originated in Europe and spread to other continents, including America. Therefore, European water polo has had a significant influence on American water polo, especially in terms of technique, tactics, and strategy. Many American players and coaches have improved their skills and knowledge by playing or training in Europe or learning from European players and coaches.
      <br />
      <br />
      One of the most influential European countries for American water polo is Hungary, which is considered the most successful water polo nation in the world. Hungary has won 15 Olympic gold medals, more than any other country. Hungary is known for its creative and intelligent playing style, which is based on strong ball handling, quick passing, and precise shooting. Many Hungarian players and coaches have left their mark on American water polo, such as Tibor Benedek, Zoltan Szecsi, and Attila Banhidy.
      <br />
      <br />
      Another influential European country for American water polo is Serbia, which is also one of the leading water polo nations globally. Serbia has won 7 Olympic gold medals, with the most recent one in 2016. Serbia is known for its physical and aggressive playing style, which relies on strong defense, fast counterattacks, and powerful shooting. Many Serbian players and coaches have shared their experience and expertise with American water polo, including Dejan Udovicic, Andrija Prlainovic, and Filip Filipovic.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Americans in International Competitions
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      In addition to learning from international water polo, American water polo has also contributed significantly to international water polo, especially in terms of performance, innovation, and promotion. Many American players and coaches have participated in international competitions, both at the club and national levels, where they showcased their talents and enhanced their reputation.
      <br />
      <br />
      One of the most important international water polo competitions is the Olympic Games, held every four years. The American team has always participated in this competition, except in 1976 and 1980. The American team has won a total of 12 Olympic medals: 3 gold, 4 silver, and 5 bronze. The most recent medal was a silver medal for the men in 2008. The American team is currently ranked fourth in the world.
      <br />
      <br />
      Another significant international competition for water polo is the World Championships, held every two years. The American team has also consistently participated in this competition since its first edition in 1973. The American team has won a total of 9 World Championship medals: 2 gold, 3 silver, and 4 bronze. The most recent medal was a bronze medal for the women in 2019. The American team is currently ranked third in the world.
      <br />
      <br />
      A third major international competition for water polo is the World League, held annually. The American team has also consistently participated in this competition since its first edition in 2002. The American team has won a total of 10 World League medals: 3 gold, 4 silver, and 3 bronze. The most recent medal was a gold medal for the women in 2021. The American team is currently ranked second in the world.
    </MKTypography>
  </MKBox>
</MKBox>
         






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 5: Sports Scholarships and the Future of Athletes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sports scholarships offer numerous opportunities for young athletes to showcase their talent and develop their skills. One of the most significant opportunities is obtaining a sports scholarship to study and compete at the college level. In this chapter, we will discuss the importance of sports scholarships and how Sport Scholarships America can help you make your dream come true.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 The Importance of Scholarships
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A sports scholarship is financial support provided by a university to a student-athlete to combine studies and sports. It can cover the costs of tuition, books, housing, meals, and other expenses. Additionally, it may offer benefits such as academic tutoring, medical care, training facilities, and equipment.
      <br />
      <br />
      Obtaining a sports scholarship is competitive, with many factors playing a role in the awarding process, including academic achievements, athletic performance, personality, motivation, leadership, and adaptability. Therefore, it's essential to prepare well and distinguish yourself from the competition.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 How Sport Scholarships America Can Help
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo of Sport Scholarships America, featuring sports equipment and a graduation cap, representing the focus on sports scholarships for student-athletes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo of Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America is an organization specialized in guiding young sports talents to sports scholarships. With passionate and experienced experts, Sport Scholarships America supports student-athletes at every step of the journey, from initial orientation to final placement. Their services include:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>A personal intake interview to discuss the student-athlete's wishes, possibilities, and expectations.</li>
        <li>A professional evaluation of the student-athlete's athletic and academic level.</li>
        <li>An extensive network of contacts with coaches, universities, and colleges.</li>
        <li>Effective promotion of the student-athlete's profile and videos to potential coaches.</li>
        <li>Expert advice on the best options based on the student-athlete's preferences, goals, and budget.</li>
        <li>Negotiation of the best scholarship offers on behalf of the student-athlete.</li>
        <li>Assistance in applying for necessary documents, such as visa, transcript, diploma, and admission tests.</li>
        <li>Preparation for student-athletes' life during their scholarship, including culture, language, rules, and tips.</li>
        <li>Aftercare and follow-up during the scholarship period.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Want to explore scholarship opportunities and achieve your athletic and academic goals? <Link to="/Services-Products">View the full range</Link> of services offered by Sport Scholarships America and schedule a consultation today.
    </MKTypography>
  </MKBox>
</MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;