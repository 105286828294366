/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Waterpol1.jpeg";
import Image2 from "assets/images/Sports_Images/Waterpolo2.jpeg";
import Image3 from "assets/images/Sports_Images/Waterpolo3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
// Define your article structure with headers and subheaders for navigation
const articleStructure = [
  { id: 'intro', title: 'Vandpolostipendier i Amerika: Sådan Studerer og Spiller du i Landet med Stjerner og Striper', isSubheader: false },

  { id: 'Chapter 1', title: '1. Historie og Udvikling af Vandpolo i Amerika', isSubheader: false },
  { id: '1.1', title: '1.1 De Tidlige År', isSubheader: true },
  { id: '1.2', title: '1.2 Vækst og Professionalisering', isSubheader: true },

  { id: 'Chapter 2', title: '2. Universitetsvandpolo i Dag', isSubheader: false },
  { id: '2.1', title: '2.1 Vigtige Konkurrencer og Konferencer', isSubheader: true },
  { id: '2.2', title: '2.2 Topprogrammer og Prestigefyldte Hold', isSubheader: true },

  { id: 'Chapter 3', title: '3. Fra College til Professionelt Niveau', isSubheader: false },
  { id: '3.1', title: '3.1 Vejen til Professionalisme', isSubheader: true },
  { id: '3.2', title: '3.2 Professionelle Ligaer', isSubheader: true },

  { id: 'Chapter 4', title: '4. Internationale Indflydelser og Konkurrencer', isSubheader: false },
  { id: '4.1', title: '4.1 Udenlandske Indflydelser', isSubheader: true },
  { id: '4.2', title: '4.2 Amerikanere i Internationale Konkurrencer', isSubheader: true },

  { id: 'Chapter 5', title: '5. Vandpolostipendier og Fremtiden', isSubheader: false },
  { id: '5.1', title: '5.1 Vigtigheden af Stipendier', isSubheader: true },
  { id: '5.2', title: '5.2 Hvordan Sport Scholarships America Kan Hjælpe', isSubheader: true },
];

  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Vandpolostipendier i Amerika: Sådan Studerer og Spiller du i Landet med Stjerner og Striper
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Stadion fyldt med fans venter på en collega vandpolokamp."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Stilhed før en kamp
      </figcaption>
    </figure>
    Vandpolo opstod i Europa i slutningen af det 19. århundrede som en form for vandrugby og opnåede hurtigt popularitet i forskellige lande, herunder Amerika. Den første officielle kamp i Amerika blev spillet i 1888 mellem to klubber i New York. Siden da har vandpolo udviklet sig betydeligt i Amerika, både på professionelt og universitetsniveau.
    <br />
    <br />
    Universitetsvandpolo er en af hovedfaktorerne i udviklingen af vandpolospillere i Amerika. Mange spillere begynder deres karriere på gymnasiet og går derefter videre til college, hvor de har mulighed for at konkurrere mod de bedste hold og spillere i landet. Universitetsvandpolo giver også en fremragende akademisk uddannelse, der giver spillerne mulighed for at kombinere deres passion for sporten med deres uddannelsesmæssige mål.
    <br />
    <br />
    Men hvordan kan du, som hollandsk studerende, studere og spille i Amerika? Det er her Sportbeursamerika.nl kan hjælpe. Sportbeursamerika.nl er en organisation specialiseret i at hjælpe studerende med at opnå vandpolostipendier i Amerika. Sportbeursamerika.nl har et team af eksperter, der kan guide dig gennem hvert trin i processen, lige fra at finde det rette universitet til at håndtere alle praktiske forhold.
    <br />
    <br />
    Hvis du er interesseret i at opnå et vandpolostipendium i Amerika, så læs videre for at lære mere om denne spændende mulighed.
  </MKTypography>
  {/* Tilføj punktopstillet liste eller andet indhold efter behov */}
</MKBox>
{/* Hovedkapitler og underkapitler med pladsholdertekst */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Historie og Udvikling af Vandpolo i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Vandpolo har en lang og rig historie i Amerika. Det er en af de ældste olympiske sportsgrene, først spillet i OL i 1900 i Paris, hvor Amerika også deltog. Siden da har Amerika deltaget i hvert eneste olympiske vandpolomesterskab, undtagen i 1976 og 1980, hvor det trak sig i protest mod de politiske situationer i Sydafrika og Sovjetunionen, henholdsvis.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidlige År
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      De første vandpoloklubber i Amerika blev etableret i New York og Boston i slutningen af det 19. århundrede. De spillede ifølge de engelske regler, der var meget grovere og mere voldelige end de moderne regler. Sporten blev hurtigt populær blandt indvandrere fra Europa, især fra Irland og Skotland. Det første nationale mesterskab blev afholdt i 1890 og blev vundet af Sydenham Swimmers Club.
      <br />
      <br />
      De første universitetshold blev dannet i begyndelsen af det 20. århundrede, især på østkysten. Ivy League-universiteter som Harvard, Yale og Princeton dominerede sporten indtil 1920'erne. Det første interkollegiale mesterskab blev organiseret i 1912 og blev vundet af Princeton.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Vækst og Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      I 1920'erne begyndte vandpolo at sprede sig til andre dele af landet, især til Californien. En ny spillestil, fokuseret på hastighed, smidighed og skydning frem for fysisk kontakt, udviklede sig der. Californiske hold blev hurtigt de bedste i landet og vandt de fleste nationale og interkollegiale titler.
      <br />
      <br />
      I 1930'erne opstod de første professionelle ligaer i Amerika, såsom American Water Polo League og Pacific Coast Water Polo League. Disse ligaer tiltrak mange tilskuere og medieopmærksomhed, hvilket hævede niveauet og populariteten af sporten. Mange professionelle spillere var også universitetsspiller eller kandidater, der fortsatte deres karriere efter deres studier.
      <br />
      <br />
      En af de centrale figurer i Amerikansk vandpolos historie var James "Jimmy" Smith, der betragtes som "den moderne vandpolos fader". Han var spiller, træner, dommer, organisator og promotor for sporten. Han spillede for forskellige klubber og universiteter, herunder UCLA, hvor han senere blev træner. Han førte det amerikanske hold til to olympiske bronze medaljer i 1924 og 1932. Han var også en af grundlæggerne af International Swimming Hall of Fame, hvor han blev optaget i 1965.
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  








<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Universitetsvandpolo i Dag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Universitetsvandpolo er en af de mest prestigefyldte og konkurrencedygtige sportsgrene i Amerika. Det tilbyder en unik mulighed for studerende til at kombinere deres akademiske og atletiske ambitioner og konkurrere mod de bedste spillere i landet. Universitetsvandpolo er også en betydelig kilde til talent for det nationale hold og professionelle ligaer.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Nøglekonkurrencer og Konferencer
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Handling i vandet: spiller skyder mod målet under en vandpolokamp."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Spillere i aktion
        </figcaption>
      </figure>
      Universitetsvandpolo reguleres af National Collegiate Athletic Association (NCAA), som opdeler sporten i tre divisioner baseret på niveauet og antallet af stipendier til rådighed. Division I er den højeste division, bestående af cirka 30 hold, primært fra Californien. Division II og III har hver omkring 20 hold, mere spredt ud over landet.
      <br />
      <br />
      Hvert år afholdes der et nationalt mesterskab for hver division, hvor de bedste hold fra hver konference konkurrerer om titlen. En konference er en gruppe af universiteter, der geografisk eller historisk er forbundet og regelmæssigt konkurrerer mod hinanden. De største konferencer for vandpolo inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          The Mountain Pacific Sports Federation (MPSF), som består af otte hold fra Californien, herunder UCLA, Stanford, USC og Berkeley. Denne konference betragtes som den stærkeste og mest prestigefyldte i landet og har vundet de fleste nationale titler.
        </li>
        <li>
          The Western Water Polo Association (WWPA), som består af ni hold fra Californien, Colorado, Utah og Hawaii. Denne konference er den næststærkeste i landet og har også vundet nogle nationale titler.
        </li>
        <li>
          The Collegiate Water Polo Association (CWPA), som består af 18 hold fra østkysten, herunder Harvard, Princeton, Brown og Navy. Denne konference er den tredjestærkeste i landet og har lejlighedsvis vundet en national titel.
        </li>
        <li>
          The Southern California Intercollegiate Athletic Conference (SCIAC), som består af ni hold fra det sydlige Californien, herunder Pomona-Pitzer, Claremont-Mudd-Scripps og Occidental. Denne konference er den fjerdestærkeste i landet og konkurrerer primært i Division III.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Topprogrammer og Prestigefyldte Hold
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Universitetsvandpolo har adskillige førende programmer kendt for deres fremragende præstationer, både inden for sport og akademik. Disse programmer tiltrækker mange talentfulde spillere, der nyder godt af de faciliteter, træning og uddannelse af høj kvalitet, de tilbyder. Nogle af disse programmer er:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          University of California, Los Angeles (UCLA) er kendt for sit exceptionelle vandpoloprogram, der rangerer blandt de mest succesrige i historien. Med et imponerende antal nationale titler for både mænds og kvinders hold indtager programmet en prominent plads i sporten. Kampene spilles på Spieker Aquatics Center, med plads til 2.500 tilskuere. UCLA-holdet er berømt for sin hurtige og dynamiske spillestil og har produceret et betydeligt antal olympiske medaljetagere.
        </li>
        <li>
          Stanford University praler også af et prestigefyldt vandpoloprogram med talrige nationale titler for både mænds og kvinders hold. Holdene spiller deres hjemmekampe på Avery Aquatic Center, der rummer 2.530 tilskuere. Stanfords vandpolohold er kendt for deres tekniske og taktiske færdigheder og har også bidraget til udviklingen af mange olympiske medaljetagere.
        </li>
        <li>
          University of Southern California (USC) har også et stærkt vandpoloprogram med flere nationale mesterskaber for både mænds og kvinders hold. Deres hjemmebase er Uytengsu Aquatics Center, med plads til 2.500 tilskuere. USC-holdet er karakteriseret ved en fysisk og aggressiv spillestil og har også trænet en betydelig mængde olympiske medaljetagere.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  









<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Fra College til Professionelt Niveau
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Universitetsvandpolo er ikke kun en fantastisk måde at studere og spille på i Amerika, men også en mulig springbræt til en professionel karriere. Mange universitetsspillere går videre til at spille i professionelle ligaer, både i Amerika og i udlandet, efter endt uddannelse. Nogle af dem bliver også udvalgt til det nationale hold, som deltager i internationale turneringer som OL, verdensmesterskaberne og Verdensligaen.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Vejen til Professionalisme
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Mens vandpolo er en populær og konkurrencedygtig sport i Amerika, er den stadig ikke så kommerciel og lukrativ som nogle andre sportsgrene som basketball, fodbold eller baseball. Derfor er der ikke mange professionelle ligaer eller hold i Amerika, og lønninger og præmiepenge er relativt lave. De fleste professionelle spillere har også et andet job eller en anden indtægtskilde ved siden af deres vandpolokarriere.
      <br />
      <br />
      Den vigtigste professionelle liga i Amerika er National Water Polo League (NWPL), som blev etableret i 2018 som en efterfølger til American Water Polo League (AWPL). NWPL består af otte hold, hver bestående af 15 spillere. Holdene spiller en sæson på 14 kampe, efterfulgt af en playoff-turnering for at afgøre mesteren. Den nuværende mester er Los Angeles Water Polo Club, som vandt titlen i 2022.
      <br />
      <br />
      De fleste spillere i NWPL er tidligere universitetsspillere eller kandidater, der fortsætter deres karrierer efter deres studier. Nogle af dem er også medlemmer af det nationale hold, som regelmæssigt træner og spiller på Olympic Training Center i Colorado Springs. Det nationale hold trænes af Dejan Udovicic, en tidligere serbisk spiller og træner, der har været ved roret siden 2013.
      <br />
      <br />
      En af de mest succesrige alumner fra universitetsvandpolo er Tony Azevedo, som betragtes som en af de største spillere gennem tiderne. Han spillede for Stanford University, hvor han blev kåret til NCAA Player of the Year fire gange. Han spillede også for flere professionelle hold i Europa og Amerika, herunder Brescia (Italien), Olympiacos (Grækenland) og Long Beach Shore (Amerika). Han deltog også i fem OL, hvor han vandt en sølvmedalje i 2008. Han bliver ofte omtalt som "The Savior", fordi han ofte scorede afgørende mål for sit hold.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professionelle Ligaer
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Udover NWPL er der andre professionelle ligaer i Amerika, såsom Major League Water Polo (MLWP) og Premier Water Polo League (PWPL). Disse ligaer er imidlertid mindre etablerede og mindre populære end NWPL og har færre hold og spillere. De anerkendes heller ikke af USA Water Polo, den nationale forbund, der er ansvarlig for at organisere og regulere vandpolo i Amerika.
      <br />
      <br />
      Mange amerikanske spillere vælger derfor at fortsætte deres professionelle karriere i udlandet, hvor der er flere muligheder og bedre forhold. Europa er det primære rejsemål for amerikanske vandpolospillere, fordi kontinentet har nogle af de stærkeste og mest prestigefyldte ligaer i verden. Nogle af disse ligaer inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          LEN Champions League, betragtes som den øverste klubkonkurrence i Europa. Den består af 16 hold fra forskellige lande, der kæmper om den europæiske titel. Den nuværende mester er Pro Recco (Italien), som vandt titlen i 2021.
        </li>
        <li>
          LEN Euro Cup, betragtes som den næststørste klubkonkurrence i Europa. Den består af 32 hold fra forskellige lande, der kæmper om en plads i Champions League. Den nuværende mester er Orvosegyetem SC (Ungarn), som vandt titlen i 2021.
        </li>
        <li>
          Nationale ligaer organiseret af de respektive forbund i hvert land. Nogle af de stærkeste og mest populære nationale ligaer inkluderer Serie A1 (Italien), Liga Premaat (Spanien), A1 Ethniki (Grækenland) og OB I (Ungarn).
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>
                         






                                  





<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationale Indflydelser og Konkurrencer
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Vandpolo er en global sport, der spilles og følges af millioner af mennesker over hele verden. Det er også en sport, der konstant udvikler sig og innoverer, takket være indflydelsen fra forskellige kulturer, stilarter og traditioner. Amerikansk vandpolo er ingen undtagelse, da den har lært og nydt godt af international vandpolo, både på klub- og nationalniveau.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Udenlandske Indflydelser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="NCAA vandpolo mesterskabskamp"
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          En kamp for NCAA-mesterskabet
        </figcaption>
      </figure>
      Som vi har set, har vandpolo sin oprindelse i Europa og spredte sig til andre kontinenter, herunder Amerika. Derfor har europæisk vandpolo haft en betydelig indflydelse på amerikansk vandpolo, især når det kommer til teknik, taktik og strategi. Mange amerikanske spillere og trænere har forbedret deres færdigheder og viden ved at spille eller træne i Europa eller ved at lære af europæiske spillere og trænere.
      <br />
      <br />
      Et af de mest indflydelsesrige europæiske lande for amerikansk vandpolo er Ungarn, som betragtes som den mest succesrige vandpolonation i verden. Ungarn har vundet 15 olympiske guldmedaljer, flere end noget andet land. Ungarn er kendt for sin kreative og intelligente spillestil, der er baseret på stærk boldbehandling, hurtige pasninger og præcis skudafgivelse. Mange ungarske spillere og trænere har sat deres præg på amerikansk vandpolo, såsom Tibor Benedek, Zoltan Szecsi og Attila Banhidy.
      <br />
      <br />
      Et andet indflydelsesrigt europæisk land for amerikansk vandpolo er Serbien, som også er en af de førende vandpolonationer globalt. Serbien har vundet 7 olympiske guldmedaljer, den seneste i 2016. Serbien er kendt for sin fysiske og aggressive spillestil, der bygger på stærkt forsvar, hurtige modangreb og kraftfuld skudafgivelse. Mange serbiske spillere og trænere har delt deres erfaring og ekspertise med amerikansk vandpolo, herunder Dejan Udovicic, Andrija Prlainovic og Filip Filipovic.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikanere i Internationale Konkurrencer
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Udover at lære af international vandpolo har amerikansk vandpolo også bidraget betydeligt til international vandpolo, især når det kommer til præstation, innovation og promovering. Mange amerikanske spillere og trænere har deltaget i internationale konkurrencer, både på klub- og landsholdsniveau, hvor de har vist deres talenter og forbedret deres ry.
      <br />
      <br />
      En af de vigtigste internationale vandpolokonkurrencer er De Olympiske Lege, der afholdes hvert fjerde år. Det amerikanske hold har altid deltaget i denne konkurrence, bortset fra i 1976 og 1980. Det amerikanske hold har vundet i alt 12 olympiske medaljer: 3 guld, 4 sølv og 5 bronze. Den seneste medalje var en sølvmedalje til mændene i 2008. Det amerikanske hold er i øjeblikket rangeret fjerde i verden.
      <br />
      <br />
      En anden betydningsfuld international konkurrence for vandpolo er verdensmesterskaberne, der afholdes hvert andet år. Det amerikanske hold har også konsekvent deltaget i denne konkurrence siden dens første udgave i 1973. Det amerikanske hold har vundet i alt 9 verdensmesterskabsmedaljer: 2 guld, 3 sølv og 4 bronze. Den seneste medalje var en bronze til kvinderne i 2019. Det amerikanske hold er i øjeblikket rangeret tredje i verden.
      <br />
      <br />
      En tredje vigtig international konkurrence for vandpolo er Verdensligaen, der afholdes årligt. Det amerikanske hold har også konsekvent deltaget i denne konkurrence siden dens første udgave i 2002. Det amerikanske hold har vundet i alt 10 Verdensliga-medaljer: 3 guld, 4 sølv og 3 bronze. Den seneste medalje var en guldmedalje til kvinderne i 2021. Det amerikanske hold er i øjeblikket rangeret som nummer to i verden.
    </MKTypography>
  </MKBox>
</MKBox>







                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Sportslegater og fremtiden for atleter
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sportslegater tilbyder talrige muligheder for unge atleter til at vise deres talent og udvikle deres færdigheder. En af de mest betydningsfulde muligheder er at opnå et sportslegat for at studere og konkurrere på kollegialt niveau. I dette kapitel vil vi diskutere vigtigheden af sportslegater og hvordan Sport Legater Amerika kan hjælpe dig med at realisere din drøm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vigtigheden af legater
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et sportslegat er økonomisk støtte, som et universitet tilbyder til en studerende-atlet for at kombinere studier og sport. Det kan dække omkostningerne til undervisning, bøger, bolig, måltider og andre udgifter. Derudover kan det også tilbyde fordele såsom akademisk vejledning, lægehjælp, træningsfaciliteter og udstyr.
      <br />
      <br />
      At opnå et sportslegat er konkurrencepræget, hvor mange faktorer spiller en rolle i tildelingsprocessen, herunder akademiske præstationer, atletisk præstation, personlighed, motivation, lederskab og tilpasningsevne. Derfor er det vigtigt at forberede sig godt og adskille sig fra konkurrencen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Legater Amerika Kan Hjælpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo af Sport Legater Amerika, der viser sportsudstyr og en eksamenshue, der repræsenterer fokus på sportslegater for studerende-atleter."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo af Sport Legater Amerika
        </figcaption>
      </figure>
      Sport Legater Amerika er en organisation specialiseret i at guide unge sports talenter til sportslegater. Med passionerede og erfarne eksperter støtter Sport Legater Amerika studerende-atleter på hvert trin af rejsen, fra indledende orientering til endelig placering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intake-interview for at diskutere den studerende-athlets ønsker, muligheder og forventninger.</li>
        <li>En professionel evaluering af den studerende-athlets atletiske og akademiske niveau.</li>
        <li>Et omfattende netværk af kontakter med trænere, universiteter og kollegier.</li>
        <li>Effektiv markedsføring af den studerende-athlets profil og videoer til potentielle trænere.</li>
        <li>Ekspertrådgivning om de bedste muligheder baseret på den studerende-athlets præferencer, mål og budget.</li>
        <li>Forhandling af de bedste legat tilbud på vegne af den studerende-atlet.</li>
        <li>Hjælp til ansøgning om nødvendige dokumenter, såsom visum, transkript, eksamensbevis og optagelsesprøver.</li>
        <li>Forberedelse til den studerende-atlets liv under deres legat, herunder kultur, sprog, regler og tips.</li>
        <li>Efterpleje og opfølgning under legatperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ønsker du at udforske legatmuligheder og opnå dine sportslige og akademiske mål? <Link to="/da/Tjenester-Produkter">Se det fulde udvalg</Link> af tjenester tilbudt af Sport Legater Amerika og planlæg en konsultation i dag.
    </MKTypography>
  </MKBox>
</MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;