

/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================
* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post2 from "assets/images/TijdUS.png";
import "../../../../H1asH3.css"

function Profile() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center" />
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} justifyContent="space-between" alignItems="center" mb={1}>
                <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Parte 1: Crescendo na Holanda</MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text">
              <br />
  Nos arredores encantadores de Nijmegen, meu caso de amor vitalício com o futebol começou. Como uma criança das ruas de Nijmegen, eu sentia a magia do esporte a cada passo que dava na grama verde. Meu primeiro clube foi o BVC '12, onde, quando jovem, experimentei meus primeiros dribles e gols. Foi uma época de inocência, onde descobri minha paixão pelo esporte e onde foram lançadas as bases para o meu futuro.
  <br /><br />

  Após minha aventura no BVC '12, embarquei em uma jornada por clubes de futebol locais, como Quick, NEC e Union. Essa jornada formou a base da minha educação no futebol e me aproximou do meu sonho: alcançar o topo do futebol juvenil holandês. Como jovem jogador de futebol, sonhava em jogar em estádios grandes, cercado por uma multidão de torcedores animados. Era um sonho compartilhado por muitos jovens talentos na Holanda, e eu estava determinado a fazer tudo para tornar esse sonho realidade.
  <br /><br />

  Mas à medida que desenvolvi meu talento no futebol, também comecei a perceber como uma boa educação é crucial para um futuro sólido. Na Holanda, minhas ambições no futebol e na educação às vezes pareciam contraditórias. Era um desafio equilibrar as duas paixões.
  <br /><br />

  A diversidade brilhante e as oportunidades imensas da América sempre me fascinaram, e essa fascinação só cresceu durante uma viagem com minha família. Eu estava quase certo de que voltaria a esse país, talvez por um período prolongado. Mais tarde, por meio de conhecidos, ouvi histórias sobre as oportunidades de combinar uma carreira no futebol com estudos universitários na América, e meu desejo de seguir o mesmo caminho cresceu exponencialmente.
  <br /><br />

  No entanto, não foi fácil organizar tudo. O complexo processo de bolsas esportivas e oportunidades de estudo na América inicialmente parecia assustador. Em algum lugar, eu sabia que poderia ser mais simples e acessível, o que acabou sendo minha motivação para fundar esta empresa anos depois.
  <br /><br />

  Depois de entrar em contato com vários treinadores, o treinador da Universidade de Tulsa tinha uma história especialmente convincente para compartilhar. Ele falou sobre uma equipe que sempre competia por prêmios, consistentemente classificada entre as 25 melhores do país e participava de grandes competições. Suas palavras me deram confiança de que este era o lugar onde eu poderia realizar meus sonhos no futebol e ambições acadêmicas.
  <br /><br />

  E assim aconteceu, duas semanas depois de completar 18 anos, meu capítulo na Holanda estava (temporariamente) encerrado. Embarquei no avião para a América, pronto para a aventura que estava por vir. Determinado a unir minhas paixões pelo futebol e pela educação, eu estava no início de uma jornada emocionante. Mais sobre minhas experiências na América, ficarei feliz em compartilhar com você na parte 2 da minha história, onde eu aprofundo minhas aventuras nos Estados Unidos.
</MKTypography>
<hr style={{ margin: '20px 0'}} />

<MKTypography component="a" href="#" variant="body1" fontWeight="light" color="info" mt={3}>
  <Grid container justifyContent="flex-end"> {/* This line is updated */}
    <Grid item xs={12} lg={6} mb={{ xs: 3, lg: 0 }}>
      <TransparentBlogCard
        image={post2}
        title="Parte 2: Meu Tempo na América"
        action={{
          type: "internal",
          route: "/pt/Sobre-mim/O-meu-tempo-na-América",
          color: "info",
          label: "read more",
        }}
      />
    </Grid>
  </Grid>
</MKTypography>

</Grid>
</Grid>
</Grid>
</Container>
</MKBox>
);
}

export default Profile;


