/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Produktside: https://www.creative-tim.com/product/material-kit-pro-react
* Opphavsrett 2023 Creative Tim (https://www.creative-tim.com)

Kodet av www.creative-tim.com

 =========================================================

* Den ovenfor nevnte opphavsrettsmerknaden og denne tillatelsesmerknaden skal inkluderes i alle kopier eller betydelige deler av programvaren.
*/

// @mui materialkomponenter
import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React-komponenter
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import mittBilde from "assets/images/SoccerfotoKayDuit1.png";
import andreBilde from "assets/images/SoccerfotoKayDuit2.png"

function Testimonials() {
  const [nåværendeBilde, settNåværendeBilde] = useState(mittBilde);
  const [gjennomsiktighet, settGjennomsiktighet] = useState(1);

  const mittBildeAlt = "Kay Duit i aksjon under en fotballkamp, spiller for University of Tulsa, dynamisk og fokusert på ballen"; // Erstatt med faktisk alternativ tekst
  const andreBildeAlt = "Kay Duit som fotballspiller ved University of Tulsa, aktiv i et spill på fotballbanen."; // Erstatt med faktisk alternativ tekst

  useEffect(() => {
    const byttBilde = () => {
      settGjennomsiktighet(0); // begynner å visne ut
      setTimeout(() => {
        settNåværendeBilde(forrige => forrige === mittBilde ? andreBilde : mittBilde); // bytt bilde etter halvparten av varigheten
        settGjennomsiktighet(1); // begynner å visne inn
      }, 500); // dette er halvparten av varigheten for overgangen
    };

    const intervall = setInterval(byttBilde, 10000); // bytt hvert 10. sekund

    return () => clearInterval(intervall);
  }, []);

  return (
    <MKBox component="section" position="relative" py={8}>
        <Container>
          <Grid container justifyContent="center" sx={{ pt: 0, pb: 0 }}>
            <Grid item xs={12} md={7} sx={{ textAlign: "center" }}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom mb={3}>
            Min Historie: Fra Lidenskap til Yrke                        </MKTypography>
            <MKTypography color="text" variant="body2" align="center" gutterBottom mb={14}>
            Hvordan min erfaring i den amerikanske idrettsverdenen nå danner nøkkelen til å veilede unge talenter til suksess.               </MKTypography>

              
            </Grid>
          </Grid>
        </Container>
     
      <Container sx={{ maxWidth: "100% !important" }}>
      
      <Grid container item xs={12} sm={10} sx={{ mx: "auto" }}>
          
          <MKBox variant="gradient" bgColor="dark" borderRadius="lg" width="100%" py={6}>
            <Grid container>
              
              <Grid item xs={12} md={6} xl={4} sx={{ position: "relative", px: 6 }}>
              <MKBox
                component="img"
                src={nåværendeBilde}
                alt={nåværendeBilde === mittBilde ? mittBildeAlt : andreBildeAlt}
                borderRadius="md"
                maxWidth="300px"
                width="100%"
                position="relative"
                mt={-12}
                style={{ transition: 'opacity 1s', opacity: gjennomsiktighet }} // inline stil for overgang
                    />
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                xl={4}
                sx={{
                  position: "relative",
                  px: { xs: 6, md: 2 },
                  mt: { xs: 3, md: 0 },
                  my: { xs: 0, md: "auto" },
                  color: ({ palette: { white } }) => white.main,
                }}
              >
                <Icon fontSize="large">format_quote</Icon>
                <MKTypography
                  variant="body2"
                  color="white"
                  fontWeight="light"
                  mb={2}
                  sx={{ fontSize: "1.125rem" }}
                >
Som tidligere fotballspiller ved University of Tulsa hadde jeg privilegiet av å oppleve fire uforglemmelige år i hjertet av amerikansk studentkultur. Den lidenskapen og erfaringen driver meg nå til å veilede europeiske studenter på deres egen fotballreise i USA. Sammen vil vi gjøre din amerikanske idrettsdrøm til virkelighet.
                </MKTypography>
                <MKTypography variant="button" fontWeight="bold" color="white">
                  Kay Duit -{" "}
                  <MKTypography variant="caption" color="white">
                    Grunnlegger
                  </MKTypography>
                </MKTypography>
              </Grid>
              <Grid item xs={1} />
              <Grid
                item
                xs={12}
                xl={2}
                sx={{ px: { xs: 6, xl: 0 }, my: { xs: 0, xl: "auto" }, lineHeight: 1 }}
              >
                <MKTypography variant="h5" color="white" mt={{ xs: 6, xl: 0 }} mb={1}>
                4-års brevvinner
                <br />
                3x AAC-mester
                              
                </MKTypography>
                
                <MKTypography
                  component="a"
                  href="/no/Om-Meg"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  sx={{
                    width: "maks-innhold",
                    visning: "fleks",
                    utjevning: "senter",


                    "& .material-icons-round": {
                      skriftstørrelse: "1.125rem",
                      transform: `oversettX(3px)`,
                      overgang: "transform 0.2s kubisk-bezier(0.34, 1.61, 0.7, 1.3)",
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `oversettX(6px)`,
                    },
                  }}
                >
                  Les mer
                  <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MKTypography>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Testimonials;
