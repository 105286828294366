/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Swimming1.jpg";
import Image2 from "assets/images/Sports_Images/Swimming2.jpeg";
import Image3 from "assets/images/Sports_Images/Swimming3.avif";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Svømning i Amerika: Sådan Får du Et Sportslegat og Bliver En Del af en Prestigefyldt Sports Historie', isSubheader: false },
  
    { id: 'Kapitel 1', title: 'Kapitel 1: Historie og Udvikling af Svømning i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Tidlige År og Universiteternes Rolle', isSubheader: true },
    { id: '1.2', title: '1.2 Vækst og Professionalisering', isSubheader: true },
  
    { id: 'Kapitel 2', title: 'Kapitel 2: Kollegial Svømning i Dag', isSubheader: false },
    { id: '2.1', title: '2.1 Nøglekonkurrencer og Konferencer', isSubheader: true },
    { id: '2.2', title: '2.2 Topprogrammer og Prestigefyldte Hold', isSubheader: true },
  
    { id: 'Kapitel 3', title: 'Kapitel 3: Fra College til Professionelt Niveau', isSubheader: false },
    { id: '3.1', title: '3.1 Vejen til Professionalisme', isSubheader: true },
    { id: '3.2', title: '3.2 Professionel Svømning og De Olympiske Lege', isSubheader: true },
  
    { id: 'Kapitel 4', title: 'Kapitel 4: Internationale Påvirkninger og Konkurrencer', isSubheader: false },
    { id: '4.1', title: '4.1 Udenlandske Påvirkninger', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanere i Udlandet', isSubheader: true },
  
    { id: 'Kapitel 5', title: 'Kapitel 5: Sportslegater og fremtiden for atleter', isSubheader: false },
    { id: '5.1', title: '5.1 Vigtigheden af legater', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Legater Amerika Kan Hjælpe', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
       </MKBox>
</Grid>
<Grid item xs={12} md={9}>
<MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Svømning i Amerika: Sådan Får du Et Sportslegat og Bliver En Del af en Prestigefyldt Sports Historie
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Svømmestadion fyldt med tilskuere under en kollegiekonkurrence."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Et stadion under en kamp
      </figcaption>
    </figure>
    Svømning er en af de mest populære og konkurrencedygtige sportsgrene i verden. Millioner af mennesker nyder at svømme, både rekreativt og professionelt. Men vidste du, at svømning i Amerika har nået et helt andet niveau? Svømning i Amerika er ikke bare en sport; det er en kultur, en tradition og en livsstil.
    <br />
    <br />
    I denne artikel vil vi fortælle dig alt om udviklingen af svømning som en konkurrencedygtig sport i De Forenede Stater, betydningen af kollegial svømning for fremme af sporten, og hvordan man får et sportslegat til at studere og svømme i Amerika. Vi vil også dykke ned i rollen som Sportbeursamerika.nl, en organisation der hjælper unge svømmere med at realisere deres drømme.
    <br />
    <br />
    Svømning er en sport, der har været praktiseret i århundreder, men det var ikke før det 19. århundrede, at det begyndte at udvikle sig som en organiseret og reguleret aktivitet. De første moderne olympiske lege i 1896 inkluderede svømmekonkurrencer som en af hovedbegivenhederne, og siden da har svømning været en fast bestanddel af den olympiske dagsorden.
    <br />
    <br />
    Men hvorfor er svømning så populær og succesfuld i Amerika? Hvad gør det så specielt og unikt? Svaret ligger i universiteternes rolle i at fremme svømning som en akademisk og idrætslig disciplin. Amerikanske universiteter tilbyder ikke kun uddannelse af høj kvalitet, men også fremragende faciliteter, træning og vejledning til studerende-udøvere, der ønsker at specialisere sig i svømning. Derudover yder de økonomisk støtte i form af sportslegater, hvilket giver talentfulde svømmere mulighed for at realisere deres potentiale uden at bekymre sig om omkostningerne til deres uddannelse.
    <br />
    <br />
    Dog er sportslegater ikke lette at opnå. De er meget konkurrencedygtige og kræver et højt niveau af engagement, disciplin og præstation. Derfor er det vigtigt at være godt forberedt og søge professionel assistance, når man ansøger om et sportslegat. Det er her Sportbeursamerika.nl kommer ind i billedet.
    <br />
    <br />
    Sportbeursamerika.nl er en organisation specialiseret i at guide unge svømmere til sportslegater i Amerika. De har et team af eksperter, der kan hjælpe dig med alle aspekter af processen, såsom at vælge det rigtige universitet, forberede dit CV og din motiveringsbrev, gøre dig klar til optagelsesprøver og interviews, arrangere din visa og forsikring, og meget mere. De har også forbindelser til hundredvis af universiteter og trænere i Amerika, hvilket giver dem mulighed for at matche dig med de bedste muligheder for din profil og dine mål.
    <br />
    <br />
    Så hvis du drømmer om at studere og svømme i Amerika, er Sportbeursamerika.nl din bedste allierede. De har allerede hjulpet hundredvis af svømmere med at få deres drømme til at gå i opfyldelse, og de kan også hjælpe dig. Men før du kontakter dem, lad os først tage et kig på historien og udviklingen af svømning i Amerika, og hvorfor det er en fantastisk mulighed for dig.
  </MKTypography>
</MKBox>


<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Historie og Udvikling af Svømning i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Svømning har en lang og rig historie i Amerika. Det hele begyndte i slutningen af det 19. århundrede, da universiteter begyndte at danne deres egne svømmehold og konkurrencer. Dette markerede begyndelsen på en tradition, der ville føre til svømningens opblomstring som en national sport.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidlige År og Universiteternes Rolle
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Det første universitet, der etablerede et officielt svømmehold, var Harvard i 1878. Kort efter fulgte andre prestigefyldte universiteter som Yale, Princeton, Columbia og Cornell. Disse universiteter organiserede interkollegiale kampe og turneringer, der tiltrak offentlighedens og mediernes opmærksomhed. De introducerede også nye regler og teknikker til svømning, herunder brystsvømning, rygcrawl og butterfly.
      <br />
      <br />
      I 1902 blev Intercollegiate Swimming Association (ISA) grundlagt, hvilket blev den første nationale organisation for kollegial svømning. ISA organiserede årlige mesterskaber for mænd, hvor de bedste hold og individuelle svømmere blev æret. I 1916 blev National Collegiate Athletic Association (NCAA) etableret som den overordnede organisation for alle kollegiale sportsgrene. NCAA fik ansvaret for at organisere de nationale mesterskaber for kollegial svømning, som fortsætter den dag i dag.
      <br />
      <br />
      Kollegial svømning var ikke begrænset til mænd. Kvinder begyndte også at konkurrere på kollegialt niveau i begyndelsen af det 20. århundrede, selvom de mødte flere restriktioner og diskrimination. De første kvindelige kollegiale svømmehold blev dannet i 1914 på University of Chicago og i 1916 på University of Wisconsin. I 1922 blev Women's Intercollegiate Swimming Association (WISA) grundlagt og fungerede som ækvivalenten til ISA for kvinder. WISA organiserede også årlige mesterskaber for kvinder, som opnåede betydelig popularitet.
      <br />
      <br />
      Kollegial svømning spillede en afgørende rolle i at fremme svømning som en professionel sport i Amerika. Mange kollegiale svømmere gik videre til professionelle ligaer og de olympiske lege, hvor de repræsenterede deres land og vandt medaljer. Nogle af de mest kendte navne inkluderer Johnny Weissmuller, Mark Spitz, Matt Biondi, Janet Evans, Michael Phelps og Katie Ledecky.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Vækst og Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ud over kollegial svømning opstod der også andre former for professionel svømning i Amerika. En af dem var masters svømning, som fokuserede på voksne svømmere, der ønskede at forbedre deres færdigheder eller vedligeholde deres fitness. Masters svømning begyndte i 1970 med etableringen af United States Masters Swimming (USMS), en organisation der tilbød lokale klubber, konkurrencer og programmer for voksne svømmere. Masters svømning voksede hurtigt til en global bevægelse med millioner af medlemmer i over 100 lande.
      <br />
      <br />
      En anden form for professionel svømning var åbentvandssvømning, som foregik i naturlige vandområder som søer, floder og have. Åbentvandssvømning var en udfordrende og eventyrlig sport, der krævede udholdenhed, navigationsfærdigheder og tilpasningsevne. Åbentvandssvømning fik mere anerkendelse i 2008, da det blev tilføjet som en olympisk begivenhed. Nogle af de mest berømte åbentvandssvømmere inkluderer Gertrude Ederle, Lynne Cox, Maarten van der Weijden og Ous Mellouli.
      <br />
      <br />
      Professionel svømning nåede sit højdepunkt under de olympiske lege, hvor amerikanske svømmere dominerede. Siden 1896 har Amerika vundet over 500 medaljer i svømning, herunder mere end 200 guldmedaljer. Dette gør Amerika langt den mest succesfulde nation i olympisk svømning.
      <br />
      <br />
      Men hvordan kan du også være en del af denne glorværdige sports historie? Hvordan kan du studere og svømme i Amerika med et sportslegat? Det vil vi fortælle dig i det næste kapitel.
    </MKTypography>
  </MKBox>
</MKBox>



                                              





                                  








<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Kollegial Svømning i Dag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Som du har set, har kollegial svømning en lang og stolt historie i Amerika. Men hvad med nutiden? Hvordan er kollegial svømning organiseret og struktureret i dag? Hvad er de vigtigste konkurrencer og konferencer? Hvilke er de bedste programmer og prestigefyldte hold? Lad os tage et nærmere kig.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Nøglekonkurrencer og Konferencer
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Katie Ledecky svømmer for Stanford i en kollegial konkurrence."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Katie Ledecky i aktion som svømmer for Stanford
        </figcaption>
      </figure>
      Kollegial svømning i Amerika reguleres primært af NCAA, som har tre divisioner: Division I, Division II og Division III. Hver division har sine egne regler, kriterier og mesterskaber. Generelt set er Division I den mest konkurrencedygtige og prestigefyldte division, der præsenterer de bedste svømmere, trænere og faciliteter. Division II og III er mindre konkurrencedygtige, men tilbyder stadig høj kvalitet inden for uddannelse og sport.
      <br />
      <br />
      Inden for hver division er der forskellige konferencer, som er grupper af universiteter, der er geografisk eller historisk forbundet. Hver konference organiserer sine egne mesterskaber, hvor de bedste hold og individuelle svømmere kvalificerer sig til NCAA's nationale mesterskaber. Nogle af de mest kendte konferencer inden for kollegial svømning inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Southeastern Conference (SEC), der består af 14 universiteter i det sydøstlige USA, såsom Alabama, Florida, Georgia, Kentucky, LSU, Tennessee og Texas A&M. SEC er kendt for sin dominans inden for svømning, især i mænds begivenheder. SEC har vundet over 50 nationale titler i svømning, hvoraf over 40 af dem kommer fra mændenes hold på Florida.
        </li>
        <li>
          Pacific-12 Conference (Pac-12), der består af 12 universiteter på vestkysten af Amerika, herunder Arizona, Californien, Stanford, UCLA, USC og Washington. Pac-12 er kendt for sin fremragende præstation inden for svømning, især i kvinders begivenheder. Pac-12 har vundet over 40 nationale titler i svømning, hvoraf over 30 tilhører kvindernes hold på Stanford.
        </li>
        <li>
          Big Ten Conference (Big Ten), der består af 14 universiteter i Midtvesten og det nordøstlige USA, såsom Indiana, Michigan, Minnesota, Ohio State, Penn State og Wisconsin. Big Ten er kendt for sin mangfoldighed og balance inden for svømning, med stærke hold inden for både mænds og kvinders begivenheder. Big Ten har vundet over 30 nationale titler i svømning, hvoraf over 20 af dem er sikret af mændenes hold på Michigan.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dette er blot nogle få eksempler på de mange konferencer, der findes inden for kollegial svømning. Hver konference har sin egen kultur, rivaliseringer og traditioner, der bidrager til spændingen i kollegial svømning.
    </MKTypography>

  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Topprogrammer og Prestigefyldte Hold
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ud over konferencer er der individuelle universiteter, der skiller sig ud på grund af deres topprogrammer og prestigefyldte svømmehold. Disse universiteter tilbyder ikke kun en fremragende uddannelse til deres studerende, men giver også førsteklasses træning, træning og faciliteter til deres atleter. De tiltrækker også mange talentfulde svømmere fra både nationale og internationale baggrunde, der ønsker at studere og svømme på det højeste niveau.
      <br />
      <br />
      Nogle af disse topuniversiteter inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Stanford University: Stanford er et af de mest anerkendte universiteter i verden både akademisk og idrætsligt. Stanford har et af de bedste kvindelige svømmeprogrammer i Amerika, med over 10 nationale titler og mere end 100 olympiske medaljer. Stanford har også et stærkt herresvømmeprogram, med over 5 nationale titler og mere end 50 olympiske medaljer. Nogle berømte alumner fra Stanford inkluderer John Hencken, Pablo Morales, Jenny Thompson, Summer Sanders, Misty Hyman, Janet Evans og Katie Ledecky.
        </li>
        <li>
          University of California: Californien er et andet topuniversitet både akademisk og idrætsligt. Californien har et af de bedste herresvømmeprogrammer i Amerika, med over 10 nationale titler og mere end 100 olympiske medaljer. Californien har også et stærkt kvindeligt svømmeprogram, med over 5 nationale titler og mere end 50 olympiske medaljer. Nogle berømte alumner fra Californien inkluderer Matt Biondi, Anthony Ervin, Natalie Coughlin, Nathan Adrian, Dana Vollmer og Ryan Murphy.
        </li>
        <li>
          University of Texas: Texas er et af de største og mest indflydelsesrige universiteter i Amerika, med en stærk omdømme inden for akademikere og sport. Texas har et af de bedste herresvømmeprogrammer i Amerika, med over 15 nationale titler og mere end 100 olympiske medaljer. Texas har også et stærkt kvindeligt svømmeprogram, med over 5 nationale titler og mere end 50 olympiske medaljer. Nogle berømte alumner fra Texas inkluderer Josh Davis, Aaron Peirsol, Ian Crocker, Brendan Hansen, Ricky Berens og Joseph Schooling.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dette er blot nogle få af de mange topuniversiteter inden for kollegial svømning. Hvert universitet har sin egen historie, filosofi og vision, der bidrager til udviklingen af svømmere.
      <br />
      <br />
      Som du kan se, er kollegial svømning i dag en yderst dynamisk og konkurrencedygtig sport, der tilbyder mange muligheder for studerende-atleter, der ønsker at udmerke sig både akademisk og idrætsligt. Men hvordan kan du få adgang til disse muligheder? Hvordan kan du få et sportslegat til at studere og svømme i Amerika? Det vil vi fortælle dig i det næste kapitel.
    </MKTypography>
  </MKBox>
</MKBox>
                               






                                  





<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Fra College til Professionelt Niveau
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    At studere og svømme i Amerika er ikke kun en fantastisk oplevelse, men også en fantastisk mulighed for at kickstarte din karriere som professionel svømmer. Mange kollegiale svømmere går videre til det professionelle niveau, hvor de repræsenterer deres land i internationale konkurrencer og De Olympiske Lege. Men hvordan springer du fra college til det professionelle niveau? Hvad er udfordringerne og fordelene ved denne overgang? Lad os tage et nærmere kig.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Vejen til Professionalisme
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      For at foretage overgangen fra college til det professionelle niveau skal du først opfylde NCAA's krav. NCAA har strenge regler vedrørende amatørstatus for studerende-atleter, hvilket betyder, at du ikke kan tjene eller acceptere penge for din sportslige præstation. Dette betyder, at du ikke kan indgå sponsorataftaler, præmiepengesaftaler eller andre former for kompensation, mens du stadig studerer og svømmer på kollegialt niveau.
      <br />
      <br />
      Imidlertid, når du har afsluttet dine studier eller beslutter dig for at afslutte din uddannelse, kan du frasige din amatørstatus og blive professionel svømmer. Det betyder, at du kan slutte dig til en professionel svømmeorganisation, såsom USA Swimming, som er den nationale styrende organ for svømning i Amerika. USA Swimming giver dig mulighed for at deltage i professionelle svømmekonkurrencer som Pro Swim Series, US Open, US Nationals og Olympic Trials. Disse konkurrencer er designet til at forbedre dine færdigheder, hæve din rangering og kvalificere dig til internationale begivenheder.
      <br />
      <br />
      Som professionel svømmer kan du også drage fordel af sponsorataftaler, præmiepenge og andre former for kompensation for din sportslige præstation. Dette kan hjælpe med at forbedre din økonomiske situation, støtte din livsstil og øge din motivation. Men at blive professionel svømmer kommer også med flere ansvar og udfordringer. For eksempel bliver du nødt til at håndtere din egen træningsplan, ernæringsplan, rejseudgifter, lægehjælp og skat. Du vil også skulle håndtere øget konkurrence, pres og forventninger fra dig selv og andre.
      <br />
      <br />
      Derfor er det afgørende at være godt forberedt på overgangen fra college til det professionelle niveau. Du skal ikke kun have fremragende svømmefærdigheder, men også have en stærk mental indstilling, klare mål og god støtte. Du skal også være realistisk om dine chancer og muligheder og være villig til at arbejde hårdt og ofre.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professionel Svømning og De Olympiske Lege
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      En af enhver professionel svømmers største drømme er at deltage i De Olympiske Lege, den mest prestigefyldte og betydningsfulde sportsbegivenhed i verden. De Olympiske Lege tilbyder ikke kun en chance for at repræsentere dit land og vinde medaljer, men også for at skabe historie og blive en legende.
      <br />
      <br />
      Imidlertid skal du for at kvalificere dig til De Olympiske Lege først opfylde kravene fra Den Internationale Olympiske Komité (IOC) og Den Internationale Svømmeføderation (FINA). Disse organisationer har strenge regler vedrørende kvalifikationskriterier, antallet af deltagere pr. land, antidopingkontroller og andre aspekter af olympisk svømning.
      <br />
      <br />
      Et af de vigtigste krav for at kvalificere sig til De Olympiske Lege er at opnå en specifik tidsstandard i en anerkendt konkurrence. Denne tidsstandard fastlægges af FINA baseret på gennemsnittet af de 16 bedste præstationer globalt i hver disciplin over de sidste fire år. Tidsstandarden varierer efter køn, aldersgruppe og svømmetag.
      <br />
      <br />
      Et andet væsentligt krav for at kvalificere sig til De Olympiske Lege er at blive udvalgt af din nationale svømmeorganisation, såsom USA Swimming. Hver national svømmeorganisation har sin egen udvælgelsesproces, der typisk involverer en række prøver eller mesterskaber, hvor de bedste svømmere vælges til at repræsentere landet. Hver national svømmeorganisation har også et begrænset antal pladser pr. disciplin, afhængigt af antallet af kvalificerede svømmere og kvoterne fastsat af IOC og FINA.
      <br />
      <br />
      Hvis du formår at opfylde disse krav og kvalificere dig til De Olympiske Lege, er du en af de heldige få, der får muligheden for at være en del af verdens største sportsbegivenhed. Du vil kunne konkurrere mod de bedste svømmere i verden, udfordre dig selv og potentielt vinde en medalje og indskrive dit navn i historiebøgerne.
      <br />
      <br />
      Som du kan se, er overgangen fra college til det professionelle niveau en spændende og givende rejse, der tilbyder mange muligheder for din svømmekarriere. Men hvordan kan du starte denne rejse? Hvordan kan du få et sportslegat til at studere og svømme i Amerika? Det vil vi fortælle dig i det næste kapitel.
    </MKTypography>
  </MKBox>
</MKBox>





                                  








<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationale Påvirkninger og Konkurrencer
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Svømning i Amerika er ikke kun en national sport, men også en international en. Svømning i Amerika påvirkes af og påvirker andre lande og kulturer, hver med deres egne stilarter, teknikker og traditioner inden for svømning. Svømning i Amerika udfordres og beriges også af andre internationale konkurrencer, der giver muligheder for at lære af og konkurrere mod svømmere fra hele verden. Men hvordan opstod og udviklede disse internationale påvirkninger og konkurrencer sig? Hvordan påvirker de kollegial og professionel svømning i Amerika? Lad os tage et nærmere kig.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Udenlandske Påvirkninger
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Svømmer i aktion under NCAA's nationale mesterskab."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          NCAA's nationale mesterskab
        </figcaption>
      </figure>
      Svømning er en universel sport, der praktiseres i næsten alle lande i verden. Hvert land har sin egen historie, kultur og identitet inden for svømning, hvilket afspejles i, hvordan de svømmer, træner og coacher. Nogle lande har haft en betydelig indflydelse på svømning i Amerika, både positivt og negativt.
      <br />
      <br />
      En af de positive indflydelser er Australien, som er en af ​​Amerikas største rivaler og allierede inden for svømning. Australien har en lang og stolt tradition inden for svømning, med mere end 100 olympiske medaljer og over 20 verdensrekorder. Australien er kendt for sin innovation og kreativitet inden for svømning og har introduceret nye teknikker, strategier og metoder. Australien har også produceret mange talentfulde og karismatiske svømmere, såsom Dawn Fraser, Shane Gould, Ian Thorpe, Grant Hackett, Libby Trickett og Cate Campbell.
      <br />
      <br />
      Australien har påvirket svømningen i Amerika ved at skabe en sund konkurrence og samarbejde, der motiverer begge lande til at forbedre sig og lære af hinanden. Mange australske og amerikanske svømmere har trænet, studeret eller arbejdet sammen, hvilket har forbedret deres færdigheder, viden og venskaber. Nogle eksempler inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          I 1976 trænede Mark Spitz, den amerikanske stjerne, der vandt syv guldmedaljer i München, sammen med John Devitt, den australske legende, der vandt to guldmedaljer i Melbourne og Rom.
        </li>
        <li>
          I 1988 blev Matt Biondi, den amerikanske sensation, der vandt syv medaljer i Seoul, færdiguddannet fra University of California med en grad i politisk økonomi.
        </li>
        <li>
          I 2016 blev Joseph Schooling, den singaporeanske overraskelse, der vandt den første guldmedalje for sit land i 100m butterfly i Rio de Janeiro, færdiguddannet fra University of Texas med en grad i økonomi.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dette er kun nogle få eksempler på, hvordan udenlandske påvirkninger har påvirket svømning i Amerika. Mange flere lande og kulturer har sat deres præg på svømning, herunder Japan, Kina, Rusland, Frankrig, Brasilien og Sydafrika. Svømning i Amerika er ikke kun en national sport, men også en international sport, der konstant udvikler sig og ændrer sig gennem interaktion med andre lande og kulturer.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikanere i Udlandet
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Svømning i Amerika påvirkes ikke kun af og påvirker andre lande og kulturer, men udfordres også og beriges af andre internationale konkurrencer. Svømning i Amerika giver mulighed for at lære af og konkurrere mod svømmere fra hele verden, hver med deres egne stilarter, teknikker og traditioner inden for svømning. Svømning i Amerika giver også mulighed for at rejse til og opleve forskellige steder, mennesker og kulturer, hvilket udvider dine horisonter og ændrer din perspektiv.
      <br />
      <br />
      En af de største internationale konkurrencer, som amerikanske svømmere deltager i, er verdensmesterskaberne i svømning, der afholdes hvert andet år af FINA. Verdensmesterskaberne i svømning er en begivenhed, der omfatter alle svømmediscipliner, herunder lang bane, kort bane, åbent vand, synkronsvømning og styrtdykning. Verdensmesterskaberne i svømning giver mulighed for at konkurrere mod verdens bedste svømmere, forbedre din placering og forbedre din kvalifikation til andre begivenheder.
      <br />
      <br />
      En anden betydningsfuld international konkurrence, som amerikanske svømmere deltager i, er World Cup Swimming, der afholdes årligt af FINA. World Cup Swimming er en serie af kortbanebegivenheder, der finder sted i forskellige byer rundt om i verden. World Cup Swimming er en mulighed for at konkurrere mod forskellige modstandere, teste dine færdigheder og tjene pengepræmier.
      <br />
      <br />
      En tredje stor international konkurrence, som amerikanske svømmere deltager i, er Pan American Games, der afholdes hvert fjerde år af Pan American Sports Organization (PASO). Pan American Games er en multisportbegivenhed, der samler atleter fra Nord-, Syd- og Mellemamerika. Pan American Games er en chance for at repræsentere dit kontinent, vise solidaritet og vinde medaljer.
      <br />
      <br />
      Dette er kun nogle få eksempler på de mange internationale konkurrencer, som amerikanske svømmere deltager i. Der er mange flere internationale konkurrencer inden for svømning, såsom Commonwealth Games, European Championships, Asian Games og African Games.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  




<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Sportslegater og fremtiden for atleter
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sportslegater tilbyder talrige muligheder for unge atleter til at vise deres talent og udvikle deres færdigheder. En af de mest betydningsfulde muligheder er at opnå et sportslegat for at studere og konkurrere på kollegialt niveau. I dette kapitel vil vi diskutere vigtigheden af sportslegater og hvordan Sport Legater Amerika kan hjælpe dig med at realisere din drøm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vigtigheden af legater
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et sportslegat er økonomisk støtte, som et universitet tilbyder til en studerende-atlet for at kombinere studier og sport. Det kan dække omkostningerne til undervisning, bøger, bolig, måltider og andre udgifter. Derudover kan det også tilbyde fordele såsom akademisk vejledning, lægehjælp, træningsfaciliteter og udstyr.
      <br />
      <br />
      At opnå et sportslegat er konkurrencepræget, hvor mange faktorer spiller en rolle i tildelingsprocessen, herunder akademiske præstationer, atletisk præstation, personlighed, motivation, lederskab og tilpasningsevne. Derfor er det vigtigt at forberede sig godt og adskille sig fra konkurrencen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Legater Amerika Kan Hjælpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo af Sport Legater Amerika, der viser sportsudstyr og en eksamenshue, der repræsenterer fokus på sportslegater for studerende-atleter."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo af Sport Legater Amerika
        </figcaption>
      </figure>
      Sport Legater Amerika er en organisation specialiseret i at guide unge sports talenter til sportslegater. Med passionerede og erfarne eksperter støtter Sport Legater Amerika studerende-atleter på hvert trin af rejsen, fra indledende orientering til endelig placering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intake-interview for at diskutere den studerende-athlets ønsker, muligheder og forventninger.</li>
        <li>En professionel evaluering af den studerende-athlets atletiske og akademiske niveau.</li>
        <li>Et omfattende netværk af kontakter med trænere, universiteter og kollegier.</li>
        <li>Effektiv markedsføring af den studerende-athlets profil og videoer til potentielle trænere.</li>
        <li>Ekspertrådgivning om de bedste muligheder baseret på den studerende-athlets præferencer, mål og budget.</li>
        <li>Forhandling af de bedste legat tilbud på vegne af den studerende-atlet.</li>
        <li>Hjælp til ansøgning om nødvendige dokumenter, såsom visum, transkript, eksamensbevis og optagelsesprøver.</li>
        <li>Forberedelse til den studerende-atlets liv under deres legat, herunder kultur, sprog, regler og tips.</li>
        <li>Efterpleje og opfølgning under legatperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ønsker du at udforske legatmuligheder og opnå dine sportslige og akademiske mål? <Link to="/da/Tjenester-Produkter">Se det fulde udvalg</Link> af tjenester tilbudt af Sport Legater Amerika og planlæg en konsultation i dag.
    </MKTypography>
  </MKBox>
</MKBox>

          

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;