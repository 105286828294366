/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Pagina del prodotto: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Codificato da www.creative-tim.com

 =========================================================

* La nota di copyright sopra riportata e questa nota di autorizzazione devono essere incluse in tutte le copie o parti sostanziali del software.
*/

// Componenti Material-UI
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Componenti di Material Kit 2 PRO React
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Immagini
import post1 from "assets/images/Jeugd.png";
import post3 from "assets/images/Deal.png";
import "../../../../H1asH3.css";


function Profilo() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center"></MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox 
                  display="flex" 
                  flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} 
                  justifyContent="space-between" 
                  alignItems="center" 
                  mb={1}
              >
                  <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Parte 2: Il Mio Tempo in America</MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text">
  <br />
    Il mio tempo in America è stato come un viaggio mozzafiato, pieno di esperienze indimenticabili e crescita personale. 
    Grazie al calcio, ho viaggiato da costa a costa, accompagnato da un gruppo di compagni di squadra che sono diventati rapidamente alcuni dei miei migliori amici. 
    Le nostre giornate iniziavano presto con sessioni di allenamento intensive in palestra, qualcosa a cui non ero abituato nei Paesi Bassi. 
    Poi velocemente all'università per le lezioni, e poi di nuovo all'allenamento nel pomeriggio. Era un impegno quotidiano che mi sfidava sia fisicamente che mentalmente.
  <br /><br />
    Le partite erano momenti salienti a cui accorrevano migliaia di fan, e ogni volta sembrava che stessimo giocando per qualcosa di più grande di noi stessi. 
    L'adrenalina nell'entrare in campo davanti a una folla così appassionata è indescrivibile. Inoltre, i viaggi che facevamo per le partite erano indimenticabili. 
    In aereo, trascorrevamo spesso ore a giocare a carte, e nelle hall degli hotel condividevamo momenti divertenti e conversazioni emozionanti. 
    I ristoranti con piatti deliziosi diventavano i nostri luoghi temporanei per mangiare, e queste avventure culinarie aggiungevano una dimensione extra ai nostri viaggi. 
    Tutto era organizzato con cura, dalle carte sull'aereo ai ristoranti in cui cenavamo. 
    Queste esperienze mi hanno permesso di gettare uno sguardo sulla grandezza dell'America.
  <br /><br />
    Inoltre, ho avuto l'opportunità di fare uno stage presso i principali club professionistici, tra cui i Seattle Sounders. 
    Condividere il campo con giocatori come il miglior marcatore di tutti i tempi d'America, Clint Dempsey, è stato semplicemente surreale, un momento che porterò sempre nel cuore. 
    Ma come in ogni avventura, questo viaggio ha avuto le sue sfide. 
    Nel mio ultimo anno, ho affrontato un grave infortunio ai flessori, che ha temporaneamente fermato le mie ambizioni nel calcio. 
    Questo è stato un periodo di riflessione personale e resilienza, da cui sono emerso più forte.
  <br /><br />
    Inoltre, il mio percorso accademico in America è stato altrettanto importante. Nonostante l'intenso programma di allenamento calcistico e i viaggi estensivi, 
    ho ricevuto una guida perfetta per continuare i miei studi. Non è stato un compito facile, poiché spesso saltavo le lezioni a causa del mio coinvolgimento nella squadra di calcio. 
    Tuttavia, ero determinato a avere successo, e ci sono riuscito con orgoglio, laureandomi con lode, magna cum laude. 
    Questo successo accademico è stato una testimonianza del mio impegno sia nello sport che nell'educazione.
  <br /><br />
    Durante la mia avventura in America, ho vissuto feste tipicamente americane che sembravano uscite direttamente da un film. 
    Questi vivaci incontri sociali, pieni di musica, danza e risate fragorose, mi hanno offerto l'opportunità di crescere, non solo come atleta e studente, 
    ma anche come appassionato di vita sociale. Queste feste hanno aggiunto una dimensione extra alla mia vita da studente e hanno arricchito le mie esperienze sociali.
  <br /><br />
    Tuttavia, l'America significava più per me che solo calcio, partite, eventi sociali e successi accademici. 
    Era un'opportunità per ampliare il mio cerchio sociale e fare amicizie per tutta la vita. 
    Grazie a queste amicizie, ho potuto scoprire il paese in nuovi modi. 
    Dalla pausa di primavera in Messico, dove abbiamo goduto del sole, del mare e della spiaggia, alla celebrazione del Ringraziamento con le famiglie degli amici, 
    che ora posso chiamare la mia famiglia americana. La loro calorosa accoglienza mi ha davvero fatto sentire parte della loro vita, 
    e queste esperienze hanno reso il mio tempo in America ancora più ricco.
  <br /><br />
  Questo periodo della mia vita ha approfondito il mio amore sia per il calcio che per l'istruzione e mi ha portato sulla strada che seguo oggi per aiutare gli altri a realizzare i loro sogni in America. 
  Restate sintonizzati, perché nella Parte 3 condividerò come il mio ritorno nei Paesi Bassi e la fondazione della mia azienda hanno indirizzato la mia vita in una nuova direzione.
</MKTypography>
              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="Parte 1: Crescere nei Paesi Bassi"
                    action={{
                      type: "internal",
                      route: "/it/Chi-sono/Crescere",
                      color: "info",
                      label: "scopri di più",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="Parte 3: Tornando nei Paesi Bassi"
                    action={{
                      type: "internal",
                      route: "/it/Chi-sono/Il-mio-ritorno-ai-Paesi-Bassi",           
                      color: "info",
                      label: "scopri di più",
                    }}
                  />
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profilo;
