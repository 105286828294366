import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

function ImageModal({ open, onClose, imageSrc, altText }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogContent style={{ textAlign: 'center' }}>
        <img src={imageSrc} alt={altText} style={{ width: '100%', maxHeight: 'auto' }} />
      </DialogContent>
    </Dialog>
  );
}

export default ImageModal;
