/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


// @mui material components
import React, { useState } from 'react'; // Step 1: Import useState


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Logos/NCAA.png";
import post3 from "assets/images/Logos/NAIA.png";



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {

  const articleStructure = [
    { id: 'intro', title: 'NJCAA: Die Sprungbrett zu Großen Colleges und der NCAA', isSubheader: false },
  
    { id: 'Kapitel 1', title: 'Ursprung und Wachstum der NJCAA', isSubheader: true },
    { id: '1.1', title: 'Die Drei Divisionen der NJCAA', isSubheader: true },
    { id: '1.2', title: 'Der Übergang zu Größeren Colleges und der NCAA', isSubheader: true },
  
    { id: 'Kapitel 2', title: 'Die Rolle der Akademiker', isSubheader: true },
    { id: '2.1', title: 'Finanzen und Sichtbarkeit', isSubheader: true },
    { id: '2.2', title: 'Herausforderungen und die Zukunft', isSubheader: true },
    { id: '2.3', title: 'Abschließend', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
     <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhaltsverzeichnis
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verstecken</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Anzeigen</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="intro" mb={3}>

<MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>NJCAA: Die Sprungbrett zu Großen Colleges und der NCAA</MKTypography>
</MKBox>

<MKBox id="Kapitel 1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Ursprung und Wachstum der NJCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
Die National Junior College Athletic Association (NJCAA) wurde 1938 gegründet und hat sich seitdem zu einem prominenten Akteur in der amerikanischen College-Sportlandschaft entwickelt. Die Organisation bietet Sportmöglichkeiten für Junior Colleges, kleinere zweijährige Institutionen, die den Studierenden eine Grundlage für höhere Bildung und sportliche Exzellenz bieten.

<br />
<br />
In den Anfangsjahren war die NJCAA in ihrem Umfang begrenzt, aber sie ist gewachsen und hat sich angepasst, um den Bedürfnissen einer vielfältigen und sich wandelnden Studierendenschaft gerecht zu werden. Der Schwerpunkt liegt darauf, den Zugang zum sportlichen Wettbewerb zu ermöglichen und gleichzeitig den akademischen Fortschritt zu fördern.

</MKTypography>

<MKBox id="1.1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Die Drei Divisionen der NJCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
Die NJCAA ist in drei Divisionen unterteilt, die jeweils darauf ausgelegt sind, den unterschiedlichen Bedürfnissen und Möglichkeiten der Studierenden-Athleten gerecht zu werden:

<br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division I:{' '}
</MKTypography>
Diese Division umfasst Schulen, die in der Lage sind, vollständige Sportstipendien anzubieten, was zu einem höheren Wettbewerbsniveau führt. Studierende-Athleten haben hier die Möglichkeit, ihre Fähigkeiten zu entwickeln und auf einem intensiven Niveau zu konkurrieren, oft als Sprungbrett zu den NCAA-Divisionen.

<br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division II:{' '}
</MKTypography>
Hier finden wir ein Gleichgewicht zwischen sportlichem Wettbewerb und akademischer Verantwortung, wobei Schulen teilweise Stipendien anbieten dürfen. Dies gewährleistet ein gesundes Gleichgewicht und bietet den Studierenden-Athleten die Möglichkeit, sich in ihrem Sport zu beweisen, während sie gleichzeitig auf akademischen Erfolg vorbereitet werden.   <br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division III:{' '}
</MKTypography>
In dieser Division liegt der Schwerpunkt darauf, wettbewerbsfähige Sportmöglichkeiten ohne Sportstipendien anzubieten. Studierende-Athleten haben die Möglichkeit, an Sportarten teilzunehmen, während sie sich gleichzeitig vollständig am akademischen Leben beteiligen, was zu einer umfassenden College-Erfahrung führt.  </MKTypography>


<MKBox id="1.2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Der Übergang zu Größeren Colleges und der NCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
Die NJCAA spielt eine Schlüsselrolle als Sprungbrett für Studierende-Athleten, die ihre akademische und sportliche Laufbahn an größeren vierjährigen Institutionen, einschließlich derer in der NCAA, fortsetzen möchten. Viele Studierende-Athleten beginnen ihre Reise in der NJCAA, um ihre Fähigkeiten zu entwickeln, ihre akademischen Qualifikationen zu verbessern und sich bei Rekrutierern größerer Programme ins Rampenlicht zu spielen.


<br />
<br />
Indem sie sich in der wettbewerbsintensiven Umgebung der NJCAA beweisen, können Studierende-Athleten die Aufmerksamkeit von NCAA-Schulen auf sich ziehen und möglicherweise Stipendien verdienen, um ihre Ausbildung und Sportkarriere fortzusetzen. Dieser Prozess ist nicht nur für die Athleten selbst vorteilhaft, sondern trägt auch zur Stärkung von Sportprogrammen im ganzen Land bei.


</MKTypography>






                                  






<MKBox id="Kapitel 2" mb={3}>
  
  
  <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Die Rolle der Akademiker
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              Die NJCAA setzt sich stark für den akademischen Erfolg und die Entwicklung ihrer Studenten-Athleten ein. Es gelten strenge akademische Standards, und die angeschlossenen Schulen bieten wesentliche Unterstützung, um sicherzustellen, dass Athleten sowohl im Hörsaal als auch in ihrem Sport herausragend sind. Dies verdeutlicht die entscheidende Rolle eines ausgewogenen Ansatzes innerhalb der College-Sportlandschaft, obwohl das Niveau etwas niedriger sein kann als an einigen anderen Bildungseinrichtungen.
<br />
                <br />
                </MKTypography>

                <MKBox id="2.1" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Finanzen und Sichtbarkeit
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              Trotz begrenzter finanzieller Mittel im Vergleich zur NCAA spielt die NJCAA eine entscheidende Rolle bei der Unterstützung von Sportprogrammen und der Förderung von Chancen für Studenten-Athleten. Die Organisation arbeitet auch daran, ihre Sichtbarkeit und Reichweite zu erhöhen, um mehr Studenten und Athleten zu erreichen und zu inspirieren.


<br />
                </MKTypography>

                <MKBox id="2.2" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Herausforderungen und die Zukunft
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              Die NJCAA steht, wie andere Sportorganisationen, vor Herausforderungen, darunter die Bewältigung von Fragen der Gleichstellung im Sport und die Unterstützung der mentalen Gesundheit von Studenten-Athleten. Dennoch ist die Organisation aufgrund ihres Engagements für die Förderung von Möglichkeiten und die Unterstützung von Studenten-Athleten gut positioniert, um weiterhin eine positive Wirkung zu erzielen.



                </MKTypography>

                <MKBox id="2.3" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Abschließend
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              Die National Junior College Athletic Association ist eine wesentliche Komponente der amerikanischen College-Sportlandschaft, die einzigartige Möglichkeiten für Studenten-Athleten bietet, um zu konkurrieren, zu lernen und zu wachsen. Mit ihrem Engagement für akademische und sportliche Exzellenz bleibt die NJCAA eine einflussreiche Kraft bei der Gestaltung der Zukunft junger Athleten in Amerika.








<br />


                </MKTypography>

              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="NCAA"
                    description="NCAA: Das Herz des College-Sports in Amerika"
                    action={{
                      type: "internal",
                      route: "/de/NCAA",
                      color: "info",
                      label: "Mehr Lesen",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="NAIA"
                    description="Die NAIA: Ein Alternativer Weg im College-Sport in Amerika"
                    action={{
                      type: "internal",
                      route: "/de/NAIA",
                      color: "info",
                      label: "Mehr Lesen",
                     }}
                    />
                  </Grid>
                </Grid>
  
              </Grid>
          </Grid>
        </Container>
      </MKBox>
    );
  }
  
  export default Beschrijving;