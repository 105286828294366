import { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import SimplePricingCard from "examples/Cards/PricingCards/SimplePricingCard";
import SimplePricingCardRibbon from "examples/Cards/PricingCards/SimplePricingCardRibbon";
import { PopupButton } from "react-calendly";
import { Link } from 'react-scroll';
import TextField from "@mui/material/TextField";  // Ajouter cette ligne
import Box from '@mui/material/Box'; // Assurer l'importation de Box
import emailjs from 'emailjs-com';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Link as RouterLink } from 'react-router-dom';

function Services() {
  const [activeTab, setActiveTab] = useState(0);
  const [tabType, setTabType] = useState("mensuel");
  const [showModal, setShowModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [email, setEmail] = useState("");
  const [isAgreed, setIsAgreed] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  const handleTabType = ({ currentTarget }, newValue) => {
    setActiveTab(newValue);
    setTabType(currentTarget.id);
  };

  const handleEmailSubmit = async () => {
    if (email && isAgreed) {
      const emailParams = {
        from_email: email,
        // autres paramètres si nécessaire
      };

      emailjs.send('service_sj82src', 'template_2eyxhno', emailParams, 'xOBnOFlYt964jvT3q')
        .then((response) => {
          console.log('Email envoyé avec succès', response.status, response.text);
          alert('Lien demandé, veuillez vérifier votre boîte de réception.');
          setShowEmailModal(false);
          setEmail("");
          setIsAgreed(false);
        }, (error) => {
          console.log("Échec de l'envoi de l'email', error");
          alert('Demande échouée, veuillez réessayer.');
        });
    } else if (!isAgreed) {
      alert('Veuillez accepter la politique de confidentialité.');
    } else {
      alert('Veuillez saisir une adresse e-mail');
    }
  };

  return (
    <section id="sectionServices">
      <Container sx={{ pb: { xs: 4, lg: 4 }, pt: 12 }}>
          {/* Modal d'entrée de l'email */}
          <Modal
        open={showEmailModal}
        onClose={() => setShowEmailModal(false)}
        aria-labelledby="titre-modal"
        aria-describedby="description-modal"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: { xs: '90%', sm: '70%', md: '50%', lg: '40%' },
            maxWidth: '500px',
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <TextField
            label="Adresse e-mail"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ mb: 2 }}
          />
          {/* Checkbox pour l'accord de la politique de confidentialité */}
          <FormControlLabel
  control={<Checkbox checked={isAgreed} onChange={(e) => setIsAgreed(e.target.checked)} />}
  label={
    <span>
       J'ai lu la{' '}
      <RouterLink 
        to="/Privacy-Policy" 
        component="a" 
        target="_blank" 
        style={{ textDecoration: 'none' }}>
         politique de confidentialité
      </RouterLink> et j'accepte.
    </span>
  }
  sx={{ my: 2 }}
/>


          <MKButton variant="gradient" color="dark" onClick={handleEmailSubmit}>
            Envoyer
          </MKButton>
        </Box>
      </Modal>


      <Grid container item xs={12} justifyContent="center" md={8} mx="auto" textAlign="center">
      <Grid item xs={12}> {/* Pleine largeur pour le titre */}
    <MKTypography variant="h3" color="text">Services</MKTypography>
  </Grid>
  <Grid item xs={12}> {/* Pleine largeur pour le texte */}
    <MKTypography variant="body2" color="text">
    Tous les services offerts par Sport Scholarships America
</MKTypography>
  </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={10}
          lg={8}
          justifyContent="center"
          textAlign="center"
          mx="auto"
          mt={6}
        >
          <AppBar position="static">
          <Tabs value={activeTab} onChange={handleTabType}>
  <Tab
    id="mensuel"
    label={
      <Link 
        to="sectionServices" 
        spy={true}
        smooth={true}
        duration={500}
        offset={-70}
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <MKBox py={0.5} px={2} color="inherit">
          Services
        </MKBox>
      </Link>
    }
  />
  <Tab
    id="annuel"
    label={
      <Link 
        to="sectionProduits" 
        spy={true}
        smooth={true}
        duration={500}
        offset={-70}
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <MKBox py={0.5} px={2} color="inherit">
          Produits
        </MKBox>
      </Link>
    }
  />
</Tabs>
          </AppBar>
        </Grid>
        <Grid container spacing={3} mt={6}>
        <Modal open={showModal} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
          <Slide direction="down" in={showModal} timeout={500}>
            <MKBox
              position="relative"
              width="500px"
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              bgColor="white"
              shadow="xl"
            >
              <MKBox display="flex" alginItems="center" justifyContent="space-between" p={2}>
                <MKTypography variant="h5">
Choisissez un entretien préalable
</MKTypography>
                <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox p={2}>
                <MKTypography variant="body2" color="secondary" fontWeight="regular">
                Cher client,
                <br />
                  <br />

                  Je suis ravi de votre intérêt pour mon service standard ! Pour garantir que mon service réponde parfaitement à vos besoins spécifiques, je recommande fortement de réserver d'abord un entretien préalable sans engagement.
                  <br />
                  <br />
                  Lors de cet entretien, nous pourrons discuter en détail de votre situation et déterminer ensemble la meilleure approche pour vous. Cela permet une expérience plus personnalisée et m'aide à optimiser le service pour votre situation unique.


                </MKTypography>
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox display="flex" justifyContent="space-between" p={1.5}>
                <MKButton variant="gradient" color="dark" onClick={toggleModal}>
                  Fermer
                </MKButton>
                
                <PopupButton
    url="https://calendly.com/kay-duit"
    rootElement={document.getElementById("root")}
    text="PRENDRE RENDEZ-VOUS"
    className="custom-calendly-button"
  />
              </MKBox>
            </MKBox>
          </Slide>
        </Modal>
        <Grid item xs={12} sm={6} lg={3}>
  <SimplePricingCard
    color="dark"
    title="Guide d'information"
    description="Guide complet d'information en PDF"
    price={{ value: "Gratuit", type: "" }}
    action={{
      type: "downloadPDF",
      label: "Télécharger PDF",
      onClick: () => setShowEmailModal(true) // Fonction pour montrer le modal d'entrée de l'email
    }}
    specifications={["Guide complet d'information en PDF"]}
  />
</Grid>
<Grid item xs={12} sm={6} lg={3}>
  <SimplePricingCardRibbon
    color="dark"
    title="Entretien préalable"
    description="Consultation en ligne de 60 minutes"
    price={{ value: " € 14.99*", type: "" }}
    action={{
      type: "external", // Changer en externe pour Calendly
      route: "https://calendly.com/kay-duit", // Votre lien Calendly
      label: "PRENDRE RENDEZ-VOUS"
    }}
    specifications={[
      "Alignement des objectifs et ambitions",
      "Évaluation des performances sportives",
      "Plan d'intégration académique",
      "Stratégie de trajectoire individuelle",
      "Analyse des chances de succès aux États-Unis"
    ]}
  />
</Grid>

          <Grid item xs={12} sm={6} lg={3}>
  <SimplePricingCard
    color="dark"
    title="Standard"
    description="Votre chemin vers les États-Unis"
    price={{ value: "Sur demande", type: "" }}
    action={{
      type: "modal",
      label: "Demander",
      onClick: toggleModal
    }}
    specifications={[
      "Plan personnalisé",
      "Soutien à la création d'un portefeuille sportif",
      "Soutien à la préparation académique",
      "Promotion auprès des universités",
      "Négociation des bourses sportives",
      "Conseils sur le choix des universités et des bourses",
      "Soutien à la demande de visa",
      "Soutien à l'inscription",
      "Soutien aux affaires pratiques",
    ]}
  />
</Grid>
<Grid item xs={12} sm={6} lg={3}>
  <SimplePricingCard
    color="dark"
    title="Premium"
    description="Votre chemin vers les États-Unis"
    price={{ value: "Sur demande", type: "" }}
    action={{
      type: "modal",
      label: "Demander",
      onClick: toggleModal
    }}
    specifications={[
      "Plan personnalisé",
      "Soutien à la création d'un portefeuille sportif",
      "Soutien à la préparation académique",
      "Promotion auprès des universités",
      "Négociation des bourses sportives",
      "Conseils sur le choix des universités et des bourses",
      "Soutien à la demande de visa",
      "Soutien à l'inscription",
      "Soutien aux affaires pratiques",
      "Livres d'étude SAT/ACT",
      "Appel Zoom bi-hebdomadaire",
    ]}
  />
</Grid>
        </Grid>
      </Container>
      <Container>
    <Grid item xs={12} style={{ marginBottom: '80px' }}>
      <MKTypography variant="body2" color="text">
        {/* Votre texte ici */}
        *Auparavant, je proposais l'entretien préalable gratuitement. Malheureusement, cela a mené à de nombreux rendez-vous avec des individus non sérieux, ce qui était coûteux en termes de temps. Pour garantir la qualité de mes services et m'assurer que les conversations soient précieuses pour les deux parties, je demande maintenant une petite somme de €14.99. Ce montant aide à filtrer les demandes sérieuses tout en gardant la conversation informelle et centrée sur vos besoins spécifiques.

Je vous remercie de votre compréhension et j'attends avec impatience une conversation productive et éclairante avec vous.
      </MKTypography>
    </Grid>
  </Container>
    
    </section>
  );
}

export default Services;
