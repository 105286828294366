import React from 'react';
import { Container, Box, Typography, useTheme } from '@mui/material';
import backgroundImage from '../../../../../../assets/images/MapUSA.png';
import './markers.css';

function MapUSA() {
  const theme = useTheme();

  // Random positions for the markers
  const markers = [
    { top: "13%", left: "33%" },
    { top: "22%", left: "60%" },
    { top: "35%", left: "11%" },
    { top: "43%", left: "37%" },
    { top: "50%", left: "70%" },
    { top: "70%", left: "47%" },
    { top: "28%", left: "80%" },
    { top: "60%", left: "27%" },
    { top: "80%", left: "80%" }
  ];

  return (
    <Container
      maxWidth="lg"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          overflow: 'hidden',
          paddingTop: '50%', // adjust according to your image's aspect ratio
          position: 'relative',
          width: '100%', // Default width for smaller screens
          [theme.breakpoints.up('lg')]: {
            width: '80%',
            paddingTop: '40%', // Width for larger screens
          },
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: '50px',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            borderRadius: 'inherit',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundImage: theme.functions.linearGradient(
                theme.functions.rgba(theme.palette.gradients.info.main, 0.8),
                theme.functions.rgba(theme.palette.gradients.info.state, 0.8)
              ),
              borderRadius: 'inherit',
            },
          }}
        >
          {/* Markers */}
          {markers.map((marker, index) => (
          <div
          key={index}
          className="geopin"
          style={{
            position: "absolute",
            top: marker.top,
            left: marker.left,
          }}
        >
          <div className="geopulse"></div>
        </div>
          ))}
          <Typography
            variant="h3"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: 'white !important', // Use !important to override any conflicting styles
              zIndex: 1, // Ensure the text is on top of the image
              textAlign: 'center', // Center the text horizontally
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', // Text shadow effect
              [theme.breakpoints.down('md')]: {
                fontSize: '18px', // Decrease font size on smaller screens
              },
            }}
          >
Plus de 1 000 universités américaines proposent des bourses sportives.          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default MapUSA;
