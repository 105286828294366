/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import Image1 from "assets/images/Sports_Images/Ice Hockey1.jpeg";
import Image2 from "assets/images/Sports_Images/Ice Hockey2.jpg";
import Image3 from "assets/images/Sports_Images/Ice Hockey3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images

import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Beca de Hockey sobre Hielo en Estados Unidos: Cómo Estudiar y Jugar Hockey sobre Hielo en América', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capítulo 1: La Historia y Desarrollo del Hockey sobre Hielo en América', isSubheader: false },
    { id: '1.1', title: '1.1 Primeros Años y el Papel de las Universidades', isSubheader: true },
    { id: '1.2', title: '1.2 Crecimiento y Profesionalización', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capítulo 2: El Hockey sobre Hielo Universitario Hoy', isSubheader: false },
    { id: '2.1', title: '2.1 Principales Ligas y Conferencias', isSubheader: true },
    { id: '2.2', title: '2.2 Programas Destacados y Equipos Prestigiosos', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capítulo 3: Desde la Universidad hasta los Profesionales', isSubheader: false },
    { id: '3.1', title: '3.1 El Camino hacia el Profesionalismo', isSubheader: true },
    { id: '3.2', title: '3.2 El Draft de la NHL y Otras Oportunidades', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capítulo 4: Influencias Internacionales', isSubheader: false },
    { id: '4.1', title: '4.1 Jugadores Extranjeros en el Hockey sobre Hielo Universitario', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capítulo 5: Becas Deportivas y el Futuro de los Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 La Importancia de las Becas', isSubheader: true },
    { id: '5.2', title: '5.2 Cómo Puede Ayudar Sport Scholarships America', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contenidos
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Ocultar</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Beca de Hockey sobre Hielo en Estados Unidos: Cómo Estudiar y Jugar Hockey sobre Hielo en América
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Vista aérea de un estadio lleno en un partido universitario de hockey sobre hielo."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Un estadio lleno durante un partido universitario
      </figcaption>
    </figure>
    El hockey sobre hielo es uno de los deportes más populares en Estados Unidos. Es un deporte rápido, físico y emocionante que atrae a millones de aficionados. El hockey sobre hielo se juega en varios niveles, desde amateur hasta profesional. Pero uno de los niveles más importantes y prestigiosos es el hockey sobre hielo universitario.
    <br />
    <br />
    El hockey sobre hielo universitario es la versión del hockey sobre hielo jugada por estudiantes de universidades y colegios en América. Es parte del sistema educativo estadounidense, combinando deportes y académicos. El hockey sobre hielo universitario ofrece a los jóvenes jugadores la oportunidad de desarrollarse tanto académica como atléticamente mientras disfrutan de la vida estudiantil.
    <br />
    <br />
    El hockey sobre hielo universitario también es un trampolín hacia el mundo profesional. Muchos jugadores que juegan en la NHL (Liga Nacional de Hockey) u otras ligas profesionales han jugado primero hockey sobre hielo universitario. El hockey sobre hielo universitario es una excelente manera de prepararse para una carrera en el deporte.
    <br />
    <br />
    Pero, ¿cómo puede un joven jugador de hockey sobre hielo calificar para el hockey sobre hielo universitario? Ahí es donde Sportbeursamerika.nl puede ayudarte. Me especializo en guiar a estudiantes-atletas que desean obtener una beca deportiva en América. Una beca deportiva es una recompensa financiera que recibes de una universidad o colegio para estudiar y jugar deportes allí. Con una beca deportiva, puedes estudiar y jugar hockey sobre hielo en América sin incurrir en costos elevados.
    <br />
    <br />
    En este artículo, te proporcionaremos más información sobre el hockey sobre hielo universitario en América, su historia, desarrollo, ligas, equipos, oportunidades, influencias internacionales y, por supuesto, becas. También te contaré cómo puedo ayudarte a hacer realidad tu sueño. ¿Estás listo para aprender más sobre las becas de hockey sobre hielo en Estados Unidos? ¡Sigue leyendo!
  </MKTypography>
</MKBox>




<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 1: La Historia y Desarrollo del Hockey sobre Hielo en América
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El hockey sobre hielo es un deporte que ha existido en América durante más de un siglo. Tiene una historia rica y fascinante estrechamente ligada al papel de las universidades. En este capítulo, profundizamos en los orígenes, el crecimiento y la profesionalización del hockey sobre hielo en América.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Primeros Años y el Papel de las Universidades
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      El hockey sobre hielo se originó en Canadá, donde se jugó por primera vez en el siglo XIX. El juego se extendió rápidamente a Estados Unidos, volviéndose particularmente popular en los estados del noreste. Los primeros equipos universitarios se formaron a finales del siglo XIX y principios del XX, incluyendo a Yale, Harvard, Princeton y Dartmouth.
      <br />
      <br />
      Estos equipos jugaban partidos intercolegiales, atrayendo a menudo a grandes multitudes. También jugaban contra equipos canadienses, que solían ser más fuertes. Para elevar el nivel de juego, algunas universidades comenzaron a reclutar jugadores profesionales que se inscribían como estudiantes. Esta práctica generó controversia y críticas ya que comprometía el estatus amateur del deporte.
      <br />
      <br />
      Para poner fin a esta práctica, se fundó la Asociación de Hockey Intercolegial (IHA) en 1898, la primera colección de programas de hockey sobre hielo universitario en América. La IHA, a veces referida como la Liga de Hockey Intercolegial, incluía equipos como Brown, Columbia, la Universidad de Pensilvania y Yale. Esta organización era la única autoridad de campeonato para el hockey sobre hielo universitario en ese momento, determinando al campeón nacional de facto basado en el rendimiento de la temporada. La IHA continuó existiendo hasta 1913.
      <br />
      <br />
      Después de la disolución de la IHA, se estableció la Liga de Hockey Intercolegial (IHL) en 1912 por Harvard, Princeton y Yale. Esta liga fue una respuesta a la necesidad de que los mejores equipos universitarios continuaran compitiendo entre sí. La IHL existió hasta 1917 cuando las actividades fueron suspendidas debido a la Primera Guerra Mundial.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crecimiento y Profesionalización
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A lo largo del siglo XX, el hockey sobre hielo universitario creció hasta convertirse en un deporte nacional, jugado por cientos de equipos en diversas divisiones y conferencias. El hockey sobre hielo universitario también fue influenciado cada vez más por el surgimiento de ligas de hockey sobre hielo profesionales, como la NHL (fundada en 1917) y la World Hockey Association (fundada en 1972).
      <br />
      <br />
      Estas ligas profesionales proporcionaron oportunidades para que jugadores talentosos de la universidad avanzaran a un nivel superior. Muchos jugadores universitarios fueron seleccionados en el Draft de la NHL, el evento anual donde los equipos de la NHL eligen nuevos jugadores. El primer draft de la NHL en el que se seleccionó a un jugador universitario tuvo lugar en 1967. Al Karlander, un jugador de Michigan Tech, fue seleccionado por Detroit como la selección número 17 ese año. Desde entonces, más de mil jugadores universitarios han sido seleccionados por equipos de la NHL.
      <br />
      <br />
      El hockey sobre hielo universitario también fue testigo de momentos significativos en la historia del deporte. Uno de los más memorables fue el "Milagro sobre Hielo" en 1980 cuando el equipo olímpico estadounidense, compuesto principalmente por jugadores universitarios, derrotó a la Unión Soviética en un juego histórico durante los Juegos Olímpicos de Invierno en Lake Placid. Esto fue un logro notable ya que los soviéticos eran considerados el mejor equipo de hockey sobre hielo del mundo en ese momento.
      <br />
      <br />
      Por lo tanto, el hockey sobre hielo universitario tiene una historia larga e impresionante, siendo testigo de la pasión y el talento de los jugadores estadounidenses. El hockey sobre hielo universitario también ha contribuido al desarrollo e innovación del deporte al introducir nuevas reglas, tácticas y estilos.
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  





<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 2: El Hockey sobre Hielo Universitario Hoy
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El hockey sobre hielo universitario es uno de los deportes más competitivos y emocionantes en América hoy en día. Se juega por más de 400 equipos, divididos en tres divisiones de la NCAA, varias conferencias y zonas regionales. En este capítulo, proporcionaremos una visión general de las principales ligas y conferencias, los programas destacados y los equipos prestigiosos, y la estructura de la temporada y el campeonato.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Principales Ligas y Conferencias
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Jugadores de Massachusetts celebrando una victoria en el hockey sobre hielo universitario."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Jugadores de Massachusetts celebrando una victoria
        </figcaption>
      </figure>
      La NCAA (Asociación Nacional de Atletismo Universitario) es la organización que regula el hockey sobre hielo universitario. La NCAA divide a los equipos en tres divisiones basadas en el nivel, el presupuesto y el número de becas que ofrecen. Las divisiones son:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          División I: Esta es la división más alta donde compiten los mejores equipos y jugadores. La División I comprende 60 equipos, divididos en seis conferencias: Atlantic Hockey, Big Ten, ECAC Hockey, Hockey East, NCHC (Conferencia Nacional de Hockey Universitario) y WCHA (Asociación de Hockey Universitario del Oeste). Estas conferencias organizan sus propias competiciones y torneos a lo largo de la temporada.
        </li>
        <li>
          División II: Esta es una división intermedia con menos equipos y jugadores. Hay solo siete equipos en la División II, y no tienen su propia conferencia. Por lo general, compiten contra equipos de la División III u otras ligas.
        </li>
        <li>
          División III: Esta es la división más baja donde participan la mayoría de los equipos y jugadores. La División III consta de 84 equipos, divididos en siete conferencias: CCC (Conferencia Costera de la Commonwealth), MASCAC (Conferencia Atlética Colegial del Estado de Massachusetts), MIAC (Conferencia Atlética Intercolegial de Minnesota), NCHA (Asociación de Hockey Colegial del Norte), NEHC (Conferencia de Hockey de Nueva Inglaterra), SUNYAC (Conferencia Atlética de la Universidad Estatal de Nueva York) y UCHC (Conferencia de Hockey Universitario Unido). Estas conferencias también organizan sus propias competiciones y torneos durante la temporada.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Además de las divisiones y conferencias de la NCAA, también hay otras ligas y organizaciones que ofrecen hockey sobre hielo universitario.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programas Destacados y Equipos Prestigiosos
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      El hockey sobre hielo universitario tiene varios programas destacados y equipos prestigiosos que se destacan por su éxito, tradición, reputación e influencia. Algunos de estos programas y equipos incluyen:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Universidad de Dakota del Norte: Uno de los programas de hockey sobre hielo universitario más exitosos en América, compitiendo en la conferencia NCHC en la División I. La universidad ha ganado múltiples campeonatos de la NCAA y ha producido numerosos jugadores que han jugado en la NHL, incluidos nombres conocidos como Jonathan Toews, Zach Parise y T.J. Oshie.
        </li>
        <li>
          Boston College: Un destacado programa de hockey sobre hielo universitario en América, compitiendo en la conferencia Hockey East en la División I. Boston College tiene varios campeonatos nacionales en su haber. El programa también es conocido por desarrollar a más de 80 jugadores de la NHL, con exalumnos destacados como Johnny Gaudreau, Patrick Eaves y Brian Gionta.
        </li>
        <li>
          Universidad de Minnesota: Este histórico programa de hockey sobre hielo universitario, compitiendo en la conferencia Big Ten en la División I, ha logrado un impresionante número de campeonatos nacionales. Es conocido por producir un número récord de jugadores que han jugado en la NHL, incluidas estrellas como Phil Kessel, Paul Martin y Blake Wheeler.
        </li>
        <li>
          Universidad de Wisconsin: Uno de los programas de hockey sobre hielo universitario más prestigiosos en América, compitiendo en la conferencia Big Ten en la División I. Wisconsin ha ganado múltiples campeonatos nacionales y también ha entrenado a un número significativo de jugadores que han jugado en la NHL, incluidos Ryan Suter, Joe Pavelski y Dany Heatley.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estos programas y equipos son solo algunos ejemplos de los muchos que ofrece el hockey sobre hielo universitario. Hay muchos más equipos que tienen su propia historia, cultura e identidad. El hockey sobre hielo universitario es un deporte diverso y dinámico con mucho que ofrecer a jugadores y fanáticos.
    </MKTypography>
  </MKBox>
</MKBox>





                                  








<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 3: Desde la Universidad hasta los Profesionales
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El hockey sobre hielo universitario no es solo un deporte; también es un terreno de aprendizaje. Muchos jugadores que participan en el hockey sobre hielo universitario tienen ambiciones de avanzar al mundo profesional. En este capítulo, discutiremos cómo el hockey sobre hielo universitario puede servir como un trampolín hacia la NHL u otras ligas profesionales, qué exalumnos han llegado al mundo del hockey sobre hielo profesional y qué oportunidades existen más allá de la NHL.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 El Camino hacia el Profesionalismo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      El hockey sobre hielo universitario es una excelente manera de prepararse para una carrera en el deporte. Al jugar hockey sobre hielo universitario, puedes mejorar tus habilidades, conocimientos y experiencia mientras obtienes un título universitario. El hockey sobre hielo universitario también te brinda la oportunidad de competir contra otros jugadores talentosos que te desafían e inspiran.
      <br />
      <br />
      Una de las principales ventajas del hockey sobre hielo universitario es que aumenta tu visibilidad y exposición. Jugar hockey sobre hielo universitario puede atraer la atención de cazatalentos, entrenadores y gerentes de equipos profesionales que están buscando nuevos jugadores. También puedes participar en exhibiciones, campamentos y clínicas para mostrar tus habilidades a posibles empleadores.
      <br />
      <br />
      Otra ventaja del hockey sobre hielo universitario es que ofrece flexibilidad y opciones. Al jugar hockey sobre hielo universitario, puedes posponer la toma de decisiones sobre tu futuro. Puedes optar por hacer la transición a un equipo profesional después de tu primer, segundo, tercer o cuarto año, según tu situación y preferencia. También puedes optar por completar tus estudios antes de convertirte en profesional.
      <br />
      <br />
      El hockey sobre hielo universitario ha producido muchos jugadores que han tenido éxito en el mundo del hockey sobre hielo profesional. Algunos de estos jugadores incluyen:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Jack Eichel: Jugó una temporada para la Universidad de Boston en la conferencia Hockey East en la División I. Ganó el premio Hobey Baker como el mejor jugador del año en 2015. Fue seleccionado como el segundo en general en el Draft de la NHL de 2015 por los Buffalo Sabres, donde se convirtió en el capitán y jugador estrella. En 2023, ganó la Stanley Cup con los Vegas Golden Knights.
        </li>
        <li>
          Cale Makar: Jugó dos temporadas para UMass Amherst en la conferencia Hockey East en la División I. También ganó el premio Hobey Baker como el mejor jugador del año en 2019. Fue seleccionado como el cuarto en general en el Draft de la NHL de 2017 por el Colorado Avalanche, donde actualmente juega como uno de los mejores defensas de la liga.
        </li>
        <li>
          Hilary Knight: Hilary Knight jugó cuatro temporadas para la Universidad de Wisconsin en la conferencia WCHA en la División I. Era una jugadora muy talentosa y fue nominada como una de las diez finalistas principales para el premio Memorial Patty Kazmaier en 2009, 2011 y 2012. Aunque no ganó el premio, fue dos veces All-American de Primera Equipo. Knight luego jugó para el Boston Pride en la NWHL (Liga Nacional de Hockey Femenino) y el equipo nacional de EE. UU., con el que ganó dos medallas de plata olímpicas y múltiples campeonatos mundiales.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estos jugadores son solo algunos ejemplos de los muchos que han convertido sus carreras de hockey sobre hielo universitario en carreras profesionales. El hockey sobre hielo universitario es un camino probado hacia el profesionalismo para muchos jugadores.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 El Draft de la NHL y Otras Oportunidades
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La NHL (Liga Nacional de Hockey) es la principal liga profesional de hockey sobre hielo en el mundo. Es el sueño de muchos jóvenes jugadores jugar en la NHL algún día. Una de las formas de lograrlo es a través del Draft de la NHL, el evento anual donde los equipos de la NHL seleccionan nuevos jugadores.
      <br />
      <br />
      El Draft de la NHL es un momento emocionante y significativo para muchos jugadores universitarios. Es una oportunidad para ser elegido por un equipo de la NHL, que te ofrece un contrato para jugar para ellos. También reconoce tu talento, potencial y trabajo duro.
      <br />
      <br />
      El Draft de la NHL consta de siete rondas en las que cada equipo de la NHL selecciona a un jugador por turno. El orden de selección se determina mediante una lotería, con los equipos que tuvieron los peores resultados en la temporada anterior teniendo una mejor oportunidad de elegir primero. El Draft de la NHL generalmente tiene lugar en junio o julio después del final de la temporada de hockey sobre hielo universitario.
      <br />
      <br />
      Para ser elegible para el Draft de la NHL, debes cumplir con ciertos criterios. Debes ser un jugador norteamericano entre las edades de 18 y 20 años o un jugador internacional entre las edades de 18 y 21 años. No debes haber sido seleccionado previamente por un equipo de la NHL ni haber renunciado a tus derechos.
      <br />
      <br />
      Ser seleccionado por un equipo de la NHL no significa que debas jugar inmediatamente para ellos. Puedes optar por regresar a tu equipo universitario y completar tus estudios mientras retienes los derechos del equipo de la NHL. También puedes optar por unirte al equipo de la NHL o su filial de ligas menores, donde puedes seguir desarrollándote y entrenando.
      <br />
      <br />
      Sin embargo, el Draft de la NHL no es la única forma de llegar a la NHL. Hay otras posibilidades, como la agencia libre. Esto ocurre cuando tú, como jugador, no tienes un contrato con un equipo de la NHL y eres libre de negociar con cualquier equipo que desees. Esto puede suceder si no fuiste seleccionado en el draft o si tu contrato con un equipo de la NHL expira o es terminado. Como agente libre, puedes intentar asegurar un contrato con un equipo de la NHL que esté interesado en ti.
      <br />
      <br />
      Sin embargo, la NHL no es la única liga profesional de hockey sobre hielo en el mundo. Hay otras ligas donde puedes jugar como jugador y ganarte la vida. Algunas de estas ligas incluyen:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          KHL (Liga Continental de Hockey): Esta es la principal liga profesional de hockey sobre hielo en Europa y Asia. Está compuesta por 24 equipos de ocho países: Rusia, Finlandia, Bielorrusia, Kazajistán, China, Letonia, Eslovaquia y la República Checa. Ofrece un alto nivel de juego y salario, atrayendo jugadores de diversos países.
        </li>
        <li>
          SHL (Liga Sueca de Hockey): Esta es la principal liga profesional de hockey sobre hielo en Suecia. Está compuesta por 14 equipos que compiten por el campeonato nacional. Es conocida por su juego rápido y técnico y ha producido muchos jugadores que han jugado en la NHL.
        </li>
        <li>
          Liiga (Liga Finlandesa de Hockey): Esta es la principal liga profesional de hockey sobre hielo en Finlandia. Está compuesta por 15 equipos que compiten por el campeonato nacional. Es conocida por su juego físico y táctico y también ha producido muchos jugadores que han jugado en la NHL.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estas ligas son solo algunos ejemplos de las muchas ligas en todo el mundo. Hay muchas más ligas en otros países, incluyendo Suiza, Alemania, Francia, Noruega, Dinamarca, Austria, Polonia, Japón y Australia. Estas ligas ofrecen diferentes niveles de juego, salarios y estilos de vida a los jugadores.
      <br />
      <br />
      El hockey sobre hielo universitario, por lo tanto, no es el final del camino para muchos jugadores. En cambio, es el comienzo de un viaje emocionante hacia el mundo profesional. El hockey sobre hielo universitario ofrece muchas oportunidades y opciones para los jugadores que persiguen sus sueños.
    </MKTypography>
  </MKBox>
</MKBox>
                              






                                  








<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 4: Influencias Internacionales
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El hockey sobre hielo universitario es un deporte que no solo es estadounidense, sino también internacional. El hockey sobre hielo universitario está influenciado por la presencia y el rendimiento de jugadores extranjeros en las competiciones universitarias estadounidenses. El hockey sobre hielo universitario también influye en las oportunidades y el desarrollo de jugadores estadounidenses en el extranjero. En este capítulo, discutiremos el papel y el impacto de los jugadores internacionales en el hockey sobre hielo universitario y las oportunidades para que los jugadores estadounidenses jueguen internacionalmente.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Jugadores Extranjeros en el Hockey sobre Hielo Universitario
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Jugadores de hockey sobre hielo de Quinnipiac celebrando un gol."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Jugadores de Quinnipiac celebrando un gol
        </figcaption>
      </figure>
      El hockey sobre hielo universitario es un deporte que está abierto a jugadores de todo el mundo. Hay muchos jugadores extranjeros que vienen a América para jugar hockey sobre hielo universitario, con diversos antecedentes, culturas y nacionalidades. Algunos de estos jugadores incluyen:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Daryl Watts: Es una jugadora de hockey sobre hielo canadiense que jugó para Boston College y Wisconsin en la División I de la NCAA durante dos temporadas. Ganó el premio Patty Kazmaier en 2018 como estudiante de primer año, convirtiéndose en la primera estudiante de primer año en ganar el premio. Watts luego jugó para Toronto Six en la Premier Hockey Federation (PHF), anteriormente conocida como National Women’s Hockey League (NWHL), y firmó un contrato récord con Toronto Six en 2023.
        </li>
        <li>
          Rod Brind'Amour: Nacido en Canadá, jugó hockey sobre hielo universitario en la Universidad Estatal de Michigan antes de pasar a la NHL. Tuvo una carrera profesional muy exitosa, con puntos destacados que incluyen ganar la Stanley Cup.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estos jugadores son solo algunos ejemplos de los muchos jugadores extranjeros que juegan o han jugado hockey sobre hielo universitario. Hay muchos más jugadores de otros países como Suecia, Rusia, la República Checa, Alemania, Francia, Suiza, Dinamarca, Japón y Australia.
      <br />
      <br />
      Estos jugadores extranjeros tienen un papel e impacto importantes en el hockey sobre hielo universitario. Aportan su propio estilo, habilidades y experiencia a las competiciones universitarias estadounidenses. Enriquecen el nivel y la diversidad del juego. También generan más interés y atención para el hockey sobre hielo universitario en sus propios países y regiones.
      <br />
      <br />
      El hockey sobre hielo universitario es un deporte que se beneficia de las influencias internacionales. Es un deporte que acoge y aprecia a jugadores de todo el mundo.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  


<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Becas Deportivas y el Futuro de los Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Las becas deportivas ofrecen numerosas oportunidades para que los jóvenes atletas muestren su talento y desarrollen sus habilidades. Una de las oportunidades más significativas es obtener una beca deportiva para estudiar y competir a nivel universitario. En este capítulo, discutiremos la importancia de las becas deportivas y cómo Sport Scholarships America puede ayudarte a hacer realidad tu sueño.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 La Importancia de las Becas
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Una beca deportiva es un apoyo financiero proporcionado por una universidad a un estudiante-atleta para combinar estudios y deporte. Puede cubrir los costos de matrícula, libros, alojamiento, comidas y otros gastos. Además, puede ofrecer beneficios como tutoría académica, atención médica, instalaciones de entrenamiento y equipo.
      <br />
      <br />
      Obtener una beca deportiva es competitivo, con muchos factores que influyen en el proceso de adjudicación, incluyendo logros académicos, rendimiento deportivo, personalidad, motivación, liderazgo y adaptabilidad. Por lo tanto, es importante prepararse bien y destacarse entre la competencia.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Cómo Puede Ayudar Sport Scholarships America
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo de Sport Scholarships America, que muestra equipamiento deportivo y una gorra de graduación, representando el enfoque en becas deportivas para estudiantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America es una organización especializada en guiar a jóvenes talentos deportivos hacia becas deportivas. Con expertos apasionados y experimentados, Sport Scholarships America apoya a los estudiantes-atletas en cada paso del camino, desde la orientación inicial hasta la colocación final. Sus servicios incluyen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Una entrevista personal para discutir los deseos, posibilidades y expectativas del estudiante-atleta.</li>
        <li>Una evaluación profesional del nivel académico y deportivo del estudiante-atleta.</li>
        <li>Una extensa red de contactos con entrenadores, universidades y colegios.</li>
        <li>Promoción efectiva del perfil y videos del estudiante-atleta ante entrenadores potenciales.</li>
        <li>Asesoramiento experto sobre las mejores opciones basadas en las preferencias, metas y presupuesto del estudiante-atleta.</li>
        <li>Negociación de las mejores ofertas de becas en nombre del estudiante-atleta.</li>
        <li>Asistencia en la solicitud de documentos necesarios, como visa, expediente académico, diploma y pruebas de admisión.</li>
        <li>Preparación para la vida del estudiante-atleta durante su beca, incluyendo cultura, idioma, reglas y consejos.</li>
        <li>Seguimiento y atención posterior durante el período de la beca.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      ¿Quieres explorar oportunidades de becas y alcanzar tus metas deportivas y académicas? <Link to="/es/Servicios-Productos">Consulta la gama completa</Link> de servicios ofrecidos por Sport Scholarships America y programa una consulta hoy mismo.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;