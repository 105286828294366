/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Waterpol1.jpeg";
import Image2 from "assets/images/Sports_Images/Waterpolo2.jpeg";
import Image3 from "assets/images/Sports_Images/Waterpolo3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Becas de Water Polo en América: Cómo Estudiar y Jugar en la Tierra de las Barras y las Estrellas', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capítulo 1: Historia y Desarrollo del Water Polo en América', isSubheader: false },
    { id: '1.1', title: '1.1 Primeros Años', isSubheader: true },
    { id: '1.2', title: '1.2 Crecimiento y Profesionalización', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capítulo 2: El Water Polo Universitario Hoy', isSubheader: false },
    { id: '2.1', title: '2.1 Competencias y Conferencias Clave', isSubheader: true },
    { id: '2.2', title: '2.2 Programas Destacados y Equipos Prestigiosos', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capítulo 3: De la Universidad a los Profesionales', isSubheader: false },
    { id: '3.1', title: '3.1 El Camino hacia el Profesionalismo', isSubheader: true },
    { id: '3.2', title: '3.2 Ligas Profesionales', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capítulo 4: Influencias y Competencias Internacionales', isSubheader: false },
    { id: '4.1', title: '4.1 Influencias Extranjeras', isSubheader: true },
    { id: '4.2', title: '4.2 Estadounidenses en Competencias Internacionales', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capítulo 5: Becas Deportivas y el Futuro de los Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 La Importancia de las Becas', isSubheader: true },
    { id: '5.2', title: '5.2 Cómo Puede Ayudar Sport Scholarships America', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contenidos
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Ocultar</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Becas de Water Polo en América: Cómo Estudiar y Jugar en la Tierra de las Barras y las Estrellas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Estadio lleno de fanáticos esperando un partido universitario de water polo."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Calma antes de un partido
      </figcaption>
    </figure>
    El water polo se originó en Europa a finales del siglo XIX como una forma de rugby acuático y rápidamente ganó popularidad en varios países, incluido América. El primer partido oficial en América se jugó en 1888 entre dos clubes en Nueva York. Desde entonces, el water polo ha desarrollado significativamente en América, tanto a nivel profesional como universitario.
    <br />
    <br />
    El water polo universitario es uno de los principales factores en el desarrollo de jugadores de water polo en América. Muchos jugadores comienzan sus carreras en la escuela secundaria y luego pasan a la universidad, donde tienen la oportunidad de competir contra los mejores equipos y jugadores del país. El water polo universitario también proporciona una excelente educación académica, permitiendo a los jugadores combinar su pasión por el deporte con sus objetivos educativos.
    <br />
    <br />
    Pero, ¿cómo puedes, como estudiante holandés, estudiar y jugar en América? Ahí es donde Sportbeursamerika.nl puede ayudar. Sportbeursamerika.nl es una organización especializada en ayudar a los estudiantes a obtener becas de water polo en América. Sportbeursamerika.nl cuenta con un equipo de expertos que pueden guiarte en cada paso del proceso, desde encontrar la universidad adecuada hasta manejar todos los asuntos prácticos.
    <br />
    <br />
    Si estás interesado en obtener una beca de water polo en América, sigue leyendo para obtener más información sobre esta emocionante oportunidad.
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 1: Historia y Desarrollo del Water Polo en América
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El water polo tiene una larga y rica historia en América. Es uno de los deportes olímpicos más antiguos, jugado por primera vez en los Juegos Olímpicos de 1900 en París, donde América también participó. Desde entonces, América ha competido en cada competencia olímpica de water polo, excepto en 1976 y 1980, cuando se retiró en protesta contra las situaciones políticas en Sudáfrica y la Unión Soviética, respectivamente.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Primeros Años
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Los primeros clubes de water polo en América se establecieron en Nueva York y Boston a fines del siglo XIX. Jugaban según las reglas inglesas, que eran mucho más duras y violentas que las reglas modernas. El deporte rápidamente se hizo popular entre los inmigrantes de Europa, especialmente de Irlanda y Escocia. El primer campeonato nacional se celebró en 1890 y fue ganado por el Sydenham Swimmers Club.
      <br />
      <br />
      Los primeros equipos universitarios se formaron a principios del siglo XX, especialmente en la costa este. Las universidades de la Ivy League como Harvard, Yale y Princeton dominaron el deporte hasta la década de 1920. La primera competencia intercolegial se organizó en 1912 y fue ganada por Princeton.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crecimiento y Profesionalización
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      En la década de 1920, el water polo comenzó a expandirse a otras partes del país, especialmente a California. Allí se desarrolló un nuevo estilo de juego, centrado en la velocidad, la agilidad y el lanzamiento en lugar del contacto físico. Los equipos de California rápidamente se convirtieron en los mejores del país, ganando la mayoría de los títulos nacionales e intercolegiales.
      <br />
      <br />
      En la década de 1930, surgieron las primeras ligas profesionales en América, como la American Water Polo League y la Pacific Coast Water Polo League. Estas ligas atrajeron a muchos espectadores y atención de los medios, elevando el nivel y la popularidad del deporte. Muchos jugadores profesionales también eran jugadores universitarios o graduados que continuaban sus carreras después de sus estudios.
      <br />
      <br />
      Una de las figuras clave en la historia del water polo estadounidense fue James "Jimmy" Smith, considerado el "padre del water polo moderno". Fue jugador, entrenador, árbitro, organizador y promotor del deporte. Jugó para varios clubes y universidades, incluida UCLA, donde luego se convirtió en entrenador. Llevó al equipo estadounidense a dos medallas de bronce olímpicas en 1924 y 1932. También fue uno de los fundadores del International Swimming Hall of Fame, donde fue incluido en 1965.
    </MKTypography>
  </MKBox>
</MKBox>



                                              





                                  







<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 2: El Water Polo Universitario Hoy
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El water polo universitario es uno de los deportes más prestigiosos y competitivos en América. Ofrece una oportunidad única para que los estudiantes combinen sus ambiciones académicas y atléticas y compitan contra los mejores jugadores del país. El water polo universitario también es una fuente significativa de talento para el equipo nacional y las ligas profesionales.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Competencias y Conferencias Clave
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Acción en el agua: jugador dispara a la portería durante un partido de water polo."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Jugadores en acción
        </figcaption>
      </figure>
      El water polo universitario está regulado por la Asociación Atlética Universitaria Nacional (NCAA), que divide el deporte en tres divisiones basadas en el nivel y la cantidad de becas disponibles. La División I es la división más alta, que consta de aproximadamente 30 equipos, principalmente de California. La División II y III tienen alrededor de 20 equipos cada una, más distribuidos por todo el país.
      <br />
      <br />
      Cada año, se lleva a cabo un campeonato nacional para cada división, con los mejores equipos de cada conferencia compitiendo por el título. Una conferencia es un grupo de universidades que están geográfica o históricamente conectadas y compiten regularmente entre sí. Las principales conferencias de water polo incluyen:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          La Mountain Pacific Sports Federation (MPSF), que consta de ocho equipos de California, incluidos UCLA, Stanford, USC y Berkeley. Esta conferencia se considera la más fuerte y prestigiosa del país y ha ganado la mayoría de los títulos nacionales.
        </li>
        <li>
          La Western Water Polo Association (WWPA), que consta de nueve equipos de California, Colorado, Utah y Hawái. Esta conferencia es la segunda más fuerte del país y también ha ganado algunos títulos nacionales.
        </li>
        <li>
          La Asociación de Water Polo Universitario (CWPA), que consta de 18 equipos de la costa este, incluidos Harvard, Princeton, Brown y la Armada. Esta conferencia es la tercera más fuerte del país y ocasionalmente ha ganado un título nacional.
        </li>
        <li>
          La Conferencia Atlética Interuniversitaria del Sur de California (SCIAC), que consta de nueve equipos del sur de California, incluidos Pomona-Pitzer, Claremont-Mudd-Scripps y Occidental. Esta conferencia es la cuarta más fuerte del país y compite principalmente en la División III.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programas Destacados y Equipos Prestigiosos
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      El water polo universitario tiene varios programas líderes conocidos por sus excelentes actuaciones, tanto en deportes como en académicos. Estos programas atraen a muchos jugadores talentosos que se benefician de las instalaciones, entrenadores y educación de alta calidad que proporcionan. Algunos de estos programas son:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          La Universidad de California, Los Ángeles (UCLA) es conocida por su excepcional programa de water polo, que se encuentra entre los más exitosos de la historia. Con un impresionante número de títulos nacionales para ambos equipos masculinos y femeninos, el programa ocupa un lugar destacado en el deporte. Los partidos se juegan en el Spieker Aquatics Center, con una capacidad para 2,500 espectadores. El equipo de UCLA es famoso por su estilo de juego rápido y dinámico y ha producido una cantidad significativa de medallistas olímpicos.
        </li>
        <li>
          La Universidad de Stanford también cuenta con un prestigioso programa de water polo con numerosos títulos nacionales tanto para equipos masculinos como femeninos. Los equipos juegan sus partidos en casa en el Avery Aquatic Center, que tiene capacidad para 2,530 espectadores. Los equipos de water polo de Stanford son conocidos por sus habilidades técnicas y tácticas y también han contribuido al desarrollo de muchos medallistas olímpicos.
        </li>
        <li>
          La Universidad del Sur de California (USC) también tiene un fuerte programa de water polo con múltiples campeonatos nacionales tanto para hombres como para mujeres. Su base principal es el Uytengsu Aquatics Center, con capacidad para 2,500 espectadores. El equipo de USC se caracteriza por un estilo de juego físico y agresivo y también ha entrenado a un número significativo de medallistas olímpicos.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  









<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 3: De la Universidad a los Profesionales
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El water polo universitario no solo es una excelente manera de estudiar y jugar en América, sino también un posible trampolín hacia una carrera profesional. Muchos jugadores universitarios pasan a jugar en ligas profesionales, tanto en América como en el extranjero, después de graduarse. Algunos de ellos también son seleccionados para el equipo nacional, que participa en torneos internacionales como los Juegos Olímpicos, los Campeonatos del Mundo y la Liga Mundial.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 El Camino hacia el Profesionalismo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Aunque el water polo es un deporte popular y competitivo en América, aún no es tan comercial y lucrativo como algunos otros deportes como el baloncesto, el fútbol o el béisbol. Por lo tanto, no hay muchas ligas o equipos profesionales en América, y los salarios y premios son relativamente bajos. La mayoría de los jugadores profesionales también tienen otro trabajo o fuente de ingresos además de su carrera en el water polo.
      <br />
      <br />
      La principal liga profesional en América es la Liga Nacional de Water Polo (NWPL), que se estableció en 2018 como sucesora de la Liga Americana de Water Polo (AWPL). La NWPL consta de ocho equipos, cada uno compuesto por 15 jugadores. Los equipos juegan una temporada regular de 14 partidos, seguida de un torneo de playoffs para determinar al campeón. El campeón actual es el Club de Water Polo de Los Ángeles, que ganó el título en 2022.
      <br />
      <br />
      La mayoría de los jugadores en la NWPL son ex jugadores universitarios o graduados que continúan sus carreras después de sus estudios. Algunos de ellos también son miembros del equipo nacional, que entrena y juega regularmente en el Centro de Entrenamiento Olímpico en Colorado Springs. El equipo nacional está dirigido por Dejan Udovicic, un ex jugador y entrenador serbio que ha estado al mando desde 2013.
      <br />
      <br />
      Uno de los ex alumnos más exitosos del water polo universitario es Tony Azevedo, quien es considerado uno de los mejores jugadores de todos los tiempos. Jugó para la Universidad de Stanford, donde fue nombrado Jugador del Año de la NCAA cuatro veces. También jugó para varios equipos profesionales en Europa y América, incluidos Brescia (Italia), Olympiacos (Grecia) y Long Beach Shore (América). También compitió en cinco Juegos Olímpicos, ganando una medalla de plata en 2008. A menudo se le llama "El Salvador" porque a menudo marcaba goles decisivos para su equipo.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Ligas Profesionales
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Además de la NWPL, hay otras ligas profesionales en América, como la Major League Water Polo (MLWP) y la Premier Water Polo League (PWPL). Sin embargo, estas ligas son menos establecidas y menos populares que la NWPL y tienen menos equipos y jugadores. Tampoco son reconocidas por USA Water Polo, la federación nacional responsable de organizar y regular el water polo en América.
      <br />
      <br />
      Por lo tanto, muchos jugadores estadounidenses eligen continuar sus carreras profesionales en el extranjero, donde hay más oportunidades y mejores condiciones. Europa es el destino principal para los jugadores de water polo estadounidenses porque el continente cuenta con algunas de las ligas más fuertes y prestigiosas del mundo. Algunas de estas ligas incluyen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          La Liga de Campeones LEN, considerada la competición de clubes más importante de Europa. Consta de 16 equipos de diversos países que compiten por el título europeo. El campeón actual es Pro Recco (Italia), quien ganó el título en 2021.
        </li>
        <li>
          La Eurocopa LEN, considerada la segunda competición de clubes en Europa. Consta de 32 equipos de diferentes países que compiten por un lugar en la Liga de Campeones. El campeón actual es Orvosegyetem SC (Hungría), quien ganó el título en 2021.
        </li>
        <li>
          Ligas nacionales organizadas por las respectivas federaciones de cada país. Algunas de las ligas nacionales más fuertes y populares incluyen Serie A1 (Italia), Liga Premaat (España), A1 Ethniki (Grecia) y OB I (Hungría).
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>






                                  






<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 4: Influencias y Competencias Internacionales
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El water polo es un deporte global jugado y seguido por millones de personas en todo el mundo. También es un deporte que evoluciona e innova constantemente, gracias a la influencia de diversas culturas, estilos y tradiciones. El water polo estadounidense no es una excepción, ya que ha aprendido y se ha beneficiado del water polo internacional, tanto a nivel de club como nacional.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Influencias Extranjeras
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Partido por el campeonato de water polo de la NCAA"
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Un partido por el campeonato de la NCAA
        </figcaption>
      </figure>
      Como hemos visto, el water polo se originó en Europa y se extendió a otros continentes, incluyendo América. Por lo tanto, el water polo europeo ha tenido una influencia significativa en el water polo estadounidense, especialmente en cuanto a técnica, táctica y estrategia. Muchos jugadores y entrenadores estadounidenses han mejorado sus habilidades y conocimientos al jugar o entrenar en Europa o al aprender de jugadores y entrenadores europeos.
      <br />
      <br />
      Uno de los países europeos más influyentes para el water polo estadounidense es Hungría, que se considera la nación de water polo más exitosa del mundo. Hungría ha ganado 15 medallas de oro olímpicas, más que cualquier otro país. Hungría es conocida por su estilo de juego creativo e inteligente, que se basa en un manejo fuerte del balón, pases rápidos y tiros precisos. Muchos jugadores y entrenadores húngaros han dejado su huella en el water polo estadounidense, como Tibor Benedek, Zoltan Szecsi y Attila Banhidy.
      <br />
      <br />
      Otro país europeo influyente para el water polo estadounidense es Serbia, que también es una de las principales naciones de water polo a nivel mundial. Serbia ha ganado 7 medallas de oro olímpicas, siendo la más reciente en 2016. Serbia es conocida por su estilo de juego físico y agresivo, que se basa en una fuerte defensa, rápidos contraataques y tiros potentes. Muchos jugadores y entrenadores serbios han compartido su experiencia y conocimientos con el water polo estadounidense, incluidos Dejan Udovicic, Andrija Prlainovic y Filip Filipovic.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Estadounidenses en Competencias Internacionales
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Además de aprender del water polo internacional, el water polo estadounidense también ha contribuido significativamente al water polo internacional, especialmente en términos de rendimiento, innovación y promoción. Muchos jugadores y entrenadores estadounidenses han participado en competiciones internacionales, tanto a nivel de club como nacional, donde han mostrado sus talentos y han mejorado su reputación.
      <br />
      <br />
      Una de las competiciones de water polo internacional más importantes son los Juegos Olímpicos, que se celebran cada cuatro años. El equipo estadounidense siempre ha participado en esta competición, excepto en 1976 y 1980. El equipo estadounidense ha ganado un total de 12 medallas olímpicas: 3 de oro, 4 de plata y 5 de bronce. La medalla más reciente fue una medalla de plata para los hombres en 2008. El equipo estadounidense actualmente ocupa el cuarto lugar en el mundo.
      <br />
      <br />
      Otra competencia internacional importante para el water polo es el Campeonato Mundial, que se celebra cada dos años. El equipo estadounidense también ha participado consistentemente en esta competición desde su primera edición en 1973. El equipo estadounidense ha ganado un total de 9 medallas en el Campeonato Mundial: 2 de oro, 3 de plata y 4 de bronce. La medalla más reciente fue una medalla de bronce para las mujeres en 2019. El equipo estadounidense actualmente ocupa el tercer lugar en el mundo.
      <br />
      <br />
      Una tercera competencia internacional importante para el water polo es la Liga Mundial, que se celebra anualmente. El equipo estadounidense también ha participado consistentemente en esta competición desde su primera edición en 2002. El equipo estadounidense ha ganado un total de 10 medallas en la Liga Mundial: 3 de oro, 4 de plata y 3 de bronce. La medalla más reciente fue una medalla de oro para las mujeres en 2021. El equipo estadounidense actualmente ocupa el segundo lugar en el mundo.
    </MKTypography>
  </MKBox>
</MKBox>







                                  



<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Becas Deportivas y el Futuro de los Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Las becas deportivas ofrecen numerosas oportunidades para que los jóvenes atletas muestren su talento y desarrollen sus habilidades. Una de las oportunidades más significativas es obtener una beca deportiva para estudiar y competir a nivel universitario. En este capítulo, discutiremos la importancia de las becas deportivas y cómo Sport Scholarships America puede ayudarte a hacer realidad tu sueño.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 La Importancia de las Becas
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Una beca deportiva es un apoyo financiero proporcionado por una universidad a un estudiante-atleta para combinar estudios y deporte. Puede cubrir los costos de matrícula, libros, alojamiento, comidas y otros gastos. Además, puede ofrecer beneficios como tutoría académica, atención médica, instalaciones de entrenamiento y equipo.
      <br />
      <br />
      Obtener una beca deportiva es competitivo, con muchos factores que influyen en el proceso de adjudicación, incluyendo logros académicos, rendimiento deportivo, personalidad, motivación, liderazgo y adaptabilidad. Por lo tanto, es importante prepararse bien y destacarse entre la competencia.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Cómo Puede Ayudar Sport Scholarships America
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo de Sport Scholarships America, que muestra equipamiento deportivo y una gorra de graduación, representando el enfoque en becas deportivas para estudiantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America es una organización especializada en guiar a jóvenes talentos deportivos hacia becas deportivas. Con expertos apasionados y experimentados, Sport Scholarships America apoya a los estudiantes-atletas en cada paso del camino, desde la orientación inicial hasta la colocación final. Sus servicios incluyen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Una entrevista personal para discutir los deseos, posibilidades y expectativas del estudiante-atleta.</li>
        <li>Una evaluación profesional del nivel académico y deportivo del estudiante-atleta.</li>
        <li>Una extensa red de contactos con entrenadores, universidades y colegios.</li>
        <li>Promoción efectiva del perfil y videos del estudiante-atleta ante entrenadores potenciales.</li>
        <li>Asesoramiento experto sobre las mejores opciones basadas en las preferencias, metas y presupuesto del estudiante-atleta.</li>
        <li>Negociación de las mejores ofertas de becas en nombre del estudiante-atleta.</li>
        <li>Asistencia en la solicitud de documentos necesarios, como visa, expediente académico, diploma y pruebas de admisión.</li>
        <li>Preparación para la vida del estudiante-atleta durante su beca, incluyendo cultura, idioma, reglas y consejos.</li>
        <li>Seguimiento y atención posterior durante el período de la beca.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      ¿Quieres explorar oportunidades de becas y alcanzar tus metas deportivas y académicas? <Link to="/es/Servicios-Productos">Consulta la gama completa</Link> de servicios ofrecidos por Sport Scholarships America y programa una consulta hoy mismo.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;