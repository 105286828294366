/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";


// Material Kit 2 PRO React examples
import RaisedBlogCard from "examples/Cards/BlogCards/RaisedBlogCard";


// Images
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import NCAA from "assets/images/Logos/NCAA_Transparent.png"
import NJCAA from "assets/images/Logos/NJCAA_Transparent.png"
import NAIA from "assets/images/Logos/NAIA_Transparent.png"

function BlogPostThree() {
  const post1 = (NCAA)
  const post2 = (NJCAA)
  const post3 = (NAIA)

  return (
    <>
      <MKBox position="relative" variant="gradient" bgColor="dark" mt={{ xs: 0, lg: 8 }} mx={-2}>
        <MKBox
          component="img"
          src={bgPattern}
          alt="Hintergrundmuster"
          position="absolute"
          top={0}
          left={0}
          width={{ xs: "100%", lg: "100%" }}
          height={{ xs: "auto", lg: "auto" }}
          opacity={0.6}
        />
        <Container>
          <Grid container justifyContent="center" sx={{ pt: 6, pb: 18 }}>
            <Grid item xs={12} md={7} sx={{ textAlign: "center" }}>
              <MKTypography variant="h2" color="white" mb={1}>
                Chancen auf allen Ebenen
              </MKTypography>
              <MKTypography variant="body2" color="white">
                Jeder Sport, jede akademische Ebene: Entdecken Sie eine Welt voller Möglichkeiten an unzähligen amerikanischen Universitäten für jeden Studenten-Athleten.
              </MKTypography>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
  
      <MKBox component="section" mt={-10}>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4} mb={{ xs: 3, lg: 0 }}>
              <RaisedBlogCard
                image={post1}
                category={{ color: "primary", label: "Haus" }}
                title="NCAA"
                subtitle="National Collegiate Athletic Association"
                description=
                  "Die NCAA verwaltet den College-Sport in den USA und überwacht Wettbewerbe für mehr als 1.100 angeschlossene Universitäten. Als führende Organisation spielt sie eine entscheidende Rolle in der amerikanischen Hochschulsportlandschaft."
                action={{
                  type: "internal",
                  route: "/de/NCAA",
                  color: "info",
                  label: "Mehr Informationen",
                }}
                imageStyle={{ marginTop: "-44px" }}
                shadowTop="-30px" // Verschieben Sie den Schatten um 50px nach unten, um zur Bewegung des Bildes zu passen
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} mb={{ xs: 3, lg: 0 }}>
              <RaisedBlogCard
                image={post2}
                category={{ color: "primary", label: "Haus" }}
                title="NJCAA"
                subtitle="National Junior College Association"
                description="Die NJCAA regelt den Sport an Community Colleges in den USA und verwaltet Wettbewerbe für Hunderte von angeschlossenen Institutionen. Als zentrale Organisation ist sie für den zweijährigen Collegesport in Amerika, wo Talente für höhere Ebenen entwickelt werden, unerlässlich."
                action={{
                  type: "internal",
                  route: "/de/NJCAA",
                  color: "info",
                  label: "Mehr Informationen",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} mb={{ xs: 3, lg: 0 }}>
              <RaisedBlogCard
                image={post3}
                category={{ color: "primary", label: "Haus" }}
                title="NAIA"
                subtitle="National Association of Intercollegiate Athletics"
                description=
                  "Die NAIA spielt eine zentrale Rolle in der Verwaltung des Sports an kleineren Universitäten in den USA mit Hunderten von angeschlossenen Institutionen. Als führende Organisation spielt sie eine wichtige Rolle in der amerikanischen interkollegialen Sportlandschaft und fördert Talent und Integrität."
                action={{
                  type: "internal",
                  route: "/de/NAIA",
                  color: "info",
                  label: "Mehr Informationen",
                }}
                imageStyle={{ marginTop: "50px" }}
                shadowTop="none"
                paddingBelowImage="120px"
              />
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
  
}

export default BlogPostThree;
