import { useEffect, useRef } from "react";
import { Helmet } from 'react-helmet';

// rellax
import Rellax from "rellax";

// typed-js
import Typed from "typed.js";



// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DetailedFooter from "examples/Footers/DetailedFooter";


// Coworking page sections
import DesignBlocks from "./sections/DesignBlocks"

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import Breadcrumbs from "examples/Breadcrumbs";

// Images
import bgImage from "assets/images/Achtergronden/Home_BG.png";

function AllSports() {
  const headerRef = useRef(null);
  const typedJSRef = useRef(null);

  // Setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []);

  // Setting up typedJS
  useEffect(() => {
    const typedJS = new Typed(typedJSRef.current, {
      strings: [
        "american football",
        "basketball",
        "baseball",
        "field hockey",
        "golf",
        "gymnastics",
        "rowing",
        "soccer",
        "softball",
        "swimming",
        "tennis",
        "track and field",
        "volleyball",
        "water polo",
        "wrestling",
      ],
      
            typeSpeed: 90,
      backSpeed: 90,
      backDelay: 200,
      startDelay: 500,
      loop: true,
    });

    return () => typedJS.destroy();
  }, []);

  return (
    <>
 <Helmet>
    <title>Overview of College Sports in the US - Sport Scholarships America</title>
    <meta name="description" content="A comprehensive overview of all college sports in America. Discover opportunities in sports such as football, hockey, and tennis for scholarships in the US." />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta charset="UTF-8" />
    <meta name="robots" content="index, follow" />
    
    {/* Open Graph Tags */}
    
    
    
    
    
 
    {/* Favicon */}
    <link rel="icon" href="https://sportscholarshipsamerica.com/32x32Sportbeurs.png" />
</Helmet>


      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://calendly.com/sportbeurs-amerika/intake",
          label: "BOOK APPOINTMENT",
          color: "info",
        }}
        transparent
        light
         
      />
      <MKBox
        ref={headerRef}
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
  All the information you need about <br /> <span ref={typedJSRef} /> 
            </MKTypography>
           
           
          
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
             <MKBox
  width={{ xs: "80%", md: "50%", lg: "25%" }}
  mb={1}
  sx={{
    backgroundColor: "transparent", // Transparent background color
  }}
>
  <Breadcrumbs
    routes={[
      { label: "Home", route: "/" },
      { label: "All Sports" },
    ]}
  />
</MKBox>  
        <DesignBlocks />
        
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DetailedFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AllSports;
