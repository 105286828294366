import PropTypes from "prop-types";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKBadge from "components/MKBadge";

function ExampleCard({ image, name, badgeContent, secondBadgeContent, altText, ...rest }) {
  return (
    <MKBox position="relative" pb={badgeContent || secondBadgeContent ? 4 : 0}> {/* Increased bottom padding when badges are present */}
      <MKBox
        bgColor="#851f32"
        borderRadius="15px"
        shadow="lg"
        minHeight="10rem"
        sx={{
          overflow: "hidden",
          transform: "perspective(999px) rotateX(0deg) translate3d(0, 0, 0)",
          transformOrigin: "50% 0",
          backfaceVisibility: "hidden",
          willChange: "transform, box-shadow",
          transition: "transform 200ms ease-out",
          "&:hover": {
            transform: "perspective(999px) rotateX(7deg) translate3d(0px, -4px, 5px)",
          },
        }}
        {...rest}
      >
        <MKBox component="img" src={image} alt={altText || name} width="100%" my="auto" />
      </MKBox>
      {name && (
        <MKBox mt={1} ml={1} lineHeight={1}>
          <MKTypography variant="h4" fontWeight="bold">
            {name}
          </MKTypography>
        </MKBox>
      )}
      {(badgeContent || secondBadgeContent) && (
        <MKBox display="flex" position="absolute" bottom="0" alignItems="center" >
          {badgeContent && (
            <MKBadge badgeContent={badgeContent} color="info" variant="contained" container circular/>
          )}
          {badgeContent && secondBadgeContent && <MKBox mx={0.5} />} {/* Add space if both badges are present */}
          {secondBadgeContent && (
            <MKBadge badgeContent={secondBadgeContent} color="primary" variant="contained" container circular/>
          )}
        </MKBox>
      )}
    </MKBox>
  );
}

ExampleCard.defaultProps = {
  name: "",
  badgeContent: null,
  secondBadgeContent: null,
};

ExampleCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string,
  badgeContent: PropTypes.string,
  secondBadgeContent: PropTypes.string,
};

export default ExampleCard;
