/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Softbal1.jpeg";
import Image2 from "assets/images/Sports_Images/Softbal2.jpg";
import Image3 from "assets/images/Sports_Images/Softbal3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Borse di Studio per il Softball negli Stati Uniti: Come Studiare e Giocare a Softball in America', isSubheader: false },
  
    { id: 'Capitolo 1', title: 'Capitolo 1: Storia e Sviluppo del Softball in America', isSubheader: false },
    { id: '1.1', title: '1.1 Primi Anni e il Ruolo delle Scuole e delle Università', isSubheader: true },
    { id: '1.2', title: '1.2 Crescita e Professionalizzazione', isSubheader: true },
  
    { id: 'Capitolo 2', title: 'Capitolo 2: Il Softball Universitario Oggi', isSubheader: false },
    { id: '2.1', title: '2.1 Leghe e Conferenze Importanti', isSubheader: true },
    { id: '2.2', title: '2.2 Programmi Top e Squadre Prestigiose', isSubheader: true },
  
    { id: 'Capitolo 3', title: 'Capitolo 3: Dall\'Università al Professionismo', isSubheader: false },
    { id: '3.1', title: '3.1 Il Percorso verso il Professionismo', isSubheader: true },
    { id: '3.2', title: '3.2 Softball Professionistico e Altre Opportunità di Carriera', isSubheader: true },
  
    { id: 'Capitolo 4', title: 'Capitolo 4: Influenze ed Eventi Internazionali', isSubheader: false },
    { id: '4.1', title: '4.1 Influenze Straniere', isSubheader: true },
    { id: '4.2', title: '4.2 Gli Americani sul Palcoscenico Mondiale', isSubheader: true },
  
    { id: 'Capitolo 5', title: 'Capitolo 5: Borse di Studio Sportive e il Futuro degli Atleti', isSubheader: false },
    { id: '5.1', title: '5.1 L\'Importanza delle Borse di Studio', isSubheader: true },
    { id: '5.2', title: '5.2 Come Sport Scholarships America Può Aiutare', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };
  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indice
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Nascondi</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostra</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>

























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Borse di Studio per il Softball negli Stati Uniti: Come Studiare e Giocare a Softball in America
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Vista aerea di uno stadio affollato durante una partita di softball universitario."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Uno stadio pieno durante una partita
      </figcaption>
    </figure>
    Il softball è uno sport che ha avuto origine negli Stati Uniti alla fine del XIX secolo. È una variante del baseball ma con una palla più grande, un campo più piccolo e riprese più brevi. Il softball si gioca con due squadre di nove giocatori, che si alternano nel battere e nel difendere. L'obiettivo è segnare più punti della squadra avversaria correndo intorno alle quattro basi dopo aver colpito la palla.
    <br />
    <br />
    Il softball ha rapidamente guadagnato popolarità negli USA, soprattutto tra le scuole e le università. Veniva visto come uno sport che promuoveva il fitness fisico, la vivacità mentale e l'interazione sociale. Ha anche attratto molto talento e competizione, specialmente a livello collegiale. Il softball collegiale rappresenta il livello più alto del softball negli USA, dove gli studenti-atleti competono per la propria università o college. Il softball collegiale offre prestigio, riconoscimento e borse di studio per i migliori giocatori.
    <br />
    <br />
    Sportbeursamerika.nl è un'organizzazione dedicata ad aiutare giovani atleti a ottenere borse di studio per il softball. Sportbeursamerika.nl dispone di un team di esperti che possono guidarti nella ricerca della migliore università o college in base al tuo profilo accademico e atletico. Possono anche assisterti nella preparazione della tua candidatura, nella creazione del tuo video, nel contatto con gli allenatori e nell'organizzazione del tuo visto. Sportbeursamerika.nl ha già aiutato molti successi a realizzare il loro sogno di studiare e giocare a softball in America.
    <br />
    <br />
    Se vuoi saperne di più sul softball in America e su come ottenere una borsa di studio per il softball, continua a leggere!
  </MKTypography>
  {/* Aggiungi elenco puntato o altro contenuto se necessario */}
</MKBox>

{/* Capitoli principali e sottocapitoli con testo segnaposto */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 1: Storia e Sviluppo del Softball in America
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Il softball è uno sport che ha avuto origine negli Stati Uniti ma si è diffuso in tutto il mondo. È uno sport che ha subito molti cambiamenti e innovazioni, con numerosi personaggi ed eventi influenti. In questo capitolo, ti forniremo una panoramica della storia e dello sviluppo del softball in America, dai suoi primi anni fino ai giorni nostri.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Primi Anni e il Ruolo delle Scuole e delle Università
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Il softball fu giocato per la prima volta nel 1887 a Chicago come variante indoor del baseball. Fu concepito da un gruppo di uomini che celebravano il Ringraziamento in una palestra. Usarono un guanto da pugilato come palla e un manico di scopa come mazza. Il gioco guadagnò rapidamente popolarità tra vari gruppi di persone, tra cui pompieri, operai e membri della chiesa.
      <br />
      <br />
      Nei primi anni del softball, non esistevano regole o dimensioni standardizzate. Il gioco veniva adattato allo spazio disponibile e al numero di giocatori. Esistevano varie varianti del softball, come mushball, kittenball, diamond ball e indoor baseball. Il termine "softball" fu ufficialmente utilizzato nel 1926.
      <br />
      <br />
      Le scuole e le università hanno svolto un ruolo significativo nello sviluppo del softball. Vedevano il softball come uno sport adatto sia per ragazzi che per ragazze, che promuoveva l'educazione fisica e il tempo libero. Hanno anche organizzato le prime squadre scolastiche e universitarie e le prime competizioni organizzate. Una delle prime competizioni scolastiche è stata la Public Schools Athletic League di New York City, che ha iniziato il softball per ragazze nel 1913. Una delle prime competizioni universitarie è stata la National Collegiate Athletic Association (NCAA), che ha avviato il softball femminile nel 1957.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crescita e Professionalizzazione
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Il softball è diventato uno sport popolare e prestigioso in America, soprattutto tra le donne. Ciò è dovuto in parte all'introduzione del Titolo IX nel 1972, una legge che garantiva pari opportunità per le donne nell'istruzione, comprese le borse di studio sportive. Ciò ha fornito a più studentesse-atlete l'opportunità di giocare a softball a livello collegiale.
      <br />
      <br />
      Oltre al softball collegiale, sono emerse leghe professionistiche di softball in America. La prima lega professionale di softball femminile è stata l'International Women’s Professional Softball Association (IWPSA), fondata nel 1976. Questa lega era composta da dieci squadre provenienti da diverse città, come New York, Chicago e Los Angeles. La lega durò quattro stagioni, fino al 1980.
      <br />
      <br />
      La seconda lega professionale di softball femminile è stata la Women’s Pro Softball League (WPSL), fondata nel 1997. Questa lega comprendeva sei squadre provenienti da vari stati, tra cui Florida, Georgia e Virginia. La lega cambiò successivamente nome in National Pro Fastpitch (NPF). La NPF è stata attiva fino al 2021 e ha subito due stagioni cancellate a causa del COVID-19. È stata lanciata una nuova lega di softball, la Women's Professional Fastpitch (WPF), nel 2022.
      <br />
      <br />
      Le leghe professionistiche di softball hanno avuto un impatto significativo sul softball collegiale. Molti giocatori professionisti erano ex studenti-atleti che hanno continuato le loro carriere dopo la laurea. Alcuni giocatori professionisti sono diventati anche allenatori o istruttori per squadre universitarie o giocatori individuali. Inoltre, molti giocatori professionisti si sono distinti come modelli e fonti di ispirazione per giovani giocatori di softball.
      <br />
      <br />
      Alcuni esempi di figure chiave nel mondo del softball sono:

    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Jennie Finch: Ex lanciatrice per gli Arizona Wildcats e la nazionale USA. Ha vinto due medaglie olimpiche, l'oro nel 2004 e l'argento nel 2008. Ha giocato anche per i Chicago Bandits nella NPF. È considerata una delle più grandi lanciatrici di tutti i tempi e un'icona per lo sport.
        </li>
        <li>
          Cat Osterman: Ex lanciatrice per i Texas Longhorns e la nazionale USA. Ha anche vinto due medaglie olimpiche, l'oro nel 2004 e l'argento nel 2008. Ha giocato per i Rockford Thunder e gli Houston Scrap Yard Dawgs nella NPF. In seguito è diventata allenatrice dei Texas State Bobcats.
        </li>
        <li>
          Monica Abbott: Ex lanciatrice per i Tennessee Volunteers e la nazionale USA. Ha vinto una medaglia olimpica d'argento nel 2008. In seguito ha giocato per i Scrap Yard Fast Pitch nella NPF e i Toyota Red Terriers in Giappone. È la prima giocatrice di softball femminile a firmare un contratto da un milione di dollari.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>

                                              





                                  







<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 2: Il Softball Universitario Oggi
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Il softball è uno degli sport più popolari e competitivi a livello universitario in America. Ogni anno, più di mille squadre partecipano alla stagione universitaria di softball, che va da febbraio a giugno. Il momento clou della stagione è il Women's College World Series (WCWS), il campionato nazionale di softball universitario.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Leghe e Conferenze Importanti
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Giocatori di Tennessee e Alabama in azione durante il College World Series."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
         }}>
          Giocatori in azione durante il College World Series
        </figcaption>
      </figure>
      Il softball universitario è regolamentato dalla National Collegiate Athletic Association (NCAA), un'organizzazione responsabile dell'organizzazione e della gestione dei programmi sportivi per università e college in America. La NCAA divide le università in tre divisioni in base alle loro dimensioni, al budget e alle prestazioni sportive. Queste divisioni sono:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Division I: La divisione più alta e prestigiosa, con più di 300 università e oltre 10.000 studenti-atleti. La Division I offre più borse di studio, le migliori strutture e la competizione più forte. La Division I è composta da 32 conferenze, gruppi regionali di università che giocano partite tra loro. Alcuni esempi di conferenze sono la Southeastern Conference (SEC), la Pac-12 Conference e la Big Ten Conference.
        </li>
        <li>
          Division II: La divisione intermedia, con più di 300 università e oltre 8.000 studenti-atleti. La Division II offre meno borse di studio ma fornisce comunque buone strutture e una forte competizione. La Division II è composta da 23 conferenze, come la Great Lakes Valley Conference (GLVC), la Sunshine State Conference e la California Collegiate Athletic Association (CCAA).
        </li>
        <li>
          Division III: La divisione più bassa, con più di 400 università e oltre 6.000 studenti-atleti. La Division III non offre borse di studio ma fornisce programmi accademici solidi e un'esperienza sportiva. La Division III è composta da 43 conferenze, tra cui la New England Small College Athletic Conference (NESCAC), la Centennial Conference e la University Athletic Association (UAA).
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ogni divisione ha il proprio campionato alla fine della stagione, dove le migliori squadre di ogni conferenza si qualificano per un torneo a eliminazione diretta. Il campionato della Division I è chiamato Women's College World Series (WCWS), tenuto a Oklahoma City. Il campionato della Division II è chiamato NCAA Division II Softball Championship, tenuto a Denver. Il campionato della Division III è chiamato NCAA Division III Softball Championship, tenuto a Salem.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programmi Top e Squadre Prestigiose
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Il softball universitario ha prodotto molti programmi top e squadre prestigiose che si sono distinte attraverso la loro storia di successo, i giocatori talentuosi e gli allenatori influenti. Alcuni di questi programmi e squadre includono:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          UCLA Bruins: Questo programma è considerato uno dei più vincenti nella storia del softball universitario, con un impressionante numero di titoli nazionali al suo attivo. Nel corso degli anni, hanno prodotto medagliati olimpici, tra cui nomi famosi come Lisa Fernandez, Natasha Watley e Stacey Nuveman.
        </li>
        <li>
          Oklahoma Sooners: Gli Oklahoma Sooners sono una squadra particolarmente forte e hanno sempre ottenuto prestazioni di alto livello. Hanno vinto diversi titoli nazionali e hanno avuto impressionanti serie di vittorie in passato.
        </li>
        <li>
          Arizona Wildcats: Il programma di softball degli Arizona Wildcats vanta un'impressionante lista di titoli nazionali. Hanno anche un rispettato allenatore, Mike Candrea, con un eccellente curriculum di numerose vittorie e persino un oro olimpico come allenatore della nazionale statunitense di softball.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Questi programmi e squadre contribuiscono allo sviluppo dei giocatori di softball offrendo loro un alto livello di allenamento, coaching e competizione. Aiutano anche i giocatori a migliorare le loro abilità, conoscenze e mentalità, preparandoli per il prossimo passo nella loro carriera.
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  






<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 3: Dall'Università al Professionismo
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Il softball è uno sport che offre molte opportunità agli studenti atleti di continuare la loro carriera dopo il periodo universitario. Alcuni studenti atleti scelgono di giocare a softball professionistico, mentre altri esplorano altre possibilità di carriera. In questo capitolo, discutiamo del percorso verso il professionismo, delle leghe di softball professionistiche e delle altre opzioni di carriera per i giocatori di softball.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Il Percorso verso il Professionismo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Giocare a softball universitario è una buona preparazione per giocare a softball professionistico. Molti studenti atleti che eccellono nel softball universitario vengono osservati o selezionati dalle squadre professionistiche. Ciò significa che loro vengono offerti un contratto per giocare per una squadra specifica, di solito in cambio di uno stipendio e altri benefici.
      <br />
      <br />
      Tuttavia, giocare a softball professionistico non è facile. La competizione è molto alta e i giocatori devono adattarsi costantemente a nuove situazioni, avversari e sfide. Inoltre, gli stipendi dei giocatori di softball professionistici non sono molto alti rispetto ad altri sport. Secondo un rapporto del 2016, lo stipendio medio di un giocatore NPF era di circa $ 5.000-6.000 a stagione. Questo significa che molti giocatori professionisti devono avere un secondo lavoro o un'altra fonte di reddito per arrivare a fine mese.
      <br />
      <br />
      Pertanto, alcuni studenti atleti scelgono di non giocare a softball professionistico ma di completare i loro studi o intraprendere un'altra carriera. Questo può anche essere una buona scelta perché il softball universitario sviluppa molte abilità e qualità che sono utili in altri campi, come leadership, teamwork, comunicazione, disciplina e risoluzione dei problemi.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Softball Professionistico e Altre Opportunità di Carriera
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Come accennato in precedenza, la principale lega di softball professionistico in America è la National Pro Fastpitch (NPF). Attualmente la NPF è composta da cinque squadre: le Aussie Peppers of Minnesota, le Canadian Wild of Southern Illinois, le Chicago Bandits, le Cleveland Comets e le USSSA Pride. La NPF ha anche una partnership con la Chinese Softball Association (CSA), che invia due squadre alla NPF: le Beijing Eagles e le Shanghai Shengli.
      <br />
      <br />
      Tuttavia, la NPF non è l'unica lega di softball professionistico al mondo. Ci sono anche altre leghe in paesi come Giappone, Australia, Italia e Messico. Alcuni giocatori americani scelgono di giocare in queste leghe perché sono meglio pagati o perché vogliono vivere un'esperienza culturale diversa.
      <br />
      <br />
      Oltre a giocare a softball professionistico, ci sono altre opportunità di carriera per i giocatori di softball. Alcuni esempi includono:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Coaching: Alcuni giocatori diventano allenatori per squadre scolastiche, universitarie o professionistiche. Utilizzano le loro conoscenze e esperienze per allenare, motivare e consigliare altri giocatori.
        </li>
        <li>
          Educazione: Alcuni giocatori diventano insegnanti o professori a vari livelli e in vari campi. Utilizzano la loro formazione accademica e le loro competenze pedagogiche per educare e guidare gli studenti.
        </li>
        <li>
          Media: Alcuni giocatori diventano giornalisti, commentatori o analisti per vari media. Utilizzano le loro competenze comunicative e la loro conoscenza dello sport per riportare notizie ed eventi di softball.
        </li>
        <li>
          Imprenditorialità: Alcuni giocatori diventano imprenditori o manager in diversi settori e industrie. Utilizzano le loro competenze creative e commerciali per sviluppare e vendere prodotti o servizi.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Come puoi vedere, ci sono molte opportunità per i giocatori di softball di utilizzare la loro passione e il loro talento in diversi ambiti. La chiave è stabilire i tuoi obiettivi, esplorare le tue opzioni e perseguire i tuoi sogni.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  




<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 4: Influenze ed Eventi Internazionali
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Il softball non è solo uno sport americano ma anche uno sport internazionale. Il softball è praticato in oltre 140 paesi in tutto il mondo e ha avuto un impatto significativo sulla cultura sportiva e sullo sviluppo in varie regioni. In questo capitolo, discuteremo delle influenze straniere sul softball in America e delle influenze americane sul softball a livello mondiale.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Influenze Straniere
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="La squadra di softball dell'Università dell'Oklahoma festeggia la vittoria del titolo NCAA."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          I giocatori dell'Oklahoma festeggiano il campionato nazionale
        </figcaption>
      </figure>
      Il softball in America deve molto alle influenze straniere che hanno arricchito e sfidato lo sport. Alcune di queste influenze includono:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Giappone: Il Giappone è uno dei paesi più forti nel softball, sia a livello professionistico che universitario. Il Giappone ha una lunga tradizione di softball risalente agli anni '30. Il Giappone ha anche la propria lega professionistica di softball, la Japan Softball League (JSL), composta da 12 squadre. Il Giappone ha avuto un impatto significativo sul softball americano introducendo nuove tecniche, tattiche e stili di gioco. Il Giappone ha anche molti programmi di scambio e partnership con università e squadre americane, offrendo a molti giocatori l'opportunità di imparare l'uno dall'altro.
        </li>
        <li>
          Australia: L'Australia è un altro paese che gioca un ruolo significativo nel softball, specialmente a livello internazionale. L'Australia ha la propria squadra nazionale di softball, l'Aussie Spirit, che ha vinto molte medaglie alle Olimpiadi, ai Campionati del Mondo e alla Coppa del Mondo di Softball. L'Australia ha anche molti giocatori che competono nella NPF, come Stacey Porter, Kaia Parnaby e Taylah Tsitsikronis. L'Australia ha avuto un'influenza sostanziale sul softball americano mostrando un alto livello di abilità atletiche, perseveranza e lavoro di squadra.
        </li>
        <li>
          Canada: Il Canada, come paese confinante con l'America, ha anche una forte cultura del softball. Il Canada ha la propria squadra nazionale di softball, i Canadian Wild, che partecipa anche alla NPF. Il Canada ha anche molti giocatori che hanno giocato o giocano attualmente per università americane, come Danielle Lawrie, Jenn Salling e Sara Groenewegen. Il Canada ha avuto un impatto significativo sul softball americano condividendo una passione comune, una visione e un'apprezzamento per lo sport.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Queste influenze straniere hanno contribuito allo sviluppo del softball in America creando più diversità, competizione e collaborazione. Hanno anche contribuito allo sviluppo dei giocatori universitari esponendoli a culture, prospettive ed esperienze diverse.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Gli Americani sul Palcoscenico Mondiale
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Il softball in America ha avuto anche un impatto significativo sul softball a livello mondiale. L'America è stata un paese leader nel softball per molti anni, sia a livello professionistico che universitario. L'America ha prodotto molte storie di successo e fonti di ispirazione per altri paesi e giocatori.
      <br />
      <br />
      Queste storie di successo hanno avuto un impatto significativo sul softball a livello mondiale mostrando un alto livello di prestazioni, eccellenza e leadership. Hanno anche guadagnato molto rispetto e ammirazione da altri paesi e giocatori, che li vedono come esempi e sfidanti.
      <br />
      <br />
      Il ruolo del softball universitario nella preparazione alle competizioni internazionali è cruciale. Il softball universitario fornisce ai giocatori una solida base di abilità, conoscenze ed esperienze che possono utilizzare per competere con i migliori giocatori del mondo. Il softball universitario aiuta anche a scoprire, sviluppare e promuovere il talento e il potenziale dei giocatori in modo che possano eccellere al più alto livello.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  







<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 5: Borse di Studio Sportive e il Futuro degli Atleti
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le borse di studio sportive offrono numerose opportunità ai giovani atleti di mostrare il loro talento e sviluppare le loro abilità. Una delle opportunità più significative è ottenere una borsa di studio sportiva per studiare e competere a livello universitario. In questo capitolo, discuteremo dell'importanza delle borse di studio sportive e di come Sport Scholarships America può aiutarti a realizzare il tuo sogno.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 L'Importanza delle Borse di Studio
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Una borsa di studio sportiva è un sostegno finanziario fornito da un'università a uno studente-atleta per combinare studio e sport. Può coprire i costi di iscrizione, libri, alloggio, pasti e altre spese. Inoltre, può offrire vantaggi come il tutorato accademico, l'assistenza medica, le strutture di allenamento e l'attrezzatura.
      <br />
      <br />
      Ottenere una borsa di studio sportiva è competitivo, con molti fattori che influenzano il processo di assegnazione, tra cui risultati accademici, prestazioni sportive, personalità, motivazione, leadership e adattabilità. È quindi importante prepararsi bene e distinguersi dalla concorrenza.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Come Sport Scholarships America Può Aiutare
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo di Sport Scholarships America, raffigurante attrezzature sportive e una toga, simbolo del focus sulle borse di studio sportive per gli studenti-atleti."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo di Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America è un'organizzazione specializzata nell'orientamento dei giovani talenti sportivi verso borse di studio sportive. Con esperti appassionati ed esperti, Sport Scholarships America supporta gli studenti-atleti in ogni fase del percorso, dall'orientamento iniziale alla collocazione finale. I loro servizi includono:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Un'intervista personale per discutere i desideri, le possibilità e le aspettative dello studente-atleta.</li>
        <li>Una valutazione professionale del livello accademico e sportivo dello studente-atleta.</li>
        <li>Una vasta rete di contatti con allenatori, università e college.</li>
        <li>Promozione efficace del profilo e dei video dello studente-atleta presso allenatori potenziali.</li>
        <li>Consulenza esperta sulle migliori opzioni in base alle preferenze, agli obiettivi e al budget dello studente-atleta.</li>
        <li>Negoziazione delle migliori offerte di borse di studio per conto dello studente-atleta.</li>
        <li>Assistenza nella richiesta dei documenti necessari, come visto, trascrizione, diploma e test di ammissione.</li>
        <li>Preparazione alla vita dello studente-atleta durante la borsa di studio, compresa cultura, lingua, regole e consigli.</li>
        <li>Assistenza e monitoraggio durante il periodo di borsa di studio.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vuoi esplorare le opportunità di borse di studio e raggiungere i tuoi obiettivi sportivi e accademici? <Link to="/it/Servizi-Prodotti">Consulta la gamma completa</Link> dei servizi offerti da Sport Scholarships America e prenota una consultazione oggi stesso.
    </MKTypography>
  </MKBox>
</MKBox>
          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;