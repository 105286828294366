/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";



// Images
import Image1 from "assets/images/Sports_Images/Soccer1.jpg";
import Image2 from "assets/images/Sports_Images/Soccer2.jpg";
import Image3 from "assets/images/Sports_Images/Soccer3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Jouer au Football et Étudier en Amérique : Un Rêve Devenu Réalité', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Chapitre 1 : Histoire et Développement du Football en Amérique', isSubheader: false },
    { id: '1.1', title: '1.1 Premières Années et le Rôle des Universités', isSubheader: true },
    { id: '1.2', title: '1.2 Croissance et Professionnalisation', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Chapitre 2 : Le Football Universitaire Aujourd\'hui', isSubheader: false },
    { id: '2.1', title: '2.1 Compétitions et Conférences Importantes pour le Football', isSubheader: true },
    { id: '2.2', title: '2.2 Programmes de Premier Plan et Équipes Prestigieuses pour le Football', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Chapitre 3 : Du Collège aux Professionnels', isSubheader: false },
    { id: '3.1', title: '3.1 Le Chemin vers le Professionnalisme', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Chapitre 4 : L\'Influence du Football International', isSubheader: false },
    { id: '4.1', title: '4.1 Influences Étrangères', isSubheader: true },
    { id: '4.2', title: '4.2 Les Américains à l\'Étranger', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Chapitre 5 : Bourses Sportives et l\'Avenir des Athlètes', isSubheader: false },
    { id: '5.1', title: '5.1 L\'Importance des Bourses', isSubheader: true },
    { id: '5.2', title: '5.2 Comment Sport Scholarships America Peut Aider', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Sommaire
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Cacher</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Afficher</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























{/* Introduction */}
<MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Jouer au Football et Étudier en Amérique : Un Rêve Devenu Réalité
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Stade de Creighton, pouvant accueillir plus de 7000 spectateurs, lors d'un match de football universitaire."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Stade de Creighton : domicile de plus de 7000 fans de football.
      </figcaption>
    </figure>
    Le football est l'un des sports les plus populaires au monde, mais en Amérique, il a longtemps été éclipsé par d'autres sports comme le basketball, le baseball et le football américain. Cependant, ces dernières décennies, le football a connu une croissance énorme en popularité et en qualité aux États-Unis, grâce en partie au succès de l'équipe nationale, à la montée de la Major League Soccer (MLS) et à l'influence des stars internationales.
    <br />
    <br />
    Mais saviez-vous que le football en Amérique offre également une opportunité unique pour les jeunes talents de réaliser leurs rêves ? En jouant au football et en étudiant en Amérique, vous pouvez non seulement développer vos compétences athlétiques mais aussi obtenir un diplôme académique et vivre une expérience inoubliable. Tout cela est possible grâce aux bourses de football que les universités américaines offrent aux joueurs talentueux.
    <br />
    <br />
    Dans cet article, nous vous dirons tout ce que vous devez savoir sur le fait de jouer au football et d'étudier en Amérique, de l'histoire et du développement du football aux États-Unis aux avantages et aux opportunités d'une bourse de football. Nous expliquerons également comment Sportbeursamerika.nl peut vous aider à concrétiser ce rêve. Alors, lisez la suite et découvrez comment vous pouvez profiter de cette opportunité unique !
  </MKTypography>
</MKBox>

{/* Chapter 1 */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 1 : Histoire et Développement du Football en Amérique
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le football, ou soccer comme l'appellent les Américains, n'est pas un sport aussi récent aux États-Unis que vous pourriez le penser. En fait, le premier match officiel de football en Amérique a eu lieu en 1869, entre deux équipes universitaires : Princeton et Rutgers. Depuis lors, le football a eu une longue et fascinante histoire aux États-Unis, que nous allons discuter dans ce chapitre.
  </MKTypography>
  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Premières Années et le Rôle des Universités
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      À la fin du XIXe et au début du XXe siècle, le football était particulièrement populaire parmi les étudiants, qui pratiquaient le jeu selon diverses règles et formats. Par exemple, il y avait des équipes qui jouaient avec 11, 15 ou même 25 joueurs, et qui utilisaient parfois des éléments de rugby ou de football américain.
      <br />
      <br />
      Pour créer plus d'unité et de structure, l'Intercollegiate Association of Amateur Athletes of America (IAAAA) a été fondée en 1905, précurseur de la National Collegiate Athletic Association (NCAA). La NCAA est l'organisation principale qui réglemente et organise le football universitaire aux États-Unis.
      <br />
      <br />
      Dans la première moitié du XXe siècle, le nombre d'équipes de football universitaire a régulièrement augmenté, et des compétitions et championnats régionaux ont également émergé. L'un des plus célèbres d'entre eux est la College Cup, organisée depuis 1959 comme le championnat national masculin. Pour les femmes, la première College Cup n'a eu lieu qu'en 1982, mais depuis lors, le football féminin a connu une croissance considérable aux États-Unis, en partie grâce au succès de l'équipe nationale dans les tournois internationaux.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Croissance et Professionnalisation
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      En plus du football universitaire, le football professionnel s'est également développé aux États-Unis, bien que avec plus de hauts et de bas. La première ligue professionnelle était la American Soccer League (ASL), fondée en 1921 et principalement composée d'équipes du Nord-Est. L'ASL a connu quelques années réussies mais s'est heurtée à un conflit avec la fédération de football américaine sur l'organisation et la réglementation du jeu. Cela a conduit à une scission et finalement à la fin de l'ASL en 1933.
      <br />
      <br />
      Après la Seconde Guerre mondiale, différentes ligues ont tenté de relancer le football professionnel, mais aucune d'entre elles n'a survécu longtemps. Ce n'est qu'en 1967 qu'une ligue stable a été établie : la North American Soccer League (NASL). La NASL a attiré quelques grands noms en Amérique, dont Pelé, Franz Beckenbauer et Johan Cruyff, et a attiré de grands publics dans les stades. Cependant, la NASL n'a pas non plus résisté aux problèmes financiers, à la concurrence d'autres sports et au manque d'intérêt des médias. La NASL a cessé d'exister en 1984.
      <br />
      <br />
      Il a fallu attendre 1996 pour qu'une ligue professionnelle soit à nouveau établie : la Major League Soccer (MLS). La MLS est actuellement la plus haute division du football américain, composée de 27 équipes des États-Unis et du Canada. La MLS est devenue une ligue compétitive et attractive, attirant à la fois des joueurs nationaux et internationaux. La MLS a également contribué à la popularisation du football en Amérique en construisant des stades, en soutenant le développement des jeunes et en collaborant avec d'autres ligues.
      <br />
      <br />
      Le football professionnel a également influencé le football universitaire, et vice versa. Par exemple, il existe des joueurs qui passent à la MLS après leur carrière universitaire, ou des joueurs qui retournent à l'université pour terminer leurs études après la MLS. Il y a aussi des joueurs qui jouent à la fois pour leur université et une équipe professionnelle, grâce à des arrangements spéciaux. De plus, certaines équipes professionnelles collaborent avec des programmes universitaires pour repérer et développer des talents.
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  







{/* Chapter 2 */}
<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 2 : Le Football Universitaire Aujourd'hui
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Comme nous l'avons vu dans le chapitre précédent, le football universitaire en Amérique a une longue et riche tradition. Mais à quoi ressemble-t-il aujourd'hui ? Quels sont les compétitions et les conférences existantes ? Quels sont les programmes et les équipes les plus prestigieux et les plus performants ? Et comment pouvez-vous, en tant que joueur, vous impliquer dans ce monde excitant ? Dans ce chapitre, nous vous donnerons un aperçu du football universitaire aujourd'hui et vous montrerons à quoi vous attendre si vous choisissez une bourse de football en Amérique.
  </MKTypography>
  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Compétitions et Conférences Importantes pour le Football
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Joueur de football de Santa Clara en action lors du tournoi NCAA."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Joueurs de football en action lors d'un match du tournoi de la NCAA.
        </figcaption>
      </figure>
      Comme mentionné précédemment, la NCAA est l'organisation principale qui réglemente et organise le football universitaire aux États-Unis. La NCAA se compose de trois divisions : la Division I, la Division II et la Division III. Chaque division a ses propres règles, exigences et bourses pour les étudiants-athlètes.
      <br />
      <br />
      La Division I est la division la plus haute et la plus compétitive, où les meilleurs joueurs et équipes s'affrontent. La Division I se compose d'environ 200 équipes masculines et 300 équipes féminines, réparties dans différentes conférences régionales. Une conférence est un groupe d'universités qui collaborent pour organiser une compétition dans leur région. Quelques exemples de conférences bien connues sont l'Atlantic Coast Conference (ACC), la Big Ten Conference, la Pac-12 Conference et la Southeastern Conference (SEC).
      <br />
      <br />
      Chaque année, les équipes au sein de leur conférence se disputent le titre et une place dans le championnat national : la College Cup. La College Cup est un tournoi à élimination directe organisé à la fin de la saison, généralement en novembre et décembre. Pour se qualifier pour la College Cup, les équipes doivent d'abord gagner leur place à travers leur conférence ou à travers une invitation de la part d'un comité de sélection basée sur leur performance au cours de la saison. La College Cup se compose de 48 équipes masculines et 64 équipes féminines, qui s'affrontent dans différentes manches jusqu'à ce qu'un champion reste.
      <br />
      <br />
      La Division II et la Division III sont moins compétitives que la Division I mais offrent toujours de nombreuses opportunités aux étudiants-athlètes de se développer et de profiter du jeu. La Division II se compose d'environ 160 équipes masculines et 200 équipes féminines, également réparties dans différentes conférences. La Division II a également un championnat national, composé de 36 équipes masculines et 48 équipes féminines. La Division III se compose d'environ 400 équipes masculines et 430 équipes féminines, qui concourent également dans des conférences. Cependant, la Division III n'offre pas de bourses aux étudiants-athlètes mais fournit d'autres formes de soutien financier. La Division III a également un championnat national, composé de 62 équipes masculines et 64 équipes féminines.
      <br />
      <br />
      En plus de la NCAA, il existe d'autres organisations qui organisent le football universitaire aux États-Unis, telles que la National Association of Intercollegiate Athletics (NAIA), la National Junior College Athletic Association (NJCAA) et la United States Collegiate Athletic Association (USCAA). Ces organisations ont leurs propres divisions, règles, bourses et championnats, qui sont généralement plus petits et moins connus que ceux de la NCAA.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programmes de Premier Plan et Équipes Prestigieuses pour le Football
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dans chaque division et conférence, il existe certains programmes et équipes qui se distinguent par leur histoire, leurs performances, leur réputation, leurs installations, et plus encore. Ces programmes et équipes attirent souvent les meilleurs joueurs, offrent les meilleures bourses, ont les meilleurs entraîneurs et personnel, possèdent des installations d'entraînement et de jeu de premier ordre, ont la plus grande base de fans et d'attention médiatique, et produisent fréquemment des joueurs pour le circuit professionnel.
      <br />
      <br />
      Il est difficile d'établir une liste définitive des meilleurs programmes et équipes de football universitaire en Amérique car de nombreux facteurs entrent en jeu et les classements peuvent changer chaque année. Cependant, pour vous donner une idée, nous mentionnerons quelques exemples de programmes et d'équipes qui sont souvent considérés comme les meilleurs du football universitaire en Amérique.
      <br />
      <br />
      Pour les hommes, l'un des programmes les plus prestigieux est celui de l'Université du Maryland, qui a remporté le championnat national 4 fois (1968, 2005, 2008 et 2018) et a produit plus de 20 joueurs ayant joué dans la MLS, tels qu'Omar Gonzalez, Graham Zusi et Zack Steffen. D'autres programmes de premier plan pour les hommes incluent ceux de l'Université de Virginie, de l'Université de l'Indiana, de l'Université Stanford et de l'Université Wake Forest.
      <br />
      <br />
      Pour les femmes, l'un des programmes les plus dominants est celui de l'Université de Caroline du Nord, qui a remporté le championnat national plus de 20 fois et a produit plus de 40 joueuses ayant joué pour l'équipe nationale, telles que Mia Hamm, Kristine Lilly et Tobin Heath. D'autres programmes de premier plan pour les femmes incluent ceux de l'Université Notre Dame, de l'Université de Portland, de l'Université Stanford et de l'Université d'État de Floride.
      <br />
      <br />
      Ce ne sont que quelques exemples parmi les nombreux excellents programmes et équipes de football universitaire en Amérique. Si vous voulez en savoir plus sur les différents programmes et équipes, vous pouvez rechercher en ligne des classements, des statistiques, des critiques, des témoignages de joueurs et d'entraîneurs. Vous pouvez également contacter Sportbeursamerika.nl, qui peut vous aider à trouver le meilleur programme et équipe qui vous convient.
    </MKTypography>
  </MKBox>
</MKBox>






                                  






{/* Chapter 3 */}
<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 3 : Du Collège aux Professionnels
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    De nombreux jeunes joueurs de football rêvent un jour de jouer professionnellement et d'imiter leurs idoles au plus haut niveau. Mais comment pouvez-vous réaliser ce rêve ? Quelles sont les étapes à suivre pour passer du collège aux professionnels ? Et quelles sont les opportunités et les défis que vous rencontrerez en chemin ? Dans ce chapitre, nous répondrons à ces questions et vous montrerons comment le football universitaire peut servir de tremplin vers une carrière professionnelle.
  </MKTypography>
  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Le Chemin vers le Professionnalisme
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      L'un des avantages les plus significatifs du football universitaire est qu'il vous offre la possibilité de vous développer à la fois sur le plan athlétique et académique tout en profitant de la vie étudiante. En jouant au football et en étudiant en Amérique, vous pouvez non seulement améliorer vos compétences en football mais aussi élargir vos connaissances, élargir votre réseau, façonner votre personnalité et améliorer vos perspectives d'avenir.
      <br />
      <br />
      Le football universitaire peut également être un tremplin vers une carrière professionnelle si vous le souhaitez. Il existe différentes façons de passer du football universitaire au circuit professionnel, aux États-Unis et à l'étranger. Les méthodes les plus courantes incluent :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          MLS SuperDraft : C'est un événement annuel où les équipes de la MLS sélectionnent à tour de rôle des joueurs parmi un pool de joueurs disponibles, dont la plupart viennent du football universitaire. Le MLS SuperDraft se compose de quatre tours, chaque équipe effectuant une sélection par tour. L'ordre des sélections est déterminé par divers facteurs tels que la performance de l'équipe lors de la saison précédente, la loterie et les échanges potentiels. Le MLS SuperDraft est l'un des principaux moyens pour les joueurs de football universitaire d'entrer dans la MLS et offre la possibilité de décrocher un contrat et une bourse.
        </li>
        <li>
          Règle des Joueurs Formés au Club MLS : Cette règle permet aux équipes de la MLS de signer des joueurs de leurs propres académies de jeunes sans utiliser de choix de draft. La règle vise à encourager et récompenser le développement des talents locaux. De nombreuses équipes de la MLS ont des partenariats avec des programmes universitaires, ce qui leur permet de suivre et de mentoriser des joueurs qui jouent à la fois pour leur université et leurs équipes de jeunes. La règle des Joueurs Formés au Club MLS est un autre moyen pour les joueurs de football universitaire d'entrer dans la MLS et offre la possibilité de décrocher un contrat et une bourse.
        </li>
        <li>
          Programme Generation Adidas : Sponsorisé par Adidas, ce programme est conçu pour aider les jeunes joueurs à passer du football universitaire au football professionnel. Le programme sélectionne des joueurs prometteurs, généralement âgés de moins de 20 ans, qui n'ont pas encore obtenu leur diplôme universitaire. Ces joueurs se voient offrir des contrats par la MLS, ce qui leur permet de participer au MLS SuperDraft sans perdre leur éligibilité universitaire. Cela signifie qu'ils peuvent toujours retourner à leur université pour terminer leurs études s'ils le souhaitent. Le programme offre également des bourses d'études aux joueurs pour couvrir leurs frais d'études.
        </li>
        <li>
          Opportunités à l'Étranger : Ce sont des opportunités pour les joueurs de football universitaire de jouer à l'étranger, par exemple, en Europe, en Asie ou en Amérique du Sud. Ces opportunités dépendent généralement de divers facteurs tels que le niveau du joueur, la nationalité, l'âge et le réseau. Certains joueurs peuvent, par exemple, exploiter une double nationalité ou des origines familiales pour obtenir un passeport étranger, ce qui facilite leur participation dans un autre pays. D'autres joueurs peuvent bénéficier de leurs liens avec des recruteurs, des agents ou des entraîneurs qui peuvent les aider à obtenir un contrat avec un club étranger. Les opportunités à l'étranger sont un autre moyen pour les joueurs de football universitaire de poursuivre une carrière professionnelle, mais elles comportent également plus de risques et d'incertitudes.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ce ne sont que quelques-unes des façons de passer du collège aux professionnels, mais il en existe d'autres. La clé est d'être conscient des opportunités disponibles et de se préparer aux défis que vous pourriez rencontrer. Il est également important d'être réaliste quant à vos chances et vos attentes et de ne pas mettre tous vos œufs dans le même panier. Le football universitaire est une opportunité fantastique de se développer en tant que joueur et en tant que personne. Par conséquent, il est sage de garder d'autres options ouvertes et de ne pas se concentrer uniquement sur le football mais aussi sur vos études, vos passe-temps, vos amis et votre famille.
      <br />
      <br />
      Dans le prochain chapitre, nous explorerons l'influence du football international sur le football universitaire et comment cela impacte et inspire les joueurs de football universitaire.
    </MKTypography>
  </MKBox>
</MKBox>

                






                                  







{/* Chapter 4 */}
<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 4 : L'Influence du Football International
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le football est un sport mondial qui connecte des millions de personnes à travers le monde. Il n'est pas surprenant que le football international ait une influence significative sur le football universitaire en Amérique. Dans ce chapitre, nous explorerons comment les influences étrangères impactent et inspirent le football universitaire et comment les joueurs américains trouvent leur voie vers des contrées étrangères.
  </MKTypography>
  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Influences Étrangères
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Le prestigieux trophée du soccer NCAA."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Le trophée NCAA convoité
        </figcaption>
      </figure>
      La popularité du football international aux États-Unis a considérablement augmenté ces dernières années. Selon un sondage Gallup de 2023, le soccer est le troisième sport le plus populaire parmi les Américains, après le football américain et le basketball. Cela est en partie dû à la diversité croissante de la population américaine, à l'accessibilité des matchs internationaux à la télévision et sur Internet, et au succès de l'équipe nationale américaine sur la scène mondiale.
      <br />
      <br />
      Cet intérêt pour le football international a également eu un impact sur le football universitaire. De nombreux joueurs de football universitaire admirent leurs joueurs et équipes étrangers préférés, essayant d'imiter leur style de jeu et leur technique. Certains joueurs de football universitaire ont même des origines étrangères ou une double nationalité, créant un lien spécial avec un pays ou une région particulière.
      <br />
      <br />
      Un exemple est Gio Reyna, le fils de l'ancien international américain Claudio Reyna. Gio Reyna est né en Angleterre, où son père a joué pour Sunderland et Manchester City. Il a grandi avec un amour pour le football anglais et a joué pour les équipes de jeunes du New York City FC. En 2023, il a déménagé au Borussia Dortmund en Allemagne, où il est rapidement devenu l'un des plus grands talents d'Europe. Il a également fait ses débuts avec l'équipe nationale américaine, formant une nouvelle génération aux côtés d'autres jeunes stars comme Christian Pulisic et Weston McKennie.
      <br />
      <br />
      Un autre exemple est Catarina Macario, une attaquante brésilienne qui a déménagé aux États-Unis à l'âge de douze ans. Elle a joué pour l'Université de Stanford, où elle a remporté le trophée Hermann deux fois en tant que meilleure joueuse de football universitaire du pays. Elle a également obtenu la citoyenneté américaine, ce qui lui a permis de représenter l'équipe nationale féminine des États-Unis. En 2023, elle a signé un contrat avec l'Olympique Lyonnais en France, l'une des meilleures équipes féminines du monde.
      <br />
      <br />
      Ces exemples illustrent comment les influences étrangères enrichissent et diversifient le football universitaire et comment les joueurs de football universitaire peuvent réaliser leurs rêves dans le football international.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Les Américains à l'Étranger
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Non seulement les influences étrangères impactent le football universitaire, mais les joueurs américains qui cherchent leur fortune à l'étranger jouent également un rôle significatif. Un nombre croissant de joueurs américains choisissent de partir en Europe ou sur d'autres continents après leur carrière universitaire pour rivaliser avec les meilleurs joueurs du monde.
      <br />
      <br />
      Un des exemples les plus réussis est Clint Dempsey, qui a joué pour l'Université de Furman avant de rejoindre la MLS. En 2023, il a déménagé à Fulham en Angleterre, où il est devenu un buteur prolifique. Il a marqué plus de 50 buts pour le club, dont un but historique contre la Juventus en Ligue Europa. Il a également joué pour Tottenham Hotspur et Seattle Sounders et a été un joueur clé pour l'équipe nationale américaine.
      <br />
      <br />
      Une autre success story est Tobin Heath, qui a joué pour l'Université de Caroline du Nord, où elle a remporté deux championnats nationaux. Elle a également joué pour divers clubs aux États-Unis, en France et en Angleterre, dont le Paris Saint-Germain et Manchester United. Elle a remporté quatre médailles d'or olympiques et deux Coupes du Monde FIFA avec l'équipe nationale féminine des États-Unis, où elle est connue pour ses compétences techniques et sa créativité.
      <br />
      <br />
      Ces exemples montrent comment les joueurs américains peuvent se développer et exceller à l'étranger et comment ils peuvent mettre le football universitaire sur la carte en tant qu'incubateur de talents.
    </MKTypography>
  </MKBox>
</MKBox>







                                  



<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 5 : Bourses Sportives et l'Avenir des Athlètes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Les bourses sportives offrent de nombreuses opportunités aux jeunes athlètes de montrer leur talent et de développer leurs compétences. L'une des opportunités les plus significatives est d'obtenir une bourse sportive pour étudier et compétitionner au niveau collégial. Dans ce chapitre, nous discuterons de l'importance des bourses sportives et de comment Sport Scholarships America peut vous aider à réaliser votre rêve.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 L'Importance des Bourses
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Une bourse sportive est un soutien financier fourni par une université à un étudiant-athlète pour combiner études et sport. Elle peut couvrir les frais de scolarité, les livres, le logement, les repas et autres dépenses. De plus, elle peut offrir des avantages tels que du tutorat académique, des soins médicaux, des installations d'entraînement et du matériel.
      <br />
      <br />
      Obtenir une bourse sportive est compétitif, avec de nombreux facteurs qui influent sur le processus d'attribution, notamment les performances académiques, sportives, la personnalité, la motivation, le leadership et l'adaptabilité. Il est donc important de bien se préparer et de se démarquer de la concurrence.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Comment Sport Scholarships America Peut Aider
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo de Sport Scholarships America, représentant du matériel sportif et une casquette de graduation, symbolisant le focus sur les bourses sportives pour les étudiants-athlètes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America est une organisation spécialisée dans l'orientation des jeunes talents sportifs vers des bourses sportives. Avec des experts passionnés et expérimentés, Sport Scholarships America soutient les étudiants-athlètes à chaque étape du parcours, de l'orientation initiale à la placement final. Leurs services comprennent :
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Un entretien personnel pour discuter des souhaits, des possibilités et des attentes de l'étudiant-athlète.</li>
        <li>Une évaluation professionnelle du niveau académique et sportif de l'étudiant-athlète.</li>
        <li>Un vaste réseau de contacts avec des entraîneurs, des universités et des collèges.</li>
        <li>Promotion efficace du profil et des vidéos de l'étudiant-athlète auprès des entraîneurs potentiels.</li>
        <li>Conseils d'experts sur les meilleures options en fonction des préférences, des objectifs et du budget de l'étudiant-athlète.</li>
        <li>Négociation des meilleures offres de bourses au nom de l'étudiant-athlète.</li>
        <li>Aide à la demande de documents nécessaires, tels que visa, relevé de notes, diplôme et tests d'admission.</li>
        <li>Préparation à la vie d'étudiant-athlète pendant sa bourse, y compris culture, langue, règles et conseils.</li>
        <li>Suivi et accompagnement pendant la période de la bourse.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vous voulez explorer les opportunités de bourses et atteindre vos objectifs sportifs et académiques ? <Link to="/fr/Services-Produits">Consultez la gamme complète</Link> des services offerts par Sport Scholarships America et planifiez une consultation dès aujourd'hui.
    </MKTypography>
  </MKBox>
</MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;