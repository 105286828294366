/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Swimming1.jpg";
import Image2 from "assets/images/Sports_Images/Swimming2.jpeg";
import Image3 from "assets/images/Sports_Images/Swimming3.avif";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Schwimmen in Amerika: Wie man ein Sportstipendium bekommt und Teil einer angesehenen Sportgeschichte wird', isSubheader: false },
  
    { id: 'Kapitel-1', title: 'Kapitel 1: Die Geschichte und Entwicklung des Schwimmens in Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Frühe Jahre und die Rolle der Universitäten', isSubheader: true },
    { id: '1.2', title: '1.2 Wachstum und Professionalisierung', isSubheader: true },
  
    { id: 'Kapitel-2', title: 'Kapitel 2: Das Schwimmen an Universitäten heute', isSubheader: false },
    { id: '2.1', title: '2.1 Wichtige Wettbewerbe und Konferenzen', isSubheader: true },
    { id: '2.2', title: '2.2 Top-Programme und prestigeträchtige Teams', isSubheader: true },
  
    { id: 'Kapitel-3', title: 'Kapitel 3: Vom College zum Profi-Level', isSubheader: false },
    { id: '3.1', title: '3.1 Der Weg zum Profisport', isSubheader: true },
    { id: '3.2', title: '3.2 Professionelles Schwimmen und die Olympischen Spiele', isSubheader: true },
  
    { id: 'Kapitel-4', title: 'Kapitel 4: Internationale Einflüsse und Wettkämpfe', isSubheader: false },
    { id: '4.1', title: '4.1 Ausländische Einflüsse', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikaner im Ausland', isSubheader: true },
  
    { id: 'Kapitel-5', title: 'Kapitel 5: Sportstipendien und die Zukunft der Athleten', isSubheader: false },
    { id: '5.1', title: '5.1 Die Bedeutung von Stipendien', isSubheader: true },
    { id: '5.2', title: '5.2 Wie Sportstipendien Amerika helfen können', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhaltsverzeichnis
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verstecken</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Anzeigen</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Schwimmen in Amerika: Wie man ein Sportstipendium bekommt und Teil einer angesehenen Sportgeschichte wird
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Schwimmstadion gefüllt mit Zuschauern während eines College-Wettbewerbs."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Ein Stadion während eines Spiels
      </figcaption>
    </figure>
    Schwimmen ist eine der beliebtesten und wettbewerbsfähigsten Sportarten der Welt. Millionen von Menschen genießen das Schwimmen, sowohl in ihrer Freizeit als auch professionell. Aber wussten Sie, dass das Schwimmen in Amerika ein ganz anderes Niveau erreicht hat? Schwimmen in Amerika ist nicht nur ein Sport; es ist eine Kultur, eine Tradition und eine Lebensweise.
    <br />
    <br />
    In diesem Artikel werden wir Ihnen alles über die Entwicklung des Schwimmens als Wettkampfsport in den Vereinigten Staaten, die Bedeutung des Hochschul-Schwimmens für die Weiterentwicklung des Sports und wie man ein Sportstipendium erhält, um in Amerika zu studieren und zu schwimmen, erzählen. Wir werden auch auf die Rolle von Sportbeursamerika.nl eingehen, einer Organisation, die jungen Schwimmern hilft, ihre Träume zu verwirklichen.
    <br />
    <br />
    Schwimmen ist eine Sportart, die seit Jahrhunderten praktiziert wird, aber erst im 19. Jahrhundert begann sie sich als organisierte und regulierte Aktivität zu entwickeln. Die ersten modernen Olympischen Spiele im Jahr 1896 umfassten Schwimmwettbewerbe als eine der Hauptveranstaltungen, und seitdem ist das Schwimmen ein fester Bestandteil der olympischen Agenda.
    <br />
    <br />
    Aber warum ist das Schwimmen in Amerika so beliebt und erfolgreich? Was macht es so besonders und einzigartig? Die Antwort liegt in der Rolle der Universitäten bei der Förderung des Schwimmens als akademische und sportliche Disziplin. Amerikanische Universitäten bieten nicht nur eine hochwertige Ausbildung, sondern auch ausgezeichnete Einrichtungen, Coaching und Anleitung für Studenten-Athleten, die sich auf das Schwimmen spezialisieren möchten. Darüber hinaus bieten sie finanzielle Unterstützung in Form von Sportstipendien, die talentierten Schwimmern ermöglichen, ihr Potenzial zu entfalten, ohne sich um die Kosten ihrer Ausbildung sorgen zu müssen.
    <br />
    <br />
    Sportstipendien zu erhalten ist jedoch nicht einfach. Sie sind sehr konkurrenzfähig und erfordern ein hohes Maß an Engagement, Disziplin und Leistung. Daher ist es wichtig, gut vorbereitet zu sein und professionelle Hilfe in Anspruch zu nehmen, wenn Sie sich um ein Sportstipendium bewerben. Hier kommt Sportbeursamerika.nl ins Spiel.
    <br />
    <br />
    Sportbeursamerika.nl ist eine Organisation, die sich auf die Beratung junger Schwimmer zu Sportstipendien in Amerika spezialisiert hat. Sie haben ein Expertenteam, das Sie bei allen Aspekten des Prozesses unterstützen kann, wie der Auswahl der richtigen Universität, der Vorbereitung Ihres Lebenslaufs und Ihres Motivationsschreibens, der Vorbereitung auf Zulassungsprüfungen und Interviews, der Organisation Ihres Visums und Ihrer Versicherung und vielem mehr. Sie haben auch Verbindungen zu Hunderten von Universitäten und Trainern in Amerika, was es ihnen ermöglicht, Sie mit den besten Optionen für Ihr Profil und Ihre Ziele abzustimmen.
    <br />
    <br />
    Also, wenn Sie davon träumen, in Amerika zu studieren und zu schwimmen, ist Sportbeursamerika.nl Ihr bester Verbündeter. Sie haben bereits Hunderten von Schwimmern geholfen, ihre Träume wahr werden zu lassen, und sie können auch Ihnen helfen. Bevor Sie sich jedoch an sie wenden, werfen wir zuerst einen Blick auf die Geschichte und Entwicklung des Schwimmens in Amerika und warum es eine fantastische Gelegenheit für Sie ist.
  </MKTypography>
</MKBox>

<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Die Geschichte und Entwicklung des Schwimmens in Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Schwimmen hat eine lange und reiche Geschichte in Amerika. Alles begann im späten 19. Jahrhundert, als Universitäten begannen, ihre eigenen Schwimmteams und Wettkämpfe zu bilden. Dies markierte den Beginn einer Tradition, die zum Aufstieg des Schwimmens als Nationalsport führen würde.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Frühe Jahre und die Rolle der Universitäten
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Die erste Universität, die ein offizielles Schwimmteam gründete, war Harvard im Jahr 1878. Bald darauf folgten andere angesehene Universitäten wie Yale, Princeton, Columbia und Cornell. Diese Universitäten organisierten interkollegiale Wettkämpfe und Turniere, die öffentliche und mediale Aufmerksamkeit erregten. Sie führten auch neue Regeln und Techniken für das Schwimmen ein, darunter Brustschwimmen, Rückenschwimmen und Schmetterling.
      <br />
      <br />
      Im Jahr 1902 wurde die Intercollegiate Swimming Association (ISA) gegründet, die erste nationale Organisation für das Hochschul-Schwimmen. Die ISA organisierte jährliche Meisterschaften für Männer, bei denen die besten Teams und Einzelschwimmer geehrt wurden. Im Jahr 1916 wurde die National Collegiate Athletic Association (NCAA) als übergreifende Organisation für alle Hochschulsportarten gegründet. Die NCAA übernahm die Verantwortung für die Organisation der nationalen Meisterschaften für das Hochschul-Schwimmen, die bis heute stattfinden.
      <br />
      <br />
      Das Hochschul-Schwimmen beschränkte sich nicht nur auf Männer. Frauen begannen ebenfalls im frühen 20. Jahrhundert auf Hochschulebene zu konkurrieren, obwohl sie mit mehr Einschränkungen und Diskriminierung konfrontiert waren. Die ersten weiblichen Hochschul-Schwimmteams wurden 1914 an der University of Chicago und 1916 an der University of Wisconsin gegründet. Im Jahr 1922 wurde die Women’s Intercollegiate Swimming Association (WISA) gegründet, die als Pendant zur ISA für Frauen diente. Die WISA organisierte ebenfalls jährliche Meisterschaften für Frauen, die große Popularität erlangten.
      <br />
      <br />
      Das Hochschul-Schwimmen spielte eine entscheidende Rolle bei der Förderung des Schwimmens als Profisport in Amerika. Viele Hochschul-Schwimmer gingen in professionelle Ligen und die Olympischen Spiele, wo sie ihr Land vertraten und Medaillen gewannen. Zu den bekanntesten Namen gehören Johnny Weissmuller, Mark Spitz, Matt Biondi, Janet Evans, Michael Phelps und Katie Ledecky.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Wachstum und Professionalisierung
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Neben dem Hochschul-Schwimmen entstanden in Amerika auch andere Formen des professionellen Schwimmens. Eine davon war das Masters-Schwimmen, das sich auf erwachsene Schwimmer konzentrierte, die ihre Fähigkeiten verbessern oder ihre Fitness erhalten wollten. Das Masters-Schwimmen begann 1970 mit der Gründung des United States Masters Swimming (USMS), einer Organisation, die lokale Clubs, Wettkämpfe und Programme für erwachsene Schwimmer anbietet. Das Masters-Schwimmen entwickelte sich schnell zu einer globalen Bewegung mit Millionen von Mitgliedern in über 100 Ländern.
      <br />
      <br />
      Eine weitere Form des professionellen Schwimmens war das Freiwasserschwimmen, das in natürlichen Gewässern wie Seen, Flüssen und Ozeanen stattfand. Das Freiwasserschwimmen war eine herausfordernde und abenteuerliche Sportart, die Ausdauer, Navigationsfähigkeiten und Anpassungsfähigkeit erforderte. Das Freiwasserschwimmen erlangte 2008 mehr Anerkennung, als es als olympische Disziplin hinzugefügt wurde. Einige der bekanntesten Freiwasserschwimmer sind Gertrude Ederle, Lynne Cox, Maarten van der Weijden und Ous Mellouli.
      <br />
      <br />
      Das professionelle Schwimmen erreichte seinen Höhepunkt während der Olympischen Spiele, bei denen amerikanische Schwimmer dominierten. Seit 1896 hat Amerika über 500 Medaillen im Schwimmen gewonnen, darunter mehr als 200 Goldmedaillen. Damit ist Amerika bei weitem das erfolgreichste Land im olympischen Schwimmen.
      <br />
      <br />
      Aber wie können Sie auch Teil dieser glorreichen Sportgeschichte sein? Wie können Sie mit einem Sportstipendium in Amerika studieren und schwimmen? Das erfahren Sie im nächsten Kapitel.
    </MKTypography>
  </MKBox>
</MKBox>



                                              





                                  







<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Das Schwimmen an Universitäten heute
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Wie Sie gesehen haben, hat das Schwimmen an Universitäten in Amerika eine lange und stolze Geschichte. Aber wie sieht es in der Gegenwart aus? Wie ist das Schwimmen an Universitäten heute organisiert und strukturiert? Was sind die wichtigsten Wettbewerbe und Konferenzen? Welche sind die Top-Programme und prestigeträchtigen Teams? Werfen wir einen genaueren Blick darauf.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Wichtige Wettbewerbe und Konferenzen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Katie Ledecky beim Schwimmen für Stanford in einem College-Wettbewerb."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Katie Ledecky im Einsatz als Schwimmerin für Stanford
        </figcaption>
      </figure>
      Das Schwimmen an Universitäten in Amerika wird hauptsächlich von der NCAA reguliert, die drei Divisionen hat: Division I, Division II und Division III. Jede Division hat ihre eigenen Regeln, Kriterien und Meisterschaften. Im Allgemeinen ist Division I die wettbewerbsfähigste und prestigeträchtigste Division mit den besten Schwimmern, Trainern und Einrichtungen. Division II und III sind weniger wettbewerbsfähig, bieten aber dennoch eine hochwertige Ausbildung und Sportmöglichkeiten.
      <br />
      <br />
      In jeder Division gibt es verschiedene Konferenzen, die Gruppen von Universitäten zusammenfassen, die geografisch oder historisch verbunden sind. Jede Konferenz organisiert ihre eigenen Meisterschaften, wobei sich die besten Teams und Einzelschwimmer für die NCAA-Landesmeisterschaften qualifizieren. Einige der bekanntesten Konferenzen im Hochschul-Schwimmen sind:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Die Southeastern Conference (SEC), bestehend aus 14 Universitäten im Südosten der Vereinigten Staaten, wie Alabama, Florida, Georgia, Kentucky, LSU, Tennessee und Texas A&M. Die SEC ist bekannt für ihre Dominanz im Schwimmen, insbesondere bei den Männer-Events. Die SEC hat über 50 nationale Titel im Schwimmen gewonnen, wobei mehr als 40 davon vom Männer-Team in Florida stammen.
        </li>
        <li>
          Die Pacific-12 Conference (Pac-12), bestehend aus 12 Universitäten an der Westküste Amerikas, darunter Arizona, Kalifornien, Stanford, UCLA, USC und Washington. Die Pac-12 ist bekannt für ihre Exzellenz im Schwimmen, insbesondere bei den Frauen-Events. Die Pac-12 hat über 40 nationale Titel im Schwimmen gewonnen, wobei mehr als 30 davon dem Frauen-Team in Stanford gehören.
        </li>
        <li>
          Die Big Ten Conference (Big Ten), bestehend aus 14 Universitäten im Mittleren Westen und Nordosten der Vereinigten Staaten, wie Indiana, Michigan, Minnesota, Ohio State, Penn State und Wisconsin. Die Big Ten ist bekannt für ihre Vielfalt und Ausgewogenheit im Schwimmen, mit starken Teams in den Männer- und Frauen-Events. Die Big Ten hat über 30 nationale Titel im Schwimmen gewonnen, wobei über 20 davon vom Männer-Team in Michigan gesichert wurden.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dies sind nur einige Beispiele für die vielen Konferenzen, die im Hochschul-Schwimmen existieren. Jede Konferenz hat ihre eigene Kultur, Rivalitäten und Traditionen, die zur Spannung im Hochschul-Schwimmen beitragen.
    </MKTypography>

  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Top-Programme und prestigeträchtige Teams
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Neben den Konferenzen gibt es einzelne Universitäten, die aufgrund ihrer Top-Programme und prestigeträchtigen Schwimmteams herausragen. Diese Universitäten bieten nicht nur eine ausgezeichnete Ausbildung für ihre Studenten, sondern auch erstklassiges Coaching, Training und Einrichtungen für ihre Athleten. Sie ziehen auch viele talentierte Schwimmer aus dem In- und Ausland an, die auf höchstem Niveau studieren und schwimmen möchten.
      <br />
      <br />
      Einige dieser Top-Universitäten sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Stanford University: Stanford ist sowohl akademisch als auch sportlich eine der renommiertesten Universitäten der Welt. Stanford hat eines der besten Frauen-Schwimmprogramme in Amerika mit über 10 nationalen Titeln und mehr als 100 olympischen Medaillen. Stanford hat auch ein starkes Männer-Schwimmprogramm mit über 5 nationalen Titeln und mehr als 50 olympischen Medaillen. Einige berühmte Alumni von Stanford sind John Hencken, Pablo Morales, Jenny Thompson, Summer Sanders, Misty Hyman, Janet Evans und Katie Ledecky.
        </li>
        <li>
          University of California: Kalifornien ist eine weitere Top-Universität sowohl akademisch als auch sportlich. Kalifornien hat eines der besten Männer-Schwimmprogramme in Amerika mit über 10 nationalen Titeln und mehr als 100 olympischen Medaillen. Kalifornien hat auch ein starkes Frauen-Schwimmprogramm mit über 5 nationalen Titeln und mehr als 50 olympischen Medaillen. Einige berühmte Alumni von Kalifornien sind Matt Biondi, Anthony Ervin, Natalie Coughlin, Nathan Adrian, Dana Vollmer und Ryan Murphy.
        </li>
        <li>
          University of Texas: Texas ist eine der größten und einflussreichsten Universitäten Amerikas mit einem starken Ruf in Wissenschaft und Sport. Texas hat eines der besten Männer-Schwimmprogramme in Amerika mit über 15 nationalen Titeln und mehr als 100 olympischen Medaillen. Texas hat auch ein starkes Frauen-Schwimmprogramm mit über 5 nationalen Titeln und mehr als 50 olympischen Medaillen. Einige berühmte Alumni von Texas sind Josh Davis, Aaron Peirsol, Ian Crocker, Brendan Hansen, Ricky Berens und Joseph Schooling.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dies sind nur einige der vielen Top-Universitäten im Hochschul-Schwimmen. Jede Universität hat ihre eigene Geschichte, Philosophie und Vision, die zur Entwicklung von Schwimmern beitragen.
      <br />
      <br />
      Wie Sie sehen können, ist das Schwimmen an Universitäten heute ein äußerst dynamischer und wettbewerbsfähiger Sport, der vielen Studenten-Athleten die Möglichkeit bietet, sowohl akademisch als auch sportlich herausragend zu sein. Aber wie können Sie diese Möglichkeiten nutzen? Wie können Sie ein Sportstipendium erhalten, um in Amerika zu studieren und zu schwimmen? Das erfahren Sie im nächsten Kapitel.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  








<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Vom College zum Profi-Level
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Das Studium und Schwimmen in Amerika ist nicht nur eine großartige Erfahrung, sondern auch eine fantastische Gelegenheit, Ihre Karriere als professioneller Schwimmer zu starten. Viele College-Schwimmer machen den Übergang zum Profi-Level, wo sie ihr Land in internationalen Wettbewerben und den Olympischen Spielen vertreten. Aber wie gelingt der Sprung vom College zum Profi-Level? Was sind die Herausforderungen und Vorteile dieses Übergangs? Lassen Sie uns einen genaueren Blick darauf werfen.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Der Weg zum Profisport
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Um den Übergang vom College zum Profi-Level zu schaffen, müssen Sie zunächst die Anforderungen der NCAA erfüllen. Die NCAA hat strenge Regeln bezüglich des Amateurstatus von Studenten-Athleten, was bedeutet, dass Sie kein Geld für Ihre sportliche Leistung verdienen oder akzeptieren können. Dies bedeutet, dass Sie keine Sponsorenverträge, Preisgeldvereinbarungen oder andere Formen der Vergütung akzeptieren können, während Sie noch auf College-Ebene studieren und schwimmen.
      <br />
      <br />
      Sobald Sie jedoch Ihr Studium abgeschlossen haben oder sich entscheiden, Ihre Ausbildung zu beenden, können Sie Ihren Amateurstatus aufgeben und professioneller Schwimmer werden. Dies bedeutet, dass Sie sich einer professionellen Schwimmorganisation wie USA Swimming anschließen können, dem nationalen Schwimmverband Amerikas. USA Swimming bietet Ihnen die Möglichkeit, an professionellen Schwimmwettbewerben wie der Pro Swim Series, den US Open, den US Nationals und den Olympic Trials teilzunehmen. Diese Wettbewerbe sind darauf ausgelegt, Ihre Fähigkeiten zu verbessern, Ihr Ranking zu steigern und Sie für internationale Veranstaltungen zu qualifizieren.
      <br />
      <br />
      Als professioneller Schwimmer können Sie auch von Sponsorenverträgen, Preisgeldern und anderen Formen der Vergütung für Ihre sportliche Leistung profitieren. Dies kann dazu beitragen, Ihre finanzielle Situation zu verbessern, Ihren Lebensstil zu unterstützen und Ihre Motivation zu steigern. Der Übergang zum professionellen Schwimmer bringt jedoch auch mehr Verantwortung und Herausforderungen mit sich. Zum Beispiel müssen Sie Ihren eigenen Trainingsplan, Ernährungsplan, Reisekosten, medizinische Versorgung und Steuern verwalten. Sie werden auch mit erhöhtem Wettbewerb, Druck und Erwartungen von Ihnen und anderen konfrontiert sein.
      <br />
      <br />
      Daher ist es entscheidend, gut auf den Übergang vom College zum Profi-Level vorbereitet zu sein. Sie müssen nicht nur über ausgezeichnete Schwimmfähigkeiten verfügen, sondern auch über eine starke mentale Einstellung, klare Ziele und gute Unterstützung. Sie müssen auch realistisch über Ihre Chancen und Möglichkeiten sein und bereit sein, hart zu arbeiten und Opfer zu bringen.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professionelles Schwimmen und die Olympischen Spiele
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Einer der größten Träume jedes professionellen Schwimmers ist die Teilnahme an den Olympischen Spielen, dem prestigeträchtigsten und bedeutendsten sportlichen Ereignis der Welt. Die Olympischen Spiele bieten nicht nur die Möglichkeit, Ihr Land zu vertreten und Medaillen zu gewinnen, sondern auch, Geschichte zu schreiben und zur Legende zu werden.
      <br />
      <br />
      Um sich jedoch für die Olympischen Spiele zu qualifizieren, müssen Sie zunächst die Anforderungen des Internationalen Olympischen Komitees (IOC) und der Internationalen Schwimmföderation (FINA) erfüllen. Diese Organisationen haben strenge Regeln bezüglich der Qualifikationskriterien, der Anzahl der Teilnehmer pro Land, der Anti-Doping-Kontrollen und anderer Aspekte des olympischen Schwimmens.
      <br />
      <br />
      Eine der wichtigsten Anforderungen, um sich für die Olympischen Spiele zu qualifizieren, ist das Erreichen eines spezifischen Zeitstandards in einem anerkannten Wettbewerb. Diese Zeitstandards werden von der FINA auf der Grundlage des Durchschnitts der besten 16 Leistungen weltweit in jeder Disziplin in den letzten vier Jahren festgelegt. Der Zeitstandard variiert je nach Geschlecht, Altersgruppe und Schwimmstil.
      <br />
      <br />
      Eine weitere wesentliche Anforderung für die Qualifikation für die Olympischen Spiele ist die Auswahl durch Ihre nationale Schwimmorganisation, wie zum Beispiel USA Swimming. Jede nationale Schwimmorganisation hat ihren eigenen Auswahlprozess, der in der Regel eine Serie von Ausscheidungswettkämpfen oder Meisterschaften umfasst, bei denen die besten Schwimmer ausgewählt werden, um das Land zu vertreten. Jede nationale Schwimmorganisation hat auch eine begrenzte Anzahl von Plätzen pro Disziplin, abhängig von der Anzahl der qualifizierten Schwimmer und den Quoten, die vom IOC und der FINA festgelegt wurden.
      <br />
      <br />
      Wenn es Ihnen gelingt, diese Anforderungen zu erfüllen und sich für die Olympischen Spiele zu qualifizieren, gehören Sie zu den glücklichen Wenigen, die die Möglichkeit haben, Teil des größten sportlichen Ereignisses der Welt zu sein. Sie werden gegen die besten Schwimmer der Welt antreten, sich selbst herausfordern und möglicherweise eine Medaille gewinnen und Ihren Namen in die Geschichtsbücher eintragen können.
      <br />
      <br />
      Wie Sie sehen können, ist der Übergang vom College zum Profi-Level eine aufregende und lohnende Reise, die viele Möglichkeiten für Ihre Schwimmkarriere bietet. Aber wie können Sie diese Reise beginnen? Wie können Sie ein Sportstipendium erhalten, um in Amerika zu studieren und zu schwimmen? Das erfahren Sie im nächsten Kapitel.
    </MKTypography>
  </MKBox>
</MKBox>





                                  








<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationale Einflüsse und Wettkämpfe
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Schwimmen in Amerika ist nicht nur ein nationaler, sondern auch ein internationaler Sport. Schwimmen in Amerika wird von anderen Ländern und Kulturen beeinflusst und beeinflusst sie ihrerseits, von denen jede ihre eigenen Stile, Techniken und Traditionen im Schwimmen hat. Schwimmen in Amerika wird auch durch andere internationale Wettkämpfe herausgefordert und bereichert, die Gelegenheiten bieten, von Schwimmern aus der ganzen Welt zu lernen und gegen sie anzutreten. Aber wie entstanden und entwickelten sich diese internationalen Einflüsse und Wettkämpfe? Wie wirken sie sich auf das College- und Profischwimmen in Amerika aus? Lassen Sie uns einen genaueren Blick darauf werfen.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Ausländische Einflüsse
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Schwimmen ist ein universeller Sport, der in fast jedem Land der Welt praktiziert wird. Jedes Land hat seine eigene Geschichte, Kultur und Identität im Schwimmen, die sich darin widerspiegeln, wie sie schwimmen, trainieren und coachen. Einige Länder haben einen erheblichen Einfluss auf das Schwimmen in Amerika gehabt, sowohl positiv als auch negativ.
      <br />
      <br />
      Einer der positiven Einflüsse ist Australien, das einer der größten Rivalen und Verbündeten Amerikas im Schwimmen ist. Australien hat eine lange und stolze Tradition im Schwimmen, mit mehr als 100 olympischen Medaillen und über 20 Weltrekorden. Australien ist bekannt für seine Innovation und Kreativität im Schwimmen, indem es neue Techniken, Strategien und Methoden einführt. Australien hat auch viele talentierte und charismatische Schwimmer hervorgebracht, wie Dawn Fraser, Shane Gould, Ian Thorpe, Grant Hackett, Libby Trickett und Cate Campbell.
      <br />
      <br />
      Australien hat das Schwimmen in Amerika durch die Schaffung eines gesunden Wettbewerbs und einer Zusammenarbeit beeinflusst, die beide Länder dazu motiviert, sich zu verbessern und voneinander zu lernen. Viele australische und amerikanische Schwimmer haben gemeinsam trainiert, studiert oder gearbeitet und dadurch ihre Fähigkeiten, ihr Wissen und ihre Freundschaften verbessert. Einige Beispiele sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          1976 trainierte Mark Spitz, der amerikanische Star, der in München sieben Goldmedaillen gewann, neben John Devitt, der australischen Legende, die in Melbourne und Rom zwei Goldmedaillen gewann.
        </li>
        <li>
          1988 schloss Matt Biondi, die amerikanische Sensation, die in Seoul sieben Medaillen gewann, sein Studium an der University of California mit einem Abschluss in Politischer Ökonomie ab.
        </li>
        <li>
          2016 schloss Joseph Schooling, die singapurische Überraschung, die in Rio de Janeiro die erste Goldmedaille für sein Land über 100m Schmetterling gewann, sein Studium an der University of Texas mit einem Abschluss in Wirtschaftswissenschaften ab.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dies sind nur einige Beispiele dafür, wie ausländische Einflüsse das Schwimmen in Amerika beeinflusst haben. Viele weitere Länder und Kulturen haben ihre Spuren im Schwimmen hinterlassen, darunter Japan, China, Russland, Frankreich, Brasilien und Südafrika. Schwimmen in Amerika ist nicht nur ein nationaler, sondern auch ein internationaler Sport, der sich ständig durch die Interaktion mit anderen Ländern und Kulturen weiterentwickelt und verändert.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikaner im Ausland
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Schwimmen in Amerika wird nicht nur von anderen Ländern und Kulturen beeinflusst und beeinflusst sie, sondern wird auch durch andere internationale Wettkämpfe herausgefordert und bereichert. Schwimmen in Amerika bietet die Möglichkeit, von Schwimmern aus der ganzen Welt zu lernen und gegen sie anzutreten, von denen jeder seine eigenen Stile, Techniken und Traditionen im Schwimmen hat. Schwimmen in Amerika bietet auch die Möglichkeit, zu verschiedenen Orten, Menschen und Kulturen zu reisen und sie zu erleben, wodurch Ihr Horizont erweitert und Ihre Perspektive verändert wird.
      <br />
      <br />
      Einer der wichtigsten internationalen Wettkämpfe, an denen amerikanische Schwimmer teilnehmen, sind die Weltmeisterschaften im Schwimmen, die alle zwei Jahre von der FINA ausgetragen werden. Die Weltmeisterschaften im Schwimmen sind eine Veranstaltung, die alle Schwimmdisziplinen umfasst, einschließlich Langbahn, Kurzbahn, Freiwasser, Synchronschwimmen und Wasserspringen. Die Weltmeisterschaften im Schwimmen bieten die Möglichkeit, gegen die besten Schwimmer der Welt anzutreten, Ihre Platzierung zu verbessern und Ihre Qualifikation für andere Veranstaltungen zu verbessern.
      <br />
      <br />
      Ein weiterer bedeutender internationaler Wettbewerb, an dem amerikanische Schwimmer teilnehmen, sind die Weltcup-Schwimmwettbewerbe, die jährlich von der FINA ausgetragen werden. Die Weltcup-Schwimmwettbewerbe sind eine Serie von Kurzbahn-Veranstaltungen, die in verschiedenen Städten auf der ganzen Welt stattfinden. Die Weltcup-Schwimmwettbewerbe bieten die Möglichkeit, gegen verschiedene Gegner anzutreten, Ihre Fähigkeiten zu testen und Preisgelder zu gewinnen.
      <br />
      <br />
      Ein dritter bedeutender internationaler Wettbewerb, an dem amerikanische Schwimmer teilnehmen, sind die Panamerikanischen Spiele, die alle vier Jahre von der Pan American Sports Organization (PASO) veranstaltet werden. Die Panamerikanischen Spiele sind eine Multisportveranstaltung, bei der Athleten aus Nord-, Süd- und Mittelamerika zusammenkommen. Die Panamerikanischen Spiele bieten die Möglichkeit, Ihren Kontinent zu vertreten, Solidarität zu zeigen und Medaillen zu gewinnen.
      <br />
      <br />
      Dies sind nur einige Beispiele für die vielen internationalen Wettkämpfe, an denen amerikanische Schwimmer teilnehmen. Es gibt viele weitere internationale Schwimmwettbewerbe, wie die Commonwealth-Spiele, die Europameisterschaften, die Asienspiele und die Afrikaspiele.
    </MKTypography>
  </MKBox>
</MKBox>
                                






                                  


                                
<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Sportstipendien und die Zukunft der Athleten
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sportstipendien bieten zahlreiche Möglichkeiten für junge Athleten, ihr Talent zu zeigen und ihre Fähigkeiten zu entwickeln. Eine der bedeutendsten Möglichkeiten ist der Erhalt eines Sportstipendiums, um auf Hochschulebene zu studieren und zu konkurrieren. In diesem Kapitel werden wir die Bedeutung von Sportstipendien diskutieren und wie Sportstipendien Amerika Ihnen helfen kann, Ihren Traum zu verwirklichen.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Die Bedeutung von Stipendien
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ein Sportstipendium ist finanzielle Unterstützung, die von einer Universität einem Studenten-Athleten zur Verfügung gestellt wird, um Studium und Sport zu kombinieren. Es kann die Kosten für Studiengebühren, Bücher, Unterkunft, Mahlzeiten und andere Ausgaben abdecken. Darüber hinaus kann es auch Vorteile wie akademische Nachhilfe, medizinische Versorgung, Trainingsmöglichkeiten und Ausrüstung bieten.
      <br />
      <br />
      Die Erlangung eines Sportstipendiums ist wettbewerbsfähig, wobei viele Faktoren eine Rolle im Zuteilungsprozess spielen, einschließlich akademischer Leistungen, sportlicher Leistung, Persönlichkeit, Motivation, Führung und Anpassungsfähigkeit. Daher ist es wichtig, sich gut vorzubereiten und sich von der Konkurrenz abzuheben.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Wie Sportstipendien Amerika helfen können
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo von Sportstipendien Amerika, das Sportausrüstung und eine Abschlusskappe zeigt, die den Fokus auf Sportstipendien für Studenten-Athleten repräsentiert."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo von Sportstipendien Amerika
        </figcaption>
      </figure>
      Sportstipendien Amerika ist eine Organisation, die sich auf die Anleitung junger Sporttalente zu Sportstipendien spezialisiert hat. Mit leidenschaftlichen und erfahrenen Experten unterstützt Sportstipendien Amerika Studenten-Athleten auf jedem Schritt der Reise, von der ersten Orientierung bis zur endgültigen Platzierung. Ihre Dienstleistungen umfassen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Ein persönliches Aufnahmegespräch, um die Wünsche, Möglichkeiten und Erwartungen des Studenten-Athleten zu besprechen.</li>
        <li>Eine professionelle Bewertung des sportlichen und akademischen Niveaus des Studenten-Athleten.</li>
        <li>Ein umfangreiches Netzwerk von Kontakten zu Trainern, Universitäten und Hochschulen.</li>
        <li>Effektive Werbung für das Profil und die Videos des Studenten-Athleten bei potenziellen Trainern.</li>
        <li>Expertenberatung zu den besten Optionen basierend auf den Vorlieben, Zielen und dem Budget des Studenten-Athleten.</li>
        <li>Verhandlung der besten Stipendienangebote im Namen des Studenten-Athleten.</li>
        <li>Hilfe bei der Beantragung der erforderlichen Dokumente wie Visum, Zeugnis, Diplom und Zulassungstests.</li>
        <li>Vorbereitung auf das Leben des Studenten-Athleten während seines Stipendiums, einschließlich Kultur, Sprache, Regeln und Tipps.</li>
        <li>Nachsorge und Follow-up während der Stipendiumszeit.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Möchten Sie Stipendienmöglichkeiten erkunden und Ihre sportlichen und akademischen Ziele erreichen? <Link to="/de/Dienstleistungen-Produkte">Sehen Sie sich die vollständige Palette</Link> der von Sportstipendien Amerika angebotenen Dienstleistungen an und vereinbaren Sie noch heute eine Beratung.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;