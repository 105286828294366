/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Hockey1.jpg";
import Image2 from "assets/images/Sports_Images/Hockey2.jpeg";
import Image3 from "assets/images/Sports_Images/Hockey3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");




  
  
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Becas de Hockey sobre Césped en Estados Unidos: Cómo el Hockey sobre Césped Puede Ayudarte a Alcanzar tus Sueños', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capítulo 1: La Historia y Desarrollo del Hockey sobre Césped en Estados Unidos', isSubheader: false },
    { id: '1.1', title: '1.1 Historia Temprana', isSubheader: true },
    { id: '1.2', title: '1.2 Desarrollo y Profesionalización', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capítulo 2: Hockey sobre Césped a Nivel Universitario', isSubheader: false },
    { id: '2.1', title: '2.1 Competiciones y Conferencias', isSubheader: true },
    { id: '2.2', title: '2.2 Impacto en el Desarrollo del Jugador', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capítulo 3: Transición de la Universidad al Profesionalismo', isSubheader: false },
    { id: '3.1', title: '3.1 De la Universidad a los Profesionales', isSubheader: true },
    { id: '3.2', title: '3.2 Oportunidades Profesionales y Ligas', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capítulo 4: Influencias y Oportunidades Internacionales', isSubheader: false },
    { id: '4.1', title: '4.1 Influencias Globales', isSubheader: true },
    { id: '4.2', title: '4.2 Estadounidenses en el Escenario Mundial', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capítulo 5: Becas Deportivas y el Futuro de los Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 La Importancia de las Becas', isSubheader: true },
    { id: '5.2', title: '5.2 Cómo Puede Ayudar Sport Scholarships America', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  const [,] = useState("");


  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contenidos
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Ocultar</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Becas de Hockey sobre Césped en Estados Unidos: Cómo el Hockey sobre Césped Puede Ayudarte a Alcanzar tus Sueños
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Jugador en acción durante un partido de hockey sobre césped universitario en Colgate."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
      Jugador en acción durante un partido
      </figcaption>
    </figure>

    El hockey sobre césped es uno de los deportes de más rápido crecimiento en Estados Unidos. Cada vez más jóvenes atletas están descubriendo los beneficios y la diversión de este deporte dinámico y desafiante. Pero, ¿sabías que el hockey sobre césped también puede ayudarte a alcanzar tus metas académicas y profesionales?
    <br />
    <br />

    Si tienes pasión por el hockey sobre césped, tienes una oportunidad única para jugar a nivel universitario en Estados Unidos. Esto significa que puedes estudiar en una de las mejores universidades del mundo mientras desarrollas tus habilidades atléticas bajo la guía profesional. Además, puedes calificar para una beca deportiva, que brinda apoyo financiero para tu educación.

    <br />
    <br />
    Pero, ¿cómo obtienes una beca de este tipo? ¿Y cómo te preparas para jugar hockey sobre césped en Estados Unidos? Ahí es donde Sportbeursamerika.nl puede ayudarte. Sportbeursamerika.nl es una agencia especializada que ayuda a jóvenes talentos a cumplir su sueño de estudiar y jugar hockey en Estados Unidos. Ofrezco orientación personalizada, consejos y apoyo para encontrar la universidad adecuada, solicitar una beca, manejar todos los asuntos prácticos e integrarse en la cultura estadounidense.
    <br />
    <br />
    En este artículo, te contaré más sobre la historia y el desarrollo del hockey sobre césped en Estados Unidos, las oportunidades y beneficios de jugar a nivel universitario, la transición de la universidad al juego profesional, las influencias y oportunidades internacionales dentro del deporte, y, por supuesto, la importancia de las becas y el papel de Sportbeursamerika.nl. Después de leer este artículo, tendrás una mejor comprensión de lo que implica el hockey sobre césped en Estados Unidos y cómo prepararte para este emocionante desafío.
    <br />
    <br />
    ¿Estás listo para aprender más sobre el hockey sobre césped en Estados Unidos? ¡Sigue leyendo!
  </MKTypography>
  {/* Agregar lista con viñetas u otro contenido según sea necesario */}
</MKBox>

<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 1: La Historia y Desarrollo del Hockey sobre Césped en Estados Unidos
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El hockey sobre césped es un deporte con una historia larga y rica. ¿Pero cómo se originó y creció en Estados Unidos? En este capítulo, te llevaremos en un viaje a través del tiempo, desde la introducción temprana hasta la profesionalización actual del deporte.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Historia Temprana
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      El hockey sobre césped fue introducido por primera vez en los Estados Unidos a finales del siglo XIX por inmigrantes británicos y turistas. El juego ganó rápidamente popularidad entre las mujeres, quienes lo veían como una forma de hacer ejercicio y socializar. Los primeros equipos universitarios se formaron en 1901 en el Vassar College en Nueva York y el Bryn Mawr College en Pensilvania. Estos equipos principalmente jugaban entre ellos o contra clubes locales.
      <br />
      <br />
      En 1922, se estableció la Asociación de Hockey sobre Césped de los Estados Unidos (USFHA) como la organización nacional para el deporte. La USFHA organizó los primeros campeonatos nacionales para mujeres en 1925 y para hombres en 1930. La USFHA también fue responsable de seleccionar y entrenar equipos nacionales que competían en torneos internacionales como los Juegos Olímpicos y los Campeonatos Mundiales.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Desarrollo y Profesionalización
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      En la segunda mitad del siglo XX, el hockey sobre césped continuó creciendo en popularidad y calidad en Estados Unidos. El juego se expandió aún más por todo el país, especialmente en el Noreste, Medio Oeste y Oeste. El número de equipos universitarios aumentó, y se formaron más ligas y conferencias. Algunos de los programas más prestigiosos y competitivos incluyen la Universidad de Princeton, la Universidad de Maryland, la Universidad de Carolina del Norte, la Universidad de Stanford y la Universidad de Harvard.
      <br />
      <br />
      El nivel de hockey sobre césped universitario también aumentó gracias a instalaciones mejoradas, entrenamiento y reclutamiento. Muchas universidades ahora ofrecen becas deportivas a jugadores talentosos de hockey sobre césped, lo que les permite estudiar sin preocupaciones financieras. Además, el hockey sobre césped universitario sirve como un semillero para el hockey sobre césped profesional, que también está volviéndose más desarrollado y profesionalizado.
      <br />
      <br />
      El hockey sobre césped profesional también ha influido en el equipo nacional, que ha tenido un mejor desempeño en el escenario internacional. Por ejemplo, el equipo femenino de EE. UU. ha ganado bronce dos veces en los Juegos Olímpicos (en 1984 y 1996) y plata dos veces en los Juegos Panamericanos (en 2011 y 2015). El equipo masculino de EE. UU. también ha participado en múltiples Juegos Olímpicos y Campeonatos Mundiales, pero aún no ha asegurado ninguna medalla.
      <br />
      <br />
      Como puedes ver, el hockey sobre césped ha recorrido un largo camino en Estados Unidos, desde una actividad recreativa hasta un deporte profesional. Pero, ¿qué implica jugar hockey sobre césped a nivel universitario? Te lo diremos en el próximo capítulo.
    </MKTypography>
  </MKBox>
</MKBox>











                                              





                                  







<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 2: Hockey sobre Césped a Nivel Universitario
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Jugar hockey sobre césped a nivel universitario es una de las experiencias más desafiantes y gratificantes para un joven atleta. Te ofrece la oportunidad de combinar tu talento atlético con tus ambiciones académicas y de formar parte de una comunidad unida y diversa. En este capítulo, te contaremos más sobre las competiciones y conferencias, el impacto en el desarrollo del jugador y los beneficios de jugar hockey sobre césped a nivel universitario.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Competiciones y Conferencias
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Vista aérea del estadio de hockey sobre césped de Boston College"
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
        El estadio de Boston College
        </figcaption>
      </figure>
      El hockey sobre césped a nivel universitario está regulado por la Asociación Atlética Universitaria Nacional (NCAA), que divide el deporte en tres divisiones: División I, División II y División III. Cada división tiene sus propias reglas, requisitos y becas. En general, la División I es el nivel de competencia más alto, con los mejores equipos y jugadores, y ofrece la mayor cantidad de becas. La División II y III son menos competitivas pero aún proporcionan amplias oportunidades para jugadores talentosos.
      <br />
      <br />
      Dentro de cada división, hay varias conferencias, que consisten en universidades que están conectadas geográfica o históricamente. Cada conferencia tiene su propio calendario, clasificaciones y campeonato. Algunas de las conferencias más conocidas incluyen la Conferencia Big Ten, la Conferencia de la Costa Atlántica, la Ivy League, la Patriot League y la America East Conference.
      <br />
      <br />
      Los mejores equipos y jugadores de cada conferencia pueden clasificar para el campeonato nacional, que se celebra anualmente en noviembre. El campeonato nacional involucra un torneo eliminatorio, con el ganador coronado como campeón nacional.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Impacto en el Desarrollo del Jugador
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Jugar hockey sobre césped a nivel universitario tiene un impacto significativo en el desarrollo del jugador. Permite a los jugadores mejorar sus habilidades bajo la tutela de entrenadores profesionales, entrenar con instalaciones y equipos de última generación, competir contra otros atletas de élite y aprender de sus compañeros de equipo y oponentes.
      <br />
      <br />
      Además, jugar hockey sobre césped a nivel universitario también ofrece a los jugadores muchos beneficios personales. Ayuda a los jugadores a aumentar su confianza, disciplina, liderazgo, trabajo en equipo, comunicación y habilidades para resolver problemas. También ayuda a los jugadores a mejorar su manejo del tiempo, hábitos de estudio, manejo del estrés y bienestar general. Además, expone a los jugadores a diferentes culturas, perspectivas y experiencias, ampliando sus horizontes.
      <br />
      <br />
      La historia de las jugadoras de hockey holandesas que reciben becas para jugar a nivel universitario en América sirve como ejemplo de cómo esta experiencia puede mejorar su desarrollo como jugadoras. Muchas han jugado para prestigiosos equipos universitarios en Estados Unidos, donde no solo han ganado campeonatos y títulos individuales, sino que también han aprendido lecciones valiosas.
      <br />
      <br />
      La experiencia en América brinda a estos atletas una oportunidad única para sumergirse en una cultura diferente, disfrutar de nuevos entornos sociales y experimentar un sistema educativo diferente. La interacción con entrenadores que los desafían y apoyan contribuye a refinar sus habilidades y ampliar su comprensión táctica del juego.
      <br />
      <br />
      Al regresar a los Países Bajos, estas jugadoras de hockey han utilizado sus conocimientos y experiencias adquiridos para destacarse a nivel profesional y, en algunos casos, asumir roles de liderazgo dentro del equipo nacional. Su tiempo en América muchas veces las ha preparado para la presión y las expectativas que conllevan las competiciones internacionales, sentando las bases para su éxito futuro.
      <br />
      <br />
      Como puedes ver, jugar hockey sobre césped a nivel universitario puede ofrecerte muchas ventajas, tanto atléticas como personales. ¿Pero cómo haces la transición de la universidad al juego profesional? Lo explicaremos en el próximo capítulo.
    </MKTypography>
  </MKBox>
</MKBox>





                                  








<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 3: Transición de la Universidad al Profesionalismo
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El hockey sobre césped a nivel universitario es una excelente manera de desatar tu potencial atlético, pero no tiene por qué ser el fin de tu carrera. Si tienes la ambición y la pasión para ir más lejos, puedes hacer la transición al juego profesional. En este capítulo, te contaremos más sobre cómo pasar de la universidad a los profesionales y qué oportunidades profesionales y ligas existen para los jugadores de hockey sobre césped.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 De la Universidad a los Profesionales
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Hacer la transición de la universidad al juego profesional no es fácil, pero tampoco es imposible. Requiere mucha dedicación, trabajo duro, talento y un poco de suerte. El aspecto más crucial es mostrarte a las personas adecuadas que pueden ayudarte a alcanzar tu sueño.
      <br />
      <br />
      Una forma de mostrarte es participar en pruebas. Las pruebas son sesiones de entrenamiento o partidos abiertos donde los equipos profesionales pueden buscar y evaluar jugadores. Las pruebas suelen realizarse antes o después de la temporada y pueden anunciarse a través de sitios web, redes sociales o boca a boca. Las pruebas pueden ser una excelente oportunidad para mostrar tus habilidades y recibir comentarios de entrenadores y managers. Las pruebas pueden ser realizadas por equipos profesionales de todo el mundo.
      <br />
      <br />
      Otra forma de darte a conocer es aprovechar tu red de contactos. Tu red incluye a todas las personas que conoces o te encuentras que están relacionadas con el hockey sobre césped, incluidos entrenadores, compañeros de equipo, oponentes, amigos, familiares, profesores, exalumnos, etc. Tu red puede ayudarte a mantenerte informado sobre las últimas noticias, oportunidades y consejos en el mundo del hockey sobre césped. También puede ayudarte a conectarte con personas que tienen influencia o contactos en el ámbito profesional.
      <br />
      <br />
      Además, muchos jugadores de hockey europeos regresan a Europa para probar suerte allí. Como puedes ver, hay varias formas de llamar la atención de los equipos profesionales. ¿Pero qué tipos de equipos existen y qué tipos de ligas existen? Explicaremos eso en la próxima sección.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Oportunidades Profesionales y Ligas
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Para un jugador de hockey sobre césped que ha pasado por el sistema universitario estadounidense, las puertas a una carrera profesional a menudo están ampliamente abiertas. Esta experiencia es más que un trampolín; es una preparación integral que los ha desarrollado en muchos aspectos: físicamente, tácticamente y mentalmente.
      <br />
      <br />
      Un jugador que sobresale en el hockey universitario puede llamar la atención de cazatalentos y equipos profesionales, no solo por su desempeño en el campo, sino también por la disciplina y versatilidad que han cultivado al estudiar y jugar simultáneamente. Las ligas profesionales de hockey en todo el mundo, como las de los Países Bajos, Bélgica, Alemania y Australia, son conocidas por reclutar jugadores talentosos con una sólida formación en hockey universitario.
      <br />
      <br />
      La naturaleza competitiva del hockey universitario en Estados Unidos también garantiza que los jugadores estén acostumbrados a un alto nivel de entrenamiento y preparación física, lo cual es esencial para el juego profesional. Además, a menudo tienen experiencia jugando en partidos y campeonatos importantes, lo que les ayuda a manejar la presión de las competiciones profesionales.
      <br />
      <br />
      Además, la experiencia internacional y la exposición a diferentes estilos de juego en América proporcionan a estos jugadores una perspectiva única que pueden llevar a sus carreras profesionales. Les ayuda a adaptarse rápidamente a nuevos equipos y estrategias y les proporciona una comprensión más amplia del juego.
      <br />
      <br />
      Los equipos profesionales valoran la combinación de disciplina académica y excelencia atlética que un antiguo jugador universitario aporta. Estos jugadores suelen estar bien preparados para enfrentar los desafíos de la vida profesional, incluido el equilibrio entre el deporte y otros compromisos de vida.
      <br />
      <br />
      Como puedes ver, hay muchas oportunidades profesionales y ligas para jugadores de hockey sobre césped en América. ¿Pero qué pasa con el resto del mundo? ¿Cómo se compara el hockey sobre césped en América con el hockey sobre césped internacional? Explicaremos eso en el próximo capítulo.
    </MKTypography>
  </MKBox>
</MKBox>





                                  







<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 4: Influencias y Oportunidades Internacionales
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El hockey sobre césped en América no está aislado, sino que es parte de un mundo del hockey sobre césped más grande y diverso. El hockey sobre césped es un deporte global que se juega en más de 100 países en todos los continentes. El hockey sobre césped en América está influenciado por y también influye en el hockey sobre césped internacional. En este capítulo, te contaremos más sobre las influencias globales, los estadounidenses en el escenario mundial y las oportunidades internacionales para los jugadores de hockey sobre césped.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Influencias Globales
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      El hockey sobre césped en América está influenciado por el hockey sobre césped jugado en otros países, especialmente en Europa y Asia. Estos países tienen una tradición más larga y rica en hockey sobre césped y a menudo tienen un nivel de juego y organización más alto. Al observar y aprender de estos países, el hockey sobre césped en América puede desarrollarse y mejorar aún más.
      <br />
      <br />
      Una forma en que el hockey sobre césped en América está influenciado por el hockey sobre césped internacional es adoptando diferentes estilos y tácticas. Por ejemplo, el hockey sobre césped holandés es conocido por su juego rápido y ofensivo, con un enfoque en la posesión del balón, las combinaciones y los intentos de gol. El hockey sobre césped alemán es conocido por su estilo físico y defensivo, con alta presión, duelos y contraataques. El hockey sobre césped indio es conocido por su juego técnico y creativo, con un enfoque en el dribbling, el pase y los trucos.
      <br />
      <br />
      Al aprender de estos estilos y tácticas, el hockey sobre césped en América puede diversificar su propio juego y adaptarse a diferentes situaciones, agregando más variedad, flexibilidad y efectividad.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Estadounidenses en el Escenario Mundial
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      El hockey sobre césped en América también impacta en el hockey sobre césped internacional al proporcionar jugadores que participan en los torneos más grandes y significativos en todo el mundo. Estos jugadores no solo representan a su país, sino también a su universidad, equipo y deporte. A través de sus actuaciones, demuestran lo que el hockey sobre césped en América tiene para ofrecer e inspiran a otros a participar en este deporte.
      <br />
      <br />
      Un ejemplo destacado de cómo los jugadores estadounidenses entran en el escenario mundial es la historia de Lauren Crandall. Lauren es una jugadora estadounidense de hockey sobre césped que recibió una beca para jugar en la Universidad de Wake Forest. Jugó para el equipo durante cuatro temporadas, ganando el título de la División I de la NCAA en dos ocasiones.
      <br />
      <br />
      Lauren también formó parte del equipo nacional estadounidense, participando en tres Juegos Olímpicos (en 2008, 2012 y 2016), dos Campeonatos Mundiales (en 2010 y 2014) y cuatro Juegos Panamericanos (en 2007, 2011, 2015 y 2019). También fue capitana del equipo desde 2013 hasta 2016.
      <br />
      <br />
      Lauren afirma que su tiempo en América la ayudó significativamente a realizar su sueño de jugar al más alto nivel. Aprendió mucho de su entrenadora, Jennifer Averill, quien la apoyó y motivó. También disfrutó de la cultura, la gente y la educación en América. Se enorgullece de representar a su país y de ser un modelo a seguir para los jóvenes jugadores.
      <br />
      <br />
      Como puedes ver, el hockey sobre césped en América puede ofrecerte muchas influencias y oportunidades internacionales, tanto como jugador como individual. ¿Pero cómo puedes aprovechar estas oportunidades? ¿Y cómo puedes obtener una beca para estudiar y jugar en América? Te lo contaremos en el próximo capítulo.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  




<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Becas Deportivas y el Futuro de los Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Las becas deportivas ofrecen numerosas oportunidades para que los jóvenes atletas muestren su talento y desarrollen sus habilidades. Una de las oportunidades más significativas es obtener una beca deportiva para estudiar y competir a nivel universitario. En este capítulo, discutiremos la importancia de las becas deportivas y cómo Sport Scholarships America puede ayudarte a hacer realidad tu sueño.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 La Importancia de las Becas
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Una beca deportiva es un apoyo financiero proporcionado por una universidad a un estudiante-atleta para combinar estudios y deporte. Puede cubrir los costos de matrícula, libros, alojamiento, comidas y otros gastos. Además, puede ofrecer beneficios como tutoría académica, atención médica, instalaciones de entrenamiento y equipo.
      <br />
      <br />
      Obtener una beca deportiva es competitivo, con muchos factores que influyen en el proceso de adjudicación, incluyendo logros académicos, rendimiento deportivo, personalidad, motivación, liderazgo y adaptabilidad. Por lo tanto, es importante prepararse bien y destacarse entre la competencia.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Cómo Puede Ayudar Sport Scholarships America
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo de Sport Scholarships America, que muestra equipamiento deportivo y una gorra de graduación, representando el enfoque en becas deportivas para estudiantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America es una organización especializada en guiar a jóvenes talentos deportivos hacia becas deportivas. Con expertos apasionados y experimentados, Sport Scholarships America apoya a los estudiantes-atletas en cada paso del camino, desde la orientación inicial hasta la colocación final. Sus servicios incluyen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Una entrevista personal para discutir los deseos, posibilidades y expectativas del estudiante-atleta.</li>
        <li>Una evaluación profesional del nivel académico y deportivo del estudiante-atleta.</li>
        <li>Una extensa red de contactos con entrenadores, universidades y colegios.</li>
        <li>Promoción efectiva del perfil y videos del estudiante-atleta ante entrenadores potenciales.</li>
        <li>Asesoramiento experto sobre las mejores opciones basadas en las preferencias, metas y presupuesto del estudiante-atleta.</li>
        <li>Negociación de las mejores ofertas de becas en nombre del estudiante-atleta.</li>
        <li>Asistencia en la solicitud de documentos necesarios, como visa, expediente académico, diploma y pruebas de admisión.</li>
        <li>Preparación para la vida del estudiante-atleta durante su beca, incluyendo cultura, idioma, reglas y consejos.</li>
        <li>Seguimiento y atención posterior durante el período de la beca.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      ¿Quieres explorar oportunidades de becas y alcanzar tus metas deportivas y académicas? <Link to="/es/Servicios-Productos">Consulta la gama completa</Link> de servicios ofrecidos por Sport Scholarships America y programa una consulta hoy mismo.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;