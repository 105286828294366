/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import { Helmet } from 'react-helmet';

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbarDeutsch";

// Author page sections
import Profile from "Languages/Deutsch/pages/Over/Overmij/NaUSA/sections/Profile";

// Routes
import routes from "routes_Deutsch";import Breadcrumbs from "examples/Breadcrumbs";
import footerRoutes from "footer.routes";
import DetailedFooter from "examples/Footers/DetailedFooter";
// Images
import bgImage from "assets/images/Achtergronden/Amsterdam_BG.png";

function NaUSA() {
  return (
    <>
  <Helmet>
    <title>Teil 3: Zurück in den Niederlanden - Die Anwendung meiner Erfahrungen</title>
    <meta name="description" content="Erfahren Sie, wie meine Rückkehr in die Niederlande der Beginn eines neuen Kapitels war, in dem ich mit meinen Erfahrungen anderen helfe, ihren amerikanischen Traum zu verwirklichen." />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta charset="UTF-8" />
    <meta name="robots" content="index, follow" />
    
    {/* Open Graph Tags */}
    
    
    
    
    

    {/* Favicon */}
    <link rel="icon" href="https://sportscholarshipsamerica.com/32x32Sportbeurs.png" />
</Helmet>


       <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://calendly.com/sportbeurs-amerika/intake",
          label: "TERMIN BUCHEN",
          color: "info",
        }}
        transparent
        light
         
      />
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center 40%",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
                  <MKBox
  width={{ xs: "80%", md: "50%", lg: "25%" }}
  mb={1}
  sx={{
    backgroundColor: "transparent", // Transparent background color
  }}
>
  <Breadcrumbs
    routes={[
      { label: "Home", route: "/de" },
      { label: "Über Mich", route: "/de/Über-mich" },
      { label: "Meine Rückkehr in die Niederlande" },
    ]}
  />
</MKBox>  
          <Profile />
        </Card>
      
        <MKBox pt={6} px={1} mt={6}>
        <DetailedFooter content={footerRoutes} />
      </MKBox>    </>
  );
}

export default NaUSA;
