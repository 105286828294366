/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


// @mui material components
import React, { useState } from 'react'; // Step 1: Import useState


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Logos/NCAA.png";
import post3 from "assets/images/Logos/NAIA.png";



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {

  const articleStructure = [
    { id: 'intro', title: 'NJCAA: Springbrættet til Store Colleges og NCAA', isSubheader: false },
  
    { id: 'Kapitel_1', title: 'Oprindelse og Vækst af NJCAA', isSubheader: true },
    { id: '1.1', title: 'NJCAAs Tre Divisioner', isSubheader: true },
    { id: '1.2', title: 'Overgangen til Større Colleges og NCAA', isSubheader: true },
  
    { id: 'Kapitel_2', title: 'Akademikernes rolle', isSubheader: true },
    { id: '2.1', title: 'Økonomi og Synlighed', isSubheader: true },
    { id: '2.2', title: 'Udfordringer og Fremtiden', isSubheader: true },
    { id: '2.3', title: 'Afslutning', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
     <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="intro" mb={3}>

<MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>NJCAA: Springbrættet til Store Colleges og NCAA</MKTypography>
</MKBox>

<MKBox id="Kapitel 1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Oprindelse og Vækst af NJCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
National Junior College Athletic Association (NJCAA) blev grundlagt i 1938 og er siden vokset til at være en prominent aktør i det amerikanske collegiale sportslandskab. Organisationen tilbyder sportsmuligheder til junior colleges, mindre toårige institutioner, der giver studerende et springbræt til højere uddannelse og atletisk fremragende.

<br />
<br />
I begyndelsen var NJCAA begrænset i størrelse, men den er vokset og tilpasset for at imødekomme behovene hos en mangfoldig og skiftende studenterpopulation. Fokus ligger på at give adgang til atletisk konkurrence, samtidig med at den akademiske fremgang fremmes.

</MKTypography>

<MKBox id="1.1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
NJCAAs Tre Divisioner
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
NJCAA er opdelt i tre divisioner, hver designet til at imødekomme forskellige behov og muligheder for studerende-athleter:

<br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division I:{' '}
</MKTypography>
Denne division omfatter skoler, der er i stand til at tilbyde fulde atletikstipendier, hvilket resulterer i et højere niveau af konkurrence. Studerende-atleter har her mulighed for at udvikle deres færdigheder og konkurrere på et intensivt niveau, ofte som et springbræt til NCAA-divisionerne.

<br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division II:{' '}
</MKTypography>
Her finder vi en balance mellem atletisk konkurrence og akademiske ansvar, med skoler, der må tilbyde delvise stipendier. Dette sikrer en sund balance og giver studerende-atleter mulighed for at udmærke sig i deres sport, samtidig med at de også forberedes til akademisk succes.   <br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division III:{' '}
</MKTypography>
I denne division ligger fokus på at tilbyde konkurrencedygtige sportsmuligheder uden atletikstipendier. Studerende-atleter får mulighed for at deltage i sport, mens de også fuldt ud deltager i det akademiske liv, hvilket resulterer i en velafbalanceret collegial oplevelse.  </MKTypography>


<MKBox id="1.2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Overgangen til Større Colleges og NCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
NJCAA spiller en nøglerolle som springbræt for studerende-athleter, der ønsker at fortsætte deres akademiske og atletiske karriere på større fireårige institutioner, herunder dem i NCAA. Mange studerende-athleter begynder deres rejse i NJCAA for at udvikle deres færdigheder, forbedre akademiske kvalifikationer og gøre sig selv bemærket af rekrutter fra større programmer.


<br />
<br />
Ved at bevise sig i NJCAAs konkurrenceprægede miljø kan studerende-atleter tiltrække opmærksomhed fra NCAA-skoler og muligvis tjene stipendier til at fortsætte deres uddannelse og sportskarriere. Denne proces er ikke kun gavnlig for atleterne selv, men bidrager også til at styrke sportsprogrammer over hele landet.


</MKTypography>






                                  








<MKBox id="Kapitel 2" mb={3}>
  
  
  <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Akademikernes rolle
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              NJCAA arbejder hårdt for akademisk succes og udvikling af dens studerende-atleter. Der er strenge akademiske standarder, og de tilknyttede skoler tilbyder essentiel vejledning for at sikre, at atleterne excellerer både i klasseværelset og i deres sport. Dette illustrerer den afgørende rolle af en afbalanceret tilgang inden for college sportslandskabet, selvom niveauet kan være lidt lavere end på nogle andre uddannelsesinstitutioner.
<br />
                <br />
                </MKTypography>

                <MKBox id="2.1" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Økonomi og Synlighed
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              På trods af at økonomiske ressourcer er mere begrænsede sammenlignet med NCAA, spiller NJCAA en afgørende rolle i støtten til atletiske programmer og fremme af muligheder for studerende-atleter. Organisationen arbejder også på at øge sin synlighed og rækkevidde med det formål at nå og inspirere flere studerende og atleter.


<br />
                </MKTypography>

                <MKBox id="2.2" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Udfordringer og Fremtiden
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              NJCAA står, ligesom andre atletiske organisationer, over for udfordringer, herunder at navigere gennem spørgsmål om ligestilling i sport og støtte til mentale helbred hos studerende-atleter. Dog er organisationen godt positioneret til fortsat at gøre en positiv indvirkning med dens forpligtelse til at fremme muligheder og støtte studerende-atleter.



                </MKTypography>

                <MKBox id="2.3" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Afslutning
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              National Junior College Athletic Association er en væsentlig komponent i det amerikanske college sportslandskab, der giver unikke muligheder for studerende-atleter at konkurrere, lære og vokse. Med dens forpligtelse til akademisk og atletisk fremragende forbliver NJCAA en indflydelsesrig kraft i formningen af fremtiden for unge atleter i Amerika.








<br />


                </MKTypography>


              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="NCAA"
                    description="NCAA: Hjertet af College Sport i Amerika"
                    action={{
                      type: "internal",
                      route: "/da/NCAA",
                      color: "info",
                      label: "Læs Mere",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="NAIA"
                    description="NAIA: En Alternativ Rute i College Sport i Amerika"
                    action={{
                      type: "internal",
                      route: "/da/NAIA",
                      color: "info",
                      label: "Læs Mere",
                     }}
                    />
                  </Grid>
                </Grid>
  
              </Grid>
          </Grid>
        </Container>
      </MKBox>
    );
  }
  
  export default Beschrijving;