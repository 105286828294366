import React, { useState } from "react";
import { Container, Grid, Box, Typography } from "@mui/material";
import MKBadge from "components/MKBadge";
import MKButton from "components/MKButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ExampleCard from "../ExampleCard";
import Toefl_cover from "assets/images/Producten/Toefl_kaft.png";
import Toefl_open from "assets/images/Producten/Toefl_open.png";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";

import MKSnackbar from "components/MKSnackbar";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Link } from 'react-router-dom';


const sportsData = [
  {
    items: [
      {
        image: Toefl_cover,
        alt: "Copertina della Guida allo Studio TOEFL creata da Sport Scholarships America, con titolo e logo",
      },
      {
        image: Toefl_cover,
        alt: "Copertina della Guida allo Studio TOEFL creata da Sport Scholarships America, con titolo e logo",
      },
      {
        image: Toefl_open,
        alt: "Guida allo Studio TOEFL aperta di Sport Scholarships America, mostrando pagine su strategie di test e materiali di pratica",
      },
      {
        image: Toefl_open,
        alt: "Guida allo Studio TOEFL aperta di Sport Scholarships America, mostrando pagine su strategie di test e materiali di pratica",
      },
    ],
  },
];


function DesignBlocks() {
  const [selectedImage, setSelectedImage] = useState(Toefl_cover);
  const [selectedAlt, setSelectedAlt] = useState("Copertina della Guida allo Studio TOEFL creata da Sport Scholarships America, con titolo e logo"); // Imposta il testo alternativo iniziale
  const [quantity, setQuantity] = useState(0);
  const [show, setShow] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showModal, setShowModal] = useState(false);


  const handleIncrement = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 0) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const handleAddToCartClick = () => {
    if (quantity === 0) {
      setShowSnackbar(true);
      setShowModal(false); // Assicura che il modal non si apra contemporaneamente
    } else {
      setShowModal(true);
      setShowSnackbar(false); // Assicura che lo snackbar non si apra contemporaneamente
    }
  };


  const toggleSnackbar = () => setShow(!show);
  const toggleModal = () => setShow(!show);



  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const renderData = sportsData.map(({ items }) => (
    <Grid container spacing={3} alignItems="flex-start">
      <Grid item xs={12} md={4}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
          <ExampleCard
  image={selectedImage}
  alt={selectedAlt} // Passa qui il testo alternativo aggiornato
  applyGradient={false}
  applyPopOut={false}
/>

          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} justifyContent="center">
            {items.map(({ image, name, alt }) => (
  <Grid item xs={3} sm={3} md={3} key={name}>
    <div
      onClick={() => {
        setSelectedImage(image);
        setSelectedAlt(alt); // Aggiorna qui il testo alternativo
      }}
      style={{ cursor: "pointer" }}
    >
      <ExampleCard
        image={image}
        alt={alt} // Passaggio corretto del testo alternativo
        selected={selectedImage === image}
      />
    </div>
  </Grid>
))}

            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} container flexDirection="column" alignItems="flex-start" sx={{ textAlign: "left", my: 0, pl: { xs: 0, md: 4 } }}>
        <MKBadge 
          badgeContent="Sport Scholarships America" 
          variant="contained" 
          color="info" 
          container 
          circular 
          sx={{ mb: isSmallScreen ? 0 : 2, marginLeft: isSmallScreen ? 0 : 4 }} 
        />
        <Typography variant="h1" fontWeight="bold" sx={{ pb: isSmallScreen ? 0 : 2, marginLeft: isSmallScreen ? 0 : 4 }}>
          Guida allo Studio TOEFL
        </Typography>
        <Typography variant="body1" color="text" sx={{ pb: isSmallScreen ? 0 : 2, marginLeft: isSmallScreen ? 0 : 4 }}>
          Questa guida copre in modo esaustivo tutte le sezioni essenziali del TOEFL:
          <ul style={{ paddingLeft: "30px", paddingTop: "10px", paddingBottom: "10px" }}> {/* Aumenta il valore del padding secondo necessità */}
            <li>
              <strong>Lettura:</strong> Strategie di lettura efficaci e risposta accurata alle domande.
            </li>
            <li>
              <strong>Ascolto:</strong> Capacità di ascolto migliorate con abilità di prendere appunti.
            </li>
            <li>
              <strong>Parlare:</strong> Competenza nel parlare e risposta alle domande orali.
            </li>
            <li>
              <strong>Scrittura:</strong> Capacità di scrittura per saggi e discussioni online.
            </li>
          </ul>
          Questa guida allo studio include domande di pratica, risposte dettagliate e preziose intuizioni. Preparati accuratamente per ogni aspetto del test TOEFL!
        </Typography>
        <Typography variant="h3" sx={{ pb: isSmallScreen ? 0 : 2, marginLeft: isSmallScreen ? 0 : 4 }}>
          €10.00
        </Typography>
        <Grid container spacing={2} alignItems="flex-start" sx={{ marginLeft: isSmallScreen ? 0 : 2 }}>
          <Grid item>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid lightgray",
                padding: "5px 10px",
                marginTop: "20px",
                backgroundColor: "lightgrey",
                width: "200px",
                borderRadius: "15px",
                height: "48px",
              }}
            >
              <RemoveIcon onClick={handleDecrement} style={{ cursor: "pointer" }} color=""/>
              <span style={{ color: "" }}>{quantity}</span>
              <AddIcon onClick={handleIncrement} style={{ cursor: "pointer" }} color=""/>
            </div>
          </Grid>
          <Grid item>
            <MKButton 
              color="info" 
              style={{ height: "48px", marginTop: "20px" }} 
              startIcon={<AddShoppingCartIcon />}
              onClick={handleAddToCartClick} // Spostato qui l'onClick
            >
              Aggiungi al Carrello
            </MKButton>
          </Grid>
          <MKSnackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            color="primary"
            icon="notifications"
            title="Sport Scholarships America"
            content="Oops, non puoi aggiungere 0 prodotti. Inserisci una quantità valida."
            dateTime="1 minuto fa"
            open={show}
            close={toggleSnackbar}
          />
        </Grid>
      </Grid>
    </Grid>
  ));
  return (
    <Box component="section" my={6} py={6}>
      <Container sx={{ mt: 0 }}>{renderData}</Container>

      <MKSnackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        color="primary"
        icon="notifications"
        title="Sport Scholarships America"
        content="Oops, non puoi aggiungere 0 prodotti. Si prega di inserire una quantità valida."
        dateTime="0 minuti fa"
        open={showSnackbar}
        close={() => setShowSnackbar(false)}
      />

      <Modal 
        open={showModal} 
        onClose={() => setShowModal(false)} 
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Slide direction="down" in={showModal} timeout={500}>
          <MKBox
            position="relative"
            width="500px"
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
          >
            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
              <MKTypography variant="h5">Temporalmente esaurito
              </MKTypography>
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={() => setShowModal(false)} />
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox p={2}>
              <MKTypography variant="body2" color="secondary" fontWeight="regular">
                Purtroppo, il prodotto che hai selezionato è attualmente esaurito. Mi scuso per l'inconveniente. Sto lavorando sodo per rifornire il magazzino il prima possibile.
                <br/>
                <br/>
                Se hai domande o hai bisogno di ulteriori informazioni, non esitare a contattarmi. Sono qui per aiutarti!
              </MKTypography>
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox display="flex" justifyContent="space-between" p={1.5}>
              <MKButton variant="gradient" color="dark" onClick={() => setShowModal(false)}>
                Chiudi
              </MKButton>
              <MKButton 
                component={Link} 
                to="/it/Modulo-di-contatto" 
                variant="gradient" 
                color="info"
              >
                Modulo di contatto
              </MKButton>
            </MKBox>
          </MKBox>
        </Slide>
      </Modal>

    </Box>
  );
}

export default DesignBlocks;
