
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import SchoolIcon from '@mui/icons-material/School';
import StarIcon from '@mui/icons-material/Star';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2'; 
import Filter3Icon from '@mui/icons-material/Filter3'; 
import Filter4Icon from '@mui/icons-material/Filter4'; 
import Filter5Icon from '@mui/icons-material/Filter5'; 
import Filter6Icon from '@mui/icons-material/Filter6'; 
import Filter7Icon from '@mui/icons-material/Filter7'; 
import Filter8Icon from '@mui/icons-material/Filter8'; 
import Filter9Icon from '@mui/icons-material/Filter9';  // Import the number 1 icon
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import ScheduleIcon from '@mui/icons-material/Schedule';
import VideocamIcon from '@mui/icons-material/Videocam';
import QuizIcon from '@mui/icons-material/Quiz';
import RuleIcon from '@mui/icons-material/Rule';
import ChecklistIcon from '@mui/icons-material/Checklist';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import DescriptionIcon from '@mui/icons-material/Description';




const boxStyle = {
  boxShadow: '0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15)',
  background: '#f5f5f5', // Light grey background
  color: '#000', // Text color set to black for contrast
};

const arrowStyle = {
  borderRight: '7px solid white',
};
function Cronología() {
  return (
    <VerticalTimeline lineColor='rgb(33, 150, 243)'>
      <VerticalTimelineElement
        iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
        icon={<StarIcon />}
      />
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ ...boxStyle, position: 'relative' }} // Añade la posición relativa al estilo
        contentArrowStyle={arrowStyle}
        date="Paso 1"
        iconStyle={{ 
          background: 'rgb(33, 150, 243)', 
          color: '#fff', 
          boxShadow: '0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15)', // Asegúrate de que la sombra se aplique aquí
          border: '2px solid white' // Mantén el borde
        }}
        icon={<RecordVoiceOverIcon />}
      >
        <div style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          opacity: 0.5 // Opacidad reducida para un efecto de marca de agua
        }}>
          <Filter1Icon style={{ color: 'grey', fontSize: '48px' }} />
        </div>

        <h3 className="vertical-timeline-element-title">Consulta inicial:</h3>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Añadido Roboto como familia de fuentes
          fontSize: '17px', // Cambiado el tamaño de la fuente a 17px
          fontWeight: '400', // Asegúrate de que el peso de la fuente sea normal
          marginBottom: '10px', // Ajusta el valor según sea necesario
        }}>
          Este es tu punto de partida: el momento en que alineamos tus sueños y objetivos con un camino realista hacia una beca deportiva en América.
        </p>

        <ul style={{ 
          listStyleType: 'disc', 
          marginLeft: '20px', 
          fontSize: '17px', 
        }}>  
          <li>
            <strong>Objetivos personales:</strong> Aclarar tus ambiciones y lo que buscas en una beca deportiva y experiencia universitaria.
          </li>
          <li>
            <strong>Habilidades deportivas:</strong> Evaluación de tu rendimiento deportivo actual y potencial para competir a nivel universitario.
          </li>
          <li>
            <strong>Aspiraciones académicas:</strong> Comprensión de tus intereses y objetivos académicos, y cómo integrarlos con tu deporte.
          </li>
          <li>
            <strong>Objetivos personales:</strong> Basado en la conversación, elaboraré una estrategia inicial y un cronograma para tu viaje hacia una beca deportiva.
          </li>
        </ul>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Añadido Roboto como familia de fuentes
          fontSize: '17px', // Cambiado el tamaño de la fuente a 17px
          fontWeight: '400', // Asegúrate de que el peso de la fuente sea normal
        }}>
          Juntos, sentamos las bases para una coincidencia exitosa con una universidad estadounidense y establecemos el rumbo para los siguientes pasos en el proceso.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ ...boxStyle, position: 'relative' }} // Añade la posición relativa al estilo
        contentArrowStyle={arrowStyle}
        date="Paso 2"
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        icon={<ScheduleIcon />}
      >
        <div style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          opacity: 0.5 // Opacidad reducida para un efecto de marca de agua
        }}>
          <Filter2Icon style={{ color: 'grey', fontSize: '48px' }} />
        </div>
        <h3 className="vertical-timeline-element-title">Evaluación y planificación:</h3>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Añadido Roboto como familia de fuentes
          fontSize: '17px', // Cambiado el tamaño de la fuente a 17px
          fontWeight: '400', // Asegúrate de que el peso de la fuente sea normal
          marginBottom: '10px', // Ajusta el valor según sea necesario
        }}>
          Después de tu consulta inicial, me tomo el tiempo para una evaluación exhaustiva y la elaboración de un plan personalizado.
        </p>

        <ul style={{ 
          listStyleType: 'disc', 
          marginLeft: '20px', 
          fontSize: '17px', 
        }}>  
          <li>
            <strong>Análisis de potencial:</strong> Evalúo tu historial deportivo y académico para medir tus perspectivas en EE. UU.
          </li>
          <li>
            <strong>Plan de acción:</strong> Desarrollo un plan a medida, incluyendo un cronograma y hitos, para alcanzar tus objetivos.
          </li>
          <li>
            <strong>Consejo estratégico:</strong> Asesoramiento sobre cómo mejorar tu rendimiento deportivo y académico para aumentar tus posibilidades de obtener una beca.
          </li>
          <li>
            <strong>Universidades objetivo:</strong> Juntos, identificamos una lista de universidades objetivo que se alinean con tu perfil y ambiciones.
          </li>
        </ul>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Añadido Roboto como familia de fuentes
          fontSize: '17px', // Cambiado el tamaño de la fuente a 17px
          fontWeight: '400', // Asegúrate de que el peso de la fuente sea normal
        }}>
          Este plan detallado forma la base de tu viaje hacia una beca deportiva y asegura que estés preparado para cada paso subsiguiente.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ ...boxStyle, position: 'relative' }} // Añade la posición relativa al estilo
        contentArrowStyle={arrowStyle}
        date="Paso 3"
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        icon={<VideocamIcon />}
      >
        <div style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          opacity: 0.5 // Opacidad reducida para un efecto de marca de agua
        }}>
          <Filter3Icon style={{ color: 'grey', fontSize: '48px' }} />
        </div>
        <h3 className="vertical-timeline-element-title">Material de vídeo y portafolio deportivo:</h3>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Añadido Roboto como familia de fuentes
          fontSize: '17px', // Cambiado el tamaño de la fuente a 17px
          fontWeight: '400', // Asegúrate de que el peso de la fuente sea normal
          marginBottom: '10px', // Ajusta el valor según sea necesario
        }}>
          Crear un portafolio deportivo convincente y material de vídeo profesional es crucial para tu visibilidad ante los entrenadores.
        </p>

        <ul style={{ 
          listStyleType: 'disc', 
          marginLeft: '20px', 
          fontSize: '17px', 
        }}>  
          <li>
            <strong>Producción de vídeo:</strong> Asesoro sobre el contenido y superviso la producción de un vídeo destacado que muestre tus habilidades de manera óptima.
          </li>
          <li>
            <strong>Desarrollo de portafolio:</strong> Ayudo en la compilación de un portafolio deportivo completo, incluyendo estadísticas, logros y recomendaciones.
          </li>
          <li>
            <strong>Historia personal:</strong> Tu trayectoria y logros deportivos se tejen en una narrativa personal que te distingue de los demás.
          </li>
          <li>
            <strong>Presentación digital:</strong> Me aseguro de que tu portafolio y vídeo sean accesibles y compartibles digitalmente para entrenadores y equipos de scouting.
          </li>
        </ul>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Añadido Roboto como familia de fuentes
          fontSize: '17px', // Cambiado el tamaño de la fuente a 17px
          fontWeight: '400', // Asegúrate de que el peso de la fuente sea normal
        }}>
          Con este paso, nos aseguramos de que tu talento esté destacado de manera inequívoca y atraiga la atención de las universidades estadounidenses.
        </p>
      </VerticalTimelineElement>




      <VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Añade la posición relativa al estilo
  contentArrowStyle={arrowStyle}
  date="Paso 4"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<QuizIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Opacidad reducida para un efecto de marca de agua
  }}>
    <Filter4Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Preparación académica:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Añadido Roboto como familia de fuentes
    fontSize: '17px', // Cambiado el tamaño de la fuente a 17px
    fontWeight: '400', // Asegúrate de que el peso de la fuente sea normal
    marginBottom: '10px', // Ajusta el valor según sea necesario
  }}>
    Una buena preparación académica es esencial para la admisión y el éxito en EE. UU.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Preparación para exámenes:</strong> Proporciono materiales de estudio y orientación para los exámenes SAT/ACT y TOEFL/IELTS, esenciales para tu admisión.
    </li>
    <li>
      <strong>Planificación de estudios:</strong> Juntos, desarrollamos un plan de estudios para prepararte para los exámenes, sin descuidar tu entrenamiento deportivo.
    </li>
    <li>
      <strong>Documentos de solicitud:</strong> Ayudo en la preparación y revisión de tus ensayos de solicitud y otros documentos requeridos.
    </li>
    <li>
      <strong>Asesoramiento académico:</strong> Recibes asesoramiento sobre los requisitos académicos y cómo fortalecer tu perfil académico.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Añadido Roboto como familia de fuentes
    fontSize: '17px', // Cambiado el tamaño de la fuente a 17px
    fontWeight: '400', // Asegúrate de que el peso de la fuente sea normal
  }}>
    Mi objetivo es asegurar que estés académicamente preparado no solo para ser aceptado, sino para sobresalir en la universidad.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Añade la posición relativa al estilo
  contentArrowStyle={arrowStyle}
  date="Paso 5"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<SchoolIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Opacidad reducida para un efecto de marca de agua
  }}>
    <Filter5Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Promoción en universidades:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Añadido Roboto como familia de fuentes
    fontSize: '17px', // Cambiado el tamaño de la fuente a 17px
    fontWeight: '400', // Asegúrate de que el peso de la fuente sea normal
    marginBottom: '10px', // Ajusta el valor según sea necesario
  }}>
    La promoción de tu perfil en las universidades es un paso clave donde mi experiencia y extensa red entran en juego.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Rol clave de la red:</strong> Mi extensa red dentro de las universidades estadounidenses es invaluable para dar a conocer tu candidatura.
    </li>
    <li>
      <strong>Posicionamiento estratégico:</strong> Utilizo mi conocimiento y experiencia para posicionar estratégicamente tu perfil para destacar ante los programas deportivos y las instituciones académicas adecuadas.
    </li>
    <li>
      <strong>Plan de promoción adaptable:</strong> Creo un plan de promoción flexible y personalizado que destaque los aspectos únicos de tu perfil deportivo y académico.
    </li>
    <li>
      <strong>Maximización de oportunidades:</strong> A través de la promoción dirigida, me aseguro de que tus posibilidades de obtener una beca se maximicen, centrándome en el mejor ajuste para tus ambiciones personales y deportivas.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Añadido Roboto como familia de fuentes
    fontSize: '17px', // Cambiado el tamaño de la fuente a 17px
    fontWeight: '400', // Asegúrate de que el peso de la fuente sea normal
  }}>
    Mi valor añadido radica en resaltar efectivamente tu talento y aspiraciones, aumentando significativamente las posibilidades de éxito. Aquí es donde mi red, mi comprensión del sistema universitario estadounidense y mi enfoque personal marcan una diferencia decisiva.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Añade la posición relativa al estilo
  contentArrowStyle={arrowStyle}
  date="Paso 6"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<RecordVoiceOverIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Opacidad reducida para un efecto de marca de agua
  }}>
    <Filter6Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Comunicación con entrenadores:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    La comunicación efectiva con los entrenadores es esencial. Actúo como tu representante personal para hacer una fuerte primera impresión.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Presentaciones directas:</strong> Presentaciones personales a entrenadores en mi red, adaptadas a tu disciplina deportiva.
    </li>
    <li>
      <strong>Estrategia de comunicación:</strong> Desarrollo de una estrategia de comunicación efectiva para resaltar tus cualidades y ambiciones.
    </li>
    <li>
      <strong>Orientación en negociaciones:</strong> Orientación en la comunicación con entrenadores durante la fase de negociación de becas deportivas.
    </li>
    <li>
      <strong>Soporte continuo:</strong> Soporte y asesoramiento continuos en todas las comunicaciones para asegurarte de hacer una impresión positiva y duradera.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Mi papel es actuar como un puente entre tú y los entrenadores estadounidenses, y crear un canal de comunicación que maximice tus posibilidades de una beca deportiva exitosa.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Añade la posición relativa al estilo
  contentArrowStyle={arrowStyle}
  date="Paso 7"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<RuleIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Opacidad reducida para un efecto de marca de agua
  }}>
    <Filter7Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Selección y ofertas de becas:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    El proceso de selección es crucial; aquí es donde los frutos de nuestro trabajo conjunto se materializan en forma de ofertas de becas.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Evaluación de ofertas:</strong> Juntos revisamos las ofertas recibidas y discutimos los pros y contras de cada opción.
    </li>
    <li>
      <strong>Asesoramiento estratégico:</strong> Ofrezco asesoramiento estratégico para ayudarte a tomar la mejor decisión para tu futuro deportivo y académico.
    </li>
    <li>
      <strong>Apoyo en negociaciones:</strong> Mi experiencia en negociaciones se utiliza para lograr las condiciones más favorables para ti.
    </li>
    <li>
      <strong>Elección final:</strong> Con mi ayuda, tomas una elección final bien informada donde comienza tu carrera deportiva y educación en EE. UU.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Mi guía en esta fase está dirigida a asegurar el mejor resultado posible para tus ambiciones y garantizar tu futuro en una universidad de primera en América.
  </p>
  
</VerticalTimelineElement>

<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Añade la posición relativa al estilo
  contentArrowStyle={arrowStyle}
  date="Paso 8"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<DescriptionIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Opacidad reducida para un efecto de marca de agua
  }}>
    <Filter8Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Solicitud de visa y proceso de inscripción:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    Después de la selección, comienza una fase crítica: la finalización de tu solicitud de visa y los procedimientos de inscripción.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Solicitud de visa:</strong> Orientación en la solicitud de la visa de estudiante adecuada, incluyendo la recopilación de la documentación necesaria.
    </li>
    <li>
      <strong>Ayuda con la inscripción:</strong> Asistencia en completar todos los formularios y documentos de inscripción para tu universidad y competición elegidas.
    </li>
    <li>
      <strong>Planificación financiera:</strong> Asesoramiento sobre asuntos financieros como tasas de becas, matrículas y costos de vida en EE. UU.
    </li>
    <li>
      <strong>Revisión final:</strong> Una revisión final exhaustiva para asegurarse de que todos los aspectos administrativos y logísticos estén cubiertos.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Mi orientación en la preparación asegura que comiences tu aventura estadounidense con confianza y bien informado.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Añade la posición relativa al estilo
  contentArrowStyle={arrowStyle}
  date="Paso 9"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<ChecklistIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Opacidad reducida para un efecto de marca de agua
  }}>
    <Filter8Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Preparativos para la partida:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    Una transición sin problemas a EE. UU. requiere una preparación meticulosa; los preparativos finales se están realizando ahora.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Lista de comprobación:</strong> Una lista de comprobación completa para asegurar que no se olvide nada, desde documentos esenciales hasta necesidades personales.
    </li>
    <li>
      <strong>Alojamiento:</strong> Asistencia en la búsqueda de vivienda adecuada cerca de tu universidad y con las comodidades adecuadas.
    </li>
    <li>
      <strong>Seguro:</strong> Asesoramiento sobre los seguros adecuados para salud, viaje y estancia.
    </li>
    <li>
      <strong>Breve sesión informativa:</strong> Una sesión informativa sobre diferencias culturales, la vida en EE. UU. y consejos prácticos para la vida cotidiana.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Mi orientación en la preparación asegura que comiences tu aventura estadounidense con confianza y bien informado.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Añade la posición relativa al estilo
  contentArrowStyle={arrowStyle}
  date="Paso 10"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<FlightTakeoffIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Opacidad reducida para un efecto de marca de agua
  }}>
    <Filter9Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Partida y llegada a EE. UU.:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    Ha llegado el último paso de tu preparación: el viaje a América.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Preparativos para la partida:</strong> Me aseguro de que estés completamente preparado para tu partida, con una lista de comprobación detallada y consejos finales.
    </li>
    <li>
      <strong>Momento de despedida:</strong> Un momento para reflexionar sobre este importante hito y despedirse antes de perseguir tu sueño.
    </li>
    <li>
      <strong>Llegada a EE. UU.:</strong> Tras la llegada, ofrezco apoyo para ayudarte a instalarte y orientarte en tu nuevo entorno.
    </li>
    <li>
      <strong>Los primeros días:</strong> Los primeros días son emocionantes, y me aseguro de que recibas el apoyo necesario para empezar bien.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Estoy a tu lado en cada paso, desde la salida de España hasta tu llegada al campus en EE. UU., listo para comenzar tus estudios y carrera deportiva.
  </p>
  
</VerticalTimelineElement>

<VerticalTimelineElement
  iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
  icon={<SportsScoreIcon />}
/>
</VerticalTimeline>

  );
}

export default Cronología;
