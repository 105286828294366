/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Basketbal1.jpeg";
import Image2 from "assets/images/Sports_Images/Basketbal2.webp";
import Image3 from "assets/images/Sports_Images/Basketbal3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import { Link } from 'react-router-dom';

import "../../../../H1asH3.css"

// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'USA Basketstipendium: Hur du Studerar och Spelar Basket i Amerika', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapitel 1: Historia och Utveckling av Collegbasket', isSubheader: false },
    { id: '1.1', title: '1.1 Tidiga År', isSubheader: true },
    { id: '1.2', title: '1.2 Tillväxt och Professionalisering', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapitel 2: Collegbasket idag', isSubheader: false },
    { id: '2.1', title: '2.1 Viktiga Tävlingar och Konferenser', isSubheader: true },
    { id: '2.2', title: '2.2 Topprogram och Prestigefyllda Lag', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapitel 3: Från College till NBA', isSubheader: false },
    { id: '3.1', title: '3.1 Vägen till NBA', isSubheader: true },
    { id: '3.2', title: '3.2 Andra Professionella Möjligheter', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapitel 4: Internationella Influenser och Möjligheter', isSubheader: false },
    { id: '4.1', title: '4.1 Utländska Spelare i Collegbasket', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanska Spelare Utomlands', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapitel 5: Idrottsstipendier och Framtiden för Idrottare', isSubheader: false },
    { id: '5.1', title: '5.1 Vikten av Stipendier', isSubheader: true },
    { id: '5.2', title: '5.2 Hur Sport Scholarships America Kan Hjälpa', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };





  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innehåll
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Dölj</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Visa</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>






















          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    USA Basketstipendium: Hur du Studerar och Spelar Basket i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Livligt stadion på University of Arizona under en collegbasketmatch"
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Matchdag på University of Arizona Stadium
      </figcaption>
    </figure>
    Basket är en av de mest populära sporterna i USA. Miljoner människor följer matcherna i NBA, den professionella basketligan, och NCAA, den collegbasketliga. Basket är inte bara en spännande sport att titta på utan också ett bra sätt att utvecklas som spelare och person.
    <br/>
    <br/>
    Om du är en ung basketspelare som drömmer om en karriär i NBA, eller helt enkelt vill njuta av att spela basket på en hög nivå, kan ett basketstipendium i Amerika vara för dig. Ett basketstipendium är ekonomiskt stöd du får från ett amerikanskt universitet för att studera och spela basket där. Med ett basketstipendium kan du inte bara få din akademiska examen utan också förbättra dina basketfärdigheter under professionell vägledning.
    <br/>
    <br/>
    Men hur får man ett basketstipendium i Amerika? Vilka är fördelarna och utmaningarna med att spela collegbasket? Och vilka är möjligheterna efter din collegkarriär? I den här artikeln kommer vi att besvara alla dessa frågor och berätta allt du behöver veta om basketstipendier i Amerika. Vi kommer också att diskutera Sportbeursamerika.nl:s roll, en organisation specialiserad på att hjälpa unga spelare att förverkliga sin dröm om ett basketstipendium.
    <br/>
    <br/>
    Vill du veta mer om basketstipendier i Amerika? Läs vidare!
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Historia och Utveckling av Collegbasket
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Basket är en relativt ung sport, uppfunnen år 1891 av James Naismith, en kanadensisk gymnasielärare. Han tänkte ut spelet som en inomhusaktivitet för sina elever under vintermånaderna. Spelet blev snabbt populärt och spred sig över olika skolor och universitet i Nordamerika.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidiga År
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Den första officiella collegenivåbasketmatchen ägde rum år 1895 mellan University of Chicago och University of Iowa. År 1901 etablerades den första intercollegiatekonferensen, Western Conference, senare känt som Big Ten Conference. År 1906 organiserades den första nationella mästerskapet av Amateur Athletic Union (AAU), som dominerade collegbasketen fram till 1936.
      <br/>
      <br/>
      År 1939 hölls den första NCAA-turneringen, med åtta lag som tävlade om titeln. Turneringen vanns av Oregon, som besegrade Ohio State med 46-33. Turneringen växte till en årlig händelse som alltmer lockade uppmärksamheten från fans och media.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Tillväxt och Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Efter andra världskriget ökade populäriteten och kvaliteten på collegbasketen. Nya stjärnor framträdde, som Bill Russell, Wilt Chamberlain, Oscar Robertson och Kareem Abdul-Jabbar, som senare också gjorde sig ett namn i NBA. Nya tävlingar och konferenser bildades, som National Invitation Tournament (NIT), Atlantic Coast Conference (ACC) och Southeastern Conference (SEC).
      <br/>
      <br/>
      År 1957 ägde en av de mest minnesvärda matcherna i collegbasketens historia rum när North Carolina Tar Heels besegrade Kansas Jayhawks med 54-53 efter tre förlängningar. Matchen betraktas som en vändpunkt i collegbasketen och visar hur spännande och dramatiskt spelet kan vara.
      <br/>
      <br/>
      En annan historisk match ägde rum år 1966 när Texas Western Miners besegrade Kentucky Wildcats med 72-65. Matchen var banbrytande eftersom Texas Western var det första laget som bestod helt av svarta spelare, medan Kentucky var helt vitt. Matchen ses som en symbol för medborgerliga rättighetskampen och integrationen i Amerika.
      <br/>
      <br/>
      År 1979 ägde den första direktsända NCAA-turneringen rum, med ett rekordantal tittare. Finalen stod mellan Michigan State Spartans och Indiana State Sycamores, ledda av framtida NBA-legender Magic Johnson och Larry Bird, respektive. Michigan State vann matchen med 75-64 och betraktas som en av de bästa finalerna någonsin.
      <br/>
      <br/>
      På 80- och 90-talet fortsatte collegbasketen att växa och utvecklas, med nya regler, stilar och strategier. Nya rivaliteter uppstod, som Duke vs North Carolina, Georgetown vs Syracuse och UCLA vs Arizona. Det fanns också nya stjärnor, som Michael Jordan, Patrick Ewing, Shaquille O’Neal och Tim Duncan, som lyfte collegbasketen till en högre nivå.
    </MKTypography>
  </MKBox>
</MKBox>











                                              





                                  






<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Collegbasket idag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Collegbasket är idag en av de mest populära och prestigefyllda sporterna i Amerika. Varje år lockar matcherna miljontals fans till arenor och tv-skärmar. Collegbasket är också en stor källa till talang för NBA, där hundratals spelare rekryteras varje år från universitetslag.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Viktiga Tävlingar och Konferenser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Steph Curry i aktion för Davidson College, utmärkande sig i collegbasket."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Stephen Curry i aktion som spelare för Davidson College
        </figcaption>
      </figure>
      Collegbasket organiseras främst av National Collegiate Athletic Association (NCAA), som övervakar över 1 000 universitet och högskolor. NCAA delar in lag i tre divisioner, där Division I är den högsta. Division I består av 32 konferenser, var och en bestående av lag som är geografiskt eller historiskt anslutna.
      <br/>
      <br/>
      Konferenserna är viktiga eftersom de avgör vilka lag som spelar mot varandra under grundserien, som löper från november till mars. Varje konferens har sin egen spelschema, regler och mästerskap. Konferensmästare förtjänar automatiskt en plats i NCAA-turneringen, höjdpunkten i collegbasket.
      <br/>
      <br/>
      NCAA-turneringen, även känd som March Madness, är en utslagningsturnering som hålls varje år i mars och april. Den består av 68 lag, varav 32 är konferensmästare och 36 är at-large-bjudningar, valda av en kommitté baserat på deras prestation under säsongen. Lagen delas in i fyra regioner och rankas från 1 till 16.
      <br/>
      <br/>
      Turneringen börjar med fyra play-in-matcher, där de lägst rankade lagen tävlar om de sista fyra platserna i huvudturneringen. Huvudturneringen består av sex omgångar, eliminera ett lag i varje omgång tills ett återstår. De fyra sista lagen, kända som Final Four, spelar på en neutral plats för att avgöra den nationella mästaren.
      <br/>
      <br/>
      NCAA-turneringen är en av de mest spännande och oberäkneliga sportevenemangen i världen. Varje år finns det överraskningar, dramer och sensationer, kända som upptäckter, buzzer-beaters och Askungesagor. Turneringen lockar miljontals tittare och spelare, som försöker förutsäga vinnaren.
      <br/>
      <br/>
      Förutom NCAA-turneringen finns det andra betydande tävlingar i collegbasket, såsom NIT, CBI, CIT och NAIA-turneringen. Dessa tävlingar erbjuder möjligheter för lag som inte kvalificerar sig för NCAA-turneringen eller vill få ytterligare erfarenhet.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Topprogram och Prestigefyllda Lag
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Collegbasket har många topprogram och prestigefyllda lag, som utmärks av sin historia, tradition, framgång och inflytande. Några av dessa program är:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          UCLA Bruins: Det mest framgångsrika programmet i collegbasketens historia, med 11 nationella titlar, inklusive 10 under tränaren John Wooden på 60- och 70-talet. UCLA har producerat många kända spelare, som Kareem Abdul-Jabbar, Bill Walton och Reggie Miller.
        </li>
        <li>
          Kentucky Wildcats: Det näst mest framgångsrika programmet i collegbasketens historia, med åtta nationella titlar och 17 Final Four-uppträdanden. Kentucky är känt för sina hängivna fans och sin förmåga att locka topprekryter.
        </li>
        <li>
          North Carolina Tar Heels: Det tredje mest framgångsrika programmet i collegbasketens historia, med sex nationella titlar och 20 Final Four-uppträdanden. North Carolina har haft många legendariska tränare, som Dean Smith och Roy Williams, och spelare som Michael Jordan, James Worthy och Vince Carter.
        </li>
        <li>
          Duke Blue Devils: Ett av de mest dominerande programmen i modern collegbasket, med fem nationella titlar och 16 Final Four-uppträdanden sedan 1986. Duke är känt för sin tränare Mike Krzyzewski, den mest vinnande tränaren i collegbasketens historia, och spelare som Christian Laettner, Grant Hill och Zion Williamson.
        </li>
        <li>
          Kansas Jayhawks: Ett av de mest traditionella och inflytelserika programmen i collegbasket, med tre nationella titlar och 15 Final Four-uppträdanden. Kansas är basketens födelseplats, eftersom James Naismith var dess första tränare. Kansas har också haft många stora tränare, som Phog Allen, Larry Brown och Bill Self, och spelare som Wilt Chamberlain, Danny Manning och Paul Pierce.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dessa program är bara några exempel på de många utmärkta lagen som berikar collegbasketen. Varje år gör nya utmanare och överraskningar spelet spännande och oberäkneligt.
    </MKTypography>
  </MKBox>
</MKBox>










                                              






                                  






<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Från College till NBA
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Collegbasket är inte bara en sport utan också en utbildning. Många spelare som deltar i collegbasket siktar på att nå NBA, den bästa och mest prestigefyllda professionella basketligan i världen. Men NBA är inte lätt att nå och kräver mycket talang, hårt arbete och tur.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Vägen till NBA
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      För att vara berättigad till NBA måste en spelare vara minst 19 år gammal och ha tagit examen från gymnasiet minst ett år tidigare. Detta innebär att de flesta spelare som strävar efter att nå NBA först går på college och spelar i minst ett år. Detta är också känt som en-och-gjort-regeln eftersom många spelare går in i NBA-draften efter bara ett år på college.
      <br />
      <br />
      NBA-draften är en årlig händelse där de 30 NBA-lagen turas om att välja en spelare från en pool av tillgängliga spelare. Draften består av två omgångar, där varje lag har en pick per omgång. Ordningen för valen bestäms genom en lotteri för lag som inte kvalificerade sig för slutspelet föregående säsong och genom omvänd ranking för slutspelet lag.
      <br />
      <br />
      Draften är en betydande stund för spelare eftersom den avgör vilket lag som håller deras rättigheter och hur mycket pengar de kan tjäna. Spelare som väljs i första rundan får ett garanterat kontrakt i minst två år, medan de som väljs i andra rundan inte har några garantier. Spelare som inte väljs kan försöka säkra ett kontrakt genom free agency eller genom att gå med i G League, NBA:s utvecklingsliga.
      <br />
      <br />
      Dock är draften ingen garanti för framgång i NBA. Många högt rankade spelare uppfyller inte förväntningarna eller drabbas av skador. Å andra sidan finns det också många spelare som draftas lågt eller inte alls men ändå utmärker sig i NBA. Ett välkänt exempel är Giannis Antetokounmpo, som valdes som nummer 15 totalt 2013 och utnämndes till NBA:s MVP och mästare 2021.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Andra Professionella Möjligheter
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Inte alla spelare som deltar i collegbasket siktar på att gå till NBA. Vissa spelare väljer att slutföra sina akademiska examina och förfölja andra karriärer. Andra väljer att spela professionell basket i andra länder eller ligor.
      <br />
      <br />
      Det finns många professionella basketligor runt om i världen som erbjuder olika nivåer av kvalitet, lön och livsstil. Några av dessa ligor inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          EuroLeague: Den högsta och mest prestigefyllda europeiska klubbkonkurrensen, med 18 lag från 10 länder. EuroLeague anses vara den näst bästa ligan i världen efter NBA.
        </li>
        <li>
          Chinese Basketball Association (CBA): Den högsta och mest populära kinesiska klubbkonkurrensen, med 20 lag från hela Kina. CBA är känt för sina höga löner och stora publiker.
        </li>
        <li>
          Australian National Basketball League (NBL): Den högsta och mest konkurrenskraftiga australiska klubbkonkurrensen, med nio lag från Australien och Nya Zeeland. NBL är känt för sitt snabba och fysiska spel.
        </li>
        <li>
          Basketball Africa League (BAL): Den nyaste och mest ambitiösa afrikanska klubbkonkurrensen, med 12 lag från 12 länder. BAL stöds av NBA och FIBA och syftar till att utveckla basket på kontinenten.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dessa ligor är bara några exempel på de många möjligheter som finns för professionella basketspelare utanför NBA. Många spelare trivs med att bo och spela i olika kulturer samtidigt som de fortsätter att följa sin passion för spelet.
    </MKTypography>
  </MKBox>
</MKBox>











                                              






                                  






<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationella Influenser och Möjligheter
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Basket är en global sport som förenar människor från olika nationaliteter, kulturer och bakgrunder. Collegbasket är ett utmärkt exempel på detta, eftersom det lockar både utländska och amerikanska spelare och erbjuder möjligheter. I det här kapitlet kommer vi att utforska de internationella influenserna och möjligheterna i collegbasket.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Utländska Spelare i Collegbasket
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Collegbasket har alltid välkomnat utländska spelare som har bidragit med sin egen stil, färdigheter och perspektiv till spelet. Enligt NCAA fanns det under säsongen 2019-2020 över 1 200 utländska spelare i Division I, från 101 länder. De vanligaste ursprungsländerna var Kanada, Australien, Nigeria, Frankrike och Storbritannien.
      <br />
      <br />
      Utländska spelare väljer collegbasket av olika skäl. Vissa vill kombinera sin akademiska utbildning med sina idrottsliga ambitioner. Andra syftar till att anpassa sig till amerikansk kultur och livsstil. Åter andra strävar efter att öka sina chanser att nå NBA eller representera sina nationella lag.
      <br />
      <br />
      Utländska spelare har haft en betydande påverkan på collegbasket, både individuellt och kollektivt. Några av de mest framgångsrika och inflytelserika utländska spelarna i collegbasketens historia inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Hakeem Olajuwon: En nigeriansk spelare som spelade för Houston Cougars från 1981 till 1984. Han ledde sitt lag till tre på varandra följande Final Four-framträdanden och vann priset för årets nationella spelare 1984. Han var förstavalet totalt i NBA-draften 1984 och blev en av de största centerna genom tiderna.
        </li>
        <li>
          Andrew Bogut: En australisk spelare som spelade för Utah Utes från 2003 till 2005. Han vann priset för årets nationella spelare 2005 och ledde sitt lag till Sweet Sixteen. Han var förstavalet totalt i NBA-draften 2005 och blev en All-Star och mästare.
        </li>
        <li>
          Luka Garza: En amerikansk spelare med bosniskt ursprung som spelade för Iowa Hawkeyes från 2017 till 2021. Han vann priset för årets nationella spelare två gånger, 2020 och 2021, och blev sin universitets bästa målskytt genom tiderna. Han valdes som nummer 52 totalt i NBA-draften 2021 och anses vara en av de bästa collegespelarna någonsin.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dessa spelare är bara några exempel på de många utländska spelare som har utmärkt sig i collegbasket. Varje år dyker nya talanger upp och berikar spelet.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikanska Spelare Utomlands
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Inte bara utländska spelare kommer till Amerika för att studera och spela basket, utan amerikanska spelare går också utomlands av samma anledningar. Vissa amerikanska spelare väljer att hoppa över eller förkorta sina gymnasie- eller collegekarriärer för att spela professionellt i andra länder eller ligor.
      <br />
      <br />
      Orsakerna till detta val kan variera, men några inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Ekonomiskt: Vissa spelare vill tjäna pengar med sin talang istället för att vänta på ett NBA-kontrakt eller ett stipendium.
        </li>
        <li>
          Idrottsligt: Vissa spelare vill utvecklas mot starkare motståndare istället för att dominera på sin nivå.
        </li>
        <li>
          Personligt: Vissa spelare vill resa, lära sig nya kulturer eller behålla familjeband.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Amerikanska spelare som går utomlands har olika upplevelser och resultat. Vissa lyckas förverkliga sin NBA-dröm, medan andra njuter av en lång och framgångsrik karriär. Vissa anpassar sig väl till sin nya miljö, medan andra har problem med språk, mat eller livsstil.
      <br />
      <br />
      Några exempel på amerikanska spelare som har gått utomlands inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Brandon Jennings: En spelare som hoppade över sin gymnasieutbildning för att spela för Lottomatica Roma i Italien 2008. Han var det tionde valet totalt i NBA-draften 2009 och spelade i 10 säsonger i NBA.
        </li>
        <li>
          Emmanuel Mudiay: En spelare som hoppade över sitt college för att spela för Guangdong Southern Tigers i Kina 2014. Han var det sjunde valet totalt i NBA-draften 2015 och spelar för närvarande för Utah Jazz.
        </li>
        <li>
          LaMelo Ball: En spelare som hoppade över sin gymnasie- och collegeutbildning för att spela för Illawarra Hawks i Australien 2019. Han var det tredje valet totalt i NBA-draften 2020 och vann Rookie of the Year-priset.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dessa spelare är bara några exempel på de många amerikanska spelare som har valt en alternativ väg till professionell basket. Varje år globaliseras spelet med nya äventyrare.
    </MKTypography>
  </MKBox>
</MKBox>






                                              






                                  






<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Idrottsstipendier och Framtiden för Idrottare
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrottsstipendier erbjuder många möjligheter för unga idrottare att visa upp sin talang och utveckla sina färdigheter. En av de mest betydelsefulla möjligheterna är att få ett idrottsstipendium för att studera och tävla på collegenivå. I det här kapitlet kommer vi att diskutera vikten av idrottsstipendier och hur Sport Scholarships America kan hjälpa dig att förverkliga din dröm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vikten av Stipendier
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ett idrottsstipendium är ekonomiskt stöd som tillhandahålls av ett universitet till en idrottare för att kombinera studier och idrott. Det kan täcka kostnaderna för undervisning, böcker, boende, måltider och andra utgifter. Dessutom kan det erbjuda fördelar som akademisk handledning, sjukvård, träningsanläggningar och utrustning.
      <br />
      <br />
      Att få ett idrottsstipendium är konkurrenskraftigt, med många faktorer som spelar en roll i tilldelningsprocessen, inklusive akademiska prestationer, idrottsprestationer, personlighet, motivation, ledarskap och anpassningsförmåga. Därför är det viktigt att förbereda sig väl och skilja sig från konkurrensen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hur Sport Scholarships America Kan Hjälpa
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotyp för Sport Scholarships America, med sportutrustning och en studentmössa, som representerar fokus på idrottsstipendier för idrottare."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotyp för Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America är en organisation specialiserad på att vägleda unga idrottstalanger till idrottsstipendier. Med passionerade och erfarna experter stöder Sport Scholarships America idrottare i varje steg av resan, från initial orientering till slutlig placering. Deras tjänster inkluderar:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intagningsintervju för att diskutera idrottarens önskemål, möjligheter och förväntningar.</li>
        <li>En professionell utvärdering av idrottarens akademiska och idrottsliga nivå.</li>
        <li>Ett omfattande nätverk av kontakter med tränare, universitet och högskolor.</li>
        <li>Effektiv marknadsföring av idrottarens profil och videor till potentiella tränare.</li>
        <li>Expertrådgivning om de bästa alternativen baserat på idrottarens preferenser, mål och budget.</li>
        <li>Förhandling om de bästa stipendieerbjudandena för idrottaren.</li>
        <li>Hjälp med att ansöka om nödvändiga dokument, såsom visum, betyg, examensbevis och antagningsprov.</li>
        <li>Förberedelse för idrottarens liv under stipendieperioden, inklusive kultur, språk, regler och tips.</li>
        <li>Uppföljning och uppföljning under stipendieperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vill du utforska möjligheter till stipendier och uppnå dina idrottsliga och akademiska mål? <Link to="/sv/Tjänster-Produkter">Se det fullständiga utbudet</Link> av tjänster som erbjuds av Sport Scholarships America och boka en konsultation idag.
    </MKTypography>
  </MKBox>
</MKBox>

          










        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;