/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Pagina del prodotto: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Codificato da www.creative-tim.com

 =========================================================

* La presente nota di copyright e questo avviso di permesso devono essere inclusi in tutte le copie o porzioni sostanziali del Software.
*/

// @mui componenti materiali

import React from "react";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Componenti Material Kit 2 PRO React
import MKBox from "components/MKBox";

// Esempi di Material Kit 2 PRO React
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

function StatsOne() {
  return (
    <MKBox component="section" py={8}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard 
            count={100} 
            suffix="+"
            title="Studenti:"
            description="Guidati con successo verso le loro università dei sogni"  
            onClick={() => console.log('Cliccato su 100')}
            />
          </Grid>
          <Grid item xs={12} md={4} >
            <DefaultCounterCard
              count={70}
              title="Università:"
              suffix="+"
              description="Studenti collocati con successo in tutta l'America"
              onClick={() => console.log('Cliccato su 70')}

            />
          </Grid>
          <Grid item xs={12} md={4}>
          <Link to="/it/Tutti-gli-sport">
              <DefaultCounterCard
                count={10}
                suffix="+"
                title="Sport:"
                description="Guida i talenti in varie discipline"
              />
            </Link>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default StatsOne;
