/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 PRO React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';

// Pages
import Faq from "Languages/Norsk/pages/Contact/FAQs";
import Author from "Languages/Norsk/pages/Over/Overmij/Profiel";
import VirtualRealityPage from "Languages/Norsk/pages/Content/Podcast";
import AllSports from "Languages/Norsk/pages/All Sports/Overzicht";
import Contactformulier from "Languages/Norsk/pages/Contact/Contactformulier"
import Jeugd from "Languages/Norsk/pages/Over/Overmij/Jeugd";
import Amerika from "Languages/Norsk/pages/Over/Overmij/Amerika";
import NaUSA from "Languages/Norsk/pages/Over/Overmij/NaUSA";
import DienstenProducten from "Languages/Norsk/pages/Aanbod/Diensten_producten";

import Timeline from "Languages/Norsk/pages/Aanbod/Traject";
import AmericanFootball from "Languages/Norsk/pages/All Sports/Sports/AmericanFootball";
import Athletics from "Languages/Norsk/pages/All Sports/Sports/Track and Field";
import Basketball from "Languages/Norsk/pages/All Sports/Sports/Basketball";
import Golf from "Languages/Norsk/pages/All Sports/Sports/Golf";
import Hockey from "Languages/Norsk/pages/All Sports/Sports/Field Hockey";
import Baseball from "Languages/Norsk/pages/All Sports/Sports/Baseball";
import Icehockey from "Languages/Norsk/pages/All Sports/Sports/Ice Hockey";
import Rowing from "Languages/Norsk/pages/All Sports/Sports/Rowing";
import Softbal from "Languages/Norsk/pages/All Sports/Sports/Softbal";
import Tennis from "Languages/Norsk/pages/All Sports/Sports/Tennis";
import Gymnastics from "Languages/Norsk/pages/All Sports/Sports/Gymnastics";
import Soccer from "Languages/Norsk/pages/All Sports/Sports/Soccer";
import Volleyball from "Languages/Norsk/pages/All Sports/Sports/Volleyball";
import Waterpolo from "Languages/Norsk/pages/All Sports/Sports/Waterpolo";
import Wrestling from "Languages/Norsk/pages/All Sports/Sports/Wrestling";
import Swimming from "Languages/Norsk/pages/All Sports/Sports/Swimming";
import CompanyTimeline from "Languages/Norsk/pages/Over/Over Sport Scholarships America";
import Kenniscentrum from "Languages/Norsk/pages/Content/Kenniscentrum";





const routes = [
  {
    name: "Idretter",
    icon: <SportsSoccerIcon></SportsSoccerIcon>,
    collapse: [
      {
        name: "All Idretter",
        description: "Oppdag Alle Idretter",
        route: "/no/Alle-idretter",
        component: <AllSports />,
        dropdown: true,
        collapse: [
          {
            name: "Amerikansk Fotball",
            route: "/no/Alle-idretter/Amerikansk-Fotball",
            component: <AmericanFootball />,
          },
      
          {
            name: "Baseball",
            route: "/no/Alle-idretter/Baseball",
            component: <Baseball />,
          },
          {
            name: "Basketball",
            route: "/no/Alle-idretter/Basketball",
            component: <Basketball />,
          },
          {
            name: "Bryting",
            route: "/no/Alle-idretter/Bryting",
            component: <Wrestling />,
          },
          {
            name: "Field Hockey",
            route: "/no/Alle-idretter/Field-Hockey",
            component: <Hockey />,
          },
          {
            name: "Fotball",
            route: "/no/Alle-idretter/Fotball",
            component: <Soccer />,
          },
          {
            name: "Friidrett",
            route: "/no/Alle-idretter/Friidrett",
            component: <Athletics />,
          },
          {
            name: "Golf",
            route: "/no/Alle-idretter/Golf",
            component: <Golf />,
          },
          {
            name: "Gymnastikk",
            route: "/no/Alle-idretter/Gymnastikk",
            component: <Gymnastics />,
          },
          {
            name: "Ishockey",
            route: "/no/Alle-idretter/Ishockey",
            component: <Icehockey />,
          },
          {
            name: "Roing",
            route: "/no/Alle-idretter/Roing",
            component: <Rowing />,
          },
          {
            name: "Softball",
            route: "/no/Alle-idretter/Softball",
            component: <Softbal />,
          },
          {
            name: "Svømming",
            route: "/no/Alle-idretter/Svømming",
            component: <Swimming />,
          },
          {
            name: "Vannpolo",
            route: "/no/Alle-idretter/Vannpolo",
            component: <Waterpolo />,
          },
          {
            name: "Tennis",
            route: "/no/Alle-idretter/Tennis",
            component: <Tennis />,
          },
          {
            name: "Volleyball",
            route: "/no/Alle-idretter/Volleyball",
            component: <Volleyball />,
          },
        
        ],
      },
   

],
  },     

    {
      name: "Om",
      icon: <Icon>person</Icon>,
    
      collapse: [
        {
          name: "Om Meg",
          description: "En introduksjon",
          route: "/no/Om-meg",
          component: <Author />,
        
          dropdown: true,
          collapse: [
            {
              name: "Del 1: Å Vokse Opp i Nederland",
              route: "/no/Om-meg/Vokse-opp",
              component: <Jeugd />,
            },

            {
              name: "Del 2: Min Tid i Amerika",
              route: "/no/Om-meg/Min-tid-i-Amerika",
              component: <Amerika />,
            },

            {
              name: "Del 3: Min Tilbakevending Til Nederland",
              route: "/no/Om-meg/Min-tilbakevending-til-Nederland",
              component: <NaUSA />,
            },
             ],
         },
         {
          name: "Om Sport Scholarships America",
          description: "Selskapets historie",
          route: "/no/Om-Sport-Scholarships-America",
          component: <CompanyTimeline />,
        
          dropdown: false,
          
         },
    ],
},    
 
  {
    name: "Tilbud",
    icon: <Icon>monetization_on</Icon>,
    collapse: [
      {
        name: "Tjenester & Produkter",
        description: "Oppdag alle tjenester og produkter",
        route: "/no/Tjenester-Produkter",
        component: <DienstenProducten />,
        dropdown: false,
      
      },
      {
        name: "Prosess",
        description: "Prosessoversikt",
        route: "/no/Prosess",
        component: <Timeline />,
        dropdown: false,
       
      },
      
    ],
  },
  {
    name: "Innhold",
    icon: <Icon>article</Icon>,
    collapse: [
      {
        name: "Podcast",
        route: "/no/Podcast",
        description: "Lytt til min podcast",
        component: <VirtualRealityPage />
      },
      {
        name: "Kunnskapssenter",
        route: "/no/Kunnskapssenter",
        description: "Nyttige artikler og lenker",
        component: <Kenniscentrum />
      },
     
    ],
  },
  {
    name: "Kontakt",
    icon: <Icon>mail</Icon>,
    collapse: [
      {
        name: "FAQ",
        description:"Svar på Ofte Stilte Spørsmål",
        route: "/no/FAQs",
        component: <Faq />,
      },
      {
        name: "Kontaktskjema",
        route: "/no/Kontaktskjema",
        component: <Contactformulier />,
        description: "Ta kontakt",
      },
  
     
    ],
  },
];

export default routes;
