/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Baseball1.jpg";
import Image2 from "assets/images/Sports_Images/Baseball2.jpeg";
import Image3 from "assets/images/Sports_Images/Baseball3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';

import "../../../../H1asH3.css"

const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Beisebol e Estudar na América: Um Sonho que Pode se Tornar Realidade', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capítulo 1: A História e Desenvolvimento do Beisebol Universitário', isSubheader: false },
    { id: '1.1', title: '1.1 Primeiros Anos e o Papel das Universidades', isSubheader: true },
    { id: '1.2', title: '1.2 Crescimento e Profissionalização', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capítulo 2: O Beisebol Universitário Hoje', isSubheader: false },
    { id: '2.1', title: '2.1 Principais Ligas e Conferências', isSubheader: true },
    { id: '2.2', title: '2.2 Principais Programas e Times Prestigiados', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capítulo 3: Do Universitário para os Profissionais', isSubheader: false },
    { id: '3.1', title: '3.1 O Caminho para o Profissionalismo', isSubheader: true },
    { id: '3.2', title: '3.2 O Draft da MLB e Outras Oportunidades Profissionais', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capítulo 4: Influências Internacionais', isSubheader: false },
    { id: '4.1', title: '4.1 Jogadores Estrangeiros no Beisebol Universitário', isSubheader: true },
    { id: '4.2', title: '4.2 Americanos no Exterior', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 A Importância das Bolsas de Estudo', isSubheader: true },
    { id: '5.2', title: '5.2 Como a Sport Scholarships America Pode Ajudar', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Conteúdo
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Esconder</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Beisebol e Estudar na América: Um Sonho que Pode se Tornar Realidade
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Visão aérea do estádio durante a College World Series."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        A College World Series
      </figcaption>
    </figure>
    O beisebol é um dos esportes mais populares e significativos nos Estados Unidos. Milhões de pessoas acompanham a Major League Baseball (MLB), a liga profissional de beisebol mais alta do mundo. Mas antes de chegar à MLB, você deve primeiro desenvolver e provar suas habilidades em outro nível: o beisebol universitário.
    <br />
    <br />
    O beisebol universitário é o beisebol jogado por estudantes em universidades e faculdades americanas. É um esporte altamente competitivo e prestigiado que atrai muita atenção e talento. O beisebol universitário serve como um passo crucial para jogadores que desejam jogar profissionalmente e também como uma oportunidade de receber uma boa educação.
    <br />
    <br />
    Mas como entrar no beisebol universitário? E como conseguir uma bolsa de estudos para estudar e jogar nos EUA? É aí que o Sportbeursamerika.nl pode ajudar você. O Sportbeursamerika.nl é uma organização especializada em orientar e apoiar estudantes-atletas que desejam realizar seus sonhos. Posso ajudá-lo com tudo o que você precisa para encontrar uma universidade adequada, obter uma bolsa de estudos e se preparar para a vida nos EUA.
    <br />
    <br />
    Neste artigo, contaremos tudo o que você precisa saber sobre o beisebol universitário nos EUA. Abordaremos a história e o desenvolvimento deste esporte, as ligas e conferências-chave, os principais programas e equipes prestigiadas, o caminho para os profissionais, influências internacionais e, é claro, a importância das bolsas de estudo. Depois de ler este artigo, você terá uma melhor compreensão do que o beisebol universitário envolve e como pode aumentar suas chances de fazer parte dele.
    <br />
    <br />
    Está pronto para realizar seu sonho? Vamos começar!
  </MKTypography>
</MKBox>

<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 1: A História e Desenvolvimento do Beisebol Universitário
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O beisebol é um esporte que existe há mais de um século. Mas como ele surgiu e cresceu para se tornar um dos esportes mais populares e significativos nos EUA? Neste capítulo, forneceremos uma visão geral da história e desenvolvimento do beisebol universitário.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Primeiros Anos e o Papel das Universidades
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A origem do beisebol não é totalmente clara. Existem várias teorias sobre como e onde o jogo começou. Alguns dizem que descende de jogos europeus antigos como o críquete, rounders ou stoolball. Outros afirmam que originou-se de um jogo nativo americano chamado lacrosse. Ainda outros sugerem que foi inventado por um soldado americano chamado Abner Doubleday em 1839.
      <br />
      <br />
      O que é certo é que o beisebol rapidamente se tornou popular nos EUA, especialmente nas cidades da Costa Leste. Era jogado por amadores, clubes, trabalhadores de fábricas e soldados. Também era jogado por estudantes em universidades e faculdades, que tinham seus próprios times e regras.
      <br />
      <br />
      O primeiro jogo de beisebol intercolegial conhecido nos Estados Unidos ocorreu em 1859 entre Amherst College e Williams College, duas respeitadas instituições acadêmicas em Massachusetts. Este jogo é historicamente significativo como um dos primeiros exemplos de jogos de beisebol universitários, precursor do posterior desenvolvimento do beisebol universitário. Embora o resultado exato e o curso do jogo possam variar em fontes históricas, este evento marca um ponto de partida importante na história do beisebol intercolegial. Ele ilustra como o beisebol se tornou popular entre os estudantes nos primeiros dias e como as universidades se tornaram locais importantes para o crescimento e desenvolvimento do esporte nos Estados Unidos.
      <br />
      <br />
      Nas décadas de 1860 e 1870, o número de times universitários cresceu constantemente. Alguns dos primeiros times incluíam Harvard, Yale, Princeton, Dartmouth, Brown e Columbia. Esses times principalmente jogavam entre si ou contra clubes locais.
      <br />
      <br />
      Em 1879, foi realizada a primeira conferência intercolegial de beisebol em Springfield, Massachusetts. O objetivo era padronizar e melhorar as regras e os padrões do jogo. A conferência levou à formação da Associação Americana de Beisebol Universitário, com seis prestigiadas instituições educacionais - Harvard, Yale, Princeton, Amherst, Dartmouth e Brown - fazendo parte dela. Este marcou o início de uma longa tradição de campeonatos nacionais de beisebol universitário.
    </MKTypography>
  </MKBox>

  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crescimento e Profissionalização
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      No final do século XIX e início do século XX, o beisebol tornou-se cada vez mais popular e profissional nos EUA. A National League (NL) e a American League (AL) foram estabelecidas como as principais ligas profissionais de beisebol, atraindo muitos jogadores, fãs e dinheiro.
      <br />
      <br />
      Isso também teve um impacto no beisebol universitário. Muitos jogadores universitários foram recrutados ou contratados por equipes profissionais. Alguns deixaram suas universidades para jogar profissionalmente, enquanto outros continuaram seus estudos, mas também jogavam meio período para equipes profissionais.
      <br />
      <br />
      Isso levou a vários problemas e conflitos no beisebol universitário. Algumas universidades se opuseram à ideia de que seus jogadores fossem pagos para jogar, considerando isso contrário ao espírito amador e à integridade acadêmica dos esportes universitários. Eles também queriam reter seus melhores jogadores para seus próprios times.
      <br />
      <br />
      Outras universidades foram mais tolerantes ou pragmáticas sobre o fenômeno de jogadores profissionais. Elas reconheciam que o beisebol era um esporte lucrativo e prestigioso que poderia fornecer muitos benefícios às suas instituições. Elas também queriam competir com outras universidades no mais alto nível.
      <br />
      <br />
      Para lidar com esses problemas, várias medidas foram tomadas por diferentes organizações. A IBA foi substituída pela National Collegiate Athletic Association (NCAA) em 1906. A NCAA assumiu a responsabilidade de regular e organizar o beisebol universitário e outros esportes. A NCAA também estabeleceu regras e diretrizes para a elegibilidade e comportamento dos jogadores universitários.
      <br />
      <br />
      A NCAA também organizou a primeira College World Series (CWS) em 1947. Este era o novo campeonato nacional de beisebol universitário, realizado anualmente em Omaha, Nebraska. A CWS rapidamente se tornou um dos eventos mais prestigiados e populares nos esportes universitários.
      <br />
      <br />
      Além da NCAA, havia outras organizações envolvidas no beisebol universitário. Uma delas era a National Association of Intercollegiate Athletics (NAIA), estabelecida em 1937. A NAIA era uma alternativa à NCAA, focando em universidades e faculdades menores. A NAIA também organizava seu próprio campeonato nacional de beisebol universitário.
      <br />
      <br />
      Outra organização era a National Junior College Athletic Association (NJCAA), fundada em 1938. A NJCAA era uma organização para faculdades comunitárias oferecendo programas de dois anos. A NJCAA também organizava seu próprio campeonato nacional de beisebol universitário.
      <br />
      <br />
      Essas organizações todas contribuíram para o crescimento e a profissionalização do beisebol universitário. Elas criaram mais oportunidades e diversidade para jogadores, treinadores, escolas e fãs. Elas também tornaram o beisebol universitário uma parte integrante do sistema de beisebol americano.
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  






<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 2: O Beisebol Universitário Hoje
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O beisebol universitário é um dos esportes mais populares e significativos nos EUA hoje. Atrai milhões de fãs, atenção da mídia e patrocinadores. Produz milhares de jogadores, treinadores e oficiais. Oferece centenas de bolsas de estudo, prêmios e honras.
    <br />
    <br />
    Mas como é o beisebol universitário hoje? Como o sistema é organizado? Quais são as principais ligas e conferências? Quais são os principais programas e times prestigiados? Neste capítulo, forneceremos uma visão geral do beisebol universitário hoje.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Principais Ligas e Conferências
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Barry Bonds em ação pela Arizona State no beisebol universitário."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Barry Bonds como jogador da Arizona State
        </figcaption>
      </figure>
      Como vimos no capítulo anterior, várias organizações estão envolvidas no beisebol universitário. A mais importante delas é a NCAA, representando mais de 1.200 universidades e faculdades. A NCAA é dividida em três divisões: Divisão I, Divisão II e Divisão III.
      <br />
      <br />
      A Divisão I é o nível mais alto do beisebol universitário, composta por aproximadamente 300 escolas, divididas em 31 conferências. Cada conferência tem suas próprias regras, calendários e campeonatos. As conferências mais conhecidas incluem a Atlantic Coast Conference (ACC), a Big Ten Conference (B1G), a Big 12 Conference (B12), a Pac-12 Conference (P12) e a Southeastern Conference (SEC).
      <br />
      <br />
      A Divisão II é o nível intermediário do beisebol universitário, composta por aproximadamente 270 escolas, divididas em 23 conferências. As conferências são tipicamente orientadas regionalmente, como a California Collegiate Athletic Association (CCAA), a Gulf South Conference (GSC) e a Northeast-10 Conference (NE10).
      <br />
      <br />
      A Divisão III é o nível mais baixo do beisebol universitário, composta por aproximadamente 380 escolas, divididas em 43 conferências. As conferências também são tipicamente orientadas regionalmente, como a New England Small College Athletic Conference (NESCAC), a Southern California Intercollegiate Athletic Conference (SCIAC) e a Wisconsin Intercollegiate Athletic Conference (WIAC).
      <br />
      <br />
      Além da NCAA, há outras organizações envolvidas no beisebol universitário. Uma delas é a NAIA, representando aproximadamente 190 escolas. A NAIA é dividida em 21 conferências, como a American Midwest Conference (AMC), a Golden State Athletic Conference (GSAC) e a Heart of America Athletic Conference (HAAC).
      <br />
      <br />
      Outra organização é a NJCAA, representando aproximadamente 500 faculdades comunitárias. A NJCAA é dividida em três divisões: Divisão I, Divisão II e Divisão III. Cada divisão é subdividida em distritos e regiões.
      <br />
      <br />
      Essas organizações realizam seus próprios campeonatos nacionais de beisebol universitário a cada ano. O campeonato da Divisão I da NCAA é o evento mais conhecido e prestigiado, frequentemente referido como a College World Series (CWS).
      <br />
      <br />
      A CWS consiste em um torneio de dupla eliminação de 64 times selecionados com base em seu desempenho na temporada regular e nos campeonatos das conferências. Os times são divididos em 16 grupos regionais de quatro times cada. Os vencedores de cada grupo regional avançam para a rodada super regional, onde competem contra outro time em uma série melhor-de-três. Os vencedores de cada rodada super regional avançam para o torneio final da CWS, consistindo de dois grupos de quatro times cada. Os vencedores de cada grupo se enfrentam em uma série melhor-de-três para determinar o campeão nacional.
      <br />
      <br />
      O torneio final da CWS é realizado anualmente em junho em Omaha, Nebraska. É considerado um dos maiores e mais emocionantes eventos nos esportes universitários, atraindo milhares de fãs, mídia e olheiros.
      <br />
      <br />
      Os campeonatos da Divisão II e Divisão III da NCAA seguem um formato semelhante, mas com menos times. Os campeonatos da NAIA e NJCAA também têm seus próprios formatos, que variam dependendo do número de times participantes.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Principais Programas e Times Prestigiados
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O beisebol universitário é um esporte altamente competitivo e prestigiado, com uma rica tradição e um grande pool de talentos. Existem muitos programas e times que se distinguem através de seu sucesso e reputação. Alguns desses programas e times incluem:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Texas Longhorns: O time de beisebol da Universidade do Texas em Austin, competindo na Big 12 Conference. Este programa tem um histórico impressionante com inúmeras aparições na College World Series e múltiplos campeonatos. Alguns ex-alunos notáveis incluem Roger Clemens, Burt Hooton e Huston Street.
        </li>
        <li>
          LSU Tigers: O time de beisebol da Louisiana State University em Baton Rouge, ativo na SEC. LSU é conhecida por sua dominância no beisebol universitário e conquistou múltiplos campeonatos. Alguns ex-alunos notáveis incluem Albert Belle, Aaron Hill e DJ LeMahieu.
        </li>
        <li>
          USC Trojans: O time de beisebol da University of Southern California em Los Angeles, membro da Pac-12 Conference. USC tem uma tradição rica com inúmeros campeonatos e produziu muitos jogadores talentosos. Alguns ex-alunos famosos incluem Tom Seaver, Randy Johnson e Mark McGwire.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estes são apenas alguns exemplos de principais programas e times prestigiados no beisebol universitário. Existem muitos mais programas e times, cada um com sua própria história, cultura e identidade.
      <br />
      <br />
      Estes programas e times contribuem para o desenvolvimento dos jogadores de várias maneiras. Eles fornecem a eles um alto nível de treinamento, instalações e competição. Eles também os ajudam a alcançar seus objetivos acadêmicos, atléticos e pessoais.
    </MKTypography>
  </MKBox>
</MKBox>

                     






                                  






<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 3: Do Universitário para os Profissionais
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O beisebol universitário não é apenas um esporte; também é um caminho para o profissionalismo. Muitos jogadores de beisebol universitário têm a ambição de jogar profissionalmente, seja na MLB ou em outras ligas profissionais. Mas como é esse caminho? Como você pode ir do universitário para os profissionais? Neste capítulo, forneceremos uma visão geral do caminho para o profissionalismo no beisebol.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 O Caminho para o Profissionalismo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O caminho para o profissionalismo no beisebol geralmente começa com o Draft da MLB. O Draft da MLB é um evento anual onde os 30 times da MLB selecionam jogadores de várias fontes, incluindo beisebol universitário, beisebol do ensino médio, beisebol internacional e beisebol independente.
      <br />
      <br />
      O Draft da MLB consiste em 40 rodadas, com cada time podendo escolher um jogador por rodada. A ordem das seleções é determinada pela classificação reversa da temporada anterior da MLB, com algumas exceções para escolhas de compensação e escolhas de loteria.
      <br />
      <br />
      O Draft da MLB é uma oportunidade significativa para jogadores de beisebol universitário começarem suas carreiras profissionais. Aproximadamente 1.200 jogadores são draftados no Draft da MLB a cada ano, com cerca de 60% provenientes do beisebol universitário.
      <br />
      <br />
      Se você for escolhido no Draft da MLB, tem duas opções: pode assinar um contrato com o time que o selecionou ou pode optar por não assinar e voltar para sua universidade ou outra fonte.
      <br />
      <br />
      Se optar por assinar um contrato, receberá um bônus de assinatura com base na rodada em que foi escolhido. Os bônus variam de vários milhões de dólares para a primeira rodada a vários milhares de dólares para rodadas posteriores.
      <br />
      <br />
      Quando assina um contrato, será designado para uma das equipes de ligas menores do time que o selecionou. As ligas menores são os níveis mais baixos do beisebol profissional, servindo como um sistema de desenvolvimento para a MLB. Existem seis níveis nas ligas menores: Rookie, Class A Short Season, Class A, Class A Advanced, Double-A e Triple-A.
      <br />
      <br />
      Enquanto joga nas ligas menores, você deve melhorar suas habilidades e demonstrar seu desempenho para avançar para níveis mais altos. Você também tem que competir com outros jogadores que compartilham o mesmo sonho: chegar às grandes ligas.
      <br />
      <br />
      Alcançar as grandes ligas é o objetivo final de todo jogador de beisebol profissional. É o mais alto nível do beisebol profissional, onde você pode competir contra os melhores jogadores do mundo, na frente dos maiores fãs e pelos maiores salários.
      <br />
      <br />
      No entanto, chegar às grandes ligas não é fácil. Apenas cerca de 10% dos jogadores draftados no Draft da MLB eventualmente chegam às grandes ligas, e apenas cerca de 1% desses draftados têm carreiras longas e bem-sucedidas nas grandes ligas.
      <br />
      <br />
      Portanto, o caminho para o profissionalismo no beisebol é uma jornada longa e desafiadora que requer dedicação, trabalho árduo e um pouco de sorte. Mas também é um caminho que pode oferecer muitas recompensas, oportunidades e sonhos.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 O Draft da MLB e Outras Oportunidades Profissionais
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O Draft da MLB não é a única maneira de jogar profissionalmente no beisebol. Existem também outras oportunidades profissionais fora da MLB que podem ser atraentes para jogadores de beisebol universitário.
      <br />
      <br />
      Uma delas é a liga independente. A liga independente é uma liga profissional de beisebol que não está afiliada à MLB ou às ligas menores. A liga independente é composta por várias ligas, como a Atlantic League, a American Association e a Frontier League.
      <br />
      <br />
      A liga independente oferece uma alternativa para jogadores que não são draftados no Draft da MLB ou não recebem ofertas de contrato de um time da MLB. A liga independente também oferece uma chance para jogadores que são liberados das ligas menores ou desejam estender suas carreiras.
      <br />
      <br />
      A liga independente tem um nível mais baixo de jogo e pagamento em comparação com a MLB ou as ligas menores, mas também oferece um nível mais alto de liberdade e diversão. A liga independente é conhecida por sua criatividade, diversidade e experimentos. Também produziu alguns jogadores que eventualmente chegaram às grandes ligas, como Jose Bautista, Rich Hill e Max Muncy.
      <br />
      <br />
      Outra oportunidade profissional é a liga internacional. A liga internacional é um termo coletivo para ligas profissionais de beisebol disputadas fora dos Estados Unidos. A liga internacional é composta por várias ligas, como a Nippon Professional Baseball (NPB) no Japão, a Korean Baseball Organization (KBO) na Coreia do Sul, a Chinese Professional Baseball League (CPBL) em Taiwan e a Mexican Baseball League (LMB) no México.
      <br />
      <br />
      A liga internacional oferece uma oportunidade para jogadores que desejam ampliar seus horizontes e experimentar uma cultura diferente. A liga internacional também oferece uma oportunidade para jogadores que buscam um nível mais alto de jogo e pagamento do que a liga independente, mas podem não conseguir chegar à MLB ou às ligas menores.
      <br />
      <br />
      Estes são apenas alguns exemplos de oportunidades profissionais fora da MLB. Existem muitas outras oportunidades, como ligas de inverno, ligas de verão universitárias e ligas amadoras.
      <br />
      <br />
      Essas oportunidades oferecem diferentes vantagens e desvantagens para jogadores de beisebol universitário. Elas proporcionam diferentes níveis de competição, recompensa, desafio e diversão. Elas oferecem diversos caminhos para o profissionalismo no beisebol.
    </MKTypography>
  </MKBox>
</MKBox>
                                






                                  




<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 4: Influências Internacionais
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O beisebol universitário não é apenas um esporte americano; também é um esporte internacional influenciado por jogadores, treinadores e fãs de diversos países e culturas. Neste capítulo, forneceremos uma visão geral das influências internacionais no beisebol universitário.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Jogadores Estrangeiros no Beisebol Universitário
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Equipe de beisebol da USC comemorando uma vitória."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Jogadores da USC comemorando uma vitória
        </figcaption>
      </figure>
      Uma das influências internacionais mais significativas no beisebol universitário é a presença de jogadores estrangeiros. Jogadores estrangeiros são jogadores que não nasceram nos EUA ou não têm cidadania americana. Eles vêm de diferentes países, como Canadá, México, Japão, Coreia do Sul, Taiwan, Cuba, Venezuela, República Dominicana e Holanda.
      <br />
      <br />
      Os jogadores estrangeiros desempenham um papel vital no beisebol universitário. Eles trazem suas próprias habilidades, estilos e perspectivas para o jogo. Eles enriquecem a diversidade e a qualidade do beisebol universitário. Eles também aumentam o reconhecimento e o apelo internacional do beisebol universitário.
      <br />
      <br />
      Existem muitas histórias de sucesso de jogadores estrangeiros que se destacaram no mundo do beisebol universitário americano. Um exemplo proeminente é Nomar Garciaparra. Nomar Garciaparra foi um shortstop mexicano-americano que jogou pelo Instituto de Tecnologia da Geórgia. Ele foi selecionado na primeira rodada do Draft da MLB em 1994 pelo Boston Red Sox. Ele jogou 14 temporadas na MLB, onde se tornou All-Star seis vezes e venceu o título de rebatidas da AL duas vezes. Existem muitos mais exemplos de jogadores estrangeiros que deixaram sua marca no beisebol universitário.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Americanos no Exterior
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Outra influência internacional no beisebol universitário é a presença de americanos no exterior. Americanos no exterior são jogadores que nasceram nos EUA ou têm cidadania americana, mas jogam ou jogaram em ligas profissionais fora dos EUA.
      <br />
      <br />
      Os americanos no exterior também desempenham um papel significativo no beisebol universitário. Eles trazem suas próprias experiências, conhecimentos e redes para o jogo. Eles também aprendem com outras culturas e estilos de beisebol. Eles aumentam o intercâmbio e a colaboração internacional no beisebol universitário.
      <br />
      <br />
      Essas oportunidades oferecem muitos benefícios aos jogadores de beisebol universitário americanos. Eles proporcionam um nível mais alto de competição, exposição e recompensa. Eles também ajudam a ampliar seus horizontes, aumentar a adaptabilidade e expandir suas redes.
      <br />
      <br />
      A experiência internacional pode ser benéfica para o desenvolvimento de um jogador. Isso pode ajudá-los a aprender novas habilidades, técnicas e estratégias. Isso também pode ajudá-los a melhorar suas fraquezas, alavancar seus pontos fortes e maximizar seu potencial.
    </MKTypography>
  </MKBox>
</MKBox>







                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    As bolsas de estudo esportivas oferecem inúmeras oportunidades para jovens atletas mostrarem seu talento e desenvolverem suas habilidades. Uma das oportunidades mais significativas é obter uma bolsa de estudos esportiva para estudar e competir no nível universitário. Neste capítulo, discutiremos a importância das bolsas de estudo esportivas e como a Sport Scholarships America pode ajudá-lo a realizar seu sonho.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 A Importância das Bolsas de Estudo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Uma bolsa de estudos esportiva é um suporte financeiro fornecido por uma universidade a um estudante-atleta para combinar estudos e esportes. Pode cobrir os custos de mensalidades, livros, moradia, refeições e outras despesas. Além disso, pode oferecer benefícios como tutoria acadêmica, cuidados médicos, instalações de treinamento e equipamentos.
      <br />
      <br />
      Obter uma bolsa de estudos esportiva é competitivo, com muitos fatores desempenhando um papel no processo de concessão, incluindo realizações acadêmicas, desempenho atlético, personalidade, motivação, liderança e adaptabilidade. Portanto, é importante se preparar bem e se destacar da concorrência.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Como a Sport Scholarships America Pode Ajudar
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo da Sport Scholarships America, mostrando equipamentos esportivos e um capelo de formatura, representando o foco em bolsas de estudo esportivas para estudantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo da Sport Scholarships America
        </figcaption>
      </figure>
      A Sport Scholarships America é uma organização especializada em orientar jovens talentos esportivos para bolsas de estudo esportivas. Com especialistas apaixonados e experientes, a Sport Scholarships America apoia estudantes-atletas em cada etapa da jornada, desde a orientação inicial até a colocação final. Seus serviços incluem:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Uma entrevista de admissão pessoal para discutir os desejos, possibilidades e expectativas do estudante-atleta.</li>
        <li>Uma avaliação profissional do nível acadêmico e atlético do estudante-atleta.</li>
        <li>Uma extensa rede de contatos com treinadores, universidades e faculdades.</li>
        <li>Promoção eficaz do perfil e vídeos do estudante-atleta para treinadores potenciais.</li>
        <li>Conselhos especializados sobre as melhores opções com base nas preferências, metas e orçamento do estudante-atleta.</li>
        <li>Negociação das melhores ofertas de bolsas de estudo em nome do estudante-atleta.</li>
        <li>Auxílio na solicitação de documentos necessários, como visto, histórico escolar, diploma e testes de admissão.</li>
        <li>Preparação para a vida do estudante-atleta durante a bolsa de estudos, incluindo cultura, idioma, regras e dicas.</li>
        <li>Acompanhamento e acompanhamento durante o período da bolsa de estudos.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Quer explorar oportunidades de bolsas de estudo e alcançar seus objetivos atléticos e acadêmicos? <Link to="/pt/Serviços-Produtos">Veja a gama completa</Link> de serviços oferecidos pela Sport Scholarships America e agende uma consulta hoje.
    </MKTypography>
  </MKBox>
</MKBox>



          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;