import { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MKTypography from "components/MKTypography";

import MKBox from "components/MKBox";

import SAT from "assets/images/Producten/SAT_kaft.png";
import ACT from "assets/images/Producten/ACT_kaft.png";
import TOEFL from "assets/images/Producten/Toefl_kaft.png";
import Hoodie from "assets/images/Producten/Hoodie_front.png";
import Shirt from "assets/images/Producten/Shirt_front.png";
import { Link } from 'react-scroll';



import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

function Producten() {
  const [activeTab, setActiveTab] = useState(1);
  const [tabType, setTabType] = useState("monthly");

  const handleTabType = ({ currentTarget }, newValue) => {
    setActiveTab(newValue);
    setTabType(currentTarget.id);
  };

  
  return (
    <section id="productenSection">
      <Container sx={{ pb: { xs: 12, lg: 8 }, pt: 0 }}>
      <Grid container item xs={12} justifyContent="center" md={8} mx="auto" textAlign="center">
  {/* Wrap each MKTypography in its own Grid item */}
  <Grid item xs={12}> {/* Full width for the heading */}
    <MKTypography variant="h3" color="text">Products</MKTypography>
  </Grid>
  <Grid item xs={12}> {/* Full width for the body text */}
    <MKTypography variant="body2" color="text">
    From study guides to Sport Scholarships America merchandise





</MKTypography>
  </Grid>
  <Grid
          container
          item
          xs={12}
          md={10}
          lg={8}
          justifyContent="center"
          textAlign="center"
          mx="auto"
          mt={6}
        >
          <AppBar position="static">
          <Tabs value={activeTab} onChange={handleTabType}>
  <Tab
    id="monthly"
    label={
      <Link 
        to="dienstenSection" 
        spy={true}
        smooth={true}
        duration={500}
        offset={-70}
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <MKBox py={0.5} px={2} color="inherit">
          Services
        </MKBox>
      </Link>
    }
  />
  <Tab
    id="annual"
    label={
      <Link 
        to="productenSection" 
        spy={true}
        smooth={true}
        duration={500}
        offset={-70}
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <MKBox py={0.5} px={2} color="inherit">
          Products
        </MKBox>
      </Link>
    }
  />
</Tabs>
          </AppBar>
        </Grid>
          
          
        <Grid container spacing={3} mt={6} mb={6}>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="SAT Study Guide"
      image={SAT}
      description="Prepare for the SAT"
      action={{ type: "internal", route: "/Services-Products/SAT-guide", label: "Order", color: "primary" }}
    />
  </Grid>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="ACT Study Guide"
      image={ACT}
      description="Prepare for the ACT"
      action={{ type: "internal", route: "/Services-Products/ACT-guide", label: "Order", color: "primary" }}
    />
  </Grid>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="TOEFL Study Guide"
      image={TOEFL}
      description="Prepare for the TOEFL"
      action={{ type: "internal", route: "/Services-Products/TOEFL-guide", label: "Order", color: "primary" }}
    />
  </Grid>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="Hoodie"
      image={Hoodie}
      description="The Sport Scholarships America Hoodie"
      action={{ type: "internal", route: "/Services-Products/Hoodie", label: "Order", color: "primary" }}
    />
  </Grid>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="T-shirt"
      image={Shirt}
      description="The Sport Scholarships America T-shirt"
      action={{ type: "internal", route: "/Services-Products/T-shirt", label: "Order", color: "primary" }}
    />
  </Grid>
</Grid>

          
        
        </Grid>
      </Container>
    </section>
  );
}

export default Producten;
