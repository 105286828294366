
import { LinkedIn } from "@mui/icons-material";
import { Instagram } from "@mui/icons-material";
import { Twitter } from "@mui/icons-material";


// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images//Logos/Sportbeurs.png";


const date = new Date().getFullYear();

export default {
  brand: {
    name: "Sport Scholarships America",
    image: logoCT,
    route: "/pt/",
  },
  socials: [
    {
      icon: <LinkedIn />,
      link: "https://www.linkedin.com/company/sportbeurs-amerika-nl",
    },
    {
      icon: <Instagram />,
      link: "",
    },
    {
      icon: <Twitter />,
      link: "",
    },
  
  ],
  menus: [
    {
      name: "Empresa",
      items: [
        { name: "Sobre Mim", route: "/pt/Sobre-mim" },
        { name: "Sobre Sport Scholarships America", route: "/pt/Sobre-Sport-Scholarships-America" },
        { name: "Serviços & Produtos", route: "/pt/Serviços-Produtos" },
      ],
    },
    {
      name: "Fontes",
      items: [
        { name: "Podcast", route: "/pt/Podcast" },
        { name: "Centro de Conhecimento", route: "/pt/Centro-de-conhecimento" },
      ],
    },
    {
      name: "Ajuda e Suporte",
      items: [
        { name: "Formulário de Contato", route: "/pt/Formulário-de-contato" },
        { name: "FAQ's", route: "/pt/FAQs" },
      ],
    },
    {
      name: "Legal",
      items: [
        { name: "Privacy Policy", route: "/Privacy-Policy" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
All rights reserved. Copyright © {date} Sport Scholarships America    </MKTypography>
  ),
};