/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Track and Field1.jpg";
import Image2 from "assets/images/Sports_Images/Track and Field2.jpeg";
import Image3 from "assets/images/Sports_Images/Track and Field3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Atletismo y Estudiar en América: Un Sueño que Puede Hacerse Realidad', isSubheader: false },
  
    { id: 'Capítulo 1', title: '1. Historia y Desarrollo del Atletismo a Nivel Universitario', isSubheader: false },
    { id: '1.1', title: '1.1 Primeros Años y Orígenes', isSubheader: true },
    { id: '1.2', title: '1.2 Crecimiento y Profesionalización', isSubheader: true },
  
    { id: 'Capítulo 2', title: '2. Atletismo Universitario en la Actualidad', isSubheader: false },
    { id: '2.1', title: '2.1 Principales Competiciones y Conferencias', isSubheader: true },
    { id: '2.2', title: '2.2 Programas Destacados y Equipos Prestigiosos', isSubheader: true },
  
    { id: 'Capítulo 3', title: '3. De la Universidad a lo Profesional', isSubheader: false },
    { id: '3.1', title: '3.1 El Camino hacia el Profesionalismo', isSubheader: true },
    { id: '3.2', title: '3.2 Relación con el Atletismo Profesional', isSubheader: true },
  
    { id: 'Capítulo 4', title: '4. Influencias y Oportunidades Internacionales', isSubheader: false },
    { id: '4.1', title: '4.1 Atletas Extranjeros y Competiciones', isSubheader: true },
    { id: '4.2', title: '4.2 Atletas Estadounidenses en el Extranjero', isSubheader: true },
  
    { id: 'Capítulo 5', title: '5. Becas Deportivas y el Futuro de los Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 La Importancia de las Becas', isSubheader: true },
    { id: '5.2', title: '5.2 Cómo Puede Ayudar Sport Scholarships America', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contents
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Ocultar</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                      key={section.id}
                      onClick={() => scrollTo(section.id)}
                      color="secondary"
                      variant="gradient"
                      sx={{ 
                        justifyContent: "flex-start",
                        pl: section.isSubheader ? 4 : 2,
                        textTransform: "none",
                        fontWeight: section.isSubheader ? "normal" : "bold",
                        borderTopLeftRadius: index === 0 ? '20px' : '0',
                        borderTopRightRadius: index === 0 ? '20px' : '0',
                        borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                        borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                        textAlign: "left",
                        width: '100%',
                      }}
                    >
                      {section.title}
                    </MKButton>
                  ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <>
  <MKBox id="intro" mb={3}>
    <MKTypography variant="h1" className="h1-as-h3" mb={2}>
      Atletismo y Estudiar en América: Un Sueño que Puede Hacerse Realidad
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image1}
          alt="Corredores en plena acción durante las Relays de Penn State, un destacado evento universitario de atletismo."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal1(Image1)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Acción en las Relays de Penn State.
        </figcaption>
      </figure>
      El atletismo es uno de los deportes más populares y significativos en los Estados Unidos. Millones de personas practican el atletismo como hobby, como parte de su programa escolar o universitario, o como atletas profesionales. El atletismo también es un deporte que ofrece muchas oportunidades para que los jóvenes talentos se desarrollen, compitan y sobresalgan en el más alto nivel.
      <br/>
      <br/>
      Una de las mejores maneras de aprovechar estas oportunidades es obteniendo una beca de atletismo en los Estados Unidos. Una beca de atletismo es el apoyo financiero que recibes de una universidad para estudiar y participar en el atletismo allí. Con una beca de atletismo, no solo puedes obtener tu título académico sino también participar en uno de los programas de atletismo más prestigiosos y competitivos del mundo.
      <br/>
      <br/>
      Pero, ¿cómo se obtiene una beca de atletismo en los Estados Unidos? ¿Cuáles son los beneficios y desafíos de estudiar y competir a nivel universitario? ¿Y cómo puedes prepararte para una carrera exitosa como atleta después de tus estudios? Estas son algunas de las preguntas que responderemos en este artículo.
      <br/>
      <br/>
      También te contaremos cómo Sportbeursamerika.nl puede ayudarte a hacer realidad tu sueño. Sportbeursamerika.nl es una organización especializada en guiar a jóvenes atletas hacia becas deportivas en los Estados Unidos. Tengo mucha experiencia en encontrar la mejor combinación entre atletas y universidades. Ofrezco orientación personalizada, consejos profesionales y apoyo práctico en cada paso del proceso.
      <br/>
      <br/>
      Si estás interesado en obtener una beca de atletismo en los Estados Unidos, este artículo es para ti. Sigue leyendo y descubre todo lo que necesitas saber sobre el atletismo a nivel universitario en los Estados Unidos.
    </MKTypography>
    {/* Agregar lista con viñetas u otro contenido según sea necesario */}
  </MKBox>
  {/* Capítulos principales y subcapítulos con texto de relleno */}
  <MKBox id="Chapter 1" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
      Capítulo 1: Historia y Desarrollo del Atletismo a Nivel Universitario
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      El atletismo a nivel universitario tiene una historia larga y rica en los Estados Unidos. Comenzó en el siglo XIX como una forma de promover la salud física y mental de los estudiantes. Se convirtió en un deporte importante que atrajo a millones de espectadores y produjo muchos atletas que más tarde se hicieron famosos en todo el mundo.
    </MKTypography>

    <MKBox id="1.1" mb={2}>
      <MKTypography variant="h4" mb={1}>
        1.1 Primeros Años y Orígenes
      </MKTypography>
      <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        La primera competencia de atletismo en las universidades tuvo lugar en 1864, cuando estudiantes de Yale y Oxford se desafiaron mutuamente para una competencia en Londres. La competencia consistió en cuatro eventos: carreras, salto de longitud, salto de altura y salto con pértiga. Yale ganó la competencia 7-3.
        <br/>
        <br/>
        Esta competencia inspiró a otras universidades a formar equipos de atletismo y competir entre ellas. En 1873, se fundó la primera asociación interuniversitaria de atletismo, la Asociación Intercolegial de Atletas Aficionados de América (IAAAA). La IAAAA organizó campeonatos anuales para universidades en el este de los Estados Unidos.
        <br/>
        <br/>
        El siglo XIX tardío fue una época importante para el desarrollo del atletismo. Se caracterizó por la formalización de reglas y la aparición de figuras influyentes como James E. Sullivan, cofundador de la Unión Atlética Amateur en los Estados Unidos. Jugó un papel significativo en la popularización del atletismo y la organización de eventos importantes. La introducción de los Juegos Olímpicos modernos en 1896 dio una plataforma internacional al deporte, lo que llevó a la estandarización de reglas y eventos. Además, los avances tecnológicos, como mejoras en calzado y pistas de carrera, contribuyeron a la evolución del atletismo durante este período.
      </MKTypography>
    </MKBox>
    <MKBox id="1.2" mb={2}>
      <MKTypography variant="h4" mb={1}>
        1.2 Crecimiento y Profesionalización
      </MKTypography>
      <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        En el siglo XX, el atletismo se volvió cada vez más popular e importante en las universidades. El número de programas de atletismo, competiciones y becas aumentó. El nivel de los atletas también aumentó, gracias en parte a un mejor entrenamiento, instalaciones y entrenadores.
        <br/>
        <br/>
        Uno de los factores que contribuyeron al crecimiento y profesionalización del atletismo a nivel universitario fue la fundación de la Asociación Atlética Universitaria Nacional (NCAA) en 1906. La NCAA es una organización que supervisa las actividades deportivas de más de 1000 universidades en los Estados Unidos. La NCAA regula, entre otras cosas, las reglas, calificaciones, becas y sanciones para el atletismo a nivel universitario.
        <br/>
        <br/>
        Otro factor que influyó en el desarrollo del atletismo a nivel universitario fue la relación con el atletismo profesional. Muchos atletas que tuvieron éxito a nivel universitario luego pasaron al circuito profesional. Algunos ejemplos son Jesse Owens, Carl Lewis, Jackie Joyner-Kersee y Michael Johnson.
      </MKTypography>
    </MKBox>
  </MKBox>
</>






                                              





                                  








<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 2: Atletismo Universitario en la Actualidad
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El atletismo universitario es hoy en día uno de los deportes más populares y prestigiosos en los Estados Unidos. Cada año, miles de atletas participan en diversas competiciones y eventos, atrayendo a millones de fanáticos y atención mediática. El atletismo universitario también ofrece muchas oportunidades para que los atletas se desarrollen, actúen y disfruten de su deporte.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Principales Competiciones y Conferencias
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Sydney McLaughlin, destacándose como atleta para la Universidad de Kentucky, con el uniforme de competición."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Sydney McLaughlin estudió en la Universidad de Kentucky
        </figcaption>
      </figure>
      La principal organización que regula el atletismo universitario es la NCAA. La NCAA divide a las universidades en tres divisiones, según su tamaño, presupuesto y nivel de competencia. La División I es la más alta, donde se encuentran la mayoría de las principales universidades y atletas. Las Divisiones II y III son divisiones inferiores, donde las universidades tienen menos becas y recursos, pero ponen más énfasis en el rendimiento académico.
      <br/>
      <br/>
      Dentro de cada división, hay diferentes conferencias, que son grupos de universidades que están conectadas geográfica o históricamente. Cada conferencia organiza sus propios campeonatos y competiciones para sus miembros. Algunas de las conferencias más conocidas son la Big Ten, la Pac-12, la SEC, la ACC y la Ivy League.
      <br/>
      <br/>
      Los principales eventos en la temporada de atletismo universitario son los campeonatos de la NCAA, que se celebran anualmente en marzo (en pista cubierta) y junio (al aire libre). Estos campeonatos determinan qué universidades y atletas son los mejores en cada división y evento. Los campeonatos atraen a muchos espectadores, patrocinadores y atención mediática.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programas Destacados y Equipos Prestigiosos
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Hay muchas universidades conocidas por sus excelentes programas de atletismo. Estas universidades tienen una larga tradición de éxito, entrenamiento de alta calidad e instalaciones, y un gran atractivo para atletas talentosos. Algunas de estas universidades son:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Oregon: Oregon es una de las universidades más exitosas en el atletismo, especialmente en las carreras. Oregon ha ganado más de 30 títulos de la NCAA y ha producido muchos atletas famosos, como Steve Prefontaine, Alberto Salazar, Galen Rupp y Matthew Centrowitz.
        </li>
        <li>
          USC: USC es otra de las principales universidades en el atletismo, con un fuerte enfoque en las carreras de velocidad, saltos y lanzamientos. USC ha ganado más de 25 títulos de la NCAA y ha producido muchos medallistas olímpicos, como Quincy Watts, Andre De Grasse y Michael Norman.
        </li>
        <li>
          Arkansas: Arkansas es una universidad dominante en el atletismo, especialmente durante la temporada en pista cubierta. Arkansas ha ganado más de 40 títulos de la NCAA, de los cuales más de 30 son en pista cubierta. Arkansas es conocida por su profundidad y versatilidad en diversos eventos.
        </li>
        <li>
          Texas: Texas es una de las universidades más versátiles en el atletismo, con un buen equilibrio entre los equipos masculinos y femeninos, y entre eventos individuales y de relevos. Texas ha ganado más de 20 títulos de la NCAA y ha producido muchos atletas de clase mundial, como Sanya Richards-Ross, Trey Hardee, Michelle Carter y Courtney Okolo.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estas universidades ofrecen no solo un alto nivel de competencia y entrenamiento para sus atletas, sino también un gran ambiente y cultura. Los atletas son parte de una comunidad unida que los apoya y motiva para alcanzar su potencial.
    </MKTypography>
  </MKBox>
</MKBox>












                                              






                                  








<MKBox id="Chapter 3" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Capítulo 3: De la Universidad a lo Profesional
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        El atletismo universitario no solo es un deporte, sino también un campo de entrenamiento para atletas que aspiran a una carrera profesional. Muchos atletas que han destacado a nivel universitario luego han pasado al circuito profesional. El atletismo universitario ofrece a los atletas la oportunidad de desarrollarse, experimentar y establecer contactos con otros atletas, entrenadores y organizaciones.
    </MKTypography>

    <MKBox id="3.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.1 El Camino hacia el Profesionalismo
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Para muchos atletas, el objetivo es convertirse en atletas profesionales después de sus estudios. Esto significa practicar su deporte como una profesión y ganar dinero a través de actuaciones, contratos y patrocinios. Para convertirse en atletas profesionales, los atletas deben cumplir varias condiciones, tales como:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Tener un alto nivel de rendimiento y potencial. Los atletas profesionales deben poder competir con los mejores atletas del mundo, y mejorar y adaptarse continuamente a condiciones cambiantes.
                </li>
                <li>
                    Mantener una buena salud y estado físico. Los atletas profesionales deben cuidar bien sus cuerpos y protegerse contra lesiones, enfermedades y sobreentrenamiento. También deben encontrar un buen equilibrio entre entrenamiento, descanso y nutrición.
                </li>
                <li>
                    Contar con una buena orientación y apoyo. Los atletas profesionales deben poder confiar en un equipo de expertos para ayudarlos con la técnica, táctica, preparación mental, asuntos legales, cuestiones financieras y marketing.
                </li>
                <li>
                    Tener una buena planificación y estrategia. Los atletas profesionales necesitan saber qué objetivos quieren alcanzar, en qué competiciones quieren participar, cómo quieren clasificarse para eventos importantes y cómo quieren gestionar su carrera.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            El atletismo universitario puede ayudar a los atletas a cumplir con estas condiciones. Ofrece a los atletas la oportunidad de mostrar su talento, mejorar sus habilidades, aumentar su experiencia y ampliar su red de contactos. El atletismo universitario también puede servir como trampolín hacia el nivel profesional al exponer a los atletas a niveles altos de competencia, atención mediática e interés público.
        </MKTypography>
    </MKBox>
    <MKBox id="3.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.2 Relación con el Atletismo Profesional
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            El atletismo universitario tiene una estrecha relación con el atletismo profesional. Los dos niveles se influencian mutuamente de diversas maneras, como:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Desarrollo de talentos: El atletismo universitario es una fuente importante de talento para el atletismo profesional. Muchos atletas profesionales comenzaron como atletas universitarios y sentaron las bases para su éxito posterior allí.
                </li>
                <li>
                    Búsqueda de talentos: Las organizaciones atléticas profesionales supervisan de cerca el atletismo universitario para descubrir y atraer posibles estrellas. Ofrecen contratos, patrocinios y orientación a los atletas universitarios para persuadirlos de convertirse en profesionales.
                </li>
                <li>
                    Intercambio de talentos: El atletismo universitario también está abierto a atletas profesionales que aún están estudiando o que regresan a la escuela. Algunos atletas profesionales eligen completar o reanudar sus estudios mientras continúan su carrera deportiva.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Por lo tanto, el atletismo universitario no es solo un punto final sino también un trampolín para los atletas que persiguen una carrera profesional. Ofrece a los atletas la oportunidad de prepararse para el nivel profesional, pero también de explorar otros caminos.
        </MKTypography>
    </MKBox>
</MKBox>










                                              






                                  








<MKBox id="Chapter 4" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Capítulo 4: Influencias y Oportunidades Internacionales
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        El atletismo universitario no es solo un deporte estadounidense, sino también internacional. Está influenciado por y ofrece oportunidades para atletas de diversos países y culturas. El atletismo universitario es un deporte que refleja la diversidad y la interconexión del mundo.
    </MKTypography>

    <MKBox id="4.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.1 Atletas Extranjeros y Competiciones
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image3}
                    alt="El famoso estadio atlético de Oregón, hogar de numerosos eventos de atletismo universitario."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal3(Image3)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Estadio atlético de Oregón, un ícono del deporte universitario.
                </figcaption>
            </figure>
            Una de las formas en que el atletismo universitario es internacional es a través de la presencia y actuaciones de atletas extranjeros. Cada año, cientos de atletas de otros países vienen a los EE. UU. para estudiar y competir a nivel universitario. Estos atletas aportan su propio talento, estilo y cultura a las universidades estadounidenses, enriqueciendo la comunidad de atletismo con sus perspectivas y experiencias.
            <br/>
            <br/>
            Los atletas extranjeros también tienen un impacto significativo en los resultados y clasificaciones del atletismo universitario. Muchos atletas extranjeros están entre los mejores atletas en su evento, a menudo ganando medallas, títulos y récords. Algunos atletas extranjeros son tan buenos que superan o desafían a los atletas estadounidenses.
            <br/>
            <br/>
            Ejemplos de exitosos atletas extranjeros en competiciones universitarias estadounidenses incluyen:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Andre De Grasse: un velocista canadiense que se destacó en Coffeyville Community College y en la Universidad del Sur de California (USC), ganando múltiples títulos NJCAA y NCAA en eventos de velocidad. Sus actuaciones en USC establecieron su reputación como uno de los principales velocistas. Después de su carrera universitaria, De Grasse ganó múltiples medallas olímpicas, incluido el oro en los 200 metros en los Juegos Olímpicos de Tokio 2020, mostrándose como uno de los principales velocistas del mundo.
                </li>

                <li>
                    Kerron Clement: un atleta trinitense-estadounidense que sobresale en los 400 metros con vallas. Estudió en la Universidad de Florida, donde ganó títulos de la NCAA y se convirtió en un corredor de vallas de clase mundial. Clement capturó oro en los Juegos Olímpicos de 2008 y 2016 y tiene varios campeonatos mundiales en su haber, lo que lo convierte en uno de los corredores de vallas más exitosos de su generación.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Los atletas extranjeros influyen no solo en el atletismo universitario, sino también en otras competiciones y eventos internacionales. Muchos atletas extranjeros que compitieron a nivel universitario luego participan en los Juegos Olímpicos, Campeonatos Mundiales u otros campeonatos regionales o continentales. Representan a su propio país pero también tienen vínculos con su universidad estadounidense.
        </MKTypography>
    </MKBox>
    <MKBox id="4.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.2 Atletas Estadounidenses en el Extranjero
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Otra forma en que el atletismo universitario es internacional es a través de las oportunidades y desafíos que ofrece a los atletas estadounidenses para competir en el extranjero. Muchos atletas estadounidenses que han competido a nivel universitario tienen la oportunidad de ampliar sus horizontes y poner a prueba sus habilidades contra otros atletas de diferentes países y culturas.
            <br/>
            <br/>
            Una de las principales oportunidades para que los atletas estadounidenses compitan en el extranjero es, por supuesto, los Juegos Olímpicos. Los Juegos Olímpicos son el evento deportivo más grande y prestigioso del mundo, donde miles de atletas de más de 200 países se reúnen para competir por el oro, la plata y el bronce. Los Juegos Olímpicos también son una oportunidad para que los atletas estadounidenses representen a su país, muestren su patriotismo y compartan su orgullo nacional.
            <br/>
            <br/>
            Otro evento importante para que los atletas estadounidenses compitan en el extranjero son los Campeonatos Mundiales de Atletismo. Los Campeonatos Mundiales de Atletismo son el evento más significativo de atletismo después de los Juegos Olímpicos, donde se encuentran los mejores atletas del mundo para competir por el título mundial. Los Campeonatos Mundiales de Atletismo también son una oportunidad para que los atletas estadounidenses midan su nivel, alimenten sus rivalidades y establezcan su reputación.
            <br/>
            <br/>
            Además de estos eventos principales, también hay otras oportunidades para que los atletas estadounidenses compitan en el extranjero, como la Diamond League, el Continental Tour, los Relevos Mundiales de Atletismo u otros campeonatos regionales o continentales. Estos eventos ofrecen a los atletas estadounidenses la oportunidad de aumentar su experiencia, ganancias y base de fanáticos.
            <br/>
            <br/>
            El atletismo universitario juega un papel importante en el desarrollo y éxito de los atletas estadounidenses que compiten en el extranjero. Prepara a los atletas para el nivel internacional al proporcionarles entrenamiento de alta calidad, coaching y competencia. El atletismo universitario también apoya a los atletas que compiten en el extranjero ofreciéndoles una red de contactos, patrocinadores y fanáticos.
        </MKTypography>
    </MKBox>
</MKBox>











                                              






                                  



<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Becas Deportivas y el Futuro de los Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Las becas deportivas ofrecen numerosas oportunidades para que los jóvenes atletas muestren su talento y desarrollen sus habilidades. Una de las oportunidades más significativas es obtener una beca deportiva para estudiar y competir a nivel universitario. En este capítulo, discutiremos la importancia de las becas deportivas y cómo Sport Scholarships America puede ayudarte a hacer realidad tu sueño.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 La Importancia de las Becas
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Una beca deportiva es un apoyo financiero proporcionado por una universidad a un estudiante-atleta para combinar estudios y deporte. Puede cubrir los costos de matrícula, libros, alojamiento, comidas y otros gastos. Además, puede ofrecer beneficios como tutoría académica, atención médica, instalaciones de entrenamiento y equipo.
      <br />
      <br />
      Obtener una beca deportiva es competitivo, con muchos factores que influyen en el proceso de adjudicación, incluyendo logros académicos, rendimiento deportivo, personalidad, motivación, liderazgo y adaptabilidad. Por lo tanto, es importante prepararse bien y destacarse entre la competencia.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Cómo Puede Ayudar Sport Scholarships America
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo de Sport Scholarships America, que muestra equipamiento deportivo y una gorra de graduación, representando el enfoque en becas deportivas para estudiantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America es una organización especializada en guiar a jóvenes talentos deportivos hacia becas deportivas. Con expertos apasionados y experimentados, Sport Scholarships America apoya a los estudiantes-atletas en cada paso del camino, desde la orientación inicial hasta la colocación final. Sus servicios incluyen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Una entrevista personal para discutir los deseos, posibilidades y expectativas del estudiante-atleta.</li>
        <li>Una evaluación profesional del nivel académico y deportivo del estudiante-atleta.</li>
        <li>Una extensa red de contactos con entrenadores, universidades y colegios.</li>
        <li>Promoción efectiva del perfil y videos del estudiante-atleta ante entrenadores potenciales.</li>
        <li>Asesoramiento experto sobre las mejores opciones basadas en las preferencias, metas y presupuesto del estudiante-atleta.</li>
        <li>Negociación de las mejores ofertas de becas en nombre del estudiante-atleta.</li>
        <li>Asistencia en la solicitud de documentos necesarios, como visa, expediente académico, diploma y pruebas de admisión.</li>
        <li>Preparación para la vida del estudiante-atleta durante su beca, incluyendo cultura, idioma, reglas y consejos.</li>
        <li>Seguimiento y atención posterior durante el período de la beca.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      ¿Quieres explorar oportunidades de becas y alcanzar tus metas deportivas y académicas? <Link to="/es/Servicios-Productos">Consulta la gama completa</Link> de servicios ofrecidos por Sport Scholarships America y programa una consulta hoy mismo.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;