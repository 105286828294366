/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Basketbal1.jpeg";
import Image2 from "assets/images/Sports_Images/Basketbal2.webp";
import Image3 from "assets/images/Sports_Images/Basketbal3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import { Link } from 'react-router-dom';

import "../../../../H1asH3.css"

// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'USA Basketballstipendium: Wie man in Amerika Basketball studiert und spielt', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapitel 1: Geschichte und Entwicklung des College-Basketballs', isSubheader: false },
    { id: '1.1', title: '1.1 Frühe Jahre', isSubheader: true },
    { id: '1.2', title: '1.2 Wachstum und Professionalisierung', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapitel 2: College-Basketball heute', isSubheader: false },
    { id: '2.1', title: '2.1 Wichtige Wettbewerbe und Konferenzen', isSubheader: true },
    { id: '2.2', title: '2.2 Top-Programme und angesehene Teams', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapitel 3: Vom College in die NBA', isSubheader: false },
    { id: '3.1', title: '3.1 Der Weg in die NBA', isSubheader: true },
    { id: '3.2', title: '3.2 Andere professionelle Möglichkeiten', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapitel 4: Internationale Einflüsse und Möglichkeiten', isSubheader: false },
    { id: '4.1', title: '4.1 Ausländische Spieler im College-Basketball', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanische Spieler im Ausland', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapitel 5: Sportstipendien und die Zukunft der Athleten', isSubheader: false },
    { id: '5.1', title: '5.1 Die Bedeutung von Stipendien', isSubheader: true },
    { id: '5.2', title: '5.2 Wie Sportstipendien Amerika helfen kann', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };





  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhaltsverzeichnis
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verstecken</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Anzeigen</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    USA Basketball Scholarship: Wie man Basketball in Amerika studiert und spielt
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Lebhaftes Stadion der University of Arizona während eines College-Basketballspiels"
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Spieltag im Stadion der University of Arizona
      </figcaption>
    </figure>
    Basketball ist eine der beliebtesten Sportarten in den Vereinigten Staaten. Millionen von Menschen verfolgen die Spiele der NBA, der professionellen Basketballliga, und der NCAA, der College-Basketballliga. Basketball ist nicht nur ein spannendes Spiel zum Zuschauen, sondern auch eine großartige Möglichkeit, sich als Spieler und als Person weiterzuentwickeln.
    <br/>
    <br/>
    Wenn Sie ein junger Basketballspieler sind, der von einer Karriere in der NBA träumt, oder einfach nur Basketball auf hohem Niveau spielen möchte, dann könnte ein Basketballstipendium in Amerika das Richtige für Sie sein. Ein Basketballstipendium ist finanzielle Unterstützung, die Sie von einer amerikanischen Universität erhalten, um dort zu studieren und Basketball zu spielen. Mit einem Basketballstipendium können Sie nicht nur Ihren akademischen Abschluss erlangen, sondern auch Ihre Basketballtalente unter professioneller Anleitung verbessern.
    <br/>
    <br/>
    Aber wie bekommt man ein Basketballstipendium in Amerika? Was sind die Vor- und Nachteile des College-Basketballs? Und welche Möglichkeiten gibt es nach Ihrer College-Karriere? In diesem Artikel werden wir all diese Fragen beantworten und Ihnen alles Wissenswerte über Basketballstipendien in Amerika erzählen. Wir werden auch die Rolle von Sportbeursamerika.nl diskutieren, einer Organisation, die sich darauf spezialisiert hat, jungen Spielern zu helfen, ihren Traum von einem Basketballstipendium zu verwirklichen.
    <br/>
    <br/>
    Möchten Sie mehr über Basketballstipendien in Amerika erfahren? Lesen Sie weiter!
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
<MKBox id="Kapitel 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Geschichte und Entwicklung des College-Basketballs
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Basketball ist eine relativ junge Sportart, die im Jahr 1891 von James Naismith, einem kanadischen Sportlehrer, erfunden wurde. Er konzipierte das Spiel als eine Indoor-Aktivität für seine Schüler während der Wintermonate. Das Spiel wurde schnell populär und verbreitete sich an verschiedenen Schulen und Universitäten in Nordamerika.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Frühe Jahre
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Das erste offizielle College-Basketballspiel fand 1895 zwischen der University of Chicago und der University of Iowa statt. Im Jahr 1901 wurde die erste interkollegiale Konferenz gegründet, die Western Conference, später bekannt als die Big Ten Conference. Im Jahr 1906 wurde die erste nationale Meisterschaft von der Amateur Athletic Union (AAU) organisiert, die den College-Basketball bis 1936 dominierte.
      <br/>
      <br/>
      1939 fand das erste NCAA-Turnier statt, bei dem acht Teams um den Titel kämpften. Das Turnier wurde von Oregon gewonnen, das Ohio State mit 46-33 besiegte. Das Turnier entwickelte sich zu einem jährlichen Ereignis, das zunehmend die Aufmerksamkeit von Fans und Medien auf sich zog.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Wachstum und Professionalisierung
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Nach dem Zweiten Weltkrieg nahm der College-Basketball an Popularität und Qualität zu. Neue Stars wie Bill Russell, Wilt Chamberlain, Oscar Robertson und Kareem Abdul-Jabbar tauchten auf, die später auch in der NBA einen Namen machten. Es entstanden neue Wettbewerbe und Konferenzen wie das National Invitation Tournament (NIT), die Atlantic Coast Conference (ACC) und die Southeastern Conference (SEC).
      <br/>
      <br/>
      1957 fand eines der denkwürdigsten Spiele in der Geschichte des College-Basketballs statt, als die North Carolina Tar Heels die Kansas Jayhawks nach drei Verlängerungen mit 54-53 besiegten. Das Spiel gilt als Wendepunkt im College-Basketball und zeigt, wie aufregend und dramatisch das Spiel sein kann.
      <br/>
      <br/>
      Ein weiteres historisches Spiel fand 1966 statt, als die Texas Western Miners die Kentucky Wildcats mit 72-65 besiegten. Das Spiel war wegweisend, weil Texas Western das erste Team war, das ausschließlich aus schwarzen Spielern bestand, während Kentucky ausschließlich weiß war. Das Spiel wird als Symbol für den Kampf um Bürgerrechte und Integration in Amerika angesehen.
      <br/>
      <br/>
      1979 fand das erste live übertragene NCAA-Turnier statt, mit einer Rekordzahl von Zuschauern. Das Finale fand zwischen den Michigan State Spartans und den Indiana State Sycamores statt, angeführt von den zukünftigen NBA-Legenden Magic Johnson und Larry Bird. Michigan State gewann das Spiel 75-64 und gilt als eines der besten Finals überhaupt.
      <br/>
      <br/>
      In den 80er und 90er Jahren setzte sich das Wachstum und die Entwicklung des College-Basketballs fort, mit neuen Regeln, Stilen und Strategien. Neue Rivalitäten entstanden, wie Duke vs. North Carolina, Georgetown vs. Syracuse und UCLA vs. Arizona. Es gab auch neue Stars wie Michael Jordan, Patrick Ewing, Shaquille O'Neal und Tim Duncan, die den College-Basketball auf ein höheres Niveau hoben.
    </MKTypography>
  </MKBox>
</MKBox>













                                              





                                  







<MKBox id="Kapitel 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: College-Basketball Heute
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College-Basketball ist heute eine der beliebtesten und angesehensten Sportarten in Amerika. Jedes Jahr ziehen die Spiele Millionen von Fans in die Stadien und vor die Fernsehbildschirme. College-Basketball ist auch eine wichtige Talentquelle für die NBA, die jedes Jahr Hunderte von Spielern von Universitätsteams rekrutiert.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Wichtige Wettbewerbe und Konferenzen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Steph Curry in Aktion für das Davidson College, brillierend im College-Basketball."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Stephen Curry in Aktion als Spieler für das Davidson College
        </figcaption>
      </figure>
      College-Basketball wird hauptsächlich von der National Collegiate Athletic Association (NCAA) organisiert, die über 1.000 Universitäten und Colleges überwacht. Die NCAA teilt die Teams in drei Divisionen ein, wobei Division I die höchste ist. Division I besteht aus 32 Konferenzen, von denen jede aus geografisch oder historisch verbundenen Teams besteht.
      <br/>
      <br/>
      Die Konferenzen sind wichtig, da sie bestimmen, welche Teams während der regulären Saison von November bis März gegeneinander spielen. Jede Konferenz hat ihren eigenen Zeitplan, ihre eigenen Regeln und ihre eigene Meisterschaft. Die Konferenzmeister verdienen automatisch einen Platz im NCAA-Turnier, dem Höhepunkt des College-Basketballs.
      <br/>
      <br/>
      Das NCAA-Turnier, auch als March Madness bekannt, ist ein Knockout-Turnier, das jedes Jahr im März und April stattfindet. Es besteht aus 68 Teams, von denen 32 Konferenzmeister sind und 36 At-Large-Bids, die von einem Ausschuss aufgrund ihrer Leistungen während der Saison ausgewählt werden. Die Teams werden in vier Regionen eingeteilt und von 1 bis 16 gerankt.
      <br/>
      <br/>
      Das Turnier beginnt mit vier Play-in-Spielen, bei denen die am niedrigsten gerankten Teams um die letzten vier Plätze im Hauptturnier kämpfen. Das Hauptturnier besteht aus sechs Runden, wobei in jeder Runde ein Team eliminiert wird, bis eines übrig bleibt. Die letzten vier Teams, bekannt als das Final Four, spielen an einem neutralen Ort, um den nationalen Meister zu ermitteln.
      <br/>
      <br/>
      Das NCAA-Turnier ist eines der aufregendsten und unvorhersehbarsten Sportereignisse der Welt. Jedes Jahr gibt es Überraschungen, Dramen und Sensationen, die als Upsets, Buzzer-Beater und Cinderella-Geschichten bekannt sind. Das Turnier zieht Millionen von Zuschauern und Wettern an, die versuchen, den Sieger vorherzusagen.
      <br/>
      <br/>
      Neben dem NCAA-Turnier gibt es weitere bedeutende Wettbewerbe im College-Basketball, wie das NIT, CBI, CIT und das NAIA-Turnier. Diese Wettbewerbe bieten Teams die Möglichkeit, die nicht für das NCAA-Turnier qualifiziert sind oder zusätzliche Erfahrungen sammeln möchten.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Top-Programme und angesehene Teams
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      College-Basketball umfasst viele Top-Programme und angesehene Teams, die sich durch ihre Geschichte, Tradition, Erfolg und ihren Einfluss auszeichnen. Einige dieser Programme sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          UCLA Bruins: Das erfolgreichste Programm in der Geschichte des College-Basketballs mit 11 nationalen Titeln, darunter 10 unter Trainer John Wooden in den 60er und 70er Jahren. UCLA hat viele berühmte Spieler hervorgebracht, wie Kareem Abdul-Jabbar, Bill Walton und Reggie Miller.
        </li>
        <li>
          Kentucky Wildcats: Das zweiterfolgreichste Programm in der Geschichte des College-Basketballs mit acht nationalen Titeln und 17 Final-Four-Teilnahmen. Kentucky ist bekannt für seine leidenschaftlichen Fans und seine Fähigkeit, Top-Rekruten anzuziehen.
        </li>
        <li>
          North Carolina Tar Heels: Das dritterfolgreichste Programm in der Geschichte des College-Basketballs mit sechs nationalen Titeln und 20 Final-Four-Teilnahmen. North Carolina hatte viele legendäre Trainer wie Dean Smith und Roy Williams sowie Spieler wie Michael Jordan, James Worthy und Vince Carter.
        </li>
        <li>
          Duke Blue Devils: Eines der dominantesten Programme im modernen College-Basketball mit fünf nationalen Titeln und 16 Final-Four-Teilnahmen seit 1986. Duke ist bekannt für seinen Trainer Mike Krzyzewski, den erfolgreichsten Trainer in der Geschichte des College-Basketballs, sowie Spieler wie Christian Laettner, Grant Hill und Zion Williamson.
        </li>
        <li>
          Kansas Jayhawks: Eines der traditionellsten und einflussreichsten Programme im College-Basketball mit drei nationalen Titeln und 15 Final-Four-Teilnahmen. Kansas ist der Geburtsort des Spiels, da James Naismith sein erster Trainer war. Kansas hatte auch viele großartige Trainer wie Phog Allen, Larry Brown und Bill Self sowie Spieler wie Wilt Chamberlain, Danny Manning und Paul Pierce.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Diese Programme sind nur einige Beispiele für die vielen ausgezeichneten Teams, die den College-Basketball bereichern. Jedes Jahr sorgen neue Herausforderer und Überraschungen für Spannung und Unvorhersehbarkeit im Spiel.
    </MKTypography>
  </MKBox>
</MKBox>










                                              






                                  







<MKBox id="Kapitel 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Vom College zur NBA
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College-Basketball ist nicht nur ein Sport, sondern auch eine Ausbildung. Viele Spieler, die am College-Basketball teilnehmen, streben danach, die NBA zu erreichen, die beste und angesehenste professionelle Basketballliga der Welt. Die NBA zu erreichen ist jedoch nicht einfach und erfordert viel Talent, harte Arbeit und Glück.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Der Weg zur NBA
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Um für die NBA berechtigt zu sein, muss ein Spieler mindestens 19 Jahre alt sein und mindestens ein Jahr vor dem Abschluss der High School die Schule abgeschlossen haben. Das bedeutet, dass die meisten Spieler, die die NBA erreichen wollen, zuerst aufs College gehen und dort mindestens ein Jahr spielen. Dies wird auch als One-and-Done-Regel bezeichnet, da viele Spieler nach nur einem Jahr College in den NBA-Draft eintreten.
      <br />
      <br />
      Der NBA-Draft ist eine jährliche Veranstaltung, bei der die 30 NBA-Teams abwechselnd einen Spieler aus einem Pool verfügbarer Spieler auswählen. Der Draft besteht aus zwei Runden, wobei jedes Team pro Runde einen Pick hat. Die Reihenfolge der Picks wird durch eine Lotterie für Teams bestimmt, die in der vorherigen Saison nicht in die Playoffs eingezogen sind, und durch eine umgekehrte Rangliste für die Playoff-Teams.
      <br />
      <br />
      Der Draft ist ein bedeutsamer Moment für Spieler, da er bestimmt, welches Team ihre Rechte besitzt und wie viel Geld sie verdienen können. Spieler, die in der ersten Runde ausgewählt werden, erhalten einen garantierten Vertrag für mindestens zwei Jahre, während diejenigen, die in der zweiten Runde ausgewählt werden, keine Garantien haben. Spieler, die nicht ausgewählt werden, können versuchen, einen Vertrag durch freie Agentur oder durch den Beitritt zur G-League, der Entwicklungsliga der NBA, zu erhalten.
      <br />
      <br />
      Der Draft ist jedoch keine Garantie für Erfolg in der NBA. Viele hoch gedraftete Spieler erfüllen nicht die Erwartungen oder erleiden Verletzungen. Andererseits gibt es auch viele Spieler, die niedrig gedraftet oder überhaupt nicht gedraftet wurden, aber dennoch in der NBA herausragen. Ein bekanntes Beispiel ist Giannis Antetokounmpo, der 2013 an 15. Stelle ausgewählt wurde und 2021 zum NBA-MVP und Champion ernannt wurde.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Andere professionelle Möglichkeiten
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Nicht alle Spieler, die am College-Basketball teilnehmen, streben die NBA an. Einige Spieler entscheiden sich dafür, ihre akademischen Abschlüsse zu absolvieren und andere Karrieren zu verfolgen. Andere wählen den Weg, in anderen Ländern oder Ligen professionellen Basketball zu spielen.
      <br />
      <br />
      Es gibt viele professionelle Basketballligen auf der ganzen Welt, die unterschiedliche Qualitätsniveaus, Gehälter und Lebensstile bieten. Einige dieser Ligen sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          EuroLeague: Der höchste und angesehenste europäische Vereinswettbewerb mit 18 Teams aus 10 Ländern. Die EuroLeague gilt als die zweitbeste Liga der Welt nach der NBA.
        </li>
        <li>
          Chinese Basketball Association (CBA): Der höchste und beliebteste chinesische Vereinswettbewerb mit 20 Teams aus ganz China. Die CBA ist bekannt für ihre hohen Gehälter und großen Zuschauerzahlen.
        </li>
        <li>
          Australian National Basketball League (NBL): Der höchste und wettbewerbsfähigste australische Vereinswettbewerb mit neun Teams aus Australien und Neuseeland. Die NBL ist bekannt für ihr schnelles und körperbetontes Gameplay.
        </li>
        <li>
          Basketball Africa League (BAL): Der neueste und ehrgeizigste afrikanische Vereinswettbewerb mit 12 Teams aus 12 Ländern. Die BAL wird von der NBA und der FIBA unterstützt und zielt darauf ab, Basketball auf dem Kontinent zu entwickeln.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Diese Ligen sind nur einige Beispiele für die vielen Möglichkeiten, die professionellen Basketballspielern außerhalb der NBA zur Verfügung stehen. Viele Spieler genießen es, in verschiedenen Kulturen zu leben und zu spielen, während sie ihre Leidenschaft für das Spiel weiterverfolgen.
    </MKTypography>
  </MKBox>
</MKBox>














                                              






                                  








<MKBox id="Kapitel 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationale Einflüsse und Möglichkeiten
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Basketball ist ein globaler Sport, der Menschen unterschiedlicher Nationalitäten, Kulturen und Hintergründe zusammenbringt. College-Basketball ist ein hervorragendes Beispiel dafür, da er sowohl ausländische als auch amerikanische Spieler anzieht und Möglichkeiten bietet. In diesem Kapitel werden die internationalen Einflüsse und Möglichkeiten im College-Basketball erkundet.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Ausländische Spieler im College-Basketball
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      College-Basketball hat schon immer ausländische Spieler willkommen geheißen, die ihren eigenen Stil, ihre Fähigkeiten und Perspektiven in das Spiel eingebracht haben. Laut der NCAA gab es in der Saison 2019-2020 über 1.200 ausländische Spieler in Division I, die aus 101 Ländern stammten. Die häufigsten Herkunftsländer waren Kanada, Australien, Nigeria, Frankreich und das Vereinigte Königreich.
      <br />
      <br />
      Ausländische Spieler wählen College-Basketball aus verschiedenen Gründen. Einige möchten ihre akademische Ausbildung mit ihren sportlichen Ambitionen verbinden. Andere zielen darauf ab, sich an die amerikanische Kultur und Lebensweise anzupassen. Wieder andere wollen ihre Chancen erhöhen, die NBA zu erreichen oder ihre Nationalmannschaften zu vertreten.
      <br />
      <br />
      Ausländische Spieler haben einen bedeutenden Einfluss auf den College-Basketball, sowohl individuell als auch kollektiv. Einige der erfolgreichsten und einflussreichsten ausländischen Spieler in der Geschichte des College-Basketballs sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Hakeem Olajuwon: Ein nigerianischer Spieler, der von 1981 bis 1984 für die Houston Cougars spielte. Er führte sein Team zu drei aufeinanderfolgenden Final-Four-Teilnahmen und gewann 1984 die Auszeichnung als nationaler Spieler des Jahres. Er wurde im NBA-Draft 1984 als erster gesamter Pick ausgewählt und wurde einer der größten Center aller Zeiten.
        </li>
        <li>
          Andrew Bogut: Ein australischer Spieler, der von 2003 bis 2005 für die Utah Utes spielte. Er gewann 2005 die Auszeichnung als nationaler Spieler des Jahres und führte sein Team ins Sweet Sixteen. Er wurde im NBA-Draft 2005 als erster gesamter Pick ausgewählt und wurde ein All-Star und Champion.
        </li>
        <li>
          Luka Garza: Ein amerikanischer Spieler mit bosnischen Wurzeln, der von 2017 bis 2021 für die Iowa Hawkeyes spielte. Er gewann zweimal die Auszeichnung als nationaler Spieler des Jahres, 2020 und 2021, und wurde der Rekordhalter seines Colleges in der Punkte-Bestenliste. Er wurde im NBA-Draft 2021 als 52. gesamter Pick ausgewählt und gilt als einer der größten College-Spieler aller Zeiten.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Diese Spieler sind nur einige Beispiele für die vielen ausländischen Spieler, die im College-Basketball herausragten. Jedes Jahr kommen neue Talente hinzu und bereichern das Spiel.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikanische Spieler im Ausland
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Nicht nur ausländische Spieler kommen nach Amerika, um Basketball zu studieren und zu spielen, sondern auch amerikanische Spieler gehen aus denselben Gründen ins Ausland. Einige amerikanische Spieler entscheiden sich dafür, ihre High-School- oder College-Karrieren zu überspringen oder abzukürzen, um professionell in anderen Ländern oder Ligen zu spielen.
      <br />
      <br />
      Die Gründe für diese Entscheidung können variieren, aber einige sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Finanziell: Einige Spieler möchten mit ihrem Talent Geld verdienen, anstatt auf einen NBA-Vertrag oder ein Stipendium zu warten.
        </li>
        <li>
          Athletisch: Einige Spieler möchten sich gegen stärkere Gegner entwickeln, anstatt auf ihrem Niveau zu dominieren.
        </li>
        <li>
          Persönlich: Einige Spieler möchten reisen, neue Kulturen kennenlernen oder familiäre Bindungen aufrechterhalten.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Amerikanische Spieler, die ins Ausland gehen, haben unterschiedliche Erfahrungen und Ergebnisse. Einige haben Erfolg mit ihrem NBA-Traum, während andere eine lange und erfolgreiche Karriere genießen. Einige passen sich gut an ihre neue Umgebung an, während andere mit Sprache, Essen oder Lebensstil kämpfen.
      <br />
      <br />
      Einige Beispiele für amerikanische Spieler, die ins Ausland gegangen sind, sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Brandon Jennings: Ein Spieler, der seine High-School übersprang, um 2008 für Lottomatica Roma in Italien zu spielen. Er wurde im NBA-Draft 2009 als 10. gesamter Pick ausgewählt und spielte 10 Saisons in der NBA.
        </li>
        <li>
          Emmanuel Mudiay: Ein Spieler, der sein College übersprang, um 2014 für die Guangdong Southern Tigers in China zu spielen. Er wurde im NBA-Draft 2015 als 7. gesamter Pick ausgewählt und spielt derzeit für die Utah Jazz.
        </li>
        <li>
          LaMelo Ball: Ein Spieler, der seine High-School und sein College übersprang, um 2019 für die Illawarra Hawks in Australien zu spielen. Er wurde im NBA-Draft 2020 als 3. gesamter Pick ausgewählt und gewann die Auszeichnung als Rookie des Jahres.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Diese Spieler sind nur einige Beispiele für die vielen amerikanischen Spieler, die einen alternativen Weg zum professionellen Basketball gewählt haben. Jedes Jahr globalisieren neue Abenteurer das Spiel.
    </MKTypography>
  </MKBox>
</MKBox>










                                              






                                  



                                
<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Sportstipendien und die Zukunft der Athleten
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sportstipendien bieten zahlreiche Möglichkeiten für junge Athleten, ihr Talent zu zeigen und ihre Fähigkeiten zu entwickeln. Eine der bedeutendsten Möglichkeiten ist der Erhalt eines Sportstipendiums, um auf Hochschulebene zu studieren und zu konkurrieren. In diesem Kapitel werden wir die Bedeutung von Sportstipendien diskutieren und wie Sportstipendien Amerika Ihnen helfen kann, Ihren Traum zu verwirklichen.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Die Bedeutung von Stipendien
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ein Sportstipendium ist finanzielle Unterstützung, die von einer Universität einem Studenten-Athleten zur Verfügung gestellt wird, um Studium und Sport zu kombinieren. Es kann die Kosten für Studiengebühren, Bücher, Unterkunft, Mahlzeiten und andere Ausgaben abdecken. Darüber hinaus kann es auch Vorteile wie akademische Nachhilfe, medizinische Versorgung, Trainingsmöglichkeiten und Ausrüstung bieten.
      <br />
      <br />
      Die Erlangung eines Sportstipendiums ist wettbewerbsfähig, wobei viele Faktoren eine Rolle im Zuteilungsprozess spielen, einschließlich akademischer Leistungen, sportlicher Leistung, Persönlichkeit, Motivation, Führung und Anpassungsfähigkeit. Daher ist es wichtig, sich gut vorzubereiten und sich von der Konkurrenz abzuheben.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Wie Sportstipendien Amerika helfen können
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo von Sportstipendien Amerika, das Sportausrüstung und eine Abschlusskappe zeigt, die den Fokus auf Sportstipendien für Studenten-Athleten repräsentiert."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo von Sportstipendien Amerika
        </figcaption>
      </figure>
      Sportstipendien Amerika ist eine Organisation, die sich auf die Anleitung junger Sporttalente zu Sportstipendien spezialisiert hat. Mit leidenschaftlichen und erfahrenen Experten unterstützt Sportstipendien Amerika Studenten-Athleten auf jedem Schritt der Reise, von der ersten Orientierung bis zur endgültigen Platzierung. Ihre Dienstleistungen umfassen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Ein persönliches Aufnahmegespräch, um die Wünsche, Möglichkeiten und Erwartungen des Studenten-Athleten zu besprechen.</li>
        <li>Eine professionelle Bewertung des sportlichen und akademischen Niveaus des Studenten-Athleten.</li>
        <li>Ein umfangreiches Netzwerk von Kontakten zu Trainern, Universitäten und Hochschulen.</li>
        <li>Effektive Werbung für das Profil und die Videos des Studenten-Athleten bei potenziellen Trainern.</li>
        <li>Expertenberatung zu den besten Optionen basierend auf den Vorlieben, Zielen und dem Budget des Studenten-Athleten.</li>
        <li>Verhandlung der besten Stipendienangebote im Namen des Studenten-Athleten.</li>
        <li>Hilfe bei der Beantragung der erforderlichen Dokumente wie Visum, Zeugnis, Diplom und Zulassungstests.</li>
        <li>Vorbereitung auf das Leben des Studenten-Athleten während seines Stipendiums, einschließlich Kultur, Sprache, Regeln und Tipps.</li>
        <li>Nachsorge und Follow-up während der Stipendiumszeit.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Möchten Sie Stipendienmöglichkeiten erkunden und Ihre sportlichen und akademischen Ziele erreichen? <Link to="/de/Dienstleistungen-Produkte">Sehen Sie sich die vollständige Palette</Link> der von Sportstipendien Amerika angebotenen Dienstleistungen an und vereinbaren Sie noch heute eine Beratung.
    </MKTypography>
  </MKBox>
</MKBox>








        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;