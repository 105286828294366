// Composants matériels @mui
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Composants Material Kit 2 PRO React
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";
import "../../../../H1asH3.css"

// Images
import profilePicture from "assets/images/Profielfoto_Kay_Duit.jpeg";

function Profile() {
  return (
    <MKBox component="section" pt={{ xs: 20, sm: 20,  md: 6}} pb={{ xs: 6, sm: 0}}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
            <MKAvatar src={profilePicture} alt="Photo de profil de Kay Duit, fondateur et propriétaire" size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox 
                display="flex" 
                flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} 
                justifyContent="space-between" 
                alignItems="center" 
                mb={1}
              >
                <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Kay Duit</MKTypography>
                <MKBox display="flex" flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} gap={1}>
                <a href="https://www.linkedin.com/in/kayduit/" target="_blank" rel="noopener noreferrer">
                <MKSocialButton color="linkedin" style={{ width: '140px' }}>
                    <MKBox component="i" color="inherit" mr={0.5} className="fab fa-linkedin" />
                    LinkedIn
                  </MKSocialButton>
                  </a>
                  <a href="https://www.instagram.com/kayduit/" target="_blank" rel="noopener noreferrer">
                  <MKSocialButton color="instagram" style={{ width: '140px' }}>
                    <MKBox component="i" color="inherit" mr={0.5} className="fab fa-instagram" />
                    Instagram
                  </MKSocialButton>
                  </a>
                  <a href="/Podcast">
                  <MKSocialButton color="pinterest" style={{ width: '140px' }}>
                    <MKBox component="i" color="inherit" mr={0.5} className="fas fa-podcast" />
                    Podcast
                  </MKSocialButton>
                  </a>
                </MKBox>
              </MKBox>
              <br />
              <MKBox>
  <MKTypography variant="body1" fontWeight="light" color="text">
    Par une chaude soirée d'été en Amérique, j'étais sur le bord du terrain de soccer, attendant le coup de sifflet pour entrer sur le terrain. Mon cœur battait la chamade, non pas à cause du match à venir, mais à cause de la réalisation de tout le chemin parcouru. Trois ans plus tôt, j'avais pris un avion depuis mon pays d'origine pour les États-Unis, un saut dans l'inconnu à la recherche d'aventure et de l'opportunité de réaliser à la fois mes rêves sportifs et académiques.
    <br /><br />
    Au cours de ces quatre années, j'avais vécu bien plus que des matchs de soccer et des études. Il y avait les dîners tardifs après une grande victoire, les vols pour des matchs à l'extérieur dans des États lointains, les amitiés que j'avais nouées et les innombrables fois où j'avais réalisé à quel point le monde était plus vaste que le petit endroit d'où je venais.
    <br /><br />
    Ce soir-là, alors que l'hymne national retentissait et que les tribunes étaient remplies de milliers de fans en liesse, je pensais à tous les jeunes étudiants du monde entier qui, comme moi, rêvaient de telles opportunités. Et je me suis engagé envers moi-même qu'une fois mon voyage en Amérique terminé, j'offrirais une main secourable à ces étudiants aspirants, peu importe d'où ils venaient, les aidant à réaliser des rêves similaires aux miens.
  </MKTypography>
</MKBox>

              <MKTypography
                component="a"
                href="#"
                variant="body1"
                fontWeight="light"
                color="info"
                mt={3}
                sx={{
                  width: "max-content",
                  display: "flex",
                  alignItems: "center",
                  "& .material-icons-round": {
                    transform: `translateX(3px)`,
                    transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                  },
                  "&:hover .material-icons-round, &:focus .material-icons-round": {
                    transform: `translateX(6px)`,
                  },
                }}
              >
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
