/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post2 from "assets/images/TijdUS.png";
import "../../../../H1asH3.css"


// Images

function Profile() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
            <MKBox 
                display="flex" 
                flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} 
                justifyContent="space-between" 
                alignItems="center" 
    mb={1}
>
    <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Part 3: Back in the Netherlands</MKTypography>

    
</MKBox>
           

              <MKTypography variant="body1" fontWeight="light" color="text">

              <MKTypography variant="h4">
               
                </MKTypography>
                <br />
                Ved tilbakekomsten til Nederland opplevde jeg en blanding av nostalgi og besluttsomhet. Min dype lidenskap for fotball ble nå ledsaget av en fornyet iver etter akademiske utfordringer. Amsterdam ble bakteppet for mitt neste eventyr, der jeg forfulgte en mastergrad i kvantitativ finans, et felt som vekket både min analytiske hjerne og mine ambisjoner.
                <br /><br />
                Etter å ha fullført studiene, begynte jeg å jobbe som konsulent. Selv om verden av tall og analyse fascinerte meg, følte jeg at noe manglet. Ekkoene fra min amerikanske reise fortsatte å gjensoundere inni meg, og minnet meg om den unike muligheten jeg hadde opplevd, en mulighet jeg ønsket for andre.
                <br /><br />
                Følgelig bestemte jeg meg for å bruke mine erfaringer og kunnskap til å veilede andre unge, ambisiøse idrettsutøvere. Før jeg startet dette prosjektet, engasjerte jeg meg med trenere, utøvere, tidligere utøvere og andre i mitt omfattende nettverk i Amerika. Disse diskusjonene hjalp både meg og fremtidige studenter jeg ville støtte, til å forstå den beste tilnærmingen til disse mulighetene.
                <br /><br />
                Under den utfordrende COVID-19-pandemien samarbeidet jeg med et ledende byrå i Amerika. Selv om jeg kunne hjelpe færre internasjonale studenter på grunn av begrenset tilgjengelighet av stipender, brukte jeg min ekspertise til å hjelpe amerikanske studenter. Denne erfaringen utvidet ikke bare nettverket mitt betydelig, men ga også innsikt i det amerikanske stipendmarkedet, som jeg nå kan anvende mer effektivt i Europa.
                <br /><br />
                Utfordringene under pandemien styrket min misjon om å veilede unge talenter mot amerikanske universiteter og idrettsstipender. Til tross for vanskelighetene brakte det en følelse av oppfyllelse jeg aldri hadde opplevd før. Det var min måte å gi tilbake til det samfunnet som en gang ønsket meg velkommen så varmt i USA.
                <br /><br />
                Dette er min historie, og jeg er stolt over at jeg nå kan hjelpe andre med å skrive sine egne historier i landet med uendelige muligheter. Hvis du vil lære mer om selskapet mitt og reisen til klientene mine for å realisere drømmene sine, kan du fortsette å lese på nettstedet mitt. Hvis du har spørsmål eller ønsker å kontakte meg, nøl ikke med å gjøre det.
              </MKTypography>

              <hr style={{ margin: '20px 0'}} />
              <Grid item xs={12} lg={6} mb={{ xs: 3, lg: 0 }}>
            <TransparentBlogCard
              image={post2}
              title="Part 2: My Time in America"
              action={{
                type: "internal",
                route: "/no/Om-meg/Min-tid-i-Amerika",
                color: "info",
                label: "read more",
              }}
            />
          </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}


export default Profile;
