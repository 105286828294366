/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Track and Field1.jpg";
import Image2 from "assets/images/Sports_Images/Track and Field2.jpeg";
import Image3 from "assets/images/Sports_Images/Track and Field3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Friidrott och Studier i Amerika: En Dröm Som Kan Bli Verklighet', isSubheader: false },
  
    { id: 'Kapitel 1', title: 'Kapitel 1: Historia och Utveckling av Friidrott på College-Nivå', isSubheader: false },
    { id: '1.1', title: '1.1 Tidiga År och Ursprung', isSubheader: true },
    { id: '1.2', title: '1.2 Tillväxt och Professionalisering', isSubheader: true },
  
    { id: 'Kapitel 2', title: 'Kapitel 2: Friidrott på College-Nivå Idag', isSubheader: false },
    { id: '2.1', title: '2.1 Stora Tävlingar och Konferenser', isSubheader: true },
    { id: '2.2', title: '2.2 Toppprogram och Prestigefyllda Lag', isSubheader: true },
  
    { id: 'Kapitel 3', title: 'Kapitel 3: Från College till Professionell Nivå', isSubheader: false },
    { id: '3.1', title: '3.1 Vägen till Professionalism', isSubheader: true },
    { id: '3.2', title: '3.2 Relationen med Professionell Friidrott', isSubheader: true },
  
    { id: 'Kapitel 4', title: 'Kapitel 4: Internationella Influenser och Möjligheter', isSubheader: false },
    { id: '4.1', title: '4.1 Utländska Idrottare och Tävlingar', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanska Idrottare Utrikes', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapitel 5: Idrottsstipendier och Framtiden för Idrottare', isSubheader: false },
    { id: '5.1', title: '5.1 Vikten av Stipendier', isSubheader: true },
    { id: '5.2', title: '5.2 Hur Sport Scholarships America Kan Hjälpa', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contents
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Dölj</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                      key={section.id}
                      onClick={() => scrollTo(section.id)}
                      color="secondary"
                      variant="gradient"
                      sx={{ 
                        justifyContent: "flex-start",
                        pl: section.isSubheader ? 4 : 2,
                        textTransform: "none",
                        fontWeight: section.isSubheader ? "normal" : "bold",
                        borderTopLeftRadius: index === 0 ? '20px' : '0',
                        borderTopRightRadius: index === 0 ? '20px' : '0',
                        borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                        borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                        textAlign: "left",
                        width: '100%',
                      }}
                    >
                      {section.title}
                    </MKButton>
                  ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Visa</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="introduction" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Friidrott och Studier i Amerika: En Dröm Som Kan Bli Verklighet
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Löpare i full aktion under Penn State Relays, en framstående track and field-händelse på college."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Aktivitet vid Penn State Relays.
      </figcaption>
    </figure>
    Friidrott är en av de mest populära och betydande sporterna i USA. Miljontals människor utövar friidrott som hobby, som en del av sin skol- eller collegeprogram eller som professionella idrottare. Friidrott är också en sport som erbjuder många möjligheter för unga talanger att utvecklas, tävla och utmärka sig på högsta nivå.
    <br/>
    <br/>
    Ett av de bästa sätten att dra nytta av dessa möjligheter är genom att få ett friidrottsstipendium i USA. Ett friidrottsstipendium är ekonomiskt stöd du får från ett universitet för att studera och delta i friidrott där. Med ett friidrottsstipendium kan du inte bara tjäna din akademiska examen utan också delta i ett av de mest prestigefyllda och konkurrenskraftiga friidrottsprogrammen i världen.
    <br/>
    <br/>
    Men hur får man ett friidrottsstipendium i USA? Vilka är fördelarna och utmaningarna med att studera och tävla på college-nivå? Och hur kan du förbereda dig för en framgångsrik karriär som idrottare efter dina studier? Detta är några av frågorna vi kommer att besvara i den här artikeln.
    <br/>
    <br/>
    Vi kommer också att berätta hur Sportbeursamerika.nl kan hjälpa dig att förverkliga din dröm. Sportbeursamerika.nl är en organisation specialiserad på att guida unga idrottare till idrottsstipendier i USA. Jag har mycket erfarenhet av att hitta den bästa matchningen mellan idrottare och universitet. Jag erbjuder personlig vägledning, professionell rådgivning och praktiskt stöd vid varje steg i processen.
    <br/>
    <br/>
    Om du är intresserad av att få ett friidrottsstipendium i USA är den här artikeln för dig. Läs vidare och upptäck allt du behöver veta om friidrott på college-nivå i USA.
  </MKTypography>
  {/* Lägg till punktlista eller annat innehåll vid behov */}
</MKBox>
{/* Huvudkapitel och delkapitel med platshållartext */}
<MKBox id="Kapitel 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Historia och Utveckling av Friidrott på College-Nivå
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Friidrott på college-nivå har en lång och rik historia i USA. Det började på 1800-talet som ett sätt att främja studenternas fysiska och mentala hälsa. Det utvecklades till en stor sport som lockade miljontals åskådare och producerade många idrottare som senare blev världsberömda.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidiga År och Ursprung
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Den första friidrottstävlingen på universitet ägde rum 1864, när studenter från Yale och Oxford utmanade varandra i en match i London. Tävlingen bestod av fyra grenar: löpning, längdhopp, höjdhopp och stavhopp. Yale vann matchen med 7-3.
      <br/>
      <br/>
      Denna match inspirerade andra universitet att också bilda friidrottslag och tävla mot varandra. År 1873 grundades den första intercollegiate friidrottsföreningen, Intercollegiate Association of Amateur Athletes of America (IAAAA). IAAAA organiserade årliga mästerskap för universitet i östra USA.
      <br/>
      <br/>
      Sent 1800-tal var en viktig era för utvecklingen av friidrott. Det kännetecknades av formalisering av regler och framväxten av inflytelserika personer som James E. Sullivan, medgrundare av Amateur Athletic Union i USA. Han spelade en betydande roll i att popularisera friidrott och organisera stora evenemang. Införandet av de moderna olympiska spelen 1896 gav en internationell plattform åt sporten, vilket ledde till standardisering av regler och grenar. Även tekniska framsteg, såsom förbättringar av skodon och löparbanor, bidrog till utvecklingen av friidrott under denna period.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Tillväxt och Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Under 1900-talet blev friidrott alltmer populärt och viktigt på universiteten. Antalet friidrottsprogram, tävlingar och stipendier ökade. Nivån på idrottarna steg också, delvis tack vare bättre träning, faciliteter och coachning.
      <br/>
      <br/>
      En av faktorerna som bidrog till tillväxten och professionaliseringen av friidrott på college-nivå var grundandet av National Collegiate Athletic Association (NCAA) år 1906. NCAA är en organisation som övervakar idrottsaktiviteterna på mer än 1000 universitet i USA. NCAA reglerar bland annat regler, kvalifikationer, stipendier och sanktioner för friidrott på college-nivå.
      <br/>
      <br/>
      En annan faktor som påverkade utvecklingen av friidrott på college-nivå var relationen till professionell friidrott. Många idrottare som var framgångsrika på college-nivå övergick senare till den professionella cirkeln. Några exempel är Jesse Owens, Carl Lewis, Jackie Joyner-Kersee och Michael Johnson.
    </MKTypography>
  </MKBox>
</MKBox>






                                              





                                  







<MKBox id="Kapitel 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Friidrott på College-Nivå Idag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Friidrott på college-nivå är idag en av de mest populära och prestigefyllda sporterna i USA. Varje år deltar tusentals idrottare i olika tävlingar och evenemang, vilket lockar miljontals fans och medial uppmärksamhet. Friidrott på college-nivå erbjuder också många möjligheter för idrottare att utvecklas, prestera och njuta av sin sport.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Stora Tävlingar och Konferenser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Sydney McLaughlin, som utmärker sig som idrottare för University of Kentucky, i tävlingsuniform."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Sydney McLaughlin studerade vid University of Kentucky
        </figcaption>
      </figure>
      Den huvudsakliga organisationen som reglerar friidrott på college-nivå är NCAA. NCAA delar universitet in i tre divisioner, baserat på deras storlek, budget och nivå av konkurrens. Division I är den högsta divisionen, där de flesta toppuniversiteten och idrottarna är. Divisioner II och III är lägre divisioner, där universitet har färre stipendier och resurser, men lägger mer vikt vid akademisk prestation.
      <br/>
      <br/>
      Inom varje division finns det olika konferenser, som är grupper av universitet som är geografiskt eller historiskt kopplade. Varje konferens organiserar sina egna mästerskap och tävlingar för sina medlemmar. Några av de mest välkända konferenserna är Big Ten, Pac-12, SEC, ACC och Ivy League.
      <br/>
      <br/>
      De viktigaste evenemangen i friidrottssäsongen på college-nivå är NCAA-mästerskapen, som hålls årligen i mars (inomhus) och juni (utomhus). Dessa mästerskap avgör vilka universitet och idrottare som är bäst i varje division och gren. Mästerskapen lockar många åskådare, sponsorer och medial uppmärksamhet.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Toppprogram och Prestigefyllda Lag
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Det finns många universitet kända för sina utmärkta friidrottsprogram. Dessa universitet har en lång tradition av framgång, högkvalitativ coachning och faciliteter samt ett starkt attraktionsvärde för talangfulla idrottare. Några av dessa universitet är:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Oregon: Oregon är ett av de mest framgångsrika universiteten inom friidrott, särskilt inom löpning. Oregon har vunnit över 30 NCAA-titlar och har producerat många kända idrottare, såsom Steve Prefontaine, Alberto Salazar, Galen Rupp och Matthew Centrowitz.
        </li>
        <li>
          USC: USC är ett annat toppuniversitet inom friidrott, med fokus på sprint, hopp och kast. USC har vunnit över 25 NCAA-titlar och har producerat många olympiska medaljörer, såsom Quincy Watts, Andre De Grasse och Michael Norman.
        </li>
        <li>
          Arkansas: Arkansas är ett dominerande universitet inom friidrott, särskilt under inomhussäsongen. Arkansas har vunnit över 40 NCAA-titlar, varav över 30 är inomhus. Arkansas är känt för sin bredd och mångsidighet i olika grenar.
        </li>
        <li>
          Texas: Texas är ett av de mest mångsidiga universiteten inom friidrott, med en bra balans mellan herr- och damlag och mellan individuella och stafettgrenar. Texas har vunnit över 20 NCAA-titlar och har producerat många världsklassidrottare, såsom Sanya Richards-Ross, Trey Hardee, Michelle Carter och Courtney Okolo.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dessa universitet erbjuder inte bara en hög nivå av konkurrens och träning för sina idrottare utan också en fantastisk atmosfär och kultur. Idrottarna är en del av en tätt sammanhållen gemenskap som stödjer och motiverar dem att nå sin fulla potential.
    </MKTypography>
  </MKBox>
</MKBox>











                                              






                                  








<MKBox id="Kapitel 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Från College till Professionell Nivå
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Friidrott på college-nivå är inte bara en sport utan också en träningsplats för idrottare som aspirerar på en professionell karriär. Många idrottare som har presterat på college-nivå har senare övergått till den professionella cirkeln. Friidrott på college-nivå erbjuder idrottare möjligheten att utvecklas, uppleva och nätverka med andra idrottare, tränare och organisationer.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Vägen till Professionalism
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      För många idrottare är målet att bli professionell idrottare efter sina studier. Detta innebär att utöva sin sport som ett yrke och tjäna pengar genom prestationer, kontrakt och sponsoravtal. För att bli en professionell idrottare måste idrottare uppfylla flera villkor, såsom:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Ha en hög prestandanivå och potential. Professionella idrottare måste kunna tävla med världens bästa idrottare och kontinuerligt förbättra sig och anpassa sig till förändrade förhållanden.
        </li>
        <li>
          Bibehålla god hälsa och kondition. Professionella idrottare måste ta hand om sina kroppar och skydda sig mot skador, sjukdomar och överträning. De måste också hitta en bra balans mellan träning, vila och näring.
        </li>
        <li>
          Ha bra vägledning och stöd. Professionella idrottare bör kunna lita på ett team av experter för att hjälpa dem med teknik, taktik, mental förberedelse, juridiska frågor, ekonomiska frågor och marknadsföring.
        </li>
        <li>
          Ha bra planering och strategi. Professionella idrottare måste veta vilka mål de vill uppnå, vilka tävlingar de vill delta i, hur de vill kvalificera sig för stora evenemang och hur de vill hantera sin karriär.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Friidrott på college-nivå kan hjälpa idrottare att uppfylla dessa villkor. Det erbjuder idrottare chansen att visa upp sin talang, förbättra sina färdigheter, öka sin erfarenhet och utöka sitt nätverk. Friidrott på college-nivå kan också fungera som en trampolin till den professionella nivån genom att exponera idrottare för höga nivåer av konkurrens, medial uppmärksamhet och allmänt intresse.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Relationen med Professionell Friidrott
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Friidrott på college-nivå har ett nära förhållande till professionell friidrott. De två nivåerna påverkar varandra på olika sätt, såsom:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Talangutveckling: Friidrott på college-nivå är en stor källa till talang för professionell friidrott. Många professionella idrottare började som college-idrottare och lade grunden för sin senare framgång där.
        </li>
        <li>
          Talangscouting: Professionella idrottsorganisationer övervakar noggrant college-idrott för att upptäcka och locka till sig potentiella stjärnor. De erbjuder college-idrottare kontrakt, sponsoravtal och vägledning för att locka dem att bli professionella.
        </li>
        <li>
          Talangutbyte: Friidrott på college-nivå är också öppen för professionella idrottare som fortfarande studerar eller återvänder till skolan. Vissa professionella idrottare väljer att slutföra eller återuppta sina studier samtidigt som de fortsätter sin idrottskarriär.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Friidrott på college-nivå är därför inte bara ett slutmål utan också en trampsten för idrottare som strävar efter en professionell karriär. Det erbjuder idrottare möjligheten att förbereda sig för den professionella nivån, men också att utforska andra vägar.
    </MKTypography>
  </MKBox>
</MKBox>







                                              






                                  







<MKBox id="Kapitel 4" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Kapitel 4: Internationella Influenser och Möjligheter
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Collegespår och fält är inte bara en amerikansk sport utan också en internationell sådan. Den påverkas av och erbjuder möjligheter för idrottare från olika länder och kulturer. Collegespår och fält är en sport som speglar mångfalden och sammanlänkningen i världen.
    </MKTypography>

    <MKBox id="4.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.1 Utländska Idrottare och Tävlingar
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image3}
                    alt="Den berömda Oregon-atletiska arenan, hem för många collegetrack- och fälthändelser."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal3(Image3)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Oregon-atletiska arenan, en ikon för collegeträningsläger.
                </figcaption>
            </figure>
            Ett sätt på vilket collegespår och fält är internationellt är genom närvaron och prestationerna från utländska idrottare. Varje år kommer hundratals idrottare från andra länder till USA för att studera och tävla på collegenivå. Dessa idrottare för med sig sin egen talang, stil och kultur till amerikanska universitet och berikar spår- och fältgemenskapen med sina perspektiv och erfarenheter.
            <br/>
            <br/>
            Utländska idrottare har också en betydande inverkan på resultaten och rankingarna inom collegespår och fält. Många utländska idrottare är bland de främsta inom sin gren och vinner ofta medaljer, titlar och rekord. Vissa utländska idrottare är till och med så bra att de överträffar eller utmanar amerikanska idrottare.
            <br/>
            <br/>
            Exempel på framgångsrika utländska idrottare i amerikanska collegetävlingar inkluderar:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Andre De Grasse: en kanadensisk sprinter som utmärkte sig vid Coffeyville Community College och University of Southern California (USC), där han vann flera NJCAA- och NCAA-titlar i sprintgrenar. Hans prestationer vid USC etablerade hans rykte som en ledande sprinter. Efter sin collegelöpning vann De Grasse flera olympiska medaljer, inklusive guld på 200 meter vid OS i Tokyo 2020, vilket visade honom som en av världens främsta sprinters.
                </li>

                <li>
                    Kerron Clement: en Trinidad-amerikansk idrottare som utmärkte sig på 400 meter häck. Han studerade vid University of Florida, där han vann NCAA-titlar och utvecklades till en världsklass häcklöpare. Clement erövrade guld vid OS 2008 och 2016 och har flera VM-titlar på sitt namn, vilket gör honom till en av de mest framgångsrika häcklöparna i sin generation.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Utländska idrottare påverkar inte bara collegespår och fält utan också andra internationella tävlingar och evenemang. Många utländska idrottare som tävlade på collegenivå deltar senare i olympiska spelen, världsmästerskapen eller andra regionala eller kontinentala mästerskap. De representerar sitt eget land men har också band till sitt amerikanska universitet.
        </MKTypography>
    </MKBox>
    <MKBox id="4.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.2 Amerikanska Idrottare Utrikes
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Ett annat sätt på vilket collegespår och fält är internationellt är genom de möjligheter och utmaningar det erbjuder för amerikanska idrottare att tävla utomlands. Många amerikanska idrottare som har tävlat på collegenivå får chansen att vidga sina vyer och testa sina färdigheter mot andra idrottare från olika länder och kulturer.
            <br/>
            <br/>
            En av de främsta möjligheterna för amerikanska idrottare att tävla utomlands är naturligtvis olympiska spelen. OS är världens största och mest prestigefyllda idrottsevenemang, där tusentals idrottare från över 200 länder samlas för att tävla om guld, silver och brons. OS är också en chans för amerikanska idrottare att representera sitt land, visa sin patriotism och dela sin nationella stolthet.
            <br/>
            <br/>
            En annan viktig händelse för amerikanska idrottare att tävla utomlands är VM i friidrott. VM i friidrott är det mest betydande friidrottsevenemanget efter OS, där världens bästa idrottare möts för att tävla om världsmästartiteln. VM i friidrott är också en chans för amerikanska idrottare att mäta sin nivå, bränsle sin rivalitet och etablera sitt rykte.
            <br/>
            <br/>
            Förutom dessa stora evenemang finns det också andra möjligheter för amerikanska idrottare att tävla utomlands, som Diamond League, Continental Tour, World Athletics Relays eller andra regionala eller kontinentala mästerskap. Dessa evenemang erbjuder amerikanska idrottare möjligheten att öka sin erfarenhet, sina intäkter och sin fanbas.
            <br/>
            <br/>
            Collegespår och fält spelar en viktig roll för utvecklingen och framgången för amerikanska idrottare som tävlar utomlands. Det förbereder idrottare för internationell nivå genom att erbjuda dem högkvalitativ träning, coaching och tävling. Collegespår och fält stödjer också idrottare som tävlar utomlands genom att erbjuda dem ett nätverk av kontakter, sponsorer och fans.
        </MKTypography>
    </MKBox>
</MKBox>










                                              






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Idrottsstipendier och Framtiden för Idrottare
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrottsstipendier erbjuder många möjligheter för unga idrottare att visa upp sin talang och utveckla sina färdigheter. En av de mest betydelsefulla möjligheterna är att få ett idrottsstipendium för att studera och tävla på collegenivå. I det här kapitlet kommer vi att diskutera vikten av idrottsstipendier och hur Sport Scholarships America kan hjälpa dig att förverkliga din dröm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vikten av Stipendier
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ett idrottsstipendium är ekonomiskt stöd som tillhandahålls av ett universitet till en idrottare för att kombinera studier och idrott. Det kan täcka kostnaderna för undervisning, böcker, boende, måltider och andra utgifter. Dessutom kan det erbjuda fördelar som akademisk handledning, sjukvård, träningsanläggningar och utrustning.
      <br />
      <br />
      Att få ett idrottsstipendium är konkurrenskraftigt, med många faktorer som spelar en roll i tilldelningsprocessen, inklusive akademiska prestationer, idrottsprestationer, personlighet, motivation, ledarskap och anpassningsförmåga. Därför är det viktigt att förbereda sig väl och skilja sig från konkurrensen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hur Sport Scholarships America Kan Hjälpa
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotyp för Sport Scholarships America, med sportutrustning och en studentmössa, som representerar fokus på idrottsstipendier för idrottare."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotyp för Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America är en organisation specialiserad på att vägleda unga idrottstalanger till idrottsstipendier. Med passionerade och erfarna experter stöder Sport Scholarships America idrottare i varje steg av resan, från initial orientering till slutlig placering. Deras tjänster inkluderar:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intagningsintervju för att diskutera idrottarens önskemål, möjligheter och förväntningar.</li>
        <li>En professionell utvärdering av idrottarens akademiska och idrottsliga nivå.</li>
        <li>Ett omfattande nätverk av kontakter med tränare, universitet och högskolor.</li>
        <li>Effektiv marknadsföring av idrottarens profil och videor till potentiella tränare.</li>
        <li>Expertrådgivning om de bästa alternativen baserat på idrottarens preferenser, mål och budget.</li>
        <li>Förhandling om de bästa stipendieerbjudandena för idrottaren.</li>
        <li>Hjälp med att ansöka om nödvändiga dokument, såsom visum, betyg, examensbevis och antagningsprov.</li>
        <li>Förberedelse för idrottarens liv under stipendieperioden, inklusive kultur, språk, regler och tips.</li>
        <li>Uppföljning och uppföljning under stipendieperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vill du utforska möjligheter till stipendier och uppnå dina idrottsliga och akademiska mål? <Link to="/sv/Tjänster-Produkter">Se det fullständiga utbudet</Link> av tjänster som erbjuds av Sport Scholarships America och boka en konsultation idag.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;