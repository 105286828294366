/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


// @mui material components
import React, { useState } from 'react'; // Step 1: Import useState


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Logos/NCAA.png";
import post3 from "assets/images/Logos/NAIA.png";



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {

  const articleStructure = [
    { id: 'intro', title: 'NJCAA: A Porta de Entrada para Grandes Universidades e a NCAA', isSubheader: false },
  
    { id: 'Capítulo 1', title: 'Origem e Crescimento da NJCAA', isSubheader: true },
    { id: '1.1', title: 'As Três Divisões da NJCAA', isSubheader: true },
    { id: '1.2', title: 'Transição para Grandes Universidades e a NCAA', isSubheader: true },
  
    { id: 'Capítulo 2', title: 'O Papel dos Acadêmicos', isSubheader: true },
    { id: '2.1', title: 'Finanças e Visibilidade', isSubheader: true },
    { id: '2.2', title: 'Desafios e o Futuro', isSubheader: true },
    { id: '2.3', title: 'Conclusão', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
     <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contents
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Esconder</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="intro" mb={3}>

<MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>NJCAA: A Porta de Entrada para Grandes Universidades e a NCAA</MKTypography>
</MKBox>

<MKBox id="Capítulo 1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Origem e Crescimento da NJCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
A National Junior College Athletic Association (NJCAA) foi fundada em 1938 e desde então cresceu para se tornar um jogador proeminente no cenário esportivo universitário americano. A organização oferece oportunidades esportivas para faculdades juniores, instituições menores de dois anos que proporcionam aos alunos um caminho para o ensino superior e excelência atlética.

<br />
<br />
Em seus primeiros anos, a NJCAA tinha um escopo limitado, mas cresceu e se adaptou para atender às necessidades de um corpo discente diversificado e em constante mudança. O foco está em fornecer acesso à competição atlética enquanto também se promove o progresso acadêmico.

</MKTypography>

<MKBox id="1.1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
As Três Divisões da NJCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
A NJCAA é dividida em três divisões, cada uma projetada para atender às diferentes necessidades e oportunidades dos estudantes-atletas:

<br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Divisão I:{' '}
</MKTypography>
Esta divisão inclui escolas capazes de oferecer bolsas de estudo atléticas completas, resultando em um nível mais alto de competição. Os estudantes-atletas aqui têm a oportunidade de desenvolver suas habilidades e competir em um nível intensivo, muitas vezes como um trampolim para as divisões da NCAA.

<br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Divisão II:{' '}
</MKTypography>
Aqui encontramos um equilíbrio entre competição atlética e responsabilidades acadêmicas, com escolas autorizadas a oferecer bolsas de estudo parciais. Isso garante um equilíbrio saudável e dá aos estudantes-atletas a oportunidade de se destacarem em seu esporte, enquanto também são preparados para o sucesso acadêmico.   <br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Divisão III:{' '}
</MKTypography>
Nesta divisão, o foco está em fornecer oportunidades esportivas competitivas sem bolsas de estudo atléticas. Os estudantes-atletas têm a oportunidade de participar de esportes enquanto se envolvem totalmente na vida acadêmica, resultando em uma experiência universitária bem arredondada.  </MKTypography>


<MKBox id="1.2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Transição para Grandes Universidades e a NCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
A NJCAA desempenha um papel fundamental como um trampolim para estudantes-atletas que desejam continuar suas carreiras acadêmicas e atléticas em instituições maiores de quatro anos, incluindo aquelas na NCAA. Muitos estudantes-atletas começam sua jornada na NJCAA para desenvolver suas habilidades, melhorar qualificações acadêmicas e se destacar para recrutadores de programas maiores.


<br />
<br />
Ao provar seu valor no ambiente competitivo da NJCAA, os estudantes-atletas podem atrair a atenção de escolas da NCAA e potencialmente ganhar bolsas de estudo para continuar sua educação e carreira esportiva. Este processo não é apenas benéfico para os atletas, mas também contribui para fortalecer programas esportivos em todo o país.


</MKTypography>






                                  






<MKBox id="Capítulo 2" mb={3}>
  
  
  <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              O Papel dos Acadêmicos
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              A NJCAA está fortemente comprometida com o sucesso acadêmico e o desenvolvimento de seus estudantes-atletas. Existem rigorosos padrões acadêmicos, e as escolas afiliadas fornecem orientação essencial para garantir que os atletas se destaquem tanto na sala de aula quanto em seu esporte. Isso ilustra o papel crucial de uma abordagem equilibrada dentro do cenário esportivo universitário, embora o nível possa ser ligeiramente inferior ao de algumas outras instituições educacionais.
<br />
                <br />
                </MKTypography>

                <MKBox id="2.1" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Finanças e Visibilidade
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              Apesar dos recursos financeiros serem mais limitados em comparação com a NCAA, a NJCAA desempenha um papel crucial no apoio aos programas atléticos e na promoção de oportunidades para estudantes-atletas. A organização também trabalha para aumentar sua visibilidade e alcance com o objetivo de alcançar e inspirar mais estudantes e atletas.


<br />
                </MKTypography>

                <MKBox id="2.2" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Desafios e o Futuro
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              A NJCAA, assim como outras organizações esportivas, enfrenta desafios, incluindo navegar por questões de igualdade no esporte e apoiar a saúde mental dos estudantes-atletas. No entanto, com seu compromisso em promover oportunidades e apoiar os estudantes-atletas, a organização está bem posicionada para continuar fazendo um impacto positivo.



                </MKTypography>

                <MKBox id="2.3" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Conclusão
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              A National Junior College Athletic Association é um componente essencial do cenário esportivo universitário americano, oferecendo oportunidades únicas para estudantes-atletas competirem, aprenderem e crescerem. Com seu compromisso com a excelência acadêmica e atlética, a NJCAA continua sendo uma força influente na formação do futuro de jovens atletas na América.








<br />


                </MKTypography>


              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="NCAA"
                    description="NCAA: O Coração do Esporte Universitário na América"
                    action={{
                      type: "internal",
                      route: "/pt/NCAA",
                      color: "info",
                      label: "Leia Mais",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="NAIA"
                    description="A NAIA: Uma Rota Alternativa no Esporte Universitário na América"
                    action={{
                      type: "internal",
                      route: "/pt/NAIA",
                      color: "info",
                      label: "Leia Mais",
                     }}
                    />
                  </Grid>
                </Grid>
  
              </Grid>
          </Grid>
        </Container>
      </MKBox>
    );
  }
  
  export default Beschrijving;