/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Rowing1.jpg";
import Image2 from "assets/images/Sports_Images/Rowing2.jpg";
import Image3 from "assets/images/Sports_Images/Rowing3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images

import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: "Studiare e Remare in America: Un'Opportunità Unica per i Giovani Rematori", isSubheader: false },
  
    { id: 'Capitolo 1', title: "Capitolo 1: Storia e Sviluppo del Canottaggio in America", isSubheader: false },
    { id: '1.1', title: "1.1 Gli Anni Iniziali e il Ruolo dei College", isSubheader: true },
    { id: '1.2', title: "1.2 Crescita e Tradizioni", isSubheader: true },
  
    { id: 'Capitolo 2', title: "Capitolo 2: Il Canottaggio al Livello Universitario Oggi", isSubheader: false },
    { id: '2.1', title: "2.1 Principali Competizioni e Conferenze", isSubheader: true },
    { id: '2.2', title: "2.2 Programmi e Squadre Leader", isSubheader: true },
  
    { id: 'Capitolo 3', title: "Capitolo 3: Dal College all'Elite", isSubheader: false },
    { id: '3.1', title: "3.1 Il Percorso verso la Vetta", isSubheader: true },
    { id: '3.2', title: "3.2 Opportunità Professionali e Internazionali", isSubheader: true },
  
    { id: 'Capitolo 4', title: "Capitolo 4: Influenze Internazionali e Diversità", isSubheader: false },
    { id: '4.1', title: "4.1 Atleti Internazionali nel Canottaggio Universitario", isSubheader: true },
    { id: '4.2', title: "4.2 Canottieri Americani all'Estero", isSubheader: true },
  
    { id: 'Capitolo 5', title: "Capitolo 5: Borse di Studio Sportive e il Futuro degli Atleti", isSubheader: false },
    { id: '5.1', title: "5.1 L'Importanza delle Borse di Studio", isSubheader: true },
    { id: '5.2', title: "5.2 Come Sport Scholarships America Può Aiutare", isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indice
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Nascondi</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostra</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>

























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Studiare e Remare in America: Un'Opportunità Unica per i Giovani Rematori
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Rematori in azione durante il campionato nazionale universitario di canottaggio."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Rematori in azione durante il campionato nazionale
      </figcaption>
    </figure>
    Il canottaggio è uno degli sport più antichi e prestigiosi negli Stati Uniti. Sin dal XIX secolo, il canottaggio di alto livello è stato praticato nelle università americane, dove i rematori non solo sviluppano la propria forza fisica e mentale, ma anche le loro capacità accademiche e sociali. Studiare e remare in America è un'opportunità unica per i giovani rematori che vogliono perseguire i loro sogni atletici ricevendo allo stesso tempo un'educazione di valore ed avendo un'esperienza indimenticabile. In questo articolo, vi diremo tutto ciò che c'è da sapere sul canottaggio in America, dalla sua storia e tradizioni alle opportunità e sfide. Spiegheremo anche come Sportbeursamerika.nl può aiutarvi a ottenere una borsa di studio per il canottaggio e prepararvi per la vostra avventura.
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 1: Storia e Sviluppo del Canottaggio in America
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    In questo capitolo, approfondiamo la affascinante storia del canottaggio negli Stati Uniti. Esaminiamo come questo sport si sia diffuso attraverso l'oceano dall'Inghilterra ed è diventato parte integrante della cultura universitaria americana. Dalle prime gare di canottaggio sul lago Winnipesaukee alle storiche rivalità tra prestigiose università, esaminiamo lo sviluppo del canottaggio come sport che incarna sia l'agilità fisica che il orgoglio accademico.
  </MKTypography>
  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Gli Anni Iniziali e il Ruolo dei College
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Il canottaggio come sport ha origini in Inghilterra nel XVIII secolo quando si svolgevano gare tra rematori professionisti sul Tamigi. Queste gare attiravano grandi pubblici e scommesse, rendendo rapidamente popolare il canottaggio. Nel XVIII e XIX secolo, il canottaggio si diffuse in altri paesi, incluso gli Stati Uniti. La prima gara di canottaggio americana si svolse nel 1843 sul lago Winnipesaukee in New Hampshire. Nel 1852, la prima gara intercollegiale di canottaggio si svolse tra Harvard e Yale sul lago Winnipesaukee. Questo fu anche il primo evento sportivo intercollegiale negli Stati Uniti. Da allora, il canottaggio è diventato una parte significativa dello sport universitario americano.
      <br />
      <br />
      Il ruolo dei college nella promozione del canottaggio era cruciale. I college vedevano nel canottaggio un modo per instillare disciplina, spirito di squadra e leadership nei loro studenti, mentre miglioravano anche il loro prestigio e la loro reputazione. I college investivano una quantità significativa di denaro e risorse nella costruzione di palestre per le barche, nell'acquisizione di barche e nell'attrarre allenatori. Organizzavano anche regolarmente competizioni contro altri college, sia a livello regionale che nazionale. Queste competizioni erano spesso molto frequentate da studenti, ex studenti, media e sponsor, creando un grande interesse e coinvolgimento nello sport.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crescita e Tradizioni
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Il canottaggio è cresciuto costantemente in popolarità e prestigio nella seconda metà del XIX secolo e nella prima metà del XX secolo. Sono emerse varie competizioni regionali e nazionali, come gli Eastern Sprints, l'IRA National Championship Regatta, il Dad Vail Regatta e l'Head of the Charles Regatta. I rematori americani hanno anche partecipato a eventi internazionali, tra cui l'Henley Royal Regatta in Inghilterra e i Giochi Olimpici. I rematori americani spesso hanno ottenuto risultati impressionanti, vincendo l'oro per otto Giochi Olimpici consecutivi tra il 1920 e il 1956.
      <br />
      <br />
      Il canottaggio ha sviluppato una ricca cultura e tradizione nei college americani. Ogni università aveva i propri colori, mascotte, canzoni e rituali associati al canottaggio. Alcune tradizioni erano giocose o simboliche, come gettare il timoniere in acqua dopo una vittoria o indossare una cravatta o una spilla speciale. Altre tradizioni erano serie o cerimoniali, come cantare l'inno dell'alma mater o tenere un memoriale per i rematori deceduti. Queste tradizioni hanno contribuito al senso di orgoglio, cameratismo e identità tra i rematori.
    </MKTypography>
  </MKBox>
</MKBox>






                                  







<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 2: Il Canottaggio al Livello Universitario Oggi
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    In questo capitolo, ci focalizziamo sul canottaggio contemporaneo al livello universitario in America. Esaminiamo come lo sport si sia evoluto in una parte altamente competitiva e prestigiosa del panorama sportivo collegiale. Qui discutiamo delle principali competizioni e conferenze, della struttura delle divisioni NCAA e diamo un'occhiata ai principali programmi universitari di canottaggio. Questa sezione mette in luce lo stato attuale del canottaggio nelle università americane e le opportunità uniche che offre ai giovani atleti.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Principali Competizioni e Conferenze
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="La squadra di canottaggio di Stanford festeggia la vittoria del campionato nazionale."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          I rematori di Stanford festeggiano il campionato nazionale
        </figcaption>
      </figure>
      Il canottaggio al livello universitario rimane uno sport altamente competitivo e prestigioso negli Stati Uniti oggi. Ci sono più di 300 università che offrono programmi di canottaggio, con circa 150 che partecipano alla NCAA (National Collegiate Athletic Association), l'organizzazione che sovrintende agli sport universitari. La NCAA divide le università in tre divisioni in base al numero di studenti, al budget e al livello dello sport. La Divisione I è la divisione più alta, con i programmi di canottaggio più robusti e competitivi. Le Divisioni II e III hanno programmi di canottaggio meno numerosi e più piccoli, ma sono comunque altamente competitivi.
      <br />
      <br />
      All'interno di ogni divisione, ci sono varie conferenze, che sono gruppi di università che sono geograficamente o storicamente collegate. Ogni conferenza organizza il proprio campionato, dove le migliori squadre si qualificano per i campionati nazionali. Le principali conferenze per il canottaggio includono il Big Ten, il Pac-12, l'Ivy League, l'ACC e il Big 12. Queste conferenze spesso hanno i programmi di canottaggio più di successo e influenti del paese.
      <br />
      <br />
      Oltre ai campionati di conferenza, ci sono altre regate nazionali significative a cui le università possono partecipare. La più prestigiosa è il Campionato di Canottaggio Femminile NCAA, tenutosi dal 1997 per le rematrici di tutti e tre le divisioni. Per i rematori maschi, non c'è un campionato NCAA equivalente, ma c'è il Campionato Nazionale di Canottaggio IRA, tenutosi dal 1895 per entrambe le squadre varsity e lightweight. Altre note regate nazionali includono il Dad Vail Regatta, il San Diego Crew Classic, il Campionato Nazionale di Canottaggio ACRA e il CRASH-B Sprints.
    </MKTypography>
  </MKBox>

  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programmi e Squadre Leader
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Il canottaggio al livello universitario ha una lunga storia di rivalità, eccellenza e innovazione. Molte università si sono distinte attraverso i loro programmi di canottaggio, sia in termini di prestazioni che di sviluppo. Alcune di queste università includono:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Università di Washington: Gli Huskies sono noti per il loro potente e rispettato programma di canottaggio. Con più campionati nazionali nel canottaggio maschile e una storia di produzione di medagliati olimpici, tra cui la leggendaria "Boys in the Boat" che hanno vinto l'oro nel 1936. Gli Huskies sono celebrati per il loro rigido allenamento, forte spirito di squadra e allenatori influenti come Al Ulbrickson.
        </li>
        <li>
          Università di Yale: Come uno dei pionieri del canottaggio intercollegiale, i Bulldogs di Yale hanno un programma di canottaggio antico e prestigioso. La loro storia include diversi titoli nazionali e un significativo contributo al canottaggio americano a livello olimpico. Yale è anche conosciuta per la sua eccellenza accademica e la storica rivalità con Harvard.
        </li>
        <li>
          Università della California, Berkeley: Gli Orsi Dorati hanno un programma di canottaggio di successo e versatile, distinto dai loro titoli nazionali e dai partecipanti olimpici. Il programma è noto per la sua diversità, coinvolgimento nella comunità e allenamento dedicato.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>








                                  








<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 3: Dal College all'Elite
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    In questo capitolo, esploriamo il viaggio dei rematori universitari verso i ranghi élite del mondo del canottaggio. Esaminiamo come le esperienze e le competenze acquisite dai rematori al livello universitario li preparino per livelli superiori di competizione, inclusa la partecipazione alle squadre nazionali e ai Giochi Olimpici. Questo capitolo mette in evidenza la transizione dal canottaggio universitario alle fasi professionali e internazionali, concentrandosi sui percorsi seguiti dagli atleti, le sfide che affrontano e le storie di successo di coloro che raggiungono il vertice. Esaminiamo anche come i programmi universitari di canottaggio identifichino e sviluppino i talenti, aprendo la strada per futuri olimpionici e campioni del mondo.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Il Percorso verso la Vetta
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Il canottaggio al livello universitario non è solo una sfida sportiva ma anche un potenziale trampolino di lancio verso i ranghi élite del mondo del canottaggio. Molti rematori che hanno praticato il canottaggio al livello universitario passano alle squadre nazionali e al canottaggio olimpico. Questo perché il canottaggio universitario offre un'eccellente preparazione in termini di tecnica, tattica, condizionamento fisico e mentale e spirito di squadra. Inoltre, il canottaggio universitario offre l'opportunità di distinguersi agli occhi degli scout e degli allenatori delle associazioni nazionali e delle selezioni.
      <br />
      <br />
      Ci sono numerosi esempi di rematori che sono passati dal college all'élite. Un esempio recente di una rematrice che ha vinto una medaglia olimpica dopo la sua carriera universitaria è Madison Mailey. Mailey, una rematrice All-American alla Northeastern University, laureatasi nel 2018, ha vinto una medaglia d'oro alle Olimpiadi di Tokyo nell'otto femminile per il Canada. Lei e i suoi compagni di squadra hanno preso un vantaggio iniziale e hanno respinto un attacco tardivo dalla Nuova Zelanda, vincendo per un margine di soli 0,91 secondi.
    </MKTypography>
  </MKBox>

  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Opportunità Professionali e Internazionali
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Il canottaggio universitario apre le porte a opportunità professionali e internazionali per i rematori. Oltre alla potenziale partecipazione olimpica, i rematori universitari possono utilizzare le loro competenze per competere in prestigiose regate internazionali come la Henley Royal Regatta o la World Rowing Cup. Questi eventi forniscono una piattaforma per confrontarsi con i migliori del mondo e acquisire esperienza in un contesto internazionale.
      <br />
      <br />
      Inoltre, c'è un percorso verso il canottaggio professionale dove laureati universitari possono unirsi a squadre professionali e partecipare a competizioni globali. Questo offre una carriera a tempo pieno nel canottaggio con un allenamento intensivo e l'opportunità di competere al più alto livello.
      <br />
      <br />
      Per coloro interessati a una carriera da allenatore, il canottaggio universitario fornisce una solida base. Molti allenatori di successo nel canottaggio sono ex atleti universitari che utilizzano la loro esperienza e competenza per guidare nuove generazioni di rematori. Questo percorso di carriera può portare a posizioni con squadre nazionali e club di canottaggio in tutto il mondo.
      <br />
      <br />
      In sintesi, le esperienze acquisite nel canottaggio universitario possono portare a una serie di opportunità professionali e internazionali, dagli atleti che perseguono una carriera professionale a coloro che continuano lo sport in un ruolo di allenatore.
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  






<MKBox id="Capitolo 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 4: Influenze Internazionali e Diversità
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Questo capitolo mette in luce le dinamiche internazionali e la diversità all'interno del canottaggio universitario negli Stati Uniti. Esploriamo come gli studenti atleti internazionali arricchiscano lo sport con le loro esperienze e prospettive uniche, e come i canottieri americani trovino opportunità per remare all'estero e svilupparsi ulteriormente. Queste interazioni contribuiscono a un'esperienza culturale più ricca e allargano gli orizzonti sia dei canottieri americani che internazionali. Approfondiamo i vari percorsi che gli studenti internazionali intraprendono per remare a livello universitario negli Stati Uniti, i vantaggi di queste esperienze e l'impatto che hanno sullo sport e sui suoi partecipanti. Inoltre, ci concentriamo sulle opportunità per i canottieri americani di acquisire esperienza internazionale, tra cui programmi di scambio di studio, ritiri di allenamento internazionali e opportunità di coaching. Questo capitolo sottolinea l'importanza delle influenze internazionali nell'arricchire il canottaggio universitario e promuovere lo scambio culturale e la comprensione reciproca.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Atleti Internazionali nel Canottaggio Universitario
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Barche da canottaggio pronte per l'inizio di una gara universitaria."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Barche pronte per l'inizio di una gara
        </figcaption>
      </figure>
      Il canottaggio universitario non è solo un affare americano, ma anche internazionale. Molti studenti internazionali vengono negli Stati Uniti per studiare e remare nelle università americane. Questi studenti portano con sé la propria cultura, background e prospettiva, contribuendo in modo significativo alla diversità e alla qualità delle squadre. Inoltre, offrono un'opportunità per lo scambio culturale e l'amicizia tra canottieri di diversi paesi.
      <br />
      <br />
      Ci sono diverse ragioni per cui gli studenti internazionali scelgono il canottaggio universitario negli Stati Uniti. Alcuni di questi motivi includono:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Borse di Studio per il Canottaggio: Molte università americane offrono borse di studio per il canottaggio a canottieri talentuosi, che possono ridurre o addirittura eliminare i costi di iscrizione. Questo è un incentivo significativo per gli studenti internazionali che altrimenti potrebbero non essere in grado di studiare negli Stati Uniti.
        </li>
        <li>
          Eccellenza Accademica: Molte università americane sono note per i loro elevati standard accademici e la loro reputazione. Questo attira gli studenti internazionali che cercano un'istruzione eccellente e un diploma riconosciuto globalmente.
        </li>
        <li>
          Sfida Sportiva: Molti studenti internazionali vedono il canottaggio universitario come una sfida sportiva e un'opportunità per competere con i migliori canottieri del mondo. Mirano a migliorare le proprie abilità, spingere i propri limiti e realizzare il proprio potenziale.
        </li>
        <li>
          Sviluppo Personale: Molti studenti internazionali considerano il canottaggio universitario come uno sviluppo personale e un'opportunità per crescere come individui. Vogliono acquisire nuove esperienze, incontrare nuove persone, conoscere nuove culture e ampliare i propri orizzonti.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      "Niki van Sprang è un esempio di uno studente internazionale che ha remato a livello universitario negli Stati Uniti. Van Sprang, originario dei Paesi Bassi, dove ha vinto diversi titoli nazionali, ha ricevuto una borsa di studio per il canottaggio dall'Università della California, Berkeley. Lì, si è unito alla squadra di primo livello e ha ottenuto un successo significativo. Dopo essersi laureato nel 2017, Van Sprang è tornato nei Paesi Bassi ed è diventato membro della nazionale di canottaggio. Ha partecipato alle Olimpiadi di Tokyo 2020, dove lui e Guillaume Krommenhoek hanno ottenuto il 7º posto nel due senza. Inoltre, ha vinto l'argento ai Campionati Mondiali 2022 a Račice e ai Campionati Europei 2022 a Monaco, così come il bronzo ai Campionati Europei 2023 a Bled, tutti nell'otto."
    </MKTypography>
  </MKBox>

  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Canottieri Americani all'Estero
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Il canottaggio universitario può anche portare opportunità internazionali per gli studenti-atleti americani che desiderano ampliare o cambiare i loro orizzonti sportivi. Ci sono varie opportunità per i canottieri americani di remare all'estero e acquisire esperienza. Alcune di queste opportunità includono:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Programmi di Scambio di Studio: Alcune università americane hanno programmi di scambio con università straniere, che permettono agli studenti di studiare e remare in un altro paese per un semestre o un anno. Questo offre loro l'opportunità di vivere un'altra cultura, lingua e sistema educativo, oltre a remare con altre squadre e allenatori.
        </li>
        <li>
          Ritiri di Allenamento: Alcune università americane organizzano ritiri di allenamento all'estero, di solito durante le vacanze invernali o estive. Questo dà loro la possibilità di allenarsi in un ambiente diverso, clima e condizioni dell'acqua, oltre a competere contro altre squadre o partecipare a regate locali.
        </li>
        <li>
          Coaching: Alcuni canottieri americani decidono di allenare all'estero dopo la laurea, di solito in scuole, club o squadre nazionali. Questo offre loro l'opportunità di condividere le proprie conoscenze ed esperienze, oltre a imparare da altri allenatori e culture.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>



                                              






                                  






<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 5: Borse di Studio Sportive e il Futuro degli Atleti
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le borse di studio sportive offrono numerose opportunità ai giovani atleti di mostrare il loro talento e sviluppare le loro abilità. Una delle opportunità più significative è ottenere una borsa di studio sportiva per studiare e competere a livello universitario. In questo capitolo, discuteremo dell'importanza delle borse di studio sportive e di come Sport Scholarships America può aiutarti a realizzare il tuo sogno.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 L'Importanza delle Borse di Studio
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Una borsa di studio sportiva è un sostegno finanziario fornito da un'università a uno studente-atleta per combinare studio e sport. Può coprire i costi di iscrizione, libri, alloggio, pasti e altre spese. Inoltre, può offrire vantaggi come il tutorato accademico, l'assistenza medica, le strutture di allenamento e l'attrezzatura.
      <br />
      <br />
      Ottenere una borsa di studio sportiva è competitivo, con molti fattori che influenzano il processo di assegnazione, tra cui risultati accademici, prestazioni sportive, personalità, motivazione, leadership e adattabilità. È quindi importante prepararsi bene e distinguersi dalla concorrenza.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Come Sport Scholarships America Può Aiutare
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo di Sport Scholarships America, raffigurante attrezzature sportive e una toga, simbolo del focus sulle borse di studio sportive per gli studenti-atleti."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo di Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America è un'organizzazione specializzata nell'orientamento dei giovani talenti sportivi verso borse di studio sportive. Con esperti appassionati ed esperti, Sport Scholarships America supporta gli studenti-atleti in ogni fase del percorso, dall'orientamento iniziale alla collocazione finale. I loro servizi includono:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Un'intervista personale per discutere i desideri, le possibilità e le aspettative dello studente-atleta.</li>
        <li>Una valutazione professionale del livello accademico e sportivo dello studente-atleta.</li>
        <li>Una vasta rete di contatti con allenatori, università e college.</li>
        <li>Promozione efficace del profilo e dei video dello studente-atleta presso allenatori potenziali.</li>
        <li>Consulenza esperta sulle migliori opzioni in base alle preferenze, agli obiettivi e al budget dello studente-atleta.</li>
        <li>Negoziazione delle migliori offerte di borse di studio per conto dello studente-atleta.</li>
        <li>Assistenza nella richiesta dei documenti necessari, come visto, trascrizione, diploma e test di ammissione.</li>
        <li>Preparazione alla vita dello studente-atleta durante la borsa di studio, compresa cultura, lingua, regole e consigli.</li>
        <li>Assistenza e monitoraggio durante il periodo di borsa di studio.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vuoi esplorare le opportunità di borse di studio e raggiungere i tuoi obiettivi sportivi e accademici? <Link to="/it/Servizi-Prodotti">Consulta la gamma completa</Link> dei servizi offerti da Sport Scholarships America e prenota una consultazione oggi stesso.
    </MKTypography>
  </MKBox>
</MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;