/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Gymnastics1.jpeg";
import Image2 from "assets/images/Sports_Images/Gymnastics2.jpg";
import Image3 from "assets/images/Sports_Images/Gymnastics3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'introduksjon', title: 'Turn og Studier i Amerika: En Unik Mulighet for Unge Turnere', isSubheader: false },
  
    { id: 'Kapittel 1', title: 'Kapittel 1: Historie og Utvikling av Turn i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Tidlige År og Universitetenes Rolle', isSubheader: true },
    { id: '1.2', title: '1.2 Vekst og Professionalisering', isSubheader: true },
  
    { id: 'Kapittel 2', title: 'Kapittel 2: Collegiat Turn i Dag', isSubheader: false },
    { id: '2.1', title: '2.1 Store Konkurranser og Konferanser', isSubheader: true },
    { id: '2.2', title: '2.2 Topp Program og Prestisjefylte Lag', isSubheader: true },
  
    { id: 'Kapittel 3', title: 'Kapittel 3: Fra College til Profesjonelt Nivå', isSubheader: false },
    { id: '3.1', title: '3.1 Veien til Profesjonalitet', isSubheader: true },
    { id: '3.2', title: '3.2 Profesjonelle Karrierer og Muligheter', isSubheader: true },
  
    { id: 'Kapittel 4', title: 'Kapittel 4: Internasjonale Innflytelser', isSubheader: false },
    { id: '4.1', title: '4.1 Utenlandske Innflytelser og Utveksling', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanere i Utlandet', isSubheader: true },
  
    { id: 'Kapittel 5', title: 'Kapittel 5: Idrettsstipender og Fremtiden til Utøvere', isSubheader: false },
    { id: '5.1', title: '5.1 Viktigheten av Stipender', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Scholarships America Kan Hjelpe', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>























          <MKBox id="introduksjon" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Turn og Studier i Amerika: En Unik Mulighet for Unge Turnere
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Utsikt fra tribunene på en collegiat turnkonkurranse i et stort stadion."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        En stor arena under en NCAA turnkonkurranse
      </figcaption>
    </figure>
    Turn har en lang og rik historie i USA. Siden 1800-tallet har turn blitt praktisert ved universiteter, der turnere utvikler sine ferdigheter og viser frem sine talenter. Turn er ikke bare en idrett; det er også en kunstform, en vitenskap og en livsstil. Turnere lærer disiplin, gjennomføringsevne, kreativitet og samarbeid. De trener hardt, men har også mye moro.
    <br />
    <br />
    Å konkurrere i turn på collegiat nivå er en unik mulighet for unge turnere til å oppfylle sine drømmer. Ved å tjene et idrettsstipend, kan du studere ved et prestisjefylt universitet samtidig som du deltar i spennende konkurranser mot andre toppturnere. Du blir en del av et nært samfunn av student-idrettsutøvere som støtter og motiverer hverandre. Du får tilgang til de beste fasilitetene, trenerne og mentorene som hjelper deg med å nå ditt potensiale. Og du får en uforglemmelig opplevelse som forbereder deg på din fremtidige karriere.
    <br />
    <br />
    Men hvordan kvalifiserer du deg for et idrettsstipend? Hva er kravene og fordelene? Hvordan finner du det rette universitetet for deg? Og hvordan er livet til en student-turner? Dette er alle spørsmål som Sportbeursamerika.nl kan svare på for deg. Sportbeursamerika.nl er en plattform som hjelper unge turnere å finne veien til Amerika. Jeg tilbyr personlig veiledning, råd og støtte gjennom hele søknads-, utvelgelses-, forberedelses- og avreiseprosessen. Jeg har kontakter med hundrevis av universiteter og trenere som leter etter turntalent. Jeg kjenner innsiden og utsiden av det amerikanske utdanningssystemet og collegiat turn. Og jeg har personlig erfaring med idrett og studier i Amerika.
    <br />
    <br />
    Er du nysgjerrig på mulighetene til å kombinere turn og studier i Amerika? Les videre og oppdag alt du trenger å vite om denne unike muligheten.
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>

<MKBox id="Kapittel 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 1: Historie og Utvikling av Turn i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Turn er en av de eldste idrettene i verden, med sine røtter i gamle sivilisasjoner som Hellas, Roma og Kina. Turn ble brukt som et middel for å trene kroppen, skjerpe sinnet og løfte sjelen. Det ble også sett på som en form for uttrykk, kunst og skjønnhet.
    <br />
    <br />
    I USA begynte turn å utvikle seg på 1800-tallet da europeiske innvandrere brakte med seg sin kunnskap og sine ferdigheter. Turn ble raskt populært på skoler, klubber og foreninger, der det ble sett på som en måte å fremme helse, karakter og statsborgerskap. Turn ble også en kilde til nasjonal stolthet, spesielt etter Uavhengighetskrigen.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidlige År og Universitetenes Rolle
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      De første universitetsturnlagene ble dannet sent på 1800-tallet da studenter etablerte egne klubber og organiserte mellomuniversitetskonkurranser. Den første offisielle universitetsturnkonkurransen fant sted i 1897 da Yale University møtte Springfield College. Springfield College regnes som fødestedet for amerikansk turn, da det var den første skolen som tilbød turn som et akademisk fag. Mange anerkjente turntrenere og ledere studerte eller underviste ved Springfield College.
      <br />
      <br />
      På begynnelsen av 1900-tallet fortsatte universitetsturn å vokse med etableringen av regionale og nasjonale organisasjoner som Eastern Intercollegiate Gymnastics League (EIGL) i 1902 og National Collegiate Athletic Association (NCAA) i 1906. NCAA begynte å organisere nasjonale mesterskap for menn i 1938 og for kvinner i 1982. NCAA forblir hovedorganisasjonen for collegiat turn i Amerika.
    </MKTypography>
  </MKBox>

  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Vekst og Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Populariteten og nivået av universitetsturn økte etter andre verdenskrig da mange veteraner returnerte til universitetene og forfulgte sine idrettsambisjoner. Påvirkningen fra europeiske trenere og utøvere, som kom til Amerika for å unnslippe kommunismen eller nazismen, bidro også til utviklingen av universitetsturn. En slik figur var George Gulack, en ungarsk turner som vant gull under OL i 1936 i Berlin. Gulack spilte en betydelig rolle i den olympiske komiteen og amerikanske og internasjonale turnorganisasjoner, og tjenestegjorde i American Olympic Committee fra 1934 til 1958.
      <br />
      <br />
      På 1950- og 60-tallet begynte profesjonell turn å skille seg fra universitetsturn, med fremveksten av internasjonale stjerner som Larisa Latynina, Olga Korbut og Nadia Comaneci. Disse turnerne inspirerte mange unge amerikanere til å bli med i private klubber og fokusere på individuelle prestasjoner. En av dem var Cathy Rigby, som ble den første amerikanske kvinnen som vant en medalje i et verdensmesterskap i 1970. Hun ble senere en svært vellykket skuespillerinne.
      <br />
      <br />
      Profesjonell turn nådde sitt høydepunkt i 1978 da amerikanske turner Kurt Thomas ble den første mannen som vant en gullmedalje i et verdensmesterskap. Han studerte ved Indiana State University og ble senere skuespiller. Samme år debuterte Mary Lou Retton på den nasjonale scenen. Hun ble den første amerikanske kvinnen som vant gull i all-around under OL i 1984.
      <br />
      <br />
      Universitetsturn forble imidlertid relevant og innflytelsesrik, spesielt for kvinner. Mange kvinnelige turnere valgte å fortsette karrierene sine på collegiatnivå fordi de fikk flere muligheter og fordeler der enn i profesjonelle kretser.
      <br />
      <br />
      Universitetsturn spilte derfor en betydelig rolle i historien og utviklingen av turn i Amerika. Det produserte mange utøvere som utmerket seg ikke bare i sin idrett, men også i sine studier og senere karrierer. Det bidro også til spredning og promotering av turn som en idrett for alle, uavhengig av alder, kjønn eller bakgrunn. Universitetsturn er fortsatt en levende og dynamisk idrett som tiltrekker seg tusenvis av fans og tilskuere hvert år.
    </MKTypography>
  </MKBox>
</MKBox>



                                              





                                  







<MKBox id="Kapittel 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 2: Collegiat Turn i Dag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Turn er en av de mest populære og konkurransedyktige idrettene på collegiatnivå i Amerika. Hvert år konkurrerer hundrevis av lag og tusenvis av utøvere om de ettertraktede titlene og trofeene. Collegiat turn tilbyr en unik kombinasjon av idrettsprestasjoner, spektakulære oppvisninger og akademisk fremragende. Det er en idrett som krever mye, men gir også mye tilbake.
  </MKTypography>
  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Store Konkurranser og Konferanser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="En turner fra Auburn i aksjon."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          En turner fra Auburn i aksjon
        </figcaption>
      </figure>
      Collegiat turn styres av NCAA, som fastsetter regler, kvalifikasjoner og mesterskap. NCAA deler collegiat turn inn i tre divisjoner basert på antall stipend, budsjetter og lagnivå. Divisjon I er den høyeste divisjonen, der de fleste toppturnerne og lagene deltar. Divisjon II og III er lavere divisjoner med færre stipend tilbudt og mer vekt på akademisk prestasjon.
      <br />
      <br />
      Innenfor hver divisjon er det forskjellige konferanser som danner regionale eller tematiske grupper av universiteter. Konferanser organiserer egne konkurranser og mesterskap, som fungerer som forrundene for nasjonale mesterskap. De største konferansene for collegiat turn er:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Big Ten Conference, som består av 14 universiteter i Midtvesten og Nordøsten i Amerika. Big Ten er en av de eldste og mest prestisjefylte konferansene, kjent for sin akademiske og idrettslige fremragende. Big Ten har noen av de beste herre- og damesturnprogrammene, inkludert Michigan, Minnesota, Nebraska og Penn State.
        </li>
        <li>
          Southeastern Conference (SEC), som består av 14 universiteter i sørøst i Amerika. SEC er en av de mest dominerende og innflytelsesrike konferansene, kjent for sine lidenskapelige og lojale fans. SEC har noen av de beste damesturnprogrammene, som Alabama, Florida, Georgia og LSU.
        </li>
        <li>
          Pac-12 Conference, som består av 12 universiteter i den vestlige delen av USA. Pac-12 er en av de mest innovative og mangfoldige konferansene, kjent for sin kreativitet og kultur. Pac-12 har noen av de beste herre- og damesturnprogrammene, inkludert California, Stanford, UCLA og Utah.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Nasjonale mesterskap er høydepunktet av collegiat turnsesongen, der de beste lagene og individene konkurrerer om ære og berømmelse. Nasjonale mesterskap avholdes hvert år i april på ulike steder over hele landet. Nasjonale mesterskap består av to runder: de regionale mesterskapene og de nasjonale finalene. De regionale mesterskapene er kvalifiseringsrundene, der 36 lag for herrer og 36 lag for damer deles inn i seks regioner. De to beste lagene fra hver region går videre til de nasjonale finalene. De nasjonale finalene er de avgjørende rundene, der 12 lag for herrer og 12 lag for damer deles inn i to økter. De fire beste lagene fra hver økt går videre til superfinalen (Super Six), der de konkurrerer om tittelen som nasjonalt mesterlag. I tillegg tildeles individuelle titler for all-around-konkurransen og apparatfinalene.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Topp Program og Prestisjefylte Lag
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Collegiat turn har produsert flere topp program som skiller seg ut med sin konsistens, kvalitet og tradisjon. Disse programmene har vunnet flere nasjonale titler, trent mange talentfulle utøvere og tiltrokket mange tilhengere. Noen av disse programmene inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          UCLA Bruins: UCLA-turnprogrammet er et av de mest vellykkede og respekterte programmene i landet. UCLA-turnprogrammet har vunnet mer enn 20 nasjonale titler, inkludert flere titler for herrer og flertallet for damer. Programmet har også produsert mange olympiske og verdensmestere, som Peter Vidmar, Tim Daggett, Jamie Dantzscher, Mohini Bhardwaj, Tasha Schwikert, Samantha Peszek og Kyla Ross.
        </li>
        <li>
          Oklahoma Sooners: Oklahoma-turnprogrammet er et av de mest dominerende og imponerende programmene i nasjonen. Oklahoma-turnprogrammet har vunnet mer enn 10 nasjonale titler, inkludert flere titler for herrer og nesten like mange for damer. Programmet har også produsert mange NCAA-mestere, inkludert Jonathan Horton, Jake Dalton, Maggie Nichols, Brenna Dowell og Anastasia Webb.
        </li>
        <li>
          Utah Utes: Utah-turnprogrammet er et av de mest historiske og lojale programmene i landet. Utah-turnprogrammet har vunnet mer enn 10 nasjonale titler, alle for damer. Programmet har også produsert legendariske trenere og utøvere, inkludert Greg Marsden, Megan Marsden, Missy Marlowe, Theresa Kulikowski, Ashley Postell og MyKayla Skinner.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Collegiat turn tilbyr en unik mulighet for unge turnere å praktisere idretten sin på høyeste nivå samtidig som de får en verdifull utdannelse. Collegiat turn er en idrett som gir mange utfordringer og belønninger, både på og utenfor gulvet. Collegiat turn er en idrett som kan forandre livet ditt.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  





<MKBox id="Kapittel 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 3: Fra College til Profesjonelt Nivå
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Turn er en idrett som krever mye talent, dedikasjon og utholdenhet. Turnere begynner ofte treningen i ung alder og drømmer om en profesjonell karriere. Men hvordan gjør du overgangen fra collegiat turn til profesjonell turn? Og hvilke muligheter og utfordringer venter deg?
  </MKTypography>
  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Veien til Profesjonalitet
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Collegiat turn er en utmerket forberedelse til profesjonell turn fordi det gir deg muligheten til å forbedre ferdighetene dine, få erfaring og utvide nettverket ditt. Mange collegiat turnere har deltatt i profesjonelle konkurranser som olympiske leker, verdensmesterskap og verdenscupen. Noen av dem har til og med vunnet medaljer.
      <br />
      <br />
      Imidlertid må collegiat turnere for å delta i profesjonelle konkurranser overholde visse regler og betingelser. De må opprettholde sin amatørstatus, noe som betyr at de ikke kan tjene penger på idretten sin. De har heller ikke lov til å ha sponsorer eller agenter med mindre de får tillatelse fra NCAA. De må også oppfylle universitetets akademiske krav, noe som noen ganger kan være utfordrende å balansere med treningsplanen sin.
      <br />
      <br />
      I tillegg må collegiat turnere også vurdere alderen og fysiske tilstand. Turn er en idrett som stiller store krav til kroppen og fører ofte til skader. Mange turnere når sitt toppnivå rundt tjueårene og kan slite med å opprettholde nivået etterpå. Noen turnere velger å gi opp sine profesjonelle ambisjoner og fokusere på studier eller karrierer utenfor idretten.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Profesjonelle Karrierer og Muligheter
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      For de som bestemmer seg for å fortsette med profesjonell turn, er det ulike muligheter og sjanser til å følge lidenskapen sin. En av de mest prestisjefylte og lukrative alternativene er å konkurrere i olympiske leker, som holdes hvert fjerde år. OL er høydepunktet i enhver idrettskarriere, og tilbyr muligheten til å representere landet ditt, konkurrere mot verdens beste turnere og vinne en medalje. OL bringer også berømmelse og anerkjennelse som kan føre til sponsoravtaler, medieoppmerksomhet og andre fordeler.
      <br />
      <br />
      Et annet alternativ er å delta i verdensmesterskap, som holdes hvert år (unntatt i olympiske år). Verdensmesterskapene er en betydelig benchmark for nivået og utviklingen av turn på verdensbasis. Verdensmesterskapene tilbyr også muligheten til å kvalifisere seg til OL eller motta et wildcard-innlegg. Verdensmesterskapene er en kilde til stolthet og prestisje som kan føre til pengepremier, stipend og andre belønninger.
      <br />
      <br />
      Et tredje alternativ er å konkurrere i verdenscupen, som består av en serie individuelle konkurranser holdt over hele verden. Verdenscupen er et attraktivt alternativ for turnere som søker mer fleksibilitet og variasjon i idrettskarrieren sin. Verdenscupen tilbyr også muligheten til å utforske ulike land og kulturer, skape nye venner og utvide horisontene dine. Verdenscupen er også en kilde til moro og eventyr som kan føre til refusjon av reiseutgifter, bonuser og andre insentiver.
      <br />
      <br />
      I tillegg til disse internasjonale konkurransene, er det andre profesjonelle muligheter for turnere, slik som:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Gjennomføre klinikker, workshops eller demonstrasjoner der du deler kunnskapen din og erfaringene dine med andre turnere, trenere eller fans.
        </li>
        <li>
          Arbeide som trener, instruktør eller rådgiver, og hjelpe andre turnere med å nå målene sine, forbedre teknikken sin eller løse problemene sine.
        </li>
        <li>
          Arbeide som kommentator, analytiker eller journalist, og tilby dine meninger og innsikt om turn, konkurranser eller aktuelle hendelser.
        </li>
        <li>
          Arbeide som modell, skuespiller eller påvirker, og bruke utseendet ditt, personligheten din eller populariteten din til å fremme produkter, tjenester eller merker.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Profesjonell turn er en spennende og utfordrende karriere som tilbyr mange muligheter og sjanser. Imidlertid er det også en krevende og usikker karriere som kommer med risikoer og utfordringer. Å velge en karriere innen profesjonell turn krever både hjertet og hodet ditt.
    </MKTypography>
  </MKBox>
</MKBox>
                                 






                                  



<MKBox id="Kapittel 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 4: Internasjonale Innflytelser
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Turn er en idrett uten grenser. Turn er en idrett som samler mennesker fra hele verden, inspirerer dem og beriker dem. Turn er en idrett påvirket av ulike kulturer, stiler og tradisjoner. I dette kapittelet vil vi se på de internasjonale innflytelsene på collegiat turn i Amerika og hvordan disse innflytelsene har formet og transformert collegiat turn.
  </MKTypography>
  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Utenlandske Innflytelser og Utveksling
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Stanford turner i aksjon på bommen under en collegiakonkurranse."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          En turner fra Stanford i aksjon
        </figcaption>
      </figure>
      I den fascinerende verdenen av collegiat turn i Amerika spiller internasjonal utveksling en avgjørende rolle. Visste du at utenlandske turnere bringer med seg en unik dynamikk og teknikker som hever konkurransenivået? Disse utøverne bringer ofte innovative stiler og metoder som inspirerer og utfordrer deres amerikanske motparter. Dette gjør ikke bare idretten mer mangfoldig, men også mer konkurransedyktig.
      <br />
      <br />
      Et eksempel på dette er påvirkningen fra øst-europeiske turnere på 80- og 90-tallet. Deres ankomst i USA brakte nye teknikker og treningsmetoder, noe som førte til en betydelig utvikling innen amerikansk turn. Disse innflytelsene er fortsatt synlige i dag i stilen og teknikken til mange toppturnere.
      <br />
      <br />
      Mangfoldet som internasjonale turnere bringer, beriker sportskulturen på campusene. Det skaper en smeltedigel av kulturer og ideer som bare gagner idretten. I tillegg gir det amerikanske studenter og trenere muligheten til å lære fra ulike kulturelle perspektiver og skjerpe sine egne ferdigheter og taktikker.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikanere i Utlandet
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      På den annen side har amerikanske turnere også hatt en betydelig innvirkning på den internasjonale turnverdenen. Mange amerikanske studentatleter har oppnådd internasjonal suksess etter collegiatkarrieren. Prestasjonene deres ved verdensmesterskap og olympiske leker skyldes ofte treningen og erfaringen de fikk i collegiat-kretsen.
      <br />
      <br />
      Disse turnerne fungerer som ambassadører for amerikansk turnkultur. Suksessene deres inspirerer unge turnere over hele verden til å ønske å være en del av et amerikansk universitetslag. Dette skaper en positiv syklus der talent og kunnskap utveksles over landegrensene.
      <br />
      <br />
      Stiene disse turnerne har tatt, demonstrerer hvordan collegiat turn kan være en utmerket grunnmur for en internasjonal karriere. Erfaringene de får under studiene - fra å delta i høykvalitetskonkurranser til å trene under topp trenere - forbereder dem på utfordringene i den profesjonelle idrettsverdenen.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 5: Idrettsstipender og Fremtiden til Utøvere
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrettsstipender gir mange muligheter for unge utøvere til å vise sitt talent og utvikle sine ferdigheter. En av de mest betydningsfulle mulighetene er å få et idrettsstipend for å studere og konkurrere på college-nivå. I dette kapittelet vil vi diskutere viktigheten av idrettsstipender og hvordan Sport Scholarships America kan hjelpe deg med å oppfylle drømmen din.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Viktigheten av Stipender
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et idrettsstipend er økonomisk støtte gitt av et universitet til en studentutøver for å kombinere studier og idrett. Det kan dekke kostnadene for skolepenger, bøker, bolig, måltider og andre utgifter. I tillegg kan det tilby fordeler som akademisk veiledning, medisinsk hjelp, treningsfasiliteter og utstyr.
      <br />
      <br />
      Å få et idrettsstipend er konkurransedyktig, med mange faktorer som spiller en rolle i tildelingsprosessen, inkludert akademiske prestasjoner, idrettslige resultater, personlighet, motivasjon, lederegenskaper og tilpasningsevne. Det er derfor viktig å forberede seg godt og skille seg ut fra konkurransen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Scholarships America Kan Hjelpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo for Sport Scholarships America, som viser sportsutstyr og en eksamenskappe, symboliserer fokuset på idrettsstipender for studentutøvere."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo for Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America er en organisasjon spesialisert i å veilede unge idrettstalenter til idrettsstipender. Med lidenskapelige og erfarne eksperter støtter Sport Scholarships America studentutøvere på hvert trinn i reisen, fra første orientering til endelig plassering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig inntaksintervju for å diskutere studentutøverens ønsker, muligheter og forventninger.</li>
        <li>En profesjonell evaluering av studentutøverens akademiske og idrettslige nivå.</li>
        <li>Et omfattende nettverk av kontakter med trenere, universiteter og høyskoler.</li>
        <li>Effektiv markedsføring av studentutøverens profil og videoer til potensielle trenere.</li>
        <li>Ekspertråd om de beste alternativene basert på studentutøverens preferanser, mål og budsjett.</li>
        <li>Forhandlinger om de beste stipendtilbudene på vegne av studentutøveren.</li>
        <li>Hjelp til å søke om nødvendige dokumenter, som visum, transkripsjon, diploma og opptaksprøver.</li>
        <li>Forberedelse til studentutøverens liv under stipendet, inkludert kultur, språk, regler og tips.</li>
        <li>Oppfølging og oppfølging under stipendperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vil du utforske stipendmuligheter og oppnå dine idrettslige og akademiske mål? <Link to="/no/Tjenester-Produkter">Se det komplette utvalget</Link> av tjenester som tilbys av Sport Scholarships America og planlegg en konsultasjon i dag.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;