/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Hockey1.jpg";
import Image2 from "assets/images/Sports_Images/Hockey2.jpeg";
import Image3 from "assets/images/Sports_Images/Hockey3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");




  
  
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'Introduction', title: 'Bourses de Hockey sur Gazon en Amérique : Comment le Hockey sur Gazon Peut Vous Aider à Réaliser Vos Rêves', isSubheader: false },
  
    { id: 'Chapitre 1', title: 'Chapitre 1 : Histoire et Développement du Hockey sur Gazon en Amérique', isSubheader: false },
    { id: '1.1', title: '1.1 Débuts de l\'Histoire', isSubheader: true },
    { id: '1.2', title: '1.2 Développement et Professionnalisation', isSubheader: true },
  
    { id: 'Chapitre 2', title: 'Chapitre 2 : Le Hockey sur Gazon au Niveau Universitaire', isSubheader: false },
    { id: '2.1', title: '2.1 Compétitions et Conférences', isSubheader: true },
    { id: '2.2', title: '2.2 Impact sur le Développement des Joueurs', isSubheader: true },
  
    { id: 'Chapitre 3', title: 'Chapitre 3 : Transition du Niveau Universitaire au Professionnel', isSubheader: false },
    { id: '3.1', title: '3.1 Du Collège aux Pros', isSubheader: true },
    { id: '3.2', title: '3.2 Opportunités Professionnelles et Ligues', isSubheader: true },
  
    { id: 'Chapitre 4', title: 'Chapitre 4 : Influences et Opportunités Internationales', isSubheader: false },
    { id: '4.1', title: '4.1 Influences Mondiales', isSubheader: true },
    { id: '4.2', title: '4.2 Les Américains sur la Scène Mondiale', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Chapitre 5 : Bourses Sportives et l\'Avenir des Athlètes', isSubheader: false },
    { id: '5.1', title: '5.1 L\'Importance des Bourses', isSubheader: true },
    { id: '5.2', title: '5.2 Comment Sport Scholarships America Peut Aider', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  const [,] = useState("");


  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Sommaire
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Cacher</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Afficher</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>






















          <MKBox id="Introduction" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Bourses de Hockey sur Gazon en Amérique : Comment le Hockey sur Gazon Peut Vous Aider à Réaliser Vos Rêves
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Joueur en action lors d'un match de hockey sur gazon universitaire de Colgate."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
      Joueur en action lors d'un match
      </figcaption>
    </figure>

    Le hockey sur gazon est l'un des sports en pleine expansion en Amérique. De plus en plus de jeunes athlètes découvrent les avantages et le plaisir de ce sport dynamique et exigeant. Mais saviez-vous que le hockey sur gazon peut également vous aider à réaliser vos objectifs académiques et professionnels ?
    <br />
    <br />

    Si vous avez une passion pour le hockey sur gazon, vous avez une opportunité unique de jouer au niveau universitaire en Amérique. Cela signifie que vous pouvez étudier dans l'une des meilleures universités du monde tout en développant vos compétences athlétiques sous la supervision de professionnels. De plus, vous pouvez être éligible à une bourse sportive, offrant un soutien financier pour vos études.

    <br />
    <br />
    Mais comment obtenir une telle bourse ? Et comment se préparer à jouer au hockey sur gazon en Amérique ? C'est là que Sportbeursamerika.nl peut vous aider. Sportbeursamerika.nl est une agence spécialisée qui aide les jeunes talents à réaliser leur rêve d'étudier et de jouer au hockey en Amérique. Je propose un accompagnement personnalisé, des conseils et un soutien pour trouver la bonne université, demander une bourse, gérer tous les aspects pratiques et s'intégrer dans la culture américaine.
    <br />
    <br />
    Dans cet article, je vous en dirai plus sur l'histoire et le développement du hockey sur gazon en Amérique, les opportunités et avantages de jouer au niveau universitaire, la transition du collège au jeu professionnel, les influences et opportunités internationales dans le sport, et bien sûr, l'importance des bourses et le rôle de Sportbeursamerika.nl. Après avoir lu cet article, vous aurez une meilleure compréhension de ce que le hockey sur gazon en Amérique implique et comment vous préparer à ce défi excitant.
    <br />
    <br />
    Êtes-vous prêt à en savoir plus sur le hockey sur gazon en Amérique ? Continuez à lire !
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapitre 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 1 : Histoire et Développement du Hockey sur Gazon en Amérique
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le hockey sur gazon est un sport avec une longue et riche histoire. Mais comment a-t-il été introduit et développé en Amérique ? Dans ce chapitre, nous vous emmènerons dans un voyage à travers le temps, de l'introduction précoce à la professionnalisation actuelle du sport.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Débuts de l'Histoire
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Le hockey sur gazon a été introduit aux États-Unis à la fin du 19e siècle par des immigrants britanniques et des touristes. Le jeu a rapidement gagné en popularité chez les femmes, qui le voyaient comme un moyen de faire de l'exercice et de socialiser. Les premières équipes universitaires ont été formées en 1901 au Vassar College dans l'État de New York et au Bryn Mawr College en Pennsylvanie. Ces équipes jouaient principalement les unes contre les autres ou contre des clubs locaux.
      <br />
      <br />
      En 1922, l'Association américaine de hockey sur gazon (USFHA) a été créée en tant qu'organisation nationale pour le sport. L'USFHA a organisé les premiers championnats nationaux pour les femmes en 1925 et pour les hommes en 1930. L'USFHA était également responsable de la sélection et de la formation des équipes nationales qui participaient à des tournois internationaux tels que les Jeux olympiques et les Championnats du monde.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Développement et Professionnalisation
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dans la seconde moitié du 20e siècle, le hockey sur gazon a continué de croître en popularité et en qualité en Amérique. Le jeu s'est davantage répandu à travers le pays, notamment dans le Nord-Est, le Midwest et l'Ouest. Le nombre d'équipes universitaires a augmenté, et plus de ligues et de conférences ont été formées. Certaines des programmes les plus prestigieux et compétitifs comprennent l'Université de Princeton, l'Université du Maryland, l'Université de Caroline du Nord, l'Université Stanford et l'Université Harvard.
      <br />
      <br />
      Le niveau de hockey sur gazon universitaire a également augmenté grâce à l'amélioration des installations, de l'encadrement et du recrutement. De nombreuses universités offrent désormais des bourses sportives aux joueurs talentueux de hockey sur gazon, leur permettant d'étudier sans soucis financiers. De plus, le hockey sur gazon universitaire sert de terreau pour le hockey sur gazon professionnel, qui devient également plus développé et professionnalisé.
      <br />
      <br />
      Le hockey sur gazon professionnel a également influencé l'équipe nationale, qui a mieux performé sur la scène internationale. Par exemple, l'équipe féminine américaine a remporté deux fois le bronze aux Jeux olympiques (en 1984 et 1996) et deux fois l'argent aux Jeux panaméricains (en 2011 et 2015). L'équipe masculine américaine a également participé à plusieurs Jeux olympiques et Championnats du monde, mais n'a pas encore remporté de médailles.
      <br />
      <br />
      Comme vous pouvez le voir, le hockey sur gazon a parcouru un long chemin en Amérique, passant d'une activité récréative à un sport professionnel. Mais que faut-il pour jouer au hockey sur gazon au niveau universitaire ? Nous vous le dirons dans le prochain chapitre.
    </MKTypography>
  </MKBox>
</MKBox>












                                              





                                  







<MKBox id="Chapitre 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 2 : Le Hockey sur Gazon au Niveau Universitaire
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Jouer au hockey sur gazon au niveau universitaire est l'une des expériences les plus stimulantes et gratifiantes pour un jeune athlète. Cela vous offre l'opportunité de mêler votre talent sportif à vos ambitions académiques et de faire partie d'une communauté proche et diversifiée. Dans ce chapitre, nous vous en dirons plus sur les compétitions et les conférences, l'impact sur le développement des joueurs, et les avantages de jouer au hockey sur gazon au niveau universitaire.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Compétitions et Conférences
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Vue aérienne du stade de hockey sur gazon de Boston College"
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
        Le stade de Boston College
        </figcaption>
      </figure>
      Le hockey sur gazon au niveau universitaire est réglementé par la National Collegiate Athletic Association (NCAA), qui divise le sport en trois divisions : Division I, Division II et Division III. Chaque division a ses propres règles, exigences et bourses. Généralement, la Division I est le niveau de compétition le plus élevé, mettant en vedette les meilleures équipes et joueurs, et offrant le plus de bourses. Les divisions II et III sont moins compétitives mais offrent tout de même de nombreuses opportunités pour les joueurs talentueux.
      <br />
      <br />
      Au sein de chaque division, il existe différentes conférences, composées d'universités qui sont géographiquement ou historiquement liées. Chaque conférence a son propre calendrier, classement et championnat. Certaines des conférences les plus connues comprennent la Big Ten Conference, l'Atlantic Coast Conference, la Ivy League, la Patriot League et la America East Conference.
      <br />
      <br />
      Les meilleures équipes et joueurs de chaque conférence peuvent se qualifier pour le championnat national, qui a lieu chaque année en novembre. Le championnat national implique un tournoi à élimination directe, avec le vainqueur couronné champion national.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Impact sur le Développement des Joueurs
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Jouer au hockey sur gazon au niveau universitaire a un impact significatif sur le développement des joueurs. Cela permet aux joueurs d'améliorer leurs compétences sous la direction d'entraîneurs professionnels, de s'entraîner avec des installations et un équipement de pointe, de rivaliser contre d'autres athlètes de haut niveau, et d'apprendre de leurs coéquipiers et adversaires.
      <br />
      <br />
      De plus, jouer au hockey sur gazon au niveau universitaire offre également de nombreux avantages personnels aux joueurs. Cela les aide à renforcer leur confiance, leur discipline, leur leadership, leur esprit d'équipe, leur communication et leurs compétences en résolution de problèmes. Cela aide également les joueurs à améliorer leur gestion du temps, leurs habitudes d'étude, leur gestion du stress et leur bien-être général. De plus, cela expose les joueurs à différentes cultures, perspectives et expériences, élargissant ainsi leurs horizons.
      <br />
      <br />
      L'histoire des joueuses de hockey néerlandaises recevant des bourses pour jouer au niveau universitaire en Amérique sert d'exemple de la manière dont cette expérience peut améliorer leur développement en tant que joueuses. Beaucoup ont joué pour des équipes universitaires prestigieuses aux États-Unis, où elles ont non seulement remporté des championnats et des titres individuels, mais ont également appris des leçons précieuses.
      <br />
      <br />
      L'expérience en Amérique offre à ces athlètes une opportunité unique de se plonger dans une culture différente, de profiter de nouveaux environnements sociaux et de vivre un système éducatif différent. L'interaction avec des entraîneurs qui les challenge et les soutient contribue à affiner leurs compétences et à élargir leur compréhension tactique du jeu.
      <br />
      <br />
      En rentrant aux Pays-Bas, ces joueuses de hockey ont utilisé leurs connaissances et expériences acquises pour exceller au niveau professionnel et, dans certains cas, assumer des rôles de leadership au sein de l'équipe nationale. Leur séjour en Amérique les a souvent préparées à la pression et aux attentes qui accompagnent les compétitions internationales, formant ainsi une base pour leur succès futur.
      <br />
      <br />
      Comme vous pouvez le constater, jouer au hockey sur gazon au niveau universitaire peut vous offrir de nombreux avantages, tant sur le plan sportif que personnel. Mais comment passer du collège au jeu professionnel ? Nous vous expliquerons cela dans le prochain chapitre.
    </MKTypography>
  </MKBox>
</MKBox>




                                  







<MKBox id="Chapitre 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 3 : Transition du Niveau Universitaire au Professionnel
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le hockey sur gazon au niveau universitaire est un excellent moyen de libérer votre potentiel athlétique, mais cela ne doit pas être la fin de votre carrière. Si vous avez l'ambition et la passion d'aller plus loin, vous pouvez passer au jeu professionnel. Dans ce chapitre, nous vous en dirons plus sur la transition du collège aux pros et sur les opportunités professionnelles et les ligues existantes pour les joueurs de hockey sur gazon.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Du Collège aux Pros
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Passer du niveau universitaire au jeu professionnel n'est pas facile, mais ce n'est pas impossible non plus. Cela nécessite beaucoup de dévouement, de travail acharné, de talent et un peu de chance. L'aspect le plus crucial est de vous faire remarquer par les bonnes personnes qui peuvent vous aider à réaliser votre rêve.
      <br />
      <br />
      Une façon de vous faire remarquer est de participer aux essais. Les essais sont des sessions d'entraînement ou des matchs ouverts où les équipes professionnelles peuvent repérer et évaluer les joueurs. Les essais sont généralement organisés avant ou après la saison et peuvent être annoncés via des sites Web, les réseaux sociaux ou le bouche-à-oreille. Les essais peuvent être une excellente occasion de montrer vos compétences et de recevoir des retours d'entraîneurs et de managers. Les essais peuvent être organisés par des équipes professionnelles du monde entier.
      <br />
      <br />
      Une autre façon de vous faire connaître est de tirer parti de votre réseau. Votre réseau comprend toutes les personnes que vous connaissez ou rencontrez qui sont liées au hockey sur gazon, y compris les entraîneurs, les coéquipiers, les adversaires, les amis, la famille, les enseignants, les anciens élèves, etc. Votre réseau peut vous aider à rester informé des dernières nouvelles, des opportunités et des conseils dans le monde du hockey sur gazon. Il peut également vous aider à vous connecter avec des personnes ayant de l'influence ou des connections dans le milieu professionnel.
      <br />
      <br />
      De plus, de nombreux joueurs de hockey européens retournent en Europe pour tenter leur chance là-bas. Comme vous pouvez le constater, il existe différentes façons de se faire remarquer par les équipes professionnelles. Mais quels types d'équipes existe-t-il et quels types de ligues existent ? Nous expliquerons cela dans la prochaine section.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Opportunités Professionnelles et Ligues
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Pour un joueur de hockey sur gazon qui a suivi le système universitaire américain, les portes d'une carrière professionnelle sont souvent grandes ouvertes. Cette expérience est plus qu'un simple tremplin ; c'est une préparation complète qui les a développés sous de nombreux aspects - physiquement, tactiquement et mentalement.
      <br />
      <br />
      Un joueur excellent en hockey universitaire peut attirer l'attention des recruteurs et des équipes professionnelles, non seulement pour ses performances sur le terrain, mais aussi en raison de la discipline et de la polyvalence qu'il a cultivées en étudiant et en jouant simultanément. Les ligues professionnelles de hockey sur gazon dans le monde entier, comme celles des Pays-Bas, de Belgique, d'Allemagne et d'Australie, sont connues pour recruter des joueurs talentueux ayant une solide expérience du hockey universitaire.
      <br />
      <br />
      La nature compétitive du hockey universitaire en Amérique garantit également que les joueurs sont habitués à un niveau élevé d'entraînement et de préparation physique, ce qui est essentiel pour le jeu professionnel. De plus, ils ont souvent de l'expérience dans des matches et des championnats importants, ce qui les aide à gérer la pression des compétitions professionnelles.
      <br />
      <br />
      De plus, l'expérience internationale et l'exposition à différents styles de jeu en Amérique offrent à ces joueurs une perspective unique qu'ils peuvent emporter dans leur carrière professionnelle. Cela les aide à s'adapter rapidement à de nouvelles équipes et stratégies et leur fournit une compréhension plus large du jeu.
      <br />
      <br />
      Les équipes professionnelles apprécient la combinaison de discipline académique et d'excellence athlétique qu'apporte un ancien joueur universitaire. Ces joueurs sont souvent bien préparés à affronter les défis de la vie professionnelle, y compris l'équilibre entre le sport et les autres engagements de la vie.
      <br />
      <br />
      Comme vous pouvez le constater, il existe de nombreuses opportunités professionnelles et ligues pour les joueurs de hockey sur gazon en Amérique. Mais qu'en est-il du reste du monde ? Comment le hockey sur gazon en Amérique se compare-t-il au hockey sur gazon international ? Nous expliquerons cela dans le prochain chapitre.
    </MKTypography>
  </MKBox>
</MKBox>





                                  








<MKBox id="Chapitre 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 4 : Influences et Opportunités Internationales
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le hockey sur gazon en Amérique ne reste pas isolé mais fait partie d'un monde du hockey sur gazon plus vaste et diversifié. Le hockey sur gazon est un sport mondial pratiqué dans plus de 100 pays sur tous les continents. Le hockey sur gazon en Amérique est influencé par le hockey sur gazon international et influence lui-même le hockey sur gazon international. Dans ce chapitre, nous vous en dirons plus sur les influences mondiales, les Américains sur la scène mondiale et les opportunités internationales pour les joueurs de hockey sur gazon.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Influences Mondiales
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Le hockey sur gazon en Amérique est influencé par le hockey sur gazon pratiqué dans d'autres pays, notamment en Europe et en Asie. Ces pays ont une tradition plus longue et plus riche en hockey sur gazon et ont souvent un niveau de jeu et d'organisation plus élevé. En observant et en apprenant de ces pays, le hockey sur gazon en Amérique peut se développer et s'améliorer davantage.
      <br />
      <br />
      Une façon dont le hockey sur gazon en Amérique est influencé par le hockey sur gazon international est en adoptant différents styles et tactiques. Par exemple, le hockey sur gazon néerlandais est connu pour son jeu rapide et offensif, avec un accent sur la possession de balle, les combinaisons et les tentatives de but. Le hockey sur gazon allemand est connu pour son style physique et défensif, avec une pression élevée, des duels et des contre-attaques. Le hockey sur gazon indien est connu pour son jeu technique et créatif, avec un accent sur le dribble, la passe et les astuces.
      <br />
      <br />
      En apprenant de ces styles et tactiques, le hockey sur gazon en Amérique peut diversifier son propre jeu et s'adapter à différentes situations, ajoutant plus de variété, de flexibilité et d'efficacité.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Les Américains sur la Scène Mondiale
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Le hockey sur gazon en Amérique impacte également le hockey sur gazon international en fournissant des joueurs qui participent aux plus grands et aux plus significatifs tournois du monde. Ces joueurs représentent non seulement leur pays, mais aussi leur université, leur équipe et leur sport. À travers leurs performances, ils démontrent ce que le hockey sur gazon en Amérique a à offrir et inspirent les autres à participer à ce sport.
      <br />
      <br />
      Un exemple parfait de la façon dont les joueurs américains entrent sur la scène mondiale est l'histoire de Lauren Crandall. Lauren est une joueuse de hockey sur gazon américaine qui a reçu une bourse pour jouer pour l'Université de Wake Forest. Elle a joué pour l'équipe pendant quatre saisons, remportant le titre de la Division I de la NCAA deux fois.
      <br />
      <br />
      Lauren a également fait partie de l'équipe nationale américaine, participant à trois Jeux olympiques (en 2008, 2012 et 2016), deux Championnats du monde (en 2010 et 2014) et quatre Jeux panaméricains (en 2007, 2011, 2015 et 2019). Elle a également été capitaine de l'équipe de 2013 à 2016.
      <br />
      <br />
      Lauren déclare que son séjour en Amérique l'a beaucoup aidée à réaliser son rêve de jouer au plus haut niveau. Elle a beaucoup appris de son entraîneur, Jennifer Averill, qui l'a soutenue et motivée. Elle a également apprécié la culture, les gens et l'éducation en Amérique. Elle est fière de représenter son pays et d'être un modèle pour les jeunes joueurs.
      <br />
      <br />
      Comme vous pouvez le constater, le hockey sur gazon en Amérique peut vous offrir de nombreuses influences et opportunités internationales, à la fois en tant que joueur et en tant qu'individu. Mais comment saisir ces opportunités ? Et comment obtenir une bourse pour étudier et jouer en Amérique ? Nous vous le dirons dans le prochain chapitre.
    </MKTypography>
  </MKBox>
</MKBox>






                                  



<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 5 : Bourses Sportives et l'Avenir des Athlètes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Les bourses sportives offrent de nombreuses opportunités aux jeunes athlètes de montrer leur talent et de développer leurs compétences. L'une des opportunités les plus significatives est d'obtenir une bourse sportive pour étudier et compétitionner au niveau collégial. Dans ce chapitre, nous discuterons de l'importance des bourses sportives et de comment Sport Scholarships America peut vous aider à réaliser votre rêve.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 L'Importance des Bourses
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Une bourse sportive est un soutien financier fourni par une université à un étudiant-athlète pour combiner études et sport. Elle peut couvrir les frais de scolarité, les livres, le logement, les repas et autres dépenses. De plus, elle peut offrir des avantages tels que du tutorat académique, des soins médicaux, des installations d'entraînement et du matériel.
      <br />
      <br />
      Obtenir une bourse sportive est compétitif, avec de nombreux facteurs qui influent sur le processus d'attribution, notamment les performances académiques, sportives, la personnalité, la motivation, le leadership et l'adaptabilité. Il est donc important de bien se préparer et de se démarquer de la concurrence.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Comment Sport Scholarships America Peut Aider
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo de Sport Scholarships America, représentant du matériel sportif et une casquette de graduation, symbolisant le focus sur les bourses sportives pour les étudiants-athlètes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America est une organisation spécialisée dans l'orientation des jeunes talents sportifs vers des bourses sportives. Avec des experts passionnés et expérimentés, Sport Scholarships America soutient les étudiants-athlètes à chaque étape du parcours, de l'orientation initiale à la placement final. Leurs services comprennent :
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Un entretien personnel pour discuter des souhaits, des possibilités et des attentes de l'étudiant-athlète.</li>
        <li>Une évaluation professionnelle du niveau académique et sportif de l'étudiant-athlète.</li>
        <li>Un vaste réseau de contacts avec des entraîneurs, des universités et des collèges.</li>
        <li>Promotion efficace du profil et des vidéos de l'étudiant-athlète auprès des entraîneurs potentiels.</li>
        <li>Conseils d'experts sur les meilleures options en fonction des préférences, des objectifs et du budget de l'étudiant-athlète.</li>
        <li>Négociation des meilleures offres de bourses au nom de l'étudiant-athlète.</li>
        <li>Aide à la demande de documents nécessaires, tels que visa, relevé de notes, diplôme et tests d'admission.</li>
        <li>Préparation à la vie d'étudiant-athlète pendant sa bourse, y compris culture, langue, règles et conseils.</li>
        <li>Suivi et accompagnement pendant la période de la bourse.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vous voulez explorer les opportunités de bourses et atteindre vos objectifs sportifs et académiques ? <Link to="/fr/Services-Produits">Consultez la gamme complète</Link> des services offerts par Sport Scholarships America et planifiez une consultation dès aujourd'hui.
    </MKTypography>
  </MKBox>
</MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;