import React, { useState } from 'react';

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Presentation page components
import ExampleCard from "./ExampleCard";

// Data
import data from "./data/designBlocksData";

function DesignBlocks() {
  const [sortOrder, setSortOrder] = useState("Popularity");

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

  const sortedData = data.map((category) => ({
    ...category,
    items: [...category.items].sort((a, b) => {
      if (sortOrder === "Popularity") {
        return a.popularity - b.popularity;
      }
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (sortOrder === "A-Z") {
        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
      } else {
        return nameA > nameB ? -1 : nameA < nameB ? 1 : 0;
      }
    }),
  }));
  

  const renderData = sortedData.map(({ title, description, items }) => (
    <Grid container spacing={3} sx={{ mb: 10 }} key={title}>
      <Grid item xs={12} lg={3}>
        <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
          <MKTypography variant="h3" fontWeight="bold" mb={1}>
            {title}
          </MKTypography>
          <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
            {description}
          </MKTypography>
        </MKBox>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Grid container spacing={3}>
        {items.map(({ image, name, count, badgeContent, secondBadgeContent, altText, route }) => (
            <Grid item xs={12} md={4} sx={{ mb: 2 }} key={name}>
              <Link to={route}>
                <ExampleCard image={image} name={name} count={count} badgeContent={badgeContent} secondBadgeContent={secondBadgeContent} altText={altText}  />
              </Link>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  ));

  return (
    <MKBox component="section" my={0} py={0}>
      <Container>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid
            container
            item
            xs={12}
            lg={6}
            flexDirection="column"
            alignItems="center"
            sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
          >
         
            <MKTypography variant="h2" fontWeight="bold">
              Visão Geral dos Esportes Universitários
            </MKTypography>
            <MKTypography variant="body1" color="text">
              Inicie sua jornada: Uma visão geral dos esportes que tornam seu sonho de uma bolsa de estudos esportiva nos Estados Unidos uma realidade.
            </MKTypography>
          </Grid>
          <Grid item>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="sort-order-label">Ordenar</InputLabel>
              <Select
                labelId="sort-order-label"
                id="sort-order"
                value={sortOrder}
                label="Ordenar"
                onChange={handleSortChange}
              >
                <MenuItem value="A-Z">A-Z</MenuItem>
                <MenuItem value="Z-A">Z-A</MenuItem>
                <MenuItem value="Popularity">Popularidade</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Container>
      <Container sx={{ mt: 6 }}>{renderData}</Container>
    </MKBox>
  );
}

export default DesignBlocks;
