import americanFootball from "assets/images/SportsIcons/americanfootball.png";
import athletics from "assets/images/SportsIcons/atletiek.png";
import basketball from "assets/images/SportsIcons/basketbal.png";
import golf from "assets/images/SportsIcons/golf.png";
import fieldHockey from "assets/images/SportsIcons/hockey.png"; // Updated to Field Hockey
import baseball from "assets/images/SportsIcons/honkbal.png";
import rowing from "assets/images/SportsIcons/roeien.png";
import softball from "assets/images/SportsIcons/softbal.png"
import tennis from "assets/images/SportsIcons/tennis.png";
import gymnastics from "assets/images/SportsIcons/turnen.png";
import soccer from "assets/images/SportsIcons/voetbal.png";
import volleyball from "assets/images/SportsIcons/volleybal.png";
import waterPolo from "assets/images/SportsIcons/waterpolo.png";
import wrestling from "assets/images/SportsIcons/worstelen.png";
import swimming from "assets/images/SportsIcons/zwemmen.png";
import iceHockey from "assets/images/SportsIcons/ijshockey.png";

export default [
  {
    title: "Sports",
    description: "Une sélection de 16 sports pour lesquels je propose des conseils pour une bourse sportive",
    items: [
      {
        image: americanFootball,
        name: "Football Américain",
        badgeContent: "Hommes",
        secondBadgeContent: "",
        route: "/fr/Tous-les-sports/Football-Américain",
        popularity: 13,
        altText: "Icône du football américain"
      },
      {
        image: athletics,
        name: "Athlétisme",
        badgeContent: "Hommes",
        secondBadgeContent: "Femmes",
        route: "/fr/Tous-les-sports/Athlétisme",
        popularity: 5,
        altText: "Icône de l'athlétisme"
      },
      {
        image: basketball,
        name: "Basketball",
        badgeContent: "Hommes",
        secondBadgeContent: "Femmes",
        route: "/fr/Tous-les-sports/Basketball",
        popularity: 3,
        altText: "Icône du basketball"
      },
      {
        image: golf,
        name: "Golf",
        badgeContent: "Hommes",
        secondBadgeContent: "Femmes",
        route: "/fr/Tous-les-sports/Golf",
        popularity: 8,
        altText: "Icône du golf"
      },
      {
        image: gymnastics,
        name: "Gymnastique",
        badgeContent: "Hommes",
        secondBadgeContent: "Femmes",
        route: "/fr/Tous-les-sports/Gymnastique",
        popularity: 7,
        altText: "Icône de la gymnastique"
      },
      {
        image: iceHockey,
        name: "Hockey sur Glace",
        badgeContent: "Hommes",
        secondBadgeContent: "Femmes",
        route: "/fr/Tous-les-sports/Hockey-sur-glace",
        popularity: 12,
        altText: "Icône du hockey sur glace"
      },
      {
        image: fieldHockey, // Mis à jour en Hockey sur Gazon
        name: "Hockey", // Mis à jour en Hockey sur Gazon
        badgeContent: "",
        secondBadgeContent: "Femmes",
        route: "/fr/Tous-les-sports/Hockey", // Mis à jour en Hockey sur Gazon
        popularity: 11,
        altText: "Icône du hockey sur gazon" // Mis à jour en Hockey sur Gazon
      },
      {
        image: baseball,
        name: "Baseball",
        badgeContent: "Hommes",
        secondBadgeContent: "",
        route: "/fr/Tous-les-sports/Baseball",
        popularity: 15,
        altText: "Icône du baseball"
      },
      {
        image: rowing,
        name: "Aviron",
        badgeContent: "Hommes",
        secondBadgeContent: "Femmes",
        route: "/fr/Tous-les-sports/Aviron",
        popularity: 9,
        altText: "Icône de l'aviron"
      },
      {
        image: swimming,
        name: "Natation",
        badgeContent: "Hommes",
        secondBadgeContent: "Femmes",
        route: "/fr/Tous-les-sports/Natation",
        popularity: 4,
        altText: "Icône du Natation"
      },
      {
        image: softball,
        name: "Softball",
        badgeContent: "",
        secondBadgeContent: "Femmes",
        route: "/fr/Tous-les-sports/Softball",
        popularity: 14,
        altText: "Icône du softball"
      },
      {
        image: tennis,
        name: "Tennis",
        badgeContent: "Hommes",
        secondBadgeContent: "Femmes",
        route: "/fr/Tous-les-sports/Tennis",
        popularity: 2,
        altText: "Icône du tennis"
      },
      {
        image: volleyball,
        name: "Volleyball",
        badgeContent: "Hommes",
        secondBadgeContent: "Femmes",
        route: "/fr/Tous-les-sports/Volleyball",
        popularity: 6,
        altText: "Icône du volleyball"
      },
      {
        image: waterPolo,
        name: "Water Polo",
        badgeContent: "Hommes",
        secondBadgeContent: "Femmes",
        route: "/fr/Tous-les-sports/Water-polo",
        popularity: 10,
        altText: "Icône du water polo"
      },
      {
        image: wrestling,
        name: "Lutte",
        badgeContent: "Hommes",
        secondBadgeContent: "Femmes",
        route: "/fr/Tous-les-sports/Lutte",
        popularity: 16,
        altText: "Icône de la lutte"
      },
    ],
  },
];
