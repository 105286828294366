/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Basketbal1.jpeg";
import Image2 from "assets/images/Sports_Images/Basketbal2.webp";
import Image3 from "assets/images/Sports_Images/Basketbal3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import { Link } from 'react-router-dom';

import "../../../../H1asH3.css"

// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Borsa di studio per il basket negli Stati Uniti: Come Studiare e Giocare a Basket in America', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capitolo 1: Storia e Sviluppo del Basket Universitario', isSubheader: false },
    { id: '1.1', title: '1.1 Anni Iniziali', isSubheader: true },
    { id: '1.2', title: '1.2 Crescita e Professionalizzazione', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capitolo 2: Il Basket Universitario Oggi', isSubheader: false },
    { id: '2.1', title: '2.1 Competizioni e Conferenze Importanti', isSubheader: true },
    { id: '2.2', title: '2.2 Programmi di Eccellenza e Squadre Prestigiose', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capitolo 3: Dal College alla NBA', isSubheader: false },
    { id: '3.1', title: '3.1 Il Percorso verso la NBA', isSubheader: true },
    { id: '3.2', title: '3.2 Altre Opportunità Professionali', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capitolo 4: Influenze e Opportunità Internazionali', isSubheader: false },
    { id: '4.1', title: '4.1 Giocatori Stranieri nel Basket Universitario', isSubheader: true },
    { id: '4.2', title: '4.2 Giocatori Americani all\'Estero', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capitolo 5: Borse di Studio Sportive e il Futuro degli Atleti', isSubheader: false },
    { id: '5.1', title: '5.1 L\'Importanza delle Borse di Studio', isSubheader: true },
    { id: '5.2', title: '5.2 Come Sport Scholarships America Può Aiutare', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };





  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indice
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Nascondi</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostra</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>






















          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Borsa di studio per il basket negli Stati Uniti: Come Studiare e Giocare a Basket in America
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Stadio vibrante dell'Università dell'Arizona durante una partita di pallacanestro universitaria"
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Giorno di gioco allo Stadio dell'Università dell'Arizona
      </figcaption>
    </figure>
    Il basket è uno degli sport più popolari negli Stati Uniti. Milioni di persone seguono le partite della NBA, la lega professionale di basket, e della NCAA, la lega universitaria di basket. Il basket non è solo un gioco eccitante da guardare, ma anche un ottimo modo per svilupparsi come giocatore e come persona.
    <br/>
    <br/>
    Se sei un giovane giocatore di basket che sogna una carriera nella NBA, o semplicemente vuoi goderti giocare a basket a un livello elevato, allora una borsa di studio per il basket in America potrebbe essere per te. Una borsa di studio per il basket è il supporto finanziario che ricevi da un'università americana per studiare e giocare a basket lì. Con una borsa di studio per il basket, non solo puoi ottenere il tuo diploma accademico, ma anche migliorare i tuoi talenti nel basket sotto la guida professionale.
    <br/>
    <br/>
    Ma come si ottiene una borsa di studio per il basket in America? Quali sono i vantaggi e le sfide del giocare a basket universitario? E quali sono le possibilità dopo la tua carriera universitaria? In questo articolo, risponderemo a tutte queste domande e ti diremo tutto ciò che devi sapere sulle borse di studio per il basket in America. Discuteremo anche il ruolo di Sportbeursamerika.nl, un'organizzazione specializzata nell'aiutare i giovani giocatori a realizzare il loro sogno di una borsa di studio per il basket.
    <br/>
    <br/>
    Vuoi saperne di più sulle borse di studio per il basket in America? Continua a leggere!
  </MKTypography>
  {/* Aggiungi elenco puntato o altro contenuto se necessario */}
</MKBox>
{/* Capitoli principali e sottocapitoli con testo di esempio */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 1: Storia e Sviluppo del Basket Universitario
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Il basket è uno sport relativamente giovane, inventato nel 1891 da James Naismith, un insegnante canadese di educazione fisica. Concepii il gioco come un'attività indoor per i suoi studenti durante i mesi invernali. Il gioco divenne rapidamente popolare e si diffuse in varie scuole e università del Nord America.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Anni Iniziali
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La prima partita ufficiale di basket di livello universitario si svolse nel 1895 tra l'Università di Chicago e l'Università dell'Iowa. Nel 1901, fu istituita la prima conferenza intercollegiale, la Western Conference, poi nota come Big Ten Conference. Nel 1906, fu organizzato il primo campionato nazionale dall'Amateur Athletic Union (AAU), che dominò il basket universitario fino al 1936.
      <br/>
      <br/>
      Nel 1939 si tenne il primo torneo NCAA, con otto squadre in competizione per il titolo. Il torneo fu vinto dall'Oregon, che sconfisse l'Ohio State 46-33. Il torneo crebbe diventando un evento annuale che attirava sempre più l'attenzione dei tifosi e dei media.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crescita e Professionalizzazione
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dopo la Seconda Guerra Mondiale, il basket universitario aumentò in popolarità e qualità. Emersero nuove stelle, come Bill Russell, Wilt Chamberlain, Oscar Robertson e Kareem Abdul-Jabbar, che in seguito si fecero un nome anche nella NBA. Nacquero nuove competizioni e conferenze, come il National Invitation Tournament (NIT), l'Atlantic Coast Conference (ACC) e il Southeastern Conference (SEC).
      <br/>
      <br/>
      Nel 1957 si svolse una delle partite più memorabili nella storia del basket universitario quando i North Carolina Tar Heels sconfissero i Kansas Jayhawks 54-53 dopo tre tempi supplementari. La partita è considerata un punto di svolta nel basket universitario, mostrando quanto il gioco potesse essere emozionante e drammatico.
      <br/>
      <br/>
      Un'altra partita storica avvenne nel 1966 quando i Texas Western Miners sconfissero i Kentucky Wildcats 72-65. La partita fu rivoluzionaria perché i Texas Western furono la prima squadra composta interamente da giocatori neri, mentre i Kentucky erano interamente bianchi. La partita è vista come un simbolo della lotta per i diritti civili e dell'integrazione in America.
      <br/>
      <br/>
      Nel 1979 si tenne il primo torneo NCAA trasmesso in diretta, con un numero record di spettatori. La finale fu tra i Michigan State Spartans e gli Indiana State Sycamores, guidati dalle future leggende NBA Magic Johnson e Larry Bird, rispettivamente. Michigan State vinse la partita 75-64 ed è considerata una delle migliori finali di sempre.
      <br/>
      <br/>
      Negli anni '80 e '90, il basket universitario continuò a crescere ed evolversi, con nuove regole, stili e strategie. Emersero nuove rivalità, come Duke contro North Carolina, Georgetown contro Syracuse e UCLA contro Arizona. Ci furono anche nuove stelle, come Michael Jordan, Patrick Ewing, Shaquille O'Neal e Tim Duncan, che elevarono il basket universitario a un livello superiore.
    </MKTypography>
  </MKBox>
</MKBox>












                                              





                                  







<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 2: Il Basket Universitario Oggi
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Il basket universitario è uno degli sport più popolari e prestigiosi in America oggi. Ogni anno, le partite attirano milioni di fan negli stadi e davanti ai televisori. Il basket universitario è anche una fonte principale di talento per la NBA, che recluta centinaia di giocatori ogni anno dalle squadre universitarie.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Competizioni e Conferenze Importanti
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Steph Curry in azione per il Davidson College, eccellendo nel basket universitario."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Stephen Curry in azione come giocatore per il Davidson College
        </figcaption>
      </figure>
      Il basket universitario è principalmente organizzato dalla National Collegiate Athletic Association (NCAA), che supervisiona più di 1.000 università e college. La NCAA suddivide le squadre in tre divisioni, con la Division I che è la più alta. La Division I è composta da 32 conferenze, ognuna delle quali comprende squadre che sono geograficamente o storicamente connesse.
      <br/>
      <br/>
      Le conferenze sono importanti poiché determinano quali squadre giocano tra loro durante la stagione regolare, che va da novembre a marzo. Ogni conferenza ha il suo calendario, regole e campionato. I campioni della conferenza guadagnano automaticamente un posto nel torneo NCAA, il culmine del basket universitario.
      <br/>
      <br/>
      Il torneo NCAA, anche conosciuto come March Madness, è un torneo ad eliminazione diretta che si tiene ogni anno tra marzo e aprile. È composto da 68 squadre, di cui 32 sono campioni di conferenza e 36 sono inviti "at-large", selezionati da un comitato in base alle loro prestazioni durante la stagione. Le squadre sono divise in quattro regioni e classificate da 1 a 16.
      <br/>
      <br/>
      Il torneo inizia con quattro partite preliminari, in cui le squadre con il ranking più basso competono per gli ultimi quattro posti nel torneo principale. Il torneo principale consiste in sei turni, eliminando una squadra in ogni turno fino a che ne rimane una sola. Le ultime quattro squadre, conosciute come il Final Four, giocano in una location neutrale per determinare il campione nazionale.
      <br/>
      <br/>
      Il torneo NCAA è uno degli eventi sportivi più eccitanti e imprevedibili al mondo. Ogni anno ci sono sorprese, drammi e sensazioni, conosciute come sorprese, canestri allo scadere e storie di Cenerentola. Il torneo attira milioni di spettatori e scommettitori, che cercano di predire il vincitore.
      <br/>
      <br/>
      Oltre al torneo NCAA, ci sono altre competizioni significative nel basket universitario, come il NIT, il CBI, il CIT e il torneo NAIA. Queste competizioni offrono opportunità per le squadre che non si sono qualificate per il torneo NCAA o che vogliono acquisire ulteriore esperienza.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programmi di Eccellenza e Squadre Prestigiose
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Il basket universitario presenta molti programmi di eccellenza e squadre prestigiose, distinte dalla loro storia, tradizione, successo e influenza. Alcuni di questi programmi sono:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          UCLA Bruins: Il programma più vincente nella storia del basket universitario, con 11 titoli nazionali, di cui 10 sotto la guida dell'allenatore John Wooden negli anni '60 e '70. UCLA ha prodotto molti giocatori famosi, come Kareem Abdul-Jabbar, Bill Walton e Reggie Miller.
        </li>
        <li>
          Kentucky Wildcats: Il secondo programma più vincente nella storia del basket universitario, con otto titoli nazionali e 17 apparizioni al Final Four. Kentucky è nota per i suoi appassionati tifosi e la sua capacità di attrarre i migliori reclute.
        </li>
        <li>
          North Carolina Tar Heels: Il terzo programma più vincente nella storia del basket universitario, con sei titoli nazionali e 20 apparizioni al Final Four. North Carolina ha avuto molti allenatori leggendari, come Dean Smith e Roy Williams, e giocatori come Michael Jordan, James Worthy e Vince Carter.
        </li>
        <li>
          Duke Blue Devils: Uno dei programmi più dominanti nel basket universitario moderno, con cinque titoli nazionali e 16 apparizioni al Final Four dal 1986. Duke è nota per il suo allenatore Mike Krzyzewski, l'allenatore più vincente nella storia del basket universitario, e giocatori come Christian Laettner, Grant Hill e Zion Williamson.
        </li>
        <li>
          Kansas Jayhawks: Uno dei programmi più tradizionali e influenti nel basket universitario, con tre titoli nazionali e 15 apparizioni al Final Four. Kansas è il luogo di nascita del gioco, poiché James Naismith è stato il suo primo allenatore. Kansas ha anche avuto molti grandi allenatori, come Phog Allen, Larry Brown e Bill Self, e giocatori come Wilt Chamberlain, Danny Manning e Paul Pierce.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Questi programmi sono solo alcuni esempi delle numerose squadre eccellenti che arricchiscono il basket universitario. Ogni anno nuovi sfidanti e sorprese rendono il gioco emozionante e imprevedibile.
    </MKTypography>
  </MKBox>
</MKBox>












                                              






                                  







<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 3: Dal College alla NBA
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Il basket universitario non è solo uno sport ma anche un'istruzione. Molti giocatori che partecipano al basket universitario mirano a raggiungere la NBA, la migliore e più prestigiosa lega professionistica di basket al mondo. Tuttavia, la NBA non è facile da raggiungere e richiede molto talento, duro lavoro e fortuna.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Il Percorso verso la NBA
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Per essere idoneo per la NBA, un giocatore deve avere almeno 19 anni e essersi diplomato al liceo almeno un anno prima. Questo significa che la maggior parte dei giocatori che aspirano a raggiungere la NBA vanno prima al college e giocano per almeno un anno. Questo è anche conosciuto come la regola del "one-and-done" perché molti giocatori entrano nel draft NBA dopo solo un anno di college.
      <br />
      <br />
      Il draft NBA è un evento annuale in cui le 30 squadre NBA si alternano nella selezione di un giocatore da un gruppo di giocatori disponibili. Il draft consiste in due turni, con ogni squadra che ha una scelta per turno. L'ordine delle scelte è determinato attraverso una lotteria per le squadre che non hanno fatto i playoff nella stagione precedente e mediante classifica inversa per le squadre playoff.
      <br />
      <br />
      Il draft è un momento significativo per i giocatori perché determina quale squadra detiene i loro diritti e quanto denaro possono guadagnare. I giocatori scelti nel primo turno ricevono un contratto garantito per almeno due anni, mentre quelli selezionati nel secondo turno non hanno garanzie. I giocatori che non vengono scelti possono cercare di ottenere un contratto tramite l'agenzia libera o unendosi alla G League, la lega di sviluppo della NBA.
      <br />
      <br />
      Tuttavia, il draft non è una garanzia di successo nella NBA. Molti giocatori scelti molto in alto non soddisfano le aspettative o subiscono infortuni. D'altra parte, ci sono anche molti giocatori che vengono scelti bassi o per niente ma eccellono comunque nella NBA. Un esempio ben noto è Giannis Antetokounmpo, che è stato selezionato 15º assoluto nel 2013 e è stato nominato MVP e campione NBA nel 2021.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Altre Opportunità Professionali
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Non tutti i giocatori che partecipano al basket universitario mirano a raggiungere la NBA. Alcuni giocatori scelgono di completare i loro diplomi accademici e perseguire altre carriere. Altri optano per giocare a basket professionistico in altri paesi o leghe.
      <br />
      <br />
      Ci sono molte leghe professionistiche di basket in tutto il mondo, che offrono livelli di qualità, stipendio e stile di vita variabili. Alcune di queste leghe includono:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          EuroLeague: La competizione europea per club più alta e prestigiosa, con 18 squadre provenienti da 10 paesi. EuroLeague è considerata la seconda miglior lega al mondo dopo la NBA.
        </li>
        <li>
          Chinese Basketball Association (CBA): La competizione per club cinese più alta e popolare, con 20 squadre provenienti da tutta la Cina. La CBA è nota per i suoi alti salari e grandi audience.
        </li>
        <li>
          Australian National Basketball League (NBL): La competizione per club australiana più alta e competitiva, con nove squadre provenienti dall'Australia e dalla Nuova Zelanda. La NBL è nota per il suo gioco veloce e fisico.
        </li>
        <li>
          Basketball Africa League (BAL): La competizione per club africana più nuova e ambiziosa, con 12 squadre provenienti da 12 paesi. La BAL è supportata dalla NBA e dalla FIBA e mira a sviluppare il basket nel continente.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Queste leghe sono solo alcuni esempi delle numerose opportunità disponibili per i giocatori di basket professionistico al di fuori della NBA. Molti giocatori godono di vivere e giocare in culture diverse continuando a perseguire la loro passione per il gioco.
    </MKTypography>
  </MKBox>
</MKBox>













                                              






                                  








<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 4: Influenze e Opportunità Internazionali
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Il basket è uno sport globale che unisce persone di diverse nazionalità, culture e background. Il basket universitario è un esempio lampante di questo, poiché attira sia giocatori stranieri che americani, offrendo opportunità. In questo capitolo, esploreremo le influenze e le opportunità internazionali nel basket universitario.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Giocatori Stranieri nel Basket Universitario
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Partita di basket NCAA Final Four con una folla di quasi 80.000 fan nello stadio."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
         }}>
        Quasi 80.000 spettatori durante una partita di campionato nazionale
        </figcaption>
      </figure>
      Il basket universitario ha sempre accolto giocatori stranieri che hanno contribuito con il loro stile, le loro abilità e le loro prospettive al gioco. Secondo la NCAA, nella stagione 2019-2020, c'erano oltre 1.200 giocatori stranieri nella Divisione I, provenienti da 101 paesi. I paesi di origine più comuni erano Canada, Australia, Nigeria, Francia e Regno Unito.
      <br />
      <br />
      I giocatori stranieri scelgono il basket universitario per vari motivi. Alcuni vogliono combinare la loro formazione accademica con le loro ambizioni sportive. Altri mirano ad adattarsi alla cultura e allo stile di vita americani. Altri ancora cercano di aumentare le loro possibilità di raggiungere la NBA o di rappresentare le loro squadre nazionali.
      <br />
      <br />
      I giocatori stranieri hanno avuto un impatto significativo sul basket universitario, sia individualmente che collettivamente. Alcuni dei giocatori stranieri più riusciti e influenti nella storia del basket universitario includono:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Hakeem Olajuwon: Un giocatore nigeriano che ha giocato per gli Houston Cougars dal 1981 al 1984. Ha guidato la sua squadra a tre apparizioni consecutive alle Final Four e ha vinto il premio di giocatore nazionale dell'anno nel 1984. È stato la prima scelta assoluta nel draft NBA del 1984 ed è diventato uno dei più grandi centri di tutti i tempi.
        </li>
        <li>
          Andrew Bogut: Un giocatore australiano che ha giocato per gli Utah Utes dal 2003 al 2005. Ha vinto il premio di giocatore nazionale dell'anno nel 2005 e ha portato la sua squadra ai Sweet Sixteen. È stato la prima scelta assoluta nel draft NBA del 2005 ed è diventato un All-Star e campione.
        </li>
        <li>
          Luka Garza: Un giocatore americano con radici bosniache che ha giocato per gli Iowa Hawkeyes dal 2017 al 2021. Ha vinto il premio di giocatore nazionale dell'anno due volte, nel 2020 e nel 2021, ed è diventato il miglior marcatore di tutti i tempi della sua università. È stato selezionato 52º assoluto nel draft NBA del 2021 e è considerato uno dei più grandi giocatori universitari di sempre.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Questi giocatori sono solo alcuni esempi dei molti giocatori stranieri che si sono distinti nel basket universitario. Ogni anno emergono nuovi talenti, arricchendo il gioco.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Giocatori Americani all'Estero
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Non solo i giocatori stranieri vengono in America per studiare e giocare a basket, ma anche i giocatori americani vanno all'estero per gli stessi motivi. Alcuni giocatori americani scelgono di saltare o abbreviare le loro carriere al liceo o al college per giocare professionalmente in altri paesi o leghe.
      <br />
      <br />
      Le ragioni di questa scelta possono variare, ma includono:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Finanziarie: Alcuni giocatori vogliono guadagnare denaro con il loro talento invece di aspettare un contratto NBA o una borsa di studio.
        </li>
        <li>
          Atletiche: Alcuni giocatori vogliono svilupparsi contro avversari più forti invece di dominare al loro livello.
        </li>
        <li>
          Personali: Alcuni giocatori vogliono viaggiare, imparare nuove culture o mantenere legami familiari.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      I giocatori americani che vanno all'estero hanno esperienze e risultati diversi. Alcuni riescono a realizzare il loro sogno NBA, mentre altri godono di una carriera lunga e di successo. Alcuni si adattano bene al loro nuovo ambiente, mentre altri hanno difficoltà con la lingua, il cibo o lo stile di vita.
      <br />
      <br />
      Alcuni esempi di giocatori americani che sono andati all'estero includono:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Brandon Jennings: Un giocatore che ha saltato il liceo per giocare per la Lottomatica Roma in Italia nel 2008. È stato la 10ª scelta assoluta nel draft NBA del 2009 e ha giocato per 10 stagioni nella NBA.
        </li>
        <li>
          Emmanuel Mudiay: Un giocatore che ha saltato il college per giocare per i Guangdong Southern Tigers in Cina nel 2014. È stato la 7ª scelta assoluta nel draft NBA del 2015 e attualmente gioca per gli Utah Jazz.
        </li>
        <li>
          LaMelo Ball: Un giocatore che ha saltato il liceo e il college per giocare per gli Illawarra Hawks in Australia nel 2019. È stato la 3ª scelta assoluta nel draft NBA del 2020 e ha vinto il premio di Rookie dell'Anno.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Questi giocatori sono solo alcuni esempi dei molti giocatori americani che hanno scelto un percorso alternativo per il basket professionistico. Ogni anno, nuovi avventurieri globalizzano il gioco.
    </MKTypography>
  </MKBox>
</MKBox>











                                              






                                  






<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 5: Borse di Studio Sportive e il Futuro degli Atleti
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le borse di studio sportive offrono numerose opportunità ai giovani atleti di mostrare il loro talento e sviluppare le loro abilità. Una delle opportunità più significative è ottenere una borsa di studio sportiva per studiare e competere a livello universitario. In questo capitolo, discuteremo dell'importanza delle borse di studio sportive e di come Sport Scholarships America può aiutarti a realizzare il tuo sogno.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 L'Importanza delle Borse di Studio
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Una borsa di studio sportiva è un sostegno finanziario fornito da un'università a uno studente-atleta per combinare studio e sport. Può coprire i costi di iscrizione, libri, alloggio, pasti e altre spese. Inoltre, può offrire vantaggi come il tutorato accademico, l'assistenza medica, le strutture di allenamento e l'attrezzatura.
      <br />
      <br />
      Ottenere una borsa di studio sportiva è competitivo, con molti fattori che influenzano il processo di assegnazione, tra cui risultati accademici, prestazioni sportive, personalità, motivazione, leadership e adattabilità. È quindi importante prepararsi bene e distinguersi dalla concorrenza.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Come Sport Scholarships America Può Aiutare
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo di Sport Scholarships America, raffigurante attrezzature sportive e una toga, simbolo del focus sulle borse di studio sportive per gli studenti-atleti."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo di Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America è un'organizzazione specializzata nell'orientamento dei giovani talenti sportivi verso borse di studio sportive. Con esperti appassionati ed esperti, Sport Scholarships America supporta gli studenti-atleti in ogni fase del percorso, dall'orientamento iniziale alla collocazione finale. I loro servizi includono:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Un'intervista personale per discutere i desideri, le possibilità e le aspettative dello studente-atleta.</li>
        <li>Una valutazione professionale del livello accademico e sportivo dello studente-atleta.</li>
        <li>Una vasta rete di contatti con allenatori, università e college.</li>
        <li>Promozione efficace del profilo e dei video dello studente-atleta presso allenatori potenziali.</li>
        <li>Consulenza esperta sulle migliori opzioni in base alle preferenze, agli obiettivi e al budget dello studente-atleta.</li>
        <li>Negoziazione delle migliori offerte di borse di studio per conto dello studente-atleta.</li>
        <li>Assistenza nella richiesta dei documenti necessari, come visto, trascrizione, diploma e test di ammissione.</li>
        <li>Preparazione alla vita dello studente-atleta durante la borsa di studio, compresa cultura, lingua, regole e consigli.</li>
        <li>Assistenza e monitoraggio durante il periodo di borsa di studio.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vuoi esplorare le opportunità di borse di studio e raggiungere i tuoi obiettivi sportivi e accademici? <Link to="/it/Servizi-Prodotti">Consulta la gamma completa</Link> dei servizi offerti da Sport Scholarships America e prenota una consultazione oggi stesso.
    </MKTypography>
  </MKBox>
</MKBox>

          










        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;