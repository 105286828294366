/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState } from 'react'; // Step 1: Import useState


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";


// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Logos/NCAA.png";
import post3 from "assets/images/Logos/NJCAA.png";



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {

  const articleStructure = [
    { id: 'intro', title: 'La NAIA: Un Percorso Alternativo nello Sport Universitario in America', isSubheader: false },
  
    { id: 'Hoofdstuk 1', title: 'Origine e Sviluppo della NAIA', isSubheader: true },
    { id: '1.1', title: 'La NAIA e le sue Divisioni Uniche', isSubheader: true },
    { id: '1.2', title: 'La Missione Accademica', isSubheader: true },
  
    { id: 'Hoofdstuk 2', title: 'Finanze, Media e Visibilità', isSubheader: true },
    { id: '2.1', title: 'Sfide e Prospettive Future', isSubheader: true },
    { id: '2.2', title: 'In Conclusione', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
     <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contents
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Nascondi</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostra</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="intro" mb={3}>
              <MKTypography variant="h1" className="h1-as-h3" mb={2}>La NAIA: Un Percorso Alternativo nello Sport Universitario in America

</MKTypography>
              </MKBox>
              <MKBox id="Hoofdstuk 1" mb={3}>

              <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Origine e Sviluppo della NAIA
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              La National Association of Intercollegiate Athletics, nota come NAIA, è un'entità cruciale nel panorama dello sport universitario americano, sebbene meno conosciuta rispetto alla NCAA. Fondata nel 1937, la NAIA si è sviluppata in un'organizzazione rinomata che offre agli studenti-atleti un'opportunità unica di realizzare le loro aspirazioni sportive e accademiche.

<br />
                <br />
                Fin dall'inizio, la NAIA si è concentrata nell'offrire opportunità alle istituzioni educative più piccole, ponendo l'accento sulla creazione di un'esperienza equilibrata per gli studenti-atleti. L'organizzazione promuove sia gli sport competitivi che l'eccellenza accademica, con la convinzione che le due possano andare di pari passo.

</MKTypography>
<MKBox id="1.1" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
                <br />
                La NAIA e le sue Divisioni Uniche
                </MKTypography>
                </MKBox>

                <MKTypography variant="body1" fontWeight="light" color="text"> 
                A differenza della NCAA, la NAIA è organizzata in modo più compatto, con una suddivisione in due divisioni che offrono entrambe una ricca combinazione di sport e istruzione:

<br /><br />
  <MKTypography component="span" variant="body1" fontWeight="bold">
    Divisione I:{' '}
  </MKTypography>
  In questa divisione troviamo istituti che offrono un livello più elevato di borse di studio sportive e in cui la competizione sportiva è molto competitiva. Nonostante le dimensioni più ridotte rispetto alle istituzioni della Divisione I della NCAA, la Divisione I della NAIA offre una piattaforma per gli atleti di alto livello per mostrare le loro abilità e svilupparsi.  
  <br />
  <br />
  <MKTypography component="span" variant="body1" fontWeight="bold">
    Divisione II:{' '}
  </MKTypography>
  Questa divisione mette ancora più enfasi sull'equilibrio tra sport, accademici e coinvolgimento del campus. Le borse di studio sportive sono più limitate, ma le opportunità per gli studenti-atleti di eccellere sia nello sport che nello studio sono abbondanti. 
  </MKTypography>

  <MKBox id="1.2" mb={3}>

  <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              La Missione Accademica
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              La NAIA attribuisce grande importanza alle performance accademiche dei suoi studenti-atleti. Sono stati stabiliti rigorosi standard accademici per garantire la partecipazione agli sport, e le scuole affiliate sono impegnate nel fornire il necessario supporto accademico. Questo si traduce in un ambiente in cui gli studenti-atleti sono incoraggiati a perseguire l'eccellenza in ogni aspetto della loro vita.

                <br />
                </MKTypography>



                                  







                <MKBox id="Hoofdstuk 2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Finanze, Media e Visibilità
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
Sebbene la NAIA possa non competere finanziariamente con i giganteschi guadagni della NCAA, svolge comunque un ruolo importante nel finanziare borse di studio per gli atleti e migliorare le strutture. Inoltre, la NAIA sta guadagnando visibilità grazie ai servizi di streaming e ai social media, contribuendo a diffondere il messaggio di un equilibrio tra atletica e accademici.

<br />
</MKTypography>
<MKBox id="2.1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Sfide e Prospettive Future
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
Come la NCAA, la NAIA affronta sfide, tra cui la navigazione della complessità dello status dilettantistico degli studenti atleti e il garantire l'equità nello sport. Tuttavia, con la sua posizione unica e l'impegno nello sviluppo degli studenti atleti, la NAIA è ben posizionata per continuare a fare un impatto positivo sulla vita di molti giovani atleti.

</MKTypography>
<MKBox id="2.2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
In Conclusione
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
La National Association of Intercollegiate Athletics offre un'alternativa preziosa e unica nel mondo dello sport universitario americano. Con il suo focus sull'equilibrio, sull'eccellenza accademica e sulla competizione atletica, la NAIA rimane una forza essenziale nel plasmare il futuro degli studenti atleti. Guardando avanti e continuando a evolversi, il suo impegno nello sviluppo dei giovani rimane saldo, lasciando un'impronta duratura e positiva nel mondo dello sport e dell'istruzione americani.
<br />


</MKTypography>


              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="NCAA"
                    description="NCAA: Il Cuore dello Sport Universitario in America"
                    action={{
                      type: "internal",
                      route: "/it/NCAA",
                      color: "info",
                      label: "Leggi Di Più",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="NJCAA"
                    description="NJCAA: Il Trampolino per Grandi College e la NCAA"
                    action={{
                      type: "internal",
                      route: "/it/NJCAA",
                      color: "info",
                      label: "Leggi Di Più",
                    }}
                  />
                </Grid>
              </Grid>

            </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;