/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";



// Images
import Image1 from "assets/images/Sports_Images/Soccer1.jpg";
import Image2 from "assets/images/Sports_Images/Soccer2.jpg";
import Image3 from "assets/images/Sports_Images/Soccer3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Jogar Futebol e Estudar na América: Um Sonho Realizado', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capítulo 1: A História e o Desenvolvimento do Futebol na América', isSubheader: false },
    { id: '1.1', title: '1.1 Primeiros Anos e o Papel das Universidades', isSubheader: true },
    { id: '1.2', title: '1.2 Crescimento e Profissionalização', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capítulo 2: O Futebol Universitário Hoje', isSubheader: false },
    { id: '2.1', title: '2.1 Competições e Conferências Importantes para o Futebol', isSubheader: true },
    { id: '2.2', title: '2.2 Programas Principais e Equipes Prestigiadas para o Futebol', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capítulo 3: Do Universitário para os Profissionais', isSubheader: false },
    { id: '3.1', title: '3.1 O Caminho para o Profissionalismo', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capítulo 4: A Influência do Futebol Internacional', isSubheader: false },
    { id: '4.1', title: '4.1 Influências Estrangeiras', isSubheader: true },
    { id: '4.2', title: '4.2 Americanos no Exterior', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 A Importância das Bolsas de Estudo', isSubheader: true },
    { id: '5.2', title: '5.2 Como a Sport Scholarships America Pode Ajudar', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Conteúdo
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Esconder</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























{/* Introdução */}
<MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Jogar Futebol e Estudar na América: Um Sonho Realizado
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Estádio da Creighton, com capacidade para mais de 7000 espectadores, durante uma partida de futebol universitário."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Estádio da Creighton: lar de mais de 7000 fãs de futebol.
      </figcaption>
    </figure>
    O futebol é um dos esportes mais populares do mundo, mas na América, por muito tempo, foi ofuscado por outros esportes como basquete, beisebol e futebol americano. No entanto, nas últimas décadas, o futebol cresceu tremendamente em popularidade e qualidade nos EUA, graças em parte ao sucesso da seleção nacional, ao surgimento da Major League Soccer (MLS) e à influência de estrelas internacionais.
    <br />
    <br />
    Mas você sabia que o futebol na América também oferece uma oportunidade única para jovens talentos perseguirem seus sonhos? Ao jogar futebol e estudar na América, você não só pode desenvolver suas habilidades atléticas, mas também obter um diploma acadêmico e vivenciar uma experiência inesquecível. Tudo isso é possível graças às bolsas de estudo em futebol que as universidades americanas oferecem a jogadores talentosos.
    <br />
    <br />
    Neste artigo, vamos contar tudo o que você precisa saber sobre jogar futebol e estudar na América, desde a história e o desenvolvimento do futebol nos EUA até os benefícios e oportunidades de uma bolsa de estudos em futebol. Também explicaremos como o Sportbeursamerika.nl pode ajudá-lo a transformar esse sonho em realidade. Portanto, continue lendo e descubra como você pode aproveitar essa oportunidade única!
  </MKTypography>
</MKBox>

{/* Capítulo 1 */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 1: A História e o Desenvolvimento do Futebol na América
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O futebol, ou soccer como os americanos o chamam, não é um esporte tão novo nos EUA quanto você pode pensar. Na verdade, a primeira partida oficial de futebol na América ocorreu em 1869, entre duas equipes universitárias: Princeton e Rutgers. Desde então, o futebol teve uma longa e fascinante história nos EUA, que discutiremos neste capítulo.
  </MKTypography>
  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Primeiros Anos e o Papel das Universidades
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      No final do século XIX e início do século XX, o futebol era especialmente popular entre os estudantes, que jogavam o jogo de acordo com várias regras e formatos. Por exemplo, havia equipes que jogavam com 11, 15 ou até mesmo 25 jogadores, e às vezes usavam elementos de rúgbi ou futebol americano.
      <br />
      <br />
      Para criar mais unidade e estrutura, a Associação Intercolegial de Atletas Amadores da América (IAAAA) foi fundada em 1905, precursora da Associação Atlética Intercolegial Nacional (NCAA). A NCAA é a principal organização que regula e organiza o futebol universitário nos EUA.
      <br />
      <br />
      Na primeira metade do século XX, o número de equipes de futebol universitário cresceu constantemente, e competições e campeonatos regionais também surgiram. Um dos mais famosos é a College Cup, realizada desde 1959 como o campeonato nacional masculino. Para as mulheres, a primeira College Cup foi realizada apenas em 1982, mas desde então, o futebol feminino viu um crescimento tremendo nos EUA, em parte graças ao sucesso da seleção nacional em torneios internacionais.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crescimento e Profissionalização
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Além do futebol universitário, o futebol profissional também se desenvolveu nos EUA, embora com mais altos e baixos. A primeira liga profissional foi a American Soccer League (ASL), fundada em 1921 e composta principalmente por equipes do Nordeste. A ASL teve alguns anos de sucesso, mas entrou em conflito com a federação americana de futebol sobre a organização e regulamentação do jogo. Isso levou a uma divisão e, eventualmente, ao fim da ASL em 1933.
      <br />
      <br />
      Após a Segunda Guerra Mundial, várias ligas tentaram reviver o futebol profissional, mas nenhuma delas sobreviveu por muito tempo. Somente em 1967 uma liga estável foi estabelecida: a North American Soccer League (NASL). A NASL trouxe grandes nomes para a América, incluindo Pelé, Franz Beckenbauer e Johan Cruyff, e atraiu grandes multidões para os estádios. No entanto, a NASL também não conseguiu superar problemas financeiros, competição de outros esportes e falta de interesse da mídia. A NASL deixou de existir em 1984.
      <br />
      <br />
      Foi necessário esperar até 1996 para que uma liga profissional fosse estabelecida novamente: a Major League Soccer (MLS). A MLS é atualmente a divisão mais alta do futebol americano, composta por 27 equipes dos EUA e do Canadá. A MLS evoluiu para uma liga competitiva e atraente, atraindo tanto jogadores domésticos quanto internacionais. A MLS também contribuiu para a popularização do futebol na América construindo estádios, apoiando o desenvolvimento da juventude e colaborando com outras ligas.
      <br />
      <br />
      O futebol profissional também influenciou o futebol universitário, e vice-versa. Por exemplo, existem jogadores que transitam para a MLS após suas carreiras universitárias, ou jogadores que retornam à universidade para completar seus estudos após a MLS. Também existem jogadores que jogam tanto em sua universidade quanto em um time profissional, graças a acordos especiais. Além disso, alguns times profissionais colaboram com programas universitários para recrutar e desenvolver talentos.
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  







{/* Capítulo 2 */}
<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 2: O Futebol Universitário Hoje
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Como vimos no capítulo anterior, o futebol universitário na América tem uma longa e rica tradição. Mas como ele se parece hoje? Quais competições e conferências existem? Quais programas e equipes são os mais prestigiados e bem-sucedidos? E como você, como jogador, pode se envolver nesse mundo emocionante? Neste capítulo, vamos lhe dar uma visão geral do futebol universitário hoje e mostrar o que esperar se você escolher uma bolsa de estudos em futebol na América.
  </MKTypography>
  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Competições e Conferências Importantes para o Futebol
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Jogador de futebol de Santa Clara em ação durante o torneio da NCAA."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Jogadores de futebol em ação durante partida do torneio da NCAA.
        </figcaption>
      </figure>
      Como mencionado anteriormente, a NCAA é a principal organização que regula e organiza o futebol universitário nos EUA. A NCAA é composta por três divisões: Divisão I, Divisão II e Divisão III. Cada divisão tem suas próprias regras, requisitos e bolsas de estudo para estudantes-atletas.
      <br />
      <br />
      A Divisão I é a divisão mais alta e competitiva, onde os melhores jogadores e equipes competem. A Divisão I é composta por aproximadamente 200 equipes masculinas e 300 equipes femininas, divididas em diversas conferências regionais. Uma conferência é um grupo de universidades que colaboram para organizar uma competição dentro de sua região. Alguns exemplos de conferências conhecidas são a Atlantic Coast Conference (ACC), a Big Ten Conference, a Pac-12 Conference e a Southeastern Conference (SEC).
      <br />
      <br />
      Anualmente, as equipes dentro de suas conferências competem pelo título e uma vaga no campeonato nacional: a College Cup. A College Cup é um torneio eliminatório realizado no final da temporada, geralmente em novembro e dezembro. Para se qualificar para a College Cup, as equipes devem primeiro conquistar sua vaga por meio de sua conferência ou por meio de uma seleção especial concedida por um comitê de seleção com base em seu desempenho durante a temporada. A College Cup é composta por 48 equipes masculinas e 64 equipes femininas, que competem em diversas rodadas até que um campeão permaneça.
      <br />
      <br />
      As Divisões II e III são menos competitivas que a Divisão I, mas ainda oferecem muitas oportunidades para estudantes-atletas se desenvolverem e desfrutarem do jogo. A Divisão II é composta por aproximadamente 160 equipes masculinas e 200 equipes femininas, também divididas em diferentes conferências. A Divisão II também tem um campeonato nacional, composto por 36 equipes masculinas e 48 equipes femininas. A Divisão III é composta por aproximadamente 400 equipes masculinas e 430 equipes femininas, que também competem em conferências. No entanto, a Divisão III não oferece bolsas de estudo para estudantes-atletas, mas fornece outras formas de apoio financeiro. A Divisão III também tem um campeonato nacional, composto por 62 equipes masculinas e 64 equipes femininas.
      <br />
      <br />
      Além da NCAA, existem outras organizações que organizam o futebol universitário nos EUA, como a Associação Nacional de Atletas Intercolegiais (NAIA), a Associação Atlética Intercolegial Junior Nacional (NJCAA) e a Associação Atlética Universitária Colegial dos Estados Unidos (USCAA). Essas organizações têm suas próprias divisões, regras, bolsas de estudo e campeonatos, que geralmente são menores e menos conhecidos do que os da NCAA.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programas Principais e Equipes Prestigiadas para o Futebol
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dentro de cada divisão e conferência, existem certos programas e equipes que se destacam devido à sua história, desempenho, reputação, instalações e muito mais. Esses programas e equipes frequentemente atraem os melhores jogadores, oferecem as melhores bolsas de estudo, têm os melhores treinadores e equipe, possuem instalações de treinamento e jogo de primeira linha, têm a maior base de fãs e atenção da mídia e frequentemente produzem jogadores para o circuito profissional.
      <br />
      <br />
      É difícil compilar uma lista definitiva dos melhores programas e equipes no futebol universitário na América porque muitos fatores entram em jogo, e os rankings podem mudar a cada ano. No entanto, para lhe dar uma ideia, mencionaremos alguns exemplos de programas e equipes que são frequentemente considerados os melhores do futebol universitário na América.
      <br />
      <br />
      Para os homens, um dos programas mais prestigiados é o da Universidade de Maryland, que conquistou o campeonato nacional 4 vezes (1968, 2005, 2008 e 2018) e produziu mais de 20 jogadores que jogaram na MLS, como Omar Gonzalez, Graham Zusi e Zack Steffen. Outros programas líderes para homens incluem os da Universidade da Virgínia, Universidade de Indiana, Universidade de Stanford e Universidade Wake Forest.
      <br />
      <br />
      Para as mulheres, um dos programas mais dominantes é o da Universidade da Carolina do Norte, que conquistou o campeonato nacional mais de 20 vezes e produziu mais de 40 jogadoras que jogaram pela seleção nacional, como Mia Hamm, Kristine Lilly e Tobin Heath. Outros programas líderes para mulheres incluem os da Universidade de Notre Dame, Universidade de Portland, Universidade de Stanford e Universidade Estadual da Flórida.
      <br />
      <br />
      Estes são apenas alguns exemplos dos muitos excelentes programas e equipes no futebol universitário na América. Se você quiser saber mais sobre diferentes programas e equipes, pode pesquisar online por rankings, estatísticas, análises e depoimentos de jogadores e treinadores. Você também pode entrar em contato com a Sportbeursamerika.nl, que pode ajudá-lo a encontrar o melhor programa e equipe que se adapte a você.
    </MKTypography>
  </MKBox>
</MKBox>







                                  






{/* Capítulo 3 */}
<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 3: Do Universitário para os Profissionais
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Muitos jovens jogadores de futebol sonham em um dia jogar profissionalmente e emular seus ídolos no mais alto nível. Mas como você pode tornar esse sonho realidade? Quais são os passos que você precisa tomar para ir do universitário para os profissionais? E quais são as oportunidades e desafios que você encontrará ao longo do caminho? Neste capítulo, responderemos a essas perguntas e mostraremos como o futebol universitário pode servir como uma ponte para uma carreira profissional.
  </MKTypography>
  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 O Caminho para o Profissionalismo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Um dos maiores benefícios do futebol universitário é que ele oferece a oportunidade de desenvolver-se tanto atlética quanto academicamente enquanto desfruta da vida estudantil. Ao jogar futebol e estudar na América, você não apenas melhora suas habilidades no esporte, mas também amplia seu conhecimento, expande sua rede de contatos, molda sua personalidade e aprimora suas perspectivas futuras.
      <br />
      <br />
      O futebol universitário também pode ser uma ponte para uma carreira profissional se você desejar. Existem várias maneiras de fazer a transição do futebol universitário para o circuito profissional, tanto nos Estados Unidos quanto no exterior. Os métodos mais comuns incluem:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          MLS SuperDraft: Este é um evento anual onde as equipes da MLS selecionam jogadores de um grupo de jogadores disponíveis, a maioria dos quais vem do futebol universitário. O MLS SuperDraft consiste em quatro rodadas, com cada equipe fazendo uma escolha por rodada. A ordem das seleções é determinada por vários fatores, como o desempenho da equipe na temporada anterior, sorteio e possíveis negociações. O MLS SuperDraft é uma das principais maneiras para jogadores de futebol universitário entrarem na MLS e oferece a oportunidade de garantir um contrato e uma bolsa de estudos.
        </li>
        <li>
          Regra de Jogadores Criados em Casa da MLS: Esta regra permite que as equipes da MLS assinem jogadores de suas próprias academias juvenis sem usar uma escolha de draft. A regra visa incentivar e recompensar o desenvolvimento de talentos locais. Muitas equipes da MLS têm parcerias com programas universitários, permitindo que elas acompanhem e orientem jogadores que jogam tanto por seu programa universitário quanto por suas equipes juvenis. A Regra de Jogadores Criados em Casa da MLS é outra maneira para jogadores de futebol universitário entrarem na MLS e oferece a oportunidade de garantir um contrato e uma bolsa de estudos.
        </li>
        <li>
          Programa Generation Adidas: Patrocinado pela Adidas, este programa é projetado para ajudar jovens jogadores na transição do futebol universitário para o futebol profissional. O programa seleciona jogadores promissores, geralmente com menos de 20 anos, que ainda não se formaram na universidade. Esses jogadores recebem contratos da MLS, permitindo que participem do MLS SuperDraft sem perder sua elegibilidade universitária. Isso significa que eles ainda podem retornar à universidade para concluir seus estudos, se desejarem. O programa também fornece bolsas de estudo educacionais aos jogadores para cobrir os custos de seus estudos.
        </li>
        <li>
          Oportunidades no Exterior: Estas são oportunidades para jogadores de futebol universitário jogarem no exterior, por exemplo, na Europa, Ásia ou América do Sul. Essas oportunidades geralmente dependem de vários fatores, como o nível do jogador, nacionalidade, idade e rede de contatos. Alguns jogadores podem, por exemplo, aproveitar a dupla nacionalidade ou origem familiar para se qualificar para um passaporte estrangeiro, facilitando sua participação em outro país. Outros jogadores podem se beneficiar de suas conexões com olheiros, agentes ou treinadores que podem ajudá-los a garantir um contrato com um clube estrangeiro. As oportunidades no exterior são outra maneira para jogadores de futebol universitário buscarem uma carreira profissional, mas também vêm com mais riscos e incertezas.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estas são apenas algumas das maneiras pelas quais você pode ir do universitário para os profissionais, mas existem mais. A chave é estar ciente das oportunidades disponíveis e se preparar para os desafios que você pode encontrar. Também é importante ser realista sobre suas chances e expectativas e não colocar todos os seus ovos em uma cesta. O futebol universitário é uma oportunidade fantástica para se desenvolver como jogador e como pessoa. Portanto, é sábio manter outras opções abertas e não focar apenas no futebol, mas também em seus estudos, hobbies, amigos e família.
      <br />
      <br />
      No próximo capítulo, exploraremos a influência do futebol internacional no futebol universitário e como isso impacta e inspira os jogadores de futebol universitário.
    </MKTypography>
  </MKBox>
</MKBox>







                                  







{/* Capítulo 4 */}
<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 4: A Influência do Futebol Internacional
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O futebol é um esporte global que conecta milhões de pessoas ao redor do mundo. Não é surpresa que o futebol internacional tenha uma influência significativa no futebol universitário na América. Neste capítulo, exploraremos como as influências estrangeiras impactam e inspiram o futebol universitário e como os jogadores americanos encontram seu caminho para terras estrangeiras.
  </MKTypography>
  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Influências Estrangeiras
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="O prestigiado troféu de futebol da NCAA."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          O cobiçado troféu da NCAA
        </figcaption>
      </figure>
      A popularidade do futebol internacional nos Estados Unidos cresceu significativamente nos últimos anos. De acordo com uma pesquisa Gallup de 2023, o futebol é o terceiro esporte mais popular entre os americanos, atrás do futebol americano e do basquete. Isso se deve em parte à crescente diversidade da população americana, à acessibilidade das partidas internacionais pela TV e pela internet, e ao sucesso da seleção nacional dos EUA no cenário mundial.
      <br />
      <br />
      Esse interesse pelo futebol internacional também teve impacto no futebol universitário. Muitos jogadores de futebol universitário admiram seus jogadores e equipes estrangeiras favoritos, tentando emular seu estilo de jogo e técnica. Alguns jogadores de futebol universitário até têm origens estrangeiras ou dupla nacionalidade, criando uma conexão especial com um país ou região específica.
      <br />
      <br />
      Um exemplo é Gio Reyna, filho do ex-internacional dos EUA Claudio Reyna. Gio Reyna nasceu na Inglaterra, onde seu pai jogou pelo Sunderland e Manchester City. Ele cresceu com amor pelo futebol inglês e jogou nos times de base do New York City FC. Em 2023, ele se transferiu para o Borussia Dortmund na Alemanha, onde rapidamente se tornou um dos principais talentos da Europa. Ele também estreou pela seleção nacional dos EUA, formando uma nova geração ao lado de outras jovens estrelas como Christian Pulisic e Weston McKennie.
      <br />
      <br />
      Outro exemplo é Catarina Macario, uma atacante brasileira que se mudou para os EUA aos doze anos. Ela jogou pela Universidade de Stanford, onde ganhou o prêmio Hermann Trophy duas vezes como a melhor jogadora de futebol universitário do país. Ela também obteve a cidadania dos EUA, permitindo que representasse a seleção nacional feminina dos EUA. Em 2023, ela assinou contrato com o Olympique Lyon na França, um dos principais times femininos do mundo.
      <br />
      <br />
      Esses exemplos ilustram como as influências estrangeiras enriquecem e diversificam o futebol universitário e como os jogadores de futebol universitário podem alcançar seus sonhos no futebol internacional.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Americanos no Exterior
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Não apenas as influências estrangeiras impactam o futebol universitário, mas os jogadores americanos que buscam suas fortunas no exterior também desempenham um papel significativo. Um número crescente de jogadores americanos escolhe se mudar para a Europa ou outros continentes após suas carreiras universitárias para competir contra os melhores jogadores do mundo.
      <br />
      <br />
      Um dos exemplos mais bem-sucedidos é Clint Dempsey, que jogou pela Universidade de Furman antes de ingressar na MLS. Em 2023, ele se transferiu para o Fulham na Inglaterra, onde se destacou como um prolífico artilheiro. Ele marcou mais de 50 gols pelo clube, incluindo um gol histórico contra a Juventus na Liga Europa. Ele também jogou pelo Tottenham Hotspur e pelo Seattle Sounders e foi um jogador-chave da seleção nacional dos EUA.
      <br />
      <br />
      Outra história de sucesso é a de Tobin Heath, que jogou pela Universidade da Carolina do Norte, onde conquistou dois campeonatos nacionais. Ela também jogou por vários clubes nos EUA, França e Inglaterra, incluindo Paris Saint-Germain e Manchester United. Ela conquistou quatro medalhas de ouro olímpicas e dois Copas do Mundo da FIFA com a seleção nacional feminina dos EUA, onde é conhecida por suas habilidades técnicas e criatividade.
      <br />
      <br />
      Esses exemplos demonstram como os jogadores americanos podem se desenvolver e se destacar no exterior e como podem colocar o futebol universitário no mapa como um incubador de talentos.
    </MKTypography>
  </MKBox>
</MKBox>







                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    As bolsas de estudo esportivas oferecem inúmeras oportunidades para jovens atletas mostrarem seu talento e desenvolverem suas habilidades. Uma das oportunidades mais significativas é obter uma bolsa de estudos esportiva para estudar e competir no nível universitário. Neste capítulo, discutiremos a importância das bolsas de estudo esportivas e como a Sport Scholarships America pode ajudá-lo a realizar seu sonho.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 A Importância das Bolsas de Estudo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Uma bolsa de estudos esportiva é um suporte financeiro fornecido por uma universidade a um estudante-atleta para combinar estudos e esportes. Pode cobrir os custos de mensalidades, livros, moradia, refeições e outras despesas. Além disso, pode oferecer benefícios como tutoria acadêmica, cuidados médicos, instalações de treinamento e equipamentos.
      <br />
      <br />
      Obter uma bolsa de estudos esportiva é competitivo, com muitos fatores desempenhando um papel no processo de concessão, incluindo realizações acadêmicas, desempenho atlético, personalidade, motivação, liderança e adaptabilidade. Portanto, é importante se preparar bem e se destacar da concorrência.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Como a Sport Scholarships America Pode Ajudar
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo da Sport Scholarships America, mostrando equipamentos esportivos e um capelo de formatura, representando o foco em bolsas de estudo esportivas para estudantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo da Sport Scholarships America
        </figcaption>
      </figure>
      A Sport Scholarships America é uma organização especializada em orientar jovens talentos esportivos para bolsas de estudo esportivas. Com especialistas apaixonados e experientes, a Sport Scholarships America apoia estudantes-atletas em cada etapa da jornada, desde a orientação inicial até a colocação final. Seus serviços incluem:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Uma entrevista de admissão pessoal para discutir os desejos, possibilidades e expectativas do estudante-atleta.</li>
        <li>Uma avaliação profissional do nível acadêmico e atlético do estudante-atleta.</li>
        <li>Uma extensa rede de contatos com treinadores, universidades e faculdades.</li>
        <li>Promoção eficaz do perfil e vídeos do estudante-atleta para treinadores potenciais.</li>
        <li>Conselhos especializados sobre as melhores opções com base nas preferências, metas e orçamento do estudante-atleta.</li>
        <li>Negociação das melhores ofertas de bolsas de estudo em nome do estudante-atleta.</li>
        <li>Auxílio na solicitação de documentos necessários, como visto, histórico escolar, diploma e testes de admissão.</li>
        <li>Preparação para a vida do estudante-atleta durante a bolsa de estudos, incluindo cultura, idioma, regras e dicas.</li>
        <li>Acompanhamento e acompanhamento durante o período da bolsa de estudos.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Quer explorar oportunidades de bolsas de estudo e alcançar seus objetivos atléticos e acadêmicos? <Link to="/pt/Serviços-Produtos">Veja a gama completa</Link> de serviços oferecidos pela Sport Scholarships America e agende uma consulta hoje.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;