/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


// @mui material components
import React, { useState } from 'react'; // Step 1: Import useState


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Logos/NJCAA.png";
import post3 from "assets/images/Logos/NAIA.png";



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {

  const articleStructure = [
    { id: 'intro', title: 'A NCAA: O Coração do Esporte Universitário na América', isSubheader: false },
  
    { id: 'Capítulo 1', title: 'As Origens e o Crescimento da NCAA', isSubheader: true },
    { id: '1.1', title: 'Os Três Rostos da NCAA: Divisões I, II e III', isSubheader: true },
    { id: '1.2', title: 'O Papel dos Acadêmicos', isSubheader: true },
  
    { id: 'Capítulo 2', title: 'Finanças, Mídia e Marketing', isSubheader: true },
    { id: '2.1', title: 'Desafios e Futuro', isSubheader: true },
    { id: '2.2', title: 'Conclusão', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
     <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contents
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Esconder</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="intro" mb={3}>


              
<MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>NCAA: O Coração do Esporte Universitário na América</MKTypography>
</MKBox>
<MKBox id="Capítulo 1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
As Origens e o Crescimento da NCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
A National Collegiate Athletic Association, mais conhecida como NCAA, tem sido o centro do cenário esportivo universitário americano por mais de um século. Fundada em 1906, essa organização evoluiu de uma pequena entidade reguladora para uma organização poderosa e influente que impacta a vida de milhares de estudantes-atletas, treinadores e entusiastas do esporte.
<br />
<br />
Nos primeiros anos da NCAA, o foco era principalmente em estabelecer regras e garantir a segurança nos esportes. No entanto, com o tempo, o papel da NCAA se expandiu, tornando-se cada vez mais envolvida na regulamentação de todos os aspectos do esporte universitário, desde requisitos acadêmicos até questões financeiras.
</MKTypography>

<MKBox id="1.1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Os Três Rostos da NCAA: Divisões I, II e III
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
A NCAA é dividida em três divisões, cada uma com seu próprio caráter único e nível de competição:
<br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Divisão I:{' '}
</MKTypography>
é conhecida como o ápice do esporte universitário, onde escolas com os maiores orçamentos, as melhores instalações e os atletas mais talentosos se reúnem. As universidades nesta divisão geralmente são grandes e têm recursos significativos à sua disposição. Elas oferecem bolsas de estudo atléticas completas e parciais e atraem os melhores atletas de todo o país e do mundo. A competição é intensa e a pressão para performar é alta. No entanto, também é um palco onde nascem estrelas e onde os sonhos dos jovens atletas podem se tornar realidade. A divisão é subdividida em três categorias: Football Bowl Subdivision (FBS), Football Championship Subdivision (FCS) e escolas sem futebol no nível da Divisão I.  <br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Divisão II:{' '}
</MKTypography>
representa uma abordagem mais equilibrada para o esporte universitário, onde o esporte, o desempenho acadêmico e o envolvimento no campus caminham juntos. As universidades nesta divisão geralmente são menores do que as da Divisão I, mas ainda oferecem um alto nível de competição. Os atletas na Divisão II podem esperar apoio tanto no campo quanto na sala de aula, com o objetivo de fornecer uma experiência universitária completa.  <br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Divisão III:{' '}
</MKTypography>
finalmente, enfatiza o desenvolvimento geral do estudante-atleta. Os esportes são importantes, mas são apenas uma parte da experiência universitária mais ampla. Não são oferecidas bolsas de estudo atléticas na Divisão III, mas os atletas têm a oportunidade de participar de esportes competitivos enquanto também se integram totalmente à vida acadêmica e social no campus.</MKTypography>

<MKBox id="1.2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
O Papel dos Acadêmicos
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
A NCAA leva muito a sério o desempenho acadêmico de seus estudantes-atletas. Todos os atletas devem atender a requisitos acadêmicos rigorosos para serem elegíveis para competir, e as próprias universidades são responsáveis por fornecer o apoio necessário para garantir que os atletas tenham sucesso na sala de aula. Isso inclui orientadores acadêmicos, tutores e programas de estudo especiais, todos projetados para ajudar os estudantes-atletas a equilibrar suas obrigações esportivas e acadêmicas.                <br />
<br />
</MKTypography>
   





                                  






<MKBox id="Capítulo 2" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Finanças, Mídia e Marketing
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              A NCAA também é uma potência financeira, com bilhões de dólares fluindo através da organização anualmente graças a contratos de televisão, patrocinadores e mercadorias. Essas receitas são usadas para financiar bolsas de estudo, melhorar as instalações e aprimorar a experiência atlética geral para os estudantes-atletas. Ao mesmo tempo, a popularidade dos esportes universitários levou a acordos lucrativos de marketing e mídia, tornando o atletismo universitário um pilar da cultura esportiva americana.

<br />
                </MKTypography>

                <MKBox id="2.1" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Desafios e Futuro
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              Apesar de seu sucesso, a NCAA também enfrenta desafios significativos. Questões como o status amador dos estudantes-atletas, a saúde mental dos atletas e a busca pela igualdade no esporte são apenas alguns dos tópicos que a organização deve abordar em sua busca por um futuro melhor para o atletismo universitário.
<br />
<br />
A NCAA permanece um jogador crucial no sistema educacional americano, e seu impacto na vida dos jovens atletas e no mundo do esporte em geral é inegável. À medida que olha para o futuro, fica claro que a organização continuará desempenhando um papel fundamental na formação do cenário esportivo universitário na América.


                </MKTypography>

                <MKBox id="2.2" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Conclusão
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              A Associação Atlética Universitária Nacional é mais do que apenas uma organização esportiva; é uma parte crucial da paisagem educacional e esportiva americana. Com sua rica história, estrutura complexa e profundo impacto na vida de inúmeros indivíduos, a NCAA continua sendo uma parte fascinante e vital da cultura americana. Seu futuro sem dúvida estará repleto de desafios e oportunidades, mas uma coisa é certa: a NCAA continuará buscando a excelência, tanto no campo quanto na sala de aula.




<br />


                </MKTypography>



              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="NJCAA"
                    description="NJCAA: A Porta de Entrada para Grandes Universidades e a NCAA"
                    action={{
                      type: "internal",
                      route: "/pt/NJCAA",
                      color: "info",
                      label: "Leia Mais",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="NAIA"
                    description="A NAIA: Uma Rota Alternativa no Esporte Universitário na América"
                    action={{
                      type: "internal",
                      route: "/pt/NAIA",
                      color: "info",
                      label: "Leia Mais",
                    }}
                    />
                  </Grid>
                </Grid>
  
              </Grid>
          </Grid>
        </Container>
      </MKBox>
    );
  }
  
  export default Beschrijving;