/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


// @mui material components
import React, { useState } from 'react'; // Step 1: Import useState


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Logos/NJCAA.png";
import post3 from "assets/images/Logos/NAIA.png";



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {

  const articleStructure = [
    { id: 'intro', title: 'NCAA: The Heart of College Sport in America', isSubheader: false },
  
    { id: 'Chapter 1', title: 'The Roots and Growth of the NCAA', isSubheader: true },
    { id: '1.1', title: 'The Three Faces of the NCAA: Divisions I, II, and III', isSubheader: true },
    { id: '1.2', title: 'The Role of Academics', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Finance, Media, and Marketing', isSubheader: true },
    { id: '2.1', title: 'Challenges and Future', isSubheader: true },
    { id: '2.2', title: 'Conclusion', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
     <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Content
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Hide</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Show</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="intro" mb={3}>


              
<MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>NCAA: The Heart of College Sport in America</MKTypography>
</MKBox>
<MKBox id="Chapter 1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
The Roots and Growth of the NCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
The National Collegiate Athletic Association, better known as the NCAA, has been the centerpiece of the American collegiate sports landscape for over a century. Founded in 1906, this organization has evolved from a small regulatory entity to a powerful and influential organization that impacts the lives of thousands of student-athletes, coaches, and sports enthusiasts.
<br />
<br />
In the early years of the NCAA, the focus was primarily on establishing rules and ensuring safety in sports. However, as time progressed, the NCAA's role expanded, with it becoming increasingly involved in regulating every aspect of collegiate athletics, from academic requirements to financial matters.
</MKTypography>

<MKBox id="1.1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
The Three Faces of the NCAA: Divisions I, II, and III
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
The NCAA is divided into three divisions, each with its own unique character and level of competition:
<br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division I:{' '}
</MKTypography>
is known as the pinnacle of collegiate sports, where schools with the largest budgets, the best facilities, and the most talented athletes come together. The universities in this division are often large and have significant resources at their disposal. They offer full and partial athletic scholarships and attract top athletes from across the country and the world. The competition is intense, and the pressure to perform is high. Yet, it's also a stage where stars are born and where the dreams of young athletes can come true. The division is subdivided into three categories: Football Bowl Subdivision (FBS), Football Championship Subdivision (FCS), and schools without football at the Division I level.  <br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division II:{' '}
</MKTypography>
represents a more balanced approach to collegiate sports, where athletics, academic performance, and campus engagement go hand in hand. The universities in this division are often smaller than those in Division I but still offer a high level of competition. Athletes in Division II can expect support both on the field and in the classroom, with the goal of providing a full collegiate experience.  <br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division III:{' '}
</MKTypography>
finally, emphasizes the overall development of the student-athlete. Sports are important, but they're just a part of the broader collegiate experience. No athletic scholarships are offered in Division III, but athletes have the opportunity to participate in competitive sports while also fully integrating into academic and social life on campus.</MKTypography>

<MKBox id="1.2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
The Role of Academics
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
The NCAA takes the academic performance of its student-athletes very seriously. All athletes must meet strict academic requirements to be eligible for competition, and the universities themselves are responsible for providing the necessary support to ensure that athletes succeed in the classroom. This includes academic advisors, tutors, and special study programs, all designed to help student-athletes balance their athletic and academic obligations.                <br />
<br />
</MKTypography>



                                  







<MKBox id="Chapter 2" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Finance, Media, and Marketing
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              The NCAA is also a financial powerhouse, with billions of dollars flowing through the organization annually thanks to television contracts, sponsors, and merchandise. These revenues are used to fund scholarships, improve facilities, and enhance the overall athletic experience for student-athletes. At the same time, the popularity of college sports has led to lucrative marketing and media deals, making collegiate athletics a staple of American sports culture.

<br />
                </MKTypography>

                <MKBox id="2.1" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Challenges and Future
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              Despite its success, the NCAA also faces significant challenges. Issues such as the amateur status of student-athletes, athletes' mental health, and the pursuit of equality in sports are just some of the topics the organization must address in its quest for a better future for collegiate athletics.
<br />
<br />
The NCAA remains a crucial player in the American education system, and its impact on the lives of young athletes and the broader sports world is undeniable. As it looks ahead to the future, it is clear that the organization will continue to play a key role in shaping the collegiate sports landscape in America.


                </MKTypography>

                <MKBox id="2.2" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Conclusion
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              The National Collegiate Athletic Association is more than just a sports organization; it is a crucial part of the American educational and sports landscape. With its rich history, complex structure, and deep impact on the lives of countless individuals, the NCAA remains a fascinating and vital part of American culture. Its future will undoubtedly be filled with challenges and opportunities, but one thing is certain: the NCAA will continue to strive for excellence, both on the field and in the classroom.




<br />


                </MKTypography>



              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="NJCAA"
                    description="De Springplank naar Grote Colleges en de NCAA"
                    action={{
                      type: "internal",
                      route: "/NJCAA",
                      color: "info",
                      label: "lees meer",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="NAIA"
                    description="Een Alternatieve Route in College Sport in Amerika"
                    action={{
                      type: "internal",
                      route: "/NAIA",
                      color: "info",
                      label: "lees meer",
                    }}
                    />
                  </Grid>
                </Grid>
  
              </Grid>
          </Grid>
        </Container>
      </MKBox>
    );
  }
  
  export default Beschrijving;