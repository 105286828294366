/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Volleybal1.avif";
import Image2 from "assets/images/Sports_Images/volleybal2.webp";
import Image3 from "assets/images/Sports_Images/Volleybal3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images
import { Link } from 'react-router-dom';



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Bolsa de Estudos de Vôlei: Como Estudar e Jogar Vôlei na América?', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capítulo 1: História e Desenvolvimento do Vôlei na América', isSubheader: false },
    { id: '1.1', title: '1.1 Primeiros Anos e o Papel das Instituições Educacionais', isSubheader: true },
    { id: '1.2', title: '1.2 Crescimento e Profissionalização', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capítulo 2: Universidade de Vôlei Hoje', isSubheader: false },
    { id: '2.1', title: '2.1 Principais Ligas e Conferências', isSubheader: true },
    { id: '2.2', title: '2.2 Principais Programas e Equipes de Prestígio', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capítulo 3: Da Universidade para os Profissionais', isSubheader: false },
    { id: '3.1', title: '3.1 O Caminho para o Profissionalismo', isSubheader: true },
    { id: '3.2', title: '3.2 Ligas Profissionais e Oportunidades de Carreira', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capítulo 4: Influências Internacionais e Competições', isSubheader: false },
    { id: '4.1', title: '4.1 Influências Estrangeiras', isSubheader: true },
    { id: '4.2', title: '4.2 Americanos no Palco Mundial', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 A Importância das Bolsas de Estudo', isSubheader: true },
    { id: '5.2', title: '5.2 Como a Sport Scholarships America Pode Ajudar', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Conteúdo
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Esconder</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
           <Grid item xs={12} md={9}>
























{/* Caixa para a Introdução */}
<MKBox id="intro" mb={3}>
  {/* Título da Introdução */}
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Bolsa de Estudos de Vôlei: Como Estudar e Jogar Vôlei na América?
  </MKTypography>
  {/* Texto da Introdução */}
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O vôlei é um esporte que demanda muito das suas habilidades físicas e mentais. Você precisa reagir rapidamente, colaborar eficazmente, pensar taticamente e ter uma forte resistência. Além disso, o vôlei é um esporte que proporciona muita alegria e satisfação, especialmente quando jogado em alto nível. Mas como você, como um talento do vôlei, pode seguir suas ambições atléticas sem negligenciar seus estudos? A resposta é: optando por uma bolsa de estudos de vôlei na América.
    <br />
    <br />
    Uma bolsa de estudos de vôlei é uma ajuda financeira oferecida por uma universidade americana que permite que você estude e jogue vôlei lá. Com uma bolsa de estudos de vôlei, você pode alcançar dois objetivos de uma vez: você pode obter um diploma reconhecido de uma instituição educacional prestigiada enquanto desenvolve ainda mais suas habilidades de vôlei sob orientação profissional. Além disso, você pode desfrutar da experiência única de viver e aprender em uma cultura diferente, com novos amigos e desafios.
    <br />
    <br />
    Mas como você pode se qualificar para uma bolsa de estudos de vôlei? Quais são os requisitos e expectativas? E como você encontra a universidade certa que se adapta a você? Estas são todas perguntas às quais o Sportbeursamerika.nl pode ajudá-lo. O Sportbeursamerika.nl é especialista no campo de bolsas de estudo esportivas na América. Com anos de experiência e uma extensa rede, posso orientá-lo por todas as etapas para se candidatar com sucesso a uma bolsa de estudos de vôlei, desde a criação de um perfil online até o tratamento de todos os assuntos práticos.
    <br />
    <br />
    Se você quiser saber mais sobre como o Sportbeursamerika.nl pode ajudá-lo a estudar e jogar vôlei na América, continue lendo ou entre em contato comigo para uma conversa introdutória sem compromisso. Ficarei feliz em contar mais sobre as possibilidades e benefícios de uma bolsa de estudos de vôlei na América.
  </MKTypography>
  {/* Adicionar lista com marcadores ou outro conteúdo conforme necessário */}
</MKBox>

{/* Capítulos e subcapítulos principais com texto fictício */}
<MKBox id="Chapter 1" mb={3}>
  {/* Título do Capítulo 1 */}
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 1: História e Desenvolvimento do Vôlei na América
  </MKTypography>
  {/* Texto do Capítulo 1 */}
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O vôlei é um esporte que existe há mais de um século, mas experimentou um crescimento significativo em popularidade e profissionalismo, especialmente nas últimas décadas. Neste capítulo, vamos mergulhar na história e desenvolvimento do vôlei na América, desde seus primeiros anos até o presente.
  </MKTypography>

  {/* Subcapítulo 1.1 */}
  <MKBox id="1.1" mb={2}>
    {/* Título do Subcapítulo 1.1 */}
    <MKTypography variant="h4" mb={1}>
      1.1 Primeiros Anos e o Papel das Instituições Educacionais
    </MKTypography>
    {/* Texto do Subcapítulo 1.1 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O vôlei foi inventado em 1895 por William G. Morgan, um professor de educação física na YMCA (Associação Cristã de Moços) em Holyoke, Massachusetts. Ele tinha como objetivo criar um jogo menos exigente fisicamente do que o basquete, que tinha sido inventado um ano antes por seu colega James Naismith. Inicialmente, ele chamou o jogo de "mintonette", mas logo ficou conhecido como "vôlei" porque os jogadores tinham que dar toques na bola sobre uma rede.
      <br />
      <br />
      O jogo se espalhou rapidamente pelos Estados Unidos e outros países, principalmente através da YMCA e outras instituições educacionais. Em 1916, um dos primeiros torneios oficiais de vôlei foi realizado na Universidade da Califórnia em Berkeley. Em 1922, a United States Volleyball Association (USVBA) foi estabelecida, que mais tarde se tornou a USA Volleyball (USAV). Essa organização passou a ser responsável por regular e promover o vôlei na América.
      <br />
      <br />
      Em 1928, o primeiro campeonato nacional masculino foi organizado pela USVBA, seguido pelo primeiro campeonato nacional feminino em 1949. Em 1955, o primeiro Campeonato Mundial masculino foi realizado na França, onde a América terminou em sexto lugar. Em 1956, o primeiro Campeonato Mundial feminino aconteceu na França, com a América terminando em quarto lugar.
    </MKTypography>
  </MKBox>

  {/* Subcapítulo 1.2 */}
  <MKBox id="1.2" mb={2}>
    {/* Título do Subcapítulo 1.2 */}
    <MKTypography variant="h4" mb={1}>
      1.2 Crescimento e Profissionalização
    </MKTypography>
    {/* Texto do Subcapítulo 1.2 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Nos anos 1960 e 1970, o vôlei começou a crescer e a se profissionalizar na América. Isso se deveu em parte à introdução de novas regras, como a adoção do sistema de ponto de rali, onde cada ponto conta, independentemente de quem está sacando. O vôlei também ganhou mais atenção através das transmissões televisivas dos Jogos Olímpicos, onde o vôlei é um esporte oficial desde 1964.
      <br />
      <br />
      Uma das figuras-chave na história do vôlei na América é Doug Beal, que alcançou sucesso tanto como jogador quanto como treinador. Ele jogou pela seleção nacional de 1970 a 1976 e conquistou o ouro nos Jogos Pan-Americanos em 1975, entre outros feitos. Mais tarde, ele se tornou o treinador da seleção nacional de 1977 a 1984 e os levou ao seu primeiro ouro olímpico em 1984 em Los Angeles. Ele também esteve envolvido na fundação da Major League Volleyball (MLV), uma liga profissional feminina que existiu de 1987 a 1989.
      <br />
      <br />
      Outro evento significativo na história do vôlei na América foi o estabelecimento da Association of Volleyball Professionals (AVP) em 1983, focada no vôlei de praia. O vôlei de praia é uma variante do vôlei jogada na areia com equipes de dois jogadores. A AVP organizou torneios e competições tanto para homens quanto para mulheres, ajudando a popularizar o vôlei de praia junto ao público. Alguns jogadores de vôlei de praia conhecidos incluem Karch Kiraly, Sinjin Smith, Randy Stoklos, Misty May-Treanor e Kerri Walsh Jennings.
    </MKTypography>
  </MKBox>
</MKBox>





                                  




{/* Capítulo 2: Universidade de Vôlei Hoje */}
<MKBox id="Chapter 2" mb={3}>
  {/* Título do Capítulo 2 */}
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 2: Universidade de Vôlei Hoje
  </MKTypography>
  {/* Texto do Capítulo 2 */}
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O vôlei é um dos esportes mais populares e competitivos no nível universitário na América. Todos os anos, milhares de equipes participam de várias competições e conferências, culminando nos campeonatos nacionais. Neste capítulo, forneceremos uma visão geral das principais ligas e conferências, e discutiremos alguns dos programas de destaque e equipes de prestígio no vôlei universitário.
  </MKTypography>

  {/* Subcapítulo 2.1: Principais Ligas e Conferências */}
  <MKBox id="2.1" mb={2}>
    {/* Título do Subcapítulo 2.1 */}
    <MKTypography variant="h4" mb={1}>
      2.1 Principais Ligas e Conferências
    </MKTypography>
    {/* Texto do Subcapítulo 2.1 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Equipe de vôlei celebrando uma vitória juntos."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Jogadores de vôlei comemorando uma vitória
        </figcaption>
      </figure>
      A principal organização responsável por organizar e regular o vôlei universitário na América é a National Collegiate Athletic Association (NCAA). A NCAA divide as universidades em três divisões com base em seu tamanho, orçamento e desempenho atlético. As divisões são:
    </MKTypography>

    {/* Lista de Divisões */}
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Divisão I: Esta é a divisão mais alta, onde a maioria das universidades grandes e conhecidas está localizada. As equipes nesta divisão recebem mais atenção, cobertura midiática e bolsas de estudo. Existem aproximadamente 330 universidades nesta divisão, divididas em 32 conferências. Uma conferência é um grupo de universidades que estão geograficamente ou historicamente conectadas e competem entre si. Alguns exemplos de conferências na Divisão I incluem a Big Ten, Pac-12, Southeastern Conference (SEC) e Atlantic Coast Conference (ACC).
        </li>
        <li>
          Divisão II: Esta é a divisão intermediária, onde as universidades são um pouco menores e menos conhecidas do que na Divisão I, mas ainda mantêm um alto nível de vôlei. As equipes nesta divisão recebem menos atenção, cobertura midiática e bolsas de estudo do que na Divisão I, mas mais do que na Divisão III. Existem aproximadamente 300 universidades nesta divisão, divididas em 23 conferências. Alguns exemplos de conferências na Divisão II incluem a Great Lakes Valley Conference (GLVC), California Collegiate Athletic Association (CCAA) e Gulf South Conference (GSC).
        </li>
        <li>
          Divisão III: Esta é a divisão mais baixa, onde as universidades se concentram principalmente na excelência acadêmica em vez do desempenho atlético. As equipes nesta divisão não recebem bolsas de estudo para seus atletas, mas fornecem ajuda financeira com base no mérito acadêmico. Existem aproximadamente 450 universidades nesta divisão, divididas em 43 conferências. Alguns exemplos de conferências na Divisão III incluem a New England Small College Athletic Conference (NESCAC), University Athletic Association (UAA) e Centennial Conference.
        </li>
      </ul>
    </MKTypography>
    {/* Texto adicional */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Todos os anos, as equipes dentro de suas divisões e conferências competem em jogos para se qualificar para os campeonatos nacionais, também conhecidos como Torneio da NCAA. Este é um torneio eliminatório onde as melhores equipes de cada divisão competem pelo título de campeão nacional. O torneio é realizado na primavera para homens e no outono para mulheres.
    </MKTypography>
  </MKBox>

  {/* Subcapítulo 2.2: Principais Programas e Equipes de Prestígio */}
  <MKBox id="2.2" mb={2}>
    {/* Título do Subcapítulo 2.2 */}
    <MKTypography variant="h4" mb={1}>
      2.2 Principais Programas e Equipes de Prestígio
    </MKTypography>
    {/* Texto do Subcapítulo 2.2 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O vôlei universitário na América ostenta um alto nível de competição, e muitas equipes se destacam em suas respectivas divisões e conferências. No entanto, algumas equipes ganharam uma reputação especial e história devido ao seu desempenho consistente, jogadores e treinadores talentosos, e uma grande base de fãs. Aqui estão alguns dos principais programas e equipes de prestígio no vôlei universitário:
    </MKTypography>
    {/* Lista de Programas e Equipes */}
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          UCLA Bruins: Representando a Universidade da Califórnia, Los Angeles, os UCLA Bruins são conhecidos por sua forte tradição tanto no vôlei masculino quanto feminino na Divisão I da NCAA. Historicamente, eles ganharam vários campeonatos nacionais e produziram alguns dos jogadores mais respeitados do esporte. Esta equipe é um membro proeminente da Pac-12 Conference.
        </li>
        <li>
          Stanford Cardinal: A equipe de vôlei feminino da Universidade de Stanford, localizada em Stanford, Califórnia, se distinguiu ao vencer um número impressionante de campeonatos nacionais na Divisão I da NCAA, mais do que qualquer outra equipe em sua categoria. Eles consistentemente produziram jogadores talentosos e competem na Pac-12 Conference.
        </li>
        <li>
          Penn State Nittany Lions: A equipe de vôlei feminino da Universidade Estadual da Pensilvânia em University Park, Pensilvânia, tem uma reputação de excelência na Divisão I da NCAA, com inúmeros campeonatos nacionais em seu nome. Eles são conhecidos por seus títulos consecutivos e treinaram jogadores excepcionais ao longo dos anos. Esta equipe é um membro significativo da Big Ten Conference.
        </li>
        <li>
          BYU Cougars: A Brigham Young University em Provo, Utah, tem uma respeitável equipe de vôlei masculino na Divisão I da NCAA, que conquistou o campeonato nacional várias vezes. A equipe consistentemente se destacou no desenvolvimento de jogadores de vôlei de ponta e faz parte da Mountain Pacific Sports Federation (MPSF).
        </li>
      </ul>
    </MKTypography>
    {/* Texto adicional */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Esses programas têm sido proeminentes e influentes no vôlei universitário nos Estados Unidos ao longo dos anos, conhecidos por suas tradições fortes, jogadores talentosos e treinadores influentes.
    </MKTypography>
  </MKBox>
</MKBox>
                                     






                                  






{/* Capítulo 3: Da Universidade para os Profissionais */}
<MKBox id="Chapter 3" mb={3}>
  {/* Título do Capítulo 3 */}
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 3: Da Universidade para os Profissionais
  </MKTypography>
  {/* Texto do Capítulo 3 */}
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Para muitos estudantes-atletas que jogam vôlei no nível universitário, é um sonho também jogar vôlei profissionalmente após a formatura. Mas quão realista é esse sonho e quais são os passos que você precisa tomar para alcançá-lo? Neste capítulo, discutiremos o caminho para o profissionalismo, ligas profissionais e oportunidades de carreira para jogadores de vôlei.
  </MKTypography>

  {/* Subcapítulo 3.1: O Caminho para o Profissionalismo */}
  <MKBox id="3.1" mb={2}>
    {/* Título do Subcapítulo 3.1 */}
    <MKTypography variant="h4" mb={1}>
      3.1 O Caminho para o Profissionalismo
    </MKTypography>
    {/* Texto do Subcapítulo 3.1 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Jogar vôlei no nível universitário é uma ótima maneira de melhorar suas habilidades no vôlei, obter seu diploma acadêmico e se desenvolver pessoalmente. No entanto, também é um ambiente altamente competitivo e exigente onde você deve investir muito tempo e energia tanto no seu esporte quanto nos seus estudos. Portanto, é importante ser realista sobre suas chances de jogar vôlei profissionalmente após sua carreira universitária.
      <br />
      <br />
      Ao contrário de alguns outros esportes como basquete ou futebol, não há uma liga profissional de vôlei na América que recrute diretamente das universidades. Isso significa que como jogador graduado, você precisará buscar outras oportunidades para iniciar sua carreira profissional, geralmente no exterior. Existem várias ligas profissionais de vôlei na Europa, Ásia, América do Sul e outras regiões onde você pode jogar por clubes que oferecem um salário e outros benefícios.
      <br />
      <br />
      Mas como você entra em contato com esses clubes e como garante que se destaque entre os muitos outros jogadores que também estão procurando um contrato? Aqui estão algumas dicas para ajudá-lo a encontrar um clube profissional de vôlei:
    </MKTypography>
    {/* Lista de Dicas */}
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Crie um perfil online em uma plataforma como Volleyball Agency ou Volleyball Market, onde você pode compartilhar seus vídeos, estatísticas, referências e informações de contato com clubes e agentes em potencial.
        </li>
        <li>
          Entre em contato com seus ex-treinadores, colegas de equipe ou outros contatos que tenham experiência com vôlei profissional ou tenham conexões com clubes ou agentes. Eles podem fornecer conselhos, fazer recomendações ou apresentá-lo às pessoas certas.
        </li>
        <li>
          Participe de testes, apresentações ou acampamentos organizados por clubes ou organizações profissionais. Estes são eventos onde você pode mostrar suas habilidades para olheiros, treinadores ou gerentes em busca de novos jogadores. Certifique-se de estar bem preparado, tanto fisicamente quanto mentalmente, e deixe uma impressão positiva.
        </li>
        <li>
          Seja flexível e esteja disposto a se mudar para outro país ou continente. Jogar vôlei profissionalmente muitas vezes significa viver em uma cultura diferente, com um idioma, clima e costumes diferentes. Isso pode ser um desafio significativo, mas também uma ótima oportunidade para aprender e crescer como pessoa.
        </li>
      </ul>
    </MKTypography>
  </MKBox>

  {/* Subcapítulo 3.2: Ligas Profissionais e Oportunidades de Carreira */}
  <MKBox id="3.2" mb={2}>
    {/* Título do Subcapítulo 3.2 */}
    <MKTypography variant="h4" mb={1}>
      3.2 Ligas Profissionais e Oportunidades de Carreira
    </MKTypography>
    {/* Texto do Subcapítulo 3.2 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Se você conseguir garantir um contrato com um clube profissional de vôlei, poderá desfrutar dos benefícios de jogar no mais alto nível do esporte. Você pode participar de ligas emocionantes, competir contra os melhores jogadores do mundo, viajar para diferentes países e cidades e ganhar dinheiro com sua paixão. Mas quais são as principais ligas profissionais em que você pode participar e quais são as outras oportunidades de carreira para jogadores de vôlei?
      <br />
      <br />
      Aqui estão algumas das ligas de vôlei profissional mais prestigiadas e lucrativas do mundo:
    </MKTypography>
    {/* Lista de Ligas Profissionais */}
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          A Serie A Italiana: Esta é a divisão principal do vôlei italiano, composta por 14 times masculinos e 12 times femininos. A Serie A é conhecida como uma das ligas mais fortes e competitivas do mundo, apresentando muitas estrelas internacionais e salários altos. O campeão da Serie A também se classifica para a Liga dos Campeões, o principal torneio de clubes na Europa.
        </li>
        <li>
          A Süper Lig Turca: Esta é a divisão principal do vôlei turco, composta por 12 times masculinos e 14 times femininos. A Süper Lig também é uma das principais ligas do mundo, com investimentos significativos, atenção da mídia e jogadores talentosos. O campeão da Süper Lig também se classifica para a Liga dos Campeões.
        </li>
        <li>
          A Superliga Brasileira: Esta é a divisão principal do vôlei brasileiro, composta por 12 times masculinos e 12 times femininos. A Superliga é a liga dominante na América do Sul, conhecida por sua paixão, atmosfera e qualidade. O campeão da Superliga também se classifica para o Campeonato Mundial de Clubes de Vôlei da FIVB, o principal torneio de clubes do mundo.
        </li>
      </ul>
    </MKTypography>
    {/* Outras Oportunidades de Carreira */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Além de jogar em ligas profissionais, existem outras oportunidades para jogadores de vôlei continuarem ou expandirem suas carreiras. Aqui estão alguns exemplos:
    </MKTypography>
    {/* Lista de Outras Oportunidades de Carreira */}
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Treinamento ou treinamento de outros jogadores: Se você quiser encerrar ou reduzir sua carreira de jogador, também pode transmitir seus conhecimentos e experiência para outros jogadores como treinador ou instrutor. Você pode trabalhar para um clube profissional, uma universidade, uma escola ou outra organização que ofereça vôlei. Você também pode estabelecer sua própria escola ou academia de vôlei, onde pode treinar e orientar jovens talentos.
        </li>
        <li>
          Trabalhar na mídia ou na indústria: Se você tiver interesse na mídia ou em indústrias relacionadas ao vôlei, também pode construir uma carreira lá. Você pode trabalhar como comentarista, analista, jornalista, escritor, fotógrafo ou videógrafo para vários veículos de mídia que cobrem o vôlei. Você também pode trabalhar como representante, consultor, profissional de marketing ou designer para diferentes empresas que oferecem produtos ou serviços de vôlei.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  






{/* Capítulo 4: Influências e Competições Internacionais */}
<MKBox id="Chapter 4" mb={3}>
  {/* Título do Capítulo 4 */}
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 4: Influências Internacionais e Competições
  </MKTypography>
  {/* Texto do Capítulo 4 */}
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O vôlei é um esporte global, jogado e seguido por milhões de pessoas em diferentes países e continentes. O vôlei universitário na América não está isolado, mas é influenciado e desafiado pela comunidade internacional de vôlei. Neste capítulo, discutiremos as influências estrangeiras e as competições internacionais que desempenham um papel no vôlei universitário.
  </MKTypography>

  {/* Subcapítulo 4.1: Influências Estrangeiras */}
  <MKBox id="4.1" mb={2}>
    {/* Título do Subcapítulo 4.1 */}
    <MKTypography variant="h4" mb={1}>
      4.1 Influências Estrangeiras
    </MKTypography>
    {/* Texto do Subcapítulo 4.1 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O vôlei universitário na América deve muito às influências estrangeiras que enriqueceram e melhoraram o esporte. Uma das influências mais significativas é a imigração de jogadores, treinadores e preparadores de outros países que trouxeram seu conhecimento, experiência e cultura para a América. Alguns desses imigrantes até se tornaram lendas no vôlei universitário, como:
    </MKTypography>
    {/* Lista de Influências Estrangeiras */}
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Arie Selinger: Ele é um treinador israelense que se mudou para a América em 1969 para treinar a seleção nacional feminina. Ele levou a equipe à sua primeira medalha olímpica, uma prata em 1984. Ele também treinou várias equipes universitárias, incluindo a Long Beach State, onde se tornou tetracampeão nacional.
        </li>
        <li>
          Andy Banachowski: Ele é um jogador polonês que imigrou para a América em 1951. Ele jogou pela UCLA, onde se tornou bicampeão nacional. Mais tarde, tornou-se treinador da equipe feminina da UCLA, vencendo seis campeonatos nacionais e mais de 1000 partidas.
        </li>
        <li>
          Marv Dunphy: Ele é um jogador canadense que veio para a América em 1972 para jogar pela Pepperdine University. Mais tarde, tornou-se treinador da equipe masculina da Pepperdine, vencendo quatro campeonatos nacionais. Ele também treinou a seleção nacional masculina, que conquistou o ouro nas Olimpíadas de 1988.
        </li>
      </ul>
    </MKTypography>
    {/* Texto Continuação do Subcapítulo 4.1 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Outra influência importante é a popularidade e o nível do vôlei internacional, que fornece inspiração e motivação para o vôlei universitário. O vôlei internacional inclui vários torneios regionais e globais onde as melhores equipes e jogadores do mundo se encontram. Alguns desses torneios incluem:
    </MKTypography>
    {/* Lista de Torneios Internacionais */}
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Os Jogos Olímpicos: Este é o maior e mais prestigiado evento esportivo do mundo, realizado a cada quatro anos. O vôlei é um esporte olímpico oficial desde 1964, tanto para homens quanto para mulheres. Os Estados Unidos conquistaram um total de 9 medalhas olímpicas no vôlei, incluindo 3 ouros, 3 pratas e 3 bronzes.
        </li>
        <li>
          O Campeonato Mundial: Este é o torneio mais importante para as seleções nacionais, realizado a cada quatro anos. É organizado pela Federação Internacional de Vôlei (FIVB), o órgão governante global do vôlei. O Campeonato Mundial é realizado desde 1949 para homens e desde 1952 para mulheres. Os Estados Unidos conquistaram um total de 5 medalhas no Campeonato Mundial de vôlei, incluindo 2 ouros, 1 prata e 2 bronzes.
        </li>
        <li>
          A Copa do Mundo: Este é outro torneio para seleções nacionais, realizado a cada quatro anos. Também é organizado pela FIVB, mas difere do Campeonato Mundial em termos de número de equipes participantes (12 em vez de 24) e sistema de qualificação (baseado em campeonatos continentais). A Copa do Mundo é realizada desde 1965 para homens e desde 1973 para mulheres. Os Estados Unidos conquistaram um total de 7 medalhas na Copa do Mundo de vôlei, incluindo 2 ouros, 3 pratas e 2 bronzes.
        </li>
      </ul>
    </MKTypography>
  </MKBox>

  {/* Subcapítulo 4.2: Americanos no Palco Mundial */}
  <MKBox id="4.2" mb={2}>
    {/* Título do Subcapítulo 4.2 */}
    <MKTypography variant="h4" mb={1}>
      4.2 Americanos no Palco Mundial
    </MKTypography>
    {/* Texto do Subcapítulo 4.2 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O vôlei universitário na América não apenas aprendeu com o vôlei internacional, mas também contribuiu significativamente para o vôlei internacional. Muitos jogadores americanos que competiram no nível universitário também participaram com sucesso de competições internacionais, tanto por suas seleções nacionais quanto por clubes profissionais. Aqui estão alguns exemplos de jogadores americanos que se destacaram no palco mundial:
    </MKTypography>
    {/* Lista de Americanos no Palco Mundial */}
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Karch Kiraly: Ele é um dos maiores jogadores de vôlei de todos os tempos, destacando-se tanto no vôlei de quadra quanto no vôlei de praia. Ele jogou pela UCLA, onde se tornou tricampeão nacional. Ele também jogou pela seleção nacional, conquistando dois ouros olímpicos no vôlei de quadra (1984 e 1988) e um no vôlei de praia (1996). Ele também jogou profissionalmente na Itália, onde conquistou quatro títulos italianos e dois títulos da Liga dos Campeões.
        </li>
        <li>
          Logan Tom: Ela é uma das maiores jogadoras de vôlei feminino de todos os tempos, jogando tanto como atacante quanto como líbero. Ela jogou pela Stanford, onde se tornou campeã nacional e quatro vezes All-American. Ela também jogou pela seleção nacional, participando de quatro Jogos Olímpicos e conquistando duas medalhas de prata (2008 e 2012). Ela também jogou profissionalmente em vários países, incluindo Brasil, Turquia, Japão e China.
        </li>
        <li>
          Matt Anderson: Ele é um dos melhores jogadores de vôlei masculino da geração atual, capaz de jogar tanto como oposto quanto como ponteiro. Ele jogou pela Penn State, onde se tornou campeão nacional em 2008 e foi nomeado All-American em 2007 e 2008. Ele também joga pela seleção nacional, conquistando bronze nas Olimpíadas de 2016 e ouro na Copa do Mundo de 2015. Ele também jogou profissionalmente na Rússia, onde conquistou três títulos russos e dois títulos da Liga dos Campeões.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>
            






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    As bolsas de estudo esportivas oferecem inúmeras oportunidades para jovens atletas mostrarem seu talento e desenvolverem suas habilidades. Uma das oportunidades mais significativas é obter uma bolsa de estudos esportiva para estudar e competir no nível universitário. Neste capítulo, discutiremos a importância das bolsas de estudo esportivas e como a Sport Scholarships America pode ajudá-lo a realizar seu sonho.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 A Importância das Bolsas de Estudo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Uma bolsa de estudos esportiva é um suporte financeiro fornecido por uma universidade a um estudante-atleta para combinar estudos e esportes. Pode cobrir os custos de mensalidades, livros, moradia, refeições e outras despesas. Além disso, pode oferecer benefícios como tutoria acadêmica, cuidados médicos, instalações de treinamento e equipamentos.
      <br />
      <br />
      Obter uma bolsa de estudos esportiva é competitivo, com muitos fatores desempenhando um papel no processo de concessão, incluindo realizações acadêmicas, desempenho atlético, personalidade, motivação, liderança e adaptabilidade. Portanto, é importante se preparar bem e se destacar da concorrência.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Como a Sport Scholarships America Pode Ajudar
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo da Sport Scholarships America, mostrando equipamentos esportivos e um capelo de formatura, representando o foco em bolsas de estudo esportivas para estudantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo da Sport Scholarships America
        </figcaption>
      </figure>
      A Sport Scholarships America é uma organização especializada em orientar jovens talentos esportivos para bolsas de estudo esportivas. Com especialistas apaixonados e experientes, a Sport Scholarships America apoia estudantes-atletas em cada etapa da jornada, desde a orientação inicial até a colocação final. Seus serviços incluem:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Uma entrevista de admissão pessoal para discutir os desejos, possibilidades e expectativas do estudante-atleta.</li>
        <li>Uma avaliação profissional do nível acadêmico e atlético do estudante-atleta.</li>
        <li>Uma extensa rede de contatos com treinadores, universidades e faculdades.</li>
        <li>Promoção eficaz do perfil e vídeos do estudante-atleta para treinadores potenciais.</li>
        <li>Conselhos especializados sobre as melhores opções com base nas preferências, metas e orçamento do estudante-atleta.</li>
        <li>Negociação das melhores ofertas de bolsas de estudo em nome do estudante-atleta.</li>
        <li>Auxílio na solicitação de documentos necessários, como visto, histórico escolar, diploma e testes de admissão.</li>
        <li>Preparação para a vida do estudante-atleta durante a bolsa de estudos, incluindo cultura, idioma, regras e dicas.</li>
        <li>Acompanhamento e acompanhamento durante o período da bolsa de estudos.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Quer explorar oportunidades de bolsas de estudo e alcançar seus objetivos atléticos e acadêmicos? <Link to="/pt/Serviços-Produtos">Veja a gama completa</Link> de serviços oferecidos pela Sport Scholarships America e agende uma consulta hoje.
    </MKTypography>
  </MKBox>
</MKBox>



          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;