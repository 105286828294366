/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Gymnastics1.jpeg";
import Image2 from "assets/images/Sports_Images/Gymnastics2.jpg";
import Image3 from "assets/images/Sports_Images/Gymnastics3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Gymnastique et Études en Amérique : Une Opportunité Unique pour les Jeunes Gymnastes', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Chapitre 1 : Histoire et Développement de la Gymnastique en Amérique', isSubheader: false },
    { id: '1.1', title: '1.1 Premières Années et le Rôle des Universités', isSubheader: true },
    { id: '1.2', title: '1.2 Croissance et Professionnalisation', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Chapitre 2 : La Gymnastique Universitaire Aujourd\'hui', isSubheader: false },
    { id: '2.1', title: '2.1 Principales Compétitions et Conférences', isSubheader: true },
    { id: '2.2', title: '2.2 Programmes d\'Élite et Équipes Prestigieuses', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Chapitre 3 : Du Collège aux Professionnels', isSubheader: false },
    { id: '3.1', title: '3.1 Le Chemin vers le Professionnalisme', isSubheader: true },
    { id: '3.2', title: '3.2 Carrières et Opportunités Professionnelles', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Chapitre 4 : Influences Internationales', isSubheader: false },
    { id: '4.1', title: '4.1 Influences Étrangères et Échanges', isSubheader: true },
    { id: '4.2', title: '4.2 Américains à l\'Étranger', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Chapitre 5 : Bourses Sportives et l\'Avenir des Athlètes', isSubheader: false },
    { id: '5.1', title: '5.1 L\'Importance des Bourses', isSubheader: true },
    { id: '5.2', title: '5.2 Comment Sport Scholarships America Peut Aider', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Sommaire
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Cacher</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Afficher</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Gymnastique et Études en Amérique : Une Opportunité Unique pour les Jeunes Gymnastes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Vue des tribunes lors d'une compétition de gymnastique universitaire dans un grand stade."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Une grande arène lors d'une compétition de gymnastique universitaire de la NCAA
      </figcaption>
    </figure>
    La gymnastique a une longue et riche histoire aux États-Unis. Depuis le XIXe siècle, la gymnastique est pratiquée dans les universités, où les gymnastes développent leurs compétences et mettent en valeur leurs talents. La gymnastique n'est pas seulement un sport ; c'est aussi une forme d'art, une science et un mode de vie. Les gymnastes apprennent la discipline, la persévérance, la créativité et le travail d'équipe. Ils s'entraînent dur mais s'amusent aussi beaucoup.
    <br />
    <br />
    Concourir en gymnastique au niveau collégial est une opportunité unique pour les jeunes gymnastes de réaliser leurs rêves. En obtenant une bourse sportive, vous pouvez étudier dans une université prestigieuse tout en participant à des compétitions excitantes contre d'autres gymnastes de haut niveau. Vous faites partie d'une communauté soudée de sportifs-étudiants qui se soutiennent et se motivent mutuellement. Vous avez accès aux meilleures installations, aux meilleurs entraîneurs et mentors qui vous aident à atteindre votre potentiel. Et vous vivez une expérience inoubliable qui vous prépare à votre future carrière.
    <br />
    <br />
    Mais comment obtenir une bourse sportive ? Quels sont les critères et les avantages ? Comment trouver la bonne université pour vous ? Et quelle est la vie d'un étudiant-gymnaste ? Ce sont toutes des questions auxquelles Sportbeursamerika.nl peut répondre pour vous. Sportbeursamerika.nl est une plateforme qui aide les jeunes gymnastes à trouver leur chemin vers l'Amérique. J'offre un accompagnement personnel, des conseils et un soutien tout au long du processus de candidature, de sélection, de préparation et de départ. J'ai des contacts avec des centaines d'universités et d'entraîneurs à la recherche de talents en gymnastique. Je connais les tenants et les aboutissants du système éducatif américain et de la gymnastique universitaire. Et j'ai une expérience personnelle du sport et des études en Amérique.
    <br />
    <br />
    Êtes-vous curieux des opportunités de combiner la gymnastique et les études en Amérique ? Continuez à lire et découvrez tout ce que vous devez savoir sur cette opportunité unique.
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>

<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 1 : Histoire et Développement de la Gymnastique en Amérique
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    La gymnastique est l'un des sports les plus anciens au monde, avec ses origines dans les civilisations anciennes telles que la Grèce, Rome et la Chine. La gymnastique était utilisée comme un moyen d'entraîner le corps, d'aiguiser l'esprit et d'élever l'âme. Elle était également considérée comme une forme d'expression, d'art et de beauté.
    <br />
    <br />
    Aux États-Unis, la gymnastique a commencé à se développer au XIXe siècle lorsque les immigrants européens ont apporté leurs connaissances et leurs compétences. La gymnastique est rapidement devenue populaire dans les écoles, les clubs et les associations, où elle était vue comme un moyen de promouvoir la santé, le caractère et la citoyenneté. La gymnastique est également devenue une source de fierté nationale, notamment après la guerre d'indépendance.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Premières Années et le Rôle des Universités
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Les premières équipes de gymnastique universitaire ont été formées à la fin du XIXe siècle lorsque les étudiants ont créé leurs propres clubs et organisé des compétitions interuniversitaires. La première compétition officielle de gymnastique universitaire a eu lieu en 1897 lorsque l'Université Yale a affronté le Springfield College. Le Springfield College est considéré comme le berceau de la gymnastique américaine car c'était la première école à offrir la gymnastique comme matière académique. De nombreux entraîneurs et dirigeants de gymnastique renommés ont étudié ou enseigné au Springfield College.
      <br />
      <br />
      Au début du XXe siècle, la gymnastique universitaire a continué de se développer avec la création d'organisations régionales et nationales comme la Ligue de Gymnastique Intercollegiale de l'Est (EIGL) en 1902 et la National Collegiate Athletic Association (NCAA) en 1906. La NCAA a commencé à organiser des championnats nationaux pour les hommes en 1938 et pour les femmes en 1982. La NCAA reste l'organisation principale de la gymnastique universitaire en Amérique.
    </MKTypography>
  </MKBox>

  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Croissance et Professionnalisation
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La popularité et le niveau de la gymnastique universitaire ont augmenté après la Seconde Guerre mondiale, lorsque de nombreux vétérans sont retournés à l'université et ont poursuivi leurs ambitions sportives. L'influence des entraîneurs et des athlètes européens, venus en Amérique pour fuir le communisme ou le nazisme, a également contribué au développement de la gymnastique universitaire. Une figure emblématique en était George Gulack, un gymnaste hongrois qui a remporté l'or aux Jeux olympiques de 1936 à Berlin. Gulack a joué un rôle important au sein du comité olympique et des organisations de gymnastique américaines et internationales, siégeant au Comité olympique américain de 1934 à 1958.
      <br />
      <br />
      Dans les années 1950 et 60, la gymnastique professionnelle a commencé à se différencier de la gymnastique universitaire, avec l'émergence de stars internationales telles que Larisa Latynina, Olga Korbut et Nadia Comaneci. Ces gymnastes ont inspiré de nombreux jeunes Américains à rejoindre des clubs privés et à se concentrer sur des réalisations individuelles. L'une d'elles était Cathy Rigby, qui est devenue la première femme américaine à remporter une médaille lors d'un championnat du monde en 1970. Elle est ensuite devenue une actrice très réussie.
      <br />
      <br />
      La gymnastique professionnelle a atteint son apogée en 1978 lorsque le gymnaste américain Kurt Thomas est devenu le premier homme à remporter une médaille d'or lors d'un championnat du monde. Il a étudié à l'Université d'État de l'Indiana et est ensuite devenu acteur. La même année, Mary Lou Retton a fait ses débuts sur la scène nationale. Elle est devenue la première femme américaine à remporter l'or dans le concours général aux Jeux olympiques de 1984.
      <br />
      <br />
      La gymnastique universitaire est cependant restée pertinente et influente, surtout pour les femmes. De nombreuses gymnastes féminines ont choisi de poursuivre leur carrière au niveau collégial car elles y recevaient plus d'opportunités et d'avantages que dans le circuit professionnel.
      <br />
      <br />
      La gymnastique universitaire a donc joué un rôle important dans l'histoire et le développement de la gymnastique en Amérique. Elle a produit de nombreux athlètes qui se sont distingués non seulement dans leur sport, mais aussi dans leurs études et leurs carrières ultérieures. Elle a également contribué à la diffusion et à la promotion de la gymnastique en tant que sport pour tous, quel que soit l'âge, le sexe ou l'origine. La gymnastique universitaire reste un sport vibrant et dynamique qui attire des milliers de fans et de spectateurs chaque année.
    </MKTypography>
  </MKBox>
</MKBox>



                                              





                                  








<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 2 : La Gymnastique Universitaire Aujourd'hui
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    La gymnastique est l'un des sports les plus populaires et compétitifs au niveau universitaire en Amérique. Chaque année, des centaines d'équipes et des milliers d'athlètes se disputent les titres et trophées tant convoités. La gymnastique universitaire offre une combinaison unique d'athlétisme, de spectacle et d'excellence académique. C'est un sport qui demande beaucoup mais qui donne aussi beaucoup en retour.
  </MKTypography>
  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Principales Compétitions et Conférences
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Une gymnaste d'Auburn exécutant une routine au sol lors d'une compétition universitaire."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Une gymnaste d'Auburn en action
        </figcaption>
      </figure>
      La gymnastique universitaire est régie par la NCAA, qui établit les règles, les qualifications et les championnats. La NCAA divise la gymnastique universitaire en trois divisions en fonction du nombre de bourses, du budget et du niveau de l'équipe. La Division I est la division la plus élevée, avec la plupart des meilleurs gymnastes et équipes participant. Les Divisions II et III sont des divisions inférieures offrant moins de bourses et mettant davantage l'accent sur la performance académique.
      <br />
      <br />
      Au sein de chaque division, il existe différentes conférences formant des groupes régionaux ou thématiques d'universités. Les conférences organisent leurs propres compétitions et championnats, qui servent de préliminaires aux championnats nationaux. Les principales conférences de gymnastique universitaire sont :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          La Conférence Big Ten, composée de 14 universités dans le Midwest et le Nord-Est de l'Amérique. La Big Ten est l'une des conférences les plus anciennes et prestigieuses, réputée pour son excellence académique et sportive. La Big Ten compte parmi ses membres certains des meilleurs programmes de gymnastique masculins et féminins, notamment Michigan, Minnesota, Nebraska et Penn State.
        </li>
        <li>
          La Conférence du Sud-Est (SEC), regroupant 14 universités dans le Sud-Est de l'Amérique. La SEC est l'une des conférences les plus dominantes et influentes, connue pour ses fans passionnés et loyaux. La SEC compte parmi ses membres certains des meilleurs programmes de gymnastique féminine, tels qu'Alabama, Florida, Georgia et LSU.
        </li>
        <li>
          La Conférence Pac-12, composée de 12 universités dans l'Ouest des États-Unis. La Pac-12 est l'une des conférences les plus innovantes et diversifiées, réputée pour sa créativité et sa culture. La Pac-12 compte parmi ses membres certains des meilleurs programmes de gymnastique masculins et féminins, notamment California, Stanford, UCLA et Utah.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Les championnats nationaux sont le point culminant de la saison de gymnastique universitaire, où les meilleures équipes et les meilleurs individus s'affrontent pour l'honneur et la gloire. Les championnats nationaux ont lieu chaque année en avril dans différents endroits à travers le pays. Les championnats nationaux se composent de deux tours : les championnats régionaux et les finales nationales. Les championnats régionaux sont les tours de qualification, où 36 équipes masculines et 36 équipes féminines sont réparties en six régions. Les deux meilleures équipes de chaque région se qualifient pour les finales nationales. Les finales nationales sont les tours décisifs, où 12 équipes masculines et 12 équipes féminines sont réparties en deux sessions. Les quatre meilleures équipes de chaque session se qualifient pour la super finale (Super Six), où elles se disputent le titre de champion national par équipe. De plus, des titres individuels sont décernés pour la compétition générale et les finales par agrès.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programmes d'Élite et Équipes Prestigieuses
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La gymnastique universitaire a produit plusieurs programmes d'élite distingués par leur régularité, leur qualité et leur tradition. Ces programmes ont remporté plusieurs titres nationaux, formé de nombreux athlètes talentueux et attiré de nombreux fans. Certains de ces programmes comprennent :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Bruins d'UCLA : Le programme de gymnastique d'UCLA est l'un des programmes les plus réussis et respectés du pays. Le programme de gymnastique d'UCLA a remporté plus de 20 titres nationaux, dont plusieurs titres pour les hommes et la majorité pour les femmes. Le programme a également produit de nombreux champions olympiques et mondiaux, tels que Peter Vidmar, Tim Daggett, Jamie Dantzscher, Mohini Bhardwaj, Tasha Schwikert, Samantha Peszek et Kyla Ross.
        </li>
        <li>
          Sooners d'Oklahoma : Le programme de gymnastique d'Oklahoma est l'un des programmes les plus dominants et impressionnants du pays. Le programme de gymnastique d'Oklahoma a remporté plus de 10 titres nationaux, dont plusieurs titres pour les hommes et presque autant pour les femmes. Le programme a également produit de nombreux champions NCAA, dont Jonathan Horton, Jake Dalton, Maggie Nichols, Brenna Dowell et Anastasia Webb.
        </li>
        <li>
          Utes d'Utah : Le programme de gymnastique d'Utah est l'un des programmes les plus historiques et loyaux du pays. Le programme de gymnastique d'Utah a remporté plus de 10 titres nationaux, tous pour les femmes. Le programme a également produit des entraîneurs et des athlètes légendaires, dont Greg Marsden, Megan Marsden, Missy Marlowe, Theresa Kulikowski, Ashley Postell et MyKayla Skinner.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La gymnastique universitaire offre une opportunité unique pour les jeunes gymnastes de pratiquer leur sport au plus haut niveau tout en recevant une éducation précieuse. La gymnastique universitaire est un sport qui offre de nombreux défis et récompenses, sur et en dehors du praticable. La gymnastique universitaire est un sport qui peut changer votre vie.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  








<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 3 : Du Collège aux Professionnels
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    La gymnastique est un sport qui exige beaucoup de talent, de dévouement et de persévérance. Les gymnastes commencent souvent leur entraînement à un jeune âge et rêvent d'une carrière professionnelle. Mais comment passer de la gymnastique universitaire à la gymnastique professionnelle ? Et quelles sont les opportunités et les défis qui vous attendent ?
  </MKTypography>
  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Le Chemin vers le Professionnalisme
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La gymnastique universitaire est une excellente préparation pour la gymnastique professionnelle car elle vous donne l'opportunité d'améliorer vos compétences, de gagner de l'expérience et d'élargir votre réseau. De nombreux gymnastes universitaires ont participé à des compétitions professionnelles telles que les Jeux olympiques, les championnats du monde et la Coupe du monde. Certains ont même remporté des médailles.
      <br />
      <br />
      Cependant, pour participer à des compétitions professionnelles, les gymnastes universitaires doivent respecter certaines règles et conditions. Ils doivent maintenir leur statut d'amateur, ce qui signifie qu'ils ne peuvent pas gagner d'argent grâce à leur sport. Ils n'ont pas non plus le droit d'avoir des sponsors ou des agents à moins de recevoir l'autorisation de la NCAA. Ils doivent également satisfaire aux exigences académiques de leur université, ce qui peut parfois être difficile à concilier avec leur emploi du temps d'entraînement.
      <br />
      <br />
      De plus, les gymnastes universitaires doivent également tenir compte de leur âge et de leur condition physique. La gymnastique est un sport qui impose beaucoup de contraintes au corps et qui entraîne souvent des blessures. Beaucoup de gymnastes atteignent leur apogée autour de la vingtaine et peuvent avoir du mal à maintenir leur niveau par la suite. Certains gymnastes choisissent d'abandonner leurs ambitions professionnelles et de se concentrer sur leurs études ou leur carrière en dehors du sport.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Carrières et Opportunités Professionnelles
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Pour ceux qui décident de poursuivre leur carrière en gymnastique professionnelle, il existe diverses opportunités et possibilités pour poursuivre leur passion. L'une des options les plus prestigieuses et lucratives est de participer aux Jeux olympiques, qui ont lieu tous les quatre ans. Les Jeux olympiques sont le point culminant de toute carrière sportive, offrant la possibilité de représenter son pays, de concourir contre les meilleurs gymnastes du monde et de remporter une médaille. Les Jeux olympiques apportent également la gloire et la reconnaissance qui peuvent conduire à des contrats de sponsoring, à une attention médiatique et à d'autres avantages.
      <br />
      <br />
      Une autre option est de participer aux championnats du monde, qui ont lieu chaque année (sauf les années olympiques). Les championnats du monde sont un indicateur significatif du niveau et du développement de la gymnastique dans le monde entier. Les championnats du monde offrent également la possibilité de se qualifier pour les Jeux olympiques ou de recevoir une wild card. Les championnats du monde sont une source de fierté et de prestige qui peuvent conduire à des prix en argent, des bourses et d'autres récompenses.
      <br />
      <br />
      Une troisième option est de participer à la Coupe du monde, qui se compose d'une série de compétitions individuelles organisées dans le monde entier. La Coupe du monde est une option attrayante pour les gymnastes à la recherche de plus de flexibilité et de variété dans leur carrière sportive. La Coupe du monde offre également la possibilité de découvrir différents pays et cultures, de se faire de nouveaux amis et d'élargir ses horizons. La Coupe du monde est également une source de plaisir et d'aventure qui peut conduire à des remboursements de voyage, des primes et d'autres incitations.
      <br />
      <br />
      En plus de ces compétitions internationales, il existe d'autres opportunités professionnelles pour les gymnastes, telles que :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Animer des cliniques, des ateliers ou des démonstrations où vous partagez vos connaissances et votre expérience avec d'autres gymnastes, entraîneurs ou fans.
        </li>
        <li>
          Travailler en tant qu'entraîneur, formateur ou conseiller, aidant d'autres gymnastes à atteindre leurs objectifs, à améliorer leur technique ou à résoudre leurs problèmes.
        </li>
        <li>
          Travailler en tant que commentateur, analyste ou journaliste, offrant votre opinion et votre éclairage sur la gymnastique, les compétitions ou les événements actuels.
        </li>
        <li>
          Travailler en tant que mannequin, acteur ou influenceur, utilisant votre apparence, votre personnalité ou votre popularité pour promouvoir des produits, des services ou des marques.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La gymnastique professionnelle est une carrière excitante et stimulante qui offre de nombreuses possibilités et opportunités. Cependant, c'est aussi une carrière exigeante et incertaine qui comporte des risques et des obstacles. Choisir une carrière dans la gymnastique professionnelle nécessite à la fois votre cœur et votre tête.
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  







<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 4 : Influences Internationales
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    La gymnastique est un sport sans frontières. La gymnastique est un sport qui rassemble des personnes du monde entier, les inspire et les enrichit. La gymnastique est un sport influencé par diverses cultures, styles et traditions. Dans ce chapitre, nous examinerons les influences internationales sur la gymnastique universitaire en Amérique et comment ces influences ont façonné et transformé la gymnastique universitaire.
  </MKTypography>
  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Influences Étrangères et Échanges
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Gymnaste de Stanford en action sur le cheval d'arçons lors d'une compétition universitaire."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Un gymnaste de Stanford en action
        </figcaption>
      </figure>
      Dans le monde fascinant de la gymnastique universitaire en Amérique, l'échange international joue un rôle crucial. Saviez-vous que les gymnastes étrangers apportent une dynamique unique et des techniques qui élèvent le niveau de la compétition ? Ces athlètes apportent souvent des styles et des méthodes innovants qui inspirent et défient leurs homologues américains. Cela rend non seulement le sport plus diversifié mais aussi plus compétitif.
      <br />
      <br />
      Un exemple en est l'impact des gymnastes d'Europe de l'Est dans les années 80 et 90. Leur arrivée aux États-Unis a apporté de nouvelles techniques et méthodes d'entraînement, conduisant à une évolution significative de la gymnastique américaine. Ces influences sont encore visibles aujourd'hui dans le style et la technique de nombreux meilleurs gymnastes.
      <br />
      <br />
      La diversité que les gymnastes internationaux apportent enrichit la culture sportive sur les campus. Cela crée un creuset de cultures et d'idées qui ne peut que bénéficier au sport. De plus, cela offre aux étudiants et aux entraîneurs américains l'opportunité d'apprendre de différentes perspectives culturelles et d'affûter leurs propres compétences et tactiques.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Américains à l'Étranger
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      D'autre part, les gymnastes américains ont également eu un impact significatif sur le monde de la gymnastique internationale. De nombreux étudiants-athlètes américains ont connu un succès international après leur carrière universitaire. Leurs performances aux championnats du monde et aux Jeux olympiques résultent souvent de l'entraînement et de l'expérience qu'ils ont acquis dans le circuit collégial.
      <br />
      <br />
      Ces gymnastes servent d'ambassadeurs de la culture de la gymnastique américaine. Leurs succès inspirent les jeunes gymnastes du monde entier à aspirer à faire partie d'une équipe universitaire américaine. Cela crée un cycle positif où le talent et les connaissances sont échangés à travers les frontières.
      <br />
      <br />
      Les parcours de ces gymnastes montrent comment la gymnastique universitaire peut être une excellente base pour une carrière internationale. Les expériences qu'ils acquièrent pendant leurs études - de la participation à des compétitions de haut niveau à l'entraînement sous la direction de grands entraîneurs - les préparent aux défis du monde professionnel du sport.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  


<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 5 : Bourses Sportives et l'Avenir des Athlètes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Les bourses sportives offrent de nombreuses opportunités aux jeunes athlètes de montrer leur talent et de développer leurs compétences. L'une des opportunités les plus significatives est d'obtenir une bourse sportive pour étudier et compétitionner au niveau collégial. Dans ce chapitre, nous discuterons de l'importance des bourses sportives et de comment Sport Scholarships America peut vous aider à réaliser votre rêve.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 L'Importance des Bourses
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Une bourse sportive est un soutien financier fourni par une université à un étudiant-athlète pour combiner études et sport. Elle peut couvrir les frais de scolarité, les livres, le logement, les repas et autres dépenses. De plus, elle peut offrir des avantages tels que du tutorat académique, des soins médicaux, des installations d'entraînement et du matériel.
      <br />
      <br />
      Obtenir une bourse sportive est compétitif, avec de nombreux facteurs qui influent sur le processus d'attribution, notamment les performances académiques, sportives, la personnalité, la motivation, le leadership et l'adaptabilité. Il est donc important de bien se préparer et de se démarquer de la concurrence.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Comment Sport Scholarships America Peut Aider
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo de Sport Scholarships America, représentant du matériel sportif et une casquette de graduation, symbolisant le focus sur les bourses sportives pour les étudiants-athlètes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America est une organisation spécialisée dans l'orientation des jeunes talents sportifs vers des bourses sportives. Avec des experts passionnés et expérimentés, Sport Scholarships America soutient les étudiants-athlètes à chaque étape du parcours, de l'orientation initiale à la placement final. Leurs services comprennent :
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Un entretien personnel pour discuter des souhaits, des possibilités et des attentes de l'étudiant-athlète.</li>
        <li>Une évaluation professionnelle du niveau académique et sportif de l'étudiant-athlète.</li>
        <li>Un vaste réseau de contacts avec des entraîneurs, des universités et des collèges.</li>
        <li>Promotion efficace du profil et des vidéos de l'étudiant-athlète auprès des entraîneurs potentiels.</li>
        <li>Conseils d'experts sur les meilleures options en fonction des préférences, des objectifs et du budget de l'étudiant-athlète.</li>
        <li>Négociation des meilleures offres de bourses au nom de l'étudiant-athlète.</li>
        <li>Aide à la demande de documents nécessaires, tels que visa, relevé de notes, diplôme et tests d'admission.</li>
        <li>Préparation à la vie d'étudiant-athlète pendant sa bourse, y compris culture, langue, règles et conseils.</li>
        <li>Suivi et accompagnement pendant la période de la bourse.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vous voulez explorer les opportunités de bourses et atteindre vos objectifs sportifs et académiques ? <Link to="/fr/Services-Produits">Consultez la gamme complète</Link> des services offerts par Sport Scholarships America et planifiez une consultation dès aujourd'hui.
    </MKTypography>
  </MKBox>
</MKBox>
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;