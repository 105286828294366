/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 PRO React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';

// Pages
import Faq from "Languages/Espanol/pages/Contact/FAQs";
import Author from "Languages/Espanol/pages/Over/Overmij/Profiel";
import VirtualRealityPage from "Languages/Espanol/pages/Content/Podcast";
import AllSports from "Languages/Espanol/pages/All Sports/Overzicht";
import Contactformulier from "Languages/Espanol/pages/Contact/Contactformulier"
import Jeugd from "Languages/Espanol/pages/Over/Overmij/Jeugd";
import Amerika from "Languages/Espanol/pages/Over/Overmij/Amerika";
import NaUSA from "Languages/Espanol/pages/Over/Overmij/NaUSA";
import DienstenProducten from "Languages/Espanol/pages/Aanbod/Diensten_producten";

import Timeline from "Languages/Espanol/pages/Aanbod/Traject";
import AmericanFootball from "Languages/Espanol/pages/All Sports/Sports/AmericanFootball";
import Athletics from "Languages/Espanol/pages/All Sports/Sports/Track and Field";
import Basketball from "Languages/Espanol/pages/All Sports/Sports/Basketball";
import Golf from "Languages/Espanol/pages/All Sports/Sports/Golf";
import Hockey from "Languages/Espanol/pages/All Sports/Sports/Field Hockey";
import Baseball from "Languages/Espanol/pages/All Sports/Sports/Baseball";
import Icehockey from "Languages/Espanol/pages/All Sports/Sports/Ice Hockey";
import Rowing from "Languages/Espanol/pages/All Sports/Sports/Rowing";
import Softbal from "Languages/Espanol/pages/All Sports/Sports/Softbal";
import Tennis from "Languages/Espanol/pages/All Sports/Sports/Tennis";
import Gymnastics from "Languages/Espanol/pages/All Sports/Sports/Gymnastics";
import Soccer from "Languages/Espanol/pages/All Sports/Sports/Soccer";
import Volleyball from "Languages/Espanol/pages/All Sports/Sports/Volleyball";
import Waterpolo from "Languages/Espanol/pages/All Sports/Sports/Waterpolo";
import Wrestling from "Languages/Espanol/pages/All Sports/Sports/Wrestling";
import Swimming from "Languages/Espanol/pages/All Sports/Sports/Swimming";
import CompanyTimeline from "Languages/Espanol/pages/Over/Over Sport Scholarships America";
import Kenniscentrum from "Languages/Espanol/pages/Content/Kenniscentrum";





const routes = [
  {
    name: "Deportes",
    icon: <SportsSoccerIcon></SportsSoccerIcon>,
    collapse: [
      {
        name: "Todos los Deportes",
        description: "Descubre Todos los Deportes",
        route: "/es/Todos-los-deportes",
        component: <AllSports />,
        dropdown: true,
        collapse: [
          {
            name: "Atletismo",
            route: "/es/Todos-los-deportes/Atletismo",
            component: <Athletics />,
          },
          {
            name: "Béisbol",
            route: "/es/Todos-los-deportes/Béisbol",
            component: <Baseball />,
          },
          {
            name: "Baloncesto",
            route: "/es/Todos-los-deportes/Baloncesto",
            component: <Basketball />,
          },
          {
            name: "Fútbol",
            route: "/es/Todos-los-deportes/Fútbol",
            component: <Soccer />,
          },
          {
            name: "Fútbol Americano",
            route: "/es/Todos-los-deportes/Fútbol-Americano",
            component: <AmericanFootball />,
          },
          {
            name: "Gimnasia",
            route: "/es/Todos-los-deportes/Gimnasia",
            component: <Gymnastics />,
          },
          {
            name: "Golf",
            route: "/es/Todos-los-deportes/Golf",
            component: <Golf />,
          },
          {
            name: "Hockey",
            route: "/es/Todos-los-deportes/Hockey",
            component: <Hockey />,
          },
          {
            name: "Hockey Sobre Hielo",
            route: "/es/Todos-los-deportes/Hockey-sobre-hielo",
            component: <Icehockey />,
          },
          {
            name: "Lucha",
            route: "/es/Todos-los-deportes/Lucha",
            component: <Wrestling />,
          },
          {
            name: "Natación",
            route: "/es/Todos-los-deportes/Natación",
            component: <Swimming />,
          },
          {
            name: "Remo",
            route: "/es/Todos-los-deportes/Remo",
            component: <Rowing />,
          },
          {
            name: "Softball",
            route: "/es/Todos-los-deportes/Softball",
            component: <Softbal />, // Note: Typo in component name `<Softbal />`, should it be `<Softball />`?
          },
          {
            name: "Tenis",
            route: "/es/Todos-los-deportes/Tenis",
            component: <Tennis />,
          },
          {
            name: "Volleyball",
            route: "/es/Todos-los-deportes/Volleyball",
            component: <Volleyball />,
          },
          {
            name: "Waterpolo",
            route: "/es/Todos-los-deportes/Waterpolo",
            component: <Waterpolo />,
          },
        ]
        
      },
   

],
  },     

    {
      name: "Acerca de",
      icon: <Icon>person</Icon>,
    
      collapse: [
        {
          name: "Acerca De Mí",
          description: "Una introducción",
          route: "/es/Acerca-de-mí",
          component: <Author />,
        
          dropdown: true,
          collapse: [
            {
              name: "Parte 1: Creciendo en los Países Bajos",
              route: "/es/Acerca-de-mí/Creciendo",
              component: <Jeugd />,
            },

            {
              name: "Parte 2: Mi Tiempo en América",
              route: "/es/Acerca-de-mí/Mi-tiempo-en-América",
              component: <Amerika />,
            },

            {
              name: "Parte 3: Mi Regreso a Los-Países-Bajos",
              route: "/es/Acerca-de-mí/Mi-regreso-a-los-Países-Bajos",
              component: <NaUSA />,
            },
             ],
         },
         {
          name: "Acerca de Sport Scholarships America",
          description: "Historia de la compañía",
          route: "/es/Acerca-de-Sport-Scholarships-America",
          component: <CompanyTimeline />,
        
          dropdown: false,
          
         },
    ],
},    
 
  {
    name: "Servicios",
    icon: <Icon>monetization_on</Icon>,
    collapse: [
      {
        name: "Servicios & Productos",
        description: "Descubre todos los servicios y productos",
        route: "/es/Servicios-Productos",
        component: <DienstenProducten />,
        dropdown: false,
      
      },
      {
        name: "Proceso",
        description: "Resumen del proceso",
        route: "/es/Proceso",
        component: <Timeline />,
        dropdown: false,
       
      },
      
    ],
  },
  {
    name: "Contenido",
    icon: <Icon>article</Icon>,
    collapse: [
      {
        name: "Podcast",
        route: "/es/Podcast",
        description: "Escucha mi podcast",
        component: <VirtualRealityPage />
      },
      {
        name: "Centro de Conocimientos",
        route: "/es/Centro-de-conocimientos",
        description: "Artículos y enlaces útiles",
        component: <Kenniscentrum />
      },
     
    ],
  },
  {
    name: "Contacto",
    icon: <Icon>mail</Icon>,
    collapse: [
      {
        name: "FAQ",
        description:"Respuestas a Preguntas Frecuentes",
        route: "/es/FAQs",
        component: <Faq />,
      },
      {
        name: "Formulario de Contacto",
        route: "/es/Formulario-de-contacto",
        component: <Contactformulier />,
        description: "Póngase en contacto",
      },
  
     
    ],
  },
];

export default routes;
