import { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MKTypography from "components/MKTypography";

import MKBox from "components/MKBox";

import SAT from "assets/images/Producten/SAT_kaft.png";
import ACT from "assets/images/Producten/ACT_kaft.png";
import TOEFL from "assets/images/Producten/Toefl_kaft.png";
import Hoodie from "assets/images/Producten/Hoodie_front.png";
import Shirt from "assets/images/Producten/Shirt_front.png";
import { Link } from 'react-scroll';

import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

function Productos() {
  const [pestañaActiva, setPestañaActiva] = useState(1);
  const [tipoPestaña, setTipoPestaña] = useState("mensual");

  const manejarTipoPestaña = ({ currentTarget }, newValue) => {
    setPestañaActiva(newValue);
    setTipoPestaña(currentTarget.id);
  };

  
  return (
    <section id="seccionProductos">
      <Container sx={{ pb: { xs: 12, lg: 8 }, pt: 0 }}>
      <Grid container item xs={12} justifyContent="center" md={8} mx="auto" textAlign="center">
  {/* Envolver cada MKTypography en su propio elemento Grid */}
  <Grid item xs={12}> {/* Ancho completo para el título */}
    <MKTypography variant="h3" color="text">Productos</MKTypography>
  </Grid>
  <Grid item xs={12}> {/* Ancho completo para el texto */}
    <MKTypography variant="body2" color="text">
    Desde guías de estudio hasta mercancía de Sport Scholarships America





</MKTypography>
  </Grid>
  <Grid
          container
          item
          xs={12}
          md={10}
          lg={8}
          justifyContent="center"
          textAlign="center"
          mx="auto"
          mt={6}
        >
          <AppBar position="static">
          <Tabs value={pestañaActiva} onChange={manejarTipoPestaña}>
  <Tab
    id="mensual"
    label={
      <Link 
        to="seccionServicios" 
        spy={true}
        smooth={true}
        duration={500}
        offset={-70}
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <MKBox py={0.5} px={2} color="inherit">
          Servicios
        </MKBox>
      </Link>
    }
  />
  <Tab
    id="anual"
    label={
      <Link 
        to="seccionProductos" 
        spy={true}
        smooth={true}
        duration={500}
        offset={-70}
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <MKBox py={0.5} px={2} color="inherit">
          Productos
        </MKBox>
      </Link>
    }
  />
</Tabs>
          </AppBar>
        </Grid>
          
          
        <Grid container spacing={3} mt={6} mb={6}>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="Guía de Estudio SAT"
      image={SAT}
      description="Prepárate para el SAT"
      action={{ type: "internal", route: "/es/Servicios-Productos/SAT-guide", label: "Ordenar", color: "primary" }}
    />
  </Grid>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="Guía de Estudio ACT"
      image={ACT}
      description="Prepárate para el ACT"
      action={{ type: "internal", route: "/es/Servicios-Productos/ACT-guide", label: "Ordenar", color: "primary" }}
    />
  </Grid>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="Guía de Estudio TOEFL"
      image={TOEFL}
      description="Prepárate para el TOEFL"
      action={{ type: "internal", route: "/es/Servicios-Productos/TOEFL-guide", label: "Ordenar", color: "primary" }}
    />
  </Grid>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="Hoodie"
      image={Hoodie}
      description="El Hoodie de Sport Scholarships America"
      action={{ type: "internal", route: "/es/Servicios-Productos/Hoodie", label: "Ordenar", color: "primary" }}
    />
  </Grid>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="Camiseta"
      image={Shirt}
      description="La camiseta de Sport Scholarships America"
      action={{ type: "internal", route: "/es/Servicios-Productos/T-shirt", label: "Ordenar", color: "primary" }}
    />
  </Grid>
</Grid>

          
        
        </Grid>
      </Container>
    </section>
  );
}

export default Productos;
