/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Golf1.jpeg";
import Image2 from "assets/images/Sports_Images/Golf2.webp";
import Image3 from "assets/images/Sports_Images/Golf3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"


// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Golfstipendium: Sådan Studerer og Spiller du Golf i Amerika', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapitel 1: Historie og Udvikling af College Golf', isSubheader: false },
    { id: '1.1', title: '1.1 Tidlige År', isSubheader: true },
    { id: '1.2', title: '1.2 Vækst og Professionalisering', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapitel 2: Det Nuværende Landskab af College Golf', isSubheader: false },
    { id: '2.1', title: '2.1 Største Konkurrencer og Konferencer', isSubheader: true },
    { id: '2.2', title: '2.2 Topprogrammer og Prestigefyldte Hold', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapitel 3: Fra College til Professionelt Niveau', isSubheader: false },
    { id: '3.1', title: '3.1 Vejen til Den Professionelle Turné', isSubheader: true },
    { id: '3.2', title: '3.2 Alternative Ruter til Professionalisme', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapitel 4: Internationale Indflydelser og Muligheder', isSubheader: false },
    { id: '4.1', title: '4.1 Udenlandske Spillere i College Golf', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanske Spillere i Udlandet', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapitel 5: Sportslegater og fremtiden for atleter', isSubheader: false },
    { id: '5.1', title: '5.1 Vigtigheden af legater', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Legater Amerika Kan Hjælpe', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };





  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Golfstipendium: Sådan Studerer og Spiller du Golf i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Golf green under NCAA mesterskabet, med spillere og tilskuere omkring banen"
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        NCAA Golf Mesterskabet
      </figcaption>
    </figure>
    Golf er en af de mest populære sportsgrene på universitetsniveau i USA. Hvert år konkurrerer tusinder af studerende-atleter om nationale titler, prestigefyldte priser og en plads i professionel golf. College golf tilbyder ikke kun en chance for at forbedre dine atletiske færdigheder, men også for at opnå en akademisk grad, opbygge et netværk og få en uforglemmelig oplevelse.
    <br/>
    <br/>
    Dog er college golf ikke for alle. Det kræver et højt niveau af engagement, disciplin og talent. Desuden er det ikke let at blive optaget på et universitet med et godt golfprogram. Konkurrencen er hård, og omkostningerne er høje.
    <br/>
    <br/>
    Heldigvis er der muligheder for at gøre din drøm til virkelighed med hjælp fra et golfstipendium. Et golfstipendium er økonomisk støtte, du modtager fra et universitet for at studere og spille golf der. Med et golfstipendium kan du reducere eller endda fuldt dække dine studieomkostninger, samtidig med at du nyder alle fordelene ved college golf.
    <br/>
    <br/>
    Men hvordan får du et golfstipendium? Og hvad skal du overveje, når du vælger et universitet? Det er her, Sportbeursamerika.nl kan hjælpe dig. Sportbeursamerika.nl er specialisten i at guide studerende til sportsstipendier i Amerika. Jeg har erfaring og viden om det amerikanske uddannelses- og sportsystem, og jeg har hjulpet mange studerende-atleter med at realisere deres drøm.
    <br/>
    <br/>
    I denne artikel giver vi mere information om college golf og viser, hvordan Sportbeursamerika.nl kan støtte dig i at finde det perfekte golfstipendium for dig.
    <br/>
    <br/>
    Vil du vide mere? Bliv ved med at læse!
  </MKTypography>
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Hoofdstuk 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Historie og Udvikling af College Golf
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College golf har en lang og rig historie, der går tilbage til begyndelsen af det 20. århundrede. I dette kapitel vil vi se på de tidlige år og væksten og professionaliseringen af college golf i USA.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidlige År
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      De første golfkonkurrencer på universiteter og colleges i USA fandt sted i 1890'erne. Den første officielle interkollegiale golfkamp var mellem Harvard og Yale i 1897. I 1901 blev Intercollegiate Golf Association (IGA) grundlagt, som organiserede det første nationale mesterskab i 1902. Yale vandt den første titel og fortsatte med at dominere indtil 1920'erne.
      <br/>
      <br/>
      De første golfprogrammer på universiteter fokuserede primært på sportens sociale og rekreative aspekter. Studerende spillede primært på lokale baner og havde lidt vejledning eller faciliteter. Golfholdene bestod normalt af fire eller fem spillere, der kvalificerede sig gennem interne turneringer.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Vækst og Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      I 1930'erne begyndte college golf at vokse og professionalisere sig betydeligt. Denne periode så en stigning i antallet af universitetsgolfprogrammer og en forbedring af kvaliteten af træning og faciliteter. Professionaliseringen blev yderligere stimuleret af den stigende opmærksomhed på sporten og den voksende anerkendelse af golfsportens potentiale på college-niveauet. I løbet af denne tid tog National Collegiate Athletic Association (NCAA) en førende rolle i at organisere og regulere college golf, hvilket bidrog til yderligere udvikling og popularitet af sporten i USA.
      <br/>
      <br/>
      NCAA overtog organisationen af det nationale mesterskab fra IGA og introducerede nye regler og formater. NCAA opdelte universiteterne i forskellige divisioner baseret på deres størrelse og atletiske niveau. NCAA organiserede også regionale mesterskaber for at lette kvalifikationen til det nationale mesterskab.
      <br/>
      <br/>
      Universiteter begyndte også at investere mere i deres golfprogrammer ved at ansætte professionelle trænere, bygge eller forbedre golfbaner og tilbyde stipendier til talentfulde spillere. Golfholdene blev større og stærkere, og konkurrencen blev mere intens.
      <br/>
      <br/>
      I 1940'erne og 1950'erne blev der også tilføjet flere vigtige turneringer, såsom Walker Cup, Palmer Cup og Eisenhower Trophy. Disse turneringer gav college-golfspillere mulighed for at konkurrere med internationale spillere og forbedre deres færdigheder.
      <br/>
      <br/>
      I dette kapitel har vi set, hvordan college golf opstod og voksede i USA. I det næste kapitel vil vi se på det nuværende landskab af college golf og hvilke konkurrencer og programmer, der er tilgængelige.
    </MKTypography>
  </MKBox>
</MKBox>













                                              





                                  






<MKBox id="Chapter 2" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Kapitel 2: Det Nuværende Landskab af College Golf
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        I dag er college golf en af de mest konkurrencedygtige og prestigefyldte sportsgrene på universitetsniveau i USA. I dette kapitel vil vi se på de største konkurrencer og konferencer samt de bedste programmer og prestigefyldte hold i college golf.
    </MKTypography>

    <MKBox id="2.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.1 Største Konkurrencer og Konferencer
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image2}
                    alt="Tiger Woods repræsenterer Stanford i college golf, vist i kampkoncentration."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal2(Image2)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Tiger Woods i aktion for Stanford.
                </figcaption>
            </figure>
            NCAA er den største og vigtigste organisation for college golf i USA. NCAA opdeler universiteter i tre divisioner: Division I, Division II og Division III. Hver division har sit eget mesterskab, afholdt årligt i maj eller juni. Mesterskaberne består af en hold- og en individuel turnering, hvor de bedste spillere og hold fra hver division konkurrerer om den nationale titel.
            <br/>
            <br/>
            NCAA har også adskillige regionale konferencer, der består af universiteter, der geografisk eller historisk er forbundet. Hver konference har sit eget mesterskab, der fungerer som en kvalifikationsturnering til det nationale mesterskab. Nogle konferencer er stærkere end andre og har mere indflydelse på college golf-landskabet.
            <br/>
            <br/>
            Nogle af de mest kendte konferencer inkluderer:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    The Atlantic Coast Conference (ACC), der består af 15 universiteter på Østkysten, såsom Duke, North Carolina og Clemson.
                </li>
                <li>
                    The Big Ten Conference, der består af 14 universiteter i Midtvesten, såsom Michigan, Ohio State og Illinois.
                </li>
                <li>
                    The Pac-12 Conference, der består af 12 universiteter på Vestkysten, såsom Stanford, California og Arizona State.
                </li>
                <li>
                    The Southeastern Conference (SEC), der består af 14 universiteter i Sydøst, såsom Alabama, Georgia og Florida.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Ud over NCAA er der også andre organisationer, der arrangerer college golfkonkurrencer, såsom National Association of Intercollegiate Athletics (NAIA), National Junior College Athletic Association (NJCAA) og National Christian College Athletic Association (NCCAA). Disse organisationer har deres egne divisioner, konferencer og mesterskaber, som generelt er mindre og mindre prestigefyldte end dem i NCAA.
        </MKTypography>
    </MKBox>
    <MKBox id="2.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.2 Topprogrammer og Prestigefyldte Hold
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            College golf er ikke kun en sport, men også en uddannelse. Universiteterne, der tilbyder college golf, har forskellige programmer, der varierer i kvalitet, omdømme og faciliteter. Nogle programmer er førende og prestigefyldte og tiltrækker de bedste spillere og trænere. Andre programmer er mindre kendte eller succesrige, men tilbyder stadig gode muligheder for studerende-athleter.
            <br/>
            <br/>
            Nogle af faktorerne, der bestemmer et program, inkluderer:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Programmets historie og tradition.
                </li>
                <li>
                    Holdets niveau og resultater.
                </li>
                <li>
                    Kvaliteten og erfaringen af træneren.
                </li>
                <li>
                    Programmets faciliteter og udstyr.
                </li>
                <li>
                    Programmets akademiske standarder og krav.
                </li>
                <li>
                    Programmets atmosfære og kultur.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Nogle af de mest fremtrædende college golfprogrammer inkluderer:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Stanford University: Stanford har et af de ældste og mest succesrige golfprogrammer i USA med flere nationale titler i herregolf. Programmet har produceret berømte alumner som Tiger Woods og Tom Watson. Stanfords kvindelige golfhold har også opnået national anerkendelse med flere NCAA-titler og individuelle mestre.
                </li>
                <li>
                    Oklahoma State University: Oklahoma State har et dominerende golfprogram, især kendt for sit herrehold med flere nationale titler. Programmet har produceret topgolfspillere som Rickie Fowler og Viktor Hovland.
                </li>
                <li>
                    Duke University: Duke er kendt for sit stærke kvindelige golfprogram med flere NCAA-titler. Dukes herre golfhold har præsteret stærkt i konference mesterskaber og har en respektabel historie i NCAA konkurrencer. Duke har produceret talentfulde golfspillere, herunder Amanda Blumenherst og Brittany Lang.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            I dette kapitel har vi set, hvordan det nuværende landskab af college golf ser ud, og hvilke konkurrencer og programmer der er tilgængelige. I det næste kapitel vil vi se på, hvordan man går fra college til professionelt niveau, og hvilke alternative ruter der er tilgængelige.
        </MKTypography>
    </MKBox>
</MKBox>










                                              






                                  






<MKBox id="Chapter 3" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Kapitel 3: Fra College til Professionelt Niveau
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        College golf er ikke bare en sport, men også en karrierevej. Mange college golfspillere aspirerer til at skifte til den professionelle turné og spille på de højeste niveauer af golf. I dette kapitel vil vi udforske, hvordan college golf kan fungere som et springbræt til professionel golf, og de alternative ruter, der er tilgængelige.
    </MKTypography>

    <MKBox id="3.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.1 Vejen til Den Professionelle Turné
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            College golf er en fremragende forberedelse til en professionel karriere inden for golf. College golfspillere har mulighed for at udvikle sig sportsligt, akademisk og personligt. De lærer at håndtere pres, konkurrence og forventninger. De spiller på udfordrende baner mod stærke modstandere og under forskellige forhold. De har også adgang til faciliteter af høj kvalitet, trænere og vejledning.
            <br/>
            <br/>
            College golfspillere har også en fordel i forhold til andre spillere, der ikke deltager på universitetet. De har en grad, der kan hjælpe dem med at finde et job eller en sponsor, hvis de ikke lykkes som professionelle. De har også et netværk af kontakter og venner, der kan støtte eller rådgive dem i deres karriere.
            <br/>
            <br/>
            Mange college golfspillere skifter til den professionelle turné efter at have afsluttet deres studier. Nogle gør det tidligere, hvis de mener, de er klar. For at blive professionel skal de kvalificere sig til en turné, såsom PGA Tour, LPGA Tour eller European Tour, gennem kvalifikationsturneringer som Q-School eller Korn Ferry Tour.
            <br/>
            <br/>
            Mange tidligere college golfspillere har haft succes på den professionelle turné. Eksempler inkluderer:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Phil Mickelson: Mickelson spillede college golf for Arizona State University, hvor han vandt NCAA-mesterskabet tre gange. Han blev professionel i 1992 og har siden opnået talrige sejre på PGA Tour, herunder flere majors.
                </li>
                <li>
                    Annika Sörenstam: Sörenstam spillede college golf for University of Arizona, hvor hun vandt NCAA-mesterskabet i 1991. Hun blev professionel i 1992 og har siden opnået talrige professionelle sejre, herunder flere majors.
                </li>
                <li>
                    Jon Rahm: Rahm spillede college golf for Arizona State University, hvor han vandt Ben Hogan Award to gange som den bedste college-golfer. Han blev professionel i 2016 og har siden opnået flere professionelle sejre, herunder majors.
                </li>
            </ul>
        </MKTypography>
    </MKBox>
    <MKBox id="3.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.2 Alternative Ruter til Professionalisme
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Mens college golf er en almindelig vej til den professionelle turné, er det ikke den eneste. Der er andre veje, spillere kan følge for at opnå deres drøm. Nogle spillere vælger ikke at gå på universitetet, men at blive professionelle direkte efter gymnasiet. Andre deltager på universitetet, men forlader tidligt for at fokusere fuldt ud på deres sport.
            <br/>
            <br/>
            Eksempler på spillere, der tog en alternativ rute, inkluderer:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Tiger Woods: Woods spillede college golf for Stanford University, hvor han vandt NCAA-mesterskabet to gange. Han blev dog professionel i 1996 efter to års studier. Han har siden opnået talrige sejre på PGA Tour, herunder flere majors.
                </li>
                <li>
                    Lydia Ko: Ko spillede aldrig college golf, men blev professionel i 2013 i en alder af 16 år. Hun har siden opnået flere sejre på LPGA Tour.
                </li>
                <li>
                    Jordan Spieth: Spieth spillede college golf for University of Texas, hvor han vandt NCAA-mesterskabet i 2012. Han blev dog professionel i 2012 efter et års studier. Han har siden opnået flere sejre på PGA Tour, herunder flere majors.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            I dette kapitel har vi set, hvordan man går fra college til den professionelle turné, og hvilke alternative ruter der er tilgængelige. I det næste kapitel vil vi udforske de internationale indflydelser og muligheder i college golf.
        </MKTypography>
    </MKBox>
</MKBox>







                                              






                                  






<MKBox id="Chapter 4" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Kapitel 4: Internationale Indflydelser og Muligheder
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        College golf er ikke kun en amerikansk sport, men også en international en af slagsen. Der er mange udenlandske spillere, der kommer til USA for at studere og spille golf, og der er også mange amerikanske spillere, der spiller i udlandet. I dette kapitel vil vi se på indvirkningen og bidragene fra internationale studerende-atleter samt mulighederne for amerikanske college golfspillere for at spille internationalt.
    </MKTypography>

    <MKBox id="4.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.1 Udenlandske Spillere i College Golf
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image3}
                    alt="Texas Longhorns, med Jordan Spieth, der fejrer det nationale mesterskab"
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal3(Image3)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Texas Longhorns, med Jordan Spieth, der fejrer det nationale mesterskab
                </figcaption>
            </figure>
            College golf er et attraktivt valg for mange udenlandske spillere, der søger en kombination af sport og uddannelse. De kan drage fordel af den høje kvalitet af amerikanske universiteter, de fremragende faciliteter og vejledning fra golfprogrammerne og den stærke konkurrence og eksponering i college golfturneringer.
            <br/>
            <br/>
            Udenlandske spillere bidrager også meget til amerikanske universiteter. De bringer mangfoldighed, kultur og talent til deres hold. De kan også dele deres oplevelser og perspektiver med deres holdkammerater og trænere, hvilket bidrager til deres udvikling.
            <br/>
            <br/>
            Mange udenlandske spillere har haft succes i college golf og har udmærket sig som studerende-atleter. Eksempler inkluderer:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Maria Fassi: Fassi spillede college golf på University of Arkansas, hvor hun vandt ANNIKA-prisen to gange som den bedste kvindelige college-golfer. Hun blev professionel i 2019 og har siden opnået flere professionelle sejre.
                </li>
                <li>
                    Viktor Hovland: Hovland spillede college golf på Oklahoma State University, hvor han vandt NCAA-mesterskabet i 2018. Han blev professionel i 2019 og har siden opnået flere professionelle sejre.
                </li>
            </ul>
        </MKTypography>
    </MKBox>
    <MKBox id="4.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.2 Amerikanske Spillere i Udlandet
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            College golf er ikke den eneste måde at udvikle sig som golfer på. Der er også mange muligheder for amerikanske college golfspillere for at spille internationalt og opleve andre kulturer og baner. Dette kan udvide deres horisonter, øge deres tilpasningsevne og forbedre deres spil.
            <br/>
            <br/>
            Amerikanske college golfspillere kan spille internationalt gennem forskellige programmer, såsom:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Palmer Cup: En årlig turnering mellem hold af amerikanske og internationale college-golfspillere, opkaldt efter Arnold Palmer, en berømt golfspiller, der også spillede college golf. Turneringen afholdes på forskellige steder rundt om i verden, såsom Frankrig, Irland, England og Sverige.
                </li>
                <li>
                    Eisenhower Trophy: En toårig turnering mellem hold af amatør-golfspillere fra forskellige lande, opkaldt efter Dwight D. Eisenhower, en tidligere amerikansk præsident og ivrig golfspiller. Turneringen afholdes på forskellige steder rundt om i verden, såsom Australien, Japan, Mexico og Tyrkiet.
                </li>
                <li>
                    Studieopholdsprogrammet: Et akademisk program, der tilbyder studerende muligheden for at studere i et semester eller et år på et udenlandsk universitet. Nogle universiteter har også golfprogrammer, der samarbejder med udenlandske universiteter, hvilket giver studerende-atleter mulighed for at spille golf under deres ophold.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Mange amerikanske college golfspillere har haft gavn af at spille i udlandet og har løftet deres spil til et højere niveau. Eksempler inkluderer:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Collin Morikawa: Morikawa spillede college golf på University of California, Berkeley, hvor han blev fire gange All-American. Han spillede også i Palmer Cup i 2017 og 2018 samt i Eisenhower Trophy i 2018. Han blev professionel i 2019 og har siden opnået flere professionelle sejre.
                </li>
                <li>
                    Jennifer Kupcho: Kupcho spillede college golf på Wake Forest University, hvor hun vandt NCAA-mesterskabet i 2018. Hun spillede også i Palmer Cup i 2018 og i Curtis Cup i 2016 og 2018. Hun blev professionel i 2019 og har siden opnået flere professionelle sejre.
                </li>
                <li>
                    Patrick Reed: Reed spillede college golf på University of Georgia og Augusta State University, hvor han vandt NCAA-mesterskabet to gange. Han spillede også i Palmer Cup i 2011 og studerede i et semester på University of Stirling i Skotland. Han blev professionel i 2011 og har siden opnået flere professionelle sejre.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            I dette kapitel har vi set, hvordan college golf er en international sport, og indflydelserne og mulighederne for udenlandske og amerikanske spillere. I det næste kapitel vil vi se på vigtigheden af golflegater og rollen hos Sportbeursamerika.nl.
        </MKTypography>
    </MKBox>
</MKBox>













                                              






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Sportslegater og fremtiden for atleter
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sportslegater tilbyder talrige muligheder for unge atleter til at vise deres talent og udvikle deres færdigheder. En af de mest betydningsfulde muligheder er at opnå et sportslegat for at studere og konkurrere på kollegialt niveau. I dette kapitel vil vi diskutere vigtigheden af sportslegater og hvordan Sport Legater Amerika kan hjælpe dig med at realisere din drøm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vigtigheden af legater
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et sportslegat er økonomisk støtte, som et universitet tilbyder til en studerende-atlet for at kombinere studier og sport. Det kan dække omkostningerne til undervisning, bøger, bolig, måltider og andre udgifter. Derudover kan det også tilbyde fordele såsom akademisk vejledning, lægehjælp, træningsfaciliteter og udstyr.
      <br />
      <br />
      At opnå et sportslegat er konkurrencepræget, hvor mange faktorer spiller en rolle i tildelingsprocessen, herunder akademiske præstationer, atletisk præstation, personlighed, motivation, lederskab og tilpasningsevne. Derfor er det vigtigt at forberede sig godt og adskille sig fra konkurrencen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Legater Amerika Kan Hjælpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo af Sport Legater Amerika, der viser sportsudstyr og en eksamenshue, der repræsenterer fokus på sportslegater for studerende-atleter."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo af Sport Legater Amerika
        </figcaption>
      </figure>
      Sport Legater Amerika er en organisation specialiseret i at guide unge sports talenter til sportslegater. Med passionerede og erfarne eksperter støtter Sport Legater Amerika studerende-atleter på hvert trin af rejsen, fra indledende orientering til endelig placering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intake-interview for at diskutere den studerende-athlets ønsker, muligheder og forventninger.</li>
        <li>En professionel evaluering af den studerende-athlets atletiske og akademiske niveau.</li>
        <li>Et omfattende netværk af kontakter med trænere, universiteter og kollegier.</li>
        <li>Effektiv markedsføring af den studerende-athlets profil og videoer til potentielle trænere.</li>
        <li>Ekspertrådgivning om de bedste muligheder baseret på den studerende-athlets præferencer, mål og budget.</li>
        <li>Forhandling af de bedste legat tilbud på vegne af den studerende-atlet.</li>
        <li>Hjælp til ansøgning om nødvendige dokumenter, såsom visum, transkript, eksamensbevis og optagelsesprøver.</li>
        <li>Forberedelse til den studerende-atlets liv under deres legat, herunder kultur, sprog, regler og tips.</li>
        <li>Efterpleje og opfølgning under legatperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ønsker du at udforske legatmuligheder og opnå dine sportslige og akademiske mål? <Link to="/da/Tjenester-Produkter">Se det fulde udvalg</Link> af tjenester tilbudt af Sport Legater Amerika og planlæg en konsultation i dag.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;