/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Produktside: https://www.creative-tim.com/product/material-kit-pro-react
* Ophavsret 2023 Creative Tim (https://www.creative-tim.com)

Kodet af www.creative-tim.com

 =========================================================

* Ovenstående ophavsretsbemærkning og denne tilladelse skal inkluderes i alle kopier eller væsentlige dele af softwaren.
*/

// @mui material komponenter

import React from "react";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React komponenter
import MKBox from "components/MKBox";

// Material Kit 2 PRO React eksempler
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

function StatsOne() {
  return (
    <MKBox component="section" py={8}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard 
            count={100} 
            suffix="+"
            title="Studerende:"
            description="Vellykket vejledt til deres drømmeuniversiteter"  
            onClick={() => console.log('Klikket på 100')}
            />
          </Grid>
          <Grid item xs={12} md={4} >
            <DefaultCounterCard
              count={70}
              title="Universiteter:"
              suffix="+"
              description="Studerende succesfuldt placeret på tværs af Amerika"
              onClick={() => console.log('Klikket på 70')}

            />
          </Grid>
          <Grid item xs={12} md={4}>
          <Link to="/da/Alle-sportsgrene">
              <DefaultCounterCard
                count={10}
                suffix="+"
                title="Sportsgrene:"
                description="Vejleder talenter i forskellige discipliner"
              />
            </Link>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default StatsOne;
