import { useEffect, useRef } from "react";
import { Helmet } from 'react-helmet';

// rellax
import Rellax from "rellax";

// typed-js
import Typed from "typed.js";



// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbarItaliano";
import DetailedFooter from "examples/Footers/DetailedFooter";


// Coworking page sections
import DesignBlocks from "./sections/DesignBlocks"

// Routes
import routes from "routes_Italiano";import footerRoutes from "footer.routes_Italiano";
import Breadcrumbs from "examples/Breadcrumbs";

// Images
import bgImage from "assets/images/Achtergronden/Home_BG.png";

function AllSports() {
  const headerRef = useRef(null);
  const typedJSRef = useRef(null);

  useEffect(() => {
    const newSports = [
      "Atletica",
      "Basket",
      "Baseball",
      "Calcio",
      "Canottaggio",
      "Football Americano",
      "Ginnastica",
      "Golf",
      "Hockey",
      "Hockey su ghiaccio",
      "Lotta",
      "Pallanuoto",
      "Pallavolo",
      "Softball",
      "Tennis",
    ];
  
    const sortedSports = newSports.sort((a, b) => a.localeCompare(b));
  
    const typedJS = new Typed(typedJSRef.current, {
      strings: sortedSports,
      typeSpeed: 90,
      backSpeed: 90,
      backDelay: 200,
      startDelay: 500,
      loop: true,
    });
  
    return () => typedJS.destroy();
  }, []);
  

  return (
    <>
      <Helmet>
        <title>Panoramica degli Sport Universitari negli Stati Uniti - Borse di Studio Sportive America</title>
        <meta name="description" content="Una panoramica completa di tutti gli sport universitari in America. Scopri le opportunità nello sport come il calcio, l'hockey e il tennis per borse di studio sportive negli Stati Uniti." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charset="UTF-8" />
        <meta name="robots" content="index, follow" />
      
        {/* Tag Open Graph */}
        
        
        
        
        
   
        {/* Favicon */}
        <link rel="icon" href="https://borsestudiosportiveamerica.it/32x32BorseStudioSportive.png" />
      </Helmet>
  
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://calendly.com/borsestudiosportive-america/colloquio",
          label: "PRENOTA APPUNTAMENTO",
          color: "info",
        }}
        transparent
        light
      />
      <MKBox
        ref={headerRef}
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Tutte le informazioni di cui hai bisogno su <br /> <span ref={typedJSRef} /> 
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox
          width={{ xs: "80%", md: "50%", lg: "25%" }}
          mb={1}
          sx={{
            backgroundColor: "transparent", // Colore di sfondo trasparente
          }}
        >
          <Breadcrumbs
            routes={[
              { label: "Home", route: "/it" },
              { label: "Tutti gli Sport" },
            ]}
          />
        </MKBox>  
        <DesignBlocks />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DetailedFooter content={footerRoutes} />
      </MKBox>
    </>
  );
  
}

export default AllSports;
