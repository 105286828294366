/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Produktside: https://www.creative-tim.com/product/material-kit-pro-react
* Opphavsrett 2023 Creative Tim (https://www.creative-tim.com)

Kodet av www.creative-tim.com

=========================================================

* Den ovennevnte opphavsrettsmeldingen og denne tillatelsesmeldingen skal inkluderes i alle kopier eller betydelige deler av programvaren.
*/

// @mui materialkomponenter
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React komponenter
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Bilder
import post1 from "assets/images/Jeugd.png";
import post3 from "assets/images/Deal.png";
import "../../../../H1asH3.css";


function Profil() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center"></MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox 
                  display="flex" 
                  flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} 
                  justifyContent="space-between" 
                  alignItems="center" 
                  mb={1}
              >
                  <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Del 2: Min Tid i Amerika</MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text">
  <br />
    Min tid i Amerika var som en pustende reise, fylt med uforglemmelige opplevelser og personlig vekst. 
    Takket være fotball reiste jeg fra kyst til kyst, ledsaget av et gruppe lagkamerater som raskt ble noen av mine beste venner. 
    Våre dager begynte tidlig med intensive treningsøkter i treningsstudioet, noe jeg ikke var vant til i Nederland. 
    Deretter raskt til universitetet for forelesninger, og tilbake til trening om ettermiddagen. Det var en daglig forpliktelse som utfordret meg både fysisk og mentalt.
  <br /><br />
    Kampene var høydepunkter som tusenvis av fans strømmet til, og hver gang føltes det som om vi spilte for noe større enn oss selv. 
    Adrenalinet med å entre banen foran en så lidenskapelig publikum er ubeskrivelig. I tillegg var reisene vi tok for kamper uforglemmelige. 
    På flyet tilbrakte vi ofte timer med å spille kort, og på hotellene delte vi morsomme øyeblikk og spennende samtaler. 
    Restauranter med deilige retter ble midlertidige spisesteder, og disse kulinariske opplevelsene la en ekstra dimensjon til våre reiser. 
    Alt var nøye organisert, fra kortene på flyet til restaurantene der vi spiste. 
    Disse opplevelsene tillot meg å få et glimt av Amerikas storhet.
  <br /><br />
    Dessuten hadde jeg muligheten til å hospitere med ledende profesjonelle klubber, inkludert Seattle Sounders. 
    Å dele banen med spillere som Amerikas tidligere toppscorer Clint Dempsey var intet mindre enn surrealistisk, et høydepunkt jeg alltid vil verdsette. 
    Men som i enhver eventyr, hadde denne reisen sine utfordringer. 
    I mitt siste år møtte jeg en alvorlig hamstring-skade, som midlertidig stoppet mine fotballdrømmer. 
    Dette var en periode med personlig refleksjon og motstand, som jeg kom sterkere ut av.
  <br /><br />
    Videre var min akademiske reise i Amerika like viktig. Til tross for det intensive fotballtreningsskjemaet og omfattende reiser, 
    fikk jeg perfekt veiledning for å fortsette studiene mine. Det var ikke en enkel oppgave, da jeg ofte gikk glipp av forelesninger på grunn av deltakelsen i fotballaget. 
    Likevel var jeg fast bestemt på å lykkes, og det gjorde jeg med stolthet, med utmerkelse, magna cum laude. 
    Denne akademiske suksessen var en bekreftelse på min dedikasjon til både sporten og utdannelsen min.
  <br /><br />
    Under mitt eventyr i Amerika opplevde jeg typiske amerikanske fester som virket som om de kom rett ut fra en film. 
    Disse livlige sosiale sammenkomstene, fylt med musikk, dans og høy latter, ga meg muligheten til å vokse, ikke bare som utøver og student, 
    men også som en sosial entusiast. Disse festene la til en ekstra dimensjon til studentlivet mitt og beriket mine sosiale opplevelser.
  <br /><br />
    Imidlertid betydde Amerika mer for meg enn bare fotball, kamper, sosiale arrangementer og akademiske prestasjoner. 
    Det var en mulighet til å utvide mitt sosiale nettverk og danne livslange vennskap. 
    Takket være disse vennskapene kunne jeg oppdage landet på nye måter. 
    Fra vårferie i Mexico, der vi nøt sol, hav og strand, til feiringen av Thanksgiving med venners familier, 
    som jeg nå kan kalle min amerikanske familie. Deres varme velkomst gjorde meg virkelig til en del av deres liv, 
    og disse opplevelsene har gjort tiden min i Amerika enda rikere.
  <br /><br />
  Denne perioden av livet mitt dypet min kjærlighet både for fotball og utdanning og førte meg til den veien jeg nå følger for å hjelpe andre med å oppnå drømmene sine i Amerika. 
  Følg med, for i Del 3 vil jeg dele hvordan min tilbakekomst til Nederland og grunnleggelsen av selskapet mitt tok livet mitt i en ny retning.
</MKTypography>
              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="Del 1: Oppvekst i Nederland"
                    action={{
                      type: "internal",
                      route: "/no/Om-meg/Vokse-opp",
                      color: "info",
                      label: "les mer",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="Del 3: Tilbake i Nederland"
                    action={{
                      type: "internal",
                      route: "/no/Om-meg/Min-tilbakevending-til-Nederland",           
                      color: "info",
                      label: "les mer",
                    }}
                  />
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profil;
