/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Wrestling1.jpeg";
import Image2 from "assets/images/Sports_Images/Wrestling2.webp";
import Image3 from "assets/images/Sports_Images/Wrestling3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Becas de Lucha en los Estados Unidos: Cómo Estudiar y Luchar en América Puede Hacer Realidad tus Sueños', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capítulo 1: La Historia y Desarrollo de la Lucha en América', isSubheader: false },
    { id: '1.1', title: '1.1 Primeros Años', isSubheader: true },
    { id: '1.2', title: '1.2 Crecimiento y Evolución', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capítulo 2: La Lucha Universitaria Hoy en Día', isSubheader: false },
    { id: '2.1', title: '2.1 Competiciones y Conferencias Importantes', isSubheader: true },
    { id: '2.2', title: '2.2 Programas Principales y Equipos Prestigiosos', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capítulo 3: Desde la Universidad hasta el Nivel Olímpico y las Ligas Profesionales', isSubheader: false },
    { id: '3.1', title: '3.1 El Camino hacia la Cima', isSubheader: true },
    { id: '3.2', title: '3.2 Lucha Profesional y los Juegos Olímpicos', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capítulo 4: Influencias y Competencias Internacionales', isSubheader: false },
    { id: '4.1', title: '4.1 Estilos y Tradiciones de Lucha Extranjera', isSubheader: true },
    { id: '4.2', title: '4.2 Estadounidenses en el Escenario Mundial', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capítulo 5: Becas Deportivas y el Futuro de los Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 La Importancia de las Becas', isSubheader: true },
    { id: '5.2', title: '5.2 Cómo Puede Ayudar Sport Scholarships America', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contenidos
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Ocultar</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>






















          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Becas de Lucha en los Estados Unidos: Cómo Estudiar y Luchar en América Puede Hacer Realidad tus Sueños
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Vista aérea de un estadio lleno en un partido de lucha universitaria."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        El Campeonato Nacional
      </figcaption>
    </figure>
    La lucha es un deporte que se ha practicado durante miles de años en todo el mundo. Es una forma de combate físico en la que dos oponentes intentan dominarse mutuamente a través de agarres, lanzamientos, pines y otras técnicas. La lucha requiere no solo fuerza física, velocidad, resistencia y flexibilidad, sino también agudeza mental, estrategia, disciplina y perseverancia.
    <br />
    <br />
    En los Estados Unidos, la lucha es uno de los deportes más practicados y observados. Tiene una larga e intrigante historia que se remonta a la época colonial. Desde entonces, se ha convertido en un fenómeno nacional con millones de fanáticos y practicantes. La lucha universitaria estadounidense representa el pináculo de este desarrollo. Es el nivel donde los mejores luchadores del país se enfrentan en emocionantes competiciones que a menudo sirven como un trampolín hacia carreras profesionales u olímpicas.
    <br />
    <br />
    La importancia de la lucha universitaria en el desarrollo de los atletas no puede subestimarse. Los luchadores universitarios no solo tienen la oportunidad de mostrar y mejorar sus talentos atléticos, sino también de estudiar en universidades de renombre, donde pueden adquirir valiosas habilidades académicas y conocimientos. Además, pueden beneficiarse de becas que pueden cubrir una parte significativa o la totalidad de sus costos de matrícula.
    <br />
    <br />
    Sin embargo, obtener una beca de lucha no es una tarea fácil. Requiere mucha preparación, dedicación y orientación. Afortunadamente, no tienes que hacerlo solo. Sportbeursamerika.nl es una organización especializada en ayudar a jóvenes talentos de la lucha a realizar su sueño de estudiar y luchar en América. Te ofrezco asesoramiento profesional, apoyo personal y acceso a mi amplia red de contactos en el mundo de la lucha estadounidense.
    ¿Te gustaría saber más sobre cómo puedo ayudarte a obtener una beca de lucha en los Estados Unidos? ¡Sigue leyendo! En este artículo, te contaré más sobre:
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 1: La Historia y Desarrollo de la Lucha en América
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    La lucha es un deporte que se ha practicado desde tiempos antiguos en diversas culturas. Es una de las formas más antiguas de competición organizada, mencionada en la mitología griega y la Biblia. También fue uno de los primeros deportes incluidos en los antiguos Juegos Olímpicos.
    <br />
    <br />
    En los Estados Unidos, la lucha ha tenido su propia historia y evolución estrechamente relacionada con el contexto social y político del país. En este capítulo, proporcionaremos una visión general de las principales fases y eventos en la historia de la lucha estadounidense y cómo contribuyeron a la formación de la lucha universitaria moderna.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Primeros Años
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Los inicios de la lucha en América se remontan a la época colonial cuando los colonos europeos trajeron sus propios estilos y tradiciones de lucha al nuevo continente. Estos estilos se basaban principalmente en la lucha inglesa catch-as-catch-can, que permitía agarres en cualquier parte del cuerpo excepto la cabeza. Era una forma popular de entretenimiento y desafío entre los colonos, quienes a menudo organizaban combates en días festivos o de mercado.
      <br />
      <br />
      Uno de los luchadores más conocidos en América fue George Washington, quien, siendo joven, era conocido por sus habilidades de lucha. Una vez derrotó a siete oponentes en un día durante un combate en Virginia. Otro luchador famoso de este período fue Abraham Lincoln, quien ganó múltiples combates como adolescente en Illinois.
      <br />
      <br />
      El primer equipo de lucha universitaria se formó en 1780 en la Universidad de Yale, pero pasó más de un siglo para que la lucha universitaria despegara realmente. El primer combate intercolegial oficial tuvo lugar en 1889 entre Yale y la Universidad de Columbia, con victoria de Yale por 5-0. En 1892, se celebró el primer campeonato nacional en la ciudad de Nueva York, con la participación de ocho equipos de diferentes estados. El ganador fue la Universidad de Cornell, que ganó los siete combates.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crecimiento y Evolución
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A finales del siglo XIX y principios del siglo XX, la lucha comenzó a expandirse y diversificarse en América. Surgieron varios estilos regionales, como la lucha estilo Iowa, que enfatizaba la velocidad y agilidad, o la lucha estilo Oklahoma, que se centraba en la fuerza y el control. También se introdujeron nuevas reglas para hacer la lucha más segura y justa, como la prohibición de agarres peligrosos o la introducción de límites de tiempo.
      <br />
      <br />
      La lucha universitaria también creció en popularidad y prestigio, con más equipos, competiciones y fanáticos. En 1928, se fundó la Asociación Atlética Universitaria Nacional (NCAA) para regular y promover la lucha universitaria. La NCAA organizó campeonatos nacionales anuales para diferentes divisiones basadas en el tamaño y nivel de las universidades. La división más prestigiosa fue la División I, que comprendía aproximadamente 80 equipos.
      <br />
      <br />
      La lucha universitaria también produjo muchas estrellas y leyendas que lograron éxito nacional e internacional. Algunos ejemplos incluyen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Dan Gable: Uno de los mejores luchadores de todos los tiempos, que dominó tanto como atleta como entrenador. Ganó tres títulos de la NCAA para la Universidad Estatal de Iowa, una medalla de oro en los Juegos Olímpicos de 1972 sin perder un solo punto y 15 títulos de la NCAA como entrenador de la Universidad de Iowa.
        </li>
        <li>
          John Smith: Un seis veces campeón mundial y dos veces campeón olímpico conocido por sus técnicas y tácticas innovadoras. Ganó dos títulos de la NCAA para la Universidad Estatal de Oklahoma y luego se convirtió en el entrenador principal del mismo equipo.
        </li>
        <li>
          Cael Sanderson: El único luchador en ganar cuatro títulos de la NCAA sin perder un solo combate. Tuvo un récord perfecto de 159-0 para la Universidad Estatal de Iowa y también ganó una medalla de oro en los Juegos Olímpicos de 2004. Actualmente es el entrenador principal de la Universidad Estatal de Pensilvania, donde ha ganado ocho títulos de la NCAA.
        </li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estos son solo algunos ejemplos del crecimiento y la evolución de la lucha en América, que han llevado a la actual escena de la lucha universitaria, considerada uno de los deportes más competitivos y emocionantes del país.
    </MKTypography>
  </MKBox>
</MKBox>





                                  








<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 2: La Lucha Universitaria Hoy en Día
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    La lucha universitaria es uno de los deportes más populares y prestigiosos en América hoy en día. Atrae a millones de fanáticos y espectadores que siguen los emocionantes encuentros a través de la televisión, internet o en vivo en los estadios. La lucha universitaria también ofrece numerosas oportunidades para estudiantes-atletas que pueden beneficiarse de becas, apoyo académico y orientación profesional.
    <br />
    <br />
    En este capítulo, te contaremos más sobre las principales competiciones y conferencias en la lucha universitaria y los programas principales y equipos prestigiosos que dominan el deporte.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Competiciones y Conferencias Importantes
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Dos luchadores universitarios en un intenso duelo en el tapete."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Dos luchadores en acción
        </figcaption>
      </figure>
      La lucha universitaria está organizada por la NCAA, que tiene tres divisiones: División I, División II y División III. Cada división tiene sus propias reglas, criterios y campeonatos. La división más competitiva y conocida es la División I, que consta de aproximadamente 80 equipos de diferentes estados.
      <br />
      <br />
      Los equipos en la División I están divididos en varias conferencias, formando grupos regionales o temáticos. Las conferencias organizan sus propias competiciones y campeonatos, que sirven como clasificatorios para el campeonato nacional. Las conferencias más destacadas en la lucha universitaria incluyen:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Conferencia Big Ten: La conferencia más grande y fuerte en la lucha universitaria, compuesta por 14 equipos de las regiones del Medio Oeste y Noreste del país. La Big Ten ha producido la mayoría de los campeones nacionales, incluyendo a Penn State, Iowa, Ohio State, Michigan y Minnesota.
        </li>
        <li>
          Conferencia Big 12: La segunda conferencia más grande y fuerte en la lucha universitaria, compuesta por 12 equipos de las regiones Sur y Oeste del país. La Big 12 también ha producido muchos campeones nacionales, incluyendo a Oklahoma State, Oklahoma, Iowa State y Missouri.
        </li>
        <li>
          Conferencia de la Costa Atlántica (ACC): Una conferencia emergente en la lucha universitaria, compuesta por seis equipos de la región Sureste del país. La ACC ha ganado más atención recientemente debido a las actuaciones de equipos como Virginia Tech, North Carolina State y North Carolina.
        </li>
        <li>
          Asociación Intercolegial de Lucha del Este (EIWA): Una conferencia histórica en la lucha universitaria, compuesta por 16 equipos de la región Este del país. La EIWA es la conferencia de lucha más antigua en América, fundada en 1905. La EIWA también ha producido algunos campeones nacionales, incluyendo a Cornell, Lehigh, Princeton y Navy.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estas son solo algunas de las muchas conferencias en la lucha universitaria, cada una con sus propias rivalidades, tradiciones y fanáticos. Sin embargo, las conferencias no son el único factor que determina qué tan exitoso es un equipo. Hay otros aspectos que juegan un papel en el éxito de un programa de lucha.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programas Principales y Equipos Prestigiosos
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Un programa de lucha es un término utilizado para referirse a la organización y estructura de un equipo de lucha universitaria de una universidad. Un programa de lucha incluye aspectos como el entrenador, personal, instalaciones, reclutamiento, entrenamiento, financiamiento y la cultura del equipo.
      <br />
      <br />
      Un buen programa de lucha puede marcar una diferencia significativa en el desarrollo de los estudiantes-atletas. Un buen programa de lucha puede proporcionar a los estudiantes-atletas:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Un entrenador experimentado y calificado que pueda enseñarles cómo mejorar su técnica, tácticas, condición física y mentalidad.
        </li>
        <li>
          Un personal dedicado y profesional que pueda apoyarlos en áreas como nutrición, prevención de lesiones, recuperación, orientación académica y planificación de carrera.
        </li>
        <li>
          Instalaciones modernas y bien equipadas donde puedan entrenar a un nivel alto con equipo y materiales adecuados.
        </li>
        <li>
          Una estrategia de reclutamiento efectiva y ética que pueda ayudarlos a atraer y retener luchadores talentosos y motivados.
        </li>
        <li>
          Un horario de entrenamiento intensivo y variado que pueda desafiarlos y prepararlos para diferentes encuentros y situaciones.
        </li>
        <li>
          Financiamiento adecuado y estable que pueda ayudarlos a cubrir los costos de la lucha, como viajes, equipo, becas y otros gastos.
        </li>
        <li>
          Una cultura positiva e inspiradora que pueda motivarlos y alentarlos a alcanzar su potencial, perseguir sus metas y compartir su pasión por el deporte.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Hay muchas universidades en América que tienen excelentes programas de lucha que han tenido éxito en la lucha universitaria durante años. Algunos de estos programas incluyen:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Penn State: El equipo líder actual en la lucha universitaria, que ha ganado el campeonato nacional ocho veces desde 2011. Penn State es conocido por su estilo agresivo y espectacular de lucha, liderado por el entrenador Cael Sanderson, uno de los mejores luchadores de todos los tiempos.
        </li>
        <li>
          Iowa: El equipo más exitoso en la historia de la lucha universitaria, habiendo ganado el campeonato nacional más de 20 veces desde 1975. Iowa es conocido por su estilo agresivo y dominante de lucha, liderado por el entrenador Tom Brands, un ex campeón olímpico.
        </li>
        <li>
          Oklahoma State: El segundo equipo más exitoso en la historia de la lucha universitaria, habiendo ganado el campeonato nacional más de 20 veces. Oklahoma State es conocido por su estilo técnico y táctico de lucha, liderado por el entrenador John Smith, uno de los mejores luchadores de todos los tiempos.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estos son solo algunos de los muchos programas principales y equipos prestigiosos en la lucha universitaria, cada uno con sus propias características, logros y fanáticos. Sin embargo, no es fácil ser parte de estos programas y equipos. Se requiere mucho talento, trabajo duro y dedicación para asegurar una beca de lucha.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  








<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 3: Desde la Universidad hasta el Nivel Olímpico y las Ligas Profesionales
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    La lucha universitaria no es el punto final para muchos estudiantes-atletas que sueñan con una carrera en el deporte. Más bien, es un trampolín hacia niveles superiores donde pueden competir con los mejores luchadores del mundo. En este capítulo, te contaremos más sobre el camino hacia la cima en la lucha profesional o olímpica y los éxitos y desafíos de los luchadores estadounidenses en el escenario mundial.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 El Camino hacia la Cima
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La lucha universitaria es uno de los deportes más competitivos y exigentes en América. Requiere mucho talento, trabajo duro y dedicación para asegurar una beca de lucha, y aún más para brillar en competiciones nacionales. Pero incluso si eres uno de los mejores luchadores universitarios, no es una garantía de éxito a nivel profesional o olímpico.
      <br />
      <br />
      Hay varios factores que hacen que sea desafiante la transición de la lucha universitaria a la lucha profesional o olímpica. Algunos de estos factores incluyen:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Diferencias de Estilo: La lucha universitaria se practica según las reglas de folkstyle, únicas en América. Estas reglas se centran en controlar y dominar al oponente, con puntos otorgados por montajes, escapes, reversals y near falls. La lucha profesional o olímpica, por otro lado, se practica según las reglas de freestyle o Greco-Romano, utilizadas internacionalmente. Estas reglas enfatizan la puntuación de puntos a través de lanzamientos, inclinaciones, exposiciones y pines. Esto significa que los luchadores universitarios necesitan adaptar su técnica, tácticas y estrategia para competir con luchadores de otros países que tienen más experiencia con estos estilos.
        </li>
        <li>
          Diferencias de Edad: La lucha universitaria es un deporte para atletas jóvenes, generalmente entre 18 y 23 años. La lucha profesional o olímpica, sin embargo, es un deporte para atletas adultos, generalmente entre 25 y 35 años. Esto significa que los luchadores universitarios tienen que competir con luchadores más viejos y con más experiencia que tienen una mayor fuerza física, madurez mental y refinamiento técnico.
        </li>
        <li>
          Diferencias Financieras: La lucha universitaria es un deporte apoyado por universidades, que proporcionan a los estudiantes-atletas becas, instalaciones, entrenadores y otros beneficios. La lucha profesional o olímpica, sin embargo, recibe poco apoyo financiero del gobierno o los patrocinadores. Esto significa que los luchadores universitarios necesitan buscar otras fuentes de ingresos para cubrir sus gastos de entrenamiento, viajes, equipo y gastos de vida.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estos son solo algunos de los muchos desafíos que los luchadores universitarios deben superar para perseguir su sueño de una carrera profesional o olímpica. Sin embargo, a pesar de estos desafíos, también hay muchos éxitos e historias inspiradoras de luchadores estadounidenses que han llegado al más alto nivel.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Lucha Profesional y los Juegos Olímpicos
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La lucha profesional o la lucha olímpica es la cima del deporte, donde los mejores luchadores del mundo compiten en competiciones y eventos prestigiosos. Hay dos formas principales de lucha profesional o olímpica: freestyle y Greco-Romano.
      <br />
      <br />
      La lucha libre es una forma de lucha donde se permiten todo tipo de agarres en todo el cuerpo, siempre y cuando no sean peligrosos o inapropiados. La lucha libre es practicada tanto por hombres como por mujeres, en diversas categorías de peso. La lucha libre es uno de los deportes más antiguos en los Juegos Olímpicos, celebrado desde 1904 para hombres y desde 2004 para mujeres.
      <br />
      <br />
      La lucha grecorromana es una forma de lucha donde solo se permiten agarres por encima de la cintura, y se prohíbe el uso de las piernas para agarrar o lanzar al oponente. La lucha grecorromana es practicada solo por hombres, en diversas categorías de peso. La lucha grecorromana es la forma más antigua de lucha en los Juegos Olímpicos, celebrada desde 1896.
      <br />
      <br />
      Los luchadores estadounidenses han logrado muchos éxitos tanto en la lucha libre como en la lucha grecorromana, en los Juegos Olímpicos, así como en otras competiciones internacionales como los Campeonatos Mundiales, los Juegos Panamericanos o la Copa del Mundo. Algunos de los luchadores estadounidenses más exitosos y conocidos incluyen:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Jordan Burroughs: Cinco veces campeón mundial y campeón olímpico en lucha libre, conocido por su estilo explosivo y dinámico. Ganó una medalla de oro en los Juegos Olímpicos de 2012 en Londres y cuatro títulos mundiales en 2011, 2013, 2015 y 2017. También ganó dos títulos de la NCAA para la Universidad de Nebraska.
        </li>
        <li>
          Kyle Snyder: Tres veces campeón mundial y campeón olímpico en lucha libre, conocido por su fuerza y técnica. Ganó una medalla de oro en los Juegos Olímpicos de 2016 en Río de Janeiro y tres títulos mundiales en 2015, 2017 y 2019. También ganó tres títulos de la NCAA para la Universidad Estatal de Ohio.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estos son solo algunos de los muchos éxitos e historias inspiradoras de luchadores estadounidenses a nivel profesional u olímpico. Demuestran que es posible alcanzar tu sueño si estás dispuesto a trabajar duro, aprender y crecer.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  






<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 4: Influencias y Competencias Internacionales
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    La lucha es un deporte universal, practicado y apreciado en muchos países y culturas. La lucha también tiene muchos estilos y tradiciones que han surgido y desarrollado en diferentes regiones y períodos. En este capítulo, te contaremos más sobre las influencias y competencias internacionales en la lucha y cómo influyen y desafían a los luchadores estadounidenses.
  </MKTypography>
  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Estilos y Tradiciones de Lucha Extranjera
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Vista aérea de un partido de lucha universitaria de la NCAA en un estadio lleno."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Un partido en el torneo de la NCAA
        </figcaption>
      </figure>
      La lucha es un deporte que se ha practicado desde tiempos antiguos en varias partes del mundo. Es una de las formas más antiguas de expresión física, a menudo asociada con significados religiosos, culturales o sociales. Hay muchos tipos de lucha, cada uno con sus propias características, reglas y técnicas. Algunos ejemplos incluyen:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Sumo: Un estilo tradicional de lucha japonés considerado un deporte nacional y un ritual sagrado. El sumo es practicado por luchadores pesados y fuertes que intentan empujar al otro fuera de un anillo circular o tirarlo al suelo.
        </li>
        <li>
          Lucha mongola: Un estilo tradicional de lucha mongol considerado un símbolo de coraje y fuerza. La lucha mongola es practicada por luchadores musculosos y ágiles que intentan derribarse mutuamente a través de agarres en la parte superior del cuerpo o las piernas.
        </li>
        <li>
          Lucha turca de aceite: Un estilo tradicional de lucha turca considerado un patrimonio y un arte. La lucha turca de aceite es practicada por luchadores aceitados y musculosos que intentan derrotarse mutuamente a través de agarres en los pantalones o el cinturón.
        </li>
        <li>
          Lucha senegalesa: Un estilo tradicional de lucha senegalés considerado un deporte popular y lucrativo. La lucha senegalesa es practicada por luchadores atléticos y coloridos que intentan derribarse mutuamente a través de lanzamientos, golpes o patadas.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estos son solo algunos de los muchos estilos y tradiciones de lucha extranjera, cada uno con su propia historia, cultura y seguidores. Estos estilos también han influido en la lucha universitaria estadounidense al introducir nuevas técnicas, tácticas o desafíos. Muchos luchadores universitarios estadounidenses han viajado o entrenado en otros países para ampliar sus horizontes y mejorar sus habilidades.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Estadounidenses en el Escenario Mundial
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La lucha universitaria estadounidense no solo se centra en competiciones nacionales, sino también en competiciones internacionales. Hay muchos eventos internacionales donde los luchadores universitarios estadounidenses pueden participar para competir con los mejores luchadores del mundo. Algunos de estos eventos incluyen:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Los Juegos Olímpicos: El evento más grande y prestigioso en la lucha profesional o olímpica, celebrado cada cuatro años. Los Juegos Olímpicos son el objetivo final para muchos luchadores universitarios que sueñan con ganar una medalla de oro para su país.
        </li>
        <li>
          Los Campeonatos Mundiales: El segundo evento más grande y prestigioso en la lucha profesional o olímpica, celebrado anualmente. Los Campeonatos Mundiales son una oportunidad significativa para los luchadores universitarios de clasificarse para los Juegos Olímpicos o establecer su reputación en el escenario mundial.
        </li>
        <li>
          La Copa del Mundo: El segundo evento más grande y prestigioso en la lucha regional, celebrado anualmente. La Copa del Mundo es una oportunidad significativa para los luchadores universitarios de formar parte de un equipo nacional que compite contra otros equipos nacionales en un formato de equipo.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estos son solo algunos de los muchos eventos internacionales donde los luchadores universitarios estadounidenses pueden participar para mostrar sus talentos y perseguir sus sueños. Sin embargo, estos eventos no solo son una fuente de honor y gloria, sino también una fuente de desafío y dificultad. Los luchadores universitarios deben competir con luchadores de otros países que a menudo tienen más experiencia, estilo o motivación.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  



<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Becas Deportivas y el Futuro de los Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Las becas deportivas ofrecen numerosas oportunidades para que los jóvenes atletas muestren su talento y desarrollen sus habilidades. Una de las oportunidades más significativas es obtener una beca deportiva para estudiar y competir a nivel universitario. En este capítulo, discutiremos la importancia de las becas deportivas y cómo Sport Scholarships America puede ayudarte a hacer realidad tu sueño.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 La Importancia de las Becas
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Una beca deportiva es un apoyo financiero proporcionado por una universidad a un estudiante-atleta para combinar estudios y deporte. Puede cubrir los costos de matrícula, libros, alojamiento, comidas y otros gastos. Además, puede ofrecer beneficios como tutoría académica, atención médica, instalaciones de entrenamiento y equipo.
      <br />
      <br />
      Obtener una beca deportiva es competitivo, con muchos factores que influyen en el proceso de adjudicación, incluyendo logros académicos, rendimiento deportivo, personalidad, motivación, liderazgo y adaptabilidad. Por lo tanto, es importante prepararse bien y destacarse entre la competencia.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Cómo Puede Ayudar Sport Scholarships America
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo de Sport Scholarships America, que muestra equipamiento deportivo y una gorra de graduación, representando el enfoque en becas deportivas para estudiantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America es una organización especializada en guiar a jóvenes talentos deportivos hacia becas deportivas. Con expertos apasionados y experimentados, Sport Scholarships America apoya a los estudiantes-atletas en cada paso del camino, desde la orientación inicial hasta la colocación final. Sus servicios incluyen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Una entrevista personal para discutir los deseos, posibilidades y expectativas del estudiante-atleta.</li>
        <li>Una evaluación profesional del nivel académico y deportivo del estudiante-atleta.</li>
        <li>Una extensa red de contactos con entrenadores, universidades y colegios.</li>
        <li>Promoción efectiva del perfil y videos del estudiante-atleta ante entrenadores potenciales.</li>
        <li>Asesoramiento experto sobre las mejores opciones basadas en las preferencias, metas y presupuesto del estudiante-atleta.</li>
        <li>Negociación de las mejores ofertas de becas en nombre del estudiante-atleta.</li>
        <li>Asistencia en la solicitud de documentos necesarios, como visa, expediente académico, diploma y pruebas de admisión.</li>
        <li>Preparación para la vida del estudiante-atleta durante su beca, incluyendo cultura, idioma, reglas y consejos.</li>
        <li>Seguimiento y atención posterior durante el período de la beca.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      ¿Quieres explorar oportunidades de becas y alcanzar tus metas deportivas y académicas? <Link to="/es/Servicios-Productos">Consulta la gama completa</Link> de servicios ofrecidos por Sport Scholarships America y programa una consulta hoy mismo.
    </MKTypography>
  </MKBox>
</MKBox>



         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;