/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


// @mui material components
import React, { useState } from 'react'; // Step 1: Import useState


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Logos/NJCAA.png";
import post3 from "assets/images/Logos/NAIA.png";



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {

  const articleStructure = [
    { id: 'intro', title: 'NCAA: Hjärtat av College-sport i Amerika', isSubheader: false },
  
    { id: 'Kapitel 1', title: 'Ursprunget och Tillväxten av NCAA', isSubheader: true },
    { id: '1.1', title: 'NCAA:s Tre Ansikten: Division I, II och III', isSubheader: true },
    { id: '1.2', title: 'Akademikers Roll', isSubheader: true },
  
    { id: 'Kapitel 2', title: 'Finanser, Media och Marknadsföring', isSubheader: true },
    { id: '2.1', title: 'Utmaningar och Framtid', isSubheader: true },
    { id: '2.2', title: 'Avslutning', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
     <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                       Innehåll
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}> Dölj</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Visa</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="intro" mb={3}>


              
<MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>NCAA: Hjärtat av College-sport i Amerika</MKTypography>
</MKBox>
<MKBox id="Kapitel 1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Ursprunget och Tillväxten av NCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
National Collegiate Athletic Association, mer känd som NCAA, har varit navet i den amerikanska högskolesporten i över ett sekel. Grundad 1906 har denna organisation utvecklats från en liten reglerande enhet till en kraftfull och inflytelserik organisation som påverkar livet för tusentals studentidrottare, tränare och sportentusiaster.
<br />
<br />
I NCAA:s tidiga år låg fokus främst på att fastställa regler och säkerställa säkerheten inom idrotten. Men med tiden utvidgades NCAA:s roll och blev allt mer involverad i att reglera varje aspekt av högskolesporten, från akademiska krav till ekonomiska frågor.
</MKTypography>

<MKBox id="1.1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
NCAA:s Tre Ansikten: Division I, II och III
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
NCAA är uppdelad i tre divisioner, var och en med sin egen unika karaktär och tävlingsnivå:
<br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division I:{' '}
</MKTypography>
är känt som höjdpunkten av collegespel, där skolor med störst budgetar, bästa faciliteter och mest talangfulla idrottare samlas. Universiteten i denna division är ofta stora och har betydande resurser till sitt förfogande. De erbjuder fulla och partiella idrottsstipendier och lockar toppidrottare från hela landet och världen. Tävlingen är intensiv och pressen att prestera är hög. Ändå är det också en scen där stjärnor föds och där drömmar för unga idrottare kan bli verklighet. Divisionen är uppdelad i tre kategorier: Football Bowl Subdivision (FBS), Football Championship Subdivision (FCS) och skolor utan fotboll på Division I-nivå.  <br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division II:{' '}
</MKTypography>
representerar ett mer balanserat tillvägagångssätt för collegespel, där idrott, akademisk prestation och campusengagemang går hand i hand. Universiteten i denna division är ofta mindre än de i Division I, men erbjuder fortfarande en hög nivå av konkurrens. Idrottare i Division II kan förvänta sig stöd både på fältet och i klassrummet, med målet att erbjuda en komplett collegupplevelse.  <br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division III:{' '}
</MKTypography>
betonar slutligen den övergripande utvecklingen av studentidrottaren. Sport är viktigt, men det är bara en del av den bredare collegupplevelsen. Inga idrottsstipendier erbjuds i Division III, men idrottare har möjlighet att delta i tävlingsidrotter samtidigt som de fullt ut integreras i akademiskt och socialt liv på campus.</MKTypography>

<MKBox id="1.2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Akademikers Roll
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
NCAA tar sina studentidrottarnas akademiska prestation mycket på allvar. Alla idrottare måste uppfylla strikta akademiska krav för att vara berättigade till tävling, och universiteten är själva ansvariga för att tillhandahålla det nödvändiga stödet för att säkerställa att idrottarna lyckas i klassrummet. Detta inkluderar akademiska rådgivare, handledare och specialstudieprogram, allt designat för att hjälpa studentidrottarna att balansera sina idrottsliga och akademiska åtaganden.                <br />
<br />
</MKTypography>





                                  







<MKBox id="Kapitel 2" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Finanser, Media och Marknadsföring
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              NCAA är också en finansiell maktfaktor, med miljarder dollar som strömmar genom organisationen årligen tack vare TV-kontrakt, sponsorer och merchandise. Dessa intäkter används för att finansiera stipendier, förbättra faciliteter och förbättra den övergripande atletiska upplevelsen för studentidrottare. Samtidigt har populariteten för collegeidrott lett till lukrativa marknadsförings- och medieavtal, vilket gör collegiat idrott till en grundläggande del av amerikansk idrottskultur.

<br />
                </MKTypography>

                <MKBox id="2.1" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Utmaningar och Framtid
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              Trots sin framgång står NCAA också inför betydande utmaningar. Frågor som studentidrottarnas amatörstatus, idrottares mentala hälsa och strävan efter jämlikhet inom idrotten är bara några av ämnena som organisationen måste ta itu med i sin strävan efter en bättre framtid för collegiat idrott.
<br />
<br />
NCAA förblir en avgörande aktör inom det amerikanska utbildningssystemet, och dess påverkan på unga idrottare och den bredare idrottsvärlden är obestridlig. När den ser framåt mot framtiden är det klart att organisationen kommer att fortsätta spela en nyckelroll i formandet av collegiat idrottslandskapet i Amerika.


                </MKTypography>

                <MKBox id="2.2" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Avslutning
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              National Collegiate Athletic Association är mer än bara en idrottsorganisation; det är en avgörande del av den amerikanska utbildnings- och idrottslandskapet. Med sin rika historia, komplexa struktur och djupa inverkan på livet för otaliga individer förblir NCAA en fascinerande och vital del av amerikansk kultur. Dess framtid kommer utan tvekan att vara fylld med utmaningar och möjligheter, men en sak är säker: NCAA kommer att fortsätta sträva efter excellens, både på planen och i klassrummet.




<br />


                </MKTypography>



              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="NJCAA"
                    description="NJCAA: Stegen till Stora Universitet och NCAA"
                    action={{
                      type: "internal",
                      route: "/sv/NJCAA",
                      color: "info",
                      label: "Läs Mer",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="NAIA"
                    description="NAIA: Ett Alternativt Tillvägagångssätt inom Collegesport i Amerika"
                    action={{
                      type: "internal",
                      route: "/sv/NAIA",
                      color: "info",
                      label: "Läs Mer",
                    }}
                    />
                  </Grid>
                </Grid>
  
              </Grid>
          </Grid>
        </Container>
      </MKBox>
    );
  }
  
  export default Beschrijving;