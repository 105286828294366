/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Basketbal1.jpeg";
import Image2 from "assets/images/Sports_Images/Basketbal2.webp";
import Image3 from "assets/images/Sports_Images/Basketbal3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import { Link } from 'react-router-dom';

import "../../../../H1asH3.css"

// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'USA Basketball Scholarship: How to Study and Play Basketball in America', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Chapter 1: History and Development of College Basketball', isSubheader: false },
    { id: '1.1', title: '1.1 Early Years', isSubheader: true },
    { id: '1.2', title: '1.2 Growth and Professionalization', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Chapter 2: College Basketball Today', isSubheader: false },
    { id: '2.1', title: '2.1 Important Competitions and Conferences', isSubheader: true },
    { id: '2.2', title: '2.2 Top Programs and Prestigious Teams', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Chapter 3: From College to the NBA', isSubheader: false },
    { id: '3.1', title: '3.1 The Path to the NBA', isSubheader: true },
    { id: '3.2', title: '3.2 Other Professional Opportunities', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Chapter 4: International Influences and Opportunities', isSubheader: false },
    { id: '4.1', title: '4.1 Foreign Players in College Basketball', isSubheader: true },
    { id: '4.2', title: '4.2 American Players Abroad', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Chapter 5: Sports Scholarships and the Future of American Football', isSubheader: false },
    { id: '5.1', title: '5.1 The Importance of Scholarships', isSubheader: true },
    { id: '5.2', title: '5.2 How Sport Scholarships America Can Help', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };





  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Content
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Hide</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Show</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    USA Basketball Scholarship: How to Study and Play Basketball in America
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Vibrant stadium of the University of Arizona during a college basketball game"
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Game day at the University of Arizona Stadium
      </figcaption>
    </figure>
    Basketball is one of the most popular sports in the United States. Millions of people follow the games of the NBA, the professional basketball league, and the NCAA, the college basketball league. Basketball is not only an exciting game to watch but also a great way to develop as a player and as a person.
    <br/>
    <br/>
    If you are a young basketball player dreaming of a career in the NBA, or simply want to enjoy playing basketball at a high level, then a basketball scholarship in America might be for you. A basketball scholarship is financial support you receive from an American university to study and play basketball there. With a basketball scholarship, you can not only obtain your academic degree but also improve your basketball talents under professional guidance.
    <br/>
    <br/>
    But how do you get a basketball scholarship in America? What are the benefits and challenges of playing college basketball? And what are the possibilities after your college career? In this article, we will answer all these questions and tell you everything you need to know about basketball scholarships in America. We will also discuss the role of Sportbeursamerika.nl, an organization specialized in helping young players realize their dream of a basketball scholarship.
    <br/>
    <br/>
    Want to know more about basketball scholarships in America? Read on!
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 1: History and Development of College Basketball
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Basketball is a relatively young sport, invented in 1891 by James Naismith, a Canadian physical education teacher. He conceived the game as an indoor activity for his students during the winter months. The game quickly became popular and spread across various schools and universities in North America.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Early Years
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      The first official college-level basketball game took place in 1895 between the University of Chicago and the University of Iowa. In 1901, the first intercollegiate conference was established, the Western Conference, later known as the Big Ten Conference. In 1906, the first national championship was organized by the Amateur Athletic Union (AAU), which dominated college basketball until 1936.
      <br/>
      <br/>
      In 1939, the first NCAA tournament was held, with eight teams competing for the title. The tournament was won by Oregon, defeating Ohio State 46-33. The tournament grew into an annual event that increasingly attracted the attention of fans and media.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Growth and Professionalization
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      After World War II, college basketball increased in popularity and quality. New stars emerged, such as Bill Russell, Wilt Chamberlain, Oscar Robertson, and Kareem Abdul-Jabbar, who later also made a name for themselves in the NBA. New competitions and conferences were formed, such as the National Invitation Tournament (NIT), the Atlantic Coast Conference (ACC), and the Southeastern Conference (SEC).
      <br/>
      <br/>
      In 1957, one of the most memorable games in college basketball history took place when North Carolina Tar Heels defeated Kansas Jayhawks 54-53 after three overtimes. The game is considered a turning point in college basketball, showcasing how exciting and dramatic the game could be.
      <br/>
      <br/>
      Another historic game occurred in 1966 when Texas Western Miners defeated Kentucky Wildcats 72-65. The game was groundbreaking because Texas Western was the first team to consist entirely of black players, while Kentucky was entirely white. The game is seen as a symbol of the civil rights struggle and integration in America.
      <br/>
      <br/>
      In 1979, the first live broadcast NCAA tournament took place, with a record number of viewers. The final was between Michigan State Spartans and Indiana State Sycamores, led by future NBA legends Magic Johnson and Larry Bird, respectively. Michigan State won the game 75-64 and is considered one of the best finals ever.
      <br/>
      <br/>
      In the 80s and 90s, college basketball continued to grow and evolve, with new rules, styles, and strategies. New rivalries emerged, such as Duke vs North Carolina, Georgetown vs Syracuse, and UCLA vs Arizona. There were also new stars, like Michael Jordan, Patrick Ewing, Shaquille O’Neal, and Tim Duncan, who elevated college basketball to a higher level.
    </MKTypography>
  </MKBox>
</MKBox>













                                              





                                  








<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 2: College Basketball Today
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College basketball is one of the most popular and prestigious sports in America today. Every year, the games attract millions of fans to stadiums and television screens. College basketball is also a major source of talent for the NBA, recruiting hundreds of players each year from university teams.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Important Competitions and Conferences
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Steph Curry in action for Davidson College, excelling in college basketball."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Stephen Curry in action as a player for Davidson College
        </figcaption>
      </figure>
      College basketball is primarily organized by the National Collegiate Athletic Association (NCAA), which oversees more than 1,000 universities and colleges. The NCAA divides teams into three divisions, with Division I being the highest. Division I consists of 32 conferences, each comprising teams that are geographically or historically connected.
      <br/>
      <br/>
      The conferences are important as they determine which teams play against each other during the regular season, running from November to March. Each conference has its own schedule, rules, and championship. Conference champions automatically earn a spot in the NCAA tournament, the pinnacle of college basketball.
      <br/>
      <br/>
      The NCAA tournament, also known as March Madness, is a knockout tournament held every year in March and April. It consists of 68 teams, 32 of which are conference champions, and 36 are at-large bids, selected by a committee based on their performance during the season. The teams are divided into four regions and ranked from 1 to 16.
      <br/>
      <br/>
      The tournament begins with four play-in games, where the lowest-ranked teams compete for the last four spots in the main tournament. The main tournament consists of six rounds, eliminating one team in each round until one remains. The final four teams, known as the Final Four, play at a neutral location to determine the national champion.
      <br/>
      <br/>
      The NCAA tournament is one of the most exciting and unpredictable sporting events in the world. Each year there are surprises, dramas, and sensations, known as upsets, buzzer-beaters, and Cinderella stories. The tournament attracts millions of viewers and bettors, who try to predict the winner.
      <br/>
      <br/>
      Besides the NCAA tournament, there are other significant competitions in college basketball, such as the NIT, CBI, CIT, and NAIA tournament. These competitions offer opportunities for teams that are not qualified for the NCAA tournament or want to gain additional experience.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Top Programs and Prestigious Teams
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      College basketball features many top programs and prestigious teams, distinguished by their history, tradition, success, and influence. Some of these programs are:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          UCLA Bruins: The most successful program in college basketball history, with 11 national titles, including 10 under coach John Wooden in the 60s and 70s. UCLA has produced many famous players, such as Kareem Abdul-Jabbar, Bill Walton, and Reggie Miller.
        </li>
        <li>
          Kentucky Wildcats: The second most successful program in college basketball history, with eight national titles and 17 Final Four appearances. Kentucky is known for its fervent fans and its ability to attract top recruits.
        </li>
        <li>
          North Carolina Tar Heels: The third most successful program in college basketball history, with six national titles and 20 Final Four appearances. North Carolina has had many legendary coaches, such as Dean Smith and Roy Williams, and players like Michael Jordan, James Worthy, and Vince Carter.
        </li>
        <li>
          Duke Blue Devils: One of the most dominant programs in modern college basketball, with five national titles and 16 Final Four appearances since 1986. Duke is known for its coach Mike Krzyzewski, the winningest coach in college basketball history, and players like Christian Laettner, Grant Hill, and Zion Williamson.
        </li>
        <li>
          Kansas Jayhawks: One of the most traditional and influential programs in college basketball, with three national titles and 15 Final Four appearances. Kansas is the birthplace of the game, as James Naismith was its first coach. Kansas has also had many great coaches, like Phog Allen, Larry Brown, and Bill Self, and players, such as Wilt Chamberlain, Danny Manning, and Paul Pierce.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      These programs are just a few examples of the many excellent teams that enrich college basketball. Every year new challengers and surprises make the game exciting and unpredictable.
    </MKTypography>
  </MKBox>
</MKBox>












                                              






                                  








<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 3: From College to the NBA
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College basketball is not only a sport but also an education. Many players participating in college basketball aim to reach the NBA, the best and most prestigious professional basketball league in the world. However, the NBA is not easy to reach and requires a lot of talent, hard work, and luck.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 The Path to the NBA
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      To be eligible for the NBA, a player must be at least 19 years old and have graduated from high school at least one year prior. This means that most players aspiring to reach the NBA first go to college and play for at least one year. This is also known as the one-and-done rule because many players enter the NBA draft after just one year of college.
      <br />
      <br />
      The NBA draft is an annual event where the 30 NBA teams take turns selecting a player from a pool of available players. The draft consists of two rounds, with each team having one pick per round. The order of picks is determined through a lottery for teams that did not make the playoffs the previous season and by reverse ranking for the playoff teams.
      <br />
      <br />
      The draft is a significant moment for players because it determines which team holds their rights and how much money they can earn. Players chosen in the first round receive a guaranteed contract for at least two years, while those selected in the second round have no guarantees. Players who are not chosen can try to secure a contract through free agency or by joining the G League, the NBA's development league.
      <br />
      <br />
      However, the draft is not a guarantee of success in the NBA. Many highly drafted players do not meet expectations or suffer injuries. On the other hand, there are also many players who are drafted low or not at all but still excel in the NBA. A well-known example is Giannis Antetokounmpo, who was selected 15th overall in 2013 and was named the NBA MVP and champion in 2021.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Other Professional Opportunities
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Not all players participating in college basketball aim to go to the NBA. Some players choose to complete their academic degrees and pursue other careers. Others opt to play professional basketball in other countries or leagues.
      <br />
      <br />
      There are many professional basketball leagues around the world, offering varying levels of quality, salary, and lifestyle. Some of these leagues include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          EuroLeague: The highest and most prestigious European club competition, featuring 18 teams from 10 countries. EuroLeague is considered the second-best league in the world after the NBA.
        </li>
        <li>
          Chinese Basketball Association (CBA): The highest and most popular Chinese club competition, with 20 teams from across China. CBA is known for its high salaries and large audiences.
        </li>
        <li>
          Australian National Basketball League (NBL): The highest and most competitive Australian club competition, with nine teams from Australia and New Zealand. NBL is known for its fast and physical gameplay.
        </li>
        <li>
          Basketball Africa League (BAL): The newest and most ambitious African club competition, featuring 12 teams from 12 countries. BAL is supported by the NBA and FIBA and aims to develop basketball on the continent.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      These leagues are just a few examples of the many opportunities available to professional basketball players outside of the NBA. Many players enjoy living and playing in different cultures while continuing to pursue their passion for the game.
    </MKTypography>
  </MKBox>
</MKBox>














                                              






                                  









<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 4: International Influences and Opportunities
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Basketball is a global sport that brings people from different nationalities, cultures, and backgrounds together. College basketball is a prime example of this, as it attracts both foreign and American players, offering opportunities. In this chapter, we will explore the international influences and opportunities in college basketball.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Foreign Players in College Basketball
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="NCAA Final Four basketball game with a crowd of nearly 80,000 fans in the stadium."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
         }}>
        Nearly 80,000 spectators during a national championship game
        </figcaption>
      </figure>
      College basketball has always welcomed foreign players who have contributed their own style, skills, and perspectives to the game. According to the NCAA, in the 2019-2020 season, there were over 1,200 foreign players in Division I, hailing from 101 countries. The most common countries of origin were Canada, Australia, Nigeria, France, and the United Kingdom.
      <br />
      <br />
      Foreign players choose college basketball for various reasons. Some want to combine their academic education with their athletic ambitions. Others aim to adapt to American culture and lifestyle. Yet others seek to enhance their chances of reaching the NBA or representing their national teams.
      <br />
      <br />
      Foreign players have had a significant impact on college basketball, both individually and collectively. Some of the most successful and influential foreign players in the history of college basketball include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Hakeem Olajuwon: A Nigerian player who played for the Houston Cougars from 1981 to 1984. He led his team to three consecutive Final Four appearances and won the national player of the year award in 1984. He was the first overall pick in the 1984 NBA draft and became one of the greatest centers of all time.
        </li>
        <li>
          Andrew Bogut: An Australian player who played for the Utah Utes from 2003 to 2005. He won the national player of the year award in 2005 and led his team to the Sweet Sixteen. He was the first overall pick in the 2005 NBA draft and became an All-Star and champion.
        </li>
        <li>
          Luka Garza: An American player with Bosnian roots who played for the Iowa Hawkeyes from 2017 to 2021. He won the national player of the year award twice, in 2020 and 2021, and became his university's all-time leading scorer. He was selected 52nd overall in the 2021 NBA draft and is considered one of the greatest college players ever.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      These players are just a few examples of the many foreign players who have excelled in college basketball. Each year, new talents emerge, enriching the game.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 American Players Abroad
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Not only do foreign players come to America to study and play basketball, but American players also go abroad for the same reasons. Some American players choose to skip or cut short their high school or college careers to play professionally in other countries or leagues.
      <br />
      <br />
      The reasons for this choice can vary, but some include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Financial: Some players want to earn money with their talent instead of waiting for an NBA contract or a scholarship.
        </li>
        <li>
          Athletic: Some players want to develop against stronger opponents instead of dominating at their level.
        </li>
        <li>
          Personal: Some players want to travel, learn new cultures, or maintain family ties.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      American players going abroad have different experiences and outcomes. Some succeed in realizing their NBA dream, while others enjoy a long and successful career. Some adapt well to their new surroundings, while others struggle with language, food, or lifestyle.
      <br />
      <br />
      Some examples of American players who have gone abroad include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Brandon Jennings: A player who skipped his high school to play for Lottomatica Roma in Italy in 2008. He was the 10th overall pick in the 2009 NBA draft and played for 10 seasons in the NBA.
        </li>
        <li>
          Emmanuel Mudiay: A player who skipped his college to play for the Guangdong Southern Tigers in China in 2014. He was the 7th overall pick in the 2015 NBA draft and currently plays for the Utah Jazz.
        </li>
        <li>
          LaMelo Ball: A player who skipped his high school and college to play for the Illawarra Hawks in Australia in 2019. He was the 3rd overall pick in the 2020 NBA draft and won the Rookie of the Year award.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      These players are just a few examples of the many American players who have chosen an alternative path to professional basketball. Every year, new adventurers globalize the game.
    </MKTypography>
  </MKBox>
</MKBox>











                                              






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 5: Sports Scholarships and the Future of Athletes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sports scholarships offer numerous opportunities for young athletes to showcase their talent and develop their skills. One of the most significant opportunities is obtaining a sports scholarship to study and compete at the college level. In this chapter, we will discuss the importance of sports scholarships and how Sport Scholarships America can help you make your dream come true.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 The Importance of Scholarships
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A sports scholarship is financial support provided by a university to a student-athlete to combine studies and sports. It can cover the costs of tuition, books, housing, meals, and other expenses. Additionally, it may offer benefits such as academic tutoring, medical care, training facilities, and equipment.
      <br />
      <br />
      Obtaining a sports scholarship is competitive, with many factors playing a role in the awarding process, including academic achievements, athletic performance, personality, motivation, leadership, and adaptability. Therefore, it's essential to prepare well and distinguish yourself from the competition.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 How Sport Scholarships America Can Help
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo of Sport Scholarships America, featuring sports equipment and a graduation cap, representing the focus on sports scholarships for student-athletes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo of Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America is an organization specialized in guiding young sports talents to sports scholarships. With passionate and experienced experts, Sport Scholarships America supports student-athletes at every step of the journey, from initial orientation to final placement. Their services include:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>A personal intake interview to discuss the student-athlete's wishes, possibilities, and expectations.</li>
        <li>A professional evaluation of the student-athlete's athletic and academic level.</li>
        <li>An extensive network of contacts with coaches, universities, and colleges.</li>
        <li>Effective promotion of the student-athlete's profile and videos to potential coaches.</li>
        <li>Expert advice on the best options based on the student-athlete's preferences, goals, and budget.</li>
        <li>Negotiation of the best scholarship offers on behalf of the student-athlete.</li>
        <li>Assistance in applying for necessary documents, such as visa, transcript, diploma, and admission tests.</li>
        <li>Preparation for student-athletes' life during their scholarship, including culture, language, rules, and tips.</li>
        <li>Aftercare and follow-up during the scholarship period.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Want to explore scholarship opportunities and achieve your athletic and academic goals? <Link to="/Services-Products">View the full range</Link> of services offered by Sport Scholarships America and schedule a consultation today.
    </MKTypography>
  </MKBox>
</MKBox>
          










        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;