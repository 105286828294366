/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Gymnastics1.jpeg";
import Image2 from "assets/images/Sports_Images/Gymnastics2.jpg";
import Image3 from "assets/images/Sports_Images/Gymnastics3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Gimnasia y Estudiar en Estados Unidos: Una Oportunidad Única para Jóvenes Gimnastas', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capítulo 1: Historia y Desarrollo de la Gimnasia en Estados Unidos', isSubheader: false },
    { id: '1.1', title: '1.1 Primeros Años y el Papel de las Universidades', isSubheader: true },
    { id: '1.2', title: '1.2 Crecimiento y Profesionalización', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capítulo 2: La Gimnasia Universitaria Hoy en Día', isSubheader: false },
    { id: '2.1', title: '2.1 Principales Competiciones y Conferencias', isSubheader: true },
    { id: '2.2', title: '2.2 Programas Destacados y Equipos Prestigiosos', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capítulo 3: De la Universidad a los Profesionales', isSubheader: false },
    { id: '3.1', title: '3.1 El Camino hacia el Profesionalismo', isSubheader: true },
    { id: '3.2', title: '3.2 Carreras y Oportunidades Profesionales', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capítulo 4: Influencias Internacionales', isSubheader: false },
    { id: '4.1', title: '4.1 Influencias Extranjeras e Intercambio', isSubheader: true },
    { id: '4.2', title: '4.2 Los Estadounidenses en el Extranjero', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capítulo 5: Becas Deportivas y el Futuro de los Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 La Importancia de las Becas', isSubheader: true },
    { id: '5.2', title: '5.2 Cómo Puede Ayudar Sport Scholarships America', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contenidos
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Ocultar</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Gimnasia y Estudiar en Estados Unidos: Una Oportunidad Única para Jóvenes Gimnastas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Vista desde las gradas en una competencia de gimnasia universitaria en un estadio grande."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Un gran estadio durante una competencia de gimnasia de la NCAA
      </figcaption>
    </figure>
    La gimnasia tiene una larga y rica historia en Estados Unidos. Desde el siglo XIX, la gimnasia se ha practicado en universidades, donde los gimnastas desarrollan sus habilidades y muestran sus talentos. La gimnasia no es solo un deporte; también es una forma de arte, una ciencia y un estilo de vida. Los gimnastas aprenden disciplina, perseverancia, creatividad y trabajo en equipo. Entrenan duro pero también se divierten mucho.
    <br />
    <br />
    Competir en gimnasia a nivel universitario es una oportunidad única para que los jóvenes gimnastas cumplan sus sueños. Al obtener una beca deportiva, puedes estudiar en una universidad prestigiosa mientras participas en emocionantes competiciones contra otros gimnastas de élite. Te conviertes en parte de una comunidad unida de estudiantes-atletas que se apoyan y se motivan mutuamente. Tienes acceso a las mejores instalaciones, entrenadores y mentores que te ayudan a alcanzar tu potencial. Y obtienes una experiencia inolvidable que te prepara para tu futura carrera.
    <br />
    <br />
    Pero, ¿cómo calificas para una beca deportiva? ¿Cuáles son los requisitos y beneficios? ¿Cómo encuentras la universidad adecuada para ti? ¿Y cómo es la vida de un estudiante-gimnasta? Estas son todas preguntas que Sportbeursamerika.nl puede responder por ti. Sportbeursamerika.nl es una plataforma que ayuda a los jóvenes gimnastas a encontrar su camino hacia América. Ofrezco orientación personal, consejos y apoyo durante todo el proceso de solicitud, selección, preparación y partida. Tengo conexiones con cientos de universidades y entrenadores que buscan talento gimnástico. Conozco los pormenores del sistema educativo estadounidense y de la gimnasia universitaria. Y tengo experiencia personal con el deporte y el estudio en Estados Unidos.
    <br />
    <br />
    ¿Tienes curiosidad por las oportunidades para combinar la gimnasia y el estudio en Estados Unidos? Sigue leyendo y descubre todo lo que necesitas saber sobre esta oportunidad única.
  </MKTypography>
  {/* Agregar una lista con viñetas u otro contenido según sea necesario */}
</MKBox>


<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 1: Historia y Desarrollo de la Gimnasia en Estados Unidos
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    La gimnasia es uno de los deportes más antiguos del mundo, con sus orígenes en civilizaciones antiguas como Grecia, Roma y China. La gimnasia se utilizaba como un medio para entrenar el cuerpo, agudizar la mente y elevar el alma. También se veía como una forma de expresión, arte y belleza.
    <br />
    <br />
    En Estados Unidos, la gimnasia comenzó a desarrollarse en el siglo XIX cuando inmigrantes europeos trajeron sus conocimientos y habilidades. La gimnasia rápidamente se popularizó en escuelas, clubes y asociaciones, donde se consideraba una forma de promover la salud, el carácter y la ciudadanía. La gimnasia también se convirtió en una fuente de orgullo nacional, especialmente después de la Guerra de Independencia.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Primeros Años y el Papel de las Universidades
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Los primeros equipos universitarios de gimnasia se formaron a finales del siglo XIX cuando los estudiantes establecieron sus propios clubes y organizaron competiciones intercolegiales. La primera competición oficial de gimnasia universitaria tuvo lugar en 1897 cuando la Universidad de Yale enfrentó al Springfield College. El Springfield College se considera el lugar de nacimiento de la gimnasia estadounidense ya que fue la primera escuela en ofrecer la gimnasia como materia académica. Muchos entrenadores y líderes de gimnasia de renombre estudiaron o enseñaron en el Springfield College.
      <br />
      <br />
      A principios del siglo XX, la gimnasia universitaria continuó creciendo con el establecimiento de organizaciones regionales y nacionales como la Eastern Intercollegiate Gymnastics League (EIGL) en 1902 y la National Collegiate Athletic Association (NCAA) en 1906. La NCAA comenzó a organizar campeonatos nacionales para hombres en 1938 y para mujeres en 1982. La NCAA sigue siendo la principal organización para la gimnasia universitaria en Estados Unidos.
    </MKTypography>
  </MKBox>

  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crecimiento y Profesionalización
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La popularidad y el nivel de la gimnasia universitaria aumentaron después de la Segunda Guerra Mundial cuando muchos veteranos regresaron a las universidades y persiguieron sus ambiciones atléticas. La influencia de entrenadores y atletas europeos, que llegaron a Estados Unidos para escapar del comunismo o del nazismo, también contribuyó al desarrollo de la gimnasia universitaria. Una de estas figuras fue George Gulack, un gimnasta húngaro que ganó el oro en los Juegos Olímpicos de 1936 en Berlín. Gulack desempeñó un papel significativo en el comité olímpico y en las organizaciones de gimnasia estadounidenses e internacionales, sirviendo en el Comité Olímpico Estadounidense de 1934 a 1958.
      <br />
      <br />
      En las décadas de 1950 y 1960, la gimnasia profesional comenzó a diferenciarse de la gimnasia universitaria, con el surgimiento de estrellas internacionales como Larisa Latynina, Olga Korbut y Nadia Comaneci. Estas gimnastas inspiraron a muchos jóvenes estadounidenses a unirse a clubes privados y enfocarse en logros individuales. Una de ellas fue Cathy Rigby, quien se convirtió en la primera mujer estadounidense en ganar una medalla en un campeonato mundial en 1970. Más tarde se convirtió en una actriz muy exitosa.
      <br />
      <br />
      La gimnasia profesional alcanzó su punto máximo en 1978 cuando el gimnasta estadounidense Kurt Thomas se convirtió en el primer hombre en ganar una medalla de oro en un campeonato mundial. Estudió en la Universidad Estatal de Indiana y más tarde se convirtió en actor. En el mismo año, Mary Lou Retton hizo su debut en el escenario nacional. Se convirtió en la primera mujer estadounidense en ganar oro en el all-around en los Juegos Olímpicos de 1984.
      <br />
      <br />
      Sin embargo, la gimnasia universitaria siguió siendo relevante e influyente, especialmente para las mujeres. Muchas gimnastas femeninas optaron por continuar sus carreras a nivel universitario porque recibían más oportunidades y beneficios allí que en el circuito profesional.
      <br />
      <br />
      La gimnasia universitaria, por lo tanto, desempeñó un papel significativo en la historia y el desarrollo de la gimnasia en Estados Unidos. Produjo muchos atletas que sobresalieron no solo en su deporte sino también en sus estudios y carreras posteriores. También contribuyó a la difusión y promoción de la gimnasia como un deporte para todos, independientemente de la edad, el género o el origen. La gimnasia universitaria sigue siendo un deporte vibrante y dinámico que atrae a miles de fanáticos y espectadores cada año.
    </MKTypography>
  </MKBox>
</MKBox>

                                              





                                  







<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 2: La Gimnasia Universitaria Hoy en Día
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    La gimnasia es uno de los deportes más populares y competitivos a nivel universitario en Estados Unidos. Cada año, cientos de equipos y miles de atletas compiten por los codiciados títulos y trofeos. La gimnasia universitaria ofrece una combinación única de atletismo, espectáculo y excelencia académica. Es un deporte que demanda mucho pero también da mucho a cambio.
  </MKTypography>
  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Principales Competiciones y Conferencias
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Una gimnasta de Auburn realizando una rutina de suelo durante una competencia universitaria."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Una gimnasta de Auburn en acción
        </figcaption>
      </figure>
      La gimnasia universitaria está regida por la NCAA, que establece las reglas, calificaciones y campeonatos. La NCAA divide la gimnasia universitaria en tres divisiones según el número de becas, presupuesto y nivel del equipo. La División I es la división más alta, con la mayoría de los mejores gimnastas y equipos participando. La División II y III son divisiones más bajas con menos becas ofrecidas y más énfasis en el rendimiento académico.
      <br />
      <br />
      Dentro de cada división, hay diferentes conferencias que forman grupos regionales o temáticos de universidades. Las conferencias organizan sus propias competiciones y campeonatos, que sirven como preliminares para los campeonatos nacionales. Las principales conferencias para la gimnasia universitaria son:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          La Conferencia del Big Ten, compuesta por 14 universidades en el Medio Oeste y Noreste de Estados Unidos. El Big Ten es una de las conferencias más antiguas y prestigiosas, conocida por su excelencia académica y atlética. El Big Ten tiene algunos de los mejores programas de gimnasia masculina y femenina, incluidos Michigan, Minnesota, Nebraska y Penn State.
        </li>
        <li>
          La Conferencia del Sureste (SEC), que comprende 14 universidades en el Sureste de Estados Unidos. La SEC es una de las conferencias más dominantes e influyentes, conocida por sus fanáticos apasionados y leales. La SEC tiene algunos de los mejores programas de gimnasia femenina, como Alabama, Florida, Georgia y LSU.
        </li>
        <li>
          La Conferencia de Pac-12, compuesta por 12 universidades en el Oeste de Estados Unidos. La Pac-12 es una de las conferencias más innovadoras y diversas, conocida por su creatividad y cultura. La Pac-12 tiene algunos de los mejores programas de gimnasia masculina y femenina, incluidos California, Stanford, UCLA y Utah.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Los campeonatos nacionales son el punto culminante de la temporada de gimnasia universitaria, donde los mejores equipos e individuos compiten por honor y gloria. Los campeonatos nacionales se celebran cada año en abril en varios lugares de todo el país. Los campeonatos nacionales constan de dos rondas: los campeonatos regionales y las finales nacionales. Los campeonatos regionales son las rondas clasificatorias, donde 36 equipos masculinos y 36 equipos femeninos se dividen en seis regiones. Los dos mejores equipos de cada región avanzan a las finales nacionales. Las finales nacionales son las rondas decisivas, donde 12 equipos masculinos y 12 equipos femeninos se dividen en dos sesiones. Los cuatro mejores equipos de cada sesión avanzan a la superfinal (Super Six), donde compiten por el título de campeón nacional por equipos. Además, se otorgan títulos individuales para la competición general y las finales por aparatos.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programas Destacados y Equipos Prestigiosos
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La gimnasia universitaria ha producido varios programas destacados distinguidos por su consistencia, calidad y tradición. Estos programas han ganado múltiples títulos nacionales, entrenado a muchos atletas talentosos y atraído a numerosos fanáticos. Algunos de estos programas incluyen:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          UCLA Bruins: El programa de gimnasia de UCLA es uno de los programas más exitosos y respetados del país. El programa de gimnasia de UCLA ha ganado más de 20 títulos nacionales, incluidos múltiples títulos para hombres y la mayoría para mujeres. El programa también ha producido numerosos campeones olímpicos y mundiales, como Peter Vidmar, Tim Daggett, Jamie Dantzscher, Mohini Bhardwaj, Tasha Schwikert, Samantha Peszek y Kyla Ross.
        </li>
        <li>
          Oklahoma Sooners: El programa de gimnasia de Oklahoma es uno de los programas más dominantes e impresionantes de la nación. El programa de gimnasia de Oklahoma ha ganado más de 10 títulos nacionales, incluidos múltiples títulos para hombres y casi tantos para mujeres. El programa también ha producido muchos campeones de la NCAA, incluidos Jonathan Horton, Jake Dalton, Maggie Nichols, Brenna Dowell y Anastasia Webb.
        </li>
        <li>
          Utah Utes: El programa de gimnasia de Utah es uno de los programas más históricos y leales del país. El programa de gimnasia de Utah ha ganado más de 10 títulos nacionales, todos ellos para mujeres. El programa también ha producido entrenadores y atletas legendarios, incluidos Greg Marsden, Megan Marsden, Missy Marlowe, Theresa Kulikowski, Ashley Postell y MyKayla Skinner.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La gimnasia universitaria ofrece una oportunidad única para que los jóvenes gimnastas practiquen su deporte al más alto nivel mientras reciben una educación valiosa. La gimnasia universitaria es un deporte que ofrece muchos desafíos y recompensas, tanto dentro como fuera del tapiz. La gimnasia universitaria es un deporte que puede cambiar tu vida.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  







<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 3: De la Universidad a los Profesionales
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    La gimnasia es un deporte que requiere mucho talento, dedicación y perseverancia. Los gimnastas suelen comenzar a entrenar a una edad temprana y sueñan con una carrera profesional. Pero ¿cómo se hace la transición de la gimnasia universitaria a la gimnasia profesional? ¿Y cuáles son las oportunidades y desafíos que te esperan?
  </MKTypography>
  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 El Camino hacia el Profesionalismo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La gimnasia universitaria es una excelente preparación para la gimnasia profesional porque te brinda la oportunidad de mejorar tus habilidades, ganar experiencia y expandir tu red de contactos. Muchos gimnastas universitarios han participado en competiciones profesionales como los Juegos Olímpicos, campeonatos mundiales y la Copa del Mundo. Algunos de ellos incluso han ganado medallas.
      <br />
      <br />
      Sin embargo, para participar en competiciones profesionales, los gimnastas universitarios deben cumplir con ciertas reglas y condiciones. Deben mantener su estatus de aficionado, lo que significa que no pueden ganar dinero con su deporte. Tampoco se les permite tener patrocinadores o agentes a menos que reciban permiso de la NCAA. También deben cumplir con los requisitos académicos de su universidad, lo que a veces puede ser difícil de conciliar con su horario de entrenamiento.
      <br />
      <br />
      Además, los gimnastas universitarios también deben considerar su edad y condición física. La gimnasia es un deporte que exige mucho al cuerpo y a menudo conduce a lesiones. Muchos gimnastas alcanzan su pico alrededor de los veinte años y pueden tener dificultades para mantener su nivel después. Algunos gimnastas optan por renunciar a sus ambiciones profesionales y enfocarse en sus estudios o carreras fuera del deporte.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Carreras y Oportunidades Profesionales
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Para aquellos que deciden continuar con la gimnasia profesional, existen diversas oportunidades y posibilidades para seguir su pasión. Una de las opciones más prestigiosas y lucrativas es competir en los Juegos Olímpicos, que se celebran cada cuatro años. Los Juegos Olímpicos son el pináculo de cualquier carrera deportiva, ofreciendo la oportunidad de representar a tu país, competir contra los mejores gimnastas del mundo y ganar una medalla. Los Juegos Olímpicos también traen fama y reconocimiento que pueden conducir a contratos de patrocinio, atención mediática y otros beneficios.
      <br />
      <br />
      Otra opción es participar en los campeonatos mundiales, que se celebran cada año (excepto en los años olímpicos). Los campeonatos mundiales son un punto de referencia significativo para el nivel y desarrollo de la gimnasia a nivel mundial. Los campeonatos mundiales también ofrecen la oportunidad de clasificarse para los Juegos Olímpicos o recibir una entrada de comodín. Los campeonatos mundiales son una fuente de orgullo y prestigio que puede conducir a premios en efectivo, becas y otros beneficios.
      <br />
      <br />
      Una tercera opción es competir en la Copa del Mundo, que consiste en una serie de competiciones individuales celebradas en todo el mundo. La Copa del Mundo es una opción atractiva para los gimnastas que buscan más flexibilidad y variedad en su carrera deportiva. La Copa del Mundo también ofrece la oportunidad de explorar diferentes países y culturas, hacer nuevos amigos y ampliar tus horizontes. La Copa del Mundo también es una fuente de diversión y aventura que puede llevar a reembolsos de viaje, bonificaciones y otros incentivos.
      <br />
      <br />
      Además de estas competiciones internacionales, hay otras oportunidades profesionales para los gimnastas, como:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Realizar clínicas, talleres o demostraciones donde compartas tus conocimientos y experiencia con otros gimnastas, entrenadores o fanáticos.
        </li>
        <li>
          Trabajar como entrenador, instructor o asesor, ayudando a otros gimnastas a alcanzar sus objetivos, mejorar su técnica o resolver sus problemas.
        </li>
        <li>
          Trabajar como comentarista, analista o periodista, ofreciendo tu opinión e insights sobre la gimnasia, competiciones o eventos actuales.
        </li>
        <li>
          Trabajar como modelo, actor o influencer, utilizando tu apariencia, personalidad o popularidad para promocionar productos, servicios o marcas.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La gimnasia profesional es una carrera emocionante y desafiante que ofrece muchas posibilidades y oportunidades. Sin embargo, también es una carrera exigente e incierta que conlleva riesgos y obstáculos. Elegir una carrera en la gimnasia profesional requiere tanto el corazón como la cabeza.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  







<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 4: Influencias Internacionales
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    La gimnasia es un deporte sin fronteras. La gimnasia es un deporte que une a personas de todo el mundo, las inspira y enriquece. La gimnasia es un deporte influenciado por diversas culturas, estilos y tradiciones. En este capítulo, analizaremos las influencias internacionales en la gimnasia universitaria en América y cómo estas influencias han moldeado y transformado la gimnasia universitaria.
  </MKTypography>
  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Influencias Extranjeras e Intercambio
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Gimnasta de Stanford en acción en el potro durante una competencia universitaria."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Un gimnasta de Stanford en acción
        </figcaption>
      </figure>
      En el fascinante mundo de la gimnasia universitaria en América, el intercambio internacional juega un papel crucial. ¿Sabías que los gimnastas extranjeros aportan una dinámica y técnicas únicas que elevan el nivel de competencia? Estos atletas a menudo traen estilos y métodos innovadores que inspiran y desafían a sus homólogos estadounidenses. Esto no solo hace que el deporte sea más diverso, sino también más competitivo.
      <br />
      <br />
      Un ejemplo de esto es el impacto de los gimnastas del este de Europa en los años 80 y 90. Su llegada a Estados Unidos trajo nuevas técnicas y métodos de entrenamiento, lo que llevó a una evolución significativa en la gimnasia estadounidense. Estas influencias todavía son visibles hoy en día en el estilo y la técnica de muchos gimnastas de élite.
      <br />
      <br />
      La diversidad que aportan los gimnastas internacionales enriquece la cultura deportiva en los campus. Crea un crisol de culturas e ideas que solo beneficia al deporte. Además, ofrece a los estudiantes y entrenadores estadounidenses la oportunidad de aprender desde diferentes perspectivas culturales y mejorar sus propias habilidades y tácticas.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Los Estadounidenses en el Extranjero
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Por otro lado, los gimnastas estadounidenses también han tenido un impacto significativo en el mundo de la gimnasia internacional. Numerosos estudiantes atletas estadounidenses han logrado éxito internacional después de sus carreras universitarias. Sus actuaciones en campeonatos mundiales y en los Juegos Olímpicos a menudo son el resultado del entrenamiento y la experiencia que adquirieron en el circuito universitario.
      <br />
      <br />
      Estos gimnastas sirven como embajadores de la cultura gimnástica estadounidense. Sus éxitos inspiran a jóvenes gimnastas de todo el mundo a aspirar a formar parte de un equipo universitario estadounidense. Esto crea un ciclo positivo donde el talento y el conocimiento se intercambian a través de las fronteras.
      <br />
      <br />
      Los caminos que han tomado estos gimnastas demuestran cómo la gimnasia universitaria puede ser una excelente base para una carrera internacional. Las experiencias que adquieren durante sus estudios, desde participar en competiciones de alto nivel hasta entrenar bajo los mejores entrenadores, los preparan para los desafíos del mundo deportivo profesional.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  



<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Becas Deportivas y el Futuro de los Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Las becas deportivas ofrecen numerosas oportunidades para que los jóvenes atletas muestren su talento y desarrollen sus habilidades. Una de las oportunidades más significativas es obtener una beca deportiva para estudiar y competir a nivel universitario. En este capítulo, discutiremos la importancia de las becas deportivas y cómo Sport Scholarships America puede ayudarte a hacer realidad tu sueño.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 La Importancia de las Becas
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Una beca deportiva es un apoyo financiero proporcionado por una universidad a un estudiante-atleta para combinar estudios y deporte. Puede cubrir los costos de matrícula, libros, alojamiento, comidas y otros gastos. Además, puede ofrecer beneficios como tutoría académica, atención médica, instalaciones de entrenamiento y equipo.
      <br />
      <br />
      Obtener una beca deportiva es competitivo, con muchos factores que influyen en el proceso de adjudicación, incluyendo logros académicos, rendimiento deportivo, personalidad, motivación, liderazgo y adaptabilidad. Por lo tanto, es importante prepararse bien y destacarse entre la competencia.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Cómo Puede Ayudar Sport Scholarships America
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo de Sport Scholarships America, que muestra equipamiento deportivo y una gorra de graduación, representando el enfoque en becas deportivas para estudiantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America es una organización especializada en guiar a jóvenes talentos deportivos hacia becas deportivas. Con expertos apasionados y experimentados, Sport Scholarships America apoya a los estudiantes-atletas en cada paso del camino, desde la orientación inicial hasta la colocación final. Sus servicios incluyen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Una entrevista personal para discutir los deseos, posibilidades y expectativas del estudiante-atleta.</li>
        <li>Una evaluación profesional del nivel académico y deportivo del estudiante-atleta.</li>
        <li>Una extensa red de contactos con entrenadores, universidades y colegios.</li>
        <li>Promoción efectiva del perfil y videos del estudiante-atleta ante entrenadores potenciales.</li>
        <li>Asesoramiento experto sobre las mejores opciones basadas en las preferencias, metas y presupuesto del estudiante-atleta.</li>
        <li>Negociación de las mejores ofertas de becas en nombre del estudiante-atleta.</li>
        <li>Asistencia en la solicitud de documentos necesarios, como visa, expediente académico, diploma y pruebas de admisión.</li>
        <li>Preparación para la vida del estudiante-atleta durante su beca, incluyendo cultura, idioma, reglas y consejos.</li>
        <li>Seguimiento y atención posterior durante el período de la beca.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      ¿Quieres explorar oportunidades de becas y alcanzar tus metas deportivas y académicas? <Link to="/es/Servicios-Productos">Consulta la gama completa</Link> de servicios ofrecidos por Sport Scholarships America y programa una consulta hoy mismo.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;