/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import Image1 from "assets/images/Sports_Images/Ice Hockey1.jpeg";
import Image2 from "assets/images/Sports_Images/Ice Hockey2.jpg";
import Image3 from "assets/images/Sports_Images/Ice Hockey3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images

import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Ishockeystipendium USA: Hur man Studerar och Spelar Ishockey i Amerika', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapitel 1: Ishockeyns Historia och Utveckling i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Tidiga År och Collegens Roll', isSubheader: true },
    { id: '1.2', title: '1.2 Tillväxt och Professionalisering', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapitel 2: Collegetishockey Idag', isSubheader: false },
    { id: '2.1', title: '2.1 Stora Ligor och Konferenser', isSubheader: true },
    { id: '2.2', title: '2.2 Toppprogram och Prestigefyllda Lag', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapitel 3: Från College till Proffsen', isSubheader: false },
    { id: '3.1', title: '3.1 Vägen till Professionalism', isSubheader: true },
    { id: '3.2', title: '3.2 NHL-draften och Andra Möjligheter', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapitel 4: Internationella Influenser', isSubheader: false },
    { id: '4.1', title: '4.1 Utländska Spelare i Collegetishockey', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapitel 5: Idrottsstipendier och Framtiden för Idrottare', isSubheader: false },
    { id: '5.1', title: '5.1 Vikten av Stipendier', isSubheader: true },
    { id: '5.2', title: '5.2 Hur Sport Scholarships America Kan Hjälpa', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innehåll
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Dölj</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Visa</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>





















          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Ishockeystipendium USA: Hur man Studerar och Spelar Ishockey i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Aerial view of a packed stadium at a college ice hockey game."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Ett fullsatt stadion under en collegetävling
      </figcaption>
    </figure>
    Ishockey är en av de mest populära sporterna i Amerika. Det är en snabb, fysisk och spännande sport som lockar miljontals fans. Ishockey spelas på olika nivåer, från amatör till professionell. Men en av de viktigaste och mest prestigefyllda nivåerna är collegetishockey.
    <br />
    <br />
    Collegetishockey är versionen av ishockey som spelas av universitets- och högskolestudenter i Amerika. Det är en del av det amerikanska utbildningssystemet som kombinerar idrott och akademik. Collegetishockey erbjuder unga spelare möjlighet att utvecklas både akademiskt och idrottsligt samtidigt som de njuter av studentlivet.
    <br />
    <br />
    Collegetishockey är också en trampolin till den professionella världen. Många spelare som spelar i NHL (National Hockey League) eller andra professionella ligor har först spelat collegetishockey. Collegetishockey är ett utmärkt sätt att förbereda sig för en karriär inom sporten.
    <br />
    <br />
    Men hur kan en ung ishockeyspelare kvalificera sig för collegetishockey? Där kan Sportbeursamerika.nl hjälpa dig. Jag är specialiserad på att guida studentidrottare som vill få ett idrottsstipendium i Amerika. Ett idrottsstipendium är en ekonomisk belöning som du får från ett universitet eller högskola för att studera och spela idrott där. Med ett idrottsstipendium kan du studera och spela ishockey i Amerika utan att få höga kostnader.
    <br />
    <br />
    I den här artikeln kommer vi att ge dig mer information om collegetishockey i Amerika, dess historia, utveckling, ligor, lag, möjligheter, internationella influenser och naturligtvis stipendier. Jag kommer också berätta hur jag kan hjälpa dig att förverkliga din dröm. Är du redo att lära dig mer om ishockeystipendier i USA? Fortsätt läsa!
  </MKTypography>
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Ishockeyns Historia och Utveckling i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Ishockey är en sport som har funnits i Amerika i över ett sekel. Den har en rik och fascinerande historia som är nära kopplad till collegens roll. I det här kapitlet dyker vi djupare in i ursprunget, tillväxten och professionaliseringen av ishockeyn i Amerika.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidiga År och Collegens Roll
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ishockey uppstod i Kanada, där det först spelades på 1800-talet. Spelet spreds snabbt till USA och blev särskilt populärt i nordöstra staterna. De första universitetslagen bildades i slutet av 1800-talet och början av 1900-talet, inklusive Yale, Harvard, Princeton och Dartmouth.
      <br />
      <br />
      Dessa lag spelade intercollegiate-matcher och lockade ofta stora publikmängder. De spelade också mot kanadensiska lag, som vanligtvis var starkare. För att höja spelnivån började vissa universitet rekrytera professionella spelare som registrerade sig som studenter. Denna praxis väckte kontroverser och kritik eftersom den komprometterade sportens amatörstatus.
      <br />
      <br />
      För att sätta stopp för denna praxis grundades Intercollegiate Hockey Association (IHA) 1898, den första samlingen av collegetishockeyprogram i Amerika. IHA, ibland kallad Intercollegiate Hockey League, inkluderade lag som Brown, Columbia, University of Pennsylvania och Yale. Denna organisation var den enda mästerskapsmyndigheten för collegetishockey på den tiden och avgjorde den facto nationella mästaren baserat på säsongens prestanda. IHA fortsatte att existera fram till 1913.
      <br />
      <br />
      Efter upplösningen av IHA etablerades Intercollegiate Hockey League (IHL) 1912 av Harvard, Princeton och Yale. Denna liga var ett svar på behovet av att de bästa collegetlagen fortsatte att tävla mot varandra. IHL existerade fram till 1917 när aktiviteter avbröts på grund av första världskriget.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Tillväxt och Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Under 1900-talet växte collegetishockey till en nationell sport, spelad av hundratals lag i olika divisioner och konferenser. Collegetishockey påverkades också alltmer av uppkomsten av professionella ishockeyligor, som NHL (grundad 1917) och World Hockey Association (grundad 1972).
      <br />
      <br />
      Dessa professionella ligor erbjöd möjligheter för talangfulla collegespelare att avancera till en högre nivå. Många collegetishockeyspelare valdes i NHL-draften, den årliga händelsen där NHL-lag väljer nya spelare. Den första NHL-draften där en collegespelare valdes ägde rum 1967. Al Karlander, en spelare från Michigan Tech, valdes av Detroit som det 17: e totalt det året. Sedan dess har över tusen collegetishockeyspelare valts av NHL-lag.
      <br />
      <br />
      Collegetishockey bevittnade också betydande ögonblick i sportens historia. Ett av de mest minnesvärda var "Miraklet på isen" 1980 när det amerikanska olympiska laget, främst bestående av collegetishockeyspelare, besegrade Sovjetunionen i en historisk match under vinter-OS i Lake Placid. Detta var en anmärkningsvärd prestation eftersom sovjeterna vid den tiden betraktades som världens bästa ishockeylag.
      <br />
      <br />
      Collegetishockey har därför en lång och imponerande historia och vittnar om passionen och talangen hos amerikanska spelare. Collegetishockey har också bidragit till utvecklingen och innovationen av sporten genom att introducera nya regler, taktiker och stilar.
    </MKTypography>
  </MKBox>
</MKBox>
                                  





                                  






<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Collegetishockey Idag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Collegetishockey är en av de mest konkurrenskraftiga och spännande sporterna i Amerika idag. Den spelas av över 400 lag, uppdelade i tre NCAA-divisioner, olika konferenser och regionala zoner. I det här kapitlet kommer vi att ge en översikt över de stora ligorna och konferenserna, toppprogrammen och prestigefyllda lagen samt strukturen för säsongen och mästerskapet.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Stora Ligor och Konferenser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Spelare från Massachusetts firar en seger i collegetishockey."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Spelare från Massachusetts firar en seger
        </figcaption>
      </figure>
      NCAA (National Collegiate Athletic Association) är organisationen som reglerar collegetishockey. NCAA delar lagen i tre divisioner baserat på nivån, budgeten och antalet stipendier de erbjuder. Divisionerna är:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Division I: Detta är den högsta divisionen där de bästa lagen och spelarna tävlar. Division I består av 60 lag, uppdelade i sex konferenser: Atlantic Hockey, Big Ten, ECAC Hockey, Hockey East, NCHC (National Collegiate Hockey Conference) och WCHA (Western Collegiate Hockey Association). Dessa konferenser organiserar sina egna tävlingar och turneringar under säsongen.
        </li>
        <li>
          Division II: Detta är en mellanliggande division med färre lag och spelare. Det finns endast sju lag i Division II, och de har inte sin egen konferens. De tävlar vanligtvis mot lag från Division III eller andra ligor.
        </li>
        <li>
          Division III: Detta är den lägsta divisionen där de flesta lagen och spelarna deltar. Division III består av 84 lag, uppdelade i sju konferenser: CCC (Commonwealth Coast Conference), MASCAC (Massachusetts State Collegiate Athletic Conference), MIAC (Minnesota Intercollegiate Athletic Conference), NCHA (Northern Collegiate Hockey Association), NEHC (New England Hockey Conference), SUNYAC (State University of New York Athletic Conference) och UCHC (United Collegiate Hockey Conference). Dessa konferenser organiserar också sina egna tävlingar och turneringar under säsongen.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Förutom NCAA-divisionerna och konferenserna finns det också andra ligor och organisationer som erbjuder collegetishockey.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Toppprogram och Prestigefyllda Lag
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Collegetishockey har flera toppprogram och prestigefyllda lag som utmärker sig på grund av sin framgång, tradition, rykte och inflytande. Några av dessa program och lag inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          University of North Dakota: Ett av de mest framgångsrika collegetishockeyprogrammen i Amerika, tävlande i NCHC-konferensen i Division I. Universitetet har vunnit flera NCAA-mästerskap och har producerat talrika spelare som har spelat i NHL, inklusive välkända namn som Jonathan Toews, Zach Parise och T.J. Oshie.
        </li>
        <li>
          Boston College: Ett framstående collegetishockeyprogram i Amerika, tävlande i Hockey East-konferensen i Division I. Boston College har flera nationella mästerskap på sitt namn. Programmet är också känt för att ha utvecklat över 80 NHL-spelare, med kända alumner som Johnny Gaudreau, Patrick Eaves och Brian Gionta.
        </li>
        <li>
          University of Minnesota: Detta historiska collegetishockeyprogram, tävlande i Big Ten-konferensen i Division I, har uppnått ett imponerande antal nationella mästerskap. Det är känt för att ha producerat ett rekordantal spelare som har spelat i NHL, inklusive stjärnor som Phil Kessel, Paul Martin och Blake Wheeler.
        </li>
        <li>
          University of Wisconsin: Ett av de mest prestigefyllda collegetishockeyprogrammen i Amerika, tävlande i Big Ten-konferensen i Division I. Wisconsin har vunnit flera nationella mästerskap och har också tränat ett betydande antal spelare som har spelat i NHL, inklusive Ryan Suter, Joe Pavelski och Dany Heatley.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dessa program och lag är bara några exempel på de många som collegetishockey har att erbjuda. Det finns många fler lag som har sin egen historia, kultur och identitet. Collegetishockey är en mångsidig och dynamisk sport med mycket att erbjuda spelare och fans.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  







<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Från College till Proffsen
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Collegetishockey är inte bara en sport; det är också en läroplats. Många spelare som deltar i collegetishockey har ambitioner att avancera till den professionella världen. I det här kapitlet kommer vi att diskutera hur collegetishockey kan fungera som en trampsten till NHL eller andra professionella ligor, vilka alumner som har lyckats i den professionella ishockeyvärlden och vilka möjligheter som finns bortom NHL.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Vägen till Professionalism
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Collegetishockey är ett utmärkt sätt att förbereda sig för en karriär inom idrott. Genom att spela collegetishockey kan du förbättra dina färdigheter, kunskaper och erfarenheter samtidigt som du tar examen. Collegetishockey ger dig också möjlighet att tävla mot andra talangfulla spelare som utmanar och inspirerar dig.
      <br />
      <br />
      En av de viktigaste fördelarna med collegetishockey är att den ökar din synlighet och exponering. Att spela collegetishockey kan locka till sig uppmärksamhet från scouter, tränare och chefer från professionella lag som letar efter nya spelare. Du kan också delta i uppvisningar, läger och kliniker för att visa upp dina färdigheter för potentiella arbetsgivare.
      <br />
      <br />
      En annan fördel med collegetishockey är att den erbjuder flexibilitet och alternativ. Genom att spela collegetishockey kan du skjuta upp beslutet om din framtid. Du kan välja att övergå till ett professionellt lag efter ditt första, andra, tredje eller fjärde år, beroende på din situation och preferens. Du kan också välja att avsluta dina studier innan du blir professionell.
      <br />
      <br />
      Collegetishockey har producerat många spelare som har lyckats i den professionella ishockeyvärlden. Några av dessa spelare inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Jack Eichel: Han spelade en säsong för Boston University i Hockey East-konferensen i Division I. Han vann Hobey Baker Award som årets bästa spelare 2015. Han valdes som nummer två totalt i NHL-draften 2015 av Buffalo Sabres, där han blev kapten och stjärnspelare. År 2023 vann han Stanley Cup med Vegas Golden Knights.
        </li>
        <li>
          Cale Makar: Han spelade två säsonger för UMass Amherst i Hockey East-konferensen i Division I. Han vann också Hobey Baker Award som årets bästa spelare 2019. Han valdes som nummer fyra totalt i NHL-draften 2017 av Colorado Avalanche, där han för närvarande spelar som en av ligans bästa backar.
        </li>
        <li>
          Hilary Knight: Hilary Knight spelade fyra säsonger för University of Wisconsin i WCHA-konferensen i Division I. Hon var en mycket talangfull spelare och nominerades som en av de tio bästa finalisterna för Patty Kazmaier Memorial Award 2009, 2011 och 2012. Även om hon inte vann priset, var hon två gånger First Team All-American. Knight spelade senare för Boston Pride i NWHL (National Women’s Hockey League) och det amerikanska landslaget, med vilket hon vann två olympiska silvermedaljer och flera världsmästerskap.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dessa spelare är bara några exempel på de många som har förvandlat sina collegetishockeykarriärer till professionella karriärer. Collegetishockey är en beprövad väg till professionalism för många spelare.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 NHL-draften och Andra Möjligheter
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      NHL (National Hockey League) är den främsta professionella ishockeyligan i världen. Det är drömmen för många unga spelare att en dag spela i NHL. Ett av sätten att uppnå det är genom NHL-draften, den årliga händelsen där NHL-lag väljer nya spelare.
      <br />
      <br />
      NHL-draften är ett spännande och betydelsefullt ögonblick för många collegetishockeyspelare. Det är en möjlighet att bli vald av ett NHL-lag, som erbjuder dig ett kontrakt för att spela för dem. Det erkänner också din talang, potential och hårda arbete.
      <br />
      <br />
      NHL-draften består av sju rundor där varje NHL-lag turas om att välja en spelare. Urvalsordningen bestäms genom en lotteri, där lag som hade sämst resultat föregående säsong har bättre chanser att välja först. NHL-draften äger vanligtvis rum i juni eller juli efter avslutningen av collegetishockeysäsongen.
      <br />
      <br />
      För att vara berättigad till NHL-draften måste du uppfylla vissa kriterier. Du måste vara en nordamerikansk spelare mellan 18 och 20 år eller en internationell spelare mellan 18 och 21 år. Du får inte tidigare ha blivit draftad av ett NHL-lag eller ha gett upp dina rättigheter.
      <br />
      <br />
      Att bli draftad av ett NHL-lag innebär inte att du omedelbart måste spela för dem. Du kan välja att återvända till ditt collegeteam och slutföra dina studier samtidigt som du behåller rättigheterna för NHL-laget. Du kan också välja att ansluta dig till NHL-laget eller deras farmarlag, där du kan fortsätta utvecklas och träna.
      <br />
      <br />
      NHL-draften är dock inte det enda sättet att nå NHL. Det finns andra möjligheter, som fri agentur. Detta är när du som spelare inte har ett kontrakt med ett NHL-lag och är fri att förhandla med vilket lag du vill. Detta kan hända om du inte blev draftad eller om ditt kontrakt med ett NHL-lag löper ut eller avslutas. Som fri agent kan du försöka säkra ett kontrakt med ett NHL-lag som är intresserat av dig.
      <br />
      <br />
      NHL är dock inte den enda professionella ishockeyligan i världen. Det finns andra ligor där du kan spela som spelare och försörja dig. Några av dessa ligor inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          KHL (Kontinental Hockey League): Detta är den främsta professionella ishockeyligan i Europa och Asien. Den består av 24 lag från åtta länder: Ryssland, Finland, Belarus, Kazakhstan, Kina, Lettland, Slovakien och Tjeckien. Den erbjuder en hög nivå av spel och lön, vilket lockar spelare från olika länder.
        </li>
        <li>
          SHL (Svenska Hockeyligan): Detta är den främsta professionella ishockeyligan i Sverige. Den består av 14 lag som tävlar om det nationella mästerskapet. Den är känd för sitt snabba och tekniska spel och har producerat många spelare som har spelat i NHL.
        </li>
        <li>
          Liiga (Finska Hockeyligan): Detta är den främsta professionella ishockeyligan i Finland. Den består av 15 lag som tävlar om det nationella mästerskapet. Den är känd för sitt fysiska och taktiska spel och har också producerat många spelare som har spelat i NHL.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dessa ligor är bara några exempel på de många ligorna runt om i världen. Det finns många fler ligor i andra länder, inklusive Schweiz, Tyskland, Frankrike, Norge, Danmark, Österrike, Polen, Japan och Australien. Dessa ligor erbjuder olika spelnivåer, löner och livsstilar för spelare.
      <br />
      <br />
      Collegetishockey är därför inte slutet på vägen för många spelare. Istället är det början på en spännande resa in i den professionella världen. Collegetishockey erbjuder många möjligheter och alternativ för spelare som följer sina drömmar.
    </MKTypography>
  </MKBox>
</MKBox>




                                  






<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationella Influenser
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Collegetishockey är en sport som inte bara är amerikansk utan också internationell. Collegetishockey påverkas av närvaron och prestationen av utländska spelare i amerikanska college-tävlingar. Collegetishockey påverkar också möjligheterna och utvecklingen av amerikanska spelare utomlands. I det här kapitlet kommer vi att diskutera den roll och påverkan som internationella spelare har i collegetishockey och möjligheterna för amerikanska spelare att spela internationellt.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Utländska Spelare i Collegetishockey
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Quinnipiac spelare firar ett mål."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Quinnipiac spelare firar ett mål
        </figcaption>
      </figure>
      Collegetishockey är en sport som är öppen för spelare från hela världen. Det finns många utländska spelare som kommer till Amerika för att spela collegetishockey, med olika bakgrunder, kulturer och nationaliteter. Några av dessa spelare inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Daryl Watts: Hon är en kanadensisk ishockeyspelare som spelade för både Boston College och Wisconsin i NCAA Division I i två säsonger. Hon vann Patty Kazmaier Award 2018 som nykomling, vilket gör henne till den första nykomlingen att vinna priset. Watts spelade sedan för Toronto Six i Premier Hockey Federation (PHF), tidigare känt som National Women’s Hockey League (NWHL), och skrev på ett rekordkontrakt med Toronto Six 2023.
        </li>
        <li>
          Rod Brind'Amour: Född i Kanada, spelade han collegetishockey vid Michigan State University innan han gick vidare till NHL. Han hade en mycket framgångsrik professionell karriär, med höjdpunkter inklusive att vinna Stanley Cup.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dessa spelare är bara några exempel på de många utländska spelare som spelar eller har spelat collegetishockey. Det finns många fler spelare från andra länder som Sverige, Ryssland, Tjeckien, Tyskland, Frankrike, Schweiz, Danmark, Japan och Australien.
      <br />
      <br />
      Dessa utländska spelare har en viktig roll och påverkan i collegetishockey. De för med sig sin egen stil, färdigheter och erfarenhet till amerikanska collegetävlingar. De berikar spelets nivå och mångfald. De skapar också mer intresse och uppmärksamhet för collegetishockey i sina egna länder och regioner.
      <br />
      <br />
      Collegetishockey är en sport som gynnas av internationella influenser. Det är en sport som välkomnar och uppskattar spelare från hela världen.
    </MKTypography>
  </MKBox>
</MKBox>






                                  






<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Idrottsstipendier och Framtiden för Idrottare
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrottsstipendier erbjuder många möjligheter för unga idrottare att visa upp sin talang och utveckla sina färdigheter. En av de mest betydelsefulla möjligheterna är att få ett idrottsstipendium för att studera och tävla på collegenivå. I det här kapitlet kommer vi att diskutera vikten av idrottsstipendier och hur Sport Scholarships America kan hjälpa dig att förverkliga din dröm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vikten av Stipendier
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ett idrottsstipendium är ekonomiskt stöd som tillhandahålls av ett universitet till en idrottare för att kombinera studier och idrott. Det kan täcka kostnaderna för undervisning, böcker, boende, måltider och andra utgifter. Dessutom kan det erbjuda fördelar som akademisk handledning, sjukvård, träningsanläggningar och utrustning.
      <br />
      <br />
      Att få ett idrottsstipendium är konkurrenskraftigt, med många faktorer som spelar en roll i tilldelningsprocessen, inklusive akademiska prestationer, idrottsprestationer, personlighet, motivation, ledarskap och anpassningsförmåga. Därför är det viktigt att förbereda sig väl och skilja sig från konkurrensen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hur Sport Scholarships America Kan Hjälpa
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotyp för Sport Scholarships America, med sportutrustning och en studentmössa, som representerar fokus på idrottsstipendier för idrottare."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotyp för Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America är en organisation specialiserad på att vägleda unga idrottstalanger till idrottsstipendier. Med passionerade och erfarna experter stöder Sport Scholarships America idrottare i varje steg av resan, från initial orientering till slutlig placering. Deras tjänster inkluderar:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intagningsintervju för att diskutera idrottarens önskemål, möjligheter och förväntningar.</li>
        <li>En professionell utvärdering av idrottarens akademiska och idrottsliga nivå.</li>
        <li>Ett omfattande nätverk av kontakter med tränare, universitet och högskolor.</li>
        <li>Effektiv marknadsföring av idrottarens profil och videor till potentiella tränare.</li>
        <li>Expertrådgivning om de bästa alternativen baserat på idrottarens preferenser, mål och budget.</li>
        <li>Förhandling om de bästa stipendieerbjudandena för idrottaren.</li>
        <li>Hjälp med att ansöka om nödvändiga dokument, såsom visum, betyg, examensbevis och antagningsprov.</li>
        <li>Förberedelse för idrottarens liv under stipendieperioden, inklusive kultur, språk, regler och tips.</li>
        <li>Uppföljning och uppföljning under stipendieperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vill du utforska möjligheter till stipendier och uppnå dina idrottsliga och akademiska mål? <Link to="/sv/Tjänster-Produkter">Se det fullständiga utbudet</Link> av tjänster som erbjuds av Sport Scholarships America och boka en konsultation idag.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;