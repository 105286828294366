/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Waterpol1.jpeg";
import Image2 from "assets/images/Sports_Images/Waterpolo2.jpeg";
import Image3 from "assets/images/Sports_Images/Waterpolo3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Borse di Studio per il Water Polo in America: Come Studiare e Giocare nella Terra delle Stelle e Strisce', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capitolo 1: Storia e Sviluppo della Pallanuoto in America', isSubheader: false },
    { id: '1.1', title: '1.1 Anni Iniziali', isSubheader: true },
    { id: '1.2', title: '1.2 Crescita e Professionalizzazione', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capitolo 2: La Pallanuoto Universitaria Oggi', isSubheader: false },
    { id: '2.1', title: '2.1 Principali Competizioni e Conferenze', isSubheader: true },
    { id: '2.2', title: '2.2 Programmi Top e Squadre Prestigiose', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capitolo 3: Dal College ai Professionisti', isSubheader: false },
    { id: '3.1', title: '3.1 Il Percorso verso il Professionismo', isSubheader: true },
    { id: '3.2', title: '3.2 Leghe Professionistiche', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capitolo 4: Influenze e Competizioni Internazionali', isSubheader: false },
    { id: '4.1', title: '4.1 Influenze Straniere', isSubheader: true },
    { id: '4.2', title: '4.2 Gli Americani nelle Competizioni Internazionali', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capitolo 5: Borse di Studio Sportive e il Futuro degli Atleti', isSubheader: false },
    { id: '5.1', title: '5.1 L\'Importanza delle Borse di Studio', isSubheader: true },
    { id: '5.2', title: '5.2 Come Sport Scholarships America Può Aiutare', isSubheader: true },
  ];
  
  // Other components remain unchanged.
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indice
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Nascondi</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostra</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>























          <MKBox id="intro" mb={3}>
    <MKTypography variant="h1" className="h1-as-h3" mb={2}>
        Borse di Studio per il Water Polo in America: Come Studiare e Giocare nella Terra delle Stelle e Strisce
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
            <img
                src={Image1}
                alt="Stadio pieno di tifosi in attesa di una partita di pallanuoto universitaria."
                style={{
                    maxWidth: "100%",
                    border: "2px solid #fff",
                    boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                    cursor: "pointer",
                    outline: "none",
                }}
                onClick={() => openModal1(Image1)}
            />
            <figcaption style={{ 
                textAlign: 'left', 
                fontWeight: 'bold', 
                fontSize: '12px', 
                wordWrap: 'break-word', 
            }}>
                Calma prima di una partita
            </figcaption>
        </figure>
        La pallanuoto ha avuto origine in Europa alla fine del XIX secolo come una forma di rugby in acqua e ha rapidamente guadagnato popolarità in vari paesi, compresi gli Stati Uniti. La prima partita ufficiale in America è stata giocata nel 1888 tra due club a New York. Da allora, la pallanuoto si è sviluppata notevolmente in America, sia a livello professionale che universitario.
        <br />
        <br />
        La pallanuoto universitaria è uno dei principali fattori nello sviluppo dei giocatori di pallanuoto in America. Molti giocatori iniziano la loro carriera alle scuole superiori e poi passano al college, dove hanno l'opportunità di competere contro le migliori squadre e giocatori del paese. La pallanuoto universitaria offre anche un'eccellente istruzione accademica, permettendo ai giocatori di combinare la loro passione per lo sport con i loro obiettivi educativi.
        <br />
        <br />
        Ma come puoi, come studente olandese, studiare e giocare in America? È qui che può aiutare Sportbeursamerika.nl. Sportbeursamerika.nl è un'organizzazione specializzata nell'assistere gli studenti nell'ottenere borse di studio per la pallanuoto in America. Sportbeursamerika.nl dispone di un team di esperti che possono guidarti attraverso ogni passo del processo, dalla ricerca dell'università giusta alla gestione di tutte le questioni pratiche.
        <br />
        <br />
        Se sei interessato a ottenere una borsa di studio per la pallanuoto in America, continua a leggere per saperne di più su questa eccitante opportunità.
    </MKTypography>
    {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Capitolo 1: Storia e Sviluppo della Pallanuoto in America
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        La pallanuoto ha una lunga e ricca storia in America. È uno dei più antichi sport olimpici, giocato per la prima volta ai Giochi Olimpici del 1900 a Parigi, dove l'America ha anche partecipato. Da allora, l'America ha gareggiato in ogni competizione olimpica di pallanuoto, tranne nel 1976 e nel 1980 quando si è ritirata in segno di protesta contro le situazioni politiche in Sudafrica e nell'Unione Sovietica, rispettivamente.
    </MKTypography>

    <MKBox id="1.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            1.1 Anni Iniziali
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            I primi club di pallanuoto in America sono stati fondati a New York e Boston alla fine del XIX secolo. Giocavano secondo le regole inglesi, che erano molto più rude e violento rispetto alle regole moderne. Lo sport è diventato rapidamente popolare tra gli immigrati dall'Europa, soprattutto dall'Irlanda e dalla Scozia. Il primo campionato nazionale si è tenuto nel 1890 e è stato vinto dal Sydenham Swimmers Club.
            <br />
            <br />
            Le prime squadre universitarie sono state formate all'inizio del XX secolo, specialmente sulla East Coast. Le università della Ivy League come Harvard, Yale e Princeton hanno dominato lo sport fino agli anni '20. La prima competizione intercollegiale è stata organizzata nel 1912 e è stata vinta da Princeton.
        </MKTypography>
    </MKBox>
    <MKBox id="1.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            1.2 Crescita e Professionalizzazione
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Negli anni '20, la pallanuoto ha iniziato a diffondersi in altre parti del paese, specialmente in California. Lì si è sviluppato un nuovo stile di gioco, incentrato sulla velocità, l'agilità e il tiro anziché sul contatto fisico. Le squadre della California sono rapidamente diventate le migliori del paese, vincendo la maggior parte dei titoli nazionali e intercollegiali.
            <br />
            <br />
            Negli anni '30, sono emerse le prime leghe professionali in America, come l'American Water Polo League e la Pacific Coast Water Polo League. Queste leghe hanno attirato molti spettatori e l'attenzione dei media, alzando il livello e la popolarità dello sport. Molti giocatori professionisti erano anche giocatori universitari o laureati che hanno continuato la loro carriera dopo gli studi.
            <br />
            <br />
            Una delle figure chiave nella storia della pallanuoto americana è stato James "Jimmy" Smith, considerato il "padre della pallanuoto moderna". Era giocatore, allenatore, arbitro, organizzatore e promotore dello sport. Ha giocato per vari club e università, tra cui UCLA, dove in seguito è diventato allenatore. Ha guidato la squadra americana verso due medaglie di bronzo olimpiche nel 1924 e nel 1932. È stato anche uno dei fondatori della International Swimming Hall of Fame, dove è stato inserito nel 1965.
        </MKTypography>
    </MKBox>
</MKBox>



                                              





                                  








<MKBox id="Chapter 2" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Capitolo 2: La Pallanuoto Universitaria Oggi
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        La pallanuoto universitaria è uno degli sport più prestigiosi e competitivi in America. Offre un'opportunità unica agli studenti di combinare le loro ambizioni accademiche e atletiche e di competere contro i migliori giocatori del paese. La pallanuoto universitaria è anche una fonte significativa di talento per la nazionale e le leghe professionistiche.
    </MKTypography>

    <MKBox id="2.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.1 Principali Competizioni e Conferenze
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image2}
                    alt="Azione in acqua: giocatore tira in porta durante una partita di pallanuoto."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal2(Image2)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Giocatori in azione
                </figcaption>
            </figure>
            La pallanuoto universitaria è regolamentata dalla National Collegiate Athletic Association (NCAA), che divide lo sport in tre divisioni in base al livello e al numero di borse di studio disponibili. La Division I è la divisione più alta, composta da circa 30 squadre, principalmente della California. La Division II e la Division III hanno ciascuna circa 20 squadre, più distribuite nel paese.
            <br />
            <br />
            Ogni anno si tiene un campionato nazionale per ogni divisione, con le migliori squadre di ciascuna conferenza che competono per il titolo. Una conferenza è un gruppo di università che sono geograficamente o storicamente connesse e competono regolarmente tra loro. Le principali conferenze per la pallanuoto includono:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    La Mountain Pacific Sports Federation (MPSF), che comprende otto squadre della California, tra cui UCLA, Stanford, USC e Berkeley. Questa conferenza è considerata la più forte e prestigiosa del paese e ha vinto il maggior numero di titoli nazionali.
                </li>
                <li>
                    La Western Water Polo Association (WWPA), che comprende nove squadre della California, del Colorado, dello Utah e delle Hawaii. Questa conferenza è la seconda più forte del paese e ha vinto anche alcuni titoli nazionali.
                </li>
                <li>
                    La Collegiate Water Polo Association (CWPA), che comprende 18 squadre della East Coast, tra cui Harvard, Princeton, Brown e Navy. Questa conferenza è la terza più forte del paese e ha occasionalmente vinto un titolo nazionale.
                </li>
                <li>
                    La Southern California Intercollegiate Athletic Conference (SCIAC), che comprende nove squadre della Southern California, tra cui Pomona-Pitzer, Claremont-Mudd-Scripps e Occidental. Questa conferenza è la quarta più forte del paese e compete principalmente nella Division III.
                </li>
            </ul>
        </MKTypography>
    </MKBox>
    <MKBox id="2.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.2 Programmi Top e Squadre Prestigiose
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            La pallanuoto universitaria ha diversi programmi leader noti per le loro eccellenti prestazioni, sia nello sport che nell'ambito accademico. Questi programmi attraggono molti giocatori talentuosi che beneficiano delle strutture, dell'allenamento e dell'istruzione di alta qualità che offrono. Alcuni di questi programmi sono:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    La University of California, Los Angeles (UCLA) è nota per il suo eccezionale programma di pallanuoto, che si colloca tra i più vincenti della storia. Con un impressionante numero di titoli nazionali sia per le squadre maschili che femminili, il programma occupa un posto di rilievo nello sport. Le partite si giocano al Spieker Aquatics Center, con una capacità di 2.500 spettatori. La squadra di UCLA è famosa per il suo gioco veloce e dinamico e ha prodotto un numero significativo di medagliati olimpici.
                </li>
                <li>
                    Anche la Stanford University vanta un prestigioso programma di pallanuoto con numerosi titoli nazionali sia per le squadre maschili che femminili. Le squadre giocano le loro partite casalinghe all'Avery Aquatic Center, che può ospitare 2.530 spettatori. Le squadre di pallanuoto di Stanford sono note per le loro abilità tecniche e tattiche e hanno contribuito allo sviluppo di molti medagliati olimpici.
                </li>
                <li>
                    La University of Southern California (USC) ha anche un solido programma di pallanuoto con multiple vittorie di campionato nazionale sia per gli uomini che per le donne. La loro base è l'Uytengsu Aquatics Center, con una capacità di 2.500 spettatori. La squadra di USC è caratterizzata da uno stile di gioco fisico e aggressivo e ha anche formato un numero significativo di medagliati olimpici.
                </li>
            </ul>
        </MKTypography>
    </MKBox>
</MKBox>



                                              






                                  








<MKBox id="Chapter 3" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Capitolo 3: Dal College ai Professionisti
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        La pallanuoto universitaria non è solo un ottimo modo per studiare e giocare in America, ma anche un possibile trampolino di lancio per una carriera professionale. Molti giocatori universitari passano a giocare in leghe professionistiche, sia in America che all'estero, dopo essersi laureati. Alcuni di loro vengono anche selezionati per la nazionale, che partecipa a tornei internazionali come le Olimpiadi, i Campionati del Mondo e la World League.
    </MKTypography>

    <MKBox id="3.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.1 Il Percorso verso il Professionismo
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Anche se la pallanuoto è uno sport popolare e competitivo in America, non è ancora così commerciale e redditizio come alcuni altri sport come il basket, il football o il baseball. Pertanto, non ci sono molte leghe professionistiche o squadre in America, e i salari e i premi in denaro sono relativamente bassi. La maggior parte dei giocatori professionisti ha anche un altro lavoro o una fonte di reddito accanto alla loro carriera nella pallanuoto.
            <br />
            <br />
            La principale lega professionistica in America è la National Water Polo League (NWPL), che è stata fondata nel 2018 come successore della American Water Polo League (AWPL). La NWPL è composta da otto squadre, ognuna composta da 15 giocatori. Le squadre disputano una stagione regolare di 14 partite, seguita da un torneo playoff per determinare il campione. Il campione attuale è il Los Angeles Water Polo Club, che ha vinto il titolo nel 2022.
            <br />
            <br />
            La maggior parte dei giocatori nella NWPL sono ex giocatori universitari o laureati che continuano la loro carriera dopo gli studi. Alcuni di loro fanno anche parte della nazionale, che si allena e gioca regolarmente presso il Centro di Allenamento Olimpico a Colorado Springs. La nazionale è allenata da Dejan Udovicic, ex giocatore e allenatore serbo che è al timone dal 2013.
            <br />
            <br />
            Uno degli ex giocatori universitari di maggior successo della pallanuoto è Tony Azevedo, considerato uno dei più grandi giocatori di tutti i tempi. Ha giocato per la Stanford University, dove è stato nominato Giocatore dell'Anno della NCAA quattro volte. Ha anche giocato per diverse squadre professionistiche in Europa e America, tra cui Brescia (Italia), Olympiacos (Grecia) e Long Beach Shore (America). Ha anche partecipato a cinque Olimpiadi, vincendo una medaglia d'argento nel 2008. È spesso chiamato "Il Salvatore" perché segnava spesso gol decisivi per la sua squadra.
        </MKTypography>
    </MKBox>
    <MKBox id="3.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.2 Leghe Professionistiche
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Oltre alla NWPL, ci sono altre leghe professionistiche in America, come la Major League Water Polo (MLWP) e la Premier Water Polo League (PWPL). Tuttavia, queste leghe sono meno consolidate e meno popolari rispetto alla NWPL e hanno meno squadre e giocatori. Inoltre, non sono riconosciute da USA Water Polo, la federazione nazionale responsabile dell'organizzazione e della regolamentazione della pallanuoto in America.
            <br />
            <br />
            Molti giocatori americani, quindi, scelgono di continuare la loro carriera professionale all'estero, dove ci sono più opportunità e condizioni migliori. L'Europa è la destinazione principale per i giocatori di pallanuoto americani perché il continente vanta alcune delle leghe più forti e prestigiose al mondo. Alcune di queste leghe includono:
        </MKTypography>

        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    La LEN Champions League, considerata la principale competizione per club in Europa. È composta da 16 squadre di vari paesi che competono per il titolo europeo. Il campione attuale è il Pro Recco (Italia), che ha vinto il titolo nel 2021.
                </li>
                <li>
                    La LEN Euro Cup, considerata la seconda competizione per club in Europa. Comprende 32 squadre di diversi paesi che competono per un posto nella Champions League. Il campione attuale è l'Orvosegyetem SC (Ungheria), che ha vinto il titolo nel 2021.
                </li>
                <li>
                    Le leghe nazionali organizzate dalle rispettive federazioni di ciascun paese. Alcune delle leghe nazionali più forti e popolari includono Serie A1 (Italia), Liga Premaat (Spagna), A1 Ethniki (Grecia) e OB I (Ungheria).
                </li>
            </ul>
        </MKTypography>
    </MKBox>
</MKBox>

                                              






                                  





<MKBox id="Chapter 4" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Capitolo 4: Influenze e Competizioni Internazionali
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        La pallanuoto è uno sport globale giocato e seguito da milioni di persone in tutto il mondo. È anche uno sport che si evolve e si innova costantemente, grazie all'influenza di varie culture, stili e tradizioni. Anche la pallanuoto americana non fa eccezione, poiché ha imparato e beneficiato dalla pallanuoto internazionale, sia a livello di club che nazionale.
    </MKTypography>

    <MKBox id="4.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.1 Influenze Straniere
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Come abbiamo visto, la pallanuoto ha avuto origine in Europa e si è diffusa in altri continenti, inclusa l'America. Pertanto, la pallanuoto europea ha avuto un'influenza significativa sulla pallanuoto americana, specialmente in termini di tecnica, tattica e strategia. Molti giocatori e allenatori americani hanno migliorato le proprie abilità e conoscenze giocando o allenandosi in Europa o apprendendo da giocatori e allenatori europei.
            <br />
            <br />
            Uno dei paesi europei più influenti per la pallanuoto americana è l'Ungheria, considerata la nazione di pallanuoto più vincente al mondo. L'Ungheria ha vinto 15 medaglie d'oro olimpiche, più di qualsiasi altro paese. L'Ungheria è nota per il suo stile di gioco creativo e intelligente, basato su un forte controllo palla, passaggi rapidi e tiri precisi. Molti giocatori e allenatori ungheresi hanno lasciato il segno sulla pallanuoto americana, come Tibor Benedek, Zoltan Szecsi e Attila Banhidy.
            <br />
            <br />
            Un altro paese europeo influente per la pallanuoto americana è la Serbia, che è anche una delle principali nazioni di pallanuoto a livello globale. La Serbia ha vinto 7 medaglie d'oro olimpiche, con l'ultima nel 2016. La Serbia è nota per il suo stile di gioco fisico e aggressivo, basato su una forte difesa, veloci contropiedi e tiri potenti. Molti giocatori e allenatori serbi hanno condiviso la loro esperienza e competenza con la pallanuoto americana, tra cui Dejan Udovicic, Andrija Prlainovic e Filip Filipovic.
        </MKTypography>
    </MKBox>
    <MKBox id="4.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.2 Gli Americani nelle Competizioni Internazionali
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Oltre ad apprendere dalla pallanuoto internazionale, la pallanuoto americana ha contribuito significativamente alla pallanuoto internazionale, specialmente in termini di prestazioni, innovazione e promozione. Molti giocatori e allenatori americani hanno partecipato a competizioni internazionali, sia a livello di club che nazionale, dove hanno mostrato i loro talenti e migliorato la loro reputazione.
            <br />
            <br />
            Una delle competizioni internazionali di pallanuoto più importanti sono i Giochi Olimpici, che si tengono ogni quattro anni. La squadra americana ha sempre partecipato a questa competizione, tranne nel 1976 e nel 1980. La squadra americana ha vinto un totale di 12 medaglie olimpiche: 3 d'oro, 4 d'argento e 5 di bronzo. La medaglia più recente è stata una medaglia d'argento per gli uomini nel 2008. La squadra americana è attualmente classificata al quarto posto al mondo.
            <br />
            <br />
            Un'altra competizione internazionale significativa per la pallanuoto è il Campionato del Mondo, che si tiene ogni due anni. La squadra americana ha anche partecipato costantemente a questa competizione fin dalla sua prima edizione nel 1973. La squadra americana ha vinto un totale di 9 medaglie ai Campionati del Mondo: 2 d'oro, 3 d'argento e 4 di bronzo. La medaglia più recente è stata una medaglia di bronzo per le donne nel 2019. La squadra americana è attualmente classificata al terzo posto al mondo.
            <br />
            <br />
            Una terza importante competizione internazionale di pallanuoto è la World League, che si tiene annualmente. La squadra americana ha anche partecipato costantemente a questa competizione fin dalla sua prima edizione nel 2002. La squadra americana ha vinto un totale di 10 medaglie nella World League: 3 d'oro, 4 d'argento e 3 di bronzo. La medaglia più recente è stata una medaglia d'oro per le donne nel 2021. La squadra americana è attualmente classificata al secondo posto al mondo.
        </MKTypography>
    </MKBox>
</MKBox>

         






                                  







<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 5: Borse di Studio Sportive e il Futuro degli Atleti
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le borse di studio sportive offrono numerose opportunità ai giovani atleti di mostrare il loro talento e sviluppare le loro abilità. Una delle opportunità più significative è ottenere una borsa di studio sportiva per studiare e competere a livello universitario. In questo capitolo, discuteremo dell'importanza delle borse di studio sportive e di come Sport Scholarships America può aiutarti a realizzare il tuo sogno.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 L'Importanza delle Borse di Studio
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Una borsa di studio sportiva è un sostegno finanziario fornito da un'università a uno studente-atleta per combinare studio e sport. Può coprire i costi di iscrizione, libri, alloggio, pasti e altre spese. Inoltre, può offrire vantaggi come il tutorato accademico, l'assistenza medica, le strutture di allenamento e l'attrezzatura.
      <br />
      <br />
      Ottenere una borsa di studio sportiva è competitivo, con molti fattori che influenzano il processo di assegnazione, tra cui risultati accademici, prestazioni sportive, personalità, motivazione, leadership e adattabilità. È quindi importante prepararsi bene e distinguersi dalla concorrenza.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Come Sport Scholarships America Può Aiutare
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo di Sport Scholarships America, raffigurante attrezzature sportive e una toga, simbolo del focus sulle borse di studio sportive per gli studenti-atleti."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo di Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America è un'organizzazione specializzata nell'orientamento dei giovani talenti sportivi verso borse di studio sportive. Con esperti appassionati ed esperti, Sport Scholarships America supporta gli studenti-atleti in ogni fase del percorso, dall'orientamento iniziale alla collocazione finale. I loro servizi includono:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Un'intervista personale per discutere i desideri, le possibilità e le aspettative dello studente-atleta.</li>
        <li>Una valutazione professionale del livello accademico e sportivo dello studente-atleta.</li>
        <li>Una vasta rete di contatti con allenatori, università e college.</li>
        <li>Promozione efficace del profilo e dei video dello studente-atleta presso allenatori potenziali.</li>
        <li>Consulenza esperta sulle migliori opzioni in base alle preferenze, agli obiettivi e al budget dello studente-atleta.</li>
        <li>Negoziazione delle migliori offerte di borse di studio per conto dello studente-atleta.</li>
        <li>Assistenza nella richiesta dei documenti necessari, come visto, trascrizione, diploma e test di ammissione.</li>
        <li>Preparazione alla vita dello studente-atleta durante la borsa di studio, compresa cultura, lingua, regole e consigli.</li>
        <li>Assistenza e monitoraggio durante il periodo di borsa di studio.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vuoi esplorare le opportunità di borse di studio e raggiungere i tuoi obiettivi sportivi e accademici? <Link to="/it/Servizi-Prodotti">Consulta la gamma completa</Link> dei servizi offerti da Sport Scholarships America e prenota una consultazione oggi stesso.
    </MKTypography>
  </MKBox>
</MKBox>

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;