import Alabama from "assets/images/Staten_Images/Alabama.webp"
import Florida from "assets/images/Staten_Images/Florida.jpeg"
import Connecticut from "assets/images/Staten_Images/Connecticut.jpeg"
import Kentucky from "assets/images/Staten_Images/Kentucky.webp"
import Michigan from "assets/images/Staten_Images/Michigan.jpeg"
import North_Carolina from "assets/images/Staten_Images/North_Carolina.jpg"
import Texas from "assets/images/Staten_Images/Texas.jpeg"



const states = [
  {
    "name": "Alabama",
    "image": Alabama,
    "imageDescription": "Alabama vs Auburn ist eine der größten College-Football-Rivalitäten.",

    "description": [
      "Collegesport ist ein integraler Bestandteil der Kultur in Alabama, mit einem besonderen Schwerpunkt auf American Football. Die University of Alabama (UA) in Tuscaloosa und die Auburn University in Auburn dominieren die Sportlandschaft im Bundesstaat.",
      "Das Football-Team der UA, die Crimson Tide, hat eine reiche Geschichte und hat zahlreiche nationale Meisterschaften gewonnen. Heimspiele im immensen Bryant-Denny Stadium können bis zu 100.000 Fans anlocken. Die Leidenschaft der Fans ist enorm, und der Ruf 'Roll Tide' ist in ganz Alabama bekannt.",
      "Auf der anderen Seite haben wir die Auburn University mit ihrem Football-Team, den Tigers. Sie bilden zusammen mit der UA den Iron Bowl, eine der intensivsten Rivalitäten im Collegesport. Dieser jährliche Zusammenstoß dreht den gesamten Staat auf den Kopf und garantiert Aufregung und Spektakel.",
      "Neben Football hat Alabama starke Programme in Basketball, Baseball und anderen Sportarten, unterstützt von einem starken Gemeinschaftsgeist. In Alabama sind Sportarten mehr als nur Zeitvertreib; sie sind ein wesentlicher Bestandteil des Lebensstils und der Identität des Staates."
    ]
  },

  {
    "name": "Alaska",
    "description": [
      "Anders als in vielen anderen US-Bundesstaaten spielt der Collegesport in Alaska eine bescheidenere Rolle, hauptsächlich aufgrund seiner abgelegenen Lage und des einzigartigen Klimas. Hier liegt der Schwerpunkt mehr auf Wintersportarten und Outdoor-Aktivitäten als auf traditionellen amerikanischen Sportarten wie Football und Baseball.",
      "Die University of Alaska Anchorage (UAA) und die University of Alaska Fairbanks (UAF) sind die Hauptinstitutionen mit aktiven Sportprogrammen. Die UAA hat ein starkes Ski- und Eiskunstlaufteam, während die UAF für ihr Eishockeyteam, die Nanooks, bekannt ist, die eine treue lokale Anhängerschaft haben.",
      "In Alaska geht es beim Collegesport mehr um das Erlebnis und die Gemeinschaft als um Großveranstaltungen. Spiele bieten der Gemeinschaft die Möglichkeit, zusammenzukommen, die Athleten anzufeuern und die einzigartige Sportkultur des Staates zu feiern.",
      "Trotz des kleineren Maßstabs haben Collegesportarten in Alaska ihren eigenen Charme und bieten eine herzliche Gemeinschaft in der kalten Umgebung des Staates."
    ]
  },

  {
    "name": "Arizona",
    "description": [
      "Collegesport ist in Arizona immens beliebt, mit einer reichen Tradition sowohl im American Football als auch im Basketball. Die Arizona State University (ASU) in Tempe und die University of Arizona (UA) in Tucson sind die Säulen der Sportlandschaft des Staates.",
      "ASUs Football-Team, die Sun Devils, genießt eine leidenschaftliche Anhängerschaft, während ihr Basketballteam regelmäßig in NCAA-Turnieren glänzt. UA ist mit ihren Wildcats bekannt für ihr erfolgreiches Basketballprogramm und die intensive Rivalität mit ASU, die im jährlichen 'Duel in the Desert' Football-Spiel gipfelt.",
      "Neben den großen Sportarten excelleiert Arizona auch in Baseball, Softball und Golf, wobei Universitätsteams oft im nationalen Rampenlicht stehen. Die Unterstützung der Gemeinschaft ist überwältigend, und Sportveranstaltungen gelten als bedeutende gesellschaftliche Ereignisse.",
      "Collegesport in Arizona verkörpert den Geist des Wettbewerbs und der Gemeinschaft, wobei intensive Rivalität und Fanleidenschaft zu einer lebendigen und lebhaften Sportkultur beitragen."
    ]
  },

  {
    "name": "Arkansas",
    "description": [
      "In Arkansas spielen Collegesportarten eine zentrale Rolle im Gemeinschaftsleben, wobei ein besonderer Schwerpunkt auf American Football und Basketball liegt. Die University of Arkansas in Fayetteville ist eine Macht im Staat, bekannt für ihre Razorbacks.",
      "Das Football-Team der Razorbacks genießt regionale und nationale Anerkennung, mit einer engagierten Fangemeinde, die jedes Spiel leidenschaftlich verfolgt. Ihre Heimspiele im beeindruckenden Razorback Stadium sind ein Spektakel, bei dem der Ruf 'Woo Pig Sooie' von Zehntausenden von Fans widerhallt.",
      "Basketball ist auch ein wesentlicher Bestandteil der Sportkultur in Arkansas, wobei die Razorbacks regelmäßig an der NCAA-Turnieren teilnehmen. Die Spannung und Energie in der Bud Walton Arena während der Basketballsaison sind spürbar.",
      "Über die großen Sportarten hinaus haben die Razorbacks auch starke Programme in Leichtathletik, Leichtathletik und Softball. Diese Vielfalt an Sportarten bereichert die Sportkultur in Arkansas, wo Collegesport eine Quelle des Stolzes, des Gemeinschaftssinns und der Unterhaltung ist."
    ]
  },
  
  {
    "name": "California",
    "description": [
      "Collegesport in Kalifornien ist vielfältig und hochkompetitiv, mit zahlreichen Universitäten, die in verschiedenen Sportarten herausragend sind. Vom American Football bis zum Schwimmen hat der Staat alles zu bieten.",
      "Die Pac-12-Konferenz mit prominenten Schulen wie der University of California, Los Angeles (UCLA) und der University of Southern California (USC) ist für ihr hohes sportliches Niveau bekannt. Das Basketballteam der UCLA hat eine reiche Geschichte, während USC traditionell im Football hervorsticht.",
      "Neben den bekannten Teams gibt es auch kleinere Schulen mit beeindruckenden Sportprogrammen, wie die Stanford University, bekannt für ihre Exzellenz im Schwimmen und in der Leichtathletik. Diese Vielfalt schafft eine dynamische und aufregende Sportumgebung im Staat.",
      "Das angenehme Klima und die ausgezeichneten Sporteinrichtungen Kaliforniens fördern eine aktive Beteiligung und Begeisterung für den Collegesport. Ob es sich um ein Footballspiel in einem vollbesetzten Stadion oder einen lokalen Schwimmwettbewerb handelt, Sportarten sind ein integraler Bestandteil des kalifornischen Lebensstils."
    ]
  },
  
  {
    "name": "Colorado",
    "description": [
      "Collegesport in Colorado genießt eine lebendige und enthusiastische Anhängerschaft, teilweise aufgrund der beeindruckenden natürlichen Umgebung und des aktiven Outdoor-Lifestyles. Der Staat hat eine reiche Tradition im American Football, Basketball und Wintersport.",
      "Die University of Colorado Boulder (CU) und die Colorado State University (CSU) sind prominente Akteure in der Collegesport-Szene, wobei das jährliche 'Rocky Mountain Showdown' Footballspiel als Höhepunkt ihrer Rivalität gilt.",
      "CUs Football-Team, die Buffaloes, hat eine leidenschaftliche Fangemeinde, während ihr Skiteam bei nationalen Wettbewerben glänzt und von den ausgezeichneten Wintersporteinrichtungen Colorados profitiert. CSU ist auch in verschiedenen Sportarten wettbewerbsfähig, mit einer starken Präsenz im American Football und Basketball.",
      "Neben den größeren Universitäten haben auch kleinere Hochschulen in Colorado aktive Sportprogramme, die zur reichen und vielfältigen Sportkultur im Staat beitragen. Der Collegesport in Colorado steht für Gemeinschaft, Durchhaltevermögen und die Feier des Sports in all seinen Formen."
    ]
  },
  
  {
    "name": "Connecticut",
    "image": Connecticut,
    "imageDescription": "Das Damen-Basketballteam der UConn ist eines der dominantesten Collegesportteams aller Zeiten.",
  
    "description": [
      "Collegesport in Connecticut wird von Basketball dominiert, mit einem besonderen Schwerpunkt auf den erfolgreichen Programmen der University of Connecticut (UConn). Der Staat hat eine reiche Tradition und eine leidenschaftliche Fangemeinde, die tief in den Collegesport investiert ist.",
      "Das Damen-Basketballteam der UConn ist national bekannt und hat mehrere NCAA-Meisterschaften gewonnen, was sie zu einer Macht im Sport macht. Auch das Herrenteam hat beeindruckende Leistungen erbracht, was Basketball zu einem zentralen Bestandteil der Sportkultur in Connecticut macht.",
      "Während Basketball vorherrscht, gibt es auch starke Programme in anderen Sportarten wie Eishockey, Football und Lacrosse, wobei verschiedene Universitäten auf nationaler Ebene wettbewerbsfähig sind.",
      "Collegesport in Connecticut verkörpert den Stolz und den Gemeinschaftssinn des Staates, bei dem Sportveranstaltungen eine Gelegenheit für Fans bieten, zusammenzukommen, die Teams anzufeuern und an einer reichen Sporttradition teilzunehmen."
    ]
  },
  
  {
    "name": "Delaware",
    "description": [
      "Trotz seiner geringen Größe hat Delaware eine lebendige Collegesport-Szene mit starker Beteiligung der Gemeinschaft. Die University of Delaware (UD) in Newark steht im Mittelpunkt des Geschehens, wo die Blue Hens in verschiedenen Sportarten herausragend sind.",
      "UDs Football-Team hat eine reiche Geschichte und eine engagierte Fangemeinde, mit aufregenden Heimspielen im Delaware Stadium. Basketball und Lacrosse sind ebenfalls beliebte Sportarten, wobei die Teams regelmäßig um regionale und nationale Anerkennung kämpfen.",
      "Trotz seiner geringen Größe hat Delaware starke Programme in Leichtathletik, Schwimmen und anderen Sportarten, die alle zur dynamischen Sportkultur im Staat beitragen. Der Collegesport hier ist mehr als nur ein Zeitvertreib; er ist ein wichtiger Bestandteil der Identität der Gemeinschaft und fördert eine starke Verbindung zwischen der Universität und der breiteren Gemeinschaft.",
      "In Delaware bringen Collegesportarten die Menschen zusammen, wobei der kleine Maßstab eine eng verbundene und leidenschaftliche Sportgemeinschaft schafft."
    ]
  },
  
  


    {
      "name": "Florida",
      "image": Florida,
      "imageDescription": "Das Ben Hill Griffin Stadium ist die Heimat der Florida Gators.",
  
      "description": [
        "Florida ist ein Hotspot für Collegesport, mit einigen der wettbewerbsfähigsten und leidenschaftlichsten Programmen im Land. American Football und Basketball dominieren die Sportszene, aber es gibt auch Platz für eine Vielzahl anderer Sportarten.",
        "Teams wie die Florida Gators und die Florida State Seminoles sind national anerkannt und haben eine reiche Geschichte sowohl im Football als auch im Basketball. Ihre intensive Rivalität zieht jährlich Tausende von Fans an, und die Spiele sind Highlights der Sportsaison.",
        "Darüber hinaus gibt es auch kleinere Universitäten und Hochschulen mit starken Sportprogrammen, die zur reichen Sportkultur im Staat beitragen. Baseball, Leichtathletik und Schwimmen sind ebenfalls beliebt, und die ausgezeichneten Einrichtungen und das sonnige Klima machen Florida zu einem idealen Ort für College-Athleten.",
        "In Florida sind Collegesportarten mehr als nur Wettbewerb; sie sind eine Lebensweise, eine Quelle des Stolzes und ein wesentlicher Bestandteil der Identität der Gemeinschaft."
      ]
    },
  
    {
      "name": "Georgia",
      "description": [
        "Collegesport in Georgia zeichnet sich durch leidenschaftliche Leidenschaft und tief verwurzelte Rivalitäten aus, wobei American Football als unbestrittener König gilt. Der Staat beheimatet einige der renommiertesten Programme des Landes, bei denen Fans jedes Spiel leidenschaftlich erleben.",
        "Die University of Georgia Bulldogs in Athens und die Georgia Tech Yellow Jackets in Atlanta sind die Hauptakteure, wobei ihre jährliche 'Clean, Old-Fashioned Hate'-Rivalität den Staat fest im Griff hat. Footballspiele, insbesondere im massiven Sanford Stadium der UGA, ziehen Zehntausende von Fans an.",
        "Neben Football excelleiert Georgia auch im Basketball, in der Leichtathletik und im Baseball, wobei talentierte Athleten und engagierte Unterstützer vertreten sind. Die Sportprogramme an kleineren Schulen und Hochschulen tragen zu einer reichen und vielfältigen Sportlandschaft im gesamten Staat bei.",
        "In Georgia ist Sport mehr als nur Wettbewerb; es ist eine Lebensweise, eine Quelle des Gemeinschaftsgefühls und ein unverzichtbarer Bestandteil der regionalen Identität."
      ]
    },


    {
      "name": "Hawaii",
      "description": [
          "College-Sport in Hawaii bietet ein einzigartiges Erlebnis, inspiriert von der Schönheit der Inseln und der starken lokalen Kultur. Trotz der geografischen Isolation sind Sportteams und Fans in Hawaii außergewöhnlich leidenschaftlich und engagiert.",
          "Die University of Hawaii at Manoa spielt eine zentrale Rolle in der College-Sportlandschaft, mit ihrem Football-Team, den Rainbow Warriors, als bedeutendem Mittelpunkt. Ihre Heimspiele im Aloha Stadium schaffen eine lebendige und festliche Atmosphäre mit einem starken Inselgeist.",
          "Neben American Football sind auch Volleyball, Surfen und Kanurennen beliebt und spiegeln die einzigartige Sportkultur und die natürliche Landschaft von Hawaii wider. Die Rainbow Warriors sind auch im Basketball und Baseball wettbewerbsfähig, mit einer Hingabe, die von der gesamten Gemeinschaft geteilt wird.",
          "In Hawaii repräsentieren College-Sportarten den Aloha-Spirit, wobei Teams und Fans die Inselkultur, die Gemeinschaft und den Stolz umarmen und gemeinsam ein einzigartiges und lebendiges Sporterlebnis schaffen."
      ]
  },
  {
      "name": "Idaho",
      "description": [
          "College-Sport in Idaho hat eine starke Tradition, insbesondere im American Football und Basketball. Das Gefühl der Gemeinschaft und die Beteiligung an Sportteams sind bemerkenswert, obwohl der Bundesstaat keine großen Universitäten beherbergt.",
          "Die Boise State University, berühmt für ihr blaues Footballfeld, spielt eine herausragende Rolle in der Sportlandschaft von Idaho. Das Football-Team hat nationale Aufmerksamkeit mit seinem Erfolg und seinem innovativen Spielstil erlangt, und die Heimspiele sind ein Spektakel für sich.",
          "Neben Boise State tragen auch kleinere Schulen und Hochschulen zur reichen Sportkultur bei, mit Programmen in Sportarten wie Baseball, Leichtathletik und Wintersportarten, die von der einzigartigen natürlichen Umgebung Idahos profitieren.",
          "In Idaho sind College-Sportarten mehr als nur ein Zeitvertreib; sie sind eine Lebensweise und ein wesentlicher Bestandteil des Lebensstils und der Identität des Bundesstaates, wobei Gemeinschaften zusammenkommen, um ihre Teams zu unterstützen."
      ]
  },
  {
      "name": "Illinois",
      "description": [
          "Illinois hat eine reiche Geschichte und eine tiefe Liebe für College-Sportarten, mit mächtigen Programmen und engagierten Fans. American Football und Basketball ziehen das ganze Jahr über erhebliche Aufmerksamkeit auf sich, aber es gibt auch eine Vielzahl anderer Sportarten, die gedeihen.",
          "Die University of Illinois Fighting Illini und die Northwestern University Wildcats führen den Weg an, mit intensiven Rivalitäten und wettbewerbsfähigen Ligen, die den Bundesstaat lebendig halten. Memorial Stadium und Ryan Field sind Sporttempel, in denen jedes Spiel ein unvergessliches Erlebnis ist.",
          "Basketball ist ebenfalls prominent, wobei der Bundesstaat regelmäßig Talente hervorbringt, die sowohl national als auch international erfolgreich sind. Die Illinois Fighting Illini haben eine reiche Basketballtradition und sind eine ständige Bedrohung in NCAA-Turnieren.",
          "Von kleinen Colleges bis hin zu großen Universitäten dienen Sportarten in Illinois als Bindemittel, als Quelle des Stolzes und als wesentlicher Bestandteil der Kultur und Identität des Bundesstaates."
      ]
  },
  {
      "name": "Indiana",
      "description": [
          "Indiana ist bekannt für seine tief verwurzelte Liebe zum Basketball, und dies zeigt sich in der College-Sportkultur des Bundesstaates. Die Rivalität, Leidenschaft und Hingabe sowohl der Spieler als auch der Fans sind unübertroffen.",
          "Programme wie die Indiana Hoosiers und die Purdue Boilermakers sind national bekannt, insbesondere im Basketball. Eine elektrisierende Atmosphäre herrscht das ganze Jahr über in Veranstaltungsorten wie der Assembly Hall und der Mackey Arena, wo Geschichte und Tradition zusammenkommen.",
          "Während Basketball die Führung übernimmt, gedeihen auch andere Sportarten in Indiana. American Football, Baseball und Leichtathletik haben jeweils starke Programme und genießen breite Unterstützung durch die Gemeinschaft.",
          "Sport in Indiana ist mehr als nur ein Spiel; es ist eine Lebensweise, ein Katalysator für Gemeinschaftsgeist und eine stolze Tradition, die von Generation zu Generation weitergegeben wird. Hier, im Herzen des Mittleren Westens, sind College-Sportarten ein integraler Bestandteil der Identität des Bundesstaates."
      ]
  },
  {
      "name": "Iowa",
      "description": [
          "College-Sportarten in Iowa sind eine Quelle des Stolzes und des Gemeinschaftsgeistes, mit einer reichen Tradition sowohl im American Football als auch im Ringen. Die Rivalität zwischen den Iowa Hawkeyes und den Iowa State Cyclones ist zentral und sorgt jedes Jahr für unvergessliche Momente.",
          "Das Kinnick Stadium in Iowa City und das Jack Trice Stadium in Ames sind die Veranstaltungsorte für intensive Footballspiele jeden Herbst, die Zehntausende von Fans anziehen. Die Leidenschaft und Hingabe der Fans sind charakteristisch für die Sportkultur des Bundesstaates.",
          "Auch im Ringen zeichnet sich Iowa aus, wobei die Hawkeyes regelmäßig nationale Titel gewinnen. Diese Sportart hat in Iowa eine tief verwurzelte Tradition und genießt treue Unterstützung.",
          "Von kleinen Colleges bis hin zu großen Universitäten sind Sportarten in Iowa mehr als nur Wettbewerb; sie sind eine Lebensweise, eine Quelle des Gemeinschaftsgeistes und ein wichtiger Bestandteil der Identität des Bundesstaates."
      ]
  },
  {
      "name": "Kansas",
      "description": [
          "College-Sportarten in Kansas sind von Tradition und Gemeinschaftsgeist geprägt, wobei Basketball als unbestrittener Höhepunkt gilt. Der Bundesstaat beheimatet einige der bekanntesten Programme im Land, und die Rivalität und Leidenschaft sind intensiv.",
          "Die Kansas Jayhawks und die Kansas State Wildcats führen die Charge an, wobei ihr jährliches 'Sunflower Showdown' eines der Highlights des Sportjahres ist. Die Allen Fieldhouse, Heimat der Jayhawks, gilt als eines der einschüchterndsten Arenen im College-Basketball.",
          "Während Basketball das Herz der Sportkultur in Kansas bildet, spielen auch American Football, Baseball und Leichtathletik eine bedeutende Rolle. Die Fans zeichnen sich durch ihre unübertroffene Hingabe aus und tragen zu einer lebendigen Atmosphäre während der Spiele bei.",
          "Sportarten in Kansas sind mehr als nur Wettbewerb; sie sind eine Quelle des Stolzes, ein Katalysator für Gemeinschaft und ein wesentlicher Bestandteil der Identität des Bundesstaates."
      ]
  },
  {
      "name": "Kentucky",
      "description": [
          "College-Sportarten in Kentucky sind gleichbedeutend mit Basketball und zeichnen sich durch eine reiche Tradition und leidenschaftliche Fanbasis aus. Die Rivalität zwischen den Kentucky Wildcats und den Louisville Cardinals ist eine der intensivsten und meistbeachteten im Land.",
          "Die Rupp Arena, die Heimat der Wildcats, ist ein wahrer Basketballtempel, in dem die Geschichte und der Stolz des Programms spürbar sind. Die Wildcats haben mehrere nationale Meisterschaften auf ihrem Konto und sind eine feste Größe im College-Basketball.",
          "Neben Basketball hat Kentucky auch starke Programme im American Football, in Reitsportarten und im Schießen, die alle die Sportkultur des Bundesstaates bereichern. Das Kentucky Derby, ein legendäres Pferderennen, unterstreicht die reiche Tradition im Reitsport.",
          "In Kentucky sind Sportarten mehr als nur ein Zeitvertreib; sie sind eine Lebensweise, eine Quelle der Gemeinschaft und des Stolzes und ein wesentlicher Bestandteil der Identität des Bundesstaates."
      ]
  },
  {
      "name": "Louisiana",
      "description": [
          "In Louisiana sind College-Sportarten ein integraler Bestandteil der Kultur, mit einer tief verwurzelten Leidenschaft für American Football und Baseball. Die Louisiana State University (LSU) Tigers sind ein Kraftpaket, das riesige Menschenmengen anzieht und eine beispiellose Atmosphäre schafft.",
          "Das Tiger Stadium, auch bekannt als 'Death Valley', ist berüchtigt für seine wilden Fans und die einschüchternde Atmosphäre, die es zu einem der gefürchtetsten Heimatfelder im College-Football macht. 'Saturday Night in Death Valley' ist mehr als nur ein Spiel; es ist ein Ereignis, eine Tradition, ein Teil der Seele Louisianas.",
          "Neben LSU tragen auch kleinere Universitäten und Colleges zur reichen Sportlandschaft bei, mit starken Programmen in verschiedenen Sportarten. Baseball erfreut sich ebenfalls großer Beliebtheit, wobei LSU regelmäßig um den nationalen Titel kämpft.",
          "Sport in Louisiana ist eine Feier der Gemeinschaft, eine Quelle des Stolzes und eine Bindung, die Menschen unabhängig von ihrem Hintergrund zusammenbringt. Es ist ein lebendiger und wesentlicher Bestandteil des Lebensstils und der Identität des Bundesstaates."
      ]
  },
  {
      "name": "Maine",
      "description": [
          "College-Sportarten in Maine spiegeln die einzigartige Natur und den Gemeinschaftssinn des Bundesstaates wider und konzentrieren sich stark auf Wintersportarten und Leichtathletik. Die University of Maine Black Bears führt den Weg und repräsentiert stolz in verschiedenen NCAA-Divisionen.",
          "Das Alfond Stadium in Orono ist das Herzstück des American Football im Bundesstaat, während die Harold Alfond Sports Arena eine zentrale Rolle in der Eishockeysaison spielt. Die Black Bears haben eine stolze Eishockeytradition, und enthusiastische Fans trotzen der Kälte, um ihr Team zu unterstützen.",
          "Neben Eishockey und American Football gibt es auch starke Programme in Leichtathletik, Rudern und anderen Sportarten, die die sportliche Landschaft von Maine bereichern. Der Bundesstaat genießt auch lebhafte Wettkämpfe in Highschool- und Vereinssportarten, die Sport zu einem integralen Bestandteil der Gemeinschaft machen.",
          "Sport in Maine ist mehr als nur Wettbewerb; es ist eine Quelle des Stolzes, eine Möglichkeit, den einzigartigen Geist des Bundesstaates zu feiern, und eine Bindung, die Menschen unabhängig von der Jahreszeit zusammenbringt."
      ]
  },
  {
      "name": "Maryland",
      "description": [
          "College-Sport in Maryland gedeiht mit einer starken Tradition im Basketball, Lacrosse und American Football. Die University of Maryland Terrapins, oder einfach die Terps, sind zentral für die Sportkultur des Bundesstaates.",
          "Das Xfinity Center und das Maryland Stadium sind die stolzen Arenen für Basketball und American Football, in denen die Terps ihre Heimspiele austragen. Die Fans sind glühend und tragen zu einer elektrisierenden Atmosphäre während der Spiele bei. Maryland ist für sein wettbewerbsfähiges Basketballprogramm bekannt, mit mehreren Teilnahmen am NCAA-Turnier.",
          "Auch im Lacrosse ist Maryland stark, wobei die Terps regelmäßig um nationale Titel kämpfen. Diese Sportart hat einen besonderen Platz in den Herzen der Bewohner, und intensive College-Rivalitäten machen jede Saison zu einem Spektakel.",
          "Sport in Maryland ist ein Ausdruck von Gemeinschaft, Stolz und Tradition und spielt eine entscheidende Rolle dabei, Menschen zusammenzubringen und die einzigartige Kultur des Bundesstaates zu feiern."
      ]
  },
  
  {
    "name": "Massachusetts",
    "description": [
        "In Massachusetts spielen College-Sportarten eine bedeutende Rolle im täglichen Leben, mit einer reichen Geschichte im Basketball, American Football und Eishockey. Die Universitäten hier haben starke Sportprogramme und leidenschaftliche Fans.",
        "Basketball hat einen besonderen Platz in den Herzen der Bewohner von Massachusetts, da das Spiel hier erfunden wurde. Die University of Massachusetts Minutemen, bekannt als UMass Minutemen, sind stolze Vertreter im NCAA Division I Basketball.",
        "Amerikanischer Fußball und Eishockey sind ebenfalls beliebt, wobei intensive lokale Rivalitäten spannende Wettkämpfe bieten. Das Harvard-Yale-Fußballspiel, bekannt als 'The Game', ist ein jährlicher Höhepunkt.",
        "Sport in Massachusetts steht für Tradition, Gemeinschaft und Wettbewerb. Es bringt Menschen unabhängig von ihrer Herkunft zusammen und ist ein wesentlicher Bestandteil der kulturellen Identität des Bundesstaates."
    ]
},
{
    "name": "Michigan",
    "image": Michigan,
    "imageDescription": "Michigan Stadium: das drittgrößte Stadion der Welt und Heimat der Michigan Wolverines. Es bietet Platz für 107.601 Fans.",
    "description": [
        "Michigan hat eine reiche Tradition im College-Sport, insbesondere im American Football und Basketball. Die Rivalität zwischen den Michigan Wolverines und den Michigan State Spartans ist legendär und erzeugt eine elektrisierende Atmosphäre im gesamten Bundesstaat.",
        "Das Michigan Stadium, auch bekannt als 'The Big House', ist eines der größten Stadien der Welt und bietet Platz für über 100.000 Fans. Die Wolverines haben eine glorreiche Geschichte mit mehreren nationalen Titeln im Football.",
        "Auch Basketball ist sehr beliebt, wobei sowohl Michigan als auch Michigan State regelmäßig am NCAA-Turnier teilnehmen. Die Leidenschaft und Hingabe der Fans sind beispiellos, und Spieltage sind wahre Ereignisse in Michigan.",
        "Neben diesen Hauptsportarten hat Michigan auch starke Programme im Eishockey, Leichtathletik und anderen Sportarten. Sport in Michigan ist mehr als nur eine Freizeitbeschäftigung; er ist ein integraler Bestandteil des Lebensstils und der Identität des Bundesstaates."
    ]
},
{
    "name": "Minnesota",
    "description": [
        "College-Sportarten in Minnesota haben einen einzigartigen Charakter mit einem starken Schwerpunkt auf Eishockey, American Football und Basketball. Die University of Minnesota Golden Gophers spielt eine zentrale Rolle in der Sportszene des Bundesstaates.",
        "TCF Bank Stadium und Williams Arena sind während der Fußball- und Basketballsaison belebte Zentren der Aktivität, während die Mariucci Arena eine Hochburg für Eishockey ist. Die Golden Gophers sind stolze Vertreter in der NCAA mit einer reichen Geschichte und treuen Fans.",
        "Eishockey ist besonders beliebt in Minnesota und wird oft als 'The State of Hockey' bezeichnet. Die College-Teams genießen starke Unterstützung und bieten aufregende Action, besonders während des berühmten 'Frozen Four' NCAA-Turniers.",
        "Sport in Minnesota ist tief mit der Kultur und Identität des Bundesstaates verwoben und bietet eine Quelle des Stolzes, des Gemeinschaftsgeistes und der lebhaften Rivalitäten, die Menschen jeden Alters zusammenbringen."
    ]
},
{
    "name": "Mississippi",
    "description": [
        "College-Sportarten sind ein integraler Bestandteil der Kultur in Mississippi mit intensiver Leidenschaft für American Football, Basketball und Baseball. Die Rivalität zwischen den Ole Miss Rebels und den Mississippi State Bulldogs ist eine der intensivsten im Land.",
        "Vaught-Hemingway Stadium in Oxford und Davis Wade Stadium in Starkville sind wahre Tempel für Football-Fans, wo die Spannung und Leidenschaft des Spiels greifbar sind. Das jährliche Egg Bowl, das Aufeinandertreffen von Ole Miss und Mississippi State, ist ein Highlight im Sportkalender.",
        "Auch Basketball und Baseball sind bedeutend, wobei beide Universitäten starke Teams und Traditionen aufbauen. Die Fans sind loyal und lautstark und schaffen eine elektrisierende Atmosphäre bei Spielen.",
        "Sport in Mississippi steht für Gemeinschaft, Stolz und Tradition. Es bringt Menschen zusammen, schafft unvergessliche Momente und ist ein wesentlicher Bestandteil der Identität des Bundesstaates."
    ]
},
{
    "name": "Missouri",
    "description": [
        "College-Sportarten in Missouri stehen für Leidenschaft, Gemeinschaft und Wettbewerb. Der Bundesstaat hat eine reiche Sportgeschichte, wobei American Football, Basketball und Baseball die Hauptrolle spielen.",
        "Die University of Missouri Tigers, auch bekannt als Mizzou, und die Missouri State Bears sind zwei prominente Vertreter in der College-Sportlandschaft. Mizzous Faurot Field und die Mizzou Arena sind Zentren der Aufregung und des Teamgeistes an Spieltagen.",
        "Die Tigers sind in der SEC, einer der wettbewerbsfähigsten Konferenzen im College-Football, stark vertreten. Ihr Basketballteam ist auch ein Fixpunkt im NCAA-Turnier, wobei die Fans uneingeschränkte Unterstützung bieten.",
        "Sport in Missouri stärkt die Gemeinschaftsbindungen und bringt Menschen zusammen. Es geht nicht nur um das Spiel; es geht um das Erlebnis, die Traditionen und die unzerbrechliche Bindung zwischen den Teams und ihren Unterstützern."
    ]
},
{
    "name": "Montana",
    "description": [
        "In Montana spielen College-Sportarten eine entscheidende Rolle im Gemeinschaftsleben, wobei American Football, Basketball und Rodeo beliebte Sportarten sind. Die Montana Grizzlies und die Montana State Bobcats dominieren die Sportszene, und ihre Rivalität ist intensiv.",
        "Das Washington-Grizzly Stadium der Grizzlies und das Bobcat Stadium der Bobcats sind stolze Football-Arenen in Montana. Hier kommen Fans zusammen, um ihre Teams zu unterstützen, unabhängig vom Wetter. Das jährliche Brawl of the Wild-Spiel zwischen den Grizzlies und den Bobcats ist ein Muss.",
        "Auch Basketball ist beliebt, mit lebhaften Spielen und einer starken Fanbasis. Rodeo, das einzigartig für Montana ist, zieht ebenfalls viel Aufmerksamkeit und Wertschätzung auf sich und spiegelt die Kultur und Traditionen des Bundesstaates wider.",
        "College-Sportarten in Montana stehen für Gemeinschaft, Stolz und die Liebe zum Spiel. Sie bringen Menschen zusammen, schaffen lebenslange Erinnerungen und tragen zur einzigartigen Identität des Big Sky Country bei."
    ]
},
{
    "name": "Nebraska",
    "description": [
        "In Nebraska zeichnen sich College-Sportarten durch tiefe Leidenschaft und Loyalität aus, mit einem besonderen Fokus auf American Football. Die Nebraska Cornhuskers, ansässig an der University of Nebraska-Lincoln, sind das Herzstück der Sportkultur des Bundesstaates.",
        "Das Memorial Stadium, die Heimat der Cornhuskers, wird auch als 'The Sea of Red' bezeichnet, wo Fans in ihrer charakteristischen roten Kleidung zusammenkommen, um ihr Team zu unterstützen. Das Stadion ist bekannt für seinen ohrenbetäubenden Lärm und die einzigartige Atmosphäre an Spieltagen.",
        "Neben Football spielt auch Basketball eine bedeutende Rolle, wobei sowohl Männer- als auch Frauenteams auf hohem Niveau antreten. Die Fans sind genauso engagiert und schaffen eine lebendige Atmosphäre bei jedem Spiel.",
        "College-Sportarten in Nebraska gehen über den reinen Sport hinaus; es ist eine Möglichkeit, Gemeinschaften zusammenzubringen, Traditionen zu feiern und ein Gefühl von Stolz und Zusammengehörigkeit im 'Cornhusker State' zu schaffen."
    ]
},
{
    "name": "Nevada",
    "description": [
        "College-Sportarten in Nevada bieten eine einzigartige Mischung aus Unterhaltung und Wettbewerb, mit besonderem Augenmerk auf Basketball und American Football. Die University of Nevada, Las Vegas (UNLV), und die University of Nevada, Reno sind die beiden Hauptinstitutionen im Sport.",
        "UNLVs Basketballteam, die Runnin' Rebels, hat eine reiche Geschichte und ist landesweit bekannt. Ihre Heimspiele sind eine echte Attraktion, komplett mit energiegeladenen Vorstellungen und einem begeisterten Publikum. Das Footballteam der Nevada Wolf Pack trägt ebenfalls zur Sportkultur im Bundesstaat bei, mit aufregenden Spielen und einer treuen Fanbasis.",
        "In Nevada gibt es auch ein lebhaftes Interesse an anderen College-Sportarten wie Baseball, Softball und Fußball. Diese Sportarten ziehen lokale Gemeinschaften an und bieten reichlich Gelegenheit für Athleten, sich zu entwickeln.",
        "Insgesamt sind College-Sportarten in Nevada ein wesentlicher Bestandteil des Staates, die nicht nur Unterhaltung bieten, sondern auch Gemeinschaften zusammenbringen und die lokale Kultur bereichern."
    ]
},




{
  "name": "New Hampshire",
  "description": [
      "Im malerischen Bundesstaat New Hampshire spielen Collegesportarten eine entscheidende Rolle im Gemeinschaftsleben. Obwohl der Staat kleiner ist als viele andere, ist die Hingabe zum Sport immens.",
      "Die University of New Hampshire (UNH) dominiert die Sportlandschaft, insbesondere im Eishockey, wo die Wildcats kontinuierlich auf hohem Niveau spielen. Die Whittemore Center Arena, das Heimstadion des Eishockeyteams, ist für ihre lebhafte Atmosphäre und leidenschaftlichen Fans bekannt.",
      "Auch Football und Basketball sind beliebte Sportarten an der UNH und ziehen während der Saison große Menschenmengen an. Die Gemeinschaft ist stolz auf ihre Teams, und die Studenten sind eng in Sportveranstaltungen eingebunden.",
      "Der Ansatz von New Hampshire für den Collegesport ist auf die Gemeinschaft ausgerichtet, mit dem Ziel, Menschen zusammenzubringen, Erfolge zu feiern und unvergessliche Momente in der malerischen Umgebung des Staates zu schaffen."
  ]
},
{
  "name": "New Jersey",
  "description": [
      "Im lebhaften Bundesstaat New Jersey herrscht eine lebendige und wettbewerbsfähige Atmosphäre im Collegesport, mit verschiedenen Universitäten, die auf hohem Niveau konkurrieren. Der Staat beherbergt einige führende Programme, die sowohl regional als auch national anerkannt sind.",
      "Eines der prominentesten Universitätsteams sind die Rutgers Scarlet Knights, Teil der renommierten Big Ten Conference. Ihre Football- und Basketballspiele ziehen große Menschenmengen an und erzeugen Aufregung im ganzen Staat.",
      "New Jersey ist auch für seine starken Lacrosse- und Fußballprogramme bekannt, die junge Talente anziehen und fördern. Diese Sportarten spielen eine wichtige Rolle in der Collegesportkultur des Staates, mit aufregenden Wettbewerben und engagierten Fans.",
      "Die Gemeinschaft in New Jersey unterstützt ihre Collegesportteams, was zu einer unterstützenden und leidenschaftlichen Atmosphäre bei jedem Ereignis führt. Collegesport in New Jersey sind mehr als nur Spiele; sie sind ein wesentlicher Bestandteil des kulturellen Gefüges des Staates."
  ]
},
{
  "name": "New Mexico",
  "description": [
      "New Mexico hat aufgrund seiner einzigartigen kulturellen und geografischen Hintergründe eine besondere Beziehung zum Collegesport. Der Staat beherbergt einige herausragende Universitätssportprogramme, die auf nationaler Ebene konkurrieren.",
      "Die University of New Mexico Lobos in Albuquerque ist ein zentraler Stolzpunkt. Ihr Basketballteam zieht regelmäßig große Menschenmengen in The Pit an, eine der einschüchterndsten Arenen des Landes.",
      "Die New Mexico State Aggies aus Las Cruces sind ebenfalls nationale Mitbewerber, insbesondere im Basketball und Football, wo sie heftige Rivalitäten mit den Lobos haben, die im Rio Grande Rivalry gipfeln.",
      "Gestützt auf eine reiche Geschichte und eine leidenschaftliche Fangemeinde, bieten Collegesportarten in New Mexico nicht nur Unterhaltung, sondern fördern auch den Gemeinschaftssinn und die staatliche Identität. In diesem Wüstenstaat ist Sport eine Feier von Talent, Ausdauer und Gemeinschaftsbindungen."
  ]
},
{
  "name": "New York",
  "description": [
      "Im geschäftigen Bundesstaat New York spielen Collegesportarten eine dynamische und vielseitige Rolle. Der Staat beherbergt zahlreiche Universitäten, von denen jede ihre eigenen einzigartigen Sporttraditionen hat.",
      "Die Syracuse University sticht mit ihrem Basketballteam, den Orange, heraus, das oft auf höchstem nationalen Niveau konkurriert. Das Carrier Dome-Stadion, bekannt für seine beeindruckende Kapazität und lebendige Atmosphäre, ist ein Symbol in der Welt des Collegesports.",
      "Darüber hinaus spielen kleinere Universitäten und Hochschulen ebenfalls eine bedeutende Rolle, mit starken Programmen in Sportarten wie Lacrosse, Fußball und Baseball. Diese kleineren Programme fördern den Wettbewerbsgeist im Staat und schaffen eine reiche und vielfältige Sportlandschaft.",
      "Die Beteiligung der Gemeinschaft am Collegesport in New York ist immens, und Fans unterstützen ihre Teams in guten und schlechten Zeiten. Von geschäftigen Städten bis hin zu ruhigeren ländlichen Gebieten vereint der Sport New Yorker und vermittelt ein Gefühl von Stolz und Zusammengehörigkeit."
  ]
},
{
  "name": "North Carolina",
  "image": North_Carolina,
  "imageDescription": "Die ikonische Rivalität: DUKE vs. UNC in Aktion",
  "description": [
      "North Carolina hat eine reiche Tradition und eine unvergleichliche Leidenschaft, wenn es um Collegesport geht, insbesondere im Basketball. Die Rivalität zwischen den University of North Carolina Tar Heels und den Duke University Blue Devils ist weltberühmt und garantiert spannende Duelle.",
      "Die Tar Heels und Blue Devils dominieren nicht nur die Basketballsaison, sondern bringen auch eine Welle von Aufregung und Gemeinschaftssinn im ganzen Staat. Es ist eine tief verwurzelte Sportkultur in North Carolinas Identität.",
      "Neben Basketball spielt auch American Football eine prominente Rolle, mit starken Teams und leidenschaftlichen Fanbasen. Und im Frühling blüht die Liebe zum Baseball auf, wenn sowohl Universitäts- als auch College-Teams auf den Feldern gegeneinander antreten.",
      "Collegesport in North Carolina geht über die Sportplätze und Hallen hinaus; er ist eine Lebensweise, eine Quelle des Stolzes und ein Mittel für Gemeinschaften, zusammenzukommen und ihren Staat zu feiern."
  ]
},
{
  "name": "North Dakota",
  "description": [
      "Collegesport in North Dakota bietet ein einzigartiges Erlebnis, bei dem Gemeinden eng mit ihren Universitätsteams verbunden sind. Der Stolz und das Zusammengehörigkeitsgefühl sind während Sportveranstaltungen deutlich sichtbar, obwohl der Staat weniger prominente Universitäten hat als andere Regionen Amerikas.",
      "Das American-Football-Team der North Dakota State University, die Bison, ist bekannt für ihre Erfolge in der NCAA Division I Football Championship Subdivision, was der Sportkultur des Staates einen erheblichen Schub verleiht. Fans versammeln sich im Fargodome, um ihr Team zu unterstützen, und schaffen eine intensive Atmosphäre.",
      "Auch Eishockey spielt in North Dakota eine wichtige Rolle, mit den University of North Dakota Fighting Hawks als führendem Team. Die Leidenschaft für diesen Wintersport ist während der Saison deutlich spürbar, wenn die Fans in die Arena strömen.",
      "Collegesport in North Dakota, insbesondere American Football und Eishockey, bringt Gemeinschaften zusammen, schafft starken lokalen Stolz und trägt zur lebendigen Sportkultur des Staates bei."
  ]
},
{
  "name": "Ohio",
  "description": [
      "Collegesport ist ein integraler Bestandteil der Kultur in Ohio, mit einem besonderen Schwerpunkt auf American Football und Basketball. Die Ohio State University (OSU) und die University of Cincinnati sind zwei führende Institutionen im Sport.",
      "Das Footballteam der OSU, die Buckeyes, genießt nationalen Ruhm und ist eine wahre Kraft in der NCAA. Ihre Spiele im massiven Ohio Stadium ziehen mehr als 100.000 Fans an und schaffen eine unvergleichliche Atmosphäre während der Heimspiele. 'O-H-I-O' ist ein Ruf, der im ganzen Staat bekannt ist.",
      "Auch Basketball wird prominent präsentiert, wobei sowohl Männer- als auch Frauenteams auf hohem Niveau konkurrieren. Der Staat Ohio beherbergt mehrere Universitätsteams, die regelmäßig am NCAA-Turnier teilnehmen und die Basketballfieber verstärken.",
      "Collegesport in Ohio bietet mehr als nur Wettbewerb; er ist eine Quelle des Stolzes, der Tradition und des Gemeinschaftsgeistes, der Menschen aus allen Lebensbereichen vereint."
  ]
},


{
  "name": "Oklahoma",
  "description": [
      "In Oklahoma spielen Collegesportarten eine zentrale Rolle im Herzen der Bewohner, mit einer tief verwurzelten Leidenschaft für American Football. Die University of Oklahoma (OU) und die Oklahoma State University (OSU) sind die Juwelen der Sportwelt des Staates.",
      "Das Footballteam der OU, die Sooners, hat eine reiche Geschichte und zahlreiche Erfolge, was eine lebendige Atmosphäre um ihre Spiele schafft. Das Gaylord Family Oklahoma Memorial Stadium ist eine Festung für das Team, wo Fans lautstark 'Boomer Sooner' rufen.",
      "Die OSU ist der OU nicht weit hinterher, mit starken Leistungen sowohl im Football als auch in anderen Sportarten, wo ihr American-Football-Team, die Cowboys, eine bedeutende Rolle spielt. Das Engagement der Fans während der 'Bedlam Series', des jährlichen Duells zwischen OU und OSU, ist enorm.",
      "Collegesport in Oklahoma symbolisiert den Kampf, die Ausdauer und das Gemeinschaftsgefühl des Staates und ist viel mehr als nur ein Spiel."
  ]
},
{
  "name": "Oregon",
  "description": [
      "Collegesportarten in Oregon sind ein wesentlicher Bestandteil des Staates, wobei der Fokus sowohl auf American Football als auch auf Leichtathletik liegt. Die University of Oregon (UO) in Eugene ist Mittelpunkt dieser sportlichen Leidenschaft und hat einen nationalen Ruf aufgebaut.",
      "Das Footballteam der UO, die Ducks, ist für ihr temporeiches Spiel und ihre einzigartigen Trikots bekannt, während das Leichtathletikprogramm zu den besten des Landes gehört. Das jährliche Leichtathletikereignis Prefontaine Classic, das am berühmten Hayward Field stattfindet, unterstreicht die Hingabe Oregons zur Leichtathletik.",
      "Die Oregon State University (OSU) mit ihrem Footballteam, den Beavers, sorgt für starke Konkurrenz, insbesondere während des 'Civil War', dem jährlichen Zusammenstoß mit den Ducks. Dies ist mehr als nur ein Spiel; es ist eine staatliche Tradition, die Gemeinschaften zusammenbringt.",
      "Neben diesen Sportarten erfreut sich auch Basketball großer Beliebtheit, und leidenschaftliche Fans machen den Collegesport in Oregon zu einem Erlebnis, das man nicht verpassen möchte. Es ist eine Feier des sportlichen Talents, der Gemeinschaft und der einzigartigen Sportkultur des Staates."
  ]
},
{
  "name": "Pennsylvania",
  "description": [
      "Pennsylvania hat eine tiefe Wertschätzung für Collegesportarten, mit einem starken Schwerpunkt auf American Football, Basketball und Leichtathletik. Teams wie die Penn State Nittany Lions und die Pittsburgh Panthers dominieren die Sportlandschaft und schaffen aufregende Rivalitäten.",
      "Das Beaver Stadium, das Zuhause der Nittany Lions, ist für seine überwältigende Atmosphäre und das intensive Fanerlebnis bekannt, insbesondere während der 'White Out'-Spiele. Diese Momente sind der Höhepunkt des Sportkalenders und ziehen Fans aus dem ganzen Staat an.",
      "Auf dem Basketballplatz haben die Villanova Wildcats in letzter Zeit nationalen Erfolg erzielt, mit mehreren Meisterschaften, die den Staat begeistert haben. Ihre Leistungen haben Pennsylvania als Basketballmacht auf die Landkarte gesetzt.",
      "Auch Leichtathletik spielt eine entscheidende Rolle, mit den renommierten Penn Relays, die jedes Jahr in Philadelphia Spitzenathleten anziehen. Diese reiche Sportkultur stärkt den Gemeinschaftssinn und trägt zum Stolz Pennsylvanias bei."
  ]
},
{
  "name": "Rhode Island",
  "description": [
      "Trotz seiner geringen Größe hat Rhode Island eine lebhafte Collegesport-Szene, wobei Basketball und Segeln besonders beliebt sind. Der Staat hat Teams, die mit Herz und Seele spielen und aufregende Momente schaffen.",
      "Teams wie die Rhode Island Rams und die Providence Friars haben eine treue Fangemeinde und liefern fesselnde Spiele. Das Dunkin' Donuts Center in Providence wird während der Basketballspiele zu einer belebten Arena, in der die Einheimischen in Scharen erscheinen, um ihre Teams zu unterstützen.",
      "Neben Basketball ist Rhode Island für seine herausragenden Segelprogramme bekannt, wobei die wunderschöne Küste ideale Bedingungen für den Sport bietet. Universitäten wie Brown und URI haben starke Segelteams, die national antreten.",
      "Diese Sportarten tragen zur lebendigen Kultur von Rhode Island bei, wo jedes Spiel eine Gelegenheit ist, den einzigartigen Geist dieses kleinen, aber stolzen Staates zu feiern."
  ]
},
{
  "name": "South Carolina",
  "description": [
      "Collegesport in South Carolina atmet Tradition und Leidenschaft, wobei American Football und Basketball im Mittelpunkt stehen. Die intensive Rivalität zwischen den University of South Carolina Gamecocks und den Clemson University Tigers dominiert die Sportlandschaft.",
      "Während des jährlichen Football-Duells 'The Palmetto Bowl' erwacht der gesamte Staat zum Leben, wobei die Grenze zwischen Karminrot und Orange deutlich gezogen wird. Die Energie und Leidenschaft der Fans sind unvergleichlich und machen jedes Spiel zu einem unvergesslichen Erlebnis.",
      "Auch Basketball nimmt in den Herzen der Sportfans in South Carolina einen prominenten Platz ein, wobei Teams in beiden Männer- und Frauenligen an der Spitze stehen. Zum Beispiel hat das Frauenbasketballteam der Gamecocks nationalen Erfolg erzielt und den Gemeinschaftsstolz und die Beteiligung weiter gestärkt.",
      "Die Sportkultur in South Carolina geht über die Spielfelder hinaus; sie ist eine Lebensweise, eine Quelle des Stolzes und ein unverzichtbarer Bestandteil der Identität des Staates."
  ]
},
{
  "name": "South Dakota",
  "description": [
      "Collegesport in South Dakota bietet ein einzigartiges und leidenschaftliches Erlebnis, wobei der Schwerpunkt auf Basketball, American Football und Leichtathletik liegt. Die South Dakota State University Jackrabbits und die University of South Dakota Coyotes sind prominente Spieler in der Sportlandschaft.",
      "Basketballspiele, insbesondere während der Rivalitätsbegegnungen zwischen den Jackrabbits und den Coyotes, ziehen enthusiastische Menschenmengen an und schaffen eine elektrisierende Atmosphäre. Der Staat jubelt sowohl den Männer- als auch den Frauenteams zu, wobei die Leistungen auf dem Feld eine Quelle des Stolzes für lokale Gemeinschaften sind.",
      "American Football erfreut sich ebenfalls großer Beliebtheit, mit aufregenden Spielen und starker Fanbeteiligung. Die Leichtathletikprogramme in South Dakota glänzen, wobei Athleten national antreten und stolz den Staat repräsentieren.",
      "Collegesport in South Dakota ist mehr als nur ein Spiel; er ist ein wesentlicher Bestandteil der Gemeinschaft, stärkt die Bindungen zwischen den Bewohnern und trägt zur lebendigen Kultur des Staates bei."
  ]
},
{
  "name": "Tennessee",
  "description": [
      "In Tennessee spielen Collegesportarten eine entscheidende Rolle in der Gemeinschaft, mit einer reichen Tradition in American Football, Basketball und Leichtathletik. Die University of Tennessee Volunteers und die Vanderbilt University Commodores sind führende Teams in der Sportlandschaft des Staates.",
      "Die Volunteers, mit ihrer ikonischen orangefarbenen Farbe, ziehen riesige Menschenmengen ins Neyland Stadium, eines der größten American-Football-Stadien des Landes. Die Leidenschaft der Fans ist unübertroffen, und 'Rocky Top' erschallt laut bei jedem Spiel.",
      "Auch Basketball ist in Tennessee ein großer Anziehungspunkt, wobei sowohl Männer- als auch Frauenteams nationale Anerkennung erhalten. Der Staat hat eine reiche Geschichte in der Produktion von Top-Athleten und hat zum Wachstum des Sports auf nationaler Ebene beigetragen.",
      "Collegesport in Tennessee ist ein wesentlicher Bestandteil der Kultur und Identität des Staates, der Menschen zusammenbringt, Traditionen feiert und die nächste Generation von Athleten inspiriert."
  ]
},
{
  "name": "Texas",
  "image": Texas,
  "imageDescription": "Traditionelle Rivalität: Texas Longhorns treten gegen Texas A&M an",
  "description": [
      "Collegesport in Texas ist gleichbedeutend mit Größe, Leidenschaft und reicher Tradition, wobei American Football die Führung übernimmt. Teams wie die Texas Longhorns und die Texas A&M Aggies haben riesige Anhängerschaften, und ihre Spiele sind Saisonhöhepunkte.",
      "Das Darrell K Royal–Texas Memorial Stadium in Austin und das Kyle Field in College Station sind Sporttempel, in denen jedes Heimspiel zu einem spektakulären Ereignis wird. Die Rivalität zwischen verschiedenen Universitätsteams ist intensiv und trägt zur einzigartigen Sportkultur des Staates bei.",
      "Neben Football sind auch Basketball und Baseball beliebt, mit starken Programmen, die national antreten. Die Unterstützung für Frauensportarten wächst stetig, wobei Basketball und Leichtathletik besonders hervorstechen.",
      "Collegesport in Texas ist mehr als nur ein Zeitvertreib; es ist eine Lebensweise, eine Quelle des Stolzes und eine verbindende Kraft, die Gemeinschaften zusammenführt."
  ]
},


{
  "name": "Utah",
  "description": [
      "In Utah haben Collegesportarten einen bedeutenden Platz innerhalb der Gemeinschaft, wobei Basketball und American Football die beliebtesten Sportarten sind. Die University of Utah Utes und die Brigham Young University Cougars liefern sich in verschiedenen Disziplinen einen erbitterten Wettbewerb.",
      "Das jährliche Football-Duell zwischen den Utes und den Cougars, bekannt als 'Heiliger Krieg', ist eines der meist erwarteten Sportereignisse im Bundesstaat. Es bringt Fans zusammen, um Sportsgeist, Rivalität und Staatsruhm zu feiern.",
      "Auch Basketball hat in Utah eine reiche Tradition, mit aufregenden Spielzeiten und unvergesslichen Momenten in Männer- und Frauenligen. Die Teams streben nach Exzellenz, und die Unterstützung der Fans ist unwandelbar.",
      "Die Auswirkungen des Collegesports in Utah gehen über das Spielfeld hinaus. Er fördert den Gemeinschaftssinn, den Schuls stolz und trägt wesentlich zur kulturellen Identität des Staates bei."
  ]
},
{
  "name": "Vermont",
  "description": [
      "In Vermont, wo die Natur vorherrscht und die Gemeinschaften eng verbunden sind, spielen Collegesportarten eine einzigartige Rolle, um die Menschen zusammenzubringen. Wintersportarten wie Skifahren und Snowboarden stehen aufgrund des bergigen Geländes und des kalten Klimas im Mittelpunkt.",
      "Die University of Vermont Catamounts ist das Zentrum der Collegesport-Szene, mit Teams, die in verschiedenen Sportarten, darunter Eishockey und Basketball, herausragende Leistungen erbringen. Die Catamounts haben eine treue Anhängerschaft, und die Atmosphäre bei Heimspielen ist intensiv.",
      "Basketball erfreut sich großer Beliebtheit, und die Spiele der Catamounts sind ein Muss für Sportbegeisterte. Das Team hat mehrfach am NCAA-Turnier teilgenommen und den Bundesstaat in der Welt des College-Basketballs bekannt gemacht.",
      "Collegesportarten in Vermont sind mehr als nur ein Spiel; sie sind eine Möglichkeit, Gemeinschaften zusammenzubringen, Talente zu entwickeln und die einzigartige Sportkultur des Staates zu feiern."
  ]
},
{
  "name": "Virginia",
  "description": [
      "In Virginia sind Collegesportarten ein integraler Bestandteil der lokalen Kultur, mit einem starken Fokus auf American Football, Basketball und der zunehmenden Beliebtheit von Fußball. Die Virginia Cavaliers und die Virginia Tech Hokies sind führende Universitätsteams, die Leidenschaft und Rivalität auf das Spielfeld bringen.",
      "American Football zieht Massen von Fans in die Stadien, wo die Atmosphäre an Spieltagen elektrisch ist. Investitionen in Basketballprogramme haben sich ebenfalls ausgezahlt, wobei Teams national antreten und eine engagierte Anhängerschaft haben.",
      "Fußball gewinnt in Virginia an Bedeutung, wobei sich sowohl Männer- als auch Frauenteams zu Wettbewerbskräften entwickeln. Universitäten investieren in Einrichtungen und Training, während lokale Akademien junge Talente hervorbringen.",
      "Diese Mischung aus traditionellen und aufstrebenden Sportarten bereichert die Collegesport-Szene in Virginia, schafft eine lebendige Sportgemeinschaft und bietet Athleten und Fans unvergessliche Erlebnisse sowie ein Gefühl von Stolz und Einheit."
  ]
},
{
  "name": "Washington",
  "description": [
      "Collegesportarten in Washington gedeihen mit einer Mischung aus Tradition und Enthusiasmus, wobei American Football, Basketball und Rudern im Mittelpunkt stehen. Die University of Washington Huskies und die Washington State University Cougars sind Pioniere der Sportkultur des Staates.",
      "Das Husky Stadium in Seattle und das Martin Stadium in Pullman brummen vor Aufregung während der American-Football-Spiele, wo Fans aus der gesamten Region zusammenkommen, um ihre Teams zu unterstützen. Der 'Apple Cup', das jährliche Aufeinandertreffen zwischen den Huskies und den Cougars, ist ein Höhepunkt der Rivalität.",
      "Auch Basketball spielt eine bedeutende Rolle, wobei sowohl Männer- als auch Frauenteams auf hohem Niveau konkurrieren. Die Huskies haben eine starke Tradition in diesem Sport und ziehen leidenschaftliche Unterstützer an.",
      "Rudern ist eine weitere bemerkenswerte Disziplin, wobei die Huskies zahlreiche nationale Meisterschaften gewonnen haben. Collegesportarten in Washington spiegeln die Vielfalt und Leidenschaft des Staates wider, in dem Fairness, Wettbewerb und Kameradschaft hoch geschätzt werden."
  ]
},
{
  "name": "West Virginia",
  "description": [
      "Collegesportarten sind das Herzstück von West Virginia, mit einem starken Schwerpunkt auf American Football und Basketball. Die West Virginia University Mountaineers sind die stolzen Vertreter des Staates, und ihre Spiele bringen die Gemeinschaft zusammen.",
      "Das Milan Puskar Stadium in Morgantown erwacht während der Heimspiele der Mountaineers zum Leben, wenn Fans die ikonischen Gold- und Blaufarben tragen und laut jubeln. Der 'Backyard Brawl', eine historische Rivalität mit der University of Pittsburgh, sorgt für zusätzliche Spannung in der Saison.",
      "Basketball ist eine weitere Leidenschaft, wobei spannende Spiele die Fans auf der Kante ihrer Sitze halten. Die Mountaineers haben sowohl Männer- als auch Frauenteams, die auf nationaler Ebene konkurrieren.",
      "Collegesportarten in West Virginia sind eine Quelle von Stolz und Einheit und spiegeln den starken Gemeinschaftssinn und die Traditionen des Staates wider."
  ]
},



{
  "name": "Wisconsin",
  "description": [
      "Collegesportarten sind tief in der Kultur von West-Wisconsin verwurzelt, wobei American Football, Basketball und Eishockey als prominente Disziplinen gelten. Die University of Wisconsin Badgers sind die treibende Kraft hinter der Sportkultur im Bundesstaat.",
      "Das Camp Randall Stadium in Madison brummt vor Aufregung während der American-Football-Spiele, wenn sich Fans versammeln, um 'Jump Around' zu singen und ihr Team anzufeuern. Die Rivalität mit Teams wie den University of Michigan Wolverines ist legendär und führt zu intensiven Spielen.",
      "Auch Basketball hat eine reiche Tradition, wobei die Badgers regelmäßig um die Spitzenplätze in NCAA-Turnieren kämpfen. Eishockey hat einen besonderen Platz im Herzen des Staates, wobei aufregende Spiele die Winterabende erhellen.",
      "Collegesportarten in West-Wisconsin gehen über das Spielfeld hinaus; sie sind eine Quelle von Stolz, Kameradschaft und stärken die Bindungen zwischen den Gemeinschaften im Bundesstaat."
  ]
},
{
  "name": "Wyoming",
  "description": [
      "Collegesportarten in Wyoming haben einen einzigartigen Charakter, wobei ein starker Schwerpunkt auf Outdoor-Sportarten wie Skifahren, Snowboarden und Bergsteigen liegt. Die University of Wyoming Cowboys und Cowgirls sind die Leitfiguren dieser abenteuerlichen Sportkultur.",
      "Das War Memorial Stadium in Laramie erwacht während der American-Football-Spiele zum Leben, wenn sich Fans versammeln, um ihre Unterstützung für die Cowboys zu zeigen. Die atemberaubenden Berge dienen als Kulisse für diese Sportveranstaltungen.",
      "Die Universität hat auch eine starke Tradition im Skifahren und Snowboarden, wobei Athleten nationale und internationale Wettbewerbe dominieren. Die weite Wildnis von Wyoming dient Abenteurern und Outdoor-Enthusiasten als Spielplatz.",
      "Collegesportarten in Wyoming feiern die Schönheit der Natur und den abenteuerlichen Geist des Staates, in dem Fairness, Leidenschaft und Liebe zur Natur zusammenkommen."
  ]
}



];

export default states;