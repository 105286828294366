import americanFootball from "assets/images/SportsIcons/americanfootball.png";
import athletics from "assets/images/SportsIcons/atletiek.png";
import basketball from "assets/images/SportsIcons/basketbal.png";
import golf from "assets/images/SportsIcons/golf.png";
import fieldHockey from "assets/images/SportsIcons/hockey.png"; // Updated to Field Hockey
import baseball from "assets/images/SportsIcons/honkbal.png";
import rowing from "assets/images/SportsIcons/roeien.png";
import softball from "assets/images/SportsIcons/softbal.png"
import tennis from "assets/images/SportsIcons/tennis.png";
import gymnastics from "assets/images/SportsIcons/turnen.png";
import soccer from "assets/images/SportsIcons/voetbal.png";
import volleyball from "assets/images/SportsIcons/volleybal.png";
import waterPolo from "assets/images/SportsIcons/waterpolo.png";
import wrestling from "assets/images/SportsIcons/worstelen.png";
import swimming from "assets/images/SportsIcons/zwemmen.png";
import iceHockey from "assets/images/SportsIcons/ijshockey.png";

export default [
  {
    title: "Sports",
    description: "Una selección de 16 deportes para los cuales ofrezco orientación hacia una beca deportiva",
    items: [
      {
        image: americanFootball,
        name: "Fútbol Americano",
        badgeContent: "Hombres",
        secondBadgeContent: "",
        route: "/es/Todos-los-deportes/Fútbol-Americano",
        popularity: 13,
        altText: "Icono de fútbol americano"
      },
      {
        image: athletics,
        name: "Atletismo",
        badgeContent: "Hombres",
        secondBadgeContent: "Mujeres",
        route: "/es/Todos-los-deportes/Atletismo",
        popularity: 5,
        altText: "Icono de atletismo"
      },
      {
        image: basketball,
        name: "Baloncesto",
        badgeContent: "Hombres",
        secondBadgeContent: "Mujeres",
        route: "/es/Todos-los-deportes/Baloncesto",
        popularity: 2,
        altText: "Icono de baloncesto"
      },
      {
        image: golf,
        name: "Golf",
        badgeContent: "Hombres",
        secondBadgeContent: "Mujeres",
        route: "/es/Todos-los-deportes/Golf",
        popularity: 7,
        altText: "Icono de golf"
      },
      {
        image: iceHockey,
        name: "Hockey sobre Hielo",
        badgeContent: "Hombres",
        secondBadgeContent: "Mujeres",
        route: "/es/Todos-los-deportes/Hockey-sobre-hielo",
        popularity: 12,
        altText: "Icono de hockey sobre hielo"
      },
      {
        image: fieldHockey,
        name: "Hockey sobre Césped",
        badgeContent: "",
        secondBadgeContent: "Mujeres",
        route: "/es/Todos-los-deportes/Hockey",
        popularity: 10,
        altText: "Icono de hockey sobre césped"
      },
      {
        image: baseball,
        name: "Béisbol",
        badgeContent: "Hombres",
        secondBadgeContent: "",
        route: "/es/Todos-los-deportes/Béisbol",
        popularity: 15,
        altText: "Icono de béisbol"
      },
      {
        image: rowing,
        name: "Remo",
        badgeContent: "Hombres",
        secondBadgeContent: "Mujeres",
        route: "/es/Todos-los-deportes/Remo",
        popularity: 11,
        altText: "Icono de remo"
      },
      {
        image: soccer,
        name: "Fútbol",
        badgeContent: "Hombres",
        secondBadgeContent: "Mujeres",
        route: "/es/Todos-los-deportes/Fútbol",
        popularity: 1,
        altText: "Icono de fútbol"
      },
      {
        image: softball,
        name: "Softball",
        badgeContent: "",
        secondBadgeContent: "Mujeres",
        route: "/es/Todos-los-deportes/Softball",
        popularity: 14,
        altText: "Icono de softball"
      },
      {
        image: tennis,
        name: "Tenis",
        badgeContent: "Hombres",
        secondBadgeContent: "Mujeres",
        route: "/es/Todos-los-deportes/Tenis",
        popularity: 3,
        altText: "Icono de tenis"
      },
      {
        image: gymnastics,
        name: "Gimnasia",
        badgeContent: "Hombres",
        secondBadgeContent: "Mujeres",
        route: "/es/Todos-los-deportes/Gimnasia",
        popularity: 8,
        altText: "Icono de gimnasia"
      },
      {
        image: swimming,
        name: "Natación",
        badgeContent: "Hombres",
        secondBadgeContent: "Mujeres",
        route: "/es/Todos-los-deportes/Natación",
        popularity: 4,
        altText: "Icono de Natación"
      },
      {
        image: volleyball,
        name: "Voleibol",
        badgeContent: "Hombres",
        secondBadgeContent: "Mujeres",
        route: "/es/Todos-los-deportes/Voleibol",
        popularity: 6,
        altText: "Icono de voleibol"
      },
      {
        image: waterPolo,
        name: "Waterpolo",
        badgeContent: "Hombres",
        secondBadgeContent: "Mujeres",
        route: "/es/Todos-los-deportes/Waterpolo",
        popularity: 9,
        altText: "Icono de waterpolo"
      },
      {
        image: wrestling,
        name: "Lucha",
        badgeContent: "Hombres",
        secondBadgeContent: "Mujeres",
        route: "/es/Todos-los-deportes/Lucha",
        popularity: 16,
        altText: "Icono de lucha"
      },
    ],
  },
];
