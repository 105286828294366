/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Golf1.jpeg";
import Image2 from "assets/images/Sports_Images/Golf2.webp";
import Image3 from "assets/images/Sports_Images/Golf3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"


// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Golfstipendium: Hur man Studerar och Spelar Golf i Amerika', isSubheader: false },
  
    { id: 'Hoofdstuk 1', title: 'Kapitel 1: Historia och Utveckling av Collegegolf', isSubheader: false },
    { id: '1.1', title: '1.1 Tidiga År', isSubheader: true },
    { id: '1.2', title: '1.2 Tillväxt och Professionalisering', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapitel 2: Den Nuvarande Landskapet för Collegegolf', isSubheader: false },
    { id: '2.1', title: '2.1 Stora Tävlingar och Konferenser', isSubheader: true },
    { id: '2.2', title: '2.2 Toppprogram och Prestigefyllda Lag', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapitel 3: Från College till Proffsen', isSubheader: false },
    { id: '3.1', title: '3.1 Vägen till den Professionella Cirkeln', isSubheader: true },
    { id: '3.2', title: '3.2 Alternativa Vägar till Professionalism', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapitel 4: Internationella Influenser och Möjligheter', isSubheader: false },
    { id: '4.1', title: '4.1 Utländska Spelare i Collegegolfen', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanska Spelare Utomlands', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapitel 5: Idrottsstipendier och Framtiden för Idrottare', isSubheader: false },
    { id: '5.1', title: '5.1 Vikten av Stipendier', isSubheader: true },
    { id: '5.2', title: '5.2 Hur Sport Scholarships America Kan Hjälpa', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };





  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innehåll
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Dölj</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Visa</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>






















          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Golfstipendium: Hur man Studerar och Spelar Golf i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Golf green under NCAA-mästerskapet, med spelare och åskådare runt banan"
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        NCAA Golfmästerskap
      </figcaption>
    </figure>
    Golf är en av de mest populära sporterna på collegenivå i USA. Varje år tävlar tusentals studentatleter om nationella titlar, prestigefyllda utmärkelser och en plats i proffsgolfen. Collegegolfen erbjuder inte bara en chans att förbättra dina idrottsliga färdigheter utan också att erhålla en akademisk examen, bygga upp ett nätverk och få en oförglömlig erfarenhet.
    <br/>
    <br/>
    Dock är collegegolfen inte för alla. Det kräver en hög nivå av engagemang, disciplin och talang. Dessutom är det inte lätt att bli antagen till ett universitet med ett bra golfprogram. Konkurrensen är hård, och kostnaderna är höga.
    <br/>
    <br/>
    Lyckligtvis finns det möjligheter att förverkliga din dröm med hjälp av ett golfstipendium. Ett golfstipendium är ekonomiskt stöd som du får från ett universitet för att studera och spela golf där. Med ett golfstipendium kan du minska eller till och med helt täcka dina studiekostnader samtidigt som du njuter av alla fördelar med collegegolfen.
    <br/>
    <br/>
    Men hur får man ett golfstipendium? Och vad ska man tänka på när man väljer ett universitet? Det är där Sportbeursamerika.nl kan hjälpa dig. Sportbeursamerika.nl är specialisten på att guida studenter till idrottsstipendier i Amerika. Jag har erfarenhet och kunskap om det amerikanska utbildnings- och idrottssystemet, och jag har hjälpt många studentatleter att uppnå sina drömmar.
    <br/>
    <br/>
    I den här artikeln tillhandahåller vi mer information om collegegolfen och visar hur Sportbeursamerika.nl kan stödja dig i att hitta det perfekta golfstipendiet för dig.
    <br/>
    <br/>
    Vill du veta mer? Fortsätt läsa!
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Hoofdstuk 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Historia och Utveckling av Collegegolf
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Collegegolfen har en lång och rik historia som sträcker sig tillbaka till början av 1900-talet. I det här kapitlet kommer vi att titta på de tidiga åren och tillväxten och professionaliseringen av collegegolfen i USA.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidiga År
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      De första golfmästerskapen vid universitet och högskolor i USA ägde rum på 1890-talet. Den första officiella intercollegiate golfmatchen var mellan Harvard och Yale år 1897. År 1901 grundades Intercollegiate Golf Association (IGA), som organiserade det första nationella mästerskapet år 1902. Yale vann den första titeln och fortsatte att dominera fram till 1920-talet.
      <br/>
      <br/>
      De första golfprogrammen vid universitet var främst inriktade på den sociala och rekreativa aspekten av sporten. Studenter spelade mestadels på lokala banor och hade lite vägledning eller faciliteter. Golflagen bestod vanligtvis av fyra eller fem spelare som kvalificerade sig genom interna turneringar.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Tillväxt och Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      På 1930-talet började collegegolfen växa och professionaliseras betydligt. Under denna period ökade antalet universitetsgolfprogram och kvaliteten på träning och faciliteter förbättrades. Professionaliseringen stimulerades ytterligare av det ökande intresset för sporten och den växande erkännandet av golfens potential på collegenivå. Under denna tid tog National Collegiate Athletic Association (NCAA) en ledande roll i att organisera och reglera collegegolfen, vilket bidrog till ytterligare utveckling och popularitet för sporten i USA.
      <br/>
      <br/>
      NCAA tog över organisationen av det nationella mästerskapet från IGA och införde nya regler och format. NCAA delade upp universiteten i olika divisioner, baserat på deras storlek och idrottsnivå. NCAA organiserade också regionala mästerskap för att underlätta kvalificeringen till det nationella mästerskapet.
      <br/>
      <br/>
      Universiteten började också investera mer i sina golfprogram genom att anställa professionella tränare, bygga eller förbättra golfbanor och erbjuda stipendier till talangfulla spelare. Golflagen blev större och starkare, och konkurrensen blev intensivare.
      <br/>
      <br/>
      På 1940- och 1950-talen lades fler viktiga turneringar också till, såsom Walker Cup, Palmer Cup och Eisenhower Trophy. Dessa turneringar gav collegespelare chansen att tävla med internationella spelare och förbättra sina färdigheter.
      <br/>
      <br/>
      I det här kapitlet har vi sett hur collegegolfen uppstod och växte i USA. I nästa kapitel ska vi titta på den nuvarande landskapet för collegegolfen och vilka tävlingar och program som finns tillgängliga.
    </MKTypography>
  </MKBox>
</MKBox>













                                              





                                  







<MKBox id="Chapter 2" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Kapitel 2: Den Nuvarande Landskapet för Collegegolf
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Idag är collegegolf en av de mest konkurrenskraftiga och prestigefyllda sporterna på universitetsnivå i USA. I det här kapitlet kommer vi att titta på de stora tävlingarna och konferenserna, samt de bästa programmen och prestigefyllda lagen inom collegegolfen.
    </MKTypography>

    <MKBox id="2.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.1 Stora Tävlingar och Konferenser
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image2}
                    alt="Tiger Woods representerar Stanford i collegegolf, visas i matchkoncentration."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal2(Image2)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Tiger Woods i aktion för Stanford.
                </figcaption>
            </figure>
            NCAA är den största och viktigaste organisationen för collegegolf i USA. NCAA delar upp universiteten i tre divisioner: Division I, Division II och Division III. Varje division har sitt eget mästerskap, som hålls årligen i maj eller juni. Mästerskapen består av en lag- och en individuell turnering, där de bästa spelarna och lagen från varje division tävlar om den nationella titeln.
            <br/>
            <br/>
            NCAA har också flera regionala konferenser, som består av universitet som är geografiskt eller historiskt anslutna. Varje konferens har sitt eget mästerskap, som fungerar som en kvalifikationsturnering för det nationella mästerskapet. Vissa konferenser är starkare än andra och har större inflytande på collegegolfens landskap.
            <br/>
            <br/>
            Några av de mest välkända konferenserna inkluderar:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Atlantic Coast Conference (ACC), som består av 15 universitet på Östkusten, såsom Duke, North Carolina och Clemson.
                </li>
                <li>
                    Big Ten Conference, som består av 14 universitet i Mellanvästern, såsom Michigan, Ohio State och Illinois.
                </li>
                <li>
                    Pac-12 Conference, som består av 12 universitet på Västkusten, såsom Stanford, California och Arizona State.
                </li>
                <li>
                    Southeastern Conference (SEC), som består av 14 universitet i sydöstra USA, såsom Alabama, Georgia och Florida.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Förutom NCAA finns det också andra organisationer som organiserar collegegolftävlingar, såsom National Association of Intercollegiate Athletics (NAIA), National Junior College Athletic Association (NJCAA) och National Christian College Athletic Association (NCCAA). Dessa organisationer har sina egna divisioner, konferenser och mästerskap, som generellt sett är mindre och mindre prestigefyllda än de som arrangeras av NCAA.
        </MKTypography>
    </MKBox>
    <MKBox id="2.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.2 Toppprogram och Prestigefyllda Lag
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Collegegolfen är inte bara en sport utan också en utbildning. Universiteten som erbjuder collegegolf har olika program, varierande i kvalitet, rykte och faciliteter. Vissa program är ledande och prestigefyllda och lockar de bästa spelarna och tränarna. Andra program är mindre kända eller framgångsrika men erbjuder fortfarande goda möjligheter för studentatleter.
            <br/>
            <br/>
            Några av faktorerna som avgör ett programs kvalitet inkluderar:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Programmets historia och tradition.
                </li>
                <li>
                    Lagets nivå och resultat.
                </li>
                <li>
                    Tränarens kvalitet och erfarenhet.
                </li>
                <li>
                    Programmets faciliteter och utrustning.
                </li>
                <li>
                    Programmets akademiska standarder och krav.
                </li>
                <li>
                    Programmets atmosfär och kultur.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Några av de mest framstående collegegolfprogrammen inkluderar:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Stanford University: Stanford har ett av de äldsta och mest framgångsrika golfprogrammen i USA, med flera nationella titlar inom herrgolf. Programmet har producerat kända alumner som Tiger Woods och Tom Watson. Stanfords damlag i golf har också fått nationell erkännande med flera NCAA-titlar och individuella mästare.
                </li>
                <li>
                    Oklahoma State University: Oklahoma State har ett dominerande golfprogram, särskilt känt för sitt herrlag, med flera nationella titlar. Programmet har producerat toppgolfare som Rickie Fowler och Viktor Hovland.
                </li>
                <li>
                    Duke University: Duke är känt för sitt starka damlag i golf, med flera NCAA-titlar. Dukes herrlag i golf har presterat starkt i konferensmästerskap och har en respektabel historia i NCAA-tävlingar. Duke har producerat talangfulla golfare, inklusive Amanda Blumenherst och Brittany Lang.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            I det här kapitlet har vi sett hur det nuvarande landskapet för collegegolf ser ut och vilka tävlingar och program som är tillgängliga. I nästa kapitel kommer vi att titta på hur man går från college till proffs och vilka alternativa vägar som finns.
        </MKTypography>
    </MKBox>
</MKBox>











                                              






                                  







<MKBox id="Chapter 3" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Kapitel 3: Från College till Proffsen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Collegegolf är inte bara en sport, utan också en karriärväg. Många collegespelare strävar efter att gå över till den professionella cirkeln och spela på den högsta nivån inom golfen. I det här kapitlet kommer vi att utforska hur collegegolf kan fungera som en trampsten till professionell golf och vilka alternativa vägar som finns tillgängliga.
    </MKTypography>

    <MKBox id="3.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.1 Vägen till den Professionella Cirkeln
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Collegegolf är en utmärkt förberedelse för en professionell karriär inom golfen. Collegespelare har möjlighet att utvecklas atletiskt, akademiskt och personligt. De lär sig hantera tryck, konkurrens och förväntningar. De spelar på utmanande banor mot starka motståndare och under olika förhållanden. De har också tillgång till högkvalitativa faciliteter, tränare och vägledning.
            <br/>
            <br/>
            Collegespelare har också en fördel jämfört med andra spelare som inte går på universitet. De har en examen som kan hjälpa dem att hitta ett jobb eller en sponsor om de inte lyckas som professionella. De har också ett nätverk av kontakter och vänner som kan stödja eller ge råd i deras karriärer.
            <br/>
            <br/>
            Många collegespelare övergår till den professionella cirkeln efter att ha avslutat sina studier. Vissa gör det tidigare om de tror att de är redo. För att bli professionell måste de kvalificera sig för en tour, som PGA-touren, LPGA-touren eller Europatouren, genom kvalifikationstävlingar som Q-School eller Korn Ferry Tour.
            <br/>
            <br/>
            Många tidigare collegespelare har haft framgång på den professionella cirkeln. Exempel inkluderar:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Phil Mickelson: Mickelson spelade collegegolf för Arizona State University, där han vann NCAA-mästerskapet tre gånger. Han blev professionell 1992 och har sedan dess uppnått talrika segrar på PGA-touren, inklusive flera major-titlar.
                </li>
                <li>
                    Annika Sörenstam: Sörenstam spelade collegegolf för University of Arizona, där hon vann NCAA-mästerskapet 1991. Hon blev professionell 1992 och har sedan dess uppnått talrika professionella segrar, inklusive flera major-titlar.
                </li>
                <li>
                    Jon Rahm: Rahm spelade collegegolf för Arizona State University, där han vann Ben Hogan Award två gånger som den bästa collegespelaren. Han blev professionell 2016 och har sedan dess uppnått flera professionella segrar, inklusive major-titlar.
                </li>
            </ul>
        </MKTypography>
    </MKBox>
    <MKBox id="3.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.2 Alternativa Vägar till Professionalism
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Även om collegegolf är en vanlig väg till den professionella cirkeln är det inte den enda. Det finns andra vägar som spelare kan följa för att uppnå sin dröm. Vissa spelare väljer att inte gå på universitet utan att bli professionella direkt efter gymnasiet. Andra går på universitet men lämnar tidigt för att helt fokusera på sin sport.
            <br/>
            <br/>
            Exempel på spelare som tog en alternativ väg inkluderar:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Tiger Woods: Woods spelade collegegolf för Stanford University, där han vann NCAA-mästerskapet två gånger. Han blev dock professionell 1996 efter två års studier. Han har sedan dess uppnått talrika segrar på PGA-touren, inklusive flera major-titlar.
                </li>
                <li>
                    Lydia Ko: Ko spelade aldrig collegegolf men blev professionell 2013 vid 16 års ålder. Hon har sedan dess uppnått flera segrar på LPGA-touren.
                </li>
                <li>
                    Jordan Spieth: Spieth spelade collegegolf för University of Texas, där han vann NCAA-mästerskapet 2012. Han blev dock professionell 2012 efter ett års studier. Han har sedan dess uppnått flera segrar på PGA-touren, inklusive flera major-titlar.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            I det här kapitlet har vi sett hur man går från college till proffsen och vilka alternativa vägar som finns tillgängliga. I nästa kapitel kommer vi att utforska de internationella influenserna och möjligheterna inom collegegolf.
        </MKTypography>
    </MKBox>
</MKBox>





                                              






                                  







<MKBox id="Chapter 4" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Kapitel 4: Internationella Influenser och Möjligheter
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Collegegolf är inte bara en amerikansk sport, utan också en internationell. Det finns många utländska spelare som kommer till USA för att studera och spela golf, och det finns också många amerikanska spelare som spelar utomlands. I det här kapitlet kommer vi att titta på påverkan och bidragen från internationella studentidrottare och möjligheterna för amerikanska collegespelare att spela internationellt.
    </MKTypography>

    <MKBox id="4.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.1 Utländska Spelare i Collegegolfen
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image3}
                    alt="Texas Longhorns, med Jordan Spieth, firar det nationella mästerskapet"
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal3(Image3)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Texas Longhorns, med Jordan Spieth, firar det nationella mästerskapet
                </figcaption>
            </figure>
            Collegegolf är ett attraktivt alternativ för många utländska spelare som letar efter en kombination av idrott och utbildning. De kan dra nytta av hög kvalitet på amerikanska universitet, de utmärkta faciliteterna och vägledningen från golfprogrammen samt den starka konkurrensen och exponeringen av collegespelturneringar.
            <br/>
            <br/>
            Utländska spelare erbjuder också mycket till amerikanska universitet. De bidrar med mångfald, kultur och talang till sina lag. De kan också dela med sig av sina erfarenheter och perspektiv med sina lagkamrater och tränare, vilket bidrar till deras utveckling.
            <br/>
            <br/>
            Många utländska spelare har varit framgångsrika i collegegolfen och har utmärkt sig som studentidrottare. Exempel inkluderar:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Maria Fassi: Fassi spelade collegegolf vid University of Arkansas, där hon vann ANNIKA Award två gånger som den bästa kvinnliga collegespelaren. Hon blev professionell 2019 och har sedan dess uppnått flera professionella segrar.
                </li>
                <li>
                    Viktor Hovland: Hovland spelade collegegolf vid Oklahoma State University, där han vann NCAA-mästerskapet 2018. Han blev professionell 2019 och har sedan dess uppnått flera professionella segrar.
                </li>
            </ul>
        </MKTypography>
    </MKBox>
    <MKBox id="4.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.2 Amerikanska Spelare Utomlands
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Collegegolfen är inte det enda sättet att utvecklas som golfspelare. Det finns också många möjligheter för amerikanska collegespelare att spela internationellt och uppleva andra kulturer och banor. Detta kan bredda deras vyer, öka deras anpassningsförmåga och förbättra deras spel.
            <br/>
            <br/>
            Amerikanska collegespelare kan spela internationellt genom olika program, såsom:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Palmer Cup: En årlig turnering mellan lag av amerikanska och internationella collegespelare, uppkallad efter Arnold Palmer, en känd golfare som också spelade collegegolf. Turneringen hålls på olika platser runt om i världen, som Frankrike, Irland, England och Sverige.
                </li>
                <li>
                    Eisenhower Trophy: En biennal turnering mellan lag av amatörgolfare från olika länder, uppkallad efter Dwight D. Eisenhower, en tidigare amerikansk president och ivrig golfare. Turneringen hålls på olika platser runt om i världen, som Australien, Japan, Mexiko och Turkiet.
                </li>
                <li>
                    Studera Utomlands-programmet: Ett akademiskt program som erbjuder studenter möjlighet att studera under en termin eller ett år vid ett utländskt universitet. Vissa universitet har också golfprogram som samarbetar med utländska universitet, vilket gör att studentidrottare kan spela golf under sin vistelse.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Många amerikanska collegespelare har gynnats av att spela utomlands och har höjt sitt spel till en högre nivå. Exempel inkluderar:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Collin Morikawa: Morikawa spelade collegegolf vid University of California, Berkeley, där han blev fyra gånger All-American. Han spelade också i Palmer Cup 2017 och 2018 samt i Eisenhower Trophy 2018. Han blev professionell 2019 och har sedan dess uppnått flera professionella segrar.
                </li>
                <li>
                    Jennifer Kupcho: Kupcho spelade collegegolf vid Wake Forest University, där hon vann NCAA-mästerskapet 2018. Hon spelade också i Palmer Cup 2018 och i Curtis Cup 2016 och 2018. Hon blev professionell 2019 och har sedan dess uppnått flera professionella segrar.
                </li>
                <li>
                    Patrick Reed: Reed spelade collegegolf vid University of Georgia och Augusta State University, där han vann NCAA-mästerskapet två gånger. Han spelade också i Palmer Cup 2011 och studerade en termin vid University of Stirling i Skottland. Han blev professionell 2011 och har sedan dess uppnått flera professionella segrar.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            I det här kapitlet har vi sett hur collegegolf är en internationell sport och påverkan och möjligheter för utländska och amerikanska spelare. I nästa kapitel kommer vi att titta på betydelsen av golfstipendier och rollen för Sportbeursamerika.nl.
        </MKTypography>
    </MKBox>
</MKBox>











                                              






                                  






<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Idrottsstipendier och Framtiden för Idrottare
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrottsstipendier erbjuder många möjligheter för unga idrottare att visa upp sin talang och utveckla sina färdigheter. En av de mest betydelsefulla möjligheterna är att få ett idrottsstipendium för att studera och tävla på collegenivå. I det här kapitlet kommer vi att diskutera vikten av idrottsstipendier och hur Sport Scholarships America kan hjälpa dig att förverkliga din dröm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vikten av Stipendier
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ett idrottsstipendium är ekonomiskt stöd som tillhandahålls av ett universitet till en idrottare för att kombinera studier och idrott. Det kan täcka kostnaderna för undervisning, böcker, boende, måltider och andra utgifter. Dessutom kan det erbjuda fördelar som akademisk handledning, sjukvård, träningsanläggningar och utrustning.
      <br />
      <br />
      Att få ett idrottsstipendium är konkurrenskraftigt, med många faktorer som spelar en roll i tilldelningsprocessen, inklusive akademiska prestationer, idrottsprestationer, personlighet, motivation, ledarskap och anpassningsförmåga. Därför är det viktigt att förbereda sig väl och skilja sig från konkurrensen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hur Sport Scholarships America Kan Hjälpa
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotyp för Sport Scholarships America, med sportutrustning och en studentmössa, som representerar fokus på idrottsstipendier för idrottare."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotyp för Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America är en organisation specialiserad på att vägleda unga idrottstalanger till idrottsstipendier. Med passionerade och erfarna experter stöder Sport Scholarships America idrottare i varje steg av resan, från initial orientering till slutlig placering. Deras tjänster inkluderar:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intagningsintervju för att diskutera idrottarens önskemål, möjligheter och förväntningar.</li>
        <li>En professionell utvärdering av idrottarens akademiska och idrottsliga nivå.</li>
        <li>Ett omfattande nätverk av kontakter med tränare, universitet och högskolor.</li>
        <li>Effektiv marknadsföring av idrottarens profil och videor till potentiella tränare.</li>
        <li>Expertrådgivning om de bästa alternativen baserat på idrottarens preferenser, mål och budget.</li>
        <li>Förhandling om de bästa stipendieerbjudandena för idrottaren.</li>
        <li>Hjälp med att ansöka om nödvändiga dokument, såsom visum, betyg, examensbevis och antagningsprov.</li>
        <li>Förberedelse för idrottarens liv under stipendieperioden, inklusive kultur, språk, regler och tips.</li>
        <li>Uppföljning och uppföljning under stipendieperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vill du utforska möjligheter till stipendier och uppnå dina idrottsliga och akademiska mål? <Link to="/sv/Tjänster-Produkter">Se det fullständiga utbudet</Link> av tjänster som erbjuds av Sport Scholarships America och boka en konsultation idag.
    </MKTypography>
  </MKBox>
</MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;