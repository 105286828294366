/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";



// Images
import Image1 from "assets/images/Sports_Images/Soccer1.jpg";
import Image2 from "assets/images/Sports_Images/Soccer2.jpg";
import Image3 from "assets/images/Sports_Images/Soccer3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Spela Fotboll och Studera i Amerika: En Dröm som Går i Uppfyllelse', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapitel 1: Historia och Utveckling av Fotboll i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Tidiga År och Rollen för Collegen', isSubheader: true },
    { id: '1.2', title: '1.2 Tillväxt och Professionalisering', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapitel 2: Collegial Fotboll Idag', isSubheader: false },
    { id: '2.1', title: '2.1 Viktiga Tävlingar och Konferenser för Fotboll', isSubheader: true },
    { id: '2.2', title: '2.2 Toppprogram och Prestigefyllda Lag för Fotboll', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapitel 3: Från College till Proffsen', isSubheader: false },
    { id: '3.1', title: '3.1 Vägen till Professionalism', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapitel 4: Inflytandet från Internationell Fotboll', isSubheader: false },
    { id: '4.1', title: '4.1 Utländska Influenser', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikaner Utomlands', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapitel 5: Idrottsstipendier och Framtiden för Idrottare', isSubheader: false },
    { id: '5.1', title: '5.1 Vikten av Stipendier', isSubheader: true },
    { id: '5.2', title: '5.2 Hur Sport Scholarships America Kan Hjälpa', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innehåll
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Dölj</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Visa</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>























{/* Introduction */}
<MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Spela Fotboll och Studera i Amerika: En Dröm som Går i Uppfyllelse
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Creightons stadion, med plats för över 7000 åskådare, under en collegematch i fotboll."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Creightons Stadion: hem till över 7000 fotbollsfans.
      </figcaption>
    </figure>
    Fotboll är en av världens mest populära sporter, men i Amerika har den länge överglänsts av andra sporter som basket, baseboll och amerikansk fotboll. Men under de senaste decennierna har fotbollen vuxit enormt i popularitet och kvalitet i USA, tack vare bland annat landslagets framgångar, Major League Soccer (MLS) och internationella stjärnors inflytande.
    <br />
    <br />
    Men visste du att fotbollen i Amerika också erbjuder en unik möjlighet för unga talanger att följa sina drömmar? Genom att spela fotboll och studera i Amerika kan du inte bara utveckla dina idrottsliga färdigheter utan även få en akademisk examen och en oförglömlig upplevelse. Allt detta är möjligt tack vare de fotbollsstipendier som amerikanska universitet erbjuder till talangfulla spelare.
    <br />
    <br />
    I den här artikeln kommer vi att berätta allt du behöver veta om att spela fotboll och studera i Amerika, från historien och utvecklingen av fotbollen i USA till fördelarna och möjligheterna med ett fotbollsstipendium. Vi kommer också förklara hur Sportbeursamerika.nl kan hjälpa dig att förverkliga denna dröm. Så läs vidare och upptäck hur du kan dra nytta av denna unika möjlighet!
  </MKTypography>
</MKBox>

{/* Chapter 1 */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Historia och Utveckling av Fotboll i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Fotboll, eller soccer som amerikanerna kallar det, är inte en så ny sport i USA som du kanske tror. Faktum är att den första officiella fotbollsmatchen i Amerika ägde rum 1869, mellan två universitetslag: Princeton och Rutgers. Sedan dess har fotbollen haft en lång och fascinerande historia i USA, vilket vi ska diskutera i detta kapitel.
  </MKTypography>
  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidiga År och Rollen för Collegen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      I slutet av 1800-talet och början av 1900-talet var fotbollen särskilt populär bland studenter, som spelade spelet enligt olika regler och format. Till exempel fanns det lag som spelade med 11, 15 eller till och med 25 spelare och ibland använde element från rugby eller amerikansk fotboll.
      <br />
      <br />
      För att skapa mer enhet och struktur grundades Intercollegiate Association of Amateur Athletes of America (IAAAA) 1905, föregångaren till National Collegiate Athletic Association (NCAA). NCAA är den främsta organisationen som reglerar och organiserar collegial fotboll i USA.
      <br />
      <br />
      Under första halvan av 1900-talet växte antalet collegiala fotbollslag stadigt och regionala tävlingar och mästerskap uppstod också. En av de mest kända av dessa är College Cup, som hålls sedan 1959 som herrarnas nationella mästerskap. För kvinnor hölls den första College Cup bara 1982, men sedan dess har damfotbollen sett en enorm tillväxt i USA, delvis tack vare landslagets framgångar i internationella turneringar.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Tillväxt och Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Utöver collegial fotboll utvecklades också professionell fotboll i USA, även om det gick upp och ner. Den första professionella ligan var American Soccer League (ASL), grundad 1921 och bestående främst av lag från nordöstra USA. ASL hade några framgångsrika år men hamnade i konflikt med den amerikanska fotbollsfederationen över organisationen och regleringen av spelet. Detta ledde till en splittring och slutligen slutet för ASL 1933.
      <br />
      <br />
      Efter andra världskriget försökte olika ligor återuppliva professionell fotboll, men ingen av dem överlevde länge. Det var inte förrän 1967 som en stabil liga etablerades: North American Soccer League (NASL). NASL lockade några stora namn till Amerika, inklusive Pelé, Franz Beckenbauer och Johan Cruyff, och lockade stora publikmängder till arenorna. Men NASL kunde inte heller motstå ekonomiska problem, konkurrens från andra sporter och bristande mediainblandning. NASL upphörde att existera 1984.
      <br />
      <br />
      Det dröjde fram till 1996 innan en professionell liga etablerades igen: Major League Soccer (MLS). MLS är för närvarande den högsta divisionen i amerikansk fotboll och består av 27 lag från både USA och Kanada. MLS har utvecklats till en konkurrenskraftig och attraktiv liga, som lockar både inhemska och internationella spelare. MLS har också bidragit till populariseringen av fotbollen i Amerika genom att bygga arenor, stödja ungdomsutveckling och samarbeta med andra ligor.
      <br />
      <br />
      Professionell fotboll har också påverkat collegial fotboll, och vice versa. Till exempel finns det spelare som går över till MLS efter sina collegekarriärer eller spelare som återvänder till universitet för att slutföra sina studier efter MLS. Det finns också spelare som spelar både för sitt college och ett professionellt lag, tack vare särskilda arrangemang. Dessutom samarbetar vissa professionella lag med collegiala program för att scouta och utveckla talanger.
    </MKTypography>
  </MKBox>
</MKBox>

                                              





                                  







{/* Chapter 2 */}
<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Collegial Fotboll Idag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Som vi såg i det föregående kapitlet har collegial fotboll i Amerika en lång och rik tradition. Men hur ser det ut idag? Vilka tävlingar och konferenser finns det? Vilka program och lag är mest prestigefyllda och framgångsrika? Och hur kan du, som spelare, engagera dig i denna spännande värld? I detta kapitel ger vi dig en översikt över collegial fotboll idag och visar dig vad du kan förvänta dig om du väljer ett fotbollsstipendium i Amerika.
  </MKTypography>
  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Viktiga Tävlingar och Konferenser för Fotboll
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Santa Clara fotbollsspelare i aktion under NCAA-turneringen."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Fotbollsspelare i aktion under NCAA-turneringsmatch.
        </figcaption>
      </figure>
      Som tidigare nämnts är NCAA den främsta organisationen som reglerar och organiserar collegial fotboll i USA. NCAA består av tre divisioner: Division I, Division II och Division III. Varje division har sina egna regler, krav och stipendier för studentidrottare.
      <br />
      <br />
      Division I är den högsta och mest konkurrenskraftiga divisionen, där de bästa spelarna och lagen tävlar. Division I består av cirka 200 herrlag och 300 damlag, uppdelade i olika regionala konferenser. En konferens är en grupp av universitet som samarbetar för att organisera en tävling inom sin region. Några exempel på välkända konferenser är Atlantic Coast Conference (ACC), Big Ten Conference, Pac-12 Conference och Southeastern Conference (SEC).
      <br />
      <br />
      Varje år tävlar lagen inom sina konferenser om titeln och en plats i den nationella mästerskapet: College Cup. College Cup är en utslagsturnering som hålls i slutet av säsongen, vanligtvis i november och december. För att kvalificera sig för College Cup måste lagen först tjäna sin plats genom sin konferens eller genom ett at-large-bidrag tilldelat av ett urvalsutskott baserat på deras prestation under säsongen. College Cup består av 48 herrlag och 64 damlag, som tävlar i olika omgångar tills en mästare återstår.
      <br />
      <br />
      Division II och Division III är mindre konkurrenskraftiga än Division I men erbjuder fortfarande många möjligheter för studentidrottare att utvecklas och njuta av spelet. Division II består av cirka 160 herrlag och 200 damlag, också uppdelade i olika konferenser. Division II har också ett nationellt mästerskap, bestående av 36 herrlag och 48 damlag. Division III består av cirka 400 herrlag och 430 damlag, som också tävlar i konferenser. Division III erbjuder dock inga stipendier till studentidrottare men tillhandahåller andra former av ekonomiskt stöd. Division III har också ett nationellt mästerskap, bestående av 62 herrlag och 64 damlag.
      <br />
      <br />
      Förutom NCAA finns det andra organisationer som organiserar collegial fotboll i USA, som National Association of Intercollegiate Athletics (NAIA), National Junior College Athletic Association (NJCAA) och United States Collegiate Athletic Association (USCAA). Dessa organisationer har sina egna divisioner, regler, stipendier och mästerskap, som vanligtvis är mindre och mindre kända än de i NCAA.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Toppprogram och Prestigefyllda Lag för Fotboll
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Inom varje division och konferens finns det vissa program och lag som sticker ut på grund av sin historia, prestation, rykte, faciliteter och mer. Dessa program och lag lockar ofta de bästa spelarna, erbjuder de bästa stipendierna, har de bästa tränarna och personalen, har toppmoderna tränings- och spelanläggningar, har den största fanbasen och medieuppmärksamheten och producerar ofta spelare för den professionella cirkeln.
      <br />
      <br />
      Det är svårt att sammanställa en definitiv lista över de bästa programmen och lagen i collegial fotboll i Amerika eftersom många faktorer spelar in, och rankingarna kan förändras varje år. Men för att ge dig en idé kommer vi att nämna några exempel på program och lag som ofta anses vara bäst i collegial fotboll i Amerika.
      <br />
      <br />
      För herrar är ett av de mest prestigefyllda programmen University of Maryland, som har vunnit det nationella mästerskapet 4 gånger (1968, 2005, 2008 och 2018) och har producerat mer än 20 spelare som har spelat i MLS, som Omar Gonzalez, Graham Zusi och Zack Steffen. Andra ledande program för herrar inkluderar University of Virginia, Indiana University, Stanford University och Wake Forest University.
      <br />
      <br />
      För damer är ett av de mest dominerande programmen University of North Carolina, som har vunnit det nationella mästerskapet över 20 gånger och har producerat mer än 40 spelare som har spelat för landslaget, som Mia Hamm, Kristine Lilly och Tobin Heath. Andra ledande program för damer inkluderar University of Notre Dame, University of Portland, Stanford University och Florida State University.
      <br />
      <br />
      Detta är bara några exempel på de många utmärkta programmen och lagen i collegial fotboll i Amerika. Om du vill lära dig mer om olika program och lag kan du söka på nätet efter rankingar, statistik, recensioner och spelar- och tränarvittnesmål. Du kan också kontakta Sportbeursamerika.nl, som kan hjälpa dig att hitta det bästa programmet och laget som passar dig.
    </MKTypography>
  </MKBox>
</MKBox>




                                  






<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Från College till Proffsen
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Många unga fotbollsspelare drömmer om att en dag spela professionellt och efterlikna sina idoler på högsta nivå. Men hur kan du förverkliga den drömmen? Vilka steg måste du ta för att gå från college till proffsen? Och vilka möjligheter och utmaningar kommer du att möta längs vägen? I detta kapitel kommer vi att besvara dessa frågor och visa dig hur collegial fotboll kan fungera som en trampolin till en professionell karriär.
  </MKTypography>
  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Vägen till Professionalism
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      En av de mest betydelsefulla fördelarna med collegial fotboll är att den ger dig möjlighet att utvecklas både idrottsligt och akademiskt samtidigt som du njuter av studentlivet. Genom att spela fotboll och studera i Amerika kan du inte bara förbättra dina fotbollsfärdigheter utan också utöka din kunskap, bredda ditt nätverk, forma din personlighet och förbättra dina framtidsutsikter.
      <br />
      <br />
      Collegial fotboll kan också vara en trampolin till en professionell karriär om du önskar det. Det finns olika sätt att gå från collegial fotboll till den professionella cirkeln, både i Amerika och utomlands. De vanligaste metoderna inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          MLS SuperDraft: Detta är en årlig händelse där MLS-lag turas om att välja spelare från en pool av tillgängliga spelare, varav de flesta kommer från collegial fotboll. MLS SuperDraft består av fyra rundor, med varje lag som gör en pick per runda. Ordningen för valen bestäms av olika faktorer som lagets prestation under föregående säsong, lotteri och potentiella affärer. MLS SuperDraft är ett av de primära sätten för collegiala fotbollsspelare att komma in i MLS och erbjuder möjligheten att säkra ett kontrakt och stipendium.
        </li>
        <li>
          MLS Homegrown Player Rule: Denna regel tillåter MLS-lag att skriva kontrakt med spelare från sina egna ungdomsakademier utan att använda ett draftval. Regeln syftar till att uppmuntra och belöna utvecklingen av lokala talanger. Många MLS-lag har partnerskap med collegiala program, vilket gör att de kan spåra och handleda spelare som spelar för både sitt college och ungdomslag. MLS Homegrown Player Rule är ett annat sätt för collegiala fotbollsspelare att komma in i MLS och erbjuder möjligheten att säkra ett kontrakt och stipendium.
        </li>
        <li>
          Generation Adidas Program: Sponsrat av Adidas, är detta program utformat för att hjälpa unga spelare att övergå från collegial fotboll till professionell fotboll. Programmet väljer lovande spelare, vanligtvis under 20 år, som ännu inte har examen från sitt universitet. Dessa spelare erbjuds kontrakt av MLS, vilket gör att de kan delta i MLS SuperDraft utan att förlora sin collegiala berättigande. Detta innebär att de fortfarande kan återvända till sitt universitet för att slutföra sina studier om de önskar. Programmet erbjuder också utbildningsstipendier till spelare för att täcka deras studiekostnader.
        </li>
        <li>
          Utlandschanser: Detta är möjligheter för collegiala fotbollsspelare att spela utomlands, till exempel i Europa, Asien eller Sydamerika. Dessa möjligheter beror vanligtvis på olika faktorer som spelarens nivå, nationalitet, ålder och nätverk. Vissa spelare kan till exempel dra nytta av dubbel nationalitet eller familjebakgrund för att kvalificera sig för ett utländskt pass, vilket gör det lättare för dem att spela i ett annat land. Andra spelare kan dra nytta av sina kontakter med scouter, agenter eller tränare som kan hjälpa dem att säkra ett kontrakt med en utländsk klubb. Utlandschanser är ett annat sätt för collegiala fotbollsspelare att följa en professionell karriär, men de kommer också med fler risker och osäkerheter.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Detta är bara några av sätten du kan gå från college till proffsen, men det finns fler. Nyckeln är att vara medveten om de möjligheter som finns och att förbereda dig för de utmaningar du kan möta. Det är också viktigt att vara realistisk om dina chanser och förväntningar och inte lägga alla dina ägg i en korg. Collegial fotboll är en fantastisk möjlighet att utvecklas som spelare och som person. Därför är det klokt att hålla andra alternativ öppna och inte bara fokusera på fotboll utan också på dina studier, hobbyer, vänner och familj.
      <br />
      <br />
      I nästa kapitel kommer vi att utforska den internationella fotbollens inflytande på collegial fotboll och hur det påverkar och inspirerar collegiala fotbollsspelare.
    </MKTypography>
  </MKBox>
</MKBox>






                                  







<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Inflytandet från Internationell Fotboll
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Fotboll är en global sport som förbinder miljontals människor över hela världen. Det är inte förvånande att internationell fotboll har ett betydande inflytande på collegial fotboll i Amerika. I detta kapitel kommer vi att utforska hur utländska influenser påverkar och inspirerar collegial fotboll och hur amerikanska spelare hittar sin väg till främmande länder.
  </MKTypography>
  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Utländska Influenser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Den eftertraktade NCAA-trofén."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Den eftertraktade NCAA-trofén
        </figcaption>
      </figure>
      Populariteten för internationell fotboll i USA har ökat avsevärt de senaste åren. Enligt en Gallup-undersökning från 2023 är fotboll den tredje mest populära sporten bland amerikaner, efter amerikansk fotboll och basket. Detta beror delvis på den ökande mångfalden i den amerikanska befolkningen, tillgängligheten av internationella matcher via TV och internet samt framgången för det amerikanska landslaget på världsarenan.
      <br />
      <br />
      Detta intresse för internationell fotboll har också påverkat collegial fotboll. Många collegiala fotbollsspelare ser upp till sina favoritutländska spelare och lag och försöker efterlikna deras spelstil och teknik. Vissa collegiala fotbollsspelare har till och med utländsk bakgrund eller dubbelt medborgarskap, vilket skapar en speciell anknytning till ett visst land eller region.
      <br />
      <br />
      Ett exempel är Gio Reyna, son till den före detta amerikanska landslagsspelaren Claudio Reyna. Gio Reyna föddes i England, där hans far spelade för Sunderland och Manchester City. Han växte upp med en kärlek till engelsk fotboll och spelade för New York City FC:s ungdomslag. År 2023 flyttade han till Borussia Dortmund i Tyskland, där han snabbt blev en av Europas bästa talanger. Han gjorde också debut i det amerikanska landslaget och bildade en ny generation tillsammans med andra unga stjärnor som Christian Pulisic och Weston McKennie.
      <br />
      <br />
      Ett annat exempel är Catarina Macario, en brasiliansk forward som flyttade till USA vid tolv års ålder. Hon spelade för Stanford University, där hon vann Hermann Trophy två gånger som landets bästa collegiala fotbollsspelare. Hon erhöll också amerikanskt medborgarskap, vilket möjliggjorde för henne att representera det amerikanska damlandslaget. År 2023 skrev hon på ett kontrakt med Olympique Lyon i Frankrike, ett av världens främsta damlag.
      <br />
      <br />
      Dessa exempel illustrerar hur utländska influenser berikar och diversifierar collegial fotboll och hur collegiala fotbollsspelare kan uppnå sina drömmar inom internationell fotboll.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikaner Utomlands
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Inte bara påverkar utländska influenser collegial fotboll, utan amerikanska spelare som söker sin lycka utomlands spelar också en betydande roll. Ett ökande antal amerikanska spelare väljer att flytta till Europa eller andra kontinenter efter sina collegiala karriärer för att tävla mot världens bästa spelare.
      <br />
      <br />
      Ett av de mest framgångsrika exemplen är Clint Dempsey, som spelade för Furman University innan han anslöt sig till MLS. År 2023 flyttade han till Fulham i England, där han framträdde som en produktiv målskytt. Han gjorde över 50 mål för klubben, inklusive ett historiskt mål mot Juventus i Europa League. Han spelade också för Tottenham Hotspur och Seattle Sounders och var en nyckelspelare för det amerikanska landslaget.
      <br />
      <br />
      En annan framgångshistoria är Tobin Heath, som spelade för North Carolina University, där hon vann två nationella mästerskap. Hon spelade också för olika klubbar i USA, Frankrike och England, inklusive Paris Saint-Germain och Manchester United. Hon vann fyra olympiska guldmedaljer och två FIFA World Cups med det amerikanska damlandslaget, där hon är känd för sina tekniska färdigheter och kreativitet.
      <br />
      <br />
      Dessa exempel visar hur amerikanska spelare kan utvecklas och utmärka sig utomlands och hur de kan sätta collegial fotboll på kartan som ett talentinkubator.
    </MKTypography>
  </MKBox>
</MKBox>





                                  






<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Idrottsstipendier och Framtiden för Idrottare
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrottsstipendier erbjuder många möjligheter för unga idrottare att visa upp sin talang och utveckla sina färdigheter. En av de mest betydelsefulla möjligheterna är att få ett idrottsstipendium för att studera och tävla på collegenivå. I det här kapitlet kommer vi att diskutera vikten av idrottsstipendier och hur Sport Scholarships America kan hjälpa dig att förverkliga din dröm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vikten av Stipendier
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ett idrottsstipendium är ekonomiskt stöd som tillhandahålls av ett universitet till en idrottare för att kombinera studier och idrott. Det kan täcka kostnaderna för undervisning, böcker, boende, måltider och andra utgifter. Dessutom kan det erbjuda fördelar som akademisk handledning, sjukvård, träningsanläggningar och utrustning.
      <br />
      <br />
      Att få ett idrottsstipendium är konkurrenskraftigt, med många faktorer som spelar en roll i tilldelningsprocessen, inklusive akademiska prestationer, idrottsprestationer, personlighet, motivation, ledarskap och anpassningsförmåga. Därför är det viktigt att förbereda sig väl och skilja sig från konkurrensen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hur Sport Scholarships America Kan Hjälpa
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotyp för Sport Scholarships America, med sportutrustning och en studentmössa, som representerar fokus på idrottsstipendier för idrottare."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotyp för Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America är en organisation specialiserad på att vägleda unga idrottstalanger till idrottsstipendier. Med passionerade och erfarna experter stöder Sport Scholarships America idrottare i varje steg av resan, från initial orientering till slutlig placering. Deras tjänster inkluderar:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intagningsintervju för att diskutera idrottarens önskemål, möjligheter och förväntningar.</li>
        <li>En professionell utvärdering av idrottarens akademiska och idrottsliga nivå.</li>
        <li>Ett omfattande nätverk av kontakter med tränare, universitet och högskolor.</li>
        <li>Effektiv marknadsföring av idrottarens profil och videor till potentiella tränare.</li>
        <li>Expertrådgivning om de bästa alternativen baserat på idrottarens preferenser, mål och budget.</li>
        <li>Förhandling om de bästa stipendieerbjudandena för idrottaren.</li>
        <li>Hjälp med att ansöka om nödvändiga dokument, såsom visum, betyg, examensbevis och antagningsprov.</li>
        <li>Förberedelse för idrottarens liv under stipendieperioden, inklusive kultur, språk, regler och tips.</li>
        <li>Uppföljning och uppföljning under stipendieperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vill du utforska möjligheter till stipendier och uppnå dina idrottsliga och akademiska mål? <Link to="/sv/Tjänster-Produkter">Se det fullständiga utbudet</Link> av tjänster som erbjuds av Sport Scholarships America och boka en konsultation idag.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;