/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Baseball1.jpg";
import Image2 from "assets/images/Sports_Images/Baseball2.jpeg";
import Image3 from "assets/images/Sports_Images/Baseball3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';

import "../../../../H1asH3.css"

const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Baseball und Studieren in Amerika: Ein Traum, der wahr werden kann', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapitel 1: Die Geschichte und Entwicklung des College-Baseballs', isSubheader: false },
    { id: '1.1', title: '1.1 Frühe Jahre und die Rolle der Universitäten', isSubheader: true },
    { id: '1.2', title: '1.2 Wachstum und Professionalisierung', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapitel 2: College-Baseball heute', isSubheader: false },
    { id: '2.1', title: '2.1 Wichtige Ligen und Konferenzen', isSubheader: true },
    { id: '2.2', title: '2.2 Top-Programme und prestigeträchtige Teams', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapitel 3: Vom College zu den Profis', isSubheader: false },
    { id: '3.1', title: '3.1 Der Weg zum Profisport', isSubheader: true },
    { id: '3.2', title: '3.2 Der MLB Draft und andere professionelle Möglichkeiten', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapitel 4: Internationale Einflüsse', isSubheader: false },
    { id: '4.1', title: '4.1 Ausländische Spieler im College-Baseball', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikaner im Ausland', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapitel 5: Sportstipendien und die Zukunft der Athleten', isSubheader: false },
    { id: '5.1', title: '5.1 Die Bedeutung von Stipendien', isSubheader: true },
    { id: '5.2', title: '5.2 Wie Sportstipendien Amerika helfen können', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhaltsverzeichnis
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verstecken</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Anzeigen</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Baseball und Studieren in Amerika: Ein Traum, der wahr werden kann
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Luftaufnahme des Stadions während der College World Series."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Die College World Series
      </figcaption>
    </figure>
    Baseball ist einer der beliebtesten und bedeutendsten Sportarten in den Vereinigten Staaten. Millionen von Menschen verfolgen Major League Baseball (MLB), die höchste professionelle Baseballliga der Welt. Aber bevor du es in die MLB schaffst, musst du deine Fähigkeiten zunächst auf einer anderen Ebene entwickeln und beweisen: im College-Baseball.
    <br />
    <br />
    College-Baseball wird von Studenten an amerikanischen Universitäten und Hochschulen gespielt. Es handelt sich um einen hochkompetitiven und angesehenen Sport, der viel Aufmerksamkeit und Talent anzieht. College-Baseball dient als entscheidender Schritt für Spieler, die professionell spielen möchten, und bietet auch die Möglichkeit, eine gute Ausbildung zu erhalten.
    <br />
    <br />
    Aber wie kommst du in den College-Baseball? Und wie kannst du ein Stipendium erhalten, um in den USA zu studieren und zu spielen? Hier kann dir Sportbeursamerika.nl helfen. Sportbeursamerika.nl ist eine Organisation, die sich darauf spezialisiert hat, Studentenathleten zu führen und zu unterstützen, die ihren Traum verwirklichen möchten. Ich kann dir bei allem helfen, was du brauchst, um eine geeignete Universität zu finden, ein Stipendium zu erhalten und dich auf das Leben in den USA vorzubereiten.
    <br />
    <br />
    In diesem Artikel werden wir dir alles erzählen, was du über College-Baseball in den USA wissen musst. Wir werden uns mit der Geschichte und Entwicklung dieses Sports, den wichtigsten Ligen und Konferenzen, Top-Programmen und angesehenen Teams, dem Weg zu den Profis, internationalen Einflüssen und natürlich der Bedeutung von Stipendien befassen. Nach dem Lesen dieses Artikels wirst du ein besseres Verständnis dafür haben, was College-Baseball bedeutet und wie du deine Chancen erhöhen kannst, ein Teil davon zu sein.
    <br />
    <br />
    Bist du bereit, deinen Traum wahr werden zu lassen? Lasst uns anfangen!
  </MKTypography>
</MKBox>

<MKBox id="Kapitel 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Die Geschichte und Entwicklung des College-Baseballs
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Baseball ist eine Sportart, die seit über einem Jahrhundert existiert. Aber wie entstand sie und wie entwickelte sie sich zu einer der beliebtesten und bedeutendsten Sportarten in den USA? In diesem Kapitel geben wir Ihnen einen kurzen Überblick über die Geschichte und Entwicklung des College-Baseballs.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Frühe Jahre und die Rolle der Universitäten
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Die Ursprünge des Baseballs sind nicht vollständig klar. Es gibt verschiedene Theorien darüber, wie und wo das Spiel begann. Einige sagen, es stammt von alten europäischen Spielen wie Cricket, Rounders oder Stoolball ab. Andere behaupten, es stamme von einem indianischen Spiel namens Lacrosse. Wieder andere schlagen vor, dass es von einem amerikanischen Soldaten namens Abner Doubleday im Jahr 1839 erfunden wurde.
      <br />
      <br />
      Sicher ist, dass Baseball schnell in den USA populär wurde, insbesondere in den Städten an der Ostküste. Es wurde von Amateuren, Clubs, Fabrikarbeitern und Soldaten gespielt. Es wurde auch von Studenten an Universitäten und Hochschulen gespielt, die ihre eigenen Teams und Regeln hatten.
      <br />
      <br />
      Das erste bekannte interkollegiale Baseballspiel in den Vereinigten Staaten fand 1859 zwischen dem Amherst College und dem Williams College statt, zwei angesehenen akademischen Institutionen in Massachusetts. Dieses Spiel ist historisch bedeutsam als eines der frühesten Beispiele für Baseballspiele auf Hochschulebene, eine Vorläuferin der späteren Entwicklung des College-Baseballs. Obwohl das genaue Ergebnis und der Verlauf des Spiels in historischen Quellen variieren können, markiert dieses Ereignis einen wichtigen Ausgangspunkt in der Geschichte des interkollegialen Baseballs. Es zeigt, wie Baseball in den frühen Tagen unter Studenten populär wurde und wie Universitäten wichtige Schauplätze für das Wachstum und die Entwicklung des Sports in den Vereinigten Staaten wurden.
      <br />
      <br />
      In den 1860er und 1870er Jahren wuchs die Anzahl der College-Teams stetig. Einige der frühesten Teams waren Harvard, Yale, Princeton, Dartmouth, Brown und Columbia. Diese Teams spielten hauptsächlich gegeneinander oder gegen lokale Clubs.
      <br />
      <br />
      Im Jahr 1879 fand in Springfield, Massachusetts, die erste interkollegiale Baseballkonferenz statt. Ziel war es, die Regeln und Standards des Spiels zu standardisieren und zu verbessern. Die Konferenz führte zur Gründung der American College Baseball Association, an der sechs angesehene Bildungseinrichtungen - Harvard, Yale, Princeton, Amherst, Dartmouth und Brown - beteiligt waren. Dies markierte den Beginn einer langen Tradition nationaler Meisterschaften im College-Baseball.
    </MKTypography>
  </MKBox>

  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Wachstum und Professionalisierung
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ende des 19. und Anfang des 20. Jahrhunderts wurde Baseball in den USA zunehmend populär und professionell. Die National League (NL) und die American League (AL) wurden als die wichtigsten professionellen Baseballligen gegründet und zogen viele Spieler, Fans und Geld an.
      <br />
      <br />
      Dies hatte auch Auswirkungen auf den College-Baseball. Viele College-Spieler wurden von professionellen Teams angeworben oder unter Vertrag genommen. Einige verließen ihre Universitäten, um professionell zu spielen, während andere ihr Studium fortsetzten, aber auch Teilzeit für professionelle Teams spielten.
      <br />
      <br />
      Dies führte zu verschiedenen Problemen und Konflikten im College-Baseball. Einige Universitäten waren gegen die Idee, dass ihre Spieler für das Spielen bezahlt wurden, da dies dem Amateurgeist und der akademischen Integrität des Collegesports widersprach. Sie wollten auch ihre besten Spieler für ihre eigenen Teams behalten.
      <br />
      <br />
      Andere Universitäten waren toleranter oder pragmatischer gegenüber dem Phänomen der professionellen Spieler. Sie erkannten, dass Baseball eine lukrative und angesehene Sportart war, die vielen Vorteile für ihre Institutionen bieten konnte. Sie wollten auch auf höchstem Niveau mit anderen Universitäten konkurrieren.
      <br />
      <br />
      Um diese Probleme anzugehen, wurden von verschiedenen Organisationen verschiedene Maßnahmen ergriffen. Die IBA wurde 1906 durch die National Collegiate Athletic Association (NCAA) ersetzt. Die NCAA übernahm die Verantwortung für die Regulierung und Organisation des College-Baseballs und anderer Sportarten. Die NCAA legte auch Regeln und Richtlinien für die Berechtigung und das Verhalten von College-Spielern fest.
      <br />
      <br />
      Die NCAA organisierte auch die erste College World Series (CWS) im Jahr 1947. Dies war die neue nationale Meisterschaft im College-Baseball, die jährlich in Omaha, Nebraska, stattfand. Die CWS wurde schnell zu einem der angesehensten und beliebtesten Ereignisse im College-Sport.
      <br />
      <br />
      Neben der NCAA gab es auch andere Organisationen, die am College-Baseball beteiligt waren. Eine davon war die National Association of Intercollegiate Athletics (NAIA), die 1937 gegründet wurde. Die NAIA war eine Alternative zur NCAA und konzentrierte sich auf kleinere Universitäten und Hochschulen. Die NAIA organisierte auch ihre eigene nationale Meisterschaft im College-Baseball.
      <br />
      <br />
      Eine andere Organisation war die National Junior College Athletic Association (NJCAA), die 1938 gegründet wurde. Die NJCAA war eine Organisation für Community Colleges, die zweijährige Programme anboten. Die NJCAA organisierte auch ihre eigene nationale Meisterschaft im College-Baseball.
      <br />
      <br />
      Diese Organisationen trugen alle zum Wachstum und zur Professionalisierung des College-Baseballs bei. Sie schufen mehr Möglichkeiten und Vielfalt für Spieler, Trainer, Schulen und Fans. Sie machten College-Baseball auch zu einem integralen Bestandteil des amerikanischen Baseballsystems.
    </MKTypography>
  </MKBox>
</MKBox>





                                  






<MKBox id="Kapitel 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: College-Baseball heute
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College-Baseball ist heute einer der beliebtesten und bedeutendsten Sportarten in den USA. Es zieht Millionen von Fans, Medienaufmerksamkeit und Sponsoren an. Es produziert Tausende von Spielern, Trainern und Offiziellen. Es bietet Hunderte von Stipendien, Auszeichnungen und Ehren.
    <br />
    <br />
    Aber wie sieht College-Baseball heute aus? Wie ist das System organisiert? Welche sind die wichtigsten Ligen und Konferenzen? Welche sind die Top-Programme und prestigeträchtigen Teams? In diesem Kapitel geben wir Ihnen einen Überblick über den College-Baseball heute.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Wichtige Ligen und Konferenzen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Barry Bonds in Aktion für Arizona State im College-Baseball."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Barry Bonds als Spieler für Arizona State
        </figcaption>
      </figure>
      Wie wir im vorherigen Kapitel gesehen haben, sind mehrere Organisationen am College-Baseball beteiligt. Die wichtigste davon ist die NCAA, die mehr als 1.200 Universitäten und Hochschulen vertritt. Die NCAA ist in drei Divisionen unterteilt: Division I, Division II und Division III.
      <br />
      <br />
      Division I ist die höchste Ebene des College-Baseballs und besteht aus etwa 300 Schulen, die in 31 Konferenzen unterteilt sind. Jede Konferenz hat ihre eigenen Regeln, Zeitpläne und Meisterschaften. Zu den bekanntesten Konferenzen gehören die Atlantic Coast Conference (ACC), die Big Ten Conference (B1G), die Big 12 Conference (B12), die Pac-12 Conference (P12) und die Southeastern Conference (SEC).
      <br />
      <br />
      Division II ist die mittlere Ebene des College-Baseballs und besteht aus etwa 270 Schulen, die in 23 Konferenzen unterteilt sind. Konferenzen sind in der Regel regional orientiert, wie die California Collegiate Athletic Association (CCAA), die Gulf South Conference (GSC) und die Northeast-10 Conference (NE10).
      <br />
      <br />
      Division III ist die niedrigste Ebene des College-Baseballs und besteht aus etwa 380 Schulen, die in 43 Konferenzen unterteilt sind. Konferenzen sind auch in der Regel regional orientiert, wie die New England Small College Athletic Conference (NESCAC), die Southern California Intercollegiate Athletic Conference (SCIAC) und die Wisconsin Intercollegiate Athletic Conference (WIAC).
      <br />
      <br />
      Neben der NCAA gibt es andere Organisationen, die am College-Baseball beteiligt sind. Eine davon ist die NAIA, die etwa 190 Schulen vertritt. Die NAIA ist in 21 Konferenzen unterteilt, wie die American Midwest Conference (AMC), die Golden State Athletic Conference (GSAC) und die Heart of America Athletic Conference (HAAC).
      <br />
      <br />
      Eine andere Organisation ist die NJCAA, die etwa 500 Community Colleges vertritt. Die NJCAA ist in drei Divisionen unterteilt: Division I, Division II und Division III. Jede Division ist weiter in Distrikte und Regionen unterteilt.
      <br />
      <br />
      Diese Organisationen veranstalten jedes Jahr ihre eigenen nationalen Meisterschaften im College-Baseball. Die Meisterschaft der NCAA Division I ist das bekannteste und angesehenste Ereignis, das oft als College World Series (CWS) bezeichnet wird.
      <br />
      <br />
      Die CWS besteht aus einem Double-Elimination-Turnier von 64 Teams, die aufgrund ihrer Leistungen in der regulären Saison und den Konferenzmeisterschaften ausgewählt wurden. Die Teams sind in 16 regionale Gruppen mit jeweils vier Teams unterteilt. Die Gewinner jeder regionalen Gruppe ziehen in die Super-Regionalrunde ein, in der sie gegen ein anderes Team in einer Best-of-Three-Serie antreten. Die Gewinner jeder Super-Regionalrunde ziehen in das finale Turnier der CWS ein, das aus zwei Gruppen mit jeweils vier Teams besteht. Die Gewinner jeder Gruppe treten in einer Best-of-Three-Serie gegeneinander an, um den nationalen Meister zu ermitteln.
      <br />
      <br />
      Das CWS-Finalturnier findet jedes Jahr im Juni in Omaha, Nebraska, statt. Es gilt als eines der größten und aufregendsten Ereignisse im College-Sport und zieht Tausende von Fans, Medien und Scouts an.
      <br />
      <br />
      Die Meisterschaften der NCAA Division II und Division III folgen einem ähnlichen Format, aber mit weniger Teams. Die NAIA- und NJCAA-Meisterschaften haben auch ihre eigenen Formate, die je nach Anzahl der teilnehmenden Teams variieren.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Top-Programme und prestigeträchtige Teams
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      College-Baseball ist eine hochkompetitive und angesehene Sportart mit einer reichen Tradition und einem großen Talentpool. Es gibt viele Programme und Teams, die sich durch ihren Erfolg und ihren Ruf auszeichnen. Einige dieser Programme und Teams sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Texas Longhorns: Das Baseballteam der University of Texas in Austin, das in der Big 12 Conference spielt. Dieses Programm hat eine beeindruckende Erfolgsbilanz mit zahlreichen Auftritten in der College World Series und mehreren Meisterschaften. Einige bekannte Alumni sind Roger Clemens, Burt Hooton und Huston Street.
        </li>
        <li>
          LSU Tigers: Das Baseballteam der Louisiana State University in Baton Rouge, aktiv in der SEC. LSU ist für seine Dominanz im College-Baseball bekannt und hat mehrere Meisterschaften gewonnen. Einige bekannte Alumni sind Albert Belle, Aaron Hill und DJ LeMahieu.
        </li>
        <li>
          USC Trojans: Das Baseballteam der University of Southern California in Los Angeles, Mitglied der Pac-12 Conference. USC hat eine reiche Tradition mit zahlreichen Meisterschaften und hat viele talentierte Spieler hervorgebracht. Einige berühmte Alumni sind Tom Seaver, Randy Johnson und Mark McGwire.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dies sind nur einige Beispiele für Top-Programme und prestigeträchtige Teams im College-Baseball. Es gibt viele weitere Programme und Teams, jede mit ihrer eigenen Geschichte, Kultur und Identität.
      <br />
      <br />
      Diese Programme und Teams tragen auf verschiedene Weise zur Entwicklung der Spieler bei. Sie bieten ihnen ein hohes Maß an Coaching, Training, Einrichtungen und Wettbewerb. Sie helfen ihnen auch dabei, ihre akademischen, sportlichen und persönlichen Ziele zu erreichen.
    </MKTypography>
  </MKBox>
</MKBox>






                                  








<MKBox id="Kapitel 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Vom College zu den Profis
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College-Baseball ist nicht nur ein Sport; es ist auch ein Weg zum Profisport. Viele College-Baseballspieler haben den Ehrgeiz, professionell zu spielen, entweder in der MLB oder in anderen Profiligen. Aber wie sieht dieser Weg aus? Wie kannst du vom College zu den Profis gelangen? In diesem Kapitel geben wir Ihnen einen Überblick über den Weg zum Profisport im Baseball.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Der Weg zum Profisport
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Der Weg zum Profisport im Baseball beginnt in der Regel mit dem MLB Draft. Der MLB Draft ist ein jährliches Ereignis, bei dem die 30 MLB-Teams abwechselnd Spieler aus verschiedenen Quellen auswählen, darunter College-Baseball, High-School-Baseball, internationaler Baseball und unabhängiger Baseball.
      <br />
      <br />
      Der MLB Draft besteht aus 40 Runden, wobei jedem Team erlaubt ist, pro Runde einen Spieler auszuwählen. Die Reihenfolge der Auswahl wird durch die umgekehrte Platzierung der vorherigen MLB-Saison bestimmt, mit einigen Ausnahmen für Ausgleichspicks und Lotteriepicks.
      <br />
      <br />
      Der MLB Draft ist eine bedeutende Gelegenheit für College-Baseballspieler, ihre professionellen Karrieren zu beginnen. Etwa 1.200 Spieler werden jedes Jahr im MLB Draft ausgewählt, wobei etwa 60% aus dem College-Baseball stammen.
      <br />
      <br />
      Wenn Sie im MLB Draft ausgewählt werden, haben Sie zwei Möglichkeiten: Sie können einen Vertrag mit dem Team abschließen, das Sie ausgewählt hat, oder Sie können sich entscheiden, nicht zu unterschreiben und zu Ihrer Universität oder einer anderen Quelle zurückzukehren.
      <br />
      <br />
      Wenn Sie sich für den Abschluss eines Vertrags entscheiden, erhalten Sie eine Unterschriftsprämie, die je nach Runde, in der Sie ausgewählt wurden, variiert. Boni reichen von mehreren Millionen Dollar für die erste Runde bis zu mehreren tausend Dollar für spätere Runden.
      <br />
      <br />
      Wenn Sie einen Vertrag unterschreiben, werden Sie einem der Minor-League-Teams des ausgewählten Teams zugeteilt. Die Minor Leagues sind die unteren Ebenen des professionellen Baseballs und dienen als Entwicklungssystem für die MLB. Es gibt sechs Ebenen in den Minor Leagues: Rookie, Class A Short Season, Class A, Class A Advanced, Double-A und Triple-A.
      <br />
      <br />
      Während Sie in den Minor Leagues spielen, müssen Sie Ihre Fähigkeiten verbessern und Ihre Leistung zeigen, um auf höhere Ebenen aufzusteigen. Sie müssen auch mit anderen Spielern konkurrieren, die denselben Traum haben: den Sprung in die Major Leagues zu schaffen.
      <br />
      <br />
      Den Sprung in die Major Leagues zu schaffen, ist das ultimative Ziel eines jeden professionellen Baseballspielers. Es ist die höchste Ebene des professionellen Baseballs, wo Sie gegen die besten Spieler der Welt antreten können, vor den größten Fans und für die höchsten Gehälter.
      <br />
      <br />
      Dennoch ist es nicht einfach, es in die Major Leagues zu schaffen. Nur etwa 10% der Spieler, die im MLB Draft ausgewählt werden, erreichen letztendlich die Major Leagues, und nur etwa 1% der Ausgewählten haben lange und erfolgreiche Karrieren in den Major Leagues.
      <br />
      <br />
      Der Weg zum Profisport im Baseball ist also eine lange und herausfordernde Reise, die Engagement, harte Arbeit und auch etwas Glück erfordert. Aber es ist auch ein Weg, der viele Belohnungen, Möglichkeiten und Träume bieten kann.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Der MLB Draft und andere professionelle Möglichkeiten
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Der MLB Draft ist nicht der einzige Weg, um im Baseball professionell zu spielen. Es gibt auch andere professionelle Möglichkeiten außerhalb der MLB, die für College-Baseballspieler attraktiv sein können.
      <br />
      <br />
      Eine davon ist die unabhängige Liga. Die unabhängige Liga ist eine professionelle Baseballliga, die nicht mit der MLB oder den Minor Leagues verbunden ist. Die unabhängige Liga besteht aus verschiedenen Ligen wie der Atlantic League, der American Association und der Frontier League.
      <br />
      <br />
      Die unabhängige Liga bietet eine Alternative für Spieler, die nicht im MLB Draft ausgewählt wurden oder keine Vertragsangebote von einem MLB-Team erhalten haben. Die unabhängige Liga bietet auch eine Chance für Spieler, die aus den Minor Leagues entlassen wurden oder ihre Karrieren verlängern möchten.
      <br />
      <br />
      Die unabhängige Liga hat ein niedrigeres Spielniveau und niedrigere Gehälter im Vergleich zu MLB oder den Minor Leagues, bietet aber auch ein höheres Maß an Freiheit und Genuss. Die unabhängige Liga ist bekannt für ihre Kreativität, Vielfalt und Experimente. Sie hat auch einige Spieler hervorgebracht, die schließlich den Sprung in die Major Leagues geschafft haben, wie Jose Bautista, Rich Hill und Max Muncy.
      <br />
      <br />
      Eine weitere professionelle Möglichkeit ist die internationale Liga. Die internationale Liga ist ein Sammelbegriff für professionelle Baseballligen, die außerhalb der Vereinigten Staaten gespielt werden. Die internationale Liga besteht aus verschiedenen Ligen wie der Nippon Professional Baseball (NPB) in Japan, der Korean Baseball Organization (KBO) in Südkorea, der Chinese Professional Baseball League (CPBL) in Taiwan und der Mexican Baseball League (LMB) in Mexiko.
      <br />
      <br />
      Die internationale Liga bietet eine Gelegenheit für Spieler, die ihren Horizont erweitern und eine andere Kultur erleben möchten. Die internationale Liga bietet auch eine Möglichkeit für Spieler, die ein höheres Spielniveau und Gehalt als die unabhängige Liga suchen, aber möglicherweise nicht in der Lage sind, es in die MLB oder die Minor Leagues zu schaffen.
      <br />
      <br />
      Dies sind nur einige Beispiele für professionelle Möglichkeiten außerhalb der MLB. Es gibt viele weitere Möglichkeiten, wie Winterligen, College-Sommerligen und Amateurligen.
      <br />
      <br />
      Diese Möglichkeiten bieten alle verschiedene Vor- und Nachteile für College-Baseballspieler. Sie bieten unterschiedliche Wettbewerbs-, Belohnungs-, Herausforderungs- und Genussniveaus. Sie bieten verschiedene Wege zum Profisport im Baseball.
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  








<MKBox id="Kapitel 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationale Einflüsse
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College-Baseball ist nicht nur ein amerikanischer Sport; er ist auch ein internationaler Sport, der von Spielern, Trainern und Fans aus verschiedenen Ländern und Kulturen beeinflusst wird. In diesem Kapitel geben wir Ihnen einen Überblick über die internationalen Einflüsse im College-Baseball.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Ausländische Spieler im College-Baseball
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="USC baseball team celebrating a victory."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          USC-Spieler feiern einen Sieg
        </figcaption>
      </figure>
      Einer der bedeutendsten internationalen Einflüsse im College-Baseball ist das Vorhandensein ausländischer Spieler. Ausländische Spieler sind Spieler, die nicht in den USA geboren wurden oder nicht die amerikanische Staatsbürgerschaft besitzen. Sie stammen aus verschiedenen Ländern wie Kanada, Mexiko, Japan, Südkorea, Taiwan, Kuba, Venezuela, der Dominikanischen Republik und den Niederlanden.
      <br />
      <br />
      Ausländische Spieler spielen eine wichtige Rolle im College-Baseball. Sie bringen ihre eigenen Fähigkeiten, Stile und Perspektiven in das Spiel ein. Sie bereichern die Vielfalt und Qualität des College-Baseballs. Sie erhöhen auch die internationale Anerkennung und Attraktivität des College-Baseballs.
      <br />
      <br />
      Es gibt viele Erfolgsgeschichten von ausländischen Spielern, die es in die amerikanische College-Baseballwelt geschafft haben. Ein prominentes Beispiel ist Nomar Garciaparra. Nomar Garciaparra war ein mexikanisch-amerikanischer Shortstop, der für das Georgia Institute of Technology spielte. Er wurde in der ersten Runde des MLB Drafts 1994 von den Boston Red Sox ausgewählt. Er spielte 14 Spielzeiten in der MLB, wo er sechs Mal zum All-Star wurde und zweimal den AL-Batting-Titel gewann. Es gibt viele weitere Beispiele von ausländischen Spielern, die sich im College-Baseball einen Namen gemacht haben.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikaner im Ausland
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ein weiterer internationaler Einfluss im College-Baseball ist das Vorhandensein von Amerikanern im Ausland. Amerikaner im Ausland sind Spieler, die in den USA geboren wurden oder die amerikanische Staatsbürgerschaft besitzen, aber in professionellen Ligen außerhalb der USA spielen oder gespielt haben.
      <br />
      <br />
      Amerikaner im Ausland spielen ebenfalls eine wichtige Rolle im College-Baseball. Sie bringen ihre eigenen Erfahrungen, Kenntnisse und Netzwerke in das Spiel ein. Sie lernen auch von anderen Kulturen und Baseballstilen. Sie fördern den internationalen Austausch und die Zusammenarbeit im College-Baseball.
      <br />
      <br />
      Diese Möglichkeiten bieten vielen amerikanischen College-Baseballspielern viele Vorteile. Sie bieten ein höheres Wettbewerbsniveau, mehr Exposure und Belohnungen. Sie helfen auch dabei, den Horizont zu erweitern, die Anpassungsfähigkeit zu erhöhen und die Netzwerke zu erweitern.
      <br />
      <br />
      Internationale Erfahrungen können für die Entwicklung eines Spielers von Vorteil sein. Sie können ihnen helfen, neue Fähigkeiten, Techniken und Strategien zu erlernen. Sie können ihnen auch helfen, ihre Schwächen zu verbessern, ihre Stärken zu nutzen und ihr Potenzial optimal zu nutzen.
    </MKTypography>
  </MKBox>
</MKBox>

      






                                  

                                
<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Sportstipendien und die Zukunft der Athleten
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sportstipendien bieten zahlreiche Möglichkeiten für junge Athleten, ihr Talent zu zeigen und ihre Fähigkeiten zu entwickeln. Eine der bedeutendsten Möglichkeiten ist der Erhalt eines Sportstipendiums, um auf Hochschulebene zu studieren und zu konkurrieren. In diesem Kapitel werden wir die Bedeutung von Sportstipendien diskutieren und wie Sportstipendien Amerika Ihnen helfen kann, Ihren Traum zu verwirklichen.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Die Bedeutung von Stipendien
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ein Sportstipendium ist finanzielle Unterstützung, die von einer Universität einem Studenten-Athleten zur Verfügung gestellt wird, um Studium und Sport zu kombinieren. Es kann die Kosten für Studiengebühren, Bücher, Unterkunft, Mahlzeiten und andere Ausgaben abdecken. Darüber hinaus kann es auch Vorteile wie akademische Nachhilfe, medizinische Versorgung, Trainingsmöglichkeiten und Ausrüstung bieten.
      <br />
      <br />
      Die Erlangung eines Sportstipendiums ist wettbewerbsfähig, wobei viele Faktoren eine Rolle im Zuteilungsprozess spielen, einschließlich akademischer Leistungen, sportlicher Leistung, Persönlichkeit, Motivation, Führung und Anpassungsfähigkeit. Daher ist es wichtig, sich gut vorzubereiten und sich von der Konkurrenz abzuheben.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Wie Sportstipendien Amerika helfen können
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo von Sportstipendien Amerika, das Sportausrüstung und eine Abschlusskappe zeigt, die den Fokus auf Sportstipendien für Studenten-Athleten repräsentiert."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo von Sportstipendien Amerika
        </figcaption>
      </figure>
      Sportstipendien Amerika ist eine Organisation, die sich auf die Anleitung junger Sporttalente zu Sportstipendien spezialisiert hat. Mit leidenschaftlichen und erfahrenen Experten unterstützt Sportstipendien Amerika Studenten-Athleten auf jedem Schritt der Reise, von der ersten Orientierung bis zur endgültigen Platzierung. Ihre Dienstleistungen umfassen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Ein persönliches Aufnahmegespräch, um die Wünsche, Möglichkeiten und Erwartungen des Studenten-Athleten zu besprechen.</li>
        <li>Eine professionelle Bewertung des sportlichen und akademischen Niveaus des Studenten-Athleten.</li>
        <li>Ein umfangreiches Netzwerk von Kontakten zu Trainern, Universitäten und Hochschulen.</li>
        <li>Effektive Werbung für das Profil und die Videos des Studenten-Athleten bei potenziellen Trainern.</li>
        <li>Expertenberatung zu den besten Optionen basierend auf den Vorlieben, Zielen und dem Budget des Studenten-Athleten.</li>
        <li>Verhandlung der besten Stipendienangebote im Namen des Studenten-Athleten.</li>
        <li>Hilfe bei der Beantragung der erforderlichen Dokumente wie Visum, Zeugnis, Diplom und Zulassungstests.</li>
        <li>Vorbereitung auf das Leben des Studenten-Athleten während seines Stipendiums, einschließlich Kultur, Sprache, Regeln und Tipps.</li>
        <li>Nachsorge und Follow-up während der Stipendiumszeit.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Möchten Sie Stipendienmöglichkeiten erkunden und Ihre sportlichen und akademischen Ziele erreichen? <Link to="/de/Dienstleistungen-Produkte">Sehen Sie sich die vollständige Palette</Link> der von Sportstipendien Amerika angebotenen Dienstleistungen an und vereinbaren Sie noch heute eine Beratung.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;