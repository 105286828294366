/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";



// Images
import Image1 from "assets/images/Sports_Images/Soccer1.jpg";
import Image2 from "assets/images/Sports_Images/Soccer2.jpg";
import Image3 from "assets/images/Sports_Images/Soccer3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'At spille fodbold og studere i Amerika: En drøm bliver til virkelighed', isSubheader: false },
  
    { id: 'Kapitel 1', title: 'Kapitel 1: Historie og Udvikling af Fodbold i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 De Tidlige År og Universiteternes Rolle', isSubheader: true },
    { id: '1.2', title: '1.2 Vækst og Professionalisering', isSubheader: true },
  
    { id: 'Kapitel 2', title: 'Kapitel 2: College-Fodbold i Dag', isSubheader: false },
    { id: '2.1', title: '2.1 Vigtige Konkurrencer og Konferencer for Fodbold', isSubheader: true },
    { id: '2.2', title: '2.2 Topprogrammer og Prestigefyldte Hold til Fodbold', isSubheader: true },
  
    { id: 'Kapitel 3', title: 'Kapitel 3: Fra College til Professionelt Niveau', isSubheader: false },
    { id: '3.1', title: '3.1 Vejen til Professionalisme', isSubheader: true },
  
    { id: 'Kapitel 4', title: 'Kapitel 4: Indflydelsen fra International Fodbold', isSubheader: false },
    { id: '4.1', title: '4.1 Udenlandske Indflydelser', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanere i Udlandet', isSubheader: true },
  
    { id: 'Kapitel 5', title: 'Kapitel 5: Sportslegater og fremtiden for atleter', isSubheader: false },
    { id: '5.1', title: '5.1 Vigtigheden af legater', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Legater Amerika Kan Hjælpe', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>























{/* Introduction */}
<MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    At spille fodbold og studere i Amerika: En drøm bliver til virkelighed
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Creightons stadion, med plads til over 7000 tilskuere, under en college fodboldkamp."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Creighton Stadion: hjemsted for mere end 7000 fodboldfans.
      </figcaption>
    </figure>
    Fodbold er en af verdens mest populære sportsgrene, men i Amerika har den længe været overskygget af andre sportsgrene som basketball, baseball og amerikansk fodbold. Imidlertid er fodbold vokset enormt i popularitet og kvalitet i USA i de seneste årtier, takket være blandt andet landsholdets succes, opkomsten af Major League Soccer (MLS) og indflydelsen fra internationale stjerner.
    <br />
    <br />
    Men vidste du, at fodbold i Amerika også tilbyder en unik mulighed for unge talenter for at forfølge deres drømme? Ved at spille fodbold og studere i Amerika kan du ikke kun udvikle dine sportslige færdigheder, men også opnå en akademisk grad og få en uforglemmelig oplevelse. Alt dette er muligt takket være fodboldstipendierne, som amerikanske universiteter tilbyder til talentfulde spillere.
    <br />
    <br />
    I denne artikel vil vi fortælle dig alt, hvad du behøver at vide om at spille fodbold og studere i Amerika, lige fra historien og udviklingen af fodbold i USA til fordelene og mulighederne ved et fodboldstipendium. Vi vil også forklare, hvordan Sportbeursamerika.nl kan hjælpe dig med at gøre denne drøm til virkelighed. Så læs videre og opdag, hvordan du kan drage fordel af denne unikke mulighed!
  </MKTypography>
</MKBox>


{/* Chapter 1 */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Historie og Udvikling af Fodbold i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Fodbold, eller soccer som amerikanerne kalder det, er ikke en så ny sport i USA, som man måske skulle tro. Faktisk fandt den første officielle fodboldkamp i Amerika sted i 1869 mellem to universitetshold: Princeton og Rutgers. Siden da har fodbold haft en lang og fascinerende historie i USA, som vi vil diskutere i dette kapitel.
  </MKTypography>
  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 De Tidlige År og Universiteternes Rolle
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      I slutningen af ​​1800-tallet og begyndelsen af ​​1900-tallet var fodbold især populær blandt studerende, der spillede spillet efter forskellige regler og formater. Der var for eksempel hold, der spillede med 11, 15 eller endda 25 spillere og undertiden brugte elementer fra rugby eller amerikansk fodbold.
      <br />
      <br />
      For at skabe mere enhed og struktur blev Intercollegiate Association of Amateur Athletes of America (IAAAA) grundlagt i 1905, forløberen for National Collegiate Athletic Association (NCAA). NCAA er den primære organisation, der regulerer og organiserer universitetsfodbold i USA.
      <br />
      <br />
      I første halvdel af det 20. århundrede voksede antallet af universitetsfodboldhold støt, og der opstod også regionale konkurrencer og mesterskaber. En af de mest berømte af disse er College Cup, afholdt siden 1959 som herrenes nationale mesterskab. For kvinder blev den første College Cup kun afholdt i 1982, men siden da har kvindefodbold oplevet en enorm vækst i USA, delvist takket være landsholdets succes i internationale turneringer.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Vækst og Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ud over universitetsfodbold udviklede professionel fodbold sig også i USA, omend med flere op- og nedture. Den første professionelle liga var American Soccer League (ASL), grundlagt i 1921 og bestod primært af hold fra Nordøst. ASL havde nogle succesrige år, men kom i konflikt med det amerikanske fodboldforbund om organisering og regulering af spillet. Dette førte til en splittelse og i sidste ende slutningen på ASL i 1933.
      <br />
      <br />
      Efter Anden Verdenskrig forsøgte forskellige ligaer at genoplive professionel fodbold, men ingen af dem overlevede længe. Det var først i 1967, at en stabil liga blev etableret: North American Soccer League (NASL). NASL bragte nogle store navne til Amerika, herunder Pelé, Franz Beckenbauer og Johan Cruyff, og tiltrak store tilskuermængder til stadioner. Imidlertid kunne NASL heller ikke modstå økonomiske problemer, konkurrence fra andre sportsgrene og manglende medieinteresse. NASL ophørte med at eksistere i 1984.
      <br />
      <br />
      Det tog indtil 1996, før en professionel liga blev etableret igen: Major League Soccer (MLS). MLS er i øjeblikket den øverste division inden for amerikansk fodbold og består af 27 hold fra både USA og Canada. MLS er udviklet til en konkurrencedygtig og attraktiv liga, der tiltrækker både indenlandske og internationale spillere. MLS har også bidraget til populariseringen af fodbold i Amerika ved at bygge stadioner, støtte ungdomsudvikling og samarbejde med andre ligaer.
      <br />
      <br />
      Professionel fodbold har også påvirket universitetsfodbold, og vice versa. For eksempel er der spillere, der skifter til MLS efter deres universitetskarrierer, eller spillere, der vender tilbage til universitetet for at færdiggøre deres studier efter MLS. Der er også spillere, der spiller både for deres universitet og et professionelt hold, takket være særlige ordninger. Derudover samarbejder nogle professionelle hold med universitetsprogrammer for at scoutere og udvikle talenter.
    </MKTypography>
  </MKBox>
</MKBox>



                                              





                                  






{/* Chapter 2 */}
<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: College-Fodbold i Dag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Som vi så i det foregående kapitel, har college-fodbold i Amerika en lang og rig tradition. Men hvordan ser det ud i dag? Hvilke konkurrencer og konferencer findes der? Hvilke programmer og hold er de mest prestigefyldte og succesfulde? Og hvordan kan du, som spiller, blive involveret i denne spændende verden? I dette kapitel giver vi dig et overblik over college-fodbold i dag og viser dig, hvad du kan forvente, hvis du vælger et fodboldstipendium i Amerika.
  </MKTypography>
  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Vigtige Konkurrencer og Konferencer for Fodbold
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Santa Clara fodboldspiller i aktion under NCAA-turneringen."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Fodboldspillere i aktion under en NCAA-turneringskamp.
        </figcaption>
      </figure>
      Som nævnt tidligere er NCAA den primære organisation, der regulerer og organiserer college-fodbold i USA. NCAA består af tre divisioner: Division I, Division II og Division III. Hver division har sine egne regler, krav og stipendier til studerende-atleter.
      <br />
      <br />
      Division I er den højeste og mest konkurrencedygtige division, hvor de bedste spillere og hold konkurrerer. Division I består af cirka 200 herrehold og 300 damehold, opdelt i forskellige regionale konferencer. En konference er en gruppe universiteter, der samarbejder om at organisere en konkurrence inden for deres region. Nogle eksempler på velkendte konferencer er Atlantic Coast Conference (ACC), Big Ten Conference, Pac-12 Conference og Southeastern Conference (SEC).
      <br />
      <br />
      Hvert år konkurrerer holdene inden for deres konference om titlen og en plads i den nationale mesterskab: College Cup. College Cup er en knockout-turnering, der afholdes ved sæsonens afslutning, normalt i november og december. For at kvalificere sig til College Cup skal holdene først tjene deres plads gennem deres konference eller gennem en at-large-bid tildelt af et udvalg baseret på deres præstationer i løbet af sæsonen. College Cup består af 48 herrehold og 64 damehold, der konkurrerer i forskellige runder, indtil en mester forbliver.
      <br />
      <br />
      Division II og Division III er mindre konkurrencedygtige end Division I, men tilbyder stadig mange muligheder for studerende-atleter at udvikle sig og nyde spillet. Division II består af cirka 160 herrehold og 200 damehold, også opdelt i forskellige konferencer. Division II har også en national mesterskab, der består af 36 herrehold og 48 damehold. Division III består af cirka 400 herrehold og 430 damehold, der også konkurrerer i konferencer. Imidlertid tilbyder Division III ikke stipendier til studerende-atleter, men yder andre former for økonomisk støtte. Division III har også en national mesterskab, der består af 62 herrehold og 64 damehold.
      <br />
      <br />
      Udover NCAA er der andre organisationer, der organiserer college-fodbold i USA, såsom National Association of Intercollegiate Athletics (NAIA), National Junior College Athletic Association (NJCAA) og United States Collegiate Athletic Association (USCAA). Disse organisationer har deres egne divisioner, regler, stipendier og mesterskaber, som normalt er mindre og mindre kendte end dem fra NCAA.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Topprogrammer og Prestigefyldte Hold til Fodbold
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Inden for hver division og konference er der visse programmer og hold, der skiller sig ud på grund af deres historie, præstation, omdømme, faciliteter og mere. Disse programmer og hold tiltrækker ofte de bedste spillere, tilbyder de bedste stipendier, har de bedste trænere og personale, besidder topmoderne trænings- og kampfaciliteter, har den største fanbase og medieopmærksomhed og producerer ofte spillere til den professionelle cirkel.
      <br />
      <br />
      Det er vanskeligt at udarbejde en endelig liste over de bedste programmer og hold i college-fodbold i Amerika, fordi mange faktorer spiller ind, og rangordninger kan ændre sig hvert år. Imidlertid vil vi for at give dig en idé nævne nogle eksempler på programmer og hold, der ofte betragtes som de bedste i college-fodbold i Amerika.
      <br />
      <br />
      For mænd er et af de mest prestigefyldte programmer University of Maryland, som har vundet mesterskabet fire gange (1968, 2005, 2008 og 2018) og har produceret mere end 20 spillere, der har spillet i MLS, såsom Omar Gonzalez, Graham Zusi og Zack Steffen. Andre førende programmer for mænd inkluderer University of Virginia, Indiana University, Stanford University og Wake Forest University.
      <br />
      <br />
      For kvinder er et af de mest dominerende programmer University of North Carolina, som har vundet mesterskabet over 20 gange og har produceret mere end 40 spillere, der har spillet for det nationale hold, såsom Mia Hamm, Kristine Lilly og Tobin Heath. Andre førende programmer for kvinder inkluderer University of Notre Dame, University of Portland, Stanford University og Florida State University.
      <br />
      <br />
      Dette er blot nogle få eksempler på de mange fremragende programmer og hold i college-fodbold i Amerika. Hvis du vil lære mere om forskellige programmer og hold, kan du søge online efter ranglister, statistikker, anmeldelser og spiller- og træneranbefalinger. Du kan også kontakte Sportbeursamerika.nl, som kan hjælpe dig med at finde det bedste program og hold, der passer til dig.
    </MKTypography>
  </MKBox>
</MKBox>

        






                                  






<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Fra College til Professionelt Niveau
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Mange unge fodboldspillere drømmer om en dag at spille professionelt og efterligne deres idoler på det højeste niveau. Men hvordan kan du få den drøm til at gå i opfyldelse? Hvad er de trin, du skal tage for at gå fra college til professionelt niveau? Og hvilke muligheder og udfordringer vil du støde på undervejs? I dette kapitel vil vi besvare disse spørgsmål og vise dig, hvordan college-fodbold kan tjene som et springbræt til en professionel karriere.
  </MKTypography>
  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Vejen til Professionalisme
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      En af de mest betydningsfulde fordele ved college-fodbold er, at det giver dig mulighed for at udvikle dig både fysisk og akademisk samtidig med, at du nyder livet som studerende. Ved at spille fodbold og studere i Amerika kan du ikke kun forbedre dine fodboldfærdigheder, men også udvide din viden, udvide dit netværk, forme din personlighed og forbedre dine fremtidsperspektiver.
      <br />
      <br />
      College-fodbold kan også være et springbræt til en professionel karriere, hvis du ønsker det. Der er forskellige måder at skifte fra college-fodbold til den professionelle cirkel, både i Amerika og i udlandet. De mest almindelige metoder inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          MLS SuperDraft: Dette er en årlig begivenhed, hvor MLS-holdene skiftes til at vælge spillere fra en pool af tilgængelige spillere, hvoraf de fleste kommer fra college-fodbold. MLS SuperDraft består af fire runder, hvor hvert hold foretager et valg pr. runde. Valgordenen bestemmes af forskellige faktorer såsom holdets præstation i den foregående sæson, lotteri og potentielle handler. MLS SuperDraft er en af de primære måder for college-fodboldspillere at komme ind i MLS og tilbyder muligheden for at sikre en kontrakt og et stipendium.
        </li>
        <li>
          MLS Homegrown Player Rule: Denne regel tillader MLS-holdene at signere spillere fra deres egne ungdomsakademier uden at bruge et draftvalg. Reglen har til formål at opmuntre og belønne udviklingen af lokale talenter. Mange MLS-hold har partnerskaber med college-programmer, der giver dem mulighed for at spore og mentorere spillere, der spiller både for deres college- og ungdomshold. MLS Homegrown Player Rule er en anden måde for college-fodboldspillere at komme ind i MLS og tilbyder muligheden for at sikre en kontrakt og et stipendium.
        </li>
        <li>
          Generation Adidas Program: Sponsoriseret af Adidas er dette program designet til at hjælpe unge spillere med at skifte fra college-fodbold til professionel fodbold. Programmet udvælger lovende spillere, typisk under 20 år, der endnu ikke er færdige med deres universitet. Disse spillere tilbydes kontrakter af MLS, der giver dem mulighed for at deltage i MLS SuperDraft uden at miste deres college-berettigelse. Det betyder, at de stadig kan vende tilbage til deres universitet for at afslutte deres studier, hvis de ønsker det. Programmet tilbyder også uddannelsesstipendier til spillere for at dække deres studieomkostninger.
        </li>
        <li>
          Muligheder i Udlandet: Dette er muligheder for college-fodboldspillere for at spille i udlandet, f.eks. i Europa, Asien eller Sydamerika. Disse muligheder afhænger typisk af forskellige faktorer såsom spillerens niveau, nationalitet, alder og netværk. Nogle spillere kan f.eks. udnytte dobbelt statsborgerskab eller familiebaggrund for at kvalificere sig til et udenlandsk pas, hvilket gør det lettere for dem at spille i et andet land. Andre spillere kan drage fordel af deres forbindelser med spejdere, agenter eller trænere, der kan hjælpe dem med at sikre en kontrakt med en udenlandsk klub. Muligheder i udlandet er en anden måde for college-fodboldspillere at forfølge en professionel karriere på, men de kommer også med flere risici og usikkerheder.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dette er blot nogle af måderne, du kan gå fra college til professionelt niveau på, men der er flere. Det vigtigste er at være opmærksom på de tilgængelige muligheder og forberede dig på de udfordringer, du kan støde på. Det er også vigtigt at være realistisk om dine chancer og forventninger og ikke lægge alle dine æg i én kurv. College-fodbold er en fantastisk mulighed for at udvikle sig som spiller og som person. Derfor er det klogt at holde andre muligheder åbne og ikke kun fokusere på fodbold, men også på dine studier, hobbyer, venner og familie.
      <br />
      <br />
      I det næste kapitel vil vi udforske indflydelsen fra international fodbold på college-fodbold og hvordan det påvirker og inspirerer college-fodboldspillere.
    </MKTypography>
  </MKBox>
</MKBox>
   






                                  








<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Indflydelsen fra International Fodbold
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Fodbold er en global sport, der forbinder millioner af mennesker over hele verden. Det er ikke overraskende, at international fodbold har en betydelig indflydelse på college-fodbold i Amerika. I dette kapitel vil vi udforske, hvordan udenlandske indflydelser påvirker og inspirerer college-fodbold, og hvordan amerikanske spillere finder vej til udenlandske lande.
  </MKTypography>
  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Udenlandske Indflydelser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Den eftertragtede NCAA-trofæ."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Det eftertragtede NCAA-trofæ
        </figcaption>
      </figure>
      Populariteten af international fodbold i USA er vokset betydeligt i de senere år. Ifølge en Gallup-undersøgelse fra 2023 er fodbold den tredje mest populære sport blandt amerikanere, efter amerikansk fodbold og basketball. Dette skyldes delvis den stigende mangfoldighed af den amerikanske befolkning, tilgængeligheden af internationale kampe via tv og internettet samt succesen for det amerikanske landshold på verdensscenen.
      <br />
      <br />
      Denne interesse for international fodbold har også haft en indflydelse på college-fodbold. Mange college-fodboldspillere ser op til deres foretrukne udenlandske spillere og hold og forsøger at efterligne deres spillestil og teknik. Nogle college-fodboldspillere har endda udenlandske baggrunde eller dobbelt statsborgerskab, hvilket skaber en særlig forbindelse til et bestemt land eller en bestemt region.
      <br />
      <br />
      Et eksempel er Gio Reyna, sønnen af den tidligere amerikanske landsholdsspiller Claudio Reyna. Gio Reyna blev født i England, hvor hans far spillede for Sunderland og Manchester City. Han voksede op med en kærlighed til engelsk fodbold og spillede for ungdomsholdene i New York City FC. I 2023 flyttede han til Borussia Dortmund i Tyskland, hvor han hurtigt blev en af Europas største talenter. Han gjorde også sin debut for det amerikanske landshold og dannede en ny generation sammen med andre unge stjerner som Christian Pulisic og Weston McKennie.
      <br />
      <br />
      Et andet eksempel er Catarina Macario, en brasiliansk angriber, der flyttede til USA i en alder af tolv år. Hun spillede for Stanford University, hvor hun vandt Hermann Trophy to gange som landets bedste college-fodboldspiller. Hun fik også amerikansk statsborgerskab, hvilket tillod hende at repræsentere det amerikanske kvindelandshold. I 2023 underskrev hun en kontrakt med Olympique Lyon i Frankrig, et af verdens førende kvindelige hold.
      <br />
      <br />
      Disse eksempler illustrerer, hvordan udenlandske indflydelser beriger og diversificerer college-fodbold og hvordan college-fodboldspillere kan opnå deres drømme i international fodbold.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikanere i Udlandet
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ikke kun påvirker udenlandske indflydelser college-fodbold, men amerikanske spillere, der søger deres lykke i udlandet, spiller også en betydelig rolle. Et stigende antal amerikanske spillere vælger at flytte til Europa eller andre kontinenter efter deres college-karriere for at konkurrere mod verdens bedste spillere.
      <br />
      <br />
      Et af de mest succesrige eksempler er Clint Dempsey, der spillede for Furman University, før han sluttede sig til MLS. I 2023 flyttede han til Fulham i England, hvor han fremstod som en produktiv målscorer. Han scorede mere end 50 mål for klubben, herunder et historisk mål mod Juventus i Europa League. Han spillede også for Tottenham Hotspur og Seattle Sounders og var en nøglespiller for det amerikanske landshold.
      <br />
      <br />
      En anden succeshistorie er Tobin Heath, der spillede for North Carolina University, hvor hun vandt to nationale mesterskaber. Hun spillede også for forskellige klubber i USA, Frankrig og England, herunder Paris Saint-Germain og Manchester United. Hun vandt fire olympiske guldmedaljer og to FIFA-verdensmesterskaber med det amerikanske kvindelandshold, hvor hun er kendt for sine tekniske færdigheder og kreativitet.
      <br />
      <br />
      Disse eksempler viser, hvordan amerikanske spillere kan udvikle sig og præstere i udlandet, og hvordan de kan sætte college-fodbold på landkortet som en talentudvikler.
    </MKTypography>
  </MKBox>
</MKBox>

             






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Sportslegater og fremtiden for atleter
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sportslegater tilbyder talrige muligheder for unge atleter til at vise deres talent og udvikle deres færdigheder. En af de mest betydningsfulde muligheder er at opnå et sportslegat for at studere og konkurrere på kollegialt niveau. I dette kapitel vil vi diskutere vigtigheden af sportslegater og hvordan Sport Legater Amerika kan hjælpe dig med at realisere din drøm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vigtigheden af legater
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et sportslegat er økonomisk støtte, som et universitet tilbyder til en studerende-atlet for at kombinere studier og sport. Det kan dække omkostningerne til undervisning, bøger, bolig, måltider og andre udgifter. Derudover kan det også tilbyde fordele såsom akademisk vejledning, lægehjælp, træningsfaciliteter og udstyr.
      <br />
      <br />
      At opnå et sportslegat er konkurrencepræget, hvor mange faktorer spiller en rolle i tildelingsprocessen, herunder akademiske præstationer, atletisk præstation, personlighed, motivation, lederskab og tilpasningsevne. Derfor er det vigtigt at forberede sig godt og adskille sig fra konkurrencen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Legater Amerika Kan Hjælpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo af Sport Legater Amerika, der viser sportsudstyr og en eksamenshue, der repræsenterer fokus på sportslegater for studerende-atleter."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo af Sport Legater Amerika
        </figcaption>
      </figure>
      Sport Legater Amerika er en organisation specialiseret i at guide unge sports talenter til sportslegater. Med passionerede og erfarne eksperter støtter Sport Legater Amerika studerende-atleter på hvert trin af rejsen, fra indledende orientering til endelig placering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intake-interview for at diskutere den studerende-athlets ønsker, muligheder og forventninger.</li>
        <li>En professionel evaluering af den studerende-athlets atletiske og akademiske niveau.</li>
        <li>Et omfattende netværk af kontakter med trænere, universiteter og kollegier.</li>
        <li>Effektiv markedsføring af den studerende-athlets profil og videoer til potentielle trænere.</li>
        <li>Ekspertrådgivning om de bedste muligheder baseret på den studerende-athlets præferencer, mål og budget.</li>
        <li>Forhandling af de bedste legat tilbud på vegne af den studerende-atlet.</li>
        <li>Hjælp til ansøgning om nødvendige dokumenter, såsom visum, transkript, eksamensbevis og optagelsesprøver.</li>
        <li>Forberedelse til den studerende-atlets liv under deres legat, herunder kultur, sprog, regler og tips.</li>
        <li>Efterpleje og opfølgning under legatperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ønsker du at udforske legatmuligheder og opnå dine sportslige og akademiske mål? <Link to="/da/Tjenester-Produkter">Se det fulde udvalg</Link> af tjenester tilbudt af Sport Legater Amerika og planlæg en konsultation i dag.
    </MKTypography>
  </MKBox>
</MKBox>



          

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;