
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import SchoolIcon from '@mui/icons-material/School';
import StarIcon from '@mui/icons-material/Star';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2'; 
import Filter3Icon from '@mui/icons-material/Filter3'; 
import Filter4Icon from '@mui/icons-material/Filter4'; 
import Filter5Icon from '@mui/icons-material/Filter5'; 
import Filter6Icon from '@mui/icons-material/Filter6'; 
import Filter7Icon from '@mui/icons-material/Filter7'; 
import Filter8Icon from '@mui/icons-material/Filter8'; 
import Filter9Icon from '@mui/icons-material/Filter9';  // Import the number 1 icon
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import ScheduleIcon from '@mui/icons-material/Schedule';
import VideocamIcon from '@mui/icons-material/Videocam';
import QuizIcon from '@mui/icons-material/Quiz';
import RuleIcon from '@mui/icons-material/Rule';
import ChecklistIcon from '@mui/icons-material/Checklist';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import DescriptionIcon from '@mui/icons-material/Description';




const boxStyle = {
  boxShadow: '0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15)',
  background: '#f5f5f5', // Light grey background
  color: '#000', // Text color set to black for contrast
};

const arrowStyle = {
  borderRight: '7px solid white',
};

function Timeline() {
  return (
    <VerticalTimeline lineColor='rgb(33, 150, 243)'>
    <VerticalTimelineElement
      iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
      icon={<StarIcon />}
    />
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{ ...boxStyle, position: 'relative' }} // Adicionar posição relativa ao estilo
      contentArrowStyle={arrowStyle}
      date="Passo 1"
      iconStyle={{ 
        background: 'rgb(33, 150, 243)', 
        color: '#fff', 
        boxShadow: '0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15)', // Garantir a aplicação da sombra aqui
        border: '2px solid white' // Manter a borda
      }}
      icon={<RecordVoiceOverIcon />}
    >
      <div style={{
        position: 'absolute',
        top: '20px',
        right: '20px',
        opacity: 0.5 // Opacidade reduzida para um efeito de marca d'água
      }}>
        <Filter1Icon style={{ color: 'grey', fontSize: '48px' }} />
      </div>

      <h3 className="vertical-timeline-element-title">Consulta Inicial:</h3>
      <p style={{ 
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Adicionado Roboto como a família de fontes
        fontSize: '17px', // Alterado o tamanho da fonte para 18px
        fontWeight: '400', // Garantir que o peso da fonte seja normal
        marginBottom: '10px', // Ajustar o valor conforme necessário
      }}>
        Este é o seu ponto de partida – o momento em que alinhamos seus sonhos e objetivos com um caminho realista para uma bolsa de estudos esportiva na América.
      </p>

      <ul style={{ 
        listStyleType: 'disc', 
        marginLeft: '20px', 
        fontSize: '17px', 
      }}>  
        <li>
          <strong>Objetivos Pessoais:</strong> Esclarecer suas ambições e o que você busca em uma bolsa de estudos esportiva e experiência universitária.
        </li>
        <li>
          <strong>Habilidades Esportivas:</strong> Avaliação do seu desempenho esportivo atual e potencial para competição no nível universitário.
        </li>
        <li>
          <strong>Aspirações Acadêmicas:</strong> Compreensão de seus interesses e objetivos acadêmicos, e como integrá-los com seu esporte.
        </li>
        <li>
          <strong>Objetivos Pessoais:</strong> Com base na conversa, irei elaborar uma estratégia inicial e um cronograma para sua jornada até uma bolsa de estudos esportiva.
        </li>
      </ul>
      <p style={{ 
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Adicionado Roboto como a família de fontes
        fontSize: '17px', // Alterado o tamanho da fonte para 18px
        fontWeight: '400', // Garantir que o peso da fonte seja normal
      }}>
        Juntos, estabelecemos as bases para uma correspondência bem-sucedida com uma universidade americana e definimos o curso para as próximas etapas do processo.
      </p>
    </VerticalTimelineElement>


   


      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ ...boxStyle, position: 'relative' }} // Adicione posição relativa ao estilo
        contentArrowStyle={arrowStyle}
        date="Passo 2"
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        icon={<ScheduleIcon />}
      >
        <div style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          opacity: 0.5 // Opacidade reduzida para um efeito de marca d'água
        }}>
          <Filter2Icon style={{ color: 'grey', fontSize: '48px' }} />
        </div>
        <h3 className="vertical-timeline-element-title">Avaliação e Planejamento:</h3>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Adicionei Roboto como a família de fontes
          fontSize: '17px', // Alterei o tamanho da fonte para 18px
          fontWeight: '400', // Certifique-se de que o peso da fonte está normal
          marginBottom: '10px', // Ajuste o valor conforme necessário
        }}>
          Após a sua consulta inicial, dedico tempo para uma avaliação detalhada e elaboração de um plano personalizado.
        </p>

        <ul style={{ 
          listStyleType: 'disc', 
          marginLeft: '20px', 
          fontSize: '17px', 
        }}>  
          <li>
            <strong>Análise de Potencial:</strong> Avalio o seu histórico esportivo e acadêmico para avaliar suas perspectivas nos EUA.
          </li>
          <li>
            <strong>Plano de Ação:</strong> Desenvolvo um plano sob medida, incluindo um cronograma e metas, para alcançar os seus objetivos.
          </li>
          <li>
            <strong>Conselhos Estratégicos:</strong> Orientação sobre como melhorar o seu desempenho esportivo e acadêmico para aumentar as suas chances de bolsa de estudos.
          </li>
          <li>
            <strong>Universidades-Alvo:</strong> Juntos, identificamos uma lista de universidades-alvo que estejam alinhadas com o seu perfil e ambições.
          </li>
        </ul>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Adicionei Roboto como a família de fontes
          fontSize: '17px', // Alterei o tamanho da fonte para 18px
          fontWeight: '400', // Certifique-se de que o peso da fonte está normal
        }}>
          Este plano detalhado serve de base para a sua jornada rumo a uma bolsa de estudos esportiva e garante que você esteja preparado para cada etapa subsequente.
        </p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Adicione posição relativa ao estilo
  contentArrowStyle={arrowStyle}
  date="Passo 3"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<VideocamIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Opacidade reduzida para um efeito de marca d'água
  }}>
    <Filter3Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Material de Vídeo e Portfólio Esportivo:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Adicionei Roboto como a família de fontes
    fontSize: '17px', // Alterei o tamanho da fonte para 18px
    fontWeight: '400', // Garanta que o peso da fonte seja normal
    marginBottom: '10px', // Ajuste o valor conforme necessário
  }}>
    Criar um portfólio esportivo envolvente e material de vídeo profissional é crucial para aumentar sua visibilidade para os treinadores.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Produção de Vídeo:</strong> Aconselho sobre o conteúdo e supervisiono a produção de um vídeo de destaque que mostre suas habilidades de forma otimizada.
    </li>
    <li>
      <strong>Desenvolvimento do Portfólio:</strong> Ajudo a montar um portfólio esportivo abrangente, incluindo estatísticas, conquistas e recomendações.
    </li>
    <li>
      <strong>História Pessoal:</strong> Sua jornada atlética e conquistas são entrelaçadas em uma narrativa pessoal que o diferencia dos outros.
    </li>
    <li>
      <strong>Apresentação Digital:</strong> Garanto que seu portfólio e vídeo sejam digitalmente acessíveis e compartilháveis para treinadores e equipes de observação.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Adicionei Roboto como a família de fontes
    fontSize: '17px', // Alterei o tamanho da fonte para 18px
    fontWeight: '400', // Garanta que o peso da fonte seja normal
  }}>
    Com este passo, garantimos que seu talento seja destacado de forma inconfundível e atraia a atenção das universidades americanas.
  </p>
</VerticalTimelineElement>


      <VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Adicione posição relativa ao estilo
  contentArrowStyle={arrowStyle}
  date="Passo 4"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<QuizIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Opacidade reduzida para um efeito de marca d'água
  }}>
    <Filter4Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Preparação Acadêmica:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Adicionei Roboto como a família de fontes
    fontSize: '17px', // Alterei o tamanho da fonte para 18px
    fontWeight: '400', // Certifique-se de que o peso da fonte está normal
    marginBottom: '10px', // Ajuste o valor conforme necessário
  }}>
    Uma boa preparação acadêmica é essencial para admissão e sucesso nos EUA.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Preparação para Testes:</strong> Forneço materiais de estudo e orientação para SAT/ACT e TOEFL/IELTS, testes essenciais para a sua admissão.
    </li>
    <li>
      <strong>Planejamento de Estudos:</strong> Juntos, desenvolvemos um plano de estudos para se preparar para os exames, sem negligenciar o seu treinamento esportivo.
    </li>
    <li>
      <strong>Documentos de Candidatura:</strong> Ajudo na preparação e revisão dos seus ensaios de candidatura e outras documentações necessárias.
    </li>
    <li>
      <strong>Conselhos Acadêmicos:</strong> Você recebe orientações sobre requisitos acadêmicos e como fortalecer o seu perfil acadêmico.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Adicionei Roboto como a família de fontes
    fontSize: '17px', // Alterei o tamanho da fonte para 18px
    fontWeight: '400', // Certifique-se de que o peso da fonte está normal
  }}>
    Meu objetivo é garantir que você esteja academicamente preparado não apenas para ser aceito, mas para se destacar na universidade.
  </p>
  
</VerticalTimelineElement>



<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Adicione posição relativa ao estilo
  contentArrowStyle={arrowStyle}
  date="Passo 5"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<SchoolIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Opacidade reduzida para um efeito de marca d'água
  }}>
    <Filter5Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Promoção nas Universidades:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Adicionei Roboto como a família de fontes
    fontSize: '17px', // Alterei o tamanho da fonte para 18px
    fontWeight: '400', // Certifique-se de que o peso da fonte está normal
    marginBottom: '10px', // Ajuste o valor conforme necessário
  }}>
    Promover o seu perfil nas universidades é uma etapa-chave em que a minha experiência e ampla rede de contatos entram em jogo.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Papel Fundamental da Rede de Contatos:</strong> A minha extensa rede de contatos dentro das universidades americanas é inestimável para chamar a atenção para a sua candidatura.
    </li>
    <li>
      <strong>Posicionamento Estratégico:</strong> Utilizo o meu conhecimento e experiência para posicionar estrategicamente o seu perfil de forma a destacar-se para os programas esportivos certos e instituições acadêmicas.
    </li>
    <li>
      <strong>Plano de Promoção Adaptável:</strong> Crio um plano de promoção flexível e personalizado que destaca os aspectos únicos do seu perfil esportivo e acadêmico.
    </li>
    <li>
      <strong>Maximização das Oportunidades:</strong> Através da promoção direcionada, garanto que as suas chances de obter uma bolsa de estudos sejam maximizadas, com foco na melhor adequação aos seus objetivos pessoais e atléticos.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Adicionei Roboto como a família de fontes
    fontSize: '17px', // Alterei o tamanho da fonte para 18px
    fontWeight: '400', // Certifique-se de que o peso da fonte está normal
  }}>
    O meu valor acrescentado reside em destacar eficazmente o seu talento e aspirações, aumentando significativamente as chances de sucesso. É aqui que a minha rede de contatos, o meu conhecimento do sistema universitário americano e a minha abordagem pessoal fazem a diferença decisiva.
  </p>
  
</VerticalTimelineElement>


<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Adicione posição relativa ao estilo
  contentArrowStyle={arrowStyle}
  date="Passo 6"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<RecordVoiceOverIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Opacidade reduzida para um efeito de marca d'água
  }}>
    <Filter6Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Comunicação com Treinadores:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    A comunicação eficaz com os treinadores é essencial. Atuo como o seu representante pessoal para causar uma forte primeira impressão.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Introduções Diretas:</strong> Introduções pessoais a treinadores na minha rede de contatos, adaptadas à sua disciplina esportiva.
    </li>
    <li>
      <strong>Estratégia de Comunicação:</strong> Desenvolvimento de uma estratégia de comunicação eficaz para destacar as suas qualidades e ambições.
    </li>
    <li>
      <strong>Orientação na Negociação:</strong> Orientação na comunicação com treinadores durante a fase de negociação de bolsas esportivas.
    </li>
    <li>
      <strong>Suporte Contínuo:</strong> Suporte e aconselhamento contínuos em todas as comunicações para garantir que cause uma impressão positiva e duradoura.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    O meu papel é atuar como uma ponte entre você e os treinadores americanos, e criar um canal de comunicação que maximize as suas chances de uma bolsa esportiva bem-sucedida.
  </p>
  
</VerticalTimelineElement>



<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Adicione posição relativa ao estilo
  contentArrowStyle={arrowStyle}
  date="Passo 7"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<RuleIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Opacidade reduzida para um efeito de marca d'água
  }}>
    <Filter7Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Seleção e Ofertas de Bolsas de Estudo:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    O processo de seleção é crucial; é aqui que os frutos do nosso trabalho conjunto se concretizam na forma de ofertas de bolsas de estudo.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Avaliação das Ofertas:</strong> Juntos, analisamos as ofertas recebidas e discutimos os prós e contras de cada opção.
    </li>
    <li>
      <strong>Conselhos Estratégicos:</strong> Fornecerei conselhos estratégicos para ajudá-lo a fazer a melhor escolha para o seu futuro esportivo e acadêmico.
    </li>
    <li>
      <strong>Suporte em Negociações:</strong> Minha experiência em negociações é empregada para garantir as condições mais favoráveis para você.
    </li>
    <li>
      <strong>Escolha Final:</strong> Com a minha ajuda, você faz uma escolha final bem informada onde a sua carreira esportiva e educação nos Estados Unidos começam.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Minha orientação nesta fase visa garantir o melhor resultado possível para as suas ambições, e assegurar o seu futuro em uma das melhores universidades dos Estados Unidos.
  </p>
  
</VerticalTimelineElement>




<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Adicione posição relativa ao estilo
  contentArrowStyle={arrowStyle}
  date="Passo 8"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<DescriptionIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Opacidade reduzida para um efeito de marca d'água
  }}>
    <Filter8Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Solicitação de Visto e Processo de Matrícula:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    Após a seleção, uma fase crítica começa: finalizar a solicitação de visto e os procedimentos de matrícula.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Solicitação de Visto:</strong> Orientação na solicitação do visto de estudante apropriado, incluindo a coleta da documentação necessária.
    </li>
    <li>
      <strong>Assistência na Matrícula:</strong> Ajuda na conclusão de todos os formulários e documentos de matrícula para a universidade e competição escolhidas.
    </li>
    <li>
      <strong>Planejamento Financeiro:</strong> Conselhos sobre questões financeiras, como taxas de bolsa, mensalidades e custos de vida nos Estados Unidos.
    </li>
    <li>
      <strong>Verificação Final:</strong> Uma verificação final minuciosa para garantir que todos os aspectos administrativos e logísticos sejam tratados.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Minha orientação na preparação garante que você inicie sua aventura americana com confiança e bem informado.
  </p>
  
</VerticalTimelineElement>


<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Adicione posição relativa ao estilo
  contentArrowStyle={arrowStyle}
  date="Passo 9"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<ChecklistIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Opacidade reduzida para um efeito de marca d'água
  }}>
    <Filter8Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Preparação para a Partida:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    Uma transição tranquila para os Estados Unidos requer uma preparação meticulosa; agora estão sendo feitos os arranjos finais.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Lista de Verificação:</strong> Uma lista de verificação abrangente para garantir que nada seja esquecido, desde documentos essenciais até necessidades pessoais.
    </li>
    <li>
      <strong>Acomodação:</strong> Assistência na busca por acomodações adequadas próximas à sua universidade e com as comodidades certas.
    </li>
    <li>
      <strong>Seguro:</strong> Conselhos sobre os seguros adequados para saúde, viagem e estadia.
    </li>
    <li>
      <strong>Orientação:</strong> Uma sessão de orientação sobre diferenças culturais, vida nos Estados Unidos e dicas práticas para o cotidiano.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Minha orientação na preparação garante que você inicie sua aventura americana com confiança e bem informado.
  </p>
  
</VerticalTimelineElement>


<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Adicione posição relativa ao estilo
  contentArrowStyle={arrowStyle}
  date="Passo 10"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<FlightTakeoffIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Opacidade reduzida para um efeito de marca d'água
  }}>
    <Filter9Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Partida e Chegada nos EUA:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    Chegou o passo final em sua preparação: a jornada para a América.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Preparação para a Partida:</strong> Eu me certifico de que você esteja totalmente preparado para a partida, com uma lista de verificação detalhada e conselhos finais.
    </li>
    <li>
      <strong>Momento de Despedida:</strong> Um momento para refletir sobre esta conquista significativa e se despedir antes de perseguir seu sonho.
    </li>
    <li>
      <strong>Chegada nos EUA:</strong> Após a chegada, ofereço suporte para ajudá-lo a se instalar e navegar em seu novo ambiente.
    </li>
    <li>
      <strong>Primeiros Dias:</strong> Os primeiros dias são emocionantes, e eu garanto que você receba o apoio necessário para começar com o pé direito.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Estou ao seu lado em cada passo, desde a decolagem na Holanda até sua chegada ao campus nos EUA, pronto para começar seus estudos e carreira esportiva.
  </p>
  
</VerticalTimelineElement>


<VerticalTimelineElement
  iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
  icon={<SportsScoreIcon />}
/>
</VerticalTimeline>

  );
}

export default Timeline;
