/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Waterpol1.jpeg";
import Image2 from "assets/images/Sports_Images/Waterpolo2.jpeg";
import Image3 from "assets/images/Sports_Images/Waterpolo3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Wasserball-Stipendien in Amerika: Wie man im Land der Sterne und Streifen studiert und spielt', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapitel 1: Geschichte und Entwicklung von Wasserball in Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Frühe Jahre', isSubheader: true },
    { id: '1.2', title: '1.2 Wachstum und Professionalisierung', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapitel 2: Universitäts-Wasserball heute', isSubheader: false },
    { id: '2.1', title: '2.1 Wichtige Wettbewerbe und Konferenzen', isSubheader: true },
    { id: '2.2', title: '2.2 Top-Programme und angesehene Teams', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapitel 3: Vom College zum Profi', isSubheader: false },
    { id: '3.1', title: '3.1 Der Weg zum Profisport', isSubheader: true },
    { id: '3.2', title: '3.2 Professionelle Ligen', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapitel 4: Internationale Einflüsse und Wettbewerbe', isSubheader: false },
    { id: '4.1', title: '4.1 Ausländische Einflüsse', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikaner in internationalen Wettbewerben', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapitel 5: Sportstipendien und die Zukunft der Athleten', isSubheader: false },
    { id: '5.1', title: '5.1 Die Bedeutung von Stipendien', isSubheader: true },
    { id: '5.2', title: '5.2 Wie Sportstipendien Amerika helfen können', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhaltsverzeichnis
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verstecken</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Anzeigen</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Wasserball-Stipendien in Amerika: Wie man im Land der Sterne und Streifen studiert und spielt
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Stadion voller Fans, die auf ein Wasserballspiel am College warten."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Ruhe vor einem Spiel
      </figcaption>
    </figure>
    Wasserball entstand Ende des 19. Jahrhunderts in Europa als eine Form von Wasser-Rugby und gewann schnell an Popularität in verschiedenen Ländern, darunter auch Amerika. Das erste offizielle Spiel in Amerika wurde 1888 zwischen zwei Vereinen in New York gespielt. Seitdem hat sich Wasserball in Amerika sowohl auf professioneller als auch auf universitärer Ebene erheblich entwickelt.
    <br />
    <br />
    Universitärer Wasserball ist einer der Hauptfaktoren für die Entwicklung von Wasserballspielern in Amerika. Viele Spieler beginnen ihre Karriere in der High School und wechseln dann aufs College, wo sie die Möglichkeit haben, gegen die besten Teams und Spieler des Landes anzutreten. Der Universitätswasserball bietet auch eine hervorragende akademische Ausbildung, die es den Spielern ermöglicht, ihre Leidenschaft für den Sport mit ihren Bildungszielen zu verbinden.
    <br />
    <br />
    Aber wie kannst du als niederländischer Student in Amerika studieren und spielen? Dabei kann Sportbeursamerika.nl helfen. Sportbeursamerika.nl ist eine Organisation, die sich auf die Unterstützung von Studenten bei der Erlangung von Wasserball-Stipendien in Amerika spezialisiert hat. Sportbeursamerika.nl verfügt über ein Expertenteam, das dich durch jeden Schritt des Prozesses führen kann, von der Auswahl der richtigen Universität bis zur Abwicklung aller praktischen Angelegenheiten.
    <br />
    <br />
    Wenn du daran interessiert bist, ein Wasserball-Stipendium in Amerika zu erhalten, lies weiter, um mehr über diese aufregende Möglichkeit zu erfahren.
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>

<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Geschichte und Entwicklung von Wasserball in Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Wasserball hat in Amerika eine lange und reiche Geschichte. Es ist eine der ältesten olympischen Sportarten und wurde erstmals bei den Olympischen Spielen 1900 in Paris gespielt, an denen auch Amerika teilnahm. Seitdem hat Amerika an jedem olympischen Wasserballwettbewerb teilgenommen, außer 1976 und 1980, als es aus Protest gegen die politische Situation in Südafrika bzw. der Sowjetunion zurücktrat.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Frühe Jahre
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Die ersten Wasserballvereine in Amerika wurden Ende des 19. Jahrhunderts in New York und Boston gegründet. Sie spielten nach den englischen Regeln, die viel rauer und gewalttätiger waren als die modernen Regeln. Der Sport wurde schnell bei Einwanderern aus Europa beliebt, insbesondere aus Irland und Schottland. Die erste nationale Meisterschaft fand 1890 statt und wurde vom Sydenham Swimmers Club gewonnen.
      <br />
      <br />
      Die ersten Universitätsteams wurden im frühen 20. Jahrhundert gebildet, besonders an der Ostküste. Die Ivy-League-Universitäten wie Harvard, Yale und Princeton dominierten den Sport bis in die 1920er Jahre. Der erste interkollegiale Wettkampf wurde 1912 organisiert und von Princeton gewonnen.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Wachstum und Professionalisierung
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      In den 1920er Jahren begann sich Wasserball in andere Teile des Landes auszubreiten, insbesondere nach Kalifornien. Dort entwickelte sich ein neuer Spielstil, der auf Geschwindigkeit, Beweglichkeit und Torschuss statt auf körperlichen Kontakt ausgerichtet war. Die Teams aus Kalifornien wurden schnell die besten im Land und gewannen die meisten nationalen und interkollegialen Titel.
      <br />
      <br />
      In den 1930er Jahren entstanden die ersten professionellen Ligen in Amerika, wie die American Water Polo League und die Pacific Coast Water Polo League. Diese Ligen zogen viele Zuschauer und Medienaufmerksamkeit an, erhöhten das Niveau und die Popularität des Sports. Viele professionelle Spieler waren auch Universitätsspieler oder Absolventen, die ihre Karrieren nach ihrem Studium fortsetzten.
      <br />
      <br />
      Eine der Schlüsselfiguren in der Geschichte des amerikanischen Wasserballs war James "Jimmy" Smith, der als "Vater des modernen Wasserballs" gilt. Er war Spieler, Trainer, Schiedsrichter, Organisator und Förderer des Sports. Er spielte für verschiedene Vereine und Universitäten, darunter UCLA, wo er später Trainer wurde. Er führte das amerikanische Team zu zwei olympischen Bronzemedaillen 1924 und 1932. Er war auch einer der Gründer der International Swimming Hall of Fame, in die er 1965 aufgenommen wurde.
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  








<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Universitäts-Wasserball heute
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Universitäts-Wasserball ist einer der angesehensten und wettbewerbsfähigsten Sportarten in Amerika. Er bietet den Studierenden eine einzigartige Gelegenheit, ihre akademischen und sportlichen Ambitionen zu vereinen und gegen die besten Spieler des Landes anzutreten. Universitäts-Wasserball ist auch eine bedeutende Talentquelle für das Nationalteam und professionelle Ligen.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Wichtige Wettbewerbe und Konferenzen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Aktion im Wasser: Spieler schießt auf das Tor während eines Wasserballspiels."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Spieler in Aktion
        </figcaption>
      </figure>
      Universitäts-Wasserball wird von der National Collegiate Athletic Association (NCAA) reguliert, die den Sport in drei Divisionen einteilt, basierend auf dem Niveau und der Anzahl der verfügbaren Stipendien. Division I ist die höchste Division und besteht hauptsächlich aus etwa 30 Teams aus Kalifornien. Division II und III haben jeweils etwa 20 Teams, die über das Land verteilt sind.
      <br />
      <br />
      Jedes Jahr wird eine nationale Meisterschaft für jede Division ausgetragen, bei der die besten Teams jeder Konferenz um den Titel kämpfen. Eine Konferenz ist eine Gruppe von Universitäten, die geografisch oder historisch miteinander verbunden sind und regelmäßig gegeneinander antreten. Die wichtigsten Konferenzen für Wasserball sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Die Mountain Pacific Sports Federation (MPSF), die aus acht Teams aus Kalifornien besteht, darunter UCLA, Stanford, USC und Berkeley. Diese Konferenz gilt als die stärkste und angesehenste im Land und hat die meisten nationalen Titel gewonnen.
        </li>
        <li>
          Die Western Water Polo Association (WWPA), die aus neun Teams aus Kalifornien, Colorado, Utah und Hawaii besteht. Diese Konferenz ist die zweitstärkste im Land und hat ebenfalls einige nationale Titel gewonnen.
        </li>
        <li>
          Die Collegiate Water Polo Association (CWPA), die aus 18 Teams an der Ostküste besteht, darunter Harvard, Princeton, Brown und Navy. Diese Konferenz ist die drittstärkste im Land und hat gelegentlich einen nationalen Titel gewonnen.
        </li>
        <li>
          Die Southern California Intercollegiate Athletic Conference (SCIAC), die aus neun Teams aus Südkalifornien besteht, darunter Pomona-Pitzer, Claremont-Mudd-Scripps und Occidental. Diese Konferenz ist die viertstärkste im Land und tritt hauptsächlich in Division III an.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Top-Programme und angesehene Teams
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Universitäts-Wasserball verfügt über mehrere führende Programme, die für ihre hervorragenden Leistungen sowohl im Sport als auch in der Akademie bekannt sind. Diese Programme ziehen viele talentierte Spieler an, die von den hochwertigen Einrichtungen, dem Coaching und der Ausbildung profitieren. Einige dieser Programme sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Die University of California, Los Angeles (UCLA) ist für ihr außergewöhnliches Wasserballprogramm bekannt, das zu den erfolgreichsten der Geschichte gehört. Mit einer beeindruckenden Anzahl von nationalen Titeln für Männer- und Frauenteams nimmt das Programm einen herausragenden Platz im Sport ein. Die Spiele werden im Spieker Aquatics Center ausgetragen, das Platz für 2.500 Zuschauer bietet. Das UCLA-Team ist für seinen schnellen und dynamischen Spielstil bekannt und hat eine große Anzahl von Olympiamedaillengewinnern hervorgebracht.
        </li>
        <li>
          Die Stanford University verfügt ebenfalls über ein angesehenes Wasserballprogramm mit zahlreichen nationalen Titeln für Männer- und Frauenteams. Die Teams tragen ihre Heimspiele im Avery Aquatic Center aus, das Platz für 2.530 Zuschauer bietet. Die Wasserballteams von Stanford zeichnen sich durch ihre technischen und taktischen Fähigkeiten aus und haben ebenfalls zur Entwicklung vieler Olympiamedaillengewinner beigetragen.
        </li>
        <li>
          Die University of Southern California (USC) hat ebenfalls ein starkes Wasserballprogramm mit mehreren nationalen Meisterschaften für Männer- und Frauenteams. Ihre Heimatbasis ist das Uytengsu Aquatics Center mit Platz für 2.500 Zuschauer. Das USC-Team zeichnet sich durch einen physischen und aggressiven Spielstil aus und hat ebenfalls eine große Anzahl von Olympiamedaillengewinnern trainiert.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  








<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Vom College zum Profi
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Universitäts-Wasserball ist nicht nur eine großartige Möglichkeit, in Amerika zu studieren und zu spielen, sondern auch ein möglicher Sprungbrett für eine professionelle Karriere. Viele Universitätsspieler spielen nach ihrem Abschluss in professionellen Ligen, sowohl in Amerika als auch im Ausland. Einige von ihnen werden auch für das Nationalteam ausgewählt, das an internationalen Turnieren wie den Olympischen Spielen, Weltmeisterschaften und der Weltliga teilnimmt.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Der Weg zum Profisport
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Obwohl Wasserball in Amerika ein beliebter und wettbewerbsfähiger Sport ist, ist er immer noch nicht so kommerziell und lukrativ wie einige andere Sportarten wie Basketball, Fußball oder Baseball. Daher gibt es nicht viele professionelle Ligen oder Teams in Amerika, und die Gehälter und Preisgelder sind relativ niedrig. Die meisten professionellen Spieler haben auch neben ihrer Wasserballkarriere einen anderen Job oder eine andere Einkommensquelle.
      <br />
      <br />
      Die wichtigste professionelle Liga in Amerika ist die National Water Polo League (NWPL), die 2018 als Nachfolger der American Water Polo League (AWPL) gegründet wurde. Die NWPL besteht aus acht Teams, die jeweils aus 15 Spielern bestehen. Die Teams bestreiten eine reguläre Saison mit 14 Spielen, gefolgt von einem Playoff-Turnier zur Ermittlung des Meisters. Der amtierende Meister ist der Los Angeles Water Polo Club, der den Titel im Jahr 2022 gewann.
      <br />
      <br />
      Die meisten Spieler in der NWPL sind ehemalige Universitätsspieler oder Absolventen, die ihre Karrieren nach ihrem Studium fortsetzen. Einige von ihnen sind auch Mitglieder des Nationalteams, das regelmäßig im Olympischen Trainingszentrum in Colorado Springs trainiert und spielt. Das Nationalteam wird von Dejan Udovicic, einem ehemaligen serbischen Spieler und Trainer, der seit 2013 an der Spitze steht, trainiert.
      <br />
      <br />
      Einer der erfolgreichsten Absolventen des Universitäts-Wasserballs ist Tony Azevedo, der als einer der größten Spieler aller Zeiten gilt. Er spielte für die Stanford University, wo er viermal zum NCAA-Spieler des Jahres ernannt wurde. Er spielte auch für mehrere professionelle Teams in Europa und Amerika, darunter Brescia (Italien), Olympiacos (Griechenland) und Long Beach Shore (Amerika). Er nahm auch an fünf Olympischen Spielen teil und gewann eine Silbermedaille im Jahr 2008. Er wird oft als "Der Retter" bezeichnet, weil er oft entscheidende Tore für sein Team erzielte.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professionelle Ligen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Neben der NWPL gibt es in Amerika auch andere professionelle Ligen wie die Major League Water Polo (MLWP) und die Premier Water Polo League (PWPL). Diese Ligen sind jedoch weniger etabliert und weniger beliebt als die NWPL und haben weniger Teams und Spieler. Sie werden auch nicht von USA Water Polo, dem nationalen Verband, der für die Organisation und Regulierung des Wasserballs in Amerika verantwortlich ist, anerkannt.
      <br />
      <br />
      Viele amerikanische Spieler entscheiden sich daher dafür, ihre professionellen Karrieren im Ausland fortzusetzen, wo es mehr Möglichkeiten und bessere Bedingungen gibt. Europa ist das Hauptziel für amerikanische Wasserballspieler, da der Kontinent einige der stärksten und angesehensten Ligen der Welt bietet. Einige dieser Ligen sind:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Die LEN Champions League, die als der Top-Wettbewerb für Vereine in Europa gilt. Sie besteht aus 16 Teams aus verschiedenen Ländern, die um den europäischen Titel kämpfen. Der amtierende Meister ist Pro Recco (Italien), der den Titel im Jahr 2021 gewann.
        </li>
        <li>
          Der LEN Euro Cup, der als der zweitwichtigste Vereinswettbewerb in Europa gilt. Er besteht aus 32 Teams aus verschiedenen Ländern, die um einen Platz in der Champions League kämpfen. Der amtierende Meister ist Orvosegyetem SC (Ungarn), der den Titel im Jahr 2021 gewann.
        </li>
        <li>
          Nationale Ligen, die von den jeweiligen Verbänden jedes Landes organisiert werden. Einige der stärksten und beliebtesten nationalen Ligen sind Serie A1 (Italien), Liga Premaat (Spanien), A1 Ethniki (Griechenland) und OB I (Ungarn).
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>
                             






                                  





<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationale Einflüsse und Wettbewerbe
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Wasserball ist ein globaler Sport, der von Millionen Menschen auf der ganzen Welt gespielt und verfolgt wird. Es ist auch ein Sport, der ständig durch den Einfluss verschiedener Kulturen, Stile und Traditionen evolviert und innoviert. Amerikanischer Wasserball bildet dabei keine Ausnahme, da er von internationalem Wasserball, sowohl auf Vereins- als auch auf Nationalmannschaftsebene, gelernt und profitiert hat.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Ausländische Einflüsse
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Wie wir gesehen haben, hat Wasserball seinen Ursprung in Europa und verbreitete sich auf andere Kontinente, darunter auch Amerika. Daher hat der europäische Wasserball einen bedeutenden Einfluss auf den amerikanischen Wasserball, insbesondere in Bezug auf Technik, Taktik und Strategie. Viele amerikanische Spieler und Trainer haben ihre Fähigkeiten und ihr Wissen verbessert, indem sie in Europa gespielt oder trainiert haben oder von europäischen Spielern und Trainern gelernt haben.
      <br />
      <br />
      Eines der einflussreichsten europäischen Länder für den amerikanischen Wasserball ist Ungarn, das als die erfolgreichste Wasserballnation der Welt gilt. Ungarn hat 15 Olympische Goldmedaillen gewonnen, mehr als jedes andere Land. Ungarn ist für seinen kreativen und intelligenten Spielstil bekannt, der auf einer starken Ballführung, schnellen Pässen und präzisen Schüssen basiert. Viele ungarische Spieler und Trainer haben ihren Stempel auf dem amerikanischen Wasserball hinterlassen, wie Tibor Benedek, Zoltan Szecsi und Attila Banhidy.
      <br />
      <br />
      Ein weiteres einflussreiches europäisches Land für den amerikanischen Wasserball ist Serbien, das ebenfalls zu den führenden Wasserballnationen weltweit gehört. Serbien hat 7 Olympische Goldmedaillen gewonnen, zuletzt 2016. Serbien ist für seinen physischen und aggressiven Spielstil bekannt, der auf einer starken Verteidigung, schnellen Gegenangriffen und kraftvollen Schüssen basiert. Viele serbische Spieler und Trainer haben ihre Erfahrungen und Expertise mit dem amerikanischen Wasserball geteilt, darunter Dejan Udovicic, Andrija Prlainovic und Filip Filipovic.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikaner in internationalen Wettbewerben
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Neben dem Lernen aus dem internationalen Wasserball hat der amerikanische Wasserball auch einen bedeutenden Beitrag zum internationalen Wasserball geleistet, insbesondere in Bezug auf Leistung, Innovation und Förderung. Viele amerikanische Spieler und Trainer haben an internationalen Wettbewerben, sowohl auf Vereins- als auch auf Nationalmannschaftsebene, teilgenommen, wo sie ihre Talente präsentierten und ihren Ruf verbesserten.
      <br />
      <br />
      Einer der wichtigsten internationalen Wasserballwettbewerbe sind die Olympischen Spiele, die alle vier Jahre stattfinden. Die amerikanische Mannschaft hat immer an diesem Wettbewerb teilgenommen, außer 1976 und 1980. Die amerikanische Mannschaft hat insgesamt 12 Olympische Medaillen gewonnen: 3 Gold, 4 Silber und 5 Bronze. Die letzte Medaille war eine Silbermedaille für die Männer im Jahr 2008. Die amerikanische Mannschaft liegt derzeit auf dem vierten Platz in der Welt.
      <br />
      <br />
      Ein weiterer bedeutender internationaler Wettbewerb im Wasserball sind die Weltmeisterschaften, die alle zwei Jahre stattfinden. Die amerikanische Mannschaft hat auch seit ihrer ersten Ausgabe im Jahr 1973 regelmäßig an diesem Wettbewerb teilgenommen. Die amerikanische Mannschaft hat insgesamt 9 Weltmeisterschaftsmedaillen gewonnen: 2 Gold, 3 Silber und 4 Bronze. Die letzte Medaille war eine Bronzemedaille für die Frauen im Jahr 2019. Die amerikanische Mannschaft liegt derzeit auf dem dritten Platz in der Welt.
      <br />
      <br />
      Ein dritter wichtiger internationaler Wettbewerb im Wasserball ist die Weltliga, die jährlich stattfindet. Die amerikanische Mannschaft hat auch seit ihrer ersten Ausgabe im Jahr 2002 regelmäßig an diesem Wettbewerb teilgenommen. Die amerikanische Mannschaft hat insgesamt 10 Weltligamedaillen gewonnen: 3 Gold, 4 Silber und 3 Bronze. Die letzte Medaille war eine Goldmedaille für die Frauen im Jahr 2021. Die amerikanische Mannschaft liegt derzeit auf dem zweiten Platz in der Welt.
    </MKTypography>
  </MKBox>
</MKBox>

         






                                  


                                
<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Sportstipendien und die Zukunft der Athleten
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sportstipendien bieten zahlreiche Möglichkeiten für junge Athleten, ihr Talent zu zeigen und ihre Fähigkeiten zu entwickeln. Eine der bedeutendsten Möglichkeiten ist der Erhalt eines Sportstipendiums, um auf Hochschulebene zu studieren und zu konkurrieren. In diesem Kapitel werden wir die Bedeutung von Sportstipendien diskutieren und wie Sportstipendien Amerika Ihnen helfen kann, Ihren Traum zu verwirklichen.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Die Bedeutung von Stipendien
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ein Sportstipendium ist finanzielle Unterstützung, die von einer Universität einem Studenten-Athleten zur Verfügung gestellt wird, um Studium und Sport zu kombinieren. Es kann die Kosten für Studiengebühren, Bücher, Unterkunft, Mahlzeiten und andere Ausgaben abdecken. Darüber hinaus kann es auch Vorteile wie akademische Nachhilfe, medizinische Versorgung, Trainingsmöglichkeiten und Ausrüstung bieten.
      <br />
      <br />
      Die Erlangung eines Sportstipendiums ist wettbewerbsfähig, wobei viele Faktoren eine Rolle im Zuteilungsprozess spielen, einschließlich akademischer Leistungen, sportlicher Leistung, Persönlichkeit, Motivation, Führung und Anpassungsfähigkeit. Daher ist es wichtig, sich gut vorzubereiten und sich von der Konkurrenz abzuheben.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Wie Sportstipendien Amerika helfen können
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo von Sportstipendien Amerika, das Sportausrüstung und eine Abschlusskappe zeigt, die den Fokus auf Sportstipendien für Studenten-Athleten repräsentiert."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo von Sportstipendien Amerika
        </figcaption>
      </figure>
      Sportstipendien Amerika ist eine Organisation, die sich auf die Anleitung junger Sporttalente zu Sportstipendien spezialisiert hat. Mit leidenschaftlichen und erfahrenen Experten unterstützt Sportstipendien Amerika Studenten-Athleten auf jedem Schritt der Reise, von der ersten Orientierung bis zur endgültigen Platzierung. Ihre Dienstleistungen umfassen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Ein persönliches Aufnahmegespräch, um die Wünsche, Möglichkeiten und Erwartungen des Studenten-Athleten zu besprechen.</li>
        <li>Eine professionelle Bewertung des sportlichen und akademischen Niveaus des Studenten-Athleten.</li>
        <li>Ein umfangreiches Netzwerk von Kontakten zu Trainern, Universitäten und Hochschulen.</li>
        <li>Effektive Werbung für das Profil und die Videos des Studenten-Athleten bei potenziellen Trainern.</li>
        <li>Expertenberatung zu den besten Optionen basierend auf den Vorlieben, Zielen und dem Budget des Studenten-Athleten.</li>
        <li>Verhandlung der besten Stipendienangebote im Namen des Studenten-Athleten.</li>
        <li>Hilfe bei der Beantragung der erforderlichen Dokumente wie Visum, Zeugnis, Diplom und Zulassungstests.</li>
        <li>Vorbereitung auf das Leben des Studenten-Athleten während seines Stipendiums, einschließlich Kultur, Sprache, Regeln und Tipps.</li>
        <li>Nachsorge und Follow-up während der Stipendiumszeit.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Möchten Sie Stipendienmöglichkeiten erkunden und Ihre sportlichen und akademischen Ziele erreichen? <Link to="/de/Dienstleistungen-Produkte">Sehen Sie sich die vollständige Palette</Link> der von Sportstipendien Amerika angebotenen Dienstleistungen an und vereinbaren Sie noch heute eine Beratung.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;