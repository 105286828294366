/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React components
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Jeugd.png";
import post2 from "assets/images/TijdUS.png";
import post3 from "assets/images/Deal.png";

function Post2() {
  return (
    <MKBox component="section" py={0}>
      <Container>
        <Grid container item xs={12} lg={4}>
          <MKTypography variant="h3" mb={6}>
          Läs min berättelse          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
        <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <TransparentBlogCard
              image={post1}
              title="Del 1: Att Växa Upp i Nederländerna"
              description="I den förtrollande omgivningen i Nijmegen började mitt livslånga kärleksaffär med fotboll. Som ett barn från ..."
              action={{
                type: "internal",
                route: "/sv/Om-mig/Uppväxt",
                color: "info",
                label: "Läs Mer",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <TransparentBlogCard
              image={post2}
              title="Del 2: Min Tid i Amerika"
              description="Min tid i Amerika var som en andlöst resa, fylld med oförglömliga upplevelser och personlig tillväxt. Tack vare ..."
              action={{
                type: "internal",
                route: "/sv/Om-mig/Min-tid-i-Amerika",
                color: "info",
                label: "Läs Mer",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <TransparentBlogCard
              image={post3}
              title="Part 3: Min återkomst till Nederländerna"
              description="Vid återkomsten till Nederländerna upplevde jag en blandning av nostalgi och beslutsamhet. Min ..."
              action={{
                type: "internal",
                route: "/sv/Om-mig/Min-återkomst-till-Nederländerna",
                color: "info",
                label: "Läs Mer",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Post2;

