/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState } from 'react'; // Step 1: Import useState


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";


// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Logos/NCAA.png";
import post3 from "assets/images/Logos/NJCAA.png";



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {

  const articleStructure = [
    { id: 'intro', title: 'La NAIA : Une Alternative dans le Sport Universitaire en Amérique', isSubheader: false },
  
    { id: 'Chapitre 1', title: 'L\'Origine et le Développement de la NAIA', isSubheader: true },
    { id: '1.1', title: 'La NAIA et ses Divisions Uniques', isSubheader: true },
    { id: '1.2', title: 'La Mission Académique', isSubheader: true },
  
    { id: 'Chapitre 2', title: 'Finances, Médias et Visibilité', isSubheader: true },
    { id: '2.1', title: 'Défis et Voie à Suivre', isSubheader: true },
    { id: '2.2', title: 'En Conclusion', isSubheader: true },
  ];
  
  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
     <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contents
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Cacher</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Afficher</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
            <MKBox id="intro" mb={3}>
              <MKTypography variant="h1" className="h1-as-h3" mb={2}>La NAIA : Une Alternative dans le Sport Universitaire en Amérique

</MKTypography>
              </MKBox>
              <MKBox id="Hoofdstuk 1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
L'Origine et le Développement de la NAIA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
La National Association of Intercollegiate Athletics, ou NAIA, est une entité cruciale dans le paysage du sport universitaire américain, bien qu'elle soit moins connue que la NCAA. Fondée en 1937, la NAIA s'est développée en une organisation renommée offrant aux étudiants-athlètes une opportunité unique de réaliser leurs aspirations sportives et académiques.

<br />
  <br />
  Depuis le début, la NAIA s'est concentrée sur la fourniture d'opportunités aux petites institutions d'enseignement, mettant l'accent sur la création d'une expérience équilibrée pour les étudiants-athlètes. L'organisation promeut à la fois les sports compétitifs et l'excellence académique, convaincue que les deux vont de pair.

</MKTypography>
<MKBox id="1.1" mb={3}>

  <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
  <br />
  La NAIA et ses Divisions Uniques
  </MKTypography>
  </MKBox>

  <MKTypography variant="body1" fontWeight="light" color="text"> 
  Contrairement à la NCAA, la NAIA est organisée de manière plus compacte, avec une division en deux divisions offrant toutes deux un mélange riche de sport et d'éducation :

<br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division I:{' '}
</MKTypography>
Dans cette division, nous trouvons des écoles offrant un niveau plus élevé de bourses sportives et où la compétition sportive est très compétitive. Malgré la plus petite taille des écoles par rapport aux établissements de la NCAA Division I, la NAIA Division I offre une plate-forme aux meilleurs athlètes pour montrer leurs compétences et se développer.  
<br />
<br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division II:{' '}
</MKTypography>
Cette division met encore plus l'accent sur l'équilibre entre le sport, l'académique et l'engagement sur le campus. Les bourses sportives sont plus limitées, mais les opportunités pour les étudiants-athlètes d'exceller à la fois dans leur sport et dans leurs études sont nombreuses. 
</MKTypography>

<MKBox id="1.2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
La Mission Académique
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
La NAIA accorde une grande importance aux performances académiques de ses étudiants-athlètes. Des normes académiques strictes ont été établies pour garantir la participation aux sports, et les écoles affiliées sont déterminées à fournir le soutien académique nécessaire. Cela se traduit par un environnement où les étudiants-athlètes sont encouragés à viser l'excellence dans tous les aspects de leur vie.

  <br />
  </MKTypography>

                                  






  <MKBox id="Hoofdstuk 2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Finances, Médias et Visibilité
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
Bien que la NAIA ne puisse peut-être pas rivaliser financièrement avec les énormes revenus de la NCAA, elle joue toujours un rôle important dans le financement des bourses sportives et l'amélioration des installations sportives. De plus, la NAIA gagne en visibilité grâce aux services de streaming et aux médias sociaux, ce qui aide à diffuser le message d'un équilibre entre le sport et les études.

<br />
</MKTypography>
<MKBox id="2.1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Défis et Voie à Suivre
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
Tout comme la NCAA, la NAIA est confrontée à des défis, notamment la navigation dans la complexité du statut d'amateur des étudiants-athlètes et la garantie de l'égalité dans le sport. Cependant, avec sa position unique et son engagement envers le développement des étudiants-athlètes, la NAIA est bien placée pour continuer à avoir un impact positif sur la vie de nombreux jeunes athlètes.

</MKTypography>
<MKBox id="2.2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
En Conclusion
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
La National Association of Intercollegiate Athletics offre une alternative précieuse et unique dans le monde du sport universitaire américain. Avec son accent sur l'équilibre, l'excellence académique et la compétition sportive, la NAIA reste une force essentielle dans la formation de l'avenir des étudiants-athlètes. Alors qu'elle regarde vers l'avenir et continue d'évoluer, son engagement envers le développement des jeunes reste inébranlable, ce qui lui permet d'avoir un impact durable et positif sur le monde du sport et de l'éducation américain.
<br />


</MKTypography>


              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="NCAA"
                    description="NCAA : Le Cœur du Sport Universitaire en Amérique"
                    action={{
                      type: "internal",
                      route: "/fr/NCAA",
                      color: "info",
                      label: "Lire La Suite",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="NJCAA"
                    description="NJCAA : La Porte d'Entrée vers les Grandes Universités et la NCAA"
                    action={{
                      type: "internal",
                      route: "/fr/NJCAA",
                      color: "info",
                      label: "Lire La Suite",
                    }}
                  />
                </Grid>
              </Grid>

            </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;