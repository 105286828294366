/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================
* Pagina del prodotto: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
Codificato da www.creative-tim.com
=========================================================
*/

// Componenti materiali di @mui
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Componenti Material Kit 2 PRO React
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Immagini
import post2 from "assets/images/TijdUS.png";
import "../../../../H1asH3.css"

function Profilo() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center" />
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} justifyContent="space-between" alignItems="center" mb={1}>
                <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Parte 1: Crescere nei Paesi Bassi</MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text">
  <br />
  Nell'incantevole ambiente di Nijmegen è iniziato il mio amore eterno per il calcio. Da bambino delle strade di Nijmegen ho sentito la magia dello sport ad ogni passo sull'erba verde. Il mio primo club è stato il BVC '12, dove da giovane ho sperimentato i miei primi dribbling e gol. Era un periodo di innocenza, in cui ho scoperto la mia passione per lo sport e in cui sono state gettate le basi per il mio futuro.
  <br /><br />

  Dopo la mia avventura al BVC '12 ho intrapreso un viaggio attraverso club di calcio locali come Quick, NEC e Union. Questo viaggio ha gettato le basi per la mia formazione calcistica e mi ha avvicinato al mio sogno: raggiungere l'apice del calcio giovanile olandese. Da giovane calciatore sognavo di giocare in grandi stadi, circondato da una folla di tifosi in festa. Era un sogno condiviso da molti giovani talenti nei Paesi Bassi, e ero determinato a fare tutto il possibile per realizzarlo.
  <br /><br />

  Ma sviluppando il mio talento calcistico, ho cominciato a rendere conto anche di quanto sia cruciale una buona educazione per un futuro solido. Nei Paesi Bassi, le mie ambizioni nel calcio e nell'educazione sembravano a volte contraddittorie. Bilanciare entrambe le passioni era una sfida.
  <br /><br />

  La diversità scintillante e le enormi opportunità dell'America mi avevano sempre affascinato, e questa fascinazione è cresciuta ulteriormente durante una vacanza con la mia famiglia. Ero quasi certo che sarei tornato in questo paese, magari per un periodo prolungato. Successivamente, attraverso conoscenze, ho sentito storie sulle opportunità di combinare una carriera nel calcio con gli studi universitari in America, e il mio desiderio di seguire la stessa strada è cresciuto in modo esponenziale.
  <br /><br />

  Tuttavia, non è stato facile organizzare tutto. Il complesso processo di borse di studio sportive e opportunità di studio in America sembrava inizialmente intimidatorio. In qualche modo sapevo che avrebbe potuto essere più semplice e accessibile, il che alla fine è stata la mia motivazione per creare questa azienda anni dopo.
  <br /><br />

  Dopo aver contattato vari allenatori, l'allenatore dell'Università di Tulsa aveva una storia particolarmente convincente da condividere. Parlava di una squadra che competeva sempre per i premi, costantemente classificata tra le prime 25 del paese e partecipava a grandi competizioni. Le sue parole mi hanno dato la fiducia che questo era il luogo in cui potevo realizzare i miei sogni sia nel calcio che nelle ambizioni accademiche.
  <br /><br />

  E così è successo, due settimane dopo aver compiuto 18 anni, il mio capitolo nei Paesi Bassi era (temporaneamente) finito. Sono salito sull'aereo per l'America, pronto per l'avventura che mi attendeva. Determinato a unire le mie passioni per il calcio e l'educazione, mi trovavo all'inizio di un emozionante viaggio. Sarà un piacere condividere di più sulle mie esperienze in America nella parte 2 della mia storia, dove approfondirò le mie avventure negli Stati Uniti.
</MKTypography>
<hr style={{ margin: '20px 0'}} />

<MKTypography component="a" href="#" variant="body1" fontWeight="light" color="info" mt={3}>
  <Grid container justifyContent="flex-end"> {/* Questa riga è stata aggiornata */}
    <Grid item xs={12} lg={6} mb={{ xs: 3, lg: 0 }}>
      <TransparentBlogCard
        image={post2}
        title="Parte 2: Il Mio Tempo in America"
        action={{
          type: "internal",
          route: "/it/Chi-sono/Il-mio-tempo-in-America",
          color: "info",
          label: "Leggi di più",
        }}
      />
    </Grid>
  </Grid>
</MKTypography>

</Grid>
</Grid>
</Grid>
</Container>
</MKBox>
);
}

export default Profilo;
