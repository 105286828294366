/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Rowing1.jpg";
import Image2 from "assets/images/Sports_Images/Rowing2.jpg";
import Image3 from "assets/images/Sports_Images/Rowing3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images

import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Studere og ro i Amerika: En Unik Mulighed for Unge Rokere', isSubheader: false },
  
    { id: 'Kapitel 1', title: 'Kapitel 1: Historien og Udviklingen af Roning i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 De Tidlige År og Kollegiernes Rolle', isSubheader: true },
    { id: '1.2', title: '1.2 Vækst og Traditioner', isSubheader: true },
  
    { id: 'Kapitel 2', title: 'Kapitel 2: Roning på Kollegieniveau i Dag', isSubheader: false },
    { id: '2.1', title: '2.1 Store Konkurrencer og Konferencer', isSubheader: true },
    { id: '2.2', title: '2.2 Førende Programmer og Hold', isSubheader: true },
  
    { id: 'Kapitel 3', title: 'Kapitel 3: Fra Kollegium til Eliten', isSubheader: false },
    { id: '3.1', title: '3.1 Vejen til Toppen', isSubheader: true },
    { id: '3.2', title: '3.2 Professionelle og Internationale Muligheder', isSubheader: true },
  
    { id: 'Kapitel 4', title: 'Kapitel 4: Internationale Indflydelser og Mangfoldighed', isSubheader: false },
    { id: '4.1', title: '4.1 Internationale Atleter i Kollegial Roning', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanske Roere i Udlandet', isSubheader: true },
  
    { id: 'Kapitel 5', title: 'Kapitel 5: Sportslegater og fremtiden for atleter', isSubheader: false },
    { id: '5.1', title: '5.1 Vigtigheden af legater', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Legater Amerika Kan Hjælpe', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>


























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Studere og ro i Amerika: En Unik Mulighed for Unge Rokere
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Rokere i aktion under det nationale college ro-mesterskab."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Rokere i aktion under mesterskabet
      </figcaption>
    </figure>
    Roning er en af de ældste og mest prestigefyldte sportsgrene i USA. Siden det 19. århundrede er der blevet dyrket højt niveau af roning på amerikanske universiteter, hvor roere ikke kun udvikler deres fysiske og mentale styrke, men også deres akademiske og sociale færdigheder. At studere og ro i Amerika er en unik mulighed for unge roere, der ønsker at forfølge deres sportslige drømme, mens de også får en værdifuld uddannelse og en uforglemmelig oplevelse. I denne artikel vil vi fortælle dig alt, hvad du har brug for at vide om at ro i Amerika, lige fra dens historie og traditioner til mulighederne og udfordringerne. Vi vil også forklare, hvordan Sportbeursamerika.nl kan hjælpe dig med at sikre dig et rostipendium og forberede dig på dit eventyr.
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Historien og Udviklingen af Roning i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    I dette kapitel dykker vi ned i den fascinerende historie om roning i USA. Vi udforsker, hvordan denne sport spredte sig over havet fra England og blev en integreret del af amerikansk universitetskultur. Fra de første ro-konkurrencer på Lake Winnipesaukee til de ikoniske rivaliseringer mellem prestigefyldte universiteter, undersøger vi udviklingen af roning som en sport, der både inkarnerer fysisk smidighed og akademisk stolthed.
  </MKTypography>
  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 De Tidlige År og Kollegiernes Rolle
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Roning som sport opstod i England i det 18. århundrede, hvor der blev afholdt løb mellem professionelle roere på Themsen. Disse løb tiltrak store tilskuermængder og væddemål og gjorde hurtigt roning populær. I det 18. og 19. århundrede spredte roning sig til andre lande, herunder USA. Det første amerikanske ro-løb fandt sted i 1843 på Lake Winnipesaukee i New Hampshire. I 1852 blev det første interkollegiale ro-løb afholdt mellem Harvard og Yale på Lake Winnipesaukee. Dette var også den første interkollegiale sportsbegivenhed i USA. Siden da er roning blevet en betydningsfuld del af amerikansk kollegiesport.
      <br />
      <br />
      Kollegiernes rolle i at fremme roning var afgørende. Kollegierne så roning som en måde at indgyde disciplin, teamwork og ledelse i deres studerende samtidig med at de forbedrede deres prestige og omdømme. Kollegier investerede en betydelig mængde penge og ressourcer i at bygge bådhuse, erhverve både og tiltrække trænere. De arrangerede også regelmæssigt konkurrencer mod andre kollegier, både regionalt og nationalt. Disse konkurrencer blev ofte godt besøgt af studerende, alumner, medier og sponsorer, hvilket skabte stor interesse og engagement i sporten.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Vækst og Traditioner
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Roning voksede støt i popularitet og prestige i anden halvdel af det 19. århundrede og første halvdel af det 20. århundrede. Forskellige regionale og nationale konkurrencer opstod, såsom Eastern Sprints, IRA National Championship Regatta, Dad Vail Regatta og Head of the Charles Regatta. Amerikanske roere deltog også i internationale begivenheder, herunder Henley Royal Regatta i England og de olympiske lege. Amerikanske roere opnåede ofte imponerende resultater og vandt guld ved otte på hinanden følgende olympiske lege mellem 1920 og 1956.
      <br />
      <br />
      Roning udviklede også en rig kultur og tradition på amerikanske universiteter. Hver universitet havde sine egne farver, maskot, sange og ritualer forbundet med roning. Nogle traditioner var legende eller symbolske, såsom at kaste styrmanden i vandet efter en sejr eller iføre sig en speciel slips eller nål. Andre traditioner var alvorlige eller ceremonielle, såsom at synge alma mater sangen eller afholde en mindehøjtidelighed for afdøde roere. Disse traditioner bidrog til følelsen af stolthed, kammeratskab og identitet blandt roere.
    </MKTypography>
  </MKBox>
</MKBox>






                                  








<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Roning på Kollegieniveau i Dag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    I dette kapitel fokuserer vi på nutidig roning på universitetsniveau i Amerika. Vi undersøger, hvordan sporten er udviklet til en yderst konkurrencedygtig og prestigefyldt del af det kollegiale sportslandskab. Her diskuterer vi de store konkurrencer og konferencer, strukturen af NCAA-divisioner og ser på nogle af de førende universitetsroningprogrammer. Denne sektion fremhæver den nuværende tilstand af roning på amerikanske universiteter og de unikke muligheder, den tilbyder unge atleter.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Store Konkurrencer og Konferencer
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Stanford roning hold fejrer at vinde det nationale mesterskab."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Stanford roere fejrer det nationale mesterskab
        </figcaption>
      </figure>
      Roning på kollegieniveau forbliver en yderst konkurrencedygtig og prestigefyldt sport i USA i dag. Der er mere end 300 universiteter, der tilbyder roningprogrammer, med cirka 150 deltagende i NCAA (National Collegiate Athletic Association), den overordnede organisation for kollegiesport. NCAA opdeler universiteter i tre divisioner baseret på antallet af studerende, budget og sportens niveau. Division I er den højeste division med de mest robuste og konkurrencedygtige roningprogrammer. Divisioner II og III har færre og mindre roningprogrammer, men er stadig meget konkurrencedygtige.
      <br />
      <br />
      Inden for hver division er der forskellige konferencer, som er grupper af universiteter, der er geografisk eller historisk forbundet. Hver konference organiserer sit eget mesterskab, hvor de bedste hold kvalificerer sig til de nationale mesterskaber. De største konferencer for roning inkluderer Big Ten, Pac-12, Ivy League, ACC og Big 12. Disse konferencer har ofte de mest succesrige og indflydelsesrige roningprogrammer i landet.
      <br />
      <br />
      Ud over konferencemesterskaberne er der andre betydningsfulde nationale regattaer, som universiteter kan deltage i. Den mest prestigefyldte er NCAA Women’s Rowing Championship, afholdt siden 1997 for kvindelige roere i alle tre divisioner. For mandlige roere er der ikke en tilsvarende NCAA-mesterskab, men der er IRA National Championship Regatta, afholdt siden 1895 for både varsity og letvægtshold. Andre velkendte nationale regattaer inkluderer Dad Vail Regatta, San Diego Crew Classic, ACRA National Championship Regatta og CRASH-B Sprints.
    </MKTypography>
  </MKBox>

  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Førende Programmer og Hold
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Roning på kollegieniveau har en lang historie med rivalisering, excellence og innovation. Mange universiteter har udmærket sig gennem deres roningprogrammer, både hvad angår præstation og udvikling. Nogle af disse universiteter inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          University of Washington: Huskies er kendt for deres kraftfulde og respekterede roningprogram. Med flere nationale mesterskaber i herre roning og en historie med at producere olympiske medaljetagere, inklusive de legendariske "Boys in the Boat", der vandt guld i 1936. Huskies hyldes for deres strenge træning, stærke teamwork og indflydelsesrige trænere som Al Ulbrickson.
        </li>
        <li>
          Yale University: Som en af pionererne inden for interkollegial roning har Yale Bulldogs et gammelt og prestigefyldt roningprogram. Deres historie inkluderer flere nationale titler og et betydeligt bidrag til amerikansk roning på olympisk niveau. Yale er også kendt for sin akademiske excellence og historiske rivalisering med Harvard.
        </li>
        <li>
          University of California, Berkeley: Golden Bears har et succesrigt og alsidigt roningprogram, der adskiller sig ved deres nationale titler og olympiske deltagere. Programmet er kendt for sin mangfoldighed, samfundsengagement og dedikerede træning.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>






                                  







<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Fra Kollegium til Eliten
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    I dette kapitel udforsker vi rejsen fra kollegiale roere til eliten inden for roningens verden. Vi undersøger, hvordan de oplevelser og færdigheder, roere får på universitetsniveau, forbereder dem til højere konkurrenceniveauer, herunder deltagelse på nationale hold og i De Olympiske Lege. Dette kapitel fremhæver overgangen fra kollegial roning til professionelle og internationale stadier med fokus på de stier, atleterne følger, de udfordringer, de står overfor, og succeshistorierne for dem, der når toppen. Vi ser også på, hvordan universitetsroningprogrammer identificerer og udvikler talenter, der baner vejen for fremtidige olympiske mestre og verdensmestre.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Vejen til Toppen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Roning på kollegieniveau er ikke kun en sportsudfordring, men også en potentiel springbræt til eliten inden for roningens verden. Mange roere, der har rort på kollegieniveau, skifter til nationale hold og olympisk roning. Dette skyldes, at kollegial roning giver fremragende forberedelse med hensyn til teknik, taktik, fysisk og mental konditionering og teamwork. Derudover giver kollegial roning mulighed for at skille sig ud for spejdere og trænere fra nationale sammenslutninger og udvælgelser.
      <br />
      <br />
      Der er talrige eksempler på roere, der er gået fra college til eliteniveau. Et nyligt eksempel på en roer, der vandt en olympisk medalje efter sin collegekarriere, er Madison Mailey. Mailey, en All-American roer på Northeastern University, der blev færdig i 2018, vandt en guldmedalje ved OL i Tokyo i kvindernes otter for Canada. Hun og hendes holdkammerater tog føringen tidligt og holdt en sen udfordring fra New Zealand på afstand, og vandt med en margin på kun 0,91 sekunder.
    </MKTypography>
  </MKBox>

  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professionelle og Internationale Muligheder
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Kollegial roning åbner døren for professionelle og internationale muligheder for roere. Udover potentialet for olympisk deltagelse kan universitetsroere bruge deres færdigheder til at konkurrere i prestigefyldte internationale regattaer som Henley Royal Regatta eller World Rowing Cup. Disse begivenheder giver en platform for roere til at måle sig selv mod verdens bedste og få erfaring i en international sammenhæng.
      <br />
      <br />
      Derudover er der en vej til professionel roning, hvor college-kandidater kan slutte sig til professionelle hold og deltage i globale konkurrencer. Dette tilbyder en fuldtids roningkarriere med intensiv træning og muligheden for at konkurrere på højeste niveau.
      <br />
      <br />
      For dem, der er interesserede i en trænerkarriere, giver kollegial roning et solidt fundament. Mange succesrige roetrænere er tidligere kollegiale atleter, der bruger deres erfaring og ekspertise til at vejlede nye generationer af roere. Denne karrierevej kan føre til stillinger med nationale hold og roningklubber over hele verden.
      <br />
      <br />
      Sammenfattende kan de erfaringer, der opnås i kollegial roning, føre til en række professionelle og internationale muligheder, fra atleter, der forfølger en professionel karriere, til dem, der fortsætter sporten i en trænerrolle.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  






<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationale Indflydelser og Mangfoldighed
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Dette kapitel fremhæver de internationale dynamikker og mangfoldighed inden for kollegial roning i USA. Vi udforsker, hvordan internationale studerende-atleter beriger sporten med deres unikke baggrunde og perspektiver, og hvordan amerikanske roere finder muligheder for at ro i udlandet og yderligere udvikle sig selv. Disse interaktioner bidrager til en rigere kulturel oplevelse og udvider horisonterne for både amerikanske og internationale roere. Vi dykker ned i de forskellige veje, internationale studerende tager for at ro på kollegialt niveau i USA, fordelene ved disse erfaringer og den indflydelse, de har på sporten og dens deltagere. Derudover fokuserer vi på mulighederne for amerikanske roere for at få international erfaring, herunder studieudvekslingsprogrammer, internationale træningslejre og træningsmuligheder. Dette kapitel understreger betydningen af internationale indflydelser i at berige kollegial roning og fremme kulturel udveksling og forståelse.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Internationale Atleter i Kollegial Roning
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Roning både stillet op til starten af et kollegialt roneløb."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Både klar til starten af et løb
        </figcaption>
      </figure>
      Kollegial roning er ikke kun en amerikansk affære, men også en international en. Mange internationale studerende kommer til USA for at studere og ro på amerikanske universiteter. Disse studerende bringer deres egen kultur, baggrund og perspektiv med sig, hvilket gør værdifulde bidrag til holdenes mangfoldighed og kvalitet. Desuden giver de mulighed for kulturel udveksling og venskab mellem roere fra forskellige lande.
      <br />
      <br />
      Der er flere grunde til, at internationale studerende vælger kollegial roning i USA. Nogle af disse grunde inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Roning Stipendier: Mange amerikanske universiteter tilbyder roning stipendier til talentfulde roere, hvilket kan reducere eller endda eliminere deres undervisningsomkostninger. Dette er en betydelig motivationsfaktor for internationale studerende, der ellers måske ikke kunne studere i USA.
        </li>
        <li>
          Akademisk Excellence: Mange amerikanske universiteter er kendt for deres høje akademiske standarder og ry. Dette tiltrækker internationale studerende, der søger en fremragende uddannelse og en globalt anerkendt grad.
        </li>
        <li>
          Sportslig Udfordring: Mange internationale studerende ser kollegial roning som en sportslig udfordring og en mulighed for at konkurrere med verdens bedste roere. De sigter mod at forbedre deres færdigheder, presse deres grænser og opfylde deres potentiale.
        </li>
        <li>
          Personlig Udvikling: Mange internationale studerende betragter kollegial roning som personlig udvikling og en chance for at vokse som individer. De ønsker at få nye oplevelser, møde nye mennesker, lære om nye kulturer og udvide deres horisonter.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      "Niki van Sprang er et eksempel på en international studerende, der roede på kollegialt niveau i USA. Van Sprang, oprindeligt fra Holland, hvor han vandt flere nationale mesterskaber, modtog et roning stipendium fra University of California, Berkeley. Her blev han medlem af varsity holdet og opnåede betydelig succes. Efter sin eksamen i 2017 vendte Van Sprang tilbage til Holland og blev medlem af det nationale roningsteam. Han deltog i OL i Tokyo 2020, hvor han og Guillaume Krommenhoek endte på 7. pladsen i mændenes toer uden styrmand. Derudover vandt han sølv ved VM 2022 i Račice og ved EM 2022 i München, samt en bronzemedalje ved EM 2023 i Bled, alle i mændenes otter."
    </MKTypography>
  </MKBox>

  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikanske Roere i Udlandet
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Kollegial roning kan også føre til internationale muligheder for amerikanske studerende-atleter, der ønsker at udvide eller ændre deres sportslige horisonter. Der er forskellige muligheder for amerikanske roere for at ro i udlandet og få erfaring. Nogle af disse muligheder inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Studieudveksling: Nogle amerikanske universiteter har udvekslingsprogrammer med udenlandske universiteter, der giver studerende mulighed for at studere og ro i et andet land i et semester eller et år. Dette giver dem mulighed for at opleve en anden kultur, sprog og uddannelsessystem samt at ro med andre hold og trænere.
        </li>
        <li>
          Træningslejre: Nogle amerikanske universiteter arrangerer træningslejre i udlandet, normalt i løbet af vinter- eller sommerpausen. Dette giver dem mulighed for at træne i et anderledes miljø, klima og vandforhold samt at konkurrere mod andre hold eller deltage i lokale regattaer.
        </li>
        <li>
          Træning: Nogle amerikanske roere beslutter at træne i udlandet efter endt uddannelse, typisk på skoler, klubber eller nationale hold. Dette giver dem mulighed for at dele deres viden og erfaring samt at lære af andre trænere og kulturer.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>
                  






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Sportslegater og fremtiden for atleter
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sportslegater tilbyder talrige muligheder for unge atleter til at vise deres talent og udvikle deres færdigheder. En af de mest betydningsfulde muligheder er at opnå et sportslegat for at studere og konkurrere på kollegialt niveau. I dette kapitel vil vi diskutere vigtigheden af sportslegater og hvordan Sport Legater Amerika kan hjælpe dig med at realisere din drøm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vigtigheden af legater
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et sportslegat er økonomisk støtte, som et universitet tilbyder til en studerende-atlet for at kombinere studier og sport. Det kan dække omkostningerne til undervisning, bøger, bolig, måltider og andre udgifter. Derudover kan det også tilbyde fordele såsom akademisk vejledning, lægehjælp, træningsfaciliteter og udstyr.
      <br />
      <br />
      At opnå et sportslegat er konkurrencepræget, hvor mange faktorer spiller en rolle i tildelingsprocessen, herunder akademiske præstationer, atletisk præstation, personlighed, motivation, lederskab og tilpasningsevne. Derfor er det vigtigt at forberede sig godt og adskille sig fra konkurrencen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Legater Amerika Kan Hjælpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo af Sport Legater Amerika, der viser sportsudstyr og en eksamenshue, der repræsenterer fokus på sportslegater for studerende-atleter."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo af Sport Legater Amerika
        </figcaption>
      </figure>
      Sport Legater Amerika er en organisation specialiseret i at guide unge sports talenter til sportslegater. Med passionerede og erfarne eksperter støtter Sport Legater Amerika studerende-atleter på hvert trin af rejsen, fra indledende orientering til endelig placering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intake-interview for at diskutere den studerende-athlets ønsker, muligheder og forventninger.</li>
        <li>En professionel evaluering af den studerende-athlets atletiske og akademiske niveau.</li>
        <li>Et omfattende netværk af kontakter med trænere, universiteter og kollegier.</li>
        <li>Effektiv markedsføring af den studerende-athlets profil og videoer til potentielle trænere.</li>
        <li>Ekspertrådgivning om de bedste muligheder baseret på den studerende-athlets præferencer, mål og budget.</li>
        <li>Forhandling af de bedste legat tilbud på vegne af den studerende-atlet.</li>
        <li>Hjælp til ansøgning om nødvendige dokumenter, såsom visum, transkript, eksamensbevis og optagelsesprøver.</li>
        <li>Forberedelse til den studerende-atlets liv under deres legat, herunder kultur, sprog, regler og tips.</li>
        <li>Efterpleje og opfølgning under legatperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ønsker du at udforske legatmuligheder og opnå dine sportslige og akademiske mål? <Link to="/da/Tjenester-Produkter">Se det fulde udvalg</Link> af tjenester tilbudt af Sport Legater Amerika og planlæg en konsultation i dag.
    </MKTypography>
  </MKBox>
</MKBox>



          
          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;