import { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import SimplePricingCard from "examples/Cards/PricingCards/SimplePricingCard";
import SimplePricingCardRibbon from "examples/Cards/PricingCards/SimplePricingCardRibbon";
import { PopupButton } from "react-calendly";
import { Link } from 'react-scroll';
import TextField from "@mui/material/TextField";  // Add this line
import Box from '@mui/material/Box'; // Ensure Box is imported
import emailjs from 'emailjs-com';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Link as RouterLink } from 'react-router-dom';







function Diensten() {
  const [activeTab, setActiveTab] = useState(0);
  const [tabType, setTabType] = useState("monthly");
  const [showModal, setShowModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [email, setEmail] = useState("");
  const [isAgreed, setIsAgreed] = useState(false);


  const toggleModal = () => setShowModal(!showModal);

  const handleTabType = ({ currentTarget }, newValue) => {
    setActiveTab(newValue);
    setTabType(currentTarget.id);
  };

  const handleEmailSubmit = async () => {
    if (email && isAgreed) {
      const emailParams = {
        from_email: email,
        // other parameters if needed
      };
  
      emailjs.send('service_sj82src', 'template_2eyxhno', emailParams, 'xOBnOFlYt964jvT3q')
        .then((response) => {
          console.log('Email sent successfully', response.status, response.text);
          alert('Link aangevraagd, controleer uw inbox.');
          setShowEmailModal(false);
          setEmail("");
          setIsAgreed(false);
        }, (error) => {
          console.log('Failed to send email', error);
          alert('Aanvraag mislukt, probeer nogmaals.');
        });
    } else if (!isAgreed) {
      alert('Gelieve akkoord te gaan met de privacyverklaring.');
    } else {
      alert('Voer alstublieft een e-mailadres in');
    }
  };


  

  return (
    <section id="dienstenSection">
      <Container sx={{ pb: { xs: 4, lg: 4 }, pt: 12 }}>
          {/* Email Input Modal */}
          <Modal
        open={showEmailModal}
        onClose={() => setShowEmailModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: { xs: '90%', sm: '70%', md: '50%', lg: '40%' },
            maxWidth: '500px',
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <TextField
            label="Email Address"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ mb: 2 }}
          />
          {/* Checkbox for privacy policy agreement */}
          <FormControlLabel
  control={<Checkbox checked={isAgreed} onChange={(e) => setIsAgreed(e.target.checked)} />}
  label={
    <span>
       I have read the{' '}
      <RouterLink 
        to="/Privacy-Policy" 
        component="a" 
        target="_blank" 
        style={{ textDecoration: 'none' }}>
         Privacy Policy
      </RouterLink> and I agree to it.
    </span>
  }
  sx={{ my: 2 }}
/>


          <MKButton variant="gradient" color="dark" onClick={handleEmailSubmit}>
            Verstuur
          </MKButton>
        </Box>
      </Modal>


      <Grid container item xs={12} justifyContent="center" md={8} mx="auto" textAlign="center">
      <Grid item xs={12}> {/* Full width for the heading */}
    <MKTypography variant="h3" color="text">Services</MKTypography>
  </Grid>
  <Grid item xs={12}> {/* Full width for the body text */}
    <MKTypography variant="body2" color="text">
    All services offered by Sport Scholarships America
</MKTypography>
  </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={10}
          lg={8}
          justifyContent="center"
          textAlign="center"
          mx="auto"
          mt={6}
        >
          <AppBar position="static">
          <Tabs value={activeTab} onChange={handleTabType}>
  <Tab
    id="monthly"
    label={
      <Link 
        to="dienstenSection" 
        spy={true}
        smooth={true}
        duration={500}
        offset={-70}
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <MKBox py={0.5} px={2} color="inherit">
          Services
        </MKBox>
      </Link>
    }
  />
  <Tab
    id="annual"
    label={
      <Link 
        to="productenSection" 
        spy={true}
        smooth={true}
        duration={500}
        offset={-70}
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <MKBox py={0.5} px={2} color="inherit">
          Products
        </MKBox>
      </Link>
    }
  />
</Tabs>
          </AppBar>
        </Grid>
        <Grid container spacing={3} mt={6}>
        <Modal open={showModal} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
          <Slide direction="down" in={showModal} timeout={500}>
            <MKBox
              position="relative"
              width="500px"
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              bgColor="white"
              shadow="xl"
            >
              <MKBox display="flex" alginItems="center" justifyContent="space-between" p={2}>
                <MKTypography variant="h5">
Kies voor een intakegesprek
</MKTypography>
                <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox p={2}>
                <MKTypography variant="body2" color="secondary" fontWeight="regular">
                Beste klant,
                <br />
                  <br />

                  Ik ben verheugd dat u interesse heeft in mijn standaard service! Om ervoor te zorgen dat mijn service perfect aansluit op uw specifieke behoeften, raad ik ten zeerste aan om eerst een vrijblijvend intakegesprek te boeken.
                  <br />
                  <br />
                  Tijdens dit gesprek kunnen we uw situatie in detail bespreken en samen bepalen welke aanpak het beste bij u past. Dit zorgt voor een meer gepersonaliseerde ervaring en helpt mij om de service te optimaliseren voor uw unieke situatie.


                </MKTypography>
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox display="flex" justifyContent="space-between" p={1.5}>
                <MKButton variant="gradient" color="dark" onClick={toggleModal}>
                  sluit
                </MKButton>
                
                <PopupButton
    url="https://calendly.com/kay-duit"
    rootElement={document.getElementById("root")}
    text="MAAK AFSPRAAK"
    className="custom-calendly-button"
  />
              </MKBox>
            </MKBox>
          </Slide>
        </Modal>
        <Grid item xs={12} sm={6} lg={3}>
  <SimplePricingCard
    color="dark"
    title="Information Guide"
    description="Complete information guide in PDF"
    price={{ value: "Free", type: "" }}
    action={{
      type: "downloadPDF",
      label: "Download PDF",
      onClick: () => setShowEmailModal(true) // Function to show email input modal
    }}
    specifications={["Complete information guide in PDF"]}
  />
</Grid>
<Grid item xs={12} sm={6} lg={3}>
  <SimplePricingCardRibbon
    color="dark"
    title="Intake Conversation"
    description="60-minute online consultation"
    price={{ value: " € 14.99*", type: "" }}
    action={{
      type: "external", // Change to external for Calendly
      route: "https://calendly.com/kay-duit", // Your Calendly link
      label: "SCHEDULE APPOINTMENT"
    }}
    specifications={[
      "Aligning goals and ambitions",
      "Athletic performance assessment",
      "Academic integration plan",
      "Individual trajectory strategy",
      "Success chances analysis in the USA"
    ]}
  />
</Grid>

          <Grid item xs={12} sm={6} lg={3}>
  <SimplePricingCard
    color="dark"
    title="Standard"
    description="Your path to the US"
    price={{ value: "On Request", type: "" }}
    action={{
      type: "modal",
      label: "Request",
      onClick: toggleModal
    }}
    specifications={[
      "Customized plan",
      "Support in creating a sports portfolio",
      "Support in academic preparation",
      "Promotion to universities",
      "Negotiating sports scholarships",
      "University and scholarship selection advice",
      "Visa application support",
      "Enrollment support",
      "Practical matters support",
    ]}
  />
</Grid>
<Grid item xs={12} sm={6} lg={3}>
  <SimplePricingCard
    color="dark"
    title="Premium"
    description="Your path to the US"
    price={{ value: "On Request", type: "" }}
    action={{
      type: "modal",
      label: "Request",
      onClick: toggleModal
    }}
    specifications={[
      "Customized plan",
      "Support in creating a sports portfolio",
      "Support in academic preparation",
      "Promotion to universities",
      "Negotiating sports scholarships",
      "University and scholarship selection advice",
      "Visa application support",
      "Enrollment support",
      "Practical matters support",
      "SAT/ACT study books",
      "Bi-weekly Zoom call",
    ]}
  />
</Grid>
        </Grid>
      </Container>
      <Container>
    <Grid item xs={12} style={{ marginBottom: '80px' }}>
      <MKTypography variant="body2" color="text">
        {/* Your text goes here */}
        *Previously, I offered the intake conversation for free. Unfortunately, this led to many appointments with non-serious individuals, which was costly in terms of time. To ensure the quality of my services and to ensure that conversations are valuable for both parties, I now request a small fee of €14.99. This amount helps filter serious inquiries while keeping the conversation informal and focused on your specific needs.

I appreciate your understanding and look forward to a productive and insightful conversation with you.
      </MKTypography>
    </Grid>
  </Container>
    
    </section>
  );
}



export default Diensten;
