/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Waterpol1.jpeg";
import Image2 from "assets/images/Sports_Images/Waterpolo2.jpeg";
import Image3 from "assets/images/Sports_Images/Waterpolo3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Vandpolostipendier i Amerika: Hvordan Studere og Spille i Landet av Stjerner og Striper', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapittel 1: Historie og Utvikling av Vannpolo i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Tidlige År', isSubheader: true },
    { id: '1.2', title: '1.2 Vekst og Professionalisering', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapittel 2: Universitetsvannpolo i dag', isSubheader: false },
    { id: '2.1', title: '2.1 Nøkkelkonkurranser og Konferanser', isSubheader: true },
    { id: '2.2', title: '2.2 Topp Program og Prestisjefylte Lag', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapittel 3: Fra College til Profesjonell', isSubheader: false },
    { id: '3.1', title: '3.1 Veien til Professionalisme', isSubheader: true },
    { id: '3.2', title: '3.2 Profesjonelle Ligaer', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapittel 4: Internasjonale Innflytelser og Konkurranser', isSubheader: false },
    { id: '4.1', title: '4.1 Utenlandske Innflytelser', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanere i Internasjonale Konkurranser', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapittel 5: Idrettsstipender og Fremtiden til Utøvere', isSubheader: false },
    { id: '5.1', title: '5.1 Viktigheten av Stipender', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Scholarships America Kan Hjelpe', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Vandpolostipendier i Amerika: Hvordan Studere og Spille i Landet av Stjerner og Striper
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Stadion fylt av tilskuere som venter på en college-vannpolokamp."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Rolig før en kamp
      </figcaption>
    </figure>
    Vandpolo oppsto i Europa på slutten av 1800-tallet som en form for vannrugby og fikk raskt popularitet i ulike land, inkludert Amerika. Den første offisielle kampen i Amerika ble spilt i 1888 mellom to klubber i New York. Siden den gang har vannpolo utviklet seg betydelig i Amerika, både på profesjonelt og universitetsnivå.
    <br />
    <br />
    Universitetsvannpolo er en av de viktigste faktorene i utviklingen av vannpolospillere i Amerika. Mange spillere starter karrieren sin på videregående skole og går deretter videre til college, der de har muligheten til å konkurrere mot de beste lagene og spillerne i landet. Universitetsvannpolo gir også en utmerket akademisk utdanning, slik at spillerne kan kombinere lidenskapen sin for sporten med sine utdanningsmål.
    <br />
    <br />
    Men hvordan kan du som nederlandsk student studere og spille i Amerika? Det er her Sportbeursamerika.nl kan hjelpe. Sportbeursamerika.nl er en organisasjon spesialisert på å hjelpe studenter med å få vannpolostipendier i Amerika. Sportbeursamerika.nl har et team av eksperter som kan veilede deg gjennom hvert trinn i prosessen, fra å finne riktig universitet til å håndtere alle praktiske spørsmål.
    <br />
    <br />
    Hvis du er interessert i å få et vannpolostipend i Amerika, fortsett å lese for å lære mer om denne spennende muligheten.
  </MKTypography>
  {/* Legg til punktliste eller annet innhold ved behov */}
</MKBox>
{/* Hovedkapitler og underkapitler med plassholdertekst */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 1: Historie og Utvikling av Vannpolo i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Vannpolo har en lang og rik historie i Amerika. Det er en av de eldste olympiske idrettene, først spilt under OL i 1900 i Paris, der også Amerika deltok. Siden den gang har Amerika deltatt i alle olympiske vannpolokonkurranser, bortsett fra i 1976 og 1980 da det trakk seg i protest mot politiske situasjoner i Sør-Afrika og Sovjetunionen, henholdsvis.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidlige År
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      De første vannpoloklubbene i Amerika ble etablert i New York og Boston på slutten av 1800-tallet. De spilte etter de engelske reglene, som var mye grovere og mer voldelige enn de moderne reglene. Sporten ble raskt populær blant innvandrere fra Europa, spesielt fra Irland og Skottland. Det første nasjonale mesterskapet ble arrangert i 1890 og ble vunnet av Sydenham Swimmers Club.
      <br />
      <br />
      De første universitetslagene ble dannet tidlig på 1900-tallet, spesielt på østkysten. Ivy League-universitetene som Harvard, Yale og Princeton dominerte sporten frem til 1920-tallet. Den første intercollegiate konkurransen ble organisert i 1912 og ble vunnet av Princeton.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Vekst og Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      På 1920-tallet begynte vannpolo å spre seg til andre deler av landet, spesielt til California. En ny spillstil, fokusert på hastighet, smidighet og skyting i stedet for fysisk kontakt, utviklet seg der. California-lagene ble raskt de beste i landet, og vant de fleste nasjonale og intercollegiate titler.
      <br />
      <br />
      På 1930-tallet oppsto de første profesjonelle ligaene i Amerika, som American Water Polo League og Pacific Coast Water Polo League. Disse ligaene trakk til seg mange tilskuere og medieoppmerksomhet, og hevet nivået og populariteten til sporten. Mange profesjonelle spillere var også universitetsspillere eller kandidater som fortsatte karrieren etter studiene.
      <br />
      <br />
      En av nøkkelpersonene i historien om amerikansk vannpolo var James "Jimmy" Smith, betraktet som "far til moderne vannpolo." Han var spiller, trener, dommer, organisator og promoter av sporten. Han spilte for ulike klubber og universiteter, inkludert UCLA, der han senere ble trener. Han ledet det amerikanske laget til to olympiske bronsemedaljer i 1924 og 1932. Han var også en av grunnleggerne av International Swimming Hall of Fame, der han ble innlemmet i 1965.
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  







<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 2: Universitetsvannpolo i dag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Universitetsvannpolo er en av de mest prestisjefylte og konkurransedyktige idrettene i Amerika. Det tilbyr en unik mulighet for studenter til å kombinere sine akademiske og idrettslige ambisjoner og konkurrere mot de beste spillerne i landet. Universitetsvannpolo er også en betydelig kilde til talent for landslaget og profesjonelle ligaer.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Nøkkelkonkurranser og Konferanser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Action i vannet: spiller skyter mot målet under en vannpolokamp."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Spillere i aksjon
        </figcaption>
      </figure>
      Universitetsvannpolo reguleres av National Collegiate Athletic Association (NCAA), som deler sporten inn i tre divisjoner basert på nivået og antallet stipend som er tilgjengelige. Divisjon I er den høyeste divisjonen, bestående av omtrent 30 lag, hovedsakelig fra California. Divisjon II og III har hver rundt 20 lag, mer spredt over landet.
      <br />
      <br />
      Hvert år arrangeres det et nasjonalt mesterskap for hver divisjon, der de beste lagene fra hver konferanse konkurrerer om tittelen. En konferanse er en gruppe universiteter som er geografisk eller historisk tilknyttet og jevnlig konkurrerer mot hverandre. De største konferansene for vannpolo inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Mountain Pacific Sports Federation (MPSF), som består av åtte lag fra California, inkludert UCLA, Stanford, USC og Berkeley. Denne konferansen regnes som den sterkeste og mest prestisjefylte i landet og har vunnet flest nasjonale titler.
        </li>
        <li>
          Western Water Polo Association (WWPA), som består av ni lag fra California, Colorado, Utah og Hawaii. Denne konferansen er den nest sterkeste i landet og har også vunnet noen nasjonale titler.
        </li>
        <li>
          Collegiate Water Polo Association (CWPA), som består av 18 lag fra østkysten, inkludert Harvard, Princeton, Brown og Navy. Denne konferansen er den tredje sterkeste i landet og har av og til vunnet en nasjonal tittel.
        </li>
        <li>
          Southern California Intercollegiate Athletic Conference (SCIAC), som består av ni lag fra Sør-California, inkludert Pomona-Pitzer, Claremont-Mudd-Scripps og Occidental. Denne konferansen er den fjerde sterkeste i landet og konkurrerer primært i Divisjon III.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Topp Program og Prestisjefylte Lag
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Universitetsvannpolo har flere ledende programmer kjent for deres fremragende prestasjoner, både innen idrett og akademia. Disse programmene tiltrekker seg mange talentfulle spillere som drar nytte av de førsteklasses fasilitetene, coachingen og utdanningen de tilbyr. Noen av disse programmene er:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          University of California, Los Angeles (UCLA) er kjent for sitt enestående vannpoloprogram, som rangerer blant de mest vellykkede i historien. Med et imponerende antall nasjonale titler for både menns og kvinners lag, har programmet en fremtredende plass i sporten. Kampene spilles på Spieker Aquatics Center, med en kapasitet på 2 500 tilskuere. UCLA-laget er kjent for sin raske og dynamiske spillestil og har produsert et betydelig antall olympiske medaljevinnere.
        </li>
        <li>
          Stanford University skryter også av et prestisjefylt vannpoloprogram med mange nasjonale titler for både herre- og damelag. Lagene spiller hjemmekampene sine på Avery Aquatic Center, som har plass til 2 530 tilskuere. Stanfords vannpololag er kjent for sine tekniske og taktiske ferdigheter og har også bidratt til utviklingen av mange olympiske medaljevinnere.
        </li>
        <li>
          University of Southern California (USC) har også et sterkt vannpoloprogram med flere nasjonale mesterskap for både menn og kvinner. Hjemmebasen deres er Uytengsu Aquatics Center, med plass til 2 500 tilskuere. USC-laget kjennetegnes av en fysisk og aggressiv spillestil og har også trent en betydelig antall olympiske medaljevinnere.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>



                                              






                                  








<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 3: Fra College til Profesjonell
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Universitetsvannpolo er ikke bare en flott måte å studere og spille på i Amerika, men også en mulig trappetrinn til en profesjonell karriere. Mange universitetsspillere går videre til å spille i profesjonelle ligaer, både i Amerika og i utlandet, etter endt utdanning. Noen av dem blir også valgt til landslaget, som deltar i internasjonale turneringer som OL, verdensmesterskapet og Verdensligaen.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Veien til Professionalisme
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Selv om vannpolo er en populær og konkurransedyktig sport i Amerika, er den fortsatt ikke like kommersiell og lukrativ som noen andre idretter som basketball, fotball eller baseball. Derfor er det ikke mange profesjonelle ligaer eller lag i Amerika, og lønninger og premiepenger er relativt lave. De fleste profesjonelle spillere har også en annen jobb eller inntektskilde ved siden av sin vannpolokarriere.
      <br />
      <br />
      Den viktigste profesjonelle ligaen i Amerika er National Water Polo League (NWPL), som ble etablert i 2018 som en etterfølger til American Water Polo League (AWPL). NWPL består av åtte lag, hver sammensatt av 15 spillere. Lagene spiller en ordinær sesong med 14 kamper, etterfulgt av et sluttspill for å avgjøre mesteren. Den nåværende mesteren er Los Angeles Water Polo Club, som vant tittelen i 2022.
      <br />
      <br />
      De fleste spillerne i NWPL er tidligere universitetsspillere eller kandidater som fortsetter karrieren etter studiene. Noen av dem er også medlemmer av landslaget, som jevnlig trener og spiller på Olympic Training Center i Colorado Springs. Landslaget ledes av Dejan Udovicic, en tidligere serbisk spiller og trener som har vært ved roret siden 2013.
      <br />
      <br />
      En av de mest vellykkede alumnene fra universitetsvannpolo er Tony Azevedo, som regnes som en av tidenes største spillere. Han spilte for Stanford University, hvor han ble kåret til NCAA Player of the Year fire ganger. Han spilte også for flere profesjonelle lag i Europa og Amerika, inkludert Brescia (Italia), Olympiacos (Hellas) og Long Beach Shore (Amerika). Han deltok også i fem OL, og vant en sølvmedalje i 2008. Han blir ofte referert til som "The Savior" fordi han ofte scoret avgjørende mål for sitt lag.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Profesjonelle Ligaer
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      I tillegg til NWPL, er det andre profesjonelle ligaer i Amerika, som Major League Water Polo (MLWP) og Premier Water Polo League (PWPL). Imidlertid er disse ligaene mindre etablerte og mindre populære enn NWPL, og har færre lag og spillere. De blir heller ikke anerkjent av USA Water Polo, den nasjonale organisasjonen ansvarlig for å organisere og regulere vannpolo i Amerika.
      <br />
      <br />
      Mange amerikanske spillere velger derfor å fortsette sin profesjonelle karriere i utlandet, hvor det er flere muligheter og bedre forhold. Europa er det primære reisemålet for amerikanske vannpolospillere fordi kontinentet har noen av de sterkeste og mest prestisjefylte ligaene i verden. Noen av disse ligaene inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          LEN Champions League, regnet som den øverste klubbkonkurransen i Europa. Den består av 16 lag fra forskjellige land som konkurrerer om den europeiske tittelen. Den nåværende mesteren er Pro Recco (Italia), som vant tittelen i 2021.
        </li>
        <li>
          LEN Euro Cup, ansett som den andre klubbkonkurransen i Europa. Den består av 32 lag fra forskjellige land som kjemper om en plass i Champions League. Den nåværende mesteren er Orvosegyetem SC (Ungarn), som vant tittelen i 2021.
        </li>
        <li>
          Nasjonale ligaer organisert av de respektive forbundene i hvert land. Noen av de sterkeste og mest populære nasjonale ligaene inkluderer Serie A1 (Italia), Liga Premaat (Spania), A1 Ethniki (Hellas) og OB I (Ungarn).
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  




<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 4: Internasjonale Innflytelser og Konkurranser
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Vannpolo er en global sport som spilles og følges av millioner av mennesker over hele verden. Det er også en sport som stadig utvikler seg og innoverer, takket være innflytelsen fra ulike kulturer, stiler og tradisjoner. Amerikansk vannpolo er intet unntak, da det har lært og nyttiggjort seg av internasjonal vannpolo, både på klubb- og nasjonalt nivå.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Utenlandske Innflytelser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="NCAA water polo championship match"
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          En kamp for NCAA-mesterskapet
        </figcaption>
      </figure>
      Som vi har sett, har vannpolo sin opprinnelse i Europa og spredte seg til andre kontinenter, inkludert Amerika. Derfor har europeisk vannpolo hatt en betydelig innflytelse på amerikansk vannpolo, spesielt når det gjelder teknikk, taktikk og strategi. Mange amerikanske spillere og trenere har forbedret sine ferdigheter og kunnskaper ved å spille eller trene i Europa eller lære av europeiske spillere og trenere.
      <br />
      <br />
      En av de mest innflytelsesrike europeiske landene for amerikansk vannpolo er Ungarn, som regnes som verdens mest vellykkede vannpolonasjon. Ungarn har vunnet 15 olympiske gullmedaljer, mer enn noe annet land. Ungarn er kjent for sin kreative og intelligente spillestil, som er basert på god ballbehandling, raske pasninger og presis skyting. Mange ungarske spillere og trenere har satt sitt preg på amerikansk vannpolo, som Tibor Benedek, Zoltan Szecsi og Attila Banhidy.
      <br />
      <br />
      Et annet innflytelsesrikt europeisk land for amerikansk vannpolo er Serbia, som også er en av de ledende vannpolonasjonene globalt. Serbia har vunnet 7 olympiske gullmedaljer, med den siste i 2016. Serbia er kjent for sin fysiske og aggressive spillestil, som er basert på solid forsvar, raske kontringer og kraftig skyting. Mange serbiske spillere og trenere har delt sin erfaring og ekspertise med amerikansk vannpolo, inkludert Dejan Udovicic, Andrija Prlainovic og Filip Filipovic.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikanere i Internasjonale Konkurranser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      I tillegg til å lære av internasjonal vannpolo, har amerikansk vannpolo også bidratt betydelig til internasjonal vannpolo, spesielt når det gjelder prestasjon, innovasjon og promotering. Mange amerikanske spillere og trenere har deltatt i internasjonale konkurranser, både på klubb- og landslagsnivå, der de har vist frem talentene sine og forbedret sitt rykte.
      <br />
      <br />
      En av de viktigste internasjonale vannpolokonkurransene er de olympiske leker, som arrangeres hvert fjerde år. Det amerikanske laget har alltid deltatt i denne konkurransen, bortsett fra i 1976 og 1980. Det amerikanske laget har vunnet totalt 12 olympiske medaljer: 3 gull, 4 sølv og 5 bronse. Den mest nylige medaljen var en sølvmedalje for mennene i 2008. Det amerikanske laget er for øyeblikket rangert som nummer fire i verden.
      <br />
      <br />
      En annen betydelig internasjonal konkurranse for vannpolo er verdensmesterskapet, som arrangeres hvert andre år. Det amerikanske laget har også jevnlig deltatt i denne konkurransen siden den første utgaven i 1973. Det amerikanske laget har vunnet totalt 9 verdensmesterskapsmedaljer: 2 gull, 3 sølv og 4 bronse. Den mest nylige medaljen var en bronsemedalje for kvinnene i 2019. Det amerikanske laget er for øyeblikket rangert som nummer tre i verden.
      <br />
      <br />
      En tredje stor internasjonal konkurranse for vannpolo er Verdensligaen, som arrangeres årlig. Det amerikanske laget har også jevnlig deltatt i denne konkurransen siden den første utgaven i 2002. Det amerikanske laget har vunnet totalt 10 Verdensliga-medaljer: 3 gull, 4 sølv og 3 bronse. Den mest nylige medaljen var en gullmedalje for kvinnene i 2021. Det amerikanske laget er for øyeblikket rangert som nummer to i verden.
    </MKTypography>
  </MKBox>
</MKBox>







                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 5: Idrettsstipender og Fremtiden til Utøvere
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrettsstipender gir mange muligheter for unge utøvere til å vise sitt talent og utvikle sine ferdigheter. En av de mest betydningsfulle mulighetene er å få et idrettsstipend for å studere og konkurrere på college-nivå. I dette kapittelet vil vi diskutere viktigheten av idrettsstipender og hvordan Sport Scholarships America kan hjelpe deg med å oppfylle drømmen din.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Viktigheten av Stipender
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et idrettsstipend er økonomisk støtte gitt av et universitet til en studentutøver for å kombinere studier og idrett. Det kan dekke kostnadene for skolepenger, bøker, bolig, måltider og andre utgifter. I tillegg kan det tilby fordeler som akademisk veiledning, medisinsk hjelp, treningsfasiliteter og utstyr.
      <br />
      <br />
      Å få et idrettsstipend er konkurransedyktig, med mange faktorer som spiller en rolle i tildelingsprosessen, inkludert akademiske prestasjoner, idrettslige resultater, personlighet, motivasjon, lederegenskaper og tilpasningsevne. Det er derfor viktig å forberede seg godt og skille seg ut fra konkurransen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Scholarships America Kan Hjelpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo for Sport Scholarships America, som viser sportsutstyr og en eksamenskappe, symboliserer fokuset på idrettsstipender for studentutøvere."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo for Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America er en organisasjon spesialisert i å veilede unge idrettstalenter til idrettsstipender. Med lidenskapelige og erfarne eksperter støtter Sport Scholarships America studentutøvere på hvert trinn i reisen, fra første orientering til endelig plassering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig inntaksintervju for å diskutere studentutøverens ønsker, muligheter og forventninger.</li>
        <li>En profesjonell evaluering av studentutøverens akademiske og idrettslige nivå.</li>
        <li>Et omfattende nettverk av kontakter med trenere, universiteter og høyskoler.</li>
        <li>Effektiv markedsføring av studentutøverens profil og videoer til potensielle trenere.</li>
        <li>Ekspertråd om de beste alternativene basert på studentutøverens preferanser, mål og budsjett.</li>
        <li>Forhandlinger om de beste stipendtilbudene på vegne av studentutøveren.</li>
        <li>Hjelp til å søke om nødvendige dokumenter, som visum, transkripsjon, diploma og opptaksprøver.</li>
        <li>Forberedelse til studentutøverens liv under stipendet, inkludert kultur, språk, regler og tips.</li>
        <li>Oppfølging og oppfølging under stipendperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vil du utforske stipendmuligheter og oppnå dine idrettslige og akademiske mål? <Link to="/no/Tjenester-Produkter">Se det komplette utvalget</Link> av tjenester som tilbys av Sport Scholarships America og planlegg en konsultasjon i dag.
    </MKTypography>
  </MKBox>
</MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;