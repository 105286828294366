/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Track and Field1.jpg";
import Image2 from "assets/images/Sports_Images/Track and Field2.jpeg";
import Image3 from "assets/images/Sports_Images/Track and Field3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Atletik og Studier i Amerika: En Drøm, der Kan Gå i Opfyldelse', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapitel 1: Historie og Udvikling af Atletik på Collegeniveau', isSubheader: false },
    { id: '1.1', title: '1.1 Tidlige År og Oprindelse', isSubheader: true },
    { id: '1.2', title: '1.2 Vækst og Professionalisering', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapitel 2: Atletik på Collegeniveau i Dag', isSubheader: false },
    { id: '2.1', title: '2.1 Store Konkurrencer og Konferencer', isSubheader: true },
    { id: '2.2', title: '2.2 Topprogrammer og Prestigefyldte Hold', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapitel 3: Fra College til Professionel', isSubheader: false },
    { id: '3.1', title: '3.1 Vejen til Professionalisme', isSubheader: true },
    { id: '3.2', title: '3.2 Forholdet til Professionel Atletik', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapitel 4: Internationale Påvirkninger og Muligheder', isSubheader: false },
    { id: '4.1', title: '4.1 Udenlandske Atleter og Konkurrencer', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanske Atleter i Udlandet', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapitel 5: Sportslegater og fremtiden for atleter', isSubheader: false },
    { id: '5.1', title: '5.1 Vigtigheden af legater', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Legater Amerika Kan Hjælpe', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contents
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                      key={section.id}
                      onClick={() => scrollTo(section.id)}
                      color="secondary"
                      variant="gradient"
                      sx={{ 
                        justifyContent: "flex-start",
                        pl: section.isSubheader ? 4 : 2,
                        textTransform: "none",
                        fontWeight: section.isSubheader ? "normal" : "bold",
                        borderTopLeftRadius: index === 0 ? '20px' : '0',
                        borderTopRightRadius: index === 0 ? '20px' : '0',
                        borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                        borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                        textAlign: "left",
                        width: '100%',
                      }}
                    >
                      {section.title}
                    </MKButton>
                  ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>

























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Atletik og Studier i Amerika: En Drøm, der Kan Gå i Opfyldelse
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Løbere i fuld gang under Penn State Relays, en fremtrædende begivenhed inden for college atletik."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Handling ved Penn State Relays.
      </figcaption>
    </figure>
    Atletik er en af de mest populære og betydningsfulde sportsgrene i USA. Millioner af mennesker dyrker atletik som en hobby, som en del af deres skole- eller collegeprogram eller som professionelle idrætsudøvere. Atletik er også en sport, der tilbyder mange muligheder for unge talenter til at udvikle sig, konkurrere og excel på højeste niveau.
    <br/>
    <br/>
    En af de bedste måder at udnytte disse muligheder på er ved at få et atletikstipendium i USA. Et atletikstipendium er økonomisk støtte, du modtager fra et universitet for at studere og deltage i atletik der. Med et atletikstipendium kan du ikke kun tjene din akademiske grad, men også deltage i et af de mest prestigefyldte og konkurrencedygtige atletikprogrammer i verden.
    <br/>
    <br/>
    Men hvordan får du et atletikstipendium i USA? Hvad er fordelene og udfordringerne ved at studere og konkurrere på college-niveau? Og hvordan kan du forberede dig til en succesrig karriere som atlet efter dine studier? Dette er nogle af de spørgsmål, vi vil besvare i denne artikel.
    <br/>
    <br/>
    Vi vil også fortælle dig, hvordan Sportbeursamerika.nl kan hjælpe dig med at gøre din drøm til virkelighed. Sportbeursamerika.nl er en organisation, der er specialiseret i at guide unge atleter til sportsstipendier i USA. Jeg har stor erfaring med at finde det bedste match mellem atleter og universiteter. Jeg tilbyder personlig vejledning, professionel rådgivning og praktisk support ved hvert trin af processen.
    <br/>
    <br/>
    Hvis du er interesseret i at få et atletikstipendium i USA, er denne artikel for dig. Læs videre og opdag alt, hvad du har brug for at vide om atletik på collegeniveau i USA.
  </MKTypography>
  {/* Tilføj punktopstilling eller andet indhold efter behov */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Historie og Udvikling af Atletik på Collegeniveau
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Atletik på collegeniveau har en lang og rig historie i USA. Det begyndte i det 19. århundrede som en måde at fremme de fysiske og mentale helbred hos studerende. Det udviklede sig til en stor sport, der tiltrak millioner af tilskuere og producerede mange atleter, der senere blev verdensberømte.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidlige År og Oprindelse
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Den første atletikkonkurrence på universiteter fandt sted i 1864, da studerende fra Yale og Oxford udfordrede hinanden til en kamp i London. Konkurrencen bestod af fire begivenheder: løb, længdespring, højdespring og stangspring. Yale vandt kampen 7-3.
      <br/>
      <br/>
      Denne kamp inspirerede andre universiteter til også at danne atletikhold og konkurrere mod hinanden. I 1873 blev den første interkollegiale atletikforening grundlagt, Intercollegiate Association of Amateur Athletes of America (IAAAA). IAAAA organiserede årlige mesterskaber for universiteterne i det østlige USA.
      <br/>
      <br/>
      Slutningen af det 19. århundrede var en vigtig æra for udviklingen af atletik. Det blev markeret af formaliseringen af regler og fremkomsten af indflydelsesrige personer som James E. Sullivan, medstifter af Amateur Athletic Union i USA. Han spillede en betydelig rolle i at popularisere atletik og organisere store begivenheder. Indførelsen af de moderne olympiske lege i 1896 gav en international platform til sporten, hvilket førte til standardisering af regler og begivenheder. Desuden bidrog teknologiske fremskridt såsom forbedringer i fodtøj og løbebaner til udviklingen af atletik i denne periode.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Vækst og Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      I det 20. århundrede blev atletik stadig mere populært og vigtigt på universiteterne. Antallet af atletikprogrammer, konkurrencer og stipendier steg. Atleternes niveau steg også, takket være blandt andet bedre træning, faciliteter og coaching.
      <br/>
      <br/>
      En af faktorerne, der bidrog til væksten og professionaliseringen af atletik på collegeniveau, var grundlæggelsen af ​​National Collegiate Athletic Association (NCAA) i 1906. NCAA er en organisation, der overvåger sportsaktiviteterne på mere end 1000 universiteter i USA. NCAA regulerer blandt andet reglerne, kvalifikationerne, stipendierne og sanktionerne for atletik på collegeniveau.
      <br/>
      <br/>
      En anden faktor, der påvirkede udviklingen af atletik på collegeniveau, var forholdet til professionel atletik. Mange atleter, der havde succes på collegeniveau, skiftede senere til den professionelle scene. Nogle eksempler er Jesse Owens, Carl Lewis, Jackie Joyner-Kersee og Michael Johnson.
    </MKTypography>
  </MKBox>
</MKBox>





                                              





                                  







{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Atletik på Collegeniveau i Dag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Atletik på collegeniveau er i dag en af de mest populære og prestigefyldte sportsgrene i USA. Hvert år deltager tusindvis af atleter i forskellige konkurrencer og begivenheder, hvilket tiltrækker millioner af fans og medieopmærksomhed. Atletik på collegeniveau tilbyder også mange muligheder for atleter at udvikle sig, præstere og nyde deres sport.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Store Konkurrencer og Konferencer
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Sydney McLaughlin, som udmærker sig som atlet for University of Kentucky, i konkurrenceuniform."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Sydney McLaughlin studerede på University of Kentucky
        </figcaption>
      </figure>
      Den vigtigste organisation, der regulerer atletik på collegeniveau, er NCAA. NCAA opdeler universiteter i tre divisioner, baseret på deres størrelse, budget og konkurrenceniveau. Division I er den højeste division, hvor de fleste topuniversiteter og atleter er. Divisioner II og III er lavere divisioner, hvor universiteter har færre stipendier og ressourcer, men lægger mere vægt på akademisk præstation.
      <br/>
      <br/>
      Inden for hver division er der forskellige konferencer, som er grupper af universiteter, der er geografisk eller historisk forbundet. Hver konference organiserer sine egne mesterskaber og konkurrencer for sine medlemmer. Nogle af de mest kendte konferencer er Big Ten, Pac-12, SEC, ACC og Ivy League.
      <br/>
      <br/>
      De vigtigste begivenheder i collegens atletikseson er NCAA-mesterskaberne, der afholdes årligt i marts (indendørs) og juni (udendørs). Disse mesterskaber afgør, hvilke universiteter og atleter der er de bedste i hver division og begivenhed. Mesterskaberne tiltrækker mange tilskuere, sponsorer og medieopmærksomhed.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Topprogrammer og Prestigefyldte Hold
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Der er mange universiteter kendt for deres fremragende atletikprogrammer. Disse universiteter har en lang tradition for succes, højkvalitets træning og faciliteter samt en stærk appel til talentfulde atleter. Nogle af disse universiteter er:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Oregon: Oregon er et af de mest succesrige universiteter inden for atletik, især i løb. Oregon har vundet mere end 30 NCAA-titler og har produceret mange berømte atleter som Steve Prefontaine, Alberto Salazar, Galen Rupp og Matthew Centrowitz.
        </li>
        <li>
          USC: USC er et andet topuniversitet inden for atletik, med fokus på sprint, spring og kast. USC har vundet mere end 25 NCAA-titler og har produceret mange olympiske medaljevindere som Quincy Watts, Andre De Grasse og Michael Norman.
        </li>
        <li>
          Arkansas: Arkansas er et dominerende universitet inden for atletik, især i indendørsæsonen. Arkansas har vundet mere end 40 NCAA-titler, hvoraf mere end 30 er indendørs. Arkansas er kendt for sin dybde og alsidighed i forskellige begivenheder.
        </li>
        <li>
          Texas: Texas er et af de mest alsidige universiteter inden for atletik, med en god balance mellem herre- og damehold samt individuelle og stafetbegivenheder. Texas har vundet mere end 20 NCAA-titler og har produceret mange verdensklasse-atleter som Sanya Richards-Ross, Trey Hardee, Michelle Carter og Courtney Okolo.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Disse universiteter tilbyder ikke kun et højt konkurrenceniveau og træning for deres atleter, men også en god atmosfære og kultur. Atleterne er en del af et tæt sammentømret fællesskab, der støtter og motiverer dem til at nå deres potentiale.
    </MKTypography>
  </MKBox>
</MKBox>












                                              






                                  








{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Fra College til Professionel
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Atletik på collegeniveau er ikke kun en sport, men også et træningsområde for atleter, der aspirerer til en professionel karriere. Mange atleter, der har præsteret på collegeniveau, er senere skiftet til den professionelle cirkel. Atletik på collegeniveau tilbyder atleter muligheden for at udvikle sig, opleve og netværke med andre atleter, trænere og organisationer.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Vejen til Professionalisme
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      For mange atleter er målet at blive professionel atlet efter deres studier. Dette betyder at dyrke deres sport som profession og tjene penge gennem præstationer, kontrakter og sponsorater. For at blive en professionel atlet skal atleter opfylde flere betingelser, såsom:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Have et højt præstationsniveau og potentiale. Professionelle atleter skal kunne konkurrere med verdens bedste atleter og kontinuerligt forbedre sig og tilpasse sig skiftende forhold.
        </li>
        <li>
          Vedligeholde god sundhed og fitness. Professionelle atleter skal passe godt på deres kroppe og beskytte sig mod skader, sygdomme og overtræning. De skal også finde en god balance mellem træning, hvile og ernæring.
        </li>
        <li>
          Have god vejledning og støtte. Professionelle atleter bør kunne stole på et team af eksperter til at hjælpe dem med teknik, taktik, mental forberedelse, juridiske anliggender, økonomiske spørgsmål og markedsføring.
        </li>
        <li>
          Have god planlægning og strategi. Professionelle atleter skal vide, hvilke mål de vil opnå, hvilke konkurrencer de vil deltage i, hvordan de vil kvalificere sig til større begivenheder, og hvordan de vil styre deres karriere.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Atletik på collegeniveau kan hjælpe atleter med at opfylde disse betingelser. Det giver atleter mulighed for at vise deres talent, forbedre deres færdigheder, øge deres erfaring og udvide deres netværk. Atletik på collegeniveau kan også fungere som en afsætning til det professionelle niveau ved at udsætte atleter for høje konkurrenceniveauer, medieopmærksomhed og offentlig interesse.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Forholdet til Professionel Atletik
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Atletik på collegeniveau har et tæt forhold til professionel atletik. De to niveauer påvirker hinanden på forskellige måder, såsom:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Talentudvikling: Atletik på collegeniveau er en stor kilde til talent for professionel atletik. Mange professionelle atleter startede som collegeatleter og lagde grundlaget for deres senere succes der.
        </li>
        <li>
          Talentopdagelse: Professionelle atletikorganisationer overvåger tæt collegiatletik for at opdage og tiltrække potentielle stjerner. De tilbyder collegeatleter kontrakter, sponsorater og vejledning for at overtale dem til at blive professionelle.
        </li>
        <li>
          Talentudveksling: Atletik på collegeniveau er også åben for professionelle atleter, der stadig studerer eller vender tilbage til skolen. Nogle professionelle atleter vælger at fuldføre eller genoptage deres studier, mens de fortsætter deres sportskarriere.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Atletik på collegeniveau er derfor ikke kun et endemål, men også en springbræt for atleter, der forfølger en professionel karriere. Det giver atleter mulighed for at forberede sig til det professionelle niveau, men også for at udforske andre veje.
    </MKTypography>
  </MKBox>
</MKBox>










                                              






                                  








<MKBox id="Chapter 4" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Kapitel 4: Internationale Påvirkninger og Muligheder
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Atletik på collegeniveau er ikke kun en amerikansk sport, men også en international en. Den er påvirket af og tilbyder muligheder for atleter fra forskellige lande og kulturer. Atletik på collegeniveau er en sport, der afspejler verden's mangfoldighed og sammenhæng.
    </MKTypography>

    <MKBox id="4.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.1 Udenlandske Atleter og Konkurrencer
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image3}
                    alt="Den berømte Oregon-atletikstadion, hjemsted for utallige atletikbegivenheder på collegeniveau."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal3(Image3)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Oregon-atletikstadionet, et ikon inden for collegesport.
                </figcaption>
            </figure>
            En af måderne, hvorpå atletik på collegeniveau er international, er gennem tilstedeværelsen og præstationerne af udenlandske atleter. Hvert år kommer hundredvis af atleter fra andre lande til USA for at studere og konkurrere på collegeniveau. Disse atleter bringer deres eget talent, stil og kultur til amerikanske universiteter og beriger atletiksamfundet med deres perspektiver og erfaringer.
            <br/>
            <br/>
            Udenlandske atleter har også en betydelig indflydelse på resultaterne og rangeringerne af atletik på collegeniveau. Mange udenlandske atleter er blandt de bedste atleter inden for deres begivenhed og vinder ofte medaljer, titler og rekorder. Nogle udenlandske atleter er endda så gode, at de overgår eller udfordrer amerikanske atleter.
            <br/>
            <br/>
            Eksempler på succesfulde udenlandske atleter i amerikanske collegestævner inkluderer:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Andre De Grasse: En canadisk sprinter, der udmærkede sig på Coffeyville Community College og University of Southern California (USC), hvor han vandt flere NJCAA- og NCAA-titler i sprintbegivenheder. Hans præstationer på USC etablerede hans ry som en førende sprinter. Efter sin collegeskarriere vandt De Grasse flere olympiske medaljer, herunder guld på 200 meter ved OL i Tokyo 2020, og viste sig som en af verdens bedste sprintere.
                </li>

                <li>
                    Kerron Clement: En trinidadisk-amerikansk atlet, der udmærker sig i 400 meter hækkeløb. Han studerede på University of Florida, hvor han vandt NCAA-titler og udviklede sig til en verdensklasse-hækkeløber. Clement erobrede guld ved OL i 2008 og 2016 og har flere verdensmesterskaber på sit navn, hvilket gør ham til en af de mest succesrige hækkeløbere i sin generation.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Udenlandske atleter påvirker ikke kun atletik på collegeniveau, men også andre internationale konkurrencer og begivenheder. Mange udenlandske atleter, der konkurrerede på collegeniveau, deltager senere i de olympiske lege, verdensmesterskaberne eller andre regionale eller kontinentale mesterskaber. De repræsenterer deres eget land, men har også bånd til deres amerikanske universitet.
        </MKTypography>
    </MKBox>
    <MKBox id="4.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.2 Amerikanske Atleter i Udlandet
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            En anden måde, hvorpå atletik på collegeniveau er international, er gennem de muligheder og udfordringer, det tilbyder for amerikanske atleter at konkurrere i udlandet. Mange amerikanske atleter, der har konkurreret på collegeniveau, får mulighed for at udvide deres horisonter og teste deres færdigheder mod andre atleter fra forskellige lande og kulturer.
            <br/>
            <br/>
            En af de vigtigste muligheder for amerikanske atleter for at konkurrere i udlandet er naturligvis de olympiske lege. De olympiske lege er verdens største og mest prestigefyldte sportsbegivenhed, hvor tusinder af atleter fra over 200 lande mødes for at konkurrere om guld, sølv og bronze. De olympiske lege er også en mulighed for amerikanske atleter for at repræsentere deres land, vise deres patriotisme og dele deres nationale stolthed.
            <br/>
            <br/>
            En anden vigtig begivenhed for amerikanske atleter for at konkurrere i udlandet er Verdensmesterskaberne i Atletik. Verdensmesterskaberne i Atletik er den mest betydningsfulde atletikbegivenhed efter OL, hvor verdens bedste atleter mødes for at konkurrere om verdensmesterskabet. Verdensmesterskaberne i Atletik er også en chance for amerikanske atleter for at måle deres niveau, øge deres rivalisering og etablere deres ry.
            <br/>
            <br/>
            Ud over disse store begivenheder er der også andre muligheder for amerikanske atleter for at konkurrere i udlandet, såsom Diamond League, Continental Tour, Verdensmesterskaberne i Atletik og andre regionale eller kontinentale mesterskaber. Disse begivenheder giver amerikanske atleter mulighed for at øge deres erfaring, indtjening og fanbase.
            <br/>
            <br/>
            Atletik på collegeniveau spiller en vigtig rolle i udviklingen og succesen for amerikanske atleter, der konkurrerer i udlandet. Det forbereder atleterne til det internationale niveau ved at give dem høj kvalitet træning, coaching og konkurrence. Atletik på collegeniveau støtter også atleter, der konkurrerer i udlandet, ved at tilbyde dem et netværk af kontakter, sponsorer og fans.
        </MKTypography>
    </MKBox>
</MKBox>










                                              






                                  



<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Sportslegater og fremtiden for atleter
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sportslegater tilbyder talrige muligheder for unge atleter til at vise deres talent og udvikle deres færdigheder. En af de mest betydningsfulde muligheder er at opnå et sportslegat for at studere og konkurrere på kollegialt niveau. I dette kapitel vil vi diskutere vigtigheden af sportslegater og hvordan Sport Legater Amerika kan hjælpe dig med at realisere din drøm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vigtigheden af legater
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et sportslegat er økonomisk støtte, som et universitet tilbyder til en studerende-atlet for at kombinere studier og sport. Det kan dække omkostningerne til undervisning, bøger, bolig, måltider og andre udgifter. Derudover kan det også tilbyde fordele såsom akademisk vejledning, lægehjælp, træningsfaciliteter og udstyr.
      <br />
      <br />
      At opnå et sportslegat er konkurrencepræget, hvor mange faktorer spiller en rolle i tildelingsprocessen, herunder akademiske præstationer, atletisk præstation, personlighed, motivation, lederskab og tilpasningsevne. Derfor er det vigtigt at forberede sig godt og adskille sig fra konkurrencen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Legater Amerika Kan Hjælpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo af Sport Legater Amerika, der viser sportsudstyr og en eksamenshue, der repræsenterer fokus på sportslegater for studerende-atleter."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo af Sport Legater Amerika
        </figcaption>
      </figure>
      Sport Legater Amerika er en organisation specialiseret i at guide unge sports talenter til sportslegater. Med passionerede og erfarne eksperter støtter Sport Legater Amerika studerende-atleter på hvert trin af rejsen, fra indledende orientering til endelig placering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intake-interview for at diskutere den studerende-athlets ønsker, muligheder og forventninger.</li>
        <li>En professionel evaluering af den studerende-athlets atletiske og akademiske niveau.</li>
        <li>Et omfattende netværk af kontakter med trænere, universiteter og kollegier.</li>
        <li>Effektiv markedsføring af den studerende-athlets profil og videoer til potentielle trænere.</li>
        <li>Ekspertrådgivning om de bedste muligheder baseret på den studerende-athlets præferencer, mål og budget.</li>
        <li>Forhandling af de bedste legat tilbud på vegne af den studerende-atlet.</li>
        <li>Hjælp til ansøgning om nødvendige dokumenter, såsom visum, transkript, eksamensbevis og optagelsesprøver.</li>
        <li>Forberedelse til den studerende-atlets liv under deres legat, herunder kultur, sprog, regler og tips.</li>
        <li>Efterpleje og opfølgning under legatperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ønsker du at udforske legatmuligheder og opnå dine sportslige og akademiske mål? <Link to="/da/Tjenester-Produkter">Se det fulde udvalg</Link> af tjenester tilbudt af Sport Legater Amerika og planlæg en konsultation i dag.
    </MKTypography>
  </MKBox>
</MKBox>



          
          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;