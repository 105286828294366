/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import { Helmet } from 'react-helmet';

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import { Grid } from '@mui/material';
import MKTypography from 'components/MKTypography';

import Container from "@mui/material/Container";


// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbarDansk";

// Author page sections
import Profile from "Languages/Dansk/pages/Aanbod/Traject/sections/Profile";
import TL from "Languages/Dansk/pages/Aanbod/Traject/sections/TL"

// Routes
import routes from "routes_Dansk";import Breadcrumbs from "examples/Breadcrumbs";
import footerRoutes from "footer.routes_Dansk";
import DetailedFooter from "examples/Footers/DetailedFooter";
import "../../H1asH2.css"
import "../../H2-as-Body1.css"
// Images
import bgImage from "assets/images/Achtergronden/Traject_BG.png";

function NaUSA() {
  return (
    <>
<Helmet>
    <title>Rejse Mod Din Amerikanske Drøm - Trin for Trin Guide</title>
    <meta name="description" content="Følg det trin-for-trin rejse for at opnå en succesfuld sportsstipendium i USA. Opdag de vigtigste trin for at realisere din amerikanske drøm." />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta charset="UTF-8" />
    <meta name="robots" content="index, follow" />
    
    {/* Open Graph Tags */}
    
    
    
    
    
 
    {/* Favicon */}
    <link rel="icon" href="https://sportscholarshipsamerica.com/32x32Sportbeurs.png" />
</Helmet>


      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://calendly.com/sportbeurs-amerika/intake",
          label: "BOOK AFTALE",
          color: "info",
        }}
        transparent
        light
         
      />
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.5),
                rgba(gradients.dark.state, 0.5)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center 50%",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
                           <MKBox
  width={{ xs: "80%", md: "50%", lg: "25%" }}
  mb={1}
  sx={{
    backgroundColor: "transparent", // Transparent background color
  }}
>
  <Breadcrumbs
    routes={[
      { label: "Home", route: "/da" },
      { label: "Roadmap" },
    ]}
  />
</MKBox>  

 <Container>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid
                container
                item
                xs={12}
                lg={6}
                flexDirection="column"
                alignItems="center"
                sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
              >
         <MKTypography variant="h1" className="h1-as-h2" fontWeight="bold">
                Processen ved et Øjekast
                </MKTypography>
                <MKTypography variant="h2" className="h2-as-body1" color="text">
                En Kronologisk Oversigt over de Vigtige Skridt på Vejen til din Amerikanske Drøm
                </MKTypography>
              </Grid>


            </Grid>
          </Container>
          <TL />
          <Profile />
        </Card>
      
        <MKBox pt={6} px={1} mt={6}>
        <DetailedFooter content={footerRoutes} />
      </MKBox>    </>
  );
}

export default NaUSA;
