/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================
* Produktseite: https://www.creative-tim.com/product/material-kit-pro-react
* Urheberrecht 2023 Creative Tim (https://www.creative-tim.com)
Kodiert von www.creative-tim.com
=========================================================
*/

// @mui Materialkomponenten
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React-Komponenten
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Bilder
import post2 from "assets/images/TijdUS.png";
import "../../../../H1asH3.css"

function Profil() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center" />
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} justifyContent="space-between" alignItems="center" mb={1}>
                <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Teil 1: Aufwachsen in den Niederlanden</MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text">
  <br />
  In der bezaubernden Umgebung von Nijmegen begann meine lebenslange Liebesaffäre mit dem Fußball. Als Kind aus den Straßen von Nijmegen spürte ich bei jedem Schritt auf dem grünen Rasen die Magie des Sports. Mein erster Verein war BVC '12, wo ich als junger Kerl meine ersten Dribblings und Tore erlebte. Es war eine Zeit der Unschuld, in der ich meine Leidenschaft für den Sport entdeckte und die Grundlagen für meine Zukunft gelegt wurden.
  <br /><br />

  Nach meinem Abenteuer bei BVC '12 begab ich mich auf eine Reise durch lokale Fußballvereine wie Quick, NEC und Union. Diese Reise bildete die Grundlage für meine Fußballausbildung und brachte mich näher an meinen Traum: die Spitze des niederländischen Jugendfußballs zu erreichen. Als junger Fußballer träumte ich davon, in großen Stadien von einer jubelnden Menschenmenge umgeben zu spielen. Es war ein Traum, den viele junge Talente in den Niederlanden teilten, und ich war entschlossen, alles zu tun, um diesen Traum wahr werden zu lassen.
  <br /><br />

  Aber während ich mein Fußballtalent entwickelte, begann ich auch zu erkennen, wie wichtig eine gute Ausbildung für eine solide Zukunft ist. In den Niederlanden schienen meine Ambitionen im Fußball und in der Bildung manchmal widersprüchlich zu sein. Es war eine Herausforderung, beide Leidenschaften in Einklang zu bringen.
  <br /><br />

  Die sprühende Vielfalt und die riesigen Möglichkeiten Amerikas hatten mich schon immer fasziniert, und diese Faszination wuchs nur während eines Urlaubs mit meiner Familie. Ich war fast sicher, dass ich in dieses Land zurückkehren würde, vielleicht für eine längere Zeit. Später hörte ich über Bekannte Geschichten über die Möglichkeiten, eine Fußballkarriere mit Universitätsstudien in Amerika zu verbinden, und mein Wunsch, denselben Weg zu gehen, wuchs exponentiell.
  <br /><br />

  Doch es war nicht einfach, alles zu organisieren. Der komplexe Prozess von Sportstipendien und Studienmöglichkeiten in Amerika schien anfangs einschüchternd. Irgendwo wusste ich, dass es einfacher und zugänglicher sein könnte, was letztendlich meine Motivation war, dieses Unternehmen Jahre später zu gründen.
  <br /><br />

  Nachdem ich verschiedene Trainer kontaktiert hatte, hatte der Trainer der University of Tulsa eine besonders überzeugende Geschichte zu erzählen. Er sprach von einem Team, das immer um Preise kämpfte, konstant unter den Top 25 im Land rangierte und an großartigen Wettbewerben teilnahm. Seine Worte gaben mir das Vertrauen, dass dies der Ort war, an dem ich sowohl meine Fußballträume als auch akademische Ambitionen erfüllen konnte.
  <br /><br />

  Und so geschah es, zwei Wochen nach meinem 18. Geburtstag war mein Kapitel in den Niederlanden (vorübergehend) vorbei. Ich bestieg das Flugzeug nach Amerika, bereit für das Abenteuer, das vor mir lag. Entschlossen, meine Leidenschaften für Fußball und Bildung zu vereinen, stand ich am Anfang einer aufregenden Reise. Mehr über meine Erfahrungen in Amerika werde ich gerne in Teil 2 meiner Geschichte mit Ihnen teilen, in dem ich tiefer in meine Abenteuer in den Vereinigten Staaten eintauche.
</MKTypography>
<hr style={{ margin: '20px 0'}} />

<MKTypography component="a" href="#" variant="body1" fontWeight="light" color="info" mt={3}>
  <Grid container justifyContent="flex-end"> {/* Diese Zeile wurde aktualisiert */}
    <Grid item xs={12} lg={6} mb={{ xs: 3, lg: 0 }}>
      <TransparentBlogCard
        image={post2}
        title="Teil 2: Meine Zeit in Amerika"
        action={{
          type: "internal",
          route: "/de/Über-Mich/Meine-Zeit-in-Amerika",
          color: "info",
          label: "mehr lesen",
        }}
      />
    </Grid>
  </Grid>
</MKTypography>

</Grid>
</Grid>
</Grid>
</Container>
</MKBox>
);
}

export default Profil;
