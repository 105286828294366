/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Golf1.jpeg";
import Image2 from "assets/images/Sports_Images/Golf2.webp";
import Image3 from "assets/images/Sports_Images/Golf3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"


// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Golfstipendium: Wie man Golf in Amerika studiert und spielt', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapitel 1: Geschichte und Entwicklung des College-Golfs', isSubheader: false },
    { id: '1.1', title: '1.1 Frühe Jahre', isSubheader: true },
    { id: '1.2', title: '1.2 Wachstum und Professionalisierung', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapitel 2: Die aktuelle Landschaft des College-Golfs', isSubheader: false },
    { id: '2.1', title: '2.1 Wichtige Wettbewerbe und Konferenzen', isSubheader: true },
    { id: '2.2', title: '2.2 Top-Programme und angesehene Teams', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapitel 3: Vom College zum Profi', isSubheader: false },
    { id: '3.1', title: '3.1 Der Weg zum Profi-Zirkus', isSubheader: true },
    { id: '3.2', title: '3.2 Alternative Wege zum Profisport', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapitel 4: Internationale Einflüsse und Möglichkeiten', isSubheader: false },
    { id: '4.1', title: '4.1 Ausländische Spieler im College-Golf', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanische Spieler im Ausland', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapitel 5: Sportstipendien und die Zukunft der Athleten', isSubheader: false },
    { id: '5.1', title: '5.1 Die Bedeutung von Stipendien', isSubheader: true },
    { id: '5.2', title: '5.2 Wie Sportstipendien Amerika helfen können', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };





  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhaltsverzeichnis
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verstecken</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Anzeigen</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Golfstipendium: Wie man Golf in Amerika studiert und spielt
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Golfplatz während der NCAA-Meisterschaft, mit Spielern und Zuschauern auf dem Kurs"
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Die NCAA Golfmeisterschaft
      </figcaption>
    </figure>
    Golf ist eine der beliebtesten Sportarten auf Hochschulebene in den Vereinigten Staaten. Jedes Jahr kämpfen Tausende von Studentenathleten um nationale Titel, prestigeträchtige Auszeichnungen und einen Platz im professionellen Golf. College-Golf bietet nicht nur die Möglichkeit, Ihre sportlichen Fähigkeiten zu verbessern, sondern auch einen akademischen Abschluss zu erlangen, ein Netzwerk aufzubauen und eine unvergessliche Erfahrung zu machen.
    <br/>
    <br/>
    College-Golf ist jedoch nicht für jeden geeignet. Es erfordert ein hohes Maß an Engagement, Disziplin und Talent. Darüber hinaus ist es nicht einfach, an einer Universität mit einem guten Golfprogramm zugelassen zu werden. Der Wettbewerb ist hart, und die Kosten sind hoch.
    <br/>
    <br/>
    Glücklicherweise gibt es Möglichkeiten, Ihren Traum mit Hilfe eines Golfstipendiums wahr werden zu lassen. Ein Golfstipendium ist finanzielle Unterstützung, die Sie von einer Universität erhalten, um dort zu studieren und Golf zu spielen. Mit einem Golfstipendium können Sie Ihre Studienkosten reduzieren oder sogar vollständig decken, während Sie alle Vorteile des College-Golfs genießen.
    <br/>
    <br/>
    Aber wie bekommt man ein Golfstipendium? Und worauf sollten Sie achten, wenn Sie eine Universität wählen? Dabei kann Ihnen Sportbeursamerika.nl helfen. Sportbeursamerika.nl ist der Spezialist für die Begleitung von Studenten zu Sportstipendien in Amerika. Ich habe Erfahrung und Wissen über das amerikanische Bildungs- und Sportsystem und habe vielen Studentenathleten geholfen, ihren Traum zu verwirklichen.
    <br/>
    <br/>
    In diesem Artikel geben wir mehr Informationen über College-Golf und zeigen, wie Sportbeursamerika.nl Sie dabei unterstützen kann, das perfekte Golfstipendium für Sie zu finden.
    <br/>
    <br/>
    Möchten Sie mehr erfahren? Lesen Sie weiter!
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Hoofdstuk 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Geschichte und Entwicklung des College-Golfs
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Das College-Golf hat eine lange und reiche Geschichte, die bis ins frühe 20. Jahrhundert zurückreicht. In diesem Kapitel werden wir uns die Anfänge sowie das Wachstum und die Professionalisierung des College-Golfs in den Vereinigten Staaten genauer ansehen.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Frühe Jahre
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Die ersten Golfwettbewerbe an Universitäten und Colleges in den USA fanden in den 1890er Jahren statt. Das erste offizielle interkollegiale Golfspiel fand 1897 zwischen Harvard und Yale statt. Im Jahr 1901 wurde die Intercollegiate Golf Association (IGA) gegründet, die die erste nationale Meisterschaft im Jahr 1902 organisierte. Yale gewann den ersten Titel und dominierte bis in die 1920er Jahre.
      <br/>
      <br/>
      Die ersten Golfprogramme an Universitäten konzentrierten sich hauptsächlich auf die sozialen und freizeitlichen Aspekte des Sports. Die Studenten spielten hauptsächlich auf lokalen Plätzen und hatten wenig Anleitung oder Einrichtungen. Die Golfteams bestanden in der Regel aus vier oder fünf Spielern, die sich über interne Turniere qualifizierten.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Wachstum und Professionalisierung
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      In den 1930er Jahren begann das College-Golf signifikant zu wachsen und sich zu professionalisieren. In dieser Zeit stieg die Anzahl der Universitäts-Golfprogramme und die Qualität des Trainings und der Einrichtungen verbesserte sich. Die Professionalisierung wurde weiter durch die zunehmende Aufmerksamkeit für den Sport und die wachsende Anerkennung des Potenzials des Golfspiels auf College-Ebene angeregt. In dieser Zeit übernahm die National Collegiate Athletic Association (NCAA) eine führende Rolle bei der Organisation und Regulierung des College-Golfs und trug so zur weiteren Entwicklung und Popularität des Sports in den Vereinigten Staaten bei.
      <br/>
      <br/>
      Die NCAA übernahm die Organisation der nationalen Meisterschaft von der IGA und führte neue Regeln und Formate ein. Die NCAA unterteilte die Universitäten in verschiedene Divisionen, basierend auf ihrer Größe und sportlichen Leistungsfähigkeit. Die NCAA organisierte auch regionale Meisterschaften, um die Qualifikation für die nationale Meisterschaft zu erleichtern.
      <br/>
      <br/>
      Universitäten begannen auch, mehr in ihre Golfprogramme zu investieren, indem sie professionelle Trainer einstellten, Golfplätze bauten oder verbesserten und Stipendien an talentierte Spieler vergaben. Die Golfteams wurden größer und stärker, und der Wettbewerb wurde intensiver.
      <br/>
      <br/>
      In den 1940er und 1950er Jahren wurden auch wichtige Turniere hinzugefügt, wie der Walker Cup, der Palmer Cup und der Eisenhower Trophy. Diese Turniere gaben College-Golfern die Möglichkeit, mit internationalen Spielern zu konkurrieren und ihre Fähigkeiten zu verbessern.
      <br/>
      <br/>
      In diesem Kapitel haben wir gesehen, wie das College-Golf in den Vereinigten Staaten entstand und wuchs. Im nächsten Kapitel werden wir uns die aktuelle Landschaft des College-Golfs ansehen und welche Wettbewerbe und Programme verfügbar sind.
    </MKTypography>
  </MKBox>
</MKBox>















                                              





                                  








<MKBox id="Kapitel 2" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Kapitel 2: Die aktuelle Landschaft des College-Golfs
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Heutzutage ist das College-Golf eine der wettbewerbsfähigsten und angesehensten Sportarten auf Universitätsebene in den Vereinigten Staaten. In diesem Kapitel werden wir uns die wichtigsten Wettbewerbe und Konferenzen sowie die Top-Programme und angesehenen Teams im College-Golf ansehen.
    </MKTypography>

    <MKBox id="2.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.1 Wichtige Wettbewerbe und Konferenzen
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image2}
                    alt="Tiger Woods repräsentiert Stanford im College-Golf, in einer konzentrierten Spielsituation."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal2(Image2)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Tiger Woods im Einsatz für Stanford.
                </figcaption>
            </figure>
            Die NCAA ist die größte und wichtigste Organisation für College-Golf in den USA. Die NCAA teilt Universitäten in drei Divisionen ein: Division I, Division II und Division III. Jede Division hat ihre eigene Meisterschaft, die jährlich im Mai oder Juni stattfindet. Die Meisterschaften bestehen aus einem Team- und einem Einzelturnier, bei dem die besten Spieler und Teams aus jeder Division um den nationalen Titel kämpfen.
            <br/>
            <br/>
            Die NCAA hat auch mehrere regionale Konferenzen, bestehend aus Universitäten, die geografisch oder historisch verbunden sind. Jede Konferenz hat ihre eigene Meisterschaft, die als Qualifikationsturnier für die nationale Meisterschaft dient. Einige Konferenzen sind stärker als andere und haben mehr Einfluss auf die College-Golf-Landschaft.
            <br/>
            <br/>
            Einige der bekanntesten Konferenzen sind:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Die Atlantic Coast Conference (ACC), bestehend aus 15 Universitäten an der Ostküste wie Duke, North Carolina und Clemson.
                </li>
                <li>
                    Die Big Ten Conference, bestehend aus 14 Universitäten im Mittleren Westen wie Michigan, Ohio State und Illinois.
                </li>
                <li>
                    Die Pac-12 Conference, bestehend aus 12 Universitäten an der Westküste wie Stanford, California und Arizona State.
                </li>
                <li>
                    Die Southeastern Conference (SEC), bestehend aus 14 Universitäten im Südosten wie Alabama, Georgia und Florida.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Neben der NCAA gibt es auch andere Organisationen, die College-Golfwettbewerbe organisieren, wie die National Association of Intercollegiate Athletics (NAIA), die National Junior College Athletic Association (NJCAA) und die National Christian College Athletic Association (NCCAA). Diese Organisationen haben ihre eigenen Divisionen, Konferenzen und Meisterschaften, die im Allgemeinen kleiner und weniger angesehen sind als die der NCAA.
        </MKTypography>
    </MKBox>
    <MKBox id="2.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.2 Top-Programme und angesehene Teams
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            College-Golf ist nicht nur ein Sport, sondern auch eine Ausbildung. Die Universitäten, die College-Golf anbieten, haben verschiedene Programme, die sich in Qualität, Ruf und Einrichtungen unterscheiden. Einige Programme sind führend und angesehen und ziehen die besten Spieler und Trainer an. Andere Programme sind weniger bekannt oder erfolgreich, bieten aber dennoch gute Möglichkeiten für Studenten-Athleten.
            <br/>
            <br/>
            Einige der Faktoren, die ein Programm bestimmen, sind:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Die Geschichte und Tradition des Programms.
                </li>
                <li>
                    Das Niveau und die Ergebnisse des Teams.
                </li>
                <li>
                    Die Qualität und Erfahrung des Trainers.
                </li>
                <li>
                    Die Einrichtungen und Ausstattung des Programms.
                </li>
                <li>
                    Die akademischen Standards und Anforderungen des Programms.
                </li>
                <li>
                    Die Atmosphäre und Kultur des Programms.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Einige der bekanntesten College-Golfprogramme sind:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Stanford University: Stanford hat eines der ältesten und erfolgreichsten Golfprogramme in den USA mit mehreren nationalen Titeln im Herrengolf. Das Programm hat berühmte Alumni wie Tiger Woods und Tom Watson hervorgebracht. Auch das Frauengolfteam von Stanford hat mit mehreren NCAA-Titeln und Einzelmeistern nationale Anerkennung erlangt.
                </li>
                <li>
                    Oklahoma State University: Oklahoma State hat ein dominantes Golfprogramm, besonders bekannt für sein Herrenteam mit mehreren nationalen Titeln. Das Programm hat Top-Golfer wie Rickie Fowler und Viktor Hovland hervorgebracht.
                </li>
                <li>
                    Duke University: Duke ist bekannt für sein starkes Frauengolfprogramm mit mehreren NCAA-Titeln. Das Herrengolfteam von Duke hat in Konferenzmeisterschaften stark abgeschnitten und hat eine respektable Geschichte in NCAA-Wettbewerben. Duke hat talentierte Golfer wie Amanda Blumenherst und Brittany Lang hervorgebracht.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            In diesem Kapitel haben wir gesehen, wie die aktuelle Landschaft des College-Golfs aussieht und welche Wettbewerbe und Programme verfügbar sind. Im nächsten Kapitel werden wir uns damit befassen, wie man vom College zum Profi wird, und welche alternativen Wege es gibt.
        </MKTypography>
    </MKBox>
</MKBox>














                                              






                                  








<MKBox id="Kapitel 3" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Kapitel 3: Vom College zum Profi
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        College-Golf ist nicht nur ein Sport, sondern auch ein Karriereweg. Viele College-Golfer streben danach, auf den Profi-Zirkus umzusteigen und auf höchstem Niveau Golf zu spielen. In diesem Kapitel werden wir untersuchen, wie College-Golf als Sprungbrett zum Profi-Golf dienen kann, und welche alternativen Wege es gibt.
    </MKTypography>

    <MKBox id="3.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.1 Der Weg zum Profi-Zirkus
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            College-Golf ist eine ausgezeichnete Vorbereitung auf eine professionelle Karriere im Golf. College-Golfer haben die Möglichkeit, sich sportlich, akademisch und persönlich weiterzuentwickeln. Sie lernen, mit Druck, Wettbewerb und Erwartungen umzugehen. Sie spielen auf anspruchsvollen Plätzen gegen starke Gegner und unter verschiedenen Bedingungen. Sie haben auch Zugang zu hochwertigen Einrichtungen, Trainern und Unterstützung.
            <br/>
            <br/>
            College-Golfer haben auch einen Vorteil gegenüber anderen Spielern, die nicht an einer Universität teilnehmen. Sie haben einen Abschluss, der ihnen helfen kann, einen Job oder einen Sponsor zu finden, wenn sie es nicht als Profis schaffen. Sie haben auch ein Netzwerk von Kontakten und Freunden, die sie in ihrer Karriere unterstützen oder beraten können.
            <br/>
            <br/>
            Viele ehemalige College-Golfer steigen nach Abschluss ihres Studiums in den Profi-Zirkus ein. Einige tun dies früher, wenn sie glauben, bereit zu sein. Um Profi zu werden, müssen sie sich für eine Tour qualifizieren, wie die PGA Tour, die LPGA Tour oder die European Tour, durch Qualifikationsturniere wie die Q-School oder die Korn Ferry Tour.
            <br/>
            <br/>
            Viele ehemalige College-Golfer haben Erfolg auf dem Profi-Zirkus gehabt. Beispiele sind:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Phil Mickelson: Mickelson spielte College-Golf für die Arizona State University, wo er dreimal die NCAA-Meisterschaft gewann. Er wurde 1992 Profi und hat seitdem zahlreiche Siege auf der PGA Tour erzielt, darunter mehrere Majors.
                </li>
                <li>
                    Annika Sörenstam: Sörenstam spielte College-Golf für die University of Arizona, wo sie 1991 die NCAA-Meisterschaft gewann. Sie wurde 1992 Profi und hat seitdem zahlreiche professionelle Siege erzielt, darunter mehrere Majors.
                </li>
                <li>
                    Jon Rahm: Rahm spielte College-Golf für die Arizona State University, wo er zweimal den Ben Hogan Award als bester College-Golfer gewann. Er wurde 2016 Profi und hat seitdem mehrere professionelle Siege erzielt, darunter Majors.
                </li>
            </ul>
        </MKTypography>
    </MKBox>
    <MKBox id="3.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.2 Alternative Wege zum Profisport
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Während College-Golf ein üblicher Weg zum Profi-Zirkus ist, ist es nicht der einzige. Es gibt andere Wege, die Spieler einschlagen können, um ihren Traum zu verwirklichen. Einige Spieler entscheiden sich dafür, nicht an die Universität zu gehen, sondern direkt nach der High School Profis zu werden. Andere besuchen die Universität, verlassen sie aber frühzeitig, um sich vollständig auf ihren Sport zu konzentrieren.
            <br/>
            <br/>
            Beispiele für Spieler, die einen alternativen Weg eingeschlagen haben, sind:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Tiger Woods: Woods spielte College-Golf für die Stanford University, wo er zweimal die NCAA-Meisterschaft gewann. Er wurde jedoch 1996 nach zwei Jahren Studium Profi. Seitdem hat er zahlreiche Siege auf der PGA Tour erzielt, darunter mehrere Majors.
                </li>
                <li>
                    Lydia Ko: Ko spielte nie College-Golf, wurde aber 2013 im Alter von 16 Jahren Profi. Seitdem hat sie mehrere Siege auf der LPGA Tour erzielt.
                </li>
                <li>
                    Jordan Spieth: Spieth spielte College-Golf für die University of Texas, wo er 2012 die NCAA-Meisterschaft gewann. Er wurde jedoch 2012 nach einem Jahr Studium Profi. Seitdem hat er mehrere Siege auf der PGA Tour erzielt, darunter mehrere Majors.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            In diesem Kapitel haben wir gesehen, wie der Übergang vom College zum Profi erfolgen kann und welche alternativen Wege zur Verfügung stehen. Im nächsten Kapitel werden wir die internationalen Einflüsse und Möglichkeiten im College-Golf erkunden.
        </MKTypography>
    </MKBox>
</MKBox>







                                              






                                  







<MKBox id="Kapitel 4" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Kapitel 4: Internationale Einflüsse und Möglichkeiten
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        College-Golf ist nicht nur ein amerikanischer Sport, sondern auch ein internationaler. Es gibt viele ausländische Spieler, die in die USA kommen, um zu studieren und Golf zu spielen, und es gibt auch viele amerikanische Spieler, die im Ausland spielen. In diesem Kapitel werden wir uns die Auswirkungen und Beiträge internationaler Studenten-Athleten sowie die Möglichkeiten für amerikanische College-Golfer ansehen, international zu spielen.
    </MKTypography>

    <MKBox id="4.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.1 Ausländische Spieler im College-Golf
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image3}
                    alt="Texas Longhorns, mit Jordan Spieth, feiern die nationale Meisterschaft"
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal3(Image3)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Texas Longhorns, mit Jordan Spieth, feiern die nationale Meisterschaft
                </figcaption>
            </figure>
            College-Golf ist für viele ausländische Spieler, die nach einer Kombination aus Sport und Bildung suchen, eine attraktive Option. Sie können von der hohen Qualität amerikanischer Universitäten, den ausgezeichneten Einrichtungen und der Betreuung der Golfprogramme sowie dem starken Wettbewerb und der Exposition bei College-Golfturnieren profitieren.
            <br/>
            <br/>
            Ausländische Spieler bringen auch viel für amerikanische Universitäten mit. Sie bringen Vielfalt, Kultur und Talent in ihre Teams. Sie können auch ihre Erfahrungen und Perspektiven mit ihren Teamkollegen und Trainern teilen und so zu deren Entwicklung beitragen.
            <br/>
            <br/>
            Viele ausländische Spieler waren im College-Golf erfolgreich und haben sich als Studenten-Athleten hervorgetan. Beispiele sind:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Maria Fassi: Fassi spielte College-Golf an der University of Arkansas, wo sie zweimal den ANNIKA Award als beste weibliche College-Golferin gewann. Sie wurde 2019 Profi und hat seitdem mehrere professionelle Siege erzielt.
                </li>
                <li>
                    Viktor Hovland: Hovland spielte College-Golf an der Oklahoma State University, wo er 2018 die NCAA-Meisterschaft gewann. Er wurde 2019 Profi und hat seitdem mehrere professionelle Siege erzielt.
                </li>
            </ul>
        </MKTypography>
    </MKBox>
    <MKBox id="4.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.2 Amerikanische Spieler im Ausland
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            College-Golf ist nicht der einzige Weg, sich als Golfer zu entwickeln. Es gibt auch viele Möglichkeiten für amerikanische College-Golfer, international zu spielen, andere Kulturen und Plätze zu erleben. Dies kann ihren Horizont erweitern, ihre Anpassungsfähigkeit erhöhen und ihr Spiel verbessern.
            <br/>
            <br/>
            Amerikanische College-Golfer können international spielen durch verschiedene Programme, wie zum Beispiel:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Der Palmer Cup: Ein jährliches Turnier zwischen Teams von amerikanischen und internationalen College-Golfern, benannt nach Arnold Palmer, einem berühmten Golfer, der auch College-Golf spielte. Das Turnier findet an verschiedenen Orten auf der ganzen Welt statt, wie z.B. Frankreich, Irland, England und Schweden.
                </li>
                <li>
                    Der Eisenhower Trophy: Ein alle zwei Jahre stattfindendes Turnier zwischen Teams von Amateur-Golfern aus verschiedenen Ländern, benannt nach Dwight D. Eisenhower, einem ehemaligen US-Präsidenten und begeisterten Golfer. Das Turnier findet an verschiedenen Orten auf der ganzen Welt statt, wie z.B. Australien, Japan, Mexiko und der Türkei.
                </li>
                <li>
                    Das Studienprogramm im Ausland: Ein akademisches Programm, das den Studierenden die Möglichkeit bietet, ein Semester oder ein Jahr an einer ausländischen Universität zu studieren. Einige Universitäten haben auch Golfprogramme, die mit ausländischen Universitäten zusammenarbeiten und es den Studenten-Athleten ermöglichen, während ihres Aufenthalts Golf zu spielen.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Viele amerikanische College-Golfer haben vom Spielen im Ausland profitiert und ihr Spiel auf ein höheres Niveau gebracht. Beispiele sind:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Collin Morikawa: Morikawa spielte College-Golf an der University of California, Berkeley, wo er viermal All-American wurde. Er spielte auch 2017 und 2018 im Palmer Cup und 2018 im Eisenhower Trophy. Er wurde 2019 Profi und hat seitdem mehrere professionelle Siege erzielt.
                </li>
                <li>
                    Jennifer Kupcho: Kupcho spielte College-Golf an der Wake Forest University, wo sie 2018 die NCAA-Meisterschaft gewann. Sie spielte auch 2018 im Palmer Cup und 2016 und 2018 im Curtis Cup. Sie wurde 2019 Profi und hat seitdem mehrere professionelle Siege erzielt.
                </li>
                <li>
                    Patrick Reed: Reed spielte College-Golf an der University of Georgia und der Augusta State University, wo er zweimal die NCAA-Meisterschaft gewann. Er spielte auch 2011 im Palmer Cup und studierte ein Semester an der University of Stirling in Schottland. Er wurde 2011 Profi und hat seitdem mehrere professionelle Siege erzielt.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            In diesem Kapitel haben wir gesehen, wie College-Golf ein internationaler Sport ist und welche Einflüsse und Möglichkeiten es für ausländische und amerikanische Spieler gibt. Im nächsten Kapitel werden wir die Bedeutung von Golfstipendien und die Rolle von Sportbeursamerika.nl beleuchten.
        </MKTypography>
    </MKBox>
</MKBox>












                                              






                                  


                                
<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Sportstipendien und die Zukunft der Athleten
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sportstipendien bieten zahlreiche Möglichkeiten für junge Athleten, ihr Talent zu zeigen und ihre Fähigkeiten zu entwickeln. Eine der bedeutendsten Möglichkeiten ist der Erhalt eines Sportstipendiums, um auf Hochschulebene zu studieren und zu konkurrieren. In diesem Kapitel werden wir die Bedeutung von Sportstipendien diskutieren und wie Sportstipendien Amerika Ihnen helfen kann, Ihren Traum zu verwirklichen.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Die Bedeutung von Stipendien
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ein Sportstipendium ist finanzielle Unterstützung, die von einer Universität einem Studenten-Athleten zur Verfügung gestellt wird, um Studium und Sport zu kombinieren. Es kann die Kosten für Studiengebühren, Bücher, Unterkunft, Mahlzeiten und andere Ausgaben abdecken. Darüber hinaus kann es auch Vorteile wie akademische Nachhilfe, medizinische Versorgung, Trainingsmöglichkeiten und Ausrüstung bieten.
      <br />
      <br />
      Die Erlangung eines Sportstipendiums ist wettbewerbsfähig, wobei viele Faktoren eine Rolle im Zuteilungsprozess spielen, einschließlich akademischer Leistungen, sportlicher Leistung, Persönlichkeit, Motivation, Führung und Anpassungsfähigkeit. Daher ist es wichtig, sich gut vorzubereiten und sich von der Konkurrenz abzuheben.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Wie Sportstipendien Amerika helfen können
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo von Sportstipendien Amerika, das Sportausrüstung und eine Abschlusskappe zeigt, die den Fokus auf Sportstipendien für Studenten-Athleten repräsentiert."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo von Sportstipendien Amerika
        </figcaption>
      </figure>
      Sportstipendien Amerika ist eine Organisation, die sich auf die Anleitung junger Sporttalente zu Sportstipendien spezialisiert hat. Mit leidenschaftlichen und erfahrenen Experten unterstützt Sportstipendien Amerika Studenten-Athleten auf jedem Schritt der Reise, von der ersten Orientierung bis zur endgültigen Platzierung. Ihre Dienstleistungen umfassen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Ein persönliches Aufnahmegespräch, um die Wünsche, Möglichkeiten und Erwartungen des Studenten-Athleten zu besprechen.</li>
        <li>Eine professionelle Bewertung des sportlichen und akademischen Niveaus des Studenten-Athleten.</li>
        <li>Ein umfangreiches Netzwerk von Kontakten zu Trainern, Universitäten und Hochschulen.</li>
        <li>Effektive Werbung für das Profil und die Videos des Studenten-Athleten bei potenziellen Trainern.</li>
        <li>Expertenberatung zu den besten Optionen basierend auf den Vorlieben, Zielen und dem Budget des Studenten-Athleten.</li>
        <li>Verhandlung der besten Stipendienangebote im Namen des Studenten-Athleten.</li>
        <li>Hilfe bei der Beantragung der erforderlichen Dokumente wie Visum, Zeugnis, Diplom und Zulassungstests.</li>
        <li>Vorbereitung auf das Leben des Studenten-Athleten während seines Stipendiums, einschließlich Kultur, Sprache, Regeln und Tipps.</li>
        <li>Nachsorge und Follow-up während der Stipendiumszeit.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Möchten Sie Stipendienmöglichkeiten erkunden und Ihre sportlichen und akademischen Ziele erreichen? <Link to="/de/Dienstleistungen-Produkte">Sehen Sie sich die vollständige Palette</Link> der von Sportstipendien Amerika angebotenen Dienstleistungen an und vereinbaren Sie noch heute eine Beratung.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;