/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Produktside: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Kodet af www.creative-tim.com

 =========================================================

* Ovenstående ophavsretsmeddelelse og denne tilladelsesmeddelelse skal medtages i alle kopier eller betydelige dele af softwaren.
*/

// Materielle komponenter fra @mui
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React komponenter
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Billeder
import post1 from "assets/images/Jeugd.png";
import post3 from "assets/images/Deal.png";
import "../../../../H1asH3.css"


function Profil() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center"></MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox 
                  display="flex" 
                  flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} 
                  justifyContent="space-between" 
                  alignItems="center" 
                  mb={1}
              >
                  <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Del 2: Min tid i Amerika</MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text">
  <br />
    Min tid i Amerika var som en åndeløs rejse, fyldt med uforglemmelige oplevelser og personlig vækst. 
    Takket være fodbold rejste jeg fra kyst til kyst, ledsaget af en gruppe holdkammerater, der hurtigt blev nogle af mine bedste venner. 
    Vores dage begyndte tidligt med intensive træningssessioner i fitnesscentret, noget jeg ikke var vant til i Holland. 
    Derefter hurtigt til universitetet til undervisning og tilbage til træning om eftermiddagen. Det var en daglig forpligtelse, der udfordrede mig både fysisk og mentalt.
  <br /><br />
    Kampene var højdepunkter, som tusinder af fans flokkede til, og hver gang føltes det, som om vi spillede for noget større end os selv. 
    Adrenalinen ved at træde ind på banen foran en så passioneret mængde er ubeskrivelig. Desuden var turene, vi tog til kampe, uforglemmelige. 
    På flyet tilbragte vi ofte timer med at spille kort, og i hotellobbyerne delte vi morsomme øjeblikke og spændende samtaler. 
    Restauranter med lækre retter blev vores midlertidige spisesteder, og disse kulinariske eventyr tilføjede en ekstra dimension til vores rejser. 
    Alt var omhyggeligt arrangeret, fra kortene på flyet til restauranterne, hvor vi spiste. 
    Disse oplevelser gav mig et glimt af Amerikas storhed.
  <br /><br />
    Derudover fik jeg mulighed for at praktikere med førende professionelle klubber, herunder Seattle Sounders. 
    At dele banen med spillere som Amerikas all-time topscorer Clint Dempsey var intet mindre end surrealistisk, et højdepunkt, jeg altid vil værne om. 
    Men som enhver eventyr havde denne rejse sine udfordringer. 
    I mit sidste år stod jeg over for en alvorlig hamstringsskade, der midlertidigt satte mine fodboldambitioner på pause. 
    Dette var en periode med personlig refleksion og modstandsdygtighed, hvorfra jeg kom stærkere ud.
  <br /><br />
    Desuden var min akademiske rejse i Amerika lige så vigtig. På trods af det intensive fodboldtræningsprogram og omfattende rejser, 
    fik jeg den perfekte vejledning til at fortsætte mine studier. Det var ikke en nem opgave, da jeg ofte missede timer på grund af mit engagement med fodboldholdet. 
    Ikke desto mindre var jeg fast besluttet på at lykkes, og det gjorde jeg med stolthed ved at dimittere med højeste hædersbevisninger, magna cum laude. 
    Denne akademiske succes var et vidnesbyrd om min dedikation både til min sport og min uddannelse.
  <br /><br />
    Under min eventyr i Amerika oplevede jeg typiske amerikanske fester, der syntes direkte ud af en film. 
    Disse livlige sociale sammenkomster, fyldt med musik, dans og høj latter, gav mig mulighed for at vokse, ikke kun som atlet og studerende, 
    men også som en social entusiast. Disse fester tilføjede en ekstra dimension til mit studieliv og berigede mine sociale oplevelser.
  <br /><br />
    Men Amerika betød mere for mig end bare fodbold, kampe, sociale begivenheder og akademiske præstationer. 
    Det var en mulighed for at udvide mit sociale netværk og skabe livslange venskaber. 
    Takket være disse venskaber kunne jeg opdage landet på nye måder. 
    Fra forårsferie i Mexico, hvor vi nød sol, hav og strand, til at fejre Thanksgiving med venners familier, 
    som jeg nu kan kalde min amerikanske familie. Deres varme velkomst gjorde mig virkelig til en del af deres liv, 
    og disse oplevelser har gjort min tid i Amerika endnu rigere.
  <br /><br />
  Denne periode af mit liv fordybede min kærlighed til både fodbold og uddannelse og førte mig til den vej, jeg nu følger for at hjælpe andre med at opnå deres drømme i Amerika. 
  Fortsæt med at følge med, for i Del 3 vil jeg dele, hvordan min tilbagevenden til Holland og grundlæggelsen af mit firma førte mit liv i en ny retning.
</MKTypography>
              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="Del 1: Opvækst i Holland"
                    action={{
                      type: "internal",
                      route: "/da/Om-mig/Opvokser",
                      color: "info",
                      label: "læs mere",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="Del 3: Tilbage i Holland"
                    action={{
                      type: "internal",
                      route: "/da/Om-mig/Min-hjemkomst-til-Holland",           
                      color: "info",
                      label: "læs mere",
                    }}
                  />
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profil;
