import React, { useState } from "react";
import { Container, Grid, Box, Typography } from "@mui/material";
import MKBadge from "components/MKBadge";
import MKButton from "components/MKButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ExampleCard from "../ExampleCard";
import Toefl_cover from "assets/images/Producten/Toefl_kaft.png";
import Toefl_open from "assets/images/Producten/Toefl_open.png";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";

import MKSnackbar from "components/MKSnackbar";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Link } from 'react-router-dom';


const sportsData = [
  {
    items: [
      {
        image: Toefl_cover,
        alt: "Umschlag des TOEFL-Studienführers von Sport Scholarships America mit Titel und Logo",
      },
      {
        image: Toefl_cover,
        alt: "Umschlag des TOEFL-Studienführers von Sport Scholarships America mit Titel und Logo",
      },
      {
        image: Toefl_open,
        alt: "Geöffneter TOEFL-Studienführer von Sport Scholarships America mit Seiten zu Teststrategien und Übungsmaterialien",
      },
      {
        image: Toefl_open,
        alt: "Geöffneter TOEFL-Studienführer von Sport Scholarships America mit Seiten zu Teststrategien und Übungsmaterialien",
      },
    ],
  },
];


function DesignBlocks() {
  const [selectedImage, setSelectedImage] = useState(Toefl_cover);
  const [selectedAlt, setSelectedAlt] = useState("Umschlag des TOEFL-Studienführers von Sport Scholarships America mit Titel und Logo"); // Setze initialen Alt-Text
  const [quantity, setQuantity] = useState(0);
  const [show, setShow] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showModal, setShowModal] = useState(false);


  const handleIncrement = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 0) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const handleAddToCartClick = () => {
    if (quantity === 0) {
      setShowSnackbar(true);
      setShowModal(false); // Stelle sicher, dass das Modal nicht gleichzeitig geöffnet wird
    } else {
      setShowModal(true);
      setShowSnackbar(false); // Stelle sicher, dass der Snackbar nicht gleichzeitig geöffnet wird
    }
  };


  const toggleSnackbar = () => setShow(!show);
  const toggleModal = () => setShow(!show);



  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const renderData = sportsData.map(({ items }) => (
    <Grid container spacing={3} alignItems="flex-start">
      <Grid item xs={12} md={4}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
          <ExampleCard
  image={selectedImage}
  alt={selectedAlt} // Übergebe hier den aktualisierten Alt-Text
  applyGradient={false}
  applyPopOut={false}
/>

          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} justifyContent="center">
            {items.map(({ image, name, alt }) => (
  <Grid item xs={3} sm={3} md={3} key={name}>
    <div
      onClick={() => {
        setSelectedImage(image);
        setSelectedAlt(alt); // Aktualisiere hier auch den Alt-Text
      }}
      style={{ cursor: "pointer" }}
    >
      <ExampleCard
        image={image}
        alt={alt} // Übergebe den Alt-Text korrekt
        selected={selectedImage === image}
      />
    </div>
  </Grid>
))}

            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} container flexDirection="column" alignItems="flex-start" sx={{ textAlign: "left", my: 0, pl: { xs: 0, md: 4 } }}>
        <MKBadge 
          badgeContent="Sport Scholarships America" 
          variant="contained" 
          color="info" 
          container 
          circular 
          sx={{ mb: isSmallScreen ? 0 : 2, marginLeft: isSmallScreen ? 0 : 4 }} 
        />
        <Typography variant="h1" fontWeight="bold" sx={{ pb: isSmallScreen ? 0 : 2, marginLeft: isSmallScreen ? 0 : 4 }}>
          TOEFL Studienführer
        </Typography>
        <Typography variant="body1" color="text" sx={{ pb: isSmallScreen ? 0 : 2, marginLeft: isSmallScreen ? 0 : 4 }}>
          Dieser Leitfaden deckt alle wesentlichen TOEFL-Abschnitte gründlich ab:
          <ul style={{ paddingLeft: "30px", paddingTop: "10px", paddingBottom: "10px" }}> {/* Erhöhe den Padding-Wert bei Bedarf */}
            <li>
              <strong>Lesen:</strong> Effektive Lesestrategien und präzise Beantwortung von Fragen.
            </li>
            <li>
              <strong>Hören:</strong> Verbesserte Hörverständnis mit Notizfähigkeiten.
            </li>
            <li>
              <strong>Sprechen:</strong> Sprechfertigkeiten und Antwort auf mündliche Fragen.
            </li>
            <li>
              <strong>Schreiben:</strong> Schreibfertigkeiten für Aufsätze und Online-Diskussionen.
            </li>
          </ul>
          Dieser Studienführer enthält Übungsaufgaben, detaillierte Antworten und wertvolle Einblicke. Bereiten Sie sich gründlich auf jeden Aspekt des TOEFL-Tests vor!
        </Typography>
        <Typography variant="h3" sx={{ pb: isSmallScreen ? 0 : 2, marginLeft: isSmallScreen ? 0 : 4 }}>
          €10,00
        </Typography>
        <Grid container spacing={2} alignItems="flex-start" sx={{ marginLeft: isSmallScreen ? 0 : 2 }}>
          <Grid item>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid lightgray",
                padding: "5px 10px",
                marginTop: "20px",
                backgroundColor: "lightgrey",
                width: "200px",
                borderRadius: "15px",
                height: "48px",
              }}
            >
              <RemoveIcon onClick={handleDecrement} style={{ cursor: "pointer" }} color=""/>
              <span style={{ color: "" }}>{quantity}</span>
              <AddIcon onClick={handleIncrement} style={{ cursor: "pointer" }} color=""/>
            </div>
          </Grid>
          <Grid item>
            <MKButton 
              color="info" 
              style={{ height: "48px", marginTop: "20px" }} 
              startIcon={<AddShoppingCartIcon />}
              onClick={handleAddToCartClick} // Verschoben onClick hierhin
            >
              In den Warenkorb legen
            </MKButton>
          </Grid>
          <MKSnackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            color="primary"
            icon="notifications"
            title="Sport Scholarships America"
            content="Ups, du kannst keine 0 Produkte hinzufügen. Bitte gib eine gültige Menge ein."
            dateTime="Vor 1 Minute"
            open={show}
            close={toggleSnackbar}
          />
        </Grid>
      </Grid>
    </Grid>
  ));
  return (
    <Box component="section" my={6} py={6}>
      <Container sx={{ mt: 0 }}>{renderData}</Container>

      <MKSnackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        color="primary"
        icon="notifications"
        title="Sport Scholarships America"
        content="Ups, du kannst keine 0 Produkte hinzufügen. Bitte gib eine gültige Menge ein."
        dateTime="vor 0 Minuten"
        open={showSnackbar}
        close={() => setShowSnackbar(false)}
      />

      <Modal 
        open={showModal} 
        onClose={() => setShowModal(false)} 
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Slide direction="down" in={showModal} timeout={500}>
          <MKBox
            position="relative"
            width="500px"
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
          >
            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
              <MKTypography variant="h5">Vorübergehend ausverkauft
              </MKTypography>
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={() => setShowModal(false)} />
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox p={2}>
              <MKTypography variant="body2" color="secondary" fontWeight="regular">
                Leider ist das von Ihnen ausgewählte Produkt derzeit ausverkauft. Ich entschuldige mich für die Unannehmlichkeiten. Ich arbeite hart daran, den Vorrat so schnell wie möglich aufzufüllen.
                <br/>
                <br/>
                Wenn Sie Fragen haben oder weitere Informationen benötigen, kontaktieren Sie mich bitte. Ich helfe Ihnen gerne weiter!
              </MKTypography>
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox display="flex" justifyContent="space-between" p={1.5}>
              <MKButton variant="gradient" color="dark" onClick={() => setShowModal(false)}>
                Schließen
              </MKButton>
              <MKButton 
                component={Link} 
                to="/de/Kontaktformular" 
                variant="gradient" 
                color="info"
              >
                Kontaktformular
              </MKButton>
            </MKBox>
          </MKBox>
        </Slide>
      </Modal>

    </Box>
  );
}

export default DesignBlocks;

