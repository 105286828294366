/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import React, { useState } from 'react'; // Step 1: Import useState
import { Link } from 'react-router-dom';


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Football1.jpg";
import Image2 from "assets/images/Sports_Images/Football2.webp";
import Image3 from "assets/images/Sports_Images/Football3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";
import "../../../../H1asH3.css"


// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Borse di studio per il football americano negli Stati Uniti: Come garantirsi un posto in una squadra universitaria', isSubheader: false },
  
    { id: 'Capitolo 1', title: 'Capitolo 1: La Storia e lo Sviluppo del Football Americano', isSubheader: false },
    { id: '1.1', title: '1.1 Gli Anni Iniziali', isSubheader: true },
    { id: '1.2', title: '1.2 Crescita e Professionalizzazione', isSubheader: true },
  
    { id: 'Capitolo 2', title: 'Capitolo 2: Il Football Universitario', isSubheader: false },
    { id: '2.1', title: '2.1 Principali Leghe e Conferenze', isSubheader: true },
    { id: '2.2', title: '2.2 Sviluppo dei Giocatori', isSubheader: true },
  
    { id: 'Capitolo 3', title: 'Capitolo 3: Dal College alla NFL', isSubheader: false },
    { id: '3.1', title: '3.1 Il Percorso verso la NFL', isSubheader: true },
    { id: '3.2', title: '3.2 Altre Opportunità Professionali', isSubheader: true },
  
    { id: 'Capitolo 4', title: 'Capitolo 4: L\'Influenza del Football Americano sulla Cultura Americana', isSubheader: false },
    { id: '4.1', title: '4.1 Impatto Culturale', isSubheader: true },
    { id: '4.2', title: '4.2 Modelli e Ispirazione', isSubheader: true },
  
    { id: 'Capitolo 5', title: 'Capitolo 5: Borse di Studio Sportive e il Futuro degli Atleti', isSubheader: false },
    { id: '5.1', title: '5.1 L\'Importanza delle Borse di Studio', isSubheader: true },
    { id: '5.2', title: '5.2 Come Sport Scholarships America Può Aiutare', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indice
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Nascondi</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostra</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>

























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Borse di studio per il football americano negli Stati Uniti: Come garantirsi un posto in una squadra universitaria
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Vista aerea di una partita di calcio americano affollata all'Università dell'Indiana, con giocatori in azione sul campo e tifosi sugli spalti."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
       }}>
        Partita di football dell'Università dell'Indiana dagli spalti.
      </figcaption>
    </figure>
    Il football americano è uno sport nato negli Stati Uniti alla fine del XIX secolo. È uno sport di squadra in cui due squadre di undici giocatori cercano di far avanzare una palla ovale oltre la linea di porta avversaria o di calciarla tra i pali. Il gioco è caratterizzato dal contatto fisico, dalle tattiche, dalla strategia e dallo spettacolo.
    <br/>
    <br/>
    Il football americano non è solo uno sport; è anche una cultura. È una parte essenziale dell'identità, dei valori e delle tradizioni americane. È una fonte di orgoglio, passione e ispirazione per milioni di persone. È anche un'industria che genera miliardi di dollari e crea migliaia di posti di lavoro.
    <br/>
    <br/>
    Ma prima di poter diventare un giocatore professionista nella NFL, devi mostrare il tuo talento a livello universitario. Il football universitario è una parte cruciale dello sviluppo dei giocatori, offrendo ai giovani atleti l'opportunità di migliorare le proprie abilità, ricevere un'educazione e costruire una rete di contatti.
    <br/>
    <br/>
    Tuttavia, il football universitario non è facilmente accessibile a tutti. Ci sono migliaia di squadre e giocatori che competono per un numero limitato di posti e borse di studio. Inoltre, ci sono molte regole e requisiti che devi soddisfare per qualificarti per una squadra universitaria.
    <br/>
    <br/>
    È qui che Sportbeursamerika.nl può aiutarti. Sono specializzato nell'abbinare giovani talenti con squadre universitarie alla ricerca di rinforzi. Posso aiutarti a trovare una borsa di studio sportiva, a organizzare il tuo visto, a prepararti per gli esami di ammissione e a negoziare con gli allenatori. Con il mio aiuto, puoi realizzare il sogno di studiare e giocare in America.
    <br/>
    <br/>
    Vuoi sapere come? Continua a leggere!
  </MKTypography>
</MKBox>

{/* Capitoli principali e sottocapitoli con testo segnaposto */}
<MKBox id="Chapter 1: The History and Development of American Football" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 1: La Storia e lo Sviluppo del Football Americano
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Il football americano è uno sport nato negli Stati Uniti alla fine del XIX secolo. Deriva dal rugby e dal calcio, due sport popolari in Europa. In questo capitolo, ti parleremo dei primi anni e della crescita e professionalizzazione del football americano.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Gli Anni Iniziali
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      L'origine del football americano è spesso attribuita a una partita tra due squadre universitarie nel 1869: Rutgers e Princeton. Tuttavia, questa partita assomigliava più a una forma di calcio rispetto al moderno football americano. C'erano 25 giocatori per squadra, la palla non poteva essere toccata con le mani e non c'erano pali.
      <br/>
      <br/>
      La prima partita che assomigliava al moderno football americano si è svolta nel 1874 tra Harvard e McGill. Questa partita era una combinazione di rugby e calcio, consentendo il trasporto della palla con le mani e presentando dei pali. Harvard vinse la partita 3-0.
      <br/>
      <br/>
      Il ruolo delle università nella formazione delle prime squadre e leghe è stato cruciale. Erano i pionieri che definivano le regole e lo stile del gioco. Nel 1873, è stata fondata la prima associazione intercollegiale di football, composta da Yale, Columbia, Princeton e Harvard. Nel 1880, Walter Camp, giocatore e allenatore di Yale, venne considerato il "Padre del Football Americano" perché introdusse innovazioni significative, come la riduzione del numero di giocatori a 11, l'introduzione della linea di scrimmage e il sistema dei down.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crescita e Professionalizzazione
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      L'evoluzione del football americano in un fenomeno nazionale iniziò alla fine del XIX e all'inizio del XX secolo. Il gioco guadagnò popolarità tra studenti, spettatori e media. Divenne anche sempre più violento e pericoloso, portando a numerose lesioni e persino morti. Nel 1905, il presidente Theodore Roosevelt minacciò di vietare il gioco se non fossero state apportate riforme. Questo portò alla fondazione della National Collegiate Athletic Association (NCAA) nel 1906, un'organizzazione responsabile della regolamentazione e dell'organizzazione degli sport universitari.
      <br/>
      <br/>
      Anche l'influenza del football americano professionale sul gioco universitario è stata significativa. La prima lega professionale di football americano fu fondata nel 1892 ma divenne veramente di successo nel 1920 con la formazione dell'American Professional Football Association (APFA), successivamente ribattezzata National Football League (NFL). La NFL attirò molti giocatori talentuosi lontano dai college, costringendoli ad alzare il loro livello per rimanere competitivi. La NFL divenne anche una fonte principale di reddito e visibilità per le università, affittando spesso i loro stadi alle squadre professionistiche.
      <br/>
      <br/>
      In questo capitolo, ti abbiamo parlato della storia e dello sviluppo del football americano. Nel prossimo capitolo, forniremo ulteriori approfondimenti sul football americano a livello universitario.
    </MKTypography>
  </MKBox>
</MKBox>







                                              





                                  







<MKBox id="Chapter 2" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Capitolo 2: Il Football Universitario
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Il football universitario è uno degli sport più popolari e competitivi negli Stati Uniti. Ogni anno, più di mille squadre e centinaia di migliaia di giocatori competono per il titolo nazionale, i campionati regionali e i prestigiosi bowl. In questo capitolo, ti parleremo delle principali leghe e conferenze nel football universitario e dello sviluppo dei giocatori a livello universitario.
    </MKTypography>

    <MKBox id="2.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.1 Principali Leghe e Conferenze
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>

            Una panoramica delle divisioni NCAA e delle principali conferenze nel football universitario. La NCAA è l'organizzazione responsabile della regolamentazione e dell'organizzazione degli sport universitari negli Stati Uniti. La NCAA divide le squadre in tre divisioni: Divisione I, Divisione II e Divisione III. La Divisione I è la divisione più alta e si compone di due sottodivisioni: la Football Bowl Subdivision (FBS) e la Football Championship Subdivision (FCS). Le squadre FBS sono le più prestigiose e lucrative, competendo per un posto nel College Football Playoff, un sistema che determina il campione nazionale dal 2014. Le squadre FCS sono meno conosciute e ricche, ma hanno il loro proprio sistema di playoff per determinare il loro campione.
            <br/>
            <br/>
            All'interno di ogni divisione e sottodivisione, ci sono varie conferenze, che sono gruppi di squadre che sono geograficamente o storicamente connesse. Ogni conferenza ha il proprio calendario, regole e campionato. Le principali conferenze in FBS sono: Atlantic Coast Conference (ACC), Big Ten Conference, Big 12 Conference, Pac-12 Conference, Southeastern Conference (SEC) e American Athletic Conference (AAC). Le principali conferenze in FCS sono: Big Sky Conference, Colonial Athletic Association (CAA), Missouri Valley Football Conference (MVFC) e Southern Conference (SoCon).
            <br/>
            <br/>
            I programmi universitari più prestigiosi e il loro impatto sullo sport. Alcuni college hanno una lunga e gloriosa storia nel football universitario, risalente agli anni d'oro dello sport. Hanno vinto numerosi titoli nazionali, bowl, premi individuali e hanno prodotto giocatori professionisti. Hanno anche molti fan, attenzione mediatica e risorse finanziarie. Alcuni esempi di questi college sono: Alabama, Notre Dame, Ohio State, Oklahoma, USC, Michigan, Texas, Clemson, Florida State e LSU.
        </MKTypography>
    </MKBox>
    <MKBox id="2.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.2 Sviluppo dei Giocatori
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image2}
                    alt="Giovane Tom Brady in azione come quarterback per l'Università del Michigan durante una partita di football universitario, vestito con l'uniforme blu e gialla della squadra."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal2(Image2)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Tom Brady in azione per l'Università del Michigan.
                </figcaption>
            </figure>
            Come le squadre universitarie contribuiscono allo sviluppo tecnico e fisico dei giocatori. Le squadre universitarie offrono ai giovani giocatori l'opportunità di migliorare le proprie abilità sotto la guida di allenatori e membri dello staff esperti. Forniscono anche accesso a strutture di alta qualità, attrezzature e cure mediche. Inoltre, espongono i giocatori a un alto livello di competizione, dove affrontano altri giocatori talentuosi provenienti da tutto il paese.
            <br/>
            <br/>
            Esempi di famosi giocatori che hanno affinato le proprie abilità a livello universitario. Molti giocatori professionisti hanno iniziato le loro carriere a livello universitario, dove si sono distinti per le loro prestazioni e il loro potenziale. Alcuni giocatori hanno persino vinto premi come l'Heisman Trophy, il premio individuale più prestigioso nel football universitario. Esempi di questi giocatori includono: Tom Brady (Michigan), Peyton Manning (Tennessee), Joe Montana (Notre Dame), Barry Sanders (Oklahoma State), Jerry Rice (Mississippi Valley State) e Tim Tebow (Florida).
            <br/>
            <br/>
            In questo capitolo, ti abbiamo parlato del football universitario. Nel prossimo capitolo, ti parleremo del percorso verso la NFL e altre opportunità professionali.

        </MKTypography>
    </MKBox>
</MKBox>









                                              






                                  









<MKBox id="Chapter 3" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Capitolo 3: Dal College alla NFL
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Il football universitario è un trampolino di lancio per la National Football League (NFL), la lega professionistica più alta al mondo. Ogni anno, centinaia di giocatori vengono selezionati dalle squadre NFL nel Draft NFL, un evento in cui le squadre si alternano nella scelta di un giocatore da un pool di giocatori universitari. In questo capitolo, ti parleremo di più del percorso verso la NFL e delle altre opportunità professionali.
    </MKTypography>

    <MKBox id="3.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.1 Il Percorso verso la NFL
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Come il football universitario funziona come trampolino di lancio per la National Football League (NFL). Il football universitario è la principale fonte di talenti per la NFL. La maggior parte dei giocatori nella NFL ha prima giocato a livello universitario. Il football universitario offre ai giocatori la possibilità di dimostrare le proprie capacità contro altri atleti di alto livello, sviluppare le proprie abilità, migliorare le proprie statistiche e aumentare il proprio valore nel draft. Il football universitario offre anche ai giocatori esposizione e riconoscimento da parte di talent scout, allenatori e media.
            <br/>
            <br/>
            Circa 250 giocatori vengono scelti ogni anno nel Draft NFL, che di solito si tiene in aprile. Il draft consiste in sette turni, ciascuno con 32 scelte (una per ogni squadra). L'ordine delle scelte è determinato dal risultato della stagione precedente, con la squadra peggiore che sceglie prima e la migliore squadra che sceglie per ultima. Il draft è un evento emozionante e imprevedibile, con alcuni giocatori che vengono scelti più alti o più bassi del previsto, e alcuni che non vengono scelti affatto. Esempi di famosi giocatori selezionati nel Draft NFL includono: John Elway (prima scelta nel 1983), Troy Aikman (prima scelta nel 1989), Peyton Manning (prima scelta nel 1998), Tom Brady (199ª scelta nel 2000), Aaron Rodgers (24ª scelta nel 2005) e Patrick Mahomes (10ª scelta nel 2017).
        </MKTypography>
    </MKBox>
    <MKBox id="3.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.2 Altre Opportunità Professionali
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Percorsi alternativi per una carriera professionale nel football americano al di fuori della NFL. Non tutti i giocatori che giocano a livello universitario arrivano alla NFL. Alcuni giocatori non vengono scelti nel draft, o vengono scelti ma non riescono a ottenere un posto in una squadra. Alcuni giocatori si infortunano o perdono la motivazione o la passione. Ma questo non significa che devono rinunciare al sogno di giocare professionalmente. Ci sono altre opportunità professionali al di fuori della NFL, come:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    La Canadian Football League (CFL): Si tratta di una lega professionistica composta da nove squadre che giocano in Canada. La CFL ha alcune differenze rispetto alla NFL, come un campo più grande, più giocatori per squadra e meno giocate per serie. La CFL attira molti giocatori che non possono giocare nella NFL, ma vogliono comunque giocare ad un alto livello.
                </li>
                <li>
                    La XFL: Si tratta di una nuova lega professionistica fondata nel 2020 come alternativa alla NFL. La XFL è composta da otto squadre che giocano negli Stati Uniti. La XFL mira a fornire un gioco più veloce, più eccitante e più innovativo rispetto alla NFL. La XFL offre anche più opportunità per i giocatori che sono stati trascurati o ignorati dalla NFL.
                </li>
                <li>
                    La European League of Football (ELF): Si tratta di una nuova lega professionistica fondata nel 2021 con l'obiettivo di promuovere e sviluppare il football americano in Europa. L'ELF è composta da otto squadre che giocano in Germania, Polonia, Spagna e Francia. L'ELF mira a fornire una piattaforma per i giocatori europei per mostrare il loro talento e crescere come atleti.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            In questo capitolo, ti abbiamo parlato di più del percorso verso la NFL e delle altre opportunità professionali. Nel prossimo capitolo, ti parleremo di più dell'influenza del football americano sulla cultura americana.
        </MKTypography>
    </MKBox>
</MKBox>

                                              






                                  




<MKBox id="Chapter 4" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Capitolo 4: L'Influenza del Football Americano sulla Cultura Americana
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Il football americano non è solo uno sport, ma anche una cultura. È una parte significativa dell'identità, dei valori e delle tradizioni americane. È una fonte di orgoglio, passione e ispirazione per milioni di persone. È anche un'industria che genera miliardi di dollari e crea migliaia di posti di lavoro. In questo capitolo, ti parleremo di più dell'impatto culturale del football americano, e dei modelli e dell'ispirazione che offre.
    </MKTypography>

    <MKBox id="4.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.1 Impatto Culturale
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image3}
                    alt="Vista dagli spalti dello vibrante e affollato stadio universitario del Wisconsin durante una partita emozionante, con i tifosi che incitano la loro squadra."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal3(Image3)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Giornata di gioco nello stadio affollato del Wisconsin
                </figcaption>
            </figure>
            L'importanza del football americano nella società e nella cultura americana. Il football americano è uno degli sport più popolari e influenti negli Stati Uniti. Attrae milioni di fan, spettatori e media ogni anno. Fa parte della vita quotidiana, dell'istruzione, della politica, dell'economia e dell'intrattenimento di molti americani. È anche un simbolo di unità nazionale, diversità e orgoglio.
            <br/>
            <br/>
            Gli eventi principali e il loro impatto sull'identità nazionale, come il Super Bowl. Il Super Bowl è il campionato annuale della NFL, di solito tenuto in febbraio. È l'evento sportivo più grande e più seguito al mondo, con oltre 100 milioni di spettatori solo negli Stati Uniti. Il Super Bowl è più di un evento sportivo; è un fenomeno culturale. È un'opportunità per famiglie, amici e colleghi di riunirsi, festeggiare, scommettere e incitare. È anche un palcoscenico per la musica, la pubblicità, l'arte e la politica. Il Super Bowl è spesso visto come un riflesso della cultura, dei valori e delle tendenze americane.
        </MKTypography>
    </MKBox>
    <MKBox id="4.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.2 Modelli e Ispirazione
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            I giocatori di football americano sono spesso modelli e fonti di ispirazione per i giovani atleti che perseguono il sogno di giocare a livello universitario o professionistico. Alcuni giocatori hanno storie straordinarie di avversità, perseveranza e successo che motivano e ispirano gli altri. Alcuni giocatori influenzano positivamente la loro comunità, i fan e il paese attraverso le loro azioni, parole e gesti. Esempi di tali giocatori includono: Colin Kaepernick (che si inginocchiò durante l'inno nazionale per sensibilizzare l'opinione pubblica sull'ingiustizia razziale), Russell Wilson (che guidò la sua squadra al Super Bowl come underdog), JJ Watt (che raccolse milioni di dollari per le vittime dell'uragano Harvey), Drew Brees (che aiutò la sua città New Orleans a riprendersi dopo l'uragano Katrina) e Tom Brady (che continua a giocare ad alti livelli all'età di 43 anni).
            <br/>
            <br/>
            In questo capitolo, ti abbiamo parlato di più dell'influenza del football americano sulla cultura americana. Nel prossimo capitolo, ti parleremo di più delle borse di studio sportive e del futuro del football americano.
        </MKTypography>
    </MKBox>
</MKBox>











                                              






                                  




<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 5: Borse di Studio Sportive e il Futuro degli Atleti
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le borse di studio sportive offrono numerose opportunità ai giovani atleti di mostrare il loro talento e sviluppare le loro abilità. Una delle opportunità più significative è ottenere una borsa di studio sportiva per studiare e competere a livello universitario. In questo capitolo, discuteremo dell'importanza delle borse di studio sportive e di come Sport Scholarships America può aiutarti a realizzare il tuo sogno.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 L'Importanza delle Borse di Studio
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Una borsa di studio sportiva è un sostegno finanziario fornito da un'università a uno studente-atleta per combinare studio e sport. Può coprire i costi di iscrizione, libri, alloggio, pasti e altre spese. Inoltre, può offrire vantaggi come il tutorato accademico, l'assistenza medica, le strutture di allenamento e l'attrezzatura.
      <br />
      <br />
      Ottenere una borsa di studio sportiva è competitivo, con molti fattori che influenzano il processo di assegnazione, tra cui risultati accademici, prestazioni sportive, personalità, motivazione, leadership e adattabilità. È quindi importante prepararsi bene e distinguersi dalla concorrenza.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Come Sport Scholarships America Può Aiutare
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo di Sport Scholarships America, raffigurante attrezzature sportive e una toga, simbolo del focus sulle borse di studio sportive per gli studenti-atleti."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo di Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America è un'organizzazione specializzata nell'orientamento dei giovani talenti sportivi verso borse di studio sportive. Con esperti appassionati ed esperti, Sport Scholarships America supporta gli studenti-atleti in ogni fase del percorso, dall'orientamento iniziale alla collocazione finale. I loro servizi includono:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Un'intervista personale per discutere i desideri, le possibilità e le aspettative dello studente-atleta.</li>
        <li>Una valutazione professionale del livello accademico e sportivo dello studente-atleta.</li>
        <li>Una vasta rete di contatti con allenatori, università e college.</li>
        <li>Promozione efficace del profilo e dei video dello studente-atleta presso allenatori potenziali.</li>
        <li>Consulenza esperta sulle migliori opzioni in base alle preferenze, agli obiettivi e al budget dello studente-atleta.</li>
        <li>Negoziazione delle migliori offerte di borse di studio per conto dello studente-atleta.</li>
        <li>Assistenza nella richiesta dei documenti necessari, come visto, trascrizione, diploma e test di ammissione.</li>
        <li>Preparazione alla vita dello studente-atleta durante la borsa di studio, compresa cultura, lingua, regole e consigli.</li>
        <li>Assistenza e monitoraggio durante il periodo di borsa di studio.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vuoi esplorare le opportunità di borse di studio e raggiungere i tuoi obiettivi sportivi e accademici? <Link to="/it/Servizi-Prodotti">Consulta la gamma completa</Link> dei servizi offerti da Sport Scholarships America e prenota una consultazione oggi stesso.
    </MKTypography>
  </MKBox>
</MKBox>



          
        









         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;