/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Basketbal1.jpeg";
import Image2 from "assets/images/Sports_Images/Basketbal2.webp";
import Image3 from "assets/images/Sports_Images/Basketbal3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import { Link } from 'react-router-dom';

import "../../../../H1asH3.css"

// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Bolsa de Estudos de Basquete nos EUA: Como Estudar e Jogar Basquete na América', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capítulo 1: História e Desenvolvimento do Basquete Universitário', isSubheader: false },
    { id: '1.1', title: '1.1 Primeiros Anos', isSubheader: true },
    { id: '1.2', title: '1.2 Crescimento e Profissionalização', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capítulo 2: Basquete Universitário Hoje', isSubheader: false },
    { id: '2.1', title: '2.1 Competições e Conferências Importantes', isSubheader: true },
    { id: '2.2', title: '2.2 Programas de Elite e Equipes Prestigiadas', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capítulo 3: Da Faculdade para a NBA', isSubheader: false },
    { id: '3.1', title: '3.1 O Caminho para a NBA', isSubheader: true },
    { id: '3.2', title: '3.2 Outras Oportunidades Profissionais', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capítulo 4: Influências e Oportunidades Internacionais', isSubheader: false },
    { id: '4.1', title: '4.1 Jogadores Estrangeiros no Basquete Universitário', isSubheader: true },
    { id: '4.2', title: '4.2 Jogadores Americanos no Exterior', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 A Importância das Bolsas de Estudo', isSubheader: true },
    { id: '5.2', title: '5.2 Como a Sport Scholarships America Pode Ajudar', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };





  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Conteúdo
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Esconder</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>






















          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Bolsa de Estudos de Basquete nos EUA: Como Estudar e Jogar Basquete na América
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Estádio vibrante da Universidade do Arizona durante uma partida de basquete universitário"
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Dia de jogo no Estádio da Universidade do Arizona
      </figcaption>
    </figure>
    O basquete é um dos esportes mais populares nos Estados Unidos. Milhões de pessoas acompanham os jogos da NBA, a liga profissional de basquete, e da NCAA, a liga de basquete universitário. O basquete não é apenas um jogo emocionante de assistir, mas também uma ótima maneira de se desenvolver como jogador e como pessoa.
    <br/>
    <br/>
    Se você é um jovem jogador de basquete que sonha com uma carreira na NBA, ou simplesmente deseja desfrutar de jogar basquete em um nível elevado, então uma bolsa de estudos de basquete na América pode ser para você. Uma bolsa de estudos de basquete é o apoio financeiro que você recebe de uma universidade americana para estudar e jogar basquete lá. Com uma bolsa de estudos de basquete, você não apenas pode obter seu diploma acadêmico, mas também melhorar seus talentos no basquete sob orientação profissional.
    <br/>
    <br/>
    Mas como conseguir uma bolsa de estudos de basquete na América? Quais são os benefícios e desafios de jogar basquete universitário? E quais são as possibilidades após sua carreira universitária? Neste artigo, responderemos a todas essas perguntas e diremos tudo o que você precisa saber sobre bolsas de estudos de basquete na América. Também discutiremos o papel do Sportbeursamerika.nl, uma organização especializada em ajudar jovens jogadores a realizar o sonho de uma bolsa de estudos de basquete.
    <br/>
    <br/>
    Quer saber mais sobre bolsas de estudos de basquete na América? Continue lendo!
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 1: História e Desenvolvimento do Basquete Universitário
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O basquete é um esporte relativamente jovem, inventado em 1891 por James Naismith, um professor de educação física canadense. Ele concebeu o jogo como uma atividade indoor para seus alunos durante os meses de inverno. O jogo rapidamente se tornou popular e se espalhou por várias escolas e universidades na América do Norte.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Primeiros Anos
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O primeiro jogo oficial de basquete universitário ocorreu em 1895 entre a Universidade de Chicago e a Universidade de Iowa. Em 1901, a primeira conferência intercolegial foi estabelecida, a Western Conference, mais tarde conhecida como Big Ten Conference. Em 1906, o primeiro campeonato nacional foi organizado pela Amateur Athletic Union (AAU), que dominou o basquete universitário até 1936.
      <br/>
      <br/>
      Em 1939, o primeiro torneio da NCAA foi realizado, com oito equipes competindo pelo título. O torneio foi vencido por Oregon, derrotando Ohio State por 46-33. O torneio cresceu tornando-se um evento anual que cada vez mais atraiu a atenção de fãs e mídia.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crescimento e Profissionalização
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Após a Segunda Guerra Mundial, o basquete universitário aumentou em popularidade e qualidade. Novas estrelas surgiram, como Bill Russell, Wilt Chamberlain, Oscar Robertson e Kareem Abdul-Jabbar, que posteriormente também se destacaram na NBA. Novas competições e conferências foram formadas, como o National Invitation Tournament (NIT), a Atlantic Coast Conference (ACC) e a Southeastern Conference (SEC).
      <br/>
      <br/>
      Em 1957, ocorreu um dos jogos mais memoráveis ​​da história do basquete universitário quando o North Carolina Tar Heels derrotou o Kansas Jayhawks por 54-53 após três prorrogações. O jogo é considerado um ponto de virada no basquete universitário, mostrando o quão emocionante e dramático o jogo poderia ser.
      <br/>
      <br/>
      Outro jogo histórico ocorreu em 1966, quando o Texas Western Miners derrotou o Kentucky Wildcats por 72-65. O jogo foi inovador porque o Texas Western foi a primeira equipe a consistir inteiramente de jogadores negros, enquanto o Kentucky era totalmente branco. O jogo é visto como um símbolo da luta pelos direitos civis e integração na América.
      <br/>
      <br/>
      Em 1979, ocorreu a primeira transmissão ao vivo do torneio da NCAA, com um número recorde de telespectadores. A final foi entre Michigan State Spartans e Indiana State Sycamores, liderados pelos futuros ícones da NBA Magic Johnson e Larry Bird, respectivamente. Michigan State venceu o jogo por 75-64 e é considerado uma das melhores finais de todos os tempos.
      <br/>
      <br/>
      Nas décadas de 80 e 90, o basquete universitário continuou a crescer e evoluir, com novas regras, estilos e estratégias. Surgiram novas rivalidades, como Duke vs North Carolina, Georgetown vs Syracuse e UCLA vs Arizona. Também surgiram novas estrelas, como Michael Jordan, Patrick Ewing, Shaquille O'Neal e Tim Duncan, que elevaram o basquete universitário a um nível mais alto.
    </MKTypography>
  </MKBox>
</MKBox>













                                              





                                  








<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 2: Basquete Universitário Hoje
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O basquete universitário é um dos esportes mais populares e prestigiados na América hoje. Todos os anos, os jogos atraem milhões de fãs para estádios e telas de televisão. O basquete universitário também é uma importante fonte de talentos para a NBA, recrutando centenas de jogadores a cada ano das equipes universitárias.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Competições e Conferências Importantes
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Stephen Curry em ação pelo Davidson College, se destacando no basquete universitário."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Stephen Curry em ação como jogador do Davidson College
        </figcaption>
      </figure>
      O basquete universitário é primariamente organizado pela National Collegiate Athletic Association (NCAA), que supervisiona mais de 1.000 universidades e faculdades. A NCAA divide as equipes em três divisões, sendo a Divisão I a mais alta. A Divisão I consiste em 32 conferências, cada uma composta por equipes que são conectadas geograficamente ou historicamente.
      <br/>
      <br/>
      As conferências são importantes pois determinam quais equipes jogam entre si durante a temporada regular, que vai de novembro a março. Cada conferência tem seu próprio cronograma, regras e campeonato. Os campeões de conferência automaticamente ganham uma vaga no torneio da NCAA, o ápice do basquete universitário.
      <br/>
      <br/>
      O torneio da NCAA, também conhecido como March Madness, é um torneio eliminatório realizado todos os anos em março e abril. Ele consiste em 68 equipes, sendo 32 campeãs de conferências e 36 selecionadas por convite, escolhidas por um comitê com base em seu desempenho durante a temporada. As equipes são divididas em quatro regiões e classificadas de 1 a 16.
      <br/>
      <br/>
      O torneio começa com quatro jogos preliminares, onde as equipes com classificação mais baixa competem pelas últimas quatro vagas no torneio principal. O torneio principal consiste em seis rodadas, eliminando uma equipe em cada rodada até que reste apenas uma. As quatro últimas equipes, conhecidas como Final Four, jogam em um local neutro para determinar o campeão nacional.
      <br/>
      <br/>
      O torneio da NCAA é um dos eventos esportivos mais emocionantes e imprevisíveis do mundo. A cada ano, há surpresas, dramas e sensações, conhecidos como surpresas, cestas no estouro do cronômetro e histórias de Cinderela. O torneio atrai milhões de espectadores e apostadores, que tentam prever o vencedor.
      <br/>
      <br/>
      Além do torneio da NCAA, existem outras competições significativas no basquete universitário, como o NIT, CBI, CIT e o torneio da NAIA. Essas competições oferecem oportunidades para equipes que não se classificaram para o torneio da NCAA ou desejam ganhar experiência adicional.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programas de Elite e Equipes Prestigiadas
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O basquete universitário apresenta muitos programas de elite e equipes prestigiadas, distinguidas por sua história, tradição, sucesso e influência. Alguns desses programas são:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          UCLA Bruins: O programa mais bem-sucedido na história do basquete universitário, com 11 títulos nacionais, incluindo 10 sob o comando do técnico John Wooden nas décadas de 60 e 70. A UCLA produziu muitos jogadores famosos, como Kareem Abdul-Jabbar, Bill Walton e Reggie Miller.
        </li>
        <li>
          Kentucky Wildcats: O segundo programa mais bem-sucedido na história do basquete universitário, com oito títulos nacionais e 17 aparições no Final Four. Kentucky é conhecida por seus fervorosos fãs e sua capacidade de atrair os melhores recrutas.
        </li>
        <li>
          North Carolina Tar Heels: O terceiro programa mais bem-sucedido na história do basquete universitário, com seis títulos nacionais e 20 aparições no Final Four. A Carolina do Norte teve muitos técnicos lendários, como Dean Smith e Roy Williams, e jogadores como Michael Jordan, James Worthy e Vince Carter.
        </li>
        <li>
          Duke Blue Devils: Um dos programas mais dominantes no basquete universitário moderno, com cinco títulos nacionais e 16 aparições no Final Four desde 1986. Duke é conhecida por seu técnico Mike Krzyzewski, o técnico mais vitorioso na história do basquete universitário, e jogadores como Christian Laettner, Grant Hill e Zion Williamson.
        </li>
        <li>
          Kansas Jayhawks: Um dos programas mais tradicionais e influentes no basquete universitário, com três títulos nacionais e 15 aparições no Final Four. Kansas é o local de nascimento do jogo, já que James Naismith foi seu primeiro técnico. Kansas também teve muitos grandes técnicos, como Phog Allen, Larry Brown e Bill Self, e jogadores como Wilt Chamberlain, Danny Manning e Paul Pierce.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Esses programas são apenas alguns exemplos das muitas equipes excelentes que enriquecem o basquete universitário. Todos os anos, novos desafiantes e surpresas tornam o jogo emocionante e imprevisível.
    </MKTypography>
  </MKBox>
</MKBox>












                                              






                                  






<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 3: Da Faculdade para a NBA
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O basquete universitário não é apenas um esporte, mas também uma educação. Muitos jogadores que participam do basquete universitário têm como objetivo chegar à NBA, a melhor e mais prestigiada liga profissional de basquete do mundo. No entanto, a NBA não é fácil de alcançar e requer muito talento, trabalho árduo e sorte.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 O Caminho para a NBA
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Para ser elegível para a NBA, um jogador deve ter pelo menos 19 anos e ter se formado no ensino médio pelo menos um ano antes. Isso significa que a maioria dos jogadores que aspiram chegar à NBA primeiro vão para a faculdade e jogam pelo menos um ano. Isso também é conhecido como a regra do "one-and-done", porque muitos jogadores entram no draft da NBA após apenas um ano de faculdade.
      <br />
      <br />
      O draft da NBA é um evento anual em que as 30 equipes da NBA selecionam um jogador em uma piscina de jogadores disponíveis. O draft consiste em duas rodadas, com cada equipe tendo uma escolha por rodada. A ordem das escolhas é determinada por meio de um sorteio para as equipes que não se classificaram para os playoffs na temporada anterior e pelo ranking reverso para as equipes dos playoffs.
      <br />
      <br />
      O draft é um momento significativo para os jogadores, pois determina qual equipe detém seus direitos e quanto dinheiro podem ganhar. Os jogadores escolhidos na primeira rodada recebem um contrato garantido por pelo menos dois anos, enquanto aqueles selecionados na segunda rodada não têm garantias. Jogadores que não são escolhidos podem tentar garantir um contrato por meio da agência livre ou ingressando na G League, a liga de desenvolvimento da NBA.
      <br />
      <br />
      No entanto, o draft não é uma garantia de sucesso na NBA. Muitos jogadores altamente draftados não atendem às expectativas ou sofrem lesões. Por outro lado, também há muitos jogadores que são draftados em posições baixas ou nem mesmo são draftados, mas ainda se destacam na NBA. Um exemplo conhecido é Giannis Antetokounmpo, que foi selecionado em 15º lugar em 2013 e foi nomeado MVP e campeão da NBA em 2021.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Outras Oportunidades Profissionais
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Nem todos os jogadores que participam do basquete universitário têm como objetivo ir para a NBA. Alguns jogadores optam por concluir seus diplomas acadêmicos e seguir outras carreiras. Outros optam por jogar basquete profissional em outros países ou ligas.
      <br />
      <br />
      Existem muitas ligas profissionais de basquete ao redor do mundo, oferecendo diferentes níveis de qualidade, salário e estilo de vida. Algumas dessas ligas incluem:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          EuroLeague: A competição de clubes europeus mais alta e prestigiada, com 18 equipes de 10 países. A EuroLeague é considerada a segunda melhor liga do mundo após a NBA.
        </li>
        <li>
          Chinese Basketball Association (CBA): A competição de clubes chineses mais alta e popular, com 20 equipes de toda a China. A CBA é conhecida por seus altos salários e grandes audiências.
        </li>
        <li>
          Australian National Basketball League (NBL): A competição de clubes australianos mais alta e competitiva, com nove equipes da Austrália e da Nova Zelândia. A NBL é conhecida por seu jogo rápido e físico.
        </li>
        <li>
          Basketball Africa League (BAL): A competição de clubes africanos mais nova e ambiciosa, com 12 equipes de 12 países. A BAL é apoiada pela NBA e FIBA e tem como objetivo desenvolver o basquete no continente.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Essas ligas são apenas alguns exemplos das muitas oportunidades disponíveis para jogadores profissionais de basquete fora da NBA. Muitos jogadores gostam de viver e jogar em diferentes culturas enquanto continuam a seguir sua paixão pelo jogo.
    </MKTypography>
  </MKBox>
</MKBox>














                                              






                                  







<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 4: Influências e Oportunidades Internacionais
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O basquete é um esporte global que reúne pessoas de diferentes nacionalidades, culturas e origens. O basquete universitário é um exemplo claro disso, pois atrai tanto jogadores estrangeiros quanto americanos, oferecendo oportunidades. Neste capítulo, exploraremos as influências e oportunidades internacionais no basquete universitário.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Jogadores Estrangeiros no Basquete Universitário
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Jogo de basquete NCAA Final Four com uma multidão de quase 80.000 fãs no estádio."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
         }}>
          Quase 80.000 espectadores durante uma final nacional
        </figcaption>
      </figure>
      O basquete universitário sempre recebeu bem os jogadores estrangeiros, que contribuíram com seu próprio estilo, habilidades e perspectivas para o jogo. De acordo com a NCAA, na temporada 2019-2020, havia mais de 1.200 jogadores estrangeiros na Divisão I, vindos de 101 países. Os países de origem mais comuns foram Canadá, Austrália, Nigéria, França e Reino Unido.
      <br />
      <br />
      Os jogadores estrangeiros escolhem o basquete universitário por várias razões. Alguns desejam combinar sua educação acadêmica com suas ambições atléticas. Outros têm como objetivo se adaptar à cultura e ao estilo de vida americanos. Ainda outros buscam aumentar suas chances de chegar à NBA ou representar suas seleções nacionais.
      <br />
      <br />
      Os jogadores estrangeiros tiveram um impacto significativo no basquete universitário, tanto individualmente quanto coletivamente. Alguns dos jogadores estrangeiros mais bem-sucedidos e influentes na história do basquete universitário incluem:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Hakeem Olajuwon: Um jogador nigeriano que jogou pelo Houston Cougars de 1981 a 1984. Ele liderou sua equipe para três aparições consecutivas no Final Four e ganhou o prêmio de jogador nacional do ano em 1984. Ele foi a primeira escolha geral no draft da NBA de 1984 e se tornou um dos maiores pivôs de todos os tempos.
        </li>
        <li>
          Andrew Bogut: Um jogador australiano que jogou pelo Utah Utes de 2003 a 2005. Ele ganhou o prêmio de jogador nacional do ano em 2005 e levou sua equipe até o Sweet Sixteen. Ele foi a primeira escolha geral no draft da NBA de 2005 e se tornou um All-Star e campeão.
        </li>
        <li>
          Luka Garza: Um jogador americano com raízes bósnias que jogou pelo Iowa Hawkeyes de 2017 a 2021. Ele ganhou o prêmio de jogador nacional do ano duas vezes, em 2020 e 2021, e se tornou o maior pontuador de todos os tempos de sua universidade. Ele foi selecionado como 52ª escolha geral no draft da NBA de 2021 e é considerado um dos maiores jogadores universitários de todos os tempos.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estes são apenas alguns exemplos dos muitos jogadores estrangeiros que se destacaram no basquete universitário. A cada ano, novos talentos surgem, enriquecendo o jogo.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Jogadores Americanos no Exterior
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Não apenas jogadores estrangeiros vêm para a América para estudar e jogar basquete, mas jogadores americanos também vão para o exterior pelos mesmos motivos. Alguns jogadores americanos optam por pular ou encurtar suas carreiras no ensino médio ou na faculdade para jogar profissionalmente em outros países ou ligas.
      <br />
      <br />
      Os motivos para essa escolha podem variar, mas incluem alguns como:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Financeiro: Alguns jogadores querem ganhar dinheiro com seu talento em vez de esperar por um contrato na NBA ou uma bolsa de estudos.
        </li>
        <li>
          Atlético: Alguns jogadores querem se desenvolver contra oponentes mais fortes em vez de dominar em seu nível.
        </li>
        <li>
          Pessoal: Alguns jogadores querem viajar, aprender novas culturas ou manter laços familiares.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Jogadores americanos que vão para o exterior têm experiências e resultados diferentes. Alguns têm sucesso em realizar seu sonho de NBA, enquanto outros desfrutam de uma carreira longa e bem-sucedida. Alguns se adaptam bem ao novo ambiente, enquanto outros têm dificuldades com idioma, comida ou estilo de vida.
      <br />
      <br />
      Alguns exemplos de jogadores americanos que foram para o exterior incluem:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Brandon Jennings: Um jogador que pulou o ensino médio para jogar pelo Lottomatica Roma na Itália em 2008. Ele foi a 10ª escolha geral no draft da NBA de 2009 e jogou por 10 temporadas na NBA.
        </li>
        <li>
          Emmanuel Mudiay: Um jogador que pulou a faculdade para jogar pelo Guangdong Southern Tigers na China em 2014. Ele foi a 7ª escolha geral no draft da NBA de 2015 e atualmente joga pelo Utah Jazz.
        </li>
        <li>
          LaMelo Ball: Um jogador que pulou o ensino médio e a faculdade para jogar pelo Illawarra Hawks na Austrália em 2019. Ele foi a 3ª escolha geral no draft da NBA de 2020 e ganhou o prêmio de Novato do Ano.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estes são apenas alguns exemplos dos muitos jogadores americanos que escolheram um caminho alternativo para o basquete profissional. A cada ano, novos aventureiros globalizam o jogo.
    </MKTypography>
  </MKBox>
</MKBox>











                                              






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    As bolsas de estudo esportivas oferecem inúmeras oportunidades para jovens atletas mostrarem seu talento e desenvolverem suas habilidades. Uma das oportunidades mais significativas é obter uma bolsa de estudos esportiva para estudar e competir no nível universitário. Neste capítulo, discutiremos a importância das bolsas de estudo esportivas e como a Sport Scholarships America pode ajudá-lo a realizar seu sonho.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 A Importância das Bolsas de Estudo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Uma bolsa de estudos esportiva é um suporte financeiro fornecido por uma universidade a um estudante-atleta para combinar estudos e esportes. Pode cobrir os custos de mensalidades, livros, moradia, refeições e outras despesas. Além disso, pode oferecer benefícios como tutoria acadêmica, cuidados médicos, instalações de treinamento e equipamentos.
      <br />
      <br />
      Obter uma bolsa de estudos esportiva é competitivo, com muitos fatores desempenhando um papel no processo de concessão, incluindo realizações acadêmicas, desempenho atlético, personalidade, motivação, liderança e adaptabilidade. Portanto, é importante se preparar bem e se destacar da concorrência.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Como a Sport Scholarships America Pode Ajudar
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo da Sport Scholarships America, mostrando equipamentos esportivos e um capelo de formatura, representando o foco em bolsas de estudo esportivas para estudantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo da Sport Scholarships America
        </figcaption>
      </figure>
      A Sport Scholarships America é uma organização especializada em orientar jovens talentos esportivos para bolsas de estudo esportivas. Com especialistas apaixonados e experientes, a Sport Scholarships America apoia estudantes-atletas em cada etapa da jornada, desde a orientação inicial até a colocação final. Seus serviços incluem:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Uma entrevista de admissão pessoal para discutir os desejos, possibilidades e expectativas do estudante-atleta.</li>
        <li>Uma avaliação profissional do nível acadêmico e atlético do estudante-atleta.</li>
        <li>Uma extensa rede de contatos com treinadores, universidades e faculdades.</li>
        <li>Promoção eficaz do perfil e vídeos do estudante-atleta para treinadores potenciais.</li>
        <li>Conselhos especializados sobre as melhores opções com base nas preferências, metas e orçamento do estudante-atleta.</li>
        <li>Negociação das melhores ofertas de bolsas de estudo em nome do estudante-atleta.</li>
        <li>Auxílio na solicitação de documentos necessários, como visto, histórico escolar, diploma e testes de admissão.</li>
        <li>Preparação para a vida do estudante-atleta durante a bolsa de estudos, incluindo cultura, idioma, regras e dicas.</li>
        <li>Acompanhamento e acompanhamento durante o período da bolsa de estudos.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Quer explorar oportunidades de bolsas de estudo e alcançar seus objetivos atléticos e acadêmicos? <Link to="/pt/Serviços-Produtos">Veja a gama completa</Link> de serviços oferecidos pela Sport Scholarships America e agende uma consulta hoje.
    </MKTypography>
  </MKBox>
</MKBox>


          










        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;