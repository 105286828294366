/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================
* Pagina del Prodotto: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
Codificato da www.creative-tim.com
=========================================================
*/

// Componenti materiali @mui
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Componenti Material Kit 2 PRO React
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Immagini
import post2 from "assets/images/TijdUS.png";
import "../../../../H1asH3.css"

function Profilo() {
  return (
    <MKBox componente="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox 
                display="flex" 
                flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} 
                justifyContent="space-between" 
                alignItems="center" 
                mb={1}
              >
                <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Parte 3: Tornando in Olanda</MKTypography>
              </MKBox>
              <MKTypography variant="body1" fontWeight="light" color="text">
                <br />
                Al mio ritorno in Olanda, ho vissuto una miscela di nostalgia e determinazione. La mia profonda passione per il calcio era ora affiancata da un rinnovato desiderio di sfide accademiche. Amsterdam è diventata lo sfondo per la mia prossima avventura, dove ho conseguito una laurea magistrale in Finanza Quantitativa, un campo che stimolava sia la mia mente analitica che le mie ambizioni.
                <br /><br />
                Dopo aver completato gli studi, ho iniziato a lavorare come consulente. Sebbene il mondo dei numeri e dell'analisi mi affascinasse, sentivo che mancava qualcosa. Gli echi del mio viaggio in America continuavano a risuonare in me, ricordandomi dell'opportunità unica che avevo vissuto, un'opportunità che desideravo per gli altri.
                <br /><br />
                Di conseguenza, ho deciso di utilizzare le mie esperienze e conoscenze per guidare altri giovani atleti ambiziosi. Prima di lanciare questa impresa, ho interagito con allenatori, atleti, ex atleti e altri membri della mia vasta rete in America. Queste discussioni hanno aiutato sia me che gli studenti futuri a capire il miglior approccio a queste opportunità.
                <br /><br />
                Durante la sfida della pandemia COVID-19, ho collaborato con un'agenzia leader in America. Sebbene avessi potuto assistere meno studenti internazionali a causa della limitata disponibilità di borse di studio, ho utilizzato la mia esperienza per aiutare gli studenti americani. Questa esperienza non solo ha notevolmente ampliato la mia rete, ma ha anche fornito informazioni sul mercato delle borse di studio americane, che ora posso applicare in modo più efficace in Europa.
                <br /><br />
                Le sfide durante la pandemia hanno rafforzato la mia missione di guidare giovani talenti verso università americane e borse di studio sportive. Nonostante le difficoltà, ha portato un senso di realizzazione che non avevo mai provato prima. Era il mio modo di restituire alla comunità che mi aveva accolto calorosamente negli Stati Uniti.
                <br /><br />
                Questa è la mia storia, e sono fiero di poter ora aiutare gli altri a scrivere le proprie storie nella terra delle infinite possibilità. Se desideri saperne di più sulla mia azienda e sul viaggio dei miei clienti nel realizzare i loro sogni, continua a leggere sul mio sito web. Se hai domande o desideri contattarmi, non esitare a farlo.
              </MKTypography>
              <hr style={{ margin: '20px 0' }} />
              <Grid item xs={12} lg={6} mb={{ xs: 3, lg: 0 }}>
                <TransparentBlogCard
                  image={post2}
                  title="Parte 2: Il Mio Tempo in America"
                  action={{
                    type: "internal",
                    route: "/it/Chi-sono/Il-mio-tempo-in-America",
                    color: "info",
                    label: "Leggi di più",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profilo;
