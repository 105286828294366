/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Produktseite: https://www.creative-tim.com/product/material-kit-pro-react
* Urheberrecht 2023 Creative Tim (https://www.creative-tim.com)

Programmiert von www.creative-tim.com

 =========================================================

* Der obige Urheberrechtsvermerk und dieser Genehmigungsvermerk müssen in allen Kopien oder wesentlichen Teilen der Software enthalten sein.
*/

import { useState } from "react";

// @mui Material-Komponenten
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React-Komponenten
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton"; // MKButton importieren
import { Link } from 'react-router-dom';

// Komponenten für die Seite zur Vermietung
import FaqCollapse from "Languages/Deutsch/pages/Homepage/LandingPage/sections/FAQfunction";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <MKBox component="section" py={8}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
              Häufig gestellte Fragen
            </MKTypography>
            <MKBox mb={2}>
              <MKTypography variant="body2" align="center" color="text">
                Alles, was Sie wissen möchten: Antworten auf die am häufigsten gestellten Fragen zum Studium und Sport in Amerika und meinen Dienstleistungen. Wenn Ihre Frage nicht aufgeführt ist, kontaktieren Sie uns bitte.
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={10}>
          <FaqCollapse
   title="Wie viel kostet das Studium in Amerika?"
   open={collapse === 1}
   onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
>
   Die Studiengebühren und die Kosten für das Studium in Amerika variieren stark je nach Universität, Studiengang und Standort. Im Durchschnitt können internationale Studierende mit jährlichen Gesamtausgaben von etwa 30.000 bis zu 70.000 US-Dollar oder mehr rechnen. Es ist jedoch erwähnenswert, dass viele Studierende einen erheblichen Teil (oder manchmal die gesamten) dieser Kosten durch sportliche Stipendien, akademische Stipendien oder eine Kombination aus beidem abdecken können. Amerikanische Universitäten bieten oft sportliche Stipendien für talentierte Sportler an, die finanzielle Unterstützung im Austausch für ihre Teilnahme an Collegiate-Sportprogrammen bieten. Dies ermöglicht es Studenten-Athleten, ihre akademischen und sportlichen Ziele ohne erhebliche finanzielle Belastungen zu verfolgen.
</FaqCollapse>

<FaqCollapse
   title="Was ist der Unterschied zwischen einem College und einer Universität in Amerika?"
   open={collapse === 2}
   onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
>
   Die Terminologie kann verwirrend sein, insbesondere für Menschen außerhalb der Vereinigten Staaten. In der Praxis beziehen sich "College" und "Universität" tatsächlich nicht auf dasselbe. Eine "Universität" ist ein umfassender Begriff für eine Hochschuleinrichtung, die Programme auf allen Ebenen anbietet, einschließlich Bachelor-, Master- und Doktorstudien.

   Ein "College" hingegen ist in der Regel ein Teil einer Universität und konzentriert sich hauptsächlich auf grundständige Programme wie Bachelor-Abschlüsse. Mit anderen Worten, ein College ist eine Abteilung innerhalb einer Universität, die speziell der grundständigen Ausbildung gewidmet ist. Universitäten bieten in der Regel sowohl grundständige als auch postgraduale Programme an, während Colleges sich auf die grundständige Ebene konzentrieren.

   Diese Unterscheidung kann jedoch zwischen verschiedenen Einrichtungen variieren, und manchmal werden die Begriffe austauschbar verwendet. Daher ist es hilfreich, die spezifischen Details und Programme einer Bildungseinrichtung zu prüfen, um zu verstehen, was sie anbieten.
</FaqCollapse>

<FaqCollapse
   title="Welche Sportler werden von Sport Scholarships America betreut?"
   open={collapse === 3}
   onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
>
   Ich betreue Sportler aus einer Vielzahl von Sportarten. Mein starkes Netzwerk und meine Partnerschaften in Amerika ermöglichen es mir, Sportler in verschiedenen Disziplinen zu unterstützen. Egal, ob Sie Fußball, Basketball, Tennis, Golf oder eine andere Sportart spielen, ich stehe Ihnen zur Verfügung, um Ihre akademischen und sportlichen Ziele in den Vereinigten Staaten zu verfolgen. Für eine vollständige Liste der Sportarten, <Link to="/de/Alle-Sportarten" style={{ fontWeight: 'bold' }}>klicken Sie hier</Link>.
</FaqCollapse>

<FaqCollapse
   title="Wie verläuft der typische Bewerbungsprozess, und wie weit im Voraus sollte ich beginnen?"
   open={collapse === 4}
   onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
>
   Der Prozess beginnt in der Regel mit einem umfassenden Einführungsgespräch, in dem wir alle Details besprechen und Ihre spezifische Situation und Ziele verstehen. Ich werde auch meine Vorgehensweise weiter erläutern. Die Dauer des gesamten Prozesses kann variieren, aber im Allgemeinen empfehle ich, mindestens 6 bis 12 Monate im Voraus zu beginnen. Es ist wichtig zu betonen, dass je früher Sie beginnen, desto mehr Chancen können sich ergeben, daher zögern Sie nicht, den Prozess frühzeitig zu starten.

   In dieser Zeit werden wir zusammenarbeiten, um einen personalisierten Plan zu erstellen, der alle Aspekte berücksichtigt, wie Zulassungstests, Visaanträge, Erstellung von Sportvideos und mehr. Jede Reise ist einzigartig und maßgeschneidert, da es viele Faktoren zu berücksichtigen gibt. Für eine detaillierte Übersicht über den gesamten Prozess können Sie <Link to="/de/Prozess" style={{ fontWeight: 'bold' }}>hier klicken</Link>. Dies bietet einen schrittweisen Einblick in das, was Sie während Ihrer Reise zum Studium und Wettbewerb in den Vereinigten Staaten erwartet.
</FaqCollapse>

<FaqCollapse
   title="Was sind die Gebühren für die von Ihnen angebotenen Dienstleistungen?"
   open={collapse === 5}
   onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
>
   Die Kosten für meine Dienstleistungen variieren je nach Ihren spezifischen Bedürfnissen und Zielen. Das Einführungsgespräch ist ein wichtiger Schritt, bei dem wir Ihre Situation besprechen und einen maßgeschneiderten Plan entwickeln. Es ist auch die Gelegenheit zu bestimmen, ob wir die Zusammenarbeit fortsetzen möchten.

   Nach diesem Gespräch werde ich in der Lage sein, ein klares Verständnis Ihrer Wünsche und Bedürfnisse zu haben, und auf dieser Grundlage kann ich Ihnen eine angemessene Kostenschätzung geben.

   Für einen vollständigen Überblick über die von mir angebotenen Dienstleistungen und Produkte können Sie <Link to="/de/Dienstleistungen-Produkte" style={{ fontWeight: 'bold' }}>hier klicken</Link>. Hier finden Sie ausführliche Informationen darüber, wie ich Ihnen bei der Verfolgung Ihres Studien- und Wettbewerbsziels in den Vereinigten Staaten helfen kann.
</FaqCollapse>

<FaqCollapse
   title="Warum sollte ich mich von Sport Scholarships America beraten lassen?"
   open={collapse === 6}
   onClick={() => (collapse === 6 ? setCollapse(false) : setCollapse(6))}
>
   Wenn Sie an einem Wendepunkt stehen, um Ihre Träume zu verwirklichen, wie ich es einmal war, können viele Fragen und Zweifel aufkommen. Die Wahl, es selbst zu tun, mit einer Agentur zusammenzuarbeiten oder die ganze Idee aufzugeben, kann überwältigend sein. Ich verstehe diese Unsicherheit tief, weil ich selbst durch sie gegangen bin.

   Es wäre schade, wenn diese Zweifel jemanden daran hindern würden, zu verfolgen, was wirklich möglich ist - eine fantastische Erfahrung, die darauf wartet, entdeckt zu werden. Deshalb widme ich mich dazu, anderen dabei zu helfen, ihre Träume zu verwirklichen.

   Ich setze meine persönlichen Erfahrungen in die Praxis um, indem ich direkt an jedem Projekt beteiligt bin. Ich verstehe die Fragen, die Sie haben könnten, und bemühe mich, einen personalisierten Ansatz anzubieten, der sich mit Ihren einzigartigen Bedürfnissen in Einklang bringt. Zusammen können wir Ihre Vision zum Leben erwecken, gestützt auf das Wissen und das Verständnis, das aus echten, gelebten Erfahrungen stammt.
</FaqCollapse>

          </Grid>
        </Grid>
        {/* Button-Bereich */}
        <Grid container justifyContent="center" mt={4}>
          <Grid item xs={12} md={3}>
          <MKButton variant="gradient" color="light" fullWidth component={Link} to="/de/FAQs">
            Vollständige FAQ anzeigen
          </MKButton>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Faq;
