/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Gymnastics1.jpeg";
import Image2 from "assets/images/Sports_Images/Gymnastics2.jpg";
import Image3 from "assets/images/Sports_Images/Gymnastics3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Ginástica e Estudos nos Estados Unidos: Uma Oportunidade Única para Jovens Ginastas', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capítulo 1: História e Desenvolvimento da Ginástica na América', isSubheader: false },
    { id: '1.1', title: '1.1 Primeiros Anos e o Papel das Universidades', isSubheader: true },
    { id: '1.2', title: '1.2 Crescimento e Profissionalização', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capítulo 2: Ginástica Universitária Hoje', isSubheader: false },
    { id: '2.1', title: '2.1 Principais Competições e Conferências', isSubheader: true },
    { id: '2.2', title: '2.2 Programas Principais e Equipes Prestigiadas', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capítulo 3: Do College para os Profissionais', isSubheader: false },
    { id: '3.1', title: '3.1 O Caminho para o Profissionalismo', isSubheader: true },
    { id: '3.2', title: '3.2 Carreiras e Oportunidades Profissionais', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capítulo 4: Influências Internacionais', isSubheader: false },
    { id: '4.1', title: '4.1 Influências Estrangeiras e Intercâmbio', isSubheader: true },
    { id: '4.2', title: '4.2 Americanos no Exterior', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 A Importância das Bolsas de Estudo', isSubheader: true },
    { id: '5.2', title: '5.2 Como a Sport Scholarships America Pode Ajudar', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Conteúdo
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Esconder</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Ginástica e Estudos nos Estados Unidos: Uma Oportunidade Única para Jovens Ginastas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Vista das arquibancadas em uma competição de ginástica universitária em um grande estádio."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Uma grande arena durante uma competição de ginástica universitária da NCAA
      </figcaption>
    </figure>
    A ginástica tem uma longa e rica história nos Estados Unidos. Desde o século XIX, a ginástica é praticada em universidades, onde os ginastas desenvolvem suas habilidades e mostram seus talentos. A ginástica não é apenas um esporte; é também uma forma de arte, uma ciência e um estilo de vida. Os ginastas aprendem disciplina, perseverança, criatividade e trabalho em equipe. Eles treinam duro, mas também se divertem muito.
    <br />
    <br />
    Competir na ginástica no nível universitário é uma oportunidade única para jovens ginastas realizarem seus sonhos. Ao obter uma bolsa de estudos esportiva, você pode estudar em uma universidade de prestígio enquanto participa de competições emocionantes contra outros ginastas de alto nível. Você se torna parte de uma comunidade unida de estudantes-atletas que se apoiam e se motivam mutuamente. Você tem acesso às melhores instalações, treinadores e mentores que o ajudam a alcançar seu potencial. E você ganha uma experiência inesquecível que o prepara para sua futura carreira.
    <br />
    <br />
    Mas como você se qualifica para uma bolsa de estudos esportiva? Quais são os requisitos e benefícios? Como encontrar a universidade certa para você? E como é a vida de um estudante-ginasta? Essas são todas perguntas que o Sportbeursamerika.nl pode responder para você. O Sportbeursamerika.nl é uma plataforma que ajuda jovens ginastas a encontrarem seu caminho para a América. Ofereço orientação pessoal, conselhos e apoio durante todo o processo de inscrição, seleção, preparação e partida. Tenho conexões com centenas de universidades e treinadores que procuram talento na ginástica. Conheço os pormenores do sistema educacional americano e da ginástica universitária. E tenho experiência pessoal com esportes e estudos na América.
    <br />
    <br />
    Você está curioso sobre as oportunidades de combinar ginástica e estudos na América? Continue lendo e descubra tudo o que você precisa saber sobre essa oportunidade única.
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>

<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 1: História e Desenvolvimento da Ginástica na América
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    A ginástica é um dos esportes mais antigos do mundo, com suas origens em civilizações antigas como Grécia, Roma e China. A ginástica era usada como meio de treinar o corpo, aguçar a mente e elevar a alma. Também era vista como uma forma de expressão, arte e beleza.
    <br />
    <br />
    Nos Estados Unidos, a ginástica começou a se desenvolver no século XIX, quando imigrantes europeus trouxeram seu conhecimento e habilidades. A ginástica rapidamente se tornou popular em escolas, clubes e associações, onde era vista como uma maneira de promover saúde, caráter e cidadania. A ginástica também se tornou uma fonte de orgulho nacional, especialmente após a Guerra da Independência.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Primeiros Anos e o Papel das Universidades
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      As primeiras equipes universitárias de ginástica foram formadas no final do século XIX, quando os estudantes estabeleceram seus próprios clubes e organizaram competições intercolegiais. A primeira competição oficial de ginástica universitária ocorreu em 1897, quando a Universidade de Yale enfrentou o Springfield College. O Springfield College é considerado o berço da ginástica americana, pois foi a primeira escola a oferecer ginástica como disciplina acadêmica. Muitos treinadores e líderes renomados de ginástica estudaram ou lecionaram no Springfield College.
      <br />
      <br />
      No início do século XX, a ginástica universitária continuou a crescer com o estabelecimento de organizações regionais e nacionais como a Eastern Intercollegiate Gymnastics League (EIGL) em 1902 e a National Collegiate Athletic Association (NCAA) em 1906. A NCAA começou a organizar campeonatos nacionais para homens em 1938 e para mulheres em 1982. A NCAA continua sendo a principal organização para a ginástica universitária na América.
    </MKTypography>
  </MKBox>

  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crescimento e Profissionalização
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A popularidade e o nível da ginástica universitária aumentaram após a Segunda Guerra Mundial, quando muitos veteranos voltaram para as universidades e perseguiram seus objetivos atléticos. A influência de treinadores e atletas europeus, que vieram para a América para escapar do comunismo ou do nazismo, também contribuiu para o desenvolvimento da ginástica universitária. Um desses personagens foi George Gulack, um ginasta húngaro que conquistou ouro nas Olimpíadas de 1936 em Berlim. Gulack desempenhou um papel significativo no comitê olímpico e nas organizações de ginástica americanas e internacionais, servindo no Comitê Olímpico Americano de 1934 a 1958.
      <br />
      <br />
      Na década de 1950 e 60, a ginástica profissional começou a se diferenciar da ginástica universitária, com o surgimento de estrelas internacionais como Larisa Latynina, Olga Korbut e Nadia Comaneci. Essas ginastas inspiraram muitos jovens americanos a se juntarem a clubes privados e focarem em conquistas individuais. Uma delas foi Cathy Rigby, que se tornou a primeira mulher americana a ganhar uma medalha em um campeonato mundial em 1970. Ela posteriormente se tornou uma atriz de grande sucesso.
      <br />
      <br />
      A ginástica profissional atingiu seu auge em 1978, quando o ginasta americano Kurt Thomas se tornou o primeiro homem a ganhar uma medalha de ouro em um campeonato mundial. Ele estudou na Universidade Estadual de Indiana e depois se tornou ator. No mesmo ano, Mary Lou Retton fez sua estreia no cenário nacional. Ela se tornou a primeira mulher americana a ganhar ouro no all-around nas Olimpíadas de 1984.
      <br />
      <br />
      A ginástica universitária, no entanto, permaneceu relevante e influente, especialmente para as mulheres. Muitas ginastas femininas optaram por continuar suas carreiras no nível universitário porque recebiam mais oportunidades e benefícios lá do que no circuito profissional.
      <br />
      <br />
      A ginástica universitária, portanto, desempenhou um papel significativo na história e no desenvolvimento da ginástica na América. Produziu muitos atletas que se destacaram não apenas em seu esporte, mas também em seus estudos e carreiras posteriores. Também contribuiu para a disseminação e promoção da ginástica como um esporte para todos, independentemente de idade, gênero ou origem. A ginástica universitária ainda é um esporte vibrante e dinâmico que atrai milhares de fãs e espectadores todos os anos.
    </MKTypography>
  </MKBox>
</MKBox>
                                   





                                  






<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 2: Ginástica Universitária Hoje
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    A ginástica é um dos esportes mais populares e competitivos no nível universitário nos Estados Unidos. A cada ano, centenas de equipes e milhares de atletas competem pelos títulos e troféus cobiçados. A ginástica universitária oferece uma combinação única de atletismo, espetáculo e excelência acadêmica. É um esporte que exige muito, mas também recompensa muito.
  </MKTypography>
  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Principais Competições e Conferências
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Ginasta da Auburn executando uma rotina de solo durante uma competição universitária."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Uma ginasta da Auburn em ação
        </figcaption>
      </figure>
      A ginástica universitária é governada pela NCAA, que estabelece as regras, qualificações e campeonatos. A NCAA divide a ginástica universitária em três divisões com base no número de bolsas de estudo, orçamento e nível da equipe. A Divisão I é a divisão mais alta, com a maioria dos principais ginastas e equipes participando. A Divisão II e III são divisões mais baixas com menos bolsas de estudo oferecidas e mais ênfase no desempenho acadêmico.
      <br />
      <br />
      Dentro de cada divisão, existem diferentes conferências que formam grupos regionais ou temáticos de universidades. As conferências organizam suas próprias competições e campeonatos, que servem como preliminares para os campeonatos nacionais. As principais conferências de ginástica universitária são:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          A Conferência Big Ten, composta por 14 universidades no Centro-Oeste e Nordeste dos Estados Unidos. A Big Ten é uma das conferências mais antigas e prestigiadas, conhecida por sua excelência acadêmica e atlética. A Big Ten tem alguns dos melhores programas de ginástica masculina e feminina, incluindo Michigan, Minnesota, Nebraska e Penn State.
        </li>
        <li>
          A Conferência do Sudeste (SEC), composta por 14 universidades no Sudeste dos Estados Unidos. O SEC é uma das conferências mais dominantes e influentes, conhecida por seus fãs apaixonados e leais. O SEC tem alguns dos melhores programas de ginástica feminina, como Alabama, Florida, Georgia e LSU.
        </li>
        <li>
          A Conferência Pac-12, composta por 12 universidades no Oeste dos Estados Unidos. O Pac-12 é uma das conferências mais inovadoras e diversas, conhecida por sua criatividade e cultura. O Pac-12 tem alguns dos melhores programas de ginástica masculina e feminina, incluindo California, Stanford, UCLA e Utah.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Os campeonatos nacionais são o destaque da temporada de ginástica universitária, onde as melhores equipes e indivíduos competem por honra e glória. Os campeonatos nacionais são realizados todos os anos em abril em vários locais por todo o país. Os campeonatos nacionais consistem em duas rodadas: os campeonatos regionais e as finais nacionais. Os campeonatos regionais são as rodadas classificatórias, onde 36 equipes para homens e 36 equipes para mulheres são divididas em seis regiões. As duas melhores equipes de cada região avançam para as finais nacionais. As finais nacionais são as rodadas decisivas, onde 12 equipes para homens e 12 equipes para mulheres são divididas em duas sessões. As quatro melhores equipes de cada sessão avançam para a superfinal (Super Six), onde competem pelo título de campeão nacional por equipes. Além disso, são concedidos títulos individuais para a competição geral e finais por aparelhos.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programas Principais e Equipes Prestigiadas
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A ginástica universitária produziu vários programas principais distinguíveis por sua consistência, qualidade e tradição. Esses programas conquistaram múltiplos títulos nacionais, treinaram muitos atletas talentosos e atraíram numerosos fãs. Alguns desses programas incluem:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          UCLA Bruins: O programa de ginástica da UCLA é um dos programas mais bem-sucedidos e respeitados do país. O programa de ginástica da UCLA ganhou mais de 20 títulos nacionais, incluindo vários títulos para homens e a maioria para mulheres. O programa também produziu numerosos campeões olímpicos e mundiais, como Peter Vidmar, Tim Daggett, Jamie Dantzscher, Mohini Bhardwaj, Tasha Schwikert, Samantha Peszek e Kyla Ross.
        </li>
        <li>
          Oklahoma Sooners: O programa de ginástica de Oklahoma é um dos programas mais dominantes e impressionantes do país. O programa de ginástica de Oklahoma ganhou mais de 10 títulos nacionais, incluindo múltiplos títulos para homens e quase tantos para mulheres. O programa também produziu muitos campeões da NCAA, incluindo Jonathan Horton, Jake Dalton, Maggie Nichols, Brenna Dowell e Anastasia Webb.
        </li>
        <li>
          Utah Utes: O programa de ginástica de Utah é um dos programas mais históricos e leais do país. O programa de ginástica de Utah ganhou mais de 10 títulos nacionais, todos para mulheres. O programa também produziu treinadores e atletas lendários, incluindo Greg Marsden, Megan Marsden, Missy Marlowe, Theresa Kulikowski, Ashley Postell e MyKayla Skinner.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A ginástica universitária oferece uma oportunidade única para jovens ginastas praticarem seu esporte no mais alto nível enquanto recebem uma educação valiosa. A ginástica universitária é um esporte que oferece muitos desafios e recompensas, tanto no solo quanto fora dele. A ginástica universitária é um esporte que pode mudar sua vida.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  








<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 3: Do College para os Profissionais
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    A ginástica é um esporte que requer muito talento, dedicação e perseverança. Os ginastas frequentemente começam a treinar desde jovens e sonham com uma carreira profissional. Mas como fazer a transição da ginástica universitária para a ginástica profissional? E quais são as oportunidades e desafios que aguardam você?
  </MKTypography>
  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 O Caminho para o Profissionalismo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A ginástica universitária é uma excelente preparação para a ginástica profissional, pois oferece a oportunidade de melhorar suas habilidades, adquirir experiência e expandir sua rede de contatos. Muitos ginastas universitários já participaram de competições profissionais, como os Jogos Olímpicos, campeonatos mundiais e a Copa do Mundo. Alguns deles até ganharam medalhas.
      <br />
      <br />
      No entanto, para participar de competições profissionais, os ginastas universitários devem cumprir certas regras e condições. Eles devem manter seu status de amador, o que significa que não podem ganhar dinheiro com seu esporte. Eles também não têm permissão para ter patrocinadores ou agentes, a menos que recebam permissão da NCAA. Eles também devem atender aos requisitos acadêmicos de sua universidade, o que às vezes pode ser desafiador de conciliar com sua agenda de treinamento.
      <br />
      <br />
      Além disso, os ginastas universitários também devem considerar sua idade e condição física. A ginástica é um esporte que exige muito do corpo e muitas vezes leva a lesões. Muitos ginastas atingem o auge por volta dos vinte anos e podem ter dificuldade em manter seu nível posteriormente. Alguns ginastas optam por desistir de seus objetivos profissionais e se concentrar em seus estudos ou carreiras fora do esporte.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Carreiras e Oportunidades Profissionais
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Para aqueles que decidem continuar com a ginástica profissional, existem diversas oportunidades e chances de seguir sua paixão. Uma das opções mais prestigiosas e lucrativas é competir nos Jogos Olímpicos, realizados a cada quatro anos. As Olimpíadas são o ápice de qualquer carreira esportiva, oferecendo a chance de representar seu país, competir contra os melhores ginastas do mundo e ganhar uma medalha. As Olimpíadas também trazem fama e reconhecimento que podem levar a contratos de patrocínio, atenção da mídia e outros benefícios.
      <br />
      <br />
      Outra opção é participar dos campeonatos mundiais, realizados anualmente (exceto nos anos olímpicos). Os campeonatos mundiais são um marco significativo para o nível e desenvolvimento da ginástica em todo o mundo. Os campeonatos mundiais também oferecem a oportunidade de se classificar para as Olimpíadas ou receber uma entrada de convite. Os campeonatos mundiais são uma fonte de orgulho e prestígio que podem levar a prêmios em dinheiro, bolsas de estudo e outras recompensas.
      <br />
      <br />
      Uma terceira opção é competir na Copa do Mundo, que consiste em uma série de competições individuais realizadas ao redor do mundo. A Copa do Mundo é uma opção atraente para ginastas que buscam mais flexibilidade e variedade em sua carreira esportiva. A Copa do Mundo também oferece a chance de explorar diferentes países e culturas, fazer novos amigos e expandir seus horizontes. A Copa do Mundo também é uma fonte de diversão e aventura que pode levar a reembolsos de viagem, bônus e outros incentivos.
      <br />
      <br />
      Além dessas competições internacionais, existem outras oportunidades profissionais para ginastas, como:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Realizar clínicas, workshops ou demonstrações onde você compartilha seu conhecimento e experiência com outros ginastas, treinadores ou fãs.
        </li>
        <li>
          Trabalhar como treinador, instrutor ou consultor, ajudando outros ginastas a alcançar seus objetivos, melhorar sua técnica ou resolver seus problemas.
        </li>
        <li>
          Trabalhar como comentarista, analista ou jornalista, oferecendo sua opinião e visão sobre ginástica, competições ou eventos atuais.
        </li>
        <li>
          Trabalhar como modelo, ator ou influenciador, usando sua aparência, personalidade ou popularidade para promover produtos, serviços ou marcas.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A ginástica profissional é uma carreira emocionante e desafiadora que oferece muitas possibilidades e oportunidades. No entanto, também é uma carreira exigente e incerta que vem com riscos e obstáculos. Escolher uma carreira na ginástica profissional requer tanto o coração quanto a cabeça.
    </MKTypography>
  </MKBox>
</MKBox>
                                 






                                  





<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 4: Influências Internacionais
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    A ginástica é um esporte sem fronteiras. A ginástica é um esporte que une pessoas de todo o mundo, as inspira e as enriquece. A ginástica é um esporte influenciado por diversas culturas, estilos e tradições. Neste capítulo, vamos analisar as influências internacionais na ginástica universitária nos Estados Unidos e como essas influências moldaram e transformaram a ginástica universitária.
  </MKTypography>
  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Influências Estrangeiras e Intercâmbio
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Ginasta de Stanford em ação no cavalo com alças em uma competição universitária."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Uma ginasta de Stanford em ação
        </figcaption>
      </figure>
      No fascinante mundo da ginástica universitária nos Estados Unidos, o intercâmbio internacional desempenha um papel crucial. Você sabia que os ginastas estrangeiros trazem uma dinâmica e técnicas únicas que elevam o nível da competição? Esses atletas muitas vezes trazem estilos e métodos inovadores que inspiram e desafiam seus colegas americanos. Isso não só torna o esporte mais diversificado, mas também mais competitivo.
      <br />
      <br />
      Um exemplo disso é o impacto dos ginastas do Leste Europeu nas décadas de 80 e 90. Sua chegada aos EUA trouxe novas técnicas e métodos de treinamento, levando a uma evolução significativa na ginástica americana. Essas influências ainda são visíveis hoje no estilo e na técnica de muitos dos principais ginastas.
      <br />
      <br />
      A diversidade que os ginastas internacionais trazem enriquece a cultura esportiva nos campi. Isso cria um caldeirão de culturas e ideias que só beneficia o esporte. Além disso, oferece aos estudantes e treinadores americanos a oportunidade de aprender com diferentes perspectivas culturais e aprimorar suas próprias habilidades e táticas.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Americanos no Exterior
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Por outro lado, os ginastas americanos também tiveram um impacto significativo no mundo da ginástica internacional. Numerosos estudantes-atletas americanos alcançaram sucesso internacional após suas carreiras universitárias. Suas performances em campeonatos mundiais e nos Jogos Olímpicos muitas vezes resultam do treinamento e experiência adquiridos no circuito universitário.
      <br />
      <br />
      Esses ginastas servem como embaixadores da cultura da ginástica americana. Seus sucessos inspiram jovens ginastas em todo o mundo a aspirar a fazer parte de uma equipe universitária americana. Isso cria um ciclo positivo onde talento e conhecimento são trocados entre fronteiras.
      <br />
      <br />
      Os caminhos que esses ginastas trilharam demonstram como a ginástica universitária pode ser uma excelente base para uma carreira internacional. As experiências que eles adquirem durante seus estudos - desde a participação em competições de alto nível até o treinamento com os melhores treinadores - os preparam para os desafios do mundo esportivo profissional.
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    As bolsas de estudo esportivas oferecem inúmeras oportunidades para jovens atletas mostrarem seu talento e desenvolverem suas habilidades. Uma das oportunidades mais significativas é obter uma bolsa de estudos esportiva para estudar e competir no nível universitário. Neste capítulo, discutiremos a importância das bolsas de estudo esportivas e como a Sport Scholarships America pode ajudá-lo a realizar seu sonho.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 A Importância das Bolsas de Estudo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Uma bolsa de estudos esportiva é um suporte financeiro fornecido por uma universidade a um estudante-atleta para combinar estudos e esportes. Pode cobrir os custos de mensalidades, livros, moradia, refeições e outras despesas. Além disso, pode oferecer benefícios como tutoria acadêmica, cuidados médicos, instalações de treinamento e equipamentos.
      <br />
      <br />
      Obter uma bolsa de estudos esportiva é competitivo, com muitos fatores desempenhando um papel no processo de concessão, incluindo realizações acadêmicas, desempenho atlético, personalidade, motivação, liderança e adaptabilidade. Portanto, é importante se preparar bem e se destacar da concorrência.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Como a Sport Scholarships America Pode Ajudar
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo da Sport Scholarships America, mostrando equipamentos esportivos e um capelo de formatura, representando o foco em bolsas de estudo esportivas para estudantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo da Sport Scholarships America
        </figcaption>
      </figure>
      A Sport Scholarships America é uma organização especializada em orientar jovens talentos esportivos para bolsas de estudo esportivas. Com especialistas apaixonados e experientes, a Sport Scholarships America apoia estudantes-atletas em cada etapa da jornada, desde a orientação inicial até a colocação final. Seus serviços incluem:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Uma entrevista de admissão pessoal para discutir os desejos, possibilidades e expectativas do estudante-atleta.</li>
        <li>Uma avaliação profissional do nível acadêmico e atlético do estudante-atleta.</li>
        <li>Uma extensa rede de contatos com treinadores, universidades e faculdades.</li>
        <li>Promoção eficaz do perfil e vídeos do estudante-atleta para treinadores potenciais.</li>
        <li>Conselhos especializados sobre as melhores opções com base nas preferências, metas e orçamento do estudante-atleta.</li>
        <li>Negociação das melhores ofertas de bolsas de estudo em nome do estudante-atleta.</li>
        <li>Auxílio na solicitação de documentos necessários, como visto, histórico escolar, diploma e testes de admissão.</li>
        <li>Preparação para a vida do estudante-atleta durante a bolsa de estudos, incluindo cultura, idioma, regras e dicas.</li>
        <li>Acompanhamento e acompanhamento durante o período da bolsa de estudos.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Quer explorar oportunidades de bolsas de estudo e alcançar seus objetivos atléticos e acadêmicos? <Link to="/pt/Serviços-Produtos">Veja a gama completa</Link> de serviços oferecidos pela Sport Scholarships America e agende uma consulta hoje.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;