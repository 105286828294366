/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Produktside: https://www.creative-tim.com/product/material-kit-pro-react
* Opphavsrett 2023 Creative Tim (https://www.creative-tim.com)

Kodet av www.creative-tim.com

=========================================================

* Den ovennevnte opphavsrettsmerknaden og denne tillatelsesmerknaden skal inkluderes i alle kopier eller betydelige deler av programvaren.
*/

import { useState } from "react";

// Komponenter fra @mui material
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Komponenter fra Material Kit 2 PRO React
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton"; // Importer MKButton
import { Link } from 'react-router-dom';

// Komponenter for siden om utleie
import FaqCollapse from "Languages/Norsk/pages/Homepage/LandingPage/sections/FAQfunction";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <MKBox component="section" py={8}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
              Ofte stilte spørsmål
            </MKTypography>
            <MKBox mb={2}>
              <MKTypography variant="body2" align="center" color="text">
                Alt du ønsker å vite: svar på de mest stilte spørsmålene om studier og idrett i Amerika og mine tjenester. Hvis spørsmålet ditt ikke er oppført, ta gjerne kontakt.
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={10}>
          <FaqCollapse
   title="Hvor mye koster det å studere i Amerika?"
   open={collapse === 1}
   onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
>
   Skolepenger og kostnader for å studere i Amerika varierer betydelig avhengig av universitetet, studieprogrammet og stedet. I gjennomsnitt kan internasjonale studenter forvente en total årlig utgift på omtrent $30 000 til så mye som $70 000 eller mer. Det er imidlertid verdt å merke seg at mange studenter kan dekke en betydelig del (eller noen ganger hele beløpet) av disse kostnadene gjennom idrettsstipend, akademiske stipend eller en kombinasjon av begge deler. Amerikanske universiteter tilbyr ofte idrettsstipend til talentfulle idrettsutøvere, som gir økonomisk støtte i bytte mot deltakelse i collegial idrettsprogrammer. Dette gjør at studentutøvere kan forfølge sine akademiske og idrettslige mål uten betydelige økonomiske byrder.
</FaqCollapse>

<FaqCollapse
   title="Hva er forskjellen mellom en høyskole og et universitet i Amerika?"
   open={collapse === 2}
   onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
>
   Terminologien kan være forvirrende, spesielt for personer utenfor USA. I praksis refererer "høyskole" og "universitet" faktisk ikke til det samme. Et "universitet" er en omfattende betegnelse for en høyere utdanningsinstitusjon som tilbyr programmer på alle nivåer, inkludert bachelor, master og doktorgradsstudier.

   En "høyskole" er derimot vanligvis en del av et universitet og fokuserer hovedsakelig på lavere programmer, som bachelorgrader. Med andre ord er en høyskole en avdeling innenfor et universitet som er spesielt dedikert til lavere utdanning. Universiteter tilbyr vanligvis både lavere og høyere programmer, mens høyskoler fokuserer på lavere nivå.

   Imidlertid kan denne distinksjonen variere mellom ulike institusjoner, og noen ganger brukes termene om hverandre. Derfor kan det være nyttig å undersøke de spesifikke detaljene og programmene til en utdanningsinstitusjon for å forstå hva de tilbyr.
</FaqCollapse>

<FaqCollapse
   title="Hvilke idrettsutøvere veileder Sport Scholarships America?"
   open={collapse === 3}
   onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
>
   Jeg veileder idrettsutøvere innenfor et bredt spekter av idretter. Mitt sterke nettverk og mine samarbeidspartnere i Amerika gjør det mulig for meg å støtte utøvere innenfor ulike disipliner. Så enten du spiller fotball, basketball, tennis, golf eller en annen sport, er jeg klar til å hjelpe deg med å forfølge dine akademiske og idrettslige mål i USA. For en komplett liste over idretter, <Link to="/no/Alle-idretter" style={{ fontWeight: 'bold' }}>klikk her</Link>.
</FaqCollapse>

<FaqCollapse
   title="Hva er den typiske søknadsprosessen, og hvor lang tid i forveien bør jeg begynne?"
   open={collapse === 4}
   onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
>
   Prosessen begynner vanligvis med et grundig innføringsmøte der vi diskuterer alle detaljer og forstår din spesifikke situasjon og mål. Jeg vil også forklare min tilnærming nærmere. Varigheten av hele prosessen kan variere, men generelt anbefaler jeg å begynne minst 6 til 12 måneder i forveien. Det er viktig å understreke at jo tidligere du begynner, jo flere muligheter kan oppstå, så ikke nøl med å starte prosessen tidlig.

   I løpet av denne perioden vil vi samarbeide om å lage en personlig plan som tar hensyn til alle aspekter, som opptaksprøver, visumsøknader, opprettelse av idrettsvideoer og mer. Hver reise er unik og skreddersydd, da det er mange faktorer å vurdere. For en detaljert oversikt over hele prosessen, kan du <Link to="/no/Prosess" style={{ fontWeight: 'bold' }}>klikke her</Link>. Dette gir innsikt trinn for trinn i hva du kan forvente under reisen din for å studere og konkurrere i USA.
</FaqCollapse>

<FaqCollapse
   title="Hva er kostnadene for tjenestene du tilbyr?"
   open={collapse === 5}
   onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
>
   Kostnadene for tjenestene mine varierer avhengig av dine spesifikke behov og mål. Intervjusamtalen er et viktig skritt der vi diskuterer din situasjon og utvikler en tilpasset plan. Det er også muligheten til å avgjøre om vi ønsker å fortsette samarbeidet.

   Etter denne samtalen vil jeg kunne ha en klar forståelse av dine ønsker og behov, og basert på det kan jeg gi deg en passende kostnadsestimering.

   For en komplett oversikt over tjenestene og produktene jeg tilbyr, kan du <Link to="/no/Tjenester-Produkter" style={{ fontWeight: 'bold' }}>klikke her</Link>. Her finner du detaljert informasjon om hvordan jeg kan hjelpe deg med å forfølge studier og konkurranser i USA.
</FaqCollapse>

<FaqCollapse
   title="Hvorfor bør jeg velge å bli veiledet av Sport Scholarships America?"
   open={collapse === 6}
   onClick={() => (collapse === 6 ? setCollapse(false) : setCollapse(6))}
>
   Når du står ved et veiskille i realiseringen av drømmene dine, slik jeg en gang var, kan det oppstå mange spørsmål og tvil. Valget om å gjøre det selv, samarbeide med et byrå eller til og med gi opp hele ideen kan være overveldende. Jeg forstår dypt denne usikkerheten fordi jeg selv har gått gjennom den.

   Det ville være synd om disse tvilene hindret noen i å forfølge det som virkelig er mulig - en fantastisk opplevelse som venter på å bli oppdaget. Derfor er jeg dedikert til å hjelpe andre med å realisere drømmene sine.

   Jeg setter mine personlige erfaringer i praksis ved å være direkte involvert i hvert prosjekt. Jeg forstår spørsmålene du kan ha, og streber etter å tilby en personlig tilnærming som samsvarer med dine unike behov. Sammen kan vi bringe visjonen din til live, støttet av kunnskapen og forståelsen som kommer fra ekte, opplevd erfaring.
</FaqCollapse>

          </Grid>
        </Grid>
        {/* Seksjon for knapper */}
        <Grid container justifyContent="center" mt={4}>
          <Grid item xs={12} md={3}>
          <MKButton variant="gradient" color="light" fullWidth component={Link} to="/no/FAQs">
    Se fullstendig FAQ
</MKButton>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Faq;
