/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Swimming1.jpg";
import Image2 from "assets/images/Sports_Images/Swimming2.jpeg";
import Image3 from "assets/images/Sports_Images/Swimming3.avif";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Simning i Amerika: Hur du får ett idrottsstipendium och blir en del av en prestigefylld idrottshistoria', isSubheader: false },
  
    { id: 'Kapitel 1', title: 'Kapitel 1: Historien och Utvecklingen av Simning i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Tidiga År och Universitetens Roll', isSubheader: true },
    { id: '1.2', title: '1.2 Tillväxt och Professionalisering', isSubheader: true },
  
    { id: 'Kapitel 2', title: 'Kapitel 2: Collegiansimning Idag', isSubheader: false },
    { id: '2.1', title: '2.1 Viktiga Tävlingar och Konferenser', isSubheader: true },
    { id: '2.2', title: '2.2 Toppprogram och Prestigefyllda Lag', isSubheader: true },
  
    { id: 'Kapitel 3', title: 'Kapitel 3: Från College till Professionell Nivå', isSubheader: false },
    { id: '3.1', title: '3.1 Vägen till Professionalism', isSubheader: true },
    { id: '3.2', title: '3.2 Professionell Simning och Olympiska Spelen', isSubheader: true },
  
    { id: 'Kapitel 4', title: 'Kapitel 4: Internationella Influenser och Tävlingar', isSubheader: false },
    { id: '4.1', title: '4.1 Utländska Influenser', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikaner Utomlands', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapitel 5: Idrottsstipendier och Framtiden för Idrottare', isSubheader: false },
    { id: '5.1', title: '5.1 Vikten av Stipendier', isSubheader: true },
    { id: '5.2', title: '5.2 Hur Sport Scholarships America Kan Hjälpa', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innehåll
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Dölj</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Visa</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="introduction" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Simning i Amerika: Hur du får ett idrottsstipendium och blir en del av en prestigefylld idrottshistoria
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Simningsstadion fylld med åskådare under en collegetävling."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        En stadion under en match
      </figcaption>
    </figure>
    Simning är en av de mest populära och konkurrenskraftiga idrotterna i världen. Miljontals människor njuter av att simma, både rekreationellt och professionellt. Men visste du att simning i Amerika har nått en helt annan nivå? Simning i Amerika är inte bara en sport; det är en kultur, en tradition och en livsstil.
    <br />
    <br />
    I den här artikeln kommer vi att berätta allt om utvecklingen av simning som en tävlingsidrott i USA, betydelsen av collegiansimning för sportens utveckling och hur du får ett idrottsstipendium för att studera och simma i Amerika. Vi kommer också att fördjupa oss i rollen som Sportbeursamerika.nl, en organisation som hjälper unga simmare att förverkliga sina drömmar.
    <br />
    <br />
    Simning är en sport som har utövats i århundraden, men det var inte förrän på 1800-talet som den började utvecklas som en organiserad och reglerad aktivitet. De första moderna olympiska spelen 1896 inkluderade simtävlingar som en av huvudevenemangen, och sedan dess har simning varit en stapelvara på den olympiska agendan.
    <br />
    <br />
    Men varför är simning så populär och framgångsrik i Amerika? Vad gör det så speciellt och unikt? Svaret ligger i universitetens roll för att främja simning som en akademisk och idrottslig disciplin. Amerikanska universitet erbjuder inte bara högkvalitativ utbildning utan också utmärkta faciliteter, coachning och vägledning för studentidrottare som vill specialisera sig på simning. Dessutom tillhandahåller de ekonomiskt stöd i form av idrottsstipendier, vilket gör att begåvade simmare kan förverkliga sitt potential utan att behöva oroa sig för utbildningskostnaderna.
    <br />
    <br />
    Dock är idrottsstipendier inte lätt att få. De är mycket konkurrenskraftiga och kräver en hög nivå av engagemang, disciplin och prestanda. Därför är det viktigt att vara väl förberedd och söka professionell hjälp när du ansöker om ett idrottsstipendium. Det är där Sportbeursamerika.nl kommer in i bilden.
    <br />
    <br />
    Sportbeursamerika.nl är en organisation specialiserad på att vägleda unga simmare till idrottsstipendier i Amerika. De har ett team av experter som kan hjälpa dig med alla aspekter av processen, såsom att välja rätt universitet, förbereda ditt CV och motivationsbrev, förbereda dig inför antagningsprov och intervjuer, ordna ditt visum och försäkring, och mycket mer. De har också kontakter med hundratals universitet och tränare i Amerika, vilket gör att de kan matcha dig med de bästa alternativen för din profil och dina mål.
    <br />
    <br />
    Så om du drömmer om att studera och simma i Amerika är Sportbeursamerika.nl din bästa allierade. De har redan hjälpt hundratals simmare att förverkliga sina drömmar, och de kan hjälpa dig också. Men innan du kontaktar dem, låt oss först ta en titt på historien och utvecklingen av simning i Amerika och varför det är en fantastisk möjlighet för dig.
  </MKTypography>
</MKBox>

<MKBox id="Kapitel 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Historien och Utvecklingen av Simning i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Simning har en lång och rik historia i Amerika. Allt började i slutet av 1800-talet när universitet började bilda sina egna simlag och tävlingar. Detta markerade början på en tradition som skulle leda till att simning blev en nationell sport.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidiga År och Universitetens Roll
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Det första universitetet att etablera ett officiellt simlag var Harvard år 1878. Snart följde andra prestigefyllda universitet som Yale, Princeton, Columbia och Cornell efter. Dessa universitet organiserade intercollegiate matcher och turneringar som fick allmänhetens och mediernas uppmärksamhet. De införde också nya regler och tekniker för simning, inklusive bröstsim, ryggsim och fjärilssim.
      <br />
      <br />
      År 1902 grundades Intercollegiate Swimming Association (ISA), vilket blev den första nationella organisationen för collegiansimning. ISA organiserade årliga mästerskap för män, där de bästa lagen och individuella simmare hedrades. År 1916 etablerades National Collegiate Athletic Association (NCAA) som den övergripande organisationen för all collegiansport. NCAA tog ansvar för att organisera de nationella mästerskapen för collegiansimning, vilka fortsätter än idag.
      <br />
      <br />
      Collegiansimning var inte begränsad till män. Kvinnor började också tävla på collegenivå i början av 1900-talet, även om de ställdes inför fler begränsningar och diskriminering. De första kvinnliga collegiansimlagen bildades 1914 vid University of Chicago och 1916 vid University of Wisconsin. År 1922 grundades Women’s Intercollegiate Swimming Association (WISA), som tjänade som motsvarigheten till ISA för kvinnor. WISA organiserade också årliga mästerskap för kvinnor, som fick betydande popularitet.
      <br />
      <br />
      Collegiansimning spelade en avgörande roll för att främja simning som en professionell sport i Amerika. Många collegiansimmare gick vidare till professionella ligor och olympiska spel, där de representerade sitt land och vann medaljer. Några av de mest kända namnen inkluderar Johnny Weissmuller, Mark Spitz, Matt Biondi, Janet Evans, Michael Phelps och Katie Ledecky.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Tillväxt och Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Förutom collegiansimning uppstod andra former av professionell simning i Amerika. En av dem var masterssimning, som fokuserade på vuxna simmare som ville förbättra sina färdigheter eller behålla sin kondition. Masterssimning började 1970 med etableringen av United States Masters Swimming (USMS), en organisation som erbjuder lokala klubbar, tävlingar och program för vuxna simmare. Masterssimning växte snabbt till en global rörelse, med miljontals medlemmar i över 100 länder.
      <br />
      <br />
      En annan form av professionell simning var öppet vattensimning, som ägde rum i naturliga vattenkroppar som sjöar, floder och hav. Öppet vattensimning var en utmanande och äventyrlig sport som krävde uthållighet, navigationsfärdigheter och anpassningsförmåga. Öppet vattensimning fick mer erkännande 2008 när det lades till som en olympisk gren. Några av de mest kända öppet vattensimmarna inkluderar Gertrude Ederle, Lynne Cox, Maarten van der Weijden och Ous Mellouli.
      <br />
      <br />
      Professionell simning nådde sin höjdpunkt under de olympiska spelen, där amerikanska simmare dominerade. Sedan 1896 har Amerika vunnit över 500 medaljer i simning, inklusive mer än 200 guldmedaljer. Detta gör Amerika överlägset det mest framgångsrika landet i olympisk simning.
      <br />
      <br />
      Men hur kan du också vara en del av denna ärofulla idrottshistoria? Hur kan du studera och simma i Amerika med ett idrottsstipendium? Det ska vi berätta i nästa kapitel.
    </MKTypography>
  </MKBox>
</MKBox>





                                  






<MKBox id="Kapitel 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Collegiansimning Idag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Som du har sett har collegiansimning en lång och stolt historia i Amerika. Men hur ser det ut idag? Hur är collegiansimning organiserad och strukturerad idag? Vilka är de viktigaste tävlingarna och konferenserna? Vilka är de bästa programmen och prestigefyllda lagen? Låt oss titta närmare på det.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Viktiga Tävlingar och Konferenser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Katie Ledecky simmar för Stanford i en collegiantävling."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Katie Ledecky i aktion som simmare för Stanford
        </figcaption>
      </figure>
      Collegiansimning i Amerika regleras främst av NCAA, som har tre divisioner: Division I, Division II och Division III. Varje division har sina egna regler, kriterier och mästerskap. Generellt sett är Division I den mest konkurrenskraftiga och prestigefyllda divisionen, med de bästa simmarna, tränarna och faciliteterna. Division II och III är mindre konkurrenskraftiga men erbjuder fortfarande högkvalitativ utbildning och idrott.
      <br />
      <br />
      Inom varje division finns olika konferenser, vilka är grupper av universitet som är geografiskt eller historiskt förbundna. Varje konferens organiserar sina egna mästerskap, där de bästa lagen och individuella simmarna kvalificerar sig för NCAA:s nationella mästerskap. Några av de mest välkända konferenserna inom collegiansimning inkluderar:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Southeastern Conference (SEC), bestående av 14 universitet i sydöstra USA, såsom Alabama, Florida, Georgia, Kentucky, LSU, Tennessee och Texas A&M. SEC är känt för sin dominans inom simning, särskilt i herrtävlingar. SEC har vunnit över 50 nationella titlar i simning, varav över 40 kommer från herrlaget på Florida.
        </li>
        <li>
          Pacific-12 Conference (Pac-12), bestående av 12 universitet på västkusten i Amerika, inklusive Arizona, California, Stanford, UCLA, USC och Washington. Pac-12 är känt för sin excellens inom simning, särskilt i damtävlingar. Pac-12 har vunnit över 40 nationella titlar i simning, varav över 30 tillhör damlaget på Stanford.
        </li>
        <li>
          Big Ten Conference (Big Ten), bestående av 14 universitet i Mellanvästern och nordöstra USA, såsom Indiana, Michigan, Minnesota, Ohio State, Penn State och Wisconsin. Big Ten är känt för sin mångfald och balans inom simning, med starka lag inom både herr- och damtävlingar. Big Ten har vunnit över 30 nationella titlar i simning, varav över 20 säkrades av herrlaget på Michigan.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Detta är bara några exempel på de många konferenser som finns inom collegiansimning. Varje konferens har sin egen kultur, rivaliteter och traditioner, vilket bidrar till spänningen inom collegiansimning.
    </MKTypography>

  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Toppprogram och Prestigefyllda Lag
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Förutom konferenser finns det enskilda universitet som utmärker sig på grund av sina toppprogram och prestigefyllda simlag. Dessa universitet erbjuder inte bara utmärkt utbildning till sina studenter utan också förstklassig coachning, träning och faciliteter för sina idrottare. De attraherar också många talangfulla simmare från både nationella och internationella bakgrunder som vill studera och simma på högsta nivå.
      <br />
      <br />
      Några av dessa toppuniversitet inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Stanford University: Stanford är ett av de mest kända universiteten i världen både akademiskt och idrottsligt. Stanford har ett av de bästa damsimningsprogrammen i Amerika, med över 10 nationella titlar och mer än 100 olympiska medaljer. Stanford har också ett starkt herrsimningsprogram, med över 5 nationella titlar och mer än 50 olympiska medaljer. Några kända alumner från Stanford inkluderar John Hencken, Pablo Morales, Jenny Thompson, Summer Sanders, Misty Hyman, Janet Evans och Katie Ledecky.
        </li>
        <li>
          University of California: California är ett annat toppuniversitet både akademiskt och idrottsligt. California har ett av de bästa herrsimningsprogrammen i Amerika, med över 10 nationella titlar och mer än 100 olympiska medaljer. California har också ett starkt damsimningsprogram, med över 5 nationella titlar och mer än 50 olympiska medaljer. Några kända alumner från California inkluderar Matt Biondi, Anthony Ervin, Natalie Coughlin, Nathan Adrian, Dana Vollmer och Ryan Murphy.
        </li>
        <li>
          University of Texas: Texas är ett av de största och mest inflytelserika universiteten i Amerika, med ett starkt rykte inom både akademik och idrott. Texas har ett av de bästa herrsimningsprogrammen i Amerika, med över 15 nationella titlar och mer än 100 olympiska medaljer. Texas har också ett starkt damsimningsprogram, med över 5 nationella titlar och mer än 50 olympiska medaljer. Några kända alumner från Texas inkluderar Josh Davis, Aaron Peirsol, Ian Crocker, Brendan Hansen, Ricky Berens och Joseph Schooling.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Detta är bara några av de många toppuniversiteterna inom collegiansimning. Varje universitet har sin egen historia, filosofi och vision som bidrar till utvecklingen av simmare.
      <br />
      <br />
      Som du kan se är collegiansimning idag en mycket dynamisk och konkurrenskraftig sport som erbjuder många möjligheter för studentidrottare som vill utmärka sig både akademiskt och idrottsligt. Men hur kan du få tillgång till dessa möjligheter? Hur kan du få ett idrottsstipendium för att studera och simma i Amerika? Det ska vi berätta i nästa kapitel.
    </MKTypography>
  </MKBox>
</MKBox>






                                  







<MKBox id="Kapitel 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Från College till Professionell Nivå
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Att studera och simma i Amerika är inte bara en fantastisk upplevelse utan också en fantastisk möjlighet att kickstarta din karriär som professionell simmare. Många collegiansimmare går vidare till den professionella nivån, där de representerar sitt land i internationella tävlingar och olympiska spel. Men hur tar du steget från college till den professionella nivån? Vilka är utmaningarna och fördelarna med denna övergång? Låt oss titta närmare på det.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Vägen till Professionalism
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      För att göra övergången från college till den professionella nivån måste du först uppfylla NCAA:s krav. NCAA har strikta regler angående studentidrottarnas amatörstatus, vilket innebär att du inte kan tjäna eller acceptera pengar för din idrottsprestation. Detta innebär att du inte kan ingå sponsorskapsavtal, prispengar eller andra former av ersättning medan du fortfarande studerar och simmar på collegenivå.
      <br />
      <br />
      Men när du har avslutat dina studier eller bestämmer dig för att avsluta din utbildning kan du avstå från din amatörstatus och bli en professionell simmare. Detta innebär att du kan gå med i en professionell simningsorganisation, såsom USA Swimming, som är den nationella styrande kroppen för simning i Amerika. USA Swimming ger dig möjlighet att delta i professionella simningstävlingar som Pro Swim Series, US Open, US Nationals och Olympic Trials. Dessa tävlingar är utformade för att förbättra dina färdigheter, höja din ranking och kvalificera dig för internationella evenemang.
      <br />
      <br />
      Som professionell simmare kan du också dra nytta av sponsorskapsavtal, prispengar och andra former av ersättning för din idrottsprestation. Detta kan hjälpa dig att förbättra din ekonomiska situation, stödja din livsstil och öka din motivation. Att bli en professionell simmare kommer dock också med fler ansvar och utmaningar. Till exempel måste du hantera din egen träningsplan, näringsplan, reseutgifter, medicinsk vård och skatter. Du kommer också att behöva hantera ökad konkurrens, press och förväntningar från dig själv och andra.
      <br />
      <br />
      Därför är det avgörande att vara väl förberedd för övergången från college till den professionella nivån. Du måste inte bara ha utmärkta simfärdigheter utan också ha en stark mental inställning, tydliga mål och bra stöd. Du måste också vara realistisk om dina chanser och möjligheter och vara villig att arbeta hårt och göra uppoffringar.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professionell Simning och Olympiska Spelen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      En av varje professionell simmares största drömmar är att delta i Olympiska Spelen, den mest prestigefyllda och betydelsefulla idrottsevenemanget i världen. OS erbjuder inte bara en chans att representera sitt land och vinna medaljer utan också att göra historia och bli en legend.
      <br />
      <br />
      För att kvalificera dig för Olympiska Spelen måste du dock först uppfylla kraven från Internationella Olympiska Kommittén (IOC) och Internationella Simförbundet (FINA). Dessa organisationer har strikta regler angående kvalificeringskriterier, antalet deltagare per land, antidopingkontroller och andra aspekter av olympisk simning.
      <br />
      <br />
      Ett av de viktigaste kraven för att kvalificera sig för Olympiska Spelen är att uppnå en specifik tidsstandard i en erkänd tävling. Denna tidsstandard bestäms av FINA baserat på genomsnittet av de 16 bästa prestationerna globalt inom varje disciplin under de senaste fyra åren. Tidsstandarden varierar efter kön, åldersgrupp och simsätt.
      <br />
      <br />
      En annan viktig förutsättning för att kvalificera sig för Olympiska Spelen är att bli vald av din nationella simningsorganisation, såsom USA Swimming. Varje nationell simningsorganisation har sin egen urvalsprocess, vanligtvis involverande en serie uttagningar eller mästerskap där de bästa simmarna väljs för att representera landet. Varje nationell simningsorganisation har också ett begränsat antal platser per disciplin, beroende på antalet kvalificerade simmare och kvoterna som satts av IOC och FINA.
      <br />
      <br />
      Om du lyckas uppfylla dessa krav och kvalificera dig för Olympiska Spelen är du en av de lyckligt lottade som får möjlighet att vara en del av världens största idrottsevenemang. Du kommer att kunna tävla mot världens bästa simmare, utmana dig själv och potentiellt vinna en medalj och etsa ditt namn i historieböckerna.
      <br />
      <br />
      Som du kan se är övergången från college till den professionella nivån en spännande och belönande resa som erbjuder många möjligheter för din simkarriär. Men hur kan du börja denna resa? Hur kan du få ett idrottsstipendium för att studera och simma i Amerika? Det ska vi berätta i nästa kapitel.
    </MKTypography>
  </MKBox>
</MKBox>



                                  







<MKBox id="Kapitel 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationella Influenser och Tävlingar
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Simning i Amerika är inte bara en nationell sport utan också en internationell. Simning i Amerika påverkas av och påverkar andra länder och kulturer, var och en med sina egna stilar, tekniker och traditioner inom simning. Simning i Amerika utmanas och berikas också av andra internationella tävlingar, vilket ger möjligheter att lära sig av och tävla mot simmare från hela världen. Men hur uppstod och utvecklades dessa internationella influenser och tävlingar? Hur påverkar de collegiat- och professionell simning i Amerika? Låt oss titta närmare på det.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Utländska Influenser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Simmare i aktion under NCAA:s nationella mästerskap."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          NCAA:s nationella mästerskap
        </figcaption>
      </figure>
      Simning är en universell sport som utövas i nästan varje land i världen. Varje land har sin egen historia, kultur och identitet inom simning, vilket återspeglas i hur de simmar, tränar och coachar. Vissa länder har haft en betydande påverkan på simning i Amerika, både positivt och negativt.
      <br />
      <br />
      En av de positiva influenserna är Australien, som är en av Amerikas största rivaler och allierade inom simning. Australien har en lång och stolt tradition inom simning, med över 100 olympiska medaljer och över 20 världsrekord. Australien är känt för sin innovation och kreativitet inom simning, genom att introducera nya tekniker, strategier och metoder. Australien har också producerat många talangfulla och karismatiska simmare, som Dawn Fraser, Shane Gould, Ian Thorpe, Grant Hackett, Libby Trickett och Cate Campbell.
      <br />
      <br />
      Australien har påverkat simning i Amerika genom att skapa en sund konkurrens och samarbete som motiverar båda länderna att förbättra sig och lära av varandra. Många australiska och amerikanska simmare har tränat, studerat eller arbetat tillsammans, vilket förbättrar deras färdigheter, kunskaper och vänskap. Några exempel inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          År 1976 tränade Mark Spitz, den amerikanska stjärnan som vann sju guldmedaljer i München, tillsammans med John Devitt, den australiska legenden som vann två guldmedaljer i Melbourne och Rom.
        </li>
        <li>
          År 1988 tog Matt Biondi, den amerikanska sensationen som vann sju medaljer i Seoul, examen från University of California med en examen i politisk ekonomi.
        </li>
        <li>
          År 2016 tog Joseph Schooling, den singaporeanska överraskningen som vann den första guldmedaljen för sitt land på 100 meter fjäril i Rio de Janeiro, examen från University of Texas med en examen i ekonomi.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Detta är bara några exempel på hur utländska influenser har påverkat simning i Amerika. Många fler länder och kulturer har lämnat sina avtryck på simning, inklusive Japan, Kina, Ryssland, Frankrike, Brasilien och Sydafrika. Simning i Amerika är inte bara en nationell sport utan också en internationell som ständigt utvecklas och förändras genom interaktion med andra länder och kulturer.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikaner Utomlands
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Simning i Amerika påverkas inte bara av och påverkar andra länder och kulturer utan utmanas också och berikas av andra internationella tävlingar. Simning i Amerika ger möjlighet att lära sig av och tävla mot simmare från hela världen, var och en med sina egna stilar, tekniker och traditioner inom simning. Simning i Amerika erbjuder också möjligheten att resa till och uppleva olika platser, människor och kulturer, vilket vidgar dina vyer och ändrar din synvinkel.
      <br />
      <br />
      En av de stora internationella tävlingarna som amerikanska simmare deltar i är Världsmästerskapen i Simning, som hålls vartannat år av FINA. Världsmästerskapen i Simning är en händelse som omfattar alla simningsdiscipliner, inklusive lång bana, kort bana, öppet vatten, konstsimmning och simhopp. Världsmästerskapen i Simning ger möjlighet att tävla mot världens bästa simmare, förbättra din ranking och förbättra din kvalificering för andra evenemang.
      <br />
      <br />
      En annan betydande internationell tävling som amerikanska simmare deltar i är Världscupen i Simning, som hålls årligen av FINA. Världscupen i Simning är en serie kortbaneevenemang som äger rum i olika städer runt om i världen. Världscupen i Simning är en möjlighet att tävla mot olika motståndare, testa dina färdigheter och tjäna prispengar.
      <br />
      <br />
      En tredje stor internationell tävling som amerikanska simmare deltar i är Panamerikanska Spelen, som hålls vart fjärde år av Panamerikanska Sportorganisationen (PASO). Panamerikanska Spelen är en mångsidig idrottsevenemang som samlar idrottare från Nord-, Syd- och Centralamerika. Panamerikanska Spelen är en chans att representera ditt kontinent, visa solidaritet och vinna medaljer.
      <br />
      <br />
      Detta är bara några exempel på de många internationella tävlingarna som amerikanska simmare deltar i. Det finns många fler internationella tävlingar inom simning, som Samväldesspelen, Europamästerskapen, Asiatiska Spelen och Afrikanska Spelen.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  






<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Idrottsstipendier och Framtiden för Idrottare
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrottsstipendier erbjuder många möjligheter för unga idrottare att visa upp sin talang och utveckla sina färdigheter. En av de mest betydelsefulla möjligheterna är att få ett idrottsstipendium för att studera och tävla på collegenivå. I det här kapitlet kommer vi att diskutera vikten av idrottsstipendier och hur Sport Scholarships America kan hjälpa dig att förverkliga din dröm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vikten av Stipendier
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ett idrottsstipendium är ekonomiskt stöd som tillhandahålls av ett universitet till en idrottare för att kombinera studier och idrott. Det kan täcka kostnaderna för undervisning, böcker, boende, måltider och andra utgifter. Dessutom kan det erbjuda fördelar som akademisk handledning, sjukvård, träningsanläggningar och utrustning.
      <br />
      <br />
      Att få ett idrottsstipendium är konkurrenskraftigt, med många faktorer som spelar en roll i tilldelningsprocessen, inklusive akademiska prestationer, idrottsprestationer, personlighet, motivation, ledarskap och anpassningsförmåga. Därför är det viktigt att förbereda sig väl och skilja sig från konkurrensen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hur Sport Scholarships America Kan Hjälpa
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotyp för Sport Scholarships America, med sportutrustning och en studentmössa, som representerar fokus på idrottsstipendier för idrottare."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotyp för Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America är en organisation specialiserad på att vägleda unga idrottstalanger till idrottsstipendier. Med passionerade och erfarna experter stöder Sport Scholarships America idrottare i varje steg av resan, från initial orientering till slutlig placering. Deras tjänster inkluderar:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intagningsintervju för att diskutera idrottarens önskemål, möjligheter och förväntningar.</li>
        <li>En professionell utvärdering av idrottarens akademiska och idrottsliga nivå.</li>
        <li>Ett omfattande nätverk av kontakter med tränare, universitet och högskolor.</li>
        <li>Effektiv marknadsföring av idrottarens profil och videor till potentiella tränare.</li>
        <li>Expertrådgivning om de bästa alternativen baserat på idrottarens preferenser, mål och budget.</li>
        <li>Förhandling om de bästa stipendieerbjudandena för idrottaren.</li>
        <li>Hjälp med att ansöka om nödvändiga dokument, såsom visum, betyg, examensbevis och antagningsprov.</li>
        <li>Förberedelse för idrottarens liv under stipendieperioden, inklusive kultur, språk, regler och tips.</li>
        <li>Uppföljning och uppföljning under stipendieperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vill du utforska möjligheter till stipendier och uppnå dina idrottsliga och akademiska mål? <Link to="/sv/Tjänster-Produkter">Se det fullständiga utbudet</Link> av tjänster som erbjuds av Sport Scholarships America och boka en konsultation idag.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;