// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";
import "../../../../H1asH3.css"

// Images
import profilePicture from "assets/images/Profielfoto_Kay_Duit.jpeg";

function Profil() {
  return (
    <MKBox component="section" pt={{ xs: 20, sm: 20,  md: 6}} pb={{ xs: 6, sm: 0}}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
            <MKAvatar src={profilePicture} alt="Profilbild von Kay Duit, Gründer und Inhaber" size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox 
                display="flex" 
                flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} 
                justifyContent="space-between" 
                alignItems="center" 
                mb={1}
              >
                <MKTypography variant="h1" className="h1-als-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Kay Duit</MKTypography>
                <MKBox display="flex" flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} gap={1}>
                  <a href="https://www.linkedin.com/in/kayduit/" target="_blank" rel="noopener noreferrer">
                    <MKSocialButton color="linkedin" style={{ width: '140px' }}>
                      <MKBox component="i" color="inherit" mr={0.5} className="fab fa-linkedin" />
                      LinkedIn
                    </MKSocialButton>
                  </a>
                  <a href="https://www.instagram.com/kayduit/" target="_blank" rel="noopener noreferrer">
                    <MKSocialButton color="instagram" style={{ width: '140px' }}>
                      <MKBox component="i" color="inherit" mr={0.5} className="fab fa-instagram" />
                      Instagram
                    </MKSocialButton>
                  </a>
                  <a href="/Podcast">
                    <MKSocialButton color="pinterest" style={{ width: '140px' }}>
                      <MKBox component="i" color="inherit" mr={0.5} className="fas fa-podcast" />
                      Podcast
                    </MKSocialButton>
                  </a>
                </MKBox>
              </MKBox>
              <br />
              <MKBox>
                <MKTypography variant="body1" fontWeight="light" color="text">
                  An einem schwülen Sommerabend in Amerika stand ich am Spielfeldrand und wartete auf das Pfeifsignal, um auf das Feld zu stürmen. Mein Herz schlug mir bis zum Hals, nicht wegen des bevorstehenden Spiels, sondern wegen der Erkenntnis, wie weit ich gekommen war. Drei Jahre zuvor hatte ich ein Flugzeug von meinem Heimatland in die USA genommen, einen Sprung ins Unbekannte, auf der Suche nach Abenteuer und der Möglichkeit, sowohl meine sportlichen als auch akademischen Träume zu verwirklichen.
                  <br /><br />
                  In diesen vier Jahren hatte ich viel mehr erlebt als nur Fußballspiele und Studium. Es gab die späten Abendessen nach einem großen Sieg, die Flüge zu Auswärtsspielen in fernen Staaten, die Freundschaften, die ich aufgebaut hatte, und die unzähligen Male, in denen ich erkannte, wie viel größer die Welt war als der kleine Ort, von dem ich kam.
                  <br /><br />
                  An diesem Abend, als die Nationalhymne gespielt wurde und die Tribünen mit Tausenden von jubelnden Fans gefüllt waren, dachte ich an all die jungen Studenten aus der ganzen Welt, die, genauso wie ich, von solchen Möglichkeiten träumten. Und ich verpflichtete mich selbst, dass ich, sobald meine Reise in Amerika beendet war, diesen aufstrebenden Studenten, unabhängig von ihrer Herkunft, eine helfende Hand reichen würde, um ihnen bei der Verwirklichung von Träumen ähnlich wie meinen zu helfen.
                </MKTypography>
              </MKBox>

              <MKTypography
                component="a"
                href="#"
                variant="body1"
                fontWeight="light"
                color="info"
                mt={3}
                sx={{
                  width: "max-content",
                  display: "flex",
                  alignItems: "center",
                  "& .material-icons-round": {
                    transform: `translateX(3px)`,
                    transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                  },
                  "&:hover .material-icons-round, &:focus .material-icons-round": {
                    transform: `translateX(6px)`,
                  },
                }}
              >
                {/* Fügen Sie hier die Übersetzung für den Text hinzu */}
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profil;
