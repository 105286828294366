/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Wrestling1.jpeg";
import Image2 from "assets/images/Sports_Images/Wrestling2.webp";
import Image3 from "assets/images/Sports_Images/Wrestling3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Boksestipendier i USA: Hvordan Studere og Bokse i Amerika Kan La Drømmene Dine Gå i Oppfyllelse', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapittel 1: Historien og Utviklingen av Boksing i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Tidlige År', isSubheader: true },
    { id: '1.2', title: '1.2 Vekst og Utvikling', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapittel 2: College-boksing i Dag', isSubheader: false },
    { id: '2.1', title: '2.1 Viktige Konkurranser og Konferanser', isSubheader: true },
    { id: '2.2', title: '2.2 Topp Programmer og Prestisjefylte Lag', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapittel 3: Fra College til Olympisk Nivå og Profesjonelle Ligaer', isSubheader: false },
    { id: '3.1', title: '3.1 Veien til Toppen', isSubheader: true },
    { id: '3.2', title: '3.2 Profesjonell Boksing og OL', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapittel 4: Internasjonale Påvirkninger og Konkurranser', isSubheader: false },
    { id: '4.1', title: '4.1 Utenlandske Boksing Stiler og Tradisjoner', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanere på Verdensscenen', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapittel 5: Idrettsstipender og Fremtiden til Utøvere', isSubheader: false },
    { id: '5.1', title: '5.1 Viktigheten av Stipender', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Scholarships America Kan Hjelpe', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Boksestipendier i USA: Hvordan Studere og Bokse i Amerika Kan La Drømmene Dine Gå i Oppfyllelse
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Aerial view of a packed stadium at a college wrestling match."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        The National Championship
      </figcaption>
    </figure>
    Boksing er en sport som har blitt praktisert i tusenvis av år over hele verden. Det er en form for fysisk kamp der to motstandere prøver å dominere hverandre gjennom grep, kast, pinner og andre teknikker. Boksing krever ikke bare fysisk styrke, fart, utholdenhet og fleksibilitet, men også mental skarphet, strategi, disiplin og utholdenhet.
    <br />
    <br />
    I USA er boksing en av de mest praktiserte og sett på sportene. Den har en lang og fengslende historie som går tilbake til kolonitiden. Siden den gang har den utviklet seg til et nasjonalt fenomen med millioner av fans og utøvere. Amerikansk college-boksing representerer toppen av denne utviklingen. Det er nivået der landets beste boksere møtes i spennende konkurranser som ofte fungerer som en trappetrinn til profesjonelle eller olympiske karrierer.
    <br />
    <br />
    Betydningen av college-nivå-boksing i utviklingen av utøvere kan ikke undervurderes. College-boksere får ikke bare muligheten til å vise frem og forbedre sine idrettslige talenter, men også til å studere ved anerkjente universiteter, der de kan tilegne seg verdifulle akademiske ferdigheter og kunnskaper. Dessuten kan de dra nytte av stipend som kan dekke en betydelig del eller hele studieavgiften.
    <br />
    <br />
    Å skaffe seg et boksestipend er imidlertid ikke en enkel oppgave. Det krever mye forberedelse, dedikasjon og veiledning. Heldigvis trenger du ikke å gjøre det alene. Sportbeursamerika.nl er en organisasjon som spesialiserer seg på å hjelpe unge boksetalenter med å realisere drømmen om å studere og bokse i Amerika. Jeg tilbyr deg profesjonell rådgivning, personlig støtte og tilgang til mitt omfattende nettverk av kontakter i den amerikanske bokseverdenen.
    Vil du vite mer om hvordan jeg kan hjelpe deg med å sikre et boksestipend i USA? Les videre! I denne artikkelen vil jeg fortelle deg mer om:
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 1: Historien og Utviklingen av Boksing i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Boksing er en sport som har blitt praktisert siden oldtiden i ulike kulturer. Det er en av de eldste formene for organisert konkurranse, nevnt i gresk mytologi og i Bibelen. Det var også en av de første idrettene som ble inkludert i de antikke olympiske leker.
    <br />
    <br />
    I USA har boksing hatt sin egen historie og utvikling tett knyttet til landets sosiale og politiske kontekst. I dette kapittelet vil vi gi en oversikt over de viktigste fasene og hendelsene i amerikansk boksehistorie og hvordan de bidro til dannelsen av moderne college-boksing.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidlige År
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Begynnelsen av boksing i Amerika kan spores tilbake til kolonitiden da europeiske bosettere brakte sine egne boksestiler og tradisjoner til det nye kontinentet. Disse stilene var primært basert på engelsk catch-as-catch-can boksing, som tillot grep på hvilken som helst del av kroppen unntatt hodet. Det var en populær form for underholdning og utfordring blant kolonistene som ofte organiserte kamper på helligdager eller markedsdager.
      <br />
      <br />
      En av de tidligste kjente bokserne i Amerika var George Washington, som som ung mann var kjent for sine bokseferdigheter. Han beseiret en gang syv motstandere på én dag under en kamp i Virginia. En annen kjent bokser fra denne perioden var Abraham Lincoln, som vant flere kamper som tenåring i Illinois.
      <br />
      <br />
      Det første college-bokselaget ble dannet i 1780 ved Yale University, men det tok mer enn et århundre før college-boksing virkelig tok av. Den første offisielle mellomcollegiale kampen fant sted i 1889 mellom Yale og Columbia University, der Yale vant 5-0. I 1892 ble den første nasjonale mesterskapet holdt i New York City, med åtte lag fra ulike stater. Vinneren var Cornell University, som vant alle sju kampene.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Vekst og Utvikling
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      På slutten av 1800-tallet og begynnelsen av 1900-tallet begynte boksing å spre seg og diversifisere seg i Amerika. Forskjellige regionale stiler oppstod, som for eksempel Iowa-stil boksing, som la vekt på fart og smidighet, eller Oklahoma-stil boksing, som fokuserte på styrke og kontroll. Nye regler ble også introdusert for å gjøre boksing tryggere og rettferdigere, som å forby farlige grep eller innføre tidsbegrensninger.
      <br />
      <br />
      College-boksing vokste også i popularitet og prestisje, med flere lag, konkurranser og fans. I 1928 ble National Collegiate Athletic Association (NCAA) grunnlagt for å regulere og fremme college-boksing. NCAA organiserte årlige nasjonale mesterskap for forskjellige divisjoner basert på størrelsen og nivået til universitetene. Den mest prestisjefylte divisjonen var Divisjon I, som besto av omtrent 80 lag.
      <br />
      <br />
      College-boksing produserte også mange stjerner og legender som oppnådde både nasjonal og internasjonal suksess. Noen eksempler inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Dan Gable: En av de største bokserne gjennom tidene, som dominerte både som utøver og trener. Han vant tre NCAA-titler for Iowa State University, en gullmedalje i OL i 1972 uten å miste et eneste poeng, og 15 NCAA-titler som trener for University of Iowa.
        </li>
        <li>
          John Smith: En seksdobbel verdensmester og to ganger olympisk mester kjent for sine innovative teknikker og taktikker. Han vant to NCAA-titler for Oklahoma State University og ble senere hovedtrener for det samme laget.
        </li>
        <li>
          Cael Sanderson: Den eneste bokseren som har vunnet fire NCAA-titler uten å tape en eneste kamp. Han hadde en perfekt rekord på 159-0 for Iowa State University og vant også en gullmedalje i OL i 2004. Han er for øyeblikket hovedtrener ved Penn State University, der han har vunnet åtte NCAA-titler.
        </li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dette er bare noen få eksempler på veksten og utviklingen av boksing i Amerika, som har ført til den nåværende college-boksingsscenen, som anses å være en av de mest konkurransedyktige og spennende idrettene i landet.
    </MKTypography>
  </MKBox>
</MKBox>




                                  







<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 2: College-boksing i Dag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College-boksing er en av de mest populære og prestisjefylte sportene i Amerika i dag. Den tiltrekker seg millioner av fans og tilskuere som følger de spennende kampene gjennom fjernsyn, internett eller live i arenaene. College-boksing tilbyr også utallige muligheter for studentutøvere som kan dra nytte av stipend, akademisk støtte og profesjonell veiledning.
    <br />
    <br />
    I dette kapittelet vil vi fortelle deg mer om de viktigste konkurransene og konferansene i college-boksing og de beste programmene og prestisjetunge lagene som dominerer sporten.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Viktige Konkurranser og Konferanser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Two college wrestlers in an intense duel on the mat."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          To boksere i aksjon
        </figcaption>
      </figure>
      College-boksing organiseres av NCAA, som har tre divisjoner: Divisjon I, Divisjon II og Divisjon III. Hver divisjon har sine egne regler, kriterier og mesterskap. Den mest konkurransedyktige og kjente divisjonen er Divisjon I, som består av omtrent 80 lag fra forskjellige stater.
      <br />
      <br />
      Lagene i Divisjon I er delt inn i ulike konferanser, som danner regionale eller tematiske grupper. Konferansene organiserer sine egne konkurranser og mesterskap, som fungerer som kvalifiseringer for det nasjonale mesterskapet. De mest fremtredende konferansene i college-boksing inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Big Ten Conference: Den største og sterkeste konferansen i college-boksing, bestående av 14 lag fra Midtvesten og Nordøst-regionene i landet. Big Ten har produsert flest nasjonale mestere, inkludert Penn State, Iowa, Ohio State, Michigan og Minnesota.
        </li>
        <li>
          Big 12 Conference: Den nest største og sterkeste konferansen i college-boksing, bestående av 12 lag fra sør- og vestregionene i landet. Big 12 har også produsert mange nasjonale mestere, inkludert Oklahoma State, Oklahoma, Iowa State og Missouri.
        </li>
        <li>
          Atlantic Coast Conference (ACC): En fremvoksende konferanse i college-boksing, bestående av seks lag fra sørøstregionen av landet. ACC har fått mer oppmerksomhet nylig på grunn av prestasjonene til lag som Virginia Tech, North Carolina State og North Carolina.
        </li>
        <li>
          Eastern Intercollegiate Wrestling Association (EIWA): En historisk konferanse i college-boksing, bestående av 16 lag fra den østlige regionen av landet. EIWA er den eldste boksekonferansen i Amerika, grunnlagt i 1905. EIWA har også produsert noen nasjonale mestere, inkludert Cornell, Lehigh, Princeton og Navy.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dette er bare noen få av de mange konferansene i college-boksing, hver med sine egne rivaliseringer, tradisjoner og fans. Imidlertid er konferanser ikke den eneste faktoren som bestemmer hvor vellykket et lag er. Det er andre aspekter som spiller en rolle i suksessen til et bokseprogram.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Topp Programmer og Prestisjefylte Lag
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et bokseprogram er et begrep som brukes til å referere til organiseringen og strukturen til et universitets bokselag. Et bokseprogram inkluderer aspekter som trener, stab, fasiliteter, rekruttering, trening, finansiering og lagets kultur.
      <br />
      <br />
      Et godt bokseprogram kan utgjøre en betydelig forskjell i utviklingen av studentutøvere. Et godt bokseprogram kan gi studentutøvere:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          En erfaren og kvalifisert trener som kan lære dem hvordan de kan forbedre teknikken, taktikk, kondisjonen og mentaliteten deres.
        </li>
        <li>
          Et dedikert og profesjonelt team som kan støtte dem på områder som ernæring, skadeforebygging, restitusjon, akademisk veiledning og karriereplanlegging.
        </li>
        <li>
          Moderne og velutstyrte fasiliteter der de kan trene på et høyt nivå med egnet utstyr og materiale.
        </li>
        <li>
          En effektiv og etisk rekrutteringsstrategi som kan hjelpe dem med å tiltrekke og beholde talentfulle og motiverte boksere.
        </li>
        <li>
          Et intensivt og variert treningsprogram som kan utfordre dem og forberede dem på ulike kamper og situasjoner.
        </li>
        <li>
          Tilstrekkelig og stabil finansiering som kan hjelpe dem med å dekke kostnadene ved boksing, som reise, utstyr, stipend, og andre utgifter.
        </li>
        <li>
          En positiv og inspirerende kultur som kan motivere og oppmuntre dem til å nå sitt potensial, forfølge sine mål og dele sin lidenskap for sporten.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Det er mange universiteter i Amerika som har utmerkede bokseprogrammer som har vært vellykkede i college-boksing i årevis. Noen av disse programmene inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Penn State: Det nåværende beste laget i college-boksing, som har vunnet det nasjonale mesterskapet åtte ganger siden 2011. Penn State er kjent for sin aggressive og spektakulære boksestil, ledet av trener Cael Sanderson, en av de største bokserne gjennom tidene.
        </li>
        <li>
          Iowa: Det mest vellykkede laget i historien til college-boksing, som har vunnet det nasjonale mesterskapet over 20 ganger siden 1975. Iowa er kjent for sin aggressive og dominerende boksestil, ledet av trener Tom Brands, en tidligere olympisk mester.
        </li>
        <li>
          Oklahoma State: Det nest mest vellykkede laget i historien til college-boksing, som har vunnet det nasjonale mesterskapet over 20 ganger. Oklahoma State er kjent for sin tekniske og taktiske boksestil, ledet av trener John Smith, en av de største bokserne gjennom tidene.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dette er bare noen få av de mange topp programmene og prestisjefylte lagene i college-boksing, hver med sine egne egenskaper, prestasjoner og fans. Imidlertid er det ikke lett å være en del av disse programmene og lagene. Det krever mye talent, hardt arbeid og dedikasjon å sikre et boksestipend.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  







<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 3: Fra College til Olympisk Nivå og Profesjonelle Ligaer
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College-boksing er ikke sluttpunktet for mange studentutøvere som drømmer om en karriere i sporten. Det er heller et springbrett til høyere nivåer der de kan konkurrere med de beste bokserne i verden. I dette kapittelet vil vi fortelle deg mer om veien til toppen i profesjonell eller olympisk boksing og suksessene og utfordringene til amerikanske boksere på verdensscenen.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Veien til Toppen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      College-boksing er en av de mest konkurransedyktige og krevende idrettene i Amerika. Det krever mye talent, hardt arbeid og dedikasjon å sikre et boksestipend, og enda mer for å skinne i nasjonale konkurranser. Men selv om du er en av de beste college-bokserne, er det ingen garanti for suksess på profesjonelt eller olympisk nivå.
      <br />
      <br />
      Det er flere faktorer som gjør det utfordrende å gå fra college-boksing til profesjonell eller olympisk boksing. Noen av disse faktorene inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Stilforskjeller: College-boksing praktiseres i henhold til folkeregler, unike for Amerika. Disse reglene fokuserer på å kontrollere og dominere motstanderen, med poeng tildelt for å ri, unnslippe, vende og nær fall. Profesjonell eller olympisk boksing, derimot, praktiseres i henhold til fri stil eller gresk-romerske regler, brukt internasjonalt. Disse reglene legger vekt på å score poeng gjennom kast, tilt, eksponeringer og fall. Dette betyr at college-boksere må tilpasse teknikken, taktikken og strategien sin for å konkurrere med boksere fra andre land som har mer erfaring med disse stilene.
        </li>
        <li>
          Aldersforskjeller: College-boksing er en idrett for unge utøvere, vanligvis mellom 18 og 23 år gamle. Profesjonell eller olympisk boksing derimot, er en idrett for voksne utøvere, vanligvis mellom 25 og 35 år gamle. Dette betyr at college-boksere må konkurrere med eldre og mer erfarne boksere som har større fysisk styrke, mental modenhet og teknisk forfining.
        </li>
        <li>
          Økonomiske forskjeller: College-boksing er en idrett støttet av universiteter, som gir studentutøvere stipend, fasiliteter, trening og andre fordeler. Profesjonell eller olympisk boksing derimot, mottar lite økonomisk støtte fra regjeringen eller sponsorer. Dette betyr at college-boksere må søke andre inntektskilder for å dekke trening, reise, utstyr og levekostnader.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dette er bare noen av de mange utfordringene som college-boksere må overvinne for å forfølge drømmen om en profesjonell eller olympisk karriere. Imidlertid, til tross for disse utfordringene, er det også mange suksesser og inspirerende historier om amerikanske boksere som har nådd det høyeste nivået.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Profesjonell Boksing og OL
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Profesjonell boksing eller olympisk boksing er høydepunktet av sporten, der de beste bokserne i verden konkurrerer i prestisjetunge konkurranser og arrangementer. Det er to hovedformer for profesjonell eller olympisk boksing: fri stil og gresk-romersk.
      <br />
      <br />
      Fri stil-boksing er en form for boksing der alle typer grep på hele kroppen er tillatt, så lenge de ikke er farlige eller upassende. Fri stil-boksing praktiseres av både menn og kvinner, i ulike vektklasser. Fri stil-boksing er en av de eldste idrettene i de olympiske leker, holdt siden 1904 for menn og siden 2004 for kvinner.
      <br />
      <br />
      Gresk-romersk boksing er en form for boksing der bare grep over livet er tillatt, og bruken av bena for å gripe eller kaste motstanderen er forbudt. Gresk-romersk boksing praktiseres bare av menn, i ulike vektklasser. Gresk-romersk boksing er den eldste formen for boksing i de olympiske leker, holdt siden 1896.
      <br />
      <br />
      Amerikanske boksere har oppnådd mange suksesser både i fri stil og gresk-romersk boksing, både i OL og i andre internasjonale konkurranser som verdensmesterskapet, Panamerikanske leker eller verdenscupen. Noen av de mest vellykkede og velkjente amerikanske bokserne inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Jordan Burroughs: En femdobbel verdensmester og olympisk mester i fri stil-boksing, kjent for sin eksplosive og dynamiske stil. Han vant gullmedalje på OL i 2012 i London og fire verdensmesterskapstitler i 2011, 2013, 2015 og 2017. Han vant også to NCAA-titler for University of Nebraska.
        </li>
        <li>
          Kyle Snyder: En tredobbelt verdensmester og olympisk mester i fri stil-boksing, kjent for sin styrke og teknikk. Han vant gullmedalje på OL i 2016 i Rio de Janeiro og tre verdensmesterskapstitler i 2015, 2017 og 2019. Han vant også tre NCAA-titler for Ohio State University.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dette er bare noen få av de mange suksessene og inspirerende historiene om amerikanske boksere på profesjonelt eller olympisk nivå. De viser at det er mulig å oppnå drømmen din hvis du er villig til å jobbe hardt, lære og vokse.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  







<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 4: Internasjonale Påvirkninger og Konkurranser
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Boksing er en universell sport, praktisert og verdsatt i mange land og kulturer. Boksing har også mange stiler og tradisjoner som har oppstått og utviklet seg i ulike regioner og perioder. I dette kapittelet vil vi fortelle deg mer om de internasjonale påvirkningene og konkurransene i boksing og hvordan de påvirker og utfordrer amerikanske boksere.
  </MKTypography>
  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Utenlandske Boksing Stiler og Tradisjoner
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Overhead view of an NCAA wrestling match in a packed stadium."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          En kamp i NCAA-turneringen
        </figcaption>
      </figure>
      Boksing er en sport som har blitt praktisert siden oldtiden i ulike deler av verden. Det er en av de eldste formene for fysisk uttrykk, ofte assosiert med religiøse, kulturelle eller sosiale betydninger. Det er mange typer boksing, hver med sine egne kjennetegn, regler og teknikker. Noen eksempler inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Sumo: En tradisjonell japansk boksestil betraktet som en nasjonal sport og en hellig rituell. Sumo praktiseres av tunge og sterke boksere som prøver å dytte hverandre ut av en sirkulær ring eller kaste dem i bakken.
        </li>
        <li>
          Mongolsk boksing: En tradisjonell mongolsk boksestil betraktet som et symbol på mot og styrke. Mongolsk boksing praktiseres av muskuløse og smidige boksere som prøver å få hverandre i bakken gjennom grep på overkroppen eller bena.
        </li>
        <li>
          Tyrkisk oljeboksing: En tradisjonell tyrkisk boksestil betraktet som en arv og en kunst. Tyrkisk oljeboksing praktiseres av oljede og muskuløse boksere som prøver å beseire hverandre gjennom grep på buksene eller beltet.
        </li>
        <li>
          Senegalesisk boksing: En tradisjonell senegalesisk boksestil betraktet som en populær og lukrativ sport. Senegalesisk boksing praktiseres av atletiske og fargerike boksere som prøver å kaste hverandre gjennom kast, slag eller spark.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dette er bare noen få av de mange utenlandske boksingstilene og tradisjonene, hver med sin egen historie, kultur og fans. Disse stilene har også påvirket amerikansk college-boksing ved å introdusere nye teknikker, taktikker eller utfordringer. Mange amerikanske college-boksere har reist eller trent i andre land for å utvide horisonten og forbedre ferdighetene sine.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikanere på Verdensscenen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Amerikansk college-boksing fokuserer ikke bare på nasjonale konkurranser, men også på internasjonale konkurranser. Det er mange internasjonale arrangementer der amerikanske college-boksere kan delta for å konkurrere med de beste bokserne i verden. Noen av disse arrangementene inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Olympiske leker: Det største og mest prestisjefylte arrangementet innen profesjonell eller olympisk boksing, avholdt hvert fjerde år. De olympiske leker er det ultimate målet for mange college-boksere som drømmer om å vinne en gullmedalje for landet sitt.
        </li>
        <li>
          Verdensmesterskapet: Det nest største og mest prestisjefylte arrangementet innen profesjonell eller olympisk boksing, avholdt årlig. Verdensmesterskapet er en betydelig mulighet for college-boksere å kvalifisere seg for OL eller etablere sitt rykte på verdensscenen.
        </li>
        <li>
          Verdenscupen: Det nest største og mest prestisjefylte arrangementet innen regional boksing, avholdt årlig. Verdenscupen er en betydelig mulighet for college-boksere å være en del av et landslag som konkurrerer mot andre landslag i et lagspillformat.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dette er bare noen få av de mange internasjonale arrangementene der amerikanske college-boksere kan delta for å vise frem talentene sine og forfølge drømmene sine. Imidlertid er disse arrangementene ikke bare en kilde til ære og berømmelse, men også en kilde til utfordring og vanskelighet. College-boksere må konkurrere med boksere fra andre land som ofte har mer erfaring, stil eller motivasjon.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 5: Idrettsstipender og Fremtiden til Utøvere
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrettsstipender gir mange muligheter for unge utøvere til å vise sitt talent og utvikle sine ferdigheter. En av de mest betydningsfulle mulighetene er å få et idrettsstipend for å studere og konkurrere på college-nivå. I dette kapittelet vil vi diskutere viktigheten av idrettsstipender og hvordan Sport Scholarships America kan hjelpe deg med å oppfylle drømmen din.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Viktigheten av Stipender
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et idrettsstipend er økonomisk støtte gitt av et universitet til en studentutøver for å kombinere studier og idrett. Det kan dekke kostnadene for skolepenger, bøker, bolig, måltider og andre utgifter. I tillegg kan det tilby fordeler som akademisk veiledning, medisinsk hjelp, treningsfasiliteter og utstyr.
      <br />
      <br />
      Å få et idrettsstipend er konkurransedyktig, med mange faktorer som spiller en rolle i tildelingsprosessen, inkludert akademiske prestasjoner, idrettslige resultater, personlighet, motivasjon, lederegenskaper og tilpasningsevne. Det er derfor viktig å forberede seg godt og skille seg ut fra konkurransen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Scholarships America Kan Hjelpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo for Sport Scholarships America, som viser sportsutstyr og en eksamenskappe, symboliserer fokuset på idrettsstipender for studentutøvere."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo for Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America er en organisasjon spesialisert i å veilede unge idrettstalenter til idrettsstipender. Med lidenskapelige og erfarne eksperter støtter Sport Scholarships America studentutøvere på hvert trinn i reisen, fra første orientering til endelig plassering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig inntaksintervju for å diskutere studentutøverens ønsker, muligheter og forventninger.</li>
        <li>En profesjonell evaluering av studentutøverens akademiske og idrettslige nivå.</li>
        <li>Et omfattende nettverk av kontakter med trenere, universiteter og høyskoler.</li>
        <li>Effektiv markedsføring av studentutøverens profil og videoer til potensielle trenere.</li>
        <li>Ekspertråd om de beste alternativene basert på studentutøverens preferanser, mål og budsjett.</li>
        <li>Forhandlinger om de beste stipendtilbudene på vegne av studentutøveren.</li>
        <li>Hjelp til å søke om nødvendige dokumenter, som visum, transkripsjon, diploma og opptaksprøver.</li>
        <li>Forberedelse til studentutøverens liv under stipendet, inkludert kultur, språk, regler og tips.</li>
        <li>Oppfølging og oppfølging under stipendperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vil du utforske stipendmuligheter og oppnå dine idrettslige og akademiske mål? <Link to="/no/Tjenester-Produkter">Se det komplette utvalget</Link> av tjenester som tilbys av Sport Scholarships America og planlegg en konsultasjon i dag.
    </MKTypography>
  </MKBox>
</MKBox>

          
        
         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;