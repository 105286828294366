/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import React, { useState } from 'react'; // Step 1: Import useState
import { Link } from 'react-router-dom';


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Football1.jpg";
import Image2 from "assets/images/Sports_Images/Football2.webp";
import Image3 from "assets/images/Sports_Images/Football3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";
import "../../../../H1asH3.css"


// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Amerikansk fotballstipend i USA: Hvordan sikre en plass på et universitetslag', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapittel 1: Historien og utviklingen av amerikansk fotball', isSubheader: false },
    { id: '1.1', title: '1.1 De tidlige årene', isSubheader: true },
    { id: '1.2', title: '1.2 Vekst og profesjonalisering', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapittel 2: Collegefotball', isSubheader: false },
    { id: '2.1', title: '2.1 De største ligaene og konferansene', isSubheader: true },
    { id: '2.2', title: '2.2 Utvikling av spillere', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapittel 3: Fra College til NFL', isSubheader: false },
    { id: '3.1', title: '3.1 Veien til NFL', isSubheader: true },
    { id: '3.2', title: '3.2 Andre profesjonelle muligheter', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapittel 4: Innflytelsen til amerikansk fotball på amerikansk kultur', isSubheader: false },
    { id: '4.1', title: '4.1 Kulturell påvirkning', isSubheader: true },
    { id: '4.2', title: '4.2 Rollemodeller og inspirasjon', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapittel 5: Idrettsstipender og Fremtiden til Utøvere', isSubheader: false },
    { id: '5.1', title: '5.1 Viktigheten av Stipender', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Scholarships America Kan Hjelpe', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
            <MKTypography variant="h1" className="h1-as-h3" mb={2}>
  Amerikansk fotballstipend i USA: Hvordan sikre en plass på et universitetslag
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
  <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
    <img
      src={Image1}
      alt="Overhead view of a crowded college football game at Indiana University, with players in action on the field and fans in the stands."
      style={{
        maxWidth: "100%",
        border: "2px solid #fff",
        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
        cursor: "pointer",
        outline: "none",
      }}
      onClick={() => openModal1(Image1)}
    />
    <figcaption style={{ 
      textAlign: 'left', 
      fontWeight: 'bold', 
      fontSize: '12px', 
      wordWrap: 'break-word', 
     }}>
      Indiana University fotballkamp sett fra tribunen.
    </figcaption>
  </figure>
  Amerikansk fotball er en sport som oppsto i USA sent på 1800-tallet. Det er en lagsport der to lag bestående av elleve spillere prøver å føre en oval ball over motstanderens mållinje eller sparke den mellom målstengene. Spillet kjennetegnes av fysisk kontakt, taktikk, strategi og spektakulære øyeblikk.
  <br/>
  <br/>
  Amerikansk fotball er ikke bare en sport; det er også en kultur. Det er en essensiell del av amerikansk identitet, verdier og tradisjoner. Det er en kilde til stolthet, lidenskap og inspirasjon for millioner av mennesker. Det er også en industri som genererer milliarder av dollar og skaper tusenvis av arbeidsplasser.
  <br/>
  <br/>
  Men før du kan bli profesjonell spiller i NFL, må du vise talentet ditt på collegenivå. Collegefotball er en viktig del av spillerutviklingen og tilbyr unge utøvere muligheten til å forbedre ferdighetene sine, få utdanning og bygge et nettverk.
  <br/>
  <br/>
  Imidlertid er collegefotball ikke lett tilgjengelig for alle. Det er tusenvis av lag og spillere som konkurrerer om et begrenset antall plasser og stipend. Dessuten er det mange regler og krav du må oppfylle for å kvalifisere deg for et universitetslag.
  <br/>
  <br/>
  Der kan Sportbeursamerika.nl bistå deg. Jeg spesialiserer meg på å matche unge talenter med universitetslag som søker forsterkninger. Jeg kan hjelpe deg med å finne et idrettsstipend, ordne visumet ditt, forberede deg til opptaksprøvene og forhandle med trenere. Med min hjelp kan du oppfylle drømmen om å studere og spille i Amerika.
  <br/>
  <br/>
  Vil du vite hvordan? Fortsett å lese!
</MKTypography>
</MKBox>





{/* Hovedkapitler og underkapitler med plassholdertekst */}
<MKBox id="Chapter 1: The History and Development of American Football" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 1: Historien og utviklingen av amerikansk fotball
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Amerikansk fotball er en sport som oppsto i USA sent på 1800-tallet. Den er en etterkommer av rugby og fotball, to idretter som var populære i Europa. I dette kapittelet vil vi fortelle deg mer om de tidlige årene og veksten og profesjonaliseringen av amerikansk fotball.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 De tidlige årene
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Opprinnelsen til amerikansk fotball tilskrives ofte en kamp mellom to universitetslag i 1869: Rutgers og Princeton. Imidlertid lignet denne kampen mer på en form for fotball enn den moderne amerikanske fotballen. Det var 25 spillere per lag, ballen kunne ikke berøres med hendene, og det var ingen målstenger.
      <br/>
      <br/>
      Den første kampen som lignet moderne amerikansk fotball fant sted i 1874 mellom Harvard og McGill. Denne kampen var en kombinasjon av rugby og fotball, som tillot ballen å bli båret med hendene og inkluderte målstenger. Harvard vant kampen 3-0.
      <br/>
      <br/>
      Universitetenes rolle i å danne de første lagene og ligaene var avgjørende. De var pionerene som definerte reglene og stilen til spillet. I 1873 ble den første interkollegiale fotballforeningen etablert, bestående av Yale, Columbia, Princeton og Harvard. I 1880 ble Walter Camp, en spiller og trener fra Yale, betraktet som "Amerikansk fotballs far" fordi han introduserte betydelige innovasjoner, som å redusere antall spillere til 11, introdusere scrimmage-linjen og nedgangssystemet.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Vekst og profesjonalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Utviklingen av amerikansk fotball til en nasjonal fenomen begynte sent på 1800-tallet og tidlig på 1900-tallet. Spillet ble populært blant studenter, tilskuere og media. Det ble også stadig mer voldelig og farlig, noe som førte til mange skader og til og med dødsfall. I 1905 truet president Theodore Roosevelt med å forby spillet hvis ikke reformer ble gjennomført. Dette førte til etableringen av National Collegiate Athletic Association (NCAA) i 1906, en organisasjon som er ansvarlig for å regulere og organisere collegidrett.
      <br/>
      <br/>
      Påvirkningen av profesjonell amerikansk fotball på college-spillet var også betydelig. Den første profesjonelle amerikanske fotball-ligaen ble grunnlagt i 1892, men ble virkelig vellykket i 1920 med etableringen av American Professional Football Association (APFA), senere omdøpt til National Football League (NFL). NFL trakk til seg mange talentfulle spillere fra høyskoler, noe som tvang dem til å heve nivået sitt for å forbli konkurransedyktige. NFL ble også en betydelig inntektskilde og eksponering for universiteter, ofte ved å leie ut stadionene sine til profesjonelle lag.
      <br/>
      <br/>
      I dette kapittelet har vi fortalt deg mer om historien og utviklingen av amerikansk fotball. I det neste kapittelet vil vi gi ytterligere innsikt i amerikansk fotball på universitetsnivå.
    </MKTypography>
  </MKBox>
</MKBox>







                                              





                                  







<MKBox id="Chapter 2" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Kapittel 2: Collegefotball
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Collegefotball er en av de mest populære og konkurransedyktige idrettene i USA. Hvert år konkurrerer mer enn tusen lag og hundretusenvis av spillere om den nasjonale tittelen, regionale mesterskap og prestisjefylte bowlkamper. I dette kapittelet vil vi fortelle deg mer om de største ligaene og konferansene i collegefotball, og utviklingen av spillere på collegenivå.
    </MKTypography>

    <MKBox id="2.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.1 De største ligaene og konferansene
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            En oversikt over NCAA-divisjonene og de største konferansene i collegefotball. NCAA er organisasjonen som er ansvarlig for å regulere og organisere collegidrett i USA. NCAA deler lagene inn i tre divisjoner: Divisjon I, Divisjon II og Divisjon III. Divisjon I er den høyeste divisjonen og består av to underavdelinger: Football Bowl Subdivision (FBS) og Football Championship Subdivision (FCS). FBS-lagene er de mest prestisjefylte og lønnsomme lagene, som konkurrerer om en plass i College Football Playoff, et system som har bestemt nasjonalmesteren siden 2014. FCS-lagene er mindre kjente og rike, men har sitt eget sluttspillsystem for å bestemme sin mester.
            <br/>
            <br/>
            Innenfor hver divisjon og underavdeling er det forskjellige konferanser, som er grupper av lag som er geografisk eller historisk tilknyttet. Hver konferanse har sin egen timeplan, regler og mesterskap. De største konferansene i FBS er: Atlantic Coast Conference (ACC), Big Ten Conference, Big 12 Conference, Pac-12 Conference, Southeastern Conference (SEC) og American Athletic Conference (AAC). De største konferansene i FCS er: Big Sky Conference, Colonial Athletic Association (CAA), Missouri Valley Football Conference (MVFC) og Southern Conference (SoCon).
            <br/>
            <br/>
            De mest prestisjefylte collegelagene og deres innvirkning på sporten. Noen høyskoler har en lang og vellykket historie i collegefotball, som går tilbake til de tidlige årene av sporten. De har produsert mange nasjonale titler, bowlkamper, individuelle priser og profesjonelle spillere. De har også mange fans, medieoppmerksomhet og økonomiske ressurser. Noen eksempler på disse høyskolene er: Alabama, Notre Dame, Ohio State, Oklahoma, USC, Michigan, Texas, Clemson, Florida State og LSU.
        </MKTypography>
    </MKBox>
    <MKBox id="2.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.2 Utvikling av spillere
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Hvordan collegelag bidrar til teknisk og fysisk utvikling av spillere. Collegelag tilbyr unge spillere muligheten til å forbedre ferdighetene sine under veiledning av erfarne trenere og ansatte. De gir også tilgang til førsteklasses fasiliteter, utstyr og medisinsk behandling. Dessuten eksponerer de spillere for et høyt nivå av konkurranse, der de møter andre talentfulle spillere fra hele landet.
            <br/>
            <br/>
            Eksempler på kjente spillere som utviklet ferdighetene sine på collegenivå. Mange profesjonelle spillere startet karrieren sin på collegenivå, der de utmerket seg for sin prestasjon og potensial. Noen spillere har til og med vunnet priser som Heisman-troféet, den mest prestisjefylte individuelle prisen i collegefotball. Eksempler på disse spillerne inkluderer: Tom Brady (Michigan), Peyton Manning (Tennessee), Joe Montana (Notre Dame), Barry Sanders (Oklahoma State), Jerry Rice (Mississippi Valley State) og Tim Tebow (Florida).
            <br/>
            <br/>
            I dette kapittelet har vi fortalt deg mer om collegefotball. I det neste kapittelet vil vi fortelle deg mer om veien til NFL og andre profesjonelle muligheter.

        </MKTypography>
    </MKBox>
</MKBox>












                                              






                                  









<MKBox id="Chapter 3" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Kapittel 3: Fra College til NFL
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Collegefotball er et trinn på veien til National Football League (NFL), den høyeste profesjonelle ligaen i verden. Hvert år velger NFL-lag hundrevis av spillere i NFL-draften, en begivenhet der lagene tar tur til å velge en spiller fra en gruppe collegespillere. I dette kapittelet vil vi fortelle deg mer om veien til NFL og andre profesjonelle muligheter.
    </MKTypography>

    <MKBox id="3.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.1 Veien til NFL
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Hvordan collegefotball fungerer som et springbrett til National Football League (NFL). Collegefotball er hovedkilden til talent for NFL. De fleste spillerne i NFL har først spilt på collegenivå. Collegefotball tilbyr spillere sjansen til å bevise seg mot andre toppidrettsutøvere, utvikle ferdighetene sine, forbedre statistikkene sine og øke verdien i draften. Collegefotball gir også spillere eksponering og anerkjennelse av speidere, trenere og media.
            <br/>
            <br/>
            Omtrent 250 spillere velges hvert år i NFL-draften, som vanligvis finner sted i april. Draften består av syv runder, hver med 32 valg (ett for hvert lag). Rekkefølgen på valgene bestemmes av resultatet fra forrige sesong, med det dårligste laget som velger først og det beste laget sist. Draften er en spennende og uforutsigbar begivenhet, der noen spillere velges høyere eller lavere enn forventet, og noen ikke velges i det hele tatt. Eksempler på kjente spillere valgt i NFL-draften inkluderer: John Elway (førstevalg i 1983), Troy Aikman (førstevalg i 1989), Peyton Manning (førstevalg i 1998), Tom Brady (199. valg i 2000), Aaron Rodgers (24. valg i 2005) og Patrick Mahomes (10. valg i 2017).
        </MKTypography>
    </MKBox>
    <MKBox id="3.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.2 Andre profesjonelle muligheter
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Alternative veier til en profesjonell karriere i amerikansk fotball utenfor NFL. Ikke alle spillere som spiller på collegenivå, når NFL. Noen spillere blir ikke valgt i draften, eller blir valgt men klarer ikke å sikre seg plass på et lag. Noen spillere blir skadet eller mister motivasjonen eller lidenskapen. Men dette betyr ikke at de må gi opp drømmen om å spille profesjonelt. Det er andre profesjonelle muligheter utenfor NFL, som:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Canadian Football League (CFL): Dette er en profesjonell liga som består av ni lag som spiller i Canada. CFL har noen forskjeller fra NFL, som et større bane, flere spillere per lag og færre downs per drive. CFL tiltrekker mange spillere som ikke kan spille i NFL, men fortsatt ønsker å spille på et høyt nivå.
                </li>
                <li>
                    XFL: Dette er en ny profesjonell liga etablert i 2020 som et alternativ til NFL. XFL består av åtte lag som spiller i USA. XFL har som mål å tilby et raskere, mer spennende og mer innovativt spill enn NFL. XFL tilbyr også flere muligheter for spillere som har blitt oversett eller ignorert av NFL.
                </li>
                <li>
                    European League of Football (ELF): Dette er en ny profesjonell liga etablert i 2021 i et forsøk på å fremme og utvikle amerikansk fotball i Europa. ELF består av åtte lag som spiller i Tyskland, Polen, Spania og Frankrike. ELF har som mål å tilby en plattform for europeiske spillere til å vise frem sitt talent og vokse som utøvere.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            I dette kapittelet har vi fortalt deg mer om veien til NFL og andre profesjonelle muligheter. I det neste kapittelet vil vi fortelle deg mer om innflytelsen til amerikansk fotball på amerikansk kultur.
        </MKTypography>
    </MKBox>
</MKBox>


                                              






                                  



<MKBox id="Chapter 4" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Kapittel 4: Innflytelsen til amerikansk fotball på amerikansk kultur
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Amerikansk fotball er ikke bare en sport, men også en kultur. Det er en betydelig del av amerikansk identitet, verdier og tradisjoner. Det er en kilde til stolthet, lidenskap og inspirasjon for millioner av mennesker. Det er også en industri som genererer milliarder av dollar og skaper tusenvis av jobber. I dette kapittelet vil vi fortelle deg mer om den kulturelle påvirkningen av amerikansk fotball, og rollemodellene og inspirasjonen den gir.
    </MKTypography>

    <MKBox id="4.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.1 Kulturell påvirkning
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image3}
                    alt="Utsikt fra tribunene på det livlige og fulle Wisconsin college fotballstadionet under en spennende kamp, med fans som heier på laget sitt."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal3(Image3)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Kampdag på det fulle stadionet i Wisconsin
                </figcaption>
            </figure>
            Betydningen av amerikansk fotball i amerikansk samfunn og kultur. Amerikansk fotball er en av de mest populære og innflytelsesrike idrettene i USA. Den tiltrekker millioner av fans, tilskuere og medier hvert år. Den er en del av dagliglivet, utdanningen, politikken, økonomien og underholdningen til mange amerikanere. Den er også et symbol på nasjonal enhet, mangfold og stolthet.
            <br/>
            <br/>
            Store arrangementer og deres innvirkning på nasjonal identitet, som Super Bowl. Super Bowl er mesterskapet i NFL som vanligvis holdes i februar. Det er den største og mest sette sportsbegivenheten i verden, med over 100 millioner seere bare i USA. Super Bowl er mer enn en sportsbegivenhet; det er et kulturelt fenomen. Det er en mulighet for familie, venner og kolleger å samles, feste, vedde og heie. Det er også en scene for musikk, reklame, kunst og politikk. Super Bowl blir ofte sett på som en refleksjon av amerikansk kultur, verdier og trender.
        </MKTypography>
    </MKBox>
    <MKBox id="4.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.2 Rollemodeller og inspirasjon
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Amerikanske fotballspillere er ofte rollemodeller og kilder til inspirasjon for unge utøvere som forfølger drømmen om å spille på collegenivå eller på profesjonelt nivå. Noen spillere har ekstraordinære historier om motgang, vedholdenhet og suksess som motiverer og inspirerer andre. Noen spillere påvirker også positivt sitt lokalsamfunn, fans og land gjennom sine handlinger, ord og gjerninger. Eksempler på slike spillere inkluderer: Colin Kaepernick (som knelte under nasjonalsangen for å øke bevisstheten om rasemessig urettferdighet), Russell Wilson (som ledet laget sitt til Super Bowl som en underdog), JJ Watt (som samlet inn millioner av dollar til ofrene for Hurricane Harvey), Drew Brees (som hjalp byen New Orleans med å komme seg etter Hurricane Katrina) og Tom Brady (som fortsatt spiller på et toppnivå i en alder av 43 år).
            <br/>
            <br/>
            I dette kapittelet har vi fortalt deg mer om innflytelsen til amerikansk fotball på amerikansk kultur. I det neste kapittelet vil vi fortelle deg mer om idrettsstipend og fremtiden til amerikansk fotball.
        </MKTypography>
    </MKBox>
</MKBox>











                                              






                                  



<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 5: Idrettsstipender og Fremtiden til Utøvere
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrettsstipender gir mange muligheter for unge utøvere til å vise sitt talent og utvikle sine ferdigheter. En av de mest betydningsfulle mulighetene er å få et idrettsstipend for å studere og konkurrere på college-nivå. I dette kapittelet vil vi diskutere viktigheten av idrettsstipender og hvordan Sport Scholarships America kan hjelpe deg med å oppfylle drømmen din.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Viktigheten av Stipender
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et idrettsstipend er økonomisk støtte gitt av et universitet til en studentutøver for å kombinere studier og idrett. Det kan dekke kostnadene for skolepenger, bøker, bolig, måltider og andre utgifter. I tillegg kan det tilby fordeler som akademisk veiledning, medisinsk hjelp, treningsfasiliteter og utstyr.
      <br />
      <br />
      Å få et idrettsstipend er konkurransedyktig, med mange faktorer som spiller en rolle i tildelingsprosessen, inkludert akademiske prestasjoner, idrettslige resultater, personlighet, motivasjon, lederegenskaper og tilpasningsevne. Det er derfor viktig å forberede seg godt og skille seg ut fra konkurransen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Scholarships America Kan Hjelpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo for Sport Scholarships America, som viser sportsutstyr og en eksamenskappe, symboliserer fokuset på idrettsstipender for studentutøvere."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo for Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America er en organisasjon spesialisert i å veilede unge idrettstalenter til idrettsstipender. Med lidenskapelige og erfarne eksperter støtter Sport Scholarships America studentutøvere på hvert trinn i reisen, fra første orientering til endelig plassering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig inntaksintervju for å diskutere studentutøverens ønsker, muligheter og forventninger.</li>
        <li>En profesjonell evaluering av studentutøverens akademiske og idrettslige nivå.</li>
        <li>Et omfattende nettverk av kontakter med trenere, universiteter og høyskoler.</li>
        <li>Effektiv markedsføring av studentutøverens profil og videoer til potensielle trenere.</li>
        <li>Ekspertråd om de beste alternativene basert på studentutøverens preferanser, mål og budsjett.</li>
        <li>Forhandlinger om de beste stipendtilbudene på vegne av studentutøveren.</li>
        <li>Hjelp til å søke om nødvendige dokumenter, som visum, transkripsjon, diploma og opptaksprøver.</li>
        <li>Forberedelse til studentutøverens liv under stipendet, inkludert kultur, språk, regler og tips.</li>
        <li>Oppfølging og oppfølging under stipendperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vil du utforske stipendmuligheter og oppnå dine idrettslige og akademiske mål? <Link to="/no/Tjenester-Produkter">Se det komplette utvalget</Link> av tjenester som tilbys av Sport Scholarships America og planlegg en konsultasjon i dag.
    </MKTypography>
  </MKBox>
</MKBox>


          
        








         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;