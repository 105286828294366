import React from 'react';
import { Card, CardContent, Typography, Box, CardMedia } from '@mui/material';

const BlogCard = ({ title, description, image, additionalContent, isCircular }) => {
    return (
      <Card sx={{ 
        width: '320px', // Set the fixed width
        height: '310px',
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}>
        {image && (
          <CardMedia
            component="img"
            image={image}
            alt={title}
            sx={{
              width: 'auto', 
              height: 'auto',
              objectFit: 'contain',
              margin: 'auto',
              paddingTop: '16px',
              paddingBottom: '16px',
              borderRadius: isCircular ? '50%' : '0%',


            }}
          />
        )}

        <CardContent sx={{ flexGrow: 1 }}>
          <Typography 
            gutterBottom 
            variant="h5" 
            component="div"
            sx={{ textAlign: 'center' }}>
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
          {additionalContent}
        </CardContent>
      </Card>
    );
};

export default BlogCard;
