/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";


// Material Kit 2 PRO React examples
import RaisedBlogCard from "examples/Cards/BlogCards/RaisedBlogCard";


// Images
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import NCAA from "assets/images/Logos/NCAA_Transparent.png"
import NJCAA from "assets/images/Logos/NJCAA_Transparent.png"
import NAIA from "assets/images/Logos/NAIA_Transparent.png"

function BlogPostThree() {
  const post1 = (NCAA)
  const post2 = (NJCAA)
  const post3 = (NAIA)

  return (
    <>
<MKBox position="relative" variant="gradient" bgColor="dark" mt={{ xs: 0, lg: 8 }} mx={-2}>
        <MKBox
          component="img"
          src={bgPattern}
          alt="background-pattern"
          position="absolute"
          top={0}
          left={0}
          width={{ xs: "100%", lg: "100%" }}
          height={{ xs: "auto", lg: "auto" }}
          opacity={0.6}
        />
        <Container>
          <Grid container justifyContent="center" sx={{ pt: 6, pb: 18 }}>
            <Grid item xs={12} md={7} sx={{ textAlign: "center" }}>
            
              <MKTypography variant="h2" color="white" mb={1}>
              Opportunities at all levels    </MKTypography>
              <MKTypography variant="body2" color="white">
              Every sport, every academic level: explore a world of opportunities at countless American universities for every student-athlete
                            </MKTypography>
            </Grid>
          </Grid>
        </Container>
      </MKBox>

    <MKBox component="section" mt={-10}>
     

      
      <Container>
        
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4} mb={{ xs: 3, lg: 0 }}>
            <RaisedBlogCard
              image={post1}
              category={{ color: "primary", label: "house" }}
              title="NCAA"
              subtitle="National Collegiate Athletic Association"
              description=
              "The NCAA manages college sports in the USA and oversees competitions for more than 1,100 affiliated universities. As a leading organization, it plays a crucial role in the American collegiate sports landscape."
            
              action={{ 
                type: "internal",
                route: "/NCAA",
                color: "info",
              label: "More information", }}
              imageStyle={{ marginTop: "-44px" }}
              shadowTop="-30px"  // Move the shadow down by 50px to match the image's movement

            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} mb={{ xs: 3, lg: 0 }}>
            <RaisedBlogCard
              image={post2}
              category={{ color: "primary", label: "house" }}
              title="NJCAA"
              subtitle="National Junior College Association"
              description="The NJCAA regulates sports at community colleges in the USA and manages competitions for hundreds of affiliated institutions. As a central organization, it is essential for two-year college sports in America, where talent is developed for higher levels."
              
              action={{ 
                type: "internal", 
                route: "/NJCAA",
                color: "info",
                label: "More information", }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} mb={{ xs: 3, lg: 0 }}>
              <RaisedBlogCard
                image={post3}
                category={{ color: "primary", label: "house" }}
                title="NAIA"
                subtitle="National Association of Intercollegiate Athletics"
                description=
                "The NAIA plays a central role in managing sports at small-scale universities in the USA, with hundreds of affiliated institutions. As a leading organization, it plays a vital role in the American intercollegiate sports landscape, promoting talent and integrity."
                action={{ 
                  type: "internal", 
                  route: "/NAIA", 
                  color: "info",
                  label: "More information"}}
                imageStyle={{ marginTop: "50px" }}
                shadowTop="none"
                paddingBelowImage="120px"
              />
            </Grid>
          </Grid>
        </Container>
    </MKBox>
    </>
  );
}

export default BlogPostThree;
