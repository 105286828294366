/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import Image1 from "assets/images/Sports_Images/Tennis1.jpg";
import Image2 from "assets/images/Sports_Images/Tennis2.jpeg";
import Image3 from "assets/images/Sports_Images/Tennis3.jpg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Tennis Scholarships USA: How to Study and Play Tennis in America', isSubheader: false },
  
    { id: 'Hoofdstuk 1', title: '1. The History and Development of Tennis in America', isSubheader: false },
    { id: '1.1', title: '1.1 Early Years and the Influence of Universities', isSubheader: true },
    { id: '1.2', title: '1.2 Growth and Professionalization', isSubheader: true },
  
    { id: 'Hoofdstuk 2', title: '2. Collegiate Tennis Today', isSubheader: false },
    { id: '2.1', title: '2.1 Key Competitions and Conferences', isSubheader: true },
    { id: '2.2', title: '2.2 Top Programs and Prestigious Teams', isSubheader: true },
  
    { id: 'Hoofdstuk 3', title: '3: From College to the Pros', isSubheader: false },
    { id: '3.1', title: '3.1 The Path to Professionalism', isSubheader: true },
    { id: '3.2', title: '3.2 Professional Tennis Circuits', isSubheader: true },
  
    { id: 'Hoofdstuk 4', title: '4. International Influences', isSubheader: false },
    { id: '4.1', title: '4.1 Foreign Players in the Collegiate Scene', isSubheader: true },
    { id: '4.2', title: '4.2 Americans on the International Stage', isSubheader: true },
  
    { id: 'Hoofdstuk 5', title: '5. Tennis Scholarships and the Future', isSubheader: false },
    { id: '5.1', title: '5.1 The Importance of Scholarships', isSubheader: true },
    { id: '5.2', title: '5.2 How Sport Scholarships America Can Help', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Content
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Hide</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Show</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>


























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    US Tennis Scholarships: How to Study and Play Tennis in America
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Georgia tennis complex with packed stands during a college match."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Packed stands at the Georgia tennis complex.
      </figcaption>
    </figure>
    Tennis is a sport that has been played in the United States for more than a century. It began as a recreational activity for the elite but quickly evolved into a competitive and professional sport that attracted millions of fans and players worldwide. One of the key factors contributing to the growth and development of tennis in America is the collegiate system. Universities not only provide high-quality education but also excellent facilities, coaching, guidance, and scholarships for talented tennis players. Playing tennis at the collegiate level allows players to improve their skills, expand their network, increase their chances of a professional career, and earn a valuable degree simultaneously.
    <br />
    <br />
    But how can a young tennis player from the Netherlands or Belgium go to America to study and play tennis? The answer is with a tennis scholarship. A tennis scholarship is financial support granted by a university to a player who meets certain academic and athletic criteria. With a tennis scholarship, you can cover or reduce your tuition fees, books, housing, meals, and other expenses. A tennis scholarship can range from a full scholarship, covering all costs, to a partial scholarship, covering a percentage of the costs.
    <br />
    <br />
    But how do you get a tennis scholarship? That's where Sportbeursamerika.nl can help you. Sportbeursamerika.nl is a platform that assists young tennis players in realizing their dream of going to America. Sportbeursamerika.nl provides advice, guidance, mediation, and support in finding the best university and scholarship for you. Sportbeursamerika.nl has a team of experts who understand how the American collegiate system works and have contacts with hundreds of coaches and universities. Sportbeursamerika.nl has already helped many players reach their potential and change their lives.
    <br />
    <br />
    Want to learn more about tennis in America and how Sportbeursamerika.nl can assist you? Keep reading!
  </MKTypography>
</MKBox>

<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 1: History and Development of Tennis in America
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Tennis is a sport that originated in Europe but quickly spread to other parts of the world, especially to the United States. In this chapter, we will explore how tennis has developed in the USA and how universities have played a significant role in the evolution and professionalization of the sport.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Early Years and the Influence of Universities
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Tennis was first introduced in the USA in the early 19th century by wealthy Americans who had learned the game during their travels to Europe. It quickly became a popular recreational activity for the elite, who built tennis courts on their estates and clubs. The first official tennis match in the USA was played in 1874 between two English brothers, James Dwight and Fred Renshaw, both of whom were Harvard University students. They played on a tennis court they had built on their uncle's estate in Nahant, Massachusetts. The first national championships, then known as the US National Lawn Tennis Association Men's Singles Championship, were held in 1881 in Newport, Rhode Island.
      <br />
      <br />
      Universities were among the first institutions to embrace tennis as a competitive sport. They organized intercollegiate tournaments and formed teams to compete against each other. The first intercollegiate tennis championships were held in 1883, featuring Harvard, Yale, Princeton, and Columbia, and in 1885, the first official intercollegiate tennis championship was recognized. These universities quickly became known as the "Big Four" of American collegiate tennis. They dominated the sport until the early 20th century when other universities like Stanford, California, Chicago, and Cornell joined them.
      <br />
      <br />
      Universities also played a significant role in promoting and spreading tennis among different groups of people. They provided opportunities for women, African Americans, immigrants, and other minorities to practice and excel in the sport. One of the most notable examples is Althea Gibson, who in 1950 became the first African American woman to be admitted to the national championship. She won her first Grand Slam title at the French Championships in 1956 after graduating from Florida A&M University and joining the American Tennis Association, an organization for black tennis players. She later won Wimbledon twice and the US Open twice.
    </MKTypography>
  </MKBox>

  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Growth and Professionalization
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      The second half of the 20th century witnessed significant growth and professionalization of tennis in America. This was made possible, in part, by the rise of television, which gave the sport more visibility and popularity. Additionally, new technologies and materials were introduced, making the game faster and more exciting. Furthermore, new organizations and circuits were established to regulate and promote tennis, including the International Tennis Federation (ITF), the Association of Tennis Professionals (ATP), and the Women's Tennis Association (WTA).
      <br />
      <br />
      These changes also affected collegiate tennis, which had to adapt to the new reality of professional tennis. Universities had to compete with other options to attract and retain talented players. They also had to improve their programs to prepare players for a potential professional career. One of the key steps universities took was the creation of the National Collegiate Athletic Association (NCAA), an overarching organization responsible for organizing and regulating collegiate sports in the USA. The NCAA divided universities into three divisions based on their size, budget, and level of competition. The NCAA organizes annual national championships for each division, both for teams and individuals.
      <br />
      <br />
      Another consequence of the professionalization of tennis was that many American players excelled at the highest level of the sport. Some of them had a collegiate background, proving that collegiate tennis can provide a solid foundation for a successful professional career. Some of the most well-known American tennis players who played at the university level include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Arthur Ashe, who studied at UCLA and was the first African American man to win Wimbledon, the US Open, and the Australian Open.
        </li>
        <li>
          John McEnroe, who attended Stanford and won seven Grand Slam singles titles and nine in doubles.
        </li>
        <li>
          Jimmy Connors, who studied at UCLA and won eight Grand Slam singles titles and two in doubles.
        </li>
        <li>
          Billie Jean King, who attended California State University, Los Angeles, and won 12 Grand Slam singles titles and 16 in doubles. She was also a pioneer in the fight for gender equality in sports.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  









<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 2: University Tennis Today
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Tennis remains one of the most popular and prestigious sports in the American collegiate system. Every year, thousands of players participate in various competitions and conferences, showcasing the best of the sport. In this chapter, we will explore the major competitions and conferences in collegiate tennis and the top programs and prestigious teams that dominate the sport.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Key Competitions and Conferences
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Cameron Norrie in action for TCU in college tennis."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Cameron Norrie in action as a TCU player
        </figcaption>
      </figure>
      As we saw in the previous chapter, the NCAA is the primary organization responsible for organizing and regulating collegiate sports in the USA. The NCAA divides universities into three divisions: Division I, Division II, and Division III. Each division has its own rules, requirements, and championships. For tennis, there are two major competitions held by the NCAA: the team championship and the individual championship.
      <br />
      <br />
      The team championship is a knockout tournament played among the top teams in each division. Teams are selected based on their performance during the season, their ranking, and their regional results. The team championship consists of two phases: the regional phase and the national phase. The regional phase is played among teams from the same region, which are divided into four geographical zones: Northeast, Southeast, Midwest, and West. The winners of each region advance to the national phase, which is played among 16 teams from each division. The team championship follows the format of the Davis Cup, meaning each match consists of three doubles matches and six singles matches.
      <br />
      <br />
      The individual championship is a knockout tournament played among the top players in each division. Players are selected based on their ranking, their performance in the team championship, and their results in other tournaments. The individual championship consists of two categories: singles and doubles. Each category has 64 players in Division I, 48 players in Division II, and 32 players in Division III.
      <br />
      <br />
      In addition to the NCAA championships, there are also other competitions played among universities belonging to the same conference. A conference is a group of universities that collaborate in academic, athletic, and administrative areas. There are more than 30 conferences in the USA, each with its own rules, schedules, and championships. Some of the most well-known conferences include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          The Atlantic Coast Conference (ACC), consisting of 15 universities from the East Coast, such as Duke, North Carolina, Virginia, and Florida State.
        </li>
        <li>
          The Big Ten Conference, consisting of 14 universities from the Midwest, such as Michigan, Ohio State, Illinois, and Wisconsin.
        </li>
        <li>
          The Pacific-12 Conference (Pac-12), consisting of 12 universities from the West Coast, such as Stanford, California, UCLA, and USC.
        </li>
        <li>
          The Southeastern Conference (SEC), consisting of 14 universities from the Southeast, such as Georgia, Florida, Kentucky, and Vanderbilt.
        </li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      These conferences are important not only for the athletic rivalry between universities but also for their academic reputation and prestige. Furthermore, they provide more opportunities for players to excel and qualify for national championships.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Top Programs and Prestigious Teams
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      The level of competition in collegiate tennis is extremely high, meaning that only the best players and teams can thrive and succeed. However, some universities have built a long tradition and a strong reputation in tennis, leading to top programs that achieve excellent results year after year. These universities not only have the best facilities, coaches, and scholarships but also excel in recruiting, training, and guiding their players. Some of the most successful and prestigious collegiate tennis programs include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Stanford University, which has won 21 NCAA team titles in women's tennis and 17 in men's tennis, more than any other university. Stanford has also won 25 NCAA individual titles in women's tennis and 14 in men's tennis. Some of the most well-known players who played for Stanford include John McEnroe, Bob and Mike Bryan, Nicole Gibbs, and Mallory Burdette.
        </li>
        <li>
          USC (University of Southern California), which has won nearly as many NCAA team titles as Stanford in men's tennis and a few in women's tennis. USC has also won more NCAA individual titles than any other university in men's tennis and some in women's tennis. Some of the most well-known players who played for USC include Stan Smith, Alex Olmedo, Steve Johnson, and Danielle Collins.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  









<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 3: From College to the Pros
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    One of the biggest dreams of many young tennis players is to pursue a professional career and shine on the world stage. But how can you make that dream come true? Is college tennis a good preparation for the professional circuit? In this chapter, we will look at the path to professionalism and how college tennis can help players make that leap.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 The Path to Professionalism
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Becoming a professional tennis player is not an easy task. It requires not only a lot of talent but also dedication, discipline, hard work, and sacrifice. It also requires making important decisions that can influence your future. One of the most crucial decisions you have to make is whether to go to college or not.
      <br />
      <br />
      There are pros and cons to both options. If you choose to turn professional immediately after high school, you can fully focus on your tennis and try to secure a spot in the rankings as quickly as possible. You can also take advantage of your youth, energy, and potential. However, you can also face many risks, such as injuries, burnout, financial issues, or a lack of results. Furthermore, you may have to give up or postpone your academic education, limiting your options if tennis doesn't work out.
      <br />
      <br />
      If you choose to go to college, you can benefit from the many advantages that college tennis offers. Not only can you receive a high-quality education that can help you earn a degree and have an alternative career, but you can also enjoy a fantastic life experience that can help you grow as a person. You can also take advantage of the excellent facilities, coaching, guidance, and scholarships that universities provide. However, you may also encounter disadvantages, such as losing time and opportunities to turn professional, falling behind other players who started earlier, or finding it challenging to transition from college to the pros.
      <br />
      <br />
      There is no one-size-fits-all answer to whether you should go to college or not. It depends on your personal situation, goals, preferences, and opportunities. Some players have succeeded by turning professional right away, while others have benefited from their college experience. The most important thing is to make an informed decision that suits you.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professional Tennis Circuits
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      If you decide to become a professional tennis player, you need to understand how the professional tennis circuits work and how to get there. There are different circuits for men and women, each with their own organizations, rules, and tournaments.
      <br />
      <br />
      For men, there are two major circuits: the ATP Tour and the ITF World Tennis Tour. The ATP Tour is the highest level of men's professional tennis, where the best players in the world compete for prestigious titles and prize money. The ATP Tour consists of four categories of tournaments: the Grand Slams, the ATP Finals, the ATP Masters 1000, and the ATP 250/500. To participate in these tournaments, you need to have a high ranking, which is determined by the number of points you earn by winning matches.
      <br />
      <br />
      The ITF World Tennis Tour is the lower level of men's professional tennis, where players aim to improve their ranking and qualify for the ATP Tour. The ITF World Tennis Tour consists of three categories of tournaments: the ITF World Tennis Tour M25/M15, the ITF World Tennis Tour M25/M15+H, and the ITF World Tennis Tour M15/M15+H. To participate in these tournaments, you need to have an ITF ranking, which is determined by the number of points you earn by winning matches.
      <br />
      <br />
      For women, there are also two major circuits: the WTA Tour and the ITF World Tennis Tour. The WTA Tour is the highest level of women's professional tennis, where the best players in the world compete for prestigious titles and prize money. The WTA Tour consists of four categories of tournaments: the Grand Slams, the WTA Finals, the WTA Premier, and the WTA International. To participate in these tournaments, you need to have a high ranking, which is determined by the number of points you earn by winning matches.
      <br />
      <br />
      The ITF World Tennis Tour is the lower level of women's professional tennis, where players aim to improve their ranking and qualify for the WTA Tour. The ITF World Tennis Tour consists of three categories of tournaments: the ITF World Tennis Tour W100/W60/W25/W15, the ITF World Tennis Tour W100/W60/W25/W15+H, and the ITF World Tennis Tour W15/W15+H. To participate in these tournaments, you need to have an ITF ranking, which is determined by the number of points you earn by winning matches.
      <br />
      <br />
      To transition from college tennis to professional tennis, you need to build a ranking in the ITF World Tennis Tour first and then try to qualify for the ATP or WTA Tour. This can be a challenging and lengthy process that requires a lot of effort, patience, and determination. However, it is not impossible, and there are many examples of players who successfully made the transition and are now shining on the professional circuit.
    </MKTypography>
  </MKBox>
</MKBox>
                   






                                  








<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 4: International Influences
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Tennis is a global sport that brings people of different nationalities, cultures, and backgrounds together. This also holds true for college tennis in America, which has a significant diversity and richness of international influences. In this chapter, we will explore how foreign players have impacted the collegiate scene and how American players have reached the international stage.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Foreign Players in the Collegiate Scene
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Players from Virginia celebrating the national championship in college tennis."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Players from Virginia celebrating the national championship
        </figcaption>
      </figure>
      One of the most notable features of college tennis in America is the high percentage of foreign players participating in the sport. According to the Intercollegiate Tennis Association (ITA), in 2020, there were over 3,000 foreign players competing for American universities, which accounted for approximately 40% of the total number of players. These players hailed from more than 100 countries, with the most common countries being: Great Britain, Germany, France, Spain, Australia, Canada, Brazil, Japan, China, and India.
      <br />
      <br />
      The reasons why foreign players choose to come to America to study and play tennis are diverse. Some seek a better academic education that they cannot find in their own country. Others are looking for better sports development opportunities that are unavailable in their homeland. Yet others seek a new life experience that they cannot have in their own country. Whatever their motivation, foreign players bring many advantages to American college tennis.
      <br />
      <br />
      One of the advantages is that foreign players raise the level and quality of the game. They bring new skills, styles, tactics, and strategies that make the game more diverse and interesting. They also challenge American players to do their best and adapt to different situations. Moreover, they contribute to the prestige and reputation of the universities they play for, garnering more attention and recognition.
      <br />
      <br />
      Another benefit is that foreign players enrich the culture and atmosphere of the game. They bring new perspectives, ideas, values, and traditions that make the game more open and inclusive. They also create a sense of community and friendship among players from different backgrounds who collaborate and support each other. Furthermore, they learn from each other and share their experiences, broadening their horizons and promoting personal growth.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Americans on the International Stage
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      While foreign players have a significant impact on American college tennis, American players have also had a major influence on international tennis. Many American players who played in college later found success on the professional circuit and represented their country on the world stage. Some of these players include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          John Isner, who attended the University of Georgia and was a four-time All-American. He is known for his powerful serve and long matches, such as the longest match in history against Nicolas Mahut at Wimbledon in 2010. He has won 15 ATP titles and reached the fourth position in the world rankings.
        </li>
        <li>
          Kevin Anderson, who attended the University of Illinois and was a two-time All-American. He is known for his strong baseline game and consistent performances. He has won six ATP titles and reached two Grand Slam finals: the US Open in 2017 and Wimbledon in 2018.
        </li>
        <li>
          Danielle Collins, who attended the University of Virginia and was a two-time NCAA champion. She is known for her aggressive and expressive playing style and her determination. She has won one WTA title and reached the semifinals of the Australian Open in 2019.
        </li>
        <li>
          Jennifer Brady, who attended UCLA and was a one-time All-American. She is known for her powerful strokes and improved movement. She has won one WTA title and reached the final of the Australian Open in 2021.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  




<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 5: Sports Scholarships and the Future of Athletes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sports scholarships offer numerous opportunities for young athletes to showcase their talent and develop their skills. One of the most significant opportunities is obtaining a sports scholarship to study and compete at the college level. In this chapter, we will discuss the importance of sports scholarships and how Sport Scholarships America can help you make your dream come true.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 The Importance of Scholarships
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A sports scholarship is financial support provided by a university to a student-athlete to combine studies and sports. It can cover the costs of tuition, books, housing, meals, and other expenses. Additionally, it may offer benefits such as academic tutoring, medical care, training facilities, and equipment.
      <br />
      <br />
      Obtaining a sports scholarship is competitive, with many factors playing a role in the awarding process, including academic achievements, athletic performance, personality, motivation, leadership, and adaptability. Therefore, it's essential to prepare well and distinguish yourself from the competition.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 How Sport Scholarships America Can Help
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo of Sport Scholarships America, featuring sports equipment and a graduation cap, representing the focus on sports scholarships for student-athletes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo of Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America is an organization specialized in guiding young sports talents to sports scholarships. With passionate and experienced experts, Sport Scholarships America supports student-athletes at every step of the journey, from initial orientation to final placement. Their services include:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>A personal intake interview to discuss the student-athlete's wishes, possibilities, and expectations.</li>
        <li>A professional evaluation of the student-athlete's athletic and academic level.</li>
        <li>An extensive network of contacts with coaches, universities, and colleges.</li>
        <li>Effective promotion of the student-athlete's profile and videos to potential coaches.</li>
        <li>Expert advice on the best options based on the student-athlete's preferences, goals, and budget.</li>
        <li>Negotiation of the best scholarship offers on behalf of the student-athlete.</li>
        <li>Assistance in applying for necessary documents, such as visa, transcript, diploma, and admission tests.</li>
        <li>Preparation for student-athletes' life during their scholarship, including culture, language, rules, and tips.</li>
        <li>Aftercare and follow-up during the scholarship period.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Want to explore scholarship opportunities and achieve your athletic and academic goals? <Link to="/Services-Products">View the full range</Link> of services offered by Sport Scholarships America and schedule a consultation today.
    </MKTypography>
  </MKBox>
</MKBox>
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;