import { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import SimplePricingCard from "examples/Cards/PricingCards/SimplePricingCard";
import SimplePricingCardRibbon from "examples/Cards/PricingCards/SimplePricingCardRibbon";
import { PopupButton } from "react-calendly";
import { Link } from 'react-scroll';
import TextField from "@mui/material/TextField";  // Tilføj denne linje
import Box from '@mui/material/Box'; // Sørg for at Box er importeret
import emailjs from 'emailjs-com';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Link as RouterLink } from 'react-router-dom';

function Tjenester() {
  const [aktivFane, setAktivFane] = useState(0);
  const [faneType, setFaneType] = useState("månedlig");
  const [visModal, setVisModal] = useState(false);
  const [visEmailModal, setVisEmailModal] = useState(false);
  const [email, setEmail] = useState("");
  const [erEnig, setErEnig] = useState(false);

  const skiftModal = () => setVisModal(!visModal);

  const haandterFaneType = ({ currentTarget }, nyVaerdi) => {
    setAktivFane(nyVaerdi);
    setFaneType(currentTarget.id);
  };

  const haandterEmailIndsendelse = async () => {
    if (email && erEnig) {
      const emailParametre = {
        fra_email: email,
        // andre parametre hvis nødvendige
      };
  
      emailjs.send('service_sj82src', 'template_2eyxhno', emailParametre, 'xOBnOFlYt964jvT3q')
        .then((respons) => {
          console.log('Email sendt succesfuldt', respons.status, respons.text);
          alert('Link anmodet, tjek din indbakke.');
          setVisEmailModal(false);
          setEmail("");
          setErEnig(false);
        }, (fejl) => {
          console.log('Fejl ved afsendelse af email', fejl);
          alert('Anmodning mislykkedes, prøv igen.');
        });
    } else if (!erEnig) {
      alert('Accepter venligst privatlivspolitikken.');
    } else {
      alert('Indtast venligst en emailadresse');
    }
  };

  return (
    <section id="tjenesterSektion">
      <Container sx={{ pb: { xs: 4, lg: 4 }, pt: 12 }}>
          {/* Email Input Modal */}
          <Modal
        open={visEmailModal}
        onClose={() => setVisEmailModal(false)}
        aria-labelledby="modal-modal-titel"
        aria-describedby="modal-modal-beskrivelse"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: { xs: '90%', sm: '70%', md: '50%', lg: '40%' },
            maxWidth: '500px',
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <TextField
            label="Emailadresse"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ mb: 2 }}
          />
          {/* Afkrydsningsfelt for accept af privatlivspolitik */}
          <FormControlLabel
  control={<Checkbox checked={erEnig} onChange={(e) => setErEnig(e.target.checked)} />}
  label={
    <span>
       Jeg har læst{' '}
      <RouterLink 
        to="/Privacy-Policy" 
        component="a" 
        target="_blank" 
        style={{ textDecoration: 'none' }}>
         privatlivspolitikken
      </RouterLink> og accepterer den.
    </span>
  }
  sx={{ my: 2 }}
/>

          <MKButton variant="gradient" color="dark" onClick={haandterEmailIndsendelse}>
            Send
          </MKButton>
        </Box>
      </Modal>

      <Grid container item xs={12} justifyContent="center" md={8} mx="auto" textAlign="center">
      <Grid item xs={12}> {/* Fuld bredde for overskriften */}
    <MKTypography variant="h3" color="text">Tjenester</MKTypography>
  </Grid>
  <Grid item xs={12}> {/* Fuld bredde for brødteksten */}
    <MKTypography variant="body2" color="text">
    Alle tjenester tilbudt af Sport Scholarships America
</MKTypography>
  </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={10}
          lg={8}
          justifyContent="center"
          textAlign="center"
          mx="auto"
          mt={6}
        >
          <AppBar position="static">
          <Tabs value={aktivFane} onChange={haandterFaneType}>
  <Tab
    id="månedlig"
    label={
      <Link 
        to="tjenesterSektion" 
        spy={true}
        smooth={true}
        duration={500}
        offset={-70}
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <MKBox py={0.5} px={2} color="inherit">
          Tjenester
        </MKBox>
      </Link>
    }
  />
  <Tab
    id="årlig"
    label={
      <Link 
        to="produkterSektion" 
        spy={true}
        smooth={true}
        duration={500}
        offset={-70}
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <MKBox py={0.5} px={2} color="inherit">
          Produkter
        </MKBox>
      </Link>
    }
  />
</Tabs>
          </AppBar>
        </Grid>
        <Grid container spacing={3} mt={6}>
        <Modal open={visModal} onClose={skiftModal} sx={{ display: "grid", placeItems: "center" }}>
          <Slide direction="down" in={visModal} timeout={500}>
            <MKBox
              position="relative"
              width="500px"
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              bgColor="white"
              shadow="xl"
            >
              <MKBox display="flex" alginItems="center" justifyContent="space-between" p={2}>
                <MKTypography variant="h5">
Vælg en intake-samtale
</MKTypography>
                <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={skiftModal} />
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox p={2}>
                <MKTypography variant="body2" color="secondary" fontWeight="regular">
                Kære kunde,
                <br />
                  <br />

                  Jeg er glad for, at du er interesseret i min standardtjeneste! For at sikre, at min service passer perfekt til dine specifikke behov, anbefaler jeg kraftigt, at du booker en uforpligtende intake-samtale først.
                  <br />
                  <br />
                  I løbet af denne samtale kan vi diskutere din situation i detaljer og sammen afgøre, hvilken tilgang der bedst passer til dig. Dette sikrer en mere personlig oplevelse og hjælper mig med at optimere servicen til din unikke situation.


                </MKTypography>
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox display="flex" justifyContent="space-between" p={1.5}>
                <MKButton variant="gradient" color="dark" onClick={skiftModal}>
                  luk
                </MKButton>
                
                <PopupButton
    url="https://calendly.com/kay-duit"
    rootElement={document.getElementById("root")}
    text="BOOK MØDE"
    className="custom-calendly-button"
  />
              </MKBox>
            </MKBox>
          </Slide>
        </Modal>
        <Grid item xs={12} sm={6} lg={3}>
  <SimplePricingCard
    color="dark"
    title="Informationsguide"
    description="Komplet informationsguide i PDF"
    price={{ value: "Gratis", type: "" }}
    action={{
      type: "downloadPDF",
      label: "Download PDF",
      onClick: () => setVisEmailModal(true) // Funktion til at vise email input modal
    }}
    specifications={["Komplet informationsguide i PDF"]}
  />
</Grid>
<Grid item xs={12} sm={6} lg={3}>
  <SimplePricingCardRibbon
    color="dark"
    title="Intake-samtale"
    description="60-minutters online konsultation"
    price={{ value: " € 14,99*", type: "" }}
    action={{
      type: "external", // Skift til external for Calendly
      route: "https://calendly.com/kay-duit", // Dit Calendly-link
      label: "BOOK MØDE"
    }}
    specifications={[
      "Afstemning af mål og ambitioner",
      "Vurdering af atletiske præstationer",
      "Akademisk integrationsplan",
      "Individuel strategi for forløb",
      "Analyse af succeschancer i USA"
    ]}
  />
</Grid>

          <Grid item xs={12} sm={6} lg={3}>
  <SimplePricingCard
    color="dark"
    title="Standard"
    description="Din vej til USA"
    price={{ value: "Efter Anmodning", type: "" }}
    action={{
      type: "modal",
      label: "Anmod",
      onClick: skiftModal
    }}
    specifications={[
      "Tilpasset plan",
      "Støtte til oprettelse af sportsportfolio",
      "Støtte til akademisk forberedelse",
      "Promovering til universiteter",
      "Forhandling om sportsstipendier",
      "Rådgivning om valg af universitet og stipendier",
      "Støtte til visumansøgning",
      "Støtte til tilmelding",
      "Støtte til praktiske spørgsmål",
    ]}
  />
</Grid>
<Grid item xs={12} sm={6} lg={3}>
  <SimplePricingCard
    color="dark"
    title="Premium"
    description="Din vej til USA"
    price={{ value: "Efter Anmodning", type: "" }}
    action={{
      type: "modal",
      label: "Anmod",
      onClick: skiftModal
    }}
    specifications={[
      "Tilpasset plan",
      "Støtte til oprettelse af sportsportfolio",
      "Støtte til akademisk forberedelse",
      "Promovering til universiteter",
      "Forhandling om sportsstipendier",
      "Rådgivning om valg af universitet og stipendier",
      "Støtte til visumansøgning",
      "Støtte til tilmelding",
      "Støtte til praktiske spørgsmål",
      "SAT/ACT studiebøger",
      "To-ugentlige Zoom-opkald",
    ]}
  />
</Grid>
        </Grid>
      </Container>
      <Container>
    <Grid item xs={12} style={{ marginBottom: '80px' }}>
      <MKTypography variant="body2" color="text">
        {/* Din tekst her */}
        *Tidligere tilbød jeg intake-samtalen gratis. Desværre førte dette til mange aftaler med ikke-seriøse personer, hvilket var kostbart i forhold til tid. For at sikre kvaliteten af mine tjenester og for at sikre, at samtalerne er værdifulde for begge parter, anmoder jeg nu om et lille gebyr på €14,99. Dette beløb hjælper med at filtrere seriøse forespørgsler, samtidig med at samtalen holdes uformel og fokuseret på dine specifikke behov.

Jeg sætter pris på din forståelse og ser frem til en produktiv og indsigtfuld samtale med dig.
      </MKTypography>
    </Grid>
  </Container>
    
    </section>
  );
}

export default Tjenester;
