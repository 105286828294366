import { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MKTypography from "components/MKTypography";

import MKBox from "components/MKBox";

import SAT from "assets/images/Producten/SAT_kaft.png";
import ACT from "assets/images/Producten/ACT_kaft.png";
import TOEFL from "assets/images/Producten/Toefl_kaft.png";
import Hoodie from "assets/images/Producten/Hoodie_front.png";
import Shirt from "assets/images/Producten/Shirt_front.png";
import { Link } from 'react-scroll';



import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

function Produkter() {
  const [aktivFane, setAktivFane] = useState(1);
  const [fanetype, setFanetype] = useState("månedlig");

  const håndterFanetype = ({ currentTarget }, newValue) => {
    setAktivFane(newValue);
    setFanetype(currentTarget.id);
  };

  
  return (
    <section id="produkterSeksjon">
      <Container sx={{ pb: { xs: 12, lg: 8 }, pt: 0 }}>
      <Grid container item xs={12} justifyContent="center" md={8} mx="auto" textAlign="center">
  {/* Plasser hver MKTypography i sitt eget Grid-element */}
  <Grid item xs={12}> {/* Full bredde for tittelen */}
    <MKTypography variant="h3" color="text">Produkter</MKTypography>
  </Grid>
  <Grid item xs={12}> {/* Full bredde for tekstkroppen */}
    <MKTypography variant="body2" color="text">
    Fra studieguider til varer fra Sport Scholarships America





</MKTypography>
  </Grid>
  <Grid
          container
          item
          xs={12}
          md={10}
          lg={8}
          justifyContent="center"
          textAlign="center"
          mx="auto"
          mt={6}
        >
          <AppBar position="static">
          <Tabs value={aktivFane} onChange={håndterFanetype}>
  <Tab
    id="månedlig"
    label={
      <Link 
        to="tjenesterSeksjon" 
        spy={true}
        smooth={true}
        duration={500}
        offset={-70}
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <MKBox py={0.5} px={2} color="inherit">
          Tjenester
        </MKBox>
      </Link>
    }
  />
  <Tab
    id="årlig"
    label={
      <Link 
        to="produkterSeksjon" 
        spy={true}
        smooth={true}
        duration={500}
        offset={-70}
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <MKBox py={0.5} px={2} color="inherit">
          Produkter
        </MKBox>
      </Link>
    }
  />
</Tabs>
          </AppBar>
        </Grid>
          
          
        <Grid container spacing={3} mt={6} mb={6}>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="SAT Studieveiledning"
      image={SAT}
      description="Forbered deg til SAT"
      action={{ type: "internal", route: "/no/Tjenester-Produkter/SAT-guide", label: "Bestill", color: "primary" }}
    />
  </Grid>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="ACT Studieveiledning"
      image={ACT}
      description="Forbered deg til ACT"
      action={{ type: "internal", route: "/no/Tjenester-Produkter/ACT-guide", label: "Bestill", color: "primary" }}
    />
  </Grid>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="TOEFL Studieveiledning"
      image={TOEFL}
      description="Forbered deg til TOEFL"
      action={{ type: "internal", route: "/no/Tjenester-Produkter/TOEFL-guide", label: "Bestill", color: "primary" }}
    />
  </Grid>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="Hettegenser"
      image={Hoodie}
      description="Sport Scholarships America Hettegenser"
      action={{ type: "internal", route: "/no/Tjenester-Produkter/Hoodie", label: "Bestill", color: "primary" }}
    />
  </Grid>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="Skjorte"
      image={Shirt}
      description="Sport Scholarships America Skjorte"
      action={{ type: "internal", route: "/no/Tjenester-Produkter/T-shirt", label: "Bestill", color: "primary" }}
    />
  </Grid>
</Grid>

          
        
        </Grid>
      </Container>
    </section>
  );
}

export default Produkter;