/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Produktseite: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Programmiert von www.creative-tim.com

 =========================================================

* Die obige Urheberrechtsvermerke und diese Genehmigungserklärung müssen in allen Kopien oder wesentlichen Teilen der Software enthalten sein.
*/

// @mui Materialkomponenten
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React-Komponenten
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Bilder
import post1 from "assets/images/Jeugd.png";
import post3 from "assets/images/Deal.png";
import "../../../../H1asH3.css"


function Profil() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center"></MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox 
                  display="flex" 
                  flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} 
                  justifyContent="space-between" 
                  alignItems="center" 
                  mb={1}
              >
                  <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Teil 2: Meine Zeit in Amerika</MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text">
  <br />
    Meine Zeit in Amerika war wie eine atemberaubende Reise, gefüllt mit unvergesslichen Erlebnissen und persönlichem Wachstum. 
    Dank Fußball reiste ich von Küste zu Küste, begleitet von einer Gruppe von Teamkollegen, die schnell zu meinen besten Freunden wurden. 
    Unsere Tage begannen früh mit intensiven Trainingseinheiten im Fitnessstudio, etwas, an das ich mich in den Niederlanden nicht gewöhnt hatte. 
    Dann schnell zur Universität für Vorlesungen und am Nachmittag zurück zum Training. Es war eine tägliche Verpflichtung, die mich körperlich und geistig herausforderte.
  <br /><br />
    Die Spiele waren Höhepunkte, zu denen Tausende von Fans strömten, und jedes Mal fühlte es sich an, als würden wir für etwas Größeres als uns selbst spielen. 
    Die Adrenalinschübe beim Betreten des Feldes vor solch einer leidenschaftlichen Menge sind unbeschreiblich. 
    Außerdem waren die Reisen, die wir für die Spiele unternahmen, unvergesslich. 
    Im Flugzeug verbrachten wir oft Stunden mit Kartenspielen, und in Hotellobbys teilten wir uns lustige Momente und aufregende Gespräche. 
    Restaurants mit köstlichen Gerichten wurden zu unseren vorübergehenden Essensplätzen, und diese kulinarischen Abenteuer verliehen unseren Reisen eine zusätzliche Dimension. 
    Alles wurde sorgfältig arrangiert, von den Karten im Flugzeug bis zu den Restaurants, in denen wir aßen. 
    Diese Erfahrungen ermöglichten mir einen Einblick in die Größe Amerikas.
  <br /><br />
    Darüber hinaus hatte ich die Gelegenheit, Praktika bei führenden Proficlubs zu absolvieren, darunter die Seattle Sounders. 
    Das Teilen des Feldes mit Spielern wie dem Rekordtorschützen Amerikas, Clint Dempsey, war nichts weniger als surreal, ein Höhepunkt, den ich immer schätzen werde. 
    Aber wie jedes Abenteuer hatte diese Reise ihre Herausforderungen. 
    In meinem letzten Jahr hatte ich mit einer schweren Oberschenkelverletzung zu kämpfen, die meine Fußballambitionen vorübergehend stoppte. 
    Dies war eine Zeit der persönlichen Reflexion und der Widerstandsfähigkeit, aus der ich gestärkt hervorging.
  <br /><br />
    Auch meine akademische Reise in Amerika war ebenso wichtig. Trotz des intensiven Fußballtrainingsplans und der umfangreichen Reisen erhielt ich perfekte Anleitung, um meine Studien fortzusetzen. 
    Es war keine leichte Aufgabe, da ich oft aufgrund meiner Beteiligung am Fußballteam Vorlesungen verpasste. 
    Dennoch war ich entschlossen, erfolgreich zu sein, und das tat ich mit Stolz, mit Auszeichnung, magna cum laude, abschließend. 
    Dieser akademische Erfolg war ein Zeugnis meiner Hingabe sowohl an meinen Sport als auch an meine Ausbildung.
  <br /><br />
    Während meines Abenteuers in Amerika habe ich typisch amerikanische Partys erlebt, die direkt aus einem Film zu stammen schienen. 
    Diese lebhaften gesellschaftlichen Zusammenkünfte, voller Musik, Tanz und lauten Lachen, boten mir die Möglichkeit, nicht nur als Sportler und Student, 
    sondern auch als Sozialbegeisterter zu wachsen. Diese Partys fügten meinem Studentenleben eine zusätzliche Dimension hinzu und bereicherten meine sozialen Erfahrungen.
  <br /><br />
    Aber Amerika bedeutete für mich mehr als nur Fußball, Spiele, gesellschaftliche Ereignisse und akademische Leistungen. 
    Es war eine Gelegenheit, meinen sozialen Kreis zu erweitern und lebenslange Freundschaften zu schließen. 
    Dank dieser Freundschaften konnte ich das Land auf neue Weisen entdecken. 
    Vom Spring Break in Mexiko, wo wir Sonne, Meer und Strand genossen, bis hin zur Feier von Thanksgiving mit den Familien von Freunden, 
    die ich jetzt meine amerikanische Familie nennen kann. Ihr herzliches Willkommen hat mich wirklich zu einem Teil ihres Lebens gemacht, 
    und diese Erfahrungen haben meine Zeit in Amerika noch reicher gemacht.
  <br /><br />
  Diese Zeit in meinem Leben vertiefte meine Liebe sowohl zum Fußball als auch zur Bildung und führte mich auf den Weg, dem ich jetzt folge, um anderen zu helfen, ihre Träume in Amerika zu verwirklichen. 
  Bleiben Sie dran, denn im Teil 3 werde ich teilen, wie meine Rückkehr in die Niederlande und die Gründung meines Unternehmens mein Leben in eine neue Richtung führten.
</MKTypography>
              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="Teil 1: Aufwachsen in den Niederlanden"
                    action={{
                      type: "internal",
                      route: "/de/Über-Mich/Aufwachsen",
                      color: "info",
                      label: "mehr lesen",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="Teil 3: Zurück in die Niederlande"
                    action={{
                      type: "internal",
                      route: "/de/Über-Mich/Meine-Rückkehr-in-die-Niederlande",           
                      color: "info",
                      label: "mehr lesen",
                    }}
                  />
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profil;
