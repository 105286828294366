// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";
import "../../../../H1asH3.css"

// Images
import profilePicture from "assets/images/Profielfoto_Kay_Duit.jpeg";

function Profile() {
  return (
    <MKBox component="section" pt={{ xs: 20, sm: 20,  md: 6}} pb={{ xs: 6, sm: 0}}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
            <MKAvatar src={profilePicture} alt="Profielfoto van Kay Duit, oprichter en eigenaar" size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox 
                display="flex" 
                flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} 
                justifyContent="space-between" 
                alignItems="center" 
                mb={1}
              >
                <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Kay Duit</MKTypography>
                <MKBox display="flex" flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} gap={1}>
                <a href="https://www.linkedin.com/in/kayduit/" target="_blank" rel="noopener noreferrer">
                <MKSocialButton color="linkedin" style={{ width: '140px' }}>
                    <MKBox component="i" color="inherit" mr={0.5} className="fab fa-linkedin" />
                    linkedin
                  </MKSocialButton>
                  </a>
                  <a href="https://www.instagram.com/kayduit/" target="_blank" rel="noopener noreferrer">
                  <MKSocialButton color="instagram" style={{ width: '140px' }}>
                    <MKBox component="i" color="inherit" mr={0.5} className="fab fa-instagram" />
                    instagram
                  </MKSocialButton>
                  </a>
                  <a href="/Podcast">
                  <MKSocialButton color="pinterest" style={{ width: '140px' }}>
                    <MKBox component="i" color="inherit" mr={0.5} className="fas fa-podcast" />
                    podcast
                  </MKSocialButton>
                  </a>
                </MKBox>
              </MKBox>
              <br />
              <MKBox>
  <MKTypography variant="body1" fontWeight="light" color="text">
    On a sultry summer evening in America, I stood on the sidelines of the soccer field, waiting for the whistle to storm onto the field. My heart was pounding in my throat, not because of the upcoming game, but because of the realization of how far I had come. Three years earlier, I had taken a plane from my home country to the U.S., a leap into the deep, in search of adventure and the opportunity to realize both my athletic and academic dreams.
    <br /><br />
    During those four years, I had experienced so much more than just the soccer matches and the study. There were the late-night dinners after a big win, the flights to away games in distant states, the friendships I had built, and the countless times I realized how much bigger the world was than the small place I came from.
    <br /><br />
    That evening, as the national anthem played and the stands were filled with thousands of cheering fans, I thought of all the young students from around the world who, just like me, dreamed of such opportunities. And I made a commitment to myself that once my journey in America concluded, I would extend a helping hand to these aspiring students, irrespective of where they came from, aiding them in achieving dreams similar to mine.
  </MKTypography>
</MKBox>

              <MKTypography
                component="a"
                href="#"
                variant="body1"
                fontWeight="light"
                color="info"
                mt={3}
                sx={{
                  width: "max-content",
                  display: "flex",
                  alignItems: "center",
                  "& .material-icons-round": {
                    transform: `translateX(3px)`,
                    transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                  },
                  "&:hover .material-icons-round, &:focus .material-icons-round": {
                    transform: `translateX(6px)`,
                  },
                }}
              >
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
