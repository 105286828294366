/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState } from 'react'; // Step 1: Import useState


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";


// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Logos/NCAA.png";
import post3 from "assets/images/Logos/NJCAA.png";



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {

  const articleStructure = [
    { id: 'intro', title: 'The NAIA: An Alternative Route in College Sport in America', isSubheader: false },
  
    { id: 'Chapter 1', title: 'The Origin and Development of the NAIA', isSubheader: true },
    { id: '1.1', title: 'The NAIA and its Unique Divisions', isSubheader: true },
    { id: '1.2', title: 'The Academic Mission', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Finances, Media, and Visibility', isSubheader: true },
    { id: '2.1', title: 'Challenges and the Way Forward', isSubheader: true },
    { id: '2.2', title: 'In Conclusion', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
     <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Content
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Hide</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Show</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="intro" mb={3}>
              <MKTypography variant="h1" className="h1-as-h3" mb={2}>The NAIA: An Alternative Route in College Sport in America

</MKTypography>
              </MKBox>
              <MKBox id="Chapter 1" mb={3}>

              <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              The Origin and Development of the NAIA
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              The National Association of Intercollegiate Athletics, or NAIA, is a crucial entity in the American college sports landscape, albeit less known than the NCAA. Founded in 1937, the NAIA has evolved into a renowned organization that offers student-athletes a unique opportunity to fulfill their athletic and academic aspirations.

<br />
                <br />
                From the outset, the NAIA has focused on providing opportunities to smaller educational institutions, with an emphasis on creating a balanced experience for student-athletes. The organization promotes both competitive sports and academic excellence, with the belief that the two can go hand in hand.

</MKTypography>
<MKBox id="1.1" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
                <br />
                The NAIA and its Unique Divisions
                </MKTypography>
                </MKBox>

                <MKTypography variant="body1" fontWeight="light" color="text"> 
                In contrast to the NCAA, the NAIA is more compactly organized, with a division into two divisions, both offering a rich blend of sports and education:

<br /><br />
  <MKTypography component="span" variant="body1" fontWeight="bold">
    Division I:{' '}
  </MKTypography>
  In this division, we find schools that offer a higher level of athletic scholarships and where sports competition is highly competitive. Despite the smaller size of the schools compared to NCAA Division I institutions, the NAIA Division I provides a stage for top athletes to showcase their skills and develop.
  <br />
  <br />
  <MKTypography component="span" variant="body1" fontWeight="bold">
    Division II:{' '}
  </MKTypography>
  This division places even more emphasis on the balance between sports, academics, and campus involvement. Athletic scholarships are more limited, but the opportunities for student-athletes to excel in both their sport and their studies are plentiful.
  </MKTypography>

  <MKBox id="1.2" mb={3}>

  <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              The Academic Mission
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              The NAIA places great value on the academic achievements of its student-athletes. Strict academic standards have been established to ensure participation in sports, and affiliated schools are dedicated to providing the necessary academic support. This results in an environment where student-athletes are encouraged to strive for excellence in every aspect of their lives.

                <br />
                </MKTypography>
                     





                                  







                <MKBox id="Chapter 2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Finances, Media, and Visibility
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
While the NAIA may not be able to compete financially with the massive revenues of the NCAA, it still plays a significant role in funding athletic scholarships and improving facilities. Additionally, the NAIA gains visibility through streaming services and social media, helping to spread the message of balanced athletics and academics.


<br />
</MKTypography>
<MKBox id="2.1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Challenges and the Way Forward
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
Like the NCAA, the NAIA faces challenges, including navigating the complexity of student-athletes' amateur status and ensuring equality in sports. However, with its unique position and dedication to the development of student-athletes, the NAIA is well-positioned to continue making a positive impact on the lives of many young athletes.

</MKTypography>
<MKBox id="2.2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
In Conclusion
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
The National Association of Intercollegiate Athletics provides a valuable and unique alternative in the world of American college sports. With its emphasis on balance, academic excellence, and athletic competition, the NAIA remains an essential force in shaping the future of student-athletes. As it looks forward and continues to evolve, its commitment to the development of young people remains unwavering, leaving a lasting and positive impact on the American sports and education landscape.
<br />


</MKTypography>

              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="NCAA"
                    description="Het Hart van College Sport in Amerika "
                    action={{
                      type: "internal",
                      route: "/NCAA",
                      color: "info",
                      label: "lees meer",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="NJCAA"
                    description="De Springplank naar Grote Colleges en de NCAA "
                    action={{
                      type: "internal",
                      route: "/NJCAA",
                      color: "info",
                      label: "lees meer",
                    }}
                  />
                </Grid>
              </Grid>

            </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;