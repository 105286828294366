/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Waterpol1.jpeg";
import Image2 from "assets/images/Sports_Images/Waterpolo2.jpeg";
import Image3 from "assets/images/Sports_Images/Waterpolo3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Vattenpolostipendier i Amerika: Hur man Studerar och Spelar i Stjärnornas och Rändernas Land', isSubheader: false },
  
    { id: 'Kapitel 1', title: 'Kapitel 1: Historia och Utveckling av Vattenpolo i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Tidiga År', isSubheader: true },
    { id: '1.2', title: '1.2 Tillväxt och Professionalisering', isSubheader: true },
  
    { id: 'Kapitel 2', title: 'Kapitel 2: Universitetsvattenpolo Idag', isSubheader: false },
    { id: '2.1', title: '2.1 Viktiga Tävlingar och Konferenser', isSubheader: true },
    { id: '2.2', title: '2.2 Topprogram och Prestigefyllda Lag', isSubheader: true },
  
    { id: 'Kapitel 3', title: 'Kapitel 3: Från College till Proffsen', isSubheader: false },
    { id: '3.1', title: '3.1 Vägen till Professionalism', isSubheader: true },
    { id: '3.2', title: '3.2 Professionella Ligor', isSubheader: true },
  
    { id: 'Kapitel 4', title: 'Kapitel 4: Internationella Influenser och Tävlingar', isSubheader: false },
    { id: '4.1', title: '4.1 Utländska Influenser', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikaner i Internationella Tävlingar', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapitel 5: Idrottsstipendier och Framtiden för Idrottare', isSubheader: false },
    { id: '5.1', title: '5.1 Vikten av Stipendier', isSubheader: true },
    { id: '5.2', title: '5.2 Hur Sport Scholarships America Kan Hjälpa', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innehåll
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Dölj</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Visa</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>





















          <MKBox id="introduktion" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Vattenpolostipendier i Amerika: Hur man Studerar och Spelar i Stjärnornas och Rändernas Land
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Stadion fylld av fans som väntar på en collegematch i vattenpolo."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Lugnet före matchen
      </figcaption>
    </figure>
    Vattenpolo härstammar från Europa i slutet av 1800-talet som en form av vattenrugby och vann snabbt popularitet i olika länder, inklusive Amerika. Den första officiella matchen i Amerika spelades 1888 mellan två klubbar i New York. Sedan dess har vattenpolon utvecklats betydligt i Amerika, både på professionell och universitetsnivå.
    <br />
    <br />
    Universitetsvattenpolo är en av de primära faktorerna i utvecklingen av vattenpolospelare i Amerika. Många spelare börjar sina karriärer i gymnasiet och går sedan vidare till college, där de har möjlighet att tävla mot de bästa lagen och spelarna i landet. Universitetsvattenpolo erbjuder också en utmärkt akademisk utbildning, vilket gör det möjligt för spelarna att kombinera sin passion för sporten med sina utbildningsmål.
    <br />
    <br />
    Men hur kan du, som en holländsk student, studera och spela i Amerika? Det är där Sportbeursamerika.nl kan hjälpa till. Sportbeursamerika.nl är en organisation specialiserad på att hjälpa studenter att få vattenpolostipendier i Amerika. Sportbeursamerika.nl har ett team av experter som kan guida dig genom varje steg i processen, från att hitta rätt universitet till att hantera alla praktiska frågor.
    <br />
    <br />
    Om du är intresserad av att få ett vattenpolostipendium i Amerika, läs vidare för att lära dig mer om denna spännande möjlighet.
  </MKTypography>
  {/* Lägg till punktlista eller annat innehåll vid behov */}
</MKBox>
{/* Huvudkapitel och underkapitel med platshållartext */}
<MKBox id="Kapitel 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Historia och Utveckling av Vattenpolo i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Vattenpolo har en lång och rik historia i Amerika. Det är en av de äldsta olympiska sporterna, först spelad i de olympiska spelen 1900 i Paris, där även Amerika deltog. Sedan dess har Amerika deltagit i varje olympisk vattenpolotävling, förutom 1976 och 1980 när det drog sig tillbaka i protest mot de politiska situationerna i Sydafrika och Sovjetunionen, respektive.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidiga År
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      De första vattenpoloklubbarna i Amerika grundades i New York och Boston i slutet av 1800-talet. De spelade enligt de engelska reglerna, som var mycket tuffare och våldsammare än de moderna reglerna. Sporten blev snabbt populär bland invandrare från Europa, särskilt från Irland och Skottland. Det första nationella mästerskapet hölls 1890 och vanns av Sydenham Swimmers Club.
      <br />
      <br />
      De första universitetslagen bildades i början av 1900-talet, särskilt på östkusten. Ivy League-universitet som Harvard, Yale och Princeton dominerade sporten fram till 1920-talet. Den första intercollegiate-tävlingen organiserades 1912 och vanns av Princeton.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Tillväxt och Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      På 1920-talet började vattenpolo sprida sig till andra delar av landet, särskilt till Kalifornien. En ny spelstil, fokuserad på hastighet, smidighet och skott istället för fysisk kontakt, utvecklades där. Kaliforniens lag blev snabbt de bästa i landet och vann de flesta nationella och intercollegiate-titlar.
      <br />
      <br />
      På 1930-talet uppstod de första professionella ligorna i Amerika, såsom American Water Polo League och Pacific Coast Water Polo League. Dessa ligor lockade många åskådare och medieuppmärksamhet, vilket höjde nivån och populariteten för sporten. Många professionella spelare var också universitetsspelare eller examinander som fortsatte sina karriärer efter sina studier.
      <br />
      <br />
      En av de centrala figurerna i Amerikansk vattenpolos historia var James "Jimmy" Smith, ansedd som "modern vattenpolons fader." Han var spelare, tränare, domare, organisatör och främjare av sporten. Han spelade för olika klubbar och universitet, inklusive UCLA, där han senare blev tränare. Han ledde det amerikanska laget till två olympiska bronsmedaljer 1924 och 1932. Han var också en av grundarna av International Swimming Hall of Fame, där han blev invald 1965.
    </MKTypography>
  </MKBox>
</MKBox>




                                  







<MKBox id="Kapitel 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Universitetsvattenpolo Idag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Universitetsvattenpolo är en av de mest prestigefyllda och konkurrenskraftiga idrotterna i Amerika. Det erbjuder en unik möjlighet för studenter att kombinera sina akademiska och idrottsliga ambitioner och att tävla mot de bästa spelarna i landet. Universitetsvattenpolo är också en betydande källa till talang för det nationella laget och professionella ligor.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Viktiga Tävlingar och Konferenser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Action i vattnet: spelare skjuter på målet under en vattenpolomatch."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Spelare i aktion
        </figcaption>
      </figure>
      Universitetsvattenpolo regleras av National Collegiate Athletic Association (NCAA), som delar upp sporten i tre divisioner baserat på nivån och antalet stipendier som är tillgängliga. Division I är den högsta divisionen och består av cirka 30 lag, främst från Kalifornien. Division II och III har vardera cirka 20 lag, mer spridda över landet.
      <br />
      <br />
      Varje år hålls en nationell mästerskapsturnering för varje division, där de bästa lagen från varje konferens tävlar om titeln. En konferens är en grupp av universitet som är geografiskt eller historiskt förbundna och regelbundet tävlar mot varandra. De stora konferenserna för vattenpolo inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Mountain Pacific Sports Federation (MPSF), som består av åtta lag från Kalifornien, inklusive UCLA, Stanford, USC och Berkeley. Denna konferens anses vara den starkaste och mest prestigefyllda i landet och har vunnit flest nationella titlar.
        </li>
        <li>
          Western Water Polo Association (WWPA), som består av nio lag från Kalifornien, Colorado, Utah och Hawaii. Denna konferens är den näst starkaste i landet och har också vunnit några nationella titlar.
        </li>
        <li>
          Collegiate Water Polo Association (CWPA), som består av 18 lag från östkusten, inklusive Harvard, Princeton, Brown och Navy. Denna konferens är den tredje starkaste i landet och har ibland vunnit en nationell titel.
        </li>
        <li>
          Southern California Intercollegiate Athletic Conference (SCIAC), som består av nio lag från södra Kalifornien, inklusive Pomona-Pitzer, Claremont-Mudd-Scripps och Occidental. Denna konferens är den fjärde starkaste i landet och tävlar främst i Division III.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Topprogram och Prestigefyllda Lag
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Universitetsvattenpolo har flera ledande program som är kända för sina utmärkta prestationer, både inom idrott och akademik. Dessa program lockar många talangfulla spelare som gynnas av de högkvalitativa faciliteterna, tränarna och utbildningen de erbjuder. Några av dessa program är:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          University of California, Los Angeles (UCLA) är känt för sitt exceptionella vattenpoloprogram, som rankas bland de mest framgångsrika i historien. Med ett imponerande antal nationella titlar för både herr- och damlaget intar programmet en framträdande plats inom sporten. Matcherna spelas på Spieker Aquatics Center, med plats för 2 500 åskådare. UCLA-laget är känt för sin snabba och dynamiska spelstil och har producerat ett betydande antal olympiska medaljörer.
        </li>
        <li>
          Stanford University skryter också med ett prestigefyllt vattenpoloprogram med talrika nationella titlar för både herr- och damlaget. Lagen spelar sina hemmamatcher på Avery Aquatic Center, som rymmer 2 530 åskådare. Stanfords vattenpololag är kända för sina tekniska och taktiska färdigheter och har också bidragit till utvecklingen av många olympiska medaljörer.
        </li>
        <li>
          University of Southern California (USC) har också ett starkt vattenpoloprogram med flera nationella mästerskap för både herr- och damlag. Deras hemmabas är Uytengsu Aquatics Center, med plats för 2 500 åskådare. USC-laget kännetecknas av en fysisk och aggressiv spelstil och har också tränat en betydande mängd olympiska medaljörer.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>
                                              






                                  







<MKBox id="Kapitel 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Från College till Proffsen
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Universitetsvattenpolo är inte bara ett bra sätt att studera och spela i Amerika utan också en möjlig trampsten till en professionell karriär. Många universitetsspelare går vidare och spelar i professionella ligor, både i Amerika och utomlands, efter examen. Vissa av dem väljs också till det nationella laget, som deltar i internationella turneringar som OS, världsmästerskap och världsligan.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Vägen till Professionalism
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Trots att vattenpolo är en populär och konkurrenskraftig sport i Amerika är den ännu inte lika kommersiell och lukrativ som vissa andra sporter som basket, fotboll eller baseboll. Därför finns det inte många professionella ligor eller lag i Amerika, och lönerna och prispengarna är relativt låga. De flesta professionella spelare har också ett annat jobb eller en annan inkomstkälla vid sidan av sin vattenpolokarriär.
      <br />
      <br />
      Den främsta professionella ligan i Amerika är National Water Polo League (NWPL), som etablerades 2018 som en efterträdare till American Water Polo League (AWPL). NWPL består av åtta lag, var och en bestående av 15 spelare. Lagen spelar en ordinarie säsong med 14 matcher, följt av en slutspelsturnering för att avgöra mästaren. Nuvarande mästare är Los Angeles Water Polo Club, som vann titeln 2022.
      <br />
      <br />
      De flesta spelarna i NWPL är före detta universitetsspelare eller akademiker som fortsätter sina karriärer efter sina studier. Några av dem är också medlemmar i det nationella laget, som regelbundet tränar och spelar på Olympic Training Center i Colorado Springs. Det nationella laget tränas av Dejan Udovicic, en före detta serbisk spelare och tränare som varit vid rodret sedan 2013.
      <br />
      <br />
      En av de mest framgångsrika alumner av universitetsvattenpolo är Tony Azevedo, som anses vara en av de största spelarna genom tiderna. Han spelade för Stanford University, där han blev utsedd till NCAA Player of the Year fyra gånger. Han spelade också för flera professionella lag i Europa och Amerika, inklusive Brescia (Italien), Olympiacos (Grekland) och Long Beach Shore (Amerika). Han tävlade också i fem OS, och vann en silvermedalj 2008. Han kallas ofta "The Savior" eftersom han ofta gjorde avgörande mål för sitt lag.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professionella Ligor
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Förutom NWPL finns det andra professionella ligor i Amerika, såsom Major League Water Polo (MLWP) och Premier Water Polo League (PWPL). Dessa ligor är emellertid mindre etablerade och mindre populära än NWPL och har färre lag och spelare. De erkänns heller inte av USA Water Polo, den nationella federationen som ansvarar för att organisera och reglera vattenpolo i Amerika.
      <br />
      <br />
      Många amerikanska spelare väljer därför att fortsätta sina professionella karriärer utomlands, där det finns fler möjligheter och bättre förhållanden. Europa är den primära destinationen för amerikanska vattenpolospelare eftersom kontinenten har några av de starkaste och mest prestigefyllda ligorna i världen. Några av dessa ligor inkluderar:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          LEN Champions League, ansedd som den främsta klubbturneringen i Europa. Den består av 16 lag från olika länder som tävlar om den europeiska titeln. Nuvarande mästare är Pro Recco (Italien), som vann titeln 2021.
        </li>
        <li>
          LEN Euro Cup, betraktad som den andra klubbturneringen i Europa. Den består av 32 lag från olika länder som tävlar om en plats i Champions League. Nuvarande mästare är Orvosegyetem SC (Ungern), som vann titeln 2021.
        </li>
        <li>
          Nationella ligor organiserade av respektive länders förbund. Några av de starkaste och mest populära nationella ligorna inkluderar Serie A1 (Italien), Liga Premaat (Spanien), A1 Ethniki (Grekland) och OB I (Ungern).
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  





<MKBox id="Kapitel 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationella Influenser och Tävlingar
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Vattenpolo är en global sport som spelas och följs av miljontals människor runt om i världen. Det är också en sport som ständigt utvecklas och innovates, tack vare inflytandet från olika kulturer, stilar och traditioner. Amerikansk vattenpolo är inget undantag, då den har lärt sig och gynnats av internationell vattenpolo, både på klubb- och nationell nivå.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Utländska Influenser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="NCAA water polo championship match"
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          En match för NCAA-mästerskapet
        </figcaption>
      </figure>
      Som vi har sett har vattenpolo sitt ursprung i Europa och spreds till andra kontinenter, inklusive Amerika. Därför har europeisk vattenpolo haft ett betydande inflytande på amerikansk vattenpolo, särskilt när det gäller teknik, taktik och strategi. Många amerikanska spelare och tränare har förbättrat sina färdigheter och kunskaper genom att spela eller träna i Europa eller lära sig av europeiska spelare och tränare.
      <br />
      <br />
      En av de mest inflytelserika europeiska länderna för amerikansk vattenpolo är Ungern, som anses vara den mest framgångsrika vattenpolonationen i världen. Ungern har vunnit 15 olympiska guldmedaljer, fler än något annat land. Ungern är känt för sin kreativa och intelligenta spelstil, som bygger på stark bollhantering, snabb passning och precisionsavslut. Många ungerska spelare och tränare har lämnat sitt avtryck på amerikansk vattenpolo, som Tibor Benedek, Zoltan Szecsi och Attila Banhidy.
      <br />
      <br />
      Ett annat inflytelserikt europeiskt land för amerikansk vattenpolo är Serbien, som också är en av de ledande vattenpolonationerna globalt sett. Serbien har vunnit 7 olympiska guldmedaljer, varav den senaste kom 2016. Serbien är känt för sin fysiska och aggressiva spelstil, som bygger på starkt försvar, snabba kontringar och kraftfullt skott. Många serbiska spelare och tränare har delat med sig av sin erfarenhet och expertis till amerikansk vattenpolo, inklusive Dejan Udovicic, Andrija Prlainovic och Filip Filipovic.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikaner i Internationella Tävlingar
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Förutom att lära sig av internationell vattenpolo har amerikansk vattenpolo också bidragit betydligt till internationell vattenpolo, särskilt när det gäller prestanda, innovation och marknadsföring. Många amerikanska spelare och tränare har deltagit i internationella tävlingar, både på klubb- och nationsnivå, där de har visat upp sina talanger och förbättrat sitt rykte.
      <br />
      <br />
      En av de viktigaste internationella vattenpolotävlingarna är Olympiska spelen, som hålls vart fjärde år. Det amerikanska laget har alltid deltagit i denna tävling, förutom 1976 och 1980. Det amerikanska laget har totalt sett vunnit 12 olympiska medaljer: 3 guld, 4 silver och 5 brons. Den senaste medaljen var en silvermedalj för herrarna 2008. Det amerikanska laget är för närvarande rankat fyra i världen.
      <br />
      <br />
      En annan betydande internationell tävling för vattenpolo är VM, som hålls vartannat år. Det amerikanska laget har också konsekvent deltagit i denna tävling sedan den första upplagan 1973. Det amerikanska laget har totalt sett vunnit 9 VM-medaljer: 2 guld, 3 silver och 4 brons. Den senaste medaljen var en bronsmedalj för damerna 2019. Det amerikanska laget är för närvarande rankat trea i världen.
      <br />
      <br />
      En tredje stor internationell tävling för vattenpolo är Världsligan, som hålls årligen. Det amerikanska laget har också konsekvent deltagit i denna tävling sedan den första upplagan 2002. Det amerikanska laget har totalt sett vunnit 10 Världsliga-medaljer: 3 guld, 4 silver och 3 brons. Den senaste medaljen var en guldmedalj för damerna 2021. Det amerikanska laget är för närvarande rankat tvåa i världen.
    </MKTypography>
  </MKBox>
</MKBox>






                                  






<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Idrottsstipendier och Framtiden för Idrottare
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrottsstipendier erbjuder många möjligheter för unga idrottare att visa upp sin talang och utveckla sina färdigheter. En av de mest betydelsefulla möjligheterna är att få ett idrottsstipendium för att studera och tävla på collegenivå. I det här kapitlet kommer vi att diskutera vikten av idrottsstipendier och hur Sport Scholarships America kan hjälpa dig att förverkliga din dröm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vikten av Stipendier
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ett idrottsstipendium är ekonomiskt stöd som tillhandahålls av ett universitet till en idrottare för att kombinera studier och idrott. Det kan täcka kostnaderna för undervisning, böcker, boende, måltider och andra utgifter. Dessutom kan det erbjuda fördelar som akademisk handledning, sjukvård, träningsanläggningar och utrustning.
      <br />
      <br />
      Att få ett idrottsstipendium är konkurrenskraftigt, med många faktorer som spelar en roll i tilldelningsprocessen, inklusive akademiska prestationer, idrottsprestationer, personlighet, motivation, ledarskap och anpassningsförmåga. Därför är det viktigt att förbereda sig väl och skilja sig från konkurrensen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hur Sport Scholarships America Kan Hjälpa
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotyp för Sport Scholarships America, med sportutrustning och en studentmössa, som representerar fokus på idrottsstipendier för idrottare."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotyp för Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America är en organisation specialiserad på att vägleda unga idrottstalanger till idrottsstipendier. Med passionerade och erfarna experter stöder Sport Scholarships America idrottare i varje steg av resan, från initial orientering till slutlig placering. Deras tjänster inkluderar:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intagningsintervju för att diskutera idrottarens önskemål, möjligheter och förväntningar.</li>
        <li>En professionell utvärdering av idrottarens akademiska och idrottsliga nivå.</li>
        <li>Ett omfattande nätverk av kontakter med tränare, universitet och högskolor.</li>
        <li>Effektiv marknadsföring av idrottarens profil och videor till potentiella tränare.</li>
        <li>Expertrådgivning om de bästa alternativen baserat på idrottarens preferenser, mål och budget.</li>
        <li>Förhandling om de bästa stipendieerbjudandena för idrottaren.</li>
        <li>Hjälp med att ansöka om nödvändiga dokument, såsom visum, betyg, examensbevis och antagningsprov.</li>
        <li>Förberedelse för idrottarens liv under stipendieperioden, inklusive kultur, språk, regler och tips.</li>
        <li>Uppföljning och uppföljning under stipendieperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vill du utforska möjligheter till stipendier och uppnå dina idrottsliga och akademiska mål? <Link to="/sv/Tjänster-Produkter">Se det fullständiga utbudet</Link> av tjänster som erbjuds av Sport Scholarships America och boka en konsultation idag.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;