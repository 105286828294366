/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";



// Images
import Image1 from "assets/images/Sports_Images/Soccer1.jpg";
import Image2 from "assets/images/Sports_Images/Soccer2.jpg";
import Image3 from "assets/images/Sports_Images/Soccer3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Giocare a Calcio e Studiare in America: Un Sogno Che Si Realizza', isSubheader: false },
  
    { id: 'Capitolo 1', title: 'Capitolo 1: La Storia e lo Sviluppo del Calcio in America', isSubheader: false },
    { id: '1.1', title: '1.1 Anni Iniziali e il Ruolo dei College', isSubheader: true },
    { id: '1.2', title: '1.2 Crescita e Professionalizzazione', isSubheader: true },
  
    { id: 'Capitolo 2', title: 'Capitolo 2: Il Calcio Universitario Oggi', isSubheader: false },
    { id: '2.1', title: '2.1 Competizioni e Conferenze Importanti per il Calcio', isSubheader: true },
    { id: '2.2', title: '2.2 Programmi Top e Squadre Prestigiose per il Calcio', isSubheader: true },
  
    { id: 'Capitolo 3', title: 'Capitolo 3: Dal College al Professionismo', isSubheader: false },
    { id: '3.1', title: '3.1 Il Percorso verso il Professionismo', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capitolo 4: L\'Influenza del Calcio Internazionale', isSubheader: false },
    { id: '4.1', title: '4.1 Influenze Straniere', isSubheader: true },
    { id: '4.2', title: '4.2 Americani All\'estero', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capitolo 5: Borse di Studio Sportive e il Futuro degli Atleti', isSubheader: false },
    { id: '5.1', title: '5.1 L\'Importanza delle Borse di Studio', isSubheader: true },
    { id: '5.2', title: '5.2 Come Sport Scholarships America Può Aiutare', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indice
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Nascondi</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostra</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























{/* Introduzione */}
<MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Giocare a Calcio e Studiare in America: Un Sogno Che Si Realizza
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Lo stadio di Creighton, con una capacità per oltre 7000 spettatori, durante una partita di calcio universitaria."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Stadio di Creighton: casa per più di 7000 tifosi di calcio.
      </figcaption>
    </figure>
    Il calcio è uno degli sport più popolari al mondo, ma in America è stato a lungo eclissato da altri sport come il basket, il baseball e il football americano. Tuttavia, negli ultimi decenni, il calcio è cresciuto enormemente in popolarità e qualità negli Stati Uniti, grazie in parte al successo della nazionale, alla crescita della Major League Soccer (MLS) e all'influenza delle stelle internazionali.
    <br />
    <br />
    Ma sapevi che il calcio in America offre anche un'opportunità unica per i giovani talenti di perseguire i loro sogni? Giocando a calcio e studiando in America, puoi non solo sviluppare le tue abilità atletiche, ma anche conseguire un diploma accademico e vivere un'esperienza indimenticabile. Tutto questo è possibile grazie alle borse di studio per il calcio che le università americane offrono ai giocatori talentuosi.
    <br />
    <br />
    In questo articolo, ti diremo tutto ciò che devi sapere su giocare a calcio e studiare in America, dalla storia e lo sviluppo del calcio negli Stati Uniti ai benefici e alle opportunità di una borsa di studio per il calcio. Spiegheremo anche come Sportbeursamerika.nl possa aiutarti a trasformare questo sogno in realtà. Quindi, continua a leggere e scopri come puoi approfittare di questa opportunità unica!
  </MKTypography>
</MKBox>

{/* Capitolo 1 */}
<MKBox id="Capitolo 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 1: La Storia e lo Sviluppo del Calcio in America
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Il calcio, o soccer come lo chiamano gli americani, non è uno sport così nuovo negli Stati Uniti come potresti pensare. Infatti, la prima partita ufficiale di calcio in America ebbe luogo nel 1869, tra due squadre universitarie: Princeton e Rutgers. Da allora, il calcio ha avuto una lunga e affascinante storia negli USA, di cui parleremo in questo capitolo.
  </MKTypography>
  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Anni Iniziali e il Ruolo dei College
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Alla fine del XIX e all'inizio del XX secolo, il calcio era particolarmente popolare tra gli studenti, che praticavano il gioco secondo vari regolamenti e formati. Ad esempio, c'erano squadre che giocavano con 11, 15 o addirittura 25 giocatori, e a volte usavano elementi di rugby o football americano.
      <br />
      <br />
      Per creare maggiore unità e struttura, nel 1905 fu fondata la Intercollegiate Association of Amateur Athletes of America (IAAAA), precursore della National Collegiate Athletic Association (NCAA). La NCAA è l'organizzazione principale che regola e organizza il calcio universitario negli USA.
      <br />
      <br />
      Nella prima metà del XX secolo, il numero di squadre di calcio universitarie crebbe costantemente, e emersero anche competizioni e campionati regionali. Uno dei più famosi di questi è la College Cup, tenuto dal 1959 come campionato nazionale maschile. Per le donne, la prima College Cup si tenne solo nel 1982, ma da allora il calcio femminile ha visto una crescita enorme negli USA, in parte grazie al successo della nazionale nei tornei internazionali.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crescita e Professionalizzazione
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Oltre al calcio universitario, anche il calcio professionistico si è sviluppato negli USA, sebbene con più alti e bassi. La prima lega professionistica fu la American Soccer League (ASL), fondata nel 1921 e composta principalmente da squadre del Nord-Est. L'ASL ebbe alcuni anni di successo ma entrò in conflitto con la federazione calcistica americana sulla organizzazione e regolamentazione del gioco. Ciò portò a una spaccatura e alla fine della ASL nel 1933.
      <br />
      <br />
      Dopo la Seconda Guerra Mondiale, varie leghe cercarono di riportare in vita il calcio professionistico, ma nessuna di esse sopravvisse a lungo. Fu solo nel 1967 che fu fondata una lega stabile: la North American Soccer League (NASL). La NASL portò in America alcuni grandi nomi, tra cui Pelé, Franz Beckenbauer e Johan Cruyff, e attirò grandi folle negli stadi. Tuttavia, la NASL non riuscì a sopportare problemi finanziari, concorrenza da altri sport e mancanza di interesse dei media. La NASL cessò di esistere nel 1984.
      <br />
      <br />
      Ci volle fino al 1996 perché fosse fondata nuovamente una lega professionistica: la Major League Soccer (MLS). La MLS è attualmente la massima divisione del calcio americano, composta da 27 squadre sia dagli USA che dal Canada. La MLS si è evoluta in una lega competitiva e attraente, attirando sia giocatori nazionali che internazionali. La MLS ha anche contribuito alla popolarizzazione del calcio in America costruendo stadi, sostenendo lo sviluppo giovanile e collaborando con altre leghe.
      <br />
      <br />
      Il calcio professionistico ha influenzato anche il calcio universitario, e viceversa. Ad esempio, ci sono giocatori che passano alla MLS dopo la loro carriera universitaria, o giocatori che tornano all'università per completare i loro studi dopo la MLS. Ci sono anche giocatori che giocano sia per il loro college che per una squadra professionistica, grazie a disposizioni speciali. Inoltre, alcune squadre professionistiche collaborano con programmi universitari per individuare e sviluppare talenti.
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  



{/* Capitolo 2 */}
<MKBox id="Capitolo 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 2: Il Calcio Universitario Oggi
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Come abbiamo visto nel capitolo precedente, il calcio universitario in America ha una lunga e ricca tradizione. Ma com'è oggi? Quali competizioni e conferenze esistono? Quali programmi e squadre sono i più prestigiosi e di successo? E come puoi, come giocatore, entrare in questo mondo emozionante? In questo capitolo, ti forniremo una panoramica del calcio universitario oggi e ti mostreremo cosa aspettarti se scegli una borsa di studio per il calcio in America.
  </MKTypography>
  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Competizioni e Conferenze Importanti per il Calcio
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Giocatore di calcio di Santa Clara in azione durante il torneo NCAA."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Giocatori di calcio in azione durante una partita del torneo NCAA.
        </figcaption>
      </figure>
      Come già accennato, la NCAA è l'organizzazione principale che regola e organizza il calcio universitario negli USA. La NCAA è composta da tre divisioni: Divisione I, Divisione II e Divisione III. Ogni divisione ha le proprie regole, requisiti e borse di studio per gli studenti-atleti.
      <br />
      <br />
      La Divisione I è la divisione più alta e competitiva, dove giocatori e squadre migliori competono. La Divisione I è composta da circa 200 squadre maschili e 300 squadre femminili, divise in varie conferenze regionali. Una conferenza è un gruppo di università che collaborano per organizzare una competizione nella loro regione. Alcuni esempi di conferenze ben note sono l'Atlantic Coast Conference (ACC), la Big Ten Conference, la Pac-12 Conference e il Southeastern Conference (SEC).
      <br />
      <br />
      Ogni anno, le squadre della loro conferenza competono per il titolo e un posto nel campionato nazionale: la College Cup. La College Cup è un torneo a eliminazione diretta tenuto alla fine della stagione, di solito a novembre e dicembre. Per qualificarsi per la College Cup, le squadre devono prima guadagnare il loro posto attraverso la loro conferenza o attraverso un invito at-large assegnato da un comitato di selezione in base alle loro prestazioni durante la stagione. La College Cup è composta da 48 squadre maschili e 64 squadre femminili, che competono in varie fasi fino a quando non resta un campione.
      <br />
      <br />
      La Divisione II e la Divisione III sono meno competitive rispetto alla Divisione I ma offrono comunque molte opportunità agli studenti-atleti per svilupparsi e godersi il gioco. La Divisione II è composta da circa 160 squadre maschili e 200 squadre femminili, anch'esse divise in diverse conferenze. Anche la Divisione II ha un campionato nazionale, composto da 36 squadre maschili e 48 squadre femminili. La Divisione III è composta da circa 400 squadre maschili e 430 squadre femminili, che competono anche in conferenze. Tuttavia, la Divisione III non offre borse di studio agli studenti-atleti ma fornisce altre forme di supporto finanziario. Anche la Divisione III ha un campionato nazionale, composto da 62 squadre maschili e 64 squadre femminili.
      <br />
      <br />
      Oltre alla NCAA, ci sono altre organizzazioni che organizzano il calcio universitario negli USA, come la National Association of Intercollegiate Athletics (NAIA), la National Junior College Athletic Association (NJCAA) e la United States Collegiate Athletic Association (USCAA). Queste organizzazioni hanno le proprie divisioni, regole, borse di studio e campionati, che di solito sono più piccoli e meno conosciuti rispetto a quelli della NCAA.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programmi Top e Squadre Prestigiose per il Calcio
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      All'interno di ogni divisione e conferenza, ci sono certi programmi e squadre che si distinguono per la loro storia, prestazioni, reputazione, strutture e altro ancora. Questi programmi e squadre attraggono spesso i migliori giocatori, offrono le migliori borse di studio, hanno i migliori allenatori e personale, possiedono strutture di allenamento e gioco di prima classe, hanno il più grande numero di tifosi e attenzione mediatica e producono frequentemente giocatori per il circuito professionistico.
      <br />
      <br />
      È difficile compilare una lista definitiva dei migliori programmi e squadre nel calcio universitario in America perché molti fattori entrano in gioco e le classifiche possono cambiare ogni anno. Tuttavia, per darti un'idea, menzioneremo alcuni esempi di programmi e squadre che sono spesso considerati i migliori nel calcio universitario in America.
      <br />
      <br />
      Per gli uomini, uno dei programmi più prestigiosi è quello dell'Università del Maryland, che ha vinto il campionato nazionale 4 volte (1968, 2005, 2008 e 2018) e ha prodotto più di 20 giocatori che hanno giocato nella MLS, come Omar Gonzalez, Graham Zusi e Zack Steffen. Altri programmi leader per gli uomini includono quelli dell'Università della Virginia, dell'Università dell'Indiana, dell'Università di Stanford e dell'Università di Wake Forest.
      <br />
      <br />
      Per le donne, uno dei programmi più dominanti è quello dell'Università della Carolina del Nord, che ha vinto il campionato nazionale più di 20 volte e ha prodotto più di 40 giocatori che hanno giocato per la nazionale, come Mia Hamm, Kristine Lilly e Tobin Heath. Altri programmi leader per le donne includono quelli dell'Università di Notre Dame, dell'Università di Portland, dell'Università di Stanford e dell'Università della Florida.
      <br />
      <br />
      Questi sono solo alcuni esempi dei molti eccellenti programmi e squadre nel calcio universitario in America. Se vuoi saperne di più su diversi programmi e squadre, puoi cercare online le classifiche, le statistiche, le recensioni e i testimoni di giocatori e allenatori. Puoi anche contattare Sportbeursamerika.nl, che può aiutarti a trovare il miglior programma e la migliore squadra che fa per te.
    </MKTypography>
  </MKBox>
</MKBox>

        






                                  



{/* Capitolo 3 */}
<MKBox id="Capitolo 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 3: Dal College al Professionismo
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Molti giovani calciatori sognano un giorno di giocare a livello professionistico ed emulare i loro idoli al massimo livello. Ma come puoi realizzare quel sogno? Quali sono i passi che devi compiere per passare dal college al professionismo? E quali sono le opportunità e le sfide che incontrerai lungo il cammino? In questo capitolo, risponderemo a queste domande e ti mostreremo come il calcio universitario possa servire da trampolino di lancio per una carriera professionale.
  </MKTypography>
  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Il Percorso verso il Professionismo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Uno dei maggiori vantaggi del calcio universitario è che ti offre l'opportunità di svilupparsi sia dal punto di vista atletico che accademico, mentre godi della vita da studente. Giocando a calcio e studiando in America, puoi non solo migliorare le tue abilità calcistiche ma anche ampliare le tue conoscenze, allargare il tuo network, plasmare la tua personalità e migliorare le tue prospettive future.
      <br />
      <br />
      Il calcio universitario può anche essere un trampolino di lancio per una carriera professionale se lo desideri. Ci sono vari modi per passare dal calcio universitario al circuito professionistico, sia in America che all'estero. I metodi più comuni includono:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          MLS SuperDraft: Si tratta di un evento annuale in cui le squadre della MLS si alternano nella selezione di giocatori da un pool di giocatori disponibili, la maggior parte dei quali proviene dal calcio universitario. Il MLS SuperDraft consiste in quattro turni, con ogni squadra che effettua una scelta per turno. L'ordine delle selezioni è determinato da vari fattori come le prestazioni della squadra nella stagione precedente, la lotteria e eventuali scambi potenziali. Il MLS SuperDraft è uno dei modi principali per i giocatori di calcio universitario per entrare nella MLS e offre l'opportunità di ottenere un contratto e una borsa di studio.
        </li>
        <li>
          Regola dei Giocatori di Casa MLS: Questa regola consente alle squadre della MLS di firmare giocatori provenienti dalle proprie accademie giovanili senza utilizzare una scelta nel draft. L'obiettivo della regola è quello di incentivare e premiare lo sviluppo di talenti locali. Molte squadre della MLS hanno partnership con programmi universitari, permettendo loro di monitorare e mentorare giocatori che giocano sia per il loro college che per le squadre giovanili. La Regola dei Giocatori di Casa MLS è un altro modo per i giocatori di calcio universitario di entrare nella MLS e offre l'opportunità di ottenere un contratto e una borsa di studio.
        </li>
        <li>
          Programma Generation Adidas: Sponsorizzato da Adidas, questo programma è progettato per assistere giovani giocatori nella transizione dal calcio universitario al calcio professionistico. Il programma seleziona giocatori promettenti, tipicamente sotto i 20 anni, che non hanno ancora completato gli studi universitari. A questi giocatori vengono offerti contratti dalla MLS, consentendo loro di partecipare al MLS SuperDraft senza perdere l'ammissibilità al college. Ciò significa che possono comunque tornare al college per completare gli studi se lo desiderano. Il programma fornisce anche borse di studio educative ai giocatori per coprire i costi degli studi.
        </li>
        <li>
          Opportunità all'Estero: Queste sono opportunità per i giocatori di calcio universitario di giocare all'estero, ad esempio in Europa, Asia o Sud America. Queste opportunità dipendono tipicamente da vari fattori come il livello del giocatore, la nazionalità, l'età e il network. Alcuni giocatori possono, ad esempio, sfruttare la doppia nazionalità o l'origine familiare per qualificarsi per un passaporto straniero, rendendo più facile per loro giocare in un altro paese. Altri giocatori possono beneficiare delle loro connessioni con osservatori, agenti o allenatori che possono aiutarli a ottenere un contratto con un club straniero. Le opportunità all'estero sono un altro modo per i giocatori di calcio universitario di perseguire una carriera professionale, ma comportano anche più rischi e incertezze.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Questi sono solo alcuni dei modi per passare dal college al professionismo, ma ce ne sono altri. La chiave è essere consapevoli delle opportunità disponibili e prepararsi alle sfide che potresti incontrare. È anche importante essere realistici sulle tue possibilità e aspettative e non mettere tutte le uova nello stesso cesto. Il calcio universitario è un'opportunità fantastica per svilupparsi come giocatore e come persona. Pertanto, è saggio mantenere aperte anche altre opzioni e non concentrarsi solo sul calcio, ma anche sugli studi, gli hobby, gli amici e la famiglia.
      <br />
      <br />
      Nel prossimo capitolo, esploreremo l'influenza del calcio internazionale sul calcio universitario e come influisce e ispira i giocatori di calcio universitario.
    </MKTypography>
  </MKBox>
  </MKBox>

    






                                  






  <MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 4: L'Influenza del Calcio Internazionale
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Il calcio è uno sport globale che collega milioni di persone in tutto il mondo. Non sorprende quindi che il calcio internazionale abbia un'influenza significativa sul calcio universitario in America. In questo capitolo, esploreremo come le influenze straniere impattino e ispirino il calcio universitario e come i giocatori americani trovino la loro strada in terre straniere.
  </MKTypography>
  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Influenze Straniere
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Il prestigioso trofeo del calcio NCAA."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Il tanto ambito trofeo della NCAA
        </figcaption>
      </figure>
      La popolarità del calcio internazionale negli Stati Uniti è cresciuta notevolmente negli ultimi anni. Secondo un sondaggio Gallup del 2023, il calcio è il terzo sport più popolare tra gli americani, dopo il football americano e il basket. Questo è in parte dovuto all'aumento della diversità della popolazione americana, all'accessibilità delle partite internazionali attraverso la TV e internet e al successo della nazionale statunitense sulla scena mondiale.
      <br />
      <br />
      Questo interesse per il calcio internazionale ha anche avuto un impatto sul calcio universitario. Molti giocatori di calcio universitario ammirano i loro giocatori e squadre stranieri preferiti, cercando di emulare il loro stile di gioco e tecnica. Alcuni giocatori di calcio universitario hanno persino origini straniere o doppia nazionalità, creando un legame speciale con un paese o una regione particolare.
      <br />
      <br />
      Un esempio è Gio Reyna, figlio dell'ex internazionale statunitense Claudio Reyna. Gio Reyna è nato in Inghilterra, dove suo padre ha giocato per Sunderland e Manchester City. È cresciuto con un amore per il calcio inglese e ha giocato per le squadre giovanili del New York City FC. Nel 2023, si è trasferito al Borussia Dortmund in Germania, dove è rapidamente diventato uno dei talenti più promettenti d'Europa. Ha anche debuttato nella nazionale statunitense, formando una nuova generazione insieme ad altri giovani talenti come Christian Pulisic e Weston McKennie.
      <br />
      <br />
      Un altro esempio è Catarina Macario, un'attaccante brasiliana che si è trasferita negli Stati Uniti all'età di dodici anni. Ha giocato per la Stanford University, dove ha vinto il premio Hermann due volte come miglior giocatrice di calcio universitario del paese. Ha anche ottenuto la cittadinanza statunitense, permettendole di rappresentare la nazionale femminile degli Stati Uniti. Nel 2023, ha firmato un contratto con l'Olympique Lione in Francia, una delle squadre femminili più importanti al mondo.
      <br />
      <br />
      Questi esempi illustrano come le influenze straniere arricchiscano e diversifichino il calcio universitario e come i giocatori di calcio universitario possano realizzare i propri sogni nel calcio internazionale.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Americani All'estero
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Non solo le influenze straniere impattano sul calcio universitario, ma i giocatori americani che cercano la fortuna all'estero giocano anche un ruolo significativo. Un numero sempre maggiore di giocatori americani sceglie di trasferirsi in Europa o in altri continenti dopo la loro carriera universitaria per competere contro i migliori giocatori del mondo.
      <br />
      <br />
      Uno degli esempi più riusciti è Clint Dempsey, che ha giocato per la Furman University prima di unirsi alla MLS. Nel 2023, ha fatto il salto al Fulham in Inghilterra, dove è emerso come un prolifico marcatore. Ha segnato più di 50 gol per il club, incluso un gol storico contro la Juventus in Europa League. Ha anche giocato per il Tottenham Hotspur e i Seattle Sounders ed è stato un giocatore chiave per la nazionale statunitense.
      <br />
      <br />
      Un altro caso di successo è Tobin Heath, che ha giocato per la North Carolina University, dove ha vinto due campionati nazionali. Ha anche giocato per vari club negli Stati Uniti, in Francia e in Inghilterra, inclusi Paris Saint-Germain e Manchester United. Ha vinto quattro medaglie d'oro olimpiche e due Coppe del Mondo FIFA con la nazionale femminile degli Stati Uniti, dove è nota per le sue abilità tecniche e creatività.
      <br />
      <br />
      Questi esempi dimostrano come i giocatori americani possano svilupparsi ed eccellere all'estero e come possano mettere il calcio universitario sulla mappa come un incubatore di talenti.
    </MKTypography>
  </MKBox>
</MKBox>

             






                                  






<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 5: Borse di Studio Sportive e il Futuro degli Atleti
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le borse di studio sportive offrono numerose opportunità ai giovani atleti di mostrare il loro talento e sviluppare le loro abilità. Una delle opportunità più significative è ottenere una borsa di studio sportiva per studiare e competere a livello universitario. In questo capitolo, discuteremo dell'importanza delle borse di studio sportive e di come Sport Scholarships America può aiutarti a realizzare il tuo sogno.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 L'Importanza delle Borse di Studio
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Una borsa di studio sportiva è un sostegno finanziario fornito da un'università a uno studente-atleta per combinare studio e sport. Può coprire i costi di iscrizione, libri, alloggio, pasti e altre spese. Inoltre, può offrire vantaggi come il tutorato accademico, l'assistenza medica, le strutture di allenamento e l'attrezzatura.
      <br />
      <br />
      Ottenere una borsa di studio sportiva è competitivo, con molti fattori che influenzano il processo di assegnazione, tra cui risultati accademici, prestazioni sportive, personalità, motivazione, leadership e adattabilità. È quindi importante prepararsi bene e distinguersi dalla concorrenza.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Come Sport Scholarships America Può Aiutare
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo di Sport Scholarships America, raffigurante attrezzature sportive e una toga, simbolo del focus sulle borse di studio sportive per gli studenti-atleti."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo di Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America è un'organizzazione specializzata nell'orientamento dei giovani talenti sportivi verso borse di studio sportive. Con esperti appassionati ed esperti, Sport Scholarships America supporta gli studenti-atleti in ogni fase del percorso, dall'orientamento iniziale alla collocazione finale. I loro servizi includono:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Un'intervista personale per discutere i desideri, le possibilità e le aspettative dello studente-atleta.</li>
        <li>Una valutazione professionale del livello accademico e sportivo dello studente-atleta.</li>
        <li>Una vasta rete di contatti con allenatori, università e college.</li>
        <li>Promozione efficace del profilo e dei video dello studente-atleta presso allenatori potenziali.</li>
        <li>Consulenza esperta sulle migliori opzioni in base alle preferenze, agli obiettivi e al budget dello studente-atleta.</li>
        <li>Negoziazione delle migliori offerte di borse di studio per conto dello studente-atleta.</li>
        <li>Assistenza nella richiesta dei documenti necessari, come visto, trascrizione, diploma e test di ammissione.</li>
        <li>Preparazione alla vita dello studente-atleta durante la borsa di studio, compresa cultura, lingua, regole e consigli.</li>
        <li>Assistenza e monitoraggio durante il periodo di borsa di studio.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vuoi esplorare le opportunità di borse di studio e raggiungere i tuoi obiettivi sportivi e accademici? <Link to="/it/Servizi-Prodotti">Consulta la gamma completa</Link> dei servizi offerti da Sport Scholarships America e prenota una consultazione oggi stesso.
    </MKTypography>
  </MKBox>
</MKBox>
          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;