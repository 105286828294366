/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Rowing1.jpg";
import Image2 from "assets/images/Sports_Images/Rowing2.jpg";
import Image3 from "assets/images/Sports_Images/Rowing3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images

import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Estudar e Remar na América: Uma Oportunidade Única para Jovens Remadores', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capítulo 1: A História e Desenvolvimento do Remo na América', isSubheader: false },
    { id: '1.1', title: '1.1 Os Primeiros Anos e o Papel das Universidades', isSubheader: true },
    { id: '1.2', title: '1.2 Crescimento e Tradições', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capítulo 2: Remo no Nível Universitário Hoje', isSubheader: false },
    { id: '2.1', title: '2.1 Principais Competições e Conferências', isSubheader: true },
    { id: '2.2', title: '2.2 Programas e Equipes Principais', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capítulo 3: Da Universidade à Elite', isSubheader: false },
    { id: '3.1', title: '3.1 O Caminho para o Topo', isSubheader: true },
    { id: '3.2', title: '3.2 Oportunidades Profissionais e Internacionais', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capítulo 4: Influências Internacionais e Diversidade', isSubheader: false },
    { id: '4.1', title: '4.1 Atletas Internacionais no Remo Universitário', isSubheader: true },
    { id: '4.2', title: '4.2 Remadores Americanos no Exterior', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 A Importância das Bolsas de Estudo', isSubheader: true },
    { id: '5.2', title: '5.2 Como a Sport Scholarships America Pode Ajudar', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Conteúdo
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Esconder</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Estudar e Remar na América: Uma Oportunidade Única para Jovens Remadores
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Remadores em ação durante o campeonato nacional universitário de remo."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Remadores em ação durante o campeonato nacional
      </figcaption>
    </figure>
    O remo é um dos esportes mais antigos e prestigiados nos Estados Unidos. Desde o século XIX, o remo de alto nível é praticado em universidades americanas, onde os remadores não apenas desenvolvem sua força física e mental, mas também suas habilidades acadêmicas e sociais. Estudar e remar na América é uma oportunidade única para jovens remadores que desejam perseguir seus sonhos atléticos enquanto recebem uma educação valiosa e têm uma experiência inesquecível. Neste artigo, vamos contar tudo o que você precisa saber sobre o remo na América, desde sua história e tradições até as oportunidades e desafios. Também explicaremos como o Sportbeursamerika.nl pode ajudá-lo a garantir uma bolsa de estudos de remo e se preparar para sua aventura.
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 1: A História e Desenvolvimento do Remo na América
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Neste capítulo, mergulhamos na fascinante história do remo nos Estados Unidos. Exploramos como este esporte se espalhou pelo oceano desde a Inglaterra e se tornou uma parte integral da cultura universitária americana. Desde as primeiras corridas de remo no Lago Winnipesaukee até as rivalidades icônicas entre universidades prestigiadas, examinamos o desenvolvimento do remo como um esporte que incorpora tanto agilidade física quanto orgulho acadêmico.
  </MKTypography>
  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Os Primeiros Anos e o Papel das Universidades
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O remo como esporte teve origem na Inglaterra no século XVIII, quando corridas eram realizadas entre remadores profissionais no Tâmisa. Essas corridas atraíam grandes públicos e apostas, tornando rapidamente o remo popular. Nos séculos XVIII e XIX, o remo se espalhou para outros países, incluindo os Estados Unidos. A primeira corrida de remo americana ocorreu em 1843 no Lago Winnipesaukee, em New Hampshire. Em 1852, a primeira corrida de remo interuniversitária foi realizada entre Harvard e Yale no Lago Winnipesaukee. Este também foi o primeiro evento esportivo interuniversitário nos EUA. Desde então, o remo se tornou uma parte significativa dos esportes universitários americanos.
      <br />
      <br />
      O papel das universidades na promoção do remo foi crucial. As universidades viam o remo como uma forma de instilar disciplina, trabalho em equipe e liderança em seus alunos, ao mesmo tempo em que aumentavam seu prestígio e reputação. As universidades investiram uma quantidade significativa de dinheiro e recursos na construção de barracões, na aquisição de barcos e na contratação de treinadores. Elas também organizavam regularmente competições contra outras universidades, tanto regionalmente quanto nacionalmente. Essas competições eram frequentemente bem frequentadas por alunos, ex-alunos, mídia e patrocinadores, criando um grande interesse e envolvimento no esporte.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crescimento e Tradições
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O remo cresceu constantemente em popularidade e prestígio na segunda metade do século XIX e na primeira metade do século XX. Vários campeonatos regionais e nacionais surgiram, como os Eastern Sprints, o IRA National Championship Regatta, o Dad Vail Regatta e o Head of the Charles Regatta. Remadores americanos também participaram de eventos internacionais, incluindo o Henley Royal Regatta na Inglaterra e os Jogos Olímpicos. Os remadores americanos frequentemente alcançavam resultados impressionantes, ganhando ouro em oito Jogos Olímpicos consecutivos entre 1920 e 1956.
      <br />
      <br />
      O remo também desenvolveu uma cultura e tradição ricas nas universidades americanas. Cada universidade tinha suas próprias cores, mascote, músicas e rituais associados ao remo. Algumas tradições eram brincalhonas ou simbólicas, como jogar o coxswain na água após uma vitória ou usar uma gravata ou broche especial. Outras tradições eram sérias ou cerimoniais, como cantar o hino da alma mater ou realizar um memorial para remadores falecidos. Essas tradições contribuíram para o senso de orgulho, camaradagem e identidade entre os remadores.
    </MKTypography>
  </MKBox>
</MKBox>

          





                                  







<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 2: Remo no Nível Universitário Hoje
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Neste capítulo, focamos no remo contemporâneo no nível universitário na América. Examinamos como o esporte evoluiu para se tornar uma parte altamente competitiva e prestigiada do cenário esportivo universitário. Aqui, discutimos as principais competições e conferências, a estrutura das divisões da NCAA e damos uma olhada em alguns dos principais programas de remo universitário. Esta seção destaca o estado atual do remo nas universidades americanas e as oportunidades únicas que oferece aos jovens atletas.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Principais Competições e Conferências
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Equipe de remo de Stanford celebra a conquista do campeonato nacional."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Remadores de Stanford celebram o campeonato nacional
        </figcaption>
      </figure>
      O remo no nível universitário permanece um esporte altamente competitivo e prestigiado nos Estados Unidos hoje. Existem mais de 300 universidades que oferecem programas de remo, com aproximadamente 150 participando da NCAA (Associação Atlética Universitária Nacional), a organização abrangente para esportes universitários. A NCAA divide as universidades em três divisões com base no número de estudantes, orçamento e nível do esporte. A Divisão I é a mais alta divisão, apresentando os programas de remo mais robustos e competitivos. As Divisões II e III têm menos e menores programas de remo, mas ainda são altamente competitivas.
      <br />
      <br />
      Dentro de cada divisão, existem várias conferências, que são grupos de universidades geográfica ou historicamente conectadas. Cada conferência organiza seu próprio campeonato, onde as principais equipes se qualificam para os campeonatos nacionais. As principais conferências para o remo incluem o Big Ten, Pac-12, Ivy League, ACC e Big 12. Essas conferências muitas vezes têm os programas de remo mais bem-sucedidos e influentes do país.
      <br />
      <br />
      Além dos campeonatos de conferências, existem outras regatas nacionais significativas nas quais as universidades podem participar. A mais prestigiada é o Campeonato de Remo Feminino da NCAA, realizado desde 1997 para remadoras femininas em todas as três divisões. Para remadores masculinos, não há um campeonato equivalente da NCAA, mas há o Campeonato Nacional de Remo IRA, realizado desde 1895 para equipes varsity e lightweight. Outras regatas nacionais conhecidas incluem a Dad Vail Regatta, a San Diego Crew Classic, o Campeonato Nacional de Remo ACRA e o CRASH-B Sprints.
    </MKTypography>
  </MKBox>

  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programas e Equipes Principais
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O remo no nível universitário tem uma longa história de rivalidade, excelência e inovação. Muitas universidades se destacaram por meio de seus programas de remo, tanto em termos de desempenho quanto de desenvolvimento. Algumas dessas universidades incluem:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Universidade de Washington: Os Huskies são conhecidos por seu programa de remo poderoso e respeitado. Com múltiplos campeonatos nacionais no remo masculino e uma história de produção de medalhistas olímpicos, incluindo os lendários "Meninos do Barco" que ganharam o ouro em 1936. Os Huskies são celebrados por seu treinamento rigoroso, forte trabalho em equipe e treinadores influentes como Al Ulbrickson.
        </li>
        <li>
          Universidade Yale: Como uma das pioneiras do remo interuniversitário, os Bulldogs de Yale têm um programa de remo antigo e prestigiado. Sua história inclui múltiplos títulos nacionais e uma contribuição significativa para o remo americano no nível olímpico. Yale também é conhecida por sua excelência acadêmica e rivalidade histórica com Harvard.
        </li>
        <li>
          Universidade da Califórnia, Berkeley: Os Golden Bears têm um programa de remo bem-sucedido e versátil, distinguido por seus títulos nacionais e participantes olímpicos. O programa é conhecido por sua diversidade, envolvimento comunitário e treinadores dedicados.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>






                                  









<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 3: Da Universidade à Elite
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Neste capítulo, exploramos a jornada dos remadores universitários para os escalões de elite do mundo do remo. Examinamos como as experiências e habilidades adquiridas pelos remadores no nível universitário os preparam para níveis mais altos de competição, incluindo a participação em equipes nacionais e nos Jogos Olímpicos. Este capítulo destaca a transição do remo universitário para os palcos profissionais e internacionais, focando nos caminhos que os atletas seguem, nos desafios que enfrentam e nos casos de sucesso daqueles que alcançam o topo. Também analisamos como os programas de remo universitário identificam e desenvolvem talentos, abrindo caminho para futuros olímpicos e campeões mundiais.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 O Caminho para o Topo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O remo no nível universitário não é apenas um desafio esportivo, mas também uma possível trampolim para os escalões de elite do mundo do remo. Muitos remadores que competiram no nível universitário transitam para as equipes nacionais e o remo olímpico. Isso ocorre porque o remo universitário oferece uma excelente preparação em termos de técnica, tática, condicionamento físico e mental, e trabalho em equipe. Além disso, o remo universitário oferece a oportunidade de se destacar para olheiros e treinadores das associações e seleções nacionais.
      <br />
      <br />
      Há inúmeros exemplos de remadores que foram da universidade para o nível de elite. Um exemplo recente de uma remadora que conquistou uma medalha olímpica após sua carreira universitária é Madison Mailey. Mailey, uma remadora All-American na Northeastern University, que se formou em 2018, conquistou uma medalha de ouro nos Jogos Olímpicos de Tóquio no oito feminino para o Canadá. Ela e suas colegas de equipe assumiram a liderança cedo e resistiram a um desafio tardio da Nova Zelândia, vencendo por uma margem de apenas 0,91 segundos.
    </MKTypography>
  </MKBox>

  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Oportunidades Profissionais e Internacionais
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O remo universitário abre portas para oportunidades profissionais e internacionais para os remadores. Além do potencial de participação olímpica, os remadores universitários podem usar suas habilidades para competir em regatas internacionais prestigiadas, como a Regata Real Henley ou a Copa do Mundo de Remo. Esses eventos oferecem uma plataforma para os remadores se medirem com os melhores do mundo e adquirirem experiência em um contexto internacional.
      <br />
      <br />
      Além disso, existe um caminho para o remo profissional, onde graduados universitários podem ingressar em equipes profissionais e participar de competições globais. Isso oferece uma carreira de remo em tempo integral com treinamento intensivo e a oportunidade de competir no mais alto nível.
      <br />
      <br />
      Para aqueles interessados em uma carreira de treinador, o remo universitário oferece uma base sólida. Muitos treinadores de remo bem-sucedidos são ex-atletas universitários que usam sua experiência e expertise para orientar novas gerações de remadores. Esse caminho de carreira pode levar a posições com equipes nacionais e clubes de remo ao redor do mundo.
      <br />
      <br />
      Em resumo, as experiências adquiridas no remo universitário podem levar a uma variedade de oportunidades profissionais e internacionais, desde atletas que buscam uma carreira profissional até aqueles que continuam no esporte em um papel de treinador.
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  







<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 4: Influências Internacionais e Diversidade
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Este capítulo destaca as dinâmicas internacionais e a diversidade dentro do remo universitário nos Estados Unidos. Exploramos como os estudantes-atletas internacionais enriquecem o esporte com suas origens e perspectivas únicas, e como os remadores americanos encontram oportunidades para remar no exterior e se desenvolver ainda mais. Essas interações contribuem para uma experiência cultural mais rica e ampliam os horizontes tanto dos remadores americanos quanto dos internacionais. Investigamos os diversos caminhos que os estudantes internacionais seguem para remar no nível universitário nos EUA, os benefícios dessas experiências e o impacto que têm no esporte e em seus participantes. Além disso, focamos nas oportunidades para os remadores americanos adquirirem experiência internacional, incluindo programas de intercâmbio de estudos, acampamentos de treinamento internacionais e oportunidades de treinamento. Este capítulo enfatiza a importância das influências internacionais em enriquecer o remo universitário e promover a troca e o entendimento culturais.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Atletas Internacionais no Remo Universitário
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O remo universitário não é apenas uma questão americana, mas também internacional. Muitos estudantes internacionais vêm para os EUA para estudar e remar em universidades americanas. Esses estudantes trazem sua própria cultura, origem e perspectiva, fazendo contribuições valiosas para a diversidade e qualidade das equipes. Além disso, proporcionam uma oportunidade para a troca cultural e amizade entre remadores de diferentes países.
      <br />
      <br />
      Existem várias razões pelas quais estudantes internacionais escolhem o remo universitário nos EUA. Algumas dessas razões incluem:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Bolsas de Estudo no Remo: Muitas universidades americanas oferecem bolsas de estudo para remadores talentosos, o que pode reduzir ou até mesmo eliminar seus custos com mensalidades. Isso é um incentivo significativo para estudantes internacionais que de outra forma não poderiam estudar nos EUA.
        </li>
        <li>
          Excelência Acadêmica: Muitas universidades americanas são conhecidas por seus altos padrões acadêmicos e reputação. Isso atrai estudantes internacionais em busca de uma educação excelente e um diploma reconhecido globalmente.
        </li>
        <li>
          Desafio Esportivo: Muitos estudantes internacionais veem o remo universitário como um desafio esportivo e uma oportunidade de competir com os melhores remadores do mundo. Eles visam melhorar suas habilidades, superar seus limites e realizar seu potencial.
        </li>
        <li>
          Desenvolvimento Pessoal: Muitos estudantes internacionais veem o remo universitário como um desenvolvimento pessoal e uma chance de crescer como indivíduos. Eles querem ganhar novas experiências, conhecer novas pessoas, aprender sobre novas culturas e ampliar seus horizontes.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      "Niki van Sprang é um exemplo de um estudante internacional que remou no nível universitário nos Estados Unidos. Van Sprang, originário dos Países Baixos, onde conquistou vários títulos nacionais, recebeu uma bolsa de remo da Universidade da Califórnia, Berkeley. Lá, ele se juntou à equipe varsity e obteve sucesso significativo. Após se formar em 2017, Van Sprang voltou para os Países Baixos e se tornou membro da equipe nacional de remo. Ele participou dos Jogos Olímpicos de Tóquio em 2020, onde ele e Guillaume Krommenhoek terminaram em 7º lugar no dois sem masculino. Além disso, ele conquistou a prata nos Campeonatos Mundiais de 2022 em Račice e nos Campeonatos Europeus de 2022 em Munique, bem como uma medalha de bronze nos Campeonatos Europeus de 2023 em Bled, todos no oito masculino."
    </MKTypography>
  </MKBox>

  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Remadores Americanos no Exterior
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O remo universitário também pode levar a oportunidades internacionais para estudantes-atletas americanos que desejam ampliar ou mudar seus horizontes esportivos. Existem várias oportunidades para os remadores americanos remar no exterior e adquirir experiência. Algumas dessas oportunidades incluem:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Intercâmbio de Estudos: Algumas universidades americanas têm programas de intercâmbio com universidades estrangeiras, permitindo que os alunos estudem e remem em outro país por um semestre ou um ano. Isso lhes dá a oportunidade de experimentar uma cultura, idioma e sistema educacional diferentes, além de remar com outras equipes e treinadores.
        </li>
        <li>
          Acampamentos de Treinamento: Algumas universidades americanas organizam acampamentos de treinamento no exterior, geralmente durante as férias de inverno ou verão. Isso lhes dá a chance de treinar em um ambiente, clima e condição de água diferentes, além de competir contra outras equipes ou participar de regatas locais.
        </li>
        <li>
          Treinamento: Alguns remadores americanos decidem treinar no exterior após se formarem, geralmente em escolas, clubes ou equipes nacionais. Isso lhes dá a oportunidade de compartilhar seus conhecimentos e experiências, além de aprender com outros treinadores e culturas.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    As bolsas de estudo esportivas oferecem inúmeras oportunidades para jovens atletas mostrarem seu talento e desenvolverem suas habilidades. Uma das oportunidades mais significativas é obter uma bolsa de estudos esportiva para estudar e competir no nível universitário. Neste capítulo, discutiremos a importância das bolsas de estudo esportivas e como a Sport Scholarships America pode ajudá-lo a realizar seu sonho.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 A Importância das Bolsas de Estudo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Uma bolsa de estudos esportiva é um suporte financeiro fornecido por uma universidade a um estudante-atleta para combinar estudos e esportes. Pode cobrir os custos de mensalidades, livros, moradia, refeições e outras despesas. Além disso, pode oferecer benefícios como tutoria acadêmica, cuidados médicos, instalações de treinamento e equipamentos.
      <br />
      <br />
      Obter uma bolsa de estudos esportiva é competitivo, com muitos fatores desempenhando um papel no processo de concessão, incluindo realizações acadêmicas, desempenho atlético, personalidade, motivação, liderança e adaptabilidade. Portanto, é importante se preparar bem e se destacar da concorrência.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Como a Sport Scholarships America Pode Ajudar
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo da Sport Scholarships America, mostrando equipamentos esportivos e um capelo de formatura, representando o foco em bolsas de estudo esportivas para estudantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo da Sport Scholarships America
        </figcaption>
      </figure>
      A Sport Scholarships America é uma organização especializada em orientar jovens talentos esportivos para bolsas de estudo esportivas. Com especialistas apaixonados e experientes, a Sport Scholarships America apoia estudantes-atletas em cada etapa da jornada, desde a orientação inicial até a colocação final. Seus serviços incluem:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Uma entrevista de admissão pessoal para discutir os desejos, possibilidades e expectativas do estudante-atleta.</li>
        <li>Uma avaliação profissional do nível acadêmico e atlético do estudante-atleta.</li>
        <li>Uma extensa rede de contatos com treinadores, universidades e faculdades.</li>
        <li>Promoção eficaz do perfil e vídeos do estudante-atleta para treinadores potenciais.</li>
        <li>Conselhos especializados sobre as melhores opções com base nas preferências, metas e orçamento do estudante-atleta.</li>
        <li>Negociação das melhores ofertas de bolsas de estudo em nome do estudante-atleta.</li>
        <li>Auxílio na solicitação de documentos necessários, como visto, histórico escolar, diploma e testes de admissão.</li>
        <li>Preparação para a vida do estudante-atleta durante a bolsa de estudos, incluindo cultura, idioma, regras e dicas.</li>
        <li>Acompanhamento e acompanhamento durante o período da bolsa de estudos.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Quer explorar oportunidades de bolsas de estudo e alcançar seus objetivos atléticos e acadêmicos? <Link to="/pt/Serviços-Produtos">Veja a gama completa</Link> de serviços oferecidos pela Sport Scholarships America e agende uma consulta hoje.
    </MKTypography>
  </MKBox>
</MKBox>



          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;