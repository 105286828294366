/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState } from 'react'; // Step 1: Import useState


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";


// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Logos/NCAA.png";
import post3 from "assets/images/Logos/NJCAA.png";



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {

  const articleStructure = [
    { id: 'intro', title: 'NAIA: Et Alternativt Rute i Amerikansk College Sport', isSubheader: false },
  
    { id: 'Hoofdstuk 1', title: 'NAIA\'s Opprinnelse og Utvikling', isSubheader: true },
    { id: '1.1', title: 'NAIA og dens Unike Divisjoner', isSubheader: true },
    { id: '1.2', title: 'Den Akademiske Målet', isSubheader: true },
  
    { id: 'Hoofdstuk 2', title: 'Finanser, Medier, og Synlighet', isSubheader: true },
    { id: '2.1', title: 'Utfordringer og Veien Fremover', isSubheader: true },
    { id: '2.2', title: 'Avslutning', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
     <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="intro" mb={3}>
              <MKTypography variant="h1" className="h1-as-h3" mb={2}>NAIA: Et Alternativt Rute i Amerikansk College Sport

</MKTypography>
              </MKBox>
              <MKBox id="Hoofdstuk 1" mb={3}>

              <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              NAIA's Opprinnelse og Utvikling
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              National Association of Intercollegiate Athletics, eller NAIA, er en avgjørende enhet i det amerikanske collegidrettlandskapet, selv om den er mindre kjent enn NCAA. Grunnlagt i 1937, har NAIA utviklet seg til en anerkjent organisasjon som gir student-idrettsutøvere en unik mulighet til å realisere sine sportslige og akademiske ambisjoner.

<br />
                <br />
                Fra begynnelsen har NAIA fokusert på å tilby muligheter til mindre utdanningsinstitusjoner, med vekt på å skape en balansert opplevelse for student-idrettsutøvere. Organisasjonen fremmer både konkurransedyktig idrett og akademisk dyktighet, med troen på at de to kan gå hånd i hånd.

</MKTypography>
<MKBox id="1.1" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
                <br />
                NAIA og dens Unike Divisjoner
                </MKTypography>
                </MKBox>

                <MKTypography variant="body1" fontWeight="light" color="text"> 
                Til forskjell fra NCAA er NAIA mer kompakt organisert, med en inndeling i to divisjoner som begge tilbyr en rik blanding av idrett og utdanning:

<br /><br />
  <MKTypography component="span" variant="body1" fontWeight="bold">
    Divisjon I:{' '}
  </MKTypography>
  I denne divisjonen finner vi skoler som tilbyr et høyere nivå av idrettsstipend og hvor idrettskonkurransen er svært konkurransedyktig. Til tross for skolenes mindre størrelse sammenlignet med NCAA Divisjon I-institusjoner, gir NAIA Divisjon I en plattform for toppidrettsutøvere å vise frem sine ferdigheter og utvikle seg.
  <br />
  <br />
  <MKTypography component="span" variant="body1" fontWeight="bold">
    Divisjon II:{' '}
  </MKTypography>
  Denne divisjonen legger enda mer vekt på balansen mellom idrett, akademia og campusengasjement. Idrettsstipendene er mer begrensede, men mulighetene for student-idrettsutøvere å utmerke seg både i idrett og studier er rikelig til stede. 
  </MKTypography>

  <MKBox id="1.2" mb={3}>

  <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Den Akademiske Målet
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              NAIA verdsetter de akademiske prestasjonene til sine student-idrettsutøvere. Det er etablert strenge akademiske standarder for å sikre deltakelse i idrett, og tilknyttede skoler er dedikerte til å tilby nødvendig akademisk støtte. Dette resulterer i et miljø der student-idrettsutøvere oppfordres til å strebe etter utmerkelse i alle aspekter av livet sitt.

                <br />
                </MKTypography>
                  





                                  






                <MKBox id="Hoofdstuk 2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Finanser, Medier, og Synlighet
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
Selv om NAIA kanskje ikke kan konkurrere økonomisk med de enorme inntektene til NCAA, spiller den fortsatt en viktig rolle i finansieringen av idrettsstipender og forbedring av fasiliteter. Videre øker NAIA sin synlighet takket være strømmetjenester og sosiale medier, noe som hjelper til med å spre budskapet om balansert idrett og akademikere.


<br />
</MKTypography>
<MKBox id="2.1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Utfordringer og Veien Fremover
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
Akkurat som NCAA, står NAIA overfor utfordringer, inkludert å navigere gjennom kompleksiteten av amatørstatusen til student-idrettsutøvere og å sikre likhet innen idrett. Imidlertid, med sin unike posisjon og dedikasjon til utviklingen av student-idrettsutøvere, er NAIA godt posisjonert til å fortsette å ha en positiv innvirkning på livene til mange unge idrettsutøvere.

</MKTypography>
<MKBox id="2.2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Avslutning
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
National Association of Intercollegiate Athletics tilbyr et verdifullt og unikt alternativ i verden av amerikansk collegidrett. Med fokus på balanse, akademisk fremragende, og idrettslig konkurranse, forblir NAIA en avgjørende kraft i formingen av fremtiden til student-idrettsutøvere. Mens den ser fremover og fortsetter å utvikle seg, forblir dens engasjement for utviklingen av unge mennesker urokkelig, og gir dermed en vedvarende og positiv innvirkning på den amerikanske idretts- og utdanningsverdenen.
<br />


</MKTypography>

              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="NCAA"
                    description="NCAA: Hjertet av College-sport i Amerika"
                    action={{
                      type: "internal",
                      route: "/no/NCAA",
                      color: "info",
                      label: "Les Mer",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="NJCAA"
                    description="NJCAA: Springbrettet til Store Universiteter og NCAA"
                    action={{
                      type: "internal",
                      route: "/no/NJCAA",
                      color: "info",
                      label: "Les Mer",
                    }}
                  />
                </Grid>
              </Grid>

            </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;