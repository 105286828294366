/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Wrestling1.jpeg";
import Image2 from "assets/images/Sports_Images/Wrestling2.webp";
import Image3 from "assets/images/Sports_Images/Wrestling3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Brostipendier för brottning i USA: Hur att studera och brottas i Amerika kan förverkliga dina drömmar', isSubheader: false },
  
    { id: 'Kapitel 1', title: 'Kapitel 1: Brottningens Historia och Utveckling i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Tidiga Åren', isSubheader: true },
    { id: '1.2', title: '1.2 Tillväxt och Utveckling', isSubheader: true },
  
    { id: 'Kapitel 2', title: 'Kapitel 2: Collegbrottning Idag', isSubheader: false },
    { id: '2.1', title: '2.1 Viktiga Tävlingar och Konferenser', isSubheader: true },
    { id: '2.2', title: '2.2 Topp Program och Prestigefyllda Lag', isSubheader: true },
  
    { id: 'Kapitel 3', title: 'Kapitel 3: Från Collegbrottning till Olympisk Nivå och Professionella Ligor', isSubheader: false },
    { id: '3.1', title: '3.1 Vägen till Topp', isSubheader: true },
    { id: '3.2', title: '3.2 Professionell Brottning och Olympiska Spelen', isSubheader: true },
  
    { id: 'Kapitel 4', title: 'Kapitel 4: Internationella Influenser och Tävlingar', isSubheader: false },
    { id: '4.1', title: '4.1 Utländska Brottningstilar och Traditioner', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikaner på Världsscenen', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapitel 5: Idrottsstipendier och Framtiden för Idrottare', isSubheader: false },
    { id: '5.1', title: '5.1 Vikten av Stipendier', isSubheader: true },
    { id: '5.2', title: '5.2 Hur Sport Scholarships America Kan Hjälpa', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innehåll
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Dölj</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Visa</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>




















          <MKBox id="introduktion" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Brostipendier för brottning i USA: Hur att studera och brottas i Amerika kan förverkliga dina drömmar
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Aerial view of a packed stadium at a college wrestling match."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Nationalmästerskapet
      </figcaption>
    </figure>
    Brottning är en sport som har praktiserats i tusentals år över hela världen. Det är en form av fysisk kamp där två motståndare försöker dominera varandra genom grepp, kast, nedtagningar och andra tekniker. Brottning kräver inte bara fysisk styrka, snabbhet, uthållighet och flexibilitet utan också mental skärpa, strategi, disciplin och uthållighet.
    <br />
    <br />
    I USA är brottning en av de mest praktiserade och sedda sporterna. Den har en lång och fängslande historia som sträcker sig tillbaka till kolonialtiden. Sedan dess har den utvecklats till en nationell fenomen med miljontals fans och utövare. Amerikansk collegbrottning representerar höjdpunkten av denna utveckling. Det är nivån där landets bästa brottare möts i spännande tävlingar som ofta fungerar som ett trampolin till professionella eller olympiska karriärer.
    <br />
    <br />
    Betydelsen av collegenivåbrottning i idrottares utveckling kan inte underskattas. Collegebrottare får inte bara möjlighet att visa upp och förbättra sina idrottsliga talanger utan också att studera vid namnkunniga universitet, där de kan förvärva värdefulla akademiska färdigheter och kunskaper. Dessutom kan de dra nytta av stipendier som kan täcka en betydande del eller hela deras undervisningskostnader.
    <br />
    <br />
    Att få ett brottarstipendium är dock ingen lätt uppgift. Det kräver mycket förberedelse, hängivenhet och vägledning. Lyckligtvis behöver du inte göra det ensam. Sportbeursamerika.nl är en organisation som är specialiserad på att hjälpa unga brottartalanger att förverkliga sin dröm om att studera och brottas i Amerika. Jag erbjuder dig professionell rådgivning, personligt stöd och åtkomst till mitt omfattande nätverk av kontakter inom den amerikanska brottarvärlden.
    Vill du veta mer om hur jag kan hjälpa dig att säkra ett brottarstipendium i USA? Läs vidare! I den här artikeln kommer jag att berätta mer om:
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Huvudkapitel och underkapitel med platshållartext */}
<MKBox id="Kapitel 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Brottningens Historia och Utveckling i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Brottning är en sport som har praktiserats sedan antiken i olika kulturer. Det är en av de äldsta formerna av organiserad tävlan, nämnd i grekisk mytologi och i Bibeln. Det var också en av de första sporterna som ingick i de antika olympiska spelen.
    <br />
    <br />
    I USA har brottningen haft sin egen historia och utveckling nära knuten till landets sociala och politiska sammanhang. I det här kapitlet kommer vi att ge en översikt över de huvudsakliga faserna och händelserna i amerikansk brottningshistoria och hur de bidrog till bildandet av modern collegbrottning.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidiga Åren
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Brottningens början i Amerika kan spåras tillbaka till kolonialtiden när europeiska nybyggare tog med sig sina egna brottningstilar och traditioner till den nya kontinenten. Dessa stilar var främst baserade på engelsk catch-as-catch-can brottning, som tillät grepp på vilken del av kroppen som helst förutom huvudet. Det var en populär underhållningsform och utmaning bland kolonisatörerna som ofta organiserade matcher på helgdagar eller marknadsdagar.
      <br />
      <br />
      En av de tidigaste kända brottarna i Amerika var George Washington, som som ung man var känd för sina brottningstalanger. Han besegrade en gång sju motståndare på en dag under en match i Virginia. En annan känd brottare från den här perioden var Abraham Lincoln, som vann flera matcher som tonåring i Illinois.
      <br />
      <br />
      Det första collegelaget i brottning bildades 1780 vid Yale University, men det dröjde mer än ett sekel innan collegbrottningen verkligen tog fart. Den första officiella intercollegiate-matchen ägde rum 1889 mellan Yale och Columbia University, med Yale som vinnare med 5-0. År 1892 hölls det första nationella mästerskapet i New York City, med åtta lag från olika delstater. Vinnaren var Cornell University, som vann alla sju matcher.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Tillväxt och Utveckling
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      I slutet av 1800-talet och början av 1900-talet började brottningen spridas och diversifieras i Amerika. Olika regionala stilar uppstod, som Iowa-stilbrottning, som betonade hastighet och smidighet, eller Oklahoma-stilbrottning, som fokuserade på styrka och kontroll. Nya regler infördes också för att göra brottningen säkrare och rättvisare, som att förbjuda farliga grepp eller införa tidsgränser.
      <br />
      <br />
      Collegbrottningen växte också i popularitet och prestige, med fler lag, tävlingar och fans. År 1928 grundades National Collegiate Athletic Association (NCAA) för att reglera och främja collegbrottningen. NCAA organiserade årliga nationella mästerskap för olika divisioner baserade på universitetens storlek och nivå. Den mest prestigefyllda divisionen var Division I, som bestod av cirka 80 lag.
      <br />
      <br />
      Collegbrottningen producerade också många stjärnor och legender som uppnådde både nationell och internationell framgång. Några exempel inkluderar:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Dan Gable: En av de största brottarna genom tiderna, som dominerade både som idrottare och tränare. Han vann tre NCAA-titlar för Iowa State University, en guldmedalj på OS 1972 utan att förlora en enda poäng och 15 NCAA-titlar som tränare för University of Iowa.
        </li>
        <li>
          John Smith: En sexfaldig världsmästare och tvåfaldig olympisk mästare känd för sina innovativa tekniker och taktik. Han vann två NCAA-titlar för Oklahoma State University och blev senare huvudtränare för samma lag.
        </li>
        <li>
          Cael Sanderson: Den enda brottaren som har vunnit fyra NCAA-titlar utan att förlora en enda match. Han hade en perfekt rekord på 159-0 för Iowa State University och vann också en guldmedalj på OS 2004. Han är för närvarande huvudtränare vid Penn State University, där han har vunnit åtta NCAA-titlar.
        </li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Detta är bara några exempel på tillväxten och utvecklingen av brottning i Amerika, vilket har lett till den nuvarande collegbrottningsscenen, som anses vara en av de mest konkurrenskraftiga och spännande sporterna i landet.
    </MKTypography>
  </MKBox>
</MKBox>



                                  






<MKBox id="Kapitel 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Collegbrottning Idag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Collegbrottning är en av de mest populära och prestigefyllda sporterna i Amerika idag. Den lockar miljontals fans och åskådare som följer de spännande matcherna via television, internet eller live i arenorna. Collegbrottning erbjuder också talrika möjligheter för studentidrottare som kan dra nytta av stipendier, akademiskt stöd och professionell vägledning.
    <br />
    <br />
    I det här kapitlet kommer vi att berätta mer om de huvudsakliga tävlingarna och konferenserna inom collegbrottning samt de främsta programmen och prestigefyllda lagen som dominerar sporten.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Viktiga Tävlingar och Konferenser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Two college wrestlers in an intense duel on the mat."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Två brottare i aktion
        </figcaption>
      </figure>
      Collegbrottning organiseras av NCAA, som har tre divisioner: Division I, Division II och Division III. Varje division har sina egna regler, kriterier och mästerskap. Den mest konkurrenskraftiga och välkända divisionen är Division I, som består av cirka 80 lag från olika delstater.
      <br />
      <br />
      Lagen i Division I är uppdelade i olika konferenser och bildar regionala eller tematiska grupper. Konferenserna organiserar sina egna tävlingar och mästerskap, vilka fungerar som kvalifikationer för det nationella mästerskapet. De mest framträdande konferenserna inom collegbrottning inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Big Ten Conference: Den största och starkaste konferensen inom collegbrottning, bestående av 14 lag från Mellanvästern och Nordöstra regionerna i landet. Big Ten har producerat flest nationella mästare, inklusive Penn State, Iowa, Ohio State, Michigan och Minnesota.
        </li>
        <li>
          Big 12 Conference: Den näst största och starkaste konferensen inom collegbrottning, bestående av 12 lag från södra och västra regionerna i landet. Big 12 har också producerat många nationella mästare, inklusive Oklahoma State, Oklahoma, Iowa State och Missouri.
        </li>
        <li>
          Atlantic Coast Conference (ACC): En framväxande konferens inom collegbrottning, bestående av sex lag från sydöstra regionen av landet. ACC har fått mer uppmärksamhet på senare tid på grund av prestationerna från lag som Virginia Tech, North Carolina State och North Carolina.
        </li>
        <li>
          Eastern Intercollegiate Wrestling Association (EIWA): En historisk konferens inom collegbrottning, bestående av 16 lag från den östra regionen av landet. EIWA är den äldsta brottningskonferensen i Amerika, grundad 1905. EIWA har också producerat några nationella mästare, inklusive Cornell, Lehigh, Princeton och Navy.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Detta är bara några av de många konferenserna inom collegbrottning, var och en med sina egna rivaliteter, traditioner och fans. Konferenser är emellertid inte det enda som avgör hur framgångsrikt ett lag är. Det finns andra aspekter som spelar roll för ett brottningprogram.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Topp Program och Prestigefyllda Lag
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ett brottningprogram är ett begrepp som används för att hänvisa till organisationen och strukturen för ett universitets brottningsteam. Ett brottningprogram inkluderar aspekter som tränaren, personalen, faciliteterna, rekryteringen, träningen, finansieringen och lagets kultur.
      <br />
      <br />
      Ett bra brottningprogram kan göra en betydande skillnad i utvecklingen av studentidrottare. Ett bra brottningprogram kan tillhandahålla studentidrottare med:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          En erfaren och kvalificerad tränare som kan lära dem hur de förbättrar sin teknik, taktik, kondition och mindset.
        </li>
        <li>
          En engagerad och professionell personal som kan stödja dem inom områden som näring, skadeförebyggande, återhämtning, akademisk vägledning och karriärplanering.
        </li>
        <li>
          Moderna och välutrustade faciliteter där de kan träna på en hög nivå med lämplig utrustning och material.
        </li>
        <li>
          En effektiv och etisk rekryteringsstrategi som kan hjälpa dem att attrahera och behålla talangfulla och motiverade brottare.
        </li>
        <li>
          En intensiv och varierad träningsschema som kan utmana dem och förbereda dem för olika matcher och situationer.
        </li>
        <li>
          Tillräcklig och stabil finansiering som kan hjälpa dem att täcka kostnaderna för brottning, såsom resor, utrustning, stipendier och andra utgifter.
        </li>
        <li>
          En positiv och inspirerande kultur som kan motivera och uppmuntra dem att nå sin potential, följa sina mål och dela sin passion för sporten.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Det finns många universitet i Amerika som har utmärkta brottningprogram som har varit framgångsrika inom collegbrottning i åratal. Några av dessa program inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Penn State: Det nuvarande topplaget inom collegbrottning, som har vunnit det nationella mästerskapet åtta gånger sedan 2011. Penn State är känt för sin aggressiva och spektakulära brottarstil, ledd av tränare Cael Sanderson, en av de största brottarna genom tiderna.
        </li>
        <li>
          Iowa: Det mest framgångsrika laget i historien om collegbrottning, med över 20 nationella mästerskap sedan 1975. Iowa är känt för sin aggressiva och dominanta brottarstil, ledd av tränare Tom Brands, en före detta olympisk mästare.
        </li>
        <li>
          Oklahoma State: Det näst mest framgångsrika laget i historien om collegbrottning, med över 20 nationella mästerskap. Oklahoma State är känt för sin tekniska och taktiska brottarstil, ledd av tränare John Smith, en av de största brottarna genom tiderna.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Detta är bara några av de många toppprogrammen och prestigefyllda lagen inom collegbrottning, var och en med sina egna egenskaper, prestationer och fans. Det är emellertid inte lätt att vara en del av dessa program och lag. Det krävs mycket talang, hårt arbete och engagemang för att säkra ett brottningsstipendium.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  







<MKBox id="Kapitel 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Från Collegbrottning till Olympisk Nivå och Professionella Ligor
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Collegbrottning är inte slutpunkten för många studentidrottare som drömmer om en karriär inom sporten. Det är snarare en trampsten till högre nivåer där de kan tävla mot världens bästa brottare. I det här kapitlet kommer vi att berätta mer om vägen till toppen inom professionell eller olympisk brottning och framgångar och utmaningar för amerikanska brottare på världsscenen.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Vägen till Topp
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Collegbrottning är en av de mest konkurrenskraftiga och krävande sporterna i Amerika. Det kräver mycket talang, hårt arbete och engagemang att säkra ett brottningsstipendium, och ännu mer för att lysa i nationella tävlingar. Men även om du är en av de bästa collegbrottarna är det ingen garanti för framgång på professionell eller olympisk nivå.
      <br />
      <br />
      Det finns flera faktorer som gör det utmanande att övergå från collegbrottning till professionell eller olympisk brottning. Några av dessa faktorer inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Stilskillnader: Collegbrottning utövas enligt folkstyle-regler, unika för Amerika. Dessa regler fokuserar på att kontrollera och dominera motståndaren, med poäng som tilldelas för rittning, utbrytning, omvändning och nära fall. Professionell eller olympisk brottning å andra sidan utövas enligt freestyle- eller grekisk-romerska regler, använda internationellt. Dessa regler betonar att göra poäng genom kast, tiltar, exponeringar och pinnar. Det innebär att collegbrottare måste anpassa sin teknik, taktik och strategi för att tävla med brottare från andra länder som har mer erfarenhet av dessa stilar.
        </li>
        <li>
          Åldersskillnader: Collegbrottning är en sport för unga idrottare, vanligtvis mellan 18 och 23 år gamla. Professionell eller olympisk brottning är emellertid en sport för vuxna idrottare, vanligtvis mellan 25 och 35 år gamla. Det innebär att collegbrottare måste tävla mot äldre och mer erfarna brottare som har större fysisk styrka, mental mognad och teknisk förfining.
        </li>
        <li>
          Finansiella skillnader: Collegbrottning är en sport som stöds av universitet, som ger studentidrottare stipendier, faciliteter, coaching och andra förmåner. Professionell eller olympisk brottning får emellertid lite finansiellt stöd från regeringen eller sponsorer. Det innebär att collegbrottare måste söka andra inkomstkällor för att täcka sin träning, resor, utrustning och levnadskostnader.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Detta är bara några av de många utmaningar som collegbrottare måste övervinna för att följa sin dröm om en professionell eller olympisk karriär. Men trots dessa utmaningar finns det också många framgångar och inspirerande berättelser om amerikanska brottare som har nått den högsta nivån.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professionell Brottning och Olympiska Spelen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Professionell brottning eller olympisk brottning är sportens höjdpunkt, där de bästa brottarna i världen tävlar i prestigefyllda tävlingar och evenemang. Det finns två huvudformer av professionell eller olympisk brottning: fristil och grekisk-romersk.
      <br />
      <br />
      Fristilsbrottning är en form av brottning där alla typer av grepp på hela kroppen är tillåtna, så länge de inte är farliga eller olämpliga. Fristilsbrottning utövas av både män och kvinnor, i olika viktklasser. Fristilsbrottning är en av de äldsta sporterna i de olympiska spelen, hållna sedan 1904 för män och sedan 2004 för kvinnor.
      <br />
      <br />
      Grekisk-romersk brottning är en form av brottning där endast grepp över midjan är tillåtna, och användningen av benen för att ta eller kasta motståndaren är förbjuden. Grekisk-romersk brottning utövas endast av män, i olika viktklasser. Grekisk-romersk brottning är den äldsta formen av brottning i de olympiska spelen, hållna sedan 1896.
      <br />
      <br />
      Amerikanska brottare har uppnått många framgångar både inom fristils- och grekisk-romersk brottning, vid olympiska spelen såväl som i andra internationella tävlingar som världsmästerskapen, Panamerikanska spelen eller världscupen. Några av de mest framgångsrika och välkända amerikanska brottarna inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Jordan Burroughs: En femfaldig världsmästare och olympisk mästare i fristilsbrottning, känd för sin explosiva och dynamiska stil. Han vann en guldmedalj vid OS 2012 i London och fyra världsmästerskapstitlar 2011, 2013, 2015 och 2017. Han vann också två NCAA-titlar för University of Nebraska.
        </li>
        <li>
          Kyle Snyder: En trefaldig världsmästare och olympisk mästare i fristilsbrottning, känd för sin styrka och teknik. Han vann en guldmedalj vid OS 2016 i Rio de Janeiro och tre världsmästerskapstitlar 2015, 2017 och 2019. Han vann också tre NCAA-titlar för Ohio State University.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Detta är bara några av de många framgångarna och inspirerande berättelserna om amerikanska brottare på professionell eller olympisk nivå. De visar att det är möjligt att uppnå din dröm om du är villig att arbeta hårt, lära dig och växa.
    </MKTypography>
  </MKBox>
</MKBox>






                                  






<MKBox id="Kapitel 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationella Influenser och Tävlingar
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Brottning är en universell sport, utövad och uppskattad i många länder och kulturer. Brottning har också många stilar och traditioner som har uppstått och utvecklats i olika regioner och perioder. I det här kapitlet kommer vi att berätta mer om de internationella influenserna och tävlingarna inom brottning och hur de påverkar och utmanar amerikanska brottare.
  </MKTypography>
  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Utländska Brottningstilar och Traditioner
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Flygvy över en NCAA brottningstävling i en fullsatt arena."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          En match i NCAA-turneringen
        </figcaption>
      </figure>
      Brottning är en sport som har utövats sedan forntida tider i olika delar av världen. Det är en av de äldsta formerna av fysiskt uttryck, ofta associerad med religiösa, kulturella eller sociala betydelser. Det finns många typer av brottning, var och en med sina egna egenskaper, regler och tekniker. Några exempel inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Sumo: En traditionell japansk brottningstil betraktad som en nationalsport och en helig ritual. Sumo utövas av tunga och starka brottare som försöker knuffa varandra ut ur en cirkulär ring eller kasta dem till marken.
        </li>
        <li>
          Mongolisk brottning: En traditionell mongolisk brottningstil betraktad som en symbol för mod och styrka. Mongolisk brottning utövas av muskulösa och smidiga brottare som försöker föra varandra till marken genom grepp på övre kroppen eller benen.
        </li>
        <li>
          Turkisk oljebrottning: En traditionell turkisk brottningstil betraktad som ett arv och en konst. Turkisk oljebrottning utövas av oljade och muskulösa brottare som försöker besegra varandra genom grepp på byxorna eller bältet.
        </li>
        <li>
          Senegalesisk brottning: En traditionell senegalesisk brottningstil betraktad som en populär och lukrativ sport. Senegalesisk brottning utövas av atletiska och färgstarka brottare som försöker fälla varandra genom kast, slag eller sparkar.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Detta är bara några av de många utländska brottningstilarna och traditionerna, var och en med sin egen historia, kultur och fans. Dessa stilar har också påverkat amerikansk collegbrottning genom att introducera nya tekniker, taktiker eller utmaningar. Många amerikanska collegbrottare har rest eller tränat i andra länder för att bredda sina vyer och förbättra sina färdigheter.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikaner på Världsscenen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Amerikansk collegbrottning fokuserar inte bara på nationella tävlingar utan också på internationella tävlingar. Det finns många internationella evenemang där amerikanska collegbrottare kan delta för att tävla mot världens bästa brottare. Några av dessa evenemang inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Olympiska spelen: Det största och mest prestigefyllda evenemanget inom professionell eller olympisk brottning, hålls vart fjärde år. Olympiska spelen är det ultimata målet för många collegbrottare som drömmer om att vinna en guldmedalj för sitt land.
        </li>
        <li>
          Världsmästerskapen: Det näst största och mest prestigefyllda evenemanget inom professionell eller olympisk brottning, hålls årligen. Världsmästerskapen är en betydande möjlighet för collegbrottare att kvalificera sig för OS eller etablera sitt rykte på världsscenen.
        </li>
        <li>
          Världscupen: Det näst största och mest prestigefyllda evenemanget inom regional brottning, hålls årligen. Världscupen är en betydande möjlighet för collegbrottare att vara en del av ett landslag som tävlar mot andra landslag i lagformat.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Detta är bara några av de många internationella evenemang där amerikanska collegbrottare kan delta för att visa upp sina talanger och följa sina drömmar. Dock är dessa evenemang inte bara en källa till ära och berömmelse utan också en källa till utmaning och svårighet. Collegbrottare måste tävla mot brottare från andra länder som ofta har mer erfarenhet, stil eller motivation.
    </MKTypography>
  </MKBox>
</MKBox>
                                    






                                  






<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Idrottsstipendier och Framtiden för Idrottare
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrottsstipendier erbjuder många möjligheter för unga idrottare att visa upp sin talang och utveckla sina färdigheter. En av de mest betydelsefulla möjligheterna är att få ett idrottsstipendium för att studera och tävla på collegenivå. I det här kapitlet kommer vi att diskutera vikten av idrottsstipendier och hur Sport Scholarships America kan hjälpa dig att förverkliga din dröm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vikten av Stipendier
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ett idrottsstipendium är ekonomiskt stöd som tillhandahålls av ett universitet till en idrottare för att kombinera studier och idrott. Det kan täcka kostnaderna för undervisning, böcker, boende, måltider och andra utgifter. Dessutom kan det erbjuda fördelar som akademisk handledning, sjukvård, träningsanläggningar och utrustning.
      <br />
      <br />
      Att få ett idrottsstipendium är konkurrenskraftigt, med många faktorer som spelar en roll i tilldelningsprocessen, inklusive akademiska prestationer, idrottsprestationer, personlighet, motivation, ledarskap och anpassningsförmåga. Därför är det viktigt att förbereda sig väl och skilja sig från konkurrensen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hur Sport Scholarships America Kan Hjälpa
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotyp för Sport Scholarships America, med sportutrustning och en studentmössa, som representerar fokus på idrottsstipendier för idrottare."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotyp för Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America är en organisation specialiserad på att vägleda unga idrottstalanger till idrottsstipendier. Med passionerade och erfarna experter stöder Sport Scholarships America idrottare i varje steg av resan, från initial orientering till slutlig placering. Deras tjänster inkluderar:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intagningsintervju för att diskutera idrottarens önskemål, möjligheter och förväntningar.</li>
        <li>En professionell utvärdering av idrottarens akademiska och idrottsliga nivå.</li>
        <li>Ett omfattande nätverk av kontakter med tränare, universitet och högskolor.</li>
        <li>Effektiv marknadsföring av idrottarens profil och videor till potentiella tränare.</li>
        <li>Expertrådgivning om de bästa alternativen baserat på idrottarens preferenser, mål och budget.</li>
        <li>Förhandling om de bästa stipendieerbjudandena för idrottaren.</li>
        <li>Hjälp med att ansöka om nödvändiga dokument, såsom visum, betyg, examensbevis och antagningsprov.</li>
        <li>Förberedelse för idrottarens liv under stipendieperioden, inklusive kultur, språk, regler och tips.</li>
        <li>Uppföljning och uppföljning under stipendieperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vill du utforska möjligheter till stipendier och uppnå dina idrottsliga och akademiska mål? <Link to="/sv/Tjänster-Produkter">Se det fullständiga utbudet</Link> av tjänster som erbjuds av Sport Scholarships America och boka en konsultation idag.
    </MKTypography>
  </MKBox>
</MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;