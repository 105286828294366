/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";



// Images
import Image1 from "assets/images/Sports_Images/Soccer1.jpg";
import Image2 from "assets/images/Sports_Images/Soccer2.jpg";
import Image3 from "assets/images/Sports_Images/Soccer3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Soccer and Studying in America: A Dream That Can Become Reality', isSubheader: false },
  
    { id: 'Chapter 1', title: '1. The History and Development of Soccer in America', isSubheader: false },
    { id: '1.1', title: '1.1 Early Years and the Role of Colleges', isSubheader: true },
    { id: '1.2', title: '1.2 Growth and Professionalization', isSubheader: true },
  
    { id: 'Chapter 2', title: '2. College Soccer Today', isSubheader: false },
    { id: '2.1', title: '2.1 Key Competitions and Conferences for Soccer', isSubheader: true },
    { id: '2.2', title: '2.2 Top Programs and Prestigious Teams for Soccer', isSubheader: true },
  
    { id: 'Chapter 3', title: '3. From College to the Pros', isSubheader: false },
    { id: '3.1', title: '3.1 The Path to Professionalism', isSubheader: true },
  
    { id: 'Chapter 4', title: '4. The Impact of International Soccer', isSubheader: false },
    { id: '4.1', title: '4.1 Foreign Influences', isSubheader: true },
    { id: '4.2', title: '4.2 Americans Abroad', isSubheader: true },
  
    { id: 'Chapter 5', title: '5. Soccer Scholarships and the Future', isSubheader: false },
    { id: '5.1', title: '5.1 The Importance of Scholarships', isSubheader: true },
    { id: '5.2', title: '5.2 How Sport Scholarships America Can Help', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Content
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Hide</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Show</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>

























{/* Introduction */}
<MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Playing Soccer and Studying in America: A Dream Come True
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Creighton's stadium, with a capacity for over 7000 spectators, during a college soccer match."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Creighton Stadium: home to more than 7000 soccer fans.
      </figcaption>
    </figure>
    Soccer is one of the most popular sports in the world, but in America, it has long been overshadowed by other sports like basketball, baseball, and American football. However, in recent decades, soccer has grown tremendously in popularity and quality in the USA, thanks in part to the success of the national team, the rise of Major League Soccer (MLS), and the influence of international stars.
    <br />
    <br />
    But did you know that soccer in America also offers a unique opportunity for young talents to pursue their dreams? By playing soccer and studying in America, you can not only develop your athletic skills but also earn an academic degree and gain an unforgettable experience. All of this is possible thanks to the soccer scholarships that American universities offer to talented players.
    <br />
    <br />
    In this article, we'll tell you everything you need to know about playing soccer and studying in America, from the history and development of soccer in the USA to the benefits and opportunities of a soccer scholarship. We'll also explain how Sportbeursamerika.nl can help you turn this dream into reality. So, read on and discover how you can take advantage of this unique opportunity!
  </MKTypography>
</MKBox>

{/* Chapter 1 */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 1: The History and Development of Soccer in America
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Soccer, or soccer as Americans call it, is not as new a sport in the USA as you might think. In fact, the first official soccer match in America took place in 1869, between two university teams: Princeton and Rutgers. Since then, soccer has had a long and fascinating history in the USA, which we will discuss in this chapter.
  </MKTypography>
  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Early Years and the Role of Colleges
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      In the late 19th and early 20th centuries, soccer was especially popular among students, who played the game according to various rules and formats. For example, there were teams that played with 11, 15, or even 25 players, and sometimes used elements of rugby or American football.
      <br />
      <br />
      To create more unity and structure, the Intercollegiate Association of Amateur Athletes of America (IAAAA) was founded in 1905, the precursor to the National Collegiate Athletic Association (NCAA). The NCAA is the primary organization that regulates and organizes college soccer in the USA.
      <br />
      <br />
      In the first half of the 20th century, the number of college soccer teams steadily grew, and regional competitions and championships also emerged. One of the most famous of these is the College Cup, held since 1959 as the men's national championship. For women, the first College Cup was held only in 1982, but since then, women's soccer has seen tremendous growth in the USA, partly thanks to the success of the national team in international tournaments.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Growth and Professionalization
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      In addition to college soccer, professional soccer also developed in the USA, albeit with more ups and downs. The first professional league was the American Soccer League (ASL), founded in 1921 and mainly consisting of teams from the Northeast. The ASL had some successful years but ran into conflict with the American soccer federation over the organization and regulation of the game. This led to a split and ultimately the end of the ASL in 1933.
      <br />
      <br />
      After World War II, various leagues attempted to revive professional soccer, but none of them survived for long. It wasn't until 1967 that a stable league was established: the North American Soccer League (NASL). The NASL brought some big names to America, including Pelé, Franz Beckenbauer, and Johan Cruyff, and attracted large crowds to stadiums. However, the NASL also couldn't withstand financial problems, competition from other sports, and a lack of media interest. The NASL ceased to exist in 1984.
      <br />
      <br />
      It took until 1996 for a professional league to be established again: Major League Soccer (MLS). MLS is currently the highest division of American soccer, consisting of 27 teams from both the USA and Canada. MLS has evolved into a competitive and attractive league, attracting both domestic and international players. MLS has also contributed to the popularization of soccer in America by building stadiums, supporting youth development, and collaborating with other leagues.
      <br />
      <br />
      Professional soccer has also influenced college soccer, and vice versa. For example, there are players who transition to MLS after their college careers, or players who return to university to complete their studies after MLS. There are also players who play for both their college and a professional team, thanks to special arrangements. Additionally, some professional teams collaborate with college programs to scout and develop talents.
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  








{/* Chapter 2 */}
<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 2: College Soccer Today
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    As we saw in the previous chapter, college soccer in America has a long and rich tradition. But what does it look like today? What competitions and conferences exist? Which programs and teams are the most prestigious and successful? And how can you, as a player, get involved in this exciting world? In this chapter, we'll give you an overview of college soccer today and show you what to expect if you choose a soccer scholarship in America.
  </MKTypography>
  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Important Competitions and Conferences for Soccer
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Santa Clara soccer player in action during the NCAA tournament."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Soccer players in action during NCAA tournament match.
        </figcaption>
      </figure>
      As mentioned earlier, the NCAA is the primary organization that regulates and organizes college soccer in the USA. The NCAA consists of three divisions: Division I, Division II, and Division III. Each division has its own rules, requirements, and scholarships for student-athletes.
      <br />
      <br />
      Division I is the highest and most competitive division, where the best players and teams compete. Division I consists of approximately 200 men's teams and 300 women's teams, divided into various regional conferences. A conference is a group of universities that collaborate to organize a competition within their region. Some examples of well-known conferences are the Atlantic Coast Conference (ACC), the Big Ten Conference, the Pac-12 Conference, and the Southeastern Conference (SEC).
      <br />
      <br />
      Each year, teams within their conference compete for the title and a spot in the national championship: the College Cup. The College Cup is a knockout tournament held at the end of the season, usually in November and December. To qualify for the College Cup, teams must first earn their place through their conference or through an at-large bid awarded by a selection committee based on their performance during the season. The College Cup consists of 48 men's teams and 64 women's teams, who compete in various rounds until one champion remains.
      <br />
      <br />
      Division II and Division III are less competitive than Division I but still offer many opportunities for student-athletes to develop and enjoy the game. Division II consists of approximately 160 men's teams and 200 women's teams, also divided into different conferences. Division II also has a national championship, consisting of 36 men's teams and 48 women's teams. Division III consists of approximately 400 men's teams and 430 women's teams, who also compete in conferences. However, Division III does not offer scholarships to student-athletes but provides other forms of financial support. Division III also has a national championship, consisting of 62 men's teams and 64 women's teams.
      <br />
      <br />
      In addition to the NCAA, there are other organizations that organize college soccer in the USA, such as the National Association of Intercollegiate Athletics (NAIA), the National Junior College Athletic Association (NJCAA), and the United States Collegiate Athletic Association (USCAA). These organizations have their own divisions, rules, scholarships, and championships, which are usually smaller and less well-known than those of the NCAA.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Top Programs and Prestigious Teams for Soccer
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Within each division and conference, there are certain programs and teams that stand out due to their history, performance, reputation, facilities, and more. These programs and teams often attract the best players, offer the best scholarships, have the best coaches and staff, possess top-notch training and game facilities, have the largest fan base and media attention, and frequently produce players for the professional circuit.
      <br />
      <br />
      It's difficult to compile a definitive list of the best programs and teams in college soccer in America because many factors come into play, and rankings can change every year. However, to give you an idea, we will mention some examples of programs and teams that are often considered at the top of college soccer in America.
      <br />
      <br />
      For men, one of the most prestigious programs is that of the University of Maryland, which has won the national championship 4 times (1968, 2005, 2008, and 2018) and has produced more than 20 players who have played in the MLS, such as Omar Gonzalez, Graham Zusi, and Zack Steffen. Other leading programs for men include those of the University of Virginia, Indiana University, Stanford University, and Wake Forest University.
      <br />
      <br />
      For women, one of the most dominant programs is that of the University of North Carolina, which has won the national championship over 20 times and has produced more than 40 players who have played for the national team, such as Mia Hamm, Kristine Lilly, and Tobin Heath. Other leading programs for women include those of the University of Notre Dame, the University of Portland, Stanford University, and Florida State University.
      <br />
      <br />
      These are just a few examples of the many excellent programs and teams in college soccer in America. If you want to learn more about different programs and teams, you can search online for rankings, statistics, reviews, and player and coach testimonials. You can also contact Sportbeursamerika.nl, which can help you find the best program and team that suits you.
    </MKTypography>
  </MKBox>
</MKBox>
        






                                  







<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 3: From College to the Pros
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Many young soccer players dream of one day playing professionally and emulating their idols at the highest level. But how can you make that dream come true? What are the steps you need to take to go from college to the pros? And what are the opportunities and challenges you'll encounter along the way? In this chapter, we will answer these questions and show you how college soccer can serve as a stepping stone to a professional career.
  </MKTypography>
  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 The Path to Professionalism
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      One of the most significant benefits of college soccer is that it offers you the opportunity to develop both athletically and academically while enjoying student life. By playing soccer and studying in America, you can not only improve your soccer skills but also expand your knowledge, broaden your network, shape your personality, and enhance your future prospects.
      <br />
      <br />
      College soccer can also be a stepping stone to a professional career if you desire it. There are various ways to transition from college soccer to the professional circuit, both in America and abroad. The most common methods include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          MLS SuperDraft: This is an annual event where MLS teams take turns selecting players from a pool of available players, most of whom come from college soccer. The MLS SuperDraft consists of four rounds, with each team making one pick per round. The order of selections is determined by various factors such as team performance in the previous season, lottery, and potential trades. The MLS SuperDraft is one of the primary ways for college soccer players to enter the MLS and offers the opportunity to secure a contract and scholarship.
        </li>
        <li>
          MLS Homegrown Player Rule: This rule allows MLS teams to sign players from their own youth academies without using a draft pick. The rule aims to encourage and reward the development of local talents. Many MLS teams have partnerships with college programs, allowing them to track and mentor players who play for both their college and youth teams. The MLS Homegrown Player Rule is another way for college soccer players to enter the MLS and offers the opportunity to secure a contract and scholarship.
        </li>
        <li>
          Generation Adidas Program: Sponsored by Adidas, this program is designed to assist young players in transitioning from college soccer to professional soccer. The program selects promising players, typically under the age of 20, who have not yet graduated from their university. These players are offered contracts by the MLS, allowing them to participate in the MLS SuperDraft without losing their college eligibility. This means they can still return to their university to complete their studies if they wish. The program also provides educational scholarships to players to cover their study costs.
        </li>
        <li>
          Overseas Opportunities: These are opportunities for college soccer players to play abroad, for example, in Europe, Asia, or South America. These opportunities typically depend on various factors such as the player's level, nationality, age, and network. Some players may, for instance, leverage dual nationality or family background to qualify for a foreign passport, making it easier for them to play in another country. Other players may benefit from their connections with scouts, agents, or coaches who can assist them in securing a contract with a foreign club. Overseas opportunities are another way for college soccer players to pursue a professional career, but they also come with more risks and uncertainties.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      These are just some of the ways you can go from college to the pros, but there are more. The key is to be aware of the opportunities available and to prepare yourself for the challenges you may encounter. It's also important to be realistic about your chances and expectations and not put all your eggs in one basket. College soccer is a fantastic opportunity to develop as a player and as a person. Therefore, it's wise to keep other options open and not only focus on soccer but also on your studies, hobbies, friends, and family.
      <br />
      <br />
      In the next chapter, we will explore the influence of international soccer on college soccer and how it impacts and inspires college soccer players.
    </MKTypography>
  </MKBox>
</MKBox>
                






                                  








<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 4: The Influence of International Soccer
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Soccer is a global sport that connects millions of people all over the world. It's not surprising that international soccer has a significant influence on college soccer in America. In this chapter, we will explore how foreign influences impact and inspire college soccer and how American players find their way to foreign lands.
  </MKTypography>
  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Foreign Influences
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="The prestigious NCAA soccer trophy."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          The coveted NCAA trophy
        </figcaption>
      </figure>
      The popularity of international soccer in the United States has grown significantly in recent years. According to a 2023 Gallup poll, soccer is the third most popular sport among Americans, following American football and basketball. This is partly due to the increasing diversity of the American population, the accessibility of international matches through TV and the internet, and the success of the U.S. national team on the world stage.
      <br />
      <br />
      This interest in international soccer has also had an impact on college soccer. Many college soccer players look up to their favorite foreign players and teams, trying to emulate their playing style and technique. Some college soccer players even have foreign backgrounds or dual nationality, creating a special connection to a particular country or region.
      <br />
      <br />
      One example is Gio Reyna, the son of former U.S. international Claudio Reyna. Gio Reyna was born in England, where his father played for Sunderland and Manchester City. He grew up with a love for English soccer and played for the youth teams of New York City FC. In 2023, he made the move to Borussia Dortmund in Germany, where he quickly became one of Europe's top talents. He also made his debut for the U.S. national team, forming a new generation alongside other young stars like Christian Pulisic and Weston McKennie.
      <br />
      <br />
      Another example is Catarina Macario, a Brazilian forward who moved to the U.S. at the age of twelve. She played for Stanford University, where she won the Hermann Trophy twice as the country's top college soccer player. She also gained U.S. citizenship, allowing her to represent the U.S. women's national team. In 2023, she signed a contract with Olympique Lyon in France, one of the world's premier women's teams.
      <br />
      <br />
      These examples illustrate how foreign influences enrich and diversify college soccer and how college soccer players can achieve their dreams in international soccer.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Americans Abroad
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Not only do foreign influences impact college soccer, but American players who seek their fortunes abroad also play a significant role. An increasing number of American players choose to move to Europe or other continents after their college careers to compete against the world's best players.
      <br />
      <br />
      One of the most successful examples is Clint Dempsey, who played for Furman University before joining the MLS. In 2023, he made the move to Fulham in England, where he emerged as a prolific goal-scorer. He scored more than 50 goals for the club, including a historic goal against Juventus in the Europa League. He also played for Tottenham Hotspur and Seattle Sounders and was a key player for the U.S. national team.
      <br />
      <br />
      Another success story is Tobin Heath, who played for North Carolina University, where she won two national championships. She also played for various clubs in the U.S., France, and England, including Paris Saint-Germain and Manchester United. She won four Olympic gold medals and two FIFA World Cups with the U.S. women's national team, where she is known for her technical skills and creativity.
      <br />
      <br />
      These examples demonstrate how American players can develop and excel abroad and how they can put college soccer on the map as a talent incubator.
    </MKTypography>
  </MKBox>
</MKBox>
             






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 5: Sports Scholarships and the Future of Athletes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sports scholarships offer numerous opportunities for young athletes to showcase their talent and develop their skills. One of the most significant opportunities is obtaining a sports scholarship to study and compete at the college level. In this chapter, we will discuss the importance of sports scholarships and how Sport Scholarships America can help you make your dream come true.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 The Importance of Scholarships
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A sports scholarship is financial support provided by a university to a student-athlete to combine studies and sports. It can cover the costs of tuition, books, housing, meals, and other expenses. Additionally, it may offer benefits such as academic tutoring, medical care, training facilities, and equipment.
      <br />
      <br />
      Obtaining a sports scholarship is competitive, with many factors playing a role in the awarding process, including academic achievements, athletic performance, personality, motivation, leadership, and adaptability. Therefore, it's essential to prepare well and distinguish yourself from the competition.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 How Sport Scholarships America Can Help
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo of Sport Scholarships America, featuring sports equipment and a graduation cap, representing the focus on sports scholarships for student-athletes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo of Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America is an organization specialized in guiding young sports talents to sports scholarships. With passionate and experienced experts, Sport Scholarships America supports student-athletes at every step of the journey, from initial orientation to final placement. Their services include:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>A personal intake interview to discuss the student-athlete's wishes, possibilities, and expectations.</li>
        <li>A professional evaluation of the student-athlete's athletic and academic level.</li>
        <li>An extensive network of contacts with coaches, universities, and colleges.</li>
        <li>Effective promotion of the student-athlete's profile and videos to potential coaches.</li>
        <li>Expert advice on the best options based on the student-athlete's preferences, goals, and budget.</li>
        <li>Negotiation of the best scholarship offers on behalf of the student-athlete.</li>
        <li>Assistance in applying for necessary documents, such as visa, transcript, diploma, and admission tests.</li>
        <li>Preparation for student-athletes' life during their scholarship, including culture, language, rules, and tips.</li>
        <li>Aftercare and follow-up during the scholarship period.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Want to explore scholarship opportunities and achieve your athletic and academic goals? <Link to="/Services-Products">View the full range</Link> of services offered by Sport Scholarships America and schedule a consultation today.
    </MKTypography>
  </MKBox>
</MKBox>


         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;