/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import React, { useState } from 'react'; // Step 1: Import useState
import { Link } from 'react-router-dom';


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Football1.jpg";
import Image2 from "assets/images/Sports_Images/Football2.webp";
import Image3 from "assets/images/Sports_Images/Football3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";
import "../../../../H1asH3.css"


// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Bourses d\'études de football américain aux États-Unis : Comment sécuriser une place dans une équipe universitaire', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Chapitre 1: Histoire et développement du football américain', isSubheader: false },
    { id: '1.1', title: '1.1 Les Premières Années', isSubheader: true },
    { id: '1.2', title: '1.2 Croissance et Professionnalisation', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Chapitre 2: Football Universitaire', isSubheader: false },
    { id: '2.1', title: '2.1 Ligues Majeures et Conférences', isSubheader: true },
    { id: '2.2', title: '2.2 Développement des Joueurs', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Chapitre 3: Du Collège à la NFL', isSubheader: false },
    { id: '3.1', title: '3.1 Le Chemin vers la NFL', isSubheader: true },
    { id: '3.2', title: '3.2 Autres Opportunités Professionnelles', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Chapitre 4: L\'Influence du Football Américain sur la Culture Américaine', isSubheader: false },
    { id: '4.1', title: '4.1 Impact Culturel', isSubheader: true },
    { id: '4.2', title: '4.2 Modèles et Inspiration', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Chapitre 5 : Bourses Sportives et l\'Avenir des Athlètes', isSubheader: false },
    { id: '5.1', title: '5.1 L\'Importance des Bourses', isSubheader: true },
    { id: '5.2', title: '5.2 Comment Sport Scholarships America Peut Aider', isSubheader: true },
  ];
  
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Sommaire
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Cacher</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Afficher</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>


























          <MKBox id="intro" mb={3}>
    <MKTypography variant="h1" className="h1-as-h3" mb={2}>
        Bourses d'études de football américain aux États-Unis : Comment sécuriser une place dans une équipe universitaire
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
            <img
                src={Image1}
                alt="Vue aérienne d'un match de football universitaire bondé à l'Université de l'Indiana, avec des joueurs en action sur le terrain et des supporters dans les gradins."
                style={{
                    maxWidth: "100%",
                    border: "2px solid #fff",
                    boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                    cursor: "pointer",
                    outline: "none",
                }}
                onClick={() => openModal1(Image1)}
            />
            <figcaption style={{ 
                textAlign: 'left', 
                fontWeight: 'bold', 
                fontSize: '12px', 
                wordWrap: 'break-word', 
            }}>
                Match de football de l'Université de l'Indiana vu des tribunes.
            </figcaption>
        </figure>
        Le football américain est un sport qui a vu le jour aux États-Unis à la fin du XIXe siècle. C'est un sport d'équipe dans lequel deux équipes de onze joueurs tentent d'avancer un ballon ovale au-delà de la ligne de but adverse ou de le botter entre les poteaux. Le jeu est caractérisé par le contact physique, les tactiques, la stratégie et le spectacle.
        <br/>
        <br/>
        Le football américain n'est pas seulement un sport ; c'est aussi une culture. C'est une partie essentielle de l'identité, des valeurs et des traditions américaines. C'est une source de fierté, de passion et d'inspiration pour des millions de personnes. C'est aussi une industrie qui génère des milliards de dollars et crée des milliers d'emplois.
        <br/>
        <br/>
        Mais avant de pouvoir devenir joueur professionnel dans la NFL, vous devez montrer votre talent au niveau universitaire. Le football universitaire est une partie cruciale du développement des joueurs, offrant aux jeunes athlètes l'opportunité d'améliorer leurs compétences, de recevoir une éducation et de construire un réseau.
        <br/>
        <br/>
        Cependant, le football universitaire n'est pas facilement accessible à tous. Il y a des milliers d'équipes et de joueurs qui se disputent un nombre limité de places et de bourses. De plus, il y a de nombreuses règles et exigences que vous devez remplir pour être éligible dans une équipe universitaire.
        <br/>
        <br/>
        C'est là que Sportbeursamerika.nl peut vous aider. Je suis spécialisé dans la mise en relation des jeunes talents avec les équipes universitaires à la recherche de renforts. Je peux vous aider à trouver une bourse sportive, à organiser votre visa, à vous préparer pour vos examens d'admission et à négocier avec les entraîneurs. Avec mon assistance, vous pouvez réaliser votre rêve d'étudier et de jouer en Amérique.
        <br/>
        <br/>
        Vous voulez savoir comment ? Continuez à lire !
    </MKTypography>
</MKBox>

{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1: The History and Development of American Football" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Chapitre 1: Histoire et développement du football américain
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Le football américain est un sport qui a vu le jour aux États-Unis à la fin du XIXe siècle. Il est issu du rugby et du soccer, deux sports populaires en Europe. Dans ce chapitre, nous vous en dirons plus sur les premières années et la croissance et la professionnalisation du football américain.
    </MKTypography>

    <MKBox id="1.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            1.1 Les Premières Années
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            L'origine du football américain est souvent attribuée à un match entre deux équipes universitaires en 1869 : Rutgers et Princeton. Cependant, ce match ressemblait plus à une forme de soccer qu'au football américain moderne. Il y avait 25 joueurs par équipe, le ballon ne pouvait pas être touché avec les mains et il n'y avait pas de poteaux de but.
            <br/>
            <br/>
            Le premier match qui ressemblait au football américain moderne a eu lieu en 1874 entre Harvard et McGill. Ce match était une combinaison de rugby et de soccer, permettant au ballon d'être porté avec les mains et présentant des poteaux de but. Harvard a remporté le match 3-0.
            <br/>
            <br/>
            Le rôle des universités dans la formation des premières équipes et ligues était crucial. Ils étaient les pionniers qui ont défini les règles et le style du jeu. En 1873, la première association de football intercollégiale a été créée, composée de Yale, Columbia, Princeton et Harvard. En 1880, Walter Camp, joueur et entraîneur de Yale, a été considéré comme le "Père du football américain" car il a introduit des innovations importantes, telles que la réduction du nombre de joueurs à 11, l'introduction de la ligne de scrimmage et le système de downs.
        </MKTypography>
    </MKBox>
    <MKBox id="1.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            1.2 Croissance et Professionnalisation
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            L'évolution du football américain en un phénomène national a commencé à la fin du XIXe et au début du XXe siècle. Le jeu a gagné en popularité auprès des étudiants, des spectateurs et des médias. Il est également devenu de plus en plus violent et dangereux, entraînant de nombreuses blessures et même des décès. En 1905, le président Theodore Roosevelt a menacé d'interdire le jeu si des réformes n'étaient pas mises en œuvre. Cela a conduit à la création de la National Collegiate Athletic Association (NCAA) en 1906, une organisation responsable de la réglementation et de l'organisation des sports universitaires.
            <br/>
            <br/>
            L'influence du football américain professionnel sur le jeu universitaire a également été significative. La première ligue professionnelle de football américain a été fondée en 1892 mais est devenue véritablement réussie en 1920 avec la formation de l'American Professional Football Association (APFA), plus tard rebaptisée National Football League (NFL). La NFL a attiré de nombreux joueurs talentueux des collèges, les obligeant à élever leur niveau pour rester compétitifs. La NFL est également devenue une source majeure de revenus et d'exposition pour les universités, louant souvent leurs stades aux équipes professionnelles.
            <br/>
            <br/>
            Dans ce chapitre, nous vous avons parlé de l'histoire et du développement du football américain. Dans le prochain chapitre, nous vous fournirons d'autres informations sur le football américain au niveau universitaire.
        </MKTypography>
    </MKBox>
</MKBox>







                                              





                                  








<MKBox id="Chapter 2" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Chapitre 2: Football Universitaire
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Le football universitaire est l'un des sports les plus populaires et compétitifs aux États-Unis. Chaque année, plus d'un millier d'équipes et des centaines de milliers de joueurs concourent pour le titre national, les championnats régionaux et les prestigieux bowl games. Dans ce chapitre, nous vous en dirons plus sur les principales ligues et conférences du football universitaire, ainsi que sur le développement des joueurs au niveau universitaire.
    </MKTypography>

    <MKBox id="2.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.1 Ligues Majeures et Conférences
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>

            Un aperçu des divisions de la NCAA et des principales conférences du football universitaire. La NCAA est l'organisation chargée de réglementer et d'organiser les sports universitaires aux États-Unis. La NCAA divise les équipes en trois divisions : Division I, Division II et Division III. La Division I est la plus haute division et se compose de deux subdivisions : la Football Bowl Subdivision (FBS) et la Football Championship Subdivision (FCS). Les équipes FBS sont les plus prestigieuses et lucratives, concourant pour une place dans le College Football Playoff, un système qui détermine le champion national depuis 2014. Les équipes FCS sont moins connues et riches mais disposent de leur propre système de playoff pour déterminer leur champion.
            <br/>
            <br/>
            Au sein de chaque division et subdivision, il existe différentes conférences, qui regroupent des équipes ayant des liens géographiques ou historiques. Chaque conférence a son propre calendrier, ses propres règles et son propre championnat. Les principales conférences en FBS sont : Atlantic Coast Conference (ACC), Big Ten Conference, Big 12 Conference, Pac-12 Conference, Southeastern Conference (SEC) et American Athletic Conference (AAC). Les principales conférences en FCS sont : Big Sky Conference, Colonial Athletic Association (CAA), Missouri Valley Football Conference (MVFC) et Southern Conference (SoCon).
            <br/>
            <br/>
            Les programmes universitaires les plus prestigieux et leur impact sur le sport. Certaines universités ont une histoire longue et réussie dans le football universitaire, remontant aux premières années du sport. Elles ont remporté de nombreux titres nationaux, des bowl games, des récompenses individuelles et formé des joueurs professionnels. Elles ont également de nombreux fans, une attention médiatique et des ressources financières. Quelques exemples de ces universités sont : Alabama, Notre Dame, Ohio State, Oklahoma, USC, Michigan, Texas, Clemson, Florida State et LSU.
        </MKTypography>
    </MKBox>
    <MKBox id="2.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.2 Développement des Joueurs
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image2}
                    alt="Jeune Tom Brady en action en tant que quarterback pour l'Université du Michigan lors d'un match de football universitaire, vêtu de l'uniforme bleu et jaune de l'équipe."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal2(Image2)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Tom Brady en action pour l'Université du Michigan.
                </figcaption>
            </figure>
            Comment les équipes universitaires contribuent au développement technique et physique des joueurs. Les équipes universitaires offrent aux jeunes joueurs l'opportunité d'améliorer leurs compétences sous la direction d'entraîneurs et de membres du personnel expérimentés. Elles offrent également un accès à des installations de haute qualité, à un équipement et à des soins médicaux. De plus, elles exposent les joueurs à un niveau de compétition élevé, où ils affrontent d'autres joueurs talentueux venant de tout le pays.
            <br/>
            <br/>
            Exemples de joueurs célèbres ayant perfectionné leurs compétences au niveau universitaire. De nombreux joueurs professionnels ont commencé leur carrière au niveau universitaire, où ils se sont démarqués par leurs performances et leur potentiel. Certains joueurs ont même remporté des récompenses comme le Trophée Heisman, la récompense individuelle la plus prestigieuse du football universitaire. Parmi ces joueurs, on trouve : Tom Brady (Michigan), Peyton Manning (Tennessee), Joe Montana (Notre Dame), Barry Sanders (Oklahoma State), Jerry Rice (Mississippi Valley State) et Tim Tebow (Florida).
            <br/>
            <br/>
            Dans ce chapitre, nous vous avons parlé plus en détail du football universitaire. Dans le prochain chapitre, nous vous en dirons plus sur le chemin vers la NFL et les autres opportunités professionnelles.

        </MKTypography>
    </MKBox>
</MKBox>











                                              






                                  







<MKBox id="Chapter 3" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Chapitre 3: Du Collège à la NFL
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Le football universitaire est une étape vers la National Football League (NFL), la plus haute ligue professionnelle au monde. Chaque année, des centaines de joueurs sont sélectionnés par des équipes de la NFL lors de la Draft de la NFL, un événement où les équipes choisissent à tour de rôle un joueur parmi un pool de joueurs universitaires. Dans ce chapitre, nous vous en dirons plus sur le chemin vers la NFL et les autres opportunités professionnelles.
    </MKTypography>

    <MKBox id="3.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.1 Le Chemin vers la NFL
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Comment le football universitaire fonctionne comme un tremplin vers la National Football League (NFL). Le football universitaire est la principale source de talent pour la NFL. La plupart des joueurs de la NFL ont d'abord joué au niveau universitaire. Le football universitaire offre aux joueurs la possibilité de se prouver contre d'autres athlètes de haut niveau, de développer leurs compétences, d'améliorer leurs statistiques et d'augmenter leur valeur lors de la draft. Le football universitaire donne également aux joueurs une exposition et une reconnaissance auprès des recruteurs, des entraîneurs et des médias.
            <br/>
            <br/>
            Environ 250 joueurs sont choisis chaque année lors de la Draft de la NFL, qui a généralement lieu en avril. La draft se compose de sept tours, chacun avec 32 choix (un pour chaque équipe). L'ordre des choix est déterminé par le résultat de la saison précédente, la pire équipe choisissant en premier et la meilleure équipe en dernier. La draft est un événement excitant et imprévisible, certains joueurs étant choisis plus haut ou plus bas que prévu, et certains ne sont pas choisis du tout. Des exemples de joueurs célèbres sélectionnés lors de la Draft de la NFL incluent : John Elway (premier choix en 1983), Troy Aikman (premier choix en 1989), Peyton Manning (premier choix en 1998), Tom Brady (199e choix en 2000), Aaron Rodgers (24e choix en 2005) et Patrick Mahomes (10e choix en 2017).
        </MKTypography>
    </MKBox>
    <MKBox id="3.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.2 Autres Opportunités Professionnelles
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Des chemins alternatifs vers une carrière professionnelle dans le football américain en dehors de la NFL. Tous les joueurs qui jouent au niveau universitaire ne parviennent pas à atteindre la NFL. Certains joueurs ne sont pas choisis lors de la draft, ou sont choisis mais échouent à obtenir une place dans une équipe. Certains joueurs se blessent ou perdent leur motivation ou leur passion. Mais cela ne signifie pas qu'ils doivent abandonner leur rêve de jouer professionnellement. Il existe d'autres opportunités professionnelles en dehors de la NFL, telles que :
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    La Ligue canadienne de football (LCF) : Il s'agit d'une ligue professionnelle composée de neuf équipes qui jouent au Canada. La LCF présente quelques différences par rapport à la NFL, telles qu'un terrain plus grand, plus de joueurs par équipe et moins de tentatives par drive. La LCF attire de nombreux joueurs qui ne peuvent pas jouer dans la NFL, mais qui veulent quand même jouer à un niveau élevé.
                </li>
                <li>
                    La XFL : Il s'agit d'une nouvelle ligue professionnelle créée en 2020 comme alternative à la NFL. La XFL se compose de huit équipes qui jouent aux États-Unis. La XFL vise à offrir un jeu plus rapide, plus excitant et plus innovant que la NFL. La XFL offre également plus d'opportunités aux joueurs qui ont été négligés ou ignorés par la NFL.
                </li>
                <li>
                    La European League of Football (ELF) : Il s'agit d'une nouvelle ligue professionnelle créée en 2021 dans le but de promouvoir et de développer le football américain en Europe. La ELF se compose de huit équipes qui jouent en Allemagne, en Pologne, en Espagne et en France. La ELF vise à offrir une plateforme aux joueurs européens pour montrer leur talent et progresser en tant qu'athlètes.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Dans ce chapitre, nous vous avons parlé plus en détail du chemin vers la NFL et des autres opportunités professionnelles. Dans le prochain chapitre, nous vous en dirons plus sur l'influence du football américain sur la culture américaine.
        </MKTypography>
    </MKBox>
</MKBox>



                                              






                                  





<MKBox id="Chapter 4" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Chapitre 4: L'Influence du Football Américain sur la Culture Américaine
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Le football américain n'est pas seulement un sport, mais aussi une culture. Il est une partie significative de l'identité, des valeurs et des traditions américaines. Il est une source de fierté, de passion et d'inspiration pour des millions de personnes. C'est également une industrie qui génère des milliards de dollars et crée des milliers d'emplois. Dans ce chapitre, nous vous en dirons plus sur l'impact culturel du football américain, et les modèles et l'inspiration qu'il offre.
    </MKTypography>

    <MKBox id="4.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.1 Impact Culturel
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image3}
                    alt="Vue depuis les gradins du vibrant et bondé stade de football universitaire du Wisconsin lors d'un match excitant, avec des fans encourageant leur équipe."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal3(Image3)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Jour de match dans le stade bondé du Wisconsin
                </figcaption>
            </figure>
            L'importance du football américain dans la société et la culture américaines. Le football américain est l'un des sports les plus populaires et influents aux États-Unis. Il attire des millions de fans, de spectateurs et de médias chaque année. Il fait partie de la vie quotidienne, de l'éducation, de la politique, de l'économie et du divertissement de nombreux Américains. Il est également un symbole d'unité nationale, de diversité et de fierté.
            <br/>
            <br/>
            Les événements majeurs et leur impact sur l'identité nationale, tels que le Super Bowl. Le Super Bowl est le championnat annuel de la NFL, généralement organisé en février. C'est le plus grand et le plus regardé des événements sportifs au monde, avec plus de 100 millions de téléspectateurs aux États-Unis seulement. Le Super Bowl est plus qu'un événement sportif; c'est un phénomène culturel. C'est l'occasion pour la famille, les amis et les collègues de se rassembler, de faire la fête, de parier et d'encourager. C'est également une scène pour la musique, la publicité, l'art et la politique. Le Super Bowl est souvent perçu comme un reflet de la culture américaine, de ses valeurs et de ses tendances.
        </MKTypography>
    </MKBox>
    <MKBox id="4.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.2 Modèles et Inspiration
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Les joueurs de football américain sont souvent des modèles et des sources d'inspiration pour les jeunes athlètes qui poursuivent leur rêve de jouer au niveau universitaire ou professionnel. Certains joueurs ont des histoires extraordinaires d'adversité, de persévérance et de succès qui motivent et inspirent les autres. Certains joueurs ont également un impact positif sur leur communauté, leurs fans et leur pays par leurs actions, leurs paroles et leurs actes. Des exemples de tels joueurs incluent : Colin Kaepernick (qui s'est agenouillé pendant l'hymne national pour sensibiliser à l'injustice raciale), Russell Wilson (qui a mené son équipe au Super Bowl en tant que challenger), JJ Watt (qui a levé des millions de dollars pour les victimes de l'ouragan Harvey), Drew Brees (qui a aidé sa ville de la Nouvelle-Orléans à se remettre après l'ouragan Katrina) et Tom Brady (qui joue toujours à un niveau élevé à l'âge de 43 ans).
            <br/>
            <br/>
            Dans ce chapitre, nous vous avons parlé plus en détail de l'influence du football américain sur la culture américaine. Dans le prochain chapitre, nous vous en dirons plus sur les bourses sportives et l'avenir du football américain.
        </MKTypography>
    </MKBox>
</MKBox>











                                              






                                  



<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 5 : Bourses Sportives et l'Avenir des Athlètes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Les bourses sportives offrent de nombreuses opportunités aux jeunes athlètes de montrer leur talent et de développer leurs compétences. L'une des opportunités les plus significatives est d'obtenir une bourse sportive pour étudier et compétitionner au niveau collégial. Dans ce chapitre, nous discuterons de l'importance des bourses sportives et de comment Sport Scholarships America peut vous aider à réaliser votre rêve.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 L'Importance des Bourses
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Une bourse sportive est un soutien financier fourni par une université à un étudiant-athlète pour combiner études et sport. Elle peut couvrir les frais de scolarité, les livres, le logement, les repas et autres dépenses. De plus, elle peut offrir des avantages tels que du tutorat académique, des soins médicaux, des installations d'entraînement et du matériel.
      <br />
      <br />
      Obtenir une bourse sportive est compétitif, avec de nombreux facteurs qui influent sur le processus d'attribution, notamment les performances académiques, sportives, la personnalité, la motivation, le leadership et l'adaptabilité. Il est donc important de bien se préparer et de se démarquer de la concurrence.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Comment Sport Scholarships America Peut Aider
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo de Sport Scholarships America, représentant du matériel sportif et une casquette de graduation, symbolisant le focus sur les bourses sportives pour les étudiants-athlètes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America est une organisation spécialisée dans l'orientation des jeunes talents sportifs vers des bourses sportives. Avec des experts passionnés et expérimentés, Sport Scholarships America soutient les étudiants-athlètes à chaque étape du parcours, de l'orientation initiale à la placement final. Leurs services comprennent :
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Un entretien personnel pour discuter des souhaits, des possibilités et des attentes de l'étudiant-athlète.</li>
        <li>Une évaluation professionnelle du niveau académique et sportif de l'étudiant-athlète.</li>
        <li>Un vaste réseau de contacts avec des entraîneurs, des universités et des collèges.</li>
        <li>Promotion efficace du profil et des vidéos de l'étudiant-athlète auprès des entraîneurs potentiels.</li>
        <li>Conseils d'experts sur les meilleures options en fonction des préférences, des objectifs et du budget de l'étudiant-athlète.</li>
        <li>Négociation des meilleures offres de bourses au nom de l'étudiant-athlète.</li>
        <li>Aide à la demande de documents nécessaires, tels que visa, relevé de notes, diplôme et tests d'admission.</li>
        <li>Préparation à la vie d'étudiant-athlète pendant sa bourse, y compris culture, langue, règles et conseils.</li>
        <li>Suivi et accompagnement pendant la période de la bourse.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vous voulez explorer les opportunités de bourses et atteindre vos objectifs sportifs et académiques ? <Link to="/fr/Services-Produits">Consultez la gamme complète</Link> des services offerts par Sport Scholarships America et planifiez une consultation dès aujourd'hui.
    </MKTypography>
  </MKBox>
</MKBox>



          
        









         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;