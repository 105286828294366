import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";

// @mui componentes de material
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Imágenes
import bgImage from "assets/images/Achtergronden/Home_Small.png";

function ContactanosDos() {
  const form = useRef();
  const [success, setSuccess] = useState(null);

  const sendEmail = (e) => {
    e.preventDefault();
  
    emailjs
      .sendForm("service_sj82src", "template_2ikdfqp", form.current, "xOBnOFlYt964jvT3q")
      .then(
        (result) => {
          console.log("¡Correo enviado exitosamente!", result.text);
          setSuccess(true);
          form.current.reset(); // Restablecer los campos del formulario
  
          // Establecer un temporizador para ocultar el mensaje de éxito después de 5 segundos (5000 milisegundos)
          setTimeout(() => setSuccess(null), 50000);
        },
        (error) => {
          console.log("Error al enviar el correo. Error: ", error.text);
          setSuccess(false);
  
          // Establecer un temporizador para ocultar el mensaje de error después de 5 segundos (5000 milisegundos)
          setTimeout(() => setSuccess(null), 50000);
        }
      );
  };

  return (
    <MKBox component="section" py={{ xs: 4, lg: 6 }}>
      <Container>
      <Grid container item px={{ xs: 1, sm: 3, md: 6 }}> {/* Ajuste del relleno */}
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            sx={{ overflow: "hidden" }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} lg={7}>
                <MKBox component="form" p={2} method="post" ref={form} onSubmit={sendEmail}>
                  <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                    <MKTypography variant="h2" mb={1}>
                      ¡Hola!
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mb={2}>
                      ¡Me encantaría saber de ti! Por favor, completa el siguiente formulario para preguntas, comentarios o posibilidades de colaboración.                    </MKTypography>
                  </MKBox>
                  <MKBox pt={0.5} pb={3} px={3}>
                    <Grid container>
                      <Grid item xs={12} pr={1} mb={3}>
                        <MKInput
                          variant="standard"
                          label="Mi nombre es"
                          placeholder="Nombre Completo"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          name="from_name"
                          required
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={3}>
                        <MKInput
                          variant="standard"
                          label="Correo electrónico"
                          placeholder="ejemplo@ejemplo.com"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          name="from_email"
                          required
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={3}>
                        <MKInput
                          variant="standard"
                          label="Tu mensaje"
                          placeholder="Tu mensaje aquí..."
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          multiline
                          rows={6}
                          name="message"
                          required
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={6}
                      justifyContent="flex-end"
                      textAlign="right"
                      ml="auto"
                    >
                      <MKButton type="submit" variant="gradient" color="info">
                        Enviar Mensaje
                      </MKButton>
                    </Grid>
                  </MKBox>
                </MKBox>
                {success !== null && (
                  <MKBox mt={3} p={2} textAlign="center">
                    {success ? (
                      <MKTypography variant="body2" color="success">
                        ¡Mensaje enviado con éxito!
                      </MKTypography>
                    ) : (
                      <MKTypography variant="body2" color="error">
                        Hubo un error. Por favor, inténtalo de nuevo más tarde.                      </MKTypography>
                    )}
                  </MKBox>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                position="relative"
                px={0}
                sx={{
                  backgroundImage: ({
                    palette: { gradients },
                    functions: { rgba, linearGradient },
                  }) =>
                    `${linearGradient(rgba(gradients.info.main, 0.8), rgba(gradients.info.state, 0.8))}, url(${bgImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                >
                  <MKBox py={6} pl={6} pr={{ xs: 6, sm: 12 }} my="auto">
                    <MKTypography variant="h1" className="h1-as-h3" color="white" mb={1}>
                      Información de contacto
                    </MKTypography>
                    <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                      Completa el formulario y me pondré en contacto contigo lo antes posible."                    </MKTypography>
                    <MKBox display="flex" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-phone" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        (+31) 6 26007445
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-envelope" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        info@sportscholarshipsamerica.com
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-map-marker-alt" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        Ámsterdam
                      </MKTypography>
                    </MKBox>
                    <MKBox mt={3}>
                      <MKButton variant="text" color="white" size="large" iconOnly>
                        <i className="fab fa-facebook" style={{ fontSize: "1.25rem" }} />
                      </MKButton>
                      <MKButton variant="text" color="white" size="large" iconOnly>
                        <i className="fab fa-twitter" style={{ fontSize: "1.25rem" }} />
                      </MKButton>
                      <MKButton variant="text" color="white" size="large" iconOnly>
                        <i className="fab fa-instagram" style={{ fontSize: "1.25rem" }} />
                      </MKButton>
                    </MKBox>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ContactanosDos;
