/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Golf1.jpeg";
import Image2 from "assets/images/Sports_Images/Golf2.webp";
import Image3 from "assets/images/Sports_Images/Golf3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"


// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Borsa di Studio per il Golf: Come Studiare e Giocare a Golf in America', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capitolo 1: Storia e Sviluppo del Golf Universitario', isSubheader: false },
    { id: '1.1', title: '1.1 Anni Iniziali', isSubheader: true },
    { id: '1.2', title: '1.2 Crescita e Professionalizzazione', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capitolo 2: Lo Scenario Attuale del Golf Universitario', isSubheader: false },
    { id: '2.1', title: '2.1 Principali Competizioni e Conferenze', isSubheader: true },
    { id: '2.2', title: '2.2 Programmi di Punta e Squadre Prestigiose', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capitolo 3: Dal College al Professionismo', isSubheader: false },
    { id: '3.1', title: '3.1 Il Percorso verso il Circuito Professionistico', isSubheader: true },
    { id: '3.2', title: '3.2 Vie Alternative verso il Professionismo', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capitolo 4: Influenze e Opportunità Internazionali', isSubheader: false },
    { id: '4.1', title: '4.1 Giocatori Stranieri nel Golf Universitario', isSubheader: true },
    { id: '4.2', title: '4.2 Giocatori Americani all\'Estero', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capitolo 5: Borse di Studio Sportive e il Futuro degli Atleti', isSubheader: false },
    { id: '5.1', title: '5.1 L\'Importanza delle Borse di Studio', isSubheader: true },
    { id: '5.2', title: '5.2 Come Sport Scholarships America Può Aiutare', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };





  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indice
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Nascondi</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostra</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Borsa di Studio per il Golf: Come Studiare e Giocare a Golf in America
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Campo da golf durante il campionato NCAA, con giocatori e spettatori lungo il percorso"
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Il Campionato NCAA di Golf
      </figcaption>
    </figure>
    Il golf è uno degli sport più popolari a livello universitario negli Stati Uniti. Ogni anno, migliaia di studenti-atleti competono per titoli nazionali, premi prestigiosi e un posto nel golf professionistico. Il golf universitario offre non solo la possibilità di migliorare le tue abilità atletiche, ma anche di ottenere un diploma accademico, costruire una rete di contatti e vivere un'esperienza indimenticabile.
    <br/>
    <br/>
    Tuttavia, il golf universitario non è per tutti. Richiede un alto livello di impegno, disciplina e talento. Inoltre, non è facile essere ammessi a un'università con un buon programma di golf. La concorrenza è feroce e i costi sono elevati.
    <br/>
    <br/>
    Fortunatamente, ci sono opportunità per realizzare il tuo sogno con l'aiuto di una borsa di studio per il golf. Una borsa di studio per il golf è il sostegno finanziario che ricevi da un'università per studiare e giocare a golf lì. Con una borsa di studio per il golf, puoi ridurre o addirittura coprire completamente i costi di studio mentre godi di tutti i vantaggi del golf universitario.
    <br/>
    <br/>
    Ma come si ottiene una borsa di studio per il golf? E cosa dovresti considerare nella scelta di un'università? È qui che Sportbeursamerika.nl può aiutarti. Sportbeursamerika.nl è lo specialista nella guida degli studenti verso borse di studio sportive in America. Ho esperienza e conoscenza del sistema educativo e sportivo americano e ho aiutato molti studenti-atleti a realizzare il loro sogno.
    <br/>
    <br/>
    In questo articolo, forniamo ulteriori informazioni sul golf universitario e mostriamo come Sportbeursamerika.nl può supportarti nel trovare la borsa di studio perfetta per te.
    <br/>
    <br/>
    Vuoi saperne di più? Continua a leggere!
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Hoofdstuk 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 1: Storia e Sviluppo del Golf Universitario
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Il golf universitario ha una lunga e ricca storia che risale ai primi del XX secolo. In questo capitolo, esamineremo gli anni precedenti e la crescita e professionalizzazione del golf universitario negli Stati Uniti.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Anni Iniziali
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Le prime competizioni di golf presso università e college negli Stati Uniti risalgono agli anni '90 del 1890. Il primo incontro intercollegiale ufficiale di golf fu tra Harvard e Yale nel 1897. Nel 1901 fu fondata l'Associazione Intercollegiale di Golf (IGA), che organizzò il primo campionato nazionale nel 1902. Yale vinse il primo titolo e continuò a dominare fino agli anni '20.
      <br/>
      <br/>
      I primi programmi di golf presso le università erano principalmente focalizzati sugli aspetti sociali e ricreativi dello sport. Gli studenti giocavano principalmente su campi locali e avevano poche indicazioni o strutture. Le squadre di golf erano solitamente composte da quattro o cinque giocatori che si qualificavano attraverso tornei interni.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crescita e Professionalizzazione
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Negli anni '30, il golf universitario iniziò a crescere e a professionalizzarsi significativamente. In questo periodo vi fu un aumento del numero di programmi universitari di golf e un miglioramento della qualità della formazione e delle strutture. La professionalizzazione fu ulteriormente stimolata dall'attenzione sempre maggiore per lo sport e dal crescente riconoscimento del potenziale del golf a livello universitario. Durante questo periodo, la National Collegiate Athletic Association (NCAA) assunse un ruolo di primo piano nell'organizzazione e nella regolamentazione del golf universitario, contribuendo ulteriormente allo sviluppo e alla popolarità dello sport negli Stati Uniti.
      <br/>
      <br/>
      La NCAA assunse l'organizzazione del campionato nazionale dall'IGA e introdusse nuove regole e formati. La NCAA divise le università in diverse divisioni, in base alle loro dimensioni e al livello atletico. La NCAA organizzò anche campionati regionali per facilitare la qualificazione al campionato nazionale.
      <br/>
      <br/>
      Le università iniziarono anche a investire di più nei loro programmi di golf, assumendo allenatori professionisti, costruendo o migliorando campi da golf e offrendo borse di studio a giocatori talentuosi. Le squadre di golf divennero più grandi e più forti, e la competizione divenne più intensa.
      <br/>
      <br/>
      Negli anni '40 e '50 furono aggiunti anche altri tornei importanti, come la Walker Cup, la Palmer Cup e la Eisenhower Trophy. Questi tornei diedero ai golfisti universitari la possibilità di competere con giocatori internazionali e migliorare le proprie abilità.
      <br/>
      <br/>
      In questo capitolo, abbiamo visto come il golf universitario sia nato e cresciuto negli Stati Uniti. Nel prossimo capitolo, esamineremo il panorama attuale del golf universitario e quali competizioni e programmi sono disponibili.
    </MKTypography>
  </MKBox>
</MKBox>















                                              





                                  








<MKBox id="Chapter 2" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Capitolo 2: Lo Scenario Attuale del Golf Universitario
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Oggi, il golf universitario è uno degli sport più competitivi e prestigiosi a livello universitario negli Stati Uniti. In questo capitolo, esamineremo le principali competizioni e conferenze, così come i programmi di punta e le squadre prestigiose nel golf universitario.
    </MKTypography>

    <MKBox id="2.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.1 Principali Competizioni e Conferenze
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image2}
                    alt="Tiger Woods che rappresenta Stanford nel golf universitario, mostrato nella concentrazione durante la partita."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal2(Image2)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Tiger Woods in azione per Stanford.
                </figcaption>
            </figure>
            La NCAA è l'organizzazione più grande e importante per il golf universitario negli Stati Uniti. La NCAA divide le università in tre divisioni: Division I, Division II e Division III. Ogni divisione ha il suo campionato, tenuto annualmente a maggio o giugno. I campionati consistono in un torneo a squadre e un torneo individuale, in cui i migliori giocatori e squadre di ogni divisione competono per il titolo nazionale.
            <br/>
            <br/>
            La NCAA ha anche diverse conferenze regionali, composte da università che sono geograficamente o storicamente collegate. Ogni conferenza ha il suo campionato, che funge da torneo di qualificazione per il campionato nazionale. Alcune conferenze sono più forti di altre e hanno maggiore influenza sul panorama del golf universitario.
            <br/>
            <br/>
            Alcune delle conferenze più conosciute includono:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    La Atlantic Coast Conference (ACC), composta da 15 università sulla costa orientale, come Duke, North Carolina e Clemson.
                </li>
                <li>
                    La Big Ten Conference, composta da 14 università nel Midwest, come Michigan, Ohio State e Illinois.
                </li>
                <li>
                    La Pac-12 Conference, composta da 12 università sulla costa occidentale, come Stanford, California e Arizona State.
                </li>
                <li>
                    La Southeastern Conference (SEC), composta da 14 università nel sudest, come Alabama, Georgia e Florida.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Oltre alla NCAA, ci sono anche altre organizzazioni che organizzano competizioni di golf universitario, come la National Association of Intercollegiate Athletics (NAIA), la National Junior College Athletic Association (NJCAA) e la National Christian College Athletic Association (NCCAA). Queste organizzazioni hanno le loro divisioni, conferenze e campionati, che sono generalmente più piccoli e meno prestigiosi rispetto a quelli della NCAA.
        </MKTypography>
    </MKBox>
    <MKBox id="2.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.2 Programmi di Punta e Squadre Prestigiose
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Il golf universitario non è solo uno sport ma anche un'educazione. Le università che offrono il golf universitario hanno vari programmi, che variano in qualità, reputazione e strutture. Alcuni programmi sono leader e prestigiosi, attirando i migliori giocatori e allenatori. Altri programmi sono meno conosciuti o di successo ma offrono comunque buone opportunità per gli studenti-atleti.
            <br/>
            <br/>
            Alcuni dei fattori che determinano un programma includono:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    La storia e la tradizione del programma.
                </li>
                <li>
                    Il livello e i risultati della squadra.
                </li>
                <li>
                    La qualità e l'esperienza dell'allenatore.
                </li>
                <li>
                    Le strutture e l'attrezzatura del programma.
                </li>
                <li>
                    Gli standard accademici e i requisiti del programma.
                </li>
                <li>
                    L'atmosfera e la cultura del programma.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Alcuni dei programmi di golf universitari più importanti includono:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Stanford University: Stanford ha uno dei programmi di golf più antichi e di successo negli Stati Uniti, con più titoli nazionali nel golf maschile. Il programma ha prodotto famosi ex alunni come Tiger Woods e Tom Watson. Anche la squadra femminile di golf di Stanford ha ottenuto riconoscimento nazionale con diversi titoli NCAA e campioni individuali.
                </li>
                <li>
                    Oklahoma State University: Oklahoma State ha un programma di golf dominante, specialmente noto per la sua squadra maschile, con diversi titoli nazionali. Il programma ha prodotto golfisti di alto livello come Rickie Fowler e Viktor Hovland.
                </li>
                <li>
                    Duke University: Duke è nota per il suo forte programma di golf femminile, con diversi titoli NCAA. La squadra maschile di golf di Duke ha ottenuto buoni risultati nei campionati di conferenza e ha una storia rispettabile nelle competizioni NCAA. Duke ha prodotto golfisti talentuosi, tra cui Amanda Blumenherst e Brittany Lang.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            In questo capitolo, abbiamo visto com'è lo scenario attuale del golf universitario e quali competizioni e programmi sono disponibili. Nel prossimo capitolo, esamineremo come passare dal college al professionismo e quali percorsi alternativi sono disponibili.
        </MKTypography>
    </MKBox>
</MKBox>













                                              






                                  







<MKBox id="Chapter 3" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Capitolo 3: Dal College al Professionismo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Il golf universitario non è solo uno sport, ma anche un percorso di carriera. Molti giocatori di golf universitari aspirano a trasferirsi nel circuito professionistico e giocare ai più alti livelli del golf. In questo capitolo, esploreremo come il golf universitario possa servire da trampolino di lancio per il golf professionistico e le vie alternative disponibili.
    </MKTypography>

    <MKBox id="3.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.1 Il Percorso verso il Circuito Professionistico
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Il golf universitario è un'eccellente preparazione per una carriera professionale nel golf. I giocatori di golf universitari hanno l'opportunità di svilupparsi dal punto di vista atletico, accademico e personale. Imparano a gestire la pressione, la competizione e le aspettative. Giocano su campi impegnativi contro avversari forti e in varie condizioni. Hanno anche accesso a strutture di alta qualità, allenatori e orientamento.
            <br/>
            <br/>
            I giocatori di golf universitari hanno anche un vantaggio rispetto ad altri giocatori che non frequentano l'università. Hanno un diploma che può aiutarli a trovare un lavoro o uno sponsor se non riescono come professionisti. Hanno anche una rete di contatti e amici che possono sostenerli o consigliarli nella loro carriera.
            <br/>
            <br/>
            Molti giocatori di golf universitari si trasferiscono al circuito professionistico dopo aver completato gli studi. Alcuni lo fanno prima se ritengono di essere pronti. Per diventare professionisti, devono qualificarsi per un tour, come il PGA Tour, il LPGA Tour o il European Tour, attraverso tornei di qualificazione come il Q-School o il Korn Ferry Tour.
            <br/>
            <br/>
            Molti ex giocatori di golf universitari hanno avuto successo nel circuito professionistico. Esempi includono:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Phil Mickelson: Mickelson ha giocato a golf universitario per l'Arizona State University, dove ha vinto il campionato NCAA tre volte. È diventato professionista nel 1992 e da allora ha ottenuto numerosi successi nel PGA Tour, compresi vari major.
                </li>
                <li>
                    Annika Sörenstam: Sörenstam ha giocato a golf universitario per l'Università dell'Arizona, dove ha vinto il campionato NCAA nel 1991. È diventata professionista nel 1992 e da allora ha ottenuto numerosi successi professionali, compresi vari major.
                </li>
                <li>
                    Jon Rahm: Rahm ha giocato a golf universitario per l'Arizona State University, dove ha vinto per due volte il Ben Hogan Award come miglior golfista universitario. È diventato professionista nel 2016 e da allora ha ottenuto numerosi successi professionali, compresi major.
                </li>
            </ul>
        </MKTypography>
    </MKBox>
    <MKBox id="3.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.2 Vie Alternative verso il Professionismo
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Sebbene il golf universitario sia una via comune per il circuito professionistico, non è l'unica. Ci sono altri percorsi che i giocatori possono seguire per realizzare il loro sogno. Alcuni giocatori scelgono di non andare all'università ma di diventare professionisti direttamente dopo il liceo. Altri frequentano l'università ma la lasciano presto per concentrarsi interamente sul loro sport.
            <br/>
            <br/>
            Esempi di giocatori che hanno preso una via alternativa includono:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Tiger Woods: Woods ha giocato a golf universitario per la Stanford University, dove ha vinto il campionato NCAA due volte. Tuttavia, è diventato professionista nel 1996 dopo due anni di studio. Da allora ha ottenuto numerosi successi nel PGA Tour, compresi vari major.
                </li>
                <li>
                    Lydia Ko: Ko non ha mai giocato a golf universitario ma è diventata professionista nel 2013 all'età di 16 anni. Da allora ha ottenuto numerosi successi nel LPGA Tour.
                </li>
                <li>
                    Jordan Spieth: Spieth ha giocato a golf universitario per l'Università del Texas, dove ha vinto il campionato NCAA nel 2012. Tuttavia, è diventato professionista nel 2012 dopo un anno di studio. Da allora ha ottenuto numerosi successi nel PGA Tour, compresi vari major.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            In questo capitolo, abbiamo visto come passare dal college al professionismo e quali vie alternative sono disponibili. Nel prossimo capitolo, esploreremo le influenze e le opportunità internazionali nel golf universitario.
        </MKTypography>
    </MKBox>
</MKBox>








                                              






                                  








<MKBox id="Chapter 4" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Capitolo 4: Influenze e Opportunità Internazionali
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Il golf universitario non è solo uno sport americano, ma anche internazionale. Ci sono molti giocatori stranieri che vengono negli Stati Uniti per studiare e giocare a golf, e ci sono anche molti giocatori americani che giocano all'estero. In questo capitolo, esamineremo l'impatto e i contributi degli studenti atleti internazionali e le opportunità per i giocatori di golf universitari americani di giocare a livello internazionale.
    </MKTypography>

    <MKBox id="4.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.1 Giocatori Stranieri nel Golf Universitario
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image3}
                    alt="Texas Longhorns, con Jordan Spieth, che festeggiano il campionato nazionale"
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal3(Image3)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Texas Longhorns, con Jordan Spieth, che festeggiano il campionato nazionale
                </figcaption>
            </figure>
            Il golf universitario è un'opzione interessante per molti giocatori stranieri alla ricerca di una combinazione di sport e istruzione. Possono beneficiare dell'alta qualità delle università americane, delle eccellenti strutture e dell'orientamento dei programmi di golf, nonché della forte competizione e dell'esposizione dei tornei di golf universitari.
            <br/>
            <br/>
            I giocatori stranieri offrono anche molto alle università americane. Portano diversità, cultura e talento alle loro squadre. Possono anche condividere le loro esperienze e prospettive con i loro compagni di squadra e allenatori, contribuendo al loro sviluppo.
            <br/>
            <br/>
            Molti giocatori stranieri hanno avuto successo nel golf universitario, distinguendosi come studenti atleti. Esempi includono:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Maria Fassi: Fassi ha giocato a golf universitario presso l'Università dell'Arkansas, dove ha vinto per due volte il premio ANNIKA come miglior golfista universitaria femminile. È diventata professionista nel 2019 e da allora ha ottenuto diversi successi professionali.
                </li>
                <li>
                    Viktor Hovland: Hovland ha giocato a golf universitario presso l'Oklahoma State University, dove ha vinto il campionato NCAA nel 2018. È diventato professionista nel 2019 e da allora ha ottenuto diversi successi professionali.
                </li>
            </ul>
        </MKTypography>
    </MKBox>
    <MKBox id="4.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.2 Giocatori Americani all'Estero
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Il golf universitario non è l'unico modo per svilupparsi come giocatore di golf. Ci sono anche molte opportunità per i giocatori di golf universitari americani di giocare a livello internazionale, sperimentando altre culture e campi da golf. Questo può ampliare i loro orizzonti, aumentare la loro adattabilità e migliorare il loro gioco.
            <br/>
            <br/>
            I giocatori di golf universitari americani possono giocare a livello internazionale attraverso vari programmi, come:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    La Palmer Cup: Un torneo annuale tra squadre di giocatori di golf universitari americani e internazionali, denominato in onore di Arnold Palmer, famoso golfista che ha anche giocato a golf universitario. Il torneo si tiene in diverse località in tutto il mondo, come Francia, Irlanda, Inghilterra e Svezia.
                </li>
                <li>
                    Il Trofeo Eisenhower: Un torneo biennale tra squadre di golfisti dilettanti provenienti da diversi paesi, denominato in onore di Dwight D. Eisenhower, ex presidente degli Stati Uniti e appassionato golfista. Il torneo si tiene in varie località in tutto il mondo, come Australia, Giappone, Messico e Turchia.
                </li>
                <li>
                    Il Programma di Studio All'Estero: Un programma accademico che offre agli studenti l'opportunità di studiare per un semestre o un anno presso un'università straniera. Alcune università hanno anche programmi di golf che collaborano con università straniere, consentendo agli studenti atleti di giocare a golf durante il loro soggiorno.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Molti giocatori di golf universitari americani hanno tratto beneficio dal giocare all'estero, elevando il loro gioco a un livello più alto. Esempi includono:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Collin Morikawa: Morikawa ha giocato a golf universitario presso l'Università della California, Berkeley, dove è diventato quattro volte All-American. Ha anche giocato nella Palmer Cup nel 2017 e nel 2018, e nel Trofeo Eisenhower nel 2018. È diventato professionista nel 2019 e da allora ha ottenuto diversi successi professionali.
                </li>
                <li>
                    Jennifer Kupcho: Kupcho ha giocato a golf universitario presso la Wake Forest University, dove ha vinto il campionato NCAA nel 2018. Ha anche giocato nella Palmer Cup nel 2018, e nella Curtis Cup nel 2016 e nel 2018. È diventata professionista nel 2019 e da allora ha ottenuto diversi successi professionali.
                </li>
                <li>
                    Patrick Reed: Reed ha giocato a golf universitario presso l'Università della Georgia e l'Augusta State University, dove ha vinto il campionato NCAA due volte. Ha anche giocato nella Palmer Cup nel 2011 e ha studiato per un semestre presso l'Università di Stirling in Scozia. È diventato professionista nel 2011 e da allora ha ottenuto diversi successi professionali.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            In questo capitolo, abbiamo visto come il golf universitario sia uno sport internazionale, e le influenze e le opportunità per i giocatori stranieri e americani. Nel prossimo capitolo, esamineremo l'importanza delle borse di studio per il golf, e il ruolo di Sportbeursamerika.nl.
        </MKTypography>
    </MKBox>
</MKBox>












                                              






                                  






<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 5: Borse di Studio Sportive e il Futuro degli Atleti
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le borse di studio sportive offrono numerose opportunità ai giovani atleti di mostrare il loro talento e sviluppare le loro abilità. Una delle opportunità più significative è ottenere una borsa di studio sportiva per studiare e competere a livello universitario. In questo capitolo, discuteremo dell'importanza delle borse di studio sportive e di come Sport Scholarships America può aiutarti a realizzare il tuo sogno.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 L'Importanza delle Borse di Studio
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Una borsa di studio sportiva è un sostegno finanziario fornito da un'università a uno studente-atleta per combinare studio e sport. Può coprire i costi di iscrizione, libri, alloggio, pasti e altre spese. Inoltre, può offrire vantaggi come il tutorato accademico, l'assistenza medica, le strutture di allenamento e l'attrezzatura.
      <br />
      <br />
      Ottenere una borsa di studio sportiva è competitivo, con molti fattori che influenzano il processo di assegnazione, tra cui risultati accademici, prestazioni sportive, personalità, motivazione, leadership e adattabilità. È quindi importante prepararsi bene e distinguersi dalla concorrenza.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Come Sport Scholarships America Può Aiutare
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo di Sport Scholarships America, raffigurante attrezzature sportive e una toga, simbolo del focus sulle borse di studio sportive per gli studenti-atleti."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo di Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America è un'organizzazione specializzata nell'orientamento dei giovani talenti sportivi verso borse di studio sportive. Con esperti appassionati ed esperti, Sport Scholarships America supporta gli studenti-atleti in ogni fase del percorso, dall'orientamento iniziale alla collocazione finale. I loro servizi includono:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Un'intervista personale per discutere i desideri, le possibilità e le aspettative dello studente-atleta.</li>
        <li>Una valutazione professionale del livello accademico e sportivo dello studente-atleta.</li>
        <li>Una vasta rete di contatti con allenatori, università e college.</li>
        <li>Promozione efficace del profilo e dei video dello studente-atleta presso allenatori potenziali.</li>
        <li>Consulenza esperta sulle migliori opzioni in base alle preferenze, agli obiettivi e al budget dello studente-atleta.</li>
        <li>Negoziazione delle migliori offerte di borse di studio per conto dello studente-atleta.</li>
        <li>Assistenza nella richiesta dei documenti necessari, come visto, trascrizione, diploma e test di ammissione.</li>
        <li>Preparazione alla vita dello studente-atleta durante la borsa di studio, compresa cultura, lingua, regole e consigli.</li>
        <li>Assistenza e monitoraggio durante il periodo di borsa di studio.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vuoi esplorare le opportunità di borse di studio e raggiungere i tuoi obiettivi sportivi e accademici? <Link to="/it/Servizi-Prodotti">Consulta la gamma completa</Link> dei servizi offerti da Sport Scholarships America e prenota una consultazione oggi stesso.
    </MKTypography>
  </MKBox>
</MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;