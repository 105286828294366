/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Page du produit : https://www.creative-tim.com/product/material-kit-pro-react
* Droits d'auteur 2023 Creative Tim (https://www.creative-tim.com)

Codé par www.creative-tim.com

 =========================================================

* L'avis de droit d'auteur ci-dessus et cet avis d'autorisation doivent être inclus dans toutes les copies ou parties substantielles du logiciel.
*/

// Composants Matériel-UI
import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// import Stack from "@mui/material/Stack";

// Composants Material Kit 2 PRO React
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import monImage from "assets/images/SoccerfotoKayDuit1.png";
import deuxiemeImage from "assets/images/SoccerfotoKayDuit2.png"

function Témoignages() {
  const [imageActuelle, setImageActuelle] = useState(monImage);
  const [opacité, setOpacité] = useState(1);

  const monImageAlt = "Kay Duit en action lors d'un match de football, jouant pour l'Université de Tulsa, dynamique et concentré sur le ballon"; // Remplacez par votre texte alternatif réel
  const deuxiemeImageAlt = "Kay Duit en tant que joueur de football à l'Université de Tulsa, actif dans une action de jeu sur le terrain de football."; // Remplacez par votre texte alternatif réel

  useEffect(() => {
    const changerImage = () => {
      setOpacité(0); // commence à s'estomper
      setTimeout(() => {
        setImageActuelle(prev => prev === monImage ? deuxiemeImage : monImage); // change l'image après la moitié de la durée
        setOpacité(1); // commence à apparaître
      }, 500); // c'est la moitié de la durée de la transition
    };

    const intervalle = setInterval(changerImage, 10000); // change toutes les 10 secondes

    return () => clearInterval(intervalle);
  }, []);

  return (
    <MKBox component="section" position="relative" py={8}>
        <Container>
          <Grid container justifyContent="center" sx={{ pt: 0, pb: 0 }}>
            <Grid item xs={12} md={7} sx={{ textAlign: "center" }}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom mb={3}>
            Mon Histoire : De la Passion à la Profession                        </MKTypography>
            <MKTypography color="text" variant="body2" align="center" gutterBottom mb={14}>
            Comment mon expérience dans le monde du sport américain est maintenant la clé pour guider de jeunes talents vers le succès.               </MKTypography>

              
            </Grid>
          </Grid>
        </Container>
     
      <Container sx={{ maxWidth: "100% !important" }}>
      
      <Grid container item xs={12} sm={10} sx={{ mx: "auto" }}>
          
          <MKBox variant="gradient" bgColor="dark" borderRadius="lg" width="100%" py={6}>
            <Grid container>
              
              <Grid item xs={12} md={6} xl={4} sx={{ position: "relative", px: 6 }}>
              <MKBox
                component="img"
                src={imageActuelle}
                alt={imageActuelle === monImage ? monImageAlt : deuxiemeImageAlt}
                borderRadius="md"
                maxWidth="300px"
                width="100%"
                position="relative"
                mt={-12}
                style={{ transition: 'opacity 1s', opacity: opacité }} // style en ligne pour la transition
                    />
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                xl={4}
                sx={{
                  position: "relative",
                  px: { xs: 6, md: 2 },
                  mt: { xs: 3, md: 0 },
                  my: { xs: 0, md: "auto" },
                  color: ({ palette: { white } }) => white.main,
                }}
              >
                <Icon fontSize="large">format_quote</Icon>
                <MKTypography
                  variant="body2"
                  color="white"
                  fontWeight="light"
                  mb={2}
                  sx={{ fontSize: "1.125rem" }}
                >
En tant qu'ancien joueur de soccer à l'Université de Tulsa, j'ai eu le privilège de vivre quatre années inoubliables au cœur de la culture étudiante américaine. Cette passion et cette expérience me poussent maintenant à guider les étudiants européens dans leur propre voyage de soccer aux États-Unis. Ensemble, nous réaliserons votre rêve du sport américain.
                </MKTypography>
                <MKTypography variant="button" fontWeight="bold" color="white">
                  Kay Duit -{" "}
                  <MKTypography variant="caption" color="white">
                    Fondateur
                  </MKTypography>
                </MKTypography>
              </Grid>
              <Grid item xs={1} />
              <Grid
                item
                xs={12}
                xl={2}
                sx={{ px: { xs: 6, xl: 0 }, my: { xs: 0, xl: "auto" }, lineHeight: 1 }}
              >
                <MKTypography variant="h5" color="white" mt={{ xs: 6, xl: 0 }} mb={1}>
                4 ans en tant que lettre
                <br />
                3x Champion AAC
                              
                </MKTypography>
                
                <MKTypography
                  component="a"
                  href="/fr/À-propos-de-moi"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  En savoir plus
                  <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MKTypography>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Témoignages;
