import americanFootball from "assets/images/SportsIcons/americanfootball.png";
import athletics from "assets/images/SportsIcons/atletiek.png";
import basketball from "assets/images/SportsIcons/basketbal.png";
import golf from "assets/images/SportsIcons/golf.png";
import fieldHockey from "assets/images/SportsIcons/hockey.png"; // Updated to Field Hockey
import baseball from "assets/images/SportsIcons/honkbal.png";
import rowing from "assets/images/SportsIcons/roeien.png";
import softball from "assets/images/SportsIcons/softbal.png"
import tennis from "assets/images/SportsIcons/tennis.png";
import gymnastics from "assets/images/SportsIcons/turnen.png";
import soccer from "assets/images/SportsIcons/voetbal.png";
import volleyball from "assets/images/SportsIcons/volleybal.png";
import waterPolo from "assets/images/SportsIcons/waterpolo.png";
import wrestling from "assets/images/SportsIcons/worstelen.png";
import swimming from "assets/images/SportsIcons/zwemmen.png";
import iceHockey from "assets/images/SportsIcons/ijshockey.png";

export default [
  {
    title: "Sports",
    description: "Una selezione di 16 sport per i quali offro consulenza per una borsa di studio sportiva",
    items: [
      {
        image: americanFootball,
        name: "Football Americano",
        badgeContent: "Uomini",
        secondBadgeContent: "",
        route: "/it/Tutti-gli-sport/Football-Americano",
        popularity: 12,
        altText: "Icona del football americano"
      },
      {
        image: athletics,
        name: "Atletica",
        badgeContent: "Uomini",
        secondBadgeContent: "Donne",
        route: "/it/Tutti-gli-sport/Atletica",
        popularity: 5,
        altText: "Icona dell'atletica"
      },
      {
        image: basketball,
        name: "Basket",
        badgeContent: "Uomini",
        secondBadgeContent: "Donne",
        route: "/it/Tutti-gli-sport/Basket",
        popularity: 2,
        altText: "Icona del basket"
      },
      {
        image: golf,
        name: "Golf",
        badgeContent: "Uomini",
        secondBadgeContent: "Donne",
        route: "/it/Tutti-gli-sport/Golf",
        popularity: 9,
        altText: "Icona del golf"
      },
      {
        image: gymnastics,
        name: "Ginnastica",
        badgeContent: "Uomini",
        secondBadgeContent: "Donne",
        route: "/it/Tutti-gli-sport/Ginnastica",
        popularity: 8,
        altText: "Icona della ginnastica"
      },
      {
        image: iceHockey,
        name: "Hockey su Ghiaccio",
        badgeContent: "Uomini",
        secondBadgeContent: "Donne",
        route: "/it/Tutti-gli-sport/Hockey-su-ghiaccio",
        popularity: 14,
        altText: "Icona dell'hockey su ghiaccio"
      },
      {
        image: fieldHockey, // Aggiornato a Hockey su Prato
        name: "Hockey su Prato", // Aggiornato a Hockey su Prato
        badgeContent: "",
        secondBadgeContent: "Donne",
        route: "/it/Tutti-gli-sport/Hockey", // Aggiornato a Hockey su Prato
        popularity: 11,
        altText: "Icona dell'hockey su prato" // Aggiornato a Hockey su Prato
      },
      {
        image: baseball,
        name: "Baseball",
        badgeContent: "Uomini",
        secondBadgeContent: "",
        route: "/it/Tutti-gli-sport/Baseball",
        popularity: 15,
        altText: "Icona del baseball"
      },
      {
        image: rowing,
        name: "Canottaggio",
        badgeContent: "Uomini",
        secondBadgeContent: "Donne",
        route: "/it/Tutti-gli-sport/Canottaggio",
        popularity: 10,
        altText: "Icona del canottaggio"
      },
      {
        image: soccer,
        name: "Calcio",
        badgeContent: "Uomini",
        secondBadgeContent: "Donne",
        route: "/it/Tutti-gli-sport/Calcio",
        popularity: 1,
        altText: "Icona del calcio"
      },
      {
        image: softball,
        name: "Softball",
        badgeContent: "",
        secondBadgeContent: "Donne",
        route: "/it/Tutti-gli-sport/Softball",
        popularity: 13,
        altText: "Icona del softball"
      },
      {
        image: swimming,
        name: "Nuoto",
        badgeContent: "Uomini",
        secondBadgeContent: "Donne",
        route: "/it/Tutti-gli-sport/Nuoto",
        popularity: 6,
        altText: "Icona del nuoto"
      },
      {
        image: tennis,
        name: "Tennis",
        badgeContent: "Uomini",
        secondBadgeContent: "Donne",
        route: "/it/Tutti-gli-sport/Tennis",
        popularity: 4,
        altText: "Icona del tennis"
      },
      {
        image: athletics,
        name: "Atletica Leggera",
        badgeContent: "Uomini",
        secondBadgeContent: "Donne",
        route: "/it/Tutti-gli-sport/Atletica",
        popularity: 5,
        altText: "Icona dell'atletica leggera"
      },
      {
        image: volleyball,
        name: "Pallavolo",
        badgeContent: "Uomini",
        secondBadgeContent: "Donne",
        route: "/it/Tutti-gli-sport/Pallavolo",
        popularity: 3,
        altText: "Icona della pallavolo"
      },
      {
        image: waterPolo,
        name: "Pallanuoto",
        badgeContent: "Uomini",
        secondBadgeContent: "Donne",
        route: "/it/Tutti-gli-sport/Pallanuoto",
        popularity: 7,
        altText: "Icona del pallanuoto"
      },
      {
        image: wrestling,
        name: "Lotta",
        badgeContent: "Uomini",
        secondBadgeContent: "Donne",
        route: "/it/Tutti-gli-sport/Lotta",
        popularity: 16,
        altText: "Icona della lotta"
      },
    ],
  },
];
