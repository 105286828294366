/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Waterpol1.jpeg";
import Image2 from "assets/images/Sports_Images/Waterpolo2.jpeg";
import Image3 from "assets/images/Sports_Images/Waterpolo3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Bourses de Water Polo en Amérique : Comment Étudier et Jouer au Pays des Étoiles et des Rayures', isSubheader: false },
  
    { id: 'Chapitre 1', title: 'Chapitre 1 : Histoire et Développement du Water Polo en Amérique', isSubheader: false },
    { id: '1.1', title: '1.1 Premières Années', isSubheader: true },
    { id: '1.2', title: '1.2 Croissance et Professionnalisation', isSubheader: true },
  
    { id: 'Chapitre 2', title: 'Chapitre 2 : Le Water Polo Universitaire Aujourd\'hui', isSubheader: false },
    { id: '2.1', title: '2.1 Compétitions et Conférences Clés', isSubheader: true },
    { id: '2.2', title: '2.2 Programmes d\'Élite et Équipes Prestigieuses', isSubheader: true },
  
    { id: 'Chapitre 3', title: 'Chapitre 3 : Du Collège aux Professionnels', isSubheader: false },
    { id: '3.1', title: '3.1 Le Chemin vers le Professionnalisme', isSubheader: true },
    { id: '3.2', title: '3.2 Ligues Professionnelles', isSubheader: true },
  
    { id: 'Chapitre 4', title: 'Chapitre 4 : Influences et Compétitions Internationales', isSubheader: false },
    { id: '4.1', title: '4.1 Influences Étrangères', isSubheader: true },
    { id: '4.2', title: '4.2 Les Américains dans les Compétitions Internationales', isSubheader: true },
  
    { id: 'Chapitre 5', title: 'Chapitre 5 : Bourses Sportives et l\'Avenir des Athlètes', isSubheader: false },
    { id: '5.1', title: '5.1 L\'Importance des Bourses', isSubheader: true },
    { id: '5.2', title: '5.2 Comment Sport Scholarships America Peut Aider', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Sommaire
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Cacher</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Afficher</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Bourses de Water Polo en Amérique : Comment Étudier et Jouer au Pays des Étoiles et des Rayures
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Stade plein de fans attendant un match universitaire de water polo."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Calme avant un match
      </figcaption>
    </figure>
    Le water polo a été originaire d'Europe à la fin du 19ème siècle sous forme de rugby aquatique et a rapidement gagné en popularité dans divers pays, y compris en Amérique. Le premier match officiel en Amérique a eu lieu en 1888 entre deux clubs à New York. Depuis lors, le water polo s'est considérablement développé en Amérique, tant au niveau professionnel qu'universitaire.
    <br />
    <br />
    Le water polo universitaire est l'un des principaux facteurs du développement des joueurs de water polo en Amérique. De nombreux joueurs commencent leur carrière au lycée avant de passer à l'université, où ils ont l'opportunité de concourir contre les meilleures équipes et joueurs du pays. Le water polo universitaire offre également une excellente formation académique, permettant aux joueurs de combiner leur passion pour le sport avec leurs objectifs éducatifs.
    <br />
    <br />
    Mais comment pouvez-vous, en tant qu'étudiant néerlandais, étudier et jouer en Amérique ? C'est là que Sportbeursamerika.nl peut vous aider. Sportbeursamerika.nl est une organisation spécialisée dans l'assistance aux étudiants pour l'obtention de bourses de water polo en Amérique. Sportbeursamerika.nl dispose d'une équipe d'experts qui peut vous guider à chaque étape du processus, de la recherche de la bonne université à la gestion de toutes les formalités pratiques.
    <br />
    <br />
    Si vous êtes intéressé par l'obtention d'une bourse de water polo en Amérique, continuez à lire pour en savoir plus sur cette opportunité passionnante.
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 1 : Histoire et Développement du Water Polo en Amérique
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le water polo a une longue et riche histoire en Amérique. C'est l'un des plus anciens sports olympiques, d'abord joué lors des Jeux Olympiques de 1900 à Paris, où l'Amérique a également participé. Depuis lors, l'Amérique a participé à chaque compétition olympique de water polo, sauf en 1976 et 1980 où elle s'est retirée pour protester contre les situations politiques en Afrique du Sud et en Union Soviétique, respectivement.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Premières Années
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Les premiers clubs de water polo en Amérique ont été fondés à New York et à Boston à la fin du 19ème siècle. Ils jouaient selon les règles anglaises, qui étaient beaucoup plus brutales et violentes que les règles modernes. Le sport est rapidement devenu populaire parmi les immigrants venant d'Europe, en particulier d'Irlande et d'Écosse. Le premier championnat national a eu lieu en 1890 et a été remporté par le Sydenham Swimmers Club.
      <br />
      <br />
      Les premières équipes universitaires ont été formées au début du 20ème siècle, notamment sur la côte est. Les universités de la Ivy League telles que Harvard, Yale et Princeton ont dominé le sport jusqu'aux années 1920. La première compétition intercollégiale a été organisée en 1912 et a été remportée par Princeton.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Croissance et Professionnalisation
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dans les années 1920, le water polo a commencé à se répandre dans d'autres régions du pays, en particulier en Californie. Un nouveau style de jeu, axé sur la vitesse, l'agilité et le tir plutôt que le contact physique, s'est développé là-bas. Les équipes californiennes sont rapidement devenues les meilleures du pays, remportant la plupart des titres nationaux et intercollégiaux.
      <br />
      <br />
      Dans les années 1930, les premières ligues professionnelles en Amérique ont émergé, telles que la American Water Polo League et la Pacific Coast Water Polo League. Ces ligues ont attiré de nombreux spectateurs et l'attention des médias, élevant le niveau et la popularité du sport. De nombreux joueurs professionnels étaient également des joueurs universitaires ou des diplômés qui ont poursuivi leur carrière après leurs études.
      <br />
      <br />
      L'une des figures clés de l'histoire du water polo américain était James "Jimmy" Smith, considéré comme le "père du water polo moderne". Il était joueur, entraîneur, arbitre, organisateur et promoteur du sport. Il a joué pour divers clubs et universités, notamment UCLA, où il est ensuite devenu entraîneur. Il a conduit l'équipe américaine à deux médailles de bronze olympiques en 1924 et 1932. Il a également été l'un des fondateurs du International Swimming Hall of Fame, où il a été intronisé en 1965.
    </MKTypography>
  </MKBox>
</MKBox>



                                              





                                  






<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 2 : Le Water Polo Universitaire Aujourd'hui
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le water-polo universitaire est l'un des sports les plus prestigieux et compétitifs en Amérique. Il offre une opportunité unique aux étudiants de combiner leurs ambitions académiques et sportives et de rivaliser avec les meilleurs joueurs du pays. Le water-polo universitaire est également une source importante de talent pour l'équipe nationale et les ligues professionnelles.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Compétitions et Conférences Clés
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Action dans l'eau : un joueur tire au but lors d'un match de water-polo."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Joueurs en action
        </figcaption>
      </figure>
      Le water-polo universitaire est réglementé par la National Collegiate Athletic Association (NCAA), qui divise le sport en trois divisions en fonction du niveau et du nombre de bourses disponibles. La Division I est la division la plus élevée, composée d'environ 30 équipes, principalement de Californie. Les divisions II et III ont chacune environ 20 équipes, plus réparties à travers le pays.
      <br />
      <br />
      Chaque année, un championnat national est organisé pour chaque division, avec les meilleures équipes de chaque conférence qui se disputent le titre. Une conférence est un groupe d'universités qui sont géographiquement ou historiquement connectées et qui rivalisent régulièrement les unes contre les autres. Les principales conférences de water-polo comprennent :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          La Mountain Pacific Sports Federation (MPSF), qui se compose de huit équipes de Californie, dont UCLA, Stanford, USC et Berkeley. Cette conférence est considérée comme la plus forte et la plus prestigieuse du pays et a remporté le plus grand nombre de titres nationaux.
        </li>
        <li>
          La Western Water Polo Association (WWPA), qui se compose de neuf équipes de Californie, du Colorado, de l'Utah et de Hawaï. Cette conférence est la deuxième plus forte du pays et a également remporté quelques titres nationaux.
        </li>
        <li>
          La Collegiate Water Polo Association (CWPA), qui se compose de 18 équipes de la côte Est, dont Harvard, Princeton, Brown et la Marine. Cette conférence est la troisième plus forte du pays et a occasionnellement remporté un titre national.
        </li>
        <li>
          La Southern California Intercollegiate Athletic Conference (SCIAC), qui se compose de neuf équipes du sud de la Californie, dont Pomona-Pitzer, Claremont-Mudd-Scripps et Occidental. Cette conférence est la quatrième plus forte du pays et compétitionne principalement en Division III.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programmes d'Élite et Équipes Prestigieuses
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Le water-polo universitaire compte plusieurs programmes d'élite connus pour leurs excellentes performances, tant sur le plan sportif qu'académique. Ces programmes attirent de nombreux joueurs talentueux qui bénéficient des installations de haute qualité, des entraîneurs et de l'enseignement qu'ils offrent. Certains de ces programmes sont :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          L'Université de Californie, Los Angeles (UCLA) est connue pour son programme de water-polo exceptionnel, qui compte parmi les plus réussis de l'histoire. Avec un nombre impressionnant de titres nationaux pour les équipes masculines et féminines, le programme occupe une place importante dans le sport. Les matchs se déroulent au Spieker Aquatics Center, avec une capacité de 2 500 spectateurs. L'équipe de UCLA est célèbre pour son style de jeu rapide et dynamique et a produit un nombre significatif de médaillés olympiques.
        </li>
        <li>
          L'Université Stanford dispose également d'un programme de water-polo prestigieux avec de nombreux titres nationaux pour les équipes masculines et féminines. Les équipes jouent leurs matchs à domicile au Avery Aquatic Center, qui peut accueillir 2 530 spectateurs. Les équipes de water-polo de Stanford sont réputées pour leurs compétences techniques et tactiques et ont également contribué au développement de nombreux médaillés olympiques.
        </li>
        <li>
          L'Université de Californie du Sud (USC) possède également un programme solide de water-polo avec plusieurs championnats nationaux pour les équipes masculines et féminines. Leur base d'attache est le Uytengsu Aquatics Center, avec une capacité de 2 500 spectateurs. L'équipe de USC se caractérise par un style de jeu physique et agressif et a également formé un nombre important de médaillés olympiques.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  







<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 3 : Du Collège aux Professionnels
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le water-polo universitaire n'est pas seulement un excellent moyen d'étudier et de jouer en Amérique, mais aussi un tremplin possible vers une carrière professionnelle. De nombreux joueurs universitaires passent à des ligues professionnelles, aux États-Unis et à l'étranger, après avoir obtenu leur diplôme. Certains d'entre eux sont également sélectionnés pour l'équipe nationale, qui participe à des tournois internationaux tels que les Jeux olympiques, les Championnats du monde et la Ligue mondiale.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Le Chemin vers le Professionnalisme
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Bien que le water-polo soit un sport populaire et compétitif en Amérique, il n'est pas aussi commercial et lucratif que certains autres sports comme le basketball, le football ou le baseball. Par conséquent, il n'y a pas beaucoup de ligues professionnelles ou d'équipes aux États-Unis, et les salaires et les gains sont relativement bas. La plupart des joueurs professionnels ont également un autre emploi ou une autre source de revenus en plus de leur carrière de water-polo.
      <br />
      <br />
      La principale ligue professionnelle en Amérique est la National Water Polo League (NWPL), qui a été créée en 2018 en tant que successeur de l'American Water Polo League (AWPL). La NWPL se compose de huit équipes, chacune composée de 15 joueurs. Les équipes disputent une saison régulière de 14 matchs, suivie d'un tournoi éliminatoire pour déterminer le champion. Le champion en titre est le Los Angeles Water Polo Club, qui a remporté le titre en 2022.
      <br />
      <br />
      La plupart des joueurs de la NWPL sont d'anciens joueurs universitaires ou des diplômés qui poursuivent leur carrière après leurs études. Certains d'entre eux sont également membres de l'équipe nationale, qui s'entraîne et joue régulièrement au Centre d'entraînement olympique de Colorado Springs. L'équipe nationale est entraînée par Dejan Udovicic, un ancien joueur et entraîneur serbe qui est à la tête de l'équipe depuis 2013.
      <br />
      <br />
      L'un des anciens joueurs universitaires les plus réussis en water-polo est Tony Azevedo, qui est considéré comme l'un des plus grands joueurs de tous les temps. Il a joué pour l'Université Stanford, où il a été nommé Joueur de l'année NCAA quatre fois. Il a également joué pour plusieurs équipes professionnelles en Europe et en Amérique, notamment Brescia (Italie), Olympiacos (Grèce) et Long Beach Shore (Amérique). Il a également participé à cinq Jeux olympiques, remportant une médaille d'argent en 2008. Il est souvent surnommé "Le Sauveur" parce qu'il marquait souvent des buts décisifs pour son équipe.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Ligues Professionnelles
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      En plus de la NWPL, il existe d'autres ligues professionnelles en Amérique, telles que la Major League Water Polo (MLWP) et la Premier Water Polo League (PWPL). Cependant, ces ligues sont moins établies et moins populaires que la NWPL et comptent moins d'équipes et de joueurs. Elles ne sont également pas reconnues par USA Water Polo, la fédération nationale responsable de l'organisation et de la régulation du water-polo en Amérique.
      <br />
      <br />
      De nombreux joueurs américains choisissent donc de poursuivre leur carrière professionnelle à l'étranger, où il y a plus d'opportunités et de meilleures conditions. L'Europe est la destination principale des joueurs américains de water-polo car le continent compte certaines des ligues les plus fortes et les plus prestigieuses au monde. Certaines de ces ligues comprennent :
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          La Ligue des Champions de la LEN, considérée comme la compétition de club la plus prestigieuse en Europe. Elle se compose de 16 équipes de différents pays qui rivalisent pour le titre européen. Le champion en titre est Pro Recco (Italie), qui a remporté le titre en 2021.
        </li>
        <li>
          La Coupe d'Europe de la LEN, considérée comme la deuxième compétition de club en Europe. Elle comprend 32 équipes de différents pays qui se disputent une place en Ligue des Champions. Le champion en titre est l'Orvosegyetem SC (Hongrie), qui a remporté le titre en 2021.
        </li>
        <li>
          Les ligues nationales organisées par les fédérations respectives de chaque pays. Certaines des ligues nationales les plus fortes et les plus populaires comprennent la Serie A1 (Italie), la Liga Premaat (Espagne), la A1 Ethniki (Grèce) et l'OB I (Hongrie).
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  






<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 4 : Influences et Compétitions Internationales
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le water-polo est un sport mondial joué et suivi par des millions de personnes à travers le monde. C'est aussi un sport qui évolue et innove constamment, grâce à l'influence de diverses cultures, styles et traditions. Le water-polo américain ne fait pas exception, car il a appris et bénéficié du water-polo international, tant au niveau des clubs que des équipes nationales.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Influences Étrangères
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Match de championnat de water-polo universitaire"
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Un match pour le championnat universitaire de la NCAA
        </figcaption>
      </figure>
      Comme nous l'avons vu, le water-polo a pris naissance en Europe avant de se propager sur d'autres continents, y compris l'Amérique. Par conséquent, le water-polo européen a eu une influence significative sur le water-polo américain, notamment en termes de technique, de tactique et de stratégie. De nombreux joueurs et entraîneurs américains ont amélioré leurs compétences et leurs connaissances en jouant ou en s'entraînant en Europe ou en apprenant auprès de joueurs et d'entraîneurs européens.
      <br />
      <br />
      L'un des pays européens les plus influents pour le water-polo américain est la Hongrie, qui est considérée comme la nation de water-polo la plus réussie au monde. La Hongrie a remporté 15 médailles d'or olympiques, plus que tout autre pays. La Hongrie est connue pour son style de jeu créatif et intelligent, qui repose sur une bonne manipulation de balle, des passes rapides et un tir précis. De nombreux joueurs et entraîneurs hongrois ont laissé leur empreinte sur le water-polo américain, tels que Tibor Benedek, Zoltan Szecsi et Attila Banhidy.
      <br />
      <br />
      Un autre pays européen influent pour le water-polo américain est la Serbie, qui est également l'une des principales nations de water-polo dans le monde. La Serbie a remporté 7 médailles d'or olympiques, la plus récente en 2016. La Serbie est connue pour son style de jeu physique et agressif, qui repose sur une défense solide, des contre-attaques rapides et un tir puissant. De nombreux joueurs et entraîneurs serbes ont partagé leur expérience et leur expertise avec le water-polo américain, notamment Dejan Udovicic, Andrija Prlainovic et Filip Filipovic.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Les Américains dans les Compétitions Internationales
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      En plus d'apprendre du water-polo international, le water-polo américain a également contribué de manière significative au water-polo international, notamment en termes de performance, d'innovation et de promotion. De nombreux joueurs et entraîneurs américains ont participé à des compétitions internationales, tant au niveau des clubs que des équipes nationales, où ils ont montré leurs talents et ont renforcé leur réputation.
      <br />
      <br />
      Une des compétitions de water-polo internationales les plus importantes est les Jeux olympiques, qui ont lieu tous les quatre ans. L'équipe américaine a toujours participé à cette compétition, sauf en 1976 et 1980. L'équipe américaine a remporté un total de 12 médailles olympiques : 3 d'or, 4 d'argent et 5 de bronze. La médaille la plus récente était une médaille d'argent pour les hommes en 2008. L'équipe américaine est actuellement classée quatrième au monde.
      <br />
      <br />
      Une autre compétition internationale importante pour le water-polo est le Championnat du Monde, qui a lieu tous les deux ans. L'équipe américaine a également participé de manière constante à cette compétition depuis sa première édition en 1973. L'équipe américaine a remporté un total de 9 médailles au Championnat du Monde : 2 d'or, 3 d'argent et 4 de bronze. La médaille la plus récente était une médaille de bronze pour les femmes en 2019. L'équipe américaine est actuellement classée troisième au monde.
      <br />
      <br />
      Une troisième compétition internationale majeure pour le water-polo est la Ligue Mondiale, qui a lieu chaque année. L'équipe américaine a également participé de manière constante à cette compétition depuis sa première édition en 2002. L'équipe américaine a remporté un total de 10 médailles en Ligue Mondiale : 3 d'or, 4 d'argent et 3 de bronze. La médaille la plus récente était une médaille d'or pour les femmes en 2021. L'équipe américaine est actuellement classée deuxième au monde.
    </MKTypography>
  </MKBox>
</MKBox>

         






                                  


<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 5 : Bourses Sportives et l'Avenir des Athlètes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Les bourses sportives offrent de nombreuses opportunités aux jeunes athlètes de montrer leur talent et de développer leurs compétences. L'une des opportunités les plus significatives est d'obtenir une bourse sportive pour étudier et compétitionner au niveau collégial. Dans ce chapitre, nous discuterons de l'importance des bourses sportives et de comment Sport Scholarships America peut vous aider à réaliser votre rêve.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 L'Importance des Bourses
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Une bourse sportive est un soutien financier fourni par une université à un étudiant-athlète pour combiner études et sport. Elle peut couvrir les frais de scolarité, les livres, le logement, les repas et autres dépenses. De plus, elle peut offrir des avantages tels que du tutorat académique, des soins médicaux, des installations d'entraînement et du matériel.
      <br />
      <br />
      Obtenir une bourse sportive est compétitif, avec de nombreux facteurs qui influent sur le processus d'attribution, notamment les performances académiques, sportives, la personnalité, la motivation, le leadership et l'adaptabilité. Il est donc important de bien se préparer et de se démarquer de la concurrence.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Comment Sport Scholarships America Peut Aider
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo de Sport Scholarships America, représentant du matériel sportif et une casquette de graduation, symbolisant le focus sur les bourses sportives pour les étudiants-athlètes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America est une organisation spécialisée dans l'orientation des jeunes talents sportifs vers des bourses sportives. Avec des experts passionnés et expérimentés, Sport Scholarships America soutient les étudiants-athlètes à chaque étape du parcours, de l'orientation initiale à la placement final. Leurs services comprennent :
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Un entretien personnel pour discuter des souhaits, des possibilités et des attentes de l'étudiant-athlète.</li>
        <li>Une évaluation professionnelle du niveau académique et sportif de l'étudiant-athlète.</li>
        <li>Un vaste réseau de contacts avec des entraîneurs, des universités et des collèges.</li>
        <li>Promotion efficace du profil et des vidéos de l'étudiant-athlète auprès des entraîneurs potentiels.</li>
        <li>Conseils d'experts sur les meilleures options en fonction des préférences, des objectifs et du budget de l'étudiant-athlète.</li>
        <li>Négociation des meilleures offres de bourses au nom de l'étudiant-athlète.</li>
        <li>Aide à la demande de documents nécessaires, tels que visa, relevé de notes, diplôme et tests d'admission.</li>
        <li>Préparation à la vie d'étudiant-athlète pendant sa bourse, y compris culture, langue, règles et conseils.</li>
        <li>Suivi et accompagnement pendant la période de la bourse.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vous voulez explorer les opportunités de bourses et atteindre vos objectifs sportifs et académiques ? <Link to="/fr/Services-Produits">Consultez la gamme complète</Link> des services offerts par Sport Scholarships America et planifiez une consultation dès aujourd'hui.
    </MKTypography>
  </MKBox>
</MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;