/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Basketbal1.jpeg";
import Image2 from "assets/images/Sports_Images/Basketbal2.webp";
import Image3 from "assets/images/Sports_Images/Basketbal3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import { Link } from 'react-router-dom';

import "../../../../H1asH3.css"

// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Basketballstipendium i USA: Sådan studerer og spiller du basketball i Amerika', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapitel 1: Historie og udvikling af college basketball', isSubheader: false },
    { id: '1.1', title: '1.1 De tidlige år', isSubheader: true },
    { id: '1.2', title: '1.2 Vækst og professionalisering', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapitel 2: College basketball i dag', isSubheader: false },
    { id: '2.1', title: '2.1 Vigtige konkurrencer og konferencer', isSubheader: true },
    { id: '2.2', title: '2.2 Topprogrammer og prestigefyldte hold', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapitel 3: Fra college til NBA', isSubheader: false },
    { id: '3.1', title: '3.1 Vejen til NBA', isSubheader: true },
    { id: '3.2', title: '3.2 Andre professionelle muligheder', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapitel 4: Internationale indflydelser og muligheder', isSubheader: false },
    { id: '4.1', title: '4.1 Udenlandske spillere i college basketball', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanske spillere i udlandet', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapitel 5: Sportslegater og fremtiden for atleter', isSubheader: false },
    { id: '5.1', title: '5.1 Vigtigheden af legater', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Legater Amerika kan hjælpe', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };





  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
  Basketballstipendium i USA: Sådan studerer og spiller du basketball i Amerika  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Livligt stadion på University of Arizona under en college basketballkamp"
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Kampdag på University of Arizona Stadium
      </figcaption>
    </figure>
    Basketball er en af de mest populære sportsgrene i USA. Millioner af mennesker følger NBA's kampe, den professionelle basketballliga, og NCAA's kampe, college basketballligaen. Basketball er ikke kun en spændende sport at se på, men også en fantastisk måde at udvikle sig som spiller og som person.
    <br/>
    <br/>
    Hvis du er en ung basketballspiller, der drømmer om en karriere i NBA'en, eller bare gerne vil nyde at spille basketball på et højt niveau, så kan et basketballstipendium i Amerika være noget for dig. Et basketballstipendium er økonomisk støtte, du modtager fra et amerikansk universitet for at studere og spille basketball der. Med et basketballstipendium kan du ikke kun opnå din akademiske grad, men også forbedre dine basketballtalenter under professionel vejledning.
    <br/>
    <br/>
    Men hvordan får man et basketballstipendium i Amerika? Hvad er fordelene og udfordringerne ved at spille college basketball? Og hvad er mulighederne efter din college-karriere? I denne artikel vil vi besvare alle disse spørgsmål og fortælle dig alt, hvad du har brug for at vide om basketballstipendier i Amerika. Vi vil også diskutere Sportbeursamerika.nl's rolle, en organisation specialiseret i at hjælpe unge spillere med at realisere deres drøm om et basketballstipendium.
    <br/>
    <br/>
    Vil du vide mere om basketballstipendier i Amerika? Læs videre!
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>



<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Historie og Udvikling af College Basketball
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Basketball er en relativt ung sport, opfundet i 1891 af James Naismith, en canadisk lærer i fysisk uddannelse. Han konciperede spillet som en indendørs aktivitet for sine elever i løbet af vintermånederne. Spillet blev hurtigt populært og spredte sig til forskellige skoler og universiteter i Nordamerika.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidlige År
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Den første officielle basketballkamp på collegeniveau fandt sted i 1895 mellem University of Chicago og University of Iowa. I 1901 blev den første intercollegiate konference etableret, Western Conference, senere kendt som Big Ten Conference. I 1906 blev det første nationale mesterskab organiseret af Amateur Athletic Union (AAU), som dominerede college basketball indtil 1936.
      <br/>
      <br/>
      I 1939 blev det første NCAA-mesterskab afholdt, hvor otte hold konkurrerede om titlen. Turneringen blev vundet af Oregon, der besejrede Ohio State 46-33. Turneringen udviklede sig til en årlig begivenhed, der i stigende grad tiltrak opmærksomhed fra fans og medier.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Vækst og Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Efter Anden Verdenskrig øgede college basketball sin popularitet og kvalitet. Nye stjerner opstod, som Bill Russell, Wilt Chamberlain, Oscar Robertson og Kareem Abdul-Jabbar, der senere også gjorde sig bemærket i NBA. Nye konkurrencer og konferencer blev dannet, såsom National Invitation Tournament (NIT), Atlantic Coast Conference (ACC) og Southeastern Conference (SEC).
      <br/>
      <br/>
      I 1957 fandt en af de mest mindeværdige kampe i college basketballhistorien sted, da North Carolina Tar Heels besejrede Kansas Jayhawks 54-53 efter tre forlængelser. Kampen betragtes som et vendepunkt i college basketball, der viser, hvor spændende og dramatisk spillet kunne være.
      <br/>
      <br/>
      En anden historisk kamp fandt sted i 1966, da Texas Western Miners besejrede Kentucky Wildcats 72-65. Kampen var banebrydende, fordi Texas Western var det første hold, der udelukkende bestod af sorte spillere, mens Kentucky var helt hvidt. Kampen betragtes som et symbol på borgerrettighedskampen og integrationen i Amerika.
      <br/>
      <br/>
      I 1979 fandt det første live-transmitterede NCAA-mesterskab sted med et rekordstort antal seere. Finalen stod mellem Michigan State Spartans og Indiana State Sycamores, ledet af fremtidige NBA-legender Magic Johnson og Larry Bird. Michigan State vandt kampen 75-64 og betragtes som en af de bedste finaler nogensinde.
      <br/>
      <br/>
      I 80'erne og 90'erne fortsatte college basketball med at vokse og udvikle sig med nye regler, stilarter og strategier. Nye rivaliseringer opstod, såsom Duke vs North Carolina, Georgetown vs Syracuse og UCLA vs Arizona. Der var også nye stjerner som Michael Jordan, Patrick Ewing, Shaquille O'Neal og Tim Duncan, der hævede college basketball til et højere niveau.
    </MKTypography>
  </MKBox>
</MKBox>












                                              





                                  






<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: College Basketball i Dag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College basketball er en af de mest populære og prestigefyldte sportsgrene i Amerika i dag. Hvert år tiltrækker kampene millioner af fans til stadioner og fjernsynsskærme. College basketball er også en vigtig kilde til talent for NBA'en og rekrutterer hvert år hundredvis af spillere fra universitetshold.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Vigtige Konkurrencer og Konferencer
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Steph Curry i aktion for Davidson College, der udmærker sig i college basketball."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Stephen Curry i aktion som spiller for Davidson College
        </figcaption>
      </figure>
      College basketball organiseres primært af National Collegiate Athletic Association (NCAA), som har tilsyn med mere end 1.000 universiteter og colleges. NCAA opdeler holdene i tre divisioner, hvor Division I er den højeste. Division I består af 32 konferencer, hvor hvert hold er geografisk eller historisk forbundet.
      <br/>
      <br/>
      Konferencerne er vigtige, da de bestemmer, hvilke hold der skal spille mod hinanden i løbet af den normale sæson, der løber fra november til marts. Hver konference har sin egen tidsplan, regler og mesterskab. Konferencevindere optjener automatisk en plads i NCAA-turneringen, højdepunktet for college basketball.
      <br/>
      <br/>
      NCAA-turneringen, også kendt som March Madness, er en knockout-turnering, der afholdes hvert år i marts og april. Den består af 68 hold, hvoraf 32 er konferencevindere, og 36 er at-larges, udvalgt af et udvalg baseret på deres præstation i løbet af sæsonen. Holdene opdeles i fire regioner og rangeres fra 1 til 16.
      <br/>
      <br/>
      Turneringen begynder med fire play-in kampe, hvor de lavest rangerede hold konkurrerer om de sidste fire pladser i hovedturneringen. Hovedturneringen består af seks runder, hvor et hold elimineres i hver runde, indtil der kun er ét tilbage. De sidste fire hold, kendt som Final Four, spiller på en neutral placering for at afgøre den nationale mester.
      <br/>
      <br/>
      NCAA-turneringen er en af de mest spændende og uforudsigelige sportsbegivenheder i verden. Hvert år er der overraskelser, dramaer og sensationer, kendt som upsets, buzzer-beaters og Cinderella-historier. Turneringen tiltrækker millioner af seere og spillere, der forsøger at forudsige vinderen.
      <br/>
      <br/>
      Udover NCAA-turneringen er der andre betydningsfulde konkurrencer inden for college basketball, såsom NIT, CBI, CIT og NAIA-turneringen. Disse konkurrencer tilbyder muligheder for hold, der ikke er kvalificeret til NCAA-turneringen, eller som ønsker at få yderligere erfaring.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Topprogrammer og Prestigefyldte Hold
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      College basketball har mange topprogrammer og prestigefyldte hold, der adskiller sig ved deres historie, tradition, succes og indflydelse. Nogle af disse programmer er:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          UCLA Bruins: Det mest succesrige program i college basketballhistorien med 11 nationale mesterskaber, herunder 10 under træner John Wooden i 60'erne og 70'erne. UCLA har produceret mange berømte spillere, såsom Kareem Abdul-Jabbar, Bill Walton og Reggie Miller.
        </li>
        <li>
          Kentucky Wildcats: Det næst mest succesrige program i college basketballhistorien med otte nationale mesterskaber og 17 Final Four-optrædener. Kentucky er kendt for sine lidenskabelige fans og evnen til at tiltrække toprekutter.
        </li>
        <li>
          North Carolina Tar Heels: Det tredje mest succesrige program i college basketballhistorien med seks nationale mesterskaber og 20 Final Four-optrædener. North Carolina har haft mange legendariske trænere, såsom Dean Smith og Roy Williams, og spillere som Michael Jordan, James Worthy og Vince Carter.
        </li>
        <li>
          Duke Blue Devils: Et af de mest dominerende programmer i moderne college basketball med fem nationale mesterskaber og 16 Final Four-optrædener siden 1986. Duke er kendt for sin træner Mike Krzyzewski, den mest vindende træner i college basketballhistorien, og spillere som Christian Laettner, Grant Hill og Zion Williamson.
        </li>
        <li>
          Kansas Jayhawks: Et af de mest traditionelle og indflydelsesrige programmer i college basketball med tre nationale mesterskaber og 15 Final Four-optrædener. Kansas er spillets fødested, da James Naismith var dets første træner. Kansas har også haft mange store trænere, som Phog Allen, Larry Brown og Bill Self, og spillere som Wilt Chamberlain, Danny Manning og Paul Pierce.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Disse programmer er blot nogle få eksempler på de mange fremragende hold, der beriger college basketball. Hvert år gør nye udfordrere og overraskelser spillet spændende og uforudsigeligt.
    </MKTypography>
  </MKBox>
</MKBox>











                                              






                                  







<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Fra College til NBA
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College basketball er ikke kun en sport, men også en uddannelse. Mange spillere, der deltager i college basketball, sigter mod at nå NBA'en, den bedste og mest prestigefyldte professionelle basketballliga i verden. Men NBA'en er ikke let at nå, og kræver meget talent, hårdt arbejde og held.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Vejen til NBA'en
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      For at være berettiget til NBA'en skal en spiller være mindst 19 år gammel og have afsluttet gymnasiet mindst et år tidligere. Dette betyder, at de fleste spillere, der sigter mod at nå NBA'en, først går på college og spiller i mindst et år. Dette er også kendt som reglen om én og færdig, fordi mange spillere deltager i NBA-udkastet efter blot et år på college.
      <br />
      <br />
      NBA-udkastet er en årlig begivenhed, hvor de 30 NBA-hold skiftevis udvælger en spiller fra en pulje af tilgængelige spillere. Udkastet består af to runder, hvor hvert hold har ét valg pr. runde. Rækkefølgen af valg bestemmes gennem en lodtrækning for hold, der ikke nåede slutspillet i den foregående sæson, og ved omvendt rangering for holdene i slutspillet.
      <br />
      <br />
      Udkastet er et betydningsfuldt øjeblik for spillerne, da det afgør, hvilket hold der har deres rettigheder, og hvor mange penge de kan tjene. Spillere valgt i første runde får en garanteret kontrakt på mindst to år, mens dem valgt i anden runde ikke har nogen garantier. Spillere, der ikke vælges, kan forsøge at sikre sig en kontrakt gennem fri agentur eller ved at slutte sig til G League, NBA's udviklingsliga.
      <br />
      <br />
      Dog er udkastet ikke en garanti for succes i NBA'en. Mange højt udvalgte spillere lever ikke op til forventningerne eller lider af skader. På den anden side er der også mange spillere, der vælges lavt eller slet ikke, men stadig klarer sig godt i NBA'en. Et velkendt eksempel er Giannis Antetokounmpo, der blev valgt som nummer 15 samlet i 2013 og blev udnævnt til NBA MVP og mester i 2021.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Andre Professionelle Muligheder
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ikke alle spillere, der deltager i college basketball, sigter mod at komme til NBA'en. Nogle spillere vælger at færdiggøre deres akademiske uddannelser og forfølge andre karrierer. Andre vælger at spille professionel basketball i andre lande eller ligaer.
      <br />
      <br />
      Der er mange professionelle basketballigaer rundt om i verden, der tilbyder varierende kvalitetsniveauer, lønninger og livsstil. Nogle af disse ligaer inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          EuroLeague: Den højeste og mest prestigefyldte europæiske klubkonkurrence med 18 hold fra 10 lande. EuroLeague betragtes som den næstbedste liga i verden efter NBA'en.
        </li>
        <li>
          Chinese Basketball Association (CBA): Den højeste og mest populære kinesiske klubkonkurrence med 20 hold fra hele Kina. CBA er kendt for sine høje lønninger og store publikum.
        </li>
        <li>
          Australian National Basketball League (NBL): Den højeste og mest konkurrencedygtige australske klubkonkurrence med ni hold fra Australien og New Zealand. NBL er kendt for sit hurtige og fysiske spil.
        </li>
        <li>
          Basketball Africa League (BAL): Den nyeste og mest ambitiøse afrikanske klubkonkurrence med 12 hold fra 12 lande. BAL støttes af NBA'en og FIBA og sigter mod at udvikle basketball på kontinentet.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Disse ligaer er blot nogle få eksempler på de mange muligheder, der er tilgængelige for professionelle basketballspillere uden for NBA'en. Mange spillere nyder at bo og spille i forskellige kulturer, mens de fortsætter med at forfølge deres passion for spillet.
    </MKTypography>
  </MKBox>
</MKBox>













                                              






                                  







<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationale Indflydelser og Muligheder
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Basketball er en global sport, der samler mennesker fra forskellige nationaliteter, kulturer og baggrunde. College basketball er et fremragende eksempel på dette, da det tiltrækker både udenlandske og amerikanske spillere og tilbyder muligheder. I dette kapitel vil vi udforske de internationale indflydelser og muligheder i college basketball.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Udenlandske Spillere i College Basketball
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="NCAA Final Four basketball game with a crowd of nearly 80,000 fans in the stadium."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
         }}>
        Næsten 80.000 tilskuere under en national mesterskabskamp
        </figcaption>
      </figure>
      College basketball har altid budt velkommen til udenlandske spillere, der har bidraget med deres egen stil, færdigheder og perspektiver til spillet. Ifølge NCAA var der i sæsonen 2019-2020 over 1.200 udenlandske spillere i Division I, der kom fra 101 lande. De mest almindelige oprindelseslande var Canada, Australien, Nigeria, Frankrig og Storbritannien.
      <br />
      <br />
      Udenlandske spillere vælger college basketball af forskellige årsager. Nogle ønsker at kombinere deres akademiske uddannelse med deres sportslige ambitioner. Andre sigter mod at tilpasse sig amerikansk kultur og livsstil. Endnu andre søger at forbedre deres chancer for at nå NBA'en eller repræsentere deres nationale hold.
      <br />
      <br />
      Udenlandske spillere har haft en betydelig indflydelse på college basketball, både individuelt og kollektivt. Nogle af de mest succesrige og indflydelsesrige udenlandske spillere i college basketballs historie inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Hakeem Olajuwon: En nigeriansk spiller, der spillede for Houston Cougars fra 1981 til 1984. Han førte sit hold til tre på hinanden følgende Final Four-optrædener og vandt prisen som årets nationale spiller i 1984. Han var det første samlede valg i 1984 NBA-udkastet og blev en af de største centrer gennem tiderne.
        </li>
        <li>
          Andrew Bogut: En australsk spiller, der spillede for Utah Utes fra 2003 til 2005. Han vandt prisen som årets nationale spiller i 2005 og førte sit hold til Sweet Sixteen. Han var det første samlede valg i 2005 NBA-udkastet og blev en All-Star og mester.
        </li>
        <li>
          Luka Garza: En amerikansk spiller med bosniske rødder, der spillede for Iowa Hawkeyes fra 2017 til 2021. Han vandt prisen som årets nationale spiller to gange, i 2020 og 2021, og blev sin universities mest scorende spiller gennem tiden. Han blev valgt som nummer 52 samlet i 2021 NBA-udkastet og betragtes som en af de største college-spillere nogensinde.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Disse spillere er blot nogle få eksempler på de mange udenlandske spillere, der har udmærket sig i college basketball. Hvert år dukker der nye talenter op, der beriger spillet.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikanske Spillere i Udlandet
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ikke kun kommer udenlandske spillere til Amerika for at studere og spille basketball, men amerikanske spillere tager også til udlandet af samme årsager. Nogle amerikanske spillere vælger at springe over eller afkorte deres gymnasie- eller collegekarrierer for at spille professionelt i andre lande eller ligaer.
      <br />
      <br />
      Årsagerne til denne beslutning kan variere, men nogle inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Økonomisk: Nogle spillere ønsker at tjene penge med deres talent i stedet for at vente på en NBA-kontrakt eller et stipendium.
        </li>
        <li>
          Sportslig: Nogle spillere ønsker at udvikle sig mod stærkere modstandere i stedet for at dominere på deres niveau.
        </li>
        <li>
          Personlig: Nogle spillere ønsker at rejse, lære nye kulturer at kende eller opretholde familiebånd.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Amerikanske spillere, der tager til udlandet, har forskellige oplevelser og resultater. Nogle lykkes med at realisere deres NBA-drøm, mens andre nyder en lang og succesrig karriere. Nogle tilpasser sig godt til deres nye omgivelser, mens andre har det svært med sprog, mad eller livsstil.
      <br />
      <br />
      Nogle eksempler på amerikanske spillere, der er taget til udlandet, inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Brandon Jennings: En spiller, der sprang sin gymnasiet for at spille for Lottomatica Roma i Italien i 2008. Han blev valgt som nummer 10 samlet i 2009 NBA-udkastet og spillede i 10 sæsoner i NBA'en.
        </li>
        <li>
          Emmanuel Mudiay: En spiller, der sprang sit college for at spille for Guangdong Southern Tigers i Kina i 2014. Han blev valgt som nummer 7 samlet i 2015 NBA-udkastet og spiller i øjeblikket for Utah Jazz.
        </li>
        <li>
          LaMelo Ball: En spiller, der sprang sin gymnasiet og college for at spille for Illawarra Hawks i Australien i 2019. Han blev valgt som nummer 3 samlet i 2020 NBA-udkastet og vandt Rookie of the Year-prisen.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Disse spillere er blot nogle få eksempler på de mange amerikanske spillere, der har valgt en alternativ vej til professionel basketball. Hvert år globaliserer nye eventyrere spillet.
    </MKTypography>
  </MKBox>
</MKBox>











                                              






                                  


<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Sportslegater og fremtiden for atleter
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sportslegater tilbyder talrige muligheder for unge atleter til at vise deres talent og udvikle deres færdigheder. En af de mest betydningsfulde muligheder er at opnå et sportslegat for at studere og konkurrere på kollegialt niveau. I dette kapitel vil vi diskutere vigtigheden af sportslegater og hvordan Sport Legater Amerika kan hjælpe dig med at realisere din drøm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vigtigheden af legater
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et sportslegat er økonomisk støtte, som et universitet tilbyder til en studerende-atlet for at kombinere studier og sport. Det kan dække omkostningerne til undervisning, bøger, bolig, måltider og andre udgifter. Derudover kan det også tilbyde fordele såsom akademisk vejledning, lægehjælp, træningsfaciliteter og udstyr.
      <br />
      <br />
      At opnå et sportslegat er konkurrencepræget, hvor mange faktorer spiller en rolle i tildelingsprocessen, herunder akademiske præstationer, atletisk præstation, personlighed, motivation, lederskab og tilpasningsevne. Derfor er det vigtigt at forberede sig godt og adskille sig fra konkurrencen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Legater Amerika Kan Hjælpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo af Sport Legater Amerika, der viser sportsudstyr og en eksamenshue, der repræsenterer fokus på sportslegater for studerende-atleter."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo af Sport Legater Amerika
        </figcaption>
      </figure>
      Sport Legater Amerika er en organisation specialiseret i at guide unge sports talenter til sportslegater. Med passionerede og erfarne eksperter støtter Sport Legater Amerika studerende-atleter på hvert trin af rejsen, fra indledende orientering til endelig placering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intake-interview for at diskutere den studerende-athlets ønsker, muligheder og forventninger.</li>
        <li>En professionel evaluering af den studerende-athlets atletiske og akademiske niveau.</li>
        <li>Et omfattende netværk af kontakter med trænere, universiteter og kollegier.</li>
        <li>Effektiv markedsføring af den studerende-athlets profil og videoer til potentielle trænere.</li>
        <li>Ekspertrådgivning om de bedste muligheder baseret på den studerende-athlets præferencer, mål og budget.</li>
        <li>Forhandling af de bedste legat tilbud på vegne af den studerende-atlet.</li>
        <li>Hjælp til ansøgning om nødvendige dokumenter, såsom visum, transkript, eksamensbevis og optagelsesprøver.</li>
        <li>Forberedelse til den studerende-atlets liv under deres legat, herunder kultur, sprog, regler og tips.</li>
        <li>Efterpleje og opfølgning under legatperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ønsker du at udforske legatmuligheder og opnå dine sportslige og akademiske mål? <Link to="/da/Tjenester-Produkter">Se det fulde udvalg</Link> af tjenester tilbudt af Sport Legater Amerika og planlæg en konsultation i dag.
    </MKTypography>
  </MKBox>
</MKBox>









        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;