import React from 'react';
import { Box, Typography, Card, Container, Grid } from '@mui/material';
import DefaultNavbar from "examples/Navbars/DefaultNavbarEspanol";
import MKBox from "components/MKBox";
import HorizontalTimeline from "Languages/Espanol/pages/Over/Over Sport Scholarships America/sections/Beschrijving";
import routes from "routes_Espanol";import bgImage from "assets/images/Achtergronden/Home_BG.png";
import MKTypography from 'components/MKTypography';
import Breadcrumbs from "examples/Breadcrumbs";
import footerRoutes from "footer.routes_Espanol";
import DetailedFooter from "examples/Footers/DetailedFooter";
import { Helmet } from 'react-helmet';
import "../../H1asH2.css"
import "../../H2-as-Body1.css"

function CompanyTimeline() {
  return (
    <>
    <Helmet>
    <title>Sobre Sport Scholarships America - Historia y Hitos</title>
<meta name="description" content="Una visión cronológica de los eventos más importantes y hitos en la historia de Sport Scholarships America." />
<meta name="viewport" content="width=device-width, initial-scale=1" />
<meta charset="UTF-8" />
<meta name="robots" content="index, follow" />

    {/* Open Graph Tags */}
    
    
    
    
    

    {/* Favicon */}
    <link rel="icon" href="https://sportscholarshipsamerica.com/32x32Sportbeurs.png" />
</Helmet>

     <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://calendly.com/sportbeurs-amerika/intake",
          label: "RESERVAR CITA",
          color: "info",
        }}
        transparent
        light
         
      />
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
            overflow: 'hidden',
          }}
        >
                           <MKBox
  width={{ xs: "80%", md: "50%", lg: "25%" }}
  mb={1}
  sx={{
    backgroundColor: "transparent", // Transparent background color
  }}
>
  <Breadcrumbs
    routes={[
      { label: "Home", route: "/es" },
      { label: "Acerca de Sport Scholarships America" },
    ]}
  />
</MKBox>  
          
          <Container>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid
                container
                item
                xs={12}
                lg={6}
                flexDirection="column"
                alignItems="center"
                sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
              >
            <MKTypography variant="h1" className="h1-as-h2" fontWeight="bold">
    Sport Scholarships America de un Vistazo
</MKTypography>
<MKTypography variant="h2" className="h2-as-body1" color="text">
    Una Visión Cronológica de los Eventos y Hitos Clave de Sport Scholarships America
</MKTypography>

              </Grid>
            </Grid>
          </Container>

          {/* Horizontal Timeline in its own container */}
          <Container sx={{ py: 2 }}>
            <HorizontalTimeline />
          </Container>

          {/* Additional Content Section if needed */}
          <Box sx={{ p: 2 }}>
            {/* Other content */}
          </Box>
        </Card>
        <MKBox pt={6} px={1} mt={6}>
        <DetailedFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default CompanyTimeline;
