
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

import React from "react";
import { PopupButton } from "react-calendly";
import "./Button.css"
import { Helmet } from 'react-helmet';


// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DetailedFooter from "examples/Footers/DetailedFooter";

// Coworking page sections
import Testimonials from "Languages/English/pages/Homepage/LandingPage/sections/Overmij";
import StatsOne from "Languages/English/pages/Homepage/LandingPage/sections/StatsOne";
import BlogPostThree from "Languages/English/pages/Homepage/LandingPage/sections/Niveaus";
import MapUSA from "Languages/English/pages/Homepage/LandingPage/sections/MapUSA";
import Faq from "Languages/English/pages/Homepage/LandingPage/sections/Faq";
import NewsletterOne from "Languages/English/pages/Homepage/LandingPage/sections/Nieuwsbrief";
import SelectPicker from "Languages/English/pages/Homepage/LandingPage/sections/States";


// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

import "../H1asH2.css"
// Images
import bgImage from "assets/images/Achtergronden/Home_BG.png";

function LandingPage() {
  return (
    <>
<Helmet>
    <title>Your Path to a Scholarship in America - Sport Scholarships America</title>
    <meta name="description" content="Start your journey to a sports scholarship in America. Receive expert guidance and all the information you need to study and sports in the US." />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta charset="UTF-8" />
    <meta name="robots" content="index, follow" />
    
    {[/* Open Graph Tags */]}
    
    
    
    
    

    {[/* Favicon */]}
    <link rel="icon" href="https://sportscholarshipsamerica.com/32x32Sportbeurs.png" />
</Helmet>


      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://calendly.com/sportbeurs-amerika/intake",
          label: "BOOK APPOINTMENT",
          color: "info",
        }}
        transparent
        light
         
      />
<MKBox
  minHeight="75vh"
  width="100%"
  sx={{
    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
      `${linearGradient(
        rgba(gradients.dark.main, 0.5),
        rgba(gradients.dark.state, 0.5)
      )}, url(${bgImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "grid",
    placeItems: "center",
  }}
>
  {/* Visually hidden image for screen readers */}
  <img 
    src={bgImage} 
    alt="Universiteitscampus in de VS op de achtergrond, met een groen grasveld ervoor. Op de voorgrond liggen drie sportballen: een basketbal, voetbal en American football, die de focus op atletische beurzen voor internationale studenten symboliseren." 
    style={{ 
      width: '1px', 
      height: '1px', 
      position: 'absolute', 
      overflow: 'hidden', 
      clip: 'rect(1px, 1px, 1px, 1px)', 
      whiteSpace: 'nowrap' 
    }} 
  />
        <Container>
          <Grid
            container
            item
            xs={12}
            md={7}
            justifyContent={{ xs: "center", md: "start" }}
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            <MKTypography
              variant="h1"
              className="h1-as-h2"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
Your Path to an Atheltic Scholarship in America            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              mt={1}
              pr={{ md: 12, lg: 24, xl: 32 }}
              opacity={0.8}
            >
Expert Guidance for Student-Athletes to Top Universities in the USA.            </MKTypography>
            <Stack direction="row" spacing={1} mt={4} mb={3}>
  <PopupButton
    url="https://calendly.com/sportbeurs-amerika/intake"
    rootElement={document.getElementById("root")}
    text="BOOK APPOINTMENT"
    className="custom-calendly-button"
  />
 
 
</Stack>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
      <StatsOne />
      
        <Testimonials />
        <Container>
          <Grid container justifyContent="center" sx={{ pt: 6, pb: 0 }}>
            <Grid item xs={12} md={7} sx={{ textAlign: "center" }}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom mb={3}>
            Opportunities Across the Nation            </MKTypography>
            <MKTypography color="text" variant="body2" align="center" gutterBottom mb={5}>
            From California's beaches to New York's skyline and Florida's coast: I connect student-athletes with top universities in all 50 states of America            </MKTypography>

              
            </Grid>
          </Grid>
        </Container>
      
        <MapUSA />
        <SelectPicker />
        <BlogPostThree />
        
        <Faq />
        <NewsletterOne />

        
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DetailedFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default LandingPage;
