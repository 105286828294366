/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Produktside: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Kodet af www.creative-tim.com

=========================================================

* Ovenstående ophavsretsmeddelelse og denne tilladelsesmeddelelse skal inkluderes i alle kopier eller væsentlige dele af softwaren.
*/

// @mui materialkomponenter
import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React-komponenter
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import myImage from "assets/images/SoccerfotoKayDuit1.png";
import secondimage from "assets/images/SoccerfotoKayDuit2.png"

function Testimonials() {
  const [currentImage, setCurrentImage] = useState(myImage);
  const [opacity, setOpacity] = useState(1);

  const myImageAlt = "Kay Duit i aktion under en fodboldkamp, spiller for University of Tulsa, dynamisk og fokuseret på bolden"; // Erstat med din faktiske alt tekst
  const secondImageAlt = "Kay Duit som fodboldspiller på University of Tulsa, aktiv i en spilhandling på fodboldbanen."; // Erstat med din faktiske alt tekst

  useEffect(() => {
    const switchImage = () => {
      setOpacity(0); // start fading out
      setTimeout(() => {
        setCurrentImage(prev => prev === myImage ? secondimage : myImage); // switch image after half duration
        setOpacity(1); // start fading in
      }, 500); // this is half the duration for fade
    };

    const interval = setInterval(switchImage, 10000); // switch every 10 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <MKBox component="section" position="relative" py={8}>
        <Container>
          <Grid container justifyContent="center" sx={{ pt: 0, pb: 0 }}>
            <Grid item xs={12} md={7} sx={{ textAlign: "center" }}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom mb={3}>
            Min Historie: Fra Passion til Profession                        </MKTypography>
            <MKTypography color="text" variant="body2" align="center" gutterBottom mb={14}>
            Hvordan min erfaring i den amerikanske sportsverden nu udgør nøglen til at guide unge talenter til succes.               </MKTypography>

              
            </Grid>
          </Grid>
        </Container>
     
      <Container sx={{ maxWidth: "100% !important" }}>
      
      <Grid container item xs={12} sm={10} sx={{ mx: "auto" }}>
          
          <MKBox variant="gradient" bgColor="dark" borderRadius="lg" width="100%" py={6}>
            <Grid container>
              
              <Grid item xs={12} md={6} xl={4} sx={{ position: "relative", px: 6 }}>
              <MKBox
                component="img"
                src={currentImage}
                alt={currentImage === myImage ? myImageAlt : secondImageAlt}
                borderRadius="md"
                maxWidth="300px"
                width="100%"
                position="relative"
                mt={-12}
                style={{ transition: 'opacity 1s', opacity: opacity }} // inline style for transition
                    />
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                xl={4}
                sx={{
                  position: "relative",
                  px: { xs: 6, md: 2 },
                  mt: { xs: 3, md: 0 },
                  my: { xs: 0, md: "auto" },
                  color: ({ palette: { white } }) => white.main,
                }}
              >
                <Icon fontSize="large">format_quote</Icon>
                <MKTypography
                  variant="body2"
                  color="white"
                  fontWeight="light"
                  mb={2}
                  sx={{ fontSize: "1.125rem" }}
                >
Som tidligere fodboldspiller på University of Tulsa havde jeg privilegiet at opleve fire uforglemmelige år i hjertet af den amerikanske studiekultur. Den passion og erfaring driver mig nu til at vejlede europæiske studerende på deres eget fodboldeventyr i USA. Sammen vil vi gøre din amerikanske sportsdrøm til virkelighed.
                </MKTypography>
                <MKTypography variant="button" fontWeight="bold" color="white">
                  Kay Duit -{" "}
                  <MKTypography variant="caption" color="white">
                    Stifter
                  </MKTypography>
                </MKTypography>
              </Grid>
              <Grid item xs={1} />
              <Grid
                item
                xs={12}
                xl={2}
                sx={{ px: { xs: 6, xl: 0 }, my: { xs: 0, xl: "auto" }, lineHeight: 1 }}
              >
                <MKTypography variant="h5" color="white" mt={{ xs: 6, xl: 0 }} mb={1}>
                4-års brevmodtager
                <br />
                3x AAC-mester
                              
                </MKTypography>
                
                <MKTypography
                  component="a"
                  href="/da/Om-mig"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Læs mere
                  <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MKTypography>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Testimonials;
