
import { LinkedIn } from "@mui/icons-material";
import { Instagram } from "@mui/icons-material";
import { Twitter } from "@mui/icons-material";


// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images//Logos/Sportbeurs.png";


const date = new Date().getFullYear();

export default {
  brand: {
    name: "Sport Scholarships America",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <LinkedIn />,
      link: "https://www.linkedin.com/company/sportbeurs-amerika-nl",
    },
    {
      icon: <Instagram />,
      link: "",
    },
    {
      icon: <Twitter />,
      link: "",
    },
  
  ],
  menus: [
    {
      name: "Company",
      items: [
        { name: "About Me", route: "/About-Me" },
        { name: "About Sport Scholarships America", route: "/About-Sport-Scholarships-America" },
        { name: "Services & Products", route: "/Services-Products" },
      ],
    },
    {
      name: "Sources",
      items: [
        { name: "Podcast", route: "/Podcast" },
        { name: "Knowledge Center", route: "/Knowledge-Center" },
      ],
    },
    {
      name: "Help & Support",
      items: [
        { name: "Contact Form", route: "/Contact-Form" },
        { name: "FAQ's", route: "/FAQs" },
      ],
    },
    {
      name: "Legal",
      items: [
        { name: "Privacy Policy", route: "/Privacy-Policy" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
All rights reserved. Copyright © {date} Sport Scholarships America    </MKTypography>
  ),
};