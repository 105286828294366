/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Produktseite: https://www.creative-tim.com/product/material-kit-pro-react
* Urheberrecht 2023 Creative Tim (https://www.creative-tim.com)

Programmiert von www.creative-tim.com

=========================================================

* Der obige Urheberrechtsvermerk und dieser Genehmigungsvermerk müssen in allen Kopien oder wesentlichen Teilen der Software enthalten sein.
*/

// @mui Material-Komponenten
import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React-Komponenten
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import myImage from "assets/images/SoccerfotoKayDuit1.png";
import secondimage from "assets/images/SoccerfotoKayDuit2.png"

function Testimonials() {
  const [currentImage, setCurrentImage] = useState(myImage);
  const [opacity, setOpacity] = useState(1);

  const myImageAlt = "Kay Duit in Aktion während eines Fußballspiels, spielt für die University of Tulsa, dynamisch und konzentriert auf den Ball"; // Ersetzen Sie dies durch Ihren tatsächlichen Alt-Text
  const secondImageAlt = "Kay Duit als Fußballspieler an der University of Tulsa, aktiv in einer Spielaktion auf dem Fußballfeld."; // Ersetzen Sie dies durch Ihren tatsächlichen Alt-Text

  useEffect(() => {
    const switchImage = () => {
      setOpacity(0); // beginne mit dem Verblassen
      setTimeout(() => {
        setCurrentImage(prev => prev === myImage ? secondimage : myImage); // Bild nach halber Dauer wechseln
        setOpacity(1); // beginne mit dem Einblenden
      }, 500); // dies ist die Hälfte der Dauer für den Übergang
    };

    const interval = setInterval(switchImage, 10000); // alle 10 Sekunden wechseln

    return () => clearInterval(interval);
  }, []);

  return (
    <MKBox component="section" position="relative" py={8}>
        <Container>
          <Grid container justifyContent="center" sx={{ pt: 0, pb: 0 }}>
            <Grid item xs={12} md={7} sx={{ textAlign: "center" }}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom mb={3}>
            Meine Geschichte: Von der Leidenschaft zum Beruf                        </MKTypography>
            <MKTypography color="text" variant="body2" align="center" gutterBottom mb={14}>
            Wie meine Erfahrung in der amerikanischen Sportwelt jetzt der Schlüssel ist, um junge Talente zum Erfolg zu führen.               </MKTypography>

              
            </Grid>
          </Grid>
        </Container>
     
      <Container sx={{ maxWidth: "100% !important" }}>
      
      <Grid container item xs={12} sm={10} sx={{ mx: "auto" }}>
          
          <MKBox variant="gradient" bgColor="dark" borderRadius="lg" width="100%" py={6}>
            <Grid container>
              
              <Grid item xs={12} md={6} xl={4} sx={{ position: "relative", px: 6 }}>
              <MKBox
                component="img"
                src={currentImage}
                alt={currentImage === myImage ? myImageAlt : secondImageAlt}
                borderRadius="md"
                maxWidth="300px"
                width="100%"
                position="relative"
                mt={-12}
                style={{ transition: 'opacity 1s', opacity: opacity }} // Inline-Stil für den Übergang
                    />
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                xl={4}
                sx={{
                  position: "relative",
                  px: { xs: 6, md: 2 },
                  mt: { xs: 3, md: 0 },
                  my: { xs: 0, md: "auto" },
                  color: ({ palette: { white } }) => white.main,
                }}
              >
                <Icon fontSize="large">format_quote</Icon>
                <MKTypography
                  variant="body2"
                  color="white"
                  fontWeight="light"
                  mb={2}
                  sx={{ fontSize: "1.125rem" }}
                >
Als ehemaliger Fußballspieler an der University of Tulsa hatte ich das Privileg, vier unvergessliche Jahre im Herzen der amerikanischen Studentenkultur zu erleben. Diese Leidenschaft und Erfahrung treiben mich jetzt dazu an, europäische Studenten auf ihrer eigenen Fußballreise in den USA zu begleiten. Gemeinsam werden wir deinen amerikanischen Sporttraum verwirklichen.
                </MKTypography>
                <MKTypography variant="button" fontWeight="bold" color="white">
                  Kay Duit -{" "}
                  <MKTypography variant="caption" color="white">
                    Gründer
                  </MKTypography>
                </MKTypography>
              </Grid>
              <Grid item xs={1} />
              <Grid
                item
                xs={12}
                xl={2}
                sx={{ px: { xs: 6, xl: 0 }, my: { xs: 0, xl: "auto" }, lineHeight: 1 }}
              >
                <MKTypography variant="h5" color="white" mt={{ xs: 6, xl: 0 }} mb={1}>
                4-Jahres-Briefgewinner
                <br />
                3x AAC-Champion
                              
                </MKTypography>
                
                <MKTypography
                  component="a"
                  href="/de/Über-mich"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Mehr lesen
                  <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MKTypography>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Testimonials;
