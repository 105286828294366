
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import SchoolIcon from '@mui/icons-material/School';
import StarIcon from '@mui/icons-material/Star';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2'; 
import Filter3Icon from '@mui/icons-material/Filter3'; 
import Filter4Icon from '@mui/icons-material/Filter4'; 
import Filter5Icon from '@mui/icons-material/Filter5'; 
import Filter6Icon from '@mui/icons-material/Filter6'; 
import Filter7Icon from '@mui/icons-material/Filter7'; 
import Filter8Icon from '@mui/icons-material/Filter8'; 
import Filter9Icon from '@mui/icons-material/Filter9';  // Import the number 1 icon
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import ScheduleIcon from '@mui/icons-material/Schedule';
import VideocamIcon from '@mui/icons-material/Videocam';
import QuizIcon from '@mui/icons-material/Quiz';
import RuleIcon from '@mui/icons-material/Rule';
import ChecklistIcon from '@mui/icons-material/Checklist';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import DescriptionIcon from '@mui/icons-material/Description';




const boxStyle = {
  boxShadow: '0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15)',
  background: '#f5f5f5', // Light grey background
  color: '#000', // Text color set to black for contrast
};

const arrowStyle = {
  borderRight: '7px solid white',
};

function Timeline() {
  return (
    <VerticalTimeline lineColor='rgb(33, 150, 243)'>
      <VerticalTimelineElement
        iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
        icon={<StarIcon />}
      />
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ ...boxStyle, position: 'relative' }} // Add position relative to the style
        contentArrowStyle={arrowStyle}
        date="Step 1"
        iconStyle={{ 
          background: 'rgb(33, 150, 243)', 
          color: '#fff', 
          boxShadow: '0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15)', // Ensure the shadow is applied here
          border: '2px solid white' // Maintain the border
        }}
        icon={<RecordVoiceOverIcon />}
      >
        <div style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          opacity: 0.5 // Reduced opacity for a watermark effect
        }}>
          <Filter1Icon style={{ color: 'grey', fontSize: '48px' }} />
        </div>

        <h3 className="vertical-timeline-element-title">Initial Consultation:</h3>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Added Roboto as the font family
          fontSize: '17px', // Changed font size to 18px
          fontWeight: '400', // Ensure the font weight is normal
          marginBottom: '10px', // Adjust the value as needed
        }}>
          This is your starting block – the moment we align your dreams and goals with a realistic path to a sports scholarship in America.
        </p>

        <ul style={{ 
          listStyleType: 'disc', 
          marginLeft: '20px', 
          fontSize: '17px', 
        }}>  
          <li>
            <strong>Personal Objectives:</strong> Clarifying your ambitions and what you seek in a sports scholarship and university experience.
          </li>
          <li>
            <strong>Sporting Skills:</strong> Evaluation of your current sports performance and potential for competition at the university level.
          </li>
          <li>
            <strong>Academic Aspirations:</strong> Understanding of your academic interests and goals, and how to integrate them with your sport.
          </li>
          <li>
            <strong>Personal Objectives:</strong> Based on the conversation, I will draw up an initial strategy and timeline for your journey to a sports scholarship.
          </li>
        </ul>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Added Roboto as the font family
          fontSize: '17px', // Changed font size to 18px
          fontWeight: '400', // Ensure the font weight is normal
        }}>
          Together, we lay the groundwork for a successful match with an American university and set the course for the next steps in the process.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ ...boxStyle, position: 'relative' }} // Add position relative to the style
        contentArrowStyle={arrowStyle}
        date="Step 2"
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        icon={<ScheduleIcon />}
      >
        <div style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          opacity: 0.5 // Reduced opacity for a watermark effect
        }}>
          <Filter2Icon style={{ color: 'grey', fontSize: '48px' }} />
        </div>
        <h3 className="vertical-timeline-element-title">Assessment and Planning:</h3>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Added Roboto as the font family
          fontSize: '17px', // Changed font size to 18px
          fontWeight: '400', // Ensure the font weight is normal
          marginBottom: '10px', // Adjust the value as needed
        }}>
          After your initial consultation, I take the time for a thorough assessment and crafting of a personalized plan.
        </p>

        <ul style={{ 
          listStyleType: 'disc', 
          marginLeft: '20px', 
          fontSize: '17px', 
        }}>  
          <li>
            <strong>Potential Analysis:</strong> I assess your sporting and academic background to gauge your prospects in the US.
          </li>
          <li>
            <strong>Action Plan:</strong> I develop a tailor-made plan, including a timeline and milestones, to achieve your goals.
          </li>
          <li>
            <strong>Strategic Advice:</strong> Advice on how to improve your sports and academic performance to enhance your scholarship chances.
          </li>
          <li>
            <strong>Target Universities:</strong> Together, we identify a list of target universities that align with your profile and ambitions.
          </li>
        </ul>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Added Roboto as the font family
          fontSize: '17px', // Changed font size to 18px
          fontWeight: '400', // Ensure the font weight is normal
        }}>
          This detailed plan forms the basis for your journey to a sports scholarship and ensures you are prepared for each subsequent step.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ ...boxStyle, position: 'relative' }} // Add position relative to the style
        contentArrowStyle={arrowStyle}
        date="Step 3"
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        icon={<VideocamIcon />}
      >
        <div style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          opacity: 0.5 // Reduced opacity for a watermark effect
        }}>
          <Filter3Icon style={{ color: 'grey', fontSize: '48px' }} />
        </div>
        <h3 className="vertical-timeline-element-title">Video Material and Sports Portfolio:</h3>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Added Roboto as the font family
          fontSize: '17px', // Changed font size to 18px
          fontWeight: '400', // Ensure the font weight is normal
          marginBottom: '10px', // Adjust the value as needed
        }}>
          Creating a compelling sports portfolio and professional video material is crucial for your visibility to coaches.
        </p>

        <ul style={{ 
          listStyleType: 'disc', 
          marginLeft: '20px', 
          fontSize: '17px', 
        }}>  
          <li>
            <strong>Video Production:</strong> I advise on content and oversee the production of a highlight video that showcases your skills optimally.
          </li>
          <li>
            <strong>Portfolio Development:</strong> I assist in assembling a comprehensive sports portfolio, including statistics, achievements, and recommendations.
          </li>
          <li>
            <strong>Personal Story:</strong> Your athletic journey and achievements are woven into a personal narrative that sets you apart from others.
          </li>
          <li>
            <strong>Digital Presentation:</strong> I ensure your portfolio and video are digitally accessible and shareable for coaches and scouting teams.
          </li>
        </ul>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Added Roboto as the font family
          fontSize: '17px', // Changed font size to 18px
          fontWeight: '400', // Ensure the font weight is normal
        }}>
          With this step, we ensure that your talent is unmistakably highlighted and attracts the attention of American universities.
        </p>
      </VerticalTimelineElement>




      <VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Add position relative to the style
  contentArrowStyle={arrowStyle}
  date="Step 4"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<QuizIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduced opacity for a watermark effect
  }}>
    <Filter4Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Academic Preparation:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Added Roboto as the font family
    fontSize: '17px', // Changed font size to 18px
    fontWeight: '400', // Ensure the font weight is normal
    marginBottom: '10px', // Adjust the value as needed
  }}>
    Good academic preparation is essential for admission and success in the US.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Test Preparation:</strong> I provide study materials and guidance for SAT/ACT and TOEFL/IELTS, essential tests for your admission.
    </li>
    <li>
      <strong>Study Planning:</strong> Together, we develop a study plan to prepare for the exams, without neglecting your sports training.
    </li>
    <li>
      <strong>Application Documents:</strong> I assist in preparing and revising your application essays and other required documentation.
    </li>
    <li>
      <strong>Academic Advice:</strong> You receive advice on academic requirements and how to strengthen your academic profile.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Added Roboto as the font family
    fontSize: '17px', // Changed font size to 18px
    fontWeight: '400', // Ensure the font weight is normal
  }}>
    My aim is to ensure that you are academically prepared not only to be accepted but to excel at the university.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Add position relative to the style
  contentArrowStyle={arrowStyle}
  date="Step 5"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<SchoolIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduced opacity for a watermark effect
  }}>
    <Filter5Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Promotion at Universities:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Added Roboto as the font family
    fontSize: '17px', // Changed font size to 18px
    fontWeight: '400', // Ensure the font weight is normal
    marginBottom: '10px', // Adjust the value as needed
  }}>
    Promoting your profile at universities is a key step where my expertise and extensive network come into play.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Key Role of Network:</strong> My extensive network within American universities is invaluable in bringing your candidacy to attention.
    </li>
    <li>
      <strong>Strategic Positioning:</strong> I utilize my knowledge and experience to strategically position your profile to stand out to the right sports programs and academic institutions.
    </li>
    <li>
      <strong>Adaptable Promotion Plan:</strong> I create a flexible and customized promotion plan that highlights the unique aspects of your sports and academic profile.
    </li>
    <li>
      <strong>Maximization of Opportunities:</strong> Through targeted promotion, I ensure that your chances of obtaining a scholarship are maximized, with a focus on the best fit for your personal and athletic ambitions.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Added Roboto as the font family
    fontSize: '17px', // Changed font size to 18px
    fontWeight: '400', // Ensure the font weight is normal
  }}>
    My added value lies in effectively spotlighting your talent and aspirations, significantly increasing the chances of success. This is where my network, my understanding of the American university system, and my personal approach make a decisive difference.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Add position relative to the style
  contentArrowStyle={arrowStyle}
  date="Step 6"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<RecordVoiceOverIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduced opacity for a watermark effect
  }}>
    <Filter6Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Communication with Coaches:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    Effective communication with coaches is essential. I act as your personal representative to make a strong first impression.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Direct Introductions:</strong> Personal introductions to coaches in my network, tailored to your sports discipline.
    </li>
    <li>
      <strong>Communication Strategy:</strong> Development of an effective communication strategy to highlight your qualities and ambitions.
    </li>
    <li>
      <strong>Negotiation Guidance:</strong> Guidance in communicating with coaches during the negotiation phase for sports scholarships.
    </li>
    <li>
      <strong>Ongoing Support:</strong> Continuous support and advice in all communications to ensure you make a positive and lasting impression.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    My role is to act as a bridge between you and the American coaches, and to create a communication channel that maximizes your chances of a successful sports scholarship.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Add position relative to the style
  contentArrowStyle={arrowStyle}
  date="Step 7"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<RuleIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduced opacity for a watermark effect
  }}>
    <Filter7Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Selection and Scholarship Offers:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    The selection process is crucial; this is where the fruits of our joint work come to fruition in the form of scholarship offers.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Assessment of Offers:</strong> Together, we review the offers received and discuss the pros and cons of each option.
    </li>
    <li>
      <strong>Strategic Advice:</strong> I provide strategic advice to help you make the best choice for your sporting and academic future.
    </li>
    <li>
      <strong>Negotiation Support:</strong> My experience in negotiations is employed to realize the most favorable conditions for you.
    </li>
    <li>
      <strong>Final Choice:</strong> With my help, you make a well-informed final choice where your sports career and education in the US begin.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    My guidance in this phase is aimed at securing the best possible outcome for your ambitions, and ensuring your future at a top university in America.
  </p>
  
</VerticalTimelineElement>

<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Add position relative to the style
  contentArrowStyle={arrowStyle}
  date="Step 8"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<DescriptionIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduced opacity for a watermark effect
  }}>
    <Filter8Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Visa Application and Enrollment Process:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    After selection, a critical phase begins: finalizing your visa application and enrollment procedures.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Visa Application:</strong> Guidance in applying for the appropriate student visa, including gathering the necessary documentation.
    </li>
    <li>
      <strong>Enrollment Assistance:</strong> Help in completing all enrollment forms and documents for your chosen university and competition.
    </li>
    <li>
      <strong>Financial Planning:</strong> Advice on financial matters such as scholarship fees, tuition, and living costs in the US.
    </li>
    <li>
      <strong>Final Check:</strong> A thorough final check to ensure that all administrative and logistical aspects are addressed.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    My guidance in preparation ensures that you start your American adventure confidently and well-informed.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Add position relative to the style
  contentArrowStyle={arrowStyle}
  date="Step 9"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<ChecklistIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduced opacity for a watermark effect
  }}>
    <Filter8Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Preparation for Departure:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    A smooth transition to the US requires meticulous preparation; the final arrangements are now being made.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Checklist:</strong> A comprehensive checklist to ensure nothing is forgotten, from essential documents to personal necessities.
    </li>
    <li>
      <strong>Accommodation:</strong> Assistance in finding suitable housing close to your university and with the right amenities.
    </li>
    <li>
      <strong>Insurance:</strong> Advice on the right insurances for health, travel, and stay.
    </li>
    <li>
      <strong>Briefing:</strong> A briefing session on cultural differences, life in the US, and practical tips for daily living.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    My guidance in preparation ensures that you start your American adventure confidently and well-informed.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Add position relative to the style
  contentArrowStyle={arrowStyle}
  date="Step 10"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<FlightTakeoffIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduced opacity for a watermark effect
  }}>
    <Filter9Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Departure and Arrival in the US:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    The final step in your preparation has arrived: the journey to America.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Preparation for Departure:</strong> I ensure that you are fully prepared for your departure, with a detailed checklist and final advice.
    </li>
    <li>
      <strong>Farewell Moment:</strong> A moment to reflect on this significant milestone and to say goodbye before you pursue your dream.
    </li>
    <li>
      <strong>Arrival in the US:</strong> Upon arrival, I offer support to help you settle in and navigate your new environment.
    </li>
    <li>
      <strong>First Days:</strong> The first days are exciting, and I ensure that you receive the support you need to get off to a good start.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    I stand by your side at every step, from takeoff in the Netherlands to your arrival on campus in the US, ready to start your studies and sports career.
  </p>
  
</VerticalTimelineElement>

<VerticalTimelineElement
  iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
  icon={<SportsScoreIcon />}
/>
</VerticalTimeline>

  );
}

export default Timeline;
