import { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MKTypography from "components/MKTypography";

import MKBox from "components/MKBox";

import SAT from "assets/images/Producten/SAT_kaft.png";
import ACT from "assets/images/Producten/ACT_kaft.png";
import TOEFL from "assets/images/Producten/Toefl_kaft.png";
import Hoodie from "assets/images/Producten/Hoodie_front.png";
import Shirt from "assets/images/Producten/Shirt_front.png";
import { Link } from 'react-scroll';

import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

function Produkte() {
  const [aktiverTab, setAktiverTab] = useState(1);
  const [tabTyp, setTabTyp] = useState("monatlich");

  const handleTabTyp = ({ currentTarget }, newValue) => {
    setAktiverTab(newValue);
    setTabTyp(currentTarget.id);
  };

  
  return (
    <section id="produkteBereich">
      <Container sx={{ pb: { xs: 12, lg: 8 }, pt: 0 }}>
      <Grid container item xs={12} justifyContent="center" md={8} mx="auto" textAlign="center">
  {/* Jedes MKTypography in ein eigenes Grid-Element einpacken */}
  <Grid item xs={12}> {/* Volle Breite für die Überschrift */}
    <MKTypography variant="h3" color="text">Produkte</MKTypography>
  </Grid>
  <Grid item xs={12}> {/* Volle Breite für den Textkörper */}
    <MKTypography variant="body2" color="text">
    Von Studienführern bis hin zu Merchandise von Sport Scholarships America





</MKTypography>
  </Grid>
  <Grid
          container
          item
          xs={12}
          md={10}
          lg={8}
          justifyContent="center"
          textAlign="center"
          mx="auto"
          mt={6}
        >
          <AppBar position="static">
          <Tabs value={aktiverTab} onChange={handleTabTyp}>
  <Tab
    id="monatlich"
    label={
      <Link 
        to="diensteBereich" 
        spy={true}
        smooth={true}
        duration={500}
        offset={-70}
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <MKBox py={0.5} px={2} color="inherit">
          Dienste
        </MKBox>
      </Link>
    }
  />
  <Tab
    id="jährlich"
    label={
      <Link 
        to="produkteBereich" 
        spy={true}
        smooth={true}
        duration={500}
        offset={-70}
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <MKBox py={0.5} px={2} color="inherit">
          Produkte
        </MKBox>
      </Link>
    }
  />
</Tabs>
          </AppBar>
        </Grid>
          
          
        <Grid container spacing={3} mt={6} mb={6}>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="SAT Studienführer"
      image={SAT}
      description="Bereite dich auf den SAT vor"
      action={{ type: "internal", route: "/de/Dienstleistungen-Produkte/SAT-Guide", label: "Bestellen", color: "primary" }}
    />
  </Grid>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="ACT Studienführer"
      image={ACT}
      description="Bereite dich auf den ACT vor"
      action={{ type: "internal", route: "/de/Dienstleistungen-Produkte/ACT-Guide", label: "Bestellen", color: "primary" }}
    />
  </Grid>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="TOEFL Studienführer"
      image={TOEFL}
      description="Bereite dich auf den TOEFL vor"
      action={{ type: "internal", route: "/de/Dienstleistungen-Produkte/TOEFL-Guide", label: "Bestellen", color: "primary" }}
    />
  </Grid>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="Hoodie"
      image={Hoodie}
      description="Der Sport Scholarships America Hoodie"
      action={{ type: "internal", route: "/de/Dienstleistungen-Produkte/Hoodie", label: "Bestellen", color: "primary" }}
    />
  </Grid>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="T-Shirt"
      image={Shirt}
      description="Das Sport Scholarships America T-Shirt"
      action={{ type: "internal", route: "/de/Dienstleistungen-Produkte/T-Shirt", label: "Bestellen", color: "primary" }}
    />
  </Grid>
</Grid>

          
        
        </Grid>
      </Container>
    </section>
  );
}

export default Produkte;
