/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import Image1 from "assets/images/Sports_Images/Ice Hockey1.jpeg";
import Image2 from "assets/images/Sports_Images/Ice Hockey2.jpg";
import Image3 from "assets/images/Sports_Images/Ice Hockey3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images

import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Ishockeystipendium USA: Sådan Studerer og Spiller Du Ishockey i Amerika', isSubheader: false },
  
    { id: 'Kapitel 1', title: 'Kapitel 1: Ishockeyens Historie og Udvikling i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 De Tidlige År og Colleges\' Rolle', isSubheader: true },
    { id: '1.2', title: '1.2 Vækst og Professionalisering', isSubheader: true },
  
    { id: 'Kapitel 2', title: 'Kapitel 2: College Ishockey i Dag', isSubheader: false },
    { id: '2.1', title: '2.1 Store Ligaer og Konferencer', isSubheader: true },
    { id: '2.2', title: '2.2 Topprogrammer og Prestigefyldte Hold', isSubheader: true },
  
    { id: 'Kapitel 3', title: 'Kapitel 3: Fra College til Professionelt Niveau', isSubheader: false },
    { id: '3.1', title: '3.1 Vejen til Professionalisme', isSubheader: true },
    { id: '3.2', title: '3.2 NHL-draften og Andre Muligheder', isSubheader: true },
  
    { id: 'Kapitel 4', title: 'Kapitel 4: Internationale Indflydelser', isSubheader: false },
    { id: '4.1', title: '4.1 Udenlandske Spillere i College Ishockey', isSubheader: true },
  
    { id: 'Kapitel 5', title: 'Kapitel 5: Sportslegater og fremtiden for atleter', isSubheader: false },
    { id: '5.1', title: '5.1 Vigtigheden af legater', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Legater Amerika Kan Hjælpe', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>





















          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Ishockeystipendium USA: Sådan Studerer og Spiller Du Ishockey i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Luftfoto af et fyldt stadion ved en universitetskamp i ishockey."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Et fyldt stadion under en college-kamp
      </figcaption>
    </figure>
    Ishockey er en af de mest populære sportsgrene i Amerika. Det er en hurtig, fysisk og spændende sport, der tiltrækker millioner af fans. Ishockey spilles på forskellige niveauer, fra amatør til professionelt. Men et af de vigtigste og mest prestigefyldte niveauer er college-ishockey.
    <br />
    <br />
    College-ishockey er versionen af ishockey, der spilles af studerende på universiteter og colleges i Amerika. Det er en del af det amerikanske uddannelsessystem, der kombinerer sport og akademik. College-ishockey giver unge spillere mulighed for at udvikle sig både akademisk og idrætsligt, samtidig med at de nyder studenterlivet.
    <br />
    <br />
    College-ishockey er også et springbræt til den professionelle verden. Mange spillere, der spiller i NHL (National Hockey League) eller andre professionelle ligaer, har først spillet college-ishockey. College-ishockey er en fremragende måde at forberede sig til en karriere inden for sporten.
    <br />
    <br />
    Men hvordan kan en ung ishockeyspiller kvalificere sig til college-ishockey? Det er her, Sportbeursamerika.nl kan hjælpe dig. Jeg er specialiseret i at guide studerende atleter, der ønsker at få et sportsstipendium i Amerika. Et sportsstipendium er en økonomisk belønning, du modtager fra et universitet eller college for at studere og spille sport der. Med et sportsstipendium kan du studere og spille ishockey i Amerika uden at pådrage dig store omkostninger.
    <br />
    <br />
    I denne artikel vil vi give dig mere information om college-ishockey i Amerika, dets historie, udvikling, ligaer, hold, muligheder, internationale indflydelser og selvfølgelig stipendier. Jeg vil også fortælle dig, hvordan jeg kan hjælpe dig med at få din drøm til at gå i opfyldelse. Er du klar til at lære mere om ishockeystipendier i USA? Læs videre!
  </MKTypography>
</MKBox>

<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Ishockeyens Historie og Udvikling i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Ishockey er en sport, der har eksisteret i Amerika i over et århundrede. Den har en rig og fascinerende historie, der er tæt knyttet til colleges' rolle. I dette kapitel dykker vi dybere ned i oprindelsen, væksten og professionaliseringen af ishockey i Amerika.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 De Tidlige År og Colleges' Rolle
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ishockey opstod i Canada, hvor det først blev spillet i det 19. århundrede. Spillet spredte sig hurtigt til USA og blev særligt populært i de nordøstlige stater. De første universitetshold blev dannet i slutningen af det 19. og begyndelsen af det 20. århundrede, herunder Yale, Harvard, Princeton og Dartmouth.
      <br />
      <br />
      Disse hold spillede interkollegiale kampe og tiltrak ofte store tilskuermængder. De spillede også mod canadiske hold, som normalt var stærkere. For at hæve spilniveauet begyndte nogle universiteter at rekruttere professionelle spillere, der indskrev sig som studerende. Denne praksis vakte kontrovers og kritik, da den kompromitterede sportens amatørstatus.
      <br />
      <br />
      For at sætte en stopper for denne praksis blev Intercollegiate Hockey Association (IHA) grundlagt i 1898, den første samling af college-ishockeyprogrammer i Amerika. IHA, undertiden kaldet Intercollegiate Hockey League, inkluderede hold som Brown, Columbia, University of Pennsylvania og Yale. Denne organisation var den eneste mesterskabsmyndighed for college-ishockey på det tidspunkt og bestemte den de facto nationale mester baseret på sæsonens præstationer. IHA eksisterede indtil 1913.
      <br />
      <br />
      Efter opløsningen af IHA blev Intercollegiate Hockey League (IHL) etableret i 1912 af Harvard, Princeton og Yale. Denne liga var et svar på behovet for, at de bedste collegehold kunne fortsætte med at konkurrere mod hinanden. IHL eksisterede indtil 1917, hvor aktiviteter blev suspenderet på grund af 1. verdenskrig.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Vækst og Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      I løbet af det 20. århundrede voksede college-ishockey til at blive en national sport, spillet af hundredvis af hold i forskellige divisioner og konferencer. College-ishockey blev også i stigende grad påvirket af opkomsten af professionelle ishockeyligaer, såsom NHL (grundlagt i 1917) og World Hockey Association (grundlagt i 1972).
      <br />
      <br />
      Disse professionelle ligaer gav muligheder for talentfulde college-spillere for at avancere til et højere niveau. Mange college-spillere blev udvalgt i NHL Draft, den årlige begivenhed, hvor NHL-hold vælger nye spillere. Den første NHL-draft, hvor en college-spiller blev valgt, fandt sted i 1967. Al Karlander, en spiller fra Michigan Tech, blev valgt af Detroit som det 17. samlede valg det år. Siden da er over tusind college-spillere blevet udvalgt af NHL-hold.
      <br />
      <br />
      College-ishockey oplevede også betydningsfulde øjeblikke i sportens historie. Et af de mest mindeværdige var "Miraklet på Isen" i 1980, hvor det amerikanske olympiske hold, der primært bestod af college-spillere, besejrede Sovjetunionen i en historisk kamp under Vinter-OL i Lake Placid. Dette var en bemærkelsesværdig præstation, da sovjeterne på det tidspunkt blev betragtet som verdens bedste ishockeyhold.
      <br />
      <br />
      College-ishockey har derfor en lang og imponerende historie, der vidner om amerikanske spilleres lidenskab og talent. College-ishockey har også bidraget til udviklingen og innovationen af sporten ved at introducere nye regler, taktikker og stilarter.
    </MKTypography>
  </MKBox>
</MKBox>

                                              





                                  








<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: College Ishockey i Dag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College ishockey er en af de mest konkurrenceprægede og spændende sportsgrene i Amerika i dag. Den spilles af over 400 hold, opdelt i tre NCAA-divisioner, forskellige konferencer og regionale zoner. I dette kapitel giver vi en oversigt over de største ligaer og konferencer, top-programmer og prestigefyldte hold samt sæsonens struktur og mesterskab.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Store Ligaer og Konferencer
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Spillere fra Massachusetts fejrer en sejr i college ishockey."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Spillere fra Massachusetts fejrer en sejr
        </figcaption>
      </figure>
      NCAA (National Collegiate Athletic Association) er organisationen, der regulerer college ishockey. NCAA opdeler holdene i tre divisioner baseret på niveau, budget og antal legater, de tilbyder. Divisionerne er:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Division I: Dette er den højeste division, hvor de bedste hold og spillere konkurrerer. Division I består af 60 hold, opdelt i seks konferencer: Atlantic Hockey, Big Ten, ECAC Hockey, Hockey East, NCHC (National Collegiate Hockey Conference) og WCHA (Western Collegiate Hockey Association). Disse konferencer organiserer deres egne konkurrencer og turneringer i løbet af sæsonen.
        </li>
        <li>
          Division II: Dette er en mellemdivision med færre hold og spillere. Der er kun syv hold i Division II, og de har ikke deres egen konference. De konkurrerer normalt mod hold fra Division III eller andre ligaer.
        </li>
        <li>
          Division III: Dette er den laveste division, hvor de fleste hold og spillere deltager. Division III består af 84 hold, opdelt i syv konferencer: CCC (Commonwealth Coast Conference), MASCAC (Massachusetts State Collegiate Athletic Conference), MIAC (Minnesota Intercollegiate Athletic Conference), NCHA (Northern Collegiate Hockey Association), NEHC (New England Hockey Conference), SUNYAC (State University of New York Athletic Conference) og UCHC (United Collegiate Hockey Conference). Disse konferencer organiserer også deres egne konkurrencer og turneringer i løbet af sæsonen.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Udover NCAA-divisionerne og konferencerne er der også andre ligaer og organisationer, der tilbyder college ishockey.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Topprogrammer og Prestigefyldte Hold
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      College ishockey har adskillige topprogrammer og prestigefyldte hold, der skiller sig ud på grund af deres succes, tradition, ry og indflydelse. Nogle af disse programmer og hold inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          University of North Dakota: Et af de mest succesrige college ishockeyprogrammer i Amerika, der konkurrerer i NCHC-konferencen i Division I. Universitetet har vundet flere NCAA-mesterskaber og har produceret talrige spillere, der har spillet i NHL, herunder kendte navne som Jonathan Toews, Zach Parise og T.J. Oshie.
        </li>
        <li>
          Boston College: Et prominent college ishockeyprogram i Amerika, der konkurrerer i Hockey East-konferencen i Division I. Boston College har flere nationale mesterskaber på sit cv. Programmet er også kendt for at have udviklet over 80 NHL-spillere, med bemærkelsesværdige alumner som Johnny Gaudreau, Patrick Eaves og Brian Gionta.
        </li>
        <li>
          University of Minnesota: Dette historiske college ishockeyprogram, der konkurrerer i Big Ten-konferencen i Division I, har opnået et imponerende antal nationale mesterskaber. Det er kendt for at have produceret et rekordantal spillere, der har spillet i NHL, herunder stjerner som Phil Kessel, Paul Martin og Blake Wheeler.
        </li>
        <li>
          University of Wisconsin: Et af de mest prestigefyldte college ishockeyprogrammer i Amerika, der konkurrerer i Big Ten-konferencen i Division I. Wisconsin har vundet flere nationale mesterskaber og har også trænet et betydeligt antal spillere, der har spillet i NHL, herunder Ryan Suter, Joe Pavelski og Dany Heatley.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Disse programmer og hold er blot nogle få eksempler på de mange, som college ishockey har at byde på. Der er mange flere hold, der har deres egen historie, kultur og identitet. College ishockey er en mangfoldig og dynamisk sport med meget at tilbyde spillere og fans.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  







<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Fra College til Professionelt Niveau
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College ishockey er ikke bare en sport; det er også en læringsgrund. Mange spillere, der deltager i college ishockey, har ambitioner om at avancere til den professionelle verden. I dette kapitel vil vi diskutere, hvordan college ishockey kan fungere som et springbræt til NHL eller andre professionelle ligaer, hvilke alumner der har gjort det i den professionelle ishockeyverden, og hvilke muligheder der eksisterer ud over NHL.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Vejen til Professionalisme
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      College ishockey er en fremragende måde at forberede sig til en karriere inden for sport. Ved at spille college ishockey kan du forbedre dine færdigheder, viden og erfaring samtidig med, at du tjener en grad. College ishockey giver dig også mulighed for at konkurrere mod andre talentfulde spillere, der udfordrer og inspirerer dig.
      <br />
      <br />
      En af de vigtigste fordele ved college ishockey er, at det øger din synlighed og eksponering. At spille college ishockey kan tiltrække opmærksomhed fra scouts, trænere og ledere fra professionelle hold, der leder efter nye spillere. Du kan også deltage i udstillinger, lejre og klinikker for at vise dine færdigheder til potentielle arbejdsgivere.
      <br />
      <br />
      En anden fordel ved college ishockey er, at det tilbyder fleksibilitet og muligheder. Ved at spille college ishockey kan du udskyde beslutningen om din fremtid. Du kan vælge at skifte til et professionelt hold efter din første, anden, tredje eller fjerde år, afhængigt af din situation og præference. Du kan også vælge at gennemføre dine studier, før du bliver professionel.
      <br />
      <br />
      College ishockey har produceret mange spillere, der har haft succes i den professionelle ishockeyverden. Nogle af disse spillere inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Jack Eichel: Han spillede en sæson for Boston University i Hockey East-konferencen i Division I. Han vandt Hobey Baker Award som årets bedste spiller i 2015. Han blev valgt som nummer to samlet i NHL-draften i 2015 af Buffalo Sabres, hvor han blev kaptajn og stjernespiller. I 2023 vandt han Stanley Cup'en med Vegas Golden Knights.
        </li>
        <li>
          Cale Makar: Han spillede to sæsoner for UMass Amherst i Hockey East-konferencen i Division I. Han vandt også Hobey Baker Award som årets bedste spiller i 2019. Han blev valgt som nummer fire samlet i NHL-draften i 2017 af Colorado Avalanche, hvor han i øjeblikket spiller som en af ligaens bedste forsvarsspillere.
        </li>
        <li>
          Hilary Knight: Hilary Knight spillede fire sæsoner for University of Wisconsin i WCHA-konferencen i Division I. Hun var en meget talentfuld spiller og blev nomineret som top-10-finalist til Patty Kazmaier Memorial Award i 2009, 2011 og 2012. Selvom hun ikke vandt prisen, blev hun to gange valgt til First Team All-American. Knight spillede senere for Boston Pride i NWHL (National Women’s Hockey League) og det amerikanske landshold, hvor hun vandt to olympiske sølvmedaljer og flere verdensmesterskaber.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Disse spillere er blot nogle få eksempler på de mange, der har omdannet deres college ishockeykarriere til professionelle karrierer. College ishockey er en bevist vej til professionalisme for mange spillere.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 NHL-draften og Andre Muligheder
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      NHL (National Hockey League) er den førende professionelle ishockeyliga i verden. Det er drømmen for mange unge spillere at spille i NHL en dag. En af måderne at opnå det på er gennem NHL-draften, den årlige begivenhed, hvor NHL-hold udvælger nye spillere.
      <br />
      <br />
      NHL-draften er et spændende og betydningsfuldt øjeblik for mange college spillere. Det er en mulighed for at blive valgt af et NHL-hold, der tilbyder dig en kontrakt for at spille for dem. Det anerkender også dit talent, potentiale og hårde arbejde.
      <br />
      <br />
      NHL-draften består af syv runder, hvor hvert NHL-hold skiftes til at vælge en spiller. Valgordenen bestemmes af en lotteri, hvor hold, der havde de dårligste resultater i den foregående sæson, har en bedre chance for at vælge først. NHL-draften finder normalt sted i juni eller juli efter afslutningen af college ishockeysæsonen.
      <br />
      <br />
      For at være berettiget til NHL-draften skal du opfylde visse kriterier. Du skal være en nordamerikansk spiller mellem 18 og 20 år eller en international spiller mellem 18 og 21 år. Du må ikke tidligere have været draftet af et NHL-hold eller have givet dine rettigheder op.
      <br />
      <br />
      At blive valgt af et NHL-hold betyder ikke nødvendigvis, at du straks skal spille for dem. Du kan vælge at vende tilbage til dit college hold og færdiggøre dine studier, samtidig med at du bevarer rettighederne til NHL-holdet. Du kan også vælge at deltage i NHL-holdet eller deres mindre liga affiliate, hvor du yderligere kan udvikle og træne.
      <br />
      <br />
      Imidlertid er NHL-draften ikke den eneste vej til NHL. Der er andre muligheder, såsom free agency. Dette er, når du, som spiller, ikke har en kontrakt med et NHL-hold og er fri til at forhandle med enhver ønsket hold. Dette kan ske, hvis du ikke blev draftet, eller hvis din kontrakt med et NHL-hold udløber eller afsluttes. Som free agent kan du forsøge at sikre dig en kontrakt med et NHL-hold, der har interesse for dig.
      <br />
      <br />
      Ikke desto mindre er NHL ikke den eneste professionelle ishockeyliga i verden. Der er andre ligaer, hvor du kan spille som spiller og tjene til livets ophold. Nogle af disse ligaer inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          KHL (Kontinental Hockey League): Dette er den førende professionelle ishockeyliga i Europa og Asien. Den består af 24 hold fra otte lande: Rusland, Finland, Hviderusland, Kasakhstan, Kina, Letland, Slovakiet og Tjekkiet. Den tilbyder et højt niveau af spil og løn, der tiltrækker spillere fra forskellige lande.
        </li>
        <li>
          SHL (Svenska Hockeyligan): Dette er den førende professionelle ishockeyliga i Sverige. Den består af 14 hold, der konkurrerer om det nationale mesterskab. Den er kendt for sit hurtige og tekniske spil og har produceret mange spillere, der har spillet i NHL.
        </li>
        <li>
          Liiga (Finsk Hockey Liga): Dette er den førende professionelle ishockeyliga i Finland. Den består af 15 hold, der konkurrerer om det nationale mesterskab. Den er kendt for sit fysiske og taktiske spil og har også produceret mange spillere, der har spillet i NHL.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Disse ligaer er blot nogle få eksempler på de mange ligaer rundt om i verden. Der er mange flere ligaer i andre lande, herunder Schweiz, Tyskland, Frankrig, Norge, Danmark, Østrig, Polen, Japan og Australien. Disse ligaer tilbyder forskellige niveauer af spil, lønninger og livsstil til spillere.
      <br />
      <br />
      College ishockey er derfor ikke enden på vejen for mange spillere. I stedet er det begyndelsen på en spændende rejse ind i den professionelle verden. College ishockey tilbyder mange muligheder og valgmuligheder for spillere, der forfølger deres drømme.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  







<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationale Indflydelser
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College ishockey er en sport, der ikke kun er amerikansk, men også international. College ishockey påvirkes af tilstedeværelsen og præstationerne fra udenlandske spillere i amerikanske collegekonkurrencer. College ishockey påvirker også mulighederne og udviklingen af amerikanske spillere i udlandet. I dette kapitel vil vi diskutere den rolle og indflydelse, internationale spillere har i college ishockey, samt mulighederne for amerikanske spillere for at spille internationalt.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Udenlandske Spillere i College Ishockey
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Quinnipiac ishockeyspillere fejrer et mål."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Quinnipiac-spillere fejrer et mål
        </figcaption>
      </figure>
      College ishockey er en sport, der er åben for spillere fra hele verden. Der er mange udenlandske spillere, der kommer til Amerika for at spille college ishockey, med forskellige baggrunde, kulturer og nationaliteter. Nogle af disse spillere inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Daryl Watts: Hun er en canadisk ishockeyspiller, der spillede for både Boston College og Wisconsin i NCAA Division I i to sæsoner. Hun vandt Patty Kazmaier Award i 2018 som freshman, hvilket gjorde hende til den første freshman, der vandt prisen. Watts spillede derefter for Toronto Six i Premier Hockey Federation (PHF), tidligere kendt som National Women’s Hockey League (NWHL), og underskrev en rekordkontrakt med Toronto Six i 2023.
        </li>
        <li>
          Rod Brind'Amour: Født i Canada spillede han college ishockey på Michigan State University, før han fortsatte til NHL. Han havde en meget succesfuld professionel karriere, med højdepunkter, herunder vundet Stanley Cup'en.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Disse spillere er blot nogle få eksempler på de mange udenlandske spillere, der spiller eller har spillet college ishockey. Der er mange flere spillere fra andre lande såsom Sverige, Rusland, Tjekkiet, Tyskland, Frankrig, Schweiz, Danmark, Japan og Australien.
      <br />
      <br />
      Disse udenlandske spillere har en vigtig rolle og indflydelse i college ishockey. De bringer deres eget stil, færdigheder og erfaring med til amerikanske collegekonkurrencer. De beriger spillets niveau og mangfoldighed. De skaber også mere interesse og opmærksomhed omkring college ishockey i deres egne lande og regioner.
      <br />
      <br />
      College ishockey er en sport, der nyder godt af internationale indflydelser. Det er en sport, der byder spillere fra hele verden velkommen og værdsætter dem.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  






<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Sportslegater og fremtiden for atleter
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sportslegater tilbyder talrige muligheder for unge atleter til at vise deres talent og udvikle deres færdigheder. En af de mest betydningsfulde muligheder er at opnå et sportslegat for at studere og konkurrere på kollegialt niveau. I dette kapitel vil vi diskutere vigtigheden af sportslegater og hvordan Sport Legater Amerika kan hjælpe dig med at realisere din drøm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vigtigheden af legater
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et sportslegat er økonomisk støtte, som et universitet tilbyder til en studerende-atlet for at kombinere studier og sport. Det kan dække omkostningerne til undervisning, bøger, bolig, måltider og andre udgifter. Derudover kan det også tilbyde fordele såsom akademisk vejledning, lægehjælp, træningsfaciliteter og udstyr.
      <br />
      <br />
      At opnå et sportslegat er konkurrencepræget, hvor mange faktorer spiller en rolle i tildelingsprocessen, herunder akademiske præstationer, atletisk præstation, personlighed, motivation, lederskab og tilpasningsevne. Derfor er det vigtigt at forberede sig godt og adskille sig fra konkurrencen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Legater Amerika Kan Hjælpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo af Sport Legater Amerika, der viser sportsudstyr og en eksamenshue, der repræsenterer fokus på sportslegater for studerende-atleter."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo af Sport Legater Amerika
        </figcaption>
      </figure>
      Sport Legater Amerika er en organisation specialiseret i at guide unge sports talenter til sportslegater. Med passionerede og erfarne eksperter støtter Sport Legater Amerika studerende-atleter på hvert trin af rejsen, fra indledende orientering til endelig placering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intake-interview for at diskutere den studerende-athlets ønsker, muligheder og forventninger.</li>
        <li>En professionel evaluering af den studerende-athlets atletiske og akademiske niveau.</li>
        <li>Et omfattende netværk af kontakter med trænere, universiteter og kollegier.</li>
        <li>Effektiv markedsføring af den studerende-athlets profil og videoer til potentielle trænere.</li>
        <li>Ekspertrådgivning om de bedste muligheder baseret på den studerende-athlets præferencer, mål og budget.</li>
        <li>Forhandling af de bedste legat tilbud på vegne af den studerende-atlet.</li>
        <li>Hjælp til ansøgning om nødvendige dokumenter, såsom visum, transkript, eksamensbevis og optagelsesprøver.</li>
        <li>Forberedelse til den studerende-atlets liv under deres legat, herunder kultur, sprog, regler og tips.</li>
        <li>Efterpleje og opfølgning under legatperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ønsker du at udforske legatmuligheder og opnå dine sportslige og akademiske mål? <Link to="/da/Tjenester-Produkter">Se det fulde udvalg</Link> af tjenester tilbudt af Sport Legater Amerika og planlæg en konsultation i dag.
    </MKTypography>
  </MKBox>
</MKBox>



          

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;