/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React components
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Jeugd.png";
import post2 from "assets/images/TijdUS.png";
import post3 from "assets/images/Deal.png";

function Post2() {
  return (
    <MKBox component="section" py={0}>
      <Container>
        <Grid container item xs={12} lg={4}>
          <MKTypography variant="h3" mb={6}>
            Read my story
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
        <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <TransparentBlogCard
              image={post1}
              title="Part 1: Growing Up in the Netherlands"
              description="In the enchanting surroundings of Nijmegen, my lifelong love affair with football began. As a child from the  ..."
              action={{
                type: "internal",
                route: "/About-Me/Growing-Up",
                color: "info",
                label: "read more",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <TransparentBlogCard
              image={post2}
              title="Part 2: My Time in America"
              description=" My time in America was like a breathtaking journey, filled with unforgettable experiences and personal growth. ..."
              action={{
                type: "internal",
                route: "/About-Me/Time-in-America",
                color: "info",
                label: "read more",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <TransparentBlogCard
              image={post3}
              title="Part 3: Back in the Netherlands"
              description="Upon returning to the Netherlands, I experienced a mix of nostalgia and determination. My deep-rooted passion ..."
              action={{
                type: "internal",
                route: "/About-Me/Back-in-the-Netherlands",
                color: "info",
                label: "read more",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Post2;

