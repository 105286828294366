/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React components
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Jeugd.png";
import post2 from "assets/images/TijdUS.png";
import post3 from "assets/images/Deal.png";

function Post2() {
  return (
    <MKBox component="section" py={0}>
      <Container>
        <Grid container item xs={12} lg={4}>
          <MKTypography variant="h3" mb={6}>
          Leggi la mia storia          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
        <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <TransparentBlogCard
              image={post1}
              title="Parte 1: Crescere nei Paesi Bassi"
              description="Nell'incantevole ambiente di Nijmegen è iniziato il mio amore eterno per il calcio. Da bambino delle strade  ..."
              action={{
                type: "internal",
                route: "/it/Chi-sono/Crescere",
                color: "info",
                label: "Leggi Di Più",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <TransparentBlogCard
              image={post2}
              title="Parte 2: Il Mio Tempo in America"
              description="Il mio tempo in America è stato come un viaggio mozzafiato, pieno di esperienze indimenticabili e crescita ..."
              action={{
                type: "internal",
                route: "/it/Chi-sono/Il-mio-tempo-in-America",
                color: "info",
                label: "Leggi Di Più",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <TransparentBlogCard
              image={post3}
              title="Parte 3: Tornando in Olanda"
              description="Al mio ritorno in Olanda, ho vissuto una miscela di nostalgia e determinazione. La mia profonda passione per il ..."
              action={{
                type: "internal",
                route: "/it/Chi-sono/Il-mio-ritorno-ai-Paesi-Bassi",
                color: "info",
                label: "Leggi Di Più",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Post2;

