/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Página del producto: https://www.creative-tim.com/product/material-kit-pro-react
* Derechos de autor 2023 Creative Tim (https://www.creative-tim.com)

Codificado por www.creative-tim.com

 =========================================================

* El aviso de derechos de autor anterior y este aviso de permiso deben incluirse en todas las copias o partes sustanciales del software.
*/

// Componentes de Material-UI
import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// import Stack from "@mui/material/Stack";

// Componentes de Material Kit 2 PRO React
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import miImagen from "assets/images/SoccerfotoKayDuit1.png";
import segundaImagen from "assets/images/SoccerfotoKayDuit2.png"

function Testimonials() {
  const [imagenActual, setImagenActual] = useState(miImagen);
  const [opacidad, setOpacidad] = useState(1);

  const miImagenAlt = "Kay Duit en acción durante un partido de fútbol, jugando para la Universidad de Tulsa, dinámico y concentrado en el balón"; // Reemplaza con tu texto alternativo real
  const segundaImagenAlt = "Kay Duit como jugador de fútbol en la Universidad de Tulsa, activo en una jugada en el campo de fútbol."; // Reemplaza con tu texto alternativo real

  useEffect(() => {
    const cambiarImagen = () => {
      setOpacidad(0); // comienza a desvanecerse
      setTimeout(() => {
        setImagenActual(prev => prev === miImagen ? segundaImagen : miImagen); // cambia la imagen después de la mitad de la duración
        setOpacidad(1); // comienza a aparecer
      }, 500); // esto es la mitad de la duración de la transición
    };

    const intervalo = setInterval(cambiarImagen, 10000); // cambia cada 10 segundos

    return () => clearInterval(intervalo);
  }, []);

  return (
    <MKBox component="section" position="relative" py={8}>
        <Container>
          <Grid container justifyContent="center" sx={{ pt: 0, pb: 0 }}>
            <Grid item xs={12} md={7} sx={{ textAlign: "center" }}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom mb={3}>
            Mi Historia: De la Pasión a la Profesión                        </MKTypography>
            <MKTypography color="text" variant="body2" align="center" gutterBottom mb={14}>
            Cómo mi experiencia en el mundo del deporte estadounidense ahora es la clave para guiar a jóvenes talentos hacia el éxito.               </MKTypography>

              
            </Grid>
          </Grid>
        </Container>
     
      <Container sx={{ maxWidth: "100% !important" }}>
      
      <Grid container item xs={12} sm={10} sx={{ mx: "auto" }}>
          
          <MKBox variant="gradient" bgColor="dark" borderRadius="lg" width="100%" py={6}>
            <Grid container>
              
              <Grid item xs={12} md={6} xl={4} sx={{ position: "relative", px: 6 }}>
              <MKBox
                component="img"
                src={imagenActual}
                alt={imagenActual === miImagen ? miImagenAlt : segundaImagenAlt}
                borderRadius="md"
                maxWidth="300px"
                width="100%"
                position="relative"
                mt={-12}
                style={{ transition: 'opacity 1s', opacity: opacidad }} // estilo en línea para la transición
                    />
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                xl={4}
                sx={{
                  position: "relative",
                  px: { xs: 6, md: 2 },
                  mt: { xs: 3, md: 0 },
                  my: { xs: 0, md: "auto" },
                  color: ({ palette: { white } }) => white.main,
                }}
              >
                <Icon fontSize="large">format_quote</Icon>
                <MKTypography
                  variant="body2"
                  color="white"
                  fontWeight="light"
                  mb={2}
                  sx={{ fontSize: "1.125rem" }}
                >
Como exjugador de fútbol en la Universidad de Tulsa, tuve el privilegio de vivir cuatro años inolvidables en el corazón de la cultura estudiantil estadounidense. Esa pasión y experiencia ahora me impulsan a guiar a estudiantes europeos en su propio viaje futbolístico en los Estados Unidos. Juntos, haremos realidad tu sueño deportivo americano.
                </MKTypography>
                <MKTypography variant="button" fontWeight="bold" color="white">
                  Kay Duit -{" "}
                  <MKTypography variant="caption" color="white">
                    Fundador
                  </MKTypography>
                </MKTypography>
              </Grid>
              <Grid item xs={1} />
              <Grid
                item
                xs={12}
                xl={2}
                sx={{ px: { xs: 6, xl: 0 }, my: { xs: 0, xl: "auto" }, lineHeight: 1 }}
              >
                <MKTypography variant="h5" color="white" mt={{ xs: 6, xl: 0 }} mb={1}>
                Ganador de carta de 4 años
                <br />
                3x Campeón de AAC
                              
                </MKTypography>
                
                <MKTypography
                  component="a"
                  href="/es/Acerca-de-mí"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Leer más
                  <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MKTypography>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Testimonials;
