import Alabama from "assets/images/Staten_Images/Alabama.webp"
import Florida from "assets/images/Staten_Images/Florida.jpeg"
import Connecticut from "assets/images/Staten_Images/Connecticut.jpeg"
import Kentucky from "assets/images/Staten_Images/Kentucky.webp"
import Michigan from "assets/images/Staten_Images/Michigan.jpeg"
import North_Carolina from "assets/images/Staten_Images/North_Carolina.jpg"
import Texas from "assets/images/Staten_Images/Texas.jpeg"



const states = [
  {
    "name": "Alabama",
    "image": Alabama,
    "imageDescription": "Alabama vs Auburn es una de las mayores rivalidades del fútbol americano universitario.",
    "description": [
        "Los deportes universitarios son una parte integral de la cultura en Alabama, con un énfasis especial en el fútbol americano. La Universidad de Alabama (UA) en Tuscaloosa y la Universidad de Auburn en Auburn dominan el panorama deportivo en el estado.",
        "El equipo de fútbol americano de UA, los Crimson Tide, tiene una rica historia y ha ganado numerosos campeonatos nacionales. Los juegos en casa en el inmenso estadio Bryant-Denny pueden atraer hasta 100,000 fanáticos. La pasión de los fanáticos es enorme, y el canto 'Roll Tide' es conocido en todo Alabama.",
        "Por otro lado, tenemos a la Universidad de Auburn con su equipo de fútbol americano, los Tigers. Junto con UA, forman el Iron Bowl, una de las rivalidades más intensas en los deportes universitarios. Este enfrentamiento anual pone de cabeza a todo el estado y garantiza emoción y espectáculo.",
        "Además del fútbol americano, Alabama tiene programas sólidos en baloncesto, béisbol y otros deportes, respaldados por un poderoso sentido de espíritu comunitario. En Alabama, los deportes son más que simples pasatiempos; son una parte esencial del estilo de vida y la identidad del estado."
    ]
},
{
    "name": "Alaska",
    "description": [
        "A diferencia de muchos otros estados estadounidenses, los deportes universitarios juegan un papel más modesto en Alaska, principalmente debido a su ubicación remota y clima único. Aquí, el énfasis está más en los deportes de invierno y actividades al aire libre que en deportes estadounidenses tradicionales como el fútbol americano y el béisbol.",
        "La Universidad de Alaska Anchorage (UAA) y la Universidad de Alaska Fairbanks (UAF) son las principales instituciones con programas deportivos activos. UAA tiene un sólido equipo de esquí y patinaje, mientras que UAF es conocida por su equipo de hockey sobre hielo, los Nanooks, que tienen un fiel seguimiento local.",
        "En Alaska, los deportes universitarios se tratan más sobre la experiencia y la comunidad que sobre eventos a gran escala. Los juegos brindan una oportunidad para que la comunidad se reúna, anime a los atletas y celebre la cultura deportiva única del estado.",
        "A pesar de la escala más pequeña, los deportes universitarios en Alaska tienen su propio encanto y ofrecen una comunidad cálida en el frío ambiente del estado."
    ]
},
{
    "name": "Arizona",
    "description": [
        "Los deportes universitarios son inmensamente populares en Arizona, con una rica tradición tanto en fútbol americano como en baloncesto. La Universidad Estatal de Arizona (ASU) en Tempe y la Universidad de Arizona (UA) en Tucson son los pilares del panorama deportivo del estado.",
        "El equipo de fútbol americano de ASU, los Sun Devils, goza de un seguimiento apasionado, mientras que su equipo de baloncesto brilla regularmente en los torneos de la NCAA. UA, con sus Wildcats, es conocida por su exitoso programa de baloncesto y la intensa rivalidad con ASU, que culmina en el juego anual 'Duel in the Desert' de fútbol americano.",
        "Además de los deportes principales, Arizona también sobresale en béisbol, softbol y golf, con equipos universitarios a menudo en el centro de atención nacional. El apoyo comunitario es abrumador, y los eventos deportivos se consideran ocasiones sociales significativas.",
        "Los deportes universitarios en Arizona encarnan el espíritu de competencia y comunidad, con intensas rivalidades y pasión de los fanáticos que contribuyen a una cultura deportiva vibrante y animada."
    ]
},



{
  "name": "Arkansas",
  "description": [
      "En Arkansas, los deportes universitarios juegan un papel central en la vida comunitaria, con un énfasis especial en el fútbol americano y el baloncesto. La Universidad de Arkansas en Fayetteville es una potencia en el estado, conocida por sus Razorbacks.",
      "El equipo de fútbol americano de los Razorbacks goza de reconocimiento regional y nacional, con una base de fanáticos dedicados que siguen apasionadamente cada juego. Sus juegos en casa en el impresionante Razorback Stadium son un espectáculo, donde el canto 'Woo Pig Sooie' es eco de decenas de miles de fanáticos.",
      "El baloncesto también es una parte significativa de la cultura deportiva en Arkansas, con los Razorbacks participando regularmente en el torneo de la NCAA. La emoción y la energía en el Bud Walton Arena durante la temporada de baloncesto son palpables.",
      "Más allá de los deportes principales, los Razorbacks también tienen programas sólidos en atletismo, béisbol y softbol. Esta diversidad en deportes enriquece la cultura deportiva en Arkansas, donde los deportes universitarios son una fuente de orgullo, espíritu comunitario y entretenimiento."
  ]
},
{
  "name": "California",
  "description": [
      "Los deportes universitarios en California son diversos y altamente competitivos, con numerosas universidades destacándose en una variedad de deportes. Desde el fútbol americano hasta la natación, el estado lo tiene todo.",
      "La conferencia Pac-12, con prominentes escuelas como la Universidad de California, Los Ángeles (UCLA) y la Universidad del Sur de California (USC), es conocida por su alto nivel deportivo. El equipo de baloncesto de UCLA tiene una rica historia, mientras que USC tradicionalmente sobresale en el fútbol americano.",
      "Además de los equipos conocidos, también hay escuelas más pequeñas con impresionantes programas deportivos, como la Universidad de Stanford, conocida por su excelencia en natación y atletismo. Esta diversidad crea un entorno deportivo dinámico y emocionante en el estado.",
      "El clima agradable y las extensas instalaciones deportivas de California promueven la participación activa y el entusiasmo por los deportes universitarios. Ya sea un juego de fútbol americano en un estadio lleno o una competencia local de natación, los deportes son una parte integral del estilo de vida californiano."
  ]
},
{
  "name": "Colorado",
  "description": [
      "Los deportes universitarios en Colorado disfrutan de un seguimiento vibrante y entusiasta, en parte debido a los impresionantes paisajes naturales y al estilo de vida activo al aire libre. El estado tiene una rica tradición en fútbol americano, baloncesto y deportes de invierno.",
      "La Universidad de Colorado en Boulder (CU) y la Universidad Estatal de Colorado (CSU) son jugadores prominentes en la escena de los deportes universitarios, con el juego anual de fútbol 'Rocky Mountain Showdown' como punto culminante de su rivalidad.",
      "El equipo de fútbol americano de CU, los Buffaloes, tiene una base de fanáticos apasionada, mientras que su equipo de esquí sobresale en competiciones nacionales, beneficiándose de las excelentes instalaciones de deportes de invierno de Colorado. CSU también es competitiva en varios deportes, con una fuerte presencia en fútbol americano y baloncesto.",
      "Además de las universidades más grandes, los colegios más pequeños en Colorado también tienen programas deportivos activos, lo que contribuye a la rica y diversa cultura deportiva en el estado. Los deportes universitarios en Colorado representan la comunidad, la perseverancia y la celebración de los deportes en todas sus formas."
  ]
},
{
  "name": "Connecticut",
  "image": Connecticut,
  "imageDescription": "El equipo de baloncesto femenino de UConn es uno de los equipos de deportes universitarios más dominantes de todos los tiempos",
  "description": [
      "Los deportes universitarios en Connecticut están dominados por el baloncesto, con un énfasis especial en los exitosos programas de la Universidad de Connecticut (UConn). El estado tiene una rica tradición y una base de fanáticos apasionados profundamente invertidos en los deportes universitarios.",
      "El equipo de baloncesto femenino de UConn es conocido a nivel nacional y ha ganado múltiples campeonatos de la NCAA, lo que los convierte en una potencia en el deporte. El equipo masculino también ha logrado actuaciones impresionantes, haciendo del baloncesto una parte central de la cultura deportiva en Connecticut.",
      "Si bien el baloncesto reina supremo, también hay programas sólidos en otros deportes como hockey sobre hielo, fútbol americano y lacrosse, con varias universidades compitiendo a nivel nacional.",
      "Los deportes universitarios en Connecticut encarnan el orgullo y el espíritu comunitario del estado, donde los eventos deportivos brindan una oportunidad para que los fanáticos se reúnan, animen a los equipos y participen en una rica tradición deportiva."
  ]
},
{
  "name": "Delaware",
  "description": [
      "A pesar de ser uno de los estados más pequeños de los EE. UU., Delaware tiene una vibrante escena de deportes universitarios con una fuerte participación comunitaria. La Universidad de Delaware (UD) en Newark está en el centro de la acción, donde los Blue Hens sobresalen en varios deportes.",
      "El equipo de fútbol americano de UD tiene una rica historia y una base de fanáticos dedicada, con emocionantes juegos en casa en Delaware Stadium. El baloncesto y el lacrosse también son deportes populares, con equipos compitiendo regularmente por el reconocimiento regional y nacional.",
      "A pesar de su pequeño tamaño, Delaware tiene programas sólidos en atletismo, natación y otros deportes, todos contribuyendo a la dinámica cultura deportiva en el estado. Los deportes universitarios aquí son más que un simple pasatiempo; son una parte importante de la identidad comunitaria, fomentando una fuerte conexión entre la universidad y la comunidad en general.",
      "En Delaware, los deportes universitarios unen a las personas, con la pequeña escala creando una comunidad deportiva unida y apasionada."
  ]
},
{
  "name": "Florida",
  "image": Florida,
  "imageDescription": "El Ben Hill Griffin Stadium es el hogar de los Florida Gators",
  "description": [
      "Florida es un punto caliente para los deportes universitarios, con algunos de los programas más competitivos y apasionados del país. El fútbol americano y el baloncesto dominan la escena deportiva, pero también hay espacio para una variedad de otros deportes.",
      "Equipos como los Florida Gators y los Florida State Seminoles tienen reconocimiento nacional y una rica historia tanto en fútbol americano como en baloncesto. Su intensa rivalidad atrae a miles de fanáticos anualmente, y los juegos son puntos destacados de la temporada deportiva.",
      "Además, también hay universidades y colegios más pequeños con sólidos programas deportivos que contribuyen a la rica cultura deportiva en el estado. El béisbol, el atletismo y la natación también son populares, y las excelentes instalaciones y el clima soleado hacen de Florida un lugar ideal para los atletas universitarios.",
      "En Florida, los deportes universitarios son más que solo competencia; son un estilo de vida, una fuente de orgullo y una parte esencial de la identidad comunitaria."
  ]
},
{
  "name": "Georgia",
  "description": [
      "Los deportes universitarios en Georgia se caracterizan por una intensa pasión y rivalidades arraigadas, con el fútbol americano como el rey indiscutible. El estado alberga algunos de los programas más prestigiosos del país, con fanáticos que experimentan apasionadamente cada juego.",
      "Los Bulldogs de la Universidad de Georgia en Athens y los Yellow Jackets de Georgia Tech en Atlanta son los principales protagonistas, con su rivalidad anual 'Clean, Old-Fashioned Hate' atrapando al estado. Los juegos de fútbol americano, especialmente en el masivo Sanford Stadium de UGA, atraen a decenas de miles de fanáticos.",
      "Además del fútbol americano, Georgia también sobresale en baloncesto, atletismo y béisbol, con atletas talentosos y seguidores dedicados. Los programas deportivos en escuelas y colegios más pequeños también contribuyen a un paisaje deportivo rico y diverso en todo el estado.",
      "En Georgia, el deporte es más que competencia; es un estilo de vida, una fuente de espíritu comunitario y una parte indispensable de la identidad regional."
  ]
},

{
  "name": "Hawaii",
  "description": [
      "Los deportes universitarios en Hawái ofrecen una experiencia única inspirada por la belleza de la isla y la fuerte cultura local. A pesar de estar geográficamente aislados, los equipos y fanáticos deportivos en Hawái son extraordinariamente apasionados y comprometidos.",
      "La Universidad de Hawái en Manoa juega un papel central en la escena de los deportes universitarios, con su equipo de fútbol americano, los Rainbow Warriors, como un punto focal importante. Sus juegos en casa en el Aloha Stadium crean una atmósfera vibrante y festiva, con un fuerte espíritu isleño.",
      "Además del fútbol americano, el voleibol, el surf y las carreras de canoas también son populares, reflejando la cultura deportiva única y el paisaje natural de Hawái. Los Rainbow Warriors también son competitivos en baloncesto y béisbol, con una dedicación compartida por toda la comunidad.",
      "En Hawái, los deportes universitarios representan el espíritu Aloha, con equipos y fanáticos que abrazan la cultura isleña, la comunidad y el orgullo, creando juntos una experiencia deportiva única y vibrante."
  ]
},
{
  "name": "Idaho",
  "description": [
      "Los deportes universitarios en Idaho tienen una fuerte tradición, con un enfoque particular en el fútbol americano y el baloncesto. El sentido de comunidad y participación en los equipos deportivos es notable, a pesar de que el estado no alberga universidades enormes.",
      "La Universidad de Boise State, famosa por su campo de fútbol azul, juega un papel prominente en el panorama deportivo de Idaho. El equipo de fútbol americano ha llamado la atención nacional con su éxito y estilo de juego innovador, y los juegos en casa son un espectáculo en sí mismos.",
      "Además de Boise State, escuelas y colegios más pequeños también contribuyen a la rica cultura deportiva, con programas en deportes como béisbol, atletismo y deportes de invierno que se benefician del entorno natural único de Idaho.",
      "En Idaho, los deportes universitarios son más que un simple pasatiempo; son un estilo de vida y una parte esencial del estilo de vida y la identidad del estado, con comunidades que se unen para apoyar a sus equipos."
  ]
},
{
  "name": "Illinois",
  "description": [
      "Illinois tiene una rica historia y un profundo amor por los deportes universitarios, con programas poderosos y fanáticos dedicados. El fútbol americano y el baloncesto atraen una atención significativa durante todo el año, pero también hay una amplia gama de otros deportes prósperos.",
      "La Universidad de Illinois Fighting Illini y los Northwestern University Wildcats lideran el camino, con intensas rivalidades y ligas competitivas manteniendo vibrante al estado. El Memorial Stadium y el Ryan Field son templos del deporte, donde cada juego es una experiencia inolvidable.",
      "El baloncesto también es prominente, con el estado produciendo regularmente talento que sobresale tanto a nivel nacional como internacional. Los Fighting Illini de Illinois tienen una rica tradición en baloncesto y son una amenaza constante en los torneos de la NCAA.",
      "Desde colegios pequeños hasta grandes universidades, el deporte en Illinois sirve como un vínculo, una fuente de orgullo y una parte esencial de la cultura e identidad del estado."
  ]
},
{
  "name": "Indiana",
  "description": [
      "Indiana es conocida por su amor arraigado por el baloncesto, y esto es evidente en la cultura de los deportes universitarios del estado. La rivalidad, la pasión y la dedicación tanto de jugadores como de fanáticos son incomparables.",
      "Programas como los Indiana Hoosiers y los Purdue Boilermakers son reconocidos a nivel nacional, especialmente en baloncesto. Una atmósfera eléctrica se cierne en lugares como Assembly Hall y Mackey Arena durante todo el año, donde la historia y la tradición se unen.",
      "Si bien el baloncesto lleva la delantera, otros deportes también prosperan en Indiana. El fútbol americano, el béisbol y el atletismo tienen programas sólidos y disfrutan de un amplio apoyo comunitario.",
      "El deporte en Indiana es más que solo un juego; es un estilo de vida, un catalizador para el espíritu comunitario y una tradición orgullosa transmitida de generación en generación. Aquí, en el corazón del Medio Oeste, los deportes universitarios son una parte integral de la identidad del estado."
  ]
},
  
{
  "name": "Iowa",
  "description": [
      "Los deportes universitarios en Iowa son una fuente de orgullo y espíritu comunitario, con una rica tradición tanto en fútbol americano como en lucha libre. La rivalidad entre los Iowa Hawkeyes y los Iowa State Cyclones es central y proporciona momentos inolvidables en cada temporada.",
      "El Estadio Kinnick en Iowa City y el Estadio Jack Trice en Ames son los lugares para intensos juegos de fútbol americano cada otoño, atrayendo a decenas de miles de fanáticos. La pasión y dedicación de los fanáticos son características de la cultura deportiva del estado.",
      "La lucha libre es otro deporte en el que Iowa sobresale, con los Hawkeyes ganando regularmente títulos nacionales. Este deporte tiene una tradición arraigada en Iowa y disfruta de un apoyo leal.",
      "Desde pequeños colegios hasta grandes universidades, los deportes en Iowa son más que competencia; son un estilo de vida, una fuente de espíritu comunitario y una parte importante de la identidad del estado."
  ]
},
{
  "name": "Kansas",
  "description": [
      "Los deportes universitarios en Kansas están impregnados de tradición y espíritu comunitario, con el baloncesto como el punto culminante indiscutible. El estado es hogar de algunos de los programas más emblemáticos del país, y la rivalidad y pasión son intensas.",
      "Los Jayhawks de Kansas y los Wildcats de Kansas State lideran la carga, con su anual 'Sunflower Showdown' siendo uno de los puntos destacados del año deportivo. El Allen Fieldhouse, hogar de los Jayhawks, es conocido como uno de los escenarios más intimidantes en el baloncesto universitario.",
      "Si bien el baloncesto forma el corazón de la cultura deportiva en Kansas, el fútbol americano, el béisbol y el atletismo también juegan un papel importante. Los fanáticos son inigualables en su dedicación y contribuyen a la atmósfera vibrante durante los juegos.",
      "Los deportes en Kansas son más que solo competencia; son una fuente de orgullo, un catalizador para la comunidad y una parte esencial de la identidad del estado."
  ]
},
{
  "name": "Kentucky",
  "image": Kentucky,
  "imageDescription": "Karl-Anthony Towns y Devin Booker jugaron juntos para Kentucky y luego se convirtieron en grandes estrellas de la NBA",
  "description": [
      "Los deportes universitarios en Kentucky son sinónimo de baloncesto, con una rica tradición y una base de fanáticos apasionados. La rivalidad entre los Kentucky Wildcats y los Louisville Cardinals es una de las más intensas y seguidas en el país.",
      "El Rupp Arena, hogar de los Wildcats, es un verdadero templo del baloncesto, donde la historia y el orgullo del programa son palpables. Los Wildcats tienen múltiples campeonatos nacionales a su nombre y son una institución en el baloncesto universitario.",
      "Además del baloncesto, Kentucky también tiene programas sólidos en fútbol americano, deportes ecuestres y tiro, todos los cuales enriquecen la cultura deportiva del estado. El Derby de Kentucky, una carrera de caballos legendaria, subraya la rica tradición en los deportes ecuestres.",
      "En Kentucky, los deportes son más que un pasatiempo; son un estilo de vida, una fuente de comunidad y orgullo, y una parte crucial de la identidad del estado."
  ]
},
{
  "name": "Louisiana",
  "description": [
      "En Luisiana, los deportes universitarios son una parte integral de la cultura, con una pasión arraigada por el fútbol americano y el béisbol. Los Tigers de la Louisiana State University (LSU) son una potencia, atrayendo multitudes masivas y creando una atmósfera incomparable.",
      "'Tiger Stadium', también conocido como 'Death Valley', es famoso por sus fanáticos ruidosos y su atmósfera intimidante, lo que lo convierte en uno de los campos locales más temidos en el fútbol americano universitario. 'Saturday Night in Death Valley' es más que un juego; es un evento, una tradición, una parte del alma de Luisiana.",
      "Además de LSU, universidades y colegios más pequeños también contribuyen al rico panorama deportivo, con programas sólidos en varios deportes. El béisbol también goza de gran popularidad, con LSU compitiendo regularmente por el título nacional.",
      "Los deportes en Luisiana son una celebración de la comunidad, una fuente de orgullo y un vínculo que une a las personas, independientemente de su origen. Es una parte vibrante y esencial del estilo de vida y la identidad del estado."
  ]
},
  
{
  "name": "Maine",
  "description": [
      "Los deportes universitarios en Maine reflejan la naturaleza única del estado y el sentido de comunidad, con un fuerte enfoque en los deportes de invierno y atletismo. La Universidad de Maine Black Bears lidera el camino, representando con orgullo en varias divisiones de la NCAA.",
      "El Estadio Alfond en Orono es el corazón del fútbol americano en el estado, mientras que el Harold Alfond Sports Arena juega un papel central en la temporada de hockey sobre hielo. Los Black Bears tienen una orgullosa tradición en hockey sobre hielo, con entusiastas fanáticos desafiando el frío para apoyar a su equipo.",
      "Además del hockey sobre hielo y el fútbol americano, también hay programas sólidos en atletismo, remo y otros deportes que enriquecen el panorama atlético de Maine. El estado también disfruta de vibrantes competiciones deportivas de secundaria y clubes, lo que hace que el deporte sea una parte integral de la comunidad.",
      "Los deportes en Maine son más que solo competencia; son una fuente de orgullo, una forma de celebrar el espíritu único del estado y un vínculo que une a las personas, independientemente de la temporada."
  ]
},
{
  "name": "Maryland",
  "description": [
      "Los deportes universitarios en Maryland prosperan con una fuerte tradición en baloncesto, lacrosse y fútbol americano. La Universidad de Maryland Terrapins, o simplemente los Terps, son centrales para la cultura deportiva del estado.",
      "Xfinity Center y Maryland Stadium son los orgullosos escenarios para baloncesto y fútbol americano, donde los Terps juegan sus partidos en casa. Los fanáticos son fervientes y contribuyen a una atmósfera electrizante durante los juegos. Maryland es conocido por su programa competitivo de baloncesto, con múltiples apariciones en el torneo de la NCAA.",
      "El lacrosse es otro deporte en el que Maryland sobresale, con los Terps compitiendo regularmente por títulos nacionales. Este deporte ocupa un lugar especial en los corazones de los residentes, y las intensas rivalidades universitarias hacen que cada temporada sea un espectáculo.",
      "Los deportes en Maryland son una expresión de comunidad, orgullo y tradición, desempeñando un papel crucial en la unión de las personas y en la celebración de la cultura única del estado."
  ]
},
{
  "name": "Massachusetts",
  "description": [
      "En Massachusetts, los deportes universitarios juegan un papel significativo en la vida cotidiana, con una rica historia en baloncesto, fútbol americano y hockey. Las universidades aquí tienen programas deportivos sólidos y fanáticos apasionados.",
      "El baloncesto tiene un lugar especial en los corazones de los residentes de Massachusetts, ya que el juego fue inventado aquí. Los Minutemen de la Universidad de Massachusetts, conocidos como los UMass Minutemen, son orgullosos representantes en la División I de la NCAA en baloncesto.",
      "El fútbol americano y el hockey también son populares, con intensas rivalidades locales que proporcionan una competencia emocionante. El juego de fútbol americano Harvard-Yale, conocido como 'The Game', es un punto culminante anual.",
      "Los deportes en Massachusetts son sobre tradición, comunidad y competencia. Une a las personas, independientemente de su origen, y es una parte esencial de la identidad cultural del estado."
  ]
},
{
  "name": "Michigan",
  "image": Michigan,
  "imageDescription": "Michigan Stadium: el tercer estadio más grande del mundo y hogar de los Michigan Wolverines. Puede albergar a 107,601 fanáticos.",
  "description": [
      "Michigan tiene una rica tradición en deportes universitarios, especialmente en fútbol americano y baloncesto. La rivalidad entre los Michigan Wolverines de la Universidad de Michigan y los Michigan State Spartans es legendaria y crea una atmósfera eléctrica en todo el estado.",
      "Michigan Stadium, también conocido como 'The Big House', es uno de los estadios más grandes del mundo, con capacidad para más de 100,000 fanáticos. Los Wolverines tienen una historia gloriosa, con múltiples títulos nacionales en fútbol americano.",
      "El baloncesto también es muy popular, con tanto Michigan como Michigan State compitiendo regularmente en el torneo de la NCAA. La pasión y dedicación de los fanáticos son incomparables, y los días de juego son verdaderos eventos en Michigan.",
      "Además de estos deportes principales, Michigan también tiene programas sólidos en hockey sobre hielo, atletismo y otros deportes. Los deportes en Michigan son más que un pasatiempo; son una parte integral del estilo de vida y la identidad del estado."
  ]
},
    
{
  "name": "Minnesota",
  "description": [
      "Los deportes universitarios en Minnesota tienen un carácter único, con un fuerte énfasis en el hockey sobre hielo, el fútbol americano y el baloncesto. La Universidad de Minnesota Golden Gophers juega un papel central en la comunidad deportiva del estado.",
      "El Estadio TCF Bank y el Williams Arena son centros bulliciosos de actividad durante las temporadas de fútbol americano y baloncesto, mientras que el Mariucci Arena es un bastión para el hockey sobre hielo. Los Golden Gophers son orgullosos representantes en la NCAA, con una rica historia y fanáticos dedicados.",
      "El hockey sobre hielo es particularmente popular en Minnesota, a menudo referido como 'El Estado del Hockey'. Los equipos universitarios disfrutan de un fuerte apoyo y proporcionan emocionante acción, especialmente durante el famoso torneo de la NCAA 'Frozen Four'.",
      "Los deportes en Minnesota están profundamente entrelazados con la cultura e identidad del estado, ofreciendo una fuente de orgullo, espíritu comunitario y animadas rivalidades que reúnen a personas de todas las edades."
  ]
},
{
  "name": "Mississippi",
  "description": [
      "Los deportes universitarios son una parte integral de la cultura en Mississippi, con una intensa pasión por el fútbol americano, el baloncesto y el béisbol. La rivalidad entre los Rebeldes de la Universidad de Mississippi (Ole Miss) y los Bulldogs de la Universidad Estatal de Mississippi es una de las más intensas del país.",
      "El Estadio Vaught-Hemingway en Oxford y el Estadio Davis Wade en Starkville son verdaderos templos para los fanáticos del fútbol americano, donde la tensión y pasión del juego son palpables. El Bowl del Huevo anual, el choque entre Ole Miss y Mississippi State, es un punto culminante en el calendario deportivo.",
      "El baloncesto y el béisbol también son significativos, con ambas universidades construyendo equipos y tradiciones sólidas. Los fanáticos son leales y vocales, creando una atmósfera eléctrica en los juegos.",
      "Los deportes en Mississippi se tratan de comunidad, orgullo y tradición. Reúne a las personas, crea momentos inolvidables y es una parte esencial de la identidad del estado."
  ]
},
{
  "name": "Missouri",
  "description": [
      "Los deportes universitarios en Missouri son sinónimo de pasión, comunidad y competencia. El estado tiene una rica historia deportiva, con el fútbol americano, baloncesto y béisbol a la cabeza.",
      "Los Tigres de la Universidad de Missouri, también conocidos como Mizzou, y los Osos del Estado de Missouri son dos prominentes representantes en el panorama deportivo universitario. El Estadio Faurot Field y el Mizzou Arena son epicentros de emoción y espíritu de equipo en los días de juego.",
      "Los Tigres tienen una fuerte presencia en la SEC, una de las conferencias más competitivas en el fútbol americano universitario. Su equipo de baloncesto también es un elemento fijo en el torneo de la NCAA, con los fanáticos brindando un apoyo inquebrantable.",
      "Los deportes en Missouri fortalecen los lazos comunitarios y reúnen a las personas. No se trata solo del juego; se trata de la experiencia, las tradiciones y el vínculo inquebrantable entre los equipos y sus seguidores."
  ]
},
{
  "name": "Montana",
  "description": [
      "En Montana, los deportes universitarios juegan un papel crucial en la vida comunitaria, con el fútbol americano, baloncesto y rodeo como deportes populares. Los Grizzlies de Montana y los Bobcats de Montana State dominan el panorama deportivo, y su rivalidad es intensa.",
      "El Estadio Washington-Grizzly de los Grizzlies y el Estadio Bobcat de los Bobcats son orgullosas arenas de fútbol en Montana. Aquí, los fanáticos se reúnen para apoyar a sus equipos, independientemente del clima. El juego anual Brawl of the Wild entre los Grizzlies y los Bobcats es un evento que no te puedes perder.",
      "El baloncesto también es popular, con juegos animados y una sólida base de fanáticos. El rodeo, único en Montana, también atrae mucha atención y aprecio, reflejando la cultura y tradiciones del estado.",
      "Los deportes universitarios en Montana son sobre comunidad, orgullo y amor por el juego. Reúnen a las personas, crean recuerdos para toda la vida y contribuyen a la identidad única del Big Sky Country."
  ]
},
    
{
  "name": "Nebraska",
  "description": [
      "En Nebraska, los deportes universitarios se caracterizan por una profunda pasión y lealtad, con un enfoque especial en el fútbol americano. Los Cornhuskers de Nebraska, con sede en la Universidad de Nebraska-Lincoln, son el corazón de la cultura deportiva del estado.",
      "El Estadio Memorial, hogar de los Cornhuskers, también se llama 'El Mar de Rojo', donde los fanáticos con su distintivo atuendo rojo se reúnen para apoyar a su equipo. El estadio es conocido por su ensordecedor ruido y atmósfera única en los días de juego.",
      "Además del fútbol americano, el baloncesto también juega un papel significativo, con equipos tanto masculinos como femeninos compitiendo a un alto nivel. Los fanáticos son igualmente dedicados y crean una atmósfera vibrante en cada juego.",
      "Los deportes universitarios en Nebraska van más allá del deporte en sí; es una forma de unir comunidades, celebrar tradiciones y crear un sentido de orgullo y unión en 'The Cornhusker State'."
  ]
},
{
  "name": "Nevada",
  "description": [
      "Los deportes universitarios en Nevada ofrecen una combinación única de entretenimiento y competencia, con especial atención al baloncesto y al fútbol americano. La Universidad de Nevada, Las Vegas (UNLV), y la Universidad de Nevada, Reno son las dos principales instituciones en deportes.",
      "El equipo de baloncesto de UNLV, los Runnin' Rebels, tiene una rica historia y es conocido a nivel nacional. Sus juegos en casa son una verdadera atracción, completos con actuaciones enérgicas y un público entusiasta. El equipo de fútbol americano de los Nevada Wolf Pack también contribuye a la cultura deportiva en el estado, con juegos emocionantes y una base de fanáticos leales.",
      "En Nevada, también hay un vivo interés en otros deportes universitarios como béisbol, softbol y fútbol. Estos deportes atraen a las comunidades locales y brindan amplias oportunidades para que los atletas se desarrollen.",
      "En general, los deportes universitarios en Nevada son una parte esencial del estado, que proporciona no solo entretenimiento, sino también une a las comunidades y enriquece la cultura local."
  ]
},
{
  "name": "New Hampshire",
  "description": [
      "En el pintoresco estado de New Hampshire, los deportes universitarios juegan un papel crucial en la vida comunitaria. Aunque el estado es más pequeño que muchos otros, la dedicación al deporte es inmensa.",
      "La Universidad de New Hampshire (UNH) domina el panorama deportivo, especialmente en hockey, donde los Wildcats consistentemente tienen un alto rendimiento. El Whittemore Center Arena, el estadio local del equipo de hockey, es conocido por su atmósfera vibrante y fanáticos apasionados.",
      "El fútbol americano y el baloncesto también son deportes populares dentro de la UNH, atrayendo a grandes multitudes durante la temporada. La comunidad se enorgullece de sus equipos, y los estudiantes están muy involucrados en los eventos deportivos.",
      "El enfoque de New Hampshire en los deportes universitarios está centrado en la comunidad, con el objetivo de unir a las personas, celebrar los éxitos y crear momentos inolvidables en los pintorescos alrededores del estado."
  ]
},
{
  "name": "New Jersey",
  "description": [
      "Los deportes universitarios en Nueva Jersey disfrutan de una atmósfera vibrante y competitiva, con varias universidades compitiendo a un alto nivel. El estado alberga algunos programas líderes que son reconocidos tanto regional como nacionalmente.",
      "Uno de los equipos universitarios más prominentes es el Rutgers Scarlet Knights, parte de la prestigiosa Big Ten Conference. Sus juegos de fútbol americano y baloncesto atraen a grandes multitudes y generan entusiasmo en todo el estado.",
      "Nueva Jersey también es conocido por sus sólidos programas de lacrosse y fútbol, atrayendo y desarrollando talento joven. Estos deportes juegan un papel significativo en la cultura deportiva universitaria del estado, con competencias emocionantes y fanáticos dedicados.",
      "La comunidad en Nueva Jersey abraza a sus equipos deportivos universitarios, lo que resulta en una atmósfera de apoyo y pasión en cada evento. Los deportes universitarios en Nueva Jersey son más que solo juegos; son una parte esencial del tejido cultural del estado."
  ]
},
    
    
{
  "name": "New Mexico",
  "description": [
      "Nuevo México, con su singular trasfondo cultural y geográfico, tiene una relación especial con los deportes universitarios. El estado es hogar de algunos programas deportivos universitarios prominentes que compiten a nivel nacional.",
      "Los Lobos de la Universidad de Nuevo México en Albuquerque son un punto central de orgullo. Su equipo de baloncesto atrae regularmente a grandes multitudes a The Pit, uno de los estadios más intimidantes del país.",
      "Los Aggies del Estado de Nuevo México en Las Cruces también son contendientes nacionales, especialmente en baloncesto y fútbol americano, donde tienen feroces rivalidades con los Lobos, que culminan en la Rivalidad del Río Grande.",
      "Respaldados por una rica historia y una base de aficionados apasionada, los deportes universitarios en Nuevo México no solo brindan entretenimiento, sino que también promueven el espíritu comunitario y la identidad estatal. En este estado desértico, el deporte es una celebración del talento, la perseverancia y los vínculos comunitarios."
  ]
},
{
  "name": "New York",
  "description": [
      "En el bullicioso estado de Nueva York, los deportes universitarios juegan un papel dinámico y versátil. El estado es hogar de numerosas universidades, cada una con sus propias tradiciones deportivas únicas.",
      "La Universidad de Siracusa se destaca con su equipo de baloncesto, los Orange, que a menudo compiten a nivel nacional más alto. El estadio Carrier Dome, conocido por su impresionante capacidad y atmósfera animada, es un ícono en el mundo de los deportes universitarios.",
      "Además, las universidades y colegios más pequeños también juegan un papel significativo, con programas sólidos en deportes como lacrosse, fútbol y béisbol. Estos programas más pequeños fomentan el espíritu competitivo en el estado, creando un paisaje deportivo rico y diverso.",
      "La participación comunitaria en los deportes universitarios en Nueva York es inmensa, con aficionados apoyando a sus equipos en las buenas y en las malas. Desde las bulliciosas ciudades hasta las áreas rurales más tranquilas, el deporte une a los neoyorquinos e inculca un sentido de orgullo y unión."
  ]
},
{
  "name": "North Carolina",
  "image": North_Carolina,
  "imageDescription": "La rivalidad icónica: DUKE vs. UNC en acción",
  "description": [
      "Carolina del Norte tiene una rica tradición y una pasión incomparable cuando se trata de deportes universitarios, especialmente en baloncesto. La rivalidad entre los Tar Heels de la Universidad de Carolina del Norte y los Blue Devils de la Universidad Duke es mundialmente famosa y garantiza enfrentamientos emocionantes.",
      "Los Tar Heels y los Blue Devils no solo dominan la temporada de baloncesto, sino que también traen una ola de emoción y espíritu comunitario en todo el estado. Es una cultura deportiva profundamente arraigada en la identidad de Carolina del Norte.",
      "Además del baloncesto, el fútbol americano también juega un papel importante, con equipos fuertes y bases de aficionados apasionadas. Y en la primavera, el amor por el béisbol florece, con equipos universitarios y universitarios compitiendo en los campos.",
      "Los deportes universitarios en Carolina del Norte van más allá de los campos y salones deportivos; son una forma de vida, una fuente de orgullo y un medio para que las comunidades se unan y celebren su estado."
  ]
},
{
  "name": "North Dakota",
  "description": [
      "Los deportes universitarios en Dakota del Norte ofrecen una experiencia única, con comunidades involucradas estrechamente con sus equipos universitarios. El sentido de orgullo y unión es claramente visible durante los eventos deportivos, a pesar de que el estado tiene menos universidades prominentes que otras regiones estadounidenses.",
      "El equipo de fútbol americano de la Universidad Estatal de Dakota del Norte, los Bison, es conocido por sus éxitos en la Subdivisión de Campeonato de Fútbol de la División I de la NCAA, lo que da un impulso significativo a la cultura deportiva del estado. Los aficionados se reúnen en el Fargodome para apoyar a su equipo, creando una atmósfera intensa.",
      "El hockey sobre hielo también juega un papel importante en Dakota del Norte, con los Fighting Hawks de la Universidad de Dakota del Norte como equipo líder. La pasión por este deporte de invierno es evidente durante la temporada, con aficionados acudiendo en masa al estadio.",
      "Los deportes universitarios en Dakota del Norte, especialmente el fútbol americano y el hockey sobre hielo, unen a las comunidades, crean un fuerte orgullo local y contribuyen a la vibrante cultura deportiva del estado."
  ]
},

{
  "name": "Ohio",
  "description": [
      "Los deportes universitarios son una parte integral de la cultura en Ohio, con un énfasis especial en el fútbol americano y el baloncesto. La Universidad Estatal de Ohio (OSU) y la Universidad de Cincinnati son dos instituciones líderes en deportes.",
      "El equipo de fútbol americano de OSU, los Buckeyes, goza de fama nacional y es una verdadera fuerza en la NCAA. Sus juegos en el masivo Ohio Stadium atraen a más de 100,000 fanáticos, creando una atmósfera sin igual durante los juegos en casa. 'O-H-I-O' es un cántico conocido en todo el estado.",
      "El baloncesto también tiene un lugar destacado, con equipos tanto masculinos como femeninos compitiendo a un alto nivel. El estado de Ohio es hogar de múltiples equipos universitarios que participan regularmente en el torneo de la NCAA, sumándose a la fiebre del baloncesto.",
      "Los deportes universitarios en Ohio ofrecen más que solo competencia; son una fuente de orgullo, tradición y espíritu comunitario, uniendo a personas de todos los ámbitos de la vida."
  ]
},
{
  "name": "Oklahoma",
  "description": [
      "En Oklahoma, los deportes universitarios ocupan un lugar central en los corazones de sus residentes, con una pasión arraigada por el fútbol americano. La Universidad de Oklahoma (OU) y la Universidad Estatal de Oklahoma (OSU) son las joyas del mundo deportivo del estado.",
      "El equipo de fútbol americano de OU, los Sooners, tiene una rica historia y numerosos éxitos, creando una atmósfera vibrante en torno a sus juegos. El Gaylord Family Oklahoma Memorial Stadium es una fortaleza para el equipo, donde los fanáticos gritan en voz alta 'Boomer Sooner'.",
      "OSU no se queda atrás de OU, con sólidas actuaciones tanto en fútbol americano como en otros deportes, donde su equipo de fútbol americano, los Cowboys, juega un papel significativo. La dedicación de los fanáticos durante la 'Serie Bedlam', el enfrentamiento anual entre OU y OSU, es enorme.",
      "Los deportes universitarios en Oklahoma simbolizan la lucha, la perseverancia y el sentido de comunidad del estado, haciéndolo mucho más que solo un juego."
  ]
},
{
  "name": "Oregon",
  "description": [
      "Los deportes universitarios en Oregón son una parte esencial del estado, con un enfoque tanto en el fútbol americano como en el atletismo. La Universidad de Oregón (UO) en Eugene es central en esta pasión deportiva y ha construido una reputación nacional.",
      "El equipo de fútbol americano de UO, los Ducks, son conocidos por su juego rápido y sus uniformes únicos, mientras que el programa de atletismo es uno de los mejores del país. El evento atlético anual Prefontaine Classic, celebrado en el famoso Hayward Field, destaca la dedicación de Oregón al atletismo.",
      "La Universidad Estatal de Oregón (OSU) con su equipo de fútbol americano, los Beavers, proporciona una fuerte competencia, especialmente durante la 'Guerra Civil', el choque anual con los Ducks. Esto es más que un juego; es una tradición estatal que une comunidades.",
      "Además de estos deportes, el baloncesto también goza de popularidad, y los fanáticos apasionados hacen que los deportes universitarios en Oregón sean una experiencia que no querrás perderte. Es una celebración del talento atlético, la comunidad y la cultura deportiva única del estado."
  ]
},
{
  "name": "Pennsylvania",
  "description": [
      "Pensilvania tiene una profunda apreciación por los deportes universitarios, con un fuerte énfasis en el fútbol americano, el baloncesto y el atletismo. Equipos como los Nittany Lions de la Universidad Estatal de Pensilvania y los Panthers de Pittsburgh dominan el panorama deportivo y crean emocionantes rivalidades.",
      "Beaver Stadium, el hogar de los Nittany Lions, es conocido por su abrumadora atmósfera y experiencia intensa para los fanáticos, especialmente durante los juegos de 'White Out'. Estos momentos son el punto culminante del calendario deportivo y atraen a fanáticos de todo el estado.",
      "En la cancha de baloncesto, los Villanova Wildcats han logrado recientemente el éxito nacional, con múltiples campeonatos que han emocionado al estado. Sus actuaciones han puesto a Pensilvania en el mapa como un gigante del baloncesto.",
      "El atletismo también juega un papel crucial, con los prestigiosos Penn Relays atrayendo a los mejores atletas a Filadelfia cada año. Esta rica cultura deportiva fortalece el espíritu comunitario y contribuye al orgullo de Pensilvania."
  ]
},
{
  "name": "Rhode Island",
  "description": [
      "A pesar de su pequeño tamaño, Rhode Island tiene una vibrante escena de deportes universitarios, siendo notablemente populares el baloncesto y la navegación. El estado tiene equipos que juegan con corazón y alma, creando momentos emocionantes.",
      "Equipos como los Rhode Island Rams y los Providence Friars tienen una base de fanáticos leales y ofrecen juegos cautivadores. El Dunkin' Donuts Center en Providence se convierte en un bullicioso estadio durante los juegos de baloncesto, con los locales acudiendo en masa para apoyar a sus equipos.",
      "Además del baloncesto, Rhode Island es conocido por sus destacados programas de navegación, con la hermosa costa que proporciona condiciones perfectas para el deporte. Universidades como Brown y URI tienen sólidos equipos de navegación que compiten a nivel nacional.",
      "Estos deportes contribuyen a la vibrante cultura de Rhode Island, donde cada juego es una oportunidad para celebrar el espíritu único de este pequeño pero orgulloso estado."
  ]
},


  {
      "name": "South Carolina",
      "description": [
          "Los deportes universitarios en Carolina del Sur respiran tradición y pasión, con el fútbol americano y el baloncesto en el centro de atención. La intensa rivalidad entre los Gamecocks de la Universidad de Carolina del Sur y los Tigres de la Universidad de Clemson domina el panorama deportivo.",
          "Durante el enfrentamiento anual de fútbol americano, 'The Palmetto Bowl', todo el estado cobra vida, con la línea entre el granate y el naranja claramente marcada. La energía y pasión de los fanáticos son inigualables, haciendo que cada juego sea una experiencia inolvidable.",
          "El baloncesto también tiene un lugar destacado en los corazones de los fanáticos de los deportes en Carolina del Sur, con equipos compitiendo en lo más alto en las ligas masculinas y femeninas. Por ejemplo, el equipo de baloncesto femenino de los Gamecocks ha logrado el éxito nacional, mejorando aún más el orgullo y la participación de la comunidad.",
          "La cultura deportiva en Carolina del Sur va más allá de los campos de juego; es un modo de vida, una fuente de orgullo y una parte indispensable de la identidad del estado."
      ]
  },
  {
      "name": "South Dakota",
      "description": [
          "Los deportes universitarios en Dakota del Sur ofrecen una experiencia única y apasionada, con un énfasis en el baloncesto, el fútbol americano y el atletismo. Los Jackrabbits de la Universidad Estatal de Dakota del Sur y los Coyotes de la Universidad de Dakota del Sur son jugadores destacados en el panorama deportivo.",
          "Los juegos de baloncesto, especialmente durante los encuentros entre los Jackrabbits y los Coyotes, atraen a multitudes entusiastas y crean una atmósfera electrizante. El estado apoya tanto a los equipos masculinos como femeninos, siendo las actuaciones en el campo una fuente de orgullo para las comunidades locales.",
          "El fútbol americano también goza de gran popularidad, con emocionantes juegos y un fuerte compromiso de los fanáticos. Los programas atléticos en Dakota del Sur sobresalen, con atletas compitiendo a nivel nacional y representando con orgullo al estado.",
          "Los deportes universitarios en Dakota del Sur son más que solo juegos; son una parte esencial de la comunidad, fortaleciendo los lazos entre los residentes y contribuyendo a la vibrante cultura del estado."
      ]
  },
  {
      "name": "Tennessee",
      "description": [
          "En Tennessee, los deportes universitarios juegan un papel crucial en la comunidad, con una rica tradición en fútbol americano, baloncesto y atletismo. Los Volunteers de la Universidad de Tennessee y los Commodores de la Universidad de Vanderbilt son equipos líderes en el panorama deportivo del estado.",
          "Los Volunteers, con su icónico color naranja, atraen a grandes multitudes al Neyland Stadium, uno de los estadios de fútbol americano más grandes del país. La pasión de los fanáticos es incomparable, y 'Rocky Top' resuena fuertemente durante cada juego.",
          "El baloncesto también es una gran atracción en Tennessee, con equipos tanto masculinos como femeninos recibiendo reconocimiento nacional. El estado tiene una rica historia en la producción de atletas de élite y ha contribuido al crecimiento del deporte a nivel nacional.",
          "Los deportes universitarios en Tennessee son una parte esencial de la cultura e identidad del estado, reuniendo a las personas, celebrando tradiciones e inspirando a la próxima generación de atletas."
      ]
  },
  {
      "name": "Texas",
      "image": Texas,
      "imageDescription": "Rivalidad Tradicional: Texas Longhorns contra Texas A&M",
      "description": [
          "Los deportes universitarios en Texas son sinónimo de tamaño, pasión y rica tradición, con el fútbol americano a la cabeza. Equipos como los Longhorns de Texas y los Aggies de la Universidad A&M de Texas tienen seguidores masivos, y sus juegos son puntos destacados de la temporada.",
          "El Estadio Darrell K Royal–Texas Memorial en Austin y Kyle Field en College Station son templos deportivos donde cada juego en casa se convierte en un evento espectacular. La rivalidad entre varios equipos universitarios es intensa y contribuye a la cultura deportiva única del estado.",
          "Además del fútbol americano, el baloncesto y el béisbol también son populares, con programas sólidos compitiendo a nivel nacional. El apoyo a los deportes femeninos está creciendo constantemente, con el baloncesto y el atletismo destacándose en particular.",
          "Los deportes universitarios en Texas son más que un simple pasatiempo; es un modo de vida, una fuente de orgullo y una fuerza unificadora que une a las comunidades."
      ]
  },


  {
    "name": "Utah",
    "description": [
        "En Utah, los deportes universitarios ocupan un lugar significativo dentro de la comunidad, con el baloncesto y el fútbol americano como los deportes más populares. La Universidad de Utah Utes y los Brigham Young University Cougars participan en una feroz competencia en diversas disciplinas.",
        "El duelo anual de fútbol americano entre los Utes y los Cougars, conocido como la 'Guerra Santa', es uno de los eventos deportivos más esperados en el estado. Reúne a los fanáticos para celebrar el espíritu deportivo, la rivalidad y la gloria del estado.",
        "El baloncesto también tiene una rica tradición en Utah, con temporadas emocionantes y momentos memorables tanto en las ligas masculinas como femeninas. Los equipos luchan por la excelencia y el apoyo de los fanáticos es inquebrantable.",
        "El impacto de los deportes universitarios en Utah va más allá del campo de juego. Promueve el espíritu comunitario, el orgullo escolar y contribuye significativamente a la identidad cultural del estado."
    ]
},
{
    "name": "Vermont",
    "description": [
        "En Vermont, donde predomina la naturaleza y las comunidades son cercanas, los deportes universitarios desempeñan un papel único en unir a las personas. Los deportes de invierno como el esquí y el snowboard tienen un lugar destacado, dada la orografía montañosa y el clima frío.",
        "La Universidad de Vermont Catamounts es el centro de la escena deportiva universitaria, con equipos destacados en diversos deportes, incluyendo hockey sobre hielo y baloncesto. Los Catamounts tienen una base de seguidores leales y la atmósfera en los juegos en casa es intensa.",
        "El baloncesto goza de gran popularidad y los juegos de los Catamounts son imperdibles para los entusiastas del deporte. El equipo ha participado en el torneo de la NCAA en múltiples ocasiones, poniendo al estado en el mapa en el mundo del baloncesto universitario.",
        "Los deportes universitarios en Vermont son más que solo un juego; son una forma de unir a las comunidades, desarrollar talento y celebrar la cultura deportiva única del estado."
    ]
},
{
    "name": "Virginia",
    "description": [
        "En Virginia, los deportes universitarios son una parte integral de la cultura local, con un fuerte enfoque en el fútbol americano, el baloncesto y la creciente popularidad del fútbol. Los Cavaliers de Virginia y los Hokies de Virginia Tech son equipos universitarios líderes que aportan pasión y rivalidad al campo.",
        "El fútbol americano atrae a multitudes de fanáticos a los estadios, donde la atmósfera en los días de juego es eléctrica. La inversión en programas de baloncesto también ha dado sus frutos, con equipos compitiendo a nivel nacional y con una base de seguidores dedicada.",
        "El fútbol está ganando terreno en Virginia, con equipos tanto masculinos como femeninos que se están convirtiendo en fuerzas competitivas. Las universidades están invirtiendo en instalaciones y entrenadores, mientras que las academias locales producen jóvenes talentos.",
        "Esta mezcla de deportes tradicionales y emergentes enriquece la escena deportiva universitaria en Virginia, crea una comunidad deportiva vibrante y proporciona a atletas y fanáticos experiencias inolvidables y un sentido de orgullo y unidad."
    ]
},
{
    "name": "Washington",
    "description": [
        "Los deportes universitarios en Washington prosperan con una combinación de tradición y entusiasmo, con el fútbol americano, el baloncesto y el remo ocupando un lugar central. Los Huskies de la Universidad de Washington y los Cougars de la Universidad Estatal de Washington son pioneros de la cultura deportiva del estado.",
        "El Estadio Husky en Seattle y el Estadio Martin en Pullman están llenos de emoción durante los juegos de fútbol americano, donde los fanáticos de toda el área se reúnen para apoyar a sus equipos. La 'Apple Cup', el enfrentamiento anual entre los Huskies y los Cougars, es un punto destacado de la rivalidad.",
        "El baloncesto también juega un papel importante, con equipos tanto masculinos como femeninos compitiendo a un alto nivel. Los Huskies tienen un legado sólido en este deporte y atraen a seguidores apasionados.",
        "El remo es otra disciplina notable, con los Huskies ganando numerosos campeonatos nacionales. Los deportes universitarios en Washington reflejan la diversidad y pasión del estado, donde el compañerismo, la competencia y la camaradería son muy valorados."
    ]
},
{
    "name": "West Virginia",
    "description": [
        "Los deportes universitarios son el corazón latente de Virginia Occidental, con un fuerte énfasis en el fútbol americano y el baloncesto. Los Montañeros de la Universidad de Virginia Occidental son los orgullosos representantes del estado, y sus juegos unen a la comunidad.",
        "El Estadio Milan Puskar en Morgantown cobra vida durante los juegos en casa de los Montañeros, con fanáticos vistiendo los icónicos colores dorado y azul y animando ruidosamente. El 'Backyard Brawl', una rivalidad histórica con la Universidad de Pittsburgh, añade emoción extra a la temporada.",
        "El baloncesto es otra pasión, con juegos emocionantes que mantienen a los fanáticos al borde de sus asientos. Los Montañeros tienen equipos tanto masculinos como femeninos compitiendo a nivel nacional.",
        "Los deportes universitarios en Virginia Occidental son una fuente de orgullo y unidad, reflejando el fuerte sentido de comunidad y las tradiciones del estado."
    ]
},
{
    "name": "Wisconsin",
    "description": [
        "Los deportes universitarios están profundamente arraigados en la cultura de Wisconsin Occidental, con el fútbol americano, el baloncesto y el hockey sobre hielo como disciplinas prominentes. Los Badgers de la Universidad de Wisconsin son la fuerza impulsora detrás de la cultura deportiva del estado.",
        "El Estadio Camp Randall en Madison zumba de emoción durante los juegos de fútbol americano, con fanáticos que se reúnen para cantar 'Jump Around' y animar a su equipo. La rivalidad con equipos como los Wolverines de la Universidad de Michigan es legendaria y lleva a partidos intensos.",
        "El baloncesto también tiene una rica tradición, con los Badgers compitiendo regularmente por los primeros lugares en los torneos de la NCAA. El hockey sobre hielo ocupa un lugar especial en el corazón del estado, con juegos emocionantes que iluminan las noches de invierno.",
        "Los deportes universitarios en Wisconsin Occidental van más allá del campo de juego; son una fuente de orgullo, camaradería y fortalecen los vínculos entre las comunidades en el estado."
    ]
},
{
    "name": "Wyoming",
    "description": [
        "Los deportes universitarios en Wyoming tienen un carácter único, con un fuerte énfasis en los deportes al aire libre como el esquí, el snowboard y la montañismo. Los Cowboys y Cowgirls de la Universidad de Wyoming son los estandartes de esta cultura deportiva aventurera.",
        "El Estadio War Memorial en Laramie cobra vida durante los juegos de fútbol americano, donde los fanáticos se reúnen para mostrar su apoyo a los Cowboys. Las impresionantes montañas sirven de telón de fondo para estos eventos deportivos.",
        "La universidad también tiene una fuerte tradición en esquí y snowboard, con atletas que dominan competiciones nacionales e internacionales. La vasta naturaleza salvaje de Wyoming sirve como un patio de recreo para aventureros y entusiastas del aire libre.",
        "Los deportes universitarios en Wyoming celebran la belleza de la naturaleza y el espíritu aventurero del estado, donde el compañerismo, la pasión y el amor por la vida al aire libre se unen."
    ]
}



];

export default states;