/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Softbal1.jpeg";
import Image2 from "assets/images/Sports_Images/Softbal2.jpg";
import Image3 from "assets/images/Sports_Images/Softbal3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'einführung', title: 'Softball-Stipendien in den USA: Wie man in Amerika Softball studiert und spielt', isSubheader: false },
  
    { id: 'Kapitel-1', title: 'Kapitel 1: Geschichte und Entwicklung des Softballs in Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Frühe Jahre und die Rolle von Schulen und Universitäten', isSubheader: true },
    { id: '1.2', title: '1.2 Wachstum und Professionalisierung', isSubheader: true },
  
    { id: 'Kapitel-2', title: 'Kapitel 2: Softball an Universitäten heute', isSubheader: false },
    { id: '2.1', title: '2.1 Wichtige Ligen und Konferenzen', isSubheader: true },
    { id: '2.2', title: '2.2 Top-Programme und angesehene Teams', isSubheader: true },
  
    { id: 'Kapitel-3', title: 'Kapitel 3: Vom College zum Profisport', isSubheader: false },
    { id: '3.1', title: '3.1 Der Weg zum Profisport', isSubheader: true },
    { id: '3.2', title: '3.2 Professioneller Softball und andere Karrieremöglichkeiten', isSubheader: true },
  
    { id: 'Kapitel-4', title: 'Kapitel 4: Internationale Einflüsse und Ereignisse', isSubheader: false },
    { id: '4.1', title: '4.1 Ausländische Einflüsse', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikaner auf der Weltbühne', isSubheader: true },
  
    { id: 'Kapitel-5', title: 'Kapitel 5: Sportstipendien und die Zukunft der Athleten', isSubheader: false },
    { id: '5.1', title: '5.1 Die Bedeutung von Stipendien', isSubheader: true },
    { id: '5.2', title: '5.2 Wie Sportstipendien Amerika helfen können', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };
  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhaltsverzeichnis
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verstecken</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Anzeigen</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>

























          <MKBox id="einführung" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Softball-Stipendien in den USA: Wie man in Amerika Softball studiert und spielt
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Luftaufnahme eines voll besetzten Stadions bei einem College-Softballspiel."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Ein volles Stadion während eines Spiels
      </figcaption>
    </figure>
    Softball ist eine Sportart, die Ende des 19. Jahrhunderts in den Vereinigten Staaten entstand. Es handelt sich um eine Variante von Baseball, jedoch mit einem größeren Ball, einem kleineren Spielfeld und kürzeren Innings. Softball wird von zwei Teams mit je neun Spielern gespielt, die abwechselnd schlagen und verteidigen. Das Ziel ist es, mehr Läufe als das gegnerische Team zu erzielen, indem man nach dem Schlagen des Balls um die vier Bases herumläuft.
    <br />
    <br />
    Softball gewann in den USA schnell an Popularität, insbesondere an Schulen und Universitäten. Es galt als Sportart, die körperliche Fitness, geistige Schärfe und soziale Interaktion förderte. Es zog auch viel Talent und Wettbewerb an, insbesondere auf Hochschulebene. Der Collegiate Softball repräsentiert das höchste Niveau des Softballs in den USA, wo Studenten-Athleten für ihre Universität oder Hochschule antreten. Der Collegiate Softball bietet Prestige, Anerkennung und Stipendien für Spitzenathleten.
    <br />
    <br />
    Sportbeursamerika.nl ist eine Organisation, die sich darauf spezialisiert hat, jungen Athleten Softball-Stipendien zu sichern. Sportbeursamerika.nl verfügt über ein Expertenteam, das Sie bei der Auswahl der besten Universität oder Hochschule basierend auf Ihrem akademischen und sportlichen Profil unterstützen kann. Sie können Ihnen auch bei der Vorbereitung Ihrer Bewerbung, der Erstellung Ihres Videos, der Kontaktaufnahme mit Trainern und der Organisation Ihres Visums helfen. Sportbeursamerika.nl hat bereits vielen Erfolgsgeschichten geholfen, ihren Traum vom Studium und Spiel von Softball in Amerika zu verwirklichen.
    <br />
    <br />
    Wenn Sie mehr über Softball in Amerika und den Erhalt eines Softball-Stipendiums erfahren möchten, lesen Sie weiter!
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>


<MKBox id="Kapitel 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Geschichte und Entwicklung des Softballs in Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Softball ist eine Sportart, die in den Vereinigten Staaten ihren Ursprung hat, sich aber weltweit verbreitet hat. Es ist eine Sportart, die viele Veränderungen und Innovationen durchlaufen hat, mit zahlreichen einflussreichen Persönlichkeiten und Ereignissen. In diesem Kapitel geben wir Ihnen einen Überblick über die Geschichte und Entwicklung des Softballs in Amerika, von seinen Anfängen bis zur Gegenwart.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Frühe Jahre und die Rolle von Schulen und Universitäten
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Softball wurde erstmals 1887 in Chicago als eine Hallenvariante von Baseball gespielt. Es wurde von einer Gruppe von Männern erfunden, die Thanksgiving in einer Turnhalle feierten. Sie benutzten einen Boxhandschuh als Ball und einen Besenstiel als Schläger. Das Spiel gewann schnell an Popularität unter verschiedenen Gruppen von Menschen, darunter Feuerwehrleute, Fabrikarbeiter und Kirchenmitglieder.
      <br />
      <br />
      In den frühen Jahren des Softballs gab es keine standardisierten Regeln oder Abmessungen. Das Spiel wurde an den verfügbaren Platz und die Anzahl der Spieler angepasst. Es gab verschiedene Varianten von Softball, wie Mushball, Kittenball, Diamantball und Indoor-Baseball. Der Begriff "Softball" wurde offiziell im Jahr 1926 verwendet.
      <br />
      <br />
      Schulen und Universitäten spielten eine bedeutende Rolle in der Entwicklung des Softballs. Sie betrachteten Softball als eine Sportart, die sowohl für Jungen als auch für Mädchen geeignet war, um körperliche Bildung und Freizeitaktivitäten zu fördern. Sie organisierten auch die ersten Schul- und Universitätsteams sowie die ersten organisierten Wettbewerbe. Einer der ersten Schulwettbewerbe war die Public Schools Athletic League in New York City, die 1913 mit Softball für Mädchen begann. Einer der ersten Universitätswettbewerbe war die National Collegiate Athletic Association (NCAA), die 1957 Softball für Frauen einführte.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Wachstum und Professionalisierung
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Softball entwickelte sich in Amerika zu einem beliebten und angesehenen Sport, insbesondere unter Frauen. Dies war teilweise auf die Einführung des Title IX im Jahr 1972 zurückzuführen, ein Gesetz, das gleiche Chancen für Frauen in der Bildung, einschließlich Sportstipendien, garantierte. Dies ermöglichte es mehr weiblichen Studenten-Athleten, Softball auf Hochschulebene zu spielen.
      <br />
      <br />
      Neben dem Hochschul-Softball entstanden professionelle Softball-Ligen in Amerika. Die erste professionelle Softball-Liga für Frauen war die International Women's Professional Softball Association (IWPSA), die 1976 gegründet wurde. Diese Liga bestand aus zehn Teams aus verschiedenen Städten wie New York, Chicago und Los Angeles. Die Liga dauerte vier Spielzeiten, bis 1980.
      <br />
      <br />
      Die zweite professionelle Softball-Liga für Frauen war die Women's Pro Softball League (WPSL), die 1997 gegründet wurde. Diese Liga umfasste sechs Teams aus verschiedenen Bundesstaaten, darunter Florida, Georgia und Virginia. Die Liga änderte später ihren Namen in National Pro Fastpitch (NPF). Die NPF war bis 2021 aktiv und hatte zwei abgesagte Spielzeiten aufgrund von COVID-19. Eine neue Softball-Liga, die Women's Professional Fastpitch (WPF), wurde 2022 ins Leben gerufen.
      <br />
      <br />
      Die professionellen Softball-Ligen hatten einen erheblichen Einfluss auf den Hochschul-Softball. Viele professionelle Spieler waren ehemalige Studenten-Athleten, die ihre Karrieren nach dem Abschluss fortsetzten. Einige professionelle Spieler wurden auch Trainer oder Instruktoren für Hochschulmannschaften oder einzelne Spieler. Darüber hinaus zeichneten sich viele professionelle Spieler als Vorbilder und Inspirationsquellen für junge Softballspieler aus.
      <br />
      <br />
      Einige Beispiele für Schlüsselfiguren in der Welt des Softballs sind:

    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Jennie Finch: Eine ehemalige Pitcherin der Arizona Wildcats und des US-Nationalteams. Sie gewann zwei olympische Medaillen, Gold 2004 und Silber 2008. Sie spielte auch für die Chicago Bandits in der NPF. Sie gilt als eine der größten Pitcherinnen aller Zeiten und als Ikone für die Sportart.
        </li>
        <li>
          Cat Osterman: Eine ehemalige Pitcherin der Texas Longhorns und des US-Nationalteams. Sie gewann ebenfalls zwei olympische Medaillen, Gold 2004 und Silber 2008. Sie spielte für die Rockford Thunder und die Houston Scrap Yard Dawgs in der NPF. Später wurde sie Trainerin der Texas State Bobcats.
        </li>
        <li>
          Monica Abbott: Eine ehemalige Pitcherin der Tennessee Volunteers und des US-Nationalteams. Sie gewann eine Silbermedaille bei den Olympischen Spielen 2008. Sie spielte später für die Scrap Yard Fast Pitch in der NPF und die Toyota Red Terriers in Japan. Sie ist die erste weibliche Softballspielerin, die einen Millionen-Dollar-Vertrag unterschrieben hat.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>

                                              





                                  







<MKBox id="Kapitel 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Softball an Universitäten heute
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Softball ist eine der beliebtesten und wettbewerbsfähigsten Sportarten auf Universitätsebene in Amerika. Jedes Jahr nehmen mehr als tausend Teams an der Softballsaison der Universität teil, die von Februar bis Juni läuft. Der Höhepunkt der Saison ist die Women's College World Series (WCWS), die nationale Meisterschaft für Softball an Universitäten.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Wichtige Ligen und Konferenzen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Spieler von Tennessee und Alabama im Einsatz während der College World Series."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
         }}>
          Spieler im Einsatz während der College World Series
        </figcaption>
      </figure>
      Der Softball an Universitäten wird von der National Collegiate Athletic Association (NCAA) reguliert, einer Organisation, die für die Organisation und Verwaltung von Sportprogrammen für Universitäten und Hochschulen in Amerika verantwortlich ist. Die NCAA teilt die Universitäten in drei Divisionen ein, basierend auf ihrer Größe, ihrem Budget und ihrer sportlichen Leistung. Diese Divisionen sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Division I: Die höchste und angesehenste Division mit mehr als 300 Universitäten und über 10.000 Studenten-Athleten. Division I bietet die meisten Stipendien, die besten Einrichtungen und die stärkste Konkurrenz. Division I besteht aus 32 Konferenzen, regionalen Gruppen von Universitäten, die gegeneinander antreten. Einige Beispiele für Konferenzen sind die Southeastern Conference (SEC), die Pac-12 Conference und die Big Ten Conference.
        </li>
        <li>
          Division II: Die mittlere Division mit mehr als 300 Universitäten und über 8.000 Studenten-Athleten. Division II bietet weniger Stipendien, bietet aber dennoch gute Einrichtungen und starke Konkurrenz. Division II besteht aus 23 Konferenzen wie der Great Lakes Valley Conference (GLVC), der Sunshine State Conference und der California Collegiate Athletic Association (CCAA).
        </li>
        <li>
          Division III: Die niedrigste Division mit mehr als 400 Universitäten und über 6.000 Studenten-Athleten. Division III bietet keine Stipendien, sondern bietet starke akademische Programme und eine sportliche Erfahrung. Division III besteht aus 43 Konferenzen, darunter die New England Small College Athletic Conference (NESCAC), die Centennial Conference und die University Athletic Association (UAA).
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Jede Division hat ihre eigene Meisterschaft am Ende der Saison, bei der sich die besten Teams jeder Konferenz für ein Turnier im K.o.-System qualifizieren. Die Meisterschaft der Division I heißt Women's College World Series (WCWS) und findet in Oklahoma City statt. Die Meisterschaft der Division II heißt NCAA Division II Softball Championship und findet in Denver statt. Die Meisterschaft der Division III heißt NCAA Division III Softball Championship und findet in Salem statt.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Top-Programme und angesehene Teams
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Der Softball an Universitäten hat viele Top-Programme und angesehene Teams hervorgebracht, die sich durch ihre erfolgreiche Geschichte, talentierte Spieler und einflussreiche Trainer ausgezeichnet haben. Einige dieser Programme und Teams sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          UCLA Bruins: Dieses Programm gilt als eines der erfolgreichsten in der Geschichte des Softballs an Universitäten, mit einer beeindruckenden Anzahl von nationalen Titeln. Im Laufe der Jahre haben sie olympische Medaillengewinner hervorgebracht, darunter bekannte Namen wie Lisa Fernandez, Natasha Watley und Stacey Nuveman.
        </li>
        <li>
          Oklahoma Sooners: Die Oklahoma Sooners sind ein besonders starkes Team und haben konsequent Spitzenleistungen erbracht. Sie haben mehrere nationale Titel gewonnen und hatten beeindruckende Siegesserien in der Vergangenheit.
        </li>
        <li>
          Arizona Wildcats: Das Softball-Programm der Arizona Wildcats kann eine beeindruckende Liste von nationalen Titeln vorweisen. Sie haben auch einen respektierten Trainer, Mike Candrea, mit einer ausgezeichneten Erfolgsbilanz von zahlreichen Siegen und sogar olympischem Gold als Trainer der US-Nationalmannschaft im Softball.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Diese Programme und Teams tragen zur Entwicklung von Softball-Spielern bei, indem sie ihnen ein hohes Maß an Training, Coaching und Wettbewerb bieten. Sie helfen den Spielern auch dabei, ihre Fähigkeiten, ihr Wissen und ihre Mentalität zu verbessern und sie auf den nächsten Schritt in ihrer Karriere vorzubereiten.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  







<MKBox id="Kapitel 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Vom College zum Profisport
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Softball ist eine Sportart, die vielen Studenten-Athleten die Möglichkeit bietet, ihre Karrieren nach ihrer College-Zeit fortzusetzen. Einige Studenten-Athleten entscheiden sich dafür, professionellen Softball zu spielen, während andere andere Karrieremöglichkeiten erkunden. In diesem Kapitel diskutieren wir den Weg zum Profisport, professionelle Softball-Ligen und andere Karrieremöglichkeiten für Softball-Spieler.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Der Weg zum Profisport
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Das Spielen von College-Softball ist eine gute Vorbereitung für den professionellen Softball. Viele Studenten-Athleten, die im College-Softball herausragend sind, werden von professionellen Teams gesichtet oder gedraftet. Das bedeutet, dass ihnen ein Vertrag angeboten wird, um für ein bestimmtes Team zu spielen, normalerweise im Austausch gegen ein Gehalt und andere Vergünstigungen.
      <br />
      <br />
      Das Spielen von professionellem Softball ist jedoch nicht einfach. Die Konkurrenz ist sehr hoch, und die Spieler müssen sich ständig neuen Situationen, Gegnern und Herausforderungen anpassen. Außerdem sind die Gehälter der professionellen Softball-Spieler im Vergleich zu anderen Sportarten nicht sehr hoch. Laut einem Bericht von 2016 betrug das durchschnittliche Gehalt eines NPF-Spielers etwa 5.000 bis 6.000 US-Dollar pro Saison. Das bedeutet, dass viele professionelle Spieler einen Zweitjob oder eine andere Einnahmequelle benötigen, um über die Runden zu kommen.
      <br />
      <br />
      Daher entscheiden sich einige Studenten-Athleten dafür, keinen professionellen Softball zu spielen, sondern ihr Studium abzuschließen oder eine andere Karriere zu verfolgen. Dies kann auch eine gute Wahl sein, da College-Softball viele Fähigkeiten und Qualitäten entwickelt, die in anderen Bereichen nützlich sind, wie Führung, Teamarbeit, Kommunikation, Disziplin und Problemlösung.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professioneller Softball und andere Karrieremöglichkeiten
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Wie bereits erwähnt, ist die wichtigste professionelle Softball-Liga in Amerika die National Pro Fastpitch (NPF). Die NPF besteht derzeit aus fünf Teams: den Aussie Peppers of Minnesota, den Canadian Wild of Southern Illinois, den Chicago Bandits, den Cleveland Comets und den USSSA Pride. Die NPF hat auch eine Partnerschaft mit dem chinesischen Softballverband (CSA), der zwei Teams zur NPF schickt: die Beijing Eagles und die Shanghai Shengli.
      <br />
      <br />
      Die NPF ist jedoch nicht die einzige professionelle Softball-Liga der Welt. Es gibt auch andere Ligen in Ländern wie Japan, Australien, Italien und Mexiko. Einige amerikanische Spieler entscheiden sich dafür, in diesen Ligen zu spielen, weil sie besser bezahlt werden oder weil sie eine andere Kultur erleben möchten.
      <br />
      <br />
      Neben dem Spielen von professionellem Softball gibt es auch andere Karrieremöglichkeiten für Softball-Spieler. Einige Beispiele sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Coaching: Einige Spieler werden Trainer für Schul-, College- oder Profiteams. Sie nutzen ihr Wissen und ihre Erfahrung, um andere Spieler zu trainieren, zu motivieren und zu beraten.
        </li>
        <li>
          Bildung: Einige Spieler werden Lehrer oder Professoren auf verschiedenen Ebenen und in verschiedenen Fachbereichen. Sie nutzen ihren akademischen Hintergrund und ihre pädagogischen Fähigkeiten, um Studenten zu unterrichten und zu führen.
        </li>
        <li>
          Medien: Einige Spieler werden Journalisten, Kommentatoren oder Analysten für verschiedene Medien. Sie nutzen ihre Kommunikationsfähigkeiten und ihr Verständnis für den Sport, um über Softball-Nachrichten und -Ereignisse zu berichten.
        </li>
        <li>
          Unternehmertum: Einige Spieler werden Unternehmer oder Manager in verschiedenen Branchen und Industrien. Sie nutzen ihre kreativen und geschäftlichen Fähigkeiten, um Produkte oder Dienstleistungen zu entwickeln und zu verkaufen.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Wie Sie sehen können, gibt es viele Möglichkeiten für Softball-Spieler, ihre Leidenschaft und ihr Talent in verschiedenen Bereichen zu nutzen. Der Schlüssel liegt darin, Ihre Ziele zu setzen, Ihre Optionen zu erkunden und Ihre Träume zu verfolgen.
    </MKTypography>
  </MKBox>
</MKBox>
                                 






                                  






<MKBox id="Kapitel 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationale Einflüsse und Ereignisse
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Softball ist nicht nur eine amerikanische Sportart, sondern auch eine internationale. Softball wird in mehr als 140 Ländern weltweit gespielt und hat einen bedeutenden Einfluss auf die Sportkultur und -entwicklung in verschiedenen Regionen gehabt. In diesem Kapitel werden wir ausländische Einflüsse auf Softball in Amerika und amerikanische Einflüsse auf Softball auf der Weltbühne diskutieren.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Ausländische Einflüsse
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Die Softball-Mannschaft der Universität Oklahoma feiert den Gewinn des NCAA-Titels."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Oklahoma-Spieler feiern den nationalen Meistertitel
        </figcaption>
      </figure>
      Der Softball in Amerika verdankt viel den ausländischen Einflüssen, die die Sportart bereichert und herausgefordert haben. Einige dieser Einflüsse sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Japan: Japan ist eines der stärksten Länder im Softball, sowohl auf professioneller als auch auf universitärer Ebene. Japan hat eine lange Tradition im Softball, die bis in die 1930er Jahre zurückreicht. Japan hat auch seine eigene professionelle Softball-Liga, die Japan Softball League (JSL), bestehend aus 12 Teams. Japan hat einen bedeutenden Einfluss auf den amerikanischen Softball gehabt, indem es neue Techniken, Taktiken und Spielstile eingeführt hat. Japan hat auch viele Austauschprogramme und Partnerschaften mit amerikanischen Universitäten und Teams, die vielen Spielern die Möglichkeit bieten, voneinander zu lernen.
        </li>
        <li>
          Australien: Australien ist ein weiteres Land, das eine bedeutende Rolle im Softball spielt, insbesondere auf internationaler Ebene. Australien hat seine eigene Nationalmannschaft im Softball, die Aussie Spirit, die mehrere Medaillen bei Olympischen Spielen, Weltmeisterschaften und der Softball-Weltmeisterschaft gewonnen hat. Australien hat auch viele Spieler, die in der NPF antreten, wie Stacey Porter, Kaia Parnaby und Taylah Tsitsikronis. Australien hat einen erheblichen Einfluss auf den amerikanischen Softball ausgeübt, indem es ein hohes Maß an Athletik, Ausdauer und Teamwork demonstriert hat.
        </li>
        <li>
          Kanada: Kanada, als Nachbarland von Amerika, hat ebenfalls eine starke Softball-Kultur. Kanada hat seine eigene Nationalmannschaft im Softball, die Canadian Wild, die ebenfalls an der NPF teilnimmt. Kanada hat auch viele Spieler, die für amerikanische Universitäten gespielt haben oder derzeit spielen, wie Danielle Lawrie, Jenn Salling und Sara Groenewegen. Kanada hat einen erheblichen Einfluss auf den amerikanischen Softball ausgeübt, indem es eine gemeinsame Leidenschaft, Vision und Wertschätzung für den Sport geteilt hat.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Diese ausländischen Einflüsse haben zur Entwicklung des Softballs in Amerika beigetragen, indem sie mehr Vielfalt, Wettbewerb und Zusammenarbeit geschaffen haben. Sie haben auch zur Entwicklung von universitären Spielern beigetragen, indem sie sie verschiedenen Kulturen, Perspektiven und Erfahrungen ausgesetzt haben.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikaner auf der Weltbühne
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Der Softball in Amerika hat auch auf der Weltbühne einen bedeutenden Einfluss auf den Softball gehabt. Amerika ist seit vielen Jahren sowohl im Profi- als auch im Hochschulsport führend im Softball. Amerika hat viele Erfolgsgeschichten und Inspirationsquellen für andere Länder und Spieler hervorgebracht.
      <br />
      <br />
      Diese Erfolgsgeschichten haben einen bedeutenden Einfluss auf den Softball auf der Weltbühne gehabt, indem sie ein hohes Leistungsniveau, Exzellenz und Führung gezeigt haben. Sie haben auch viel Respekt und Bewunderung von anderen Ländern und Spielern erhalten, die sie als Vorbilder und Herausforderer betrachten.
      <br />
      <br />
      Die Rolle des universitären Softballs bei der Vorbereitung auf internationale Wettbewerbe ist entscheidend. Der universitäre Softball bietet Spielern ein solides Fundament an Fähigkeiten, Wissen und Erfahrung, das sie nutzen können, um mit den besten Spielern der Welt zu konkurrieren. Der universitäre Softball hilft auch dabei, das Talent und Potenzial der Spieler zu entdecken, zu entwickeln und zu fördern, damit sie auf höchstem Niveau herausragend sein können.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  


                                
<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Sportstipendien und die Zukunft der Athleten
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sportstipendien bieten zahlreiche Möglichkeiten für junge Athleten, ihr Talent zu zeigen und ihre Fähigkeiten zu entwickeln. Eine der bedeutendsten Möglichkeiten ist der Erhalt eines Sportstipendiums, um auf Hochschulebene zu studieren und zu konkurrieren. In diesem Kapitel werden wir die Bedeutung von Sportstipendien diskutieren und wie Sportstipendien Amerika Ihnen helfen kann, Ihren Traum zu verwirklichen.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Die Bedeutung von Stipendien
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ein Sportstipendium ist finanzielle Unterstützung, die von einer Universität einem Studenten-Athleten zur Verfügung gestellt wird, um Studium und Sport zu kombinieren. Es kann die Kosten für Studiengebühren, Bücher, Unterkunft, Mahlzeiten und andere Ausgaben abdecken. Darüber hinaus kann es auch Vorteile wie akademische Nachhilfe, medizinische Versorgung, Trainingsmöglichkeiten und Ausrüstung bieten.
      <br />
      <br />
      Die Erlangung eines Sportstipendiums ist wettbewerbsfähig, wobei viele Faktoren eine Rolle im Zuteilungsprozess spielen, einschließlich akademischer Leistungen, sportlicher Leistung, Persönlichkeit, Motivation, Führung und Anpassungsfähigkeit. Daher ist es wichtig, sich gut vorzubereiten und sich von der Konkurrenz abzuheben.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Wie Sportstipendien Amerika helfen können
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo von Sportstipendien Amerika, das Sportausrüstung und eine Abschlusskappe zeigt, die den Fokus auf Sportstipendien für Studenten-Athleten repräsentiert."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo von Sportstipendien Amerika
        </figcaption>
      </figure>
      Sportstipendien Amerika ist eine Organisation, die sich auf die Anleitung junger Sporttalente zu Sportstipendien spezialisiert hat. Mit leidenschaftlichen und erfahrenen Experten unterstützt Sportstipendien Amerika Studenten-Athleten auf jedem Schritt der Reise, von der ersten Orientierung bis zur endgültigen Platzierung. Ihre Dienstleistungen umfassen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Ein persönliches Aufnahmegespräch, um die Wünsche, Möglichkeiten und Erwartungen des Studenten-Athleten zu besprechen.</li>
        <li>Eine professionelle Bewertung des sportlichen und akademischen Niveaus des Studenten-Athleten.</li>
        <li>Ein umfangreiches Netzwerk von Kontakten zu Trainern, Universitäten und Hochschulen.</li>
        <li>Effektive Werbung für das Profil und die Videos des Studenten-Athleten bei potenziellen Trainern.</li>
        <li>Expertenberatung zu den besten Optionen basierend auf den Vorlieben, Zielen und dem Budget des Studenten-Athleten.</li>
        <li>Verhandlung der besten Stipendienangebote im Namen des Studenten-Athleten.</li>
        <li>Hilfe bei der Beantragung der erforderlichen Dokumente wie Visum, Zeugnis, Diplom und Zulassungstests.</li>
        <li>Vorbereitung auf das Leben des Studenten-Athleten während seines Stipendiums, einschließlich Kultur, Sprache, Regeln und Tipps.</li>
        <li>Nachsorge und Follow-up während der Stipendiumszeit.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Möchten Sie Stipendienmöglichkeiten erkunden und Ihre sportlichen und akademischen Ziele erreichen? <Link to="/de/Dienstleistungen-Produkte">Sehen Sie sich die vollständige Palette</Link> der von Sportstipendien Amerika angebotenen Dienstleistungen an und vereinbaren Sie noch heute eine Beratung.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;