
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import SchoolIcon from '@mui/icons-material/School';
import StarIcon from '@mui/icons-material/Star';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2'; 
import Filter3Icon from '@mui/icons-material/Filter3'; 
import Filter4Icon from '@mui/icons-material/Filter4'; 
import Filter5Icon from '@mui/icons-material/Filter5'; 
import Filter6Icon from '@mui/icons-material/Filter6'; 
import Filter7Icon from '@mui/icons-material/Filter7'; 
import Filter8Icon from '@mui/icons-material/Filter8'; 
import Filter9Icon from '@mui/icons-material/Filter9';  // Import the number 1 icon
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import ScheduleIcon from '@mui/icons-material/Schedule';
import VideocamIcon from '@mui/icons-material/Videocam';
import QuizIcon from '@mui/icons-material/Quiz';
import RuleIcon from '@mui/icons-material/Rule';
import ChecklistIcon from '@mui/icons-material/Checklist';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import DescriptionIcon from '@mui/icons-material/Description';




const boxStyle = {
  boxShadow: '0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15)',
  background: '#f5f5f5', // Light grey background
  color: '#000', // Text color set to black for contrast
};

const arrowStyle = {
  borderRight: '7px solid white',
};

function Zeitstrahl() {
  return (
    <VerticalTimeline lineColor='rgb(33, 150, 243)'>
      <VerticalTimelineElement
        iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
        icon={<StarIcon />}
      />
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ ...boxStyle, position: 'relative' }} // Füge die Position relativ zum Stil hinzu
        contentArrowStyle={arrowStyle}
        date="Schritt 1"
        iconStyle={{ 
          background: 'rgb(33, 150, 243)', 
          color: '#fff', 
          boxShadow: '0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15)', // Stelle sicher, dass der Schatten hier angewendet wird
          border: '2px solid white' // Behalte den Rand bei
        }}
        icon={<RecordVoiceOverIcon />}
      >
        <div style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          opacity: 0.5 // Reduzierte Deckkraft für einen Wasserzeicheneffekt
        }}>
          <Filter1Icon style={{ color: 'grey', fontSize: '48px' }} />
        </div>

        <h3 className="vertical-timeline-element-title">Erstberatung:</h3>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Hinzugefügt Roboto als Schriftfamilie
          fontSize: '17px', // Schriftgröße auf 17px geändert
          fontWeight: '400', // Stelle sicher, dass das Schriftgewicht normal ist
          marginBottom: '10px', // Wert nach Bedarf anpassen
        }}>
          Das ist dein Startblock – der Moment, in dem wir deine Träume und Ziele mit einem realistischen Weg zu einem Sportstipendium in Amerika abstimmen.
        </p>

        <ul style={{ 
          listStyleType: 'disc', 
          marginLeft: '20px', 
          fontSize: '17px', 
        }}>  
          <li>
            <strong>Persönliche Ziele:</strong> Klärung deiner Ambitionen und was du dir von einem Sportstipendium und Universitätserlebnis erhoffst.
          </li>
          <li>
            <strong>Sportliche Fähigkeiten:</strong> Bewertung deiner aktuellen sportlichen Leistung und Potenzial für den Wettkampf auf Universitätsniveau.
          </li>
          <li>
            <strong>Akademische Bestrebungen:</strong> Verständnis deiner akademischen Interessen und Ziele und wie diese mit deinem Sport integriert werden können.
          </li>
          <li>
            <strong>Persönliche Ziele:</strong> Basierend auf dem Gespräch werde ich eine erste Strategie und Zeitplan für deine Reise zu einem Sportstipendium entwerfen.
          </li>
        </ul>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Hinzugefügt Roboto als Schriftfamilie
          fontSize: '17px', // Schriftgröße auf 17px geändert
          fontWeight: '400', // Stelle sicher, dass das Schriftgewicht normal ist
        }}>
          Gemeinsam legen wir den Grundstein für eine erfolgreiche Übereinstimmung mit einer amerikanischen Universität und setzen den Kurs für die nächsten Schritte im Prozess.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ ...boxStyle, position: 'relative' }} // Füge die Position relativ zum Stil hinzu
        contentArrowStyle={arrowStyle}
        date="Schritt 2"
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        icon={<ScheduleIcon />}
      >
        <div style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          opacity: 0.5 // Reduzierte Deckkraft für einen Wasserzeicheneffekt
        }}>
          <Filter2Icon style={{ color: 'grey', fontSize: '48px' }} />
        </div>
        <h3 className="vertical-timeline-element-title">Bewertung und Planung:</h3>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Hinzugefügt Roboto als Schriftfamilie
          fontSize: '17px', // Schriftgröße auf 17px geändert
          fontWeight: '400', // Stelle sicher, dass das Schriftgewicht normal ist
          marginBottom: '10px', // Wert nach Bedarf anpassen
        }}>
          Nach deiner Erstberatung nehme ich mir Zeit für eine gründliche Bewertung und das Erstellen eines persönlichen Plans.
        </p>

        <ul style={{ 
          listStyleType: 'disc', 
          marginLeft: '20px', 
          fontSize: '17px', 
        }}>  
          <li>
            <strong>Potenzialanalyse:</strong> Ich bewerte deinen sportlichen und akademischen Hintergrund, um deine Aussichten in den USA einzuschätzen.
          </li>
          <li>
            <strong>Aktionsplan:</strong> Ich entwickle einen maßgeschneiderten Plan, einschließlich eines Zeitplans und Meilensteinen, um deine Ziele zu erreichen.
          </li>
          <li>
            <strong>Strategische Beratung:</strong> Beratung, wie du deine sportlichen und akademischen Leistungen verbessern kannst, um deine Stipendienchancen zu erhöhen.
          </li>
          <li>
            <strong>Zieluniversitäten:</strong> Gemeinsam identifizieren wir eine Liste von Zieluniversitäten, die zu deinem Profil und deinen Ambitionen passen.
          </li>
        </ul>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Hinzugefügt Roboto als Schriftfamilie
          fontSize: '17px', // Schriftgröße auf 17px geändert
          fontWeight: '400', // Stelle sicher, dass das Schriftgewicht normal ist
        }}>
          Dieser detaillierte Plan bildet die Grundlage für deine Reise zu einem Sportstipendium und stellt sicher, dass du auf jeden nachfolgenden Schritt vorbereitet bist.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ ...boxStyle, position: 'relative' }} // Füge die Position relativ zum Stil hinzu
        contentArrowStyle={arrowStyle}
        date="Schritt 3"
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        icon={<VideocamIcon />}
      >
        <div style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          opacity: 0.5 // Reduzierte Deckkraft für einen Wasserzeicheneffekt
        }}>
          <Filter3Icon style={{ color: 'grey', fontSize: '48px' }} />
        </div>
        <h3 className="vertical-timeline-element-title">Videomaterial und Sportportfolio:</h3>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Hinzugefügt Roboto als Schriftfamilie
          fontSize: '17px', // Schriftgröße auf 17px geändert
          fontWeight: '400', // Stelle sicher, dass das Schriftgewicht normal ist
          marginBottom: '10px', // Wert nach Bedarf anpassen
        }}>
          Das Erstellen eines überzeugenden Sportportfolios und professionellen Videomaterials ist entscheidend für deine Sichtbarkeit bei den Trainern.
        </p>

        <ul style={{ 
          listStyleType: 'disc', 
          marginLeft: '20px', 
          fontSize: '17px', 
        }}>  
          <li>
            <strong>Videoproduktion:</strong> Ich berate zum Inhalt und überwache die Produktion eines Highlight-Videos, das deine Fähigkeiten optimal präsentiert.
          </li>
          <li>
            <strong>Portfoliogestaltung:</strong> Ich helfe bei der Zusammenstellung eines umfassenden Sportportfolios, einschließlich Statistiken, Erfolgen und Empfehlungen.
          </li>
          <li>
            <strong>Persönliche Geschichte:</strong> Deine sportliche Reise und Erfolge werden in eine persönliche Erzählung verwoben, die dich von anderen abhebt.
          </li>
          <li>
            <strong>Digitale Präsentation:</strong> Ich stelle sicher, dass dein Portfolio und Video digital zugänglich und teilbar für Trainer und Scouting-Teams sind.
          </li>
        </ul>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Hinzugefügt Roboto als Schriftfamilie
          fontSize: '17px', // Schriftgröße auf 17px geändert
          fontWeight: '400', // Stelle sicher, dass das Schriftgewicht normal ist
        }}>
          Mit diesem Schritt stellen wir sicher, dass dein Talent unmissverständlich hervorgehoben und die Aufmerksamkeit amerikanischer Universitäten auf sich zieht.
        </p>
      </VerticalTimelineElement>




      <VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Füge die Position relativ zum Stil hinzu
  contentArrowStyle={arrowStyle}
  date="Schritt 4"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<QuizIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduzierte Deckkraft für einen Wasserzeicheneffekt
  }}>
    <Filter4Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Akademische Vorbereitung:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Hinzugefügt Roboto als Schriftfamilie
    fontSize: '17px', // Schriftgröße auf 17px geändert
    fontWeight: '400', // Stelle sicher, dass das Schriftgewicht normal ist
    marginBottom: '10px', // Wert nach Bedarf anpassen
  }}>
    Eine gute akademische Vorbereitung ist wesentlich für die Zulassung und den Erfolg in den USA.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Testvorbereitung:</strong> Ich stelle Studienmaterialien und Anleitung für SAT/ACT und TOEFL/IELTS zur Verfügung, die wesentlichen Tests für deine Zulassung.
    </li>
    <li>
      <strong>Studienplanung:</strong> Gemeinsam entwickeln wir einen Studienplan zur Vorbereitung auf die Prüfungen, ohne dein Sporttraining zu vernachlässigen.
    </li>
    <li>
      <strong>Bewerbungsdokumente:</strong> Ich unterstütze bei der Vorbereitung und Überarbeitung deiner Bewerbungsaufsätze und anderer erforderlicher Unterlagen.
    </li>
    <li>
      <strong>Akademische Beratung:</strong> Du erhältst Beratung zu akademischen Anforderungen und wie du dein akademisches Profil stärken kannst.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Hinzugefügt Roboto als Schriftfamilie
    fontSize: '17px', // Schriftgröße auf 17px geändert
    fontWeight: '400', // Stelle sicher, dass das Schriftgewicht normal ist
  }}>
    Mein Ziel ist es, sicherzustellen, dass du akademisch nicht nur aufgenommen, sondern auch ausgezeichnet wirst.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Füge die Position relativ zum Stil hinzu
  contentArrowStyle={arrowStyle}
  date="Schritt 5"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<SchoolIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduzierte Deckkraft für einen Wasserzeicheneffekt
  }}>
    <Filter5Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Promotion an Universitäten:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Hinzugefügt Roboto als Schriftfamilie
    fontSize: '17px', // Schriftgröße auf 17px geändert
    fontWeight: '400', // Stelle sicher, dass das Schriftgewicht normal ist
    marginBottom: '10px', // Wert nach Bedarf anpassen
  }}>
    Die Promotion deines Profils an Universitäten ist ein entscheidender Schritt, bei dem meine Expertise und mein umfangreiches Netzwerk ins Spiel kommen.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Wichtige Rolle des Netzwerks:</strong> Mein umfangreiches Netzwerk innerhalb amerikanischer Universitäten ist unschätzbar wertvoll, um deine Kandidatur ins Rampenlicht zu rücken.
    </li>
    <li>
      <strong>Strategische Positionierung:</strong> Ich nutze mein Wissen und meine Erfahrung, um dein Profil strategisch so zu positionieren, dass es für die richtigen Sportprogramme und akademischen Institutionen hervorsticht.
    </li>
    <li>
      <strong>Anpassungsfähiger Promotionsplan:</strong> Ich erstelle einen flexiblen und maßgeschneiderten Promotionsplan, der die einzigartigen Aspekte deines sportlichen und akademischen Profils hervorhebt.
    </li>
    <li>
      <strong>Maximierung der Möglichkeiten:</strong> Durch gezielte Promotion stelle ich sicher, dass deine Chancen auf ein Stipendium maximiert werden, mit Fokus auf das beste Match für deine persönlichen und sportlichen Ambitionen.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Hinzugefügt Roboto als Schriftfamilie
    fontSize: '17px', // Schriftgröße auf 17px geändert
    fontWeight: '400', // Stelle sicher, dass das Schriftgewicht normal ist
  }}>
    Mein Mehrwert liegt darin, dein Talent und deine Ambitionen effektiv in den Vordergrund zu stellen und die Erfolgschancen erheblich zu erhöhen. Hier machen mein Netzwerk, mein Verständnis für das amerikanische Universitätssystem und mein persönlicher Ansatz einen entscheidenden Unterschied.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Füge die Position relativ zum Stil hinzu
  contentArrowStyle={arrowStyle}
  date="Schritt 6"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<RecordVoiceOverIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduzierte Deckkraft für einen Wasserzeicheneffekt
  }}>
    <Filter6Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Kommunikation mit Trainern:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    Eine effektive Kommunikation mit Trainern ist essenziell. Ich agiere als dein persönlicher Vertreter, um einen starken ersten Eindruck zu machen.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Direkte Vorstellungen:</strong> Persönliche Vorstellungen bei Trainern in meinem Netzwerk, angepasst an deine Sportdisziplin.
    </li>
    <li>
      <strong>Kommunikationsstrategie:</strong> Entwicklung einer effektiven Kommunikationsstrategie, um deine Qualitäten und Ambitionen hervorzuheben.
    </li>
    <li>
      <strong>Verhandlungsführung:</strong> Beratung bei der Kommunikation mit Trainern während der Verhandlungsphase für Sportstipendien.
    </li>
    <li>
      <strong>Kontinuierliche Unterstützung:</strong> Ständige Unterstützung und Beratung in allen Kommunikationsangelegenheiten, um sicherzustellen, dass du einen positiven und bleibenden Eindruck hinterlässt.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Meine Rolle besteht darin, eine Brücke zwischen dir und den amerikanischen Trainern zu bilden und einen Kommunikationskanal zu schaffen, der deine Chancen auf ein erfolgreiches Sportstipendium maximiert.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Füge die Position relativ zum Stil hinzu
  contentArrowStyle={arrowStyle}
  date="Schritt 7"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<RuleIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduzierte Deckkraft für einen Wasserzeicheneffekt
  }}>
    <Filter7Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Auswahl und Stipendienangebote:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    Der Auswahlprozess ist entscheidend; hier kommen die Früchte unserer gemeinsamen Arbeit in Form von Stipendienangeboten zum Tragen.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Angebotsbewertung:</strong> Gemeinsam überprüfen wir die erhaltenen Angebote und diskutieren die Vor- und Nachteile jeder Option.
    </li>
    <li>
      <strong>Strategische Beratung:</strong> Ich biete strategische Beratung, um dir zu helfen, die beste Wahl für deine sportliche und akademische Zukunft zu treffen.
    </li>
    <li>
      <strong>Unterstützung bei Verhandlungen:</strong> Meine Erfahrung in Verhandlungen wird genutzt, um die bestmöglichen Bedingungen für dich zu realisieren.
    </li>
    <li>
      <strong>Endgültige Wahl:</strong> Mit meiner Hilfe triffst du eine gut informierte endgültige Wahl, bei der deine Sportkarriere und Ausbildung in den USA beginnen.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Meine Beratung in dieser Phase zielt darauf ab, das bestmögliche Ergebnis für deine Ambitionen zu sichern und deine Zukunft an einer Spitzenuniversität in Amerika zu gewährleisten.
  </p>
  
</VerticalTimelineElement>

<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Füge die Position relativ zum Stil hinzu
  contentArrowStyle={arrowStyle}
  date="Schritt 8"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<DescriptionIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduzierte Deckkraft für einen Wasserzeicheneffekt
  }}>
    <Filter8Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Visumantrag und Einschreibungsprozess:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    Nach der Auswahl beginnt eine kritische Phase: die Finalisierung deines Visumantrags und der Einschreibungsverfahren.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Visumantrag:</strong> Beratung beim Antrag auf das passende Studentenvisum, einschließlich der Zusammenstellung der erforderlichen Unterlagen.
    </li>
    <li>
      <strong>Einschreibungshilfe:</strong> Hilfe bei allen Einschreibungsformularen und -dokumenten für deine gewählte Universität und den Wettbewerb.
    </li>
    <li>
      <strong>Finanzplanung:</strong> Beratung zu finanziellen Angelegenheiten wie Stipendiengebühren, Studiengebühren und Lebenshaltungskosten in den USA.
    </li>
    <li>
      <strong>Abschließende Überprüfung:</strong> Eine gründliche letzte Überprüfung, um sicherzustellen, dass alle administrativen und logistischen Aspekte berücksichtigt sind.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Meine Beratung bei der Vorbereitung stellt sicher, dass du dein amerikanisches Abenteuer selbstbewusst und gut informiert beginnst.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Füge die Position relativ zum Stil hinzu
  contentArrowStyle={arrowStyle}
  date="Schritt 9"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<ChecklistIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduzierte Deckkraft für einen Wasserzeicheneffekt
  }}>
    <Filter8Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Vorbereitung auf die Abreise:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    Ein reibungsloser Übergang in die USA erfordert sorgfältige Vorbereitung; die letzten Vorbereitungen werden jetzt getroffen.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Checkliste:</strong> Eine umfassende Checkliste, um sicherzustellen, dass nichts vergessen wird, von wichtigen Dokumenten bis hin zu persönlichen Notwendigkeiten.
    </li>
    <li>
      <strong>Unterkunft:</strong> Unterstützung bei der Suche nach geeignetem Wohnraum in der Nähe deiner Universität und mit den richtigen Annehmlichkeiten.
    </li>
    <li>
      <strong>Versicherung:</strong> Beratung zu den richtigen Versicherungen für Gesundheit, Reise und Aufenthalt.
    </li>
    <li>
      <strong>Briefing:</strong> Eine Briefingsitzung zu kulturellen Unterschieden, Leben in den USA und praktischen Tipps für den Alltag.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Meine Beratung bei der Vorbereitung stellt sicher, dass du dein amerikanisches Abenteuer selbstbewusst und gut informiert beginnst.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Füge die Position relativ zum Stil hinzu
  contentArrowStyle={arrowStyle}
  date="Schritt 10"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<FlightTakeoffIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduzierte Deckkraft für einen Wasserzeicheneffekt
  }}>
    <Filter9Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Abreise und Ankunft in den USA:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    Der letzte Schritt deiner Vorbereitung ist gekommen: die Reise nach Amerika.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Vorbereitung auf die Abreise:</strong> Ich stelle sicher, dass du vollständig auf deine Abreise vorbereitet bist, mit einer detaillierten Checkliste und letzten Ratschlägen.
    </li>
    <li>
      <strong>Abschiedsmoment:</strong> Ein Moment, um über diesen bedeutenden Meilenstein nachzudenken und Abschied zu nehmen, bevor du deinem Traum nachgehst.
    </li>
    <li>
      <strong>Ankunft in den USA:</strong> Nach der Ankunft biete ich Unterstützung an, um dir zu helfen, dich einzuleben und dich in deiner neuen Umgebung zurechtzufinden.
    </li>
    <li>
      <strong>Die ersten Tage:</strong> Die ersten Tage sind aufregend, und ich stelle sicher, dass du die Unterstützung erhältst, die du brauchst, um gut zu starten.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Ich stehe dir bei jedem Schritt zur Seite, vom Abflug in Deutschland bis zu deiner Ankunft auf dem Campus in den USA, bereit, dein Studium und deine Sportkarriere zu beginnen.
  </p>
  
</VerticalTimelineElement>

<VerticalTimelineElement
  iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
  icon={<SportsScoreIcon />}
/>
</VerticalTimeline>

  );
}

export default Zeitstrahl;
