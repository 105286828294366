/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Volleybal1.avif";
import Image2 from "assets/images/Sports_Images/volleybal2.webp";
import Image3 from "assets/images/Sports_Images/Volleybal3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images
import { Link } from 'react-router-dom';



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Bourse de Volleyball : Comment Étudier et Jouer au Volleyball en Amérique ?', isSubheader: false },
  
    { id: 'Chapitre 1', title: 'Chapitre 1 : Histoire et Développement du Volleyball en Amérique', isSubheader: false },
    { id: '1.1', title: '1.1 Débuts et le Rôle des Institutions Éducatives', isSubheader: true },
    { id: '1.2', title: '1.2 Croissance et Professionnalisation', isSubheader: true },
  
    { id: 'Chapitre 2', title: 'Chapitre 2 : Le Volleyball Universitaire Aujourd\'hui', isSubheader: false },
    { id: '2.1', title: '2.1 Ligues Majeures et Conférences', isSubheader: true },
    { id: '2.2', title: '2.2 Programmes d\'Élite et Équipes Prestigieuses', isSubheader: true },
  
    { id: 'Chapitre 3', title: 'Chapitre 3 : De l\'Université aux Professionnels', isSubheader: false },
    { id: '3.1', title: '3.1 Le Chemin vers le Professionnalisme', isSubheader: true },
    { id: '3.2', title: '3.2 Ligues Professionnelles et Opportunités de Carrière', isSubheader: true },
  
    { id: 'Chapitre 4', title: 'Chapitre 4 : Influences et Compétitions Internationales', isSubheader: false },
    { id: '4.1', title: '4.1 Influences Étrangères', isSubheader: true },
    { id: '4.2', title: '4.2 Les Américains sur la Scène Mondiale', isSubheader: true },
  
    { id: 'Chapitre 5', title: 'Chapitre 5 : Bourses Sportives et l\'Avenir des Athlètes', isSubheader: false },
    { id: '5.1', title: '5.1 L\'Importance des Bourses', isSubheader: true },
    { id: '5.2', title: '5.2 Comment Sport Scholarships America Peut Aider', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Sommaire
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Cacher</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Afficher</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
           <Grid item xs={12} md={9}>


























           <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Bourse de Volleyball : Comment Étudier et Jouer au Volleyball en Amérique ?
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Match de volleyball au Nebraska, auquel assistent plus de 92 000 fans."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        92 000 spectateurs assistent à un match de volleyball au Nebraska
      </figcaption>
    </figure>
    Le volleyball est un sport qui demande beaucoup de vos capacités physiques et mentales. Vous devez réagir rapidement, collaborer efficacement, réfléchir tactiquement et avoir une forte endurance. De plus, le volleyball est un sport qui procure beaucoup de joie et de satisfaction, surtout lorsque vous jouez à un haut niveau. Mais comment pouvez-vous, en tant que talent en volleyball, poursuivre vos ambitions athlétiques sans négliger vos études ? La réponse est : en optant pour une bourse de volleyball en Amérique.
    <br />
    <br />
    Une bourse de volleyball est une aide financière offerte par une université américaine qui vous permet d'étudier et de jouer au volleyball là-bas. Avec une bourse de volleyball, vous pouvez atteindre deux objectifs à la fois : vous pouvez obtenir un diplôme reconnu d'une institution éducative prestigieuse tout en développant davantage vos compétences en volleyball sous une guidance professionnelle. De plus, vous pouvez profiter de l'expérience unique de vivre et d'apprendre dans une culture différente, avec de nouveaux amis et défis.
    <br />
    <br />
    Mais comment pouvez-vous être admissible à une bourse de volleyball ? Quels sont les critères et les attentes ? Et comment trouver la bonne université qui vous convient ? Ce sont toutes des questions auxquelles Sportbeursamerika.nl peut vous aider. Sportbeursamerika.nl est spécialisé dans le domaine des bourses sportives en Amérique. Fort de nombreuses années d'expérience et d'un vaste réseau, je peux vous guider à travers toutes les étapes pour postuler avec succès à une bourse de volleyball, de la création d'un profil en ligne à la gestion de toutes les formalités pratiques.
    <br />
    <br />
    Si vous souhaitez en savoir plus sur la manière dont Sportbeursamerika.nl peut vous aider à étudier et à jouer au volleyball en Amérique, continuez à lire ou contactez-moi pour une conversation introductive sans engagement. Je serais ravi de vous en dire plus sur les possibilités et les avantages d'une bourse de volleyball en Amérique.
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
 {/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapitre 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 1 : Histoire et Développement du Volleyball en Amérique
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le volleyball est un sport qui existe depuis plus d'un siècle, mais il a connu une croissance significative en popularité et en professionnalisme, surtout ces dernières décennies. Dans ce chapitre, nous plongerons dans l'histoire et le développement du volleyball en Amérique, de ses débuts à nos jours.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Débuts et le Rôle des Institutions Éducatives
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Le volleyball a été inventé en 1895 par William G. Morgan, professeur d'éducation physique à la YMCA (Young Men’s Christian Association) à Holyoke, dans le Massachusetts. Son objectif était de créer un jeu moins exigeant physiquement que le basketball, qui avait été inventé un an plus tôt par son collègue James Naismith. Initialement, il appelait le jeu "mintonette", mais il est rapidement devenu connu sous le nom de "volleyball" car les joueurs devaient renvoyer le ballon par-dessus un filet.
      <br />
      <br />
      Le jeu s'est rapidement répandu aux États-Unis et dans d'autres pays, principalement grâce à la YMCA et à d'autres institutions éducatives. En 1916, l'un des premiers tournois de volleyball officiels a eu lieu à l'Université de Californie à Berkeley. En 1922, l'United States Volleyball Association (USVBA) a été créée, qui est devenue plus tard USA Volleyball (USAV). Cette organisation est devenue responsable de la réglementation et de la promotion du volleyball en Amérique.
      <br />
      <br />
      En 1928, le premier championnat national masculin a été organisé par l'USVBA, suivi du premier championnat national féminin en 1949. En 1955, le premier Championnat du Monde masculin a eu lieu en France, où l'Amérique a terminé sixième. En 1956, le premier Championnat du Monde féminin a eu lieu en France, avec une quatrième place pour l'Amérique.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Croissance et Professionnalisation
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dans les années 1960 et 1970, le volleyball a commencé à croître et à se professionnaliser en Amérique. Cela était en partie dû à l'introduction de nouvelles règles, telles que l'adoption du système de point au rallye, où chaque point compte indépendamment de qui sert. Le volleyball a également gagné en attention grâce aux diffusions télévisées des Jeux olympiques, où le volleyball est un sport officiel depuis 1964.
      <br />
      <br />
      Une des figures clés de l'histoire du volleyball en Amérique est Doug Beal, qui a connu le succès à la fois en tant que joueur et entraîneur. Il a joué pour l'équipe nationale de 1970 à 1976 et a remporté l'or aux Jeux panaméricains en 1975, entre autres réalisations. Il est ensuite devenu l'entraîneur de l'équipe nationale de 1977 à 1984 et les a menés à leur première médaille d'or olympique en 1984 à Los Angeles. Il a également été impliqué dans la fondation de la Major League Volleyball (MLV), une ligue professionnelle féminine qui a existé de 1987 à 1989.
      <br />
      <br />
      Un autre événement significatif dans l'histoire du volleyball en Amérique a été la création de l'Association of Volleyball Professionals (AVP) en 1983, axée sur le beach-volley. Le beach-volley est une variante du volleyball jouée sur du sable avec des équipes de deux joueurs. L'AVP a organisé des tournois et des compétitions pour les hommes et les femmes, contribuant à populariser le beach-volley auprès du public. Certains joueurs de beach-volley bien connus incluent Karch Kiraly, Sinjin Smith, Randy Stoklos, Misty May-Treanor et Kerri Walsh Jennings.
    </MKTypography>
  </MKBox>
</MKBox>






                                  






<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 2 : Le Volleyball Universitaire Aujourd'hui
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le volleyball est l'un des sports les plus populaires et compétitifs au niveau universitaire en Amérique. Chaque année, des milliers d'équipes participent à diverses compétitions et conférences, culminant avec les championnats nationaux. Dans ce chapitre, nous vous fournirons un aperçu des principales ligues et conférences, et discuterons de certains des meilleurs programmes et équipes prestigieuses du volleyball universitaire.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Ligues Majeures et Conférences
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Équipe de volleyball célébrant une victoire ensemble."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Joueurs de volleyball célébrant une victoire
        </figcaption>
      </figure>
      L'organisation principale chargée d'organiser et de réglementer le volleyball universitaire en Amérique est la National Collegiate Athletic Association (NCAA). La NCAA divise les universités en trois divisions en fonction de leur taille, de leur budget et de leurs performances sportives. Les divisions sont :
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Division I : Il s'agit de la plus haute division, où se trouvent la plupart des grandes et célèbres universités. Les équipes de cette division reçoivent le plus d'attention, de couverture médiatique et de bourses. Il y a environ 330 universités dans cette division, réparties en 32 conférences. Une conférence est un groupe d'universités qui sont géographiquement ou historiquement liées et qui se disputent entre elles. Quelques exemples de conférences en Division I incluent la Big Ten, la Pac-12, la Southeastern Conference (SEC) et l'Atlantic Coast Conference (ACC).
        </li>
        <li>
          Division II : Il s'agit de la division intermédiaire, où les universités sont légèrement plus petites et moins connues qu'en Division I, mais elles maintiennent néanmoins un niveau élevé de volleyball. Les équipes de cette division reçoivent moins d'attention, de couverture médiatique et de bourses que en Division I mais plus que en Division III. Il y a environ 300 universités dans cette division, réparties en 23 conférences. Quelques exemples de conférences en Division II incluent la Great Lakes Valley Conference (GLVC), la California Collegiate Athletic Association (CCAA) et la Gulf South Conference (GSC).
        </li>
        <li>
          Division III : Il s'agit de la division la plus basse, où les universités se concentrent principalement sur l'excellence académique plutôt que sur les performances sportives. Les équipes de cette division ne reçoivent pas de bourses pour leurs athlètes mais offrent une aide financière basée sur le mérite académique. Il y a environ 450 universités dans cette division, réparties en 43 conférences. Quelques exemples de conférences en Division III incluent la New England Small College Athletic Conference (NESCAC), la University Athletic Association (UAA) et la Centennial Conference.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Chaque année, les équipes de leur division et de leur conférence participent à des matchs pour se qualifier pour les championnats nationaux, également connus sous le nom de Tournoi de la NCAA. Il s'agit d'un tournoi à élimination directe où les meilleures équipes de chaque division se disputent le titre de champion national. Le tournoi se déroule au printemps pour les hommes et à l'automne pour les femmes.
    </MKTypography>

  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programmes d'Élite et Équipes Prestigieuses
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Le volleyball universitaire en Amérique se distingue par un niveau de compétition élevé, et de nombreuses équipes excellent dans leurs divisions et conférences respectives. Cependant, certaines équipes ont acquis une réputation et une histoire particulières en raison de leurs performances constantes, de leurs joueurs et entraîneurs talentueux, et d'une large base de fans. Voici quelques-uns des meilleurs programmes et des équipes prestigieuses du volleyball universitaire :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Bruins de UCLA : Représentant l'Université de Californie à Los Angeles, les Bruins de UCLA sont connus pour leur forte tradition à la fois dans le volleyball masculin et féminin en NCAA Division I. Historiquement, ils ont remporté plusieurs championnats nationaux et ont produit certains des joueurs les plus respectés du sport. Cette équipe est un membre important de la Pac-12 Conference.
        </li>
        <li>
          Cardinal de Stanford : L'équipe de volleyball féminin de l'Université Stanford, située à Stanford, en Californie, s'est distinguée en remportant un nombre impressionnant de championnats nationaux en NCAA Division I, plus que toute autre équipe de leur catégorie. Elles ont constamment produit des joueurs talentueux et concourent dans la Pac-12 Conference.
        </li>
        <li>
          Lions de Penn State : L'équipe de volleyball féminin de la Pennsylvania State University à University Park, en Pennsylvanie, jouit d'une réputation d'excellence en NCAA Division I, avec de nombreux championnats nationaux à leur actif. Elles sont connues pour leurs titres consécutifs et ont formé des joueurs exceptionnels au fil des ans. Cette équipe est un membre important de la Big Ten Conference.
        </li>
        <li>
          Cougars de BYU : Brigham Young University à Provo, Utah, possède une respectée équipe de volleyball masculin en NCAA Division I, qui a remporté le championnat national à plusieurs reprises. L'équipe a constamment excellé dans le développement de joueurs de volleyball de premier plan et fait partie de la Mountain Pacific Sports Federation (MPSF).
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ces programmes ont été remarquables et influents dans le volleyball universitaire aux États-Unis au fil des ans, connus pour leurs fortes traditions, leurs joueurs talentueux et leurs entraîneurs influents.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  







<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 3 : De l'Université aux Professionnels
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Pour de nombreux étudiants-athlètes qui pratiquent le volleyball au niveau universitaire, c'est un rêve de jouer également au volleyball professionnel après l'obtention de leur diplôme. Mais à quel point ce rêve est-il réaliste, et quelles sont les étapes à suivre pour y parvenir ? Dans ce chapitre, nous discuterons du chemin vers le professionnalisme, des ligues professionnelles et des opportunités de carrière pour les joueurs de volleyball.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Le Chemin vers le Professionnalisme
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Jouer au volleyball au niveau universitaire est un excellent moyen d'améliorer vos compétences en volleyball, d'obtenir votre diplôme universitaire et de vous développer personnellement. Cependant, c'est aussi un environnement très compétitif et exigeant où vous devez investir beaucoup de temps et d'énergie à la fois dans votre sport et dans vos études. Il est donc important d'être réaliste quant à vos chances de jouer au volleyball professionnel après votre carrière universitaire.
      <br />
      <br />
      Contrairement à certains autres sports comme le basketball ou le football, il n'y a pas de ligue de volleyball professionnel en Amérique qui recrute directement à partir des universités. Cela signifie que en tant que joueur diplômé, vous devrez chercher d'autres opportunités pour débuter votre carrière professionnelle, généralement à l'étranger. Il existe diverses ligues professionnelles de volleyball en Europe, en Asie, en Amérique du Sud et dans d'autres régions où vous pouvez jouer pour des clubs qui vous offrent un salaire et d'autres avantages.
      <br />
      <br />
      Mais comment entrer en contact avec ces clubs, et comment vous assurer de vous démarquer parmi les nombreux autres joueurs qui recherchent également un contrat ? Voici quelques conseils pour vous aider à trouver un club de volleyball professionnel :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Créez un profil en ligne sur une plateforme comme Volleyball Agency ou Volleyball Market, où vous pouvez partager vos vidéos, statistiques, références et coordonnées avec des clubs potentiels et des agents.
        </li>
        <li>
          Contactez vos anciens entraîneurs, coéquipiers ou autres contacts ayant de l'expérience dans le volleyball professionnel ou ayant des liens avec des clubs ou des agents. Ils peuvent vous fournir des conseils, faire des recommandations ou vous présenter aux bonnes personnes.
        </li>
        <li>
          Participez aux essais, aux présentations ou aux camps organisés par des clubs ou des organisations professionnels. Ce sont des événements où vous pouvez mettre en valeur vos compétences auprès de recruteurs, d'entraîneurs ou de directeurs à la recherche de nouveaux joueurs. Assurez-vous d'être bien préparé, tant physiquement que mentalement, et laissez une impression positive.
        </li>
        <li>
          Soyez flexible et prêt à déménager dans un autre pays ou continent. Jouer au volleyball professionnel signifie souvent vivre dans une culture différente, avec une langue, un climat et des coutumes différents. Cela peut être un défi important mais aussi une excellente opportunité d'apprendre et de grandir en tant que personne.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Ligues Professionnelles et Opportunités de Carrière
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Si vous parvenez à décrocher un contrat avec un club de volleyball professionnel, vous pouvez profiter des avantages de jouer au plus haut niveau du sport. Vous pouvez participer à des ligues passionnantes, affronter les meilleurs joueurs du monde, voyager dans différents pays et villes, et gagner de l'argent grâce à votre passion. Mais quelles sont les principales ligues professionnelles auxquelles vous pouvez participer, et quelles sont les autres opportunités de carrière pour les joueurs de volleyball ?
      <br />
      <br />
      Voici quelques-unes des ligues de volleyball professionnelles les plus prestigieuses et lucratives dans le monde :
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          La Serie A italienne : Il s'agit de la division supérieure du volleyball italien, composée de 14 équipes masculines et de 12 équipes féminines. La Serie A est connue comme l'une des ligues les plus fortes et les plus compétitives au monde, avec de nombreuses stars internationales et des salaires élevés. Le champion de Serie A se qualifie également pour la Ligue des Champions, le principal tournoi de club en Europe.
        </li>
        <li>
          La Süper Lig turque : Il s'agit de la division supérieure du volleyball turc, composée de 12 équipes masculines et de 14 équipes féminines. La Süper Lig est également l'une des ligues principales dans le monde, avec des investissements importants, une attention médiatique et des joueurs talentueux. Le champion de la Süper Lig se qualifie également pour la Ligue des Champions.
        </li>
        <li>
          La Superliga brésilienne : Il s'agit de la division supérieure du volleyball brésilien, composée de 12 équipes masculines et de 12 équipes féminines. La Superliga est la ligue dominante en Amérique du Sud, connue pour sa passion, son atmosphère et sa qualité. Le champion de la Superliga se qualifie également pour le Championnat du Monde des Clubs de Volleyball de la FIVB, le principal tournoi de club dans le monde.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      En plus de jouer dans des ligues professionnelles, il existe d'autres opportunités pour les joueurs de volleyball de poursuivre ou d'élargir leur carrière. Voici quelques exemples :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Entraîner ou former d'autres joueurs : Si vous souhaitez mettre fin ou réduire votre carrière de joueur, vous pouvez également transmettre vos connaissances et votre expérience à d'autres joueurs en tant qu'entraîneur ou formateur. Vous pouvez travailler pour un club professionnel, une université, une école ou une autre organisation proposant du volleyball. Vous pouvez également créer votre propre école ou académie de volleyball, où vous pouvez former et encadrer de jeunes talents.
        </li>
        <li>
          Travailler dans les médias ou l'industrie : Si vous avez un intérêt pour les médias ou les industries liées au volleyball, vous pouvez également y construire une carrière. Vous pouvez travailler en tant que commentateur, analyste, journaliste, écrivain, photographe ou vidéaste pour différents médias couvrant le volleyball. Vous pouvez également travailler en tant que représentant, conseiller, marketeur ou designer pour différentes entreprises proposant des produits ou services de volleyball.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  




<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 4 : Influences et Compétitions Internationales
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le volleyball est un sport mondial, joué et suivi par des millions de personnes dans différents pays et continents. Le volleyball universitaire en Amérique n'est pas isolé mais est influencé et défié par la communauté de volleyball internationale. Dans ce chapitre, nous discuterons des influences étrangères et des compétitions internationales qui jouent un rôle dans le volleyball universitaire.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Influences Étrangères
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Le volleyball universitaire en Amérique doit beaucoup aux influences étrangères qui ont enrichi et amélioré le sport. L'une des influences les plus significatives est l'immigration de joueurs, d'entraîneurs et de préparateurs physiques d'autres pays qui ont apporté leurs connaissances, leur expérience et leur culture en Amérique. Certains de ces immigrants sont même devenus des légendes dans le volleyball universitaire, tels que :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Arie Selinger : Il est un entraîneur israélien qui est arrivé en Amérique en 1969 pour entraîner l'équipe nationale féminine. Il a mené l'équipe à sa première médaille olympique, une médaille d'argent en 1984. Il a également entraîné plusieurs équipes universitaires, dont Long Beach State, où il est devenu quadruple champion national.
        </li>
        <li>
          Andy Banachowski : Il est un joueur polonais qui a émigré en Amérique en 1951. Il a joué pour UCLA, où il est devenu double champion national. Il est ensuite devenu l'entraîneur de l'équipe féminine de UCLA, remportant six championnats nationaux et plus de 1000 matchs.
        </li>
        <li>
          Marv Dunphy : Il est un joueur canadien qui est arrivé en Amérique en 1972 pour jouer pour l'Université Pepperdine. Il est ensuite devenu l'entraîneur de l'équipe masculine de Pepperdine, remportant quatre championnats nationaux. Il a également entraîné l'équipe nationale masculine, qui a remporté l'or aux Jeux olympiques de 1988.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Une autre influence importante est la popularité et le niveau du volleyball international, qui fournit inspiration et motivation pour le volleyball universitaire. Le volleyball international comprend divers tournois régionaux et mondiaux où les meilleures équipes et joueurs du monde se rencontrent. Certains de ces tournois comprennent :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Les Jeux Olympiques : Il s'agit du plus grand et du plus prestigieux événement sportif au monde, organisé tous les quatre ans. Le volleyball est un sport olympique officiel depuis 1964 pour les hommes et les femmes. Les États-Unis ont remporté un total de 9 médailles olympiques en volleyball, dont 3 en or, 3 en argent et 3 en bronze.
        </li>
        <li>
          Le Championnat du Monde : Il s'agit du tournoi le plus important pour les équipes nationales, organisé tous les quatre ans. Il est organisé par la Fédération Internationale de Volleyball (FIVB), l'organe directeur mondial du volleyball. Le Championnat du Monde existe depuis 1949 pour les hommes et depuis 1952 pour les femmes. Les États-Unis ont remporté un total de 5 médailles en Championnat du Monde en volleyball, dont 2 en or, 1 en argent et 2 en bronze.
        </li>
        <li>
          La Coupe du Monde : Il s'agit d'un autre tournoi pour les équipes nationales, organisé tous les quatre ans. Il est également organisé par la FIVB mais diffère du Championnat du Monde en termes de nombre d'équipes participantes (12 au lieu de 24) et du système de qualification (basé sur les championnats continentaux). La Coupe du Monde existe depuis 1965 pour les hommes et depuis 1973 pour les femmes. Les États-Unis ont remporté un total de 7 médailles en Coupe du Monde en volleyball, dont 2 en or, 3 en argent et 2 en bronze.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Les Américains sur la Scène Mondiale
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Le volleyball universitaire en Amérique a non seulement appris du volleyball international, mais a également contribué de manière significative au volleyball international. De nombreux joueurs américains qui ont compétitionné au niveau universitaire ont également participé avec succès à des compétitions internationales, tant pour leurs équipes nationales que pour des clubs professionnels. Voici quelques exemples de joueurs américains qui se sont illustrés sur la scène mondiale :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Karch Kiraly : Il est l'un des plus grands joueurs de volleyball de tous les temps, excellent à la fois en volleyball en salle et en beach-volley. Il a joué pour UCLA, où il est devenu triple champion national. Il a également joué pour l'équipe nationale, remportant deux médailles d'or olympiques en volleyball en salle (1984 et 1988) et une en beach-volley (1996). Il a également joué professionnellement en Italie, où il a remporté quatre titres italiens et deux titres de la Ligue des champions.
        </li>
        <li>
          Logan Tom : Elle est l'une des plus grandes joueuses de volleyball féminin de tous les temps, jouant à la fois en tant qu'attaquante et libero. Elle a joué pour Stanford, où elle est devenue championne nationale et quatre fois All-American. Elle a également joué pour l'équipe nationale, participant à quatre Jeux olympiques et remportant deux médailles d'argent (2008 et 2012). Elle a également joué professionnellement dans différents pays, dont le Brésil, la Turquie, le Japon et la Chine.
        </li>
        <li>
          Matt Anderson : Il est l'un des meilleurs joueurs de volleyball masculin de la génération actuelle, capable de jouer à la fois en tant qu'attaquant et réceptionneur-attaquant. Il a joué pour Penn State, où il est devenu champion national en 2008 et a été nommé All-American en 2007 et 2008. Il joue également pour l'équipe nationale, remportant le bronze aux Jeux olympiques de 2016 et l'or à la Coupe du Monde de 2015. Il a également joué professionnellement en Russie, où il a remporté trois titres russes et deux titres de la Ligue des champions.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>
           






                                  


<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 5 : Bourses Sportives et l'Avenir des Athlètes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Les bourses sportives offrent de nombreuses opportunités aux jeunes athlètes de montrer leur talent et de développer leurs compétences. L'une des opportunités les plus significatives est d'obtenir une bourse sportive pour étudier et compétitionner au niveau collégial. Dans ce chapitre, nous discuterons de l'importance des bourses sportives et de comment Sport Scholarships America peut vous aider à réaliser votre rêve.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 L'Importance des Bourses
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Une bourse sportive est un soutien financier fourni par une université à un étudiant-athlète pour combiner études et sport. Elle peut couvrir les frais de scolarité, les livres, le logement, les repas et autres dépenses. De plus, elle peut offrir des avantages tels que du tutorat académique, des soins médicaux, des installations d'entraînement et du matériel.
      <br />
      <br />
      Obtenir une bourse sportive est compétitif, avec de nombreux facteurs qui influent sur le processus d'attribution, notamment les performances académiques, sportives, la personnalité, la motivation, le leadership et l'adaptabilité. Il est donc important de bien se préparer et de se démarquer de la concurrence.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Comment Sport Scholarships America Peut Aider
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo de Sport Scholarships America, représentant du matériel sportif et une casquette de graduation, symbolisant le focus sur les bourses sportives pour les étudiants-athlètes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America est une organisation spécialisée dans l'orientation des jeunes talents sportifs vers des bourses sportives. Avec des experts passionnés et expérimentés, Sport Scholarships America soutient les étudiants-athlètes à chaque étape du parcours, de l'orientation initiale à la placement final. Leurs services comprennent :
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Un entretien personnel pour discuter des souhaits, des possibilités et des attentes de l'étudiant-athlète.</li>
        <li>Une évaluation professionnelle du niveau académique et sportif de l'étudiant-athlète.</li>
        <li>Un vaste réseau de contacts avec des entraîneurs, des universités et des collèges.</li>
        <li>Promotion efficace du profil et des vidéos de l'étudiant-athlète auprès des entraîneurs potentiels.</li>
        <li>Conseils d'experts sur les meilleures options en fonction des préférences, des objectifs et du budget de l'étudiant-athlète.</li>
        <li>Négociation des meilleures offres de bourses au nom de l'étudiant-athlète.</li>
        <li>Aide à la demande de documents nécessaires, tels que visa, relevé de notes, diplôme et tests d'admission.</li>
        <li>Préparation à la vie d'étudiant-athlète pendant sa bourse, y compris culture, langue, règles et conseils.</li>
        <li>Suivi et accompagnement pendant la période de la bourse.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vous voulez explorer les opportunités de bourses et atteindre vos objectifs sportifs et académiques ? <Link to="/fr/Services-Produits">Consultez la gamme complète</Link> des services offerts par Sport Scholarships America et planifiez une consultation dès aujourd'hui.
    </MKTypography>
  </MKBox>
</MKBox>
          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;