// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

import React from "react";
import { PopupButton } from "react-calendly";
import "./Button.css"
import { Helmet } from 'react-helmet';


// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbarSvenska";
import DetailedFooter from "examples/Footers/DetailedFooter";

// Coworking page sections
import Testimonials from "Languages/Svenska/pages/Homepage/LandingPage/sections/Overmij";
import StatsOne from "Languages/Svenska/pages/Homepage/LandingPage/sections/StatsOne";
import BlogPostThree from "Languages/Svenska/pages/Homepage/LandingPage/sections/Niveaus";
import MapUSA from "Languages/Svenska/pages/Homepage/LandingPage/sections/MapUSA";
import Faq from "Languages/Svenska/pages/Homepage/LandingPage/sections/Faq";
import NewsletterOne from "Languages/Svenska/pages/Homepage/LandingPage/sections/Nieuwsbrief";
import SelectPicker from "Languages/Svenska/pages/Homepage/LandingPage/sections/States";


// Routes
import routes from "routes_Svenska";
import footerRoutes from "footer.routes_Svenska";

import "../H1asH2.css"
// Images
import bgImage from "assets/images/Achtergronden/Home_BG.png";

function LandingPage() {
  return (
    <>
    <Helmet>
    <title>Din Väg Till Ett Stipendium i Amerika - Sport Scholarships America</title>
    <meta name="description" content="Starta din resa till ett sportstipendium i Amerika. Få expertvägledning och all information du behöver för att studera och idrotta i USA." />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta charset="UTF-8" />
    <meta name="robots" content="index, follow" />
    
    {/* Open Graph Tags */}
    
    
    
    
    

    {/* Favicon */}
    <link rel="icon" href="https://sportscholarshipsamerica.com/32x32Sportbeurs.png" />
</Helmet>

      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://calendly.com/sportbeurs-amerika/intake",
          label: "BOKA TID",
          color: "info",
        }}
        transparent
        light
         
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 0.5)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        {/* Visuellt dold bild för skärmläsare */}
        <img 
          src={bgImage} 
          alt="Universitetscampus i USA i bakgrunden, med en grön gräsmatta framför. I förgrunden ligger tre sportbollar: en basketboll, fotboll och amerikansk fotboll, som symboliserar fokus på atletiska stipendier för internationella studenter." 
          style={{ 
            width: '1px', 
            height: '1px', 
            position: 'absolute', 
            overflow: 'hidden', 
            clip: 'rect(1px, 1px, 1px, 1px)', 
            whiteSpace: 'nowrap' 
          }} 
        />
        <Container>
          <Grid
            container
            item
            xs={12}
            md={7}
            justifyContent={{ xs: "center", md: "start" }}
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            <MKTypography
              variant="h1"
              className="h1-as-h2"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
Din Väg Till Ett Idrottsstipendium i Amerika            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              mt={1}
              pr={{ md: 12, lg: 24, xl: 32 }}
              opacity={0.8}
            >
Expertvägledning för Student-Idrottare till Topuniversiteten i USA.            </MKTypography>
            <Stack direction="row" spacing={1} mt={4} mb={3}>
              <PopupButton
                url="https://calendly.com/sportbeurs-amerika/intake"
                rootElement={document.getElementById("root")}
                text="BOKA TID"
                className="custom-calendly-button"
              />
            </Stack>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <StatsOne />
        <Testimonials />
        <Container>
          <Grid container justifyContent="center" sx={{ pt: 6, pb: 0 }}>
            <Grid item xs={12} md={7} sx={{ textAlign: "center" }}>
              <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom mb={3}>
Möjligheter över Hela Nationen            </MKTypography>
              <MKTypography color="text" variant="body2" align="center" gutterBottom mb={5}>
Från Kaliforniens stränder till New Yorks skyline och Floridas kust: Jag kopplar ihop student-idrottare med toppuniversiteten i alla 50 staterna i Amerika            </MKTypography>
            </Grid>
          </Grid>
        </Container>
        <MapUSA />
        <SelectPicker />
        <BlogPostThree />
        <Faq />
        <NewsletterOne />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DetailedFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default LandingPage;
