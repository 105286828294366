/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import Image1 from "assets/images/Sports_Images/Tennis1.jpg";
import Image2 from "assets/images/Sports_Images/Tennis2.jpeg";
import Image3 from "assets/images/Sports_Images/Tennis3.jpg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Tennistoelage i USA: Sådan Studerer og Spiller Man Tennis i Amerika', isSubheader: false },
  
    { id: 'Hoofdstuk 1', title: 'Kapitel 1: Historie og Udvikling af Tennis i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Tidlige År og Universiteternes Indflydelse', isSubheader: true },
    { id: '1.2', title: '1.2 Vækst og Professionalisering', isSubheader: true },
  
    { id: 'Hoofdstuk 2', title: 'Kapitel 2: Universitetstennis i Dag', isSubheader: false },
    { id: '2.1', title: '2.1 Nøglekonkurrencer og Konferencer', isSubheader: true },
    { id: '2.2', title: '2.2 Topprogrammer og Prestigefyldte Hold', isSubheader: true },
  
    { id: 'Hoofdstuk 3', title: 'Kapitel 3: Fra College til Professionel Tennis', isSubheader: false },
    { id: '3.1', title: '3.1 Vejen til Professionalisme', isSubheader: true },
    { id: '3.2', title: '3.2 Professionelle Tenniscirkler', isSubheader: true },
  
    { id: 'Hoofdstuk 4', title: 'Kapitel 4: Internationale Indflydelser', isSubheader: false },
    { id: '4.1', title: '4.1 Udenlandske Spillere i Collegiat Scenen', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanere på den Internationale Scene', isSubheader: true },
  
    { id: 'Hoofdstuk 5', title: 'Kapitel 5: Sportslegater og fremtiden for atleter', isSubheader: false },
    { id: '5.1', title: '5.1 Vigtigheden af legater', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Legater Amerika Kan Hjælpe', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>


























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Tennistoelage i USA: Sådan Studerer og Spiller Man Tennis i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Georgia tennis kompleks med fyldte tribuner under en universitetskamp."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Fyldte tribuner ved Georgia tennis kompleks.
      </figcaption>
    </figure>
    Tennis er en sport, der har været spillet i USA i mere end et århundrede. Det begyndte som en rekreativ aktivitet for eliten, men udviklede sig hurtigt til en konkurrencepræget og professionel sport, der tiltrak millioner af fans og spillere over hele verden. En af de afgørende faktorer for vækst og udvikling af tennis i Amerika er det kollegiale system. Universiteterne tilbyder ikke kun en førsteklasses uddannelse, men også fremragende faciliteter, træning, vejledning og legater til talentfulde tennisspillere. At spille tennis på collegeniveau giver spillere mulighed for at forbedre deres færdigheder, udvide deres netværk, øge deres chancer for en professionel karriere og samtidig opnå en værdifuld grad.
    <br />
    <br />
    Men hvordan kan en ung tennisspiller fra Holland eller Belgien tage til Amerika for at studere og spille tennis? Svaret er med en tennistoelage. En tennistoelage er økonomisk støtte, som et universitet giver til en spiller, der opfylder visse akademiske og sportslige kriterier. Med en tennistoelage kan du dække eller reducere dine undervisningsafgifter, bøger, bolig, måltider og andre udgifter. En tennistoelage kan variere fra et fuldt legat, der dækker alle omkostninger, til et delvist legat, der dækker en procentdel af omkostningerne.
    <br />
    <br />
    Men hvordan får du en tennistoelage? Det er her Sportbeursamerika.nl kan hjælpe dig. Sportbeursamerika.nl er en platform, der hjælper unge tennisspillere med at realisere deres drøm om at tage til Amerika. Sportbeursamerika.nl tilbyder rådgivning, vejledning, formidling og støtte til at finde det bedste universitet og legat for dig. Sportbeursamerika.nl har et team af eksperter, der forstår, hvordan det amerikanske collegiale system fungerer, og har kontakter til hundredvis af trænere og universiteter. Sportbeursamerika.nl har allerede hjulpet mange spillere med at nå deres potentiale og ændre deres liv.
    <br />
    <br />
    Vil du vide mere om tennis i Amerika og hvordan Sportbeursamerika.nl kan hjælpe dig? Bliv ved med at læse!
  </MKTypography>
</MKBox>


<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Historie og Udvikling af Tennis i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Tennis er en sport, der har sit oprindelse i Europa, men hurtigt spredte sig til andre dele af verden, især til USA. I dette kapitel vil vi udforske, hvordan tennis har udviklet sig i USA, og hvordan universiteter har spillet en betydelig rolle i sportens udvikling og professionalisering.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidlige År og Universiteternes Indflydelse
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Tennis blev først introduceret i USA i begyndelsen af det 19. århundrede af velhavende amerikanere, der havde lært spillet under deres rejser til Europa. Det blev hurtigt en populær fritidsaktivitet for eliten, der byggede tennisbaner på deres ejendomme og klubber. Den første officielle tennis kamp i USA blev spillet i 1874 mellem to engelske brødre, James Dwight og Fred Renshaw, begge studerende på Harvard University. De spillede på en tennisbane, de havde bygget på deres onkels ejendom i Nahant, Massachusetts. De første nationale mesterskaber, dengang kendt som US National Lawn Tennis Association Men's Singles Championship, blev afholdt i 1881 i Newport, Rhode Island.
      <br />
      <br />
      Universiteter var blandt de første institutioner til at omfavne tennis som en konkurrencesport. De organiserede interkollegiale turneringer og dannede hold for at konkurrere mod hinanden. De første interkollegiale tennis mesterskaber blev afholdt i 1883, med deltagelse af Harvard, Yale, Princeton og Columbia, og i 1885 blev det første officielle interkollegiale tennis mesterskab anerkendt. Disse universiteter blev hurtigt kendt som de "Store Fire" inden for amerikansk collegiat tennis. De dominerede sporten indtil begyndelsen af det 20. århundrede, hvor andre universiteter som Stanford, California, Chicago og Cornell sluttede sig til dem.
      <br />
      <br />
      Universiteter spillede også en betydelig rolle i at fremme og udbrede tennis blandt forskellige grupper af mennesker. De gav muligheder for kvinder, afroamerikanere, indvandrere og andre minoriteter for at øve sig og brillere i sporten. Et af de mest bemærkelsesværdige eksempler er Althea Gibson, som i 1950 blev den første afroamerikanske kvinde, der blev optaget til det nationale mesterskab. Hun vandt sin første Grand Slam-titel ved French Championships i 1956 efter at have taget eksamen fra Florida A&M University og være blevet medlem af American Tennis Association, en organisation for sorte tennisspillere. Hun vandt senere Wimbledon to gange og US Open to gange.
    </MKTypography>
  </MKBox>

  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Vækst og Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Anden halvdel af det 20. århundrede var præget af betydelig vækst og professionalisering af tennis i Amerika. Dette blev muliggjort delvist af opkomsten af fjernsynet, der gav sporten mere synlighed og popularitet. Derudover blev der introduceret nye teknologier og materialer, der gjorde spillet hurtigere og mere spændende. Der blev desuden etableret nye organisationer og cirkler for at regulere og promovere tennis, herunder International Tennis Federation (ITF), Association of Tennis Professionals (ATP) og Women's Tennis Association (WTA).
      <br />
      <br />
      Disse ændringer påvirkede også collegiat tennis, som måtte tilpasse sig den nye virkelighed inden for professionel tennis. Universiteter skulle konkurrere med andre muligheder for at tiltrække og fastholde talentfulde spillere. De skulle også forbedre deres programmer for at forberede spillere på en potentiel professionel karriere. Et af de vigtigste skridt, universiteterne tog, var oprettelsen af ​​National Collegiate Athletic Association (NCAA), en overordnet organisation ansvarlig for at organisere og regulere collegiale sportsgrene i USA. NCAA opdelte universiteterne i tre divisioner baseret på deres størrelse, budget og konkurrenceniveau. NCAA arrangerer årlige nationale mesterskaber for hvert niveau, både for hold og enkeltpersoner.
      <br />
      <br />
      En anden konsekvens af professionaliseringen af tennis var, at mange amerikanske spillere brillerede på det højeste niveau i sporten. Nogle af dem havde en collegiat baggrund, hvilket viser, at collegiat tennis kan give et solidt fundament for en succesfuld professionel karriere. Nogle af de mest kendte amerikanske tennisspillere, der spillede på universitetsniveau, inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Arthur Ashe, der studerede på UCLA og var den første afroamerikanske mand, der vandt Wimbledon, US Open og Australian Open.
        </li>
        <li>
          John McEnroe, der gik på Stanford og vandt syv Grand Slam single titler og ni i double.
        </li>
        <li>
          Jimmy Connors, der studerede på UCLA og vandt otte Grand Slam single titler og to i double.
        </li>
        <li>
          Billie Jean King, der gik på California State University, Los Angeles, og vandt 12 Grand Slam single titler og 16 i double. Hun var også en pioner inden for kampen for ligestilling mellem kønnene inden for sport.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  








<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Universitetstennis i Dag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Tennis forbliver en af de mest populære og prestigefyldte sportsgrene i det amerikanske collegiale system. Hvert år deltager tusindvis af spillere i forskellige konkurrencer og konferencer og viser det bedste fra sporten. I dette kapitel vil vi udforske de store konkurrencer og konferencer i collegiat tennis samt de øverste programmer og prestigefyldte hold, der dominerer sporten.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Nøglekonkurrencer og Konferencer
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Cameron Norrie i aktion for TCU i collegiat tennis."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Cameron Norrie i aktion som TCU-spiller
        </figcaption>
      </figure>
      Som vi så i det foregående kapitel, er NCAA den primære organisation, der er ansvarlig for at organisere og regulere collegiat sport i USA. NCAA opdeler universiteterne i tre divisioner: Division I, Division II og Division III. Hver division har sine egne regler, krav og mesterskaber. For tennis er der to store konkurrencer arrangeret af NCAA: holdmesterskabet og individuelt mesterskab.
      <br />
      <br />
      Holdmesterskabet er en knockout-turnering spillet blandt de bedste hold i hver division. Holdene udvælges baseret på deres præstation i løbet af sæsonen, deres rangering og deres regionale resultater. Holdmesterskabet består af to faser: den regionale fase og den nationale fase. Den regionale fase spilles blandt hold fra samme region, der er opdelt i fire geografiske zoner: Northeast, Southeast, Midwest og West. Vinderne af hver region avancerer til den nationale fase, der spilles blandt 16 hold fra hver division. Holdmesterskabet følger formatet af Davis Cup, hvilket betyder, at hver kamp består af tre double kampe og seks single kampe.
      <br />
      <br />
      Det individuelle mesterskab er en knockout-turnering spillet blandt de bedste spillere i hver division. Spillere udvælges baseret på deres rangering, deres præstation i holdmesterskabet og deres resultater i andre turneringer. Det individuelle mesterskab består af to kategorier: singler og doubler. Hver kategori har 64 spillere i Division I, 48 spillere i Division II og 32 spillere i Division III.
      <br />
      <br />
      Udover NCAA-mesterskaberne er der også andre konkurrencer spillet blandt universiteter, der tilhører den samme konference. En konference er en gruppe universiteter, der samarbejder inden for akademiske, atletiske og administrative områder. Der er mere end 30 konferencer i USA, hver med sine egne regler, tidsplaner og mesterskaber. Nogle af de mest kendte konferencer inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Atlantic Coast Conference (ACC), bestående af 15 universiteter fra East Coast, såsom Duke, North Carolina, Virginia og Florida State.
        </li>
        <li>
          Big Ten Conference, bestående af 14 universiteter fra Midwest, såsom Michigan, Ohio State, Illinois og Wisconsin.
        </li>
        <li>
          Pacific-12 Conference (Pac-12), bestående af 12 universiteter fra West Coast, såsom Stanford, California, UCLA og USC.
        </li>
        <li>
          Southeastern Conference (SEC), bestående af 14 universiteter fra Southeast, såsom Georgia, Florida, Kentucky og Vanderbilt.
        </li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Disse konferencer er vigtige ikke kun for den atletiske rivalisering mellem universiteterne, men også for deres akademiske ry og prestige. Derudover giver de flere muligheder for spillere at brillere og kvalificere sig til nationale mesterskaber.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Topprogrammer og Prestigefyldte Hold
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Konkurrence niveauet i collegiat tennis er ekstremt højt, hvilket betyder, at kun de bedste spillere og hold kan trives og lykkes. Dog har nogle universiteter opbygget en lang tradition og et stærkt ry i tennis, hvilket fører til topprogrammer, der opnår fremragende resultater år efter år. Disse universiteter har ikke kun de bedste faciliteter, trænere og legater, men excellerer også i rekruttering, træning og vejledning af deres spillere. Nogle af de mest succesfulde og prestigefyldte collegiat tennisprogrammer inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Stanford University, som har vundet 21 NCAA holdtitler i damestennis og 17 i herretennis, mere end noget andet universitet. Stanford har også vundet 25 NCAA individuelle titler i damestennis og 14 i herretennis. Nogle af de mest kendte spillere, der spillede for Stanford, inkluderer John McEnroe, Bob og Mike Bryan, Nicole Gibbs og Mallory Burdette.
        </li>
        <li>
          USC (University of Southern California), som har vundet næsten lige så mange NCAA holdtitler som Stanford i herretennis og et par i damestennis. USC har også vundet flere NCAA individuelle titler end noget andet universitet i herretennis og nogle i damestennis. Nogle af de mest kendte spillere, der spillede for USC, inkluderer Stan Smith, Alex Olmedo, Steve Johnson og Danielle Collins.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>
                              






                                  







<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Fra College til Professionel Tennis
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    En af de største drømme for mange unge tennisspillere er at forfølge en professionel karriere og skinne på verdensscenen. Men hvordan kan du gøre den drøm til virkelighed? Er collegiat tennis en god forberedelse til det professionelle cirkus? I dette kapitel vil vi se på vejen til professionalisme og hvordan collegiat tennis kan hjælpe spillere med at tage det spring.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Vejen til Professionalisme
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      At blive professionel tennisspiller er ikke en nem opgave. Det kræver ikke kun masser af talent, men også dedikation, disciplin, hårdt arbejde og ofre. Det kræver også at træffe vigtige beslutninger, der kan påvirke din fremtid. En af de mest afgørende beslutninger, du skal træffe, er, om du skal gå på college eller ej.
      <br />
      <br />
      Der er fordele og ulemper ved begge muligheder. Hvis du vælger at blive professionel med det samme efter gymnasiet, kan du fokusere fuldt ud på din tennis og forsøge at sikre dig en plads i rangeringen så hurtigt som muligt. Du kan også drage fordel af din ungdom, energi og potentiale. Dog kan du også stå over for mange risici, såsom skader, udbrændthed, økonomiske problemer eller manglende resultater. Derudover kan du være nødt til at opgive eller udsætte din akademiske uddannelse, hvilket begrænser dine muligheder, hvis tennis ikke lykkes.
      <br />
      <br />
      Hvis du vælger at gå på college, kan du nyde de mange fordele, som collegiat tennis tilbyder. Ikke kun kan du modtage en uddannelse af høj kvalitet, der kan hjælpe dig med at få en grad og have en alternativ karriere, men du kan også nyde en fantastisk livserfaring, der kan hjælpe dig med at vokse som person. Du kan også drage fordel af de fremragende faciliteter, træning, vejledning og legater, som universiteterne tilbyder. Dog kan du også støde på ulemper såsom at miste tid og muligheder for at blive professionel, sakke bagud i forhold til andre spillere, der startede tidligere, eller finde det udfordrende at skifte fra college til det professionelle cirkus.
      <br />
      <br />
      Der er ikke et universelt svar på, om du skal gå på college eller ej. Det afhænger af din personlige situation, mål, præferencer og muligheder. Nogle spillere har haft succes ved at blive professionelle med det samme, mens andre har haft gavn af deres college-oplevelse. Det vigtigste er at træffe en informeret beslutning, der passer til dig.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professionelle Tenniscirkler
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Hvis du beslutter dig for at blive professionel tennisspiller, skal du forstå, hvordan de professionelle tenniscirkler fungerer, og hvordan du kommer derhen. Der er forskellige cirkler for mænd og kvinder, hver med deres egne organisationer, regler og turneringer.
      <br />
      <br />
      For mænd er der to store cirkler: ATP Touren og ITF Verden Tennis Touren. ATP Touren er det højeste niveau af professionel herretennis, hvor de bedste spillere i verden konkurrerer om prestigefyldte titler og pengepræmier. ATP Touren består af fire kategorier af turneringer: Grand Slam, ATP Finals, ATP Masters 1000 og ATP 250/500. For at deltage i disse turneringer skal du have en høj rangering, der bestemmes af antallet af point, du optjener ved at vinde kampe.
      <br />
      <br />
      ITF Verden Tennis Touren er det lavere niveau af herreprofessionel tennis, hvor spillere sigter mod at forbedre deres rangering og kvalificere sig til ATP Touren. ITF Verden Tennis Touren består af tre kategorier af turneringer: ITF Verden Tennis Touren M25/M15, ITF Verden Tennis Touren M25/M15+H og ITF Verden Tennis Touren M15/M15+H. For at deltage i disse turneringer skal du have en ITF-rangering, der bestemmes af antallet af point, du optjener ved at vinde kampe.
      <br />
      <br />
      For kvinder er der også to store cirkler: WTA Touren og ITF Verden Tennis Touren. WTA Touren er det højeste niveau af professionel damestennis, hvor de bedste spillere i verden konkurrerer om prestigefyldte titler og pengepræmier. WTA Touren består af fire kategorier af turneringer: Grand Slam, WTA Finals, WTA Premier og WTA International. For at deltage i disse turneringer skal du have en høj rangering, der bestemmes af antallet af point, du optjener ved at vinde kampe.
      <br />
      <br />
      ITF Verden Tennis Touren er det lavere niveau af dameprofessionel tennis, hvor spillere sigter mod at forbedre deres rangering og kvalificere sig til WTA Touren. ITF Verden Tennis Touren består af tre kategorier af turneringer: ITF Verden Tennis Touren W100/W60/W25/W15, ITF Verden Tennis Touren W100/W60/W25/W15+H og ITF Verden Tennis Touren W15/W15+H. For at deltage i disse turneringer skal du have en ITF-rangering, der bestemmes af antallet af point, du optjener ved at vinde kampe.
      <br />
      <br />
      For at skifte fra collegiat tennis til professionel tennis skal du opbygge en rangering i ITF Verden Tennis Touren først og derefter forsøge at kvalificere dig til ATP eller WTA Touren. Dette kan være en udfordrende og langvarig proces, der kræver meget indsats, tålmodighed og beslutsomhed. Det er dog ikke umuligt, og der er mange eksempler på spillere, der succesfuldt har foretaget overgangen og nu stråler på det professionelle cirkus.
    </MKTypography>
  </MKBox>
</MKBox>







                                  







<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationale Indflydelser
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Tennis er en global sport, der bringer mennesker fra forskellige nationaliteter, kulturer og baggrunde sammen. Dette gælder også for collegiat tennis i Amerika, som har en betydelig diversitet og rigdom af internationale indflydelser. I dette kapitel vil vi udforske, hvordan udenlandske spillere har påvirket collegiat scenen, og hvordan amerikanske spillere har nået den internationale scene.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Udenlandske Spillere i Collegiat Scenen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Spillere fra Virginia fejrer mesterskabet i collegiat tennis."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Spillere fra Virginia fejrer mesterskabet
        </figcaption>
      </figure>
      En af de mest bemærkelsesværdige træk ved collegiat tennis i Amerika er den høje procentdel af udenlandske spillere, der deltager i sporten. Ifølge Intercollegiate Tennis Association (ITA) var der i 2020 over 3.000 udenlandske spillere, der konkurrerede for amerikanske universiteter, hvilket udgjorde ca. 40% af det samlede antal spillere. Disse spillere kom fra mere end 100 lande, hvor de mest almindelige lande var: Storbritannien, Tyskland, Frankrig, Spanien, Australien, Canada, Brasilien, Japan, Kina og Indien.
      <br />
      <br />
      Årsagerne til, at udenlandske spillere vælger at komme til Amerika for at studere og spille tennis, er forskellige. Nogle søger en bedre akademisk uddannelse, som de ikke kan finde i deres eget land. Andre leder efter bedre sportsudviklingsmuligheder, som ikke er tilgængelige i deres hjemland. Endnu andre søger en ny livserfaring, som de ikke kan have i deres eget land. Uanset deres motivation bringer udenlandske spillere mange fordele til amerikansk collegiat tennis.
      <br />
      <br />
      En af fordelene er, at udenlandske spillere hæver niveauet og kvaliteten af spillet. De bringer nye færdigheder, stilarter, taktikker og strategier, der gør spillet mere varieret og interessant. De udfordrer også amerikanske spillere til at gøre deres bedste og tilpasse sig forskellige situationer. Desuden bidrager de til prestige og ry af de universiteter, de spiller for, og tiltrækker mere opmærksomhed og anerkendelse.
      <br />
      <br />
      En anden fordel er, at udenlandske spillere beriger kulturen og atmosfæren i spillet. De bringer nye perspektiver, ideer, værdier og traditioner, der gør spillet mere åbent og inkluderende. De skaber også en følelse af fællesskab og venskab blandt spillere fra forskellige baggrunde, der samarbejder og støtter hinanden. Desuden lærer de af hinanden og deler deres erfaringer, hvilket udvider deres horisonter og fremmer personlig vækst.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikanere på den Internationale Scene
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Mens udenlandske spillere har en betydelig indflydelse på amerikansk collegiat tennis, har amerikanske spillere også haft en stor indflydelse på international tennis. Mange amerikanske spillere, der spillede på college, fandt senere succes på den professionelle scene og repræsenterede deres land på verdensscenen. Nogle af disse spillere inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          John Isner, der gik på University of Georgia og var fire gange All-American. Han er kendt for sin kraftfulde serv og lange kampe, såsom den længste kamp i historien mod Nicolas Mahut på Wimbledon i 2010. Han har vundet 15 ATP-titler og nåede fjerdepladsen i verdensranglisten.
        </li>
        <li>
          Kevin Anderson, der gik på University of Illinois og var to gange All-American. Han er kendt for sit stærke baselinespil og konsekvente præstationer. Han har vundet seks ATP-titler og nåede to Grand Slam-finaler: US Open i 2017 og Wimbledon i 2018.
        </li>
        <li>
          Danielle Collins, der gik på University of Virginia og var to gange NCAA-mester. Hun er kendt for sin aggressive og udtryksfulde spillestil og sin beslutsomhed. Hun har vundet en WTA-titel og nåede semifinalen i Australian Open i 2019.
        </li>
        <li>
          Jennifer Brady, der gik på UCLA og var en gang All-American. Hun er kendt for sine kraftfulde slag og forbedret bevægelse. Hun har vundet en WTA-titel og nåede finalen i Australian Open i 2021.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>



                                              






                                  


<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Sportslegater og fremtiden for atleter
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sportslegater tilbyder talrige muligheder for unge atleter til at vise deres talent og udvikle deres færdigheder. En af de mest betydningsfulde muligheder er at opnå et sportslegat for at studere og konkurrere på kollegialt niveau. I dette kapitel vil vi diskutere vigtigheden af sportslegater og hvordan Sport Legater Amerika kan hjælpe dig med at realisere din drøm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vigtigheden af legater
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et sportslegat er økonomisk støtte, som et universitet tilbyder til en studerende-atlet for at kombinere studier og sport. Det kan dække omkostningerne til undervisning, bøger, bolig, måltider og andre udgifter. Derudover kan det også tilbyde fordele såsom akademisk vejledning, lægehjælp, træningsfaciliteter og udstyr.
      <br />
      <br />
      At opnå et sportslegat er konkurrencepræget, hvor mange faktorer spiller en rolle i tildelingsprocessen, herunder akademiske præstationer, atletisk præstation, personlighed, motivation, lederskab og tilpasningsevne. Derfor er det vigtigt at forberede sig godt og adskille sig fra konkurrencen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Legater Amerika Kan Hjælpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo af Sport Legater Amerika, der viser sportsudstyr og en eksamenshue, der repræsenterer fokus på sportslegater for studerende-atleter."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo af Sport Legater Amerika
        </figcaption>
      </figure>
      Sport Legater Amerika er en organisation specialiseret i at guide unge sports talenter til sportslegater. Med passionerede og erfarne eksperter støtter Sport Legater Amerika studerende-atleter på hvert trin af rejsen, fra indledende orientering til endelig placering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intake-interview for at diskutere den studerende-athlets ønsker, muligheder og forventninger.</li>
        <li>En professionel evaluering af den studerende-athlets atletiske og akademiske niveau.</li>
        <li>Et omfattende netværk af kontakter med trænere, universiteter og kollegier.</li>
        <li>Effektiv markedsføring af den studerende-athlets profil og videoer til potentielle trænere.</li>
        <li>Ekspertrådgivning om de bedste muligheder baseret på den studerende-athlets præferencer, mål og budget.</li>
        <li>Forhandling af de bedste legat tilbud på vegne af den studerende-atlet.</li>
        <li>Hjælp til ansøgning om nødvendige dokumenter, såsom visum, transkript, eksamensbevis og optagelsesprøver.</li>
        <li>Forberedelse til den studerende-atlets liv under deres legat, herunder kultur, sprog, regler og tips.</li>
        <li>Efterpleje og opfølgning under legatperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ønsker du at udforske legatmuligheder og opnå dine sportslige og akademiske mål? <Link to="/da/Tjenester-Produkter">Se det fulde udvalg</Link> af tjenester tilbudt af Sport Legater Amerika og planlæg en konsultation i dag.
    </MKTypography>
  </MKBox>
</MKBox>

          
          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;