/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Track and Field1.jpg";
import Image2 from "assets/images/Sports_Images/Track and Field2.jpeg";
import Image3 from "assets/images/Sports_Images/Track and Field3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Track and Field and Studying in America: A Dream That Can Come True', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Chapter 1: History and Development of Track and Field at the College Level', isSubheader: false },
    { id: '1.1', title: '1.1 Early Years and Origins', isSubheader: true },
    { id: '1.2', title: '1.2 Growth and Professionalization', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Chapter 2: College Level Track and Field Today', isSubheader: false },
    { id: '2.1', title: '2.1 Major Competitions and Conferences', isSubheader: true },
    { id: '2.2', title: '2.2 Top Programs and Prestigious Teams', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Chapter 3: From College to Professional', isSubheader: false },
    { id: '3.1', title: '3.1 The Path to Professionalism', isSubheader: true },
    { id: '3.2', title: '3.2 Relationship with Professional Athletics', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Chapter 4: International Influences and Opportunities', isSubheader: false },
    { id: '4.1', title: '4.1 Foreign Athletes and Competitions', isSubheader: true },
    { id: '4.2', title: '4.2 American Athletes Abroad', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Chapter 5: Sports Scholarships and the Future of American Football', isSubheader: false },
    { id: '5.1', title: '5.1 The Importance of Scholarships', isSubheader: true },
    { id: '5.2', title: '5.2 How Sport Scholarships America Can Help', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contents
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Hide</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                      key={section.id}
                      onClick={() => scrollTo(section.id)}
                      color="secondary"
                      variant="gradient"
                      sx={{ 
                        justifyContent: "flex-start",
                        pl: section.isSubheader ? 4 : 2,
                        textTransform: "none",
                        fontWeight: section.isSubheader ? "normal" : "bold",
                        borderTopLeftRadius: index === 0 ? '20px' : '0',
                        borderTopRightRadius: index === 0 ? '20px' : '0',
                        borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                        borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                        textAlign: "left",
                        width: '100%',
                      }}
                    >
                      {section.title}
                    </MKButton>
                  ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Show</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>


























            <MKBox id="intro" mb={3}>
              <MKTypography variant="h1" className="h1-as-h3" mb={2}>
                Track and Field and Studying in America: A Dream That Can Come True
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                  <img
                    src={Image1}
                    alt="Runners in full action during the Penn State Relays, a prominent college track and field event."
                    style={{
                      maxWidth: "100%",
                      border: "2px solid #fff",
                      boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                      cursor: "pointer",
                      outline: "none",
                    }}
                    onClick={() => openModal1(Image1)}
                  />
                  <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                  }}>
                    Action at the Penn State Relays.
                  </figcaption>
                </figure>
                Track and field is one of the most popular and significant sports in the United States. Millions of people practice track and field as a hobby, as part of their school or college program, or as professional athletes. Track and field is also a sport that offers many opportunities for young talents to develop, compete, and excel at the highest level.
                <br/>
                <br/>
                One of the best ways to take advantage of these opportunities is by getting a track and field scholarship in the U.S. A track and field scholarship is financial support you receive from a university to study and participate in track and field there. With a track and field scholarship, you can not only earn your academic degree but also participate in one of the most prestigious and competitive track and field programs in the world.
                <br/>
                <br/>
                But how do you get a track and field scholarship in the U.S.? What are the benefits and challenges of studying and competing at the college level? And how can you prepare for a successful career as an athlete after your studies? These are some of the questions we will answer in this article.
                <br/>
                <br/>
                We will also tell you how Sportbeursamerika.nl can help you make your dream come true. Sportbeursamerika.nl is an organization specialized in guiding young athletes to sports scholarships in the U.S. I have a lot of experience in finding the best match between athletes and universities. I offer personal guidance, professional advice, and practical support at every step of the process.
                <br/>
                <br/>
                If you are interested in getting a track and field scholarship in the U.S., this article is for you. Read on and discover everything you need to know about track and field at the college level in the U.S.
              </MKTypography>
              {/* Add bulleted list or other content as needed */}
            </MKBox>
            {/* Main chapters and subchapters with placeholder text */}
            <MKBox id="Chapter 1" mb={3}>
              <MKTypography variant="h2" className="h1-as-h3" mb={2}>
                Chapter 1: History and Development of Track and Field at the College Level
              </MKTypography>
              <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                Track and field at the college level has a long and rich history in the U.S. It began in the 19th century as a way to promote the physical and mental health of students. It grew into a major sport that attracted millions of spectators and produced many athletes who later became world-famous.
              </MKTypography>

              <MKBox id="1.1" mb={2}>
                <MKTypography variant="h4" mb={1}>
                  1.1 Early Years and Origins
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                  The first track and field competition at universities took place in 1864, when students from Yale and Oxford challenged each other for a match in London. The competition consisted of four events: running, long jump, high jump, and pole vault. Yale won the match 7-3.
                  <br/>
                  <br/>
                  This match inspired other universities to also form track and field teams and compete against each other. In 1873, the first intercollegiate track and field association was founded, the Intercollegiate Association of Amateur Athletes of America (IAAAA). The IAAAA organized annual championships for universities in the eastern U.S.
                  <br/>
                  <br/>
                  The late 19th century was an important era for the development of track and field. It was marked by the formalization of rules and the emergence of influential figures such as James E. Sullivan, co-founder of the Amateur Athletic Union in the U.S. He played a significant role in popularizing track and field and organizing major events. The introduction of the modern Olympic Games in 1896 gave an international platform to the sport, leading to the standardization of rules and events. Also, technological advancements, such as improvements in footwear and running tracks, contributed to the evolution of track and field during this period.
                </MKTypography>
              </MKBox>
              <MKBox id="1.2" mb={2}>
                <MKTypography variant="h4" mb={1}>
                  1.2 Growth and Professionalization
                </MKTypography>
                <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
                  In the 20th century, track and field became increasingly popular and important at universities. The number of track and field programs, competitions, and scholarships increased. The level of the athletes also rose, thanks in part to better training, facilities, and coaching.
                  <br/>
                  <br/>
                  One of the factors that contributed to the growth and professionalization of track and field at the college level was the founding of the National Collegiate Athletic Association (NCAA) in 1906. The NCAA is an organization that oversees the sports activities of more than 1000 universities in the U.S. The NCAA regulates, among other things, the rules, qualifications, scholarships, and sanctions for track and field at the college level.
                  <br/>
                  <br/>
                  Another factor that influenced the development of track and field at the college level was the relationship with professional track and field. Many athletes who were successful at the college level later transitioned to the professional circuit. Some examples are Jesse Owens, Carl Lewis, Jackie Joyner-Kersee, and Michael Johnson.
                </MKTypography>
              </MKBox>
            </MKBox>








                                              





                                  









            <MKBox id="Chapter 2" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Chapter 2: College Level Track and Field Today
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        College level track and field is today one of the most popular and prestigious sports in the U.S. Each year, thousands of athletes participate in various competitions and events, attracting millions of fans and media attention. College level track and field also offers many opportunities for athletes to develop, perform, and enjoy their sport.
    </MKTypography>

    <MKBox id="2.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.1 Major Competitions and Conferences
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image2}
                    alt="Sydney McLaughlin, excelling as an athlete for the University of Kentucky, in competition uniform."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal2(Image2)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Sydney McLaughlin studied at the University of Kentucky
                </figcaption>
            </figure>
            The main organization regulating college level track and field is the NCAA. The NCAA divides universities into three divisions, based on their size, budget, and level of competition. Division I is the highest division, where most top universities and athletes are. Divisions II and III are lower divisions, where universities have fewer scholarships and resources, but place more emphasis on academic performance.
            <br/>
            <br/>
            Within each division, there are different conferences, which are groups of universities that are geographically or historically connected. Each conference organizes its own championships and competitions for its members. Some of the most well-known conferences are the Big Ten, the Pac-12, the SEC, the ACC, and the Ivy League.
            <br/>
            <br/>
            The main events in the college track and field season are the NCAA championships, held annually in March (indoor) and June (outdoor). These championships determine which universities and athletes are the best in each division and event. The championships attract many spectators, sponsors, and media attention.
        </MKTypography>
    </MKBox>
    <MKBox id="2.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.2 Top Programs and Prestigious Teams
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            There are many universities known for their excellent track and field programs. These universities have a long tradition of success, high-quality coaching and facilities, and a strong appeal to talented athletes. Some of these universities are:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Oregon: Oregon is one of the most successful universities in track and field, especially in running. Oregon has won more than 30 NCAA titles and has produced many famous athletes, such as Steve Prefontaine, Alberto Salazar, Galen Rupp, and Matthew Centrowitz.
                </li>
                <li>
                    USC: USC is another top university in track and field, with a strong focus on sprinting, jumping, and throwing. USC has won more than 25 NCAA titles and has produced many Olympic medalists, such as Quincy Watts, Andre De Grasse, and Michael Norman.
                </li>
                <li>
                    Arkansas: Arkansas is a dominant university in track and field, especially during the indoor season. Arkansas has won more than 40 NCAA titles, of which more than 30 are indoor. Arkansas is known for its depth and versatility in various events.
                </li>
                <li>
                    Texas: Texas is one of the most versatile universities in track and field, with a good balance between men's and women's teams, and between individual and relay events. Texas has won more than 20 NCAA titles and has produced many world-class athletes, such as Sanya Richards-Ross, Trey Hardee, Michelle Carter, and Courtney Okolo.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            These universities offer not only a high level of competition and training for their athletes but also a great atmosphere and culture. The athletes are part of a close-knit community that supports and motivates them to reach their potential.
        </MKTypography>
    </MKBox>
</MKBox>












                                              






                                  









<MKBox id="Chapter 3" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Chapter 3: From College to Professional
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        College level track and field is not only a sport but also a training ground for athletes aspiring to a professional career. Many athletes who have performed at the college level have later transitioned to the professional circuit. College level track and field offers athletes the opportunity to develop, experience, and network with other athletes, coaches, and organizations.
    </MKTypography>

    <MKBox id="3.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.1 The Path to Professionalism
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            For many athletes, the goal is to become a professional athlete after their studies. This means practicing their sport as a profession and earning money through performances, contracts, and sponsorships. To become a professional athlete, athletes must meet several conditions, such as:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Having a high level of performance and potential. Professional athletes must be able to compete with the best athletes in the world, and continually improve and adapt to changing conditions.
                </li>
                <li>
                    Maintaining good health and fitness. Professional athletes must take good care of their bodies and protect themselves against injuries, illnesses, and overtraining. They must also find a good balance between training, rest, and nutrition.
                </li>
                <li>
                    Having good guidance and support. Professional athletes should be able to rely on a team of experts to help them with technique, tactics, mental preparation, legal matters, financial issues, and marketing.
                </li>
                <li>
                    Having good planning and strategy. Professional athletes need to know what goals they want to achieve, which competitions they want to participate in, how they want to qualify for major events, and how they want to manage their career.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            College level track and field can help athletes meet these conditions. It offers athletes the chance to showcase their talent, improve their skills, increase their experience, and expand their network. College level track and field can also serve as a springboard to the professional level by exposing athletes to high levels of competition, media attention, and public interest.
        </MKTypography>
    </MKBox>
    <MKBox id="3.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.2 Relationship with Professional Athletics
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            College level track and field has a close relationship with professional athletics. The two levels influence each other in various ways, such as:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Talent development: College level track and field is a major source of talent for professional athletics. Many professional athletes started as college athletes and laid the foundation for their later success there.
                </li>
                <li>
                    Talent scouting: Professional athletic organizations closely monitor college athletics to discover and attract potential stars. They offer college athletes contracts, sponsorships, and guidance to entice them to turn professional.
                </li>
                <li>
                    Talent exchange: College level track and field is also open to professional athletes who are still studying or returning to school. Some professional athletes choose to complete or resume their studies while continuing their sports career.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            College level track and field is therefore not just an endpoint but also a stepping stone for athletes pursuing a professional career. It offers athletes the opportunity to prepare for the professional level, but also to explore other paths.
        </MKTypography>
    </MKBox>
</MKBox>










                                              






                                  









<MKBox id="Chapter 4" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Chapter 4: International Influences and Opportunities
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        College level track and field is not just an American sport but also an international one. It is influenced by and offers opportunities for athletes from various countries and cultures. College level track and field is a sport that reflects the diversity and interconnectedness of the world.
    </MKTypography>

    <MKBox id="4.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.1 Foreign Athletes and Competitions
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image3}
                    alt="The famous Oregon athletic stadium, home to numerous college track and field events."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal3(Image3)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Oregon athletic stadium, an icon of college sports.
                </figcaption>
            </figure>
            One of the ways in which college level track and field is international is through the presence and performances of foreign athletes. Every year, hundreds of athletes from other countries come to the U.S. to study and compete at the college level. These athletes bring their own talent, style, and culture to American universities, enriching the track and field community with their perspectives and experiences.
            <br/>
            <br/>
            Foreign athletes also have a significant impact on the results and rankings of college level track and field. Many foreign athletes are among the top athletes in their event, often winning medals, titles, and records. Some foreign athletes are even so good that they surpass or challenge American athletes.
            <br/>
            <br/>
            Examples of successful foreign athletes in American college competitions include:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Andre De Grasse: a Canadian sprinter who excelled at Coffeyville Community College and the University of Southern California (USC), winning multiple NJCAA and NCAA titles in sprint events. His performances at USC established his reputation as a leading sprinter. Following his college career, De Grasse won multiple Olympic medals, including gold in the 200 meters at the 2020 Tokyo Olympics, showcasing himself as one of the world's top sprinters.
                </li>

                <li>
                    Kerron Clement: a Trinidadian-American athlete excelling in the 400 meters hurdles. He studied at the University of Florida, where he won NCAA titles and developed into a world-class hurdler. Clement captured gold at the 2008 and 2016 Olympics and has several world championships to his name, making him one of the most successful hurdlers of his generation.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Foreign athletes influence not only college level track and field but also other international competitions and events. Many foreign athletes who competed at the college level later participate in the Olympic Games, World Championships, or other regional or continental championships. They represent their own country but also have ties to their American university.
        </MKTypography>
    </MKBox>
    <MKBox id="4.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.2 American Athletes Abroad
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Another way in which college level track and field is international is through the opportunities and challenges it offers for American athletes to compete abroad. Many American athletes who have competed at the college level get the chance to broaden their horizons and test their skills against other athletes from different countries and cultures.
            <br/>
            <br/>
            One of the main opportunities for American athletes to compete abroad is, of course, the Olympic Games. The Olympics are the largest and most prestigious sporting event in the world, where thousands of athletes from over 200 countries come together to compete for gold, silver, and bronze. The Olympics are also a chance for American athletes to represent their country, show their patriotism, and share their national pride.
            <br/>
            <br/>
            Another important event for American athletes to compete abroad is the World Athletics Championships. The World Athletics Championships are the most significant track and field event after the Olympics, where the world's best athletes meet to compete for the world title. The World Athletics Championships are also a chance for American athletes to measure their level, fuel their rivalry, and establish their reputation.
            <br/>
            <br/>
            Besides these major events, there are also other opportunities for American athletes to compete abroad, such as the Diamond League, the Continental Tour, the World Athletics Relays, or other regional or continental championships. These events offer American athletes the opportunity to increase their experience, earnings, and fanbase.
            <br/>
            <br/>
            College level track and field plays an important role in the development and success of American athletes competing abroad. It prepares athletes for the international level by providing them with high-quality training, coaching, and competition. College level track and field also supports athletes competing abroad by offering them a network of contacts, sponsors, and fans.
        </MKTypography>
    </MKBox>
</MKBox>











                                              






                                  




<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 5: Sports Scholarships and the Future of Athletes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sports scholarships offer numerous opportunities for young athletes to showcase their talent and develop their skills. One of the most significant opportunities is obtaining a sports scholarship to study and compete at the college level. In this chapter, we will discuss the importance of sports scholarships and how Sport Scholarships America can help you make your dream come true.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 The Importance of Scholarships
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A sports scholarship is financial support provided by a university to a student-athlete to combine studies and sports. It can cover the costs of tuition, books, housing, meals, and other expenses. Additionally, it may offer benefits such as academic tutoring, medical care, training facilities, and equipment.
      <br />
      <br />
      Obtaining a sports scholarship is competitive, with many factors playing a role in the awarding process, including academic achievements, athletic performance, personality, motivation, leadership, and adaptability. Therefore, it's essential to prepare well and distinguish yourself from the competition.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 How Sport Scholarships America Can Help
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo of Sport Scholarships America, featuring sports equipment and a graduation cap, representing the focus on sports scholarships for student-athletes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo of Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America is an organization specialized in guiding young sports talents to sports scholarships. With passionate and experienced experts, Sport Scholarships America supports student-athletes at every step of the journey, from initial orientation to final placement. Their services include:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>A personal intake interview to discuss the student-athlete's wishes, possibilities, and expectations.</li>
        <li>A professional evaluation of the student-athlete's athletic and academic level.</li>
        <li>An extensive network of contacts with coaches, universities, and colleges.</li>
        <li>Effective promotion of the student-athlete's profile and videos to potential coaches.</li>
        <li>Expert advice on the best options based on the student-athlete's preferences, goals, and budget.</li>
        <li>Negotiation of the best scholarship offers on behalf of the student-athlete.</li>
        <li>Assistance in applying for necessary documents, such as visa, transcript, diploma, and admission tests.</li>
        <li>Preparation for student-athletes' life during their scholarship, including culture, language, rules, and tips.</li>
        <li>Aftercare and follow-up during the scholarship period.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Want to explore scholarship opportunities and achieve your athletic and academic goals? <Link to="/Services-Products">View the full range</Link> of services offered by Sport Scholarships America and schedule a consultation today.
    </MKTypography>
  </MKBox>
</MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;