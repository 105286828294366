/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Produktside: https://www.creative-tim.com/product/material-kit-pro-react
* Opphavsrett 2023 Creative Tim (https://www.creative-tim.com)

Kodet av www.creative-tim.com

 =========================================================

* Den ovennevnte opphavsrettsmeldingen og denne tillatelsesmeldingen skal inkluderes i alle kopier eller betydelige deler av programvaren.
*/

// Komponenter fra @mui material
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Komponenter fra Material Kit 2 PRO React
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Eksempler fra Material Kit 2 PRO React
import RaisedBlogCard from "examples/Cards/BlogCards/RaisedBlogCard";

// Bilder
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import NCAA from "assets/images/Logos/NCAA_Transparent.png"
import NJCAA from "assets/images/Logos/NJCAA_Transparent.png"
import NAIA from "assets/images/Logos/NAIA_Transparent.png"

function BlogPostThree() {
  const post1 = (NCAA)
  const post2 = (NJCAA)
  const post3 = (NAIA)

  return (
    <>
<MKBox position="relative" variant="gradient" bgColor="dark" mt={{ xs: 0, lg: 8 }} mx={-2}>
        <MKBox
          component="img"
          src={bgPattern}
          alt="bakgrunnsmønster"
          position="absolute"
          top={0}
          left={0}
          width={{ xs: "100%", lg: "100%" }}
          height={{ xs: "auto", lg: "auto" }}
          opacity={0.6}
        />
        <Container>
          <Grid container justifyContent="center" sx={{ pt: 6, pb: 18 }}>
            <Grid item xs={12} md={7} sx={{ textAlign: "center" }}>
            
              <MKTypography variant="h2" color="white" mb={1}>
              Muligheter på alle nivåer    </MKTypography>
              <MKTypography variant="body2" color="white">
              Hvert idrettsgren, hvert akademisk nivå: utforsk en verden av muligheter ved utallige amerikanske universiteter for hver student-idrettsutøver
                            </MKTypography>
            </Grid>
          </Grid>
        </Container>
      </MKBox>

    <MKBox component="section" mt={-10}>
     

      
      <Container>
        
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4} mb={{ xs: 3, lg: 0 }}>
            <RaisedBlogCard
              image={post1}
              category={{ color: "primary", label: "house" }}
              title="NCAA"
              subtitle="National Collegiate Athletic Association"
              description=
              "NCAA administrerer idrett på college i USA og har tilsyn med konkurranser for mer enn 1 100 tilknyttede universiteter. Som en ledende organisasjon spiller den en avgjørende rolle i det amerikanske universitetsidrettslandskapet."
            
              action={{ 
                type: "internal",
                route: "/no/NCAA",
                color: "info",
              label: "Mer informasjon", }}
              imageStyle={{ marginTop: "-44px" }}
              shadowTop="-30px"  // Flytt skyggen ned med 50px for å matche bildets bevegelse

            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} mb={{ xs: 3, lg: 0 }}>
            <RaisedBlogCard
              image={post2}
              category={{ color: "primary", label: "house" }}
              title="NJCAA"
              subtitle="National Junior College Association"
              description="NJCAA regulerer idrett ved community colleges i USA og administrerer konkurranser for hundrevis av tilknyttede institusjoner. Som en sentral organisasjon er den avgjørende for idrett på toårige høyskoler i Amerika, der talent utvikles for høyere nivåer."
              
              action={{ 
                type: "internal", 
                route: "/no/NJCAA",
                color: "info",
                label: "Mer informasjon", }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} mb={{ xs: 3, lg: 0 }}>
              <RaisedBlogCard
                image={post3}
                category={{ color: "primary", label: "house" }}
                title="NAIA"
                subtitle="National Association of Intercollegiate Athletics"
                description=
                "NAIA spiller en sentral rolle i administrasjonen av idrett ved små universiteter i USA, med hundrevis av tilknyttede institusjoner. Som en ledende organisasjon spiller den en vital rolle i det amerikanske intercollegiate idrettslandskapet, og fremmer talent og integritet."
                action={{ 
                  type: "internal", 
                  route: "/no/NAIA", 
                  color: "info",
                  label: "Mer informasjon"}}
                imageStyle={{ marginTop: "50px" }}
                shadowTop="none"
                paddingBelowImage="120px"
              />
            </Grid>
          </Grid>
        </Container>
    </MKBox>
    </>
  );
}

export default BlogPostThree;
