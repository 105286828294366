// Componentes de Material do @mui
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Componentes Material Kit 2 PRO React
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";
import "../../../../H1asH3.css"

// Imagens
import profilePicture from "assets/images/Profielfoto_Kay_Duit.jpeg";

function Perfil() {
  return (
    <MKBox component="section" pt={{ xs: 20, sm: 20,  md: 6}} pb={{ xs: 6, sm: 0}}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
            <MKAvatar src={profilePicture} alt="Foto de perfil de Kay Duit, fundador e proprietário" size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox 
                display="flex" 
                flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} 
                justifyContent="space-between" 
                alignItems="center" 
                mb={1}
              >
                <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Kay Duit</MKTypography>
                <MKBox display="flex" flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} gap={1}>
                <a href="https://www.linkedin.com/in/kayduit/" target="_blank" rel="noopener noreferrer">
                <MKSocialButton color="linkedin" style={{ width: '140px' }}>
                    <MKBox component="i" color="inherit" mr={0.5} className="fab fa-linkedin" />
                    LinkedIn
                  </MKSocialButton>
                  </a>
                  <a href="https://www.instagram.com/kayduit/" target="_blank" rel="noopener noreferrer">
                  <MKSocialButton color="instagram" style={{ width: '140px' }}>
                    <MKBox component="i" color="inherit" mr={0.5} className="fab fa-instagram" />
                    Instagram
                  </MKSocialButton>
                  </a>
                  <a href="/Podcast">
                  <MKSocialButton color="pinterest" style={{ width: '140px' }}>
                    <MKBox component="i" color="inherit" mr={0.5} className="fas fa-podcast" />
                    Podcast
                  </MKSocialButton>
                  </a>
                </MKBox>
              </MKBox>
              <br />
              <MKBox>
  <MKTypography variant="body1" fontWeight="light" color="text">
    Em uma noite quente de verão na América, eu estava à beira do campo de futebol, esperando o apito para entrar em campo. Meu coração estava acelerado, não por causa do jogo iminente, mas pela realização de quão longe eu havia chegado. Três anos antes, eu havia pegado um avião do meu país natal para os Estados Unidos, um salto para o desconhecido em busca de aventura e da oportunidade de realizar tanto os meus sonhos esportivos quanto acadêmicos.
    <br /><br />
    Durante esses quatro anos, eu vivenciei muito mais do que apenas partidas de futebol e estudos. Havia jantares tardios depois de grandes vitórias, voos para jogos fora do estado, as amizades que construí e as inúmeras vezes em que percebi o quão maior o mundo era do que o pequeno lugar de onde eu vinha.
    <br /><br />
    Naquela noite, enquanto o hino nacional tocava e as arquibancadas estavam cheias de milhares de fãs aplaudindo, eu pensava em todos os jovens estudantes ao redor do mundo que, assim como eu, sonhavam com essas oportunidades. E eu fiz um compromisso comigo mesmo de que, uma vez concluída a minha jornada na América, estenderia a mão para esses estudantes aspirantes, independentemente de onde eles viessem, ajudando-os a realizar sonhos semelhantes aos meus.
  </MKTypography>
</MKBox>

              <MKTypography
                component="a"
                href="#"
                variant="body1"
                fontWeight="light"
                color="info"
                mt={3}
                sx={{
                  width: "max-content",
                  display: "flex",
                  alignItems: "center",
                  "& .material-icons-round": {
                    transform: `translateX(3px)`,
                    transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                  },
                  "&:hover .material-icons-round, &:focus .material-icons-round": {
                    transform: `translateX(6px)`,
                  },
                }}
              >
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Perfil;
