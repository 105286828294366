/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Baseball1.jpg";
import Image2 from "assets/images/Sports_Images/Baseball2.jpeg";
import Image3 from "assets/images/Sports_Images/Baseball3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';

import "../../../../H1asH3.css"

const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Baseball and Studying in America: A Dream That Can Come True', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Chapter 1: The History and Development of College Baseball', isSubheader: false },
    { id: '1.1', title: '1.1 Early Years and the Role of Colleges', isSubheader: true },
    { id: '1.2', title: '1.2 Growth and Professionalization', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Chapter 2: College Baseball Today', isSubheader: false },
    { id: '2.1', title: '2.1 Major Leagues and Conferences', isSubheader: true },
    { id: '2.2', title: '2.2 Top Programs and Prestigious Teams', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Chapter 3: From College to the Pros', isSubheader: false },
    { id: '3.1', title: '3.1 The Path to Professionalism', isSubheader: true },
    { id: '3.2', title: '3.2 The MLB Draft and Other Professional Opportunities', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Chapter 4: International Influences', isSubheader: false },
    { id: '4.1', title: '4.1 Foreign Players in College Baseball', isSubheader: true },
    { id: '4.2', title: '4.2 Americans Abroad', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Chapter 5: Sports Scholarships and the Future', isSubheader: false },
    { id: '5.1', title: '5.1 The Importance of Scholarships', isSubheader: true },
    { id: '5.2', title: '5.2 How Sport Scholarships America Can Help', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Content
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Hide</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Show</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>

























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Baseball and Studying in America: A Dream That Can Come True
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Aerial view of the stadium during the College World Series."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        The College World Series
      </figcaption>
    </figure>
    Baseball is one of the most popular and significant sports in the United States. Millions of people follow Major League Baseball (MLB), the highest professional baseball league in the world. But before you can make it to the MLB, you must first develop and prove your skills at another level: college baseball.
    <br />
    <br />
    College baseball is the baseball played by students at American universities and colleges. It is a highly competitive and prestigious sport that attracts a lot of attention and talent. College baseball serves as a crucial step for players looking to play professionally and also as an opportunity to receive a good education.
    <br />
    <br />
    But how do you get into college baseball? And how can you get a scholarship to study and play in the US? This is where Sportbeursamerika.nl can help you. Sportbeursamerika.nl is an organization specialized in guiding and supporting student-athletes who want to make their dreams come true. I can assist you with everything you need to find a suitable university, obtain a scholarship, and prepare for life in the US.
    <br />
    <br />
    In this article, we will tell you everything you need to know about college baseball in the US. We will delve into the history and development of this sport, the key leagues and conferences, top programs and prestigious teams, the path to the pros, international influences, and, of course, the importance of scholarships. After reading this article, you will have a better understanding of what college baseball entails and how you can increase your chances of being part of it.
    <br />
    <br />
    Are you ready to make your dream come true? Let's get started!
  </MKTypography>
</MKBox>

<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 1: The History and Development of College Baseball
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Baseball is a sport that has existed for over a century. But how did it originate and grow to become one of the most popular and significant sports in the US? In this chapter, we will provide you with a brief overview of the history and development of college baseball.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Early Years and the Role of Colleges
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      The origin of baseball is not entirely clear. There are various theories about how and where the game began. Some say it descended from ancient European games like cricket, rounders, or stoolball. Others claim it originated from a Native American game called lacrosse. Still, others suggest it was invented by an American soldier named Abner Doubleday in 1839.
      <br />
      <br />
      What is certain is that baseball quickly became popular in the US, especially in East Coast cities. It was played by amateurs, clubs, factory workers, and soldiers. It was also played by students at universities and colleges, who had their own teams and rules.
      <br />
      <br />
      The first known intercollegiate baseball game in the United States took place in 1859 between Amherst College and Williams College, two respected academic institutions in Massachusetts. This game is historically significant as one of the earliest examples of college-level baseball games, a precursor to the later development of college baseball. Although the exact outcome and course of the game may vary in historical sources, this event marks an important starting point in the history of intercollegiate baseball. It illustrates how baseball became popular among students in the early days and how universities became important venues for the growth and development of the sport in the United States.
      <br />
      <br />
      In the 1860s and 1870s, the number of college teams steadily grew. Some of the earliest teams included Harvard, Yale, Princeton, Dartmouth, Brown, and Columbia. These teams mainly played against each other or local clubs.
      <br />
      <br />
      In 1879, the first intercollegiate baseball conference was held in Springfield, Massachusetts. The goal was to standardize and improve the rules and standards of the game. The conference led to the formation of the American College Baseball Association, with six prestigious educational institutions - Harvard, Yale, Princeton, Amherst, Dartmouth, and Brown - being part of it. This marked the beginning of a long tradition of national championships in college baseball.
    </MKTypography>
  </MKBox>

  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Growth and Professionalization
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      In the late 19th and early 20th centuries, baseball became increasingly popular and professional in the US. The National League (NL) and the American League (AL) were established as the major professional baseball leagues, attracting many players, fans, and money.
      <br />
      <br />
      This also had an impact on college baseball. Many college players were recruited or signed by professional teams. Some left their universities to play professionally, while others continued their studies but also played part-time for professional teams.
      <br />
      <br />
      This led to various issues and conflicts in college baseball. Some universities opposed the idea of their players being paid to play, considering it contrary to the amateur spirit and academic integrity of college sports. They also wanted to retain their best players for their own teams.
      <br />
      <br />
      Other universities were more tolerant or pragmatic about the phenomenon of professional players. They recognized that baseball was a lucrative and prestigious sport that could provide many benefits to their institutions. They also wanted to compete with other universities at the highest level.
      <br />
      <br />
      To address these issues, various measures were taken by different organizations. The IBA was replaced by the National Collegiate Athletic Association (NCAA) in 1906. The NCAA took on the responsibility of regulating and organizing college baseball and other sports. The NCAA also established rules and guidelines for the eligibility and behavior of college players.
      <br />
      <br />
      The NCAA also organized the first College World Series (CWS) in 1947. This was the new national championship for college baseball, held annually in Omaha, Nebraska. The CWS quickly became one of the most prestigious and popular events in college sports.
      <br />
      <br />
      In addition to the NCAA, there were other organizations involved in college baseball. One of them was the National Association of Intercollegiate Athletics (NAIA), established in 1937. The NAIA was an alternative to the NCAA, focusing on smaller universities and colleges. The NAIA also organized its own national championship for college baseball.
      <br />
      <br />
      Another organization was the National Junior College Athletic Association (NJCAA), founded in 1938. The NJCAA was an organization for community colleges offering two-year programs. The NJCAA also organized its own national championship for college baseball.
      <br />
      <br />
      These organizations all contributed to the growth and professionalization of college baseball. They created more opportunities and diversity for players, coaches, schools, and fans. They also made college baseball an integral part of the American baseball system.
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  







<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 2: College Baseball Today
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College baseball is one of the most popular and significant sports in the US today. It attracts millions of fans, media attention, and sponsors. It produces thousands of players, coaches, and officials. It offers hundreds of scholarships, awards, and honors.
    <br />
    <br />
    But what does college baseball look like today? How is the system organized? What are the major leagues and conferences? Which are the top programs and prestigious teams? In this chapter, we will provide you with an overview of college baseball today.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Major Leagues and Conferences
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Barry Bonds in action for Arizona State in college baseball."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Barry Bonds as a player for Arizona State
        </figcaption>
      </figure>
      As we saw in the previous chapter, several organizations are involved in college baseball. The most important of these is the NCAA, representing more than 1,200 universities and colleges. The NCAA is divided into three divisions: Division I, Division II, and Division III.
      <br />
      <br />
      Division I is the highest level of college baseball, consisting of approximately 300 schools, divided into 31 conferences. Each conference has its own rules, schedules, and championships. The most well-known conferences include the Atlantic Coast Conference (ACC), the Big Ten Conference (B1G), the Big 12 Conference (B12), the Pac-12 Conference (P12), and the Southeastern Conference (SEC).
      <br />
      <br />
      Division II is the middle level of college baseball, consisting of approximately 270 schools, divided into 23 conferences. Conferences are typically regionally oriented, such as the California Collegiate Athletic Association (CCAA), the Gulf South Conference (GSC), and the Northeast-10 Conference (NE10).
      <br />
      <br />
      Division III is the lowest level of college baseball, consisting of approximately 380 schools, divided into 43 conferences. Conferences are also typically regionally oriented, such as the New England Small College Athletic Conference (NESCAC), the Southern California Intercollegiate Athletic Conference (SCIAC), and the Wisconsin Intercollegiate Athletic Conference (WIAC).
      <br />
      <br />
      In addition to the NCAA, there are other organizations involved in college baseball. One of them is the NAIA, representing approximately 190 schools. The NAIA is divided into 21 conferences, such as the American Midwest Conference (AMC), the Golden State Athletic Conference (GSAC), and the Heart of America Athletic Conference (HAAC).
      <br />
      <br />
      Another organization is the NJCAA, representing approximately 500 community colleges. The NJCAA is divided into three divisions: Division I, Division II, and Division III. Each division is further subdivided into districts and regions.
      <br />
      <br />
      These organizations hold their own national championships for college baseball each year. The NCAA Division I championship is the most well-known and prestigious event, often referred to as the College World Series (CWS).
      <br />
      <br />
      The CWS consists of a double-elimination tournament of 64 teams selected based on their performance in the regular season and conference championships. The teams are divided into 16 regional groups of four teams each. The winners of each regional group advance to the super regional round, where they compete against another team in a best-of-three series. The winners of each super regional round move on to the CWS final tournament, consisting of two groups of four teams each. The winners of each group face off in a best-of-three series to determine the national champion.
      <br />
      <br />
      The CWS final tournament is held annually in June in Omaha, Nebraska. It is considered one of the biggest and most exciting events in college sports, attracting thousands of fans, media, and scouts.
      <br />
      <br />
      The NCAA Division II and Division III championships follow a similar format but with fewer teams. The NAIA and NJCAA championships also have their own formats, which vary depending on the number of participating teams.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Top Programs and Prestigious Teams
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      College baseball is a highly competitive and prestigious sport with a rich tradition and talent pool. There are many programs and teams that distinguish themselves through their success and reputation. Some of these programs and teams include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Texas Longhorns: The baseball team of the University of Texas in Austin, competing in the Big 12 Conference. This program has an impressive track record with numerous appearances in the College World Series and multiple championships. Some notable alumni include Roger Clemens, Burt Hooton, and Huston Street.
        </li>
        <li>
          LSU Tigers: The baseball team of Louisiana State University in Baton Rouge, active in the SEC. LSU is known for its dominance in college baseball and has won multiple championships. Some notable alumni include Albert Belle, Aaron Hill, and DJ LeMahieu.
        </li>
        <li>
          USC Trojans: The baseball team of the University of Southern California in Los Angeles, a member of the Pac-12 Conference. USC has a rich tradition with numerous championships and has produced many talented players. Some famous alumni include Tom Seaver, Randy Johnson, and Mark McGwire.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      These are just a few examples of top programs and prestigious teams in college baseball. There are many more programs and teams, each with its own history, culture, and identity.
      <br />
      <br />
      These programs and teams contribute to the development of players in various ways. They provide them with a high level of coaching, training, facilities, and competition. They also help them achieve their academic, athletic, and personal goals.
    </MKTypography>
  </MKBox>
</MKBox>
                     






                                  









<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 3: From College to the Pros
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College baseball is not just a sport; it's also a pathway to professionalism. Many college baseball players have the ambition to play professionally, either in MLB or other professional leagues. But what does that pathway look like? How can you go from college to the pros? In this chapter, we will provide you with an overview of the pathway to professionalism in baseball.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 The Path to Professionalism
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      The path to professionalism in baseball usually begins with the MLB Draft. The MLB Draft is an annual event where the 30 MLB teams take turns selecting players from various sources, including college baseball, high school baseball, international baseball, and independent baseball.
      <br />
      <br />
      The MLB Draft consists of 40 rounds, with each team allowed to choose one player per round. The order of selections is determined by the reverse standings of the previous MLB season, with some exceptions for compensation picks and lottery picks.
      <br />
      <br />
      The MLB Draft is a significant opportunity for college baseball players to start their professional careers. Approximately 1,200 players are drafted in the MLB Draft each year, with about 60% coming from college baseball.
      <br />
      <br />
      If you are chosen in the MLB Draft, you have two options: you can sign a contract with the team that selected you, or you can choose not to sign and return to your university or another source.
      <br />
      <br />
      If you choose to sign a contract, you will receive a signing bonus based on the round in which you were selected. Bonuses vary from several million dollars for the first round to several thousand dollars for later rounds.
      <br />
      <br />
      When you sign a contract, you will be assigned to one of the minor league teams of the team that selected you. The minor leagues are the lower levels of professional baseball, serving as a development system for the MLB. There are six levels in the minor leagues: Rookie, Class A Short Season, Class A, Class A Advanced, Double-A, and Triple-A.
      <br />
      <br />
      While playing in the minor leagues, you must improve your skills and demonstrate your performance to advance to higher levels. You also have to compete with other players who share the same dream: making it to the major leagues.
      <br />
      <br />
      Reaching the major leagues is the ultimate goal for every professional baseball player. It is the highest level of professional baseball, where you can compete against the best players in the world, in front of the largest fans, and for the highest salaries.
      <br />
      <br />
      However, making it to the major leagues is not easy. Only about 10% of players drafted in the MLB Draft eventually reach the major leagues, and only about 1% of those drafted have long and successful careers in the major leagues.
      <br />
      <br />
      So, the pathway to professionalism in baseball is a long and challenging journey that requires dedication, hard work, and some luck. But it is also a pathway that can offer many rewards, opportunities, and dreams.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 The MLB Draft and Other Professional Opportunities
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      The MLB Draft is not the only way to play professionally in baseball. There are also other professional opportunities outside of MLB that can be attractive to college baseball players.
      <br />
      <br />
      One of them is the independent league. The independent league is a professional baseball league that is not affiliated with MLB or the minor leagues. The independent league consists of various leagues, such as the Atlantic League, the American Association, and the Frontier League.
      <br />
      <br />
      The independent league provides an alternative for players who are not drafted in the MLB Draft or do not receive contract offers from an MLB team. The independent league also offers a chance for players who are released from the minor leagues or want to extend their careers.
      <br />
      <br />
      The independent league has a lower level of play and pay compared to MLB or the minor leagues, but it also offers a higher level of freedom and enjoyment. The independent league is known for its creativity, diversity, and experiments. It has also produced some players who eventually reached the major leagues, such as Jose Bautista, Rich Hill, and Max Muncy.
      <br />
      <br />
      Another professional opportunity is the international league. The international league is a collective term for professional baseball leagues played outside of the United States. The international league consists of various leagues, such as Nippon Professional Baseball (NPB) in Japan, the Korean Baseball Organization (KBO) in South Korea, the Chinese Professional Baseball League (CPBL) in Taiwan, and the Mexican Baseball League (LMB) in Mexico.
      <br />
      <br />
      The international league offers an opportunity for players who want to broaden their horizons and experience a different culture. The international league also provides an opportunity for players who seek a higher level of play and pay than the independent league but may not be able to make it to MLB or the minor leagues.
      <br />
      <br />
      These are just a few examples of professional opportunities outside of MLB. There are many more opportunities, such as winter leagues, college summer leagues, and amateur leagues.
      <br />
      <br />
      These opportunities all offer different advantages and disadvantages for college baseball players. They provide different levels of competition, reward, challenge, and enjoyment. They offer various pathways to professionalism in baseball.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  








<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 4: International Influences
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College baseball is not just an American sport; it's also an international sport influenced by players, coaches, and fans from various countries and cultures. In this chapter, we will provide you with an overview of the international influences in college baseball.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Foreign Players in College Baseball
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="USC baseball team celebrating a victory."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          USC players celebrating a victory
        </figcaption>
      </figure>
      One of the most significant international influences in college baseball is the presence of foreign players. Foreign players are players who were not born in the USA or do not have American citizenship. They come from different countries, such as Canada, Mexico, Japan, South Korea, Taiwan, Cuba, Venezuela, the Dominican Republic, and the Netherlands.
      <br />
      <br />
      Foreign players play a vital role in college baseball. They bring their own skills, styles, and perspectives to the game. They enrich the diversity and quality of college baseball. They also increase the international recognition and appeal of college baseball.
      <br />
      <br />
      There are many success stories of foreign players who have made it in the American college baseball world. A prominent example is Nomar Garciaparra. Nomar Garciaparra was a Mexican-American shortstop who played for the Georgia Institute of Technology. He was selected in the first round of the MLB Draft in 1994 by the Boston Red Sox. He played 14 seasons in the MLB, where he became an All-Star six times and won the AL batting title twice. There are many more examples of foreign players who have made their mark in college baseball.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Americans Abroad
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Another international influence in college baseball is the presence of Americans abroad. Americans abroad are players who were born in the USA or have American citizenship but play or have played in professional leagues outside the USA.
      <br />
      <br />
      Americans abroad also play a significant role in college baseball. They bring their own experiences, knowledge, and networks to the game. They also learn from other cultures and styles of baseball. They enhance international exchange and collaboration in college baseball.
      <br />
      <br />
      These opportunities offer many benefits to American college baseball players. They provide a higher level of competition, exposure, and reward. They also help broaden their horizons, increase adaptability, and expand their networks.
      <br />
      <br />
      International experience can be beneficial for a player's development. It can help them learn new skills, techniques, and strategies. It can also help them improve their weaknesses, leverage their strengths, and maximize their potential.
    </MKTypography>
  </MKBox>
</MKBox>
      






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 5: Sports Scholarships and the Future of Athletes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sports scholarships offer numerous opportunities for young athletes to showcase their talent and develop their skills. One of the most significant opportunities is obtaining a sports scholarship to study and compete at the college level. In this chapter, we will discuss the importance of sports scholarships and how Sport Scholarships America can help you make your dream come true.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 The Importance of Scholarships
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A sports scholarship is financial support provided by a university to a student-athlete to combine studies and sports. It can cover the costs of tuition, books, housing, meals, and other expenses. Additionally, it may offer benefits such as academic tutoring, medical care, training facilities, and equipment.
      <br />
      <br />
      Obtaining a sports scholarship is competitive, with many factors playing a role in the awarding process, including academic achievements, athletic performance, personality, motivation, leadership, and adaptability. Therefore, it's essential to prepare well and distinguish yourself from the competition.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 How Sport Scholarships America Can Help
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo of Sport Scholarships America, featuring sports equipment and a graduation cap, representing the focus on sports scholarships for student-athletes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo of Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America is an organization specialized in guiding young sports talents to sports scholarships. With passionate and experienced experts, Sport Scholarships America supports student-athletes at every step of the journey, from initial orientation to final placement. Their services include:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>A personal intake interview to discuss the student-athlete's wishes, possibilities, and expectations.</li>
        <li>A professional evaluation of the student-athlete's athletic and academic level.</li>
        <li>An extensive network of contacts with coaches, universities, and colleges.</li>
        <li>Effective promotion of the student-athlete's profile and videos to potential coaches.</li>
        <li>Expert advice on the best options based on the student-athlete's preferences, goals, and budget.</li>
        <li>Negotiation of the best scholarship offers on behalf of the student-athlete.</li>
        <li>Assistance in applying for necessary documents, such as visa, transcript, diploma, and admission tests.</li>
        <li>Preparation for student-athletes' life during their scholarship, including culture, language, rules, and tips.</li>
        <li>Aftercare and follow-up during the scholarship period.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Want to explore scholarship opportunities and achieve your athletic and academic goals? <Link to="/Services-Products">View the full range</Link> of services offered by Sport Scholarships America and schedule a consultation today.
    </MKTypography>
  </MKBox>
</MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;