/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Golf1.jpeg";
import Image2 from "assets/images/Sports_Images/Golf2.webp";
import Image3 from "assets/images/Sports_Images/Golf3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"


// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Golfskolearstipend: Hvordan studere og spille golf i Amerika', isSubheader: false },
  
    { id: 'Kapittel 1', title: 'Kapittel 1: Historien og Utviklingen av Collegiat Golf', isSubheader: false },
    { id: '1.1', title: '1.1 De tidlige årene', isSubheader: true },
    { id: '1.2', title: '1.2 Vekst og Profesjonalisering', isSubheader: true },
  
    { id: 'Kapittel 2', title: 'Kapittel 2: Dagens landskap av Collegiat Golf', isSubheader: false },
    { id: '2.1', title: '2.1 Store konkurranser og konferanser', isSubheader: true },
    { id: '2.2', title: '2.2 Topp-programmer og prestisjefylte lag', isSubheader: true },
  
    { id: 'Kapittel 3', title: 'Kapittel 3: Fra College til Proffene', isSubheader: false },
    { id: '3.1', title: '3.1 Veien til den profesjonelle kretsen', isSubheader: true },
    { id: '3.2', title: '3.2 Alternative ruter til profesjonalitet', isSubheader: true },
  
    { id: 'Kapittel 4', title: 'Kapittel 4: Internasjonale Påvirkninger og Muligheter', isSubheader: false },
    { id: '4.1', title: '4.1 Utenlandske Spillere i Collegiat Golf', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanske Spillere i Utlandet', isSubheader: true },
  
    { id: 'Kapittel 5', title: 'Kapittel 5: Idrettsstipender og Fremtiden til Utøvere', isSubheader: false },
    { id: '5.1', title: '5.1 Viktigheten av Stipender', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Scholarships America Kan Hjelpe', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };





  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>






















          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Golfskolearstipend: Hvordan studere og spille golf i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Golfbane under NCAA-mesterskapet, med spillere og tilskuere rundt banen"
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        NCAA Golfmesterskapet
      </figcaption>
    </figure>
    Golf er en av de mest populære idrettene på college-nivå i USA. Hvert år konkurrerer tusenvis av studentutøvere om nasjonale titler, prestisjetunge priser og en plass i profesjonell golf. Collegiate golf tilbyr ikke bare en sjanse til å forbedre dine ferdigheter som idrettsutøver, men også til å tjene en akademisk grad, bygge et nettverk og ha en uforglemmelig opplevelse.
    <br/>
    <br/>
    Imidlertid er collegial golf ikke for alle. Det krever et høyt nivå av engasjement, disiplin og talent. Dessuten er det ikke lett å bli tatt opp til en universitet med et godt golprogram. Konkurransen er tøff, og kostnadene er høye.
    <br/>
    <br/>
    Heldigvis finnes det muligheter til å realisere drømmen din med hjelp av et golfskolearstipend. Et golfskolearstipend er økonomisk støtte du mottar fra et universitet for å studere og spille golf der. Med et golfskolearstipend kan du redusere eller til og med fullt ut dekke studiekostnadene mens du nyter fordelene med collegial golf.
    <br/>
    <br/>
    Men hvordan får du et golfskolearstipend? Og hva bør du vurdere når du velger et universitet? Det er her Sportbeursamerika.nl kan hjelpe deg. Sportbeursamerika.nl er spesialisten på å veilede studenter til idrettsstipend i Amerika. Jeg har erfaring og kunnskap om det amerikanske utdannings- og idrettssystemet, og jeg har hjulpet mange studentutøvere med å oppnå drømmen sin.
    <br/>
    <br/>
    I denne artikkelen gir vi mer informasjon om collegial golf og viser hvordan Sportbeursamerika.nl kan støtte deg i å finne det perfekte golfskolearstipendet for deg.
    <br/>
    <br/>
    Vil du vite mer? Fortsett å lese!
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
<MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Golfskolearstipend: Hvordan studere og spille golf i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Golfbane under NCAA-mesterskapet, med spillere og tilskuere rundt banen"
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        NCAA Golfmesterskapet
      </figcaption>
    </figure>
    Golf er en av de mest populære idrettene på college-nivå i USA. Hvert år konkurrerer tusenvis av studentutøvere om nasjonale titler, prestisjetunge priser og en plass i profesjonell golf. Collegiate golf tilbyr ikke bare en sjanse til å forbedre dine ferdigheter som idrettsutøver, men også til å tjene en akademisk grad, bygge et nettverk og ha en uforglemmelig opplevelse.
    <br/>
    <br/>
    Imidlertid er collegial golf ikke for alle. Det krever et høyt nivå av engasjement, disiplin og talent. Dessuten er det ikke lett å bli tatt opp til en universitet med et godt golprogram. Konkurransen er tøff, og kostnadene er høye.
    <br/>
    <br/>
    Heldigvis finnes det muligheter til å realisere drømmen din med hjelp av et golfskolearstipend. Et golfskolearstipend er økonomisk støtte du mottar fra et universitet for å studere og spille golf der. Med et golfskolearstipend kan du redusere eller til og med fullt ut dekke studiekostnadene mens du nyter fordelene med collegial golf.
    <br/>
    <br/>
    Men hvordan får du et golfskolearstipend? Og hva bør du vurdere når du velger et universitet? Det er her Sportbeursamerika.nl kan hjelpe deg. Sportbeursamerika.nl er spesialisten på å veilede studenter til idrettsstipend i Amerika. Jeg har erfaring og kunnskap om det amerikanske utdannings- og idrettssystemet, og jeg har hjulpet mange studentutøvere med å oppnå drømmen sin.
    <br/>
    <br/>
    I denne artikkelen gir vi mer informasjon om collegial golf og viser hvordan Sportbeursamerika.nl kan støtte deg i å finne det perfekte golfskolearstipendet for deg.
    <br/>
    <br/>
    Vil du vite mer? Fortsett å lese!
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>












                                              





                                  








<MKBox id="Kapittel 2" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Kapittel 2: Dagens landskap av collegiat golf
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        I dag er collegiat golf en av de mest konkurransedyktige og prestisjefylte idrettene på universitetsnivå i USA. I dette kapittelet vil vi se på de store konkurransene og konferansene, samt de beste programmene og prestisjetunge lagene innen collegiat golf.
    </MKTypography>

    <MKBox id="2.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.1 Store konkurranser og konferanser
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image2}
                    alt="Tiger Woods representerer Stanford i collegiat golf, vist i konsentrasjon under kamp."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal2(Image2)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Tiger Woods i aksjon for Stanford.
                </figcaption>
            </figure>
            NCAA er den største og viktigste organisasjonen for collegiat golf i USA. NCAA deler universitetene inn i tre divisjoner: Divisjon I, Divisjon II og Divisjon III. Hver divisjon har sitt eget mesterskap, som arrangeres årlig i mai eller juni. Mesterskapene består av et lag- og et individuelt mesterskap, der de beste spillerne og lagene fra hver divisjon konkurrerer om den nasjonale tittelen.
            <br/>
            <br/>
            NCAA har også flere regionale konferanser, som består av universiteter som er geografisk eller historisk forbundet. Hver konferanse har sitt eget mesterskap, som fungerer som et kvalifiseringsmesterskap for det nasjonale mesterskapet. Noen konferanser er sterkere enn andre og har større innflytelse på collegiat golf-landskapet.
            <br/>
            <br/>
            Noen av de mest kjente konferansene inkluderer:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Atlantic Coast Conference (ACC), som består av 15 universiteter på østkysten, som Duke, North Carolina og Clemson.
                </li>
                <li>
                    Big Ten Conference, som består av 14 universiteter i Midtvesten, som Michigan, Ohio State og Illinois.
                </li>
                <li>
                    Pac-12 Conference, som består av 12 universiteter på vestkysten, som Stanford, California og Arizona State.
                </li>
                <li>
                    Southeastern Conference (SEC), som består av 14 universiteter i sørøst, som Alabama, Georgia og Florida.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            I tillegg til NCAA, er det også andre organisasjoner som organiserer collegiat golfkonkurranser, som National Association of Intercollegiate Athletics (NAIA), National Junior College Athletic Association (NJCAA) og National Christian College Athletic Association (NCCAA). Disse organisasjonene har sine egne divisjoner, konferanser og mesterskap, som generelt er mindre og mindre prestisjefylte enn de til NCAA.
        </MKTypography>
    </MKBox>
    <MKBox id="2.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.2 Topp-programmer og prestisjefylte lag
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Collegiat golf er ikke bare en idrett, men også en utdanning. Universitetene som tilbyr collegiat golf har ulike programmer, som varierer i kvalitet, omdømme og fasiliteter. Noen programmer er ledende og prestisjefylte, og tiltrekker seg de beste spillerne og trenerne. Andre programmer er mindre kjente eller vellykkede, men tilbyr likevel gode muligheter for student-idrettsutøvere.
            <br/>
            <br/>
            Noen av faktorene som avgjør et program inkluderer:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Programmets historie og tradisjon.
                </li>
                <li>
                    Nivået og resultatene til laget.
                </li>
                <li>
                    Kvaliteten og erfaringen til treneren.
                </li>
                <li>
                    Fasilitetene og utstyret til programmet.
                </li>
                <li>
                    De akademiske standardene og kravene til programmet.
                </li>
                <li>
                    Atmosfæren og kulturen til programmet.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Noen av de mest fremtredende collegiat golf-programmene inkluderer:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Stanford University: Stanford har et av de eldste og mest vellykkede golfprogrammene i USA, med flere nasjonale titler innen herregolf. Programmet har produsert kjente alumner som Tiger Woods og Tom Watson. Stanfords kvinnelige golflag har også fått nasjonal anerkjennelse med flere NCAA-titler og individuelle mestere.
                </li>
                <li>
                    Oklahoma State University: Oklahoma State har et dominerende golfprogram, spesielt kjent for sitt herrelag, med flere nasjonale titler. Programmet har produsert topp golfspillere som Rickie Fowler og Viktor Hovland.
                </li>
                <li>
                    Duke University: Duke er kjent for sitt sterke kvinnelige golfprogram, med flere NCAA-titler. Dukes herregolfslag har prestert sterkt i konferansemesterskap og har en respektabel historie i NCAA-konkurranser. Duke har produsert talentfulle golfspillere, inkludert Amanda Blumenherst og Brittany Lang.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            I dette kapittelet har vi sett hvordan dagens landskap av collegiat golf ser ut, og hvilke konkurranser og programmer som er tilgjengelige. I neste kapittel vil vi se på hvordan man går fra collegiat til profesjonell nivå, og hvilke alternative ruter som er tilgjengelige.
        </MKTypography>
    </MKBox>
</MKBox>













                                              






                                  








<MKBox id="Kapittel 3" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Kapittel 3: Fra College til Proffene
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Collegiat golf er ikke bare en idrett, men også en karrierevei. Mange collegiat golfspillere ønsker å gå over til den profesjonelle kretsen og spille på det høyeste nivået innen golf. I dette kapittelet vil vi utforske hvordan collegiat golf kan være et springbrett til profesjonell golf og de alternative rutene som er tilgjengelige.
    </MKTypography>

    <MKBox id="3.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.1 Veien til den profesjonelle kretsen
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Collegiat golf er en utmerket forberedelse til en profesjonell karriere innen golf. Collegiat golfspillere har muligheten til å utvikle seg både idrettslig, akademisk og personlig. De lærer å håndtere press, konkurranse og forventninger. De spiller på utfordrende baner mot sterke motstandere og under ulike forhold. De har også tilgang til førsteklasses fasiliteter, trenere og veiledning.
            <br/>
            <br/>
            Collegiat golfspillere har også en fordel over andre spillere som ikke går på universitet. De har en grad som kan hjelpe dem med å finne jobb eller en sponsor hvis de ikke lykkes som profesjonelle. De har også et nettverk av kontakter og venner som kan støtte eller gi råd til dem i karrieren.
            <br/>
            <br/>
            Mange collegiat golfspillere går over til den profesjonelle kretsen etter å ha fullført studiene. Noen gjør det tidligere hvis de tror de er klare. For å bli profesjonelle, må de kvalifisere seg for en tour, som PGA-touren, LPGA-touren eller Europatouren, gjennom kvalifiseringsturneringer som Q-School eller Korn Ferry Tour.
            <br/>
            <br/>
            Mange tidligere collegiat golfspillere har hatt suksess på den profesjonelle kretsen. Eksempler inkluderer:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Phil Mickelson: Mickelson spilte collegiat golf for Arizona State University, der han vant NCAA-mesterskapet tre ganger. Han ble profesjonell i 1992 og har siden oppnådd utallige seire på PGA-touren, inkludert flere majortitler.
                </li>
                <li>
                    Annika Sörenstam: Sörenstam spilte collegiat golf for University of Arizona, der hun vant NCAA-mesterskapet i 1991. Hun ble profesjonell i 1992 og har siden oppnådd utallige profesjonelle seire, inkludert flere majortitler.
                </li>
                <li>
                    Jon Rahm: Rahm spilte collegiat golf for Arizona State University, der han vant Ben Hogan Award to ganger som den beste collegiat golfspilleren. Han ble profesjonell i 2016 og har siden oppnådd flere profesjonelle seire, inkludert majortitler.
                </li>
            </ul>
        </MKTypography>
    </MKBox>
    <MKBox id="3.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.2 Alternative ruter til profesjonalitet
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Mens collegiat golf er en vanlig vei til den profesjonelle kretsen, er det ikke den eneste. Det finnes andre veier spillere kan følge for å oppnå drømmen sin. Noen spillere velger å ikke gå på universitet, men å bli profesjonelle direkte etter videregående skole. Andre går på universitet, men slutter tidlig for å fokusere fullstendig på idretten sin.
            <br/>
            <br/>
            Eksempler på spillere som tok en alternativ rute inkluderer:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Tiger Woods: Woods spilte collegiat golf for Stanford University, der han vant NCAA-mesterskapet to ganger. Imidlertid ble han profesjonell i 1996 etter to års studier. Han har siden oppnådd utallige seire på PGA-touren, inkludert flere majortitler.
                </li>
                <li>
                    Lydia Ko: Ko spilte aldri collegiat golf, men ble profesjonell i 2013 i en alder av 16 år. Hun har siden oppnådd flere seire på LPGA-touren.
                </li>
                <li>
                    Jordan Spieth: Spieth spilte collegiat golf for University of Texas, der han vant NCAA-mesterskapet i 2012. Imidlertid ble han profesjonell i 2012 etter ett års studier. Han har siden oppnådd flere seire på PGA-touren, inkludert flere majortitler.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            I dette kapittelet har vi sett hvordan man går fra college til de profesjonelle, og hvilke alternative ruter som er tilgjengelige. I neste kapittel vil vi utforske de internasjonale innflytelsene og mulighetene innen collegiat golf.
        </MKTypography>
    </MKBox>
</MKBox>






                                              






                                  








<MKBox id="Kapittel 4" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Kapittel 4: Internasjonale Påvirkninger og Muligheter
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Collegiat golf er ikke bare en amerikansk idrett, men også en internasjonal en. Det er mange utenlandske spillere som kommer til USA for å studere og spille golf, og det er også mange amerikanske spillere som spiller i utlandet. I dette kapittelet vil vi se på påvirkningen og bidragene fra internasjonale student-utøvere og mulighetene for amerikanske collegiat golfspillere til å spille internasjonalt.
    </MKTypography>

    <MKBox id="4.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.1 Utenlandske Spillere i Collegiat Golf
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image3}
                    alt="Texas Longhorns, med Jordan Spieth, feirer nasjonalt mesterskap"
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal3(Image3)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Texas Longhorns, med Jordan Spieth, feirer nasjonalt mesterskap
                </figcaption>
            </figure>
            Collegiat golf er et attraktivt alternativ for mange utenlandske spillere som søker en kombinasjon av idrett og utdanning. De kan dra nytte av den høye kvaliteten til amerikanske universiteter, de utmerkede fasilitetene og veiledningen fra golfprogrammene, og den sterke konkurransen og eksponeringen fra collegiat golfturneringer.
            <br/>
            <br/>
            Utenlandske spillere tilfører også mye til amerikanske universiteter. De bringer mangfold, kultur og talent til sine lag. De kan også dele sine erfaringer og perspektiver med lagkameratene og trenerne sine, noe som bidrar til deres utvikling.
            <br/>
            <br/>
            Mange utenlandske spillere har hatt suksess i collegiat golf og har utmerket seg som student-utøvere. Eksempler inkluderer:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Maria Fassi: Fassi spilte collegiat golf ved University of Arkansas, der hun vant ANNIKA Award to ganger som den beste kvinnelige collegiat golfspilleren. Hun ble profesjonell i 2019 og har siden oppnådd flere profesjonelle seire.
                </li>
                <li>
                    Viktor Hovland: Hovland spilte collegiat golf ved Oklahoma State University, der han vant NCAA-mesterskapet i 2018. Han ble profesjonell i 2019 og har siden oppnådd flere profesjonelle seire.
                </li>
            </ul>
        </MKTypography>
    </MKBox>
    <MKBox id="4.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.2 Amerikanske Spillere i Utlandet
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Collegiat golf er ikke den eneste måten å utvikle seg som golfspiller på. Det er også mange muligheter for amerikanske collegiat golfspillere til å spille internasjonalt, oppleve andre kulturer og baner. Dette kan utvide horisontene deres, øke deres tilpasningsevne og forbedre spillet deres.
            <br/>
            <br/>
            Amerikanske collegiat golfspillere kan spille internasjonalt gjennom ulike programmer, som:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Palmer Cup: En årlig turnering mellom lag av amerikanske og internasjonale collegiat golfspillere, oppkalt etter Arnold Palmer, en kjent golfspiller som også spilte collegiat golf. Turneringen arrangeres på ulike steder rundt om i verden, som Frankrike, Irland, England og Sverige.
                </li>
                <li>
                    Eisenhower-troféet: En toårlig turnering mellom lag av amatør golfspillere fra ulike land, oppkalt etter Dwight D. Eisenhower, en tidligere amerikansk president og ivrig golfspiller. Turneringen arrangeres på ulike steder rundt om i verden, som Australia, Japan, Mexico og Tyrkia.
                </li>
                <li>
                    Study Abroad Program: Et akademisk program som tilbyr studenter muligheten til å studere i en semester eller et år ved et utenlandsk universitet. Noen universiteter har også golfprogrammer som samarbeider med utenlandske universiteter, slik at student-utøvere kan spille golf under oppholdet.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Mange amerikanske collegiat golfspillere har dratt nytte av å spille i utlandet og har hevet spillet sitt til et høyere nivå. Eksempler inkluderer:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Collin Morikawa: Morikawa spilte collegiat golf ved University of California, Berkeley, der han ble en fire ganger All-American. Han deltok også i Palmer Cup i 2017 og 2018, og i Eisenhower-troféet i 2018. Han ble profesjonell i 2019 og har siden oppnådd flere profesjonelle seire.
                </li>
                <li>
                    Jennifer Kupcho: Kupcho spilte collegiat golf ved Wake Forest University, der hun vant NCAA-mesterskapet i 2018. Hun deltok også i Palmer Cup i 2018, og i Curtis Cup i 2016 og 2018. Hun ble profesjonell i 2019 og har siden oppnådd flere profesjonelle seire.
                </li>
                <li>
                    Patrick Reed: Reed spilte collegiat golf ved University of Georgia og Augusta State University, der han vant NCAA-mesterskapet to ganger. Han deltok også i Palmer Cup i 2011 og studerte en semester ved University of Stirling i Skottland. Han ble profesjonell i 2011 og har siden oppnådd flere profesjonelle seire.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            I dette kapittelet har vi sett hvordan collegiat golf er en internasjonal idrett, og innflytelsen og mulighetene for utenlandske og amerikanske spillere. I neste kapittel vil vi se på betydningen av golfstipend, og rollen til Sportbeursamerika.nl.
        </MKTypography>
    </MKBox>
</MKBox>












                                              






                                  




<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 5: Idrettsstipender og Fremtiden til Utøvere
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrettsstipender gir mange muligheter for unge utøvere til å vise sitt talent og utvikle sine ferdigheter. En av de mest betydningsfulle mulighetene er å få et idrettsstipend for å studere og konkurrere på college-nivå. I dette kapittelet vil vi diskutere viktigheten av idrettsstipender og hvordan Sport Scholarships America kan hjelpe deg med å oppfylle drømmen din.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Viktigheten av Stipender
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et idrettsstipend er økonomisk støtte gitt av et universitet til en studentutøver for å kombinere studier og idrett. Det kan dekke kostnadene for skolepenger, bøker, bolig, måltider og andre utgifter. I tillegg kan det tilby fordeler som akademisk veiledning, medisinsk hjelp, treningsfasiliteter og utstyr.
      <br />
      <br />
      Å få et idrettsstipend er konkurransedyktig, med mange faktorer som spiller en rolle i tildelingsprosessen, inkludert akademiske prestasjoner, idrettslige resultater, personlighet, motivasjon, lederegenskaper og tilpasningsevne. Det er derfor viktig å forberede seg godt og skille seg ut fra konkurransen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Scholarships America Kan Hjelpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo for Sport Scholarships America, som viser sportsutstyr og en eksamenskappe, symboliserer fokuset på idrettsstipender for studentutøvere."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo for Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America er en organisasjon spesialisert i å veilede unge idrettstalenter til idrettsstipender. Med lidenskapelige og erfarne eksperter støtter Sport Scholarships America studentutøvere på hvert trinn i reisen, fra første orientering til endelig plassering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig inntaksintervju for å diskutere studentutøverens ønsker, muligheter og forventninger.</li>
        <li>En profesjonell evaluering av studentutøverens akademiske og idrettslige nivå.</li>
        <li>Et omfattende nettverk av kontakter med trenere, universiteter og høyskoler.</li>
        <li>Effektiv markedsføring av studentutøverens profil og videoer til potensielle trenere.</li>
        <li>Ekspertråd om de beste alternativene basert på studentutøverens preferanser, mål og budsjett.</li>
        <li>Forhandlinger om de beste stipendtilbudene på vegne av studentutøveren.</li>
        <li>Hjelp til å søke om nødvendige dokumenter, som visum, transkripsjon, diploma og opptaksprøver.</li>
        <li>Forberedelse til studentutøverens liv under stipendet, inkludert kultur, språk, regler og tips.</li>
        <li>Oppfølging og oppfølging under stipendperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vil du utforske stipendmuligheter og oppnå dine idrettslige og akademiske mål? <Link to="/no/Tjenester-Produkter">Se det komplette utvalget</Link> av tjenester som tilbys av Sport Scholarships America og planlegg en konsultasjon i dag.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;