/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Swimming1.jpg";
import Image2 from "assets/images/Sports_Images/Swimming2.jpeg";
import Image3 from "assets/images/Sports_Images/Swimming3.avif";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Nuoto in America: Ottenere una Borsa di Studio Sportiva e Far Parte di una Storia Sportiva Prestigiosa', isSubheader: false },
  
    { id: 'Capitolo 1', title: 'Capitolo 1: Storia e Sviluppo del Nuoto in America', isSubheader: false },
    { id: '1.1', title: '1.1 Gli Anni Iniziali e il Ruolo delle Università', isSubheader: true },
    { id: '1.2', title: '1.2 Crescita e Professionalizzazione', isSubheader: true },
  
    { id: 'Capitolo 2', title: 'Capitolo 2: Il Nuoto Collegiale Oggi', isSubheader: false },
    { id: '2.1', title: '2.1 Principali Competizioni e Conferenze', isSubheader: true },
    { id: '2.2', title: '2.2 Programmi di Spicco e Squadre Prestigiose', isSubheader: true },
  
    { id: 'Capitolo 3', title: 'Capitolo 3: Dalla Carriera Collegiale al Professionismo', isSubheader: false },
    { id: '3.1', title: '3.1 Il Cammino verso il Professionismo', isSubheader: true },
    { id: '3.2', title: '3.2 Il Nuoto Professionistico e le Olimpiadi', isSubheader: true },
  
    { id: 'Capitolo 4', title: 'Capitolo 4: Influenze Internazionali e Competizioni', isSubheader: false },
    { id: '4.1', title: '4.1 Influenze Esterne', isSubheader: true },
    { id: '4.2', title: '4.2 Gli Americani all\'Estero', isSubheader: true },
  
    { id: 'Capitolo 5', title: 'Capitolo 5: Borse di Studio Sportive e il Futuro degli Atleti', isSubheader: false },
    { id: '5.1', title: '5.1 L\'Importanza delle Borse di Studio', isSubheader: true },
    { id: '5.2', title: '5.2 Come Sport Scholarships America Può Assistere', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indice
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Nascondi</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostra</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Nuotare in America: Come Ottenere una Borsa di Studio Sportiva e Far Parte di una Storica Tradizione Sportiva
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Stadio di nuoto pieno di spettatori durante una competizione universitaria."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Uno stadio durante una partita
      </figcaption>
    </figure>
    Il nuoto è uno degli sport più popolari e competitivi al mondo. Milioni di persone praticano il nuoto, sia a scopo ricreativo che professionale. Ma sapevi che il nuoto in America ha raggiunto un livello completamente diverso? Nuotare in America non è solo uno sport; è una cultura, una tradizione e uno stile di vita.
    <br />
    <br />
    In questo articolo, ti diremo tutto sullo sviluppo del nuoto come sport competitivo negli Stati Uniti, sull'importanza del nuoto universitario per l'avanzamento dello sport e su come ottenere una borsa di studio sportiva per studiare e nuotare in America. Approfondiremo anche il ruolo di Sportbeursamerika.nl, un'organizzazione che aiuta i giovani nuotatori a realizzare i loro sogni.
    <br />
    <br />
    Il nuoto è uno sport praticato da secoli, ma è stato solo nel XIX secolo che ha cominciato a svilupparsi come attività organizzata e regolamentata. Le prime moderne Olimpiadi nel 1896 includevano competizioni di nuoto tra gli eventi principali, e da allora il nuoto è stato una delle discipline principali dell'agenda olimpica.
    <br />
    <br />
    Ma perché il nuoto è così popolare e di successo in America? Cosa lo rende così speciale e unico? La risposta risiede nel ruolo delle università nel promuovere il nuoto come disciplina accademica e atletica. Le università americane offrono non solo un'istruzione di alta qualità, ma anche eccellenti strutture, allenatori e orientamento per gli studenti-atleti che vogliono specializzarsi nel nuoto. Inoltre, forniscono supporto finanziario sotto forma di borse di studio sportive, permettendo ai nuotatori talentuosi di realizzare il proprio potenziale senza preoccuparsi dei costi dell'istruzione.
    <br />
    <br />
    Tuttavia, le borse di studio sportive non sono facili da ottenere. Sono molto competitive e richiedono un alto livello di dedizione, disciplina e prestazione. Pertanto, è essenziale essere ben preparati e cercare assistenza professionale quando si richiede una borsa di studio sportiva. Ecco dove entra in gioco Sportbeursamerika.nl.
    <br />
    <br />
    Sportbeursamerika.nl è un'organizzazione specializzata nella guida dei giovani nuotatori alle borse di studio sportive in America. Hanno un team di esperti che può assisterti in tutti gli aspetti del processo, come scegliere la giusta università, preparare il tuo curriculum e lettera di motivazione, prepararti per gli esami di ammissione e gli intervisti, organizzare il tuo visto e assicurazione, e molto altro ancora. Hanno anche contatti con centinaia di università e allenatori in America, permettendoti di trovare le migliori opzioni per il tuo profilo e i tuoi obiettivi.
    <br />
    <br />
    Quindi, se sogni di studiare e nuotare in America, Sportbeursamerika.nl è il tuo miglior alleato. Hanno già aiutato centinaia di nuotatori a realizzare i loro sogni, e possono aiutarti anche tu. Ma prima di contattarli, diamo prima uno sguardo alla storia e allo sviluppo del nuoto in America e perché è un'opportunità così fantastica per te.
  </MKTypography>
</MKBox>

<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 1: Storia e Sviluppo del Nuoto in America
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Il nuoto ha una lunga e ricca storia in America. Tutto è iniziato alla fine del XIX secolo quando le università hanno iniziato a formare le proprie squadre di nuoto e competizioni. Questo segnò l'inizio di una tradizione che avrebbe portato all'ascesa del nuoto come sport nazionale.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Primi Anni e il Ruolo delle Università
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La prima università a istituire ufficialmente una squadra di nuoto fu Harvard nel 1878. Poco dopo, altre prestigiose università come Yale, Princeton, Columbia e Cornell fecero lo stesso. Queste università organizzavano incontri e tornei intercollegiali che attiravano l'attenzione del pubblico e dei media. Introdussero anche nuove regole e tecniche per il nuoto, tra cui la rana, il dorso e la farfalla.
      <br />
      <br />
      Nel 1902 fu fondata la Intercollegiate Swimming Association (ISA), diventando la prima organizzazione nazionale per il nuoto universitario. La ISA organizzava campionati annuali per uomini, dove venivano onorati i migliori team e nuotatori individuali. Nel 1916 fu istituita la National Collegiate Athletic Association (NCAA) come organizzazione sovraordinata per tutti gli sport universitari. La NCAA assunse la responsabilità di organizzare i campionati nazionali per il nuoto universitario, che continuano ancora oggi.
      <br />
      <br />
      Il nuoto universitario non era limitato agli uomini. Anche le donne iniziarono a competere a livello universitario all'inizio del XX secolo, anche se affrontavano più restrizioni e discriminazioni. Le prime squadre femminili di nuoto universitario furono formate nel 1914 all'Università di Chicago e nel 1916 all'Università del Wisconsin. Nel 1922 fu fondata la Women’s Intercollegiate Swimming Association (WISA), fungendo da equivalente della ISA per le donne. Anche la WISA organizzava campionati annuali per donne, che acquisirono una notevole popolarità.
      <br />
      <br />
      Il nuoto universitario ha svolto un ruolo cruciale nel promuovere il nuoto come sport professionistico in America. Molti nuotatori universitari passarono alle leghe professionistiche e alle Olimpiadi, dove rappresentarono il loro paese e vinsero medaglie. Alcuni dei nomi più noti includono Johnny Weissmuller, Mark Spitz, Matt Biondi, Janet Evans, Michael Phelps e Katie Ledecky.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crescita e Professionalizzazione
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Oltre al nuoto universitario, emersero altre forme di nuoto professionale in America. Una di queste era il nuoto per maestri, che si concentrava su nuotatori adulti desiderosi di migliorare le proprie abilità o mantenere la propria forma fisica. Il nuoto per maestri iniziò nel 1970 con la fondazione del United States Masters Swimming (USMS), un'organizzazione che offriva club locali, competizioni e programmi per nuotatori adulti. Il nuoto per maestri crebbe rapidamente in un movimento globale, con milioni di membri in oltre 100 paesi.
      <br />
      <br />
      Un'altra forma di nuoto professionale era il nuoto in acque libere, che si svolgeva in corpi idrici naturali come laghi, fiumi e oceani. Il nuoto in acque libere era uno sport impegnativo e avventuroso che richiedeva resistenza, capacità di navigazione e adattabilità. Il nuoto in acque libere ottenne più riconoscimento nel 2008 quando fu aggiunto come evento olimpico. Alcuni dei nuotatori in acque libere più famosi includono Gertrude Ederle, Lynne Cox, Maarten van der Weijden e Ous Mellouli.
      <br />
      <br />
      Il nuoto professionale raggiunse il suo apice durante le Olimpiadi, dove i nuotatori americani dominarono. Dal 1896, l'America ha vinto oltre 500 medaglie nel nuoto, tra cui più di 200 medaglie d'oro. Questo rende l'America di gran lunga il paese più vincente nel nuoto olimpico.
      <br />
      <br />
      Ma come puoi anche far parte di questa gloriosa storia sportiva? Come puoi studiare e nuotare in America con una borsa di studio sportiva? Te lo diremo nel prossimo capitolo.
    </MKTypography>
  </MKBox>
</MKBox>

                                              





                                  








<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 2: Il Nuoto Universitario Oggi
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Come hai visto, il nuoto universitario ha una lunga e orgogliosa storia in America. Ma che ne è del presente? Come è organizzato e strutturato il nuoto universitario oggi? Quali sono le competizioni e le conferenze chiave? Quali sono i programmi migliori e le squadre prestigiose? Approfondiamo.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Competizioni e Conferenze Chiave
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Katie Ledecky nuota per Stanford in una competizione universitaria."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Katie Ledecky in azione come nuotatrice per Stanford
        </figcaption>
      </figure>
      Il nuoto universitario in America è principalmente regolato dalla NCAA, che ha tre divisioni: Division I, Division II e Division III. Ogni divisione ha le proprie regole, criteri e campionati. In generale, la Division I è la divisione più competitiva e prestigiosa, con i migliori nuotatori, allenatori e strutture. La Division II e la Division III sono meno competitive ma offrono comunque un'istruzione e uno sport di alta qualità.
      <br />
      <br />
      All'interno di ciascuna divisione, ci sono varie conferenze, che sono gruppi di università che sono geograficamente o storicamente connesse. Ciascuna conferenza organizza i propri campionati, con le squadre migliori e i nuotatori individuali che si qualificano per i campionati nazionali NCAA. Alcune delle conferenze più note nel nuoto universitario includono:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Il Southeastern Conference (SEC), composto da 14 università nel sud-est degli Stati Uniti, come Alabama, Florida, Georgia, Kentucky, LSU, Tennessee e Texas A&M. Il SEC è noto per la sua dominanza nel nuoto, specialmente nelle competizioni maschili. Il SEC ha vinto più di 50 titoli nazionali nel nuoto, con oltre 40 di essi provenienti dalla squadra maschile della Florida.
        </li>
        <li>
          Il Pacific-12 Conference (Pac-12), composto da 12 università sulla costa occidentale dell'America, tra cui Arizona, California, Stanford, UCLA, USC e Washington. Il Pac-12 è noto per la sua eccellenza nel nuoto, specialmente nelle competizioni femminili. Il Pac-12 ha vinto più di 40 titoli nazionali nel nuoto, con oltre 30 di essi appartenenti alla squadra femminile di Stanford.
        </li>
        <li>
          Il Big Ten Conference (Big Ten), composto da 14 università nel Midwest e nel Nord-est degli Stati Uniti, come Indiana, Michigan, Minnesota, Ohio State, Penn State e Wisconsin. Il Big Ten è noto per la sua diversità ed equilibrio nel nuoto, con squadre forti sia nelle competizioni maschili che femminili. Il Big Ten ha vinto più di 30 titoli nazionali nel nuoto, con oltre 20 di essi conquistati dalla squadra maschile di Michigan.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Questi sono solo alcuni esempi delle numerose conferenze che esistono nel nuoto universitario. Ciascuna conferenza ha la propria cultura, rivalità e tradizioni, contribuendo all'emozione del nuoto universitario.
    </MKTypography>

  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programmi Migliori e Squadre Prestigiose
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Oltre alle conferenze, ci sono singole università che si distinguono per i loro programmi migliori e le loro squadre prestigiose di nuoto. Queste università non solo offrono un'istruzione eccellente ai loro studenti, ma forniscono anche allenamenti, preparazione e strutture di alto livello per i loro atleti. Attraggono inoltre molti nuotatori talentuosi sia nazionali che internazionali che vogliono studiare e nuotare ai massimi livelli.
      <br />
      <br />
      Alcune di queste università di punta includono:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Stanford University: Stanford è una delle università più rinomate al mondo sia accademicamente che atleticamente. Stanford ha uno dei migliori programmi di nuoto femminile in America, con oltre 10 titoli nazionali e più di 100 medaglie olimpiche. Stanford ha anche un forte programma maschile di nuoto, con oltre 5 titoli nazionali e più di 50 medaglie olimpiche. Alcuni famosi ex alunni di Stanford includono John Hencken, Pablo Morales, Jenny Thompson, Summer Sanders, Misty Hyman, Janet Evans e Katie Ledecky.
        </li>
        <li>
          University of California: California è un'altra università di punta sia accademicamente che atleticamente. California ha uno dei migliori programmi maschili di nuoto in America, con oltre 10 titoli nazionali e più di 100 medaglie olimpiche. California ha anche un forte programma femminile di nuoto, con oltre 5 titoli nazionali e più di 50 medaglie olimpiche. Alcuni famosi ex alunni della California includono Matt Biondi, Anthony Ervin, Natalie Coughlin, Nathan Adrian, Dana Vollmer e Ryan Murphy.
        </li>
        <li>
          University of Texas: Texas è una delle università più grandi e influenti in America, con una forte reputazione in ambito accademico e sportivo. Texas ha uno dei migliori programmi maschili di nuoto in America, con oltre 15 titoli nazionali e più di 100 medaglie olimpiche. Texas ha anche un forte programma femminile di nuoto, con oltre 5 titoli nazionali e più di 50 medaglie olimpiche. Alcuni famosi ex alunni del Texas includono Josh Davis, Aaron Peirsol, Ian Crocker, Brendan Hansen, Ricky Berens e Joseph Schooling.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Queste sono solo alcune delle molte università di punta nel nuoto universitario. Ciascuna università ha la propria storia, filosofia e visione che contribuiscono allo sviluppo dei nuotatori.
      <br />
      <br />
      Come puoi vedere, il nuoto universitario oggi è uno sport altamente dinamico e competitivo che offre molte opportunità agli studenti-atleti che desiderano eccellere sia accademicamente che atleticamente. Ma come puoi accedere a queste opportunità? Come puoi ottenere una borsa di studio sportiva per studiare e nuotare in America? Te lo diremo nel prossimo capitolo.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  








<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 3: Dal College al Livello Professionale
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Studiare e nuotare in America non è solo un'ottima esperienza, ma anche una fantastica opportunità per avviare la tua carriera come nuotatore professionista. Molti nuotatori universitari passano al livello professionale, dove rappresentano il proprio paese in competizioni internazionali e ai Giochi Olimpici. Ma come si fa il salto dal college al livello professionale? Quali sono le sfide e i vantaggi di questa transizione? Approfondiamo.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Il Percorso verso il Professionismo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Per fare il passaggio dal college al livello professionale, devi prima soddisfare i requisiti della NCAA. La NCAA ha regole rigide riguardanti lo status di dilettante degli studenti-atleti, il che significa che non puoi guadagnare o accettare denaro per le tue prestazioni sportive. Questo significa che non puoi entrare in contratti di sponsorizzazione, accordi per premi in denaro o altre forme di compensazione mentre studi e nuoti ancora a livello collegiale.
      <br />
      <br />
      Tuttavia, una volta completati gli studi o decidi di interrompere la tua formazione, puoi rinunciare al tuo status di dilettante e diventare un nuotatore professionista. Ciò significa che puoi unirti a un'organizzazione professionale di nuoto, come USA Swimming, che è l'ente di governo nazionale per il nuoto in America. USA Swimming ti offre l'opportunità di partecipare a competizioni di nuoto professionale come la Pro Swim Series, l'US Open, i Campionati Nazionali USA e le Prove Olimpiche. Queste competizioni sono progettate per migliorare le tue abilità, aumentare il tuo ranking e qualificarti per eventi internazionali.
      <br />
      <br />
      Come nuotatore professionista, puoi anche beneficiare di contratti di sponsorizzazione, premi in denaro e altre forme di compensazione per le tue prestazioni sportive. Questo può aiutare a migliorare la tua situazione finanziaria, sostenere il tuo stile di vita e aumentare la tua motivazione. Tuttavia, diventare un nuotatore professionista comporta anche più responsabilità e sfide. Ad esempio, dovrai gestire il tuo programma di allenamento, piano alimentare, spese di viaggio, cure mediche e tasse. Dovrai anche affrontare una maggiore concorrenza, pressione e aspettative da parte tua e degli altri.
      <br />
      <br />
      Pertanto, è fondamentale essere ben preparati per la transizione dal college al livello professionale. Devi non solo possedere eccellenti abilità di nuoto, ma anche avere una forte mentalità, obiettivi chiari e un buon supporto. Devi anche essere realistico sulle tue possibilità e opportunità e essere disposto a lavorare sodo e fare sacrifici.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Il Nuoto Professionale e i Giochi Olimpici
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Uno dei più grandi sogni di ogni nuotatore professionista è partecipare ai Giochi Olimpici, l'evento sportivo più prestigioso e significativo al mondo. Le Olimpiadi offrono non solo la possibilità di rappresentare il proprio paese e vincere medaglie, ma anche di fare storia e diventare una leggenda.
      <br />
      <br />
      Tuttavia, per qualificarti per i Giochi Olimpici, devi prima soddisfare i requisiti del Comitato Olimpico Internazionale (COI) e della Federazione Internazionale del Nuoto (FINA). Queste organizzazioni hanno regole rigide riguardanti i criteri di qualificazione, il numero di partecipanti per paese, i controlli antidoping e altri aspetti del nuoto olimpico.
      <br />
      <br />
      Uno dei requisiti fondamentali per qualificarsi ai Giochi Olimpici è raggiungere uno standard di tempo specifico in una competizione riconosciuta. Questo standard di tempo è determinato dalla FINA in base alla media delle migliori 16 prestazioni mondiali in ciascuna disciplina negli ultimi quattro anni. Lo standard di tempo varia per sesso, gruppo di età e stile.
      <br />
      <br />
      Un altro requisito essenziale per qualificarsi ai Giochi Olimpici è essere selezionato dalla propria organizzazione nazionale di nuoto, come USA Swimming. Ogni organizzazione nazionale di nuoto ha il proprio processo di selezione, che prevede tipicamente una serie di prove o campionati in cui vengono scelti i migliori nuotatori per rappresentare il paese. Ogni organizzazione nazionale di nuoto ha anche un numero limitato di posti per disciplina, a seconda del numero di nuotatori qualificati e delle quote fissate dal COI e dalla FINA.
      <br />
      <br />
      Se riesci a soddisfare questi requisiti e a qualificarti per i Giochi Olimpici, sei uno dei fortunati pochi che hanno l'opportunità di fare parte del più grande evento sportivo al mondo. Sarai in grado di competere contro i migliori nuotatori del mondo, sfidarti e potenzialmente vincere una medaglia e incidere il tuo nome nei libri di storia.
      <br />
      <br />
      Come puoi vedere, la transizione dal college al livello professionale è un viaggio emozionante e gratificante che offre molte opportunità per la tua carriera nel nuoto. Ma come puoi iniziare questo viaggio? Come puoi ottenere una borsa di studio sportiva per studiare e nuotare in America? Te lo diremo nel prossimo capitolo.
    </MKTypography>
  </MKBox>
</MKBox>





                                  







<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 4: Influenze e Competizioni Internazionali
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Il nuoto in America non è solo uno sport nazionale ma anche internazionale. Il nuoto in America è influenzato da e influisce su altri paesi e culture, ognuno dei quali ha i propri stili, tecniche e tradizioni nel nuoto. Il nuoto in America è anche sfidato ed arricchito da altre competizioni internazionali, offrendo opportunità di imparare e competere contro nuotatori provenienti da tutto il mondo. Ma come sono nate e si sono sviluppate queste influenze e competizioni internazionali? In che modo influenzano il nuoto collegiale e professionale in America? Approfondiamo.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Influenze Straniere
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Nuotatore in azione durante il campionato nazionale della NCAA."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Il campionato nazionale della NCAA
        </figcaption>
      </figure>
      Il nuoto è uno sport universale praticato in quasi ogni paese del mondo. Ogni paese ha la sua storia, cultura e identità nel nuoto, riflesse nel modo in cui nuotano, si allenano e allenano. Alcuni paesi hanno avuto un'influenza significativa sul nuoto in America, sia in modo positivo che negativo.
      <br />
      <br />
      Una delle influenze positive è l'Australia, che è uno dei maggiori rivali e alleati dell'America nel nuoto. L'Australia ha una lunga e orgogliosa tradizione nel nuoto, con oltre 100 medaglie olimpiche e oltre 20 record mondiali. L'Australia è nota per la sua innovazione e creatività nel nuoto, introducendo nuove tecniche, strategie e metodi. L'Australia ha inoltre prodotto molti nuotatori talentuosi e carismatici, come Dawn Fraser, Shane Gould, Ian Thorpe, Grant Hackett, Libby Trickett e Cate Campbell.
      <br />
      <br />
      L'Australia ha influenzato il nuoto in America creando una sana competizione e collaborazione che motiva entrambi i paesi a migliorare e imparare l'uno dall'altro. Molti nuotatori australiani e americani hanno allenato, studiato o lavorato insieme, migliorando le loro abilità, conoscenze e amicizie. Alcuni esempi includono:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Nel 1976, Mark Spitz, la stella americana che ha vinto sette medaglie d'oro a Monaco, si è allenato insieme a John Devitt, la leggenda australiana che ha vinto due medaglie d'oro a Melbourne e Roma.
        </li>
        <li>
          Nel 1988, Matt Biondi, la sensazione americana che ha vinto sette medaglie a Seoul, si è laureato all'Università della California in economia politica.
        </li>
        <li>
          Nel 2016, Joseph Schooling, la sorpresa di Singapore che ha vinto la prima medaglia d'oro per il suo paese nei 100m farfalla a Rio de Janeiro, si è laureato all'Università del Texas in economia.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Questi sono solo alcuni esempi di come le influenze straniere hanno influenzato il nuoto in America. Molti altri paesi e culture hanno lasciato il segno nel nuoto, tra cui Giappone, Cina, Russia, Francia, Brasile e Sudafrica. Il nuoto in America non è solo uno sport nazionale ma anche internazionale che evolve e cambia costantemente attraverso l'interazione con altri paesi e culture.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Americani all'Estero
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Il nuoto in America non è solo influenzato da e influisce su altri paesi e culture, ma è anche sfidato e arricchito da altre competizioni internazionali. Il nuoto in America offre l'opportunità di imparare e competere contro nuotatori provenienti da tutto il mondo, ognuno con i propri stili, tecniche e tradizioni nel nuoto. Il nuoto in America offre anche la possibilità di viaggiare e sperimentare luoghi, persone e culture diverse, ampliando i tuoi orizzonti e cambiando la tua prospettiva.
      <br />
      <br />
      Una delle principali competizioni internazionali a cui partecipano i nuotatori americani sono i Campionati Mondiali di Nuoto, che si tengono ogni due anni da parte della FINA. I Campionati Mondiali di Nuoto sono un evento che comprende tutte le discipline del nuoto, tra cui lungo corso, corto corso, acque libere, nuoto sincronizzato e tuffi. I Campionati Mondiali di Nuoto offrono l'opportunità di competere contro i migliori nuotatori del mondo, migliorare il tuo ranking e migliorare la tua qualificazione per altri eventi.
      <br />
      <br />
      Un'altra significativa competizione internazionale a cui partecipano i nuotatori americani è la Coppa del Mondo di Nuoto, che si tiene annualmente dalla FINA. La Coppa del Mondo di Nuoto è una serie di eventi in corto che si svolgono in varie città del mondo. La Coppa del Mondo di Nuoto è un'opportunità per gareggiare contro avversari diversi, testare le tue abilità e guadagnare premi in denaro.
      <br />
      <br />
      Una terza importante competizione internazionale a cui partecipano i nuotatori americani sono i Giochi Panamericani, che si tengono ogni quattro anni da parte dell'Organizzazione Sportiva Panamericana (PASO). I Giochi Panamericani sono un evento multisportivo che riunisce atleti del Nord, Sud e Centro America. I Giochi Panamericani sono un'occasione per rappresentare il proprio continente, mostrare solidarietà e vincere medaglie.
      <br />
      <br />
      Questi sono solo alcuni esempi delle molte competizioni internazionali a cui partecipano i nuotatori americani. Ci sono molte altre competizioni internazionali nel nuoto, come i Giochi del Commonwealth, i Campionati Europei, i Giochi Asiatici e i Giochi Africani.
    </MKTypography>
  </MKBox>
</MKBox>
                          






                                  






<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 5: Borse di Studio Sportive e il Futuro degli Atleti
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le borse di studio sportive offrono numerose opportunità ai giovani atleti di mostrare il loro talento e sviluppare le loro abilità. Una delle opportunità più significative è ottenere una borsa di studio sportiva per studiare e competere a livello universitario. In questo capitolo, discuteremo dell'importanza delle borse di studio sportive e di come Sport Scholarships America può aiutarti a realizzare il tuo sogno.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 L'Importanza delle Borse di Studio
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Una borsa di studio sportiva è un sostegno finanziario fornito da un'università a uno studente-atleta per combinare studio e sport. Può coprire i costi di iscrizione, libri, alloggio, pasti e altre spese. Inoltre, può offrire vantaggi come il tutorato accademico, l'assistenza medica, le strutture di allenamento e l'attrezzatura.
      <br />
      <br />
      Ottenere una borsa di studio sportiva è competitivo, con molti fattori che influenzano il processo di assegnazione, tra cui risultati accademici, prestazioni sportive, personalità, motivazione, leadership e adattabilità. È quindi importante prepararsi bene e distinguersi dalla concorrenza.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Come Sport Scholarships America Può Aiutare
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo di Sport Scholarships America, raffigurante attrezzature sportive e una toga, simbolo del focus sulle borse di studio sportive per gli studenti-atleti."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo di Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America è un'organizzazione specializzata nell'orientamento dei giovani talenti sportivi verso borse di studio sportive. Con esperti appassionati ed esperti, Sport Scholarships America supporta gli studenti-atleti in ogni fase del percorso, dall'orientamento iniziale alla collocazione finale. I loro servizi includono:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Un'intervista personale per discutere i desideri, le possibilità e le aspettative dello studente-atleta.</li>
        <li>Una valutazione professionale del livello accademico e sportivo dello studente-atleta.</li>
        <li>Una vasta rete di contatti con allenatori, università e college.</li>
        <li>Promozione efficace del profilo e dei video dello studente-atleta presso allenatori potenziali.</li>
        <li>Consulenza esperta sulle migliori opzioni in base alle preferenze, agli obiettivi e al budget dello studente-atleta.</li>
        <li>Negoziazione delle migliori offerte di borse di studio per conto dello studente-atleta.</li>
        <li>Assistenza nella richiesta dei documenti necessari, come visto, trascrizione, diploma e test di ammissione.</li>
        <li>Preparazione alla vita dello studente-atleta durante la borsa di studio, compresa cultura, lingua, regole e consigli.</li>
        <li>Assistenza e monitoraggio durante il periodo di borsa di studio.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vuoi esplorare le opportunità di borse di studio e raggiungere i tuoi obiettivi sportivi e accademici? <Link to="/it/Servizi-Prodotti">Consulta la gamma completa</Link> dei servizi offerti da Sport Scholarships America e prenota una consultazione oggi stesso.
    </MKTypography>
  </MKBox>
</MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;