import Alabama from "assets/images/Staten_Images/Alabama.webp"
import Florida from "assets/images/Staten_Images/Florida.jpeg"
import Connecticut from "assets/images/Staten_Images/Connecticut.jpeg"
import Kentucky from "assets/images/Staten_Images/Kentucky.webp"
import Michigan from "assets/images/Staten_Images/Michigan.jpeg"
import North_Carolina from "assets/images/Staten_Images/North_Carolina.jpg"
import Texas from "assets/images/Staten_Images/Texas.jpeg"



const states = [
  {
    "name": "Alabama",
    image: Alabama,
    "imageDescription": "Alabama vs Auburn är en av de största rivaliteterna inom collegelagsfotboll.",
    "description": [
      "Collegesport är en integrerad del av kulturen i Alabama, med särskild tonvikt på amerikansk fotboll. University of Alabama (UA) i Tuscaloosa och Auburn University i Auburn dominerar sportscenen i delstaten.",
      "UAs fotbollslag, Crimson Tide, har en rik historia och har vunnit talrika nationella mästerskap. Hemmamatcherna på den enorma Bryant-Denny Stadium kan locka upp till 100 000 fans. Fansens passion är enorm, och sången 'Roll Tide' är känd över hela Alabama.",
      "Å andra sidan har vi Auburn University med deras fotbollslag, Tigers. De, tillsammans med UA, utgör Iron Bowl, en av de mest intensiva rivaliteterna inom collegelagsport. Denna årliga kollision vänder hela staten upp och ner och garanterar spänning och spektakel.",
      "Förutom fotboll har Alabama starka program inom basket, baseboll och andra sporter, som stöds av en kraftfull känsla av gemenskap. I Alabama är sport mer än bara tidsfördriv; det är en väsentlig del av delstatens livsstil och identitet."
    ]
  },
  {
    "name": "Alaska",
    "description": [
      "Till skillnad från många andra amerikanska stater spelar collegesport en mer anspråkslös roll i Alaska, främst på grund av dess avlägsna läge och unika klimat. Här ligger tonvikten mer på vintersport och utomhusaktiviteter än på traditionella amerikanska sporter som fotboll och baseboll.",
      "University of Alaska Anchorage (UAA) och University of Alaska Fairbanks (UAF) är de primära institutionerna med aktiva sportsprogram. UAA har ett starkt skid- och skridskolag, medan UAF är känt för sitt ishockeylag, Nanooks, som har en lojal lokal följarskara.",
      "I Alaska handlar collegesport mer om upplevelsen och gemenskapen än om storskaliga evenemang. Matcherna ger en möjlighet för gemenskapen att samlas, heja på idrottarna och fira delstatens unika sportskultur.",
      "Trots den mindre skalan har collegesport i Alaska sin egen charm och erbjuder en varm gemenskap i delstatens kalla miljö."
    ]
  },
  {
    "name": "Arizona",
    "description": [
      "Collegesport är oerhört populärt i Arizona, med en rik tradition inom både amerikansk fotboll och basket. Arizona State University (ASU) i Tempe och University of Arizona (UA) i Tucson är pelarna inom delstatens sportscen.",
      "ASUs fotbollslag, Sun Devils, har en passionerad följarskara, medan deras basketlag regelbundet lyser i NCAA-turneringar. UA, med sina Wildcats, är kända för sitt framgångsrika basketprogram och intensiva rivalitet med ASU, som kulminerar i det årliga 'Duel in the Desert'-fotbollsmatchen.",
      "Förutom de stora sporterna utmärker sig även Arizona inom baseboll, softboll och golf, med universitetslag ofta i nationellt fokus. Gemenskapens stöd är överväldigande, och sportevenemang betraktas som betydande sociala tillställningar.",
      "Collegesport i Arizona förkroppsligar tävlingsandan och gemenskapen, med intensiv rivalitet och fansens passion som bidrar till en livlig och livfull sportskultur."
    ]
  },
  {
    "name": "Arkansas",
    "description": [
      "I Arkansas spelar collegesport en central roll i samhällslivet, med särskild tonvikt på amerikansk fotboll och basket. University of Arkansas i Fayetteville är en kraft att räkna med i delstaten, känt för sina Razorbacks.",
      "Razorbacks fotbollslag njuter av regional och nationell erkännande, med en hängiven fanskara som passionerat följer varje match. Deras hemmamatcher på den imponerande Razorback Stadium är ett spektakel, där sången 'Woo Pig Sooie' ekar från tiotusentals fans.",
      "Basket är också en betydande del av sportskulturen i Arkansas, med Razorbacks som regelbundet deltar i NCAA-turneringar. Spänningen och energin i Bud Walton Arena under basketssäsongen är påtaglig.",
      "Bortom de stora sporterna har Razorbacks också starka program inom friidrott, baseboll och softboll. Denna mångfald inom sporten berikar sportskulturen i Arkansas, där collegesport är en källa till stolthet, gemenskapssinne och underhållning."
    ]
  },
  {
    "name": "California",
    "description": [
      "Collegesport i Kalifornien är mångsidig och mycket konkurrenskraftig, med många universitet som utmärker sig inom olika sporter. Från amerikansk fotboll till simning, delstaten har allt.",
      "Pac-12-konferensen, med framstående skolor som University of California, Los Angeles (UCLA) och University of Southern California (USC), är känd för sin höga nivå inom sport. UCLAs basketlag har en rik historia, medan USC traditionellt utmärker sig inom fotboll.",
      "Förutom de välkända lagen finns det också mindre skolor med imponerande sportprogram, som Stanford University, känt för sin excellens inom simning och friidrott. Denna mångfald skapar en dynamisk och spännande sportsmiljö i delstaten.",
      "Kaliforniens behagliga klimat och omfattande sportsanläggningar främjar aktiv deltagande och entusiasm för collegesport. Oavsett om det är en fotbollsmatch i en fullsatt arena eller en lokal simtävling är sport en integrerad del av kaliforniernas livsstil."
    ]
  },
  
  {
    "name": "Colorado",
    "description": [
      "Collegesport i Colorado njuter av ett livligt och entusiastiskt följe, delvis på grund av den imponerande naturliga omgivningen och den aktiva utomhuslivsstilen. Delstaten har en rik tradition inom amerikansk fotboll, basket och vintersporter.",
      "University of Colorado Boulder (CU) och Colorado State University (CSU) är framstående aktörer inom collegelagsscenen, med den årliga 'Rocky Mountain Showdown'-fotbollsmatchen som höjdpunkten på deras rivalitet.",
      "CUs fotbollslag, Buffaloes, har en passionerad fanskara, medan deras skidteam utmärker sig i nationella tävlingar och gynnas av Colorados utmärkta vintersportanläggningar. CSU är också konkurrenskraftigt inom olika sporter, med en stark närvaro inom amerikansk fotboll och basket.",
      "Förutom de större universiteten har mindre högskolor i Colorado också aktiva sportprogram, vilket bidrar till den rika och mångsidiga sportkulturen i delstaten. Collegesport i Colorado står för gemenskap, uthållighet och firandet av sport i alla former."
    ]
  },
  {
    "name": "Connecticut",
    image: Connecticut,
    "imageDescription": "UCONNs damers basketlag är ett av de mest dominerande collegelagen genom tiderna",
    "description": [
      "Collegesport i Connecticut domineras av basket, med särskild tonvikt på University of Connecticuts (UConn) framgångsrika program. Delstaten har en rik tradition och en passionerad fanskara djupt engagerad i collegesport.",
      "UCONNs damers basketlag är nationellt erkänt och har vunnit flera NCAA-mästerskap, vilket gör dem till en maktfaktor inom sporten. Herrlaget har också uppnått imponerande prestationer och gör basket till en central del av sportskulturen i Connecticut.",
      "Även om basketen regerar finns det också starka program inom andra sporter som ishockey, fotboll och lacrosse, där olika universitet tävlar på nationell nivå.",
      "Collegesport i Connecticut förkroppsligar stoltheten och gemenskapssinnet i delstaten, där sportevenemang ger möjlighet för fans att samlas, heja på lagen och delta i en rik sporttradition."
    ]
  },
  {
    "name": "Delaware",
    "description": [
      "Trots att det är en av de minsta staterna i USA har Delaware en livlig collegelagsscenen med starkt samhällsengagemang. University of Delaware (UD) i Newark är i centrum för aktiviteten, där Blue Hens utmärker sig inom olika sporter.",
      "UDs fotbollslag har en rik historia och en engagerad fanskara, med spännande hemmamatcher på Delaware Stadium. Basket och lacrosse är också populära sporter, där lagen regelbundet tävlar om regional och nationell erkännande.",
      "Trots sin lilla storlek har Delaware starka program inom friidrott, simning och andra sporter, vilket alla bidrar till den dynamiska sportkulturen i delstaten. Collegesport här är mer än bara tidsfördriv; det är en viktig del av samhällets identitet, vilket främjar en stark koppling mellan universitetet och den bredare samhällen.",
      "I Delaware förenar collegesport människor, med den lilla skalan som skapar en sammanhållen och passionerad sportgemenskap."
    ]
  },
  {
    "name": "Florida",
    image: Florida,
    "imageDescription": "Ben Hill Griffin Stadium är hem för Florida Gators",
    "description": [
      "Florida är en het punkt för collegesport, med några av de mest konkurrenskraftiga och passionerade programmen i landet. Amerikansk fotboll och basket dominerar sportscenen, men det finns också utrymme för olika andra sporter.",
      "Lag som Florida Gators och Florida State Seminoles har nationell erkänning och en rik historia inom både fotboll och basket. Deras intensiva rivalitet lockar tusentals fans årligen, och matcherna är höjdpunkterna i sportsäsongen.",
      "Dessutom finns det också mindre universitet och högskolor med starka sportprogram som bidrar till den rika sportkulturen i delstaten. Baseboll, friidrott och simning är också populära, och de utmärkta faciliteterna och det soliga klimatet gör Florida till en idealisk plats för collegelag idrottare.",
      "I Florida är collegesport mer än bara tävling; det är en livsstil, en källa till stolthet och en väsentlig del av gemenskapens identitet."
    ]
  },
  {
    "name": "Georgia",
    "description": [
      "Collegesport i Georgia präglas av intensiv passion och djupt rotade rivaliteter, med amerikansk fotboll som den obestridliga kungen. Delstaten är hem för några av de mest prestigefyllda programmen i landet, med fans som upplever varje match passionerat.",
      "University of Georgia Bulldogs i Athens och Georgia Tech Yellow Jackets i Atlanta är de stora aktörerna, med sin årliga 'Clean, Old-Fashioned Hate'-rivalitet som griper tag i delstaten. Fotbollsmatcher, särskilt i UGAs massiva Sanford Stadium, lockar tiotusentals fans.",
      "Förutom fotbollen utmärker sig Georgia också inom basket, friidrott och baseboll, med talangfulla idrottare och engagerade supportrar. Sportprogrammen vid mindre skolor och högskolor bidrar till en rik och mångsidig sportlandskap i hela delstaten.",
      "I Georgia är sport mer än tävling; det är en livsstil, en källa till gemenskapssinne och en oumbärlig del av den regionala identiteten."
    ]
  },
  
  {
    "name": "Hawaii",
    "description": [
      "Collegesport på Hawaii erbjuder en unik upplevelse inspirerad av öns skönhet och starka lokala kultur. Trots att de är geografiskt isolerade är sportslag och fans på Hawaii oerhört passionerade och engagerade.",
      "University of Hawaii at Manoa spelar en central roll i collegelagsscenen, med sitt fotbollslag, Rainbow Warriors, som en betydande fokuspunkt. Deras hemmamatcher på Aloha Stadium skapar en livlig och festlig atmosfär, med en stark ö-anda.",
      "Förutom amerikansk fotboll är volleyboll, surfing och kanotkapplöpning också populära, vilket speglar den unika sportkulturen och den naturliga landsbygden på Hawaii. Rainbow Warriors är också konkurrenskraftiga inom basket och baseboll, med en hängivenhet som delas av hela samhället.",
      "På Hawaii representerar collegesporten den Aloha-anda, där lag och fans omfamnar ö-kulturen, gemenskapen och stoltheten och skapar en unik och livlig sportupplevelse tillsammans."
    ]
  },
  {
    "name": "Idaho",
    "description": [
      "Collegesport i Idaho har en stark tradition, med särskild tonvikt på amerikansk fotboll och basket. Känslan av gemenskap och engagemang i sportlag är anmärkningsvärt, även om delstaten inte har enorma universitet.",
      "Boise State University, känd för sin blåa fotbollsplan, spelar en framträdande roll i Idahos sportlandskap. Fotbollslaget har fått nationell uppmärksamhet med sin framgång och innovativa spelstil, och hemmamatcherna är en spektakulär upplevelse i sig.",
      "Förutom Boise State bidrar mindre skolor och högskolor också till den rika sportkulturen, med program inom sporter som baseboll, friidrott och vintersporter som drar nytta av Idahos unika naturmiljö.",
      "I Idaho är collegesport mer än bara ett tidsfördriv; det är en livsstil och en väsentlig del av delstatens livsstil och identitet, där samhällen samlas för att stödja sina lag."
    ]
  },
  {
    "name": "Illinois",
    "description": [
      "Illinois har en rik historia och djup kärlek för collegesport, med kraftfulla program och engagerade fans. Amerikansk fotboll och basket drar betydande uppmärksamhet året runt, men det finns också ett brett utbud av andra sporter som frodas.",
      "University of Illinois Fighting Illini och Northwestern University Wildcats leder vägen, med intensiva rivaliteter och konkurrenskraftiga ligor som håller delstaten levande. Memorial Stadium och Ryan Field är idrottstempel, där varje match är en oförglömlig upplevelse.",
      "Basket är också framträdande, med delstaten regelbundet producerande talang som utmärker sig både nationellt och internationellt. Illinois Fighting Illini har en rik baskettradition och utgör en konstant fara i NCAA-turneringar.",
      "Från små högskolor till stora universitet fungerar sport i Illinois som en bindande faktor, en källa till stolthet och en väsentlig del av kulturen och identiteten i delstaten."
    ]
  },
  {
    "name": "Indiana",
    "description": [
      "Indiana är känt för sin djupt rotade kärlek till basket, och detta är uppenbart i delstatens collegelagssportkultur. Rivaliteten, passionen och engagemanget hos både spelare och fans är oöverträffade.",
      "Program som Indiana Hoosiers och Purdue Boilermakers är nationellt erkända, särskilt inom basket. En elektrisk atmosfär hänger i arenor som Assembly Hall och Mackey Arena under hela året, där historia och tradition går hand i hand.",
      "Även om basketen leder vägen trivs också andra sporter i Indiana. Amerikansk fotboll, baseboll och friidrott har alla starka program och njuter av bred samhällssupport.",
      "Sport i Indiana är mer än bara ett spel; det är en livsstil, en katalysator för gemenskapssinne och en stolt tradition som förs vidare genom generationer. Här, i hjärtat av Mellanvästern, är collegelagssport en integrerad del av delstatens identitet."
    ]
  },
  {
    "name": "Iowa",
    "description": [
      "Collegesport i Iowa är en källa till stolthet och gemenskapssinne, med en rik tradition inom både amerikansk fotboll och brottning. Rivaliteten mellan Iowa Hawkeyes och Iowa State Cyclones är central och ger oförglömliga ögonblick varje säsong.",
      "Kinnick Stadium i Iowa City och Jack Trice Stadium i Ames är arenorna för intensiva fotbollsmatcher varje höst, vilket lockar tiotusentals fans. Passionen och hängivenheten hos fansen är karakteristisk för delstatens sportkultur.",
      "Brottning är en annan sport där Iowa utmärker sig, med Hawkeyes som regelbundet vinner nationella titlar. Den här sporten har en djupt rotad tradition i Iowa och njuter av lojal support.",
      "Från små högskolor till stora universitet är sport i Iowa mer än bara tävling; det är en livsstil, en källa till gemenskapssinne och en viktig del av delstatens identitet."
    ]
  },
  {
    "name": "Kansas",
    "description": [
      "Collegesport i Kansas är djupt rotad i tradition och gemenskapssinne, med basket som den oomtvistade höjdpunkten. Delstaten är hem för några av de mest ikoniska programmen i landet, och rivaliteten och passionen är intensiva.",
      "Kansas Jayhawks och Kansas State Wildcats leder an, med deras årliga 'Sunflower Showdown' som en av höjdpunkterna under sportåret. Allen Fieldhouse, hem för Jayhawks, är känt som en av de mest skrämmande arenorna inom collegbasketen.",
      "Även om basketen formar hjärtat av sportkulturen i Kansas spelar amerikansk fotboll, baseboll och friidrott också en betydande roll. Fansen är oöverträffade i sitt engagemang och bidrar till den livliga atmosfären under matcherna.",
      "Sport i Kansas är mer än bara tävling; det är en källa till stolthet, en katalysator för gemenskap och en väsentlig del av delstatens identitet."
    ]
  },
  {
    "name": "Kentucky",
    image: Kentucky,
    "imageDescription": "Karl-Anthony Towns och Devin Booker spelade tillsammans för Kentucky och blev senare betydande NBA-stjärnor",
    "description": [
      "Collegesport i Kentucky är synonymt med basket och präglas av en rik tradition och en passionerad supporterkår. Rivaliteten mellan Kentucky Wildcats och Louisville Cardinals är en av de mest intensiva och mest sedda i landet.",
      "Rupp Arena, hemmaarena för Wildcats, är en sann baskettempel där programmets historia och stolthet är påtaglig. Wildcats har flera nationella mästerskapstitlar och är en fixtur inom collegbasketen.",
      "Förutom basket har Kentucky också starka program inom amerikansk fotboll, ridsport och skytte, vilket berikar delstatens sportkultur. Kentucky Derby, ett legendariskt hästkapplöpning, understryker den rika traditionen inom ridsport.",
      "I Kentucky är sport mer än bara ett tidsfördriv; det är en livsstil, en källa till gemenskap och stolthet samt en avgörande del av delstatens identitet."
    ]
  },
  {
    "name": "Louisiana",
    "description": [
      "I Louisiana är collegesport en integrerad del av kulturen, med en djupt rotad passion för amerikansk fotboll och baseboll. Louisiana State University (LSU) Tigers är en maktfaktor som lockar enorma folkmassor och skapar en unik atmosfär.",
      "Tiger Stadium, även känt som 'Death Valley', är ökänt för sina livliga fans och skrämmande atmosfär, vilket gör det till en av de mest fruktade hemmaarenorna inom collegfotbollen. 'Saturday Night in Death Valley' är mer än bara en match; det är en händelse, en tradition, en del av Louisianas själ.",
      "Förutom LSU bidrar mindre universitet och högskolor också till den rika sportskapen, med starka program inom olika sporter. Baseboll är också mycket populärt, med LSU som regelbundet tävlar om nationella titlar.",
      "Sport i Louisiana är en hyllning till gemenskapen, en källa till stolthet och en bindande kraft som förenar människor, oavsett bakgrund. Det är en livlig och avgörande del av delstatens livsstil och identitet."
    ]
  },
  {
    "name": "Maine",
    "description": [
      "Collegesport i Maine återspeglar delstatens unika natur och känsla för gemenskap, med ett starkt fokus på vintersporter och friidrott. University of Maine Black Bears leder vägen och representerar stolt i olika NCAA-divisioner.",
      "Alfond Stadium i Orono är hjärtat av amerikansk fotboll i delstaten, medan Harold Alfond Sports Arena spelar en central roll under ishockeysäsongen. Black Bears har en stolt tradition inom ishockey, med entusiastiska fans som trotsar kylan för att stödja sitt lag.",
      "Förutom ishockey och fotboll finns det också starka program inom friidrott, rodd och andra sporter som berikar Maines atletiska landskap. Delstaten njuter också av livliga tävlingar inom gymnasie- och klubbidrott, vilket gör sport till en integrerad del av gemenskapen.",
      "Sport i Maine är mer än bara tävling; det är en källa till stolthet, ett sätt att fira delstatens unika anda och en bindande kraft som förenar människor, oavsett säsong."
    ]
  },
  {
    "name": "Maryland",
    "description": [
      "Collegesport i Maryland frodas med en stark tradition inom basket, lacrosse och amerikansk fotboll. University of Maryland Terrapins, eller bara Terps, är centrala för delstatens sportkultur.",
      "Xfinity Center och Maryland Stadium är stolta arenor för basket och fotboll, där Terps spelar sina hemmamatcher. Fansen är hängivna och bidrar till en elektrisk atmosfär under matcherna. Maryland är känt för sitt konkurrenskraftiga basketprogram, med flera deltaganden i NCAA-turneringar.",
      "Lacrosse är en annan sport där Maryland utmärker sig, med Terps som regelbundet tävlar om nationella titlar. Den här sporten har en speciell plats i invånarnas hjärtan, och intensiva collegematcher gör varje säsong till en spektakulär upplevelse.",
      "Sport i Maryland är ett uttryck för gemenskap, stolthet och tradition, och spelar en avgörande roll för att föra människor samman och fira delstatens unika kultur."
    ]
  },

  {
    "name": "Massachusetts",
    "description": [
      "I Massachusetts spelar collegesport en betydande roll i vardagen, med en rik historia inom basket, amerikansk fotboll och ishockey. Universiteten här har starka sportsprogram och passionerade fans.",
      "Basket har en särskild plats i massachusettbornas hjärtan, eftersom spelet uppfanns här. University of Massachusetts Minutemen, även kända som UMass Minutemen, är stolta representanter i NCAA Division I-basketen.",
      "Amerikansk fotboll och ishockey är också populära, med intensiva lokala rivaliteter som ger spännande tävlingar. Harvard-Yale-fotbollsmatchen, känd som 'The Game', är en årlig höjdpunkt.",
      "Sport i Massachusetts handlar om tradition, gemenskap och tävling. Det förenar människor, oavsett bakgrund, och är en väsentlig del av delstatens kulturella identitet."
    ]
  },
  {
    "name": "Michigan",
    image: Michigan,
    "imageDescription": "Michigan Stadium: den tredje största arenan i världen och hem för Michigan Wolverines. Den kan rymma 107 601 fans.",
    "description": [
      "Michigan har en rik tradition inom collegesport, särskilt inom amerikansk fotboll och basket. Rivaliteten mellan University of Michigan Wolverines och Michigan State Spartans är legendarisk och skapar en elektrisk atmosfär över hela delstaten.",
      "Michigan Stadium, även känt som 'The Big House', är en av de största arenorna i världen och rymmer över 100 000 fans. Wolverines har en ärofylld historia, med flera nationella titlar inom fotboll.",
      "Basket är också enormt populärt, med både Michigan och Michigan State som regelbundet tävlar i NCAA-turneringen. Fansens passion och engagemang är enastående, och matchdagar är verkliga händelser i Michigan.",
      "Förutom dessa stora sporter har Michigan också starka program inom ishockey, friidrott och andra sporter. Sport i Michigan är mer än bara ett tidsfördriv; det är en integrerad del av delstatens livsstil och identitet."
    ]
  },
  {
    "name": "Minnesota",
    "description": [
      "Collegesport i Minnesota har en unik karaktär, med ett starkt fokus på ishockey, amerikansk fotboll och basket. University of Minnesota Golden Gophers spelar en central roll i delstatens sportgemenskap.",
      "TCF Bank Stadium och Williams Arena är pulserande centra för aktivitet under fotbolls- och basket säsongerna, medan Mariucci Arena är en fästning för ishockey. Golden Gophers är stolta representanter i NCAA, med en rik historia och hängivna fans.",
      "Ishockey är särskilt populärt i Minnesota, ofta kallat 'The State of Hockey'. Collegelagen njuter av starkt stöd och erbjuder spännande action, särskilt under den berömda 'Frozen Four' NCAA-turneringen.",
      "Sport i Minnesota är djupt förankrat i kulturen och identiteten i delstaten och erbjuder en källa till stolthet, gemenskapssinne och livliga rivaliteter som förenar människor i alla åldrar."
    ]
  },
  {
    "name": "Mississippi",
    "description": [
      "Collegesport är en integrerad del av kulturen i Mississippi, med intensiv passion för amerikansk fotboll, basket och baseboll. Rivaliteten mellan University of Mississippi (Ole Miss) Rebels och Mississippi State Bulldogs är en av de mest intensiva i landet.",
      "Vaught-Hemingway Stadium i Oxford och Davis Wade Stadium i Starkville är verkliga tempel för fotbollsfans, där spänningen och passionen i spelet är påtaglig. Den årliga Egg Bowl, krocken mellan Ole Miss och Mississippi State, är en höjdpunkt i sportkalendern.",
      "Basket och baseboll är också betydande, med båda universiteten som bygger starka lag och traditioner. Fansen är lojala och högljudda och skapar en elektrisk atmosfär på matcherna.",
      "Sport i Mississippi handlar om gemenskap, stolthet och tradition. Det förenar människor, skapar oförglömliga ögonblick och är en väsentlig del av delstatens identitet."
    ]
  },
  {
    "name": "Missouri",
    "description": [
      "Collegesport i Missouri är synonymt med passion, gemenskap och tävling. Delstaten har en rik sportshistoria, med amerikansk fotboll, basket och baseboll i ledningen.",
      "University of Missouri Tigers, också kända som Mizzou, och Missouri State Bears är två framstående representanter inom collegsportlandskapet. Mizzous Faurot Field och Mizzou Arena är epicentra av spänning och laganda under matchdagar.",
      "Tigerna har en stark närvaro i SEC, en av de mest konkurrenskraftiga konferenserna inom collegfotbollen. Deras basketlag är också en fixtur i NCAA-turneringen, med fans som ger obevekligt stöd.",
      "Sport i Missouri stärker gemenskapsbanden och förenar människor. Det handlar inte bara om spelet; det handlar om upplevelsen, traditionerna och det outplånliga bandet mellan lagen och deras supportrar."
    ]
  },
    
  {
    "name": "Montana",
    "description": [
      "I Montana spelar collegesport en avgörande roll i samhällslivet, med amerikansk fotboll, basket och rodeo som populära sporter. Montana Grizzlies och Montana State Bobcats dominerar sportscenen, och deras rivalitet är intensiv.",
      "Grizzlies 'Washington-Grizzly Stadium och Bobcats 'Bobcat Stadium är stolta fotbollsarenor i Montana. Här samlas fansen för att stötta sina lag, oavsett väder. Den årliga Brawl of the Wild-matchen mellan Grizzlies och Bobcats är ett måste-se-evenemang.",
      "Basket är också populärt, med livliga matcher och en stark fanbas. Rodeo, unikt för Montana, attraherar också mycket uppmärksamhet och uppskattning och speglar delstatens kultur och traditioner.",
      "Collegesport i Montana handlar om gemenskap, stolthet och kärlek till spelet. Det förenar människor, skapar livslånga minnen och bidrar till den unika identiteten för Big Sky Country."
    ]
  },
  {
    "name": "Nebraska",
    "description": [
      "I Nebraska präglas collegesport av djup passion och lojalitet, med ett särskilt fokus på amerikansk fotboll. Nebraska Cornhuskers, baserade vid University of Nebraska-Lincoln, är hjärtat av delstatens sportkultur.",
      "Memorial Stadium, hem för Cornhuskers, kallas också 'The Sea of Red', där fans i sin karakteristiska röda klädsel samlas för att stödja sitt lag. Stadion är känd för sitt öronbedövande buller och unika atmosfär på matchdagar.",
      "Förutom fotboll spelar också basket en betydande roll, med både herr- och damlag som tävlar på en hög nivå. Fansen är lika hängivna och skapar en livlig atmosfär vid varje match.",
      "Collegesport i Nebraska sträcker sig bortom bara sporten; det är ett sätt att föra samhällen samman, fira traditioner och skapa en känsla av stolthet och samhörighet i 'The Cornhusker State'."
    ]
  },
  {
    "name": "Nevada",
    "description": [
      "Collegesport i Nevada erbjuder en unik blandning av underhållning och tävling, med särskild uppmärksamhet på basket och amerikansk fotboll. University of Nevada, Las Vegas (UNLV), och University of Nevada, Reno är de två främsta institutionerna inom sport.",
      "UNLV:s basketlag, Runnin 'Rebels, har en rik historia och är kända nationellt. Deras hemmamatcher är en sann attraktion, komplett med energifyllda framträdanden och en entusiastisk publik. Nevada Wolf Packs fotbollslag bidrar också till delstatens sportskultur, med spännande matcher och en lojal fanbas.",
      "I Nevada finns det också ett levande intresse för andra collegesporter som baseboll, softboll och fotboll. Dessa sporter lockar lokala samhällen och ger gott om möjligheter för idrottare att utvecklas.",
      "Sammanfattningsvis är collegesport i Nevada en väsentlig del av delstaten, som inte bara ger underhållning utan också förenar samhällen och berikar den lokala kulturen."
    ]
  },
  {
    "name": "New Hampshire",
    "description": [
      "I den pittoreska delstaten New Hampshire spelar collegesport en avgörande roll i samhällslivet. Trots att delstaten är mindre än många andra är engagemanget för sport enormt.",
      "University of New Hampshire (UNH) dominerar sportskänseln, särskilt inom ishockey, där Wildcats konsekvent presterar på en hög nivå. Whittemore Center Arena, hemmaarenan för hockeylaget, är känd för sin livliga atmosfär och passionerade fans.",
      "Fotboll och basket är också populära sporter inom UNH och lockar stora folkmassor under säsongen. Samhället är stolta över sina lag, och studenter är nära involverade i sportevenemangen.",
      "New Hampshires syn på collegesport är samhällsinriktad, med syfte att förena människor, fira framgångar och skapa oförglömliga ögonblick i delstatens pittoreska omgivningar."
    ]
  },
  {
    "name": "New Jersey",
    "description": [
      "Collegesport i New Jersey njuter av en livlig och konkurrenskraftig atmosfär, med olika universitet som tävlar på en hög nivå. Delstaten är värd för några ledande program som är erkända både regionalt och nationellt.",
      "Ett av de mest framträdande universitetslagen är Rutgers Scarlet Knights, en del av den prestigefyllda Big Ten Conference. Deras fotbolls- och basketmatcher drar stora folkmassor och genererar spänning över hela delstaten.",
      "New Jersey är också känt för sina starka lacrosse- och fotbollsprogram, vilket lockar och utvecklar ungtalanger. Dessa sporter spelar en betydande roll i delstatens collegesportkultur, med spännande tävlingar och engagerade fans.",
      "Gemenskapen i New Jersey omfamnar sina collegesportlag, vilket resulterar i ett stödjande och passionerat atmosfär vid varje evenemang. Collegesport i New Jersey handlar inte bara om matcher; det är en väsentlig del av delstatens kulturella tyg."
    ]
  },
    
    
  {
    "name": "New Mexico",
    "description": [
      "New Mexico, med sin unika kulturella och geografiska bakgrund, har ett speciellt förhållande till collegidrott. Staten är hem för några framstående universitetsidrottsprogram som tävlar på nationell nivå.",
      "University of New Mexico Lobos i Albuquerque är en central stolthetspunkt. Deras basketlag drar regelbundet stora folkmassor till The Pit, en av de mest skrämmande arenorna i landet.",
      "New Mexico State Aggies från Las Cruces är också nationella konkurrenter, särskilt inom basket och amerikansk fotboll, där de har häftiga rivaliteter med Lobos, vilket kulminerar i Rio Grande Rivalry.",
      "Med stöd av en rik historia och en passionerad fanbas ger collegidrott i New Mexico inte bara underhållning utan främjar också gemenskapssinne och delstatsidentitet. I denna ökenstat är sport en hyllning till talang, uthållighet och gemenskapsband."
    ]
  },
  {
    "name": "New York",
    "description": [
      "I den livliga staten New York spelar collegidrott en dynamisk och mångsidig roll. Staten är hem för många universitet, var och en med sina egna unika idrottstraditioner.",
      "Syracuse University sticker ut med sitt basketlag, Orange, som ofta tävlar på högsta nationella nivå. Carrier Dome-stadion, känd för sin imponerande kapacitet och livliga atmosfär, är en ikon inom collegidrottsvärlden.",
      "Dessutom spelar mindre universitet och högskolor också en betydande roll, med starka program inom idrotter som lacrosse, fotboll och baseboll. Dessa mindre program närs av tävlingssinnet i staten och skapar en rik och mångsidig idrottslandskap.",
      "Gemenskapsengagemanget i collegidrott i New York är enormt, med fans som stöder sina lag genom tunna och tjocka. Från livliga städer till lugnare landsbygdsområden förenar sporten New Yorkbor och infuserar en känsla av stolthet och samhörighet."
    ]
  },
  {
    "name": "North Carolina",
    image: North_Carolina,
    "imageDescription": "Den ikoniska rivaliteten: DUKE vs. UNC i aktion",
    "description": [
      "North Carolina har en rik tradition och enastående passion när det gäller collegidrott, särskilt inom basket. Rivaliteten mellan University of North Carolina Tar Heels och Duke University Blue Devils är världsberömd och garanterar spännande möten.",
      "Tar Heels och Blue Devils dominerar inte bara basket­säsongen utan sprider också en våg av spänning och gemenskapssinne över hela staten. Det är en sportkultur djupt rotad i North Carolinas identitet.",
      "Förutom basket spelar amerikansk fotboll också en framträdande roll, med starka lag och passionerade supportrar. Och på våren blomstrar kärleken till baseboll, med både universitets- och collegeteam som tävlar på fälten.",
      "Collegidrott i North Carolina går bortom idrottsarenorna och salarna; det är en livsstil, en källa till stolthet och ett sätt för samhällen att komma samman och fira sin delstat."
    ]
  },
  {
    "name": "North Dakota",
    "description": [
      "Collegidrott i North Dakota erbjuder en unik upplevelse, där samhällen är nära involverade med sina universitetslag. Känslan av stolthet och samhörighet är tydligt synlig under sportevenemang, trots att staten har färre framstående universitet än andra amerikanska regioner.",
      "Det amerikanska fotbollslaget från North Dakota State University, Bison, är känt för sina framgångar i NCAA Division I Football Championship Subdivision, vilket ger ett betydande bidrag till statens idrottskultur. Fans samlas vid Fargodome för att stödja sitt lag och skapa en intensiv atmosfär.",
      "Ishockey spelar också en stor roll i North Dakota, med University of North Dakota Fighting Hawks som ett ledande lag. Passionen för denna vintersport är tydlig under säsongen, när fansen flockas till arenan.",
      "Collegidrott i North Dakota, särskilt amerikansk fotboll och ishockey, förenar samhällen, skapar stark lokal stolthet och bidrar till den livliga idrottskulturen i staten."
    ]
  },
  {
    "name": "Ohio",
    "description": [
      "Collegidrott är en integrerad del av kulturen i Ohio, med särskild tonvikt på amerikansk fotboll och basket. Ohio State University (OSU) och University of Cincinnati är två ledande institutioner inom idrott.",
      "OSUs fotbollslag, Buckeyes, njuter av nationellt berömmelse och är en verklig kraft inom NCAA. Deras matcher på den massiva Ohio Stadium lockar mer än 100 000 åskådare och skapar en unik atmosfär under hemmamatcherna. 'O-H-I-O' är en sång som är känd över hela staten.",
      "Basket är också framträdande, med både herr- och damlag som tävlar på hög nivå. Ohio är hem för flera universitetslag som regelbundet deltar i NCAA-turneringen och bidrar till basketfeber.",
      "Collegidrott i Ohio erbjuder mer än bara tävling; det är en källa till stolthet, tradition och gemenskapssinne som förenar människor från alla samhällsskikt."
    ]
  },
  {
    "name": "Oklahoma",
    "description": [
      "I Oklahoma har collegidrott en central plats i invånarnas hjärtan, med en djupt rotad passion för amerikansk fotboll. University of Oklahoma (OU) och Oklahoma State University (OSU) är statens juveler inom idrottsvärlden.",
      "OUS fotbollslag, Sooners, har en rik historia och många framgångar, vilket skapar en livlig atmosfär kring deras matcher. Gaylord Family Oklahoma Memorial Stadium är en fästning för laget, där fansen högljutt sjunger 'Boomer Sooner'.",
      "OSU är inte långt efter OU, med starka prestationer både inom fotboll och andra sporter, där deras amerikanska fotbollslag, Cowboys, spelar en betydande roll. Dedikationen från fansen under 'Bedlam Series', den årliga striden mellan OU och OSU, är enorm.",
      "Collegidrott i Oklahoma symboliserar kampen, uthålligheten och känslan av gemenskap i staten, vilket gör det mycket mer än bara ett spel."
    ]
  },
  {
    "name": "Oregon",
    "description": [
      "Collegidrott i Oregon är en väsentlig del av staten, med fokus på både amerikansk fotboll och friidrott. University of Oregon (UO) i Eugene är central för denna idrottsliga passion och har byggt upp ett nationellt rykte.",
      "UOs fotbollslag, Ducks, är kända för sin snabba spelstil och unika uniformer, medan friidrottsprogrammet är ett av de bästa i landet. Den årliga friidrottsevenemanget Prefontaine Classic, som hålls på den berömda Hayward Field, lyfter fram Oregons dedikation till friidrott.",
      "Oregon State University (OSU) med deras fotbollslag, Beavers, ger stark konkurrens, särskilt under 'Civil War', den årliga sammandrabbningen med Ducks. Detta är mer än ett spel; det är en delstatstradition som förenar samhällen.",
      "Förutom dessa idrotter njuter basket också av popularitet, och passionerade fans gör collegidrott i Oregon till en upplevelse du inte vill missa. Det är en hyllning till idrottslig talang, gemenskap och statens unika idrottskultur."
    ]
  },
  {
    "name": "Pennsylvania",
    "description": [
      "Pennsylvania har en djup uppskattning för collegidrott, med ett starkt fokus på amerikansk fotboll, basket och friidrott. Lag som Penn State Nittany Lions och Pittsburgh Panthers dominerar idrottslandskapet och skapar spännande rivaliteter.",
      "Beaver Stadium, hem för Nittany Lions, är känt för sin överväldigande atmosfär och intensiva fanupplevelse, särskilt under 'White Out'-matcher. Dessa ögonblick är höjdpunkten på idrottskalendern och lockar fans från hela staten.",
      "På basketplanen har Villanova Wildcats nyligen uppnått nationell framgång, med flera mästerskap som har glatt staten. Deras prestationer har satt Pennsylvania på kartan som en basketmakt.",
      "Friidrott spelar också en avgörande roll, med det prestigefyllda Penn Relays som lockar toppidrottare till Philadelphia varje år. Denna rika idrottskultur stärker gemenskapens anda och bidrar till stoltheten över Pennsylvania."
    ]
  },
  {
    "name": "Rhode Island",
    "description": [
      "Trots sin lilla storlek har Rhode Island en livlig collegidrottsscen, med basket och segling som särskilt populära. Staten har lag som spelar med hjärta och själ och skapar spännande ögonblick.",
      "Lag som Rhode Island Rams och Providence Friars har en lojal fanbas och levererar fängslande matcher. Dunkin' Donuts Center i Providence blir en livlig arena under basketmatcher, med lokalbefolkningen som strömmar till för att stödja sina lag.",
      "Förutom basket är Rhode Island känt för sina utmärkta seglingsprogram, med den vackra kusten som ger perfekta förhållanden för sporten. Universitet som Brown och URI har starka seglingslag som tävlar nationellt.",
      "Dessa idrotter bidrar till Rhode Islands livliga kultur, där varje match är en möjlighet att fira delstatens unika anda."
    ]
  },
  {
    "name": "South Carolina",
    "description": [
      "Collegidrott i South Carolina andas tradition och passion, med amerikansk fotboll och basket i centrum för uppmärksamheten. Den intensiva rivaliteten mellan University of South Carolina Gamecocks och Clemson University Tigers dominerar idrottslandskapet.",
      "Under den årliga fotbollsshowdownen, 'The Palmetto Bowl', kommer hela staten till liv, med linjen mellan garnet och orange tydligt dragen. Fansens energi och passion är oöverträffad, vilket gör varje match till en oförglömlig upplevelse.",
      "Basket har också en framträdande plats i hjärtat av sportfans i South Carolina, med lag som tävlar i toppen både inom herr- och damligorna. Till exempel har Gamecocks damlag i basket uppnått nationell framgång, vilket ytterligare förstärker gemenskapens stolthet och engagemang.",
      "Idrottskulturen i South Carolina går bortom spelplanerna; det är en livsstil, en källa till stolthet och en oumbärlig del av delstatens identitet."
    ]
  },

  {
    "name": "South Dakota",
    "description": [
      "Collegesport i South Dakota erbjuder en unik och passionerad upplevelse, med tonvikt på basket, amerikansk fotboll och friidrott. South Dakota State University Jackrabbits och University of South Dakota Coyotes är framträdande aktörer inom idrottslandskapet.",
      "Basketbollmatcher, särskilt under rivalmötena mellan Jackrabbits och Coyotes, lockar entusiastiska folkmassor och skapar en elektrifierande atmosfär. Staten hejar på både herr- och damlag, där on-field-prestationer är en källa till stolthet för lokala samhällen.",
      "Amerikansk fotboll njuter också av stor popularitet, med spännande matcher och starkt engagemang från fansen. Idrottsprogrammen i South Dakota utmärker sig, med idrottare som tävlar nationellt och stolt representerar staten.",
      "Collegesport i South Dakota är mer än bara spel; det är en väsentlig del av samhället, som stärker banden mellan invånarna och bidrar till statens livliga kultur."
    ]
  },
  {
    "name": "Tennessee",
    "description": [
      "I Tennessee spelar collegesport en avgörande roll i samhället, med en rik tradition inom amerikansk fotboll, basket och friidrott. University of Tennessee Volunteers och Vanderbilt University Commodores är ledande lag inom statens idrottslandskap.",
      "Volunteers, med sin ikoniska orange färg, lockar enorma folkmassor till Neyland Stadium, en av de största amerikanska fotbollsstadionerna i landet. Fansens passion är oöverträffad, och 'Rocky Top' ekar högt under varje match.",
      "Basket är också en stor attraktion i Tennessee, med både herr- och damlag som får nationell uppmärksamhet. Staten har en rik historia av att producera toppidrottare och har bidragit till sportens tillväxt på nationell nivå.",
      "Collegesport i Tennessee är en väsentlig del av statens kultur och identitet, som samlar människor, firar traditioner och inspirerar nästa generation av idrottare."
    ]
  },
  {
    "name": "Texas",
    image: Texas,
    "imageDescription": "Traditionell rivalitet: Texas Longhorns möter Texas A&M",
    "description": [
      "Collegesport i Texas är synonymt med storlek, passion och rik tradition, med amerikansk fotboll i spetsen. Lag som Texas Longhorns och Texas A&M Aggies har massiva följarskaror, och deras matcher är säsongens höjdpunkter.",
      "Darrell K Royal–Texas Memorial Stadium i Austin och Kyle Field i College Station är idrottstempel där varje hemmamatch förvandlas till ett spektakulärt evenemang. Rivaliteten mellan olika universitetslag är intensiv och bidrar till statens unika idrottskultur.",
      "Förutom fotboll är basket och baseboll också populära, med starka program som tävlar nationellt. Stödet för damidrott växer stadigt, med basket och friidrott som särskilt framträdande.",
      "Collegesport i Texas är mer än bara en fritidssysselsättning; det är en livsstil, en källa till stolthet och en bindande kraft som samlar samhällen."
    ]
  },
  {
    "name": "Utah",
    "description": [
      "I Utah har collegesport en betydande plats inom samhället, med basket och amerikansk fotboll som de mest populära sporterna. University of Utah Utes och Brigham Young University Cougars engagerar sig i intensiv konkurrens inom olika discipliner.",
      "Den årliga fotbollsduellen mellan Utes och Cougars, känd som 'Holy War', är en av de mest efterlängtade sportevenemangen i staten. Den samlar fans för en fest av sportsmannaanda, rivalitet och statlig ära.",
      "Basket har också en rik tradition i Utah, med spännande säsonger och minnesvärda ögonblick både inom herr- och damligorna. Lagen strävar efter excellens, och fansens stöd är obrottsligt.",
      "Effekten av collegesport i Utah går bortom spelplanen. Den främjar samhällsanda, skolstolthet och bidrar avsevärt till delstatens kulturella identitet."
    ]
  },
  {
    "name": "Vermont",
    "description": [
      "I Vermont, där naturen dominerar och samhällena är sammanflätade, spelar collegesport en unik roll för att föra människor samman. Vintersporter som skidåkning och snowboardåkning är framträdande, med tanke på det bergiga terrängen och det kalla klimatet.",
      "University of Vermont Catamounts är mittpunkten för collegesportsscen, med lag som utmärker sig inom olika sporter, inklusive ishockey och basket. Catamounts har en lojal följarskara, och atmosfären på hemmamatcherna är intensiv.",
      "Basket njuter av stor popularitet, och Catamounts matcher är ett måste för idrottsentusiaster. Laget har deltagit i NCAA-turneringen flera gånger och har satt staten på kartan inom collegbasketens värld.",
      "Collegesport i Vermont är mer än bara ett spel; det är ett sätt att föra samhällen samman, utveckla talanger och fira statens unika idrottskultur."
    ]
  },
  {
    "name": "Virginia",
    "description": [
      "I Virginia är collegesport en integrerad del av den lokala kulturen, med ett starkt fokus på amerikansk fotboll, basket och den växande populariteten för fotboll. Virginia Cavaliers och Virginia Tech Hokies är ledande universitetslag som för med sig passion och rivalitet till planen.",
      "Amerikansk fotboll lockar folkmassor till arenorna, där atmosfären under matchdagar är elektrisk. Investeringar i basketprogram har också gett resultat, med lag som tävlar nationellt och har en hängiven följarskara.",
      "Fotboll vinner terräng i Virginia, med både herr- och damlag som utvecklas till konkurrenskraftiga krafter. Universitet investerar i faciliteter och träning, medan lokala akademier producerar unga talanger.",
      "Denna mix av traditionella och nya sporter berikar collegesportscenen i Virginia, skapar en livlig idrottsgemenskap och ger idrottare och fans oförglömliga upplevelser samt en känsla av stolthet och enhet."
    ]
  },
  {
    "name": "Washington",
    "description": [
      "Collegesport i Washington frodas med en blandning av tradition och entusiasm, med amerikansk fotboll, basket och rodd i centrum. University of Washington Huskies och Washington State University Cougars är pionjärer inom delstatens idrottskultur.",
      "Husky Stadium i Seattle och Martin Stadium i Pullman surrar av spänning under amerikansk fotboll, där fans från hela området samlas för att stödja sina lag. 'Apple Cup', den årliga sammanstötningen mellan Huskies och Cougars, är en höjdpunkt i rivaliteten.",
      "Basket spelar också en betydande roll, med både herr- och damlag som tävlar på hög nivå. Huskies har en stark arv inom denna sport och attraherar passionerade supportrar.",
      "Rodd är en annan betydande disciplin, med Huskies som vinner talrika nationella mästerskap. Collegesport i Washington speglar delstatens mångfald och passion, där sportsmannaanda, tävling och kamratskap värderas högt."
    ]
  },
  {
    "name": "West Virginia",
    "description": [
      "Collegesport är hjärtat i West Virginia, med ett starkt fokus på amerikansk fotboll och basket. West Virginia University Mountaineers är stolta representanter för delstaten, och deras matcher samlar samhället.",
      "Milan Puskar Stadium i Morgantown kommer till liv under Mountaineers' hemmamatcher, med fans som bär de ikoniska guld- och blå färgerna och hejar högt. 'Backyard Brawl', en historisk rivalitet med University of Pittsburgh, lägger till extra spänning till säsongen.",
      "Basket är en annan passion, med spännande matcher som håller fansen på helspänn. Mountaineers har både herr- och damlag som tävlar på nationell nivå.",
      "Collegesport i West Virginia är en källa till stolthet och enhet, vilket återspeglar den starka känslan av samhörighet och traditioner i delstaten."
    ]
  },
  {
    "name": "Wisconsin",
    "description": [
      "Collegesport är djupt rotad i kulturen i västra Wisconsin, med amerikansk fotboll, basket och ishockey som framträdande discipliner. University of Wisconsin Badgers är den drivande kraften bakom idrottskulturen i delstaten.",
      "Camp Randall Stadium i Madison surrar av spänning under amerikansk fotboll, med fans som samlas för att sjunga 'Jump Around' och heja på sitt lag. Rivaliteten med lag som University of Michigan Wolverines är legendarisk och leder till intensiva matcher.",
      "Basket har också en rik tradition, med Badgers som regelbundet tävlar om de bästa platserna i NCAA-turneringar. Ishockey har en särskild plats i delstatens hjärta, med spännande matcher som lyser upp vinterkvällarna.",
      "Collegesport i västra Wisconsin går bortom spelplanen; det är en källa till stolthet, kamratskap och stärker banden mellan samhällena i delstaten."
    ]
  },
  {
    "name": "Wyoming",
    "description": [
      "Collegesport i Wyoming har en unik karaktär, med ett starkt fokus på utomhussporter som skidåkning, snowboardåkning och bergsklättring. University of Wyoming Cowboys och Cowgirls är frontfigurerna för denna äventyrliga idrottskultur.",
      "War Memorial Stadium i Laramie kommer till liv under amerikansk fotboll, där fansen samlas för att visa sitt stöd för Cowboys. De hisnande bergen fungerar som en bakgrund för dessa sportevenemang.",
      "Universitetet har också en stark tradition inom skidåkning och snowboardåkning, med idrottare som dominerar nationella och internationella tävlingar. Wyomings stora vildmark fungerar som en lekplats för äventyrare och friluftsentusiaster.",
      "Collegesport i Wyoming firar naturens skönhet och delstatens äventyrliga anda, där sportsmannaanda, passion och kärlek till utomhusaktiviteter kommer samman."
    ]
  }



];

export default states;