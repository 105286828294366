/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


// @mui material components
import React, { useState } from 'react'; // Step 1: Import useState


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Logos/NJCAA.png";
import post3 from "assets/images/Logos/NAIA.png";



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {

  const articleStructure = [
    { id: 'intro', title: 'NCAA: Das Herz des College-Sports in Amerika', isSubheader: false },
  
    { id: 'Kapitel 1', title: 'Die Wurzeln und das Wachstum der NCAA', isSubheader: true },
    { id: '1.1', title: 'Die drei Gesichter der NCAA: Divisionen I, II und III', isSubheader: true },
    { id: '1.2', title: 'Die Rolle der Akademiker', isSubheader: true },
  
    { id: 'Kapitel 2', title: 'Finanzen, Medien und Marketing', isSubheader: true },
    { id: '2.1', title: 'Herausforderungen und Zukunft', isSubheader: true },
    { id: '2.2', title: 'Abschließend', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
     <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhaltsverzeichnis
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verstecken</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Anzeigen</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="intro" mb={3}>


              
<MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>NCAA: Das Herz des College-Sports in Amerika</MKTypography>
</MKBox>
<MKBox id="Kapitel 1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Die Wurzeln und das Wachstum der NCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
Die National Collegiate Athletic Association, besser bekannt als die NCAA, ist seit über einem Jahrhundert das Zentrum der amerikanischen Collegiate-Sportlandschaft. Gegründet im Jahr 1906 hat sich diese Organisation von einer kleinen, regulierenden Einheit zu einer mächtigen und einflussreichen Organisation entwickelt, die das Leben von Tausenden von Studenten-Athleten, Trainern und Sportbegeisterten berührt.
<br />
<br />
In den frühen Jahren der NCAA lag der Schwerpunkt hauptsächlich auf der Festlegung von Regeln und der Sicherstellung der Sicherheit im Sport. Im Laufe der Zeit erweiterte sich jedoch die Rolle der NCAA, wobei sie immer mehr damit befasst war, alle Aspekte des Collegiate-Sports zu regulieren, von akademischen Anforderungen bis hin zu finanziellen Fragen.
</MKTypography>

<MKBox id="1.1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Die drei Gesichter der NCAA: Divisionen I, II und III
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
Die NCAA ist in drei Divisionen unterteilt, jede mit ihrem eigenen einzigartigen Charakter und Wettbewerbsniveau:
<br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division I:{' '}
</MKTypography>
steht als Höhepunkt des Collegiate-Sports, wo Schulen mit den größten Budgets, den besten Einrichtungen und den talentiertesten Athleten zusammenkommen. Die Universitäten in dieser Division sind oft groß und verfügen über erhebliche Ressourcen. Sie bieten Voll- und Teilstipendien für Athletik an und ziehen Spitzenathleten aus dem ganzen Land und der Welt an. Der Wettbewerb ist intensiv, und der Druck, zu performen, ist hoch. Dennoch ist es auch eine Bühne, auf der Stars geboren werden und wo die Träume junger Athleten Wirklichkeit werden können. Die Division ist in drei Kategorien unterteilt: Football Bowl Subdivision (FBS), Football Championship Subdivision (FCS) und Schulen ohne Football auf Division-I-Niveau.  <br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division II:{' '}
</MKTypography>
repräsentiert einen ausgewogeneren Ansatz zum Collegiate-Sport, bei dem Athletik, akademische Leistungen und Campusengagement Hand in Hand gehen. Die Universitäten in dieser Division sind oft kleiner als die in Division I, bieten aber immer noch ein hohes Maß an Wettbewerb. Athleten in Division II können Unterstützung sowohl auf dem Spielfeld als auch im Klassenzimmer erwarten, um ein vollständiges College-Erlebnis zu bieten.  <br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division III:{' '}
</MKTypography>
legt schließlich den Schwerpunkt auf die ganzheitliche Entwicklung des Studenten-Athleten. Sport ist wichtig, aber nur ein Teil des breiteren College-Erlebnisses. In Division III werden keine Athletikstipendien angeboten, aber Athleten haben die Möglichkeit, an wettbewerbsfähigen Sportarten teilzunehmen, während sie auch vollständig in das akademische und soziale Leben auf dem Campus integriert sind.</MKTypography>

<MKBox id="1.2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Die Rolle der Akademiker
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
Die NCAA nimmt die akademischen Leistungen ihrer Studenten-Athleten sehr ernst. Alle Athleten müssen strenge akademische Anforderungen erfüllen, um für den Wettkampf zugelassen zu werden, und die Universitäten selbst sind dafür verantwortlich, die notwendige Unterstützung anzubieten, um sicherzustellen, dass die Athleten im Klassenzimmer erfolgreich sind. Dazu gehören akademische Berater, Tutoren und spezielle Studienprogramme, die alle darauf abzielen, den Studenten-Athleten zu helfen, die Balance zwischen ihren sportlichen und akademischen Verpflichtungen zu halten.                <br />
<br />
</MKTypography>
         





                                  


<MKBox id="Kapitel 2" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Finanzen, Medien und Marketing
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              Die NCAA ist auch ein finanzielles Schwergewicht, mit Milliarden von Dollar, die jährlich durch die Organisation fließen, dank Fernsehverträgen, Sponsoren und Merchandising. Diese Einnahmen werden verwendet, um Stipendien zu finanzieren, Einrichtungen zu verbessern und die allgemeine Sporterfahrung für Studentenathleten zu verbessern. Gleichzeitig hat die Popularität des Universitätssports zu lukrativen Marketing- und Medienverträgen geführt, was dazu geführt hat, dass der Universitätssport ein fester Bestandteil der amerikanischen Sportkultur ist.

<br />
                </MKTypography>

                <MKBox id="2.1" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Herausforderungen und Zukunft
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              Trotz ihres Erfolgs steht die NCAA auch vor erheblichen Herausforderungen. Themen wie der Amateurstatus von Studentenathleten, die geistige Gesundheit von Athleten und das Streben nach Gleichheit im Sport sind nur einige der Themen, die die Organisation in ihrem Streben nach einer besseren Zukunft für den Universitätssport angehen muss.
<br />
<br />
Die NCAA bleibt ein entscheidender Akteur im amerikanischen Bildungssystem, und ihr Einfluss auf das Leben junger Athleten und die breitere Sportwelt ist unbestreitbar. Während sie in die Zukunft blickt, ist klar, dass die Organisation eine Schlüsselrolle bei der Gestaltung der universitären Sportlandschaft in Amerika spielen wird.


                </MKTypography>

                <MKBox id="2.2" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Abschließend
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              Die National Collegiate Athletic Association ist mehr als nur eine Sportorganisation; sie ist ein entscheidender Bestandteil des amerikanischen Bildungs- und Sportumfelds. Mit ihrer reichen Geschichte, ihrer komplexen Struktur und ihrem tiefen Einfluss auf das Leben unzähliger Individuen bleibt die NCAA ein faszinierender und vitaler Bestandteil der amerikanischen Kultur. Ihre Zukunft wird zweifellos mit Herausforderungen und Chancen gefüllt sein, aber eines ist sicher: Die NCAA wird weiterhin nach Exzellenz streben, sowohl auf dem Spielfeld als auch im Klassenzimmer.




<br />


                </MKTypography>





              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="NJCAA"
                    description="NJCAA: Die Sprungbrett zu Großen Colleges und der NCAA"
                    action={{
                      type: "internal",
                      route: "/de/NJCAA",
                      color: "info",
                      label: "Mehr Lesen",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="NAIA"
                    description="Die NAIA: Ein Alternativer Weg im College-Sport in Amerika"
                    action={{
                      type: "internal",
                      route: "/de/NAIA",
                      color: "info",
                      label: "Mehr Lesen",
                    }}
                    />
                  </Grid>
                </Grid>
  
              </Grid>
          </Grid>
        </Container>
      </MKBox>
    );
  }
  
  export default Beschrijving;