import { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import SimplePricingCard from "examples/Cards/PricingCards/SimplePricingCard";
import SimplePricingCardRibbon from "examples/Cards/PricingCards/SimplePricingCardRibbon";
import { PopupButton } from "react-calendly";
import { Link } from 'react-scroll';
import TextField from "@mui/material/TextField"; // Legg til denne linjen
import Box from '@mui/material/Box'; // Sørg for å importere Box
import emailjs from 'emailjs-com';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Link as RouterLink } from 'react-router-dom';

function Tjenester() {
  const [aktivFane, setAktivFane] = useState(0);
  const [faneType, setFaneType] = useState("månedlig");
  const [visModal, setVisModal] = useState(false);
  const [visEpostModal, setVisEpostModal] = useState(false);
  const [epost, setEpost] = useState("");
  const [erEnig, setErEnig] = useState(false);

  const byttModal = () => setVisModal(!visModal);

  const håndterFaneType = ({ currentTarget }, nyVerdi) => {
    setAktivFane(nyVerdi);
    setFaneType(currentTarget.id);
  };

  const håndterEpostInnsending = async () => {
    if (epost && erEnig) {
      const epostParametere = {
        from_email: epost,
        // andre parametere hvis nødvendig
      };
  
      emailjs.send('service_sj82src', 'template_2eyxhno', epostParametere, 'xOBnOFlYt964jvT3q')
        .then((respons) => {
          console.log('Epost sendt vellykket', respons.status, respons.text);
          alert('Lenke forespurt, sjekk innboksen din.');
          setVisEpostModal(false);
          setEpost("");
          setErEnig(false);
        }, (feil) => {
          console.log('Feilet å sende epost', feil);
          alert('Forespørsel mislyktes, prøv igjen.');
        });
    } else if (!erEnig) {
      alert('Vennligst godta personvernerklæringen.');
    } else {
      alert('Vennligst skriv inn en e-postadresse');
    }
  };

  return (
    <section id="tjenesterSeksjon">
      <Container sx={{ pb: { xs: 4, lg: 4 }, pt: 12 }}>
          {/* Epost Inndata Modal */}
          <Modal
        open={visEpostModal}
        onClose={() => setVisEpostModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: { xs: '90%', sm: '70%', md: '50%', lg: '40%' },
            maxWidth: '500px',
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <TextField
            label="E-postadresse"
            variant="outlined"
            fullWidth
            value={epost}
            onChange={(e) => setEpost(e.target.value)}
            sx={{ mb: 2 }}
          />
          {/* Avmerkingsboks for samtykke til personvernregler */}
          <FormControlLabel
  control={<Checkbox checked={erEnig} onChange={(e) => setErEnig(e.target.checked)} />}
  label={
    <span>
       Jeg har lest og godtar{' '}
      <RouterLink 
        to="/Privacy-Policy" 
        component="a" 
        target="_blank" 
        style={{ textDecoration: 'none' }}>
         personvernerklæringen
      </RouterLink>.
    </span>
  }
  sx={{ my: 2 }}
/>


          <MKButton variant="gradient" color="dark" onClick={håndterEpostInnsending}>
            Send
          </MKButton>
        </Box>
      </Modal>


      <Grid container item xs={12} justifyContent="center" md={8} mx="auto" textAlign="center">
      <Grid item xs={12}> {/* Full bredde for overskriften */}
    <MKTypography variant="h3" color="text">Tjenester</MKTypography>
  </Grid>
  <Grid item xs={12}> {/* Full bredde for brødteksten */}
    <MKTypography variant="body2" color="text">
    Alle tjenester tilbudt av Sport Scholarships America
</MKTypography>
  </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={10}
          lg={8}
          justifyContent="center"
          textAlign="center"
          mx="auto"
          mt={6}
        >
          <AppBar position="static">
          <Tabs value={aktivFane} onChange={håndterFaneType}>
  <Tab
    id="månedlig"
    label={
      <Link 
        to="tjenesterSeksjon" 
        spy={true}
        smooth={true}
        duration={500}
        offset={-70}
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <MKBox py={0.5} px={2} color="inherit">
          Tjenester
        </MKBox>
      </Link>
    }
  />
  <Tab
    id="årlig"
    label={
      <Link 
        to="produkterSeksjon" 
        spy={true}
        smooth={true}
        duration={500}
        offset={-70}
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <MKBox py={0.5} px={2} color="inherit">
          Produkter
        </MKBox>
      </Link>
    }
  />
</Tabs>
          </AppBar>
        </Grid>
        <Grid container spacing={3} mt={6}>
        <Modal open={visModal} onClose={byttModal} sx={{ display: "grid", placeItems: "center" }}>
          <Slide direction="down" in={visModal} timeout={500}>
            <MKBox
              position="relative"
              width="500px"
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              bgColor="white"
              shadow="xl"
            >
              <MKBox display="flex" alginItems="center" justifyContent="space-between" p={2}>
                <MKTypography variant="h5">
Velg en inntakssamtale
</MKTypography>
                <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={byttModal} />
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox p={2}>
                <MKTypography variant="body2" color="secondary" fontWeight="regular">
                Kjære kunde,
                <br />
                  <br />

                  Jeg er glad for at du er interessert i min standardtjeneste! For å sikre at tjenesten min passer perfekt til dine spesifikke behov, anbefaler jeg på det sterkeste å først bestille en uforpliktende inntakssamtale.
                  <br />
                  <br />
                  I løpet av denne samtalen kan vi diskutere situasjonen din i detalj og sammen bestemme hvilken tilnærming som passer best for deg. Dette gir en mer personlig opplevelse og hjelper meg med å optimalisere tjenesten for din unike situasjon.


                </MKTypography>
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox display="flex" justifyContent="space-between" p={1.5}>
                <MKButton variant="gradient" color="dark" onClick={byttModal}>
                  lukk
                </MKButton>
                
                <PopupButton
    url="https://calendly.com/kay-duit"
    rootElement={document.getElementById("root")}
    text="BESTILL AVTALE"
    className="custom-calendly-button"
  />
              </MKBox>
            </MKBox>
          </Slide>
        </Modal>
        <Grid item xs={12} sm={6} lg={3}>
  <SimplePricingCard
    color="dark"
    title="Informasjonsguide"
    description="Fullstendig informasjonsguide i PDF"
    price={{ value: "Gratis", type: "" }}
    action={{
      type: "downloadPDF",
      label: "Last ned PDF",
      onClick: () => setVisEpostModal(true) // Funksjon for å vise inndatamodal for e-post
    }}
    specifications={["Fullstendig informasjonsguide i PDF"]}
  />
</Grid>
<Grid item xs={12} sm={6} lg={3}>
  <SimplePricingCardRibbon
    color="dark"
    title="Inntakssamtale"
    description="60-minutters nettbasert konsultasjon"
    price={{ value: " € 14.99*", type: "" }}
    action={{
      type: "external", // Endre til external for Calendly
      route: "https://calendly.com/kay-duit", // Din Calendly-lenke
      label: "PLANLEGG AVTALE"
    }}
    specifications={[
      "Tilpasse mål og ambisjoner",
      "Vurdering av idrettsprestasjoner",
      "Akademisk integreringsplan",
      "Individuell karriereplanlegging",
      "Analyse av suksessmuligheter i USA"
    ]}
  />
</Grid>

          <Grid item xs={12} sm={6} lg={3}>
  <SimplePricingCard
    color="dark"
    title="Standard"
    description="Ditt vei til USA"
    price={{ value: "På forespørsel", type: "" }}
    action={{
      type: "modal",
      label: "Forespør",
      onClick: byttModal
    }}
    specifications={[
      "Tilpasset plan",
      "Støtte til å lage en sportsportefølje",
      "Støtte til akademisk forberedelse",
      "Markedsføring mot universiteter",
      "Forhandling om idrettsstipend",
      "Råd om valg av universitet og stipend",
      "Støtte for visumsøknad",
      "Støtte for opptak",
      "Støtte for praktiske spørsmål",
    ]}
  />
</Grid>
<Grid item xs={12} sm={6} lg={3}>
  <SimplePricingCard
    color="dark"
    title="Premium"
    description="Ditt vei til USA"
    price={{ value: "På forespørsel", type: "" }}
    action={{
      type: "modal",
      label: "Forespør",
      onClick: byttModal
    }}
    specifications={[
      "Tilpasset plan",
      "Støtte til å lage en sportsportefølje",
      "Støtte til akademisk forberedelse",
      "Markedsføring mot universiteter",
      "Forhandling om idrettsstipend",
      "Råd om valg av universitet og stipend",
      "Støtte for visumsøknad",
      "Støtte for opptak",
      "Støtte for praktiske spørsmål",
      "Studiebøker for SAT/ACT",
      "Biukentlig Zoom-samtale",
    ]}
  />
</Grid>
        </Grid>
      </Container>
      <Container>
    <Grid item xs={12} style={{ marginBottom: '80px' }}>
      <MKTypography variant="body2" color="text">
        {/* Din tekst går her */}
        *Tidligere tilbød jeg inntakssamtalen gratis. Dessverre førte dette til mange avtaler med lite seriøse personer, noe som var kostbart i form av tid. For å sikre kvaliteten på tjenestene mine og for å sørge for at samtalene er verdifulle for begge parter, ber jeg nå om et lite gebyr på € 14,99. Dette beløpet hjelper med å filtrere seriøse henvendelser samtidig som samtalen er uformell og fokusert på dine spesifikke behov.

Jeg setter pris på din forståelse og ser frem til en produktiv og innsiktsfull samtale med deg.
      </MKTypography>
    </Grid>
  </Container>
    
    </section>
  );
}

export default Tjenester;
