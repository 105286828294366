/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import Image1 from "assets/images/Sports_Images/Ice Hockey1.jpeg";
import Image2 from "assets/images/Sports_Images/Ice Hockey2.jpg";
import Image3 from "assets/images/Sports_Images/Ice Hockey3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images

import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Bourse de Hockey sur Glace aux États-Unis : Comment Étudier et Jouer au Hockey sur Glace en Amérique', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Chapitre 1 : L\'Histoire et le Développement du Hockey sur Glace en Amérique', isSubheader: false },
    { id: '1.1', title: '1.1 Premières Années et le Rôle des Collèges', isSubheader: true },
    { id: '1.2', title: '1.2 Croissance et Professionnalisation', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Chapitre 2 : Le Hockey sur Glace Universitaire Aujourd\'hui', isSubheader: false },
    { id: '2.1', title: '2.1 Les Ligues Majeures et les Conférences', isSubheader: true },
    { id: '2.2', title: '2.2 Programmes de Premier Plan et Équipes Prestigieuses', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Chapitre 3 : Du Collège aux Pros', isSubheader: false },
    { id: '3.1', title: '3.1 Le Chemin vers le Professionnalisme', isSubheader: true },
    { id: '3.2', title: '3.2 Le Repêchage de la LNH et Autres Opportunités', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Chapitre 4 : Influences Internationales', isSubheader: false },
    { id: '4.1', title: '4.1 Joueurs Étrangers dans le Hockey sur Glace Universitaire', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Chapitre 5 : Bourses Sportives et l\'Avenir des Athlètes', isSubheader: false },
    { id: '5.1', title: '5.1 L\'Importance des Bourses', isSubheader: true },
    { id: '5.2', title: '5.2 Comment Sport Scholarships America Peut Aider', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Sommaire
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Cacher</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Afficher</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Bourse de Hockey sur Glace aux États-Unis : Comment Étudier et Jouer au Hockey sur Glace en Amérique
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Vue aérienne d'un stade rempli lors d'un match de hockey sur glace universitaire."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Un stade rempli lors d'un match universitaire
      </figcaption>
    </figure>
    Le hockey sur glace est l'un des sports les plus populaires en Amérique. C'est un sport rapide, physique et excitant qui attire des millions de fans. Le hockey sur glace se pratique à différents niveaux, de l'amateur au professionnel. Mais l'un des niveaux les plus importants et prestigieux est le hockey sur glace universitaire.
    <br />
    <br />
    Le hockey sur glace universitaire est la version du hockey sur glace pratiquée par les étudiants des universités et collèges en Amérique. C'est une partie du système éducatif américain, combinant sport et académique. Le hockey sur glace universitaire offre aux jeunes joueurs l'occasion de se développer à la fois académiquement et athlétiquement tout en profitant de la vie étudiante.
    <br />
    <br />
    Le hockey sur glace universitaire est également un tremplin vers le monde professionnel. De nombreux joueurs qui évoluent dans la LNH (Ligue nationale de hockey) ou d'autres ligues professionnelles ont d'abord joué au hockey sur glace universitaire. Le hockey sur glace universitaire est un excellent moyen de se préparer à une carrière dans le sport.
    <br />
    <br />
    Mais comment un jeune joueur de hockey sur glace peut-il se qualifier pour le hockey sur glace universitaire ? C'est là que Sportbeursamerika.nl peut vous aider. Je suis spécialisé dans l'accompagnement des étudiants-athlètes qui veulent obtenir une bourse sportive en Amérique. Une bourse sportive est une récompense financière que vous recevez d'une université ou d'un collège pour y étudier et y pratiquer un sport. Avec une bourse sportive, vous pouvez étudier et jouer au hockey sur glace en Amérique sans supporter des coûts élevés.
    <br />
    <br />
    Dans cet article, nous vous fournirons plus d'informations sur le hockey sur glace universitaire en Amérique, son histoire, son développement, ses ligues, ses équipes, ses opportunités, ses influences internationales et, bien sûr, ses bourses. Je vous dirai également comment je peux vous aider à réaliser votre rêve. Êtes-vous prêt à en savoir plus sur les bourses de hockey sur glace aux États-Unis ? Lisez la suite !
  </MKTypography>
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 1 : L'Histoire et le Développement du Hockey sur Glace en Amérique
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le hockey sur glace est un sport qui existe en Amérique depuis plus d'un siècle. Il a une histoire riche et fascinante étroitement liée au rôle des collèges. Dans ce chapitre, nous plongeons plus en profondeur dans les origines, la croissance et la professionnalisation du hockey sur glace en Amérique.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Premières Années et le Rôle des Collèges
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Le hockey sur glace a été créé au Canada, où il a été joué pour la première fois au 19e siècle. Le jeu s'est rapidement propagé aux États-Unis, devenant particulièrement populaire dans les États du nord-est. Les premières équipes universitaires ont été formées à la fin du 19e et au début du 20e siècle, notamment à Yale, Harvard, Princeton et Dartmouth.
      <br />
      <br />
      Ces équipes disputaient des matchs intercollégiaux, attirant souvent de grands publics. Elles jouaient également contre des équipes canadiennes, qui étaient généralement plus fortes. Pour élever le niveau de jeu, certaines universités ont commencé à recruter des joueurs professionnels qui s'inscrivaient comme étudiants. Cette pratique a suscité des controverses et des critiques car elle compromettait le statut amateur du sport.
      <br />
      <br />
      Pour mettre fin à cette pratique, l'Intercollegiate Hockey Association (IHA) a été fondée en 1898, le premier regroupement de programmes de hockey sur glace universitaire en Amérique. L'IHA, parfois appelée la Ligue de hockey intercollégiale, comprenait des équipes comme Brown, Columbia, l'Université de Pennsylvanie et Yale. Cette organisation était l'autorité de championnat unique pour le hockey sur glace universitaire à l'époque, déterminant le champion national de facto en fonction des performances saisonnières. L'IHA a continué à exister jusqu'en 1913.
      <br />
      <br />
      Après la dissolution de l'IHA, la Intercollegiate Hockey League (IHL) a été créée en 1912 par Harvard, Princeton et Yale. Cette ligue était une réponse au besoin pour les meilleures équipes universitaires de continuer à concourir les unes contre les autres. L'IHL a existé jusqu'en 1917, lorsque les activités ont été suspendues en raison de la Première Guerre mondiale.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Croissance et Professionnalisation
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Tout au long du 20e siècle, le hockey sur glace universitaire est devenu un sport national, joué par des centaines d'équipes dans diverses divisions et conférences. Le hockey sur glace universitaire a également été de plus en plus influencé par la montée des ligues de hockey sur glace professionnelles, telles que la LNH (fondée en 1917) et l'Association mondiale de hockey (fondée en 1972).
      <br />
      <br />
      Ces ligues professionnelles ont offert des opportunités aux joueurs de collège talentueux de passer à un niveau supérieur. De nombreux joueurs universitaires ont été sélectionnés lors du repêchage de la LNH, l'événement annuel où les équipes de la LNH choisissent de nouveaux joueurs. Le premier repêchage de la LNH auquel un joueur universitaire a été sélectionné a eu lieu en 1967. Al Karlander, un joueur de Michigan Tech, a été sélectionné par Détroit en tant que 17e choix au total cette année-là. Depuis lors, plus d'un millier de joueurs universitaires ont été repêchés par des équipes de la LNH.
      <br />
      <br />
      Le hockey sur glace universitaire a également été le théâtre de moments significatifs de l'histoire du sport. L'un des plus mémorables a été le "Miracle sur Glace" en 1980 lorsque l'équipe olympique américaine, composée principalement de joueurs universitaires, a battu l'Union soviétique lors d'un match historique pendant les Jeux olympiques d'hiver à Lake Placid. Ce fut un exploit remarquable car les Soviétiques étaient considérés comme la meilleure équipe de hockey sur glace au monde à l'époque.
      <br />
      <br />
      Le hockey sur glace universitaire a donc une histoire longue et impressionnante, témoignant de la passion et du talent des joueurs américains. Le hockey sur glace universitaire a également contribué au développement et à l'innovation du sport en introduisant de nouvelles règles, tactiques et styles.
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  






<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 2 : Le Hockey sur Glace Universitaire Aujourd'hui
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le hockey sur glace universitaire est l'un des sports les plus compétitifs et excitants en Amérique aujourd'hui. Il est pratiqué par plus de 400 équipes, réparties en trois divisions de la NCAA, dans diverses conférences et zones régionales. Dans ce chapitre, nous donnerons un aperçu des principales ligues et conférences, des meilleurs programmes et équipes prestigieuses, ainsi que de la structure de la saison et du championnat.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Les Ligues Majeures et les Conférences
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Joueurs du Massachusetts célébrant une victoire dans le hockey sur glace universitaire."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Joueurs du Massachusetts célébrant une victoire
        </figcaption>
      </figure>
      La NCAA (National Collegiate Athletic Association) est l'organisation qui régule le hockey sur glace universitaire. La NCAA divise les équipes en trois divisions en fonction du niveau, du budget et du nombre de bourses qu'elles offrent. Les divisions sont les suivantes :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Division I : Il s'agit de la division la plus élevée où les meilleures équipes et joueurs s'affrontent. La Division I comprend 60 équipes, réparties en six conférences : Atlantic Hockey, Big Ten, ECAC Hockey, Hockey East, NCHC (National Collegiate Hockey Conference) et WCHA (Western Collegiate Hockey Association). Ces conférences organisent leurs propres compétitions et tournois tout au long de la saison.
        </li>
        <li>
          Division II : Il s'agit d'une division intermédiaire avec moins d'équipes et de joueurs. Il n'y a que sept équipes en Division II, et elles n'ont pas leur propre conférence. Elles concourent généralement contre des équipes de Division III ou d'autres ligues.
        </li>
        <li>
          Division III : Il s'agit de la division la plus basse où la plupart des équipes et joueurs participent. La Division III se compose de 84 équipes, réparties en sept conférences : CCC (Commonwealth Coast Conference), MASCAC (Massachusetts State Collegiate Athletic Conference), MIAC (Minnesota Intercollegiate Athletic Conference), NCHA (Northern Collegiate Hockey Association), NEHC (New England Hockey Conference), SUNYAC (State University of New York Athletic Conference) et UCHC (United Collegiate Hockey Conference). Ces conférences organisent également leurs propres compétitions et tournois pendant la saison.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      En plus des divisions et conférences de la NCAA, il existe également d'autres ligues et organisations qui proposent du hockey sur glace universitaire.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programmes de Premier Plan et Équipes Prestigieuses
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Le hockey sur glace universitaire compte plusieurs programmes de premier plan et équipes prestigieuses qui se démarquent par leur succès, leur tradition, leur réputation et leur influence. Certains de ces programmes et équipes incluent :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Université du Dakota du Nord : L'un des programmes de hockey sur glace universitaire les plus réussis en Amérique, concourant dans la conférence NCHC en Division I. L'université a remporté plusieurs championnats de la NCAA et a produit de nombreux joueurs qui ont joué dans la LNH, dont des noms bien connus comme Jonathan Toews, Zach Parise et T.J. Oshie.
        </li>
        <li>
          Boston College : Un programme de hockey sur glace universitaire de premier plan en Amérique, concourant dans la conférence Hockey East en Division I. Boston College a remporté plusieurs championnats nationaux. Le programme est également connu pour avoir formé plus de 80 joueurs de la LNH, avec des anciens élèves notables comme Johnny Gaudreau, Patrick Eaves et Brian Gionta.
        </li>
        <li>
          Université du Minnesota : Ce programme historique de hockey sur glace universitaire, concourant dans la conférence Big Ten en Division I, a remporté un nombre impressionnant de championnats nationaux. Il est connu pour avoir produit un nombre record de joueurs ayant joué dans la LNH, y compris des stars comme Phil Kessel, Paul Martin et Blake Wheeler.
        </li>
        <li>
          Université du Wisconsin : L'un des programmes de hockey sur glace universitaire les plus prestigieux en Amérique, concourant dans la conférence Big Ten en Division I. Le Wisconsin a remporté plusieurs championnats nationaux et a également formé un nombre important de joueurs qui ont joué dans la LNH, dont Ryan Suter, Joe Pavelski et Dany Heatley.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ces programmes et équipes ne sont que quelques exemples parmi tant d'autres que le hockey sur glace universitaire a à offrir. Il y a beaucoup d'autres équipes qui ont leur propre histoire, culture et identité. Le hockey sur glace universitaire est un sport diversifié et dynamique qui offre beaucoup aux joueurs et aux fans.
    </MKTypography>
  </MKBox>
</MKBox>
                    






                                  








<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 3 : Du Collège aux Pros
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le hockey sur glace universitaire n'est pas seulement un sport ; c'est aussi un terrain d'apprentissage. De nombreux joueurs qui participent au hockey sur glace universitaire ont des ambitions de passer au monde professionnel. Dans ce chapitre, nous discuterons de la manière dont le hockey sur glace universitaire peut servir de tremplin vers la LNH ou d'autres ligues professionnelles, des anciens élèves qui ont réussi dans le monde professionnel du hockey sur glace, et des opportunités qui existent au-delà de la LNH.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Le Chemin vers le Professionnalisme
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Le hockey sur glace universitaire est un excellent moyen de se préparer à une carrière dans le sport. En jouant au hockey sur glace universitaire, vous pouvez améliorer vos compétences, vos connaissances et votre expérience tout en obtenant un diplôme. Le hockey sur glace universitaire vous offre également la possibilité de rivaliser avec d'autres joueurs talentueux qui vous défient et vous inspirent.
      <br />
      <br />
      Un des avantages clés du hockey sur glace universitaire est qu'il augmente votre visibilité et votre exposition. Jouer au hockey sur glace universitaire peut attirer l'attention des recruteurs, des entraîneurs et des directeurs d'équipe professionnelle qui recherchent de nouveaux joueurs. Vous pouvez également participer à des présentations, des camps et des cliniques pour mettre en valeur vos compétences auprès des employeurs potentiels.
      <br />
      <br />
      Un autre avantage du hockey sur glace universitaire est qu'il offre de la flexibilité et des options. En jouant au hockey sur glace universitaire, vous pouvez retarder la prise de décision concernant votre avenir. Vous pouvez choisir de passer à une équipe professionnelle après votre première, deuxième, troisième ou quatrième année, en fonction de votre situation et de vos préférences. Vous pouvez également choisir de terminer vos études avant de devenir professionnel.
      <br />
      <br />
      Le hockey sur glace universitaire a produit de nombreux joueurs qui ont réussi dans le monde professionnel du hockey sur glace. Certains de ces joueurs incluent :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Jack Eichel : Il a joué une saison pour l'Université de Boston dans la conférence Hockey East en Division I. Il a remporté le Prix Hobey Baker du meilleur joueur de l'année en 2015. Il a été sélectionné au deuxième rang lors du repêchage de la LNH en 2015 par les Sabres de Buffalo, où il est devenu le capitaine et le joueur vedette. En 2023, il a remporté la Coupe Stanley avec les Golden Knights de Vegas.
        </li>
        <li>
          Cale Makar : Il a joué deux saisons pour UMass Amherst dans la conférence Hockey East en Division I. Il a également remporté le Prix Hobey Baker du meilleur joueur de l'année en 2019. Il a été sélectionné au quatrième rang lors du repêchage de la LNH en 2017 par l'Avalanche du Colorado, où il joue actuellement en tant que l'un des meilleurs défenseurs de la ligue.
        </li>
        <li>
          Hilary Knight : Hilary Knight a joué quatre saisons pour l'Université du Wisconsin dans la conférence WCHA en Division I. Elle était une joueuse très talentueuse et a été nominée comme finaliste dans le top 10 pour le Prix Memorial Patty Kazmaier en 2009, 2011 et 2012. Bien qu'elle n'ait pas remporté le prix, elle a été deux fois nommée Première Équipe All-American. Knight a ensuite joué pour les Boston Pride dans la NWHL (National Women's Hockey League) et l'équipe nationale des États-Unis, avec laquelle elle a remporté deux médailles d'argent olympiques et plusieurs championnats du monde.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ces joueurs ne sont que quelques exemples parmi tant d'autres qui ont transformé leur carrière de hockey sur glace universitaire en carrière professionnelle. Le hockey sur glace universitaire est un chemin éprouvé vers le professionnalisme pour de nombreux joueurs.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Le Repêchage de la LNH et Autres Opportunités
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La LNH (Ligue Nationale de Hockey) est la première ligue professionnelle de hockey sur glace au monde. C'est le rêve de nombreux jeunes joueurs de jouer un jour dans la LNH. L'une des façons d'y parvenir est à travers le repêchage de la LNH, l'événement annuel où les équipes de la LNH sélectionnent de nouveaux joueurs.
      <br />
      <br />
      Le repêchage de la LNH est un moment excitant et significatif pour de nombreux joueurs universitaires. C'est l'occasion d'être choisi par une équipe de la LNH, qui vous offre un contrat pour jouer pour elle. Cela reconnaît également votre talent, votre potentiel et votre travail acharné.
      <br />
      <br />
      Le repêchage de la LNH se compose de sept rondes au cours desquelles chaque équipe de la LNH prend tour à tour un joueur. L'ordre de sélection est déterminé par un tirage au sort, les équipes ayant obtenu les pires résultats lors de la saison précédente ayant une meilleure chance de choisir en premier. Le repêchage de la LNH a généralement lieu en juin ou juillet après la fin de la saison de hockey sur glace universitaire.
      <br />
      <br />
      Pour être éligible au repêchage de la LNH, vous devez remplir certains critères. Vous devez être un joueur nord-américain âgé de 18 à 20 ans ou un joueur international âgé de 18 à 21 ans. Vous ne devez pas avoir été précédemment repêché par une équipe de la LNH ou avoir renoncé à vos droits.
      <br />
      <br />
      Être repêché par une équipe de la LNH ne signifie pas que vous devez immédiatement jouer pour elle. Vous pouvez choisir de retourner à votre équipe universitaire et de terminer vos études tout en conservant les droits de l'équipe de la LNH. Vous pouvez également choisir de rejoindre l'équipe de la LNH ou leur filiale de la Ligue mineure, où vous pouvez continuer à vous développer et à vous entraîner.
      <br />
      <br />
      Cependant, le repêchage de la LNH n'est pas le seul moyen d'atteindre la LNH. Il existe d'autres possibilités, telles que l'agent libre. Cela se produit lorsque vous, en tant que joueur, n'avez pas de contrat avec une équipe de la LNH et êtes libre de négocier avec n'importe quelle équipe de votre choix. Cela peut arriver si vous n'avez pas été repêché ou si votre contrat avec une équipe de la LNH expire ou est résilié. En tant qu'agent libre, vous pouvez essayer de décrocher un contrat avec une équipe de la LNH qui s'intéresse à vous.
      <br />
      <br />
      Néanmoins, la LNH n'est pas la seule ligue professionnelle de hockey sur glace au monde. Il existe d'autres ligues où vous pouvez jouer en tant que joueur et gagner votre vie. Certaines de ces ligues incluent :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          KHL (Ligue Continentale de Hockey) : Il s'agit de la première ligue professionnelle de hockey sur glace en Europe et en Asie. Elle est composée de 24 équipes provenant de huit pays : la Russie, la Finlande, la Biélorussie, le Kazakhstan, la Chine, la Lettonie, la Slovaquie et la République tchèque. Elle offre un niveau de jeu et un salaire élevés, attirant des joueurs de différents pays.
        </li>
        <li>
          SHL (Ligue Suédoise de Hockey) : Il s'agit de la première ligue professionnelle de hockey sur glace en Suède. Elle est composée de 14 équipes qui concourent pour le championnat national. Elle est connue pour son jeu rapide et technique et a produit de nombreux joueurs ayant joué dans la LNH.
        </li>
        <li>
          Liiga (Ligue Finnoise de Hockey) : Il s'agit de la première ligue professionnelle de hockey sur glace en Finlande. Elle est composée de 15 équipes qui concourent pour le championnat national. Elle est connue pour son jeu physique et tactique et a également produit de nombreux joueurs ayant joué dans la LNH.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ces ligues ne sont que quelques exemples parmi tant d'autres dans le monde entier. Il existe de nombreuses autres ligues dans d'autres pays, notamment en Suisse, en Allemagne, en France, en Norvège, au Danemark, en Autriche, en Pologne, au Japon et en Australie. Ces ligues offrent différents niveaux de jeu, de salaires et de modes de vie aux joueurs.
      <br />
      <br />
      Le hockey sur glace universitaire n'est donc pas la fin de la route pour de nombreux joueurs. Au contraire, c'est le début d'un voyage passionnant dans le monde professionnel. Le hockey sur glace universitaire offre de nombreuses opportunités et options aux joueurs qui poursuivent leurs rêves.
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  







<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 4 : Influences Internationales
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le hockey sur glace universitaire est un sport qui n'est pas seulement américain, mais aussi international. Le hockey sur glace universitaire est influencé par la présence et la performance de joueurs étrangers dans les compétitions universitaires américaines. Le hockey sur glace universitaire influence également les opportunités et le développement des joueurs américains à l'étranger. Dans ce chapitre, nous discuterons du rôle et de l'impact des joueurs internationaux dans le hockey sur glace universitaire et des opportunités pour les joueurs américains de jouer à l'international.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Joueurs Étrangers dans le Hockey sur Glace Universitaire
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Joueurs de hockey sur glace de Quinnipiac célébrant un but."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Joueurs de Quinnipiac célébrant un but
        </figcaption>
      </figure>
      Le hockey sur glace universitaire est un sport ouvert aux joueurs du monde entier. De nombreux joueurs étrangers viennent en Amérique pour jouer au hockey sur glace universitaire, avec des origines, des cultures et des nationalités variées. Certains de ces joueurs incluent :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Daryl Watts : Elle est une joueuse de hockey sur glace canadienne qui a joué pour Boston College et Wisconsin en NCAA Division I pendant deux saisons. Elle a remporté le Prix Patty Kazmaier en 2018 en tant que première année, devenant ainsi la première première année à remporter le prix. Watts a ensuite joué pour les Toronto Six dans la Premier Hockey Federation (PHF), anciennement connue sous le nom de National Women's Hockey League (NWHL), et a signé un contrat record avec les Toronto Six en 2023.
        </li>
        <li>
          Rod Brind'Amour : Né au Canada, il a joué au hockey sur glace universitaire à l'Université du Michigan avant de passer à la LNH. Il a eu une carrière professionnelle très réussie, avec des moments forts incluant la victoire de la Coupe Stanley.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ces joueurs ne sont que quelques exemples parmi tant d'autres de joueurs étrangers qui jouent ou ont joué au hockey sur glace universitaire. Il y a beaucoup plus de joueurs d'autres pays tels que la Suède, la Russie, la République tchèque, l'Allemagne, la France, la Suisse, le Danemark, le Japon et l'Australie.
      <br />
      <br />
      Ces joueurs étrangers ont un rôle et un impact importants dans le hockey sur glace universitaire. Ils apportent leur propre style, leurs compétences et leur expérience aux compétitions universitaires américaines. Ils enrichissent le niveau et la diversité du jeu. Ils suscitent également plus d'intérêt et d'attention pour le hockey sur glace universitaire dans leurs propres pays et régions.
      <br />
      <br />
      Le hockey sur glace universitaire est un sport qui bénéficie des influences internationales. C'est un sport qui accueille et apprécie les joueurs du monde entier.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  



<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 5 : Bourses Sportives et l'Avenir des Athlètes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Les bourses sportives offrent de nombreuses opportunités aux jeunes athlètes de montrer leur talent et de développer leurs compétences. L'une des opportunités les plus significatives est d'obtenir une bourse sportive pour étudier et compétitionner au niveau collégial. Dans ce chapitre, nous discuterons de l'importance des bourses sportives et de comment Sport Scholarships America peut vous aider à réaliser votre rêve.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 L'Importance des Bourses
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Une bourse sportive est un soutien financier fourni par une université à un étudiant-athlète pour combiner études et sport. Elle peut couvrir les frais de scolarité, les livres, le logement, les repas et autres dépenses. De plus, elle peut offrir des avantages tels que du tutorat académique, des soins médicaux, des installations d'entraînement et du matériel.
      <br />
      <br />
      Obtenir une bourse sportive est compétitif, avec de nombreux facteurs qui influent sur le processus d'attribution, notamment les performances académiques, sportives, la personnalité, la motivation, le leadership et l'adaptabilité. Il est donc important de bien se préparer et de se démarquer de la concurrence.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Comment Sport Scholarships America Peut Aider
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo de Sport Scholarships America, représentant du matériel sportif et une casquette de graduation, symbolisant le focus sur les bourses sportives pour les étudiants-athlètes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America est une organisation spécialisée dans l'orientation des jeunes talents sportifs vers des bourses sportives. Avec des experts passionnés et expérimentés, Sport Scholarships America soutient les étudiants-athlètes à chaque étape du parcours, de l'orientation initiale à la placement final. Leurs services comprennent :
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Un entretien personnel pour discuter des souhaits, des possibilités et des attentes de l'étudiant-athlète.</li>
        <li>Une évaluation professionnelle du niveau académique et sportif de l'étudiant-athlète.</li>
        <li>Un vaste réseau de contacts avec des entraîneurs, des universités et des collèges.</li>
        <li>Promotion efficace du profil et des vidéos de l'étudiant-athlète auprès des entraîneurs potentiels.</li>
        <li>Conseils d'experts sur les meilleures options en fonction des préférences, des objectifs et du budget de l'étudiant-athlète.</li>
        <li>Négociation des meilleures offres de bourses au nom de l'étudiant-athlète.</li>
        <li>Aide à la demande de documents nécessaires, tels que visa, relevé de notes, diplôme et tests d'admission.</li>
        <li>Préparation à la vie d'étudiant-athlète pendant sa bourse, y compris culture, langue, règles et conseils.</li>
        <li>Suivi et accompagnement pendant la période de la bourse.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vous voulez explorer les opportunités de bourses et atteindre vos objectifs sportifs et académiques ? <Link to="/fr/Services-Produits">Consultez la gamme complète</Link> des services offerts par Sport Scholarships America et planifiez une consultation dès aujourd'hui.
    </MKTypography>
  </MKBox>
</MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;