
/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post2 from "assets/images/TijdUS.png";
import "../../../../H1asH3.css"


// Images

function Profile() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
            <MKBox 
                display="flex" 
                flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} 
                justifyContent="space-between" 
                alignItems="center" 
    mb={1}
>
    <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Part 3: Back in the Netherlands</MKTypography>

    
</MKBox>
           

              <MKTypography variant="body1" fontWeight="light" color="text">

              <MKTypography variant="h4">
               
                </MKTypography>
                <br />
                Al regresar a los Países Bajos, experimenté una mezcla de nostalgia y determinación. Mi profunda pasión por el fútbol ahora se unía a un renovado deseo de desafíos académicos. Ámsterdam se convirtió en el escenario de mi próxima aventura, donde busqué obtener una maestría en Finanzas Cuantitativas, un campo que estimulaba tanto mi mente analítica como mis ambiciones.
                <br /><br />
                Después de completar mis estudios, comencé a trabajar como consultor. Aunque el mundo de los números y el análisis me fascinaba, sentía que algo faltaba. Los ecos de mi viaje por Estados Unidos seguían resonando en mí, recordándome la oportunidad única que había experimentado, una oportunidad que deseaba para otros.
                <br /><br />
                En consecuencia, decidí utilizar mis experiencias y conocimientos para guiar a otros jóvenes atletas ambiciosos. Antes de lanzar esta empresa, me involucré con entrenadores, atletas, exatletas y otros en mi amplia red en Estados Unidos. Estas conversaciones ayudaron tanto a mí como a los futuros estudiantes a comprender el mejor enfoque para estas oportunidades.
                <br /><br />
                Durante la desafiante pandemia de COVID-19, colaboré con una agencia líder en Estados Unidos. Aunque pude ayudar a menos estudiantes internacionales debido a la disponibilidad limitada de becas, utilicé mi experiencia para ayudar a estudiantes estadounidenses. Esta experiencia no solo amplió sustancialmente mi red, sino que también proporcionó ideas sobre el mercado de becas estadounidenses, que ahora puedo aplicar de manera más efectiva en Europa.
                <br /><br />
                Los desafíos durante la pandemia fortalecieron mi misión de guiar a jóvenes talentos hacia universidades estadounidenses y becas deportivas. A pesar de las dificultades, me brindó una sensación de realización que nunca antes había experimentado. Era mi manera de devolver a la comunidad que una vez me recibió cálidamente en Estados Unidos.
                <br /><br />
                Esta es mi historia, y estoy orgulloso de poder ayudar a otros a escribir sus propias historias en la tierra de las infinitas posibilidades. Si desea obtener más información sobre mi empresa y el viaje de mis clientes para hacer realidad sus sueños, continúe leyendo en mi sitio web. Si tiene alguna pregunta o desea ponerse en contacto conmigo, no dude en hacerlo.
              </MKTypography>

              <hr style={{ margin: '20px 0'}} />
              <Grid item xs={12} lg={6} mb={{ xs: 3, lg: 0 }}>
            <TransparentBlogCard
              image={post2}
              title="Part 2: My Time in America"
              action={{
                type: "internal",
                route: "/es/Acerca-de-mí/Mi-tiempo-en-América",
                color: "info",
                label: "read more",
              }}
            />
          </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}


export default Profile;
