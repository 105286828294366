/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Volleybal1.avif";
import Image2 from "assets/images/Sports_Images/volleybal2.webp";
import Image3 from "assets/images/Sports_Images/Volleybal3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images
import { Link } from 'react-router-dom';



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Volleyball Scholarship: How Can You Study and Play Volleyball in America?', isSubheader: false },
  
    { id: 'Chapter 1', title: '1. The History and Development of Volleyball in America', isSubheader: false },
    { id: '1.1', title: '1.1 Early Years and the Role of Educational Institutions', isSubheader: true },
    { id: '1.2', title: '1.2 Growth and Professionalization', isSubheader: true },
  
    { id: 'Chapter 2', title: '2. University Volleyball Today', isSubheader: false },
    { id: '2.1', title: '2.1 Major Competitions and Conferences', isSubheader: true },
    { id: '2.2', title: '2.2 Top Programs and Prestigious Teams', isSubheader: true },
  
    { id: 'Chapter 3', title: '3. From College to the Pros', isSubheader: false },
    { id: '3.1', title: '3.1 The Path to Professionalism', isSubheader: true },
    { id: '3.2', title: '3.2 Professional Competitions and Career Opportunities', isSubheader: true },
  
    { id: 'Chapter 4', title: '4. International Influences and Competitions', isSubheader: false },
    { id: '4.1', title: '4.1 Foreign Influences', isSubheader: true },
    { id: '4.2', title: '4.2 Americans on the International Stage', isSubheader: true },
  
    { id: 'Chapter 5', title: '5. Volleyball Scholarships and the Future', isSubheader: false },
    { id: '5.1', title: '5.1 The Importance of Scholarships', isSubheader: true },
    { id: '5.2', title: '5.2 How Sport Scholarships America Can Help', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Content
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Hide</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Show</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
           <Grid item xs={12} md={9}>


























           <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Volleyball Scholarship: How Can You Study and Play Volleyball in America?
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Volleyball game in Nebraska, attended by more than 92,000 fans."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        92,000 spectators watching a volleyball game in Nebraska
      </figcaption>
    </figure>
    Volleyball is a sport that demands a lot from your physical and mental abilities. You need to react quickly, collaborate effectively, think tactically, and have strong endurance. Additionally, volleyball is a sport that provides a lot of joy and satisfaction, especially when playing at a high level. But how can you, as a volleyball talent, pursue your athletic ambitions without neglecting your studies? The answer is: by opting for a volleyball scholarship in America.
    <br />
    <br />
    A volleyball scholarship is a financial aid offered by an American university that allows you to study and play volleyball there. With a volleyball scholarship, you can achieve two goals at once: you can earn a recognized degree from a prestigious educational institution while further developing your volleyball skills under professional guidance. Moreover, you can enjoy the unique experience of living and learning in a different culture, with new friends and challenges.
    <br />
    <br />
    But how can you qualify for a volleyball scholarship? What are the requirements and expectations? And how do you find the right university that suits you? These are all questions that Sportbeursamerika.nl can assist you with. Sportbeursamerika.nl is the specialist in the field of sports scholarships in America. With years of experience and an extensive network, I can guide you through all the steps to successfully apply for a volleyball scholarship, from creating an online profile to handling all practical matters.
    <br />
    <br />
    If you want to learn more about how Sportbeursamerika.nl can help you study and play volleyball in America, continue reading or contact me for a no-obligation introductory conversation. I would be happy to tell you more about the possibilities and benefits of a volleyball scholarship in America.
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
 {/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 1: The History and Development of Volleyball in America
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Volleyball is a sport that has existed for more than a century, but it has experienced significant growth in popularity and professionalism, especially in recent decades. In this chapter, we will delve into the history and development of volleyball in America, from its early years to the present.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Early Years and the Role of Educational Institutions
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Volleyball was invented in 1895 by William G. Morgan, a physical education teacher at the YMCA (Young Men’s Christian Association) in Holyoke, Massachusetts. He aimed to create a game that was less physically demanding than basketball, which had been invented a year earlier by his colleague James Naismith. Initially, he called the game "mintonette," but it soon became known as "volleyball" because players had to volley the ball over a net.
      <br />
      <br />
      The game quickly spread throughout the United States and other countries, primarily through YMCA and other educational institutions. In 1916, one of the first official volleyball tournaments was held at the University of California in Berkeley. In 1922, the United States Volleyball Association (USVBA) was established, which later became USA Volleyball (USAV). This organization became responsible for regulating and promoting volleyball in America.
      <br />
      <br />
      In 1928, the first national championship for men was organized by the USVBA, followed by the first national championship for women in 1949. In 1955, the first men's World Championship was held in France, where America finished sixth. In 1956, the first women's World Championship took place in France, with America finishing fourth.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Growth and Professionalization
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      In the 1960s and 1970s, volleyball began to grow and professionalize in America. This was partly due to the introduction of new rules, such as the adoption of the rally-point system, where every point counts regardless of who is serving. Volleyball also gained more attention through television broadcasts of the Olympic Games, where volleyball has been an official sport since 1964.
      <br />
      <br />
      One of the key figures in the history of volleyball in America is Doug Beal, who achieved success both as a player and a coach. He played for the national team from 1970 to 1976 and won gold at the Pan American Games in 1975, among other achievements. He later became the coach of the national team from 1977 to 1984 and led them to their first Olympic gold in 1984 in Los Angeles. He was also involved in the founding of the Major League Volleyball (MLV), a professional women's league that existed from 1987 to 1989.
      <br />
      <br />
      Another significant event in the history of volleyball in America was the establishment of the Association of Volleyball Professionals (AVP) in 1983, focusing on beach volleyball. Beach volleyball is a variant of volleyball played on sand with teams of two players. The AVP organized tournaments and competitions for both men and women, helping to popularize beach volleyball with the public. Some well-known beach volleyball players include Karch Kiraly, Sinjin Smith, Randy Stoklos, Misty May-Treanor, and Kerri Walsh Jennings.
    </MKTypography>
  </MKBox>
</MKBox>
             





                                  







<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 2: University Volleyball Today
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Volleyball is one of the most popular and competitive sports at the university level in America. Every year, thousands of teams participate in various competitions and conferences, culminating in the national championships. In this chapter, we will provide you with an overview of the major leagues and conferences, and discuss some of the top programs and prestigious teams in university volleyball.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Major Leagues and Conferences
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Volleyball team celebrating a victory together."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Volleyball players celebrating a victory
        </figcaption>
      </figure>
      The primary organization responsible for organizing and regulating university volleyball in America is the National Collegiate Athletic Association (NCAA). The NCAA divides universities into three divisions based on their size, budget, and athletic performance. The divisions are:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Division I: This is the highest division, where most large and well-known universities are located. Teams in this division receive the most attention, media coverage, and scholarships. There are approximately 330 universities in this division, divided into 32 conferences. A conference is a group of universities that are geographically or historically connected and compete against each other. Some examples of conferences in Division I include the Big Ten, Pac-12, Southeastern Conference (SEC), and Atlantic Coast Conference (ACC).
        </li>
        <li>
          Division II: This is the middle division, where universities are slightly smaller and less well-known than in Division I, but they still maintain a high level of volleyball. Teams in this division receive less attention, media coverage, and scholarships than in Division I but more than in Division III. There are approximately 300 universities in this division, divided into 23 conferences. Some examples of conferences in Division II include the Great Lakes Valley Conference (GLVC), California Collegiate Athletic Association (CCAA), and Gulf South Conference (GSC).
        </li>
        <li>
          Division III: This is the lowest division, where universities primarily focus on academic excellence rather than athletic performance. Teams in this division do not receive scholarships for their athletes but do provide financial aid based on academic merit. There are approximately 450 universities in this division, divided into 43 conferences. Some examples of conferences in Division III include the New England Small College Athletic Conference (NESCAC), University Athletic Association (UAA), and Centennial Conference.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Each year, teams within their division and conference compete in matches to qualify for the national championships, also known as the NCAA Tournament. This is a knockout tournament where the best teams from each division compete for the title of national champion. The tournament is held in the spring for men and in the fall for women.
    </MKTypography>

  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Top Programs and Prestigious Teams
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      University volleyball in America boasts a high level of competition, and many teams excel in their respective divisions and conferences. However, some teams have earned a special reputation and history due to their consistent performance, talented players and coaches, and a large fanbase. Here are some of the top programs and prestigious teams in university volleyball:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          UCLA Bruins: Representing the University of California, Los Angeles, the UCLA Bruins are known for their strong tradition in both men's and women's volleyball in NCAA Division I. Historically, they have won multiple national championships and have produced some of the most respected players in the sport. This team is a prominent member of the Pac-12 Conference.
        </li>
        <li>
          Stanford Cardinal: The women's volleyball team of Stanford University, located in Stanford, California, has distinguished itself by winning an impressive number of national championships in NCAA Division I, more than any other team in their category. They have consistently produced talented players and compete in the Pac-12 Conference.
        </li>
        <li>
          Penn State Nittany Lions: The women's volleyball team of Pennsylvania State University in University Park, Pennsylvania, has a reputation for excellence in NCAA Division I, with numerous national championships to their name. They are known for their consecutive titles and have trained outstanding players over the years. This team is a significant member of the Big Ten Conference.
        </li>
        <li>
          BYU Cougars: Brigham Young University in Provo, Utah, has a respected men's volleyball team in NCAA Division I, which has won the national championship multiple times. The team has consistently excelled in developing top volleyball players and is part of the Mountain Pacific Sports Federation (MPSF).
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      These programs have been prominent and influential in university volleyball in the United States over the years, known for their strong traditions, talented players, and influential coaches.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  








<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 3: From College to the Pros
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    For many student-athletes who play volleyball at the university level, it is a dream to also play professional volleyball after graduation. But how realistic is this dream, and what are the steps you need to take to achieve it? In this chapter, we will discuss the path to professionalism, professional leagues, and career opportunities for volleyball players.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 The Path to Professionalism
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Playing volleyball at the university level is a great way to improve your volleyball skills, earn your academic degree, and develop personally. However, it is also a highly competitive and demanding environment where you must invest a lot of time and energy into both your sport and your studies. Therefore, it's important to be realistic about your chances of playing professional volleyball after your college career.
      <br />
      <br />
      Unlike some other sports like basketball or soccer, there is no professional volleyball league in America that recruits directly from universities. This means that as a graduate player, you will need to seek other opportunities to start your professional career, usually abroad. There are various professional volleyball leagues in Europe, Asia, South America, and other regions where you can play for clubs that offer you a salary and other benefits.
      <br />
      <br />
      But how do you get in touch with these clubs, and how do you ensure that you stand out among the many other players who are also looking for a contract? Here are some tips to help you find a professional volleyball club:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Create an online profile on a platform like Volleyball Agency or Volleyball Market, where you can share your videos, statistics, references, and contact information with potential clubs and agents.
        </li>
        <li>
          Contact your former coaches, teammates, or other contacts who have experience with professional volleyball or have connections with clubs or agents. They can provide you with advice, make recommendations, or introduce you to the right people.
        </li>
        <li>
          Participate in try-outs, showcases, or camps organized by professional clubs or organizations. These are events where you can showcase your skills to scouts, coaches, or managers looking for new players. Make sure you are well-prepared, both physically and mentally, and leave a positive impression.
        </li>
        <li>
          Be flexible and willing to move to another country or continent. Playing professional volleyball often means living in a different culture, with a different language, climate, and customs. This can be a significant challenge but also a great opportunity to learn and grow as a person.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professional Leagues and Career Opportunities
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      If you manage to secure a contract with a professional volleyball club, you can enjoy the benefits of playing at the highest level of the sport. You can participate in exciting leagues, compete against the best players in the world, travel to different countries and cities, and earn money from your passion. But what are the main professional leagues you can participate in, and what are the other career opportunities for volleyball players?
      <br />
      <br />
      Here are some of the most prestigious and lucrative professional volleyball leagues in the world:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          The Italian Serie A: This is the top division of Italian volleyball, consisting of 14 teams for men and 12 teams for women. Serie A is known as one of the strongest and most competitive leagues in the world, featuring many international stars and high salaries. The champion of Serie A also qualifies for the Champions League, the premier club tournament in Europe.
        </li>
        <li>
          The Turkish Süper Lig: This is the top division of Turkish volleyball, consisting of 12 teams for men and 14 teams for women. The Süper Lig is also one of the leading leagues in the world, with significant investments, media attention, and talented players. The champion of the Süper Lig also qualifies for the Champions League.
        </li>
        <li>
          The Brazilian Superliga: This is the top division of Brazilian volleyball, consisting of 12 teams for men and 12 teams for women. The Superliga is the dominant league in South America, known for its passion, atmosphere, and quality. The champion of the Superliga also qualifies for the FIVB Volleyball Men's Club World Championship, the premier club tournament in the world.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      In addition to playing in professional leagues, there are other opportunities for volleyball players to continue or expand their careers. Here are some examples:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Coaching or training other players: If you want to end or reduce your playing career, you can also pass on your knowledge and experience to other players as a coach or trainer. You can work for a professional club, a university, a school, or another organization that offers volleyball. You can also establish your own volleyball school or academy, where you can train and mentor young talents.
        </li>
        <li>
          Working in the media or the industry: If you have an interest in the media or industries related to volleyball, you can also build a career there. You can work as a commentator, analyst, journalist, writer, photographer, or videographer for various media outlets covering volleyball. You can also work as a representative, advisor, marketer, or designer for different companies offering volleyball products or services.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  







<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 4: International Influences and Competitions
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Volleyball is a global sport, played and followed by millions of people in different countries and continents. University volleyball in America is not isolated but is influenced and challenged by the international volleyball community. In this chapter, we will discuss the foreign influences and international competitions that play a role in university volleyball.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Foreign Influences
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Missouri Volleyball Stadium filled with enthusiastic spectators."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          A University of Missouri match
        </figcaption>
      </figure>
      University volleyball in America owes much to the foreign influences that have enriched and improved the sport. One of the most significant influences is the immigration of players, coaches, and trainers from other countries who brought their knowledge, experience, and culture to America. Some of these immigrants have even become legends in university volleyball, such as:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Arie Selinger: He is an Israeli coach who moved to America in 1969 to coach the national women's team. He led the team to their first Olympic medal, a silver in 1984. He also coached several university teams, including Long Beach State, where he became a four-time national champion.
        </li>
        <li>
          Andy Banachowski: He is a Polish player who emigrated to America in 1951. He played for UCLA, where he became a two-time national champion. He later became the coach of the UCLA women's team, winning six national championships and over 1000 matches.
        </li>
        <li>
          Marv Dunphy: He is a Canadian player who came to America in 1972 to play for Pepperdine University. He later became the coach of the Pepperdine men's team, winning four national championships. He also coached the national men's team, which won gold at the 1988 Olympics.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Another important influence is the popularity and level of international volleyball, which provides inspiration and motivation for university volleyball. International volleyball includes various regional and global tournaments where the best teams and players in the world meet. Some of these tournaments include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          The Olympic Games: This is the largest and most prestigious sporting event in the world, held every four years. Volleyball has been an official Olympic sport since 1964 for both men and women. The United States has won a total of 9 Olympic medals in volleyball, including 3 gold, 3 silver, and 3 bronze.
        </li>
        <li>
          The World Championship: This is the most important tournament for national teams, held every four years. It is organized by the International Volleyball Federation (FIVB), the global governing body for volleyball. The World Championship has been held since 1949 for men and since 1952 for women. The United States has won a total of 5 World Championship medals in volleyball, including 2 gold, 1 silver, and 2 bronze.
        </li>
        <li>
          The World Cup: This is another tournament for national teams, held every four years. It is also organized by the FIVB but differs from the World Championship in terms of the number of participating teams (12 instead of 24) and the qualification system (based on continental championships). The World Cup has been held since 1965 for men and since 1973 for women. The United States has won a total of 7 World Cup medals in volleyball, including 2 gold, 3 silver, and 2 bronze.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Americans on the World Stage
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      University volleyball in America has not only learned from international volleyball but has also contributed significantly to international volleyball. Many American players who competed at the university level have also successfully participated in international competitions, both for their national teams and professional clubs. Here are some examples of American players who excelled on the world stage:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Karch Kiraly: He is one of the greatest volleyball players of all time, excelling in both indoor and beach volleyball. He played for UCLA, where he became a three-time national champion. He also played for the national team, winning two Olympic gold medals in indoor volleyball (1984 and 1988) and one in beach volleyball (1996). He also played professionally in Italy, where he won four Italian titles and two Champions League titles.
        </li>
        <li>
          Logan Tom: She is one of the greatest female volleyball players of all time, playing both as an attacker and a libero. She played for Stanford, where she became a national champion and a four-time All-American. She also played for the national team, participating in four Olympic Games and winning two silver medals (2008 and 2012). She also played professionally in various countries, including Brazil, Turkey, Japan, and China.
        </li>
        <li>
          Matt Anderson: He is one of the best male volleyball players of the current generation, capable of playing as both an opposite and an outside hitter. He played for Penn State, where he became a national champion in 2008 and was named an All-American in 2007 and 2008. He also plays for the national team, winning bronze at the 2016 Olympics and gold at the 2015 World Cup. He also played professionally in Russia, where he won three Russian titles and two Champions League titles.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>
                      






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 5: Sports Scholarships and the Future of Athletes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sports scholarships offer numerous opportunities for young athletes to showcase their talent and develop their skills. One of the most significant opportunities is obtaining a sports scholarship to study and compete at the college level. In this chapter, we will discuss the importance of sports scholarships and how Sport Scholarships America can help you make your dream come true.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 The Importance of Scholarships
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A sports scholarship is financial support provided by a university to a student-athlete to combine studies and sports. It can cover the costs of tuition, books, housing, meals, and other expenses. Additionally, it may offer benefits such as academic tutoring, medical care, training facilities, and equipment.
      <br />
      <br />
      Obtaining a sports scholarship is competitive, with many factors playing a role in the awarding process, including academic achievements, athletic performance, personality, motivation, leadership, and adaptability. Therefore, it's essential to prepare well and distinguish yourself from the competition.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 How Sport Scholarships America Can Help
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo of Sport Scholarships America, featuring sports equipment and a graduation cap, representing the focus on sports scholarships for student-athletes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo of Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America is an organization specialized in guiding young sports talents to sports scholarships. With passionate and experienced experts, Sport Scholarships America supports student-athletes at every step of the journey, from initial orientation to final placement. Their services include:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>A personal intake interview to discuss the student-athlete's wishes, possibilities, and expectations.</li>
        <li>A professional evaluation of the student-athlete's athletic and academic level.</li>
        <li>An extensive network of contacts with coaches, universities, and colleges.</li>
        <li>Effective promotion of the student-athlete's profile and videos to potential coaches.</li>
        <li>Expert advice on the best options based on the student-athlete's preferences, goals, and budget.</li>
        <li>Negotiation of the best scholarship offers on behalf of the student-athlete.</li>
        <li>Assistance in applying for necessary documents, such as visa, transcript, diploma, and admission tests.</li>
        <li>Preparation for student-athletes' life during their scholarship, including culture, language, rules, and tips.</li>
        <li>Aftercare and follow-up during the scholarship period.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Want to explore scholarship opportunities and achieve your athletic and academic goals? <Link to="/Services-Products">View the full range</Link> of services offered by Sport Scholarships America and schedule a consultation today.
    </MKTypography>
  </MKBox>
</MKBox>
       
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;