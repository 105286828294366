/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Softbal1.jpeg";
import Image2 from "assets/images/Sports_Images/Softbal2.jpg";
import Image3 from "assets/images/Sports_Images/Softbal3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Softball Scholarships in the USA: How to Study and Play Softball in America', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Chapter 1: History and Development of Softball in America', isSubheader: false },
    { id: '1.1', title: '1.1 Early Years and the Role of Schools and Universities', isSubheader: true },
    { id: '1.2', title: '1.2 Growth and Professionalization', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Chapter 2: University Softball Today', isSubheader: false },
    { id: '2.1', title: '2.1 Important Leagues and Conferences', isSubheader: true },
    { id: '2.2', title: '2.2 Top Programs and Prestigious Teams', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Chapter 3: From College to the Pros', isSubheader: false },
    { id: '3.1', title: '3.1 The Path to Professionalism', isSubheader: true },
    { id: '3.2', title: '3.2 Professional Softball and Other Career Opportunities', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Chapter 4: International Influences and Events', isSubheader: false },
    { id: '4.1', title: '4.1 Foreign Influences', isSubheader: true },
    { id: '4.2', title: '4.2 Americans on the World Stage', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Chapter 5: Sports Scholarships and the Future', isSubheader: false },
    { id: '5.1', title: '5.1 The Importance of Scholarships', isSubheader: true },
    { id: '5.2', title: '5.2 How Sport Scholarships America Can Help', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };
  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Content
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Hide</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Show</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>


























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Softball Scholarships in the USA: How to Study and Play Softball in America
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Aerial view of a packed stadium at a college softball game."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        A full stadium during a game
      </figcaption>
    </figure>
    Softball is a sport that originated in the United States in the late 19th century. It is a variation of baseball but with a larger ball, a smaller field, and shorter innings. Softball is played by two teams of nine players, taking turns batting and defending. The objective is to score more runs than the opposing team by running around the four bases after hitting the ball.
    <br />
    <br />
    Softball quickly gained popularity in the USA, especially among schools and universities. It was seen as a sport that promoted physical fitness, mental sharpness, and social interaction. It also attracted a lot of talent and competition, particularly at the collegiate level. Collegiate softball represents the highest level of softball in the USA, where student-athletes compete for their university or college. Collegiate softball offers prestige, recognition, and scholarships for top players.
    <br />
    <br />
    Sportbeursamerika.nl is an organization dedicated to helping young athletes secure softball scholarships. Sportbeursamerika.nl has a team of experts who can guide you in finding the best university or college based on your academic and athletic profile. They can also assist you in preparing your application, creating your video, connecting with coaches, and arranging your visa. Sportbeursamerika.nl has already helped many success stories achieve their dream of studying and playing softball in America.
    <br />
    <br />
    If you want to learn more about softball in America and how to get a softball scholarship, keep reading!
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>

{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 1: History and Development of Softball in America
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Softball is a sport that originated in the United States but has spread worldwide. It is a sport that has undergone many changes and innovations, with numerous influential figures and events. In this chapter, we will provide you with an overview of the history and development of softball in America, from its early years to the present day.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Early Years and the Role of Schools and Universities
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Softball was first played in 1887 in Chicago as an indoor variant of baseball. It was conceived by a group of men celebrating Thanksgiving in a gymnasium. They used a boxing glove as the ball and a broomstick as the bat. The game quickly gained popularity among various groups of people, including firefighters, factory workers, and church members.
      <br />
      <br />
      In the early years of softball, there were no standardized rules or dimensions. The game was adapted to the available space and the number of players. There were various variants of softball, such as mushball, kittenball, diamond ball, and indoor baseball. The term "softball" was officially used in 1926.
      <br />
      <br />
      Schools and universities played a significant role in the development of softball. They viewed softball as a sport suitable for both boys and girls, promoting physical education and recreation. They also organized the first school and university teams and the first organized competitions. One of the first school competitions was the Public Schools Athletic League in New York City, which started softball for girls in 1913. One of the first university competitions was the National Collegiate Athletic Association (NCAA), which began women's softball in 1957.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Growth and Professionalization
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Softball grew into a popular and prestigious sport in America, especially among women. This was partly due to the introduction of Title IX in 1972, a law that guaranteed equal opportunities for women in education, including sports scholarships. This provided more female student-athletes with the opportunity to play softball at the collegiate level.
      <br />
      <br />
      In addition to collegiate softball, professional softball leagues emerged in America. The first professional women's softball league was the International Women’s Professional Softball Association (IWPSA), founded in 1976. This league consisted of ten teams from different cities, such as New York, Chicago, and Los Angeles. The league lasted for four seasons, until 1980.
      <br />
      <br />
      The second professional women's softball league was the Women’s Pro Softball League (WPSL), established in 1997. This league featured six teams from various states, including Florida, Georgia, and Virginia. The league later changed its name to National Pro Fastpitch (NPF). The NPF was active until 2021 and underwent two canceled seasons due to COVID-19. A new softball league, the Women's Professional Fastpitch (WPF), was launched in 2022.
      <br />
      <br />
      The professional softball leagues had a significant impact on collegiate softball. Many professional players were former student-athletes who continued their careers after graduation. Some professional players also became coaches or instructors for collegiate teams or individual players. Additionally, many professional players distinguished themselves as role models and sources of inspiration for young softball players.
      <br />
      <br />
      Some examples of key figures in the world of softball are:

    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Jennie Finch: A former pitcher for the Arizona Wildcats and the USA national team. She won two Olympic medals, gold in 2004 and silver in 2008. She also played for the Chicago Bandits in the NPF. She is considered one of the greatest pitchers of all time and an icon for the sport.
        </li>
        <li>
          Cat Osterman: A former pitcher for the Texas Longhorns and the USA national team. She also won two Olympic medals, gold in 2004 and silver in 2008. She played for the Rockford Thunder and the Houston Scrap Yard Dawgs in the NPF. She later became the coach of the Texas State Bobcats.
        </li>
        <li>
          Monica Abbott: A former pitcher for the Tennessee Volunteers and the USA national team. She won a silver Olympic medal in 2008. She later played for the Scrap Yard Fast Pitch in the NPF and the Toyota Red Terriers in Japan. She is the first female softball player to sign a million-dollar contract.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>

                                              





                                  







<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 2: University Softball Today
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Softball is one of the most popular and competitive sports at the university level in America. Every year, more than a thousand teams participate in the university softball season, which runs from February to June. The highlight of the season is the Women's College World Series (WCWS), the national championship for university softball.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Important Leagues and Conferences
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Players from Tennessee and Alabama in action during the College World Series."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
         }}>
          Players in action during the College World Series
        </figcaption>
      </figure>
      University softball is regulated by the National Collegiate Athletic Association (NCAA), an organization responsible for organizing and managing sports programs for universities and colleges in America. The NCAA divides universities into three divisions based on their size, budget, and athletic performance. These divisions are:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Division I: The highest and most prestigious division, with more than 300 universities and over 10,000 student-athletes. Division I offers the most scholarships, the best facilities, and the strongest competition. Division I consists of 32 conferences, regional groups of universities that play matches against each other. Some examples of conferences are the Southeastern Conference (SEC), the Pac-12 Conference, and the Big Ten Conference.
        </li>
        <li>
          Division II: The middle division, with more than 300 universities and over 8,000 student-athletes. Division II offers fewer scholarships but still provides good facilities and strong competition. Division II consists of 23 conferences, such as the Great Lakes Valley Conference (GLVC), the Sunshine State Conference, and the California Collegiate Athletic Association (CCAA).
        </li>
        <li>
          Division III: The lowest division, with more than 400 universities and over 6,000 student-athletes. Division III does not offer scholarships but provides strong academic programs and a sporting experience. Division III consists of 43 conferences, including the New England Small College Athletic Conference (NESCAC), the Centennial Conference, and the University Athletic Association (UAA).
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Each division has its own championship at the end of the season, where the best teams from each conference qualify for a knockout-style tournament. The championship of Division I is called the Women's College World Series (WCWS), held in Oklahoma City. The championship of Division II is called the NCAA Division II Softball Championship, held in Denver. The championship of Division III is called the NCAA Division III Softball Championship, held in Salem.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Top Programs and Prestigious Teams
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      University softball has produced many top programs and prestigious teams that have distinguished themselves through their successful history, talented players, and influential coaches. Some of these programs and teams include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          UCLA Bruins: This program is considered one of the most successful in the history of university softball, with an impressive number of national titles to their name. Over the years, they have produced Olympic medalists, including well-known names like Lisa Fernandez, Natasha Watley, and Stacey Nuveman.
        </li>
        <li>
          Oklahoma Sooners: The Oklahoma Sooners are a notably strong team and have consistently delivered top performances. They have won multiple national titles and have had impressive winning streaks in the past.
        </li>
        <li>
          Arizona Wildcats: The softball program of the Arizona Wildcats boasts an impressive list of national titles. They also have a respected coach, Mike Candrea, with an excellent track record of numerous victories and even Olympic gold as the coach of the U.S. national softball team.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      These programs and teams contribute to the development of softball players by offering them a high level of training, coaching, and competition. They also help players enhance their skills, knowledge, and mentality, preparing them for the next step in their careers.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  








<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 3: From College to the Pros
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Softball is a sport that offers many opportunities for student-athletes to continue their careers after their college period. Some student-athletes choose to play professional softball, while others explore other career possibilities. In this chapter, we discuss the path to professionalism, professional softball leagues, and other career options for softball players.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 The Path to Professionalism
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Playing college softball is good preparation for playing professional softball. Many student-athletes who excel in college softball are scouted or drafted by professional teams. This means they are offered a contract to play for a specific team, usually in exchange for a salary and other benefits.
      <br />
      <br />
      However, playing professional softball is not easy. The competition is very high, and players must constantly adapt to new situations, opponents, and challenges. Moreover, the salaries of professional softball players are not very high compared to other sports. According to a 2016 report, the average salary of an NPF player was approximately $5,000 to $6,000 per season. This means that many professional players need to have a second job or another source of income to make ends meet.
      <br />
      <br />
      Therefore, some student-athletes choose not to play professional softball but to complete their studies or pursue another career. This can also be a good choice because college softball develops many skills and qualities that are useful in other fields, such as leadership, teamwork, communication, discipline, and problem-solving.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professional Softball and Other Career Opportunities
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      As mentioned earlier, the main professional softball league in America is the National Pro Fastpitch (NPF). The NPF currently consists of five teams: the Aussie Peppers of Minnesota, the Canadian Wild of Southern Illinois, the Chicago Bandits, the Cleveland Comets, and the USSSA Pride. The NPF also has a partnership with the Chinese Softball Association (CSA), which sends two teams to the NPF: the Beijing Eagles and the Shanghai Shengli.
      <br />
      <br />
      However, the NPF is not the only professional softball league in the world. There are also other leagues in countries such as Japan, Australia, Italy, and Mexico. Some American players choose to play in these leagues because they are better paid or because they want to experience a different culture.
      <br />
      <br />
      Besides playing professional softball, there are other career opportunities for softball players. Some examples include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Coaching: Some players become coaches for school, college, or professional teams. They use their knowledge and experience to train, motivate, and advise other players.
        </li>
        <li>
          Education: Some players become teachers or professors at various levels and in various fields. They use their academic background and pedagogical skills to educate and guide students.
        </li>
        <li>
          Media: Some players become journalists, commentators, or analysts for various media outlets. They use their communication skills and their insight into the sport to report on softball news and events.
        </li>
        <li>
          Entrepreneurship: Some players become entrepreneurs or managers in different sectors and industries. They use their creative and business skills to develop and sell products or services.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      As you can see, there are many opportunities for softball players to use their passion and talent in different domains. The key is to set your goals, explore your options, and pursue your dreams.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  








<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 4: International Influences and Events
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Softball is not only an American sport but also an international one. Softball is played in more than 140 countries worldwide and has had a significant impact on the sports culture and development in various regions. In this chapter, we will discuss foreign influences on softball in America and American influences on softball on the world stage.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Foreign Influences
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Oklahoma University softball team celebrates winning the NCAA title."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Oklahoma players celebrate the national championship
        </figcaption>
      </figure>
      Softball in America owes much to the foreign influences that have enriched and challenged the sport. Some of these influences include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Japan: Japan is one of the strongest countries in softball, both at the professional and collegiate levels. Japan has a long tradition of softball dating back to the 1930s. Japan also has its own professional softball league, the Japan Softball League (JSL), consisting of 12 teams. Japan has had a significant impact on American softball by introducing new techniques, tactics, and playing styles. Japan also has many exchange programs and partnerships with American universities and teams, providing many players the opportunity to learn from each other.
        </li>
        <li>
          Australia: Australia is another country that plays a significant role in softball, especially at the international level. Australia has its own national softball team, the Aussie Spirit, which has won multiple medals at the Olympics, World Championships, and the World Cup of Softball. Australia also has many players who compete in the NPF, such as Stacey Porter, Kaia Parnaby, and Taylah Tsitsikronis. Australia has had a substantial influence on American softball by showcasing a high level of athleticism, perseverance, and teamwork.
        </li>
        <li>
          Canada: Canada, as a neighboring country to America, also has a strong softball culture. Canada has its own national softball team, the Canadian Wild, which also participates in the NPF. Canada also has many players who have played or currently play for American universities, such as Danielle Lawrie, Jenn Salling, and Sara Groenewegen. Canada has had a significant influence on American softball by sharing a common passion, vision, and appreciation for the sport.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      These foreign influences have contributed to the development of softball in America by creating more diversity, competition, and collaboration. They have also contributed to the development of collegiate players by exposing them to different cultures, perspectives, and experiences.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Americans on the World Stage
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Softball in America has also had a significant impact on softball on the world stage. America has been a leading country in softball for many years, both professionally and collegiately. America has produced many success stories and sources of inspiration for other countries and players.
      <br />
      <br />
      These success stories have had a significant influence on softball on the world stage by showcasing a high level of performance, excellence, and leadership. They have also gained a lot of respect and admiration from other countries and players, who see them as examples and challengers.
      <br />
      <br />
      The role of collegiate softball in preparing for international competitions is crucial. Collegiate softball provides players with a solid foundation of skills, knowledge, and experience that they can use to compete with the best players in the world. Collegiate softball also helps to discover, develop, and promote the talent and potential of players so that they can excel at the highest level.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  




<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 5: Sports Scholarships and the Future of Athletes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sports scholarships offer numerous opportunities for young athletes to showcase their talent and develop their skills. One of the most significant opportunities is obtaining a sports scholarship to study and compete at the college level. In this chapter, we will discuss the importance of sports scholarships and how Sport Scholarships America can help you make your dream come true.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 The Importance of Scholarships
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A sports scholarship is financial support provided by a university to a student-athlete to combine studies and sports. It can cover the costs of tuition, books, housing, meals, and other expenses. Additionally, it may offer benefits such as academic tutoring, medical care, training facilities, and equipment.
      <br />
      <br />
      Obtaining a sports scholarship is competitive, with many factors playing a role in the awarding process, including academic achievements, athletic performance, personality, motivation, leadership, and adaptability. Therefore, it's essential to prepare well and distinguish yourself from the competition.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 How Sport Scholarships America Can Help
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo of Sport Scholarships America, featuring sports equipment and a graduation cap, representing the focus on sports scholarships for student-athletes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo of Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America is an organization specialized in guiding young sports talents to sports scholarships. With passionate and experienced experts, Sport Scholarships America supports student-athletes at every step of the journey, from initial orientation to final placement. Their services include:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>A personal intake interview to discuss the student-athlete's wishes, possibilities, and expectations.</li>
        <li>A professional evaluation of the student-athlete's athletic and academic level.</li>
        <li>An extensive network of contacts with coaches, universities, and colleges.</li>
        <li>Effective promotion of the student-athlete's profile and videos to potential coaches.</li>
        <li>Expert advice on the best options based on the student-athlete's preferences, goals, and budget.</li>
        <li>Negotiation of the best scholarship offers on behalf of the student-athlete.</li>
        <li>Assistance in applying for necessary documents, such as visa, transcript, diploma, and admission tests.</li>
        <li>Preparation for student-athletes' life during their scholarship, including culture, language, rules, and tips.</li>
        <li>Aftercare and follow-up during the scholarship period.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Want to explore scholarship opportunities and achieve your athletic and academic goals? <Link to="/Services-Products">View the full range</Link> of services offered by Sport Scholarships America and schedule a consultation today.
    </MKTypography>
  </MKBox>
</MKBox>
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;