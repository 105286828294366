/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Jeugd.png";
import post3 from "assets/images/Deal.png";
import "../../../../H1asH3.css"


function Profile() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center"></MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox 
                  display="flex" 
                  flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} 
                  justifyContent="space-between" 
                  alignItems="center" 
                  mb={1}
              >
                  <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Part 2: My Time in America</MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text">
  <br />
    My time in America was like a breathtaking journey, filled with unforgettable experiences and personal growth. 
    Thanks to soccer, I traveled from coast to coast, accompanied by a group of teammates who quickly became some of my best friends. 
    Our days started early with intensive training sessions in the gym, something I was not accustomed to in the Netherlands. 
    Then quickly off to university for classes, and back to training in the afternoon. It was a daily commitment that challenged me both physically and mentally.
  <br /><br />
    The matches were highlights that thousands of fans flocked to, and each time it felt as if we were playing for something greater than ourselves. 
    The adrenaline of entering the field in front of such a passionate crowd is indescribable. Moreover, the trips we took for matches were unforgettable. 
    On the plane, we often spent hours playing cards, and in hotel lobbies, we shared hilarious moments and exciting conversations. 
    Restaurants with delicious dishes became our temporary dining spots, and these culinary adventures added an extra dimension to our travels. 
    Everything was meticulously arranged, from the cards on the plane to the restaurants where we dined. 
    These experiences allowed me to catch a glimpse of America's grandeur.
  <br /><br />
    Additionally, I had the opportunity to intern with leading professional clubs, including Seattle Sounders. 
    Sharing the field with players like America's all-time top scorer Clint Dempsey was nothing short of surreal, a highlight I will always cherish. 
    But like every adventure, this journey had its challenges. 
    In my final year, I faced a severe hamstring injury, temporarily halting my soccer ambitions. 
    This was a period of personal reflection and resilience, from which I emerged stronger.
  <br /><br />
    Moreover, my academic journey in America was equally important. Despite the intensive soccer training schedule and extensive travel, 
    I received perfect guidance to continue my studies. It wasn't an easy task, as I often missed classes due to my involvement with the soccer team. 
    Nevertheless, I was determined to succeed, and I did so with pride, graduating with highest honors, magna cum laude. 
    This academic success was a testament to my dedication to both my sport and my education.
  <br /><br />
    During my adventure in America, I experienced typical American parties that seemed straight out of a movie. 
    These vibrant social gatherings, filled with music, dance, and loud laughter, offered me the opportunity to grow, not just as an athlete and student, 
    but also as a social enthusiast. These parties added an extra dimension to my student life and enriched my social experiences.
  <br /><br />
    However, America meant more to me than just soccer, matches, social events, and academic achievements. 
    It was an opportunity to expand my social circle and make lifelong friendships. 
    Thanks to these friendships, I was able to discover the country in new ways. 
    From spring break in Mexico, where we enjoyed sun, sea, and beach, to celebrating Thanksgiving with friends' families, 
    whom I can now call my American family. Their warm welcome truly made me part of their lives, 
    and these experiences have made my time in America even richer.
  <br /><br />
  This period of my life deepened my love for both soccer and education and led me to the path I now follow to help others achieve their dreams in America. 
  Stay tuned, as in Part 3, I will share how my return to the Netherlands and the founding of my company took my life in a new direction.
</MKTypography>
              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="Part 1: Growing Up in the Netherlands"
                    action={{
                      type: "internal",
                      route: "/About-Me/Growing-Up",
                      color: "info",
                      label: "read more",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="Part 3: Back in the Netherlands"
                    action={{
                      type: "internal",
                      route: "/About-Me/Back-in-the-Netherlands",           
                      color: "info",
                      label: "read more",
                    }}
                  />
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;