/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState } from 'react'; // Step 1: Import useState


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";


// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Logos/NCAA.png";
import post3 from "assets/images/Logos/NJCAA.png";



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {

  const articleStructure = [
    { id: 'intro', title: 'La NAIA: Una Ruta Alternativa en el Deporte Universitario en América', isSubheader: false },
  
    { id: 'Capítulo 1', title: 'El Origen y Desarrollo de la NAIA', isSubheader: true },
    { id: '1.1', title: 'La NAIA y sus Divisiones Únicas', isSubheader: true },
    { id: '1.2', title: 'La Misión Académica', isSubheader: true },
  
    { id: 'Capítulo 2', title: 'Finanzas, Medios y Visibilidad', isSubheader: true },
    { id: '2.1', title: 'Desafíos y el Camino a Seguir', isSubheader: true },
    { id: '2.2', title: 'En Conclusión', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
     <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contents
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Ocultar</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="intro" mb={3}>
              <MKTypography variant="h1" className="h1-as-h3" mb={2}>La NAIA: Una Ruta Alternativa en el Deporte Universitario en América

</MKTypography>
              </MKBox>
              <MKBox id="Hoofdstuk 1" mb={3}>

              <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              El Origen y Desarrollo de la NAIA
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              La Asociación Nacional de Atletismo Interuniversitario, conocida como la NAIA, es una entidad crucial en el panorama deportivo universitario estadounidense, aunque menos conocida que la NCAA. Fundada en 1937, la NAIA ha evolucionado hasta convertirse en una organización de renombre que ofrece a los estudiantes-atletas una oportunidad única para cumplir sus aspiraciones deportivas y académicas.

<br />
                <br />
                Desde el principio, la NAIA se ha centrado en brindar oportunidades a instituciones educativas más pequeñas, con énfasis en crear una experiencia equilibrada para los estudiantes-atletas. La organización promueve tanto deportes competitivos como excelencia académica, con la convicción de que ambos pueden ir de la mano.

</MKTypography>
<MKBox id="1.1" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
                <br />
                La NAIA y sus Divisiones Únicas
                </MKTypography>
                </MKBox>

                <MKTypography variant="body1" fontWeight="light" color="text"> 
                A diferencia de la NCAA, la NAIA está más compactamente organizada, con una división en dos divisiones, ambas ofreciendo una rica mezcla de deportes y educación:

<br /><br />
  <MKTypography component="span" variant="body1" fontWeight="bold">
    División I:{' '}
  </MKTypography>
  En esta división encontramos escuelas que ofrecen un nivel más alto de becas atléticas y donde la competencia deportiva es muy competitiva. A pesar del tamaño más pequeño de las escuelas en comparación con las instituciones de la División I de la NCAA, la División I de la NAIA proporciona un escenario para que los mejores atletas muestren sus habilidades y se desarrollen.
  <br />
  <br />
  <MKTypography component="span" variant="body1" fontWeight="bold">
    División II:{' '}
  </MKTypography>
  Esta división pone aún más énfasis en el equilibrio entre deportes, académicos y participación en el campus. Las becas atléticas son más limitadas, pero las oportunidades para que los estudiantes-atletas sobresalgan tanto en su deporte como en sus estudios son abundantes.
  </MKTypography>

  <MKBox id="1.2" mb={3}>

  <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              La Misión Académica
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              La NAIA valora mucho los logros académicos de sus estudiantes-atletas. Se han establecido estrictas normas académicas para garantizar la participación en deportes, y las escuelas afiliadas están dedicadas a proporcionar el apoyo académico necesario. Esto resulta en un ambiente donde se anima a los estudiantes-atletas a esforzarse por la excelencia en cada aspecto de sus vidas.

                <br />
                </MKTypography>
            





                                  








                <MKBox id="Hoofdstuk 2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Finanzas, Medios y Visibilidad
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
Aunque la NAIA puede que no pueda competir financieramente con los gigantescos ingresos de la NCAA, sigue desempeñando un papel importante en el financiamiento de becas deportivas y la mejora de instalaciones. Además, la NAIA gana visibilidad gracias a los servicios de streaming y las redes sociales, lo que ayuda a difundir el mensaje de un equilibrio entre el deporte y lo académico.


<br />
</MKTypography>
<MKBox id="2.1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Desafíos y el Camino a Seguir
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
Al igual que la NCAA, la NAIA enfrenta desafíos, incluida la navegación a través de la complejidad del estatus amateur de los estudiantes atletas y garantizar la igualdad en el deporte. Sin embargo, con su posición única y su dedicación al desarrollo de estudiantes atletas, la NAIA está bien posicionada para seguir teniendo un impacto positivo en la vida de muchos jóvenes atletas.

</MKTypography>
<MKBox id="2.2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
En Conclusión
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
La Asociación Nacional de Atletismo Interuniversitario ofrece una alternativa valiosa y única en el mundo del deporte universitario estadounidense. Con su énfasis en el equilibrio, la excelencia académica y la competencia atlética, la NAIA sigue siendo una fuerza esencial en la formación del futuro de los estudiantes atletas. Mientras mira hacia adelante y continúa evolucionando, su compromiso con el desarrollo de jóvenes sigue siendo inquebrantable, lo que le permite tener un impacto duradero y positivo en el mundo del deporte y la educación en Estados Unidos.
<br />


</MKTypography>


              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="NCAA"
                    description="NCAA: El Corazón del Deporte Universitario en Estados Unidos"
                    action={{
                      type: "internal",
                      route: "/es/NCAA",
                      color: "info",
                      label: "Leer Más",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="NJCAA"
                    description="NJCAA: La Puerta de Entrada a Grandes Universidades y la NCAA"
                    action={{
                      type: "internal",
                      route: "/es/NJCAA",
                      color: "info",
                      label: "Leer Más",
                    }}
                  />
                </Grid>
              </Grid>

            </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;