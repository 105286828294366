/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 PRO React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';

// Pages
import Faq from "Languages/Deutsch/pages/Contact/FAQs";
import Author from "Languages/Deutsch/pages/Over/Overmij/Profiel";
import VirtualRealityPage from "Languages/Deutsch/pages/Content/Podcast";
import AllSports from "Languages/Deutsch/pages/All Sports/Overzicht";
import Contactformulier from "Languages/Deutsch/pages/Contact/Contactformulier"
import Jeugd from "Languages/Deutsch/pages/Over/Overmij/Jeugd";
import Amerika from "Languages/Deutsch/pages/Over/Overmij/Amerika";
import NaUSA from "Languages/Deutsch/pages/Over/Overmij/NaUSA";
import DienstenProducten from "Languages/Deutsch/pages/Aanbod/Diensten_producten";

import Timeline from "Languages/Deutsch/pages/Aanbod/Traject";
import AmericanFootball from "Languages/Deutsch/pages/All Sports/Sports/Baseball";
import Athletics from "Languages/Deutsch/pages/All Sports/Sports/Track and Field";
import Basketball from "Languages/Deutsch/pages/All Sports/Sports/Basketball";
import Golf from "Languages/Deutsch/pages/All Sports/Sports/Golf";
import Hockey from "Languages/Deutsch/pages/All Sports/Sports/Field Hockey";
import Baseball from "Languages/Deutsch/pages/All Sports/Sports/Baseball";
import Icehockey from "Languages/Deutsch/pages/All Sports/Sports/Ice Hockey";
import Rowing from "Languages/Deutsch/pages/All Sports/Sports/Rowing";
import Softbal from "Languages/Deutsch/pages/All Sports/Sports/Softbal";
import Tennis from "Languages/Deutsch/pages/All Sports/Sports/Tennis";
import Gymnastics from "Languages/Deutsch/pages/All Sports/Sports/Gymnastics";
import Soccer from "Languages/Deutsch/pages/All Sports/Sports/Soccer";
import Volleyball from "Languages/Deutsch/pages/All Sports/Sports/Volleyball";
import Waterpolo from "Languages/Deutsch/pages/All Sports/Sports/Waterpolo";
import Wrestling from "Languages/Deutsch/pages/All Sports/Sports/Wrestling";
import Swimming from "Languages/Deutsch/pages/All Sports/Sports/Swimming";
import CompanyTimeline from "Languages/Deutsch/pages/Over/Over Sport Scholarships America";
import Kenniscentrum from "Languages/Deutsch/pages/Content/Kenniscentrum";





const routes = [
  {
    name: "Sportaten",
    icon: <SportsSoccerIcon></SportsSoccerIcon>,
    collapse: [
      {
        name: "All Sportaten",
        description: "Entdecke alle Sportarten",
        route: "/de/Alle-Sportarten",
        component: <AllSports />,
        dropdown: true,
        collapse: [
          {
            name: "American Football",
            route: "/de/Alle-Sportarten/American-Football",
            component: <AmericanFootball />,
          },
          {
            name: "Baseball",
            route: "/de/Alle-Sportarten/Baseball",
            component: <Baseball />,
          },
          {
            name: "Basketball",
            route: "/de/Alle-Sportarten/Basketball",
            component: <Basketball />,
          },
          {
            name: "Eishockey",
            route: "/de/Alle-Sportarten/Eishockey",
            component: <Icehockey />,
          },
          {
            name: "Fußball",
            route: "/de/Alle-Sportarten/Fußball",
            component: <Soccer />,
          },
          {
            name: "Golf",
            route: "/de/Alle-Sportarten/Golf",
            component: <Golf />,
          },
          {
            name: "Hockey",
            route: "/de/Alle-Sportarten/Hockey",
            component: <Hockey />,
          },
          {
            name: "Leichtathletik",
            route: "/de/Alle-Sportarten/Leichtathletik",
            component: <Athletics />,
          },
          {
            name: "Ringen",
            route: "/de/Alle-Sportarten/Ringen",
            component: <Wrestling />,
          },
          {
            name: "Rudern",
            route: "/de/Alle-Sportarten/Rudern",
            component: <Rowing />,
          },
          {
            name: "Schwimmen",
            route: "/de/Alle-Sportarten/Schwimmen",
            component: <Swimming />,
          },
          {
            name: "Softball",
            route: "/de/Alle-Sportarten/Softball",
            component: <Softbal />, // Note: Typo in component name `<Softbal />`, should it be `<Softball />`?
          },
          {
            name: "Tennis",
            route: "/de/Alle-Sportarten/Tennis",
            component: <Tennis />,
          },
          {
            name: "Turnen",
            route: "/de/Alle-Sportarten/Turnen",
            component: <Gymnastics />,
          },
          {
            name: "Volleyball",
            route: "/de/Alle-Sportarten/Volleyball",
            component: <Volleyball />,
          },
          {
            name: "Wasserball",
            route: "/de/Alle-Sportarten/Wasserball",
            component: <Waterpolo />,
          },
        ]
        
      },
   

],
  },     

    {
      name: "Über",
      icon: <Icon>person</Icon>,
    
      collapse: [
        {
          name: "Über Mich",
          description: "Eine Einführung",
          route: "/de/Über-Mich",
          component: <Author />,
        
          dropdown: true,
          collapse: [
            {
              name: "Teil 1: Aufwachsen in den Niederlanden",
              route: "/de/Über-Mich/Aufwachsen",
              component: <Jeugd />,
            },

            {
              name: "Teil 2: Meine Zeit in Amerika",
              route: "/de/Über-Mich/Meine-Zeit-in-Amerika",
              component: <Amerika />,
            },

            {
              name: "Teil 3: Meine Rückkehr in die Niederlande",
              route: "/de/Über-Mich/Meine-Rückkehr-in-die-Niederlande",
              component: <NaUSA />,
            },
             ],
         },
         {
          name: "Über Sport Scholarships America",
          description: "Firmengeschichte",
          route: "/de/Über-Sport-Scholarships-America",
          component: <CompanyTimeline />,
        
          dropdown: false,
          
         },
    ],
},    
 
  {
    name: "Angebot",
    icon: <Icon>monetization_on</Icon>,
    collapse: [
      {
        name: "Dienstleistungen & Produkte",
        description: "Alle Dienstleistungen und Produkte entdecken",
        route: "/de/Dienstleistungen-Produkte",
        component: <DienstenProducten />,
        dropdown: false,
      
      },
      {
        name: "Prozess",
        description: "Prozess Übersicht",
        route: "/de/Prozess",
        component: <Timeline />,
        dropdown: false,
       
      },
      
    ],
  },
  {
    name: "Material",
    icon: <Icon>article</Icon>,
    collapse: [
      {
        name: "Podcast",
        route: "/de/Podcast",
        description: "Hören Sie meinen Podcast",
        component: <VirtualRealityPage />
      },
      {
        name: "Wissenszentrum",
        route: "/de/Wissenszentrum",
        description: "Hilfreiche Artikel und Links",
        component: <Kenniscentrum />
      },
     
    ],
  },
  {
    name: "Kontakt",
    icon: <Icon>mail</Icon>,
    collapse: [
      {
        name: "FAQ",
        description:"Antworten auf häufig gestellte Fragen",
        route: "/de/FAQs",
        component: <Faq />,
      },
      {
        name: "Kontaktformular",
        route: "/de/Kontaktformular",
        component: <Contactformulier />,
        description: "Kontakt aufnehmen",
      },
  
     
    ],
  },
];

export default routes;
