/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Baseball1.jpg";
import Image2 from "assets/images/Sports_Images/Baseball2.jpeg";
import Image3 from "assets/images/Sports_Images/Baseball3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';

import "../../../../H1asH3.css"

const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Baseball og Studier i Amerika: En Drøm, Der Kan Blive Virkelighed', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapitel 1: Historie og Udvikling af College Baseball', isSubheader: false },
    { id: '1.1', title: '1.1 Tidlige År og Kollegiernes Rolle', isSubheader: true },
    { id: '1.2', title: '1.2 Vækst og Professionalisering', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapitel 2: College Baseball i dag', isSubheader: false },
    { id: '2.1', title: '2.1 Store Ligaer og Konferencer', isSubheader: true },
    { id: '2.2', title: '2.2 Topprogrammer og Prestigefyldte Hold', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapitel 3: Fra College til Professionelt Niveau', isSubheader: false },
    { id: '3.1', title: '3.1 Vejen til Professionalisme', isSubheader: true },
    { id: '3.2', title: '3.2 MLB Draft og Andre Professionelle Muligheder', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapitel 4: Internationale Indflydelser', isSubheader: false },
    { id: '4.1', title: '4.1 Udenlandske Spillere i College Baseball', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanere i Udlandet', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapitel 5: Sportslegater og fremtiden for atleter', isSubheader: false },
    { id: '5.1', title: '5.1 Vigtigheden af legater', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Legater Amerika Kan Hjælpe', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Baseball og Studier i Amerika: En Drøm, Der Kan Blive Virkelighed
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Fugleperspektiv af stadionet under College World Series."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        College World Series
      </figcaption>
    </figure>
    Baseball er en af de mest populære og betydningsfulde sportsgrene i USA. Millioner af mennesker følger Major League Baseball (MLB), den højeste professionelle baseballliga i verden. Men før du kan nå MLB, skal du først udvikle og bevise dine færdigheder på et andet niveau: college baseball.
    <br />
    <br />
    College baseball er baseball spillet af studerende på amerikanske universiteter og colleges. Det er en yderst konkurrencedygtig og prestigefyldt sport, der tiltrækker meget opmærksomhed og talent. College baseball fungerer som et afgørende skridt for spillere, der ønsker at spille professionelt, og også som en mulighed for at modtage en god uddannelse.
    <br />
    <br />
    Men hvordan kommer du ind på college baseball? Og hvordan kan du få et legat til at studere og spille i USA? Det er her, Sportbeursamerika.nl kan hjælpe dig. Sportbeursamerika.nl er en organisation specialiseret i at vejlede og støtte studerende-atleter, der ønsker at opfylde deres drømme. Jeg kan hjælpe dig med alt hvad du har brug for at finde et passende universitet, opnå et legat og forberede dig på livet i USA.
    <br />
    <br />
    I denne artikel vil vi fortælle dig alt hvad du har brug for at vide om college baseball i USA. Vi vil dykke ned i historien og udviklingen af denne sport, de vigtigste ligaer og konferencer, topprogrammer og prestigefyldte hold, vejen til de professionelle, internationale indflydelser, og selvfølgelig vigtigheden af legater. Efter at have læst denne artikel vil du have en bedre forståelse af, hvad college baseball indebærer, og hvordan du kan øge dine chancer for at være en del af det.
    <br />
    <br />
    Er du klar til at få din drøm til at gå i opfyldelse? Lad os komme i gang!
  </MKTypography>
</MKBox>




<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Historie og Udvikling af College Baseball
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Baseball er en sport, der har eksisteret i over et århundrede. Men hvordan opstod den og voksede for at blive en af de mest populære og betydningsfulde sportsgrene i USA? I dette kapitel vil vi give dig et kort overblik over historien og udviklingen af college baseball.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidlige År og Kollegiernes Rolle
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Oprindelsen af baseball er ikke helt klar. Der er forskellige teorier om, hvordan og hvor spillet begyndte. Nogle siger, at det nedstammer fra gamle europæiske spil som cricket, rounders eller stoolball. Andre hævder, at det stammer fra et Native American spil kaldet lacrosse. Andre igen foreslår, at det blev opfundet af en amerikansk soldat ved navn Abner Doubleday i 1839.
      <br />
      <br />
      Det, der er sikkert, er, at baseball hurtigt blev populært i USA, især i byer på Østkysten. Det blev spillet af amatører, klubber, fabriksarbejdere og soldater. Det blev også spillet af studerende på universiteter og colleges, der havde deres egne hold og regler.
      <br />
      <br />
      Det første dokumenterede interkollegiale baseballkamp i USA fandt sted i 1859 mellem Amherst College og Williams College, to respekterede akademiske institutioner i Massachusetts. Denne kamp er historisk betydningsfuld som et af de tidligste eksempler på college baseballkampe, en forløber for senere udvikling af college baseball. Selvom det præcise resultat og forløb af kampen kan variere i historiske kilder, markerer dette begivenheden et vigtigt udgangspunkt i historien om interkollegial baseball. Det illustrerer, hvordan baseball blev populært blandt studerende i de tidlige dage, og hvordan universiteter blev vigtige steder for sportens vækst og udvikling i USA.
      <br />
      <br />
      I 1860'erne og 1870'erne voksede antallet af kollegiehold støt. Nogle af de tidligste hold inkluderede Harvard, Yale, Princeton, Dartmouth, Brown og Columbia. Disse hold spillede primært mod hinanden eller lokale klubber.
      <br />
      <br />
      I 1879 blev den første interkollegiale baseballkonference afholdt i Springfield, Massachusetts. Målet var at standardisere og forbedre reglerne og standarderne for spillet. Konferencen førte til dannelse af American College Baseball Association, med seks prestigefyldte uddannelsesinstitutioner - Harvard, Yale, Princeton, Amherst, Dartmouth og Brown - som en del af den. Dette markerede begyndelsen på en lang tradition for nationale mesterskaber i college baseball.
    </MKTypography>
  </MKBox>

  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Vækst og Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      I slutningen af ​​1800-tallet og begyndelsen af 1900-tallet blev baseball stadig mere populært og professionelt i USA. National League (NL) og American League (AL) blev etableret som de vigtigste professionelle baseballligaer, hvilket tiltrak mange spillere, fans og penge.
      <br />
      <br />
      Dette havde også en indvirkning på college baseball. Mange college-spillere blev rekrutteret eller underskrevet af professionelle hold. Nogle forlod deres universiteter for at spille professionelt, mens andre fortsatte deres studier, men spillede også deltid for professionelle hold.
      <br />
      <br />
      Dette førte til forskellige problemer og konflikter i college baseball. Nogle universiteter var imod ideen om, at deres spillere blev betalt for at spille, da de betragtede det som modsat den amatørånd og akademiske integritet i kollegiale sportsgrene. De ønskede også at beholde deres bedste spillere til deres egne hold.
      <br />
      <br />
      Andre universiteter var mere tolerante eller pragmatiske over for fænomenet med professionelle spillere. De anerkendte, at baseball var en lukrativ og prestigefyldt sport, der kunne give mange fordele til deres institutioner. De ønskede også at konkurrere med andre universiteter på det højeste niveau.
      <br />
      <br />
      For at tackle disse problemer blev der truffet forskellige foranstaltninger af forskellige organisationer. IBA blev erstattet af National Collegiate Athletic Association (NCAA) i 1906. NCAA påtog sig ansvaret for at regulere og organisere college baseball og andre sportsgrene. NCAA etablerede også regler og retningslinjer for berettigelse og adfærd af college-spillere.
      <br />
      <br />
      NCAA organiserede også den første College World Series (CWS) i 1947. Dette var det nye nationale mesterskab for college baseball, afholdt årligt i Omaha, Nebraska. CWS blev hurtigt en af ​​de mest prestigefyldte og populære begivenheder inden for kollegiale sportsgrene.
      <br />
      <br />
      Ud over NCAA var der andre organisationer involveret i college baseball. En af dem var National Association of Intercollegiate Athletics (NAIA), etableret i 1937. NAIA var et alternativ til NCAA, der fokuserede på mindre universiteter og colleges. NAIA organiserede også sit eget nationale mesterskab for college baseball.
      <br />
      <br />
      En anden organisation var National Junior College Athletic Association (NJCAA), grundlagt i 1938. NJCAA var en organisation for community colleges, der tilbød toårige programmer. NJCAA organiserede også sit eget nationale mesterskab for college baseball.
      <br />
      <br />
      Disse organisationer bidrog alle til væksten og professionaliseringen af college baseball. De skabte flere muligheder og mangfoldighed for spillere, trænere, skoler og fans. De gjorde også college baseball til en integreret del af det amerikanske baseballsystem.
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  






<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: College Baseball i dag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College baseball er en af de mest populære og betydningsfulde sportsgrene i USA i dag. Den tiltrækker millioner af fans, medieopmærksomhed og sponsorer. Den producerer tusinder af spillere, trænere og officials. Den tilbyder hundreder af legater, priser og æresbevisninger.
    <br />
    <br />
    Men hvordan ser college baseball ud i dag? Hvordan er systemet organiseret? Hvad er de store ligaer og konferencer? Hvilke er de bedste programmer og prestigefyldte hold? I dette kapitel vil vi give dig et overblik over college baseball i dag.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Store Ligaer og Konferencer
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Som vi så i det forrige kapitel, er flere organisationer involveret i college baseball. Den vigtigste af disse er NCAA, der repræsenterer mere end 1.200 universiteter og colleges. NCAA er opdelt i tre divisioner: Division I, Division II og Division III.
      <br />
      <br />
      Division I er det højeste niveau af college baseball og består af cirka 300 skoler, opdelt i 31 konferencer. Hver konference har sine egne regler, tidsplaner og mesterskaber. De mest kendte konferencer inkluderer Atlantic Coast Conference (ACC), Big Ten Conference (B1G), Big 12 Conference (B12), Pac-12 Conference (P12) og Southeastern Conference (SEC).
      <br />
      <br />
      Division II er det mellemste niveau af college baseball og består af cirka 270 skoler, opdelt i 23 konferencer. Konferencer er typisk regionalt orienteret, såsom California Collegiate Athletic Association (CCAA), Gulf South Conference (GSC) og Northeast-10 Conference (NE10).
      <br />
      <br />
      Division III er det laveste niveau af college baseball og består af cirka 380 skoler, opdelt i 43 konferencer. Konferencer er også typisk regionalt orienteret, såsom New England Small College Athletic Conference (NESCAC), Southern California Intercollegiate Athletic Conference (SCIAC) og Wisconsin Intercollegiate Athletic Conference (WIAC).
      <br />
      <br />
      Ud over NCAA er der andre organisationer involveret i college baseball. En af dem er NAIA, der repræsenterer cirka 190 skoler. NAIA er opdelt i 21 konferencer, såsom American Midwest Conference (AMC), Golden State Athletic Conference (GSAC) og Heart of America Athletic Conference (HAAC).
      <br />
      <br />
      En anden organisation er NJCAA, der repræsenterer cirka 500 community colleges. NJCAA er opdelt i tre divisioner: Division I, Division II og Division III. Hver division er yderligere opdelt i distrikter og regioner.
      <br />
      <br />
      Disse organisationer afholder hvert år deres egne nationale mesterskaber i college baseball. NCAA Division I-mesterskabet er det mest kendte og prestigefyldte arrangement, ofte omtalt som College World Series (CWS).
      <br />
      <br />
      CWS består af en double-elimination-turnering med 64 hold udvalgt baseret på deres præstation i grundspillet og konference-mesterskaberne. Holdene er opdelt i 16 regionale grupper med fire hold hver. Vinderne af hver regional gruppe avancerer til super regional-runden, hvor de konkurrerer mod et andet hold i en bedst-af-tre-serie. Vinderne af hver super regional-runde går videre til CWS-finalen, der består af to grupper med fire hold hver. Vinderne af hver gruppe mødes i en bedst-af-tre-serie for at afgøre den nationale mester.
      <br />
      <br />
      CWS-finalen afholdes årligt i juni i Omaha, Nebraska. Det betragtes som en af de største og mest spændende begivenheder inden for kollegiale sportsgrene og tiltrækker tusinder af fans, medier og spejdere.
      <br />
      <br />
      NCAA Division II og Division III-mesterskaberne følger en lignende format, men med færre hold. NAIA og NJCAA-mesterskaberne har også deres egne formater, der varierer afhængigt af antallet af deltagende hold.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Topprogrammer og Prestigefyldte Hold
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      College baseball er en yderst konkurrencedygtig og prestigefyldt sport med en rig tradition og talentpulje. Der er mange programmer og hold, der adskiller sig gennem deres succes og omdømme. Nogle af disse programmer og hold inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Texas Longhorns: Baseballholdet på University of Texas i Austin, der konkurrerer i Big 12 Conference. Dette program har en imponerende track record med talrige optrædener i College World Series og flere mesterskaber. Nogle bemærkelsesværdige alumner inkluderer Roger Clemens, Burt Hooton og Huston Street.
        </li>
        <li>
          LSU Tigers: Baseballholdet på Louisiana State University i Baton Rouge, aktiv i SEC. LSU er kendt for sin dominans inden for college baseball og har vundet flere mesterskaber. Nogle bemærkelsesværdige alumner inkluderer Albert Belle, Aaron Hill og DJ LeMahieu.
        </li>
        <li>
          USC Trojans: Baseballholdet på University of Southern California i Los Angeles, medlem af Pac-12 Conference. USC har en rig tradition med talrige mesterskaber og har produceret mange talentfulde spillere. Nogle kendte alumner inkluderer Tom Seaver, Randy Johnson og Mark McGwire.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Disse er blot nogle få eksempler på topprogrammer og prestigefyldte hold inden for college baseball. Der er mange flere programmer og hold, hver med sin egen historie, kultur og identitet.
      <br />
      <br />
      Disse programmer og hold bidrager til udviklingen af spillere på forskellige måder. De giver dem en høj grad af træning, faciliteter og konkurrence. De hjælper også med at opnå deres akademiske, sportslige og personlige mål.
    </MKTypography>
  </MKBox>
</MKBox>






                                  






<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Fra College til Professionelt Niveau
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College baseball er ikke bare en sport; det er også en vej til professionalisme. Mange college baseballspillere har ambitionen om at spille professionelt, enten i MLB eller andre professionelle ligaer. Men hvordan ser den vej ud? Hvordan kan man gå fra college til det professionelle niveau? I dette kapitel vil vi give dig et overblik over vejen til professionalisme inden for baseball.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Vejen til Professionalisme
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vejen til professionalisme inden for baseball begynder normalt med MLB Draft. MLB Draft er en årlig begivenhed, hvor de 30 MLB-hold skiftes til at vælge spillere fra forskellige kilder, herunder college baseball, high school baseball, international baseball og independent baseball.
      <br />
      <br />
      MLB Draft består af 40 runder, hvor hvert hold har lov til at vælge en spiller pr. runde. Rækkefølgen af valg bestemmes af det omvendte stilling fra den foregående MLB-sæson, med nogle undtagelser for kompensationsvalg og lodtrækningsvalg.
      <br />
      <br />
      MLB Draft er en betydelig mulighed for college baseballspillere for at starte deres professionelle karriere. Cirka 1.200 spillere bliver draftet i MLB Draft hvert år, hvoraf cirka 60% kommer fra college baseball.
      <br />
      <br />
      Hvis du bliver valgt i MLB Draft, har du to muligheder: Du kan underskrive en kontrakt med det hold, der valgte dig, eller du kan vælge ikke at underskrive og vende tilbage til din universitet eller en anden kilde.
      <br />
      <br />
      Hvis du vælger at underskrive en kontrakt, vil du modtage en underskrivelsesbonus baseret på den runde, du blev valgt i. Bonuser varierer fra flere millioner dollars for første runde til flere tusind dollars for senere runder.
      <br />
      <br />
      Når du underskriver en kontrakt, vil du blive tildelt en af de minor league-hold fra det hold, der valgte dig. Minor leagues er de lavere niveauer af professionel baseball og fungerer som et udviklingssystem for MLB. Der er seks niveauer i minor leagues: Rookie, Class A Short Season, Class A, Class A Advanced, Double-A og Triple-A.
      <br />
      <br />
      Mens du spiller i minor leagues, skal du forbedre dine færdigheder og vise din præstation for at avancere til højere niveauer. Du skal også konkurrere med andre spillere, der deler den samme drøm: at nå til major leagues.
      <br />
      <br />
      At nå major leagues er det ultimative mål for enhver professionel baseballspiller. Det er det højeste niveau af professionel baseball, hvor du kan konkurrere mod verdens bedste spillere, foran de største fans og for de højeste lønninger.
      <br />
      <br />
      Dog er det ikke let at nå major leagues. Kun cirka 10% af de spillere, der bliver draftet i MLB Draft, når til sidst major leagues, og kun cirka 1% af dem, der bliver draftet, har lange og succesrige karrierer i major leagues.
      <br />
      <br />
      Så vejen til professionalisme inden for baseball er en lang og udfordrende rejse, der kræver dedikation, hårdt arbejde og lidt held. Men det er også en vej, der kan tilbyde mange belønninger, muligheder og drømme.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 MLB Draft og Andre Professionelle Muligheder
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      MLB Draft er ikke den eneste måde at spille professionelt i baseball på. Der er også andre professionelle muligheder uden for MLB, der kan være attraktive for college baseballspillere.
      <br />
      <br />
      En af dem er den uafhængige liga. Den uafhængige liga er en professionel baseballliga, der ikke er tilknyttet MLB eller minor leagues. Den uafhængige liga består af forskellige ligaer, såsom Atlantic League, American Association og Frontier League.
      <br />
      <br />
      Den uafhængige liga giver en alternativ mulighed for spillere, der ikke bliver draftet i MLB Draft eller ikke modtager kontrakttilbud fra et MLB-hold. Den uafhængige liga tilbyder også en chance for spillere, der bliver frigivet fra minor leagues eller ønsker at forlænge deres karriere.
      <br />
      <br />
      Den uafhængige liga har et lavere niveau af spil og løn i forhold til MLB eller minor leagues, men den tilbyder også et højere niveau af frihed og fornøjelse. Den uafhængige liga er kendt for sin kreativitet, mangfoldighed og eksperimenter. Den har også produceret nogle spillere, der senere nåede til major leagues, såsom Jose Bautista, Rich Hill og Max Muncy.
      <br />
      <br />
      En anden professionel mulighed er den internationale liga. Den internationale liga er en samlebetegnelse for professionelle baseballligaer, der spilles uden for USA. Den internationale liga består af forskellige ligaer, såsom Nippon Professional Baseball (NPB) i Japan, Korean Baseball Organization (KBO) i Sydkorea, Chinese Professional Baseball League (CPBL) i Taiwan og Mexican Baseball League (LMB) i Mexico.
      <br />
      <br />
      Den internationale liga tilbyder en mulighed for spillere, der ønsker at udvide deres horisonter og opleve en anden kultur. Den internationale liga giver også en mulighed for spillere, der søger et højere niveau af spil og løn end den uafhængige liga, men måske ikke kan nå til MLB eller minor leagues.
      <br />
      <br />
      Dette er blot nogle få eksempler på professionelle muligheder uden for MLB. Der er mange flere muligheder, såsom vinterligaer, college sommerligaer og amatør-ligaer.
      <br />
      <br />
      Disse muligheder tilbyder alle forskellige fordele og ulemper for college baseballspillere. De giver forskellige niveauer af konkurrence, belønning, udfordring og fornøjelse. De tilbyder forskellige veje til professionalisme inden for baseball.
    </MKTypography>
  </MKBox>
</MKBox>






                                  






<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationale Indflydelser
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College baseball er ikke kun en amerikansk sport; det er også en international sport påvirket af spillere, trænere og fans fra forskellige lande og kulturer. I dette kapitel vil vi give dig et overblik over de internationale indflydelser i college baseball.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Udenlandske Spillere i College Baseball
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="USC baseball team celebrating a victory."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          USC-spillere fejrer en sejr
        </figcaption>
      </figure>
      En af de mest betydningsfulde internationale indflydelser i college baseball er tilstedeværelsen af udenlandske spillere. Udenlandske spillere er spillere, der ikke er født i USA eller ikke har amerikansk statsborgerskab. De kommer fra forskellige lande, såsom Canada, Mexico, Japan, Sydkorea, Taiwan, Cuba, Venezuela, Den Dominikanske Republik og Nederlandene.
      <br />
      <br />
      Udenlandske spillere spiller en vigtig rolle i college baseball. De bringer deres egne færdigheder, stilarter og perspektiver til spillet. De beriger mangfoldigheden og kvaliteten af college baseball. De øger også den internationale anerkendelse og tiltrækningskraft af college baseball.
      <br />
      <br />
      Der er mange succeshistorier om udenlandske spillere, der har klaret sig godt i den amerikanske college baseball-verden. Et fremtrædende eksempel er Nomar Garciaparra. Nomar Garciaparra var en mexicansk-amerikansk shortstop, der spillede for Georgia Institute of Technology. Han blev valgt i første runde af MLB Draft i 1994 af Boston Red Sox. Han spillede 14 sæsoner i MLB, hvor han blev udvalgt til All-Star seks gange og vandt AL battingtitlen to gange. Der er mange flere eksempler på udenlandske spillere, der har gjort sig bemærket i college baseball.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikanere i Udlandet
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      En anden international indflydelse i college baseball er tilstedeværelsen af amerikanere i udlandet. Amerikanere i udlandet er spillere, der er født i USA eller har amerikansk statsborgerskab, men spiller eller har spillet i professionelle ligaer uden for USA.
      <br />
      <br />
      Amerikanere i udlandet spiller også en betydelig rolle i college baseball. De bringer deres egne erfaringer, viden og netværk til spillet. De lærer også fra andre kulturer og stilarter af baseball. De forbedrer international udveksling og samarbejde i college baseball.
      <br />
      <br />
      Disse muligheder tilbyder mange fordele for amerikanske college baseballspillere. De giver et højere niveau af konkurrence, eksponering og belønning. De hjælper også med at udvide deres horisonter, øge tilpasningsevnen og udvide deres netværk.
      <br />
      <br />
      International erfaring kan være gavnlig for en spillers udvikling. Det kan hjælpe dem med at lære nye færdigheder, teknikker og strategier. Det kan også hjælpe dem med at forbedre deres svagheder, udnytte deres styrker og maksimere deres potentiale.
    </MKTypography>
  </MKBox>
</MKBox>






                                  




<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Sportslegater og fremtiden for atleter
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sportslegater tilbyder talrige muligheder for unge atleter til at vise deres talent og udvikle deres færdigheder. En af de mest betydningsfulde muligheder er at opnå et sportslegat for at studere og konkurrere på kollegialt niveau. I dette kapitel vil vi diskutere vigtigheden af sportslegater og hvordan Sport Legater Amerika kan hjælpe dig med at realisere din drøm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vigtigheden af legater
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et sportslegat er økonomisk støtte, som et universitet tilbyder til en studerende-atlet for at kombinere studier og sport. Det kan dække omkostningerne til undervisning, bøger, bolig, måltider og andre udgifter. Derudover kan det også tilbyde fordele såsom akademisk vejledning, lægehjælp, træningsfaciliteter og udstyr.
      <br />
      <br />
      At opnå et sportslegat er konkurrencepræget, hvor mange faktorer spiller en rolle i tildelingsprocessen, herunder akademiske præstationer, atletisk præstation, personlighed, motivation, lederskab og tilpasningsevne. Derfor er det vigtigt at forberede sig godt og adskille sig fra konkurrencen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Legater Amerika Kan Hjælpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo af Sport Legater Amerika, der viser sportsudstyr og en eksamenshue, der repræsenterer fokus på sportslegater for studerende-atleter."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo af Sport Legater Amerika
        </figcaption>
      </figure>
      Sport Legater Amerika er en organisation specialiseret i at guide unge sports talenter til sportslegater. Med passionerede og erfarne eksperter støtter Sport Legater Amerika studerende-atleter på hvert trin af rejsen, fra indledende orientering til endelig placering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intake-interview for at diskutere den studerende-athlets ønsker, muligheder og forventninger.</li>
        <li>En professionel evaluering af den studerende-athlets atletiske og akademiske niveau.</li>
        <li>Et omfattende netværk af kontakter med trænere, universiteter og kollegier.</li>
        <li>Effektiv markedsføring af den studerende-athlets profil og videoer til potentielle trænere.</li>
        <li>Ekspertrådgivning om de bedste muligheder baseret på den studerende-athlets præferencer, mål og budget.</li>
        <li>Forhandling af de bedste legat tilbud på vegne af den studerende-atlet.</li>
        <li>Hjælp til ansøgning om nødvendige dokumenter, såsom visum, transkript, eksamensbevis og optagelsesprøver.</li>
        <li>Forberedelse til den studerende-atlets liv under deres legat, herunder kultur, sprog, regler og tips.</li>
        <li>Efterpleje og opfølgning under legatperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ønsker du at udforske legatmuligheder og opnå dine sportslige og akademiske mål? <Link to="/da/Tjenester-Produkter">Se det fulde udvalg</Link> af tjenester tilbudt af Sport Legater Amerika og planlæg en konsultation i dag.
    </MKTypography>
  </MKBox>
</MKBox>



          
          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;