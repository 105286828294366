import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function Header() {
  return (
    <Box>
      <Typography
        variant="h5"
        component="h1"
        sx={{
          fontFamily: "Inconsolata",
          margin: "2rem 2rem 4rem 2rem"
        }}
      >
        404 NOT FOUND
      </Typography>
    </Box>
  );
}

export default Header;
