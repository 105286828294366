/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Softbal1.jpeg";
import Image2 from "assets/images/Sports_Images/Softbal2.jpg";
import Image3 from "assets/images/Sports_Images/Softbal3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Bourses de softball aux États-Unis : Comment étudier et jouer au softball en Amérique', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Chapitre 1 : Histoire et développement du softball en Amérique', isSubheader: false },
    { id: '1.1', title: '1.1 Premières années et rôle des écoles et des universités', isSubheader: true },
    { id: '1.2', title: '1.2 Croissance et professionnalisation', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Chapitre 2 : Le Softball Universitaire Aujourd\'hui', isSubheader: false },
    { id: '2.1', title: '2.1 Ligues et Conférences Importantes', isSubheader: true },
    { id: '2.2', title: '2.2 Programmes de Premier Plan et Équipes Prestigieuses', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Chapitre 3 : Du Collège aux Professionnels', isSubheader: false },
    { id: '3.1', title: '3.1 Le Chemin vers le Professionnalisme', isSubheader: true },
    { id: '3.2', title: '3.2 Softball Professionnel et Autres Opportunités de Carrière', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Chapitre 4 : Influences et Événements Internationaux', isSubheader: false },
    { id: '4.1', title: '4.1 Influences Étrangères', isSubheader: true },
    { id: '4.2', title: '4.2 Les Américains sur la Scène Internationale', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Chapitre 5 : Bourses Sportives et l\'Avenir des Athlètes', isSubheader: false },
    { id: '5.1', title: '5.1 L\'Importance des Bourses', isSubheader: true },
    { id: '5.2', title: '5.2 Comment Sport Scholarships America Peut Aider', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };
  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Sommaire
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Cacher</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Afficher</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>

























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Bourses de softball aux États-Unis : Comment étudier et jouer au softball en Amérique
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Vue aérienne d'un stade rempli lors d'un match de softball universitaire."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Un stade plein lors d'un match
      </figcaption>
    </figure>
    Le softball est un sport qui a vu le jour aux États-Unis à la fin du XIXe siècle. C'est une variante du baseball mais avec une balle plus grande, un terrain plus petit et des manches plus courtes. Le softball se joue avec deux équipes de neuf joueurs qui alternent entre le bâton et la défense. L'objectif est de marquer plus de points que l'équipe adverse en courant autour des quatre bases après avoir frappé la balle.
    <br />
    <br />
    Le softball a rapidement gagné en popularité aux États-Unis, notamment dans les écoles et les universités. Il était considéré comme un sport qui favorisait la forme physique, la vivacité d'esprit et l'interaction sociale. Il a également attiré beaucoup de talent et de compétition, notamment au niveau collégial. Le softball collégial représente le plus haut niveau de softball aux États-Unis, où les étudiants-athlètes concourent pour leur université ou leur collège. Le softball collégial offre prestige, reconnaissance et bourses d'études aux meilleurs joueurs.
    <br />
    <br />
    Sportbeursamerika.nl est une organisation dédiée à aider les jeunes athlètes à obtenir des bourses de softball. Sportbeursamerika.nl dispose d'une équipe d'experts qui peut vous aider à trouver la meilleure université ou collège en fonction de votre profil académique et sportif. Ils peuvent également vous aider à préparer votre candidature, à créer votre vidéo, à entrer en contact avec les entraîneurs et à organiser votre visa. Sportbeursamerika.nl a déjà aidé de nombreux succès à réaliser leur rêve d'étudier et de jouer au softball en Amérique.
    <br />
    <br />
    Si vous voulez en savoir plus sur le softball en Amérique et comment obtenir une bourse de softball, continuez à lire !
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>

{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 1 : Histoire et développement du softball en Amérique
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le softball est un sport qui a vu le jour aux États-Unis mais qui s'est répandu dans le monde entier. C'est un sport qui a subi de nombreux changements et innovations, avec de nombreuses figures et événements influents. Dans ce chapitre, nous vous fournirons un aperçu de l'histoire et du développement du softball en Amérique, de ses premières années à nos jours.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Premières années et rôle des écoles et des universités
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Le softball a été joué pour la première fois en 1887 à Chicago comme une variante en salle du baseball. Il a été conçu par un groupe d'hommes célébrant Thanksgiving dans un gymnase. Ils utilisaient un gant de boxe comme balle et un balai comme batte. Le jeu a rapidement gagné en popularité parmi divers groupes de personnes, y compris les pompiers, les ouvriers d'usine et les membres d'église.
      <br />
      <br />
      Au cours des premières années du softball, il n'y avait pas de règles ou de dimensions standardisées. Le jeu s'adaptait à l'espace disponible et au nombre de joueurs. Il existait différentes variantes de softball, telles que mushball, kittenball, diamond ball et indoor baseball. Le terme "softball" a été officiellement utilisé en 1926.
      <br />
      <br />
      Les écoles et les universités ont joué un rôle important dans le développement du softball. Ils considéraient le softball comme un sport adapté aussi bien aux garçons qu'aux filles, favorisant l'éducation physique et les loisirs. Ils ont également organisé les premières équipes scolaires et universitaires ainsi que les premières compétitions organisées. L'une des premières compétitions scolaires était la Public Schools Athletic League à New York, qui a lancé le softball pour les filles en 1913. L'une des premières compétitions universitaires était la National Collegiate Athletic Association (NCAA), qui a commencé le softball féminin en 1957.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Croissance et professionnalisation
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Le softball est devenu un sport populaire et prestigieux en Amérique, notamment chez les femmes. Cela était en partie dû à l'introduction de la loi Title IX en 1972, une loi qui garantissait l'égalité des chances pour les femmes dans l'éducation, y compris les bourses sportives. Cela a permis à davantage d'étudiantes-athlètes de jouer au softball au niveau collégial.
      <br />
      <br />
      En plus du softball collégial, des ligues professionnelles de softball ont émergé en Amérique. La première ligue professionnelle de softball féminin était l'International Women’s Professional Softball Association (IWPSA), fondée en 1976. Cette ligue se composait de dix équipes de différentes villes, telles que New York, Chicago et Los Angeles. La ligue a duré quatre saisons, jusqu'en 1980.
      <br />
      <br />
      La deuxième ligue professionnelle de softball féminin était la Women’s Pro Softball League (WPSL), établie en 1997. Cette ligue comprenait six équipes de différents États, notamment la Floride, la Géorgie et la Virginie. La ligue a ensuite changé de nom pour devenir la National Pro Fastpitch (NPF). La NPF a été active jusqu'en 2021 et a subi deux saisons annulées en raison de la COVID-19. Une nouvelle ligue de softball, la Women's Professional Fastpitch (WPF), a été lancée en 2022.
      <br />
      <br />
      Les ligues professionnelles de softball ont eu un impact significatif sur le softball collégial. De nombreux joueurs professionnels étaient d'anciens étudiants-athlètes qui ont continué leur carrière après l'obtention de leur diplôme. Certains joueurs professionnels sont également devenus entraîneurs ou instructeurs pour des équipes collégiales ou des joueurs individuels. De plus, de nombreux joueurs professionnels se sont distingués en tant que modèles et sources d'inspiration pour les jeunes joueurs de softball.
      <br />
      <br />
      Voici quelques exemples de figures clés dans le monde du softball :

    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Jennie Finch : Ancienne lanceuse pour les Arizona Wildcats et l'équipe nationale des États-Unis. Elle a remporté deux médailles olympiques, l'or en 2004 et l'argent en 2008. Elle a également joué pour les Chicago Bandits dans la NPF. Elle est considérée comme l'une des plus grandes lanceuses de tous les temps et une icône pour le sport.
        </li>
        <li>
          Cat Osterman : Ancienne lanceuse pour les Texas Longhorns et l'équipe nationale des États-Unis. Elle a également remporté deux médailles olympiques, l'or en 2004 et l'argent en 2008. Elle a joué pour les Rockford Thunder et les Houston Scrap Yard Dawgs dans la NPF. Elle est ensuite devenue l'entraîneuse des Texas State Bobcats.
        </li>
        <li>
          Monica Abbott : Ancienne lanceuse pour les Tennessee Volunteers et l'équipe nationale des États-Unis. Elle a remporté une médaille d'argent olympique en 2008. Elle a ensuite joué pour le Scrap Yard Fast Pitch dans la NPF et les Toyota Red Terriers au Japon. Elle est la première joueuse de softball féminin à signer un contrat d'un million de dollars.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  





<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 2 : Le Softball Universitaire Aujourd'hui
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le softball est l'un des sports les plus populaires et compétitifs au niveau universitaire aux États-Unis. Chaque année, plus d'un millier d'équipes participent à la saison de softball universitaire, qui se déroule de février à juin. Le point culminant de la saison est la Série Mondiale Universitaire Féminine (WCWS), le championnat national de softball universitaire.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Ligues et Conférences Importantes
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Joueuses de Tennessee et d'Alabama en action lors de la Série Mondiale Universitaire."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
         }}>
          Joueuses en action lors de la Série Mondiale Universitaire
        </figcaption>
      </figure>
      Le softball universitaire est réglementé par la National Collegiate Athletic Association (NCAA), une organisation chargée d'organiser et de gérer les programmes sportifs des universités et des collèges aux États-Unis. La NCAA divise les universités en trois divisions en fonction de leur taille, de leur budget et de leurs performances sportives. Ces divisions sont :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Division I : La division la plus élevée et la plus prestigieuse, avec plus de 300 universités et plus de 10 000 étudiants-athlètes. La Division I offre le plus de bourses, les meilleures installations et la concurrence la plus forte. La Division I se compose de 32 conférences, des groupes régionaux d'universités qui jouent des matchs les unes contre les autres. Quelques exemples de conférences sont la Southeastern Conference (SEC), la Pac-12 Conference et la Big Ten Conference.
        </li>
        <li>
          Division II : La division intermédiaire, avec plus de 300 universités et plus de 8 000 étudiants-athlètes. La Division II offre moins de bourses mais fournit toujours de bonnes installations et une concurrence solide. La Division II se compose de 23 conférences, telles que la Great Lakes Valley Conference (GLVC), la Sunshine State Conference et la California Collegiate Athletic Association (CCAA).
        </li>
        <li>
          Division III : La division la plus basse, avec plus de 400 universités et plus de 6 000 étudiants-athlètes. La Division III n'offre pas de bourses mais propose des programmes académiques solides et une expérience sportive. La Division III se compose de 43 conférences, dont la New England Small College Athletic Conference (NESCAC), la Centennial Conference et la University Athletic Association (UAA).
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Chaque division a son propre championnat à la fin de la saison, où les meilleures équipes de chaque conférence se qualifient pour un tournoi à élimination directe. Le championnat de la Division I s'appelle la Série Mondiale Universitaire Féminine (WCWS), qui se tient à Oklahoma City. Le championnat de la Division II s'appelle le Championnat de Softball de la Division II de la NCAA, qui se tient à Denver. Le championnat de la Division III s'appelle le Championnat de Softball de la Division III de la NCAA, qui se tient à Salem.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programmes de Premier Plan et Équipes Prestigieuses
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Le softball universitaire a produit de nombreux programmes de premier plan et équipes prestigieuses qui se sont distingués par leur histoire réussie, leurs joueurs talentueux et leurs entraîneurs influents. Certains de ces programmes et équipes incluent :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          UCLA Bruins : Ce programme est considéré comme l'un des plus réussis de l'histoire du softball universitaire, avec un nombre impressionnant de titres nationaux à leur actif. Au fil des ans, ils ont produit des médaillées olympiques, dont des noms bien connus comme Lisa Fernandez, Natasha Watley et Stacey Nuveman.
        </li>
        <li>
          Oklahoma Sooners : Les Oklahoma Sooners sont une équipe remarquablement forte et ont toujours offert des performances de premier plan. Ils ont remporté plusieurs titres nationaux et ont eu d'impressionnantes séries de victoires par le passé.
        </li>
        <li>
          Arizona Wildcats : Le programme de softball des Arizona Wildcats compte une impressionnante liste de titres nationaux. Ils ont également un entraîneur respecté, Mike Candrea, avec un excellent palmarès de nombreuses victoires et même une médaille d'or olympique en tant qu'entraîneur de l'équipe nationale américaine de softball.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ces programmes et équipes contribuent au développement des joueurs de softball en leur offrant un niveau élevé d'entraînement, de coaching et de compétition. Ils aident également les joueurs à améliorer leurs compétences, leurs connaissances et leur mentalité, les préparant ainsi à la prochaine étape de leur carrière.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  






<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 3 : Du Collège aux Professionnels
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le softball est un sport qui offre de nombreuses opportunités aux étudiants-athlètes de poursuivre leur carrière après leur période universitaire. Certains choisissent de jouer au softball professionnel, tandis que d'autres explorent d'autres possibilités de carrière. Dans ce chapitre, nous discutons du chemin vers le professionnalisme, des ligues professionnelles de softball et d'autres options de carrière pour les joueurs de softball.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Le Chemin vers le Professionnalisme
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Jouer au softball universitaire est une bonne préparation pour jouer au softball professionnel. De nombreux étudiants-athlètes qui excellent au softball universitaire sont repérés ou draftés par des équipes professionnelles. Cela signifie qu'ils se voient offrir un contrat pour jouer pour une équipe spécifique, généralement en échange d'un salaire et d'autres avantages.
      <br />
      <br />
      Cependant, jouer au softball professionnel n'est pas facile. La compétition est très forte et les joueurs doivent constamment s'adapter à de nouvelles situations, adversaires et défis. De plus, les salaires des joueurs de softball professionnel ne sont pas très élevés par rapport à d'autres sports. Selon un rapport de 2016, le salaire moyen d'un joueur de NPF était d'environ 5 000 à 6 000 dollars par saison. Cela signifie que de nombreux joueurs professionnels doivent avoir un deuxième emploi ou une autre source de revenus pour joindre les deux bouts.
      <br />
      <br />
      Par conséquent, certains étudiants-athlètes choisissent de ne pas jouer au softball professionnel mais de terminer leurs études ou de poursuivre une autre carrière. Cela peut également être un bon choix car le softball universitaire développe de nombreuses compétences et qualités utiles dans d'autres domaines, telles que le leadership, le travail d'équipe, la communication, la discipline et la résolution de problèmes.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Softball Professionnel et Autres Opportunités de Carrière
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Comme mentionné précédemment, la principale ligue professionnelle de softball en Amérique est la National Pro Fastpitch (NPF). La NPF se compose actuellement de cinq équipes : les Aussie Peppers de Minnesota, les Canadian Wild de Southern Illinois, les Chicago Bandits, les Cleveland Comets et les USSSA Pride. La NPF a également un partenariat avec l'Association Chinoise de Softball (CSA), qui envoie deux équipes à la NPF : les Beijing Eagles et les Shanghai Shengli.
      <br />
      <br />
      Cependant, la NPF n'est pas la seule ligue professionnelle de softball dans le monde. Il existe également d'autres ligues dans des pays tels que le Japon, l'Australie, l'Italie et le Mexique. Certains joueurs américains choisissent de jouer dans ces ligues parce qu'ils sont mieux payés ou parce qu'ils veulent vivre une expérience culturelle différente.
      <br />
      <br />
      Outre la pratique du softball professionnel, il existe d'autres opportunités de carrière pour les joueurs de softball. Quelques exemples incluent :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Entraînement : Certains joueurs deviennent des entraîneurs pour des équipes scolaires, universitaires ou professionnelles. Ils utilisent leurs connaissances et leur expérience pour entraîner, motiver et conseiller d'autres joueurs.
        </li>
        <li>
          Éducation : Certains joueurs deviennent des enseignants ou des professeurs à différents niveaux et dans différents domaines. Ils utilisent leur formation académique et leurs compétences pédagogiques pour éduquer et guider les étudiants.
        </li>
        <li>
          Médias : Certains joueurs deviennent des journalistes, des commentateurs ou des analystes pour différents médias. Ils utilisent leurs compétences en communication et leur compréhension du sport pour rapporter les nouvelles et les événements de softball.
        </li>
        <li>
          Entrepreneuriat : Certains joueurs deviennent des entrepreneurs ou des managers dans différents secteurs et industries. Ils utilisent leurs compétences créatives et commerciales pour développer et vendre des produits ou des services.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Comme vous pouvez le constater, il existe de nombreuses opportunités pour les joueurs de softball d'utiliser leur passion et leur talent dans différents domaines. La clé est de définir vos objectifs, d'explorer vos options et de poursuivre vos rêves.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  








<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 4 : Influences et Événements Internationaux
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le softball n'est pas seulement un sport américain mais aussi un sport international. Le softball est pratiqué dans plus de 140 pays à travers le monde et a eu un impact significatif sur la culture sportive et le développement dans diverses régions. Dans ce chapitre, nous discuterons des influences étrangères sur le softball en Amérique et des influences américaines sur le softball sur la scène internationale.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Influences Étrangères
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="L'équipe de softball de l'Université d'Oklahoma célèbre sa victoire au championnat de la NCAA."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Les joueurs d'Oklahoma célèbrent le championnat national
        </figcaption>
      </figure>
      Le softball en Amérique doit beaucoup aux influences étrangères qui ont enrichi et challengé le sport. Certaines de ces influences incluent :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Japon : Le Japon est l'un des pays les plus forts en softball, tant au niveau professionnel qu'universitaire. Le Japon a une longue tradition de softball remontant aux années 1930. Le Japon a également sa propre ligue professionnelle de softball, la Japan Softball League (JSL), composée de 12 équipes. Le Japon a eu un impact significatif sur le softball américain en introduisant de nouvelles techniques, tactiques et styles de jeu. Le Japon a également de nombreux programmes d'échange et partenariats avec des universités et équipes américaines, offrant à de nombreux joueurs l'opportunité d'apprendre les uns des autres.
        </li>
        <li>
          Australie : L'Australie est un autre pays qui joue un rôle significatif dans le softball, surtout au niveau international. L'Australie a sa propre équipe nationale de softball, l'Aussie Spirit, qui a remporté plusieurs médailles aux Jeux olympiques, aux Championnats du monde et à la Coupe du monde de softball. L'Australie a également de nombreux joueurs qui concourent dans la NPF, tels que Stacey Porter, Kaia Parnaby et Taylah Tsitsikronis. L'Australie a eu une influence substantielle sur le softball américain en montrant un haut niveau d'athlétisme, de persévérance et d'esprit d'équipe.
        </li>
        <li>
          Canada : Le Canada, en tant que pays voisin de l'Amérique, a également une forte culture du softball. Le Canada a sa propre équipe nationale de softball, les Canadian Wild, qui participe également à la NPF. Le Canada a également de nombreux joueurs qui ont joué ou jouent actuellement pour des universités américaines, tels que Danielle Lawrie, Jenn Salling et Sara Groenewegen. Le Canada a eu une influence significative sur le softball américain en partageant une passion commune, une vision et une appréciation pour le sport.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ces influences étrangères ont contribué au développement du softball en Amérique en créant plus de diversité, de compétition et de collaboration. Elles ont également contribué au développement des joueurs universitaires en les exposant à différentes cultures, perspectives et expériences.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Les Américains sur la Scène Internationale
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Le softball en Amérique a également eu un impact significatif sur le softball sur la scène internationale. L'Amérique a été un pays leader en softball pendant de nombreuses années, tant au niveau professionnel qu'universitaire. L'Amérique a produit de nombreux succès et sources d'inspiration pour d'autres pays et joueurs.
      <br />
      <br />
      Ces succès ont eu une influence significative sur le softball sur la scène internationale en montrant un haut niveau de performance, d'excellence et de leadership. Ils ont également gagné beaucoup de respect et d'admiration de la part d'autres pays et joueurs, qui les voient comme des exemples et des challengers.
      <br />
      <br />
      Le rôle du softball universitaire dans la préparation aux compétitions internationales est crucial. Le softball universitaire fournit aux joueurs une base solide de compétences, de connaissances et d'expérience qu'ils peuvent utiliser pour rivaliser avec les meilleurs joueurs du monde. Le softball universitaire aide également à découvrir, développer et promouvoir le talent et le potentiel des joueurs afin qu'ils puissent exceller au plus haut niveau.
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  



<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 5 : Bourses Sportives et l'Avenir des Athlètes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Les bourses sportives offrent de nombreuses opportunités aux jeunes athlètes de montrer leur talent et de développer leurs compétences. L'une des opportunités les plus significatives est d'obtenir une bourse sportive pour étudier et compétitionner au niveau collégial. Dans ce chapitre, nous discuterons de l'importance des bourses sportives et de comment Sport Scholarships America peut vous aider à réaliser votre rêve.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 L'Importance des Bourses
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Une bourse sportive est un soutien financier fourni par une université à un étudiant-athlète pour combiner études et sport. Elle peut couvrir les frais de scolarité, les livres, le logement, les repas et autres dépenses. De plus, elle peut offrir des avantages tels que du tutorat académique, des soins médicaux, des installations d'entraînement et du matériel.
      <br />
      <br />
      Obtenir une bourse sportive est compétitif, avec de nombreux facteurs qui influent sur le processus d'attribution, notamment les performances académiques, sportives, la personnalité, la motivation, le leadership et l'adaptabilité. Il est donc important de bien se préparer et de se démarquer de la concurrence.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Comment Sport Scholarships America Peut Aider
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo de Sport Scholarships America, représentant du matériel sportif et une casquette de graduation, symbolisant le focus sur les bourses sportives pour les étudiants-athlètes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America est une organisation spécialisée dans l'orientation des jeunes talents sportifs vers des bourses sportives. Avec des experts passionnés et expérimentés, Sport Scholarships America soutient les étudiants-athlètes à chaque étape du parcours, de l'orientation initiale à la placement final. Leurs services comprennent :
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Un entretien personnel pour discuter des souhaits, des possibilités et des attentes de l'étudiant-athlète.</li>
        <li>Une évaluation professionnelle du niveau académique et sportif de l'étudiant-athlète.</li>
        <li>Un vaste réseau de contacts avec des entraîneurs, des universités et des collèges.</li>
        <li>Promotion efficace du profil et des vidéos de l'étudiant-athlète auprès des entraîneurs potentiels.</li>
        <li>Conseils d'experts sur les meilleures options en fonction des préférences, des objectifs et du budget de l'étudiant-athlète.</li>
        <li>Négociation des meilleures offres de bourses au nom de l'étudiant-athlète.</li>
        <li>Aide à la demande de documents nécessaires, tels que visa, relevé de notes, diplôme et tests d'admission.</li>
        <li>Préparation à la vie d'étudiant-athlète pendant sa bourse, y compris culture, langue, règles et conseils.</li>
        <li>Suivi et accompagnement pendant la période de la bourse.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vous voulez explorer les opportunités de bourses et atteindre vos objectifs sportifs et académiques ? <Link to="/fr/Services-Produits">Consultez la gamme complète</Link> des services offerts par Sport Scholarships America et planifiez une consultation dès aujourd'hui.
    </MKTypography>
  </MKBox>
</MKBox>
          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;