/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Hockey1.jpg";
import Image2 from "assets/images/Sports_Images/Hockey2.jpeg";
import Image3 from "assets/images/Sports_Images/Hockey3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");




  
  
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Fält Hockey Stipendier i Amerika: Hur Fält Hockey Kan Hjälpa Dig Att Uppnå Dina Drömmar', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapitel 1: Historia och Utveckling av Fält Hockey i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Tidig Historia', isSubheader: true },
    { id: '1.2', title: '1.2 Utveckling och Professionalisering', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapitel 2: Fält Hockey på Universitetsnivå', isSubheader: false },
    { id: '2.1', title: '2.1 Tävlingar och Konferenser', isSubheader: true },
    { id: '2.2', title: '2.2 Påverkan på Spelarutveckling', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapitel 3: Övergång från Universitet till Professionell', isSubheader: false },
    { id: '3.1', title: '3.1 Från College till Proffs', isSubheader: true },
    { id: '3.2', title: '3.2 Professionella Möjligheter och Ligor', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapitel 4: Internationella Påverkningar och Möjligheter', isSubheader: false },
    { id: '4.1', title: '4.1 Globala Påverkningar', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikaner på Världsscenen', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapitel 5: Idrottsstipendier och Framtiden för Idrottare', isSubheader: false },
    { id: '5.1', title: '5.1 Vikten av Stipendier', isSubheader: true },
    { id: '5.2', title: '5.2 Hur Sport Scholarships America Kan Hjälpa', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  const [,] = useState("");


  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innehåll
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Dölj</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Visa</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>






















          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Fält Hockey Stipendier i Amerika: Hur Fält Hockey Kan Hjälpa Dig Att Uppnå Dina Drömmar
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Spelare i aktion under en Colgate-college-hockeymatch."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
      Spelare i aktion under en match
      </figcaption>
    </figure>

    Fält hockey är en av de snabbast växande sporterna i Amerika. Fler och fler unga idrottare upptäcker fördelarna och nöjet med denna dynamiska och utmanande sport. Men visste du att fält hockey också kan hjälpa dig att uppnå dina akademiska och professionella mål?
    <br />
    <br />

    Om du har en passion för fält hockey har du en unik möjlighet att spela på collegenivå i Amerika. Detta innebär att du kan studera vid ett av världens bästa universitet samtidigt som du utvecklar dina idrottsliga färdigheter under professionell vägledning. Dessutom kan du kvalificera dig för ett idrottsstipendium, vilket ger ekonomiskt stöd för din utbildning.

    <br />
    <br />
    Men hur får du ett sådant stipendium? Och hur förbereder du dig för att spela fält hockey i Amerika? Det är där Sportbeursamerika.nl kan hjälpa dig. Sportbeursamerika.nl är en specialiserad byrå som hjälper unga talanger att uppfylla sin dröm om att studera och spela hockey i Amerika. Jag erbjuder personlig vägledning, råd och stöd för att hitta rätt universitet, ansöka om stipendium, hantera alla praktiska frågor och integrera i amerikansk kultur.
    <br />
    <br />
    I den här artikeln kommer jag att berätta mer om historien och utvecklingen av fält hockey i Amerika, möjligheterna och fördelarna med att spela på universitetsnivå, övergången från college till professionellt spel, de internationella influenserna och möjligheterna inom sporten, och, naturligtvis, vikten av stipendier och rollen för Sportbeursamerika.nl. Efter att ha läst den här artikeln kommer du att ha en bättre förståelse för vad fält hockey i Amerika innebär och hur du förbereder dig för den här spännande utmaningen.
    <br />
    <br />
    Är du redo att lära dig mer om fält hockey i Amerika? Fortsätt läsa!
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Historia och Utveckling av Fält Hockey i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Fält hockey är en sport med en lång och rik historia. Men hur uppstod den och växte den i Amerika? I det här kapitlet tar vi dig med på en resa genom tiden, från de tidiga introduktionerna till dagens professionalisering av sporten.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidig Historia
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Fält hockey introducerades först i USA i slutet av 1800-talet av brittiska invandrare och turister. Spelet fick snabbt popularitet bland kvinnor, som såg det som ett sätt att motionera och umgås. De första collegelagen bildades 1901 vid Vassar College i New York och Bryn Mawr College i Pennsylvania. Dessa lag spelade främst mot varandra eller lokala klubbar.
      <br />
      <br />
      År 1922 grundades United States Field Hockey Association (USFHA) som den nationella organisationen för sporten. USFHA organiserade de första nationella mästerskapen för kvinnor 1925 och för män 1930. USFHA var också ansvarig för att välja och träna landslag som tävlade i internationella turneringar som OS och världsmästerskap.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Utveckling och Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Under andra hälften av 1900-talet fortsatte fält hockey att växa i popularitet och kvalitet i Amerika. Spelet spreds vidare över landet, särskilt i Nordöstra, Mellanvästern och Västern. Antalet collegelag ökade, och fler ligor och konferenser bildades. Några av de mest prestigefyllda och konkurrenskraftiga programmen inkluderar Princeton University, University of Maryland, University of North Carolina, Stanford University och Harvard University.
      <br />
      <br />
      Collegelandhockeyn ökade också tack vare förbättrade faciliteter, tränare och rekrytering. Många universitet erbjuder nu idrottsstipendier till talangfulla fält hockeyspelare, vilket gör det möjligt för dem att studera utan ekonomiska bekymmer. Dessutom fungerar collegelandhockeyn som en grogrund för professionell fält hockey, som också blir mer utvecklad och professionaliserad.
      <br />
      <br />
      Professionell fält hockey har också påverkat det nationella laget, som har presterat bättre på den internationella scenen. Till exempel har det amerikanska damlaget vunnit brons två gånger på OS (1984 och 1996) och silver två gånger på Pan American Games (2011 och 2015). Det amerikanska herrlaget har också deltagit i flera OS och världsmästerskap men har ännu inte säkrat några medaljer.
      <br />
      <br />
      Som du kan se har fält hockey kommit långt i Amerika, från en rekreationell aktivitet till en professionell sport. Men vad innebär det att spela fält hockey på collegenivå? Det berättar vi i nästa kapitel.
    </MKTypography>
  </MKBox>
</MKBox>







                                              





                                  







<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Fält Hockey på Universitetsnivå
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Att spela fält hockey på universitetsnivå är en av de mest utmanande och belönande upplevelserna för en ung idrottare. Det ger dig möjligheten att kombinera din idrottstalang med dina akademiska ambitioner och att vara en del av en nära sammanhållen och mångsidig gemenskap. I det här kapitlet kommer vi att berätta mer om tävlingarna och konferenserna, effekten på spelarutveckling och fördelarna med att spela fält hockey på universitetsnivå.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Tävlingar och Konferenser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Flygvy över Boston College-fält hockey-stadion"
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
        Stadion för Boston College
        </figcaption>
      </figure>
      Fält hockey på universitetsnivå regleras av National Collegiate Athletic Association (NCAA), som delar upp sporten i tre divisioner: Division I, Division II och Division III. Varje division har sina egna regler, krav och stipendier. Generellt sett är Division I den högsta tävlingsnivån, med de bästa lagen och spelarna, och erbjuder de flesta stipendierna. Division II och III är mindre konkurrenskraftiga men ger fortfarande gott om möjligheter för talangfulla spelare.
      <br />
      <br />
      Inom varje division finns det olika konferenser, bestående av universitet som är geografiskt eller historiskt anslutna. Varje konferens har sin egen schema, ranking och mästerskap. Några av de mest välkända konferenserna inkluderar Big Ten Conference, Atlantic Coast Conference, Ivy League, Patriot League och America East Conference.
      <br />
      <br />
      De bästa lagen och spelarna från varje konferens kan kvalificera sig för det nationella mästerskapet, som hålls årligen i november. Det nationella mästerskapet innebär en knockout-turnering, där vinnaren kröns till nationell mästare.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Påverkan på Spelarutveckling
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Att spela fält hockey på universitetsnivå har en betydande påverkan på spelarutveckling. Det gör det möjligt för spelare att förbättra sina färdigheter under professionell träning, att träna med toppmoderna faciliteter och utrustning, att tävla mot andra toppidrottare och att lära av sina lagkamrater och motståndare.
      <br />
      <br />
      Dessutom erbjuder spel av fält hockey på universitetsnivå också spelare många personliga fördelar. Det hjälper spelare att öka sitt självförtroende, disciplin, ledarskap, teamwork, kommunikation och problemlösningsförmåga. Det hjälper också spelare att förbättra sin tidsplanering, studievanor, stresshantering och övergripande välbefinnande. Dessutom exponerar det spelare för olika kulturer, perspektiv och erfarenheter, vilket breddar deras vyer.
      <br />
      <br />
      Historien om nederländska kvinnliga hockeyspelare som får stipendier för att spela på universitetsnivå i Amerika tjänar som ett exempel på hur denna erfarenhet kan förbättra deras utveckling som spelare. Många har spelat för prestigefyllda universitetslag i USA, där de inte bara har vunnit mästerskap och individuella titlar utan också lärt sig värdefulla läxor.
      <br />
      <br />
      Erfarenheten i Amerika ger dessa idrottare en unik möjlighet att fördjupa sig i en annan kultur, njuta av nya sociala miljöer och uppleva ett annat utbildningssystem. Interaktion med tränare som utmanar och stöder dem bidrar till att förbättra deras färdigheter och utvidga deras taktiska förståelse för spelet.
      <br />
      <br />
      När de återvänder till Nederländerna har dessa hockeyspelare använt sina förvärvade kunskaper och erfarenheter för att excellera på professionell nivå och i vissa fall ta på sig ledande roller inom det nationella laget. Deras tid i Amerika har ofta förberett dem för det tryck och de förväntningar som kommer med internationella tävlingar och skapat en grund för deras framtida framgång.
      <br />
      <br />
      Som du kan se kan att spela fält hockey på universitetsnivå erbjuda dig många fördelar, både idrottsligt och personligt. Men hur övergår du från collegespel till professionellt spel? Det förklarar vi i nästa kapitel.
    </MKTypography>
  </MKBox>
</MKBox>


                                  







<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Övergång från Universitet till Professionell
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Fält hockey på universitetsnivå är ett utmärkt sätt att utveckla din idrottspotential, men det behöver inte vara slutet på din karriär. Om du har ambitionen och passionen att gå vidare kan du övergå till professionellt spel. I det här kapitlet kommer vi att berätta mer om hur du går från college till proffs och vilka professionella möjligheter och ligor som finns för fält hockey spelare.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Från College till Proffs
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Övergången från universitet till professionellt spel är inte lätt, men det är inte omöjligt heller. Det kräver mycket dedikation, hårt arbete, talang och lite tur. Det mest avgörande är att visa upp dig för rätt personer som kan hjälpa dig att uppnå din dröm.
      <br />
      <br />
      Ett sätt att visa upp dig är genom att delta i provspelningar. Provspelningar är öppna träningspass eller matcher där professionella lag kan scouta och bedöma spelare. Provspelningar hålls vanligtvis före eller efter säsongen och kan tillkännages via webbplatser, sociala medier eller mun-till-mun-metoden. Provspelningar kan vara en utmärkt möjlighet att visa upp dina färdigheter och få feedback från tränare och manager. Provspelningar kan genomföras av professionella lag över hela världen.
      <br />
      <br />
      Ett annat sätt att göra dig känd är att dra nytta av ditt nätverk. Ditt nätverk omfattar alla människor du känner eller möter som är kopplade till fält hockey, inklusive tränare, lagkamrater, motståndare, vänner, familj, lärare, alumner, osv. Ditt nätverk kan hjälpa dig att hålla dig informerad om de senaste nyheterna, möjligheterna och tipsen i fält hockey-världen. Det kan också hjälpa dig att ansluta till personer som har inflytande eller kontakter inom den professionella scenen.
      <br />
      <br />
      Dessutom återvänder många europeiska hockey spelare till Europa för att prova lyckan där. Som du kan se finns det olika sätt att bli uppmärksammad av professionella lag. Men vilka typer av lag finns det, och vilka typer av ligor finns det? Det förklarar vi i nästa avsnitt.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professionella Möjligheter och Ligor
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      För en fält hockey spelare som har gått igenom det amerikanska collegesystemet är dörrarna till en professionell karriär ofta vidöppna. Denna erfarenhet är mer än bara ett steg; det är en omfattande förberedelse som har utvecklat dem på många sätt - fysiskt, taktiskt och mentalt.
      <br />
      <br />
      En spelare som utmärker sig i collegespel kan få uppmärksamhet från scouter och professionella lag, inte bara för deras prestationer på planen utan också på grund av den disciplin och mångsidighet de har odlat genom att studera och spela samtidigt. Professionella hockey ligor över hela världen, som de i Nederländerna, Belgien, Tyskland och Australien, är kända för att rekrytera talangfulla spelare med en solid bakgrund inom collegehockey.
      <br />
      <br />
      Den konkurrenskraftiga naturen i collegespel i Amerika säkerställer också att spelare är vana vid en hög nivå av träning och fysisk förberedelse, vilket är avgörande för det professionella spelet. Dessutom har de ofta erfarenhet av att spela i viktiga matcher och mästerskap, vilket hjälper dem att hantera trycket från professionella tävlingar.
      <br />
      <br />
      Dessutom ger den internationella erfarenheten och exponeringen för olika spelformer i Amerika dessa spelare en unik perspektiv som de kan bära med sig in i sina professionella karriärer. Det hjälper dem att anpassa sig snabbt till nya lag och strategier och ger en bredare förståelse för spelet.
      <br />
      <br />
      Professionella lag värdesätter kombinationen av akademisk disciplin och idrottslig excellens som en före detta collegespelare bringar med sig. Dessa spelare är ofta väl förberedda att möta utmaningarna i det professionella livet, inklusive att balansera sport med andra livsförpliktelser.
      <br />
      <br />
      Som du kan se finns det många professionella möjligheter och ligor för fält hockey spelare i Amerika. Men vad sägs om resten av världen? Hur jämförs fält hockey i Amerika med internationell fält hockey? Det förklarar vi i nästa kapitel.
    </MKTypography>
  </MKBox>
</MKBox>



                                  







<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationella Påverkningar och Möjligheter
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Fält hockey i Amerika är inte isolerat utan är en del av en större och mer mångsidig värld av fält hockey. Fält hockey är en global sport som spelas i över 100 länder över alla kontinenter. Fält hockey i Amerika påverkas av och påverkar internationell fält hockey. I det här kapitlet kommer vi att berätta mer om globala påverkningar, amerikaner på världsscenen och internationella möjligheter för fält hockey spelare.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Globala Påverkningar
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Fält hockey i Amerika påverkas av fält hockey som spelas i andra länder, särskilt i Europa och Asien. Dessa länder har en längre och rikare tradition av fält hockey och har ofta en högre nivå av spel och organisation. Genom att observera och lära från dessa länder kan fält hockey i Amerika ytterligare utvecklas och förbättras.
      <br />
      <br />
      Ett sätt som fält hockey i Amerika påverkas av internationell fält hockey är genom att anta olika stilar och taktiker. Till exempel är holländsk fält hockey känt för sitt snabba och offensiva spel, med fokus på bollinnehav, kombinationer och målförsök. Tysk fält hockey är känt för sin fysiska och defensiva stil, med högt tryck, dueller och kontringar. Indisk fält hockey är känt för sitt tekniska och kreativa spel, med fokus på dribbling, passning och trick.
      <br />
      <br />
      Genom att lära från dessa stilar och taktiker kan fält hockey i Amerika diversifiera sitt eget spel och anpassa sig till olika situationer, vilket ger mer variation, flexibilitet och effektivitet.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikaner på Världsscenen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Fält hockey i Amerika påverkar också internationell fält hockey genom att tillhandahålla spelare som deltar i de största och mest betydelsefulla turneringarna över hela världen. Dessa spelare representerar inte bara sitt land utan också sitt universitet, sitt lag och sin sport. Genom sina prestationer visar de vad fält hockey i Amerika har att erbjuda och inspirerar andra att delta i denna sport.
      <br />
      <br />
      Ett utmärkt exempel på hur amerikanska spelare träder fram på världsscenen är berättelsen om Lauren Crandall. Lauren är en amerikansk fält hockey spelare som fick ett stipendium för att spela för Wake Forest University. Hon spelade för laget i fyra säsonger och vann NCAA Division I-titeln två gånger.
      <br />
      <br />
      Lauren var också en del av det amerikanska landslaget och deltog i tre olympiska spel (2008, 2012 och 2016), två världsmästerskap (2010 och 2014) och fyra Panamerikanska Spel (2007, 2011, 2015 och 2019). Hon tjänstgjorde också som lagkapten från 2013 till 2016.
      <br />
      <br />
      Lauren säger att hennes tid i Amerika hjälpte henne betydligt att förverkliga sin dröm om att spela på högsta nivå. Hon lärde sig mycket av sin tränare, Jennifer Averill, som stöttade och motiverade henne. Hon uppskattade också kulturen, människorna och utbildningen i Amerika. Hon är stolt över att representera sitt land och vara en förebild för unga spelare.
      <br />
      <br />
      Som du kan se kan fält hockey i Amerika erbjuda dig många internationella påverkningar och möjligheter, både som spelare och som individ. Men hur kan du gripa dessa möjligheter? Och hur kan du få ett stipendium för att studera och spela i Amerika? Det ska vi berätta i nästa kapitel.
    </MKTypography>
  </MKBox>
</MKBox>
                                              






                                  







<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Idrottsstipendier och Framtiden för Idrottare
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrottsstipendier erbjuder många möjligheter för unga idrottare att visa upp sin talang och utveckla sina färdigheter. En av de mest betydelsefulla möjligheterna är att få ett idrottsstipendium för att studera och tävla på collegenivå. I det här kapitlet kommer vi att diskutera vikten av idrottsstipendier och hur Sport Scholarships America kan hjälpa dig att förverkliga din dröm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vikten av Stipendier
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ett idrottsstipendium är ekonomiskt stöd som tillhandahålls av ett universitet till en idrottare för att kombinera studier och idrott. Det kan täcka kostnaderna för undervisning, böcker, boende, måltider och andra utgifter. Dessutom kan det erbjuda fördelar som akademisk handledning, sjukvård, träningsanläggningar och utrustning.
      <br />
      <br />
      Att få ett idrottsstipendium är konkurrenskraftigt, med många faktorer som spelar en roll i tilldelningsprocessen, inklusive akademiska prestationer, idrottsprestationer, personlighet, motivation, ledarskap och anpassningsförmåga. Därför är det viktigt att förbereda sig väl och skilja sig från konkurrensen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hur Sport Scholarships America Kan Hjälpa
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotyp för Sport Scholarships America, med sportutrustning och en studentmössa, som representerar fokus på idrottsstipendier för idrottare."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotyp för Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America är en organisation specialiserad på att vägleda unga idrottstalanger till idrottsstipendier. Med passionerade och erfarna experter stöder Sport Scholarships America idrottare i varje steg av resan, från initial orientering till slutlig placering. Deras tjänster inkluderar:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intagningsintervju för att diskutera idrottarens önskemål, möjligheter och förväntningar.</li>
        <li>En professionell utvärdering av idrottarens akademiska och idrottsliga nivå.</li>
        <li>Ett omfattande nätverk av kontakter med tränare, universitet och högskolor.</li>
        <li>Effektiv marknadsföring av idrottarens profil och videor till potentiella tränare.</li>
        <li>Expertrådgivning om de bästa alternativen baserat på idrottarens preferenser, mål och budget.</li>
        <li>Förhandling om de bästa stipendieerbjudandena för idrottaren.</li>
        <li>Hjälp med att ansöka om nödvändiga dokument, såsom visum, betyg, examensbevis och antagningsprov.</li>
        <li>Förberedelse för idrottarens liv under stipendieperioden, inklusive kultur, språk, regler och tips.</li>
        <li>Uppföljning och uppföljning under stipendieperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vill du utforska möjligheter till stipendier och uppnå dina idrottsliga och akademiska mål? <Link to="/sv/Tjänster-Produkter">Se det fullständiga utbudet</Link> av tjänster som erbjuds av Sport Scholarships America och boka en konsultation idag.
    </MKTypography>
  </MKBox>
</MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;