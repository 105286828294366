/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Pagina del Prodotto: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Codificato da www.creative-tim.com

 =========================================================

* La notifica di copyright sopra e questa notifica di autorizzazione devono essere incluse in tutte le copie o parti sostanziali del Software.
*/

// Componenti Material-UI
import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// import Stack from "@mui/material/Stack";

// Componenti Material Kit 2 PRO React
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import miaImmagine from "assets/images/SoccerfotoKayDuit1.png";
import secondaImmagine from "assets/images/SoccerfotoKayDuit2.png"

function Testimonianze() {
  const [immagineCorrente, impostaImmagineCorrente] = useState(miaImmagine);
  const [opacita, impostaOpacita] = useState(1);

  const miaImmagineAlt = "Kay Duit in azione durante una partita di calcio, giocando per l'Università di Tulsa, dinamico e concentrato sul pallone"; // Sostituisci con il testo alternativo effettivo
  const secondaImmagineAlt = "Kay Duit come calciatore presso l'Università di Tulsa, attivo in un'azione di gioco sul campo di calcio."; // Sostituisci con il testo alternativo effettivo

  useEffect(() => {
    const cambiaImmagine = () => {
      impostaOpacita(0); // inizia a dissolversi
      setTimeout(() => {
        impostaImmagineCorrente(prev => prev === miaImmagine ? secondaImmagine : miaImmagine); // cambia immagine dopo la metà della durata
        impostaOpacita(1); // inizia a comparire
      }, 500); // questa è la metà della durata della transizione
    };

    const intervallo = setInterval(cambiaImmagine, 10000); // cambia ogni 10 secondi

    return () => clearInterval(intervallo);
  }, []);

  return (
    <MKBox component="section" position="relative" py={8}>
        <Container>
          <Grid container justifyContent="center" sx={{ pt: 0, pb: 0 }}>
            <Grid item xs={12} md={7} sx={{ textAlign: "center" }}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom mb={3}>
            La Mia Storia: Dalla Passione alla Professione                        </MKTypography>
            <MKTypography color="text" variant="body2" align="center" gutterBottom mb={14}>
            Come la mia esperienza nel mondo dello sport americano ora è la chiave per guidare giovani talenti al successo.               </MKTypography>

              
            </Grid>
          </Grid>
        </Container>
     
      <Container sx={{ maxWidth: "100% !important" }}>
      
      <Grid container item xs={12} sm={10} sx={{ mx: "auto" }}>
          
          <MKBox variant="gradient" bgColor="dark" borderRadius="lg" width="100%" py={6}>
            <Grid container>
              
              <Grid item xs={12} md={6} xl={4} sx={{ position: "relative", px: 6 }}>
              <MKBox
                component="img"
                src={immagineCorrente}
                alt={immagineCorrente === miaImmagine ? miaImmagineAlt : secondaImmagineAlt}
                borderRadius="md"
                maxWidth="300px"
                width="100%"
                position="relative"
                mt={-12}
                style={{ transition: 'opacity 1s', opacity: opacita }} // stile inline per la transizione
                    />
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                xl={4}
                sx={{
                  position: "relative",
                  px: { xs: 6, md: 2 },
                  mt: { xs: 3, md: 0 },
                  my: { xs: 0, md: "auto" },
                  color: ({ palette: { white } }) => white.main,
                }}
              >
                <Icon fontSize="large">format_quote</Icon>
                <MKTypography
                  variant="body2"
                  color="white"
                  fontWeight="light"
                  mb={2}
                  sx={{ fontSize: "1.125rem" }}
                >
Come ex calciatore presso l'Università di Tulsa, ho avuto il privilegio di vivere quattro anni indimenticabili nel cuore della cultura studentesca americana. Ora quella passione ed esperienza mi spingono a guidare gli studenti europei nel loro percorso calcistico negli Stati Uniti. Insieme, realizzeremo il vostro sogno sportivo americano.
                </MKTypography>
                <MKTypography variant="button" fontWeight="bold" color="white">
                  Kay Duit -{" "}
                  <MKTypography variant="caption" color="white">
                    Fondatore
                  </MKTypography>
                </MKTypography>
              </Grid>
              <Grid item xs={1} />
              <Grid
                item
                xs={12}
                xl={2}
                sx={{ px: { xs: 6, xl: 0 }, my: { xs: 0, xl: "auto" }, lineHeight: 1 }}
              >
                <MKTypography variant="h5" color="white" mt={{ xs: 6, xl: 0 }} mb={1}>
                4 anni da letterwinner
                <br />
                3x Campione AAC
                              
                </MKTypography>
                
                <MKTypography
                  component="a"
                  href="/it/Chi-sono"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Leggi di più
                  <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MKTypography>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Testimonianze;
