/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Swimming1.jpg";
import Image2 from "assets/images/Sports_Images/Swimming2.jpeg";
import Image3 from "assets/images/Sports_Images/Swimming3.avif";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Swimming in America: How to Get a Sports Scholarship and Be Part of a Prestigious Sports History', isSubheader: false },
  
    { id: 'Chapter 1', title: '1. The History and Development of Swimming in America', isSubheader: false },
    { id: '1.1', title: '1.1 Early Years and the Role of Universities', isSubheader: true },
    { id: '1.2', title: '1.2 Growth and Professionalization', isSubheader: true },
  
    { id: 'Chapter 2', title: '2. Collegiate Swimming Today', isSubheader: false },
    { id: '2.1', title: '2.1 Key Competitions and Conferences', isSubheader: true },
    { id: '2.2', title: '2.2 Top Programs and Prestigious Teams', isSubheader: true },
  
    { id: 'Chapter 3', title: '3. From College to Professional Level', isSubheader: false },
    { id: '3.1', title: '3.1 The Path to Professionalism', isSubheader: true },
    { id: '3.2', title: '3.2 Professional Swimming and the Olympics', isSubheader: true },
  
    { id: 'Chapter 4', title: '4. International Influences and Competitions', isSubheader: false },
    { id: '4.1', title: '4.1 Foreign Influences', isSubheader: true },
    { id: '4.2', title: '4.2 Americans Abroad', isSubheader: true },
  
    { id: 'Chapter 5', title: '5. Sports Scholarships and the Future', isSubheader: false },
    { id: '5.1', title: '5.1 The Importance of Scholarships', isSubheader: true },
    { id: '5.2', title: '5.2 How Sport Scholarships America Can Help', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Content
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Hide</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Show</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Swimming in America: How to Get a Sports Scholarship and Be Part of a Prestigious Sports History
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Swimming stadium filled with spectators during a college competition."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        A stadium during a match
      </figcaption>
    </figure>
    Swimming is one of the most popular and competitive sports in the world. Millions of people enjoy swimming, both recreationally and professionally. But did you know that swimming in America has reached a whole different level? Swimming in America is not just a sport; it's a culture, a tradition, and a way of life.
    <br />
    <br />
    In this article, we will tell you everything about the development of swimming as a competitive sport in the United States, the importance of collegiate swimming for the advancement of the sport, and how to get a sports scholarship to study and swim in America. We will also delve into the role of Sportbeursamerika.nl, an organization that helps young swimmers realize their dreams.
    <br />
    <br />
    Swimming is a sport that has been practiced for centuries, but it wasn't until the 19th century that it began to develop as an organized and regulated activity. The first modern Olympic Games in 1896 included swimming competitions as one of the main events, and since then, swimming has been a staple of the Olympic agenda.
    <br />
    <br />
    But why is swimming so popular and successful in America? What makes it so special and unique? The answer lies in the role of universities in promoting swimming as an academic and athletic discipline. American universities not only offer high-quality education but also excellent facilities, coaching, and guidance for student-athletes looking to specialize in swimming. Moreover, they provide financial support in the form of sports scholarships, allowing talented swimmers to realize their potential without worrying about the costs of their education.
    <br />
    <br />
    However, sports scholarships are not easy to obtain. They are highly competitive and require a high level of dedication, discipline, and performance. Therefore, it's essential to be well-prepared and seek professional assistance when applying for a sports scholarship. That's where Sportbeursamerika.nl comes into play.
    <br />
    <br />
    Sportbeursamerika.nl is an organization specialized in guiding young swimmers to sports scholarships in America. They have a team of experts who can assist you with all aspects of the process, such as choosing the right university, preparing your resume and motivation letter, getting ready for admission exams and interviews, arranging your visa and insurance, and much more. They also have connections with hundreds of universities and coaches in America, allowing them to match you with the best options for your profile and goals.
    <br />
    <br />
    So, if you dream of studying and swimming in America, Sportbeursamerika.nl is your best ally. They have already helped hundreds of swimmers make their dreams come true, and they can help you too. But before you contact them, let's first take a look at the history and development of swimming in America and why it's such a fantastic opportunity for you.
  </MKTypography>
</MKBox>

<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 1: The History and Development of Swimming in America
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Swimming has a long and rich history in America. It all began in the late 19th century when universities started forming their own swim teams and competitions. This marked the beginning of a tradition that would lead to the rise of swimming as a national sport.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Early Years and the Role of Universities
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      The first university to establish an official swim team was Harvard in 1878. Soon after, other prestigious universities like Yale, Princeton, Columbia, and Cornell followed suit. These universities organized intercollegiate matches and tournaments that garnered public and media attention. They also introduced new rules and techniques for swimming, including the breaststroke, backstroke, and butterfly.
      <br />
      <br />
      In 1902, the Intercollegiate Swimming Association (ISA) was founded, becoming the first national organization for collegiate swimming. The ISA organized annual championships for men, where the best teams and individual swimmers were honored. In 1916, the National Collegiate Athletic Association (NCAA) was established as the overarching organization for all collegiate sports. The NCAA took responsibility for organizing the national championships for collegiate swimming, which continue to this day.
      <br />
      <br />
      Collegiate swimming was not limited to men. Women also began competing at the collegiate level in the early 20th century, although they faced more restrictions and discrimination. The first female collegiate swim teams were formed in 1914 at the University of Chicago and in 1916 at the University of Wisconsin. In 1922, the Women’s Intercollegiate Swimming Association (WISA) was founded, serving as the equivalent of the ISA for women. The WISA also organized annual championships for women, which gained significant popularity.
      <br />
      <br />
      Collegiate swimming played a crucial role in promoting swimming as a professional sport in America. Many collegiate swimmers went on to professional leagues and the Olympic Games, where they represented their country and won medals. Some of the most well-known names include Johnny Weissmuller, Mark Spitz, Matt Biondi, Janet Evans, Michael Phelps, and Katie Ledecky.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Growth and Professionalization
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      In addition to collegiate swimming, other forms of professional swimming emerged in America. One of them was masters swimming, which focused on adult swimmers looking to improve their skills or maintain their fitness. Masters swimming began in 1970 with the establishment of the United States Masters Swimming (USMS), an organization offering local clubs, competitions, and programs for adult swimmers. Masters swimming quickly grew into a global movement, with millions of members in over 100 countries.
      <br />
      <br />
      Another form of professional swimming was open water swimming, which took place in natural bodies of water such as lakes, rivers, and oceans. Open water swimming was a challenging and adventurous sport that required endurance, navigation skills, and adaptability. Open water swimming gained more recognition in 2008 when it was added as an Olympic event. Some of the most famous open water swimmers include Gertrude Ederle, Lynne Cox, Maarten van der Weijden, and Ous Mellouli.
      <br />
      <br />
      Professional swimming reached its peak during the Olympic Games, where American swimmers dominated. Since 1896, America has won over 500 medals in swimming, including more than 200 gold medals. This makes America by far the most successful country in Olympic swimming.
      <br />
      <br />
      But how can you also be a part of this glorious sports history? How can you study and swim in America with a sports scholarship? We will tell you in the next chapter.
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  








<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 2: Collegiate Swimming Today
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    As you've seen, collegiate swimming has a long and proud history in America. But what about the present? How is collegiate swimming organized and structured today? What are the key competitions and conferences? Which are the top programs and prestigious teams? Let's take a closer look.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Key Competitions and Conferences
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Katie Ledecky swimming for Stanford in a college competition."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Katie Ledecky in action as a swimmer for Stanford
        </figcaption>
      </figure>
      Collegiate swimming in America is primarily regulated by the NCAA, which has three divisions: Division I, Division II, and Division III. Each division has its own rules, criteria, and championships. Generally, Division I is the most competitive and prestigious division, featuring the best swimmers, coaches, and facilities. Division II and III are less competitive but still offer high-quality education and sports.
      <br />
      <br />
      Within each division, there are various conferences, which are groups of universities that are geographically or historically connected. Each conference organizes its own championships, with the top teams and individual swimmers qualifying for the NCAA national championships. Some of the most well-known conferences in collegiate swimming include:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          The Southeastern Conference (SEC), consisting of 14 universities in the southeastern United States, such as Alabama, Florida, Georgia, Kentucky, LSU, Tennessee, and Texas A&M. The SEC is known for its dominance in swimming, especially in men's events. The SEC has won more than 50 national titles in swimming, with over 40 of them coming from the men's team at Florida.
        </li>
        <li>
          The Pacific-12 Conference (Pac-12), comprising 12 universities on the west coast of America, including Arizona, California, Stanford, UCLA, USC, and Washington. The Pac-12 is known for its excellence in swimming, particularly in women's events. The Pac-12 has won more than 40 national titles in swimming, with over 30 of them belonging to the women's team at Stanford.
        </li>
        <li>
          The Big Ten Conference (Big Ten), consisting of 14 universities in the Midwest and Northeastern United States, such as Indiana, Michigan, Minnesota, Ohio State, Penn State, and Wisconsin. The Big Ten is known for its diversity and balance in swimming, with strong teams in both men's and women's events. The Big Ten has won more than 30 national titles in swimming, with over 20 of them secured by the men's team at Michigan.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      These are just a few examples of the many conferences that exist in collegiate swimming. Each conference has its own culture, rivalries, and traditions, contributing to the excitement of collegiate swimming.
    </MKTypography>

  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Top Programs and Prestigious Teams
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      In addition to conferences, there are individual universities that stand out due to their top programs and prestigious swimming teams. These universities not only offer excellent education to their students but also provide top-notch coaching, training, and facilities for their athletes. They also attract many talented swimmers from both domestic and international backgrounds who want to study and swim at the highest level.
      <br />
      <br />
      Some of these top universities include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Stanford University: Stanford is one of the most renowned universities in the world academically and athletically. Stanford has one of the best women's swimming programs in America, with over 10 national titles and more than 100 Olympic medals. Stanford also has a strong men's swimming program, with over 5 national titles and more than 50 Olympic medals. Some famous alumni of Stanford include John Hencken, Pablo Morales, Jenny Thompson, Summer Sanders, Misty Hyman, Janet Evans, and Katie Ledecky.
        </li>
        <li>
          University of California: California is another top university both academically and athletically. California has one of the best men's swimming programs in America, with over 10 national titles and more than 100 Olympic medals. California also has a strong women's swimming program, with over 5 national titles and more than 50 Olympic medals. Some famous alumni of California include Matt Biondi, Anthony Ervin, Natalie Coughlin, Nathan Adrian, Dana Vollmer, and Ryan Murphy.
        </li>
        <li>
          University of Texas: Texas is one of the largest and most influential universities in America, with a strong reputation in academics and sports. Texas has one of the best men's swimming programs in America, with over 15 national titles and more than 100 Olympic medals. Texas also has a strong women's swimming program, with over 5 national titles and more than 50 Olympic medals. Some famous alumni of Texas include Josh Davis, Aaron Peirsol, Ian Crocker, Brendan Hansen, Ricky Berens, and Joseph Schooling.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      These are just a few of the many top universities in collegiate swimming. Each university has its own history, philosophy, and vision that contribute to the development of swimmers.
      <br />
      <br />
      As you can see, collegiate swimming today is a highly dynamic and competitive sport that offers many opportunities for student-athletes who want to excel academically and athletically. But how can you access these opportunities? How can you get a sports scholarship to study and swim in America? We will tell you in the next chapter.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  








<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 3: From College to Professional Level
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Studying and swimming in America is not only a great experience but also a fantastic opportunity to kickstart your career as a professional swimmer. Many collegiate swimmers transition to the professional level, where they represent their country in international competitions and the Olympic Games. But how do you make the leap from college to the professional level? What are the challenges and benefits of this transition? Let's take a closer look.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 The Path to Professionalism
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      To make the transition from college to the professional level, you first need to meet the requirements of the NCAA. The NCAA has strict rules regarding the amateur status of student-athletes, which means you cannot earn or accept money for your athletic performance. This means you cannot enter into sponsorship contracts, prize money agreements, or other forms of compensation while still studying and swimming at the collegiate level.
      <br />
      <br />
      However, once you have completed your studies or decide to end your education, you can renounce your amateur status and become a professional swimmer. This means you can join a professional swimming organization, such as USA Swimming, which is the national governing body for swimming in America. USA Swimming provides you with the opportunity to participate in professional swimming competitions like the Pro Swim Series, the US Open, the US Nationals, and the Olympic Trials. These competitions are designed to enhance your skills, raise your ranking, and qualify you for international events.
      <br />
      <br />
      As a professional swimmer, you can also benefit from sponsorship contracts, prize money, and other forms of compensation for your athletic performance. This can help improve your financial situation, support your lifestyle, and increase your motivation. However, becoming a professional swimmer also comes with more responsibilities and challenges. For instance, you will need to manage your own training schedule, nutrition plan, travel expenses, medical care, and taxes. You will also have to deal with increased competition, pressure, and expectations from yourself and others.
      <br />
      <br />
      Therefore, it's crucial to be well-prepared for the transition from college to the professional level. You must not only possess excellent swimming skills but also have a strong mental attitude, clear goals, and good support. You must also be realistic about your chances and opportunities and be willing to work hard and make sacrifices.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professional Swimming and the Olympic Games
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      One of the greatest dreams of every professional swimmer is to participate in the Olympic Games, the most prestigious and significant sporting event in the world. The Olympics not only offer a chance to represent your country and win medals but also to make history and become a legend.
      <br />
      <br />
      However, to qualify for the Olympic Games, you must first meet the requirements of the International Olympic Committee (IOC) and the International Swimming Federation (FINA). These organizations have strict rules concerning qualification criteria, the number of participants per country, anti-doping controls, and other aspects of Olympic swimming.
      <br />
      <br />
      One of the key requirements to qualify for the Olympic Games is to achieve a specific time standard in a recognized competition. This time standard is determined by FINA based on the average of the top 16 performances worldwide in each discipline over the past four years. The time standard varies by gender, age group, and stroke.
      <br />
      <br />
      Another essential requirement to qualify for the Olympic Games is to be selected by your national swimming organization, such as USA Swimming. Each national swimming organization has its own selection process, typically involving a series of trials or championships where the best swimmers are chosen to represent the country. Each national swimming organization also has a limited number of spots per discipline, depending on the number of qualified swimmers and the quotas set by the IOC and FINA.
      <br />
      <br />
      If you manage to meet these requirements and qualify for the Olympic Games, you are one of the fortunate few who get the opportunity to be a part of the largest sporting event in the world. You will be able to compete against the best swimmers in the world, challenge yourself, and potentially win a medal and etch your name in the history books.
      <br />
      <br />
      As you can see, the transition from college to the professional level is an exciting and rewarding journey that offers many opportunities for your swimming career. But how can you start this journey? How can you get a sports scholarship to study and swim in America? We will tell you in the next chapter.
    </MKTypography>
  </MKBox>
</MKBox>





                                  









<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 4: International Influences and Competitions
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Swimming in America is not just a national sport but also an international one. Swimming in America is influenced by and influences other countries and cultures, each of which has its own styles, techniques, and traditions in swimming. Swimming in America is also challenged and enriched by other international competitions, providing opportunities to learn from and compete against swimmers from around the world. But how did these international influences and competitions originate and develop? How do they impact collegiate and professional swimming in America? Let's take a closer look.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Foreign Influences
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Swimmer in action during the NCAA national championship."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          The NCAA national championship
        </figcaption>
      </figure>
      Swimming is a universal sport practiced in almost every country in the world. Each country has its own history, culture, and identity in swimming, reflected in how they swim, train, and coach. Some countries have had a significant influence on swimming in America, both positively and negatively.
      <br />
      <br />
      One of the positive influences is Australia, which is one of America's biggest rivals and allies in swimming. Australia has a long and proud tradition in swimming, with more than 100 Olympic medals and over 20 world records. Australia is known for its innovation and creativity in swimming, introducing new techniques, strategies, and methods. Australia has also produced many talented and charismatic swimmers, such as Dawn Fraser, Shane Gould, Ian Thorpe, Grant Hackett, Libby Trickett, and Cate Campbell.
      <br />
      <br />
      Australia has influenced swimming in America by creating a healthy competition and collaboration that motivates both countries to improve and learn from each other. Many Australian and American swimmers have trained, studied, or worked together, enhancing their skills, knowledge, and friendships. Some examples include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          In 1976, Mark Spitz, the American star who won seven gold medals in Munich, trained alongside John Devitt, the Australian legend who won two gold medals in Melbourne and Rome.
        </li>
        <li>
          In 1988, Matt Biondi, the American sensation who won seven medals in Seoul, graduated from the University of California with a degree in political economy.
        </li>
        <li>
          In 2016, Joseph Schooling, the Singaporean surprise who won the first gold medal for his country in the 100m butterfly in Rio de Janeiro, graduated from the University of Texas with a degree in economics.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      These are just a few examples of how foreign influences have impacted swimming in America. Many more countries and cultures have left their mark on swimming, including Japan, China, Russia, France, Brazil, and South Africa. Swimming in America is not only a national sport but also an international one that constantly evolves and changes through interaction with other countries and cultures.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Americans Abroad
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Swimming in America is not only influenced by and influences other countries and cultures but is also challenged and enriched by other international competitions. Swimming in America provides the opportunity to learn from and compete against swimmers from around the world, each with their own styles, techniques, and traditions in swimming. Swimming in America also offers the chance to travel to and experience different places, people, and cultures, broadening your horizons and changing your perspective.
      <br />
      <br />
      One of the major international competitions in which American swimmers participate is the World Swimming Championships, held every two years by FINA. The World Swimming Championships are an event that encompasses all swimming disciplines, including long course, short course, open water, synchronized swimming, and diving. The World Swimming Championships provide an opportunity to compete against the world's best swimmers, improve your ranking, and enhance your qualification for other events.
      <br />
      <br />
      Another significant international competition that American swimmers participate in is the World Cup Swimming, held annually by FINA. The World Cup Swimming is a series of short course events that take place in various cities around the world. The World Cup Swimming is an opportunity to race against different opponents, test your skills, and earn prize money.
      <br />
      <br />
      A third major international competition in which American swimmers participate is the Pan American Games, held every four years by the Pan American Sports Organization (PASO). The Pan American Games are a multi-sport event that brings together athletes from North, South, and Central America. The Pan American Games are a chance to represent your continent, show solidarity, and win medals.
      <br />
      <br />
      These are just a few examples of the many international competitions in which American swimmers participate. There are many more international competitions in swimming, such as the Commonwealth Games, European Championships, Asian Games, and African Games.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 5: Sports Scholarships and the Future of Athletes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sports scholarships offer numerous opportunities for young athletes to showcase their talent and develop their skills. One of the most significant opportunities is obtaining a sports scholarship to study and compete at the college level. In this chapter, we will discuss the importance of sports scholarships and how Sport Scholarships America can help you make your dream come true.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 The Importance of Scholarships
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A sports scholarship is financial support provided by a university to a student-athlete to combine studies and sports. It can cover the costs of tuition, books, housing, meals, and other expenses. Additionally, it may offer benefits such as academic tutoring, medical care, training facilities, and equipment.
      <br />
      <br />
      Obtaining a sports scholarship is competitive, with many factors playing a role in the awarding process, including academic achievements, athletic performance, personality, motivation, leadership, and adaptability. Therefore, it's essential to prepare well and distinguish yourself from the competition.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 How Sport Scholarships America Can Help
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo of Sport Scholarships America, featuring sports equipment and a graduation cap, representing the focus on sports scholarships for student-athletes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo of Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America is an organization specialized in guiding young sports talents to sports scholarships. With passionate and experienced experts, Sport Scholarships America supports student-athletes at every step of the journey, from initial orientation to final placement. Their services include:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>A personal intake interview to discuss the student-athlete's wishes, possibilities, and expectations.</li>
        <li>A professional evaluation of the student-athlete's athletic and academic level.</li>
        <li>An extensive network of contacts with coaches, universities, and colleges.</li>
        <li>Effective promotion of the student-athlete's profile and videos to potential coaches.</li>
        <li>Expert advice on the best options based on the student-athlete's preferences, goals, and budget.</li>
        <li>Negotiation of the best scholarship offers on behalf of the student-athlete.</li>
        <li>Assistance in applying for necessary documents, such as visa, transcript, diploma, and admission tests.</li>
        <li>Preparation for student-athletes' life during their scholarship, including culture, language, rules, and tips.</li>
        <li>Aftercare and follow-up during the scholarship period.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Want to explore scholarship opportunities and achieve your athletic and academic goals? <Link to="/Services-Products">View the full range</Link> of services offered by Sport Scholarships America and schedule a consultation today.
    </MKTypography>
  </MKBox>
</MKBox>
       
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;