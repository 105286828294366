
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import SchoolIcon from '@mui/icons-material/School';
import StarIcon from '@mui/icons-material/Star';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2'; 
import Filter3Icon from '@mui/icons-material/Filter3'; 
import Filter4Icon from '@mui/icons-material/Filter4'; 
import Filter5Icon from '@mui/icons-material/Filter5'; 
import Filter6Icon from '@mui/icons-material/Filter6'; 
import Filter7Icon from '@mui/icons-material/Filter7'; 
import Filter8Icon from '@mui/icons-material/Filter8'; 
import Filter9Icon from '@mui/icons-material/Filter9';  // Import the number 1 icon
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import ScheduleIcon from '@mui/icons-material/Schedule';
import VideocamIcon from '@mui/icons-material/Videocam';
import QuizIcon from '@mui/icons-material/Quiz';
import RuleIcon from '@mui/icons-material/Rule';
import ChecklistIcon from '@mui/icons-material/Checklist';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import DescriptionIcon from '@mui/icons-material/Description';




const boxStyle = {
  boxShadow: '0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15)',
  background: '#f5f5f5', // Light grey background
  color: '#000', // Text color set to black for contrast
};

const arrowStyle = {
  borderRight: '7px solid white',
};
function Timeline() {
  return (
    <VerticalTimeline lineColor='rgb(33, 150, 243)'>
      <VerticalTimelineElement
        iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
        icon={<StarIcon />}
      />
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ ...boxStyle, position: 'relative' }} // Aggiungi la posizione relativa allo stile
        contentArrowStyle={arrowStyle}
        date="Fase 1"
        iconStyle={{ 
          background: 'rgb(33, 150, 243)', 
          color: '#fff', 
          boxShadow: '0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15)', // Assicurati che l'ombra sia applicata qui
          border: '2px solid white' // Mantieni il bordo
        }}
        icon={<RecordVoiceOverIcon />}
      >
        <div style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          opacity: 0.5 // Opacità ridotta per un effetto filigrana
        }}>
          <Filter1Icon style={{ color: 'grey', fontSize: '48px' }} />
        </div>

        <h3 className="vertical-timeline-element-title">Consultazione iniziale:</h3>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Aggiunto Roboto come famiglia di font
          fontSize: '17px', // Dimensione del font modificata a 17px
          fontWeight: '400', // Assicurati che il peso del font sia normale
          marginBottom: '10px', // Regola il valore secondo necessità
        }}>
          Questo è il tuo blocco di partenza: il momento in cui allineiamo i tuoi sogni e obiettivi con un percorso realistico verso una borsa di studio sportiva in America.
        </p>

        <ul style={{ 
          listStyleType: 'disc', 
          marginLeft: '20px', 
          fontSize: '17px', 
        }}>  
          <li>
            <strong>Obiettivi personali:</strong> Chiarire le tue ambizioni e cosa cerchi in una borsa di studio sportiva e nell'esperienza universitaria.
          </li>
          <li>
            <strong>Competenze sportive:</strong> Valutazione della tua attuale performance sportiva e del potenziale per competere a livello universitario.
          </li>
          <li>
            <strong>Aspirazioni accademiche:</strong> Comprensione dei tuoi interessi e obiettivi accademici e di come integrarli con il tuo sport.
          </li>
          <li>
            <strong>Obiettivi personali:</strong> Sulla base della conversazione, elaborerò una strategia iniziale e un calendario per il tuo percorso verso una borsa di studio sportiva.
          </li>
        </ul>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Aggiunto Roboto come famiglia di font
          fontSize: '17px', // Dimensione del font modificata a 17px
          fontWeight: '400', // Assicurati che il peso del font sia normale
        }}>
          Insieme, gettiamo le basi per una corrispondenza di successo con un'università americana e stabiliamo il percorso per i passi successivi nel processo.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ ...boxStyle, position: 'relative' }} // Aggiungi la posizione relativa allo stile
        contentArrowStyle={arrowStyle}
        date="Fase 2"
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        icon={<ScheduleIcon />}
      >
        <div style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          opacity: 0.5 // Opacità ridotta per un effetto filigrana
        }}>
          <Filter2Icon style={{ color: 'grey', fontSize: '48px' }} />
        </div>
        <h3 className="vertical-timeline-element-title">Valutazione e pianificazione:</h3>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Aggiunto Roboto come famiglia di font
          fontSize: '17px', // Dimensione del font modificata a 17px
          fontWeight: '400', // Assicurati che il peso del font sia normale
          marginBottom: '10px', // Regola il valore secondo necessità
        }}>
          Dopo la tua consultazione iniziale, mi prendo il tempo per una valutazione approfondita e la creazione di un piano personalizzato.
        </p>

        <ul style={{ 
          listStyleType: 'disc', 
          marginLeft: '20px', 
          fontSize: '17px', 
        }}>  
          <li>
            <strong>Analisi del potenziale:</strong> Valuto il tuo background sportivo e accademico per misurare le tue prospettive negli Stati Uniti.
          </li>
          <li>
            <strong>Piano d'azione:</strong> Sviluppo un piano su misura, inclusi un calendario e traguardi, per raggiungere i tuoi obiettivi.
          </li>
          <li>
            <strong>Consulenza strategica:</strong> Consigli su come migliorare le tue performance sportive e accademiche per aumentare le tue possibilità di ottenere una borsa di studio.
          </li>
          <li>
            <strong>Università target:</strong> Insieme, identifichiamo un elenco di università target che si allineano al tuo profilo e alle tue ambizioni.
          </li>
        </ul>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Aggiunto Roboto come famiglia di font
          fontSize: '17px', // Dimensione del font modificata a 17px
          fontWeight: '400', // Assicurati che il peso del font sia normale
        }}>
          Questo piano dettagliato costituisce la base per il tuo viaggio verso una borsa di studio sportiva e assicura che tu sia preparato per ogni passo successivo.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ ...boxStyle, position: 'relative' }} // Aggiungi la posizione relativa allo stile
        contentArrowStyle={arrowStyle}
        date="Fase 3"
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        icon={<VideocamIcon />}
      >
        <div style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          opacity: 0.5 // Opacità ridotta per un effetto filigrana
        }}>
          <Filter3Icon style={{ color: 'grey', fontSize: '48px' }} />
        </div>
        <h3 className="vertical-timeline-element-title">Materiale video e portfolio sportivo:</h3>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Aggiunto Roboto come famiglia di font
          fontSize: '17px', // Dimensione del font modificata a 17px
          fontWeight: '400', // Assicurati che il peso del font sia normale
          marginBottom: '10px', // Regola il valore secondo necessità
        }}>
          Creare un portfolio sportivo convincente e materiale video professionale è fondamentale per la tua visibilità agli allenatori.
        </p>

        <ul style={{ 
          listStyleType: 'disc', 
          marginLeft: '20px', 
          fontSize: '17px', 
        }}>  
          <li>
            <strong>Produzione video:</strong> Fornisco consulenza sul contenuto e supervisiono la produzione di un video di highlight che mostri al meglio le tue capacità.
          </li>
          <li>
            <strong>Sviluppo del portfolio:</strong> Aiuto nella compilazione di un portfolio sportivo completo, inclusi statistiche, risultati e raccomandazioni.
          </li>
          <li>
            <strong>Storia personale:</strong> Il tuo percorso e i tuoi successi sportivi sono intrecciati in un racconto personale che ti distingue dagli altri.
          </li>
          <li>
            <strong>Presentazione digitale:</strong> Assicuro che il tuo portfolio e video siano accessibili digitalmente e condivisibili per allenatori e squadre di scouting.
          </li>
        </ul>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Aggiunto Roboto come famiglia di font
          fontSize: '17px', // Dimensione del font modificata a 17px
          fontWeight: '400', // Assicurati che il peso del font sia normale
        }}>
          Con questo passaggio, ci assicuriamo che il tuo talento sia messo in evidenza in modo inequivocabile e attiri l'attenzione delle università americane.
        </p>
      </VerticalTimelineElement>




      <VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Aggiungi la posizione relativa allo stile
  contentArrowStyle={arrowStyle}
  date="Fase 4"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<QuizIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Opacità ridotta per un effetto filigrana
  }}>
    <Filter4Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Preparazione accademica:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Aggiunto Roboto come famiglia di font
    fontSize: '17px', // Dimensione del font modificata a 17px
    fontWeight: '400', // Assicurati che il peso del font sia normale
    marginBottom: '10px', // Regola il valore secondo necessità
  }}>
    Una buona preparazione accademica è essenziale per l'ammissione e il successo negli Stati Uniti.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Preparazione ai test:</strong> Fornisco materiali di studio e orientamento per i test SAT/ACT e TOEFL/IELTS, essenziali per la tua ammissione.
    </li>
    <li>
      <strong>Pianificazione dello studio:</strong> Insieme, sviluppiamo un piano di studio per prepararti agli esami, senza trascurare il tuo allenamento sportivo.
    </li>
    <li>
      <strong>Documenti di candidatura:</strong> Aiuto nella preparazione e revisione dei tuoi saggi di candidatura e altri documenti richiesti.
    </li>
    <li>
      <strong>Consulenza accademica:</strong> Ricevi consulenza sui requisiti accademici e su come rafforzare il tuo profilo accademico.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Aggiunto Roboto come famiglia di font
    fontSize: '17px', // Dimensione del font modificata a 17px
    fontWeight: '400', // Assicurati che il peso del font sia normale
  }}>
    Il mio obiettivo è assicurarmi che tu sia preparato accademicamente non solo per essere accettato, ma anche per eccellere all'università.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Aggiungi la posizione relativa allo stile
  contentArrowStyle={arrowStyle}
  date="Fase 5"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<SchoolIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Opacità ridotta per un effetto filigrana
  }}>
    <Filter5Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Promozione presso le università:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Aggiunto Roboto come famiglia di font
    fontSize: '17px', // Dimensione del font modificata a 17px
    fontWeight: '400', // Assicurati che il peso del font sia normale
    marginBottom: '10px', // Regola il valore secondo necessità
  }}>
    La promozione del tuo profilo presso le università è un passo chiave dove la mia esperienza e la mia vasta rete entrano in gioco.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Ruolo chiave della rete:</strong> La mia vasta rete all'interno delle università americane è inestimabile per portare alla luce la tua candidatura.
    </li>
    <li>
      <strong>Posizionamento strategico:</strong> Utilizzo le mie conoscenze ed esperienze per posizionare strategicamente il tuo profilo in modo che spicchi tra i giusti programmi sportivi e istituzioni accademiche.
    </li>
    <li>
      <strong>Piano di promozione adattabile:</strong> Creo un piano di promozione flessibile e personalizzato che evidenzia gli aspetti unici del tuo profilo sportivo e accademico.
    </li>
    <li>
      <strong>Massimizzazione delle opportunità:</strong> Attraverso una promozione mirata, mi assicuro che le tue possibilità di ottenere una borsa di studio siano massimizzate, concentrandomi sul miglior adattamento per le tue ambizioni personali e sportive.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Aggiunto Roboto come famiglia di font
    fontSize: '17px', // Dimensione del font modificata a 17px
    fontWeight: '400', // Assicurati che il peso del font sia normale
  }}>
    Il mio valore aggiunto risiede nell'efficace messa in risalto del tuo talento e delle tue aspirazioni, aumentando significativamente le possibilità di successo. Qui la mia rete, la mia comprensione del sistema universitario americano e il mio approccio personale fanno la differenza.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Aggiungi la posizione relativa allo stile
  contentArrowStyle={arrowStyle}
  date="Fase 6"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<RecordVoiceOverIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Opacità ridotta per un effetto filigrana
  }}>
    <Filter6Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Comunicazione con gli allenatori:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    Una comunicazione efficace con gli allenatori è essenziale. Agisco come tuo rappresentante personale per fare una forte prima impressione.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Presentazioni dirette:</strong> Presentazioni personali agli allenatori nella mia rete, adattate alla tua disciplina sportiva.
    </li>
    <li>
      <strong>Strategia di comunicazione:</strong> Sviluppo di una strategia di comunicazione efficace per evidenziare le tue qualità e ambizioni.
    </li>
    <li>
      <strong>Guida alla negoziazione:</strong> Guida nella comunicazione con gli allenatori durante la fase di negoziazione delle borse di studio sportive.
    </li>
    <li>
      <strong>Supporto continuo:</strong> Supporto e consulenza continui in tutte le comunicazioni per assicurarti di fare una impressione positiva e duratura.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Il mio ruolo è quello di fungere da ponte tra te e gli allenatori americani e di creare un canale di comunicazione che massimizzi le tue possibilità di ottenere una borsa di studio sportiva di successo.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Aggiungi la posizione relativa allo stile
  contentArrowStyle={arrowStyle}
  date="Fase 7"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<RuleIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Opacità ridotta per un effetto filigrana
  }}>
    <Filter7Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Selezione e offerte di borse di studio:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    Il processo di selezione è cruciale; qui i frutti del nostro lavoro congiunto si concretizzano in forma di offerte di borse di studio.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Valutazione delle offerte:</strong> Esaminiamo insieme le offerte ricevute e discutiamo i pro e i contro di ciascuna opzione.
    </li>
    <li>
      <strong>Consulenza strategica:</strong> Offro consulenza strategica per aiutarti a prendere la migliore decisione per il tuo futuro sportivo e accademico.
    </li>
    <li>
      <strong>Supporto nella negoziazione:</strong> La mia esperienza nelle negoziazioni viene utilizzata per realizzare le condizioni più favorevoli per te.
    </li>
    <li>
      <strong>Scelta finale:</strong> Con il mio aiuto, fai una scelta finale informata dove inizia la tua carriera sportiva e accademica negli Stati Uniti.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    La mia guida in questa fase mira a garantire il miglior risultato possibile per le tue ambizioni e a garantire il tuo futuro in una università di primo livello in America.
  </p>
  
</VerticalTimelineElement>

<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Aggiungi la posizione relativa allo stile
  contentArrowStyle={arrowStyle}
  date="Fase 8"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<DescriptionIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Opacità ridotta per un effetto filigrana
  }}>
    <Filter8Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Domanda di visto e processo di iscrizione:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    Dopo la selezione, inizia una fase critica: la finalizzazione della tua domanda di visto e delle procedure di iscrizione.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Domanda di visto:</strong> Guida nella richiesta del visto studentesco appropriato, compresa la raccolta della documentazione necessaria.
    </li>
    <li>
      <strong>Assistenza nell'iscrizione:</strong> Aiuto nel completamento di tutti i moduli e documenti di iscrizione per l'università e la competizione scelte.
    </li>
    <li>
      <strong>Pianificazione finanziaria:</strong> Consigli su questioni finanziarie quali tasse per borse di studio, rette universitarie e costi di vita negli Stati Uniti.
    </li>
    <li>
      <strong>Controllo finale:</strong> Un controllo finale approfondito per assicurarsi che tutti gli aspetti amministrativi e logistici siano stati affrontati.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    La mia guida nella preparazione assicura che tu inizi la tua avventura americana con fiducia e ben informato.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Aggiungi la posizione relativa allo stile
  contentArrowStyle={arrowStyle}
  date="Fase 9"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<ChecklistIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Opacità ridotta per un effetto filigrana
  }}>
    <Filter8Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Preparativi per la partenza:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    Una transizione senza problemi verso gli Stati Uniti richiede una preparazione meticolosa; gli ultimi preparativi sono ora in corso.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Lista di controllo:</strong> Una lista di controllo completa per assicurarsi che nulla venga dimenticato, dai documenti essenziali alle necessità personali.
    </li>
    <li>
      <strong>Alloggio:</strong> Assistenza nella ricerca di un alloggio adeguato vicino alla tua università e con le comodità adeguate.
    </li>
    <li>
      <strong>Assicurazione:</strong> Consigli sulle assicurazioni adeguate per salute, viaggio e soggiorno.
    </li>
    <li>
      <strong>Briefing:</strong> Una sessione informativa sulle differenze culturali, la vita negli Stati Uniti e consigli pratici per la vita quotidiana.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    La mia guida nella preparazione assicura che tu inizi la tua avventura americana con fiducia e ben informato.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Aggiungi la posizione relativa allo stile
  contentArrowStyle={arrowStyle}
  date="Fase 10"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<FlightTakeoffIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Opacità ridotta per un effetto filigrana
  }}>
    <Filter9Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Partenza e Arrivo negli Stati Uniti:</h3>
<p style={{
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: '17px',
  fontWeight: '400',
  marginBottom: '10px',
}}>
  L'ultimo passo nella tua preparazione è arrivato: il viaggio verso l'America.
</p>

<ul style={{
  listStyleType: 'disc',
  marginLeft: '20px',
  fontSize: '17px',
}}>
  <li>
    <strong>Preparazione per la Partenza:</strong> Mi assicuro che tu sia completamente preparato per la partenza, con una checklist dettagliata e consigli finali.
  </li>
  <li>
    <strong>Momento dell'Addio:</strong> Un momento per riflettere su questo significativo traguardo e per dire addio prima di perseguire il tuo sogno.
  </li>
  <li>
    <strong>Arrivo negli Stati Uniti:</strong> All'arrivo, offro supporto per aiutarti a stabilirti e navigare nel tuo nuovo ambiente.
  </li>
  <li>
    <strong>Primi Giorni:</strong> I primi giorni sono eccitanti, e mi assicuro che tu riceva il supporto di cui hai bisogno per iniziare bene.
  </li>
</ul>
<p style={{
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: '17px',
  fontWeight: '400',
}}>
  Sto al tuo fianco ad ogni passo, dal decollo nei Paesi Bassi all'arrivo nel campus negli Stati Uniti, pronto a iniziare i tuoi studi e la tua carriera sportiva.
</p>
  
</VerticalTimelineElement>

<VerticalTimelineElement
  iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
  icon={<SportsScoreIcon />}
/>
</VerticalTimeline>

  );
}

export default Timeline;
