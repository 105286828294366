import React, { useState } from "react";
import { Container, Grid, Box, Typography } from "@mui/material";
import MKBadge from "components/MKBadge";
import MKButton from "components/MKButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ExampleCard from "../ExampleCard";
import Toefl_cover from "assets/images/Producten/Toefl_kaft.png";
import Toefl_open from "assets/images/Producten/Toefl_open.png";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";

import MKSnackbar from "components/MKSnackbar";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Link } from 'react-router-dom';


const sportsData = [
  {
    items: [
      {
        image: Toefl_cover,
        alt: "Couverture du guide d'étude TOEFL créé par Sport Scholarships America, avec titre et logo",
      },
      {
        image: Toefl_cover,
        alt: "Couverture du guide d'étude TOEFL créé par Sport Scholarships America, avec titre et logo",
      },
      {
        image: Toefl_open,
        alt: "Guide d'étude TOEFL ouvert de Sport Scholarships America, montrant des pages sur les stratégies de test et les matériaux de pratique",
      },
      {
        image: Toefl_open,
        alt: "Guide d'étude TOEFL ouvert de Sport Scholarships America, montrant des pages sur les stratégies de test et les matériaux de pratique",
      },
    ],
  },
];


function DesignBlocks() {
  const [selectedImage, setSelectedImage] = useState(Toefl_cover);
  const [selectedAlt, setSelectedAlt] = useState("Couverture du guide d'étude TOEFL créé par Sport Scholarships America, avec titre et logo"); // Définir le texte alternatif initial
  const [quantity, setQuantity] = useState(0);
  const [show, setShow] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showModal, setShowModal] = useState(false);


  const handleIncrement = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 0) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const handleAddToCartClick = () => {
    if (quantity === 0) {
      setShowSnackbar(true);
      setShowModal(false); // Assurez-vous que le modal ne s'ouvre pas en même temps
    } else {
      setShowModal(true);
      setShowSnackbar(false); // Assurez-vous que le snackbar ne s'ouvre pas en même temps
    }
  };


  const toggleSnackbar = () => setShow(!show);
  const toggleModal = () => setShow(!show);



  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const renderData = sportsData.map(({ items }) => (
    <Grid container spacing={3} alignItems="flex-start">
      <Grid item xs={12} md={4}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
          <ExampleCard
  image={selectedImage}
  alt={selectedAlt} // Passez le texte alternatif mis à jour ici
  applyGradient={false}
  applyPopOut={false}
/>

          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} justifyContent="center">
            {items.map(({ image, name, alt }) => (
  <Grid item xs={3} sm={3} md={3} key={name}>
    <div
      onClick={() => {
        setSelectedImage(image);
        setSelectedAlt(alt); // Mettre à jour le texte alternatif ici aussi
      }}
      style={{ cursor: "pointer" }}
    >
      <ExampleCard
        image={image}
        alt={alt} // Passage correct du texte alternatif
        selected={selectedImage === image}
      />
    </div>
  </Grid>
))}

            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} container flexDirection="column" alignItems="flex-start" sx={{ textAlign: "left", my: 0, pl: { xs: 0, md: 4 } }}>
        <MKBadge 
          badgeContent="Sport Scholarships America" 
          variant="contained" 
          color="info" 
          container 
          circular 
          sx={{ mb: isSmallScreen ? 0 : 2, marginLeft: isSmallScreen ? 0 : 4 }} 
        />
        <Typography variant="h1" fontWeight="bold" sx={{ pb: isSmallScreen ? 0 : 2, marginLeft: isSmallScreen ? 0 : 4 }}>
          Guide d'étude TOEFL
        </Typography>
        <Typography variant="body1" color="text" sx={{ pb: isSmallScreen ? 0 : 2, marginLeft: isSmallScreen ? 0 : 4 }}>
          Ce guide couvre en profondeur toutes les sections essentielles du TOEFL :
          <ul style={{ paddingLeft: "30px", paddingTop: "10px", paddingBottom: "10px" }}> {/* Augmentez la valeur du padding selon vos besoins */}
            <li>
              <strong>Lecture :</strong> Stratégies de lecture efficaces et réponse précise aux questions.
            </li>
            <li>
              <strong>Écoute :</strong> Compétences d'écoute améliorées avec capacité de prise de notes.
            </li>
            <li>
              <strong>Parole :</strong> Maîtrise de la parole et réponse aux questions orales.
            </li>
            <li>
              <strong>Écriture :</strong> Compétences en écriture pour les essais et les discussions en ligne.
            </li>
          </ul>
          Ce guide d'étude comprend des questions de pratique, des réponses détaillées et des idées précieuses. Préparez-vous minutieusement à chaque aspect de l'examen TOEFL !
        </Typography>
        <Typography variant="h3" sx={{ pb: isSmallScreen ? 0 : 2, marginLeft: isSmallScreen ? 0 : 4 }}>
          €10.00
        </Typography>
        <Grid container spacing={2} alignItems="flex-start" sx={{ marginLeft: isSmallScreen ? 0 : 2 }}>
          <Grid item>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid lightgray",
                padding: "5px 10px",
                marginTop: "20px",
                backgroundColor: "lightgrey",
                width: "200px",
                borderRadius: "15px",
                height: "48px",
              }}
            >
              <RemoveIcon onClick={handleDecrement} style={{ cursor: "pointer" }} color=""/>
              <span style={{ color: "" }}>{quantity}</span>
              <AddIcon onClick={handleIncrement} style={{ cursor: "pointer" }} color=""/>
            </div>
          </Grid>
          <Grid item>
            <MKButton 
              color="info" 
              style={{ height: "48px", marginTop: "20px" }} 
              startIcon={<AddShoppingCartIcon />}
              onClick={handleAddToCartClick} // Déplacé onClick ici
            >
              Ajouter au panier
            </MKButton>
          </Grid>
          <MKSnackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            color="primary"
            icon="notifications"
            title="Sport Scholarships America"
            content="Oops, vous ne pouvez pas ajouter 0 produits. Veuillez entrer une quantité valide."
            dateTime="Il y a 1 minute"
            open={show}
            close={toggleSnackbar}
          />
        </Grid>
      </Grid>
    </Grid>
  ));

  return (
    <Box component="section" my={6} py={6}>
      <Container sx={{ mt: 0 }}>{renderData}</Container>

      <MKSnackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        color="primary"
        icon="notifications"
        title="Sport Scholarships America"
        content="Oops, vous ne pouvez pas ajouter 0 produits. Veuillez entrer une quantité valide."
        dateTime="Il y a 0 minute"
        open={showSnackbar}
        close={() => setShowSnackbar(false)}
      />

      <Modal 
        open={showModal} 
        onClose={() => setShowModal(false)} 
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Slide direction="down" in={showModal} timeout={500}>
          <MKBox
            position="relative"
            width="500px"
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
          >
            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
              <MKTypography variant="h5">Temporairement en rupture de stock
              </MKTypography>
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={() => setShowModal(false)} />
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox p={2}>
              <MKTypography variant="body2" color="secondary" fontWeight="regular">
                Malheureusement, le produit que vous avez sélectionné est actuellement en rupture de stock. Je m'excuse pour le désagrément. Je travaille dur pour reconstituer le stock dès que possible.
                <br/>
                <br/>
                Si vous avez des questions ou avez besoin de plus d'informations, n'hésitez pas à me contacter. Je suis là pour aider !
              </MKTypography>
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox display="flex" justifyContent="space-between" p={1.5}>
              <MKButton variant="gradient" color="dark" onClick={() => setShowModal(false)}>
                Fermer
              </MKButton>
              <MKButton 
                component={Link} 
                to="/fr/Formulaire-de-contact" 
                variant="gradient" 
                color="info"
              >
                Formulaire de contact
              </MKButton>
            </MKBox>
          </MKBox>
        </Slide>
      </Modal>

    </Box>
  );
}

export default DesignBlocks;
