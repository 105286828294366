/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React components
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Jeugd.png";
import post2 from "assets/images/TijdUS.png";
import post3 from "assets/images/Deal.png";

function Post2() {
  return (
    <MKBox component="section" py={0}>
      <Container>
        <Grid container item xs={12} lg={4}>
          <MKTypography variant="h3" mb={6}>
          Lisez mon histoire          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
        <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <TransparentBlogCard
              image={post1}
              title="Partie 1: Grandir aux Pays-Bas"
              description="Dans l'environnement enchanteur de Nijmegen, a commencé mon histoire d'amour de toute une vie avec le football.  ..."
              action={{
                type: "internal",
                route: "/fr/À-propos-de-moi/Grandir",
                color: "info",
                label: "Lire La Suite",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <TransparentBlogCard
              image={post2}
              title="Partie 2 : Mon Temps en Amérique"
              description=" Mon temps en Amérique a été comme un voyage époustouflant, rempli d'expériences inoubliables et de croissance ..."
              action={{
                type: "internal",
                route: "/fr/À-propos-de-moi/Mon-temps-en-Amérique",
                color: "info",
                label: "Lire La Suite",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <TransparentBlogCard
              image={post3}
              title="Partie 3: De Retour aux Pays-Bas"
              description="Dans l'environnement enchanteur de Nijmegen, a commencé mon histoire d'amour de toute une vie avec le football. ..."
              action={{
                type: "internal",
                route: "/fr/À-propos-de-moi/Grandir",
                color: "info",
                label: "Lire La Suite",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Post2;

