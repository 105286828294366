/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Swimming1.jpg";
import Image2 from "assets/images/Sports_Images/Swimming2.jpeg";
import Image3 from "assets/images/Sports_Images/Swimming3.avif";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Natación en América: Cómo Obtener una Beca Deportiva y Ser Parte de una Historia Deportiva Prestigiosa', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capítulo 1: La Historia y Desarrollo de la Natación en América', isSubheader: false },
    { id: '1.1', title: '1.1 Primeros Años y el Papel de las Universidades', isSubheader: true },
    { id: '1.2', title: '1.2 Crecimiento y Profesionalización', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capítulo 2: La Natación Universitaria Hoy', isSubheader: false },
    { id: '2.1', title: '2.1 Competiciones y Conferencias Clave', isSubheader: true },
    { id: '2.2', title: '2.2 Programas Principales y Equipos Prestigiosos', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capítulo 3: Desde la Universidad hasta el Nivel Profesional', isSubheader: false },
    { id: '3.1', title: '3.1 El Camino hacia el Profesionalismo', isSubheader: true },
    { id: '3.2', title: '3.2 La Natación Profesional y los Juegos Olímpicos', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capítulo 4: Influencias y Competencias Internacionales', isSubheader: false },
    { id: '4.1', title: '4.1 Influencias Extranjeras', isSubheader: true },
    { id: '4.2', title: '4.2 Estadounidenses en el Extranjero', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capítulo 5: Becas Deportivas y el Futuro de los Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 La Importancia de las Becas', isSubheader: true },
    { id: '5.2', title: '5.2 Cómo Puede Ayudar Sport Scholarships America', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contenidos
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Ocultar</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>


       <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Natación en América: Cómo Obtener una Beca Deportiva y Ser Parte de una Historia Deportiva Prestigiosa
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Estadio de natación lleno de espectadores durante una competencia universitaria."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Un estadio durante un partido
      </figcaption>
    </figure>
    La natación es uno de los deportes más populares y competitivos del mundo. Millones de personas disfrutan de la natación, tanto recreativa como profesionalmente. ¿Pero sabías que la natación en América ha alcanzado un nivel completamente diferente? La natación en América no es solo un deporte; es una cultura, una tradición y un estilo de vida.
    <br />
    <br />
    En este artículo, te contaremos todo sobre el desarrollo de la natación como deporte competitivo en los Estados Unidos, la importancia de la natación universitaria para el avance del deporte y cómo obtener una beca deportiva para estudiar y nadar en América. También profundizaremos en el papel de Sportbeursamerika.nl, una organización que ayuda a los jóvenes nadadores a hacer realidad sus sueños.
    <br />
    <br />
    La natación es un deporte que se practica desde hace siglos, pero no fue hasta el siglo XIX que comenzó a desarrollarse como una actividad organizada y regulada. Los primeros Juegos Olímpicos modernos en 1896 incluyeron competiciones de natación como uno de los eventos principales, y desde entonces, la natación ha sido un elemento básico de la agenda olímpica.
    <br />
    <br />
    Pero, ¿por qué la natación es tan popular y exitosa en América? ¿Qué la hace tan especial y única? La respuesta radica en el papel de las universidades en la promoción de la natación como una disciplina académica y atlética. Las universidades estadounidenses no solo ofrecen educación de alta calidad, sino también excelentes instalaciones, entrenamiento y orientación para los estudiantes atletas que buscan especializarse en natación. Además, brindan apoyo financiero en forma de becas deportivas, lo que permite a los nadadores talentosos alcanzar su potencial sin preocuparse por los costos de su educación.
    <br />
    <br />
    Sin embargo, las becas deportivas no son fáciles de obtener. Son altamente competitivas y requieren un alto nivel de dedicación, disciplina y rendimiento. Por lo tanto, es esencial estar bien preparado y buscar ayuda profesional al solicitar una beca deportiva. Ahí es donde entra en juego Sportbeursamerika.nl.
    <br />
    <br />
    Sportbeursamerika.nl es una organización especializada en guiar a los jóvenes nadadores hacia becas deportivas en América. Tienen un equipo de expertos que pueden ayudarte con todos los aspectos del proceso, como elegir la universidad adecuada, preparar tu currículum y carta de motivación, prepararte para exámenes de admisión y entrevistas, tramitar tu visa y seguro, y mucho más. También tienen conexiones con cientos de universidades y entrenadores en América, lo que les permite emparejarte con las mejores opciones para tu perfil y objetivos.
    <br />
    <br />
    Entonces, si sueñas con estudiar y nadar en América, Sportbeursamerika.nl es tu mejor aliado. Ya han ayudado a cientos de nadadores a hacer realidad sus sueños, y pueden ayudarte a ti también. Pero antes de contactarlos, echemos un vistazo a la historia y el desarrollo de la natación en América y por qué es una oportunidad tan fantástica para ti.
  </MKTypography>
</MKBox>

<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 1: La Historia y Desarrollo de la Natación en América
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    La natación tiene una larga y rica historia en América. Todo comenzó a fines del siglo XIX cuando las universidades comenzaron a formar sus propios equipos de natación y competiciones. Esto marcó el comienzo de una tradición que llevaría al surgimiento de la natación como un deporte nacional.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Primeros Años y el Papel de las Universidades
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La primera universidad en establecer un equipo oficial de natación fue Harvard en 1878. Poco después, otras prestigiosas universidades como Yale, Princeton, Columbia y Cornell siguieron su ejemplo. Estas universidades organizaban partidos y torneos intercolegiales que llamaban la atención del público y los medios de comunicación. También introdujeron nuevas reglas y técnicas para la natación, incluyendo el estilo braza, espalda y mariposa.
      <br />
      <br />
      En 1902, se fundó la Asociación Intercolegial de Natación (ISA), convirtiéndose en la primera organización nacional para la natación universitaria. La ISA organizaba campeonatos anuales para hombres, donde se homenajeaban a los mejores equipos y nadadores individuales. En 1916, se estableció la Asociación Atlética Nacional Universitaria (NCAA) como la organización general para todos los deportes universitarios. La NCAA asumió la responsabilidad de organizar los campeonatos nacionales de natación universitaria, que continúan hasta hoy.
      <br />
      <br />
      La natación universitaria no se limitaba a los hombres. Las mujeres también comenzaron a competir a nivel universitario a principios del siglo XX, aunque enfrentaban más restricciones y discriminación. Los primeros equipos universitarios femeninos de natación se formaron en 1914 en la Universidad de Chicago y en 1916 en la Universidad de Wisconsin. En 1922, se fundó la Asociación Intercolegial Femenina de Natación (WISA), que servía como equivalente de la ISA para mujeres. La WISA también organizaba campeonatos anuales para mujeres, que ganaron una gran popularidad.
      <br />
      <br />
      La natación universitaria jugó un papel crucial en la promoción de la natación como un deporte profesional en América. Muchos nadadores universitarios pasaron a ligas profesionales y los Juegos Olímpicos, donde representaron a su país y ganaron medallas. Algunos de los nombres más conocidos incluyen a Johnny Weissmuller, Mark Spitz, Matt Biondi, Janet Evans, Michael Phelps y Katie Ledecky.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crecimiento y Profesionalización
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Además de la natación universitaria, surgieron otras formas de natación profesional en América. Una de ellas fue la natación de maestros, que se centraba en nadadores adultos que buscaban mejorar sus habilidades o mantener su forma física. La natación de maestros comenzó en 1970 con el establecimiento de la Asociación Estadounidense de Natación de Maestros (USMS), una organización que ofrecía clubes locales, competiciones y programas para nadadores adultos. La natación de maestros rápidamente se convirtió en un movimiento global, con millones de miembros en más de 100 países.
      <br />
      <br />
      Otra forma de natación profesional fue la natación en aguas abiertas, que se llevaba a cabo en cuerpos de agua naturales como lagos, ríos y océanos. La natación en aguas abiertas era un deporte desafiante y aventurero que requería resistencia, habilidades de navegación y adaptabilidad. La natación en aguas abiertas ganó más reconocimiento en 2008 cuando se agregó como un evento olímpico. Algunos de los nadadores en aguas abiertas más famosos incluyen a Gertrude Ederle, Lynne Cox, Maarten van der Weijden y Ous Mellouli.
      <br />
      <br />
      La natación profesional alcanzó su punto máximo durante los Juegos Olímpicos, donde los nadadores estadounidenses dominaron. Desde 1896, América ha ganado más de 500 medallas en natación, incluyendo más de 200 medallas de oro. Esto convierte a América, por mucho, en el país más exitoso en natación olímpica.
      <br />
      <br />
      Pero, ¿cómo puedes ser parte de esta gloriosa historia deportiva? ¿Cómo puedes estudiar y nadar en América con una beca deportiva? Te lo diremos en el próximo capítulo.
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  






<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 2: La Natación Universitaria Hoy
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Como has visto, la natación universitaria tiene una larga y orgullosa historia en América. Pero, ¿qué hay del presente? ¿Cómo se organiza y estructura la natación universitaria hoy? ¿Cuáles son las competiciones y conferencias clave? ¿Cuáles son los programas principales y los equipos prestigiosos? Echemos un vistazo más de cerca.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Competiciones y Conferencias Clave
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Katie Ledecky nadando para Stanford en una competencia universitaria."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Katie Ledecky en acción como nadadora para Stanford
        </figcaption>
      </figure>
      La natación universitaria en América está principalmente regulada por la NCAA, que tiene tres divisiones: División I, División II y División III. Cada división tiene sus propias reglas, criterios y campeonatos. En general, la División I es la más competitiva y prestigiosa, con los mejores nadadores, entrenadores e instalaciones. La División II y III son menos competitivas pero aún ofrecen una educación y deportes de alta calidad.
      <br />
      <br />
      Dentro de cada división, hay varias conferencias, que son grupos de universidades que están conectadas geográfica o históricamente. Cada conferencia organiza sus propios campeonatos, y los mejores equipos y nadadores individuales se clasifican para los campeonatos nacionales de la NCAA. Algunas de las conferencias más conocidas en la natación universitaria incluyen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          La Conferencia del Sureste (SEC), que consiste en 14 universidades en el sureste de los Estados Unidos, como Alabama, Florida, Georgia, Kentucky, LSU, Tennessee y Texas A&M. La SEC es conocida por su dominio en la natación, especialmente en eventos masculinos. La SEC ha ganado más de 50 títulos nacionales en natación, con más de 40 de ellos provenientes del equipo masculino de Florida.
        </li>
        <li>
          La Conferencia del Pacífico-12 (Pac-12), que comprende 12 universidades en la costa oeste de América, incluidas Arizona, California, Stanford, UCLA, USC y Washington. La Pac-12 es conocida por su excelencia en natación, especialmente en eventos femeninos. La Pac-12 ha ganado más de 40 títulos nacionales en natación, con más de 30 de ellos pertenecientes al equipo femenino de Stanford.
        </li>
        <li>
          La Conferencia del Big Ten (Big Ten), que consiste en 14 universidades en el Medio Oeste y el Noreste de los Estados Unidos, como Indiana, Michigan, Minnesota, Ohio State, Penn State y Wisconsin. El Big Ten es conocido por su diversidad y equilibrio en la natación, con equipos fuertes tanto en eventos masculinos como femeninos. El Big Ten ha ganado más de 30 títulos nacionales en natación, con más de 20 de ellos asegurados por el equipo masculino de Michigan.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estos son solo algunos ejemplos de las muchas conferencias que existen en la natación universitaria. Cada conferencia tiene su propia cultura, rivalidades y tradiciones, lo que contribuye a la emoción de la natación universitaria.
    </MKTypography>

  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programas Principales y Equipos Prestigiosos
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Además de las conferencias, hay universidades individuales que se destacan debido a sus programas principales y equipos de natación prestigiosos. Estas universidades no solo ofrecen una excelente educación a sus estudiantes, sino que también brindan entrenamiento, entrenamiento e instalaciones de primera categoría para sus atletas. También atraen a muchos nadadores talentosos tanto de origen nacional como internacional que desean estudiar y nadar al más alto nivel.
      <br />
      <br />
      Algunas de estas principales universidades incluyen:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Universidad de Stanford: Stanford es una de las universidades más renombradas del mundo académica y atléticamente. Stanford tiene uno de los mejores programas de natación femenina en América, con más de 10 títulos nacionales y más de 100 medallas olímpicas. Stanford también tiene un sólido programa de natación masculina, con más de 5 títulos nacionales y más de 50 medallas olímpicas. Algunos exalumnos famosos de Stanford incluyen a John Hencken, Pablo Morales, Jenny Thompson, Summer Sanders, Misty Hyman, Janet Evans y Katie Ledecky.
        </li>
        <li>
          Universidad de California: California es otra universidad principal tanto académica como atléticamente. California tiene uno de los mejores programas de natación masculina en América, con más de 10 títulos nacionales y más de 100 medallas olímpicas. California también tiene un sólido programa de natación femenina, con más de 5 títulos nacionales y más de 50 medallas olímpicas. Algunos exalumnos famosos de California incluyen a Matt Biondi, Anthony Ervin, Natalie Coughlin, Nathan Adrian, Dana Vollmer y Ryan Murphy.
        </li>
        <li>
          Universidad de Texas: Texas es una de las universidades más grandes e influyentes de América, con una sólida reputación en académicos y deportes. Texas tiene uno de los mejores programas de natación masculina en América, con más de 15 títulos nacionales y más de 100 medallas olímpicas. Texas también tiene un sólido programa de natación femenina, con más de 5 títulos nacionales y más de 50 medallas olímpicas. Algunos exalumnos famosos de Texas incluyen a Josh Davis, Aaron Peirsol, Ian Crocker, Brendan Hansen, Ricky Berens y Joseph Schooling.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estas son solo algunas de las muchas principales universidades en la natación universitaria. Cada universidad tiene su propia historia, filosofía y visión que contribuyen al desarrollo de los nadadores.
      <br />
      <br />
      Como puedes ver, la natación universitaria hoy en día es un deporte altamente dinámico y competitivo que ofrece muchas oportunidades para estudiantes-atletas que desean sobresalir académica y atléticamente. ¿Pero cómo puedes acceder a estas oportunidades? ¿Cómo puedes obtener una beca deportiva para estudiar y nadar en América? Te lo diremos en el próximo capítulo.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  





<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 3: Desde la Universidad hasta el Nivel Profesional
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Estudiar y nadar en América no solo es una gran experiencia, sino también una oportunidad fantástica para iniciar tu carrera como nadador profesional. Muchos nadadores universitarios hacen la transición al nivel profesional, donde representan a su país en competiciones internacionales y en los Juegos Olímpicos. Pero, ¿cómo das el salto de la universidad al nivel profesional? ¿Cuáles son los desafíos y beneficios de esta transición? Veámoslo más de cerca.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 El Camino hacia el Profesionalismo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Para hacer la transición de la universidad al nivel profesional, primero debes cumplir con los requisitos de la NCAA. La NCAA tiene reglas estrictas con respecto al estatus amateur de los estudiantes atletas, lo que significa que no puedes ganar ni aceptar dinero por tu desempeño atlético. Esto significa que no puedes firmar contratos de patrocinio, acuerdos de premios en efectivo u otras formas de compensación mientras estudias y nadas a nivel universitario.
      <br />
      <br />
      Sin embargo, una vez que hayas completado tus estudios o decidas finalizar tu educación, puedes renunciar a tu estatus amateur y convertirte en un nadador profesional. Esto significa que puedes unirte a una organización de natación profesional, como USA Swimming, que es el organismo rector nacional de la natación en América. USA Swimming te brinda la oportunidad de participar en competiciones de natación profesional como la Serie Pro Swim, el Abierto de Estados Unidos, los Nacionales de Estados Unidos y las Pruebas Olímpicas. Estas competiciones están diseñadas para mejorar tus habilidades, aumentar tu clasificación y calificarte para eventos internacionales.
      <br />
      <br />
      Como nadador profesional, también puedes beneficiarte de contratos de patrocinio, premios en efectivo y otras formas de compensación por tu desempeño atlético. Esto puede ayudar a mejorar tu situación financiera, apoyar tu estilo de vida y aumentar tu motivación. Sin embargo, convertirse en un nadador profesional también conlleva más responsabilidades y desafíos. Por ejemplo, deberás administrar tu propio horario de entrenamiento, plan de nutrición, gastos de viaje, atención médica e impuestos. También tendrás que lidiar con una mayor competencia, presión y expectativas de ti mismo y de los demás.
      <br />
      <br />
      Por lo tanto, es crucial estar bien preparado para la transición de la universidad al nivel profesional. No solo debes poseer excelentes habilidades de natación, sino también tener una fuerte actitud mental, objetivos claros y buen apoyo. También debes ser realista sobre tus posibilidades y oportunidades, y estar dispuesto a trabajar duro y hacer sacrificios.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 La Natación Profesional y los Juegos Olímpicos
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Uno de los mayores sueños de todo nadador profesional es participar en los Juegos Olímpicos, el evento deportivo más prestigioso y significativo del mundo. Los Juegos Olímpicos no solo ofrecen la oportunidad de representar a tu país y ganar medallas, sino también de hacer historia y convertirte en una leyenda.
      <br />
      <br />
      Sin embargo, para calificar para los Juegos Olímpicos, primero debes cumplir con los requisitos del Comité Olímpico Internacional (COI) y la Federación Internacional de Natación (FINA). Estas organizaciones tienen reglas estrictas con respecto a los criterios de clasificación, el número de participantes por país, los controles antidopaje y otros aspectos de la natación olímpica.
      <br />
      <br />
      Uno de los requisitos clave para calificar para los Juegos Olímpicos es alcanzar un estándar de tiempo específico en una competición reconocida. Este estándar de tiempo es determinado por FINA en función del promedio de las 16 mejores actuaciones a nivel mundial en cada disciplina durante los últimos cuatro años. El estándar de tiempo varía según el género, grupo de edad y estilo.
      <br />
      <br />
      Otro requisito esencial para calificar para los Juegos Olímpicos es ser seleccionado por tu organización nacional de natación, como USA Swimming. Cada organización nacional de natación tiene su propio proceso de selección, que generalmente implica una serie de pruebas o campeonatos donde se eligen a los mejores nadadores para representar al país. Cada organización nacional de natación también tiene un número limitado de plazas por disciplina, dependiendo del número de nadadores calificados y las cuotas establecidas por el COI y FINA.
      <br />
      <br />
      Si logras cumplir con estos requisitos y calificar para los Juegos Olímpicos, eres uno de los afortunados que tienen la oportunidad de ser parte del evento deportivo más grande del mundo. Podrás competir contra los mejores nadadores del mundo, desafiarte a ti mismo y potencialmente ganar una medalla y grabar tu nombre en los libros de historia.
      <br />
      <br />
      Como puedes ver, la transición de la universidad al nivel profesional es un viaje emocionante y gratificante que ofrece muchas oportunidades para tu carrera en la natación. Pero, ¿cómo puedes comenzar este viaje? ¿Cómo puedes obtener una beca deportiva para estudiar y nadar en América? Te lo diremos en el próximo capítulo.
    </MKTypography>
  </MKBox>
</MKBox>




                                  







<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 4: Influencias y Competencias Internacionales
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    La natación en América no es solo un deporte nacional, sino también internacional. La natación en América está influenciada por y también influye en otros países y culturas, cada uno de los cuales tiene sus propios estilos, técnicas y tradiciones en la natación. La natación en América también se ve desafiada y enriquecida por otras competencias internacionales, brindando oportunidades para aprender y competir contra nadadores de todo el mundo. Pero, ¿cómo se originaron y desarrollaron estas influencias y competencias internacionales? ¿Cómo afectan a la natación colegial y profesional en América? Veámoslo más de cerca.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Influencias Extranjeras
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Nadador en acción durante el campeonato nacional de la NCAA."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          El campeonato nacional de la NCAA
        </figcaption>
      </figure>
      La natación es un deporte universal practicado en casi todos los países del mundo. Cada país tiene su propia historia, cultura e identidad en la natación, reflejada en cómo nadan, entrenan y entrenan a sus nadadores. Algunos países han tenido una influencia significativa en la natación en América, tanto positiva como negativamente.
      <br />
      <br />
      Una de las influencias positivas es Australia, que es uno de los mayores rivales y aliados de América en la natación. Australia tiene una larga y orgullosa tradición en la natación, con más de 100 medallas olímpicas y más de 20 récords mundiales. Australia es conocida por su innovación y creatividad en la natación, introduciendo nuevas técnicas, estrategias y métodos. Australia también ha producido muchos nadadores talentosos y carismáticos, como Dawn Fraser, Shane Gould, Ian Thorpe, Grant Hackett, Libby Trickett y Cate Campbell.
      <br />
      <br />
      Australia ha influenciado la natación en América creando una competencia y colaboración saludables que motivan a ambos países a mejorar y aprender el uno del otro. Muchos nadadores australianos y estadounidenses han entrenado, estudiado o trabajado juntos, mejorando sus habilidades, conocimientos y amistades. Algunos ejemplos incluyen:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          En 1976, Mark Spitz, la estrella estadounidense que ganó siete medallas de oro en Munich, entrenó junto a John Devitt, la leyenda australiana que ganó dos medallas de oro en Melbourne y Roma.
        </li>
        <li>
          En 1988, Matt Biondi, la sensación estadounidense que ganó siete medallas en Seúl, se graduó de la Universidad de California con un título en economía política.
        </li>
        <li>
          En 2016, Joseph Schooling, la sorpresa de Singapur que ganó la primera medalla de oro para su país en los 100 metros mariposa en Río de Janeiro, se graduó de la Universidad de Texas con un título en economía.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estos son solo algunos ejemplos de cómo las influencias extranjeras han impactado en la natación en América. Muchos más países y culturas han dejado su huella en la natación, incluyendo Japón, China, Rusia, Francia, Brasil y Sudáfrica. La natación en América no es solo un deporte nacional, sino también internacional, que evoluciona y cambia constantemente a través de la interacción con otros países y culturas.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Estadounidenses en el Extranjero
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La natación en América no solo está influenciada por y también influye en otros países y culturas, sino que también se ve desafiada y enriquecida por otras competencias internacionales. La natación en América brinda la oportunidad de aprender y competir contra nadadores de todo el mundo, cada uno con sus propios estilos, técnicas y tradiciones en la natación. La natación en América también ofrece la oportunidad de viajar y experimentar diferentes lugares, personas y culturas, ampliando tus horizontes y cambiando tu perspectiva.
      <br />
      <br />
      Una de las principales competencias internacionales en las que participan los nadadores estadounidenses son los Campeonatos Mundiales de Natación, que se celebran cada dos años por FINA. Los Campeonatos Mundiales de Natación son un evento que abarca todas las disciplinas de natación, incluyendo piscina larga, piscina corta, aguas abiertas, natación sincronizada y saltos. Los Campeonatos Mundiales de Natación brindan la oportunidad de competir contra los mejores nadadores del mundo, mejorar tu clasificación y aumentar tu calificación para otros eventos.
      <br />
      <br />
      Otra competencia internacional importante en la que participan los nadadores estadounidenses es la Copa del Mundo de Natación, que se celebra anualmente por FINA. La Copa del Mundo de Natación es una serie de eventos en piscina corta que tienen lugar en varias ciudades de todo el mundo. La Copa del Mundo de Natación es una oportunidad para competir contra diferentes oponentes, poner a prueba tus habilidades y ganar dinero en premios.
      <br />
      <br />
      Una tercera competencia internacional importante en la que participan los nadadores estadounidenses son los Juegos Panamericanos, que se celebran cada cuatro años por la Organización Deportiva Panamericana (ODEPA). Los Juegos Panamericanos son un evento multideportivo que reúne a atletas de América del Norte, América del Sur y América Central. Los Juegos Panamericanos son una oportunidad para representar a tu continente, mostrar solidaridad y ganar medallas.
      <br />
      <br />
      Estos son solo algunos ejemplos de las muchas competencias internacionales en las que participan los nadadores estadounidenses. Hay muchas más competencias internacionales en natación, como los Juegos de la Commonwealth, los Campeonatos Europeos, los Juegos Asiáticos y los Juegos Africanos.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  

<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Becas Deportivas y el Futuro de los Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Las becas deportivas ofrecen numerosas oportunidades para que los jóvenes atletas muestren su talento y desarrollen sus habilidades. Una de las oportunidades más significativas es obtener una beca deportiva para estudiar y competir a nivel universitario. En este capítulo, discutiremos la importancia de las becas deportivas y cómo Sport Scholarships America puede ayudarte a hacer realidad tu sueño.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 La Importancia de las Becas
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Una beca deportiva es un apoyo financiero proporcionado por una universidad a un estudiante-atleta para combinar estudios y deporte. Puede cubrir los costos de matrícula, libros, alojamiento, comidas y otros gastos. Además, puede ofrecer beneficios como tutoría académica, atención médica, instalaciones de entrenamiento y equipo.
      <br />
      <br />
      Obtener una beca deportiva es competitivo, con muchos factores que influyen en el proceso de adjudicación, incluyendo logros académicos, rendimiento deportivo, personalidad, motivación, liderazgo y adaptabilidad. Por lo tanto, es importante prepararse bien y destacarse entre la competencia.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Cómo Puede Ayudar Sport Scholarships America
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo de Sport Scholarships America, que muestra equipamiento deportivo y una gorra de graduación, representando el enfoque en becas deportivas para estudiantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America es una organización especializada en guiar a jóvenes talentos deportivos hacia becas deportivas. Con expertos apasionados y experimentados, Sport Scholarships America apoya a los estudiantes-atletas en cada paso del camino, desde la orientación inicial hasta la colocación final. Sus servicios incluyen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Una entrevista personal para discutir los deseos, posibilidades y expectativas del estudiante-atleta.</li>
        <li>Una evaluación profesional del nivel académico y deportivo del estudiante-atleta.</li>
        <li>Una extensa red de contactos con entrenadores, universidades y colegios.</li>
        <li>Promoción efectiva del perfil y videos del estudiante-atleta ante entrenadores potenciales.</li>
        <li>Asesoramiento experto sobre las mejores opciones basadas en las preferencias, metas y presupuesto del estudiante-atleta.</li>
        <li>Negociación de las mejores ofertas de becas en nombre del estudiante-atleta.</li>
        <li>Asistencia en la solicitud de documentos necesarios, como visa, expediente académico, diploma y pruebas de admisión.</li>
        <li>Preparación para la vida del estudiante-atleta durante su beca, incluyendo cultura, idioma, reglas y consejos.</li>
        <li>Seguimiento y atención posterior durante el período de la beca.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      ¿Quieres explorar oportunidades de becas y alcanzar tus metas deportivas y académicas? <Link to="/es/Servicios-Productos">Consulta la gama completa</Link> de servicios ofrecidos por Sport Scholarships America y programa una consulta hoy mismo.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;