import americanFootball from "assets/images/SportsIcons/americanfootball.png";
import athletics from "assets/images/SportsIcons/atletiek.png";
import basketball from "assets/images/SportsIcons/basketbal.png";
import golf from "assets/images/SportsIcons/golf.png";
import fieldHockey from "assets/images/SportsIcons/hockey.png"; // Updated to Field Hockey
import baseball from "assets/images/SportsIcons/honkbal.png";
import rowing from "assets/images/SportsIcons/roeien.png";
import softball from "assets/images/SportsIcons/softbal.png"
import tennis from "assets/images/SportsIcons/tennis.png";
import gymnastics from "assets/images/SportsIcons/turnen.png";
import soccer from "assets/images/SportsIcons/voetbal.png";
import volleyball from "assets/images/SportsIcons/volleybal.png";
import waterPolo from "assets/images/SportsIcons/waterpolo.png";
import wrestling from "assets/images/SportsIcons/worstelen.png";
import swimming from "assets/images/SportsIcons/zwemmen.png";
import iceHockey from "assets/images/SportsIcons/ijshockey.png";

export default [
  {
    title: "Sports",
    description: "A selection of 16 sports for which I offer guidance towards a sports scholarship",
    items: [
      {
        image: americanFootball,
        name: "American Football",
        badgeContent: "Men",
        secondBadgeContent: "",
        route: "/All-Sports/American-Football",
        popularity: 13,
        altText: "Icon of American football"
      },
      {
        image: basketball,
        name: "Basketball",
        badgeContent: "Men",
        secondBadgeContent: "Women",
        route: "/All-Sports/Basketball",
        popularity: 10,
        altText: "Icon of basketball"
      },
      {
        image: baseball,
        name: "Baseball",
        badgeContent: "Men",
        secondBadgeContent: "",
        route: "/All-Sports/Baseball",
        popularity: 15,
        altText: "Icon of baseball"
      },
      {
        image: golf,
        name: "Golf",
        badgeContent: "Men",
        secondBadgeContent: "Women",
        route: "/All-Sports/Golf",
        popularity: 5,
        altText: "Icon of golf"
      },
      {
        image: gymnastics,
        name: "Gymnastics",
        badgeContent: "Men",
        secondBadgeContent: "Women",
        route: "/All-Sports/Gymnastics",
        popularity: 7,
        altText: "Icon of gymnastics"
      },
      {
        image: iceHockey,
        name: "Ice Hockey",
        badgeContent: "Men",
        secondBadgeContent: "Women",
        route: "/All-Sports/Ice-Hockey",
        popularity: 14,
        altText: "Icon of ice hockey"
      },
      {
        image: fieldHockey, // Updated to Field Hockey
        name: "Field Hockey", // Updated to Field Hockey
        badgeContent: "",
        secondBadgeContent: "Women",
        route: "/All-Sports/Field-Hockey", // Updated to Field Hockey
        popularity: 6,
        altText: "Icon of field hockey" // Updated to Field Hockey
      },
      {
        image: rowing,
        name: "Rowing",
        badgeContent: "Men",
        secondBadgeContent: "Women",
        route: "/All-Sports/Rowing",
        popularity: 8,
        altText: "Icon of rowing"
      },
      {
        image: soccer,
        name: "Soccer",
        badgeContent: "Men",
        secondBadgeContent: "Women",
        route: "/All-Sports/Soccer",
        popularity: 1,
        altText: "Icon of soccer"
      },
      {
        image: softball,
        name: "Softball",
        badgeContent: "",
        secondBadgeContent: "Women",
        route: "/All-Sports/Softball",
        popularity: 12,
        altText: "Icon of softball"
      },
      {
        image: swimming,
        name: "Swimming",
        badgeContent: "Men",
        secondBadgeContent: "Women",
        route: "/All-Sports/Swimming",
        popularity: 3,
        altText: "Icon of swimming"
      },
      {
        image: tennis,
        name: "Tennis",
        badgeContent: "Men",
        secondBadgeContent: "Women",
        route: "/All-Sports/Tennis",
        popularity: 2,
        altText: "Icon of tennis"
      },
      {
        image: athletics,
        name: "Track and Field",
        badgeContent: "Men",
        secondBadgeContent: "Women",
        route: "/All-Sports/Track-and-Field",
        popularity: 4,
        altText: "Icon of track and field"
      },
      {
        image: volleyball,
        name: "Volleyball",
        badgeContent: "Men",
        secondBadgeContent: "Women",
        route: "/All-Sports/Volleyball",
        popularity: 9,
        altText: "Icon of volleyball"
      },
      {
        image: waterPolo,
        name: "Water Polo",
        badgeContent: "Men",
        secondBadgeContent: "Women",
        route: "/All-Sports/Waterpolo",
        popularity: 11,
        altText: "Icon of water polo"
      },
      {
        image: wrestling,
        name: "Wrestling",
        badgeContent: "Men",
        secondBadgeContent: "Women",
        route: "/All-Sports/Wrestling",
        popularity: 16,
        altText: "Icon of wrestling"
      },
  
    ],
  },
];
