/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================
* Página do Produto: https://www.creative-tim.com/product/material-kit-pro-react
* Direitos Autorais 2023 Creative Tim (https://www.creative-tim.com)
Codificado por www.creative-tim.com
=========================================================
*/

// Componentes do Material-UI
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Componentes do Material Kit 2 PRO React
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Imagens
import post2 from "assets/images/TijdUS.png";
import "../../../../H1asH3.css"

// Função Perfil
function Perfil() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox 
                display="flex" 
                flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} 
                justifyContent="space-between" 
                alignItems="center" 
                mb={1}
              >
                <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Parte 3: De Volta à Holanda</MKTypography>
              </MKBox>
              <MKTypography variant="body1" fontWeight="light" color="text">
                <br />
                Ao retornar à Holanda, experimentei uma mistura de nostalgia e determinação. Minha paixão profunda pelo futebol agora estava acompanhada de um desejo renovado por desafios acadêmicos. Amsterdã se tornou o cenário para minha próxima aventura, onde busquei um mestrado em Finanças Quantitativas, uma área que despertou minha mente analítica e minhas ambições.
                <br /><br />
                Após concluir meus estudos, comecei a trabalhar como consultor. Embora o mundo dos números e análises me fascinasse, senti que algo estava faltando. Os ecos da minha jornada americana continuavam ressoando dentro de mim, lembrando-me da oportunidade única que eu havia vivenciado, uma oportunidade que eu desejava para os outros também.
                <br /><br />
                Consequently, I decided to use my experiences and knowledge to guide other young, ambitious athletes. Before launching this venture, I tapped into my extensive network in America, engaging with coaches, athletes, former athletes, and others. These discussions helped me and future students I would support, to understand the best approach to these opportunities.
                <br /><br />
                During the challenging COVID-19 pandemic, I collaborated with a leading agency in America. Although I could assist fewer international students due to limited scholarship availability, I utilized my expertise to aid American students. This experience not only expanded my network substantially but also provided insights into the American scholarship market, which I could now apply more effectively in Europe.
                <br /><br />
                Os desafios durante a pandemia fortaleceram minha missão de orientar jovens talentos para universidades americanas e bolsas de estudo esportivas. Apesar das dificuldades, trouxe uma sensação de realização que eu nunca havia experimentado antes. Era minha maneira de retribuir à comunidade que um dia me acolheu calorosamente nos Estados Unidos.
                <br /><br />
                This is my story, and I am proud that I can now help others to write their own in the land of endless possibilities. If you want to learn more about my company and the journey of my clients in realizing their dreams, continue reading on my website. If you have any questions or wish to contact me, please do not hesitate to do so.
              </MKTypography>
              <hr style={{ margin: '20px 0' }} />
              <Grid item xs={12} lg={6} mb={{ xs: 3, lg: 0 }}>
                <TransparentBlogCard
                  image={post2}
                  title="Parte 2: Meu Tempo nos Estados Unidos"
                  action={{
                    type: "internal",
                    route: "/pt/Sobre-mim/O-meu-tempo-na-América",
                    color: "info",
                    label: "Leia mais",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

// Exportar o Perfil
export default Perfil;
