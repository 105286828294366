/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Rowing1.jpg";
import Image2 from "assets/images/Sports_Images/Rowing2.jpg";
import Image3 from "assets/images/Sports_Images/Rowing3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images

import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Studieren und Rudern in Amerika: Eine einzigartige Gelegenheit für junge Ruderer', isSubheader: false },
  
    { id: 'Kapitel 1', title: 'Kapitel 1: Die Geschichte und Entwicklung des Ruderns in Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Die frühen Jahre und die Rolle der Universitäten', isSubheader: true },
    { id: '1.2', title: '1.2 Wachstum und Traditionen', isSubheader: true },
  
    { id: 'Kapitel 2', title: 'Kapitel 2: Rudern auf Hochschulebene heute', isSubheader: false },
    { id: '2.1', title: '2.1 Hauptwettbewerbe und Konferenzen', isSubheader: true },
    { id: '2.2', title: '2.2 Führende Programme und Teams', isSubheader: true },
  
    { id: 'Kapitel 3', title: 'Kapitel 3: Vom College zur Elite', isSubheader: false },
    { id: '3.1', title: '3.1 Der Weg an die Spitze', isSubheader: true },
    { id: '3.2', title: '3.2 Professionelle und internationale Möglichkeiten', isSubheader: true },
  
    { id: 'Kapitel 4', title: 'Kapitel 4: Internationale Einflüsse und Vielfalt', isSubheader: false },
    { id: '4.1', title: '4.1 Internationale Athleten im College-Rudern', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanische Ruderer im Ausland', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapitel 5: Sportstipendien und die Zukunft der Athleten', isSubheader: false },
    { id: '5.1', title: '5.1 Die Bedeutung von Stipendien', isSubheader: true },
    { id: '5.2', title: '5.2 Wie Sportstipendien Amerika helfen können', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhaltsverzeichnis
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verstecken</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Anzeigen</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>


























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Studieren und Rudern in Amerika: Eine einzigartige Gelegenheit für junge Ruderer
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Ruderer in Aktion während der nationalen College-Rudermeisterschaft."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Ruderer in Aktion während der nationalen Meisterschaft
      </figcaption>
    </figure>
    Rudern ist eine der ältesten und angesehensten Sportarten in den Vereinigten Staaten. Seit dem 19. Jahrhundert wird auf amerikanischen Universitäten hochrangiges Rudern betrieben, wo Ruderer nicht nur ihre körperliche und geistige Stärke, sondern auch ihre akademischen und sozialen Fähigkeiten entwickeln. Das Studium und Rudern in Amerika ist eine einzigartige Gelegenheit für junge Ruderer, die ihre sportlichen Träume verfolgen möchten, während sie gleichzeitig eine wertvolle Ausbildung erhalten und eine unvergessliche Erfahrung machen möchten. In diesem Artikel erfahren Sie alles, was Sie über das Rudern in Amerika wissen müssen, von seiner Geschichte und seinen Traditionen bis hin zu den Möglichkeiten und Herausforderungen. Wir erklären auch, wie Sportbeursamerika.nl Ihnen helfen kann, ein Ruderstipendium zu erhalten und sich auf Ihr Abenteuer vorzubereiten.
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
<MKBox id="Kapitel 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Die Geschichte und Entwicklung des Ruderns in Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    In diesem Kapitel tauchen wir ein in die faszinierende Geschichte des Ruderns in den Vereinigten Staaten. Wir untersuchen, wie dieser Sport über den Ozean aus England kam und zu einem integralen Bestandteil der amerikanischen Universitätskultur wurde. Von den ersten Ruderrennen auf dem Lake Winnipesaukee bis zu den ikonischen Rivalitäten zwischen renommierten Universitäten untersuchen wir die Entwicklung des Ruderns als Sport, der sowohl körperliche Beweglichkeit als auch akademischen Stolz verkörpert.
  </MKTypography>
  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Die frühen Jahre und die Rolle der Universitäten
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Das Rudern als Sport entstand im 18. Jahrhundert in England, als Rennen zwischen professionellen Ruderern auf der Themse veranstaltet wurden. Diese Rennen lockten große Zuschauermengen und Wetten an und machten das Rudern schnell populär. Im 18. und 19. Jahrhundert verbreitete sich das Rudern auch in andere Länder, einschließlich der Vereinigten Staaten. Das erste amerikanische Ruderrennen fand 1843 auf dem Lake Winnipesaukee in New Hampshire statt. Im Jahr 1852 wurde das erste interkollegiale Ruderrennen zwischen Harvard und Yale auf dem Lake Winnipesaukee ausgetragen. Dies war auch das erste interkollegiale Sportereignis in den USA. Seitdem ist das Rudern zu einem bedeutenden Bestandteil des amerikanischen College-Sports geworden.
      <br />
      <br />
      Die Rolle der Universitäten bei der Förderung des Ruderns war entscheidend. Die Universitäten sahen das Rudern als Möglichkeit, Disziplin, Teamarbeit und Führung bei ihren Studenten zu fördern, während sie gleichzeitig ihren Prestige und ihren Ruf steigerten. Die Universitäten investierten erhebliche Geld- und Ressourcenmittel in den Bau von Bootshäusern, den Erwerb von Booten und die Anwerbung von Trainern. Sie organisierten auch regelmäßig Wettbewerbe gegen andere Universitäten, sowohl regional als auch national. Diese Wettbewerbe wurden oft von Studenten, Alumni, Medien und Sponsoren gut besucht, was ein großes Interesse und Engagement für den Sport schuf.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Wachstum und Traditionen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Das Rudern wuchs im zweiten Hälfte des 19. Jahrhunderts und der ersten Hälfte des 20. Jahrhunderts stetig an Popularität und Ansehen. Verschiedene regionale und nationale Wettbewerbe entstanden, wie die Eastern Sprints, die IRA National Championship Regatta, die Dad Vail Regatta und die Head of the Charles Regatta. Amerikanische Ruderer nahmen auch an internationalen Veranstaltungen teil, darunter die Henley Royal Regatta in England und die Olympischen Spiele. Amerikanische Ruderer erzielten oft beeindruckende Ergebnisse und gewannen zwischen 1920 und 1956 bei acht aufeinander folgenden Olympischen Spielen Gold.
      <br />
      <br />
      Das Rudern entwickelte auch an amerikanischen Universitäten eine reiche Kultur und Tradition. Jede Universität hatte ihre eigenen Farben, Maskottchen, Lieder und Rituale, die mit dem Rudern verbunden waren. Einige Traditionen waren verspielt oder symbolisch, wie das Werfen des Steuermanns ins Wasser nach einem Sieg oder das Tragen einer besonderen Krawatte oder Anstecknadel. Andere Traditionen waren ernsthaft oder zeremoniell, wie das Singen des Alma-Mater-Liedes oder das Abhalten einer Gedenkfeier für verstorbene Ruderer. Diese Traditionen trugen zum Stolz, zur Kameradschaft und zur Identität unter den Ruderern bei.
    </MKTypography>
  </MKBox>
</MKBox>






                                  







<MKBox id="Kapitel 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Rudern auf Hochschulebene heute
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    In diesem Kapitel konzentrieren wir uns auf das zeitgenössische Rudern auf Hochschulebene in Amerika. Wir untersuchen, wie sich der Sport zu einem hochkompetitiven und angesehenen Teil der Hochschulsportlandschaft entwickelt hat. Hier diskutieren wir die wichtigsten Wettbewerbe und Konferenzen, die Struktur der NCAA-Divisionen und werfen einen Blick auf einige der führenden Universitätsruderteams. Dieser Abschnitt hebt den aktuellen Stand des Ruderns an amerikanischen Universitäten und die einzigartigen Möglichkeiten hervor, die er jungen Athleten bietet.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Hauptwettbewerbe und Konferenzen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Das Rudern auf Hochschulebene ist heute in den Vereinigten Staaten ein hochkompetitiver und angesehener Sport. Es gibt mehr als 300 Universitäten, die Ruderteams anbieten, wobei etwa 150 an der NCAA (National Collegiate Athletic Association), der Dachorganisation für Hochschulsport, teilnehmen. Die NCAA teilt die Universitäten in drei Divisionen ein, basierend auf der Anzahl der Studierenden, dem Budget und dem Leistungsniveau des Sports. Division I ist die höchste Division und umfasst die robustesten und wettbewerbsfähigsten Ruderteams. Die Divisionen II und III haben weniger und kleinere Ruderteams, sind aber dennoch hochkompetitiv.
      <br />
      <br />
      Innerhalb jeder Division gibt es verschiedene Konferenzen, die Gruppen von Universitäten zusammenfassen, die geografisch oder historisch verbunden sind. Jede Konferenz organisiert ihre eigene Meisterschaft, bei der sich die besten Teams für die nationalen Meisterschaften qualifizieren. Zu den bedeutenden Konferenzen für das Rudern gehören die Big Ten, Pac-12, Ivy League, ACC und Big 12. Diese Konferenzen haben oft die erfolgreichsten und einflussreichsten Ruderteams des Landes.
      <br />
      <br />
      Neben den Konferenzmeisterschaften gibt es weitere bedeutende nationale Regatten, an denen Universitäten teilnehmen können. Die prestigeträchtigste ist die NCAA Frauenrudern-Meisterschaft, die seit 1997 für weibliche Ruderer aller drei Divisionen ausgetragen wird. Für männliche Ruderer gibt es keine entsprechende NCAA-Meisterschaft, aber es gibt die IRA National Championship Regatta, die seit 1895 für sowohl Varsity- als auch Leichtgewichts-Teams stattfindet. Zu den weiteren bekannten nationalen Regatten gehören die Dad Vail Regatta, das San Diego Crew Classic, die ACRA National Championship Regatta und die CRASH-B Sprints.
    </MKTypography>
  </MKBox>

  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Führende Programme und Teams
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Das Rudern auf Hochschulebene hat eine lange Geschichte von Rivalität, Exzellenz und Innovation. Viele Universitäten haben sich durch ihre Ruderteams sowohl in Bezug auf Leistung als auch Entwicklung ausgezeichnet. Einige dieser Universitäten sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          University of Washington: Die Huskies sind bekannt für ihr kraftvolles und angesehenes Ruderteam. Mit mehreren nationalen Meisterschaften im Herrenrudern und einer Geschichte der Produktion von Olympiamedaillengewinnern, einschließlich der legendären "Boys in the Boat", die 1936 Gold gewannen. Die Huskies werden für ihr rigoroses Training, starke Teamarbeit und einflussreiche Trainer wie Al Ulbrickson gefeiert.
        </li>
        <li>
          Yale University: Als einer der Pioniere des interkollegialen Ruderns hat das Yale-Team eine alte und angesehene Rudervergangenheit. Ihre Geschichte umfasst mehrere nationale Titel und einen bedeutenden Beitrag zum amerikanischen Rudern auf olympischer Ebene. Yale ist auch für seine akademische Exzellenz und die historische Rivalität mit Harvard bekannt.
        </li>
        <li>
          University of California, Berkeley: Die Golden Bears haben ein erfolgreiches und vielseitiges Ruderteam, das sich durch nationale Titel und Olympiateilnehmer auszeichnet. Das Programm ist bekannt für seine Vielfalt, das Engagement in der Gemeinschaft und das engagierte Coaching.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>






                                  








<MKBox id="Kapitel 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Vom College zur Elite
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    In diesem Kapitel erkunden wir die Reise der College-Ruderer zu den elitären Rängen der Ruderwelt. Wir untersuchen, wie die Erfahrungen und Fähigkeiten, die Ruderer auf Hochschulebene erlangen, sie auf höhere Wettbewerbsstufen vorbereiten, einschließlich der Teilnahme an Nationalmannschaften und den Olympischen Spielen. Dieses Kapitel beleuchtet den Übergang vom Hochschulrudern zu professionellen und internationalen Bühnen und konzentriert sich auf die Wege, die Athleten verfolgen, die Herausforderungen, denen sie gegenüberstehen, und die Erfolgsgeschichten derer, die es an die Spitze geschafft haben. Wir betrachten auch, wie Hochschulruderteams Talente identifizieren und entwickeln und so den Weg für zukünftige Olympiasieger und Weltmeister ebnen.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Der Weg an die Spitze
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Das Rudern auf Hochschulebene ist nicht nur eine sportliche Herausforderung, sondern auch ein potenzieller Sprungbrett zu den elitären Ebenen der Ruderwelt. Viele Ruderer, die auf Hochschulebene gerudert haben, wechseln zu Nationalmannschaften und dem olympischen Rudern. Dies liegt daran, dass das Hochschulrudern eine ausgezeichnete Vorbereitung in Bezug auf Technik, Taktik, physische und mentale Konditionierung und Teamwork bietet. Darüber hinaus bietet das Hochschulrudern die Möglichkeit, bei Talentscouts und Trainern von nationalen Verbänden und Auswahlteams aufzufallen.
      <br />
      <br />
      Es gibt zahlreiche Beispiele von Ruderern, die vom College zur Eliteebene aufgestiegen sind. Ein aktuelles Beispiel für eine Ruderin, die nach ihrer College-Karriere eine olympische Medaille gewann, ist Madison Mailey. Mailey, eine All-American-Ruderin an der Northeastern University, die 2018 graduierte, gewann eine Goldmedaille bei den Olympischen Spielen in Tokio im Frauenachter für Kanada. Sie und ihre Teamkolleginnen übernahmen früh die Führung und hielten einer späten Herausforderung aus Neuseeland stand und gewannen mit nur 0,91 Sekunden Vorsprung.
    </MKTypography>
  </MKBox>

  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professionelle und internationale Möglichkeiten
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Das Hochschulrudern öffnet Türen zu professionellen und internationalen Möglichkeiten für Ruderer. Neben der Möglichkeit zur Teilnahme an den Olympischen Spielen können Universitätsruderer ihre Fähigkeiten nutzen, um an renommierten internationalen Regatten wie der Henley Royal Regatta oder dem Weltcup im Rudern teilzunehmen. Diese Veranstaltungen bieten eine Plattform für Ruderer, sich mit den Besten der Welt zu messen und Erfahrungen in einem internationalen Kontext zu sammeln.
      <br />
      <br />
      Darüber hinaus gibt es einen Weg zum professionellen Rudern, bei dem Hochschulabsolventen sich professionellen Teams anschließen und an globalen Wettkämpfen teilnehmen können. Dies bietet eine Vollzeit-Ruderkarriere mit intensivem Training und die Möglichkeit, auf höchstem Niveau zu konkurrieren.
      <br />
      <br />
      Für diejenigen, die an einer Trainerkarriere interessiert sind, bietet das Hochschulrudern eine solide Grundlage. Viele erfolgreiche Rudertrainer sind ehemalige Hochschulathleten, die ihre Erfahrung und Expertise nutzen, um neue Generationen von Ruderern zu betreuen. Dieser Karriereweg kann zu Positionen in Nationalmannschaften und Ruderclubs auf der ganzen Welt führen.
      <br />
      <br />
      Zusammenfassend können die Erfahrungen, die im Hochschulrudern gesammelt wurden, zu einer Vielzahl von professionellen und internationalen Möglichkeiten führen, angefangen bei Athleten, die eine professionelle Karriere anstreben, bis hin zu denen, die den Sport in einer Trainerrolle fortsetzen.
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  







<MKBox id="Kapitel 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationale Einflüsse und Vielfalt
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Dieses Kapitel hebt die internationalen Dynamiken und die Vielfalt im College-Rudern in den Vereinigten Staaten hervor. Wir erkunden, wie internationale Studenten-Athleten den Sport mit ihren einzigartigen Hintergründen und Perspektiven bereichern und wie amerikanische Ruderer Möglichkeiten finden, im Ausland zu rudern und sich weiterzuentwickeln. Diese Interaktionen tragen zu einem reicheren kulturellen Erlebnis bei und erweitern den Horizont sowohl von amerikanischen als auch von internationalen Ruderern. Wir gehen auf die verschiedenen Wege ein, die internationale Studenten auf Hochschulebene in den USA einschlagen, die Vorteile dieser Erfahrungen und die Auswirkungen, die sie auf den Sport und seine Teilnehmer haben. Darüber hinaus konzentrieren wir uns auf die Möglichkeiten für amerikanische Ruderer, internationale Erfahrungen zu sammeln, darunter Austauschprogramme, internationale Trainingslager und Trainermöglichkeiten. Dieses Kapitel betont die Bedeutung internationaler Einflüsse für die Bereicherung des Collegiate-Ruderens und die Förderung des kulturellen Austauschs und Verständnisses.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Internationale Athleten im College-Rudern
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Das College-Rudern ist nicht nur eine amerikanische Angelegenheit, sondern auch eine internationale. Viele internationale Studenten kommen in die USA, um an amerikanischen Universitäten zu studieren und zu rudern. Diese Studenten bringen ihre eigene Kultur, ihren Hintergrund und ihre Perspektive mit und leisten wertvolle Beiträge zur Vielfalt und Qualität der Teams. Darüber hinaus bieten sie eine Möglichkeit für kulturellen Austausch und Freundschaft unter Ruderern aus verschiedenen Ländern.
      <br />
      <br />
      Es gibt mehrere Gründe, warum internationale Studenten das College-Rudern in den USA wählen. Einige dieser Gründe sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Rudelstipendien: Viele amerikanische Universitäten bieten Stipendien für talentierte Ruderer an, die ihre Studiengebühren reduzieren oder sogar eliminieren können. Dies ist ein bedeutender Anreiz für internationale Studenten, die sonst möglicherweise nicht in den USA studieren könnten.
        </li>
        <li>
          Akademische Exzellenz: Viele amerikanische Universitäten sind bekannt für ihre hohen akademischen Standards und ihren Ruf. Dies zieht internationale Studenten an, die eine ausgezeichnete Ausbildung und einen weltweit anerkannten Abschluss suchen.
        </li>
        <li>
          Sportliche Herausforderung: Viele internationale Studenten sehen das College-Rudern als sportliche Herausforderung und als Gelegenheit, mit den besten Ruderern der Welt zu konkurrieren. Sie möchten ihre Fähigkeiten verbessern, ihre Grenzen austesten und ihr Potenzial ausschöpfen.
        </li>
        <li>
          Persönliche Entwicklung: Viele internationale Studenten betrachten das College-Rudern als persönliche Entwicklung und als Chance, sich als Individuen weiterzuentwickeln. Sie möchten neue Erfahrungen sammeln, neue Leute kennenlernen, neue Kulturen kennenlernen und ihren Horizont erweitern.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      "Niki van Sprang ist ein Beispiel für einen internationalen Studenten, der auf Hochschulebene in den Vereinigten Staaten gerudert ist. Van Sprang, ursprünglich aus den Niederlanden, wo er mehrere nationale Titel gewonnen hatte, erhielt ein Rudelstipendium von der University of California, Berkeley. Dort schloss er sich dem Varsity-Team an und erzielte bedeutenden Erfolg. Nach seinem Abschluss im Jahr 2017 kehrte Van Sprang in die Niederlande zurück und wurde Mitglied der Nationalmannschaft. Er nahm an den Olympischen Spielen 2020 in Tokio teil, wo er und Guillaume Krommenhoek im Männer-Zweier den 7. Platz belegten. Darüber hinaus gewann er Silber bei den Weltmeisterschaften 2022 in Račice und bei den Europameisterschaften 2022 in München sowie eine Bronzemedaille bei den Europameisterschaften 2023 in Bled, alles im Männer-Achter."
    </MKTypography>
  </MKBox>

  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikanische Ruderer im Ausland
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Das College-Rudern kann auch für amerikanische Studenten-Athleten, die ihre sportlichen Horizonte erweitern oder ändern möchten, zu internationalen Möglichkeiten führen. Es gibt verschiedene Möglichkeiten für amerikanische Ruderer, im Ausland zu rudern und Erfahrungen zu sammeln. Einige dieser Möglichkeiten sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Studienaustausch: Einige amerikanische Universitäten haben Austauschprogramme mit ausländischen Universitäten, die es den Studenten ermöglichen, für ein Semester oder ein Jahr in einem anderen Land zu studieren und zu rudern. Dies gibt ihnen die Möglichkeit, eine andere Kultur, Sprache und Bildungssystem zu erleben, sowie mit anderen Teams und Trainern zu rudern.
        </li>
        <li>
          Trainingslager: Einige amerikanische Universitäten organisieren Trainingslager im Ausland, normalerweise während der Winter- oder Sommerpause. Dies gibt ihnen die Möglichkeit, in einer anderen Umgebung, Klima und Wasserbedingung zu trainieren sowie gegen andere Teams anzutreten oder an lokalen Regatten teilzunehmen.
        </li>
        <li>
          Coaching: Einige amerikanische Ruderer entscheiden sich nach ihrem Abschluss, im Ausland zu coachen, in der Regel an Schulen, Clubs oder Nationalmannschaften. Dies gibt ihnen die Möglichkeit, ihr Wissen und ihre Erfahrungen weiterzugeben, sowie von anderen Trainern und Kulturen zu lernen.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  


                                
<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Sportstipendien und die Zukunft der Athleten
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sportstipendien bieten zahlreiche Möglichkeiten für junge Athleten, ihr Talent zu zeigen und ihre Fähigkeiten zu entwickeln. Eine der bedeutendsten Möglichkeiten ist der Erhalt eines Sportstipendiums, um auf Hochschulebene zu studieren und zu konkurrieren. In diesem Kapitel werden wir die Bedeutung von Sportstipendien diskutieren und wie Sportstipendien Amerika Ihnen helfen kann, Ihren Traum zu verwirklichen.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Die Bedeutung von Stipendien
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ein Sportstipendium ist finanzielle Unterstützung, die von einer Universität einem Studenten-Athleten zur Verfügung gestellt wird, um Studium und Sport zu kombinieren. Es kann die Kosten für Studiengebühren, Bücher, Unterkunft, Mahlzeiten und andere Ausgaben abdecken. Darüber hinaus kann es auch Vorteile wie akademische Nachhilfe, medizinische Versorgung, Trainingsmöglichkeiten und Ausrüstung bieten.
      <br />
      <br />
      Die Erlangung eines Sportstipendiums ist wettbewerbsfähig, wobei viele Faktoren eine Rolle im Zuteilungsprozess spielen, einschließlich akademischer Leistungen, sportlicher Leistung, Persönlichkeit, Motivation, Führung und Anpassungsfähigkeit. Daher ist es wichtig, sich gut vorzubereiten und sich von der Konkurrenz abzuheben.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Wie Sportstipendien Amerika helfen können
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo von Sportstipendien Amerika, das Sportausrüstung und eine Abschlusskappe zeigt, die den Fokus auf Sportstipendien für Studenten-Athleten repräsentiert."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo von Sportstipendien Amerika
        </figcaption>
      </figure>
      Sportstipendien Amerika ist eine Organisation, die sich auf die Anleitung junger Sporttalente zu Sportstipendien spezialisiert hat. Mit leidenschaftlichen und erfahrenen Experten unterstützt Sportstipendien Amerika Studenten-Athleten auf jedem Schritt der Reise, von der ersten Orientierung bis zur endgültigen Platzierung. Ihre Dienstleistungen umfassen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Ein persönliches Aufnahmegespräch, um die Wünsche, Möglichkeiten und Erwartungen des Studenten-Athleten zu besprechen.</li>
        <li>Eine professionelle Bewertung des sportlichen und akademischen Niveaus des Studenten-Athleten.</li>
        <li>Ein umfangreiches Netzwerk von Kontakten zu Trainern, Universitäten und Hochschulen.</li>
        <li>Effektive Werbung für das Profil und die Videos des Studenten-Athleten bei potenziellen Trainern.</li>
        <li>Expertenberatung zu den besten Optionen basierend auf den Vorlieben, Zielen und dem Budget des Studenten-Athleten.</li>
        <li>Verhandlung der besten Stipendienangebote im Namen des Studenten-Athleten.</li>
        <li>Hilfe bei der Beantragung der erforderlichen Dokumente wie Visum, Zeugnis, Diplom und Zulassungstests.</li>
        <li>Vorbereitung auf das Leben des Studenten-Athleten während seines Stipendiums, einschließlich Kultur, Sprache, Regeln und Tipps.</li>
        <li>Nachsorge und Follow-up während der Stipendiumszeit.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Möchten Sie Stipendienmöglichkeiten erkunden und Ihre sportlichen und akademischen Ziele erreichen? <Link to="/de/Dienstleistungen-Produkte">Sehen Sie sich die vollständige Palette</Link> der von Sportstipendien Amerika angebotenen Dienstleistungen an und vereinbaren Sie noch heute eine Beratung.
    </MKTypography>
  </MKBox>
</MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;