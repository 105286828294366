
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import SchoolIcon from '@mui/icons-material/School';
import StarIcon from '@mui/icons-material/Star';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2'; 
import Filter3Icon from '@mui/icons-material/Filter3'; 
import Filter4Icon from '@mui/icons-material/Filter4'; 
import Filter5Icon from '@mui/icons-material/Filter5'; 
import Filter6Icon from '@mui/icons-material/Filter6'; 
import Filter7Icon from '@mui/icons-material/Filter7'; 
import Filter8Icon from '@mui/icons-material/Filter8'; 
import Filter9Icon from '@mui/icons-material/Filter9';  // Import the number 1 icon
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import ScheduleIcon from '@mui/icons-material/Schedule';
import VideocamIcon from '@mui/icons-material/Videocam';
import QuizIcon from '@mui/icons-material/Quiz';
import RuleIcon from '@mui/icons-material/Rule';
import ChecklistIcon from '@mui/icons-material/Checklist';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import DescriptionIcon from '@mui/icons-material/Description';




const boxStyle = {
  boxShadow: '0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15)',
  background: '#f5f5f5', // Light grey background
  color: '#000', // Text color set to black for contrast
};

const arrowStyle = {
  borderRight: '7px solid white',
};
function Tidslinje() {
  return (
    <VerticalTimeline lineColor='rgb(33, 150, 243)'>
      <VerticalTimelineElement
        iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
        icon={<StarIcon />}
      />
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ ...boxStyle, position: 'relative' }} // Tilføj position relativ til stilen
        contentArrowStyle={arrowStyle}
        date="Trin 1"
        iconStyle={{ 
          background: 'rgb(33, 150, 243)', 
          color: '#fff', 
          boxShadow: '0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15)', // Sørg for at skyggen er anvendt her
          border: '2px solid white' // Bevar rammen
        }}
        icon={<RecordVoiceOverIcon />}
      >
        <div style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          opacity: 0.5 // Reduceret opacitet for en vandmærkeeffekt
        }}>
          <Filter1Icon style={{ color: 'grey', fontSize: '48px' }} />
        </div>

        <h3 className="vertical-timeline-element-title">Indledende konsultation:</h3>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Tilføjede Roboto som skrifttype
          fontSize: '17px', // Ændrede skriftstørrelsen til 17px
          fontWeight: '400', // Sørg for at skriftvægten er normal
          marginBottom: '10px', // Juster værdien efter behov
        }}>
          Dette er dit startpunkt – øjeblikket, hvor vi afstemmer dine drømme og mål med en realistisk vej til et sportsstipendium i Amerika.
        </p>

        <ul style={{ 
          listStyleType: 'disc', 
          marginLeft: '20px', 
          fontSize: '17px', 
        }}>  
          <li>
            <strong>Personlige mål:</strong> Afklaring af dine ambitioner og hvad du søger i et sportsstipendium og universitetsoplevelse.
          </li>
          <li>
            <strong>Sportslige færdigheder:</strong> Evaluering af din nuværende sportspræstation og potentiale for konkurrence på universitetsniveau.
          </li>
          <li>
            <strong>Akademiske aspirationer:</strong> Forståelse for dine akademiske interesser og mål, og hvordan de kan integreres med din sport.
          </li>
          <li>
            <strong>Personlige mål:</strong> Baseret på samtalen, vil jeg udarbejde en indledende strategi og tidsplan for din rejse til et sportsstipendium.
          </li>
        </ul>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Tilføjede Roboto som skrifttype
          fontSize: '17px', // Ændrede skriftstørrelsen til 17px
          fontWeight: '400', // Sørg for at skriftvægten er normal
        }}>
          Sammen lægger vi grundlaget for et succesfuldt match med et amerikansk universitet og sætter kursen for de næste trin i processen.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ ...boxStyle, position: 'relative' }} // Tilføj position relativ til stilen
        contentArrowStyle={arrowStyle}
        date="Trin 2"
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        icon={<ScheduleIcon />}
      >
        <div style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          opacity: 0.5 // Reduceret opacitet for en vandmærkeeffekt
        }}>
          <Filter2Icon style={{ color: 'grey', fontSize: '48px' }} />
        </div>
        <h3 className="vertical-timeline-element-title">Vurdering og planlægning:</h3>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Tilføjede Roboto som skrifttype
          fontSize: '17px', // Ændrede skriftstørrelsen til 17px
          fontWeight: '400', // Sørg for at skriftvægten er normal
          marginBottom: '10px', // Juster værdien efter behov
        }}>
          Efter din indledende konsultation tager jeg mig tid til en grundig vurdering og udarbejdelse af en personlig plan.
        </p>

        <ul style={{ 
          listStyleType: 'disc', 
          marginLeft: '20px', 
          fontSize: '17px', 
        }}>  
          <li>
            <strong>Potentialeanalyse:</strong> Jeg vurderer din sportslige og akademiske baggrund for at bedømme dine udsigter i USA.
          </li>
          <li>
            <strong>Handlingsplan:</strong> Jeg udvikler en skræddersyet plan, inklusive en tidsplan og milepæle, for at nå dine mål.
          </li>
          <li>
            <strong>Strategisk rådgivning:</strong> Rådgivning om, hvordan du kan forbedre din sports- og akademiske præstation for at øge dine stipendiechancer.
          </li>
          <li>
            <strong>Måluniversiteter:</strong> Sammen identificerer vi en liste over måluniversiteter, der passer til din profil og ambitioner.
          </li>
        </ul>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Tilføjede Roboto som skrifttype
          fontSize: '17px', // Ændrede skriftstørrelsen til 17px
          fontWeight: '400', // Sørg for at skriftvægten er normal
        }}>
          Denne detaljerede plan danner grundlaget for din rejse til et sportsstipendium og sikrer, at du er forberedt på hvert efterfølgende trin.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ ...boxStyle, position: 'relative' }} // Tilføj position relativ til stilen
        contentArrowStyle={arrowStyle}
        date="Trin 3"
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        icon={<VideocamIcon />}
      >
        <div style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          opacity: 0.5 // Reduceret opacitet for en vandmærkeeffekt
        }}>
          <Filter3Icon style={{ color: 'grey', fontSize: '48px' }} />
        </div>
        <h3 className="vertical-timeline-element-title">Videomateriale og sportsportfolio:</h3>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Tilføjede Roboto som skrifttype
          fontSize: '17px', // Ændrede skriftstørrelsen til 17px
          fontWeight: '400', // Sørg for at skriftvægten er normal
          marginBottom: '10px', // Juster værdien efter behov
        }}>
          At skabe et overbevisende sportsportfolio og professionelt videomateriale er afgørende for din synlighed over for trænere.
        </p>

        <ul style={{ 
          listStyleType: 'disc', 
          marginLeft: '20px', 
          fontSize: '17px', 
        }}>  
          <li>
            <strong>Videoproduktion:</strong> Jeg rådgiver om indhold og overvåger produktionen af en highlight-video, der optimalt viser dine færdigheder.
          </li>
          <li>
            <strong>Portfolieudvikling:</strong> Jeg assisterer i sammensætningen af en omfattende sportsportfolio, inklusive statistikker, præstationer og anbefalinger.
          </li>
          <li>
            <strong>Personlig historie:</strong> Din atletiske rejse og præstationer væves ind i en personlig fortælling, der adskiller dig fra andre.
          </li>
          <li>
            <strong>Digital præsentation:</strong> Jeg sikrer, at din portfolio og video er digitalt tilgængelige og delbare for trænere og scoutinghold.
          </li>
        </ul>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Tilføjede Roboto som skrifttype
          fontSize: '17px', // Ændrede skriftstørrelsen til 17px
          fontWeight: '400', // Sørg for at skriftvægten er normal
        }}>
          Med dette skridt sikrer vi, at dit talent er uomtvisteligt fremhævet og tiltrækker opmærksomheden fra amerikanske universiteter.
        </p>
      </VerticalTimelineElement>




      <VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Tilføj position relativ til stilen
  contentArrowStyle={arrowStyle}
  date="Trin 4"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<QuizIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduceret opacitet for en vandmærkeeffekt
  }}>
    <Filter4Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Akademisk forberedelse:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Tilføjede Roboto som skrifttype
    fontSize: '17px', // Ændrede skriftstørrelsen til 17px
    fontWeight: '400', // Sørg for at skriftvægten er normal
    marginBottom: '10px', // Juster værdien efter behov
  }}>
    God akademisk forberedelse er afgørende for optagelse og succes i USA.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Testforberedelse:</strong> Jeg stiller studiematerialer og vejledning til rådighed for SAT/ACT og TOEFL/IELTS, essentielle tests for din optagelse.
    </li>
    <li>
      <strong>Studieplanlægning:</strong> Sammen udvikler vi en studieplan for at forberede dig til eksamenerne, uden at forsømme din sportstræning.
    </li>
    <li>
      <strong>Ansøgningsdokumenter:</strong> Jeg assisterer i forberedelsen og revisionen af dine ansøgningsessays og anden nødvendig dokumentation.
    </li>
    <li>
      <strong>Akademisk rådgivning:</strong> Du modtager rådgivning om akademiske krav og hvordan du kan styrke din akademiske profil.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Tilføjede Roboto som skrifttype
    fontSize: '17px', // Ændrede skriftstørrelsen til 17px
    fontWeight: '400', // Sørg for at skriftvægten er normal
  }}>
    Mit mål er at sikre, at du er akademisk forberedt ikke kun til at blive accepteret, men til at udmærke dig på universitetet.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Tilføj position relativ til stilen
  contentArrowStyle={arrowStyle}
  date="Trin 5"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<SchoolIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduceret opacitet for en vandmærkeeffekt
  }}>
    <Filter5Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Promovering ved universiteter:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Tilføjede Roboto som skrifttype
    fontSize: '17px', // Ændrede skriftstørrelsen til 17px
    fontWeight: '400', // Sørg for at skriftvægten er normal
    marginBottom: '10px', // Juster værdien efter behov
  }}>
    Promovering af din profil ved universiteter er et nøgletrin, hvor min ekspertise og omfattende netværk kommer i spil.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Netværkets nøglerolle:</strong> Mit omfattende netværk inden for amerikanske universiteter er uvurderligt i at bringe din kandidatur til opmærksomhed.
    </li>
    <li>
      <strong>Strategisk positionering:</strong> Jeg udnytter min viden og erfaring til strategisk at positionere din profil, så den skiller sig ud for de rigtige sportsprogrammer og akademiske institutioner.
    </li>
    <li>
      <strong>Tilpasningsdygtig promoveringsplan:</strong> Jeg skaber en fleksibel og skræddersyet promoveringsplan, der fremhæver de unikke aspekter af din sports- og akademiske profil.
    </li>
    <li>
      <strong>Maksimering af muligheder:</strong> Gennem målrettet promovering sikrer jeg, at dine chancer for at opnå et stipendium maksimeres, med fokus på det bedste match for dine personlige og atletiske ambitioner.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Tilføjede Roboto som skrifttype
    fontSize: '17px', // Ændrede skriftstørrelsen til 17px
    fontWeight: '400', // Sørg for at skriftvægten er normal
  }}>
    Min merværdi ligger i effektivt at fremhæve dit talent og dine ambitioner, hvilket betydeligt øger chancerne for succes. Dette er, hvor mit netværk, min forståelse af det amerikanske universitetssystem og min personlige tilgang gør en afgørende forskel.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Tilføj position relativ til stilen
  contentArrowStyle={arrowStyle}
  date="Trin 6"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<RecordVoiceOverIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduceret opacitet for en vandmærkeeffekt
  }}>
    <Filter6Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Kommunikation med trænere:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    Effektiv kommunikation med trænere er afgørende. Jeg agerer som din personlige repræsentant for at gøre et stærkt førstehåndsindtryk.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Direkte introduktioner:</strong> Personlige introduktioner til trænere i mit netværk, tilpasset din sportsdisciplin.
    </li>
    <li>
      <strong>Kommunikationsstrategi:</strong> Udvikling af en effektiv kommunikationsstrategi for at fremhæve dine kvaliteter og ambitioner.
    </li>
    <li>
      <strong>Forhandlingsvejledning:</strong> Vejledning i kommunikation med trænere under forhandlingsfasen for sportsstipendier.
    </li>
    <li>
      <strong>Løbende støtte:</strong> Kontinuerlig støtte og rådgivning i al kommunikation for at sikre, at du gør et positivt og varigt indtryk.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Min rolle er at fungere som en bro mellem dig og de amerikanske trænere og at skabe en kommunikationskanal, der maksimerer dine chancer for et succesfuldt sportsstipendium.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Tilføj position relativ til stilen
  contentArrowStyle={arrowStyle}
  date="Trin 7"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<RuleIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduceret opacitet for en vandmærkeeffekt
  }}>
    <Filter7Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Valg og stipendietilbud:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    Udvalgsprocessen er afgørende; dette er hvor frugterne af vores fælles arbejde kommer til udtryk i form af stipendietilbud.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Vurdering af tilbud:</strong> Sammen gennemgår vi de modtagne tilbud og diskuterer fordele og ulemper ved hver mulighed.
    </li>
    <li>
      <strong>Strategisk rådgivning:</strong> Jeg giver strategisk rådgivning til at hjælpe dig med at træffe det bedste valg for din sportslige og akademiske fremtid.
    </li>
    <li>
      <strong>Støtte til forhandlinger:</strong> Min erfaring i forhandlinger anvendes til at realisere de mest favorable betingelser for dig.
    </li>
    <li>
      <strong>Endeligt valg:</strong> Med min hjælp træffer du et velinformeret endeligt valg, hvor din sportskarriere og uddannelse i USA begynder.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Min vejledning i denne fase er rettet mod at sikre det bedst mulige resultat for dine ambitioner og sikre din fremtid på et topuniversitet i Amerika.
  </p>
  
</VerticalTimelineElement>

<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Tilføj position relativ til stilen
  contentArrowStyle={arrowStyle}
  date="Trin 8"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<DescriptionIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduceret opacitet for en vandmærkeeffekt
  }}>
    <Filter8Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Visumansøgning og tilmeldingsproces:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    Efter valget begynder en kritisk fase: finalisering af din visumansøgning og tilmeldingsprocedurer.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Visumansøgning:</strong> Vejledning i ansøgning om det passende studievisum, inklusive indsamling af den nødvendige dokumentation.
    </li>
    <li>
      <strong>Tilmeldingsassistance:</strong> Hjælp til at udfylde alle tilmeldingsformularer og dokumenter for dit valgte universitet og konkurrence.
    </li>
    <li>
      <strong>Finansiel planlægning:</strong> Rådgivning om finansielle spørgsmål såsom stipendiegebyrer, undervisningsafgifter og leveomkostninger i USA.
    </li>
    <li>
      <strong>Endelig kontrol:</strong> En grundig endelig kontrol for at sikre, at alle administrative og logistiske aspekter er adresseret.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Min vejledning i forberedelsen sikrer, at du starter dit amerikanske eventyr selvsikkert og velinformeret.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Tilføj position relativ til stilen
  contentArrowStyle={arrowStyle}
  date="Trin 9"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<ChecklistIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduceret opacitet for en vandmærkeeffekt
  }}>
    <Filter8Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Forberedelse til afrejse:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    En problemfri overgang til USA kræver omhyggelig forberedelse; de sidste ordninger foretages nu.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Tjekliste:</strong> En omfattende tjekliste for at sikre, at intet er glemt, fra vigtige dokumenter til personlige fornødenheder.
    </li>
    <li>
      <strong>Bolig:</strong> Assistance til at finde passende bolig tæt på dit universitet og med de rigtige faciliteter.
    </li>
    <li>
      <strong>Forsikring:</strong> Rådgivning om de rigtige forsikringer for sundhed, rejse og ophold.
    </li>
    <li>
      <strong>Briefing:</strong> En briefing om kulturelle forskelle, livet i USA og praktiske tips til dagligdagen.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Min vejledning i forberedelsen sikrer, at du starter dit amerikanske eventyr selvsikkert og velinformeret.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Tilføj position relativ til stilen
  contentArrowStyle={arrowStyle}
  date="Trin 10"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<FlightTakeoffIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Reduceret opacitet for en vandmærkeeffekt
  }}>
    <Filter9Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Afrejse og ankomst i USA:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    Det endelige skridt i din forberedelse er ankommet: rejsen til Amerika.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Forberedelse til afrejse:</strong> Jeg sikrer, at du er fuldt forberedt til din afrejse, med en detaljeret tjekliste og sidste råd.
    </li>
    <li>
      <strong>Afskedsmoment:</strong> Et øjeblik til at reflektere over denne betydningsfulde milepæl og til at sige farvel, før du forfølger din drøm.
    </li>
    <li>
      <strong>Ankomst i USA:</strong> Ved ankomst tilbyder jeg støtte til at hjælpe dig med at falde til og navigere i dit nye miljø.
    </li>
    <li>
      <strong>De første dage:</strong> De første dage er spændende, og jeg sikrer, at du modtager den støtte, du har brug for, for at komme godt fra start.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Jeg står ved din side ved hvert trin, fra afgang i Danmark til din ankomst på campus i USA, klar til at starte din uddannelse og sportskarriere.
  </p>
  
</VerticalTimelineElement>

<VerticalTimelineElement
  iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
  icon={<SportsScoreIcon />}
/>
</VerticalTimeline>

  );
}

export default Tidslinje;
