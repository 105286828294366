/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Swimming1.jpg";
import Image2 from "assets/images/Sports_Images/Swimming2.jpeg";
import Image3 from "assets/images/Sports_Images/Swimming3.avif";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'La Natation en Amérique : Comment Obtenir une Bourse d\'Études Sportives et Faire Partie d\'une Histoire Sportive Prestigieuse', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Chapitre 1 : Histoire et Développement de la Natation en Amérique', isSubheader: false },
    { id: '1.1', title: '1.1 Premières Années et le Rôle des Universités', isSubheader: true },
    { id: '1.2', title: '1.2 Croissance et Professionnalisation', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Chapitre 2 : La Natation Universitaire Aujourd\'hui', isSubheader: false },
    { id: '2.1', title: '2.1 Principales Compétitions et Conférences', isSubheader: true },
    { id: '2.2', title: '2.2 Programmes de Haut Niveau et Équipes Prestigieuses', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Chapitre 3 : Du Collège au Niveau Professionnel', isSubheader: false },
    { id: '3.1', title: '3.1 Le Chemin vers le Professionnalisme', isSubheader: true },
    { id: '3.2', title: '3.2 La Natation Professionnelle et les Jeux Olympiques', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Chapitre 4 : Influences et Compétitions Internationales', isSubheader: false },
    { id: '4.1', title: '4.1 Influences Étrangères', isSubheader: true },
    { id: '4.2', title: '4.2 Américains à l\'Étranger', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Chapitre 5 : Bourses Sportives et l\'Avenir des Athlètes', isSubheader: false },
    { id: '5.1', title: '5.1 L\'Importance des Bourses', isSubheader: true },
    { id: '5.2', title: '5.2 Comment Sport Scholarships America Peut Aider', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Sommaire
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Cacher</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Afficher</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    La Natation en Amérique : Comment Obtenir une Bourse d'Études Sportives et Faire Partie d'une Histoire Sportive Prestigieuse
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Stade de natation rempli de spectateurs lors d'une compétition universitaire."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Un stade pendant un match
      </figcaption>
    </figure>
    La natation est l'un des sports les plus populaires et compétitifs au monde. Des millions de personnes pratiquent la natation, tant de manière récréative que professionnelle. Mais saviez-vous que la natation en Amérique a atteint un niveau complètement différent ? La natation en Amérique n'est pas seulement un sport ; c'est une culture, une tradition et un mode de vie.
    <br />
    <br />
    Dans cet article, nous vous dirons tout sur le développement de la natation en tant que sport compétitif aux États-Unis, l'importance de la natation universitaire pour l'avancement du sport, et comment obtenir une bourse sportive pour étudier et nager en Amérique. Nous examinerons également le rôle de Sportbeursamerika.nl, une organisation qui aide les jeunes nageurs à réaliser leurs rêves.
    <br />
    <br />
    La natation est un sport pratiqué depuis des siècles, mais ce n'est qu'au XIXe siècle qu'elle a commencé à se développer en tant qu'activité organisée et réglementée. Les premiers Jeux olympiques modernes en 1896 incluaient des compétitions de natation parmi les principaux événements, et depuis lors, la natation est devenue un pilier du programme olympique.
    <br />
    <br />
    Mais pourquoi la natation est-elle si populaire et réussie en Amérique ? Qu'est-ce qui la rend si spéciale et unique ? La réponse réside dans le rôle des universités dans la promotion de la natation en tant que discipline académique et sportive. Les universités américaines n'offrent pas seulement une éducation de haute qualité, mais aussi d'excellentes installations, un encadrement et un accompagnement pour les étudiants-athlètes souhaitant se spécialiser dans la natation. De plus, elles offrent un soutien financier sous forme de bourses sportives, permettant aux nageurs talentueux de réaliser leur potentiel sans se soucier des coûts de leur éducation.
    <br />
    <br />
    Cependant, les bourses sportives ne sont pas faciles à obtenir. Elles sont très compétitives et nécessitent un haut niveau de dévouement, de discipline et de performance. Il est donc essentiel d'être bien préparé et de rechercher une assistance professionnelle lors de la demande d'une bourse sportive. C'est là que Sportbeursamerika.nl entre en jeu.
    <br />
    <br />
    Sportbeursamerika.nl est une organisation spécialisée dans l'accompagnement des jeunes nageurs vers des bourses sportives en Amérique. Ils ont une équipe d'experts qui peut vous aider dans tous les aspects du processus, tels que le choix de la bonne université, la préparation de votre CV et de votre lettre de motivation, la préparation aux examens d'admission et aux entretiens, l'organisation de votre visa et de votre assurance, et bien plus encore. Ils ont également des liens avec des centaines d'universités et d'entraîneurs en Amérique, ce qui leur permet de vous mettre en relation avec les meilleures options pour votre profil et vos objectifs.
    <br />
    <br />
    Donc, si vous rêvez d'étudier et de nager en Amérique, Sportbeursamerika.nl est votre meilleur allié. Ils ont déjà aidé des centaines de nageurs à réaliser leurs rêves, et ils peuvent vous aider aussi. Mais avant de les contacter, jetons d'abord un coup d'œil à l'histoire et au développement de la natation en Amérique et pourquoi c'est une opportunité fantastique pour vous.
  </MKTypography>
</MKBox>

<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 1 : Histoire et Développement de la Natation en Amérique
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    La natation a une longue et riche histoire en Amérique. Tout a commencé à la fin du XIXe siècle lorsque les universités ont commencé à former leurs propres équipes de natation et à organiser des compétitions. Cela a marqué le début d'une tradition qui allait conduire à l'essor de la natation en tant que sport national.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Premières Années et le Rôle des Universités
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La première université à établir une équipe de natation officielle était Harvard en 1878. Peu de temps après, d'autres universités prestigieuses telles que Yale, Princeton, Columbia et Cornell ont suivi. Ces universités ont organisé des matches et des tournois intercollégiaux qui ont attiré l'attention du public et des médias. Elles ont également introduit de nouvelles règles et techniques pour la natation, notamment la brasse, le dos et le papillon.
      <br />
      <br />
      En 1902, l'Intercollegiate Swimming Association (ISA) a été fondée, devenant la première organisation nationale de natation collégiale. L'ISA organisait des championnats annuels pour les hommes, où les meilleures équipes et les nageurs individuels étaient honorés. En 1916, la National Collegiate Athletic Association (NCAA) a été créée comme l'organisation supérieure pour tous les sports collégiaux. La NCAA a pris en charge l'organisation des championnats nationaux de natation collégiale, qui se poursuivent à ce jour.
      <br />
      <br />
      La natation universitaire ne se limitait pas aux hommes. Les femmes ont également commencé à concourir au niveau collégial au début du XXe siècle, bien qu'elles aient été confrontées à plus de restrictions et de discrimination. Les premières équipes féminines de natation collégiale ont été formées en 1914 à l'Université de Chicago et en 1916 à l'Université du Wisconsin. En 1922, la Women's Intercollegiate Swimming Association (WISA) a été fondée, servant d'équivalent de l'ISA pour les femmes. La WISA organisait également des championnats annuels pour les femmes, qui ont connu une popularité significative.
      <br />
      <br />
      La natation universitaire a joué un rôle crucial dans la promotion de la natation en tant que sport professionnel en Amérique. De nombreux nageurs universitaires sont devenus des professionnels et ont participé aux ligues professionnelles et aux Jeux olympiques, où ils ont représenté leur pays et remporté des médailles. Certains des noms les plus connus incluent Johnny Weissmuller, Mark Spitz, Matt Biondi, Janet Evans, Michael Phelps et Katie Ledecky.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Croissance et Professionnalisation
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      En plus de la natation universitaire, d'autres formes de natation professionnelle ont émergé en Amérique. L'une d'elles était la natation en maîtres, qui se concentrait sur les nageurs adultes cherchant à améliorer leurs compétences ou à maintenir leur forme physique. La natation en maîtres a commencé en 1970 avec la création de l'United States Masters Swimming (USMS), une organisation offrant des clubs locaux, des compétitions et des programmes pour les nageurs adultes. La natation en maîtres est rapidement devenue un mouvement mondial, avec des millions de membres dans plus de 100 pays.
      <br />
      <br />
      Une autre forme de natation professionnelle était la natation en eau libre, qui se déroulait dans des plans d'eau naturels tels que les lacs, les rivières et les océans. La natation en eau libre était un sport exigeant et aventureux qui nécessitait de l'endurance, des compétences en navigation et de l'adaptabilité. La natation en eau libre a gagné en reconnaissance en 2008 lorsqu'elle a été ajoutée en tant qu'épreuve olympique. Certains des nageurs en eau libre les plus célèbres incluent Gertrude Ederle, Lynne Cox, Maarten van der Weijden et Ous Mellouli.
      <br />
      <br />
      La natation professionnelle a atteint son apogée lors des Jeux olympiques, où les nageurs américains ont dominé. Depuis 1896, l'Amérique a remporté plus de 500 médailles en natation, dont plus de 200 médailles d'or. Cela fait de l'Amérique de loin le pays le plus réussi en natation olympique.
      <br />
      <br />
      Mais comment pouvez-vous également faire partie de cette glorieuse histoire sportive ? Comment pouvez-vous étudier et nager en Amérique avec une bourse sportive ? Nous vous le dirons dans le prochain chapitre.
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  






<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 2 : La Natation Universitaire Aujourd'hui
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Comme vous l'avez vu, la natation universitaire a une longue et fière histoire en Amérique. Mais qu'en est-il du présent ? Comment la natation universitaire est-elle organisée et structurée aujourd'hui ? Quels sont les principales compétitions et conférences ? Quels sont les programmes de haut niveau et les équipes prestigieuses ? Jetons un coup d'œil de plus près.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Principales Compétitions et Conférences
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Katie Ledecky nageant pour Stanford lors d'une compétition universitaire."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Katie Ledecky en action en tant que nageuse pour Stanford
        </figcaption>
      </figure>
      La natation universitaire en Amérique est principalement réglementée par la NCAA, qui comprend trois divisions : Division I, Division II et Division III. Chaque division a ses propres règles, critères et championnats. En général, la Division I est la division la plus compétitive et prestigieuse, regroupant les meilleurs nageurs, entraîneurs et installations. Les Divisions II et III sont moins compétitives mais offrent néanmoins une éducation de qualité et des sports.
      <br />
      <br />
      Au sein de chaque division, il existe différentes conférences, qui sont des groupes d'universités ayant des liens géographiques ou historiques. Chaque conférence organise ses propres championnats, les meilleures équipes et nageurs individuels se qualifiant pour les championnats nationaux de la NCAA. Certaines des conférences les plus connues en natation universitaire comprennent :
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          La Southeastern Conference (SEC), composée de 14 universités dans le sud-est des États-Unis, telles que l'Alabama, la Floride, la Géorgie, le Kentucky, LSU, le Tennessee et Texas A&M. La SEC est réputée pour sa domination en natation, notamment dans les épreuves masculines. La SEC a remporté plus de 50 titres nationaux en natation, dont plus de 40 proviennent de l'équipe masculine de Floride.
        </li>
        <li>
          La Pacific-12 Conference (Pac-12), regroupant 12 universités sur la côte ouest de l'Amérique, dont l'Arizona, la Californie, Stanford, UCLA, USC et Washington. La Pac-12 est réputée pour son excellence en natation, notamment dans les épreuves féminines. La Pac-12 a remporté plus de 40 titres nationaux en natation, dont plus de 30 appartiennent à l'équipe féminine de Stanford.
        </li>
        <li>
          La Big Ten Conference (Big Ten), composée de 14 universités dans le Midwest et le nord-est des États-Unis, telles que l'Indiana, le Michigan, le Minnesota, Ohio State, Penn State et Wisconsin. La Big Ten est réputée pour sa diversité et son équilibre en natation, avec des équipes solides dans les épreuves masculines et féminines. La Big Ten a remporté plus de 30 titres nationaux en natation, dont plus de 20 ont été remportés par l'équipe masculine de Michigan.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ce ne sont que quelques exemples parmi les nombreuses conférences qui existent en natation universitaire. Chaque conférence a sa propre culture, ses rivalités et ses traditions, contribuant à l'excitation de la natation universitaire.
    </MKTypography>

  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programmes de Haut Niveau et Équipes Prestigieuses
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      En plus des conférences, il existe des universités individuelles qui se distinguent par leurs programmes de haut niveau et leurs équipes de natation prestigieuses. Ces universités offrent non seulement une excellente éducation à leurs étudiants, mais également un encadrement, un entraînement et des installations de premier ordre pour leurs athlètes. Elles attirent également de nombreux nageurs talentueux, tant nationaux qu'internationaux, qui veulent étudier et nager au plus haut niveau.
      <br />
      <br />
      Parmi ces universités de haut niveau, on peut citer :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          L'Université Stanford : Stanford est l'une des universités les plus renommées au monde, tant sur le plan académique que sportif. Stanford dispose d'un des meilleurs programmes de natation féminine en Amérique, avec plus de 10 titres nationaux et plus de 100 médailles olympiques. Stanford a également un solide programme de natation masculine, avec plus de 5 titres nationaux et plus de 50 médailles olympiques. Parmi les anciens élèves célèbres de Stanford, on compte John Hencken, Pablo Morales, Jenny Thompson, Summer Sanders, Misty Hyman, Janet Evans et Katie Ledecky.
        </li>
        <li>
          L'Université de Californie : La Californie est une autre grande université, tant sur le plan académique que sportif. La Californie dispose d'un des meilleurs programmes de natation masculine en Amérique, avec plus de 10 titres nationaux et plus de 100 médailles olympiques. La Californie dispose également d'un solide programme de natation féminine, avec plus de 5 titres nationaux et plus de 50 médailles olympiques. Parmi les anciens élèves célèbres de la Californie, on compte Matt Biondi, Anthony Ervin, Natalie Coughlin, Nathan Adrian, Dana Vollmer et Ryan Murphy.
        </li>
        <li>
          L'Université du Texas : Le Texas est l'une des plus grandes et des plus influentes universités en Amérique, avec une solide réputation dans les domaines académique et sportif. Le Texas dispose d'un des meilleurs programmes de natation masculine en Amérique, avec plus de 15 titres nationaux et plus de 100 médailles olympiques. Le Texas dispose également d'un solide programme de natation féminine, avec plus de 5 titres nationaux et plus de 50 médailles olympiques. Parmi les anciens élèves célèbres du Texas, on compte Josh Davis, Aaron Peirsol, Ian Crocker, Brendan Hansen, Ricky Berens et Joseph Schooling.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ce ne sont là que quelques-unes des nombreuses universités de haut niveau en natation universitaire. Chaque université a son propre historique, sa philosophie et sa vision qui contribuent au développement des nageurs.
      <br />
      <br />
      Comme vous pouvez le constater, la natation universitaire aujourd'hui est un sport très dynamique et compétitif qui offre de nombreuses opportunités aux étudiants-athlètes qui souhaitent exceller à la fois sur le plan académique et athlétique. Mais comment pouvez-vous accéder à ces opportunités ? Comment pouvez-vous obtenir une bourse sportive pour étudier et nager en Amérique ? Nous vous le dirons dans le prochain chapitre.
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  







<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 3 : Du Collège au Niveau Professionnel
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Étudier et nager en Amérique n'est pas seulement une expérience formidable, mais aussi une opportunité fantastique pour démarrer votre carrière en tant que nageur professionnel. De nombreux nageurs universitaires passent au niveau professionnel, où ils représentent leur pays dans des compétitions internationales et les Jeux Olympiques. Mais comment passer du collège au niveau professionnel ? Quels sont les défis et les avantages de cette transition ? Examinons cela de plus près.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Le Chemin vers le Professionnalisme
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Pour passer du collège au niveau professionnel, vous devez d'abord remplir les exigences de la NCAA. La NCAA a des règles strictes concernant le statut d'amateur des étudiants-athlètes, ce qui signifie que vous ne pouvez pas gagner ou accepter d'argent pour vos performances athlétiques. Cela signifie que vous ne pouvez pas conclure de contrats de parrainage, d'accords de prix en argent ou d'autres formes de rémunération tout en étudiant et en nageant au niveau collégial.
      <br />
      <br />
      Cependant, une fois que vous avez terminé vos études ou décidez de mettre fin à votre éducation, vous pouvez renoncer à votre statut d'amateur et devenir nageur professionnel. Cela signifie que vous pouvez rejoindre une organisation de natation professionnelle, telle que USA Swimming, qui est l'organisme national de natation en Amérique. USA Swimming vous offre la possibilité de participer à des compétitions de natation professionnelles telles que la Pro Swim Series, l'US Open, les championnats nationaux américains et les sélections olympiques. Ces compétitions sont conçues pour améliorer vos compétences, augmenter votre classement et vous qualifier pour des événements internationaux.
      <br />
      <br />
      En tant que nageur professionnel, vous pouvez également bénéficier de contrats de parrainage, de prix en argent et d'autres formes de rémunération pour vos performances sportives. Cela peut aider à améliorer votre situation financière, à soutenir votre mode de vie et à augmenter votre motivation. Cependant, devenir nageur professionnel comporte également plus de responsabilités et de défis. Par exemple, vous devrez gérer votre propre emploi du temps d'entraînement, votre plan nutritionnel, vos frais de déplacement, vos soins médicaux et vos impôts. Vous devrez également faire face à une concurrence accrue, à la pression et aux attentes, tant de votre part que des autres.
      <br />
      <br />
      Il est donc crucial d'être bien préparé à la transition du collège au niveau professionnel. Vous devez non seulement posséder d'excellentes compétences en natation, mais aussi avoir une attitude mentale solide, des objectifs clairs et un bon soutien. Vous devez également être réaliste quant à vos chances et opportunités, et être prêt à travailler dur et à faire des sacrifices.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 La Natation Professionnelle et les Jeux Olympiques
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Un des plus grands rêves de chaque nageur professionnel est de participer aux Jeux Olympiques, l'événement sportif le plus prestigieux et le plus significatif au monde. Les Jeux Olympiques offrent non seulement une chance de représenter votre pays et de remporter des médailles, mais aussi de faire l'histoire et de devenir une légende.
      <br />
      <br />
      Cependant, pour se qualifier pour les Jeux Olympiques, vous devez d'abord remplir les exigences du Comité International Olympique (CIO) et de la Fédération Internationale de Natation (FINA). Ces organisations ont des règles strictes concernant les critères de qualification, le nombre de participants par pays, les contrôles antidopage et d'autres aspects de la natation olympique.
      <br />
      <br />
      L'une des principales exigences pour se qualifier aux Jeux Olympiques est d'atteindre un standard de temps spécifique lors d'une compétition reconnue. Ce standard de temps est déterminé par la FINA sur la base de la moyenne des 16 meilleures performances mondiales dans chaque discipline au cours des quatre dernières années. Le standard de temps varie en fonction du sexe, du groupe d'âge et de la nage.
      <br />
      <br />
      Une autre exigence essentielle pour se qualifier aux Jeux Olympiques est d'être sélectionné par votre organisation nationale de natation, telle que USA Swimming. Chaque organisation nationale de natation a son propre processus de sélection, impliquant généralement une série de sélections ou de championnats où les meilleurs nageurs sont choisis pour représenter le pays. Chaque organisation nationale de natation a également un nombre limité de places par discipline, en fonction du nombre de nageurs qualifiés et des quotas fixés par le CIO et la FINA.
      <br />
      <br />
      Si vous parvenez à remplir ces exigences et à vous qualifier pour les Jeux Olympiques, vous faites partie des chanceux qui ont l'opportunité de participer au plus grand événement sportif au monde. Vous pourrez rivaliser avec les meilleurs nageurs du monde, vous mettre au défi et potentiellement remporter une médaille et graver votre nom dans les livres d'histoire.
      <br />
      <br />
      Comme vous pouvez le voir, la transition du collège au niveau professionnel est un voyage excitant et enrichissant qui offre de nombreuses opportunités pour votre carrière en natation. Mais comment pouvez-vous commencer ce voyage ? Comment pouvez-vous obtenir une bourse sportive pour étudier et nager en Amérique ? Nous vous le dirons dans le prochain chapitre.
    </MKTypography>
  </MKBox>
</MKBox>





                                  







<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 4 : Influences et Compétitions Internationales
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    La natation en Amérique n'est pas seulement un sport national mais aussi un sport international. La natation en Amérique est influencée par et influence d'autres pays et cultures, chacun ayant ses propres styles, techniques et traditions en matière de natation. La natation en Amérique est également confrontée et enrichie par d'autres compétitions internationales, offrant des opportunités d'apprendre et de concourir contre des nageurs du monde entier. Mais comment ces influences et compétitions internationales ont-elles débuté et évolué ? Comment impactent-elles la natation universitaire et professionnelle en Amérique ? Examinons cela de plus près.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Influences Étrangères
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Nageur en action lors du championnat national de la NCAA."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Le championnat national de la NCAA
        </figcaption>
      </figure>
      La natation est un sport universel pratiqué dans presque tous les pays du monde. Chaque pays a sa propre histoire, sa culture et son identité en matière de natation, reflétées dans la façon dont ils nagent, s'entraînent et coachent. Certains pays ont eu une influence significative sur la natation en Amérique, à la fois positivement et négativement.
      <br />
      <br />
      L'une des influences positives est l'Australie, qui est l'un des plus grands rivaux et alliés de l'Amérique en natation. L'Australie a une longue et fière tradition en natation, avec plus de 100 médailles olympiques et plus de 20 records du monde. L'Australie est connue pour son innovation et sa créativité en natation, introduisant de nouvelles techniques, stratégies et méthodes. L'Australie a également produit de nombreux nageurs talentueux et charismatiques, tels que Dawn Fraser, Shane Gould, Ian Thorpe, Grant Hackett, Libby Trickett et Cate Campbell.
      <br />
      <br />
      L'Australie a influencé la natation en Amérique en créant une compétition saine et une collaboration qui motivent les deux pays à s'améliorer et à apprendre l'un de l'autre. De nombreux nageurs australiens et américains ont été entraînés, étudiés ou ont travaillé ensemble, améliorant leurs compétences, leurs connaissances et leurs amitiés. Quelques exemples incluent :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          En 1976, Mark Spitz, la star américaine qui a remporté sept médailles d'or à Munich, s'est entraîné aux côtés de John Devitt, la légende australienne qui a remporté deux médailles d'or à Melbourne et à Rome.
        </li>
        <li>
          En 1988, Matt Biondi, la sensation américaine qui a remporté sept médailles à Séoul, a obtenu son diplôme de l'Université de Californie en économie politique.
        </li>
        <li>
          En 2016, Joseph Schooling, la surprise singapourienne qui a remporté la première médaille d'or pour son pays sur 100m papillon à Rio de Janeiro, a obtenu son diplôme de l'Université du Texas en économie.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ce ne sont que quelques exemples de l'impact des influences étrangères sur la natation en Amérique. De nombreux autres pays et cultures ont laissé leur empreinte sur la natation, notamment le Japon, la Chine, la Russie, la France, le Brésil et l'Afrique du Sud. La natation en Amérique n'est pas seulement un sport national mais aussi un sport international qui évolue constamment grâce à l'interaction avec d'autres pays et cultures.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Américains à l'Étranger
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La natation en Amérique n'est pas seulement influencée par et n'influence pas d'autres pays et cultures, mais est également confrontée et enrichie par d'autres compétitions internationales. La natation en Amérique offre la possibilité d'apprendre et de concourir contre des nageurs du monde entier, chacun avec ses propres styles, techniques et traditions en matière de natation. La natation en Amérique offre également la possibilité de voyager et de découvrir différents endroits, personnes et cultures, élargissant vos horizons et changeant votre perspective.
      <br />
      <br />
      Une des principales compétitions internationales auxquelles les nageurs américains participent est le Championnat du Monde de Natation, organisé tous les deux ans par la FINA. Le Championnat du Monde de Natation est un événement qui englobe toutes les disciplines de natation, y compris le grand bassin, le petit bassin, l'eau libre, la natation synchronisée et le plongeon. Le Championnat du Monde de Natation offre une occasion de concourir contre les meilleurs nageurs du monde, d'améliorer votre classement et de renforcer votre qualification pour d'autres événements.
      <br />
      <br />
      Une autre compétition internationale importante à laquelle les nageurs américains participent est la Coupe du Monde de Natation, organisée annuellement par la FINA. La Coupe du Monde de Natation est une série d'événements en petit bassin qui ont lieu dans différentes villes à travers le monde. La Coupe du Monde de Natation est une occasion de courir contre différents adversaires, de tester vos compétences et de gagner de l'argent.
      <br />
      <br />
      Une troisième compétition internationale majeure à laquelle les nageurs américains participent est les Jeux Panaméricains, organisés tous les quatre ans par l'Organisation Sportive Panaméricaine (ODEPA). Les Jeux Panaméricains sont un événement multisport qui réunit des athlètes d'Amérique du Nord, d'Amérique du Sud et d'Amérique centrale. Les Jeux Panaméricains sont une chance de représenter votre continent, de montrer votre solidarité et de remporter des médailles.
      <br />
      <br />
      Ce ne sont que quelques exemples parmi les nombreuses compétitions internationales auxquelles les nageurs américains participent. Il existe de nombreuses autres compétitions internationales en natation, telles que les Jeux du Commonwealth, les Championnats d'Europe, les Jeux Asiatiques et les Jeux Africains.
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  

<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 5 : Bourses Sportives et l'Avenir des Athlètes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Les bourses sportives offrent de nombreuses opportunités aux jeunes athlètes de montrer leur talent et de développer leurs compétences. L'une des opportunités les plus significatives est d'obtenir une bourse sportive pour étudier et compétitionner au niveau collégial. Dans ce chapitre, nous discuterons de l'importance des bourses sportives et de comment Sport Scholarships America peut vous aider à réaliser votre rêve.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 L'Importance des Bourses
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Une bourse sportive est un soutien financier fourni par une université à un étudiant-athlète pour combiner études et sport. Elle peut couvrir les frais de scolarité, les livres, le logement, les repas et autres dépenses. De plus, elle peut offrir des avantages tels que du tutorat académique, des soins médicaux, des installations d'entraînement et du matériel.
      <br />
      <br />
      Obtenir une bourse sportive est compétitif, avec de nombreux facteurs qui influent sur le processus d'attribution, notamment les performances académiques, sportives, la personnalité, la motivation, le leadership et l'adaptabilité. Il est donc important de bien se préparer et de se démarquer de la concurrence.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Comment Sport Scholarships America Peut Aider
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo de Sport Scholarships America, représentant du matériel sportif et une casquette de graduation, symbolisant le focus sur les bourses sportives pour les étudiants-athlètes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America est une organisation spécialisée dans l'orientation des jeunes talents sportifs vers des bourses sportives. Avec des experts passionnés et expérimentés, Sport Scholarships America soutient les étudiants-athlètes à chaque étape du parcours, de l'orientation initiale à la placement final. Leurs services comprennent :
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Un entretien personnel pour discuter des souhaits, des possibilités et des attentes de l'étudiant-athlète.</li>
        <li>Une évaluation professionnelle du niveau académique et sportif de l'étudiant-athlète.</li>
        <li>Un vaste réseau de contacts avec des entraîneurs, des universités et des collèges.</li>
        <li>Promotion efficace du profil et des vidéos de l'étudiant-athlète auprès des entraîneurs potentiels.</li>
        <li>Conseils d'experts sur les meilleures options en fonction des préférences, des objectifs et du budget de l'étudiant-athlète.</li>
        <li>Négociation des meilleures offres de bourses au nom de l'étudiant-athlète.</li>
        <li>Aide à la demande de documents nécessaires, tels que visa, relevé de notes, diplôme et tests d'admission.</li>
        <li>Préparation à la vie d'étudiant-athlète pendant sa bourse, y compris culture, langue, règles et conseils.</li>
        <li>Suivi et accompagnement pendant la période de la bourse.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vous voulez explorer les opportunités de bourses et atteindre vos objectifs sportifs et académiques ? <Link to="/fr/Services-Produits">Consultez la gamme complète</Link> des services offerts par Sport Scholarships America et planifiez une consultation dès aujourd'hui.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;