/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Pagina del prodotto: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Codificato da www.creative-tim.com

 =========================================================

* L'avviso di copyright sopra e questo avviso di autorizzazione devono essere inclusi in tutte le copie o parti sostanziali del Software.
*/

// @mui componenti materiali
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Componenti Material Kit 2 PRO React
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Esempi Material Kit 2 PRO React
import RaisedBlogCard from "examples/Cards/BlogCards/RaisedBlogCard";

// Immagini
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import NCAA from "assets/images/Logos/NCAA_Transparent.png"
import NJCAA from "assets/images/Logos/NJCAA_Transparent.png"
import NAIA from "assets/images/Logos/NAIA_Transparent.png"

function BlogPostThree() {
  const post1 = (NCAA)
  const post2 = (NJCAA)
  const post3 = (NAIA)

  return (
    <>
<MKBox position="relative" variant="gradient" bgColor="dark" mt={{ xs: 0, lg: 8 }} mx={-2}>
        <MKBox
          component="img"
          src={bgPattern}
          alt="background-pattern"
          position="absolute"
          top={0}
          left={0}
          width={{ xs: "100%", lg: "100%" }}
          height={{ xs: "auto", lg: "auto" }}
          opacity={0.6}
        />
        <Container>
          <Grid container justifyContent="center" sx={{ pt: 6, pb: 18 }}>
            <Grid item xs={12} md={7} sx={{ textAlign: "center" }}>
            
              <MKTypography variant="h2" color="white" mb={1}>
              Opportunità a tutti i livelli    </MKTypography>
              <MKTypography variant="body2" color="white">
              Ogni sport, ogni livello accademico: esplora un mondo di opportunità in innumerevoli università americane per ogni studente-atleta
                            </MKTypography>
            </Grid>
          </Grid>
        </Container>
      </MKBox>

    <MKBox component="section" mt={-10}>
     

      
      <Container>
        
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4} mb={{ xs: 3, lg: 0 }}>
            <RaisedBlogCard
              image={post1}
              category={{ color: "primary", label: "house" }}
              title="NCAA"
              subtitle="National Collegiate Athletic Association"
              description=
              "La NCAA gestisce lo sport universitario negli Stati Uniti e sovraintende le competizioni per oltre 1.100 università affiliate. In quanto organizzazione di spicco, svolge un ruolo cruciale nel panorama dello sport universitario americano."
            
              action={{ 
                type: "internal",
                route: "/it/NCAA",
                color: "info",
              label: "Maggiori informazioni", }}
              imageStyle={{ marginTop: "-44px" }}
              shadowTop="-30px"  // Sposta l'ombra in basso di 50px per abbinarla al movimento dell'immagine

            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} mb={{ xs: 3, lg: 0 }}>
            <RaisedBlogCard
              image={post2}
              category={{ color: "primary", label: "house" }}
              title="NJCAA"
              subtitle="National Junior College Association"
              description="La NJCAA regola lo sport nei college comunitari degli Stati Uniti e gestisce le competizioni per centinaia di istituzioni affiliate. In quanto organizzazione centrale, è fondamentale per lo sport di college di due anni in America, dove si sviluppa il talento per livelli superiori."
              
              action={{ 
                type: "internal", 
                route: "/it/NJCAA",
                color: "info",
                label: "Maggiori informazioni", }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} mb={{ xs: 3, lg: 0 }}>
              <RaisedBlogCard
                image={post3}
                category={{ color: "primary", label: "house" }}
                title="NAIA"
                subtitle="National Association of Intercollegiate Athletics"
                description=
                "La NAIA svolge un ruolo centrale nella gestione dello sport nelle piccole università degli Stati Uniti, con centinaia di istituti affiliati. In quanto organizzazione di spicco, svolge un ruolo vitale nel panorama dello sport intercollegiale americano, promuovendo il talento e l'integrità."
                action={{ 
                  type: "internal", 
                  route: "/it/NAIA", 
                  color: "info",
                  label: "Maggiori informazioni"}}
                imageStyle={{ marginTop: "50px" }}
                shadowTop="none"
                paddingBelowImage="120px"
              />
            </Grid>
          </Grid>
        </Container>
    </MKBox>
    </>
  );
}

export default BlogPostThree;
