/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Volleybal1.avif";
import Image2 from "assets/images/Sports_Images/volleybal2.webp";
import Image3 from "assets/images/Sports_Images/Volleybal3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images
import { Link } from 'react-router-dom';



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Beca de Voleibol: ¿Cómo Puedes Estudiar y Jugar Voleibol en Estados Unidos?', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capítulo 1: Historia y Desarrollo del Voleibol en Estados Unidos', isSubheader: false },
    { id: '1.1', title: '1.1 Primeros Años y el Papel de las Instituciones Educativas', isSubheader: true },
    { id: '1.2', title: '1.2 Crecimiento y Profesionalización', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capítulo 2: El Voleibol Universitario Hoy', isSubheader: false },
    { id: '2.1', title: '2.1 Principales Ligas y Conferencias', isSubheader: true },
    { id: '2.2', title: '2.2 Programas Principales y Equipos Prestigiosos', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capítulo 3: De la Universidad a los Profesionales', isSubheader: false },
    { id: '3.1', title: '3.1 El Camino hacia el Profesionalismo', isSubheader: true },
    { id: '3.2', title: '3.2 Ligas Profesionales y Oportunidades de Carrera', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capítulo 4: Influencias y Competencias Internacionales', isSubheader: false },
    { id: '4.1', title: '4.1 Influencias Extranjeras', isSubheader: true },
    { id: '4.2', title: '4.2 Estadounidenses en el Escenario Mundial', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capítulo 5: Becas Deportivas y el Futuro de los Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 La Importancia de las Becas', isSubheader: true },
    { id: '5.2', title: '5.2 Cómo Puede Ayudar Sport Scholarships America', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contenidos
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Ocultar</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
           <Grid item xs={12} md={9}>

























           <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Beca de Voleibol: ¿Cómo Puedes Estudiar y Jugar Voleibol en Estados Unidos?
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Juego de voleibol en Nebraska, al que asistieron más de 92,000 espectadores."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        92,000 espectadores viendo un juego de voleibol en Nebraska
      </figcaption>
    </figure>
    El voleibol es un deporte que demanda mucho de tus habilidades físicas y mentales. Necesitas reaccionar rápidamente, colaborar efectivamente, pensar tácticamente y tener una resistencia sólida. Además, el voleibol es un deporte que proporciona mucha alegría y satisfacción, especialmente cuando se juega a un alto nivel. Pero, ¿cómo puedes, como un talento de voleibol, perseguir tus ambiciones atléticas sin descuidar tus estudios? La respuesta es: optando por una beca de voleibol en Estados Unidos.
    <br />
    <br />
    Una beca de voleibol es una ayuda financiera ofrecida por una universidad estadounidense que te permite estudiar y jugar voleibol allí. Con una beca de voleibol, puedes lograr dos objetivos al mismo tiempo: puedes obtener un título reconocido de una institución educativa prestigiosa mientras desarrollas aún más tus habilidades de voleibol bajo la guía profesional. Además, puedes disfrutar de la experiencia única de vivir y aprender en una cultura diferente, con nuevos amigos y desafíos.
    <br />
    <br />
    Pero, ¿cómo puedes calificar para una beca de voleibol? ¿Cuáles son los requisitos y expectativas? ¿Y cómo encuentras la universidad adecuada que se adapte a ti? Estas son todas preguntas a las que Sportbeursamerika.nl puede ayudarte. Sportbeursamerika.nl es el especialista en el campo de las becas deportivas en Estados Unidos. Con años de experiencia y una extensa red, puedo guiarte a través de todos los pasos para solicitar con éxito una beca de voleibol, desde la creación de un perfil en línea hasta el manejo de todos los asuntos prácticos.
    <br />
    <br />
    Si deseas obtener más información sobre cómo Sportbeursamerika.nl puede ayudarte a estudiar y jugar voleibol en Estados Unidos, continúa leyendo o contáctame para una conversación introductoria sin compromiso. Estaré encantado de contarte más sobre las posibilidades y beneficios de una beca de voleibol en Estados Unidos.
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
 {/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 1: Historia y Desarrollo del Voleibol en Estados Unidos
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El voleibol es un deporte que ha existido durante más de un siglo, pero ha experimentado un crecimiento significativo en popularidad y profesionalismo, especialmente en las últimas décadas. En este capítulo, profundizaremos en la historia y el desarrollo del voleibol en Estados Unidos, desde sus primeros años hasta el presente.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Primeros Años y el Papel de las Instituciones Educativas
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      El voleibol fue inventado en 1895 por William G. Morgan, un maestro de educación física en la YMCA (Asociación de Jóvenes Cristianos) en Holyoke, Massachusetts. Su objetivo era crear un juego que fuera menos exigente físicamente que el baloncesto, que había sido inventado un año antes por su colega James Naismith. Inicialmente, llamó al juego "mintonette", pero pronto se conoció como "voleibol" porque los jugadores tenían que hacer voleos sobre una red.
      <br />
      <br />
      El juego se extendió rápidamente por todo Estados Unidos y otros países, principalmente a través de la YMCA y otras instituciones educativas. En 1916, se celebró uno de los primeros torneos oficiales de voleibol en la Universidad de California en Berkeley. En 1922, se estableció la Asociación de Voleibol de Estados Unidos (USVBA), que más tarde se convirtió en la Federación de Voleibol de Estados Unidos (USAV). Esta organización se encargó de regular y promover el voleibol en Estados Unidos.
      <br />
      <br />
      En 1928, se organizó el primer campeonato nacional para hombres por parte de la USVBA, seguido por el primer campeonato nacional para mujeres en 1949. En 1955, se celebró el primer Campeonato Mundial masculino en Francia, donde América terminó sexta. En 1956, se llevó a cabo el primer Campeonato Mundial femenino en Francia, con América terminando en cuarto lugar.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crecimiento y Profesionalización
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      En la década de 1960 y 1970, el voleibol comenzó a crecer y profesionalizarse en Estados Unidos. Esto se debió en parte a la introducción de nuevas reglas, como la adopción del sistema de punto de rally, donde cada punto cuenta independientemente de quién esté sirviendo. El voleibol también ganó más atención a través de las transmisiones televisivas de los Juegos Olímpicos, donde el voleibol ha sido un deporte oficial desde 1964.
      <br />
      <br />
      Una de las figuras clave en la historia del voleibol en Estados Unidos es Doug Beal, quien logró el éxito tanto como jugador como entrenador. Jugó para el equipo nacional de 1970 a 1976 y ganó el oro en los Juegos Panamericanos en 1975, entre otros logros. Más tarde se convirtió en el entrenador del equipo nacional de 1977 a 1984 y los llevó a su primer oro olímpico en 1984 en Los Ángeles. También estuvo involucrado en la fundación de la Major League Volleyball (MLV), una liga profesional femenina que existió de 1987 a 1989.
      <br />
      <br />
      Otro evento significativo en la historia del voleibol en Estados Unidos fue el establecimiento de la Asociación de Profesionales de Voleibol (AVP) en 1983, centrada en el voleibol playa. El voleibol playa es una variante del voleibol jugada en la arena con equipos de dos jugadores. La AVP organizó torneos y competiciones tanto para hombres como para mujeres, ayudando a popularizar el voleibol playa con el público. Algunos jugadores de voleibol playa conocidos incluyen a Karch Kiraly, Sinjin Smith, Randy Stoklos, Misty May-Treanor y Kerri Walsh Jennings.
    </MKTypography>
  </MKBox>
</MKBox>






                                  






<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 2: El Voleibol Universitario Hoy
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El voleibol es uno de los deportes más populares y competitivos a nivel universitario en Estados Unidos. Cada año, miles de equipos participan en varias competiciones y conferencias, culminando en los campeonatos nacionales. En este capítulo, te proporcionaremos una visión general de las principales ligas y conferencias, y discutiremos algunos de los programas principales y equipos prestigiosos en el voleibol universitario.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Principales Ligas y Conferencias
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Equipo de voleibol celebrando una victoria juntos."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Jugadores de voleibol celebrando una victoria
        </figcaption>
      </figure>
      La organización principal responsable de organizar y regular el voleibol universitario en Estados Unidos es la Asociación Nacional Atlética Universitaria (NCAA). La NCAA divide las universidades en tres divisiones según su tamaño, presupuesto y rendimiento atlético. Las divisiones son:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          División I: Esta es la división más alta, donde se encuentran la mayoría de las universidades grandes y conocidas. Los equipos en esta división reciben la mayor atención, cobertura mediática y becas. Hay aproximadamente 330 universidades en esta división, divididas en 32 conferencias. Una conferencia es un grupo de universidades que están conectadas geográfica o históricamente y compiten entre sí. Algunos ejemplos de conferencias en la División I incluyen la Big Ten, Pac-12, Southeastern Conference (SEC) y Atlantic Coast Conference (ACC).
        </li>
        <li>
          División II: Esta es la división intermedia, donde las universidades son ligeramente más pequeñas y menos conocidas que en la División I, pero aún mantienen un alto nivel de voleibol. Los equipos en esta división reciben menos atención, cobertura mediática y becas que en la División I pero más que en la División III. Hay aproximadamente 300 universidades en esta división, divididas en 23 conferencias. Algunos ejemplos de conferencias en la División II incluyen la Great Lakes Valley Conference (GLVC), California Collegiate Athletic Association (CCAA) y Gulf South Conference (GSC).
        </li>
        <li>
          División III: Esta es la división más baja, donde las universidades se centran principalmente en la excelencia académica en lugar del rendimiento atlético. Los equipos en esta división no reciben becas para sus atletas, pero sí proporcionan ayuda financiera basada en el mérito académico. Hay aproximadamente 450 universidades en esta división, divididas en 43 conferencias. Algunos ejemplos de conferencias en la División III incluyen la New England Small College Athletic Conference (NESCAC), University Athletic Association (UAA) y Centennial Conference.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Cada año, los equipos dentro de su división y conferencia compiten en partidos para calificar para los campeonatos nacionales, también conocidos como el Torneo de la NCAA. Este es un torneo eliminatorio donde los mejores equipos de cada división compiten por el título de campeón nacional. El torneo se lleva a cabo en primavera para hombres y en otoño para mujeres.
    </MKTypography>

  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programas Principales y Equipos Prestigiosos
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      El voleibol universitario en Estados Unidos cuenta con un alto nivel de competencia, y muchos equipos destacan en sus respectivas divisiones y conferencias. Sin embargo, algunos equipos han ganado una reputación especial e historia debido a su rendimiento consistente, jugadores y entrenadores talentosos, y una gran base de seguidores. Aquí hay algunos de los programas principales y equipos prestigiosos en el voleibol universitario:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          UCLA Bruins: Representando a la Universidad de California, Los Ángeles, los UCLA Bruins son conocidos por su fuerte tradición tanto en voleibol masculino como femenino en la División I de la NCAA. Históricamente, han ganado múltiples campeonatos nacionales y han producido algunos de los jugadores más respetados en el deporte. Este equipo es un miembro prominente de la Conferencia Pac-12.
        </li>
        <li>
          Stanford Cardinal: El equipo de voleibol femenino de la Universidad de Stanford, ubicada en Stanford, California, se ha distinguido por ganar un número impresionante de campeonatos nacionales en la División I de la NCAA, más que cualquier otro equipo en su categoría. Han producido consistentemente jugadores talentosos y compiten en la Conferencia Pac-12.
        </li>
        <li>
          Penn State Nittany Lions: El equipo de voleibol femenino de la Universidad Estatal de Pensilvania en University Park, Pensilvania, tiene una reputación de excelencia en la División I de la NCAA, con numerosos campeonatos nacionales en su haber. Son conocidos por sus títulos consecutivos y han entrenado jugadores sobresalientes a lo largo de los años. Este equipo es un miembro significativo de la Conferencia Big Ten.
        </li>
        <li>
          BYU Cougars: Brigham Young University en Provo, Utah, tiene un respetado equipo de voleibol masculino en la División I de la NCAA, que ha ganado el campeonato nacional múltiples veces. El equipo ha destacado consistentemente en el desarrollo de los mejores jugadores de voleibol y forma parte de la Mountain Pacific Sports Federation (MPSF).
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estos programas han sido prominentes e influyentes en el voleibol universitario en Estados Unidos a lo largo de los años, conocidos por sus fuertes tradiciones, jugadores talentosos y entrenadores influyentes.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  







<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 3: De la Universidad a los Profesionales
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Para muchos estudiantes-atletas que juegan voleibol a nivel universitario, es un sueño también jugar voleibol profesional después de graduarse. Pero ¿qué tan realista es este sueño y cuáles son los pasos que necesitas tomar para lograrlo? En este capítulo, discutiremos el camino hacia el profesionalismo, las ligas profesionales y las oportunidades de carrera para los jugadores de voleibol.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 El Camino hacia el Profesionalismo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Jugar voleibol a nivel universitario es una excelente manera de mejorar tus habilidades en el voleibol, obtener tu grado académico y desarrollarte personalmente. Sin embargo, también es un entorno altamente competitivo y exigente donde debes invertir mucho tiempo y energía tanto en tu deporte como en tus estudios. Por lo tanto, es importante ser realista sobre tus posibilidades de jugar voleibol profesional después de tu carrera universitaria.
      <br />
      <br />
      A diferencia de otros deportes como el baloncesto o el fútbol, no hay una liga profesional de voleibol en Estados Unidos que reclute directamente de las universidades. Esto significa que como jugador graduado, necesitarás buscar otras oportunidades para iniciar tu carrera profesional, generalmente en el extranjero. Hay varias ligas profesionales de voleibol en Europa, Asia, América del Sur y otras regiones donde puedes jugar para clubes que te ofrecen un salario y otros beneficios.
      <br />
      <br />
      Pero ¿cómo te pones en contacto con estos clubes y cómo aseguras destacarte entre los muchos otros jugadores que también están buscando un contrato? Aquí hay algunos consejos para ayudarte a encontrar un club profesional de voleibol:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Crea un perfil en línea en una plataforma como Volleyball Agency o Volleyball Market, donde puedas compartir tus videos, estadísticas, referencias e información de contacto con posibles clubes y agentes.
        </li>
        <li>
          Ponte en contacto con tus antiguos entrenadores, compañeros de equipo u otros contactos que tengan experiencia en voleibol profesional o que tengan conexiones con clubes o agentes. Pueden brindarte consejos, hacer recomendaciones o presentarte a las personas adecuadas.
        </li>
        <li>
          Participa en pruebas, exhibiciones o campamentos organizados por clubes u organizaciones profesionales. Estos son eventos donde puedes mostrar tus habilidades a cazatalentos, entrenadores o gerentes que buscan nuevos jugadores. Asegúrate de estar bien preparado, tanto física como mentalmente, y dejar una impresión positiva.
        </li>
        <li>
          Sé flexible y esté dispuesto a mudarte a otro país o continente. Jugar voleibol profesional a menudo significa vivir en una cultura diferente, con un idioma, clima y costumbres diferentes. Esto puede ser un desafío significativo pero también una gran oportunidad para aprender y crecer como persona.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Ligas Profesionales y Oportunidades de Carrera
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Si logras asegurar un contrato con un club profesional de voleibol, puedes disfrutar de los beneficios de jugar en el nivel más alto del deporte. Puedes participar en ligas emocionantes, competir contra los mejores jugadores del mundo, viajar a diferentes países y ciudades, y ganar dinero con tu pasión. Pero ¿cuáles son las principales ligas profesionales en las que puedes participar y cuáles son las otras oportunidades de carrera para los jugadores de voleibol?
      <br />
      <br />
      Aquí tienes algunas de las ligas profesionales de voleibol más prestigiosas y lucrativas en el mundo:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          La Serie A italiana: Esta es la máxima división del voleibol italiano, que consta de 14 equipos para hombres y 12 equipos para mujeres. La Serie A es conocida como una de las ligas más fuertes y competitivas del mundo, con muchos astros internacionales y salarios altos. El campeón de la Serie A también se clasifica para la Liga de Campeones, el principal torneo de clubes en Europa.
        </li>
        <li>
          La Süper Lig turca: Esta es la máxima división del voleibol turco, que consta de 12 equipos para hombres y 14 equipos para mujeres. La Süper Lig también es una de las ligas líderes en el mundo, con importantes inversiones, atención mediática y jugadores talentosos. El campeón de la Süper Lig también se clasifica para la Liga de Campeones.
        </li>
        <li>
          La Superliga brasileña: Esta es la máxima división del voleibol brasileño, que consta de 12 equipos para hombres y 12 equipos para mujeres. La Superliga es la liga dominante en América del Sur, conocida por su pasión, ambiente y calidad. El campeón de la Superliga también se clasifica para el Campeonato Mundial de Clubes de Voleibol Masculino de la FIVB, el principal torneo de clubes en el mundo.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Además de jugar en ligas profesionales, hay otras oportunidades para los jugadores de voleibol para continuar o expandir sus carreras. Aquí tienes algunos ejemplos:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Entrenar o formar a otros jugadores: Si quieres terminar o reducir tu carrera como jugador, también puedes transmitir tus conocimientos y experiencia a otros jugadores como entrenador o formador. Puedes trabajar para un club profesional, una universidad, una escuela u otra organización que ofrezca voleibol. También puedes establecer tu propia escuela o academia de voleibol, donde puedas entrenar y orientar a jóvenes talentos.
        </li>
        <li>
          Trabajar en los medios de comunicación o en la industria: Si tienes interés en los medios de comunicación o en industrias relacionadas con el voleibol, también puedes construir una carrera allí. Puedes trabajar como comentarista, analista, periodista, escritor, fotógrafo o videógrafo para varios medios que cubren el voleibol. También puedes trabajar como representante, asesor, comercializador o diseñador para diferentes empresas que ofrecen productos o servicios de voleibol.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>
                              






                                  






<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 4: Influencias y Competencias Internacionales
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El voleibol es un deporte global, jugado y seguido por millones de personas en diferentes países y continentes. El voleibol universitario en Estados Unidos no está aislado, sino que está influenciado y desafiado por la comunidad internacional de voleibol. En este capítulo, discutiremos las influencias extranjeras y las competencias internacionales que juegan un papel en el voleibol universitario.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Influencias Extranjeras
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Estadio de Voleibol de Missouri lleno de espectadores entusiastas."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Un partido de la Universidad de Missouri
        </figcaption>
      </figure>
      El voleibol universitario en Estados Unidos debe mucho a las influencias extranjeras que han enriquecido y mejorado el deporte. Una de las influencias más significativas es la inmigración de jugadores, entrenadores y preparadores físicos de otros países que trajeron su conocimiento, experiencia y cultura a Estados Unidos. Algunos de estos inmigrantes incluso se han convertido en leyendas en el voleibol universitario, como:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Arie Selinger: Es un entrenador israelí que se trasladó a Estados Unidos en 1969 para entrenar al equipo nacional femenino. Llevó al equipo a su primera medalla olímpica, una plata en 1984. También entrenó varios equipos universitarios, incluido Long Beach State, donde se convirtió en campeón nacional en cuatro ocasiones.
        </li>
        <li>
          Andy Banachowski: Es un jugador polaco que emigró a Estados Unidos en 1951. Jugó para UCLA, donde se convirtió en campeón nacional en dos ocasiones. Más tarde se convirtió en el entrenador del equipo femenino de UCLA, ganando seis campeonatos nacionales y más de 1000 partidos.
        </li>
        <li>
          Marv Dunphy: Es un jugador canadiense que llegó a Estados Unidos en 1972 para jugar en la Universidad de Pepperdine. Más tarde se convirtió en el entrenador del equipo masculino de Pepperdine, ganando cuatro campeonatos nacionales. También entrenó al equipo nacional masculino, que ganó el oro en los Juegos Olímpicos de 1988.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Otra influencia importante es la popularidad y el nivel del voleibol internacional, que proporciona inspiración y motivación para el voleibol universitario. El voleibol internacional incluye varios torneos regionales y globales donde se encuentran los mejores equipos y jugadores del mundo. Algunos de estos torneos incluyen:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Los Juegos Olímpicos: Este es el evento deportivo más grande y prestigioso del mundo, celebrado cada cuatro años. El voleibol ha sido un deporte olímpico oficial desde 1964 tanto para hombres como para mujeres. Estados Unidos ha ganado un total de 9 medallas olímpicas en voleibol, incluidas 3 de oro, 3 de plata y 3 de bronce.
        </li>
        <li>
          El Campeonato Mundial: Este es el torneo más importante para los equipos nacionales, celebrado cada cuatro años. Es organizado por la Federación Internacional de Voleibol (FIVB), el organismo rector mundial del voleibol. El Campeonato Mundial se ha celebrado desde 1949 para hombres y desde 1952 para mujeres. Estados Unidos ha ganado un total de 5 medallas en el Campeonato Mundial de voleibol, incluidas 2 de oro, 1 de plata y 2 de bronce.
        </li>
        <li>
          La Copa del Mundo: Este es otro torneo para equipos nacionales, celebrado cada cuatro años. También es organizado por la FIVB, pero difiere del Campeonato Mundial en cuanto al número de equipos participantes (12 en lugar de 24) y el sistema de clasificación (basado en campeonatos continentales). La Copa del Mundo se ha celebrado desde 1965 para hombres y desde 1973 para mujeres. Estados Unidos ha ganado un total de 7 medallas en la Copa del Mundo de voleibol, incluidas 2 de oro, 3 de plata y 2 de bronce.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Estadounidenses en el Escenario Mundial
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      El voleibol universitario en Estados Unidos no solo ha aprendido del voleibol internacional, sino que también ha contribuido significativamente al voleibol internacional. Muchos jugadores estadounidenses que compitieron a nivel universitario también han participado con éxito en competiciones internacionales, tanto para sus equipos nacionales como para clubes profesionales. Aquí tienes algunos ejemplos de jugadores estadounidenses que destacaron en el escenario mundial:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Karch Kiraly: Es uno de los mejores jugadores de voleibol de todos los tiempos, destacando tanto en voleibol indoor como en voleibol playa. Jugó para UCLA, donde se convirtió en campeón nacional en tres ocasiones. También jugó para el equipo nacional, ganando dos medallas de oro olímpicas en voleibol indoor (1984 y 1988) y una en voleibol playa (1996). También jugó profesionalmente en Italia, donde ganó cuatro títulos italianos y dos títulos de la Liga de Campeones.
        </li>
        <li>
          Logan Tom: Es una de las mejores jugadoras de voleibol femenino de todos los tiempos, jugando tanto como atacante como libero. Jugó para Stanford, donde se convirtió en campeona nacional y cuatro veces All-American. También jugó para el equipo nacional, participando en cuatro Juegos Olímpicos y ganando dos medallas de plata (2008 y 2012). También jugó profesionalmente en varios países, incluidos Brasil, Turquía, Japón y China.
        </li>
        <li>
          Matt Anderson: Es uno de los mejores jugadores de voleibol masculino de la generación actual, capaz de jugar como opuesto y como atacante exterior. Jugó para Penn State, donde se convirtió en campeón nacional en 2008 y fue nombrado All-American en 2007 y 2008. También juega para el equipo nacional, ganando bronce en los Juegos Olímpicos de 2016 y oro en la Copa del Mundo de 2015. También jugó profesionalmente en Rusia, donde ganó tres títulos rusos y dos títulos de la Liga de Campeones.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>
      






                                  



<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Becas Deportivas y el Futuro de los Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Las becas deportivas ofrecen numerosas oportunidades para que los jóvenes atletas muestren su talento y desarrollen sus habilidades. Una de las oportunidades más significativas es obtener una beca deportiva para estudiar y competir a nivel universitario. En este capítulo, discutiremos la importancia de las becas deportivas y cómo Sport Scholarships America puede ayudarte a hacer realidad tu sueño.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 La Importancia de las Becas
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Una beca deportiva es un apoyo financiero proporcionado por una universidad a un estudiante-atleta para combinar estudios y deporte. Puede cubrir los costos de matrícula, libros, alojamiento, comidas y otros gastos. Además, puede ofrecer beneficios como tutoría académica, atención médica, instalaciones de entrenamiento y equipo.
      <br />
      <br />
      Obtener una beca deportiva es competitivo, con muchos factores que influyen en el proceso de adjudicación, incluyendo logros académicos, rendimiento deportivo, personalidad, motivación, liderazgo y adaptabilidad. Por lo tanto, es importante prepararse bien y destacarse entre la competencia.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Cómo Puede Ayudar Sport Scholarships America
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo de Sport Scholarships America, que muestra equipamiento deportivo y una gorra de graduación, representando el enfoque en becas deportivas para estudiantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America es una organización especializada en guiar a jóvenes talentos deportivos hacia becas deportivas. Con expertos apasionados y experimentados, Sport Scholarships America apoya a los estudiantes-atletas en cada paso del camino, desde la orientación inicial hasta la colocación final. Sus servicios incluyen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Una entrevista personal para discutir los deseos, posibilidades y expectativas del estudiante-atleta.</li>
        <li>Una evaluación profesional del nivel académico y deportivo del estudiante-atleta.</li>
        <li>Una extensa red de contactos con entrenadores, universidades y colegios.</li>
        <li>Promoción efectiva del perfil y videos del estudiante-atleta ante entrenadores potenciales.</li>
        <li>Asesoramiento experto sobre las mejores opciones basadas en las preferencias, metas y presupuesto del estudiante-atleta.</li>
        <li>Negociación de las mejores ofertas de becas en nombre del estudiante-atleta.</li>
        <li>Asistencia en la solicitud de documentos necesarios, como visa, expediente académico, diploma y pruebas de admisión.</li>
        <li>Preparación para la vida del estudiante-atleta durante su beca, incluyendo cultura, idioma, reglas y consejos.</li>
        <li>Seguimiento y atención posterior durante el período de la beca.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      ¿Quieres explorar oportunidades de becas y alcanzar tus metas deportivas y académicas? <Link to="/es/Servicios-Productos">Consulta la gama completa</Link> de servicios ofrecidos por Sport Scholarships America y programa una consulta hoy mismo.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;