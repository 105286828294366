/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Rowing1.jpg";
import Image2 from "assets/images/Sports_Images/Rowing2.jpg";
import Image3 from "assets/images/Sports_Images/Rowing3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images

import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Estudiar y Remar en América: Una Oportunidad Única para Jóvenes Remeros', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capítulo 1: La Historia y Desarrollo del Remo en América', isSubheader: false },
    { id: '1.1', title: '1.1 Los Primeros Años y el Papel de las Universidades', isSubheader: true },
    { id: '1.2', title: '1.2 Crecimiento y Tradiciones', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capítulo 2: Remo en el Nivel Universitario Hoy', isSubheader: false },
    { id: '2.1', title: '2.1 Principales Competencias y Conferencias', isSubheader: true },
    { id: '2.2', title: '2.2 Programas y Equipos Líderes', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capítulo 3: Desde la Universidad hasta la Élite', isSubheader: false },
    { id: '3.1', title: '3.1 El Camino hacia la Cima', isSubheader: true },
    { id: '3.2', title: '3.2 Oportunidades Profesionales e Internacionales', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capítulo 4: Influencias Internacionales y Diversidad', isSubheader: false },
    { id: '4.1', title: '4.1 Atletas Internacionales en el Remo Universitario', isSubheader: true },
    { id: '4.2', title: '4.2 Remeros Estadounidenses en el Extranjero', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capítulo 5: Becas Deportivas y el Futuro de los Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 La Importancia de las Becas', isSubheader: true },
    { id: '5.2', title: '5.2 Cómo Puede Ayudar Sport Scholarships America', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contenidos
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Ocultar</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Estudiar y Remar en América: Una Oportunidad Única para Jóvenes Remeros
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Remeros en acción durante el campeonato nacional universitario de remo."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Remeros en acción durante el campeonato nacional
      </figcaption>
    </figure>
    El remo es uno de los deportes más antiguos y prestigiosos en los Estados Unidos. Desde el siglo XIX, el remo de alto nivel se ha practicado en las universidades estadounidenses, donde los remeros no solo desarrollan su fuerza física y mental, sino también sus habilidades académicas y sociales. Estudiar y remar en América es una oportunidad única para jóvenes remeros que desean perseguir sus sueños atléticos mientras reciben una educación valiosa y tienen una experiencia inolvidable. En este artículo, te contaremos todo lo que necesitas saber sobre el remo en América, desde su historia y tradiciones hasta las oportunidades y desafíos. También explicaremos cómo Sportbeursamerika.nl puede ayudarte a obtener una beca de remo y prepararte para tu aventura.
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 1: La Historia y Desarrollo del Remo en América
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    En este capítulo, nos sumergimos en la fascinante historia del remo en los Estados Unidos. Exploramos cómo este deporte se extendió por el océano desde Inglaterra y se convirtió en una parte integral de la cultura universitaria estadounidense. Desde las primeras carreras de remo en el lago Winnipesaukee hasta las rivalidades icónicas entre prestigiosas universidades, examinamos el desarrollo del remo como un deporte que encarna tanto la agilidad física como el orgullo académico.
  </MKTypography>
  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Los Primeros Años y el Papel de las Universidades
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      El remo como deporte se originó en Inglaterra en el siglo XVIII, cuando se celebraban carreras entre remeros profesionales en el Támesis. Estas carreras atrajeron a grandes audiencias y apuestas, haciendo que el remo se popularizara rápidamente. En los siglos XVIII y XIX, el remo se extendió a otros países, incluidos los Estados Unidos. La primera carrera de remo estadounidense tuvo lugar en 1843 en el lago Winnipesaukee en New Hampshire. En 1852, se celebró la primera carrera de remo intercolegial entre Harvard y Yale en el lago Winnipesaukee. Este fue también el primer evento deportivo intercolegial en los Estados Unidos. Desde entonces, el remo se ha convertido en una parte significativa del deporte universitario estadounidense.
      <br />
      <br />
      El papel de las universidades en promover el remo fue crucial. Las universidades veían el remo como una forma de inculcar disciplina, trabajo en equipo y liderazgo en sus estudiantes, a la vez que mejoraban su prestigio y reputación. Las universidades invirtieron una cantidad significativa de dinero y recursos en la construcción de casas de botes, adquisición de botes y atracción de entrenadores. También organizaron regularmente competiciones contra otras universidades, tanto a nivel regional como nacional. Estas competiciones a menudo eran muy concurridas por estudiantes, ex alumnos, medios de comunicación y patrocinadores, creando un gran interés y participación en el deporte.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crecimiento y Tradiciones
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      El remo creció constantemente en popularidad y prestigio en la segunda mitad del siglo XIX y la primera mitad del siglo XX. Surgieron varias competiciones regionales y nacionales, como los Eastern Sprints, el Campeonato Nacional IRA Regatta, el Dad Vail Regatta y el Head of the Charles Regatta. Los remeros estadounidenses también participaron en eventos internacionales, incluidas la Henley Royal Regatta en Inglaterra y los Juegos Olímpicos. Los remeros estadounidenses a menudo lograban resultados impresionantes, ganando medallas de oro en ocho Juegos Olímpicos consecutivos entre 1920 y 1956.
      <br />
      <br />
      El remo también desarrolló una rica cultura y tradición en las universidades estadounidenses. Cada universidad tenía sus propios colores, mascota, canciones y rituales asociados con el remo. Algunas tradiciones eran juguetonas o simbólicas, como arrojar al timonel al agua después de una victoria o usar una corbata o pin especial. Otras tradiciones eran serias o ceremoniales, como cantar el himno de la alma mater o celebrar un memorial para remeros fallecidos. Estas tradiciones contribuyeron al sentido de orgullo, camaradería e identidad entre los remeros.
    </MKTypography>
  </MKBox>
</MKBox>






                                  






<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 2: Remo en el Nivel Universitario Hoy
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    En este capítulo, nos enfocamos en el remo contemporáneo en el nivel universitario en América. Examinamos cómo el deporte ha evolucionado hasta convertirse en una parte altamente competitiva y prestigiosa del panorama deportivo universitario. Aquí discutimos las principales competiciones y conferencias, la estructura de las divisiones de la NCAA y echamos un vistazo a algunos de los principales programas universitarios de remo. Esta sección destaca el estado actual del remo en las universidades estadounidenses y las oportunidades únicas que ofrece a los jóvenes atletas.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Principales Competencias y Conferencias
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Equipo de remo de Stanford celebra ganar el campeonato nacional."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Remeros de Stanford celebran el campeonato nacional
        </figcaption>
      </figure>
      El remo en el nivel universitario sigue siendo un deporte altamente competitivo y prestigioso en los Estados Unidos hoy en día. Hay más de 300 universidades que ofrecen programas de remo, con aproximadamente 150 participando en la NCAA (Asociación Nacional de Atletismo Universitario), la organización principal para los deportes universitarios. La NCAA divide a las universidades en tres divisiones basadas en el número de estudiantes, presupuesto y nivel del deporte. La División I es la división más alta, con los programas de remo más robustos y competitivos. Las Divisiones II y III tienen menos y más pequeños programas de remo pero siguen siendo altamente competitivas.
      <br />
      <br />
      Dentro de cada división, hay varias conferencias, que son grupos de universidades que están geográfica o históricamente conectadas. Cada conferencia organiza su propio campeonato, donde los mejores equipos califican para los campeonatos nacionales. Las principales conferencias de remo incluyen la Big Ten, Pac-12, Ivy League, ACC y Big 12. Estas conferencias a menudo tienen los programas de remo más exitosos e influyentes del país.
      <br />
      <br />
      Además de los campeonatos de conferencias, hay otras regatas nacionales significativas en las que las universidades pueden participar. La más prestigiosa es el Campeonato Nacional de Remo Femenino de la NCAA, celebrado desde 1997 para remeras en las tres divisiones. Para los remeros masculinos, no hay un campeonato de la NCAA equivalente, pero está la Regata Nacional IRA, celebrada desde 1895 para equipos tanto de varsity como ligero. Otras regatas nacionales conocidas incluyen la Regata Dad Vail, el San Diego Crew Classic, el Campeonato Nacional ACRA y el CRASH-B Sprints.
    </MKTypography>
  </MKBox>

  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programas y Equipos Líderes
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      El remo en el nivel universitario tiene una larga historia de rivalidad, excelencia e innovación. Muchas universidades se han distinguido a través de sus programas de remo, tanto en términos de rendimiento como de desarrollo. Algunas de estas universidades incluyen:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Universidad de Washington: Los Huskies son conocidos por su poderoso y respetado programa de remo. Con múltiples campeonatos nacionales en remo masculino y una historia de producción de medallistas olímpicos, incluidos los legendarios "Boys in the Boat" que ganaron el oro en 1936. Los Huskies son celebrados por su riguroso entrenamiento, fuerte trabajo en equipo y entrenadores influyentes como Al Ulbrickson.
        </li>
        <li>
          Universidad de Yale: Como uno de los pioneros del remo intercolegial, los Bulldogs de Yale tienen un programa de remo antiguo y prestigioso. Su historia incluye múltiples títulos nacionales y una contribución significativa al remo estadounidense a nivel olímpico. Yale también es conocida por su excelencia académica y su histórica rivalidad con Harvard.
        </li>
        <li>
          Universidad de California, Berkeley: Los Golden Bears tienen un exitoso y versátil programa de remo distinguido por sus títulos nacionales y participantes olímpicos. El programa es conocido por su diversidad, participación comunitaria y entrenamiento dedicado.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>





                                  







<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 3: Desde la Universidad hasta la Élite
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    En este capítulo, exploramos el viaje de los remeros universitarios a los rangos de élite del mundo del remo. Examinamos cómo las experiencias y habilidades adquiridas por los remeros en el nivel universitario los preparan para niveles más altos de competencia, incluida la participación en equipos nacionales y los Juegos Olímpicos. Este capítulo destaca la transición del remo universitario a los escenarios profesionales e internacionales, centrándose en los caminos que siguen los atletas, los desafíos que enfrentan y las historias de éxito de aquellos que alcanzan la cima. También analizamos cómo los programas universitarios de remo identifican y desarrollan talentos, allanando el camino para futuros olímpicos y campeones mundiales.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 El Camino hacia la Cima
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      El remo en el nivel universitario no es solo un desafío deportivo, sino también un posible trampolín hacia los escalones élites del mundo del remo. Muchos remeros que han remado en la universidad hacen la transición a equipos nacionales y remo olímpico. Esto se debe a que el remo universitario proporciona una excelente preparación en términos de técnica, tácticas, condición física y mental, y trabajo en equipo. Además, el remo universitario ofrece la oportunidad de destacar ante cazatalentos y entrenadores de asociaciones y selecciones nacionales.
      <br />
      <br />
      Hay numerosos ejemplos de remeros que han pasado de la universidad al nivel élite. Un ejemplo reciente de una remera que ganó una medalla olímpica después de su carrera universitaria es Madison Mailey. Mailey, una remera All-American en la Universidad Northeastern, que se graduó en 2018, ganó una medalla de oro en los Juegos Olímpicos de Tokio en el ocho femenino para Canadá. Ella y sus compañeras de equipo tomaron una ventaja temprana y resistieron un desafío tardío de Nueva Zelanda, ganando por un margen de apenas 0.91 segundos.
    </MKTypography>
  </MKBox>

  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Oportunidades Profesionales e Internacionales
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      El remo universitario abre la puerta a oportunidades profesionales e internacionales para los remeros. Además del potencial para la participación olímpica, los remeros universitarios pueden usar sus habilidades para competir en prestigiosas regatas internacionales como la Regata Real de Henley o la Copa del Mundo de Remo. Estos eventos proporcionan una plataforma para que los remeros se midan con los mejores del mundo y ganen experiencia en un contexto internacional.
      <br />
      <br />
      Además, existe un camino hacia el remo profesional donde los graduados universitarios pueden unirse a equipos profesionales y participar en competiciones globales. Esto ofrece una carrera de remo a tiempo completo con entrenamiento intensivo y la oportunidad de competir en el nivel más alto.
      <br />
      <br />
      Para aquellos interesados en una carrera como entrenador, el remo universitario proporciona una base sólida. Muchos entrenadores exitosos de remo son ex atletas universitarios que utilizan su experiencia y conocimientos para orientar a las nuevas generaciones de remeros. Este camino profesional puede llevar a posiciones con equipos nacionales y clubes de remo de todo el mundo.
      <br />
      <br />
      En resumen, las experiencias obtenidas en el remo universitario pueden conducir a una variedad de oportunidades profesionales e internacionales, desde atletas que persiguen una carrera profesional hasta aquellos que continúan en el deporte en un papel de entrenador.
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  








<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 4: Influencias Internacionales y Diversidad
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Este capítulo destaca la dinámica internacional y diversidad dentro del remo universitario en los Estados Unidos. Exploramos cómo los estudiantes deportistas internacionales enriquecen el deporte con sus antecedentes y perspectivas únicas, y cómo los remeros estadounidenses encuentran oportunidades para remar en el extranjero y desarrollarse aún más. Estas interacciones contribuyen a una experiencia cultural más rica y amplían los horizontes tanto de los remeros estadounidenses como internacionales. Nos adentramos en los diversos caminos que toman los estudiantes internacionales para remar en el nivel universitario en los EE. UU., los beneficios de estas experiencias y el impacto que tienen en el deporte y sus participantes. Además, nos enfocamos en las oportunidades para que los remeros estadounidenses obtengan experiencia internacional, incluyendo programas de intercambio de estudios, campamentos de entrenamiento internacionales y oportunidades de entrenamiento. Este capítulo enfatiza la importancia de las influencias internacionales en enriquecer el remo universitario y promover el intercambio cultural y la comprensión.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Atletas Internacionales en el Remo Universitario
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Botes de remo alineados para el inicio de una carrera universitaria de remo."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Botes listos para el inicio de una carrera
        </figcaption>
      </figure>
      El remo universitario no es solo un asunto estadounidense, sino también internacional. Muchos estudiantes internacionales vienen a los EE. UU. para estudiar y remar en universidades estadounidenses. Estos estudiantes aportan su propia cultura, antecedentes y perspectivas, haciendo contribuciones valiosas a la diversidad y calidad de los equipos. Además, brindan la oportunidad para el intercambio cultural y la amistad entre remeros de diferentes países.
      <br />
      <br />
      Hay varias razones por las cuales los estudiantes internacionales eligen el remo universitario en los EE. UU. Algunas de estas razones incluyen:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Becas de Remo: Muchas universidades estadounidenses ofrecen becas de remo a remeros talentosos, lo que puede reducir o incluso eliminar sus costos de matrícula. Este es un incentivo significativo para los estudiantes internacionales que de otra manera no podrían estudiar en los EE. UU.
        </li>
        <li>
          Excelencia Académica: Muchas universidades estadounidenses son conocidas por sus altos estándares académicos y reputación. Esto atrae a estudiantes internacionales que buscan una educación excelente y un título reconocido globalmente.
        </li>
        <li>
          Desafío Deportivo: Muchos estudiantes internacionales ven el remo universitario como un desafío deportivo y una oportunidad para competir con los mejores remeros del mundo. Apuntan a mejorar sus habilidades, superar sus límites y alcanzar su potencial.
        </li>
        <li>
          Desarrollo Personal: Muchos estudiantes internacionales ven el remo universitario como desarrollo personal y una oportunidad para crecer como individuos. Quieren obtener nuevas experiencias, conocer gente nueva, aprender sobre nuevas culturas y ampliar sus horizontes.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      "Niki van Sprang es un ejemplo de un estudiante internacional que remó a nivel universitario en los Estados Unidos. Van Sprang, originario de los Países Bajos donde ganó múltiples títulos nacionales, recibió una beca de remo de la Universidad de California, Berkeley. Allí, se unió al equipo varsity y logró un éxito significativo. Después de graduarse en 2017, Van Sprang regresó a los Países Bajos y se convirtió en miembro del equipo nacional de remo. Participó en los Juegos Olímpicos de Tokio 2020, donde él y Guillaume Krommenhoek terminaron en 7mo lugar en el par de remos sin timonel masculino. Además, ganó plata en los Campeonatos Mundiales 2022 en Račice y en los Campeonatos Europeos 2022 en Múnich, así como una medalla de bronce en los Campeonatos Europeos 2023 en Bled, todos en el ocho masculino."
    </MKTypography>
  </MKBox>

  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Remeros Estadounidenses en el Extranjero
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      El remo universitario también puede llevar a oportunidades internacionales para los estudiantes deportistas estadounidenses que desean ampliar o cambiar sus horizontes deportivos. Hay diversas oportunidades para que los remeros estadounidenses remen en el extranjero y obtengan experiencia. Algunas de estas oportunidades incluyen:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Intercambio de Estudios: Algunas universidades estadounidenses tienen programas de intercambio con universidades extranjeras, que permiten a los estudiantes estudiar y remar en otro país por un semestre o un año. Esto les da la oportunidad de experimentar una cultura, idioma y sistema educativo diferentes, así como de remar con otros equipos y entrenadores.
        </li>
        <li>
          Campamentos de Entrenamiento: Algunas universidades estadounidenses organizan campamentos de entrenamiento en el extranjero, generalmente durante las vacaciones de invierno o verano. Esto les da la oportunidad de entrenar en un ambiente, clima y condiciones de agua diferentes, así como de competir contra otros equipos o participar en regatas locales.
        </li>
        <li>
          Entrenamiento: Algunos remeros estadounidenses deciden entrenar en el extranjero después de graduarse, típicamente en escuelas, clubes o equipos nacionales. Esto les da la oportunidad de compartir sus conocimientos y experiencia, así como de aprender de otros entrenadores y culturas.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  



<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Becas Deportivas y el Futuro de los Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Las becas deportivas ofrecen numerosas oportunidades para que los jóvenes atletas muestren su talento y desarrollen sus habilidades. Una de las oportunidades más significativas es obtener una beca deportiva para estudiar y competir a nivel universitario. En este capítulo, discutiremos la importancia de las becas deportivas y cómo Sport Scholarships America puede ayudarte a hacer realidad tu sueño.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 La Importancia de las Becas
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Una beca deportiva es un apoyo financiero proporcionado por una universidad a un estudiante-atleta para combinar estudios y deporte. Puede cubrir los costos de matrícula, libros, alojamiento, comidas y otros gastos. Además, puede ofrecer beneficios como tutoría académica, atención médica, instalaciones de entrenamiento y equipo.
      <br />
      <br />
      Obtener una beca deportiva es competitivo, con muchos factores que influyen en el proceso de adjudicación, incluyendo logros académicos, rendimiento deportivo, personalidad, motivación, liderazgo y adaptabilidad. Por lo tanto, es importante prepararse bien y destacarse entre la competencia.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Cómo Puede Ayudar Sport Scholarships America
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo de Sport Scholarships America, que muestra equipamiento deportivo y una gorra de graduación, representando el enfoque en becas deportivas para estudiantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America es una organización especializada en guiar a jóvenes talentos deportivos hacia becas deportivas. Con expertos apasionados y experimentados, Sport Scholarships America apoya a los estudiantes-atletas en cada paso del camino, desde la orientación inicial hasta la colocación final. Sus servicios incluyen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Una entrevista personal para discutir los deseos, posibilidades y expectativas del estudiante-atleta.</li>
        <li>Una evaluación profesional del nivel académico y deportivo del estudiante-atleta.</li>
        <li>Una extensa red de contactos con entrenadores, universidades y colegios.</li>
        <li>Promoción efectiva del perfil y videos del estudiante-atleta ante entrenadores potenciales.</li>
        <li>Asesoramiento experto sobre las mejores opciones basadas en las preferencias, metas y presupuesto del estudiante-atleta.</li>
        <li>Negociación de las mejores ofertas de becas en nombre del estudiante-atleta.</li>
        <li>Asistencia en la solicitud de documentos necesarios, como visa, expediente académico, diploma y pruebas de admisión.</li>
        <li>Preparación para la vida del estudiante-atleta durante su beca, incluyendo cultura, idioma, reglas y consejos.</li>
        <li>Seguimiento y atención posterior durante el período de la beca.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      ¿Quieres explorar oportunidades de becas y alcanzar tus metas deportivas y académicas? <Link to="/es/Servicios-Productos">Consulta la gama completa</Link> de servicios ofrecidos por Sport Scholarships America y programa una consulta hoy mismo.
    </MKTypography>
  </MKBox>
</MKBox>



          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;