/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Página del producto: https://www.creative-tim.com/product/material-kit-pro-react
* Derechos de autor 2023 Creative Tim (https://www.creative-tim.com)

Codificado por www.creative-tim.com

 =========================================================

* El aviso de derechos de autor anterior y este aviso de permisos deben incluirse en todas las copias o partes sustanciales del software.
*/

// Componentes materiales de @mui
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Componentes de Material Kit 2 PRO React
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Imágenes
import post1 from "assets/images/Jeugd.png";
import post3 from "assets/images/Deal.png";
import "../../../../H1asH3.css";


function Perfil() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center"></MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox 
                  display="flex" 
                  flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} 
                  justifyContent="space-between" 
                  alignItems="center" 
                  mb={1}
              >
                  <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Parte 2: Mi Tiempo en América</MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text">
  <br />
    Mi tiempo en América fue como un viaje impresionante, lleno de experiencias inolvidables y crecimiento personal. 
    Gracias al fútbol, viajé de costa a costa, acompañado de un grupo de compañeros de equipo que rápidamente se convirtieron en algunos de mis mejores amigos. 
    Nuestros días comenzaban temprano con sesiones de entrenamiento intensivas en el gimnasio, algo a lo que no estaba acostumbrado en los Países Bajos. 
    Luego rápidamente a la universidad para clases, y de regreso al entrenamiento por la tarde. Era un compromiso diario que me desafiaba tanto física como mentalmente.
  <br /><br />
    Los partidos eran momentos destacados a los que acudían miles de fanáticos, y cada vez sentía que estábamos jugando por algo más grande que nosotros mismos. 
    La adrenalina al entrar al campo frente a una multitud apasionada es indescriptible. Además, los viajes que hicimos para los partidos fueron inolvidables. 
    En el avión, a menudo pasábamos horas jugando a las cartas, y en los vestíbulos de los hoteles compartíamos momentos hilarantes y conversaciones emocionantes. 
    Los restaurantes con deliciosos platillos se convirtieron en nuestros lugares temporales de comida, y estas aventuras culinarias agregaron una dimensión adicional a nuestros viajes. 
    Todo estaba meticulosamente organizado, desde las cartas en el avión hasta los restaurantes donde cenábamos. 
    Estas experiencias me permitieron vislumbrar la grandeza de América.
  <br /><br />
    Además, tuve la oportunidad de hacer prácticas en clubes profesionales líderes, incluyendo el Seattle Sounders. 
    Compartir el campo con jugadores como el máximo goleador de todos los tiempos de América, Clint Dempsey, fue simplemente surrealista, un momento que siempre atesoraré. 
    Pero como en toda aventura, este viaje tuvo sus desafíos. 
    En mi último año, enfrenté una grave lesión en el tendón de la corva que detuvo temporalmente mis ambiciones en el fútbol. 
    Este fue un período de reflexión personal y resistencia, del cual salí más fuerte.
  <br /><br />
    Además, mi viaje académico en América fue igualmente importante. A pesar del exigente programa de entrenamiento de fútbol y los extensos viajes, 
    recibí una guía perfecta para continuar mis estudios. No fue una tarea fácil, ya que a menudo faltaba a clases debido a mi participación en el equipo de fútbol. 
    Sin embargo, estaba decidido a tener éxito, y lo logré con orgullo, graduándome con honores máximos, magna cum laude. 
    Este éxito académico fue un testimonio de mi dedicación tanto al deporte como a mi educación.
  <br /><br />
    Durante mi aventura en América, experimenté fiestas típicas estadounidenses que parecían sacadas de una película. 
    Estas animadas reuniones sociales, llenas de música, baile y risas fuertes, me ofrecieron la oportunidad de crecer, no solo como atleta y estudiante, 
    sino también como entusiasta social. Estas fiestas agregaron una dimensión adicional a mi vida estudiantil y enriquecieron mis experiencias sociales.
  <br /><br />
    Sin embargo, América significó más para mí que solo fútbol, partidos, eventos sociales y logros académicos. 
    Fue una oportunidad para ampliar mi círculo social y hacer amistades para toda la vida. 
    Gracias a estas amistades, pude descubrir el país de nuevas formas. 
    Desde las vacaciones de primavera en México, donde disfrutamos del sol, el mar y la playa, hasta la celebración del Día de Acción de Gracias con las familias de amigos, 
    a quienes ahora puedo llamar mi familia estadounidense. Su cálida bienvenida realmente me hizo parte de sus vidas, 
    y estas experiencias hicieron que mi tiempo en América fuera aún más enriquecedor.
  <br /><br />
  Este período de mi vida profundizó mi amor tanto por el fútbol como por la educación y me llevó al camino que sigo ahora para ayudar a otros a alcanzar sus sueños en América. 
  Mantente atento, porque en la Parte 3 compartiré cómo mi regreso a los Países Bajos y la fundación de mi empresa llevaron mi vida en una nueva dirección.
</MKTypography>
              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="Parte 1: Creciendo en los Países Bajos"
                    action={{
                      type: "internal",
                      route: "/es/Acerca-de-mí/Creciendo",
                      color: "info",
                      label: "leer más",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="Parte 3: De Regreso en los Países Bajos"
                    action={{
                      type: "internal",
                      route: "/es/Acerca-de-mí/Mi-regreso-a-los-Países-Bajos",           
                      color: "info",
                      label: "leer más",
                    }}
                  />
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Perfil;
