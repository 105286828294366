/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React components
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Jeugd.png";
import post2 from "assets/images/TijdUS.png";
import post3 from "assets/images/Deal.png";

function Post2() {
  return (
    <MKBox component="section" py={0}>
      <Container>
        <Grid container item xs={12} lg={4}>
          <MKTypography variant="h3" mb={6}>
          Læs min historie          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
        <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <TransparentBlogCard
              image={post1}
              title="Del 1: Opvækst i Nederlandene"
              description="I de fortryllende omgivelser i Nijmegen begyndte mit livslange kærlighedsforhold til fodbold. Som barn fra  ..."
              action={{
                type: "internal",
                route: "/da/Om-mig/Opvokser",
                color: "info",
                label: "læs mere",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <TransparentBlogCard
              image={post2}
              title="Del 2: Min tid i Amerika"
              description=" Min tid i Amerika var som en åndeløs rejse, fyldt med uforglemmelige oplevelser og personlig vækst. ..."
              action={{
                type: "internal",
                route: "/da/Om-mig/Min-tid-i-Amerika",
                color: "info",
                label: "læs mere",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <TransparentBlogCard
              image={post3}
              title="Del 3: Min hjemkomst til Holland"
              description="Ved tilbagevenden til Holland oplevede jeg en blanding af nostalgi og beslutsomhed. Min dybt forankrede ..."
              action={{
                type: "internal",
                route: "/da/Om-mig/Min-hjemkomst-til-Holland",
                color: "info",
                label: "læs mere",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Post2;

