


/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post2 from "assets/images/TijdUS.png";
import "../../../../H1asH3.css"


// Images

function Profile() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
            <MKBox 
                display="flex" 
                flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} 
                justifyContent="space-between" 
                alignItems="center" 
    mb={1}
>
    <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Part 3: Back in the Netherlands</MKTypography>

    
</MKBox>
           

              <MKTypography variant="body1" fontWeight="light" color="text">

              <MKTypography variant="h4">
               
                </MKTypography>
                <br />
                Bei meiner Rückkehr in die Niederlande erlebte ich eine Mischung aus Nostalgie und Entschlossenheit. Meine tief verwurzelte Leidenschaft für Fußball wurde nun von einem erneuten Eifer für akademische Herausforderungen begleitet. Amsterdam wurde zur Kulisse für mein nächstes Abenteuer, bei dem ich einen Master-Abschluss in Quantitativer Finanzierung anstrebte, ein Bereich, der sowohl meinen analytischen Verstand als auch meine Ambitionen ansprach.
                <br /><br />
                Nach Abschluss meines Studiums begann ich als Berater zu arbeiten. Obwohl mich die Welt der Zahlen und Analysen faszinierte, hatte ich das Gefühl, dass etwas fehlte. Die Echos meiner amerikanischen Reise hallten weiter in mir nach und erinnerten mich an die einzigartige Gelegenheit, die ich erlebt hatte, eine Gelegenheit, die ich mir auch für andere wünschte.
                <br /><br />
                Folglich beschloss ich, meine Erfahrungen und mein Wissen zu nutzen, um andere junge, ehrgeizige Athleten zu unterstützen. Bevor ich dieses Unternehmen startete, griff ich auf mein umfangreiches Netzwerk in Amerika zurück und engagierte mich mit Trainern, Athleten, ehemaligen Athleten und anderen. Diese Gespräche halfen sowohl mir als auch den zukünftigen Studenten, die ich unterstützen würde, die beste Herangehensweise an diese Möglichkeiten zu verstehen.
                <br /><br />
                Während der herausfordernden COVID-19-Pandemie arbeitete ich mit einer führenden Agentur in Amerika zusammen. Obwohl ich aufgrund begrenzter Stipendienverfügbarkeit weniger internationale Studenten unterstützen konnte, nutzte ich meine Expertise, um amerikanischen Studenten zu helfen. Diese Erfahrung erweiterte nicht nur mein Netzwerk erheblich, sondern lieferte auch Einblicke in den amerikanischen Stipendienmarkt, die ich nun in Europa effektiver anwenden kann.
                <br /><br />
                Die Herausforderungen während der Pandemie verstärkten meine Mission, junge Talente zu amerikanischen Universitäten und Sportstipendien zu führen. Trotz der Schwierigkeiten brachte es ein Gefühl der Erfüllung, das ich zuvor nie erlebt hatte. Es war meine Art, der Gemeinschaft etwas zurückzugeben, die mich einst in den Vereinigten Staaten herzlich willkommen hieß.
                <br /><br />
                Das ist meine Geschichte, und ich bin stolz darauf, dass ich nun anderen helfen kann, ihre eigenen Geschichten im Land der endlosen Möglichkeiten zu schreiben. Wenn Sie mehr über mein Unternehmen und die Reise meiner Kunden zur Verwirklichung ihrer Träume erfahren möchten, lesen Sie weiter auf meiner Website. Wenn Sie Fragen haben oder mich kontaktieren möchten, zögern Sie bitte nicht, dies zu tun.
              </MKTypography>

              <hr style={{ margin: '20px 0'}} />
              <Grid item xs={12} lg={6} mb={{ xs: 3, lg: 0 }}>
            <TransparentBlogCard
              image={post2}
              title="Part 2: My Time in America"
              action={{
                type: "internal",
                route: "/About-Me/Time-in-America",
                color: "info",
                label: "read more",
              }}
            />
          </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}


export default Profile;

