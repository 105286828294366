/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Produktside: https://www.creative-tim.com/product/material-kit-pro-react
* Ophavsret 2023 Creative Tim (https://www.creative-tim.com)

Kodet af www.creative-tim.com

 =========================================================

* Ovenstående ophavsretsmeddelelse og denne tilladelsesmeddelelse skal inkluderes i alle kopier eller betydelige dele af softwaren.
*/

import { useState } from "react";

// @mui materialekomponenter
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React-komponenter
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton"; // Importer MKButton
import { Link } from 'react-router-dom';

// Komponenter til side med udlejning
import FaqCollapse from "Languages/Dansk/pages/Homepage/LandingPage/sections/FAQfunction";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <MKBox component="section" py={8}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
              Ofte stillede spørgsmål
            </MKTypography>
            <MKBox mb={2}>
              <MKTypography variant="body2" align="center" color="text">
                Alt hvad du ønsker at vide: svar på de mest stillede spørgsmål om at studere og dyrke sport i Amerika og mine tjenester. Hvis dit spørgsmål ikke er angivet, er du velkommen til at kontakte os.
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={10}>
          <FaqCollapse
   title="Hvor meget koster det at studere i Amerika?"
   open={collapse === 1}
   onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
>
   Undervisningsgebyrerne og omkostningerne ved at studere i Amerika varierer meget afhængigt af universitetet, studieprogrammet og stedet. Gennemsnitligt kan internationale studerende forvente en samlet årlig udgift på cirka $30.000 til så meget som $70.000 eller mere. Det er dog værd at bemærke, at mange studerende kan dække en betydelig del (eller sommetider hele) disse omkostninger gennem atletiske legater, akademiske legater eller en kombination af begge. Amerikanske universiteter tilbyder ofte atletiske legater til talentfulde atleter, der yder økonomisk støtte i bytte for deres deltagelse i collegiale sportsprogrammer. Dette giver mulighed for, at studerende-atleter kan forfølge deres akademiske og atletiske mål uden betydelige økonomiske byrder.
</FaqCollapse>

<FaqCollapse
   title="Hvad er forskellen mellem et college og et universitet i Amerika?"
   open={collapse === 2}
   onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
>
   Terminologien kan være forvirrende, især for folk uden for USA. I praksis refererer "college" og "universitet" faktisk ikke til det samme. Et "universitet" er en omfattende betegnelse for en højere uddannelsesinstitution, der tilbyder programmer på alle niveauer, herunder kandidat-, master- og doktorgradsstudier.

   Et "college" derimod er normalt en del af et universitet og fokuserer primært på bachelorprogrammer, såsom bachelorgrader. Med andre ord er et college en afdeling inden for et universitet, der specifikt er dedikeret til grunduddannelse. Universiteter tilbyder typisk både grund- og kandidatprogrammer, mens colleges koncentrerer sig om grundniveauet.

   Denne sondring kan dog variere mellem forskellige institutioner, og nogle gange bruges termerne ombytteligt. Derfor er det nyttigt at undersøge de specifikke detaljer og programmer for en uddannelsesinstitution for at forstå, hvad de tilbyder.
</FaqCollapse>

<FaqCollapse
   title="Hvilke atleter vejledes af Sport Scholarships America?"
   open={collapse === 3}
   onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
>
   Jeg vejleder atleter inden for en bred vifte af sportsgrene. Mit stærke netværk og samarbejde i Amerika gør det muligt for mig at støtte atleter inden for forskellige discipliner. Så uanset om du spiller fodbold, basketball, tennis, golf eller en anden sport, er jeg klar til at hjælpe dig med at forfølge dine akademiske og sportslige mål i USA. For en komplet liste over sportsgrene, <Link to="/da/Alle-sportsgrene" style={{ fontWeight: 'bold' }}>klik her</Link>.
</FaqCollapse>

<FaqCollapse
   title="Hvad er den typiske ansøgningsproces, og hvor lang tid i forvejen skal jeg begynde?"
   open={collapse === 4}
   onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
>
   Processen begynder normalt med et omfattende indledende møde, hvor vi drøfter alle detaljer og forstår din specifikke situation og mål. Jeg vil også forklare min tilgang nærmere. Varigheden af hele processen kan variere, men generelt anbefaler jeg at begynde mindst 6 til 12 måneder i forvejen. Det er vigtigt at understrege, at jo tidligere du starter, desto flere muligheder kan opstå, så tøv ikke med at starte processen tidligt.

   I løbet af denne periode vil vi samarbejde om at oprette en personlig plan, der tager højde for alle aspekter, såsom optagelsesprøver, visumansøgninger, oprettelse af sportsvideoer og mere. Hver rejse er unik og skræddersyet, da der er mange faktorer at overveje. For en detaljeret oversigt over hele processen kan du <Link to="/da/Proces" style={{ fontWeight: 'bold' }}>klikke her</Link>. Dette giver et trin-for-trin indblik i, hvad du kan forvente under din rejse for at studere og konkurrere i USA.
</FaqCollapse>

<FaqCollapse
   title="Hvad er omkostningerne for de tjenester, du leverer?"
   open={collapse === 5}
   onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
>
   Omkostningerne for mine tjenester varierer afhængigt af dine specifikke behov og mål. Indtagelsessamtalen er et vigtigt skridt, hvor vi drøfter din situation og udvikler en tilpasset plan. Det er også muligheden for at afgøre, om vi ønsker at fortsætte med at arbejde sammen.

   Efter denne samtale vil jeg være i stand til at få en klar forståelse af dine ønsker og behov, og baseret på det kan jeg give dig en passende omkostningsvurdering.

   For en komplet oversigt over de tjenester og produkter, jeg tilbyder, kan du <Link to="/da/Tjenester-Produkter" style={{ fontWeight: 'bold' }}>klikke her</Link>. Her finder du detaljerede oplysninger om, hvordan jeg kan hjælpe dig med at forfølge dine studie- og konkurrenceambitioner i USA.
</FaqCollapse>

<FaqCollapse
   title="Hvorfor skal jeg vælge at blive vejledt af Sport Scholarships America?"
   open={collapse === 6}
   onClick={() => (collapse === 6 ? setCollapse(false) : setCollapse(6))}
>
   Når du står ved et vejkryds for at realisere dine drømme, som jeg engang var, kan der opstå mange spørgsmål og tvivl. Valget om at gøre det selv, samarbejde med et bureau eller endda opgive hele idéen kan være overvældende. Jeg forstår dybt denne usikkerhed, fordi jeg selv har været igennem den.

   Det ville være en skam, hvis disse tvivl forhindrer nogen i at forfølge, hvad der virkelig er muligt - en fantastisk oplevelse, der venter på at blive opdaget. Derfor dedikerer jeg mig til at hjælpe andre med at realisere deres drømme.

   Jeg sætter mine personlige erfaringer i praksis ved direkte at være involveret i hvert projekt. Jeg forstår de spørgsmål, du måtte have, og stræber efter at tilbyde en personlig tilgang, der stemmer overens med dine unikke behov. Sammen kan vi bringe din vision til live, bakket op af den viden og forståelse, der kommer fra ægte, levet erfaring.
</FaqCollapse>

          </Grid>
        </Grid>
        {/* Sektion med knapper */}
        <Grid container justifyContent="center" mt={4}>
          <Grid item xs={12} md={3}>
          <MKButton variant="gradient" color="light" fullWidth component={Link} to="/da/FAQs">
    Se alle FAQ
</MKButton>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Faq;
