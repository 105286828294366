/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Golf1.jpeg";
import Image2 from "assets/images/Sports_Images/Golf2.webp";
import Image3 from "assets/images/Sports_Images/Golf3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"


// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'Introduction', title: 'Bourse de Golf : Comment Étudier et Jouer au Golf en Amérique', isSubheader: false },
  
    { id: 'Chapitre 1', title: 'Chapitre 1 : Histoire et Développement du Golf Universitaire', isSubheader: false },
    { id: '1.1', title: '1.1 Premières Années', isSubheader: true },
    { id: '1.2', title: '1.2 Croissance et Professionnalisation', isSubheader: true },
  
    { id: 'Chapitre 2', title: 'Chapitre 2 : Le Paysage Actuel du Golf Universitaire', isSubheader: false },
    { id: '2.1', title: '2.1 Principales Compétitions et Conférences', isSubheader: true },
    { id: '2.2', title: '2.2 Meilleurs Programmes et Équipes Prestigieuses', isSubheader: true },
  
    { id: 'Chapitre 3', title: 'Chapitre 3 : Du Collège aux Professionnels', isSubheader: false },
    { id: '3.1', title: '3.1 La Voie vers le Circuit Professionnel', isSubheader: true },
    { id: '3.2', title: '3.2 Voies Alternatives vers le Professionnalisme', isSubheader: true },
  
    { id: 'Chapitre 4', title: 'Chapitre 4 : Influences et Opportunités Internationales', isSubheader: false },
    { id: '4.1', title: '4.1 Joueurs Étrangers dans le Golf Universitaire', isSubheader: true },
    { id: '4.2', title: '4.2 Joueurs Américains à l\'Étranger', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Chapitre 5 : Bourses Sportives et l\'Avenir des Athlètes', isSubheader: false },
    { id: '5.1', title: '5.1 L\'Importance des Bourses', isSubheader: true },
    { id: '5.2', title: '5.2 Comment Sport Scholarships America Peut Aider', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };





  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Sommaire
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Cacher</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Afficher</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="Introduction" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Bourse de Golf : Comment Étudier et Jouer au Golf en Amérique
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Green de golf lors du championnat NCAA, avec des joueurs et des spectateurs autour du parcours"
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Le Championnat de Golf NCAA
      </figcaption>
    </figure>
    Le golf est l'un des sports les plus populaires au niveau universitaire aux États-Unis. Chaque année, des milliers d'étudiants-athlètes concourent pour des titres nationaux, des récompenses prestigieuses et une place dans le golf professionnel. Le golf universitaire offre non seulement une chance d'améliorer vos compétences athlétiques, mais aussi d'obtenir un diplôme académique, de construire un réseau et de vivre une expérience inoubliable.
    <br/>
    <br/>
    Cependant, le golf universitaire n'est pas pour tout le monde. Cela demande un haut niveau d'engagement, de discipline et de talent. De plus, il n'est pas facile d'être admis dans une université avec un bon programme de golf. La compétition est féroce et les coûts sont élevés.
    <br/>
    <br/>
    Heureusement, il existe des opportunités pour réaliser votre rêve avec l'aide d'une bourse de golf. Une bourse de golf est un soutien financier que vous recevez d'une université pour y étudier et y jouer au golf. Avec une bourse de golf, vous pouvez réduire ou même couvrir entièrement vos frais de scolarité tout en profitant de tous les avantages du golf universitaire.
    <br/>
    <br/>
    Mais comment obtenir une bourse de golf ? Et que devez-vous considérer lors du choix d'une université ? C'est là que Sportbeursamerika.nl peut vous aider. Sportbeursamerika.nl est spécialisé dans l'accompagnement des étudiants pour les bourses sportives en Amérique. J'ai de l'expérience et des connaissances sur le système éducatif et sportif américain, et j'ai aidé de nombreux étudiants-athlètes à réaliser leur rêve.
    <br/>
    <br/>
    Dans cet article, nous fournissons plus d'informations sur le golf universitaire et montrons comment Sportbeursamerika.nl peut vous aider à trouver la bourse de golf parfaite pour vous.
    <br/>
    <br/>
    Vous voulez en savoir plus ? Continuez à lire !
  </MKTypography>
  {/* Ajoutez une liste à puces ou un autre contenu au besoin */}
</MKBox>
{/* Principaux chapitres et sous-chapitres avec un texte d'espace réservé */}
<MKBox id="Chapitre 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 1 : Histoire et Développement du Golf Universitaire
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le golf universitaire a une longue et riche histoire qui remonte au début du 20e siècle. Dans ce chapitre, nous examinerons les premières années et la croissance et la professionnalisation du golf universitaire aux États-Unis.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Premières Années
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Les premières compétitions de golf dans les universités et les collèges aux États-Unis ont eu lieu dans les années 1890. Le premier match de golf intercollégial officiel a eu lieu entre Harvard et Yale en 1897. En 1901, l'Intercollegiate Golf Association (IGA) a été fondée, qui a organisé le premier championnat national en 1902. Yale a remporté le premier titre et a continué de dominer jusqu'aux années 1920.
      <br/>
      <br/>
      Les premiers programmes de golf dans les universités étaient principalement axés sur les aspects sociaux et récréatifs du sport. Les étudiants jouaient principalement sur des terrains locaux et avaient peu de conseils ou de installations. Les équipes de golf étaient généralement composées de quatre ou cinq joueurs qui se qualifiaient grâce à des tournois internes.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Croissance et Professionnalisation
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dans les années 1930, le golf universitaire a commencé à croître et à se professionnaliser de manière significative. Cette période a vu une augmentation du nombre de programmes universitaires de golf et une amélioration de la qualité de l'entraînement et des installations. La professionnalisation a été stimulée par l'attention croissante portée au sport et par la reconnaissance croissante du potentiel du golf au niveau collégial. Pendant cette période, la National Collegiate Athletic Association (NCAA) a joué un rôle de premier plan dans l'organisation et la réglementation du golf universitaire, contribuant au développement et à la popularité accrue du sport aux États-Unis.
      <br/>
      <br/>
      La NCAA a repris l'organisation du championnat national à l'IGA et a introduit de nouvelles règles et formats. La NCAA a divisé les universités en différentes divisions, en fonction de leur taille et de leur niveau athlétique. La NCAA a également organisé des championnats régionaux pour faciliter la qualification pour le championnat national.
      <br/>
      <br/>
      Les universités ont également commencé à investir davantage dans leurs programmes de golf, en engageant des entraîneurs professionnels, en construisant ou en améliorant des parcours de golf et en offrant des bourses aux joueurs talentueux. Les équipes de golf sont devenues plus grandes et plus fortes, et la compétition est devenue plus intense.
      <br/>
      <br/>
      Dans les années 1940 et 1950, d'autres tournois importants ont également été ajoutés, tels que la Walker Cup, la Palmer Cup et le Eisenhower Trophy. Ces tournois ont donné aux golfeurs universitaires la chance de concourir avec des joueurs internationaux et d'améliorer leurs compétences.
      <br/>
      <br/>
      Dans ce chapitre, nous avons vu comment le golf universitaire a émergé et s'est développé aux États-Unis. Dans le prochain chapitre, nous examinerons le paysage actuel du golf universitaire et quels sont les compétitions et programmes disponibles.
    </MKTypography>
  </MKBox>
</MKBox>














                                              





                                  








<MKBox id="Chapitre 2" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Chapitre 2 : Le Paysage Actuel du Golf Universitaire
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Aujourd'hui, le golf universitaire est l'un des sports les plus compétitifs et prestigieux au niveau universitaire aux États-Unis. Dans ce chapitre, nous examinerons les principales compétitions et conférences, ainsi que les meilleurs programmes et équipes prestigieuses du golf universitaire.
    </MKTypography>

    <MKBox id="2.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.1 Principales Compétitions et Conférences
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image2}
                    alt="Tiger Woods représentant Stanford dans le golf universitaire, montré en pleine concentration pendant un match."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal2(Image2)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Tiger Woods en action pour Stanford.
                </figcaption>
            </figure>
            La NCAA est l'organisation la plus importante pour le golf universitaire aux États-Unis. La NCAA divise les universités en trois divisions : Division I, Division II et Division III. Chaque division a son propre championnat, organisé chaque année en mai ou juin. Les championnats se composent d'un tournoi par équipe et d'un tournoi individuel, où les meilleurs joueurs et équipes de chaque division concourent pour le titre national.
            <br/>
            <br/>
            La NCAA a également plusieurs conférences régionales, composées d'universités qui sont géographiquement ou historiquement liées. Chaque conférence a son propre championnat, servant de tournoi de qualification pour le championnat national. Certaines conférences sont plus fortes que d'autres et ont plus d'influence sur le paysage du golf universitaire.
            <br/>
            <br/>
            Certaines des conférences les plus connues incluent :
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    L'Atlantic Coast Conference (ACC), composée de 15 universités sur la côte Est, telles que Duke, North Carolina et Clemson.
                </li>
                <li>
                    La Big Ten Conference, composée de 14 universités dans le Midwest, telles que Michigan, Ohio State et Illinois.
                </li>
                <li>
                    La Pac-12 Conference, composée de 12 universités sur la côte Ouest, telles que Stanford, California et Arizona State.
                </li>
                <li>
                    La Southeastern Conference (SEC), composée de 14 universités dans le Sud-Est, telles que Alabama, Georgia et Florida.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            En plus de la NCAA, il existe également d'autres organisations qui organisent des compétitions de golf universitaire, telles que la National Association of Intercollegiate Athletics (NAIA), la National Junior College Athletic Association (NJCAA) et la National Christian College Athletic Association (NCCAA). Ces organisations ont leurs propres divisions, conférences et championnats, qui sont généralement plus petits et moins prestigieux que ceux de la NCAA.
        </MKTypography>
    </MKBox>
    <MKBox id="2.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.2 Meilleurs Programmes et Équipes Prestigieuses
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Le golf universitaire n'est pas seulement un sport mais aussi une éducation. Les universités qui proposent le golf universitaire ont différents programmes, variant en qualité, réputation et installations. Certains programmes sont leaders et prestigieux, attirant les meilleurs joueurs et entraîneurs. D'autres programmes sont moins connus ou moins réussis mais offrent quand même de bonnes opportunités aux étudiants-athlètes.
            <br/>
            <br/>
            Certains des facteurs qui déterminent un programme comprennent :
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    L'histoire et la tradition du programme.
                </li>
                <li>
                    Le niveau et les résultats de l'équipe.
                </li>
                <li>
                    La qualité et l'expérience de l'entraîneur.
                </li>
                <li>
                    Les installations et l'équipement du programme.
                </li>
                <li>
                    Les normes académiques et les exigences du programme.
                </li>
                <li>
                    L'atmosphère et la culture du programme.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Certains des programmes de golf universitaire les plus remarquables incluent :
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    L'Université Stanford : Stanford possède l'un des plus anciens et des plus réussis programmes de golf aux États-Unis, avec plusieurs titres nationaux en golf masculin. Le programme a produit des anciens élèves célèbres tels que Tiger Woods et Tom Watson. L'équipe de golf féminin de Stanford a également acquis une reconnaissance nationale avec plusieurs titres NCAA et champions individuels.
                </li>
                <li>
                    L'Université d'État de l'Oklahoma : l'Oklahoma State possède un programme de golf dominant, notamment connu pour son équipe masculine, avec plusieurs titres nationaux. Le programme a produit des golfeurs de premier plan tels que Rickie Fowler et Viktor Hovland.
                </li>
                <li>
                    L'Université Duke : Duke est connue pour son solide programme de golf féminin, avec plusieurs titres NCAA. L'équipe de golf masculin de Duke a performé avec force dans les championnats de conférence et possède une histoire respectable dans les compétitions NCAA. Duke a produit des golfeurs talentueux, dont Amanda Blumenherst et Brittany Lang.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Dans ce chapitre, nous avons vu à quoi ressemble le paysage actuel du golf universitaire et quels sont les compétitions et programmes disponibles. Dans le prochain chapitre, nous verrons comment passer du collège aux pros et quelles sont les voies alternatives disponibles.
        </MKTypography>
    </MKBox>
</MKBox>













                                              






                                  








<MKBox id="Chapitre 3" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Chapitre 3 : Du Collège aux Professionnels
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Le golf universitaire n'est pas seulement un sport, mais aussi une voie de carrière. De nombreux golfeurs universitaires aspirent à passer au circuit professionnel et à jouer aux plus hauts niveaux du golf. Dans ce chapitre, nous explorerons comment le golf universitaire peut servir de tremplin vers le golf professionnel et les voies alternatives disponibles.
    </MKTypography>

    <MKBox id="3.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.1 La Voie vers le Circuit Professionnel
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Le golf universitaire est une excellente préparation pour une carrière professionnelle dans le golf. Les golfeurs universitaires ont l'opportunité de se développer athlétiquement, académiquement et personnellement. Ils apprennent à gérer la pression, la compétition et les attentes. Ils jouent sur des parcours difficiles contre des adversaires forts et dans diverses conditions. Ils ont également accès à des installations de haute qualité, à des entraîneurs et à des conseils.
            <br/>
            <br/>
            Les golfeurs universitaires ont également un avantage sur les autres joueurs qui n'assistent pas à l'université. Ils ont un diplôme qui peut les aider à trouver un emploi ou un sponsor s'ils ne réussissent pas en tant que professionnels. Ils ont également un réseau de contacts et d'amis qui peuvent les soutenir ou les conseiller dans leur carrière.
            <br/>
            <br/>
            De nombreux golfeurs universitaires passent au circuit professionnel après avoir terminé leurs études. Certains le font plus tôt s'ils estiment être prêts. Pour devenir professionnel, ils doivent se qualifier pour un circuit, tel que le PGA Tour, le LPGA Tour ou le European Tour, par le biais de tournois de qualification comme le Q-School ou le Korn Ferry Tour.
            <br/>
            <br/>
            De nombreux anciens golfeurs universitaires ont connu le succès sur le circuit professionnel. Parmi les exemples, on peut citer :
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Phil Mickelson : Mickelson a joué au golf universitaire pour l'Université d'État d'Arizona, où il a remporté le championnat NCAA trois fois. Il est devenu professionnel en 1992 et a depuis remporté de nombreux titres du PGA Tour, dont plusieurs majeurs.
                </li>
                <li>
                    Annika Sörenstam : Sörenstam a joué au golf universitaire pour l'Université de l'Arizona, où elle a remporté le championnat NCAA en 1991. Elle est devenue professionnelle en 1992 et a depuis remporté de nombreux titres professionnels, dont plusieurs majeurs.
                </li>
                <li>
                    Jon Rahm : Rahm a joué au golf universitaire pour l'Université d'État d'Arizona, où il a remporté le prix Ben Hogan deux fois en tant que meilleur golfeur universitaire. Il est devenu professionnel en 2016 et a depuis remporté plusieurs victoires professionnelles, dont des majeurs.
                </li>
            </ul>
        </MKTypography>
    </MKBox>
    <MKBox id="3.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.2 Voies Alternatives vers le Professionnalisme
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Bien que le golf universitaire soit une voie commune vers le circuit professionnel, ce n'est pas la seule. Il existe d'autres chemins que les joueurs peuvent suivre pour réaliser leur rêve. Certains joueurs choisissent de ne pas aller à l'université mais de devenir professionnels directement après le lycée. D'autres fréquentent l'université mais partent tôt pour se concentrer entièrement sur leur sport.
            <br/>
            <br/>
            Des exemples de joueurs qui ont emprunté une voie alternative incluent :
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Tiger Woods : Woods a joué au golf universitaire pour l'Université Stanford, où il a remporté le championnat NCAA deux fois. Cependant, il est devenu professionnel en 1996 après deux années d'études. Il a depuis remporté de nombreuses victoires sur le PGA Tour, dont plusieurs majeurs.
                </li>
                <li>
                    Lydia Ko : Ko n'a jamais joué au golf universitaire mais est devenue professionnelle en 2013 à l'âge de 16 ans. Elle a depuis remporté de nombreuses victoires sur le LPGA Tour.
                </li>
                <li>
                    Jordan Spieth : Spieth a joué au golf universitaire pour l'Université du Texas, où il a remporté le championnat NCAA en 2012. Cependant, il est devenu professionnel en 2012 après une année d'études. Il a depuis remporté de nombreuses victoires sur le PGA Tour, dont plusieurs majeurs.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Dans ce chapitre, nous avons vu comment passer du collège aux pros et quelles sont les voies alternatives disponibles. Dans le prochain chapitre, nous explorerons les influences et opportunités internationales dans le golf universitaire.
        </MKTypography>
    </MKBox>
</MKBox>






                                              






                                  








<MKBox id="Chapitre 4" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Chapitre 4 : Influences et Opportunités Internationales
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Le golf universitaire n'est pas seulement un sport américain, mais aussi un sport international. De nombreux joueurs étrangers viennent aux États-Unis pour étudier et jouer au golf, et il y a aussi de nombreux joueurs américains qui jouent à l'étranger. Dans ce chapitre, nous examinerons l'impact et les contributions des étudiants-athlètes internationaux et les opportunités pour les golfeurs universitaires américains de jouer à l'international.
    </MKTypography>

    <MKBox id="4.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.1 Joueurs Étrangers dans le Golf Universitaire
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image3}
                    alt="Texas Longhorns, avec Jordan Spieth, célébrant le championnat national"
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal3(Image3)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Texas Longhorns, avec Jordan Spieth, célébrant le championnat national
                </figcaption>
            </figure>
            Le golf universitaire est une option attrayante pour de nombreux joueurs étrangers à la recherche d'une combinaison de sport et d'éducation. Ils peuvent bénéficier de la haute qualité des universités américaines, des excellentes installations et conseils des programmes de golf, et de la forte compétition et exposition des tournois de golf universitaires.
            <br/>
            <br/>
            Les joueurs étrangers offrent également beaucoup aux universités américaines. Ils apportent de la diversité, de la culture et du talent à leurs équipes. Ils peuvent également partager leurs expériences et perspectives avec leurs coéquipiers et entraîneurs, contribuant à leur développement.
            <br/>
            <br/>
            De nombreux joueurs étrangers ont réussi dans le golf universitaire, se distinguant en tant qu'étudiants-athlètes. Parmi les exemples, on peut citer :
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Maria Fassi : Fassi a joué au golf universitaire à l'Université de l'Arkansas, où elle a remporté le prix ANNIKA deux fois en tant que meilleure golfeuse universitaire féminine. Elle est devenue professionnelle en 2019 et a depuis remporté plusieurs victoires professionnelles.
                </li>
                <li>
                    Viktor Hovland : Hovland a joué au golf universitaire à l'Université d'État d'Oklahoma, où il a remporté le championnat NCAA en 2018. Il est devenu professionnel en 2019 et a depuis remporté plusieurs victoires professionnelles.
                </li>
            </ul>
        </MKTypography>
    </MKBox>
    <MKBox id="4.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.2 Joueurs Américains à l'Étranger
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Le golf universitaire n'est pas le seul moyen de se développer en tant que golfeur. Il existe également de nombreuses opportunités pour les golfeurs universitaires américains de jouer à l'international, découvrant d'autres cultures et parcours. Cela peut élargir leurs horizons, augmenter leur adaptabilité et améliorer leur jeu.
            <br/>
            <br/>
            Les golfeurs universitaires américains peuvent jouer à l'international grâce à divers programmes, tels que :
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    La Palmer Cup : Un tournoi annuel entre des équipes de golfeurs universitaires américains et internationaux, nommé d'après Arnold Palmer, un célèbre golfeur qui a également joué au golf universitaire. Le tournoi se déroule à différents endroits dans le monde, tels que la France, l'Irlande, l'Angleterre et la Suède.
                </li>
                <li>
                    La Eisenhower Trophy : Un tournoi biennal entre des équipes de golfeurs amateurs de différents pays, nommé d'après Dwight D. Eisenhower, un ancien président américain et fervent golfeur. Le tournoi se déroule à divers endroits dans le monde, tels que l'Australie, le Japon, le Mexique et la Turquie.
                </li>
                <li>
                    Le Programme d'Études à l'Étranger : Un programme académique qui offre aux étudiants la possibilité d'étudier pendant un semestre ou une année dans une université étrangère. Certaines universités ont également des programmes de golf qui collaborent avec des universités étrangères, permettant aux étudiants-athlètes de jouer au golf pendant leur séjour.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            De nombreux golfeurs universitaires américains ont bénéficié de leur expérience à l'étranger, élevant leur jeu à un niveau supérieur. Parmi les exemples, on peut citer :
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Collin Morikawa : Morikawa a joué au golf universitaire à l'Université de Californie, Berkeley, où il est devenu quatre fois All-American. Il a également joué à la Palmer Cup en 2017 et 2018, et à la Eisenhower Trophy en 2018. Il est devenu professionnel en 2019 et a depuis remporté plusieurs victoires professionnelles.
                </li>
                <li>
                    Jennifer Kupcho : Kupcho a joué au golf universitaire à l'Université Wake Forest, où elle a remporté le championnat NCAA en 2018. Elle a également joué à la Palmer Cup en 2018, et à la Curtis Cup en 2016 et 2018. Elle est devenue professionnelle en 2019 et a depuis remporté plusieurs victoires professionnelles.
                </li>
                <li>
                    Patrick Reed : Reed a joué au golf universitaire à l'Université de Géorgie et à l'Université Augusta State, où il a remporté le championnat NCAA deux fois. Il a également joué à la Palmer Cup en 2011 et a étudié un semestre à l'Université de Stirling en Écosse. Il est devenu professionnel en 2011 et a depuis remporté plusieurs victoires professionnelles.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Dans ce chapitre, nous avons vu comment le golf universitaire est un sport international, et les influences et opportunités pour les joueurs étrangers et américains. Dans le prochain chapitre, nous examinerons l'importance des bourses de golf et le rôle de Sportbeursamerika.nl.
        </MKTypography>
    </MKBox>
</MKBox>











                                              






                                  




<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 5 : Bourses Sportives et l'Avenir des Athlètes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Les bourses sportives offrent de nombreuses opportunités aux jeunes athlètes de montrer leur talent et de développer leurs compétences. L'une des opportunités les plus significatives est d'obtenir une bourse sportive pour étudier et compétitionner au niveau collégial. Dans ce chapitre, nous discuterons de l'importance des bourses sportives et de comment Sport Scholarships America peut vous aider à réaliser votre rêve.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 L'Importance des Bourses
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Une bourse sportive est un soutien financier fourni par une université à un étudiant-athlète pour combiner études et sport. Elle peut couvrir les frais de scolarité, les livres, le logement, les repas et autres dépenses. De plus, elle peut offrir des avantages tels que du tutorat académique, des soins médicaux, des installations d'entraînement et du matériel.
      <br />
      <br />
      Obtenir une bourse sportive est compétitif, avec de nombreux facteurs qui influent sur le processus d'attribution, notamment les performances académiques, sportives, la personnalité, la motivation, le leadership et l'adaptabilité. Il est donc important de bien se préparer et de se démarquer de la concurrence.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Comment Sport Scholarships America Peut Aider
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo de Sport Scholarships America, représentant du matériel sportif et une casquette de graduation, symbolisant le focus sur les bourses sportives pour les étudiants-athlètes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America est une organisation spécialisée dans l'orientation des jeunes talents sportifs vers des bourses sportives. Avec des experts passionnés et expérimentés, Sport Scholarships America soutient les étudiants-athlètes à chaque étape du parcours, de l'orientation initiale à la placement final. Leurs services comprennent :
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Un entretien personnel pour discuter des souhaits, des possibilités et des attentes de l'étudiant-athlète.</li>
        <li>Une évaluation professionnelle du niveau académique et sportif de l'étudiant-athlète.</li>
        <li>Un vaste réseau de contacts avec des entraîneurs, des universités et des collèges.</li>
        <li>Promotion efficace du profil et des vidéos de l'étudiant-athlète auprès des entraîneurs potentiels.</li>
        <li>Conseils d'experts sur les meilleures options en fonction des préférences, des objectifs et du budget de l'étudiant-athlète.</li>
        <li>Négociation des meilleures offres de bourses au nom de l'étudiant-athlète.</li>
        <li>Aide à la demande de documents nécessaires, tels que visa, relevé de notes, diplôme et tests d'admission.</li>
        <li>Préparation à la vie d'étudiant-athlète pendant sa bourse, y compris culture, langue, règles et conseils.</li>
        <li>Suivi et accompagnement pendant la période de la bourse.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vous voulez explorer les opportunités de bourses et atteindre vos objectifs sportifs et académiques ? <Link to="/fr/Services-Produits">Consultez la gamme complète</Link> des services offerts par Sport Scholarships America et planifiez une consultation dès aujourd'hui.
    </MKTypography>
  </MKBox>
</MKBox>
          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;