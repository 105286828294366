/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Baseball1.jpg";
import Image2 from "assets/images/Sports_Images/Baseball2.jpeg";
import Image3 from "assets/images/Sports_Images/Baseball3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';

import "../../../../H1asH3.css"

const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Baseball e Studio in America: Un Sogno Che Può Diventare Realtà', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capitolo 1: La Storia e lo Sviluppo del Baseball Universitario', isSubheader: false },
    { id: '1.1', title: '1.1 Anni Iniziali e il Ruolo dei College', isSubheader: true },
    { id: '1.2', title: '1.2 Crescita e Professionalizzazione', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capitolo 2: Il Baseball Universitario Oggi', isSubheader: false },
    { id: '2.1', title: '2.1 Principali Leghe e Conferenze', isSubheader: true },
    { id: '2.2', title: '2.2 Programmi di Punta e Squadre Prestigiose', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capitolo 3: Dal College alla Professionalità', isSubheader: false },
    { id: '3.1', title: '3.1 La Via alla Professionalità', isSubheader: true },
    { id: '3.2', title: '3.2 Il Draft MLB e Altre Opportunità Professionali', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capitolo 4: Influenze Internazionali', isSubheader: false },
    { id: '4.1', title: '4.1 Giocatori Stranieri nel Baseball Universitario', isSubheader: true },
    { id: '4.2', title: '4.2 Americani all\'Estero', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capitolo 5: Borse di Studio Sportive e il Futuro degli Atleti', isSubheader: false },
    { id: '5.1', title: '5.1 L\'Importanza delle Borse di Studio', isSubheader: true },
    { id: '5.2', title: '5.2 Come Sport Scholarships America Può Aiutare', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indice
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Nascondi</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostra</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Baseball e Studio in America: Un Sogno Che Può Diventare Realtà
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Vista aerea dello stadio durante la College World Series."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        La College World Series
      </figcaption>
    </figure>
    Il baseball è uno degli sport più popolari e significativi negli Stati Uniti. Milioni di persone seguono la Major League Baseball (MLB), la massima lega professionistica di baseball al mondo. Ma prima di poter arrivare alla MLB, devi prima sviluppare e dimostrare le tue abilità a un altro livello: il baseball universitario.
    <br />
    <br />
    Il baseball universitario è il baseball giocato dagli studenti delle università e dei college americani. È uno sport altamente competitivo e prestigioso che attira molta attenzione e talento. Il baseball universitario serve come passo cruciale per i giocatori che cercano di giocare professionalmente e anche come opportunità per ricevere un'istruzione di qualità.
    <br />
    <br />
    Ma come si fa a entrare nel baseball universitario? E come si può ottenere una borsa di studio per studiare e giocare negli Stati Uniti? Qui è dove Sportbeursamerika.nl può aiutarti. Sportbeursamerika.nl è un'organizzazione specializzata nel guidare e supportare gli studenti-atleti che vogliono realizzare i loro sogni. Posso assisterti con tutto ciò di cui hai bisogno per trovare un'università adatta, ottenere una borsa di studio e prepararti alla vita negli Stati Uniti.
    <br />
    <br />
    In questo articolo, ti diremo tutto ciò che devi sapere sul baseball universitario negli Stati Uniti. Approfondiremo la storia e lo sviluppo di questo sport, le leghe e i campionati chiave, i programmi di punta e le squadre prestigiose, il percorso verso i professionisti, le influenze internazionali e, naturalmente, l'importanza delle borse di studio. Dopo aver letto questo articolo, avrai una migliore comprensione di cosa comporti il baseball universitario e come puoi aumentare le tue possibilità di farne parte.
    <br />
    <br />
    Sei pronto a realizzare il tuo sogno? Cominciamo!
  </MKTypography>
</MKBox>

<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 1: La Storia e lo Sviluppo del Baseball Universitario
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Il baseball è uno sport che esiste da oltre un secolo. Ma come è nato e cresciuto fino a diventare uno degli sport più popolari e significativi negli Stati Uniti? In questo capitolo, ti forniremo una breve panoramica della storia e dello sviluppo del baseball universitario.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Anni Iniziali e il Ruolo dei College
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      L'origine del baseball non è del tutto chiara. Ci sono varie teorie su come e dove il gioco sia iniziato. Alcuni dicono che sia derivato da giochi europei antichi come cricket, rounders o stoolball. Altri sostengono che sia nato da un gioco nativo americano chiamato lacrosse. Altri ancora suggeriscono che sia stato inventato da un soldato americano di nome Abner Doubleday nel 1839.
      <br />
      <br />
      Ciò che è certo è che il baseball è diventato rapidamente popolare negli Stati Uniti, specialmente nelle città della costa orientale. Veniva giocato da dilettanti, club, operai delle fabbriche e soldati. Veniva anche giocato dagli studenti delle università e dei college, che avevano le proprie squadre e regole.
      <br />
      <br />
      Il primo incontro intercollegiale di baseball conosciuto negli Stati Uniti ebbe luogo nel 1859 tra l'Amherst College e il Williams College, due rispettabili istituzioni accademiche nel Massachusetts. Questo incontro è storicamente significativo come uno dei primi esempi di giochi di baseball a livello universitario, un precursore dello sviluppo successivo del baseball universitario. Sebbene l'esito esatto e il corso della partita possano variare nelle fonti storiche, questo evento segna un punto di partenza importante nella storia del baseball intercollegiale. Illustra come il baseball diventò popolare tra gli studenti nei primi giorni e come le università divennero luoghi importanti per la crescita e lo sviluppo dello sport negli Stati Uniti.
      <br />
      <br />
      Negli anni '60 e '70 del XIX secolo, il numero di squadre universitarie crebbe costantemente. Alcune delle prime squadre includevano Harvard, Yale, Princeton, Dartmouth, Brown e Columbia. Queste squadre giocavano principalmente tra loro o contro club locali.
      <br />
      <br />
      Nel 1879 si tenne la prima conferenza intercollegiale di baseball a Springfield, Massachusetts. L'obiettivo era standardizzare e migliorare le regole e gli standard del gioco. La conferenza portò alla formazione dell'American College Baseball Association, con sei prestigiose istituzioni educative - Harvard, Yale, Princeton, Amherst, Dartmouth e Brown - facendone parte. Questo segnò l'inizio di una lunga tradizione di campionati nazionali di baseball universitario.
    </MKTypography>
  </MKBox>

  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crescita e Professionalizzazione
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Alla fine del XIX e all'inizio del XX secolo, il baseball divenne sempre più popolare e professionale negli Stati Uniti. La National League (NL) e l'American League (AL) furono stabilite come le principali leghe professionistiche di baseball, attirando molti giocatori, fan e denaro.
      <br />
      <br />
      Questo ebbe un impatto anche sul baseball universitario. Molti giocatori universitari furono reclutati o firmati da squadre professionistiche. Alcuni lasciarono le loro università per giocare professionalmente, mentre altri continuarono i loro studi ma giocavano anche a tempo parziale per squadre professionistiche.
      <br />
      <br />
      Ciò portò a varie questioni e conflitti nel baseball universitario. Alcune università si opposero all'idea che i loro giocatori fossero pagati per giocare, considerandolo contrario allo spirito amatoriale e all'integrità accademica dello sport universitario. Volevano anche trattenere i loro migliori giocatori per le proprie squadre.
      <br />
      <br />
      Altre università erano più tolleranti o pragmatiche riguardo al fenomeno dei giocatori professionisti. Riconoscevano che il baseball era uno sport redditizio e prestigioso che poteva fornire molti benefici alle loro istituzioni. Volevano anche competere con altre università ai massimi livelli.
      <br />
      <br />
      Per affrontare queste questioni, furono prese varie misure da diverse organizzazioni. L'IBA fu sostituito dalla National Collegiate Athletic Association (NCAA) nel 1906. La NCAA assunse la responsabilità di regolamentare e organizzare il baseball universitario e altri sport. La NCAA stabilì anche regole e linee guida per l'ammissibilità e il comportamento dei giocatori universitari.
      <br />
      <br />
      La NCAA organizzò anche la prima College World Series (CWS) nel 1947. Questo era il nuovo campionato nazionale per il baseball universitario, tenuto annualmente a Omaha, Nebraska. La CWS divenne rapidamente uno degli eventi più prestigiosi e popolari nello sport universitario.
      <br />
      <br />
      Oltre alla NCAA, c'erano altre organizzazioni coinvolte nel baseball universitario. Una di esse era la National Association of Intercollegiate Athletics (NAIA), fondata nel 1937. La NAIA era un'alternativa alla NCAA, concentrata su università e college più piccoli. La NAIA organizzava anche il suo campionato nazionale per il baseball universitario.
      <br />
      <br />
      Un'altra organizzazione era la National Junior College Athletic Association (NJCAA), fondata nel 1938. La NJCAA era un'organizzazione per i college comunitari che offrivano programmi biennali. La NJCAA organizzava anche il suo campionato nazionale per il baseball universitario.
      <br />
      <br />
      Queste organizzazioni contribuirono tutte alla crescita e alla professionalizzazione del baseball universitario. Hanno creato più opportunità e diversità per giocatori, allenatori, scuole e fan. Hanno anche reso il baseball universitario parte integrante del sistema di baseball americano.
    </MKTypography>
  </MKBox>
</MKBox>

                                              





                                  





<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 2: Il Baseball Universitario Oggi
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Il baseball universitario è uno degli sport più popolari e significativi negli Stati Uniti oggi. Attrae milioni di fan, attenzione mediatica e sponsor. Produce migliaia di giocatori, allenatori e ufficiali. Offre centinaia di borse di studio, premi ed onorificenze.
    <br />
    <br />
    Ma com'è il baseball universitario oggi? Come è organizzato il sistema? Quali sono le principali leghe e conferenze? Quali sono i programmi di punta e le squadre prestigiose? In questo capitolo, ti forniremo una panoramica del baseball universitario oggi.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Principali Leghe e Conferenze
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Barry Bonds in azione per l'Arizona State nel baseball universitario."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Barry Bonds come giocatore per l'Arizona State
        </figcaption>
      </figure>
      Come abbiamo visto nel capitolo precedente, diverse organizzazioni sono coinvolte nel baseball universitario. La più importante di queste è la NCAA, che rappresenta più di 1.200 università e college. La NCAA è divisa in tre divisioni: Division I, Division II e Division III.
      <br />
      <br />
      La Division I è il livello più alto del baseball universitario, composta da circa 300 scuole, divise in 31 conferenze. Ogni conferenza ha le proprie regole, calendari e campionati. Le conferenze più conosciute includono l'Atlantic Coast Conference (ACC), la Big Ten Conference (B1G), la Big 12 Conference (B12), la Pac-12 Conference (P12) e la Southeastern Conference (SEC).
      <br />
      <br />
      La Division II è il livello intermedio del baseball universitario, composta da circa 270 scuole, divise in 23 conferenze. Le conferenze sono tipicamente orientate regionalmente, come la California Collegiate Athletic Association (CCAA), la Gulf South Conference (GSC) e la Northeast-10 Conference (NE10).
      <br />
      <br />
      La Division III è il livello più basso del baseball universitario, composta da circa 380 scuole, divise in 43 conferenze. Anche le conferenze sono tipicamente orientate regionalmente, come la New England Small College Athletic Conference (NESCAC), la Southern California Intercollegiate Athletic Conference (SCIAC) e la Wisconsin Intercollegiate Athletic Conference (WIAC).
      <br />
      <br />
      Oltre alla NCAA, ci sono altre organizzazioni coinvolte nel baseball universitario. Una di esse è la NAIA, che rappresenta circa 190 scuole. La NAIA è divisa in 21 conferenze, come l'American Midwest Conference (AMC), la Golden State Athletic Conference (GSAC) e la Heart of America Athletic Conference (HAAC).
      <br />
      <br />
      Un'altra organizzazione è la NJCAA, che rappresenta circa 500 college comunitari. La NJCAA è divisa in tre divisioni: Division I, Division II e Division III. Ogni divisione è ulteriormente suddivisa in distretti e regioni.
      <br />
      <br />
      Queste organizzazioni tengono ogni anno i loro campionati nazionali di baseball universitario. Il campionato della Division I della NCAA è l'evento più conosciuto e prestigioso, spesso chiamato College World Series (CWS).
      <br />
      <br />
      Il CWS consiste in un torneo a doppia eliminazione di 64 squadre selezionate in base alle loro prestazioni nella stagione regolare e nei campionati di conferenza. Le squadre sono divise in 16 gruppi regionali di quattro squadre ciascuno. I vincitori di ogni gruppo regionale avanzano alla fase super regionale, dove competono contro un'altra squadra in una serie di tre partite. I vincitori di ogni fase super regionale passano al torneo finale del CWS, composto da due gruppi di quattro squadre ciascuno. I vincitori di ogni gruppo si sfidano in una serie di tre partite per determinare il campione nazionale.
      <br />
      <br />
      Il torneo finale del CWS si tiene ogni anno a giugno a Omaha, Nebraska. È considerato uno degli eventi più grandi ed emozionanti nello sport universitario, attirando migliaia di fan, media e osservatori.
      <br />
      <br />
      I campionati della Division II e della Division III della NCAA seguono un formato simile ma con meno squadre. Anche i campionati della NAIA e della NJCAA hanno i loro formati, che variano a seconda del numero di squadre partecipanti.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programmi di Punta e Squadre Prestigiose
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Il baseball universitario è uno sport altamente competitivo e prestigioso con una ricca tradizione e un vasto bacino di talenti. Ci sono molti programmi e squadre che si distinguono attraverso il loro successo e la loro reputazione. Alcuni di questi programmi e squadre includono:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Texas Longhorns: La squadra di baseball dell'Università del Texas ad Austin, che compete nella Big 12 Conference. Questo programma ha un impressionante curriculum con numerose apparizioni nel College World Series e vari campionati. Alcuni ex alunni notevoli includono Roger Clemens, Burt Hooton e Huston Street.
        </li>
        <li>
          LSU Tigers: La squadra di baseball della Louisiana State University a Baton Rouge, attiva nella SEC. LSU è nota per la sua dominanza nel baseball universitario e ha vinto diversi campionati. Alcuni ex alunni notevoli includono Albert Belle, Aaron Hill e DJ LeMahieu.
        </li>
        <li>
          USC Trojans: La squadra di baseball dell'Università della California del Sud a Los Angeles, membro della Pac-12 Conference. USC ha una ricca tradizione con numerosi campionati e ha prodotto molti giocatori di talento. Alcuni famosi ex alunni includono Tom Seaver, Randy Johnson e Mark McGwire.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Questi sono solo alcuni esempi di programmi di punta e squadre prestigiose nel baseball universitario. Ci sono molti altri programmi e squadre, ognuno con la propria storia, cultura e identità.
      <br />
      <br />
      Questi programmi e squadre contribuiscono allo sviluppo dei giocatori in vari modi. Forniscono loro un alto livello di allenamento, strutture e competizione. Inoltre, li aiutano a raggiungere i loro obiettivi accademici, sportivi e personali.
    </MKTypography>
  </MKBox>
</MKBox>






                                  








<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 3: Dal College alla Professionalità
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Il baseball universitario non è solo uno sport; è anche una via verso la professionalità. Molti giocatori di baseball universitari hanno l'ambizione di giocare professionalmente, sia nella MLB che in altre leghe professionistiche. Ma com'è questa via? Come si passa dal college alla professionalità? In questo capitolo, ti forniremo una panoramica della via alla professionalità nel baseball.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 La Via alla Professionalità
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La via alla professionalità nel baseball di solito inizia con il Draft MLB. Il Draft MLB è un evento annuale in cui le 30 squadre MLB si alternano nella selezione di giocatori provenienti da varie fonti, tra cui il baseball universitario, il baseball delle scuole superiori, il baseball internazionale e il baseball indipendente.
      <br />
      <br />
      Il Draft MLB consiste in 40 turni, con ciascuna squadra autorizzata a scegliere un giocatore per turno. L'ordine delle selezioni è determinato dalla classifica inversa della stagione MLB precedente, con alcune eccezioni per le scelte di compensazione e le scelte della lotteria.
      <br />
      <br />
      Il Draft MLB è una significativa opportunità per i giocatori di baseball universitari di avviare le loro carriere professionali. Circa 1.200 giocatori vengono selezionati nel Draft MLB ogni anno, di cui circa il 60% proviene dal baseball universitario.
      <br />
      <br />
      Se sei scelto nel Draft MLB, hai due opzioni: puoi firmare un contratto con la squadra che ti ha selezionato, oppure puoi scegliere di non firmare e tornare alla tua università o ad un'altra fonte.
      <br />
      <br />
      Se scegli di firmare un contratto, riceverai un bonus di firma in base al turno in cui sei stato selezionato. I bonus variano da diversi milioni di dollari per il primo turno a diversi migliaia di dollari per i turni successivi.
      <br />
      <br />
      Quando firmi un contratto, verrai assegnato a una delle squadre delle leghe minori della squadra che ti ha selezionato. Le leghe minori sono i livelli inferiori del baseball professionistico, servendo come sistema di sviluppo per la MLB. Ci sono sei livelli nelle leghe minori: Rookie, Classe A Short Season, Classe A, Classe A Advanced, Double-A e Triple-A.
      <br />
      <br />
      Mentre giochi nelle leghe minori, devi migliorare le tue abilità e dimostrare le tue prestazioni per avanzare ai livelli superiori. Devi anche competere con altri giocatori che condividono lo stesso sogno: arrivare alle major league.
      <br />
      <br />
      Raggiungere le major league è l'obiettivo finale per ogni giocatore di baseball professionista. È il livello più alto del baseball professionistico, dove puoi competere contro i migliori giocatori del mondo, davanti ai più grandi fan e per i salari più alti.
      <br />
      <br />
      Tuttavia, arrivare alle major league non è facile. Solo circa il 10% dei giocatori selezionati nel Draft MLB raggiunge effettivamente le major league, e solo circa l'1% di quelli selezionati ha carriere lunghe e di successo nelle major league.
      <br />
      <br />
      Quindi, la via alla professionalità nel baseball è un lungo e difficile viaggio che richiede dedizione, duro lavoro e un po' di fortuna. Ma è anche una via che può offrire molte ricompense, opportunità e sogni.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Il Draft MLB e Altre Opportunità Professionali
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Il Draft MLB non è l'unico modo per giocare professionalmente nel baseball. Ci sono anche altre opportunità professionali al di fuori della MLB che possono essere interessanti per i giocatori di baseball universitari.
      <br />
      <br />
      Una di esse è la lega indipendente. La lega indipendente è una lega professionistica di baseball non affiliata con la MLB o le leghe minori. La lega indipendente è composta da varie leghe, come l'Atlantic League, l'American Association e la Frontier League.
      <br />
      <br />
      La lega indipendente offre un'alternativa per i giocatori che non sono selezionati nel Draft MLB o non ricevono offerte contrattuali da una squadra MLB. La lega indipendente offre anche una possibilità per i giocatori che vengono rilasciati dalle leghe minori o desiderano prolungare le loro carriere.
      <br />
      <br />
      La lega indipendente ha un livello di gioco e di paga inferiore rispetto alla MLB o alle leghe minori, ma offre anche un livello di libertà e divertimento più elevato. La lega indipendente è nota per la sua creatività, diversità e sperimentazioni. Ha anche prodotto alcuni giocatori che alla fine hanno raggiunto le major league, come Jose Bautista, Rich Hill e Max Muncy.
      <br />
      <br />
      Un'altra opportunità professionale è la lega internazionale. La lega internazionale è un termine collettivo per le leghe professionistiche di baseball giocate al di fuori degli Stati Uniti. La lega internazionale è composta da varie leghe, come la Nippon Professional Baseball (NPB) in Giappone, la Korean Baseball Organization (KBO) in Corea del Sud, la Chinese Professional Baseball League (CPBL) a Taiwan e la Mexican Baseball League (LMB) in Messico.
      <br />
      <br />
      La lega internazionale offre un'opportunità per i giocatori che desiderano ampliare i propri orizzonti e sperimentare una cultura diversa. La lega internazionale offre anche un'opportunità per i giocatori che cercano un livello di gioco e di paga più elevato rispetto alla lega indipendente ma potrebbero non riuscire a raggiungere la MLB o le leghe minori.
      <br />
      <br />
      Questi sono solo alcuni esempi di opportunità professionali al di fuori della MLB. Ci sono molte altre opportunità, come le leghe invernali, le leghe estive universitarie e le leghe amatoriali.
      <br />
      <br />
      Queste opportunità offrono tutte vantaggi e svantaggi diversi per i giocatori di baseball universitari. Forniscono diversi livelli di competizione, ricompensa, sfida e divertimento. Offrono varie vie verso la professionalità nel baseball.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  







<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 4: Influenze Internazionali
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Il baseball universitario non è solo uno sport americano; è anche uno sport internazionale influenzato da giocatori, allenatori e tifosi provenienti da vari paesi e culture. In questo capitolo, ti forniremo una panoramica delle influenze internazionali nel baseball universitario.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Giocatori Stranieri nel Baseball Universitario
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Squadra di baseball dell'USC che festeggia una vittoria."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Giocatori dell'USC che festeggiano una vittoria
        </figcaption>
      </figure>
      Una delle influenze internazionali più significative nel baseball universitario è la presenza di giocatori stranieri. I giocatori stranieri sono giocatori che non sono nati negli Stati Uniti o non hanno cittadinanza americana. Provengono da paesi diversi, come Canada, Messico, Giappone, Corea del Sud, Taiwan, Cuba, Venezuela, Repubblica Dominicana e Paesi Bassi.
      <br />
      <br />
      I giocatori stranieri svolgono un ruolo vitale nel baseball universitario. Portano le proprie abilità, stili e prospettive al gioco. Arricchiscono la diversità e la qualità del baseball universitario. Aumentano anche il riconoscimento internazionale e l'appeal del baseball universitario.
      <br />
      <br />
      Ci sono molte storie di successo di giocatori stranieri che si sono affermati nel mondo del baseball universitario americano. Un esempio importante è Nomar Garciaparra. Nomar Garciaparra era un shortstop messicano-americano che ha giocato per il Georgia Institute of Technology. È stato selezionato nel primo turno del Draft MLB del 1994 dai Boston Red Sox. Ha giocato 14 stagioni nella MLB, dove è diventato All-Star sei volte e ha vinto il titolo di battuta AL due volte. Ci sono molti altri esempi di giocatori stranieri che hanno lasciato il segno nel baseball universitario.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Americani all'Estero
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Un'altra influenza internazionale nel baseball universitario è la presenza di americani all'estero. Gli americani all'estero sono giocatori che sono nati negli Stati Uniti o hanno cittadinanza americana ma giocano o hanno giocato nelle leghe professionistiche al di fuori degli Stati Uniti.
      <br />
      <br />
      Gli americani all'estero svolgono anche un ruolo significativo nel baseball universitario. Portano le proprie esperienze, conoscenze e reti al gioco. Imparano anche da altre culture e stili di baseball. Potenziano lo scambio e la collaborazione internazionali nel baseball universitario.
      <br />
      <br />
      Queste opportunità offrono molti vantaggi ai giocatori di baseball universitari americani. Forniscono un livello più elevato di competizione, esposizione e ricompensa. Aiutano anche ad ampliare i loro orizzonti, aumentare l'adattabilità ed espandere le loro reti.
      <br />
      <br />
      L'esperienza internazionale può essere vantaggiosa per lo sviluppo di un giocatore. Può aiutarli a imparare nuove abilità, tecniche e strategie. Può anche aiutarli a migliorare i loro punti deboli, sfruttare i loro punti di forza e massimizzare il loro potenziale.
    </MKTypography>
  </MKBox>
</MKBox>







                                  






<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 5: Borse di Studio Sportive e il Futuro degli Atleti
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le borse di studio sportive offrono numerose opportunità ai giovani atleti di mostrare il loro talento e sviluppare le loro abilità. Una delle opportunità più significative è ottenere una borsa di studio sportiva per studiare e competere a livello universitario. In questo capitolo, discuteremo dell'importanza delle borse di studio sportive e di come Sport Scholarships America può aiutarti a realizzare il tuo sogno.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 L'Importanza delle Borse di Studio
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Una borsa di studio sportiva è un sostegno finanziario fornito da un'università a uno studente-atleta per combinare studio e sport. Può coprire i costi di iscrizione, libri, alloggio, pasti e altre spese. Inoltre, può offrire vantaggi come il tutorato accademico, l'assistenza medica, le strutture di allenamento e l'attrezzatura.
      <br />
      <br />
      Ottenere una borsa di studio sportiva è competitivo, con molti fattori che influenzano il processo di assegnazione, tra cui risultati accademici, prestazioni sportive, personalità, motivazione, leadership e adattabilità. È quindi importante prepararsi bene e distinguersi dalla concorrenza.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Come Sport Scholarships America Può Aiutare
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo di Sport Scholarships America, raffigurante attrezzature sportive e una toga, simbolo del focus sulle borse di studio sportive per gli studenti-atleti."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo di Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America è un'organizzazione specializzata nell'orientamento dei giovani talenti sportivi verso borse di studio sportive. Con esperti appassionati ed esperti, Sport Scholarships America supporta gli studenti-atleti in ogni fase del percorso, dall'orientamento iniziale alla collocazione finale. I loro servizi includono:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Un'intervista personale per discutere i desideri, le possibilità e le aspettative dello studente-atleta.</li>
        <li>Una valutazione professionale del livello accademico e sportivo dello studente-atleta.</li>
        <li>Una vasta rete di contatti con allenatori, università e college.</li>
        <li>Promozione efficace del profilo e dei video dello studente-atleta presso allenatori potenziali.</li>
        <li>Consulenza esperta sulle migliori opzioni in base alle preferenze, agli obiettivi e al budget dello studente-atleta.</li>
        <li>Negoziazione delle migliori offerte di borse di studio per conto dello studente-atleta.</li>
        <li>Assistenza nella richiesta dei documenti necessari, come visto, trascrizione, diploma e test di ammissione.</li>
        <li>Preparazione alla vita dello studente-atleta durante la borsa di studio, compresa cultura, lingua, regole e consigli.</li>
        <li>Assistenza e monitoraggio durante il periodo di borsa di studio.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vuoi esplorare le opportunità di borse di studio e raggiungere i tuoi obiettivi sportivi e accademici? <Link to="/it/Servizi-Prodotti">Consulta la gamma completa</Link> dei servizi offerti da Sport Scholarships America e prenota una consultazione oggi stesso.
    </MKTypography>
  </MKBox>
</MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;