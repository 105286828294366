// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";
import "../../../../H1asH3.css"

// Images
import profilePicture from "assets/images/Profielfoto_Kay_Duit.jpeg";

function Perfil() {
  return (
    <MKBox component="section" pt={{ xs: 20, sm: 20,  md: 6}} pb={{ xs: 6, sm: 0}}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
            <MKAvatar src={profilePicture} alt="Foto de perfil de Kay Duit, fundador y propietario" size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox 
                display="flex" 
                flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} 
                justifyContent="space-between" 
                alignItems="center" 
                mb={1}
              >
                <MKTypography variant="h1" className="h1-como-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Kay Duit</MKTypography>
                <MKBox display="flex" flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} gap={1}>
                  <a href="https://www.linkedin.com/in/kayduit/" target="_blank" rel="noopener noreferrer">
                    <MKSocialButton color="linkedin" style={{ width: '140px' }}>
                      <MKBox component="i" color="inherit" mr={0.5} className="fab fa-linkedin" />
                      LinkedIn
                    </MKSocialButton>
                  </a>
                  <a href="https://www.instagram.com/kayduit/" target="_blank" rel="noopener noreferrer">
                    <MKSocialButton color="instagram" style={{ width: '140px' }}>
                      <MKBox component="i" color="inherit" mr={0.5} className="fab fa-instagram" />
                      Instagram
                    </MKSocialButton>
                  </a>
                  <a href="/Podcast">
                    <MKSocialButton color="pinterest" style={{ width: '140px' }}>
                      <MKBox component="i" color="inherit" mr={0.5} className="fas fa-podcast" />
                      Podcast
                    </MKSocialButton>
                  </a>
                </MKBox>
              </MKBox>
              <br />
              <MKBox>
                <MKTypography variant="body1" fontWeight="light" color="text">
                  En una calurosa tarde de verano en América, me encontraba en el lateral del campo de fútbol, esperando el silbato para entrar al campo. Mi corazón latía en mi garganta, no por el próximo partido, sino por la realización de lo lejos que había llegado. Tres años antes, había tomado un avión desde mi país natal a los Estados Unidos, un salto a lo desconocido, en busca de aventuras y la oportunidad de realizar mis sueños tanto deportivos como académicos.
                  <br /><br />
                  Durante esos cuatro años, había experimentado mucho más que solo los partidos de fútbol y el estudio. Estaban las cenas nocturnas después de una gran victoria, los vuelos a partidos fuera en estados lejanos, las amistades que había construido y las innumerables veces que me di cuenta de lo mucho más grande que era el mundo en comparación con el pequeño lugar de donde venía.
                  <br /><br />
                  Esa noche, mientras sonaba el himno nacional y las gradas estaban llenas de miles de fanáticos animando, pensé en todos los jóvenes estudiantes de todo el mundo que, al igual que yo, soñaban con tales oportunidades. Y me comprometí a mí mismo que una vez que mi viaje en América concluyera, extendería una mano amiga a estos estudiantes aspirantes, sin importar de dónde vinieran, ayudándoles a alcanzar sueños similares a los míos.
                </MKTypography>
              </MKBox>

              <MKTypography
                component="a"
                href="#"
                variant="body1"
                fontWeight="light"
                color="info"
                mt={3}
                sx={{
                  width: "max-content",
                  display: "flex",
                  alignItems: "center",
                  "& .material-icons-round": {
                    transform: `translateX(3px)`,
                    transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                  },
                  "&:hover .material-icons-round, &:focus .material-icons-round": {
                    transform: `translateX(6px)`,
                  },
                }}
              >
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Perfil;