/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Página del producto: https://www.creative-tim.com/product/material-kit-pro-react
* Derechos de autor 2023 Creative Tim (https://www.creative-tim.com)

Codificado por www.creative-tim.com

 =========================================================

* Este aviso de derechos de autor y este aviso de permiso se incluirán en todas las copias o partes sustanciales del Software.
*/

// @mui componentes de material

import React from "react";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Componentes de Material Kit 2 PRO React
import MKBox from "components/MKBox";

// Ejemplos de Material Kit 2 PRO React
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

function StatsOne() {
  return (
    <MKBox component="section" py={8}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard 
            count={100} 
            suffix="+"
            title="Estudiantes:"
            description="Guiados con éxito a sus universidades de ensueño"  
            onClick={() => console.log('Clic en 100')}
            />
          </Grid>
          <Grid item xs={12} md={4} >
            <DefaultCounterCard
              count={70}
              title="Universidades:"
              suffix="+"
              description="Estudiantes colocados con éxito en toda América"
              onClick={() => console.log('Clic en 70')}

            />
          </Grid>
          <Grid item xs={12} md={4}>
          <Link to="/es/Todos-los-deportes">
              <DefaultCounterCard
                count={10}
                suffix="+"
                title="Deportes:"
                description="Guiando talentos en diversas disciplinas"
              />
            </Link>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default StatsOne;
