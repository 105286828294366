/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";


// Material Kit 2 PRO React examples
import RaisedBlogCard from "examples/Cards/BlogCards/RaisedBlogCard";


// Images
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import NCAA from "assets/images/Logos/NCAA_Transparent.png"
import NJCAA from "assets/images/Logos/NJCAA_Transparent.png"
import NAIA from "assets/images/Logos/NAIA_Transparent.png"

function BlogPostThree() {
  const post1 = (NCAA)
  const post2 = (NJCAA)
  const post3 = (NAIA)

  return (
    <>
      <MKBox position="relative" variant="gradient" bgColor="dark" mt={{ xs: 0, lg: 8 }} mx={-2}>
        <MKBox
          component="img"
          src={bgPattern}
          alt="patrón de fondo"
          position="absolute"
          top={0}
          left={0}
          width={{ xs: "100%", lg: "100%" }}
          height={{ xs: "auto", lg: "auto" }}
          opacity={0.6}
        />
        <Container>
          <Grid container justifyContent="center" sx={{ pt: 6, pb: 18 }}>
            <Grid item xs={12} md={7} sx={{ textAlign: "center" }}>
              <MKTypography variant="h2" color="white" mb={1}>
                Oportunidades en todos los niveles
              </MKTypography>
              <MKTypography variant="body2" color="white">
                Cada deporte, cada nivel académico: explore un mundo de oportunidades en innumerables universidades estadounidenses para cada estudiante-atleta.
              </MKTypography>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
  
      <MKBox component="section" mt={-10}>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4} mb={{ xs: 3, lg: 0 }}>
              <RaisedBlogCard
                image={post1}
                category={{ color: "primary", label: "Casa" }}
                title="NCAA"
                subtitle="Asociación Nacional de Atletismo Universitario"
                description=
                  "La NCAA administra el deporte universitario en los Estados Unidos y supervisa competencias para más de 1,100 universidades afiliadas. Como organización líder, desempeña un papel crucial en el panorama deportivo universitario estadounidense."
                action={{
                  type: "internal",
                  route: "/es/NCAA",
                  color: "info",
                  label: "Más información",
                }}
                imageStyle={{ marginTop: "-44px" }}
                shadowTop="-30px" // Mueva la sombra hacia abajo en 50px para que coincida con el movimiento de la imagen
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} mb={{ xs: 3, lg: 0 }}>
              <RaisedBlogCard
                image={post2}
                category={{ color: "primary", label: "Casa" }}
                title="NJCAA"
                subtitle="Asociación Nacional de Atletismo de Junior College"
                description="La NJCAA regula el deporte en colegios comunitarios en los Estados Unidos y administra competencias para cientos de instituciones afiliadas. Como organización central, es esencial para el deporte de dos años en América, donde se desarrolla el talento para niveles superiores."
                action={{
                  type: "internal",
                  route: "/es/NJCAA",
                  color: "info",
                  label: "Más información",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} mb={{ xs: 3, lg: 0 }}>
              <RaisedBlogCard
                image={post3}
                category={{ color: "primary", label: "Casa" }}
                title="NAIA"
                subtitle="Asociación Nacional de Atletismo Interuniversitario"
                description=
                  "La NAIA desempeña un papel central en la gestión del deporte en universidades de pequeña escala en los Estados Unidos, con cientos de instituciones afiliadas. Como organización líder, desempeña un papel vital en el panorama deportivo interuniversitario estadounidense, promoviendo el talento y la integridad."
                action={{
                  type: "internal",
                  route: "/es/NAIA",
                  color: "info",
                  label: "Más información",
                }}
                imageStyle={{ marginTop: "50px" }}
                shadowTop="none"
                paddingBelowImage="120px"
              />
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
  
}

export default BlogPostThree;
