/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Baseball1.jpg";
import Image2 from "assets/images/Sports_Images/Baseball2.jpeg";
import Image3 from "assets/images/Sports_Images/Baseball3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';

import "../../../../H1asH3.css"

const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Baseball og Studier i Amerika: En Drøm Som Kan Gå i Oppfyllelse', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapittel 1: Historien og Utviklingen av Collegelaget i Baseball', isSubheader: false },
    { id: '1.1', title: '1.1 Tidlige År og Rollen til Collegene', isSubheader: true },
    { id: '1.2', title: '1.2 Vekst og Professionalisering', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapittel 2: Collegelaget i Baseball i Dag', isSubheader: false },
    { id: '2.1', title: '2.1 Store Ligaer og Konferanser', isSubheader: true },
    { id: '2.2', title: '2.2 Topp Programmer og Prestisjefylte Lag', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapittel 3: Fra College til Profesjonelt Nivå', isSubheader: false },
    { id: '3.1', title: '3.1 Veien til Profesjonalitet', isSubheader: true },
    { id: '3.2', title: '3.2 MLB-draften og Andre Profesjonelle Muligheter', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapittel 4: Internasjonale Innflytelser', isSubheader: false },
    { id: '4.1', title: '4.1 Utenlandske Spillere i Collegelaget i Baseball', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanere i Utlandet', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapittel 5: Idrettsstipender og Fremtiden til Utøvere', isSubheader: false },
    { id: '5.1', title: '5.1 Viktigheten av Stipender', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Scholarships America Kan Hjelpe', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Baseball og Studier i Amerika: En Drøm Som Kan Gå i Oppfyllelse
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Fugleperspektiv av stadion under College World Series."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        College World Series
      </figcaption>
    </figure>
    Baseball er en av de mest populære og betydningsfulle idrettene i USA. Millioner av mennesker følger Major League Baseball (MLB), den høyeste profesjonelle baseballigaen i verden. Men før du kan nå MLB, må du først utvikle og bevise ferdighetene dine på et annet nivå: collegelaget i baseball.
    <br />
    <br />
    Collegelaget i baseball er baseballen spilt av studenter ved amerikanske universiteter og høyskoler. Det er en svært konkurransedyktig og prestisjefylt idrett som tiltrekker seg mye oppmerksomhet og talent. Collegelaget i baseball fungerer som et avgjørende steg for spillere som ønsker å spille profesjonelt, og også som en mulighet til å få en god utdannelse.
    <br />
    <br />
    Men hvordan kommer du inn på collegelaget i baseball? Og hvordan kan du få et stipend for å studere og spille i USA? Dette er hvor Sportbeursamerika.nl kan hjelpe deg. Sportbeursamerika.nl er en organisasjon spesialisert i å veilede og støtte studentidrettsutøvere som ønsker å oppfylle drømmene sine. Jeg kan hjelpe deg med alt du trenger for å finne et passende universitet, få et stipend og forberede deg på livet i USA.
    <br />
    <br />
    I denne artikkelen vil vi fortelle deg alt du trenger å vite om collegelaget i baseball i USA. Vi vil gå inn på historien og utviklingen av denne idretten, de viktigste ligaene og konferansene, toppprogrammer og prestisjetunge lag, veien til proffene, internasjonale innflytelser, og selvfølgelig betydningen av stipender. Etter å ha lest denne artikkelen, vil du ha en bedre forståelse av hva collegelaget i baseball innebærer og hvordan du kan øke sjansene dine for å være en del av det.
    <br />
    <br />
    Er du klar for å oppfylle drømmen din? La oss komme i gang!
  </MKTypography>
</MKBox>

<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 1: Historien og Utviklingen av Collegelaget i Baseball
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Baseball er en idrett som har eksistert i over et århundre. Men hvordan oppstod den og vokste til å bli en av de mest populære og betydningsfulle idrettene i USA? I dette kapittelet vil vi gi deg en kort oversikt over historien og utviklingen av collegelaget i baseball.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidlige År og Rollen til Collegene
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Opprinnelsen til baseball er ikke helt klar. Det er ulike teorier om hvordan og hvor spillet begynte. Noen sier at det nedstammer fra gamle europeiske spill som cricket, rounders eller stoolball. Andre hevder at det oppsto fra et innfødt amerikansk spill kalt lacrosse. Fortsatt andre antyder at det ble oppfunnet av en amerikansk soldat ved navn Abner Doubleday i 1839.
      <br />
      <br />
      Det som er sikkert, er at baseball raskt ble populært i USA, spesielt i byene på østkysten. Det ble spilt av amatører, klubber, fabrikkarbeidere og soldater. Det ble også spilt av studenter ved universiteter og høyskoler, som hadde sine egne lag og regler.
      <br />
      <br />
      Den første kjente intercollegiate baseballkampen i USA fant sted i 1859 mellom Amherst College og Williams College, to respekterte akademiske institusjoner i Massachusetts. Denne kampen er historisk betydningsfull som et av de tidligste eksemplene på collegenivå baseballkamper, en forløper for senere utvikling av collegelaget i baseball. Selv om det nøyaktige utfallet og forløpet til kampen kan variere i historiske kilder, markerer denne hendelsen et viktig startpunkt i historien om intercollegiate baseball. Den illustrerer hvordan baseball ble populært blant studenter i de tidlige dagene, og hvordan universitetene ble viktige arenaer for vekst og utvikling av sporten i USA.
      <br />
      <br />
      På 1860- og 1870-tallet vokste antallet collegelag jevnt. Noen av de tidligste lagene inkluderte Harvard, Yale, Princeton, Dartmouth, Brown og Columbia. Disse lagene spilte hovedsakelig mot hverandre eller lokale klubber.
      <br />
      <br />
      I 1879 ble den første intercollegiate baseballkonferansen holdt i Springfield, Massachusetts. Målet var å standardisere og forbedre reglene og standardene for spillet. Konferansen førte til dannelsen av American College Baseball Association, med seks prestisjetunge utdanningsinstitusjoner - Harvard, Yale, Princeton, Amherst, Dartmouth og Brown - som var en del av den. Dette markerte begynnelsen på en lang tradisjon med nasjonale mesterskap i collegelaget i baseball.
    </MKTypography>
  </MKBox>

  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Vekst og Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      På slutten av 1800-tallet og begynnelsen av 1900-tallet ble baseball stadig mer populært og profesjonelt i USA. National League (NL) og American League (AL) ble etablert som de største profesjonelle baseballigaene, og tiltok mange spillere, fans og penger.
      <br />
      <br />
      Dette hadde også en innvirkning på collegelaget i baseball. Mange collegespillere ble rekruttert eller signert av profesjonelle lag. Noen forlot universitetene sine for å spille profesjonelt, mens andre fortsatte studiene sine, men spilte også deltid for profesjonelle lag.
      <br />
      <br />
      Dette førte til ulike problemer og konflikter i collegelaget i baseball. Noen universiteter var imot ideen om at spillerne deres ble betalt for å spille, og anså det som motstridende med den amatørånden og akademiske integriteten til collegeidretten. De ønsket også å beholde sine beste spillere for sine egne lag.
      <br />
      <br />
      Andre universiteter var mer tolerante eller pragmatiske når det gjaldt fenomenet profesjonelle spillere. De erkjente at baseball var en lukrativ og prestisjefylt sport som kunne gi mange fordeler til institusjonene deres. De ønsket også å konkurrere med andre universiteter på det høyeste nivået.
      <br />
      <br />
      For å håndtere disse problemene ble ulike tiltak tatt av ulike organisasjoner. IBA ble erstattet av National Collegiate Athletic Association (NCAA) i 1906. NCAA tok på seg ansvaret for å regulere og organisere collegelaget i baseball og andre idretter. NCAA etablerte også regler og retningslinjer for kvalifisering og oppførsel for collegespillere.
      <br />
      <br />
      NCAA organiserte også den første College World Series (CWS) i 1947. Dette var det nye nasjonale mesterskapet for collegelaget i baseball, holdt årlig i Omaha, Nebraska. CWS ble raskt en av de mest prestisjefylte og populære hendelsene i collegeidretten.
      <br />
      <br />
      I tillegg til NCAA var det andre organisasjoner involvert i collegelaget i baseball. En av dem var National Association of Intercollegiate Athletics (NAIA), etablert i 1937. NAIA var et alternativ til NCAA, med fokus på mindre universiteter og høyskoler. NAIA organiserte også sitt eget nasjonale mesterskap for collegelaget i baseball.
      <br />
      <br />
      En annen organisasjon var National Junior College Athletic Association (NJCAA), grunnlagt i 1938. NJCAA var en organisasjon for community colleges som tilbød toårige programmer. NJCAA organiserte også sitt eget nasjonale mesterskap for collegelaget i baseball.
      <br />
      <br />
      Disse organisasjonene bidro alle til vekst og professionalisering av collegelaget i baseball. De skapte flere muligheter og mangfold for spillere, trenere, skoler og fans. De gjorde også collegelaget i baseball til en integrert del av det amerikanske baseballsystemet.
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  






<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 2: Collegelaget i Baseball i Dag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Collegelaget i baseball er en av de mest populære og betydningsfulle idrettene i USA i dag. Den tiltrekker millioner av fans, medieoppmerksomhet og sponsorer. Den produserer tusenvis av spillere, trenere og offisielle. Den tilbyr hundrevis av stipend, utmerkelser og æresbevisninger.
    <br />
    <br />
    Men hvordan ser collegelaget i baseball ut i dag? Hvordan er systemet organisert? Hva er de store ligaene og konferansene? Hvilke er de beste programmene og prestisjefylte lagene? I dette kapittelet vil vi gi deg en oversikt over collegelaget i baseball i dag.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Store Ligaer og Konferanser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Barry Bonds i aksjon for Arizona State i collegelaget i baseball."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Barry Bonds som spiller for Arizona State
        </figcaption>
      </figure>
      Som vi så i forrige kapittel, er flere organisasjoner involvert i collegelaget i baseball. Den viktigste av disse er NCAA, som representerer mer enn 1 200 universiteter og høyskoler. NCAA er delt inn i tre divisjoner: Divisjon I, Divisjon II og Divisjon III.
      <br />
      <br />
      Divisjon I er det høyeste nivået av collegelaget i baseball og består av omtrent 300 skoler, fordelt på 31 konferanser. Hver konferanse har sine egne regler, planer og mesterskap. De mest kjente konferansene inkluderer Atlantic Coast Conference (ACC), Big Ten Conference (B1G), Big 12 Conference (B12), Pac-12 Conference (P12) og Southeastern Conference (SEC).
      <br />
      <br />
      Divisjon II er det midterste nivået av collegelaget i baseball og består av omtrent 270 skoler, fordelt på 23 konferanser. Konferansene er vanligvis regionalt orienterte, som California Collegiate Athletic Association (CCAA), Gulf South Conference (GSC) og Northeast-10 Conference (NE10).
      <br />
      <br />
      Divisjon III er det laveste nivået av collegelaget i baseball og består av omtrent 380 skoler, fordelt på 43 konferanser. Konferansene er også vanligvis regionalt orienterte, som New England Small College Athletic Conference (NESCAC), Southern California Intercollegiate Athletic Conference (SCIAC) og Wisconsin Intercollegiate Athletic Conference (WIAC).
      <br />
      <br />
      I tillegg til NCAA er det andre organisasjoner involvert i collegelaget i baseball. En av dem er NAIA, som representerer omtrent 190 skoler. NAIA er delt inn i 21 konferanser, som American Midwest Conference (AMC), Golden State Athletic Conference (GSAC) og Heart of America Athletic Conference (HAAC).
      <br />
      <br />
      En annen organisasjon er NJCAA, som representerer omtrent 500 community colleges. NJCAA er delt inn i tre divisjoner: Divisjon I, Divisjon II og Divisjon III. Hver divisjon er videre delt inn i distrikter og regioner.
      <br />
      <br />
      Disse organisasjonene holder sine egne nasjonale mesterskap i collegelaget i baseball hvert år. NCAA Divisjon I-mesterskapet er den mest kjente og prestisjefylte begivenheten, ofte referert til som College World Series (CWS).
      <br />
      <br />
      CWS består av en dobbel eliminasjonsturnering med 64 lag valgt basert på deres prestasjoner i grunnspillet og konferansemesterskapene. Lagene er delt inn i 16 regionale grupper med fire lag i hver. Vinnerne av hver regionalgruppe avanserer til superregionalsrunden, der de konkurrerer mot et annet lag i en best av tre-serie. Vinnerne av hver superregionalsrunde går videre til CWS-finaleturneringen, som består av to grupper med fire lag i hver. Vinnerne av hver gruppe møtes i en best av tre-serie for å avgjøre nasjonal mester.
      <br />
      <br />
      CWS-finaleturneringen arrangeres årlig i juni i Omaha, Nebraska. Den regnes som en av de største og mest spennende begivenhetene innen collegidrett og tiltrekker seg tusenvis av fans, medier og speidere.
      <br />
      <br />
      NCAA Divisjon II- og Divisjon III-mesterskapene følger et lignende format, men med færre lag. NAIA og NJCAA-mesterskapene har også sine egne formater, som varierer avhengig av antallet deltakende lag.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Topp Programmer og Prestisjefylte Lag
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Collegelaget i baseball er en svært konkurransedyktig og prestisjefylt idrett med en rik tradisjon og talentpool. Det er mange programmer og lag som skiller seg ut gjennom sin suksess og rykte. Noen av disse programmene og lagene inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Texas Longhorns: Baseballaget til University of Texas i Austin, som konkurrerer i Big 12 Conference. Dette programmet har en imponerende historie med mange opptredener i College World Series og flere mesterskap. Noen kjente alumner inkluderer Roger Clemens, Burt Hooton og Huston Street.
        </li>
        <li>
          LSU Tigers: Baseballaget til Louisiana State University i Baton Rouge, aktiv i SEC. LSU er kjent for sin dominans i collegelaget i baseball og har vunnet flere mesterskap. Noen kjente alumner inkluderer Albert Belle, Aaron Hill og DJ LeMahieu.
        </li>
        <li>
          USC Trojans: Baseballaget til University of Southern California i Los Angeles, medlem av Pac-12 Conference. USC har en rik tradisjon med mange mesterskap og har produsert mange talentfulle spillere. Noen kjente alumner inkluderer Tom Seaver, Randy Johnson og Mark McGwire.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dette er bare noen få eksempler på topp programmer og prestisjefylte lag i collegelaget i baseball. Det er mange flere programmer og lag, hver med sin egen historie, kultur og identitet.
      <br />
      <br />
      Disse programmene og lagene bidrar til utviklingen av spillere på ulike måter. De gir dem høy kvalitet på coaching, trening, fasiliteter og konkurranse. De hjelper også spillerne med å oppnå sine akademiske, idrettslige og personlige mål.
    </MKTypography>
  </MKBox>
</MKBox>
     






                                  








<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 3: Fra College til Profesjonelt Nivå
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Collegelaget i baseball er ikke bare en idrett; det er også en vei til profesjonalitet. Mange collegespillere i baseball har ambisjonen om å spille profesjonelt, enten i MLB eller andre profesjonelle ligaer. Men hvordan ser den veien ut? Hvordan kan du gå fra college til profesjonelt nivå? I dette kapittelet vil vi gi deg en oversikt over veien til profesjonalitet i baseball.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Veien til Profesjonalitet
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Veien til profesjonalitet i baseball begynner vanligvis med MLB-draften. MLB-draften er en årlig hendelse der de 30 MLB-lagene tar tur til å velge spillere fra ulike kilder, inkludert collegelaget i baseball, high school baseball, internasjonal baseball og uavhengig baseball.
      <br />
      <br />
      MLB-draften består av 40 runder, der hvert lag får lov til å velge en spiller per runde. Rekkefølgen på valgene bestemmes av de omvendte stillingene fra den forrige MLB-sesongen, med noen unntak for kompensasjonsvalg og lotterivalg.
      <br />
      <br />
      MLB-draften er en betydelig mulighet for collegespillere i baseball til å starte sine profesjonelle karrierer. Omtrent 1 200 spillere blir valgt i MLB-draften hvert år, der omtrent 60% kommer fra collegelaget i baseball.
      <br />
      <br />
      Hvis du blir valgt i MLB-draften, har du to alternativer: du kan signere en kontrakt med laget som valgte deg, eller du kan velge å ikke signere og returnere til universitetet ditt eller en annen kilde.
      <br />
      <br />
      Hvis du velger å signere en kontrakt, vil du motta en signeringbonus basert på runden du ble valgt i. Bonuser varierer fra flere millioner dollar for første runde til flere tusen dollar for senere runder.
      <br />
      <br />
      Når du signerer en kontrakt, blir du tildelt en av de mindre ligalagene til laget som valgte deg. De mindre ligaene er de lavere nivåene av profesjonell baseball og fungerer som et utviklingssystem for MLB. Det er seks nivåer i de mindre ligaene: Rookie, Class A Short Season, Class A, Class A Advanced, Double-A og Triple-A.
      <br />
      <br />
      Mens du spiller i de mindre ligaene, må du forbedre ferdighetene dine og vise frem prestasjonene dine for å avansere til høyere nivåer. Du må også konkurrere med andre spillere som deler den samme drømmen: å nå de store ligaene.
      <br />
      <br />
      Å nå de store ligaene er det ultimate målet for enhver profesjonell baseballspiller. Det er det høyeste nivået av profesjonell baseball, der du kan konkurrere mot de beste spillerne i verden, foran de største fansene, og for de høyeste lønnene.
      <br />
      <br />
      Imidlertid er det ikke lett å nå de store ligaene. Bare omtrent 10% av spillerne som blir valgt i MLB-draften, når til slutt de store ligaene, og bare omtrent 1% av de som blir valgt, har lange og vellykkede karrierer i de store ligaene.
      <br />
      <br />
      Så veien til profesjonalitet i baseball er en lang og utfordrende reise som krever dedikasjon, hardt arbeid og litt flaks. Men det er også en vei som kan tilby mange belønninger, muligheter og drømmer.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 MLB-draften og Andre Profesjonelle Muligheter
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      MLB-draften er ikke den eneste måten å spille profesjonelt i baseball på. Det er også andre profesjonelle muligheter utenfor MLB som kan være attraktive for collegespillere i baseball.
      <br />
      <br />
      En av dem er den uavhengige ligaen. Den uavhengige ligaen er en profesjonell baseballiga som ikke er tilknyttet MLB eller de mindre ligaene. Den uavhengige ligaen består av ulike ligaer, slik som Atlantic League, American Association og Frontier League.
      <br />
      <br />
      Den uavhengige ligaen gir et alternativ for spillere som ikke blir valgt i MLB-draften eller ikke får kontraktstilbud fra et MLB-lag. Den uavhengige ligaen tilbyr også en sjanse for spillere som blir løslatt fra de mindre ligaene eller ønsker å forlenge karrierene sine.
      <br />
      <br />
      Den uavhengige ligaen har et lavere nivå av spill og lønn sammenlignet med MLB eller de mindre ligaene, men den tilbyr også et høyere nivå av frihet og glede. Den uavhengige ligaen er kjent for sin kreativitet, mangfold og eksperimenter. Den har også produsert noen spillere som til slutt nådde de store ligaene, slik som Jose Bautista, Rich Hill og Max Muncy.
      <br />
      <br />
      En annen profesjonell mulighet er den internasjonale ligaen. Den internasjonale ligaen er en fellesbetegnelse for profesjonelle baseballigaer som spilles utenfor USA. Den internasjonale ligaen består av ulike ligaer, slik som Nippon Professional Baseball (NPB) i Japan, Korean Baseball Organization (KBO) i Sør-Korea, Chinese Professional Baseball League (CPBL) i Taiwan, og Mexican Baseball League (LMB) i Mexico.
      <br />
      <br />
      Den internasjonale ligaen tilbyr en mulighet for spillere som ønsker å utvide horisontene sine og oppleve en annen kultur. Den internasjonale ligaen gir også en mulighet for spillere som søker et høyere nivå av spill og lønn enn den uavhengige ligaen, men kanskje ikke klarer å nå MLB eller de mindre ligaene.
      <br />
      <br />
      Dette er bare noen få eksempler på profesjonelle muligheter utenfor MLB. Det er mange flere muligheter, slik som vinterligaer, collegesommerligaer og amatør-ligaer.
      <br />
      <br />
      Disse mulighetene tilbyr alle forskjellige fordeler og ulemper for collegespillere i baseball. De gir forskjellige nivåer av konkurranse, belønning, utfordring og glede. De tilbyr ulike veier til profesjonalitet i baseball.
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  






<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 4: Internasjonale Innflytelser
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Collegelaget i baseball er ikke bare en amerikansk idrett; det er også en internasjonal idrett påvirket av spillere, trenere og tilhengere fra ulike land og kulturer. I dette kapittelet vil vi gi deg en oversikt over de internasjonale innflytelsene i collegelaget i baseball.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Utenlandske Spillere i Collegelaget i Baseball
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="USC-baseballag som feirer en seier."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          USC-spillere feirer en seier
        </figcaption>
      </figure>
      En av de mest betydningsfulle internasjonale innflytelsene i collegelaget i baseball er tilstedeværelsen av utenlandske spillere. Utenlandske spillere er spillere som ikke er født i USA eller ikke har amerikansk statsborgerskap. De kommer fra forskjellige land, slik som Canada, Mexico, Japan, Sør-Korea, Taiwan, Cuba, Venezuela, Den dominikanske republikk og Nederland.
      <br />
      <br />
      Utenlandske spillere spiller en viktig rolle i collegelaget i baseball. De bringer med seg sine egne ferdigheter, stiler og perspektiver til spillet. De beriker mangfoldet og kvaliteten på collegelaget i baseball. De øker også den internasjonale anerkjennelsen og appellen til collegelaget i baseball.
      <br />
      <br />
      Det er mange suksesshistorier om utenlandske spillere som har gjort det i den amerikanske collegelaget i baseball-verdenen. Et fremtredende eksempel er Nomar Garciaparra. Nomar Garciaparra var en meksikansk-amerikansk shortstop som spilte for Georgia Institute of Technology. Han ble valgt i første runde av MLB-draften i 1994 av Boston Red Sox. Han spilte 14 sesonger i MLB, der han ble utvalgt til All-Star seks ganger og vant AL battingtittelen to ganger. Det er mange flere eksempler på utenlandske spillere som har satt sitt preg på collegelaget i baseball.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikanere i Utlandet
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      En annen internasjonal innflytelse i collegelaget i baseball er tilstedeværelsen av amerikanere i utlandet. Amerikanere i utlandet er spillere som er født i USA eller har amerikansk statsborgerskap, men spiller eller har spilt i profesjonelle ligaer utenfor USA.
      <br />
      <br />
      Amerikanere i utlandet spiller også en betydelig rolle i collegelaget i baseball. De bringer med seg sine egne erfaringer, kunnskaper og nettverk til spillet. De lærer også fra andre kulturer og stiler i baseball. De fremmer internasjonal utveksling og samarbeid i collegelaget i baseball.
      <br />
      <br />
      Disse mulighetene tilbyr mange fordeler for amerikanske collegespillere i baseball. De gir et høyere nivå av konkurranse, eksponering og belønning. De hjelper også til med å utvide horisontene deres, øke tilpasningsevnen og utvide nettverkene deres.
      <br />
      <br />
      Internasjonal erfaring kan være gunstig for en spillers utvikling. Det kan hjelpe dem med å lære nye ferdigheter, teknikker og strategier. Det kan også hjelpe dem med å forbedre svakhetene sine, utnytte styrkene sine og maksimere potensialet sitt.
    </MKTypography>
  </MKBox>
</MKBox>






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 5: Idrettsstipender og Fremtiden til Utøvere
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrettsstipender gir mange muligheter for unge utøvere til å vise sitt talent og utvikle sine ferdigheter. En av de mest betydningsfulle mulighetene er å få et idrettsstipend for å studere og konkurrere på college-nivå. I dette kapittelet vil vi diskutere viktigheten av idrettsstipender og hvordan Sport Scholarships America kan hjelpe deg med å oppfylle drømmen din.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Viktigheten av Stipender
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et idrettsstipend er økonomisk støtte gitt av et universitet til en studentutøver for å kombinere studier og idrett. Det kan dekke kostnadene for skolepenger, bøker, bolig, måltider og andre utgifter. I tillegg kan det tilby fordeler som akademisk veiledning, medisinsk hjelp, treningsfasiliteter og utstyr.
      <br />
      <br />
      Å få et idrettsstipend er konkurransedyktig, med mange faktorer som spiller en rolle i tildelingsprosessen, inkludert akademiske prestasjoner, idrettslige resultater, personlighet, motivasjon, lederegenskaper og tilpasningsevne. Det er derfor viktig å forberede seg godt og skille seg ut fra konkurransen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Scholarships America Kan Hjelpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo for Sport Scholarships America, som viser sportsutstyr og en eksamenskappe, symboliserer fokuset på idrettsstipender for studentutøvere."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo for Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America er en organisasjon spesialisert i å veilede unge idrettstalenter til idrettsstipender. Med lidenskapelige og erfarne eksperter støtter Sport Scholarships America studentutøvere på hvert trinn i reisen, fra første orientering til endelig plassering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig inntaksintervju for å diskutere studentutøverens ønsker, muligheter og forventninger.</li>
        <li>En profesjonell evaluering av studentutøverens akademiske og idrettslige nivå.</li>
        <li>Et omfattende nettverk av kontakter med trenere, universiteter og høyskoler.</li>
        <li>Effektiv markedsføring av studentutøverens profil og videoer til potensielle trenere.</li>
        <li>Ekspertråd om de beste alternativene basert på studentutøverens preferanser, mål og budsjett.</li>
        <li>Forhandlinger om de beste stipendtilbudene på vegne av studentutøveren.</li>
        <li>Hjelp til å søke om nødvendige dokumenter, som visum, transkripsjon, diploma og opptaksprøver.</li>
        <li>Forberedelse til studentutøverens liv under stipendet, inkludert kultur, språk, regler og tips.</li>
        <li>Oppfølging og oppfølging under stipendperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vil du utforske stipendmuligheter og oppnå dine idrettslige og akademiske mål? <Link to="/no/Tjenester-Produkter">Se det komplette utvalget</Link> av tjenester som tilbys av Sport Scholarships America og planlegg en konsultasjon i dag.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;