/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


// @mui material components
import React, { useState } from 'react'; // Step 1: Import useState


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Logos/NCAA.png";
import post3 from "assets/images/Logos/NAIA.png";



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {

  const articleStructure = [
    { id: 'intro', title: 'NJCAA : La Porte d\'Entrée vers les Grandes Universités et la NCAA', isSubheader: false },
  
    { id: 'Chapitre 1', title: 'Origine et Croissance de la NJCAA', isSubheader: true },
    { id: '1.1', title: 'Les Trois Divisions de la NJCAA', isSubheader: true },
    { id: '1.2', title: 'Transition vers les Grandes Universités et la NCAA', isSubheader: true },
  
    { id: 'Chapitre 2', title: 'Le Rôle des Académiciens', isSubheader: true },
    { id: '2.1', title: 'Finances et Visibilité', isSubheader: true },
    { id: '2.2', title: 'Défis et Avenir', isSubheader: true },
    { id: '2.3', title: 'Conclusion', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
     <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contents
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Cacher</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Afficher</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="intro" mb={3}>

<MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>NJCAA : La Porte d'Entrée vers les Grandes Universités et la NCAA</MKTypography>
</MKBox>

<MKBox id="Chapitre 1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Origine et Croissance de la NJCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
La National Junior College Athletic Association (NJCAA) a été fondée en 1938 et est depuis devenue un acteur majeur du paysage sportif collégial américain. L'organisation offre des opportunités sportives aux collèges juniors, de plus petites institutions de deux ans qui offrent aux étudiants une voie vers l'enseignement supérieur et l'excellence athlétique.

<br />
<br />
Dans ses premières années, la NJCAA était limitée en ampleur, mais elle a grandi et s'est adaptée pour répondre aux besoins d'un corps étudiant diversifié et en évolution. L'accent est mis sur la fourniture d'un accès à la compétition sportive tout en favorisant le progrès académique.

</MKTypography>

<MKBox id="1.1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Les Trois Divisions de la NJCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
La NJCAA est divisée en trois divisions, chacune conçue pour répondre aux différents besoins et opportunités des étudiants-athlètes :

<br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division I :{' '}
</MKTypography>
Cette division comprend des écoles capables d'offrir des bourses sportives complètes, ce qui entraîne un niveau de compétition plus élevé. Les étudiants-athlètes ont ici la possibilité de développer leurs compétences et de concourir à un niveau intensif, souvent comme tremplin vers les divisions de la NCAA.

<br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division II :{' '}
</MKTypography>
Ici, nous trouvons un équilibre entre la compétition sportive et les responsabilités académiques, avec des écoles autorisées à offrir des bourses partielles. Cela garantit un équilibre sain et donne aux étudiants-athlètes la possibilité de briller dans leur sport tout en étant préparés au succès académique.   <br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division III :{' '}
</MKTypography>
Dans cette division, l'accent est mis sur la fourniture d'opportunités sportives compétitives sans bourses sportives. Les étudiants-athlètes ont la possibilité de participer à des sports tout en s'engageant pleinement dans la vie académique, ce qui entraîne une expérience collégiale complète.  </MKTypography>


<MKBox id="1.2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Transition vers les Grandes Universités et la NCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
La NJCAA joue un rôle clé en tant que tremplin pour les étudiants-athlètes souhaitant poursuivre leurs carrières académiques et sportives dans de plus grandes institutions de quatre ans, y compris celles de la NCAA. De nombreux étudiants-athlètes commencent leur parcours dans la NJCAA pour développer leurs compétences, améliorer leurs qualifications académiques et se faire remarquer par les recruteurs de programmes plus importants.


<br />
<br />
En prouvant leur valeur dans l'environnement compétitif de la NJCAA, les étudiants-athlètes peuvent attirer l'attention des universités de la NCAA et potentiellement obtenir des bourses pour poursuivre leurs études et leur carrière sportive. Ce processus est non seulement bénéfique pour les athlètes eux-mêmes, mais contribue également au renforcement des programmes sportifs à travers le pays.


</MKTypography>





                                  







<MKBox id="Chapitre 2" mb={3}>
  
  
  <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Le Rôle des Académiciens
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              La NJCAA s'engage fortement pour la réussite académique et le développement de ses étudiants-athlètes. Des normes académiques rigoureuses sont en place, et les écoles affiliées fournissent un encadrement essentiel pour garantir que les athlètes excellent à la fois en classe et dans leur sport. Cela illustre le rôle crucial d'une approche équilibrée au sein du paysage sportif collégial, bien que le niveau puisse être légèrement inférieur à celui de certaines autres institutions éducatives.
<br />
                <br />
                </MKTypography>

                <MKBox id="2.1" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Finances et Visibilité
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              Malgré des ressources financières plus limitées par rapport à la NCAA, la NJCAA joue un rôle crucial dans le soutien des programmes sportifs et la promotion des opportunités pour les étudiants-athlètes. L'organisation travaille également à accroître sa visibilité et sa portée dans le but d'atteindre et d'inspirer un plus grand nombre d'étudiants et d'athlètes.


<br />
                </MKTypography>

                <MKBox id="2.2" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Défis et Avenir
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              La NJCAA, comme d'autres organisations sportives, doit relever des défis, notamment celui de naviguer à travers les questions d'égalité dans le sport et de soutenir la santé mentale des étudiants-athlètes. Cependant, avec son engagement à promouvoir des opportunités et à soutenir les étudiants-athlètes, l'organisation est bien positionnée pour continuer à avoir un impact positif.



                </MKTypography>

                <MKBox id="2.3" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Conclusion
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              La National Junior College Athletic Association est un composant essentiel du paysage sportif collégial américain, offrant des opportunités uniques aux étudiants-athlètes pour concourir, apprendre et grandir. Avec son engagement envers l'excellence académique et athlétique, la NJCAA reste une force influente dans la formation de l'avenir des jeunes athlètes en Amérique.








<br />


                </MKTypography>


              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="NCAA"
                    description="NCAA : Le Cœur du Sport Universitaire en Amérique"
                    action={{
                      type: "internal",
                      route: "/fr/NCAA",
                      color: "info",
                      label: "Lire La Suite",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="NAIA"
                    description="La NAIA : Une Alternative dans le Sport Universitaire en Amérique"
                    action={{
                      type: "internal",
                      route: "/fr/NAIA",
                      color: "info",
                      label: "Lire La Suite",
                     }}
                    />
                  </Grid>
                </Grid>
  
              </Grid>
          </Grid>
        </Container>
      </MKBox>
    );
  }
  
  export default Beschrijving;