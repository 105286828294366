// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbarDansk";

import { Helmet } from 'react-helmet';

// VirtualReality page components
import MediaPlayer from "Languages/Dansk/pages/Content/Podcast/components/MediaPlayer";

// Routes
import routes from "routes_Dansk";import footerRoutes from "footer.routes_Dansk";

// Images
import bgImage from "assets/images/Achtergronden/Podcast_BG.png";
import DetailedFooter from "examples/Footers/DetailedFooter";

function VirtualReality() {
  return (
    <>
   <Helmet>
    <title>Podcast: Sport og Studier i Amerika - Sport Scholarships America</title>
    <meta name="description" content="Lyt til min podcast om mulighederne og oplevelserne ved at dyrke sport og studere i Amerika. Berig din viden med historier fra studerende atleter." />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta charset="UTF-8" />
    <meta name="robots" content="index, follow" />
    
    {/* Open Graph Tags */}
    
    
    
    
    

    {/* Favicon */}
    <link rel="icon" href="https://sportscholarshipsamerica.com/32x32Sportbeurs.png" />
</Helmet>


      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://calendly.com/sportbeurs-amerika/intake",
          label: "BOOK AFTALE",
          color: "info",
        }}
      
         
  
      />
      <MKBox
        component="header"
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.1),
              rgba(gradients.dark.state, 0.1)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={8} lg={6}>
              <MediaPlayer sx={{ width: "100%", height: "auto" }} />
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DetailedFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default VirtualReality;
