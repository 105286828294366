import { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MKTypography from "components/MKTypography";

import MKBox from "components/MKBox";

import SAT from "assets/images/Producten/SAT_kaft.png";
import ACT from "assets/images/Producten/ACT_kaft.png";
import TOEFL from "assets/images/Producten/Toefl_kaft.png";
import Hoodie from "assets/images/Producten/Hoodie_front.png";
import Shirt from "assets/images/Producten/Shirt_front.png";
import { Link } from 'react-scroll';



import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
function Prodotti() {
  const [tabAttivo, setTabAttivo] = useState(1);
  const [tipoTab, setTipoTab] = useState("mensile");

  const gestisciTipoTab = ({ currentTarget }, newValue) => {
    setTabAttivo(newValue);
    setTipoTab(currentTarget.id);
  };

  
  return (
    <section id="sezioneProdotti">
      <Container sx={{ pb: { xs: 12, lg: 8 }, pt: 0 }}>
      <Grid container item xs={12} justifyContent="center" md={8} mx="auto" textAlign="center">
  {/* Inserire ogni MKTypography in un proprio elemento Grid */}
  <Grid item xs={12}> {/* Larghezza piena per il titolo */}
    <MKTypography variant="h3" color="text">Prodotti</MKTypography>
  </Grid>
  <Grid item xs={12}> {/* Larghezza piena per il testo */}
    <MKTypography variant="body2" color="text">
    Dalle guide di studio al merchandising di Sport Scholarships America





</MKTypography>
  </Grid>
  <Grid
          container
          item
          xs={12}
          md={10}
          lg={8}
          justifyContent="center"
          textAlign="center"
          mx="auto"
          mt={6}
        >
          <AppBar position="static">
          <Tabs value={tabAttivo} onChange={gestisciTipoTab}>
  <Tab
    id="mensile"
    label={
      <Link 
        to="sezioneServizi" 
        spy={true}
        smooth={true}
        duration={500}
        offset={-70}
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <MKBox py={0.5} px={2} color="inherit">
          Servizi
        </MKBox>
      </Link>
    }
  />
  <Tab
    id="annuale"
    label={
      <Link 
        to="sezioneProdotti" 
        spy={true}
        smooth={true}
        duration={500}
        offset={-70}
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <MKBox py={0.5} px={2} color="inherit">
          Prodotti
        </MKBox>
      </Link>
    }
  />
</Tabs>
          </AppBar>
        </Grid>
          
          
        <Grid container spacing={3} mt={6} mb={6}>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="Guida allo Studio SAT"
      image={SAT}
      description="Preparati per il SAT"
      action={{ type: "internal", route: "/it/Servizi-Prodotti/SAT-guide", label: "Ordina", color: "primary" }}
    />
  </Grid>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="Guida allo Studio ACT"
      image={ACT}
      description="Preparati per l'ACT"
      action={{ type: "internal", route: "/it/Servizi-Prodotti/ACT-guide", label: "Ordina", color: "primary" }}
    />
  </Grid>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="Guida allo Studio TOEFL"
      image={TOEFL}
      description="Preparati per il TOEFL"
      action={{ type: "internal", route: "/it/Servizi-Prodotti/TOEFL-guide", label: "Ordina", color: "primary" }}
    />
  </Grid>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="Hoodie"
      image={Hoodie}
      description="L'Hoodie di Sport Scholarships America"
      action={{ type: "internal", route: "/it/Servizi-Prodotti/Hoodie", label: "Ordina", color: "primary" }}
    />
  </Grid>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="Maglietta"
      image={Shirt}
      description="La maglietta di Sport Scholarships America"
      action={{ type: "internal", route: "/it/Servizi-Prodotti/T-shirt", label: "Ordina", color: "primary" }}
    />
  </Grid>
</Grid>

          
        
        </Grid>
      </Container>
    </section>
  );
}

export default Prodotti;