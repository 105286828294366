/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Produktsida: https://www.creative-tim.com/product/material-kit-pro-react
* Upphovsrätt 2023 Creative Tim (https://www.creative-tim.com)

Kodad av www.creative-tim.com

=========================================================

* Ovanstående upphovsrättsnotis och denna tillståndsnotis ska inkluderas i alla kopior eller väsentliga delar av programvaran.
*/

// @mui materialkomponenter
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React-komponenter
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Bilder
import post1 from "assets/images/Jeugd.png";
import post3 from "assets/images/Deal.png";
import "../../../../H1asH3.css";


function Profil() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center"></MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox 
                  display="flex" 
                  flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} 
                  justifyContent="space-between" 
                  alignItems="center" 
                  mb={1}
              >
                  <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Del 2: Min Tid i Amerika</MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text">
  <br />
    Min tid i Amerika var som en andlöst resa, fylld med oförglömliga upplevelser och personlig tillväxt. 
    Tack vare fotbollen reste jag från kust till kust, tillsammans med ett gäng lagkamrater som snabbt blev några av mina bästa vänner. 
    Våra dagar började tidigt med intensiva träningspass på gymmet, något jag inte var van vid i Nederländerna. 
    Sedan snabbt till universitetet för lektioner, och tillbaka till träningen på eftermiddagen. Det var ett dagligt åtagande som utmanade mig både fysiskt och mentalt.
  <br /><br />
    Matcherna var höjdpunkter som tusentals fans samlades för att se, och varje gång kändes det som om vi spelade för något större än oss själva. 
    Adrenalinet att kliva ut på planen inför en så passionerad publik är obeskrivligt. Dessutom var resorna vi gjorde för matcherna oförglömliga. 
    På planet spenderade vi ofta timmar med att spela kort, och på hotellens lobbyer delade vi skrattretande ögonblick och spännande samtal. 
    Restauranger med läckra rätter blev våra tillfälliga matställen, och dessa kulinariska äventyr gav en extra dimension till våra resor. 
    Allt var noggrant organiserat, från korten på planet till restaurangerna där vi åt. 
    Dessa upplevelser gav mig en glimt av Amerikas storhet.
  <br /><br />
    Dessutom hade jag möjlighet att praktisera med ledande professionella klubbar, inklusive Seattle Sounders. 
    Att dela planen med spelare som USA:s genom tiderna främsta målskytt Clint Dempsey var inget mindre än overkligt, en höjdpunkt jag alltid kommer att värdera. 
    Men som i varje äventyr hade denna resa sina utmaningar. 
    Under mitt sista år drabbades jag av en allvarlig skada på hamstringen, vilket tillfälligt stoppade mina fotbollsambitioner. 
    Detta var en period av personlig reflektion och motstånd, ur vilket jag kom stärkt.
  <br /><br />
    Dessutom var min akademiska resa i Amerika lika viktig. Trots det intensiva fotbollsträningsschemat och omfattande resande, 
    fick jag perfekt vägledning för att fortsätta mina studier. Det var ingen lätt uppgift, eftersom jag ofta missade lektioner på grund av mitt engagemang i fotbollslaget. 
    Men jag var fast besluten att lyckas, och det gjorde jag med stolthet, jag tog examen med högsta heder, magna cum laude. 
    Denna akademiska framgång var ett vittnesbörd om min hängivenhet både till min sport och min utbildning.
  <br /><br />
    Under min äventyrsperiod i Amerika upplevde jag typiska amerikanska fester som verkade komma direkt från en film. 
    Dessa livliga sociala sammankomster, fyllda med musik, dans och högt skratt, erbjöd mig möjligheten att växa, inte bara som idrottare och student, 
    utan också som en social entusiast. Dessa fester lade till en extra dimension till mitt studentliv och berikade mina sociala upplevelser.
  <br /><br />
    Men Amerika betydde mer för mig än bara fotboll, matcher, sociala evenemang och akademiska prestationer. 
    Det var en möjlighet att utvidga mitt sociala nätverk och skapa livslånga vänskapsband. 
    Tack vare dessa vänskaper kunde jag upptäcka landet på nya sätt. 
    Från vårbreak i Mexiko, där vi njöt av solen, havet och stranden, till att fira Thanksgiving med vänners familjer, 
    som jag nu kan kalla min amerikanska familj. Deras varma välkomnande gjorde mig verkligen till en del av deras liv, 
    och dessa upplevelser har gjort min tid i Amerika ännu rikare.
  <br /><br />
  Den här perioden av mitt liv fördjupade min kärlek både för fotboll och utbildning och ledde mig till den väg jag nu följer för att hjälpa andra att uppnå sina drömmar i Amerika. 
  Håll dig uppdaterad, för i Del 3 kommer jag att dela med mig av hur min återkomst till Nederländerna och grundandet av mitt företag tog mitt liv i en ny riktning.
</MKTypography>
              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="Del 1: Uppväxt i Nederländerna"
                    action={{
                      type: "internal",
                      route: "/sv/Om-mig/Uppväxt",
                      color: "info",
                      label: "läs mer",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="Del 3: Tillbaka i Nederländerna"
                    action={{
                      type: "internal",
                      route: "/sv/Om-mig/Min-återkomst-till-Nederländerna",           
                      color: "info",
                      label: "läs mer",
                    }}
                  />
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profil;
