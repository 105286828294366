/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


// @mui material components
import React, { useState } from 'react'; // Step 1: Import useState


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Logos/NCAA.png";
import post3 from "assets/images/Logos/NAIA.png";



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {

  const articleStructure = [
    { id: 'intro', title: 'NJCAA: Springbrettet til Store Universiteter og NCAA', isSubheader: false },
  
    { id: 'Kapittel 1', title: 'Opprinnelse og Vekst av NJCAA', isSubheader: true },
    { id: '1.1', title: 'De Tre Divisjonene i NJCAA', isSubheader: true },
    { id: '1.2', title: 'Overgang til Store Universiteter og NCAA', isSubheader: true },
  
    { id: 'Kapittel 2', title: 'Akademikernes rolle', isSubheader: true },
    { id: '2.1', title: 'Økonomi og synlighet', isSubheader: true },
    { id: '2.2', title: 'Utfordringer og fremtiden', isSubheader: true },
    { id: '2.3', title: 'Konklusjon', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
     <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="intro" mb={3}>

<MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>NJCAA: Springbrettet til Store Universiteter og NCAA</MKTypography>
</MKBox>

<MKBox id="Kapittel 1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Opprinnelse og Vekst av NJCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
National Junior College Athletic Association (NJCAA) ble grunnlagt i 1938 og har siden vokst til å bli en prominent aktør innenfor det amerikanske college-idrettslandskapet. Organisasjonen tilbyr idrettsmuligheter for junior colleges, mindre toårige institusjoner som gir studenter en vei til høyere utdanning og idrettslig fremragende.

<br />
<br />
I sine tidlige år var NJCAA begrenset i omfang, men den har vokst og tilpasset seg for å møte behovene til en mangfoldig og stadig skiftende studentpopulasjon. Fokuset ligger på å tilby tilgang til idrettskonkurranse samtidig som akademisk fremgang fremmes.

</MKTypography>

<MKBox id="1.1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
De Tre Divisjonene i NJCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
NJCAA er delt inn i tre divisjoner, hver designet for å møte de ulike behovene og mulighetene til studentutøvere:

<br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Divisjon I:{' '}
</MKTypography>
Denne divisjonen inkluderer skoler som er i stand til å tilby fulle idrettsstipend, noe som resulterer i et høyere nivå av konkurranse. Studentutøvere her har muligheten til å utvikle sine ferdigheter og konkurrere på et intensivt nivå, ofte som et springbrett til NCAA-divisjoner.

<br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Divisjon II:{' '}
</MKTypography>
Her finner vi en balanse mellom idrettskonkurranse og akademiske ansvar, med skoler som har lov til å tilby delvise stipend. Dette sikrer en sunn balanse og gir studentutøvere muligheten til å utmerke seg i sin idrett samtidig som de forberedes på akademisk suksess.   <br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Divisjon III:{' '}
</MKTypography>
I denne divisjonen ligger fokuset på å tilby konkurransedyktige idrettsmuligheter uten idrettsstipend. Studentutøvere får muligheten til å delta i idrett mens de fullt ut engasjerer seg i det akademiske livet, noe som resulterer i en godt avrundet college-opplevelse.  </MKTypography>


<MKBox id="1.2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Overgang til Store Universiteter og NCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
NJCAA spiller en nøkkelrolle som et springbrett for studentutøvere som ønsker å fortsette sin akademiske og idrettslige karriere ved større fireårige institusjoner, inkludert de i NCAA. Mange studentutøvere begynner sin reise i NJCAA for å utvikle sine ferdigheter, forbedre akademiske kvalifikasjoner og markere seg for rekrutterere fra større programmer.


<br />
<br />
Ved å bevise seg i den konkurransedyktige atmosfæren i NJCAA, kan studentutøvere tiltrekke seg oppmerksomhet fra NCAA-skoler og potensielt tjene stipender for å fortsette sin utdanning og idrettskarriere. Denne prosessen er ikke bare gunstig for utøverne selv, men bidrar også til å styrke idrettsprogrammer over hele landet.


</MKTypography>





                                  








<MKBox id="Kapittel 2" mb={3}>
  
  
  <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Akademikernes rolle
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              NJCAA er sterkt engasjert i den akademiske suksessen og utviklingen av sine studentutøvere. Det er strenge akademiske standarder, og de tilknyttede skolene gir essensiell veiledning for å sikre at utøverne utmerker seg både i klasserommet og i sin idrett. Dette illustrerer den avgjørende rollen til en balansert tilnærming innenfor college-idrettslandskapet, selv om nivået kan være litt lavere enn ved noen andre utdanningsinstitusjoner.
<br />
                <br />
                </MKTypography>

                <MKBox id="2.1" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Økonomi og synlighet
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              Til tross for at de økonomiske ressursene er mer begrensede sammenlignet med NCAA, spiller NJCAA en avgjørende rolle i å støtte idrettsprogrammer og fremme muligheter for studentutøvere. Organisasjonen jobber også med å øke sin synlighet og rekkevidde med mål om å nå og inspirere flere studenter og utøvere.


<br />
                </MKTypography>

                <MKBox id="2.2" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Utfordringer og fremtiden
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              NJCAA, som andre idrettsorganisasjoner, står overfor utfordringer, inkludert å navigere gjennom spørsmål om likestilling i idrett og støtte til den mentale helsen til studentutøvere. Imidlertid er organisasjonen godt rustet til å fortsette å gjøre en positiv innvirkning med sitt engasjement for å fremme muligheter og støtte studentutøvere.



                </MKTypography>

                <MKBox id="2.3" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Konklusjon
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              National Junior College Athletic Association er en essensiell komponent i det amerikanske collegidrettslandskapet, og tilbyr unike muligheter for studentutøvere å konkurrere, lære og vokse. Med sitt fokus på akademisk og idrettslig ekspertise, forblir NJCAA en innflytelsesrik kraft i formingen av fremtiden for unge utøvere i Amerika.








<br />


                </MKTypography>


              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="NCAA"
                    description="NCAA: Hjertet av College-sport i Amerika"
                    action={{
                      type: "internal",
                      route: "/no/NCAA",
                      color: "info",
                      label: "Les Mer",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="NAIA"
                    description="NAIA: Et Alternativt Rute i Amerikansk College Sport"
                    action={{
                      type: "internal",
                      route: "/no/NAIA",
                      color: "info",
                      label: "Les Mer",
                     }}
                    />
                  </Grid>
                </Grid>
  
              </Grid>
          </Grid>
        </Container>
      </MKBox>
    );
  }
  
  export default Beschrijving;