/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Swimming1.jpg";
import Image2 from "assets/images/Sports_Images/Swimming2.jpeg";
import Image3 from "assets/images/Sports_Images/Swimming3.avif";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Natação na América: Como Conseguir uma Bolsa de Estudos Esportiva e Fazer Parte de uma História Esportiva Prestigiada', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capítulo 1: História e Desenvolvimento da Natação na América', isSubheader: false },
    { id: '1.1', title: '1.1 Primeiros Anos e o Papel das Universidades', isSubheader: true },
    { id: '1.2', title: '1.2 Crescimento e Profissionalização', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capítulo 2: Natação Universitária Hoje', isSubheader: false },
    { id: '2.1', title: '2.1 Principais Competições e Conferências', isSubheader: true },
    { id: '2.2', title: '2.2 Principais Programas e Equipes de Prestígio', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capítulo 3: Do Ensino Superior ao Nível Profissional', isSubheader: false },
    { id: '3.1', title: '3.1 O Caminho para o Profissionalismo', isSubheader: true },
    { id: '3.2', title: '3.2 Natação Profissional e os Jogos Olímpicos', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capítulo 4: Influências e Competições Internacionais', isSubheader: false },
    { id: '4.1', title: '4.1 Influências Estrangeiras', isSubheader: true },
    { id: '4.2', title: '4.2 Americanos no Exterior', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 A Importância das Bolsas de Estudo', isSubheader: true },
    { id: '5.2', title: '5.2 Como a Sport Scholarships America Pode Ajudar', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Conteúdo
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Esconder</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
      {/* Seção de Introdução */}
<MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Natação na América: Como Conseguir uma Bolsa de Estudos Esportiva e Fazer Parte de uma História Esportiva Prestigiada
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Estádio de natação lotado de espectadores durante uma competição universitária."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Um estádio durante uma partida
      </figcaption>
    </figure>
    A natação é um dos esportes mais populares e competitivos do mundo. Milhões de pessoas desfrutam da natação, tanto de forma recreativa quanto profissional. Mas você sabia que a natação na América atingiu um nível completamente diferente? A natação na América não é apenas um esporte; é uma cultura, uma tradição e um estilo de vida.
    <br />
    <br />
    Neste artigo, vamos contar tudo sobre o desenvolvimento da natação como esporte competitivo nos Estados Unidos, a importância da natação universitária para o avanço do esporte e como conseguir uma bolsa de estudos esportiva para estudar e nadar na América. Também vamos aprofundar o papel da Sportbeursamerika.nl, uma organização que ajuda jovens nadadores a realizarem seus sonhos.
    <br />
    <br />
    A natação é um esporte praticado há séculos, mas foi apenas no século XIX que começou a se desenvolver como uma atividade organizada e regulamentada. Os primeiros Jogos Olímpicos modernos em 1896 incluíram competições de natação como um dos principais eventos, e desde então, a natação tem sido uma parte fundamental da agenda olímpica.
    <br />
    <br />
    Mas por que a natação é tão popular e bem-sucedida na América? O que a torna tão especial e única? A resposta está no papel das universidades na promoção da natação como disciplina acadêmica e atlética. As universidades americanas não oferecem apenas educação de alta qualidade, mas também excelentes instalações, treinamento e orientação para estudantes-atletas que desejam se especializar em natação. Além disso, elas fornecem apoio financeiro na forma de bolsas de estudo esportivas, permitindo que nadadores talentosos realizem seu potencial sem se preocupar com os custos de sua educação.
    <br />
    <br />
    No entanto, as bolsas de estudo esportivas não são fáceis de obter. Elas são altamente competitivas e exigem um alto nível de dedicação, disciplina e desempenho. Portanto, é essencial estar bem preparado e procurar assistência profissional ao se candidatar a uma bolsa de estudos esportiva. É aí que entra a Sportbeursamerika.nl.
    <br />
    <br />
    A Sportbeursamerika.nl é uma organização especializada em orientar jovens nadadores para bolsas de estudo esportivas na América. Eles têm uma equipe de especialistas que pode ajudá-lo com todos os aspectos do processo, como escolher a universidade certa, preparar seu currículo e carta de motivação, se preparar para exames de admissão e entrevistas, arranjar seu visto e seguro e muito mais. Eles também têm conexões com centenas de universidades e treinadores na América, permitindo que eles o correspondam com as melhores opções para seu perfil e objetivos.
    <br />
    <br />
    Portanto, se você sonha em estudar e nadar na América, a Sportbeursamerika.nl é sua melhor aliada. Eles já ajudaram centenas de nadadores a realizarem seus sonhos, e podem ajudar você também. Mas antes de entrar em contato com eles, vamos primeiro dar uma olhada na história e no desenvolvimento da natação na América e por que é uma oportunidade tão fantástica para você.
  </MKTypography>
</MKBox>

{/* Capítulo 1: História e Desenvolvimento da Natação na América */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 1: História e Desenvolvimento da Natação na América
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    A natação tem uma história longa e rica na América. Tudo começou no final do século XIX, quando as universidades começaram a formar suas próprias equipes e competições de natação. Isso marcou o início de uma tradição que levaria ao surgimento da natação como um esporte nacional.
  </MKTypography>

  {/* Seção 1.1: Primeiros Anos e o Papel das Universidades */}
  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Primeiros Anos e o Papel das Universidades
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A primeira universidade a estabelecer uma equipe oficial de natação foi Harvard em 1878. Pouco depois, outras universidades de prestígio como Yale, Princeton, Columbia e Cornell seguiram o exemplo. Essas universidades organizaram partidas e torneios intercolegiais que chamaram a atenção do público e da mídia. Elas também introduziram novas regras e técnicas para a natação, incluindo o nado de peito, costas e borboleta.
      <br />
      <br />
      Em 1902, a Associação de Natação Intercolegial (ISA) foi fundada, tornando-se a primeira organização nacional para natação universitária. A ISA organizou campeonatos anuais para homens, onde as melhores equipes e nadadores individuais eram homenageados. Em 1916, a Associação Atlética Intercolegial Nacional (NCAA) foi estabelecida como a organização abrangente para todos os esportes universitários. A NCAA assumiu a responsabilidade pela organização dos campeonatos nacionais de natação universitária, que continuam até hoje.
      <br />
      <br />
      A natação universitária não se limitou aos homens. As mulheres também começaram a competir no nível universitário no início do século XX, embora enfrentassem mais restrições e discriminação. As primeiras equipes universitárias femininas de natação foram formadas em 1914 na Universidade de Chicago e em 1916 na Universidade de Wisconsin. Em 1922, a Associação de Natação Intercolegial Feminina (WISA) foi fundada, servindo como o equivalente da ISA para mulheres. A WISA também organizou campeonatos anuais para mulheres, que ganharam significativa popularidade.
      <br />
      <br />
      A natação universitária desempenhou um papel crucial na promoção da natação como um esporte profissional na América. Muitos nadadores universitários passaram para ligas profissionais e para os Jogos Olímpicos, onde representaram seu país e conquistaram medalhas. Alguns dos nomes mais conhecidos incluem Johnny Weissmuller, Mark Spitz, Matt Biondi, Janet Evans, Michael Phelps e Katie Ledecky.
    </MKTypography>
  </MKBox>

  {/* Seção 1.2: Crescimento e Profissionalização */}
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crescimento e Profissionalização
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Além da natação universitária, outras formas de natação profissional surgiram na América. Uma delas foi a natação master, que se concentrou em nadadores adultos que buscavam melhorar suas habilidades ou manter sua forma física. A natação master começou em 1970 com o estabelecimento da United States Masters Swimming (USMS), uma organização que oferece clubes locais, competições e programas para nadadores adultos. A natação master rapidamente se tornou um movimento global, com milhões de membros em mais de 100 países.
      <br />
      <br />
      Outra forma de natação profissional foi a natação em águas abertas, que ocorria em corpos naturais de água como lagos, rios e oceanos. A natação em águas abertas era um esporte desafiador e aventureiro que exigia resistência, habilidades de navegação e adaptabilidade. A natação em águas abertas ganhou mais reconhecimento em 2008 quando foi adicionada como um evento olímpico. Alguns dos nadadores em águas abertas mais famosos incluem Gertrude Ederle, Lynne Cox, Maarten van der Weijden e Ous Mellouli.
      <br />
      <br />
      A natação profissional atingiu seu auge durante os Jogos Olímpicos, onde nadadores americanos dominaram. Desde 1896, a América ganhou mais de 500 medalhas na natação, incluindo mais de 200 medalhas de ouro. Isso faz da América de longe o país mais bem-sucedido na natação olímpica.
      <br />
      <br />
      Mas como você também pode fazer parte dessa gloriosa história esportiva? Como você pode estudar e nadar na América com uma bolsa de estudos esportiva? Vamos contar para você no próximo capítulo.
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  






{/* Capítulo 2: Natação Universitária Hoje */}
<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 2: Natação Universitária Hoje
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Como você viu, a natação universitária tem uma longa e orgulhosa história na América. Mas e quanto ao presente? Como a natação universitária é organizada e estruturada hoje? Quais são as principais competições e conferências? Quais são os programas de destaque e equipes de prestígio? Vamos dar uma olhada mais de perto.
  </MKTypography>

  {/* Seção 2.1: Principais Competições e Conferências */}
  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Principais Competições e Conferências
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Katie Ledecky nadando pela Stanford em uma competição universitária."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Katie Ledecky em ação como nadadora pela Stanford
        </figcaption>
      </figure>
      A natação universitária na América é principalmente regulada pela NCAA, que possui três divisões: Divisão I, Divisão II e Divisão III. Cada divisão possui suas próprias regras, critérios e campeonatos. Geralmente, a Divisão I é a mais competitiva e prestigiada, apresentando os melhores nadadores, treinadores e instalações. As Divisões II e III são menos competitivas, mas ainda oferecem educação e esportes de alta qualidade.
      <br />
      <br />
      Dentro de cada divisão, existem várias conferências, que são grupos de universidades que estão geograficamente ou historicamente conectadas. Cada conferência organiza seus próprios campeonatos, com as principais equipes e nadadores individuais se classificando para os campeonatos nacionais da NCAA. Algumas das conferências mais conhecidas na natação universitária incluem:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          A Conferência Sudeste (SEC), composta por 14 universidades no sudeste dos Estados Unidos, como Alabama, Flórida, Geórgia, Kentucky, LSU, Tennessee e Texas A&M. A SEC é conhecida por sua dominância na natação, especialmente nos eventos masculinos. A SEC conquistou mais de 50 títulos nacionais na natação, com mais de 40 deles provenientes da equipe masculina da Flórida.
        </li>
        <li>
          A Conferência Pacífico-12 (Pac-12), composta por 12 universidades na costa oeste dos Estados Unidos, incluindo Arizona, Califórnia, Stanford, UCLA, USC e Washington. A Pac-12 é conhecida por sua excelência na natação, especialmente nos eventos femininos. A Pac-12 conquistou mais de 40 títulos nacionais na natação, com mais de 30 deles pertencentes à equipe feminina de Stanford.
        </li>
        <li>
          A Conferência Big Ten (Big Ten), composta por 14 universidades no Meio-Oeste e Nordeste dos Estados Unidos, como Indiana, Michigan, Minnesota, Ohio State, Penn State e Wisconsin. A Big Ten é conhecida por sua diversidade e equilíbrio na natação, com equipes fortes em ambos os eventos masculinos e femininos. A Big Ten conquistou mais de 30 títulos nacionais na natação, com mais de 20 deles conquistados pela equipe masculina de Michigan.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estes são apenas alguns exemplos das muitas conferências que existem na natação universitária. Cada conferência tem sua própria cultura, rivalidades e tradições, contribuindo para a emoção da natação universitária.
    </MKTypography>

  </MKBox>

  {/* Seção 2.2: Principais Programas e Equipes de Prestígio */}
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Principais Programas e Equipes de Prestígio
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Além das conferências, existem universidades individuais que se destacam devido aos seus programas de destaque e equipes de natação de prestígio. Essas universidades não apenas oferecem excelente educação aos seus alunos, mas também fornecem treinamento, orientação e instalações de alta qualidade para seus atletas. Elas também atraem muitos nadadores talentosos de origens tanto nacionais quanto internacionais que desejam estudar e nadar no mais alto nível.
      <br />
      <br />
      Algumas dessas principais universidades incluem:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Universidade de Stanford: Stanford é uma das universidades mais renomadas do mundo academicamente e atléticamente. Stanford possui um dos melhores programas de natação feminina na América, com mais de 10 títulos nacionais e mais de 100 medalhas olímpicas. Stanford também possui um forte programa de natação masculina, com mais de 5 títulos nacionais e mais de 50 medalhas olímpicas. Alguns ex-alunos famosos de Stanford incluem John Hencken, Pablo Morales, Jenny Thompson, Summer Sanders, Misty Hyman, Janet Evans e Katie Ledecky.
        </li>
        <li>
          Universidade da Califórnia: A Califórnia é outra universidade de ponta tanto academicamente quanto atléticamente. A Califórnia possui um dos melhores programas de natação masculina na América, com mais de 10 títulos nacionais e mais de 100 medalhas olímpicas. A Califórnia também possui um forte programa de natação feminina, com mais de 5 títulos nacionais e mais de 50 medalhas olímpicas. Alguns ex-alunos famosos da Califórnia incluem Matt Biondi, Anthony Ervin, Natalie Coughlin, Nathan Adrian, Dana Vollmer e Ryan Murphy.
        </li>
        <li>
          Universidade do Texas: O Texas é uma das maiores e mais influentes universidades da América, com uma forte reputação em acadêmicos e esportes. O Texas possui um dos melhores programas de natação masculina na América, com mais de 15 títulos nacionais e mais de 100 medalhas olímpicas. O Texas também possui um forte programa de natação feminina, com mais de 5 títulos nacionais e mais de 50 medalhas olímpicas. Alguns ex-alunos famosos do Texas incluem Josh Davis, Aaron Peirsol, Ian Crocker, Brendan Hansen, Ricky Berens e Joseph Schooling.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estas são apenas algumas das muitas principais universidades na natação universitária. Cada universidade tem sua própria história, filosofia e visão que contribuem para o desenvolvimento dos nadadores.
      <br />
      <br />
      Como você pode ver, a natação universitária hoje é um esporte altamente dinâmico e competitivo que oferece muitas oportunidades para estudantes-atletas que desejam se destacar academicamente e atléticamente. Mas como você pode acessar essas oportunidades? Como você pode obter uma bolsa de estudos esportiva para estudar e nadar na América? Nós vamos te contar no próximo capítulo.
    </MKTypography>
  </MKBox>
</MKBox>






                                  







{/* Capítulo 3: Do Ensino Superior ao Nível Profissional */}
<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 3: Do Ensino Superior ao Nível Profissional
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Estudar e nadar na América não é apenas uma ótima experiência, mas também uma oportunidade fantástica para iniciar sua carreira como nadador profissional. Muitos nadadores universitários fazem a transição para o nível profissional, onde representam seu país em competições internacionais e nos Jogos Olímpicos. Mas como você faz a transição do ensino superior para o nível profissional? Quais são os desafios e benefícios dessa transição? Vamos dar uma olhada mais de perto.
  </MKTypography>

  {/* Seção 3.1: O Caminho para o Profissionalismo */}
  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 O Caminho para o Profissionalismo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Para fazer a transição do ensino superior para o nível profissional, você primeiro precisa atender aos requisitos da NCAA. A NCAA possui regras estritas em relação ao status de amador dos estudantes-atletas, o que significa que você não pode ganhar ou aceitar dinheiro por sua performance atlética. Isso significa que você não pode entrar em contratos de patrocínio, acordos de premiação em dinheiro ou outras formas de compensação enquanto ainda estiver estudando e nadando no nível universitário.
      <br />
      <br />
      No entanto, uma vez que você tenha concluído seus estudos ou decida encerrar sua educação, você pode renunciar ao seu status de amador e se tornar um nadador profissional. Isso significa que você pode se juntar a uma organização de natação profissional, como a USA Swimming, que é o órgão governante nacional da natação na América. A USA Swimming oferece a oportunidade de participar de competições de natação profissional como a Pro Swim Series, o US Open, o US Nationals e as Olympic Trials. Essas competições são projetadas para melhorar suas habilidades, aumentar seu ranking e qualificá-lo para eventos internacionais.
      <br />
      <br />
      Como nadador profissional, você também pode se beneficiar de contratos de patrocínio, premiações em dinheiro e outras formas de compensação por sua performance atlética. Isso pode ajudar a melhorar sua situação financeira, apoiar seu estilo de vida e aumentar sua motivação. No entanto, se tornar um nadador profissional também vem com mais responsabilidades e desafios. Por exemplo, você precisará gerenciar seu próprio cronograma de treinamento, plano de nutrição, despesas de viagem, cuidados médicos e impostos. Você também terá que lidar com uma competição, pressão e expectativas aumentadas de si mesmo e dos outros.
      <br />
      <br />
      Portanto, é crucial estar bem preparado para a transição do ensino superior para o nível profissional. Você não só deve possuir excelentes habilidades de natação, mas também ter uma forte atitude mental, metas claras e bom apoio. Você também deve ser realista sobre suas chances e oportunidades e estar disposto a trabalhar duro e fazer sacrifícios.
    </MKTypography>
  </MKBox>

  {/* Seção 3.2: Natação Profissional e os Jogos Olímpicos */}
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Natação Profissional e os Jogos Olímpicos
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Um dos maiores sonhos de todo nadador profissional é participar dos Jogos Olímpicos, o evento esportivo mais prestigiado e significativo do mundo. As Olimpíadas não apenas oferecem a chance de representar seu país e ganhar medalhas, mas também de fazer história e se tornar uma lenda.
      <br />
      <br />
      No entanto, para se qualificar para os Jogos Olímpicos, você deve primeiro atender aos requisitos do Comitê Olímpico Internacional (COI) e da Federação Internacional de Natação (FINA). Essas organizações têm regras estritas em relação aos critérios de qualificação, número de participantes por país, controles antidoping e outros aspectos da natação olímpica.
      <br />
      <br />
      Um dos requisitos-chave para se qualificar para os Jogos Olímpicos é alcançar um padrão de tempo específico em uma competição reconhecida. Este padrão de tempo é determinado pela FINA com base na média das 16 melhores performances mundiais em cada disciplina nos últimos quatro anos. O padrão de tempo varia por gênero, grupo etário e estilo.
      <br />
      <br />
      Outro requisito essencial para se qualificar para os Jogos Olímpicos é ser selecionado pela sua organização nacional de natação, como a USA Swimming. Cada organização nacional de natação tem seu próprio processo de seleção, geralmente envolvendo uma série de testes ou campeonatos onde os melhores nadadores são escolhidos para representar o país. Cada organização nacional de natação também tem um número limitado de vagas por disciplina, dependendo do número de nadadores qualificados e das cotas estabelecidas pelo COI e pela FINA.
      <br />
      <br />
      Se você conseguir atender a esses requisitos e se qualificar para os Jogos Olímpicos, você é um dos poucos sortudos que têm a oportunidade de fazer parte do maior evento esportivo do mundo. Você poderá competir contra os melhores nadadores do mundo, desafiar a si mesmo e potencialmente ganhar uma medalha e eternizar seu nome nos livros de história.
      <br />
      <br />
      Como você pode ver, a transição do ensino superior para o nível profissional é uma jornada emocionante e recompensadora que oferece muitas oportunidades para sua carreira na natação. Mas como você pode começar essa jornada? Como você pode obter uma bolsa de estudos esportiva para estudar e nadar na América? Nós vamos te contar no próximo capítulo.
    </MKTypography>
  </MKBox>
</MKBox>





                                  






{/* Capítulo 4: Influências e Competições Internacionais */}
<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 4: Influências e Competições Internacionais
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    A natação na América não é apenas um esporte nacional, mas também um internacional. A natação na América é influenciada por e influencia outros países e culturas, cada um dos quais tem seus próprios estilos, técnicas e tradições na natação. A natação na América também é desafiada e enriquecida por outras competições internacionais, fornecendo oportunidades para aprender e competir contra nadadores de todo o mundo. Mas como essas influências e competições internacionais se originaram e se desenvolveram? Como elas impactam a natação universitária e profissional na América? Vamos dar uma olhada mais de perto.
  </MKTypography>

  {/* Seção 4.1: Influências Estrangeiras */}
  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Influências Estrangeiras
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Nadador em ação durante o campeonato nacional da NCAA."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          O campeonato nacional da NCAA
        </figcaption>
      </figure>
      A natação é um esporte universal praticado em quase todos os países do mundo. Cada país tem sua própria história, cultura e identidade na natação, refletida em como nadam, treinam e treinam. Alguns países tiveram uma influência significativa na natação na América, tanto positiva quanto negativamente.
      <br />
      <br />
      Uma das influências positivas é a Austrália, que é um dos maiores rivais e aliados da América na natação. A Austrália tem uma longa e orgulhosa tradição na natação, com mais de 100 medalhas olímpicas e mais de 20 recordes mundiais. A Austrália é conhecida por sua inovação e criatividade na natação, introduzindo novas técnicas, estratégias e métodos. A Austrália também produziu muitos nadadores talentosos e carismáticos, como Dawn Fraser, Shane Gould, Ian Thorpe, Grant Hackett, Libby Trickett e Cate Campbell.
      <br />
      <br />
      A Austrália influenciou a natação na América criando uma competição saudável e colaborativa que motiva ambos os países a melhorar e aprender um com o outro. Muitos nadadores australianos e americanos treinaram, estudaram ou trabalharam juntos, aprimorando suas habilidades, conhecimentos e amizades. Alguns exemplos incluem:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Em 1976, Mark Spitz, a estrela americana que ganhou sete medalhas de ouro em Munique, treinou ao lado de John Devitt, a lenda australiana que ganhou duas medalhas de ouro em Melbourne e Roma.
        </li>
        <li>
          Em 1988, Matt Biondi, a sensação americana que ganhou sete medalhas em Seul, se formou na Universidade da Califórnia com um diploma em economia política.
        </li>
        <li>
          Em 2016, Joseph Schooling, a surpresa de Cingapura que ganhou a primeira medalha de ouro para seu país nos 100m borboleta no Rio de Janeiro, se formou na Universidade do Texas com um diploma em economia.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estes são apenas alguns exemplos de como as influências estrangeiras impactaram a natação na América. Muitos outros países e culturas deixaram sua marca na natação, incluindo Japão, China, Rússia, França, Brasil e África do Sul. A natação na América não é apenas um esporte nacional, mas também um internacional que está em constante evolução e mudança através da interação com outros países e culturas.
    </MKTypography>
  </MKBox>

  {/* Seção 4.2: Americanos no Exterior */}
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Americanos no Exterior
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A natação na América não é apenas influenciada por e influencia outros países e culturas, mas também é desafiada e enriquecida por outras competições internacionais. A natação na América oferece a oportunidade de aprender e competir contra nadadores de todo o mundo, cada um com seus próprios estilos, técnicas e tradições na natação. A natação na América também oferece a chance de viajar e experimentar diferentes lugares, pessoas e culturas, ampliando seus horizontes e mudando sua perspectiva.
      <br />
      <br />
      Uma das principais competições internacionais em que os nadadores americanos participam são os Campeonatos Mundiais de Natação, realizados a cada dois anos pela FINA. Os Campeonatos Mundiais de Natação são um evento que abrange todas as disciplinas da natação, incluindo piscina longa, piscina curta, águas abertas, nado sincronizado e saltos ornamentais. Os Campeonatos Mundiais de Natação oferecem uma oportunidade de competir contra os melhores nadadores do mundo, melhorar seu ranking e aumentar sua qualificação para outros eventos.
      <br />
      <br />
      Outra competição internacional significativa em que os nadadores americanos participam são a Copa do Mundo de Natação, realizada anualmente pela FINA. A Copa do Mundo de Natação é uma série de eventos de piscina curta que ocorrem em várias cidades ao redor do mundo. A Copa do Mundo de Natação é uma oportunidade de competir contra diferentes oponentes, testar suas habilidades e ganhar dinheiro.
      <br />
      <br />
      Uma terceira competição internacional importante em que os nadadores americanos participam são os Jogos Pan-Americanos, realizados a cada quatro anos pela Organização Desportiva Pan-Americana (PASO). Os Jogos Pan-Americanos são um evento multiesportivo que reúne atletas da América do Norte, América do Sul e América Central. Os Jogos Pan-Americanos são uma chance de representar seu continente, mostrar solidariedade e ganhar medalhas.
      <br />
      <br />
      Estes são apenas alguns exemplos das muitas competições internacionais em que os nadadores americanos participam. Existem muitas mais competições internacionais na natação, como os Jogos da Commonwealth, Campeonatos Europeus, Jogos Asiáticos e Jogos Africanos.
    </MKTypography>
  </MKBox>
</MKBox>
                                






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    As bolsas de estudo esportivas oferecem inúmeras oportunidades para jovens atletas mostrarem seu talento e desenvolverem suas habilidades. Uma das oportunidades mais significativas é obter uma bolsa de estudos esportiva para estudar e competir no nível universitário. Neste capítulo, discutiremos a importância das bolsas de estudo esportivas e como a Sport Scholarships America pode ajudá-lo a realizar seu sonho.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 A Importância das Bolsas de Estudo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Uma bolsa de estudos esportiva é um suporte financeiro fornecido por uma universidade a um estudante-atleta para combinar estudos e esportes. Pode cobrir os custos de mensalidades, livros, moradia, refeições e outras despesas. Além disso, pode oferecer benefícios como tutoria acadêmica, cuidados médicos, instalações de treinamento e equipamentos.
      <br />
      <br />
      Obter uma bolsa de estudos esportiva é competitivo, com muitos fatores desempenhando um papel no processo de concessão, incluindo realizações acadêmicas, desempenho atlético, personalidade, motivação, liderança e adaptabilidade. Portanto, é importante se preparar bem e se destacar da concorrência.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Como a Sport Scholarships America Pode Ajudar
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo da Sport Scholarships America, mostrando equipamentos esportivos e um capelo de formatura, representando o foco em bolsas de estudo esportivas para estudantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo da Sport Scholarships America
        </figcaption>
      </figure>
      A Sport Scholarships America é uma organização especializada em orientar jovens talentos esportivos para bolsas de estudo esportivas. Com especialistas apaixonados e experientes, a Sport Scholarships America apoia estudantes-atletas em cada etapa da jornada, desde a orientação inicial até a colocação final. Seus serviços incluem:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Uma entrevista de admissão pessoal para discutir os desejos, possibilidades e expectativas do estudante-atleta.</li>
        <li>Uma avaliação profissional do nível acadêmico e atlético do estudante-atleta.</li>
        <li>Uma extensa rede de contatos com treinadores, universidades e faculdades.</li>
        <li>Promoção eficaz do perfil e vídeos do estudante-atleta para treinadores potenciais.</li>
        <li>Conselhos especializados sobre as melhores opções com base nas preferências, metas e orçamento do estudante-atleta.</li>
        <li>Negociação das melhores ofertas de bolsas de estudo em nome do estudante-atleta.</li>
        <li>Auxílio na solicitação de documentos necessários, como visto, histórico escolar, diploma e testes de admissão.</li>
        <li>Preparação para a vida do estudante-atleta durante a bolsa de estudos, incluindo cultura, idioma, regras e dicas.</li>
        <li>Acompanhamento e acompanhamento durante o período da bolsa de estudos.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Quer explorar oportunidades de bolsas de estudo e alcançar seus objetivos atléticos e acadêmicos? <Link to="/pt/Serviços-Produtos">Veja a gama completa</Link> de serviços oferecidos pela Sport Scholarships America e agende uma consulta hoje.
    </MKTypography>
  </MKBox>
</MKBox>



          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;