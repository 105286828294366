import { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import SimplePricingCard from "examples/Cards/PricingCards/SimplePricingCard";
import SimplePricingCardRibbon from "examples/Cards/PricingCards/SimplePricingCardRibbon";
import { PopupButton } from "react-calendly";
import { Link } from 'react-scroll';
import TextField from "@mui/material/TextField"; // Añade esta línea
import Box from '@mui/material/Box'; // Asegúrate de importar Box
import emailjs from 'emailjs-com';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Link as RouterLink } from 'react-router-dom';

function Servicios() {
  const [pestañaActiva, setPestañaActiva] = useState(0);
  const [tipoDePestaña, setTipoDePestaña] = useState("mensual");
  const [mostrarModal, setMostrarModal] = useState(false);
  const [mostrarModalEmail, setMostrarModalEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [estaDeAcuerdo, setEstaDeAcuerdo] = useState(false);

  const alternarModal = () => setMostrarModal(!mostrarModal);

  const manejarTipoDePestaña = ({ currentTarget }, newValue) => {
    setPestañaActiva(newValue);
    setTipoDePestaña(currentTarget.id);
  };

  const manejarEnvioEmail = async () => {
    if (email && estaDeAcuerdo) {
      const parametrosEmail = {
        from_email: email,
        // otros parámetros si son necesarios
      };
  
      emailjs.send('service_sj82src', 'template_2eyxhno', parametrosEmail, 'xOBnOFlYt964jvT3q')
        .then((response) => {
          console.log('Email enviado exitosamente', response.status, response.text);
          alert('Enlace solicitado, revise su bandeja de entrada.');
          setMostrarModalEmail(false);
          setEmail("");
          setEstaDeAcuerdo(false);
        }, (error) => {
          console.log('Fallo al enviar email', error);
          alert('Solicitud fallida, intente nuevamente.');
        });
    } else if (!estaDeAcuerdo) {
      alert('Por favor, acepte la declaración de privacidad.');
    } else {
      alert('Por favor, introduzca una dirección de correo electrónico');
    }
  };

  return (
    <section id="seccionServicios">
      <Container sx={{ pb: { xs: 4, lg: 4 }, pt: 12 }}>
          {/* Modal de Entrada de Email */}
          <Modal
        open={mostrarModalEmail}
        onClose={() => setMostrarModalEmail(false)}
        aria-labelledby="titulo-modal-modal"
        aria-describedby="descripcion-modal-modal"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: { xs: '90%', sm: '70%', md: '50%', lg: '40%' },
            maxWidth: '500px',
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <TextField
            label="Dirección de Correo Electrónico"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ mb: 2 }}
          />
          {/* Checkbox para aceptar la política de privacidad */}
          <FormControlLabel
  control={<Checkbox checked={estaDeAcuerdo} onChange={(e) => setEstaDeAcuerdo(e.target.checked)} />}
  label={
    <span>
       He leído y acepto la{' '}
      <RouterLink 
        to="/Privacy-Policy" 
        component="a" 
        target="_blank" 
        style={{ textDecoration: 'none' }}>
         declaración de privacidad
      </RouterLink>.
    </span>
  }
  sx={{ my: 2 }}
/>


          <MKButton variant="gradient" color="dark" onClick={manejarEnvioEmail}>
            Enviar
          </MKButton>
        </Box>
      </Modal>


      <Grid container item xs={12} justifyContent="center" md={8} mx="auto" textAlign="center">
      <Grid item xs={12}> {/* Ancho completo para el encabezado */}
    <MKTypography variant="h3" color="text">Servicios</MKTypography>
  </Grid>
  <Grid item xs={12}> {/* Ancho completo para el texto del cuerpo */}
    <MKTypography variant="body2" color="text">
    Todos los servicios ofrecidos por Sport Scholarships America
</MKTypography>
  </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={10}
          lg={8}
          justifyContent="center"
          textAlign="center"
          mx="auto"
          mt={6}
        >
          <AppBar position="static">
          <Tabs value={pestañaActiva} onChange={manejarTipoDePestaña}>
  <Tab
    id="mensual"
    label={
      <Link 
        to="seccionServicios" 
        spy={true}
        smooth={true}
        duration={500}
        offset={-70}
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <MKBox py={0.5} px={2} color="inherit">
          Servicios
        </MKBox>
      </Link>
    }
  />
  <Tab
    id="anual"
    label={
      <Link 
        to="seccionProductos" 
        spy={true}
        smooth={true}
        duration={500}
        offset={-70}
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <MKBox py={0.5} px={2} color="inherit">
          Productos
        </MKBox>
      </Link>
    }
  />
</Tabs>
          </AppBar>
        </Grid>
        <Grid container spacing={3} mt={6}>
        <Modal open={mostrarModal} onClose={alternarModal} sx={{ display: "grid", placeItems: "center" }}>
          <Slide direction="down" in={mostrarModal} timeout={500}>
            <MKBox
              position="relative"
              width="500px"
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              bgColor="white"
              shadow="xl"
            >
              <MKBox display="flex" alginItems="center" justifyContent="space-between" p={2}>
                <MKTypography variant="h5">
Elige una consulta inicial
</MKTypography>
                <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={alternarModal} />
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox p={2}>
                <MKTypography variant="body2" color="secondary" fontWeight="regular">
                Estimado cliente,
                <br />
                  <br />

                  Me complace que esté interesado en mi servicio estándar. Para asegurar que mi servicio se ajuste perfectamente a sus necesidades específicas, recomiendo encarecidamente reservar una consulta inicial sin compromiso.
                  <br />
                  <br />
                  Durante esta consulta, podemos discutir su situación en detalle y determinar juntos qué enfoque es el mejor para usted. Esto garantiza una experiencia más personalizada y me ayuda a optimizar el servicio para su situación única.


                </MKTypography>
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox display="flex" justifyContent="space-between" p={1.5}>
                <MKButton variant="gradient" color="dark" onClick={alternarModal}>
                  Cerrar
                </MKButton>
                
                <PopupButton
    url="https://calendly.com/kay-duit"
    rootElement={document.getElementById("root")}
    text="AGENDAR CITA"
    className="custom-calendly-button"
  />
              </MKBox>
            </MKBox>
          </Slide>
        </Modal>
        <Grid item xs={12} sm={6} lg={3}>
  <SimplePricingCard
    color="dark"
    title="Guía de Información"
    description="Guía completa de información en PDF"
    price={{ value: "Gratis", type: "" }}
    action={{
      type: "downloadPDF",
      label: "Descargar PDF",
      onClick: () => setMostrarModalEmail(true) // Función para mostrar el modal de entrada de email
    }}
    specifications={["Guía completa de información en PDF"]}
  />
</Grid>
<Grid item xs={12} sm={6} lg={3}>
  <SimplePricingCardRibbon
    color="dark"
    title="Conversación Inicial"
    description="Consulta online de 60 minutos"
    price={{ value: " € 14.99*", type: "" }}
    action={{
      type: "external", // Cambiar a external para Calendly
      route: "https://calendly.com/kay-duit", // Tu enlace de Calendly
      label: "AGENDAR CITA"
    }}
    specifications={[
      "Alinear objetivos y ambiciones",
      "Evaluación del rendimiento deportivo",
      "Plan de integración académica",
      "Estrategia de trayectoria individual",
      "Análisis de posibilidades de éxito en EE. UU."
    ]}
  />
</Grid>

          <Grid item xs={12} sm={6} lg={3}>
  <SimplePricingCard
    color="dark"
    title="Estándar"
    description="Tu camino a EE. UU."
    price={{ value: "A Consultar", type: "" }}
    action={{
      type: "modal",
      label: "Solicitar",
      onClick: alternarModal
    }}
    specifications={[
      "Plan personalizado",
      "Apoyo en la creación de un portafolio deportivo",
      "Apoyo en la preparación académica",
      "Promoción a universidades",
      "Negociación de becas deportivas",
      "Asesoramiento en selección de universidades y becas",
      "Apoyo en la solicitud de visa",
      "Apoyo en la matriculación",
      "Apoyo en asuntos prácticos",
    ]}
  />
</Grid>
<Grid item xs={12} sm={6} lg={3}>
  <SimplePricingCard
    color="dark"
    title="Premium"
    description="Tu camino a EE. UU."
    price={{ value: "A Consultar", type: "" }}
    action={{
      type: "modal",
      label: "Solicitar",
      onClick: alternarModal
    }}
    specifications={[
      "Plan personalizado",
      "Apoyo en la creación de un portafolio deportivo",
      "Apoyo en la preparación académica",
      "Promoción a universidades",
      "Negociación de becas deportivas",
      "Asesoramiento en selección de universidades y becas",
      "Apoyo en la solicitud de visa",
      "Apoyo en la matriculación",
      "Apoyo en asuntos prácticos",
      "Libros de estudio para SAT/ACT",
      "Llamada Zoom quincenal",
    ]}
  />
</Grid>
        </Grid>
      </Container>
      <Container>
    <Grid item xs={12} style={{ marginBottom: '80px' }}>
      <MKTypography variant="body2" color="text">
        {/* Tu texto va aquí */}
        *Anteriormente, ofrecía la conversación inicial de forma gratuita. Lamentablemente, esto llevó a muchas citas con personas no serias, lo cual era costoso en términos de tiempo. Para garantizar la calidad de mis servicios y asegurar que las conversaciones sean valiosas para ambas partes, ahora solicito una pequeña tarifa de €14.99. Esta cantidad ayuda a filtrar consultas serias mientras mantiene la conversación informal y centrada en sus necesidades específicas.

Aprecio su comprensión y espero tener una conversación productiva y perspicaz con usted.
      </MKTypography>
    </Grid>
  </Container>
    
    </section>
  );
}

export default Servicios;
