/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Hockey1.jpg";
import Image2 from "assets/images/Sports_Images/Hockey2.jpeg";
import Image3 from "assets/images/Sports_Images/Hockey3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");




  
  
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Felthockey-stipendier i Amerika: Hvordan Felthockey Kan Hjelpe Deg med Å Oppnå Dine Drømmer', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapittel 1: Historien og Utviklingen av Felthockey i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Tidlig Historie', isSubheader: true },
    { id: '1.2', title: '1.2 Utvikling og Profesjonalisering', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapittel 2: Felthockey på Universitetsnivå', isSubheader: false },
    { id: '2.1', title: '2.1 Konkurranser og Konferanser', isSubheader: true },
    { id: '2.2', title: '2.2 Innvirkning på Spillerutvikling', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapittel 3: Overgangen fra Universitet til Profesjonell', isSubheader: false },
    { id: '3.1', title: '3.1 Fra College til Proffene', isSubheader: true },
    { id: '3.2', title: '3.2 Profesjonelle Muligheter og Ligaer', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapittel 4: Internasjonale Innflytelser og Muligheter', isSubheader: false },
    { id: '4.1', title: '4.1 Globale Innflytelser', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanere på Verdensscenen', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapittel 5: Idrettsstipender og Fremtiden til Utøvere', isSubheader: false },
    { id: '5.1', title: '5.1 Viktigheten av Stipender', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Scholarships America Kan Hjelpe', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  const [,] = useState("");


  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Felthockey-stipendier i Amerika: Hvordan Felthockey Kan Hjelpe Deg med Å Oppnå Dine Drømmer
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Spiller i aksjon under en Colgate-college-felthockeykamp."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
      Spiller i aksjon under en kamp
      </figcaption>
    </figure>

    Felthockey er en av de raskest voksende sportene i Amerika. Flere og flere unge idrettsutøvere oppdager fordelene og gleden med denne dynamiske og utfordrende sporten. Men visste du at felthockey også kan hjelpe deg med å oppnå dine akademiske og profesjonelle mål?
    <br />
    <br />

    Hvis du har lidenskap for felthockey, har du en unik mulighet til å spille på collegenivå i Amerika. Dette betyr at du kan studere ved et av verdens beste universiteter samtidig som du utvikler dine idrettsferdigheter under profesjonell veiledning. Dessuten kan du kvalifisere deg for et idrettsstipend, som gir økonomisk støtte til utdanningen din.

    <br />
    <br />
    Men hvordan skaffer du deg et slikt stipend? Og hvordan forbereder du deg på å spille felthockey i Amerika? Det er her Sportbeursamerika.nl kan hjelpe deg. Sportbeursamerika.nl er et spesialisert byrå som hjelper unge talenter med å oppfylle drømmen om å studere og spille hockey i Amerika. Jeg tilbyr personlig veiledning, råd og støtte i å finne rett universitet, søke om stipend, håndtere alle praktiske spørsmål og integrere deg i amerikansk kultur.
    <br />
    <br />
    I denne artikkelen vil jeg fortelle deg mer om historien og utviklingen av felthockey i Amerika, mulighetene og fordelene ved å spille på universitetsnivå, overgangen fra college til profesjonelt spill, de internasjonale påvirkningene og mulighetene innen sporten, og selvfølgelig viktigheten av stipend og rollen til Sportbeursamerika.nl. Etter å ha lest denne artikkelen vil du ha en bedre forståelse av hva felthockey i Amerika innebærer, og hvordan du kan forberede deg på denne spennende utfordringen.
    <br />
    <br />
    Er du klar til å lære mer om felthockey i Amerika? Fortsett å lese!
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 1: Historien og Utviklingen av Felthockey i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Felthockey er en sport med en lang og rik historie. Men hvordan oppstod den og vokste den i Amerika? I dette kapittelet tar vi deg med på en reise gjennom tidene, fra den tidlige introduksjonen til dagens profesjonalisering av sporten.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidlig Historie
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Felthockey ble først introdusert i USA mot slutten av 1800-tallet av britiske immigranter og turister. Spillet fikk raskt popularitet blant kvinner, som så det som en måte å trene og sosialisere seg på. De første collegelagene ble dannet i 1901 ved Vassar College i New York og Bryn Mawr College i Pennsylvania. Disse lagene spilte primært mot hverandre eller lokale klubber.
      <br />
      <br />
      I 1922 ble United States Field Hockey Association (USFHA) etablert som den nasjonale organisasjonen for sporten. USFHA organiserte de første nasjonale mesterskapene for kvinner i 1925 og for menn i 1930. USFHA var også ansvarlig for å velge ut og trene nasjonale lag som konkurrerte i internasjonale turneringer som OL og verdensmesterskapet.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Utvikling og Profesjonalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      I andre halvdel av 1900-tallet fortsatte felthockey å vokse i popularitet og kvalitet i Amerika. Spillet spredte seg lenger over landet, spesielt i Nordøst, Midtvesten og Vesten. Antallet collegelag økte, og flere ligaer og konferanser ble dannet. Noen av de mest prestisjefylte og konkurransedyktige programmene inkluderer Princeton University, University of Maryland, University of North Carolina, Stanford University og Harvard University.
      <br />
      <br />
      Nivået på collegelaget i felthockey økte også takket være forbedrede fasiliteter, coaching og rekruttering. Mange universiteter tilbyr nå idrettsstipend til talentfulle felthockeyspillere, som lar dem studere uten økonomiske bekymringer. Videre fungerer collegelaget i felthockey som en grobunn for profesjonell felthockey, som også blir mer utviklet og profesjonalisert.
      <br />
      <br />
      Profesjonell felthockey har også påvirket det nasjonale laget, som har gjort det bedre på den internasjonale scenen. For eksempel har USAs kvinnelag vunnet bronse to ganger i OL (i 1984 og 1996) og sølv to ganger i Pan American Games (i 2011 og 2015). USAs herrelag har også deltatt i flere OL og verdensmesterskap, men har ennå ikke sikret noen medaljer.
      <br />
      <br />
      Som du kan se, har felthockey kommet langt i Amerika, fra en fritidsaktivitet til en profesjonell sport. Men hva innebærer det å spille felthockey på collegenivå? Det skal vi fortelle deg i neste kapittel.
    </MKTypography>
  </MKBox>
</MKBox>










                                              





                                  








<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 2: Felthockey på Universitetsnivå
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Å spille felthockey på universitetsnivå er en av de mest utfordrende og givende opplevelsene for en ung utøver. Det gir deg muligheten til å kombinere dine idrettslige talenter med dine akademiske ambisjoner og å være en del av et tett og mangfoldig fellesskap. I dette kapittelet vil vi fortelle deg mer om konkurransene og konferansene, innvirkningen på spillerutviklingen og fordelene ved å spille felthockey på universitetsnivå.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Konkurranser og Konferanser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Luftfotografi av Boston College felthockeystadion"
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
        Stadionet til Boston College
        </figcaption>
      </figure>
      Felthockey på universitetsnivå reguleres av National Collegiate Athletic Association (NCAA), som deler sporten inn i tre divisjoner: Divisjon I, Divisjon II og Divisjon III. Hver divisjon har sine egne regler, krav og stipend. Generelt sett er Divisjon I det høyeste konkurranse nivået, med de beste lagene og spillerne, og tilbyr de fleste stipendene. Divisjon II og III er mindre konkurransedyktige, men gir likevel gode muligheter for talentfulle spillere.
      <br />
      <br />
      Innenfor hver divisjon er det ulike konferanser, bestående av universiteter som er geografisk eller historisk knyttet sammen. Hver konferanse har sitt eget program, rangeringer og mesterskap. Noen av de mest kjente konferansene inkluderer Big Ten Conference, Atlantic Coast Conference, Ivy League, Patriot League og America East Conference.
      <br />
      <br />
      De beste lagene og spillerne fra hver konferanse kan kvalifisere seg for den nasjonale mesterskapet, som arrangeres årlig i november. Det nasjonale mesterskapet involverer en utslagsturnering, der vinneren kåres som nasjonal mester.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Innvirkning på Spillerutvikling
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Å spille felthockey på universitetsnivå har en betydelig innvirkning på spillerutviklingen. Det lar spillere forbedre sine ferdigheter under profesjonell coaching, trene med toppmoderne fasiliteter og utstyr, konkurrere mot andre topputøvere, og lære av sine lagkamerater og motstandere.
      <br />
      <br />
      Videre tilbyr å spille felthockey på universitetsnivå også spillere mange personlige fordeler. Det hjelper spillere med å øke selvtilliten, disiplinen, lederegenskapene, samarbeidsevnen, kommunikasjonen og problemløsningsevnen. Det hjelper også spillere med å forbedre tidsstyringen, studievanene, stresshåndteringen og generelle trivselen. Dessuten eksponerer det spillere for ulike kulturer, perspektiver og erfaringer, og utvider deres horisonter.
      <br />
      <br />
      Historien om nederlandske kvinnelige hockeyspillere som mottar stipend for å spille på universitetsnivå i Amerika tjener som et eksempel på hvordan denne erfaringen kan forbedre deres utvikling som spillere. Mange har spilt for prestisjefylte universitetslag i USA, der de ikke bare har vunnet mesterskap og individuelle titler, men også lært verdifulle leksjoner.
      <br />
      <br />
      Erfaringen i Amerika gir disse utøverne en unik mulighet til å fordype seg i en annen kultur, nyte nye sosiale miljøer og oppleve et annet utdanningssystem. Samspillet med trenere som utfordrer og støtter dem, bidrar til å forbedre ferdighetene deres og utvide deres taktiske forståelse av spillet.
      <br />
      <br />
      Ved å komme tilbake til Nederland har disse hockeyspillerne brukt sin opparbeidede kunnskap og erfaringer til å utmerke seg på profesjonelt nivå og i noen tilfeller påta seg ledelsesroller innen landslaget. Tiden deres i Amerika har ofte forberedt dem på presset og forventningene som følger med internasjonale konkurranser, og dannet grunnlaget for deres fremtidige suksess.
      <br />
      <br />
      Som du kan se, kan å spille felthockey på universitetsnivå tilby deg mange fordeler, både idrettslig og personlig. Men hvordan går du over fra college til profesjonell spill? Det vil vi forklare i neste kapittel.
    </MKTypography>
  </MKBox>
</MKBox>





                                  








<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 3: Overgangen fra Universitet til Profesjonell
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Felthockey på universitetsnivå er en flott måte å utfolde ditt idrettslige potensial på, men det trenger ikke å være slutten på karrieren din. Hvis du har ambisjonen og lidenskapen til å gå videre, kan du gå over til profesjonell spilling. I dette kapittelet vil vi fortelle deg mer om hvordan du går fra college til proffene og hvilke profesjonelle muligheter og ligaer som finnes for felthockeyspillere.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Fra College til Proffene
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Overgangen fra universitet til profesjonell spilling er ikke enkel, men den er heller ikke umulig. Det krever mye dedikasjon, hardt arbeid, talent og litt flaks. Det mest avgjørende aspektet er å presentere deg selv for de rette menneskene som kan hjelpe deg å oppnå drømmen din.
      <br />
      <br />
      En måte å vise seg frem på er ved å delta i prøvespill. Prøvespill er åpne treningssesjoner eller kamper der profesjonelle lag kan speide og vurdere spillere. Prøvespill avholdes vanligvis før eller etter sesongen og kan kunngjøres gjennom nettsider, sosiale medier eller via ord om munn. Prøvespill kan være en utmerket mulighet til å vise frem ferdighetene dine og få tilbakemelding fra trenere og managere. Prøvespill kan arrangeres av profesjonelle lag over hele verden.
      <br />
      <br />
      En annen måte å gjøre deg selv kjent på er ved å dra nytte av nettverket ditt. Nettverket ditt omfatter alle menneskene du kjenner eller møter som er knyttet til felthockey, inkludert trenere, lagkamerater, motstandere, venner, familie, lærere, alumner osv. Nettverket ditt kan hjelpe deg med å holde deg informert om de siste nyhetene, mulighetene og tipsene i felthockeyverdenen. Det kan også hjelpe deg med å koble deg med personer som har innflytelse eller forbindelser i det profesjonelle miljøet.
      <br />
      <br />
      I tillegg vender mange europeiske hockeyspillere tilbake til Europa for å prøve lykken der. Som du kan se, er det ulike måter å bli lagt merke til av profesjonelle lag på. Men hva slags lag finnes det, og hvilke typer ligaer eksisterer? Det vil vi forklare i neste avsnitt.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Profesjonelle Muligheter og Ligaer
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      For en felthockeyspiller som har gått gjennom det amerikanske collegesystemet, er dørene til en profesjonell karriere ofte vidåpne. Denne erfaringen er mer enn bare et springbrett; det er en omfattende forberedelse som har utviklet dem på mange områder - fysisk, taktisk og mentalt.
      <br />
      <br />
      En spiller som utmerker seg i collegehockey kan fange oppmerksomheten til speidere og profesjonelle lag, ikke bare på grunn av deres prestasjoner på banen, men også på grunn av disiplinen og allsidigheten de har dyrket ved å studere og spille samtidig. Profesjonelle hockeyligaer over hele verden, som de i Nederland, Belgia, Tyskland og Australia, er kjent for å rekruttere talentfulle spillere med solid bakgrunn i collegehockey.
      <br />
      <br />
      Den konkurransepregete naturen til collegehockey i Amerika sikrer også at spillerne er vant til et høyt nivå av trening og fysisk forberedelse, noe som er avgjørende for det profesjonelle spillet. Videre har de ofte erfaring med å spille i viktige kamper og mesterskap, noe som hjelper dem med å håndtere presset fra profesjonelle konkurranser.
      <br />
      <br />
      I tillegg gir den internasjonale erfaringen og eksponeringen for forskjellige spillestiler i Amerika disse spillerne et unikt perspektiv de kan ta med seg inn i sine profesjonelle karrierer. Det hjelper dem med å tilpasse seg raskt til nye lag og strategier og gir en bredere forståelse av spillet.
      <br />
      <br />
      Profesjonelle lag verdsetter kombinasjonen av akademisk disiplin og idrettslig dyktighet som en tidligere college-spiller bringer med seg. Disse spillerne er ofte godt forberedt på å møte utfordringene i profesjonell livet, inkludert balanseringen av sport med andre livsforpliktelser.
      <br />
      <br />
      Som du kan se, er det mange profesjonelle muligheter og ligaer for felthockeyspillere i Amerika. Men hva med resten av verden? Hvordan sammenligner felthockey i Amerika seg med internasjonal felthockey? Det vil vi forklare i neste kapittel.
    </MKTypography>
  </MKBox>
</MKBox>






                                  







<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 4: Internasjonale Innflytelser og Muligheter
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Felthockey i Amerika er ikke isolert, men er en del av en større og mer mangfoldig felthockeyverden. Felthockey er en global sport som spilles i mer enn 100 land over alle kontinenter. Felthockey i Amerika påvirkes av og påvirker internasjonal felthockey. I dette kapittelet vil vi fortelle deg mer om globale påvirkninger, amerikanere på verdensscenen og internasjonale muligheter for felthockeyspillere.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Globale Innflytelser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Felthockey i Amerika påvirkes av felthockey som spilles i andre land, spesielt i Europa og Asia. Disse landene har en lengre og rikere tradisjon innen felthockey og har ofte et høyere nivå av spill og organisering. Ved å observere og lære av disse landene kan felthockey i Amerika videreutvikle seg og forbedre seg.
      <br />
      <br />
      En måte som felthockey i Amerika påvirkes av internasjonal felthockey på er ved å adoptere ulike stiler og taktikker. For eksempel er nederlandsk felthockey kjent for sitt raske og angripende spill, med fokus på ballbesittelse, kombinasjoner og målforsøk. Tysk felthockey er kjent for sin fysiske og defensive stil, med høyt press, dueller og kontringer. Indisk felthockey er kjent for sitt tekniske og kreative spill, med fokus på driblinger, pasninger og triks.
      <br />
      <br />
      Ved å lære av disse stilene og taktikkene kan felthockey i Amerika diversifisere sitt eget spill og tilpasse seg ulike situasjoner, og dermed legge til mer variasjon, fleksibilitet og effektivitet.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikanere på Verdensscenen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Felthockey i Amerika påvirker også internasjonal felthockey ved å tilby spillere som deltar i de største og mest betydningsfulle turneringene globalt. Disse spillerne representerer ikke bare sitt land, men også sitt universitet, sitt lag og sin sport. Gjennom sine prestasjoner viser de hva felthockey i Amerika har å tilby, og inspirerer andre til å delta i denne sporten.
      <br />
      <br />
      Et godt eksempel på hvordan amerikanske spillere når verdensscenen er historien til Lauren Crandall. Lauren er en amerikansk felthockeyspiller som fikk et stipend for å spille for Wake Forest University. Hun spilte for laget i fire sesonger, og vant NCAA Division I-tittelen to ganger.
      <br />
      <br />
      Lauren var også en del av det amerikanske landslaget, og deltok i tre olympiske leker (i 2008, 2012 og 2016), to verdensmesterskap (i 2010 og 2014) og fire Panamerikanske leker (i 2007, 2011, 2015 og 2019). Hun tjenestegjorde også som lagkaptein fra 2013 til 2016.
      <br />
      <br />
      Lauren sier at tiden hennes i Amerika hjalp henne betydelig med å realisere drømmen om å spille på det høyeste nivået. Hun lærte mye av treneren sin, Jennifer Averill, som støttet og motiverte henne. Hun likte også kulturen, menneskene og utdannelsen i Amerika. Hun er stolt av å representere landet sitt og være en rollemodell for unge spillere.
      <br />
      <br />
      Som du kan se, kan felthockey i Amerika tilby deg mange internasjonale innflytelser og muligheter, både som spiller og som individ. Men hvordan kan du gripe disse mulighetene? Og hvordan kan du få et stipend for å studere og spille i Amerika? Det vil vi fortelle deg i neste kapittel.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 5: Idrettsstipender og Fremtiden til Utøvere
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrettsstipender gir mange muligheter for unge utøvere til å vise sitt talent og utvikle sine ferdigheter. En av de mest betydningsfulle mulighetene er å få et idrettsstipend for å studere og konkurrere på college-nivå. I dette kapittelet vil vi diskutere viktigheten av idrettsstipender og hvordan Sport Scholarships America kan hjelpe deg med å oppfylle drømmen din.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Viktigheten av Stipender
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et idrettsstipend er økonomisk støtte gitt av et universitet til en studentutøver for å kombinere studier og idrett. Det kan dekke kostnadene for skolepenger, bøker, bolig, måltider og andre utgifter. I tillegg kan det tilby fordeler som akademisk veiledning, medisinsk hjelp, treningsfasiliteter og utstyr.
      <br />
      <br />
      Å få et idrettsstipend er konkurransedyktig, med mange faktorer som spiller en rolle i tildelingsprosessen, inkludert akademiske prestasjoner, idrettslige resultater, personlighet, motivasjon, lederegenskaper og tilpasningsevne. Det er derfor viktig å forberede seg godt og skille seg ut fra konkurransen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Scholarships America Kan Hjelpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo for Sport Scholarships America, som viser sportsutstyr og en eksamenskappe, symboliserer fokuset på idrettsstipender for studentutøvere."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo for Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America er en organisasjon spesialisert i å veilede unge idrettstalenter til idrettsstipender. Med lidenskapelige og erfarne eksperter støtter Sport Scholarships America studentutøvere på hvert trinn i reisen, fra første orientering til endelig plassering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig inntaksintervju for å diskutere studentutøverens ønsker, muligheter og forventninger.</li>
        <li>En profesjonell evaluering av studentutøverens akademiske og idrettslige nivå.</li>
        <li>Et omfattende nettverk av kontakter med trenere, universiteter og høyskoler.</li>
        <li>Effektiv markedsføring av studentutøverens profil og videoer til potensielle trenere.</li>
        <li>Ekspertråd om de beste alternativene basert på studentutøverens preferanser, mål og budsjett.</li>
        <li>Forhandlinger om de beste stipendtilbudene på vegne av studentutøveren.</li>
        <li>Hjelp til å søke om nødvendige dokumenter, som visum, transkripsjon, diploma og opptaksprøver.</li>
        <li>Forberedelse til studentutøverens liv under stipendet, inkludert kultur, språk, regler og tips.</li>
        <li>Oppfølging og oppfølging under stipendperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vil du utforske stipendmuligheter og oppnå dine idrettslige og akademiske mål? <Link to="/no/Tjenester-Produkter">Se det komplette utvalget</Link> av tjenester som tilbys av Sport Scholarships America og planlegg en konsultasjon i dag.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;