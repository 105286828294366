/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Volleybal1.avif";
import Image2 from "assets/images/Sports_Images/volleybal2.webp";
import Image3 from "assets/images/Sports_Images/Volleybal3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images
import { Link } from 'react-router-dom';



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Volleyballstipend: Hvordan Kan Du Studere og Spille Volleyball i Amerika?', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapittel 1: Historien og Utviklingen av Volleyball i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Tidlige År og Rollen til Utdanningsinstitusjoner', isSubheader: true },
    { id: '1.2', title: '1.2 Vekst og Professionalisering', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapittel 2: Universitetsvolleyball i dag', isSubheader: false },
    { id: '2.1', title: '2.1 Store Ligaer og Konferanser', isSubheader: true },
    { id: '2.2', title: '2.2 Topp Programmer og Prestisjetunge Lag', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapittel 3: Fra College til Profesjonelle', isSubheader: false },
    { id: '3.1', title: '3.1 Veien til Profesjonalitet', isSubheader: true },
    { id: '3.2', title: '3.2 Profesjonelle Ligaer og Karrieremuligheter', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapittel 4: Internasjonale Påvirkninger og Konkurranser', isSubheader: false },
    { id: '4.1', title: '4.1 Utenlandske Påvirkninger', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanere på Verdensscenen', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapittel 5: Idrettsstipender og Fremtiden til Utøvere', isSubheader: false },
    { id: '5.1', title: '5.1 Viktigheten av Stipender', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Scholarships America Kan Hjelpe', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
           <Grid item xs={12} md={9}>

























           <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Volleyballstipend: Hvordan Kan Du Studere og Spille Volleyball i Amerika?
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Volleyball game in Nebraska, attended by more than 92,000 fans."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        92,000 tilskuere ser på en volleyballkamp i Nebraska
      </figcaption>
    </figure>
    Volleyball er en idrett som krever mye av dine fysiske og mentale evner. Du må reagere raskt, samarbeide effektivt, tenke taktisk og ha sterk utholdenhet. I tillegg er volleyball en idrett som gir mye glede og tilfredsstillelse, spesielt når du spiller på et høyt nivå. Men hvordan kan du, som et volleyballtalent, forfølge dine idrettslige ambisjoner uten å forsømme studiene? Svaret er: ved å velge et volleyballstipend i Amerika.
    <br />
    <br />
    Et volleyballstipend er en økonomisk støtte som tilbys av et amerikansk universitet som lar deg studere og spille volleyball der. Med et volleyballstipend kan du oppnå to mål samtidig: du kan tjene en anerkjent grad fra en prestisjetunge utdanningsinstitusjon samtidig som du videreutvikler volleyballferdighetene dine under profesjonell veiledning. Dessuten kan du nyte den unike opplevelsen av å bo og lære i en annen kultur, med nye venner og utfordringer.
    <br />
    <br />
    Men hvordan kan du kvalifisere deg for et volleyballstipend? Hva er kravene og forventningene? Og hvordan finner du det rette universitetet som passer for deg? Dette er alle spørsmål som Sportbeursamerika.nl kan hjelpe deg med. Sportbeursamerika.nl er spesialisten på området sportsstipend i Amerika. Med års erfaring og et omfattende nettverk kan jeg guide deg gjennom alle trinnene for å søke om et volleyballstipend, fra å opprette en online profil til å håndtere alle praktiske forhold.
    <br />
    <br />
    Hvis du ønsker å lære mer om hvordan Sportbeursamerika.nl kan hjelpe deg med å studere og spille volleyball i Amerika, fortsett å lese eller ta kontakt med meg for en uforpliktende innledende samtale. Jeg vil gjerne fortelle deg mer om mulighetene og fordelene med et volleyballstipend i Amerika.
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
 {/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 1: Historien og Utviklingen av Volleyball i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Volleyball er en idrett som har eksistert i over et århundre, men den har opplevd betydelig vekst i popularitet og professionalitet, spesielt de siste tiårene. I dette kapittelet vil vi dykke ned i historien og utviklingen av volleyball i Amerika, fra dens tidlige år til nåtiden.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidlige År og Rollen til Utdanningsinstitusjoner
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Volleyball ble oppfunnet i 1895 av William G. Morgan, en fysisk utdannelseslærer ved YMCA (Young Men's Christian Association) i Holyoke, Massachusetts. Han ønsket å skape et spill som var mindre fysisk krevende enn basketball, som hadde blitt oppfunnet et år tidligere av hans kollega James Naismith. Til å begynne med kalte han spillet "mintonette", men det ble snart kjent som "volleyball" fordi spillerne måtte volleye ballen over et nett.
      <br />
      <br />
      Spillet spredte seg raskt over hele USA og andre land, hovedsakelig gjennom YMCA og andre utdanningsinstitusjoner. I 1916 ble en av de første offisielle volleyballturneringene arrangert ved University of California i Berkeley. I 1922 ble United States Volleyball Association (USVBA) etablert, som senere ble til USA Volleyball (USAV). Denne organisasjonen ble ansvarlig for å regulere og fremme volleyball i Amerika.
      <br />
      <br />
      I 1928 ble den første nasjonale mesterskapet for menn organisert av USVBA, etterfulgt av det første nasjonale mesterskapet for kvinner i 1949. I 1955 ble det første verdensmesterskapet for menn arrangert i Frankrike, der Amerika kom på sjetteplass. I 1956 fant det første verdensmesterskapet for kvinner sted i Frankrike, der Amerika kom på fjerdeplass.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Vekst og Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      På 1960- og 1970-tallet begynte volleyball å vokse og professionalisere seg i Amerika. Dette skyldtes delvis innføringen av nye regler, som vedtaket av rally-point-systemet, der hvert poeng teller uansett hvem som server. Volleyball fikk også mer oppmerksomhet gjennom TV-sendinger av de olympiske leker, der volleyball har vært en offisiell idrett siden 1964.
      <br />
      <br />
      En av nøkkelpersonene i volleyballens historie i Amerika er Doug Beal, som oppnådde suksess både som spiller og trener. Han spilte for landslaget fra 1970 til 1976 og vant gull ved Pan American Games i 1975, blant andre prestasjoner. Han ble senere trener for landslaget fra 1977 til 1984 og ledet dem til deres første olympiske gull i 1984 i Los Angeles. Han var også involvert i grunnleggelsen av Major League Volleyball (MLV), en profesjonell kvinners liga som eksisterte fra 1987 til 1989.
      <br />
      <br />
      Et annet betydningsfullt arrangement i volleyballens historie i Amerika var etableringen av Association of Volleyball Professionals (AVP) i 1983, som fokuserte på beachvolleyball. Beachvolleyball er en variant av volleyball spilt på sand med lag på to spillere. AVP organiserte turneringer og konkurranser for både menn og kvinner, noe som bidro til å popularisere beachvolleyballen blant publikum. Noen kjente beachvolleyballspillere inkluderer Karch Kiraly, Sinjin Smith, Randy Stoklos, Misty May-Treanor og Kerri Walsh Jennings.
    </MKTypography>
  </MKBox>
</MKBox>






                                  




<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 2: Universitetsvolleyball i dag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Volleyball er en av de mest populære og konkurransedyktige idrettene på universitetsnivå i Amerika. Hvert år deltar tusenvis av lag i ulike konkurranser og konferanser, som kulminerer i nasjonale mesterskap. I dette kapittelet vil vi gi deg en oversikt over de største ligaene og konferansene, og diskutere noen av de beste programmene og prestisjetunge lagene i universitetsvolleyball.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Store Ligaer og Konferanser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Volleyball team celebrating a victory together."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Volleyballspillere feirer en seier
        </figcaption>
      </figure>
      Den primære organisasjonen som er ansvarlig for å organisere og regulere universitetsvolleyball i Amerika, er National Collegiate Athletic Association (NCAA). NCAA deler universitetene inn i tre divisjoner basert på størrelse, budsjett og idrettslig ytelse. Disse divisjonene er:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Divisjon I: Dette er den høyeste divisjonen, der de fleste store og kjente universitetene befinner seg. Lag i denne divisjonen får mest oppmerksomhet, mediedekning og stipender. Det er omtrent 330 universiteter i denne divisjonen, delt inn i 32 konferanser. En konferanse er en gruppe universiteter som er geografisk eller historisk knyttet til hverandre og konkurrerer mot hverandre. Noen eksempler på konferanser i Divisjon I inkluderer Big Ten, Pac-12, Southeastern Conference (SEC) og Atlantic Coast Conference (ACC).
        </li>
        <li>
          Divisjon II: Dette er mellomdivisjonen, der universitetene er litt mindre og mindre kjente enn i Divisjon I, men de opprettholder likevel et høyt nivå av volleyball. Lag i denne divisjonen får mindre oppmerksomhet, mediedekning og stipender enn i Divisjon I, men mer enn i Divisjon III. Det er omtrent 300 universiteter i denne divisjonen, delt inn i 23 konferanser. Noen eksempler på konferanser i Divisjon II inkluderer Great Lakes Valley Conference (GLVC), California Collegiate Athletic Association (CCAA) og Gulf South Conference (GSC).
        </li>
        <li>
          Divisjon III: Dette er den laveste divisjonen, der universitetene primært fokuserer på akademisk fremragende i stedet for idrettslig ytelse. Lag i denne divisjonen får ikke stipender for sine idrettsutøvere, men gir økonomisk hjelp basert på akademisk fortjeneste. Det er omtrent 450 universiteter i denne divisjonen, delt inn i 43 konferanser. Noen eksempler på konferanser i Divisjon III inkluderer New England Small College Athletic Conference (NESCAC), University Athletic Association (UAA) og Centennial Conference.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Hvert år konkurrerer lag innenfor sin divisjon og konferanse i kamper for å kvalifisere seg til nasjonale mesterskap, også kjent som NCAA-turneringen. Dette er en utslagsturnering der de beste lagene fra hver divisjon konkurrerer om tittelen som nasjonal mester. Turneringen avholdes om våren for menn og om høsten for kvinner.
    </MKTypography>

  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Topp Programmer og Prestisjetunge Lag
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Universitetsvolleyball i Amerika skryter av et høyt nivå av konkurranse, og mange lag utmerker seg i sine respektive divisjoner og konferanser. Imidlertid har noen lag tjent seg et spesielt omdømme og historie på grunn av deres konsistente prestasjoner, talentfulle spillere og trenere, og en stor tilhengerskare. Her er noen av de beste programmene og prestisjetunge lagene i universitetsvolleyball:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          UCLA Bruins: Representanter for University of California, Los Angeles, er UCLA Bruins kjent for sin sterke tradisjon både innen herre- og damervolleyball i NCAA Divisjon I. Historisk sett har de vunnet flere nasjonale mesterskap og har produsert noen av de mest respekterte spillerne i idretten. Dette laget er et fremtredende medlem av Pac-12 Conference.
        </li>
        <li>
          Stanford Cardinal: Damelaget i volleyball ved Stanford University, lokalisert i Stanford, California, har utmerket seg ved å vinne et imponerende antall nasjonale mesterskap i NCAA Divisjon I, mer enn noe annet lag i deres kategori. De har jevnlig produsert talentfulle spillere og konkurrerer i Pac-12 Conference.
        </li>
        <li>
          Penn State Nittany Lions: Damelaget i volleyball ved Pennsylvania State University i University Park, Pennsylvania, har et rykte for fremragende prestasjoner i NCAA Divisjon I, med mange nasjonale mesterskap på sin CV. De er kjent for sine påfølgende titler og har trent fremstående spillere gjennom årene. Dette laget er et betydelig medlem av Big Ten Conference.
        </li>
        <li>
          BYU Cougars: Brigham Young University i Provo, Utah, har et respektert herrelag i volleyball i NCAA Divisjon I, som har vunnet nasjonale mesterskap flere ganger. Laget har jevnlig utmerket seg i å utvikle toppvolleyballspillere og er en del av Mountain Pacific Sports Federation (MPSF).
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Disse programmene har vært fremtredende og innflytelsesrike i universitetsvolleyballen i USA gjennom årene, kjent for sine sterke tradisjoner, talentfulle spillere og innflytelsesrike trenere.
    </MKTypography>
  </MKBox>
</MKBox>
                            






                                  







<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 3: Fra College til Profesjonelle
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    For mange studentidrettsutøvere som spiller volleyball på universitetsnivå, er det en drøm å også spille profesjonell volleyball etter endt utdanning. Men hvor realistisk er denne drømmen, og hvilke steg må du ta for å oppnå den? I dette kapittelet vil vi diskutere veien til profesjonalitet, profesjonelle ligaer og karrieremuligheter for volleyballspillere.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Veien til Profesjonalitet
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Å spille volleyball på universitetsnivå er en flott måte å forbedre dine volleyballferdigheter, tjene din akademiske grad og utvikle deg personlig. Imidlertid er det også et svært konkurransedyktig og krevende miljø der du må investere mye tid og energi både i idretten og studiene dine. Derfor er det viktig å være realistisk med tanke på sjansene dine for å spille profesjonell volleyball etter din college-karriere.
      <br />
      <br />
      I motsetning til noen andre idretter som basketball eller fotball, er det ingen profesjonell volleyballiga i Amerika som rekrutterer direkte fra universitetene. Dette betyr at som nyutdannet spiller må du søke andre muligheter for å starte din profesjonelle karriere, vanligvis i utlandet. Det er ulike profesjonelle volleyballigaer i Europa, Asia, Sør-Amerika og andre regioner der du kan spille for klubber som tilbyr deg lønn og andre fordeler.
      <br />
      <br />
      Men hvordan kommer du i kontakt med disse klubbene, og hvordan sikrer du at du skiller deg ut blant de mange andre spillerne som også leter etter en kontrakt? Her er noen tips som kan hjelpe deg med å finne en profesjonell volleyballklubb:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Opprett en online profil på en plattform som Volleyball Agency eller Volleyball Market, der du kan dele videoer, statistikk, referanser og kontaktinformasjon med potensielle klubber og agenter.
        </li>
        <li>
          Kontakt dine tidligere trenere, lagkamerater eller andre kontakter som har erfaring med profesjonell volleyball eller har forbindelser med klubber eller agenter. De kan gi deg råd, gi anbefalinger eller introdusere deg for de rette personene.
        </li>
        <li>
          Delta på prøvespill, utstillinger eller leirer organisert av profesjonelle klubber eller organisasjoner. Dette er arrangementer der du kan vise frem ferdighetene dine til speidere, trenere eller managere som leter etter nye spillere. Sørg for at du er godt forberedt, både fysisk og mentalt, og etterlater et positivt inntrykk.
        </li>
        <li>
          Vær fleksibel og villig til å flytte til et annet land eller kontinent. Å spille profesjonell volleyball betyr ofte å bo i en annen kultur, med et annet språk, klima og skikker. Dette kan være en betydelig utfordring, men også en flott mulighet til å lære og vokse som person.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Profesjonelle Ligaer og Karrieremuligheter
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Hvis du klarer å sikre deg en kontrakt med en profesjonell volleyballklubb, kan du nyte fordelene ved å spille på det høyeste nivået i sporten. Du kan delta i spennende ligaer, konkurrere mot verdens beste spillere, reise til ulike land og byer, og tjene penger på din lidenskap. Men hva er de viktigste profesjonelle ligaene du kan delta i, og hva er de andre karrieremulighetene for volleyballspillere?
      <br />
      <br />
      Her er noen av de mest prestisjefylte og lukrative profesjonelle volleyballigaene i verden:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Den italienske Serie A: Dette er den øverste divisjonen av italiensk volleyball, bestående av 14 lag for menn og 12 lag for kvinner. Serie A er kjent som en av de sterkeste og mest konkurransedyktige ligaene i verden, med mange internasjonale stjerner og høye lønninger. Mesteren av Serie A kvalifiserer også for Champions League, den fremste klubbtuneringen i Europa.
        </li>
        <li>
          Den tyrkiske Süper Lig: Dette er den øverste divisjonen av tyrkisk volleyball, bestående av 12 lag for menn og 14 lag for kvinner. Süper Lig er også en av de ledende ligaene i verden, med betydelige investeringer, medieoppmerksomhet og talentfulle spillere. Mesteren av Süper Lig kvalifiserer også for Champions League.
        </li>
        <li>
          Den brasilianske Superliga: Dette er den øverste divisjonen av brasiliansk volleyball, bestående av 12 lag for menn og 12 lag for kvinner. Superligaen er den dominerende ligaen i Sør-Amerika, kjent for sin lidenskap, atmosfære og kvalitet. Mesteren av Superligaen kvalifiserer også for FIVB Volleyball Men's Club World Championship, den fremste klubbtuneringen i verden.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      I tillegg til å spille i profesjonelle ligaer, er det andre muligheter for volleyballspillere å fortsette eller utvide karrieren sin. Her er noen eksempler:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Trener eller trene andre spillere: Hvis du ønsker å avslutte eller redusere din spillekarriere, kan du også overføre din kunnskap og erfaring til andre spillere som trener eller trener. Du kan jobbe for en profesjonell klubb, et universitet, en skole eller en annen organisasjon som tilbyr volleyball. Du kan også etablere din egen volleyballskole eller akademi, der du kan trene og veilede unge talenter.
        </li>
        <li>
          Arbeide i media eller bransjen: Hvis du har interesse for media eller bransjer relatert til volleyball, kan du også bygge en karriere der. Du kan jobbe som kommentator, analytiker, journalist, skribent, fotograf eller videografer for ulike mediekanaler som dekker volleyball. Du kan også jobbe som representant, rådgiver, markedsfører eller designer for ulike selskaper som tilbyr volleyballprodukter eller tjenester.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  






<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 4: Internasjonale Påvirkninger og Konkurranser
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Volleyball er en global sport, spilt og fulgt av millioner av mennesker i ulike land og kontinenter. Universitetsvolleyball i Amerika er ikke isolert, men påvirkes og utfordres av det internasjonale volleyballmiljøet. I dette kapittelet vil vi diskutere de utenlandske påvirkningene og internasjonale konkurransene som spiller en rolle i universitetsvolleyball.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Utenlandske Påvirkninger
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Missouri Volleyball Stadium fylt med entusiastiske tilskuere."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          En kamp ved University of Missouri
        </figcaption>
      </figure>
      Universitetsvolleyball i Amerika skylder mye til de utenlandske påvirkningene som har beriket og forbedret sporten. En av de mest betydningsfulle påvirkningene er innvandringen av spillere, trenere og trenere fra andre land som brakte med seg sin kunnskap, erfaring og kultur til Amerika. Noen av disse innvandrerne har til og med blitt legender innenfor universitetsvolleyball, slik som:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Arie Selinger: Han er en israelsk trener som flyttet til Amerika i 1969 for å trene det nasjonale kvinnelandslaget. Han ledet laget til deres første olympiske medalje, en sølvmedalje i 1984. Han trente også flere universitetslag, inkludert Long Beach State, der han ble firedobbelt nasjonal mester.
        </li>
        <li>
          Andy Banachowski: Han er en polsk spiller som emigrerte til Amerika i 1951. Han spilte for UCLA, der han ble to ganger nasjonal mester. Han ble senere trener for UCLA kvinnelag, og vant seks nasjonale mesterskap og over 1000 kamper.
        </li>
        <li>
          Marv Dunphy: Han er en kanadisk spiller som kom til Amerika i 1972 for å spille for Pepperdine University. Han ble senere trener for Pepperdine mennelag, og vant fire nasjonale mesterskap. Han trente også det nasjonale herrelandslaget, som vant gull under OL i 1988.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      En annen viktig innflytelse er populariteten og nivået av internasjonal volleyball, som gir inspirasjon og motivasjon for universitetsvolleyball. Internasjonal volleyball inkluderer ulike regionale og globale turneringer der de beste lagene og spillerne i verden møtes. Noen av disse turneringene inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          De olympiske leker: Dette er den største og mest prestisjefylte sportsbegivenheten i verden, holdt hvert fjerde år. Volleyball har vært en offisiell olympisk sport siden 1964 for både menn og kvinner. USA har vunnet totalt 9 olympiske medaljer i volleyball, inkludert 3 gull, 3 sølv og 3 bronse.
        </li>
        <li>
          Verdensmesterskapet: Dette er den viktigste turneringen for nasjonale lag, holdt hvert fjerde år. Den arrangeres av International Volleyball Federation (FIVB), det globale styringsorganet for volleyball. Verdensmesterskapet har blitt avholdt siden 1949 for menn og siden 1952 for kvinner. USA har vunnet totalt 5 verdensmesterskapsmedaljer i volleyball, inkludert 2 gull, 1 sølv og 2 bronse.
        </li>
        <li>
          Verdenscupen: Dette er en annen turnering for nasjonale lag, holdt hvert fjerde år. Den arrangeres også av FIVB, men skiller seg fra verdensmesterskapet i antall deltakende lag (12 i stedet for 24) og kvalifikasjonssystemet (basert på kontinentale mesterskap). Verdenscupen har blitt avholdt siden 1965 for menn og siden 1973 for kvinner. USA har vunnet totalt 7 verdenscupmedaljer i volleyball, inkludert 2 gull, 3 sølv og 2 bronse.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikanere på Verdensscenen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Universitetsvolleyball i Amerika har ikke bare lært fra internasjonal volleyball, men har også bidratt betydelig til internasjonal volleyball. Mange amerikanske spillere som konkurrerte på universitetsnivå har også deltatt på internasjonale konkurranser, både for sine nasjonale lag og profesjonelle klubber. Her er noen eksempler på amerikanske spillere som har utmerket seg på verdensscenen:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Karch Kiraly: Han er en av de største volleyballspillerne gjennom tidene, som utmerket seg både i innendørs- og strandvolleyball. Han spilte for UCLA, der han ble tredobbelt nasjonal mester. Han spilte også for det nasjonale laget, og vant to olympiske gullmedaljer i innendørs volleyball (1984 og 1988) og en i strandvolleyball (1996). Han spilte også profesjonelt i Italia, der han vant fire italienske titler og to Champions League-titler.
        </li>
        <li>
          Logan Tom: Hun er en av de største kvinnelige volleyballspillerne gjennom tidene, som spilte både som angriper og libero. Hun spilte for Stanford, der hun ble nasjonal mester og fire ganger All-American. Hun spilte også for det nasjonale laget, deltok i fire olympiske leker og vant to sølvmedaljer (2008 og 2012). Hun spilte også profesjonelt i ulike land, inkludert Brasil, Tyrkia, Japan og Kina.
        </li>
        <li>
          Matt Anderson: Han er en av de beste mannlige volleyballspillerne i den nåværende generasjonen, i stand til å spille både som motsatt og utenfor angriper. Han spilte for Penn State, der han ble nasjonal mester i 2008 og ble utnevnt til All-American i 2007 og 2008. Han spiller også for det nasjonale laget, vant bronse under OL i 2016 og gull under verdensmesterskapet i 2015. Han spilte også profesjonelt i Russland, der han vant tre russiske titler og to Champions League-titler.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>





                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 5: Idrettsstipender og Fremtiden til Utøvere
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrettsstipender gir mange muligheter for unge utøvere til å vise sitt talent og utvikle sine ferdigheter. En av de mest betydningsfulle mulighetene er å få et idrettsstipend for å studere og konkurrere på college-nivå. I dette kapittelet vil vi diskutere viktigheten av idrettsstipender og hvordan Sport Scholarships America kan hjelpe deg med å oppfylle drømmen din.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Viktigheten av Stipender
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et idrettsstipend er økonomisk støtte gitt av et universitet til en studentutøver for å kombinere studier og idrett. Det kan dekke kostnadene for skolepenger, bøker, bolig, måltider og andre utgifter. I tillegg kan det tilby fordeler som akademisk veiledning, medisinsk hjelp, treningsfasiliteter og utstyr.
      <br />
      <br />
      Å få et idrettsstipend er konkurransedyktig, med mange faktorer som spiller en rolle i tildelingsprosessen, inkludert akademiske prestasjoner, idrettslige resultater, personlighet, motivasjon, lederegenskaper og tilpasningsevne. Det er derfor viktig å forberede seg godt og skille seg ut fra konkurransen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Scholarships America Kan Hjelpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo for Sport Scholarships America, som viser sportsutstyr og en eksamenskappe, symboliserer fokuset på idrettsstipender for studentutøvere."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo for Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America er en organisasjon spesialisert i å veilede unge idrettstalenter til idrettsstipender. Med lidenskapelige og erfarne eksperter støtter Sport Scholarships America studentutøvere på hvert trinn i reisen, fra første orientering til endelig plassering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig inntaksintervju for å diskutere studentutøverens ønsker, muligheter og forventninger.</li>
        <li>En profesjonell evaluering av studentutøverens akademiske og idrettslige nivå.</li>
        <li>Et omfattende nettverk av kontakter med trenere, universiteter og høyskoler.</li>
        <li>Effektiv markedsføring av studentutøverens profil og videoer til potensielle trenere.</li>
        <li>Ekspertråd om de beste alternativene basert på studentutøverens preferanser, mål og budsjett.</li>
        <li>Forhandlinger om de beste stipendtilbudene på vegne av studentutøveren.</li>
        <li>Hjelp til å søke om nødvendige dokumenter, som visum, transkripsjon, diploma og opptaksprøver.</li>
        <li>Forberedelse til studentutøverens liv under stipendet, inkludert kultur, språk, regler og tips.</li>
        <li>Oppfølging og oppfølging under stipendperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vil du utforske stipendmuligheter og oppnå dine idrettslige og akademiske mål? <Link to="/no/Tjenester-Produkter">Se det komplette utvalget</Link> av tjenester som tilbys av Sport Scholarships America og planlegg en konsultasjon i dag.
    </MKTypography>
  </MKBox>
</MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;