import Alabama from "assets/images/Staten_Images/Alabama.webp"
import Florida from "assets/images/Staten_Images/Florida.jpeg"
import Connecticut from "assets/images/Staten_Images/Connecticut.jpeg"
import Kentucky from "assets/images/Staten_Images/Kentucky.webp"
import Michigan from "assets/images/Staten_Images/Michigan.jpeg"
import North_Carolina from "assets/images/Staten_Images/North_Carolina.jpg"
import Texas from "assets/images/Staten_Images/Texas.jpeg"



const states = [
  {
    name: "Alabama",
    image: Alabama,
    imageDescription: "Alabama vs Auburn er en af de største rivaliseringer i college football.",
    description: [
      "College-sport er en integreret del af kulturen i Alabama, med et særligt fokus på amerikansk fodbold. University of Alabama (UA) i Tuscaloosa og Auburn University i Auburn dominerer sportslandskabet i staten.",
      "UAs fodboldhold, Crimson Tide, har en rig historie og har vundet talrige nationale mesterskaber. Hjemmekampe på det enorme Bryant-Denny Stadium kan tiltrække op til 100.000 fans. Fansenes lidenskab er enorm, og 'Roll Tide'-råbet er kendt overalt i Alabama.",
      "På den anden side har vi Auburn University med deres fodboldhold, Tigers. De, sammen med UA, udgør Iron Bowl, en af de mest intense rivaliseringer i collegessport. Dette årlige opgør vender hele staten på hovedet og garanterer spænding og spektakel.",
      "Ud over fodbold har Alabama stærke programmer inden for basketball, baseball og andre sportsgrene, understøttet af en stærk følelse af fællesskabsånd. I Alabama er sport mere end bare tidsfordriv; det er en væsentlig del af statens livsstil og identitet.",
    ],
  },

  {
    name: "Alaska",
    description: [
      "I modsætning til mange andre amerikanske stater spiller collegessport en mere beskeden rolle i Alaska, primært på grund af dens fjerntliggende beliggenhed og unikke klima. Her ligger vægten mere på vintersport og udendørsaktiviteter end på traditionelle amerikanske sportsgrene som fodbold og baseball.",
      "University of Alaska Anchorage (UAA) og University of Alaska Fairbanks (UAF) er de primære institutioner med aktive sportsprogrammer. UAA har et stærkt ski- og skøjteløbshold, mens UAF er kendt for sit ishockeyhold, Nanooks, som har en loyal lokal følgeskab.",
      "I Alaska handler collegessport mere om oplevelsen og fællesskabet end store begivenheder. Kampe giver mulighed for, at fællesskabet kan samles, heppe på atleterne og fejre statens unikke sportskultur.",
      "Trods den mindre skala har collegessport i Alaska sin egen charme og tilbyder et varmt fællesskab i statens kolde miljø.",
    ],
  },

  {
    name: "Arizona",
    description: [
      "Collegessport er enormt populær i Arizona, med en rig tradition inden for både amerikansk fodbold og basketball. Arizona State University (ASU) i Tempe og University of Arizona (UA) i Tucson er grundpillerne i statens sportslandskab.",
      "ASUs fodboldhold, Sun Devils, nyder en lidenskabelig følgeskab, mens deres basketballhold regelmæssigt stråler i NCAA-turneringer. UA, med deres Wildcats, er kendt for deres succesrige basketballprogram og intense rivalisering med ASU, der kulminerer i det årlige 'Duel in the Desert'-fodboldspil.",
      "Udover de større sportsgrene excellerer Arizona også inden for baseball, softball og golf, hvor universitetsholdene ofte er i nationalt rampelys. Fællesskabsstøtten er overvældende, og sportsbegivenheder betragtes som betydelige sociale begivenheder.",
      "Collegessport i Arizona udtrykker konkurrenceånden og fællesskabet, hvor intens rivalisering og fanlidenskab bidrager til en levende og livlig sportskultur.",
    ],
  },




  {
    name: "Arkansas",
    description: [
      "I Arkansas spiller collegessport en central rolle i lokalsamfundslivet, med et særligt fokus på amerikansk fodbold og basketball. University of Arkansas i Fayetteville er en kraftanstrengelse i staten, kendt for sine Razorbacks.",
      "Razorbacks' fodboldhold nyder regional og national anerkendelse, med en dedikeret fanbase, der passioneret følger hvert spil. Deres hjemmekampe på det imponerende Razorback Stadium er et spektakel, hvor 'Woo Pig Sooie'-råbet gentages af titusinder af fans.",
      "Basketball er også en betydelig del af sportskulturen i Arkansas, med Razorbacks regelmæssigt deltagende i NCAA-turneringen. Spændingen og energien i Bud Walton Arena under basketballsæsonen er mærkbar.",
      "Ud over de større sportsgrene har Razorbacks også stærke programmer inden for atletik, baseball og softball. Denne mangfoldighed inden for sport beriger sportskulturen i Arkansas, hvor collegessport er en kilde til stolthed, fællesskabsånd og underholdning.",
    ],
  },
  
  {
    name: "California",
    description: [
      "Collegessport i Californien er mangfoldig og meget konkurrencepræget, med talrige universiteter, der excellerer i forskellige sportsgrene. Fra amerikansk fodbold til svømning har staten det hele.",
      "Pac-12-konferencen, med prominente skoler som University of California, Los Angeles (UCLA) og University of Southern California (USC), er kendt for sit høje sportsniveau. UCLAs basketballhold har en rig historie, mens USC traditionelt set udmærker sig i fodbold.",
      "Ud over de velkendte hold er der også mindre skoler med imponerende sportsprogrammer, såsom Stanford University, der er kendt for deres fremragende præstationer inden for svømning og atletik. Denne mangfoldighed skaber et dynamisk og spændende sportsmiljø i staten.",
      "Californiens behagelige klima og omfattende sportsfaciliteter fremmer aktiv deltagelse og entusiasme for collegessport. Uanset om det er en fodboldkamp i et fyldt stadion eller en lokal svømmekonkurrence, er sport en integreret del af californisk livsstil.",
    ],
  },
  
  {
    name: "Colorado",
    description: [
      "Collegessport i Colorado nyder en livlig og entusiastisk følgeskab, delvist på grund af de imponerende naturskønne omgivelser og den aktive udendørs livsstil. Staten har en rig tradition inden for amerikansk fodbold, basketball og vintersport.",
      "University of Colorado Boulder (CU) og Colorado State University (CSU) er prominente aktører på collegessportsscenen, med det årlige 'Rocky Mountain Showdown'-fodboldspil som højdepunktet på deres rivalisering.",
      "CUs fodboldhold, Buffaloes, har en passioneret fanbase, mens deres skihold udmærker sig i nationale konkurrencer, hvilket gavner af Colorados fremragende vintersportsfaciliteter. CSU er også konkurrencedygtig inden for forskellige sportsgrene, med en stærk tilstedeværelse inden for amerikansk fodbold og basketball.",
      "Ud over de større universiteter har mindre collegers i Colorado også aktive sportsprogrammer, hvilket bidrager til den rige og mangfoldige sportskultur i staten. Collegessport i Colorado står for fællesskab, udholdenhed og fejring af sport i alle former.",
    ],
  },
  
  {
    name: "Connecticut",
    image: Connecticut,
    imageDescription: "UConns kvindebasketballhold er et af de mest dominerende collegessportshold gennem tiderne",
  
    description: [
      "Collegessport i Connecticut domineres af basketball, med et særligt fokus på de succesrige programmer på University of Connecticut (UConn). Staten har en rig tradition og en passioneret fanbase, der dybt investerer i collegessport.",
      "UConns kvindebasketballhold er nationalt anerkendt og har vundet flere NCAA-mesterskaber, hvilket gør dem til en magtfaktor inden for sporten. Herreholdet har også opnået imponerende præstationer, hvilket gør basketball til en central del af sportskulturen i Connecticut.",
      "Mens basketball regerer suverænt, er der også stærke programmer inden for andre sportsgrene som ishockey, fodbold og lacrosse, hvor forskellige universiteter er konkurrencedygtige på nationalt niveau.",
      "Collegessport i Connecticut udtrykker statens stolthed og fællesskabsånd, hvor sportsbegivenheder giver mulighed for, at fans kan samles, heppe på holdene og deltage i en rig sports tradition.",
    ],
  },
  
  
  
  

    {
      name: "Delaware",
      description: [
        "Selvom det er en af de mindste stater i USA, har Delaware en livlig collegessportsscene med stærk samfundsengagement. University of Delaware (UD) i Newark er midtpunktet for aktiviteten, hvor Blue Hens excellerer i forskellige sportsgrene.",
        "UDs fodboldhold har en rig historie og en dedikeret fanbase, med spændende hjemmekampe på Delaware Stadium. Basketball og lacrosse er også populære sportsgrene, hvor holdene regelmæssigt konkurrerer om regional og national anerkendelse.",
        "Trods sin lille størrelse har Delaware stærke programmer inden for atletik, svømning og andre sportsgrene, hvilket alt sammen bidrager til den dynamiske sportskultur i staten. Collegessport her er mere end bare tidsfordriv; det er en vigtig del af fællesskabsidentiteten, der fremmer en stærk forbindelse mellem universitetet og det bredere samfund.",
        "I Delaware bringer collegessport folk sammen, hvor den lille skala skaber et tæt og passioneret sportsfællesskab.",
      ],
    },
    
    {
      name: "Florida",
      image: Florida,
      imageDescription: "Ben Hill Griffin Stadium er hjemsted for Florida Gators",
    
      description: [
        "Florida er et hotspot for collegessport med nogle af de mest konkurrencedygtige og passionerede programmer i landet. Amerikansk fodbold og basketball dominerer sportskulturen, men der er også plads til en række andre sportsgrene.",
        "Hold som Florida Gators og Florida State Seminoles har national anerkendelse og en rig historie inden for både fodbold og basketball. Deres intense rivalisering tiltrækker årligt tusinder af fans, og kampene er højdepunkterne i sportsæsonen.",
        "Derudover er der også mindre universiteter og kollegier med stærke sportsprogrammer, der bidrager til den rige sportskultur i staten. Baseball, atletik og svømning er også populære, og de fremragende faciliteter og solrige klima gør Florida til et ideelt sted for collegespillere.",
        "I Florida er collegessport mere end bare konkurrence; det er en livsstil, en kilde til stolthed og en væsentlig del af fællesskabsidentiteten.",
      ],
    },
    
    {
      name: "Georgia",
      description: [
        "Collegessport i Georgia er kendetegnet ved intens lidenskab og dybt forankrede rivaliseringer, med amerikansk fodbold som den ubestridte konge. Staten huser nogle af de mest prestigefyldte programmer i landet, hvor fans passioneret oplever hvert spil.",
        "University of Georgia Bulldogs i Athens og Georgia Tech Yellow Jackets i Atlanta er de store spillere, med deres årlige 'Clean, Old-Fashioned Hate'-rivalisering, der griber staten. Fodboldkampe, især på UGAs massive Sanford Stadium, tiltrækker titusinder af fans.",
        "Ud over fodbold udmærker Georgia sig også i basketball, atletik og baseball, med talentfulde atleter og dedikerede tilhængere. Sportsprogrammerne på mindre skoler og kollegier bidrager til et rigt og mangfoldigt sportslandskab i hele staten.",
        "I Georgia er sport mere end konkurrence; det er en livsstil, en kilde til fællesskabsånd og en uundværlig del af regional identitet.",
      ],
    },
    
    {
      name: "Hawaii",
      description: [
        "Collegessport i Hawaii tilbyder en unik oplevelse inspireret af øens skønhed og stærke lokale kultur. På trods af at være geografisk isoleret, er sports hold og fans på Hawaii ekstraordinært passionerede og engagerede.",
        "University of Hawaii at Manoa spiller en central rolle i collegessportsscenen, med sit fodboldhold, Rainbow Warriors, som et betydeligt omdrejningspunkt. Deres hjemmekampe på Aloha Stadium skaber en levende og festlig atmosfære med en stærk ø-ånd.",
        "Ud over amerikansk fodbold er volleyball, surfing og kano racing også populære og afspejler den unikke sportskultur og natur i Hawaii. Rainbow Warriors er også konkurrencedygtige i basketball og baseball, med en dedikation, der deles af hele lokalsamfundet.",
        "På Hawaii repræsenterer collegessporten Aloha-ånden, hvor hold og fans omfavner økultur, fællesskab og stolthed og skaber en unik og levende sportsoplevelse sammen.",
      ],
    },
    
    {
      name: "Idaho",
      description: [
        "Collegessport i Idaho har en stærk tradition med særligt fokus på amerikansk fodbold og basketball. Følelsen af fællesskab og involvering i sports hold er bemærkelsesværdig, selvom staten ikke huser store universiteter.",
        "Boise State University, berømt for sin blå fodboldbane, spiller en prominent rolle i Idahos sportslandskab. Fodboldholdet har fået national opmærksomhed med sin succes og innovative spillestil, og hjemmekampene er et spektakel i sig selv.",
        "Ud over Boise State bidrager mindre skoler og kollegier også til den rige sportskultur, med programmer inden for sportsgrene som baseball, atletik og vintersport, der drager fordel af Idahos unikke naturlige miljø.",
        "I Idaho er collegessport mere end bare tidsfordriv; det er en livsstil og en væsentlig del af statens livsstil og identitet, hvor fællesskaber kommer sammen for at støtte deres hold.",
      ],
    },
    




    {
      name: "Illinois",
      description: [
        "Illinois har en rig historie og dyb kærlighed til collegessport med stærke programmer og dedikerede fans. Amerikansk fodbold og basketball tiltrækker betydelig opmærksomhed hele året, men der er også et bredt udvalg af andre sportsgrene, der trives.",
        "University of Illinois Fighting Illini og Northwestern University Wildcats fører an, med intense rivaliseringer og konkurrenceprægede ligaer, der holder staten levende. Memorial Stadium og Ryan Field er sportens templer, hvor hvert spil er en uforglemmelig oplevelse.",
        "Basketball er også fremtrædende, med staten regelmæssigt producerer talenter, der udmærker sig både nationalt og internationalt. Illinois Fighting Illini har en rig basketballtradition og udgør en konstant trussel i NCAA-turneringer.",
        "Fra små kollegier til store universiteter fungerer sport i Illinois som en forbindelse, en kilde til stolthed og en væsentlig del af kulturen og identiteten i staten.",
      ],
    },
    
    {
      name: "Indiana",
      description: [
        "Indiana er kendt for sin dybt forankrede kærlighed til basketball, og dette er tydeligt i statens collegessportkultur. Rivaliseringen, lidenskaben og dedikationen fra både spillere og fans er uovertruffen.",
        "Programmer som Indiana Hoosiers og Purdue Boilermakers er nationalt anerkendte, især inden for basketball. Der hænger en elektrisk atmosfære i arenaer som Assembly Hall og Mackey Arena året rundt, hvor historie og tradition mødes.",
        "Mens basketball tager føringen, trives andre sportsgrene også i Indiana. Amerikansk fodbold, baseball og atletik har alle stærke programmer og nyder bred støtte fra lokalsamfundet.",
        "Sport i Indiana er mere end bare en kamp; det er en livsstil, en katalysator for fællesskabsånd og en stolt tradition, der går i arv gennem generationer. Her, midt i Midwest, er collegessport en integreret del af statens identitet.",
      ],
    },
    
    {
      name: "Iowa",
      description: [
        "Collegessport i Iowa er en kilde til stolthed og fællesskabsånd, med en rig tradition inden for både amerikansk fodbold og brydning. Rivaliseringen mellem Iowa Hawkeyes og Iowa State Cyclones er central og giver uforglemmelige øjeblikke hver sæson.",
        "Kinnick Stadium i Iowa City og Jack Trice Stadium i Ames er stederne for intense fodboldkampe hver efterår, der tiltrækker titusinder af fans. Fansenes lidenskab og dedikation er karakteristisk for statens sportskultur.",
        "Brydning er en anden sport, hvor Iowa excellerer, med Hawkeyes regelmæssigt vinder nationale mesterskaber. Denne sport har en dybt forankret tradition i Iowa og nyder loyal støtte.",
        "Fra små kollegier til store universiteter er sport i Iowa mere end konkurrence; det er en livsstil, en kilde til fællesskabsånd og en vigtig del af statens identitet.",
      ],
    },
    
    {
      name: "Kansas",
      description: [
        "Collegessport i Kansas er præget af tradition og fællesskabsånd, med basketball som det ubestridte højdepunkt. Staten huser nogle af de mest ikoniske programmer i landet, og rivaliseringen og lidenskaben er intens.",
        "Kansas Jayhawks og Kansas State Wildcats fører an, med deres årlige 'Sunflower Showdown' som et af højdepunkterne i sportsåret. Allen Fieldhouse, hjemstedet for Jayhawks, er kendt som en af de mest intimidende arenaer inden for collegbasketball.",
        "Mens basketball udgør hjertet af sportskulturen i Kansas, spiller amerikansk fodbold, baseball og atletik også en betydelig rolle. Fansene er uden sidestykke i deres dedikation og bidrager til den livlige atmosfære under kampe.",
        "Sport i Kansas er mere end bare konkurrence; det er en kilde til stolthed, en katalysator for fællesskab og en væsentlig del af statens identitet.",
      ],
    },
    
    {
      name: "Kentucky",
      image: Kentucky,
      imageDescription: "Karl-Anthony Towns og Devin Booker spillede sammen for Kentucky og blev senere store NBA-stjerner",
    
      description: [
        "Collegessport i Kentucky er synonymt med basketball og har en rig tradition og en passioneret fanbase. Rivaliseringen mellem Kentucky Wildcats og Louisville Cardinals er en af de mest intense og sete i landet.",
        "Rupp Arena, hjemstedet for Wildcats, er et sandt basketballtempel, hvor programmets historie og stolthed er håndgribelige. Wildcats har flere nationale mesterskaber på deres CV og er en fast bestanddel inden for collegbasketball.",
        "Ud over basketball har Kentucky også stærke programmer inden for amerikansk fodbold, hestesport og skydning, som alle beriger statens sportskultur. Kentucky Derby, et legendarisk hestevæddeløb, understreger den rige tradition inden for hestesport.",
        "I Kentucky er sport mere end en fritidsbeskæftigelse; det er en livsstil, en kilde til fællesskab og stolthed og en afgørende del af statens identitet.",
      ],
    },
    
    {
      name: "Louisiana",
      description: [
        "I Louisiana er collegessport en integreret del af kulturen med en dyb lidenskab for amerikansk fodbold og baseball. Louisiana State University (LSU) Tigers er en magtfaktor, der tiltrækker massive menneskemængder og skaber en uovertruffen atmosfære.",
        "Tiger Stadium, også kendt som 'Death Valley,' er berygtet for sine brølende fans og intimiderende atmosfære, hvilket gør det til en af de mest frygtede hjemmebaner inden for collegfodbold. 'Saturday Night in Death Valley' er mere end bare en kamp; det er en begivenhed, en tradition, en del af Louisianas sjæl.",
        "Ud over LSU bidrager mindre universiteter og kollegier også til det rige sportslandskab, med stærke programmer inden for forskellige sportsgrene. Baseball nyder også stor popularitet, med LSU regelmæssigt kæmper om det nationale mesterskab.",
        "Sport i Louisiana er en fejring af fællesskab, en kilde til stolthed og en bånd, der bringer folk sammen, uanset deres baggrund. Det er en livlig og væsentlig del af statens livsstil og identitet.",
      ],
    },
    
    {
      name: "Maine",
      description: [
        "Collegessport i Maine afspejler statens unikke natur og følelse af fællesskab, med et stærkt fokus på vintersport og atletik. University of Maine Black Bears fører an og repræsenterer stolt i forskellige NCAA-divisioner.",
        "Alfond Stadium i Orono er hjertet af amerikansk fodbold i staten, mens Harold Alfond Sports Arena spiller en central rolle i ishockeysæsonen. Black Bears har en stolt ishockeytradition, med entusiastiske fans der tager den kolde med for at støtte deres hold.",
        "Ud over ishockey og fodbold er der også stærke programmer inden for atletik, roning og andre sportsgrene, der beriger Maines sportslandskab. Staten nyder også en livlig konkurrence inden for gymnasie- og klubidræt, hvilket gør sport til en integreret del af fællesskabet.",
        "Sport i Maine er mere end bare konkurrence; det er en kilde til stolthed, en måde at fejre statens unikke ånd på og et bånd, der bringer folk sammen, uanset årstiden.",
      ],
    },
    
    {
      name: "Maryland",
      description: [
        "Collegessport i Maryland trives med en stærk tradition inden for basketball, lacrosse og amerikansk fodbold. University of Maryland Terrapins, eller simpelthen Terps, er centrale for statens sportskultur.",
        "Xfinity Center og Maryland Stadium er de stolte arenaer for basketball og fodbold, hvor Terps spiller deres hjemmekampe. Fans er lidenskabelige og bidrager til en elektrificerende atmosfære under kampe. Maryland er kendt for sit konkurrencedygtige basketballprogram, med flere NCAA-turneringsoptrædener.",
        "Lacrosse er en anden sport, hvor Maryland excellerer, med Terps regelmæssigt kæmper om nationale mesterskaber. Denne sport har en særlig plads i indbyggernes hjerter, og intense collegierivaliseringer gør hver sæson til et spektakel.",
        "Sport i Maryland er en udtryk for fællesskab, stolthed og tradition og spiller en afgørende rolle i at bringe folk sammen og fejre statens unikke kultur.",
      ],
    },
    
  


    {
      name: "Massachusetts",
      description: [
        "I Massachusetts spiller universitetssport en betydelig rolle i dagligdagen med en rig historie inden for basketball, amerikansk fodbold og ishockey. Universiteterne her har stærke sportsprogrammer og passionerede fans.",
        "Basketball har en særlig plads i Massachusetts' indbyggeres hjerter, da spillet blev opfundet her. University of Massachusetts Minutemen, kendt som UMass Minutemen, er stolte repræsentanter i NCAA Division I basketball.",
        "Amerikansk fodbold og ishockey er også populære, og intense lokale rivaliseringer giver spændende konkurrence. Harvard-Yale-fodboldkampen, kendt som 'The Game', er et årligt højdepunkt.",
        "Sport i Massachusetts er om tradition, fællesskab og konkurrence. Det bringer mennesker sammen, uanset deres baggrund, og er en væsentlig del af statens kulturelle identitet."
      ]
    },
    {
      name: "Michigan",
      image: Michigan,
      imageDescription: "Michigan Stadium: den tredjestørste stadion i verden og hjemsted for Michigan Wolverines. Det kan rumme 107.601 fans.",
      description: [
        "Michigan har en rig tradition inden for universitetssport, især inden for amerikansk fodbold og basketball. Rivaliseringen mellem University of Michigan Wolverines og Michigan State Spartans er legendarisk og skaber en elektrisk atmosfære i hele staten.",
        "Michigan Stadium, også kendt som 'The Big House', er en af de største stadioner i verden og kan rumme over 100.000 fans. Wolverines har en glorværdig historie med flere nationale mesterskaber i fodbold.",
        "Basketball er også enormt populært, og både Michigan og Michigan State deltager regelmæssigt i NCAA-turneringen. Fansenes lidenskab og dedikation er enestående, og kampdage er ægte begivenheder i Michigan.",
        "Ud over disse store sportsgrene har Michigan også stærke programmer inden for ishockey, atletik og andre sportsgrene. Sport i Michigan er mere end bare en fritidsbeskæftigelse; det er en integreret del af statens livsstil og identitet."
      ]
    },
    {
      name: "Minnesota",
      description: [
        "Universitetssport i Minnesota har en unik karakter med stærk fokus på ishockey, amerikansk fodbold og basketball. University of Minnesota Golden Gophers spiller en central rolle i statens sportsfællesskab.",
        "TCF Bank Stadium og Williams Arena er travle centre for aktivitet under fodbold- og basketballsæsonerne, mens Mariucci Arena er et fast sted for ishockey. Golden Gophers er stolte repræsentanter i NCAA med en rig historie og dedikerede fans.",
        "Ishockey er særligt populært i Minnesota og omtales ofte som 'The State of Hockey'. Universitetshold nyder stor støtte og giver spændende action, især under den berømte 'Frozen Four' NCAA-turnering.",
        "Sport i Minnesota er dybt forbundet med statens kultur og identitet og tilbyder en kilde til stolthed, fællesskabsånd og livlige rivaliseringer, der samler mennesker i alle aldre."
      ]
    },
    {
      name: "Mississippi",
      description: [
        "Universitetssport er en integreret del af kulturen i Mississippi med intens passion for amerikansk fodbold, basketball og baseball. Rivaliseringen mellem University of Mississippi (Ole Miss) Rebels og Mississippi State Bulldogs er en af de mest intense i landet.",
        "Vaught-Hemingway Stadium i Oxford og Davis Wade Stadium i Starkville er ægte templer for fodboldfans, hvor spændingen og passionen for spillet er håndgribelig. Den årlige Egg Bowl, sammenstødet mellem Ole Miss og Mississippi State, er et højdepunkt i sportskalenderen.",
        "Basketball og baseball er også betydningsfulde, og begge universiteter opbygger stærke hold og traditioner. Fansene er loyale og højlydte, hvilket skaber en elektrisk atmosfære ved kampene.",
        "Sport i Mississippi handler om fællesskab, stolthed og tradition. Det bringer mennesker sammen, skaber uforglemmelige øjeblikke og er en væsentlig del af statens identitet."
      ]
    },
    {
      name: "Missouri",
      description: [
        "Universitetssport i Missouri er synonymt med passion, fællesskab og konkurrence. Staten har en rig sports-historie, hvor amerikansk fodbold, basketball og baseball fører an.",
        "University of Missouri Tigers, også kendt som Mizzou, og Missouri State Bears er to fremtrædende repræsentanter i universitetssportslandskabet. Mizzous Faurot Field og Mizzou Arena er epicentre for spænding og holdsånd på kampdage.",
        "Tigerne har en stærk tilstedeværelse i SEC, en af de mest konkurrenceprægede konferencer inden for universitetsfodbold. Deres basketballhold er også en fast bestanddel af NCAA-turneringen, hvor fansene giver urokkelig støtte.",
        "Sport i Missouri styrker fællesskabsbånd og samler mennesker. Det handler ikke kun om spillet; det handler om oplevelsen, traditionerne og det uadskillelige bånd mellem holdene og deres tilhængere."
      ]
    },
    {
      name: "Montana",
      description: [
        "I Montana spiller universitetssport en afgørende rolle i lokalsamfundet med amerikansk fodbold, basketball og rodeo som populære sportsgrene. Montana Grizzlies og Montana State Bobcats dominerer sportslandskabet, og deres rivalisering er intens.",
        "Grizzlies' Washington-Grizzly Stadium og Bobcats' Bobcat Stadium er stolte fodboldarenaer i Montana. Her samles fansene for at støtte deres hold, uanset vejrforholdene. Den årlige Brawl of the Wild-kamp mellem Grizzlies og Bobcats er en begivenhed, man ikke må gå glip af.",
        "Basketball er også populært med livlige kampe og en stærk fanbase. Rodeo, der er unikt for Montana, tiltrækker også stor opmærksomhed og værdsættelse og afspejler statens kultur og traditioner.",
        "Universitetssport i Montana handler om fællesskab, stolthed og kærlighed til spillet. Det samler mennesker, skaber livslange minder og bidrager til den unikke identitet i Big Sky Country."
      ]
    },
    {
      name: "Nebraska",
      description: [
        "I Nebraska er universitetssport karakteriseret ved dyb lidenskab og loyalitet, med særligt fokus på amerikansk fodbold. Nebraska Cornhuskers, baseret på University of Nebraska-Lincoln, er hjertet af statens sportskultur.",
        "Memorial Stadium, hjemsted for Cornhuskers, kaldes også 'The Sea of Red', hvor fans i deres karakteristiske røde tøj samles for at støtte deres hold. Stadionet er kendt for sin øredøvende støj og unikke atmosfære på kampdage.",
        "Ud over fodbold spiller basketball også en betydelig rolle, hvor både mænds og kvinders hold konkurrerer på et højt niveau. Fansene er lige så dedikerede og skaber en levende atmosfære ved hver kamp.",
        "Universitetssport i Nebraska handler ikke kun om selve sporten; det er en måde at bringe lokalsamfundene sammen, fejre traditioner og skabe en følelse af stolthed og fællesskab i 'The Cornhusker State'."
      ]
    },
    {
      name: "Nevada",
      description: [
        "Universitetssport i Nevada tilbyder en unik blanding af underholdning og konkurrence med særlig opmærksomhed på basketball og amerikansk fodbold. University of Nevada, Las Vegas (UNLV) og University of Nevada, Reno er de to hovedinstitutioner inden for sport.",
        "UNLVs basketballhold, Runnin' Rebels, har en rig historie og er kendt over hele landet. Deres hjemmekampe er en sand attraktion med energiske præstationer og et entusiastisk publikum. Nevada Wolf Packs fodboldhold bidrager også til sportskulturen i staten med spændende kampe og en loyal fanbase.",
        "I Nevada er der også en livlig interesse for andre universitetssportsgrene som baseball, softball og fodbold. Disse sportsgrene tiltrækker lokale samfund og giver rigeligt med muligheder for atleter at udvikle sig.",
        "Overordnet set er universitetssport i Nevada en væsentlig del af staten, der ikke kun giver underholdning, men også samler lokalsamfund og beriger den lokale kultur."
      ]
    },
    {
      name: "New Hampshire",
      description: [
        "I den maleriske stat New Hampshire spiller universitetssport en afgørende rolle i lokalsamfundet. Selvom staten er mindre end mange andre, er dedikationen til sport enorm.",
        "University of New Hampshire (UNH) dominerer sportslandskabet, især inden for ishockey, hvor Wildcats konstant præsterer på et højt niveau. Whittemore Center Arena, hjemmebanen for ishockeyholdet, er kendt for sin levende atmosfære og passionerede fans.",
        "Fodbold og basketball er også populære sportsgrene inden for UNH og tiltrækker store tilskuerskarer i løbet af sæsonen. Lokalsamfundet er stolte af deres hold, og studerende er tæt involveret i sportsbegivenheder.",
        "New Hampshires tilgang til universitetssport er fokuseret på lokalsamfundet med det formål at samle mennesker, fejre succeser og skabe uforglemmelige øjeblikke i statens maleriske omgivelser."
      ]
    },
    






    {
      "name": "New Jersey",
      "description": [
        "College sports i New Jersey nyder en livlig og konkurrencepræget atmosfære, hvor forskellige universiteter konkurrerer på et højt niveau. Staten huser nogle førende programmer, der er anerkendt både regionalt og nationalt.",
        "Et af de mest fremtrædende universitetshold er Rutgers Scarlet Knights, en del af den prestigefyldte Big Ten Conference. Deres fodbold- og basketballkampe tiltrækker store menneskemængder og skaber spænding i hele staten.",
        "New Jersey er også kendt for sine stærke lacrosse- og fodboldprogrammer, der tiltrækker og udvikler ungt talent. Disse sportsgrene spiller en betydelig rolle i statens collegesporskultur med spændende konkurrencer og dedikerede fans.",
        "Samfundet i New Jersey omfavner sine collegesporthold, hvilket resulterer i en støttende og passioneret atmosfære ved hvert arrangement. College sports i New Jersey er mere end bare spil; de er en væsentlig del af statens kulturelle væv."
      ]
    },
    {
      "name": "New Mexico",
      "description": [
        "New Mexico har med sin unikke kulturelle og geografiske baggrund et specielt forhold til collegespors. Staten huser nogle fremtrædende universitetssportsprogrammer, der konkurrerer på nationalt niveau.",
        "University of New Mexico Lobos i Albuquerque er et centralt punkt for stolthed. Deres basketballhold tiltrækker regelmæssigt store menneskemængder til The Pit, en af de mest intimidende arenaer i landet.",
        "New Mexico State Aggies fra Las Cruces er også nationale konkurrenter, især inden for basketball og fodbold, hvor de har intense rivaliseringer med Lobos, der kulminerer i Rio Grande-rivaliseringen.",
        "Understøttet af en rig historie og en passioneret fanbase giver collegespors i New Mexico ikke kun underholdning, men fremmer også fællesskabsånd og statens identitet. I denne ørkenstat er sport en fejring af talent, udholdenhed og fællesskabsbånd."
      ]
    },
    {
      "name": "New York",
      "description": [
        "I den travle stat New York spiller collegespors en dynamisk og alsidig rolle. Staten huser talrige universiteter, hver med sine egne unikke sports traditioner.",
        "Syracuse University skiller sig ud med sit basketballhold, Orange, der ofte konkurrerer på det højeste nationale niveau. Carrier Dome-stadionet, kendt for sin imponerende kapacitet og livlige atmosfære, er en ikonisk del af collegesporsverdenen.",
        "Derudover spiller mindre universiteter og kollegier også en betydelig rolle med stærke programmer inden for sportsgrene som lacrosse, fodbold og baseball. Disse mindre programmer nærrer den konkurrencemæssige ånd i staten og skaber et rigt og varieret sportslandskab.",
        "Fællesskabsengagementet i collegespors i New York er enormt, med fans der støtter deres hold gennem tyndt og tykt. Fra travle byer til mere stille landdistrikter forener sport New Yorkere og indprenter en følelse af stolthed og samhørighed."
      ]
    },
    {
      "name": "North Carolina",
      "image": North_Carolina,
      "imageDescription": "Den ikoniske rivalisering: DUKE vs. UNC i aktion",
      "description": [
        "North Carolina har en rig tradition og enestående passion, når det kommer til collegespors, især inden for basketball. Rivaliseringen mellem University of North Carolina Tar Heels og Duke University Blue Devils er verdenskendt og garanterer spændende opgør.",
        "Tar Heels og Blue Devils dominerer ikke kun basketballsæsonen, men bringer også bølger af spænding og fællesskabsånd over hele staten. Det er en sportskultur dybt forankret i North Carolinas identitet.",
        "Ud over basketball spiller amerikansk fodbold også en fremtrædende rolle, med stærke hold og passionerede fanbaser. Og om foråret blomstrer kærligheden til baseball, hvor både universitets- og kollegeteams konkurrerer på markerne.",
        "Collegespors i North Carolina går ud over sportsbanerne og hallerne; de er en livsstil, en kilde til stolthed og en måde for samfundene at komme sammen og fejre deres stat på."
      ]
    },
    {
      "name": "North Dakota",
      "description": [
        "Collegespors i North Dakota tilbyder en unik oplevelse, hvor lokalsamfundene er tæt involveret med deres universitetshold. Stoltheden og samhørigheden er tydelig under sportsbegivenheder, på trods af at staten har færre fremtrædende universiteter end andre amerikanske regioner.",
        "American football-holdet fra North Dakota State University, Bison, er kendt for sine succeser i NCAA Division I Football Championship Subdivision, hvilket giver et betydeligt løft til statens sportskultur. Fans samles ved Fargodome for at støtte deres hold og skabe en intens atmosfære.",
        "Is hockey spiller også en stor rolle i North Dakota, med University of North Dakota Fighting Hawks som et førende hold. Passionen for denne vintersport er tydelig i løbet af sæsonen, hvor fans strømmer til arenaen.",
        "Collegespors i North Dakota, især amerikansk fodbold og is hockey, samler samfundene, skaber stærk lokal stolthed og bidrager til statens levende sportskultur."
      ]
    },
    {
      "name": "Ohio",
      "description": [
        "Collegespors er en integreret del af kulturen i Ohio, med et særligt fokus på amerikansk fodbold og basketball. Ohio State University (OSU) og University of Cincinnati er to førende institutioner inden for sport.",
        "OSUs fodboldhold, Buckeyes, nyder national berømmelse og er en sand kraft i NCAA. Deres kampe på det massive Ohio Stadium tiltrækker mere end 100.000 fans og skaber en uovertruffen atmosfære under hjemmekampe. 'O-H-I-O' er en kendt råb i hele staten.",
        "Basketball er også fremtrædende, med både herre- og damehold, der konkurrerer på et højt niveau. Ohio-staten huser flere universitetshold, der regelmæssigt deltager i NCAA-turneringen, hvilket tilføjer til basketballfrenzien.",
        "Collegespors i Ohio tilbyder mere end bare konkurrence; de er en kilde til stolthed, tradition og fællesskabsånd og forener folk fra alle samfundslag."
      ]
    },
    {
      "name": "Oklahoma",
      "description": [
        "I Oklahoma indtager collegespors en central plads i indbyggernes hjerter, med en dybt forankret passion for amerikansk fodbold. University of Oklahoma (OU) og Oklahoma State University (OSU) er statens juveler inden for sportsverdenen.",
        "OUs fodboldhold, Sooners, har en rig historie og talrige succeser, der skaber en livlig atmosfære omkring deres kampe. Gaylord Family Oklahoma Memorial Stadium er en fæstning for holdet, hvor fans højlydt råber 'Boomer Sooner'.",
        "OSU er ikke langt bagud OU, med stærke præstationer både i fodbold og andre sportsgrene, hvor deres amerikansk fodboldhold, Cowboys, spiller en betydelig rolle. Dedikationen fra fans under 'Bedlam-serien', den årlige kamp mellem OU og OSU, er enorm.",
        "Collegespors i Oklahoma symboliserer statens kamp, udholdenhed og fællesskabssans, hvilket gør det meget mere end bare et spil."
      ]
    },
    {
      "name": "Oregon",
      "description": [
        "Collegespors i Oregon er en væsentlig del af staten, med fokus på både amerikansk fodbold og atletik. University of Oregon (UO) i Eugene er central for denne sports passion og har opbygget et nationalt ry.",
        "UOs fodboldhold, Ducks, er kendt for deres hurtige spil og unikke uniformer, mens atletikprogrammet er et af de bedste i landet. Det årlige atletikarrangement, Prefontaine Classic, afholdt på det berømte Hayward Field, fremhæver Oregons dedikation til atletik.",
        "Oregon State University (OSU) med deres fodboldhold, Beavers, giver stærk konkurrence, især under 'Civil War', det årlige opgør med Ducks. Dette er mere end et spil; det er en stats tradition, der bringer samfundene sammen.",
        "Ud over disse sportsgrene nyder basketball også popularitet, og passionerede fans gør collegespors i Oregon til en oplevelse, du ikke vil gå glip af. Det er en fejring af atletisk talent, fællesskab og statens unikke sportskultur."
      ]
    },
    {
      "name": "Pennsylvania",
      "description": [
        "Pennsylvania har en dyb værdsættelse for collegespors, med stærkt fokus på amerikansk fodbold, basketball og atletik. Hold som Penn State Nittany Lions og Pittsburgh Panthers dominerer sportslandskabet og skaber spændende rivaliseringer.",
        "Beaver Stadium, hjemstedet for Nittany Lions, er kendt for sin overvældende atmosfære og intense fanoplevelse, især under 'White Out'-kampe. Disse øjeblikke er højdepunktet i sportskalenderen og tiltrækker fans fra hele staten.",
        "På basketballbanen har Villanova Wildcats for nylig opnået national succes med flere mesterskaber, der har begejstret staten. Deres præstationer har sat Pennsylvania på landkortet som en basketballmagt.",
        "Atletik spiller også en afgørende rolle, med det prestigefyldte Penn Relays, der tiltrækker topatleter til Philadelphia hvert år. Denne rige sportskultur styrker fællesskabsånden og bidrager til stoltheden i Pennsylvania."
      ]
    },
    {
      "name": "Rhode Island",
      "description": [
        "Trods sin lille størrelse har Rhode Island en livlig collegespors scene, med basketball og sejlsport, der er særligt populære. Staten har hold, der spiller med hjerte og sjæl og skaber spændende øjeblikke.",
        "Hold som Rhode Island Rams og Providence Friars har en loyal fanbase og leverer fængslende kampe. Dunkin' Donuts Center i Providence bliver en travl arena under basketballkampe, hvor lokale møder talstærkt frem for at støtte deres hold.",
        "Ud over basketball er Rhode Island kendt for sine fremragende sejlsportsprogrammer, hvor den smukke kystlinje giver perfekte forhold for sporten. Universiteter som Brown og URI har stærke sejlsportshold, der konkurrerer nationalt.",
        "Disse sportsgrene bidrager til Rhode Islands livlige kultur, hvor hvert spil er en mulighed for at fejre statens unikke ånd."
      ]
    },
    {
      "name": "South Carolina",
      "description": [
        "Collegespors i South Carolina ånder tradition og passion, med amerikansk fodbold og basketball i centrum for opmærksomheden. Den intense rivalisering mellem University of South Carolina Gamecocks og Clemson University Tigers dominerer sportslandskabet.",
        "Under det årlige fodboldopgør, 'The Palmetto Bowl', kommer hele staten til live, hvor linjen mellem garnet og orange tydeligt tegnes. Fansenes energi og lidenskab er uden sidestykke og gør hver kamp til en uforglemmelig oplevelse.",
        "Basketball har også en fremtrædende plads i sportsfansens hjerter i South Carolina, med hold, der konkurrerer i toppen både i herre- og dameklassen. For eksempel har Gamecocks' kvindelige basketballhold opnået national succes, hvilket yderligere styrker fællesskabsstoltheden og engagementet.",
        "Sportskulturen i South Carolina går ud over spillebanerne; det er en livsstil, en kilde til stolthed og en uundværlig del af statens identitet."
      ]
    },
    {
      "name": "South Dakota",
      "description": [
        "Collegespors i South Dakota tilbyder en unik og passioneret oplevelse, med fokus på basketball, amerikansk fodbold og atletik. South Dakota State University Jackrabbits og University of South Dakota Coyotes er fremtrædende aktører i sportslandskabet.",
        "Basketballkampe, især under rivalopgørene mellem Jackrabbits og Coyotes, tiltrækker entusiastiske tilskuere og skaber en elektrificerende atmosfære. Staten hepper på både herre- og dameholdene, hvor præstationerne på banen er en kilde til stolthed for de lokale samfund.",
        "Amerikansk fodbold nyder også stor popularitet, med spændende kampe og stærkt fan-engagement. Atletikprogrammer i South Dakota excellerer, med atleter der konkurrerer nationalt og stolt repræsenterer staten.",
        "Collegespors i South Dakota er mere end bare spil; det er en væsentlig del af fællesskabet, der styrker båndene mellem beboerne og bidrager til statens levende kultur."
      ]
    },
    {
      "name": "Tennessee",
      "description": [
        "I Tennessee spiller collegespors en afgørende rolle i samfundet, med en rig tradition inden for amerikansk fodbold, basketball og atletik. University of Tennessee Volunteers og Vanderbilt University Commodores er førende hold i statens sportslandskab.",
        "Volunteers, med deres ikoniske orange farve, tiltrækker enorme menneskemængder til Neyland Stadium, en af de største amerikanske fodboldstadioner i landet. Fansenes lidenskab er uden sidestykke, og 'Rocky Top' runger højt under hver kamp.",
        "Basketball er også en stor attraktion i Tennessee, med både herre- og damehold, der modtager national anerkendelse. Staten har en rig historie med at producere topatleter og har bidraget til sportens vækst på nationalt niveau.",
        "Collegespors i Tennessee er en væsentlig del af statens kultur og identitet, der bringer folk sammen, fejrer traditioner og inspirerer den næste generation af atleter."
      ]
    },
    





    {
      "name": "Texas",
      "image": Texas,
      "imageDescription": "Traditionel rivalisering: Texas Longhorns står over for Texas A&M",
      "description": [
          "Collegesport i Texas er synonymt med størrelse, lidenskab og rig tradition, hvor amerikansk fodbold tager føringen. Hold som Texas Longhorns og Texas A&M Aggies har massive følgeskaber, og deres kampe er sæsonhøjdepunkter.",
          "Darrell K Royal–Texas Memorial Stadium i Austin og Kyle Field i College Station er sports-templer, hvor enhver hjemmekamp bliver til en spektakulær begivenhed. Rivaliseringen mellem forskellige universitetshold er intens og bidrager til statens unikke sportskultur.",
          "Udover fodbold er basketball og baseball også populære, med stærke programmer, der konkurrerer nationalt. Støtten til kvindesport vokser støt, især basketball og atletik skiller sig ud.",
          "Collegesport i Texas er mere end bare en fritidsinteresse; det er en livsstil, en kilde til stolthed og en samlende kraft, der bringer samfund sammen."
      ]
  },
  {
      "name": "Utah",
      "description": [
          "I Utah indtager collegespors en betydelig plads inden for samfundet, hvor basketball og amerikansk fodbold er de mest populære sportsgrene. University of Utah Utes og Brigham Young University Cougars deltager i heftig konkurrence på forskellige områder.",
          "Det årlige fodboldopgør mellem Utes og Cougars, kendt som 'Holy War', er en af de mest forventede sportsbegivenheder i staten. Det bringer fans sammen til en fejring af sportsånd, rivalisering og statslig ære.",
          "Basketball har også en rig tradition i Utah, med spændende sæsoner og mindeværdige øjeblikke både i herre- og dame-ligaerne. Holdene stræber efter excellence, og fanstøtten er urokkelig.",
          "Collegespors i Utah har indflydelse ud over spillebanen. Det fremmer fællesskabssind, skolestolthed og bidrager væsentligt til statens kulturelle identitet."
      ]
  },
  {
      "name": "Vermont",
      "description": [
          "I Vermont, hvor naturen dominerer, og samfundene er tætte, spiller collegesport en unik rolle i at samle folk. Vintersportsgrene som skiløb og snowboarding er fremtrædende, givet det bjergede terræn og det kolde klima.",
          "University of Vermont Catamounts er centrum for collegesportsscenariet, hvor hold excellerer i forskellige sportsgrene, herunder ishockey og basketball. Catamounts har en loyal følgeskab, og atmosfæren ved hjemmekampe er intens.",
          "Basketball nyder stor popularitet, og Catamounts' kampe er et must for sportsentusiaster. Holdet har deltaget i NCAA-turneringen flere gange og har sat staten på landkortet i collegebasketballens verden.",
          "Collegesport i Vermont er mere end bare et spil; det er en måde at samle samfund, udvikle talent og fejre statens unikke sportskultur."
      ]
  },
  {
      "name": "Virginia",
      "description": [
          "I Virginia er collegesport en integreret del af den lokale kultur, med stærkt fokus på amerikansk fodbold, basketball og den voksende popularitet af fodbold. Virginia Cavaliers og Virginia Tech Hokies er førende universitetshold, der bringer lidenskab og rivalisering til banen.",
          "Amerikansk fodbold tiltrækker mængder af fans til stadioner, hvor atmosfæren på kampdage er elektrisk. Investeringen i basketballprogrammer har også båret frugt, med hold, der konkurrerer nationalt og har en dedikeret følgeskab.",
          "Fodbold vinder frem i Virginia, med både herre- og damehold, der udvikler sig til konkurrencedygtige styrker. Universiteter investerer i faciliteter og træning, mens lokale akademier producerer ungt talent.",
          "Denne blanding af traditionelle og opkommende sportsgrene beriger collegesporscenen i Virginia, skaber et levende sportsfællesskab og giver atleter og fans uforglemmelige oplevelser samt en følelse af stolthed og enhed."
      ]
  },
  {
      "name": "Washington",
      "description": [
          "Collegesport i Washington trives med en blanding af tradition og entusiasme, hvor amerikansk fodbold, basketball og roning indtager hovedrollen. University of Washington Huskies og Washington State University Cougars er pionerer inden for statens sportskultur.",
          "Husky Stadium i Seattle og Martin Stadium i Pullman summer af spænding under amerikanske fodboldkampe, hvor fans fra hele området samles for at støtte deres hold. 'Apple Cup', det årlige opgør mellem Huskies og Cougars, er et højdepunkt for rivaliseringen.",
          "Basketball spiller også en betydelig rolle, med både herre- og damehold, der konkurrerer på et højt niveau. Huskies har en stærk arv inden for denne sport og tiltrækker passionerede tilhængere.",
          "Roningsporten er en anden bemærkelsesværdig disciplin, hvor Huskies har vundet talrige nationale mesterskaber. Collegesport i Washington afspejler mangfoldigheden og lidenskaben i staten, hvor sportsånd, konkurrence og kammeratskab er meget værdsatte."
      ]
  },
  {
      "name": "West Virginia",
      "description": [
          "Collegesport er hjertet af West Virginia, med stærk fokus på amerikansk fodbold og basketball. West Virginia University Mountaineers er statens stolte repræsentanter, og deres kampe samler lokalsamfundet.",
          "Milan Puskar Stadium i Morgantown springer til live under Mountaineers' hjemmekampe, hvor fans ifører sig de ikoniske guld- og blå farver og hepper højt. 'Backyard Brawl', en historisk rivalisering med University of Pittsburgh, tilføjer ekstra spænding til sæsonen.",
          "Basketball er en anden passion, med spændende kampe, der holder fansene på kanten af deres sæder. Mountaineers har både herre- og damehold, der konkurrerer på nationalt niveau.",
          "Collegesport i West Virginia er en kilde til stolthed og enhed, der afspejler det stærke fællesskab og traditionerne i staten."
      ]
  },
  {
      "name": "Wisconsin",
      "description": [
          "Collegesport er dybt forankret i kulturen i West Wisconsin, med amerikansk fodbold, basketball og ishockey som prominente discipliner. University of Wisconsin Badgers er drivkraften bag sportskulturen i staten.",
          "Camp Randall Stadium i Madison summer af spænding under amerikanske fodboldkampe, hvor fans samles for at synge 'Jump Around' og heppe på deres hold. Rivaliseringen med hold som University of Michigan Wolverines er legendarisk og fører til intense kampe.",
          "Basketball har også en rig tradition, med Badgers, der regelmæssigt konkurrerer om de øverste placeringer i NCAA-turneringer. Ishockey indtager en særlig plads i statens hjerte, med spændende kampe, der lyser vinteraftenerne op.",
          "Collegesport i West Wisconsin går ud over spillebanen; det er en kilde til stolthed, kammeratskab og styrker båndene mellem samfundene i staten."
      ]
  },
  {
      "name": "Wyoming",
      "description": [
          "Collegesport i Wyoming har en unik karakter, med stærkt fokus på udendørs sportsgrene som skiløb, snowboarding og bjergbestigning. University of Wyoming Cowboys og Cowgirls er standardbærere for denne eventyrlige sportskultur.",
          "War Memorial Stadium i Laramie springer til live under amerikanske fodboldkampe, hvor fans samles for at vise deres støtte til Cowboys. De betagende bjerge fungerer som baggrund for disse sportsbegivenheder.",
          "Universitetet har også en stærk tradition inden for skiløb og snowboarding, med atleter der dominerer nationale og internationale konkurrencer. Wyomings store vildmark fungerer som legeplads for eventyrlystne og udendørs entusiaster.",
          "Collegesport i Wyoming fejrer naturens skønhed og statens eventyrlige ånd, hvor sportsånd, passion og kærlighed til det frie liv forenes."
      ]
  }
  



];

export default states;