/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


// @mui material components
import React, { useState } from 'react'; // Step 1: Import useState


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Logos/NCAA.png";
import post3 from "assets/images/Logos/NAIA.png";



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {

  const articleStructure = [
    { id: 'intro', title: 'NJCAA: Stegen till Stora Universitet och NCAA', isSubheader: false },
  
    { id: 'Hoofdstuk 1', title: 'Ursprung och Tillväxt av NJCAA', isSubheader: true },
    { id: '1.1', title: 'NJCAAs Tre Divisioner', isSubheader: true },
    { id: '1.2', title: 'Övergång till Stora Universitet och NCAA', isSubheader: true },
  
    { id: 'Hoofdstuk 2', title: 'Akademikernas Roll', isSubheader: true },
    { id: '2.1', title: 'Ekonomi och Synlighet', isSubheader: true },
    { id: '2.2', title: 'Utmaningar och Framtiden', isSubheader: true },
    { id: '2.3', title: 'Avslutning', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
     <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                       Innehåll
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}> Dölj</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Visa</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="intro" mb={3}>

<MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>NJCAA: Stegen till Stora Universitet och NCAA</MKTypography>
</MKBox>

<MKBox id="Kapitel 1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Ursprung och Tillväxt av NJCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
National Junior College Athletic Association (NJCAA) grundades 1938 och har sedan dess vuxit till att bli en framträdande aktör inom den amerikanska collegidrotten. Organisationen erbjuder idrottsmöjligheter för junior colleges, mindre tvååriga institutioner som ger studenterna en väg till högre utbildning och idrottslig framgång.

<br />
<br />
I sina tidiga år var NJCAA begränsad i omfattning, men den har vuxit och anpassat sig för att möta behoven hos en mångfaldig och ständigt föränderlig studentpopulation. Fokus ligger på att erbjuda tillgång till idrottstävlingar samtidigt som akademiskt framsteg främjas.

</MKTypography>

<MKBox id="1.1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
NJCAAs Tre Divisioner
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
NJCAA är uppdelad i tre divisioner, var och en utformad för att möta olika behov och möjligheter för studenter-idrottare:

<br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division I:{' '}
</MKTypography>
Denna division inkluderar skolor som kan erbjuda fulla idrottsstipendier, vilket resulterar i en högre nivå av konkurrens. Studenter-idrottare här har möjlighet att utveckla sina färdigheter och tävla på en intensiv nivå, ofta som en trampolin till NCAA-divisioner.

<br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division II:{' '}
</MKTypography>
Här hittar vi en balans mellan idrottstävling och akademiska ansvar, med skolor som tillåts erbjuda delvis stipendier. Detta säkerställer en hälsosam balans och ger studenter-idrottare möjlighet att utmärka sig i sin sport samtidigt som de förbereds för akademisk framgång.   <br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division III:{' '}
</MKTypography>
I denna division ligger fokus på att erbjuda konkurrenskraftiga idrottsmöjligheter utan idrottsstipendier. Studenter-idrottare har möjlighet att delta i idrott samtidigt som de fullt ut engagerar sig i det akademiska livet, vilket resulterar i en väl avrundad college-upplevelse.  </MKTypography>


<MKBox id="1.2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Övergång till Stora Universitet och NCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
NJCAA spelar en nyckelroll som ett trampolin för studenter-idrottare som vill fortsätta sina akademiska och idrottsliga karriärer vid större fyraåriga institutioner, inklusive de i NCAA. Många studenter-idrottare börjar sin resa i NJCAA för att utveckla sina färdigheter, förbättra sina akademiska kvalifikationer och synas för rekryterare från större program.


<br />
<br />
Genom att bevisa sin förmåga i den konkurrenskraftiga miljön i NJCAA kan studenter-idrottare locka uppmärksamhet från NCAA-skolor och potentiellt tjäna stipendier för att fortsätta sin utbildning och idrottskarriär. Denna process är inte bara fördelaktig för idrottarna själva, utan bidrar också till att stärka idrottsprogram över hela landet.


</MKTypography>





                                  







<MKBox id="Kapitel 2" mb={3}>
  
  
  <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Akademikernas Roll
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              NJCAA är starkt engagerad i den akademiska framgången och utvecklingen av sina studentidrottare. Det finns rigorösa akademiska standarder, och de anslutna skolorna ger väsentlig vägledning för att säkerställa att idrottarna utmärker sig både i klassrummet och i sin sport. Detta illustrerar den avgörande rollen för ett balanserat tillvägagångssätt inom collegesporthandeln, även om nivån kan vara något lägre än vid vissa andra utbildningsinstitutioner.
<br />
                <br />
                </MKTypography>

                <MKBox id="2.1" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Ekonomi och Synlighet
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              Trots att de ekonomiska resurserna är mer begränsade jämfört med NCAA, spelar NJCAA en avgörande roll i att stödja idrottsprogram och främja möjligheter för studentidrottare. Organisationen arbetar också med att öka sin synlighet och räckvidd med målet att nå och inspirera fler studenter och idrottare.


<br />
                </MKTypography>

                <MKBox id="2.2" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Utmaningar och Framtiden
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              NJCAA, liksom andra idrottsorganisationer, står inför utmaningar, inklusive att navigera genom frågor om jämställdhet inom idrotten och stödja den mentala hälsan hos studentidrottare. Men med sitt engagemang för att främja möjligheter och stödja studentidrottare, är organisationen väl positionerad för att fortsätta göra en positiv inverkan.



                </MKTypography>

                <MKBox id="2.3" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Avslutning
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              National Junior College Athletic Association är en väsentlig komponent i det amerikanska collegesporthandeln, vilket ger unika möjligheter för studentidrottare att tävla, lära sig och växa. Med sitt åtagande för akademisk och idrottslig framgång förblir NJCAA en inflytelserik kraft i formandet av framtiden för unga idrottare i Amerika.








<br />


                </MKTypography>


              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="NCAA"
                    description="NCAA: Hjärtat av College-sport i Amerika"
                    action={{
                      type: "internal",
                      route: "/sv/NCAA",
                      color: "info",
                      label: "Läs Mer",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="NAIA"
                    description="NAIA: Ett Alternativt Tillvägagångssätt inom Collegesport i Amerika"
                    action={{
                      type: "internal",
                      route: "/sv/NAIA",
                      color: "info",
                      label: "Läs Mer",
                     }}
                    />
                  </Grid>
                </Grid>
  
              </Grid>
          </Grid>
        </Container>
      </MKBox>
    );
  }
  
  export default Beschrijving;