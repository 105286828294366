/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================
* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post2 from "assets/images/TijdUS.png";
import "../../../../H1asH3.css"

function Profile() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center" />
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} justifyContent="space-between" alignItems="center" mb={1}>
                <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Part 1: Growing Up in the Netherlands</MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text">
  <br />
  In the enchanting surroundings of Nijmegen, my lifelong love affair with football began. As a child from the streets of Nijmegen, I felt the magic of the sport with every step I took on the green grass. My first club was BVC '12, where as a young lad, I experienced my first dribbles and goals. It was a time of innocence, where I discovered my passion for the sport, and where the foundations of my future were laid.
  <br /><br />

  After my adventure at BVC '12, I embarked on a journey through local football clubs, such as Quick, NEC, and Union. This journey formed the basis of my football education and brought me closer to my dream: reaching the pinnacle of Dutch youth football. As a young footballer, I dreamed of playing in big stadiums, surrounded by a sea of cheering fans. It was a dream shared by many young talents in the Netherlands, and I was determined to do everything to make this dream a reality.
  <br /><br />

  But as I developed my football talent, I also began to realize how crucial good education is for a solid future. In the Netherlands, my ambitions in football and education sometimes seemed contradictory. It was a challenge to balance both passions.
  <br /><br />

  The sparkling diversity and immense opportunities of America had always fascinated me, and this fascination only grew stronger during a vacation with my family. I was almost certain that I would return to this country, perhaps for an extended period. Later, through acquaintances, I heard stories about the opportunities to combine a football career with university studies in America, and my desire to follow the same path grew exponentially.
  <br /><br />

  Yet, it was not easy to arrange everything. The complex process of sports scholarships and study opportunities in America initially seemed daunting. Somewhere I knew it could be simpler and more accessible, which would eventually be my motivation to set up this company years later.
  <br /><br />

  After having contacted various coaches, the coach of the University of Tulsa had a particularly compelling story to share. He spoke about a team that always competed for prizes, consistently ranked in the top 25 in the country, and participated in great competitions. His words gave me the confidence that this was the place where I could fulfill both my football dreams and academic ambitions.
  <br /><br />

  And so it happened, two weeks after turning 18, my chapter in the Netherlands was (temporarily) over. I boarded the plane to America, ready for the adventure that lay ahead. Determined to unite my passions for football and education, I stood at the beginning of an exciting journey. More about my experiences in America, I will gladly share with you in part 2 of my story, where I delve deeper into my adventures in the United States.
</MKTypography>
<hr style={{ margin: '20px 0'}} />

<MKTypography component="a" href="#" variant="body1" fontWeight="light" color="info" mt={3}>
  <Grid container justifyContent="flex-end"> {/* This line is updated */}
    <Grid item xs={12} lg={6} mb={{ xs: 3, lg: 0 }}>
      <TransparentBlogCard
        image={post2}
        title="Part 2: My Time in America"
        action={{
          type: "internal",
          route: "/About-Me/Time-in-America",
          color: "info",
          label: "read more",
        }}
      />
    </Grid>
  </Grid>
</MKTypography>

</Grid>
</Grid>
</Grid>
</Container>
</MKBox>
);
}

export default Profile;
