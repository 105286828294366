/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import React, { useState } from 'react'; // Step 1: Import useState
import { Link } from 'react-router-dom';


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Football1.jpg";
import Image2 from "assets/images/Sports_Images/Football2.webp";
import Image3 from "assets/images/Sports_Images/Football3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";
import "../../../../H1asH3.css"


// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Amerikansk Fotbollsstipendier i USA: Hur man Säkrar en Plats i Ett Universitetslag', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapitel 1: Historien och Utvecklingen av Amerikansk Fotboll', isSubheader: false },
    { id: '1.1', title: '1.1 De Tidiga Åren', isSubheader: true },
    { id: '1.2', title: '1.2 Tillväxt och Professionalisering', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapitel 2: Collegians Fotboll', isSubheader: false },
    { id: '2.1', title: '2.1 Stora Ligor och Konferenser', isSubheader: true },
    { id: '2.2', title: '2.2 Spelarutveckling', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapitel 3: Från College till NFL', isSubheader: false },
    { id: '3.1', title: '3.1 Vägen till NFL', isSubheader: true },
    { id: '3.2', title: '3.2 Andra Professionella Möjligheter', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapitel 4: Påverkan av Amerikansk Fotboll på Amerikansk Kultur', isSubheader: false },
    { id: '4.1', title: '4.1 Kulturell Påverkan', isSubheader: true },
    { id: '4.2', title: '4.2 Förebilder och Inspiration', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapitel 5: Idrottsstipendier och Framtiden för Idrottare', isSubheader: false },
    { id: '5.1', title: '5.1 Vikten av Stipendier', isSubheader: true },
    { id: '5.2', title: '5.2 Hur Sport Scholarships America Kan Hjälpa', isSubheader: true },
  ];
  
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innehåll
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Dölj</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Visa</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>





















          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Amerikansk Fotbollsstipendier i USA: Hur man Säkrar en Plats i Ett Universitetslag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Flygbild av en fullsatt collegelagd match i amerikansk fotboll vid Indiana University, med spelare i aktion på planen och fans på läktaren."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
       }}>
        Indiana University-fotbollsmatch från läktaren.
      </figcaption>
    </figure>
    Amerikansk fotboll är en sport som härstammar från USA i slutet av 1800-talet. Det är en lagsport där två lag med elva spelare försöker föra en oval boll över motståndarens mållinje eller sparka den mellan målstolparna. Spelet kännetecknas av fysisk kontakt, taktik, strategi och spektakel.
    <br/>
    <br/>
    Amerikansk fotboll är inte bara en sport; det är också en kultur. Det är en väsentlig del av den amerikanska identiteten, värderingarna och traditionerna. Det är en källa till stolthet, passion och inspiration för miljontals människor. Det är också en industri som genererar miljarder dollar och skapar tusentals jobb.
    <br/>
    <br/>
    Men innan du kan bli en professionell spelare i NFL måste du visa upp din talang på collegenivå. Collegians fotboll är en avgörande del av spelarutvecklingen och erbjuder unga idrottare möjligheten att förbättra sina färdigheter, få en utbildning och bygga ett nätverk.
    <br/>
    <br/>
    Emellertid är collegians fotboll inte lättillgänglig för alla. Det finns tusentals lag och spelare som tävlar om ett begränsat antal platser och stipendier. Dessutom finns det många regler och krav som du måste uppfylla för att kvalificera dig för ett universitetslag.
    <br/>
    <br/>
    Det är där Sportbeursamerika.nl kan hjälpa dig. Jag är specialiserad på att matcha unga talanger med universitetslag som letar efter förstärkningar. Jag kan hjälpa dig att hitta ett idrottsstipendium, ordna ditt visum, förbereda dig för dina antagningsprov och förhandla med tränare. Med min hjälp kan du uppfylla din dröm om att studera och spela i Amerika.
    <br/>
    <br/>
    Vill du veta hur? Fortsätt läsa!
  </MKTypography>
</MKBox>

<MKBox id="Chapter 1: The History and Development of American Football" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Historien och Utvecklingen av Amerikansk Fotboll
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Amerikansk fotboll är en sport som härstammar från USA i slutet av 1800-talet. Den är en efterträdare till rugby och fotboll, två sporter som var populära i Europa. I det här kapitlet kommer vi att berätta mer om de tidiga åren och tillväxten och professionaliseringen av amerikansk fotboll.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 De Tidiga Åren
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ursprunget till amerikansk fotboll tillskrivs ofta en match mellan två universitetslag år 1869: Rutgers och Princeton. Men denna match liknade mer en form av fotboll än den moderna amerikanska fotbollen. Det fanns 25 spelare per lag, bollen fick inte röras med händerna, och det fanns inga målstolpar.
      <br/>
      <br/>
      Den första matchen som liknade modern amerikansk fotboll ägde rum år 1874 mellan Harvard och McGill. Denna match var en kombination av rugby och fotboll, vilket tillät bollen att bäras med händerna och inkluderade målstolpar. Harvard vann matchen 3-0.
      <br/>
      <br/>
      Universitetens roll i att forma de första lagen och ligorna var avgörande. De var pionjärer som definierade reglerna och stilen på spelet. År 1873 etablerades det första intercollegiate fotbollsförbundet, bestående av Yale, Columbia, Princeton och Harvard. År 1880 ansågs Walter Camp, en spelare och tränare från Yale, vara "Fadern till Amerikansk Fotboll" eftersom han introducerade betydande innovationer, såsom att minska antalet spelare till 11, införa scrimmage-linjen och nedräkningssystemet.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Tillväxt och Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Utvecklingen av amerikansk fotboll till en nationell fenomen började i slutet av 1800-talet och början av 1900-talet. Spelet blev populärt bland studenter, åskådare och media. Det blev också alltmer våldsamt och farligt, vilket ledde till talrika skador och till och med dödsfall. År 1905 hotade president Theodore Roosevelt att förbjuda spelet om inte reformer genomfördes. Detta ledde till etablerandet av National Collegiate Athletic Association (NCAA) år 1906, en organisation som ansvarar för att reglera och organisera collegians idrott.
      <br/>
      <br/>
      Den professionella amerikanska fotbollens inflytande på collegespelen var också betydande. Den första professionella amerikanska fotbollsligan grundades år 1892 men blev verkligen framgångsrik år 1920 med bildandet av American Professional Football Association (APFA), senare omdöpt till National Football League (NFL). NFL lockade många talangfulla spelare bort från collegen, vilket tvingade dem att höja sin nivå för att förbli konkurrenskraftiga. NFL blev också en betydande inkomst- och exponeringskälla för universitet, ofta hyrde deras arenor till professionella lag.
      <br/>
      <br/>
      I det här kapitlet har vi berättat mer om historien och utvecklingen av amerikansk fotboll. I nästa kapitel kommer vi att ge ytterligare insikter om amerikansk fotboll på universitetsnivå.
    </MKTypography>
  </MKBox>
</MKBox>







                                              





                                  







<MKBox id="Chapter 2" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Kapitel 2: Collegians Fotboll
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Collegians fotboll är en av de mest populära och konkurrenskraftiga sporterna i USA. Varje år tävlar mer än tusen lag och hundratusentals spelare om den nationella titeln, regionala mästerskap och prestigefyllda bowlspel. I det här kapitlet kommer vi att berätta mer om de stora ligorna och konferenserna inom collegians fotboll, och utvecklingen av spelare på collegenivå.
    </MKTypography>

    <MKBox id="2.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.1 Stora Ligor och Konferenser
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            En översikt över NCAA-divisionerna och de stora konferenserna inom collegians fotboll. NCAA är organisationen som ansvarar för att reglera och organisera collegians idrott i USA. NCAA delar in lag i tre divisioner: Division I, Division II och Division III. Division I är den högsta divisionen och består av två underavdelningar: Football Bowl Subdivision (FBS) och Football Championship Subdivision (FCS). FBS-lag är de mest prestigefyllda och lukrativa lagen, som tävlar om en plats i College Football Playoff, ett system som har avgjort mästerskapet sedan 2014. FCS-lag är mindre kända och rika men har sitt eget playoff-system för att avgöra sin mästare.
            <br/>
            <br/>
            Inom varje division och underavdelning finns det olika konferenser, som är grupper av lag som är geografiskt eller historiskt förbundna. Varje konferens har sin egen schema, regler och mästerskap. De stora konferenserna i FBS är: Atlantic Coast Conference (ACC), Big Ten Conference, Big 12 Conference, Pac-12 Conference, Southeastern Conference (SEC) och American Athletic Conference (AAC). De stora konferenserna i FCS är: Big Sky Conference, Colonial Athletic Association (CAA), Missouri Valley Football Conference (MVFC) och Southern Conference (SoCon).
            <br/>
            <br/>
            De mest prestigefyllda collegiansprogrammen och deras inverkan på sporten. Vissa colleges har en lång och framgångsrik historia inom collegians fotboll, som sträcker sig tillbaka till sportens tidiga år. De har producerat många nationella titlar, bowlspel, individuella utmärkelser och professionella spelare. De har också många fans, medieuppmärksamhet och ekonomiska resurser. Exempel på dessa colleges är: Alabama, Notre Dame, Ohio State, Oklahoma, USC, Michigan, Texas, Clemson, Florida State och LSU.
        </MKTypography>
    </MKBox>
    <MKBox id="2.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.2 Spelarutveckling
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Hur collegelag bidrar till spelarnas tekniska och fysiska utveckling. Collegelag erbjuder unga spelare möjligheten att förbättra sina färdigheter under ledning av erfarna tränare och personal. De tillhandahåller också tillgång till högkvalitativa faciliteter, utrustning och sjukvård. Dessutom exponerar de spelarna för en hög nivå av konkurrens, där de möter andra talangfulla spelare från hela landet.
            <br/>
            <br/>
            Exempel på kända spelare som slipade sina färdigheter på collegenivån. Många professionella spelare började sina karriärer på collegenivån, där de utmärkte sig för sin prestation och potential. Vissa spelare har till och med vunnit utmärkelser som Heisman Trophy, den mest prestigefyllda individuella utmärkelsen inom collegians fotboll. Exempel på dessa spelare inkluderar: Tom Brady (Michigan), Peyton Manning (Tennessee), Joe Montana (Notre Dame), Barry Sanders (Oklahoma State), Jerry Rice (Mississippi Valley State) och Tim Tebow (Florida).
            <br/>
            <br/>
            I det här kapitlet har vi berättat mer om collegians fotboll. I nästa kapitel kommer vi att berätta mer om vägen till NFL och andra professionella möjligheter.
        </MKTypography>
    </MKBox>
</MKBox>











                                              






                                  








<MKBox id="Chapter 3" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Kapitel 3: Från College till NFL
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Collegians fotboll är en trampsten till National Football League (NFL), den högsta professionella ligan i världen. Varje år väljs hundratals spelare av NFL-lag i NFL-draften, ett evenemang där lagen turas om att välja en spelare från en pool av collegespelare. I det här kapitlet kommer vi att berätta mer om vägen till NFL och andra professionella möjligheter.
    </MKTypography>

    <MKBox id="3.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.1 Vägen till NFL
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Hur collegians fotboll fungerar som en studsboll till National Football League (NFL). Collegians fotboll är den främsta källan till talang för NFL. De flesta spelarna i NFL har först spelat på collegenivå. Collegians fotboll ger spelarna chansen att bevisa sig mot andra toppidrottare, utveckla sina färdigheter, förbättra sina statistik och öka sitt utkastsvärde. Collegians fotboll ger också spelarna exponering och erkännande av scouter, tränare och media.
            <br/>
            <br/>
            Ungefär 250 spelare väljs varje år i NFL-draften, som vanligtvis äger rum i april. Draften består av sju rundor, varje med 32 val (ett för varje lag). Ordningen för valen bestäms av resultatet från föregående säsong, med det sämsta laget som väljer först och det bästa laget sist. Draften är en spännande och oberäknelig händelse, där vissa spelare väljs högre eller lägre än förväntat, och vissa inte väljs alls. Exempel på kända spelare som valts i NFL-draften inkluderar: John Elway (första valet 1983), Troy Aikman (första valet 1989), Peyton Manning (första valet 1998), Tom Brady (199:e valet 2000), Aaron Rodgers (24:e valet 2005) och Patrick Mahomes (10:e valet 2017).
        </MKTypography>
    </MKBox>
    <MKBox id="3.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.2 Andra Professionella Möjligheter
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Alternativa vägar till en professionell karriär inom amerikansk fotboll utanför NFL. Inte alla spelare som spelar på collegenivå når NFL. Vissa spelare väljs inte i utkastet, eller väljs men lyckas inte säkra en plats i ett lag. Vissa spelare skadar sig eller förlorar sin motivation eller passion. Men detta betyder inte att de måste ge upp sin dröm om att spela professionellt. Det finns andra professionella möjligheter utanför NFL, såsom:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Canadian Football League (CFL): Detta är en professionell liga som består av nio lag som spelar i Kanada. CFL har vissa skillnader från NFL, som en större plan, fler spelare per lag och färre downs per drive. CFL lockar många spelare som inte kan spela i NFL, men fortfarande vill spela på en hög nivå.
                </li>
                <li>
                    XFL: Detta är en ny professionell liga som etablerades 2020 som ett alternativ till NFL. XFL består av åtta lag som spelar i USA. XFL syftar till att erbjuda ett snabbare, mer spännande och mer innovativt spel än NFL. XFL erbjuder också fler möjligheter för spelare som har blivit förbisedda eller ignorerade av NFL.
                </li>
                <li>
                    European League of Football (ELF): Detta är en ny professionell liga som etablerades 2021 i ett försök att främja och utveckla amerikansk fotboll i Europa. ELF består av åtta lag som spelar i Tyskland, Polen, Spanien och Frankrike. ELF syftar till att erbjuda en plattform för europeiska spelare att visa upp sin talang och växa som idrottare.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            I det här kapitlet har vi berättat mer om vägen till NFL och andra professionella möjligheter. I nästa kapitel kommer vi att berätta mer om amerikansk fotbolls inflytande på amerikansk kultur.
        </MKTypography>
    </MKBox>
</MKBox>


                                              






                                  


<MKBox id="Chapter 4" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Kapitel 4: Påverkan av Amerikansk Fotboll på Amerikansk Kultur
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Amerikansk fotboll är inte bara en sport, utan också en kultur. Det är en betydande del av amerikansk identitet, värderingar och traditioner. Det är en källa till stolthet, passion och inspiration för miljontals människor. Det är också en industri som genererar miljarder dollar och skapar tusentals jobb. I det här kapitlet kommer vi att berätta mer om den kulturella påverkan av amerikansk fotboll och de förebilder och inspiration den erbjuder.
    </MKTypography>

    <MKBox id="4.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.1 Kulturell Påverkan
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image3}
                    alt="Utsikt från läktaren på den livliga och fullsatta Wisconsin college fotbollsstadion under en spännande match, med fans som hejar på sitt lag."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal3(Image3)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Speldag på den fullsatta stadion i Wisconsin
                </figcaption>
            </figure>
            Betydelsen av amerikansk fotboll i amerikansk samhälle och kultur. Amerikansk fotboll är en av de mest populära och inflytelserika sporterna i USA. Den attraherar miljoner fans, åskådare och medier varje år. Den är en del av många amerikaners vardag, utbildning, politik, ekonomi och underhållning. Den är också en symbol för nationell enhet, mångfald och stolthet.
            <br/>
            <br/>
            Stora evenemang och deras inverkan på den nationella identiteten, såsom Super Bowl. Super Bowl är den årliga mästerskapet för NFL, vanligtvis hållen i februari. Det är den största och mest sedda sportevenemanget i världen, med över 100 miljoner tittare bara i USA. Super Bowl är mer än ett sportevenemang; det är ett kulturellt fenomen. Det är en möjlighet för familj, vänner och kollegor att samlas, festa, satsa och heja. Det är också en scen för musik, reklam, konst och politik. Super Bowl ses ofta som en spegling av amerikansk kultur, värderingar och trender.
        </MKTypography>
    </MKBox>
    <MKBox id="4.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.2 Förebilder och Inspiration
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Amerikansk fotbollsspelare är ofta förebilder och källor till inspiration för unga idrottare som följer sin dröm om att spela på collegenivå eller på professionell nivå. Vissa spelare har extraordinära berättelser om motgång, uthållighet och framgång som motiverar och inspirerar andra. Vissa spelare påverkar också positivt sin gemenskap, sina fans och sitt land genom sina handlingar, ord och gärningar. Exempel på sådana spelare inkluderar: Colin Kaepernick (som knäböjde under nationalsången för att uppmärksamma rasism), Russell Wilson (som ledde sitt lag till Super Bowl som underdog), JJ Watt (som samlade in miljoner dollar till offren för orkanen Harvey), Drew Brees (som hjälpte sin stad New Orleans att återhämta sig efter orkanen Katrina) och Tom Brady (som fortfarande spelar på toppnivå vid 43 års ålder).
            <br/>
            <br/>
            I det här kapitlet har vi berättat mer om amerikansk fotbolls påverkan på amerikansk kultur. I nästa kapitel kommer vi att berätta mer om idrottsstipendier och framtiden för amerikansk fotboll.
        </MKTypography>
    </MKBox>
</MKBox>












                                              






                                  



<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Idrottsstipendier och Framtiden för Idrottare
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrottsstipendier erbjuder många möjligheter för unga idrottare att visa upp sin talang och utveckla sina färdigheter. En av de mest betydelsefulla möjligheterna är att få ett idrottsstipendium för att studera och tävla på collegenivå. I det här kapitlet kommer vi att diskutera vikten av idrottsstipendier och hur Sport Scholarships America kan hjälpa dig att förverkliga din dröm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vikten av Stipendier
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ett idrottsstipendium är ekonomiskt stöd som tillhandahålls av ett universitet till en idrottare för att kombinera studier och idrott. Det kan täcka kostnaderna för undervisning, böcker, boende, måltider och andra utgifter. Dessutom kan det erbjuda fördelar som akademisk handledning, sjukvård, träningsanläggningar och utrustning.
      <br />
      <br />
      Att få ett idrottsstipendium är konkurrenskraftigt, med många faktorer som spelar en roll i tilldelningsprocessen, inklusive akademiska prestationer, idrottsprestationer, personlighet, motivation, ledarskap och anpassningsförmåga. Därför är det viktigt att förbereda sig väl och skilja sig från konkurrensen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hur Sport Scholarships America Kan Hjälpa
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotyp för Sport Scholarships America, med sportutrustning och en studentmössa, som representerar fokus på idrottsstipendier för idrottare."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotyp för Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America är en organisation specialiserad på att vägleda unga idrottstalanger till idrottsstipendier. Med passionerade och erfarna experter stöder Sport Scholarships America idrottare i varje steg av resan, från initial orientering till slutlig placering. Deras tjänster inkluderar:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intagningsintervju för att diskutera idrottarens önskemål, möjligheter och förväntningar.</li>
        <li>En professionell utvärdering av idrottarens akademiska och idrottsliga nivå.</li>
        <li>Ett omfattande nätverk av kontakter med tränare, universitet och högskolor.</li>
        <li>Effektiv marknadsföring av idrottarens profil och videor till potentiella tränare.</li>
        <li>Expertrådgivning om de bästa alternativen baserat på idrottarens preferenser, mål och budget.</li>
        <li>Förhandling om de bästa stipendieerbjudandena för idrottaren.</li>
        <li>Hjälp med att ansöka om nödvändiga dokument, såsom visum, betyg, examensbevis och antagningsprov.</li>
        <li>Förberedelse för idrottarens liv under stipendieperioden, inklusive kultur, språk, regler och tips.</li>
        <li>Uppföljning och uppföljning under stipendieperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vill du utforska möjligheter till stipendier och uppnå dina idrottsliga och akademiska mål? <Link to="/sv/Tjänster-Produkter">Se det fullständiga utbudet</Link> av tjänster som erbjuds av Sport Scholarships America och boka en konsultation idag.
    </MKTypography>
  </MKBox>
</MKBox>




        









         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;