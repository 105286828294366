/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Produktseite: https://www.creative-tim.com/product/material-kit-pro-react
* Urheberrecht 2023 Creative Tim (https://www.creative-tim.com)

Kodiert von www.creative-tim.com

 =========================================================

* Der obige Urheberrechtshinweis und dieser Genehmigungsvermerk müssen in allen Kopien oder wesentlichen Teilen der Software enthalten sein.
*/

// @mui Materialkomponenten

import React from "react";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React Komponenten
import MKBox from "components/MKBox";

// Material Kit 2 PRO React Beispiele
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

function StatsOne() {
  return (
    <MKBox component="section" py={8}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard 
            count={100} 
            suffix="+"
            title="Studenten:"
            description="Erfolgreich an ihren Traumuniversitäten geführt"  
            onClick={() => console.log('Auf 100 geklickt')}
            />
          </Grid>
          <Grid item xs={12} md={4} >
            <DefaultCounterCard
              count={70}
              title="Universitäten:"
              suffix="+"
              description="Studenten erfolgreich in ganz Amerika platziert"
              onClick={() => console.log('Auf 70 geklickt')}

            />
          </Grid>
          <Grid item xs={12} md={4}>
          <Link to="/de/Alle-Sportarten">
              <DefaultCounterCard
                count={10}
                suffix="+"
                title="Sportarten:"
                description="Begleitung von Talenten in verschiedenen Disziplinen"
              />
            </Link>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default StatsOne;
