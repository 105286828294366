/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Rowing1.jpg";
import Image2 from "assets/images/Sports_Images/Rowing2.jpg";
import Image3 from "assets/images/Sports_Images/Rowing3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images

import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Étudier et ramer en Amérique : Une opportunité unique pour les jeunes rameurs', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Chapitre 1 : Histoire et développement de l\'aviron en Amérique', isSubheader: false },
    { id: '1.1', title: '1.1 Les Premières Années et le Rôle des Collèges', isSubheader: true },
    { id: '1.2', title: '1.2 Croissance et Traditions', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Chapitre 2 : L\'aviron au Niveau Universitaire Aujourd\'hui', isSubheader: false },
    { id: '2.1', title: '2.1 Principales Compétitions et Conférences', isSubheader: true },
    { id: '2.2', title: '2.2 Programmes et Équipes Leaders', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Chapitre 3 : Du Collège à l\'Élite', isSubheader: false },
    { id: '3.1', title: '3.1 Le Chemin vers le Sommet', isSubheader: true },
    { id: '3.2', title: '3.2 Opportunités Professionnelles et Internationales', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Chapitre 4 : Influences Internationales et Diversité', isSubheader: false },
    { id: '4.1', title: '4.1 Athlètes Internationaux dans l\'Aviron Universitaire', isSubheader: true },
    { id: '4.2', title: '4.2 Rameurs Américains à l\'Étranger', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Chapitre 5 : Bourses Sportives et l\'Avenir des Athlètes', isSubheader: false },
    { id: '5.1', title: '5.1 L\'Importance des Bourses', isSubheader: true },
    { id: '5.2', title: '5.2 Comment Sport Scholarships America Peut Aider', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Sommaire
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Cacher</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Afficher</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>























          <>
  <MKBox id="intro" mb={3}>
    <MKTypography variant="h1" className="h1-as-h3" mb={2}>
      Étudier et ramer en Amérique : Une opportunité unique pour les jeunes rameurs
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image1}
          alt="Rameurs en action lors du championnat national universitaire d'aviron."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal1(Image1)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Rameurs en action lors du championnat national
        </figcaption>
      </figure>
      L'aviron est l'un des sports les plus anciens et les plus prestigieux aux États-Unis. Depuis le 19e siècle, l'aviron de haut niveau est pratiqué dans les universités américaines, où les rameurs développent non seulement leur force physique et mentale, mais aussi leurs compétences académiques et sociales. Étudier et ramer en Amérique est une opportunité unique pour les jeunes rameurs qui veulent poursuivre leurs rêves sportifs tout en recevant une éducation précieuse et en vivant une expérience inoubliable. Dans cet article, nous vous dirons tout ce que vous devez savoir sur l'aviron en Amérique, de son histoire et ses traditions aux opportunités et défis. Nous expliquerons également comment Sportbeursamerika.nl peut vous aider à obtenir une bourse d'aviron et à vous préparer pour votre aventure.
    </MKTypography>
    {/* Ajouter une liste à puces ou d'autres contenus au besoin */}
  </MKBox>
  {/* Principaux chapitres et sous-chapitres avec du texte de substitution */}
  <MKBox id="Chapter 1" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
      Chapitre 1 : Histoire et développement de l'aviron en Amérique
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dans ce chapitre, nous plongeons dans l'histoire fascinante de l'aviron aux États-Unis. Nous explorons comment ce sport s'est propagé de l'Angleterre à travers l'océan pour devenir une partie intégrante de la culture universitaire américaine. Des premières courses d'aviron sur le lac Winnipesaukee aux rivalités emblématiques entre universités prestigieuses, nous examinons le développement de l'aviron comme un sport qui incarne à la fois l'agilité physique et la fierté académique.
    </MKTypography>
    <MKBox id="1.1" mb={2}>
      <MKTypography variant="h4" mb={1}>
        1.1 Les Premières Années et le Rôle des Collèges
      </MKTypography>
      <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        L'aviron en tant que sport a son origine en Angleterre au 18e siècle, lorsque des courses étaient organisées entre des rameurs professionnels sur la Tamise. Ces courses attiraient de nombreux spectateurs et des paris, rendant rapidement l'aviron populaire. Aux 18e et 19e siècles, l'aviron s'est propagé dans d'autres pays, dont les États-Unis. La première course d'aviron américaine a eu lieu en 1843 sur le lac Winnipesaukee, dans le New Hampshire. En 1852, la première course d'aviron intercollégiale a eu lieu entre Harvard et Yale sur le lac Winnipesaukee. Il s'agissait également du premier événement sportif intercollégial aux États-Unis. Depuis lors, l'aviron est devenu une partie importante du sport universitaire américain.
        <br />
        <br />
        Le rôle des collèges dans la promotion de l'aviron était crucial. Les collèges voyaient dans l'aviron un moyen d'inculquer la discipline, le travail d'équipe et le leadership à leurs étudiants tout en améliorant leur prestige et leur réputation. Les collèges investissaient une somme importante d'argent et de ressources dans la construction de hangars à bateaux, l'acquisition de bateaux et l'attraction d'entraîneurs. Ils organisaient également régulièrement des compétitions contre d'autres collèges, tant au niveau régional que national. Ces compétitions étaient souvent très suivies par les étudiants, les anciens élèves, les médias et les sponsors, suscitant un grand intérêt et une grande implication dans le sport.
      </MKTypography>
    </MKBox>
    <MKBox id="1.2" mb={2}>
      <MKTypography variant="h4" mb={1}>
        1.2 Croissance et Traditions
      </MKTypography>
      <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        L'aviron a progressé régulièrement en popularité et en prestige dans la seconde moitié du 19e siècle et la première moitié du 20e siècle. Diverses compétitions régionales et nationales ont émergé, telles que les Eastern Sprints, le Championnat National de l'IRA, le Regatta Dad Vail et le Head of the Charles Regatta. Les rameurs américains ont également participé à des événements internationaux, notamment le Henley Royal Regatta en Angleterre et les Jeux Olympiques. Les rameurs américains ont souvent obtenu des résultats impressionnants, remportant l'or lors de huit Jeux Olympiques consécutifs entre 1920 et 1956.
        <br />
        <br />
        L'aviron a également développé une culture et des traditions riches dans les collèges américains. Chaque université avait ses propres couleurs, mascotte, chansons et rituels associés à l'aviron. Certaines traditions étaient ludiques ou symboliques, comme jeter le barreur à l'eau après une victoire ou porter une cravate ou une épingle spéciale. D'autres traditions étaient sérieuses ou cérémonielles, comme chanter l'hymne de l'école ou organiser un mémorial pour les rameurs décédés. Ces traditions ont contribué au sentiment de fierté, de camaraderie et d'identité parmi les rameurs.
      </MKTypography>
    </MKBox>
  </MKBox>
</>





                                  






<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 2 : L'aviron au Niveau Universitaire Aujourd'hui
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Dans ce chapitre, nous nous concentrons sur l'aviron contemporain au niveau universitaire en Amérique. Nous examinons comment le sport a évolué pour devenir une partie hautement compétitive et prestigieuse du paysage sportif collégial. Ici, nous discutons des principales compétitions et conférences, de la structure des divisions de la NCAA, et jetons un coup d'œil sur certains des principaux programmes universitaires d'aviron. Cette section met en lumière l'état actuel de l'aviron dans les universités américaines et les opportunités uniques qu'il offre aux jeunes athlètes.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Principales Compétitions et Conférences
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="L'équipe d'aviron de Stanford célèbre sa victoire au championnat national."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Les rameurs de Stanford célèbrent le championnat national
        </figcaption>
      </figure>
      L'aviron au niveau universitaire reste un sport hautement compétitif et prestigieux aux États-Unis aujourd'hui. Il y a plus de 300 universités qui proposent des programmes d'aviron, avec environ 150 participant à la NCAA (National Collegiate Athletic Association), l'organisation principale pour les sports universitaires. La NCAA divise les universités en trois divisions en fonction du nombre d'étudiants, du budget et du niveau du sport. La Division I est la division la plus élevée, présentant les programmes d'aviron les plus robustes et compétitifs. Les Divisions II et III ont moins de programmes d'aviron mais sont toujours très compétitives.
      <br />
      <br />
      Au sein de chaque division, il existe différentes conférences, qui sont des groupes d'universités qui sont géographiquement ou historiquement liées. Chaque conférence organise son propre championnat, où les meilleures équipes se qualifient pour les championnats nationaux. Les principales conférences pour l'aviron incluent la Big Ten, la Pac-12, la Ivy League, l'ACC et la Big 12. Ces conférences ont souvent les programmes d'aviron les plus réussis et influents du pays.
      <br />
      <br />
      En plus des championnats de conférence, il existe d'autres régates nationales importantes auxquelles les universités peuvent participer. La plus prestigieuse est le Championnat d'Aviron Féminin de la NCAA, organisé depuis 1997 pour les rameuses des trois divisions. Pour les rameurs masculins, il n'y a pas de championnat équivalent de la NCAA, mais il y a le Championnat National d'Aviron de l'IRA, organisé depuis 1895 pour les équipes de varsity et de lightweight. D'autres régates nationales bien connues incluent la Dad Vail Regatta, la San Diego Crew Classic, le Championnat National d'Aviron de l'ACRA et les CRASH-B Sprints.
    </MKTypography>
  </MKBox>

  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programmes et Équipes Leaders
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      L'aviron au niveau universitaire a une longue histoire de rivalité, d'excellence et d'innovation. De nombreuses universités se sont distinguées par leurs programmes d'aviron, tant en termes de performance que de développement. Certaines de ces universités incluent :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Université de Washington : Les Huskies sont connus pour leur programme d'aviron puissant et respecté. Avec plusieurs championnats nationaux en aviron masculin et une histoire de production de médaillés olympiques, dont le légendaire "Boys in the Boat" qui a remporté l'or en 1936. Les Huskies sont célébrés pour leur entraînement rigoureux, leur travail d'équipe solide et leurs entraîneurs influents comme Al Ulbrickson.
        </li>
        <li>
          Université Yale : En tant que pionnière de l'aviron intercollégial, les Bulldogs de Yale ont un programme d'aviron ancien et prestigieux. Leur histoire comprend plusieurs titres nationaux et une contribution significative à l'aviron américain au niveau olympique. Yale est également connue pour son excellence académique et sa rivalité historique avec Harvard.
        </li>
        <li>
          Université de Californie, Berkeley : Les Golden Bears ont un programme d'aviron réussi et polyvalent, distingué par leurs titres nationaux et leurs participants aux Jeux Olympiques. Le programme est connu pour sa diversité, son implication communautaire et ses entraîneurs dévoués.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>








                                  







<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 3 : Du Collège à l'Élite
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Dans ce chapitre, nous explorons le parcours des rameurs universitaires vers les rangs d'élite du monde de l'aviron. Nous examinons comment les expériences et les compétences acquises par les rameurs au niveau universitaire les préparent aux niveaux de compétition supérieurs, y compris la participation aux équipes nationales et aux Jeux Olympiques. Ce chapitre met en lumière la transition de l'aviron collégial aux scènes professionnelles et internationales, en se concentrant sur les parcours suivis par les athlètes, les défis auxquels ils font face et les succès de ceux qui atteignent le sommet. Nous examinons également comment les programmes d'aviron universitaires identifient et développent les talents, ouvrant la voie aux futurs Olympiens et champions du monde.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Le Chemin vers le Sommet
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      L'aviron au niveau universitaire n'est pas seulement un défi sportif mais aussi un tremplin potentiel vers les échelons d'élite du monde de l'aviron. De nombreux rameurs qui ont pratiqué l'aviron au niveau collégial passent aux équipes nationales et à l'aviron olympique. Cela s'explique par le fait que l'aviron universitaire offre une excellente préparation en termes de technique, de tactique, de condition physique et mentale, et de travail d'équipe. De plus, l'aviron universitaire offre la possibilité de se démarquer aux yeux des recruteurs et des entraîneurs des associations et sélections nationales.
      <br />
      <br />
      Il existe de nombreux exemples de rameurs qui sont passés du collège au niveau élite. Un exemple récent d'une rameuse qui a remporté une médaille olympique après sa carrière universitaire est Madison Mailey. Mailey, une rameuse All-American à l'Université Northeastern, diplômée en 2018, a remporté une médaille d'or aux Jeux olympiques de Tokyo dans le huit féminin pour le Canada. Elle et ses coéquipières ont pris une avance précoce et ont résisté à un défi tardif de la Nouvelle-Zélande, remportant avec une marge de seulement 0,91 seconde.
    </MKTypography>
  </MKBox>

  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Opportunités Professionnelles et Internationales
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      L'aviron universitaire ouvre la porte à des opportunités professionnelles et internationales pour les rameurs. En plus de la possibilité de participer aux Jeux olympiques, les rameurs universitaires peuvent utiliser leurs compétences pour concourir dans des régates internationales prestigieuses telles que la Henley Royal Regatta ou la World Rowing Cup. Ces événements offrent une plateforme aux rameurs pour se mesurer aux meilleurs du monde et acquérir de l'expérience dans un contexte international.
      <br />
      <br />
      De plus, il existe un chemin vers l'aviron professionnel où les diplômés universitaires peuvent rejoindre des équipes professionnelles et participer à des compétitions mondiales. Cela offre une carrière d'aviron à temps plein avec un entraînement intensif et la possibilité de concourir au plus haut niveau.
      <br />
      <br />
      Pour ceux intéressés par une carrière d'entraîneur, l'aviron universitaire offre une base solide. De nombreux entraîneurs d'aviron à succès sont d'anciens athlètes collégiaux qui utilisent leur expérience et leur expertise pour encadrer les nouvelles générations de rameurs. Ce chemin de carrière peut mener à des postes avec les équipes nationales et les clubs d'aviron du monde entier.
      <br />
      <br />
      En résumé, les expériences acquises dans l'aviron universitaire peuvent conduire à une gamme d'opportunités professionnelles et internationales, des athlètes poursuivant une carrière professionnelle à ceux qui continuent le sport dans un rôle d'entraîneur.
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  






<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 4 : Influences Internationales et Diversité
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Ce chapitre met en lumière les dynamiques internationales et la diversité au sein de l'aviron universitaire aux États-Unis. Nous explorons comment les étudiants-athlètes internationaux enrichissent le sport avec leurs antécédents et perspectives uniques, et comment les rameurs américains trouvent des opportunités pour ramer à l'étranger et se développer davantage. Ces interactions contribuent à une expérience culturelle plus riche et élargissent les horizons des rameurs américains et internationaux. Nous examinons les différents chemins empruntés par les étudiants internationaux pour ramer au niveau universitaire aux États-Unis, les avantages de ces expériences, et l'impact qu'elles ont sur le sport et ses participants. De plus, nous nous concentrons sur les opportunités pour les rameurs américains de gagner une expérience internationale, y compris les programmes d'échange d'étudiants, les camps d'entraînement internationaux, et les opportunités d'entraînement. Ce chapitre souligne l'importance des influences internationales dans l'enrichissement de l'aviron universitaire et la promotion de l'échange culturel et de la compréhension.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Athlètes Internationaux dans l'Aviron Universitaire
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      L'aviron universitaire n'est pas seulement une affaire américaine mais aussi internationale. De nombreux étudiants internationaux viennent aux États-Unis pour étudier et ramer dans les universités américaines. Ces étudiants apportent leur propre culture, leur contexte et leur perspective, contribuant ainsi à la diversité et à la qualité des équipes. De plus, ils offrent une opportunité d'échange culturel et d'amitié entre les rameurs de différents pays.
      <br />
      <br />
      Il y a plusieurs raisons pour lesquelles les étudiants internationaux choisissent l'aviron universitaire aux États-Unis. Certaines de ces raisons incluent :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Bourses d'Aviron : De nombreuses universités américaines offrent des bourses d'aviron aux rameurs talentueux, ce qui peut réduire voire éliminer leurs frais de scolarité. C'est un incitatif significatif pour les étudiants internationaux qui sinon pourraient ne pas pouvoir étudier aux États-Unis.
        </li>
        <li>
          Excellence Académique : De nombreuses universités américaines sont connues pour leurs normes académiques élevées et leur réputation. Cela attire les étudiants internationaux cherchant une excellente éducation et un diplôme reconnu mondialement.
        </li>
        <li>
          Défi Sportif : De nombreux étudiants internationaux voient l'aviron universitaire comme un défi sportif et une opportunité de rivaliser avec les meilleurs rameurs du monde. Ils visent à améliorer leurs compétences, repousser leurs limites, et réaliser leur potentiel.
        </li>
        <li>
          Développement Personnel : De nombreux étudiants internationaux voient l'aviron universitaire comme un développement personnel et une chance de grandir en tant qu'individus. Ils veulent acquérir de nouvelles expériences, rencontrer de nouvelles personnes, apprendre de nouvelles cultures, et élargir leurs horizons.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      "Niki van Sprang est un exemple d'un étudiant international qui a ramé au niveau universitaire aux États-Unis. Van Sprang, originaire des Pays-Bas où il a remporté de multiples titres nationaux, a reçu une bourse d'aviron de l'Université de Californie à Berkeley. Là-bas, il a rejoint l'équipe varsity et a connu un succès significatif. Après avoir obtenu son diplôme en 2017, Van Sprang est retourné aux Pays-Bas et est devenu membre de l'équipe nationale d'aviron. Il a participé aux Jeux olympiques de Tokyo 2020, où lui et Guillaume Krommenhoek ont terminé 7èmes dans la paire sans barreur masculin. De plus, il a remporté l'argent aux Championnats du Monde 2022 à Račice et aux Championnats d'Europe 2022 à Munich, ainsi qu'une médaille de bronze aux Championnats d'Europe 2023 à Bled, tous dans le huit masculin."
    </MKTypography>
  </MKBox>

  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Rameurs Américains à l'Étranger
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      L'aviron universitaire peut également ouvrir des opportunités internationales pour les étudiants-athlètes américains qui souhaitent élargir ou changer leurs horizons sportifs. Il existe diverses opportunités pour les rameurs américains de ramer à l'étranger et de gagner de l'expérience. Certaines de ces opportunités incluent :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Programme d'Échange : Certaines universités américaines ont des programmes d'échange avec des universités étrangères, permettant aux étudiants de étudier et de ramer dans un autre pays pendant un semestre ou une année. Cela leur donne l'occasion de vivre une culture, une langue, et un système éducatif différents, ainsi que de ramer avec d'autres équipes et entraîneurs.
        </li>
        <li>
          Camps d'Entraînement : Certaines universités américaines organisent des camps d'entraînement à l'étranger, généralement pendant les vacances d'hiver ou d'été. Cela leur donne l'occasion de s'entraîner dans un environnement, un climat, et des conditions d'eau différents, ainsi que de concourir contre d'autres équipes ou de participer à des régates locales.
        </li>
        <li>
          Entraînement : Certains rameurs américains décident d'entraîner à l'étranger après avoir obtenu leur diplôme, généralement dans des écoles, des clubs, ou des équipes nationales. Cela leur donne l'occasion de partager leurs connaissances et leur expérience, ainsi que d'apprendre d'autres entraîneurs et cultures.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  

<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 5 : Bourses Sportives et l'Avenir des Athlètes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Les bourses sportives offrent de nombreuses opportunités aux jeunes athlètes de montrer leur talent et de développer leurs compétences. L'une des opportunités les plus significatives est d'obtenir une bourse sportive pour étudier et compétitionner au niveau collégial. Dans ce chapitre, nous discuterons de l'importance des bourses sportives et de comment Sport Scholarships America peut vous aider à réaliser votre rêve.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 L'Importance des Bourses
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Une bourse sportive est un soutien financier fourni par une université à un étudiant-athlète pour combiner études et sport. Elle peut couvrir les frais de scolarité, les livres, le logement, les repas et autres dépenses. De plus, elle peut offrir des avantages tels que du tutorat académique, des soins médicaux, des installations d'entraînement et du matériel.
      <br />
      <br />
      Obtenir une bourse sportive est compétitif, avec de nombreux facteurs qui influent sur le processus d'attribution, notamment les performances académiques, sportives, la personnalité, la motivation, le leadership et l'adaptabilité. Il est donc important de bien se préparer et de se démarquer de la concurrence.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Comment Sport Scholarships America Peut Aider
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo de Sport Scholarships America, représentant du matériel sportif et une casquette de graduation, symbolisant le focus sur les bourses sportives pour les étudiants-athlètes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America est une organisation spécialisée dans l'orientation des jeunes talents sportifs vers des bourses sportives. Avec des experts passionnés et expérimentés, Sport Scholarships America soutient les étudiants-athlètes à chaque étape du parcours, de l'orientation initiale à la placement final. Leurs services comprennent :
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Un entretien personnel pour discuter des souhaits, des possibilités et des attentes de l'étudiant-athlète.</li>
        <li>Une évaluation professionnelle du niveau académique et sportif de l'étudiant-athlète.</li>
        <li>Un vaste réseau de contacts avec des entraîneurs, des universités et des collèges.</li>
        <li>Promotion efficace du profil et des vidéos de l'étudiant-athlète auprès des entraîneurs potentiels.</li>
        <li>Conseils d'experts sur les meilleures options en fonction des préférences, des objectifs et du budget de l'étudiant-athlète.</li>
        <li>Négociation des meilleures offres de bourses au nom de l'étudiant-athlète.</li>
        <li>Aide à la demande de documents nécessaires, tels que visa, relevé de notes, diplôme et tests d'admission.</li>
        <li>Préparation à la vie d'étudiant-athlète pendant sa bourse, y compris culture, langue, règles et conseils.</li>
        <li>Suivi et accompagnement pendant la période de la bourse.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vous voulez explorer les opportunités de bourses et atteindre vos objectifs sportifs et académiques ? <Link to="/fr/Services-Produits">Consultez la gamme complète</Link> des services offerts par Sport Scholarships America et planifiez une consultation dès aujourd'hui.
    </MKTypography>
  </MKBox>
</MKBox>
          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;