/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================
* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post2 from "assets/images/TijdUS.png";
import "../../../../H1asH3.css"

function Profile() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center" />
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} justifyContent="space-between" alignItems="center" mb={1}>
                <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Part 1: Growing Up in the Netherlands</MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text">
              <br />
  I de fortryllende omgivelsene til Nijmegen begynte mitt livslange kjærlighetsforhold til fotball. Som et barn fra gatene i Nijmegen følte jeg magien med sporten med hvert skritt jeg tok på den grønne gresset. Min første klubb var BVC '12, der jeg som en ung gutt opplevde mine første driblinger og mål. Det var en tid med uskyld, der jeg oppdaget min lidenskap for sporten, og der grunnlaget for min fremtid ble lagt.
  <br /><br />

  Etter mitt eventyr på BVC '12 begav jeg meg ut på en reise gjennom lokale fotballklubber, som Quick, NEC og Union. Denne reisen dannet grunnlaget for min fotballutdanning og brakte meg nærmere drømmen min: å nå toppen av nederlandsk ungdomsfotball. Som ung fotballspiller drømte jeg om å spille på store stadioner, omgitt av et hav av jublende fans. Det var en drøm delt av mange unge talenter i Nederland, og jeg var fast bestemt på å gjøre alt for å gjøre denne drømmen til virkelighet.
  <br /><br />

  Men mens jeg utviklet fotballtalentet mitt, begynte jeg også å innse hvor avgjørende god utdanning er for en solid fremtid. I Nederland virket ambisjonene mine innen fotball og utdanning noen ganger motstridende. Det var en utfordring å balansere begge lidenskapene.
  <br /><br />

  Mangfoldet og de enorme mulighetene i Amerika hadde alltid fascinert meg, og denne fascinasjonen vokste bare sterkere under en ferie med familien. Jeg var nesten sikker på at jeg ville vende tilbake til dette landet, kanskje for en lengre periode. Senere, gjennom bekjentskaper, hørte jeg historier om mulighetene for å kombinere en fotballkarriere med universitetsstudier i Amerika, og ønsket mitt om å følge den samme veien vokste eksponentielt.
  <br /><br />

  Likevel var det ikke enkelt å ordne alt. Den komplekse prosessen med idrettsstipender og studiemuligheter i Amerika virket først skremmende. Et sted visste jeg at det kunne være enklere og mer tilgjengelig, noe som til slutt ble min motivasjon for å starte dette selskapet år senere.
  <br /><br />

  Etter å ha kontaktet ulike trenere hadde treneren ved University of Tulsa en spesielt overbevisende historie å dele. Han snakket om et lag som alltid konkurrerte om premier, konsekvent rangert blant de 25 beste i landet og deltatt i store konkurranser. Hans ord ga meg selvtillit til at dette var stedet der jeg kunne oppfylle både mine fotballdrømmer og akademiske ambisjoner.
  <br /><br />

  Og slik skjedde det, to uker etter at jeg fylte 18 år, var kapittelet mitt i Nederland (midlertidig) over. Jeg gikk om bord i flyet til Amerika, klar for det eventyret som ventet. Fast bestemt på å forene lidenskapene mine for fotball og utdanning, sto jeg i begynnelsen av en spennende reise. Mer om mine opplevelser i Amerika vil jeg gjerne dele med deg i del 2 av min historie, der jeg går dypere inn i mine eventyr i USA.
</MKTypography>
<hr style={{ margin: '20px 0'}} />

<MKTypography component="a" href="#" variant="body1" fontWeight="light" color="info" mt={3}>
  <Grid container justifyContent="flex-end"> {/* This line is updated */}
    <Grid item xs={12} lg={6} mb={{ xs: 3, lg: 0 }}>
      <TransparentBlogCard
        image={post2}
        title="Part 2: My Time in America"
        action={{
          type: "internal",
          route: "/no/Om-meg/Min-tid-i-Amerika",
          color: "info",
          label: "read more",
        }}
      />
    </Grid>
  </Grid>
</MKTypography>

</Grid>
</Grid>
</Grid>
</Container>
</MKBox>
);
}

export default Profile;
