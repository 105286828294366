/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Página do Produto: https://www.creative-tim.com/product/material-kit-pro-react
* Direitos Autorais 2023 Creative Tim (https://www.creative-tim.com)

Codificado por www.creative-tim.com

 =========================================================

* O aviso de direitos autorais acima e este aviso de permissão devem ser incluídos em todas as cópias ou partes substanciais do software.
*/

// @mui componentes de material
import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// import Stack from "@mui/material/Stack";

// Componentes Material Kit 2 PRO React
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import minhaImagem from "assets/images/SoccerfotoKayDuit1.png";
import segundaImagem from "assets/images/SoccerfotoKayDuit2.png"

function Testimonials() {
  const [imagemAtual, setImagemAtual] = useState(minhaImagem);
  const [opacidade, setOpacidade] = useState(1);

  const meuAltDaImagem = "Kay Duit em ação durante um jogo de futebol, jogando pela University of Tulsa, dinâmico e focado na bola"; // Substitua pelo seu texto alternativo real
  const segundoAltDaImagem = "Kay Duit como jogador de futebol na University of Tulsa, ativo em uma jogada no campo de futebol."; // Substitua pelo seu texto alternativo real

  useEffect(() => {
    const alternarImagem = () => {
      setOpacidade(0); // começa a desaparecer
      setTimeout(() => {
        setImagemAtual(anterior => anterior === minhaImagem ? segundaImagem : minhaImagem); // troca de imagem após metade da duração
        setOpacidade(1); // começa a aparecer
      }, 500); // esta é metade da duração para a transição
    };

    const intervalo = setInterval(alternarImagem, 10000); // trocar a cada 10 segundos

    return () => clearInterval(intervalo);
  }, []);

  return (
    <MKBox component="section" position="relative" py={8}>
        <Container>
          <Grid container justifyContent="center" sx={{ pt: 0, pb: 0 }}>
            <Grid item xs={12} md={7} sx={{ textAlign: "center" }}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom mb={3}>
            Minha História: Da Paixão à Profissão                        </MKTypography>
            <MKTypography color="text" variant="body2" align="center" gutterBottom mb={14}>
            Como minha experiência no mundo esportivo americano agora é a chave para orientar jovens talentos ao sucesso.               </MKTypography>

              
            </Grid>
          </Grid>
        </Container>
     
      <Container sx={{ maxWidth: "100% !important" }}>
      
      <Grid container item xs={12} sm={10} sx={{ mx: "auto" }}>
          
          <MKBox variant="gradient" bgColor="dark" borderRadius="lg" width="100%" py={6}>
            <Grid container>
              
              <Grid item xs={12} md={6} xl={4} sx={{ position: "relative", px: 6 }}>
              <MKBox
                component="img"
                src={imagemAtual}
                alt={imagemAtual === minhaImagem ? meuAltDaImagem : segundoAltDaImagem}
                borderRadius="md"
                maxWidth="300px"
                width="100%"
                position="relative"
                mt={-12}
                style={{ transition: 'opacity 1s', opacity: opacidade }} // estilo em linha para a transição
                    />
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                xl={4}
                sx={{
                  position: "relative",
                  px: { xs: 6, md: 2 },
                  mt: { xs: 3, md: 0 },
                  my: { xs: 0, md: "auto" },
                  color: ({ palette: { white } }) => white.main,
                }}
              >
                <Icon fontSize="large">format_quote</Icon>
                <MKTypography
                  variant="body2"
                  color="white"
                  fontWeight="light"
                  mb={2}
                  sx={{ fontSize: "1.125rem" }}
                >
Como ex-jogador de futebol da University of Tulsa, tive o privilégio de vivenciar quatro anos inesquecíveis no coração da cultura estudantil americana. Essa paixão e experiência agora me impulsionam a orientar estudantes europeus em sua própria jornada no futebol nos EUA. Juntos, tornaremos seu sonho esportivo americano realidade.
                </MKTypography>
                <MKTypography variant="button" fontWeight="bold" color="white">
                  Kay Duit -{" "}
                  <MKTypography variant="caption" color="white">
                    Fundador
                  </MKTypography>
                </MKTypography>
              </Grid>
              <Grid item xs={1} />
              <Grid
                item
                xs={12}
                xl={2}
                sx={{ px: { xs: 6, xl: 0 }, my: { xs: 0, xl: "auto" }, lineHeight: 1 }}
              >
                <MKTypography variant="h5" color="white" mt={{ xs: 6, xl: 0 }} mb={1}>
                4 anos vencendo cartas
                <br />
                3x Campeão da AAC
                              
                </MKTypography>
                
                <MKTypography
                  component="a"
                  href="/pt/Sobre-Mim"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  sx={{
                    width: "máximo-conteúdo",
                    display: "flex",
                    alignItems: "centro",


                    "& .material-icons-round": {
                      fontSize: "1.125rem",
                      transform: `traduzirX(3px)`,
                      transição: "transformar 0,2s cúbica-bezier(0,34, 1,61, 0,7, 1,3)",
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `traduzirX(6px)`,
                    },
                  }}
                >
                  Leia mais
                  <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MKTypography>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Testimonials;
