import { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import SimplePricingCard from "examples/Cards/PricingCards/SimplePricingCard";
import SimplePricingCardRibbon from "examples/Cards/PricingCards/SimplePricingCardRibbon";
import { PopupButton } from "react-calendly";
import { Link } from 'react-scroll';
import TextField from "@mui/material/TextField";  // Adicione esta linha
import Box from '@mui/material/Box'; // Certifique-se de importar o Box
import emailjs from 'emailjs-com';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Link as RouterLink } from 'react-router-dom';

function Serviços() {
  const [abaAtiva, setAbaAtiva] = useState(0);
  const [tipoAba, setTipoAba] = useState("mensal");
  const [mostrarModal, setMostrarModal] = useState(false);
  const [mostrarModalEmail, setMostrarModalEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [estaConcordando, setEstaConcordando] = useState(false);

  const alternarModal = () => setMostrarModal(!mostrarModal);

  const lidarComTipoAba = ({ currentTarget }, novoValor) => {
    setAbaAtiva(novoValor);
    setTipoAba(currentTarget.id);
  };

  const lidarComEnvioEmail = async () => {
    if (email && estaConcordando) {
      const parametrosEmail = {
        from_email: email,
        // outros parâmetros, se necessário
      };
  
      emailjs.send('service_sj82src', 'template_2eyxhno', parametrosEmail, 'xOBnOFlYt964jvT3q')
        .then((resposta) => {
          console.log('Email enviado com sucesso', resposta.status, resposta.text);
          alert('Link solicitado, verifique sua caixa de entrada.');
          setMostrarModalEmail(false);
          setEmail("");
          setEstaConcordando(false);
        }, (erro) => {
          console.log('Falha ao enviar o email', erro);
          alert('Solicitação falhou, tente novamente.');
        });
    } else if (!estaConcordando) {
      alert('Por favor, concorde com a política de privacidade.');
    } else {
      alert('Por favor, insira um endereço de email.');
    }
  };

  return (
    <section id="seçãoDeServiços">
      <Container sx={{ pb: { xs: 4, lg: 4 }, pt: 12 }}>
          {/* Modal de Entrada de Email */}
          <Modal
        open={mostrarModalEmail}
        onClose={() => setMostrarModalEmail(false)}
        aria-labelledby="modal-modal-título"
        aria-describedby="modal-modal-descrição"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: { xs: '90%', sm: '70%', md: '50%', lg: '40%' },
            maxWidth: '500px',
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <TextField
            label="Endereço de Email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ mb: 2 }}
          />
          {/* Caixa de seleção para concordar com a política de privacidade */}
          <FormControlLabel
  control={<Checkbox checked={estaConcordando} onChange={(e) => setEstaConcordando(e.target.checked)} />}
  label={
    <span>
       Eu li a{' '}
      <RouterLink 
        to="/Privacy-Policy" 
        component="a" 
        target="_blank" 
        style={{ textDecoration: 'none' }}>
         política de privacidade
      </RouterLink> e concordo com ela.
    </span>
  }
  sx={{ my: 2 }}
/>

          <MKButton variant="gradient" color="dark" onClick={lidarComEnvioEmail}>
            Enviar
          </MKButton>
        </Box>
      </Modal>

      <Grid container item xs={12} justifyContent="center" md={8} mx="auto" textAlign="center">
      <Grid item xs={12}> {/* Largura total para o título */}
    <MKTypography variant="h3" color="text">Serviços</MKTypography>
  </Grid>
  <Grid item xs={12}> {/* Largura total para o texto principal */}
    <MKTypography variant="body2" color="text">
    Todos os serviços oferecidos pela Sport Scholarships America
</MKTypography>
  </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={10}
          lg={8}
          justifyContent="center"
          textAlign="center"
          mx="auto"
          mt={6}
        >
          <AppBar position="static">
          <Tabs value={abaAtiva} onChange={lidarComTipoAba}>
  <Tab
    id="mensal"
    label={
      <Link 
        to="seçãoDeServiços" 
        spy={true}
        smooth={true}
        duration={500}
        offset={-70}
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <MKBox py={0.5} px={2} color="inherit">
          Serviços
        </MKBox>
      </Link>
    }
  />
  <Tab
    id="anual"
    label={
      <Link 
        to="seçãoDeProdutos" 
        spy={true}
        smooth={true}
        duration={500}
        offset={-70}
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <MKBox py={0.5} px={2} color="inherit">
          Produtos
        </MKBox>
      </Link>
    }
  />
</Tabs>
          </AppBar>
        </Grid>
        <Grid container spacing={3} mt={6}>
        <Modal open={mostrarModal} onClose={alternarModal} sx={{ display: "grid", placeItems: "center" }}>
          <Slide direction="down" in={mostrarModal} timeout={500}>
            <MKBox
              position="relative"
              width="500px"
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              bgColor="white"
              shadow="xl"
            >
              <MKBox display="flex" alginItems="center" justifyContent="space-between" p={2}>
                <MKTypography variant="h5">
Escolha uma Conversa de Avaliação
</MKTypography>
                <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={alternarModal} />
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox p={2}>
                <MKTypography variant="body2" color="secondary" fontWeight="regular">
                Prezado cliente,
                <br />
                  <br />

                  Fico feliz que você tenha interesse em meu serviço padrão! Para garantir que meu serviço se ajuste perfeitamente às suas necessidades específicas, recomendo fortemente que você reserve primeiro uma conversa de avaliação sem compromisso.
                  <br />
                  <br />
                  Durante esta conversa, podemos discutir detalhadamente sua situação e determinar juntos a abordagem que melhor se adapta a você. Isso proporciona uma experiência mais personalizada e me ajuda a otimizar o serviço para a sua situação única.


                </MKTypography>
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox display="flex" justifyContent="space-between" p={1.5}>
                <MKButton variant="gradient" color="dark" onClick={alternarModal}>
                  Fechar
                </MKButton>
                
                <PopupButton
    url="https://calendly.com/kay-duit"
    rootElement={document.getElementById("root")}
    text="AGENDAR COMPROMISSO"
    className="custom-calendly-button"
  />
              </MKBox>
            </MKBox>
          </Slide>
        </Modal>
        <Grid item xs={12} sm={6} lg={3}>
  <SimplePricingCard
    color="dark"
    title="Guia de Informações"
    description="Guia completo de informações em PDF"
    price={{ value: "Grátis", type: "" }}
    action={{
      type: "downloadPDF",
      label: "Baixar PDF",
      onClick: () => setMostrarModalEmail(true) // Função para mostrar o modal de entrada de email
    }}
    specifications={["Guia completo de informações em PDF"]}
  />
</Grid>
<Grid item xs={12} sm={6} lg={3}>
  <SimplePricingCardRibbon
    color="dark"
    title="Conversa de Avaliação"
    description="Consulta online de 60 minutos"
    price={{ value: " € 14,99*", type: "" }}
    action={{
      type: "external", // Alterar para external para o Calendly
      route: "https://calendly.com/kay-duit", // Seu link do Calendly
      label: "AGENDAR COMPROMISSO"
    }}
    specifications={[
      "Alinhamento de metas e ambições",
      "Avaliação de desempenho atlético",
      "Plano de integração acadêmica",
      "Estratégia de trajetória individual",
      "Análise das chances de sucesso nos EUA"
    ]}
  />
</Grid>

          <Grid item xs={12} sm={6} lg={3}>
  <SimplePricingCard
    color="dark"
    title="Padrão"
    description="Seu caminho para os EUA"
    price={{ value: "Sob Consulta", type: "" }}
    action={{
      type: "modal",
      label: "Solicitar",
      onClick: alternarModal
    }}
    specifications={[
      "Plano personalizado",
      "Apoio na criação de um portfólio esportivo",
      "Apoio na preparação acadêmica",
      "Promoção para universidades",
      "Negociação de bolsas esportivas",
      "Aconselhamento na seleção de universidades e bolsas de estudo",
      "Apoio na solicitação de visto",
      "Apoio na matrícula",
      "Apoio em questões práticas",
    ]}
  />
</Grid>
<Grid item xs={12} sm={6} lg={3}>
  <SimplePricingCard
    color="dark"
    title="Premium"
    description="Seu caminho para os EUA"
    price={{ value: "Sob Consulta", type: "" }}
    action={{
      type: "modal",
      label: "Solicitar",
      onClick: alternarModal
    }}
    specifications={[
      "Plano personalizado",
      "Apoio na criação de um portfólio esportivo",
      "Apoio na preparação acadêmica",
      "Promoção para universidades",
      "Negociação de bolsas esportivas",
      "Aconselhamento na seleção de universidades e bolsas de estudo",
      "Apoio na solicitação de visto",
      "Apoio na matrícula",
      "Apoio em questões práticas",
      "Livros de estudo SAT/ACT",
      "Chamadas quinzenais por Zoom",
    ]}
  />
</Grid>
        </Grid>
      </Container>
      <Container>
    <Grid item xs={12} style={{ marginBottom: '80px' }}>
      <MKTypography variant="body2" color="text">
        {/* Seu texto vai aqui */}
        *Anteriormente, eu oferecia a conversa de avaliação gratuitamente. Infelizmente, isso resultava em muitos compromissos com pessoas não sérias, o que era custoso em termos de tempo. Para garantir a qualidade dos meus serviços e para garantir que as conversas sejam valiosas para ambas as partes, agora solicito uma pequena taxa de €14,99. Essa quantia ajuda a filtrar consultas sérias, enquanto mantém a conversa informal e focada nas suas necessidades específicas.

Agradeço a sua compreensão e aguardo uma conversa produtiva e esclarecedora com você.
      </MKTypography>
    </Grid>
  </Container>
    
    </section>
  );
}

export default Serviços;
