import React, { useState } from "react";
import { Container, Grid, Box, Typography } from "@mui/material";
import MKBadge from "components/MKBadge";
import MKButton from "components/MKButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ExampleCard from "../ExampleCard";
import SAT_open from "assets/images/Producten/SAT_open.png";
import SAT_cover from "assets/images/Producten/SAT_kaft.png";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";

import MKSnackbar from "components/MKSnackbar";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Link } from 'react-router-dom';


const sportsData = [
  {
    items: [
      {
        image: SAT_cover,
        alt: "Omslaget på SAT Studiehåndboken laget av Sport Scholarships America, med tittel og logo",
      },
      {
        image: SAT_cover,
        alt: "Omslaget på SAT Studiehåndboken laget av Sport Scholarships America, med tittel og logo",
      },
      {
        image: SAT_open,
        alt: "Åpnet SAT Studiehåndbok fra Sport Scholarships America, som viser sider om teststrategier og øvingsmateriell",
      },
      {
        image: SAT_open,
        alt: "Åpnet SAT Studiehåndbok fra Sport Scholarships America, som viser sider om teststrategier og øvingsmateriell",
      },
    ],
  },
];

function DesignBlocks() {
  const [selectedImage, setSelectedImage] = useState(SAT_cover);
  const [selectedAlt, setSelectedAlt] = useState("Omslaget på SAT Studiehåndboken laget av Sport Scholarships America, med tittel og logo"); // Set initial alt text
  const [quantity, setQuantity] = useState(0);
  const [show, setShow] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleIncrement = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 0) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const handleAddToCartClick = () => {
    if (quantity === 0) {
      setShowSnackbar(true);
      setShowModal(false); // Ensure that the modal does not open simultaneously
    } else {
      setShowModal(true);
      setShowSnackbar(false); // Ensure that the snackbar does not open simultaneously
    }
  };

  const toggleSnackbar = () => setShow(!show);
  const toggleModal = () => setShow(!show);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const renderData = sportsData.map(({ items }) => (
    <Grid container spacing={3} alignItems="flex-start">
      <Grid item xs={12} md={4}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <ExampleCard
              image={selectedImage}
              alt={selectedAlt} // Pass the updated alt text here
              applyGradient={false}
              applyPopOut={false}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} justifyContent="center">
              {items.map(({ image, name, alt }) => (
                <Grid item xs={3} sm={3} md={3} key={name}>
                  <div
                    onClick={() => {
                      setSelectedImage(image);
                      setSelectedAlt(alt); // Update alt text here as well
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <ExampleCard
                      image={image}
                      alt={alt} // Correctly passing the alt text
                      selected={selectedImage === image}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} container flexDirection="column" alignItems="flex-start" sx={{ textAlign: "left", my: 0, pl: { xs: 0, md: 4 } }}>
        <MKBadge 
          badgeContent="Sport Scholarships America" 
          variant="contained" 
          color="info" 
          container 
          circular 
          sx={{ mb: isSmallScreen ? 0 : 2, marginLeft: isSmallScreen ? 0 : 4 }} 
        />
        <Typography variant="h1" fontWeight="bold" sx={{ pb: isSmallScreen ? 0 : 2, marginLeft: isSmallScreen ? 0 : 4 }}>
          SAT Studiehåndbok
        </Typography>
        <Typography variant="body1" color="text" sx={{ pb: isSmallScreen ? 0 : 2, marginLeft: isSmallScreen ? 0 : 4 }}>
          Denne håndboken dekker grundig alle essensielle SAT-seksjoner:
          <ul style={{ paddingLeft: "30px", paddingTop: "10px", paddingBottom: "10px" }}> {/* Øk verdien for padding etter behov */}
            <li>
              <strong>Lese:</strong> Strategier for tekstforståelse og kritisk analyse.
            </li>
            <li>
              <strong>Skriving og Språk:</strong> Fokus på grammatikk, vokabular og effektiv språkbruk.
            </li>
            <li>
              <strong>Matematikk:</strong> Omfattende forberedelse til begge matteavsnittene.
            </li>
          </ul>
          Hver seksjon inneholder nøye sammensatte øvingspørsmål, detaljerte svar med forklaringer og eksperttips, alle eksklusivt utformet av Sport Scholarships America.
        </Typography>
        <Typography variant="h3" sx={{ pb: isSmallScreen ? 0 : 2, marginLeft: isSmallScreen ? 0 : 4 }}>
          €10.00
        </Typography>
        <Grid container spacing={2} alignItems="flex-start" sx={{ marginLeft: isSmallScreen ? 0 : 2 }}>
          <Grid item>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid lightgray",
                padding: "5px 10px",
                marginTop: "20px",
                backgroundColor: "lightgrey",
                width: "200px",
                borderRadius: "15px",
                height: "48px",
              }}
            >
              <RemoveIcon onClick={handleDecrement} style={{ cursor: "pointer" }} color=""/>
              <span style={{ color: "" }}>{quantity}</span>
              <AddIcon onClick={handleIncrement} style={{ cursor: "pointer" }} color=""/>
            </div>
          </Grid>
          <Grid item>
            <MKButton 
              color="info" 
              style={{ height: "48px", marginTop: "20px" }} 
              startIcon={<AddShoppingCartIcon />}
              onClick={handleAddToCartClick} // Moved onClick here
            >
              Legg til i handlekurv
            </MKButton>
          </Grid>
          <MKSnackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            color="primary"
            icon="notifications"
            title="Sport Scholarships America"
            content="Ops, du kan ikke legge til 0 produkter. Vennligst skriv inn en gyldig mengde."
            dateTime="1 min ago"
            open={show}
            close={toggleSnackbar}
          />
        </Grid>
      </Grid>
    </Grid>
  ));

  return (
    <Box component="section" my={6} py={6}>
      <Container sx={{ mt: 0 }}>{renderData}</Container>

      <MKSnackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        color="primary"
        icon="notifications"
        title="Sport Scholarships America"
        content="Oops, du kan ikke legge til 0 produkter. Vennligst skriv inn en gyldig mengde."
        dateTime="0 minutter siden"
        open={showSnackbar}
        close={() => setShowSnackbar(false)}
      />

      <Modal 
        open={showModal} 
        onClose={() => setShowModal(false)} 
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Slide direction="down" in={showModal} timeout={500}>
          <MKBox
            position="relative"
            width="500px"
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
          >
            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
              <MKTypography variant="h5">Midlertidig utsolgt
              </MKTypography>
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={() => setShowModal(false)} />
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox p={2}>
              <MKTypography variant="body2" color="secondary" fontWeight="regular">
                Dessverre er produktet du valgte for øyeblikket utsolgt. Jeg beklager ulempen. Jeg jobber hardt med å fylle på lageret så snart som mulig.
                <br/>
                <br/>
                Hvis du har spørsmål eller trenger mer informasjon, nøl ikke med å kontakte meg. Jeg er her for å hjelpe!
              </MKTypography>
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox display="flex" justifyContent="space-between" p={1.5}>
              <MKButton variant="gradient" color="dark" onClick={() => setShowModal(false)}>
                Lukk
              </MKButton>
              <MKButton 
                component={Link} 
                to="/no/Kontaktskjema" 
                variant="gradient" 
                color="info"
              >
                Kontaktskjema
              </MKButton>
            </MKBox>
          </MKBox>
        </Slide>
      </Modal>

    </Box>
  );
}

export default DesignBlocks;
