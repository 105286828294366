/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Track and Field1.jpg";
import Image2 from "assets/images/Sports_Images/Track and Field2.jpeg";
import Image3 from "assets/images/Sports_Images/Track and Field3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Leichtathletik und Studium in Amerika: Ein Traum, der wahr werden kann', isSubheader: false },
  
    { id: 'Kapitel-1', title: 'Kapitel 1: Geschichte und Entwicklung der Leichtathletik auf Hochschulebene', isSubheader: false },
    { id: '1.1', title: '1.1 Frühe Jahre und Ursprünge', isSubheader: true },
    { id: '1.2', title: '1.2 Wachstum und Professionalisierung', isSubheader: true },
  
    { id: 'Kapitel-2', title: 'Kapitel 2: Leichtathletik auf Hochschulebene heute', isSubheader: false },
    { id: '2.1', title: '2.1 Hauptwettkämpfe und Konferenzen', isSubheader: true },
    { id: '2.2', title: '2.2 Top-Programme und angesehene Teams', isSubheader: true },
  
    { id: 'Kapitel-3', title: 'Kapitel 3: Vom College zum Profi', isSubheader: false },
    { id: '3.1', title: '3.1 Der Weg zum Profisport', isSubheader: true },
    { id: '3.2', title: '3.2 Beziehung zum Profisport', isSubheader: true },
  
    { id: 'Kapitel-4', title: 'Kapitel 4: Internationale Einflüsse und Möglichkeiten', isSubheader: false },
    { id: '4.1', title: '4.1 Ausländische Athleten und Wettbewerbe', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanische Athleten im Ausland', isSubheader: true },
  
    { id: 'Kapitel-5', title: 'Kapitel 5: Sportstipendien und die Zukunft der Athleten', isSubheader: false },
    { id: '5.1', title: '5.1 Die Bedeutung von Stipendien', isSubheader: true },
    { id: '5.2', title: '5.2 Wie Sportstipendien Amerika helfen können', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contents
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verstecken</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                      key={section.id}
                      onClick={() => scrollTo(section.id)}
                      color="secondary"
                      variant="gradient"
                      sx={{ 
                        justifyContent: "flex-start",
                        pl: section.isSubheader ? 4 : 2,
                        textTransform: "none",
                        fontWeight: section.isSubheader ? "normal" : "bold",
                        borderTopLeftRadius: index === 0 ? '20px' : '0',
                        borderTopRightRadius: index === 0 ? '20px' : '0',
                        borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                        borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                        textAlign: "left",
                        width: '100%',
                      }}
                    >
                      {section.title}
                    </MKButton>
                  ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Anzeigen</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>


























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Leichtathletik und Studium in Amerika: Ein Traum, der wahr werden kann
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Athleten in voller Aktion während der Penn State Relays, einem prominenten Leichtathletik-Wettbewerb an Universitäten."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Aktion bei den Penn State Relays.
      </figcaption>
    </figure>
    Leichtathletik ist eine der beliebtesten und bedeutendsten Sportarten in den Vereinigten Staaten. Millionen von Menschen betreiben Leichtathletik als Hobby, im Rahmen ihres Schul- oder College-Programms oder als professionelle Athleten. Leichtathletik ist auch eine Sportart, die vielen jungen Talenten die Möglichkeit bietet, sich auf höchstem Niveau zu entwickeln, zu konkurrieren und zu glänzen.
    <br/>
    <br/>
    Eine der besten Möglichkeiten, von diesen Chancen zu profitieren, besteht darin, ein Leichtathletik-Stipendium in den USA zu erhalten. Ein Leichtathletik-Stipendium ist finanzielle Unterstützung, die Sie von einer Universität erhalten, um dort zu studieren und an Leichtathletik teilzunehmen. Mit einem Leichtathletik-Stipendium können Sie nicht nur Ihren akademischen Abschluss machen, sondern auch an einem der renommiertesten und wettbewerbsfähigsten Leichtathletikprogramme der Welt teilnehmen.
    <br/>
    <br/>
    Aber wie bekommen Sie ein Leichtathletik-Stipendium in den USA? Was sind die Vorteile und Herausforderungen des Studiums und des Wettbewerbs auf College-Ebene? Und wie können Sie sich auf eine erfolgreiche Karriere als Athlet nach Ihrem Studium vorbereiten? Diese und andere Fragen werden wir in diesem Artikel beantworten.
    <br/>
    <br/>
    Wir werden Ihnen auch zeigen, wie Sportbeursamerika.nl Ihnen helfen kann, Ihren Traum wahr werden zu lassen. Sportbeursamerika.nl ist eine Organisation, die sich auf die Begleitung junger Athleten zu Sportstipendien in den USA spezialisiert hat. Ich habe viel Erfahrung darin, die beste Übereinstimmung zwischen Athleten und Universitäten zu finden. Ich biete persönliche Betreuung, professionelle Beratung und praktische Unterstützung bei jedem Schritt des Prozesses.
    <br/>
    <br/>
    Wenn Sie daran interessiert sind, ein Leichtathletik-Stipendium in den USA zu erhalten, ist dieser Artikel genau das Richtige für Sie. Lesen Sie weiter und entdecken Sie alles, was Sie über Leichtathletik auf College-Ebene in den USA wissen müssen.
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>

<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Geschichte und Entwicklung der Leichtathletik auf Hochschulebene
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Die Leichtathletik auf Hochschulebene hat eine lange und reiche Geschichte in den USA. Sie begann im 19. Jahrhundert als Möglichkeit, die körperliche und geistige Gesundheit der Studenten zu fördern. Sie entwickelte sich zu einem bedeutenden Sport, der Millionen von Zuschauern anzog und viele Athleten hervorbrachte, die später weltberühmt wurden.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Frühe Jahre und Ursprünge
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Der erste Leichtathletikwettkampf an Universitäten fand 1864 statt, als Studenten von Yale und Oxford sich zu einem Wettkampf in London herausforderten. Der Wettkampf bestand aus vier Disziplinen: Laufen, Weitsprung, Hochsprung und Stabhochsprung. Yale gewann das Match mit 7-3.
      <br/>
      <br/>
      Dieser Wettkampf inspirierte auch andere Universitäten, Leichtathletikteams zu gründen und gegeneinander anzutreten. 1873 wurde die erste interkollegiale Leichtathletikvereinigung gegründet, die Intercollegiate Association of Amateur Athletes of America (IAAAA). Die IAAAA organisierte jährliche Meisterschaften für Universitäten an der Ostküste der USA.
      <br/>
      <br/>
      Das späte 19. Jahrhundert war eine wichtige Ära für die Entwicklung der Leichtathletik. Es war geprägt von der Formalisierung von Regeln und dem Aufkommen einflussreicher Persönlichkeiten wie James E. Sullivan, Mitbegründer der Amateur Athletic Union in den USA. Er spielte eine bedeutende Rolle bei der Popularisierung der Leichtathletik und der Organisation großer Veranstaltungen. Die Einführung der modernen Olympischen Spiele im Jahr 1896 gab dem Sport eine internationale Plattform und führte zur Standardisierung von Regeln und Disziplinen. Auch technologische Fortschritte, wie Verbesserungen bei Schuhen und Laufbahnen, trugen während dieser Zeit zur Evolution der Leichtathletik bei.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Wachstum und Professionalisierung
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Im 20. Jahrhundert wurde die Leichtathletik an Universitäten immer beliebter und wichtiger. Die Anzahl der Leichtathletikprogramme, Wettkämpfe und Stipendien stieg. Auch das Niveau der Athleten stieg, unter anderem dank besserem Training, besseren Einrichtungen und Coaching.
      <br/>
      <br/>
      Einer der Faktoren, die zum Wachstum und zur Professionalisierung der Leichtathletik auf Hochschulebene beitrugen, war die Gründung der National Collegiate Athletic Association (NCAA) im Jahr 1906. Die NCAA ist eine Organisation, die die sportlichen Aktivitäten von mehr als 1000 Universitäten in den USA überwacht. Die NCAA regelt unter anderem die Regeln, Qualifikationen, Stipendien und Sanktionen für die Leichtathletik auf Hochschulebene.
      <br/>
      <br/>
      Ein weiterer Faktor, der die Entwicklung der Leichtathletik auf Hochschulebene beeinflusste, war die Beziehung zum professionellen Leichtathletikbereich. Viele Athleten, die auf Hochschulebene erfolgreich waren, wechselten später auf den Profi-Zirkel. Einige Beispiele sind Jesse Owens, Carl Lewis, Jackie Joyner-Kersee und Michael Johnson.
    </MKTypography>
  </MKBox>
</MKBox>








                                              





                                  







<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Leichtathletik auf Hochschulebene heute
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Die Leichtathletik auf Hochschulebene ist heute einer der beliebtesten und angesehensten Sportarten in den USA. Jedes Jahr nehmen Tausende von Athleten an verschiedenen Wettkämpfen und Veranstaltungen teil, die Millionen von Fans und Medienaufmerksamkeit anziehen. Die Leichtathletik auf Hochschulebene bietet auch viele Möglichkeiten für Athleten, sich zu entwickeln, zu performen und ihren Sport zu genießen.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Hauptwettkämpfe und Konferenzen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Sydney McLaughlin, eine herausragende Athletin der University of Kentucky, im Wettkampfanzug."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Sydney McLaughlin studierte an der University of Kentucky
        </figcaption>
      </figure>
      Die Hauptorganisation, die die Leichtathletik auf Hochschulebene regelt, ist die NCAA. Die NCAA unterteilt Universitäten in drei Divisionen, basierend auf ihrer Größe, ihrem Budget und ihrem Wettbewerbsniveau. Division I ist die höchste Division, in der die meisten Top-Universitäten und Athleten vertreten sind. Divisionen II und III sind niedrigere Divisionen, in denen Universitäten weniger Stipendien und Ressourcen haben, aber mehr Wert auf akademische Leistungen legen.
      <br/>
      <br/>
      Innerhalb jeder Division gibt es verschiedene Konferenzen, die Gruppen von Universitäten sind, die geografisch oder historisch verbunden sind. Jede Konferenz organisiert ihre eigenen Meisterschaften und Wettkämpfe für ihre Mitglieder. Einige der bekanntesten Konferenzen sind die Big Ten, die Pac-12, die SEC, die ACC und die Ivy League.
      <br/>
      <br/>
      Die wichtigsten Ereignisse in der College-Leichtathletiksaison sind die NCAA-Meisterschaften, die jährlich im März (Halle) und im Juni (Freiluft) stattfinden. Diese Meisterschaften bestimmen, welche Universitäten und Athleten in jeder Division und Disziplin die besten sind. Die Meisterschaften ziehen viele Zuschauer, Sponsoren und Medienaufmerksamkeit an.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Top-Programme und angesehene Teams
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Es gibt viele Universitäten, die für ihre ausgezeichneten Leichtathletikprogramme bekannt sind. Diese Universitäten haben eine lange Tradition des Erfolgs, hochwertiges Coaching und Einrichtungen sowie eine starke Anziehungskraft auf talentierte Athleten. Einige dieser Universitäten sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Oregon: Oregon ist eine der erfolgreichsten Universitäten in der Leichtathletik, insbesondere im Laufen. Oregon hat mehr als 30 NCAA-Titel gewonnen und viele berühmte Athleten hervorgebracht, wie Steve Prefontaine, Alberto Salazar, Galen Rupp und Matthew Centrowitz.
        </li>
        <li>
          USC: USC ist eine weitere Top-Universität in der Leichtathletik, mit einem starken Fokus auf Sprint, Sprung und Wurf. USC hat mehr als 25 NCAA-Titel gewonnen und viele olympische Medaillengewinner hervorgebracht, wie Quincy Watts, Andre De Grasse und Michael Norman.
        </li>
        <li>
          Arkansas: Arkansas ist eine dominierende Universität in der Leichtathletik, insbesondere während der Hallensaison. Arkansas hat mehr als 40 NCAA-Titel gewonnen, von denen mehr als 30 in der Halle sind. Arkansas ist bekannt für seine Breite und Vielseitigkeit in verschiedenen Disziplinen.
        </li>
        <li>
          Texas: Texas ist eine der vielseitigsten Universitäten in der Leichtathletik, mit einem guten Gleichgewicht zwischen Männer- und Frauenteams sowie zwischen Einzel- und Staffelwettbewerben. Texas hat mehr als 20 NCAA-Titel gewonnen und viele Weltklasse-Athleten hervorgebracht, wie Sanya Richards-Ross, Trey Hardee, Michelle Carter und Courtney Okolo.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Diese Universitäten bieten nicht nur ein hohes Wettbewerbsniveau und Training für ihre Athleten, sondern auch eine großartige Atmosphäre und Kultur. Die Athleten sind Teil einer engen Gemeinschaft, die sie unterstützt und motiviert, ihr Potenzial auszuschöpfen.
    </MKTypography>
  </MKBox>
</MKBox>











                                              






                                  







<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Vom College zum Profi
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Die Leichtathletik auf Hochschulebene ist nicht nur ein Sport, sondern auch ein Trainingsplatz für Athleten, die eine professionelle Karriere anstreben. Viele Athleten, die auf Hochschulebene aktiv waren, sind später in den professionellen Bereich gewechselt. Die Leichtathletik auf Hochschulebene bietet Athleten die Möglichkeit, sich zu entwickeln, Erfahrungen zu sammeln und ein Netzwerk mit anderen Athleten, Trainern und Organisationen aufzubauen.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Der Weg zum Profisport
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Für viele Athleten ist das Ziel, nach ihrem Studium Profisportler zu werden. Das bedeutet, ihren Sport als Beruf auszuüben und durch Leistungen, Verträge und Sponsoring Geld zu verdienen. Um Profisportler zu werden, müssen Athleten mehrere Bedingungen erfüllen, wie zum Beispiel:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Ein hohes Leistungsniveau und Potential haben. Profisportler müssen in der Lage sein, mit den besten Athleten der Welt zu konkurrieren, und sich ständig verbessern und an sich verändernde Bedingungen anpassen können.
        </li>
        <li>
          Gute Gesundheit und Fitness aufrechterhalten. Profisportler müssen ihren Körper gut pflegen und sich vor Verletzungen, Krankheiten und Übertraining schützen. Sie müssen auch ein gutes Gleichgewicht zwischen Training, Ruhe und Ernährung finden.
        </li>
        <li>
          Gute Anleitung und Unterstützung haben. Profisportler sollten sich auf ein Team von Experten verlassen können, die ihnen bei Technik, Taktik, mentaler Vorbereitung, rechtlichen Angelegenheiten, finanziellen Fragen und Marketing helfen.
        </li>
        <li>
          Gute Planung und Strategie haben. Profisportler müssen wissen, welche Ziele sie erreichen wollen, an welchen Wettkämpfen sie teilnehmen wollen, wie sie sich für große Veranstaltungen qualifizieren wollen und wie sie ihre Karriere managen wollen.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Die Leichtathletik auf Hochschulebene kann Athleten dabei helfen, diese Bedingungen zu erfüllen. Sie bietet Athleten die Chance, ihr Talent zu präsentieren, ihre Fähigkeiten zu verbessern, Erfahrungen zu sammeln und ihr Netzwerk zu erweitern. Die Leichtathletik auf Hochschulebene kann auch als Sprungbrett in den Profibereich dienen, indem sie Athleten einem hohen Wettbewerbsniveau, Medienaufmerksamkeit und öffentlichem Interesse aussetzt.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Beziehung zum Profisport
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Die Leichtathletik auf Hochschulebene hat eine enge Beziehung zum Profisport. Die beiden Ebenen beeinflussen sich auf verschiedene Weise, wie zum Beispiel:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Talententwicklung: Die Leichtathletik auf Hochschulebene ist eine wichtige Quelle für Talente im Profisport. Viele Profisportler begannen als College-Athleten und legten dort den Grundstein für ihren späteren Erfolg.
        </li>
        <li>
          Talent-Scouting: Professionelle Sportorganisationen überwachen College-Sportveranstaltungen genau, um potenzielle Stars zu entdecken und anzuziehen. Sie bieten College-Athleten Verträge, Sponsoring und Anleitung an, um sie zum Wechsel ins Profilager zu bewegen.
        </li>
        <li>
          Talentaustausch: Die Leichtathletik auf Hochschulebene steht auch professionellen Athleten offen, die noch studieren oder zum Studium zurückkehren. Einige Profisportler entscheiden sich dafür, ihr Studium abzuschließen oder fortzusetzen, während sie ihre Sportkarriere fortsetzen.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Die Leichtathletik auf Hochschulebene ist daher nicht nur ein Endpunkt, sondern auch ein Sprungbrett für Athleten, die eine professionelle Karriere anstreben. Sie bietet Athleten die Möglichkeit, sich auf die Profiebene vorzubereiten, aber auch andere Wege zu erkunden.
    </MKTypography>
  </MKBox>
</MKBox>









                                              






                                  








<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationale Einflüsse und Möglichkeiten
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Die Leichtathletik auf Hochschulebene ist nicht nur ein amerikanischer Sport, sondern auch ein internationaler. Sie wird von Athleten aus verschiedenen Ländern und Kulturen beeinflusst und bietet diesen Athleten Chancen. Die Leichtathletik auf Hochschulebene ist ein Sport, der die Vielfalt und Vernetzung der Welt widerspiegelt.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Ausländische Athleten und Wettbewerbe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Einer der Wege, auf denen die Leichtathletik auf Hochschulebene international ist, ist durch die Präsenz und Leistungen ausländischer Athleten. Jedes Jahr kommen Hunderte von Athleten aus anderen Ländern in die USA, um auf Hochschulebene zu studieren und zu konkurrieren. Diese Athleten bringen ihr eigenes Talent, ihren Stil und ihre Kultur in amerikanische Universitäten ein und bereichern die Leichtathletikgemeinschaft mit ihren Perspektiven und Erfahrungen.
      <br/>
      <br/>
      Ausländische Athleten haben auch einen erheblichen Einfluss auf die Ergebnisse und Platzierungen in der Leichtathletik auf Hochschulebene. Viele ausländische Athleten gehören zu den besten Athleten in ihrer Disziplin und gewinnen oft Medaillen, Titel und Rekorde. Einige ausländische Athleten sind sogar so gut, dass sie amerikanische Athleten übertreffen oder herausfordern.
      <br/>
      <br/>
      Beispiele für erfolgreiche ausländische Athleten bei amerikanischen College-Wettbewerben sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Andre De Grasse: Ein kanadischer Sprinter, der an der Coffeyville Community College und der University of Southern California (USC) herausragte und mehrere NJCAA- und NCAA-Titel in Sprint-Disziplinen gewann. Seine Leistungen an der USC etablierten seinen Ruf als führender Sprinter. Nach seiner College-Karriere gewann De Grasse mehrere olympische Medaillen, darunter Gold über 200 Meter bei den Olympischen Spielen 2020 in Tokio, und präsentierte sich als einer der weltbesten Sprinter.
        </li>
        <li>
          Kerron Clement: Ein trinidadisch-amerikanischer Athlet, der im 400-Meter-Hürdenlauf herausragt. Er studierte an der University of Florida, wo er NCAA-Titel gewann und sich zu einem Weltklasse-Hürdenläufer entwickelte. Clement gewann Gold bei den Olympischen Spielen 2008 und 2016 und hat mehrere Weltmeisterschaften zu seinem Namen, was ihn zu einem der erfolgreichsten Hürdenläufer seiner Generation macht.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ausländische Athleten beeinflussen nicht nur die Leichtathletik auf Hochschulebene, sondern auch andere internationale Wettbewerbe und Veranstaltungen. Viele ausländische Athleten, die auf Hochschulebene konkurriert haben, nehmen später an den Olympischen Spielen, Weltmeisterschaften oder anderen regionalen oder kontinentalen Meisterschaften teil. Sie vertreten ihr eigenes Land, haben aber auch Bindungen zu ihrer amerikanischen Universität.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikanische Athleten im Ausland
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Eine weitere Möglichkeit, auf die die Leichtathletik auf Hochschulebene international ist, sind die Chancen und Herausforderungen, die sie amerikanischen Athleten bietet, im Ausland zu konkurrieren. Viele amerikanische Athleten, die auf Hochschulebene konkurriert haben, bekommen die Chance, ihren Horizont zu erweitern und ihre Fähigkeiten gegen Athleten aus verschiedenen Ländern und Kulturen zu testen.
      <br/>
      <br/>
      Eine der wichtigsten Möglichkeiten für amerikanische Athleten, im Ausland zu konkurrieren, sind natürlich die Olympischen Spiele. Die Olympischen Spiele sind das größte und prestigeträchtigste Sportereignis der Welt, bei dem Tausende von Athleten aus über 200 Ländern zusammenkommen, um um Gold, Silber und Bronze zu kämpfen. Die Olympischen Spiele sind auch eine Gelegenheit für amerikanische Athleten, ihr Land zu vertreten, ihren Patriotismus zu zeigen und ihren nationalen Stolz zu teilen.
      <br/>
      <br/>
      Ein weiteres wichtiges Ereignis, bei dem amerikanische Athleten im Ausland konkurrieren können, sind die Leichtathletik-Weltmeisterschaften. Die Leichtathletik-Weltmeisterschaften sind das bedeutendste Leichtathletikereignis nach den Olympischen Spielen, bei dem sich die besten Athleten der Welt treffen, um um den Weltmeistertitel zu kämpfen. Die Leichtathletik-Weltmeisterschaften sind auch eine Gelegenheit für amerikanische Athleten, ihr Niveau zu messen, ihre Rivalität zu schüren und ihren Ruf zu festigen.
      <br/>
      <br/>
      Neben diesen großen Veranstaltungen gibt es auch andere Möglichkeiten für amerikanische Athleten, im Ausland zu konkurrieren, wie die Diamond League, die Continental Tour, die Leichtathletik-Weltstaffeln oder andere regionale oder kontinentale Meisterschaften. Diese Veranstaltungen bieten amerikanischen Athleten die Möglichkeit, ihre Erfahrungen, Einnahmen und Fangemeinde zu erhöhen.
      <br/>
      <br/>
      Die Leichtathletik auf Hochschulebene spielt eine wichtige Rolle bei der Entwicklung und dem Erfolg amerikanischer Athleten, die im Ausland konkurrieren. Sie bereitet Athleten auf internationalem Niveau vor, indem sie ihnen hochwertiges Training, Coaching und Wettbewerb bietet. Die Leichtathletik auf Hochschulebene unterstützt Athleten, die im Ausland konkurrieren, auch durch ein Netzwerk von Kontakten, Sponsoren und Fans.
    </MKTypography>
  </MKBox>
</MKBox>











                                              






                                  

                                
<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Sportstipendien und die Zukunft der Athleten
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sportstipendien bieten zahlreiche Möglichkeiten für junge Athleten, ihr Talent zu zeigen und ihre Fähigkeiten zu entwickeln. Eine der bedeutendsten Möglichkeiten ist der Erhalt eines Sportstipendiums, um auf Hochschulebene zu studieren und zu konkurrieren. In diesem Kapitel werden wir die Bedeutung von Sportstipendien diskutieren und wie Sportstipendien Amerika Ihnen helfen kann, Ihren Traum zu verwirklichen.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Die Bedeutung von Stipendien
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ein Sportstipendium ist finanzielle Unterstützung, die von einer Universität einem Studenten-Athleten zur Verfügung gestellt wird, um Studium und Sport zu kombinieren. Es kann die Kosten für Studiengebühren, Bücher, Unterkunft, Mahlzeiten und andere Ausgaben abdecken. Darüber hinaus kann es auch Vorteile wie akademische Nachhilfe, medizinische Versorgung, Trainingsmöglichkeiten und Ausrüstung bieten.
      <br />
      <br />
      Die Erlangung eines Sportstipendiums ist wettbewerbsfähig, wobei viele Faktoren eine Rolle im Zuteilungsprozess spielen, einschließlich akademischer Leistungen, sportlicher Leistung, Persönlichkeit, Motivation, Führung und Anpassungsfähigkeit. Daher ist es wichtig, sich gut vorzubereiten und sich von der Konkurrenz abzuheben.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Wie Sportstipendien Amerika helfen können
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo von Sportstipendien Amerika, das Sportausrüstung und eine Abschlusskappe zeigt, die den Fokus auf Sportstipendien für Studenten-Athleten repräsentiert."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo von Sportstipendien Amerika
        </figcaption>
      </figure>
      Sportstipendien Amerika ist eine Organisation, die sich auf die Anleitung junger Sporttalente zu Sportstipendien spezialisiert hat. Mit leidenschaftlichen und erfahrenen Experten unterstützt Sportstipendien Amerika Studenten-Athleten auf jedem Schritt der Reise, von der ersten Orientierung bis zur endgültigen Platzierung. Ihre Dienstleistungen umfassen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Ein persönliches Aufnahmegespräch, um die Wünsche, Möglichkeiten und Erwartungen des Studenten-Athleten zu besprechen.</li>
        <li>Eine professionelle Bewertung des sportlichen und akademischen Niveaus des Studenten-Athleten.</li>
        <li>Ein umfangreiches Netzwerk von Kontakten zu Trainern, Universitäten und Hochschulen.</li>
        <li>Effektive Werbung für das Profil und die Videos des Studenten-Athleten bei potenziellen Trainern.</li>
        <li>Expertenberatung zu den besten Optionen basierend auf den Vorlieben, Zielen und dem Budget des Studenten-Athleten.</li>
        <li>Verhandlung der besten Stipendienangebote im Namen des Studenten-Athleten.</li>
        <li>Hilfe bei der Beantragung der erforderlichen Dokumente wie Visum, Zeugnis, Diplom und Zulassungstests.</li>
        <li>Vorbereitung auf das Leben des Studenten-Athleten während seines Stipendiums, einschließlich Kultur, Sprache, Regeln und Tipps.</li>
        <li>Nachsorge und Follow-up während der Stipendiumszeit.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Möchten Sie Stipendienmöglichkeiten erkunden und Ihre sportlichen und akademischen Ziele erreichen? <Link to="/de/Dienstleistungen-Produkte">Sehen Sie sich die vollständige Palette</Link> der von Sportstipendien Amerika angebotenen Dienstleistungen an und vereinbaren Sie noch heute eine Beratung.
    </MKTypography>
  </MKBox>
</MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;