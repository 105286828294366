/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Waterpol1.jpeg";
import Image2 from "assets/images/Sports_Images/Waterpolo2.jpeg";
import Image3 from "assets/images/Sports_Images/Waterpolo3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Bolsas de Estudo de Polo Aquático na América: Como Estudar e Jogar na Terra das Estrelas e Listras', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capítulo 1: História e Desenvolvimento do Polo Aquático na América', isSubheader: false },
    { id: '1.1', title: '1.1 Primeiros Anos', isSubheader: true },
    { id: '1.2', title: '1.2 Crescimento e Profissionalização', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capítulo 2: Polo Aquático Universitário Hoje', isSubheader: false },
    { id: '2.1', title: '2.1 Competições e Conferências Principais', isSubheader: true },
    { id: '2.2', title: '2.2 Programas Principais e Equipes de Prestígio', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capítulo 3: Do Colegial para o Profissional', isSubheader: false },
    { id: '3.1', title: '3.1 O Caminho para o Profissionalismo', isSubheader: true },
    { id: '3.2', title: '3.2 Ligas Profissionais', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capítulo 4: Influências Internacionais e Competições', isSubheader: false },
    { id: '4.1', title: '4.1 Influências Estrangeiras', isSubheader: true },
    { id: '4.2', title: '4.2 Americanos em Competições Internacionais', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 A Importância das Bolsas de Estudo', isSubheader: true },
    { id: '5.2', title: '5.2 Como a Sport Scholarships America Pode Ajudar', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Conteúdo
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Esconder</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>























{/* Caixa de Introdução */}
<MKBox id="intro" mb={3}>
  {/* Título da Introdução */}
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Bolsas de Estudo de Polo Aquático na América: Como Estudar e Jogar na Terra das Estrelas e Listras
  </MKTypography>
  {/* Texto da Introdução */}
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O polo aquático teve origem na Europa no final do século XIX como uma forma de rúgbi aquático e rapidamente ganhou popularidade em vários países, incluindo a América. A primeira partida oficial na América foi disputada em 1888 entre dois clubes em Nova York. Desde então, o polo aquático se desenvolveu significativamente na América, tanto nos níveis profissional quanto universitário.
    <br />
    <br />
    O polo aquático universitário é um dos principais fatores no desenvolvimento de jogadores de polo aquático na América. Muitos jogadores começam suas carreiras no ensino médio e depois passam para a faculdade, onde têm a oportunidade de competir contra as melhores equipes e jogadores do país. O polo aquático universitário também oferece uma excelente educação acadêmica, permitindo que os jogadores combinem sua paixão pelo esporte com seus objetivos educacionais.
    <br />
    <br />
    Mas como você, como estudante holandês, pode estudar e jogar na América? É aí que o Sportbeursamerika.nl pode ajudar. O Sportbeursamerika.nl é uma organização especializada em ajudar os alunos a obter bolsas de estudo de polo aquático na América. O Sportbeursamerika.nl tem uma equipe de especialistas que podem guiá-lo em cada etapa do processo, desde encontrar a universidade certa até lidar com todos os assuntos práticos.
    <br />
    <br />
    Se você está interessado em obter uma bolsa de estudos de polo aquático na América, continue lendo para saber mais sobre esta emocionante oportunidade.
  </MKTypography>
  {/* Adicione uma lista com marcadores ou outro conteúdo, se necessário */}
</MKBox>

{/* Capítulos e subcapítulos principais com texto fictício */}
<MKBox id="Chapter 1" mb={3}>
  {/* Título do Capítulo 1 */}
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 1: História e Desenvolvimento do Polo Aquático na América
  </MKTypography>
  {/* Texto do Capítulo 1 */}
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O polo aquático tem uma longa e rica história na América. É um dos esportes olímpicos mais antigos, primeiro disputado nos Jogos Olímpicos de 1900 em Paris, onde a América também participou. Desde então, a América competiu em todas as competições olímpicas de polo aquático, exceto em 1976 e 1980, quando se retirou em protesto contra as situações políticas na África do Sul e na União Soviética, respectivamente.
  </MKTypography>

  {/* Subcapítulo 1.1: Primeiros Anos */}
  <MKBox id="1.1" mb={2}>
    {/* Título do Subcapítulo 1.1 */}
    <MKTypography variant="h4" mb={1}>
      1.1 Primeiros Anos
    </MKTypography>
    {/* Texto do Subcapítulo 1.1 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Os primeiros clubes de polo aquático na América foram estabelecidos em Nova York e Boston no final do século XIX. Eles jogavam de acordo com as regras inglesas, que eram muito mais rudes e violentas do que as regras modernas. O esporte rapidamente se tornou popular entre imigrantes da Europa, especialmente da Irlanda e da Escócia. O primeiro campeonato nacional foi realizado em 1890 e foi vencido pelo Sydenham Swimmers Club.
      <br />
      <br />
      As primeiras equipes universitárias foram formadas no início do século XX, especialmente na Costa Leste. As universidades da Ivy League, como Harvard, Yale e Princeton, dominaram o esporte até a década de 1920. A primeira competição intercolegial foi organizada em 1912 e foi vencida por Princeton.
    </MKTypography>
  </MKBox>

  {/* Subcapítulo 1.2: Crescimento e Profissionalização */}
  <MKBox id="1.2" mb={2}>
    {/* Título do Subcapítulo 1.2 */}
    <MKTypography variant="h4" mb={1}>
      1.2 Crescimento e Profissionalização
    </MKTypography>
    {/* Texto do Subcapítulo 1.2 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Na década de 1920, o polo aquático começou a se espalhar para outras partes do país, especialmente para a Califórnia. Um novo estilo de jogo, focado em velocidade, agilidade e arremessos em vez de contato físico, desenvolveu-se lá. As equipes da Califórnia rapidamente se tornaram as melhores do país, vencendo a maioria dos títulos nacionais e intercolegiais.
      <br />
      <br />
      Na década de 1930, surgiram as primeiras ligas profissionais na América, como a American Water Polo League e a Pacific Coast Water Polo League. Essas ligas atraíram muitos espectadores e atenção da mídia, elevando o nível e a popularidade do esporte. Muitos jogadores profissionais também eram jogadores universitários ou graduados que continuavam suas carreiras após os estudos.
      <br />
      <br />
      Uma das figuras-chave na história do polo aquático americano foi James "Jimmy" Smith, considerado o "pai do polo aquático moderno". Ele foi jogador, treinador, árbitro, organizador e promotor do esporte. Ele jogou por vários clubes e universidades, incluindo a UCLA, onde mais tarde se tornou treinador. Ele liderou a equipe americana para duas medalhas olímpicas de bronze em 1924 e 1932. Ele também foi um dos fundadores do International Swimming Hall of Fame, onde foi introduzido em 1965.
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  









{/* Capítulo 2: Polo Aquático Universitário Hoje */}
<MKBox id="Chapter 2" mb={3}>
  {/* Título do Capítulo 2 */}
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 2: Polo Aquático Universitário Hoje
  </MKTypography>
  {/* Texto do Capítulo 2 */}
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O polo aquático universitário é um dos esportes mais prestigiados e competitivos na América. Ele oferece uma oportunidade única para os estudantes combinarem suas ambições acadêmicas e atléticas e competirem contra os melhores jogadores do país. O polo aquático universitário também é uma fonte significativa de talento para a equipe nacional e ligas profissionais.
  </MKTypography>

  {/* Subcapítulo 2.1: Competições e Conferências Principais */}
  <MKBox id="2.1" mb={2}>
    {/* Título do Subcapítulo 2.1 */}
    <MKTypography variant="h4" mb={1}>
      2.1 Competições e Conferências Principais
    </MKTypography>
    {/* Texto do Subcapítulo 2.1 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O polo aquático universitário é regulamentado pela Associação Atlética Universitária Nacional (NCAA), que divide o esporte em três divisões com base no nível e no número de bolsas de estudo disponíveis. A Divisão I é a divisão mais alta, composta por aproximadamente 30 equipes, principalmente da Califórnia. A Divisão II e a III têm cerca de 20 equipes cada, mais distribuídas pelo país.
      <br />
      <br />
      Todo ano, um campeonato nacional é realizado para cada divisão, com as melhores equipes de cada conferência competindo pelo título. Uma conferência é um grupo de universidades que são geograficamente ou historicamente conectadas e competem regularmente entre si. As principais conferências de polo aquático incluem:
    </MKTypography>
    {/* Lista de Conferências */}
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          A Mountain Pacific Sports Federation (MPSF), que consiste em oito equipes da Califórnia, incluindo UCLA, Stanford, USC e Berkeley. Esta conferência é considerada a mais forte e prestigiada do país e conquistou a maioria dos títulos nacionais.
        </li>
        <li>
          A Western Water Polo Association (WWPA), que consiste em nove equipes da Califórnia, Colorado, Utah e Havaí. Esta conferência é a segunda mais forte do país e também conquistou alguns títulos nacionais.
        </li>
        <li>
          A Collegiate Water Polo Association (CWPA), que consiste em 18 equipes da Costa Leste, incluindo Harvard, Princeton, Brown e Navy. Esta conferência é a terceira mais forte do país e ocasionalmente conquistou um título nacional.
        </li>
        <li>
          A Southern California Intercollegiate Athletic Conference (SCIAC), que consiste em nove equipes do sul da Califórnia, incluindo Pomona-Pitzer, Claremont-Mudd-Scripps e Occidental. Esta conferência é a quarta mais forte do país e compete principalmente na Divisão III.
        </li>
      </ul>
    </MKTypography>
  </MKBox>

  {/* Subcapítulo 2.2: Programas Principais e Equipes de Prestígio */}
  <MKBox id="2.2" mb={2}>
    {/* Título do Subcapítulo 2.2 */}
    <MKTypography variant="h4" mb={1}>
      2.2 Programas Principais e Equipes de Prestígio
    </MKTypography>
    {/* Texto do Subcapítulo 2.2 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O polo aquático universitário tem vários programas líderes conhecidos por suas excelentes performances, tanto nos esportes quanto na academia. Esses programas atraem muitos jogadores talentosos que se beneficiam das instalações de alta qualidade, treinamento e educação que oferecem. Alguns desses programas são:
    </MKTypography>
    {/* Lista de Programas e Equipes de Prestígio */}
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          A Universidade da Califórnia, Los Angeles (UCLA) é conhecida por seu excepcional programa de polo aquático, que está entre os mais bem-sucedidos da história. Com um impressionante número de títulos nacionais para ambas as equipes masculinas e femininas, o programa ocupa um lugar de destaque no esporte. As partidas são disputadas no Spieker Aquatics Center, com capacidade para 2.500 espectadores. A equipe da UCLA é famosa por seu estilo de jogo rápido e dinâmico e produziu um número significativo de medalhistas olímpicos.
        </li>
        <li>
          A Universidade de Stanford também possui um prestigioso programa de polo aquático com inúmeros títulos nacionais para ambas as equipes masculinas e femininas. As equipes jogam suas partidas em casa no Avery Aquatic Center, que acomoda 2.530 espectadores. As equipes de polo aquático de Stanford são conhecidas por suas habilidades técnicas e táticas e também contribuíram para o desenvolvimento de muitos medalhistas olímpicos.
        </li>
        <li>
          A Universidade do Sul da Califórnia (USC) também possui um forte programa de polo aquático com múltiplos campeonatos nacionais para ambas as equipes masculinas e femininas. Sua base é o Uytengsu Aquatics Center, com capacidade para 2.500 espectadores. A equipe da USC é caracterizada por um estilo de jogo físico e agressivo e também treinou um número significativo de medalhistas olímpicos.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  







{/* Capítulo 3: Do Colegial para o Profissional */}
<MKBox id="Chapter 3" mb={3}>
  {/* Título do Capítulo 3 */}
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 3: Do Colegial para o Profissional
  </MKTypography>
  {/* Texto do Capítulo 3 */}
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O polo aquático universitário não é apenas uma ótima maneira de estudar e jogar na América, mas também pode ser um trampolim para uma carreira profissional. Muitos jogadores universitários passam a jogar em ligas profissionais, tanto na América quanto no exterior, após se formarem. Alguns deles também são selecionados para a equipe nacional, que participa de torneios internacionais como as Olimpíadas, Campeonatos Mundiais e a Liga Mundial.
  </MKTypography>

  {/* Subcapítulo 3.1: O Caminho para o Profissionalismo */}
  <MKBox id="3.1" mb={2}>
    {/* Título do Subcapítulo 3.1 */}
    <MKTypography variant="h4" mb={1}>
      3.1 O Caminho para o Profissionalismo
    </MKTypography>
    {/* Texto do Subcapítulo 3.1 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Embora o polo aquático seja um esporte popular e competitivo na América, ainda não é tão comercial e lucrativo como alguns outros esportes como basquete, futebol ou beisebol. Portanto, não existem muitas ligas ou equipes profissionais na América, e os salários e prêmios em dinheiro são relativamente baixos. A maioria dos jogadores profissionais também tem outro emprego ou fonte de renda ao lado de sua carreira no polo aquático.
      <br />
      <br />
      A principal liga profissional na América é a National Water Polo League (NWPL), que foi estabelecida em 2018 como sucessora da American Water Polo League (AWPL). A NWPL é composta por oito equipes, cada uma com 15 jogadores. As equipes disputam uma temporada regular de 14 jogos, seguida por um torneio de playoffs para determinar o campeão. O campeão atual é o Los Angeles Water Polo Club, que conquistou o título em 2022.
      <br />
      <br />
      A maioria dos jogadores na NWPL são ex-jogadores universitários ou graduados que continuam suas carreiras após seus estudos. Alguns deles também são membros da equipe nacional, que treina e joga regularmente no Centro de Treinamento Olímpico em Colorado Springs. A equipe nacional é treinada por Dejan Udovicic, ex-jogador e treinador sérvio que está no comando desde 2013.
      <br />
      <br />
      Um dos ex-alunos mais bem-sucedidos do polo aquático universitário é Tony Azevedo, considerado um dos maiores jogadores de todos os tempos. Ele jogou pela Universidade de Stanford, onde foi nomeado Jogador do Ano da NCAA quatro vezes. Ele também jogou por várias equipes profissionais na Europa e na América, incluindo Brescia (Itália), Olympiacos (Grécia) e Long Beach Shore (América). Ele também competiu em cinco Olimpíadas, conquistando uma medalha de prata em 2008. Ele é frequentemente chamado de "O Salvador" porque frequentemente marcava gols decisivos para sua equipe.
    </MKTypography>
  </MKBox>

  {/* Subcapítulo 3.2: Ligas Profissionais */}
  <MKBox id="3.2" mb={2}>
    {/* Título do Subcapítulo 3.2 */}
    <MKTypography variant="h4" mb={1}>
      3.2 Ligas Profissionais
    </MKTypography>
    {/* Texto do Subcapítulo 3.2 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Além da NWPL, existem outras ligas profissionais na América, como a Major League Water Polo (MLWP) e a Premier Water Polo League (PWPL). No entanto, essas ligas são menos estabelecidas e menos populares do que a NWPL e têm menos equipes e jogadores. Elas também não são reconhecidas pela USA Water Polo, a federação nacional responsável por organizar e regulamentar o polo aquático na América.
      <br />
      <br />
      Muitos jogadores americanos, portanto, optam por continuar suas carreiras profissionais no exterior, onde existem mais oportunidades e melhores condições. A Europa é o principal destino para jogadores americanos de polo aquático, pois o continente possui algumas das ligas mais fortes e prestigiadas do mundo. Algumas dessas ligas incluem:
    </MKTypography>
    {/* Lista de Ligas Profissionais na Europa */}
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          A Liga dos Campeões LEN, considerada a principal competição de clubes na Europa. Ela é composta por 16 equipes de vários países competindo pelo título europeu. O campeão atual é o Pro Recco (Itália), que conquistou o título em 2021.
        </li>
        <li>
          A Copa Euro LEN, considerada a segunda competição de clubes na Europa. Ela consiste em 32 equipes de diferentes países disputando uma vaga na Liga dos Campeões. O campeão atual é o Orvosegyetem SC (Hungria), que conquistou o título em 2021.
        </li>
        <li>
          Ligas nacionais organizadas pelas respectivas federações de cada país. Algumas das ligas nacionais mais fortes e populares incluem Serie A1 (Itália), Liga Premaat (Espanha), A1 Ethniki (Grécia) e OB I (Hungria).
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  





{/* Capítulo 4: Influências Internacionais e Competições */}
<MKBox id="Chapter 4" mb={3}>
  {/* Título do Capítulo 4 */}
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 4: Influências Internacionais e Competições
  </MKTypography>
  {/* Texto do Capítulo 4 */}
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O polo aquático é um esporte global jogado e seguido por milhões de pessoas ao redor do mundo. É também um esporte que está em constante evolução e inovação, graças à influência de diversas culturas, estilos e tradições. O polo aquático americano não é uma exceção, pois aprendeu e se beneficiou do polo aquático internacional, tanto a nível de clubes quanto nacional.
  </MKTypography>

  {/* Subcapítulo 4.1: Influências Estrangeiras */}
  <MKBox id="4.1" mb={2}>
    {/* Título do Subcapítulo 4.1 */}
    <MKTypography variant="h4" mb={1}>
      4.1 Influências Estrangeiras
    </MKTypography>
    {/* Texto do Subcapítulo 4.1 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Como vimos, o polo aquático teve origem na Europa e se espalhou para outros continentes, incluindo a América. Portanto, o polo aquático europeu teve uma influência significativa no polo aquático americano, especialmente em termos de técnica, tática e estratégia. Muitos jogadores e treinadores americanos melhoraram suas habilidades e conhecimentos jogando ou treinando na Europa ou aprendendo com jogadores e treinadores europeus.
      <br />
      <br />
      Um dos países europeus mais influentes para o polo aquático americano é a Hungria, que é considerada a nação de polo aquático mais bem-sucedida do mundo. A Hungria ganhou 15 medalhas de ouro olímpicas, mais do que qualquer outro país. A Hungria é conhecida por seu estilo de jogo criativo e inteligente, que se baseia em bom controle de bola, passes rápidos e arremessos precisos. Muitos jogadores e treinadores húngaros deixaram sua marca no polo aquático americano, como Tibor Benedek, Zoltan Szecsi e Attila Banhidy.
      <br />
      <br />
      Outro país europeu influente para o polo aquático americano é a Sérvia, que também é uma das principais nações de polo aquático globalmente. A Sérvia ganhou 7 medalhas de ouro olímpicas, sendo a mais recente em 2016. A Sérvia é conhecida por seu estilo de jogo físico e agressivo, que se baseia em uma defesa forte, contra-ataques rápidos e arremessos poderosos. Muitos jogadores e treinadores sérvios compartilharam sua experiência e expertise com o polo aquático americano, incluindo Dejan Udovicic, Andrija Prlainovic e Filip Filipovic.
    </MKTypography>
  </MKBox>

  {/* Subcapítulo 4.2: Americanos em Competições Internacionais */}
  <MKBox id="4.2" mb={2}>
    {/* Título do Subcapítulo 4.2 */}
    <MKTypography variant="h4" mb={1}>
      4.2 Americanos em Competições Internacionais
    </MKTypography>
    {/* Texto do Subcapítulo 4.2 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Além de aprender com o polo aquático internacional, o polo aquático americano também contribuiu significativamente para o polo aquático internacional, especialmente em termos de desempenho, inovação e promoção. Muitos jogadores e treinadores americanos participaram de competições internacionais, tanto a nível de clubes quanto nacional, onde mostraram seus talentos e melhoraram sua reputação.
      <br />
      <br />
      Uma das competições de polo aquático internacional mais importantes são os Jogos Olímpicos, realizados a cada quatro anos. A equipe americana sempre participou desta competição, exceto em 1976 e 1980. A equipe americana conquistou um total de 12 medalhas olímpicas: 3 de ouro, 4 de prata e 5 de bronze. A medalha mais recente foi uma medalha de prata para os homens em 2008. A equipe americana está atualmente classificada em quarto lugar no mundo.
      <br />
      <br />
      Outra competição internacional importante para o polo aquático é o Campeonato Mundial, realizado a cada dois anos. A equipe americana também participou consistentemente desta competição desde sua primeira edição em 1973. A equipe americana conquistou um total de 9 medalhas em Campeonatos Mundiais: 2 de ouro, 3 de prata e 4 de bronze. A medalha mais recente foi uma medalha de bronze para as mulheres em 2019. A equipe americana está atualmente classificada em terceiro lugar no mundo.
      <br />
      <br />
      Uma terceira competição internacional importante para o polo aquático é a Liga Mundial, realizada anualmente. A equipe americana também participou consistentemente desta competição desde sua primeira edição em 2002. A equipe americana conquistou um total de 10 medalhas na Liga Mundial: 3 de ouro, 4 de prata e 3 de bronze. A medalha mais recente foi uma medalha de ouro para as mulheres em 2021. A equipe americana está atualmente classificada em segundo lugar no mundo.
    </MKTypography>
  </MKBox>
</MKBox>







                                  






<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    As bolsas de estudo esportivas oferecem inúmeras oportunidades para jovens atletas mostrarem seu talento e desenvolverem suas habilidades. Uma das oportunidades mais significativas é obter uma bolsa de estudos esportiva para estudar e competir no nível universitário. Neste capítulo, discutiremos a importância das bolsas de estudo esportivas e como a Sport Scholarships America pode ajudá-lo a realizar seu sonho.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 A Importância das Bolsas de Estudo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Uma bolsa de estudos esportiva é um suporte financeiro fornecido por uma universidade a um estudante-atleta para combinar estudos e esportes. Pode cobrir os custos de mensalidades, livros, moradia, refeições e outras despesas. Além disso, pode oferecer benefícios como tutoria acadêmica, cuidados médicos, instalações de treinamento e equipamentos.
      <br />
      <br />
      Obter uma bolsa de estudos esportiva é competitivo, com muitos fatores desempenhando um papel no processo de concessão, incluindo realizações acadêmicas, desempenho atlético, personalidade, motivação, liderança e adaptabilidade. Portanto, é importante se preparar bem e se destacar da concorrência.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Como a Sport Scholarships America Pode Ajudar
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo da Sport Scholarships America, mostrando equipamentos esportivos e um capelo de formatura, representando o foco em bolsas de estudo esportivas para estudantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo da Sport Scholarships America
        </figcaption>
      </figure>
      A Sport Scholarships America é uma organização especializada em orientar jovens talentos esportivos para bolsas de estudo esportivas. Com especialistas apaixonados e experientes, a Sport Scholarships America apoia estudantes-atletas em cada etapa da jornada, desde a orientação inicial até a colocação final. Seus serviços incluem:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Uma entrevista de admissão pessoal para discutir os desejos, possibilidades e expectativas do estudante-atleta.</li>
        <li>Uma avaliação profissional do nível acadêmico e atlético do estudante-atleta.</li>
        <li>Uma extensa rede de contatos com treinadores, universidades e faculdades.</li>
        <li>Promoção eficaz do perfil e vídeos do estudante-atleta para treinadores potenciais.</li>
        <li>Conselhos especializados sobre as melhores opções com base nas preferências, metas e orçamento do estudante-atleta.</li>
        <li>Negociação das melhores ofertas de bolsas de estudo em nome do estudante-atleta.</li>
        <li>Auxílio na solicitação de documentos necessários, como visto, histórico escolar, diploma e testes de admissão.</li>
        <li>Preparação para a vida do estudante-atleta durante a bolsa de estudos, incluindo cultura, idioma, regras e dicas.</li>
        <li>Acompanhamento e acompanhamento durante o período da bolsa de estudos.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Quer explorar oportunidades de bolsas de estudo e alcançar seus objetivos atléticos e acadêmicos? <Link to="/pt/Serviços-Produtos">Veja a gama completa</Link> de serviços oferecidos pela Sport Scholarships America e agende uma consulta hoje.
    </MKTypography>
  </MKBox>
</MKBox>



          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;