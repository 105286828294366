/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";



function TL() {
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container item xs={12} lg={4}>
          <MKTypography variant="h3" mb={6}>
          </MKTypography>
        </Grid>
        <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            
        </Grid>
      </Container>
    </MKBox>
  );
}

export default TL;
