/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";



// Images
import Image1 from "assets/images/Sports_Images/Soccer1.jpg";
import Image2 from "assets/images/Sports_Images/Soccer2.jpg";
import Image3 from "assets/images/Sports_Images/Soccer3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Jugando al Fútbol y Estudiando en América: Un Sueño Hecho Realidad', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capítulo 1: La Historia y Desarrollo del Fútbol en América', isSubheader: false },
    { id: '1.1', title: '1.1 Primeros Años y el Papel de las Universidades', isSubheader: true },
    { id: '1.2', title: '1.2 Crecimiento y Profesionalización', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capítulo 2: El Fútbol Universitario Hoy', isSubheader: false },
    { id: '2.1', title: '2.1 Competiciones y Conferencias Importantes para el Fútbol', isSubheader: true },
    { id: '2.2', title: '2.2 Programas Principales y Equipos Prestigiosos de Fútbol', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capítulo 3: Del Colegio a los Profesionales', isSubheader: false },
    { id: '3.1', title: '3.1 El Camino hacia el Profesionalismo', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capítulo 4: La Influencia del Fútbol Internacional', isSubheader: false },
    { id: '4.1', title: '4.1 Influencias Extranjeras', isSubheader: true },
    { id: '4.2', title: '4.2 Estadounidenses en el Extranjero', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capítulo 5: Becas Deportivas y el Futuro de los Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 La Importancia de las Becas', isSubheader: true },
    { id: '5.2', title: '5.2 Cómo Puede Ayudar Sport Scholarships America', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contenidos
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Ocultar</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>























{/* Introducción */}
<MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Jugando al Fútbol y Estudiando en América: Un Sueño Hecho Realidad
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Estadio de Creighton, con capacidad para más de 7000 espectadores, durante un partido de fútbol universitario."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Estadio de Creighton: hogar de más de 7000 aficionados al fútbol.
      </figcaption>
    </figure>
    El fútbol es uno de los deportes más populares del mundo, pero en América, ha sido eclipsado durante mucho tiempo por otros deportes como el baloncesto, el béisbol y el fútbol americano. Sin embargo, en las últimas décadas, el fútbol ha crecido enormemente en popularidad y calidad en los EE. UU., gracias en parte al éxito del equipo nacional, el surgimiento de la Major League Soccer (MLS) y la influencia de estrellas internacionales.
    <br />
    <br />
    Pero ¿sabías que el fútbol en América también ofrece una oportunidad única para que los jóvenes talentos persigan sus sueños? Al jugar al fútbol y estudiar en América, no solo puedes desarrollar tus habilidades atléticas, sino también obtener un título académico y vivir una experiencia inolvidable. Todo esto es posible gracias a las becas deportivas que ofrecen las universidades estadounidenses a jugadores talentosos.
    <br />
    <br />
    En este artículo, te contaremos todo lo que necesitas saber sobre jugar al fútbol y estudiar en América, desde la historia y el desarrollo del fútbol en los EE. UU. hasta los beneficios y oportunidades de una beca deportiva. También explicaremos cómo Sportbeursamerika.nl puede ayudarte a convertir este sueño en realidad. ¡Así que sigue leyendo y descubre cómo puedes aprovechar esta oportunidad única!
  </MKTypography>
</MKBox>

{/* Capítulo 1 */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 1: La Historia y Desarrollo del Fútbol en América
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El fútbol, o soccer como lo llaman los estadounidenses, no es un deporte tan nuevo en los EE. UU. como podrías pensar. De hecho, el primer partido oficial de fútbol en América tuvo lugar en 1869, entre dos equipos universitarios: Princeton y Rutgers. Desde entonces, el fútbol ha tenido una larga y fascinante historia en los EE. UU., que discutiremos en este capítulo.
  </MKTypography>
  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Primeros Años y el Papel de las Universidades
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A finales del siglo XIX y principios del XX, el fútbol era especialmente popular entre los estudiantes, que jugaban el juego según varias reglas y formatos. Por ejemplo, había equipos que jugaban con 11, 15 o incluso 25 jugadores, y a veces usaban elementos del rugby o el fútbol americano.
      <br />
      <br />
      Para crear más unidad y estructura, se fundó la Asociación Intercolegial de Atletas Aficionados de América (IAAAA) en 1905, precursora de la Asociación Nacional Atlética Universitaria (NCAA). La NCAA es la principal organización que regula y organiza el fútbol universitario en los EE. UU.
      <br />
      <br />
      En la primera mitad del siglo XX, el número de equipos de fútbol universitario creció constantemente, y también surgieron competiciones y campeonatos regionales. Uno de los más famosos de estos es la Copa Universitaria, celebrada desde 1959 como el campeonato nacional masculino. Para las mujeres, la primera Copa Universitaria se celebró solo en 1982, pero desde entonces, el fútbol femenino ha visto un crecimiento tremendo en los EE. UU., en parte gracias al éxito del equipo nacional en torneos internacionales.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crecimiento y Profesionalización
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Además del fútbol universitario, el fútbol profesional también se desarrolló en los EE. UU., aunque con más altibajos. La primera liga profesional fue la Liga de Fútbol Americano (ASL), fundada en 1921 y compuesta principalmente por equipos del Noreste. La ASL tuvo algunos años exitosos pero entró en conflicto con la federación de fútbol americano sobre la organización y regulación del juego. Esto llevó a una división y, en última instancia, al fin de la ASL en 1933.
      <br />
      <br />
      Después de la Segunda Guerra Mundial, varias ligas intentaron revivir el fútbol profesional, pero ninguna de ellas sobrevivió mucho tiempo. No fue hasta 1967 que se estableció una liga estable: la Liga de Fútbol de América del Norte (NASL). La NASL atrajo a grandes nombres a América, incluidos Pelé, Franz Beckenbauer y Johan Cruyff, y atrajo a grandes multitudes a los estadios. Sin embargo, la NASL tampoco pudo resistir los problemas financieros, la competencia de otros deportes y la falta de interés de los medios de comunicación. La NASL dejó de existir en 1984.
      <br />
      <br />
      Se necesitó hasta 1996 para que se estableciera nuevamente una liga profesional: la Major League Soccer (MLS). La MLS es actualmente la máxima división del fútbol estadounidense, compuesta por 27 equipos tanto de EE. UU. como de Canadá. La MLS ha evolucionado hacia una liga competitiva y atractiva, atrayendo tanto a jugadores nacionales como internacionales. La MLS también ha contribuido a la popularización del fútbol en América construyendo estadios, apoyando el desarrollo juvenil y colaborando con otras ligas.
      <br />
      <br />
      El fútbol profesional también ha influido en el fútbol universitario, y viceversa. Por ejemplo, hay jugadores que pasan a la MLS después de sus carreras universitarias, o jugadores que regresan a la universidad para completar sus estudios después de la MLS. También hay jugadores que juegan tanto para su universidad como para un equipo profesional, gracias a arreglos especiales. Además, algunos equipos profesionales colaboran con programas universitarios para reclutar y desarrollar talentos.
    </MKTypography>
  </MKBox>
</MKBox>

                                              





                                  







{/* Capítulo 2 */}
<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 2: El Fútbol Universitario Hoy
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Como vimos en el capítulo anterior, el fútbol universitario en América tiene una larga y rica tradición. Pero, ¿cómo es hoy en día? ¿Qué competiciones y conferencias existen? ¿Qué programas y equipos son los más prestigiosos y exitosos? ¿Y cómo puedes, como jugador, involucrarte en este emocionante mundo? En este capítulo, te daremos una visión general del fútbol universitario hoy y te mostraremos qué esperar si eliges una beca deportiva en América.
  </MKTypography>
  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Competiciones y Conferencias Importantes para el Fútbol
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Jugador de fútbol de Santa Clara en acción durante el torneo de la NCAA."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Jugadores de fútbol en acción durante un partido del torneo de la NCAA.
        </figcaption>
      </figure>
      Como se mencionó anteriormente, la NCAA es la principal organización que regula y organiza el fútbol universitario en los EE. UU. La NCAA consta de tres divisiones: División I, División II y División III. Cada división tiene sus propias reglas, requisitos y becas para estudiantes atletas.
      <br />
      <br />
      La División I es la división más alta y competitiva, donde compiten los mejores jugadores y equipos. La División I consta de aproximadamente 200 equipos masculinos y 300 equipos femeninos, divididos en diversas conferencias regionales. Una conferencia es un grupo de universidades que colaboran para organizar una competencia dentro de su región. Algunos ejemplos de conferencias conocidas son la Conferencia de la Costa Atlántica (ACC), la Conferencia Big Ten, la Conferencia Pac-12 y la Conferencia del Sureste (SEC).
      <br />
      <br />
      Cada año, los equipos dentro de su conferencia compiten por el título y un lugar en el campeonato nacional: la Copa Universitaria. La Copa Universitaria es un torneo eliminatorio que se celebra al final de la temporada, generalmente en noviembre y diciembre. Para clasificar para la Copa Universitaria, los equipos primero deben ganarse su lugar a través de su conferencia o a través de una invitación especial otorgada por un comité de selección basado en su desempeño durante la temporada. La Copa Universitaria consta de 48 equipos masculinos y 64 equipos femeninos, que compiten en diversas rondas hasta que queda un campeón.
      <br />
      <br />
      La División II y la División III son menos competitivas que la División I pero aún ofrecen muchas oportunidades para que los estudiantes atletas se desarrollen y disfruten del juego. La División II consta de aproximadamente 160 equipos masculinos y 200 equipos femeninos, también divididos en diferentes conferencias. La División II también tiene un campeonato nacional, que consta de 36 equipos masculinos y 48 equipos femeninos. La División III consta de aproximadamente 400 equipos masculinos y 430 equipos femeninos, que también compiten en conferencias. Sin embargo, la División III no ofrece becas a los estudiantes atletas, pero proporciona otras formas de apoyo financiero. La División III también tiene un campeonato nacional, que consta de 62 equipos masculinos y 64 equipos femeninos.
      <br />
      <br />
      Además de la NCAA, existen otras organizaciones que organizan el fútbol universitario en los EE. UU., como la Asociación Nacional de Atletismo Intercolegial (NAIA), la Asociación Nacional de Atletismo Universitario Junior (NJCAA) y la Asociación Atlética Universitaria Colegiada de Estados Unidos (USCAA). Estas organizaciones tienen sus propias divisiones, reglas, becas y campeonatos, que generalmente son más pequeños y menos conocidos que los de la NCAA.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programas Principales y Equipos Prestigiosos de Fútbol
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dentro de cada división y conferencia, existen ciertos programas y equipos que se destacan debido a su historia, desempeño, reputación, instalaciones y más. Estos programas y equipos a menudo atraen a los mejores jugadores, ofrecen las mejores becas, tienen los mejores entrenadores y personal, poseen instalaciones de entrenamiento y juego de primera clase, tienen la mayor base de fanáticos y atención de los medios, y con frecuencia producen jugadores para el circuito profesional.
      <br />
      <br />
      Es difícil compilar una lista definitiva de los mejores programas y equipos de fútbol universitario en América porque muchos factores entran en juego y las clasificaciones pueden cambiar cada año. Sin embargo, para darte una idea, mencionaremos algunos ejemplos de programas y equipos que a menudo se consideran los mejores en el fútbol universitario en América.
      <br />
      <br />
      Para los hombres, uno de los programas más prestigiosos es el de la Universidad de Maryland, que ha ganado el campeonato nacional 4 veces (1968, 2005, 2008 y 2018) y ha producido más de 20 jugadores que han jugado en la MLS, como Omar González, Graham Zusi y Zack Steffen. Otros programas líderes para hombres incluyen los de la Universidad de Virginia, la Universidad de Indiana, la Universidad de Stanford y la Universidad de Wake Forest.
      <br />
      <br />
      Para las mujeres, uno de los programas más dominantes es el de la Universidad de Carolina del Norte, que ha ganado el campeonato nacional más de 20 veces y ha producido más de 40 jugadoras que han jugado para el equipo nacional, como Mia Hamm, Kristine Lilly y Tobin Heath. Otros programas líderes para mujeres incluyen los de la Universidad de Notre Dame, la Universidad de Portland, la Universidad de Stanford y la Universidad Estatal de Florida.
      <br />
      <br />
      Estos son solo algunos ejemplos de los muchos programas y equipos excelentes en el fútbol universitario en América. Si quieres obtener más información sobre diferentes programas y equipos, puedes buscar en línea rankings, estadísticas, reseñas y testimonios de jugadores y entrenadores. También puedes ponerte en contacto con Sportbeursamerika.nl, que puede ayudarte a encontrar el mejor programa y equipo que se adapte a ti.
    </MKTypography>
  </MKBox>
</MKBox>






                                  







{/* Capítulo 3 */}
<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 3: Del Colegio a los Profesionales
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Muchos jóvenes futbolistas sueñan con jugar profesionalmente algún día y emular a sus ídolos en el más alto nivel. Pero, ¿cómo puedes hacer realidad ese sueño? ¿Cuáles son los pasos que debes seguir para pasar del colegio a los profesionales? ¿Y cuáles son las oportunidades y desafíos que encontrarás en el camino? En este capítulo, responderemos a estas preguntas y te mostraremos cómo el fútbol universitario puede servir como un trampolín hacia una carrera profesional.
  </MKTypography>
  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 El Camino hacia el Profesionalismo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Uno de los beneficios más significativos del fútbol universitario es que te ofrece la oportunidad de desarrollarte tanto atlética como académicamente mientras disfrutas de la vida estudiantil. Al jugar al fútbol y estudiar en América, no solo puedes mejorar tus habilidades futbolísticas, sino también ampliar tus conocimientos, ampliar tu red de contactos, moldear tu personalidad y mejorar tus perspectivas futuras.
      <br />
      <br />
      El fútbol universitario también puede ser un trampolín hacia una carrera profesional si así lo deseas. Hay diversas formas de pasar del fútbol universitario al circuito profesional, tanto en América como en el extranjero. Los métodos más comunes incluyen:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          SuperDraft de la MLS: Este es un evento anual en el que los equipos de la MLS seleccionan a los jugadores de un grupo de jugadores disponibles, la mayoría de los cuales provienen del fútbol universitario. El SuperDraft de la MLS consta de cuatro rondas, con cada equipo haciendo una selección por ronda. El orden de las selecciones se determina por diversos factores como el rendimiento del equipo en la temporada anterior, la lotería y posibles intercambios. El SuperDraft de la MLS es una de las principales formas para que los jugadores de fútbol universitario ingresen a la MLS y ofrece la oportunidad de asegurar un contrato y una beca.
        </li>
        <li>
          Regla de Jugador Creado en Casa de la MLS: Esta regla permite a los equipos de la MLS firmar jugadores de sus propias academias juveniles sin usar una selección en el draft. El objetivo de la regla es fomentar y recompensar el desarrollo de talentos locales. Muchos equipos de la MLS tienen asociaciones con programas universitarios, lo que les permite hacer un seguimiento y mentorizar a jugadores que juegan tanto para su universidad como para sus equipos juveniles. La Regla de Jugador Creado en Casa de la MLS es otra forma para que los jugadores de fútbol universitario ingresen a la MLS y ofrece la oportunidad de asegurar un contrato y una beca.
        </li>
        <li>
          Programa Generation Adidas: Patrocinado por Adidas, este programa está diseñado para ayudar a los jóvenes jugadores a hacer la transición del fútbol universitario al fútbol profesional. El programa selecciona a jugadores prometedores, típicamente menores de 20 años, que aún no se han graduado de su universidad. A estos jugadores se les ofrecen contratos por la MLS, lo que les permite participar en el SuperDraft de la MLS sin perder su elegibilidad universitaria. Esto significa que aún pueden regresar a su universidad para completar sus estudios si lo desean. El programa también proporciona becas educativas a los jugadores para cubrir sus costos de estudio.
        </li>
        <li>
          Oportunidades en el Extranjero: Estas son oportunidades para que los jugadores de fútbol universitario jueguen en el extranjero, por ejemplo, en Europa, Asia o América del Sur. Estas oportunidades suelen depender de varios factores como el nivel del jugador, la nacionalidad, la edad y la red de contactos. Algunos jugadores, por ejemplo, pueden aprovechar la doble nacionalidad o el origen familiar para calificar para un pasaporte extranjero, lo que les facilita jugar en otro país. Otros jugadores pueden beneficiarse de sus conexiones con cazatalentos, agentes o entrenadores que pueden ayudarlos a asegurar un contrato con un club extranjero. Las oportunidades en el extranjero son otra forma para que los jugadores de fútbol universitario persigan una carrera profesional, pero también vienen con más riesgos e incertidumbres.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estas son solo algunas de las formas en que puedes pasar del colegio a los profesionales, pero hay más. La clave es estar al tanto de las oportunidades disponibles y prepararte para los desafíos que puedas encontrar. También es importante ser realista acerca de tus posibilidades y expectativas y no poner todos tus huevos en una sola canasta. El fútbol universitario es una oportunidad fantástica para desarrollarte como jugador y como persona. Por lo tanto, es sabio mantener abiertas otras opciones y no solo centrarte en el fútbol, sino también en tus estudios, pasatiempos, amigos y familia.
      <br />
      <br />
      En el próximo capítulo, exploraremos la influencia del fútbol internacional en el fútbol universitario y cómo impacta e inspira a los jugadores de fútbol universitario.
    </MKTypography>
  </MKBox>
</MKBox>






                                  







{/* Capítulo 4 */}
<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 4: La Influencia del Fútbol Internacional
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El fútbol es un deporte global que conecta a millones de personas en todo el mundo. No es sorprendente que el fútbol internacional tenga una influencia significativa en el fútbol universitario en América. En este capítulo, exploraremos cómo las influencias extranjeras impactan e inspiran al fútbol universitario y cómo los jugadores estadounidenses encuentran su camino en tierras extranjeras.
  </MKTypography>
  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Influencias Extranjeras
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="El codiciado trofeo de la NCAA."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          El codiciado trofeo de la NCAA
        </figcaption>
      </figure>
      La popularidad del fútbol internacional en los Estados Unidos ha crecido significativamente en los últimos años. Según una encuesta Gallup de 2023, el fútbol es el tercer deporte más popular entre los estadounidenses, después del fútbol americano y el baloncesto. Esto se debe en parte a la creciente diversidad de la población estadounidense, la accesibilidad de los partidos internacionales a través de la televisión e internet, y el éxito del equipo nacional de Estados Unidos en el escenario mundial.
      <br />
      <br />
      Este interés en el fútbol internacional también ha tenido un impacto en el fútbol universitario. Muchos jugadores de fútbol universitario admiran a sus jugadores y equipos extranjeros favoritos, tratando de emular su estilo de juego y técnica. Algunos jugadores de fútbol universitario incluso tienen antecedentes extranjeros o doble nacionalidad, creando una conexión especial con un país o región en particular.
      <br />
      <br />
      Un ejemplo es Gio Reyna, hijo del ex internacional estadounidense Claudio Reyna. Gio Reyna nació en Inglaterra, donde su padre jugó para Sunderland y Manchester City. Creció con un amor por el fútbol inglés y jugó para los equipos juveniles del New York City FC. En 2023, se trasladó al Borussia Dortmund en Alemania, donde rápidamente se convirtió en uno de los talentos más destacados de Europa. También debutó en el equipo nacional de Estados Unidos, formando una nueva generación junto a otras jóvenes estrellas como Christian Pulisic y Weston McKennie.
      <br />
      <br />
      Otro ejemplo es Catarina Macario, una delantera brasileña que se trasladó a Estados Unidos a la edad de doce años. Jugó para la Universidad de Stanford, donde ganó el Trofeo Hermann dos veces como la mejor jugadora de fútbol universitario del país. También obtuvo la ciudadanía estadounidense, lo que le permitió representar al equipo nacional femenino de Estados Unidos. En 2023, firmó un contrato con el Olympique Lyon en Francia, uno de los equipos femeninos más importantes del mundo.
      <br />
      <br />
      Estos ejemplos ilustran cómo las influencias extranjeras enriquecen y diversifican el fútbol universitario y cómo los jugadores de fútbol universitario pueden alcanzar sus sueños en el fútbol internacional.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Estadounidenses en el Extranjero
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      No solo las influencias extranjeras impactan en el fútbol universitario, sino que los jugadores estadounidenses que buscan su fortuna en el extranjero también juegan un papel significativo. Un número creciente de jugadores estadounidenses elige mudarse a Europa u otros continentes después de sus carreras universitarias para competir contra los mejores jugadores del mundo.
      <br />
      <br />
      Uno de los ejemplos más exitosos es Clint Dempsey, quien jugó para la Universidad de Furman antes de unirse a la MLS. En 2023, se trasladó al Fulham en Inglaterra, donde emergió como un prolífico goleador. Anotó más de 50 goles para el club, incluido un gol histórico contra la Juventus en la Liga Europa. También jugó para el Tottenham Hotspur y el Seattle Sounders y fue un jugador clave para el equipo nacional de Estados Unidos.
      <br />
      <br />
      Otro caso de éxito es Tobin Heath, quien jugó para la Universidad de Carolina del Norte, donde ganó dos campeonatos nacionales. También jugó para varios clubes en Estados Unidos, Francia e Inglaterra, incluidos el Paris Saint-Germain y el Manchester United. Ganó cuatro medallas de oro olímpicas y dos Copas del Mundo de la FIFA con el equipo nacional femenino de Estados Unidos, donde es conocida por sus habilidades técnicas y creatividad.
      <br />
      <br />
      Estos ejemplos demuestran cómo los jugadores estadounidenses pueden desarrollarse y destacarse en el extranjero y cómo pueden poner al fútbol universitario en el mapa como un incubador de talentos.
    </MKTypography>
  </MKBox>
</MKBox>






                                  



<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Becas Deportivas y el Futuro de los Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Las becas deportivas ofrecen numerosas oportunidades para que los jóvenes atletas muestren su talento y desarrollen sus habilidades. Una de las oportunidades más significativas es obtener una beca deportiva para estudiar y competir a nivel universitario. En este capítulo, discutiremos la importancia de las becas deportivas y cómo Sport Scholarships America puede ayudarte a hacer realidad tu sueño.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 La Importancia de las Becas
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Una beca deportiva es un apoyo financiero proporcionado por una universidad a un estudiante-atleta para combinar estudios y deporte. Puede cubrir los costos de matrícula, libros, alojamiento, comidas y otros gastos. Además, puede ofrecer beneficios como tutoría académica, atención médica, instalaciones de entrenamiento y equipo.
      <br />
      <br />
      Obtener una beca deportiva es competitivo, con muchos factores que influyen en el proceso de adjudicación, incluyendo logros académicos, rendimiento deportivo, personalidad, motivación, liderazgo y adaptabilidad. Por lo tanto, es importante prepararse bien y destacarse entre la competencia.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Cómo Puede Ayudar Sport Scholarships America
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo de Sport Scholarships America, que muestra equipamiento deportivo y una gorra de graduación, representando el enfoque en becas deportivas para estudiantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America es una organización especializada en guiar a jóvenes talentos deportivos hacia becas deportivas. Con expertos apasionados y experimentados, Sport Scholarships America apoya a los estudiantes-atletas en cada paso del camino, desde la orientación inicial hasta la colocación final. Sus servicios incluyen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Una entrevista personal para discutir los deseos, posibilidades y expectativas del estudiante-atleta.</li>
        <li>Una evaluación profesional del nivel académico y deportivo del estudiante-atleta.</li>
        <li>Una extensa red de contactos con entrenadores, universidades y colegios.</li>
        <li>Promoción efectiva del perfil y videos del estudiante-atleta ante entrenadores potenciales.</li>
        <li>Asesoramiento experto sobre las mejores opciones basadas en las preferencias, metas y presupuesto del estudiante-atleta.</li>
        <li>Negociación de las mejores ofertas de becas en nombre del estudiante-atleta.</li>
        <li>Asistencia en la solicitud de documentos necesarios, como visa, expediente académico, diploma y pruebas de admisión.</li>
        <li>Preparación para la vida del estudiante-atleta durante su beca, incluyendo cultura, idioma, reglas y consejos.</li>
        <li>Seguimiento y atención posterior durante el período de la beca.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      ¿Quieres explorar oportunidades de becas y alcanzar tus metas deportivas y académicas? <Link to="/es/Servicios-Productos">Consulta la gama completa</Link> de servicios ofrecidos por Sport Scholarships America y programa una consulta hoy mismo.
    </MKTypography>
  </MKBox>
</MKBox>



          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;