/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import Image1 from "assets/images/Sports_Images/Ice Hockey1.jpeg";
import Image2 from "assets/images/Sports_Images/Ice Hockey2.jpg";
import Image3 from "assets/images/Sports_Images/Ice Hockey3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images

import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Ishockeystipend USA: Hvordan Studere og Spille Ishockey i Amerika', isSubheader: false },
  
    { id: 'Kapittel 1', title: 'Kapittel 1: Ishockeyens Historie og Utvikling i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Tidlige År og Collegens Rolle', isSubheader: true },
    { id: '1.2', title: '1.2 Vekst og Professionalisering', isSubheader: true },
  
    { id: 'Kapittel 2', title: 'Kapittel 2: Collegiat Ishockey i Dag', isSubheader: false },
    { id: '2.1', title: '2.1 Store Ligaer og Konferanser', isSubheader: true },
    { id: '2.2', title: '2.2 Topp Programmer og Prestisjetunge Lag', isSubheader: true },
  
    { id: 'Kapittel 3', title: 'Kapittel 3: Fra College til Profesjonelt Nivå', isSubheader: false },
    { id: '3.1', title: '3.1 Veien til Profesjonalitet', isSubheader: true },
    { id: '3.2', title: '3.2 NHL-draften og Andre Muligheter', isSubheader: true },
  
    { id: 'Kapittel 4', title: 'Kapittel 4: Internasjonale Påvirkninger', isSubheader: false },
    { id: '4.1', title: '4.1 Utenlandske Spillere i Collegiat Ishockey', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapittel 5: Idrettsstipender og Fremtiden til Utøvere', isSubheader: false },
    { id: '5.1', title: '5.1 Viktigheten av Stipender', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Scholarships America Kan Hjelpe', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>

























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Ishockeystipend USA: Hvordan Studere og Spille Ishockey i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Luftutsikt over et fullsatt stadion under en universitetskamp i ishockey."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Et fullsatt stadion under en universitetskamp
      </figcaption>
    </figure>
    Ishockey er en av de mest populære idrettene i Amerika. Det er en rask, fysisk og spennende idrett som tiltrekker seg millioner av fans. Ishockey spilles på ulike nivåer, fra amatør til profesjonell. Men et av de viktigste og prestisjetunge nivåene er collegiat ishockey.
    <br />
    <br />
    Collegiat ishockey er versjonen av ishockey som spilles av studenter ved universiteter og høyskoler i Amerika. Det er en del av det amerikanske utdanningssystemet, som kombinerer idrett og akademikk. Collegiat ishockey gir unge spillere muligheten til å utvikle seg både akademisk og idrettslig samtidig som de nyter studentlivet.
    <br />
    <br />
    Collegiat ishockey er også et springbrett til den profesjonelle verdenen. Mange spillere som spiller i NHL (National Hockey League) eller andre profesjonelle ligaer har først spilt collegiat ishockey. Collegiat ishockey er en utmerket måte å forberede seg på en karriere i sporten.
    <br />
    <br />
    Men hvordan kan en ung ishockeyspiller kvalifisere seg for collegiat ishockey? Det er her Sportbeursamerika.nl kan hjelpe deg. Jeg er spesialisert i å veilede student-idrettsutøvere som ønsker å få et idrettsstipend i Amerika. Et idrettsstipend er en økonomisk belønning du mottar fra et universitet eller høyskole for å studere og spille idrett der. Med et idrettsstipend kan du studere og spille ishockey i Amerika uten å pådra deg høye kostnader.
    <br />
    <br />
    I denne artikkelen vil vi gi deg mer informasjon om collegiat ishockey i Amerika, dens historie, utvikling, ligaer, lag, muligheter, internasjonale påvirkninger og, selvfølgelig, stipend. Jeg vil også fortelle deg hvordan jeg kan hjelpe deg med å få drømmen din til å gå i oppfyllelse. Er du klar til å lære mer om ishockeystipend i USA? Les videre!
  </MKTypography>
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Kapittel 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 1: Ishockeyens Historie og Utvikling i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Ishockey er en idrett som har eksistert i Amerika i over et århundre. Den har en rik og fascinerende historie som er nært knyttet til collegenes rolle. I dette kapittelet dykker vi dypere ned i opprinnelsen, veksten og professionaliseringen av ishockey i Amerika.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidlige År og Collegens Rolle
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ishockey oppstod i Canada, der det først ble spilt på 1800-tallet. Spillet spredte seg raskt til USA, og ble særlig populært i de nordøstlige statene. De første universitetslagene ble dannet mot slutten av 1800-tallet og begynnelsen av 1900-tallet, inkludert Yale, Harvard, Princeton og Dartmouth.
      <br />
      <br />
      Disse lagene spilte intercollegiate kamper, og trakk ofte store folkemengder. De spilte også mot kanadiske lag, som vanligvis var sterkere. For å heve spillestandarden begynte noen universiteter å rekruttere profesjonelle spillere som meldte seg inn som studenter. Denne praksisen ble møtt med kontrovers og kritikk da den kompromitterte idrettens amatørstatus.
      <br />
      <br />
      For å sette en stopper for denne praksisen ble Intercollegiate Hockey Association (IHA) grunnlagt i 1898, den første samlingen av collegiat ishockeyprogrammer i Amerika. IHA, noen ganger referert til som Intercollegiate Hockey League, inkluderte lag som Brown, Columbia, University of Pennsylvania og Yale. Denne organisasjonen var den eneste mesterskapsmyndigheten for collegiat ishockey på den tiden, og bestemte den de facto nasjonale mesteren basert på sesongprestasjon. IHA fortsatte å eksistere frem til 1913.
      <br />
      <br />
      Etter oppløsningen av IHA ble Intercollegiate Hockey League (IHL) etablert i 1912 av Harvard, Princeton og Yale. Denne ligaen var et svar på behovet for at de beste college-lagene fortsatte å konkurrere mot hverandre. IHL eksisterte frem til 1917 da aktiviteter ble suspendert på grunn av første verdenskrig.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Vekst og Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Gjennom det 20. århundre vokste collegiat ishockey til å bli en nasjonal sport, spilt av hundrevis av lag i ulike divisjoner og konferanser. Collegiat ishockey ble også i økende grad påvirket av oppkomsten av profesjonelle ishockeyligaer, som NHL (grunnlagt i 1917) og World Hockey Association (grunnlagt i 1972).
      <br />
      <br />
      Disse profesjonelle ligaene ga muligheter for talentfulle collegiat-spillere å avansere til et høyere nivå. Mange collegiat-spillere ble valgt i NHL Draft, den årlige begivenheten der NHL-lag velger nye spillere. Den første NHL-draften der en collegiat-spiller ble valgt fant sted i 1967. Al Karlander, en spiller fra Michigan Tech, ble valgt av Detroit som det 17. samlevalget det året. Siden den gang har over tusen collegiat-spillere blitt draftet av NHL-lag.
      <br />
      <br />
      Collegiat ishockey har også vært vitne til betydningsfulle øyeblikk i sportens historie. En av de mest minneverdige var "Mirakelet på Isen" i 1980 da det amerikanske olympiske laget, primært bestående av collegiat-spillere, beseiret Sovjetunionen i en historisk kamp under Vinter-OL i Lake Placid. Dette var en bemerkelsesverdig prestasjon da sovjetere ble ansett som det beste ishockeylaget i verden på den tiden.
      <br />
      <br />
      Collegiat ishockey har derfor en lang og imponerende historie, som vitner om lidenskapen og talentet til amerikanske spillere. Collegiat ishockey har også bidratt til utviklingen og innovasjonen av sporten ved å introdusere nye regler, taktikker og stiler.
    </MKTypography>
  </MKBox>
</MKBox>

                                              





                                  







<MKBox id="Kapittel 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 2: Collegiat Ishockey i Dag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Collegiat ishockey er en av de mest konkurransedyktige og spennende idrettene i Amerika i dag. Det spilles av over 400 lag, delt inn i tre NCAA-divisjoner, ulike konferanser og regionale soner. I dette kapittelet gir vi en oversikt over de store ligaene og konferansene, topprogrammer og prestisjefylte lag, samt strukturen for sesongen og mesterskapet.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Store Ligaer og Konferanser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Spillere fra Massachusetts feirer seier i collegiat ishockey."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Spillere fra Massachusetts feirer seier
        </figcaption>
      </figure>
      NCAA (National Collegiate Athletic Association) er organisasjonen som regulerer collegiat ishockey. NCAA deler lagene inn i tre divisjoner basert på nivå, budsjett og antall stipend de tilbyr. Disse divisjonene er:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Divisjon I: Dette er den høyeste divisjonen der de beste lagene og spillerne konkurrerer. Divisjon I består av 60 lag, delt inn i seks konferanser: Atlantic Hockey, Big Ten, ECAC Hockey, Hockey East, NCHC (National Collegiate Hockey Conference) og WCHA (Western Collegiate Hockey Association). Disse konferansene organiserer egne konkurranser og turneringer gjennom sesongen.
        </li>
        <li>
          Divisjon II: Dette er en mellomdivisjon med færre lag og spillere. Det er bare syv lag i Divisjon II, og de har ikke sin egen konferanse. De konkurrerer vanligvis mot lag fra Divisjon III eller andre ligaer.
        </li>
        <li>
          Divisjon III: Dette er den laveste divisjonen der de fleste lagene og spillerne deltar. Divisjon III består av 84 lag, delt inn i syv konferanser: CCC (Commonwealth Coast Conference), MASCAC (Massachusetts State Collegiate Athletic Conference), MIAC (Minnesota Intercollegiate Athletic Conference), NCHA (Northern Collegiate Hockey Association), NEHC (New England Hockey Conference), SUNYAC (State University of New York Athletic Conference) og UCHC (United Collegiate Hockey Conference). Disse konferansene organiserer også egne konkurranser og turneringer gjennom sesongen.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      I tillegg til NCAA-divisjonene og konferansene, finnes det også andre ligaer og organisasjoner som tilbyr collegiat ishockey.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Topp Programmer og Prestisjetunge Lag
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Collegiat ishockey har flere topp programmer og prestisjetunge lag som skiller seg ut på grunn av sin suksess, tradisjon, rykte og innflytelse. Noen av disse programmene og lagene inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          University of North Dakota: Et av de mest vellykkede collegiat ishockeyprogrammene i Amerika, konkurrerer i NCHC-konferansen i Divisjon I. Universitetet har vunnet flere NCAA-mesterskap og har produsert mange spillere som har spilt i NHL, inkludert kjente navn som Jonathan Toews, Zach Parise og T.J. Oshie.
        </li>
        <li>
          Boston College: Et fremtredende collegiat ishockeyprogram i Amerika, konkurrerer i Hockey East-konferansen i Divisjon I. Boston College har flere nasjonale mesterskap på sin merittliste. Programmet er også kjent for å ha utviklet over 80 NHL-spillere, med kjente alumner som Johnny Gaudreau, Patrick Eaves og Brian Gionta.
        </li>
        <li>
          University of Minnesota: Dette historiske collegiat ishockeyprogrammet, konkurrerer i Big Ten-konferansen i Divisjon I, har oppnådd et imponerende antall nasjonale mesterskap. Det er kjent for å produsere et rekordstort antall spillere som har spilt i NHL, inkludert stjerner som Phil Kessel, Paul Martin og Blake Wheeler.
        </li>
        <li>
          University of Wisconsin: Et av de mest prestisjefylte collegiat ishockeyprogrammene i Amerika, konkurrerer i Big Ten-konferansen i Divisjon I. Wisconsin har vunnet flere nasjonale mesterskap og har også trent en betydelig antall spillere som har spilt i NHL, inkludert Ryan Suter, Joe Pavelski og Dany Heatley.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Disse programmene og lagene er bare noen få eksempler på de mange som collegiat ishockey har å tilby. Det er mange flere lag som har sin egen historie, kultur og identitet. Collegiat ishockey er en mangfoldig og dynamisk idrett med mye å tilby spillere og fans.
    </MKTypography>
  </MKBox>
</MKBox>






                                  








<MKBox id="Kapittel 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 3: Fra College til Profesjonelt Nivå
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Collegiat ishockey er ikke bare en idrett; det er også et læringsområde. Mange spillere som deltar i collegiat ishockey har ambisjoner om å avansere til det profesjonelle nivået. I dette kapittelet vil vi diskutere hvordan collegiat ishockey kan være et springbrett til NHL eller andre profesjonelle ligaer, hvilke tidligere studenter som har lykkes i den profesjonelle ishockeyverdenen, og hvilke muligheter som finnes utenfor NHL.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Veien til Profesjonalitet
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Collegiat ishockey er en utmerket måte å forberede seg til en karriere innen idrett. Ved å spille collegiat ishockey kan du forbedre ferdighetene dine, kunnskapen din og erfaringen din samtidig som du tjener en grad. Collegiat ishockey gir deg også muligheten til å konkurrere mot andre talentfulle spillere som utfordrer og inspirerer deg.
      <br />
      <br />
      En av de viktigste fordelene med collegiat ishockey er at det øker synligheten og eksponeringen din. Å spille collegiat ishockey kan tiltrekke oppmerksomheten til speidere, trenere og managere fra profesjonelle lag som er på jakt etter nye spillere. Du kan også delta på utstillinger, leire og klinikker for å vise frem ferdighetene dine til potensielle arbeidsgivere.
      <br />
      <br />
      En annen fordel med collegiat ishockey er at det tilbyr fleksibilitet og alternativer. Ved å spille collegiat ishockey kan du utsette beslutningen om fremtiden din. Du kan velge å gå over til et profesjonelt lag etter ditt første, andre, tredje eller fjerde år, avhengig av situasjonen og preferansen din. Du kan også velge å fullføre studiene dine før du blir profesjonell.
      <br />
      <br />
      Collegiat ishockey har produsert mange spillere som har lyktes i den profesjonelle ishockeyverdenen. Noen av disse spillerne inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Jack Eichel: Han spilte én sesong for Boston University i Hockey East-konferansen i Divisjon I. Han vant Hobey Baker-prisen som årets beste spiller i 2015. Han ble valgt som nummer to totalt i NHL-draften i 2015 av Buffalo Sabres, der han ble kaptein og stjerne. I 2023 vant han Stanley Cup med Vegas Golden Knights.
        </li>
        <li>
          Cale Makar: Han spilte to sesonger for UMass Amherst i Hockey East-konferansen i Divisjon I. Han vant også Hobey Baker-prisen som årets beste spiller i 2019. Han ble valgt som nummer fire totalt i NHL-draften i 2017 av Colorado Avalanche, der han for tiden spiller som en av de beste forsvarsspillerne i ligaen.
        </li>
        <li>
          Hilary Knight: Hilary Knight spilte fire sesonger for University of Wisconsin i WCHA-konferansen i Divisjon I. Hun var en svært talentfull spiller og ble nominert som en av de ti beste finalistene til Patty Kazmaier Memorial Award i 2009, 2011 og 2012. Selv om hun ikke vant prisen, var hun to ganger utpekt til First Team All-American. Knight spilte senere for Boston Pride i NWHL (National Women’s Hockey League) og det amerikanske landslaget, hvor hun vant to olympiske sølvmedaljer og flere verdensmesterskap.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Disse spillerne er bare noen få eksempler på de mange som har gjort collegiat ishockeykarrieren sin om til profesjonelle karrierer. Collegiat ishockey er en bevist vei til profesjonalitet for mange spillere.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 NHL-draften og Andre Muligheter
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      NHL (National Hockey League) er den fremste profesjonelle ishockeylegen i verden. Det er drømmen til mange unge spillere å en dag spille i NHL. En av måtene å oppnå det på er gjennom NHL-draften, det årlige arrangementet der NHL-lag velger nye spillere.
      <br />
      <br />
      NHL-draften er et spennende og betydningsfullt øyeblikk for mange collegiat spillere. Det er en mulighet til å bli valgt av et NHL-lag, som tilbyr deg en kontrakt for å spille for dem. Det anerkjenner også talentet ditt, potensialet ditt og harde arbeidet ditt.
      <br />
      <br />
      NHL-draften består av syv runder der hvert NHL-lag tar svinger med å velge en spiller. Rekkefølgen for valg bestemmes av et lotteri, der lag som hadde de dårligste resultatene i forrige sesong har større sjanse for å velge først. NHL-draften finner vanligvis sted i juni eller juli etter slutten av collegiat ishockey-sesongen.
      <br />
      <br />
      For å være kvalifisert for NHL-draften, må du oppfylle visse kriterier. Du må være en nordamerikansk spiller mellom 18 og 20 år eller en internasjonal spiller mellom 18 og 21 år. Du må ikke ha blitt tidligere valgt av et NHL-lag eller ha gitt opp rettighetene dine.
      <br />
      <br />
      Å bli valgt av et NHL-lag betyr ikke nødvendigvis at du må spille umiddelbart for dem. Du kan velge å returnere til collegiat laget ditt og fullføre studiene dine samtidig som du beholder rettighetene til NHL-laget. Du kan også velge å bli med i NHL-laget eller deres affiliate i mindre liga, der du kan videreutvikle deg og trene.
      <br />
      <br />
      Imidlertid er ikke NHL-draften den eneste måten å nå NHL på. Det er andre muligheter, for eksempel free agency. Dette er når du, som spiller, ikke har en kontrakt med et NHL-lag og er fri til å forhandle med hvilket som helst lag du ønsker. Dette kan skje hvis du ikke ble draftet eller hvis kontrakten din med et NHL-lag utløper eller avbrytes. Som fri agent kan du prøve å sikre deg en kontrakt med et NHL-lag som er interessert i deg.
      <br />
      <br />
      Likevel er ikke NHL den eneste profesjonelle ishockeyligaen i verden. Det er andre ligaer der du kan spille som spiller og tjene til livets opphold. Noen av disse ligaene inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          KHL (Kontinental Hockey League): Dette er den fremste profesjonelle ishockeyligaen i Europa og Asia. Den består av 24 lag fra åtte land: Russland, Finland, Hviterussland, Kasakhstan, Kina, Latvia, Slovakia og Tsjekkia. Den tilbyr et høyt nivå av spill og lønn, og tiltrekker spillere fra ulike land.
        </li>
        <li>
          SHL (Swedish Hockey League): Dette er den fremste profesjonelle ishockeyligaen i Sverige. Den består av 14 lag som konkurrerer om nasjonalt mesterskap. Den er kjent for sitt raske og tekniske spill og har produsert mange spillere som har spilt i NHL.
        </li>
        <li>
          Liiga (Finnish Hockey League): Dette er den fremste profesjonelle ishockeyligaen i Finland. Den består av 15 lag som konkurrerer om nasjonalt mesterskap. Den er kjent for sitt fysiske og taktiske spill og har også produsert mange spillere som har spilt i NHL.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Disse ligaene er bare noen få eksempler på de mange ligaene rundt om i verden. Det er mange flere ligaer i andre land, inkludert Sveits, Tyskland, Frankrike, Norge, Danmark, Østerrike, Polen, Japan og Australia. Disse ligaene tilbyr ulike nivåer av spill, lønn og livsstiler til spillerne.
      <br />
      <br />
      Collegiat ishockey er derfor ikke enden på veien for mange spillere. I stedet er det begynnelsen på en spennende reise inn i den profesjonelle verdenen. Collegiat ishockey tilbyr mange muligheter og alternativer for spillere som følger drømmene sine.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  







<MKBox id="Kapittel 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 4: Internasjonale Påvirkninger
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Collegiat ishockey er en idrett som ikke bare er amerikansk, men også internasjonal. Collegiat ishockey påvirkes av tilstedeværelsen og prestasjonene til utenlandske spillere i amerikanske collegiatkonkurranser. Collegiat ishockey påvirker også mulighetene og utviklingen til amerikanske spillere i utlandet. I dette kapittelet vil vi diskutere rollen og innvirkningen av internasjonale spillere i collegiat ishockey og mulighetene for amerikanske spillere til å spille internasjonalt.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Utenlandske Spillere i Collegiat Ishockey
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Quinnipiac ishockeyspillere feirer et mål."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Quinnipiac-spillere feirer et mål
        </figcaption>
      </figure>
      Collegiat ishockey er en idrett som er åpen for spillere fra hele verden. Det er mange utenlandske spillere som kommer til Amerika for å spille collegiat ishockey, med forskjellige bakgrunner, kulturer og nasjonaliteter. Noen av disse spillerne inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Daryl Watts: Hun er en kanadisk ishockeyspiller som spilte for både Boston College og Wisconsin i NCAA Divisjon I i to sesonger. Hun vant Patty Kazmaier Award i 2018 som førsteårsstudent, noe som gjorde henne til den første førsteårsstudenten som vant prisen. Watts spilte deretter for Toronto Six i Premier Hockey Federation (PHF), tidligere kjent som National Women’s Hockey League (NWHL), og signerte en rekordkontrakt med Toronto Six i 2023.
        </li>
        <li>
          Rod Brind'Amour: Født i Canada, spilte han collegiat ishockey ved Michigan State University før han gikk videre til NHL. Han hadde en svært vellykket profesjonell karriere, med høydepunkter som inkluderte å vinne Stanley Cup.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Disse spillerne er bare noen få eksempler på de mange utenlandske spillerne som spiller eller har spilt collegiat ishockey. Det er mange flere spillere fra andre land som Sverige, Russland, Tsjekkia, Tyskland, Frankrike, Sveits, Danmark, Japan og Australia.
      <br />
      <br />
      Disse utenlandske spillerne har en viktig rolle og innvirkning på collegiat ishockey. De bringer sin egen stil, ferdigheter og erfaring til amerikanske collegiatkonkurranser. De beriker nivået og mangfoldet i spillet. De skaper også mer interesse og oppmerksomhet for collegiat ishockey i sine egne land og regioner.
      <br />
      <br />
      Collegiat ishockey er en idrett som nyter godt av internasjonale påvirkninger. Det er en idrett som ønsker og verdsetter spillere fra hele verden.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 5: Idrettsstipender og Fremtiden til Utøvere
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrettsstipender gir mange muligheter for unge utøvere til å vise sitt talent og utvikle sine ferdigheter. En av de mest betydningsfulle mulighetene er å få et idrettsstipend for å studere og konkurrere på college-nivå. I dette kapittelet vil vi diskutere viktigheten av idrettsstipender og hvordan Sport Scholarships America kan hjelpe deg med å oppfylle drømmen din.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Viktigheten av Stipender
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et idrettsstipend er økonomisk støtte gitt av et universitet til en studentutøver for å kombinere studier og idrett. Det kan dekke kostnadene for skolepenger, bøker, bolig, måltider og andre utgifter. I tillegg kan det tilby fordeler som akademisk veiledning, medisinsk hjelp, treningsfasiliteter og utstyr.
      <br />
      <br />
      Å få et idrettsstipend er konkurransedyktig, med mange faktorer som spiller en rolle i tildelingsprosessen, inkludert akademiske prestasjoner, idrettslige resultater, personlighet, motivasjon, lederegenskaper og tilpasningsevne. Det er derfor viktig å forberede seg godt og skille seg ut fra konkurransen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Scholarships America Kan Hjelpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo for Sport Scholarships America, som viser sportsutstyr og en eksamenskappe, symboliserer fokuset på idrettsstipender for studentutøvere."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo for Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America er en organisasjon spesialisert i å veilede unge idrettstalenter til idrettsstipender. Med lidenskapelige og erfarne eksperter støtter Sport Scholarships America studentutøvere på hvert trinn i reisen, fra første orientering til endelig plassering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig inntaksintervju for å diskutere studentutøverens ønsker, muligheter og forventninger.</li>
        <li>En profesjonell evaluering av studentutøverens akademiske og idrettslige nivå.</li>
        <li>Et omfattende nettverk av kontakter med trenere, universiteter og høyskoler.</li>
        <li>Effektiv markedsføring av studentutøverens profil og videoer til potensielle trenere.</li>
        <li>Ekspertråd om de beste alternativene basert på studentutøverens preferanser, mål og budsjett.</li>
        <li>Forhandlinger om de beste stipendtilbudene på vegne av studentutøveren.</li>
        <li>Hjelp til å søke om nødvendige dokumenter, som visum, transkripsjon, diploma og opptaksprøver.</li>
        <li>Forberedelse til studentutøverens liv under stipendet, inkludert kultur, språk, regler og tips.</li>
        <li>Oppfølging og oppfølging under stipendperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vil du utforske stipendmuligheter og oppnå dine idrettslige og akademiske mål? <Link to="/no/Tjenester-Produkter">Se det komplette utvalget</Link> av tjenester som tilbys av Sport Scholarships America og planlegg en konsultasjon i dag.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;