/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import React, { useState } from 'react'; // Step 1: Import useState
import { Link } from 'react-router-dom';


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Football1.jpg";
import Image2 from "assets/images/Sports_Images/Football2.webp";
import Image3 from "assets/images/Sports_Images/Football3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";
import "../../../../H1asH3.css"


// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'American Football Stipendien in den USA: Wie man einen Platz im Universitätsteam sichert', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapitel 1: Die Geschichte und Entwicklung des American Football', isSubheader: false },
    { id: '1.1', title: '1.1 Die Anfangsjahre', isSubheader: true },
    { id: '1.2', title: '1.2 Wachstum und Professionalisierung', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapitel 2: College-Football', isSubheader: false },
    { id: '2.1', title: '2.1 Wichtige Ligen und Konferenzen', isSubheader: true },
    { id: '2.2', title: '2.2 Spielerentwicklung', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapitel 3: Vom College zur NFL', isSubheader: false },
    { id: '3.1', title: '3.1 Der Weg zur NFL', isSubheader: true },
    { id: '3.2', title: '3.2 Andere professionelle Möglichkeiten', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapitel 4: Der Einfluss des American Footballs auf die amerikanische Kultur', isSubheader: false },
    { id: '4.1', title: '4.1 Kultureller Einfluss', isSubheader: true },
    { id: '4.2', title: '4.2 Vorbilder und Inspiration', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapitel 5: Sportstipendien und die Zukunft der Athleten', isSubheader: false },
    { id: '5.1', title: '5.1 Die Bedeutung von Stipendien', isSubheader: true },
    { id: '5.2', title: '5.2 Wie Sportstipendien Amerika helfen können', isSubheader: true },
  ];
  
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhaltsverzeichnis
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verstecken</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Anzeigen</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
    <MKTypography variant="h1" className="h1-as-h3" mb={2}>
        American Football Stipendien in den USA: Wie man einen Platz im Universitätsteam sichert
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
            <img
                src={Image1}
                alt="Luftaufnahme eines überfüllten College-Footballspiels an der Indiana University, mit Spielern auf dem Feld und Fans auf den Tribünen."
                style={{
                    maxWidth: "100%",
                    border: "2px solid #fff",
                    boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                    cursor: "pointer",
                    outline: "none",
                }}
                onClick={() => openModal1(Image1)}
            />
            <figcaption style={{ 
                textAlign: 'left', 
                fontWeight: 'bold', 
                fontSize: '12px', 
                wordWrap: 'break-word', 
            }}>
                Fußballspiel der Indiana University von den Tribünen aus.
            </figcaption>
        </figure>
        American Football ist ein Sport, der Ende des 19. Jahrhunderts in den Vereinigten Staaten entstand. Es ist ein Mannschaftssport, bei dem zwei Teams mit je elf Spielern versuchen, einen ovalen Ball über die Torlinie des Gegners zu bringen oder ihn zwischen den Torpfosten zu schießen. Das Spiel zeichnet sich durch körperlichen Kontakt, Taktik, Strategie und Spektakel aus.
        <br/>
        <br/>
        American Football ist nicht nur ein Sport; es ist auch eine Kultur. Es ist ein wesentlicher Bestandteil der amerikanischen Identität, Werte und Traditionen. Es ist eine Quelle des Stolzes, der Leidenschaft und der Inspiration für Millionen von Menschen. Es ist auch eine Industrie, die Milliarden von Dollar generiert und Tausende von Arbeitsplätzen schafft.
        <br/>
        <br/>
        Bevor Sie jedoch als Profispieler in der NFL erfolgreich sein können, müssen Sie Ihr Talent auf Hochschulebene zeigen. College-Football ist ein wichtiger Bestandteil der Spielerentwicklung und bietet jungen Athleten die Möglichkeit, ihre Fähigkeiten zu verbessern, eine Ausbildung zu erhalten und ein Netzwerk aufzubauen.
        <br/>
        <br/>
        Allerdings ist College-Football nicht für jeden leicht zugänglich. Tausende von Teams und Spielern konkurrieren um eine begrenzte Anzahl von Plätzen und Stipendien. Darüber hinaus gibt es viele Regeln und Anforderungen, die Sie erfüllen müssen, um für ein Universitätsteam zugelassen zu werden.
        <br/>
        <br/>
        Hier kann Ihnen Sportbeursamerika.nl helfen. Ich habe mich darauf spezialisiert, junge Talente mit Universitätsteams zu vermitteln, die Verstärkung suchen. Ich kann Ihnen helfen, ein Sportstipendium zu finden, Ihr Visum zu arrangieren, sich auf Ihre Zulassungsprüfungen vorzubereiten und mit Trainern zu verhandeln. Mit meiner Unterstützung können Sie Ihren Traum verwirklichen, in Amerika zu studieren und zu spielen.
        <br/>
        <br/>
        Möchten Sie wissen, wie? Lesen Sie weiter!
    </MKTypography>
</MKBox>





{/* Hauptkapitel und Unterkapitel mit Platzhaltertext */}
<MKBox id="Kapitel 1: Die Geschichte und Entwicklung des American Football" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Die Geschichte und Entwicklung des American Football
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    American Football ist ein Sport, der im späten 19. Jahrhundert in den Vereinigten Staaten entstand. Er ist ein Nachkomme von Rugby und Fußball, zwei Sportarten, die in Europa populär waren. In diesem Kapitel werden wir Ihnen mehr über die Anfangsjahre und das Wachstum sowie die Professionalisierung des American Football erzählen.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Die Anfangsjahre
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Die Ursprünge des American Football werden oft einem Spiel zwischen zwei Universitätsteams im Jahr 1869 zugeschrieben: Rutgers und Princeton. Allerdings ähnelte dieses Spiel eher einer Form von Fußball als dem modernen American Football. Es gab 25 Spieler pro Team, der Ball durfte nicht mit den Händen berührt werden und es gab keine Torpfosten.
      <br/>
      <br/>
      Das erste Spiel, das dem modernen American Football ähnelte, fand 1874 zwischen Harvard und McGill statt. Dieses Spiel war eine Kombination aus Rugby und Fußball, bei dem der Ball mit den Händen getragen werden durfte und Torpfosten vorhanden waren. Harvard gewann das Spiel mit 3-0.
      <br/>
      <br/>
      Die Rolle der Universitäten bei der Bildung der ersten Teams und Ligen war entscheidend. Sie waren die Pioniere, die die Regeln und den Stil des Spiels definierten. Im Jahr 1873 wurde der erste interkollegiale Fußballverband gegründet, bestehend aus Yale, Columbia, Princeton und Harvard. Im Jahr 1880 wurde Walter Camp, ein Spieler und Trainer von Yale, als "Vater des American Football" betrachtet, weil er bedeutende Innovationen einführte, wie die Reduzierung der Anzahl der Spieler auf 11, die Einführung der Scrimmage-Linie und des Downs-Systems.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Wachstum und Professionalisierung
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Die Entwicklung des American Footballs zu einem nationalen Phänomen begann im späten 19. und frühen 20. Jahrhundert. Das Spiel gewann unter Studenten, Zuschauern und den Medien an Popularität. Es wurde auch zunehmend gewalttätig und gefährlich, was zu zahlreichen Verletzungen und sogar Todesfällen führte. Im Jahr 1905 drohte Präsident Theodore Roosevelt, das Spiel zu verbieten, wenn keine Reformen durchgeführt würden. Dies führte zur Gründung der National Collegiate Athletic Association (NCAA) im Jahr 1906, einer Organisation, die für die Regulierung und Organisation des College-Sports verantwortlich ist.
      <br/>
      <br/>
      Der Einfluss des professionellen American Footballs auf das College-Spiel war ebenfalls bedeutend. Die erste professionelle American-Football-Liga wurde 1892 gegründet, wurde aber erst 1920 mit der Gründung der American Professional Football Association (APFA), später umbenannt in National Football League (NFL), wirklich erfolgreich. Die NFL zog viele talentierte Spieler von den Colleges ab und zwang sie, ihr Niveau zu erhöhen, um wettbewerbsfähig zu bleiben. Die NFL wurde auch eine wichtige Einnahmequelle und Plattform für Universitäten, die oft ihre Stadien an professionelle Teams vermieteten.
      <br/>
      <br/>
      In diesem Kapitel haben wir Ihnen mehr über die Geschichte und Entwicklung des American Footballs erzählt. Im nächsten Kapitel werden wir weitere Einblicke in den American Football auf Universitätsebene geben.
    </MKTypography>
  </MKBox>
</MKBox>









                                              





                                  






<MKBox id="Kapitel 2" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Kapitel 2: College-Football
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        College-Football ist einer der beliebtesten und wettbewerbsfähigsten Sportarten in den Vereinigten Staaten. Jedes Jahr kämpfen mehr als tausend Teams und Hunderttausende von Spielern um den nationalen Titel, regionale Meisterschaften und prestigeträchtige Bowl-Spiele. In diesem Kapitel werden wir Ihnen mehr über die wichtigsten Ligen und Konferenzen im College-Football sowie die Entwicklung von Spielern auf Hochschulebene erzählen.
    </MKTypography>

    <MKBox id="2.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.1 Wichtige Ligen und Konferenzen
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>

            Eine Übersicht über die NCAA-Divisionen und wichtige Konferenzen im College-Football. Die NCAA ist die Organisation, die für die Regulierung und Organisation des Hochschulsports in den Vereinigten Staaten zuständig ist. Die NCAA teilt die Teams in drei Divisionen ein: Division I, Division II und Division III. Division I ist die höchste Division und besteht aus zwei Unterabteilungen: der Football Bowl Subdivision (FBS) und der Football Championship Subdivision (FCS). FBS-Teams sind die angesehensten und lukrativsten Teams, die um einen Platz im College Football Playoff kämpfen, einem System, das seit 2014 den nationalen Meister bestimmt. FCS-Teams sind weniger bekannt und wohlhabend, haben jedoch ihr eigenes Playoff-System, um ihren Meister zu bestimmen.
            <br/>
            <br/>
            Innerhalb jeder Division und Unterabteilung gibt es verschiedene Konferenzen, die Gruppen von Teams sind, die geografisch oder historisch miteinander verbunden sind. Jede Konferenz hat ihren eigenen Zeitplan, ihre eigenen Regeln und ihre eigene Meisterschaft. Die wichtigsten Konferenzen in FBS sind: Atlantic Coast Conference (ACC), Big Ten Conference, Big 12 Conference, Pac-12 Conference, Southeastern Conference (SEC) und American Athletic Conference (AAC). Die wichtigsten Konferenzen in FCS sind: Big Sky Conference, Colonial Athletic Association (CAA), Missouri Valley Football Conference (MVFC) und Southern Conference (SoCon).
            <br/>
            <br/>
            Die prestigeträchtigsten College-Programme und ihre Auswirkungen auf den Sport. Einige Hochschulen haben eine lange und erfolgreiche Geschichte im College-Football, die bis in die Anfangsjahre des Sports zurückreicht. Sie haben viele nationale Titel, Bowl-Spiele, individuelle Auszeichnungen und professionelle Spieler hervorgebracht. Sie haben auch viele Fans, Medienaufmerksamkeit und finanzielle Ressourcen. Einige Beispiele für diese Hochschulen sind: Alabama, Notre Dame, Ohio State, Oklahoma, USC, Michigan, Texas, Clemson, Florida State und LSU.
        </MKTypography>
    </MKBox>
    <MKBox id="2.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.2 Spielerentwicklung
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image2}
                    alt="Junges Tom Brady in Aktion als Quarterback für die University of Michigan während eines College-Footballspiels, gekleidet in der blau-gelben Uniform des Teams."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal2(Image2)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Tom Brady in Aktion für die University of Michigan.
                </figcaption>
            </figure>
            Wie College-Teams zur technischen und physischen Entwicklung von Spielern beitragen. College-Teams bieten jungen Spielern die Möglichkeit, ihre Fähigkeiten unter der Anleitung erfahrener Trainer und Mitarbeiter zu verbessern. Sie bieten auch Zugang zu hochwertigen Einrichtungen, Ausrüstungen und medizinischer Versorgung. Darüber hinaus setzen sie die Spieler einem hohen Wettbewerbsniveau aus, wo sie auf andere talentierte Spieler aus dem ganzen Land treffen.
            <br/>
            <br/>
            Beispiele für berühmte Spieler, die ihre Fähigkeiten auf Hochschulebene geschärft haben. Viele professionelle Spieler haben ihre Karriere auf Hochschulebene begonnen, wo sie durch ihre Leistung und ihr Potenzial aufgefallen sind. Einige Spieler haben sogar Auszeichnungen wie die Heisman Trophy, die prestigeträchtigste individuelle Auszeichnung im College-Football, gewonnen. Beispiele für diese Spieler sind: Tom Brady (Michigan), Peyton Manning (Tennessee), Joe Montana (Notre Dame), Barry Sanders (Oklahoma State), Jerry Rice (Mississippi Valley State) und Tim Tebow (Florida).
            <br/>
            <br/>
            In diesem Kapitel haben wir Ihnen mehr über College-Football erzählt. Im nächsten Kapitel werden wir Ihnen mehr über den Weg zur NFL und andere professionelle Möglichkeiten erzählen.

        </MKTypography>
    </MKBox>
</MKBox>













                                              






                                  








<MKBox id="Kapitel 3" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Kapitel 3: Vom College zur NFL
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        College-Football ist ein Sprungbrett zur National Football League (NFL), der höchsten professionellen Liga der Welt. Jedes Jahr werden Hunderte von Spielern von NFL-Teams im NFL-Draft ausgewählt, einer Veranstaltung, bei der Teams abwechselnd einen Spieler aus einem Pool von College-Spielern auswählen. In diesem Kapitel werden wir Ihnen mehr über den Weg zur NFL und andere professionelle Möglichkeiten erzählen.
    </MKTypography>

    <MKBox id="3.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.1 Der Weg zur NFL
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Wie College-Football als Sprungbrett zur National Football League (NFL) fungiert. College-Football ist die Hauptquelle für Talente für die NFL. Die meisten Spieler in der NFL haben zuerst auf Hochschulebene gespielt. College-Football bietet Spielern die Möglichkeit, sich gegen andere Top-Athleten zu beweisen, ihre Fähigkeiten zu entwickeln, ihre Statistiken zu verbessern und ihren Draft-Wert zu steigern. College-Football gibt Spielern auch Exposition und Anerkennung durch Scouts, Trainer und Medien.
            <br/>
            <br/>
            Etwa 250 Spieler werden jedes Jahr im NFL-Draft ausgewählt, der normalerweise im April stattfindet. Der Draft besteht aus sieben Runden, jede mit 32 Picks (einer für jedes Team). Die Reihenfolge der Picks wird durch das Ergebnis der vorherigen Saison bestimmt, wobei das schlechteste Team zuerst und das beste Team zuletzt wählt. Der Draft ist eine aufregende und unvorhersehbare Veranstaltung, bei der einige Spieler höher oder niedriger als erwartet ausgewählt werden und einige überhaupt nicht ausgewählt werden. Beispiele für berühmte Spieler, die im NFL-Draft ausgewählt wurden, sind: John Elway (erster Pick 1983), Troy Aikman (erster Pick 1989), Peyton Manning (erster Pick 1998), Tom Brady (199. Pick 2000), Aaron Rodgers (24. Pick 2005) und Patrick Mahomes (10. Pick 2017).
        </MKTypography>
    </MKBox>
    <MKBox id="3.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.2 Andere professionelle Möglichkeiten
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Alternative Wege zu einer professionellen Karriere im American Football außerhalb der NFL. Nicht alle Spieler, die auf Hochschulebene spielen, schaffen es in die NFL. Einige Spieler werden im Draft nicht ausgewählt oder werden ausgewählt, schaffen es aber nicht, einen Platz in einem Team zu sichern. Einige Spieler werden verletzt oder verlieren ihre Motivation oder Leidenschaft. Das bedeutet jedoch nicht, dass sie ihren Traum vom professionellen Spielen aufgeben müssen. Es gibt andere professionelle Möglichkeiten außerhalb der NFL, wie zum Beispiel:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Die Canadian Football League (CFL): Dies ist eine professionelle Liga, die aus neun Teams besteht, die in Kanada spielen. Die CFL unterscheidet sich von der NFL, z. B. durch ein größeres Spielfeld, mehr Spieler pro Team und weniger Downs pro Angriff. Die CFL zieht viele Spieler an, die nicht in der NFL spielen können, aber dennoch auf einem hohen Niveau spielen möchten.
                </li>
                <li>
                    Die XFL: Dies ist eine neue professionelle Liga, die 2020 als Alternative zur NFL gegründet wurde. Die XFL besteht aus acht Teams, die in den Vereinigten Staaten spielen. Die XFL zielt darauf ab, ein schnelleres, aufregenderes und innovativeres Spiel als die NFL anzubieten. Die XFL bietet auch mehr Möglichkeiten für Spieler, die von der NFL übersehen oder ignoriert wurden.
                </li>
                <li>
                    Die European League of Football (ELF): Dies ist eine neue professionelle Liga, die 2021 gegründet wurde, um den American Football in Europa zu fördern und zu entwickeln. Die ELF besteht aus acht Teams, die in Deutschland, Polen, Spanien und Frankreich spielen. Die ELF zielt darauf ab, eine Plattform für europäische Spieler zu bieten, um ihr Talent zu zeigen und sich als Athleten zu entwickeln.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            In diesem Kapitel haben wir Ihnen mehr über den Weg zur NFL und andere professionelle Möglichkeiten erzählt. Im nächsten Kapitel werden wir Ihnen mehr über den Einfluss des American Footballs auf die amerikanische Kultur erzählen.
        </MKTypography>
    </MKBox>
</MKBox>



                                              






                                  





<MKBox id="Kapitel 4" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Kapitel 4: Der Einfluss des American Footballs auf die amerikanische Kultur
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        American Football ist nicht nur ein Sport, sondern auch eine Kultur. Es ist ein bedeutender Teil der amerikanischen Identität, Werte und Traditionen. Es ist eine Quelle des Stolzes, der Leidenschaft und der Inspiration für Millionen von Menschen. Es ist auch eine Industrie, die Milliarden von Dollar generiert und Tausende von Arbeitsplätzen schafft. In diesem Kapitel werden wir Ihnen mehr über den kulturellen Einfluss des American Footballs und die Vorbilder und Inspirationen, die er bietet, erzählen.
    </MKTypography>

    <MKBox id="4.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.1 Kultureller Einfluss
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image3}
                    alt="Blick von den Tribünen auf das lebhafte und vollbesetzte Football-Stadion der Universität Wisconsin während eines aufregenden Spiels, bei dem die Fans ihr Team anfeuern."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal3(Image3)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Spieltag im vollbesetzten Stadion von Wisconsin
                </figcaption>
            </figure>
            Die Bedeutung des American Footballs in der amerikanischen Gesellschaft und Kultur. American Football ist einer der beliebtesten und einflussreichsten Sportarten in den Vereinigten Staaten. Er zieht jedes Jahr Millionen von Fans, Zuschauern und Medien an. Er ist Teil des täglichen Lebens, der Bildung, der Politik, der Wirtschaft und der Unterhaltung vieler Amerikaner. Er ist auch ein Symbol für nationale Einheit, Vielfalt und Stolz.
            <br/>
            <br/>
            Bedeutende Ereignisse und ihr Einfluss auf die nationale Identität, wie zum Beispiel der Super Bowl. Der Super Bowl ist die jährliche Meisterschaft der NFL, die normalerweise im Februar stattfindet. Es ist das größte und meistgesehene Sportereignis der Welt, mit über 100 Millionen Zuschauern allein in den Vereinigten Staaten. Der Super Bowl ist mehr als nur ein Sportereignis; er ist ein kulturelles Phänomen. Es ist eine Gelegenheit für Familie, Freunde und Kollegen, sich zu versammeln, zu feiern, zu wetten und zu jubeln. Er ist auch eine Bühne für Musik, Werbung, Kunst und Politik. Der Super Bowl wird oft als Spiegelbild der amerikanischen Kultur, Werte und Trends betrachtet.
        </MKTypography>
    </MKBox>
    <MKBox id="4.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.2 Vorbilder und Inspiration
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            American-Football-Spieler sind oft Vorbilder und Quellen der Inspiration für junge Athleten, die ihren Traum von einer Karriere auf College- oder Profiebene verfolgen. Einige Spieler haben außergewöhnliche Geschichten von Widrigkeiten, Beharrlichkeit und Erfolg, die andere motivieren und inspirieren. Einige Spieler beeinflussen auch positiv ihre Gemeinschaft, Fans und ihr Land durch ihr Handeln, ihre Worte und Taten. Beispiele für solche Spieler sind: Colin Kaepernick (der während der Nationalhymne niederkniete, um auf rassistische Ungerechtigkeit aufmerksam zu machen), Russell Wilson (der sein Team als Außenseiter zum Super Bowl führte), JJ Watt (der Millionen von Dollar für die Opfer von Hurrikan Harvey sammelte), Drew Brees (der seiner Stadt New Orleans half, sich nach Hurrikan Katrina zu erholen) und Tom Brady (der immer noch auf hohem Niveau spielt im Alter von 43 Jahren).
            <br/>
            <br/>
            In diesem Kapitel haben wir Ihnen mehr über den Einfluss des American Footballs auf die amerikanische Kultur erzählt. Im nächsten Kapitel werden wir Ihnen mehr über Sportstipendien und die Zukunft des American Footballs erzählen.
        </MKTypography>
    </MKBox>
</MKBox>












                                              






                                  
<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Sportstipendien und die Zukunft der Athleten
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sportstipendien bieten zahlreiche Möglichkeiten für junge Athleten, ihr Talent zu zeigen und ihre Fähigkeiten zu entwickeln. Eine der bedeutendsten Möglichkeiten ist der Erhalt eines Sportstipendiums, um auf Hochschulebene zu studieren und zu konkurrieren. In diesem Kapitel werden wir die Bedeutung von Sportstipendien diskutieren und wie Sportstipendien Amerika Ihnen helfen kann, Ihren Traum zu verwirklichen.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Die Bedeutung von Stipendien
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ein Sportstipendium ist finanzielle Unterstützung, die von einer Universität einem Studenten-Athleten zur Verfügung gestellt wird, um Studium und Sport zu kombinieren. Es kann die Kosten für Studiengebühren, Bücher, Unterkunft, Mahlzeiten und andere Ausgaben abdecken. Darüber hinaus kann es auch Vorteile wie akademische Nachhilfe, medizinische Versorgung, Trainingsmöglichkeiten und Ausrüstung bieten.
      <br />
      <br />
      Die Erlangung eines Sportstipendiums ist wettbewerbsfähig, wobei viele Faktoren eine Rolle im Zuteilungsprozess spielen, einschließlich akademischer Leistungen, sportlicher Leistung, Persönlichkeit, Motivation, Führung und Anpassungsfähigkeit. Daher ist es wichtig, sich gut vorzubereiten und sich von der Konkurrenz abzuheben.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Wie Sportstipendien Amerika helfen können
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo von Sportstipendien Amerika, das Sportausrüstung und eine Abschlusskappe zeigt, die den Fokus auf Sportstipendien für Studenten-Athleten repräsentiert."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo von Sportstipendien Amerika
        </figcaption>
      </figure>
      Sportstipendien Amerika ist eine Organisation, die sich auf die Anleitung junger Sporttalente zu Sportstipendien spezialisiert hat. Mit leidenschaftlichen und erfahrenen Experten unterstützt Sportstipendien Amerika Studenten-Athleten auf jedem Schritt der Reise, von der ersten Orientierung bis zur endgültigen Platzierung. Ihre Dienstleistungen umfassen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Ein persönliches Aufnahmegespräch, um die Wünsche, Möglichkeiten und Erwartungen des Studenten-Athleten zu besprechen.</li>
        <li>Eine professionelle Bewertung des sportlichen und akademischen Niveaus des Studenten-Athleten.</li>
        <li>Ein umfangreiches Netzwerk von Kontakten zu Trainern, Universitäten und Hochschulen.</li>
        <li>Effektive Werbung für das Profil und die Videos des Studenten-Athleten bei potenziellen Trainern.</li>
        <li>Expertenberatung zu den besten Optionen basierend auf den Vorlieben, Zielen und dem Budget des Studenten-Athleten.</li>
        <li>Verhandlung der besten Stipendienangebote im Namen des Studenten-Athleten.</li>
        <li>Hilfe bei der Beantragung der erforderlichen Dokumente wie Visum, Zeugnis, Diplom und Zulassungstests.</li>
        <li>Vorbereitung auf das Leben des Studenten-Athleten während seines Stipendiums, einschließlich Kultur, Sprache, Regeln und Tipps.</li>
        <li>Nachsorge und Follow-up während der Stipendiumszeit.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Möchten Sie Stipendienmöglichkeiten erkunden und Ihre sportlichen und akademischen Ziele erreichen? <Link to="/de/Dienstleistungen-Produkte">Sehen Sie sich die vollständige Palette</Link> der von Sportstipendien Amerika angebotenen Dienstleistungen an und vereinbaren Sie noch heute eine Beratung.
    </MKTypography>
  </MKBox>
</MKBox>










         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;