/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Rowing1.jpg";
import Image2 from "assets/images/Sports_Images/Rowing2.jpg";
import Image3 from "assets/images/Sports_Images/Rowing3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images

import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Att Studera och Rodda i Amerika: En Unik Möjlighet för Unga Roddare', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapitel 1: Roddets Historia och Utveckling i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 De Tidiga Åren och Kollegiernas Roll', isSubheader: true },
    { id: '1.2', title: '1.2 Tillväxt och Traditioner', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapitel 2: Rodd på Collegenivå Idag', isSubheader: false },
    { id: '2.1', title: '2.1 Stora Tävlingar och Konferenser', isSubheader: true },
    { id: '2.2', title: '2.2 Ledande Program och Lag', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapitel 3: Från College till Eliten', isSubheader: false },
    { id: '3.1', title: '3.1 Vägen till Topp', isSubheader: true },
    { id: '3.2', title: '3.2 Professionella och Internationella Möjligheter', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapitel 4: Internationella Influenser och Mångfald', isSubheader: false },
    { id: '4.1', title: '4.1 Internationella Idrottare inom Collegial Rodd', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanska Roddare Utomlands', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapitel 5: Idrottsstipendier och Framtiden för Idrottare', isSubheader: false },
    { id: '5.1', title: '5.1 Vikten av Stipendier', isSubheader: true },
    { id: '5.2', title: '5.2 Hur Sport Scholarships America Kan Hjälpa', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innehåll
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Dölj</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Visa</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Att Studera och Rodda i Amerika: En Unik Möjlighet för Unga Roddare
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Roddare i aktion under det nationella collegemästerskapet i rodd."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Roddare i aktion under det nationella mästerskapet
      </figcaption>
    </figure>
    Rodd är en av de äldsta och mest prestigefyllda sporterna i USA. Sedan 1800-talet har högklassigt rodd utövats vid amerikanska universitet, där roddare inte bara utvecklar sin fysiska och mentala styrka utan också sina akademiska och sociala färdigheter. Att studera och ro i Amerika är en unik möjlighet för unga roddare som vill följa sina idrottsdrömmar samtidigt som de får en värdefull utbildning och en oförglömlig upplevelse. I den här artikeln kommer vi att berätta allt du behöver veta om rodd i Amerika, från dess historia och traditioner till möjligheterna och utmaningarna. Vi kommer också förklara hur Sportbeursamerika.nl kan hjälpa dig att säkra ett rodstipendium och förbereda dig för ditt äventyr.
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Roddets Historia och Utveckling i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    I det här kapitlet dyker vi ner i den fascinerande historien om rodd i USA. Vi utforskar hur denna sport spreds över haven från England och blev en integrerad del av amerikansk universitetskultur. Från de första roddtävlingarna på Lake Winnipesaukee till de ikoniska rivaliteterna mellan prestigefyllda universitet, undersöker vi utvecklingen av rodd som en sport som både innefattar fysisk smidighet och akademisk stolthet.
  </MKTypography>
  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 De Tidiga Åren och Kollegiernas Roll
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Rodd som sport hade sitt ursprung i England på 1700-talet när tävlingar hölls mellan professionella roddare på Themsen. Dessa tävlingar lockade stora publikskaror och satsningar, vilket snabbt gjorde rodd populärt. Under 1700- och 1800-talen spreds rodd till andra länder, inklusive USA. Den första amerikanska roddtävlingen ägde rum 1843 på Lake Winnipesaukee i New Hampshire. 1852 hölls den första interkollegiala roddtävlingen mellan Harvard och Yale på Lake Winnipesaukee. Detta var också den första interkollegiala idrottshändelsen i USA. Sedan dess har rodd blivit en betydande del av amerikansk collegemiljö.
      <br />
      <br />
      Kollegiernas roll för att främja rodd var avgörande. Kollegier såg rodd som ett sätt att inpränta disciplin, samarbete och ledarskap hos sina studenter samtidigt som de förbättrade sitt anseende och rykte. Kollegier investerade betydande belopp och resurser i att bygga roddbåthus, skaffa båtar och locka tränare. De organiserade också regelbundet tävlingar mot andra kollegier, både regionalt och nationellt. Dessa tävlingar besöktes ofta av studenter, alumner, media och sponsorer, vilket skapade stort intresse och engagemang för sporten.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Tillväxt och Traditioner
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Rodd växte stadigt i popularitet och anseende under andra hälften av 1800-talet och första halvan av 1900-talet. Olika regionala och nationella tävlingar uppstod, såsom Eastern Sprints, IRA National Championship Regatta, Dad Vail Regatta och Head of the Charles Regatta. Amerikanska roddare deltog också i internationella evenemang, inklusive Henley Royal Regatta i England och olympiska spelen. Amerikanska roddare uppnådde ofta imponerande resultat och vann guld vid åtta på varandra följande olympiska spel mellan 1920 och 1956.
      <br />
      <br />
      Rodd utvecklade också en rik kultur och tradition vid amerikanska kollegier. Varje universitet hade sina egna färger, maskot, sånger och ritualer associerade med rodd. Vissa traditioner var lekfulla eller symboliska, såsom att kasta styrmannen i vattnet efter en seger eller bära en speciell slips eller pin. Andra traditioner var allvarliga eller ceremoniella, såsom att sjunga alma mater-sången eller hålla en minnesceremoni för avlidna roddare. Dessa traditioner bidrog till känslan av stolthet, kamratskap och identitet bland roddare.
    </MKTypography>
  </MKBox>
</MKBox>


                                  






<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Rodd på Collegenivå Idag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    I det här kapitlet fokuserar vi på nutida rodd på universitetsnivå i Amerika. Vi undersöker hur sporten har utvecklats till en högt konkurrenskraftig och prestigefylld del av collegiat sportlandskapet. Här diskuterar vi de stora tävlingarna och konferenserna, strukturen för NCAA-divisioner, och tar en titt på några av de ledande universitetsroddprogrammen. Denna sektion belyser det nuvarande tillståndet för rodd på amerikanska universitet och de unika möjligheter det erbjuder unga idrottare.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Stora Tävlingar och Konferenser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Stanfords roddlag firar vinsten i det nationella mästerskapet."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Stanfords roddare firar det nationella mästerskapet
        </figcaption>
      </figure>
      Rodd på collegenivå förblir en högt konkurrenskraftig och prestigefylld sport i USA idag. Det finns över 300 universitet som erbjuder roddprogram, med cirka 150 som deltar i NCAA (National Collegiate Athletic Association), den övergripande organisationen för collegiat idrott. NCAA delar upp universiteten i tre divisioner baserat på antalet studenter, budget och nivån på sporten. Division I är den högsta divisionen och har de mest robusta och konkurrenskraftiga roddprogrammen. Divisionerna II och III har färre och mindre roddprogram men är fortfarande högt konkurrenskraftiga.
      <br />
      <br />
      Inom varje division finns olika konferenser, som är grupper av universitet som är geografiskt eller historiskt kopplade. Varje konferens organiserar sin egen mästerskapstävling, där de bästa lagen kvalificerar sig för nationella mästerskap. De stora konferenserna för rodd inkluderar Big Ten, Pac-12, Ivy League, ACC och Big 12. Dessa konferenser har ofta de mest framgångsrika och inflytelserika roddprogrammen i landet.
      <br />
      <br />
      Förutom konferensmästerskapen finns det andra betydande nationella regattor som universiteten kan delta i. Den mest prestigefyllda är NCAA Women’s Rowing Championship, som hållits sedan 1997 för kvinnliga roddare i alla tre divisionerna. För manliga roddare finns det inget motsvarande NCAA-mästerskap, men det finns IRA National Championship Regatta, som hållits sedan 1895 för både varsity- och lättviktsteam. Andra välkända nationella regattor inkluderar Dad Vail Regatta, San Diego Crew Classic, ACRA National Championship Regatta och CRASH-B Sprints.
    </MKTypography>
  </MKBox>

  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Ledande Program och Lag
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Rodd på collegenivå har en lång historia av rivalitet, excellens och innovation. Många universitet har utmärkt sig genom sina roddprogram, både när det gäller prestanda och utveckling. Några av dessa universitet inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          University of Washington: Huskies är kända för sitt kraftfulla och respekterade roddprogram. Med flera nationella mästerskapstitlar i herrrodd och en historia av att producera olympiska medaljörer, inklusive den legendariska "Boys in the Boat" som vann guld 1936. Huskies hyllas för sin rigorösa träning, starkt samarbete och inflytelserika tränare som Al Ulbrickson.
        </li>
        <li>
          Yale University: Som en av pionjärerna inom intercollegiate rodd har Yale Bulldogs ett gammalt och prestigefyllt roddprogram. Deras historia inkluderar flera nationella titlar och ett betydande bidrag till amerikansk rodd på olympisk nivå. Yale är också känt för sin akademiska excellens och historiska rivalitet med Harvard.
        </li>
        <li>
          University of California, Berkeley: Golden Bears har ett framgångsrikt och mångsidigt roddprogram som utmärks av sina nationella titlar och olympiska deltagare. Programmet är känt för sin mångfald, samhällsengagemang och dedikerade tränare.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>






                                  







<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Från College till Eliten
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    I detta kapitel utforskar vi resan för college-roddare till de elitnivåer av roddvärlden. Vi undersöker hur de erfarenheter och färdigheter som roddare får på universitetsnivå förbereder dem för högre nivåer av tävling, inklusive deltagande i nationella lag och Olympiska Spelen. Detta kapitel belyser övergången från collegiat rodd till professionella och internationella arenor, med fokus på de vägar idrottare följer, de utmaningar de står inför, och framgångshistorierna för dem som når toppen. Vi tittar också på hur universitetsroddprogram identifierar och utvecklar talanger, vilket banar väg för framtida olympier och världsmästare.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Vägen till Topp
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Rodd på collegenivå är inte bara en sportslig utmaning utan också en potentiell trampsten till de elitnivåer av roddvärlden. Många roddare som har rott på collegenivå övergår till nationella lag och olympisk rodd. Detta beror på att collegiat rodd ger utmärkt förberedelse när det gäller teknik, taktik, fysisk och mental konditionering, och teamwork. Dessutom erbjuder collegiat rodd möjligheten att sticka ut för scouter och tränare från nationella förbund och uttagningar.
      <br />
      <br />
      Det finns talrika exempel på roddare som har gått från college till elitnivån. Ett nyligen exempel på en roddare som vann en olympisk medalj efter sin collegekarriär är Madison Mailey. Mailey, en All-American-roddare vid Northeastern University, som tog examen 2018, vann en guldmedalj vid Olympiska spelen i Tokyo i damernas åtta för Kanada. Hon och hennes lagkamrater tog en tidig ledning och höll emot en sen utmaning från Nya Zeeland, och vann med en marginal på bara 0,91 sekunder.
    </MKTypography>
  </MKBox>

  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professionella och Internationella Möjligheter
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Collegiat rodd öppnar dörren till professionella och internationella möjligheter för roddare. Förutom potentialen för olympiskt deltagande kan universitetsroddare använda sina färdigheter för att tävla i prestigefyllda internationella regattor som Henley Royal Regatta eller World Rowing Cup. Dessa evenemang ger en plattform för roddare att mäta sig med världens bästa och få erfarenhet i en internationell kontext.
      <br />
      <br />
      Dessutom finns det en väg till professionell rodd där collegeexamen kan ansluta sig till professionella lag och delta i globala tävlingar. Detta erbjuder en heltidsroddkarriär med intensiv träning och möjligheten att tävla på högsta nivå.
      <br />
      <br />
      För de som är intresserade av en tränarkarriär ger collegiat rodd en stabil grund. Många framgångsrika roddtränare är tidigare collegiata idrottare som använder sin erfarenhet och expertis för att handleda nya generationer av roddare. Denna karriärväg kan leda till positioner med nationella lag och roddklubbar runt om i världen.
      <br />
      <br />
      Sammanfattningsvis kan de erfarenheter som fås i collegiat rodd leda till en rad professionella och internationella möjligheter, från idrottare som följer en professionell karriär till de som fortsätter sporten i en tränarroll.
    </MKTypography>
  </MKBox>
</MKBox>
                                          






                                  






<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationella Influenser och Mångfald
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Detta kapitel belyser de internationella dynamikerna och mångfalden inom college-rodd i USA. Vi utforskar hur internationella studentidrottare berikar sporten med sina unika bakgrunder och perspektiv, och hur amerikanska roddare hittar möjligheter att ro utomlands och vidareutveckla sig själva. Dessa interaktioner bidrar till en rikare kulturell upplevelse och vidgar horisonterna för både amerikanska och internationella roddare. Vi fördjupar oss i de olika vägarna internationella studenter tar för att ro på collegenivå i USA, fördelarna med dessa erfarenheter och den påverkan de har på sporten och dess deltagare. Dessutom fokuserar vi på möjligheterna för amerikanska roddare att få internationell erfarenhet, inklusive utbytesprogram för studier, internationella träningsläger och tränarmöjligheter. Detta kapitel betonar betydelsen av internationella influenser för att berika collegial rodd och främja kulturell utbyte och förståelse.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Internationella Idrottare inom Collegial Rodd
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Rodd båtar står uppställda för starten av ett collegialt roddlopp."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Båtar redo för starten av ett lopp
        </figcaption>
      </figure>
      Collegial rodd är inte bara en amerikansk angelägenhet utan också en internationell sådan. Många internationella studenter kommer till USA för att studera och ro på amerikanska universitet. Dessa studenter bidrar med sin egen kultur, bakgrund och perspektiv och gör värdefulla bidrag till lagens mångfald och kvalitet. Dessutom skapar de en möjlighet till kulturellt utbyte och vänskap mellan roddare från olika länder.
      <br />
      <br />
      Det finns flera skäl till varför internationella studenter väljer collegial rodd i USA. Några av dessa skäl inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Roddstipendier: Många amerikanska universitet erbjuder roddstipendier till talangfulla roddare, vilket kan minska eller till och med eliminera deras undervisningskostnader. Detta är en betydande drivkraft för internationella studenter som annars kanske inte har möjlighet att studera i USA.
        </li>
        <li>
          Akademisk excellens: Många amerikanska universitet är kända för sina höga akademiska standarder och rykte. Detta lockar internationella studenter som söker en utmärkt utbildning och en globalt erkänd examen.
        </li>
        <li>
          Sportslig utmaning: Många internationella studenter ser collegial rodd som en sportslig utmaning och en möjlighet att tävla med världens bästa roddare. De strävar efter att förbättra sina färdigheter, pressa sina gränser och uppfylla sitt potential.
        </li>
        <li>
          Personlig utveckling: Många internationella studenter betraktar collegial rodd som personlig utveckling och en chans att växa som individer. De vill skaffa sig nya erfarenheter, träffa nya människor, lära sig om nya kulturer och vidga sina vyer.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      "Niki van Sprang är ett exempel på en internationell student som rodde på collegenivå i USA. Van Sprang, ursprungligen från Nederländerna där han vann flera nationella titlar, fick ett roddstipendium från University of California, Berkeley. Där anslöt han sig till elitlaget och uppnådde betydande framgång. Efter examen 2017 återvände Van Sprang till Nederländerna och blev medlem i det nationella roddlaget. Han deltog i de olympiska spelen i Tokyo 2020, där han och Guillaume Krommenhoek kom på 7:e plats i herrarnas utan-styrman-tvåa. Dessutom vann han silver vid VM 2022 i Račice och vid EM 2022 i München, samt brons vid EM 2023 i Bled, alla i herrarnas åtta."
    </MKTypography>
  </MKBox>

  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikanska Roddare Utomlands
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Collegial rodd kan också leda till internationella möjligheter för amerikanska studentidrottare som vill vidga eller ändra sina sportliga horisonter. Det finns olika möjligheter för amerikanska roddare att ro utomlands och skaffa sig erfarenhet. Några av dessa möjligheter inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Studieutbyte: Vissa amerikanska universitet har utbytesprogram med utländska universitet, vilket ger studenter möjlighet att studera och ro i ett annat land under en termin eller ett år. Det ger dem möjlighet att uppleva en annan kultur, språk och utbildningssystem, samt att ro med andra lag och tränare.
        </li>
        <li>
          Träningsläger: Vissa amerikanska universitet organiserar träningsläger utomlands, vanligtvis under vinter- eller sommaruppehållet. Det ger dem chansen att träna i en annan miljö, klimat och vattenförhållanden, samt att tävla mot andra lag eller delta i lokala regattor.
        </li>
        <li>
          Tränarroll: Vissa amerikanska roddare väljer att träna utomlands efter examen, vanligtvis på skolor, klubbar eller nationella lag. Det ger dem möjlighet att dela med sig av sin kunskap och erfarenhet, samt att lära sig av andra tränare och kulturer.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>
                                              






                                  






<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Idrottsstipendier och Framtiden för Idrottare
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrottsstipendier erbjuder många möjligheter för unga idrottare att visa upp sin talang och utveckla sina färdigheter. En av de mest betydelsefulla möjligheterna är att få ett idrottsstipendium för att studera och tävla på collegenivå. I det här kapitlet kommer vi att diskutera vikten av idrottsstipendier och hur Sport Scholarships America kan hjälpa dig att förverkliga din dröm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vikten av Stipendier
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ett idrottsstipendium är ekonomiskt stöd som tillhandahålls av ett universitet till en idrottare för att kombinera studier och idrott. Det kan täcka kostnaderna för undervisning, böcker, boende, måltider och andra utgifter. Dessutom kan det erbjuda fördelar som akademisk handledning, sjukvård, träningsanläggningar och utrustning.
      <br />
      <br />
      Att få ett idrottsstipendium är konkurrenskraftigt, med många faktorer som spelar en roll i tilldelningsprocessen, inklusive akademiska prestationer, idrottsprestationer, personlighet, motivation, ledarskap och anpassningsförmåga. Därför är det viktigt att förbereda sig väl och skilja sig från konkurrensen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hur Sport Scholarships America Kan Hjälpa
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotyp för Sport Scholarships America, med sportutrustning och en studentmössa, som representerar fokus på idrottsstipendier för idrottare."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotyp för Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America är en organisation specialiserad på att vägleda unga idrottstalanger till idrottsstipendier. Med passionerade och erfarna experter stöder Sport Scholarships America idrottare i varje steg av resan, från initial orientering till slutlig placering. Deras tjänster inkluderar:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intagningsintervju för att diskutera idrottarens önskemål, möjligheter och förväntningar.</li>
        <li>En professionell utvärdering av idrottarens akademiska och idrottsliga nivå.</li>
        <li>Ett omfattande nätverk av kontakter med tränare, universitet och högskolor.</li>
        <li>Effektiv marknadsföring av idrottarens profil och videor till potentiella tränare.</li>
        <li>Expertrådgivning om de bästa alternativen baserat på idrottarens preferenser, mål och budget.</li>
        <li>Förhandling om de bästa stipendieerbjudandena för idrottaren.</li>
        <li>Hjälp med att ansöka om nödvändiga dokument, såsom visum, betyg, examensbevis och antagningsprov.</li>
        <li>Förberedelse för idrottarens liv under stipendieperioden, inklusive kultur, språk, regler och tips.</li>
        <li>Uppföljning och uppföljning under stipendieperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vill du utforska möjligheter till stipendier och uppnå dina idrottsliga och akademiska mål? <Link to="/sv/Tjänster-Produkter">Se det fullständiga utbudet</Link> av tjänster som erbjuds av Sport Scholarships America och boka en konsultation idag.
    </MKTypography>
  </MKBox>
</MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;