/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import Image1 from "assets/images/Sports_Images/Tennis1.jpg";
import Image2 from "assets/images/Sports_Images/Tennis2.jpeg";
import Image3 from "assets/images/Sports_Images/Tennis3.jpg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Amerikanske Tennisstipend: Hvordan Studere og Spille Tennis i Amerika', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapittel 1: Historie og Utvikling av Tennis i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Tidlige År og Påvirkningen av Universitetene', isSubheader: true },
    { id: '1.2', title: '1.2 Vekst og Professionalisering', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapittel 2: Universitetstennis i dag', isSubheader: false },
    { id: '2.1', title: '2.1 Viktige Konkurranser og Konferanser', isSubheader: true },
    { id: '2.2', title: '2.2 Topp Program og Prestisjefylte Lag', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapittel 3: Fra College til Proffene', isSubheader: false },
    { id: '3.1', title: '3.1 Veien til Profesjonalisme', isSubheader: true },
    { id: '3.2', title: '3.2 Profesjonelle Tennissirkler', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapittel 4: Internasjonale Påvirkninger', isSubheader: false },
    { id: '4.1', title: '4.1 Utenlandske Spillere i Collegemiljøet', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanere på den Internasjonale Scenen', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapittel 5: Idrettsstipender og Fremtiden til Utøvere', isSubheader: false },
    { id: '5.1', title: '5.1 Viktigheten av Stipender', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Scholarships America Kan Hjelpe', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Amerikanske Tennisstipend: Hvordan Studere og Spille Tennis i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Tennisbaner i Georgia med fulle tribuner under en collegematch."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Fulle tribuner på tennisbanene i Georgia.
      </figcaption>
    </figure>
    Tennis er en sport som har blitt spilt i USA i over et århundre. Det begynte som en fritidsaktivitet for de rike, men utviklet seg raskt til en konkurransepreget og profesjonell sport som tiltrekker seg millioner av tilhengere og spillere over hele verden. En av de viktigste faktorene som bidrar til vekst og utvikling av tennis i Amerika er det collegiale systemet. Universitetene gir ikke bare høykvalitetsutdanning, men også utmerkede fasiliteter, coaching, veiledning og stipend for talentfulle tennisspillere. Å spille tennis på collegenivå lar spillere forbedre ferdighetene sine, utvide nettverket sitt, øke sjansene for en profesjonell karriere og samtidig tjene en verdifull grad.
    <br />
    <br />
    Men hvordan kan en ung tennisspiller fra Nederland eller Belgia dra til Amerika for å studere og spille tennis? Svaret er med et tennisstipend. Et tennisstipend er økonomisk støtte gitt av et universitet til en spiller som oppfyller visse akademiske og sportslige kriterier. Med et tennisstipend kan du dekke eller redusere skolepenger, bøker, bolig, måltider og andre utgifter. Et tennisstipend kan variere fra et fullstendig stipend, som dekker alle kostnader, til et delstipend, som dekker en prosentandel av kostnadene.
    <br />
    <br />
    Men hvordan får du et tennisstipend? Det er her Sportbeursamerika.nl kan hjelpe deg. Sportbeursamerika.nl er en plattform som hjelper unge tennisspillere med å realisere drømmen om å dra til Amerika. Sportbeursamerika.nl gir råd, veiledning, formidling og støtte i å finne det beste universitetet og stipendet for deg. Sportbeursamerika.nl har et team av eksperter som forstår hvordan det collegiale systemet i Amerika fungerer og har kontakter med hundrevis av trenere og universiteter. Sportbeursamerika.nl har allerede hjulpet mange spillere med å nå sitt potensiale og endre livet deres.
    <br />
    <br />
    Vil du lære mer om tennis i Amerika og hvordan Sportbeursamerika.nl kan hjelpe deg? Fortsett å lese!
  </MKTypography>
</MKBox>

<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 1: Historie og Utvikling av Tennis i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Tennis er en sport som opprinnelig stammer fra Europa, men som raskt spredte seg til andre deler av verden, spesielt til USA. I dette kapittelet vil vi utforske hvordan tennis har utviklet seg i USA og hvordan universitetene har spilt en betydelig rolle i sportens utvikling og professionalisering.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidlige År og Påvirkningen av Universitetene
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Tennis ble først introdusert i USA tidlig på 1800-tallet av rike amerikanere som hadde lært spillet under reisene sine til Europa. Det ble raskt en populær fritidsaktivitet for eliten, som bygde tennisbaner på eiendommene sine og klubber. Den første offisielle tennis kampen i USA ble spilt i 1874 mellom to engelske brødre, James Dwight og Fred Renshaw, begge studenter ved Harvard University. De spilte på en tennisbane de hadde bygget på onkelens eiendom i Nahant, Massachusetts. De første nasjonale mesterskapene, da kjent som US National Lawn Tennis Association Men's Singles Championship, ble avholdt i 1881 i Newport, Rhode Island.
      <br />
      <br />
      Universiteter var blant de første institusjonene som omfavnet tennis som en konkurransepreget sport. De organiserte interkollegiale turneringer og dannet lag for å konkurrere mot hverandre. De første interkollegiale tennis mesterskapene ble avholdt i 1883, med Harvard, Yale, Princeton og Columbia, og i 1885 ble det første offisielle interkollegiale tennis mesterskapet anerkjent. Disse universitetene ble raskt kjent som "The Big Four" innen amerikansk collegiatennis. De dominerte sporten frem til begynnelsen av 1900-tallet da andre universiteter som Stanford, California, Chicago og Cornell sluttet seg til dem.
      <br />
      <br />
      Universitetene spilte også en betydelig rolle i å fremme og spre tennis blant ulike grupper av mennesker. De tilbød muligheter for kvinner, afroamerikanere, innvandrere og andre minoriteter til å praktisere og utmerke seg i sporten. Et av de mest bemerkelsesverdige eksemplene er Althea Gibson, som i 1950 ble den første afroamerikanske kvinnen som ble tatt opp til det nasjonale mesterskapet. Hun vant sin første Grand Slam-tittel på French Championships i 1956 etter å ha fullført studiene ved Florida A&M University og blitt medlem av American Tennis Association, en organisasjon for svarte tennisspillere. Senere vant hun Wimbledon to ganger og US Open to ganger.
    </MKTypography>
  </MKBox>

  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Vekst og Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Andre halvdel av det 20. århundre vitnet om betydelig vekst og professionalisering av tennis i Amerika. Dette ble muliggjort, delvis, av oppkomsten av fjernsyn, som ga sporten mer synlighet og popularitet. I tillegg ble det introdusert nye teknologier og materialer, noe som gjorde spillet raskere og mer spennende. Videre ble det etablert nye organisasjoner og kretser for å regulere og fremme tennis, inkludert International Tennis Federation (ITF), Association of Tennis Professionals (ATP) og Women's Tennis Association (WTA).
      <br />
      <br />
      Disse endringene påvirket også collegiatennis, som måtte tilpasse seg den nye virkeligheten med profesjonell tennis. Universitetene måtte konkurrere med andre alternativer for å tiltrekke og beholde talentfulle spillere. De måtte også forbedre programmene sine for å forberede spillerne på en potensiell profesjonell karriere. En av de viktigste tiltakene universitetene tok var opprettelsen av National Collegiate Athletic Association (NCAA), en overordnet organisasjon ansvarlig for å organisere og regulere collegiatidretter i USA. NCAA delte universitetene inn i tre divisjoner basert på størrelse, budsjett og nivå av konkurranse. NCAA organiserer årlige nasjonale mesterskap for hver divisjon, både for lag og enkeltpersoner.
      <br />
      <br />
      En annen konsekvens av professionaliseringen av tennis var at mange amerikanske spillere utmerket seg på det høyeste nivået av sporten. Noen av dem hadde en collegiat bakgrunn, noe som viser at collegiatennis kan gi et solid grunnlag for en vellykket profesjonell karriere. Noen av de mest kjente amerikanske tennisspillerne som spilte på universitetsnivå inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Arthur Ashe, som studerte ved UCLA og var den første afroamerikanske mannen som vant Wimbledon, US Open og Australian Open.
        </li>
        <li>
          John McEnroe, som gikk på Stanford og vant syv Grand Slam singletitler og ni i double.
        </li>
        <li>
          Jimmy Connors, som studerte ved UCLA og vant åtte Grand Slam singletitler og to i double.
        </li>
        <li>
          Billie Jean King, som gikk på California State University, Los Angeles, og vant 12 Grand Slam singletitler og 16 i double. Hun var også en pioner i kampen for likestilling innen idrett.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  








<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 2: Universitetstennis i dag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Tennis forblir en av de mest populære og prestisjefylte idrettene i det amerikanske collegiale systemet. Hvert år deltar tusenvis av spillere i ulike konkurranser og konferanser, og viser det beste av sporten. I dette kapittelet vil vi utforske de største konkurransene og konferansene innen collegiatennis og de beste programmene og prestisjefylte lagene som dominerer sporten.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Viktige Konkurranser og Konferanser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Cameron Norrie i aksjon for TCU i collegemtennis."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Cameron Norrie i aksjon som TCU-spiller
        </figcaption>
      </figure>
      Som vi så i forrige kapittel, er NCAA den primære organisasjonen som er ansvarlig for å organisere og regulere collegidretter i USA. NCAA deler universitetene inn i tre divisjoner: Divisjon I, Divisjon II og Divisjon III. Hver divisjon har sine egne regler, krav og mesterskap. For tennis er det to store konkurranser arrangert av NCAA: lagmesterskapet og individmesterskapet.
      <br />
      <br />
      Lagmesterskapet er en knockout-turnering spilt blant de beste lagene i hver divisjon. Lagene velges basert på deres prestasjoner i løpet av sesongen, deres rangering og deres regionale resultater. Lagmesterskapet består av to faser: den regionale fasen og den nasjonale fasen. Den regionale fasen spilles blant lag fra samme region, som er delt inn i fire geografiske soner: Nordøst, Sørøst, Midwest og Vest. Vinnerne av hver region går videre til den nasjonale fasen, som spilles blant 16 lag fra hver divisjon. Lagmesterskapet følger formatet til Davis Cup, noe som betyr at hver kamp består av tre doublekamper og seks singelkamper.
      <br />
      <br />
      Individmesterskapet er en knockout-turnering spilt blant de beste spillerne i hver divisjon. Spillerne velges basert på deres rangering, deres prestasjon i lagmesterskapet og deres resultater i andre turneringer. Individmesterskapet består av to kategorier: singel og double. Hver kategori har 64 spillere i Divisjon I, 48 spillere i Divisjon II og 32 spillere i Divisjon III.
      <br />
      <br />
      I tillegg til NCAA-mesterskapene er det også andre konkurranser spilt blant universiteter som tilhører samme konferanse. En konferanse er en gruppe universiteter som samarbeider innen akademiske, idrettslige og administrative områder. Det er mer enn 30 konferanser i USA, hver med sine egne regler, planer og mesterskap. Noen av de mest kjente konferansene inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Atlantic Coast Conference (ACC), som består av 15 universiteter fra Østkysten, som Duke, North Carolina, Virginia og Florida State.
        </li>
        <li>
          Big Ten Conference, som består av 14 universiteter fra Midwest, som Michigan, Ohio State, Illinois og Wisconsin.
        </li>
        <li>
          Pacific-12 Conference (Pac-12), som består av 12 universiteter fra Vestkysten, som Stanford, California, UCLA og USC.
        </li>
        <li>
          Southeastern Conference (SEC), som består av 14 universiteter fra Sørøst, som Georgia, Florida, Kentucky og Vanderbilt.
        </li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Disse konferansene er viktige ikke bare for den idrettslige rivaliseringen mellom universiteter, men også for deres akademiske omdømme og prestisje. Videre gir de flere muligheter for spillere til å utmerke seg og kvalifisere seg for nasjonale mesterskap.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Topp Program og Prestisjefylte Lag
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Konkurransenivået i collegiatennis er ekstremt høyt, noe som betyr at bare de beste spillerne og lagene kan trives og lykkes. Imidlertid har noen universiteter bygd en lang tradisjon og et sterkt rykte innen tennis, noe som fører til topp program som oppnår utmerkede resultater år etter år. Disse universitetene har ikke bare de beste fasilitetene, trenerne og stipendene, men utmerker seg også i rekruttering, trening og veiledning av spillerne sine. Noen av de mest vellykkede og prestisjefylte collegiatennisprogrammene inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Stanford University, som har vunnet 21 NCAA-lagtitler i kvinnetennis og 17 i herretennis, mer enn noen annen universitet. Stanford har også vunnet 25 NCAA-individuelle titler i kvinnetennis og 14 i herretennis. Noen av de mest kjente spillerne som har spilt for Stanford inkluderer John McEnroe, Bob og Mike Bryan, Nicole Gibbs og Mallory Burdette.
        </li>
        <li>
          USC (University of Southern California), som har vunnet nesten like mange NCAA-lagtitler som Stanford i herretennis og noen få i kvinnetennis. USC har også vunnet flere NCAA-individuelle titler enn noen annen universitet i herretennis og noen i kvinnetennis. Noen av de mest kjente spillerne som har spilt for USC inkluderer Stan Smith, Alex Olmedo, Steve Johnson og Danielle Collins.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  









<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 3: Fra College til Proffene
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    En av de største drømmene til mange unge tennisspillere er å forfølge en profesjonell karriere og skinne på verdensscenen. Men hvordan kan du gjøre den drømmen til virkelighet? Er collegiatennis en god forberedelse for den profesjonelle sirkelen? I dette kapittelet vil vi se på veien til profesjonalisme og hvordan collegiatennis kan hjelpe spillere med å ta det spranget.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Veien til Profesjonalisme
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Å bli en profesjonell tennisspiller er ikke en enkel oppgave. Det krever ikke bare mye talent, men også dedikasjon, disiplin, hardt arbeid og offer. Det krever også å ta viktige beslutninger som kan påvirke din fremtid. En av de viktigste beslutningene du må ta, er om du skal gå på college eller ikke.
      <br />
      <br />
      Det er fordeler og ulemper med begge alternativene. Hvis du velger å bli profesjonell umiddelbart etter videregående skole, kan du fokusere fullt ut på tennisen din og prøve å sikre deg en plass i rangeringene så raskt som mulig. Du kan også dra nytte av din ungdom, energi og potensiale. Imidlertid kan du også møte mange risikoer, som skader, utbrenthet, økonomiske problemer eller mangel på resultater. Videre kan du måtte gi opp eller utsette din akademiske utdannelse, noe som begrenser dine muligheter hvis tennisen ikke fungerer.
      <br />
      <br />
      Hvis du velger å gå på college, kan du dra nytte av de mange fordelene som collegiatennis tilbyr. Ikke bare kan du få en høykvalitets utdannelse som kan hjelpe deg med å tjene en grad og ha en alternativ karriere, men du kan også nyte en fantastisk livserfaring som kan hjelpe deg med å vokse som person. Du kan også dra nytte av de utmerkede fasilitetene, trenerne, veiledningen og stipendene som universitetene tilbyr. Imidlertid kan du også møte ulemper, som å miste tid og muligheter til å bli profesjonell, ligge etter andre spillere som startet tidligere, eller finne det utfordrende å gå fra college til proffene.
      <br />
      <br />
      Det er ikke et svar som passer alle på om du skal gå på college eller ikke. Det avhenger av din personlige situasjon, mål, preferanser og muligheter. Noen spillere har lyktes ved å bli profesjonelle umiddelbart, mens andre har hatt nytte av sin college-erfaring. Det viktigste er å ta en informert beslutning som passer for deg.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Profesjonelle Tennissirkler
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Hvis du bestemmer deg for å bli profesjonell tennisspiller, må du forstå hvordan de profesjonelle tennissirklene fungerer og hvordan du kommer deg dit. Det er forskjellige sirkler for menn og kvinner, hver med sine egne organisasjoner, regler og turneringer.
      <br />
      <br />
      For menn er det to store sirkler: ATP-touren og ITF-verdenstennissturen. ATP-touren er det høyeste nivået av herretennis, der verdens beste spillere konkurrerer om prestisjetunge titler og premiepenger. ATP-touren består av fire kategorier turneringer: Grand Slam, ATP Finals, ATP Masters 1000 og ATP 250/500. For å delta i disse turneringene må du ha en høy rangering, som bestemmes av antall poeng du tjener ved å vinne kamper.
      <br />
      <br />
      ITF-verdenstennissturen er det lavere nivået av herretennis, der spillere sikter på å forbedre rangeringen og kvalifisere seg for ATP-touren. ITF-verdenstennissturen består av tre kategorier turneringer: ITF-verdenstennisstur M25/M15, ITF-verdenstennisstur M25/M15+H og ITF-verdenstennisstur M15/M15+H. For å delta i disse turneringene må du ha en ITF-rangering, som bestemmes av antall poeng du tjener ved å vinne kamper.
      <br />
      <br />
      For kvinner er det også to store sirkler: WTA-touren og ITF-verdenstennissturen. WTA-touren er det høyeste nivået av kvinnertennis, der verdens beste spillere konkurrerer om prestisjetunge titler og premiepenger. WTA-touren består av fire kategorier turneringer: Grand Slam, WTA Finals, WTA Premier og WTA International. For å delta i disse turneringene må du ha en høy rangering, som bestemmes av antall poeng du tjener ved å vinne kamper.
      <br />
      <br />
      ITF-verdenstennissturen er det lavere nivået av kvinnertennis, der spillere sikter på å forbedre rangeringen og kvalifisere seg for WTA-touren. ITF-verdenstennissturen består av tre kategorier turneringer: ITF-verdenstennisstur W100/W60/W25/W15, ITF-verdenstennisstur W100/W60/W25/W15+H og ITF-verdenstennisstur W15/W15+H. For å delta i disse turneringene må du ha en ITF-rangering, som bestemmes av antall poeng du tjener ved å vinne kamper.
      <br />
      <br />
      For å gå fra collegiatennis til profesjonell tennis, må du bygge en rangering i ITF-verdenstennissturen først og deretter prøve å kvalifisere deg for ATP- eller WTA-touren. Dette kan være en utfordrende og langvarig prosess som krever mye innsats, tålmodighet og besluttsomhet. Imidlertid er det ikke umulig, og det er mange eksempler på spillere som vellykket har gjort overgangen og nå lyser på den profesjonelle sirkelen.
    </MKTypography>
  </MKBox>
</MKBox>
      






                                  







<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 4: Internasjonale Påvirkninger
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Tennis er en global sport som samler mennesker av forskjellige nasjonaliteter, kulturer og bakgrunner. Dette gjelder også for collegiatennis i Amerika, som har en betydelig mangfoldighet og rikdom av internasjonale påvirkninger. I dette kapittelet vil vi utforske hvordan utenlandske spillere har påvirket collegemiljøet og hvordan amerikanske spillere har nådd den internasjonale scenen.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Utenlandske Spillere i Collegemiljøet
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Spillere fra Virginia feirer nasjonalt mesterskap i collegestennis."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Spillere fra Virginia feirer nasjonalt mesterskap
        </figcaption>
      </figure>
      En av de mest bemerkelsesverdige trekkene ved collegiatennis i Amerika er den høye prosentandelen av utenlandske spillere som deltar i sporten. Ifølge Intercollegiate Tennis Association (ITA) var det i 2020 over 3 000 utenlandske spillere som konkurrerte for amerikanske universiteter, noe som utgjorde omtrent 40% av det totale antallet spillere. Disse spillerne kom fra over 100 land, med de vanligste landene som: Storbritannia, Tyskland, Frankrike, Spania, Australia, Canada, Brasil, Japan, Kina og India.
      <br />
      <br />
      Årsakene til at utenlandske spillere velger å komme til Amerika for å studere og spille tennis er varierte. Noen søker en bedre akademisk utdannelse som de ikke kan finne i sitt eget land. Andre leter etter bedre utviklingsmuligheter som ikke er tilgjengelige i hjemlandet sitt. Andre igjen søker en ny livserfaring som de ikke kan ha i sitt eget land. Uansett motivasjon bringer utenlandske spillere mange fordeler til amerikansk collegiatennis.
      <br />
      <br />
      En av fordelene er at utenlandske spillere hever nivået og kvaliteten på spillet. De bringer nye ferdigheter, stiler, taktikker og strategier som gjør spillet mer mangfoldig og interessant. De utfordrer også amerikanske spillere til å gjøre sitt beste og tilpasse seg forskjellige situasjoner. Dessuten bidrar de til prestisje og omdømme til universitetene de spiller for, og tiltrekker mer oppmerksomhet og anerkjennelse.
      <br />
      <br />
      En annen fordel er at utenlandske spillere beriker kulturen og atmosfæren i spillet. De bringer nye perspektiver, ideer, verdier og tradisjoner som gjør spillet mer åpent og inkluderende. De skaper også en følelse av fellesskap og vennskap blant spillere fra forskjellige bakgrunner som samarbeider og støtter hverandre. Videre lærer de av hverandre og deler erfaringene sine, utvider horisontene sine og fremmer personlig vekst.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikanere på den Internasjonale Scenen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Mens utenlandske spillere har en betydelig innvirkning på amerikansk collegiatennis, har amerikanske spillere også hatt en betydelig innflytelse på internasjonal tennis. Mange amerikanske spillere som spilte på college fant senere suksess på den profesjonelle sirkelen og representerte landet sitt på verdensscenen. Noen av disse spillerne inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          John Isner, som gikk på University of Georgia og var en fire ganger All-American. Han er kjent for sin kraftige serve og lange kamper, som den lengste kampen i historien mot Nicolas Mahut på Wimbledon i 2010. Han har vunnet 15 ATP-titler og nådde fjerdeplassen på verdensrangeringen.
        </li>
        <li>
          Kevin Anderson, som gikk på University of Illinois og var to ganger All-American. Han er kjent for sitt sterke grunnlinjespill og jevne prestasjoner. Han har vunnet seks ATP-titler og nådde to Grand Slam-finaler: US Open i 2017 og Wimbledon i 2018.
        </li>
        <li>
          Danielle Collins, som gikk på University of Virginia og var to ganger NCAA-mester. Hun er kjent for sin aggressive og uttrykksfulle spillestil og sin besluttsomhet. Hun har vunnet en WTA-tittel og nådde semifinalen i Australian Open i 2019.
        </li>
        <li>
          Jennifer Brady, som gikk på UCLA og var en gang All-American. Hun er kjent for sine kraftige slag og forbedret bevegelse. Hun har vunnet en WTA-tittel og nådde finalen i Australian Open i 2021.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  




<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 5: Idrettsstipender og Fremtiden til Utøvere
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrettsstipender gir mange muligheter for unge utøvere til å vise sitt talent og utvikle sine ferdigheter. En av de mest betydningsfulle mulighetene er å få et idrettsstipend for å studere og konkurrere på college-nivå. I dette kapittelet vil vi diskutere viktigheten av idrettsstipender og hvordan Sport Scholarships America kan hjelpe deg med å oppfylle drømmen din.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Viktigheten av Stipender
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et idrettsstipend er økonomisk støtte gitt av et universitet til en studentutøver for å kombinere studier og idrett. Det kan dekke kostnadene for skolepenger, bøker, bolig, måltider og andre utgifter. I tillegg kan det tilby fordeler som akademisk veiledning, medisinsk hjelp, treningsfasiliteter og utstyr.
      <br />
      <br />
      Å få et idrettsstipend er konkurransedyktig, med mange faktorer som spiller en rolle i tildelingsprosessen, inkludert akademiske prestasjoner, idrettslige resultater, personlighet, motivasjon, lederegenskaper og tilpasningsevne. Det er derfor viktig å forberede seg godt og skille seg ut fra konkurransen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Scholarships America Kan Hjelpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo for Sport Scholarships America, som viser sportsutstyr og en eksamenskappe, symboliserer fokuset på idrettsstipender for studentutøvere."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo for Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America er en organisasjon spesialisert i å veilede unge idrettstalenter til idrettsstipender. Med lidenskapelige og erfarne eksperter støtter Sport Scholarships America studentutøvere på hvert trinn i reisen, fra første orientering til endelig plassering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig inntaksintervju for å diskutere studentutøverens ønsker, muligheter og forventninger.</li>
        <li>En profesjonell evaluering av studentutøverens akademiske og idrettslige nivå.</li>
        <li>Et omfattende nettverk av kontakter med trenere, universiteter og høyskoler.</li>
        <li>Effektiv markedsføring av studentutøverens profil og videoer til potensielle trenere.</li>
        <li>Ekspertråd om de beste alternativene basert på studentutøverens preferanser, mål og budsjett.</li>
        <li>Forhandlinger om de beste stipendtilbudene på vegne av studentutøveren.</li>
        <li>Hjelp til å søke om nødvendige dokumenter, som visum, transkripsjon, diploma og opptaksprøver.</li>
        <li>Forberedelse til studentutøverens liv under stipendet, inkludert kultur, språk, regler og tips.</li>
        <li>Oppfølging og oppfølging under stipendperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vil du utforske stipendmuligheter og oppnå dine idrettslige og akademiske mål? <Link to="/no/Tjenester-Produkter">Se det komplette utvalget</Link> av tjenester som tilbys av Sport Scholarships America og planlegg en konsultasjon i dag.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;