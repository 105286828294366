/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================
* Produktside: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
Kodet af www.creative-tim.com
=========================================================
*/

// @mui materialkomponenter
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React-komponenter
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Billeder
import post2 from "assets/images/TijdUS.png";
import "../../../../H1asH3.css"

function Profil() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center" />
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} justifyContent="space-between" alignItems="center" mb={1}>
                <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Del 1: Opvækst i Nederlandene</MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text">
  <br />
  I de fortryllende omgivelser i Nijmegen begyndte mit livslange kærlighedsforhold til fodbold. Som barn fra Nijmegens gader følte jeg magien i sporten med hvert skridt, jeg tog på den grønne græs. Min første klub var BVC '12, hvor jeg som ung dreng oplevede mine første driblinger og mål. Det var en tid med uskyldighed, hvor jeg opdagede min passion for sporten, og hvor grundlaget for min fremtid blev lagt.
  <br /><br />

  Efter mit eventyr hos BVC '12 begav jeg mig ud på en rejse gennem lokale fodboldklubber som Quick, NEC og Union. Denne rejse dannet grundlaget for min fodbolduddannelse og bragte mig tættere på min drøm: at nå toppen af hollandsk ungdomsfodbold. Som ung fodboldspiller drømte jeg om at spille på store stadioner omgivet af en hav af jublende fans. Det var en drøm, der blev delt af mange unge talenter i Nederlandene, og jeg var fast besluttet på at gøre alt for at gøre denne drøm til virkelighed.
  <br /><br />

  Men mens jeg udviklede mit fodboldtalent, begyndte jeg også at indse, hvor vigtig en god uddannelse er for en solid fremtid. I Nederlandene virkede mine ambitioner inden for fodbold og uddannelse sommetider modstridende. Det var en udfordring at afveje begge passioner.
  <br /><br />

  Amerikas sprudlende mangfoldighed og enorme muligheder havde altid fascineret mig, og denne fascination voksede kun under en ferie med min familie. Jeg var næsten sikker på, at jeg ville vende tilbage til dette land, måske i en længere periode. Senere hørte jeg via bekendte historier om mulighederne for at kombinere en karriere inden for fodbold med universitetsstudier i Amerika, og min lyst til at følge den samme vej voksede eksponentielt.
  <br /><br />

  Alligevel var det ikke nemt at arrangere alt. Den komplekse proces med sportslegater og studiemuligheder i Amerika virkede først skræmmende. Et sted vidste jeg, at det kunne være enklere og mere tilgængeligt, hvilket senere blev min motivation for at starte dette firma år senere.
  <br /><br />

  Efter at have kontaktet forskellige trænere havde træneren for University of Tulsa en særligt overbevisende historie at dele. Han talte om et hold, der altid konkurrerede om priser, konstant rangeret blandt de 25 bedste i landet og deltog i store konkurrencer. Hans ord gav mig selvtillid til, at dette var stedet, hvor jeg kunne opfylde både mine drømme om fodbold og mine akademiske ambitioner.
  <br /><br />

  Og så skete det, to uger efter min 18-års fødselsdag var mit kapitel i Nederlandene (midlertidigt) afsluttet. Jeg bordede flyet til Amerika, klar til det eventyr, der ventede forude. Fast besluttet på at forene mine lidenskaber for fodbold og uddannelse stod jeg i begyndelsen af en spændende rejse. Mere om mine oplevelser i Amerika deler jeg gerne med dig i del 2 af min historie, hvor jeg dykker dybere ned i mine oplevelser i USA.
</MKTypography>
<hr style={{ margin: '20px 0'}} />

<MKTypography component="a" href="#" variant="body1" fontWeight="light" color="info" mt={3}>
  <Grid container justifyContent="flex-end"> {/* Denne linje er opdateret */}
    <Grid item xs={12} lg={6} mb={{ xs: 3, lg: 0 }}>
      <TransparentBlogCard
        image={post2}
        title="Del 2: Min Tid i Amerika"
        action={{
          type: "internal",
          route: "/da/Om-mig/Min-tid-i-Amerika",
          color: "info",
          label: "læs mere",
        }}
      />
    </Grid>
  </Grid>
</MKTypography>

</Grid>
</Grid>
</Grid>
</Container>
</MKBox>
);
}

export default Profil;
