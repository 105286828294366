// Componenti materiali di @mui
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Componenti Material Kit 2 PRO React
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";
import "../../../../H1asH3.css"

// Immagini
import profilePicture from "assets/images/Profielfoto_Kay_Duit.jpeg";

function Profile() {
  return (
    <MKBox component="section" pt={{ xs: 20, sm: 20,  md: 6}} pb={{ xs: 6, sm: 0}}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
            <MKAvatar src={profilePicture} alt="Foto del profilo di Kay Duit, fondatore e proprietario" size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox 
                display="flex" 
                flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} 
                justifyContent="space-between" 
                alignItems="center" 
                mb={1}
              >
                <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Kay Duit</MKTypography>
                <MKBox display="flex" flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} gap={1}>
                <a href="https://www.linkedin.com/in/kayduit/" target="_blank" rel="noopener noreferrer">
                <MKSocialButton color="linkedin" style={{ width: '140px' }}>
                    <MKBox component="i" color="inherit" mr={0.5} className="fab fa-linkedin" />
                    LinkedIn
                  </MKSocialButton>
                  </a>
                  <a href="https://www.instagram.com/kayduit/" target="_blank" rel="noopener noreferrer">
                  <MKSocialButton color="instagram" style={{ width: '140px' }}>
                    <MKBox component="i" color="inherit" mr={0.5} className="fab fa-instagram" />
                    Instagram
                  </MKSocialButton>
                  </a>
                  <a href="/Podcast">
                  <MKSocialButton color="pinterest" style={{ width: '140px' }}>
                    <MKBox component="i" color="inherit" mr={0.5} className="fas fa-podcast" />
                    Podcast
                  </MKSocialButton>
                  </a>
                </MKBox>
              </MKBox>
              <br />
              <MKBox>
  <MKTypography variant="body1" fontWeight="light" color="text">
    In una calda serata d'estate in America, ero sul bordo del campo da calcio, in attesa del fischio per entrare in campo. Il mio cuore batteva forte in gola, non a causa della partita imminente, ma a causa della realizzazione di quanto fossi giunto lontano. Tre anni prima, avevo preso un aereo dal mio paese natale agli Stati Uniti, un salto nel vuoto alla ricerca di avventura e dell'opportunità di realizzare sia i miei sogni sportivi che accademici.
    <br /><br />
    In quei quattro anni avevo vissuto molto di più delle partite di calcio e dello studio. C'erano le cene notturne dopo una grande vittoria, i voli per le partite in trasferta in stati lontani, le amicizie che avevo costruito e le innumerevoli volte in cui avevo realizzato quanto il mondo fosse più grande del piccolo luogo da cui venivo.
    <br /><br />
    Quella sera, mentre suonava l'inno nazionale e gli spalti erano pieni di migliaia di tifosi che urlavano, ho pensato a tutti i giovani studenti di tutto il mondo che, come me, sognavano tali opportunità. E ho fatto un impegno a me stesso che una volta concluso il mio viaggio in America, avrei teso una mano ai giovani studenti aspiranti, indipendentemente da dove venissero, aiutandoli a realizzare sogni simili ai miei.
  </MKTypography>
</MKBox>

              <MKTypography
                component="a"
                href="#"
                variant="body1"
                fontWeight="light"
                color="info"
                mt={3}
                sx={{
                  width: "max-content",
                  display: "flex",
                  alignItems: "center",
                  "& .material-icons-round": {
                    transform: `translateX(3px)`,
                    transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                  },
                  "&:hover .material-icons-round, &:focus .material-icons-round": {
                    transform: `translateX(6px)`,
                  },
                }}
              >
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
