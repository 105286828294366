/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Rowing1.jpg";
import Image2 from "assets/images/Sports_Images/Rowing2.jpg";
import Image3 from "assets/images/Sports_Images/Rowing3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images

import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Studere og ro i Amerika: En unik mulighet for unge roere', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapittel 1: Historien og utviklingen av roing i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 De tidlige årene og rollen til collegene', isSubheader: true },
    { id: '1.2', title: '1.2 Vekst og tradisjoner', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapittel 2: Roing på universitetsnivå i dag', isSubheader: false },
    { id: '2.1', title: '2.1 Store konkurranser og konferanser', isSubheader: true },
    { id: '2.2', title: '2.2 Ledende programmer og lag', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapittel 3: Fra College til Elite', isSubheader: false },
    { id: '3.1', title: '3.1 Veien til Toppen', isSubheader: true },
    { id: '3.2', title: '3.2 Profesjonelle og internasjonale muligheter', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapittel 4: Internasjonale påvirkninger og mangfold', isSubheader: false },
    { id: '4.1', title: '4.1 Internasjonale Utøvere i Collegiate Roing', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanske Roere i Utlandet', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapittel 5: Idrettsstipender og Fremtiden til Utøvere', isSubheader: false },
    { id: '5.1', title: '5.1 Viktigheten av Stipender', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Scholarships America Kan Hjelpe', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>

























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Studere og ro i Amerika: En unik mulighet for unge roere
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Roere i aksjon under den nasjonale collegeroingmesterskapet."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Roere i aksjon under nasjonalmesterskapet
      </figcaption>
    </figure>
    Roing er en av de eldste og mest prestisjefylte idrettene i USA. Siden 1800-tallet har høykvalitets roing blitt praktisert ved amerikanske universiteter, der roere ikke bare utvikler sin fysiske og mentale styrke, men også sine akademiske og sosiale ferdigheter. Å studere og ro i Amerika er en unik mulighet for unge roere som ønsker å følge sine idrettsdrømmer samtidig som de får en verdifull utdanning og en uforglemmelig opplevelse. I denne artikkelen vil vi fortelle deg alt du trenger å vite om roing i Amerika, fra dens historie og tradisjoner til mulighetene og utfordringene. Vi vil også forklare hvordan Sportbeursamerika.nl kan hjelpe deg med å sikre et rostipend og forberede deg på ditt eventyr.
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 1: Historien og utviklingen av roing i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    I dette kapittelet dykker vi ned i den fascinerende historien om roing i USA. Vi utforsker hvordan denne sporten spredte seg over havet fra England og ble en integrert del av amerikansk universitetskultur. Fra de første roingene på Lake Winnipesaukee til de ikoniske rivaliseringene mellom prestisjefylte universiteter, undersøker vi utviklingen av roing som en sport som inkorporerer både fysisk smidighet og akademisk stolthet.
  </MKTypography>
  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 De tidlige årene og rollen til collegene
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Roing som en sport oppsto i England på 1700-tallet da løp ble holdt mellom profesjonelle roere på Themsen. Disse løpene trakk store publikummere og veddemål, noe som raskt gjorde roing populært. På 1700- og 1800-tallet spredte roing seg til andre land, inkludert USA. Det første amerikanske roeløpet fant sted i 1843 på Lake Winnipesaukee i New Hampshire. I 1852 ble det første interkollegiale roeløpet holdt mellom Harvard og Yale på Lake Winnipesaukee. Dette var også den første interkollegiale sportsbegivenheten i USA. Siden den gang har roing blitt en betydelig del av amerikansk collegidrett.
      <br />
      <br />
      Collegene hadde en avgjørende rolle i å fremme roing. Collegene så på roing som en måte å innprente disiplin, samarbeid og ledelse hos studentene sine samtidig som de forbedret sitt renommé og omdømme. Collegene investerte betydelige summer og ressurser i å bygge båthus, skaffe båter og tiltrekke seg trenere. De arrangerte også jevnlig konkurranser mot andre colleg, både regionalt og nasjonalt. Disse konkurransene ble ofte godt besøkt av studenter, alumner, medier og sponsorer, og skapte stor interesse og engasjement for sporten.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Vekst og tradisjoner
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Roing vokste jevnt i popularitet og prestisje i andre halvdel av 1800-tallet og første halvdel av 1900-tallet. Forskjellige regionale og nasjonale konkurranser oppsto, som Eastern Sprints, IRA National Championship Regatta, Dad Vail Regatta og Head of the Charles Regatta. Amerikanske roere deltok også i internasjonale arrangementer, inkludert Henley Royal Regatta i England og olympiske leker. Amerikanske roere oppnådde ofte imponerende resultater, med gullmedaljer på åtte påfølgende olympiske leker mellom 1920 og 1956.
      <br />
      <br />
      Roing utviklet også en rik kultur og tradisjon ved amerikanske colleg. Hvert universitet hadde sine egne farger, maskot, sanger og ritualer knyttet til roing. Noen tradisjoner var lekne eller symbolske, som å kaste styrmannen i vannet etter en seier eller å bruke en spesiell slips eller pin. Andre tradisjoner var alvorlige eller seremonielle, som å synge alma mater-sangen eller holde en minnestund for avdøde roere. Disse tradisjonene bidro til følelsen av stolthet, fellesskap og identitet blant roere.
    </MKTypography>
  </MKBox>
</MKBox>





                                  








<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 2: Roing på universitetsnivå i dag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    I dette kapittelet fokuserer vi på moderne roing på universitetsnivå i Amerika. Vi undersøker hvordan sporten har utviklet seg til å bli en svært konkurransedyktig og prestisjefylt del av det kollegiale idrettslandskapet. Her diskuterer vi de største konkurransene og konferansene, strukturen til NCAA-divisjonene, og tar en titt på noen av de ledende universitetsroprogrammene. Denne seksjonen fremhever den nåværende tilstanden for roing ved amerikanske universiteter og de unike mulighetene den tilbyr unge idrettsutøvere.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Store konkurranser og konferanser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Stanford roteam feirer seieren i det nasjonale mesterskapet."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Stanford roere feirer det nasjonale mesterskapet
        </figcaption>
      </figure>
      Roing på universitetsnivå forblir en svært konkurransedyktig og prestisjefylt idrett i USA i dag. Det er mer enn 300 universiteter som tilbyr roprogrammer, med omtrent 150 som deltar i NCAA (National Collegiate Athletic Association), den overordnede organisasjonen for collegidrett. NCAA deler universiteter inn i tre divisjoner basert på antall studenter, budsjett og nivået på sporten. Divisjon I er den høyeste divisjonen, med de mest robuste og konkurransedyktige roprogrammene. Divisjonene II og III har færre og mindre roprogrammer, men er fortsatt svært konkurransedyktige.
      <br />
      <br />
      Innenfor hver divisjon er det ulike konferanser, som er grupper av universiteter som er geografisk eller historisk knyttet sammen. Hver konferanse organiserer sin egen mesterskap, der de beste lagene kvalifiserer seg for nasjonale mesterskap. De største konferansene for roing inkluderer Big Ten, Pac-12, Ivy League, ACC og Big 12. Disse konferansene har ofte de mest vellykkede og innflytelsesrike roprogrammene i landet.
      <br />
      <br />
      I tillegg til konferansemesterskapene, er det andre betydningsfulle nasjonale regattaer som universitetene kan delta i. Den mest prestisjefylte er NCAA Women’s Rowing Championship, som har vært arrangert siden 1997 for kvinnelige roere i alle tre divisjonene. For mannlige roere er det ingen tilsvarende NCAA-mesterskap, men det er IRA National Championship Regatta, som har vært arrangert siden 1895 for både varslag og lettvektlag. Andre velkjente nasjonale regattaer inkluderer Dad Vail Regatta, San Diego Crew Classic, ACRA National Championship Regatta og CRASH-B Sprints.
    </MKTypography>
  </MKBox>

  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Ledende programmer og lag
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Roing på universitetsnivå har en lang historie med rivalisering, fremragende prestasjoner og innovasjon. Mange universiteter har utmerket seg gjennom sine roprogrammer, både når det gjelder prestasjon og utvikling. Noen av disse universitetene inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          University of Washington: Huskies er kjent for sitt sterke og respekterte roprogram. Med flere nasjonale mesterskap i herre-roing og en historie med å produsere olympiske medaljevinnere, inkludert den legendariske "Boys in the Boat" som vant gull i 1936. Huskies er feiret for sin strenge trening, sterke lagarbeid og innflytelsesrike trenere som Al Ulbrickson.
        </li>
        <li>
          Yale University: Som en av pionerene innen intercollegial roing, har Yale Bulldogs et gammelt og prestisjefylt roprogram. Historien deres inkluderer flere nasjonale titler og et betydelig bidrag til amerikansk roing på olympisk nivå. Yale er også kjent for sin akademiske fremragende og historiske rivalisering med Harvard.
        </li>
        <li>
          University of California, Berkeley: Golden Bears har et vellykket og allsidig roprogram utmerket seg med sine nasjonale titler og olympiske deltakere. Programmet er kjent for sin mangfoldighet, samfunnsengasjement og dedikerte trening.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>






                                  









<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 3: Fra College til Elite
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    I dette kapittelet utforsker vi reisen til college-roere til de elite-rekkene av roingens verden. Vi undersøker hvordan erfaringene og ferdighetene tilegnet av roere på universitetsnivå forbereder dem på høyere nivåer av konkurranse, inkludert deltakelse på nasjonale lag og de olympiske leker. Dette kapittelet fremhever overgangen fra collegiar roing til profesjonelle og internasjonale arenaer, med fokus på veiene utøvere følger, utfordringene de møter, og suksesshistoriene til de som når toppen. Vi ser også på hvordan universitetsroprogrammer identifiserer og utvikler talenter, og legger veien for fremtidige olympiere og verdensmestere.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Veien til Toppen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Roing på collegenivå er ikke bare en sportslig utfordring, men også en potensiell springbrett til de elite nivåene av roingens verden. Mange roere som har rodd på collegenivå, går videre til nasjonale lag og olympisk roing. Dette skyldes at college-roing gir utmerket forberedelse når det gjelder teknikk, taktikk, fysisk og mental kondisjonering, og samarbeid. I tillegg gir college-roing muligheten til å skille seg ut for speidere og trenere fra nasjonale forbund og uttak.
      <br />
      <br />
      Det finnes utallige eksempler på roere som har gått fra college til elitenivå. Et nylig eksempel på en roer som vant en olympisk medalje etter sin collegkarriere, er Madison Mailey. Mailey, en All-American-roer ved Northeastern University, som ble ferdig utdannet i 2018, vant en gullmedalje ved Tokyo-OL i kvinnenes åtter for Canada. Hun og hennes lagkamerater tok en tidlig ledelse og holdt unna for en sen utfordring fra New Zealand, og vant med bare 0,91 sekunder.
    </MKTypography>
  </MKBox>

  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Profesjonelle og internasjonale muligheter
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      College-roing åpner døren for profesjonelle og internasjonale muligheter for roere. I tillegg til muligheten for olympisk deltakelse, kan universitetsroere bruke sine ferdigheter til å konkurrere i prestisjetunge internasjonale regattaer som Henley Royal Regatta eller World Rowing Cup. Disse arrangementene gir en plattform for roere å måle seg mot verdens beste og få erfaring i en internasjonal sammenheng.
      <br />
      <br />
      Videre er det en vei til profesjonell roing der college-kandidater kan bli med i profesjonelle lag og delta i globale konkurranser. Dette tilbyr en heltids karriere innen roing med intensiv trening og muligheten til å konkurrere på høyeste nivå.
      <br />
      <br />
      For de som er interessert i en trenerkarriere, gir college-roing et solid grunnlag. Mange vellykkede rotrener er tidligere collegiat-utøvere som bruker sin erfaring og ekspertise til å veilede nye generasjoner av roere. Denne karriereveien kan føre til stillinger med nasjonale lag og roklubber over hele verden.
      <br />
      <br />
      Oppsummert kan erfaringene som er oppnådd i college-roing føre til en rekke profesjonelle og internasjonale muligheter, fra utøvere som forfølger en profesjonell karriere til de som fortsetter idretten i en trenerrolle.
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  







<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 4: Internasjonale påvirkninger og mangfold
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Dette kapittelet fremhever de internasjonale dynamikkene og mangfoldet innen college-roing i USA. Vi utforsker hvordan internasjonale studentatleter beriker sporten med sine unike bakgrunner og perspektiver, og hvordan amerikanske roere finner muligheter til å ro i utlandet og videreutvikle seg selv. Disse interaksjonene bidrar til en rikere kulturell opplevelse og utvider horisontene både for amerikanske og internasjonale roere. Vi går inn på de ulike veiene internasjonale studenter tar for å ro på collegenivå i USA, fordelene med disse erfaringene, og den påvirkningen de har på sporten og dens deltakere. I tillegg fokuserer vi på mulighetene for amerikanske roere til å få internasjonal erfaring, inkludert utvekslingsprogrammer, internasjonale treningsleirer og treningsmuligheter. Dette kapittelet understreker betydningen av internasjonale påvirkninger for å berike collegial roing og fremme kulturell utveksling og forståelse.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Internasjonale Utøvere i Collegiate Roing
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Roing båter linet opp for starten av et collegiat roingsløp."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Båter klare for starten av et løp
        </figcaption>
      </figure>
      Collegiate roing er ikke bare en amerikansk affære, men også en internasjonal en. Mange internasjonale studenter kommer til USA for å studere og ro på amerikanske universiteter. Disse studentene bringer med seg sin egen kultur, bakgrunn og perspektiv, og bidrar til mangfoldet og kvaliteten på lagene. Dessuten gir de en mulighet for kulturell utveksling og vennskap blant roere fra forskjellige land.
      <br />
      <br />
      Det er flere grunner til at internasjonale studenter velger collegiat roing i USA. Noen av disse grunnene inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Ro-stipend: Mange amerikanske universiteter tilbyr ro-stipend til talentfulle roere, som kan redusere eller til og med eliminere deres skolepenger. Dette er en betydelig motivasjon for internasjonale studenter som kanskje ikke ellers kunne studere i USA.
        </li>
        <li>
          Akademisk dyktighet: Mange amerikanske universiteter er kjent for sine høye akademiske standarder og rykte. Dette tiltrekker internasjonale studenter som søker en utmerket utdanning og et globalt anerkjent diplom.
        </li>
        <li>
          Sportslig utfordring: Mange internasjonale studenter ser på collegiat roing som en sportslig utfordring og en mulighet til å konkurrere med verdens beste roere. De har som mål å forbedre sine ferdigheter, utfordre grensene sine, og oppfylle sitt potensial.
        </li>
        <li>
          Personlig utvikling: Mange internasjonale studenter ser på collegiat roing som personlig utvikling og en sjanse til å vokse som individer. De ønsker å få nye erfaringer, møte nye mennesker, lære om nye kulturer, og utvide horisontene sine.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      "Niki van Sprang er et eksempel på en internasjonal student som rodde på collegenivå i USA. Van Sprang, opprinnelig fra Nederland hvor han vant flere nasjonale titler, mottok et ro-stipend fra University of California, Berkeley. Der ble han med på varsity-laget og oppnådde betydelig suksess. Etter å ha fullført utdannelsen i 2017, vendte Van Sprang tilbake til Nederland og ble medlem av det nasjonale rolandslaget. Han deltok i OL i Tokyo 2020, der han og Guillaume Krommenhoek kom på 7. plass i herrenes dobbeltsculler. I tillegg vant han sølvmedalje ved VM i Račice 2022 og ved EM i München 2022, samt bronsemedalje ved EM i Bled 2023, alle i herrenes åtter."
    </MKTypography>
  </MKBox>

  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikanske Roere i Utlandet
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Collegiat roing kan også føre til internasjonale muligheter for amerikanske studentatleter som ønsker å utvide eller endre sine sportslige horisonter. Det er ulike muligheter for amerikanske roere til å ro i utlandet og få erfaring. Noen av disse mulighetene inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Studieutveksling: Noen amerikanske universiteter har utvekslingsprogrammer med utenlandske universiteter, som lar studenter studere og ro i et annet land i en semester eller et år. Dette gir dem muligheten til å oppleve en annen kultur, språk, og utdanningssystem, samt å ro med andre lag og trenere.
        </li>
        <li>
          Treningssamlinger: Noen amerikanske universiteter organiserer treningssamlinger i utlandet, vanligvis i løpet av vinter- eller sommerferien. Dette gir dem sjansen til å trene i en annen miljø, klima, og vannforhold, samt å konkurrere mot andre lag eller delta i lokale regattaer.
        </li>
        <li>
          Trener: Noen amerikanske roere velger å trene i utlandet etter endt utdanning, vanligvis på skoler, klubber, eller nasjonale lag. Dette gir dem muligheten til å dele sin kunnskap og erfaring, samt å lære av andre trenere og kulturer.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 5: Idrettsstipender og Fremtiden til Utøvere
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrettsstipender gir mange muligheter for unge utøvere til å vise sitt talent og utvikle sine ferdigheter. En av de mest betydningsfulle mulighetene er å få et idrettsstipend for å studere og konkurrere på college-nivå. I dette kapittelet vil vi diskutere viktigheten av idrettsstipender og hvordan Sport Scholarships America kan hjelpe deg med å oppfylle drømmen din.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Viktigheten av Stipender
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et idrettsstipend er økonomisk støtte gitt av et universitet til en studentutøver for å kombinere studier og idrett. Det kan dekke kostnadene for skolepenger, bøker, bolig, måltider og andre utgifter. I tillegg kan det tilby fordeler som akademisk veiledning, medisinsk hjelp, treningsfasiliteter og utstyr.
      <br />
      <br />
      Å få et idrettsstipend er konkurransedyktig, med mange faktorer som spiller en rolle i tildelingsprosessen, inkludert akademiske prestasjoner, idrettslige resultater, personlighet, motivasjon, lederegenskaper og tilpasningsevne. Det er derfor viktig å forberede seg godt og skille seg ut fra konkurransen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Scholarships America Kan Hjelpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo for Sport Scholarships America, som viser sportsutstyr og en eksamenskappe, symboliserer fokuset på idrettsstipender for studentutøvere."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo for Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America er en organisasjon spesialisert i å veilede unge idrettstalenter til idrettsstipender. Med lidenskapelige og erfarne eksperter støtter Sport Scholarships America studentutøvere på hvert trinn i reisen, fra første orientering til endelig plassering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig inntaksintervju for å diskutere studentutøverens ønsker, muligheter og forventninger.</li>
        <li>En profesjonell evaluering av studentutøverens akademiske og idrettslige nivå.</li>
        <li>Et omfattende nettverk av kontakter med trenere, universiteter og høyskoler.</li>
        <li>Effektiv markedsføring av studentutøverens profil og videoer til potensielle trenere.</li>
        <li>Ekspertråd om de beste alternativene basert på studentutøverens preferanser, mål og budsjett.</li>
        <li>Forhandlinger om de beste stipendtilbudene på vegne av studentutøveren.</li>
        <li>Hjelp til å søke om nødvendige dokumenter, som visum, transkripsjon, diploma og opptaksprøver.</li>
        <li>Forberedelse til studentutøverens liv under stipendet, inkludert kultur, språk, regler og tips.</li>
        <li>Oppfølging og oppfølging under stipendperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vil du utforske stipendmuligheter og oppnå dine idrettslige og akademiske mål? <Link to="/no/Tjenester-Produkter">Se det komplette utvalget</Link> av tjenester som tilbys av Sport Scholarships America og planlegg en konsultasjon i dag.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;