/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Basketbal1.jpeg";
import Image2 from "assets/images/Sports_Images/Basketbal2.webp";
import Image3 from "assets/images/Sports_Images/Basketbal3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import { Link } from 'react-router-dom';

import "../../../../H1asH3.css"

// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Beca de Baloncesto en Estados Unidos: Cómo Estudiar y Jugar Baloncesto en América', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capítulo 1: Historia y Desarrollo del Baloncesto Universitario', isSubheader: false },
    { id: '1.1', title: '1.1 Primeros Años', isSubheader: true },
    { id: '1.2', title: '1.2 Crecimiento y Profesionalización', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capítulo 2: Baloncesto Universitario Hoy', isSubheader: false },
    { id: '2.1', title: '2.1 Competencias y Conferencias Importantes', isSubheader: true },
    { id: '2.2', title: '2.2 Programas Principales y Equipos Prestigiosos', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capítulo 3: Desde la Universidad hasta la NBA', isSubheader: false },
    { id: '3.1', title: '3.1 El Camino hacia la NBA', isSubheader: true },
    { id: '3.2', title: '3.2 Otras Oportunidades Profesionales', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capítulo 4: Influencias y Oportunidades Internacionales', isSubheader: false },
    { id: '4.1', title: '4.1 Jugadores Extranjeros en el Baloncesto Universitario', isSubheader: true },
    { id: '4.2', title: '4.2 Jugadores Estadounidenses en el Extranjero', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capítulo 5: Becas Deportivas y el Futuro de los Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 La Importancia de las Becas', isSubheader: true },
    { id: '5.2', title: '5.2 Cómo Puede Ayudar Sport Scholarships America', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };





  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contenidos
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Ocultar</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>





















{/* Sección de Introducción: Beca de Baloncesto en Estados Unidos */}
<MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Beca de Baloncesto en Estados Unidos: Cómo Estudiar y Jugar Baloncesto en América
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Estadio vibrante de la Universidad de Arizona durante un juego de baloncesto universitario"
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Día de juego en el Estadio de la Universidad de Arizona
      </figcaption>
    </figure>
    El baloncesto es uno de los deportes más populares en los Estados Unidos. Millones de personas siguen los juegos de la NBA, la liga profesional de baloncesto, y la NCAA, la liga de baloncesto universitario. El baloncesto no solo es un juego emocionante para ver, sino también una excelente manera de desarrollarse como jugador y como persona.
    <br/>
    <br/>
    Si eres un joven jugador de baloncesto que sueña con una carrera en la NBA, o simplemente quieres disfrutar jugando baloncesto a un alto nivel, entonces una beca de baloncesto en América podría ser para ti. Una beca de baloncesto es el apoyo financiero que recibes de una universidad estadounidense para estudiar y jugar baloncesto allí. Con una beca de baloncesto, no solo puedes obtener tu título académico, sino también mejorar tus talentos en el baloncesto bajo la guía profesional.
    <br/>
    <br/>
    Pero, ¿cómo se obtiene una beca de baloncesto en América? ¿Cuáles son los beneficios y desafíos de jugar baloncesto universitario? ¿Y cuáles son las posibilidades después de tu carrera universitaria? En este artículo, responderemos a todas estas preguntas y te diremos todo lo que necesitas saber sobre las becas de baloncesto en América. También discutiremos el papel de Sportbeursamerika.nl, una organización especializada en ayudar a los jóvenes jugadores a realizar su sueño de una beca de baloncesto.
    <br/>
    <br/>
    ¿Quieres saber más sobre las becas de baloncesto en América? ¡Sigue leyendo!
  </MKTypography>
  {/* Agrega una lista con viñetas u otro contenido según sea necesario */}
</MKBox>

{/* Capítulos principales y subcapítulos con texto de relleno */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 1: Historia y Desarrollo del Baloncesto Universitario
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El baloncesto es un deporte relativamente joven, inventado en 1891 por James Naismith, un profesor canadiense de educación física. Concibió el juego como una actividad indoor para sus estudiantes durante los meses de invierno. El juego rápidamente se volvió popular y se extendió por diversas escuelas y universidades en América del Norte.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Primeros Años
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      El primer partido oficial de baloncesto a nivel universitario tuvo lugar en 1895 entre la Universidad de Chicago y la Universidad de Iowa. En 1901, se estableció la primera conferencia intercolegial, la Conferencia del Oeste, más tarde conocida como la Conferencia del Big Ten. En 1906, se organizó el primer campeonato nacional por la Unión Atlética Amateur (AAU), que dominó el baloncesto universitario hasta 1936.
      <br/>
      <br/>
      En 1939, se llevó a cabo el primer torneo de la NCAA, con ocho equipos compitiendo por el título. El torneo fue ganado por Oregon, derrotando a Ohio State 46-33. El torneo creció hasta convertirse en un evento anual que atrajo cada vez más la atención de los aficionados y los medios de comunicación.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crecimiento y Profesionalización
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Después de la Segunda Guerra Mundial, el baloncesto universitario aumentó en popularidad y calidad. Surgieron nuevas estrellas, como Bill Russell, Wilt Chamberlain, Oscar Robertson y Kareem Abdul-Jabbar, quienes luego también se hicieron un nombre en la NBA. Se formaron nuevas competiciones y conferencias, como el Torneo de Invitación Nacional (NIT), la Conferencia de la Costa Atlántica (ACC) y la Conferencia del Sudeste (SEC).
      <br/>
      <br/>
      En 1957, tuvo lugar uno de los juegos más memorables en la historia del baloncesto universitario cuando los Tar Heels de Carolina del Norte derrotaron a los Jayhawks de Kansas 54-53 después de tres tiempos extras. El juego se considera un punto de inflexión en el baloncesto universitario, mostrando lo emocionante y dramático que podía ser el juego.
      <br/>
      <br/>
      Otro juego histórico ocurrió en 1966 cuando los Mineros de Texas Western derrotaron a los Wildcats de Kentucky 72-65. El juego fue innovador porque Texas Western fue el primer equipo en consistir enteramente de jugadores negros, mientras que Kentucky era enteramente blanco. El juego se considera un símbolo de la lucha por los derechos civiles y la integración en América.
      <br/>
      <br/>
      En 1979, tuvo lugar la primera transmisión en vivo del torneo de la NCAA, con un número récord de espectadores. La final fue entre los Spartans de Michigan State y los Sycamores de Indiana State, liderados por las futuras leyendas de la NBA Magic Johnson y Larry Bird, respectivamente. Michigan State ganó el juego 75-64 y se considera una de las mejores finales de la historia.
      <br/>
      <br/>
      En las décadas de los 80 y 90, el baloncesto universitario continuó creciendo y evolucionando, con nuevas reglas, estilos y estrategias. Surgieron nuevas rivalidades, como Duke vs North Carolina, Georgetown vs Syracuse y UCLA vs Arizona. También hubo nuevas estrellas, como Michael Jordan, Patrick Ewing, Shaquille O'Neal y Tim Duncan, quienes elevaron el baloncesto universitario a un nivel superior.
    </MKTypography>
  </MKBox>
</MKBox>













                                              





                                  







{/* Capítulo 2: Baloncesto Universitario Hoy */}
<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 2: Baloncesto Universitario Hoy
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El baloncesto universitario es uno de los deportes más populares y prestigiosos en Estados Unidos hoy en día. Cada año, los juegos atraen a millones de fanáticos a los estadios y pantallas de televisión. El baloncesto universitario también es una importante fuente de talento para la NBA, reclutando a cientos de jugadores cada año de los equipos universitarios.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Competencias y Conferencias Importantes
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Steph Curry en acción para Davidson College, destacándose en el baloncesto universitario."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Stephen Curry en acción como jugador de Davidson College
        </figcaption>
      </figure>
      El baloncesto universitario es principalmente organizado por la Asociación Nacional de Atletismo Universitario (NCAA), que supervisa más de 1,000 universidades y colegios. La NCAA divide a los equipos en tres divisiones, siendo la División I la más alta. La División I consta de 32 conferencias, cada una compuesta por equipos que están conectados geográfica o históricamente.
      <br/>
      <br/>
      Las conferencias son importantes ya que determinan qué equipos juegan entre sí durante la temporada regular, que va desde noviembre hasta marzo. Cada conferencia tiene su propio calendario, reglas y campeonato. Los campeones de conferencia ganan automáticamente un lugar en el torneo de la NCAA, la cúspide del baloncesto universitario.
      <br/>
      <br/>
      El torneo de la NCAA, también conocido como Locura de Marzo, es un torneo de eliminación directa que se celebra todos los años en marzo y abril. Consiste en 68 equipos, 32 de los cuales son campeones de conferencia, y 36 son invitaciones especiales, seleccionadas por un comité en función de su desempeño durante la temporada. Los equipos se dividen en cuatro regiones y se clasifican del 1 al 16.
      <br/>
      <br/>
      El torneo comienza con cuatro juegos de entrada, donde los equipos con menor clasificación compiten por los últimos cuatro lugares en el torneo principal. El torneo principal consta de seis rondas, eliminando a un equipo en cada ronda hasta que uno queda. Los cuatro equipos finales, conocidos como el Final Four, juegan en una ubicación neutral para determinar el campeón nacional.
      <br/>
      <br/>
      El torneo de la NCAA es uno de los eventos deportivos más emocionantes e impredecibles del mundo. Cada año hay sorpresas, dramas y sensaciones, conocidos como sorpresas, ganadores en el último segundo e historias de Cenicienta. El torneo atrae a millones de espectadores y apostadores, que intentan predecir al ganador.
      <br/>
      <br/>
      Además del torneo de la NCAA, hay otras competencias significativas en el baloncesto universitario, como el NIT, CBI, CIT y el torneo de la NAIA. Estas competencias ofrecen oportunidades para equipos que no califican para el torneo de la NCAA o quieren ganar experiencia adicional.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programas Principales y Equipos Prestigiosos
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      El baloncesto universitario cuenta con muchos programas principales y equipos prestigiosos, distinguidos por su historia, tradición, éxito e influencia. Algunos de estos programas son:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          UCLA Bruins: El programa más exitoso en la historia del baloncesto universitario, con 11 títulos nacionales, incluyendo 10 bajo el entrenador John Wooden en los años 60 y 70. UCLA ha producido muchos jugadores famosos, como Kareem Abdul-Jabbar, Bill Walton y Reggie Miller.
        </li>
        <li>
          Kentucky Wildcats: El segundo programa más exitoso en la historia del baloncesto universitario, con ocho títulos nacionales y 17 apariciones en el Final Four. Kentucky es conocido por sus fervientes fanáticos y su capacidad para atraer a los mejores reclutas.
        </li>
        <li>
          North Carolina Tar Heels: El tercer programa más exitoso en la historia del baloncesto universitario, con seis títulos nacionales y 20 apariciones en el Final Four. North Carolina ha tenido muchos entrenadores legendarios, como Dean Smith y Roy Williams, y jugadores como Michael Jordan, James Worthy y Vince Carter.
        </li>
        <li>
          Duke Blue Devils: Uno de los programas más dominantes en el baloncesto universitario moderno, con cinco títulos nacionales y 16 apariciones en el Final Four desde 1986. Duke es conocido por su entrenador Mike Krzyzewski, el entrenador más ganador en la historia del baloncesto universitario, y jugadores como Christian Laettner, Grant Hill y Zion Williamson.
        </li>
        <li>
          Kansas Jayhawks: Uno de los programas más tradicionales e influyentes en el baloncesto universitario, con tres títulos nacionales y 15 apariciones en el Final Four. Kansas es el lugar de nacimiento del juego, ya que James Naismith fue su primer entrenador. Kansas también ha tenido muchos grandes entrenadores, como Phog Allen, Larry Brown y Bill Self, y jugadores como Wilt Chamberlain, Danny Manning y Paul Pierce.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estos programas son solo algunos ejemplos de los muchos equipos excelentes que enriquecen el baloncesto universitario. Cada año, nuevos retadores y sorpresas hacen que el juego sea emocionante e impredecible.
    </MKTypography>
  </MKBox>
</MKBox>










                                              






                                  







{/* Capítulo 3: Desde la Universidad hasta la NBA */}
<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 3: Desde la Universidad hasta la NBA
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El baloncesto universitario no es solo un deporte, sino también una educación. Muchos jugadores que participan en el baloncesto universitario tienen como objetivo llegar a la NBA, la mejor y más prestigiosa liga profesional de baloncesto en el mundo. Sin embargo, llegar a la NBA no es fácil y requiere mucho talento, trabajo duro y suerte.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 El Camino hacia la NBA
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Para ser elegible para la NBA, un jugador debe tener al menos 19 años y haberse graduado de la escuela secundaria al menos un año antes. Esto significa que la mayoría de los jugadores que aspiran a llegar a la NBA primero van a la universidad y juegan al menos un año. Esto también se conoce como la regla de uno y hecho, porque muchos jugadores ingresan al draft de la NBA después de solo un año de universidad.
      <br />
      <br />
      El draft de la NBA es un evento anual en el que los 30 equipos de la NBA seleccionan a un jugador de un grupo de jugadores disponibles. El draft consta de dos rondas, con cada equipo teniendo una selección por ronda. El orden de las selecciones se determina a través de una lotería para los equipos que no llegaron a los playoffs la temporada anterior y por clasificación inversa para los equipos de playoffs.
      <br />
      <br />
      El draft es un momento importante para los jugadores porque determina qué equipo tiene sus derechos y cuánto dinero pueden ganar. Los jugadores seleccionados en la primera ronda reciben un contrato garantizado por al menos dos años, mientras que aquellos seleccionados en la segunda ronda no tienen garantías. Los jugadores que no son seleccionados pueden intentar asegurar un contrato a través de la agencia libre o uniéndose a la G League, la liga de desarrollo de la NBA.
      <br />
      <br />
      Sin embargo, el draft no es una garantía de éxito en la NBA. Muchos jugadores seleccionados con alta posición no cumplen con las expectativas o sufren lesiones. Por otro lado, también hay muchos jugadores que son seleccionados con baja posición o no son seleccionados en absoluto, pero aún así sobresalen en la NBA. Un ejemplo conocido es Giannis Antetokounmpo, quien fue seleccionado en la posición 15 en 2013 y fue nombrado MVP y campeón de la NBA en 2021.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Otras Oportunidades Profesionales
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      No todos los jugadores que participan en el baloncesto universitario tienen como objetivo llegar a la NBA. Algunos jugadores eligen completar sus títulos académicos y seguir otras carreras. Otros optan por jugar baloncesto profesional en otros países o ligas.
      <br />
      <br />
      Hay muchas ligas de baloncesto profesionales en todo el mundo, que ofrecen diferentes niveles de calidad, salario y estilo de vida. Algunas de estas ligas incluyen:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          EuroLeague: La competición de clubes europeos más alta y prestigiosa, con 18 equipos de 10 países. EuroLeague es considerada la segunda mejor liga del mundo después de la NBA.
        </li>
        <li>
          Asociación de Baloncesto de China (CBA): La competición de clubes chinos más alta y popular, con 20 equipos de toda China. La CBA es conocida por sus altos salarios y grandes audiencias.
        </li>
        <li>
          Liga Nacional de Baloncesto de Australia (NBL): La competición de clubes australianos más alta y competitiva, con nueve equipos de Australia y Nueva Zelanda. La NBL es conocida por su juego rápido y físico.
        </li>
        <li>
          Liga Africana de Baloncesto (BAL): La competición de clubes africanos más nueva y ambiciosa, con 12 equipos de 12 países. La BAL cuenta con el apoyo de la NBA y la FIBA y tiene como objetivo desarrollar el baloncesto en el continente.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estas ligas son solo algunos ejemplos de las muchas oportunidades disponibles para jugadores de baloncesto profesionales fuera de la NBA. Muchos jugadores disfrutan viviendo y jugando en diferentes culturas mientras continúan persiguiendo su pasión por el juego.
    </MKTypography>
  </MKBox>
</MKBox>















                                              






                                  






{/* Capítulo 4: Influencias y Oportunidades Internacionales */}
<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 4: Influencias y Oportunidades Internacionales
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El baloncesto es un deporte global que reúne a personas de diferentes nacionalidades, culturas y antecedentes. El baloncesto universitario es un ejemplo perfecto de esto, ya que atrae tanto a jugadores extranjeros como estadounidenses, ofreciendo oportunidades. En este capítulo, exploraremos las influencias y oportunidades internacionales en el baloncesto universitario.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Jugadores Extranjeros en el Baloncesto Universitario
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      El baloncesto universitario siempre ha recibido con agrado a jugadores extranjeros que han contribuido con su propio estilo, habilidades y perspectivas al juego. Según la NCAA, en la temporada 2019-2020, había más de 1,200 jugadores extranjeros en la División I, procedentes de 101 países. Los países de origen más comunes fueron Canadá, Australia, Nigeria, Francia y el Reino Unido.
      <br />
      <br />
      Los jugadores extranjeros eligen el baloncesto universitario por varias razones. Algunos quieren combinar su educación académica con sus ambiciones atléticas. Otros tienen como objetivo adaptarse a la cultura y estilo de vida estadounidenses. Otros aún buscan mejorar sus posibilidades de llegar a la NBA o representar a sus selecciones nacionales.
      <br />
      <br />
      Los jugadores extranjeros han tenido un impacto significativo en el baloncesto universitario, tanto individual como colectivamente. Algunos de los jugadores extranjeros más exitosos e influyentes en la historia del baloncesto universitario incluyen:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Hakeem Olajuwon: Un jugador nigeriano que jugó para los Houston Cougars de 1981 a 1984. Llevó a su equipo a tres apariciones consecutivas en el Final Four y ganó el premio al jugador nacional del año en 1984. Fue la primera selección general en el draft de la NBA de 1984 y se convirtió en uno de los mejores centros de todos los tiempos.
        </li>
        <li>
          Andrew Bogut: Un jugador australiano que jugó para los Utah Utes de 2003 a 2005. Ganó el premio al jugador nacional del año en 2005 y llevó a su equipo al Sweet Sixteen. Fue la primera selección general en el draft de la NBA de 2005 y se convirtió en un All-Star y campeón.
        </li>
        <li>
          Luka Garza: Un jugador estadounidense con raíces bosnias que jugó para los Iowa Hawkeyes de 2017 a 2021. Ganó el premio al jugador nacional del año dos veces, en 2020 y 2021, y se convirtió en el máximo anotador de todos los tiempos de su universidad. Fue seleccionado en la posición 52 en el draft de la NBA de 2021 y es considerado uno de los mejores jugadores universitarios de todos los tiempos.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estos jugadores son solo algunos ejemplos de los muchos jugadores extranjeros que han sobresalido en el baloncesto universitario. Cada año, surgen nuevos talentos que enriquecen el juego.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Jugadores Estadounidenses en el Extranjero
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      No solo los jugadores extranjeros vienen a América para estudiar y jugar baloncesto, sino que también los jugadores estadounidenses van al extranjero por las mismas razones. Algunos jugadores estadounidenses eligen saltarse o acortar sus carreras de secundaria o universidad para jugar profesionalmente en otros países o ligas.
      <br />
      <br />
      Las razones para esta elección pueden variar, pero algunas incluyen:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Financieras: Algunos jugadores quieren ganar dinero con su talento en lugar de esperar un contrato de la NBA o una beca.
        </li>
        <li>
          Atléticas: Algunos jugadores quieren desarrollarse contra oponentes más fuertes en lugar de dominar en su nivel.
        </li>
        <li>
          Personales: Algunos jugadores quieren viajar, aprender nuevas culturas o mantener lazos familiares.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Los jugadores estadounidenses que van al extranjero tienen diferentes experiencias y resultados. Algunos logran realizar su sueño de la NBA, mientras que otros disfrutan de una carrera larga y exitosa. Algunos se adaptan bien a su nuevo entorno, mientras que otros luchan con el idioma, la comida o el estilo de vida.
      <br />
      <br />
      Algunos ejemplos de jugadores estadounidenses que han ido al extranjero incluyen:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Brandon Jennings: Un jugador que se saltó su escuela secundaria para jugar para Lottomatica Roma en Italia en 2008. Fue la décima selección general en el draft de la NBA de 2009 y jugó durante 10 temporadas en la NBA.
        </li>
        <li>
          Emmanuel Mudiay: Un jugador que se saltó su universidad para jugar para los Guangdong Southern Tigers en China en 2014. Fue la séptima selección general en el draft de la NBA de 2015 y actualmente juega para el Utah Jazz.
        </li>
        <li>
          LaMelo Ball: Un jugador que se saltó su escuela secundaria y universidad para jugar para los Illawarra Hawks en Australia en 2019. Fue la tercera selección general en el draft de la NBA de 2020 y ganó el premio al Novato del Año.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estos jugadores son solo algunos ejemplos de los muchos jugadores estadounidenses que han elegido un camino alternativo hacia el baloncesto profesional. Cada año, nuevos aventureros globalizan el juego.
    </MKTypography>
  </MKBox>
</MKBox>












                                              






                                  



<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Becas Deportivas y el Futuro de los Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Las becas deportivas ofrecen numerosas oportunidades para que los jóvenes atletas muestren su talento y desarrollen sus habilidades. Una de las oportunidades más significativas es obtener una beca deportiva para estudiar y competir a nivel universitario. En este capítulo, discutiremos la importancia de las becas deportivas y cómo Sport Scholarships America puede ayudarte a hacer realidad tu sueño.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 La Importancia de las Becas
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Una beca deportiva es un apoyo financiero proporcionado por una universidad a un estudiante-atleta para combinar estudios y deporte. Puede cubrir los costos de matrícula, libros, alojamiento, comidas y otros gastos. Además, puede ofrecer beneficios como tutoría académica, atención médica, instalaciones de entrenamiento y equipo.
      <br />
      <br />
      Obtener una beca deportiva es competitivo, con muchos factores que influyen en el proceso de adjudicación, incluyendo logros académicos, rendimiento deportivo, personalidad, motivación, liderazgo y adaptabilidad. Por lo tanto, es importante prepararse bien y destacarse entre la competencia.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Cómo Puede Ayudar Sport Scholarships America
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo de Sport Scholarships America, que muestra equipamiento deportivo y una gorra de graduación, representando el enfoque en becas deportivas para estudiantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America es una organización especializada en guiar a jóvenes talentos deportivos hacia becas deportivas. Con expertos apasionados y experimentados, Sport Scholarships America apoya a los estudiantes-atletas en cada paso del camino, desde la orientación inicial hasta la colocación final. Sus servicios incluyen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Una entrevista personal para discutir los deseos, posibilidades y expectativas del estudiante-atleta.</li>
        <li>Una evaluación profesional del nivel académico y deportivo del estudiante-atleta.</li>
        <li>Una extensa red de contactos con entrenadores, universidades y colegios.</li>
        <li>Promoción efectiva del perfil y videos del estudiante-atleta ante entrenadores potenciales.</li>
        <li>Asesoramiento experto sobre las mejores opciones basadas en las preferencias, metas y presupuesto del estudiante-atleta.</li>
        <li>Negociación de las mejores ofertas de becas en nombre del estudiante-atleta.</li>
        <li>Asistencia en la solicitud de documentos necesarios, como visa, expediente académico, diploma y pruebas de admisión.</li>
        <li>Preparación para la vida del estudiante-atleta durante su beca, incluyendo cultura, idioma, reglas y consejos.</li>
        <li>Seguimiento y atención posterior durante el período de la beca.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      ¿Quieres explorar oportunidades de becas y alcanzar tus metas deportivas y académicas? <Link to="/es/Servicios-Productos">Consulta la gama completa</Link> de servicios ofrecidos por Sport Scholarships America y programa una consulta hoy mismo.
    </MKTypography>
  </MKBox>
</MKBox>












        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;