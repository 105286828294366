/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";


// Material Kit 2 PRO React examples
import RaisedBlogCard from "examples/Cards/BlogCards/RaisedBlogCard";


// Images
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import NCAA from "assets/images/Logos/NCAA_Transparent.png"
import NJCAA from "assets/images/Logos/NJCAA_Transparent.png"
import NAIA from "assets/images/Logos/NAIA_Transparent.png"

function BlogPostThree() {
  const post1 = (NCAA)
  const post2 = (NJCAA)
  const post3 = (NAIA)

  return (
    <>
      <MKBox position="relative" variant="gradient" bgColor="dark" mt={{ xs: 0, lg: 8 }} mx={-2}>
        <MKBox
          component="img"
          src={bgPattern}
          alt="baggrundsmønster"
          position="absolute"
          top={0}
          left={0}
          width={{ xs: "100%", lg: "100%" }}
          height={{ xs: "auto", lg: "auto" }}
          opacity={0.6}
        />
        <Container>
          <Grid container justifyContent="center" sx={{ pt: 6, pb: 18 }}>
            <Grid item xs={12} md={7} sx={{ textAlign: "center" }}>
              <MKTypography variant="h2" color="white" mb={1}>
                Muligheder på alle niveauer
              </MKTypography>
              <MKTypography variant="body2" color="white">
                Hver sport, hvert akademisk niveau: udforsk en verden af muligheder på utallige amerikanske universiteter for hver studerende-udøver
              </MKTypography>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
  
      <MKBox component="section" mt={-10}>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4} mb={{ xs: 3, lg: 0 }}>
              <RaisedBlogCard
                image={post1}
                category={{ color: "primary", label: "hus" }}
                title="NCAA"
                subtitle="National Collegiate Athletic Association"
                description=
                  "NCAA administrerer collegesport i USA og overvåger konkurrencer for mere end 1.100 tilknyttede universiteter. Som en ledende organisation spiller den en afgørende rolle i det amerikanske collegiale sportslandskab."
                action={{
                  type: "internal",
                  route: "/da/NCAA",
                  color: "info",
                  label: "Mere information",
                }}
                imageStyle={{ marginTop: "-44px" }}
                shadowTop="-30px" // Flyt skyggen ned med 50px for at matche billedets bevægelse
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} mb={{ xs: 3, lg: 0 }}>
              <RaisedBlogCard
                image={post2}
                category={{ color: "primary", label: "hus" }}
                title="NJCAA"
                subtitle="National Junior College Association"
                description="NJCAA regulerer sport på community colleges i USA og administrerer konkurrencer for hundredvis af tilknyttede institutioner. Som en central organisation er den afgørende for to-årig collegesport i Amerika, hvor talent udvikles til højere niveauer."
                action={{
                  type: "internal",
                  route: "/da/NJCAA",
                  color: "info",
                  label: "Mere information",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} mb={{ xs: 3, lg: 0 }}>
              <RaisedBlogCard
                image={post3}
                category={{ color: "primary", label: "hus" }}
                title="NAIA"
                subtitle="National Association of Intercollegiate Athletics"
                description=
                  "NAIA spiller en central rolle i administrationen af sport på små universiteter i USA med hundredvis af tilknyttede institutioner. Som en ledende organisation spiller den en vital rolle i det amerikanske intercollegiale sportslandskab og fremmer talent og integritet."
                action={{
                  type: "internal",
                  route: "/da/NAIA",
                  color: "info",
                  label: "Mere information",
                }}
                imageStyle={{ marginTop: "50px" }}
                shadowTop="none"
                paddingBelowImage="120px"
              />
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
  
}

export default BlogPostThree;
