/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Helmet } from 'react-helmet';


import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbarPortugues";
import DetailedFooter from "examples/Footers/DetailedFooter";
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";

// HelpCenter page components
import FaqCollapse from "Languages/Portugues/pages/Contact/FAQs/components/FaqCollapse";

// Routes
import routes from "routes_Portugues";import footerRoutes from "footer.routes_Portugues";
import "../../H1asH3.css"



function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <>
  <Helmet>
    <title>Perguntas Frequentes - Tudo Sobre Bolsas de Estudo nos EUA</title>
    <meta name="description" content="Encontre respostas para perguntas frequentes sobre bolsas de estudo nos Estados Unidos, o processo, requisitos e como a Bolsas de Estudo Esportivas América pode ajudar você." />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta charset="UTF-8" />
    <meta name="robots" content="index, follow" />
    
    {/* Open Graph Tags */}
    
    
    
    
     

    {/* Favicon */}
    <link rel="icon" href="https://sportscholarshipsamerica.com/32x32Sportbeurs.png" />
</Helmet>


      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://calendly.com/sportbeurs-amerika/intake",
          label: "MARCAR CONSULTA",
          color: "info",
        }}
      
         
      />
     <MKBox component="section" pt={20} pb={12}>
        <Container sx={{ mb: 8 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <SimpleInfoCard
                icon="sports_soccer"
                title="Esporte"
                description="Descubra os passos essenciais e critérios para atletas estudantis garantirem uma bolsa de esportes nos EUA."
                direction="center"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <SimpleInfoCard
                icon="school"
                title="Estudo"
                description="Saiba tudo sobre os requisitos acadêmicos e preparações necessárias para combinar esportes de elite com estudos na América."
                direction="center"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <SimpleInfoCard
                icon="connect_without_contact_icon"
                title="Social"
                description="Obtenha insights sobre o aspecto social da vida como um atleta estudantil nos EUA, incluindo networking e adaptação a uma nova cultura."
                direction="center"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <SimpleInfoCard
                icon="info_outlined"
                title="Geral"
                description="Encontre respostas para perguntas comuns sobre o processo, preparação e expectativas de obter uma bolsa de esportes na América."
                direction="center"
              />
            </Grid>

          </Grid>
        </Container>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography className="h1-as-h3" variant="h1" color="white">
                    Frequently Asked Questions
                  </MKTypography>
                 
                </MKBox>
                <MKBox p={6}>
                <FaqCollapse
  title="Por que a consulta inicial agora custa €14,99?"
  open={collapse === 25}
  onClick={() => (collapse === 25 ? setCollapse(false) : setCollapse(25))}
>
  Anteriormente, ofereci a consulta inicial gratuitamente. Foi um prazer fornecer este serviço sem custo, pois me ajuda a entender melhor suas necessidades e adaptar meus serviços de acordo. No entanto, essa abordagem às vezes resultava em pedidos de pessoas que não estavam considerando seriamente o uso dos meus serviços. Isso resultou em muito tempo gasto em conversas que não levaram a uma cooperação mutuamente benéfica.

  Para garantir a qualidade e eficiência do meu serviço, decidi cobrar uma pequena taxa de €14,99 pela consulta inicial. Isso ajuda a garantir que aqueles que solicitam uma conversa estejam considerando seriamente o uso dos meus serviços. Quero garantir que esta conversa ainda seja não vinculativa e focada inteiramente em entender sua situação e discutir como posso ajudá-lo melhor.

  Agradeço sua compreensão e aguardo uma conversa produtiva e esclarecedora com você.
</FaqCollapse>

<MKTypography variant="h3" my={3}>
Esporte
</MKTypography>

<FaqCollapse
  title="Quais são os requisitos para se qualificar para uma bolsa de estudos esportiva?"
  open={collapse === 1}
  onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
>
  Para se qualificar para uma bolsa de estudos esportiva na América, você precisa se destacar tanto academicamente quanto em seu esporte. As escolas examinam seu diploma do ensino médio, suas notas e frequentemente são necessários resultados de testes como o SAT ou ACT. Para quem não fala inglês, geralmente é exigida uma pontuação no TOEFL ou IELTS. Em termos esportivos, você precisa demonstrar conquistas significativas em seu esporte em um nível competitivo dentro do cenário universitário americano. Isso muitas vezes inclui participar de competições nacionais ou de clubes respeitáveis, com evidências como vídeos de jogos, estatísticas e recomendações de treinadores. Qualidades pessoais como liderança, trabalho em equipe e perseverança também são consideradas. Ganhar uma bolsa de estudos é uma batalha competitiva onde você se destaca entre muitos aspirantes a estudantes-atletas, então é importante se destacar e se inscrever cedo. Muitos alunos optam por apoio de agências de recrutamento especializadas.
</FaqCollapse>

<FaqCollapse
  title="Como posso ser recrutado por treinadores americanos?"
  open={collapse === 2}
  onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
>
  Para ser recrutado por treinadores americanos, é crucial demonstrar suas habilidades esportivas e manter qualificações acadêmicas. Começa com a compilação de vídeos de jogo, estatísticas e recomendações que destacam sua habilidade e potencial. No entanto, no mundo competitivo dos esportes universitários, uma forte rede de contatos desempenha um papel fundamental. É aí que 'Sport Scholarships America' entra em jogo. Com minha extensa rede de contatos dentro das universidades americanas, posso abrir portas que de outra forma permaneceriam fechadas. Apresento você diretamente aos treinadores, garanto que você se destaque e ajudo a navegar no complexo mundo do recrutamento. Minhas conexões e experiência aumentam significativamente a chance de que seu talento seja notado e que você obtenha a bolsa de estudos esportiva que está buscando. Com 'Sport Scholarships America', você está mais forte no processo de recrutamento.
</FaqCollapse>

<FaqCollapse
  title="Quais compromissos eu assumo com uma bolsa de estudos esportiva?"
  open={collapse === 3}
  onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
>
  Com uma bolsa de estudos esportiva, você assume diversos compromissos. Em primeiro lugar, espera-se que você mantenha o sucesso acadêmico e cumpra os padrões da universidade. Você também deve aderir aos horários de treinamento e competição de sua equipe esportiva, o que requer um compromisso de tempo significativo. Além disso, você pode ser obrigado a participar de reuniões de equipe, eventos da mídia e serviços comunitários organizados pela sua escola. A universidade apoia você com uma bolsa de estudos com a expectativa de que você se saia bem tanto no campo quanto na sala de aula e seja um representante positivo do programa esportivo. Violação das regras da equipe, desempenho acadêmico insatisfatório ou problemas de comportamento podem levar a sanções ou até mesmo à retirada da bolsa de estudos. Portanto, é importante equilibrar e levar a sério suas responsabilidades para manter sua bolsa de estudos.
</FaqCollapse>

<FaqCollapse
  title="Posso praticar meu esporte e ao mesmo tempo ter um bom desempenho nos estudos?"
  open={collapse === 4}
  onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
>
  Sim, é possível se destacar tanto em seu esporte quanto em seus estudos. As universidades americanas frequentemente fornecem amplo apoio aos estudantes-atletas, como tutoria, planejamento de estudos e consultores acadêmicos, especificamente projetados para ajudá-lo a equilibrar os compromissos esportivos e os requisitos acadêmicos. Gerenciamento de tempo e organização são cruciais. Você aprenderá a priorizar e gerenciar seu tempo de forma eficiente para ter sucesso em ambas as áreas. Isso requer disciplina e comprometimento, mas com a mentalidade certa e sistemas de apoio adequados, é certamente possível. Muitos estudantes-atletas antes de você provaram que é possível obter alto desempenho acadêmico enquanto também se destacam em seu esporte.
</FaqCollapse>

<FaqCollapse
  title="O que acontece com minha bolsa de estudos se eu me machucar?"
  open={collapse === 5}
  onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
>
  Sofrer uma lesão não significa automaticamente que você perde sua bolsa de estudos esportiva. A maioria das universidades americanas possui políticas que mantêm as bolsas de estudos para estudantes-atletas durante a recuperação de uma lesão, desde que continuem a atender aos requisitos acadêmicos e permaneçam envolvidos com a equipe em um papel modificado. Você receberá apoio da equipe médica esportiva da universidade durante o processo de recuperação. No entanto, as condições específicas podem variar de escola para escola, e a gravidade da lesão pode desempenhar um papel, então é importante entender o contrato de bolsa de estudos e discutir quais são as expectativas em caso de lesão.
</FaqCollapse>

<FaqCollapse
  title="Quão importante são os esportes nas universidades americanas?"
  open={collapse === 6}
  onClick={() => (collapse === 6 ? setCollapse(false) : setCollapse(6))}
>
  Os esportes desempenham um papel central nas universidades americanas; eles contribuem para o espírito escolar e para a comunidade. Os programas esportivos em muitos campi recebem investimentos e atenção significativos, com instalações e treinadores de ponta. Os eventos esportivos atraem grandes multidões e atenção da mídia. Para os estudantes-atletas, esses programas oferecem não apenas a chance de competir em um nível alto, mas também de desenvolver habilidades de liderança e trabalho em equipe, que são valiosas para o crescimento pessoal e futuras carreiras.
</FaqCollapse>

<FaqCollapse
  title="Como é um dia típico para um estudante-atleta nos EUA?"
  open={collapse === 7}
  onClick={() => (collapse === 7 ? setCollapse(false) : setCollapse(7))}
>
  Um dia típico para um estudante-atleta nos EUA é cuidadosamente organizado e requer disciplina. O dia muitas vezes começa cedo com treinamento ou condicionamento físico, seguido de aulas ao longo do dia. Os estudantes-atletas podem usar o tempo entre as aulas para tutoria ou lição de casa. As aulas da tarde geralmente são seguidas por treinamento em equipe, reuniões táticas ou competições. As noites são reservadas para tempo adicional de estudo, recuperação e preparação para o próximo dia. A vida de um estudante-atleta é um equilíbrio cuidadoso entre esportes, estudos e tempo pessoal.
</FaqCollapse>

<MKTypography variant="h3" mt={6} mb={3}>
Estudo
</MKTypography>

<FaqCollapse
  title="Quais campos de estudo posso seguir com uma bolsa de estudos esportiva?"
  open={collapse === 8}
  onClick={() => (collapse === 8 ? setCollapse(false) : setCollapse(8))}
>
  Com uma bolsa de estudos esportiva nos EUA, você pode seguir quase qualquer campo de estudo oferecido pela universidade, desde que atenda aos requisitos de admissão do programa específico. Isso varia de artes e humanidades a engenharia e administração de empresas. É importante escolher um campo de estudo que esteja alinhado com seus interesses e aspirações de carreira e onde você possa equilibrar suas obrigações acadêmicas com seu esporte. As universidades frequentemente incentivam os estudantes-atletas a escolher um caminho acadêmico que os desafie academicamente ao lado de sua carreira esportiva, preparando-os para o futuro.
</FaqCollapse>



                  
       
<FaqCollapse
  title="Como as universidades americanas diferem das universidades europeias?"
  open={collapse === 9}
  onClick={() => (collapse === 9 ? setCollapse(false) : setCollapse(9))}
>
  As universidades americanas diferem das europeias de várias maneiras. Uma diferença notável é a cultura do campus; as universidades americanas frequentemente oferecem uma experiência abrangente com instalações extensas como complexos esportivos, refeitórios e residências estudantis diretamente no campus. O sistema educacional é mais flexível, permitindo que os alunos escolham disciplinas fora de sua área principal e criem seus próprios horários. O envolvimento em esportes é muito maior nos EUA, com equipes esportivas universitárias competindo em nível nacional e oferecendo bolsas de estudo esportivas para atletas talentosos. Além disso, a interação com os professores é mais informal e acessível, e há uma forte ênfase na avaliação contínua e na participação em aulas, em vez de apenas exames. Financeiramente, o custo de estudar em uma universidade americana geralmente é mais alto do que na Europa, mas isso é frequentemente compensado por uma ampla gama de bolsas de estudo e programas de ajuda financeira disponíveis para estudantes americanos e internacionais.
</FaqCollapse>

<FaqCollapse
  title="Quão rigoroso é o programa acadêmico para estudantes-atletas?"
  open={collapse === 10}
  onClick={() => (collapse === 10 ? setCollapse(false) : setCollapse(10))}
>
  O programa acadêmico para estudantes-atletas nos EUA pode ser desafiador devido à combinação de compromissos esportivos e exigências de estudo. Os estudantes-atletas devem seguir um currículo completo e atender aos mesmos padrões acadêmicos que os não atletas, levando a uma agenda ocupada. Os horários de treinamento e competição demandam muito tempo, tornando o gerenciamento eficaz do tempo essencial para ter sucesso tanto no campo quanto na sala de aula. Apesar desses desafios, muitas universidades oferecem serviços de apoio como tutoria e aconselhamento acadêmico, especificamente projetados para ajudar os atletas a equilibrar seus compromissos esportivos e de estudo.
</FaqCollapse>

<FaqCollapse
  title="Quais são os requisitos de idioma para estudantes não falantes de inglês?"
  open={collapse === 11}
  onClick={() => (collapse === 11 ? setCollapse(false) : setCollapse(11))}
>
  Estudantes não falantes de inglês que desejam estudar em uma universidade americana geralmente precisam demonstrar proficiência em inglês. Isso geralmente é medido por meio de testes padronizados de idiomas como TOEFL (Teste de Inglês como Língua Estrangeira) ou IELTS (Sistema Internacional de Teste de Língua Inglesa). Cada universidade define suas próprias pontuações mínimas que os alunos devem alcançar. Algumas instituições também podem aceitar testes alternativos ou oferecer suas próprias avaliações de proficiência em idiomas. Além disso, os estudantes-atletas que recebem uma bolsa de estudos esportiva podem receber suporte adicional de idiomas por meio de sua universidade para ajudá-los a ter sucesso tanto em seus empreendimentos acadêmicos quanto atléticos. É importante para os estudantes verificar os requisitos específicos de idiomas de sua universidade e programa esportivo escolhidos para garantir que atendam a todas as condições.
</FaqCollapse>

<FaqCollapse
  title="Quais são os requisitos gerais de admissão para universidades americanas?"
  open={collapse === 12}
  onClick={() => (collapse === 12 ? setCollapse(false) : setCollapse(12))}
>
  Os requisitos gerais de admissão para universidades americanas incluem:
  <ul style={{ paddingLeft: '30px' }}> {/* Indentation applied here */}
    <li>Histórico Acadêmico: Comprovação de sucesso no ensino médio, geralmente por meio de um diploma de ensino médio ou equivalente, com histórico escolar mostrando disciplinas e notas.</li>
    <li>Testes Padronizados: As pontuações do SAT ou ACT geralmente são necessárias para estudantes domésticos e às vezes para candidatos internacionais.</li>
    <li>Proficiência em Idiomas: Estudantes não falantes de inglês devem enviar pontuações no TOEFL ou IELTS para demonstrar sua proficiência em inglês.</li>
    <li>Cartas de Recomendação: Cartas de professores ou orientadores escolares destacando suas qualidades acadêmicas e pessoais.</li>
    <li>Declaração Pessoal ou Ensaios: Peças escritas onde os alunos explicam sua motivação, objetivos e adequação para a universidade.</li>
    <li>Atividades Extracurriculares: Evidências de envolvimento fora da sala de aula, como clubes, esportes, trabalho voluntário e outros interesses.</li>
    <li>Para estudantes internacionais, os requisitos adicionais podem incluir documentação financeira para provar que podem custear seus estudos e despesas de vida, e às vezes uma entrevista. Programas e universidades específicos também podem ter critérios adicionais, então é importante verificar as informações de admissão de cada instituição.</li>
  </ul>
</FaqCollapse>

<FaqCollapse
  title="Recebo orientação acadêmica como atleta?"
  open={collapse === 13}
  onClick={() => (collapse === 13 ? setCollapse(false) : setCollapse(13))}
>
  Sim, nas universidades americanas, os estudantes-atletas frequentemente recebem orientação acadêmica para ajudá-los a ter sucesso. Esse apoio pode incluir salas de estudo, onde determinadas horas são reservadas para estudar, acesso a tutoria para disciplinas desafiadoras e conselheiros acadêmicos especificamente designados para atletas para ajudar a equilibrar sua carga horária e compromissos esportivos. Esses serviços são projetados para ajudar os atletas a alcançar seus objetivos acadêmicos e permanecer elegíveis para seu esporte.
</FaqCollapse>

<FaqCollapse
  title="Quais são as consequências se eu não tiver um desempenho acadêmico?"
  open={collapse === 14}
  onClick={() => (collapse === 14 ? setCollapse(false) : setCollapse(14))}
>
  Se você não tiver um desempenho acadêmico, pode haver várias consequências, dependendo das regras da universidade e do departamento atlético. Geralmente, estudantes-atletas que não conseguem atender aos padrões acadêmicos podem ser colocados em probation acadêmico. Isso pode levar à perda de tempo de jogo, restrições à participação em eventos esportivos ou, em casos graves, retirada da bolsa de estudos esportiva. Além disso, os estudantes-atletas precisam manter um certo GPA para serem elegíveis para competição de acordo com as regras da NCAA ou da NAIA. Portanto, é crucial utilizar os recursos acadêmicos e orientação oferecidos pela universidade para evitar problemas acadêmicos.
</FaqCollapse>

<FaqCollapse
  title="Quantas universidades posso me candidatar?"
  open={collapse === 13}
  onClick={() => (collapse === 13 ? setCollapse(false) : setCollapse(13))}
>
  Para estudantes-atletas, o processo de candidatura a universidades muitas vezes está vinculado ao processo de recrutamento precedente. Eles geralmente começam a se candidatar depois que já houve contato com treinadores e interesse mostrado em suas habilidades atléticas. Isso significa que o número de universidades a que um estudante-atleta se candidata pode variar dependendo do interesse recebido de programas esportivos. É comum que um atleta se concentre em um número menor de escolas - geralmente entre 3 e 5 - onde ele sabe que há interesse mútuo e onde já teve contato prévio com a equipe técnica sobre oportunidades de bolsas de estudo. Isso torna o processo de inscrição mais direcionado e muitas vezes mais eficaz, pois as chances de aceitação e obtenção de uma bolsa de estudos esportiva são maiores nessas instituições.
</FaqCollapse>

<MKTypography variant="h3" mt={6} mb={3}>
Social
</MKTypography>

<FaqCollapse
  title="Como é a vida social para estudantes-atletas na América?"
  open={collapse === 15}
  onClick={() => (collapse === 15 ? setCollapse(false) : setCollapse(15))}
>
  A vida social para estudantes-atletas na América pode ser muito dinâmica e gratificante, mas também ocupada. Como atleta, você é frequentemente uma figura conhecida no campus, o que pode levar a uma vida social ativa. Muitos estudantes-atletas encontram uma comunidade dentro de sua equipe e do departamento atlético mais amplo, proporcionando um forte apoio social. No entanto, equilibrar o envolvimento em esportes e acadêmicos pode ser desafiador devido ao tempo disponível para outras atividades sociais. Treinamento, competições, tempo de viagem para competições e a necessidade de cumprir obrigações acadêmicas ocupam uma parte significativa do tempo. No entanto, muitos atletas reservam tempo para eventos sociais, organizações estudantis e atividades no campus, contribuindo para uma experiência universitária rica. A cultura universitária nos EUA também incentiva o envolvimento em trabalho voluntário, desenvolvimento de liderança e outras atividades extracurriculares, todas as quais contribuem para a vida social. É importante para os estudantes-atletas encontrar um bom equilíbrio entre esportes, estudos e atividades sociais para ter uma experiência universitária completa e equilibrada.
</FaqCollapse>

<FaqCollapse
  title="Posso participar de organizações estudantis ou outras atividades fora dos esportes?"
  open={collapse === 16}
  onClick={() => (collapse === 16 ? setCollapse(false) : setCollapse(16))}
>
  Certamente, como estudante-atleta, você tem a oportunidade de participar de organizações estudantis e atividades extracurriculares, mas deve reconhecer que seus compromissos esportivos muitas vezes têm prioridade. Treinos, competições e viagens para competições podem consumir grande parte do seu tempo. Portanto, você precisará se destacar no gerenciamento do tempo para combinar seu esporte, estudos e outras atividades. Embora os esportes tenham prioridade, muitas universidades incentivam os estudantes-atletas a buscar uma experiência universitária equilibrada e muitas vezes oferecem oportunidades flexíveis para que os atletas participem da vida no campus de forma mais ampla. Isso pode levar a uma vida universitária muito gratificante, embora ocupada, onde o planejamento e a organização sejam essenciais.
</FaqCollapse>





<FaqCollapse
  title="Como lidar com a saudade de casa ou o choque cultural?"
  open={collapse === 17}
  onClick={() => (collapse === 17 ? setCollapse(false) : setCollapse(17))}
>
  A saudade de casa e o choque cultural são reações naturais ao viver longe de casa, mas saiba que você não está sozinho. Como atleta, você faz parte de uma equipe, de uma comunidade de indivíduos que compartilham sua paixão pelo esporte e frequentemente passam por experiências semelhantes. Esse sentimento de camaradagem pode ser um poderoso amortecedor contra a saudade de casa. Mantenha-se ativamente envolvido com sua equipe e utilize a estrutura e a rotina que a vida em equipe oferece. Compartilhe seus sentimentos com os colegas de equipe; é provável que eles possam se identificar e oferecer apoio. Lembre-se de que essas experiências contribuem para o crescimento pessoal. Com o tempo e a paciência, você se ajustará, e seu novo ambiente poderá começar a se sentir como um segundo lar. As universidades também oferecem aconselhamento e apoio para estudantes internacionais, portanto, não hesite em procurar ajuda se precisar. Você está cercado por pessoas que querem vê-lo ter sucesso, tanto dentro quanto fora do campo.
</FaqCollapse>

<FaqCollapse
  title="Existem oportunidades de networking com ex-alunos ou profissionais?"
  open={collapse === 18}
  onClick={() => (collapse === 18 ? setCollapse(false) : setCollapse(18))}
>
  Sim, muitas universidades americanas oferecem excelentes oportunidades de networking com ex-alunos e profissionais, especialmente para estudantes-atletas. As equipes esportivas frequentemente têm uma rede leal de ex-jogadores que construíram carreiras bem-sucedidas. As universidades organizam regularmente eventos de networking, feiras de carreira e programas de mentoria onde você pode se conectar com graduados e profissionais de diversos campos. Esses contatos podem ser inestimáveis para estágios, oportunidades de emprego e conselhos profissionais. Como atleta, você também tem a chance de participar de eventos de arrecadação de fundos e encontros de ex-alunos, fornecendo oportunidades adicionais para construir relacionamentos. É importante aproveitar essas oportunidades e construir sua rede durante seu tempo na universidade.
</FaqCollapse>

<FaqCollapse
  title="Quais são as opções de moradia para estudantes-atletas?"
  open={collapse === 19}
  onClick={() => (collapse === 19 ? setCollapse(false) : setCollapse(19))}
>
  Os estudantes-atletas nas universidades americanas frequentemente têm várias opções de moradia:
  <ul style={{ paddingLeft: '30px' }}> {/* Indentation applied here */}
    <li>
      Complexos de Moradia para Atletas: Algumas universidades oferecem instalações de moradia especiais para atletas com comodidades adaptadas às necessidades dos esportistas, como academias e salas de estudo.
    </li>
    <li>
      Dormitórios no Campus: Dormitórios tradicionais ou alojamentos no campus são uma escolha comum, com alunos do primeiro ano frequentemente morando nesses espaços de convivência.
    </li>
    <li>
      Apartamentos Universitários: Para estudantes-atletas mais velhos ou transferidos, às vezes existem apartamentos disponíveis que oferecem mais privacidade e independência do que os dormitórios.
    </li>
    <li>
      Moradia Privada: Alguns atletas optam por moradia fora do campus, como apartamentos ou casas, que alugam na comunidade próxima.
    </li>
  </ul>
  A escolha depende da preferência pessoal, orçamento, disponibilidade e política da universidade. As universidades geralmente têm um departamento de moradia que pode ajudar a encontrar a opção certa para você como estudante-atleta.
</FaqCollapse>

<MKTypography variant="h3" mt={6} mb={3}>
Geral
</MKTypography>

<FaqCollapse
  title="Quando devo me candidatar a uma bolsa de estudos esportiva?"
  open={collapse === 20}
  onClick={() => (collapse === 20 ? setCollapse(false) : setCollapse(20))}
>
  Como atleta internacional, o processo de candidatura a uma bolsa de estudos esportiva nos Estados Unidos é semelhante ao dos atletas domésticos, mas há etapas adicionais a serem consideradas. Sport Scholarships America NL orienta um estudante por todos esses passos:
  <ul style={{ paddingLeft: '30px' }}> {/* Indentation applied here */}
    <li>
      Preparação Antecipada: Comece cedo, idealmente no penúltimo ano do ensino médio, para reunir informações sobre universidades e seus programas de bolsas de estudo esportivas.
    </li>
    <li>
      Contato com Treinadores: Com a ajuda do Sport Scholarships America, estabeleça contato com treinadores em seu penúltimo ano. Isso inclui enviar e-mails, compartilhar desempenhos esportivos e vídeos, e expressar interesse no programa.
    </li>
    <li>
      Proficiência em Idiomas: Certifique-se de ter feito os testes de idiomas exigidos, como TOEFL ou IELTS, bem antes de se candidatar. Materiais de estudo e informações adicionais sobre esses testes podem ser solicitados.
    </li>
    <li>
      Documentação Acadêmica: O Sport Scholarships America ajuda na tradução e certificação oportuna de seus documentos acadêmicos, já que as universidades os exigem em inglês.
    </li>
    <li>
      Candidaturas: Inicie o processo formal de candidatura nas universidades em seu último ano do ensino médio, considerando os prazos das universidades e as temporadas esportivas.
    </li>
    <li>
      Bolsas de Estudo e Finanças: Pesquise oportunidades de bolsas de estudo e organize a documentação financeira necessária, pois isso geralmente é exigido para estudantes internacionais.
    </li>
    <li>
      Centros de Elegibilidade: Registre-se nos centros de elegibilidade relevantes, como o Centro de Elegibilidade da NCAA ou o Centro de Elegibilidade da NAIA, que avaliam seu status de amador e histórico acadêmico.
    </li>
    <li>
      Visitas Oficiais: Se possível, planeje visitas aos campi que o convidaram ou nos quais você tem um interesse sério.
    </li>
  </ul>
  É essencial estar ciente e cumprir os prazos e requisitos específicos, pois estes podem variar de universidade para universidade e programa de bolsas de estudo esportivas. Além disso, o processo pode levar mais tempo devido ao tempo necessário para obtenção de vistos e processamento de documentação internacional.
</FaqCollapse>
<FaqCollapse
  title="Quais são os custos associados a uma bolsa de estudos esportiva que não são cobertos?"
  open={collapse === 21}
  onClick={() => (collapse === 21 ? setCollapse(false) : setCollapse(21))}
>
  Embora uma bolsa de estudos esportiva possa cobrir muitos dos custos de uma visita à universidade, existem certas despesas que podem não ser cobertas e que você precisará financiar:
  <ul style={{ paddingLeft: '30px' }}> {/* Indentation applied here */}
    <li>
      Despesas Pessoais: Dinheiro para atividades como jantar fora, viagens pessoais ou entretenimento.
    </li>
    <li>
      Livros e Materiais Escolares: Às vezes, os custos de livros e outros materiais de estudo não são totalmente cobertos.
    </li>
    <li>
      Despesas de Viagem: Viajar para casa durante as férias e intervalos geralmente não está incluído.
    </li>
    <li>
      Moradia e Alimentação: Algumas bolsas de estudos cobrem apenas uma parte da moradia e dos planos de refeições.
    </li>
    <li>
      Custos de Materiais Específicos do Curso: Equipamentos de laboratório especial ou materiais de arte podem incorrer em custos adicionais.
    </li>
    <li>
      Seguro de Saúde: Estudantes internacionais geralmente precisam comprar um seguro de saúde, que nem sempre é coberto por bolsas de estudo.
    </li>
    <li>
      Taxas: Várias taxas, como tecnologia, atividades ou taxas de instalações, podem ser cobradas separadamente.
    </li>
  </ul>
  É importante revisar cuidadosamente os termos de sua bolsa de estudos esportiva e planejar um orçamento para despesas que não são cobertas. Algumas universidades oferecem ajuda financeira adicional ou programas de trabalho-estudo para ajudar a cobrir esses custos.
</FaqCollapse>




<FaqCollapse
  title="Como funciona o processo de solicitação de visto para estudar nos EUA?"
  open={collapse === 22}
  onClick={() => (collapse === 22 ? setCollapse(false) : setCollapse(22))}
>
  Se você, como estudante europeu, obteve uma bolsa de estudos esportiva para estudar nos EUA, obter um visto adequado é uma etapa importante. Nisso, minha empresa, Sport Scholarships America, irá ajudá-lo. Uma vez admitido em uma universidade dos EUA, você receberá o Formulário I-20 da instituição, que é essencial para sua solicitação de visto. Com este documento, você paga a taxa SEVIS I-901 e agenda uma entrevista de visto na embaixada ou consulado dos EUA. Em seguida, preenche o formulário DS-160 online e reúne os documentos necessários, como comprovantes financeiros e registros acadêmicos. Com a ajuda do Sport Scholarships America, você se prepara para a entrevista e garante que toda a documentação esteja correta, agilizando o processo. Meu apoio aumenta suas chances de uma solicitação de visto bem-sucedida, permitindo que você se concentre em seus objetivos acadêmicos e atléticos nos EUA.
</FaqCollapse>
<FaqCollapse
  title="O seguro médico está incluído na bolsa de estudos?"
  open={collapse === 23}
  onClick={() => (collapse === 23 ? setCollapse(false) : setCollapse(23))}
>
  Algumas bolsas de estudos esportivas podem incluir seguro médico, mas isso não é padrão em todas as bolsas de estudo ou universidades. É crucial perguntar sobre isso com seu programa específico. Se você organizar sua bolsa de estudos esportiva por meio do Sport Scholarships America, eu o ajudarei a entender o que sua bolsa de estudos cobre e aconselhá-lo sobre como garantir um seguro para as questões que não são cobertas. No caso de o seguro médico não estar incluído, ajudarei você a encontrar um seguro adequado para garantir que você esteja bem coberto durante seus estudos e atividades atléticas nos EUA. Os cuidados médicos podem ser caros na América, portanto, é essencial estar bem preparado nesse aspecto.
</FaqCollapse>

<FaqCollapse
  title="Como posso visitar minha família ou eles podem me visitar durante meus estudos?"
  open={collapse === 24}
  onClick={() => (collapse === 24 ? setCollapse(false) : setCollapse(24))}
>
  Os estudantes-atletas frequentemente podem visitar suas famílias durante feriados escolares designados, como férias de verão e inverno. É importante considerar o calendário esportivo e as obrigações acadêmicas ao planejar viagens. Para os pais ou membros da família que vêm do exterior para os EUA, geralmente é necessário solicitar um visto de turista. Eles devem iniciar esse processo com bastante antecedência da viagem pretendida. Tenha em mente que planejar viagens e visitas também é uma consideração financeira, portanto, é sábio planejar isso com antecedência.
</FaqCollapse>

<FaqCollapse
  title="O que acontece depois que me formo? Posso ficar e trabalhar nos EUA?"
  open={collapse === 25}
  onClick={() => (collapse === 25 ? setCollapse(false) : setCollapse(25))}
>
  Após concluir seus estudos com uma bolsa de estudos esportiva nos EUA, você tem várias opções. Se desejar continuar trabalhando, pode aproveitar o Treinamento Prático Opcional (OPT), que permite que os graduados trabalhem em sua área de estudo por até 12 meses. Para graduados em áreas STEM, isso pode ser estendido para 24 meses. Para se qualificar para isso, você deve se inscrever no U.S. Citizenship and Immigration Services (USCIS) e receber aprovação antes que seu visto expire. Opções de trabalho a longo prazo exigem um visto de trabalho, geralmente patrocinado por um empregador. É importante começar a explorar suas oportunidades de carreira durante seus estudos e fazer networking para aumentar suas chances de emprego após a formatura.
</FaqCollapse>



                

                 
                  
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={{ xs: 0, lg: 3 }} pb={3}>
      <DetailedFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Faq;
