/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Página do Produto: https://www.creative-tim.com/product/material-kit-pro-react
* Direitos Autorais 2023 Creative Tim (https://www.creative-tim.com)

Codificado por www.creative-tim.com

=========================================================

* O aviso de direitos autorais acima e este aviso de permissão devem ser incluídos em todas as cópias ou partes substanciais do Software.
*/

// Componentes de material do @mui

import React from "react";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Componentes do Material Kit 2 PRO React
import MKBox from "components/MKBox";

// Exemplos do Material Kit 2 PRO React
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

function StatsOne() {
  return (
    <MKBox component="section" py={8}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard 
            count={100} 
            suffix="+"
            title="Alunos:"
            description="Guiados com sucesso para suas universidades dos sonhos"  
            onClick={() => console.log('Clicou em 100')}
            />
          </Grid>
          <Grid item xs={12} md={4} >
            <DefaultCounterCard
              count={70}
              title="Universidades:"
              suffix="+"
              description="Alunos colocados com sucesso em toda a América"
              onClick={() => console.log('Clicou em 70')}

            />
          </Grid>
          <Grid item xs={12} md={4}>
          <Link to="/pt/Todos-os-esportes">
              <DefaultCounterCard
                count={10}
                suffix="+"
                title="Esportes:"
                description="Orientando talentos em várias disciplinas"
              />
            </Link>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default StatsOne;
