/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Track and Field1.jpg";
import Image2 from "assets/images/Sports_Images/Track and Field2.jpeg";
import Image3 from "assets/images/Sports_Images/Track and Field3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Friidrett og Studier i Amerika: En Drøm Som Kan Gå i Oppfyllelse', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapittel 1: Historie og Utvikling av Friidrett på Collegenivå', isSubheader: false },
    { id: '1.1', title: '1.1 Tidlige År og Opprinnelse', isSubheader: true },
    { id: '1.2', title: '1.2 Vekst og Profesjonalisering', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapittel 2: Friidrett på Collegenivå i Dag', isSubheader: false },
    { id: '2.1', title: '2.1 Store Konkurranser og Konferanser', isSubheader: true },
    { id: '2.2', title: '2.2 Topp Programmer og Prestisjetunge Lag', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapittel 3: Fra College til Profesjonelt Nivå', isSubheader: false },
    { id: '3.1', title: '3.1 Veien til Profesjonalisme', isSubheader: true },
    { id: '3.2', title: '3.2 Forholdet til Profesjonell Friidrett', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapittel 4: Internasjonale Påvirkninger og Muligheter', isSubheader: false },
    { id: '4.1', title: '4.1 Utenlandske Utøvere og Konkurranser', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanske Utøvere i Utlandet', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapittel 5: Idrettsstipender og Fremtiden til Utøvere', isSubheader: false },
    { id: '5.1', title: '5.1 Viktigheten av Stipender', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Scholarships America Kan Hjelpe', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contents
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                      key={section.id}
                      onClick={() => scrollTo(section.id)}
                      color="secondary"
                      variant="gradient"
                      sx={{ 
                        justifyContent: "flex-start",
                        pl: section.isSubheader ? 4 : 2,
                        textTransform: "none",
                        fontWeight: section.isSubheader ? "normal" : "bold",
                        borderTopLeftRadius: index === 0 ? '20px' : '0',
                        borderTopRightRadius: index === 0 ? '20px' : '0',
                        borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                        borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                        textAlign: "left",
                        width: '100%',
                      }}
                    >
                      {section.title}
                    </MKButton>
                  ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>

























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Friidrett og Studier i Amerika: En Drøm Som Kan Gå i Oppfyllelse
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Løpere i full aksjon under Penn State Relays, et fremtredende collegeløp og friidrettsarrangement."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Handling ved Penn State Relays.
      </figcaption>
    </figure>
    Friidrett er en av de mest populære og betydningsfulle idrettene i USA. Millioner av mennesker driver med friidrett som en hobby, som en del av skole- eller collegeprogrammet sitt, eller som profesjonelle utøvere. Friidrett er også en idrett som tilbyr mange muligheter for unge talenter til å utvikle seg, konkurrere og utmerke seg på høyeste nivå.
    <br/>
    <br/>
    En av de beste måtene å dra nytte av disse mulighetene på, er ved å få et friidrettsstipend i USA. Et friidrettsstipend er økonomisk støtte du mottar fra et universitet for å studere og delta i friidrett der. Med et friidrettsstipend kan du ikke bare tjene din akademiske grad, men også delta i et av de mest prestisjefylte og konkurransedyktige friidrettsprogrammene i verden.
    <br/>
    <br/>
    Men hvordan får du et friidrettsstipend i USA? Hva er fordelene og utfordringene med å studere og konkurrere på college-nivå? Og hvordan kan du forberede deg til en vellykket karriere som utøver etter studiene? Dette er noen av spørsmålene vi vil svare på i denne artikkelen.
    <br/>
    <br/>
    Vi vil også fortelle deg hvordan Sportbeursamerika.nl kan hjelpe deg med å oppfylle drømmen din. Sportbeursamerika.nl er en organisasjon spesialisert på å veilede unge utøvere til sportsstipend i USA. Jeg har mye erfaring med å finne den beste matchen mellom utøvere og universiteter. Jeg tilbyr personlig veiledning, profesjonell rådgivning og praktisk støtte på hvert trinn av prosessen.
    <br/>
    <br/>
    Hvis du er interessert i å få et friidrettsstipend i USA, er denne artikkelen for deg. Les videre og oppdag alt du trenger å vite om friidrett på college-nivå i USA.
  </MKTypography>
  {/* Legg til punktliste eller annet innhold om nødvendig */}
</MKBox>
{/* Hovedkapitler og underkapitler med plassholder tekst */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 1: Historie og Utvikling av Friidrett på Collegenivå
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Friidrett på collegenivå har en lang og rik historie i USA. Det startet på 1800-tallet som en måte å fremme fysisk og mental helse blant studenter. Det utviklet seg til en stor idrett som tiltrakk millioner av tilskuere og produserte mange utøvere som senere ble verdenskjente.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidlige År og Opprinnelse
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Det første friidrettsstevnet ved universiteter fant sted i 1864, da studenter fra Yale og Oxford utfordret hverandre til en kamp i London. Konkurransen besto av fire øvelser: løping, lengdehopp, høydehopp og stavhopp. Yale vant kampen 7-3.
      <br/>
      <br/>
      Dette stevnet inspirerte andre universiteter til også å danne friidrettslag og konkurrere mot hverandre. I 1873 ble den første intercollegiate friidrettsforeningen grunnlagt, Intercollegiate Association of Amateur Athletes of America (IAAAA). IAAAA organiserte årlige mesterskap for universiteter i det østlige USA.
      <br/>
      <br/>
      Slutten av 1800-tallet var en viktig æra for utviklingen av friidrett. Den var preget av formaliseringen av regler og fremveksten av innflytelsesrike figurer som James E. Sullivan, medgrunnlegger av Amateur Athletic Union i USA. Han spilte en betydelig rolle i å popularisere friidrett og organisere store arrangementer. Innføringen av de moderne olympiske leker i 1896 ga en internasjonal plattform for sporten, noe som førte til standardisering av regler og øvelser. Også teknologiske fremskritt, som forbedringer i fottøy og løpebaner, bidro til utviklingen av friidrett i løpet av denne perioden.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Vekst og Profesjonalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      På 1900-tallet ble friidrett stadig mer populær og viktig ved universitetene. Antallet friidrettsprogrammer, konkurranser og stipend økte. Nivået til utøverne steg også, takket være blant annet bedre trening, fasiliteter og coaching.
      <br/>
      <br/>
      En av faktorene som bidro til vekst og profesjonalisering av friidrett på collegenivå var grunnleggelsen av National Collegiate Athletic Association (NCAA) i 1906. NCAA er en organisasjon som overvåker idrettsaktivitetene til mer enn 1000 universiteter i USA. NCAA regulerer blant annet regler, kvalifikasjoner, stipend og sanksjoner for friidrett på collegenivå.
      <br/>
      <br/>
      En annen faktor som påvirket utviklingen av friidrett på collegenivå var forholdet til profesjonell friidrett. Mange utøvere som var vellykkede på collegenivå, gikk senere over til den profesjonelle banen. Noen eksempler er Jesse Owens, Carl Lewis, Jackie Joyner-Kersee og Michael Johnson.
    </MKTypography>
  </MKBox>
</MKBox>








                                              





                                  








<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 2: Friidrett på Collegenivå i Dag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Friidrett på collegenivå er i dag en av de mest populære og prestisjefylte idrettene i USA. Hvert år deltar tusenvis av utøvere i ulike konkurranser og arrangementer, som tiltrekker millioner av fans og medieoppmerksomhet. Friidrett på collegenivå tilbyr også mange muligheter for utøvere til å utvikle seg, prestere og nyte idretten sin.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Store Konkurranser og Konferanser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Sydney McLaughlin, utmerker seg som utøver for University of Kentucky, i konkurranseuniform."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Sydney McLaughlin studerte ved University of Kentucky
        </figcaption>
      </figure>
      Den viktigste organisasjonen som regulerer friidrett på collegenivå er NCAA. NCAA deler universiteter inn i tre divisjoner, basert på størrelsen deres, budsjett og nivå av konkurranse. Divisjon I er den høyeste divisjonen, der de fleste toppuniversitetene og utøverne er. Divisjonene II og III er lavere divisjoner, der universitetene har færre stipend og ressurser, men legger mer vekt på akademisk prestasjon.
      <br/>
      <br/>
      Innenfor hver divisjon er det forskjellige konferanser, som er grupper av universiteter som er geografisk eller historisk tilknyttet. Hver konferanse organiserer sine egne mesterskap og konkurranser for medlemmene sine. Noen av de mest kjente konferansene er Big Ten, Pac-12, SEC, ACC og Ivy League.
      <br/>
      <br/>
      De viktigste hendelsene i colletage friidrettssesongen er NCAA-mesterskapene, som holdes årlig i mars (innendørs) og juni (utendørs). Disse mesterskapene avgjør hvilke universiteter og utøvere som er de beste i hver divisjon og øvelse. Mesterskapene tiltrekker seg mange tilskuere, sponsorer og medieoppmerksomhet.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Topp Programmer og Prestisjetunge Lag
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Det er mange universiteter kjent for sine fremragende friidrettsprogrammer. Disse universitetene har en lang tradisjon med suksess, høykvalitets coaching og fasiliteter, og en sterk appell til talentfulle utøvere. Noen av disse universitetene er:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Oregon: Oregon er et av de mest vellykkede universitetene innen friidrett, spesielt innen løping. Oregon har vunnet mer enn 30 NCAA-titler og har produsert mange kjente utøvere, som Steve Prefontaine, Alberto Salazar, Galen Rupp og Matthew Centrowitz.
        </li>
        <li>
          USC: USC er et annet toppuniversitet innen friidrett, med fokus på sprint, hopp og kast. USC har vunnet mer enn 25 NCAA-titler og har produsert mange olympiske medaljevinnere, som Quincy Watts, Andre De Grasse og Michael Norman.
        </li>
        <li>
          Arkansas: Arkansas er et dominerende universitet innen friidrett, spesielt under innendørssesongen. Arkansas har vunnet mer enn 40 NCAA-titler, hvorav mer enn 30 er innendørs. Arkansas er kjent for sin dybde og allsidighet i ulike øvelser.
        </li>
        <li>
          Texas: Texas er et av de mest allsidige universitetene innen friidrett, med en god balanse mellom herre- og dame-lagene, og mellom individuelle og stafettøvelser. Texas har vunnet mer enn 20 NCAA-titler og har produsert mange verdensklasseutøvere, som Sanya Richards-Ross, Trey Hardee, Michelle Carter og Courtney Okolo.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Disse universitetene tilbyr ikke bare et høyt nivå av konkurranse og trening for utøverne sine, men også en flott atmosfære og kultur. Utøverne er en del av et tett samfunn som støtter og motiverer dem til å nå sitt potensial.
    </MKTypography>
  </MKBox>
</MKBox>












                                              






                                  








<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 3: Fra College til Profesjonelt Nivå
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Friidrett på collegenivå er ikke bare en idrett, men også en treningsarena for utøvere som aspirerer til en profesjonell karriere. Mange utøvere som har prestert på collegenivå, har senere gått over til den profesjonelle sirkelen. Friidrett på collegenivå gir utøvere muligheten til å utvikle seg, oppleve og nettverke med andre utøvere, trenere og organisasjoner.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Veien til Profesjonalisme
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      For mange utøvere er målet å bli profesjonell utøver etter studiene. Dette betyr å utøve idretten sin som en profesjon og tjene penger gjennom prestasjoner, kontrakter og sponsoravtaler. For å bli en profesjonell utøver, må utøvere oppfylle flere betingelser, slik som:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Ha et høyt prestasjonsnivå og potensial. Profesjonelle utøvere må kunne konkurrere med de beste utøverne i verden og kontinuerlig forbedre seg og tilpasse seg endrede forhold.
        </li>
        <li>
          Opprettholde god helse og kondisjon. Profesjonelle utøvere må ta godt vare på kroppen sin og beskytte seg mot skader, sykdommer og overtrening. De må også finne en god balanse mellom trening, hvile og ernæring.
        </li>
        <li>
          Ha god veiledning og støtte. Profesjonelle utøvere bør kunne stole på et team av eksperter som kan hjelpe dem med teknikk, taktikk, mental forberedelse, juridiske spørsmål, økonomiske spørsmål og markedsføring.
        </li>
        <li>
          Ha god planlegging og strategi. Profesjonelle utøvere må vite hvilke mål de ønsker å oppnå, hvilke konkurranser de ønsker å delta i, hvordan de ønsker å kvalifisere seg for store arrangementer, og hvordan de ønsker å styre karrieren sin.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Friidrett på collegenivå kan hjelpe utøvere med å oppfylle disse betingelsene. Det tilbyr utøvere sjansen til å vise frem talentet sitt, forbedre ferdighetene sine, øke erfaringen sin og utvide nettverket sitt. Friidrett på collegenivå kan også fungere som en trampoline til det profesjonelle nivået ved å eksponere utøvere for høy konkurranse, medieoppmerksomhet og offentlig interesse.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Forholdet til Profesjonell Friidrett
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Friidrett på collegenivå har et nært forhold til profesjonell friidrett. De to nivåene påvirker hverandre på ulike måter, slik som:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Talentutvikling: Friidrett på collegenivå er en stor kilde til talent for profesjonell friidrett. Mange profesjonelle utøvere startet som collegestudenter og la grunnlaget for senere suksess der.
        </li>
        <li>
          Talentoppdagelse: Profesjonelle idrettsorganisasjoner overvåker nøye collegeidretten for å oppdage og tiltrekke potensielle stjerner. De tilbyr collegestudenter kontrakter, sponsoravtaler og veiledning for å overtale dem til å bli profesjonelle.
        </li>
        <li>
          Talentutveksling: Friidrett på collegenivå er også åpent for profesjonelle utøvere som fortsatt studerer eller som vender tilbake til skolen. Noen profesjonelle utøvere velger å fullføre eller gjenoppta studiene sine mens de fortsetter karrieren sin innen idrett.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Friidrett på collegenivå er derfor ikke bare et endepunkt, men også en trappetrinn for utøvere som søker en profesjonell karriere. Det tilbyr utøvere muligheten til å forberede seg til det profesjonelle nivået, men også til å utforske andre veier.
    </MKTypography>
  </MKBox>
</MKBox>









                                              






                                  







<MKBox id="Chapter 4" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Kapittel 4: Internasjonale Påvirkninger og Muligheter
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Friidrett på collegenivå er ikke bare en amerikansk idrett, men også en internasjonal en. Den påvirkes av og tilbyr muligheter for utøvere fra ulike land og kulturer. Friidrett på collegenivå er en idrett som gjenspeiler mangfoldet og sammenhengen i verden.
    </MKTypography>

    <MKBox id="4.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.1 Utenlandske Utøvere og Konkurranser
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image3}
                    alt="Det berømte Oregon-athletiske stadionet, hjem til utallige college-friidrettsarrangementer."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal3(Image3)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Oregon-athletiske stadionet, en ikon for college-idrett.
                </figcaption>
            </figure>
            En av måtene friidrett på collegenivå er internasjonal på, er gjennom tilstedeværelsen og prestasjonene til utenlandske utøvere. Hvert år kommer hundrevis av utøvere fra andre land til USA for å studere og konkurrere på collegenivå. Disse utøverne bringer med seg sitt eget talent, stil og kultur til amerikanske universiteter, og beriker friidrettsfellesskapet med sine perspektiver og erfaringer.
            <br/>
            <br/>
            Utenlandske utøvere har også en betydelig innvirkning på resultatene og rangeringene av friidrett på collegenivå. Mange utenlandske utøvere er blant de beste utøverne i sin øvelse, og vinner ofte medaljer, titler og rekorder. Noen utenlandske utøvere er til og med så gode at de overgår eller utfordrer amerikanske utøvere.
            <br/>
            <br/>
            Eksempler på vellykkede utenlandske utøvere i amerikanske collegekonkurranser inkluderer:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Andre De Grasse: en kanadisk sprinter som utmerket seg ved Coffeyville Community College og University of Southern California (USC), og vant flere NJCAA- og NCAA-titler i sprintøvelser. Hans prestasjoner på USC etablerte hans rykte som en ledende sprinter. Etter sin collegekarriere vant De Grasse flere olympiske medaljer, inkludert gull på 200 meter under OL i Tokyo i 2020, og viste seg som en av verdens beste sprintere.
                </li>
                <li>
                    Kerron Clement: en trinidadisk-amerikansk utøver som utmerket seg på 400 meter hekk. Han studerte ved University of Florida, der han vant NCAA-titler og utviklet seg til en verdensklasse hekkeløper. Clement tok gull under OL i 2008 og 2016, og har flere verdensmesterskapstitler på sitt navn, noe som gjør ham til en av de mest vellykkede hekkeløperne i sin generasjon.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Utenlandske utøvere påvirker ikke bare friidrett på collegenivå, men også andre internasjonale konkurranser og arrangementer. Mange utenlandske utøvere som konkurrerte på collegenivå, deltar senere i OL, verdensmesterskap eller andre regionale eller kontinentale mesterskap. De representerer sitt eget land, men har også tilknytning til sitt amerikanske universitet.
        </MKTypography>
    </MKBox>
    <MKBox id="4.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.2 Amerikanske Utøvere i Utlandet
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            En annen måte friidrett på collegenivå er internasjonal på, er gjennom mulighetene og utfordringene den tilbyr for amerikanske utøvere å konkurrere i utlandet. Mange amerikanske utøvere som har konkurrert på collegenivå, får sjansen til å utvide horisontene sine og teste ferdighetene sine mot andre utøvere fra forskjellige land og kulturer.
            <br/>
            <br/>
            En av de viktigste mulighetene for amerikanske utøvere å konkurrere i utlandet er selvfølgelig de olympiske leker. OL er verdens største og mest prestisjefylte idrettsarrangement, der tusenvis av utøvere fra over 200 land kommer sammen for å konkurrere om gull, sølv og bronse. OL er også en sjanse for amerikanske utøvere å representere landet sitt, vise sin patriotisme og dele sin nasjonale stolthet.
            <br/>
            <br/>
            En annen viktig begivenhet for amerikanske utøvere å konkurrere i utlandet er verdensmesterskapet i friidrett. Verdensmesterskapet i friidrett er det mest betydningsfulle friidrettsarrangementet etter OL, der verdens beste utøvere møtes for å konkurrere om verdensmestertittelen. Verdensmesterskapet i friidrett er også en sjanse for amerikanske utøvere å måle sitt nivå, fyre opp rivaliseringen sin og etablere sitt rykte.
            <br/>
            <br/>
            Bortsett fra disse store arrangementene, er det også andre muligheter for amerikanske utøvere å konkurrere i utlandet, slik som Diamond League, Continental Tour, World Athletics Relays eller andre regionale eller kontinentale mesterskap. Disse arrangementene tilbyr amerikanske utøvere muligheten til å øke erfaringen sin, inntektene sine og sin fanskare.
            <br/>
            <br/>
            Friidrett på collegenivå spiller en viktig rolle i utviklingen og suksessen til amerikanske utøvere som konkurrerer i utlandet. Det forbereder utøvere på det internasjonale nivået ved å gi dem høykvalitets trening, coaching og konkurranse. Friidrett på collegenivå støtter også utøvere som konkurrerer i utlandet ved å tilby dem et nettverk av kontakter, sponsorer og fans.
        </MKTypography>
    </MKBox>
</MKBox>










                                              






                                  




<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 5: Idrettsstipender og Fremtiden til Utøvere
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrettsstipender gir mange muligheter for unge utøvere til å vise sitt talent og utvikle sine ferdigheter. En av de mest betydningsfulle mulighetene er å få et idrettsstipend for å studere og konkurrere på college-nivå. I dette kapittelet vil vi diskutere viktigheten av idrettsstipender og hvordan Sport Scholarships America kan hjelpe deg med å oppfylle drømmen din.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Viktigheten av Stipender
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et idrettsstipend er økonomisk støtte gitt av et universitet til en studentutøver for å kombinere studier og idrett. Det kan dekke kostnadene for skolepenger, bøker, bolig, måltider og andre utgifter. I tillegg kan det tilby fordeler som akademisk veiledning, medisinsk hjelp, treningsfasiliteter og utstyr.
      <br />
      <br />
      Å få et idrettsstipend er konkurransedyktig, med mange faktorer som spiller en rolle i tildelingsprosessen, inkludert akademiske prestasjoner, idrettslige resultater, personlighet, motivasjon, lederegenskaper og tilpasningsevne. Det er derfor viktig å forberede seg godt og skille seg ut fra konkurransen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Scholarships America Kan Hjelpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo for Sport Scholarships America, som viser sportsutstyr og en eksamenskappe, symboliserer fokuset på idrettsstipender for studentutøvere."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo for Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America er en organisasjon spesialisert i å veilede unge idrettstalenter til idrettsstipender. Med lidenskapelige og erfarne eksperter støtter Sport Scholarships America studentutøvere på hvert trinn i reisen, fra første orientering til endelig plassering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig inntaksintervju for å diskutere studentutøverens ønsker, muligheter og forventninger.</li>
        <li>En profesjonell evaluering av studentutøverens akademiske og idrettslige nivå.</li>
        <li>Et omfattende nettverk av kontakter med trenere, universiteter og høyskoler.</li>
        <li>Effektiv markedsføring av studentutøverens profil og videoer til potensielle trenere.</li>
        <li>Ekspertråd om de beste alternativene basert på studentutøverens preferanser, mål og budsjett.</li>
        <li>Forhandlinger om de beste stipendtilbudene på vegne av studentutøveren.</li>
        <li>Hjelp til å søke om nødvendige dokumenter, som visum, transkripsjon, diploma og opptaksprøver.</li>
        <li>Forberedelse til studentutøverens liv under stipendet, inkludert kultur, språk, regler og tips.</li>
        <li>Oppfølging og oppfølging under stipendperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vil du utforske stipendmuligheter og oppnå dine idrettslige og akademiske mål? <Link to="/no/Tjenester-Produkter">Se det komplette utvalget</Link> av tjenester som tilbys av Sport Scholarships America og planlegg en konsultasjon i dag.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;