/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Gymnastics1.jpeg";
import Image2 from "assets/images/Sports_Images/Gymnastics2.jpg";
import Image3 from "assets/images/Sports_Images/Gymnastics3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Gymnastik og Studier i Amerika: En Unik Mulighed for Unge Gymnaster', isSubheader: false },
  
    { id: 'Hoofdstuk 1', title: 'Kapitel 1: Historie og Udvikling af Gymnastik i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Tidlige År og Universiteternes Rolle', isSubheader: true },
    { id: '1.2', title: '1.2 Vækst og Professionalisering', isSubheader: true },
  
    { id: 'Hoofdstuk 2', title: 'Kapitel 2: Collegiate Gymnastik i Dag', isSubheader: false },
    { id: '2.1', title: '2.1 Store Konkurrencer og Konferencer', isSubheader: true },
    { id: '2.2', title: '2.2 Topprogrammer og Prestigefyldte Hold', isSubheader: true },
  
    { id: 'Hoofdstuk 3', title: 'Kapitel 3: Fra College til Professionel', isSubheader: false },
    { id: '3.1', title: '3.1 Vejen til Professionalisme', isSubheader: true },
    { id: '3.2', title: '3.2 Professionelle Karrierer og Muligheder', isSubheader: true },
  
    { id: 'Hoofdstuk 4', title: 'Kapitel 4: Internationale Indflydelser', isSubheader: false },
    { id: '4.1', title: '4.1 Udenlandske Indflydelser og Udveksling', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanere i Udlandet', isSubheader: true },
  
    { id: 'Hoofdstuk 5', title: 'Kapitel 5: Sportslegater og fremtiden for atleter', isSubheader: false },
    { id: '5.1', title: '5.1 Vigtigheden af legater', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Legater Amerika Kan Hjælpe', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Gymnastik og Studier i Amerika: En Unik Mulighed for Unge Gymnaster
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Udsigt fra tribunerne ved en gymnastikkonkurrence på et college i en stor stadion."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        En stor arena under en NCAA-gymnastikkonkurrence
      </figcaption>
    </figure>
    Gymnastik har en lang og rig historie i USA. Siden 1800-tallet har gymnastik været praktiseret på universiteter, hvor gymnaster udvikler deres færdigheder og viser deres talenter. Gymnastik er ikke kun en sport; det er også en kunstform, en videnskab og en livsstil. Gymnaster lærer disciplin, udholdenhed, kreativitet og teamwork. De træner hårdt, men har også det sjovt.
    <br />
    <br />
    At konkurrere i gymnastik på collegeniveau er en unik mulighed for unge gymnaster at opfylde deres drømme. Ved at opnå et sportslegat kan du studere på et prestigefyldt universitet samtidig med at du deltager i spændende konkurrencer mod andre topgymnaster. Du bliver en del af et tæt sammenspillet fællesskab af studerende-atleter, der støtter og motiverer hinanden. Du får adgang til de bedste faciliteter, trænere og mentorer, der hjælper dig med at nå dit potentiale. Og du får en uforglemmelig oplevelse, der forbereder dig på din fremtidige karriere.
    <br />
    <br />
    Men hvordan kvalificerer du dig til et sportslegat? Hvad er kravene og fordelene? Hvordan finder du det rette universitet for dig? Og hvordan er livet som studerende-gymnast? Dette er alle spørgsmål, som Sportbeursamerika.nl kan besvare for dig. Sportbeursamerika.nl er en platform, der hjælper unge gymnaster med at finde vej til Amerika. Jeg tilbyder personlig vejledning, rådgivning og støtte gennem hele ansøgnings-, udvælgelses-, forberedelses- og afrejseprocessen. Jeg har forbindelser til hundredvis af universiteter og trænere, der leder efter gymnastiktalent. Jeg kender til detaljerne i det amerikanske uddannelsessystem og collegiat gymnastik. Og jeg har personlig erfaring med sport og studier i Amerika.
    <br />
    <br />
    Er du nysgerrig efter at høre om mulighederne for at kombinere gymnastik og studier i Amerika? Læs videre og opdag alt, hvad du har brug for at vide om denne unikke mulighed.
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>

<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Historie og Udvikling af Gymnastik i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Gymnastik er en af verdens ældste sportsgrene, med rødder i gamle civilisationer som Grækenland, Rom og Kina. Gymnastik blev brugt som et middel til at træne kroppen, skærpe sindet og hæve sjælen. Det blev også betragtet som en form for udtryk, kunst og skønhed.
    <br />
    <br />
    I USA begyndte gymnastik at udvikle sig i det 19. århundrede, da europæiske immigranter bragte deres viden og færdigheder med sig. Gymnastik blev hurtigt populært på skoler, klubber og foreninger, hvor det blev set som en måde at fremme sundhed, karakter og medborgerskab. Gymnastik blev også en kilde til national stolthed, især efter Uafhængighedskrigen.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidlige År og Universiteternes Rolle
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      De første universitetsgymnastikhold blev dannet i slutningen af det 19. århundrede, da studerende etablerede deres egne klubber og organiserede interkollegiale konkurrencer. Den første officielle universitetsgymnastikkonkurrence fandt sted i 1897, da Yale University stod over for Springfield College. Springfield College betragtes som fødestedet for amerikansk gymnastik, da det var den første skole, der tilbød gymnastik som et akademisk fag. Mange kendte gymnastiktrænere og ledere studerede eller underviste på Springfield College.
      <br />
      <br />
      I begyndelsen af det 20. århundrede fortsatte universitetsgymnastikken med at vokse med etableringen af regionale og nationale organisationer som Eastern Intercollegiate Gymnastics League (EIGL) i 1902 og National Collegiate Athletic Association (NCAA) i 1906. NCAA begyndte at organisere nationale mesterskaber for mænd i 1938 og for kvinder i 1982. NCAA forbliver den primære organisation for collegiat gymnastik i Amerika.
    </MKTypography>
  </MKBox>

  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Vækst og Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Populariteten og niveauet af universitetsgymnastik steg efter Anden Verdenskrig, da mange veteraner vendte tilbage til universiteterne og forfulgte deres atletiske ambitioner. Påvirkningen fra europæiske trænere og atleter, der kom til Amerika for at undslippe kommunismen eller nazismen, bidrog også til udviklingen af universitetsgymnastikken. En sådan figur var George Gulack, en ungarsk gymnast, der vandt guld ved OL i 1936 i Berlin. Gulack spillede en betydelig rolle i den olympiske komité og amerikanske og internationale gymnastikorganisationer og tjente på det amerikanske olympiske udvalg fra 1934 til 1958.
      <br />
      <br />
      I 1950'erne og 60'erne begyndte professionel gymnastik at differentiere sig fra universitetsgymnastikken, med fremkomsten af internationale stjerner som Larisa Latynina, Olga Korbut og Nadia Comaneci. Disse gymnaster inspirerede mange unge amerikanere til at deltage i private klubber og fokusere på individuelle præstationer. En af dem var Cathy Rigby, der blev den første amerikanske kvinde til at vinde en medalje ved et verdensmesterskab i 1970. Hun blev senere en meget succesrig skuespiller.
      <br />
      <br />
      Professionel gymnastik nåede sit højdepunkt i 1978, da amerikansk gymnast Kurt Thomas blev den første mand til at vinde en guldmedalje ved et verdensmesterskab. Han studerede på Indiana State University og blev senere skuespiller. Samme år gjorde Mary Lou Retton sin debut på den nationale scene. Hun blev den første amerikanske kvinde til at vinde guld i all-around ved OL i 1984.
      <br />
      <br />
      Universitetsgymnastikken forblev dog relevant og indflydelsesrig, især for kvinder. Mange kvindelige gymnaster valgte at fortsætte deres karriere på collegeniveau, fordi de fik flere muligheder og fordele der end i den professionelle kreds.
      <br />
      <br />
      Universitetsgymnastik spillede derfor en betydelig rolle i historien og udviklingen af gymnastik i Amerika. Det producerede mange atleter, der udmærkede sig ikke kun i deres sport, men også i deres studier og senere karrierer. Det bidrog også til udbredelsen og fremme af gymnastik som en sport for alle, uanset alder, køn eller baggrund. Universitetsgymnastik er stadig en levende og dynamisk sport, der tiltrækker tusinder af fans og tilskuere hvert år.
    </MKTypography>
  </MKBox>
</MKBox>

                                              





                                  








<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Collegiate Gymnastik i Dag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Gymnastik er en af de mest populære og konkurrenceprægede sportsgrene på collegeniveau i Amerika. Hvert år konkurrerer hundredvis af hold og tusinder af atleter om de eftertragtede titler og trofæer. Collegiate gymnastik tilbyder en unik kombination af atletik, spektakel og akademisk fremragende. Det er en sport, der kræver meget, men giver også meget tilbage.
  </MKTypography>
  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Store Konkurrencer og Konferencer
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="En gymnast fra Auburn udfører en gulv rutine under en collegestævne."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          En gymnast fra Auburn i aktion
        </figcaption>
      </figure>
      Collegiate gymnastik reguleres af NCAA, som fastsætter regler, kvalifikationer og mesterskaber. NCAA deler collegiate gymnastik op i tre divisioner baseret på antallet af legater, budget og holdniveau. Division I er den højeste division, hvor de fleste topgymnaster og hold deltager. Division II og III er lavere divisioner med færre legater tilbudt og mere fokus på akademisk præstation.
      <br />
      <br />
      Inden for hver division er der forskellige konferencer, der danner regionale eller tematiske grupper af universiteter. Konferencer organiserer deres egne konkurrencer og mesterskaber, der fungerer som prækvalifikationer til de nationale mesterskaber. De største konferencer for collegiat gymnastik er:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Big Ten Conference, der består af 14 universiteter i Midwest og Northeast of America. Big Ten er en af de ældste og mest prestigefyldte konferencer, kendt for sin akademiske og atletiske fremragende. Big Ten har nogle af de bedste mænds og kvinders gymnastikprogrammer, herunder Michigan, Minnesota, Nebraska og Penn State.
        </li>
        <li>
          Southeastern Conference (SEC), der består af 14 universiteter i Sydøstamerika. SEC er en af de mest dominerende og indflydelsesrige konferencer, kendt for sine passionerede og loyale fans. SEC har nogle af de bedste kvindegymnastikprogrammer, såsom Alabama, Florida, Georgia og LSU.
        </li>
        <li>
          Pac-12 Conference, der består af 12 universiteter i den vestlige USA. Pac-12 er en af de mest innovative og mangfoldige konferencer, kendt for sin kreativitet og kultur. Pac-12 har nogle af de bedste mænds og kvinders gymnastikprogrammer, herunder California, Stanford, UCLA og Utah.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      De nationale mesterskaber er højdepunktet i den collegiale gymnastiksæson, hvor de bedste hold og individer konkurrerer om ære og hæder. De nationale mesterskaber afholdes hvert år i april på forskellige steder i hele landet. De nationale mesterskaber består af to runder: de regionale mesterskaber og de nationale finaler. De regionale mesterskaber er kvalifikationsrunder, hvor 36 hold for mænd og 36 hold for kvinder opdeles i seks regioner. De to bedste hold fra hver region avancerer til de nationale finaler. De nationale finaler er de afgørende runder, hvor 12 hold for mænd og 12 hold for kvinder opdeles i to sessioner. De fire bedste hold fra hver session avancerer til superfinalen (Super Six), hvor de konkurrerer om titlen som nationalt holdmesterskab. Derudover tildeles individuelle titler til all-around konkurrencen og apparaturfinalerne.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Topprogrammer og Prestigefyldte Hold
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Collegiate gymnastik har produceret flere topprogrammer, der adskiller sig ved deres konsistens, kvalitet og tradition. Disse programmer har vundet flere nationale titler, trænet mange talentfulde atleter og tiltrukket talrige fans. Nogle af disse programmer inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          UCLA Bruins: UCLA gymnastikprogrammet er et af de mest succesrige og respekterede programmer i landet. UCLA gymnastikprogrammet har vundet mere end 20 nationale titler, herunder flere titler for mænd og flertallet for kvinder. Programmet har også produceret talrige olympiske og verdensmestre, såsom Peter Vidmar, Tim Daggett, Jamie Dantzscher, Mohini Bhardwaj, Tasha Schwikert, Samantha Peszek og Kyla Ross.
        </li>
        <li>
          Oklahoma Sooners: Oklahoma gymnastikprogrammet er et af de mest dominerende og imponerende programmer i nationen. Oklahoma gymnastikprogrammet har vundet mere end 10 nationale titler, herunder flere titler for mænd og næsten lige så mange for kvinder. Programmet har også produceret mange NCAA-mestre, herunder Jonathan Horton, Jake Dalton, Maggie Nichols, Brenna Dowell og Anastasia Webb.
        </li>
        <li>
          Utah Utes: Utah gymnastikprogrammet er et af de mest historiske og loyale programmer i landet. Utah gymnastikprogrammet har vundet mere end 10 nationale titler, alle for kvinder. Programmet har også produceret legendariske trænere og atleter, herunder Greg Marsden, Megan Marsden, Missy Marlowe, Theresa Kulikowski, Ashley Postell og MyKayla Skinner.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Collegiate gymnastik tilbyder en unik mulighed for unge gymnaster at dyrke deres sport på højeste niveau samtidig med at de modtager en værdifuld uddannelse. Collegiate gymnastik er en sport, der giver mange udfordringer og belønninger, både på og uden for gulvet. Collegiate gymnastik er en sport, der kan ændre dit liv.
    </MKTypography>
  </MKBox>
</MKBox>
                           






                                  






<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Fra College til Professionel
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Gymnastik er en sport, der kræver masser af talent, dedikation og udholdenhed. Gymnaster starter ofte træning i en ung alder og drømmer om en professionel karriere. Men hvordan laver du overgangen fra collegiat gymnastik til professionel gymnastik? Og hvilke muligheder og udfordringer venter dig?
  </MKTypography>
  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Vejen til Professionalisme
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Collegiat gymnastik er fremragende forberedelse til professionel gymnastik, fordi det giver dig mulighed for at forbedre dine færdigheder, få erfaring og udvide dit netværk. Mange collegiat gymnaster har deltaget i professionelle konkurrencer såsom Olympiske Lege, verdensmesterskaber og World Cup. Nogle af dem har endda vundet medaljer.
      <br />
      <br />
      Dog skal collegiat gymnaster overholde visse regler og betingelser for at deltage i professionelle konkurrencer. De skal opretholde deres amatørstatus, hvilket betyder, at de ikke kan tjene penge på deres sport. De har heller ikke lov til at have sponsorer eller agenter, medmindre de får tilladelse fra NCAA. De skal også opfylde universitetets akademiske krav, hvilket nogle gange kan være udfordrende at balancere med deres træningsplan.
      <br />
      <br />
      Derudover skal collegiat gymnaster også overveje deres alder og fysiske tilstand. Gymnastik er en sport, der stiller store krav til kroppen og fører ofte til skader. Mange gymnaster når deres højdepunkt omkring tyverne og kan have svært ved at opretholde deres niveau efterfølgende. Nogle gymnaster vælger at opgive deres professionelle ambitioner og fokusere på deres studier eller karriere uden for sporten.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professionelle Karrierer og Muligheder
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      For dem der beslutter sig for at fortsætte med professionel gymnastik, er der forskellige muligheder og chancer for at forfølge deres passion. En af de mest prestigefyldte og indbringende muligheder er at konkurrere i Olympiske Lege, der afholdes hvert fjerde år. De Olympiske Lege er højdepunktet i enhver sportskarriere, idet de tilbyder muligheden for at repræsentere dit land, konkurrere mod verdens bedste gymnaster og vinde en medalje. De Olympiske Lege bringer også berømmelse og anerkendelse, der kan føre til sponsoratkontrakter, medieopmærksomhed og andre fordele.
      <br />
      <br />
      En anden mulighed er at deltage i verdensmesterskaberne, der afholdes hvert år (undtagen i olympiske år). Verdensmesterskaberne er et betydeligt benchmark for niveauet og udviklingen af gymnastik globalt set. Verdensmesterskaberne tilbyder også muligheden for at kvalificere sig til Olympiske Lege eller modtage et wildcard-entry. Verdensmesterskaberne er en kilde til stolthed og prestige, der kan føre til præmiepenge, legater og andre belønninger.
      <br />
      <br />
      En tredje mulighed er at konkurrere i World Cup, der består af en række individuelle konkurrencer rundt om i verden. World Cup er et attraktivt valg for gymnaster, der leder efter mere fleksibilitet og variation i deres sportskarriere. World Cup tilbyder også muligheden for at udforske forskellige lande og kulturer, få nye venner og udvide dine horisonter. World Cup er også en kilde til sjov og eventyr, der kan føre til rejseudgifter, bonusser og andre incitamenter.
      <br />
      <br />
      Udover disse internationale konkurrencer er der andre professionelle muligheder for gymnaster, såsom:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          At afholde klinikker, workshops eller demonstrationer, hvor du deler din viden og erfaring med andre gymnaster, trænere eller fans.
        </li>
        <li>
          Arbejde som træner, træner eller rådgiver og hjælpe andre gymnaster med at opnå deres mål, forbedre deres teknik eller løse deres problemer.
        </li>
        <li>
          Arbejde som kommentator, analytiker eller journalist og tilbyde din mening og indsigt om gymnastik, konkurrencer eller aktuelle begivenheder.
        </li>
        <li>
          Arbejde som model, skuespiller eller influencer og bruge dit udseende, personlighed eller popularitet til at promovere produkter, tjenester eller brands.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Professionel gymnastik er en spændende og udfordrende karriere, der tilbyder mange muligheder og muligheder. Det er imidlertid også en krævende og usikker karriere, der kommer med risici og forhindringer. At vælge en karriere inden for professionel gymnastik kræver både dit hjerte og dit hoved.
    </MKTypography>
  </MKBox>
</MKBox>




                                  






<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationale Indflydelser
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Gymnastik er en sport uden grænser. Gymnastik er en sport, der samler folk fra hele verden, inspirerer og beriger dem. Gymnastik er en sport påvirket af forskellige kulturer, stilarter og traditioner. I dette kapitel vil vi se på de internationale indflydelser på collegiat gymnastik i Amerika og hvordan disse indflydelser har formet og transformeret collegiat gymnastik.
  </MKTypography>
  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Udenlandske Indflydelser og Udveksling
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Stanford gymnast in action on the pommel horse at a college competition."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          En gymnast fra Stanford i aktion
        </figcaption>
      </figure>
      I den fascinerende verden af collegiat gymnastik i Amerika spiller international udveksling en afgørende rolle. Vidste du, at udenlandske gymnaster bringer en unik dynamik og teknikker, der hæver konkurrenceniveauet? Disse atleter bringer ofte innovative stilarter og metoder, der inspirerer og udfordrer deres amerikanske modparter. Dette gør ikke kun sporten mere mangfoldig, men også mere konkurrencepræget.
      <br />
      <br />
      Et eksempel på dette er påvirkningen af ​​østeuropæiske gymnaster i '80'erne og '90'erne. Deres ankomst i USA bragte nye teknikker og træningsmetoder, hvilket førte til en betydelig udvikling inden for amerikansk gymnastik. Disse indflydelser er stadig synlige i dag i stilen og teknikken hos mange top gymnaster.
      <br />
      <br />
      Mangfoldigheden, som internationale gymnaster bringer, beriger sportskulturen på campusserne. Det skaber en smeltedigel af kulturer og ideer, der kun gavner sporten. Derudover giver det amerikanske studerende og trænere mulighed for at lære fra forskellige kulturelle perspektiver og skærpe deres egne færdigheder og taktikker.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikanere i Udlandet
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      På den anden side har amerikanske gymnaster også haft en betydelig indflydelse på den internationale gymnastikverden. Talrige amerikanske studerende-atleter har opnået international succes efter deres collegiale karriere. Deres præstationer ved verdensmesterskaber og de Olympiske Lege skyldes ofte den træning og erfaring, de har fået i den collegiale cirkel.
      <br />
      <br />
      Disse gymnaster fungerer som ambassadører for amerikansk gymnastikkultur. Deres succeser inspirerer unge gymnaster over hele verden til at stræbe efter at være en del af et amerikansk universitetshold. Dette skaber en positiv cyklus, hvor talent og viden udveksles på tværs af grænser.
      <br />
      <br />
      De veje, disse gymnaster har taget, demonstrerer, hvordan collegiat gymnastik kan være en fremragende grundlag for en international karriere. De erfaringer, de får under deres studier - fra deltagelse i konkurrencer på højt niveau til træning under toptrænere - forbereder dem på udfordringerne i den professionelle sportsverden.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  




<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Sportslegater og fremtiden for atleter
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sportslegater tilbyder talrige muligheder for unge atleter til at vise deres talent og udvikle deres færdigheder. En af de mest betydningsfulde muligheder er at opnå et sportslegat for at studere og konkurrere på kollegialt niveau. I dette kapitel vil vi diskutere vigtigheden af sportslegater og hvordan Sport Legater Amerika kan hjælpe dig med at realisere din drøm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vigtigheden af legater
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et sportslegat er økonomisk støtte, som et universitet tilbyder til en studerende-atlet for at kombinere studier og sport. Det kan dække omkostningerne til undervisning, bøger, bolig, måltider og andre udgifter. Derudover kan det også tilbyde fordele såsom akademisk vejledning, lægehjælp, træningsfaciliteter og udstyr.
      <br />
      <br />
      At opnå et sportslegat er konkurrencepræget, hvor mange faktorer spiller en rolle i tildelingsprocessen, herunder akademiske præstationer, atletisk præstation, personlighed, motivation, lederskab og tilpasningsevne. Derfor er det vigtigt at forberede sig godt og adskille sig fra konkurrencen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Legater Amerika Kan Hjælpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo af Sport Legater Amerika, der viser sportsudstyr og en eksamenshue, der repræsenterer fokus på sportslegater for studerende-atleter."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo af Sport Legater Amerika
        </figcaption>
      </figure>
      Sport Legater Amerika er en organisation specialiseret i at guide unge sports talenter til sportslegater. Med passionerede og erfarne eksperter støtter Sport Legater Amerika studerende-atleter på hvert trin af rejsen, fra indledende orientering til endelig placering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intake-interview for at diskutere den studerende-athlets ønsker, muligheder og forventninger.</li>
        <li>En professionel evaluering af den studerende-athlets atletiske og akademiske niveau.</li>
        <li>Et omfattende netværk af kontakter med trænere, universiteter og kollegier.</li>
        <li>Effektiv markedsføring af den studerende-athlets profil og videoer til potentielle trænere.</li>
        <li>Ekspertrådgivning om de bedste muligheder baseret på den studerende-athlets præferencer, mål og budget.</li>
        <li>Forhandling af de bedste legat tilbud på vegne af den studerende-atlet.</li>
        <li>Hjælp til ansøgning om nødvendige dokumenter, såsom visum, transkript, eksamensbevis og optagelsesprøver.</li>
        <li>Forberedelse til den studerende-atlets liv under deres legat, herunder kultur, sprog, regler og tips.</li>
        <li>Efterpleje og opfølgning under legatperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ønsker du at udforske legatmuligheder og opnå dine sportslige og akademiske mål? <Link to="/da/Tjenester-Produkter">Se det fulde udvalg</Link> af tjenester tilbudt af Sport Legater Amerika og planlæg en konsultation i dag.
    </MKTypography>
  </MKBox>
</MKBox>



          
          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;