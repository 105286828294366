import React, { useState } from 'react';
import jsonp from 'jsonp';

// @mui materialkomponenter
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

// Material Kit 2 PRO React-komponenter
import MKBox from 'components/MKBox';
import MKButton from 'components/MKButton';
import MKTypography from 'components/MKTypography';

function Nyhetsbrev() {
  const [epost, setEpost] = useState('');
  const [melding, setMelding] = useState('');
  const [status, setStatus] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    const url =
      'https://gmail.us21.list-manage.com/subscribe/post-json?u=beee14e4f9b951d6808340453&id=e3e7c3e834&f_id=00f4eee6f0';

    jsonp(`${url}&EMAIL=${epost}`, { param: 'c' }, (err, data) => {
      if (err) {
        console.error(err);
        setMelding('Noe gikk galt. Prøv igjen senere.');
        setStatus('error');
      } else if (data.result !== 'success') {
        setMelding(data.msg);
        setStatus('error');
      } else {
        setMelding('Du har blitt vellykket abonnert!');
        setStatus('success');
        setEpost('');
      }
    });
  };

  return (
    <MKBox component="section" py={8}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={5} mr="auto">
            <MKTypography variant="h4" mb={1}>
              Hold deg oppdatert!
            </MKTypography>
            <MKTypography variant="body2" color="text">
              Abonner på nyhetsbrevet og motta de nyeste nyhetene, oppdateringene og intervjuene direkte i innboksen din!
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6} flexDirection="column" justifyContent="center" ml="auto">
            <MKBox component="form" method="" action="" onSubmit={onSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                  <TextField
                    label="Din e-postadresse..."
                    fullWidth
                    variant="outlined"
                    value={epost}
                    onChange={(e) => setEpost(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MKButton type="submit" variant="gradient" color="info" fullWidth sx={{ height: '100%' }}>
                    Abonner
                  </MKButton>
                </Grid>
              </Grid>
              {melding && (
                <MKTypography variant="body2" color={status === 'success' ? 'success' : 'error'} mt={2}>
                  {melding}
                </MKTypography>
              )}
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Nyhetsbrev;
