/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Baseball1.jpg";
import Image2 from "assets/images/Sports_Images/Baseball2.jpeg";
import Image3 from "assets/images/Sports_Images/Baseball3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';

import "../../../../H1asH3.css"

const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Baseball och Studier i Amerika: En Dröm Som Kan Bli Verklighet', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapitel 1: Historien och Utvecklingen av Collegbaseboll', isSubheader: false },
    { id: '1.1', title: '1.1 Tidiga År och Rollen av Colleg', isSubheader: true },
    { id: '1.2', title: '1.2 Tillväxt och Professionalisering', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapitel 2: Collegbaseboll Idag', isSubheader: false },
    { id: '2.1', title: '2.1 Stora Ligor och Konferenser', isSubheader: true },
    { id: '2.2', title: '2.2 Toppprogram och Prestigefyllda Lag', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapitel 3: Från College till Proffsen', isSubheader: false },
    { id: '3.1', title: '3.1 Vägen till Professionalism', isSubheader: true },
    { id: '3.2', title: '3.2 MLB-draften och Andra Professionella Möjligheter', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapitel 4: Internationella Influenser', isSubheader: false },
    { id: '4.1', title: '4.1 Utländska Spelare i Collegbaseboll', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikaner Utomlands', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapitel 5: Idrottsstipendier och Framtiden för Idrottare', isSubheader: false },
    { id: '5.1', title: '5.1 Vikten av Stipendier', isSubheader: true },
    { id: '5.2', title: '5.2 Hur Sport Scholarships America Kan Hjälpa', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innehåll
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Dölj</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Visa</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>





















          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Baseball och Studier i Amerika: En Dröm Som Kan Bli Verklighet
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Flygvy över stadion under College World Series."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        College World Series
      </figcaption>
    </figure>
    Baseboll är en av de mest populära och betydande sporterna i USA. Miljontals människor följer Major League Baseball (MLB), den högsta professionella basebolligan i världen. Men innan du kan komma till MLB måste du först utveckla och bevisa dina färdigheter på en annan nivå: collegbaseboll.
    <br />
    <br />
    Collegbaseboll är baseboll som spelas av studenter vid amerikanska universitet och högskolor. Det är en mycket konkurrenskraftig och prestigefylld sport som lockar mycket uppmärksamhet och talang. Collegbaseboll tjänar som ett avgörande steg för spelare som vill spela professionellt och också som en möjlighet att få en bra utbildning.
    <br />
    <br />
    Men hur kommer man in på collegbaseboll? Och hur kan du få ett stipendium för att studera och spela i USA? Det är här Sportbeursamerika.nl kan hjälpa dig. Sportbeursamerika.nl är en organisation som är specialiserad på att vägleda och stödja idrottande studenter som vill förverkliga sina drömmar. Jag kan hjälpa dig med allt du behöver för att hitta ett lämpligt universitet, få ett stipendium och förbereda dig för livet i USA.
    <br />
    <br />
    I den här artikeln kommer vi att berätta allt du behöver veta om collegbaseboll i USA. Vi kommer att fördjupa oss i historien och utvecklingen av denna sport, de viktigaste ligorna och konferenserna, toppprogram och prestigefyllda lag, vägen till proffsen, internationella influenser och naturligtvis vikten av stipendier. Efter att ha läst den här artikeln kommer du att ha en bättre förståelse för vad collegbaseboll innebär och hur du kan öka dina chanser att vara en del av det.
    <br />
    <br />
    Är du redo att förverkliga din dröm? Då kör vi!
  </MKTypography>
</MKBox>

<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Historien och Utvecklingen av Collegbaseboll
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Baseboll är en sport som har funnits i över ett sekel. Men hur uppstod den och växte till att bli en av de mest populära och betydande sporterna i USA? I det här kapitlet kommer vi att ge dig en kort översikt över historien och utvecklingen av collegbaseboll.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidiga År och Rollen av Colleg
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ursprunget till baseboll är inte helt klart. Det finns olika teorier om hur och var spelet började. Vissa säger att det härstammar från antika europeiska spel som cricket, rounders eller stoolball. Andra hävdar att det härstammar från ett ursprungligt amerikanskt spel som kallas lacrosse. Ändå föreslår andra att det uppfanns av en amerikansk soldat vid namn Abner Doubleday år 1839.
      <br />
      <br />
      Vad som är säkert är att baseboll snabbt blev populärt i USA, särskilt i östkuststäder. Den spelades av amatörer, klubbar, fabriksarbetare och soldater. Den spelades också av studenter vid universitet och högskolor, som hade sina egna lag och regler.
      <br />
      <br />
      Den första kända intercollegiate basebollmatchen i USA ägde rum år 1859 mellan Amherst College och Williams College, två respekterade akademiska institutioner i Massachusetts. Denna match är historiskt signifikant som ett av de tidigaste exemplen på collegebasebollmatcher, en föregångare till senare utveckling av collegbaseboll. Även om det exakta resultatet och förloppet för matchen kan variera i historiska källor, markerar detta evenemang en viktig startpunkt i historien om intercollegiate baseboll. Det illustrerar hur baseboll blev populärt bland studenter i de tidiga dagarna och hur universitet blev viktiga arenor för sportens tillväxt och utveckling i USA.
      <br />
      <br />
      På 1860-talet och 1870-talet växte antalet collegelag stadigt. Några av de tidigaste lagen inkluderade Harvard, Yale, Princeton, Dartmouth, Brown och Columbia. Dessa lag spelade främst mot varandra eller mot lokala klubbar.
      <br />
      <br />
      År 1879 hölls den första intercollegiate basebollkonferensen i Springfield, Massachusetts. Målet var att standardisera och förbättra reglerna och standarderna för spelet. Konferensen ledde till bildandet av American College Baseball Association, där sex prestigefyllda utbildningsinstitutioner - Harvard, Yale, Princeton, Amherst, Dartmouth och Brown - var delaktiga. Detta markerade början på en lång tradition av nationella mästerskap i collegbaseboll.
    </MKTypography>
  </MKBox>

  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Tillväxt och Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      I slutet av 1800-talet och början av 1900-talet blev baseboll alltmer populärt och professionellt i USA. National League (NL) och American League (AL) etablerades som de främsta professionella basebolligorna och lockade många spelare, fans och pengar.
      <br />
      <br />
      Detta hade också en inverkan på collegbaseboll. Många collegespelare rekryterades eller skrevs kontrakt med professionella lag. Vissa lämnade sina universitet för att spela professionellt, medan andra fortsatte sina studier men spelade också deltid för professionella lag.
      <br />
      <br />
      Detta ledde till olika problem och konflikter inom collegbaseboll. Vissa universitet motsatte sig tanken på att deras spelare fick betalt för att spela, eftersom de ansåg att det stred mot den amatörmässiga andan och den akademiska integriteten hos collegidrotten. De ville också behålla sina bästa spelare för sina egna lag.
      <br />
      <br />
      Andra universitet var mer toleranta eller pragmatiska angående fenomenet med professionella spelare. De insåg att baseboll var en lukrativ och prestigefylld sport som kunde erbjuda många fördelar åt deras institutioner. De ville också konkurrera med andra universitet på högsta nivå.
      <br />
      <br />
      För att hantera dessa problem vidtogs olika åtgärder av olika organisationer. IBA ersattes av National Collegiate Athletic Association (NCAA) år 1906. NCAA tog på sig ansvaret för att reglera och organisera collegbaseboll och andra sporter. NCAA etablerade också regler och riktlinjer för collegespelares berättigande och beteende.
      <br />
      <br />
      NCAA organiserade också den första College World Series (CWS) år 1947. Detta var det nya nationella mästerskapet för collegbaseboll, som hölls årligen i Omaha, Nebraska. CWS blev snabbt en av de mest prestigefyllda och populära händelserna inom collegidrott.
      <br />
      <br />
      Förutom NCAA fanns det andra organisationer involverade i collegbaseboll. En av dem var National Association of Intercollegiate Athletics (NAIA), som grundades år 1937. NAIA var ett alternativ till NCAA och fokuserade på mindre universitet och högskolor. NAIA organiserade också sitt eget nationella mästerskap för collegbaseboll.
      <br />
      <br />
      En annan organisation var National Junior College Athletic Association (NJCAA), som grundades år 1938. NJCAA var en organisation för community colleges som erbjöd tvååriga program. NJCAA organiserade också sitt eget nationella mästerskap för collegbaseboll.
      <br />
      <br />
      Dessa organisationer bidrog alla till tillväxten och professionaliseringen av collegbaseboll. De skapade fler möjligheter och mångfald för spelare, tränare, skolor och fans. De gjorde också collegbaseboll till en integrerad del av det amerikanska basebollsystemet.
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  






<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Collegbaseboll Idag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Collegbaseboll är en av de mest populära och betydande sporterna i USA idag. Den lockar miljontals fans, medial uppmärksamhet och sponsorer. Den producerar tusentals spelare, tränare och funktionärer. Den erbjuder hundratals stipendier, utmärkelser och hedersutmärkelser.
    <br />
    <br />
    Men hur ser collegbaseboll ut idag? Hur är systemet organiserat? Vilka är de stora ligorna och konferenserna? Vilka är de bästa programmen och prestigefyllda lagen? I det här kapitlet kommer vi att ge dig en översikt över collegbaseboll idag.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Stora Ligor och Konferenser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Barry Bonds i aktion för Arizona State i collegbaseboll."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Barry Bonds som spelare för Arizona State
        </figcaption>
      </figure>
      Som vi såg i det föregående kapitlet är flera organisationer involverade i collegbaseboll. Den viktigaste av dessa är NCAA, som representerar över 1 200 universitet och högskolor. NCAA är uppdelad i tre divisioner: Division I, Division II och Division III.
      <br />
      <br />
      Division I är den högsta nivån av collegbaseboll och består av cirka 300 skolor, uppdelade i 31 konferenser. Varje konferens har sina egna regler, scheman och mästerskap. De mest kända konferenserna inkluderar Atlantic Coast Conference (ACC), Big Ten Conference (B1G), Big 12 Conference (B12), Pac-12 Conference (P12) och Southeastern Conference (SEC).
      <br />
      <br />
      Division II är mellannivån av collegbaseboll och består av cirka 270 skolor, uppdelade i 23 konferenser. Konferenser är vanligtvis regionalt orienterade, som California Collegiate Athletic Association (CCAA), Gulf South Conference (GSC) och Northeast-10 Conference (NE10).
      <br />
      <br />
      Division III är den lägsta nivån av collegbaseboll och består av cirka 380 skolor, uppdelade i 43 konferenser. Konferenser är också vanligtvis regionalt orienterade, som New England Small College Athletic Conference (NESCAC), Southern California Intercollegiate Athletic Conference (SCIAC) och Wisconsin Intercollegiate Athletic Conference (WIAC).
      <br />
      <br />
      Förutom NCAA finns det andra organisationer som är involverade i collegbaseboll. En av dem är NAIA, som representerar cirka 190 skolor. NAIA är uppdelad i 21 konferenser, som American Midwest Conference (AMC), Golden State Athletic Conference (GSAC) och Heart of America Athletic Conference (HAAC).
      <br />
      <br />
      En annan organisation är NJCAA, som representerar cirka 500 community colleges. NJCAA är uppdelad i tre divisioner: Division I, Division II och Division III. Varje division är ytterligare uppdelad i distrikt och regioner.
      <br />
      <br />
      Dessa organisationer håller sina egna nationella mästerskap för collegbaseboll varje år. NCAA Division I-mästerskapet är det mest kända och prestigefyllda evenemanget, ofta kallat College World Series (CWS).
      <br />
      <br />
      CWS består av en dubbel-eliminerings-turnering med 64 lag som väljs utifrån deras prestation under ordinarie säsong och konferensmästerskap. Lagen är uppdelade i 16 regionala grupper om fyra lag var. Vinnarna av varje regionala grupp går vidare till super regional-rundan, där de tävlar mot ett annat lag i en bäst av tre-serie. Vinnarna av varje super regional-runda går vidare till CWS-finalturneringen, som består av två grupper om fyra lag var. Vinnarna av varje grupp möts i en bäst av tre-serie för att avgöra mästaren.
      <br />
      <br />
      CWS-finalturneringen hålls årligen i juni i Omaha, Nebraska. Den anses vara en av de största och mest spännande händelserna inom collegidrott och lockar tusentals fans, medier och scouter.
      <br />
      <br />
      NCAA Division II- och Division III-mästerskapen följer ett liknande format men med färre lag. NAIA- och NJCAA-mästerskapen har också sina egna format, som varierar beroende på antalet deltagande lag.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Toppprogram och Prestigefyllda Lag
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Collegbaseboll är en högt konkurrenskraftig och prestigefylld sport med en rik tradition och talangpool. Det finns många program och lag som utmärker sig genom sin framgång och sitt rykte. Några av dessa program och lag inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Texas Longhorns: Basebollaget vid University of Texas i Austin, som tävlar i Big 12 Conference. Det här programmet har en imponerande meritlista med många framträdanden i College World Series och flera mästerskap. Några kända alumner inkluderar Roger Clemens, Burt Hooton och Huston Street.
        </li>
        <li>
          LSU Tigers: Basebollaget vid Louisiana State University i Baton Rouge, aktiv i SEC. LSU är känt för sin dominans inom collegbaseboll och har vunnit flera mästerskap. Några kända alumner inkluderar Albert Belle, Aaron Hill och DJ LeMahieu.
        </li>
        <li>
          USC Trojans: Basebollaget vid University of Southern California i Los Angeles, medlem av Pac-12 Conference. USC har en rik tradition med många mästerskap och har producerat många talangfulla spelare. Några berömda alumner inkluderar Tom Seaver, Randy Johnson och Mark McGwire.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Det här är bara några exempel på toppprogram och prestigefyllda lag inom collegbaseboll. Det finns många fler program och lag, var och en med sin egen historia, kultur och identitet.
      <br />
      <br />
      Dessa program och lag bidrar till spelarnas utveckling på olika sätt. De ger dem hög nivå av coachning, träning, faciliteter och konkurrens. De hjälper också spelarna att uppnå sina akademiska, idrottsliga och personliga mål.
    </MKTypography>
  </MKBox>
</MKBox>






                                  







<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Från College till Proffsen
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Collegbaseboll är inte bara en sport; det är också en väg till professionalism. Många collegbasebollspelare har ambitionen att spela professionellt, antingen i MLB eller andra professionella ligor. Men hur ser den vägen ut? Hur kan du gå från college till proffsen? I det här kapitlet ger vi dig en översikt över vägen till professionalism inom baseboll.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Vägen till Professionalism
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vägen till professionalism inom baseboll börjar vanligtvis med MLB-draften. MLB-draften är en årlig händelse där de 30 MLB-lagen tar tur att välja spelare från olika källor, inklusive collegbaseboll, high school-baseboll, internationell baseboll och oberoende baseboll.
      <br />
      <br />
      MLB-draften består av 40 omgångar, och varje lag får välja en spelare per omgång. Ordningen för valen bestäms av det omvända ställningen från föregående MLB-säsong, med vissa undantag för kompensationsval och lotterival.
      <br />
      <br />
      MLB-draften är en betydande möjlighet för collegbasebollsspelare att börja sina professionella karriärer. Ungefär 1 200 spelare väljs i MLB-draften varje år, och cirka 60% kommer från collegbaseboll.
      <br />
      <br />
      Om du väljs i MLB-draften har du två alternativ: du kan skriva på ett kontrakt med det lag som valde dig, eller så kan du välja att inte skriva på och återvända till ditt universitet eller en annan källa.
      <br />
      <br />
      Om du väljer att skriva på ett kontrakt får du en signaturbonus baserad på den omgång du valdes i. Bonusar varierar från flera miljoner dollar för första omgången till flera tusen dollar för senare omgångar.
      <br />
      <br />
      När du skriver på ett kontrakt kommer du att tilldelas en av minor league-lagen hos det lag som valde dig. Minor league är de lägre nivåerna av professionell baseboll och fungerar som ett utvecklingssystem för MLB. Det finns sex nivåer i minor league: Rookie, Class A Short Season, Class A, Class A Advanced, Double-A och Triple-A.
      <br />
      <br />
      Under tiden du spelar i minor league måste du förbättra dina färdigheter och visa din prestanda för att avancera till högre nivåer. Du måste också konkurrera med andra spelare som delar samma dröm: att nå de stora ligorna.
      <br />
      <br />
      Att nå de stora ligorna är det ultimata målet för varje professionell basebollsspelare. Det är den högsta nivån av professionell baseboll, där du kan tävla mot världens bästa spelare, inför de största fansen och för de högsta lönerna.
      <br />
      <br />
      Att komma till de stora ligorna är dock inte lätt. Endast cirka 10% av spelarna som väljs i MLB-draften når slutligen de stora ligorna, och endast cirka 1% av dem som väljs har långa och framgångsrika karriärer i de stora ligorna.
      <br />
      <br />
      Så vägen till professionalism inom baseboll är en lång och utmanande resa som kräver engagemang, hårt arbete och lite tur. Men det är också en väg som kan erbjuda många belöningar, möjligheter och drömmar.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 MLB-draften och Andra Professionella Möjligheter
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      MLB-draften är inte det enda sättet att spela professionellt i baseboll. Det finns också andra professionella möjligheter utanför MLB som kan vara attraktiva för collegbasebollsspelare.
      <br />
      <br />
      En av dem är den oberoende ligan. Den oberoende ligan är en professionell basebolliga som inte är ansluten till MLB eller minor league. Den oberoende ligan består av olika ligor, som Atlantic League, American Association och Frontier League.
      <br />
      <br />
      Den oberoende ligan ger ett alternativ för spelare som inte väljs i MLB-draften eller inte får kontraktserbjudanden från ett MLB-lag. Den oberoende ligan erbjuder också en chans för spelare som släpps från minor league eller vill förlänga sina karriärer.
      <br />
      <br />
      Den oberoende ligan har en lägre nivå av spel och betalning jämfört med MLB eller minor league, men den erbjuder också en högre nivå av frihet och njutning. Den oberoende ligan är känd för sin kreativitet, mångfald och experiment. Den har också producerat några spelare som slutligen nådde de stora ligorna, som Jose Bautista, Rich Hill och Max Muncy.
      <br />
      <br />
      En annan professionell möjlighet är den internationella ligan. Den internationella ligan är en samlingsbeteckning för professionella basebolligor som spelas utanför USA. Den internationella ligan består av olika ligor, som Nippon Professional Baseball (NPB) i Japan, Korean Baseball Organization (KBO) i Sydkorea, Chinese Professional Baseball League (CPBL) i Taiwan och Mexican Baseball League (LMB) i Mexiko.
      <br />
      <br />
      Den internationella ligan erbjuder en möjlighet för spelare som vill bredda sina vyer och uppleva en annan kultur. Den internationella ligan ger också en möjlighet för spelare som söker en högre nivå av spel och betalning än den oberoende ligan men kanske inte kan nå MLB eller minor league.
      <br />
      <br />
      Det här är bara några exempel på professionella möjligheter utanför MLB. Det finns många fler möjligheter, som vinterligor, collegsommarligor och amatörligor.
      <br />
      <br />
      Dessa möjligheter erbjuder alla olika fördelar och nackdelar för collegbasebollsspelare. De erbjuder olika nivåer av konkurrens, belöning, utmaning och njutning. De erbjuder olika vägar till professionalism inom baseboll.
    </MKTypography>
  </MKBox>
</MKBox>





                                  







<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationella Influenser
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Collegbaseboll är inte bara en amerikansk sport; det är också en internationell sport påverkad av spelare, tränare och fans från olika länder och kulturer. I det här kapitlet ger vi dig en översikt över de internationella influenserna i collegbaseboll.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Utländska Spelare i Collegbaseboll
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="USC basebollag firar en seger."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          USC-spelare firar en seger
        </figcaption>
      </figure>
      En av de mest betydande internationella influenserna i collegbaseboll är närvaron av utländska spelare. Utländska spelare är spelare som inte är födda i USA eller inte har amerikanskt medborgarskap. De kommer från olika länder, som Kanada, Mexiko, Japan, Sydkorea, Taiwan, Kuba, Venezuela, Dominikanska republiken och Nederländerna.
      <br />
      <br />
      Utländska spelare spelar en avgörande roll i collegbaseboll. De tar med sig sina egna färdigheter, stilar och perspektiv till spelet. De berikar mångfalden och kvaliteten på collegbaseboll. De ökar också den internationella igenkännandet och attraktionen för collegbaseboll.
      <br />
      <br />
      Det finns många framgångshistorier om utländska spelare som har lyckats i den amerikanska collegbasebollvärlden. Ett framstående exempel är Nomar Garciaparra. Nomar Garciaparra var en mexikansk-amerikansk shortstop som spelade för Georgia Institute of Technology. Han valdes i första rundan av MLB-draften 1994 av Boston Red Sox. Han spelade 14 säsonger i MLB, där han blev en All-Star sex gånger och vann AL-battingtiteln två gånger. Det finns många fler exempel på utländska spelare som har gjort avtryck i collegbaseboll.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikaner Utomlands
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      En annan internationell influens i collegbaseboll är närvaron av amerikaner utomlands. Amerikaner utomlands är spelare som är födda i USA eller har amerikanskt medborgarskap men spelar eller har spelat i professionella ligor utanför USA.
      <br />
      <br />
      Amerikaner utomlands spelar också en betydande roll i collegbaseboll. De tar med sig sina egna erfarenheter, kunskaper och nätverk till spelet. De lär sig också från andra kulturer och stilar av baseboll. De förbättrar internationellt utbyte och samarbete i collegbaseboll.
      <br />
      <br />
      Dessa möjligheter erbjuder många fördelar för amerikanska collegbasebollsspelare. De ger en högre nivå av konkurrens, exponering och belöning. De hjälper också till att vidga deras vyer, öka anpassningsförmågan och utvidga deras nätverk.
      <br />
      <br />
      Internationell erfarenhet kan vara fördelaktig för en spelares utveckling. Det kan hjälpa dem att lära sig nya färdigheter, tekniker och strategier. Det kan också hjälpa dem att förbättra sina svagheter, utnyttja sina styrkor och maximera sitt potential.
    </MKTypography>
  </MKBox>
</MKBox>






                                  






<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Idrottsstipendier och Framtiden för Idrottare
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrottsstipendier erbjuder många möjligheter för unga idrottare att visa upp sin talang och utveckla sina färdigheter. En av de mest betydelsefulla möjligheterna är att få ett idrottsstipendium för att studera och tävla på collegenivå. I det här kapitlet kommer vi att diskutera vikten av idrottsstipendier och hur Sport Scholarships America kan hjälpa dig att förverkliga din dröm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vikten av Stipendier
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ett idrottsstipendium är ekonomiskt stöd som tillhandahålls av ett universitet till en idrottare för att kombinera studier och idrott. Det kan täcka kostnaderna för undervisning, böcker, boende, måltider och andra utgifter. Dessutom kan det erbjuda fördelar som akademisk handledning, sjukvård, träningsanläggningar och utrustning.
      <br />
      <br />
      Att få ett idrottsstipendium är konkurrenskraftigt, med många faktorer som spelar en roll i tilldelningsprocessen, inklusive akademiska prestationer, idrottsprestationer, personlighet, motivation, ledarskap och anpassningsförmåga. Därför är det viktigt att förbereda sig väl och skilja sig från konkurrensen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hur Sport Scholarships America Kan Hjälpa
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotyp för Sport Scholarships America, med sportutrustning och en studentmössa, som representerar fokus på idrottsstipendier för idrottare."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotyp för Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America är en organisation specialiserad på att vägleda unga idrottstalanger till idrottsstipendier. Med passionerade och erfarna experter stöder Sport Scholarships America idrottare i varje steg av resan, från initial orientering till slutlig placering. Deras tjänster inkluderar:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intagningsintervju för att diskutera idrottarens önskemål, möjligheter och förväntningar.</li>
        <li>En professionell utvärdering av idrottarens akademiska och idrottsliga nivå.</li>
        <li>Ett omfattande nätverk av kontakter med tränare, universitet och högskolor.</li>
        <li>Effektiv marknadsföring av idrottarens profil och videor till potentiella tränare.</li>
        <li>Expertrådgivning om de bästa alternativen baserat på idrottarens preferenser, mål och budget.</li>
        <li>Förhandling om de bästa stipendieerbjudandena för idrottaren.</li>
        <li>Hjälp med att ansöka om nödvändiga dokument, såsom visum, betyg, examensbevis och antagningsprov.</li>
        <li>Förberedelse för idrottarens liv under stipendieperioden, inklusive kultur, språk, regler och tips.</li>
        <li>Uppföljning och uppföljning under stipendieperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vill du utforska möjligheter till stipendier och uppnå dina idrottsliga och akademiska mål? <Link to="/sv/Tjänster-Produkter">Se det fullständiga utbudet</Link> av tjänster som erbjuds av Sport Scholarships America och boka en konsultation idag.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;