/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================
* Page du produit : https://www.creative-tim.com/product/material-kit-pro-react
* Droits d'auteur 2023 Creative Tim (https://www.creative-tim.com)
Codé par www.creative-tim.com
=========================================================
*/

// Composants matériels @mui
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Composants Material Kit 2 PRO React
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post2 from "assets/images/TijdUS.png";
import "../../../../H1asH3.css"

function Profil() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center" />
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} justifyContent="space-between" alignItems="center" mb={1}>
                <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Partie 1: Grandir aux Pays-Bas</MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text">
  <br />
  Dans l'environnement enchanteur de Nijmegen, a commencé mon histoire d'amour de toute une vie avec le football. Enfant des rues de Nijmegen, j'ai ressenti la magie du sport à chaque pas que je faisais sur l'herbe verte. Mon premier club était BVC '12, où en tant que jeune, j'ai vécu mes premiers dribbles et buts. C'était une époque d'innocence, où j'ai découvert ma passion pour le sport et où les bases de mon avenir ont été posées.
  <br /><br />

  Après mon aventure à BVC '12, j'ai entrepris un voyage à travers des clubs de football locaux tels que Quick, NEC et Union. Ce voyage a jeté les bases de ma formation de football et m'a rapproché de mon rêve : atteindre le sommet du football des jeunes aux Pays-Bas. En tant que jeune footballeur, je rêvais de jouer dans de grands stades, entouré par une mer de fans en liesse. C'était un rêve partagé par de nombreux jeunes talents aux Pays-Bas, et j'étais déterminé à tout faire pour que ce rêve devienne réalité.
  <br /><br />

  Mais à mesure que je développais mon talent pour le football, je commençais aussi à réaliser à quel point une bonne éducation était cruciale pour un avenir solide. Aux Pays-Bas, mes ambitions dans le football et l'éducation semblaient parfois contradictoires. Équilibrer ces deux passions était un défi.
  <br /><br />

  La diversité étincelante et les énormes opportunités de l'Amérique m'avaient toujours fasciné, et cette fascination ne faisait que croître lors d'un voyage avec ma famille. J'étais presque sûr que je retournerais dans ce pays, peut-être pour une période prolongée. Plus tard, par le biais de connaissances, j'ai entendu des histoires sur les opportunités de combiner une carrière dans le football avec des études universitaires en Amérique, et mon désir de suivre le même chemin a rapidement augmenté.
  <br /><br />

  Cependant, ce n'était pas facile à organiser. Le processus complexe des bourses sportives et des opportunités d'études en Amérique semblait initialement intimidant. Quelque part, je savais que cela pourrait être plus simple et accessible, ce qui a finalement été ma motivation pour créer cette entreprise des années plus tard.
  <br /><br />

  Après avoir contacté différents entraîneurs, l'entraîneur de l'Université de Tulsa avait une histoire particulièrement convaincante à partager. Il parlait d'une équipe qui compétait toujours pour des prix, constamment classée parmi les 25 meilleures du pays et participant à de grandes compétitions. Ses paroles m'ont donné la confiance que c'était l'endroit où je pouvais réaliser à la fois mes rêves de football et mes ambitions académiques.
  <br /><br />

  Et ainsi, deux semaines après avoir eu 18 ans, mon chapitre aux Pays-Bas était (temporairement) terminé. J'ai embarqué dans l'avion pour l'Amérique, prêt pour l'aventure qui m'attendait. Déterminé à réunir mes passions pour le football et l'éducation, j'étais au début d'un voyage excitant. Je serais ravi de partager davantage sur mes expériences en Amérique dans la partie 2 de mon histoire, où je plongerai plus en profondeur dans mes aventures aux États-Unis.
</MKTypography>
<hr style={{ margin: '20px 0'}} />

<MKTypography component="a" href="#" variant="body1" fontWeight="light" color="info" mt={3}>
  <Grid container justifyContent="flex-end"> {/* Cette ligne a été mise à jour */}
    <Grid item xs={12} lg={6} mb={{ xs: 3, lg: 0 }}>
      <TransparentBlogCard
        image={post2}
        title="Partie 2: Mon Temps en Amérique"
        action={{
          type: "internal",
          route: "/fr/À-propos-de-moi/Mon-temps-en-Amérique",
          color: "info",
          label: "lire la suite",
        }}
      />
    </Grid>
  </Grid>
</MKTypography>

</Grid>
</Grid>
</Grid>
</Container>
</MKBox>
);
}

export default Profil;
