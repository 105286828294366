/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Hockey1.jpg";
import Image2 from "assets/images/Sports_Images/Hockey2.jpeg";
import Image3 from "assets/images/Sports_Images/Hockey3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");




  
  
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Borse di Studio per l\'Hockey su Prato in America: Come l\'Hockey su Prato può Aiutarti a Realizzare i Tuoi Sogni', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capitolo 1: La Storia e lo Sviluppo dell\'Hockey su Prato in America', isSubheader: false },
    { id: '1.1', title: '1.1 Storia Antica', isSubheader: true },
    { id: '1.2', title: '1.2 Sviluppo e Professionalizzazione', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capitolo 2: Hockey su Prato a Livello Universitario', isSubheader: false },
    { id: '2.1', title: '2.1 Competizioni e Conferenze', isSubheader: true },
    { id: '2.2', title: '2.2 Impatto sullo Sviluppo dei Giocatori', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capitolo 3: Transizione dall\'Università al Professionismo', isSubheader: false },
    { id: '3.1', title: '3.1 Dal College ai Professionisti', isSubheader: true },
    { id: '3.2', title: '3.2 Opportunità Professionali e Leghe', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capitolo 4: Influenze e Opportunità Internazionali', isSubheader: false },
    { id: '4.1', title: '4.1 Influenze Globali', isSubheader: true },
    { id: '4.2', title: '4.2 Gli Americani sulla Scena Mondiale', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capitolo 5: Borse di Studio Sportive e il Futuro degli Atleti', isSubheader: false },
    { id: '5.1', title: '5.1 L\'Importanza delle Borse di Studio', isSubheader: true },
    { id: '5.2', title: '5.2 Come Sport Scholarships America Può Aiutare', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  const [,] = useState("");


  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indice
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Nascondi</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostra</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Borse di Studio per l'Hockey su Prato in America: Come l'Hockey su Prato può Aiutarti a Realizzare i Tuoi Sogni
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Giocatore in azione durante una partita di hockey su prato al college Colgate."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
      Giocatore in azione durante una partita
      </figcaption>
    </figure>

    L'hockey su prato è uno degli sport in più rapida crescita in America. Sempre più giovani atleti stanno scoprendo i benefici e il divertimento di questo sport dinamico e stimolante. Ma sapevi che l'hockey su prato può anche aiutarti a raggiungere i tuoi obiettivi accademici e professionali?
    <br />
    <br />

    Se hai una passione per l'hockey su prato, hai un'opportunità unica di giocare a livello universitario in America. Ciò significa che puoi studiare presso una delle migliori università al mondo mentre sviluppi le tue capacità atletiche sotto la guida professionale. Inoltre, potresti essere idoneo per una borsa di studio sportiva, che fornisce supporto finanziario per la tua istruzione.

    <br />
    <br />
    Ma come si ottiene una tale borsa di studio? E come ci si prepara a giocare a hockey su prato in America? È qui che Sportbeursamerika.nl può assisterti. Sportbeursamerika.nl è un'agenzia specializzata che aiuta i giovani talenti a realizzare il loro sogno di studiare e giocare a hockey in America. Offro assistenza personale, consigli e supporto nella ricerca dell'università giusta, nell'applicazione per una borsa di studio, nella gestione di tutte le questioni pratiche e nell'integrazione nella cultura americana.
    <br />
    <br />
    In questo articolo, ti parlerò di più sulla storia e lo sviluppo dell'hockey su prato in America, sulle opportunità e i benefici del giocare a livello universitario, sulla transizione dal college al gioco professionistico, sulle influenze e opportunità internazionali all'interno dello sport e, naturalmente, sull'importanza delle borse di studio e il ruolo di Sportbeursamerika.nl. Dopo aver letto questo articolo, avrai una migliore comprensione di cosa comporti l'hockey su prato in America e come prepararti per questa sfida entusiasmante.
    <br />
    <br />
    Sei pronto per saperne di più sull'hockey su prato in America? Continua a leggere!
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 1: La Storia e lo Sviluppo dell'Hockey su Prato in America
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    L'hockey su prato è uno sport con una lunga e ricca storia. Ma come è nato e cresciuto in America? In questo capitolo, ti porteremo in un viaggio nel tempo, dall'introduzione precoce alla attuale professionalizzazione dello sport.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
       1.1 Storia Antica
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      L'hockey su prato è stato introdotto negli Stati Uniti alla fine del XIX secolo dagli immigrati britannici e dai turisti. Il gioco ha rapidamente guadagnato popolarità tra le donne, che lo vedevano come un modo per fare esercizio e socializzare. Le prime squadre universitarie sono state formate nel 1901 al Vassar College di New York e al Bryn Mawr College in Pennsylvania. Queste squadre giocavano principalmente l'una contro l'altra o contro club locali.
      <br />
      <br />
      Nel 1922 è stata fondata la United States Field Hockey Association (USFHA) come organizzazione nazionale per lo sport. La USFHA ha organizzato i primi campionati nazionali per donne nel 1925 e per uomini nel 1930. La USFHA era anche responsabile della selezione e dell'addestramento delle nazionali che competevano in tornei internazionali come le Olimpiadi e i Campionati del Mondo.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Sviluppo e Professionalizzazione
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Nella seconda metà del XX secolo, l'hockey su prato ha continuato a crescere in popolarità e qualità in America. Il gioco si è diffuso ulteriormente in tutto il paese, in particolare nel Nord-Est, nel Midwest e nell'Ovest. Il numero di squadre universitarie è aumentato, e sono state formate più leghe e conferenze. Alcuni dei programmi più prestigiosi e competitivi includono Princeton University, University of Maryland, University of North Carolina, Stanford University e Harvard University.
      <br />
      <br />
      Il livello dell'hockey su prato universitario è anche aumentato grazie a strutture, allenatori e reclutamento migliorati. Molte università offrono ora borse di studio sportive ai talentuosi giocatori di hockey su prato, permettendo loro di studiare senza preoccupazioni finanziarie. Inoltre, l'hockey su prato universitario serve da vivaio per l'hockey su prato professionistico, che sta diventando sempre più sviluppato e professionalizzato.
      <br />
      <br />
      Anche l'hockey su prato professionistico ha influenzato la nazionale, che ha ottenuto successi migliori sulla scena internazionale. Ad esempio, la squadra femminile degli Stati Uniti ha vinto il bronzo due volte alle Olimpiadi (nel 1984 e nel 1996) e l'argento due volte ai Giochi Panamericani (nel 2011 e nel 2015). La squadra maschile degli Stati Uniti ha anche partecipato a molte Olimpiadi e Campionati del Mondo, ma non ha ancora ottenuto medaglie.
      <br />
      <br />
      Come puoi vedere, l'hockey su prato ha fatto molta strada in America, da un'attività ricreativa a uno sport professionistico. Ma cosa comporta giocare a hockey su prato a livello universitario? Te lo diremo nel prossimo capitolo.
    </MKTypography>
  </MKBox>
</MKBox>











                                              





                                  









<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 2: Hockey su Prato a Livello Universitario
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Giocare a hockey su prato a livello universitario è una delle esperienze più impegnative e gratificanti per un giovane atleta. Ti offre l'opportunità di combinare il tuo talento atletico con le tue ambizioni accademiche e di far parte di una comunità unita e diversificata. In questo capitolo, ti parleremo di più sulle competizioni e le conferenze, l'impatto sullo sviluppo dei giocatori e i benefici di giocare a hockey su prato a livello universitario.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Competizioni e Conferenze
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Vista aerea dello stadio di hockey su prato del Boston College"
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
        Lo stadio del Boston College
        </figcaption>
      </figure>
      L'hockey su prato a livello universitario è regolamentato dalla National Collegiate Athletic Association (NCAA), che divide lo sport in tre divisioni: Divisione I, Divisione II e Divisione III. Ogni divisione ha le proprie regole, requisiti e borse di studio. In generale, la Divisione I è il livello di competizione più alto, con le migliori squadre e giocatori, e offre il maggior numero di borse di studio. La Divisione II e la Divisione III sono meno competitive ma offrono comunque ampie opportunità per giocatori talentuosi.
      <br />
      <br />
      All'interno di ciascuna divisione, ci sono varie conferenze, composte da università che sono connesse geograficamente o storicamente. Ogni conferenza ha il proprio programma, classifica e campionato. Alcune delle conferenze più conosciute includono la Big Ten Conference, l'Atlantic Coast Conference, l'Ivy League, la Patriot League e l'America East Conference.
      <br />
      <br />
      Le migliori squadre e giocatori di ciascuna conferenza possono qualificarsi per il campionato nazionale, che si tiene annualmente a novembre. Il campionato nazionale prevede un torneo ad eliminazione diretta, con il vincitore incoronato campione nazionale.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Impatto sullo Sviluppo dei Giocatori
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Giocare a hockey su prato a livello universitario ha un impatto significativo sullo sviluppo dei giocatori. Consente loro di migliorare le proprie abilità sotto la guida di allenatori professionisti, di allenarsi con strutture e attrezzature all'avanguardia, di competere contro altri atleti di alto livello e di imparare dai loro compagni di squadra e avversari.
      <br />
      <br />
      Inoltre, giocare a hockey su prato a livello universitario offre anche ai giocatori molti benefici personali. Aiuta i giocatori a aumentare la loro fiducia, disciplina, leadership, spirito di squadra, comunicazione e capacità di risolvere i problemi. Aiuta anche i giocatori a migliorare la loro gestione del tempo, le abitudini di studio, la gestione dello stress e il benessere generale. Inoltre, espone i giocatori a diverse culture, prospettive ed esperienze, ampliando i loro orizzonti.
      <br />
      <br />
      La storia delle giocatrici olandesi di hockey su prato che ricevono borse di studio per giocare a livello universitario in America serve come esempio di come questa esperienza possa migliorare il loro sviluppo come giocatori. Molte hanno giocato per prestigiose squadre universitarie negli Stati Uniti, dove non solo hanno vinto campionati e titoli individuali ma hanno anche imparato lezioni preziose.
      <br />
      <br />
      L'esperienza in America offre a questi atleti un'opportunità unica per immergersi in una cultura diversa, godersi nuovi ambienti sociali e sperimentare un diverso sistema educativo. L'interazione con allenatori che li sfidano e li sostengono contribuisce a perfezionare le loro abilità e a espandere la loro comprensione tattica del gioco.
      <br />
      <br />
      Al loro ritorno nei Paesi Bassi, queste giocatrici di hockey hanno utilizzato le conoscenze e le esperienze acquisite per eccellere a livello professionistico e, in alcuni casi, assumere ruoli di leadership all'interno della nazionale. Il loro tempo in America le ha spesso preparate alle pressioni e alle aspettative che accompagnano le competizioni internazionali, creando una base per il loro successo futuro.
      <br />
      <br />
      Come puoi vedere, giocare a hockey su prato a livello universitario può offrirti molti vantaggi, sia dal punto di vista atletico che personale. Ma come si passa dal college al gioco professionistico? Te lo spiegheremo nel prossimo capitolo.
    </MKTypography>
  </MKBox>
</MKBox>




                                  








<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 3: Transizione dall'Università al Professionismo
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Giocare a hockey su prato a livello universitario è un ottimo modo per liberare il proprio potenziale atletico, ma non deve necessariamente essere la fine della tua carriera. Se hai l'ambizione e la passione per andare oltre, puoi passare al gioco professionale. In questo capitolo, ti parleremo di più su come passare dal college ai professionisti e quali opportunità professionali e leghe esistono per i giocatori di hockey su prato.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Dal College ai Professionisti
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La transizione dall'università al gioco professionale non è facile, ma non è neanche impossibile. Richiede molta dedizione, duro lavoro, talento e un po' di fortuna. L'aspetto più cruciale è mostrarti alle persone giuste che possono aiutarti a realizzare il tuo sogno.
      <br />
      <br />
      Un modo per farti notare è partecipare ai provini. I provini sono sessioni di allenamento o partite aperte in cui le squadre professionistiche possono cercare e valutare i giocatori. I provini si tengono tipicamente prima o dopo la stagione e possono essere annunciati attraverso siti web, social media o passaparola. I provini possono essere un'eccellente opportunità per mostrare le tue abilità e ricevere feedback da allenatori e manager. I provini possono essere condotti da squadre professionistiche in tutto il mondo.
      <br />
      <br />
      Un altro modo per farti conoscere è sfruttare la tua rete di contatti. La tua rete comprende tutte le persone che conosci o incontri che sono connesse all'hockey su prato, inclusi allenatori, compagni di squadra, avversari, amici, familiari, insegnanti, alumni, ecc. La tua rete può aiutarti a rimanere informato sulle ultime notizie, opportunità e consigli nel mondo dell'hockey su prato. Può anche aiutarti a connetterti con individui che hanno influenza o connessioni nel mondo professionistico.
      <br />
      <br />
      Inoltre, molti giocatori di hockey europei tornano in Europa per provare la loro fortuna lì. Come puoi vedere, ci sono vari modi per farsi notare dalle squadre professionistiche. Ma che tipo di squadre ci sono e che tipo di leghe esistono? Lo spiegheremo nella prossima sezione.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Opportunità Professionali e Leghe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Per un giocatore di hockey su prato che ha attraversato il sistema universitario americano, le porte per una carriera professionale sono spesso spalancate. Questa esperienza è più di una semplice pietra miliare; è una preparazione completa che li ha sviluppati in molti aspetti - fisicamente, tatticamente e mentalmente.
      <br />
      <br />
      Un giocatore che eccelle nell'hockey universitario può attirare l'attenzione di scout e squadre professionistiche, non solo per le loro prestazioni in campo, ma anche per la disciplina e la versatilità che hanno coltivato studiando e giocando contemporaneamente. Le leghe professionistiche di hockey su prato in tutto il mondo, come quelle nei Paesi Bassi, in Belgio, in Germania e in Australia, sono conosciute per reclutare giocatori talentuosi con una solida esperienza nell'hockey universitario.
      <br />
      <br />
      La natura competitiva dell'hockey universitario in America assicura anche che i giocatori siano abituati a un alto livello di allenamento e preparazione fisica, che è essenziale per il gioco professionale. Inoltre, hanno spesso esperienza di partite importanti e campionati, che li aiutano a gestire la pressione delle competizioni professionali.
      <br />
      <br />
      Inoltre, l'esperienza internazionale e l'esposizione a diversi stili di gioco in America forniscono a questi giocatori una prospettiva unica che possono portare nella loro carriera professionale. Aiuta ad adattarsi rapidamente a nuove squadre e strategie e fornisce una comprensione più ampia del gioco.
      <br />
      <br />
      Le squadre professionistiche apprezzano la combinazione di disciplina accademica ed eccellenza atletica che un ex giocatore universitario porta. Questi giocatori sono spesso ben preparati ad affrontare le sfide della vita professionale, inclusa la gestione dello sport con altri impegni di vita.
      <br />
      <br />
      Come puoi vedere, ci sono molte opportunità professionali e leghe per i giocatori di hockey su prato in America. Ma cosa succede nel resto del mondo? Come si confronta l'hockey su prato in America con l'hockey su prato internazionale? Lo spiegheremo nel prossimo capitolo.
    </MKTypography>
  </MKBox>
</MKBox>






                                  






<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 4: Influenze e Opportunità Internazionali
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    L'hockey su prato in America non è isolato, ma fa parte di un mondo dell'hockey su prato più ampio e diversificato. L'hockey su prato è uno sport globale giocato in più di 100 paesi su tutti i continenti. L'hockey su prato in America è influenzato e influenzato dall'hockey su prato internazionale. In questo capitolo, ti parleremo di più sulle influenze globali, sugli americani sulla scena mondiale e sulle opportunità internazionali per i giocatori di hockey su prato.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Influenze Globali
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      L'hockey su prato in America è influenzato dall'hockey su prato giocato in altri paesi, specialmente in Europa e Asia. Questi paesi hanno una tradizione più lunga e ricca di hockey su prato e spesso hanno un livello di gioco e organizzazione più elevato. Osservando e imparando da questi paesi, l'hockey su prato in America può svilupparsi ulteriormente e migliorare.
      <br />
      <br />
      Un modo in cui l'hockey su prato in America è influenzato dall'hockey su prato internazionale è adottando stili e tattiche differenti. Ad esempio, l'hockey su prato olandese è noto per il suo gioco veloce e d'attacco, con un focus sul possesso palla, le combinazioni e i tentativi in porta. L'hockey su prato tedesco è noto per il suo stile fisico e difensivo, con un'elevata pressione, duelli e contropiedi. L'hockey su prato indiano è noto per il suo gioco tecnico e creativo, con un focus sul dribbling, il passaggio e i trucchi.
      <br />
      <br />
      Imparando da questi stili e tattiche, l'hockey su prato in America può diversificare il proprio gioco e adattarsi a diverse situazioni, aggiungendo più varietà, flessibilità ed efficacia.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Gli Americani sulla Scena Mondiale
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      L'hockey su prato in America influisce anche sull'hockey su prato internazionale fornendo giocatori che partecipano ai tornei più grandi e significativi in tutto il mondo. Questi giocatori non rappresentano solo il loro paese, ma anche la loro università, squadra e sport. Attraverso le loro performance, dimostrano ciò che l'hockey su prato in America ha da offrire e ispirano gli altri a partecipare a questo sport.
      <br />
      <br />
      Un esempio lampante di come i giocatori americani entrano nella scena mondiale è la storia di Lauren Crandall. Lauren è una giocatrice di hockey su prato americana che ha ricevuto una borsa di studio per giocare per la Wake Forest University. Ha giocato per la squadra per quattro stagioni, vincendo il titolo NCAA Division I due volte.
      <br />
      <br />
      Lauren ha anche fatto parte della nazionale americana, partecipando a tre Giochi Olimpici (nel 2008, 2012 e 2016), due Campionati del Mondo (nel 2010 e 2014) e quattro Giochi Panamericani (nel 2007, 2011, 2015 e 2019). Ha anche ricoperto il ruolo di capitano della squadra dal 2013 al 2016.
      <br />
      <br />
      Lauren afferma che il suo tempo in America l'ha aiutata significativamente nel realizzare il suo sogno di giocare ai massimi livelli. Ha imparato molto dal suo allenatore, Jennifer Averill, che l'ha sostenuta e motivata. Ha anche apprezzato la cultura, le persone e l'istruzione in America. È orgogliosa di rappresentare il suo paese e di essere un modello per i giovani giocatori.
      <br />
      <br />
      Come puoi vedere, l'hockey su prato in America può offrirti molte influenze e opportunità internazionali, sia come giocatore che come individuo. Ma come puoi cogliere queste opportunità? E come puoi ottenere una borsa di studio per studiare e giocare in America? Te lo diremo nel prossimo capitolo.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  






<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 5: Borse di Studio Sportive e il Futuro degli Atleti
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le borse di studio sportive offrono numerose opportunità ai giovani atleti di mostrare il loro talento e sviluppare le loro abilità. Una delle opportunità più significative è ottenere una borsa di studio sportiva per studiare e competere a livello universitario. In questo capitolo, discuteremo dell'importanza delle borse di studio sportive e di come Sport Scholarships America può aiutarti a realizzare il tuo sogno.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 L'Importanza delle Borse di Studio
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Una borsa di studio sportiva è un sostegno finanziario fornito da un'università a uno studente-atleta per combinare studio e sport. Può coprire i costi di iscrizione, libri, alloggio, pasti e altre spese. Inoltre, può offrire vantaggi come il tutorato accademico, l'assistenza medica, le strutture di allenamento e l'attrezzatura.
      <br />
      <br />
      Ottenere una borsa di studio sportiva è competitivo, con molti fattori che influenzano il processo di assegnazione, tra cui risultati accademici, prestazioni sportive, personalità, motivazione, leadership e adattabilità. È quindi importante prepararsi bene e distinguersi dalla concorrenza.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Come Sport Scholarships America Può Aiutare
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo di Sport Scholarships America, raffigurante attrezzature sportive e una toga, simbolo del focus sulle borse di studio sportive per gli studenti-atleti."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo di Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America è un'organizzazione specializzata nell'orientamento dei giovani talenti sportivi verso borse di studio sportive. Con esperti appassionati ed esperti, Sport Scholarships America supporta gli studenti-atleti in ogni fase del percorso, dall'orientamento iniziale alla collocazione finale. I loro servizi includono:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Un'intervista personale per discutere i desideri, le possibilità e le aspettative dello studente-atleta.</li>
        <li>Una valutazione professionale del livello accademico e sportivo dello studente-atleta.</li>
        <li>Una vasta rete di contatti con allenatori, università e college.</li>
        <li>Promozione efficace del profilo e dei video dello studente-atleta presso allenatori potenziali.</li>
        <li>Consulenza esperta sulle migliori opzioni in base alle preferenze, agli obiettivi e al budget dello studente-atleta.</li>
        <li>Negoziazione delle migliori offerte di borse di studio per conto dello studente-atleta.</li>
        <li>Assistenza nella richiesta dei documenti necessari, come visto, trascrizione, diploma e test di ammissione.</li>
        <li>Preparazione alla vita dello studente-atleta durante la borsa di studio, compresa cultura, lingua, regole e consigli.</li>
        <li>Assistenza e monitoraggio durante il periodo di borsa di studio.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vuoi esplorare le opportunità di borse di studio e raggiungere i tuoi obiettivi sportivi e accademici? <Link to="/it/Servizi-Prodotti">Consulta la gamma completa</Link> dei servizi offerti da Sport Scholarships America e prenota una consultazione oggi stesso.
    </MKTypography>
  </MKBox>
</MKBox>
          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;