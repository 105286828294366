/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Wrestling1.jpeg";
import Image2 from "assets/images/Sports_Images/Wrestling2.webp";
import Image3 from "assets/images/Sports_Images/Wrestling3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Bourses de Lutte aux États-Unis : Comment Étudier et Lutter en Amérique Peut Réaliser Vos Rêves', isSubheader: false },
  
    { id: 'Chapitre 1', title: 'Chapitre 1 : Histoire et Développement de la Lutte aux États-Unis', isSubheader: false },
    { id: '1.1', title: '1.1 Premières Années', isSubheader: true },
    { id: '1.2', title: '1.2 Croissance et Évolution', isSubheader: true },
  
    { id: 'Chapitre 2', title: 'Chapitre 2 : La Lutte Universitaire Aujourd\'hui', isSubheader: false },
    { id: '2.1', title: '2.1 Compétitions et Conférences Importantes', isSubheader: true },
    { id: '2.2', title: '2.2 Programmes de Premier Plan et Équipes Prestigieuses', isSubheader: true },
  
    { id: 'Chapitre 3', title: 'Chapitre 3 : Du Niveau Universitaire au Niveau Olympique et aux Ligues Professionnelles', isSubheader: false },
    { id: '3.1', title: '3.1 Le Chemin vers le Sommet', isSubheader: true },
    { id: '3.2', title: '3.2 La Lutte Professionnelle et les Jeux Olympiques', isSubheader: true },
  
    { id: 'Chapitre 4', title: 'Chapitre 4 : Influences et Compétitions Internationales', isSubheader: false },
    { id: '4.1', title: '4.1 Styles et Traditions de Lutte Étrangère', isSubheader: true },
    { id: '4.2', title: '4.2 Les Américains sur la Scène Internationale', isSubheader: true },
  
    { id: 'Chapitre 5', title: 'Chapitre 5 : Bourses Sportives et l\'Avenir des Athlètes', isSubheader: false },
    { id: '5.1', title: '5.1 L\'Importance des Bourses', isSubheader: true },
    { id: '5.2', title: '5.2 Comment Sport Scholarships America Peut Aider', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Sommaire
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Cacher</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Afficher</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Bourses de Lutte aux États-Unis : Comment Étudier et Lutter en Amérique Peut Réaliser Vos Rêves
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Vue aérienne d'un stade plein lors d'un match de lutte universitaire."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Le Championnat National
      </figcaption>
    </figure>
    La lutte est un sport pratiqué depuis des milliers d'années dans le monde entier. C'est une forme de combat physique dans laquelle deux adversaires cherchent à se dominer mutuellement à travers des prises, des projections, des immobilisations et d'autres techniques. La lutte nécessite non seulement de la force physique, de la vitesse, de l'endurance et de la flexibilité, mais aussi de la vivacité mentale, de la stratégie, de la discipline et de la persévérance.
    <br />
    <br />
    Aux États-Unis, la lutte est l'un des sports les plus pratiqués et regardés. Elle a une longue et captivante histoire remontant aux temps coloniaux. Depuis lors, elle s'est développée en un phénomène national avec des millions de fans et de pratiquants. La lutte universitaire américaine représente le summum de ce développement. C'est le niveau où les meilleurs lutteurs du pays se rencontrent lors de compétitions passionnantes qui servent souvent de tremplin vers des carrières professionnelles ou olympiques.
    <br />
    <br />
    La signification de la lutte universitaire dans le développement des athlètes ne peut être sous-estimée. Les lutteurs universitaires ont non seulement l'occasion de mettre en valeur et d'améliorer leurs talents athlétiques, mais aussi d'étudier dans des universités de renom, où ils peuvent acquérir des compétences et des connaissances académiques précieuses. De plus, ils peuvent bénéficier de bourses d'études qui peuvent couvrir une partie importante ou la totalité de leurs frais de scolarité.
    <br />
    <br />
    Cependant, obtenir une bourse de lutte n'est pas une tâche facile. Cela nécessite beaucoup de préparation, de dévouement et de conseils. Heureusement, vous n'avez pas à le faire seul. Sportbeursamerika.nl est une organisation spécialisée dans l'aide aux jeunes talents de la lutte à réaliser leur rêve d'étudier et de lutter en Amérique. Je vous offre des conseils professionnels, un soutien personnel et un accès à mon vaste réseau de contacts dans le monde de la lutte américaine.
    Souhaitez-vous en savoir plus sur la manière dont je peux vous aider à obtenir une bourse de lutte aux États-Unis ? Lisez la suite ! Dans cet article, je vous en dirai plus sur :
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 1 : Histoire et Développement de la Lutte aux États-Unis
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    La lutte est un sport pratiqué depuis l'Antiquité dans diverses cultures. C'est l'une des plus anciennes formes de compétition organisée, mentionnée dans la mythologie grecque et la Bible. Elle a également été l'un des premiers sports inclus dans les anciens Jeux olympiques.
    <br />
    <br />
    Aux États-Unis, la lutte a eu sa propre histoire et évolution étroitement liée au contexte social et politique du pays. Dans ce chapitre, nous donnerons un aperçu des principales phases et événements de l'histoire de la lutte américaine et de leur contribution à la formation de la lutte universitaire moderne.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Premières Années
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Les débuts de la lutte en Amérique remontent à l'époque coloniale, lorsque les colons européens ont apporté leurs propres styles et traditions de lutte sur le nouveau continent. Ces styles étaient principalement basés sur la lutte catch-as-catch-can anglaise, qui permettait les prises sur n'importe quelle partie du corps sauf la tête. C'était une forme de divertissement et de défi populaires parmi les colons qui organisaient souvent des matches lors des jours fériés ou des jours de marché.
      <br />
      <br />
      L'un des premiers lutteurs connus en Amérique était George Washington, qui, jeune homme, était connu pour ses compétences en lutte. Il a un jour vaincu sept adversaires en une journée lors d'un match en Virginie. Un autre lutteur célèbre de cette période était Abraham Lincoln, qui a remporté plusieurs matches en tant qu'adolescent en Illinois.
      <br />
      <br />
      La première équipe de lutte universitaire a été formée en 1780 à l'Université Yale, mais il a fallu plus d'un siècle pour que la lutte universitaire prenne réellement son envol. Le premier match intercollégial officiel a eu lieu en 1889 entre Yale et l'Université Columbia, Yale remportant 5-0. En 1892, le premier championnat national a eu lieu à New York, avec huit équipes de différents États. Le vainqueur était l'Université Cornell, qui a remporté tous ses sept matches.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Croissance et Évolution
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      À la fin du XIXe et au début du XXe siècle, la lutte a commencé à se répandre et à se diversifier en Amérique. Divers styles régionaux ont émergé, tels que la lutte de style Iowa, qui mettait l'accent sur la vitesse et l'agilité, ou la lutte de style Oklahoma, qui se concentrait sur la force et le contrôle. De nouvelles règles ont également été introduites pour rendre la lutte plus sûre et plus équitable, comme l'interdiction de prises dangereuses ou l'introduction de limites de temps.
      <br />
      <br />
      La lutte universitaire a également gagné en popularité et en prestige, avec plus d'équipes, de compétitions et de fans. En 1928, la National Collegiate Athletic Association (NCAA) a été fondée pour réglementer et promouvoir la lutte universitaire. La NCAA organisait des championnats nationaux annuels pour différentes divisions en fonction de la taille et du niveau des universités. La division la plus prestigieuse était la Division I, qui regroupait environ 80 équipes.
      <br />
      <br />
      La lutte universitaire a également produit de nombreuses stars et légendes qui ont connu un succès national et international. Quelques exemples incluent :
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Dan Gable : L'un des plus grands lutteurs de tous les temps, qui a dominé à la fois en tant qu'athlète et entraîneur. Il a remporté trois titres de la NCAA pour l'Université d'État de l'Iowa, une médaille d'or aux Jeux olympiques de 1972 sans perdre un seul point, et 15 titres de la NCAA en tant qu'entraîneur pour l'Université de l'Iowa.
        </li>
        <li>
          John Smith : Un champion du monde six fois et double champion olympique connu pour ses techniques et tactiques innovantes. Il a remporté deux titres de la NCAA pour l'Université d'État de l'Oklahoma et est devenu plus tard l'entraîneur-chef de la même équipe.
        </li>
        <li>
          Cael Sanderson : Le seul lutteur à avoir remporté quatre titres de la NCAA sans perdre un seul match. Il avait un bilan parfait de 159-0 pour l'Université d'État de l'Iowa et a également remporté une médaille d'or aux Jeux olympiques de 2004. Il est actuellement l'entraîneur-chef de l'Université d'État de Pennsylvanie, où il a remporté huit titres de la NCAA.
        </li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ce ne sont là que quelques exemples de la croissance et de l'évolution de la lutte en Amérique, qui ont conduit à la scène actuelle de la lutte universitaire, considérée comme l'un des sports les plus compétitifs et les plus passionnants du pays.
    </MKTypography>
  </MKBox>
</MKBox>





                                  







<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 2 : La Lutte Universitaire Aujourd'hui
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    La lutte universitaire est l'un des sports les plus populaires et prestigieux en Amérique aujourd'hui. Elle attire des millions de fans et spectateurs qui suivent les matches excitants à la télévision, sur internet, ou en direct dans les arènes. La lutte universitaire offre également de nombreuses opportunités pour les étudiants-athlètes qui peuvent bénéficier de bourses d'études, de soutien académique, et d'encadrement professionnel.
    <br />
    <br />
    Dans ce chapitre, nous vous en dirons plus sur les principales compétitions et conférences de la lutte universitaire et les programmes de premier plan et les équipes prestigieuses qui dominent le sport.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Compétitions et Conférences Importantes
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Deux lutteurs universitaires dans un duel intense sur le tapis."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Deux lutteurs en action
        </figcaption>
      </figure>
      La lutte universitaire est organisée par la NCAA, qui comprend trois divisions : Division I, Division II, et Division III. Chaque division a ses propres règles, critères, et championnats. La division la plus compétitive et la plus connue est la Division I, qui comprend environ 80 équipes de différents États.
      <br />
      <br />
      Les équipes de la Division I sont divisées en diverses conférences, formant des groupes régionaux ou thématiques. Les conférences organisent leurs propres compétitions et championnats, qui servent de qualifications pour le championnat national. Les conférences les plus importantes en lutte universitaire incluent :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Big Ten Conference : La plus grande et la plus forte conférence en lutte universitaire, composée de 14 équipes des régions du Midwest et du Nord-Est du pays. Le Big Ten a produit le plus de champions nationaux, notamment Penn State, Iowa, Ohio State, Michigan, et Minnesota.
        </li>
        <li>
          Big 12 Conference : La deuxième plus grande et la plus forte conférence en lutte universitaire, composée de 12 équipes des régions du Sud et de l'Ouest du pays. Le Big 12 a également produit de nombreux champions nationaux, dont Oklahoma State, Oklahoma, Iowa State, et Missouri.
        </li>
        <li>
          Atlantic Coast Conference (ACC) : Une conférence émergente en lutte universitaire, composée de six équipes de la région du Sud-Est du pays. L'ACC a attiré plus d'attention récemment en raison des performances d'équipes comme Virginia Tech, North Carolina State, et North Carolina.
        </li>
        <li>
          Eastern Intercollegiate Wrestling Association (EIWA) : Une conférence historique en lutte universitaire, composée de 16 équipes de la région de l'Est du pays. L'EIWA est la plus ancienne conférence de lutte en Amérique, fondée en 1905. L'EIWA a également produit quelques champions nationaux, dont Cornell, Lehigh, Princeton, et Navy.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ce ne sont là que quelques-unes des nombreuses conférences en lutte universitaire, chacune ayant ses propres rivalités, traditions, et fans. Cependant, les conférences ne sont pas le seul facteur qui détermine le succès d'une équipe. Il y a d'autres aspects qui jouent un rôle dans le succès d'un programme de lutte.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programmes de Premier Plan et Équipes Prestigieuses
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Un programme de lutte est un terme utilisé pour désigner l'organisation et la structure d'une équipe de lutte universitaire d'une université. Un programme de lutte comprend des aspects tels que l'entraîneur, le personnel, les installations, le recrutement, l'entraînement, le financement, et la culture de l'équipe.
      <br />
      <br />
      Un bon programme de lutte peut faire une différence significative dans le développement des étudiants-athlètes. Un bon programme de lutte peut fournir aux étudiants-athlètes :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Un entraîneur expérimenté et qualifié qui peut leur apprendre comment améliorer leur technique, leurs tactiques, leur condition physique, et leur mentalité.
        </li>
        <li>
          Un personnel dévoué et professionnel qui peut les soutenir dans des domaines tels que la nutrition, la prévention des blessures, la récupération, l'orientation académique, et la planification de carrière.
        </li>
        <li>
          Des installations modernes et bien équipées où ils peuvent s'entraîner à un niveau élevé avec des équipements et des matériaux adaptés.
        </li>
        <li>
          Une stratégie de recrutement efficace et éthique qui peut les aider à attirer et à conserver des lutteurs talentueux et motivés.
        </li>
        <li>
          Un programme d'entraînement intensif et varié qui peut les mettre au défi et les préparer à différents matches et situations.
        </li>
        <li>
          Un financement adéquat et stable qui peut les aider à couvrir les coûts de la lutte, tels que les déplacements, l'équipement, les bourses d'études, et autres dépenses.
        </li>
        <li>
          Une culture positive et inspirante qui peut les motiver et les encourager à atteindre leur potentiel, poursuivre leurs objectifs, et partager leur passion pour le sport.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Il y a beaucoup d'universités en Amérique qui ont d'excellents programmes de lutte qui ont réussi en lutte universitaire depuis des années. Certains de ces programmes incluent :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Penn State : L'équipe de premier plan actuelle en lutte universitaire, qui a remporté le championnat national huit fois depuis 2011. Penn State est connue pour son style de lutte agressif et spectaculaire, dirigé par l'entraîneur Cael Sanderson, l'un des plus grands lutteurs de tous les temps.
        </li>
        <li>
          Iowa : L'équipe la plus réussie de l'histoire de la lutte universitaire, ayant remporté le championnat national plus de 20 fois depuis 1975. Iowa est connue pour son style de lutte agressif et dominant, dirigé par l'entraîneur Tom Brands, un ancien champion olympique.
        </li>
        <li>
          Oklahoma State : La deuxième équipe la plus réussie de l'histoire de la lutte universitaire, ayant remporté le championnat national plus de 20 fois. Oklahoma State est connue pour son style de lutte technique et tactique, dirigé par l'entraîneur John Smith, l'un des plus grands lutteurs de tous les temps.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ce ne sont là que quelques-uns des nombreux programmes de premier plan et équipes prestigieuses en lutte universitaire, chacun avec ses propres caractéristiques, réalisations, et fans. Cependant, il n'est pas facile de faire partie de ces programmes et équipes. Il faut beaucoup de talent, de travail, et de dévouement pour obtenir une bourse de lutte.
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  








<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 3 : Du Niveau Universitaire au Niveau Olympique et aux Ligues Professionnelles
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    La lutte universitaire n'est pas le point final pour de nombreux étudiants-athlètes qui rêvent d'une carrière dans le sport. C'est plutôt une étape vers des niveaux plus élevés où ils peuvent concourir avec les meilleurs lutteurs du monde. Dans ce chapitre, nous vous en dirons plus sur le chemin vers le sommet en lutte professionnelle ou olympique et sur les succès et les défis des lutteurs américains sur la scène mondiale.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Le Chemin vers le Sommet
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La lutte universitaire est l'un des sports les plus compétitifs et exigeants en Amérique. Elle demande beaucoup de talent, de travail, et de dévouement pour obtenir une bourse de lutte, et encore plus pour briller dans les compétitions nationales. Mais même si vous êtes l'un des meilleurs lutteurs universitaires, cela ne garantit pas le succès au niveau professionnel ou olympique.
      <br />
      <br />
      Il existe plusieurs facteurs qui rendent difficile la transition de la lutte universitaire à la lutte professionnelle ou olympique. Certains de ces facteurs incluent :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Différences de Style : La lutte universitaire est pratiquée selon les règles du style folklorique, propres à l'Amérique. Ces règles mettent l'accent sur le contrôle et la domination de l'adversaire, avec des points attribués pour la prise en main, l'évasion, le renversement, et les quasi-chutes. La lutte professionnelle ou olympique, en revanche, est pratiquée selon les règles du libre ou du gréco-romain, utilisées à l'échelle internationale. Ces règles mettent l'accent sur le marquage de points par des lancers, des bascules, des expositions, et des épingles. Cela signifie que les lutteurs universitaires doivent adapter leur technique, leurs tactiques, et leur stratégie pour concourir avec des lutteurs d'autres pays qui ont plus d'expérience avec ces styles.
        </li>
        <li>
          Différences d'Âge : La lutte universitaire est un sport pour les jeunes athlètes, généralement âgés de 18 à 23 ans. La lutte professionnelle ou olympique, cependant, est un sport pour les athlètes adultes, généralement âgés de 25 à 35 ans. Cela signifie que les lutteurs universitaires doivent concourir avec des lutteurs plus âgés et plus expérimentés qui ont une plus grande force physique, une maturité mentale accrue, et un raffinement technique supérieur.
        </li>
        <li>
          Différences Financières : La lutte universitaire est un sport soutenu par les universités, qui fournissent aux étudiants-athlètes des bourses, des installations, un encadrement, et d'autres avantages. La lutte professionnelle ou olympique, cependant, reçoit peu de soutien financier de la part du gouvernement ou des sponsors. Cela signifie que les lutteurs universitaires doivent chercher d'autres sources de revenus pour couvrir leur entraînement, leurs déplacements, leur équipement, et leurs dépenses de subsistance.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ce ne sont là que quelques-uns des nombreux défis que les lutteurs universitaires doivent surmonter pour poursuivre leur rêve d'une carrière professionnelle ou olympique. Cependant, malgré ces défis, il y a aussi de nombreux succès et histoires inspirantes de lutteurs américains qui ont atteint le plus haut niveau.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 La Lutte Professionnelle et les Jeux Olympiques
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La lutte professionnelle ou olympique est le summum du sport, où les meilleurs lutteurs du monde concourent dans des compétitions et des événements prestigieux. Il existe deux principales formes de lutte professionnelle ou olympique : le libre et le gréco-romain.
      <br />
      <br />
      La lutte libre est une forme de lutte où tous les types de prises sur tout le corps sont autorisés, tant qu'elles ne sont pas dangereuses ou inappropriées. La lutte libre est pratiquée par les hommes et les femmes, dans différentes catégories de poids. La lutte libre est l'un des plus anciens sports des Jeux Olympiques, pratiqué depuis 1904 pour les hommes et depuis 2004 pour les femmes.
      <br />
      <br />
      La lutte gréco-romaine est une forme de lutte où seules les prises au-dessus de la taille sont autorisées, et l'utilisation des jambes pour attraper ou projeter l'adversaire est interdite. La lutte gréco-romaine est pratiquée uniquement par les hommes, dans différentes catégories de poids. La lutte gréco-romaine est la plus ancienne forme de lutte aux Jeux Olympiques, pratiquée depuis 1896.
      <br />
      <br />
      Les lutteurs américains ont remporté de nombreux succès aussi bien en lutte libre qu'en lutte gréco-romaine, aux Jeux Olympiques ainsi que dans d'autres compétitions internationales telles que les Championnats du Monde, les Jeux Panaméricains, ou la Coupe du Monde. Certains des lutteurs américains les plus réussis et les plus connus incluent :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Jordan Burroughs : Cinq fois champion du monde et champion olympique en lutte libre, connu pour son style explosif et dynamique. Il a remporté une médaille d'or aux Jeux Olympiques de 2012 à Londres et quatre titres mondiaux en 2011, 2013, 2015, et 2017. Il a également remporté deux titres de la NCAA pour l'Université du Nebraska.
        </li>
        <li>
          Kyle Snyder : Trois fois champion du monde et champion olympique en lutte libre, connu pour sa force et sa technique. Il a remporté une médaille d'or aux Jeux Olympiques de 2016 à Rio de Janeiro et trois titres mondiaux en 2015, 2017, et 2019. Il a également remporté trois titres de la NCAA pour l'Université d'État de l'Ohio.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ce ne sont là que quelques-uns des nombreux succès et histoires inspirantes de lutteurs américains au niveau professionnel ou olympique. Ils montrent qu'il est possible de réaliser son rêve si l'on est prêt à travailler dur, à apprendre, et à évoluer.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  





<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 4 : Influences et Compétitions Internationales
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    La lutte est un sport universel, pratiqué et apprécié dans de nombreux pays et cultures. La lutte a également de nombreux styles et traditions qui ont vu le jour et se sont développés dans différentes régions et époques. Dans ce chapitre, nous vous en dirons plus sur les influences et les compétitions internationales en lutte et sur la manière dont elles influencent et défient les lutteurs américains.
  </MKTypography>
  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Styles et Traditions de Lutte Étrangère
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Vue aérienne d'un match de lutte NCAA dans un stade bondé."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Un match dans le tournoi de la NCAA
        </figcaption>
      </figure>
      La lutte est un sport pratiqué depuis l'Antiquité dans diverses parties du monde. C'est l'une des formes les plus anciennes d'expression physique, souvent associée à des significations religieuses, culturelles ou sociales. Il existe de nombreux types de lutte, chacun avec ses propres caractéristiques, règles et techniques. Quelques exemples incluent :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Sumo : Un style de lutte traditionnel japonais considéré comme un sport national et un rituel sacré. Le sumo est pratiqué par des lutteurs lourds et forts qui essaient de se pousser hors d'un anneau circulaire ou de les jeter au sol.
        </li>
        <li>
          Lutte mongole : Un style de lutte traditionnel mongol considéré comme un symbole de courage et de force. La lutte mongole est pratiquée par des lutteurs musclés et agiles qui essaient de se faire tomber mutuellement au sol par des prises sur le haut du corps ou les jambes.
        </li>
        <li>
          Lutte turque à l'huile : Un style de lutte traditionnel turc considéré comme un patrimoine et un art. La lutte turque à l'huile est pratiquée par des lutteurs huilés et musclés qui essaient de se vaincre mutuellement par des prises sur le pantalon ou la ceinture.
        </li>
        <li>
          Lutte sénégalaise : Un style de lutte traditionnel sénégalais considéré comme un sport populaire et lucratif. La lutte sénégalaise est pratiquée par des lutteurs athlétiques et colorés qui essaient de se mettre mutuellement à terre par des projections, des coups de poing ou des coups de pied.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ce ne sont là que quelques-uns des nombreux styles et traditions de lutte étrangère, chacun ayant sa propre histoire, culture et fans. Ces styles ont également influencé la lutte universitaire américaine en introduisant de nouvelles techniques, tactiques ou défis. De nombreux lutteurs universitaires américains ont voyagé ou se sont entraînés dans d'autres pays pour élargir leurs horizons et améliorer leurs compétences.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Les Américains sur la Scène Internationale
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La lutte universitaire américaine ne se concentre pas uniquement sur les compétitions nationales, mais aussi sur les compétitions internationales. Il existe de nombreux événements internationaux auxquels les lutteurs universitaires américains peuvent participer pour concourir avec les meilleurs lutteurs du monde. Certains de ces événements incluent :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Les Jeux Olympiques : Le plus grand et le plus prestigieux événement en lutte professionnelle ou olympique, se tenant tous les quatre ans. Les Jeux Olympiques sont l'objectif ultime de nombreux lutteurs universitaires qui rêvent de remporter une médaille d'or pour leur pays.
        </li>
        <li>
          Les Championnats du Monde : Le deuxième plus grand et le plus prestigieux événement en lutte professionnelle ou olympique, se tenant annuellement. Les Championnats du Monde sont une occasion significative pour les lutteurs universitaires de se qualifier pour les Jeux Olympiques ou d'établir leur réputation sur la scène mondiale.
        </li>
        <li>
          La Coupe du Monde : Le deuxième plus grand et le plus prestigieux événement en lutte régionale, se tenant annuellement. La Coupe du Monde est une occasion significative pour les lutteurs universitaires de faire partie d'une équipe nationale qui concourt contre d'autres équipes nationales dans un format par équipes.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ce ne sont là que quelques-uns des nombreux événements internationaux auxquels les lutteurs universitaires américains peuvent participer pour montrer leurs talents et poursuivre leurs rêves. Cependant, ces événements ne sont pas seulement une source d'honneur et de gloire, mais aussi une source de défi et de difficulté. Les lutteurs universitaires doivent concourir avec des lutteurs d'autres pays qui ont souvent plus d'expérience, de style ou de motivation.
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  

<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 5 : Bourses Sportives et l'Avenir des Athlètes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Les bourses sportives offrent de nombreuses opportunités aux jeunes athlètes de montrer leur talent et de développer leurs compétences. L'une des opportunités les plus significatives est d'obtenir une bourse sportive pour étudier et compétitionner au niveau collégial. Dans ce chapitre, nous discuterons de l'importance des bourses sportives et de comment Sport Scholarships America peut vous aider à réaliser votre rêve.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 L'Importance des Bourses
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Une bourse sportive est un soutien financier fourni par une université à un étudiant-athlète pour combiner études et sport. Elle peut couvrir les frais de scolarité, les livres, le logement, les repas et autres dépenses. De plus, elle peut offrir des avantages tels que du tutorat académique, des soins médicaux, des installations d'entraînement et du matériel.
      <br />
      <br />
      Obtenir une bourse sportive est compétitif, avec de nombreux facteurs qui influent sur le processus d'attribution, notamment les performances académiques, sportives, la personnalité, la motivation, le leadership et l'adaptabilité. Il est donc important de bien se préparer et de se démarquer de la concurrence.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Comment Sport Scholarships America Peut Aider
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo de Sport Scholarships America, représentant du matériel sportif et une casquette de graduation, symbolisant le focus sur les bourses sportives pour les étudiants-athlètes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America est une organisation spécialisée dans l'orientation des jeunes talents sportifs vers des bourses sportives. Avec des experts passionnés et expérimentés, Sport Scholarships America soutient les étudiants-athlètes à chaque étape du parcours, de l'orientation initiale à la placement final. Leurs services comprennent :
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Un entretien personnel pour discuter des souhaits, des possibilités et des attentes de l'étudiant-athlète.</li>
        <li>Une évaluation professionnelle du niveau académique et sportif de l'étudiant-athlète.</li>
        <li>Un vaste réseau de contacts avec des entraîneurs, des universités et des collèges.</li>
        <li>Promotion efficace du profil et des vidéos de l'étudiant-athlète auprès des entraîneurs potentiels.</li>
        <li>Conseils d'experts sur les meilleures options en fonction des préférences, des objectifs et du budget de l'étudiant-athlète.</li>
        <li>Négociation des meilleures offres de bourses au nom de l'étudiant-athlète.</li>
        <li>Aide à la demande de documents nécessaires, tels que visa, relevé de notes, diplôme et tests d'admission.</li>
        <li>Préparation à la vie d'étudiant-athlète pendant sa bourse, y compris culture, langue, règles et conseils.</li>
        <li>Suivi et accompagnement pendant la période de la bourse.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vous voulez explorer les opportunités de bourses et atteindre vos objectifs sportifs et académiques ? <Link to="/fr/Services-Produits">Consultez la gamme complète</Link> des services offerts par Sport Scholarships America et planifiez une consultation dès aujourd'hui.
    </MKTypography>
  </MKBox>
</MKBox>


         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;