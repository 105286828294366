
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import SchoolIcon from '@mui/icons-material/School';
import StarIcon from '@mui/icons-material/Star';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2'; 
import Filter3Icon from '@mui/icons-material/Filter3'; 
import Filter4Icon from '@mui/icons-material/Filter4'; 
import Filter5Icon from '@mui/icons-material/Filter5'; 
import Filter6Icon from '@mui/icons-material/Filter6'; 
import Filter7Icon from '@mui/icons-material/Filter7'; 
import Filter8Icon from '@mui/icons-material/Filter8'; 
import Filter9Icon from '@mui/icons-material/Filter9';  // Import the number 1 icon
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import ScheduleIcon from '@mui/icons-material/Schedule';
import VideocamIcon from '@mui/icons-material/Videocam';
import QuizIcon from '@mui/icons-material/Quiz';
import RuleIcon from '@mui/icons-material/Rule';
import ChecklistIcon from '@mui/icons-material/Checklist';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import DescriptionIcon from '@mui/icons-material/Description';




const boxStyle = {
  boxShadow: '0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15)',
  background: '#f5f5f5', // Light grey background
  color: '#000', // Text color set to black for contrast
};

const arrowStyle = {
  borderRight: '7px solid white',
};

function Timeline() {
  return (
    <VerticalTimeline lineColor='rgb(33, 150, 243)'>
    <VerticalTimelineElement
      iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
      icon={<StarIcon />}
    />
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{ ...boxStyle, position: 'relative' }} // Lägg till relativ position i stilen
      contentArrowStyle={arrowStyle}
      date="Steg 1"
      iconStyle={{
        background: 'rgb(33, 150, 243)',
        color: '#fff',
        boxShadow: '0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15)', // Se till att skuggan appliceras här
        border: '2px solid white' // Behåll ramen
      }}
      icon={<RecordVoiceOverIcon />}
    >
      <div style={{
        position: 'absolute',
        top: '20px',
        right: '20px',
        opacity: 0.5 // Reducerad opacitet för en vattenmärkeseffekt
      }}>
        <Filter1Icon style={{ color: 'grey', fontSize: '48px' }} />
      </div>
  
      <h3 className="vertical-timeline-element-title">Inledande Konsultation:</h3>
      <p style={{
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Lade till Roboto som teckensnittsfamilj
        fontSize: '17px', // Ändrade teckenstorleken till 18px
        fontWeight: '400', // Se till att teckenvikten är normal
        marginBottom: '10px' // Justera värdet vid behov
      }}>
        Detta är din startpunkt - ögonblicket då vi anpassar dina drömmar och mål med en realistisk väg till ett idrottsstipendium i Amerika.
      </p>
  
      <ul style={{
        listStyleType: 'disc',
        marginLeft: '20px',
        fontSize: '17px'
      }}>
        <li>
          <strong>Personliga Mål:</strong> Klarlägg dina ambitioner och vad du söker i ett idrottsstipendium och universitetserfarenhet.
        </li>
        <li>
          <strong>Sportprestationer:</strong> Utvärdering av din nuvarande idrottsprestation och potential för tävling på universitetsnivå.
        </li>
        <li>
          <strong>Akademiska Ambitioner:</strong> Förståelse för dina akademiska intressen och mål, och hur du kan integrera dem med din idrott.
        </li>
        <li>
          <strong>Personliga Mål:</strong> Baserat på samtalet kommer jag att utarbeta en initial strategi och tidsplan för din resa till ett idrottsstipendium.
        </li>
      </ul>
      <p style={{
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Lade till Roboto som teckensnittsfamilj
        fontSize: '17px', // Ändrade teckenstorleken till 18px
        fontWeight: '400' // Se till att teckenvikten är normal
      }}>
        Tillsammans lägger vi grunden för en lyckad matchning med ett amerikanskt universitet och ställer in kursen för de nästa stegen i processen.
      </p>
    </VerticalTimelineElement>
  

    <VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Lägg till position relativt till stilen
  contentArrowStyle={arrowStyle}
  date="Steg 2"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<ScheduleIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Minskad opacitet för en vattenstämpel effekt
  }}>
    <Filter2Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Bedömning och Planering:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Lade till Roboto som typsnittsfamilj
    fontSize: '17px', // Ändrade typsnittsstorleken till 18px
    fontWeight: '400', // Se till att typsnittsvikten är normal
    marginBottom: '10px', // Justera värdet vid behov
  }}>
    Efter din inledande konsultation tar jag mig tid för en grundlig bedömning och skapande av en personlig plan.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Potentialanalys:</strong> Jag bedömer din idrottsliga och akademiska bakgrund för att bedöma dina möjligheter i USA.
    </li>
    <li>
      <strong>Åtgärdsplan:</strong> Jag utvecklar en skräddarsydd plan, inklusive en tidslinje och milstolpar, för att uppnå dina mål.
    </li>
    <li>
      <strong>Strategisk rådgivning:</strong> Råd om hur du kan förbättra din idrottsliga och akademiska prestation för att öka dina stipendiemöjligheter.
    </li>
    <li>
      <strong>Måluniversitet:</strong> Tillsammans identifierar vi en lista över måluniversitet som är i linje med din profil och dina ambitioner.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Lade till Roboto som typsnittsfamilj
    fontSize: '17px', // Ändrade typsnittsstorleken till 18px
    fontWeight: '400', // Se till att typsnittsvikten är normal
  }}>
    Denna detaljerade plan utgör grunden för din resa till ett idrottsstipendium och säkerställer att du är förberedd för varje följande steg.
  </p>
</VerticalTimelineElement>


<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Lägg till position relativt till stilen
  contentArrowStyle={arrowStyle}
  date="Steg 3"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<VideocamIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Minskad opacitet för en vattenstämpel effekt
  }}>
    <Filter3Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Videomaterial och Idrottsportfölj:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Lade till Roboto som typsnittsfamilj
    fontSize: '17px', // Ändrade typsnittsstorleken till 18px
    fontWeight: '400', // Se till att typsnittsvikten är normal
    marginBottom: '10px', // Justera värdet vid behov
  }}>
    Att skapa en övertygande idrottsportfölj och professionellt videomaterial är avgörande för din synlighet för tränare.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Videoproduktion:</strong> Jag ger råd om innehåll och övervakar produktionen av en höjdpunktvideo som optimerar din skicklighet.
    </li>
    <li>
      <strong>Portföljutveckling:</strong> Jag hjälper till att sammanställa en omfattande idrottsportfölj, inklusive statistik, prestationer och rekommendationer.
    </li>
    <li>
      <strong>Personlig berättelse:</strong> Din idrottsliga resa och prestationer vävs in i en personlig berättelse som sätter dig isär från andra.
    </li>
    <li>
      <strong>Digital presentation:</strong> Jag ser till att din portfölj och video är digitalt tillgängliga och delbara för tränare och scoutande lag.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Lade till Roboto som typsnittsfamilj
    fontSize: '17px', // Ändrade typsnittsstorleken till 18px
    fontWeight: '400', // Se till att typsnittsvikten är normal
  }}>
    Med detta steg säkerställer vi att din talang tydligt framhävs och attraherar uppmärksamheten från amerikanska universitet.
  </p>
</VerticalTimelineElement>




<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Lägg till position relativt till stilen
  contentArrowStyle={arrowStyle}
  date="Steg 4"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<QuizIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Minskad opacitet för en vattenstämpel effekt
  }}>
    <Filter4Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Akademisk Förberedelse:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Lade till Roboto som typsnittsfamilj
    fontSize: '17px', // Ändrade typsnittsstorleken till 18px
    fontWeight: '400', // Se till att typsnittsvikten är normal
    marginBottom: '10px', // Justera värdet vid behov
  }}>
    En god akademisk förberedelse är avgörande för antagning och framgång i USA.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Testförberedelse:</strong> Jag tillhandahåller studiematerial och vägledning för SAT/ACT och TOEFL/IELTS, viktiga tester för din antagning.
    </li>
    <li>
      <strong>Studieplanering:</strong> Tillsammans utvecklar vi en studieplan för att förbereda dig för examina, utan att försumma din idrottsträning.
    </li>
    <li>
      <strong>Ansökningsdokument:</strong> Jag hjälper till med att förbereda och revidera dina ansökningsessäer och andra nödvändiga dokument.
    </li>
    <li>
      <strong>Akademiskt Rådgivning:</strong> Du får råd om akademiska krav och hur du kan stärka din akademiska profil.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Lade till Roboto som typsnittsfamilj
    fontSize: '17px', // Ändrade typsnittsstorleken till 18px
    fontWeight: '400', // Se till att typsnittsvikten är normal
  }}>
    Mitt mål är att säkerställa att du är akademiskt förberedd inte bara för att bli antagen utan också för att utmärka dig vid universitetet.
  </p>
</VerticalTimelineElement>




<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Lägg till position relativt till stilen
  contentArrowStyle={arrowStyle}
  date="Steg 5"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<SchoolIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Minskad opacitet för en vattenstämpel effekt
  }}>
    <Filter5Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Marknadsföring vid Universitet:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Lade till Roboto som typsnittsfamilj
    fontSize: '17px', // Ändrade typsnittsstorleken till 18px
    fontWeight: '400', // Se till att typsnittsvikten är normal
    marginBottom: '10px', // Justera värdet vid behov
  }}>
    Att marknadsföra din profil vid universitet är ett nyckelsteg där min kompetens och omfattande nätverk kommer till nytta.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Nätverkets Viktiga Roll:</strong> Mitt omfattande nätverk inom amerikanska universitet är ovärderligt för att väcka uppmärksamhet kring din kandidatur.
    </li>
    <li>
      <strong>Strategisk Positionering:</strong> Jag använder min kunskap och erfarenhet för att strategiskt positionera din profil så att den sticker ut för rätt idrottsprogram och akademiska institutioner.
    </li>
    <li>
      <strong>Anpassningsbar Marknadsföringsplan:</strong> Jag skapar en flexibel och anpassad marknadsföringsplan som lyfter fram de unika aspekterna av din idrotts- och akademiska profil.
    </li>
    <li>
      <strong>Maximera Möjligheter:</strong> Genom riktad marknadsföring ser jag till att dina chanser att få ett stipendium maximeras, med fokus på det bästa för din personliga och idrottsliga ambition.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Lade till Roboto som typsnittsfamilj
    fontSize: '17px', // Ändrade typsnittsstorleken till 18px
    fontWeight: '400', // Se till att typsnittsvikten är normal
  }}>
    Mitt mervärde ligger i att effektivt belysa din talang och dina ambitioner, vilket väsentligt ökar chanserna till framgång. Här gör mitt nätverk, min förståelse för det amerikanska universitetssystemet och mitt personliga tillvägagångssätt en avgörande skillnad.
  </p>
</VerticalTimelineElement>



<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Lägg till position relativt till stilen
  contentArrowStyle={arrowStyle}
  date="Steg 6"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<RecordVoiceOverIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Minskad opacitet för en vattenstämpel effekt
  }}>
    <Filter6Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Kommunikation med Tränare:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Lade till Roboto som typsnittsfamilj
    fontSize: '17px', // Ändrade typsnittsstorleken till 18px
    fontWeight: '400', // Se till att typsnittsvikten är normal
    marginBottom: '10px', // Justera värdet vid behov
  }}>
    Effektiv kommunikation med tränare är avgörande. Jag agerar som din personliga representant för att göra ett starkt första intryck.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Direkta Introduktioner:</strong> Personliga introduktioner till tränare i mitt nätverk, anpassade till din idrottsgren.
    </li>
    <li>
      <strong>Kommunikationsstrategi:</strong> Utveckling av en effektiv kommunikationsstrategi för att framhäva dina kvaliteter och ambitioner.
    </li>
    <li>
      <strong>Guidning vid Förhandlingar:</strong> Vägledning i kommunikationen med tränare under förhandlingsfasen för idrottsstipendier.
    </li>
    <li>
      <strong>Kontinuerligt Stöd:</strong> Kontinuerligt stöd och rådgivning i all kommunikation för att säkerställa att du gör ett positivt och varaktigt intryck.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Lade till Roboto som typsnittsfamilj
    fontSize: '17px', // Ändrade typsnittsstorleken till 18px
    fontWeight: '400', // Se till att typsnittsvikten är normal
  }}>
    Min roll är att agera som en länk mellan dig och de amerikanska tränarna och skapa en kommunikationskanal som maximerar dina chanser till ett framgångsrikt idrottsstipendium.
  </p>
</VerticalTimelineElement>



<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Lägg till position relativt till stilen
  contentArrowStyle={arrowStyle}
  date="Steg 7"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<RuleIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Minskad opacitet för en vattenstämpel effekt
  }}>
    <Filter7Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Urval och Stipendieerbjudanden:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Lade till Roboto som typsnittsfamilj
    fontSize: '17px', // Ändrade typsnittsstorleken till 18px
    fontWeight: '400', // Se till att typsnittsvikten är normal
    marginBottom: '10px', // Justera värdet vid behov
  }}>
    Urvalsprocessen är avgörande; det är här resultaten av vårt gemensamma arbete omvandlas till stipendieerbjudanden.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Utvärdering av Erbjudanden:</strong> Tillsammans granskar vi de mottagna erbjudandena och diskuterar fördelar och nackdelar med varje alternativ.
    </li>
    <li>
      <strong>Strategisk Rådgivning:</strong> Jag ger strategisk rådgivning för att hjälpa dig att göra det bästa valet för din idrottsliga och akademiska framtid.
    </li>
    <li>
      <strong>Stöd vid Förhandlingar:</strong> Min erfarenhet av förhandlingar används för att uppnå de mest fördelaktiga villkoren för dig.
    </li>
    <li>
      <strong>Det Slutgiltiga Valet:</strong> Med min hjälp gör du ett välgrundat slutgiltigt val där din idrottskarriär och utbildning i USA börjar.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Lade till Roboto som typsnittsfamilj
    fontSize: '17px', // Ändrade typsnittsstorleken till 18px
    fontWeight: '400', // Se till att typsnittsvikten är normal
  }}>
    Min vägledning i denna fas syftar till att säkerställa det bästa möjliga resultatet för dina ambitioner och att säkerställa din framtid vid ett toppuniversitet i Amerika.
  </p>
</VerticalTimelineElement>


<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Lägg till position relativt till stilen
  contentArrowStyle={arrowStyle}
  date="Steg 8"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<DescriptionIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Minskad opacitet för en vattenstämpel effekt
  }}>
    <Filter8Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Visumansökan och Antagningsprocess:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Lade till Roboto som typsnittsfamilj
    fontSize: '17px', // Ändrade typsnittsstorleken till 18px
    fontWeight: '400', // Se till att typsnittsvikten är normal
    marginBottom: '10px', // Justera värdet vid behov
  }}>
    Efter urvalsprocessen börjar en kritisk fas: att slutföra din visumansökan och antagningsförfaranden.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Visumansökan:</strong> Vägledning vid ansökan om lämpligt studentvisum, inklusive insamling av nödvändig dokumentation.
    </li>
    <li>
      <strong>Assistans vid Antagning:</strong> Hjälp med att fylla i alla antagningsformulär och dokument för ditt valda universitet och tävling.
    </li>
    <li>
      <strong>Ekonomisk Planering:</strong> Rådgivning om ekonomiska frågor som stipendieavgifter, undervisning och levnadskostnader i USA.
    </li>
    <li>
      <strong>Slutkontroll:</strong> En noggrann slutkontroll för att säkerställa att alla administrativa och logistiska aspekter är hanterade.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Lade till Roboto som typsnittsfamilj
    fontSize: '17px', // Ändrade typsnittsstorleken till 18px
    fontWeight: '400', // Se till att typsnittsvikten är normal
  }}>
    Min vägledning i förberedelsen säkerställer att du startar ditt amerikanska äventyr självsäkert och väl informerad.
  </p>
</VerticalTimelineElement>



<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Lägg till position relativt till stilen
  contentArrowStyle={arrowStyle}
  date="Steg 9"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<ChecklistIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Minskad opacitet för en vattenstämpel effekt
  }}>
    <Filter8Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Förberedelse för Avresa:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Lade till Roboto som typsnittsfamilj
    fontSize: '17px', // Ändrade typsnittsstorleken till 18px
    fontWeight: '400', // Se till att typsnittsvikten är normal
    marginBottom: '10px', // Justera värdet vid behov
  }}>
    En smidig övergång till USA kräver noggrann förberedelse; de sista arrangemangen görs nu.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Checklista:</strong> En omfattande checklista för att säkerställa att inget glöms bort, från viktiga dokument till personliga nödvändigheter.
    </li>
    <li>
      <strong>Boende:</strong> Hjälp med att hitta lämpligt boende nära ditt universitet och med rätt bekvämligheter.
    </li>
    <li>
      <strong>Försäkring:</strong> Rådgivning om rätt försäkringar för hälsa, resor och vistelse.
    </li>
    <li>
      <strong>Briefing:</strong> En informationsstund om kulturella skillnader, livet i USA och praktiska tips för vardagen.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Lade till Roboto som typsnittsfamilj
    fontSize: '17px', // Ändrade typsnittsstorleken till 18px
    fontWeight: '400', // Se till att typsnittsvikten är normal
  }}>
    Min vägledning i förberedelsen säkerställer att du startar ditt amerikanska äventyr självsäkert och väl informerad.
  </p>
</VerticalTimelineElement>

<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Lägg till position relativt till stilen
  contentArrowStyle={arrowStyle}
  date="Steg 10"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<FlightTakeoffIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Minskad opacitet för en vattenstämpel effekt
  }}>
    <Filter9Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Avresa och Ankomst i USA:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Lade till Roboto som typsnittsfamilj
    fontSize: '17px', // Ändrade typsnittsstorleken till 18px
    fontWeight: '400', // Se till att typsnittsvikten är normal
    marginBottom: '10px', // Justera värdet vid behov
  }}>
    Det sista steget i din förberedelse har kommit: resan till Amerika.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Förberedelse för Avresa:</strong> Jag ser till att du är fullt förberedd för din avresa, med en detaljerad checklista och slutliga råd.
    </li>
    <li>
      <strong>Avskedsmoment:</strong> En stund att reflektera över denna betydande milstolpe och ta farväl innan du följer din dröm.
    </li>
    <li>
      <strong>Ankomst i USA:</strong> Vid ankomsten erbjuder jag stöd för att hjälpa dig att bosätta dig och navigera i din nya miljö.
    </li>
    <li>
      <strong>Första Dagarna:</strong> De första dagarna är spännande, och jag ser till att du får det stöd du behöver för en bra start.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Lade till Roboto som typsnittsfamilj
    fontSize: '17px', // Ändrade typsnittsstorleken till 18px
    fontWeight: '400', // Se till att typsnittsvikten är normal
  }}>
    Jag står vid din sida vid varje steg, från starten i Nederländerna till din ankomst på campus i USA, redo att påbörja dina studier och din idrottskarriär.
  </p>
</VerticalTimelineElement>


<VerticalTimelineElement
  iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
  icon={<SportsScoreIcon />}
/>
</VerticalTimeline>

  );
}

export default Timeline;
