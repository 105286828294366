import React, { useState } from "react";
import { Container, Grid, Box, Typography } from "@mui/material";
import MKBadge from "components/MKBadge";
import MKButton from "components/MKButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ExampleCard from "../ExampleCard";
import Hoodie_front from "assets/images/Producten/Hoodie_front.png";
import Hoodie_back from "assets/images/Producten/Hoodie_back.png";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import MKSnackbar from "components/MKSnackbar";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Link } from 'react-router-dom';


const sportsData = [
  {
    items: [
      {
        image: Hoodie_front,
        alt: "Frente de la sudadera con capucha de Sport Scholarships America con el logotipo en el pecho",
      },
      {
        image: Hoodie_front,
        alt: "Frente de la sudadera con capucha de Sport Scholarships America con el logotipo en el pecho",
      },
      {
        image: Hoodie_back,
        alt: "Parte posterior de la sudadera con capucha de Sport Scholarships America",
      },
      {
        image: Hoodie_back,
        alt: "Parte posterior de la sudadera con capucha de Sport Scholarships America",
      },
    ],
  },
];

function DesignBlocks() {
  const [selectedImage, setSelectedImage] = useState(Hoodie_front);
  const [selectedAlt, setSelectedAlt] = useState("Frente de la sudadera con capucha de Sport Scholarships America con el logotipo en el pecho"); // Establecer texto alternativo inicial
  const [quantity, setQuantity] = useState(0);
  const [show, setShow] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleIncrement = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 0) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const handleAddToCartClick = () => {
    if (quantity === 0) {
      setShowSnackbar(true);
      setShowModal(false); // Asegúrese de que el modal no se abra simultáneamente
    } else {
      setShowModal(true);
      setShowSnackbar(false); // Asegúrese de que el snackbar no se abra simultáneamente
    }
  };

  const toggleSnackbar = () => setShow(!show);
  const toggleModal = () => setShow(!show);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const renderData = sportsData.map(({ items }) => (
    <Grid container spacing={3} alignItems="flex-start">
      <Grid item xs={12} md={4}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <ExampleCard
              image={selectedImage}
              alt={selectedAlt} // Pase el texto alternativo actualizado aquí
              applyGradient={false}
              applyPopOut={false}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} justifyContent="center">
              {items.map(({ image, name, alt }) => (
                <Grid item xs={3} sm={3} md={3} key={name}>
                  <div
                    onClick={() => {
                      setSelectedImage(image);
                      setSelectedAlt(alt); // Actualice el texto alternativo aquí también
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <ExampleCard
                      image={image}
                      alt={alt} // Texto alternativo pasando correctamente
                      selected={selectedImage === image}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} container flexDirection="column" alignItems="flex-start" sx={{ textAlign: "left", my: 0, pl: { xs: 0, md: 4 } }}>
        <MKBadge 
          badgeContent="Sport Scholarships America" 
          variant="contained" 
          color="info" 
          container 
          circular 
          sx={{ mb: isSmallScreen ? 0 : 2, marginLeft: isSmallScreen ? 0 : 4 }} 
        />
        <Typography variant="h1" fontWeight="bold" sx={{ pb: isSmallScreen ? 0 : 2, marginLeft: isSmallScreen ? 0 : 4 }}>
          Sudadera con capucha de Sport Scholarships America
        </Typography>
        <Typography variant="body1" color="text" sx={{ pb: isSmallScreen ? 0 : 2, marginLeft: isSmallScreen ? 0 : 4 }}>
          Esta sudadera ofrece comodidad y estilo definitivos, independientemente de la temporada. Las características incluyen:

          <ul style={{ paddingLeft: "30px", paddingTop: "10px", paddingBottom: "10px" }}> {/* Aumente el valor de relleno según sea necesario */}
            <li>
              <strong>Comodidad para todas las estaciones:</strong> Hecho con material de alta calidad perfecto para cualquier clima.
            </li>
            <li>
              <strong>Diseño elegante:</strong> Un diseño atemporal que se adapta a cualquier atuendo.
            </li>
            <li>
              <strong>Calidad duradera:</strong> Construido para durar, lavado tras lavado.
            </li>
            <li>
              <strong>Capucha y bolsillos:</strong> Práctico y elegante, con capucha y bolsillos convenientes.
            </li>
          </ul>
          Opta por la comodidad y el estilo con esta versátil sudadera con capucha. ¡Perfecta para uso diario!
        </Typography>
        <Typography variant="h3" sx={{ pb: isSmallScreen ? 0 : 2, marginLeft: isSmallScreen ? 0 : 4 }}>
          10,00 €
        </Typography>
        <Grid container spacing={2} alignItems="flex-start" sx={{ marginLeft: isSmallScreen ? 0 : 2 }}>
          <Grid item>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid lightgray",
                padding: "5px 10px",
                marginTop: "20px",
                backgroundColor: "lightgrey",
                width: "200px",
                borderRadius: "15px",
                height: "48px",
              }}
            >
              <RemoveIcon onClick={handleDecrement} style={{ cursor: "pointer" }} color=""/>
              <span style={{ color: "" }}>{quantity}</span>
              <AddIcon onClick={handleIncrement} style={{ cursor: "pointer" }} color=""/>
            </div>
          </Grid>
          <Grid item>
            <MKButton 
              color="info" 
              style={{ height: "48px", marginTop: "20px" }} 
              startIcon={<AddShoppingCartIcon />}
              onClick={handleAddToCartClick} // Movido onClick aquí
            >
              Añadir al carrito
            </MKButton>
          </Grid>
          <MKSnackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            color="primary"
            icon="notifications"
            title="Sport Scholarships America"
            content="¡Ups, no puedes agregar 0 productos. Por favor, introduce una cantidad válida."
            dateTime="Hace 1 minuto"
            open={show}
            close={toggleSnackbar}
          />
        </Grid>
      </Grid>
    </Grid>
  ));

  return (
    <Box component="section" my={6} py={6}>
      <Container sx={{ mt: 0 }}>{renderData}</Container>

      <MKSnackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        color="primary"
        icon="notifications"
        title="Sport Scholarships America"
        content="Ups, no puedes añadir 0 productos. Por favor, introduce una cantidad válida."
        dateTime="Hace 0 minutos"
        open={showSnackbar}
        close={() => setShowSnackbar(false)}
      />

      <Modal 
        open={showModal} 
        onClose={() => setShowModal(false)} 
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Slide direction="down" in={showModal} timeout={500}>
          <MKBox
            position="relative"
            width="500px"
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
          >
            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
              <MKTypography variant="h5">Temporalmente agotado
              </MKTypography>
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={() => setShowModal(false)} />
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox p={2}>
              <MKTypography variant="body2" color="secondary" fontWeight="regular">
                Lamentablemente, el producto que seleccionaste está temporalmente agotado. Lamento las molestias. Estoy trabajando duro para reponer el stock lo antes posible.
                <br/>
                <br/>
                Si tienes alguna pregunta o necesitas más información, no dudes en contactarme. ¡Estoy aquí para ayudar!
              </MKTypography>
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox display="flex" justifyContent="space-between" p={1.5}>
              <MKButton variant="gradient" color="dark" onClick={() => setShowModal(false)}>
                Cerrar
              </MKButton>
              <MKButton 
                component={Link} 
                to="/es/Formulario-de-contacto" 
                variant="gradient" 
                color="info"
              >
                Formulario de contacto
              </MKButton>
            </MKBox>
          </MKBox>
        </Slide>
      </Modal>

    </Box>
  );
}

export default DesignBlocks;

