// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

import React from "react";
import { PopupButton } from "react-calendly";
import "./Button.css"
import { Helmet } from 'react-helmet';


// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbarDeutsch";
import DetailedFooter from "examples/Footers/DetailedFooter";

// Coworking page sections
import Testimonials from "Languages/Deutsch/pages/Homepage/LandingPage/sections/Overmij";
import StatsOne from "Languages/Deutsch/pages/Homepage/LandingPage/sections/StatsOne";
import BlogPostThree from "Languages/Deutsch/pages/Homepage/LandingPage/sections/Niveaus";
import MapUSA from "Languages/Deutsch/pages/Homepage/LandingPage/sections/MapUSA";
import Faq from "Languages/Deutsch/pages/Homepage/LandingPage/sections/Faq";
import NewsletterOne from "Languages/Deutsch/pages/Homepage/LandingPage/sections/Nieuwsbrief";
import SelectPicker from "Languages/Deutsch/pages/Homepage/LandingPage/sections/States";


// Routes
import routes from "routes_Deutsch";
import footerRoutes from "footer.routes_Deutsch";

import "../H1asH2.css"
// Images
import bgImage from "assets/images/Achtergronden/Home_BG.png";

function LandingPage() {
  return (
    <>
      <Helmet>
        <title>Ihr Weg zu einem Sportstipendium in Amerika - Sport Scholarships America</title>
        <meta name="description" content="Beginnen Sie Ihre Reise zu einem Sportstipendium in Amerika. Erhalten Sie fachkundige Beratung und alle Informationen, die Sie benötigen, um in den USA zu studieren und Sport zu treiben." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charset="UTF-8" />
        <meta name="robots" content="index, follow" />
        
        {/* Open Graph Tags */}
        
        
        
        
        

        {/* Favicon */}
        <link rel="icon" href="https://sportscholarshipsamerica.com/32x32Sportbeurs.png" />
      </Helmet>

      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://calendly.com/sportbeurs-amerika/intake",
          label: "TERMIN BUCHEN",
          color: "info",
        }}
        transparent
        light
         
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 0.5)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        {/* Visuell verstecktes Bild für Screenreader */}
        <img 
          src={bgImage} 
          alt="Universitätscampus in den USA im Hintergrund, mit einer grünen Rasenfläche davor. Im Vordergrund liegen drei Sportbälle: ein Basketball, Fußball und American Football, die den Fokus auf sportliche Stipendien für internationale Studierende symbolisieren." 
          style={{ 
            width: '1px', 
            height: '1px', 
            position: 'absolute', 
            overflow: 'hidden', 
            clip: 'rect(1px, 1px, 1px, 1px)', 
            whiteSpace: 'nowrap' 
          }} 
        />
        <Container>
          <Grid
            container
            item
            xs={12}
            md={7}
            justifyContent={{ xs: "center", md: "start" }}
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            <MKTypography
              variant="h1"
              className="h1-as-h2"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Ihr Pfad zu einem sportlichen Stipendium in Amerika
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              mt={1}
              pr={{ md: 12, lg: 24, xl: 32 }}
              opacity={0.8}
            >
              Fachberatung für Schüler-Athleten zu Top-Universitäten in den USA.
            </MKTypography>
            <Stack direction="row" spacing={1} mt={4} mb={3}>
              <PopupButton
                url="https://calendly.com/sportbeurs-amerika/intake"
                rootElement={document.getElementById("root")}
                text="TERMIN BUCHEN"
                className="custom-calendly-button"
              />
            </Stack>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <StatsOne />
        <Testimonials />
        <Container>
          <Grid container justifyContent="center" sx={{ pt: 6, pb: 0 }}>
            <Grid item xs={12} md={7} sx={{ textAlign: "center" }}>
              <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom mb={3}>
                Möglichkeiten in der ganzen Nation
              </MKTypography>
              <MKTypography color="text" variant="body2" align="center" gutterBottom mb={5}>
                Von den Stränden Kaliforniens über die Skyline von New York bis zur Küste Floridas: Ich verbinde Schüler-Athleten mit Top-Universitäten in allen 50 Staaten Amerikas
              </MKTypography>
            </Grid>
          </Grid>
        </Container>
        <MapUSA />
        <SelectPicker />
        <BlogPostThree />
        <Faq />
        <NewsletterOne />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DetailedFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default LandingPage;
