/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Page du produit : https://www.creative-tim.com/product/material-kit-pro-react
* Droits d'auteur 2023 Creative Tim (https://www.creative-tim.com)

Codé par www.creative-tim.com

 =========================================================

* L'avis de droits d'auteur ci-dessus et cet avis d'autorisation doivent être inclus dans toutes les copies ou parties substantielles du logiciel.
*/

// Composants matériels @mui
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Composants de Material Kit 2 PRO React
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Jeugd.png";
import post3 from "assets/images/Deal.png";
import "../../../../H1asH3.css";


function Profil() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center"></MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox 
                  display="flex" 
                  flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} 
                  justifyContent="space-between" 
                  alignItems="center" 
                  mb={1}
              >
                  <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Partie 2 : Mon Temps en Amérique</MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text">
  <br />
    Mon temps en Amérique a été comme un voyage époustouflant, rempli d'expériences inoubliables et de croissance personnelle. 
    Grâce au soccer, j'ai voyagé d'une côte à l'autre, accompagné d'un groupe de coéquipiers qui sont rapidement devenus certains de mes meilleurs amis. 
    Nos journées commençaient tôt avec des séances d'entraînement intensives à la salle de sport, quelque chose auquel je n'étais pas habitué aux Pays-Bas. 
    Ensuite, rapidement à l'université pour les cours, et retour à l'entraînement l'après-midi. C'était un engagement quotidien qui me mettait au défi physiquement et mentalement.
  <br /><br />
    Les matchs étaient des moments forts auxquels des milliers de fans se précipitaient, et à chaque fois, on avait l'impression de jouer pour quelque chose de plus grand que nous-mêmes. 
    L'adrénaline en entrant sur le terrain devant une foule passionnée est indescriptible. De plus, les voyages que nous avons faits pour les matchs étaient inoubliables. 
    Dans l'avion, nous passions souvent des heures à jouer aux cartes, et dans les halls d'hôtel, nous partagions des moments hilarants et des conversations passionnantes. 
    Les restaurants avec des plats délicieux sont devenus nos lieux de repas temporaires, et ces aventures culinaires ont ajouté une dimension supplémentaire à nos voyages. 
    Tout était méticuleusement organisé, des cartes dans l'avion aux restaurants où nous dînions. 
    Ces expériences m'ont permis d'entrevoir la grandeur de l'Amérique.
  <br /><br />
    De plus, j'ai eu l'opportunité de faire des stages avec les clubs professionnels de premier plan, y compris les Seattle Sounders. 
    Partager le terrain avec des joueurs comme le meilleur buteur de tous les temps des États-Unis, Clint Dempsey, était tout simplement surréaliste, un moment que je chérirai toujours. 
    Mais comme dans toute aventure, ce voyage a eu ses défis. 
    Dans ma dernière année, j'ai fait face à une grave blessure aux ischio-jambiers qui a temporairement stoppé mes ambitions dans le soccer. 
    Ce fut une période de réflexion personnelle et de résilience, dont je suis sorti plus fort.
  <br /><br />
    De plus, mon parcours académique en Amérique était tout aussi important. Malgré le programme intensif d'entraînement de soccer et les voyages étendus, 
    j'ai reçu un encadrement parfait pour poursuivre mes études. Ce n'était pas une tâche facile, car je manquais souvent les cours en raison de ma participation à l'équipe de soccer. 
    Cependant, j'étais déterminé à réussir, et je l'ai fait avec fierté, obtenant les plus grandes distinctions, magna cum laude. 
    Cette réussite académique témoignait de mon dévouement à la fois au sport et à mon éducation.
  <br /><br />
    Pendant mon aventure en Amérique, j'ai vécu des fêtes typiquement américaines qui semblaient tout droit sorties d'un film. 
    Ces rassemblements sociaux animés, remplis de musique, de danse et de rires forts, m'ont offert l'opportunité de grandir, non seulement en tant qu'athlète et étudiant, 
    mais aussi en tant qu'amateur de vie sociale. Ces fêtes ont ajouté une dimension supplémentaire à ma vie étudiante et ont enrichi mes expériences sociales.
  <br /><br />
    Cependant, l'Amérique signifiait plus pour moi que simplement le soccer, les matchs, les événements sociaux et les réalisations académiques. 
    C'était une opportunité d'élargir mon cercle social et de nouer des amitiés pour la vie. 
    Grâce à ces amitiés, j'ai pu découvrir le pays de nouvelles manières. 
    Des vacances de printemps au Mexique, où nous avons profité du soleil, de la mer et de la plage, à la célébration de Thanksgiving avec les familles d'amis, 
    que je peux maintenant appeler ma famille américaine. Leur chaleureux accueil m'a vraiment intégré à leur vie, 
    et ces expériences ont rendu mon temps en Amérique encore plus enrichissant.
  <br /><br />
  Cette période de ma vie a approfondi mon amour tant pour le soccer que pour l'éducation et m'a conduit sur le chemin que je suis aujourd'hui pour aider les autres à réaliser leurs rêves en Amérique. 
  Restez à l'écoute, car dans la Partie 3, je partagerai comment mon retour aux Pays-Bas et la création de mon entreprise ont orienté ma vie dans une nouvelle direction.
</MKTypography>
              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="Partie 1 : Grandir aux Pays-Bas"
                    action={{
                      type: "internal",
                      route: "/fr/À-propos-de-moi/Grandir",
                      color: "info",
                      label: "en savoir plus",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="Partie 3 : De Retour aux Pays-Bas"
                    action={{
                      type: "internal",
                      route: "/fr/À-propos-de-moi/Grandir",           
                      color: "info",
                      label: "en savoir plus",
                    }}
                  />
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profil;
