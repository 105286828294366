/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post2 from "assets/images/TijdUS.png";
import "../../../../H1asH3.css"


// Images
function Profil() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container element xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox 
                display="flex" 
                flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} 
                justifyContent="space-between" 
                alignItems="center" 
                mb={1}
              >
                <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Del 3: Tilbage i Holland</MKTypography>
              </MKBox>
              <MKTypography variant="body1" fontWeight="let" color="text">
                <br />
                Ved tilbagevenden til Holland oplevede jeg en blanding af nostalgi og beslutsomhed. Min dybt forankrede passion for fodbold blev nu ledsaget af en fornyet iver efter akademiske udfordringer. Amsterdam blev baggrunden for mit næste eventyr, hvor jeg forfulgte en kandidatgrad i kvantitativ finans, et område der vækkede både min analytiske sind og mine ambitioner.
                <br /><br />
                Efter at have afsluttet mine studier begyndte jeg at arbejde som konsulent. Selvom verden af tal og analyse fascinerede mig, følte jeg, at der manglede noget. Ekkoerne fra min amerikanske rejse fortsatte med at lyde i mig og mindede mig om den unikke mulighed, jeg havde oplevet, en mulighed jeg ønskede, at andre også skulle have.
                <br /><br />
                Derfor besluttede jeg at bruge mine erfaringer og viden til at guide andre unge, ambitiøse atleter. Inden jeg lancerede dette projekt, udnyttede jeg mit omfattende netværk i Amerika og engagerede mig med trænere, atleter, tidligere atleter og andre. Disse samtaler hjalp både mig og de fremtidige studerende, jeg ville støtte, med at forstå den bedste tilgang til disse muligheder.
                <br /><br />
                Under den udfordrende COVID-19-pandemi samarbejdede jeg med en førende agentur i Amerika. Selvom jeg kunne hjælpe færre internationale studerende på grund af begrænset stipendiemuligheder, udnyttede jeg min ekspertise til at hjælpe amerikanske studerende. Denne erfaring udvidede ikke kun mit netværk betydeligt, men gav også indsigt i det amerikanske stipendiemarked, som jeg nu kunne anvende mere effektivt i Europa.
                <br /><br />
                Udfordringerne under pandemien styrkede min mission om at guide unge talenter mod amerikanske universiteter og sportsstipendier. På trods af vanskelighederne gav det en følelse af opfyldelse, som jeg aldrig havde oplevet før. Det var min måde at give tilbage til det fællesskab, der engang bød mig varmt velkommen i USA.
                <br /><br />
                Dette er min historie, og jeg er stolt af, at jeg nu kan hjælpe andre med at skrive deres egne i det land med uendelige muligheder. Hvis du vil vide mere om mit firma og mine kunders rejse for at realisere deres drømme, så læs videre på min hjemmeside. Hvis du har spørgsmål eller ønsker at kontakte mig, så tøv ikke med at gøre det.
              </MKTypography>
              <hr style={{ margin: '20px 0'}} />
              <Grid item xs={12} lg={6} mb={{ xs: 3, lg: 0 }}>
                <TransparentBlogCard
                  image={post2}
                  title="Del 2: Min Tid i Amerika"
                  action={{
                    type: "internal",
                    route: "/da/Om-mig/Min-tid-i-Amerika",
                    color: "info",
                    label: "Læs mere",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profil;
