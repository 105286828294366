import { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MKTypography from "components/MKTypography";

import MKBox from "components/MKBox";

import SAT from "assets/images/Producten/SAT_kaft.png";
import ACT from "assets/images/Producten/ACT_kaft.png";
import TOEFL from "assets/images/Producten/Toefl_kaft.png";
import Hoodie from "assets/images/Producten/Hoodie_front.png";
import Shirt from "assets/images/Producten/Shirt_front.png";
import { Link } from 'react-scroll';



import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
function Produtos() {
  const [abaAtiva, setAbaAtiva] = useState(1);
  const [tipoAba, setTipoAba] = useState("mensal");

  const handleTipoAba = ({ currentTarget }, newValue) => {
    setAbaAtiva(newValue);
    setTipoAba(currentTarget.id);
  };

  
  return (
    <section id="secaoProdutos">
      <Container sx={{ pb: { xs: 12, lg: 8 }, pt: 0 }}>
      <Grid container item xs={12} justifyContent="center" md={8} mx="auto" textAlign="center">
  {/* Envolver cada MKTypography em seu próprio elemento Grid */}
  <Grid item xs={12}> {/* Largura total para o título */}
    <MKTypography variant="h3" color="text">Produtos</MKTypography>
  </Grid>
  <Grid item xs={12}> {/* Largura total para o texto */}
    <MKTypography variant="body2" color="text">
    De guias de estudo a mercadorias da Sport Scholarships America





</MKTypography>
  </Grid>
  <Grid
          container
          item
          xs={12}
          md={10}
          lg={8}
          justifyContent="center"
          textAlign="center"
          mx="auto"
          mt={6}
        >
          <AppBar position="static">
          <Tabs value={abaAtiva} onChange={handleTipoAba}>
  <Tab
    id="mensal"
    label={
      <Link 
        to="secaoServicos" 
        spy={true}
        smooth={true}
        duration={500}
        offset={-70}
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <MKBox py={0.5} px={2} color="inherit">
          Serviços
        </MKBox>
      </Link>
    }
  />
  <Tab
    id="anual"
    label={
      <Link 
        to="secaoProdutos" 
        spy={true}
        smooth={true}
        duration={500}
        offset={-70}
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <MKBox py={0.5} px={2} color="inherit">
          Produtos
        </MKBox>
      </Link>
    }
  />
</Tabs>
          </AppBar>
        </Grid>
          
          
        <Grid container spacing={3} mt={6} mb={6}>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="Guia de Estudo SAT"
      image={SAT}
      description="Prepare-se para o SAT"
      action={{ type: "internal", route: "/pt/Serviços-Produtos/SAT-guide", label: "Encomendar", color: "primary" }}
    />
  </Grid>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="Guia de Estudo ACT"
      image={ACT}
      description="Prepare-se para o ACT"
      action={{ type: "internal", route: "/pt/Serviços-Produtos/ACT-guide", label: "Encomendar", color: "primary" }}
    />
  </Grid>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="Guia de Estudo TOEFL"
      image={TOEFL}
      description="Prepare-se para o TOEFL"
      action={{ type: "internal", route: "/pt/Serviços-Produtos/TOEFL-guide", label: "Encomendar", color: "primary" }}
    />
  </Grid>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="Hoodie"
      image={Hoodie}
      description="O Hoodie da Sport Scholarships America"
      action={{ type: "internal", route: "/pt/Serviços-Produtos/Hoodie", label: "Encomendar", color: "primary" }}
    />
  </Grid>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="Camiseta"
      image={Shirt}
      description="A Camiseta da Sport Scholarships America"
      action={{ type: "internal", route: "/pt/Serviços-Produtos/T-shirt", label: "Encomendar", color: "primary" }}
    />
  </Grid>
</Grid>

          
        
        </Grid>
      </Container>
    </section>
  );
}

export default Produtos;




