/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Produkt sida: https://www.creative-tim.com/product/material-kit-pro-react
* Upphovsrätt 2023 Creative Tim (https://www.creative-tim.com)

Kodad av www.creative-tim.com

 =========================================================

* Ovanstående upphovsrättsliga meddelande och detta tillståndsmeddelande ska inkluderas i alla kopior eller väsentliga delar av programvaran.
*/

// @mui materialkomponenter
import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React komponenter
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import minBild from "assets/images/SoccerfotoKayDuit1.png";
import andraBilden from "assets/images/SoccerfotoKayDuit2.png"

function Testimonials() {
  const [nuvarandeBild, setNuvarandeBild] = useState(minBild);
  const [opacity, setOpacity] = useState(1);

  const minBildAlt = "Kay Duit i aktion under en fotbollsmatch, spelar för University of Tulsa, dynamisk och fokuserad på bollen"; // Ersätt med din faktiska alternativa text
  const andraBildAlt = "Kay Duit som fotbollsspelare vid University of Tulsa, aktiv i en spelaktion på fotbollsplanen."; // Ersätt med din faktiska alternativa text

  useEffect(() => {
    const bytBild = () => {
      setOpacity(0); // börja bleknar ut
      setTimeout(() => {
        setNuvarandeBild(prev => prev === minBild ? andraBilden : minBild); // byt bild efter halva varaktigheten
        setOpacity(1); // börja bleknar in
      }, 500); // detta är hälften av varaktigheten för övergången
    };

    const intervall = setInterval(bytBild, 10000); // byt var 10:e sekund

    return () => clearInterval(intervall);
  }, []);

  return (
    <MKBox component="section" position="relative" py={8}>
        <Container>
          <Grid container justifyContent="center" sx={{ pt: 0, pb: 0 }}>
            <Grid item xs={12} md={7} sx={{ textAlign: "center" }}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom mb={3}>
            Min berättelse: Från passion till yrke                        </MKTypography>
            <MKTypography color="text" variant="body2" align="center" gutterBottom mb={14}>
            Hur min erfarenhet av den amerikanska sportvärlden nu är nyckeln till att vägleda unga talanger till framgång.               </MKTypography>

              
            </Grid>
          </Grid>
        </Container>
     
      <Container sx={{ maxWidth: "100% !important" }}>
      
      <Grid container item xs={12} sm={10} sx={{ mx: "auto" }}>
          
          <MKBox variant="gradient" bgColor="dark" borderRadius="lg" width="100%" py={6}>
            <Grid container>
              
              <Grid item xs={12} md={6} xl={4} sx={{ position: "relative", px: 6 }}>
              <MKBox
                component="img"
                src={nuvarandeBild}
                alt={nuvarandeBild === minBild ? minBildAlt : andraBildAlt}
                borderRadius="md"
                maxWidth="300px"
                width="100%"
                position="relative"
                mt={-12}
                style={{ transition: 'opacity 1s', opacity: opacity }} // inline-stil för övergången
                    />
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                xl={4}
                sx={{
                  position: "relative",
                  px: { xs: 6, md: 2 },
                  mt: { xs: 3, md: 0 },
                  my: { xs: 0, md: "auto" },
                  color: ({ palette: { white } }) => white.main,
                }}
              >
                <Icon fontSize="large">format_quote</Icon>
                <MKTypography
                  variant="body2"
                  color="white"
                  fontWeight="light"
                  mb={2}
                  sx={{ fontSize: "1.125rem" }}
                >
                Som före detta fotbollsspelare vid University of Tulsa hade jag privilegiet att uppleva fyra oförglömliga år i hjärtat av den amerikanska studentkulturen. Den passionen och erfarenheten driver mig nu att vägleda europeiska studenter på deras egen fotbollsresa i USA. Tillsammans kommer vi att göra din amerikanska sportdröm till verklighet.
                </MKTypography>
                <MKTypography variant="button" fontWeight="bold" color="white">
                  Kay Duit -{" "}
                  <MKTypography variant="caption" color="white">
                    Grundare
                  </MKTypography>
                </MKTypography>
              </Grid>
              <Grid item xs={1} />
              <Grid
                item
                xs={12}
                xl={2}
                sx={{ px: { xs: 6, xl: 0 }, my: { xs: 0, xl: "auto" }, lineHeight: 1 }}
              >
                <MKTypography variant="h5" color="white" mt={{ xs: 6, xl: 0 }} mb={1}>
                4-årig brevvinnare
                <br />
                3x AAC-mästare
                              
                </MKTypography>
                
                <MKTypography
                  component="a"
                  href="/sv/Om-Mig"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  sx={{
                    width: "maxinnehåll",
                    display: "flex",
                    alignItems: "centrum",


                    "& .material-icons-round": {
                      fontSize: "1.125rem",
                      transform: `översättX(3px)`,
                      övergång: "transformera 0,2s kubisk-bézier(0,34, 1,61, 0,7, 1,3)",
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `översättX(6px)`,
                    },
                  }}
                >
                  Läs mer
                  <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MKTypography>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Testimonials;
