/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


// @mui material components
import React, { useState } from 'react'; // Step 1: Import useState


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Logos/NJCAA.png";
import post3 from "assets/images/Logos/NAIA.png";



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {

  const articleStructure = [
    { id: 'intro', title: 'NCAA: Hjertet av College-sport i Amerika', isSubheader: false },
  
    { id: 'Kapittel 1', title: 'NCAA\'s Opprinnelse og Vekst', isSubheader: true },
    { id: '1.1', title: 'NCAA\'s Tre Ansikter: Divisjonene I, II og III', isSubheader: true },
    { id: '1.2', title: 'Den Akademiske Rollen', isSubheader: true },
  
    { id: 'Kapittel 2', title: 'Finanser, Medier og Markedsføring', isSubheader: true },
    { id: '2.1', title: 'Utfordringer og Fremtid', isSubheader: true },
    { id: '2.2', title: 'Avslutning', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
     <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="intro" mb={3}>


              
<MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>NCAA: Hjertet av College-sport i Amerika</MKTypography>
</MKBox>
<MKBox id="Kapittel 1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
NCAA's Opprinnelse og Vekst
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
National Collegiate Athletic Association, bedre kjent som NCAA, har vært kjernen i det amerikanske collegiale sportslandskapet i over et århundre. Grunnlagt i 1906, har denne organisasjonen utviklet seg fra en liten regulatorisk enhet til en mektig og innflytelsesrik organisasjon som påvirker livene til tusenvis av studentutøvere, trenere og sportsentusiaster.
<br />
<br />
I de tidlige årene av NCAA var fokuset hovedsakelig på å etablere regler og sikre sikkerheten i idrett. Imidlertid utvidet NCAA sin rolle etter hvert som tiden gikk, og ble stadig mer involvert i å regulere alle aspekter av collegial idrett, fra akademiske krav til økonomiske spørsmål.
</MKTypography>

<MKBox id="1.1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
NCAA's Tre Ansikter: Divisjonene I, II og III
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
NCAA er delt inn i tre divisjoner, hver med sitt eget unike preg og nivå av konkurranse:
<br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Divisjon I:{' '}
</MKTypography>
er kjent som høydepunktet av collegial idrett, der skoler med de største budsjettene, de beste fasilitetene og de mest talentfulle utøverne samles. Universitetene i denne divisjonen er ofte store og har betydelige ressurser til disposisjon. De tilbyr full og delvis idrettsstipend og tiltrekker seg topputøvere fra hele landet og verden. Konkurransen er intens, og presset for å prestere er høyt. Likevel er det også en scene der stjerner blir født, og der drømmene til unge utøvere kan gå i oppfyllelse. Divisjonen er delt inn i tre kategorier: Football Bowl Subdivision (FBS), Football Championship Subdivision (FCS) og skoler uten fotball på divisjon I-nivå.  <br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Divisjon II:{' '}
</MKTypography>
representerer en mer balansert tilnærming til collegial idrett, der idrett, akademisk prestasjon og engasjement på campus går hånd i hånd. Universitetene i denne divisjonen er ofte mindre enn de i Divisjon I, men tilbyr fortsatt et høyt nivå av konkurranse. Utøvere i Divisjon II kan forvente støtte både på banen og i klasserommet, med mål om å tilby en komplett collegial opplevelse.  <br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Divisjon III:{' '}
</MKTypography>
legger til slutt vekt på den totale utviklingen av studentutøveren. Sport er viktig, men det er bare en del av den bredere collegiale opplevelsen. Det tilbys ingen idrettsstipend i Divisjon III, men utøvere har muligheten til å delta i konkurransedyktige idretter mens de også er fullt integrert i det akademiske og sosiale livet på campus.</MKTypography>

<MKBox id="1.2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Den Akademiske Rollen
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
NCAA tar den akademiske prestasjonen til sine studentutøvere veldig alvorlig. Alle utøvere må oppfylle strenge akademiske krav for å være kvalifisert for konkurranse, og universitetene selv er ansvarlige for å tilby nødvendig støtte for å sikre at utøvere lykkes i klasserommet. Dette inkluderer akademiske rådgivere, veiledere og spesielle studieprogrammer, alt designet for å hjelpe studentutøvere med å balansere sine idrettslige og akademiske forpliktelser.                <br />
<br />
</MKTypography>

                                                  





                                  







<MKBox id="Kapittel 2" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Finanser, Medier og Markedsføring
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              NCAA er også en finansiell tyngdekraft, med milliarder av dollar som strømmer gjennom organisasjonen årlig takket være TV-kontrakter, sponsorer og merchandise. Disse inntektene brukes til å finansiere stipender, forbedre fasiliteter og forbedre den generelle idrettsopplevelsen for studentutøvere. Samtidig har populariteten til universitetssport ført til lukrative markedsførings- og medieavtaler, noe som gjør universitetsidrett til en grunnleggende del av amerikansk idrettskultur.

<br />
                </MKTypography>

                <MKBox id="2.1" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Utfordringer og Fremtid
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              Til tross for sin suksess står NCAA også overfor betydelige utfordringer. Problemer som amatørstatusen til studentutøvere, idrettsutøveres mentale helse og jakten på likestilling innen idrett er bare noen av temaene organisasjonen må adressere i sin streben etter en bedre fremtid for universitetssport.
<br />
<br />
NCAA forblir en avgjørende aktør i det amerikanske utdanningssystemet, og dens innvirkning på livet til unge idrettsutøvere og den bredere idrettsverdenen er uomtvistelig. Mens den ser fremover mot fremtiden, er det klart at organisasjonen vil fortsette å spille en nøkkelrolle i utformingen av det amerikanske universitetsidrettslandskapet.


                </MKTypography>

                <MKBox id="2.2" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Avslutning
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              National Collegiate Athletic Association er mer enn bare en idrettsorganisasjon; det er en avgjørende del av det amerikanske utdannings- og idrettslandskapet. Med sin rike historie, komplekse struktur og dype innvirkning på livet til utallige individer, forblir NCAA en fascinerende og vital del av amerikansk kultur. Dens fremtid vil utvilsomt være fylt med utfordringer og muligheter, men én ting er sikkert: NCAA vil fortsette å streve etter fremragende, både på banen og i klasserommet.




<br />


                </MKTypography>



              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="NJCAA"
                    description="NJCAA: Springbrettet til Store Universiteter og NCAA"
                    action={{
                      type: "internal",
                      route: "/no/NJCAA",
                      color: "info",
                      label: "Les Mer",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="NAIA"
                    description="NAIA: Et Alternativt Rute i Amerikansk College Sport"
                    action={{
                      type: "internal",
                      route: "/no/NAIA",
                      color: "info",
                      label: "Les Mer",
                    }}
                    />
                  </Grid>
                </Grid>
  
              </Grid>
          </Grid>
        </Container>
      </MKBox>
    );
  }
  
  export default Beschrijving;