/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Baseball1.jpg";
import Image2 from "assets/images/Sports_Images/Baseball2.jpeg";
import Image3 from "assets/images/Sports_Images/Baseball3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';

import "../../../../H1asH3.css"

const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Baseball et Étudier en Amérique : Un Rêve Qui Peut Devenir Réalité', isSubheader: false },
  
    { id: 'Chapitre 1', title: 'Chapitre 1 : L\'Histoire et le Développement du Baseball Universitaire', isSubheader: false },
    { id: '1.1', title: '1.1 Premières Années et le Rôle des Collèges', isSubheader: true },
    { id: '1.2', title: '1.2 Croissance et Professionnalisation', isSubheader: true },
  
    { id: 'Chapitre 2', title: 'Chapitre 2 : Le Baseball Universitaire Aujourd\'hui', isSubheader: false },
    { id: '2.1', title: '2.1 Ligues Majeures et Conférences', isSubheader: true },
    { id: '2.2', title: '2.2 Programmes de Premier Plan et Équipes Prestigieuses', isSubheader: true },
  
    { id: 'Chapitre 3', title: 'Chapitre 3 : Du Collège aux Professionnels', isSubheader: false },
    { id: '3.1', title: '3.1 La Voie vers le Professionnalisme', isSubheader: true },
    { id: '3.2', title: '3.2 La Draft de la MLB et Autres Opportunités Professionnelles', isSubheader: true },
  
    { id: 'Chapitre 4', title: 'Chapitre 4 : Influences Internationales', isSubheader: false },
    { id: '4.1', title: '4.1 Joueurs Étrangers dans le Baseball Universitaire', isSubheader: true },
    { id: '4.2', title: '4.2 Américains à l\'Étranger', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Chapitre 5 : Bourses Sportives et l\'Avenir des Athlètes', isSubheader: false },
    { id: '5.1', title: '5.1 L\'Importance des Bourses', isSubheader: true },
    { id: '5.2', title: '5.2 Comment Sport Scholarships America Peut Aider', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Sommaire
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Cacher</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Afficher</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>






















          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Baseball et Étudier en Amérique : Un Rêve Qui Peut Devenir Réalité
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Vue aérienne du stade lors de la Série mondiale universitaire."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        La Série mondiale universitaire
      </figcaption>
    </figure>
    Le baseball est l'un des sports les plus populaires et significatifs aux États-Unis. Des millions de personnes suivent la Major League Baseball (MLB), la plus haute ligue de baseball professionnelle au monde. Mais avant de pouvoir accéder à la MLB, vous devez d'abord développer et prouver vos compétences à un autre niveau : le baseball universitaire.
    <br />
    <br />
    Le baseball universitaire est pratiqué par des étudiants dans les universités et collèges américains. C'est un sport très compétitif et prestigieux qui attire beaucoup d'attention et de talent. Le baseball universitaire constitue une étape cruciale pour les joueurs souhaitant jouer professionnellement et aussi une opportunité de recevoir une bonne éducation.
    <br />
    <br />
    Mais comment accéder au baseball universitaire ? Et comment obtenir une bourse pour étudier et jouer aux États-Unis ? C'est là que Sportbeursamerika.nl peut vous aider. Sportbeursamerika.nl est une organisation spécialisée dans l'accompagnement et le soutien des étudiants-athlètes qui souhaitent réaliser leurs rêves. Je peux vous aider avec tout ce dont vous avez besoin pour trouver une université adaptée, obtenir une bourse et vous préparer à la vie aux États-Unis.
    <br />
    <br />
    Dans cet article, nous vous dirons tout ce que vous devez savoir sur le baseball universitaire aux États-Unis. Nous explorerons l'histoire et le développement de ce sport, les principales ligues et conférences, les programmes de premier plan et les équipes prestigieuses, le chemin vers les pros, les influences internationales et, bien sûr, l'importance des bourses d'études. Après avoir lu cet article, vous aurez une meilleure compréhension de ce que le baseball universitaire implique et comment vous pouvez augmenter vos chances d'en faire partie.
    <br />
    <br />
    Êtes-vous prêt à réaliser votre rêve ? Commençons !
  </MKTypography>
</MKBox>

<MKBox id="Chapitre 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 1 : L'Histoire et le Développement du Baseball Universitaire
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le baseball est un sport qui existe depuis plus d'un siècle. Mais comment a-t-il commencé et grandi pour devenir l'un des sports les plus populaires et significatifs aux États-Unis ? Dans ce chapitre, nous vous fournirons un bref aperçu de l'histoire et du développement du baseball universitaire.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Premières Années et le Rôle des Collèges
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      L'origine du baseball n'est pas entièrement claire. Il existe différentes théories sur la manière dont et où le jeu a commencé. Certains disent qu'il descend de jeux européens anciens comme le cricket, le rounders ou le stoolball. D'autres affirment qu'il est issu d'un jeu amérindien appelé crosse. D'autres encore suggèrent qu'il a été inventé par un soldat américain nommé Abner Doubleday en 1839.
      <br />
      <br />
      Ce qui est certain, c'est que le baseball est rapidement devenu populaire aux États-Unis, notamment dans les villes de la côte Est. Il était pratiqué par des amateurs, des clubs, des ouvriers d'usine et des soldats. Il était également pratiqué par des étudiants dans les universités et collèges, qui avaient leurs propres équipes et règles.
      <br />
      <br />
      Le premier match de baseball intercollégial connu aux États-Unis a eu lieu en 1859 entre Amherst College et Williams College, deux institutions académiques respectées du Massachusetts. Ce match est historiquement significatif en tant qu'un des premiers exemples de matchs de baseball de niveau collégial, précurseur du développement ultérieur du baseball universitaire. Bien que le résultat exact et le déroulement du match puissent varier selon les sources historiques, cet événement marque un point de départ important dans l'histoire du baseball intercollégial. Il illustre comment le baseball est devenu populaire parmi les étudiants à ses débuts et comment les universités sont devenues des lieux importants pour la croissance et le développement du sport aux États-Unis.
      <br />
      <br />
      Dans les années 1860 et 1870, le nombre d'équipes collégiales a régulièrement augmenté. Certaines des premières équipes incluaient Harvard, Yale, Princeton, Dartmouth, Brown et Columbia. Ces équipes jouaient principalement les unes contre les autres ou contre des clubs locaux.
      <br />
      <br />
      En 1879, la première conférence de baseball intercollégiale a eu lieu à Springfield, Massachusetts. L'objectif était de standardiser et d'améliorer les règles et les normes du jeu. La conférence a conduit à la formation de l'Association de Baseball des Collèges Américains, avec six prestigieuses institutions éducatives - Harvard, Yale, Princeton, Amherst, Dartmouth et Brown - en faisant partie. Cela marqua le début d'une longue tradition de championnats nationaux de baseball universitaire.
    </MKTypography>
  </MKBox>

  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Croissance et Professionnalisation
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      À la fin du XIXe et au début du XXe siècle, le baseball est devenu de plus en plus populaire et professionnel aux États-Unis. La Ligue nationale (NL) et la Ligue américaine (AL) ont été établies comme les principales ligues de baseball professionnel, attirant de nombreux joueurs, fans et argent.
      <br />
      <br />
      Cela a également eu un impact sur le baseball universitaire. De nombreux joueurs collégiaux ont été recrutés ou signés par des équipes professionnelles. Certains ont quitté leurs universités pour jouer professionnellement, tandis que d'autres ont continué leurs études mais ont également joué à temps partiel pour des équipes professionnelles.
      <br />
      <br />
      Cela a entraîné divers problèmes et conflits dans le baseball universitaire. Certaines universités s'opposaient à l'idée que leurs joueurs soient rémunérés pour jouer, considérant cela contraire à l'esprit amateur et à l'intégrité académique des sports universitaires. Elles voulaient également garder leurs meilleurs joueurs pour leurs propres équipes.
      <br />
      <br />
      D'autres universités étaient plus tolérantes ou pragmatiques à l'égard du phénomène des joueurs professionnels. Elles reconnaissaient que le baseball était un sport lucratif et prestigieux qui pouvait apporter de nombreux avantages à leurs institutions. Elles voulaient également rivaliser avec d'autres universités au plus haut niveau.
      <br />
      <br />
      Pour résoudre ces problèmes, diverses mesures ont été prises par différentes organisations. L'IBA a été remplacée par la National Collegiate Athletic Association (NCAA) en 1906. La NCAA a pris en charge la réglementation et l'organisation du baseball universitaire et d'autres sports. La NCAA a également établi des règles et des lignes directrices pour l'éligibilité et le comportement des joueurs universitaires.
      <br />
      <br />
      La NCAA a également organisé la première Série mondiale universitaire (CWS) en 1947. Il s'agissait du nouveau championnat national de baseball universitaire, organisé annuellement à Omaha, Nebraska. La CWS est rapidement devenue l'un des événements les plus prestigieux et populaires du sport universitaire.
      <br />
      <br />
      En plus de la NCAA, il y avait d'autres organisations impliquées dans le baseball universitaire. L'une d'elles était l'Association Nationale des Athlètes Interuniversitaires (NAIA), établie en 1937. La NAIA était une alternative à la NCAA, se concentrant sur les petites universités et collèges. La NAIA a également organisé son propre championnat national de baseball universitaire.
      <br />
      <br />
      Une autre organisation était la National Junior College Athletic Association (NJCAA), fondée en 1938. La NJCAA était une organisation pour les collèges communautaires offrant des programmes de deux ans. La NJCAA a également organisé son propre championnat national de baseball universitaire.
      <br />
      <br />
      Ces organisations ont toutes contribué à la croissance et à la professionnalisation du baseball universitaire. Elles ont créé plus d'opportunités et de diversité pour les joueurs, les entraîneurs, les écoles et les fans. Elles ont également fait du baseball universitaire une partie intégrante du système de baseball américain.
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  





<MKBox id="Chapitre 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 2 : Le Baseball Universitaire Aujourd'hui
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le baseball universitaire est l'un des sports les plus populaires et significatifs aux États-Unis aujourd'hui. Il attire des millions de fans, l'attention des médias et des sponsors. Il produit des milliers de joueurs, d'entraîneurs et d'officiels. Il offre des centaines de bourses, de récompenses et d'honneurs.
    <br />
    <br />
    Mais à quoi ressemble le baseball universitaire aujourd'hui ? Comment le système est-il organisé ? Quelles sont les principales ligues et conférences ? Quels sont les programmes de premier plan et les équipes prestigieuses ? Dans ce chapitre, nous vous fournirons un aperçu du baseball universitaire aujourd'hui.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Ligues Majeures et Conférences
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Barry Bonds en action pour Arizona State dans le baseball universitaire."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Barry Bonds en tant que joueur pour Arizona State
        </figcaption>
      </figure>
      Comme nous l'avons vu dans le chapitre précédent, plusieurs organisations sont impliquées dans le baseball universitaire. La plus importante d'entre elles est la NCAA, représentant plus de 1 200 universités et collèges. La NCAA est divisée en trois divisions : Division I, Division II et Division III.
      <br />
      <br />
      La Division I est le niveau le plus élevé du baseball universitaire, composée d'environ 300 écoles, réparties en 31 conférences. Chaque conférence a ses propres règles, calendriers et championnats. Les conférences les plus connues incluent l'Atlantic Coast Conference (ACC), la Big Ten Conference (B1G), la Big 12 Conference (B12), la Pac-12 Conference (P12) et la Southeastern Conference (SEC).
      <br />
      <br />
      La Division II est le niveau intermédiaire du baseball universitaire, composée d'environ 270 écoles, réparties en 23 conférences. Les conférences sont généralement orientées régionalement, telles que la California Collegiate Athletic Association (CCAA), la Gulf South Conference (GSC) et la Northeast-10 Conference (NE10).
      <br />
      <br />
      La Division III est le niveau le plus bas du baseball universitaire, composée d'environ 380 écoles, réparties en 43 conférences. Les conférences sont également généralement orientées régionalement, telles que la New England Small College Athletic Conference (NESCAC), la Southern California Intercollegiate Athletic Conference (SCIAC) et la Wisconsin Intercollegiate Athletic Conference (WIAC).
      <br />
      <br />
      En plus de la NCAA, d'autres organisations sont impliquées dans le baseball universitaire. L'une d'entre elles est la NAIA, représentant environ 190 écoles. La NAIA est divisée en 21 conférences, telles que l'American Midwest Conference (AMC), la Golden State Athletic Conference (GSAC) et la Heart of America Athletic Conference (HAAC).
      <br />
      <br />
      Une autre organisation est la NJCAA, représentant environ 500 collèges communautaires. La NJCAA est divisée en trois divisions : Division I, Division II et Division III. Chaque division est ensuite subdivisée en districts et régions.
      <br />
      <br />
      Ces organisations organisent leurs propres championnats nationaux de baseball universitaire chaque année. Le championnat de la Division I de la NCAA est l'événement le plus connu et prestigieux, souvent appelé Série mondiale universitaire (CWS).
      <br />
      <br />
      La CWS consiste en un tournoi à double élimination de 64 équipes sélectionnées en fonction de leurs performances en saison régulière et de leurs championnats de conférence. Les équipes sont réparties en 16 groupes régionaux de quatre équipes chacun. Les vainqueurs de chaque groupe régional avancent au tour super régional, où ils affrontent une autre équipe dans une série au meilleur de trois matchs. Les vainqueurs de chaque tour super régional passent au tournoi final de la CWS, composé de deux groupes de quatre équipes chacun. Les vainqueurs de chaque groupe s'affrontent dans une série au meilleur de trois matchs pour déterminer le champion national.
      <br />
      <br />
      Le tournoi final de la CWS a lieu chaque année en juin à Omaha, Nebraska. Il est considéré comme l'un des plus grands et des plus excitants événements du sport universitaire, attirant des milliers de fans, de médias et de recruteurs.
      <br />
      <br />
      Les championnats de la Division II et de la Division III de la NCAA suivent un format similaire mais avec moins d'équipes. Les championnats de la NAIA et de la NJCAA ont également leurs propres formats, qui varient en fonction du nombre d'équipes participantes.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programmes de Premier Plan et Équipes Prestigieuses
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Le baseball universitaire est un sport très compétitif et prestigieux avec une tradition riche et un vivier de talents. Il existe de nombreux programmes et équipes qui se distinguent par leur succès et leur réputation. Certains de ces programmes et équipes incluent :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Longhorns du Texas : L'équipe de baseball de l'Université du Texas à Austin, compétitionnant dans la Big 12 Conference. Ce programme a un bilan impressionnant avec de nombreuses apparitions à la Série mondiale universitaire et plusieurs championnats. Certains anciens élèves notables incluent Roger Clemens, Burt Hooton et Huston Street.
        </li>
        <li>
          Tigres de LSU : L'équipe de baseball de l'Université d'État de Louisiane à Baton Rouge, active dans la SEC. LSU est connue pour sa domination dans le baseball universitaire et a remporté plusieurs championnats. Certains anciens élèves notables incluent Albert Belle, Aaron Hill et DJ LeMahieu.
        </li>
        <li>
          Trojans de l'USC : L'équipe de baseball de l'Université de Californie du Sud à Los Angeles, membre de la Pac-12 Conference. USC a une riche tradition avec de nombreux championnats et a produit de nombreux joueurs talentueux. Certains anciens élèves célèbres incluent Tom Seaver, Randy Johnson et Mark McGwire.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ce ne sont que quelques exemples de programmes de premier plan et d'équipes prestigieuses dans le baseball universitaire. Il existe de nombreux autres programmes et équipes, chacun avec son histoire, sa culture et son identité propres.
      <br />
      <br />
      Ces programmes et équipes contribuent au développement des joueurs de diverses manières. Ils leur fournissent un niveau élevé d'encadrement, d'entraînement, d'installations et de compétition. Ils les aident également à atteindre leurs objectifs académiques, athlétiques et personnels.
    </MKTypography>
  </MKBox>
</MKBox>

                     






                                  







<MKBox id="Chapitre 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 3 : Du Collège aux Professionnels
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le baseball universitaire n'est pas seulement un sport ; c'est aussi une voie vers le professionnalisme. De nombreux joueurs de baseball universitaire ont l'ambition de jouer professionnellement, que ce soit dans la MLB ou d'autres ligues professionnelles. Mais à quoi ressemble cette voie ? Comment passer du collège aux professionnels ? Dans ce chapitre, nous vous donnerons un aperçu de la voie vers le professionnalisme dans le baseball.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 La Voie vers le Professionnalisme
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La voie vers le professionnalisme dans le baseball commence généralement par la Draft de la MLB. La Draft de la MLB est un événement annuel où les 30 équipes de la MLB choisissent à tour de rôle des joueurs issus de diverses sources, y compris le baseball universitaire, le baseball lycéen, le baseball international et le baseball indépendant.
      <br />
      <br />
      La Draft de la MLB se compose de 40 tours, chaque équipe étant autorisée à choisir un joueur par tour. L'ordre des sélections est déterminé par le classement inversé de la saison de la MLB précédente, avec quelques exceptions pour les choix de compensation et les choix de loterie.
      <br />
      <br />
      La Draft de la MLB est une opportunité significative pour les joueurs de baseball universitaire de commencer leur carrière professionnelle. Environ 1 200 joueurs sont sélectionnés lors de la Draft de la MLB chaque année, environ 60% provenant du baseball universitaire.
      <br />
      <br />
      Si vous êtes sélectionné lors de la Draft de la MLB, vous avez deux options : vous pouvez signer un contrat avec l'équipe qui vous a choisi, ou vous pouvez choisir de ne pas signer et retourner à votre université ou à une autre source.
      <br />
      <br />
      Si vous choisissez de signer un contrat, vous recevrez une prime de signature basée sur le tour où vous avez été sélectionné. Les primes varient de plusieurs millions de dollars pour le premier tour à plusieurs milliers de dollars pour les tours ultérieurs.
      <br />
      <br />
      Lorsque vous signez un contrat, vous serez affecté à l'une des équipes de ligues mineures de l'équipe qui vous a sélectionné. Les ligues mineures sont les niveaux inférieurs du baseball professionnel, servant de système de développement pour la MLB. Il existe six niveaux dans les ligues mineures : Rookie, Class A Short Season, Class A, Class A Advanced, Double-A et Triple-A.
      <br />
      <br />
      En jouant dans les ligues mineures, vous devez améliorer vos compétences et démontrer votre performance pour progresser vers des niveaux plus élevés. Vous devez également rivaliser avec d'autres joueurs partageant le même rêve : arriver en ligues majeures.
      <br />
      <br />
      Atteindre les ligues majeures est l'objectif ultime de tout joueur de baseball professionnel. C'est le plus haut niveau du baseball professionnel, où vous pouvez rivaliser contre les meilleurs joueurs du monde, devant les plus grands fans, et pour les salaires les plus élevés.
      <br />
      <br />
      Cependant, arriver en ligues majeures n'est pas facile. Seulement environ 10% des joueurs sélectionnés lors de la Draft de la MLB parviennent finalement aux ligues majeures, et seulement environ 1% de ceux sélectionnés ont des carrières longues et réussies en ligues majeures.
      <br />
      <br />
      Ainsi, la voie vers le professionnalisme dans le baseball est un voyage long et difficile qui nécessite dévouement, travail acharné et un peu de chance. Mais c'est aussi une voie qui peut offrir de nombreuses récompenses, opportunités et rêves.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 La Draft de la MLB et Autres Opportunités Professionnelles
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La Draft de la MLB n'est pas le seul moyen de jouer professionnellement au baseball. Il existe également d'autres opportunités professionnelles en dehors de la MLB qui peuvent être attrayantes pour les joueurs de baseball universitaire.
      <br />
      <br />
      L'une d'entre elles est la ligue indépendante. La ligue indépendante est une ligue de baseball professionnel qui n'est pas affiliée à la MLB ou aux ligues mineures. La ligue indépendante se compose de diverses ligues, telles que la Atlantic League, l'American Association et la Frontier League.
      <br />
      <br />
      La ligue indépendante offre une alternative pour les joueurs qui ne sont pas sélectionnés lors de la Draft de la MLB ou qui ne reçoivent pas d'offres de contrat d'une équipe de la MLB. La ligue indépendante offre également une chance pour les joueurs qui sont libérés des ligues mineures ou qui veulent prolonger leur carrière.
      <br />
      <br />
      La ligue indépendante a un niveau de jeu et de salaire inférieur par rapport à la MLB ou aux ligues mineures, mais elle offre également un plus grand niveau de liberté et de plaisir. La ligue indépendante est connue pour sa créativité, sa diversité et ses expérimentations. Elle a également produit certains joueurs qui ont finalement atteint les ligues majeures, tels que Jose Bautista, Rich Hill et Max Muncy.
      <br />
      <br />
      Une autre opportunité professionnelle est la ligue internationale. La ligue internationale est un terme collectif pour les ligues de baseball professionnel jouées en dehors des États-Unis. La ligue internationale se compose de diverses ligues, telles que la Nippon Professional Baseball (NPB) au Japon, la Korean Baseball Organization (KBO) en Corée du Sud, la Chinese Professional Baseball League (CPBL) à Taïwan et la Mexican Baseball League (LMB) au Mexique.
      <br />
      <br />
      La ligue internationale offre une opportunité pour les joueurs qui veulent élargir leurs horizons et découvrir une autre culture. La ligue internationale offre également une opportunité pour les joueurs qui recherchent un niveau de jeu et de salaire supérieur à celui de la ligue indépendante mais qui pourraient ne pas être en mesure d'atteindre la MLB ou les ligues mineures.
      <br />
      <br />
      Ce ne sont que quelques exemples d'opportunités professionnelles en dehors de la MLB. Il existe de nombreuses autres opportunités, telles que les ligues hivernales, les ligues estivales universitaires et les ligues amateurs.
      <br />
      <br />
      Ces opportunités offrent toutes différents avantages et inconvénients pour les joueurs de baseball universitaire. Elles fournissent différents niveaux de compétition, de récompense, de défi et de plaisir. Elles offrent diverses voies vers le professionnalisme dans le baseball.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  







<MKBox id="Chapitre 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 4 : Influences Internationales
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le baseball universitaire n'est pas seulement un sport américain ; c'est aussi un sport international influencé par des joueurs, des entraîneurs et des fans de divers pays et cultures. Dans ce chapitre, nous vous donnerons un aperçu des influences internationales dans le baseball universitaire.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Joueurs Étrangers dans le Baseball Universitaire
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Équipe de baseball de l'USC célébrant une victoire."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Joueurs de l'USC célébrant une victoire
        </figcaption>
      </figure>
      Une des influences internationales les plus significatives dans le baseball universitaire est la présence de joueurs étrangers. Les joueurs étrangers sont des joueurs qui ne sont pas nés aux États-Unis ou n'ont pas la citoyenneté américaine. Ils viennent de différents pays, tels que le Canada, le Mexique, le Japon, la Corée du Sud, Taïwan, Cuba, le Venezuela, la République dominicaine et les Pays-Bas.
      <br />
      <br />
      Les joueurs étrangers jouent un rôle vital dans le baseball universitaire. Ils apportent leurs propres compétences, styles et perspectives au jeu. Ils enrichissent la diversité et la qualité du baseball universitaire. Ils augmentent également la reconnaissance internationale et l'attrait du baseball universitaire.
      <br />
      <br />
      Il existe de nombreuses histoires à succès de joueurs étrangers qui ont réussi dans le monde du baseball universitaire américain. Un exemple éminent est Nomar Garciaparra. Nomar Garciaparra était un arrêt-court mexicano-américain qui a joué pour le Georgia Institute of Technology. Il a été sélectionné au premier tour de la Draft de la MLB en 1994 par les Red Sox de Boston. Il a joué 14 saisons dans la MLB, où il est devenu six fois All-Star et a remporté deux fois le titre de champion de la Ligue américaine. Il existe de nombreux autres exemples de joueurs étrangers qui ont marqué le baseball universitaire.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Américains à l'Étranger
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Une autre influence internationale dans le baseball universitaire est la présence d'Américains à l'étranger. Les Américains à l'étranger sont des joueurs qui sont nés aux États-Unis ou ont la citoyenneté américaine mais jouent ou ont joué dans des ligues professionnelles en dehors des États-Unis.
      <br />
      <br />
      Les Américains à l'étranger jouent également un rôle significatif dans le baseball universitaire. Ils apportent leurs propres expériences, connaissances et réseaux au jeu. Ils apprennent également d'autres cultures et styles de baseball. Ils renforcent l'échange et la collaboration internationaux dans le baseball universitaire.
      <br />
      <br />
      Ces opportunités offrent de nombreux avantages aux joueurs de baseball universitaire américains. Elles fournissent un niveau de compétition, d'exposition et de récompense plus élevé. Elles aident également à élargir leurs horizons, à augmenter leur adaptabilité et à étendre leurs réseaux.
      <br />
      <br />
      L'expérience internationale peut être bénéfique pour le développement d'un joueur. Elle peut les aider à apprendre de nouvelles compétences, techniques et stratégies. Elle peut également les aider à améliorer leurs faiblesses, à exploiter leurs forces et à maximiser leur potentiel.
    </MKTypography>
  </MKBox>
</MKBox>





                                  


<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 5 : Bourses Sportives et l'Avenir des Athlètes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Les bourses sportives offrent de nombreuses opportunités aux jeunes athlètes de montrer leur talent et de développer leurs compétences. L'une des opportunités les plus significatives est d'obtenir une bourse sportive pour étudier et compétitionner au niveau collégial. Dans ce chapitre, nous discuterons de l'importance des bourses sportives et de comment Sport Scholarships America peut vous aider à réaliser votre rêve.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 L'Importance des Bourses
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Une bourse sportive est un soutien financier fourni par une université à un étudiant-athlète pour combiner études et sport. Elle peut couvrir les frais de scolarité, les livres, le logement, les repas et autres dépenses. De plus, elle peut offrir des avantages tels que du tutorat académique, des soins médicaux, des installations d'entraînement et du matériel.
      <br />
      <br />
      Obtenir une bourse sportive est compétitif, avec de nombreux facteurs qui influent sur le processus d'attribution, notamment les performances académiques, sportives, la personnalité, la motivation, le leadership et l'adaptabilité. Il est donc important de bien se préparer et de se démarquer de la concurrence.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Comment Sport Scholarships America Peut Aider
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo de Sport Scholarships America, représentant du matériel sportif et une casquette de graduation, symbolisant le focus sur les bourses sportives pour les étudiants-athlètes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America est une organisation spécialisée dans l'orientation des jeunes talents sportifs vers des bourses sportives. Avec des experts passionnés et expérimentés, Sport Scholarships America soutient les étudiants-athlètes à chaque étape du parcours, de l'orientation initiale à la placement final. Leurs services comprennent :
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Un entretien personnel pour discuter des souhaits, des possibilités et des attentes de l'étudiant-athlète.</li>
        <li>Une évaluation professionnelle du niveau académique et sportif de l'étudiant-athlète.</li>
        <li>Un vaste réseau de contacts avec des entraîneurs, des universités et des collèges.</li>
        <li>Promotion efficace du profil et des vidéos de l'étudiant-athlète auprès des entraîneurs potentiels.</li>
        <li>Conseils d'experts sur les meilleures options en fonction des préférences, des objectifs et du budget de l'étudiant-athlète.</li>
        <li>Négociation des meilleures offres de bourses au nom de l'étudiant-athlète.</li>
        <li>Aide à la demande de documents nécessaires, tels que visa, relevé de notes, diplôme et tests d'admission.</li>
        <li>Préparation à la vie d'étudiant-athlète pendant sa bourse, y compris culture, langue, règles et conseils.</li>
        <li>Suivi et accompagnement pendant la période de la bourse.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vous voulez explorer les opportunités de bourses et atteindre vos objectifs sportifs et académiques ? <Link to="/fr/Services-Produits">Consultez la gamme complète</Link> des services offerts par Sport Scholarships America et planifiez une consultation dès aujourd'hui.
    </MKTypography>
  </MKBox>
</MKBox>
          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;