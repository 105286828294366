/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import React, { useState } from 'react'; // Step 1: Import useState
import { Link } from 'react-router-dom';


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Football1.jpg";
import Image2 from "assets/images/Sports_Images/Football2.webp";
import Image3 from "assets/images/Sports_Images/Football3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";
import "../../../../H1asH3.css"


// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Bolsas de Estudo de Futebol Americano nos EUA: Como Garantir uma Vaga em uma Equipe Universitária', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capítulo 1: A História e Desenvolvimento do Futebol Americano', isSubheader: false },
    { id: '1.1', title: '1.1 Os Primeiros Anos', isSubheader: true },
    { id: '1.2', title: '1.2 Crescimento e Profissionalização', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capítulo 2: Futebol Americano Universitário', isSubheader: false },
    { id: '2.1', title: '2.1 Principais Ligas e Conferências', isSubheader: true },
    { id: '2.2', title: '2.2 Desenvolvimento de Jogadores', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capítulo 3: Da Faculdade para a NFL', isSubheader: false },
    { id: '3.1', title: '3.1 O Caminho para a NFL', isSubheader: true },
    { id: '3.2', title: '3.2 Outras Oportunidades Profissionais', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capítulo 4: A Influência do Futebol Americano na Cultura Americana', isSubheader: false },
    { id: '4.1', title: '4.1 Impacto Cultural', isSubheader: true },
    { id: '4.2', title: '4.2 Modelos e Inspiração', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 A Importância das Bolsas de Estudo', isSubheader: true },
    { id: '5.2', title: '5.2 Como a Sport Scholarships America Pode Ajudar', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Conteúdo
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Esconder</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>


























          <MKBox id="intro" mb={3}>
            <MKTypography variant="h1" className="h1-as-h3" mb={2}>
              Bolsas de Estudo de Futebol Americano nos EUA: Como Garantir uma Vaga em uma Equipe Universitária
            </MKTypography>
            <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                  src={Image1}
                  alt="Visão aérea de um jogo de futebol americano universitário lotado na Universidade de Indiana, com jogadores em ação no campo e torcedores nas arquibancadas."
                  style={{
                    maxWidth: "100%",
                    border: "2px solid #fff",
                    boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                    cursor: "pointer",
                    outline: "none",
                  }}
                  onClick={() => openModal1(Image1)}
                />
                <figcaption style={{ 
                  textAlign: 'left', 
                  fontWeight: 'bold', 
                  fontSize: '12px', 
                  wordWrap: 'break-word', 
                 }}>
                  Jogo de futebol americano da Universidade de Indiana das arquibancadas.
                </figcaption>
              </figure>
              O Futebol Americano é um esporte que teve origem nos Estados Unidos no final do século XIX. É um esporte de equipe no qual duas equipes de onze jogadores tentam avançar uma bola oval além da linha de gol do adversário ou chutá-la entre os postes de gol. O jogo é caracterizado por contato físico, táticas, estratégia e espetáculo.
              <br/>
              <br/>
              O Futebol Americano não é apenas um esporte; também é uma cultura. É uma parte essencial da identidade, valores e tradições americanas. É uma fonte de orgulho, paixão e inspiração para milhões de pessoas. Também é uma indústria que gera bilhões de dólares e cria milhares de empregos.
              <br/>
              <br/>
              Mas antes de você se tornar um jogador profissional na NFL, você precisa mostrar seu talento no nível universitário. O futebol americano universitário é uma parte crucial do desenvolvimento do jogador, oferecendo aos jovens atletas a oportunidade de melhorar suas habilidades, receber uma educação e construir uma rede de contatos.
              <br/>
              <br/>
              No entanto, o futebol americano universitário não é facilmente acessível para todos. Existem milhares de equipes e jogadores competindo por um número limitado de vagas e bolsas de estudo. Além disso, existem muitas regras e requisitos que você deve cumprir para se qualificar para uma equipe universitária.
              <br/>
              <br/>
              É aí que a Sportbeursamerika.nl pode ajudar você. Eu me especializo em encontrar jovens talentos para equipes universitárias que estão procurando reforços. Posso ajudá-lo a encontrar uma bolsa de estudos esportiva, providenciar seu visto, prepará-lo para os exames de admissão e negociar com treinadores. Com minha assistência, você pode realizar seu sonho de estudar e jogar na América.
              <br/>
              <br/>
              Quer saber como? Continue lendo!
            </MKTypography>
            </MKBox>
        
        
        
        {/* Main chapters and subchapters with placeholder text */}
        <MKBox id="Chapter 1: The History and Development of American Football" mb={3}>
          <MKTypography variant="h2" className="h1-as-h3" mb={2}>
            Capítulo 1: A História e Desenvolvimento do Futebol Americano
          </MKTypography>
          <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            O Futebol Americano é um esporte que teve origem nos Estados Unidos no final do século XIX. É descendente do rugby e do futebol, dois esportes populares na Europa. Neste capítulo, vamos contar mais sobre os primeiros anos e o crescimento e profissionalização do Futebol Americano.
          </MKTypography>
        
          <MKBox id="1.1" mb={2}>
            <MKTypography variant="h4" mb={1}>
              1.1 Os Primeiros Anos
            </MKTypography>
            <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              A origem do Futebol Americano é frequentemente atribuída a uma partida entre duas equipes universitárias em 1869: Rutgers e Princeton. No entanto, esta partida se parecia mais com uma forma de futebol do que o Futebol Americano moderno. Havia 25 jogadores por equipe, a bola não podia ser tocada com as mãos e não havia traves de gol.
              <br/>
              <br/>
              O primeiro jogo que se assemelhava ao Futebol Americano moderno ocorreu em 1874 entre Harvard e McGill. Este jogo foi uma combinação de rugby e futebol, permitindo que a bola fosse carregada com as mãos e apresentava traves de gol. Harvard venceu o jogo por 3 a 0.
              <br/>
              <br/>
              O papel das universidades na formação das primeiras equipes e ligas foi crucial. Eles foram os pioneiros que definiram as regras e o estilo do jogo. Em 1873, foi estabelecida a primeira associação interuniversitária de futebol, composta por Yale, Columbia, Princeton e Harvard. Em 1880, Walter Camp, jogador e treinador de Yale, foi considerado o "Pai do Futebol Americano" porque introduziu inovações significativas, como reduzir o número de jogadores para 11, introduzir a linha de scrimmage e o sistema de descidas.
            </MKTypography>
          </MKBox>
          <MKBox id="1.2" mb={2}>
            <MKTypography variant="h4" mb={1}>
              1.2 Crescimento e Profissionalização
            </MKTypography>
            <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
              A evolução do Futebol Americano para um fenômeno nacional começou no final do século XIX e início do século XX. O jogo ganhou popularidade entre estudantes, espectadores e mídia. Também se tornou cada vez mais violento e perigoso, levando a inúmeras lesões e até mortes. Em 1905, o presidente Theodore Roosevelt ameaçou proibir o jogo se não fossem implementadas reformas. Isso levou à criação da National Collegiate Athletic Association (NCAA) em 1906, uma organização responsável por regular e organizar esportes universitários.
              <br/>
              <br/>
              A influência do futebol americano profissional no jogo universitário foi significativa também. A primeira liga profissional de futebol americano foi fundada em 1892, mas se tornou realmente bem-sucedida em 1920 com a formação da American Professional Football Association (APFA), posteriormente renomeada National Football League (NFL). A NFL atraiu muitos jogadores talentosos das universidades, forçando-as a elevar seu nível para permanecerem competitivas. A NFL também se tornou uma importante fonte de receita e exposição para as universidades, muitas vezes alugando seus estádios para times profissionais.
              <br/>
              <br/>
              Neste capítulo, contamos mais sobre a história e o desenvolvimento do Futebol Americano. No próximo capítulo, forneceremos mais insights sobre o Futebol Americano no nível universitário.
            </MKTypography>
          </MKBox>
        </MKBox>








                                              





                                  







        <MKBox id="Chapter 2" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Capítulo 2: Futebol Americano Universitário
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        O futebol americano universitário é um dos esportes mais populares e competitivos nos Estados Unidos. A cada ano, mais de mil equipes e centenas de milhares de jogadores competem pelo título nacional, campeonatos regionais e prestigiosos jogos de bowl. Neste capítulo, vamos contar mais sobre as principais ligas e conferências no futebol americano universitário e o desenvolvimento dos jogadores no nível universitário.
    </MKTypography>

    <MKBox id="2.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.1 Principais Ligas e Conferências
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>

            Uma visão geral das divisões da NCAA e das principais conferências no futebol americano universitário. A NCAA é a organização responsável por regular e organizar os esportes universitários nos Estados Unidos. A NCAA divide as equipes em três divisões: Divisão I, Divisão II e Divisão III. A Divisão I é a divisão mais alta e consiste em duas subdivisões: a Football Bowl Subdivision (FBS) e a Football Championship Subdivision (FCS). As equipes da FBS são as mais prestigiadas e lucrativas, competindo por um lugar no College Football Playoff, um sistema que determina o campeão nacional desde 2014. As equipes da FCS são menos conhecidas e ricas, mas têm seu próprio sistema de playoffs para determinar seu campeão.
            <br/>
            <br/>
            Dentro de cada divisão e subdivisão, existem várias conferências, que são grupos de equipes que estão geograficamente ou historicamente conectadas. Cada conferência tem seu próprio calendário, regras e campeonato. As principais conferências na FBS são: Atlantic Coast Conference (ACC), Big Ten Conference, Big 12 Conference, Pac-12 Conference, Southeastern Conference (SEC) e American Athletic Conference (AAC). As principais conferências na FCS são: Big Sky Conference, Colonial Athletic Association (CAA), Missouri Valley Football Conference (MVFC) e Southern Conference (SoCon).
            <br/>
            <br/>
            Os programas universitários mais prestigiados e seu impacto no esporte. Algumas faculdades têm uma história longa e bem-sucedida no futebol americano universitário, remontando aos primeiros anos do esporte. Elas produziram muitos títulos nacionais, jogos de bowl, prêmios individuais e jogadores profissionais. Elas também têm muitos fãs, atenção da mídia e recursos financeiros. Alguns exemplos dessas faculdades são: Alabama, Notre Dame, Ohio State, Oklahoma, USC, Michigan, Texas, Clemson, Florida State e LSU.
        </MKTypography>
    </MKBox>
    <MKBox id="2.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.2 Desenvolvimento de Jogadores
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image2}
                    alt="Jovem Tom Brady em ação como quarterback pela Universidade de Michigan durante um jogo de futebol americano universitário, vestindo o uniforme azul e amarelo da equipe."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal2(Image2)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Tom Brady em ação pela Universidade de Michigan.
                </figcaption>
            </figure>
            Como as equipes universitárias contribuem para o desenvolvimento técnico e físico dos jogadores. As equipes universitárias oferecem aos jovens jogadores a oportunidade de melhorar suas habilidades sob a orientação de treinadores e membros da equipe experientes. Elas também fornecem acesso a instalações de alta qualidade, equipamentos e cuidados médicos. Além disso, elas expõem os jogadores a um alto nível de competição, onde enfrentam outros jogadores talentosos de todo o país.
            <br/>
            <br/>
            Exemplos de jogadores famosos que aprimoraram suas habilidades no nível universitário. Muitos jogadores profissionais começaram suas carreiras no nível universitário, onde se destacaram por seu desempenho e potencial. Alguns jogadores até ganharam prêmios como o Troféu Heisman, o prêmio individual mais prestigiado no futebol americano universitário. Exemplos desses jogadores incluem: Tom Brady (Michigan), Peyton Manning (Tennessee), Joe Montana (Notre Dame), Barry Sanders (Oklahoma State), Jerry Rice (Mississippi Valley State) e Tim Tebow (Florida).
            <br/>
            <br/>
            Neste capítulo, contamos mais sobre o futebol americano universitário. No próximo capítulo, contaremos mais sobre o caminho para a NFL e outras oportunidades profissionais.

        </MKTypography>
    </MKBox>
</MKBox>













                                              






                                  







<MKBox id="Chapter 3" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Capítulo 3: Da Faculdade para a NFL
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        O futebol americano universitário é um trampolim para a National Football League (NFL), a liga profissional mais alta do mundo. Todos os anos, centenas de jogadores são selecionados por equipes da NFL no Draft da NFL, um evento onde as equipes escolhem alternadamente um jogador de um grupo de jogadores universitários. Neste capítulo, vamos contar mais sobre o caminho para a NFL e outras oportunidades profissionais.
    </MKTypography>

    <MKBox id="3.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.1 O Caminho para a NFL
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Como o futebol americano universitário funciona como uma rampa de lançamento para a National Football League (NFL). O futebol americano universitário é a principal fonte de talento para a NFL. A maioria dos jogadores na NFL jogou primeiro no nível universitário. O futebol americano universitário oferece aos jogadores a chance de provar seu valor contra outros atletas de alto nível, desenvolver suas habilidades, melhorar suas estatísticas e aumentar seu valor no draft. O futebol americano universitário também dá aos jogadores exposição e reconhecimento por olheiros, treinadores e mídia.
            <br/>
            <br/>
            Cerca de 250 jogadores são escolhidos a cada ano no Draft da NFL, que geralmente ocorre em abril. O draft consiste em sete rodadas, cada uma com 32 escolhas (uma para cada equipe). A ordem das escolhas é determinada pelo resultado da temporada anterior, com a pior equipe escolhendo primeiro e a melhor equipe por último. O draft é um evento emocionante e imprevisível, com alguns jogadores sendo escolhidos mais cedo ou mais tarde do que o esperado, e alguns nem sendo escolhidos. Exemplos de jogadores famosos selecionados no Draft da NFL incluem: John Elway (primeira escolha em 1983), Troy Aikman (primeira escolha em 1989), Peyton Manning (primeira escolha em 1998), Tom Brady (199ª escolha em 2000), Aaron Rodgers (24ª escolha em 2005) e Patrick Mahomes (10ª escolha em 2017).
        </MKTypography>
    </MKBox>
    <MKBox id="3.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.2 Outras Oportunidades Profissionais
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Caminhos alternativos para uma carreira profissional no Futebol Americano fora da NFL. Nem todos os jogadores que jogam no nível universitário chegam à NFL. Alguns jogadores não são escolhidos no draft, ou são escolhidos mas não conseguem garantir um lugar em uma equipe. Alguns jogadores se machucam ou perdem sua motivação ou paixão. Mas isso não significa que eles precisam desistir do sonho de jogar profissionalmente. Existem outras oportunidades profissionais fora da NFL, como:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    A Canadian Football League (CFL): Esta é uma liga profissional composta por nove equipes que jogam no Canadá. A CFL tem algumas diferenças da NFL, como um campo maior, mais jogadores por equipe e menos descidas por campanha. A CFL atrai muitos jogadores que não podem jogar na NFL, mas ainda querem jogar em um alto nível.
                </li>
                <li>
                    A XFL: Esta é uma nova liga profissional estabelecida em 2020 como uma alternativa à NFL. A XFL é composta por oito equipes que jogam nos Estados Unidos. A XFL tem como objetivo fornecer um jogo mais rápido, emocionante e inovador do que a NFL. A XFL também oferece mais oportunidades para jogadores que foram ignorados ou desprezados pela NFL.
                </li>
                <li>
                    A European League of Football (ELF): Esta é uma nova liga profissional estabelecida em 2021 na tentativa de promover e desenvolver o Futebol Americano na Europa. A ELF é composta por oito equipes que jogam na Alemanha, Polônia, Espanha e França. A ELF tem como objetivo fornecer uma plataforma para os jogadores europeus mostrarem seu talento e crescerem como atletas.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Neste capítulo, contamos mais sobre o caminho para a NFL e outras oportunidades profissionais. No próximo capítulo, contaremos mais sobre a influência do Futebol Americano na cultura americana.
        </MKTypography>
    </MKBox>
</MKBox>



                                              






                                  





<MKBox id="Chapter 4" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Capítulo 4: A Influência do Futebol Americano na Cultura Americana
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        O Futebol Americano não é apenas um esporte, mas também uma cultura. É uma parte significativa da identidade, valores e tradições americanas. É uma fonte de orgulho, paixão e inspiração para milhões de pessoas. Também é uma indústria que gera bilhões de dólares e cria milhares de empregos. Neste capítulo, vamos contar mais sobre o impacto cultural do Futebol Americano, e os modelos e inspiração que ele proporciona.
    </MKTypography>

    <MKBox id="4.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.1 Impacto Cultural
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image3}
                    alt="Visão das arquibancadas no vibrante e lotado estádio de futebol americano universitário de Wisconsin durante um jogo emocionante, com fãs incentivando seu time."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal3(Image3)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Dia de jogo no lotado estádio de Wisconsin
                </figcaption>
            </figure>
            A importância do Futebol Americano na sociedade e cultura americana. O Futebol Americano é um dos esportes mais populares e influentes nos Estados Unidos. Atrai milhões de fãs, espectadores e mídia todos os anos. Faz parte da vida diária, educação, política, economia e entretenimento de muitos americanos. Também é um símbolo de união nacional, diversidade e orgulho.
            <br/>
            <br/>
            Principais eventos e seu impacto na identidade nacional, como o Super Bowl. O Super Bowl é o campeonato anual da NFL, geralmente realizado em fevereiro. É o maior e mais assistido evento esportivo do mundo, com mais de 100 milhões de telespectadores apenas nos Estados Unidos. O Super Bowl é mais do que um evento esportivo; é um fenômeno cultural. É uma oportunidade para família, amigos e colegas se reunirem, festejarem, apostarem e torcerem. Também é um palco para música, publicidade, arte e política. O Super Bowl é frequentemente visto como um reflexo da cultura, valores e tendências americanas.
        </MKTypography>
    </MKBox>
    <MKBox id="4.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.2 Modelos e Inspiração
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Os jogadores de Futebol Americano muitas vezes são modelos e fontes de inspiração para jovens atletas que buscam seu sonho de jogar no nível universitário ou profissional. Alguns jogadores têm histórias extraordinárias de adversidade, perseverança e sucesso que motivam e inspiram outros. Alguns jogadores também impactam positivamente sua comunidade, fãs e país por meio de suas ações, palavras e feitos. Exemplos de tais jogadores incluem: Colin Kaepernick (que se ajoelhou durante o hino nacional para conscientizar sobre a injustiça racial), Russell Wilson (que levou sua equipe ao Super Bowl como azarão), JJ Watt (que arrecadou milhões de dólares para as vítimas do Furacão Harvey), Drew Brees (que ajudou sua cidade Nova Orleans a se recuperar após o Furacão Katrina) e Tom Brady (que ainda joga em alto nível aos 43 anos).
            <br/>
            <br/>
            Neste capítulo, contamos mais sobre a influência do Futebol Americano na cultura americana. No próximo capítulo, contaremos mais sobre bolsas de estudo esportivas e o futuro do Futebol Americano.
        </MKTypography>
    </MKBox>
</MKBox>












                                              






                                  


<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    As bolsas de estudo esportivas oferecem inúmeras oportunidades para jovens atletas mostrarem seu talento e desenvolverem suas habilidades. Uma das oportunidades mais significativas é obter uma bolsa de estudos esportiva para estudar e competir no nível universitário. Neste capítulo, discutiremos a importância das bolsas de estudo esportivas e como a Sport Scholarships America pode ajudá-lo a realizar seu sonho.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 A Importância das Bolsas de Estudo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Uma bolsa de estudos esportiva é um suporte financeiro fornecido por uma universidade a um estudante-atleta para combinar estudos e esportes. Pode cobrir os custos de mensalidades, livros, moradia, refeições e outras despesas. Além disso, pode oferecer benefícios como tutoria acadêmica, cuidados médicos, instalações de treinamento e equipamentos.
      <br />
      <br />
      Obter uma bolsa de estudos esportiva é competitivo, com muitos fatores desempenhando um papel no processo de concessão, incluindo realizações acadêmicas, desempenho atlético, personalidade, motivação, liderança e adaptabilidade. Portanto, é importante se preparar bem e se destacar da concorrência.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Como a Sport Scholarships America Pode Ajudar
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo da Sport Scholarships America, mostrando equipamentos esportivos e um capelo de formatura, representando o foco em bolsas de estudo esportivas para estudantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo da Sport Scholarships America
        </figcaption>
      </figure>
      A Sport Scholarships America é uma organização especializada em orientar jovens talentos esportivos para bolsas de estudo esportivas. Com especialistas apaixonados e experientes, a Sport Scholarships America apoia estudantes-atletas em cada etapa da jornada, desde a orientação inicial até a colocação final. Seus serviços incluem:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Uma entrevista de admissão pessoal para discutir os desejos, possibilidades e expectativas do estudante-atleta.</li>
        <li>Uma avaliação profissional do nível acadêmico e atlético do estudante-atleta.</li>
        <li>Uma extensa rede de contatos com treinadores, universidades e faculdades.</li>
        <li>Promoção eficaz do perfil e vídeos do estudante-atleta para treinadores potenciais.</li>
        <li>Conselhos especializados sobre as melhores opções com base nas preferências, metas e orçamento do estudante-atleta.</li>
        <li>Negociação das melhores ofertas de bolsas de estudo em nome do estudante-atleta.</li>
        <li>Auxílio na solicitação de documentos necessários, como visto, histórico escolar, diploma e testes de admissão.</li>
        <li>Preparação para a vida do estudante-atleta durante a bolsa de estudos, incluindo cultura, idioma, regras e dicas.</li>
        <li>Acompanhamento e acompanhamento durante o período da bolsa de estudos.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Quer explorar oportunidades de bolsas de estudo e alcançar seus objetivos atléticos e acadêmicos? <Link to="/pt/Serviços-Produtos">Veja a gama completa</Link> de serviços oferecidos pela Sport Scholarships America e agende uma consulta hoje.
    </MKTypography>
  </MKBox>
</MKBox>


          
        









         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;