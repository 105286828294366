/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import Image1 from "assets/images/Sports_Images/Ice Hockey1.jpeg";
import Image2 from "assets/images/Sports_Images/Ice Hockey2.jpg";
import Image3 from "assets/images/Sports_Images/Ice Hockey3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images

import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Bolsa de Estudos de Hóquei no Gelo nos EUA: Como Estudar e Jogar Hóquei no Gelo na América', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capítulo 1: A História e o Desenvolvimento do Hóquei no Gelo na América', isSubheader: false },
    { id: '1.1', title: '1.1 Primeiros Anos e o Papel das Faculdades', isSubheader: true },
    { id: '1.2', title: '1.2 Crescimento e Profissionalização', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capítulo 2: Hóquei no Gelo Universitário Hoje', isSubheader: false },
    { id: '2.1', title: '2.1 Principais Ligas e Conferências', isSubheader: true },
    { id: '2.2', title: '2.2 Principais Programas e Times de Prestígio', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capítulo 3: Do College para os Profissionais', isSubheader: false },
    { id: '3.1', title: '3.1 O Caminho para o Profissionalismo', isSubheader: true },
    { id: '3.2', title: '3.2 O Draft da NHL e Outras Oportunidades', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capítulo 4: Influências Internacionais', isSubheader: false },
    { id: '4.1', title: '4.1 Jogadores Estrangeiros no Hóquei no Gelo Universitário', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 A Importância das Bolsas de Estudo', isSubheader: true },
    { id: '5.2', title: '5.2 Como a Sport Scholarships America Pode Ajudar', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Conteúdo
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Esconder</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Bolsa de Estudos de Hóquei no Gelo nos EUA: Como Estudar e Jogar Hóquei no Gelo na América
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Vista aérea de um estádio lotado em um jogo universitário de hóquei no gelo."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Um estádio lotado durante um jogo universitário
      </figcaption>
    </figure>
    O hóquei no gelo é um dos esportes mais populares na América. É um esporte rápido, físico e emocionante que atrai milhões de fãs. O hóquei no gelo é jogado em vários níveis, do amador ao profissional. Mas um dos níveis mais importantes e prestigiados é o hóquei no gelo universitário.
    <br />
    <br />
    O hóquei no gelo universitário é a versão do hóquei no gelo jogada por estudantes universitários e universidades na América. É parte do sistema educacional americano, combinando esportes e acadêmicos. O hóquei no gelo universitário oferece aos jovens jogadores a oportunidade de se desenvolverem tanto academicamente quanto atléticamente enquanto desfrutam da vida estudantil.
    <br />
    <br />
    O hóquei no gelo universitário também é um trampolim para o mundo profissional. Muitos jogadores que jogam na NHL (Liga Nacional de Hóquei) ou em outras ligas profissionais jogaram primeiro hóquei no gelo universitário. O hóquei no gelo universitário é uma excelente maneira de se preparar para uma carreira no esporte.
    <br />
    <br />
    Mas como um jovem jogador de hóquei no gelo pode se qualificar para o hóquei no gelo universitário? É aí que o Sportbeursamerika.nl pode te ajudar. Eu sou especializado em orientar estudantes-atletas que desejam obter uma bolsa de estudos esportiva na América. Uma bolsa de estudos esportiva é uma recompensa financeira que você recebe de uma universidade ou faculdade para estudar e praticar esportes lá. Com uma bolsa de estudos esportiva, você pode estudar e jogar hóquei no gelo na América sem incorrer em altos custos.
    <br />
    <br />
    Neste artigo, forneceremos mais informações sobre o hóquei no gelo universitário na América, sua história, desenvolvimento, ligas, times, oportunidades, influências internacionais e, é claro, bolsas de estudos. Também direi como posso te ajudar a tornar seu sonho realidade. Está pronto para saber mais sobre bolsas de estudo de hóquei no gelo nos EUA? Continue lendo!
  </MKTypography>
</MKBox>
{/* Capítulos principais e subcapítulos com texto de espaço reservado */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 1: A História e o Desenvolvimento do Hóquei no Gelo na América
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O hóquei no gelo é um esporte que existe na América há mais de um século. Ele tem uma história rica e fascinante intimamente ligada ao papel das faculdades. Neste capítulo, exploramos mais a fundo as origens, o crescimento e a profissionalização do hóquei no gelo na América.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Primeiros Anos e o Papel das Faculdades
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O hóquei no gelo teve origem no Canadá, onde foi jogado pela primeira vez no século XIX. O jogo rapidamente se espalhou para os Estados Unidos, tornando-se particularmente popular nos estados do nordeste. As primeiras equipes universitárias foram formadas no final do século XIX e início do século XX, incluindo Yale, Harvard, Princeton e Dartmouth.
      <br />
      <br />
      Essas equipes jogavam partidas intercolegiais, muitas vezes atraindo grandes multidões. Elas também jogavam contra equipes canadenses, que geralmente eram mais fortes. Para elevar o nível de jogo, algumas universidades começaram a recrutar jogadores profissionais que se matriculavam como estudantes. Essa prática gerou controvérsia e críticas, pois comprometia o status amador do esporte.
      <br />
      <br />
      Para acabar com essa prática, a Intercollegiate Hockey Association (IHA) foi fundada em 1898, sendo a primeira coleção de programas universitários de hóquei no gelo na América. A IHA, às vezes referida como Liga Intercolegial de Hóquei, incluía equipes como Brown, Columbia, Universidade da Pensilvânia e Yale. Esta organização era a única autoridade de campeonato para hóquei no gelo universitário na época, determinando o campeão nacional de facto com base no desempenho da temporada. A IHA continuou a existir até 1913.
      <br />
      <br />
      Após a dissolução da IHA, a Intercollegiate Hockey League (IHL) foi estabelecida em 1912 por Harvard, Princeton e Yale. Essa liga foi uma resposta à necessidade de as principais equipes universitárias continuarem competindo entre si. A IHL existiu até 1917, quando as atividades foram suspensas devido à Primeira Guerra Mundial.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crescimento e Profissionalização
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ao longo do século XX, o hóquei no gelo universitário se tornou um esporte nacional, jogado por centenas de equipes em várias divisões e conferências. O hóquei no gelo universitário também foi cada vez mais influenciado pelo surgimento de ligas profissionais de hóquei no gelo, como a NHL (fundada em 1917) e a World Hockey Association (fundada em 1972).
      <br />
      <br />
      Essas ligas profissionais forneceram oportunidades para jogadores universitários talentosos avançarem para um nível mais alto. Muitos jogadores universitários foram selecionados no Draft da NHL, o evento anual em que as equipes da NHL escolhem novos jogadores. O primeiro draft da NHL no qual um jogador universitário foi selecionado ocorreu em 1967. Al Karlander, jogador do Michigan Tech, foi selecionado pelo Detroit como a 17ª escolha geral naquele ano. Desde então, mais de mil jogadores universitários foram draftados por equipes da NHL.
      <br />
      <br />
      O hóquei no gelo universitário também testemunhou momentos significativos na história do esporte. Um dos mais memoráveis foi o "Milagre no Gelo" em 1980, quando a equipe olímpica americana, composta principalmente por jogadores universitários, derrotou a União Soviética em um jogo histórico durante os Jogos Olímpicos de Inverno em Lake Placid. Isso foi uma conquista notável, já que os soviéticos eram considerados a melhor equipe de hóquei no gelo do mundo na época.
      <br />
      <br />
      Portanto, o hóquei no gelo universitário tem uma história longa e impressionante, testemunhando a paixão e o talento dos jogadores americanos. O hóquei no gelo universitário também contribuiu para o desenvolvimento e inovação do esporte, introduzindo novas regras, táticas e estilos.
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  







<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 2: Hóquei no Gelo Universitário Hoje
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O hóquei no gelo universitário é um dos esportes mais competitivos e emocionantes na América hoje. É jogado por mais de 400 equipes, divididas em três divisões da NCAA, várias conferências e zonas regionais. Neste capítulo, forneceremos uma visão geral das principais ligas e conferências, programas de destaque e times de prestígio, e a estrutura da temporada e do campeonato.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Principais Ligas e Conferências
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Jogadores de Massachusetts comemorando uma vitória no hóquei no gelo universitário."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Jogadores de Massachusetts comemorando uma vitória
        </figcaption>
      </figure>
      A NCAA (Associação Atlética Universitária Nacional) é a organização que regula o hóquei no gelo universitário. A NCAA divide as equipes em três divisões com base no nível, orçamento e número de bolsas de estudo que oferecem. As divisões são:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Divisão I: Esta é a divisão mais alta onde as melhores equipes e jogadores competem. A Divisão I compreende 60 equipes, divididas em seis conferências: Atlantic Hockey, Big Ten, ECAC Hockey, Hockey East, NCHC (Conferência Nacional de Hóquei Universitário) e WCHA (Associação de Hóquei Universitário Ocidental). Essas conferências organizam suas próprias competições e torneios ao longo da temporada.
        </li>
        <li>
          Divisão II: Esta é uma divisão intermediária com menos equipes e jogadores. Há apenas sete equipes na Divisão II, e elas não têm sua própria conferência. Normalmente, competem contra equipes da Divisão III ou de outras ligas.
        </li>
        <li>
          Divisão III: Esta é a divisão mais baixa onde a maioria das equipes e jogadores participam. A Divisão III consiste em 84 equipes, divididas em sete conferências: CCC (Conferência da Costa Comum), MASCAC (Conferência Atlética Universitária Estadual de Massachusetts), MIAC (Conferência Atlética Intercolegial de Minnesota), NCHA (Associação de Hóquei Universitário do Norte), NEHC (Conferência de Hóquei da Nova Inglaterra), SUNYAC (Conferência Atlética da Universidade Estadual de Nova York) e UCHC (Conferência de Hóquei Universitário Unida). Essas conferências também organizam suas próprias competições e torneios durante a temporada.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Além das divisões e conferências da NCAA, também existem outras ligas e organizações que oferecem hóquei no gelo universitário.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Principais Programas e Times de Prestígio
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O hóquei no gelo universitário tem vários programas de destaque e times de prestígio que se destacam por seu sucesso, tradição, reputação e influência. Alguns desses programas e times incluem:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Universidade de Dakota do Norte: Um dos programas de hóquei no gelo universitário mais bem-sucedidos na América, competindo na conferência NCHC na Divisão I. A universidade ganhou múltiplos campeonatos da NCAA e produziu numerosos jogadores que jogaram na NHL, incluindo nomes conhecidos como Jonathan Toews, Zach Parise e T.J. Oshie.
        </li>
        <li>
          Boston College: Um proeminente programa de hóquei no gelo universitário na América, competindo na conferência Hockey East na Divisão I. O Boston College tem vários campeonatos nacionais em seu nome. O programa também é conhecido por desenvolver mais de 80 jogadores da NHL, com ex-alunos famosos como Johnny Gaudreau, Patrick Eaves e Brian Gionta.
        </li>
        <li>
          Universidade de Minnesota: Este histórico programa de hóquei no gelo universitário, competindo na conferência Big Ten na Divisão I, conquistou um impressionante número de campeonatos nacionais. É conhecido por produzir um número recorde de jogadores que jogaram na NHL, incluindo estrelas como Phil Kessel, Paul Martin e Blake Wheeler.
        </li>
        <li>
          Universidade de Wisconsin: Um dos programas de hóquei no gelo universitário mais prestigiosos na América, competindo na conferência Big Ten na Divisão I. Wisconsin conquistou múltiplos campeonatos nacionais e também treinou um número significativo de jogadores que jogaram na NHL, incluindo Ryan Suter, Joe Pavelski e Dany Heatley.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Esses programas e times são apenas alguns exemplos dos muitos que o hóquei no gelo universitário tem a oferecer. Existem muitas outras equipes que têm sua própria história, cultura e identidade. O hóquei no gelo universitário é um esporte diverso e dinâmico com muito a oferecer aos jogadores e fãs.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  







<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 3: Do College para os Profissionais
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O hóquei no gelo universitário não é apenas um esporte; também é um campo de aprendizado. Muitos jogadores que participam do hóquei no gelo universitário têm ambições de avançar para o mundo profissional. Neste capítulo, discutiremos como o hóquei no gelo universitário pode servir como um trampolim para a NHL ou outras ligas profissionais, quais ex-alunos conseguiram sucesso no mundo profissional do hóquei no gelo, e quais oportunidades existem além da NHL.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 O Caminho para o Profissionalismo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O hóquei no gelo universitário é uma excelente maneira de se preparar para uma carreira no esporte. Ao jogar hóquei no gelo universitário, você pode melhorar suas habilidades, conhecimento e experiência enquanto obtém um diploma. O hóquei no gelo universitário também lhe oferece a oportunidade de competir contra outros jogadores talentosos que o desafiam e o inspiram.
      <br />
      <br />
      Uma das principais vantagens do hóquei no gelo universitário é que ele aumenta sua visibilidade e exposição. Jogar hóquei no gelo universitário pode atrair a atenção de olheiros, treinadores e gerentes de equipes profissionais que estão procurando novos jogadores. Você também pode participar de mostras, acampamentos e clínicas para mostrar suas habilidades a possíveis empregadores.
      <br />
      <br />
      Outra vantagem do hóquei no gelo universitário é que ele oferece flexibilidade e opções. Ao jogar hóquei no gelo universitário, você pode adiar a tomada de decisão sobre seu futuro. Você pode optar por fazer a transição para uma equipe profissional após o seu primeiro, segundo, terceiro ou quarto ano, dependendo da sua situação e preferência. Você também pode optar por concluir seus estudos antes de se tornar profissional.
      <br />
      <br />
      O hóquei no gelo universitário produziu muitos jogadores que tiveram sucesso no mundo profissional do hóquei no gelo. Alguns desses jogadores incluem:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Jack Eichel: Ele jogou uma temporada pela Boston University na conferência Hockey East na Divisão I. Ele ganhou o prêmio Hobey Baker como o melhor jogador do ano em 2015. Ele foi selecionado como a segunda escolha geral no Draft da NHL de 2015 pelo Buffalo Sabres, onde se tornou o capitão e jogador destaque. Em 2023, ele ganhou a Stanley Cup com o Vegas Golden Knights.
        </li>
        <li>
          Cale Makar: Ele jogou duas temporadas pela UMass Amherst na conferência Hockey East na Divisão I. Ele também ganhou o prêmio Hobey Baker como o melhor jogador do ano em 2019. Ele foi selecionado como a quarta escolha geral no Draft da NHL de 2017 pelo Colorado Avalanche, onde atualmente joga como um dos principais defensores da liga.
        </li>
        <li>
          Hilary Knight: Hilary Knight jogou quatro temporadas pela University of Wisconsin na conferência WCHA na Divisão I. Ela era uma jogadora altamente talentosa e foi indicada como finalista entre os 10 melhores para o Prêmio Memorial Patty Kazmaier em 2009, 2011 e 2012. Embora não tenha ganho o prêmio, ela foi duas vezes nomeada Primeira Equipe All-American. Knight depois jogou pelo Boston Pride na NWHL (National Women's Hockey League) e pela seleção nacional dos EUA, com a qual ganhou duas medalhas de prata olímpicas e vários campeonatos mundiais.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Esses jogadores são apenas alguns exemplos dos muitos que transformaram suas carreiras no hóquei no gelo universitário em carreiras profissionais. O hóquei no gelo universitário é um caminho comprovado para o profissionalismo para muitos jogadores.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 O Draft da NHL e Outras Oportunidades
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A NHL (National Hockey League) é a principal liga profissional de hóquei no gelo do mundo. É o sonho de muitos jovens jogadores um dia jogar na NHL. Uma das maneiras de alcançar isso é através do Draft da NHL, o evento anual onde as equipes da NHL selecionam novos jogadores.
      <br />
      <br />
      O Draft da NHL é um momento emocionante e significativo para muitos jogadores universitários. É uma oportunidade de ser escolhido por uma equipe da NHL, que oferece a você um contrato para jogar por eles. Também reconhece seu talento, potencial e trabalho árduo.
      <br />
      <br />
      O Draft da NHL consiste em sete rodadas, nas quais cada equipe da NHL seleciona um jogador. A ordem de seleção é determinada por um sorteio, com as equipes que tiveram os piores resultados na temporada anterior tendo uma melhor chance de escolher primeiro. O Draft da NHL geralmente ocorre em junho ou julho após o final da temporada do hóquei no gelo universitário.
      <br />
      <br />
      Para ser elegível para o Draft da NHL, você deve atender a certos critérios. Você deve ser um jogador norte-americano com idades entre 18 e 20 anos ou um jogador internacional com idades entre 18 e 21 anos. Você não deve ter sido anteriormente selecionado por uma equipe da NHL ou ter renunciado aos seus direitos.
      <br />
      <br />
      Ser selecionado por uma equipe da NHL não significa que você precisa jogar imediatamente por eles. Você pode optar por voltar para sua equipe universitária e concluir seus estudos enquanto mantém os direitos da equipe da NHL. Você também pode optar por se juntar à equipe da NHL ou à sua filial da liga menor, onde pode continuar a se desenvolver e treinar.
      <br />
      <br />
      No entanto, o Draft da NHL não é a única maneira de chegar à NHL. Existem outras possibilidades, como a agência livre. Isso acontece quando você, como jogador, não tem contrato com uma equipe da NHL e está livre para negociar com qualquer equipe que desejar. Isso pode acontecer se você não foi selecionado ou se seu contrato com uma equipe da NHL expirar ou for rescindido. Como agente livre, você pode tentar garantir um contrato com uma equipe da NHL que tenha interesse em você.
      <br />
      <br />
      No entanto, a NHL não é a única liga profissional de hóquei no gelo do mundo. Existem outras ligas onde você pode jogar como jogador e ganhar a vida. Algumas dessas ligas incluem:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          KHL (Liga Continental de Hóquei): Esta é a principal liga profissional de hóquei no gelo na Europa e Ásia. É composta por 24 equipes de oito países: Rússia, Finlândia, Bielorrússia, Cazaquistão, China, Letônia, Eslováquia e República Tcheca. Oferece um alto nível de jogo e salário, atraindo jogadores de vários países.
        </li>
        <li>
          SHL (Liga Sueca de Hóquei): Esta é a principal liga profissional de hóquei no gelo na Suécia. É composta por 14 equipes competindo pelo campeonato nacional. É conhecida por seu jogo rápido e técnico e produziu muitos jogadores que jogaram na NHL.
        </li>
        <li>
          Liiga (Liga Finlandesa de Hóquei): Esta é a principal liga profissional de hóquei no gelo na Finlândia. É composta por 15 equipes competindo pelo campeonato nacional. É conhecida por seu jogo físico e tático e também produziu muitos jogadores que jogaram na NHL.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Essas ligas são apenas alguns exemplos das muitas ligas ao redor do mundo. Existem muitas mais ligas em outros países, incluindo Suíça, Alemanha, França, Noruega, Dinamarca, Áustria, Polônia, Japão e Austrália. Essas ligas oferecem diferentes níveis de jogo, salários e estilos de vida para os jogadores.
      <br />
      <br />
      O hóquei no gelo universitário, portanto, não é o fim da estrada para muitos jogadores. Em vez disso, é o início de uma jornada emocionante para o mundo profissional. O hóquei no gelo universitário oferece muitas oportunidades e opções para os jogadores que perseguem seus sonhos.
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  







<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 4: Influências Internacionais
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O hóquei no gelo universitário é um esporte que não é apenas americano, mas também internacional. O hóquei no gelo universitário é influenciado pela presença e desempenho de jogadores estrangeiros em competições universitárias americanas. O hóquei no gelo universitário também influencia as oportunidades e o desenvolvimento de jogadores americanos no exterior. Neste capítulo, discutiremos o papel e o impacto de jogadores internacionais no hóquei no gelo universitário e as oportunidades para jogadores americanos jogarem internacionalmente.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Jogadores Estrangeiros no Hóquei no Gelo Universitário
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Jogadores de hóquei no gelo da Quinnipiac celebrando um gol."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Jogadores da Quinnipiac celebrando um gol
        </figcaption>
      </figure>
      O hóquei no gelo universitário é um esporte aberto a jogadores de todo o mundo. Existem muitos jogadores estrangeiros que vêm para a América para jogar hóquei no gelo universitário, com diversas origens, culturas e nacionalidades. Alguns desses jogadores incluem:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Daryl Watts: Ela é uma jogadora de hóquei no gelo canadense que jogou tanto para Boston College quanto para Wisconsin na Divisão I da NCAA por duas temporadas. Ela ganhou o Prêmio Patty Kazmaier em 2018 como caloura, tornando-se a primeira caloura a ganhar o prêmio. Watts então jogou pelo Toronto Six na Premier Hockey Federation (PHF), anteriormente conhecida como National Women's Hockey League (NWHL), e assinou um contrato recorde com o Toronto Six em 2023.
        </li>
        <li>
          Rod Brind'Amour: Nascido no Canadá, ele jogou hóquei no gelo universitário na Michigan State University antes de se mudar para a NHL. Ele teve uma carreira profissional altamente bem-sucedida, com destaques incluindo a conquista da Stanley Cup.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Esses jogadores são apenas alguns exemplos dos muitos jogadores estrangeiros que jogam ou jogaram hóquei no gelo universitário. Existem muitos mais jogadores de outros países, como Suécia, Rússia, República Tcheca, Alemanha, França, Suíça, Dinamarca, Japão e Austrália.
      <br />
      <br />
      Esses jogadores estrangeiros têm um papel e impacto importantes no hóquei no gelo universitário. Eles trazem seu próprio estilo, habilidades e experiência para as competições universitárias americanas. Eles enriquecem o nível e a diversidade do jogo. Eles também criam mais interesse e atenção para o hóquei no gelo universitário em seus próprios países e regiões.
      <br />
      <br />
      O hóquei no gelo universitário é um esporte que se beneficia de influências internacionais. É um esporte que acolhe e aprecia jogadores de todo o mundo.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    As bolsas de estudo esportivas oferecem inúmeras oportunidades para jovens atletas mostrarem seu talento e desenvolverem suas habilidades. Uma das oportunidades mais significativas é obter uma bolsa de estudos esportiva para estudar e competir no nível universitário. Neste capítulo, discutiremos a importância das bolsas de estudo esportivas e como a Sport Scholarships America pode ajudá-lo a realizar seu sonho.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 A Importância das Bolsas de Estudo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Uma bolsa de estudos esportiva é um suporte financeiro fornecido por uma universidade a um estudante-atleta para combinar estudos e esportes. Pode cobrir os custos de mensalidades, livros, moradia, refeições e outras despesas. Além disso, pode oferecer benefícios como tutoria acadêmica, cuidados médicos, instalações de treinamento e equipamentos.
      <br />
      <br />
      Obter uma bolsa de estudos esportiva é competitivo, com muitos fatores desempenhando um papel no processo de concessão, incluindo realizações acadêmicas, desempenho atlético, personalidade, motivação, liderança e adaptabilidade. Portanto, é importante se preparar bem e se destacar da concorrência.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Como a Sport Scholarships America Pode Ajudar
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo da Sport Scholarships America, mostrando equipamentos esportivos e um capelo de formatura, representando o foco em bolsas de estudo esportivas para estudantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo da Sport Scholarships America
        </figcaption>
      </figure>
      A Sport Scholarships America é uma organização especializada em orientar jovens talentos esportivos para bolsas de estudo esportivas. Com especialistas apaixonados e experientes, a Sport Scholarships America apoia estudantes-atletas em cada etapa da jornada, desde a orientação inicial até a colocação final. Seus serviços incluem:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Uma entrevista de admissão pessoal para discutir os desejos, possibilidades e expectativas do estudante-atleta.</li>
        <li>Uma avaliação profissional do nível acadêmico e atlético do estudante-atleta.</li>
        <li>Uma extensa rede de contatos com treinadores, universidades e faculdades.</li>
        <li>Promoção eficaz do perfil e vídeos do estudante-atleta para treinadores potenciais.</li>
        <li>Conselhos especializados sobre as melhores opções com base nas preferências, metas e orçamento do estudante-atleta.</li>
        <li>Negociação das melhores ofertas de bolsas de estudo em nome do estudante-atleta.</li>
        <li>Auxílio na solicitação de documentos necessários, como visto, histórico escolar, diploma e testes de admissão.</li>
        <li>Preparação para a vida do estudante-atleta durante a bolsa de estudos, incluindo cultura, idioma, regras e dicas.</li>
        <li>Acompanhamento e acompanhamento durante o período da bolsa de estudos.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Quer explorar oportunidades de bolsas de estudo e alcançar seus objetivos atléticos e acadêmicos? <Link to="/pt/Serviços-Produtos">Veja a gama completa</Link> de serviços oferecidos pela Sport Scholarships America e agende uma consulta hoje.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;