/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post2 from "assets/images/TijdUS.png";
import "../../../../H1asH3.css"


// Images

function Profile() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
            <MKBox 
                display="flex" 
                flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} 
                justifyContent="space-between" 
                alignItems="center" 
    mb={1}
>
    <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Part 3: Back in the Netherlands</MKTypography>

    
</MKBox>
           

              <MKTypography variant="body1" fontWeight="light" color="text">

              <MKTypography variant="h4">
               
                </MKTypography>
                <br />
              
    Upon returning to the Netherlands, I experienced a mix of nostalgia and determination. My deep-rooted passion for soccer was now joined by a renewed eagerness for academic challenges. Amsterdam became the backdrop for my next adventure, where I pursued a master's degree in Quantitative Finance, a field that stirred both my analytical mind and my ambitions.
    <br /><br />
    After completing my studies, I started working as a consultant. While the world of numbers and analysis fascinated me, I felt something was missing. The echoes of my American journey continued to resonate within me, reminding me of the unique opportunity I had experienced, an opportunity I wished for others to have as well.
    <br /><br />
    Consequently, I decided to use my experiences and knowledge to guide other young, ambitious athletes. Before launching this venture, I tapped into my extensive network in America, engaging with coaches, athletes, former athletes, and others. These discussions helped me and future students I would support, to understand the best approach to these opportunities.
    <br /><br />
    During the challenging COVID-19 pandemic, I collaborated with a leading agency in America. Although I could assist fewer international students due to limited scholarship availability, I utilized my expertise to aid American students. This experience not only expanded my network substantially but also provided insights into the American scholarship market, which I could now apply more effectively in Europe.
    <br /><br />
    The challenges during the pandemic strengthened my mission to guide young talents towards American universities and sports scholarships. Despite the difficulties, it brought a sense of fulfillment I had never experienced before. It was my way of giving back to the community that once warmly welcomed me in the United States.
    <br /><br />
    This is my story, and I am proud that I can now help others to write their own in the land of endless possibilities. If you want to learn more about my company and the journey of my clients in realizing their dreams, continue reading on my website. If you have any questions or wish to contact me, please do not hesitate to do so.
</MKTypography>

              <hr style={{ margin: '20px 0'}} />
              <Grid item xs={12} lg={6} mb={{ xs: 3, lg: 0 }}>
            <TransparentBlogCard
              image={post2}
              title="Part 2: My Time in America"
              action={{
                type: "internal",
                route: "/About-Me/Time-in-America",
                color: "info",
                label: "read more",
              }}
            />
          </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}


export default Profile;
