import React from "react";
import { Helmet } from 'react-helmet';

import { useNavigate } from "react-router-dom"; // Import useNavigate
import Container from "@mui/material/Container";
import Scarecrow from "../../../../assets/images/Scarecrow.png";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Header from "./header";

function WebsiteNotFound() {
  const navigate = useNavigate(); // Use the useNavigate hook

  // Function to navigate to the homepage
  const navigateToHome = () => {
    navigate('/'); // Use navigate to go to the homepage
  };

  return (
    <>
    <Helmet>
    <title>404 Page Not Found</title>
    <meta name="description" content="Ontdek college Sports in verschillende Amerikaanse staten. Leer over belangrijke universiteiten, Sports, rivaliteiten en meer in elke staat." />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta charset="UTF-8" />
    <meta name="robots" content="index, follow" />
    
    {/* Open Graph Tags */}
    
    
    
    
    

    {/* Favicon */}
    <link rel="icon" href="https://sportscholarshipsamerica.com/32x32Sportbeurs.png" />
</Helmet>

    <Container
      sx={{
        display: "flex",
        justifyContent: "center"
      }}
    >
      <Header />
      <Box
        sx={{
          width: "100%",
          minWidth: "100px"
        }}
      >
        <img src={Scarecrow} alt="Scarecrow" className="notFoundImage" />
      </Box>
      <Stack spacing={4} alignItems="flex-start" justifyContent="center">
        <Typography variant="h1" sx={{ textAlign: "left" }}>
          I have bad news for you
        </Typography>
        <Typography variant="subtitle1" sx={{ textAlign: "left" }}>
          The page you are looking for might be removed or is temporarily
          unavailable
        </Typography>
        <Button
          variant="contained"
          color="info"
          sx={{ padding: "1rem" }}
          onClick={navigateToHome} // Attach the navigateToHome function to onClick
        >
          Back to homepage
        </Button>
      </Stack>
    </Container>
    </>
  );
}

export default WebsiteNotFound;
