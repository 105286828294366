
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import SchoolIcon from '@mui/icons-material/School';
import StarIcon from '@mui/icons-material/Star';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2'; 
import Filter3Icon from '@mui/icons-material/Filter3'; 
import Filter4Icon from '@mui/icons-material/Filter4'; 
import Filter5Icon from '@mui/icons-material/Filter5'; 
import Filter6Icon from '@mui/icons-material/Filter6'; 
import Filter7Icon from '@mui/icons-material/Filter7'; 
import Filter8Icon from '@mui/icons-material/Filter8'; 
import Filter9Icon from '@mui/icons-material/Filter9';  // Import the number 1 icon
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import ScheduleIcon from '@mui/icons-material/Schedule';
import VideocamIcon from '@mui/icons-material/Videocam';
import QuizIcon from '@mui/icons-material/Quiz';
import RuleIcon from '@mui/icons-material/Rule';
import ChecklistIcon from '@mui/icons-material/Checklist';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import DescriptionIcon from '@mui/icons-material/Description';




const boxStyle = {
  boxShadow: '0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15)',
  background: '#f5f5f5', // Light grey background
  color: '#000', // Text color set to black for contrast
};

const arrowStyle = {
  borderRight: '7px solid white',
};
function Chronologie() {
  return (
    <VerticalTimeline lineColor='rgb(33, 150, 243)'>
      <VerticalTimelineElement
        iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
        icon={<StarIcon />}
      />
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ ...boxStyle, position: 'relative' }} // Ajoutez la position relative au style
        contentArrowStyle={arrowStyle}
        date="Étape 1"
        iconStyle={{ 
          background: 'rgb(33, 150, 243)', 
          color: '#fff', 
          boxShadow: '0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15)', // Assurez-vous que l'ombre est appliquée ici
          border: '2px solid white' // Conservez la bordure
        }}
        icon={<RecordVoiceOverIcon />}
      >
        <div style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          opacity: 0.5 // Opacité réduite pour un effet de filigrane
        }}>
          <Filter1Icon style={{ color: 'grey', fontSize: '48px' }} />
        </div>

        <h3 className="vertical-timeline-element-title">Consultation initiale :</h3>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Ajouté Roboto comme famille de polices
          fontSize: '17px', // Taille de police changée à 17px
          fontWeight: '400', // Assurez-vous que le poids de la police est normal
          marginBottom: '10px', // Ajustez la valeur selon les besoins
        }}>
          C'est votre point de départ : le moment où nous alignons vos rêves et objectifs avec un chemin réaliste vers une bourse sportive en Amérique.
        </p>

        <ul style={{ 
          listStyleType: 'disc', 
          marginLeft: '20px', 
          fontSize: '17px', 
        }}>  
          <li>
            <strong>Objectifs personnels :</strong> Clarifier vos ambitions et ce que vous recherchez dans une bourse sportive et une expérience universitaire.
          </li>
          <li>
            <strong>Compétences sportives :</strong> Évaluation de votre performance sportive actuelle et de votre potentiel pour la compétition au niveau universitaire.
          </li>
          <li>
            <strong>Aspirations académiques :</strong> Compréhension de vos intérêts et objectifs académiques, et comment les intégrer avec votre sport.
          </li>
          <li>
            <strong>Objectifs personnels :</strong> Sur la base de la conversation, je vais élaborer une stratégie initiale et un calendrier pour votre parcours vers une bourse sportive.
          </li>
        </ul>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Ajouté Roboto comme famille de polices
          fontSize: '17px', // Taille de police changée à 17px
          fontWeight: '400', // Assurez-vous que le poids de la police est normal
        }}>
          Ensemble, nous jetons les bases d'une correspondance réussie avec une université américaine et établissons le cap pour les prochaines étapes du processus.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ ...boxStyle, position: 'relative' }} // Ajoutez la position relative au style
        contentArrowStyle={arrowStyle}
        date="Étape 2"
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        icon={<ScheduleIcon />}
      >
        <div style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          opacity: 0.5 // Opacité réduite pour un effet de filigrane
        }}>
          <Filter2Icon style={{ color: 'grey', fontSize: '48px' }} />
        </div>
        <h3 className="vertical-timeline-element-title">Évaluation et planification :</h3>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Ajouté Roboto comme famille de polices
          fontSize: '17px', // Taille de police changée à 17px
          fontWeight: '400', // Assurez-vous que le poids de la police est normal
          marginBottom: '10px', // Ajustez la valeur selon les besoins
        }}>
          Après votre consultation initiale, je prends le temps pour une évaluation approfondie et la création d'un plan personnalisé.
        </p>

        <ul style={{ 
          listStyleType: 'disc', 
          marginLeft: '20px', 
          fontSize: '17px', 
        }}>  
          <li>
            <strong>Analyse du potentiel :</strong> J'évalue votre contexte sportif et académique pour mesurer vos perspectives aux États-Unis.
          </li>
          <li>
            <strong>Plan d'action :</strong> Je développe un plan sur mesure, y compris un calendrier et des jalons, pour atteindre vos objectifs.
          </li>
          <li>
            <strong>Conseil stratégique :</strong> Conseils sur la manière d'améliorer vos performances sportives et académiques pour augmenter vos chances de bourse.
          </li>
          <li>
            <strong>Universités cibles :</strong> Ensemble, nous identifions une liste d'universités cibles qui correspondent à votre profil et à vos ambitions.
          </li>
        </ul>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Ajouté Roboto comme famille de polices
          fontSize: '17px', // Taille de police changée à 17px
          fontWeight: '400', // Assurez-vous que le poids de la police est normal
        }}>
          Ce plan détaillé constitue la base de votre parcours vers une bourse sportive et assure que vous êtes préparé pour chaque étape suivante.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ ...boxStyle, position: 'relative' }} // Ajoutez la position relative au style
        contentArrowStyle={arrowStyle}
        date="Étape 3"
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        icon={<VideocamIcon />}
      >
        <div style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          opacity: 0.5 // Opacité réduite pour un effet de filigrane
        }}>
          <Filter3Icon style={{ color: 'grey', fontSize: '48px' }} />
        </div>
        <h3 className="vertical-timeline-element-title">Matériel vidéo et portfolio sportif :</h3>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Ajouté Roboto comme famille de polices
          fontSize: '17px', // Taille de police changée à 17px
          fontWeight: '400', // Assurez-vous que le poids de la police est normal
          marginBottom: '10px', // Ajustez la valeur selon les besoins
        }}>
          Créer un portfolio sportif convaincant et du matériel vidéo professionnel est crucial pour votre visibilité auprès des entraîneurs.
        </p>

        <ul style={{ 
          listStyleType: 'disc', 
          marginLeft: '20px', 
          fontSize: '17px', 
        }}>  
          <li>
            <strong>Production vidéo :</strong> Je conseille sur le contenu et supervise la production d'une vidéo de faits saillants qui présente vos compétences de manière optimale.
          </li>
          <li>
            <strong>Développement de portfolio :</strong> J'aide à assembler un portfolio sportif complet, incluant statistiques, réalisations et recommandations.
          </li>
          <li>
            <strong>Histoire personnelle :</strong> Votre parcours et vos réalisations sportives sont tissés dans un récit personnel qui vous distingue.
          </li>
          <li>
            <strong>Présentation numérique :</strong> Je m'assure que votre portfolio et vidéo sont accessibles numériquement et partageables pour les entraîneurs et les équipes de recrutement.
          </li>
        </ul>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Ajouté Roboto comme famille de polices
          fontSize: '17px', // Taille de police changée à 17px
          fontWeight: '400', // Assurez-vous que le poids de la police est normal
        }}>
          Avec cette étape, nous nous assurons que votre talent est mis en évidence de manière indéniable et attire l'attention des universités américaines.
        </p>
      </VerticalTimelineElement>




      <VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Ajoutez la position relative au style
  contentArrowStyle={arrowStyle}
  date="Étape 4"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<QuizIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Opacité réduite pour un effet de filigrane
  }}>
    <Filter4Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Préparation académique :</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Ajouté Roboto comme famille de polices
    fontSize: '17px', // Taille de police changée à 17px
    fontWeight: '400', // Assurez-vous que le poids de la police est normal
    marginBottom: '10px', // Ajustez la valeur selon les besoins
  }}>
    Une bonne préparation académique est essentielle pour l'admission et la réussite aux États-Unis.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Préparation aux tests :</strong> Je fournis des matériaux d'étude et des orientations pour les tests SAT/ACT et TOEFL/IELTS, essentiels pour votre admission.
    </li>
    <li>
      <strong>Planification des études :</strong> Ensemble, nous élaborons un plan d'étude pour vous préparer aux examens, sans négliger votre entraînement sportif.
    </li>
    <li>
      <strong>Documents de candidature :</strong> Je vous aide à préparer et à réviser vos essais de candidature et autres documents requis.
    </li>
    <li>
      <strong>Conseil académique :</strong> Vous recevez des conseils sur les exigences académiques et comment renforcer votre profil académique.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Ajouté Roboto comme famille de polices
    fontSize: '17px', // Taille de police changée à 17px
    fontWeight: '400', // Assurez-vous que le poids de la police est normal
  }}>
    Mon objectif est de m'assurer que vous êtes académiquement préparé non seulement à être accepté, mais aussi à exceller à l'université.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Ajoutez la position relative au style
  contentArrowStyle={arrowStyle}
  date="Étape 5"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<SchoolIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Opacité réduite pour un effet de filigrane
  }}>
    <Filter5Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Promotion auprès des universités :</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Ajouté Roboto comme famille de polices
    fontSize: '17px', // Taille de police changée à 17px
    fontWeight: '400', // Assurez-vous que le poids de la police est normal
    marginBottom: '10px', // Ajustez la valeur selon les besoins
  }}>
    La promotion de votre profil auprès des universités est une étape clé où mon expertise et mon vaste réseau entrent en jeu.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Rôle clé du réseau :</strong> Mon vaste réseau au sein des universités américaines est inestimable pour mettre en lumière votre candidature.
    </li>
    <li>
      <strong>Positionnement stratégique :</strong> J'utilise mes connaissances et mon expérience pour positionner stratégiquement votre profil afin de le faire ressortir auprès des bons programmes sportifs et institutions académiques.
    </li>
    <li>
      <strong>Plan de promotion adaptable :</strong> Je crée un plan de promotion flexible et personnalisé qui met en évidence les aspects uniques de votre profil sportif et académique.
    </li>
    <li>
      <strong>Maximisation des opportunités :</strong> Par une promotion ciblée, je m'assure que vos chances d'obtenir une bourse sont maximisées, en me concentrant sur le meilleur ajustement pour vos ambitions personnelles et sportives.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Ajouté Roboto comme famille de polices
    fontSize: '17px', // Taille de police changée à 17px
    fontWeight: '400', // Assurez-vous que le poids de la police est normal
  }}>
    Ma valeur ajoutée réside dans la mise en avant efficace de votre talent et de vos aspirations, augmentant considérablement les chances de succès. C'est ici que mon réseau, ma compréhension du système universitaire américain et mon approche personnalisée font toute la différence.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Ajoutez la position relative au style
  contentArrowStyle={arrowStyle}
  date="Étape 6"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<RecordVoiceOverIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Opacité réduite pour un effet de filigrane
  }}>
    <Filter6Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Communication avec les entraîneurs :</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    Une communication efficace avec les entraîneurs est essentielle. J'agis comme votre représentant personnel pour faire une forte première impression.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Présentations directes :</strong> Des présentations personnelles aux entraîneurs de mon réseau, adaptées à votre discipline sportive.
    </li>
    <li>
      <strong>Stratégie de communication :</strong> Développement d'une stratégie de communication efficace pour souligner vos qualités et ambitions.
    </li>
    <li>
      <strong>Conseils en négociation :</strong> Conseils pour communiquer avec les entraîneurs pendant la phase de négociation des bourses sportives.
    </li>
    <li>
      <strong>Soutien continu :</strong> Un soutien et des conseils continus dans toutes vos communications pour vous assurer de faire une impression positive et durable.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Mon rôle est de servir de pont entre vous et les entraîneurs américains, et de créer un canal de communication qui maximise vos chances d'obtenir une bourse sportive réussie.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Ajoutez la position relative au style
  contentArrowStyle={arrowStyle}
  date="Étape 7"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<RuleIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Opacité réduite pour un effet de filigrane
  }}>
    <Filter7Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Sélection et offres de bourses :</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    Le processus de sélection est crucial ; c'est là que les fruits de notre travail commun se concrétisent sous forme d'offres de bourses.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Évaluation des offres :</strong> Nous examinons ensemble les offres reçues et discutons des avantages et inconvénients de chaque option.
    </li>
    <li>
      <strong>Conseils stratégiques :</strong> Je vous offre des conseils stratégiques pour vous aider à prendre la meilleure décision pour votre avenir sportif et académique.
    </li>
    <li>
      <strong>Soutien à la négociation :</strong> Mon expérience dans les négociations est utilisée pour obtenir les conditions les plus favorables pour vous.
    </li>
    <li>
      <strong>Choix final :</strong> Avec mon aide, vous faites un choix final éclairé où commence votre carrière sportive et éducative aux États-Unis.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Mon conseil dans cette phase vise à sécuriser le meilleur résultat possible pour vos ambitions et à garantir votre avenir dans une université de premier plan en Amérique.
  </p>
  
</VerticalTimelineElement>

<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Ajoutez la position relative au style
  contentArrowStyle={arrowStyle}
  date="Étape 8"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<DescriptionIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Opacité réduite pour un effet de filigrane
  }}>
    <Filter8Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Demande de visa et processus d'inscription :</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    Après la sélection, une phase critique commence : la finalisation de votre demande de visa et des procédures d'inscription.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Demande de visa :</strong> Orientation pour la demande du visa étudiant approprié, y compris la collecte de la documentation nécessaire.
    </li>
    <li>
      <strong>Aide à l'inscription :</strong> Assistance dans la complétion de tous les formulaires et documents d'inscription pour votre université et compétition choisies.
    </li>
    <li>
      <strong>Planification financière :</strong> Conseils sur les questions financières telles que les frais de bourse, les frais de scolarité et les coûts de vie aux États-Unis.
    </li>
    <li>
      <strong>Vérification finale :</strong> Un contrôle final approfondi pour s'assurer que tous les aspects administratifs et logistiques sont pris en compte.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Mon conseil en préparation vous assure de commencer votre aventure américaine en toute confiance et bien informé.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Ajoutez la position relative au style
  contentArrowStyle={arrowStyle}
  date="Étape 9"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<ChecklistIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Opacité réduite pour un effet de filigrane
  }}>
    <Filter8Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Préparatifs pour le départ :</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    Une transition en douceur vers les États-Unis nécessite une préparation méticuleuse ; les derniers arrangements sont maintenant en cours.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Liste de vérification :</strong> Une liste de vérification complète pour s'assurer que rien n'est oublié, des documents essentiels aux nécessités personnelles.
    </li>
    <li>
      <strong>Logement :</strong> Assistance dans la recherche d'un logement adapté à proximité de votre université et avec les commodités appropriées.
    </li>
    <li>
      <strong>Assurance :</strong> Conseils sur les assurances appropriées pour la santé, le voyage et le séjour.
    </li>
    <li>
      <strong>Briefing :</strong> Une séance d'information sur les différences culturelles, la vie aux États-Unis et des conseils pratiques pour la vie quotidienne.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Mon conseil en préparation vous assure de commencer votre aventure américaine en toute confiance et bien informé.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Ajoutez la position relative au style
  contentArrowStyle={arrowStyle}
  date="Étape 10"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<FlightTakeoffIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Opacité réduite pour un effet de filigrane
  }}>
    <Filter9Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Départ et arrivée aux États-Unis :</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    La dernière étape de votre préparation est arrivée : le voyage vers l'Amérique.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Préparatifs pour le départ :</strong> Je m'assure que vous êtes entièrement préparé pour votre départ, avec une liste de vérification détaillée et des conseils finaux.
    </li>
    <li>
      <strong>Moment de l'adieu :</strong> Un moment pour réfléchir sur cette étape importante et dire au revoir avant de poursuivre votre rêve.
    </li>
    <li>
      <strong>Arrivée aux États-Unis :</strong> À l'arrivée, je propose un soutien pour vous aider à vous installer et à vous orienter dans votre nouvel environnement.
    </li>
    <li>
      <strong>Les premiers jours :</strong> Les premiers jours sont excitants, et je m'assure que vous recevez le soutien nécessaire pour bien commencer.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Je suis à vos côtés à chaque étape, du décollage en France à votre arrivée sur le campus aux États-Unis, prêt à commencer vos études et votre carrière sportive.
  </p>
  
</VerticalTimelineElement>

<VerticalTimelineElement
  iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
  icon={<SportsScoreIcon />}
/>
</VerticalTimeline>

  );
}

export default Chronologie;
