import Alabama from "assets/images/Staten_Images/Alabama.webp"
import Florida from "assets/images/Staten_Images/Florida.jpeg"
import Connecticut from "assets/images/Staten_Images/Connecticut.jpeg"
import Kentucky from "assets/images/Staten_Images/Kentucky.webp"
import Michigan from "assets/images/Staten_Images/Michigan.jpeg"
import North_Carolina from "assets/images/Staten_Images/North_Carolina.jpg"
import Texas from "assets/images/Staten_Images/Texas.jpeg"



const states = [
  {
    "name": "Alabama",
    image: Alabama,
    "imageDescription": "Alabama vs Auburn è una delle più grandi rivalità nel football universitario.",
    "description": [
      "Gli sport universitari sono parte integrante della cultura in Alabama, con un'attenzione speciale al football americano. La University of Alabama (UA) a Tuscaloosa e l'Auburn University ad Auburn dominano il panorama sportivo nello stato.",
      "La squadra di football della UA, i Crimson Tide, ha una ricca storia e ha vinto numerosi campionati nazionali. Le partite casalinghe nell'immensa Bryant-Denny Stadium possono attrarre fino a 100.000 fan. La passione dei tifosi è enorme, e il coro 'Roll Tide' è conosciuto in tutta l'Alabama.",
      "D'altro canto, abbiamo l'Auburn University con la loro squadra di football, i Tigers. Loro, insieme alla UA, compongono l'Iron Bowl, una delle rivalità più intense negli sport universitari. Questo scontro annuale capovolge l'intero stato e garantisce eccitazione e spettacolo.",
      "Oltre al football, l'Alabama ha programmi solidi di basket, baseball e altri sport, supportati da un potente spirito comunitario. In Alabama, gli sport sono più di passatempi; sono una parte essenziale dello stile di vita e dell'identità dello stato."
    ]
  },
  {
    "name": "Alaska",
    "description": [
      "A differenza di molti altri stati americani, gli sport universitari giocano un ruolo più modesto in Alaska, principalmente a causa della sua posizione remota e del clima unico. Qui, l'accento è più sui sport invernali e sulle attività all'aperto che sugli sport americani tradizionali come il football e il baseball.",
      "La University of Alaska Anchorage (UAA) e la University of Alaska Fairbanks (UAF) sono le principali istituzioni con programmi sportivi attivi. La UAA ha una forte squadra di sci e pattinaggio, mentre la UAF è conosciuta per la sua squadra di hockey su ghiaccio, i Nanooks, che hanno un fedele seguito locale.",
      "In Alaska, gli sport universitari riguardano più l'esperienza e la comunità che grandi eventi. Le partite offrono l'opportunità alla comunità di riunirsi, tifare per gli atleti e celebrare la cultura sportiva unica dello stato.",
      "Nonostante la scala più piccola, gli sport universitari in Alaska hanno il loro fascino e offrono una comunità accogliente nel freddo ambiente dello stato."
    ]
  },
  {
    "name": "Arizona",
    "description": [
      "Gli sport universitari sono estremamente popolari in Arizona, con una ricca tradizione sia nel football americano che nel basket. La Arizona State University (ASU) a Tempe e la University of Arizona (UA) a Tucson sono i pilastri del panorama sportivo dello stato.",
      "La squadra di football della ASU, i Sun Devils, gode di un seguito appassionato, mentre la loro squadra di basket brilla regolarmente nei tornei della NCAA. La UA, con i loro Wildcats, è conosciuta per il loro programma di basket di successo e l'intensa rivalità con la ASU, che culmina nell'annuale 'Duel in the Desert'.",
      "Oltre ai principali sport, l'Arizona eccelle anche nel baseball, nel softball e nel golf, con squadre universitarie spesso sotto i riflettori nazionali. Il sostegno della comunità è travolgente, e gli eventi sportivi sono considerati occasioni sociali significative.",
      "Gli sport universitari in Arizona incarnano lo spirito della competizione e della comunità, con intense rivalità e passione dei tifosi che contribuiscono a una cultura sportiva vibrante e vivace."
    ]
  },



  {
    "name": "Arkansas",
    "description": [
      "In Arkansas, gli sport universitari giocano un ruolo centrale nella vita della comunità, con un'attenzione speciale al football americano e al basket. La University of Arkansas a Fayetteville è una potenza nello stato, conosciuta per i suoi Razorbacks.",
      "La squadra di football dei Razorbacks gode di riconoscimento regionale e nazionale, con una base di fan dedicata che segue appassionatamente ogni partita. Le loro partite casalinghe nell'impressionante Razorback Stadium sono uno spettacolo, dove il coro 'Woo Pig Sooie' viene echeggiato da decine di migliaia di fan.",
      "Anche il basket è una parte significativa della cultura sportiva in Arkansas, con i Razorbacks che partecipano regolarmente al torneo della NCAA. L'eccitazione e l'energia nel Bud Walton Arena durante la stagione del basket sono tangibili.",
      "Oltre agli sport principali, i Razorbacks hanno anche programmi solidi di atletica leggera, baseball e softball. Questa diversità negli sport arricchisce la cultura sportiva in Arkansas, dove gli sport universitari sono una fonte di orgoglio, spirito comunitario e intrattenimento."
    ]
  },
  {
    "name": "California",
    "description": [
      "Gli sport universitari in California sono diversi e altamente competitivi, con numerose università che eccellono in una varietà di sport. Dal football americano al nuoto, lo stato ha tutto.",
      "La conferenza Pac-12, con scuole importanti come la University of California, Los Angeles (UCLA) e la University of Southern California (USC), è nota per il suo alto livello sportivo. La squadra di basket della UCLA ha una ricca storia, mentre la USC eccelle tradizionalmente nel football.",
      "Oltre alle squadre ben note, ci sono anche scuole più piccole con programmi sportivi impressionanti, come la Stanford University, nota per l'eccellenza nel nuoto e nell'atletica. Questa diversità crea un ambiente sportivo dinamico ed eccitante nello stato.",
      "Il clima piacevole della California e le ampie strutture sportive promuovono la partecipazione attiva e l'entusiasmo per gli sport universitari. Che si tratti di una partita di football in uno stadio affollato o di una competizione di nuoto locale, gli sport sono parte integrante dello stile di vita californiano."
    ]
  },
  {
    "name": "Colorado",
    "description": [
      "Gli sport universitari in Colorado godono di un seguito vibrante ed entusiasta, in parte grazie ai magnifici dintorni naturali e allo stile di vita attivo all'aperto. Lo stato ha una ricca tradizione nel football americano, nel basket e negli sport invernali.",
      "La University of Colorado Boulder (CU) e la Colorado State University (CSU) sono protagonisti nel panorama degli sport universitari, con il 'Rocky Mountain Showdown' annuale come punto culminante della loro rivalità.",
      "La squadra di football della CU, i Buffaloes, ha una base di fan appassionata, mentre la loro squadra di sci eccelle nelle competizioni nazionali, beneficiando delle eccellenti strutture per gli sport invernali del Colorado. Anche la CSU è competitiva in vari sport, con una forte presenza nel football americano e nel basket.",
      "Oltre alle università più grandi, anche i college più piccoli in Colorado hanno programmi sportivi attivi, contribuendo alla ricca e diversificata cultura sportiva nello stato. Gli sport universitari in Colorado rappresentano comunità, perseveranza e celebrazione dello sport in tutte le forme."
    ]
  },
  {
    "name": "Connecticut",
    image: Connecticut,

    "imageDescription": "La squadra di basket femminile dell'UConn è una delle squadre universitarie più dominanti di tutti i tempi",
    "description": [
      "Gli sport universitari in Connecticut sono dominati dal basket, con un'attenzione speciale ai programmi di successo della University of Connecticut (UConn). Lo stato ha una ricca tradizione e una base di fan appassionata profondamente investita negli sport universitari.",
      "La squadra di basket femminile dell'UConn è rinomata a livello nazionale e ha vinto diversi campionati NCAA, diventando una potenza nello sport. Anche la squadra maschile ha ottenuto prestazioni impressionanti, rendendo il basket una parte centrale della cultura sportiva in Connecticut.",
      "Mentre il basket domina, ci sono anche programmi solidi in altri sport come l'hockey su ghiaccio, il football americano e il lacrosse, con varie università competitive a livello nazionale.",
      "Gli sport universitari in Connecticut incarnano l'orgoglio e lo spirito comunitario dello stato, dove gli eventi sportivi offrono l'opportunità per i fan di riunirsi, tifare per le squadre e partecipare a una ricca tradizione sportiva."
    ]
  },
  
  {
    "name": "Delaware",
    "description": [
      "Nonostante sia uno dei più piccoli stati degli Stati Uniti, il Delaware ha una vibrante scena degli sport universitari con un forte coinvolgimento della comunità. La University of Delaware (UD) a Newark è al centro dell'azione, dove i Blue Hens eccellono in vari sport.",
      "La squadra di football della UD ha una ricca storia e una base di fan dedicata, con emozionanti partite casalinghe allo Delaware Stadium. Anche il basket e il lacrosse sono sport popolari, con squadre che competono regolarmente per il riconoscimento regionale e nazionale.",
      "Nonostante le sue dimensioni ridotte, il Delaware ha programmi solidi in atletica, nuoto e altri sport, tutti contribuendo alla dinamica cultura sportiva nello stato. Gli sport universitari qui sono più di un semplice passatempo; sono una parte importante dell'identità della comunità, promuovendo un forte legame tra l'università e la comunità più ampia.",
      "Nel Delaware, gli sport universitari uniscono le persone, con la scala ridotta che crea una comunità sportiva unita e appassionata."
    ]
  },
  {
    "name": "Florida",
    image: Florida,
    "imageDescription": "Lo stadio Ben Hill Griffin è la sede dei Florida Gators",
    "description": [
      "La Florida è un punto di riferimento per gli sport universitari, con alcuni dei programmi più competitivi e appassionati del paese. Il football americano e il basket dominano la scena sportiva, ma c'è anche spazio per una varietà di altri sport.",
      "Squadre come i Florida Gators e i Florida State Seminoles hanno riconoscimento nazionale e una ricca storia sia nel football che nel basket. La loro intensa rivalità attira migliaia di fan ogni anno, e le partite sono i momenti salienti della stagione sportiva.",
      "Inoltre, ci sono anche università e college più piccoli con programmi sportivi solidi che contribuiscono alla ricca cultura sportiva nello stato. Anche il baseball, l'atletica e il nuoto sono popolari, e le eccellenti strutture e il clima soleggiato rendono la Florida un luogo ideale per gli atleti universitari.",
      "In Florida, gli sport universitari sono più di una semplice competizione; sono uno stile di vita, una fonte di orgoglio e una parte essenziale dell'identità della comunità."
    ]
  },
  {
    "name": "Georgia",
    "description": [
      "Gli sport universitari in Georgia sono caratterizzati da una passione intensa e da rivalità radicate, con il football americano come indiscusso re. Lo stato ospita alcuni dei programmi più prestigiosi del paese, con i fan che vivono con passione ogni partita.",
      "La University of Georgia Bulldogs ad Athens e la Georgia Tech Yellow Jackets ad Atlanta sono i protagonisti principali, con la loro annuale rivalità 'Clean, Old-Fashioned Hate' che tiene con il fiato sospeso lo stato. Le partite di football, specialmente nello enorme Sanford Stadium dell'UGA, attirano decine di migliaia di fan.",
      "Oltre al football, la Georgia eccelle anche nel basket, nell'atletica e nel baseball, con atleti talentuosi e sostenitori dedicati. I programmi sportivi presso le scuole e i college più piccoli contribuiscono a un paesaggio sportivo ricco e diversificato in tutto lo stato.",
      "In Georgia, lo sport è più della competizione; è uno stile di vita, una fonte di spirito comunitario e una parte indispensabile dell'identità regionale."
    ]
  },
  {
    "name": "Hawaii",
    "description": [
      "Gli sport universitari ad Hawaii offrono un'esperienza unica ispirata alla bellezza dell'isola e alla forte cultura locale. Nonostante sia geograficamente isolato, squadre e fan ad Hawaii sono straordinariamente appassionati e coinvolti.",
      "La University of Hawaii at Manoa gioca un ruolo centrale nella scena degli sport universitari, con la sua squadra di football, i Rainbow Warriors, come punto focale significativo. Le loro partite casalinghe all'Aloha Stadium creano un'atmosfera vivace e festosa, con un forte spirito isolano.",
      "Oltre al football americano, la pallavolo, il surf e la canoa sono anche popolari, riflettendo la cultura sportiva unica e il paesaggio naturale di Hawaii. I Rainbow Warriors sono anche competitivi nel basket e nel baseball, con un impegno condiviso da tutta la comunità.",
      "Ad Hawaii, gli sport universitari rappresentano lo spirito Aloha, con squadre e fan che abbracciano la cultura dell'isola, la comunità e l'orgoglio, creando insieme un'esperienza sportiva unica e vivace."
    ]
  },

  {
    "name": "Idaho",
    "description": [
      "Gli sport universitari in Idaho hanno una forte tradizione, con un particolare focus sul football americano e il basket. Il senso di comunità e il coinvolgimento nelle squadre sportive sono notevoli, anche se lo stato non ospita grandi università.",
      "La Boise State University, famosa per il suo campo da football blu, gioca un ruolo di spicco nel panorama sportivo dell'Idaho. La squadra di football ha attirato l'attenzione nazionale con il suo successo e lo stile di gioco innovativo, e le partite casalinghe sono uno spettacolo a sé stante.",
      "Oltre alla Boise State, scuole e college più piccoli contribuiscono anche alla ricca cultura sportiva, con programmi in sport come baseball, atletica e sport invernali che beneficiano dell'ambiente naturale unico dell'Idaho.",
      "In Idaho, gli sport universitari sono più di un semplice passatempo; sono uno stile di vita e una parte essenziale dello stile di vita e dell'identità dello stato, con le comunità che si uniscono per sostenere le proprie squadre."
    ]
  },
  {
    "name": "Illinois",
    "description": [
      "Il Illinois ha una ricca storia e un profondo amore per gli sport universitari, con programmi potenti e fan dedicati. Il football americano e il basket attirano molta attenzione durante tutto l'anno, ma ci sono anche una vasta gamma di altri sport in pieno sviluppo.",
      "La University of Illinois Fighting Illini e la Northwestern University Wildcats guidano la strada, con rivalità intense e leghe competitive che mantengono vivo lo stato. Il Memorial Stadium e il Ryan Field sono templi dello sport, dove ogni partita è un'esperienza indimenticabile.",
      "Anche il basket è prominente, con lo stato che produce regolarmente talenti che eccellono sia a livello nazionale che internazionale. Gli Illinois Fighting Illini hanno una ricca tradizione nel basket e sono una costante minaccia nei tornei NCAA.",
      "Dai piccoli college alle grandi università, lo sport in Illinois serve come legame, fonte di orgoglio e parte essenziale della cultura e dell'identità dello stato."
    ]
  },
  {
    "name": "Indiana",
    "description": [
      "L'Indiana è nota per il suo profondo amore radicato per il basket, e ciò è evidente nella cultura degli sport universitari dello stato. La rivalità, la passione e la dedizione sia dei giocatori che dei fan sono senza pari.",
      "Programmi come gli Indiana Hoosiers e i Purdue Boilermakers sono riconosciuti a livello nazionale, specialmente nel basket. Un'atmosfera elettrica aleggia in luoghi come l'Assembly Hall e il Mackey Arena durante tutto l'anno, dove storia e tradizione si uniscono.",
      "Anche se il basket prende il sopravvento, altri sport prosperano anche in Indiana. Il football americano, il baseball e l'atletica hanno tutti programmi solidi e godono di un ampio sostegno della comunità.",
      "Lo sport in Indiana è più di un semplice gioco; è uno stile di vita, un catalizzatore per lo spirito comunitario e una tradizione orgogliosa tramandata di generazione in generazione. Qui, nel cuore del Midwest, gli sport universitari sono parte integrante dell'identità dello stato."
    ]
  },
  {
    "name": "Iowa",
    "description": [
      "Gli sport universitari in Iowa sono una fonte di orgoglio e spirito comunitario, con una ricca tradizione sia nel football americano che nella lotta. La rivalità tra gli Iowa Hawkeyes e gli Iowa State Cyclones è centrale e offre momenti indimenticabili ogni stagione.",
      "Lo stadio Kinnick a Iowa City e lo stadio Jack Trice a Ames sono i luoghi per intense partite di football americano ogni autunno, attirando decine di migliaia di fan. La passione e la dedizione dei fan sono caratteristiche della cultura sportiva dello stato.",
      "Anche la lotta è uno sport in cui l'Iowa eccelle, con gli Hawkeyes che vincono regolarmente titoli nazionali. Questo sport ha una tradizione radicata in Iowa e gode di un sostegno leale.",
      "Dai piccoli college alle grandi università, lo sport in Iowa è più della competizione; è uno stile di vita, una fonte di spirito comunitario e una parte importante dell'identità dello stato."
    ]
  },

  {
    "name": "Kansas",
    "description": [
      "Gli sport universitari in Kansas sono impregnati di tradizione e spirito comunitario, con il basket come indiscusso punto forte. Lo stato è sede di alcuni dei programmi più iconici del paese, e la rivalità e la passione sono intense.",
      "I Jayhawks del Kansas e i Wildcats dello Kansas State guidano l'azione, con il loro annuale 'Sunflower Showdown' che è uno dei momenti salienti dell'anno sportivo. L'Allen Fieldhouse, casa dei Jayhawks, è conosciuta come una delle arene più intimidatorie nel basket universitario.",
      "Anche se il basket forma il cuore della cultura sportiva in Kansas, il football americano, il baseball e l'atletica giocano anche un ruolo significativo. I fan sono incomparabili nella loro dedizione e contribuiscono all'atmosfera vibrante durante le partite.",
      "Lo sport in Kansas è più che una semplice competizione; è una fonte di orgoglio, un catalizzatore per la comunità e una parte essenziale dell'identità dello stato."
    ]
  },
  {
    "name": "Kentucky",
    image: Kentucky,
    "imageDescription": "Karl-Anthony Towns e Devin Booker hanno giocato insieme per il Kentucky e in seguito sono diventati importanti stelle della NBA",
    "description": [
      "Gli sport universitari in Kentucky sono sinonimo di basket, con una ricca tradizione e una base di fan appassionati. La rivalità tra i Wildcats del Kentucky e i Cardinals di Louisville è una delle più intense e seguite del paese.",
      "La Rupp Arena, sede dei Wildcats, è un vero e proprio tempio del basket, dove la storia e l'orgoglio del programma sono tangibili. I Wildcats hanno più campionati nazionali al loro attivo e sono una presenza fissa nel basket universitario.",
      "Oltre al basket, il Kentucky ha anche programmi solidi nel football americano, negli sport equestri e nel tiro, che arricchiscono la cultura sportiva dello stato. Il Kentucky Derby, una leggendaria corsa di cavalli, sottolinea la ricca tradizione negli sport equestri.",
      "In Kentucky, gli sport sono più di un passatempo; sono uno stile di vita, una fonte di comunità e orgoglio e una parte cruciale dell'identità dello stato."
    ]
  },
  {
    "name": "Louisiana",
    "description": [
      "In Louisiana, gli sport universitari sono una parte integrante della cultura, con una profonda passione per il football americano e il baseball. I Tigers della Louisiana State University (LSU) sono una potenza, attirando masse enormi e creando un'atmosfera impareggiabile.",
      "Lo stadio dei Tigers, noto anche come 'Death Valley', è famoso per i suoi tifosi chiassosi e l'atmosfera intimidatoria, rendendolo uno dei campi di casa più temuti nel football universitario. Il 'Sabato sera a Death Valley' è più di una partita; è un evento, una tradizione, una parte dell'anima della Louisiana.",
      "Oltre alla LSU, università e college più piccoli contribuiscono anche al ricco paesaggio sportivo, con programmi solidi in vari sport. Anche il baseball gode di grande popolarità, con la LSU che gareggia regolarmente per il titolo nazionale.",
      "Gli sport in Louisiana sono una celebrazione della comunità, una fonte di orgoglio e un legame che unisce le persone, indipendentemente dal loro background. È una parte vibrante ed essenziale dello stile di vita e dell'identità dello stato."
    ]
  },
  {
    "name": "Maine",
    "description": [
      "Gli sport universitari in Maine riflettono la natura unica dello stato e il senso di comunità, con un forte focus sugli sport invernali e sull'atletica. La University of Maine Black Bears guida la strada, rappresentando con orgoglio in varie divisioni della NCAA.",
      "Lo stadio Alfond a Orono è il cuore del football americano nello stato, mentre l'Harold Alfond Sports Arena gioca un ruolo centrale nella stagione di hockey su ghiaccio. I Black Bears hanno una fiorente tradizione di hockey su ghiaccio, con appassionati tifosi che sfidano il freddo per sostenere la propria squadra.",
      "Oltre all'hockey su ghiaccio e al football americano, ci sono anche programmi solidi in atletica, canottaggio e altri sport che arricchiscono il panorama sportivo del Maine. Lo stato gode anche di vivaci competizioni sportive delle scuole superiori e dei club, rendendo lo sport una parte integrante della comunità.",
      "Gli sport in Maine sono più di una semplice competizione; sono una fonte di orgoglio, un modo per celebrare lo spirito unico dello stato e un legame che unisce le persone, indipendentemente dalla stagione."
    ]
  },
  
  {
    "name": "Maryland",
    "description": [
      "Gli sport universitari in Maryland prosperano con una forte tradizione nel basket, nel lacrosse e nel football americano. Gli University of Maryland Terrapins, o semplicemente i Terps, sono centrali nella cultura sportiva dello stato.",
      "Il Xfinity Center e il Maryland Stadium sono gli orgogliosi luoghi per il basket e il football, dove i Terps giocano le loro partite casalinghe. I fan sono ferventi e contribuiscono a un'atmosfera elettrizzante durante le partite. Il Maryland è conosciuto per il suo programma competitivo di basket, con molteplici apparizioni nei tornei NCAA.",
      "Anche il lacrosse è uno sport in cui il Maryland eccelle, con i Terps che competono regolarmente per titoli nazionali. Questo sport occupa un posto speciale nei cuori dei residenti, e le intense rivalità universitarie rendono ogni stagione uno spettacolo.",
      "Gli sport in Maryland sono un'espressione di comunità, orgoglio e tradizione, svolgendo un ruolo cruciale nel riunire le persone e celebrare la cultura unica dello stato."
    ]
  },
  {
    "name": "Massachusetts",
    "description": [
      "In Massachusetts, gli sport universitari giocano un ruolo significativo nella vita quotidiana, con una ricca storia nel basket, nel football americano e nell'hockey. Le università qui hanno programmi sportivi solidi e fan appassionati.",
      "Il basket occupa un posto speciale nei cuori dei residenti del Massachusetts, poiché il gioco è stato inventato qui. Gli University of Massachusetts Minutemen, conosciuti come gli UMass Minutemen, sono fieri rappresentanti nella Division I della NCAA.",
      "Anche il football americano e l'hockey sono popolari, con intense rivalità locali che offrono emozionanti competizioni. La partita di football tra Harvard e Yale, nota come 'The Game', è un momento saliente annuale.",
      "Gli sport in Massachusetts sono sinonimo di tradizione, comunità e competizione. Riuniscono le persone, indipendentemente dal loro background, e sono una parte essenziale dell'identità culturale dello stato."
    ]
  },
  {
    "name": "Michigan",
    image: Michigan,
    "imageDescription": "Michigan Stadium: il terzo stadio più grande del mondo e casa dei Michigan Wolverines. Può contenere 107.601 fan.",
    "description": [
      "Il Michigan ha una ricca tradizione negli sport universitari, specialmente nel football americano e nel basket. La rivalità tra i Michigan Wolverines dell'Università del Michigan e gli Spartani dello Stato del Michigan è leggendaria e crea un'atmosfera elettrica in tutto lo stato.",
      "Il Michigan Stadium, anche conosciuto come 'The Big House', è uno dei più grandi stadi del mondo, con una capacità di oltre 100.000 fan. I Wolverines hanno una storia gloriosa, con diversi titoli nazionali nel football.",
      "Anche il basket è molto popolare, con sia il Michigan che lo Stato del Michigan che competono regolarmente nel torneo NCAA. La passione e la dedizione dei fan sono senza pari, e le giornate di gioco sono veri eventi nel Michigan.",
      "Oltre a questi sport principali, il Michigan ha anche programmi solidi nell'hockey su ghiaccio, nell'atletica e in altri sport. Gli sport nel Michigan sono più di un semplice passatempo; sono una parte integrante dello stile di vita e dell'identità dello stato."
    ]
  },
  {
    "name": "Minnesota",
    "description": [
      "Gli sport universitari in Minnesota hanno un carattere unico, con un forte emphasis sull'hockey su ghiaccio, il football americano e il basket. Il University of Minnesota Golden Gophers gioca un ruolo centrale nella comunità sportiva dello stato.",
      "Il TCF Bank Stadium e il Williams Arena sono centri di attività durante le stagioni di football e basket, mentre il Mariucci Arena è un baluardo per l'hockey su ghiaccio. I Golden Gophers sono fieri rappresentanti della NCAA, con una storia ricca e fan devoti.",
      "L'hockey su ghiaccio è particolarmente popolare in Minnesota, spesso definito 'Lo Stato dell'Hockey'. Le squadre universitarie godono di un forte supporto e forniscono azioni emozionanti, specialmente durante il famoso torneo 'Frozen Four' della NCAA.",
      "Gli sport nel Minnesota sono profondamente intrecciati con la cultura e l'identità dello stato, offrendo una fonte di orgoglio, spirito comunitario e intense rivalità che uniscono persone di tutte le età."
    ]
  },
  {
    "name": "Mississippi",
    "description": [
      "Gli sport universitari sono una parte integrante della cultura nel Mississippi, con una passione intensa per il football americano, il basket e il baseball. La rivalità tra i Rebels dell'Università del Mississippi (Ole Miss) e i Bulldogs dello Stato del Mississippi è una delle più intense del paese.",
      "Il Vaught-Hemingway Stadium a Oxford e il Davis Wade Stadium a Starkville sono veri templi per i tifosi di football, dove la tensione e la passione del gioco sono tangibili. Il consueto Egg Bowl, lo scontro tra Ole Miss e Mississippi State, è un momento culminante nel calendario sportivo.",
      "Anche il basket e il baseball sono significativi, con entrambe le università che costruiscono squadre e tradizioni solide. I fan sono leali e vocali, creando un'atmosfera elettrica durante le partite.",
      "Gli sport nel Mississippi sono incentrati sulla comunità, sull'orgoglio e sulla tradizione. Riuniscono le persone, creano momenti indimenticabili e sono una parte essenziale dell'identità dello stato."
    ]
  },
  {
    "name": "Missouri",
    "description": [
      "Gli sport universitari nel Missouri sono sinonimo di passione, comunità e competizione. Lo stato ha una ricca storia sportiva, con il football americano, il basket e il baseball che prendono il comando.",
      "I Tigers dell'Università del Missouri, anche conosciuti come Mizzou, e i Bears dello Stato del Missouri sono due rappresentanti prominenti nel panorama degli sport universitari. Il Faurot Field e il Mizzou Arena sono epicentri di eccitazione e spirito di squadra durante le giornate di gioco.",
      "I Tigers hanno una forte presenza nella SEC, una delle conferenze più competitive nel football americano universitario. Anche la loro squadra di basket è una costante nel torneo della NCAA, con i fan che forniscono un supporto incondizionato.",
      "Gli sport nel Missouri rafforzano i legami comunitari e uniscono le persone. Non si tratta solo del gioco; si tratta dell'esperienza, delle tradizioni e del legame indissolubile tra le squadre e i loro sostenitori."
    ]
  },
  {
    "name": "Montana",
    "description": [
      "In Montana, gli sport universitari giocano un ruolo cruciale nella vita comunitaria, con il football americano, il basket e il rodeo come sport popolari. I Grizzlies del Montana e i Bobcats dello Stato del Montana dominano il panorama sportivo, e la loro rivalità è intensa.",
      "Il Washington-Grizzly Stadium dei Grizzlies e il Bobcat Stadium dei Bobcats sono orgogliosi stadi di football in Montana. Qui, i fan si riuniscono per sostenere le proprie squadre, indipendentemente dalle condizioni meteorologiche. Il consueto Brawl of the Wild tra i Grizzlies e i Bobcats è un evento da non perdere.",
      "Anche il basket è popolare, con partite vivaci e una forte base di fan. Il rodeo, unico nel Montana, attira anche molta attenzione e apprezzamento, riflettendo la cultura e le tradizioni dello stato.",
      "Gli sport universitari in Montana sono incentrati sulla comunità, sull'orgoglio e sull'amore per il gioco. Riuniscono le persone, creano ricordi indelebili e contribuiscono all'identità unica del Big Sky Country."
    ]
  },
  {
    "name": "Nebraska",
    "description": [
      "In Nebraska, gli sport universitari sono caratterizzati da una profonda passione e lealtà, con un'attenzione speciale per il football americano. I Cornhuskers del Nebraska, basati presso l'Università del Nebraska-Lincoln, sono il cuore della cultura sportiva dello stato.",
      "Il Memorial Stadium, sede dei Cornhuskers, è anche chiamato 'Il Mare Rosso', dove i fan vestiti con il loro caratteristico abbigliamento rosso si riuniscono per sostenere la propria squadra. Lo stadio è noto per il suo rumore assordante e per l'atmosfera unica durante le giornate di gioco.",
      "Oltre al football, anche il basket gioca un ruolo significativo, con squadre maschili e femminili che competono a un alto livello. I fan sono altrettanto dedicati e creano un'atmosfera vibrante ad ogni partita.",
      "Gli sport universitari in Nebraska vanno oltre il semplice gioco; è un modo per unire le comunità, celebrare le tradizioni e creare un senso di orgoglio e unità nello 'Stato del Cornhusker'."
    ]
  },
    
  {
    "name": "Nevada",
    "description": [
      "Gli sport universitari in Nevada offrono un mix unico di intrattenimento e competizione, con particolare attenzione al basket e al football americano. La University of Nevada, Las Vegas (UNLV), e la University of Nevada, Reno sono le due principali istituzioni nello sport.",
      "La squadra di basket della UNLV, i Runnin' Rebels, ha una ricca storia e è conosciuta in tutto il paese. Le loro partite casalinghe sono una vera attrazione, complete di esibizioni energiche e di un pubblico entusiasta. Anche la squadra di football dei Nevada Wolf Pack contribuisce alla cultura sportiva nello stato, con partite emozionanti e una base di fan leale.",
      "In Nevada c'è anche un vivo interesse per altri sport universitari come il baseball, il softball e il calcio. Questi sport attraggono le comunità locali e offrono ampie opportunità per gli atleti di svilupparsi.",
      "Complessivamente, gli sport universitari in Nevada sono una parte essenziale dello stato, fornendo non solo intrattenimento ma anche riunendo le comunità e arricchendo la cultura locale."
    ]
  },
  {
    "name": "New Hampshire",
    "description": [
      "Nel pittoresco stato del New Hampshire, gli sport universitari svolgono un ruolo cruciale nella vita comunitaria. Anche se lo stato è più piccolo rispetto a molti altri, la dedizione allo sport è immensa.",
      "La University of New Hampshire (UNH) domina il panorama sportivo, specialmente nell'hockey, dove i Wildcats si esibiscono costantemente a un alto livello. Il Whittemore Center Arena, lo stadio casalingo della squadra di hockey, è noto per la sua atmosfera vivace e i tifosi appassionati.",
      "Anche il football e il basket sono sport popolari all'interno della UNH, attrattivi grandi folle durante la stagione. La comunità è orgogliosa delle loro squadre, e gli studenti sono strettamente coinvolti negli eventi sportivi.",
      "L'approccio del New Hampshire agli sport universitari è orientato alla comunità, con l'obiettivo di unire le persone, celebrare i successi e creare momenti indimenticabili nel pittoresco ambiente dello stato."
    ]
  },
  {
    "name": "New Jersey",
    "description": [
      "Gli sport universitari in New Jersey godono di un'atmosfera vibrante e competitiva, con varie università che competono a un alto livello. Lo stato ospita alcuni dei principali programmi universitari riconosciuti sia a livello regionale che nazionale.",
      "Una delle squadre universitarie più prominenti è quella dei Rutgers Scarlet Knights, parte della prestigiosa Big Ten Conference. Le loro partite di football e basket attraggono grandi folle e generano eccitazione in tutto lo stato.",
      "Il New Jersey è anche noto per i suoi forti programmi di lacrosse e calcio, che attraggono e sviluppano giovani talenti. Questi sport giocano un ruolo significativo nella cultura degli sport universitari dello stato, con competizioni emozionanti e fan dedicati.",
      "La comunità del New Jersey abbraccia le sue squadre universitarie, risultando in un'atmosfera di supporto e passione ad ogni evento. Gli sport universitari in New Jersey sono più di semplici giochi; sono una parte essenziale del tessuto culturale dello stato."
    ]
  },
  {
    "name": "New Mexico",
    "description": [
      "Il New Mexico, con il suo unico background culturale e geografico, ha una relazione speciale con gli sport universitari. Lo stato è sede di alcuni importanti programmi sportivi universitari che competono a livello nazionale.",
      "I Lobos della University of New Mexico ad Albuquerque sono un punto centrale di orgoglio. La loro squadra di basket attira regolarmente grandi folle a The Pit, uno degli arena più intimidatori del paese.",
      "Anche gli Aggies dello Stato del New Mexico da Las Cruces sono contendenti nazionali, specialmente nel basket e nel football, dove hanno accesi rivalità con i Lobos, culminanti nella Rio Grande Rivalry.",
      "Supportati da una ricca storia e da una base di fan appassionati, gli sport universitari nel New Mexico non solo forniscono intrattenimento ma promuovono anche lo spirito comunitario e l'identità statale. In questo stato desertico, lo sport è una celebrazione del talento, della perseveranza e dei legami comunitari."
    ]
  },


  {
    "name": "New York",
    "description": [
      "Nello stato frenetico di New York, gli sport universitari giocano un ruolo dinamico e versatile. Lo stato è sede di numerose università, ognuna con le proprie tradizioni sportive uniche.",
      "La Syracuse University si distingue con la sua squadra di basket, gli Orange, che spesso competono ai massimi livelli nazionali. Lo stadio Carrier Dome, noto per la sua impressionante capacità e atmosfera vivace, è un'icona nel mondo degli sport universitari.",
      "Inoltre, anche le università e i college più piccoli svolgono un ruolo significativo, con programmi solidi in sport come lacrosse, calcio e baseball. Questi programmi più piccoli alimentano lo spirito competitivo nello stato, creando un paesaggio sportivo ricco e diversificato.",
      "Il coinvolgimento della comunità negli sport universitari a New York è immenso, con i fan che sostengono le proprie squadre nei momenti di gioia e di dolore. Dalle frenetiche città alle più tranquille aree rurali, lo sport unisce i newyorkesi e infonde un senso di orgoglio e solidarietà."
    ]
  },
  {
    "name": "North Carolina",
    image: North_Carolina,
    "imageDescription": "La famosa rivalità: DUKE vs. UNC in azione",
    "description": [
      "La Carolina del Nord ha una ricca tradizione e una passione senza pari per gli sport universitari, in particolare per il basket. La rivalità tra i Tar Heels della University of North Carolina e i Blue Devils della Duke University è famosa in tutto il mondo e garantisce scontri emozionanti.",
      "I Tar Heels e i Blue Devils non solo dominano la stagione del basket, ma portano anche un'ondata di eccitazione e spirito comunitario in tutto lo stato. È una cultura sportiva profondamente radicata nell'identità della Carolina del Nord.",
      "Oltre al basket, il football americano gioca anche un ruolo prominente, con squadre forti e tifoserie appassionate. E in primavera, l'amore per il baseball sboccia, con squadre universitarie e college che competono nei campi.",
      "Gli sport universitari in Carolina del Nord vanno oltre i campi e le sale sportive; sono uno stile di vita, una fonte di orgoglio e un modo per le comunità di riunirsi e celebrare il loro stato."
    ]
  },
  {
    "name": "North Dakota",
    "description": [
      "Gli sport universitari in North Dakota offrono un'esperienza unica, con comunità strettamente coinvolte nelle loro squadre universitarie. Il senso di orgoglio e unità è chiaramente visibile durante gli eventi sportivi, nonostante lo stato abbia meno università prominenti rispetto ad altre regioni americane.",
      "La squadra di football americano della North Dakota State University, i Bison, è nota per i suoi successi nella Division I Football Championship Subdivision della NCAA, il che dà un significativo impulso alla cultura sportiva dello stato. I fan si riuniscono al Fargodome per sostenere la loro squadra, creando un'atmosfera intensa.",
      "Anche l'hockey su ghiaccio gioca un ruolo importante in North Dakota, con i Fighting Hawks della University of North Dakota come squadra di punta. La passione per questo sport invernale è evidente durante la stagione, con i fan che affollano l'arena.",
      "Gli sport universitari in North Dakota, specialmente il football americano e l'hockey su ghiaccio, uniscono le comunità, creano un forte orgoglio locale e contribuiscono alla vibrante cultura sportiva dello stato."
    ]
  },
  {
    "name": "Ohio",
    "description": [
      "Gli sport universitari sono una parte integrante della cultura dell'Ohio, con un'enfasi particolare sul football americano e il basket. L'Ohio State University (OSU) e l'University of Cincinnati sono due istituzioni leader nello sport.",
      "La squadra di football di OSU, i Buckeyes, gode di fama nazionale ed è una vera forza nella NCAA. Le loro partite nello smisurato Ohio Stadium attirano più di 100.000 fan, creando un'atmosfera impareggiabile durante le partite casalinghe. 'O-H-I-O' è un coro conosciuto in tutto lo stato.",
      "Anche il basket è in evidenza, con squadre maschili e femminili che competono a un alto livello. Lo stato dell'Ohio è sede di diverse squadre universitarie che partecipano regolarmente al torneo della NCAA, aggiungendo frenesia al basket.",
      "Gli sport universitari in Ohio offrono più della semplice competizione; sono una fonte di orgoglio, tradizione e spirito comunitario, unendo persone provenienti da tutti gli ambiti della vita."
    ]
  },

  {
    "name": "Oklahoma",
    "description": [
      "In Oklahoma, college sports hold a central place in the hearts of its residents, with a deep-rooted passion for American football. The University of Oklahoma (OU) and Oklahoma State University (OSU) are the jewels of the state's sports world.",
      "OU's football team, the Sooners, has a rich history and numerous successes, creating a vibrant atmosphere around their games. The Gaylord Family Oklahoma Memorial Stadium is a fortress for the team, where fans loudly chant 'Boomer Sooner.'",
      "OSU is not far behind OU, with strong performances in both football and other sports, where their American football team, the Cowboys, plays a significant role. The dedication of fans during the 'Bedlam Series,' the annual battle between OU and OSU, is enormous.",
      "College sports in Oklahoma symbolize the struggle, perseverance, and sense of community of the state, making it much more than just a game."
    ]
  },
  {
    "name": "Oregon",
    "description": [
      "College sports in Oregon are an essential part of the state, with a focus on both American football and athletics. The University of Oregon (UO) in Eugene is central to this sporting passion and has built a national reputation.",
      "UO's football team, the Ducks, is known for their fast-paced play and unique uniforms, while the athletics program is one of the best in the country. The annual Prefontaine Classic athletic event, held at the famous Hayward Field, highlights Oregon's dedication to athletics.",
      "Oregon State University (OSU) with their football team, the Beavers, provides strong competition, especially during the 'Civil War,' the annual clash with the Ducks. This is more than a game; it's a state tradition that brings communities together.",
      "In addition to these sports, basketball also enjoys popularity, and passionate fans make college sports in Oregon an experience you don't want to miss. It's a celebration of athletic talent, community, and the state's unique sports culture."
    ]
  },
  {
    "name": "Pennsylvania",
    "description": [
      "Pennsylvania holds a deep appreciation for college sports, with a strong emphasis on American football, basketball, and athletics. Teams like the Penn State Nittany Lions and the Pittsburgh Panthers dominate the sports landscape and create exciting rivalries.",
      "Beaver Stadium, the home of the Nittany Lions, is known for its overwhelming atmosphere and intense fan experience, especially during 'White Out' games. These moments are the highlight of the sports calendar and attract fans from across the state.",
      "On the basketball court, the Villanova Wildcats have recently achieved national success, with multiple championships that have thrilled the state. Their performances have put Pennsylvania on the map as a basketball powerhouse.",
      "Athletics also play a crucial role, with the prestigious Penn Relays drawing top athletes to Philadelphia every year. This rich sports culture strengthens community spirit and contributes to the pride of Pennsylvania."
    ]
  },
  {
    "name": "Rhode Island",
    "description": [
      "Despite its small size, Rhode Island has a vibrant college sports scene, with basketball and sailing being notably popular. The state has teams that play with heart and soul, creating exciting moments.",
      "Teams like the Rhode Island Rams and the Providence Friars have a loyal fan base and deliver captivating games. The Dunkin' Donuts Center in Providence becomes a bustling arena during basketball games, with locals turning out in droves to support their teams.",
      "In addition to basketball, Rhode Island is known for its outstanding sailing programs, with the beautiful coastline providing perfect conditions for the sport. Universities like Brown and URI have strong sailing teams that compete nationally.",
      "These sports contribute to Rhode Island's vibrant culture, where every game is an opportunity to celebrate the unique spirit of this small but proud state."
    ]
  },

  {
    "name": "South Carolina",
    "description": [
      "College sports in South Carolina breathe tradition and passion, with American football and basketball at the center of attention. The intense rivalry between the University of South Carolina Gamecocks and the Clemson University Tigers dominates the sports landscape.",
      "During the annual football showdown, 'The Palmetto Bowl,' the entire state comes alive, with the line between garnet and orange clearly drawn. The energy and passion of the fans are unparalleled, making every game an unforgettable experience.",
      "Basketball also holds a prominent place in the hearts of sports fans in South Carolina, with teams competing at the top in both men's and women's leagues. For example, the Gamecocks' women's basketball team has achieved national success, further enhancing community pride and involvement.",
      "The sports culture in South Carolina goes beyond the playing fields; it's a way of life, a source of pride, and an indispensable part of the state's identity."
    ]
  },
  {
    "name": "South Dakota",
    "description": [
      "College sports in South Dakota offer a unique and passionate experience, with an emphasis on basketball, American football, and athletics. The South Dakota State University Jackrabbits and the University of South Dakota Coyotes are prominent players in the sports landscape.",
      "Basketball games, especially during the rival encounters between the Jackrabbits and the Coyotes, attract enthusiastic crowds and create an electrifying atmosphere. The state cheers for both men's and women's teams, with on-field performances a source of pride for local communities.",
      "American football also enjoys great popularity, with exciting games and strong fan engagement. Athletic programs in South Dakota excel, with athletes competing nationally and proudly representing the state.",
      "College sports in South Dakota are more than just games; they are an essential part of the community, strengthening bonds among residents and contributing to the state's vibrant culture."
    ]
  },
  {
    "name": "Tennessee",
    "description": [
      "In Tennessee, college sports play a crucial role in the community, with a rich tradition in American football, basketball, and athletics. The University of Tennessee Volunteers and the Vanderbilt University Commodores are leading teams in the state's sports landscape.",
      "The Volunteers, with their iconic orange color, draw huge crowds to Neyland Stadium, one of the largest American football stadiums in the country. The fans' passion is unmatched, and 'Rocky Top' resounds loudly during every game.",
      "Basketball is also a major attraction in Tennessee, with both men's and women's teams receiving national recognition. The state has a rich history of producing top athletes and has contributed to the growth of the sport at the national level.",
      "College sports in Tennessee are an essential part of the state's culture and identity, bringing people together, celebrating traditions, and inspiring the next generation of athletes."
    ]
  },
  {
    "name": "Texas",
    image: Texas,
    "imageDescription": "Traditional Rivalry: Texas Longhorns face off against Texas A&M",
    "description": [
      "College sports in Texas are synonymous with size, passion, and rich tradition, with American football taking the lead. Teams like the Texas Longhorns and the Texas A&M Aggies have massive followings, and their games are season highlights.",
      "The Darrell K Royal–Texas Memorial Stadium in Austin and Kyle Field in College Station are sports temples where every home game turns into a spectacular event. The rivalry between various university teams is intense and contributes to the state's unique sports culture.",
      "In addition to football, basketball and baseball are also popular, with strong programs competing nationally. Support for women's sports is steadily growing, with basketball and athletics standing out in particular.",
      "College sports in Texas are more than just a pastime; it's a way of life, a source of pride, and a binding force that brings communities together."
    ]
  },
  {
    "name": "Utah",
    "description": [
      "In Utah, college sports hold a significant place within the community, with basketball and American football being the most popular sports. The University of Utah Utes and the Brigham Young University Cougars engage in fierce competition in various disciplines.",
      "The annual football duel between the Utes and the Cougars, known as the 'Holy War,' is one of the most anticipated sports events in the state. It brings fans together for a celebration of sportsmanship, rivalry, and state glory.",
      "Basketball also has a rich tradition in Utah, with exciting seasons and memorable moments in both men's and women's leagues. The teams strive for excellence, and fan support is unwavering.",
      "The impact of college sports in Utah goes beyond the playing field. It promotes community spirit, school pride, and significantly contributes to the cultural identity of the state."
    ]
  },
  {
    "name": "Vermont",
    "description": [
      "In Vermont, where nature predominates, and communities are close-knit, college sports play a unique role in bringing people together. Winter sports such as skiing and snowboarding are prominently featured, given the mountainous terrain and cold climate.",
      "The University of Vermont Catamounts is the centerpiece of the college sports scene, with teams excelling in various sports, including ice hockey and basketball. The Catamounts have a loyal following, and the atmosphere at home games is intense.",
      "Basketball enjoys great popularity, and the Catamounts' games are a must-see for sports enthusiasts. The team has participated in the NCAA tournament multiple times, putting the state on the map in the college basketball world.",
      "College sports in Vermont are more than just a game; they are a way to bring communities together, develop talent, and celebrate the state's unique sports culture."
    ]
  },
  {
    "name": "Virginia",
    "description": [
      "In Virginia, college sports are an integral part of the local culture, with a strong focus on American football, basketball, and the emerging popularity of soccer. The Virginia Cavaliers and Virginia Tech Hokies are leading university teams that bring passion and rivalry to the field.",
      "American football draws crowds of fans to the stadiums, where the atmosphere on game days is electric. Investment in basketball programs has also paid off, with teams competing nationally and having a dedicated following.",
      "Soccer is gaining ground in Virginia, with both men's and women's teams developing into competitive forces. Universities are investing in facilities and coaching, while local academies produce young talent.",
      "This mix of traditional and emerging sports enriches the college sports scene in Virginia, creates a vibrant sports community, and provides athletes and fans with unforgettable experiences and a sense of pride and unity."
    ]
  },
  {
    "name": "Washington",
    "description": [
      "College sports in Washington thrive with a blend of tradition and enthusiasm, with American football, basketball, and rowing taking center stage. The University of Washington Huskies and the Washington State University Cougars are pioneers of the state's sports culture.",
      "Husky Stadium in Seattle and Martin Stadium in Pullman buzz with excitement during American football games, where fans from the entire area come together to support their teams. The 'Apple Cup,' the annual showdown between the Huskies and the Cougars, is a highlight of the rivalry.",
      "Basketball also plays a significant role, with both men's and women's teams competing at a high level. The Huskies have a strong legacy in this sport and attract passionate supporters.",
      "Rowing is another notable discipline, with the Huskies winning numerous national championships. College sports in Washington reflect the diversity and passion of the state, where sportsmanship, competition, and camaraderie are highly valued."
    ]
  },
  {
    "name": "West Virginia",
    "description": [
      "College sports are the beating heart of West Virginia, with a strong emphasis on American football and basketball. The West Virginia University Mountaineers are the proud representatives of the state, and their games bring the community together.",
      "Milan Puskar Stadium in Morgantown comes to life during the Mountaineers' home games, with fans wearing the iconic gold and blue colors and cheering loudly. The 'Backyard Brawl,' a historic rivalry with the University of Pittsburgh, adds extra excitement to the season.",
      "Basketball is another passion, with thrilling games keeping fans on the edge of their seats. The Mountaineers have both men's and women's teams competing at the national level.",
      "College sports in West Virginia are a source of pride and unity, reflecting the strong sense of community and traditions of the state."
    ]
  },

 {
    "name": "Wisconsin",
    "description": [
      "College sports are deeply rooted in the culture of West Wisconsin, with American football, basketball, and ice hockey as prominent disciplines. The University of Wisconsin Badgers are the driving force behind the sports culture in the state.",
      "Camp Randall Stadium in Madison buzzes with excitement during American football games, with fans gathering to sing 'Jump Around' and cheer for their team. The rivalry with teams like the University of Michigan Wolverines is legendary and leads to intense matches.",
      "Basketball also has a rich tradition, with the Badgers regularly competing for the top spots in NCAA tournaments. Ice hockey holds a special place in the state's heart, with exciting games lighting up the winter evenings.",
      "College sports in West Wisconsin go beyond the playing field; they are a source of pride, camaraderie, and strengthen the bonds between communities in the state."
    ]
  },
  {
    "name": "Wyoming",
    "description": [
      "College sports in Wyoming have a unique character, with a strong emphasis on outdoor sports such as skiing, snowboarding, and mountaineering. The University of Wyoming Cowboys and Cowgirls are the standard-bearers of this adventurous sports culture.",
      "War Memorial Stadium in Laramie comes to life during American football games, where fans gather to show their support for the Cowboys. The breathtaking mountains serve as a backdrop for these sporting events.",
      "The university also has a strong tradition in skiing and snowboarding, with athletes dominating national and international competitions. Wyoming's vast wilderness serves as a playground for adventurers and outdoor enthusiasts.",
      "College sports in Wyoming celebrate the beauty of nature and the adventurous spirit of the state, where sportsmanship, passion, and love for the outdoors come together."
    ]
  }



];

export default states;