/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState } from 'react'; // Step 1: Import useState


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";


// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Logos/NCAA.png";
import post3 from "assets/images/Logos/NJCAA.png";



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {

  const articleStructure = [
    { id: 'intro', title: 'NAIA: Ett Alternativt Tillvägagångssätt inom Collegesport i Amerika', isSubheader: false },
  
    { id: 'Hoofdstuk 1', title: 'Ursprunget och Utvecklingen av NAIA', isSubheader: true },
    { id: '1.1', title: 'NAIA och dess Unika Divisioner', isSubheader: true },
    { id: '1.2', title: 'Den Akademiska Uppdraget', isSubheader: true },
  
    { id: 'Hoofdstuk 2', title: 'Finanser, Media och Synlighet', isSubheader: true },
    { id: '2.1', title: 'Utmaningar och Vägen Framåt', isSubheader: true },
    { id: '2.2', title: 'Avslutningsvis', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
     <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                       Innehåll
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}> Dölj</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Visa</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="intro" mb={3}>
    <MKTypography variant="h1" className="h1-as-h3" mb={2}>NAIA: Ett Alternativt Tillvägagångssätt inom Collegesport i Amerika</MKTypography>
</MKBox>
<MKBox id="Hoofdstuk 1" mb={3}>
    <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
        <br />
        Ursprunget och Utvecklingen av NAIA
    </MKTypography>
</MKBox>
<MKTypography variant="body1" fontWeight="light" color="text"> 
    National Association of Intercollegiate Athletics, eller NAIA, är en avgörande enhet inom det amerikanska collegesporthandboken, även om den är mindre känd än NCAA. Grundad 1937, har NAIA utvecklats till en välrenommerad organisation som ger studentatleter en unik möjlighet att förverkliga sina sportsliga och akademiska ambitioner.
    <br />
    <br />
    Från början har NAIA fokuserat på att erbjuda möjligheter till mindre utbildningsinstitutioner med tonvikt på att skapa en balanserad upplevelse för studentatleter. Organisationen främjar både konkurrenskraftiga sporter och akademisk excellens, med övertygelsen att de två kan gå hand i hand.
</MKTypography>
<MKBox id="1.1" mb={3}>
    <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
        <br />
        NAIA och dess Unika Divisioner
    </MKTypography>
</MKBox>
<MKTypography variant="body1" fontWeight="light" color="text"> 
    Till skillnad från NCAA är NAIA mer kompakt organiserad, med en uppdelning i två divisioner som båda erbjuder en rik mix av sport och utbildning:
    <br /><br />
    <MKTypography component="span" variant="body1" fontWeight="bold">
        Division I:{' '}
    </MKTypography>
    I denna division finns skolor som erbjuder en högre nivå av idrottsstipendier och där sportskonkurrensen är mycket konkurrenskraftig. Trots de mindre skolornas omfattning jämfört med NCAA Division I-institutioner, ger NAIA Division I en plattform för toppatleter att visa sina färdigheter och utvecklas.
    <br />
    <br />
    <MKTypography component="span" variant="body1" fontWeight="bold">
        Division II:{' '}
    </MKTypography>
    Denna division lägger ännu mer tonvikt på balansen mellan sport, akademiker och campusengagemang. Idrottsstipendierna är mer begränsade, men möjligheterna för studentatleter att excellera både inom sin idrott och i sina studier är omfattande.
</MKTypography>
<MKBox id="1.2" mb={3}>
    <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
        <br />
        Den Akademiska Uppdraget
    </MKTypography>
</MKBox>
<MKTypography variant="body1" fontWeight="light" color="text"> 
    NAIA lägger stor vikt vid de akademiska prestationerna hos sina studentatleter. Strikta akademiska normer har fastställts för att säkerställa deltagande i idrott, och de anslutna skolorna är dedikerade till att erbjuda nödvändigt akademiskt stöd. Detta resulterar i en miljö där studentatleter uppmuntras att sträva efter utmärkelse inom alla aspekter av sina liv.
    <br />
</MKTypography>
                                   





                                  






<MKBox id="Hoofdstuk 2" mb={3}>
    <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
        <br />
        Finanser, Media och Synlighet
    </MKTypography>
</MKBox>
<MKTypography variant="body1" fontWeight="light" color="text"> 
    Även om NAIA ekonomiskt sett kanske inte kan konkurrera med NCAA:s enorma intäkter, spelar den fortfarande en viktig roll för att finansiera idrottsstipendier och förbättra faciliteter. Dessutom ökar NAIA i synlighet tack vare streamingtjänster och sociala medier, vilket hjälper till att sprida budskapet om balanserad idrott och akademik.
    <br />
</MKTypography>
<MKBox id="2.1" mb={3}>
    <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
        <br />
        Utmaningar och Vägen Framåt
    </MKTypography>
</MKBox>
<MKTypography variant="body1" fontWeight="light" color="text"> 
    Precis som NCAA står NAIA inför utmaningar, inklusive att navigera genom komplexiteten av studentatleternas amatörstatus och att säkerställa jämlikhet inom idrotten. Men med sin unika position och engagemang för studentatleternas utveckling är NAIA väl positionerad att fortsätta göra en positiv skillnad i många unga atleters liv.
</MKTypography>
<MKBox id="2.2" mb={3}>
    <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
        <br />
        Avslutningsvis
    </MKTypography>
</MKBox>
<MKTypography variant="body1" fontWeight="light" color="text"> 
    National Association of Intercollegiate Athletics erbjuder ett värdefullt och unikt alternativ i den amerikanska collegesporthandboken. Med sitt fokus på balans, akademisk excellens och idrottstävling förblir NAIA en avgörande kraft i formandet av framtiden för studentatleter. Medan den ser framåt och fortsätter att utvecklas, förblir dess engagemang för att utveckla unga människor oföränderligt, vilket ger en bestående och positiv inverkan på den amerikanska idrotts- och utbildningsvärlden.
    <br />
</MKTypography>


              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="NCAA"
                    description="NCAA: Hjärtat av College-sport i Amerika"
                    action={{
                      type: "internal",
                      route: "/sv/NCAA",
                      color: "info",
                      label: "Läs Mer",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="NJCAA"
                    description="NJCAA: Stegen till Stora Universitet och NCAA"
                    action={{
                      type: "internal",
                      route: "/sv/NJCAA",
                      color: "info",
                      label: "Läs Mer",
                    }}
                  />
                </Grid>
              </Grid>

            </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;