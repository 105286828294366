/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Page du produit : https://www.creative-tim.com/product/material-kit-pro-react
* Droits d'auteur 2023 Creative Tim (https://www.creative-tim.com)

Codé par www.creative-tim.com

 =========================================================

* La présente mention de droits d'auteur et cet avis d'autorisation doivent être inclus dans toutes les copies ou portions substantielles du logiciel.
*/

// @mui composants matériels

import React from "react";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Composants Material Kit 2 PRO React
import MKBox from "components/MKBox";

// Exemples Material Kit 2 PRO React
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

function StatsOne() {
  return (
    <MKBox component="section" py={8}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard 
            count={100} 
            suffix="+"
            title="Étudiants:"
            description="Guidés avec succès vers leurs universités de rêve"  
            onClick={() => console.log('Clic sur 100')}
            />
          </Grid>
          <Grid item xs={12} md={4} >
            <DefaultCounterCard
              count={70}
              title="Universités:"
              suffix="+"
              description="Étudiants placés avec succès à travers l'Amérique"
              onClick={() => console.log('Clic sur 70')}

            />
          </Grid>
          <Grid item xs={12} md={4}>
          <Link to="/fr/Tous-les-sports">
              <DefaultCounterCard
                count={10}
                suffix="+"
                title="Sports:"
                description="Guidant les talents dans diverses disciplines"
              />
            </Link>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default StatsOne;
