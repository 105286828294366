

/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================
* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post2 from "assets/images/TijdUS.png";
import "../../../../H1asH3.css"

function Profile() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center" />
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} justifyContent="space-between" alignItems="center" mb={1}>
                <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Part 1: Growing Up in the Netherlands</MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text">
              <br />
  I den förtrollande omgivningen i Nijmegen började mitt livslånga kärleksaffär med fotboll. Som ett barn från gatorna i Nijmegen kände jag magin i sporten med varje steg jag tog på den gröna gräsmattan. Mitt första klub var BVC '12, där jag som ung pojke upplevde mina första dribblingar och mål. Det var en tid av oskuld, där jag upptäckte min passion för sporten och där grunden för min framtid lades.
  <br /><br />

  Efter mitt äventyr på BVC '12 begav jag mig ut på en resa genom lokala fotbollsklubbar som Quick, NEC och Union. Den här resan bildade grunden för min fotbollsutbildning och närmade mig mitt dröm: att nå toppen av holländsk ungdomsfotboll. Som ung fotbollsspelare drömde jag om att spela på stora arenor, omgiven av en hav av jublande fans. Det var en dröm som delades av många unga talanger i Nederländerna, och jag var fast besluten att göra allt för att förverkliga den drömmen.
  <br /><br />

  Men när jag utvecklade mitt fotbollstalang insåg jag också hur viktig en god utbildning är för en stabil framtid. I Nederländerna verkade mina ambitioner inom fotboll och utbildning ibland motstridiga. Det var en utmaning att balansera båda passionerna.
  <br /><br />

  Amerikas gnistrande mångfald och enorma möjligheter hade alltid fascinerat mig, och den här fascinationen växte bara starkare under en semester med min familj. Jag var nästan säker på att jag skulle återvända till det här landet, kanske för en längre tid. Senare, genom bekanta, hörde jag historier om möjligheterna att kombinera en fotbollskarriär med universitetsstudier i Amerika, och min önskan att följa samma väg ökade exponentiellt.
  <br /><br />

  Men det var inte enkelt att organisera allt. Den komplexa processen med idrottsstipendier och studiemöjligheter i Amerika verkade inledningsvis skrämmande. På något sätt visste jag att det kunde vara enklare och mer tillgängligt, vilket till sist blev min motivation att starta det här företaget år senare.
  <br /><br />

  Efter att ha kontaktat olika tränare hade tränaren vid University of Tulsa en särskilt övertygande historia att dela med sig av. Han talade om ett lag som alltid tävlade om priser, konsekvent rankades bland de 25 bästa i landet och deltog i stora tävlingar. Hans ord gav mig förtroende för att detta var platsen där jag kunde förverkliga både mina drömmar inom fotboll och akademiska ambitioner.
  <br /><br />

  Och så hände det, två veckor efter att jag fyllt 18 år var mitt kapitel i Nederländerna (tillfälligt) över. Jag gick ombord på flyget till Amerika, redo för det äventyr som väntade. Besluten att förena mina passioner för fotboll och utbildning stod jag i början av en spännande resa. Mer om mina upplevelser i Amerika delar jag gärna med dig i del 2 av min historia, där jag fördjupar mig i mina äventyr i Förenta staterna.
</MKTypography>
<hr style={{ margin: '20px 0'}} />

<MKTypography component="a" href="#" variant="body1" fontWeight="light" color="info" mt={3}>
  <Grid container justifyContent="flex-end"> {/* This line is updated */}
    <Grid item xs={12} lg={6} mb={{ xs: 3, lg: 0 }}>
      <TransparentBlogCard
        image={post2}
        title="Part 2: My Time in America"
        action={{
          type: "internal",
          route: "/sv/Om-mig/Min-tid-i-Amerika",
          color: "info",
          label: "read more",
        }}
      />
    </Grid>
  </Grid>
</MKTypography>

</Grid>
</Grid>
</Grid>
</Container>
</MKBox>
);
}

export default Profile;
