/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Softbal1.jpeg";
import Image2 from "assets/images/Sports_Images/Softbal2.jpg";
import Image3 from "assets/images/Sports_Images/Softbal3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Softball-legater i USA: Sådan Studerer og Spiller Du Softball i Amerika', isSubheader: false },
  
    { id: 'Kapitel 1', title: 'Kapitel 1: Historie og Udvikling af Softball i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Tidlige År og Skolens og Universitetets Rolle', isSubheader: true },
    { id: '1.2', title: '1.2 Vækst og Professionalisering', isSubheader: true },
  
    { id: 'Kapitel 2', title: 'Kapitel 2: Universitetssoftball i dag', isSubheader: false },
    { id: '2.1', title: '2.1 Vigtige Ligaer og Konferencer', isSubheader: true },
    { id: '2.2', title: '2.2 Topprogrammer og Prestigefyldte Hold', isSubheader: true },
  
    { id: 'Kapitel 3', title: 'Kapitel 3: Fra College til Professionel', isSubheader: false },
    { id: '3.1', title: '3.1 Vejen til Professionalisme', isSubheader: true },
    { id: '3.2', title: '3.2 Professionel Softball og Andre Karrieremuligheder', isSubheader: true },
  
    { id: 'Kapitel 4', title: 'Kapitel 4: Internationale Indflydelser og Begivenheder', isSubheader: false },
    { id: '4.1', title: '4.1 Udenlandske Indflydelser', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanere på Verdensscenen', isSubheader: true },
  
    { id: 'Kapitel 5', title: 'Kapitel 5: Sportslegater og fremtiden for atleter', isSubheader: false },
    { id: '5.1', title: '5.1 Vigtigheden af legater', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Legater Amerika Kan Hjælpe', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };
  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>


























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Softball-legater i USA: Sådan Studerer og Spiller Du Softball i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Luftbillede af et pakket stadion under en college-softball-kamp."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Et fyldt stadion under en kamp
      </figcaption>
    </figure>
    Softball er en sport, der opstod i USA i slutningen af det 19. århundrede. Det er en variation af baseball, men med en større bold, et mindre felt og kortere innings. Softball spilles af to hold på ni spillere, der skiftes til at slå og forsvare. Målet er at score flere løb end det modsatte hold ved at løbe rundt om de fire baser efter at have ramt bolden.
    <br />
    <br />
    Softball vandt hurtigt popularitet i USA, især blandt skoler og universiteter. Det blev betragtet som en sport, der fremmer fysisk kondition, mental skarphed og social interaktion. Det tiltrak også meget talent og konkurrence, især på collegeniveau. Collegiate softball repræsenterer det højeste niveau af softball i USA, hvor studerende-atleter konkurrerer for deres universitet eller college. Collegiate softball tilbyder prestige, anerkendelse og legater til topudøvere.
    <br />
    <br />
    Sportbeursamerika.nl er en organisation dedikeret til at hjælpe unge atleter med at sikre softball-legater. Sportbeursamerika.nl har et team af eksperter, der kan guide dig i at finde det bedste universitet eller college baseret på din akademiske og atletiske profil. De kan også hjælpe dig med at forberede din ansøgning, oprette din video, komme i kontakt med trænere og arrangere dit visum. Sportbeursamerika.nl har allerede hjulpet mange succesfulde historier med at opnå deres drøm om at studere og spille softball i Amerika.
    <br />
    <br />
    Hvis du vil lære mere om softball i Amerika og hvordan du får et softball-legat, så læs videre!
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>


<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Historie og Udvikling af Softball i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Softball er en sport, der opstod i USA, men har spredt sig globalt. Det er en sport, der har gennemgået mange ændringer og innovationer, med talrige indflydelsesrige personer og begivenheder. I dette kapitel vil vi give dig et overblik over historien og udviklingen af softball i Amerika, fra dens tidlige år til i dag.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidlige År og Skolens og Universitetets Rolle
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Softball blev først spillet i 1887 i Chicago som en indendørs variant af baseball. Det blev opfundet af en gruppe mænd, der fejrede Thanksgiving i en gymnastiksal. De brugte en boksehandske som bold og en kosteskaft som bat. Spillet vandt hurtigt popularitet blandt forskellige grupper af mennesker, herunder brandmænd, fabriksarbejdere og kirkegængere.
      <br />
      <br />
      I de tidlige år af softball var der ingen standardiserede regler eller dimensioner. Spillet blev tilpasset den tilgængelige plads og antallet af spillere. Der var forskellige varianter af softball, såsom mushball, kittenball, diamond ball og indoor baseball. Udtrykket "softball" blev officielt brugt i 1926.
      <br />
      <br />
      Skoler og universiteter spillede en betydelig rolle i udviklingen af softball. De så softball som en sport egnet til både drenge og piger, der fremmer fysisk uddannelse og rekreation. De organiserede også de første skole- og universitetshold og de første organiserede konkurrencer. En af de første skolekonkurrencer var Public Schools Athletic League i New York City, som startede softball for piger i 1913. En af de første universitetskonkurrencer var National Collegiate Athletic Association (NCAA), som begyndte kvindernes softball i 1957.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Vækst og Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Softball blev en populær og prestigefyldt sport i Amerika, især blandt kvinder. Dette skyldtes delvist indførelsen af Title IX i 1972, en lov der garanterede lige muligheder for kvinder inden for uddannelse, herunder sportslegater. Dette gav flere kvindelige studerende-atleter mulighed for at spille softball på collegeniveau.
      <br />
      <br />
      Ud over collegial softball opstod der professionelle softballigaer i Amerika. Den første professionelle kvindernes softballiga var International Women's Professional Softball Association (IWPSA), grundlagt i 1976. Denne liga bestod af ti hold fra forskellige byer, såsom New York, Chicago og Los Angeles. Ligaen varede i fire sæsoner, indtil 1980.
      <br />
      <br />
      Den anden professionelle kvindernes softballiga var Women’s Pro Softball League (WPSL), etableret i 1997. Denne liga havde seks hold fra forskellige stater, herunder Florida, Georgia og Virginia. Ligaen ændrede senere sit navn til National Pro Fastpitch (NPF). NPF var aktiv indtil 2021 og gennemgik to aflyste sæsoner på grund af COVID-19. En ny softballiga, Women's Professional Fastpitch (WPF), blev lanceret i 2022.
      <br />
      <br />
      De professionelle softballigaer havde en betydelig indflydelse på collegial softball. Mange professionelle spillere var tidligere studerende-atleter, der fortsatte deres karriere efter eksamen. Nogle professionelle spillere blev også trænere eller instruktører for collegelhold eller individuelle spillere. Derudover skilte mange professionelle spillere sig ud som rollemodeller og inspirationskilder for unge softballspillere.
      <br />
      <br />
      Nogle eksempler på nøglefigurer inden for softball-verdenen er:

    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Jennie Finch: En tidligere pitcher for Arizona Wildcats og USA's nationale hold. Hun vandt to olympiske medaljer, guld i 2004 og sølv i 2008. Hun spillede også for Chicago Bandits i NPF. Hun betragtes som en af de største pitchere gennem alle tider og en ikon for sporten.
        </li>
        <li>
          Cat Osterman: En tidligere pitcher for Texas Longhorns og USA's nationale hold. Hun vandt også to olympiske medaljer, guld i 2004 og sølv i 2008. Hun spillede for Rockford Thunder og Houston Scrap Yard Dawgs i NPF. Senere blev hun træner for Texas State Bobcats.
        </li>
        <li>
          Monica Abbott: En tidligere pitcher for Tennessee Volunteers og USA's nationale hold. Hun vandt en sølvolympisk medalje i 2008. Senere spillede hun for Scrap Yard Fast Pitch i NPF og Toyota Red Terriers i Japan. Hun er den første kvindelige softballspiller til at underskrive en millionkontrakt.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>

                                              





                                  




<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Universitetssoftball i dag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Softball er en af de mest populære og konkurrenceprægede sportsgrene på universitetsniveau i Amerika. Hvert år deltager mere end tusind hold i universitetssæsonen for softball, som løber fra februar til juni. Højdepunktet på sæsonen er Women's College World Series (WCWS), mesterskabet for universitetssoftball.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Vigtige Ligaer og Konferencer
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Spillere fra Tennessee og Alabama i aktion under College World Series."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
         }}>
          Spillere i aktion under College World Series
        </figcaption>
      </figure>
      Universitetssoftball reguleres af National Collegiate Athletic Association (NCAA), en organisation ansvarlig for at organisere og administrere sportsprogrammer for universiteter og colleges i Amerika. NCAA opdeler universiteterne i tre divisioner baseret på deres størrelse, budget og sportslige præstationer. Disse divisioner er:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Division I: Den højeste og mest prestigefyldte division, med mere end 300 universiteter og over 10.000 studerende-atleter. Division I tilbyder flest legater, de bedste faciliteter og den stærkeste konkurrence. Division I består af 32 konferencer, regionale grupper af universiteter, der spiller kampe mod hinanden. Nogle eksempler på konferencer er Southeastern Conference (SEC), Pac-12 Conference og Big Ten Conference.
        </li>
        <li>
          Division II: Den midterste division, med mere end 300 universiteter og over 8.000 studerende-atleter. Division II tilbyder færre legater, men giver stadig gode faciliteter og stærk konkurrence. Division II består af 23 konferencer, såsom Great Lakes Valley Conference (GLVC), Sunshine State Conference og California Collegiate Athletic Association (CCAA).
        </li>
        <li>
          Division III: Den laveste division, med mere end 400 universiteter og over 6.000 studerende-atleter. Division III tilbyder ikke legater, men giver stærke akademiske programmer og en sportslig oplevelse. Division III består af 43 konferencer, herunder New England Small College Athletic Conference (NESCAC), Centennial Conference og University Athletic Association (UAA).
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Hver division har sit eget mesterskab i slutningen af sæsonen, hvor de bedste hold fra hver konference kvalificerer sig til en knockout-stil-turnering. Mesterskabet i Division I kaldes Women's College World Series (WCWS), afholdt i Oklahoma City. Mesterskabet i Division II kaldes NCAA Division II Softball Championship, afholdt i Denver. Mesterskabet i Division III kaldes NCAA Division III Softball Championship, afholdt i Salem.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Topprogrammer og Prestigefyldte Hold
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Universitetssoftball har produceret mange tophemmelige programmer og prestigefyldte hold, der har skilt sig ud gennem deres succesrige historie, talentfulde spillere og indflydelsesrige trænere. Nogle af disse programmer og hold inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          UCLA Bruins: Dette program anses for at være et af de mest succesrige i universitetssoftballs historie med et imponerende antal nationale titler på deres navn. Gennem årene har de produceret olympiske medaljetagere, herunder velkendte navne som Lisa Fernandez, Natasha Watley og Stacey Nuveman.
        </li>
        <li>
          Oklahoma Sooners: Oklahoma Sooners er et bemærkelsesværdigt stærkt hold og har konsekvent leveret toppræstationer. De har vundet flere nationale titler og har haft imponerende sejrsrækker i fortiden.
        </li>
        <li>
          Arizona Wildcats: Softballprogrammet for Arizona Wildcats praler med en imponerende liste over nationale titler. De har også en respekteret træner, Mike Candrea, med en fremragende track record af talrige sejre og endda olympisk guld som træner for det amerikanske nationale softballhold.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Disse programmer og hold bidrager til udviklingen af softballspillere ved at tilbyde dem et højt niveau af træning, coaching og konkurrence. De hjælper også spillere med at forbedre deres færdigheder, viden og mentalitet og forberede dem til næste skridt i deres karriere.
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  








<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Fra College til Professionel
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Softball er en sport, der tilbyder mange muligheder for studerende-atleter til at fortsætte deres karriere efter deres collegeperiode. Nogle studerende-atleter vælger at spille professionel softball, mens andre udforsker andre karrieremuligheder. I dette kapitel diskuterer vi vejen til professionalisme, professionelle softballigaer og andre karrieremuligheder for softballspillere.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Vejen til Professionalisme
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      At spille college softball er en god forberedelse til at spille professionel softball. Mange studerende-atleter, der klarer sig godt i college softball, bliver rekrutteret eller udvalgt af professionelle hold. Det betyder, at de tilbydes en kontrakt for at spille for et bestemt hold, normalt i bytte for en løn og andre fordele.
      <br />
      <br />
      Dog er det ikke let at spille professionel softball. Konkurrencen er meget hård, og spillere skal konstant tilpasse sig nye situationer, modstandere og udfordringer. Desuden er lønningerne for professionelle softballspillere ikke særlig høje sammenlignet med andre sportsgrene. Ifølge en rapport fra 2016 var den gennemsnitlige løn for en NPF-spiller cirka $5.000 til $6.000 pr. sæson. Det betyder, at mange professionelle spillere har brug for et sekundært job eller en anden indkomstkilde for at få økonomien til at løbe rundt.
      <br />
      <br />
      Derfor vælger nogle studerende-atleter ikke at spille professionel softball, men at fuldføre deres studier eller forfølge en anden karriere. Dette kan også være et godt valg, fordi college softball udvikler mange færdigheder og kvaliteter, der er nyttige på andre områder, såsom lederskab, teamwork, kommunikation, disciplin og problemløsning.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professionel Softball og Andre Karrieremuligheder
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Som nævnt tidligere er den vigtigste professionelle softballiga i Amerika National Pro Fastpitch (NPF). NPF består i øjeblikket af fem hold: Aussie Peppers of Minnesota, Canadian Wild of Southern Illinois, Chicago Bandits, Cleveland Comets og USSSA Pride. NPF har også et partnerskab med Chinese Softball Association (CSA), som sender to hold til NPF: Beijing Eagles og Shanghai Shengli.
      <br />
      <br />
      Dog er NPF ikke den eneste professionelle softballiga i verden. Der er også andre ligaer i lande som Japan, Australien, Italien og Mexico. Nogle amerikanske spillere vælger at spille i disse ligaer, fordi de er bedre betalte, eller fordi de ønsker at opleve en anden kultur.
      <br />
      <br />
      Udover at spille professionel softball er der andre karrieremuligheder for softballspillere. Nogle eksempler inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Coaching: Nogle spillere bliver trænere for skole-, college- eller professionelle hold. De bruger deres viden og erfaring til at træne, motivere og rådgive andre spillere.
        </li>
        <li>
          Uddannelse: Nogle spillere bliver lærere eller professorer på forskellige niveauer og i forskellige fag. De bruger deres akademiske baggrund og pædagogiske færdigheder til at undervise og vejlede studerende.
        </li>
        <li>
          Medier: Nogle spillere bliver journalister, kommentatorer eller analytikere for forskellige medieplatforme. De bruger deres kommunikationsfærdigheder og deres indsigt i sporten til at rapportere om softball-nyheder og begivenheder.
        </li>
        <li>
          Iværksætteri: Nogle spillere bliver iværksættere eller ledere inden for forskellige sektorer og brancher. De bruger deres kreative og forretningsmæssige færdigheder til at udvikle og sælge produkter eller ydelser.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Som du kan se, er der mange muligheder for softballspillere at bruge deres passion og talent i forskellige domæner. Det vigtigste er at sætte dine mål, udforske dine muligheder og forfølge dine drømme.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  








<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationale Indflydelser og Begivenheder
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Softball er ikke kun en amerikansk sport, men også en international en af slagsen. Softball spilles i mere end 140 lande over hele verden og har haft en betydelig indflydelse på sportskulturen og udviklingen i forskellige regioner. I dette kapitel vil vi diskutere udenlandske indflydelser på softball i Amerika og amerikanske indflydelser på softball på verdensplan.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Udenlandske Indflydelser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Oklahoma University softball team celebrates winning the NCAA title."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Oklahoma-spillere fejrer det nationale mesterskab
        </figcaption>
      </figure>
      Softball i Amerika skylder meget til de udenlandske indflydelser, der har beriget og udfordret sporten. Nogle af disse indflydelser inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Japan: Japan er en af de stærkeste lande inden for softball, både på professionelt og kollegialt niveau. Japan har en lang tradition for softball, der går tilbage til 1930'erne. Japan har også sin egen professionelle softballiga, Japan Softball League (JSL), der består af 12 hold. Japan har haft en betydelig indflydelse på amerikansk softball ved at introducere nye teknikker, taktikker og spillestile. Japan har også mange udvekslingsprogrammer og partnerskaber med amerikanske universiteter og hold, hvilket giver mange spillere mulighed for at lære af hinanden.
        </li>
        <li>
          Australien: Australien er et andet land, der spiller en betydelig rolle i softball, især på internationalt niveau. Australien har sit eget nationale softballhold, Aussie Spirit, der har vundet flere medaljer ved OL, verdensmesterskaberne og Softball World Cup. Australien har også mange spillere, der konkurrerer i NPF, såsom Stacey Porter, Kaia Parnaby og Taylah Tsitsikronis. Australien har haft en betydelig indflydelse på amerikansk softball ved at vise en høj grad af atletik, udholdenhed og teamwork.
        </li>
        <li>
          Canada: Canada, som et naboland til Amerika, har også en stærk softballkultur. Canada har sit eget nationale softballhold, Canadian Wild, der også deltager i NPF. Canada har også mange spillere, der har spillet eller stadig spiller for amerikanske universiteter, såsom Danielle Lawrie, Jenn Salling og Sara Groenewegen. Canada har haft en betydelig indflydelse på amerikansk softball ved at dele en fælles passion, vision og værdsættelse for sporten.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Disse udenlandske indflydelser har bidraget til udviklingen af softball i Amerika ved at skabe mere diversitet, konkurrence og samarbejde. De har også bidraget til udviklingen af kollegiale spillere ved at eksponere dem for forskellige kulturer, perspektiver og oplevelser.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikanere på Verdensscenen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Softball i Amerika har også haft en betydelig indflydelse på softball på verdensscenen. Amerika har været et førende land inden for softball i mange år, både professionelt og kollegialt. Amerika har produceret mange succeshistorier og inspirationskilder for andre lande og spillere.
      <br />
      <br />
      Disse succeshistorier har haft en betydelig indflydelse på softball på verdensscenen ved at vise en høj grad af præstation, excellence og lederskab. De har også opnået meget respekt og beundring fra andre lande og spillere, der ser dem som eksempler og udfordrere.
      <br />
      <br />
      Rollen af kollegial softball i forberedelsen til internationale konkurrencer er afgørende. Kollegial softball giver spillere en solid grundlag af færdigheder, viden og erfaring, som de kan bruge til at konkurrere med de bedste spillere i verden. Kollegial softball hjælper også med at opdage, udvikle og fremme talentet og potentialet hos spillere, så de kan udmærke sig på det højeste niveau.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Sportslegater og fremtiden for atleter
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sportslegater tilbyder talrige muligheder for unge atleter til at vise deres talent og udvikle deres færdigheder. En af de mest betydningsfulde muligheder er at opnå et sportslegat for at studere og konkurrere på kollegialt niveau. I dette kapitel vil vi diskutere vigtigheden af sportslegater og hvordan Sport Legater Amerika kan hjælpe dig med at realisere din drøm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vigtigheden af legater
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et sportslegat er økonomisk støtte, som et universitet tilbyder til en studerende-atlet for at kombinere studier og sport. Det kan dække omkostningerne til undervisning, bøger, bolig, måltider og andre udgifter. Derudover kan det også tilbyde fordele såsom akademisk vejledning, lægehjælp, træningsfaciliteter og udstyr.
      <br />
      <br />
      At opnå et sportslegat er konkurrencepræget, hvor mange faktorer spiller en rolle i tildelingsprocessen, herunder akademiske præstationer, atletisk præstation, personlighed, motivation, lederskab og tilpasningsevne. Derfor er det vigtigt at forberede sig godt og adskille sig fra konkurrencen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Legater Amerika Kan Hjælpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo af Sport Legater Amerika, der viser sportsudstyr og en eksamenshue, der repræsenterer fokus på sportslegater for studerende-atleter."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo af Sport Legater Amerika
        </figcaption>
      </figure>
      Sport Legater Amerika er en organisation specialiseret i at guide unge sports talenter til sportslegater. Med passionerede og erfarne eksperter støtter Sport Legater Amerika studerende-atleter på hvert trin af rejsen, fra indledende orientering til endelig placering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intake-interview for at diskutere den studerende-athlets ønsker, muligheder og forventninger.</li>
        <li>En professionel evaluering af den studerende-athlets atletiske og akademiske niveau.</li>
        <li>Et omfattende netværk af kontakter med trænere, universiteter og kollegier.</li>
        <li>Effektiv markedsføring af den studerende-athlets profil og videoer til potentielle trænere.</li>
        <li>Ekspertrådgivning om de bedste muligheder baseret på den studerende-athlets præferencer, mål og budget.</li>
        <li>Forhandling af de bedste legat tilbud på vegne af den studerende-atlet.</li>
        <li>Hjælp til ansøgning om nødvendige dokumenter, såsom visum, transkript, eksamensbevis og optagelsesprøver.</li>
        <li>Forberedelse til den studerende-atlets liv under deres legat, herunder kultur, sprog, regler og tips.</li>
        <li>Efterpleje og opfølgning under legatperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ønsker du at udforske legatmuligheder og opnå dine sportslige og akademiske mål? <Link to="/da/Tjenester-Produkter">Se det fulde udvalg</Link> af tjenester tilbudt af Sport Legater Amerika og planlæg en konsultation i dag.
    </MKTypography>
  </MKBox>
</MKBox>


          
          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;