/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Rowing1.jpg";
import Image2 from "assets/images/Sports_Images/Rowing2.jpg";
import Image3 from "assets/images/Sports_Images/Rowing3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images

import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Studying and Rowing in America: A Unique Opportunity for Young Rowers', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Chapter 1: The History and Development of Rowing in America', isSubheader: false },
    { id: '1.1', title: '1.1 The Early Years and the Role of Colleges', isSubheader: true },
    { id: '1.2', title: '1.2 Growth and Traditions', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Chapter 2: Rowing at the College Level Today', isSubheader: false },
    { id: '2.1', title: '2.1 Major Competitions and Conferences', isSubheader: true },
    { id: '2.2', title: '2.2 Leading Programs and Teams', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Chapter 3: From College to the Elite', isSubheader: false },
    { id: '3.1', title: '3.1 The Path to the Top', isSubheader: true },
    { id: '3.2', title: '3.2 Professional and International Opportunities', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Chapter 4: International Influences and Diversity', isSubheader: false },
    { id: '4.1', title: '4.1 International Athletes in College Rowing', isSubheader: true },
    { id: '4.2', title: '4.2 American Rowers Abroad', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Chapter 5: Sports Scholarships and the Future', isSubheader: false },
    { id: '5.1', title: '5.1 The Importance of Scholarships', isSubheader: true },
    { id: '5.2', title: '5.2 How Sport Scholarships America Can Help', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Content
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Hide</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Show</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>


























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Studying and Rowing in America: A Unique Opportunity for Young Rowers
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Rowers in action during the national college rowing championship."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Rowers in action during the national championship
      </figcaption>
    </figure>
    Rowing is one of the oldest and most prestigious sports in the United States. Since the 19th century, high-level rowing has been practiced at American universities, where rowers not only develop their physical and mental strength but also their academic and social skills. Studying and rowing in America is a unique opportunity for young rowers who want to pursue their athletic dreams while also receiving a valuable education and having an unforgettable experience. In this article, we'll tell you everything you need to know about rowing in America, from its history and traditions to the opportunities and challenges. We'll also explain how Sportbeursamerika.nl can help you secure a rowing scholarship and prepare for your adventure.
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 1: The History and Development of Rowing in America
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    In this chapter, we dive into the fascinating history of rowing in the United States. We explore how this sport spread across the ocean from England and became an integral part of American university culture. From the first rowing races on Lake Winnipesaukee to the iconic rivalries between prestigious universities, we examine the development of rowing as a sport that embodies both physical agility and academic pride.
  </MKTypography>
  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 The Early Years and the Role of Colleges
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Rowing as a sport originated in England in the 18th century when races were held between professional rowers on the Thames. These races attracted large audiences and wagers, quickly making rowing popular. In the 18th and 19th centuries, rowing spread to other countries, including the United States. The first American rowing race took place in 1843 on Lake Winnipesaukee in New Hampshire. In 1852, the first intercollegiate rowing race was held between Harvard and Yale on Lake Winnipesaukee. This was also the first intercollegiate sports event in the USA. Since then, rowing has become a significant part of American college sports.
      <br />
      <br />
      The role of colleges in promoting rowing was crucial. Colleges saw rowing as a way to instill discipline, teamwork, and leadership in their students while also enhancing their prestige and reputation. Colleges invested a significant amount of money and resources in building boathouses, acquiring boats, and attracting coaches. They also regularly organized competitions against other colleges, both regionally and nationally. These competitions were often well-attended by students, alumni, media, and sponsors, creating a great interest and involvement in the sport.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Growth and Traditions
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Rowing steadily grew in popularity and prestige in the second half of the 19th century and the first half of the 20th century. Various regional and national competitions emerged, such as the Eastern Sprints, the IRA National Championship Regatta, the Dad Vail Regatta, and the Head of the Charles Regatta. American rowers also participated in international events, including the Henley Royal Regatta in England and the Olympic Games. American rowers often achieved impressive results, winning gold at eight consecutive Olympic Games between 1920 and 1956.
      <br />
      <br />
      Rowing also developed a rich culture and tradition at American colleges. Each university had its own colors, mascot, songs, and rituals associated with rowing. Some traditions were playful or symbolic, such as throwing the coxswain into the water after a victory or wearing a special tie or pin. Other traditions were serious or ceremonial, such as singing the alma mater song or holding a memorial for deceased rowers. These traditions contributed to the sense of pride, camaraderie, and identity among rowers.
    </MKTypography>
  </MKBox>
</MKBox>
          





                                  








<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 2: Rowing at the College Level Today
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    In this chapter, we focus on contemporary rowing at the university level in America. We examine how the sport has evolved into a highly competitive and prestigious part of the collegiate sports landscape. Here, we discuss the major competitions and conferences, the structure of NCAA divisions, and take a look at some of the leading university rowing programs. This section highlights the current state of rowing at American universities and the unique opportunities it offers to young athletes.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Major Competitions and Conferences
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Stanford rowing team celebrates winning the national championship."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Stanford rowers celebrate the national championship
        </figcaption>
      </figure>
      Rowing at the college level remains a highly competitive and prestigious sport in the United States today. There are more than 300 universities that offer rowing programs, with approximately 150 participating in the NCAA (National Collegiate Athletic Association), the overarching organization for college sports. The NCAA divides universities into three divisions based on the number of students, budget, and the level of the sport. Division I is the highest division, featuring the most robust and competitive rowing programs. Divisions II and III have fewer and smaller rowing programs but are still highly competitive.
      <br />
      <br />
      Within each division, there are various conferences, which are groups of universities that are geographically or historically connected. Each conference organizes its own championship, where the top teams qualify for the national championships. The major conferences for rowing include the Big Ten, Pac-12, Ivy League, ACC, and Big 12. These conferences often have the most successful and influential rowing programs in the country.
      <br />
      <br />
      In addition to the conference championships, there are other significant national regattas in which universities can participate. The most prestigious one is the NCAA Women’s Rowing Championship, held since 1997 for female rowers in all three divisions. For male rowers, there is no equivalent NCAA championship, but there is the IRA National Championship Regatta, held since 1895 for both varsity and lightweight teams. Other well-known national regattas include the Dad Vail Regatta, the San Diego Crew Classic, the ACRA National Championship Regatta, and the CRASH-B Sprints.
    </MKTypography>
  </MKBox>

  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Leading Programs and Teams
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Rowing at the college level has a long history of rivalry, excellence, and innovation. Many universities have distinguished themselves through their rowing programs, both in terms of performance and development. Some of these universities include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          University of Washington: The Huskies are known for their powerful and respected rowing program. With multiple national championships in men's rowing and a history of producing Olympic medalists, including the legendary "Boys in the Boat" who won gold in 1936. The Huskies are celebrated for their rigorous training, strong teamwork, and influential coaches like Al Ulbrickson.
        </li>
        <li>
          Yale University: As one of the pioneers of intercollegiate rowing, Yale Bulldogs have an ancient and prestigious rowing program. Their history includes multiple national titles and a significant contribution to American rowing at the Olympic level. Yale is also known for its academic excellence and historic rivalry with Harvard.
        </li>
        <li>
          University of California, Berkeley: The Golden Bears have a successful and versatile rowing program distinguished by their national titles and Olympic participants. The program is known for its diversity, community involvement, and dedicated coaching.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>







                                  









<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 3: From College to the Elite
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    In this chapter, we explore the journey of college rowers to the elite ranks of the rowing world. We examine how the experiences and skills gained by rowers at the university level prepare them for higher levels of competition, including participation in national teams and the Olympic Games. This chapter highlights the transition from collegiate rowing to professional and international stages, focusing on the paths athletes follow, the challenges they face, and the success stories of those who reach the top. We also look at how university rowing programs identify and develop talents, paving the way for future Olympians and world champions.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 The Path to the Top
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Rowing at the college level is not only a sporting challenge but also a potential stepping stone to the elite echelons of the rowing world. Many rowers who have rowed at the college level transition to national teams and Olympic rowing. This is because college rowing provides excellent preparation in terms of technique, tactics, physical and mental conditioning, and teamwork. Additionally, college rowing offers the opportunity to stand out to scouts and coaches from national associations and selections.
      <br />
      <br />
      There are numerous examples of rowers who have gone from college to the elite level. A recent example of a rower who won an Olympic medal after her college career is Madison Mailey. Mailey, an All-American rower at Northeastern University, who graduated in 2018, won a gold medal at the Tokyo Olympics in the women's eight for Canada. She and her teammates took an early lead and held off a late challenge from New Zealand, winning by a margin of just 0.91 seconds.
    </MKTypography>
  </MKBox>

  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professional and International Opportunities
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      College rowing opens the door to professional and international opportunities for rowers. In addition to the potential for Olympic participation, university rowers can use their skills to compete in prestigious international regattas such as the Henley Royal Regatta or the World Rowing Cup. These events provide a platform for rowers to measure themselves against the world's best and gain experience in an international context.
      <br />
      <br />
      Furthermore, there is a path to professional rowing where college graduates can join professional teams and participate in global competitions. This offers a full-time rowing career with intensive training and the opportunity to compete at the highest level.
      <br />
      <br />
      For those interested in a coaching career, college rowing provides a solid foundation. Many successful rowing coaches are former collegiate athletes who use their experience and expertise to mentor new generations of rowers. This career path can lead to positions with national teams and rowing clubs around the world.
      <br />
      <br />
      In summary, the experiences gained in college rowing can lead to a range of professional and international opportunities, from athletes pursuing a professional career to those continuing the sport in a coaching role.
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  








<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 4: International Influences and Diversity
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    This chapter highlights the international dynamics and diversity within college rowing in the United States. We explore how international student-athletes enrich the sport with their unique backgrounds and perspectives, and how American rowers find opportunities to row abroad and further develop themselves. These interactions contribute to a richer cultural experience and broaden the horizons of both American and international rowers. We delve into the various paths international students take to row at the college level in the US, the benefits of these experiences, and the impact they have on the sport and its participants. Additionally, we focus on the opportunities for American rowers to gain international experience, including study exchange programs, international training camps, and coaching opportunities. This chapter emphasizes the importance of international influences in enriching collegiate rowing and promoting cultural exchange and understanding.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 International Athletes in College Rowing
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Rowing boats lined up for the start of a college rowing race."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Boats ready for the start of a race
        </figcaption>
      </figure>
      College rowing is not only an American affair but also an international one. Many international students come to the US to study and row at American universities. These students bring their own culture, background, and perspective, making valuable contributions to the diversity and quality of the teams. Moreover, they provide an opportunity for cultural exchange and friendship among rowers from different countries.
      <br />
      <br />
      There are several reasons why international students choose college rowing in the US. Some of these reasons include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Rowing Scholarships: Many American universities offer rowing scholarships to talented rowers, which can reduce or even eliminate their tuition costs. This is a significant incentive for international students who might not otherwise be able to study in the US.
        </li>
        <li>
          Academic Excellence: Many American universities are known for their high academic standards and reputation. This attracts international students seeking an excellent education and a globally recognized degree.
        </li>
        <li>
          Sporting Challenge: Many international students see college rowing as a sporting challenge and an opportunity to compete with the best rowers in the world. They aim to improve their skills, push their limits, and fulfill their potential.
        </li>
        <li>
          Personal Development: Many international students view college rowing as personal development and a chance to grow as individuals. They want to gain new experiences, meet new people, learn about new cultures, and broaden their horizons.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      "Niki van Sprang is an example of an international student who rowed at the college level in the United States. Van Sprang, originally from the Netherlands where he won multiple national titles, received a rowing scholarship from the University of California, Berkeley. There, he joined the varsity team and achieved significant success. After graduating in 2017, Van Sprang returned to the Netherlands and became a member of the national rowing team. He participated in the 2020 Tokyo Olympics, where he and Guillaume Krommenhoek finished 7th in the men's coxless pair. Additionally, he won silver at the 2022 World Championships in Račice and at the 2022 European Championships in Munich, as well as a bronze medal at the 2023 European Championships in Bled, all in the men's eight."
    </MKTypography>
  </MKBox>

  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 American Rowers Abroad
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      College rowing can also lead to international opportunities for American student-athletes who want to broaden or change their sporting horizons. There are various opportunities for American rowers to row abroad and gain experience. Some of these opportunities include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Study Exchange: Some American universities have exchange programs with foreign universities, allowing students to study and row in another country for a semester or a year. This gives them the opportunity to experience a different culture, language, and education system, as well as to row with other teams and coaches.
        </li>
        <li>
          Training Camps: Some American universities organize training camps abroad, usually during the winter or summer break. This gives them the chance to train in a different environment, climate, and water condition, as well as to compete against other teams or participate in local regattas.
        </li>
        <li>
          Coaching: Some American rowers decide to coach abroad after graduating, typically at schools, clubs, or national teams. This gives them the opportunity to share their knowledge and experience, as well as to learn from other coaches and cultures.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 5: Sports Scholarships and the Future of Athletes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sports scholarships offer numerous opportunities for young athletes to showcase their talent and develop their skills. One of the most significant opportunities is obtaining a sports scholarship to study and compete at the college level. In this chapter, we will discuss the importance of sports scholarships and how Sport Scholarships America can help you make your dream come true.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 The Importance of Scholarships
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A sports scholarship is financial support provided by a university to a student-athlete to combine studies and sports. It can cover the costs of tuition, books, housing, meals, and other expenses. Additionally, it may offer benefits such as academic tutoring, medical care, training facilities, and equipment.
      <br />
      <br />
      Obtaining a sports scholarship is competitive, with many factors playing a role in the awarding process, including academic achievements, athletic performance, personality, motivation, leadership, and adaptability. Therefore, it's essential to prepare well and distinguish yourself from the competition.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 How Sport Scholarships America Can Help
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo of Sport Scholarships America, featuring sports equipment and a graduation cap, representing the focus on sports scholarships for student-athletes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo of Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America is an organization specialized in guiding young sports talents to sports scholarships. With passionate and experienced experts, Sport Scholarships America supports student-athletes at every step of the journey, from initial orientation to final placement. Their services include:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>A personal intake interview to discuss the student-athlete's wishes, possibilities, and expectations.</li>
        <li>A professional evaluation of the student-athlete's athletic and academic level.</li>
        <li>An extensive network of contacts with coaches, universities, and colleges.</li>
        <li>Effective promotion of the student-athlete's profile and videos to potential coaches.</li>
        <li>Expert advice on the best options based on the student-athlete's preferences, goals, and budget.</li>
        <li>Negotiation of the best scholarship offers on behalf of the student-athlete.</li>
        <li>Assistance in applying for necessary documents, such as visa, transcript, diploma, and admission tests.</li>
        <li>Preparation for student-athletes' life during their scholarship, including culture, language, rules, and tips.</li>
        <li>Aftercare and follow-up during the scholarship period.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Want to explore scholarship opportunities and achieve your athletic and academic goals? <Link to="/Services-Products">View the full range</Link> of services offered by Sport Scholarships America and schedule a consultation today.
    </MKTypography>
  </MKBox>
</MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;