/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Softbal1.jpeg";
import Image2 from "assets/images/Sports_Images/Softbal2.jpg";
import Image3 from "assets/images/Sports_Images/Softbal3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Softballstipendier i USA: Hur man Studerar och Spelar Softboll i Amerika', isSubheader: false },
  
    { id: 'Kapitel 1', title: 'Kapitel 1: Historia och Utveckling av Softboll i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Tidiga År och Skolors och Universitets Roll', isSubheader: true },
    { id: '1.2', title: '1.2 Tillväxt och Professionalisering', isSubheader: true },
  
    { id: 'Kapitel 2', title: 'Kapitel 2: Universitetssoftboll Idag', isSubheader: false },
    { id: '2.1', title: '2.1 Viktiga Ligor och Konferenser', isSubheader: true },
    { id: '2.2', title: '2.2 Topp Program och Prestigefyllda Lag', isSubheader: true },
  
    { id: 'Kapitel 3', title: 'Kapitel 3: Från College till Proffsen', isSubheader: false },
    { id: '3.1', title: '3.1 Vägen till Professionalism', isSubheader: true },
    { id: '3.2', title: '3.2 Professionell Softboll och Andra Karriärmöjligheter', isSubheader: true },
  
    { id: 'Kapitel 4', title: 'Kapitel 4: Internationella Influenser och Händelser', isSubheader: false },
    { id: '4.1', title: '4.1 Utländska Influenser', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikaner på Världsarenan', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapitel 5: Idrottsstipendier och Framtiden för Idrottare', isSubheader: false },
    { id: '5.1', title: '5.1 Vikten av Stipendier', isSubheader: true },
    { id: '5.2', title: '5.2 Hur Sport Scholarships America Kan Hjälpa', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };
  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innehåll
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Dölj</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Visa</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>






















          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Softballstipendier i USA: Hur man Studerar och Spelar Softboll i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Flygbild över en fullsatt arena under en collegial softbollmatch."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        En fullsatt arena under en match
      </figcaption>
    </figure>
    Softboll är en sport som härstammar från USA i slutet av 1800-talet. Det är en variant av baseboll men med en större boll, en mindre plan och kortare innings. Softboll spelas av två lag med nio spelare, som turas om att slå och försvara. Målet är att göra fler poäng än motståndarlaget genom att springa runt de fyra baserna efter att ha slagit bollen.
    <br />
    <br />
    Softboll fick snabbt popularitet i USA, särskilt bland skolor och universitet. Det sågs som en sport som främjade fysisk kondition, mental skärpa och social interaktion. Det lockade också mycket talang och konkurrens, särskilt på collegial nivå. Collegial softboll representerar den högsta nivån av softboll i USA, där studentatleter tävlar för sitt universitet eller college. Collegial softboll erbjuder prestige, erkännande och stipendier för toppspelare.
    <br />
    <br />
    Sportbeursamerika.nl är en organisation dedikerad till att hjälpa unga idrottare att säkra softbollstipendier. Sportbeursamerika.nl har ett team av experter som kan guida dig till att hitta det bästa universitetet eller college baserat på din akademiska och idrottsliga profil. De kan också hjälpa dig att förbereda din ansökan, skapa din video, ansluta dig med tränare och ordna ditt visum. Sportbeursamerika.nl har redan hjälpt många framgångsrika historier att uppnå sin dröm om att studera och spela softboll i Amerika.
    <br />
    <br />
    Om du vill lära dig mer om softboll i Amerika och hur du får ett softbollstipendium, läs vidare!
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>

{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Kapitel 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Historia och Utveckling av Softboll i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Softboll är en sport som härstammar från USA men har spridit sig över hela världen. Det är en sport som har genomgått många förändringar och innovationer, med många inflytelserika personer och händelser. I detta kapitel kommer vi att ge dig en översikt över historien och utvecklingen av softboll i Amerika, från dess tidiga år till idag.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidiga År och Skolors och Universitets Roll
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Softboll spelades först 1887 i Chicago som en inomhusvariant av baseboll. Den skapades av en grupp män som firade Thanksgiving i en gymnastiksal. De använde en boxningshandske som boll och en kvast som klubba. Spelet fick snabbt popularitet bland olika grupper av människor, inklusive brandmän, fabriksarbetare och kyrkomedlemmar.
      <br />
      <br />
      Under de tidiga åren av softboll fanns det inga standardiserade regler eller dimensioner. Spelet anpassades till tillgängligt utrymme och antalet spelare. Det fanns olika varianter av softboll, såsom mushball, kittenball, diamond ball och indoor baseball. Termen "softboll" användes officiellt 1926.
      <br />
      <br />
      Skolor och universitet spelade en betydande roll i utvecklingen av softboll. De såg softboll som en sport lämplig för både pojkar och flickor och främjade fysisk utbildning och rekreation. De organiserade också de första skol- och universitetsteamen samt de första organiserade tävlingarna. En av de första skoltävlingarna var Public Schools Athletic League i New York City, som startade softboll för flickor 1913. En av de första universitetstävlingarna var National Collegiate Athletic Association (NCAA), som började med damernas softboll 1957.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Tillväxt och Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Softboll växte till en populär och prestigefylld sport i Amerika, särskilt bland kvinnor. Detta berodde delvis på införandet av Title IX 1972, en lag som garanterade lika möjligheter för kvinnor inom utbildning, inklusive idrottsstipendier. Detta gav fler kvinnliga studentatleter möjlighet att spela softboll på collegial nivå.
      <br />
      <br />
      Förutom collegial softboll uppkom professionella softbollsligor i Amerika. Den första professionella damernas softbollsligan var International Women's Professional Softball Association (IWPSA), grundad 1976. Denna liga bestod av tio lag från olika städer, såsom New York, Chicago och Los Angeles. Ligan varade i fyra säsonger, fram till 1980.
      <br />
      <br />
      Den andra professionella damernas softbollsligan var Women’s Pro Softball League (WPSL), etablerad 1997. Denna liga hade sex lag från olika stater, inklusive Florida, Georgia och Virginia. Ligan ändrade senare sitt namn till National Pro Fastpitch (NPF). NPF var aktiv fram till 2021 och genomgick två inställda säsonger på grund av COVID-19. En ny softbollsliga, Women's Professional Fastpitch (WPF), lanserades 2022.
      <br />
      <br />
      De professionella softbollsligorna hade en betydande inverkan på collegial softboll. Många professionella spelare var tidigare studentatleter som fortsatte sina karriärer efter examen. Vissa professionella spelare blev också tränare eller instruktörer för collegiala lag eller enskilda spelare. Dessutom utmärkte sig många professionella spelare som förebilder och inspirationskällor för unga softbollsspelare.
      <br />
      <br />
      Några exempel på nyckelfigurer inom softbollens värld är:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Jennie Finch: En före detta pitcher för Arizona Wildcats och det amerikanska landslaget. Hon vann två olympiska medaljer, guld 2004 och silver 2008. Hon spelade också för Chicago Bandits i NPF. Hon anses vara en av de bästa pitcherna genom tiderna och en ikon för sporten.
        </li>
        <li>
          Cat Osterman: En före detta pitcher för Texas Longhorns och det amerikanska landslaget. Hon vann också två olympiska medaljer, guld 2004 och silver 2008. Hon spelade för Rockford Thunder och Houston Scrap Yard Dawgs i NPF. Hon blev senare tränare för Texas State Bobcats.
        </li>
        <li>
          Monica Abbott: En före detta pitcher för Tennessee Volunteers och det amerikanska landslaget. Hon vann en olympisk silvermedalj 2008. Hon spelade senare för Scrap Yard Fast Pitch i NPF och Toyota Red Terriers i Japan. Hon är den första kvinnliga softbollsspelaren som skrivit under ett miljonkontrakt.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>
                                              





                                  






<MKBox id="Kapitel 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Universitetssoftboll Idag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Softboll är en av de mest populära och konkurrenskraftiga idrotterna på universitetsnivå i Amerika. Varje år deltar över tusen lag i universitetssoftbollssäsongen, som pågår från februari till juni. Höjdpunkten på säsongen är Women's College World Series (WCWS), den nationella mästerskapet för universitetssoftboll.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Viktiga Ligor och Konferenser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Spelare från Tennessee och Alabama i aktion under College World Series."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
         }}>
          Spelare i aktion under College World Series
        </figcaption>
      </figure>
      Universitetssoftboll regleras av National Collegiate Athletic Association (NCAA), en organisation som ansvarar för att organisera och hantera idrottsprogram för universitet och högskolor i Amerika. NCAA delar upp universiteten i tre divisioner baserat på deras storlek, budget och idrottsprestation. Dessa divisioner är:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Division I: Den högsta och mest prestigefyllda divisionen, med över 300 universitet och över 10 000 studentatleter. Division I erbjuder flest stipendier, bästa faciliteterna och starkast konkurrens. Division I består av 32 konferenser, regionala grupper av universitet som spelar matcher mot varandra. Några exempel på konferenser är Southeastern Conference (SEC), Pac-12 Conference och Big Ten Conference.
        </li>
        <li>
          Division II: Mellandivisionen, med över 300 universitet och över 8 000 studentatleter. Division II erbjuder färre stipendier men ger fortfarande bra faciliteter och stark konkurrens. Division II består av 23 konferenser, såsom Great Lakes Valley Conference (GLVC), Sunshine State Conference och California Collegiate Athletic Association (CCAA).
        </li>
        <li>
          Division III: Den lägsta divisionen, med över 400 universitet och över 6 000 studentatleter. Division III erbjuder inte stipendier men ger starka akademiska program och en sportig upplevelse. Division III består av 43 konferenser, inklusive New England Small College Athletic Conference (NESCAC), Centennial Conference och University Athletic Association (UAA).
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Varje division har sitt eget mästerskap i slutet av säsongen, där de bästa lagen från varje konferens kvalificerar sig för en turnering i knockout-stil. Mästerskapet för Division I kallas Women's College World Series (WCWS), som hålls i Oklahoma City. Mästerskapet för Division II kallas NCAA Division II Softball Championship, som hålls i Denver. Mästerskapet för Division III kallas NCAA Division III Softball Championship, som hålls i Salem.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Topp Program och Prestigefyllda Lag
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Universitetssoftbollen har producerat många toppprogram och prestigefyllda lag som har utmärkt sig genom sin framgångsrika historia, talangfulla spelare och inflytelserika tränare. Några av dessa program och lag inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          UCLA Bruins: Detta program anses vara ett av de mest framgångsrika i historien av universitetssoftboll, med ett imponerande antal nationella titlar på sitt namn. Genom åren har de producerat olympiska medaljörer, inklusive välkända namn som Lisa Fernandez, Natasha Watley och Stacey Nuveman.
        </li>
        <li>
          Oklahoma Sooners: Oklahoma Sooners är ett märkbart starkt lag och har konsekvent levererat topprestationer. De har vunnit flera nationella titlar och har haft imponerande vinstsviter tidigare.
        </li>
        <li>
          Arizona Wildcats: Softbollsprogrammet för Arizona Wildcats skryter med en imponerande lista över nationella titlar. De har också en respekterad tränare, Mike Candrea, med en utmärkt meritlista med talrika segrar och till och med olympiskt guld som tränare för det amerikanska nationallaget i softboll.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dessa program och lag bidrar till utvecklingen av softbollsspelare genom att erbjuda dem en hög nivå av träning, coaching och konkurrens. De hjälper också spelare att förbättra sina färdigheter, kunskaper och mentalitet och förbereder dem för nästa steg i deras karriärer.
    </MKTypography>
  </MKBox>
</MKBox>






                                  





<MKBox id="Kapitel 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Från College till Proffsen
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Softboll är en sport som erbjuder många möjligheter för studentatleter att fortsätta sina karriärer efter sin collegeperiod. Vissa studentatleter väljer att spela professionell softboll, medan andra utforskar andra karriärmöjligheter. I detta kapitel diskuterar vi vägen till professionalism, professionella softbollsligor och andra karriärmöjligheter för softbollsspelare.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Vägen till Professionalism
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Att spela college softboll är en bra förberedelse för att spela professionell softboll. Många studentatleter som utmärker sig i college softboll scoutas eller draftas av professionella lag. Detta innebär att de erbjuds ett kontrakt för att spela för ett specifikt lag, vanligtvis i utbyte mot en lön och andra förmåner.
      <br />
      <br />
      Att spela professionell softboll är dock inte lätt. Konkurrensen är mycket hög, och spelarna måste ständigt anpassa sig till nya situationer, motståndare och utmaningar. Dessutom är lönerna för professionella softbollsspelare inte särskilt höga jämfört med andra idrotter. Enligt en rapport från 2016 var den genomsnittliga lönen för en NPF-spelare ungefär $5 000 till $6 000 per säsong. Detta innebär att många professionella spelare behöver ha ett andra jobb eller en annan inkomstkälla för att få ekonomin att gå ihop.
      <br />
      <br />
      Därför väljer vissa studentatleter att inte spela professionell softboll utan att slutföra sina studier eller följa en annan karriär. Detta kan också vara ett bra val eftersom college softboll utvecklar många färdigheter och egenskaper som är användbara inom andra områden, såsom ledarskap, samarbete, kommunikation, disciplin och problemlösning.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professionell Softboll och Andra Karriärmöjligheter
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Som tidigare nämnts är den främsta professionella softbollsligan i Amerika National Pro Fastpitch (NPF). NPF består för närvarande av fem lag: Aussie Peppers of Minnesota, Canadian Wild of Southern Illinois, Chicago Bandits, Cleveland Comets och USSSA Pride. NPF har också ett partnerskap med Chinese Softball Association (CSA), som skickar två lag till NPF: Beijing Eagles och Shanghai Shengli.
      <br />
      <br />
      Men NPF är inte den enda professionella softbollsligan i världen. Det finns också andra ligor i länder som Japan, Australien, Italien och Mexiko. Vissa amerikanska spelare väljer att spela i dessa ligor eftersom de är bättre betalda eller för att de vill uppleva en annan kultur.
      <br />
      <br />
      Förutom att spela professionell softboll finns det andra karriärmöjligheter för softbollsspelare. Några exempel inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Tränarskap: En del spelare blir tränare för skol-, college- eller professionella lag. De använder sin kunskap och erfarenhet för att träna, motivera och ge råd åt andra spelare.
        </li>
        <li>
          Utbildning: En del spelare blir lärare eller professorer på olika nivåer och inom olika ämnen. De använder sin akademiska bakgrund och pedagogiska färdigheter för att utbilda och vägleda studenter.
        </li>
        <li>
          Media: En del spelare blir journalister, kommentatorer eller analytiker för olika mediekanaler. De använder sina kommunikationsfärdigheter och sin insikt i sporten för att rapportera om softbollsnyheter och händelser.
        </li>
        <li>
          Entreprenörskap: En del spelare blir entreprenörer eller chefer inom olika sektorer och branscher. De använder sina kreativa och affärsmässiga färdigheter för att utveckla och sälja produkter eller tjänster.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Som du kan se finns det många möjligheter för softbollsspelare att använda sin passion och talang inom olika områden. Nyckeln är att sätta dina mål, utforska dina alternativ och följa dina drömmar.
    </MKTypography>
  </MKBox>
</MKBox>






                                  






<MKBox id="Kapitel 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationella Influenser och Händelser
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Softboll är inte bara en amerikansk sport utan också en internationell sport. Softboll spelas i över 140 länder över hela världen och har haft en betydande inverkan på idrottskulturen och utvecklingen i olika regioner. I detta kapitel kommer vi att diskutera utländska influenser på softboll i Amerika och amerikanska influenser på softboll på den internationella scenen.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Utländska Influenser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Oklahoma University softball team celebrates winning the NCAA title."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Oklahoma players celebrate the national championship
        </figcaption>
      </figure>
      Softboll i Amerika är mycket skyldigt till de utländska influenser som har berikat och utmanat sporten. Några av dessa influenser inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Japan: Japan är ett av de starkaste länderna inom softboll, både på professionell och på collegenivå. Japan har en lång tradition av softboll som sträcker sig tillbaka till 1930-talet. Japan har också sin egen professionella softbollsliga, Japan Softball League (JSL), bestående av 12 lag. Japan har haft en betydande inverkan på amerikansk softboll genom att introducera nya tekniker, taktiker och spelstilar. Japan har också många utbytesprogram och partnerskap med amerikanska universitet och lag, vilket ger många spelare möjlighet att lära av varandra.
        </li>
        <li>
          Australien: Australien är ett annat land som spelar en betydande roll inom softboll, särskilt på internationell nivå. Australien har sitt eget nationella softbollslag, Aussie Spirit, som har vunnit flera medaljer vid OS, världsmästerskap och världscupen i softboll. Australien har också många spelare som tävlar i NPF, som Stacey Porter, Kaia Parnaby och Taylah Tsitsikronis. Australien har haft ett betydande inflytande på amerikansk softboll genom att visa upp en hög nivå av atletisk förmåga, uthållighet och lagarbete.
        </li>
        <li>
          Kanada: Kanada, som grannland till Amerika, har också en stark softbollskultur. Kanada har sitt eget nationella softbollslag, Canadian Wild, som också deltar i NPF. Kanada har också många spelare som har spelat eller för närvarande spelar för amerikanska universitet, som Danielle Lawrie, Jenn Salling och Sara Groenewegen. Kanada har haft en betydande inverkan på amerikansk softboll genom att dela en gemensam passion, vision och uppskattning för sporten.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dessa utländska influenser har bidragit till utvecklingen av softboll i Amerika genom att skapa mer mångfald, konkurrens och samarbete. De har också bidragit till utvecklingen av collegespelare genom att exponera dem för olika kulturer, perspektiv och erfarenheter.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikaner på Världsarenan
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Softboll i Amerika har också haft en betydande inverkan på softboll på världsarenan. Amerika har varit ett ledande land inom softboll i många år, både professionellt och på collegenivå. Amerika har producerat många framgångssagor och källor till inspiration för andra länder och spelare.
      <br />
      <br />
      Dessa framgångssagor har haft en betydande inverkan på softboll på världsarenan genom att visa upp en hög nivå av prestation, excellens och ledarskap. De har också fått mycket respekt och beundran från andra länder och spelare, som ser dem som exempel och utmanare.
      <br />
      <br />
      Rollen som collegespel i förberedelserna inför internationella tävlingar är avgörande. Collegespel ger spelare en solid grund av färdigheter, kunskap och erfarenhet som de kan använda för att tävla med de bästa spelarna i världen. Collegespel hjälper också till att upptäcka, utveckla och främja talang och potential hos spelare så att de kan utmärka sig på högsta nivå.
    </MKTypography>
  </MKBox>
</MKBox>






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Idrottsstipendier och Framtiden för Idrottare
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrottsstipendier erbjuder många möjligheter för unga idrottare att visa upp sin talang och utveckla sina färdigheter. En av de mest betydelsefulla möjligheterna är att få ett idrottsstipendium för att studera och tävla på collegenivå. I det här kapitlet kommer vi att diskutera vikten av idrottsstipendier och hur Sport Scholarships America kan hjälpa dig att förverkliga din dröm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vikten av Stipendier
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ett idrottsstipendium är ekonomiskt stöd som tillhandahålls av ett universitet till en idrottare för att kombinera studier och idrott. Det kan täcka kostnaderna för undervisning, böcker, boende, måltider och andra utgifter. Dessutom kan det erbjuda fördelar som akademisk handledning, sjukvård, träningsanläggningar och utrustning.
      <br />
      <br />
      Att få ett idrottsstipendium är konkurrenskraftigt, med många faktorer som spelar en roll i tilldelningsprocessen, inklusive akademiska prestationer, idrottsprestationer, personlighet, motivation, ledarskap och anpassningsförmåga. Därför är det viktigt att förbereda sig väl och skilja sig från konkurrensen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hur Sport Scholarships America Kan Hjälpa
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotyp för Sport Scholarships America, med sportutrustning och en studentmössa, som representerar fokus på idrottsstipendier för idrottare."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotyp för Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America är en organisation specialiserad på att vägleda unga idrottstalanger till idrottsstipendier. Med passionerade och erfarna experter stöder Sport Scholarships America idrottare i varje steg av resan, från initial orientering till slutlig placering. Deras tjänster inkluderar:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intagningsintervju för att diskutera idrottarens önskemål, möjligheter och förväntningar.</li>
        <li>En professionell utvärdering av idrottarens akademiska och idrottsliga nivå.</li>
        <li>Ett omfattande nätverk av kontakter med tränare, universitet och högskolor.</li>
        <li>Effektiv marknadsföring av idrottarens profil och videor till potentiella tränare.</li>
        <li>Expertrådgivning om de bästa alternativen baserat på idrottarens preferenser, mål och budget.</li>
        <li>Förhandling om de bästa stipendieerbjudandena för idrottaren.</li>
        <li>Hjälp med att ansöka om nödvändiga dokument, såsom visum, betyg, examensbevis och antagningsprov.</li>
        <li>Förberedelse för idrottarens liv under stipendieperioden, inklusive kultur, språk, regler och tips.</li>
        <li>Uppföljning och uppföljning under stipendieperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vill du utforska möjligheter till stipendier och uppnå dina idrottsliga och akademiska mål? <Link to="/sv/Tjänster-Produkter">Se det fullständiga utbudet</Link> av tjänster som erbjuds av Sport Scholarships America och boka en konsultation idag.
    </MKTypography>
  </MKBox>
</MKBox>

        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;