import Alabama from "assets/images/Staten_Images/Alabama.webp"
import Florida from "assets/images/Staten_Images/Florida.jpeg"
import Connecticut from "assets/images/Staten_Images/Connecticut.jpeg"
import Kentucky from "assets/images/Staten_Images/Kentucky.webp"
import Michigan from "assets/images/Staten_Images/Michigan.jpeg"
import North_Carolina from "assets/images/Staten_Images/North_Carolina.jpg"
import Texas from "assets/images/Staten_Images/Texas.jpeg"



const states = [
  {
    "name": "Alabama",
    "image": Alabama,
    "imageDescription": "Alabama contre Auburn est l'une des plus grandes rivalités du football universitaire.",
    "description": [
      "Les sports universitaires sont une partie intégrante de la culture en Alabama, avec un accent particulier sur le football américain. L'Université de l'Alabama (UA) à Tuscaloosa et l'Université d'Auburn à Auburn dominent le paysage sportif de l'État.",
      "L'équipe de football américain de l'UA, les Crimson Tide, a une histoire riche et a remporté de nombreux championnats nationaux. Les matchs à domicile au vaste Bryant-Denny Stadium peuvent attirer jusqu'à 100 000 fans. La passion des fans est énorme, et le chant 'Roll Tide' est connu dans toute l'Alabama.",
      "D'autre part, nous avons l'Université d'Auburn avec leur équipe de football américain, les Tigers. Ils, avec l'UA, constituent le Iron Bowl, l'une des rivalités les plus intenses dans les sports universitaires. Ce choc annuel met tout l'État sens dessus dessous et garantit l'excitation et le spectacle.",
      "Outre le football, l'Alabama dispose de solides programmes en basketball, baseball et autres sports, soutenus par un puissant esprit communautaire. En Alabama, les sports sont plus que de simples passe-temps ; ils font partie intégrante du mode de vie et de l'identité de l'État."
    ]
  },
  {
    "name": "Alaska",
    "description": [
      "Contrairement à de nombreux autres États américains, les sports universitaires jouent un rôle plus modeste en Alaska, principalement en raison de son emplacement éloigné et de son climat unique. Ici, l'accent est davantage mis sur les sports d'hiver et les activités de plein air que sur les sports américains traditionnels comme le football et le baseball.",
      "L'Université de l'Alaska à Anchorage (UAA) et l'Université de l'Alaska à Fairbanks (UAF) sont les principales institutions avec des programmes sportifs actifs. L'UAA a une forte équipe de ski et de patinage, tandis que l'UAF est connue pour son équipe de hockey sur glace, les Nanooks, qui ont un fidèle public local.",
      "En Alaska, les sports universitaires sont plus une question d'expérience et de communauté que d'événements à grande échelle. Les matchs offrent l'occasion à la communauté de se rassembler, d'encourager les athlètes et de célébrer la culture sportive unique de l'État.",
      "Malgré l'échelle plus petite, les sports universitaires en Alaska ont leur propre charme et offrent une communauté chaleureuse dans l'environnement froid de l'État."
    ]
  },
  {
    "name": "Arizona",
    "description": [
      "Les sports universitaires sont extrêmement populaires en Arizona, avec une riche tradition à la fois dans le football américain et le basketball. L'Université d'État de l'Arizona (ASU) à Tempe et l'Université de l'Arizona (UA) à Tucson sont les piliers du paysage sportif de l'État.",
      "L'équipe de football américain de l'ASU, les Sun Devils, bénéficie d'un suivi passionné, tandis que leur équipe de basketball brille régulièrement lors des tournois de la NCAA. UA, avec leurs Wildcats, est connue pour son programme de basketball réussi et sa rivalité intense avec ASU, culminant lors du match annuel de football 'Duel in the Desert'.",
      "En plus des sports majeurs, l'Arizona excelle également dans le baseball, le softball et le golf, avec des équipes universitaires souvent sous les projecteurs nationaux. Le soutien de la communauté est écrasant, et les événements sportifs sont considérés comme des occasions sociales importantes.",
      "Les sports universitaires en Arizona incarnent l'esprit de compétition et de communauté, avec une rivalité intense et une passion des fans contribuant à une culture sportive vibrante et animée."
    ]
  },
  {
    "name": "Arkansas",
    "description": [
      "En Arkansas, les sports universitaires jouent un rôle central dans la vie communautaire, avec un accent particulier sur le football américain et le basketball. L'Université de l'Arkansas à Fayetteville est une puissance de l'État, connue pour ses Razorbacks.",
      "L'équipe de football américain des Razorbacks jouit d'une reconnaissance régionale et nationale, avec une base de fans dévouée qui suit passionnément chaque match. Leurs matchs à domicile dans l'impressionnant Razorback Stadium sont un spectacle, où le chant 'Woo Pig Sooie' est repris par des dizaines de milliers de fans.",
      "Le basketball est également une partie importante de la culture sportive en Arkansas, avec les Razorbacks participant régulièrement au tournoi de la NCAA. L'excitation et l'énergie dans le Bud Walton Arena pendant la saison de basketball sont palpables.",
      "Au-delà des sports majeurs, les Razorbacks ont également de solides programmes en athlétisme, baseball et softball. Cette diversité dans les sports enrichit la culture sportive en Arkansas, où les sports universitaires sont une source de fierté, d'esprit communautaire et de divertissement."
    ]
  },
  
  {
    "name": "California",
    "description": [
      "Les sports universitaires en Californie sont diversifiés et hautement compétitifs, avec de nombreuses universités excellant dans une variété de sports. Du football américain à la natation, l'État a tout pour plaire.",
      "La conférence Pac-12, avec des écoles renommées comme l'Université de Californie à Los Angeles (UCLA) et l'Université de Californie du Sud (USC), est réputée pour son haut niveau de sports. L'équipe de basketball de UCLA a une riche histoire, tandis que USC excelle traditionnellement dans le football.",
      "En plus des équipes bien connues, il existe également des écoles plus petites avec d'impressionnants programmes sportifs, comme l'Université Stanford, connue pour son excellence en natation et en athlétisme. Cette diversité crée un environnement sportif dynamique et excitant dans l'État.",
      "Le climat agréable de la Californie et les vastes installations sportives encouragent la participation active et l'enthousiasme pour les sports universitaires. Que ce soit un match de football dans un stade bondé ou une compétition de natation locale, les sports font partie intégrante du mode de vie californien."
    ]
  },
  {
    "name": "Colorado",
    "description": [
      "Les sports universitaires au Colorado bénéficient d'un suivi vibrant et enthousiaste, en partie grâce aux impressionnants paysages naturels et au style de vie actif en plein air. L'État a une riche tradition en football américain, basketball et sports d'hiver.",
      "L'Université du Colorado à Boulder (CU) et l'Université d'État du Colorado (CSU) sont des acteurs importants de la scène des sports universitaires, avec le match annuel de football 'Rocky Mountain Showdown' comme point culminant de leur rivalité.",
      "L'équipe de football américain de CU, les Buffaloes, a une base de fans passionnée, tandis que leur équipe de ski excelle dans les compétitions nationales, bénéficiant des excellentes installations de sports d'hiver du Colorado. CSU est également compétitif dans divers sports, avec une forte présence en football américain et basketball.",
      "En plus des grandes universités, les collèges plus petits du Colorado ont également des programmes sportifs actifs, contribuant à la culture sportive riche et diversifiée de l'État. Les sports universitaires au Colorado représentent la communauté, la persévérance et la célébration du sport sous toutes ses formes."
    ]
  },
  {
    "name": "Connecticut",
    "image": Connecticut,
    "imageDescription": "L'équipe de basketball féminin de UConn est l'une des équipes de sports universitaires les plus dominantes de tous les temps.",
    "description": [
      "Les sports universitaires au Connecticut sont dominés par le basketball, avec un accent particulier sur les programmes réussis de l'Université du Connecticut (UConn). L'État a une riche tradition et une base de fans passionnée profondément investie dans les sports universitaires.",
      "L'équipe de basketball féminin de UConn est mondialement reconnue et a remporté plusieurs championnats de la NCAA, ce qui en fait une puissance dans le sport. L'équipe masculine a également réalisé des performances impressionnantes, faisant du basketball une partie centrale de la culture sportive au Connecticut.",
      "Bien que le basketball règne en maître, il existe également de solides programmes dans d'autres sports tels que le hockey sur glace, le football américain et le crosse, avec diverses universités compétitives au niveau national.",
      "Les sports universitaires au Connecticut incarnent la fierté et l'esprit communautaire de l'État, où les événements sportifs offrent une occasion pour les fans de se rassembler, d'encourager les équipes et de participer à une riche tradition sportive."
    ]
  },
  {
    "name": "Delaware",
    "description": [
      "Malgré sa petite taille, le Delaware possède une scène sportive universitaire dynamique avec une forte implication communautaire. L'Université du Delaware (UD) à Newark est au cœur de l'action, où les Blue Hens excellent dans divers sports.",
      "L'équipe de football de l'UD a une riche histoire et une base de fans dévouée, avec des matchs à domicile excitants au Delaware Stadium. Le basketball et le crosse sont également des sports populaires, avec des équipes se battant régulièrement pour la reconnaissance régionale et nationale.",
      "Malgré sa petite taille, le Delaware a de solides programmes en athlétisme, natation et autres sports, tous contribuant à la culture sportive dynamique de l'État. Les sports universitaires ici sont plus que de simples passe-temps ; ils sont une partie importante de l'identité communautaire, favorisant un lien solide entre l'université et la communauté élargie.",
      "Au Delaware, les sports universitaires rassemblent les gens, avec une échelle réduite créant une communauté sportive proche et passionnée."
    ]
  },
  
  


  
    {
      "name": "Florida",
      "image": Florida,
      "imageDescription": "Le Ben Hill Griffin Stadium est le domicile des Florida Gators",
      "description": [
        "La Floride est un haut lieu des sports universitaires, avec certains des programmes les plus compétitifs et passionnés du pays. Le football américain et le basketball dominent la scène sportive, mais il y a aussi de la place pour une variété d'autres sports.",
        "Des équipes comme les Florida Gators et les Florida State Seminoles sont reconnues au niveau national et ont une riche histoire à la fois en football et en basketball. Leur rivalité intense attire des milliers de fans chaque année, et les matchs sont des moments forts de la saison sportive.",
        "De plus, il existe également de plus petites universités et collèges avec de solides programmes sportifs qui contribuent à la riche culture sportive de l'État. Le baseball, l'athlétisme et la natation sont également populaires, et les excellentes installations et le climat ensoleillé font de la Floride un endroit idéal pour les athlètes universitaires.",
        "En Floride, les sports universitaires sont plus que de simples compétitions ; ils sont un mode de vie, une source de fierté et une partie essentielle de l'identité communautaire."
      ]
    },
    {
      "name": "Georgia",
      "description": [
        "Les sports universitaires en Géorgie sont caractérisés par une passion intense et des rivalités profondément enracinées, le football américain étant le roi incontesté. L'État abrite certains des programmes les plus prestigieux du pays, avec des fans vivant passionnément chaque match.",
        "Les Georgia Bulldogs de l'Université de Géorgie à Athens et les Yellow Jackets de Georgia Tech à Atlanta sont les principaux acteurs, avec leur rivalité annuelle 'Clean, Old-Fashioned Hate' qui captive l'État. Les matchs de football, surtout dans l'immense Sanford Stadium de l'UGA, attirent des dizaines de milliers de fans.",
        "En plus du football, la Géorgie excelle également dans le basketball, l'athlétisme et le baseball, avec des athlètes talentueux et des supporters dévoués. Les programmes sportifs des petites écoles et collèges contribuent à un paysage sportif riche et diversifié dans tout l'État.",
        "En Géorgie, le sport est plus qu'une compétition ; c'est un mode de vie, une source d'esprit communautaire et une partie indispensable de l'identité régionale."
      ]
    },
    {
      "name": "Hawaii",
      "description": [
        "Les sports universitaires à Hawaï offrent une expérience unique inspirée par la beauté des îles et la forte culture locale. Malgré son isolement géographique, les équipes sportives et les fans à Hawaï sont extraordinairement passionnés et engagés.",
        "L'Université d'Hawaï à Manoa joue un rôle central dans la scène des sports universitaires, avec son équipe de football, les Rainbow Warriors, comme point focal important. Leurs matchs à domicile au Aloha Stadium créent une atmosphère vibrante et festive, avec un fort esprit insulaire.",
        "En plus du football américain, le volleyball, le surf et la course de pirogues sont également populaires, reflétant la culture sportive unique et le paysage naturel d'Hawaï. Les Rainbow Warriors sont également compétitifs en basketball et en baseball, avec un dévouement partagé par toute la communauté.",
        "À Hawaï, les sports universitaires représentent l'esprit Aloha, avec des équipes et des fans qui embrassent la culture insulaire, la communauté et la fierté, créant ensemble une expérience sportive unique et vibrante."
      ]
    },
  
    {
      "name": "Idaho",
      "description": [
        "Les sports universitaires dans l'Idaho ont une forte tradition, avec un accent particulier sur le football américain et le basketball. Le sentiment de communauté et l'implication dans les équipes sportives sont remarquables, même si l'État n'accueille pas d'énormes universités.",
        "L'Université d'État de Boise, célèbre pour son terrain de football bleu, joue un rôle important dans le paysage sportif de l'Idaho. L'équipe de football a attiré l'attention nationale avec son succès et son style de jeu novateur, et les matchs à domicile sont un spectacle en eux-mêmes.",
        "En plus de Boise State, des écoles et collèges plus petits contribuent également à la riche culture sportive, avec des programmes dans des sports tels que le baseball, l'athlétisme et les sports d'hiver bénéficiant de l'environnement naturel unique de l'Idaho.",
        "En Idaho, les sports universitaires sont plus qu'un simple passe-temps ; ils sont un mode de vie et une partie essentielle du style de vie et de l'identité de l'État, les communautés se rassemblant pour soutenir leurs équipes."
      ]
    },
    {
      "name": "Illinois",
      "description": [
        "L'Illinois a une histoire riche et un amour profond pour les sports universitaires, avec des programmes puissants et des fans dévoués. Le football américain et le basketball attirent une attention significative tout au long de l'année, mais il existe également une large gamme d'autres sports prospérant.",
        "Les Fighting Illini de l'Université de l'Illinois et les Wildcats de l'Université Northwestern ouvrent la voie, avec des rivalités intenses et des ligues compétitives maintenant l'État dynamique. Le Memorial Stadium et le Ryan Field sont des temples du sport, où chaque match est une expérience inoubliable.",
        "Le basketball est également important, l'État produisant régulièrement des talents qui excellent tant au niveau national qu'international. Les Fighting Illini de l'Illinois ont une riche tradition de basketball et représentent une menace constante dans les tournois de la NCAA.",
        "Des petits collèges aux grandes universités, le sport en Illinois sert de lien, de source de fierté et de partie intégrante de la culture et de l'identité de l'État."
      ]
    },
    {
      "name": "Indiana",
      "description": [
        "L'Indiana est connu pour son amour profondément enraciné pour le basketball, et cela se ressent dans la culture des sports universitaires de l'État. La rivalité, la passion et le dévouement tant des joueurs que des fans sont inégalés.",
        "Des programmes comme les Hoosiers de l'Indiana et les Boilermakers de Purdue sont reconnus au niveau national, surtout en basketball. Une atmosphère électrique règne dans des lieux comme l'Assembly Hall et le Mackey Arena tout au long de l'année, où histoire et tradition se rencontrent.",
        "Alors que le basketball prend la tête, d'autres sports prospèrent également en Indiana. Le football américain, le baseball et l'athlétisme ont chacun des programmes solides et bénéficient d'un large soutien communautaire.",
        "Le sport en Indiana est plus qu'un simple jeu ; c'est un mode de vie, un catalyseur de l'esprit communautaire et une fière tradition transmise de génération en génération. Ici, au cœur du Midwest, les sports universitaires sont une partie intégrante de l'identité de l'État."
      ]
    },
    {
      "name": "Iowa",
      "description": [
        "Les sports universitaires en Iowa sont une source de fierté et d'esprit communautaire, avec une riche tradition à la fois dans le football américain et la lutte. La rivalité entre les Hawkeyes de l'Iowa et les Cyclones de l'Iowa State est centrale et offre des moments inoubliables chaque saison.",
        "Le Kinnick Stadium à Iowa City et le Jack Trice Stadium à Ames sont les lieux de matchs de football intenses chaque automne, attirant des dizaines de milliers de fans. La passion et le dévouement des fans caractérisent la culture sportive de l'État.",
        "La lutte est un autre sport dans lequel l'Iowa excelle, les Hawkeyes remportant régulièrement des titres nationaux. Ce sport a une tradition profondément enracinée en Iowa et bénéficie d'un soutien loyal.",
        "Des petits collèges aux grandes universités, les sports en Iowa sont plus que de la compétition ; ils sont un mode de vie, une source d'esprit communautaire et une partie importante de l'identité de l'État."
      ]
    },

    {
      "name": "Kansas",
      "description": [
        "Les sports universitaires au Kansas sont imprégnés de tradition et d'esprit communautaire, le basketball en étant le point culminant incontesté. L'État abrite certains des programmes les plus emblématiques du pays, et la rivalité et la passion sont intenses.",
        "Les Jayhawks du Kansas et les Wildcats de l'État du Kansas mènent la charge, avec leur 'Sunflower Showdown' annuel étant l'un des moments forts de l'année sportive. L'Allen Fieldhouse, domicile des Jayhawks, est connu comme l'une des arènes les plus intimidantes du basketball universitaire.",
        "Alors que le basketball forme le cœur de la culture sportive au Kansas, le football américain, le baseball et l'athlétisme jouent également un rôle important. Les fans sont inégalés dans leur dévouement et contribuent à l'atmosphère vibrante lors des matchs.",
        "Les sports au Kansas sont plus que de la compétition ; ils sont une source de fierté, un catalyseur de communauté et une partie essentielle de l'identité de l'État."
      ]
    },
    {
      "name": "Kentucky",
      "image": Kentucky,
      "imageDescription": "Karl-Anthony Towns et Devin Booker ont joué ensemble pour Kentucky avant de devenir des stars majeures de la NBA",
      "description": [
        "Les sports universitaires au Kentucky sont synonymes de basketball, avec une riche tradition et une base de fans passionnée. La rivalité entre les Kentucky Wildcats et les Louisville Cardinals est l'une des plus intenses et regardées du pays.",
        "Le Rupp Arena, domicile des Wildcats, est un véritable temple du basketball, où l'histoire et la fierté du programme sont palpables. Les Wildcats ont remporté plusieurs championnats nationaux et sont une institution dans le basketball universitaire.",
        "En plus du basketball, le Kentucky a également de solides programmes en football américain, sports équestres et tir, qui enrichissent la culture sportive de l'État. Le Kentucky Derby, une course de chevaux légendaire, souligne la riche tradition dans les sports équestres.",
        "Au Kentucky, le sport est plus qu'un passe-temps ; c'est un mode de vie, une source de communauté et de fierté, et une partie cruciale de l'identité de l'État."
      ]
    },
    {
      "name": "Louisiana",
      "description": [
        "En Louisiane, les sports universitaires font partie intégrante de la culture, avec une passion profonde pour le football américain et le baseball. Les Tigers de l'Université d'État de Louisiane (LSU) sont une puissance, attirant des foules massives et créant une atmosphère sans égal.",
        "Le Tiger Stadium, également connu sous le nom de 'Death Valley', est célèbre pour ses fans bruyants et son atmosphère intimidante, ce qui en fait l'un des terrains de jeu à domicile les plus redoutés du football universitaire. 'Le samedi soir à Death Valley' est plus qu'un match ; c'est un événement, une tradition, une partie de l'âme de la Louisiane.",
        "En plus de LSU, de plus petites universités et collèges contribuent également au riche paysage sportif, avec de solides programmes dans divers sports. Le baseball jouit également d'une grande popularité, LSU se battant régulièrement pour le titre national.",
        "Les sports en Louisiane sont une célébration de la communauté, une source de fierté et un lien qui rassemble les gens, quelle que soit leur origine. C'est une partie vibrante et essentielle du mode de vie et de l'identité de l'État."
      ]
    },
    {
      "name": "Maine",
      "description": [
        "Les sports universitaires dans le Maine reflètent la nature unique de l'État et son sens de la communauté, avec un fort accent sur les sports d'hiver et l'athlétisme. L'Université du Maine Black Bears mène la voie, représentant fièrement dans diverses divisions de la NCAA.",
        "Le Alfond Stadium à Orono est le cœur du football américain dans l'État, tandis que le Harold Alfond Sports Arena joue un rôle central pendant la saison de hockey sur glace. Les Black Bears ont une fière tradition de hockey sur glace, avec des fans enthousiastes bravant le froid pour soutenir leur équipe.",
        "En plus du hockey sur glace et du football américain, il existe également de solides programmes en athlétisme, aviron et autres sports qui enrichissent le paysage sportif du Maine. L'État bénéficie également de compétitions sportives vibrantes au lycée et en club, faisant des sports une partie intégrante de la communauté.",
        "Les sports dans le Maine sont bien plus que de la compétition ; ils sont une source de fierté, un moyen de célébrer l'esprit unique de l'État et un lien qui rassemble les gens, quelle que soit la saison."
      ]
    },
  
    {
      "name": "Maryland",
      "description": [
        "Les sports universitaires au Maryland prospèrent avec une forte tradition en basketball, crosse et football américain. Les Terrapins de l'Université du Maryland, ou simplement les Terps, sont au cœur de la culture sportive de l'État.",
        "Le Xfinity Center et le Maryland Stadium sont les fières arènes du basketball et du football, où les Terps disputent leurs matchs à domicile. Les fans sont fervents et contribuent à une atmosphère électrisante pendant les matchs. Le Maryland est connu pour son programme compétitif de basketball, avec de multiples apparitions au tournoi de la NCAA.",
        "La crosse est un autre sport dans lequel le Maryland excelle, avec les Terps luttant régulièrement pour les titres nationaux. Ce sport tient une place spéciale dans le cœur des habitants, et les rivalités universitaires intenses rendent chaque saison spectaculaire.",
        "Les sports au Maryland sont une expression de communauté, de fierté et de tradition, jouant un rôle crucial dans le rassemblement des gens et la célébration de la culture unique de l'État."
      ]
    },
    {
      "name": "Massachusetts",
      "description": [
        "Au Massachusetts, les sports universitaires jouent un rôle important dans la vie quotidienne, avec une histoire riche en basketball, football américain et hockey. Les universités ici ont de solides programmes sportifs et des fans passionnés.",
        "Le basketball occupe une place spéciale dans le cœur des habitants du Massachusetts, car le jeu a été inventé ici. Les Minutemen de l'Université du Massachusetts, connus sous le nom de UMass Minutemen, sont de fiers représentants dans le basketball de la Division I de la NCAA.",
        "Le football américain et le hockey sont également populaires, avec des rivalités locales intenses offrant une compétition excitante. Le match de football Harvard-Yale, connu sous le nom de 'The Game', est un moment fort annuel.",
        "Les sports au Massachusetts sont synonymes de tradition, de communauté et de compétition. Ils rassemblent les gens, quel que soit leur origine, et constituent une partie essentielle de l'identité culturelle de l'État."
      ]
    },
    {
      "name": "Michigan",
      "image": Michigan,
      "imageDescription": "Stade du Michigan : le troisième plus grand stade du monde et domicile des Michigan Wolverines. Il peut accueillir 107 601 fans.",
      "description": [
        "Le Michigan a une riche tradition dans les sports universitaires, notamment dans le football américain et le basketball. La rivalité entre les Wolverines de l'Université du Michigan et les Spartans du Michigan State est légendaire et crée une atmosphère électrique dans tout l'État.",
        "Le Michigan Stadium, également connu sous le nom de 'The Big House', est l'un des plus grands stades du monde, pouvant accueillir plus de 100 000 fans. Les Wolverines ont une histoire glorieuse, avec plusieurs titres nationaux en football.",
        "Le basketball est également très populaire, avec à la fois Michigan et Michigan State luttant régulièrement lors du tournoi de la NCAA. La passion et le dévouement des fans sont inégalés, et les jours de match sont de véritables événements au Michigan.",
        "En plus de ces sports majeurs, le Michigan a également de solides programmes en hockey sur glace, en athlétisme et dans d'autres sports. Les sports au Michigan sont plus qu'un simple passe-temps ; ils constituent une partie intégrante du mode de vie et de l'identité de l'État."
      ]
    },
    {
      "name": "Minnesota",
      "description": [
        "Les sports universitaires dans le Minnesota ont un caractère unique, avec un fort accent sur le hockey sur glace, le football américain et le basketball. Les Golden Gophers de l'Université du Minnesota jouent un rôle central dans la communauté sportive de l'État.",
        "Le TCF Bank Stadium et le Williams Arena sont des centres d'activité animés pendant les saisons de football et de basketball, tandis que le Mariucci Arena est une forteresse pour le hockey sur glace. Les Golden Gophers sont de fiers représentants de la NCAA, avec une histoire riche et des fans dévoués.",
        "Le hockey sur glace est particulièrement populaire au Minnesota, souvent appelé 'l'État du hockey'. Les équipes universitaires bénéficient d'un fort soutien et offrent une action palpitante, surtout pendant le célèbre tournoi 'Frozen Four' de la NCAA.",
        "Les sports au Minnesota sont profondément liés à la culture et à l'identité de l'État, offrant une source de fierté, d'esprit communautaire et de rivalités animées qui rassemblent les gens de tous âges."
      ]
    },
    {
      "name": "Mississippi",
      "description": [
        "Les sports universitaires sont une partie intégrante de la culture au Mississippi, avec une passion intense pour le football américain, le basketball et le baseball. La rivalité entre les Rebels de l'Université du Mississippi (Ole Miss) et les Bulldogs de l'État du Mississippi est l'une des plus intenses du pays.",
        "Le Vaught-Hemingway Stadium à Oxford et le Davis Wade Stadium à Starkville sont de véritables temples pour les fans de football, où la tension et la passion du jeu sont palpables. Le Egg Bowl annuel, l'affrontement entre Ole Miss et Mississippi State, est un moment fort du calendrier sportif.",
        "Le basketball et le baseball sont également importants, avec les deux universités qui construisent des équipes solides et des traditions. Les fans sont loyaux et vocaux, créant une atmosphère électrique lors des matchs.",
        "Les sports au Mississippi sont synonymes de communauté, de fierté et de tradition. Ils rassemblent les gens, créent des moments inoubliables et constituent une partie essentielle de l'identité de l'État."
      ]
    },
    {
      "name": "Missouri",
      "description": [
        "Les sports universitaires au Missouri sont synonymes de passion, de communauté et de compétition. L'État a une riche histoire sportive, avec le football américain, le basketball et le baseball en tête.",
        "Les Tigers de l'Université du Missouri, également connus sous le nom de Mizzou, et les Bears de l'État du Missouri sont deux représentants importants du paysage sportif universitaire. Le Faurot Field de Mizzou et le Mizzou Arena sont des épicentres d'excitation et d'esprit d'équipe les jours de match.",
        "Les Tigers ont une forte présence dans la SEC, l'une des conférences les plus compétitives du football universitaire. Leur équipe de basketball est également un pilier du tournoi de la NCAA, avec des fans fournissant un soutien indéfectible.",
        "Les sports au Missouri renforcent les liens communautaires et rassemblent les gens. Ce n'est pas seulement à propos du jeu ; c'est à propos de l'expérience, des traditions et du lien indissoluble entre les équipes et leurs supporters."
      ]
    },
    {
      "name": "Montana",
      "description": [
        "Au Montana, les sports universitaires jouent un rôle crucial dans la vie communautaire, avec le football américain, le basketball et le rodéo comme sports populaires. Les Grizzlies du Montana et les Bobcats de l'État du Montana dominent le paysage sportif, et leur rivalité est intense.",
        "Le Washington-Grizzly Stadium des Grizzlies et le Bobcat Stadium des Bobcats sont de fiers arènes de football au Montana. Ici, les fans se rassemblent pour soutenir leurs équipes, peu importe le temps. Le match annuel Brawl of the Wild entre les Grizzlies et les Bobcats est un événement à ne pas manquer.",
        "Le basketball est également populaire, avec des matchs animés et une forte base de fans. Le rodéo, propre au Montana, attire également beaucoup d'attention et d'appréciation, reflétant la culture et les traditions de l'État.",
        "Les sports universitaires au Montana sont synonymes de communauté, de fierté et d'amour pour le jeu. Ils rassemblent les gens, créent des souvenirs inoubliables et contribuent à l'identité unique du Big Sky Country."
      ]
    },
    {
      "name": "Nebraska",
      "description": [
        "Au Nebraska, les sports universitaires se caractérisent par une passion profonde et une loyauté, avec un accent particulier sur le football américain. Les Cornhuskers du Nebraska, basés à l'Université du Nebraska-Lincoln, sont le cœur de la culture sportive de l'État.",
        "Le Memorial Stadium, domicile des Cornhuskers, est également appelé 'La Mer Rouge', où les fans dans leur tenue rouge distinctive se rassemblent pour soutenir leur équipe. Le stade est connu pour son bruit assourdissant et son atmosphère unique les jours de match.",
        "En plus du football, le basketball joue également un rôle significatif, avec des équipes masculines et féminines compétitives. Les fans sont tout aussi dévoués et créent une atmosphère vibrante à chaque match.",
        "Les sports universitaires au Nebraska vont au-delà du simple sport ; c'est un moyen de rassembler les communautés, de célébrer les traditions et de créer un sentiment de fierté et de solidarité dans 'The Cornhusker State'."
      ]
    },
    
    {
      "name": "Nevada",
      "description": [
        "Les sports universitaires au Nevada offrent un mélange unique de divertissement et de compétition, avec une attention particulière portée au basketball et au football américain. L'Université du Nevada, Las Vegas (UNLV), et l'Université du Nevada, Reno sont les deux principales institutions sportives de l'État.",
        "L'équipe de basketball de l'UNLV, les Runnin' Rebels, a une riche histoire et est connue à l'échelle nationale. Leurs matchs à domicile sont une véritable attraction, avec des performances énergiques et un public enthousiaste. L'équipe de football des Nevada Wolf Pack contribue également à la culture sportive de l'État, avec des matchs excitants et une base de fans fidèles.",
        "Au Nevada, il existe également un intérêt vibrant pour d'autres sports universitaires tels que le baseball, le softball et le soccer. Ces sports attirent les communautés locales et offrent de nombreuses opportunités aux athlètes de se développer.",
        "Dans l'ensemble, les sports universitaires au Nevada sont une partie essentielle de l'État, offrant non seulement du divertissement, mais aussi rassemblant les communautés et enrichissant la culture locale."
      ]
    },
    {
      "name": "New Hampshire",
      "description": [
        "Dans l'état pittoresque du New Hampshire, les sports universitaires jouent un rôle crucial dans la vie communautaire. Bien que l'État soit plus petit que beaucoup d'autres, le dévouement aux sports est immense.",
        "L'Université du New Hampshire (UNH) domine le paysage sportif, surtout dans le hockey, où les Wildcats performant de manière constante à un niveau élevé. Le Whittemore Center Arena, le stade de hockey de l'équipe, est connu pour son atmosphère vibrante et ses fans passionnés.",
        "Le football et le basketball sont également des sports populaires à l'UNH, attirant de grandes foules pendant la saison. La communauté est fière de ses équipes, et les étudiants sont étroitement impliqués dans les événements sportifs.",
        "L'approche du New Hampshire envers les sports universitaires est axée sur la communauté, dans le but de rassembler les gens, de célébrer les succès et de créer des moments inoubliables dans les paysages pittoresques de l'État."
      ]
    },
    {
      "name": "New Jersey",
      "description": [
        "Les sports universitaires au New Jersey bénéficient d'une atmosphère vibrante et compétitive, avec diverses universités qui concourent à un niveau élevé. L'État abrite quelques-uns des programmes universitaires les plus en vue, reconnus à la fois régionalement et nationalement.",
        "L'une des équipes universitaires les plus importantes est celle des Rutgers Scarlet Knights, faisant partie de la prestigieuse Big Ten Conference. Leurs matchs de football et de basketball attirent de grandes foules et génèrent de l'excitation dans tout l'État.",
        "Le New Jersey est également connu pour ses solides programmes de crosse et de soccer, attirant et développant de jeunes talents. Ces sports jouent un rôle significatif dans la culture des sports universitaires de l'État, avec des compétitions excitantes et des fans dévoués.",
        "La communauté du New Jersey embrasse ses équipes sportives universitaires, ce qui crée une atmosphère de soutien et de passion lors de chaque événement. Les sports universitaires au New Jersey sont plus que de simples jeux ; ils sont une partie essentielle du tissu culturel de l'État."
      ]
    },
    {
      "name": "New Mexico",
      "description": [
        "Le Nouveau-Mexique, avec son arrière-plan culturel et géographique unique, entretient une relation spéciale avec les sports universitaires. L'État abrite certains des programmes sportifs universitaires les plus importants qui concourent au niveau national.",
        "Les Lobos de l'Université du Nouveau-Mexique à Albuquerque sont un point central de fierté. Leur équipe de basketball attire régulièrement de grandes foules à The Pit, l'une des arènes les plus intimidantes du pays.",
        "Les Aggies de l'État du Nouveau-Mexique de Las Cruces sont également des concurrents nationaux, surtout en basketball et en football, où ils ont de féroces rivalités avec les Lobos, aboutissant au Rio Grande Rivalry.",
        "Soutenus par une riche histoire et une base de fans passionnée, les sports universitaires au Nouveau-Mexique fournissent non seulement du divertissement, mais promeuvent également l'esprit communautaire et l'identité de l'État. Dans cet État désertique, le sport est une célébration du talent, de la persévérance et des liens communautaires."
      ]
    },

    {
      "name": "New York",
      "description": [
          "Dans l'État animé de New York, les sports universitaires jouent un rôle dynamique et polyvalent. L'État abrite de nombreuses universités, chacune avec ses propres traditions sportives uniques.",
          "L'Université de Syracuse se distingue par son équipe de basketball, les Orange, qui compétitionne souvent au plus haut niveau national. Le stade Carrier Dome, connu pour sa capacité impressionnante et son atmosphère animée, est une icône dans le monde des sports universitaires.",
          "De plus, les plus petites universités et collèges jouent également un rôle significatif, avec des programmes solides dans des sports tels que le lacrosse, le soccer et le baseball. Ces programmes plus petits nourrissent l'esprit de compétition dans l'État, créant un paysage sportif riche et diversifié.",
          "La participation communautaire aux sports universitaires à New York est immense, avec des fans soutenant leurs équipes dans les bons et les mauvais moments. Des villes animées aux zones rurales plus calmes, le sport unit les New-Yorkais et inculque un sentiment de fierté et de solidarité."
      ]
  },
  {
      "name": "North Carolina",
      "image": North_Carolina,
      "imageDescription": "La rivalité emblématique : DUKE vs UNC en action",
      "description": [
          "La Caroline du Nord a une riche tradition et une passion inégalée en ce qui concerne les sports universitaires, en particulier le basketball. La rivalité entre les Tar Heels de l'Université de Caroline du Nord et les Blue Devils de l'Université Duke est mondialement célèbre et garantit des confrontations excitantes.",
          "Les Tar Heels et les Blue Devils dominent non seulement la saison de basketball, mais apportent également une vague d'excitation et d'esprit communautaire dans tout l'État. C'est une culture sportive profondément ancrée dans l'identité de la Caroline du Nord.",
          "En plus du basketball, le football américain joue également un rôle important, avec des équipes solides et des fans passionnés. Et au printemps, l'amour pour le baseball fleurit, avec des équipes universitaires et collégiales qui s'affrontent sur les terrains.",
          "Les sports universitaires en Caroline du Nord vont au-delà des terrains et des salles de sport ; ils sont un mode de vie, une source de fierté et un moyen pour les communautés de se rassembler et de célébrer leur État."
      ]
  },
  {
      "name": "North Dakota",
      "description": [
          "Les sports universitaires au Dakota du Nord offrent une expérience unique, avec des communautés étroitement impliquées dans leurs équipes universitaires. Le sentiment de fierté et de solidarité est clairement visible lors des événements sportifs, malgré le fait que l'État compte moins d'universités de premier plan que d'autres régions américaines.",
          "L'équipe de football américain de l'Université d'État du Dakota du Nord, les Bison, est connue pour ses succès dans la Division I de la NCAA Football Championship Subdivision, ce qui donne un coup de pouce significatif à la culture sportive de l'État. Les fans se rassemblent au Fargodome pour soutenir leur équipe, créant une atmosphère intense.",
          "Le hockey sur glace joue également un rôle majeur au Dakota du Nord, avec les Fighting Hawks de l'Université du Dakota du Nord en tête. La passion pour ce sport d'hiver est évidente pendant la saison, avec des fans affluant à la patinoire.",
          "Les sports universitaires au Dakota du Nord, en particulier le football américain et le hockey sur glace, rassemblent les communautés, créent une forte fierté locale et contribuent à la culture sportive dynamique de l'État."
      ]
  },
  {
      "name": "Ohio",
      "description": [
          "Les sports universitaires sont une partie intégrante de la culture de l'Ohio, avec un accent particulier sur le football américain et le basketball. L'Ohio State University (OSU) et l'Université de Cincinnati sont deux institutions de premier plan dans le domaine des sports.",
          "L'équipe de football de l'OSU, les Buckeyes, jouit d'une renommée nationale et est une véritable force de la NCAA. Leurs matchs au massive Ohio Stadium attirent plus de 100 000 fans, créant une atmosphère sans pareille lors des matchs à domicile. 'O-H-I-O' est un chant connu dans tout l'État.",
          "Le basketball est également très présent, avec des équipes masculines et féminines compétitionnant à un niveau élevé. L'État de l'Ohio abrite plusieurs équipes universitaires qui participent régulièrement au tournoi de la NCAA, ajoutant à la frénésie du basketball.",
          "Les sports universitaires dans l'Ohio offrent plus que de la compétition ; ils sont une source de fierté, de tradition et d'esprit communautaire, unissant les gens de tous horizons."
      ]
  },

  {
    "name": "Oklahoma",
    "description": [
        "En Oklahoma, les sports universitaires occupent une place centrale dans le cœur de ses habitants, avec une passion profondément enracinée pour le football américain. L'Université de l'Oklahoma (OU) et l'Oklahoma State University (OSU) sont les joyaux du monde sportif de l'État.",
        "L'équipe de football de l'OU, les Sooners, a une histoire riche et de nombreux succès, créant une atmosphère vibrante autour de leurs matchs. Le Gaylord Family Oklahoma Memorial Stadium est une forteresse pour l'équipe, où les fans chantent bruyamment 'Boomer Sooner'.",
        "OSU n'est pas loin derrière l'OU, avec de solides performances tant en football qu'en d'autres sports, où leur équipe de football américain, les Cowboys, joue un rôle important. Le dévouement des fans lors de la 'Bedlam Series', la bataille annuelle entre l'OU et l'OSU, est énorme.",
        "Les sports universitaires en Oklahoma symbolisent la lutte, la persévérance et le sens de la communauté de l'État, en faisant bien plus qu'un simple jeu."
    ]
},
{
    "name": "Oregon",
    "description": [
        "Les sports universitaires en Oregon sont une partie essentielle de l'État, avec un accent sur le football américain et l'athlétisme. L'Université de l'Oregon (UO) à Eugene est au cœur de cette passion sportive et a acquis une réputation nationale.",
        "L'équipe de football de l'UO, les Ducks, est connue pour leur jeu rapide et leurs uniformes uniques, tandis que le programme d'athlétisme est l'un des meilleurs du pays. Le classique annuel d'athlétisme Prefontaine, qui se déroule au célèbre Hayward Field, met en lumière la dévotion de l'Oregon pour l'athlétisme.",
        "L'Université d'État de l'Oregon (OSU) avec son équipe de football, les Beavers, offre une forte concurrence, surtout lors de la 'Civil War', le choc annuel avec les Ducks. Ce n'est pas seulement un jeu ; c'est une tradition étatique qui rassemble les communautés.",
        "En plus de ces sports, le basketball jouit également d'une grande popularité, et les fans passionnés font des sports universitaires en Oregon une expérience à ne pas manquer. C'est une célébration du talent athlétique, de la communauté et de la culture sportive unique de l'État."
    ]
},
{
    "name": "Pennsylvania",
    "description": [
        "La Pennsylvanie a une profonde appréciation pour les sports universitaires, avec un fort accent sur le football américain, le basketball et l'athlétisme. Des équipes comme les Penn State Nittany Lions et les Pittsburgh Panthers dominent le paysage sportif et créent des rivalités excitantes.",
        "Beaver Stadium, le domicile des Nittany Lions, est connu pour son atmosphère écrasante et son intense expérience pour les fans, surtout pendant les jeux 'White Out'. Ces moments sont le point culminant du calendrier sportif et attirent des fans de tout l'État.",
        "Sur le terrain de basketball, les Wildcats de Villanova ont récemment connu un succès national, avec plusieurs championnats qui ont ravi l'État. Leurs performances ont mis la Pennsylvanie sur la carte en tant que puissance du basketball.",
        "L'athlétisme joue également un rôle crucial, avec les prestigieuses Penn Relays attirant les meilleurs athlètes à Philadelphie chaque année. Cette riche culture sportive renforce l'esprit communautaire et contribue à la fierté de la Pennsylvanie."
    ]
},
{
    "name": "Rhode Island",
    "description": [
        "Malgré sa petite taille, Rhode Island a une scène sportive universitaire vibrante, avec le basketball et la voile étant notamment populaires. L'État compte des équipes qui jouent avec cœur et âme, créant des moments excitants.",
        "Des équipes comme les Rhode Island Rams et les Providence Friars ont une base de fans fidèle et offrent des matchs captivants. Le Dunkin' Donuts Center à Providence devient une arène animée pendant les matchs de basketball, avec des habitants qui se rassemblent en masse pour soutenir leurs équipes.",
        "En plus du basketball, Rhode Island est connu pour ses programmes exceptionnels de voile, avec la magnifique côte offrant des conditions parfaites pour le sport. Des universités comme Brown et URI ont de solides équipes de voile qui compétitionnent au niveau national.",
        "Ces sports contribuent à la culture vibrante de Rhode Island, où chaque match est une occasion de célébrer l'esprit unique de cet État petit mais fier."
    ]
},
{
  "name": "South Carolina",
  "description": [
      "Les sports universitaires en Caroline du Sud respirent la tradition et la passion, avec le football américain et le basketball au centre de l'attention. La rivalité intense entre les Gamecocks de l'Université de Caroline du Sud et les Tigers de l'Université de Clemson domine le paysage sportif.",
      "Lors du traditionnel affrontement annuel de football, 'The Palmetto Bowl', tout l'État s'anime, avec la ligne entre le grenat et l'orange clairement tracée. L'énergie et la passion des fans sont incomparables, rendant chaque match inoubliable.",
      "Le basketball occupe également une place importante dans le cœur des fans de sport en Caroline du Sud, avec des équipes compétitives tant dans les ligues masculines que féminines. Par exemple, l'équipe de basketball féminin des Gamecocks a connu un succès national, renforçant encore la fierté et l'implication communautaire.",
      "La culture sportive en Caroline du Sud va au-delà des terrains de jeu ; c'est un mode de vie, une source de fierté et une partie indispensable de l'identité de l'État."
  ]
},
{
  "name": "South Dakota",
  "description": [
      "Les sports universitaires au Dakota du Sud offrent une expérience unique et passionnée, avec un accent sur le basketball, le football américain et l'athlétisme. Les Jackrabbits de l'Université d'État du Dakota du Sud et les Coyotes de l'Université du Dakota du Sud sont des acteurs importants du paysage sportif.",
      "Les matchs de basketball, notamment lors des rencontres entre les Jackrabbits et les Coyotes, attirent des foules enthousiastes et créent une atmosphère électrisante. L'État soutient à la fois les équipes masculines et féminines, les performances sur le terrain étant une source de fierté pour les communautés locales.",
      "Le football américain jouit également d'une grande popularité, avec des matchs excitants et un fort engagement des fans. Les programmes sportifs au Dakota du Sud excellent, avec des athlètes compétitionnant au niveau national et représentant fièrement l'État.",
      "Les sports universitaires au Dakota du Sud sont bien plus que des jeux ; ils sont une partie essentielle de la communauté, renforçant les liens entre les résidents et contribuant à la culture vibrante de l'État."
  ]
},
{
  "name": "Tennessee",
  "description": [
      "Au Tennessee, les sports universitaires jouent un rôle crucial dans la communauté, avec une riche tradition en football américain, basketball et athlétisme. Les Volunteers de l'Université du Tennessee et les Commodores de l'Université de Vanderbilt sont des équipes leaders dans le paysage sportif de l'État.",
      "Les Volunteers, avec leur couleur orange emblématique, attirent de grandes foules au Neyland Stadium, l'un des plus grands stades de football américain du pays. La passion des fans est inégalée, et 'Rocky Top' résonne haut et fort à chaque match.",
      "Le basketball est également une attraction majeure au Tennessee, avec des équipes masculines et féminines recevant une reconnaissance nationale. L'État a une riche histoire de production de grands athlètes et a contribué à la croissance du sport au niveau national.",
      "Les sports universitaires au Tennessee sont une partie essentielle de la culture et de l'identité de l'État, rassemblant les gens, célébrant les traditions et inspirant la prochaine génération d'athlètes."
  ]
},
{
  "name": "Texas",
  "image": Texas,
  "imageDescription": "Rivalité traditionnelle : les Longhorns du Texas affrontent les Aggies du Texas",
  "description": [
      "Les sports universitaires au Texas sont synonymes de taille, de passion et de riche tradition, avec le football américain en tête. Des équipes comme les Longhorns du Texas et les Aggies du Texas A&M ont une énorme base de fans, et leurs matchs sont des moments forts de la saison.",
      "Le Darrell K Royal–Texas Memorial Stadium à Austin et le Kyle Field à College Station sont des temples du sport où chaque match à domicile se transforme en un événement spectaculaire. La rivalité entre les différentes équipes universitaires est intense et contribue à la culture sportive unique de l'État.",
      "En plus du football, le basketball et le baseball sont également populaires, avec de solides programmes compétitionnant au niveau national. Le soutien aux sports féminins est en constante croissance, le basketball et l'athlétisme se démarquant en particulier.",
      "Les sports universitaires au Texas sont bien plus qu'un simple passe-temps ; c'est un mode de vie, une source de fierté et une force unificatrice qui rassemble les communautés."
  ]
},


{
  "name": "Utah",
  "description": [
      "Dans l'Utah, les sports universitaires occupent une place significative au sein de la communauté, le basketball et le football américain étant les sports les plus populaires. Les Utes de l'Université de l'Utah et les Cougars de l'Université Brigham Young s'engagent dans une compétition féroce dans diverses disciplines.",
      "Le duel annuel de football entre les Utes et les Cougars, connu sous le nom de 'Sainte Guerre', est l'un des événements sportifs les plus attendus de l'État. Il rassemble les fans pour une célébration de l'esprit sportif, de la rivalité et de la gloire de l'État.",
      "Le basketball a également une tradition riche en Utah, avec des saisons passionnantes et des moments mémorables dans les ligues masculines et féminines. Les équipes visent l'excellence, et le soutien des fans est inébranlable.",
      "L'impact des sports universitaires en Utah va au-delà du terrain de jeu. Il favorise l'esprit communautaire, la fierté scolaire et contribue de manière significative à l'identité culturelle de l'État."
  ]
},
{
  "name": "Vermont",
  "description": [
      "Au Vermont, où la nature prédomine et où les communautés sont proches, les sports universitaires jouent un rôle unique en rassemblant les gens. Les sports d'hiver tels que le ski et le snowboard sont mis en avant, compte tenu du relief montagneux et du climat froid.",
      "Les Catamounts de l'Université du Vermont sont au cœur de la scène des sports universitaires, avec des équipes excellentes dans divers sports, notamment le hockey sur glace et le basketball. Les Catamounts ont une base de fans fidèle, et l'ambiance lors des matchs à domicile est intense.",
      "Le basketball jouit d'une grande popularité, et les matchs des Catamounts sont un incontournable pour les amateurs de sport. L'équipe a participé plusieurs fois au tournoi de la NCAA, plaçant ainsi l'État sur la carte du monde du basketball universitaire.",
      "Les sports universitaires au Vermont sont bien plus qu'un simple jeu ; ils sont un moyen de rassembler les communautés, de développer les talents et de célébrer la culture sportive unique de l'État."
  ]
},
{
  "name": "Virginia",
  "description": [
      "En Virginie, les sports universitaires font partie intégrante de la culture locale, avec un fort accent sur le football américain, le basketball et la popularité émergente du soccer. Les Cavaliers de Virginie et les Hokies de Virginia Tech sont les principales équipes universitaires qui apportent passion et rivalité sur le terrain.",
      "Le football américain attire des foules de fans dans les stades, où l'atmosphère les jours de match est électrique. L'investissement dans les programmes de basketball a également porté ses fruits, avec des équipes compétitionnant au niveau national et bénéficiant d'un suivi dévoué.",
      "Le soccer gagne du terrain en Virginie, avec des équipes masculines et féminines se développant en forces compétitives. Les universités investissent dans les installations et l'entraînement, tandis que les académies locales produisent de jeunes talents.",
      "Ce mélange de sports traditionnels et émergents enrichit la scène des sports universitaires en Virginie, créant une communauté sportive dynamique et offrant aux athlètes et aux fans des expériences inoubliables et un sentiment de fierté et d'unité."
  ]
},
{
  "name": "Washington",
  "description": [
      "Les sports universitaires dans l'État de Washington prospèrent avec un mélange de tradition et d'enthousiasme, avec le football américain, le basketball et l'aviron en tête d'affiche. Les Huskies de l'Université de Washington et les Cougars de l'Université de l'État de Washington sont les pionniers de la culture sportive de l'État.",
      "Le Husky Stadium à Seattle et le Martin Stadium à Pullman bourdonnent d'excitation lors des matchs de football américain, où les fans de toute la région se rassemblent pour soutenir leurs équipes. L'« Apple Cup », le choc annuel entre les Huskies et les Cougars, est un moment fort de la rivalité.",
      "Le basketball joue également un rôle significatif, avec des équipes masculines et féminines compétitionnant à un niveau élevé. Les Huskies ont un héritage solide dans ce sport et attirent des supporters passionnés.",
      "L'aviron est une autre discipline notable, avec les Huskies remportant de nombreux championnats nationaux. Les sports universitaires dans l'État de Washington reflètent la diversité et la passion de l'État, où l'esprit sportif, la compétition et la camaraderie sont hautement valorisés."
  ]
},

{
  "name": "West Virginia",
  "description": [
      "Les sports universitaires sont le cœur battant de la Virginie-Occidentale, avec un fort accent sur le football américain et le basketball. Les Mountaineers de l'Université de Virginie-Occidentale sont les fiers représentants de l'État, et leurs matchs rassemblent la communauté.",
      "Le stade Milan Puskar à Morgantown prend vie lors des matchs à domicile des Mountaineers, avec des fans portant les couleurs emblématiques or et bleu et encourageant bruyamment. Le 'Backyard Brawl', une rivalité historique avec l'Université de Pittsburgh, ajoute une excitation supplémentaire à la saison.",
      "Le basketball est une autre passion, avec des matchs palpitants maintenant les fans sur le bord de leur siège. Les Mountaineers ont des équipes masculines et féminines compétitionnant au niveau national.",
      "Les sports universitaires en Virginie-Occidentale sont une source de fierté et d'unité, reflétant le fort sentiment de communauté et les traditions de l'État."
  ]
},
{
  "name": "Wisconsin",
  "description": [
      "Les sports universitaires sont profondément enracinés dans la culture de l'ouest du Wisconsin, avec le football américain, le basketball et le hockey sur glace comme disciplines prédominantes. Les Badgers de l'Université du Wisconsin sont la force motrice derrière la culture sportive de l'État.",
      "Le stade Camp Randall à Madison bourdonne d'excitation lors des matchs de football américain, avec des fans se réunissant pour chanter 'Jump Around' et encourager leur équipe. La rivalité avec des équipes comme les Wolverines de l'Université du Michigan est légendaire et conduit à des matchs intenses.",
      "Le basketball a également une riche tradition, avec les Badgers compétitionnant régulièrement pour les premières places dans les tournois de la NCAA. Le hockey sur glace occupe une place spéciale dans le cœur de l'État, avec des matchs palpitants illuminant les soirées d'hiver.",
      "Les sports universitaires dans l'ouest du Wisconsin vont au-delà du terrain de jeu ; ils sont une source de fierté, de camaraderie et renforcent les liens entre les communautés de l'État."
  ]
},
{
  "name": "Wyoming",
  "description": [
      "Les sports universitaires dans le Wyoming ont un caractère unique, avec un fort accent sur les sports de plein air tels que le ski, le snowboard et l'alpinisme. Les Cowboys et Cowgirls de l'Université du Wyoming sont les porte-étendards de cette culture sportive aventureuse.",
      "Le War Memorial Stadium à Laramie prend vie lors des matchs de football américain, où les fans se rassemblent pour montrer leur soutien aux Cowboys. Les montagnes à couper le souffle servent de toile de fond à ces événements sportifs.",
      "L'université a également une forte tradition en ski et snowboard, avec des athlètes dominant les compétitions nationales et internationales. Les vastes étendues sauvages du Wyoming servent de terrain de jeu pour les aventuriers et les passionnés de plein air.",
      "Les sports universitaires dans le Wyoming célèbrent la beauté de la nature et l'esprit aventureux de l'État, où l'esprit sportif, la passion et l'amour pour les grands espaces se rejoignent."
  ]
}



];

export default states;