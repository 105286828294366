/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Wrestling1.jpeg";
import Image2 from "assets/images/Sports_Images/Wrestling2.webp";
import Image3 from "assets/images/Sports_Images/Wrestling3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Wrestling Scholarships USA: How Studying and Wrestling in America Can Make Your Dream Come True', isSubheader: false },
  
    { id: 'Chapter 1', title: '1. The History and Development of Wrestling in America', isSubheader: false },
    { id: '1.1', title: '1.1 Early Years', isSubheader: true },
    { id: '1.2', title: '1.2 Growth and Evolution', isSubheader: true },
  
    { id: 'Chapter 2', title: '2. College Wrestling Today', isSubheader: false },
    { id: '2.1', title: '2.1 Key Competitions and Conferences', isSubheader: true },
    { id: '2.2', title: '2.2 Top Programs and Prestigious Teams', isSubheader: true },
  
    { id: 'Chapter 3', title: '3. From College to Olympic Level and Professional Competitions', isSubheader: false },
    { id: '3.1', title: '3.1 The Path to the Top', isSubheader: true },
    { id: '3.2', title: '3.2 Professional Wrestling and the Olympics', isSubheader: true },
  
    { id: 'Chapter 4', title: '4. International Influences and Competitions', isSubheader: false },
    { id: '4.1', title: '4.1 Foreign Wrestling Styles and Traditions', isSubheader: true },
    { id: '4.2', title: '4.2 Americans on the World Stage', isSubheader: true },
  
    { id: 'Chapter 5', title: '5. Wrestling Scholarships and the Future', isSubheader: false },
    { id: '5.1', title: '5.1 The Importance of Scholarships', isSubheader: true },
    { id: '5.2', title: '5.2 How Sport Scholarships America Can Help', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Content
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Hide</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Show</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Wrestling Scholarships in the USA: How Studying and Wrestling in America Can Make Your Dreams Come True
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Aerial view of a packed stadium at a college wrestling match."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        The National Championship
      </figcaption>
    </figure>
    Wrestling is a sport that has been practiced for thousands of years worldwide. It is a form of physical combat in which two opponents try to dominate each other through holds, throws, pins, and other techniques. Wrestling requires not only physical strength, speed, endurance, and flexibility but also mental sharpness, strategy, discipline, and perseverance.
    <br />
    <br />
    In the United States, wrestling is one of the most practiced and watched sports. It has a long and captivating history dating back to colonial times. Since then, it has developed into a national phenomenon with millions of fans and practitioners. American college wrestling represents the pinnacle of this development. It is the level where the country's best wrestlers meet in exciting competitions that often serve as a stepping stone to professional or Olympic careers.
    <br />
    <br />
    The significance of college-level wrestling in the development of athletes cannot be underestimated. College wrestlers not only get the opportunity to showcase and improve their athletic talents but also to study at renowned universities, where they can acquire valuable academic skills and knowledge. Moreover, they can benefit from scholarships that can cover a significant portion or all of their tuition costs.
    <br />
    <br />
    However, obtaining a wrestling scholarship is not an easy task. It requires a lot of preparation, dedication, and guidance. Fortunately, you don't have to do it alone. Sportbeursamerika.nl is an organization specializing in helping young wrestling talents realize their dream of studying and wrestling in America. I offer you professional advice, personal support, and access to my extensive network of contacts in the American wrestling world.
    Would you like to know more about how I can help you secure a wrestling scholarship in the USA? Read on! In this article, I will tell you more about:
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 1: The History and Development of Wrestling in America
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Wrestling is a sport that has been practiced since ancient times in various cultures. It is one of the oldest forms of organized competition, mentioned in Greek mythology and the Bible. It was also one of the first sports included in the ancient Olympic Games.
    <br />
    <br />
    In the United States, wrestling has had its own history and evolution closely tied to the country's social and political context. In this chapter, we will provide an overview of the main phases and events in American wrestling history and how they contributed to the formation of modern college wrestling.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Early Years
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      The beginnings of wrestling in America can be traced back to colonial times when European settlers brought their own wrestling styles and traditions to the new continent. These styles were primarily based on English catch-as-catch-can wrestling, which allowed holds on any part of the body except the head. It was a popular form of entertainment and challenge among the colonists who often organized matches on holidays or market days.
      <br />
      <br />
      One of the earliest known wrestlers in America was George Washington, who, as a young man, was known for his wrestling skills. He once defeated seven opponents in one day during a match in Virginia. Another famous wrestler from this period was Abraham Lincoln, who won multiple matches as a teenager in Illinois.
      <br />
      <br />
      The first college wrestling team was formed in 1780 at Yale University, but it took more than a century for college wrestling to truly take off. The first official intercollegiate match took place in 1889 between Yale and Columbia University, with Yale winning 5-0. In 1892, the first national championship was held in New York City, featuring eight teams from different states. The winner was Cornell University, which won all seven matches.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Growth and Evolution
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      In the late 19th and early 20th centuries, wrestling began to spread and diversify in America. Various regional styles emerged, such as Iowa-style wrestling, which emphasized speed and agility, or Oklahoma-style wrestling, which focused on strength and control. New rules were also introduced to make wrestling safer and fairer, such as banning dangerous holds or introducing time limits.
      <br />
      <br />
      College wrestling also grew in popularity and prestige, with more teams, competitions, and fans. In 1928, the National Collegiate Athletic Association (NCAA) was founded to regulate and promote college wrestling. The NCAA organized annual national championships for different divisions based on the size and level of universities. The most prestigious division was Division I, which comprised approximately 80 teams.
      <br />
      <br />
      College wrestling also produced many stars and legends who achieved both national and international success. Some examples include:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Dan Gable: One of the greatest wrestlers of all time, who dominated both as an athlete and a coach. He won three NCAA titles for Iowa State University, a gold medal at the 1972 Olympics without losing a single point, and 15 NCAA titles as a coach for the University of Iowa.
        </li>
        <li>
          John Smith: A six-time world champion and two-time Olympic champion known for his innovative techniques and tactics. He won two NCAA titles for Oklahoma State University and later became the head coach of the same team.
        </li>
        <li>
          Cael Sanderson: The only wrestler to win four NCAA titles without losing a single match. He had a perfect record of 159-0 for Iowa State University and also won a gold medal at the 2004 Olympics. He is currently the head coach at Penn State University, where he has won eight NCAA titles.
        </li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      These are just a few examples of the growth and evolution of wrestling in America, which have led to the current college wrestling scene, considered one of the most competitive and exciting sports in the country.
    </MKTypography>
  </MKBox>
</MKBox>




                                  








<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 2: College Wrestling Today
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College wrestling is one of the most popular and prestigious sports in America today. It attracts millions of fans and spectators who follow the exciting matches through television, the internet, or live in the arenas. College wrestling also offers numerous opportunities for student-athletes who can benefit from scholarships, academic support, and professional guidance.
    <br />
    <br />
    In this chapter, we will tell you more about the main competitions and conferences in college wrestling and the top programs and prestigious teams that dominate the sport.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Important Competitions and Conferences
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Two college wrestlers in an intense duel on the mat."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Two wrestlers in action
        </figcaption>
      </figure>
      College wrestling is organized by the NCAA, which has three divisions: Division I, Division II, and Division III. Each division has its own rules, criteria, and championships. The most competitive and well-known division is Division I, which consists of approximately 80 teams from different states.
      <br />
      <br />
      The teams in Division I are divided into various conferences, forming regional or thematic groups. The conferences organize their own competitions and championships, which serve as qualifications for the national championship. The most prominent conferences in college wrestling include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Big Ten Conference: The largest and strongest conference in college wrestling, consisting of 14 teams from the Midwest and Northeast regions of the country. The Big Ten has produced the most national champions, including Penn State, Iowa, Ohio State, Michigan, and Minnesota.
        </li>
        <li>
          Big 12 Conference: The second-largest and strongest conference in college wrestling, consisting of 12 teams from the South and West regions of the country. The Big 12 has also produced many national champions, including Oklahoma State, Oklahoma, Iowa State, and Missouri.
        </li>
        <li>
          Atlantic Coast Conference (ACC): An emerging conference in college wrestling, consisting of six teams from the Southeast region of the country. The ACC has gained more attention recently due to the performances of teams like Virginia Tech, North Carolina State, and North Carolina.
        </li>
        <li>
          Eastern Intercollegiate Wrestling Association (EIWA): A historic conference in college wrestling, consisting of 16 teams from the Eastern region of the country. The EIWA is the oldest wrestling conference in America, founded in 1905. The EIWA has also produced some national champions, including Cornell, Lehigh, Princeton, and Navy.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      These are just a few of the many conferences in college wrestling, each with its own rivalries, traditions, and fans. However, conferences are not the only factor that determines how successful a team is. There are other aspects that play a role in the success of a wrestling program.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Top Programs and Prestigious Teams
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A wrestling program is a term used to refer to the organization and structure of a university wrestling team. A wrestling program includes aspects such as the coach, staff, facilities, recruitment, training, funding, and the team's culture.
      <br />
      <br />
      A good wrestling program can make a significant difference in the development of student-athletes. A good wrestling program can provide student-athletes with:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          An experienced and qualified coach who can teach them how to improve their technique, tactics, conditioning, and mindset.
        </li>
        <li>
          A dedicated and professional staff that can support them in areas such as nutrition, injury prevention, recovery, academic guidance, and career planning.
        </li>
        <li>
          Modern and well-equipped facilities where they can train at a high level with suitable equipment and materials.
        </li>
        <li>
          An effective and ethical recruitment strategy that can help them attract and retain talented and motivated wrestlers.
        </li>
        <li>
          An intensive and varied training schedule that can challenge them and prepare them for different matches and situations.
        </li>
        <li>
          Adequate and stable funding that can help them cover the costs of wrestling, such as travel, equipment, scholarships, and other expenses.
        </li>
        <li>
          A positive and inspiring culture that can motivate and encourage them to reach their potential, pursue their goals, and share their passion for the sport.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      There are many universities in America that have excellent wrestling programs that have been successful in college wrestling for years. Some of these programs include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Penn State: The current top team in college wrestling, which has won the national championship eight times since 2011. Penn State is known for its aggressive and spectacular wrestling style, led by coach Cael Sanderson, one of the greatest wrestlers of all time.
        </li>
        <li>
          Iowa: The most successful team in the history of college wrestling, having won the national championship over 20 times since 1975. Iowa is known for its aggressive and dominant wrestling style, led by coach Tom Brands, a former Olympic champion.
        </li>
        <li>
          Oklahoma State: The second most successful team in the history of college wrestling, having won the national championship over 20 times. Oklahoma State is known for its technical and tactical wrestling style, led by coach John Smith, one of the greatest wrestlers of all time.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      These are just a few of the many top programs and prestigious teams in college wrestling, each with its own characteristics, achievements, and fans. However, it is not easy to be part of these programs and teams. It takes a lot of talent, hard work, and dedication to secure a wrestling scholarship.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  









<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 3: From College to Olympic Level and Professional Leagues
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College wrestling is not the endpoint for many student-athletes who dream of a career in the sport. It is rather a stepping stone to higher levels where they can compete with the best wrestlers in the world. In this chapter, we will tell you more about the path to the top in professional or Olympic wrestling and the successes and challenges of American wrestlers on the world stage.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 The Path to the Top
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      College wrestling is one of the most competitive and demanding sports in America. It requires a lot of talent, hard work, and dedication to secure a wrestling scholarship, and even more to shine in national competitions. But even if you are one of the best college wrestlers, it is not a guarantee of success at the professional or Olympic level.
      <br />
      <br />
      There are several factors that make it challenging to transition from college wrestling to professional or Olympic wrestling. Some of these factors include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Style Differences: College wrestling is practiced according to folkstyle rules, unique to America. These rules focus on controlling and dominating the opponent, with points awarded for riding, escaping, reversing, and near falls. Professional or Olympic wrestling, on the other hand, is practiced according to freestyle or Greco-Roman rules, used internationally. These rules emphasize scoring points through throws, tilts, exposures, and pins. This means that college wrestlers need to adapt their technique, tactics, and strategy to compete with wrestlers from other countries who have more experience with these styles.
        </li>
        <li>
          Age Differences: College wrestling is a sport for young athletes, usually between 18 and 23 years old. Professional or Olympic wrestling, however, is a sport for adult athletes, usually between 25 and 35 years old. This means that college wrestlers have to compete with older and more experienced wrestlers who have greater physical strength, mental maturity, and technical refinement.
        </li>
        <li>
          Financial Differences: College wrestling is a sport supported by universities, which provide student-athletes with scholarships, facilities, coaching, and other benefits. Professional or Olympic wrestling, however, receives little financial support from the government or sponsors. This means that college wrestlers need to seek other sources of income to cover their training, travel, equipment, and living expenses.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      These are just some of the many challenges that college wrestlers must overcome to pursue their dream of a professional or Olympic career. However, despite these challenges, there are also many successes and inspiring stories of American wrestlers who have made it to the highest level.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professional Wrestling and the Olympics
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Professional wrestling or Olympic wrestling is the pinnacle of the sport, where the best wrestlers in the world compete in prestigious competitions and events. There are two main forms of professional or Olympic wrestling: freestyle and Greco-Roman.
      <br />
      <br />
      Freestyle wrestling is a form of wrestling where all types of holds on the entire body are allowed, as long as they are not dangerous or inappropriate. Freestyle wrestling is practiced by both men and women, in various weight classes. Freestyle wrestling is one of the oldest sports in the Olympic Games, held since 1904 for men and since 2004 for women.
      <br />
      <br />
      Greco-Roman wrestling is a form of wrestling where only holds above the waist are allowed, and the use of the legs to grab or throw the opponent is prohibited. Greco-Roman wrestling is practiced only by men, in various weight classes. Greco-Roman wrestling is the oldest form of wrestling in the Olympic Games, held since 1896.
      <br />
      <br />
      American wrestlers have achieved many successes in both freestyle and Greco-Roman wrestling, at the Olympics as well as in other international competitions such as the World Championships, the Pan-American Games, or the World Cup. Some of the most successful and well-known American wrestlers include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Jordan Burroughs: A five-time world champion and Olympic champion in freestyle wrestling, known for his explosive and dynamic style. He won a gold medal at the 2012 Olympics in London and four world titles in 2011, 2013, 2015, and 2017. He also won two NCAA titles for the University of Nebraska.
        </li>
        <li>
          Kyle Snyder: A three-time world champion and Olympic champion in freestyle wrestling, known for his strength and technique. He won a gold medal at the 2016 Olympics in Rio de Janeiro and three world titles in 2015, 2017, and 2019. He also won three NCAA titles for Ohio State University.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      These are just some of the many successes and inspiring stories of American wrestlers at the professional or Olympic level. They show that it is possible to achieve your dream if you are willing to work hard, learn, and grow.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  







<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 4: International Influences and Competitions
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Wrestling is a universal sport, practiced and appreciated in many countries and cultures. Wrestling also has many styles and traditions that have originated and developed in different regions and periods. In this chapter, we will tell you more about the international influences and competitions in wrestling and how they influence and challenge American wrestlers.
  </MKTypography>
  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Foreign Wrestling Styles and Traditions
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Aerial view of an NCAA wrestling match in a packed stadium."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          A match in the NCAA tournament
        </figcaption>
      </figure>
      Wrestling is a sport that has been practiced since ancient times in various parts of the world. It is one of the oldest forms of physical expression, often associated with religious, cultural, or social meanings. There are many types of wrestling, each with its own characteristics, rules, and techniques. Some examples include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Sumo: A traditional Japanese wrestling style considered a national sport and a sacred ritual. Sumo is practiced by heavy and strong wrestlers who try to push each other out of a circular ring or throw them to the ground.
        </li>
        <li>
          Mongolian wrestling: A traditional Mongolian wrestling style considered a symbol of courage and strength. Mongolian wrestling is practiced by muscular and agile wrestlers who try to bring each other to the ground through holds on the upper body or legs.
        </li>
        <li>
          Turkish oil wrestling: A traditional Turkish wrestling style considered a heritage and an art. Turkish oil wrestling is practiced by oiled and muscular wrestlers who try to defeat each other through holds on the pants or belt.
        </li>
        <li>
          Senegalese wrestling: A traditional Senegalese wrestling style considered a popular and lucrative sport. Senegalese wrestling is practiced by athletic and colorful wrestlers who try to floor each other through throws, punches, or kicks.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      These are just a few of the many foreign wrestling styles and traditions, each with its own history, culture, and fans. These styles have also influenced American college wrestling by introducing new techniques, tactics, or challenges. Many American college wrestlers have traveled or trained in other countries to broaden their horizons and improve their skills.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Americans on the World Stage
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      American college wrestling is not only focused on national competitions but also on international competitions. There are many international events where American college wrestlers can participate to compete with the best wrestlers in the world. Some of these events include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          The Olympic Games: The largest and most prestigious event in professional or Olympic wrestling, held every four years. The Olympic Games are the ultimate goal for many college wrestlers who dream of winning a gold medal for their country.
        </li>
        <li>
          The World Championships: The second-largest and most prestigious event in professional or Olympic wrestling, held annually. The World Championships are a significant opportunity for college wrestlers to qualify for the Olympics or establish their reputation on the world stage.
        </li>
        <li>
          The World Cup: The second-largest and most prestigious event in regional wrestling, held annually. The World Cup is a significant opportunity for college wrestlers to be part of a national team that competes against other national teams in a team format.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      These are just a few of the many international events where American college wrestlers can participate to showcase their talents and pursue their dreams. However, these events are not only a source of honor and glory but also a source of challenge and difficulty. College wrestlers must compete with wrestlers from other countries who often have more experience, style, or motivation.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 5: Sports Scholarships and the Future of Athletes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sports scholarships offer numerous opportunities for young athletes to showcase their talent and develop their skills. One of the most significant opportunities is obtaining a sports scholarship to study and compete at the college level. In this chapter, we will discuss the importance of sports scholarships and how Sport Scholarships America can help you make your dream come true.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 The Importance of Scholarships
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A sports scholarship is financial support provided by a university to a student-athlete to combine studies and sports. It can cover the costs of tuition, books, housing, meals, and other expenses. Additionally, it may offer benefits such as academic tutoring, medical care, training facilities, and equipment.
      <br />
      <br />
      Obtaining a sports scholarship is competitive, with many factors playing a role in the awarding process, including academic achievements, athletic performance, personality, motivation, leadership, and adaptability. Therefore, it's essential to prepare well and distinguish yourself from the competition.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 How Sport Scholarships America Can Help
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo of Sport Scholarships America, featuring sports equipment and a graduation cap, representing the focus on sports scholarships for student-athletes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo of Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America is an organization specialized in guiding young sports talents to sports scholarships. With passionate and experienced experts, Sport Scholarships America supports student-athletes at every step of the journey, from initial orientation to final placement. Their services include:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>A personal intake interview to discuss the student-athlete's wishes, possibilities, and expectations.</li>
        <li>A professional evaluation of the student-athlete's athletic and academic level.</li>
        <li>An extensive network of contacts with coaches, universities, and colleges.</li>
        <li>Effective promotion of the student-athlete's profile and videos to potential coaches.</li>
        <li>Expert advice on the best options based on the student-athlete's preferences, goals, and budget.</li>
        <li>Negotiation of the best scholarship offers on behalf of the student-athlete.</li>
        <li>Assistance in applying for necessary documents, such as visa, transcript, diploma, and admission tests.</li>
        <li>Preparation for student-athletes' life during their scholarship, including culture, language, rules, and tips.</li>
        <li>Aftercare and follow-up during the scholarship period.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Want to explore scholarship opportunities and achieve your athletic and academic goals? <Link to="/Services-Products">View the full range</Link> of services offered by Sport Scholarships America and schedule a consultation today.
    </MKTypography>
  </MKBox>
</MKBox>

        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;