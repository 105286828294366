/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Página do Produto: https://www.creative-tim.com/product/material-kit-pro-react
* Direitos Autorais 2023 Creative Tim (https://www.creative-tim.com)

Codificado por www.creative-tim.com

=========================================================

* O aviso de direitos autorais acima e este aviso de permissão devem ser incluídos em todas as cópias ou partes substanciais do Software.
*/

import { useState } from "react";

// Componentes de material @mui
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Componentes do Material Kit 2 PRO React
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton"; // Importar MKButton
import { Link } from 'react-router-dom';

// Componentes da página de aluguel
import FaqCollapse from "Languages/Portugues/pages/Homepage/LandingPage/sections/FAQfunction";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <MKBox component="section" py={8}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
              Perguntas Frequentes
            </MKTypography>
            <MKBox mb={2}>
              <MKTypography variant="body2" align="center" color="text">
                Tudo o que você quer saber: respostas para as perguntas mais frequentes sobre estudar e praticar esportes na América e meus serviços. Se sua pergunta não estiver listada, entre em contato conosco.
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={10}>
          <FaqCollapse
   title="Quanto custa estudar na América?"
   open={collapse === 1}
   onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
>
   As mensalidades e os custos de estudar na América variam muito, dependendo da universidade, do programa de estudos e do local. Em média, os estudantes internacionais podem esperar uma despesa anual total de aproximadamente $30.000 a até $70.000 ou mais. No entanto, vale a pena notar que muitos estudantes podem cobrir uma parte significativa (ou às vezes a totalidade) desses custos por meio de bolsas esportivas, bolsas acadêmicas ou uma combinação de ambos. As universidades americanas frequentemente oferecem bolsas esportivas a atletas talentosos, fornecendo apoio financeiro em troca de sua participação em programas esportivos universitários. Isso permite que estudantes atletas busquem seus objetivos acadêmicos e esportivos sem grandes encargos financeiros.
</FaqCollapse>

<FaqCollapse
   title="Qual é a diferença entre faculdade e universidade na América?"
   open={collapse === 2}
   onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
>
   A terminologia pode ser confusa, especialmente para pessoas fora dos Estados Unidos. Na prática, "faculdade" e "universidade" não se referem realmente à mesma coisa. Uma "universidade" é um termo abrangente para uma instituição de ensino superior que oferece programas em todos os níveis, incluindo graduação, mestrado e doutorado.

   Uma "faculdade", por outro lado, geralmente faz parte de uma universidade e se concentra principalmente em programas de graduação, como bacharelado. Em outras palavras, uma faculdade é um departamento dentro de uma universidade especificamente dedicado ao ensino de graduação. As universidades normalmente oferecem tanto programas de graduação quanto de pós-graduação, enquanto as faculdades se concentram no nível de graduação.

   No entanto, essa distinção pode variar entre diferentes instituições e, às vezes, os termos são usados ​​de forma intercambiável. Portanto, é útil examinar os detalhes e programas específicos de uma instituição de ensino para entender o que eles oferecem.
</FaqCollapse>

<FaqCollapse
   title="Quais atletas são orientados pela Sport Scholarships America?"
   open={collapse === 3}
   onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
>
   Eu guio atletas de uma ampla variedade de esportes. Minha ampla rede e parcerias na América me permitem apoiar atletas em várias disciplinas. Portanto, se você joga futebol, basquete, tênis, golfe ou qualquer outro esporte, estou pronto para ajudá-lo a buscar seus objetivos acadêmicos e esportivos nos Estados Unidos. Para uma lista completa de esportes, <Link to="/pt/Todos-os-esportes" style={{ fontWeight: 'bold' }}>clique aqui</Link>.
</FaqCollapse>

<FaqCollapse
   title="Qual é o processo de inscrição típico e com quanto tempo de antecedência devo começar?"
   open={collapse === 4}
   onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
>
   O processo geralmente começa com uma reunião introdutória abrangente, onde discutimos todos os detalhes e entendemos sua situação e objetivos específicos. Também vou explicar minha abordagem com mais detalhes. A duração de todo o processo pode variar, mas geralmente, recomendo começar pelo menos 6 a 12 meses antes. É importante enfatizar que quanto mais cedo você começar, mais oportunidades podem surgir, então não hesite em iniciar o processo cedo.

   Durante este período, colaboraremos para criar um plano personalizado que leve em consideração todos os aspectos, como testes de admissão, solicitações de visto, criação de vídeos esportivos e muito mais. Cada jornada é única e feita sob medida, pois existem muitos fatores a serem considerados. Para uma visão geral detalhada de todo o processo, você pode <Link to="/pt/Processo" style={{ fontWeight: 'bold' }}>clicar aqui</Link>. Isso fornece uma visão passo a passo do que esperar durante sua jornada de estudar e competir nos Estados Unidos.
</FaqCollapse>

<FaqCollapse
   title="Quais são as taxas pelos serviços que você oferece?"
   open={collapse === 5}
   onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
>
   Os custos dos meus serviços variam dependendo de suas necessidades e objetivos específicos. A conversa inicial é uma etapa importante em que discutimos sua situação e desenvolvemos um plano personalizado. Também é a oportunidade de determinar se desejamos continuar trabalhando juntos.

   Após esta conversa, poderei ter uma compreensão clara de seus desejos e necessidades e, com base nisso, poderei fornecer uma estimativa de custo apropriada.

   Para uma visão geral completa dos serviços e produtos que ofereço, você pode <Link to="/pt/Serviços-Produtos" style={{ fontWeight: 'bold' }}>clicar aqui</Link>. Aqui você encontrará informações detalhadas sobre como posso ajudá-lo em sua busca por estudar e competir nos Estados Unidos.
</FaqCollapse>

<FaqCollapse
   title="Por que devo escolher ser orientado pela Sport Scholarships America?"
   open={collapse === 6}
   onClick={() => (collapse === 6 ? setCollapse(false) : setCollapse(6))}
>
   Quando você está em uma encruzilhada na realização de seus sonhos, como eu já estive, muitas perguntas e dúvidas podem surgir. A escolha de fazer você mesmo, colaborar com uma agência ou até mesmo desistir de toda a ideia pode ser avassaladora. Eu entendo profundamente essa incerteza porque passei por isso.

   Seria uma pena se essas dúvidas impedissem alguém de buscar o que realmente é possível - uma experiência fantástica esperando para ser descoberta. É por isso que sou dedicado a ajudar outras pessoas a realizar seus sonhos.

   Coloco minhas experiências pessoais em prática, envolvendo-me diretamente em cada projeto. Entendo as perguntas que você pode ter e me esforço para fornecer uma abordagem personalizada que esteja alinhada com suas necessidades únicas. Juntos, podemos dar vida à sua visão, com base no conhecimento e compreensão que vêm de uma experiência real.
</FaqCollapse>

          </Grid>
        </Grid>
        {/* Seção de Botões */}
        <Grid container justifyContent="center" mt={4}>
          <Grid item xs={12} md={3}>
          <MKButton variant="gradient" color="light" fullWidth component={Link} to="/pt/FAQs">
    Ver todas as perguntas frequentes
</MKButton>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Faq;
