/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Hockey1.jpg";
import Image2 from "assets/images/Sports_Images/Hockey2.jpeg";
import Image3 from "assets/images/Sports_Images/Hockey3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");




  
  
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Bolsas de Estudo de Hóquei em Campo na América: Como o Hóquei em Campo Pode Ajudá-lo a Alcançar Seus Sonhos', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capítulo 1: A História e o Desenvolvimento do Hóquei em Campo na América', isSubheader: false },
    { id: '1.1', title: '1.1 História Inicial', isSubheader: true },
    { id: '1.2', title: '1.2 Desenvolvimento e Profissionalização', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capítulo 2: Hóquei em Campo no Nível Universitário', isSubheader: false },
    { id: '2.1', title: '2.1 Competições e Conferências', isSubheader: true },
    { id: '2.2', title: '2.2 Impacto no Desenvolvimento do Jogador', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capítulo 3: Transição do Universitário para o Profissional', isSubheader: false },
    { id: '3.1', title: '3.1 Da Faculdade para o Profissionalismo', isSubheader: true },
    { id: '3.2', title: '3.2 Oportunidades Profissionais e Ligas', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capítulo 4: Influências e Oportunidades Internacionais', isSubheader: false },
    { id: '4.1', title: '4.1 Influências Globais', isSubheader: true },
    { id: '4.2', title: '4.2 Americanos no Palco Mundial', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 A Importância das Bolsas de Estudo', isSubheader: true },
    { id: '5.2', title: '5.2 Como a Sport Scholarships America Pode Ajudar', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  const [,] = useState("");


  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Conteúdo
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Esconder</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Bolsas de Estudo de Hóquei em Campo na América: Como o Hóquei em Campo Pode Ajudá-lo a Alcançar Seus Sonhos
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Jogador em ação durante um jogo de hóquei em campo universitário da Colgate."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Jogador em ação durante um jogo
      </figcaption>
    </figure>

    O hóquei em campo é um dos esportes de crescimento mais rápido na América. Cada vez mais jovens atletas estão descobrindo os benefícios e o prazer deste esporte dinâmico e desafiador. Mas você sabia que o hóquei em campo também pode ajudá-lo a alcançar seus objetivos acadêmicos e profissionais?
    <br />
    <br />

    Se você tem paixão pelo hóquei em campo, você tem uma oportunidade única de jogar no nível universitário na América. Isso significa que você pode estudar em uma das melhores universidades do mundo enquanto desenvolve suas habilidades atléticas sob orientação profissional. Além disso, você pode se qualificar para uma bolsa de esportes, fornecendo apoio financeiro para sua educação.

    <br />
    <br />
    Mas como você consegue essa bolsa? E como você se prepara para jogar hóquei em campo na América? É aí que o Sportbeursamerika.nl pode ajudar você. O Sportbeursamerika.nl é uma agência especializada que ajuda jovens talentos a realizar o sonho de estudar e jogar hóquei na América. Ofereço orientação pessoal, conselhos e suporte na busca pela universidade certa, inscrição para uma bolsa, lidando com todos os assuntos práticos e integração na cultura americana.
    <br />
    <br />
    Neste artigo, vou contar mais sobre a história e o desenvolvimento do hóquei em campo na América, as oportunidades e benefícios de jogar no nível universitário, a transição do universitário para o jogo profissional, as influências e oportunidades internacionais dentro do esporte e, é claro, a importância das bolsas e o papel do Sportbeursamerika.nl. Depois de ler este artigo, você terá uma melhor compreensão do que o hóquei em campo na América envolve e como se preparar para este desafio emocionante.
    <br />
    <br />
    Está pronto para aprender mais sobre o hóquei em campo na América? Continue lendo!
  </MKTypography>
  {/* Adicione uma lista de marcadores ou outro conteúdo, conforme necessário */}
</MKBox>
{/* Principais capítulos e subcapítulos com texto de espaço reservado */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 1: A História e o Desenvolvimento do Hóquei em Campo na América
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O hóquei em campo é um esporte com uma história longa e rica. Mas como ele se originou e cresceu na América? Neste capítulo, levaremos você a uma viagem no tempo, desde a introdução inicial até a atual profissionalização do esporte.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 História Inicial
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O hóquei em campo foi introduzido nos EUA no final do século XIX por imigrantes britânicos e turistas. O jogo rapidamente ganhou popularidade entre as mulheres, que o viam como uma forma de exercício e socialização. As primeiras equipes universitárias foram formadas em 1901 no Vassar College em Nova York e no Bryn Mawr College na Pensilvânia. Essas equipes principalmente jogavam entre si ou contra clubes locais.
      <br />
      <br />
      Em 1922, a United States Field Hockey Association (USFHA) foi estabelecida como a organização nacional para o esporte. A USFHA organizou os primeiros campeonatos nacionais para mulheres em 1925 e para homens em 1930. A USFHA também era responsável por selecionar e treinar equipes nacionais que competiam em torneios internacionais como as Olimpíadas e os Campeonatos Mundiais.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Desenvolvimento e Profissionalização
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Na segunda metade do século XX, o hóquei em campo continuou a crescer em popularidade e qualidade na América. O jogo se espalhou ainda mais pelo país, especialmente no Nordeste, Meio-Oeste e Oeste. O número de equipes universitárias aumentou, e mais ligas e conferências foram formadas. Alguns dos programas mais prestigiados e competitivos incluem a Universidade de Princeton, Universidade de Maryland, Universidade da Carolina do Norte, Universidade de Stanford e Universidade Harvard.
      <br />
      <br />
      O nível do hóquei universitário também aumentou graças a instalações, treinadores e recrutamento aprimorados. Muitas universidades agora oferecem bolsas esportivas para jogadores talentosos de hóquei em campo, permitindo que estudem sem preocupações financeiras. Além disso, o hóquei em campo universitário serve como um terreno fértil para o hóquei em campo profissional, que também está se tornando mais desenvolvido e profissionalizado.
      <br />
      <br />
      O hóquei em campo profissional também influenciou a equipe nacional, que vem se saindo melhor no cenário internacional. Por exemplo, a equipe feminina dos EUA ganhou bronze duas vezes nas Olimpíadas (em 1984 e 1996) e prata duas vezes nos Jogos Pan-Americanos (em 2011 e 2015). A equipe masculina dos EUA também participou de várias Olimpíadas e Campeonatos Mundiais, mas ainda não conquistou nenhuma medalha.
      <br />
      <br />
      Como você pode ver, o hóquei em campo percorreu um longo caminho na América, de uma atividade recreativa a um esporte profissional. Mas o que é necessário para jogar hóquei em campo no nível universitário? Vamos contar a você no próximo capítulo.
    </MKTypography>
  </MKBox>
</MKBox>












                                              





                                  





<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 2: Hóquei em Campo no Nível Universitário
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Jogar hóquei em campo no nível universitário é uma das experiências mais desafiadoras e recompensadoras para um jovem atleta. Oferece a oportunidade de combinar seu talento atlético com suas ambições acadêmicas e fazer parte de uma comunidade unida e diversificada. Neste capítulo, vamos contar mais sobre as competições e conferências, o impacto no desenvolvimento dos jogadores e os benefícios de jogar hóquei em campo no nível universitário.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Competições e Conferências
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Visão aérea do estádio de hóquei em campo da Boston College"
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          O estádio do Boston College
        </figcaption>
      </figure>
      O hóquei em campo no nível universitário é regulamentado pela National Collegiate Athletic Association (NCAA), que divide o esporte em três divisões: Divisão I, Divisão II e Divisão III. Cada divisão tem suas próprias regras, requisitos e bolsas de estudo. Geralmente, a Divisão I é o nível de competição mais alto, apresentando as melhores equipes e jogadores, e oferecendo a maioria das bolsas de estudo. As Divisões II e III são menos competitivas, mas ainda oferecem amplas oportunidades para jogadores talentosos.
      <br />
      <br />
      Dentro de cada divisão, existem várias conferências, consistindo de universidades que estão geograficamente ou historicamente conectadas. Cada conferência tem seu próprio cronograma, classificações e campeonato. Algumas das conferências mais conhecidas incluem a Big Ten Conference, a Atlantic Coast Conference, a Ivy League, a Patriot League e a America East Conference.
      <br />
      <br />
      As principais equipes e jogadores de cada conferência podem se classificar para o campeonato nacional, realizado anualmente em novembro. O campeonato nacional envolve um torneio eliminatório, com o vencedor coroado como campeão nacional.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Impacto no Desenvolvimento do Jogador
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Jogar hóquei em campo no nível universitário tem um impacto significativo no desenvolvimento do jogador. Isso permite que os jogadores aprimorem suas habilidades sob treinamento profissional, treinem com instalações e equipamentos de ponta, compitam contra outros atletas de elite e aprendam com seus colegas de equipe e oponentes.
      <br />
      <br />
      Além disso, jogar hóquei em campo no nível universitário também oferece muitos benefícios pessoais aos jogadores. Isso ajuda os jogadores a aumentar sua confiança, disciplina, liderança, trabalho em equipe, comunicação e habilidades de resolução de problemas. Também auxilia os jogadores a melhorar sua gestão do tempo, hábitos de estudo, gestão do estresse e bem-estar geral. Além disso, expõe os jogadores a diferentes culturas, perspectivas e experiências, ampliando seus horizontes.
      <br />
      <br />
      A história das jogadoras de hóquei holandesas recebendo bolsas para jogar no nível universitário na América serve como exemplo de como essa experiência pode aprimorar o desenvolvimento delas como jogadoras. Muitas jogaram por equipes universitárias de prestígio nos Estados Unidos, onde não apenas ganharam campeonatos e títulos individuais, mas também aprenderam lições valiosas.
      <br />
      <br />
      A experiência na América proporciona a essas atletas uma oportunidade única de se imergirem em uma cultura diferente, desfrutar de novos ambientes sociais e experimentar um sistema educacional diferente. A interação com treinadores que as desafiam e apoiam contribui para refinar suas habilidades e expandir sua compreensão tática do jogo.
      <br />
      <br />
      Ao retornar à Holanda, essas jogadoras de hóquei usaram seus conhecimentos e experiências adquiridos para se destacar no nível profissional e, em alguns casos, assumir funções de liderança dentro da equipe nacional. O tempo delas na América muitas vezes as preparou para a pressão e expectativas que acompanham as competições internacionais, formando uma base para seu sucesso futuro.
      <br />
      <br />
      Como você pode ver, jogar hóquei em campo no nível universitário pode oferecer muitas vantagens, tanto atlética quanto pessoalmente. Mas como você faz a transição do universitário para o jogo profissional? Vamos explicar isso no próximo capítulo.
    </MKTypography>
  </MKBox>
</MKBox>





                                  








<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 3: Transição do Universitário para o Profissional
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O hóquei em campo no nível universitário é uma ótima maneira de liberar seu potencial atlético, mas não precisa ser o fim da sua carreira. Se você tiver ambição e paixão para ir mais longe, pode fazer a transição para o jogo profissional. Neste capítulo, vamos contar mais sobre como ir da faculdade para o profissionalismo e quais são as oportunidades profissionais e ligas existentes para jogadores de hóquei em campo.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Da Faculdade para o Profissionalismo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Fazer a transição da universidade para o jogo profissional não é fácil, mas também não é impossível. Isso requer muita dedicação, trabalho árduo, talento e um pouco de sorte. O aspecto mais crucial é mostrar seu potencial para as pessoas certas que podem ajudá-lo a alcançar seu sonho.
      <br />
      <br />
      Uma maneira de se mostrar é participando de testes. Os testes são sessões de treinamento ou partidas abertas onde as equipes profissionais podem observar e avaliar os jogadores. Os testes geralmente são realizados antes ou depois da temporada e podem ser anunciados por meio de sites, mídias sociais ou boca a boca. Os testes podem ser uma excelente oportunidade para mostrar suas habilidades e receber feedback de treinadores e gerentes. Os testes podem ser conduzidos por equipes profissionais em todo o mundo.
      <br />
      <br />
      Outra maneira de se tornar conhecido é aproveitando sua rede de contatos. Sua rede de contatos inclui todas as pessoas que você conhece ou encontra que estão conectadas ao hóquei em campo, incluindo treinadores, colegas de equipe, oponentes, amigos, familiares, professores, ex-alunos, etc. Sua rede de contatos pode ajudá-lo a se manter informado sobre as últimas notícias, oportunidades e dicas no mundo do hóquei em campo. Também pode ajudá-lo a se conectar com pessoas que têm influência ou conexões na cena profissional.
      <br />
      <br />
      Além disso, muitos jogadores europeus de hóquei retornam à Europa para tentar a sorte lá. Como você pode ver, existem várias maneiras de ser notado por equipes profissionais. Mas que tipos de equipes existem e que tipos de ligas existem? Vamos explicar isso na próxima seção.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Oportunidades Profissionais e Ligas
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Para um jogador de hóquei em campo que passou pelo sistema universitário americano, as portas para uma carreira profissional muitas vezes estão escancaradas. Essa experiência é mais do que apenas um trampolim; é uma preparação abrangente que os desenvolve em muitos aspectos - fisicamente, taticamente e mentalmente.
      <br />
      <br />
      Um jogador que se destaca no hóquei universitário pode chamar a atenção de olheiros e equipes profissionais, não apenas por seu desempenho em campo, mas também pela disciplina e versatilidade que cultivaram ao estudar e jogar simultaneamente. As ligas profissionais de hóquei em campo em todo o mundo, como as da Holanda, Bélgica, Alemanha e Austrália, são conhecidas por recrutar jogadores talentosos com uma sólida formação no hóquei universitário.
      <br />
      <br />
      A natureza competitiva do hóquei universitário na América também garante que os jogadores estejam acostumados a um alto nível de treinamento e preparação física, o que é essencial para o jogo profissional. Além disso, eles frequentemente têm experiência em jogar em partidas e campeonatos importantes, ajudando-os a lidar com a pressão das competições profissionais.
      <br />
      <br />
      Além disso, a experiência internacional e a exposição a diferentes estilos de jogo na América proporcionam a esses jogadores uma perspectiva única que podem levar para suas carreiras profissionais. Isso os ajuda a se adaptar rapidamente a novas equipes e estratégias e fornece uma compreensão mais ampla do jogo.
      <br />
      <br />
      As equipes profissionais valorizam a combinação de disciplina acadêmica e excelência atlética que um ex-jogador universitário traz. Esses jogadores muitas vezes estão bem preparados para enfrentar os desafios da vida profissional, incluindo o equilíbrio entre o esporte e outros compromissos da vida.
      <br />
      <br />
      Como você pode ver, existem muitas oportunidades profissionais e ligas para jogadores de hóquei em campo na América. Mas e o resto do mundo? Como o hóquei em campo na América se compara ao hóquei em campo internacional? Vamos explicar isso no próximo capítulo.
    </MKTypography>
  </MKBox>
</MKBox>






                                  








<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 4: Influências e Oportunidades Internacionais
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O hóquei em campo na América não está isolado, mas faz parte de um mundo de hóquei em campo maior e mais diversificado. O hóquei em campo é um esporte global jogado em mais de 100 países em todos os continentes. O hóquei em campo na América é influenciado por e influencia o hóquei em campo internacional. Neste capítulo, vamos contar mais sobre influências globais, americanos no palco mundial e oportunidades internacionais para jogadores de hóquei em campo.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Influências Globais
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O hóquei em campo na América é influenciado pelo hóquei em campo praticado em outros países, especialmente na Europa e na Ásia. Esses países têm uma tradição mais longa e rica de hóquei em campo e muitas vezes têm um nível de jogo e organização mais elevado. Observando e aprendendo com esses países, o hóquei em campo na América pode se desenvolver e melhorar ainda mais.
      <br />
      <br />
      Uma forma pela qual o hóquei em campo na América é influenciado pelo hóquei em campo internacional é pela adoção de diferentes estilos e táticas. Por exemplo, o hóquei em campo holandês é conhecido por seu jogo rápido e ofensivo, com foco na posse de bola, combinações e tentativas de gol. O hóquei em campo alemão é conhecido por seu estilo físico e defensivo, apresentando pressão alta, duelos e contra-ataques. O hóquei em campo indiano é conhecido por seu jogo técnico e criativo, com foco em dribles, passes e truques.
      <br />
      <br />
      Ao aprender com esses estilos e táticas, o hóquei em campo na América pode diversificar seu próprio jogo e se adaptar a diferentes situações, adicionando mais variedade, flexibilidade e eficácia.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Americanos no Palco Mundial
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O hóquei em campo na América também impacta o hóquei em campo internacional ao fornecer jogadores que participam dos maiores e mais significativos torneios mundiais. Esses jogadores não apenas representam seu país, mas também sua universidade, equipe e esporte. Através de suas performances, eles demonstram o que o hóquei em campo na América tem a oferecer e inspiram outros a participar deste esporte.
      <br />
      <br />
      Um exemplo primordial de como os jogadores americanos entram no palco mundial é a história de Lauren Crandall. Lauren é uma jogadora de hóquei em campo americana que recebeu uma bolsa de estudos para jogar pela Universidade Wake Forest. Ela jogou pela equipe por quatro temporadas, ganhando o título da Divisão I da NCAA duas vezes.
      <br />
      <br />
      Lauren também fez parte da seleção nacional americana, participando de três Jogos Olímpicos (em 2008, 2012 e 2016), dois Campeonatos Mundiais (em 2010 e 2014) e quatro Jogos Pan-Americanos (em 2007, 2011, 2015 e 2019). Ela também foi capitã da equipe de 2013 a 2016.
      <br />
      <br />
      Lauren afirma que seu tempo na América a ajudou significativamente a realizar seu sonho de jogar no mais alto nível. Ela aprendeu muito com sua treinadora, Jennifer Averill, que a apoiou e motivou. Ela também gostou da cultura, das pessoas e da educação na América. Ela se orgulha de representar seu país e de ser um modelo para jovens jogadores.
      <br />
      <br />
      Como você pode ver, o hóquei em campo na América pode oferecer muitas influências e oportunidades internacionais, tanto como jogador quanto como indivíduo. Mas como você pode aproveitar essas oportunidades? E como você pode obter uma bolsa de estudos para estudar e jogar na América? Vamos contar na próxima seção.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    As bolsas de estudo esportivas oferecem inúmeras oportunidades para jovens atletas mostrarem seu talento e desenvolverem suas habilidades. Uma das oportunidades mais significativas é obter uma bolsa de estudos esportiva para estudar e competir no nível universitário. Neste capítulo, discutiremos a importância das bolsas de estudo esportivas e como a Sport Scholarships America pode ajudá-lo a realizar seu sonho.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 A Importância das Bolsas de Estudo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Uma bolsa de estudos esportiva é um suporte financeiro fornecido por uma universidade a um estudante-atleta para combinar estudos e esportes. Pode cobrir os custos de mensalidades, livros, moradia, refeições e outras despesas. Além disso, pode oferecer benefícios como tutoria acadêmica, cuidados médicos, instalações de treinamento e equipamentos.
      <br />
      <br />
      Obter uma bolsa de estudos esportiva é competitivo, com muitos fatores desempenhando um papel no processo de concessão, incluindo realizações acadêmicas, desempenho atlético, personalidade, motivação, liderança e adaptabilidade. Portanto, é importante se preparar bem e se destacar da concorrência.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Como a Sport Scholarships America Pode Ajudar
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo da Sport Scholarships America, mostrando equipamentos esportivos e um capelo de formatura, representando o foco em bolsas de estudo esportivas para estudantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo da Sport Scholarships America
        </figcaption>
      </figure>
      A Sport Scholarships America é uma organização especializada em orientar jovens talentos esportivos para bolsas de estudo esportivas. Com especialistas apaixonados e experientes, a Sport Scholarships America apoia estudantes-atletas em cada etapa da jornada, desde a orientação inicial até a colocação final. Seus serviços incluem:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Uma entrevista de admissão pessoal para discutir os desejos, possibilidades e expectativas do estudante-atleta.</li>
        <li>Uma avaliação profissional do nível acadêmico e atlético do estudante-atleta.</li>
        <li>Uma extensa rede de contatos com treinadores, universidades e faculdades.</li>
        <li>Promoção eficaz do perfil e vídeos do estudante-atleta para treinadores potenciais.</li>
        <li>Conselhos especializados sobre as melhores opções com base nas preferências, metas e orçamento do estudante-atleta.</li>
        <li>Negociação das melhores ofertas de bolsas de estudo em nome do estudante-atleta.</li>
        <li>Auxílio na solicitação de documentos necessários, como visto, histórico escolar, diploma e testes de admissão.</li>
        <li>Preparação para a vida do estudante-atleta durante a bolsa de estudos, incluindo cultura, idioma, regras e dicas.</li>
        <li>Acompanhamento e acompanhamento durante o período da bolsa de estudos.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Quer explorar oportunidades de bolsas de estudo e alcançar seus objetivos atléticos e acadêmicos? <Link to="/pt/Serviços-Produtos">Veja a gama completa</Link> de serviços oferecidos pela Sport Scholarships America e agende uma consulta hoje.
    </MKTypography>
  </MKBox>
</MKBox>



          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;