/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";



// Images
import Image1 from "assets/images/Sports_Images/Soccer1.jpg";
import Image2 from "assets/images/Sports_Images/Soccer2.jpg";
import Image3 from "assets/images/Sports_Images/Soccer3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Fußball spielen und in Amerika studieren: Ein Traum wird wahr', isSubheader: false },
  
    { id: 'Kapitel-1', title: 'Kapitel 1: Die Geschichte und Entwicklung des Fußballs in Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Frühe Jahre und die Rolle der Universitäten', isSubheader: true },
    { id: '1.2', title: '1.2 Wachstum und Professionalisierung', isSubheader: true },
  
    { id: 'Kapitel-2', title: 'Kapitel 2: Der College-Fußball heute', isSubheader: false },
    { id: '2.1', title: '2.1 Wichtige Wettbewerbe und Konferenzen für Fußball', isSubheader: true },
    { id: '2.2', title: '2.2 Top-Programme und angesehene Teams für Fußball', isSubheader: true },
  
    { id: 'Kapitel-3', title: 'Kapitel 3: Vom College zum Profi', isSubheader: false },
    { id: '3.1', title: '3.1 Der Weg zum Profi', isSubheader: true },
  
    { id: 'Kapitel-4', title: 'Kapitel 4: Der Einfluss des internationalen Fußballs', isSubheader: false },
    { id: '4.1', title: '4.1 Ausländische Einflüsse', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikaner im Ausland', isSubheader: true },
  
    { id: 'Kapitel-5', title: 'Kapitel 5: Sportstipendien und die Zukunft der Athleten', isSubheader: false },
    { id: '5.1', title: '5.1 Die Bedeutung von Stipendien', isSubheader: true },
    { id: '5.2', title: '5.2 Wie Sportstipendien Amerika helfen können', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhaltsverzeichnis
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verstecken</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Anzeigen</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























{/* Einleitung */}
<MKBox id="Einführung" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Fußball spielen und in Amerika studieren: Ein Traum wird wahr
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Das Stadion von Creighton, mit Platz für über 7000 Zuschauer, während eines College-Fußballspiels."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Creighton Stadion: Heimat von mehr als 7000 Fußballfans.
      </figcaption>
    </figure>
    Fußball ist eine der beliebtesten Sportarten der Welt, aber in Amerika wurde er lange Zeit von anderen Sportarten wie Basketball, Baseball und American Football überschattet. In den letzten Jahrzehnten hat Fußball jedoch in den USA enorm an Popularität und Qualität gewonnen, nicht zuletzt dank des Erfolgs der Nationalmannschaft, des Aufstiegs der Major League Soccer (MLS) und des Einflusses internationaler Stars.
    <br />
    <br />
    Wussten Sie jedoch, dass Fußball in Amerika auch eine einzigartige Gelegenheit für junge Talente bietet, ihre Träume zu verwirklichen? Indem Sie Fußball spielen und in Amerika studieren, können Sie nicht nur Ihre sportlichen Fähigkeiten entwickeln, sondern auch einen akademischen Abschluss erwerben und eine unvergessliche Erfahrung machen. All dies ist dank der Fußballstipendien möglich, die amerikanische Universitäten talentierten Spielern anbieten.
    <br />
    <br />
    In diesem Artikel werden wir Ihnen alles erklären, was Sie über das Fußballspielen und Studieren in Amerika wissen müssen, von der Geschichte und Entwicklung des Fußballs in den USA bis hin zu den Vorteilen und Möglichkeiten eines Fußballstipendiums. Wir werden auch erklären, wie Sportbeursamerika.nl Ihnen helfen kann, diesen Traum wahr werden zu lassen. Also, lesen Sie weiter und entdecken Sie, wie Sie diese einzigartige Gelegenheit nutzen können!
  </MKTypography>
</MKBox>


{/* Kapitel 1 */}
<MKBox id="Kapitel-1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Die Geschichte und Entwicklung des Fußballs in Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Fußball, oder wie die Amerikaner ihn nennen, Soccer, ist in den USA nicht so neu wie man denken könnte. Tatsächlich fand das erste offizielle Fußballspiel in Amerika 1869 zwischen zwei Universitätsteams statt: Princeton und Rutgers. Seitdem hat Fußball in den USA eine lange und faszinierende Geschichte, über die wir in diesem Kapitel sprechen werden.
  </MKTypography>
  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Frühe Jahre und die Rolle der Universitäten
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ende des 19. und Anfang des 20. Jahrhunderts war Fußball besonders unter Studenten beliebt, die das Spiel nach verschiedenen Regeln und Formaten spielten. Zum Beispiel gab es Teams, die mit 11, 15 oder sogar 25 Spielern spielten und manchmal Elemente von Rugby oder American Football einbezogen.
      <br />
      <br />
      Um mehr Einheit und Struktur zu schaffen, wurde 1905 die Intercollegiate Association of Amateur Athletes of America (IAAAA) gegründet, der Vorläufer der National Collegiate Athletic Association (NCAA). Die NCAA ist die Hauptorganisation, die den College-Fußball in den USA reguliert und organisiert.
      <br />
      <br />
      In der ersten Hälfte des 20. Jahrhunderts wuchs die Anzahl der College-Fußballteams stetig, und es entstanden auch regionale Wettbewerbe und Meisterschaften. Eine der bekanntesten davon ist der College Cup, der seit 1959 als nationale Meisterschaft der Männer ausgetragen wird. Für Frauen fand der erste College Cup erst 1982 statt, aber seitdem hat der Frauenfußball in den USA enormen Zuwachs verzeichnet, auch dank des Erfolgs der Nationalmannschaft bei internationalen Turnieren.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Wachstum und Professionalisierung
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Neben dem College-Fußball entwickelte sich auch der Profifußball in den USA, wenn auch mit mehr Höhen und Tiefen. Die erste professionelle Liga war die American Soccer League (ASL), die 1921 gegründet wurde und hauptsächlich aus Teams aus dem Nordosten bestand. Die ASL hatte einige erfolgreiche Jahre, geriet jedoch mit dem amerikanischen Fußballverband über die Organisation und Regulierung des Spiels in Konflikt. Dies führte zu einer Spaltung und letztendlich zum Ende der ASL im Jahr 1933.
      <br />
      <br />
      Nach dem Zweiten Weltkrieg versuchten verschiedene Ligen, den Profifußball wiederzubeleben, aber keine von ihnen überlebte lange. Erst 1967 wurde eine stabile Liga etabliert: die North American Soccer League (NASL). Die NASL brachte einige große Namen nach Amerika, darunter Pelé, Franz Beckenbauer und Johan Cruyff, und lockte große Menschenmengen in die Stadien. Die NASL konnte jedoch auch finanzielle Probleme, Konkurrenz aus anderen Sportarten und ein mangelndes Medieninteresse nicht überwinden. Die NASL hörte 1984 auf zu existieren.
      <br />
      <br />
      Es dauerte bis 1996, bis wieder eine professionelle Liga etabliert wurde: die Major League Soccer (MLS). Die MLS ist derzeit die höchste Liga im amerikanischen Fußball und besteht aus 27 Teams sowohl aus den USA als auch aus Kanada. Die MLS hat sich zu einer wettbewerbsfähigen und attraktiven Liga entwickelt, die sowohl inländische als auch internationale Spieler anzieht. Die MLS hat auch zur Popularisierung des Fußballs in Amerika beigetragen, indem sie Stadien gebaut, die Jugendentwicklung unterstützt und mit anderen Ligen zusammengearbeitet hat.
      <br />
      <br />
      Der Profifußball hat auch den College-Fußball beeinflusst, und umgekehrt. Zum Beispiel gibt es Spieler, die nach ihrer College-Karriere in die MLS wechseln, oder Spieler, die nach der MLS an die Universität zurückkehren, um ihr Studium abzuschließen. Es gibt auch Spieler, die sowohl für ihr College als auch für ein Profiteam spielen, dank spezieller Vereinbarungen. Darüber hinaus arbeiten einige Profiteams mit College-Programmen zusammen, um Talente zu scouten und zu entwickeln.
    </MKTypography>
  </MKBox>
</MKBox>

                                              





                                  






{/* Kapitel 2 */}
<MKBox id="Kapitel-2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Der College-Fußball heute
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Wie wir im vorherigen Kapitel gesehen haben, hat der College-Fußball in Amerika eine lange und reiche Tradition. Doch wie sieht er heute aus? Welche Wettbewerbe und Konferenzen gibt es? Welche Programme und Teams sind die angesehensten und erfolgreichsten? Und wie kannst du als Spieler in diese aufregende Welt einsteigen? In diesem Kapitel geben wir dir einen Überblick über den College-Fußball heute und zeigen dir, was dich erwartet, wenn du dich für ein Fußballstipendium in Amerika entscheidest.
  </MKTypography>
  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Wichtige Wettbewerbe und Konferenzen für Fußball
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Santa Clara Fußballspieler in Aktion während des NCAA-Turniers."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Fußballspieler in Aktion während eines NCAA-Turnierspiels.
        </figcaption>
      </figure>
      Wie bereits erwähnt, ist die NCAA die Hauptorganisation, die den College-Fußball in den USA regelt und organisiert. Die NCAA besteht aus drei Divisionen: Division I, Division II und Division III. Jede Division hat ihre eigenen Regeln, Anforderungen und Stipendien für Studenten-Athleten.
      <br />
      <br />
      Division I ist die höchste und wettbewerbsfähigste Division, in der die besten Spieler und Teams antreten. Division I besteht aus etwa 200 Männer- und 300 Frauenmannschaften, die in verschiedene regionale Konferenzen unterteilt sind. Eine Konferenz ist eine Gruppe von Universitäten, die zusammenarbeiten, um einen Wettbewerb in ihrer Region zu organisieren. Einige Beispiele für bekannte Konferenzen sind die Atlantic Coast Conference (ACC), die Big Ten Conference, die Pac-12 Conference und die Southeastern Conference (SEC).
      <br />
      <br />
      Jedes Jahr kämpfen Teams innerhalb ihrer Konferenz um den Titel und einen Platz im nationalen Meisterschaftsspiel: dem College Cup. Der College Cup ist ein Ausscheidungsturnier, das am Ende der Saison stattfindet, normalerweise im November und Dezember. Um sich für den College Cup zu qualifizieren, müssen Teams sich zunächst durch ihre Konferenz oder durch eine Wildcard qualifizieren, die von einem Auswahlkomitee basierend auf ihrer Leistung während der Saison vergeben wird. Der College Cup besteht aus 48 Männer- und 64 Frauenmannschaften, die in verschiedenen Runden antreten, bis ein Meister übrig bleibt.
      <br />
      <br />
      Division II und Division III sind weniger wettbewerbsfähig als Division I, bieten aber dennoch viele Möglichkeiten für Studenten-Athleten, sich zu entwickeln und das Spiel zu genießen. Division II besteht aus etwa 160 Männer- und 200 Frauenmannschaften, die ebenfalls in verschiedenen Konferenzen unterteilt sind. Division II hat ebenfalls eine nationale Meisterschaft, bestehend aus 36 Männer- und 48 Frauenmannschaften. Division III besteht aus etwa 400 Männer- und 430 Frauenmannschaften, die ebenfalls in Konferenzen antreten. Division III bietet jedoch keine Stipendien für Studenten-Athleten an, sondern andere Formen finanzieller Unterstützung. Division III hat ebenfalls eine nationale Meisterschaft, bestehend aus 62 Männer- und 64 Frauenmannschaften.
      <br />
      <br />
      Neben der NCAA gibt es andere Organisationen, die den College-Fußball in den USA organisieren, wie die National Association of Intercollegiate Athletics (NAIA), die National Junior College Athletic Association (NJCAA) und die United States Collegiate Athletic Association (USCAA). Diese Organisationen haben ihre eigenen Divisionen, Regeln, Stipendien und Meisterschaften, die in der Regel kleiner und weniger bekannt sind als die der NCAA.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Top-Programme und angesehene Teams für Fußball
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Innerhalb jeder Division und Konferenz gibt es bestimmte Programme und Teams, die aufgrund ihrer Geschichte, Leistung, Reputation, Einrichtungen und mehr herausragen. Diese Programme und Teams ziehen oft die besten Spieler an, bieten die besten Stipendien, haben die besten Trainer und Mitarbeiter, verfügen über erstklassige Trainings- und Spielfazilitäten, haben die größte Fangemeinde und Medienaufmerksamkeit und bringen häufig Spieler für den Profi-Zirkus hervor.
      <br />
      <br />
      Es ist schwierig, eine definitive Liste der besten Programme und Teams im College-Fußball in Amerika zu erstellen, da viele Faktoren eine Rolle spielen und Rankings jedes Jahr variieren können. Um dir jedoch eine Vorstellung zu geben, werden wir einige Beispiele für Programme und Teams nennen, die im College-Fußball in Amerika oft als Spitzenreiter gelten.
      <br />
      <br />
      Für Männer ist eines der angesehensten Programme das der University of Maryland, das bereits viermal die nationale Meisterschaft gewonnen hat (1968, 2005, 2008 und 2018) und mehr als 20 Spieler hervorgebracht hat, die in der MLS gespielt haben, wie Omar Gonzalez, Graham Zusi und Zack Steffen. Weitere führende Programme für Männer sind die der University of Virginia, Indiana University, Stanford University und Wake Forest University.
      <br />
      <br />
      Für Frauen ist eines der dominierendsten Programme das der University of North Carolina, das über 20 Mal die nationale Meisterschaft gewonnen hat und mehr als 40 Spielerinnen hervorgebracht hat, die für die Nationalmannschaft gespielt haben, wie Mia Hamm, Kristine Lilly und Tobin Heath. Weitere führende Programme für Frauen sind die der University of Notre Dame, der University of Portland, der Stanford University und der Florida State University.
      <br />
      <br />
      Dies sind nur einige Beispiele für die vielen ausgezeichneten Programme und Teams im College-Fußball in Amerika. Wenn du mehr über verschiedene Programme und Teams erfahren möchtest, kannst du online nach Rankings, Statistiken, Bewertungen und Spieler- und Trainerzeugnissen suchen. Du kannst auch Kontakt zu Sportbeursamerika.nl aufnehmen, die dir helfen können, das beste Programm und Team für dich zu finden.
    </MKTypography>
  </MKBox>
</MKBox>






                                  






{/* Kapitel 3 */}
<MKBox id="Kapitel-3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Vom College zum Profi
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Viele junge Fußballspieler träumen davon, eines Tages professionell zu spielen und ihre Idole auf höchstem Niveau zu emulieren. Doch wie kann man diesen Traum wahr werden lassen? Welche Schritte musst du unternehmen, um vom College zum Profi zu gelangen? Und welche Chancen und Herausforderungen wirst du auf dem Weg antreffen? In diesem Kapitel werden wir diese Fragen beantworten und dir zeigen, wie der College-Fußball als Sprungbrett für eine professionelle Karriere dienen kann.
  </MKTypography>
  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Der Weg zum Profi
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Einer der größten Vorteile des College-Fußballs ist, dass er dir die Möglichkeit bietet, sowohl sportlich als auch akademisch zu wachsen, während du das Studentenleben genießt. Indem du in Amerika Fußball spielst und studierst, kannst du nicht nur deine Fußballfähigkeiten verbessern, sondern auch dein Wissen erweitern, dein Netzwerk ausbauen, deine Persönlichkeit formen und deine Zukunftsaussichten verbessern.
      <br />
      <br />
      Der College-Fußball kann auch ein Sprungbrett für eine professionelle Karriere sein, wenn du es wünschst. Es gibt verschiedene Möglichkeiten, vom College-Fußball zum Profi zu wechseln, sowohl in Amerika als auch im Ausland. Die häufigsten Methoden sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          MLS SuperDraft: Dies ist ein jährliches Ereignis, bei dem MLS-Teams abwechselnd Spieler aus einem Pool verfügbarer Spieler auswählen, von denen die meisten aus dem College-Fußball stammen. Der MLS SuperDraft besteht aus vier Runden, wobei jedes Team pro Runde eine Auswahl trifft. Die Reihenfolge der Auswahl wird durch verschiedene Faktoren wie die Leistung des Teams in der vorherigen Saison, Lotterie und potenzielle Trades bestimmt. Der MLS SuperDraft ist einer der Hauptwege für College-Fußballspieler, in die MLS einzusteigen, und bietet die Möglichkeit, einen Vertrag und ein Stipendium zu sichern.
        </li>
        <li>
          MLS Homegrown Player Rule: Diese Regel erlaubt es MLS-Teams, Spieler aus ihren eigenen Nachwuchsakademien ohne Verwendung eines Draft-Picks zu verpflichten. Die Regel zielt darauf ab, die Entwicklung lokaler Talente zu fördern und zu belohnen. Viele MLS-Teams haben Partnerschaften mit College-Programmen, die es ihnen ermöglichen, Spieler zu verfolgen und zu betreuen, die sowohl für ihre Hochschule als auch für ihre Jugendteams spielen. Die MLS Homegrown Player Rule ist ein weiterer Weg für College-Fußballspieler, in die MLS einzusteigen, und bietet die Möglichkeit, einen Vertrag und ein Stipendium zu sichern.
        </li>
        <li>
          Generation Adidas Programm: Dieses Programm, das von Adidas gesponsert wird, soll jungen Spielern beim Übergang vom College-Fußball zum Profi-Fußball helfen. Das Programm wählt vielversprechende Spieler aus, die in der Regel unter 20 Jahre alt sind und noch nicht ihr Studium abgeschlossen haben. Diese Spieler erhalten Verträge von der MLS angeboten, die es ihnen ermöglichen, am MLS SuperDraft teilzunehmen, ohne ihre College-Berechtigung zu verlieren. Das bedeutet, dass sie immer noch zu ihrer Universität zurückkehren können, um ihr Studium abzuschließen, wenn sie möchten. Das Programm bietet den Spielern auch Bildungsstipendien, um ihre Studienkosten zu decken.
        </li>
        <li>
          Möglichkeiten im Ausland: Dies sind Möglichkeiten für College-Fußballspieler, im Ausland zu spielen, zum Beispiel in Europa, Asien oder Südamerika. Diese Möglichkeiten hängen in der Regel von verschiedenen Faktoren wie dem Leistungsniveau des Spielers, der Nationalität, dem Alter und dem Netzwerk ab. Einige Spieler können beispielsweise die doppelte Staatsbürgerschaft oder familiäre Hintergründe nutzen, um sich für einen ausländischen Pass zu qualifizieren, was es ihnen erleichtert, in einem anderen Land zu spielen. Andere Spieler können von ihren Verbindungen zu Scouts, Agenten oder Trainern profitieren, die ihnen helfen können, einen Vertrag mit einem ausländischen Verein zu erhalten. Möglichkeiten im Ausland sind ein weiterer Weg für College-Fußballspieler, eine professionelle Karriere zu verfolgen, aber sie bringen auch mehr Risiken und Unsicherheiten mit sich.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dies sind nur einige der Wege, wie du vom College zum Profi gelangen kannst, aber es gibt noch mehr. Wichtig ist, sich der vorhandenen Chancen bewusst zu sein und sich auf die Herausforderungen vorzubereiten, die du möglicherweise antreffen wirst. Es ist auch wichtig, realistisch über deine Chancen und Erwartungen zu sein und nicht alle Eier in einen Korb zu legen. Der College-Fußball ist eine fantastische Gelegenheit, sich als Spieler und als Person weiterzuentwickeln. Daher ist es klug, auch andere Optionen offen zu halten und sich nicht nur auf Fußball, sondern auch auf Studium, Hobbys, Freunde und Familie zu konzentrieren.
      <br />
      <br />
      Im nächsten Kapitel werden wir den Einfluss des internationalen Fußballs auf den College-Fußball untersuchen und wie er College-Fußballspieler beeinflusst und inspiriert.
    </MKTypography>
  </MKBox>
</MKBox>





                                  






{/* Kapitel 4 */}
<MKBox id="Kapitel-4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Der Einfluss des internationalen Fußballs
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Fußball ist ein globaler Sport, der Millionen von Menschen auf der ganzen Welt verbindet. Es ist nicht überraschend, dass der internationale Fußball einen bedeutenden Einfluss auf den College-Fußball in Amerika hat. In diesem Kapitel werden wir untersuchen, wie ausländische Einflüsse den College-Fußball beeinflussen und inspirieren und wie amerikanische Spieler ihren Weg in fremde Länder finden.
  </MKTypography>
  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Ausländische Einflüsse
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Der begehrte NCAA-Pokal."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Der begehrte NCAA-Pokal
        </figcaption>
      </figure>
      Die Popularität des internationalen Fußballs in den Vereinigten Staaten ist in den letzten Jahren signifikant gestiegen. Laut einer Gallup-Umfrage von 2023 ist Fußball der drittpopulärste Sport unter Amerikanern, nach American Football und Basketball. Dies ist teilweise auf die zunehmende Vielfalt der amerikanischen Bevölkerung, die Zugänglichkeit internationaler Spiele im Fernsehen und im Internet sowie den Erfolg der US-Nationalmannschaft auf der Weltbühne zurückzuführen.
      <br />
      <br />
      Dieses Interesse am internationalen Fußball hat auch Auswirkungen auf den College-Fußball. Viele College-Fußballspieler schauen zu ihren Lieblingsspielern und -teams aus dem Ausland auf und versuchen, ihren Spielstil und ihre Technik zu emulieren. Einige College-Fußballspieler haben sogar ausländische Wurzeln oder doppelte Staatsbürgerschaft, was eine besondere Verbindung zu einem bestimmten Land oder einer bestimmten Region schafft.
      <br />
      <br />
      Ein Beispiel hierfür ist Gio Reyna, der Sohn des ehemaligen US-amerikanischen Nationalspielers Claudio Reyna. Gio Reyna wurde in England geboren, wo sein Vater für Sunderland und Manchester City spielte. Er wuchs mit einer Liebe zum englischen Fußball auf und spielte für die Jugendteams von New York City FC. Im Jahr 2023 wechselte er zu Borussia Dortmund in Deutschland, wo er schnell zu einem der besten Talente Europas wurde. Er gab auch sein Debüt in der US-Nationalmannschaft und bildete zusammen mit anderen jungen Stars wie Christian Pulisic und Weston McKennie eine neue Generation.
      <br />
      <br />
      Ein weiteres Beispiel ist Catarina Macario, eine brasilianische Stürmerin, die im Alter von zwölf Jahren in die USA zog. Sie spielte für die Stanford University, wo sie zweimal den Hermann Trophy als beste College-Fußballspielerin des Landes gewann. Sie erlangte auch die US-Staatsbürgerschaft, was es ihr ermöglichte, die US-Frauen-Nationalmannschaft zu vertreten. Im Jahr 2023 unterzeichnete sie einen Vertrag mit Olympique Lyon in Frankreich, einem der führenden Frauen-Teams der Welt.
      <br />
      <br />
      Diese Beispiele verdeutlichen, wie ausländische Einflüsse den College-Fußball bereichern und diversifizieren und wie College-Fußballspieler ihre Träume im internationalen Fußball verwirklichen können.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikaner im Ausland
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Nicht nur ausländische Einflüsse beeinflussen den College-Fußball, sondern auch amerikanische Spieler, die ihr Glück im Ausland suchen, spielen eine bedeutende Rolle. Eine zunehmende Anzahl von amerikanischen Spielern entscheidet sich nach ihrer College-Karriere, nach Europa oder anderen Kontinenten zu ziehen, um gegen die besten Spieler der Welt anzutreten.
      <br />
      <br />
      Ein der erfolgreichsten Beispiele ist Clint Dempsey, der für die Furman University spielte, bevor er zur MLS kam. Im Jahr 2023 wechselte er zu Fulham in England, wo er sich als produktiver Torschütze herausstellte. Er erzielte mehr als 50 Tore für den Verein, darunter ein historisches Tor gegen Juventus in der Europa League. Er spielte auch für Tottenham Hotspur und Seattle Sounders und war eine Schlüsselfigur für die US-Nationalmannschaft.
      <br />
      <br />
      Ein weiteres Erfolgsbeispiel ist Tobin Heath, die für die North Carolina University spielte, wo sie zwei nationale Meisterschaften gewann. Sie spielte auch für verschiedene Vereine in den USA, Frankreich und England, darunter Paris Saint-Germain und Manchester United. Sie gewann vier olympische Goldmedaillen und zwei FIFA-Weltmeisterschaften mit der US-Frauen-Nationalmannschaft, wo sie für ihre technischen Fähigkeiten und Kreativität bekannt ist.
      <br />
      <br />
      Diese Beispiele zeigen, wie amerikanische Spieler im Ausland entwickeln und sich auszeichnen können und wie sie den College-Fußball als Talentbrutstätte bekannt machen können.
    </MKTypography>
  </MKBox>
</MKBox>







                                  



                                
<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Sportstipendien und die Zukunft der Athleten
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sportstipendien bieten zahlreiche Möglichkeiten für junge Athleten, ihr Talent zu zeigen und ihre Fähigkeiten zu entwickeln. Eine der bedeutendsten Möglichkeiten ist der Erhalt eines Sportstipendiums, um auf Hochschulebene zu studieren und zu konkurrieren. In diesem Kapitel werden wir die Bedeutung von Sportstipendien diskutieren und wie Sportstipendien Amerika Ihnen helfen kann, Ihren Traum zu verwirklichen.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Die Bedeutung von Stipendien
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ein Sportstipendium ist finanzielle Unterstützung, die von einer Universität einem Studenten-Athleten zur Verfügung gestellt wird, um Studium und Sport zu kombinieren. Es kann die Kosten für Studiengebühren, Bücher, Unterkunft, Mahlzeiten und andere Ausgaben abdecken. Darüber hinaus kann es auch Vorteile wie akademische Nachhilfe, medizinische Versorgung, Trainingsmöglichkeiten und Ausrüstung bieten.
      <br />
      <br />
      Die Erlangung eines Sportstipendiums ist wettbewerbsfähig, wobei viele Faktoren eine Rolle im Zuteilungsprozess spielen, einschließlich akademischer Leistungen, sportlicher Leistung, Persönlichkeit, Motivation, Führung und Anpassungsfähigkeit. Daher ist es wichtig, sich gut vorzubereiten und sich von der Konkurrenz abzuheben.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Wie Sportstipendien Amerika helfen können
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo von Sportstipendien Amerika, das Sportausrüstung und eine Abschlusskappe zeigt, die den Fokus auf Sportstipendien für Studenten-Athleten repräsentiert."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo von Sportstipendien Amerika
        </figcaption>
      </figure>
      Sportstipendien Amerika ist eine Organisation, die sich auf die Anleitung junger Sporttalente zu Sportstipendien spezialisiert hat. Mit leidenschaftlichen und erfahrenen Experten unterstützt Sportstipendien Amerika Studenten-Athleten auf jedem Schritt der Reise, von der ersten Orientierung bis zur endgültigen Platzierung. Ihre Dienstleistungen umfassen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Ein persönliches Aufnahmegespräch, um die Wünsche, Möglichkeiten und Erwartungen des Studenten-Athleten zu besprechen.</li>
        <li>Eine professionelle Bewertung des sportlichen und akademischen Niveaus des Studenten-Athleten.</li>
        <li>Ein umfangreiches Netzwerk von Kontakten zu Trainern, Universitäten und Hochschulen.</li>
        <li>Effektive Werbung für das Profil und die Videos des Studenten-Athleten bei potenziellen Trainern.</li>
        <li>Expertenberatung zu den besten Optionen basierend auf den Vorlieben, Zielen und dem Budget des Studenten-Athleten.</li>
        <li>Verhandlung der besten Stipendienangebote im Namen des Studenten-Athleten.</li>
        <li>Hilfe bei der Beantragung der erforderlichen Dokumente wie Visum, Zeugnis, Diplom und Zulassungstests.</li>
        <li>Vorbereitung auf das Leben des Studenten-Athleten während seines Stipendiums, einschließlich Kultur, Sprache, Regeln und Tipps.</li>
        <li>Nachsorge und Follow-up während der Stipendiumszeit.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Möchten Sie Stipendienmöglichkeiten erkunden und Ihre sportlichen und akademischen Ziele erreichen? <Link to="/de/Dienstleistungen-Produkte">Sehen Sie sich die vollständige Palette</Link> der von Sportstipendien Amerika angebotenen Dienstleistungen an und vereinbaren Sie noch heute eine Beratung.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;