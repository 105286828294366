/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Golf1.jpeg";
import Image2 from "assets/images/Sports_Images/Golf2.webp";
import Image3 from "assets/images/Sports_Images/Golf3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"


// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Golf Scholarship: How to Study and Play Golf in America', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Chapter 1: History and Development of College Golf', isSubheader: false },
    { id: '1.1', title: '1.1 Early Years', isSubheader: true },
    { id: '1.2', title: '1.2 Growth and Professionalization', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Chapter 2: The Current Landscape of College Golf', isSubheader: false },
    { id: '2.1', title: '2.1 Major Competitions and Conferences', isSubheader: true },
    { id: '2.2', title: '2.2 Top Programs and Prestigious Teams', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Chapter 3: From College to the Pros', isSubheader: false },
    { id: '3.1', title: '3.1 The Path to the Professional Circuit', isSubheader: true },
    { id: '3.2', title: '3.2 Alternative Routes to Professionalism', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Chapter 4: International Influences and Opportunities', isSubheader: false },
    { id: '4.1', title: '4.1 Foreign Players in College Golf', isSubheader: true },
    { id: '4.2', title: '4.2 American Players Abroad', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Chapter 5: Sports Scholarships and the Future of American Football', isSubheader: false },
    { id: '5.1', title: '5.1 The Importance of Scholarships', isSubheader: true },
    { id: '5.2', title: '5.2 How Sport Scholarships America Can Help', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };





  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Content
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Hide</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Show</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>

























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Golf Scholarship: How to Study and Play Golf in America
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Golf green during the NCAA championship, with players and spectators around the course"
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        The NCAA Golf Championship
      </figcaption>
    </figure>
    Golf is one of the most popular sports at the college level in the United States. Every year, thousands of student-athletes compete for national titles, prestigious awards, and a place in professional golf. College golf not only offers a chance to improve your athletic skills but also to earn an academic degree, build a network, and have an unforgettable experience.
    <br/>
    <br/>
    However, college golf is not for everyone. It requires a high level of commitment, discipline, and talent. Moreover, it is not easy to be admitted to a university with a good golf program. The competition is fierce, and the costs are high.
    <br/>
    <br/>
    Fortunately, there are opportunities to make your dream come true with the help of a golf scholarship. A golf scholarship is financial support you receive from a university to study and play golf there. With a golf scholarship, you can reduce or even fully cover your study costs while enjoying all the benefits of college golf.
    <br/>
    <br/>
    But how do you get a golf scholarship? And what should you consider when choosing a university? That's where Sportbeursamerika.nl can help you. Sportbeursamerika.nl is the specialist in guiding students to sports scholarships in America. I have experience and knowledge of the American education and sports system, and I have helped many student-athletes achieve their dream.
    <br/>
    <br/>
    In this article, we provide more information about college golf and show how Sportbeursamerika.nl can support you in finding the perfect golf scholarship for you.
    <br/>
    <br/>
    Want to know more? Keep reading!
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Hoofdstuk 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 1: History and Development of College Golf
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College golf has a long and rich history that dates back to the early 20th century. In this chapter, we will look at the early years and the growth and professionalization of college golf in the United States.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Early Years
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      The first golf competitions at universities and colleges in the U.S. took place in the 1890s. The first official intercollegiate golf match was between Harvard and Yale in 1897. In 1901, the Intercollegiate Golf Association (IGA) was founded, which organized the first national championship in 1902. Yale won the first title and continued to dominate until the 1920s.
      <br/>
      <br/>
      The first golf programs at universities were mainly focused on the social and recreational aspects of the sport. Students played mostly on local courses and had little guidance or facilities. The golf teams usually consisted of four or five players who qualified through internal tournaments.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Growth and Professionalization
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      In the 1930s, college golf began to grow and professionalize significantly. This period saw an increase in the number of university golf programs and an improvement in the quality of training and facilities. The professionalization was further stimulated by the increasing attention to the sport and the growing recognition of the potential of golf at the college level. During this time, the National Collegiate Athletic Association (NCAA) took a leading role in organizing and regulating college golf, contributing to the further development and popularity of the sport in the United States.
      <br/>
      <br/>
      The NCAA took over the organization of the national championship from the IGA, and introduced new rules and formats. The NCAA divided the universities into different divisions, based on their size and athletic level. The NCAA also organized regional championships to facilitate qualification for the national championship.
      <br/>
      <br/>
      Universities also began to invest more in their golf programs, by hiring professional coaches, building or improving golf courses, and offering scholarships to talented players. The golf teams became larger and stronger, and the competition became more intense.
      <br/>
      <br/>
      In the 1940s and 1950s, more important tournaments were also added, such as the Walker Cup, the Palmer Cup, and the Eisenhower Trophy. These tournaments gave college golfers the chance to compete with international players and improve their skills.
      <br/>
      <br/>
      In this chapter, we have seen how college golf originated and grew in the United States. In the next chapter, we will look at the current landscape of college golf, and what competitions and programs are available.
    </MKTypography>
  </MKBox>
</MKBox>














                                              





                                  








<MKBox id="Chapter 2" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Chapter 2: The Current Landscape of College Golf
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Today, college golf is one of the most competitive and prestigious sports at the university level in the United States. In this chapter, we will look at the major competitions and conferences, as well as the top programs and prestigious teams in college golf.
    </MKTypography>

    <MKBox id="2.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.1 Major Competitions and Conferences
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image2}
                    alt="Tiger Woods representing Stanford in college golf, shown in match concentration."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal2(Image2)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Tiger Woods in action for Stanford.
                </figcaption>
            </figure>
            The NCAA is the largest and most important organization for college golf in the U.S. The NCAA divides universities into three divisions: Division I, Division II, and Division III. Each division has its own championship, held annually in May or June. The championships consist of a team and an individual tournament, where the best players and teams from each division compete for the national title.
            <br/>
            <br/>
            The NCAA also has several regional conferences, consisting of universities that are geographically or historically connected. Each conference has its own championship, serving as a qualification tournament for the national championship. Some conferences are stronger than others and have more influence on the college golf landscape.
            <br/>
            <br/>
            Some of the most well-known conferences include:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    The Atlantic Coast Conference (ACC), consisting of 15 universities on the East Coast, such as Duke, North Carolina, and Clemson.
                </li>
                <li>
                    The Big Ten Conference, consisting of 14 universities in the Midwest, such as Michigan, Ohio State, and Illinois.
                </li>
                <li>
                    The Pac-12 Conference, consisting of 12 universities on the West Coast, such as Stanford, California, and Arizona State.
                </li>
                <li>
                    The Southeastern Conference (SEC), consisting of 14 universities in the Southeast, such as Alabama, Georgia, and Florida.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Besides the NCAA, there are also other organizations that organize college golf competitions, such as the National Association of Intercollegiate Athletics (NAIA), the National Junior College Athletic Association (NJCAA), and the National Christian College Athletic Association (NCCAA). These organizations have their own divisions, conferences, and championships, which are generally smaller and less prestigious than those of the NCAA.
        </MKTypography>
    </MKBox>
    <MKBox id="2.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.2 Top Programs and Prestigious Teams
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            College golf is not only a sport but also an education. The universities that offer college golf have various programs, varying in quality, reputation, and facilities. Some programs are leading and prestigious, attracting the best players and coaches. Other programs are less known or successful but still offer good opportunities for student-athletes.
            <br/>
            <br/>
            Some of the factors that determine a program include:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    The history and tradition of the program.
                </li>
                <li>
                    The level and results of the team.
                </li>
                <li>
                    The quality and experience of the coach.
                </li>
                <li>
                    The facilities and equipment of the program.
                </li>
                <li>
                    The academic standards and requirements of the program.
                </li>
                <li>
                    The atmosphere and culture of the program.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Some of the most prominent college golf programs include:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Stanford University: Stanford has one of the oldest and most successful golf programs in the U.S., with multiple national titles in men's golf. The program has produced famous alumni such as Tiger Woods and Tom Watson. Stanford's women's golf team has also gained national recognition with several NCAA titles and individual champions.
                </li>
                <li>
                    Oklahoma State University: Oklahoma State has a dominant golf program, especially known for its men's team, with multiple national titles. The program has produced top golfers such as Rickie Fowler and Viktor Hovland.
                </li>
                <li>
                    Duke University: Duke is known for its strong women's golf program, with several NCAA titles. Duke's men's golf team has performed strongly in conference championships and has a respectable history in NCAA competitions. Duke has produced talented golfers, including Amanda Blumenherst and Brittany Lang.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            In this chapter, we have seen what the current landscape of college golf looks like, and what competitions and programs are available. In the next chapter, we will look at how to go from college to the pros, and what alternative routes are available.
        </MKTypography>
    </MKBox>
</MKBox>













                                              






                                  









<MKBox id="Chapter 3" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Chapter 3: From College to the Pros
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        College golf is not just a sport, but also a career path. Many college golfers aspire to transition to the professional circuit and play at the highest levels of golf. In this chapter, we will explore how college golf can serve as a stepping stone to professional golf and the alternative routes available.
    </MKTypography>

    <MKBox id="3.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.1 The Path to the Professional Circuit
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            College golf is an excellent preparation for a professional career in golf. College golfers have the opportunity to develop athletically, academically, and personally. They learn to handle pressure, competition, and expectations. They play on challenging courses against strong opponents and under various conditions. They also have access to high-quality facilities, coaches, and guidance.
            <br/>
            <br/>
            College golfers also have an advantage over other players who do not attend university. They have a degree that can help them find a job or a sponsor if they do not succeed as professionals. They also have a network of contacts and friends who can support or advise them in their careers.
            <br/>
            <br/>
            Many college golfers transition to the professional circuit after completing their studies. Some do so earlier if they believe they are ready. To turn professional, they need to qualify for a tour, such as the PGA Tour, the LPGA Tour, or the European Tour, through qualifying tournaments like the Q-School or the Korn Ferry Tour.
            <br/>
            <br/>
            Many former college golfers have had success on the professional circuit. Examples include:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Phil Mickelson: Mickelson played college golf for Arizona State University, where he won the NCAA championship three times. He turned professional in 1992 and has since achieved numerous PGA Tour victories, including multiple majors.
                </li>
                <li>
                    Annika Sörenstam: Sörenstam played college golf for the University of Arizona, where she won the NCAA championship in 1991. She turned professional in 1992 and has since achieved numerous professional victories, including multiple majors.
                </li>
                <li>
                    Jon Rahm: Rahm played college golf for Arizona State University, where he won the Ben Hogan Award twice as the best college golfer. He turned professional in 2016 and has since achieved multiple professional victories, including majors.
                </li>
            </ul>
        </MKTypography>
    </MKBox>
    <MKBox id="3.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.2 Alternative Routes to Professionalism
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            While college golf is a common route to the professional circuit, it is not the only one. There are other paths players can follow to achieve their dream. Some players choose not to go to university but to turn professional directly after high school. Others attend university but leave early to focus entirely on their sport.
            <br/>
            <br/>
            Examples of players who took an alternative route include:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Tiger Woods: Woods played college golf for Stanford University, where he won the NCAA championship twice. However, he turned professional in 1996 after two years of study. He has since achieved numerous PGA Tour victories, including multiple majors.
                </li>
                <li>
                    Lydia Ko: Ko never played college golf but turned professional in 2013 at the age of 16. She has since achieved multiple LPGA Tour victories.
                </li>
                <li>
                    Jordan Spieth: Spieth played college golf for the University of Texas, where he won the NCAA championship in 2012. However, he turned professional in 2012 after one year of study. He has since achieved multiple PGA Tour victories, including multiple majors.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            In this chapter, we have seen how to transition from college to the pros and what alternative routes are available. In the next chapter, we will explore the international influences and opportunities in college golf.
        </MKTypography>
    </MKBox>
</MKBox>







                                              






                                  









<MKBox id="Chapter 4" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Chapter 4: International Influences and Opportunities
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        College golf is not just an American sport, but also an international one. There are many foreign players who come to the US to study and play golf, and there are also many American players who play abroad. In this chapter, we will look at the impact and contributions of international student-athletes and the opportunities for American college golfers to play internationally.
    </MKTypography>

    <MKBox id="4.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.1 Foreign Players in College Golf
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image3}
                    alt="Texas Longhorns, with Jordan Spieth, celebrating the national championship"
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal3(Image3)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Texas Longhorns, with Jordan Spieth, celebrating the national championship
                </figcaption>
            </figure>
            College golf is an attractive option for many foreign players looking for a combination of sports and education. They can benefit from the high quality of American universities, the excellent facilities and guidance of the golf programs, and the strong competition and exposure of college golf tournaments.
            <br/>
            <br/>
            Foreign players also offer a lot to American universities. They bring diversity, culture, and talent to their teams. They can also share their experiences and perspectives with their teammates and coaches, contributing to their development.
            <br/>
            <br/>
            Many foreign players have been successful in college golf, distinguishing themselves as student-athletes. Examples include:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Maria Fassi: Fassi played college golf at the University of Arkansas, where she won the ANNIKA Award twice as the best female college golfer. She turned professional in 2019 and has since achieved several professional victories.
                </li>
                <li>
                    Viktor Hovland: Hovland played college golf at Oklahoma State University, where he won the NCAA championship in 2018. He turned professional in 2019 and has since achieved several professional victories.
                </li>
            </ul>
        </MKTypography>
    </MKBox>
    <MKBox id="4.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.2 American Players Abroad
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            College golf is not the only way to develop as a golfer. There are also many opportunities for American college golfers to play internationally, experiencing other cultures and courses. This can broaden their horizons, increase their adaptability, and improve their game.
            <br/>
            <br/>
            American college golfers can play internationally through various programs, such as:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    The Palmer Cup: An annual tournament between teams of American and international college golfers, named after Arnold Palmer, a famous golfer who also played college golf. The tournament is held at different locations around the world, such as France, Ireland, England, and Sweden.
                </li>
                <li>
                    The Eisenhower Trophy: A biennial tournament between teams of amateur golfers from different countries, named after Dwight D. Eisenhower, a former US president and avid golfer. The tournament is held at various locations around the world, such as Australia, Japan, Mexico, and Turkey.
                </li>
                <li>
                    The Study Abroad Program: An academic program that offers students the opportunity to study for a semester or a year at a foreign university. Some universities also have golf programs that collaborate with foreign universities, allowing student-athletes to golf during their stay.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Many American college golfers have benefited from playing abroad, elevating their game to a higher level. Examples include:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Collin Morikawa: Morikawa played college golf at the University of California, Berkeley, where he became a four-time All-American. He also played in the Palmer Cup in 2017 and 2018, and in the Eisenhower Trophy in 2018. He turned professional in 2019 and has since achieved multiple professional victories.
                </li>
                <li>
                    Jennifer Kupcho: Kupcho played college golf at Wake Forest University, where she won the NCAA championship in 2018. She also played in the Palmer Cup in 2018, and in the Curtis Cup in 2016 and 2018. She turned professional in 2019 and has since achieved multiple professional victories.
                </li>
                <li>
                    Patrick Reed: Reed played college golf at the University of Georgia and Augusta State University, where he won the NCAA championship twice. He also played in the Palmer Cup in 2011 and studied for a semester at the University of Stirling in Scotland. He turned professional in 2011 and has since achieved multiple professional victories.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            In this chapter, we have seen how college golf is an international sport, and the influences and opportunities for foreign and American players. In the next chapter, we will look at the importance of golf scholarships, and the role of Sportbeursamerika.nl.
        </MKTypography>
    </MKBox>
</MKBox>












                                              






                                  






<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 5: Sports Scholarships and the Future of Athletes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sports scholarships offer numerous opportunities for young athletes to showcase their talent and develop their skills. One of the most significant opportunities is obtaining a sports scholarship to study and compete at the college level. In this chapter, we will discuss the importance of sports scholarships and how Sport Scholarships America can help you make your dream come true.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 The Importance of Scholarships
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A sports scholarship is financial support provided by a university to a student-athlete to combine studies and sports. It can cover the costs of tuition, books, housing, meals, and other expenses. Additionally, it may offer benefits such as academic tutoring, medical care, training facilities, and equipment.
      <br />
      <br />
      Obtaining a sports scholarship is competitive, with many factors playing a role in the awarding process, including academic achievements, athletic performance, personality, motivation, leadership, and adaptability. Therefore, it's essential to prepare well and distinguish yourself from the competition.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 How Sport Scholarships America Can Help
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo of Sport Scholarships America, featuring sports equipment and a graduation cap, representing the focus on sports scholarships for student-athletes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo of Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America is an organization specialized in guiding young sports talents to sports scholarships. With passionate and experienced experts, Sport Scholarships America supports student-athletes at every step of the journey, from initial orientation to final placement. Their services include:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>A personal intake interview to discuss the student-athlete's wishes, possibilities, and expectations.</li>
        <li>A professional evaluation of the student-athlete's athletic and academic level.</li>
        <li>An extensive network of contacts with coaches, universities, and colleges.</li>
        <li>Effective promotion of the student-athlete's profile and videos to potential coaches.</li>
        <li>Expert advice on the best options based on the student-athlete's preferences, goals, and budget.</li>
        <li>Negotiation of the best scholarship offers on behalf of the student-athlete.</li>
        <li>Assistance in applying for necessary documents, such as visa, transcript, diploma, and admission tests.</li>
        <li>Preparation for student-athletes' life during their scholarship, including culture, language, rules, and tips.</li>
        <li>Aftercare and follow-up during the scholarship period.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Want to explore scholarship opportunities and achieve your athletic and academic goals? <Link to="/Services-Products">View the full range</Link> of services offered by Sport Scholarships America and schedule a consultation today.
    </MKTypography>
  </MKBox>
</MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;