/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


// @mui material components
import React, { useState } from 'react'; // Step 1: Import useState


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Logos/NCAA.png";
import post3 from "assets/images/Logos/NAIA.png";



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {

  const articleStructure = [
    { id: 'intro', title: 'NJCAA: Il Trampolino per Grandi College e la NCAA', isSubheader: false },
  
    { id: 'Hoofdstuk 1', title: 'Origine e Crescita della NJCAA', isSubheader: true },
    { id: '1.1', title: 'Le Tre Divisioni della NJCAA', isSubheader: true },
    { id: '1.2', title: 'Passaggio a Grandi Università e alla NCAA', isSubheader: true },
  
    { id: 'Hoofdstuk 2', title: 'Il Ruolo degli Accademici', isSubheader: true },
    { id: '2.1', title: 'Finanza e Visibilità', isSubheader: true },
    { id: '2.2', title: 'Sfide e Futuro', isSubheader: true },
    { id: '2.3', title: 'Conclusione', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
     <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contents
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Nascondi</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostra</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="intro" mb={3}>

<MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>NJCAA: Il Trampolino per Grandi College e la NCAA</MKTypography>
</MKBox>

<MKBox id="Capitolo 1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Origine e Crescita della NJCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
La National Junior College Athletic Association (NJCAA) è stata fondata nel 1938 ed è cresciuta fino a diventare un protagonista nel panorama dello sport universitario americano. L'organizzazione offre opportunità sportive per i college junior, istituti più piccoli di due anni che offrono agli studenti una via verso l'istruzione superiore e l'eccellenza atletica.

<br />
<br />
Nei suoi primi anni, la NJCAA era limitata nel suo campo di azione, ma è cresciuta e si è adattata per soddisfare le esigenze di uno studentato diversificato ed in evoluzione. L'accento è posto sull'offrire accesso alla competizione atletica promuovendo nel contempo il progresso accademico.

</MKTypography>

<MKBox id="1.1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Le Tre Divisioni della NJCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
La NJCAA è divisa in tre divisioni, ognuna progettata per incontrare le diverse esigenze e opportunità degli studenti-atleti:

<br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Divisione I:{' '}
</MKTypography>
Questa divisione include scuole capaci di offrire borse di studio atletiche complete, risultando in un livello più elevato di competizione. Gli studenti-atleti qui hanno l'opportunità di sviluppare le proprie abilità e competere a livelli intensivi, spesso come trampolino di lancio verso le divisioni NCAA.

<br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Divisione II:{' '}
</MKTypography>
Qui troviamo un equilibrio tra competizione atletica e responsabilità accademiche, con le scuole autorizzate ad offrire borse di studio parziali. Ciò assicura un sano equilibrio e dà agli studenti-atleti l'opportunità di eccellere nel loro sport, preparandosi anche al successo accademico.   <br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Divisione III:{' '}
</MKTypography>
In questa divisione, l'accento è posto sull'offrire opportunità sportive competitive senza borse di studio atletiche. Gli studenti-atleti hanno l'opportunità di partecipare allo sport mentre si impegnano pienamente nella vita accademica, risultando in un'esperienza universitaria completa.  </MKTypography>


<MKBox id="1.2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Passaggio a Grandi Università e alla NCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
La NJCAA gioca un ruolo chiave come trampolino di lancio per gli studenti-atleti che desiderano continuare le loro carriere accademiche e sportive presso istituti più grandi di quattro anni, compresi quelli della NCAA. Molti studenti-atleti iniziano il loro percorso nella NJCAA per sviluppare le proprie abilità, migliorare le qualifiche accademiche e attirare l'attenzione dei reclutatori dei programmi più grandi.


<br />
<br />
Dimostrando il loro valore nell'ambiente competitivo della NJCAA, gli studenti-atleti possono attirare l'attenzione delle università della NCAA e potenzialmente guadagnare borse di studio per continuare la loro istruzione e carriera sportiva. Questo processo non è solo vantaggioso per gli atleti stessi, ma contribuisce anche a rafforzare i programmi sportivi in tutto il paese.


</MKTypography>





                                  







<MKBox id="Capitolo 2" mb={3}>
  
  
  <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Il Ruolo degli Accademici
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              La NJCAA è fortemente impegnata nel successo accademico e nello sviluppo dei suoi studenti-atleti. Sono in vigore rigorosi standard accademici, e le scuole affiliate forniscono un supporto essenziale per garantire che gli atleti eccellano sia in classe che nel loro sport. Ciò illustra il ruolo cruciale di un approccio bilanciato all'interno del panorama sportivo collegiale, anche se il livello potrebbe essere leggermente inferiore rispetto a quello di altre istituzioni educative.
<br />
                <br />
                </MKTypography>

                <MKBox id="2.1" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Finanza e Visibilità
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              Nonostante le risorse finanziarie siano più limitate rispetto alla NCAA, la NJCAA svolge un ruolo cruciale nel supportare i programmi sportivi e promuovere opportunità per gli studenti-atleti. L'organizzazione lavora anche per aumentare la propria visibilità e diffusione al fine di raggiungere e ispirare un maggior numero di studenti e atleti.


<br />
                </MKTypography>

                <MKBox id="2.2" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Sfide e Futuro
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              La NJCAA, come altre organizzazioni sportive, affronta sfide tra cui la navigazione attraverso questioni di uguaglianza nello sport e il supporto alla salute mentale degli studenti-atleti. Tuttavia, con il suo impegno a promuovere opportunità e sostenere gli studenti-atleti, l'organizzazione è ben posizionata per continuare a fare un impatto positivo.



                </MKTypography>

                <MKBox id="2.3" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Conclusione
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              La National Junior College Athletic Association è un componente essenziale del panorama sportivo collegiale americano, offrendo opportunità uniche agli studenti-atleti per competere, imparare e crescere. Con il suo impegno per l'eccellenza accademica e sportiva, la NJCAA rimane una forza influente nella formazione del futuro dei giovani atleti in America.








<br />


                </MKTypography>


              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="NCAA"
                    description="NCAA: Il Cuore dello Sport Universitario in America"
                    action={{
                      type: "internal",
                      route: "/it/NCAA",
                      color: "info",
                      label: "Leggi Di Più",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="NAIA"
                    description="La NAIA: Un Percorso Alternativo nello Sport Universitario in America"
                    action={{
                      type: "internal",
                      route: "/it/NAIA",
                      color: "info",
                      label: "Leggi Di Più",
                     }}
                    />
                  </Grid>
                </Grid>
  
              </Grid>
          </Grid>
        </Container>
      </MKBox>
    );
  }
  
  export default Beschrijving;