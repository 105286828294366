/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Basketbal1.jpeg";
import Image2 from "assets/images/Sports_Images/Basketbal2.webp";
import Image3 from "assets/images/Sports_Images/Basketbal3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import { Link } from 'react-router-dom';

import "../../../../H1asH3.css"

// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'USA Basketball Scholarship: Hvordan Studere og Spille Basketball i Amerika', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapittel 1: Historie og Utvikling av Collegelaget i Basketball', isSubheader: false },
    { id: '1.1', title: '1.1 Tidlige År', isSubheader: true },
    { id: '1.2', title: '1.2 Vekst og Professionalisering', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapittel 2: Collegelaget i Basketball i Dag', isSubheader: false },
    { id: '2.1', title: '2.1 Viktige Konkurranser og Konferanser', isSubheader: true },
    { id: '2.2', title: '2.2 Topp Programmer og Prestisjefylte Lag', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapittel 3: Fra College til NBA', isSubheader: false },
    { id: '3.1', title: '3.1 Veien til NBA', isSubheader: true },
    { id: '3.2', title: '3.2 Andre Profesjonelle Muligheter', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapittel 4: Internasjonale påvirkninger og muligheter', isSubheader: false },
    { id: '4.1', title: '4.1 Utenlandske Spillere i Collegelaget i Basketball', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanske Spillere i Utlandet', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapittel 5: Idrettsstipender og Fremtiden til Utøvere', isSubheader: false },
    { id: '5.1', title: '5.1 Viktigheten av Stipender', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Scholarships America Kan Hjelpe', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };





  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    USA Basketball Scholarship: Hvordan Studere og Spille Basketball i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Vibrant stadium of the University of Arizona during a college basketball game"
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Kampdag på University of Arizona Stadium
      </figcaption>
    </figure>
    Basketball er en av de mest populære idrettene i USA. Millioner av mennesker følger kampene i NBA, den profesjonelle basketballigaen, og NCAA, collegeligaen i basketball. Basketball er ikke bare en spennende idrett å se på, men også en flott måte å utvikle seg som spiller og som person.
    <br/>
    <br/>
    Hvis du er en ung basketballspiller som drømmer om en karriere i NBA, eller rett og slett ønsker å nyte å spille basketball på et høyt nivå, kan et basketballstipend i Amerika være noe for deg. Et basketballstipend er økonomisk støtte du mottar fra et amerikansk universitet for å studere og spille basketball der. Med et basketballstipend kan du ikke bare oppnå din akademiske grad, men også forbedre basketballtalentene dine under profesjonell veiledning.
    <br/>
    <br/>
    Men hvordan får du et basketballstipend i Amerika? Hva er fordelene og utfordringene med å spille collegelaget i basketball? Og hva er mulighetene etter din collegelagkarriere? I denne artikkelen vil vi svare på alle disse spørsmålene og fortelle deg alt du trenger å vite om basketballstipender i Amerika. Vi vil også diskutere rollen til Sportbeursamerika.nl, en organisasjon spesialisert i å hjelpe unge spillere med å realisere drømmen om et basketballstipend.
    <br/>
    <br/>
    Vil du vite mer om basketballstipender i Amerika? Les videre!
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 1: Historie og Utvikling av Collegelaget i Basketball
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Basketball er en relativt ung idrett, oppfunnet i 1891 av James Naismith, en kanadisk fysisk utdannelseslærer. Han konseptualiserte spillet som en innendørsaktivitet for studentene sine i løpet av vintermånedene. Spillet ble raskt populært og spredte seg til ulike skoler og universiteter i Nord-Amerika.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidlige År
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Den første offisielle collegenivå-basketballkampen fant sted i 1895 mellom University of Chicago og University of Iowa. I 1901 ble den første interkollegiale konferansen etablert, Western Conference, senere kjent som Big Ten Conference. I 1906 ble det første nasjonale mesterskapet organisert av Amateur Athletic Union (AAU), som dominerte collegelaget i basketball frem til 1936.
      <br/>
      <br/>
      I 1939 ble det første NCAA-turneringen avholdt, med åtte lag som konkurrerte om tittelen. Turneringen ble vunnet av Oregon, som slo Ohio State 46-33. Turneringen utviklet seg til å bli et årlig arrangement som i økende grad tiltok fansens og medias oppmerksomhet.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Vekst og Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Etter andre verdenskrig økte collegelaget i basketball i popularitet og kvalitet. Nye stjerner dukket opp, som Bill Russell, Wilt Chamberlain, Oscar Robertson og Kareem Abdul-Jabbar, som senere også gjorde seg bemerket i NBA. Nye konkurranser og konferanser ble dannet, som National Invitation Tournament (NIT), Atlantic Coast Conference (ACC) og Southeastern Conference (SEC).
      <br/>
      <br/>
      I 1957 fant en av de mest minneverdige kampene i collegelaget i basketball-historien sted da North Carolina Tar Heels slo Kansas Jayhawks 54-53 etter tre forlengelser. Kampen regnes som et vendepunkt i collegelaget i basketball, og viser hvor spennende og dramatisk spillet kunne være.
      <br/>
      <br/>
      En annen historisk kamp fant sted i 1966 da Texas Western Miners slo Kentucky Wildcats 72-65. Kampen var banebrytende fordi Texas Western var det første laget som besto av utelukkende svarte spillere, mens Kentucky var helt hvitt. Kampen sees som et symbol på kampen for borgerrettigheter og integrasjon i Amerika.
      <br/>
      <br/>
      I 1979 ble den første direktesendte NCAA-turneringen avholdt, med et rekordstort antall seere. Finalen var mellom Michigan State Spartans og Indiana State Sycamores, ledet av fremtidige NBA-legender Magic Johnson og Larry Bird, henholdsvis. Michigan State vant kampen 75-64 og regnes som en av de beste finalene noensinne.
      <br/>
      <br/>
      På 80- og 90-tallet fortsatte collegelaget i basketball å vokse og utvikle seg, med nye regler, stiler og strategier. Nye rivaliseringer oppsto, som Duke mot North Carolina, Georgetown mot Syracuse og UCLA mot Arizona. Det var også nye stjerner, som Michael Jordan, Patrick Ewing, Shaquille O’Neal og Tim Duncan, som løftet collegelaget i basketball til et høyere nivå.
    </MKTypography>
  </MKBox>
</MKBox>














                                              





                                  







<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 2: Collegelaget i Basketball i Dag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Collegelaget i basketball er en av de mest populære og prestisjefylte idrettene i Amerika i dag. Hvert år tiltrekker kampene millioner av tilskuere til stadioner og TV-skjermer. Collegelaget i basketball er også en betydelig kilde til talent for NBA, og rekrutterer hundrevis av spillere hvert år fra universitetslagene.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Viktige Konkurranser og Konferanser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Steph Curry in action for Davidson College, excelling in college basketball."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Stephen Curry i aksjon som spiller for Davidson College
        </figcaption>
      </figure>
      Collegelaget i basketball organiseres primært av National Collegiate Athletic Association (NCAA), som har tilsyn med mer enn 1 000 universiteter og høyskoler. NCAA deler lagene inn i tre divisjoner, der Division I er den høyeste. Division I består av 32 konferanser, der hver konferanse består av lag som er geografisk eller historisk tilknyttet.
      <br/>
      <br/>
      Konferansene er viktige da de avgjør hvilke lag som spiller mot hverandre i løpet av grunnspillsesongen, som varer fra november til mars. Hver konferanse har sin egen terminliste, regler og mesterskap. Konferansemestere tjener automatisk en plass i NCAA-turneringen, høydepunktet i collegelaget i basketball.
      <br/>
      <br/>
      NCAA-turneringen, også kjent som March Madness, er en utslagsturnering som arrangeres hvert år i mars og april. Den består av 68 lag, der 32 er konferansemestere, og 36 er såkalte "at-large bids", valgt av en komité basert på deres prestasjoner i løpet av sesongen. Lagene deles inn i fire regioner og rangeres fra 1 til 16.
      <br/>
      <br/>
      Turneringen begynner med fire "play-in" kamper, der de lavest rangerte lagene konkurrerer om de siste fire plassene i hovedturneringen. Hovedturneringen består av seks runder, og eliminerer ett lag i hver runde til ett lag gjenstår. De siste fire lagene, kjent som "Final Four", spiller på et nøytralt sted for å avgjøre nasjonal mester.
      <br/>
      <br/>
      NCAA-turneringen er en av de mest spennende og uforutsigbare sportsbegivenhetene i verden. Hvert år er det overraskelser, dramaer og sensasjoner, kjent som "upsets", "buzzer-beaters" og "Cinderella stories". Turneringen tiltrekker millioner av seere og spillere, som prøver å forutsi vinneren.
      <br/>
      <br/>
      I tillegg til NCAA-turneringen er det andre betydningsfulle konkurranser i collegelaget i basketball, som NIT, CBI, CIT og NAIA-turneringen. Disse konkurransene tilbyr muligheter for lag som ikke kvalifiserer seg for NCAA-turneringen eller ønsker å få ytterligere erfaring.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Topp Programmer og Prestisjefylte Lag
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Collegelaget i basketball har mange topp programmer og prestisjefylte lag, som skiller seg ut med sin historie, tradisjon, suksess og innflytelse. Noen av disse programmene er:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          UCLA Bruins: Det mest vellykkede programmet i collegelaget i basketballhistorie, med 11 nasjonale titler, inkludert 10 under trener John Wooden på 60- og 70-tallet. UCLA har produsert mange kjente spillere, som Kareem Abdul-Jabbar, Bill Walton og Reggie Miller.
        </li>
        <li>
          Kentucky Wildcats: Det nest mest vellykkede programmet i collegelaget i basketballhistorie, med åtte nasjonale titler og 17 Final Four-opptredener. Kentucky er kjent for sine lidenskapelige fans og evnen til å tiltrekke seg topprekrutter.
        </li>
        <li>
          North Carolina Tar Heels: Det tredje mest vellykkede programmet i collegelaget i basketballhistorie, med seks nasjonale titler og 20 Final Four-opptredener. North Carolina har hatt mange legendariske trenere, som Dean Smith og Roy Williams, og spillere som Michael Jordan, James Worthy og Vince Carter.
        </li>
        <li>
          Duke Blue Devils: Et av de mest dominerende programmene i moderne collegelaget i basketball, med fem nasjonale titler og 16 Final Four-opptredener siden 1986. Duke er kjent for sin trener Mike Krzyzewski, den mest seirende treneren i collegelaget i basketballhistorie, og spillere som Christian Laettner, Grant Hill og Zion Williamson.
        </li>
        <li>
          Kansas Jayhawks: Et av de mest tradisjonelle og innflytelsesrike programmene i collegelaget i basketball, med tre nasjonale titler og 15 Final Four-opptredener. Kansas er fødestedet for spillet, siden James Naismith var dens første trener. Kansas har også hatt mange gode trenere, som Phog Allen, Larry Brown og Bill Self, og spillere som Wilt Chamberlain, Danny Manning og Paul Pierce.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Disse programmene er bare noen få eksempler på de mange utmerkede lagene som beriker collegelaget i basketball. Hvert år gjør nye utfordrere og overraskelser spillet spennende og uforutsigbart.
    </MKTypography>
  </MKBox>
</MKBox>











                                              






                                  






<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 3: Fra College til NBA
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Collegelaget i basketball er ikke bare en idrett, men også en utdannelse. Mange spillere som deltar i collegelaget i basketball har som mål å nå NBA, den beste og mest prestisjefylte profesjonelle basketballigaen i verden. Imidlertid er NBA ikke lett å nå og krever mye talent, hardt arbeid og flaks.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Veien til NBA
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      For å være kvalifisert for NBA må en spiller være minst 19 år gammel og ha fullført videregående skole minst ett år tidligere. Dette betyr at de fleste spillere som ønsker å nå NBA først går på college og spiller i minst ett år. Dette er også kjent som én-og-ferdig-regelen fordi mange spillere går inn i NBA-utkastet etter bare ett år på college.
      <br />
      <br />
      NBA-utkastet er en årlig begivenhet der de 30 NBA-lagene tar tur til å velge en spiller fra et tilgjengelig spillerbasseng. Utkastet består av to runder, der hvert lag har ett valg per runde. Rekkefølgen på valgene bestemmes gjennom en lotteri for lag som ikke kvalifiserte seg for sluttspillet i forrige sesong, og ved omvendt rangering for sluttspillagene.
      <br />
      <br />
      Utkastet er et betydelig øyeblikk for spillere fordi det bestemmer hvilket lag som har rettighetene deres og hvor mye penger de kan tjene. Spillere valgt i første runde mottar en garantert kontrakt i minst to år, mens de som blir valgt i andre runde ikke har noen garantier. Spillere som ikke blir valgt, kan prøve å sikre en kontrakt gjennom fribytter eller ved å bli med i G League, NBA's utviklingsliga.
      <br />
      <br />
      Imidlertid er utkastet ingen garanti for suksess i NBA. Mange høyt utvalgte spillere møter ikke forventningene eller lider av skader. På den annen side er det også mange spillere som blir valgt lavt eller ikke i det hele tatt, men likevel utmerker seg i NBA. Et godt kjent eksempel er Giannis Antetokounmpo, som ble valgt som nummer 15 totalt i 2013 og ble kåret til NBA MVP og mester i 2021.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Andre Profesjonelle Muligheter
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ikke alle spillere som deltar i collegelaget i basketball har som mål å gå til NBA. Noen spillere velger å fullføre sine akademiske grader og forfølge andre karrierer. Andre velger å spille profesjonell basketball i andre land eller ligaer.
      <br />
      <br />
      Det er mange profesjonelle basketballigaer rundt om i verden som tilbyr varierende nivåer av kvalitet, lønn og livsstil. Noen av disse ligaene inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          EuroLeague: Den høyeste og mest prestisjefylte europeiske klubbkonkurransen, med 18 lag fra 10 land. EuroLeague regnes som den nest beste ligaen i verden etter NBA.
        </li>
        <li>
          Chinese Basketball Association (CBA): Den høyeste og mest populære kinesiske klubbkonkurransen, med 20 lag fra hele Kina. CBA er kjent for sine høye lønninger og store publikum.
        </li>
        <li>
          Australian National Basketball League (NBL): Den høyeste og mest konkurransedyktige australske klubbkonkurransen, med ni lag fra Australia og New Zealand. NBL er kjent for sitt raske og fysiske spill.
        </li>
        <li>
          Basketball Africa League (BAL): Den nyeste og mest ambisiøse afrikanske klubbkonkurransen, med 12 lag fra 12 land. BAL støttes av NBA og FIBA og har som mål å utvikle basketball på kontinentet.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Disse ligaene er bare noen få eksempler på de mange mulighetene som er tilgjengelige for profesjonelle basketballspillere utenfor NBA. Mange spillere trives med å bo og spille i forskjellige kulturer samtidig som de fortsetter å forfølge lidenskapen sin for spillet.
    </MKTypography>
  </MKBox>
</MKBox>














                                              






                                  








<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 4: Internasjonale påvirkninger og muligheter
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Basketball er en global idrett som samler mennesker fra forskjellige nasjonaliteter, kulturer og bakgrunner. Collegelaget i basketball er et førsteklasses eksempel på dette, da det tiltrekker både utenlandske og amerikanske spillere, og tilbyr muligheter. I dette kapittelet vil vi utforske de internasjonale påvirkningene og mulighetene i collegelaget i basketball.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Utenlandske Spillere i Collegelaget i Basketball
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="NCAA Final Four basketball game with a crowd of nearly 80,000 fans in the stadium."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
         }}>
        Nesten 80 000 tilskuere under en nasjonal mesterskapskamp
        </figcaption>
      </figure>
      Collegelaget i basketball har alltid ønsket utenlandske spillere velkommen, som har bidratt med sin egen stil, ferdigheter og perspektiver til spillet. Ifølge NCAA var det i sesongen 2019-2020 over 1200 utenlandske spillere i Division I, fra 101 land. De vanligste opprinnelseslandene var Canada, Australia, Nigeria, Frankrike og Storbritannia.
      <br />
      <br />
      Utenlandske spillere velger collegelaget i basketball av forskjellige årsaker. Noen ønsker å kombinere sin akademiske utdannelse med sine idrettsambisjoner. Andre sikter på å tilpasse seg amerikansk kultur og livsstil. Enda andre ønsker å øke sjansene sine for å nå NBA eller representere sine nasjonale lag.
      <br />
      <br />
      Utenlandske spillere har hatt betydelig innvirkning på collegelaget i basketball, både individuelt og kollektivt. Noen av de mest vellykkede og innflytelsesrike utenlandske spillerne i historien til collegelaget i basketball inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Hakeem Olajuwon: En nigeriansk spiller som spilte for Houston Cougars fra 1981 til 1984. Han ledet laget sitt til tre påfølgende Final Four-opptredener og vant prisen som nasjonal spiller av året i 1984. Han var første totalt valg i 1984 NBA-utkastet og ble en av de største sentrene gjennom tidene.
        </li>
        <li>
          Andrew Bogut: En australsk spiller som spilte for Utah Utes fra 2003 til 2005. Han vant prisen som nasjonal spiller av året i 2005 og ledet laget sitt til Sweet Sixteen. Han var første totalt valg i 2005 NBA-utkastet og ble en All-Star og mester.
        </li>
        <li>
          Luka Garza: En amerikansk spiller med bosniske røtter som spilte for Iowa Hawkeyes fra 2017 til 2021. Han vant prisen som nasjonal spiller av året to ganger, i 2020 og 2021, og ble sin universitets all-time ledende poengscorer. Han ble valgt som nummer 52 totalt i 2021 NBA-utkastet og regnes som en av de største collegespillerne noensinne.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Disse spillerne er bare noen få eksempler på de mange utenlandske spillerne som har utmerket seg i collegelaget i basketball. Hvert år dukker det opp nye talenter og beriker spillet.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikanske Spillere i Utlandet
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ikke bare kommer utenlandske spillere til Amerika for å studere og spille basketball, men amerikanske spillere drar også utenlands av samme grunner. Noen amerikanske spillere velger å hoppe over eller avslutte sine videregående skole- eller collegekarrierer for å spille profesjonelt i andre land eller ligaer.
      <br />
      <br />
      Årsakene til dette valget kan variere, men noen inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Økonomisk: Noen spillere ønsker å tjene penger med sitt talent i stedet for å vente på en NBA-kontrakt eller et stipend.
        </li>
        <li>
          Idrettslig: Noen spillere ønsker å utvikle seg mot sterkere motstandere i stedet for å dominere på sitt nivå.
        </li>
        <li>
          Personlig: Noen spillere ønsker å reise, lære nye kulturer eller opprettholde familiebånd.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Amerikanske spillere som drar utenlands har forskjellige opplevelser og resultater. Noen lykkes med å realisere drømmen om NBA, mens andre nyter en lang og vellykket karriere. Noen tilpasser seg godt til sine nye omgivelser, mens andre sliter med språk, mat eller livsstil.
      <br />
      <br />
      Noen eksempler på amerikanske spillere som har reist utenlands inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Brandon Jennings: En spiller som hoppet over videregående skole for å spille for Lottomatica Roma i Italia i 2008. Han var den 10. totalt valgt i 2009 NBA-utkastet og spilte i 10 sesonger i NBA.
        </li>
        <li>
          Emmanuel Mudiay: En spiller som hoppet over college for å spille for Guangdong Southern Tigers i Kina i 2014. Han var den 7. totalt valgt i 2015 NBA-utkastet og spiller for øyeblikket for Utah Jazz.
        </li>
        <li>
          LaMelo Ball: En spiller som hoppet over videregående skole og college for å spille for Illawarra Hawks i Australia i 2019. Han var den 3. totalt valgt i 2020 NBA-utkastet og vant prisen for årets nykommer.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Disse spillerne er bare noen få eksempler på de mange amerikanske spillerne som har valgt en alternativ vei til profesjonell basketball. Hvert år globaliseres spillet av nye eventyrere.
    </MKTypography>
  </MKBox>
</MKBox>











                                              






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 5: Idrettsstipender og Fremtiden til Utøvere
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrettsstipender gir mange muligheter for unge utøvere til å vise sitt talent og utvikle sine ferdigheter. En av de mest betydningsfulle mulighetene er å få et idrettsstipend for å studere og konkurrere på college-nivå. I dette kapittelet vil vi diskutere viktigheten av idrettsstipender og hvordan Sport Scholarships America kan hjelpe deg med å oppfylle drømmen din.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Viktigheten av Stipender
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et idrettsstipend er økonomisk støtte gitt av et universitet til en studentutøver for å kombinere studier og idrett. Det kan dekke kostnadene for skolepenger, bøker, bolig, måltider og andre utgifter. I tillegg kan det tilby fordeler som akademisk veiledning, medisinsk hjelp, treningsfasiliteter og utstyr.
      <br />
      <br />
      Å få et idrettsstipend er konkurransedyktig, med mange faktorer som spiller en rolle i tildelingsprosessen, inkludert akademiske prestasjoner, idrettslige resultater, personlighet, motivasjon, lederegenskaper og tilpasningsevne. Det er derfor viktig å forberede seg godt og skille seg ut fra konkurransen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Scholarships America Kan Hjelpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo for Sport Scholarships America, som viser sportsutstyr og en eksamenskappe, symboliserer fokuset på idrettsstipender for studentutøvere."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo for Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America er en organisasjon spesialisert i å veilede unge idrettstalenter til idrettsstipender. Med lidenskapelige og erfarne eksperter støtter Sport Scholarships America studentutøvere på hvert trinn i reisen, fra første orientering til endelig plassering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig inntaksintervju for å diskutere studentutøverens ønsker, muligheter og forventninger.</li>
        <li>En profesjonell evaluering av studentutøverens akademiske og idrettslige nivå.</li>
        <li>Et omfattende nettverk av kontakter med trenere, universiteter og høyskoler.</li>
        <li>Effektiv markedsføring av studentutøverens profil og videoer til potensielle trenere.</li>
        <li>Ekspertråd om de beste alternativene basert på studentutøverens preferanser, mål og budsjett.</li>
        <li>Forhandlinger om de beste stipendtilbudene på vegne av studentutøveren.</li>
        <li>Hjelp til å søke om nødvendige dokumenter, som visum, transkripsjon, diploma og opptaksprøver.</li>
        <li>Forberedelse til studentutøverens liv under stipendet, inkludert kultur, språk, regler og tips.</li>
        <li>Oppfølging og oppfølging under stipendperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vil du utforske stipendmuligheter og oppnå dine idrettslige og akademiske mål? <Link to="/no/Tjenester-Produkter">Se det komplette utvalget</Link> av tjenester som tilbys av Sport Scholarships America og planlegg en konsultasjon i dag.
    </MKTypography>
  </MKBox>
</MKBox>

          










        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;