import americanFootball from "assets/images/SportsIcons/americanfootball.png";
import athletics from "assets/images/SportsIcons/atletiek.png";
import basketball from "assets/images/SportsIcons/basketbal.png";
import golf from "assets/images/SportsIcons/golf.png";
import fieldHockey from "assets/images/SportsIcons/hockey.png"; // Updated to Field Hockey
import baseball from "assets/images/SportsIcons/honkbal.png";
import rowing from "assets/images/SportsIcons/roeien.png";
import softball from "assets/images/SportsIcons/softbal.png"
import tennis from "assets/images/SportsIcons/tennis.png";
import gymnastics from "assets/images/SportsIcons/turnen.png";
import soccer from "assets/images/SportsIcons/voetbal.png";
import volleyball from "assets/images/SportsIcons/volleybal.png";
import waterPolo from "assets/images/SportsIcons/waterpolo.png";
import wrestling from "assets/images/SportsIcons/worstelen.png";
import swimming from "assets/images/SportsIcons/zwemmen.png";
import iceHockey from "assets/images/SportsIcons/ijshockey.png";

export default [
  {
    title: "Sports",
    description: "Et utvalg av 16 idretter hvor jeg tilbyr veiledning om sportsstipend",
    items: [
      {
        image: americanFootball,
        name: "Amerikansk Fotball",
        badgeContent: "Menn",
        secondBadgeContent: "",
        route: "/no/Alle-idretter/Amerikansk-Fotball",
        popularity: 11,
        altText: "Ikon av amerikansk fotball"
      },
      {
        image: athletics,
        name: "Friidrett",
        badgeContent: "Menn",
        secondBadgeContent: "Kvinner",
        route: "/no/Alle-idretter/Friidrett",
        popularity: 4,
        altText: "Ikon av friidrett"
      },
      {
        image: basketball,
        name: "Basketball",
        badgeContent: "Menn",
        secondBadgeContent: "Kvinner",
        route: "/no/Alle-idretter/Basketball",
        popularity: 5,
        altText: "Ikon av basketball"
      },
      {
        image: golf,
        name: "Golf",
        badgeContent: "Menn",
        secondBadgeContent: "Kvinner",
        route: "/no/Alle-idretter/Golf",
        popularity: 9,
        altText: "Ikon av golf"
      },
      {
        image: gymnastics,
        name: "Gymnastikk",
        badgeContent: "Menn",
        secondBadgeContent: "Kvinner",
        route: "/no/Alle-idretter/Gymnastikk",
        popularity: 10,
        altText: "Ikon av gymnastikk"
      },
      {
        image: iceHockey,
        name: "Ishockey",
        badgeContent: "Menn",
        secondBadgeContent: "Kvinner",
        route: "/no/Alle-idretter/Ishockey",
        popularity: 2,
        altText: "Ikon av ishockey"
      },
      {
        image: fieldHockey, // Oppdatert til Landhockey
        name: "Landhockey", // Oppdatert til Landhockey
        badgeContent: "",
        secondBadgeContent: "Kvinner",
        route: "/no/Alle-idretter/Field-Hockey", // Oppdatert til Landhockey
        popularity: 15,
        altText: "Ikon av landhockey" // Oppdatert til Landhockey
      },
      {
        image: rowing,
        name: "Roing",
        badgeContent: "Menn",
        secondBadgeContent: "Kvinner",
        route: "/no/Alle-idretter/Roing",
        popularity: 7,
        altText: "Ikon av roing"
      },
      {
        image: soccer,
        name: "Fotball",
        badgeContent: "Menn",
        secondBadgeContent: "Kvinner",
        route: "/no/Alle-idretter/Fotball",
        popularity: 1,
        altText: "Ikon av fotball"
      },
      {
        image: softball,
        name: "Softball",
        badgeContent: "",
        secondBadgeContent: "Kvinner",
        route: "/no/Alle-idretter/Softball",
        popularity: 12,
        altText: "Ikon av softball"
      },
      {
        image: swimming,
        name: "Svømming",
        badgeContent: "Menn",
        secondBadgeContent: "Kvinner",
        route: "/no/Alle-idretter/Svømming",
        popularity: 3,
        altText: "Ikon av svømming"
      },
      {
        image: tennis,
        name: "Tennis",
        badgeContent: "Menn",
        secondBadgeContent: "Kvinner",
        route: "/no/Alle-idretter/Tennis",
        popularity: 8,
        altText: "Ikon av tennis"
      },
      {
        image: baseball,
        name: "Baseball",
        badgeContent: "Menn",
        route: "/no/Alle-idretter/Baseball",
        popularity: 13,
        altText: "Ikon av Baseball"
      },
      {
        image: volleyball,
        name: "Volleyball",
        badgeContent: "Menn",
        secondBadgeContent: "Kvinner",
        route: "/no/Alle-idretter/Volleyball",
        popularity: 6,
        altText: "Ikon av volleyball"
      },
      {
        image: waterPolo,
        name: "Vannpolo",
        badgeContent: "Menn",
        secondBadgeContent: "Kvinner",
        route: "/no/Alle-idretter/Vannpolo",
        popularity: 14,
        altText: "Ikon av vannpolo"
      },
      {
        image: wrestling,
        name: "Bryting",
        badgeContent: "Menn",
        secondBadgeContent: "Kvinner",
        route: "/no/Alle-idretter/Bryting",
        popularity: 16,
        altText: "Ikon av bryting"
      },
    ],
  },
];
