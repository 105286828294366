/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


// @mui material components
import React, { useState } from 'react'; // Step 1: Import useState


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Logos/NJCAA.png";
import post3 from "assets/images/Logos/NAIA.png";



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {

  const articleStructure = [
    { id: 'intro', title: 'NCAA: El Corazón del Deporte Universitario en Estados Unidos', isSubheader: false },
  
    { id: 'Capítulo 1', title: 'Orígenes y Crecimiento de la NCAA', isSubheader: true },
    { id: '1.1', title: 'Los Tres Rostros de la NCAA: Divisiones I, II y III', isSubheader: true },
    { id: '1.2', title: 'El Papel de la Academia', isSubheader: true },
  
    { id: 'Capítulo 2', title: 'Finanzas, Medios y Marketing', isSubheader: true },
    { id: '2.1', title: 'Desafíos y Futuro', isSubheader: true },
    { id: '2.2', title: 'Conclusión', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
     <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contents
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Ocultar</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="intro" mb={3}>


              
<MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>NCAA: El Corazón del Deporte Universitario en Estados Unidos</MKTypography>
</MKBox>
<MKBox id="Capítulo 1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Orígenes y Crecimiento de la NCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
La Asociación Nacional Deportiva Universitaria, mejor conocida como la NCAA, ha sido el centro del panorama deportivo universitario estadounidense durante más de un siglo. Fundada en 1906, esta organización ha evolucionado desde una pequeña entidad reguladora hasta una poderosa e influyente organización que impacta en la vida de miles de estudiantes-atletas, entrenadores y aficionados al deporte.
<br />
<br />
En los primeros años de la NCAA, el enfoque estaba principalmente en establecer reglas y garantizar la seguridad en el deporte. Sin embargo, con el paso del tiempo, el papel de la NCAA se expandió, involucrándose cada vez más en la regulación de todos los aspectos del deporte universitario, desde los requisitos académicos hasta los asuntos financieros.
</MKTypography>

<MKBox id="1.1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Los Tres Rostros de la NCAA: Divisiones I, II y III
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
La NCAA está dividida en tres divisiones, cada una con su propio carácter único y nivel de competencia:
<br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
División I:{' '}
</MKTypography>
es conocida como el pináculo del deporte universitario, donde las escuelas con los mayores presupuestos, las mejores instalaciones y los atletas más talentosos se reúnen. Las universidades en esta división suelen ser grandes y tienen recursos significativos a su disposición. Ofrecen becas deportivas completas y parciales y atraen a atletas de élite de todo el país y el mundo. La competencia es intensa y la presión por rendir es alta. Sin embargo, también es un escenario donde nacen las estrellas y donde los sueños de los jóvenes atletas pueden hacerse realidad. La división se subdivide en tres categorías: Football Bowl Subdivision (FBS), Football Championship Subdivision (FCS) y escuelas sin fútbol en el nivel de División I.  <br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
División II:{' '}
</MKTypography>
representa un enfoque más equilibrado del deporte universitario, donde el deporte, el rendimiento académico y el compromiso en el campus van de la mano. Las universidades en esta división suelen ser más pequeñas que las de la División I pero aún ofrecen un alto nivel de competencia. Los atletas en la División II pueden esperar apoyo tanto en el campo como en el aula, con el objetivo de proporcionar una experiencia universitaria completa.  <br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
División III:{' '}
</MKTypography>
por último, enfatiza el desarrollo integral del estudiante-atleta. Los deportes son importantes, pero solo son una parte de la experiencia universitaria más amplia. No se ofrecen becas deportivas en la División III, pero los atletas tienen la oportunidad de participar en deportes competitivos mientras se integran completamente en la vida académica y social en el campus.</MKTypography>

<MKBox id="1.2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
El Papel de la Academia
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
La NCAA toma muy en serio el rendimiento académico de sus estudiantes-atletas. Todos los atletas deben cumplir con estrictos requisitos académicos para ser elegibles para la competencia, y las universidades mismas son responsables de brindar el apoyo necesario para garantizar que los atletas tengan éxito en el aula. Esto incluye asesores académicos, tutores y programas de estudio especiales, todos diseñados para ayudar a los estudiantes-atletas a equilibrar sus obligaciones deportivas y académicas.                <br />
<br />
</MKTypography>

                                                  





                                  







<MKBox id="Capítulo 2" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Finanzas, Medios y Marketing
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              La NCAA también es una potencia financiera, con miles de millones de dólares fluyendo a través de la organización anualmente gracias a contratos de televisión, patrocinadores y mercancía. Estos ingresos se utilizan para financiar becas, mejorar instalaciones y mejorar la experiencia atlética general para los estudiantes atletas. Al mismo tiempo, la popularidad de los deportes universitarios ha llevado a lucrativos acuerdos de marketing y medios, convirtiendo a la atletismo universitario en un elemento básico de la cultura deportiva estadounidense.

<br />
                </MKTypography>

                <MKBox id="2.1" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Desafíos y Futuro
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              A pesar de su éxito, la NCAA también enfrenta desafíos significativos. Temas como el estatus amateur de los estudiantes atletas, la salud mental de los atletas y la búsqueda de la igualdad en el deporte son solo algunos de los temas que la organización debe abordar en su búsqueda de un mejor futuro para el atletismo universitario.
<br />
<br />
La NCAA sigue siendo un actor crucial en el sistema educativo estadounidense, y su impacto en la vida de los jóvenes atletas y en el mundo del deporte en general es innegable. A medida que mira hacia el futuro, está claro que la organización seguirá desempeñando un papel clave en la formación del panorama deportivo universitario en América.


                </MKTypography>

                <MKBox id="2.2" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Conclusión
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              La Asociación Nacional de Atletismo Universitario es más que una simple organización deportiva; es una parte crucial del panorama educativo y deportivo estadounidense. Con su rica historia, su estructura compleja y su profundo impacto en la vida de innumerables individuos, la NCAA sigue siendo una parte fascinante y vital de la cultura estadounidense. Su futuro estará sin duda lleno de desafíos y oportunidades, pero una cosa es segura: la NCAA seguirá luchando por la excelencia, tanto en el campo como en el aula.




<br />


                </MKTypography>


              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="NJCAA"
                    description="NJCAA: La Puerta de Entrada a Grandes Universidades y la NCAA"
                    action={{
                      type: "internal",
                      route: "/es/NJCAA",
                      color: "info",
                      label: "Leer Más",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="NAIA"
                    description="La NAIA: Una Ruta Alternativa en el Deporte Universitario en América"
                    action={{
                      type: "internal",
                      route: "/es/NAIA",
                      color: "info",
                      label: "Leer Más",
                    }}
                    />
                  </Grid>
                </Grid>
  
              </Grid>
          </Grid>
        </Container>
      </MKBox>
    );
  }
  
  export default Beschrijving;