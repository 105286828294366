/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Softbal1.jpeg";
import Image2 from "assets/images/Sports_Images/Softbal2.jpg";
import Image3 from "assets/images/Sports_Images/Softbal3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Becas de Softball en los Estados Unidos: Cómo Estudiar y Jugar Softball en América', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capítulo 1: Historia y Desarrollo del Softball en América', isSubheader: false },
    { id: '1.1', title: '1.1 Primeros Años y el Papel de las Escuelas y Universidades', isSubheader: true },
    { id: '1.2', title: '1.2 Crecimiento y Profesionalización', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capítulo 2: Softball Universitario Hoy', isSubheader: false },
    { id: '2.1', title: '2.1 Ligas y Conferencias Importantes', isSubheader: true },
    { id: '2.2', title: '2.2 Programas Destacados y Equipos Prestigiosos', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capítulo 3: De la Universidad a los Profesionales', isSubheader: false },
    { id: '3.1', title: '3.1 El Camino hacia el Profesionalismo', isSubheader: true },
    { id: '3.2', title: '3.2 Softball Profesional y Otras Oportunidades de Carrera', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capítulo 4: Influencias y Eventos Internacionales', isSubheader: false },
    { id: '4.1', title: '4.1 Influencias Extranjeras', isSubheader: true },
    { id: '4.2', title: '4.2 Estadounidenses en el Escenario Mundial', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capítulo 5: Becas Deportivas y el Futuro de los Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 La Importancia de las Becas', isSubheader: true },
    { id: '5.2', title: '5.2 Cómo Puede Ayudar Sport Scholarships America', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };
  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contenidos
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Ocultar</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>

























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Becas de Softball en los Estados Unidos: Cómo Estudiar y Jugar Softball en América
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Vista aérea de un estadio lleno en un juego de softbol universitario."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Un estadio lleno durante un juego
      </figcaption>
    </figure>
    El softbol es un deporte que se originó en los Estados Unidos a finales del siglo XIX. Es una variación del béisbol pero con una pelota más grande, un campo más pequeño y entradas más cortas. El softbol se juega por dos equipos de nueve jugadores, turnándose para batear y defender. El objetivo es anotar más carreras que el equipo contrario corriendo alrededor de las cuatro bases después de golpear la pelota.
    <br />
    <br />
    El softbol rápidamente ganó popularidad en los EE. UU., especialmente entre las escuelas y universidades. Se veía como un deporte que promovía la forma física, la agudeza mental y la interacción social. También atrajo mucho talento y competencia, especialmente a nivel universitario. El softbol universitario representa el nivel más alto de softbol en los EE. UU., donde los estudiantes-atletas compiten por su universidad o colegio. El softbol universitario ofrece prestigio, reconocimiento y becas para los mejores jugadores.
    <br />
    <br />
    Sportbeursamerika.nl es una organización dedicada a ayudar a los jóvenes atletas a asegurar becas de softbol. Sportbeursamerika.nl cuenta con un equipo de expertos que pueden orientarte para encontrar la mejor universidad o colegio en función de tu perfil académico y atlético. También pueden ayudarte a preparar tu solicitud, crear tu video, conectarte con entrenadores y gestionar tu visa. Sportbeursamerika.nl ya ha ayudado a muchos casos de éxito a alcanzar su sueño de estudiar y jugar al softbol en América.
    <br />
    <br />
    Si quieres saber más sobre el softbol en América y cómo obtener una beca de softbol, ¡sigue leyendo!
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>

{/* Capítulos principales y subcapítulos con texto provisional */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 1: Historia y Desarrollo del Softbol en América
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El softbol es un deporte que se originó en los Estados Unidos pero se ha extendido por todo el mundo. Es un deporte que ha sufrido muchos cambios e innovaciones, con numerosas figuras influyentes y eventos. En este capítulo, te proporcionaremos una visión general de la historia y el desarrollo del softbol en América, desde sus primeros años hasta la actualidad.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Primeros Años y el Papel de las Escuelas y Universidades
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      El softbol se jugó por primera vez en 1887 en Chicago como una variante de béisbol en interiores. Fue concebido por un grupo de hombres que celebraban el Día de Acción de Gracias en un gimnasio. Usaron un guante de boxeo como pelota y un palo de escoba como bate. El juego rápidamente ganó popularidad entre varios grupos de personas, incluidos bomberos, trabajadores de fábricas y miembros de la iglesia.
      <br />
      <br />
      En los primeros años del softbol, no había reglas ni dimensiones estandarizadas. El juego se adaptaba al espacio disponible y al número de jugadores. Había varias variantes de softbol, como mushball, kittenball, diamond ball y indoor baseball. El término "softbol" se utilizó oficialmente en 1926.
      <br />
      <br />
      Las escuelas y universidades jugaron un papel significativo en el desarrollo del softbol. Consideraban el softbol como un deporte adecuado tanto para niños como para niñas, promoviendo la educación física y la recreación. También organizaron los primeros equipos escolares y universitarios y las primeras competiciones organizadas. Una de las primeras competiciones escolares fue la Liga Atlética de Escuelas Públicas en la ciudad de Nueva York, que comenzó el softbol para niñas en 1913. Una de las primeras competiciones universitarias fue la Asociación Atlética Colegial Nacional (NCAA), que comenzó el softbol femenino en 1957.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crecimiento y Profesionalización
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      El softbol se convirtió en un deporte popular y prestigioso en América, especialmente entre las mujeres. Esto se debió en parte a la introducción del Título IX en 1972, una ley que garantizaba igualdad de oportunidades para las mujeres en la educación, incluidas las becas deportivas. Esto proporcionó más oportunidades a las estudiantes-atletas femeninas para jugar softbol a nivel universitario.
      <br />
      <br />
      Además del softbol universitario, surgieron ligas de softbol profesionales en América. La primera liga profesional de softbol femenino fue la Asociación Internacional de Softbol Profesional Femenino (IWPSA), fundada en 1976. Esta liga estaba formada por diez equipos de diferentes ciudades, como Nueva York, Chicago y Los Ángeles. La liga duró cuatro temporadas, hasta 1980.
      <br />
      <br />
      La segunda liga profesional de softbol femenino fue la Liga Profesional de Softbol Femenino (WPSL), establecida en 1997. Esta liga contaba con seis equipos de varios estados, incluidos Florida, Georgia y Virginia. La liga luego cambió su nombre a National Pro Fastpitch (NPF). La NPF estuvo activa hasta 2021 y sufrió dos temporadas canceladas debido a COVID-19. Se lanzó una nueva liga de softbol, la Women's Professional Fastpitch (WPF), en 2022.
      <br />
      <br />
      Las ligas profesionales de softbol tuvieron un impacto significativo en el softbol universitario. Muchas jugadoras profesionales eran antiguas estudiantes-atletas que continuaron sus carreras después de graduarse. Algunas jugadoras profesionales también se convirtieron en entrenadoras o instructoras de equipos universitarios o jugadores individuales. Además, muchas jugadoras profesionales se distinguieron como modelos a seguir y fuentes de inspiración para jóvenes jugadoras de softbol.
      <br />
      <br />
      Algunos ejemplos de figuras clave en el mundo del softbol son:

    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Jennie Finch: Ex lanzadora de los Arizona Wildcats y del equipo nacional de Estados Unidos. Ganó dos medallas olímpicas, oro en 2004 y plata en 2008. También jugó para los Chicago Bandits en la NPF. Es considerada una de las mejores lanzadoras de todos los tiempos y un ícono para el deporte.
        </li>
        <li>
          Cat Osterman: Ex lanzadora de los Texas Longhorns y del equipo nacional de Estados Unidos. También ganó dos medallas olímpicas, oro en 2004 y plata en 2008. Jugó para el Rockford Thunder y el Houston Scrap Yard Dawgs en la NPF. Luego se convirtió en entrenadora de los Texas State Bobcats.
        </li>
        <li>
          Monica Abbott: Ex lanzadora de los Tennessee Volunteers y del equipo nacional de Estados Unidos. Ganó una medalla olímpica de plata en 2008. Más tarde jugó para el Scrap Yard Fast Pitch en la NPF y los Toyota Red Terriers en Japón. Es la primera jugadora de softbol femenino en firmar un contrato de un millón de dólares.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>

                                              





                                  






<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 2: Softbol Universitario Hoy
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El softbol es uno de los deportes más populares y competitivos a nivel universitario en América. Cada año, más de mil equipos participan en la temporada de softbol universitario, que se extiende de febrero a junio. El punto culminante de la temporada es la Serie Mundial Universitaria de Softbol Femenino (WCWS), el campeonato nacional de softbol universitario.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Ligas y Conferencias Importantes
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Jugadoras de Tennessee y Alabama en acción durante la Serie Mundial Universitaria."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
         }}>
          Jugadoras en acción durante la Serie Mundial Universitaria
        </figcaption>
      </figure>
      El softbol universitario está regulado por la Asociación Nacional de Atletismo Universitario (NCAA), una organización responsable de organizar y gestionar programas deportivos para universidades y colegios en América. La NCAA divide a las universidades en tres divisiones según su tamaño, presupuesto y rendimiento deportivo. Estas divisiones son:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          División I: La división más alta y prestigiosa, con más de 300 universidades y más de 10,000 estudiantes-atletas. La División I ofrece más becas, las mejores instalaciones y la competencia más fuerte. La División I consta de 32 conferencias, grupos regionales de universidades que juegan partidos entre sí. Algunos ejemplos de conferencias son la Conferencia del Sureste (SEC), la Conferencia Pac-12 y la Conferencia Big Ten.
        </li>
        <li>
          División II: La división intermedia, con más de 300 universidades y más de 8,000 estudiantes-atletas. La División II ofrece menos becas pero aún proporciona buenas instalaciones y competencia fuerte. La División II consta de 23 conferencias, como la Conferencia Great Lakes Valley (GLVC), la Conferencia Sunshine State y la Asociación Atlética Universitaria de California (CCAA).
        </li>
        <li>
          División III: La división más baja, con más de 400 universidades y más de 6,000 estudiantes-atletas. La División III no ofrece becas pero proporciona programas académicos fuertes y una experiencia deportiva. La División III consta de 43 conferencias, incluida la Conferencia Atlética Universitaria del Nueva Inglaterra (NESCAC), la Conferencia Centennial y la Asociación Atlética Universitaria (UAA).
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Cada división tiene su propio campeonato al final de la temporada, donde los mejores equipos de cada conferencia califican para un torneo de estilo eliminatorio. El campeonato de la División I se llama la Serie Mundial Universitaria de Softbol Femenino (WCWS), celebrada en Oklahoma City. El campeonato de la División II se llama el Campeonato de Softbol de la División II de la NCAA, celebrado en Denver. El campeonato de la División III se llama el Campeonato de Softbol de la División III de la NCAA, celebrado en Salem.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programas Destacados y Equipos Prestigiosos
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      El softbol universitario ha producido muchos programas destacados y equipos prestigiosos que se han distinguido por su historia exitosa, sus jugadores talentosos y sus entrenadores influyentes. Algunos de estos programas y equipos incluyen:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          UCLA Bruins: Este programa se considera uno de los más exitosos en la historia del softbol universitario, con un impresionante número de títulos nacionales en su haber. A lo largo de los años, han producido medallistas olímpicos, incluyendo nombres conocidos como Lisa Fernández, Natasha Watley y Stacey Nuveman.
        </li>
        <li>
          Oklahoma Sooners: Los Oklahoma Sooners son un equipo notablemente fuerte y han entregado consistentemente actuaciones destacadas. Han ganado múltiples títulos nacionales y han tenido impresionantes rachas ganadoras en el pasado.
        </li>
        <li>
          Arizona Wildcats: El programa de softbol de los Arizona Wildcats cuenta con una impresionante lista de títulos nacionales. También tienen un respetado entrenador, Mike Candrea, con un excelente historial de numerosas victorias e incluso oro olímpico como entrenador del equipo nacional de softbol de EE. UU.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estos programas y equipos contribuyen al desarrollo de jugadoras de softbol ofreciéndoles un alto nivel de entrenamiento, coaching y competencia. También ayudan a las jugadoras a mejorar sus habilidades, conocimientos y mentalidad, preparándolas para el siguiente paso en sus carreras.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  







<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 3: De la Universidad a los Profesionales
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El softbol es un deporte que ofrece muchas oportunidades para que los estudiantes atletas continúen sus carreras después de su período universitario. Algunos estudiantes atletas eligen jugar softbol profesional, mientras que otros exploran otras posibilidades de carrera. En este capítulo, discutimos el camino hacia el profesionalismo, las ligas de softbol profesional y otras opciones de carrera para jugadoras de softbol.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 El Camino hacia el Profesionalismo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Jugar softbol universitario es una buena preparación para jugar softbol profesional. Muchas estudiantes atletas que sobresalen en el softbol universitario son reclutadas o seleccionadas por equipos profesionales. Esto significa que se les ofrece un contrato para jugar para un equipo específico, generalmente a cambio de un salario y otros beneficios.
      <br />
      <br />
      Sin embargo, jugar softbol profesional no es fácil. La competencia es muy alta y las jugadoras deben adaptarse constantemente a nuevas situaciones, oponentes y desafíos. Además, los salarios de las jugadoras de softbol profesional no son muy altos en comparación con otros deportes. Según un informe de 2016, el salario promedio de una jugadora de la NPF fue de aproximadamente $5,000 a $6,000 por temporada. Esto significa que muchas jugadoras profesionales necesitan tener un segundo trabajo u otra fuente de ingresos para llegar a fin de mes.
      <br />
      <br />
      Por lo tanto, algunas estudiantes atletas eligen no jugar softbol profesional, sino completar sus estudios o seguir otra carrera. Esto también puede ser una buena opción porque el softbol universitario desarrolla muchas habilidades y cualidades que son útiles en otros campos, como liderazgo, trabajo en equipo, comunicación, disciplina y resolución de problemas.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Softbol Profesional y Otras Oportunidades de Carrera
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Como se mencionó anteriormente, la principal liga de softbol profesional en América es la National Pro Fastpitch (NPF). La NPF actualmente consta de cinco equipos: los Aussie Peppers de Minnesota, los Canadian Wild de Southern Illinois, los Chicago Bandits, los Cleveland Comets y los USSSA Pride. La NPF también tiene una asociación con la Asociación China de Softbol (CSA), que envía dos equipos a la NPF: los Beijing Eagles y los Shanghai Shengli.
      <br />
      <br />
      Sin embargo, la NPF no es la única liga de softbol profesional en el mundo. También hay otras ligas en países como Japón, Australia, Italia y México. Algunas jugadoras estadounidenses eligen jugar en estas ligas porque reciben mejores salarios o porque quieren experimentar una cultura diferente.
      <br />
      <br />
      Además de jugar softbol profesional, hay otras oportunidades de carrera para jugadoras de softbol. Algunos ejemplos incluyen:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Entrenamiento: Algunas jugadoras se convierten en entrenadoras para equipos escolares, universitarios o profesionales. Utilizan sus conocimientos y experiencia para entrenar, motivar y asesorar a otras jugadoras.
        </li>
        <li>
          Educación: Algunas jugadoras se convierten en profesoras o profesoras en varios niveles y en diferentes campos. Utilizan su formación académica y sus habilidades pedagógicas para educar y guiar a los estudiantes.
        </li>
        <li>
          Medios de Comunicación: Algunas jugadoras se convierten en periodistas, comentaristas o analistas para varios medios de comunicación. Utilizan sus habilidades de comunicación y su conocimiento del deporte para informar sobre noticias y eventos de softbol.
        </li>
        <li>
          Emprendimiento: Algunas jugadoras se convierten en empresarias o gerentes en diferentes sectores e industrias. Utilizan sus habilidades creativas y empresariales para desarrollar y vender productos o servicios.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Como puedes ver, hay muchas oportunidades para que las jugadoras de softbol utilicen su pasión y talento en diferentes ámbitos. La clave es establecer tus objetivos, explorar tus opciones y perseguir tus sueños.
    </MKTypography>
  </MKBox>
</MKBox>
                                   






                                  








<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 4: Influencias y Eventos Internacionales
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El softbol no es solo un deporte estadounidense, sino también un deporte internacional. Se juega softbol en más de 140 países en todo el mundo y ha tenido un impacto significativo en la cultura deportiva y el desarrollo en diversas regiones. En este capítulo, discutiremos las influencias extranjeras en el softbol en América y las influencias estadounidenses en el softbol en el escenario mundial.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Influencias Extranjeras
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Equipo de softbol de la Universidad de Oklahoma celebra ganar el título de la NCAA."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Jugadoras de Oklahoma celebran el campeonato nacional
        </figcaption>
      </figure>
      El softbol en América debe mucho a las influencias extranjeras que han enriquecido y desafiado el deporte. Algunas de estas influencias incluyen:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Japón: Japón es uno de los países más fuertes en softbol, tanto a nivel profesional como universitario. Japón tiene una larga tradición de softbol que se remonta a la década de 1930. Japón también tiene su propia liga profesional de softbol, la Liga de Softbol de Japón (JSL), que consta de 12 equipos. Japón ha tenido un impacto significativo en el softbol estadounidense al introducir nuevas técnicas, tácticas y estilos de juego. Japón también tiene muchos programas de intercambio y asociaciones con universidades y equipos estadounidenses, brindando a muchas jugadoras la oportunidad de aprender unas de otras.
        </li>
        <li>
          Australia: Australia es otro país que desempeña un papel significativo en el softbol, especialmente a nivel internacional. Australia tiene su propio equipo nacional de softbol, el Aussie Spirit, que ha ganado múltiples medallas en los Juegos Olímpicos, Campeonatos Mundiales y la Copa Mundial de Softbol. Australia también tiene muchas jugadoras que compiten en la NPF, como Stacey Porter, Kaia Parnaby y Taylah Tsitsikronis. Australia ha tenido una influencia sustancial en el softbol estadounidense al mostrar un alto nivel de habilidad atlética, perseverancia y trabajo en equipo.
        </li>
        <li>
          Canadá: Canadá, como país vecino de América, también tiene una fuerte cultura de softbol. Canadá tiene su propio equipo nacional de softbol, las Canadian Wild, que también participa en la NPF. Canadá también tiene muchas jugadoras que han jugado o actualmente juegan para universidades estadounidenses, como Danielle Lawrie, Jenn Salling y Sara Groenewegen. Canadá ha tenido una influencia significativa en el softbol estadounidense al compartir una pasión, visión y aprecio comunes por el deporte.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estas influencias extranjeras han contribuido al desarrollo del softbol en América al crear más diversidad, competencia y colaboración. También han contribuido al desarrollo de jugadoras universitarias al exponerlas a diferentes culturas, perspectivas y experiencias.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Estadounidenses en el Escenario Mundial
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      El softbol en América también ha tenido un impacto significativo en el softbol en el escenario mundial. Estados Unidos ha sido un país líder en softbol durante muchos años, tanto profesional como universitariamente. Estados Unidos ha producido muchas historias de éxito y fuentes de inspiración para otros países y jugadoras.
      <br />
      <br />
      Estas historias de éxito han tenido una influencia significativa en el softbol en el escenario mundial al mostrar un alto nivel de rendimiento, excelencia y liderazgo. También han ganado mucho respeto y admiración de otros países y jugadoras, que los ven como ejemplos y retadores.
      <br />
      <br />
      El papel del softbol universitario en la preparación para competiciones internacionales es crucial. El softbol universitario proporciona a las jugadoras una base sólida de habilidades, conocimientos y experiencia que pueden utilizar para competir con las mejores jugadoras del mundo. El softbol universitario también ayuda a descubrir, desarrollar y promover el talento y el potencial de las jugadoras para que puedan sobresalir en el más alto nivel.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  


<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Becas Deportivas y el Futuro de los Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Las becas deportivas ofrecen numerosas oportunidades para que los jóvenes atletas muestren su talento y desarrollen sus habilidades. Una de las oportunidades más significativas es obtener una beca deportiva para estudiar y competir a nivel universitario. En este capítulo, discutiremos la importancia de las becas deportivas y cómo Sport Scholarships America puede ayudarte a hacer realidad tu sueño.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 La Importancia de las Becas
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Una beca deportiva es un apoyo financiero proporcionado por una universidad a un estudiante-atleta para combinar estudios y deporte. Puede cubrir los costos de matrícula, libros, alojamiento, comidas y otros gastos. Además, puede ofrecer beneficios como tutoría académica, atención médica, instalaciones de entrenamiento y equipo.
      <br />
      <br />
      Obtener una beca deportiva es competitivo, con muchos factores que influyen en el proceso de adjudicación, incluyendo logros académicos, rendimiento deportivo, personalidad, motivación, liderazgo y adaptabilidad. Por lo tanto, es importante prepararse bien y destacarse entre la competencia.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Cómo Puede Ayudar Sport Scholarships America
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo de Sport Scholarships America, que muestra equipamiento deportivo y una gorra de graduación, representando el enfoque en becas deportivas para estudiantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America es una organización especializada en guiar a jóvenes talentos deportivos hacia becas deportivas. Con expertos apasionados y experimentados, Sport Scholarships America apoya a los estudiantes-atletas en cada paso del camino, desde la orientación inicial hasta la colocación final. Sus servicios incluyen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Una entrevista personal para discutir los deseos, posibilidades y expectativas del estudiante-atleta.</li>
        <li>Una evaluación profesional del nivel académico y deportivo del estudiante-atleta.</li>
        <li>Una extensa red de contactos con entrenadores, universidades y colegios.</li>
        <li>Promoción efectiva del perfil y videos del estudiante-atleta ante entrenadores potenciales.</li>
        <li>Asesoramiento experto sobre las mejores opciones basadas en las preferencias, metas y presupuesto del estudiante-atleta.</li>
        <li>Negociación de las mejores ofertas de becas en nombre del estudiante-atleta.</li>
        <li>Asistencia en la solicitud de documentos necesarios, como visa, expediente académico, diploma y pruebas de admisión.</li>
        <li>Preparación para la vida del estudiante-atleta durante su beca, incluyendo cultura, idioma, reglas y consejos.</li>
        <li>Seguimiento y atención posterior durante el período de la beca.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      ¿Quieres explorar oportunidades de becas y alcanzar tus metas deportivas y académicas? <Link to="/es/Servicios-Productos">Consulta la gama completa</Link> de servicios ofrecidos por Sport Scholarships America y programa una consulta hoy mismo.
    </MKTypography>
  </MKBox>
</MKBox>

        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;