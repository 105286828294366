/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React from "react";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

function StatsOne() {
  return (
    <MKBox component="section" py={8}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard 
            count={100} 
            suffix="+"
            title="Students:"
            description="Successfully Guided to Their Dream Universities"  
            onClick={() => console.log('Clicked on 100')}
            />
          </Grid>
          <Grid item xs={12} md={4} >
            <DefaultCounterCard
              count={70}
              title="Universities:"
              suffix="+"
              description="Students Successfully Placed Across America"
              onClick={() => console.log('Clicked on 70')}

            />
          </Grid>
          <Grid item xs={12} md={4}>
          <Link to="/All-Sports">
              <DefaultCounterCard
                count={10}
                suffix="+"
                title="Sports:"
                description="Guiding Talents in Various Disciplines"
              />
            </Link>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default StatsOne;


