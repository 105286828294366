/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================
* Page du produit : https://www.creative-tim.com/product/material-kit-pro-react
* Droits d'auteur 2023 Creative Tim (https://www.creative-tim.com)
Codé par www.creative-tim.com
=========================================================
*/

// Composants matériels de @mui
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Composants de Material Kit 2 PRO React
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post2 from "assets/images/TijdUS.png";
import "../../../../H1asH3.css"

function Profil() {
  return (
    <MKBox composant="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox 
                display="flex" 
                flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} 
                justifyContent="space-between" 
                alignItems="center" 
                mb={1}
              >
                <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Partie 3: De Retour aux Pays-Bas</MKTypography>
              </MKBox>
              <MKTypography variant="body1" fontWeight="light" color="text">
                <br />
                À mon retour aux Pays-Bas, j'ai vécu un mélange de nostalgie et de détermination. Ma passion profondément enracinée pour le soccer était maintenant rejointe par un désir renouvelé de défis académiques. Amsterdam est devenue le décor de ma prochaine aventure, où j'ai poursuivi une maîtrise en Finance Quantitative, un domaine qui stimulait à la fois mon esprit analytique et mes ambitions.
                <br /><br />
                Après avoir terminé mes études, j'ai commencé à travailler en tant que consultant. Bien que le monde des chiffres et de l'analyse me fascinait, je sentais qu'il manquait quelque chose. Les échos de mon voyage américain continuaient de résonner en moi, me rappelant l'opportunité unique que j'avais vécue, une opportunité que je souhaitais pour d'autres.
                <br /><br />
                En conséquence, j'ai décidé d'utiliser mes expériences et mes connaissances pour guider d'autres jeunes athlètes ambitieux. Avant de lancer cette entreprise, j'ai collaboré avec des entraîneurs, des athlètes, d'anciens athlètes et d'autres membres de mon vaste réseau en Amérique. Ces discussions ont aidé à la fois moi et les futurs étudiants à comprendre la meilleure approche pour ces opportunités.
                <br /><br />
                Pendant la pandémie de COVID-19, j'ai collaboré avec une agence de premier plan aux États-Unis. Bien que j'aie pu aider moins d'étudiants internationaux en raison de la disponibilité limitée de bourses, j'ai utilisé mon expertise pour aider les étudiants américains. Cette expérience a non seulement considérablement élargi mon réseau, mais elle a également fourni des informations sur le marché des bourses américaines, que je peux désormais appliquer plus efficacement en Europe.
                <br /><br />
                Les défis de la pandémie ont renforcé ma mission de guider de jeunes talents vers les universités américaines et les bourses sportives. Malgré les difficultés, cela m'a apporté un sentiment d'accomplissement que je n'avais jamais ressenti auparavant. C'était ma manière de rendre à la communauté qui m'avait autrefois accueilli chaleureusement aux États-Unis.
                <br /><br />
                C'est mon histoire, et je suis fier de pouvoir aider d'autres personnes à écrire leurs propres histoires dans le pays des possibilités infinies. Si vous souhaitez en savoir plus sur mon entreprise et le voyage de mes clients pour réaliser leurs rêves, continuez à lire sur mon site web. Si vous avez des questions ou souhaitez me contacter, n'hésitez pas à le faire.
              </MKTypography>
              <hr style={{ margin: '20px 0' }} />
              <Grid item xs={12} lg={6} mb={{ xs: 3, lg: 0 }}>
                <TransparentBlogCard
                  image={post2}
                  title="Partie 2: Mon Temps en Amérique"
                  action={{
                    type: "interne",
                    route: "/fr/A-propos-de-Moi/Mon-Temps-en-Amerique",
                    couleur: "info",
                    étiquette: "Lire la suite",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profil;
