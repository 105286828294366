// SelectPicker.js

import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import { useNavigate } from "react-router-dom";
import states from "../../../Staten/sections/statesData"; // Import the 'states' array

function SelectPicker() {
  const navigate = useNavigate();

  const handleStateSelect = (event, value) => {
    if (value) {
      // Use navigate to go to the correct URL with a hash fragment
      navigate(`/de/Bundesstaaten#${value}`);
    }
  };

  return (
    <MKBox component="section" py={8}>
      <Container>
        <Grid container justifyContent="center">
          <Autocomplete
            defaultValue="Wählen Sie einen Bundesstaat für weitere Informationen"
            options={states.map((state) => state.name)} // Provide an array of state names
            sx={{ width: 300 }}
            onChange={handleStateSelect}
            renderInput={(params) => <MKInput {...params} variant="standard" />}
          />
        </Grid>
      </Container>
    </MKBox>
  );
}

export default SelectPicker;
