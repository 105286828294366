import americanFootball from "assets/images/SportsIcons/americanfootball.png";
import athletics from "assets/images/SportsIcons/atletiek.png";
import basketball from "assets/images/SportsIcons/basketbal.png";
import golf from "assets/images/SportsIcons/golf.png";
import fieldHockey from "assets/images/SportsIcons/hockey.png"; // Updated to Field Hockey
import baseball from "assets/images/SportsIcons/honkbal.png";
import rowing from "assets/images/SportsIcons/roeien.png";
import softball from "assets/images/SportsIcons/softbal.png"
import tennis from "assets/images/SportsIcons/tennis.png";
import gymnastics from "assets/images/SportsIcons/turnen.png";
import soccer from "assets/images/SportsIcons/voetbal.png";
import volleyball from "assets/images/SportsIcons/volleybal.png";
import waterPolo from "assets/images/SportsIcons/waterpolo.png";
import wrestling from "assets/images/SportsIcons/worstelen.png";
import swimming from "assets/images/SportsIcons/zwemmen.png";
import iceHockey from "assets/images/SportsIcons/ijshockey.png";

export default [
  {
    title: "Sports",
    description: "Et udvalg af 16 sportsgrene, hvor jeg tilbyder vejledning til en sportsstipendium",
    items: [
      {
        image: americanFootball,
        name: "Amerikansk fodbold",
        badgeContent: "Mænd",
        secondBadgeContent: "",
        route: "/da/Alle-Sportsgrene/Amerikansk-fodbold",
        popularity: 11,
        altText: "Ikon af amerikansk fodbold"
      },
      {
        image: athletics,
        name: "Atletik",
        badgeContent: "Mænd",
        secondBadgeContent: "Kvinder",
        route: "/da/Alle-Sportsgrene/Atletik",
        popularity: 7,
        altText: "Ikon af atletik"
      },
      {
        image: basketball,
        name: "Basketball",
        badgeContent: "Mænd",
        secondBadgeContent: "Kvinder",
        route: "/da/Alle-Sportsgrene/basketball",
        popularity: 10,
        altText: "Ikon af basketball"
      },
      {
        image: golf,
        name: "Golf",
        badgeContent: "Mænd",
        secondBadgeContent: "Kvinder",
        route: "/da/Alle-Sportsgrene/Golf",
        popularity: 4,
        altText: "Ikon af golf"
      },
      {
        image: fieldHockey,
        name: "Field Hockey",
        badgeContent: "",
        secondBadgeContent: "Kvinder",
        route: "/da/Alle-Sportsgrene/Field-Hockey",
        popularity: 12,
        altText: "Ikon af field hockey"
      },
      {
        image: baseball,
        name: "Baseball",
        badgeContent: "Mænd",
        secondBadgeContent: "",
        route: "/da/Alle-Sportsgrene/Baseball",
        popularity: 14,
        altText: "Ikon af baseball"
      },
      {
        image: iceHockey,
        name: "Ishockey",
        badgeContent: "Mænd",
        secondBadgeContent: "Kvinder",
        route: "/da/Alle-Sportsgrene/Ishockey",
        popularity: 2,
        altText: "Ikon af ishockey"
      },
      {
        image: rowing,
        name: "Roning",
        badgeContent: "Mænd",
        secondBadgeContent: "Kvinder",
        route: "/da/Alle-Sportsgrene/Roning",
        popularity: 6,
        altText: "Ikon af roning"
      },
      {
        image: softball,
        name: "Softball",
        badgeContent: "",
        secondBadgeContent: "Kvinder",
        route: "/da/Alle-Sportsgrene/Softball",
        popularity: 15,
        altText: "Ikon af softball"
      },
      {
        image: tennis,
        name: "Tennis",
        badgeContent: "Mænd",
        secondBadgeContent: "Kvinder",
        route: "/da/Alle-Sportsgrene/Tennis",
        popularity: 3,
        altText: "Ikon af tennis"
      },
      {
        image: gymnastics,
        name: "Gymnastik",
        badgeContent: "Mænd",
        secondBadgeContent: "Kvinder",
        route: "/da/Alle-Sportsgrene/Gymnastik",
        popularity: 8,
        altText: "Ikon af gymnastik"
      },
      {
        image: soccer,
        name: "Fodbold",
        badgeContent: "Mænd",
        secondBadgeContent: "Kvinder",
        route: "/da/Alle-Sportsgrene/Fodbold",
        popularity: 1,
        altText: "Ikon af fodbold"
      },
      {
        image: volleyball,
        name: "Volleyball",
        badgeContent: "Mænd",
        secondBadgeContent: "Kvinder",
        route: "/da/Alle-Sportsgrene/Volleyball",
        popularity: 9,
        altText: "Ikon af volleyball"
      },
      {
        image: waterPolo,
        name: "Vandpolo",
        badgeContent: "Mænd",
        secondBadgeContent: "Kvinder",
        route: "/da/Alle-Sportsgrene/Vandpolo",
        popularity: 13,
        altText: "Ikon af vandpolo"
      },
      {
        image: wrestling,
        name: "Brydning",
        badgeContent: "Mænd",
        secondBadgeContent: "Kvinder",
        route: "/da/Alle-Sportsgrene/Brydning",
        popularity: 16,
        altText: "Ikon af brydning"
      },
      {
        image: swimming,
        name: "Svømning",
        badgeContent: "Mænd",
        secondBadgeContent: "Kvinder",
        route: "/da/Alle-Sportsgrene/Svømning",
        popularity: 5,
        altText: "Ikon af svømning"
      },
    ],
  },
];
