/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 PRO React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';

// Pages
import Faq from "Languages/Svenska/pages/Contact/FAQs";
import Author from "Languages/Svenska/pages/Over/Overmij/Profiel";
import VirtualRealityPage from "Languages/Svenska/pages/Content/Podcast";
import AllSports from "Languages/Svenska/pages/All Sports/Overzicht";
import Contactformulier from "Languages/Svenska/pages/Contact/Contactformulier"
import Jeugd from "Languages/Svenska/pages/Over/Overmij/Jeugd";
import Amerika from "Languages/Svenska/pages/Over/Overmij/Amerika";
import NaUSA from "Languages/Svenska/pages/Over/Overmij/NaUSA";
import DienstenProducten from "Languages/Svenska/pages/Aanbod/Diensten_producten";

import Timeline from "Languages/Svenska/pages/Aanbod/Traject";
import AmericanFootball from "Languages/Svenska/pages/All Sports/Sports/AmericanFootball";
import Athletics from "Languages/Svenska/pages/All Sports/Sports/Track and Field";
import Basketball from "Languages/Svenska/pages/All Sports/Sports/Basketball";
import Golf from "Languages/Svenska/pages/All Sports/Sports/Golf";
import Hockey from "Languages/Svenska/pages/All Sports/Sports/Field Hockey";
import Baseball from "Languages/Svenska/pages/All Sports/Sports/Baseball";
import Icehockey from "Languages/Svenska/pages/All Sports/Sports/Ice Hockey";
import Rowing from "Languages/Svenska/pages/All Sports/Sports/Rowing";
import Softbal from "Languages/Svenska/pages/All Sports/Sports/Softbal";
import Tennis from "Languages/Svenska/pages/All Sports/Sports/Tennis";
import Gymnastics from "Languages/Svenska/pages/All Sports/Sports/Gymnastics";
import Soccer from "Languages/Svenska/pages/All Sports/Sports/Soccer";
import Volleyball from "Languages/Svenska/pages/All Sports/Sports/Volleyball";
import Waterpolo from "Languages/Svenska/pages/All Sports/Sports/Waterpolo";
import Wrestling from "Languages/Svenska/pages/All Sports/Sports/Wrestling";
import Swimming from "Languages/Svenska/pages/All Sports/Sports/Swimming";
import CompanyTimeline from "Languages/Svenska/pages/Over/Over Sport Scholarships America";
import Kenniscentrum from "Languages/Svenska/pages/Content/Kenniscentrum";





const routes = [
  {
    name: "Sporter",
    icon: <SportsSoccerIcon></SportsSoccerIcon>,
    collapse: [
      {
        name: "All Sporter",
        description: "Upptäck Alla Sporter",
        route: "/sv/Alla-sporter",
        component: <AllSports />,
        dropdown: true,
        collapse: [
          {
            name: "Amerikansk Fotboll",
            route: "/sv/Alla-sporter/Amerikansk-Fotboll",
            component: <AmericanFootball />,
          },
   
          {
            name: "Baseball",
            route: "/sv/Alla-sporter/Baseball",
            component: <Baseball />,
          },
          {
            name: "Basketboll",
            route: "/sv/Alla-sporter/Basketboll",
            component: <Basketball />,
          },
          {
            name: "Brottning",
            route: "/sv/Alla-sporter/Brottning",
            component: <Wrestling />,
          },
          {
            name: "Fält Hockey",
            route: "/sv/Alla-sporter/Fält-Hockey",
            component: <Hockey />,
          },
          {
            name: "Fotboll",
            route: "/sv/Alla-sporter/Fotboll",
            component: <Soccer />,
          },
          {
            name: "Friidrott",
            route: "/sv/Alla-sporter/Friidrott",
            component: <Athletics />,
          },
          {
            name: "Golf",
            route: "/sv/Alla-sporter/Golf",
            component: <Golf />,
          },
          {
            name: "Gymnastik",
            route: "/sv/Alla-sporter/Gymnastik",
            component: <Gymnastics />,
          },
        
          {
            name: "Ishockey",
            route: "/sv/Alla-sporter/Ishockey",
            component: <Icehockey />,
          },
          {
            name: "Roddning",
            route: "/sv/Alla-sporter/Roddning",
            component: <Rowing />,
          },
          {
            name: "Simning",
            route: "/sv/Alla-sporter/Simning",
            component: <Swimming />,
          },
          {
            name: "Softball",
            route: "/sv/Alla-sporter/Softball",
            component: <Softbal />,
          },
          {
            name: "Tennis",
            route: "/sv/Alla-sporter/Tennis",
            component: <Tennis />,
          },
          {
            name: "Vattenpolo",
            route: "/sv/Alla-sporter/Vattenpolo",
            component: <Waterpolo />,
          },
          {
            name: "Volleyboll",
            route: "/sv/Alla-sporter/Volleyboll",
            component: <Volleyball />,
          },
       
        ],
      },
   

],
  },     

    {
      name: "Om",
      icon: <Icon>person</Icon>,
    
      collapse: [
        {
          name: "Om Mig",
          description: "En introduktion",
          route: "/sv/Om-mig",
          component: <Author />,
        
          dropdown: true,
          collapse: [
            {
              name: "Del 1: Att Växa Upp i Nederländerna",
              route: "/sv/Om-mig/Uppväxt",
              component: <Jeugd />,
            },

            {
              name: "Del 2: Min Tid i Amerika",
              route: "/sv/Om-mig/Min-tid-i-Amerika",
              component: <Amerika />,
            },

            {
              name: "Part 3: Min återkomst till Nederländerna",
              route: "/sv/Om-mig/Min-återkomst-till-Nederländerna",
              component: <NaUSA />,
            },
             ],
         },
         {
          name: "Om Sport Scholarships America",
          description: "Företagets historia",
          route: "/sv/Om-Sport-Scholarships-America",
          component: <CompanyTimeline />,
        
          dropdown: false,
          
         },
    ],
},    
 
  {
    name: "Erbjudanden",
    icon: <Icon>monetization_on</Icon>,
    collapse: [
      {
        name: "Tjänster & Produkter",
        description: "Upptäck alla tjänster och produkter",
        route: "/sv/Tjänster-Produkter",
        component: <DienstenProducten />,
        dropdown: false,
      
      },
      {
        name: "Process",
        description: "Processöversikt",
        route: "/sv/Process",
        component: <Timeline />,
        dropdown: false,
       
      },
      
    ],
  },
  {
    name: "Innehåll",
    icon: <Icon>article</Icon>,
    collapse: [
      {
        name: "Podcast",
        route: "/sv/Podcast",
        description: "Lyssna på min podcast",
        component: <VirtualRealityPage />
      },
      {
        name: "Kunskapscenter",
        route: "/sv/Kunskapscenter",
        description: "Användbara artiklar och länkar",
        component: <Kenniscentrum />
      },
     
    ],
  },
  {
    name: "Kontakt",
    icon: <Icon>mail</Icon>,
    collapse: [
      {
        name: "FAQ",
        description:"Svar på Vanliga Frågo",
        route: "/sv/FAQs",
        component: <Faq />,
      },
      {
        name: "Kontaktformulär",
        route: "/sv/Kontaktformulär",
        component: <Contactformulier />,
        description: "Ta kontakt",
      },
  
     
    ],
  },
];

export default routes;
