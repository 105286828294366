/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";



// Images
import Image1 from "assets/images/Sports_Images/Soccer1.jpg";
import Image2 from "assets/images/Sports_Images/Soccer2.jpg";
import Image3 from "assets/images/Sports_Images/Soccer3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Å Spille Fotball og Studere i Amerika: En Drøm Som Går i Oppfyllelse', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapittel 1: Historien og Utviklingen av Fotball i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Tidlige År og Rollen til Universiteter', isSubheader: true },
    { id: '1.2', title: '1.2 Vekst og Professionalisering', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapittel 2: Collegiate Fotball i Dag', isSubheader: false },
    { id: '2.1', title: '2.1 Viktige Konkurranser og Konferanser for Fotball', isSubheader: true },
    { id: '2.2', title: '2.2 Topp Programmer og Prestisjefylte Lag for Fotball', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapittel 3: Fra College til Profesjonelle', isSubheader: false },
    { id: '3.1', title: '3.1 Veien til Professionalisme', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapittel 4: Påvirkningen av Internasjonal Fotball', isSubheader: false },
    { id: '4.1', title: '4.1 Utenlandske Påvirkninger', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanere i Utlandet', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapittel 5: Idrettsstipender og Fremtiden til Utøvere', isSubheader: false },
    { id: '5.1', title: '5.1 Viktigheten av Stipender', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Scholarships America Kan Hjelpe', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>























{/* Introduksjon */}
<MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Å Spille Fotball og Studere i Amerika: En Drøm Som Går i Oppfyllelse
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Creighton stadion, med plass til over 7000 tilskuere, under en college fotballkamp."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Creighton Stadion: hjemmet til mer enn 7000 fotballfans.
      </figcaption>
    </figure>
    Fotball er en av de mest populære sportene i verden, men i Amerika har den lenge blitt overskygget av andre idretter som basketball, baseball og amerikansk fotball. Imidlertid har fotballen vokst enormt i popularitet og kvalitet i USA de siste tiårene, takket være blant annet suksessen til det nasjonale laget, oppkomsten av Major League Soccer (MLS) og innflytelsen fra internasjonale stjerner.
    <br />
    <br />
    Men visste du at fotball i Amerika også tilbyr en unik mulighet for unge talenter til å forfølge sine drømmer? Ved å spille fotball og studere i Amerika kan du ikke bare utvikle dine idrettslige ferdigheter, men også tjene en akademisk grad og få en uforglemmelig opplevelse. Alt dette er mulig takket være fotballstipendene som amerikanske universiteter tilbyr til talentfulle spillere.
    <br />
    <br />
    I denne artikkelen vil vi fortelle deg alt du trenger å vite om å spille fotball og studere i Amerika, fra historien og utviklingen av fotball i USA til fordelene og mulighetene ved et fotballstipend. Vi vil også forklare hvordan Sportbeursamerika.nl kan hjelpe deg med å gjøre denne drømmen til virkelighet. Så les videre og oppdag hvordan du kan dra nytte av denne unike muligheten!
  </MKTypography>
</MKBox>

{/* Kapittel 1 */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 1: Historien og Utviklingen av Fotball i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Fotball, eller soccer som amerikanerne kaller det, er ikke en så ny sport i USA som du kanskje tror. Faktisk fant det første offisielle fotballkampen i Amerika sted i 1869, mellom to universitetslag: Princeton og Rutgers. Siden den gang har fotball hatt en lang og fascinerende historie i USA, som vi vil diskutere i dette kapittelet.
  </MKTypography>
  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidlige År og Rollen til Universiteter
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      På slutten av 1800-tallet og begynnelsen av 1900-tallet var fotball spesielt populært blant studenter, som spilte spillet i henhold til ulike regler og formater. For eksempel var det lag som spilte med 11, 15 eller til og med 25 spillere, og noen ganger brukte elementer fra rugby eller amerikansk fotball.
      <br />
      <br />
      For å skape mer enhet og struktur ble Intercollegiate Association of Amateur Athletes of America (IAAAA) grunnlagt i 1905, forgjengeren til National Collegiate Athletic Association (NCAA). NCAA er den primære organisasjonen som regulerer og organiserer collegiat fotball i USA.
      <br />
      <br />
      I første halvdel av det 20. århundre vokste antallet collegiat fotballag jevnt, og regionale konkurranser og mesterskap oppsto også. En av de mest kjente av disse er College Cup, arrangert siden 1959 som herrenes nasjonale mesterskap. For kvinner ble den første College Cup bare arrangert i 1982, men siden da har kvinnefotballen hatt en enorm vekst i USA, delvis takket være suksessen til det nasjonale laget i internasjonale turneringer.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Vekst og Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      I tillegg til collegiat fotball, utviklet profesjonell fotball seg også i USA, selv om det var med flere opp- og nedturer. Den første profesjonelle ligaen var American Soccer League (ASL), grunnlagt i 1921 og hovedsakelig bestående av lag fra nordøst. ASL hadde noen vellykkede år, men havnet i konflikt med det amerikanske fotballforbundet over organisering og regulering av spillet. Dette førte til en splittelse og til slutt enden på ASL i 1933.
      <br />
      <br />
      Etter andre verdenskrig forsøkte ulike ligaer å gjenopplive profesjonell fotball, men ingen av dem overlevde lenge. Det var ikke før i 1967 at en stabil liga ble etablert: North American Soccer League (NASL). NASL brakte noen store navn til Amerika, inkludert Pelé, Franz Beckenbauer og Johan Cruyff, og trakk store folkemengder til stadioner. Imidlertid kunne heller ikke NASL motstå økonomiske problemer, konkurranse fra andre idretter og manglende medieinteresse. NASL opphørte å eksistere i 1984.
      <br />
      <br />
      Det tok helt til 1996 før en profesjonell liga ble etablert igjen: Major League Soccer (MLS). MLS er for øyeblikket den høyeste divisjonen i amerikansk fotball, bestående av 27 lag fra både USA og Canada. MLS har utviklet seg til å bli en konkurransedyktig og attraktiv liga, som tiltrekker både nasjonale og internasjonale spillere. MLS har også bidratt til populariseringen av fotball i Amerika ved å bygge stadioner, støtte ungdomsutvikling og samarbeide med andre ligaer.
      <br />
      <br />
      Profesjonell fotball har også påvirket collegiat fotball, og vice versa. For eksempel er det spillere som går over til MLS etter sine collegiat karrierer, eller spillere som returnerer til universitetet for å fullføre studiene etter MLS. Det er også spillere som spiller for både sitt college og et profesjonelt lag, takket være spesielle ordninger. I tillegg samarbeider noen profesjonelle lag med collegiatprogrammer for å scout og utvikle talenter.
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  







{/* Kapittel 2 */}
<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 2: Collegiate Fotball i Dag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Som vi så i det forrige kapittelet, har collegiat fotball i Amerika en lang og rik tradisjon. Men hvordan ser det ut i dag? Hvilke konkurranser og konferanser eksisterer? Hvilke programmer og lag er mest prestisjefylte og vellykkede? Og hvordan kan du, som spiller, bli involvert i denne spennende verdenen? I dette kapittelet gir vi deg en oversikt over collegiat fotball i dag og viser deg hva du kan forvente hvis du velger et fotballstipend i Amerika.
  </MKTypography>
  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Viktige Konkurranser og Konferanser for Fotball
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Santa Clara fotballspiller i aksjon under NCAA-turneringen."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Fotballspillere i aksjon under en NCAA-turneringskamp.
        </figcaption>
      </figure>
      Som nevnt tidligere, er NCAA den primære organisasjonen som regulerer og organiserer collegiat fotball i USA. NCAA består av tre divisjoner: Division I, Division II og Division III. Hver divisjon har sine egne regler, krav og stipend for student-idrettsutøvere.
      <br />
      <br />
      Division I er den høyeste og mest konkurransedyktige divisjonen, der de beste spillerne og lagene konkurrerer. Division I består av omtrent 200 herrelag og 300 damelag, delt inn i ulike regionale konferanser. En konferanse er en gruppe universiteter som samarbeider om å organisere en konkurranse innenfor sin region. Noen eksempler på velkjente konferanser er Atlantic Coast Conference (ACC), Big Ten Conference, Pac-12 Conference og Southeastern Conference (SEC).
      <br />
      <br />
      Hvert år konkurrerer lagene innenfor sin konferanse om tittelen og en plass i den nasjonale mesterskapet: College Cup. College Cup er en utslagsturnering som holdes mot slutten av sesongen, vanligvis i november og desember. For å kvalifisere seg til College Cup, må lagene først tjene sin plass gjennom sin konferanse eller gjennom en "at-large bid" tildelt av et utvalgskomite basert på deres prestasjon i løpet av sesongen. College Cup består av 48 herrelag og 64 damelag, som konkurrerer i ulike runder til en mester gjenstår.
      <br />
      <br />
      Division II og Division III er mindre konkurransedyktige enn Division I, men tilbyr fortsatt mange muligheter for student-idrettsutøvere å utvikle seg og nyte spillet. Division II består av omtrent 160 herrelag og 200 damelag, også delt inn i ulike konferanser. Division II har også et nasjonalt mesterskap, bestående av 36 herrelag og 48 damelag. Division III består av omtrent 400 herrelag og 430 damelag, som også konkurrerer i konferanser. Imidlertid tilbyr ikke Division III stipend til student-idrettsutøvere, men gir andre former for økonomisk støtte. Division III har også et nasjonalt mesterskap, bestående av 62 herrelag og 64 damelag.
      <br />
      <br />
      I tillegg til NCAA, er det andre organisasjoner som organiserer collegiat fotball i USA, slik som National Association of Intercollegiate Athletics (NAIA), National Junior College Athletic Association (NJCAA) og United States Collegiate Athletic Association (USCAA). Disse organisasjonene har sine egne divisjoner, regler, stipend og mesterskap, som vanligvis er mindre og mindre kjent enn de til NCAA.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Topp Programmer og Prestisjefylte Lag for Fotball
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Innenfor hver divisjon og konferanse er det visse programmer og lag som skiller seg ut på grunn av sin historie, prestasjon, omdømme, fasiliteter og mer. Disse programmene og lagene tiltrekker ofte de beste spillerne, tilbyr de beste stipendene, har de beste trenerne og staben, har førsteklasses trenings- og kampfasiliteter, har det største tilhengergrunnlaget og medieoppmerksomheten, og produserer ofte spillere for den profesjonelle sirkelen.
      <br />
      <br />
      Det er vanskelig å lage en definitiv liste over de beste programmene og lagene i collegiat fotball i Amerika fordi mange faktorer spiller inn, og rangeringer kan endre seg hvert år. Imidlertid, for å gi deg en idé, vil vi nevne noen eksempler på programmer og lag som ofte regnes som de beste i collegiat fotball i Amerika.
      <br />
      <br />
      For menn er et av de mest prestisjefylte programmene University of Maryland, som har vunnet nasjonalt mesterskap 4 ganger (1968, 2005, 2008 og 2018) og har produsert mer enn 20 spillere som har spilt i MLS, som Omar Gonzalez, Graham Zusi og Zack Steffen. Andre ledende programmer for menn inkluderer University of Virginia, Indiana University, Stanford University og Wake Forest University.
      <br />
      <br />
      For kvinner er et av de mest dominerende programmene University of North Carolina, som har vunnet nasjonalt mesterskap over 20 ganger og har produsert mer enn 40 spillere som har spilt for landslaget, som Mia Hamm, Kristine Lilly og Tobin Heath. Andre ledende programmer for kvinner inkluderer University of Notre Dame, University of Portland, Stanford University og Florida State University.
      <br />
      <br />
      Dette er bare noen få eksempler på de mange utmerkede programmene og lagene i collegiat fotball i Amerika. Hvis du vil lære mer om forskjellige programmer og lag, kan du søke online etter rangeringer, statistikk, anmeldelser og spillere og treners vitnemål. Du kan også kontakte Sportbeursamerika.nl, som kan hjelpe deg med å finne det beste programmet og laget som passer for deg.
    </MKTypography>
  </MKBox>
</MKBox>

        






                                  






{/* Kapittel 3 */}
<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 3: Fra College til Profesjonelle
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Mange unge fotballspillere drømmer en dag om å spille profesjonelt og etterligne sine idoler på det høyeste nivået. Men hvordan kan du få den drømmen til å gå i oppfyllelse? Hva er stegene du må ta for å gå fra college til de profesjonelle? Og hvilke muligheter og utfordringer vil du møte underveis? I dette kapittelet vil vi besvare disse spørsmålene og vise deg hvordan collegiat fotball kan fungere som et springbrett til en profesjonell karriere.
  </MKTypography>
  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Veien til Professionalisme
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      En av de mest betydningsfulle fordelene med collegiat fotball er at det gir deg muligheten til å utvikle deg både idrettslig og akademisk samtidig som du nyter studentlivet. Ved å spille fotball og studere i Amerika, kan du ikke bare forbedre dine fotballferdigheter, men også utvide din kunnskap, utvide ditt nettverk, forme din personlighet og forbedre dine fremtidsutsikter.
      <br />
      <br />
      Collegiat fotball kan også være et springbrett til en profesjonell karriere hvis du ønsker det. Det er ulike måter å gå fra collegiat fotball til den profesjonelle sirkelen, både i Amerika og i utlandet. De vanligste metodene inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          MLS SuperDraft: Dette er en årlig begivenhet der MLS-lagene tar runder med å velge spillere fra en tilgjengelig spillerpool, hvor de fleste kommer fra collegiat fotball. MLS SuperDraft består av fire runder, der hvert lag gjør ett valg per runde. Rekkefølgen for valg bestemmes av ulike faktorer som lags prestasjon i forrige sesong, lotteri og potensielle handler. MLS SuperDraft er en av de viktigste måtene for collegiat fotballspillere å komme inn i MLS på og tilbyr muligheten til å sikre seg en kontrakt og et stipend.
        </li>
        <li>
          MLS Homegrown Player Rule: Denne regelen tillater MLS-lag å signere spillere fra sine egne ungdomsakademier uten å bruke et draftvalg. Målet med regelen er å oppmuntre og belønne utviklingen av lokale talenter. Mange MLS-lag har partnerskap med collegiat programmer, som lar dem spore og veilede spillere som spiller både for sitt college og ungdomslag. MLS Homegrown Player Rule er en annen måte for collegiat fotballspillere å komme inn i MLS på og tilbyr muligheten til å sikre seg en kontrakt og et stipend.
        </li>
        <li>
          Generation Adidas Program: Sponset av Adidas, er dette programmet designet for å hjelpe unge spillere med overgangen fra collegiat fotball til profesjonell fotball. Programmet velger lovende spillere, vanligvis under 20 år, som ennå ikke har fullført universitetet. Disse spillerne tilbys kontrakter av MLS, som lar dem delta i MLS SuperDraft uten å miste sin college-berettigelse. Dette betyr at de fortsatt kan returnere til universitetet for å fullføre studiene hvis de ønsker det. Programmet tilbyr også utdanningsstipend til spillerne for å dekke studiekostnadene deres.
        </li>
        <li>
          Muligheter i Utlandet: Dette er muligheter for collegiat fotballspillere å spille i utlandet, for eksempel i Europa, Asia eller Sør-Amerika. Disse mulighetene avhenger typisk av ulike faktorer som spillerens nivå, nasjonalitet, alder og nettverk. Noen spillere kan for eksempel dra nytte av dobbelt statsborgerskap eller familiær bakgrunn for å kvalifisere seg for et utenlandsk pass, noe som gjør det lettere for dem å spille i et annet land. Andre spillere kan dra nytte av sine forbindelser med speidere, agenter eller trenere som kan hjelpe dem med å sikre en kontrakt med en utenlandsk klubb. Muligheter i utlandet er en annen måte for collegiat fotballspillere å forfølge en profesjonell karriere på, men de kommer også med flere risikoer og usikkerheter.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dette er bare noen av måtene du kan gå fra college til de profesjonelle på, men det er flere. Nøkkelen er å være klar over de tilgjengelige mulighetene og å forberede seg på de utfordringene du kan møte. Det er også viktig å være realistisk med sjansene og forventningene dine og ikke legge alle eggene i én kurv. Collegiat fotball er en fantastisk mulighet til å utvikle seg som spiller og som person. Derfor er det klokt å holde andre alternativer åpne og ikke bare fokusere på fotball, men også på studier, hobbyer, venner og familie.
      <br />
      <br />
      I det neste kapittelet vil vi utforske innflytelsen fra internasjonal fotball på collegiat fotball og hvordan det påvirker og inspirerer collegiat fotballspillere.
    </MKTypography>
  </MKBox>
</MKBox>

                






                                  







{/* Kapittel 4 */}
<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 4: Påvirkningen av Internasjonal Fotball
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Fotball er en global sport som knytter millioner av mennesker over hele verden sammen. Det er ikke overraskende at internasjonal fotball har en betydelig innflytelse på collegiat fotball i Amerika. I dette kapittelet vil vi utforske hvordan utenlandske påvirkninger påvirker og inspirerer collegiat fotball, og hvordan amerikanske spillere finner veien til fremmede land.
  </MKTypography>
  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Utenlandske Påvirkninger
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Den ettertraktede NCAA-trofeen."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Den ettertraktede NCAA-trofeen
        </figcaption>
      </figure>
      Populariteten til internasjonal fotball i USA har vokst betydelig de siste årene. Ifølge en Gallup-undersøkelse fra 2023 er fotball den tredje mest populære idretten blant amerikanere, etter amerikansk fotball og basketball. Dette skyldes delvis den økende mangfoldigheten i den amerikanske befolkningen, tilgjengeligheten av internasjonale kamper gjennom TV og internett, og suksessen til det amerikanske landslaget på verdensscenen.
      <br />
      <br />
      Denne interessen for internasjonal fotball har også hatt innvirkning på collegiat fotball. Mange collegiat fotballspillere ser opp til sine favoritt utenlandske spillere og lag, og prøver å etterligne spillestilen og teknikken deres. Noen collegiat fotballspillere har til og med utenlandske bakgrunner eller dobbelt statsborgerskap, noe som skaper en spesiell tilknytning til et bestemt land eller en region.
      <br />
      <br />
      Et eksempel er Gio Reyna, sønnen til den tidligere amerikanske landslagsspilleren Claudio Reyna. Gio Reyna ble født i England, der faren spilte for Sunderland og Manchester City. Han vokste opp med en kjærlighet for engelsk fotball og spilte for ungdomslagene til New York City FC. I 2023 flyttet han til Borussia Dortmund i Tyskland, der han raskt ble en av Europas største talenter. Han debuterte også for det amerikanske landslaget, og dannet en ny generasjon sammen med andre unge stjerner som Christian Pulisic og Weston McKennie.
      <br />
      <br />
      Et annet eksempel er Catarina Macario, en brasiliansk angriper som flyttet til USA da hun var tolv år gammel. Hun spilte for Stanford University, der hun vant Hermann Trophy to ganger som landets beste collegiat fotballspiller. Hun fikk også amerikansk statsborgerskap, noe som gjorde at hun kunne representere det amerikanske kvinnelandslaget. I 2023 signerte hun kontrakt med Olympique Lyon i Frankrike, en av verdens ledende kvinnelag.
      <br />
      <br />
      Disse eksemplene illustrerer hvordan utenlandske påvirkninger beriker og diversifiserer collegiat fotball og hvordan collegiat fotballspillere kan oppnå drømmene sine i internasjonal fotball.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikanere i Utlandet
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ikke bare påvirker utenlandske påvirkninger collegiat fotball, men amerikanske spillere som søker lykken i utlandet spiller også en betydelig rolle. Et økende antall amerikanske spillere velger å flytte til Europa eller andre kontinenter etter collegiat karrieren for å konkurrere mot verdens beste spillere.
      <br />
      <br />
      Et av de mest vellykkede eksemplene er Clint Dempsey, som spilte for Furman University før han ble med i MLS. I 2023 flyttet han til Fulham i England, der han ble en prolifisk målscorer. Han scoret mer enn 50 mål for klubben, inkludert et historisk mål mot Juventus i Europa League. Han spilte også for Tottenham Hotspur og Seattle Sounders, og var en nøkkelspiller for det amerikanske landslaget.
      <br />
      <br />
      Et annet suksesshistorie er Tobin Heath, som spilte for North Carolina University, der hun vant to nasjonale mesterskap. Hun spilte også for ulike klubber i USA, Frankrike og England, inkludert Paris Saint-Germain og Manchester United. Hun vant fire olympiske gullmedaljer og to FIFA-verdensmesterskap med det amerikanske kvinnelandslaget, der hun er kjent for sine tekniske ferdigheter og kreativitet.
      <br />
      <br />
      Disse eksemplene demonstrerer hvordan amerikanske spillere kan utvikle seg og utmerke seg i utlandet, og hvordan de kan sette collegiat fotball på kartet som et talentinkubator.
    </MKTypography>
  </MKBox>
</MKBox>







                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 5: Idrettsstipender og Fremtiden til Utøvere
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrettsstipender gir mange muligheter for unge utøvere til å vise sitt talent og utvikle sine ferdigheter. En av de mest betydningsfulle mulighetene er å få et idrettsstipend for å studere og konkurrere på college-nivå. I dette kapittelet vil vi diskutere viktigheten av idrettsstipender og hvordan Sport Scholarships America kan hjelpe deg med å oppfylle drømmen din.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Viktigheten av Stipender
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et idrettsstipend er økonomisk støtte gitt av et universitet til en studentutøver for å kombinere studier og idrett. Det kan dekke kostnadene for skolepenger, bøker, bolig, måltider og andre utgifter. I tillegg kan det tilby fordeler som akademisk veiledning, medisinsk hjelp, treningsfasiliteter og utstyr.
      <br />
      <br />
      Å få et idrettsstipend er konkurransedyktig, med mange faktorer som spiller en rolle i tildelingsprosessen, inkludert akademiske prestasjoner, idrettslige resultater, personlighet, motivasjon, lederegenskaper og tilpasningsevne. Det er derfor viktig å forberede seg godt og skille seg ut fra konkurransen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Scholarships America Kan Hjelpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo for Sport Scholarships America, som viser sportsutstyr og en eksamenskappe, symboliserer fokuset på idrettsstipender for studentutøvere."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo for Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America er en organisasjon spesialisert i å veilede unge idrettstalenter til idrettsstipender. Med lidenskapelige og erfarne eksperter støtter Sport Scholarships America studentutøvere på hvert trinn i reisen, fra første orientering til endelig plassering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig inntaksintervju for å diskutere studentutøverens ønsker, muligheter og forventninger.</li>
        <li>En profesjonell evaluering av studentutøverens akademiske og idrettslige nivå.</li>
        <li>Et omfattende nettverk av kontakter med trenere, universiteter og høyskoler.</li>
        <li>Effektiv markedsføring av studentutøverens profil og videoer til potensielle trenere.</li>
        <li>Ekspertråd om de beste alternativene basert på studentutøverens preferanser, mål og budsjett.</li>
        <li>Forhandlinger om de beste stipendtilbudene på vegne av studentutøveren.</li>
        <li>Hjelp til å søke om nødvendige dokumenter, som visum, transkripsjon, diploma og opptaksprøver.</li>
        <li>Forberedelse til studentutøverens liv under stipendet, inkludert kultur, språk, regler og tips.</li>
        <li>Oppfølging og oppfølging under stipendperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vil du utforske stipendmuligheter og oppnå dine idrettslige og akademiske mål? <Link to="/no/Tjenester-Produkter">Se det komplette utvalget</Link> av tjenester som tilbys av Sport Scholarships America og planlegg en konsultasjon i dag.
    </MKTypography>
  </MKBox>
</MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;