/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Track and Field1.jpg";
import Image2 from "assets/images/Sports_Images/Track and Field2.jpeg";
import Image3 from "assets/images/Sports_Images/Track and Field3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Athlétisme et Études aux États-Unis : Un Rêve Qui Peut Devenir Réalité', isSubheader: false },
  
    { id: 'Chapitre 1', title: 'Chapitre 1 : Histoire et Développement de l\'Athlétisme au Niveau Universitaire', isSubheader: false },
    { id: '1.1', title: '1.1 Débuts et Origines', isSubheader: true },
    { id: '1.2', title: '1.2 Croissance et Professionnalisation', isSubheader: true },
  
    { id: 'Chapitre 2', title: 'Chapitre 2 : Athlétisme Universitaire Aujourd\'hui', isSubheader: false },
    { id: '2.1', title: '2.1 Compétitions Majeures et Conférences', isSubheader: true },
    { id: '2.2', title: '2.2 Programmes de Haut Niveau et Équipes Prestigieuses', isSubheader: true },
  
    { id: 'Chapitre 3', title: 'Chapitre 3 : Du Collège au Professionnalisme', isSubheader: false },
    { id: '3.1', title: '3.1 Le Chemin vers le Professionnalisme', isSubheader: true },
    { id: '3.2', title: '3.2 Relation avec l\'Athlétisme Professionnel', isSubheader: true },
  
    { id: 'Chapitre 4', title: 'Chapitre 4 : Influences et Opportunités Internationales', isSubheader: false },
    { id: '4.1', title: '4.1 Athlètes Étrangers et Compétitions', isSubheader: true },
    { id: '4.2', title: '4.2 Athlètes Américains à l\'Étranger', isSubheader: true },
  
    { id: 'Chapitre 5', title: 'Chapitre 5 : Bourses Sportives et l\'Avenir des Athlètes', isSubheader: false },
    { id: '5.1', title: '5.1 L\'Importance des Bourses', isSubheader: true },
    { id: '5.2', title: '5.2 Comment Sport Scholarships America Peut Aider', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contents
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Cacher</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                      key={section.id}
                      onClick={() => scrollTo(section.id)}
                      color="secondary"
                      variant="gradient"
                      sx={{ 
                        justifyContent: "flex-start",
                        pl: section.isSubheader ? 4 : 2,
                        textTransform: "none",
                        fontWeight: section.isSubheader ? "normal" : "bold",
                        borderTopLeftRadius: index === 0 ? '20px' : '0',
                        borderTopRightRadius: index === 0 ? '20px' : '0',
                        borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                        borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                        textAlign: "left",
                        width: '100%',
                      }}
                    >
                      {section.title}
                    </MKButton>
                  ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Afficher</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Athlétisme et Études aux États-Unis : Un Rêve Qui Peut Devenir Réalité
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Coureurs en pleine action lors des Penn State Relays, un événement important d'athlétisme universitaire."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Action lors des Penn State Relays.
      </figcaption>
    </figure>
    L'athlétisme est l'un des sports les plus populaires et les plus importants aux États-Unis. Des millions de personnes pratiquent l'athlétisme comme passe-temps, dans le cadre de leur programme scolaire ou universitaire, ou en tant qu'athlètes professionnels. L'athlétisme est également un sport qui offre de nombreuses opportunités aux jeunes talents de se développer, de concourir et d'exceller au plus haut niveau.
    <br />
    <br />
    L'une des meilleures façons de tirer parti de ces opportunités est d'obtenir une bourse d'athlétisme aux États-Unis. Une bourse d'athlétisme est un soutien financier que vous recevez d'une université pour étudier et participer à l'athlétisme là-bas. Avec une bourse d'athlétisme, vous pouvez non seulement obtenir votre diplôme universitaire mais aussi participer à l'un des programmes d'athlétisme les plus prestigieux et compétitifs au monde.
    <br />
    <br />
    Mais comment obtenir une bourse d'athlétisme aux États-Unis ? Quels sont les avantages et les défis d'étudier et de concourir au niveau universitaire ? Et comment se préparer à une carrière réussie en tant qu'athlète après vos études ? Ce sont quelques-unes des questions auxquelles nous répondrons dans cet article.
    <br />
    <br />
    Nous vous dirons également comment Sportbeursamerika.nl peut vous aider à réaliser votre rêve. Sportbeursamerika.nl est une organisation spécialisée dans l'orientation des jeunes athlètes vers des bourses d'études sportives aux États-Unis. J'ai beaucoup d'expérience dans la recherche du meilleur match entre les athlètes et les universités. J'offre un accompagnement personnel, des conseils professionnels et un soutien pratique à chaque étape du processus.
    <br />
    <br />
    Si vous êtes intéressé par l'obtention d'une bourse d'athlétisme aux États-Unis, cet article est pour vous. Lisez la suite et découvrez tout ce que vous devez savoir sur l'athlétisme au niveau universitaire aux États-Unis.
  </MKTypography>
  {/* Ajouter une liste à puces ou d'autres contenus si nécessaire */}
</MKBox>
{/* Principaux chapitres et sous-chapitres avec un texte fictif */}
<MKBox id="Chapitre 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 1 : Histoire et Développement de l'Athlétisme au Niveau Universitaire
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    L'athlétisme au niveau universitaire a une longue et riche histoire aux États-Unis. Il a commencé au 19e siècle comme moyen de promouvoir la santé physique et mentale des étudiants. Il est devenu un sport majeur qui a attiré des millions de spectateurs et a produit de nombreux athlètes de renommée mondiale.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Débuts et Origines
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La première compétition d'athlétisme universitaire a eu lieu en 1864, lorsque des étudiants de Yale et d'Oxford se sont défiés pour un match à Londres. La compétition se composait de quatre épreuves : la course, le saut en longueur, le saut en hauteur et le saut à la perche. Yale a remporté le match 7-3.
      <br />
      <br />
      Ce match a inspiré d'autres universités à former également des équipes d'athlétisme et à concourir les unes contre les autres. En 1873, la première association interuniversitaire d'athlétisme a été fondée, l'Intercollegiate Association of Amateur Athletes of America (IAAAA). L'IAAAA organisait des championnats annuels pour les universités de l'est des États-Unis.
      <br />
      <br />
      La fin du 19e siècle a été une période importante pour le développement de l'athlétisme. Elle a été marquée par la formalisation des règles et l'émergence de personnalités influentes telles que James E. Sullivan, co-fondateur de l'Union athlétique amateur aux États-Unis. Il a joué un rôle significatif dans la popularisation de l'athlétisme et l'organisation d'événements majeurs. L'introduction des Jeux olympiques modernes en 1896 a donné une plateforme internationale au sport, conduisant à la standardisation des règles et des épreuves. De plus, les avancées technologiques, telles que les améliorations des chaussures et des pistes de course, ont contribué à l'évolution de l'athlétisme pendant cette période.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Croissance et Professionnalisation
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Au 20e siècle, l'athlétisme est devenu de plus en plus populaire et important dans les universités. Le nombre de programmes d'athlétisme, de compétitions et de bourses a augmenté. Le niveau des athlètes a également augmenté, en partie grâce à une meilleure formation, des installations et un encadrement de qualité.
      <br />
      <br />
      Un des facteurs qui ont contribué à la croissance et à la professionnalisation de l'athlétisme au niveau universitaire a été la création de la National Collegiate Athletic Association (NCAA) en 1906. La NCAA est une organisation qui supervise les activités sportives de plus de 1000 universités aux États-Unis. La NCAA réglemente, entre autres, les règles, les qualifications, les bourses et les sanctions pour l'athlétisme au niveau universitaire.
      <br />
      <br />
      Un autre facteur qui a influencé le développement de l'athlétisme au niveau universitaire a été la relation avec l'athlétisme professionnel. De nombreux athlètes qui ont réussi au niveau universitaire sont ensuite passés sur le circuit professionnel. Quelques exemples sont Jesse Owens, Carl Lewis, Jackie Joyner-Kersee et Michael Johnson.
    </MKTypography>
  </MKBox>
</MKBox>









                                              





                                  








<MKBox id="Chapitre 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 2 : Athlétisme Universitaire Aujourd'hui
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    L'athlétisme universitaire est aujourd'hui l'un des sports les plus populaires et prestigieux aux États-Unis. Chaque année, des milliers d'athlètes participent à diverses compétitions et événements, attirant des millions de fans et l'attention des médias. L'athlétisme universitaire offre également de nombreuses opportunités aux athlètes de se développer, de performer et de profiter de leur sport.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Compétitions Majeures et Conférences
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Sydney McLaughlin, excellant en tant qu'athlète pour l'Université du Kentucky, en tenue de compétition."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Sydney McLaughlin a étudié à l'Université du Kentucky
        </figcaption>
      </figure>
      L'organisation principale régissant l'athlétisme universitaire est la NCAA. La NCAA divise les universités en trois divisions, en fonction de leur taille, de leur budget et de leur niveau de compétition. La Division I est la division la plus élevée, où se trouvent la plupart des meilleures universités et des meilleurs athlètes. Les Divisions II et III sont des divisions inférieures, où les universités ont moins de bourses et de ressources, mais accordent plus d'importance à la performance académique.
      <br/>
      <br/>
      Au sein de chaque division, il existe différentes conférences, qui sont des groupes d'universités liées géographiquement ou historiquement. Chaque conférence organise ses propres championnats et compétitions pour ses membres. Certaines des conférences les plus connues sont la Big Ten, la Pac-12, la SEC, la ACC et l'Ivy League.
      <br/>
      <br/>
      Les principaux événements de la saison d'athlétisme universitaire sont les championnats de la NCAA, qui ont lieu chaque année en mars (intérieur) et juin (extérieur). Ces championnats déterminent quelles universités et quels athlètes sont les meilleurs dans chaque division et chaque épreuve. Les championnats attirent de nombreux spectateurs, sponsors et l'attention des médias.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programmes de Haut Niveau et Équipes Prestigieuses
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Il existe de nombreuses universités réputées pour leurs excellents programmes d'athlétisme. Ces universités ont une longue tradition de succès, un encadrement de qualité et des installations de premier ordre, et elles attirent fortement les athlètes talentueux. Parmi ces universités, on trouve :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Oregon : Oregon est l'une des universités les plus performantes en athlétisme, notamment en course. Oregon a remporté plus de 30 titres de la NCAA et a produit de nombreux athlètes célèbres, tels que Steve Prefontaine, Alberto Salazar, Galen Rupp et Matthew Centrowitz.
        </li>
        <li>
          USC : USC est une autre grande université en athlétisme, avec un fort accent sur la sprint, le saut et le lancer. USC a remporté plus de 25 titres de la NCAA et a produit de nombreux médaillés olympiques, tels que Quincy Watts, Andre De Grasse et Michael Norman.
        </li>
        <li>
          Arkansas : Arkansas est une université dominante en athlétisme, notamment pendant la saison intérieure. Arkansas a remporté plus de 40 titres de la NCAA, dont plus de 30 en intérieur. Arkansas est connue pour sa profondeur et sa polyvalence dans diverses épreuves.
        </li>
        <li>
          Texas : Texas est l'une des universités les plus polyvalentes en athlétisme, avec un bon équilibre entre les équipes masculines et féminines, et entre les épreuves individuelles et de relais. Texas a remporté plus de 20 titres de la NCAA et a produit de nombreux athlètes de classe mondiale, tels que Sanya Richards-Ross, Trey Hardee, Michelle Carter et Courtney Okolo.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ces universités offrent non seulement un niveau élevé de compétition et d'entraînement pour leurs athlètes, mais aussi une atmosphère et une culture formidables. Les athlètes font partie d'une communauté soudée qui les soutient et les motive à atteindre leur potentiel.
    </MKTypography>
  </MKBox>
</MKBox>












                                              






                                  








<MKBox id="Chapitre 3" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Chapitre 3 : Du Collège au Professionnalisme
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        L'athlétisme universitaire n'est pas seulement un sport, mais aussi un terrain d'entraînement pour les athlètes aspirant à une carrière professionnelle. De nombreux athlètes qui ont performé au niveau universitaire sont ensuite passés sur le circuit professionnel. L'athlétisme universitaire offre aux athlètes l'opportunité de se développer, d'acquérir de l'expérience et de tisser des liens avec d'autres athlètes, entraîneurs et organisations.
    </MKTypography>

    <MKBox id="3.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.1 Le Chemin vers le Professionnalisme
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Pour de nombreux athlètes, l'objectif est de devenir un athlète professionnel après leurs études. Cela signifie pratiquer leur sport comme une profession et gagner de l'argent grâce à leurs performances, contrats et partenariats. Pour devenir un athlète professionnel, les athlètes doivent remplir plusieurs conditions, telles que :
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Avoir un haut niveau de performance et de potentiel. Les athlètes professionnels doivent être capables de rivaliser avec les meilleurs athlètes du monde et de s'améliorer continuellement en s'adaptant aux conditions changeantes.
                </li>
                <li>
                    Maintenir une bonne santé et une bonne condition physique. Les athlètes professionnels doivent prendre soin de leur corps et se protéger contre les blessures, les maladies et la surcharge d'entraînement. Ils doivent également trouver un bon équilibre entre l'entraînement, le repos et la nutrition.
                </li>
                <li>
                    Bénéficier d'un bon encadrement et soutien. Les athlètes professionnels doivent pouvoir compter sur une équipe d'experts pour les aider avec la technique, les tactiques, la préparation mentale, les questions légales, les problèmes financiers et le marketing.
                </li>
                <li>
                    Avoir une bonne planification et stratégie. Les athlètes professionnels doivent savoir quels sont leurs objectifs, à quelles compétitions ils veulent participer, comment ils veulent se qualifier pour les événements majeurs et comment ils veulent gérer leur carrière.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            L'athlétisme universitaire peut aider les athlètes à remplir ces conditions. Il offre aux athlètes la possibilité de montrer leur talent, d'améliorer leurs compétences, d'augmenter leur expérience et d'élargir leur réseau. L'athlétisme universitaire peut également servir de tremplin vers le niveau professionnel en exposant les athlètes à des niveaux élevés de compétition, d'attention médiatique et d'intérêt du public.
        </MKTypography>
    </MKBox>
    <MKBox id="3.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.2 Relation avec l'Athlétisme Professionnel
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            L'athlétisme universitaire entretient une relation étroite avec l'athlétisme professionnel. Les deux niveaux s'influencent mutuellement de différentes manières, telles que :
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Développement des talents : L'athlétisme universitaire est une source majeure de talents pour l'athlétisme professionnel. De nombreux athlètes professionnels ont commencé en tant qu'athlètes universitaires et ont posé les bases de leur succès ultérieur là-bas.
                </li>
                <li>
                    Repérage des talents : Les organisations sportives professionnelles surveillent de près l'athlétisme universitaire pour découvrir et attirer des étoiles potentielles. Elles offrent aux athlètes universitaires des contrats, des partenariats et un encadrement pour les inciter à devenir professionnels.
                </li>
                <li>
                    Échange de talents : L'athlétisme universitaire est également ouvert aux athlètes professionnels qui étudient encore ou reprennent leurs études. Certains athlètes professionnels choisissent de compléter ou de reprendre leurs études tout en poursuivant leur carrière sportive.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            L'athlétisme universitaire n'est donc pas seulement un point final, mais aussi une étape pour les athlètes poursuivant une carrière professionnelle. Il offre aux athlètes la possibilité de se préparer au niveau professionnel, mais aussi d'explorer d'autres voies.
        </MKTypography>
    </MKBox>
</MKBox>








                                              






                                  







<MKBox id="Chapitre 4" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Chapitre 4 : Influences et Opportunités Internationales
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        L'athlétisme universitaire n'est pas seulement un sport américain, mais aussi un sport international. Il est influencé par et offre des opportunités aux athlètes de différents pays et cultures. L'athlétisme universitaire est un sport qui reflète la diversité et l'interconnexion du monde.
    </MKTypography>

    <MKBox id="4.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.1 Athlètes Étrangers et Compétitions
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image3}
                    alt="Le célèbre stade d'athlétisme de l'Oregon, lieu de nombreux événements d'athlétisme universitaire."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal3(Image3)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Stade d'athlétisme de l'Oregon, une icône du sport universitaire.
                </figcaption>
            </figure>
            Une des façons dont l'athlétisme universitaire est international est par la présence et les performances d'athlètes étrangers. Chaque année, des centaines d'athlètes d'autres pays viennent aux États-Unis pour étudier et compétitionner au niveau universitaire. Ces athlètes apportent leur propre talent, style et culture aux universités américaines, enrichissant la communauté de l'athlétisme avec leurs perspectives et expériences.
            <br/>
            <br/>
            Les athlètes étrangers ont également un impact significatif sur les résultats et le classement de l'athlétisme universitaire. De nombreux athlètes étrangers figurent parmi les meilleurs athlètes de leur discipline, remportant souvent des médailles, des titres et des records. Certains athlètes étrangers sont même si bons qu'ils surpassent ou défient les athlètes américains.
            <br/>
            <br/>
            Des exemples d'athlètes étrangers performants dans les compétitions universitaires américaines incluent :
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Andre De Grasse : un sprinter canadien qui s'est illustré au Coffeyville Community College et à l'Université de Southern California (USC), remportant plusieurs titres NJCAA et NCAA dans les épreuves de sprint. Ses performances à l'USC ont établi sa réputation en tant que sprinter de premier plan. Après sa carrière universitaire, De Grasse a remporté plusieurs médailles olympiques, dont l'or sur 200 mètres aux Jeux olympiques de Tokyo 2020, se présentant comme l'un des meilleurs sprinters du monde.
                </li>

                <li>
                    Kerron Clement : un athlète trinidadien-américain excellant dans les 400 mètres haies. Il a étudié à l'Université de Floride, où il a remporté des titres NCAA et est devenu un hurdler de classe mondiale. Clement a décroché l'or aux Jeux olympiques de 2008 et 2016 et compte plusieurs championnats du monde à son actif, ce qui en fait l'un des hurdleurs les plus performants de sa génération.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Les athlètes étrangers influencent non seulement l'athlétisme universitaire, mais aussi d'autres compétitions et événements internationaux. De nombreux athlètes étrangers ayant compétitionné au niveau universitaire participent ensuite aux Jeux olympiques, aux Championnats du monde ou à d'autres championnats régionaux ou continentaux. Ils représentent leur pays d'origine mais ont aussi des liens avec leur université américaine.
        </MKTypography>
    </MKBox>
    <MKBox id="4.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.2 Athlètes Américains à l'Étranger
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Une autre façon dont l'athlétisme universitaire est international est par les opportunités et les défis qu'il offre aux athlètes américains pour compétitionner à l'étranger. De nombreux athlètes américains ayant compétitionné au niveau universitaire ont la chance d'élargir leurs horizons et de tester leurs compétences contre d'autres athlètes de différents pays et cultures.
            <br/>
            <br/>
            Une des principales opportunités pour les athlètes américains de compétitionner à l'étranger est bien sûr les Jeux olympiques. Les Jeux olympiques sont le plus grand et le plus prestigieux événement sportif au monde, où des milliers d'athlètes de plus de 200 pays se réunissent pour concourir pour l'or, l'argent et le bronze. Les Jeux olympiques sont aussi une chance pour les athlètes américains de représenter leur pays, de montrer leur patriotisme et de partager leur fierté nationale.
            <br/>
            <br/>
            Un autre événement important pour les athlètes américains de compétitionner à l'étranger est les Championnats du monde d'athlétisme. Les Championnats du monde d'athlétisme sont l'événement d'athlétisme le plus important après les Jeux olympiques, où les meilleurs athlètes du monde se rencontrent pour se disputer le titre mondial. Les Championnats du monde d'athlétisme sont également une chance pour les athlètes américains de mesurer leur niveau, alimenter leur rivalité et établir leur réputation.
            <br/>
            <br/>
            Outre ces événements majeurs, il existe également d'autres opportunités pour les athlètes américains de compétitionner à l'étranger, tels que la Diamond League, le Continental Tour, les Relais mondiaux d'athlétisme ou d'autres championnats régionaux ou continentaux. Ces événements offrent aux athlètes américains la possibilité d'augmenter leur expérience, leurs revenus et leur base de fans.
            <br/>
            <br/>
            L'athlétisme universitaire joue un rôle important dans le développement et le succès des athlètes américains compétitionnant à l'étranger. Il prépare les athlètes au niveau international en leur fournissant un entraînement, un encadrement et une compétition de haute qualité. L'athlétisme universitaire soutient également les athlètes compétitionnant à l'étranger en leur offrant un réseau de contacts, de sponsors et de fans.
        </MKTypography>
    </MKBox>
</MKBox>









                                              






                                  
<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 5 : Bourses Sportives et l'Avenir des Athlètes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Les bourses sportives offrent de nombreuses opportunités aux jeunes athlètes de montrer leur talent et de développer leurs compétences. L'une des opportunités les plus significatives est d'obtenir une bourse sportive pour étudier et compétitionner au niveau collégial. Dans ce chapitre, nous discuterons de l'importance des bourses sportives et de comment Sport Scholarships America peut vous aider à réaliser votre rêve.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 L'Importance des Bourses
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Une bourse sportive est un soutien financier fourni par une université à un étudiant-athlète pour combiner études et sport. Elle peut couvrir les frais de scolarité, les livres, le logement, les repas et autres dépenses. De plus, elle peut offrir des avantages tels que du tutorat académique, des soins médicaux, des installations d'entraînement et du matériel.
      <br />
      <br />
      Obtenir une bourse sportive est compétitif, avec de nombreux facteurs qui influent sur le processus d'attribution, notamment les performances académiques, sportives, la personnalité, la motivation, le leadership et l'adaptabilité. Il est donc important de bien se préparer et de se démarquer de la concurrence.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Comment Sport Scholarships America Peut Aider
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo de Sport Scholarships America, représentant du matériel sportif et une casquette de graduation, symbolisant le focus sur les bourses sportives pour les étudiants-athlètes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America est une organisation spécialisée dans l'orientation des jeunes talents sportifs vers des bourses sportives. Avec des experts passionnés et expérimentés, Sport Scholarships America soutient les étudiants-athlètes à chaque étape du parcours, de l'orientation initiale à la placement final. Leurs services comprennent :
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Un entretien personnel pour discuter des souhaits, des possibilités et des attentes de l'étudiant-athlète.</li>
        <li>Une évaluation professionnelle du niveau académique et sportif de l'étudiant-athlète.</li>
        <li>Un vaste réseau de contacts avec des entraîneurs, des universités et des collèges.</li>
        <li>Promotion efficace du profil et des vidéos de l'étudiant-athlète auprès des entraîneurs potentiels.</li>
        <li>Conseils d'experts sur les meilleures options en fonction des préférences, des objectifs et du budget de l'étudiant-athlète.</li>
        <li>Négociation des meilleures offres de bourses au nom de l'étudiant-athlète.</li>
        <li>Aide à la demande de documents nécessaires, tels que visa, relevé de notes, diplôme et tests d'admission.</li>
        <li>Préparation à la vie d'étudiant-athlète pendant sa bourse, y compris culture, langue, règles et conseils.</li>
        <li>Suivi et accompagnement pendant la période de la bourse.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vous voulez explorer les opportunités de bourses et atteindre vos objectifs sportifs et académiques ? <Link to="/fr/Services-Produits">Consultez la gamme complète</Link> des services offerts par Sport Scholarships America et planifiez une consultation dès aujourd'hui.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;