import React, { useEffect, Suspense, lazy } from 'react';

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 PRO React themes
import theme from "assets/theme";
import Presentation from "Languages/English/pages/Homepage";
import Dansk from "Languages/Dansk/pages/Homepage";
import Deutsch from "Languages/Deutsch/pages/Homepage";
import Espanol from "Languages/Espanol/pages/Homepage";
import Francais from "Languages/Francais/pages/Homepage";
import Italiano from "Languages/Italiano/pages/Homepage";
import Norsk from "Languages/Norsk/pages/Homepage";
import Portugues from "Languages/Portugues/pages/Homepage";
import Svenska from "Languages/Svenska/pages/Homepage";

import SAT from "Languages/English/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/SAT";
import ACT from "Languages/English/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/ACT";
import TOEFL from "Languages/English/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/TOEFL";
import Hoodie from "Languages/English/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/Hoodie";
import Shirt from "Languages/English/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/Shirt";
import AllSports from "Languages/English/pages/All Sports/Overzicht";
import DienstenProducten from "Languages/English/pages/Aanbod/Diensten_producten";
import Privacy from "Languages/English/pages/Support/Privacy";
import NAIA from "Languages/English/pages/Competities/NAIA";
import NCAA from "Languages/English/pages/Competities/NCAA";
import NJCAA from "Languages/English/pages/Competities/NJCAA";
import Staten from "Languages/English/pages/Staten";
import WebsiteNotFound from "Languages/English/pages/404";
import { Helmet } from "react-helmet";

import AllSportsDansk from "Languages/Dansk/pages/All Sports/Overzicht";
import AllSportsDeutsch from "Languages/Deutsch/pages/All Sports/Overzicht";
import AllSportsEspanol from "Languages/Espanol/pages/All Sports/Overzicht";
import AllSportsFrancais from "Languages/Francais/pages/All Sports/Overzicht";
import AllSportsItaliano from "Languages/Italiano/pages/All Sports/Overzicht";
import AllSportsNorsk from "Languages/Norsk/pages/All Sports/Overzicht";
import AllSportsPortugues from "Languages/Portugues/pages/All Sports/Overzicht";
import AllSportsSvenska from "Languages/Svenska/pages/All Sports/Overzicht";


import SATDa from "Languages/Dansk/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/SAT";
import SATDe from "Languages/Deutsch/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/SAT";
import SATEs from "Languages/Espanol/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/SAT";
import SATFr from "Languages/Francais/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/SAT";
import SATIt from "Languages/Italiano/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/SAT";
import SATNo from "Languages/Norsk/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/SAT";
import SATPt from "Languages/Portugues/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/SAT";
import SATSv from "Languages/Svenska/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/SAT";

import ACTDa from "Languages/Dansk/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/ACT";
import ACTDe from "Languages/Deutsch/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/ACT";
import ACTEs from "Languages/Espanol/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/ACT";
import ACTFr from "Languages/Francais/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/ACT";
import ACTIt from "Languages/Italiano/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/ACT";
import ACTNo from "Languages/Norsk/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/ACT";
import ACTPt from "Languages/Portugues/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/ACT";
import ACTSv from "Languages/Svenska/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/ACT";

import TOEFLDa from "Languages/Dansk/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/TOEFL";
import TOEFLDe from "Languages/Deutsch/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/TOEFL";
import TOEFLEs from "Languages/Espanol/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/TOEFL";
import TOEFLFr from "Languages/Francais/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/TOEFL";
import TOEFLIt from "Languages/Italiano/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/TOEFL";
import TOEFLNo from "Languages/Norsk/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/TOEFL";
import TOEFLPt from "Languages/Portugues/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/TOEFL";
import TOEFLSv from "Languages/Svenska/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/TOEFL";

import HoodieDa from "Languages/Dansk/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/Hoodie";
import HoodieDe from "Languages/Deutsch/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/Hoodie";
import HoodieEs from "Languages/Espanol/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/Hoodie";
import HoodieFr from "Languages/Francais/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/Hoodie";
import HoodieIt from "Languages/Italiano/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/Hoodie";
import HoodieNo from "Languages/Norsk/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/Hoodie";
import HoodiePt from "Languages/Portugues/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/Hoodie";
import HoodieSv from "Languages/Svenska/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/Hoodie";

import ShirtDa from "Languages/Dansk/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/Shirt";
import ShirtDe from "Languages/Deutsch/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/Shirt";
import ShirtEs from "Languages/Espanol/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/Shirt";
import ShirtFr from "Languages/Francais/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/Shirt";
import ShirtIt from "Languages/Italiano/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/Shirt";
import ShirtNo from "Languages/Norsk/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/Shirt";
import ShirtPt from "Languages/Portugues/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/Shirt";
import ShirtSv from "Languages/Svenska/pages/Aanbod/Diensten_producten/ProductPage/PP/Producten/Shirt";







// Material Kit 2 PRO React routes
import routes from "routes";
import routesDeutsch from 'routes_Deutsch'; // Adjust path as necessary


import Author from 'Languages/English/pages/Over/Overmij/Profiel';
import AuthorDa from 'Languages/Dansk/pages/Over/Overmij/Profiel';
import AuthorDe from 'Languages/Deutsch/pages/Over/Overmij/Profiel';
import AuthorEs from 'Languages/Espanol/pages/Over/Overmij/Profiel';
import AuthorFr from 'Languages/Francais/pages/Over/Overmij/Profiel';
import AuthorIt from 'Languages/Italiano/pages/Over/Overmij/Profiel';
import AuthorNo from 'Languages/Norsk/pages/Over/Overmij/Profiel';
import AuthorPt from 'Languages/Portugues/pages/Over/Overmij/Profiel';
import AuthorSv from 'Languages/Svenska/pages/Over/Overmij/Profiel';



import AmerikaDa from 'Languages/Dansk/pages/Over/Overmij/Amerika';
import AmerikaDe from 'Languages/Deutsch/pages/Over/Overmij/Amerika';
import AmerikaEs from 'Languages/Espanol/pages/Over/Overmij/Amerika';
import AmerikaFr from 'Languages/Francais/pages/Over/Overmij/Amerika';
import AmerikaIt from 'Languages/Italiano/pages/Over/Overmij/Amerika';
import AmerikaNo from 'Languages/Norsk/pages/Over/Overmij/Amerika';
import AmerikaPt from 'Languages/Portugues/pages/Over/Overmij/Amerika';
import AmerikaSv from 'Languages/Svenska/pages/Over/Overmij/Amerika';

import JeugdDa from 'Languages/Dansk/pages/Over/Overmij/Jeugd';
import JeugdDe from 'Languages/Deutsch/pages/Over/Overmij/Jeugd';
import JeugdEs from 'Languages/Espanol/pages/Over/Overmij/Jeugd';
import JeugdFr from 'Languages/Francais/pages/Over/Overmij/Jeugd';
import JeugdIt from 'Languages/Italiano/pages/Over/Overmij/Jeugd';
import JeugdNo from 'Languages/Norsk/pages/Over/Overmij/Jeugd';
import JeugdPt from 'Languages/Portugues/pages/Over/Overmij/Jeugd';
import JeugdSv from 'Languages/Svenska/pages/Over/Overmij/Jeugd';

import NaUSADa from 'Languages/Dansk/pages/Over/Overmij/NaUSA';
import NaUSADe from 'Languages/Deutsch/pages/Over/Overmij/NaUSA';
import NaUSAEs from 'Languages/Espanol/pages/Over/Overmij/NaUSA';
import NaUSAFr from 'Languages/Francais/pages/Over/Overmij/NaUSA';
import NaUSAIt from 'Languages/Italiano/pages/Over/Overmij/NaUSA';
import NaUSANo from 'Languages/Norsk/pages/Over/Overmij/NaUSA';
import NaUSAPt from 'Languages/Portugues/pages/Over/Overmij/NaUSA';
import NaUSASv from 'Languages/Svenska/pages/Over/Overmij/NaUSA';


import AboutSportScholarshipsAmericaDa from 'Languages/Dansk/pages/Over/Over Sport Scholarships America'; // Import the AboutSportScholarshipsAmericaDa component
import AboutSportScholarshipsAmericaDe from 'Languages/Deutsch/pages/Over/Over Sport Scholarships America'; // Import the AboutSportScholarshipsAmericaDe component
import AboutSportScholarshipsAmericaEs from 'Languages/Espanol/pages/Over/Over Sport Scholarships America'; // Import the AboutSportScholarshipsAmericaEs component
import AboutSportScholarshipsAmericaFr from 'Languages/Francais/pages/Over/Over Sport Scholarships America'; // Import the AboutSportScholarshipsAmericaFr component
import AboutSportScholarshipsAmericaIt from 'Languages/Italiano/pages/Over/Over Sport Scholarships America'; // Import the AboutSportScholarshipsAmericaIt component
import AboutSportScholarshipsAmericaNo from 'Languages/Norsk/pages/Over/Over Sport Scholarships America'; // Import the AboutSportScholarshipsAmericaNo component
import AboutSportScholarshipsAmericaPt from 'Languages/Portugues/pages/Over/Over Sport Scholarships America'; // Import the AboutSportScholarshipsAmericaPt component
import AboutSportScholarshipsAmericaSv from 'Languages/Svenska/pages/Over/Over Sport Scholarships America'; // Import the AboutSportScholarshipsAmericaSv component

import DienstenDa from 'Languages/Dansk/pages/Aanbod/Diensten_producten';
import DienstenDe from 'Languages/Deutsch/pages/Aanbod/Diensten_producten'; // Import the AboutSportScholarshipsAmericaDa component
import DienstenEs from 'Languages/Espanol/pages/Aanbod/Diensten_producten'; // Import the AboutSportScholarshipsAmericaDa component
import DienstenFr from 'Languages/Francais/pages/Aanbod/Diensten_producten'; // Import the AboutSportScholarshipsAmericaDa component
import DienstenIt from 'Languages/Italiano/pages/Aanbod/Diensten_producten'; // Import the AboutSportScholarshipsAmericaDa component
import DienstenNo from 'Languages/Norsk/pages/Aanbod/Diensten_producten'; // Import the AboutSportScholarshipsAmericaDa component
import DienstenPt from 'Languages/Portugues/pages/Aanbod/Diensten_producten'; // Import the AboutSportScholarshipsAmericaDa component
import DienstenSv from 'Languages/Svenska/pages/Aanbod/Diensten_producten'; // Import the AboutSportScholarshipsAmericaDa component


import ProcessDa from 'Languages/Dansk/pages/Aanbod/Traject';
import ProcessDe from 'Languages/Deutsch/pages/Aanbod/Traject'; // Import the AboutSportScholarshipsAmericaDa component
import ProcessEs from 'Languages/Espanol/pages/Aanbod/Traject'; // Import the AboutSportScholarshipsAmericaDa component
import ProcessFr from 'Languages/Francais/pages/Aanbod/Traject'; // Import the AboutSportScholarshipsAmericaDa component
import ProcessIt from 'Languages/Italiano/pages/Aanbod/Traject'; // Import the AboutSportScholarshipsAmericaDa component
import ProcessNo from 'Languages/Norsk/pages/Aanbod/Traject'; // Import the AboutSportScholarshipsAmericaDa component
import ProcessPt from 'Languages/Portugues/pages/Aanbod/Traject'; // Import the AboutSportScholarshipsAmericaDa component
import ProcessSv from 'Languages/Svenska/pages/Aanbod/Traject'; // Import the AboutSportScholarshipsAmericaDa component


import PodcastDa from 'Languages/Dansk/pages/Content/Podcast';
import PodcastDe from 'Languages/Deutsch/pages/Content/Podcast'; // Import the AboutSportScholarshipsAmericaDa component
import PodcastEs from 'Languages/Espanol/pages/Content/Podcast'; // Import the AboutSportScholarshipsAmericaDa component
import PodcastFr from 'Languages/Francais/pages/Content/Podcast'; // Import the AboutSportScholarshipsAmericaDa component
import PodcastIt from 'Languages/Italiano/pages/Content/Podcast'; // Import the AboutSportScholarshipsAmericaDa component
import PodcastNo from 'Languages/Norsk/pages/Content/Podcast'; // Import the AboutSportScholarshipsAmericaDa component
import PodcastPt from 'Languages/Portugues/pages/Content/Podcast'; // Import the AboutSportScholarshipsAmericaDa component
import PodcastSv from 'Languages/Svenska/pages/Content/Podcast'; // Import the AboutSportScholarshipsAmericaDa component

import KnowledgeDa from 'Languages/Dansk/pages/Content/Kenniscentrum';
import KnowledgeDe from 'Languages/Deutsch/pages/Content/Kenniscentrum'; // Import the AboutSportScholarshipsAmericaDa component
import KnowledgeEs from 'Languages/Espanol/pages/Content/Kenniscentrum'; // Import the AboutSportScholarshipsAmericaDa component
import KnowledgeFr from 'Languages/Francais/pages/Content/Kenniscentrum'; // Import the AboutSportScholarshipsAmericaDa component
import KnowledgeIt from 'Languages/Italiano/pages/Content/Kenniscentrum'; // Import the AboutSportScholarshipsAmericaDa component
import KnowledgeNo from 'Languages/Norsk/pages/Content/Kenniscentrum'; // Import the AboutSportScholarshipsAmericaDa component
import KnowledgePt from 'Languages/Portugues/pages/Content/Kenniscentrum'; // Import the AboutSportScholarshipsAmericaDa component
import KnowledgeSv from 'Languages/Svenska/pages/Content/Kenniscentrum'; // Import the AboutSportScholarshipsAmericaDa component


import FAQsDa from 'Languages/Dansk/pages/Contact/FAQs';
import FAQsDe from 'Languages/Deutsch/pages/Contact/FAQs'; // Import the AboutSportScholarshipsAmericaDa component
import FAQsEs from 'Languages/Espanol/pages/Contact/FAQs'; // Import the AboutSportScholarshipsAmericaDa component
import FAQsFr from 'Languages/Francais/pages/Contact/FAQs'; // Import the AboutSportScholarshipsAmericaDa component
import FAQsIt from 'Languages/Italiano/pages/Contact/FAQs'; // Import the AboutSportScholarshipsAmericaDa component
import FAQsNo from 'Languages/Norsk/pages/Contact/FAQs'; // Import the AboutSportScholarshipsAmericaDa component
import FAQsPt from 'Languages/Portugues/pages/Contact/FAQs'; // Import the AboutSportScholarshipsAmericaDa component
import FAQsSv from 'Languages/Svenska/pages/Contact/FAQs'; // Import the AboutSportScholarshipsAmericaDa component

import ContactDa from 'Languages/Dansk/pages/Contact/Contactformulier';
import ContactDe from 'Languages/Deutsch/pages/Contact/Contactformulier'; // Import the AboutSportScholarshipsAmericaDa component
import ContactEs from 'Languages/Espanol/pages/Contact/Contactformulier'; // Import the AboutSportScholarshipsAmericaDa component
import ContactFr from 'Languages/Francais/pages/Contact/Contactformulier'; // Import the AboutSportScholarshipsAmericaDa component
import ContactIt from 'Languages/Italiano/pages/Contact/Contactformulier'; // Import the AboutSportScholarshipsAmericaDa component
import ContactNo from 'Languages/Norsk/pages/Contact/Contactformulier'; // Import the AboutSportScholarshipsAmericaDa component
import ContactPt from 'Languages/Portugues/pages/Contact/Contactformulier'; // Import the AboutSportScholarshipsAmericaDa component
import ContactSv from 'Languages/Svenska/pages/Contact/Contactformulier'; // Import the AboutSportScholarshipsAmericaDa component

import AfDa from "Languages/Dansk/pages/All Sports/Sports/AmericanFootball";
import AfDe from "Languages/Deutsch/pages/All Sports/Sports/AmericanFootball";
import AfEs from "Languages/Espanol/pages/All Sports/Sports/AmericanFootball";
import AfFr from "Languages/Francais/pages/All Sports/Sports/AmericanFootball";
import AfIt from "Languages/Italiano/pages/All Sports/Sports/AmericanFootball";
import AfNo from "Languages/Norsk/pages/All Sports/Sports/AmericanFootball";
import AfPt from "Languages/Portugues/pages/All Sports/Sports/AmericanFootball";
import AfSv from "Languages/Svenska/pages/All Sports/Sports/AmericanFootball";

import BaseballDa from "Languages/Dansk/pages/All Sports/Sports/Baseball";
import BaseballDe from "Languages/Deutsch/pages/All Sports/Sports/Baseball";
import BaseballEs from "Languages/Espanol/pages/All Sports/Sports/Baseball";
import BaseballFr from "Languages/Francais/pages/All Sports/Sports/Baseball";
import BaseballIt from "Languages/Italiano/pages/All Sports/Sports/Baseball";
import BaseballNo from "Languages/Norsk/pages/All Sports/Sports/Baseball";
import BaseballPt from "Languages/Portugues/pages/All Sports/Sports/Baseball";
import BaseballSv from "Languages/Svenska/pages/All Sports/Sports/Baseball";

import BasketbalDa from "Languages/Dansk/pages/All Sports/Sports/Basketball";
import BasketbalDe from "Languages/Deutsch/pages/All Sports/Sports/Basketball";
import BasketbalEs from "Languages/Espanol/pages/All Sports/Sports/Basketball";
import BasketbalFr from "Languages/Francais/pages/All Sports/Sports/Basketball";
import BasketbalIt from "Languages/Italiano/pages/All Sports/Sports/Basketball";
import BasketbalNo from "Languages/Norsk/pages/All Sports/Sports/Basketball";
import BasketbalPt from "Languages/Portugues/pages/All Sports/Sports/Basketball";
import BasketbalSv from "Languages/Svenska/pages/All Sports/Sports/Basketball";

import FieldHockeyDa from "Languages/Dansk/pages/All Sports/Sports/Field Hockey";
import FieldHockeyDe from "Languages/Deutsch/pages/All Sports/Sports/Field Hockey";
import FieldHockeyEs from "Languages/Espanol/pages/All Sports/Sports/Field Hockey";
import FieldHockeyFr from "Languages/Francais/pages/All Sports/Sports/Field Hockey";
import FieldHockeyIt from "Languages/Italiano/pages/All Sports/Sports/Field Hockey";
import FieldHockeyNo from "Languages/Norsk/pages/All Sports/Sports/Field Hockey";
import FieldHockeyPt from "Languages/Portugues/pages/All Sports/Sports/Field Hockey";
import FieldHockeySv from "Languages/Svenska/pages/All Sports/Sports/Field Hockey";

import GolfDa from "Languages/Dansk/pages/All Sports/Sports/Golf";
import GolfDe from "Languages/Deutsch/pages/All Sports/Sports/Golf";
import GolfEs from "Languages/Espanol/pages/All Sports/Sports/Golf";
import GolfFr from "Languages/Francais/pages/All Sports/Sports/Golf";
import GolfIt from "Languages/Italiano/pages/All Sports/Sports/Golf";
import GolfNo from "Languages/Norsk/pages/All Sports/Sports/Golf";
import GolfPt from "Languages/Portugues/pages/All Sports/Sports/Golf";
import GolfSv from "Languages/Svenska/pages/All Sports/Sports/Golf";

import GymnasticsDa from "Languages/Dansk/pages/All Sports/Sports/Gymnastics";
import GymnasticsDe from "Languages/Deutsch/pages/All Sports/Sports/Gymnastics";
import GymnasticsEs from "Languages/Espanol/pages/All Sports/Sports/Gymnastics";
import GymnasticsFr from "Languages/Francais/pages/All Sports/Sports/Gymnastics";
import GymnasticsIt from "Languages/Italiano/pages/All Sports/Sports/Gymnastics";
import GymnasticsNo from "Languages/Norsk/pages/All Sports/Sports/Gymnastics";
import GymnasticsPt from "Languages/Portugues/pages/All Sports/Sports/Gymnastics";
import GymnasticsSv from "Languages/Svenska/pages/All Sports/Sports/Gymnastics";

import IcehockeyDa from "Languages/Dansk/pages/All Sports/Sports/Ice Hockey";
import IcehockeyDe from "Languages/Deutsch/pages/All Sports/Sports/Ice Hockey";
import IcehockeyEs from "Languages/Espanol/pages/All Sports/Sports/Ice Hockey";
import IcehockeyFr from "Languages/Francais/pages/All Sports/Sports/Ice Hockey";
import IcehockeyIt from "Languages/Italiano/pages/All Sports/Sports/Ice Hockey";
import IcehockeyNo from "Languages/Norsk/pages/All Sports/Sports/Ice Hockey";
import IcehockeyPt from "Languages/Portugues/pages/All Sports/Sports/Ice Hockey";
import IcehockeySv from "Languages/Svenska/pages/All Sports/Sports/Ice Hockey";

import RowingDa from "Languages/Dansk/pages/All Sports/Sports/Rowing";
import RowingDe from "Languages/Deutsch/pages/All Sports/Sports/Rowing";
import RowingEs from "Languages/Espanol/pages/All Sports/Sports/Rowing";
import RowingFr from "Languages/Francais/pages/All Sports/Sports/Rowing";
import RowingIt from "Languages/Italiano/pages/All Sports/Sports/Rowing";
import RowingNo from "Languages/Norsk/pages/All Sports/Sports/Rowing";
import RowingPt from "Languages/Portugues/pages/All Sports/Sports/Rowing";
import RowingSv from "Languages/Svenska/pages/All Sports/Sports/Rowing";

import SoccerDa from "Languages/Dansk/pages/All Sports/Sports/Soccer";
import SoccerDe from "Languages/Deutsch/pages/All Sports/Sports/Soccer";
import SoccerEs from "Languages/Espanol/pages/All Sports/Sports/Soccer";
import SoccerFr from "Languages/Francais/pages/All Sports/Sports/Soccer";
import SoccerIt from "Languages/Italiano/pages/All Sports/Sports/Soccer";
import SoccerNo from "Languages/Norsk/pages/All Sports/Sports/Soccer";
import SoccerPt from "Languages/Portugues/pages/All Sports/Sports/Soccer";
import SoccerSv from "Languages/Svenska/pages/All Sports/Sports/Soccer";

import SoftbalDa from "Languages/Dansk/pages/All Sports/Sports/Softbal";
import SoftbalDe from "Languages/Deutsch/pages/All Sports/Sports/Softbal";
import SoftbalEs from "Languages/Espanol/pages/All Sports/Sports/Softbal";
import SoftbalFr from "Languages/Francais/pages/All Sports/Sports/Softbal";
import SoftbalIt from "Languages/Italiano/pages/All Sports/Sports/Softbal";
import SoftbalNo from "Languages/Norsk/pages/All Sports/Sports/Softbal";
import SoftbalPt from "Languages/Portugues/pages/All Sports/Sports/Softbal";
import SoftbalSv from "Languages/Svenska/pages/All Sports/Sports/Softbal";

import SwimmingDa from "Languages/Dansk/pages/All Sports/Sports/Swimming";
import SwimmingDe from "Languages/Deutsch/pages/All Sports/Sports/Swimming";
import SwimmingEs from "Languages/Espanol/pages/All Sports/Sports/Swimming";
import SwimmingFr from "Languages/Francais/pages/All Sports/Sports/Swimming";
import SwimmingIt from "Languages/Italiano/pages/All Sports/Sports/Swimming";
import SwimmingNo from "Languages/Norsk/pages/All Sports/Sports/Swimming";
import SwimmingPt from "Languages/Portugues/pages/All Sports/Sports/Swimming";
import SwimmingSv from "Languages/Svenska/pages/All Sports/Sports/Swimming";

import TennisDa from "Languages/Dansk/pages/All Sports/Sports/Tennis";
import TennisDe from "Languages/Deutsch/pages/All Sports/Sports/Tennis";
import TennisEs from "Languages/Espanol/pages/All Sports/Sports/Tennis";
import TennisFr from "Languages/Francais/pages/All Sports/Sports/Tennis";
import TennisIt from "Languages/Italiano/pages/All Sports/Sports/Tennis";
import TennisNo from "Languages/Norsk/pages/All Sports/Sports/Tennis";
import TennisPt from "Languages/Portugues/pages/All Sports/Sports/Tennis";
import TennisSv from "Languages/Svenska/pages/All Sports/Sports/Tennis";

import AthleticDa from "Languages/Dansk/pages/All Sports/Sports/Track and Field";
import AthleticDe from "Languages/Deutsch/pages/All Sports/Sports/Track and Field";
import AthleticEs from "Languages/Espanol/pages/All Sports/Sports/Track and Field";
import AthleticFr from "Languages/Francais/pages/All Sports/Sports/Track and Field";
import AthleticIt from "Languages/Italiano/pages/All Sports/Sports/Track and Field";
import AthleticNo from "Languages/Norsk/pages/All Sports/Sports/Track and Field";
import AthleticPt from "Languages/Portugues/pages/All Sports/Sports/Track and Field";
import AthleticSv from "Languages/Svenska/pages/All Sports/Sports/Track and Field";

import VolleybalDa from "Languages/Dansk/pages/All Sports/Sports/Volleyball";
import VolleybalDe from "Languages/Deutsch/pages/All Sports/Sports/Volleyball";
import VolleybalEs from "Languages/Espanol/pages/All Sports/Sports/Volleyball";
import VolleybalFr from "Languages/Francais/pages/All Sports/Sports/Volleyball";
import VolleybalIt from "Languages/Italiano/pages/All Sports/Sports/Volleyball";
import VolleybalNo from "Languages/Norsk/pages/All Sports/Sports/Volleyball";
import VolleybalPt from "Languages/Portugues/pages/All Sports/Sports/Volleyball";
import VolleybalSv from "Languages/Svenska/pages/All Sports/Sports/Volleyball";

import WaterpoloDa from "Languages/Dansk/pages/All Sports/Sports/Waterpolo";
import WaterpoloDe from "Languages/Deutsch/pages/All Sports/Sports/Waterpolo";
import WaterpoloEs from "Languages/Espanol/pages/All Sports/Sports/Waterpolo";
import WaterpoloFr from "Languages/Francais/pages/All Sports/Sports/Waterpolo";
import WaterpoloIt from "Languages/Italiano/pages/All Sports/Sports/Waterpolo";
import WaterpoloNo from "Languages/Norsk/pages/All Sports/Sports/Waterpolo";
import WaterpoloPt from "Languages/Portugues/pages/All Sports/Sports/Waterpolo";
import WaterpoloSv from "Languages/Svenska/pages/All Sports/Sports/Waterpolo";

import WrestlingDa from "Languages/Dansk/pages/All Sports/Sports/Wrestling";
import WrestlingDe from "Languages/Deutsch/pages/All Sports/Sports/Wrestling";
import WrestlingEs from "Languages/Espanol/pages/All Sports/Sports/Wrestling";
import WrestlingFr from "Languages/Francais/pages/All Sports/Sports/Wrestling";
import WrestlingIt from "Languages/Italiano/pages/All Sports/Sports/Wrestling";
import WrestlingNo from "Languages/Norsk/pages/All Sports/Sports/Wrestling";
import WrestlingPt from "Languages/Portugues/pages/All Sports/Sports/Wrestling";
import WrestlingSv from "Languages/Svenska/pages/All Sports/Sports/Wrestling";

import NAIAda from "Languages/Dansk/pages/Competities/NAIA";
import NAIAde from "Languages/Deutsch/pages/Competities/NAIA";
import NAIAes from "Languages/Espanol/pages/Competities/NAIA";
import NAIAfr from "Languages/Francais/pages/Competities/NAIA";
import NAIAit from "Languages/Italiano/pages/Competities/NAIA";
import NAIAno from "Languages/Norsk/pages/Competities/NAIA";
import NAIApt from "Languages/Portugues/pages/Competities/NAIA";
import NAIAsv from "Languages/Svenska/pages/Competities/NAIA";

import NCAAda from "Languages/Dansk/pages/Competities/NCAA";
import NCAAde from "Languages/Deutsch/pages/Competities/NCAA";
import NCAAes from "Languages/Espanol/pages/Competities/NCAA";
import NCAAfr from "Languages/Francais/pages/Competities/NCAA";
import NCAAit from "Languages/Italiano/pages/Competities/NCAA";
import NCAAno from "Languages/Norsk/pages/Competities/NCAA";
import NCAApt from "Languages/Portugues/pages/Competities/NCAA";
import NCAAsv from "Languages/Svenska/pages/Competities/NCAA";


import NJCAAda from "Languages/Dansk/pages/Competities/NJCAA";
import NJCAAde from "Languages/Deutsch/pages/Competities/NJCAA";
import NJCAAes from "Languages/Espanol/pages/Competities/NJCAA";
import NJCAAfr from "Languages/Francais/pages/Competities/NJCAA";
import NJCAAit from "Languages/Italiano/pages/Competities/NJCAA";
import NJCAAno from "Languages/Norsk/pages/Competities/NJCAA";
import NJCAApt from "Languages/Portugues/pages/Competities/NJCAA";
import NJCAAsv from "Languages/Svenska/pages/Competities/NJCAA";

import StatenDa from "Languages/Dansk/pages/Staten";
import StatenDe from "Languages/Deutsch/pages/Staten";
import StatenEs from "Languages/Espanol/pages/Staten";
import StatenFr from "Languages/Francais/pages/Staten";
import StatenIt from "Languages/Italiano/pages/Staten";
import StatenNo from "Languages/Norsk/pages/Staten";
import StatenPt from "Languages/Portugues/pages/Staten";
import StatenSv from "Languages/Svenska/pages/Staten";
import footerRoutes_Deutsch from "footer.routes_Deutsch";




export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });


    
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
  
      <Routes>
        {getRoutes(routes)}
        {getRoutes(routesDeutsch)}

        <Route path="/" element={<Presentation />} />
        <Route path="/de" element={<Deutsch />} />
        <Route path="/da" element={<Dansk />} />

        <Route path="/es" element={<Espanol />} />
        <Route path="/fr" element={<Francais />} />
        <Route path="/it" element={<Italiano />} />
        <Route path="/no" element={<Norsk />} />
        <Route path="/pt" element={<Portugues />} />
        <Route path="/sv" element={<Svenska />} />

        <Route path="/Services-Products/ACT-guide" element={<ACT />} />
        <Route path="/Services-Products/TOEFL-guide" element={<TOEFL />} />
        <Route path="/Services-Products/Hoodie" element={<Hoodie />} />
        <Route path="/Services-Products/T-shirt" element={<Shirt />} />
        <Route path="/Services-Products/SAT-guide" element={<SAT />} />
        <Route path="/Services-Products" element={<DienstenProducten />} />
      
        <Route path="/All-Sports" element={<AllSports />} />
        <Route path="/Privacy-Policy" element={<Privacy />} />
        <Route path="/NCAA" element={<NCAA />} />
        <Route path="/NAIA" element={<NAIA />} />
        <Route path="/NJCAA" element={<NJCAA />} />
        <Route path="/States" element={<Staten />} />
        <Route path="*" element={<WebsiteNotFound />} /> {/* Update this line */}
        <Route path="/da/Alle-sportsgrene" element={<AllSportsDansk />} />
        <Route path="/de/Alle-Sportarten" element={<AllSportsDeutsch />} />
        <Route path="/es/Todos-los-deportes" element={<AllSportsEspanol />} />
        <Route path="/fr/Tous-les-sports" element={<AllSportsFrancais />} />
        <Route path="/it/Tutti-gli-sport" element={<AllSportsItaliano />} />
        <Route path="/no/Alle-idretter" element={<AllSportsNorsk />} />
        <Route path="/pt/Todos-os-esportes" element={<AllSportsPortugues />} />
        <Route path="/sv/Alla-sporter" element={<AllSportsSvenska />} />

        <Route path="/About-Me" element={<Author />} />
        <Route path="/da/Om-mig" element={<AuthorDa />} />
        <Route path="/de/Über-mich" element={<AuthorDe />} />
        <Route path="/es/Acerca-de-mí" element={<AuthorEs />} />
        <Route path="/fr/À-propos-de-moi" element={<AuthorFr />} />
        <Route path="/it/Chi-sono" element={<AuthorIt />} />
        <Route path="/no/Om-meg" element={<AuthorNo />} />
        <Route path="/pt/Sobre-mim" element={<AuthorPt />} />
        <Route path="/sv/Om-mig" element={<AuthorSv />} />

<Route path="/da/Om-mig/Min-tid-i-Amerika" element={<AmerikaDa />} />
<Route path="/de/Über-mich/Meine-Zeit-in-Amerika" element={<AmerikaDe />} />
<Route path="/es/Acerca-de-mí/Mi-tiempo-en-América" element={<AmerikaEs />} />
<Route path="/fr/À-propos-de-moi/Mon-temps-en-Amérique" element={<AmerikaFr />} />
<Route path="/it/Chi-sono/Il-mio-tempo-in-America" element={<AmerikaIt />} />
<Route path="/no/Om-meg/Min-tid-i-Amerika" element={<AmerikaNo />} />
<Route path="/pt/Sobre-mim/O-meu-tempo-na-América" element={<AmerikaPt />} />
<Route path="/sv/Om-mig/Min-tid-i-Amerika" element={<AmerikaSv />} />

<Route path="/da/Om-mig/Opvokser" element={<JeugdDa />} />
<Route path="/de/Über-mich/Aufwachsen" element={<JeugdDe />} />
<Route path="/es/Acerca-de-mí/Creciendo" element={<JeugdEs />} />
<Route path="/fr/À-propos-de-moi/Grandir" element={<JeugdFr />} />
<Route path="/it/Chi-sono/Crescere" element={<JeugdIt />} />
<Route path="/no/Om-meg/Vokse-opp" element={<JeugdNo />} />
<Route path="/pt/Sobre-mim/Crescendo" element={<JeugdPt />} />
<Route path="/sv/Om-mig/Uppväxt" element={<JeugdSv />} />

<Route path="/da/Om-mig/Min-hjemkomst-til-Holland" element={<NaUSADa />} />
<Route path="/de/Über-mich/Meine-Rückkehr-in-die-Niederlande" element={<NaUSADe />} />
<Route path="/es/Acerca-de-mí/Mi-regreso-a-los-Países-Bajos" element={<NaUSAEs />} />
<Route path="/fr/À-propos-de-moi/Mon-retour-aux-Pays-Bas" element={<NaUSAFr />} />
<Route path="/it/Chi-sono/Il-mio-ritorno-ai-Paesi-Bassi" element={<NaUSAIt />} />
<Route path="/no/Om-meg/Min-tilbakevending-til-Nederland" element={<NaUSANo />} />
<Route path="/pt/Sobre-mim/O-meu-regresso-à-Holanda" element={<NaUSAPt />} />
<Route path="/sv/Om-mig/Min-återkomst-till-Nederländerna" element={<NaUSASv />} />



<Route path="/da/Om-Sport-Scholarships-America" element={<AboutSportScholarshipsAmericaDa />} />
<Route path="/de/Über-Sport-Scholarships-America" element={<AboutSportScholarshipsAmericaDe />} />
<Route path="/es/Acerca-de-Sport-Scholarships-America" element={<AboutSportScholarshipsAmericaEs />} />
<Route path="/fr/À-propos-de-Sport-Scholarships-America" element={<AboutSportScholarshipsAmericaFr />} />
<Route path="/it/Chi-Sport-Scholarships-America" element={<AboutSportScholarshipsAmericaIt />} />
<Route path="/no/Om-Sport-Scholarships-America" element={<AboutSportScholarshipsAmericaNo />} />
<Route path="/pt/Sobre-Sport-Scholarships-America" element={<AboutSportScholarshipsAmericaPt />} />
<Route path="/sv/Om-Sport-Scholarships-America" element={<AboutSportScholarshipsAmericaSv />} />

<Route path="/da/Tjenester-Produkter" element={<DienstenDa />} />
<Route path="/de/Dienstleistungen-Produkte" element={<DienstenDe />} />
<Route path="/es/Servicios-Productos" element={<DienstenEs />} />
<Route path="/fr/Services-Produits" element={<DienstenFr />} />
<Route path="/it/Servizi-Prodotti" element={<DienstenIt />} />
<Route path="/no/Tjenester-Produkter" element={<DienstenNo />} />
<Route path="/pt/Serviços-Produtos" element={<DienstenPt />} />
<Route path="/sv/Tjänster-Produkter" element={<DienstenSv />} />

<Route path="/da/Tjenester-Produkter/SAT-guide" element={<SATDa />} />
<Route path="/de/Dienstleistungen-Produkte/SAT-guide" element={<SATDe />} />
<Route path="/es/Servicios-Productos/SAT-guide" element={<SATEs />} />
<Route path="/fr/Services-Produits/SAT-guide" element={<SATFr />} />
<Route path="/it/Servizi-Prodotti/SAT-guide" element={<SATIt />} />
<Route path="/no/Tjenester-Produkter/SAT-guide" element={<SATNo />} />
<Route path="/pt/Serviços-Produtos/SAT-guide" element={<SATPt />} />
<Route path="/sv/Tjänster-Produkter/SAT-guide" element={<SATSv />} />

<Route path="/da/Tjenester-Produkter/ACT-guide" element={<ACTDa />} />
<Route path="/de/Dienstleistungen-Produkte/ACT-guide" element={<ACTDe />} />
<Route path="/es/Servicios-Productos/ACT-guide" element={<ACTEs />} />
<Route path="/fr/Services-Produits/ACT-guide" element={<ACTFr />} />
<Route path="/it/Servizi-Prodotti/ACT-guide" element={<ACTIt />} />
<Route path="/no/Tjenester-Produkter/ACT-guide" element={<ACTNo />} />
<Route path="/pt/Serviços-Produtos/ACT-guide" element={<ACTPt />} />
<Route path="/sv/Tjänster-Produkter/ACT-guide" element={<ACTSv />} />

<Route path="/da/Tjenester-Produkter/Toefl-guide" element={<TOEFLDa />} />
<Route path="/de/Dienstleistungen-Produkte/Toefl-guide" element={<TOEFLDe />} />
<Route path="/es/Servicios-Productos/Toefl-guide" element={<TOEFLEs />} />
<Route path="/fr/Services-Produits/Toefl-guide" element={<TOEFLFr />} />
<Route path="/it/Servizi-Prodotti/Toefl-guide" element={<TOEFLIt />} />
<Route path="/no/Tjenester-Produkter/Toefl-guide" element={<TOEFLNo />} />
<Route path="/pt/Serviços-Produtos/Toefl-guide" element={<TOEFLPt />} />
<Route path="/sv/Tjänster-Produkter/Toefl-guide" element={<TOEFLSv />} />

<Route path="/da/Tjenester-Produkter/Hoodie" element={<HoodieDa />} />
<Route path="/de/Dienstleistungen-Produkte/Hoodie" element={<HoodieDe />} />
<Route path="/es/Servicios-Productos/Hoodie" element={<HoodieEs />} />
<Route path="/fr/Services-Produits/Hoodie" element={<HoodieFr />} />
<Route path="/it/Servizi-Prodotti/Hoodie" element={<HoodieIt />} />
<Route path="/no/Tjenester-Produkter/Hoodie" element={<HoodieNo />} />
<Route path="/pt/Serviços-Produtos/Hoodie" element={<HoodiePt />} />
<Route path="/sv/Tjänster-Produkter/Hoodie" element={<HoodieSv />} />

<Route path="/da/Tjenester-Produkter/T-shirt" element={<ShirtDa />} />
<Route path="/de/Dienstleistungen-Produkte/T-shirt" element={<ShirtDe />} />
<Route path="/es/Servicios-Productos/T-shirt" element={<ShirtEs />} />
<Route path="/fr/Services-Produits/T-shirt" element={<ShirtFr />} />
<Route path="/it/Servizi-Prodotti/T-shirt" element={<ShirtIt />} />
<Route path="/no/Tjenester-Produkter/T-shirt" element={<ShirtNo />} />
<Route path="/pt/Serviços-Produtos/T-shirt" element={<ShirtPt />} />
<Route path="/sv/Tjänster-Produkter/T-shirt" element={<ShirtSv />} />








<Route path="/da/Proces" element={<ProcessDa />} />
<Route path="/de/Prozess" element={<ProcessDe />} />
<Route path="/es/Proceso" element={<ProcessEs />} />
<Route path="/fr/Processus" element={<ProcessFr />} />
<Route path="/it/Processo" element={<ProcessIt />} />
<Route path="/no/Prosess" element={<ProcessNo />} />
<Route path="/pt/Processo" element={<ProcessPt />} />
<Route path="/sv/Process" element={<ProcessSv />} />


<Route path="/da/Podcast" element={<PodcastDa />} />
<Route path="/de/Podcast" element={<PodcastDe />} />
<Route path="/es/Podcast" element={<PodcastEs />} />
<Route path="/fr/Podcast" element={<PodcastFr />} />
<Route path="/it/Podcast" element={<PodcastIt />} />
<Route path="/no/Podcast" element={<PodcastNo />} />
<Route path="/pt/Podcast" element={<PodcastPt />} />
<Route path="/sv/Podcast" element={<PodcastSv />} />

<Route path="/da/Viden-Center" element={<KnowledgeDa />} />
<Route path="/de/Wissenszentrum" element={<KnowledgeDe />} />
<Route path="/es/Centro-de-conocimientos" element={<KnowledgeEs />} />
<Route path="/fr/Centre-de-connaissances" element={<KnowledgeFr />} />
<Route path="/it/Centro-di-conoscenza" element={<KnowledgeIt />} />
<Route path="/no/Kunnskapssenter" element={<KnowledgeNo />} />
<Route path="/pt/Centro-de-conhecimento" element={<KnowledgePt />} />
<Route path="/sv/Kunskapscenter" element={<KnowledgeSv />} />

<Route path="/da/FAQs" element={<FAQsDa />} />
<Route path="/de/FAQs" element={<FAQsDe />} />
<Route path="/es/FAQs" element={<FAQsEs />} />
<Route path="/fr/FAQs" element={<FAQsFr />} />
<Route path="/it/FAQs" element={<FAQsIt />} />
<Route path="/no/FAQs" element={<FAQsNo />} />
<Route path="/pt/FAQs" element={<FAQsPt />} />
<Route path="/sv/FAQs" element={<FAQsSv />} />

<Route path="/da/Kontaktformular" element={<ContactDa />} />
<Route path="/de/Kontaktformular" element={<ContactDe />} />
<Route path="/es/Formulario-de-contacto" element={<ContactEs />} />
<Route path="/fr/Formulaire-de-contact" element={<ContactFr />} />
<Route path="/it/Modulo-di-contatto" element={<ContactIt />} />
<Route path="/no/Kontaktskjema" element={<ContactNo />} />
<Route path="/pt/Formulário-de-contato" element={<ContactPt />} />
<Route path="/sv/Kontaktformulär" element={<ContactSv />} />

<Route path="/da/Alle-sportsgrene/Amerikansk-fodbold" element={<AfDa />} />
<Route path="/de/Alle-Sportarten/American-Football" element={<AfDe />} />
<Route path="/es/Todos-los-deportes/Fútbol-Americano" element={<AfEs />} />
<Route path="/fr/Tous-les-sports/Football-Américain" element={<AfFr />} />
<Route path="/it/Tutti-gli-sport/Football-Americano" element={<AfIt />} />
<Route path="/no/Alle-idretter/Amerikansk-Fotball" element={<AfNo />} />
<Route path="/pt/Todos-os-esportes/Futebol-Americano" element={<AfPt />} />
<Route path="/sv/Alla-sporter/Amerikansk-Fotboll" element={<AfSv />} />


<Route path="/da/Alle-sportsgrene/Baseball" element={<BaseballDa />} />
<Route path="/de/Alle-Sportarten/Baseball" element={<BaseballDe />} />
<Route path="/es/Todos-los-deportes/Béisbol" element={<BaseballEs />} />
<Route path="/fr/Tous-les-sports/Baseball" element={<BaseballFr />} />
<Route path="/it/Tutti-gli-sport/Baseball" element={<BaseballIt />} />
<Route path="/no/Alle-idretter/Baseball" element={<BaseballNo />} />
<Route path="/pt/Todos-os-esportes/Beisebol" element={<BaseballPt />} />
<Route path="/sv/Alla-sporter/Baseball" element={<BaseballSv />} />


<Route path="/da/Alle-sportsgrene/Basketball" element={<BasketbalDa />} />
<Route path="/de/Alle-Sportarten/Basketball" element={<BasketbalDe/>} />
<Route path="/es/Todos-los-deportes/Baloncesto" element={<BasketbalEs />} />
<Route path="/fr/Tous-les-sports/Basketball" element={<BasketbalFr />} />
<Route path="/it/Tutti-gli-sport/Basket" element={<BasketbalIt />} />
<Route path="/no/Alle-idretter/Basketball" element={<BasketbalNo />} />
<Route path="/pt/Todos-os-esportes/Basquetebol" element={<BasketbalPt />} />
<Route path="/sv/Alla-sporter/Basketboll" element={<BasketbalSv />} />


<Route path="/da/Alle-sportsgrene/Field-Hockey" element={<FieldHockeyDa />} />
<Route path="/de/Alle-Sportarten/Hockey" element={<FieldHockeyDe />} />
<Route path="/es/Todos-los-deportes/Hockey" element={<FieldHockeyEs />} />
<Route path="/fr/Tous-les-sports/Hockey" element={<FieldHockeyFr />} />
<Route path="/it/Tutti-gli-sport/Hockey" element={<FieldHockeyIt />} />
<Route path="/no/Alle-idretter/Field-Hockey" element={<FieldHockeyNo />} />
<Route path="/pt/Todos-os-esportes/Hóquei" element={<FieldHockeyPt />} />
<Route path="/sv/Alla-sporter/Fält-Hockey" element={<FieldHockeySv />} />


<Route path="/da/Alle-sportsgrene/Golf" element={<GolfDa />} />
<Route path="/de/Alle-Sportarten/Golf" element={<GolfDe />} />
<Route path="/es/Todos-los-deportes/Golf" element={<GolfEs />} />
<Route path="/fr/Tous-les-sports/Golf" element={<GolfFr />} />
<Route path="/it/Tutti-gli-sport/Golf" element={<GolfIt />} />
<Route path="/no/Alle-idretter/Golf" element={<GolfNo />} />
<Route path="/pt/Todos-os-esportes/Golfe" element={<GolfPt />} />
<Route path="/sv/Alla-sporter/Golf" element={<GolfSv />} />


<Route path="/da/Alle-sportsgrene/Gymnastik" element={<GymnasticsDa />} />
<Route path="/de/Alle-Sportarten/Turnen" element={<GymnasticsDe />} />
<Route path="/es/Todos-los-deportes/Gimnasia" element={<GymnasticsEs />} />
<Route path="/fr/Tous-les-sports/Gymnastique" element={<GymnasticsFr />} />
<Route path="/it/Tutti-gli-sport/Ginnastica" element={<GymnasticsIt />} />
<Route path="/no/Alle-idretter/Gymnastikk" element={<GymnasticsNo />} />
<Route path="/pt/Todos-os-esportes/Ginástica" element={<GymnasticsPt />} />
<Route path="/sv/Alla-sporter/Gymnastik" element={<GymnasticsSv />} />


<Route path="/da/Alle-sportsgrene/Ishockey" element={<IcehockeyDa />} />
<Route path="/de/Alle-Sportarten/Eishockey" element={<IcehockeyDe />} />
<Route path="/es/Todos-los-deportes/Hockey-sobre-hielo" element={<IcehockeyEs />} />
<Route path="/fr/Tous-les-sports/Hockey-sur-glace" element={<IcehockeyFr />} />
<Route path="/it/Tutti-gli-sport/Hockey-su-ghiaccio" element={<IcehockeyIt />} />
<Route path="/no/Alle-idretter/Ishockey" element={<IcehockeyNo />} />
<Route path="/pt/Todos-os-esportes/Hóquei-no-gelo" element={<IcehockeyPt />} />
<Route path="/sv/Alla-sporter/Ishockey" element={<IcehockeySv />} />


<Route path="/da/Alle-sportsgrene/Roning" element={<RowingDa />} />
<Route path="/de/Alle-Sportarten/Rudern" element={<RowingDe />} />
<Route path="/es/Todos-los-deportes/Remo" element={<RowingEs />} />
<Route path="/fr/Tous-les-sports/Aviron" element={<RowingFr />} />
<Route path="/it/Tutti-gli-sport/Canottaggio" element={<RowingIt />} />
<Route path="/no/Alle-idretter/Roing" element={<RowingNo />} />
<Route path="/pt/Todos-os-esportes/Remo" element={<RowingPt />} />
<Route path="/sv/Alla-sporter/Roddning" element={<RowingSv />} />


<Route path="/da/Alle-sportsgrene/Fodbold" element={<SoccerDa />} />
<Route path="/de/Alle-Sportarten/Fußball" element={<SoccerDe />} />
<Route path="/es/Todos-los-deportes/Fútbol" element={<SoccerEs />} />
<Route path="/fr/Tous-les-sports/Football" element={<SoccerFr />} />
<Route path="/it/Tutti-gli-sport/Calcio" element={<SoccerIt />} />
<Route path="/no/Alle-idretter/Fotball" element={<SoccerNo />} />
<Route path="/pt/Todos-os-esportes/Futebol" element={<SoccerPt />} />
<Route path="/sv/Alla-sporter/Fotboll" element={<SoccerSv />} />


<Route path="/da/Alle-sportsgrene/Softball" element={<SoftbalDa />} />
<Route path="/de/Alle-Sportarten/Softball" element={<SoftbalDe />} />
<Route path="/es/Todos-los-deportes/Softball" element={<SoftbalEs />} />
<Route path="/fr/Tous-les-sports/Softball" element={<SoftbalFr />} />
<Route path="/it/Tutti-gli-sport/Softball" element={<SoftbalIt />} />
<Route path="/no/Alle-idretter/Softball" element={<SoftbalNo />} />
<Route path="/pt/Todos-os-esportes/Softball" element={<SoftbalPt />} />
<Route path="/sv/Alla-sporter/Softball" element={<SoftbalSv />} />


<Route path="/da/Alle-sportsgrene/Svømning" element={<SwimmingDa />} />
<Route path="/de/Alle-Sportarten/Schwimmen" element={<SwimmingDe />} />
<Route path="/es/Todos-los-deportes/Natación" element={<SwimmingEs />} />
<Route path="/fr/Tous-les-sports/Natation" element={<SwimmingFr />} />
<Route path="/it/Tutti-gli-sport/Nuoto" element={<SwimmingIt />} />
<Route path="/no/Alle-idretter/Svømming" element={<SwimmingNo />} />
<Route path="/pt/Todos-os-esportes/Natação" element={<SwimmingPt />} />
<Route path="/sv/Alla-sporter/Simning" element={<SwimmingSv />} />


<Route path="/da/Alle-sportsgrene/Tennis" element={<TennisDa />} />
<Route path="/de/Alle-Sportarten/Tennis" element={<TennisDe />} />
<Route path="/es/Todos-los-deportes/Tenis" element={<TennisEs />} />
<Route path="/fr/Tous-les-sports/Tennis" element={<TennisFr />} />
<Route path="/it/Tutti-gli-sport/Tennis" element={<TennisIt />} />
<Route path="/no/Alle-idretter/Tennis" element={<TennisNo />} />
<Route path="/pt/Todos-os-esportes/Ténis" element={<TennisPt />} />
<Route path="/sv/Alla-sporter/Tennis" element={<TennisSv />} />


<Route path="/da/Alle-sportsgrene/Atletik" element={<AthleticDa />} />
<Route path="/de/Alle-Sportarten/Leichtathletik" element={<AthleticDe />} />
<Route path="/es/Todos-los-deportes/Atletismo" element={<AthleticEs />} />
<Route path="/fr/Tous-les-sports/Athlétisme" element={<AthleticFr />} />
<Route path="/it/Tutti-gli-sport/Atletica" element={<AthleticIt />} />
<Route path="/no/Alle-idretter/Friidrett" element={<AthleticNo />} />
<Route path="/pt/Todos-os-esportes/Atletismo" element={<AthleticPt />} />
<Route path="/sv/Alla-sporter/Friidrott" element={<AthleticSv />} />


<Route path="/da/Alle-sportsgrene/Volleyball" element={<VolleybalDa />} />
<Route path="/de/Alle-Sportarten/Volleyball" element={<VolleybalDe />} />
<Route path="/es/Todos-los-deportes/Voleibol" element={<VolleybalEs />} />
<Route path="/fr/Tous-les-sports/Volleyball" element={<VolleybalFr />} />
<Route path="/it/Tutti-gli-sport/Pallavolo" element={<VolleybalIt />} />
<Route path="/no/Alle-idretter/Volleyball" element={<VolleybalNo />} />
<Route path="/pt/Todos-os-esportes/Voleibol" element={<VolleybalPt />} />
<Route path="/sv/Alla-sporter/Volleyboll" element={<VolleybalSv />} />


<Route path="/da/Alle-sportsgrene/Vandpolo" element={<WaterpoloDa />} />
<Route path="/de/Alle-Sportarten/Wasserball" element={<WaterpoloDe />} />
<Route path="/es/Todos-los-deportes/Waterpolo" element={<WaterpoloEs />} />
<Route path="/fr/Tous-les-sports/Water-polo" element={<WaterpoloFr />} />
<Route path="/it/Tutti-gli-sport/Pallanuoto" element={<WaterpoloIt />} />
<Route path="/no/Alle-idretter/Vannpolo" element={<WaterpoloNo />} />
<Route path="/pt/Todos-os-esportes/Pólo-aquático" element={<WaterpoloPt />} />
<Route path="/sv/Alla-sporter/Vattenpolo" element={<WaterpoloSv />} />


<Route path="/da/Alle-sportsgrene/Brydning" element={<WrestlingDa />} />
<Route path="/de/Alle-Sportarten/Ringen" element={<WrestlingDe />} />
<Route path="/es/Todos-los-deportes/Lucha" element={<WrestlingEs />} />
<Route path="/fr/Tous-les-sports/Lutte" element={<WrestlingFr />} />
<Route path="/it/Tutti-gli-sport/Lotta" element={<WrestlingIt />} />
<Route path="/no/Alle-idretter/Bryting" element={<WrestlingNo />} />
<Route path="/pt/Todos-os-esportes/Luta" element={<WrestlingPt />} />
<Route path="/sv/Alla-sporter/Brottning" element={<WrestlingSv />} />

<Route path="/da/NCAA" element={<NCAAda />} />
<Route path="/de/NCAA" element={<NCAAde />} />
<Route path="/es/NCAA" element={<NCAAes />} />
<Route path="/fr/NCAA" element={<NCAAfr />} />
<Route path="/it/NCAA" element={<NCAAit />} />
<Route path="/no/NCAA" element={<NCAAno />} />
<Route path="/pt/NCAA" element={<NCAApt />} />
<Route path="/sv/NCAA" element={<NCAAsv />} />

<Route path="/da/NAIA" element={<NAIAda />} />
<Route path="/de/NAIA" element={<NAIAde />} />
<Route path="/es/NAIA" element={<NAIAes />} />
<Route path="/fr/NAIA" element={<NAIAfr />} />
<Route path="/it/NAIA" element={<NAIAit />} />
<Route path="/no/NAIA" element={<NAIAno />} />
<Route path="/pt/NAIA" element={<NAIApt />} />
<Route path="/sv/NAIA" element={<NAIAsv />} />

<Route path="/da/NJCAA" element={<NJCAAda />} />
<Route path="/de/NJCAA" element={<NJCAAde />} />
<Route path="/es/NJCAA" element={<NJCAAes />} />
<Route path="/fr/NJCAA" element={<NJCAAfr />} />
<Route path="/it/NJCAA" element={<NJCAAit />} />
<Route path="/no/NJCAA" element={<NJCAAno />} />
<Route path="/pt/NJCAA" element={<NJCAApt />} />
<Route path="/sv/NJCAA" element={<NJCAAsv />} />

<Route path="/da/Stater" element={<StatenDa />} />
<Route path="/de/Bundesstaaten" element={<StatenDe />} />
<Route path="/es/Estados" element={<StatenEs />} />
<Route path="/fr/États" element={<StatenFr />} />
<Route path="/it/Stati" element={<StatenIt />} />
<Route path="/no/Stater" element={<StatenNo />} />
<Route path="/pt/Estados" element={<StatenPt />} />
<Route path="/sv/Stater" element={<StatenSv />} />





      </Routes>
    </ThemeProvider>
  );
}

