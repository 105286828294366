import { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import SimplePricingCard from "examples/Cards/PricingCards/SimplePricingCard";
import SimplePricingCardRibbon from "examples/Cards/PricingCards/SimplePricingCardRibbon";
import { PopupButton } from "react-calendly";
import { Link } from 'react-scroll';
import TextField from "@mui/material/TextField"; // Diese Zeile hinzufügen
import Box from '@mui/material/Box'; // Stellen Sie sicher, dass Box importiert wird
import emailjs from 'emailjs-com';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Link as RouterLink } from 'react-router-dom';

function Dienstleistungen() {
  const [activeTab, setActiveTab] = useState(0);
  const [tabType, setTabType] = useState("monthly");
  const [showModal, setShowModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [email, setEmail] = useState("");
  const [isAgreed, setIsAgreed] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  const handleTabType = ({ currentTarget }, newValue) => {
    setActiveTab(newValue);
    setTabType(currentTarget.id);
  };

  const handleEmailSubmit = async () => {
    if (email && isAgreed) {
      const emailParams = {
        from_email: email,
        // andere Parameter bei Bedarf
      };
  
      emailjs.send('service_sj82src', 'template_2eyxhno', emailParams, 'xOBnOFlYt964jvT3q')
        .then((response) => {
          console.log('E-Mail erfolgreich gesendet', response.status, response.text);
          alert('Link angefordert, bitte überprüfen Sie Ihren Posteingang.');
          setShowEmailModal(false);
          setEmail("");
          setIsAgreed(false);
        }, (error) => {
          console.log('Fehler beim Senden der E-Mail', error);
          alert('Anfrage fehlgeschlagen, bitte erneut versuchen.');
        });
    } else if (!isAgreed) {
      alert('Bitte stimmen Sie der Datenschutzerklärung zu.');
    } else {
      alert('Bitte geben Sie eine E-Mail-Adresse ein');
    }
  };

  return (
    <section id="dienstenSection">
      <Container sx={{ pb: { xs: 4, lg: 4 }, pt: 12 }}>
        {/* E-Mail-Eingabe-Modal */}
        <Modal
          open={showEmailModal}
          onClose={() => setShowEmailModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              width: { xs: '90%', sm: '70%', md: '50%', lg: '40%' },
              maxWidth: '500px',
              maxHeight: '90vh',
              overflowY: 'auto',
            }}
          >
            <TextField
              label="E-Mail-Adresse"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ mb: 2 }}
            />
            {/* Kontrollkästchen für die Zustimmung zur Datenschutzerklärung */}
            <FormControlLabel
              control={<Checkbox checked={isAgreed} onChange={(e) => setIsAgreed(e.target.checked)} />}
              label={
                <span>
                  Ich habe die{' '}
                  <RouterLink 
                    to="/Privacy-Policy" 
                    component="a" 
                    target="_blank" 
                    style={{ textDecoration: 'none' }}>
                     Datenschutzerklärung
                  </RouterLink> gelesen und stimme ihr zu.
                </span>
              }
              sx={{ my: 2 }}
            />

            <MKButton variant="gradient" color="dark" onClick={handleEmailSubmit}>
              Senden
            </MKButton>
          </Box>
        </Modal>

        <Grid container item xs={12} justifyContent="center" md={8} mx="auto" textAlign="center">
          <Grid item xs={12}> {/* Volle Breite für die Überschrift */}
            <MKTypography variant="h3" color="text">Dienstleistungen</MKTypography>
          </Grid>
          <Grid item xs={12}> {/* Volle Breite für den Text */}
            <MKTypography variant="body2" color="text">
              Alle Dienstleistungen von Sportstipendien Amerika
            </MKTypography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={10}
          lg={8}
          justifyContent="center"
          textAlign="center"
          mx="auto"
          mt={6}
        >
          <AppBar position="static">
            <Tabs value={activeTab} onChange={handleTabType}>
              <Tab
                id="monthly"
                label={
                  <Link 
                    to="dienstenSection" 
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-70}
                    style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    <MKBox py={0.5} px={2} color="inherit">
                      Dienstleistungen
                    </MKBox>
                  </Link>
                }
              />
              <Tab
                id="annual"
                label={
                  <Link 
                    to="productenSection" 
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-70}
                    style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    <MKBox py={0.5} px={2} color="inherit">
                      Produkte
                    </MKBox>
                  </Link>
                }
              />
            </Tabs>
          </AppBar>
        </Grid>
        <Grid container spacing={3} mt={6}>
          <Modal open={showModal} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
            <Slide direction="down" in={showModal} timeout={500}>
              <MKBox
                position="relative"
                width="500px"
                display="flex"
                flexDirection="column"
                borderRadius="xl"
                bgColor="white"
                shadow="xl"
              >
                <MKBox display="flex" alginItems="center" justifyContent="space-between" p={2}>
                  <MKTypography variant="h5">
                    Wählen Sie ein Beratungsgespräch
                  </MKTypography>
                  <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
                </MKBox>
                <Divider sx={{ my: 0 }} />
                <MKBox p={2}>
                  <MKTypography variant="body2" color="secondary" fontWeight="regular">
                    Sehr geehrter Kunde,
                    <br />
                    <br />
                    Ich freue mich, dass Sie Interesse an meinem Standardangebot haben! Um sicherzustellen, dass meine Dienstleistung perfekt auf Ihre speziellen Bedürfnisse zugeschnitten ist, empfehle ich dringend, zunächst ein unverbindliches Beratungsgespräch zu buchen.
                    <br />
                    <br />
                    Während dieses Gesprächs können wir Ihre Situation im Detail besprechen und gemeinsam festlegen, welcher Ansatz für Sie am besten geeignet ist. Dies sorgt für ein personalisierteres Erlebnis und hilft mir, den Service für Ihre einzigartige Situation zu optimieren.
                  </MKTypography>
                </MKBox>
                <Divider sx={{ my: 0 }} />
                <MKBox display="flex" justifyContent="space-between" p={1.5}>
                  <MKButton variant="gradient" color="dark" onClick={toggleModal}>
                    Schließen
                  </MKButton>
                  
                  <PopupButton
                    url="https://calendly.com/kay-duit"
                    rootElement={document.getElementById("root")}
                    text="TERMIN VEREINBAREN"
                    className="custom-calendly-button"
                  />
                </MKBox>
              </MKBox>
            </Slide>
          </Modal>
          <Grid item xs={12} sm={6} lg={3}>
            <SimplePricingCard
              color="dark"
              title="Informationsleitfaden"
              description="Vollständiger Informationsleitfaden als PDF"
              price={{ value: "Kostenlos", type: "" }}
              action={{
                type: "downloadPDF",
                label: "PDF herunterladen",
                onClick: () => setShowEmailModal(true) // Funktion, um das E-Mail-Eingabe-Modal anzuzeigen
              }}
              specifications={["Vollständiger Informationsleitfaden als PDF"]}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <SimplePricingCardRibbon
              color="dark"
              title="Beratungsgespräch"
              description="60-minütige Online-Beratung"
              price={{ value: "€ 14,99*", type: "" }}
              action={{
                type: "external", // Ändern Sie in "external" für Calendly
                route: "https://calendly.com/kay-duit", // Ihr Calendly-Link
                label: "TERMIN VEREINBAREN"
              }}
              specifications={[
                "Abstimmung von Zielen und Ambitionen",
                "Bewertung der sportlichen Leistung",
                "Plan für akademische Integration",
                "Individuelle Strategie für den Werdegang",
                "Analyse der Erfolgschancen in den USA"
              ]}
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={3}>
            <SimplePricingCard
              color="dark"
              title="Standard"
              description="Ihr Weg in die USA"
              price={{ value: "Auf Anfrage", type: "" }}
              action={{
                type: "modal",
                label: "Anfragen",
                onClick: toggleModal
              }}
              specifications={[
                "Maßgeschneiderter Plan",
                "Unterstützung beim Erstellen eines Sportportfolios",
                "Unterstützung bei der akademischen Vorbereitung",
                "Promotion bei Universitäten",
                "Verhandlungen über Sportstipendien",
                "Beratung bei der Auswahl von Universitäten und Stipendien",
                "Unterstützung bei der Visumbeantragung",
                "Unterstützung bei der Einschreibung",
                "Unterstützung bei praktischen Angelegenheiten",
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <SimplePricingCard
              color="dark"
              title="Premium"
              description="Ihr Weg in die USA"
              price={{ value: "Auf Anfrage", type: "" }}
              action={{
                type: "modal",
                label: "Anfragen",
                onClick: toggleModal
              }}
              specifications={[
                "Maßgeschneiderter Plan",
                "Unterstützung beim Erstellen eines Sportportfolios",
                "Unterstützung bei der akademischen Vorbereitung",
                "Promotion bei Universitäten",
                "Verhandlungen über Sportstipendien",
                "Beratung bei der Auswahl von Universitäten und Stipendien",
                "Unterstützung bei der Visumbeantragung",
                "Unterstützung bei der Einschreibung",
                "Unterstützung bei praktischen Angelegenheiten",
                "SAT/ACT-Studienbücher",
                "Zweiwöchentlicher Zoom-Anruf",
              ]}
            />
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid item xs={12} style={{ marginBottom: '80px' }}>
          <MKTypography variant="body2" color="text">
            {/* Ihr Text kommt hier */}
            *Früher bot ich das Beratungsgespräch kostenlos an. Leider führte dies zu vielen Terminen mit nicht ernsthaften Personen, was zeitlich kostspielig war. Um die Qualität meiner Dienstleistungen zu gewährleisten und sicherzustellen, dass die Gespräche für beide Parteien wertvoll sind, bitte ich nun um eine kleine Gebühr von 14,99 €. Dieser Betrag hilft, ernsthafte Anfragen zu filtern, während das Gespräch informell bleibt und sich auf Ihre spezifischen Bedürfnisse konzentriert.

Ich schätze Ihr Verständnis und freue mich auf ein produktives und aufschlussreiches Gespräch mit Ihnen.
          </MKTypography>
        </Grid>
      </Container>
    </section>
  );
}

export default Dienstleistungen;
