import { useEffect, useRef } from "react";

// rellax

// typed-js


// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbarPortugues";
import DetailedFooter from "examples/Footers/DetailedFooter";


// Coworking page sections
import DesignBlocks from "./TOEFL"

// Routes
import routes from "routes_Portugues";import footerRoutes from "footer.routes_Portugues";
import { Helmet } from 'react-helmet';

// Images
import bgImage from "assets/images/Achtergronden/Home_BG.png";
import Breadcrumbs from "examples/Breadcrumbs";

function ProductPage() {
  const headerRef = useRef(null);
  const typedJSRef = useRef(null);

  // Setting up rellax
 
 

  return (
    <>
  <Helmet>
    <title>Guia de Estudo para o TOEFL - Reforce Suas Habilidades em Inglês para os EUA</title>
    <meta name="description" content="Alcance uma pontuação alta no TOEFL com nosso Guia de Estudo completo. Ideal para atletas holandeses que almejam uma bolsa de estudos esportiva na América." />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta charset="UTF-8" />
    <meta name="robots" content="index, follow" />
    
    {/* Open Graph Tags */}
    
    
    
    
     
    
    {/* Favicon */}
    <link rel="icon" href="https://sportscholarshipsamerica.com/32x32Sportbeurs.png" />
</Helmet>

     <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://calendly.com/sportbeurs-amerika/intake",
          label: "MAAK AFSPRAAK",
          color: "info",
        }}
        transparent
        light
         
      />
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center 40%",
            display: "grid",
            placeItems: "center",
          }}
        />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -14,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
              <MKBox
  width={{ xs: "80%", md: "50%", lg: "25%" }}
  mb={1}
  sx={{
    backgroundColor: "transparent", // Transparent background color
  }}
>
  <Breadcrumbs
    routes={[
      { label: "Home", route: "/pt" },
      { label: "Serviços & Produtos", route: "/pt/Serviços-Produtos" },
      { label: "TOEFL Study Guide" },
    ]}
  />
</MKBox>  
        <DesignBlocks />
        
      </Card>
        <DetailedFooter content={footerRoutes} />
    </>
  );
}

export default ProductPage;
