/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import Image1 from "assets/images/Sports_Images/Tennis1.jpg";
import Image2 from "assets/images/Sports_Images/Tennis2.jpeg";
import Image3 from "assets/images/Sports_Images/Tennis3.jpg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Bolsas de Tênis nos EUA: Como Estudar e Jogar Tênis na América', isSubheader: false },
  
    { id: 'Hoofdstuk 1', title: '1. História e Desenvolvimento do Tênis na América', isSubheader: false },
    { id: '1.1', title: '1.1 Primeiros Anos e a Influência das Universidades', isSubheader: true },
    { id: '1.2', title: '1.2 Crescimento e Profissionalização', isSubheader: true },
  
    { id: 'Hoofdstuk 2', title: '2. Tênis Universitário Hoje', isSubheader: false },
    { id: '2.1', title: '2.1 Principais Competições e Conferências', isSubheader: true },
    { id: '2.2', title: '2.2 Programas de Elite e Equipes Prestigiosas', isSubheader: true },
  
    { id: 'Hoofdstuk 3', title: '3: Da Universidade para o Profissionalismo', isSubheader: false },
    { id: '3.1', title: '3.1 O Caminho para o Profissionalismo', isSubheader: true },
    { id: '3.2', title: '3.2 Circuitos de Tênis Profissional', isSubheader: true },
  
    { id: 'Hoofdstuk 4', title: '4. Influências Internacionais', isSubheader: false },
    { id: '4.1', title: '4.1 Jogadores Estrangeiros no Cenário Universitário', isSubheader: true },
    { id: '4.2', title: '4.2 Americanos no Palco Internacional', isSubheader: true },
  
    { id: 'Hoofdstuk 5', title: '5. Bolsas de Estudo Esportivas e o Futuro dos Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 A Importância das Bolsas de Estudo', isSubheader: true },
    { id: '5.2', title: '5.2 Como a Sport Scholarships America Pode Ajudar', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Conteúdo
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Esconder</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>

























{/* Caixa de Introdução */}
<MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Bolsas de Tênis nos EUA: Como Estudar e Jogar Tênis na América
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Complexo de tênis da Geórgia com arquibancadas lotadas durante uma partida universitária."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Arquibancadas lotadas no complexo de tênis da Geórgia.
      </figcaption>
    </figure>
    O tênis é um esporte que é praticado nos Estados Unidos há mais de um século. Começou como uma atividade recreativa para a elite, mas rapidamente evoluiu para um esporte competitivo e profissional que atraiu milhões de fãs e jogadores em todo o mundo. Um dos principais fatores que contribuíram para o crescimento e desenvolvimento do tênis na América é o sistema universitário. As universidades não só fornecem educação de alta qualidade, mas também excelentes instalações, treinamento, orientação e bolsas de estudo para jogadores talentosos de tênis. Jogar tênis no nível universitário permite aos jogadores aprimorar suas habilidades, expandir sua rede de contatos, aumentar suas chances de uma carreira profissional e obter simultaneamente um diploma valioso.
    <br />
    <br />
    Mas como um jovem jogador de tênis da Holanda ou da Bélgica pode ir para a América para estudar e jogar tênis? A resposta é com uma bolsa de estudos de tênis. Uma bolsa de estudos de tênis é o apoio financeiro concedido por uma universidade a um jogador que atende a determinados critérios acadêmicos e atléticos. Com uma bolsa de estudos de tênis, você pode cobrir ou reduzir suas taxas de matrícula, livros, moradia, refeições e outras despesas. Uma bolsa de estudos de tênis pode variar de uma bolsa de estudos integral, cobrindo todos os custos, a uma bolsa de estudos parcial, cobrindo uma porcentagem dos custos.
    <br />
    <br />
    Mas como você consegue uma bolsa de estudos de tênis? É aí que o Sportbeursamerika.nl pode ajudá-lo. O Sportbeursamerika.nl é uma plataforma que ajuda jovens jogadores de tênis a realizar o sonho de ir para a América. O Sportbeursamerika.nl fornece conselhos, orientação, mediação e suporte para encontrar a melhor universidade e bolsa de estudos para você. O Sportbeursamerika.nl tem uma equipe de especialistas que entendem como o sistema universitário americano funciona e têm contatos com centenas de treinadores e universidades. O Sportbeursamerika.nl já ajudou muitos jogadores a alcançar seu potencial e mudar suas vidas.
    <br />
    <br />
    Quer saber mais sobre o tênis na América e como o Sportbeursamerika.nl pode ajudá-lo? Continue lendo!
  </MKTypography>
</MKBox>

{/* Capítulo 1: História e Desenvolvimento do Tênis na América */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 1: História e Desenvolvimento do Tênis na América
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O tênis é um esporte que teve origem na Europa, mas rapidamente se espalhou para outras partes do mundo, especialmente para os Estados Unidos. Neste capítulo, exploraremos como o tênis se desenvolveu nos EUA e como as universidades desempenharam um papel significativo na evolução e profissionalização do esporte.
  </MKTypography>

  {/* Seção 1.1: Primeiros Anos e Influência das Universidades */}
  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Primeiros Anos e a Influência das Universidades
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O tênis foi introduzido nos EUA no início do século XIX por americanos ricos que aprenderam o jogo durante suas viagens pela Europa. Rapidamente se tornou uma atividade recreativa popular para a elite, que construiu quadras de tênis em suas propriedades e clubes. A primeira partida oficial de tênis nos EUA foi disputada em 1874 entre dois irmãos ingleses, James Dwight e Fred Renshaw, ambos estudantes da Universidade Harvard. Eles jogaram em uma quadra de tênis que construíram na propriedade do tio em Nahant, Massachusetts. Os primeiros campeonatos nacionais, então conhecidos como Campeonato de Simples Masculinos da Associação Nacional de Tênis em Grama dos EUA, foram realizados em 1881 em Newport, Rhode Island.
      <br />
      <br />
      As universidades estavam entre as primeiras instituições a adotar o tênis como esporte competitivo. Elas organizavam torneios intercolegiais e formavam equipes para competir entre si. Os primeiros campeonatos intercolegiais de tênis foram realizados em 1883, com a participação de Harvard, Yale, Princeton e Columbia, e em 1885, o primeiro campeonato intercolegial de tênis oficial foi reconhecido. Essas universidades rapidamente se tornaram conhecidas como os "Quatro Grandes" do tênis universitário americano. Elas dominaram o esporte até o início do século XX, quando outras universidades como Stanford, Califórnia, Chicago e Cornell se juntaram a elas.
      <br />
      <br />
      As universidades também desempenharam um papel significativo na promoção e disseminação do tênis entre diferentes grupos de pessoas. Elas ofereceram oportunidades para mulheres, afro-americanos, imigrantes e outras minorias praticarem e se destacarem no esporte. Um dos exemplos mais notáveis é Althea Gibson, que em 1950 se tornou a primeira mulher afro-americana a ser admitida no campeonato nacional. Ela ganhou seu primeiro título de Grand Slam no Campeonato Francês em 1956 depois de se formar na Universidade Florida A&M e se juntar à Associação de Tênis Americana, uma organização para jogadores negros de tênis. Ela mais tarde ganhou Wimbledon duas vezes e o US Open duas vezes.
    </MKTypography>
  </MKBox>

  {/* Seção 1.2: Crescimento e Profissionalização */}
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crescimento e Profissionalização
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A segunda metade do século XX testemunhou um crescimento significativo e profissionalização do tênis na América. Isso foi possibilitado, em parte, pelo surgimento da televisão, que deu ao esporte mais visibilidade e popularidade. Além disso, novas tecnologias e materiais foram introduzidos, tornando o jogo mais rápido e emocionante. Além disso, novas organizações e circuitos foram estabelecidos para regulamentar e promover o tênis, incluindo a Federação Internacional de Tênis (ITF), a Associação dos Tenistas Profissionais (ATP) e a Associação de Tênis Feminino (WTA).
      <br />
      <br />
      Essas mudanças também afetaram o tênis universitário, que teve que se adaptar à nova realidade do tênis profissional. As universidades tiveram que competir com outras opções para atrair e reter jogadores talentosos. Elas também tiveram que melhorar seus programas para preparar os jogadores para uma carreira profissional potencial. Um dos passos-chave que as universidades deram foi a criação da Associação Atlética Universitária Nacional (NCAA), uma organização abrangente responsável por organizar e regulamentar os esportes universitários nos EUA. A NCAA divide as universidades em três divisões com base em seu tamanho, orçamento e nível de competição. A NCAA organiza campeonatos nacionais anuais para cada divisão, tanto para equipes quanto para indivíduos.
      <br />
      <br />
      Outra consequência da profissionalização do tênis foi que muitos jogadores americanos se destacaram no mais alto nível do esporte. Alguns deles tinham um histórico universitário, provando que o tênis universitário pode fornecer uma base sólida para uma carreira profissional bem-sucedida. Alguns dos jogadores de tênis americanos mais conhecidos que jogaram no nível universitário incluem:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Arthur Ashe, que estudou na UCLA e foi o primeiro homem afro-americano a vencer Wimbledon, o US Open e o Aberto da Austrália.
        </li>
        <li>
          John McEnroe, que frequentou Stanford e ganhou sete títulos de simples do Grand Slam e nove em duplas.
        </li>
        <li>
          Jimmy Connors, que estudou na UCLA e ganhou oito títulos de simples do Grand Slam e dois em duplas.
        </li>
        <li>
          Billie Jean King, que frequentou a California State University, Los Angeles, e ganhou 12 títulos de simples do Grand Slam e 16 em duplas. Ela também foi pioneira na luta pela igualdade de gênero no esporte.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  







{/* Capítulo 2: Tênis Universitário Hoje */}
<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 2: Tênis Universitário Hoje
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O tênis continua sendo um dos esportes mais populares e prestigiados no sistema universitário americano. Todos os anos, milhares de jogadores participam de várias competições e conferências, mostrando o melhor do esporte. Neste capítulo, exploraremos as principais competições e conferências no tênis universitário e os programas de elite e equipes prestigiosas que dominam o esporte.
  </MKTypography>

  {/* Seção 2.1: Principais Competições e Conferências */}
  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Principais Competições e Conferências
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Cameron Norrie em ação pela TCU no tênis universitário."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Cameron Norrie em ação como jogador da TCU
        </figcaption>
      </figure>
      Como vimos no capítulo anterior, a NCAA é a principal organização responsável por organizar e regulamentar os esportes universitários nos EUA. A NCAA divide as universidades em três divisões: Divisão I, Divisão II e Divisão III. Cada divisão tem suas próprias regras, requisitos e campeonatos. Para o tênis, existem duas competições principais organizadas pela NCAA: o campeonato por equipes e o campeonato individual.
      <br />
      <br />
      O campeonato por equipes é um torneio eliminatório disputado entre as principais equipes de cada divisão. As equipes são selecionadas com base em seu desempenho durante a temporada, sua classificação e seus resultados regionais. O campeonato por equipes consiste em duas fases: a fase regional e a fase nacional. A fase regional é disputada entre equipes da mesma região, que são divididas em quatro zonas geográficas: Nordeste, Sudeste, Centro-Oeste e Oeste. Os vencedores de cada região avançam para a fase nacional, que é disputada entre 16 equipes de cada divisão. O campeonato por equipes segue o formato da Copa Davis, ou seja, cada partida consiste em três partidas de duplas e seis partidas de simples.
      <br />
      <br />
      O campeonato individual é um torneio eliminatório disputado entre os melhores jogadores de cada divisão. Os jogadores são selecionados com base em sua classificação, seu desempenho no campeonato por equipes e seus resultados em outros torneios. O campeonato individual consiste em duas categorias: simples e duplas. Cada categoria tem 64 jogadores na Divisão I, 48 jogadores na Divisão II e 32 jogadores na Divisão III.
      <br />
      <br />
      Além dos campeonatos da NCAA, também existem outras competições disputadas entre universidades pertencentes à mesma conferência. Uma conferência é um grupo de universidades que colaboram em áreas acadêmicas, atléticas e administrativas. Existem mais de 30 conferências nos EUA, cada uma com suas próprias regras, calendários e campeonatos. Algumas das conferências mais conhecidas incluem:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          A Conferência da Costa Atlântica (ACC), composta por 15 universidades da Costa Leste, como Duke, North Carolina, Virginia e Florida State.
        </li>
        <li>
          A Conferência do Big Ten, composta por 14 universidades do Centro-Oeste, como Michigan, Ohio State, Illinois e Wisconsin.
        </li>
        <li>
          A Conferência Pacífico-12 (Pac-12), composta por 12 universidades da Costa Oeste, como Stanford, California, UCLA e USC.
        </li>
        <li>
          A Conferência Sudeste (SEC), composta por 14 universidades do Sudeste, como Georgia, Florida, Kentucky e Vanderbilt.
        </li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Essas conferências são importantes não apenas pela rivalidade atlética entre universidades, mas também pela sua reputação acadêmica e prestígio. Além disso, elas oferecem mais oportunidades para os jogadores se destacarem e se classificarem para os campeonatos nacionais.
    </MKTypography>
  </MKBox>

  {/* Seção 2.2: Programas de Elite e Equipes Prestigiosas */}
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programas de Elite e Equipes Prestigiosas
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O nível de competição no tênis universitário é extremamente alto, o que significa que apenas os melhores jogadores e equipes podem prosperar e ter sucesso. No entanto, algumas universidades construíram uma longa tradição e uma forte reputação no tênis, levando a programas de elite que alcançam excelentes resultados ano após ano. Essas universidades não apenas possuem as melhores instalações, treinadores e bolsas de estudo, mas também se destacam na recrutamento, treinamento e orientação de seus jogadores. Alguns dos programas universitários de tênis mais bem-sucedidos e prestigiosos incluem:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Universidade Stanford, que ganhou 21 títulos de equipe da NCAA no tênis feminino e 17 no tênis masculino, mais do que qualquer outra universidade. Stanford também ganhou 25 títulos individuais da NCAA no tênis feminino e 14 no tênis masculino. Alguns dos jogadores mais conhecidos que jogaram por Stanford incluem John McEnroe, Bob e Mike Bryan, Nicole Gibbs e Mallory Burdette.
        </li>
        <li>
          USC (Universidade do Sul da Califórnia), que ganhou quase tantos títulos de equipe da NCAA quanto Stanford no tênis masculino e alguns no tênis feminino. USC também ganhou mais títulos individuais da NCAA do que qualquer outra universidade no tênis masculino e alguns no tênis feminino. Alguns dos jogadores mais conhecidos que jogaram por USC incluem Stan Smith, Alex Olmedo, Steve Johnson e Danielle Collins.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  







{/* Capítulo 3: Da Universidade para o Profissionalismo */}
<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 3: Da Universidade para o Profissionalismo
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Um dos maiores sonhos de muitos jovens tenistas é seguir uma carreira profissional e brilhar no palco mundial. Mas como você pode tornar esse sonho realidade? O tênis universitário é uma boa preparação para o circuito profissional? Neste capítulo, vamos analisar o caminho para o profissionalismo e como o tênis universitário pode ajudar os jogadores a dar esse salto.
  </MKTypography>

  {/* Seção 3.1: O Caminho para o Profissionalismo */}
  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 O Caminho para o Profissionalismo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Tornar-se um jogador de tênis profissional não é uma tarefa fácil. Requer não apenas muito talento, mas também dedicação, disciplina, trabalho duro e sacrifício. Também requer tomar decisões importantes que podem influenciar seu futuro. Uma das decisões mais cruciais que você precisa tomar é se deve ou não ir para a universidade.
      <br />
      <br />
      Existem prós e contras para ambas as opções. Se você escolher se tornar profissional imediatamente após o ensino médio, pode se concentrar totalmente no seu tênis e tentar garantir um lugar no ranking o mais rápido possível. Você também pode aproveitar sua juventude, energia e potencial. No entanto, você também pode enfrentar muitos riscos, como lesões, esgotamento, problemas financeiros ou falta de resultados. Além disso, você pode ter que desistir ou adiar sua educação acadêmica, limitando suas opções se o tênis não der certo.
      <br />
      <br />
      Se você escolher ir para a universidade, pode se beneficiar das muitas vantagens que o tênis universitário oferece. Não apenas você pode receber uma educação de alta qualidade que pode ajudá-lo a obter um diploma e ter uma carreira alternativa, mas também pode desfrutar de uma experiência de vida fantástica que pode ajudá-lo a crescer como pessoa. Você também pode aproveitar as excelentes instalações, treinadores, orientação e bolsas de estudo que as universidades oferecem. No entanto, você também pode encontrar desvantagens, como perder tempo e oportunidades para se tornar profissional, ficar para trás de outros jogadores que começaram mais cedo ou achar difícil fazer a transição da universidade para o profissionalismo.
      <br />
      <br />
      Não há uma resposta única para saber se você deve ou não ir para a universidade. Isso depende da sua situação pessoal, objetivos, preferências e oportunidades. Alguns jogadores tiveram sucesso ao se tornarem profissionais imediatamente, enquanto outros se beneficiaram de sua experiência universitária. O mais importante é tomar uma decisão informada que lhe convém.
    </MKTypography>
  </MKBox>

  {/* Seção 3.2: Circuitos de Tênis Profissional */}
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Circuitos de Tênis Profissional
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Se você decidir se tornar um jogador de tênis profissional, você precisa entender como os circuitos de tênis profissional funcionam e como chegar lá. Existem diferentes circuitos para homens e mulheres, cada um com suas próprias organizações, regras e torneios.
      <br />
      <br />
      Para os homens, existem dois circuitos principais: o ATP Tour e o ITF World Tennis Tour. O ATP Tour é o mais alto nível do tênis profissional masculino, onde os melhores jogadores do mundo competem por títulos prestigiados e prêmios em dinheiro. O ATP Tour consiste em quatro categorias de torneios: os Grand Slams, as Finais da ATP, os Masters 1000 da ATP e os 250/500 da ATP. Para participar desses torneios, você precisa ter um alto ranking, que é determinado pelo número de pontos que você ganha ao vencer partidas.
      <br />
      <br />
      O ITF World Tennis Tour é o nível inferior do tênis profissional masculino, onde os jogadores buscam melhorar seu ranking e se qualificar para o ATP Tour. O ITF World Tennis Tour consiste em três categorias de torneios: o ITF World Tennis Tour M25/M15, o ITF World Tennis Tour M25/M15+H e o ITF World Tennis Tour M15/M15+H. Para participar desses torneios, você precisa ter um ranking da ITF, que é determinado pelo número de pontos que você ganha ao vencer partidas.
      <br />
      <br />
      Para as mulheres, também existem dois circuitos principais: o WTA Tour e o ITF World Tennis Tour. O WTA Tour é o mais alto nível do tênis profissional feminino, onde as melhores jogadoras do mundo competem por títulos prestigiados e prêmios em dinheiro. O WTA Tour consiste em quatro categorias de torneios: os Grand Slams, as Finais da WTA, as Premiadas da WTA e as Internacionais da WTA. Para participar desses torneios, você precisa ter um alto ranking, que é determinado pelo número de pontos que você ganha ao vencer partidas.
      <br />
      <br />
      O ITF World Tennis Tour é o nível inferior do tênis profissional feminino, onde as jogadoras buscam melhorar seu ranking e se qualificar para o WTA Tour. O ITF World Tennis Tour consiste em três categorias de torneios: o ITF World Tennis Tour W100/W60/W25/W15, o ITF World Tennis Tour W100/W60/W25/W15+H e o ITF World Tennis Tour W15/W15+H. Para participar desses torneios, você precisa ter um ranking da ITF, que é determinado pelo número de pontos que você ganha ao vencer partidas.
      <br />
      <br />
      Para fazer a transição do tênis universitário para o tênis profissional, você precisa construir um ranking no ITF World Tennis Tour primeiro e depois tentar se qualificar para o ATP ou WTA Tour. Isso pode ser um processo desafiador e demorado que requer muito esforço, paciência e determinação. No entanto, não é impossível, e existem muitos exemplos de jogadores que fizeram com sucesso a transição e agora estão brilhando no circuito profissional.
    </MKTypography>
  </MKBox>
</MKBox>
 






                                  








<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 4: Influências Internacionais
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O tênis é um esporte global que une pessoas de diferentes nacionalidades, culturas e origens. Isso também é verdadeiro para o tênis universitário na América, que possui uma significativa diversidade e riqueza de influências internacionais. Neste capítulo, vamos explorar como jogadores estrangeiros impactaram o cenário universitário e como jogadores americanos alcançaram o palco internacional.
  </MKTypography>

  {/* Seção 4.1: Jogadores Estrangeiros no Cenário Universitário */}
  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Jogadores Estrangeiros no Cenário Universitário
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Jogadores da Virginia celebrando o campeonato nacional no tênis universitário."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Jogadores da Virginia celebrando o campeonato nacional
        </figcaption>
      </figure>
      Uma das características mais notáveis do tênis universitário na América é o alto percentual de jogadores estrangeiros que participam do esporte. Segundo a Associação de Tênis Intercolegial (ITA), em 2020, havia mais de 3.000 jogadores estrangeiros competindo por universidades americanas, o que representava aproximadamente 40% do número total de jogadores. Esses jogadores eram originários de mais de 100 países, sendo os países mais comuns: Grã-Bretanha, Alemanha, França, Espanha, Austrália, Canadá, Brasil, Japão, China e Índia.
      <br />
      <br />
      As razões pelas quais jogadores estrangeiros escolhem vir para a América estudar e jogar tênis são diversas. Alguns buscam uma melhor educação acadêmica que não podem encontrar em seu próprio país. Outros estão procurando melhores oportunidades de desenvolvimento esportivo que não estão disponíveis em sua terra natal. Ainda outros buscam uma nova experiência de vida que não podem ter em seu próprio país. Seja qual for sua motivação, os jogadores estrangeiros trazem muitas vantagens para o tênis universitário americano.
      <br />
      <br />
      Uma das vantagens é que os jogadores estrangeiros elevam o nível e a qualidade do jogo. Eles trazem novas habilidades, estilos, táticas e estratégias que tornam o jogo mais diversificado e interessante. Eles também desafiam os jogadores americanos a darem o seu melhor e se adaptarem a diferentes situações. Além disso, contribuem para o prestígio e a reputação das universidades pelas quais jogam, recebendo mais atenção e reconhecimento.
      <br />
      <br />
      Outro benefício é que os jogadores estrangeiros enriquecem a cultura e a atmosfera do jogo. Eles trazem novas perspectivas, ideias, valores e tradições que tornam o jogo mais aberto e inclusivo. Eles também criam um senso de comunidade e amizade entre jogadores de diferentes origens que colaboram e se apoiam mutuamente. Além disso, eles aprendem uns com os outros e compartilham suas experiências, ampliando seus horizontes e promovendo o crescimento pessoal.
    </MKTypography>
  </MKBox>

  {/* Seção 4.2: Americanos no Palco Internacional */}
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Americanos no Palco Internacional
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Enquanto jogadores estrangeiros têm um impacto significativo no tênis universitário americano, jogadores americanos também tiveram uma grande influência no tênis internacional. Muitos jogadores americanos que jogaram na universidade encontraram sucesso posteriormente no circuito profissional e representaram seu país no palco mundial. Alguns desses jogadores incluem:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          John Isner, que frequentou a Universidade da Geórgia e foi quatro vezes All-American. Ele é conhecido por seu saque poderoso e partidas longas, como a mais longa da história contra Nicolas Mahut em Wimbledon em 2010. Ele venceu 15 títulos da ATP e alcançou a quarta posição no ranking mundial.
        </li>
        <li>
          Kevin Anderson, que frequentou a Universidade de Illinois e foi duas vezes All-American. Ele é conhecido por seu jogo consistente na linha de base e performances consistentes. Ele venceu seis títulos da ATP e alcançou duas finais de Grand Slam: o US Open em 2017 e Wimbledon em 2018.
        </li>
        <li>
          Danielle Collins, que frequentou a Universidade da Virgínia e foi duas vezes campeã da NCAA. Ela é conhecida por seu estilo de jogo agressivo e expressivo e sua determinação. Ela venceu um título da WTA e alcançou as semifinais do Aberto da Austrália em 2019.
        </li>
        <li>
          Jennifer Brady, que frequentou a UCLA e foi uma vez All-American. Ela é conhecida por seus golpes poderosos e movimento aprimorado. Ela venceu um título da WTA e alcançou a final do Aberto da Austrália em 2021.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  




<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    As bolsas de estudo esportivas oferecem inúmeras oportunidades para jovens atletas mostrarem seu talento e desenvolverem suas habilidades. Uma das oportunidades mais significativas é obter uma bolsa de estudos esportiva para estudar e competir no nível universitário. Neste capítulo, discutiremos a importância das bolsas de estudo esportivas e como a Sport Scholarships America pode ajudá-lo a realizar seu sonho.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 A Importância das Bolsas de Estudo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Uma bolsa de estudos esportiva é um suporte financeiro fornecido por uma universidade a um estudante-atleta para combinar estudos e esportes. Pode cobrir os custos de mensalidades, livros, moradia, refeições e outras despesas. Além disso, pode oferecer benefícios como tutoria acadêmica, cuidados médicos, instalações de treinamento e equipamentos.
      <br />
      <br />
      Obter uma bolsa de estudos esportiva é competitivo, com muitos fatores desempenhando um papel no processo de concessão, incluindo realizações acadêmicas, desempenho atlético, personalidade, motivação, liderança e adaptabilidade. Portanto, é importante se preparar bem e se destacar da concorrência.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Como a Sport Scholarships America Pode Ajudar
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo da Sport Scholarships America, mostrando equipamentos esportivos e um capelo de formatura, representando o foco em bolsas de estudo esportivas para estudantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo da Sport Scholarships America
        </figcaption>
      </figure>
      A Sport Scholarships America é uma organização especializada em orientar jovens talentos esportivos para bolsas de estudo esportivas. Com especialistas apaixonados e experientes, a Sport Scholarships America apoia estudantes-atletas em cada etapa da jornada, desde a orientação inicial até a colocação final. Seus serviços incluem:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Uma entrevista de admissão pessoal para discutir os desejos, possibilidades e expectativas do estudante-atleta.</li>
        <li>Uma avaliação profissional do nível acadêmico e atlético do estudante-atleta.</li>
        <li>Uma extensa rede de contatos com treinadores, universidades e faculdades.</li>
        <li>Promoção eficaz do perfil e vídeos do estudante-atleta para treinadores potenciais.</li>
        <li>Conselhos especializados sobre as melhores opções com base nas preferências, metas e orçamento do estudante-atleta.</li>
        <li>Negociação das melhores ofertas de bolsas de estudo em nome do estudante-atleta.</li>
        <li>Auxílio na solicitação de documentos necessários, como visto, histórico escolar, diploma e testes de admissão.</li>
        <li>Preparação para a vida do estudante-atleta durante a bolsa de estudos, incluindo cultura, idioma, regras e dicas.</li>
        <li>Acompanhamento e acompanhamento durante o período da bolsa de estudos.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Quer explorar oportunidades de bolsas de estudo e alcançar seus objetivos atléticos e acadêmicos? <Link to="/pt/Serviços-Produtos">Veja a gama completa</Link> de serviços oferecidos pela Sport Scholarships America e agende uma consulta hoje.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;