import Alabama from "assets/images/Staten_Images/Alabama.webp"
import Florida from "assets/images/Staten_Images/Florida.jpeg"
import Connecticut from "assets/images/Staten_Images/Connecticut.jpeg"
import Kentucky from "assets/images/Staten_Images/Kentucky.webp"
import Michigan from "assets/images/Staten_Images/Michigan.jpeg"
import North_Carolina from "assets/images/Staten_Images/North_Carolina.jpg"
import Texas from "assets/images/Staten_Images/Texas.jpeg"



const states = [
  {
    "name": "Alabama",
    image: Alabama,
    "imageDescription": "Alabama mot Auburn er en av de største rivaliseringene innenfor college-fotball.",

    "description": [
        "College-sport er en integrert del av kulturen i Alabama, med spesielt fokus på amerikansk fotball. University of Alabama (UA) i Tuscaloosa og Auburn University i Auburn dominerer sportslandskapet i staten.",
        "UAs fotballag, Crimson Tide, har en rik historie og har vunnet utallige nasjonale mesterskap. Hjemmekampene på det enorme Bryant-Denny Stadium kan tiltrekke seg opptil 100 000 tilskuere. Fansens lidenskap er enorm, og 'Roll Tide'-ropet er kjent over hele Alabama.",
        "På den andre siden har vi Auburn University med deres fotballag, Tigers. De, sammen med UA, utgjør Iron Bowl, en av de mest intense rivaliseringene innenfor college-sport. Dette årlige oppgjøret snur hele staten på hodet og garanterer spenning og spektakkel.",
        "I tillegg til fotball har Alabama sterke programmer innen basketball, baseball og andre idretter, støttet av en kraftig følelse av fellesskapsspirit. I Alabama er sport mer enn bare tidsfordriv; det er en viktig del av statens livsstil og identitet."
    ]
},

{
    "name": "Alaska",
    "description": [
        "I motsetning til mange andre amerikanske stater, spiller college-sport en mer beskjeden rolle i Alaska, hovedsakelig på grunn av dens fjerne beliggenhet og unike klima. Her er fokuset mer på vintersport og utendørsaktiviteter enn på tradisjonelle amerikanske idretter som fotball og baseball.",
        "University of Alaska Anchorage (UAA) og University of Alaska Fairbanks (UAF) er de primære institusjonene med aktive sportsprogrammer. UAA har et sterkt ski- og skøytelag, mens UAF er kjent for sitt ishockeylag, Nanooks, som har en lojal lokal følgerskare.",
        "I Alaska handler college-sport mer om opplevelsen og fellesskapet enn store arrangementer. Kampene gir en mulighet for fellesskapet å samles, heie på utøverne og feire statens unike sportskultur.",
        "Til tross for den mindre skalaen har college-sport i Alaska sin egen sjarm og tilbyr et varmt fellesskap i statens kalde miljø."
    ]
},

{
    "name": "Arizona",
    "description": [
        "College-sport er enormt populært i Arizona, med en rik tradisjon innen både amerikansk fotball og basketball. Arizona State University (ASU) i Tempe og University of Arizona (UA) i Tucson er pilarene i statens sportslandskap.",
        "ASUs fotballag, Sun Devils, har en lidenskapelig følgeskare, mens deres basketballag jevnlig glitrer i NCAA-turneringer. UA, med deres Wildcats, er kjent for sitt vellykkede basketballprogram og intense rivalisering med ASU, som kulminerer i det årlige 'Duel in the Desert'-fotballkampen.",
        "I tillegg til de store idrettene, utmerker Arizona seg også innen baseball, softball og golf, med universitetslagene ofte i nasjonalt fokus. Fellesskapsstøtten er overveldende, og sportsarrangementer regnes som betydningsfulle sosiale anledninger.",
        "College-sport i Arizona inkarnerer konkurransens og fellesskapets ånd, med intens rivalisering og fansens lidenskap som bidrar til en levende og livlig sportskultur."
    ]
}
,

{
  "name": "Arkansas",
  "description": [
    "I Arkansas spiller college-sport en sentral rolle i lokalsamfunnet, med spesiell vekt på amerikansk fotball og basketball. University of Arkansas i Fayetteville er en sterk aktør i staten, kjent for sitt Razorbacks-lag.",
    "Razorbacks' fotballag nyter regional og nasjonal anerkjennelse, med et dedikert fanbas som lidenskapelig følger med på hvert spill. Hjemmekampene deres på det imponerende Razorback Stadium er en spektakulær opplevelse, der 'Woo Pig Sooie'-ropet gjalles av titusenvis av fans.",
    "Basketball er også en betydelig del av sportskulturen i Arkansas, der Razorbacks jevnlig deltar i NCAA-turneringen. Spenningen og energien i Bud Walton Arena under basketballsesongen er merkbar.",
    "Utover de største idrettene, har Razorbacks også sterke programmer innen friidrett, baseball og softbal. Denne mangfoldigheten innen idrett beriker sportskulturen i Arkansas, der college-sport er en kilde til stolthet, fellesskapsspirit og underholdning."
  ]
},

{
  "name": "California",
  "description": [
    "College-sport i California er mangfoldig og svært konkurransedyktig, med mange universiteter som utmerker seg i ulike idretter. Fra amerikansk fotball til svømming, staten har alt.",
    "Pac-12-konferansen, med prominente skoler som University of California, Los Angeles (UCLA) og University of Southern California (USC), er kjent for sitt høye nivå av sport. UCLAs basketballag har en rik historie, mens USC tradisjonelt utmerker seg innen fotball.",
    "I tillegg til de kjente lagene, er det også mindre skoler med imponerende sportsprogrammer, som Stanford University, kjent for deres fremragende svømming og friidrett. Dette mangfoldet skaper et dynamisk og spennende sportsmiljø i staten.",
    "Californias behagelige klima og omfattende sportsfasiliteter fremmer aktiv deltakelse og entusiasme for college-sport. Enten det er en fotballkamp i et fullsatt stadion eller en lokal svømmekonkurranse, er sport en integrert del av den californiske livsstilen."
  ]
},

{
  "name": "Colorado",
  "description": [
    "College-sport i Colorado nyter stor entusiasme og følgeskap, delvis på grunn av de imponerende naturomgivelsene og den aktive utendørslivsstilen. Staten har en rik tradisjon innen amerikansk fotball, basketball og vintersport.",
    "University of Colorado Boulder (CU) og Colorado State University (CSU) er prominente aktører i college-sportscenen, med den årlige 'Rocky Mountain Showdown'-fotballkampen som høydepunktet i rivaliseringen deres.",
    "CUs fotballag, Buffaloes, har en lidenskapelig fanbase, mens deres skilag utmerker seg i nasjonale konkurranser, og drar nytte av Colorados utmerkede vintersportsfasiliteter. CSU er også konkurransedyktige innen ulike idretter, med en sterk tilstedeværelse innen amerikansk fotball og basketball.",
    "I tillegg til de større universitetene har mindre høyskoler i Colorado også aktive sportsprogrammer, noe som bidrar til den rike og varierte sportskulturen i staten. College-sport i Colorado står for fellesskap, utholdenhet og feiring av sport i alle former."
  ]
},

{
  "name": "Connecticut",
  image: Connecticut,
  "imageDescription": "UConns kvinnelige basketballag er en av de mest dominerende college-sportslagene gjennom tidene",

  "description": [
    "College-sport i Connecticut domineres av basketball, med spesiell vekt på de vellykkede programmene til University of Connecticut (UConn). Staten har en rik tradisjon og en lidenskapelig fanbase dypt investert i college-sport.",
    "UConns kvinnelige basketballag er nasjonalt anerkjent og har vunnet flere NCAA-mesterskap, noe som gjør dem til en maktfaktor i sporten. Herrelaget har også oppnådd imponerende resultater, noe som gjør basketball til en sentral del av sportskulturen i Connecticut.",
    "Mens basketball hersker suverent, er det også sterke programmer innen andre idretter som ishockey, fotball og lacrosse, der ulike universiteter er konkurransedyktige på nasjonalt nivå.",
    "College-sport i Connecticut inkarnerer stoltheten og fellesskapsspiritet i staten, der sportsarrangementer gir en mulighet for fansen å samles, heie på lagene og delta i en rik sportskultur."
  ]
}
,
{
  "name": "Delaware",
  "description": [
    "Tross å være en av de minste statene i USA, har Delaware en levende college-sportsscene med sterkt fellesskapsengasjement. University of Delaware (UD) i Newark er midtpunktet for handlingen, der Blue Hens utmerker seg i ulike idretter.",
    "UDs fotballag har en rik historie og en dedikert fanbase, med spennende hjemmekamper på Delaware Stadium. Basketball og lacrosse er også populære idretter, med lag som jevnlig konkurrerer om regional og nasjonal anerkjennelse.",
    "Tross sin lille størrelse har Delaware sterke programmer innen friidrett, svømming og andre idretter, som alle bidrar til den dynamiske sportskulturen i staten. College-sport her er mer enn bare en fritidsaktivitet; det er en viktig del av fellesskapsidentiteten, og fremmer en sterk forbindelse mellom universitetet og det bredere samfunnet.",
    "I Delaware bringer college-sport mennesker sammen, der den lille skalaen skaper et tett og lidenskapelig sportsfellesskap."
  ]
},

{
  "name": "Florida",
  image: Florida,
  "imageDescription": "Ben Hill Griffin Stadium er hjemmet til Florida Gators",

  "description": [
    "Florida er et knutepunkt for college-sport, med noen av de mest konkurransedyktige og lidenskapelige programmene i landet. Amerikansk fotball og basketball dominerer sportsmiljøet, men det er også plass til en rekke andre idretter.",
    "Lag som Florida Gators og Florida State Seminoles har nasjonal anerkjennelse og en rik historie både innen fotball og basketball. Deres intense rivalisering trekker tusenvis av fans årlig, og kampene er høydepunktene i sportsesongen.",
    "I tillegg er det også mindre universiteter og høyskoler med sterke sportsprogrammer som bidrar til den rike sportskulturen i staten. Baseball, friidrett og svømming er også populært, og de utmerkede fasilitetene og solfylte klimaet gjør Florida til et ideelt sted for college-utøvere.",
    "I Florida er college-sport mer enn bare konkurranse; det er en livsstil, en kilde til stolthet og en viktig del av fellesskapsidentiteten."
  ]
},

{
  "name": "Georgia",
  "description": [
    "College-sport i Georgia kjennetegnes av intens lidenskap og dypt forankrede rivaliseringer, med amerikansk fotball som den ubestridte kongen. Staten er hjemsted for noen av de mest prestisjetunge programmene i landet, der fans lidenskapelig opplever hvert spill.",
    "University of Georgia Bulldogs i Athens og Georgia Tech Yellow Jackets i Atlanta er de største aktørene, med deres årlige 'Clean, Old-Fashioned Hate' rivalisering som griper staten. Fotballkamper, spesielt på UGAs massive Sanford Stadium, tiltrekker seg titusenvis av fans.",
    "I tillegg til fotball, utmerker Georgia seg også innen basketball, friidrett og baseball, med talentfulle utøvere og dedikerte tilhengere. Sportsprogrammene ved mindre skoler og høyskoler bidrar til et rikt og mangfoldig sportslandskap over hele staten.",
    "I Georgia er sport mer enn konkurranse; det er en livsstil, en kilde til fellesskapsspirit og en uunnværlig del av regional identitet."
  ]
},
{
  "name": "Hawaii",
  "description": [
    "College sports på Hawaii tilbyr en unik opplevelse inspirert av øyens skjønnhet og sterke lokale kultur. Til tross for å være geografisk isolert, er sportslag og fans på Hawaii ekstraordinært lidenskapelige og engasjerte.",
    "University of Hawaii på Manoa spiller en sentral rolle i college-sportsscenen, med sitt fotballag, Rainbow Warriors, som et betydelig fokuspunkt. Hjemmekampene deres på Aloha Stadium skaper en levende og festlig atmosfære, med en sterk øyspirit.",
    "I tillegg til amerikansk fotball, er volleyball, surfing og kanoracing også populært, noe som gjenspeiler den unike sportskulturen og naturlandskapet på Hawaii. Rainbow Warriors er også konkurransedyktige innen basketball og baseball, med en dedikasjon som deles av hele samfunnet.",
    "På Hawaii representerer college-sport Aloha-ånden, der lag og fans omfavner øykultur, fellesskap og stolthet, og skaper en unik og levende sportsopplevelse sammen."
  ]
},
{
  "name": "Idaho",
  "description": [
    "College-sport i Idaho har en sterk tradisjon, med spesiell vekt på amerikansk fotball og basketball. Følelsen av fellesskap og engasjement i sportslag er bemerkelsesverdig, selv om staten ikke huser store universiteter.",
    "Boise State University, kjent for sin blå fotballbane, spiller en fremtredende rolle i Idahos sportslandskap. Fotballaget har fått nasjonal oppmerksomhet med sin suksess og innovative spillestil, og hjemmekampene er en begivenhet i seg selv.",
    "I tillegg til Boise State bidrar mindre skoler og høyskoler også til den rike sportskulturen, med programmer innen idretter som baseball, friidrett og vintersport som drar nytte av Idahos unike naturmiljø.",
    "I Idaho er college-sport mer enn bare en fritidsaktivitet; det er en livsstil og en viktig del av statens livsstil og identitet, der samfunn kommer sammen for å støtte sine lag."
  ]
},
{
  "name": "Illinois",
  "description": [
    "Illinois har en rik historie og dyp kjærlighet for college-sport, med sterke programmer og dedikerte fans. Amerikansk fotball og basketball trekker betydelig oppmerksomhet gjennom året, men det er også et bredt utvalg av andre idretter som trives.",
    "University of Illinois Fighting Illini og Northwestern University Wildcats leder an, med intense rivaliseringer og konkurransedyktige ligaer som holder staten levende. Memorial Stadium og Ryan Field er idrettens templer, der hvert spill er en uforglemmelig opplevelse.",
    "Basketball er også fremtredende, med staten jevnlig produserer talenter som utmerker seg både nasjonalt og internasjonalt. Illinois Fighting Illini har en rik basketballtradisjon og er en konstant trussel i NCAA-turneringer.",
    "Fra små høyskoler til store universiteter, fungerer sport i Illinois som en bånd, en kilde til stolthet og en viktig del av kulturen og identiteten til staten."
  ]
},
{
  "name": "Indiana",
  "description": [
    "Indiana er kjent for sin dype kjærlighet til basketball, og dette gjenspeiles i delstatens college-sportskultur. Rivaliseringen, lidenskapen og dedikasjonen til både spillere og fans er uovertruffen.",
    "Programmer som Indiana Hoosiers og Purdue Boilermakers er nasjonalt anerkjente, spesielt innen basketball. En elektrisk atmosfære henger i steder som Assembly Hall og Mackey Arena gjennom året, der historie og tradisjon forenes.",
    "Mens basketball tar ledelsen, trives også andre idretter i Indiana. Amerikansk fotball, baseball og friidrett har alle sterke programmer og nyter bred samfunnsstøtte.",
    "Sport i Indiana er mer enn bare et spill; det er en livsstil, en katalysator for fellesskapsspirit og en stolt tradisjon som overlever gjennom generasjoner. Her, i hjertet av Midtvesten, er college-sport en integrert del av delstatens identitet."
  ]
},

{
  "name": "Iowa",
  "description": [
    "College-sport i Iowa er en kilde til stolthet og fellesskapsspirit, med en rik tradisjon innen amerikansk fotball og bryting. Rivaliseringen mellom Iowa Hawkeyes og Iowa State Cyclones er sentral og gir uforglemmelige øyeblikk hver sesong.",
    "Kinnick Stadium i Iowa City og Jack Trice Stadium i Ames er arenaene for intense fotballkamper hver høst, og tiltrekker seg titusenvis av fans. Fansens lidenskap og dedikasjon er karakteristisk for delstatens sportskultur.",
    "Bryting er en annen idrett Iowa utmerker seg i, med Hawkeyes som regelmessig vinner nasjonale titler. Denne sporten har en dypt rotfestet tradisjon i Iowa og nyter lojal støtte.",
    "Fra små høyskoler til store universiteter, er sport i Iowa mer enn konkurranse; det er en livsstil, en kilde til fellesskapsspirit og en viktig del av delstatens identitet."
  ]
},
{
  "name": "Kansas",
  "description": [
    "College-sport i Kansas er preget av tradisjon og fellesskapsspirit, med basketball som det ubestridte høydepunktet. Delstaten huser noen av de mest ikoniske programmene i landet, og rivaliseringen og lidenskapen er intens.",
    "Kansas Jayhawks og Kansas State Wildcats leder an, med deres årlige 'Sunflower Showdown' som en av høydepunktene i sportsåret. Allen Fieldhouse, hjemmebanen til Jayhawks, er kjent som en av de mest skremmende arenaene innen college-basketball.",
    "Mens basketball danner hjertet av sportskulturen i Kansas, spiller amerikansk fotball, baseball og friidrett også en betydelig rolle. Fansen er uten sidestykke i sin dedikasjon og bidrar til den levende atmosfæren under kampene.",
    "Sport i Kansas er mer enn bare konkurranse; det er en kilde til stolthet, en katalysator for fellesskap og en viktig del av delstatens identitet."
  ]
},
{
  "name": "Kentucky",
  image: Kentucky,
  "imageDescription": "Karl-Anthony Towns og Devin Booker spilte sammen for Kentucky og ble senere store NBA-stjerner",
  "description": [
    "College-sport i Kentucky er synonymt med basketball, med en rik tradisjon og lidenskapelig fanbase. Rivaliseringen mellom Kentucky Wildcats og Louisville Cardinals er en av de mest intense og fulgte i landet.",
    "Rupp Arena, hjemmebanen til Wildcats, er et sant basketballtempel, der programmets historie og stolthet er håndgripelig. Wildcats har flere nasjonale mesterskap på sin merittliste og er en fast bestanddel innen college-basketball.",
    "I tillegg til basketball har Kentucky også sterke programmer innen amerikansk fotball, ridesport og skyting, som alle beriker delstatens sportskultur. Kentucky Derby, et legendarisk hesteveddeløp, understreker den rike tradisjonen innen ridesport.",
    "I Kentucky er sport mer enn en fritidsaktivitet; det er en livsstil, en kilde til fellesskap og stolthet, og en viktig del av delstatens identitet."
  ]
},
{
  "name": "Louisiana",
  "description": [
    "I Louisiana er college-sport en integrert del av kulturen, med dyp lidenskap for amerikansk fotball og baseball. Louisiana State University (LSU) Tigers er en maktfaktor, som tiltrekker massive publikumsmasser og skaper en uovertruffen atmosfære.",
    "'Tiger Stadium', også kjent som 'Death Valley', er beryktet for sine bråkete fans og skremmende atmosfære, noe som gjør det til en av de mest fryktede hjemmebanene innen college-fotball. 'Saturday Night in Death Valley' er mer enn bare en kamp; det er en begivenhet, en tradisjon, en del av Louisianas sjel.",
    "I tillegg til LSU, bidrar mindre universiteter og høyskoler også til det rike sportslandskapet, med sterke programmer innen ulike idretter. Baseball nyter også stor popularitet, med LSU som regelmessig konkurrerer om den nasjonale tittelen.",
    "Sport i Louisiana er en feiring av fellesskap, en kilde til stolthet og et bånd som bringer mennesker sammen, uavhengig av deres bakgrunn. Det er en levende og viktig del av delstatens livsstil og identitet."
  ]
}
,
  
{
  "name": "Maine",
  "description": [
    "College-sport i Maine gjenspeiler delstatens unike natur og fellesskapsfølelse, med et sterkt fokus på vintersport og friidrett. University of Maine Black Bears leder an, og representerer stolt i ulike NCAA-divisjoner.",
    "Alfond Stadium i Orono er hjertet av amerikansk fotball i delstaten, mens Harold Alfond Sports Arena spiller en sentral rolle i ishockeysesongen. Black Bears har en stolt ishockeytradisjon, med entusiastiske fans som trosser kulden for å støtte laget sitt.",
    "I tillegg til ishockey og amerikansk fotball, er det også sterke programmer innen friidrett, roing og andre idretter som beriker Maines idrettslandskap. Delstaten nyter også livlige konkurranser innen videregående skole og klubbsport, noe som gjør sport til en integrert del av samfunnet.",
    "Sport i Maine er mer enn bare konkurranse; det er en kilde til stolthet, en måte å feire delstatens unike ånd på, og et bånd som bringer mennesker sammen, uavhengig av årstid."
  ]
},
{
  "name": "Maryland",
  "description": [
    "College-sport i Maryland trives med en sterk tradisjon innen basketball, lacrosse og amerikansk fotball. University of Maryland Terrapins, eller bare Terps, er sentrale i delstatens sportskultur.",
    "Xfinity Center og Maryland Stadium er stolte arenaer for basketball og amerikansk fotball, der Terps spiller sine hjemmekamper. Fansen er brennende og bidrar til en elektrisk atmosfære under kampene. Maryland er kjent for sitt konkurransedyktige basketballprogram, med flere opptredener i NCAA-turneringen.",
    "Lacrosse er en annen idrett der Maryland utmerker seg, med Terps som jevnlig konkurrerer om nasjonale titler. Denne sporten har en spesiell plass i hjertene til innbyggerne, og intense college-rivaliseringer gjør hver sesong til et skue.",
    "Sport i Maryland er et uttrykk for fellesskap, stolthet og tradisjon, og spiller en avgjørende rolle i å samle folk og feire delstatens unike kultur."
  ]
},
{
  "name": "Massachusetts",
  "description": [
    "I Massachusetts spiller college-sport en betydelig rolle i dagliglivet, med en rik historie innen basketball, amerikansk fotball og hockey. Universitetene her har sterke sportsprogrammer og lidenskapelige fans.",
    "Basketball har en spesiell plass i hjertene til innbyggerne i Massachusetts, ettersom spillet ble oppfunnet her. University of Massachusetts Minutemen, kjent som UMass Minutemen, er stolte representanter i NCAA Division I basketball.",
    "Amerikansk fotball og hockey er også populære, med intense lokale rivaliseringer som gir spennende konkurranse. Harvard-Yale-fotballkampen, kjent som 'The Game', er et årlig høydepunkt.",
    "Sport i Massachusetts handler om tradisjon, fellesskap og konkurranse. Det samler folk, uavhengig av deres bakgrunn, og er en essensiell del av delstatens kulturelle identitet."
  ]
},
{
  "name": "Michigan",
  image: Michigan,
  "imageDescription": "Michigan Stadium: den tredje største stadion i verden og hjemstedet til Michigan Wolverines. Den kan romme 107 601 tilskuere.",
  "description": [
    "Michigan har en rik tradisjon innen college-sport, spesielt innen amerikansk fotball og basketball. Rivaliseringen mellom University of Michigan Wolverines og Michigan State Spartans er legendarisk og skaper en elektrisk atmosfære over hele delstaten.",
    "Michigan Stadium, også kjent som 'The Big House', er en av de største stadionene i verden, og rommer over 100 000 tilskuere. Wolverines har en ærefull historie, med flere nasjonale mesterskap i fotball.",
    "Basketball er også enormt populært, med både Michigan og Michigan State som jevnlig konkurrerer i NCAA-turneringen. Fansens lidenskap og dedikasjon er uovertruffen, og kampdager er virkelige begivenheter i Michigan.",
    "I tillegg til disse store sportene har Michigan også sterke programmer innen ishockey, friidrett og andre idretter. Sport i Michigan er mer enn bare en fritidsaktivitet; det er en integrert del av livsstilen og identiteten til delstaten."
  ]
}
,
    
{
  "name": "Minnesota",
  "description": [
    "College-sport i Minnesota har en unik karakter, med sterk vekt på ishockey, amerikansk fotball og basketball. University of Minnesota Golden Gophers spiller en sentral rolle i delstatens sportsfellesskap.",
    "TCF Bank Stadium og Williams Arena er travle aktivitetssentre under fotball- og basketballsesongene, mens Mariucci Arena er en bastion for ishockey. Golden Gophers er stolte representanter i NCAA, med en rik historie og dedikerte fans.",
    "Ishockey er spesielt populært i Minnesota, ofte referert til som 'The State of Hockey.' College-lagene nyter stor støtte og gir spennende action, spesielt under den berømte 'Frozen Four' NCAA-turneringen.",
    "Sport i Minnesota er dypt vevd inn i kulturen og identiteten til delstaten, og tilbyr en kilde til stolthet, fellesskapsånd og livlige rivaliseringer som bringer mennesker i alle aldre sammen."
  ]
},
{
  "name": "Mississippi",
  "description": [
    "College-sport er en integrert del av kulturen i Mississippi, med intens lidenskap for amerikansk fotball, basketball og baseball. Rivaliseringen mellom University of Mississippi (Ole Miss) Rebels og Mississippi State Bulldogs er en av de mest intense i landet.",
    "Vaught-Hemingway Stadium i Oxford og Davis Wade Stadium i Starkville er ekte templer for fotballfans, der spenningen og lidenskapen i spillet er håndgripelig. Den årlige Egg Bowl, sammenstøtet mellom Ole Miss og Mississippi State, er et høydepunkt i sportskalenderen.",
    "Basketball og baseball er også betydelige, med begge universitetene som bygger sterke lag og tradisjoner. Fansen er lojale og vokale, og skaper en elektrisk atmosfære under kampene.",
    "Sport i Mississippi handler om fellesskap, stolthet og tradisjon. Det samler folk, skaper uforglemmelige øyeblikk og er en essensiell del av delstatens identitet."
  ]
},
{
  "name": "Missouri",
  "description": [
    "College-sport i Missouri er synonymt med lidenskap, fellesskap og konkurranse. Delstaten har en rik sports-historie, med amerikansk fotball, basketball og baseball i front.",
    "University of Missouri Tigers, også kjent som Mizzou, og Missouri State Bears er to prominente representanter i college-sportens landskap. Mizzous Faurot Field og Mizzou Arena er sentra for spenning og lagspirit på kampdager.",
    "Tigers har en sterk tilstedeværelse i SEC, en av de mest konkurransedyktige konferansene innen college-fotball. Basketballaget deres er også en fast del av NCAA-turneringen, med fans som gir ubetinget støtte.",
    "Sport i Missouri styrker båndene i samfunnet og bringer folk sammen. Det handler ikke bare om spillet; det handler om opplevelsen, tradisjonene og den uatskillelige båndet mellom lagene og deres supportere."
  ]
},
{
  "name": "Montana",
  "description": [
    "I Montana spiller college-sport en avgjørende rolle i samfunnslivet, med amerikansk fotball, basketball og rodeo som populære idretter. Montana Grizzlies og Montana State Bobcats dominerer sportslandskapet, og rivaliseringen deres er intens.",
    "Grizzlies' Washington-Grizzly Stadium og Bobcats' Bobcat Stadium er stolte fotballarenaer i Montana. Her samles fansen for å støtte lagene sine, uavhengig av været. Den årlige Brawl of the Wild-kampen mellom Grizzlies og Bobcats er et must-see arrangement.",
    "Basketball er også populært, med livlige kamper og en sterk fanskare. Rodeo, unikt for Montana, tiltrekker også mye oppmerksomhet og anerkjennelse, noe som gjenspeiler delstatens kultur og tradisjoner.",
    "College-sport i Montana handler om fellesskap, stolthet og kjærlighet til spillet. De bringer folk sammen, skaper livslange minner og bidrar til den unike identiteten til Big Sky Country."
  ]
},
    
{
  "name": "Nebraska",
  "description": [
    "I Nebraska er college-sport preget av dyp lidenskap og lojalitet, med spesiell vekt på amerikansk fotball. Nebraska Cornhuskers, basert ved University of Nebraska-Lincoln, er hjertet av delstatens sportskultur.",
    "Memorial Stadium, hjemmet til Cornhuskers, kalles også 'The Sea of Red,' der fans i sin distinkte røde bekledning samles for å støtte laget sitt. Stadionet er kjent for sin øredøvende støy og unike atmosfære på kampdager.",
    "I tillegg til fotball, spiller basketball også en betydelig rolle, med både herre- og damelag som konkurrerer på et høyt nivå. Fansen er like dedikerte og skaper en levende atmosfære under hver kamp.",
    "College-sport i Nebraska går utover bare sporten; det er en måte å samle samfunnene på, feire tradisjoner og skape en følelse av stolthet og fellesskap i 'The Cornhusker State.'"
  ]
},
{
  "name": "Nevada",
  "description": [
    "College-sport i Nevada tilbyr en unik blanding av underholdning og konkurranse, med spesiell oppmerksomhet på basketball og amerikansk fotball. University of Nevada, Las Vegas (UNLV), og University of Nevada, Reno er de to viktigste institusjonene innen sport.",
    "UNLVs basketball-lag, Runnin 'Rebels, har en rik historie og er kjent nasjonalt. Hjemmekampene deres er en ekte attraksjon, komplett med energiske opptredener og et entusiastisk publikum. Nevada Wolf Packs fotball-lag bidrar også til sportskulturen i delstaten, med spennende kamper og en lojal fanskare.",
    "I Nevada er det også en levende interesse for andre college-sporter som baseball, softball og fotball. Disse sportene tiltrekker lokale samfunn og gir rikelig med muligheter for utøvere til å utvikle seg.",
    "Samlet sett er college-sport i Nevada en viktig del av delstaten, og gir ikke bare underholdning, men også samler samfunnene og beriker den lokale kulturen."
  ]
},
{
  "name": "New Hampshire",
  "description": [
    "I den pittoreske delstaten New Hampshire spiller college-sport en avgjørende rolle i samfunnslivet. Selv om delstaten er mindre enn mange andre, er dedikasjonen til sport enorm.",
    "University of New Hampshire (UNH) dominerer sportslandskapet, spesielt innen ishockey, der Wildcats jevnlig presterer på et høyt nivå. Whittemore Center Arena, hjemmebanen til ishockeylaget, er kjent for sin levende atmosfære og lidenskapelige fans.",
    "Fotball og basketball er også populære idretter ved UNH, og tiltrekker seg store folkemengder i løpet av sesongen. Samfunnet er stolt av lagene sine, og studentene er tett involvert i sportsarrangementene.",
    "New Hampshires tilnærming til college-sport er samfunnsfokusert, med sikte på å samle folk, feire suksesser og skape uforglemmelige øyeblikk i delstatens pittoreske omgivelser."
  ]
},
{
  "name": "New Jersey",
  "description": [
    "College-sport i New Jersey nyter en levende og konkurransedyktig atmosfære, med ulike universiteter som konkurrerer på et høyt nivå. Delstaten huser noen ledende programmer som er anerkjent både regionalt og nasjonalt.",
    "En av de mest prominente universitetslagene er Rutgers Scarlet Knights, en del av den prestisjetunge Big Ten-konferansen. Fotball- og basketballkampene deres trekker store folkemengder og genererer spenning over hele delstaten.",
    "New Jersey er også kjent for sine sterke lacrosse- og fotballprogrammer, som tiltrekker og utvikler unge talenter. Disse sportene spiller en betydelig rolle i delstatens college-sportskultur, med spennende konkurranser og dedikerte fans.",
    "Samfunnet i New Jersey omfavner sine college-sportslag, noe som resulterer i en støttende og lidenskapelig atmosfære under hvert arrangement. College-sport i New Jersey handler ikke bare om spill; det er en viktig del av delstatens kulturelle vev."
  ]
},
    
{
  "name": "New Mexico",
  "description": [
    "New Mexico, med sin unike kulturelle og geografiske bakgrunn, har et spesielt forhold til universitetssport. Staten er hjemsted for noen fremtredende universitetssportprogrammer som konkurrerer på nasjonalt nivå.",
    "University of New Mexico Lobos i Albuquerque er et sentralt stolthetspunkt. Deres basketballag trekker jevnlig store folkemengder til The Pit, en av de mest skremmende arenaene i landet.",
    "New Mexico State Aggies fra Las Cruces er også nasjonale utfordrere, spesielt innen basketball og amerikansk fotball, hvor de har intense rivaliseringer med Lobos, som kulminerer i Rio Grande Rivalry.",
    "Støttet av en rik historie og et lidenskapelig fanskare, gir universitetssport i New Mexico ikke bare underholdning, men fremmer også fellesskapsånd og statlig identitet. I denne ørkenstaten er sport en feiring av talent, utholdenhet og fellesskapsbånd."
  ]
},
{
  "name": "New York",
  "description": [
    "I den travle staten New York spiller universitetssport en dynamisk og allsidig rolle. Staten er hjemsted for mange universiteter, hver med sine egne unike sports tradisjoner.",
    "Syracuse University skiller seg ut med sitt basketballag, Orange, som ofte konkurrerer på høyeste nasjonale nivå. Carrier Dome-stadion, kjent for sin imponerende kapasitet og livlige atmosfære, er et ikon i universitetssportens verden.",
    "I tillegg spiller mindre universiteter og høyskoler også en betydelig rolle, med sterke programmer innen idretter som lacrosse, fotball og baseball. Disse mindre programmene nærer den konkurranseånden i staten, og skaper et rikt og mangfoldig sportslandskap.",
    "Fellesskapsengasjementet i universitetssport i New York er enormt, med fans som støtter lagene sine gjennom tykt og tynt. Fra travle byer til roligere landlige områder, forener sport New Yorkere og innprenter en følelse av stolthet og samhørighet."
  ]
},
{
  "name": "North Carolina",
  image: North_Carolina,
  "imageDescription": "Den ikoniske rivaliseringen: DUKE vs. UNC i aksjon",
  "description": [
    "North Carolina har en rik tradisjon og enestående lidenskap når det kommer til universitetssport, spesielt innen basketball. Rivaliseringen mellom University of North Carolina Tar Heels og Duke University Blue Devils er verdensberømt og garanterer spennende oppgjør.",
    "Tar Heels og Blue Devils dominerer ikke bare basketballsesongen, men bringer også en bølge av spenning og fellesskapsånd gjennom hele staten. Det er en sportskultur dypt forankret i North Carolinas identitet.",
    "I tillegg til basketball spiller amerikansk fotball også en fremtredende rolle, med sterke lag og lidenskapelige fanskarer. Og om våren blomstrer kjærligheten til baseball, med både universitets- og høyskolelag som konkurrerer på banene.",
    "Universitetssport i North Carolina går utover idrettsbaner og haller; det er en livsstil, en kilde til stolthet og en måte for samfunnene å komme sammen og feire sin stat på."
  ]
},
{
  "name": "North Dakota",
  "description": [
    "Universitetssport i North Dakota tilbyr en unik opplevelse, med samfunn tett involvert med sine universitetslag. Stolthet og samhørighet er tydelig synlig under sportsarrangementer, til tross for at staten har færre fremtredende universiteter enn andre amerikanske regioner.",
    "North Dakota State Universitys amerikanske fotballag, Bison, er kjent for sine suksesser i NCAA Division I Football Championship Subdivision, noe som gir et betydelig løft til statens sportskultur. Fans samles på Fargodome for å støtte laget sitt og skape en intens atmosfære.",
    "Ishockey spiller også en viktig rolle i North Dakota, med University of North Dakota Fighting Hawks som et ledende lag. Lidenskapen for denne vintersporten er tydelig i løpet av sesongen, med fans som strømmer til arenaen.",
    "Universitetssport i North Dakota, spesielt amerikansk fotball og ishockey, bringer samfunn sammen, skaper sterk lokal stolthet og bidrar til den levende sportskulturen i staten."
  ]
},

{
  "name": "Ohio",
  "description": [
    "College sports er en integrert del av kulturen i Ohio, med spesiell vekt på amerikansk fotball og basketball. The Ohio State University (OSU) og University of Cincinnati er to ledende institusjoner innen idrett.",
    "OSU's fotballag, Buckeyes, nyter nasjonal berømmelse og er en sann kraft i NCAA. Deres kamper på den massive Ohio Stadium tiltrekker seg mer enn 100 000 fans og skaper en enestående atmosfære under hjemmekampene. 'O-H-I-O' er en kjent rop gjennom hele staten.",
    "Basketball er også fremtredende, med både herre- og dame-lag som konkurrerer på høyt nivå. Ohio er hjemsted for flere universitetslag som regelmessig deltar i NCAA-turneringen, og legger til basketballfeberen.",
    "College sports i Ohio tilbyr mer enn bare konkurranse; de er en kilde til stolthet, tradisjon og fellesskapsånd, og forener mennesker fra alle samfunnslag."
  ]
},
{
  "name": "Oklahoma",
  "description": [
    "I Oklahoma har college sports en sentral plass i innbyggernes hjerter, med en dypt rotfestet lidenskap for amerikansk fotball. University of Oklahoma (OU) og Oklahoma State University (OSU) er statens sportsjuveler.",
    "OU's fotballag, Sooners, har en rik historie og utallige suksesser, og skaper en levende atmosfære rundt kampene sine. Gaylord Family Oklahoma Memorial Stadium er et festning for laget, der fansen høylytt roper 'Boomer Sooner.'",
    "OSU er ikke langt bak OU, med sterke prestasjoner både innen fotball og andre idretter, deres amerikanske fotballag, Cowboys, spiller en betydelig rolle. Dedikasjonen til fansen under 'Bedlam Series,' den årlige kampen mellom OU og OSU, er enorm.",
    "College sports i Oklahoma symboliserer kamp, utholdenhet og samfunnsfølelse i staten, og gjør det mye mer enn bare et spill."
  ]
},
{
  "name": "Oregon",
  "description": [
    "College sports i Oregon er en essensiell del av staten, med fokus på både amerikansk fotball og friidrett. University of Oregon (UO) i Eugene er sentralt i denne sportslige lidenskapen og har bygd et nasjonalt omdømme.",
    "UO's fotballag, Ducks, er kjent for sitt raske spill og unike uniformer, mens friidrettsprogrammet er blant de beste i landet. Den årlige Prefontaine Classic-arrangementet, avholdt på den berømte Hayward Field, fremhever Oregons dedikasjon til friidrett.",
    "Oregon State University (OSU) med sitt fotballag, Beavers, gir sterk konkurranse, spesielt under 'Civil War,' den årlige kampen med Ducks. Dette er mer enn bare et spill; det er en del av statstradisjonen som bringer samfunnene sammen.",
    "I tillegg til disse idrettene, nyter basketball også popularitet, og lidenskapelige fans gjør college sports i Oregon til en opplevelse du ikke vil gå glipp av. Det er en feiring av idrettslig talent, fellesskap og statens unike sportskultur."
  ]
},
{
  "name": "Pennsylvania",
  "description": [
    "Pennsylvania har en dyp respekt for college sports, med sterk vekt på amerikansk fotball, basketball og friidrett. Lag som Penn State Nittany Lions og Pittsburgh Panthers dominerer sportslandskapet og skaper spennende rivaliseringer.",
    "Beaver Stadium, hjemmet til Nittany Lions, er kjent for sin overveldende atmosfære og intense fanopplevelse, spesielt under 'White Out' -kampene. Disse øyeblikkene er høydepunktet i sportskalenderen og tiltrekker fans fra hele staten.",
    "På basketballbanen har Villanova Wildcats nylig oppnådd nasjonal suksess, med flere mesterskap som har gledet staten. Deres prestasjoner har satt Pennsylvania på kartet som en basketball stormakt.",
    "Friidrett spiller også en avgjørende rolle, med det prestisjefylte Penn Relays som trekker toppidrettsutøvere til Philadelphia hvert år. Denne rike sportskulturen styrker fellesskapsånden og bidrar til stoltheten i Pennsylvania."
  ]
},


{
  "name": "Rhode Island",
  "description": [
    "Tross sin lille størrelse har Rhode Island en livlig college sports scene, med basketball og seiling som spesielt populært. Staten har lag som spiller med hjerte og sjel, og skaper spennende øyeblikk.",
    "Lag som Rhode Island Rams og Providence Friars har en lojal fanbase og leverer fengende kamper. Dunkin' Donuts Center i Providence blir et travel arena under basketballkamper, med lokalbefolkningen som strømmer til for å støtte lagene sine.",
    "I tillegg til basketball er Rhode Island kjent for sine fremragende seilprogrammer, med den vakre kystlinjen som gir perfekte forhold for sporten. Universiteter som Brown og URI har sterke seillag som konkurrerer nasjonalt.",
    "Disse idrettene bidrar til Rhode Islands livlige kultur, der hvert spill er en mulighet til å feire den unike ånden til denne lille, men stolte staten."
  ]
},
{
  "name": "South Carolina",
  "description": [
    "College sports i South Carolina puster tradisjon og lidenskap, med amerikansk fotball og basketball i sentrum for oppmerksomheten. Den intense rivaliseringen mellom University of South Carolina Gamecocks og Clemson University Tigers dominerer sportslandskapet.",
    "Under det årlige fotballdramaet, 'The Palmetto Bowl,' våkner hele staten til liv, med tydelige linjer trukket mellom granat og oransje. Fansens energi og lidenskap er uten sidestykke, noe som gjør hvert spill til en uforglemmelig opplevelse.",
    "Basketball har også en fremtredende plass i hjertene til sportsfans i South Carolina, med lag som konkurrerer i toppen både i herre- og dameligaene. For eksempel har Gamecocks' kvinnelag i basketball oppnådd nasjonal suksess, og styrker dermed fellesskapets stolthet og engasjement.",
    "Sportskulturen i South Carolina går utover spillebanene; det er en livsstil, en kilde til stolthet og en uunnværlig del av statens identitet."
  ]
},
{
  "name": "South Dakota",
  "description": [
    "College sports i South Dakota tilbyr en unik og lidenskapelig opplevelse, med vekt på basketball, amerikansk fotball og friidrett. South Dakota State University Jackrabbits og University of South Dakota Coyotes er fremtredende aktører i sportslandskapet.",
    "Basketballkamper, spesielt under rivaliserende møter mellom Jackrabbits og Coyotes, tiltrekker entusiastiske folkemengder og skaper en elektrisk atmosfære. Staten jubler for både herre- og damelagene, med prestasjoner på banen som en kilde til stolthet for lokale samfunn.",
    "Amerikansk fotball nyter også stor popularitet, med spennende kamper og sterkt fan-engasjement. Atletiske programmer i South Dakota utmerker seg, med utøvere som konkurrerer nasjonalt og stolt representerer staten.",
    "College sports i South Dakota er mer enn bare spill; de er en viktig del av fellesskapet, styrker båndene mellom innbyggerne og bidrar til statens livlige kultur."
  ]
},
{
  "name": "Tennessee",
  "description": [
    "I Tennessee spiller college sports en avgjørende rolle i samfunnet, med en rik tradisjon innen amerikansk fotball, basketball og friidrett. University of Tennessee Volunteers og Vanderbilt University Commodores er ledende lag i statens sportslandskap.",
    "Volunteers, med sin ikoniske oransje farge, trekker enorme folkemengder til Neyland Stadium, en av de største amerikanske fotballstadionene i landet. Fansens lidenskap er uovertruffen, og 'Rocky Top' runger høyt under hver kamp.",
    "Basketball er også en stor attraksjon i Tennessee, med både herre- og damelag som mottar nasjonal anerkjennelse. Staten har en rik historie med å produsere toppidrettsutøvere og har bidratt til veksten av sporten på nasjonalt nivå.",
    "College sports i Tennessee er en viktig del av statens kultur og identitet, som bringer folk sammen, feirer tradisjoner og inspirerer neste generasjon av utøvere."
  ]
},

{
  "name": "Texas",
  image: Texas,
  "imageDescription": "Tradisjonell rivalisering: Texas Longhorns møter Texas A&M",
  "description": [
    "College-sport i Texas er synonymt med størrelse, lidenskap og rik tradisjon, med amerikansk fotball i førersetet. Lag som Texas Longhorns og Texas A&M Aggies har massive følgergrupper, og deres kamper er sesonghøydepunkter.",
    "Darrell K Royal–Texas Memorial Stadium i Austin og Kyle Field i College Station er sports-tempel hvor hver hjemmekamp blir til en spektakulær begivenhet. Rivaliseringen mellom ulike universitetslag er intens og bidrar til statens unike sportskultur.",
    "I tillegg til fotball er basketball og baseball også populære, med sterke programmer som konkurrerer nasjonalt. Støtten til kvinnesport vokser jevnt, spesielt innen basketball og friidrett.",
    "College-sport i Texas er mer enn bare en fritidssyssel; det er en livsstil, en kilde til stolthet og en bindende kraft som samler samfunnene."
  ]
},
{
  "name": "Utah",
  "description": [
    "I Utah har college-sport en betydelig plass i samfunnet, med basketball og amerikansk fotball som de mest populære idrettene. University of Utah Utes og Brigham Young University Cougars engasjerer seg i tøff konkurranse i ulike disipliner.",
    "'Holy War,' den årlige fotballduellen mellom Utes og Cougars, er en av de mest etterlengtede sportsbegivenhetene i staten. Den samler fans for en feiring av sportsånd, rivalisering og statens ære.",
    "Basketball har også en rik tradisjon i Utah, med spennende sesonger og minneverdige øyeblikk både i herre- og dameligaene. Lagene streber etter fremragende resultater, og støtten fra fansen er urokkelig.",
    "Påvirkningen av college-sport i Utah går utover spillefeltet. Den fremmer samfunnsspirit, skolestolthet og bidrar betydelig til statens kulturelle identitet."
  ]
},
{
  "name": "Vermont",
  "description": [
    "I Vermont, der naturen dominerer, og samfunnene er tett sammenvevd, spiller college-sport en unik rolle i å samle mennesker. Vintersport som ski og snowboard er fremtredende, gitt det fjellrike terrenget og det kalde klimaet.",
    "University of Vermont Catamounts er midtpunktet i college-sportsscenen, med lag som utmerker seg i ulike idretter, inkludert ishockey og basketball. Catamounts har en lojal følgerskare, og atmosfæren under hjemmekampene er intens.",
    "Basketball nyter stor popularitet, og Catamounts' kamper er et must for idrettsentusiaster. Laget har deltatt i NCAA-turneringen flere ganger, og har satt staten på kartet i college-basketballverdenen.",
    "College-sport i Vermont er mer enn bare et spill; det er en måte å samle samfunnene på, utvikle talenter og feire statens unike sportskultur."
  ]
},
{
  "name": "Virginia",
  "description": [
    "I Virginia er college-sport en integrert del av den lokale kulturen, med sterk fokus på amerikansk fotball, basketball og den økende populariteten til fotball. Virginia Cavaliers og Virginia Tech Hokies er ledende universitetslag som bringer lidenskap og rivalisering til banen.",
    "Amerikansk fotball trekker tilskuermasser til stadionene, der atmosfæren på kampdager er elektrisk. Investeringen i basketballprogrammer har også båret frukter, med lag som konkurrerer nasjonalt og har en dedikert følgerskare.",
    "Fotball vinner terreng i Virginia, med både herre- og damelag som utvikler seg til konkurransedyktige krefter. Universitetene investerer i fasiliteter og coaching, mens lokale akademier produserer unge talenter.",
    "Denne blandingen av tradisjonelle og nye idretter beriker college-sportsscenen i Virginia, skaper et levende sportsfellesskap og gir utøvere og fans uforglemmelige opplevelser samt en følelse av stolthet og enhet."
  ]
},

{
  "name": "Washington",
  "description": [
    "College-sport i Washington blomstrer med en blanding av tradisjon og entusiasme, der amerikansk fotball, basketball og roing står i sentrum. University of Washington Huskies og Washington State University Cougars er pionerer innen delstatens sportskultur.",
    "Husky Stadium i Seattle og Martin Stadium i Pullman summer av spenning under amerikansk fotballkamper, der fans fra hele området samles for å støtte lagene sine. 'Apple Cup,' det årlige oppgjøret mellom Huskies og Cougars, er et høydepunkt i rivaliseringen.",
    "Basketball spiller også en betydelig rolle, der både herre- og damelag konkurrerer på høyt nivå. Huskies har en sterk arv i denne sporten og tiltrekker seg lidenskapelige tilhengere.",
    "Roing er en annen bemerkelsesverdig disiplin, der Huskies har vunnet utallige nasjonale mesterskap. College-sport i Washington gjenspeiler mangfoldet og lidenskapen i delstaten, der sportsånd, konkurranse og kameratskap verdsettes høyt."
  ]
},
{
  "name": "West Virginia",
  "description": [
    "College-sport er hjertet og pulsen i West Virginia, med sterk vekt på amerikansk fotball og basketball. West Virginia University Mountaineers er stolte representanter for delstaten, og deres kamper samler lokalsamfunnet.",
    "Milan Puskar Stadium i Morgantown våkner til live under Mountaineers' hjemmekamper, der fansen kler seg i de ikoniske gull- og blåfargene og jubler høyt. 'Backyard Brawl,' en historisk rivalisering med University of Pittsburgh, tilfører ekstra spenning til sesongen.",
    "Basketball er en annen lidenskap, der spennende kamper holder fansen på kanten av setet. Mountaineers har både herre- og damelag som konkurrerer på nasjonalt nivå.",
    "College-sport i West Virginia er en kilde til stolthet og enhet, og gjenspeiler det sterke samfunnsfellesskapet og tradisjonene i delstaten."
  ]
},
{
  "name": "Wisconsin",
  "description": [
    "College-sport er dypt forankret i kulturen i West Wisconsin, der amerikansk fotball, basketball og ishockey er fremtredende disipliner. University of Wisconsin Badgers er drivkraften bak sportskulturen i delstaten.",
    "Camp Randall Stadium i Madison summer av spenning under amerikansk fotballkamper, der fansen samles for å synge 'Jump Around' og heie på laget sitt. Rivaliseringen med lag som University of Michigan Wolverines er legendarisk og fører til intense kamper.",
    "Basketball har også en rik tradisjon, der Badgers jevnlig konkurrerer om de øverste plassene i NCAA-turneringer. Ishockey har en spesiell plass i delstatens hjerte, der spennende kamper lyser opp vinterkveldene.",
    "College-sport i West Wisconsin går utover spillefeltet; det er en kilde til stolthet, kameratskap og styrker båndene mellom samfunnene i delstaten."
  ]
},
{
  "name": "Wyoming",
  "description": [
    "College-sport i Wyoming har en unik karakter, med sterk vekt på utendørsaktiviteter som ski, snowboard og fjellklatring. University of Wyoming Cowboys og Cowgirls er standardbærere for denne eventyrlige sportskulturen.",
    "War Memorial Stadium i Laramie våkner til live under amerikansk fotballkamper, der fansen samles for å vise sin støtte til Cowboys. De fantastiske fjellene fungerer som en bakgrunn for disse sportsarrangementene.",
    "Universitetet har også en sterk tradisjon innen ski og snowboard, der utøvere dominerer nasjonale og internasjonale konkurranser. Wyomings store villmark fungerer som lekeplass for eventyrlystne og friluftsinteresserte.",
    "College-sport i Wyoming feirer naturens skjønnhet og delstatens eventyrlystne ånd, der sportsånd, lidenskap og kjærlighet for utendørslivet forenes."
  ]
}


];

export default states;