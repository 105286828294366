/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import React, { useState } from 'react'; // Step 1: Import useState
import { Link } from 'react-router-dom';


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Football1.jpg";
import Image2 from "assets/images/Sports_Images/Football2.webp";
import Image3 from "assets/images/Sports_Images/Football3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";
import "../../../../H1asH3.css"


// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Becas de Fútbol Americano en los Estados Unidos: Cómo Asegurar un Lugar en un Equipo Universitario', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capítulo 1: La Historia y Desarrollo del Fútbol Americano', isSubheader: false },
    { id: '1.1', title: '1.1 Los Primeros Años', isSubheader: true },
    { id: '1.2', title: '1.2 Crecimiento y Profesionalización', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capítulo 2: Fútbol Americano Universitario', isSubheader: false },
    { id: '2.1', title: '2.1 Ligas y Conferencias Principales', isSubheader: true },
    { id: '2.2', title: '2.2 Desarrollo de Jugadores', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capítulo 3: De la Universidad a la NFL', isSubheader: false },
    { id: '3.1', title: '3.1 El Camino hacia la NFL', isSubheader: true },
    { id: '3.2', title: '3.2 Otras Oportunidades Profesionales', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capítulo 4: La Influencia del Fútbol Americano en la Cultura Estadounidense', isSubheader: false },
    { id: '4.1', title: '4.1 Impacto Cultural', isSubheader: true },
    { id: '4.2', title: '4.2 Modelos a Seguir e Inspiración', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capítulo 5: Becas Deportivas y el Futuro de los Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 La Importancia de las Becas', isSubheader: true },
    { id: '5.2', title: '5.2 Cómo Puede Ayudar Sport Scholarships America', isSubheader: true },
  ];
  
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contenidos
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Ocultar</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>


























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Becas de Fútbol Americano en los Estados Unidos: Cómo Asegurar un Lugar en un Equipo Universitario
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Vista aérea de un concurrido partido de fútbol americano universitario en la Universidad de Indiana, con jugadores en acción en el campo y fanáticos en las gradas."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
       }}>
        Partido de fútbol americano de la Universidad de Indiana desde las gradas.
      </figcaption>
    </figure>
    El Fútbol Americano es un deporte que se originó en los Estados Unidos a finales del siglo XIX. Es un deporte de equipo en el que dos equipos de once jugadores intentan avanzar un balón ovalado cruzando la línea de gol del oponente o chutándolo entre los postes de gol. El juego se caracteriza por el contacto físico, las tácticas, la estrategia y el espectáculo.
    <br/>
    <br/>
    El Fútbol Americano no es solo un deporte; también es una cultura. Es una parte esencial de la identidad, los valores y las tradiciones estadounidenses. Es una fuente de orgullo, pasión e inspiración para millones de personas. También es una industria que genera miles de millones de dólares y crea miles de empleos.
    <br/>
    <br/>
    Pero antes de poder triunfar como jugador profesional en la NFL, necesitas demostrar tu talento a nivel universitario. El fútbol americano universitario es una parte crucial del desarrollo de los jugadores, ofreciendo a los jóvenes atletas la oportunidad de mejorar sus habilidades, recibir una educación y construir una red de contactos.
    <br/>
    <br/>
    Sin embargo, el fútbol americano universitario no está al alcance de todos fácilmente. Hay miles de equipos y jugadores compitiendo por un número limitado de lugares y becas. Además, hay muchas reglas y requisitos que debes cumplir para calificar para un equipo universitario.
    <br/>
    <br/>
    Ahí es donde Sportbeursamerika.nl puede ayudarte. Me especializo en conectar jóvenes talentos con equipos universitarios que buscan refuerzos. Puedo ayudarte a encontrar una beca deportiva, arreglar tu visa, prepararte para tus exámenes de admisión y negociar con entrenadores. Con mi ayuda, puedes cumplir tu sueño de estudiar y jugar en América.
    <br/>
    <br/>
    ¿Quieres saber cómo? ¡Sigue leyendo!
  </MKTypography>
</MKBox>




{/* Capítulos principales y subcapítulos con texto de marcador de posición */}
<MKBox id="Chapter 1: The History and Development of American Football" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 1: La Historia y Desarrollo del Fútbol Americano
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El Fútbol Americano es un deporte que se originó en los Estados Unidos a finales del siglo XIX. Es descendiente del rugby y el fútbol, dos deportes populares en Europa. En este capítulo, te contaremos más sobre los primeros años y el crecimiento y profesionalización del Fútbol Americano.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Los Primeros Años
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      El origen del Fútbol Americano a menudo se atribuye a un partido entre dos equipos universitarios en 1869: Rutgers y Princeton. Sin embargo, este partido se parecía más a una forma de fútbol que al Fútbol Americano moderno. Había 25 jugadores por equipo, el balón no podía ser tocado con las manos y no había postes de gol.
      <br/>
      <br/>
      El primer partido que se pareció al Fútbol Americano moderno tuvo lugar en 1874 entre Harvard y McGill. Este juego fue una combinación de rugby y fútbol, permitiendo llevar el balón con las manos y contando con postes de gol. Harvard ganó el juego 3-0.
      <br/>
      <br/>
      El papel de las universidades en la formación de los primeros equipos y ligas fue crucial. Fueron los pioneros que definieron las reglas y el estilo del juego. En 1873, se estableció la primera asociación de fútbol intercolegial, que incluía a Yale, Columbia, Princeton y Harvard. En 1880, Walter Camp, un jugador y entrenador de Yale, fue considerado el "Padre del Fútbol Americano" porque introdujo innovaciones significativas, como reducir el número de jugadores a 11, introducir la línea de scrimmage y el sistema de downs.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crecimiento y Profesionalización
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La evolución del Fútbol Americano en un fenómeno nacional comenzó a finales del siglo XIX y principios del siglo XX. El juego ganó popularidad entre estudiantes, espectadores y medios de comunicación. También se volvió cada vez más violento y peligroso, lo que llevó a numerosas lesiones e incluso muertes. En 1905, el presidente Theodore Roosevelt amenazó con prohibir el juego si no se implementaban reformas. Esto condujo al establecimiento de la Asociación Atlética Colegiada Nacional (NCAA) en 1906, una organización responsable de regular y organizar los deportes universitarios.
      <br/>
      <br/>
      La influencia del fútbol americano profesional en el juego universitario fue significativa también. La primera liga de fútbol americano profesional se fundó en 1892, pero tuvo verdadero éxito en 1920 con la formación de la Asociación de Fútbol Americano Profesional (APFA), luego renombrada como la Liga Nacional de Fútbol (NFL). La NFL atrajo a muchos jugadores talentosos lejos de las universidades, obligándolas a elevar su nivel para seguir siendo competitivas. La NFL también se convirtió en una importante fuente de ingresos y exposición para las universidades, alquilando a menudo sus estadios a equipos profesionales.
      <br/>
      <br/>
      En este capítulo, te hemos contado más sobre la historia y el desarrollo del Fútbol Americano. En el siguiente capítulo, proporcionaremos más información sobre el Fútbol Americano a nivel universitario.
    </MKTypography>
  </MKBox>
</MKBox>









                                              





                                  







{/* Capítulo 2 con texto de marcador de posición */}
<MKBox id="Chapter 2" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Capítulo 2: Fútbol Americano Universitario
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        El fútbol americano universitario es uno de los deportes más populares y competitivos en los Estados Unidos. Cada año, más de mil equipos y cientos de miles de jugadores compiten por el título nacional, los campeonatos regionales y los prestigiosos juegos de tazones. En este capítulo, te contaremos más sobre las principales ligas y conferencias en el fútbol americano universitario, y el desarrollo de los jugadores a nivel universitario.
    </MKTypography>

    <MKBox id="2.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.1 Ligas y Conferencias Principales
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Una visión general de las divisiones de la NCAA y las principales conferencias en el fútbol americano universitario. La NCAA es la organización responsable de regular y organizar los deportes universitarios en los Estados Unidos. La NCAA divide a los equipos en tres divisiones: División I, División II y División III. La División I es la división más alta y consta de dos subdivisiones: la Subdivisión de Bowl de Fútbol (FBS) y la Subdivisión de Campeonato de Fútbol (FCS). Los equipos de FBS son los más prestigiosos y lucrativos, compitiendo por un lugar en el College Football Playoff, un sistema que ha determinado el campeón nacional desde 2014. Los equipos de FCS son menos conocidos y ricos, pero tienen su propio sistema de playoffs para determinar su campeón.
            <br/>
            <br/>
            Dentro de cada división y subdivisión, hay varias conferencias, que son grupos de equipos que están conectados geográfica o históricamente. Cada conferencia tiene su propio calendario, reglas y campeonato. Las principales conferencias en FBS son: Atlantic Coast Conference (ACC), Big Ten Conference, Big 12 Conference, Pac-12 Conference, Southeastern Conference (SEC) y American Athletic Conference (AAC). Las principales conferencias en FCS son: Big Sky Conference, Colonial Athletic Association (CAA), Missouri Valley Football Conference (MVFC) y Southern Conference (SoCon).
            <br/>
            <br/>
            Los programas universitarios más prestigiosos y su impacto en el deporte. Algunas universidades tienen una larga y exitosa historia en el fútbol americano universitario, que se remonta a los primeros años del deporte. Han producido muchos títulos nacionales, juegos de tazones, premios individuales y jugadores profesionales. También tienen muchos fanáticos, atención de los medios y recursos financieros. Algunos ejemplos de estas universidades son: Alabama, Notre Dame, Ohio State, Oklahoma, USC, Michigan, Texas, Clemson, Florida State y LSU.
        </MKTypography>
    </MKBox>
    <MKBox id="2.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.2 Desarrollo de Jugadores
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Cómo los equipos universitarios contribuyen al desarrollo técnico y físico de los jugadores. Los equipos universitarios ofrecen a los jóvenes jugadores la oportunidad de mejorar sus habilidades bajo la guía de entrenadores y miembros del personal experimentados. También proporcionan acceso a instalaciones de alta calidad, equipos y atención médica. Además, exponen a los jugadores a un alto nivel de competencia, donde se enfrentan a otros jugadores talentosos de todo el país.
            <br/>
            <br/>
            Ejemplos de jugadores famosos que perfeccionaron sus habilidades a nivel universitario. Muchos jugadores profesionales comenzaron sus carreras a nivel universitario, donde destacaron por su rendimiento y potencial. Algunos jugadores incluso han ganado premios como el Trofeo Heisman, el premio individual más prestigioso en el fútbol americano universitario. Ejemplos de estos jugadores incluyen: Tom Brady (Michigan), Peyton Manning (Tennessee), Joe Montana (Notre Dame), Barry Sanders (Oklahoma State), Jerry Rice (Mississippi Valley State) y Tim Tebow (Florida).
            <br/>
            <br/>
            En este capítulo, te hemos contado más sobre el fútbol americano universitario. En el próximo capítulo, te contaremos más sobre el camino hacia la NFL y otras oportunidades profesionales.
        </MKTypography>
    </MKBox>
</MKBox>












                                              






                                  








{/* Capítulo 3 con texto de marcador de posición */}
<MKBox id="Chapter 3" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Capítulo 3: De la Universidad a la NFL
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        El fútbol americano universitario es un trampolín hacia la National Football League (NFL), la liga profesional más alta del mundo. Cada año, cientos de jugadores son seleccionados por equipos de la NFL en el Draft de la NFL, un evento donde los equipos eligen a un jugador de una lista de jugadores universitarios por turnos. En este capítulo, te contaremos más sobre el camino hacia la NFL y otras oportunidades profesionales.
    </MKTypography>

    <MKBox id="3.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.1 El Camino hacia la NFL
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Cómo el fútbol americano universitario funciona como un trampolín hacia la National Football League (NFL). El fútbol americano universitario es la principal fuente de talento para la NFL. La mayoría de los jugadores en la NFL primero han jugado a nivel universitario. El fútbol americano universitario ofrece a los jugadores la oportunidad de demostrar sus habilidades contra otros atletas de alto nivel, desarrollar sus habilidades, mejorar sus estadísticas y aumentar su valor en el draft. El fútbol americano universitario también brinda a los jugadores exposición y reconocimiento por parte de cazatalentos, entrenadores y medios de comunicación.
            <br/>
            <br/>
            Alrededor de 250 jugadores son seleccionados cada año en el Draft de la NFL, que generalmente se lleva a cabo en abril. El draft consiste en siete rondas, cada una con 32 selecciones (una para cada equipo). El orden de las selecciones se determina por el resultado de la temporada anterior, con el peor equipo eligiendo primero y el mejor equipo al final. El draft es un evento emocionante e impredecible, con algunos jugadores siendo elegidos más alto o más bajo de lo esperado, y algunos no siendo elegidos en absoluto. Ejemplos de jugadores famosos seleccionados en el Draft de la NFL incluyen: John Elway (primer selección en 1983), Troy Aikman (primer selección en 1989), Peyton Manning (primer selección en 1998), Tom Brady (selección 199 en 2000), Aaron Rodgers (selección 24 en 2005) y Patrick Mahomes (selección 10 en 2017).
        </MKTypography>
    </MKBox>
    <MKBox id="3.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.2 Otras Oportunidades Profesionales
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Vías alternativas hacia una carrera profesional en el fútbol americano fuera de la NFL. No todos los jugadores que juegan a nivel universitario llegan a la NFL. Algunos jugadores no son seleccionados en el draft, o son seleccionados pero no logran asegurar un lugar en un equipo. Algunos jugadores se lesionan o pierden su motivación o pasión. Pero esto no significa que tengan que renunciar a su sueño de jugar profesionalmente. Hay otras oportunidades profesionales fuera de la NFL, como:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    La Liga Canadiense de Fútbol (CFL): Esta es una liga profesional que consta de nueve equipos que juegan en Canadá. La CFL tiene algunas diferencias con la NFL, como un campo más grande, más jugadores por equipo y menos downs por unidad. La CFL atrae a muchos jugadores que no pueden jugar en la NFL, pero aún quieren jugar a un alto nivel.
                </li>
                <li>
                    La XFL: Esta es una nueva liga profesional establecida en 2020 como alternativa a la NFL. La XFL consta de ocho equipos que juegan en los Estados Unidos. La XFL tiene como objetivo proporcionar un juego más rápido, emocionante e innovador que la NFL. La XFL también ofrece más oportunidades para jugadores que han sido pasados por alto o ignorados por la NFL.
                </li>
                <li>
                    La Liga Europea de Fútbol (ELF): Esta es una nueva liga profesional establecida en 2021 en un intento de promover y desarrollar el fútbol americano en Europa. La ELF consta de ocho equipos que juegan en Alemania, Polonia, España y Francia. La ELF tiene como objetivo proporcionar una plataforma para que los jugadores europeos muestren su talento y crezcan como atletas.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            En este capítulo, te hemos contado más sobre el camino hacia la NFL y otras oportunidades profesionales. En el próximo capítulo, te contaremos más sobre la influencia del fútbol americano en la cultura estadounidense.
        </MKTypography>
    </MKBox>
</MKBox>



                                              






                                  





{/* Capítulo 4 con texto de marcador de posición */}
<MKBox id="Chapter 4" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Capítulo 4: La Influencia del Fútbol Americano en la Cultura Estadounidense
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        El fútbol americano no es solo un deporte, sino también una cultura. Es una parte significativa de la identidad, valores y tradiciones estadounidenses. Es una fuente de orgullo, pasión e inspiración para millones de personas. También es una industria que genera miles de millones de dólares y crea miles de empleos. En este capítulo, te contaremos más sobre el impacto cultural del fútbol americano y los modelos a seguir e inspiración que proporciona.
    </MKTypography>

    <MKBox id="4.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.1 Impacto Cultural
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            La importancia del fútbol americano en la sociedad y cultura estadounidenses. El fútbol americano es uno de los deportes más populares e influyentes en los Estados Unidos. Atrae a millones de fanáticos, espectadores y medios de comunicación cada año. Es parte de la vida diaria, educación, política, economía y entretenimiento de muchos estadounidenses. También es un símbolo de unidad nacional, diversidad y orgullo.
            <br/>
            <br/>
            Eventos importantes y su impacto en la identidad nacional, como el Super Bowl. El Super Bowl es el campeonato anual de la NFL, generalmente celebrado en febrero. Es el evento deportivo más grande y más visto en el mundo, con más de 100 millones de espectadores solo en los Estados Unidos. El Super Bowl es más que un evento deportivo; es un fenómeno cultural. Es una oportunidad para que la familia, amigos y colegas se reúnan, festejen, apuesten y animen. También es un escenario para la música, publicidad, arte y política. El Super Bowl a menudo se ve como un reflejo de la cultura, valores y tendencias estadounidenses.
        </MKTypography>
    </MKBox>
    <MKBox id="4.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.2 Modelos a Seguir e Inspiración
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Los jugadores de fútbol americano a menudo son modelos a seguir y fuentes de inspiración para jóvenes atletas que persiguen su sueño de jugar a nivel universitario o profesional. Algunos jugadores tienen historias extraordinarias de adversidad, perseverancia y éxito que motivan e inspiran a otros. Algunos jugadores también impactan positivamente en su comunidad, fanáticos y país a través de sus acciones, palabras y hechos. Ejemplos de tales jugadores incluyen: Colin Kaepernick (quien se arrodilló durante el himno nacional para crear conciencia sobre la injusticia racial), Russell Wilson (quien llevó a su equipo al Super Bowl como un underdog), JJ Watt (quien recaudó millones de dólares para las víctimas del huracán Harvey), Drew Brees (quien ayudó a su ciudad Nueva Orleans a recuperarse después del huracán Katrina) y Tom Brady (quien sigue jugando a un nivel superior a los 43 años).
            <br/>
            <br/>
            En este capítulo, te hemos contado más sobre la influencia del fútbol americano en la cultura estadounidense. En el próximo capítulo, te contaremos más sobre las becas deportivas y el futuro del fútbol americano.
        </MKTypography>
    </MKBox>
</MKBox>











                                              






                                  


<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Becas Deportivas y el Futuro de los Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Las becas deportivas ofrecen numerosas oportunidades para que los jóvenes atletas muestren su talento y desarrollen sus habilidades. Una de las oportunidades más significativas es obtener una beca deportiva para estudiar y competir a nivel universitario. En este capítulo, discutiremos la importancia de las becas deportivas y cómo Sport Scholarships America puede ayudarte a hacer realidad tu sueño.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 La Importancia de las Becas
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Una beca deportiva es un apoyo financiero proporcionado por una universidad a un estudiante-atleta para combinar estudios y deporte. Puede cubrir los costos de matrícula, libros, alojamiento, comidas y otros gastos. Además, puede ofrecer beneficios como tutoría académica, atención médica, instalaciones de entrenamiento y equipo.
      <br />
      <br />
      Obtener una beca deportiva es competitivo, con muchos factores que influyen en el proceso de adjudicación, incluyendo logros académicos, rendimiento deportivo, personalidad, motivación, liderazgo y adaptabilidad. Por lo tanto, es importante prepararse bien y destacarse entre la competencia.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Cómo Puede Ayudar Sport Scholarships America
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo de Sport Scholarships America, que muestra equipamiento deportivo y una gorra de graduación, representando el enfoque en becas deportivas para estudiantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America es una organización especializada en guiar a jóvenes talentos deportivos hacia becas deportivas. Con expertos apasionados y experimentados, Sport Scholarships America apoya a los estudiantes-atletas en cada paso del camino, desde la orientación inicial hasta la colocación final. Sus servicios incluyen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Una entrevista personal para discutir los deseos, posibilidades y expectativas del estudiante-atleta.</li>
        <li>Una evaluación profesional del nivel académico y deportivo del estudiante-atleta.</li>
        <li>Una extensa red de contactos con entrenadores, universidades y colegios.</li>
        <li>Promoción efectiva del perfil y videos del estudiante-atleta ante entrenadores potenciales.</li>
        <li>Asesoramiento experto sobre las mejores opciones basadas en las preferencias, metas y presupuesto del estudiante-atleta.</li>
        <li>Negociación de las mejores ofertas de becas en nombre del estudiante-atleta.</li>
        <li>Asistencia en la solicitud de documentos necesarios, como visa, expediente académico, diploma y pruebas de admisión.</li>
        <li>Preparación para la vida del estudiante-atleta durante su beca, incluyendo cultura, idioma, reglas y consejos.</li>
        <li>Seguimiento y atención posterior durante el período de la beca.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      ¿Quieres explorar oportunidades de becas y alcanzar tus metas deportivas y académicas? <Link to="/es/Servicios-Productos">Consulta la gama completa</Link> de servicios ofrecidos por Sport Scholarships America y programa una consulta hoy mismo.
    </MKTypography>
  </MKBox>
</MKBox>




          
        









         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;