/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React components
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Jeugd.png";
import post2 from "assets/images/TijdUS.png";
import post3 from "assets/images/Deal.png";

function Post2() {
  return (
    <MKBox component="section" py={0}>
      <Container>
        <Grid container item xs={12} lg={4}>
          <MKTypography variant="h3" mb={6}>
          Les min historie          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
        <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <TransparentBlogCard
              image={post1}
              title="Del 1: Å Vokse Opp i Nederland"
              description="I de fortryllende omgivelsene til Nijmegen begynte mitt livslange kjærlighetsforhold til fotball. Som et barn fra ..."
              action={{
                type: "internal",
                route: "/no/Om-meg/Vokse-opp",
                color: "info",
                label: "Les Mer",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <TransparentBlogCard
              image={post2}
              title="Del 2: Min Tid i Amerika"
              description="Min tid i Amerika var som en pustende reise, fylt med uforglemmelige opplevelser og personlig vekst. Takket ..."
              action={{
                type: "internal",
                route: "/no/Om-meg/Min-tid-i-Amerika",
                color: "info",
                label: "Les Mer",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <TransparentBlogCard
              image={post3}
              title="Del 3: Min Tilbakevending Til Nederland"
              description="Ved tilbakekomsten til Nederland opplevde jeg en blanding av nostalgi og besluttsomhet. Min dype ..."
              action={{
                type: "internal",
                route: "/no/Om-meg/Min-tilbakevending-til-Nederland",
                color: "info",
                label: "Les Mer",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Post2;

