import { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import SimplePricingCard from "examples/Cards/PricingCards/SimplePricingCard";
import SimplePricingCardRibbon from "examples/Cards/PricingCards/SimplePricingCardRibbon";
import { PopupButton } from "react-calendly";
import { Link } from 'react-scroll';
import TextField from "@mui/material/TextField";  // Lägg till den här raden
import Box from '@mui/material/Box'; // Se till att importera Box
import emailjs from 'emailjs-com';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Link as RouterLink } from 'react-router-dom';

function Tjänster() {
  const [activeTab, setActiveTab] = useState(0);
  const [tabType, setTabType] = useState("månadsvis");
  const [showModal, setShowModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [email, setEmail] = useState("");
  const [isAgreed, setIsAgreed] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  const handleTabType = ({ currentTarget }, newValue) => {
    setActiveTab(newValue);
    setTabType(currentTarget.id);
  };

  const handleEmailSubmit = async () => {
    if (email && isAgreed) {
      const emailParams = {
        from_email: email,
        // andra parametrar om det behövs
      };

      emailjs.send('service_sj82src', 'template_2eyxhno', emailParams, 'xOBnOFlYt964jvT3q')
        .then((response) => {
          console.log('E-post skickad framgångsrikt', response.status, response.text);
          alert('Länk begärd, vänligen kontrollera din inkorg.');
          setShowEmailModal(false);
          setEmail("");
          setIsAgreed(false);
        }, (error) => {
          console.log('Misslyckades med att skicka e-post', error);
          alert('Begäran misslyckades, försök igen.');
        });
    } else if (!isAgreed) {
      alert('Vänligen acceptera integritetspolicyn.');
    } else {
      alert('Ange en e-postadress.');
    }
  };

  return (
    <section id="sektionTjänster">
      <Container sx={{ pb: { xs: 4, lg: 4 }, pt: 12 }}>
          {/* Modal för e-postinmatning */}
          <Modal
        open={showEmailModal}
        onClose={() => setShowEmailModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: { xs: '90%', sm: '70%', md: '50%', lg: '40%' },
            maxWidth: '500px',
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <TextField
            label="E-postadress"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ mb: 2 }}
          />
          {/* Kryssruta för godkännande av integritetspolicyn */}
          <FormControlLabel
  control={<Checkbox checked={isAgreed} onChange={(e) => setIsAgreed(e.target.checked)} />}
  label={
    <span>
       Jag har läst{' '}
      <RouterLink 
        to="/Privacy-Policy" 
        component="a" 
        target="_blank" 
        style={{ textDecoration: 'none' }}>
         integritetspolicyn
      </RouterLink> och godkänner den.
    </span>
  }
  sx={{ my: 2 }}
/>


          <MKButton variant="gradient" color="dark" onClick={handleEmailSubmit}>
            Skicka
          </MKButton>
        </Box>
      </Modal>


      <Grid container item xs={12} justifyContent="center" md={8} mx="auto" textAlign="center">
      <Grid item xs={12}> {/* Full bredd för rubriken */}
    <MKTypography variant="h3" color="text">Tjänster</MKTypography>
  </Grid>
  <Grid item xs={12}> {/* Full bredd för brödtexten */}
    <MKTypography variant="body2" color="text">
    Alla tjänster som erbjuds av Sport Scholarships America
</MKTypography>
  </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={10}
          lg={8}
          justifyContent="center"
          textAlign="center"
          mx="auto"
          mt={6}
        >
          <AppBar position="static">
          <Tabs value={activeTab} onChange={handleTabType}>
  <Tab
    id="månadsvis"
    label={
      <Link 
        to="sektionTjänster" 
        spy={true}
        smooth={true}
        duration={500}
        offset={-70}
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <MKBox py={0.5} px={2} color="inherit">
          Tjänster
        </MKBox>
      </Link>
    }
  />
  <Tab
    id="årlig"
    label={
      <Link 
        to="sektionProdukter" 
        spy={true}
        smooth={true}
        duration={500}
        offset={-70}
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <MKBox py={0.5} px={2} color="inherit">
          Produkter
        </MKBox>
      </Link>
    }
  />
</Tabs>
          </AppBar>
        </Grid>
        <Grid container spacing={3} mt={6}>
        <Modal open={showModal} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
          <Slide direction="down" in={showModal} timeout={500}>
            <MKBox
              position="relative"
              width="500px"
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              bgColor="white"
              shadow="xl"
            >
              <MKBox display="flex" alginItems="center" justifyContent="space-between" p={2}>
                <MKTypography variant="h5">
Välj ett inledande samtal
</MKTypography>
                <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox p={2}>
                <MKTypography variant="body2" color="secondary" fontWeight="regular">
                Kära kund,
                <br />
                  <br />

                  Jag är glad att du är intresserad av min standardtjänst! För att säkerställa att min tjänst passar perfekt för dina specifika behov rekommenderar jag starkt att du först bokar ett kostnadsfritt introduktionssamtal.
                  <br />
                  <br />
                  Under detta samtal kan vi diskutera din situation i detalj och tillsammans bestämma vilken strategi som bäst passar dig. Detta skapar en mer anpassad upplevelse och hjälper mig att optimera tjänsten för din unika situation.


                </MKTypography>
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox display="flex" justifyContent="space-between" p={1.5}>
                <MKButton variant="gradient" color="dark" onClick={toggleModal}>
                  stäng
                </MKButton>
                
                <PopupButton
    url="https://calendly.com/kay-duit"
    rootElement={document.getElementById("root")}
    text="BOKA MÖTE"
    className="anpassad-calendly-knapp"
  />
              </MKBox>
            </MKBox>
          </Slide>
        </Modal>
        <Grid item xs={12} sm={6} lg={3}>
  <SimplePricingCard
    color="dark"
    title="Informationsguide"
    description="Fullständig informationsguide i PDF"
    price={{ value: "Gratis", type: "" }}
    action={{
      type: "downloadPDF",
      label: "Ladda ner PDF",
      onClick: () => setShowEmailModal(true) // Funktion för att visa e-postinmatningsmodalen
    }}
    specifications={["Fullständig informationsguide i PDF"]}
  />
</Grid>
<Grid item xs={12} sm={6} lg={3}>
  <SimplePricingCardRibbon
    color="dark"
    title="Inledande Samtal"
    description="60-minuters onlinekonsultation"
    price={{ value: " € 14.99*", type: "" }}
    action={{
      type: "external", // Ändra till extern för Calendly
      route: "https://calendly.com/kay-duit", // Din Calendly-länk
      label: "BOKA MÖTE"
    }}
    specifications={[
      "Mål och ambitioner",
      "Bedömning av idrottsprestationer",
      "Plan för akademisk integration",
      "Individuell karriärstrategi",
      "Analys av chanser till framgång i USA"
    ]}
  />
</Grid>

          <Grid item xs={12} sm={6} lg={3}>
  <SimplePricingCard
    color="dark"
    title="Standard"
    description="Din väg till USA"
    price={{ value: "På begäran", type: "" }}
    action={{
      type: "modal",
      label: "Begär",
      onClick: toggleModal
    }}
    specifications={[
      "Anpassad plan",
      "Stöd för att skapa en idrottsportfölj",
      "Stöd för akademisk förberedelse",
      "Marknadsföring till universitet",
      "Förhandling om idrottsstipendier",
      "Råd om val av universitet och stipendier",
      "Stöd för visumansökan",
      "Stöd för registrering",
      "Praktiskt stöd",
    ]}
  />
</Grid>
<Grid item xs={12} sm={6} lg={3}>
  <SimplePricingCard
    color="dark"
    title="Premium"
    description="Din väg till USA"
    price={{ value: "På begäran", type: "" }}
    action={{
      type: "modal",
      label: "Begär",
      onClick: toggleModal
    }}
    specifications={[
      "Anpassad plan",
      "Stöd för att skapa en idrottsportfölj",
      "Stöd för akademisk förberedelse",
      "Marknadsföring till universitet",
      "Förhandling om idrottsstipendier",
      "Råd om val av universitet och stipendier",
      "Stöd för visumansökan",
      "Stöd för registrering",
      "Praktiskt stöd",
      "Studieböcker för SAT/ACT",
      "Bi-veckovis Zoom-samtal",
    ]}
  />
</Grid>
        </Grid>
      </Container>
      <Container>
    <Grid item xs={12} style={{ marginBottom: '80px' }}>
      <MKTypography variant="body2" color="text">
        {/* Din text går här */}
        *Tidigare erbjöd jag det inledande samtalet gratis. Tyvärr ledde detta till många möten med icke-seriösa individer, vilket var tidskrävande. För att säkerställa kvaliteten på mina tjänster och för att säkerställa att samtalen är värdefulla för båda parter begär jag nu en liten avgift på €14.99. Denna summa hjälper till att filtrera seriösa förfrågningar samtidigt som samtalet hålls informellt och fokuserat på dina specifika behov.

Jag uppskattar din förståelse och ser fram emot ett produktivt och insiktsfullt samtal med dig.
      </MKTypography>
    </Grid>
  </Container>
    
    </section>
  );
}

export default Tjänster;
