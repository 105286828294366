/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Hockey1.jpg";
import Image2 from "assets/images/Sports_Images/Hockey2.jpeg";
import Image3 from "assets/images/Sports_Images/Hockey3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");




  
  
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Field Hockey Scholarships in America: How Field Hockey Can Help You Achieve Your Dreams', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Chapter 1: The History and Development of Field Hockey in America', isSubheader: false },
    { id: '1.1', title: '1.1 Early History', isSubheader: true },
    { id: '1.2', title: '1.2 Development and Professionalization', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Chapter 2: Field Hockey at the University Level', isSubheader: false },
    { id: '2.1', title: '2.1 Competitions and Conferences', isSubheader: true },
    { id: '2.2', title: '2.2 Impact on Player Development', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Chapter 3: Transitioning from University to Professional', isSubheader: false },
    { id: '3.1', title: '3.1 From College to the Pros', isSubheader: true },
    { id: '3.2', title: '3.2 Professional Opportunities and Leagues', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Chapter 4: International Influences and Opportunities', isSubheader: false },
    { id: '4.1', title: '4.1 Global Influences', isSubheader: true },
    { id: '4.2', title: '4.2 Americans on the World Stage', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Chapter 5: Sports Scholarships and the Future of American Football', isSubheader: false },
    { id: '5.1', title: '5.1 The Importance of Scholarships', isSubheader: true },
    { id: '5.2', title: '5.2 How Sport Scholarships America Can Help', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  const [,] = useState("");


  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Content
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Hide</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Show</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>

























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Field Hockey Scholarships in America: How Field Hockey Can Help You Achieve Your Dreams
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Player in action during a Colgate college field hockey game."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
      Player in action during a game
      </figcaption>
    </figure>

    Field hockey is one of the fastest-growing sports in America. More and more young athletes are discovering the benefits and enjoyment of this dynamic and challenging sport. But did you know that field hockey can also help you achieve your academic and professional goals?
    <br />
    <br />

    If you have a passion for field hockey, you have a unique opportunity to play at the college level in America. This means you can study at one of the best universities in the world while developing your athletic skills under professional guidance. Moreover, you may qualify for a sports scholarship, providing financial support for your education.

    <br />
    <br />
    But how do you get such a scholarship? And how do you prepare for playing field hockey in America? That's where Sportbeursamerika.nl can assist you. Sportbeursamerika.nl is a specialized agency that helps young talents fulfill their dream of studying and playing hockey in America. I offer personal guidance, advice, and support in finding the right university, applying for a scholarship, handling all practical matters, and integrating into American culture.
    <br />
    <br />
    In this article, I will tell you more about the history and development of field hockey in America, the opportunities and benefits of playing at the university level, the transition from college to professional play, the international influences and opportunities within the sport, and, of course, the importance of scholarships and the role of Sportbeursamerika.nl. After reading this article, you will have a better understanding of what field hockey in America entails and how to prepare for this exciting challenge.
    <br />
    <br />
    Are you ready to learn more about field hockey in America? Keep reading!
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 1: The History and Development of Field Hockey in America
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Field hockey is a sport with a long and rich history. But how did it originate and grow in America? In this chapter, we will take you on a journey through time, from the early introduction to the current professionalization of the sport.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Early History
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Field hockey was first introduced in the US in the late 19th century by British immigrants and tourists. The game quickly gained popularity among women, who saw it as a way to exercise and socialize. The first collegiate teams were formed in 1901 at Vassar College in New York and Bryn Mawr College in Pennsylvania. These teams primarily played against each other or local clubs.
      <br />
      <br />
      In 1922, the United States Field Hockey Association (USFHA) was established as the national organization for the sport. The USFHA organized the first national championships for women in 1925 and for men in 1930. The USFHA was also responsible for selecting and training national teams that competed in international tournaments such as the Olympics and World Championships.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Development and Professionalization
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      In the second half of the 20th century, field hockey continued to grow in popularity and quality in America. The game spread further across the country, particularly in the Northeast, Midwest, and West. The number of collegiate teams increased, and more leagues and conferences were formed. Some of the most prestigious and competitive programs include Princeton University, University of Maryland, University of North Carolina, Stanford University, and Harvard University.
      <br />
      <br />
      The level of collegiate field hockey also rose thanks to improved facilities, coaching, and recruitment. Many universities now offer sports scholarships to talented field hockey players, allowing them to study without financial worries. Furthermore, collegiate field hockey serves as a breeding ground for professional field hockey, which is also becoming more developed and professionalized.
      <br />
      <br />
      Professional field hockey has also influenced the national team, which has been performing better on the international stage. For example, the US women's team has won bronze twice at the Olympics (in 1984 and 1996) and silver twice at the Pan American Games (in 2011 and 2015). The US men's team has also participated in multiple Olympics and World Championships but has not yet secured any medals.
      <br />
      <br />
      As you can see, field hockey has come a long way in America, from a recreational activity to a professional sport. But what does it entail to play field hockey at the collegiate level? We'll tell you in the next chapter.
    </MKTypography>
  </MKBox>
</MKBox>












                                              





                                  









<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 2: Field Hockey at the University Level
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Playing field hockey at the university level is one of the most challenging and rewarding experiences for a young athlete. It offers you the opportunity to blend your athletic talent with your academic ambitions and to be part of a close-knit and diverse community. In this chapter, we will tell you more about the competitions and conferences, the impact on player development, and the benefits of playing field hockey at the university level.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Competitions and Conferences
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Aerial view of the Boston College field hockey stadium"
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
        The stadium of Boston College
        </figcaption>
      </figure>
      Field hockey at the university level is regulated by the National Collegiate Athletic Association (NCAA), which divides the sport into three divisions: Division I, Division II, and Division III. Each division has its own rules, requirements, and scholarships. Generally, Division I is the highest competition level, featuring the best teams and players, and offering the most scholarships. Division II and III are less competitive but still provide ample opportunities for talented players.
      <br />
      <br />
      Within each division, there are various conferences, consisting of universities that are geographically or historically connected. Each conference has its own schedule, rankings, and championship. Some of the most well-known conferences include the Big Ten Conference, the Atlantic Coast Conference, the Ivy League, the Patriot League, and the America East Conference.
      <br />
      <br />
      The top teams and players from each conference can qualify for the national championship, held annually in November. The national championship involves a knockout tournament, with the winner crowned as the national champion.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Impact on Player Development
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Playing field hockey at the university level has a significant impact on player development. It enables players to enhance their skills under professional coaching, to train with state-of-the-art facilities and equipment, to compete against other top athletes, and to learn from their teammates and opponents.
      <br />
      <br />
      Furthermore, playing field hockey at the university level also offers players many personal benefits. It helps players boost their confidence, discipline, leadership, teamwork, communication, and problem-solving skills. It also assists players in improving their time management, study habits, stress management, and overall well-being. Moreover, it exposes players to different cultures, perspectives, and experiences, broadening their horizons.
      <br />
      <br />
      The story of Dutch female hockey players receiving scholarships to play at the university level in America serves as an example of how this experience can enhance their development as players. Many have played for prestigious university teams in the United States, where they have not only won championships and individual titles but also learned valuable lessons.
      <br />
      <br />
      The experience in America provides these athletes with a unique opportunity to immerse themselves in a different culture, enjoy new social environments, and experience a different education system. Interaction with coaches who challenge and support them contributes to refining their skills and expanding their tactical understanding of the game.
      <br />
      <br />
      Upon returning to the Netherlands, these hockey players have used their acquired knowledge and experiences to excel at the professional level and, in some cases, take on leadership roles within the national team. Their time in America has often prepared them for the pressure and expectations that come with international competitions, forming a foundation for their future success.
      <br />
      <br />
      As you can see, playing field hockey at the university level can offer you many advantages, both athletically and personally. But how do you transition from college to professional play? We will explain that in the next chapter.
    </MKTypography>
  </MKBox>
</MKBox>





                                  









<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 3: Transitioning from University to Professional
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Field hockey at the university level is a great way to unleash your athletic potential, but it doesn't have to be the end of your career. If you have the ambition and passion to go further, you can transition to professional play. In this chapter, we will tell you more about how to go from college to the pros and what professional opportunities and leagues exist for field hockey players.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 From College to the Pros
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Transitioning from university to professional play is not easy, but it's not impossible either. It requires a lot of dedication, hard work, talent, and a bit of luck. The most crucial aspect is to showcase yourself to the right people who can help you achieve your dream.
      <br />
      <br />
      One way to showcase yourself is by participating in try-outs. Try-outs are open training sessions or matches where professional teams can scout and assess players. Try-outs are typically held before or after the season and may be announced through websites, social media, or word of mouth. Try-outs can be an excellent opportunity to display your skills and receive feedback from coaches and managers. Try-outs can be conducted by professional teams worldwide.
      <br />
      <br />
      Another way to make yourself known is by leveraging your network. Your network comprises all the people you know or meet who are connected to field hockey, including coaches, teammates, opponents, friends, family, teachers, alumni, etc. Your network can help you stay informed about the latest news, opportunities, and tips in the field hockey world. It can also assist you in connecting with individuals who have influence or connections in the professional scene.
      <br />
      <br />
      Additionally, many European hockey players return to Europe to try their luck there. As you can see, there are various ways to get noticed by professional teams. But what kinds of teams are there, and what types of leagues exist? We will explain that in the next section.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professional Opportunities and Leagues
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      For a field hockey player who has gone through the American college system, the doors to a professional career are often wide open. This experience is more than just a stepping stone; it is a comprehensive preparation that has developed them in many aspects - physically, tactically, and mentally.
      <br />
      <br />
      A player excelling in college hockey can catch the attention of scouts and professional teams, not only for their on-field performance but also because of the discipline and versatility they have cultivated by studying and playing simultaneously. Professional hockey leagues worldwide, such as those in the Netherlands, Belgium, Germany, and Australia, are known for recruiting talented players with a solid background in college hockey.
      <br />
      <br />
      The competitive nature of college hockey in America also ensures that players are accustomed to a high level of training and physical preparation, which is essential for the professional game. Furthermore, they often have experience playing in important matches and championships, helping them handle the pressure of professional competitions.
      <br />
      <br />
      Additionally, the international experience and exposure to different playing styles in America provide these players with a unique perspective they can carry into their professional careers. It helps them adapt quickly to new teams and strategies and provides a broader understanding of the game.
      <br />
      <br />
      Professional teams value the combination of academic discipline and athletic excellence that a former college player brings. These players are often well-prepared to face the challenges of professional life, including balancing sport with other life commitments.
      <br />
      <br />
      As you can see, there are many professional opportunities and leagues for field hockey players in America. But what about the rest of the world? How does field hockey in America compare to international field hockey? We will explain that in the next chapter.
    </MKTypography>
  </MKBox>
</MKBox>






                                  








<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 4: International Influences and Opportunities
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Field hockey in America is not isolated but is part of a larger and more diverse field hockey world. Field hockey is a global sport played in more than 100 countries across all continents. Field hockey in America is influenced by and influences international field hockey. In this chapter, we will tell you more about global influences, Americans on the world stage, and international opportunities for field hockey players.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Global Influences
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Field hockey in America is influenced by field hockey played in other countries, especially in Europe and Asia. These countries have a longer and richer tradition of field hockey and often have a higher level of play and organization. By observing and learning from these countries, field hockey in America can further develop and improve.
      <br />
      <br />
      One way in which field hockey in America is influenced by international field hockey is by adopting different styles and tactics. For example, Dutch field hockey is known for its fast and attacking play, with a focus on ball possession, combinations, and goal attempts. German field hockey is known for its physical and defensive style, featuring high pressure, duels, and counterattacks. Indian field hockey is known for its technical and creative play, with a focus on dribbling, passing, and tricks.
      <br />
      <br />
      By learning from these styles and tactics, field hockey in America can diversify its own game and adapt to different situations, adding more variety, flexibility, and effectiveness.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Americans on the World Stage
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Field hockey in America also impacts international field hockey by providing players who participate in the biggest and most significant tournaments worldwide. These players not only represent their country but also their university, team, and sport. Through their performances, they demonstrate what field hockey in America has to offer and inspire others to participate in this sport.
      <br />
      <br />
      A prime example of how American players enter the world stage is the story of Lauren Crandall. Lauren is an American field hockey player who received a scholarship to play for Wake Forest University. She played for the team for four seasons, winning the NCAA Division I title twice.
      <br />
      <br />
      Lauren was also part of the American national team, participating in three Olympic Games (in 2008, 2012, and 2016), two World Championships (in 2010 and 2014), and four Pan-American Games (in 2007, 2011, 2015, and 2019). She also served as the team captain from 2013 to 2016.
      <br />
      <br />
      Lauren states that her time in America helped her significantly in realizing her dream of playing at the highest level. She learned a lot from her coach, Jennifer Averill, who supported and motivated her. She also enjoyed the culture, the people, and the education in America. She takes pride in representing her country and being a role model for young players.
      <br />
      <br />
      As you can see, field hockey in America can offer you many international influences and opportunities, both as a player and as an individual. But how can you seize these opportunities? And how can you get a scholarship to study and play in America? We will tell you in the next chapter.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  




<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 5: Sports Scholarships and the Future of Athletes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sports scholarships offer numerous opportunities for young athletes to showcase their talent and develop their skills. One of the most significant opportunities is obtaining a sports scholarship to study and compete at the college level. In this chapter, we will discuss the importance of sports scholarships and how Sport Scholarships America can help you make your dream come true.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 The Importance of Scholarships
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A sports scholarship is financial support provided by a university to a student-athlete to combine studies and sports. It can cover the costs of tuition, books, housing, meals, and other expenses. Additionally, it may offer benefits such as academic tutoring, medical care, training facilities, and equipment.
      <br />
      <br />
      Obtaining a sports scholarship is competitive, with many factors playing a role in the awarding process, including academic achievements, athletic performance, personality, motivation, leadership, and adaptability. Therefore, it's essential to prepare well and distinguish yourself from the competition.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 How Sport Scholarships America Can Help
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo of Sport Scholarships America, featuring sports equipment and a graduation cap, representing the focus on sports scholarships for student-athletes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo of Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America is an organization specialized in guiding young sports talents to sports scholarships. With passionate and experienced experts, Sport Scholarships America supports student-athletes at every step of the journey, from initial orientation to final placement. Their services include:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>A personal intake interview to discuss the student-athlete's wishes, possibilities, and expectations.</li>
        <li>A professional evaluation of the student-athlete's athletic and academic level.</li>
        <li>An extensive network of contacts with coaches, universities, and colleges.</li>
        <li>Effective promotion of the student-athlete's profile and videos to potential coaches.</li>
        <li>Expert advice on the best options based on the student-athlete's preferences, goals, and budget.</li>
        <li>Negotiation of the best scholarship offers on behalf of the student-athlete.</li>
        <li>Assistance in applying for necessary documents, such as visa, transcript, diploma, and admission tests.</li>
        <li>Preparation for student-athletes' life during their scholarship, including culture, language, rules, and tips.</li>
        <li>Aftercare and follow-up during the scholarship period.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Want to explore scholarship opportunities and achieve your athletic and academic goals? <Link to="/Services-Products">View the full range</Link> of services offered by Sport Scholarships America and schedule a consultation today.
    </MKTypography>
  </MKBox>
</MKBox>
  
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;