/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Golf1.jpeg";
import Image2 from "assets/images/Sports_Images/Golf2.webp";
import Image3 from "assets/images/Sports_Images/Golf3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"


// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Beca de Golf: Cómo Estudiar y Jugar al Golf en Estados Unidos', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capítulo 1: Historia y Desarrollo del Golf Universitario', isSubheader: false },
    { id: '1.1', title: '1.1 Primeros Años', isSubheader: true },
    { id: '1.2', title: '1.2 Crecimiento y Profesionalización', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capítulo 2: El Paisaje Actual del Golf Universitario', isSubheader: false },
    { id: '2.1', title: '2.1 Principales Competiciones y Conferencias', isSubheader: true },
    { id: '2.2', title: '2.2 Programas Destacados y Equipos Prestigiosos', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capítulo 3: Del Colegio a los Profesionales', isSubheader: false },
    { id: '3.1', title: '3.1 El Camino hacia el Circuito Profesional', isSubheader: true },
    { id: '3.2', title: '3.2 Rutas Alternativas al Profesionalismo', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capítulo 4: Influencias y Oportunidades Internacionales', isSubheader: false },
    { id: '4.1', title: '4.1 Jugadores Extranjeros en el Golf Universitario', isSubheader: true },
    { id: '4.2', title: '4.2 Jugadores Estadounidenses en el Extranjero', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capítulo 5: Becas Deportivas y el Futuro de los Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 La Importancia de las Becas', isSubheader: true },
    { id: '5.2', title: '5.2 Cómo Puede Ayudar Sport Scholarships America', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };





  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contenidos
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Ocultar</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>






















          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Beca de Golf: Cómo Estudiar y Jugar al Golf en Estados Unidos
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Campo de golf durante el campeonato de la NCAA, con jugadores y espectadores alrededor del campo"
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        El Campeonato de Golf de la NCAA
      </figcaption>
    </figure>
    El golf es uno de los deportes más populares a nivel universitario en Estados Unidos. Cada año, miles de estudiantes atletas compiten por títulos nacionales, premios prestigiosos y un lugar en el golf profesional. El golf universitario no solo ofrece la oportunidad de mejorar tus habilidades atléticas, sino también de obtener un título académico, construir una red de contactos y tener una experiencia inolvidable.
    <br/>
    <br/>
    Sin embargo, el golf universitario no es para todos. Requiere un alto nivel de compromiso, disciplina y talento. Además, no es fácil ser admitido en una universidad con un buen programa de golf. La competencia es feroz y los costos son elevados.
    <br/>
    <br/>
    Afortunadamente, existen oportunidades para hacer realidad tu sueño con la ayuda de una beca de golf. Una beca de golf es el apoyo financiero que recibes de una universidad para estudiar y jugar al golf allí. Con una beca de golf, puedes reducir o incluso cubrir completamente los costos de tus estudios mientras disfrutas de todos los beneficios del golf universitario.
    <br/>
    <br/>
    Pero, ¿cómo puedes obtener una beca de golf? ¿Y qué debes considerar al elegir una universidad? Ahí es donde Sportbeursamerika.nl puede ayudarte. Sportbeursamerika.nl es el especialista en guiar a estudiantes hacia becas deportivas en Estados Unidos. Tengo experiencia y conocimientos sobre el sistema educativo y deportivo estadounidense, y he ayudado a muchos estudiantes atletas a alcanzar su sueño.
    <br/>
    <br/>
    En este artículo, proporcionamos más información sobre el golf universitario y mostramos cómo Sportbeursamerika.nl puede ayudarte a encontrar la beca de golf perfecta para ti.
    <br/>
    <br/>
    ¿Quieres saber más? ¡Sigue leyendo!
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>

<MKBox id="Hoofdstuk 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 1: Historia y Desarrollo del Golf Universitario
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El golf universitario tiene una historia larga y rica que se remonta al principio del siglo XX. En este capítulo, analizaremos los primeros años y el crecimiento y la profesionalización del golf universitario en Estados Unidos.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Primeros Años
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Las primeras competiciones de golf en universidades y colegios en los EE. UU. se llevaron a cabo en la década de 1890. El primer partido de golf intercolegial oficial fue entre Harvard y Yale en 1897. En 1901, se fundó la Asociación Intercolegial de Golf (IGA), que organizó el primer campeonato nacional en 1902. Yale ganó el primer título y continuó dominando hasta la década de 1920.
      <br/>
      <br/>
      Los primeros programas de golf en universidades se centraron principalmente en los aspectos sociales y recreativos del deporte. Los estudiantes jugaban principalmente en campos locales y tenían poco asesoramiento o instalaciones. Los equipos de golf generalmente consistían en cuatro o cinco jugadores que se clasificaban a través de torneos internos.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crecimiento y Profesionalización
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      En la década de 1930, el golf universitario comenzó a crecer y profesionalizarse significativamente. Este período vio un aumento en el número de programas de golf universitarios y una mejora en la calidad de la formación y las instalaciones. La profesionalización fue estimulada además por la creciente atención al deporte y el reconocimiento del potencial del golf a nivel universitario. Durante este tiempo, la Asociación Nacional Atlética Universitaria (NCAA) tomó un papel importante en la organización y regulación del golf universitario, contribuyendo al desarrollo y popularidad del deporte en Estados Unidos.
      <br/>
      <br/>
      La NCAA tomó el control de la organización del campeonato nacional de la IGA e introdujo nuevas reglas y formatos. La NCAA dividió las universidades en diferentes divisiones, según su tamaño y nivel atlético. La NCAA también organizó campeonatos regionales para facilitar la clasificación para el campeonato nacional.
      <br/>
      <br/>
      Las universidades también comenzaron a invertir más en sus programas de golf, contratando entrenadores profesionales, construyendo o mejorando campos de golf y ofreciendo becas a jugadores talentosos. Los equipos de golf se hicieron más grandes y fuertes, y la competencia se intensificó.
      <br/>
      <br/>
      En las décadas de 1940 y 1950, también se agregaron más torneos importantes, como la Copa Walker, la Copa Palmer y el Trofeo Eisenhower. Estos torneos dieron a los golfistas universitarios la oportunidad de competir con jugadores internacionales y mejorar sus habilidades.
      <br/>
      <br/>
      En este capítulo, hemos visto cómo se originó y creció el golf universitario en Estados Unidos. En el próximo capítulo, veremos el panorama actual del golf universitario y qué competiciones y programas están disponibles.
    </MKTypography>
  </MKBox>
</MKBox>














                                              





                                  






<MKBox id="Chapter 2" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Capítulo 2: El Paisaje Actual del Golf Universitario
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Hoy en día, el golf universitario es uno de los deportes más competitivos y prestigiosos a nivel universitario en los Estados Unidos. En este capítulo, analizaremos las principales competiciones y conferencias, así como los mejores programas y equipos prestigiosos en el golf universitario.
    </MKTypography>

    <MKBox id="2.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.1 Principales Competiciones y Conferencias
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image2}
                    alt="Tiger Woods representando a Stanford en el golf universitario, mostrado en concentración durante el partido."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal2(Image2)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Tiger Woods en acción para Stanford.
                </figcaption>
            </figure>
            La NCAA es la organización más grande e importante para el golf universitario en los EE. UU. La NCAA divide las universidades en tres divisiones: División I, División II y División III. Cada división tiene su propio campeonato, celebrado anualmente en mayo o junio. Los campeonatos consisten en un torneo por equipos y otro individual, donde los mejores jugadores y equipos de cada división compiten por el título nacional.
            <br/>
            <br/>
            La NCAA también tiene varias conferencias regionales, que consisten en universidades que están geográficamente o históricamente conectadas. Cada conferencia tiene su propio campeonato, que sirve como torneo de clasificación para el campeonato nacional. Algunas conferencias son más fuertes que otras y tienen más influencia en el panorama del golf universitario.
            <br/>
            <br/>
            Algunas de las conferencias más conocidas incluyen:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    La Conferencia de la Costa Atlántica (ACC), que consiste en 15 universidades en la Costa Este, como Duke, Carolina del Norte y Clemson.
                </li>
                <li>
                    La Conferencia del Big Ten, que consiste en 14 universidades en el Medio Oeste, como Michigan, Ohio State e Illinois.
                </li>
                <li>
                    La Conferencia Pac-12, que consiste en 12 universidades en la Costa Oeste, como Stanford, California y Arizona State.
                </li>
                <li>
                    La Conferencia del Sureste (SEC), que consiste en 14 universidades en el Sureste, como Alabama, Georgia y Florida.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Además de la NCAA, también hay otras organizaciones que organizan competiciones de golf universitario, como la Asociación Nacional de Atletismo Intercolegial (NAIA), la Asociación Nacional de Atletismo de Colegios Comunitarios (NJCAA) y la Asociación Nacional de Atletismo de Colegios Cristianos (NCCAA). Estas organizaciones tienen sus propias divisiones, conferencias y campeonatos, que generalmente son más pequeños y menos prestigiosos que los de la NCAA.
        </MKTypography>
    </MKBox>
    <MKBox id="2.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.2 Programas Destacados y Equipos Prestigiosos
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            El golf universitario no es solo un deporte, sino también una educación. Las universidades que ofrecen golf universitario tienen diversos programas, que varían en calidad, reputación e instalaciones. Algunos programas son líderes y prestigiosos, atrayendo a los mejores jugadores y entrenadores. Otros programas son menos conocidos o exitosos pero aún ofrecen buenas oportunidades para los estudiantes atletas.
            <br/>
            <br/>
            Algunos de los factores que determinan un programa incluyen:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    La historia y tradición del programa.
                </li>
                <li>
                    El nivel y los resultados del equipo.
                </li>
                <li>
                    La calidad y experiencia del entrenador.
                </li>
                <li>
                    Las instalaciones y equipamiento del programa.
                </li>
                <li>
                    Los estándares académicos y requisitos del programa.
                </li>
                <li>
                    El ambiente y cultura del programa.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Algunos de los programas de golf universitario más destacados incluyen:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Universidad de Stanford: Stanford tiene uno de los programas de golf más antiguos y exitosos en los EE. UU., con múltiples títulos nacionales en el golf masculino. El programa ha producido exalumnos famosos como Tiger Woods y Tom Watson. El equipo de golf femenino de Stanford también ha ganado reconocimiento nacional con varios títulos de la NCAA y campeonas individuales.
                </li>
                <li>
                    Universidad Estatal de Oklahoma: Oklahoma State tiene un programa de golf dominante, especialmente conocido por su equipo masculino, con múltiples títulos nacionales. El programa ha producido golfistas de élite como Rickie Fowler y Viktor Hovland.
                </li>
                <li>
                    Universidad de Duke: Duke es conocida por su fuerte programa de golf femenino, con varios títulos de la NCAA. El equipo de golf masculino de Duke ha tenido un desempeño sólido en los campeonatos de conferencia y tiene una historia respetable en las competiciones de la NCAA. Duke ha producido golfistas talentosos, incluidas Amanda Blumenherst y Brittany Lang.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            En este capítulo, hemos visto cómo es el paisaje actual del golf universitario y qué competiciones y programas están disponibles. En el próximo capítulo, veremos cómo pasar del college al profesionalismo y qué rutas alternativas están disponibles.
        </MKTypography>
    </MKBox>
</MKBox>












                                              






                                  




<MKBox id="Chapter 3" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Capítulo 3: Del Colegio a los Profesionales
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        El golf universitario no es solo un deporte, sino también un camino profesional. Muchos golfistas universitarios aspiran a hacer la transición al circuito profesional y jugar en los más altos niveles del golf. En este capítulo, exploraremos cómo el golf universitario puede servir como un trampolín hacia el golf profesional y las rutas alternativas disponibles.
    </MKTypography>

    <MKBox id="3.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.1 El Camino hacia el Circuito Profesional
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            El golf universitario es una excelente preparación para una carrera profesional en el golf. Los golfistas universitarios tienen la oportunidad de desarrollarse atlética, académica y personalmente. Aprenden a manejar la presión, la competencia y las expectativas. Juegan en campos desafiantes contra oponentes fuertes y bajo diversas condiciones. También tienen acceso a instalaciones de alta calidad, entrenadores y orientación.
            <br/>
            <br/>
            Los golfistas universitarios también tienen una ventaja sobre otros jugadores que no asisten a la universidad. Tienen un título que puede ayudarles a encontrar un trabajo o un patrocinador si no tienen éxito como profesionales. También tienen una red de contactos y amigos que pueden apoyarlos o aconsejarlos en sus carreras.
            <br/>
            <br/>
            Muchos golfistas universitarios hacen la transición al circuito profesional después de completar sus estudios. Algunos lo hacen antes si creen que están listos. Para convertirse en profesional, necesitan clasificarse para un tour, como el PGA Tour, el LPGA Tour o el European Tour, a través de torneos de clasificación como la Q-School o el Korn Ferry Tour.
            <br/>
            <br/>
            Muchos ex golfistas universitarios han tenido éxito en el circuito profesional. Ejemplos incluyen:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Phil Mickelson: Mickelson jugó golf universitario para la Universidad Estatal de Arizona, donde ganó el campeonato de la NCAA tres veces. Se convirtió en profesional en 1992 y desde entonces ha logrado numerosas victorias en el PGA Tour, incluyendo varios majors.
                </li>
                <li>
                    Annika Sörenstam: Sörenstam jugó golf universitario para la Universidad de Arizona, donde ganó el campeonato de la NCAA en 1991. Se convirtió en profesional en 1992 y desde entonces ha logrado numerosas victorias profesionales, incluyendo varios majors.
                </li>
                <li>
                    Jon Rahm: Rahm jugó golf universitario para la Universidad Estatal de Arizona, donde ganó el Premio Ben Hogan dos veces como el mejor golfista universitario. Se convirtió en profesional en 2016 y desde entonces ha logrado múltiples victorias profesionales, incluyendo majors.
                </li>
            </ul>
        </MKTypography>
    </MKBox>
    <MKBox id="3.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.2 Rutas Alternativas al Profesionalismo
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Si bien el golf universitario es una ruta común hacia el circuito profesional, no es la única. Hay otros caminos que los jugadores pueden seguir para alcanzar su sueño. Algunos jugadores eligen no ir a la universidad sino convertirse en profesionales directamente después de la escuela secundaria. Otros asisten a la universidad pero se retiran temprano para enfocarse completamente en su deporte.
            <br/>
            <br/>
            Ejemplos de jugadores que tomaron una ruta alternativa incluyen:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Tiger Woods: Woods jugó golf universitario para la Universidad de Stanford, donde ganó el campeonato de la NCAA dos veces. Sin embargo, se convirtió en profesional en 1996 después de dos años de estudio. Desde entonces ha logrado numerosas victorias en el PGA Tour, incluyendo varios majors.
                </li>
                <li>
                    Lydia Ko: Ko nunca jugó golf universitario pero se convirtió en profesional en 2013 a la edad de 16 años. Desde entonces ha logrado múltiples victorias en el LPGA Tour.
                </li>
                <li>
                    Jordan Spieth: Spieth jugó golf universitario para la Universidad de Texas, donde ganó el campeonato de la NCAA en 2012. Sin embargo, se convirtió en profesional en 2012 después de un año de estudio. Desde entonces ha logrado múltiples victorias en el PGA Tour, incluyendo varios majors.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            En este capítulo, hemos visto cómo hacer la transición del colegio a los profesionales y qué rutas alternativas están disponibles. En el próximo capítulo, exploraremos las influencias y oportunidades internacionales en el golf universitario.
        </MKTypography>
    </MKBox>
</MKBox>






                                              






                                  








<MKBox id="Chapter 4" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Capítulo 4: Influencias y Oportunidades Internacionales
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        El golf universitario no es solo un deporte estadounidense, sino también internacional. Hay muchos jugadores extranjeros que vienen a los Estados Unidos para estudiar y jugar al golf, y también hay muchos jugadores estadounidenses que juegan en el extranjero. En este capítulo, examinaremos el impacto y las contribuciones de los atletas estudiantiles internacionales y las oportunidades para los golfistas universitarios estadounidenses de jugar internacionalmente.
    </MKTypography>

    <MKBox id="4.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.1 Jugadores Extranjeros en el Golf Universitario
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image3}
                    alt="Texas Longhorns, con Jordan Spieth, celebrando el campeonato nacional"
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal3(Image3)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Texas Longhorns, con Jordan Spieth, celebrando el campeonato nacional
                </figcaption>
            </figure>
            El golf universitario es una opción atractiva para muchos jugadores extranjeros que buscan una combinación de deportes y educación. Pueden beneficiarse de la alta calidad de las universidades estadounidenses, las excelentes instalaciones y orientación de los programas de golf, y la fuerte competencia y exposición de los torneos de golf universitario.
            <br/>
            <br/>
            Los jugadores extranjeros también ofrecen mucho a las universidades estadounidenses. Traen diversidad, cultura y talento a sus equipos. También pueden compartir sus experiencias y perspectivas con sus compañeros de equipo y entrenadores, contribuyendo a su desarrollo.
            <br/>
            <br/>
            Muchos jugadores extranjeros han tenido éxito en el golf universitario, destacándose como atletas estudiantiles. Ejemplos incluyen:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Maria Fassi: Fassi jugó al golf universitario en la Universidad de Arkansas, donde ganó el Premio ANNIKA dos veces como la mejor golfista universitaria femenina. Se convirtió en profesional en 2019 y desde entonces ha logrado varias victorias profesionales.
                </li>
                <li>
                    Viktor Hovland: Hovland jugó al golf universitario en la Universidad Estatal de Oklahoma, donde ganó el campeonato de la NCAA en 2018. Se convirtió en profesional en 2019 y desde entonces ha logrado varias victorias profesionales.
                </li>
            </ul>
        </MKTypography>
    </MKBox>
    <MKBox id="4.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.2 Jugadores Estadounidenses en el Extranjero
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            El golf universitario no es la única forma de desarrollarse como golfista. También hay muchas oportunidades para que los golfistas universitarios estadounidenses jueguen internacionalmente, experimentando otras culturas y campos de golf. Esto puede ampliar sus horizontes, aumentar su adaptabilidad y mejorar su juego.
            <br/>
            <br/>
            Los golfistas universitarios estadounidenses pueden jugar internacionalmente a través de varios programas, como:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    La Copa Palmer: Un torneo anual entre equipos de golfistas universitarios estadounidenses e internacionales, nombrado en honor a Arnold Palmer, un famoso golfista que también jugó al golf universitario. El torneo se lleva a cabo en diferentes lugares de todo el mundo, como Francia, Irlanda, Inglaterra y Suecia.
                </li>
                <li>
                    El Trofeo Eisenhower: Un torneo bianual entre equipos de golfistas amateur de diferentes países, nombrado en honor a Dwight D. Eisenhower, un ex presidente de EE. UU. y ávido golfista. El torneo se lleva a cabo en varios lugares de todo el mundo, como Australia, Japón, México y Turquía.
                </li>
                <li>
                    El Programa de Estudios en el Extranjero: Un programa académico que ofrece a los estudiantes la oportunidad de estudiar durante un semestre o un año en una universidad extranjera. Algunas universidades también tienen programas de golf que colaboran con universidades extranjeras, permitiendo a los atletas estudiar durante su estancia.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Muchos golfistas universitarios estadounidenses se han beneficiado al jugar en el extranjero, elevando su juego a un nivel más alto. Ejemplos incluyen:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Collin Morikawa: Morikawa jugó al golf universitario en la Universidad de California, Berkeley, donde se convirtió en All-American cuatro veces. También jugó en la Copa Palmer en 2017 y 2018, y en el Trofeo Eisenhower en 2018. Se convirtió en profesional en 2019 y desde entonces ha logrado múltiples victorias profesionales.
                </li>
                <li>
                    Jennifer Kupcho: Kupcho jugó al golf universitario en la Universidad de Wake Forest, donde ganó el campeonato de la NCAA en 2018. También jugó en la Copa Palmer en 2018, y en la Copa Curtis en 2016 y 2018. Se convirtió en profesional en 2019 y desde entonces ha logrado múltiples victorias profesionales.
                </li>
                <li>
                    Patrick Reed: Reed jugó al golf universitario en la Universidad de Georgia y la Universidad Estatal de Augusta, donde ganó el campeonato de la NCAA dos veces. También jugó en la Copa Palmer en 2011 y estudió durante un semestre en la Universidad de Stirling en Escocia. Se convirtió en profesional en 2011 y desde entonces ha logrado múltiples victorias profesionales.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            En este capítulo, hemos visto cómo el golf universitario es un deporte internacional, y las influencias y oportunidades para los jugadores extranjeros y estadounidenses. En el siguiente capítulo, exploraremos la importancia de las becas de golf y el papel de Sportbeursamerika.nl.
        </MKTypography>
    </MKBox>
</MKBox>









                                              






                                  



<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Becas Deportivas y el Futuro de los Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Las becas deportivas ofrecen numerosas oportunidades para que los jóvenes atletas muestren su talento y desarrollen sus habilidades. Una de las oportunidades más significativas es obtener una beca deportiva para estudiar y competir a nivel universitario. En este capítulo, discutiremos la importancia de las becas deportivas y cómo Sport Scholarships America puede ayudarte a hacer realidad tu sueño.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 La Importancia de las Becas
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Una beca deportiva es un apoyo financiero proporcionado por una universidad a un estudiante-atleta para combinar estudios y deporte. Puede cubrir los costos de matrícula, libros, alojamiento, comidas y otros gastos. Además, puede ofrecer beneficios como tutoría académica, atención médica, instalaciones de entrenamiento y equipo.
      <br />
      <br />
      Obtener una beca deportiva es competitivo, con muchos factores que influyen en el proceso de adjudicación, incluyendo logros académicos, rendimiento deportivo, personalidad, motivación, liderazgo y adaptabilidad. Por lo tanto, es importante prepararse bien y destacarse entre la competencia.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Cómo Puede Ayudar Sport Scholarships America
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo de Sport Scholarships America, que muestra equipamiento deportivo y una gorra de graduación, representando el enfoque en becas deportivas para estudiantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America es una organización especializada en guiar a jóvenes talentos deportivos hacia becas deportivas. Con expertos apasionados y experimentados, Sport Scholarships America apoya a los estudiantes-atletas en cada paso del camino, desde la orientación inicial hasta la colocación final. Sus servicios incluyen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Una entrevista personal para discutir los deseos, posibilidades y expectativas del estudiante-atleta.</li>
        <li>Una evaluación profesional del nivel académico y deportivo del estudiante-atleta.</li>
        <li>Una extensa red de contactos con entrenadores, universidades y colegios.</li>
        <li>Promoción efectiva del perfil y videos del estudiante-atleta ante entrenadores potenciales.</li>
        <li>Asesoramiento experto sobre las mejores opciones basadas en las preferencias, metas y presupuesto del estudiante-atleta.</li>
        <li>Negociación de las mejores ofertas de becas en nombre del estudiante-atleta.</li>
        <li>Asistencia en la solicitud de documentos necesarios, como visa, expediente académico, diploma y pruebas de admisión.</li>
        <li>Preparación para la vida del estudiante-atleta durante su beca, incluyendo cultura, idioma, reglas y consejos.</li>
        <li>Seguimiento y atención posterior durante el período de la beca.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      ¿Quieres explorar oportunidades de becas y alcanzar tus metas deportivas y académicas? <Link to="/es/Servicios-Productos">Consulta la gama completa</Link> de servicios ofrecidos por Sport Scholarships America y programa una consulta hoy mismo.
    </MKTypography>
  </MKBox>
</MKBox>



          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;