/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Volleybal1.avif";
import Image2 from "assets/images/Sports_Images/volleybal2.webp";
import Image3 from "assets/images/Sports_Images/Volleybal3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images
import { Link } from 'react-router-dom';



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Volleyball Stipendium: Hvordan Kan Du Studere og Spille Volleyball i Amerika?', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapitel 1: Historien og Udviklingen af Volleyball i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Tidlige år og Utdanningsinstitusjoners Rolle', isSubheader: true },
    { id: '1.2', title: '1.2 Vækst og Professionalisering', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapitel 2: Universitetsvolleyball i Dag', isSubheader: false },
    { id: '2.1', title: '2.1 Store Ligaer og Konferanser', isSubheader: true },
    { id: '2.2', title: '2.2 Top Programmer og Prestigefyldte Hold', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapitel 3: Fra College til Profesjonelt Nivå', isSubheader: false },
    { id: '3.1', title: '3.1 Vejen til Profesjonalisme', isSubheader: true },
    { id: '3.2', title: '3.2 Profesjonelle Ligaer og Karrieremuligheder', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapitel 4: Internationale Påvirkninger og Konkurranser', isSubheader: false },
    { id: '4.1', title: '4.1 Udenlandske Påvirkninger', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanere på Verdensscenen', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapitel 5: Sportslegater og fremtiden for atleter', isSubheader: false },
    { id: '5.1', title: '5.1 Vigtigheden af legater', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Legater Amerika Kan Hjælpe', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
           <Grid item xs={12} md={9}>

























           <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Volleyball Stipendium: Hvordan Kan Du Studere og Spille Volleyball i Amerika?
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Volleyball game in Nebraska, attended by more than 92,000 fans."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        92,000 tilskuere ser en volleyballkamp i Nebraska
      </figcaption>
    </figure>
    Volleyball er en sport, der kræver meget af dine fysiske og mentale evner. Du skal reagere hurtigt, samarbejde effektivt, tænke taktisk og have stærk udholdenhed. Derudover er volleyball en sport, der giver meget glæde og tilfredsstillelse, især når man spiller på et højt niveau. Men hvordan kan du som volleyballtalent forfølge dine sportslige ambitioner uden at forsømme dine studier? Svaret er: ved at vælge et volleyballstipendium i Amerika.
    <br />
    <br />
    Et volleyballstipendium er en økonomisk støtte tilbudt af et amerikansk universitet, der giver dig mulighed for at studere og spille volleyball der. Med et volleyballstipendium kan du opnå to mål på én gang: du kan tjene en anerkendt grad fra en prestigefyldt uddannelsesinstitution samtidig med at du yderligere udvikler dine volleyballfærdigheder under professionel vejledning. Derudover kan du nyde den unikke oplevelse af at bo og lære i en anden kultur, med nye venner og udfordringer.
    <br />
    <br />
    Men hvordan kan du kvalificere dig til et volleyballstipendium? Hvad er kravene og forventningerne? Og hvordan finder du det rette universitet, der passer til dig? Disse er alle spørgsmål, som Sportbeursamerika.nl kan hjælpe dig med. Sportbeursamerika.nl er specialisten inden for idrætsstipendier i Amerika. Med års erfaring og et omfattende netværk kan jeg guide dig gennem alle trin til succesfuldt at ansøge om et volleyballstipendium, lige fra oprettelse af en online profil til håndtering af alle praktiske forhold.
    <br />
    <br />
    Hvis du vil vide mere om, hvordan Sportbeursamerika.nl kan hjælpe dig med at studere og spille volleyball i Amerika, fortsæt med at læse eller kontakt mig for en uforpligtende indledende samtale. Jeg vil gerne fortælle dig mere om mulighederne og fordelene ved et volleyballstipendium i Amerika.
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>

<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 1: Historien og Utviklingen av Volleyball i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Volleyball er en sport som har eksistert i over et århundre, men den har opplevd betydelig vekst i popularitet og professionalisme, spesielt i de siste tiårene. I dette kapittelet vil vi dykke ned i historien og utviklingen av volleyball i Amerika, fra dens tidlige år til i dag.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidlige år og Utdanningsinstitusjoners Rolle
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Volleyball ble oppfunnet i 1895 av William G. Morgan, en fysisk utdanningslærer ved YMCA (Young Men's Christian Association) i Holyoke, Massachusetts. Han ønsket å skape et spill som var mindre fysisk krevende enn basketball, som hadde blitt oppfunnet et år tidligere av hans kollega James Naismith. Til å begynne med kalte han spillet "mintonette", men det ble snart kjent som "volleyball" fordi spillerne måtte volleye ballen over et nett.
      <br />
      <br />
      Spillet spredte seg raskt over hele USA og andre land, hovedsakelig gjennom YMCA og andre utdanningsinstitusjoner. I 1916 ble en av de første offisielle volleyballturneringene avholdt ved University of California i Berkeley. I 1922 ble United States Volleyball Association (USVBA) etablert, som senere ble USA Volleyball (USAV). Denne organisasjonen ble ansvarlig for å regulere og fremme volleyball i Amerika.
      <br />
      <br />
      I 1928 ble den første nasjonale mesterskapet for menn organisert av USVBA, etterfulgt av det første nasjonale mesterskapet for kvinner i 1949. I 1955 ble det første verdensmesterskapet for menn arrangert i Frankrike, der Amerika endte på sjetteplass. I 1956 fant det første verdensmesterskapet for kvinner sted i Frankrike, der Amerika endte på fjerdeplass.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Vekst og Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      På 1960- og 1970-tallet begynte volleyball å vokse og professionalisere seg i Amerika. Dette skyldtes delvis innføringen av nye regler, som vedtaket av rally-point-systemet, der hvert poeng teller uavhengig av hvem som server. Volleyball fikk også mer oppmerksomhet gjennom TV-sendte sendinger av de olympiske leker, der volleyball har vært en offisiell sport siden 1964.
      <br />
      <br />
      En av de sentrale personene i volleyballens historie i Amerika er Doug Beal, som oppnådde suksess både som spiller og trener. Han spilte for landslaget fra 1970 til 1976 og vant gull under Pan American Games i 1975, blant annet. Han ble senere trener for landslaget fra 1977 til 1984 og ledet dem til deres første olympiske gull i 1984 i Los Angeles. Han var også involvert i grunnleggelsen av Major League Volleyball (MLV), en profesjonell kvinne-liga som eksisterte fra 1987 til 1989.
      <br />
      <br />
      En annen betydelig hendelse i volleyballens historie i Amerika var etableringen av Association of Volleyball Professionals (AVP) i 1983, med fokus på beachvolleyball. Beachvolleyball er en variant av volleyball spilt på sand med lag på to spillere. AVP organiserte turneringer og konkurranser for både menn og kvinner, og bidro til å popularisere beachvolleyball blant publikum. Noen velkjente beachvolleyballspillere inkluderer Karch Kiraly, Sinjin Smith, Randy Stoklos, Misty May-Treanor og Kerri Walsh Jennings.
    </MKTypography>
  </MKBox>
</MKBox>






                                  






<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 2: Universitetsvolleyball i Dag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Volleyball er en av de mest populære og konkurransedyktige idrettene på universitetsnivå i Amerika. Hvert år deltar tusenvis av lag i ulike konkurranser og konferanser, som kulminerer i nasjonale mesterskap. I dette kapittelet vil vi gi deg en oversikt over de store ligaene og konferansene, og diskutere noen av de beste programmene og prestisjefylte lagene innen universitetsvolleyball.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Store Ligaer og Konferanser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Volleyballag som feirer en seier sammen."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Volleyballspillere som feirer en seier
        </figcaption>
      </figure>
      Den primære organisasjonen som er ansvarlig for å organisere og regulere universitetsvolleyball i Amerika, er National Collegiate Athletic Association (NCAA). NCAA deler universiteter inn i tre divisjoner basert på størrelse, budsjett og idrettsprestasjoner. Disse divisjonene er:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Divisjon I: Dette er den høyeste divisjonen, der de fleste store og velkjente universitetene er plassert. Lagene i denne divisjonen får mest oppmerksomhet, mediedekning og stipend. Det er omtrent 330 universiteter i denne divisjonen, fordelt på 32 konferanser. En konferanse er en gruppe universiteter som er geografisk eller historisk tilknyttet og konkurrerer mot hverandre. Noen eksempler på konferanser i Divisjon I inkluderer Big Ten, Pac-12, Southeastern Conference (SEC) og Atlantic Coast Conference (ACC).
        </li>
        <li>
          Divisjon II: Dette er mellomdivisjonen, der universiteter er litt mindre og mindre kjente enn i Divisjon I, men de opprettholder likevel et høyt nivå av volleyball. Lagene i denne divisjonen får mindre oppmerksomhet, mediedekning og stipend enn i Divisjon I, men mer enn i Divisjon III. Det er omtrent 300 universiteter i denne divisjonen, fordelt på 23 konferanser. Noen eksempler på konferanser i Divisjon II inkluderer Great Lakes Valley Conference (GLVC), California Collegiate Athletic Association (CCAA) og Gulf South Conference (GSC).
        </li>
        <li>
          Divisjon III: Dette er den laveste divisjonen, der universiteter primært fokuserer på akademisk fremragende i stedet for idrettsprestasjoner. Lagene i denne divisjonen får ikke stipender for sine idrettsutøvere, men gir økonomisk støtte basert på akademisk fortjeneste. Det er omtrent 450 universiteter i denne divisjonen, fordelt på 43 konferanser. Noen eksempler på konferanser i Divisjon III inkluderer New England Small College Athletic Conference (NESCAC), University Athletic Association (UAA) og Centennial Conference.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Hvert år konkurrerer lag innenfor sin divisjon og konferanse i kamper for å kvalifisere seg til de nasjonale mesterskapene, også kjent som NCAA-turneringen. Dette er en utslagsturnering der de beste lagene fra hver divisjon konkurrerer om tittelen som nasjonal mester. Turneringen arrangeres om våren for menn og om høsten for kvinner.
    </MKTypography>

  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Topp Programmer og Prestisjefylte Lag
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Universitetsvolleyball i Amerika skryter av en høy konkurransestandard, og mange lag utmerker seg i sine respektive divisjoner og konferanser. Imidlertid har noen lag tjent seg et spesielt rykte og historie på grunn av sin konsekvente prestasjon, talentfulle spillere og trenere, og en stor tilhengerskare. Her er noen av de beste programmene og prestisjefylte lagene innen universitetsvolleyball:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          UCLA Bruins: Representere University of California, Los Angeles, er UCLA Bruins kjent for sin sterke tradisjon både innen herre- og damevolleyball i NCAA Divisjon I. Historisk sett har de vunnet flere nasjonale mesterskap og har produsert noen av de mest respekterte spillerne innen sporten. Dette laget er en fremtredende medlem av Pac-12 Conference.
        </li>
        <li>
          Stanford Cardinal: Damevolleyballaget til Stanford University, lokalisert i Stanford, California, har utmerket seg ved å vinne et imponerende antall nasjonale mesterskap i NCAA Divisjon I, mer enn noe annet lag i deres kategori. De har jevnlig produsert talentfulle spillere og konkurrerer i Pac-12 Conference.
        </li>
        <li>
          Penn State Nittany Lions: Damevolleyballaget til Pennsylvania State University i University Park, Pennsylvania, har et rykte for fremragende prestasjoner i NCAA Divisjon I, med mange nasjonale mesterskap til deres navn. De er kjent for sine påfølgende titler og har trent fremragende spillere gjennom årene. Dette laget er et betydelig medlem av Big Ten Conference.
        </li>
        <li>
          BYU Cougars: Brigham Young University i Provo, Utah, har et respektert herrevolleyballag i NCAA Divisjon I, som har vunnet nasjonale mesterskap flere ganger. Laget har jevnlig utmerket seg i å utvikle topp volleyballspillere og er en del av Mountain Pacific Sports Federation (MPSF).
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Disse programmene har vært fremtredende og innflytelsesrike innen universitetsvolleyball i USA gjennom årene, kjent for sine sterke tradisjoner, talentfulle spillere og innflytelsesrike trenere.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  







<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 3: Fra College til Profesjonelt Nivå
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    For mange studentutøvere som spiller volleyball på universitetsnivå, er det en drøm å også spille profesjonell volleyball etter endt utdanning. Men hvor realistisk er denne drømmen, og hva er stegene du må ta for å oppnå den? I dette kapittelet vil vi diskutere veien til profesjonalisme, profesjonelle ligaer og karrieremuligheter for volleyballspillere.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Veien til Profesjonalisme
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Å spille volleyball på universitetsnivå er en flott måte å forbedre volleyballferdighetene dine på, tjene din akademiske grad og utvikle deg personlig. Det er imidlertid også et svært konkurransedyktig og krevende miljø der du må investere mye tid og energi både i sporten din og studiene dine. Derfor er det viktig å være realistisk med tanke på sjansene dine for å spille profesjonell volleyball etter din college-karriere.
      <br />
      <br />
      I motsetning til noen andre idretter som basketball eller fotball, er det ingen profesjonell volleyballiga i Amerika som rekrutterer direkte fra universiteter. Dette betyr at som nyutdannet spiller, må du søke etter andre muligheter for å starte din profesjonelle karriere, vanligvis i utlandet. Det finnes ulike profesjonelle volleyballigaer i Europa, Asia, Sør-Amerika og andre regioner der du kan spille for klubber som tilbyr deg lønn og andre fordeler.
      <br />
      <br />
      Men hvordan kommer du i kontakt med disse klubbene, og hvordan sikrer du deg at du skiller deg ut blant de mange andre spillerne som også leter etter en kontrakt? Her er noen tips som kan hjelpe deg med å finne en profesjonell volleyballklubb:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Opprett en nettprofil på en plattform som Volleyball Agency eller Volleyball Market, der du kan dele videoer, statistikk, referanser og kontaktinformasjon med potensielle klubber og agenter.
        </li>
        <li>
          Ta kontakt med dine tidligere trenere, lagkamerater eller andre kontakter som har erfaring med profesjonell volleyball eller har forbindelser med klubber eller agenter. De kan gi deg råd, gi anbefalinger eller introdusere deg for de rette personene.
        </li>
        <li>
          Delta på prøvespill, utstillinger eller treningsleirer organisert av profesjonelle klubber eller organisasjoner. Dette er arrangementer der du kan vise frem ferdighetene dine til speidere, trenere eller managere som leter etter nye spillere. Sørg for at du er godt forberedt, både fysisk og mentalt, og etterlater et positivt inntrykk.
        </li>
        <li>
          Vær fleksibel og villig til å flytte til et annet land eller kontinent. Å spille profesjonell volleyball betyr ofte å bo i en annen kultur, med et annet språk, klima og skikker. Dette kan være en betydelig utfordring, men også en flott mulighet til å lære og vokse som person.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Profesjonelle Ligaer og Karrieremuligheter
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Hvis du klarer å sikre deg en kontrakt med en profesjonell volleyballklubb, kan du nyte fordelene med å spille på det høyeste nivået av sporten. Du kan delta i spennende ligaer, konkurrere mot de beste spillerne i verden, reise til forskjellige land og byer, og tjene penger på lidenskapen din. Men hva er de viktigste profesjonelle ligaene du kan delta i, og hva er de andre karrieremulighetene for volleyballspillere?
      <br />
      <br />
      Her er noen av de mest prestisjefylte og lukrative profesjonelle volleyballigaene i verden:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Den italienske Serie A: Dette er den øverste divisjonen av italiensk volleyball, bestående av 14 lag for menn og 12 lag for kvinner. Serie A er kjent som en av de sterkeste og mest konkurransedyktige ligaene i verden, med mange internasjonale stjerner og høye lønninger. Mesteren av Serie A kvalifiserer også til Champions League, den fremste klubbturneringen i Europa.
        </li>
        <li>
          Den tyrkiske Süper Lig: Dette er den øverste divisjonen av tyrkisk volleyball, bestående av 12 lag for menn og 14 lag for kvinner. Süper Lig er også en av de ledende ligaene i verden, med betydelige investeringer, medieoppmerksomhet og talentfulle spillere. Mesteren av Süper Lig kvalifiserer også til Champions League.
        </li>
        <li>
          Den brasilianske Superliga: Dette er den øverste divisjonen av brasiliansk volleyball, bestående av 12 lag for menn og 12 lag for kvinner. Superligaen er den dominerende ligaen i Sør-Amerika, kjent for sin lidenskap, atmosfære og kvalitet. Mesteren av Superligaen kvalifiserer også til FIVB Volleyball Men's Club World Championship, den fremste klubbturneringen i verden.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      I tillegg til å spille i profesjonelle ligaer, er det andre muligheter for volleyballspillere å fortsette eller utvide karrieren sin. Her er noen eksempler:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Trener eller trener andre spillere: Hvis du ønsker å avslutte eller redusere spillekarrieren din, kan du også formidle kunnskapen og erfaringen din til andre spillere som trener eller trener. Du kan jobbe for en profesjonell klubb, et universitet, en skole eller en annen organisasjon som tilbyr volleyball. Du kan også etablere din egen volleyballskole eller akademi, der du kan trene og veilede unge talenter.
        </li>
        <li>
          Jobbe innen media eller industrien: Hvis du er interessert i media eller bransjer relatert til volleyball, kan du også bygge en karriere der. Du kan jobbe som kommentator, analytiker, journalist, skribent, fotograf eller videograf for ulike medier som dekker volleyball. Du kan også jobbe som representant, rådgiver, markedsfører eller designer for ulike selskaper som tilbyr volleyballprodukter eller -tjenester.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  



<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 4: Internasjonale Påvirkninger og Konkurranser
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Volleyball er en global idrett, spilt og fulgt av millioner av mennesker i forskjellige land og verdensdeler. Universitetsvolleyball i Amerika er ikke isolert, men påvirkes og utfordres av det internasjonale volleyballmiljøet. I dette kapittelet vil vi diskutere utenlandske påvirkninger og internasjonale konkurranser som spiller en rolle i universitetsvolleyball.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Utenlandske Påvirkninger
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Missouri Volleyball Stadium fylt med entusiastiske tilskuere."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          En kamp ved University of Missouri
        </figcaption>
      </figure>
      Universitetsvolleyball i Amerika skylder mye til de utenlandske påvirkningene som har beriket og forbedret idretten. En av de mest betydningsfulle påvirkningene er innvandringen av spillere, trenere og trenere fra andre land som brakte med seg sin kunnskap, erfaring og kultur til Amerika. Noen av disse innvandrerne har til og med blitt legender innen universitetsvolleyball, som for eksempel:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Arie Selinger: Han er en israelsk trener som flyttet til Amerika i 1969 for å trene det nasjonale kvinnelaget. Han ledet laget til deres første olympiske medalje, en sølvmedalje i 1984. Han trente også flere universitetslag, inkludert Long Beach State, der han ble fire ganger nasjonal mester.
        </li>
        <li>
          Andy Banachowski: Han er en polsk spiller som emigrerte til Amerika i 1951. Han spilte for UCLA, der han ble to ganger nasjonal mester. Han ble senere trener for UCLA kvinnelag, og vant seks nasjonale mesterskap og over 1000 kamper.
        </li>
        <li>
          Marv Dunphy: Han er en canadisk spiller som kom til Amerika i 1972 for å spille for Pepperdine University. Senere ble han trener for Pepperdine herrelag, og vant fire nasjonale mesterskap. Han trente også det nasjonale herrelaget, som vant gull under OL i 1988.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      En annen viktig påvirkning er populariteten og nivået på internasjonal volleyball, som gir inspirasjon og motivasjon for universitetsvolleyball. Internasjonal volleyball inkluderer ulike regionale og globale turneringer der de beste lagene og spillerne i verden møtes. Noen av disse turneringene inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          De olympiske leker: Dette er den største og mest prestisjefylte idrettsbegivenheten i verden, avholdt hvert fjerde år. Volleyball har vært en offisiell olympisk idrett siden 1964 for både menn og kvinner. USA har vunnet totalt 9 olympiske medaljer i volleyball, inkludert 3 gull, 3 sølv og 3 bronse.
        </li>
        <li>
          Verdensmesterskapet: Dette er den viktigste turneringen for nasjonale lag, avholdt hvert fjerde år. Den er organisert av International Volleyball Federation (FIVB), den globale styrende organet for volleyball. Verdensmesterskapet har vært arrangert siden 1949 for menn og siden 1952 for kvinner. USA har vunnet totalt 5 verdensmesterskapsmedaljer i volleyball, inkludert 2 gull, 1 sølv og 2 bronse.
        </li>
        <li>
          Verdenscupen: Dette er en annen turnering for nasjonale lag, avholdt hvert fjerde år. Den er også organisert av FIVB, men skiller seg fra verdensmesterskapet i antall deltakende lag (12 i stedet for 24) og kvalifikasjonssystemet (basert på kontinentale mesterskap). Verdenscupen har vært arrangert siden 1965 for menn og siden 1973 for kvinner. USA har vunnet totalt 7 verdenscupmedaljer i volleyball, inkludert 2 gull, 3 sølv og 2 bronse.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikanere på Verdensscenen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Universitetsvolleyball i Amerika har ikke bare lært fra internasjonal volleyball, men har også bidratt betydelig til internasjonal volleyball. Mange amerikanske spillere som konkurrerte på universitetsnivå, har også deltatt med suksess i internasjonale konkurranser, både for sine nasjonale lag og profesjonelle klubber. Her er noen eksempler på amerikanske spillere som utmerket seg på verdensscenen:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Karch Kiraly: Han er en av de største volleyballspillerne gjennom tidene, med suksess både i innendørs- og sandvolleyball. Han spilte for UCLA, der han ble tre ganger nasjonal mester. Han spilte også for det nasjonale laget, og vant to olympiske gullmedaljer i innendørs volleyball (1984 og 1988) og en i sandvolleyball (1996). Han spilte også profesjonelt i Italia, der han vant fire italienske titler og to Champions League-titler.
        </li>
        <li>
          Logan Tom: Hun er en av de største kvinnelige volleyballspillerne gjennom tidene, og spiller både som angriper og libero. Hun spilte for Stanford, der hun ble nasjonal mester og fire ganger All-American. Hun spilte også for det nasjonale laget, deltok i fire olympiske leker og vant to sølvmedaljer (2008 og 2012). Hun spilte også profesjonelt i forskjellige land, inkludert Brasil, Tyrkia, Japan og Kina.
        </li>
        <li>
          Matt Anderson: Han er en av de beste mannlige volleyballspillerne i den nåværende generasjonen, og kan spille både som motsatt og utenfor angriper. Han spilte for Penn State, der han ble nasjonal mester i 2008 og ble utnevnt til All-American i 2007 og 2008. Han spiller også for det nasjonale laget, og vant bronse under OL i 2016 og gull under verdenscupen i 2015. Han spilte også profesjonelt i Russland, der han vant tre russiske titler og to Champions League-titler.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>






                                  




<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Sportslegater og fremtiden for atleter
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sportslegater tilbyder talrige muligheder for unge atleter til at vise deres talent og udvikle deres færdigheder. En af de mest betydningsfulde muligheder er at opnå et sportslegat for at studere og konkurrere på kollegialt niveau. I dette kapitel vil vi diskutere vigtigheden af sportslegater og hvordan Sport Legater Amerika kan hjælpe dig med at realisere din drøm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vigtigheden af legater
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et sportslegat er økonomisk støtte, som et universitet tilbyder til en studerende-atlet for at kombinere studier og sport. Det kan dække omkostningerne til undervisning, bøger, bolig, måltider og andre udgifter. Derudover kan det også tilbyde fordele såsom akademisk vejledning, lægehjælp, træningsfaciliteter og udstyr.
      <br />
      <br />
      At opnå et sportslegat er konkurrencepræget, hvor mange faktorer spiller en rolle i tildelingsprocessen, herunder akademiske præstationer, atletisk præstation, personlighed, motivation, lederskab og tilpasningsevne. Derfor er det vigtigt at forberede sig godt og adskille sig fra konkurrencen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Legater Amerika Kan Hjælpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo af Sport Legater Amerika, der viser sportsudstyr og en eksamenshue, der repræsenterer fokus på sportslegater for studerende-atleter."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo af Sport Legater Amerika
        </figcaption>
      </figure>
      Sport Legater Amerika er en organisation specialiseret i at guide unge sports talenter til sportslegater. Med passionerede og erfarne eksperter støtter Sport Legater Amerika studerende-atleter på hvert trin af rejsen, fra indledende orientering til endelig placering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intake-interview for at diskutere den studerende-athlets ønsker, muligheder og forventninger.</li>
        <li>En professionel evaluering af den studerende-athlets atletiske og akademiske niveau.</li>
        <li>Et omfattende netværk af kontakter med trænere, universiteter og kollegier.</li>
        <li>Effektiv markedsføring af den studerende-athlets profil og videoer til potentielle trænere.</li>
        <li>Ekspertrådgivning om de bedste muligheder baseret på den studerende-athlets præferencer, mål og budget.</li>
        <li>Forhandling af de bedste legat tilbud på vegne af den studerende-atlet.</li>
        <li>Hjælp til ansøgning om nødvendige dokumenter, såsom visum, transkript, eksamensbevis og optagelsesprøver.</li>
        <li>Forberedelse til den studerende-atlets liv under deres legat, herunder kultur, sprog, regler og tips.</li>
        <li>Efterpleje og opfølgning under legatperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ønsker du at udforske legatmuligheder og opnå dine sportslige og akademiske mål? <Link to="/da/Tjenester-Produkter">Se det fulde udvalg</Link> af tjenester tilbudt af Sport Legater Amerika og planlæg en konsultation i dag.
    </MKTypography>
  </MKBox>
</MKBox>


          
          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;