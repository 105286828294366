// Materialkomponenter från @mui
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React-komponenter
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";
import "../../../../H1asH3.css"

// Bilder
import profilePicture from "assets/images/Profielfoto_Kay_Duit.jpeg";

function Profil() {
  return (
    <MKBox component="section" pt={{ xs: 20, sm: 20,  md: 6}} pb={{ xs: 6, sm: 0}}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
            <MKAvatar src={profilePicture} alt="Profilbild av Kay Duit, grundare och ägare" size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox 
                display="flex" 
                flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} 
                justifyContent="space-between" 
                alignItems="center" 
                mb={1}
              >
                <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Kay Duit</MKTypography>
                <MKBox display="flex" flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} gap={1}>
                <a href="https://www.linkedin.com/in/kayduit/" target="_blank" rel="noopener noreferrer">
                <MKSocialButton color="linkedin" style={{ width: '140px' }}>
                    <MKBox component="i" color="inherit" mr={0.5} className="fab fa-linkedin" />
                    LinkedIn
                  </MKSocialButton>
                  </a>
                  <a href="https://www.instagram.com/kayduit/" target="_blank" rel="noopener noreferrer">
                  <MKSocialButton color="instagram" style={{ width: '140px' }}>
                    <MKBox component="i" color="inherit" mr={0.5} className="fab fa-instagram" />
                    Instagram
                  </MKSocialButton>
                  </a>
                  <a href="/Podcast">
                  <MKSocialButton color="pinterest" style={{ width: '140px' }}>
                    <MKBox component="i" color="inherit" mr={0.5} className="fas fa-podcast" />
                    Podcast
                  </MKSocialButton>
                  </a>
                </MKBox>
              </MKBox>
              <br />
              <MKBox>
  <MKTypography variant="body1" fontWeight="light" color="text">
    En varm sommarkväll i Amerika stod jag vid sidan av fotbollsplanen och väntade på visslingen för att gå in på planen. Mitt hjärta bultade i halsen, inte på grund av den kommande matchen, utan på grund av insikten om hur långt jag hade kommit. Tre år tidigare hade jag tagit ett flyg från mitt hemland till USA, ett språng in i det okända i jakten på äventyr och möjligheten att förverkliga både mina idrottsliga och akademiska drömmar.
    <br /><br />
    Under de fyra åren hade jag upplevt mycket mer än bara fotbollsmatcher och studier. Det var sena middagar efter stora vinster, flygresor till bortamatcher i avlägsna stater, vänskapen jag hade byggt och de otaliga gångerna jag insåg hur mycket större världen var än den lilla platsen jag kom från.
    <br /><br />
    Den kvällen, medan nationalsången spelade och läktarna var fyllda av tusentals jublande fans, tänkte jag på alla unga studenter från hela världen som, precis som jag, drömde om sådana möjligheter. Jag lovade mig själv att när min resa i Amerika var över skulle jag sträcka ut en hjälpande hand till dessa aspirerande studenter, oavsett var de kom från, och hjälpa dem att förverkliga drömmar liknande mina egna.
  </MKTypography>
</MKBox>

              <MKTypography
                component="a"
                href="#"
                variant="body1"
                fontWeight="light"
                color="info"
                mt={3}
                sx={{
                  width: "max-content",
                  display: "flex",
                  alignItems: "center",
                  "& .material-icons-round": {
                    transform: `translateX(3px)`,
                    transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                  },
                  "&:hover .material-icons-round, &:focus .material-icons-round": {
                    transform: `translateX(6px)`,
                  },
                }}
              >
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profil;
