/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Track and Field1.jpg";
import Image2 from "assets/images/Sports_Images/Track and Field2.jpeg";
import Image3 from "assets/images/Sports_Images/Track and Field3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Atletismo e Estudar na América: Um Sonho que Pode se Tornar Realidade', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capítulo 1: História e Desenvolvimento do Atletismo no Nível Universitário', isSubheader: false },
    { id: '1.1', title: '1.1 Primeiros Anos e Origens', isSubheader: true },
    { id: '1.2', title: '1.2 Crescimento e Profissionalização', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capítulo 2: Atletismo Universitário Hoje', isSubheader: false },
    { id: '2.1', title: '2.1 Principais Competições e Conferências', isSubheader: true },
    { id: '2.2', title: '2.2 Principais Programas e Equipes Prestigiosas', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capítulo 3: Do Universitário ao Profissional', isSubheader: false },
    { id: '3.1', title: '3.1 O Caminho para o Profissionalismo', isSubheader: true },
    { id: '3.2', title: '3.2 Relação com o Atletismo Profissional', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capítulo 4: Influências e Oportunidades Internacionais', isSubheader: false },
    { id: '4.1', title: '4.1 Atletas Estrangeiros e Competições', isSubheader: true },
    { id: '4.2', title: '4.2 Atletas Americanos no Exterior', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 A Importância das Bolsas de Estudo', isSubheader: true },
    { id: '5.2', title: '5.2 Como a Sport Scholarships America Pode Ajudar', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contents
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Esconder</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                      key={section.id}
                      onClick={() => scrollTo(section.id)}
                      color="secondary"
                      variant="gradient"
                      sx={{ 
                        justifyContent: "flex-start",
                        pl: section.isSubheader ? 4 : 2,
                        textTransform: "none",
                        fontWeight: section.isSubheader ? "normal" : "bold",
                        borderTopLeftRadius: index === 0 ? '20px' : '0',
                        borderTopRightRadius: index === 0 ? '20px' : '0',
                        borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                        borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                        textAlign: "left",
                        width: '100%',
                      }}
                    >
                      {section.title}
                    </MKButton>
                  ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>























{/* Caixa principal para a introdução */}
<MKBox id="intro" mb={3}>
  {/* Título da introdução */}
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Atletismo e Estudar na América: Um Sonho que Pode se Tornar Realidade
  </MKTypography>
  {/* Texto da introdução */}
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Corredores em ação durante o Penn State Relays, um evento de atletismo universitário proeminente."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Ação no Penn State Relays.
      </figcaption>
    </figure>
    O atletismo é um dos esportes mais populares e significativos nos Estados Unidos. Milhões de pessoas praticam atletismo como um hobby, como parte de seu programa escolar ou universitário, ou como atletas profissionais. O atletismo também é um esporte que oferece muitas oportunidades para jovens talentos se desenvolverem, competirem e se destacarem no mais alto nível.
    <br/>
    <br/>
    Uma das melhores maneiras de aproveitar essas oportunidades é obtendo uma bolsa de atletismo nos EUA. Uma bolsa de atletismo é o apoio financeiro que você recebe de uma universidade para estudar e participar do atletismo lá. Com uma bolsa de atletismo, você não só pode obter seu diploma acadêmico, mas também participar de um dos programas de atletismo mais prestigiados e competitivos do mundo.
    <br/>
    <br/>
    Mas como você consegue uma bolsa de atletismo nos EUA? Quais são os benefícios e desafios de estudar e competir no nível universitário? E como você pode se preparar para uma carreira bem-sucedida como atleta após seus estudos? Essas são algumas das perguntas que responderemos neste artigo.
    <br/>
    <br/>
    Também vamos contar como a Sportbeursamerika.nl pode ajudá-lo a tornar seu sonho realidade. A Sportbeursamerika.nl é uma organização especializada em orientar jovens atletas para bolsas de estudo esportivas nos EUA. Tenho muita experiência em encontrar a melhor combinação entre atletas e universidades. Ofereço orientação pessoal, conselhos profissionais e suporte prático em cada etapa do processo.
    <br/>
    <br/>
    Se você está interessado em obter uma bolsa de atletismo nos EUA, este artigo é para você. Continue lendo e descubra tudo o que você precisa saber sobre atletismo no nível universitário nos EUA.
  </MKTypography>
  {/* Adicionar lista com marcadores ou outro conteúdo conforme necessário */}
</MKBox>

{/* Principais capítulos e subcapítulos com texto de espaço reservado */}
<MKBox id="Chapter 1" mb={3}>
  {/* Título do Capítulo 1 */}
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 1: História e Desenvolvimento do Atletismo no Nível Universitário
  </MKTypography>
  {/* Texto do Capítulo 1 */}
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O atletismo no nível universitário tem uma história longa e rica nos Estados Unidos. Começou no século XIX como uma forma de promover a saúde física e mental dos estudantes. Cresceu até se tornar um esporte importante que atraiu milhões de espectadores e produziu muitos atletas que mais tarde se tornaram famosos mundialmente.
  </MKTypography>

  {/* Subcapítulo 1.1 */}
  <MKBox id="1.1" mb={2}>
    {/* Título do Subcapítulo 1.1 */}
    <MKTypography variant="h4" mb={1}>
      1.1 Primeiros Anos e Origens
    </MKTypography>
    {/* Texto do Subcapítulo 1.1 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A primeira competição de atletismo em universidades ocorreu em 1864, quando estudantes de Yale e Oxford desafiaram-se mutuamente para uma partida em Londres. A competição consistiu em quatro eventos: corrida, salto em distância, salto em altura e salto com vara. Yale venceu a partida por 7-3.
      <br/>
      <br/>
      Essa partida inspirou outras universidades a formarem equipes de atletismo e competirem entre si. Em 1873, foi fundada a primeira associação interuniversitária de atletismo, a Intercollegiate Association of Amateur Athletes of America (IAAAA). A IAAAA organizou campeonatos anuais para universidades no leste dos EUA.
      <br/>
      <br/>
      O final do século XIX foi uma era importante para o desenvolvimento do atletismo. Foi marcado pela formalização de regras e pelo surgimento de figuras influentes como James E. Sullivan, co-fundador da Amateur Athletic Union nos EUA. Ele desempenhou um papel significativo na popularização do atletismo e na organização de eventos importantes. A introdução dos Jogos Olímpicos modernos em 1896 deu uma plataforma internacional ao esporte, levando à padronização de regras e eventos. Além disso, avanços tecnológicos, como melhorias em calçados e pistas de corrida, contribuíram para a evolução do atletismo durante esse período.
    </MKTypography>
  </MKBox>
  {/* Subcapítulo 1.2 */}
  <MKBox id="1.2" mb={2}>
    {/* Título do Subcapítulo 1.2 */}
    <MKTypography variant="h4" mb={1}>
      1.2 Crescimento e Profissionalização
    </MKTypography>
    {/* Texto do Subcapítulo 1.2 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      No século XX, o atletismo se tornou cada vez mais popular e importante nas universidades. O número de programas de atletismo, competições e bolsas de estudo aumentou. O nível dos atletas também aumentou, em parte devido ao melhor treinamento, instalações e treinamento.
      <br/>
      <br/>
      Um dos fatores que contribuíram para o crescimento e a profissionalização do atletismo no nível universitário foi a fundação da National Collegiate Athletic Association (NCAA) em 1906. A NCAA é uma organização que supervisiona as atividades esportivas de mais de 1000 universidades nos EUA. A NCAA regula, entre outras coisas, as regras, qualificações, bolsas de estudo e sanções para o atletismo no nível universitário.
      <br/>
      <br/>
      Outro fator que influenciou o desenvolvimento do atletismo no nível universitário foi a relação com o atletismo profissional. Muitos atletas que foram bem-sucedidos no nível universitário mais tarde fizeram a transição para o circuito profissional. Alguns exemplos são Jesse Owens, Carl Lewis, Jackie Joyner-Kersee e Michael Johnson.
    </MKTypography>
  </MKBox>
</MKBox>





                                              





                                  








{/* Caixa para o Capítulo 2 */}
<MKBox id="Chapter 2" mb={3}>
  {/* Título do Capítulo 2 */}
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 2: Atletismo Universitário Hoje
  </MKTypography>
  {/* Texto do Capítulo 2 */}
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O atletismo universitário é hoje um dos esportes mais populares e prestigiados nos Estados Unidos. A cada ano, milhares de atletas participam de várias competições e eventos, atraindo milhões de fãs e atenção da mídia. O atletismo universitário também oferece muitas oportunidades para os atletas se desenvolverem, se apresentarem e desfrutarem de seu esporte.
  </MKTypography>

  {/* Subcapítulo 2.1 */}
  <MKBox id="2.1" mb={2}>
    {/* Título do Subcapítulo 2.1 */}
    <MKTypography variant="h4" mb={1}>
      2.1 Principais Competições e Conferências
    </MKTypography>
    {/* Texto do Subcapítulo 2.1 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Sydney McLaughlin, se destacando como atleta pela Universidade de Kentucky, com uniforme de competição."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Sydney McLaughlin estudou na Universidade de Kentucky
        </figcaption>
      </figure>
      A principal organização que regula o atletismo universitário é a NCAA. A NCAA divide as universidades em três divisões, com base em seu tamanho, orçamento e nível de competição. A Divisão I é a mais alta, onde estão a maioria das principais universidades e atletas. As Divisões II e III são divisões inferiores, onde as universidades têm menos bolsas de estudo e recursos, mas colocam mais ênfase no desempenho acadêmico.
      <br/>
      <br/>
      Dentro de cada divisão, existem diferentes conferências, que são grupos de universidades que estão geograficamente ou historicamente conectadas. Cada conferência organiza seus próprios campeonatos e competições para seus membros. Algumas das conferências mais conhecidas são a Big Ten, a Pac-12, a SEC, a ACC e a Ivy League.
      <br/>
      <br/>
      Os principais eventos na temporada de atletismo universitário são os campeonatos da NCAA, realizados anualmente em março (indoor) e junho (outdoor). Esses campeonatos determinam quais universidades e atletas são os melhores em cada divisão e evento. Os campeonatos atraem muitos espectadores, patrocinadores e atenção da mídia.
    </MKTypography>
  </MKBox>
  {/* Subcapítulo 2.2 */}
  <MKBox id="2.2" mb={2}>
    {/* Título do Subcapítulo 2.2 */}
    <MKTypography variant="h4" mb={1}>
      2.2 Principais Programas e Equipes Prestigiadas
    </MKTypography>
    {/* Texto do Subcapítulo 2.2 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Existem muitas universidades conhecidas por seus excelentes programas de atletismo. Essas universidades têm uma longa tradição de sucesso, treinamento e instalações de alta qualidade, e um forte apelo aos atletas talentosos. Algumas dessas universidades são:
    </MKTypography>
    {/* Lista de universidades */}
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Oregon: Oregon é uma das universidades mais bem-sucedidas no atletismo, especialmente em corridas. Oregon ganhou mais de 30 títulos da NCAA e produziu muitos atletas famosos, como Steve Prefontaine, Alberto Salazar, Galen Rupp e Matthew Centrowitz.
        </li>
        <li>
          USC: USC é outra universidade de ponta no atletismo, com forte foco em corridas, saltos e lançamentos. USC ganhou mais de 25 títulos da NCAA e produziu muitos medalhistas olímpicos, como Quincy Watts, Andre De Grasse e Michael Norman.
        </li>
        <li>
          Arkansas: Arkansas é uma universidade dominante no atletismo, especialmente durante a temporada indoor. Arkansas ganhou mais de 40 títulos da NCAA, dos quais mais de 30 são indoor. Arkansas é conhecida por sua profundidade e versatilidade em vários eventos.
        </li>
        <li>
          Texas: Texas é uma das universidades mais versáteis no atletismo, com um bom equilíbrio entre equipes masculinas e femininas, e entre eventos individuais e de revezamento. Texas ganhou mais de 20 títulos da NCAA e produziu muitos atletas de classe mundial, como Sanya Richards-Ross, Trey Hardee, Michelle Carter e Courtney Okolo.
        </li>
      </ul>
    </MKTypography>
    {/* Texto adicional */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Essas universidades oferecem não apenas um alto nível de competição e treinamento para seus atletas, mas também uma ótima atmosfera e cultura. Os atletas fazem parte de uma comunidade unida que os apoia e os motiva a alcançar seu potencial.
    </MKTypography>
  </MKBox>
</MKBox>











                                              






                                  








{/* Caixa para o Capítulo 3 */}
<MKBox id="Chapter 3" mb={3}>
  {/* Título do Capítulo 3 */}
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 3: Do Universitário ao Profissional
  </MKTypography>
  {/* Texto do Capítulo 3 */}
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O atletismo universitário não é apenas um esporte, mas também um campo de treinamento para atletas aspirantes a uma carreira profissional. Muitos atletas que se destacaram no nível universitário posteriormente migraram para o circuito profissional. O atletismo universitário oferece aos atletas a oportunidade de se desenvolverem, adquirirem experiência e estabelecerem contatos com outros atletas, treinadores e organizações.
  </MKTypography>

  {/* Subcapítulo 3.1 */}
  <MKBox id="3.1" mb={2}>
    {/* Título do Subcapítulo 3.1 */}
    <MKTypography variant="h4" mb={1}>
      3.1 O Caminho para o Profissionalismo
    </MKTypography>
    {/* Texto do Subcapítulo 3.1 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Para muitos atletas, o objetivo é se tornar um atleta profissional após seus estudos. Isso significa praticar seu esporte como profissão e ganhar dinheiro por meio de performances, contratos e patrocínios. Para se tornar um atleta profissional, os atletas devem cumprir várias condições, como:
    </MKTypography>
    {/* Lista de condições */}
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Ter um alto nível de desempenho e potencial. Os atletas profissionais devem ser capazes de competir com os melhores atletas do mundo e continuar melhorando e se adaptando às condições em mudança.
        </li>
        <li>
          Manter boa saúde e forma física. Os atletas profissionais devem cuidar bem de seus corpos e se proteger contra lesões, doenças e excesso de treinamento. Eles também devem encontrar um bom equilíbrio entre treinamento, descanso e nutrição.
        </li>
        <li>
          Ter boa orientação e apoio. Os atletas profissionais devem poder contar com uma equipe de especialistas para ajudá-los com técnica, táticas, preparação mental, questões legais, questões financeiras e marketing.
        </li>
        <li>
          Ter um bom planejamento e estratégia. Os atletas profissionais precisam saber quais são seus objetivos, em quais competições desejam participar, como desejam se qualificar para eventos importantes e como desejam gerenciar sua carreira.
        </li>
      </ul>
    </MKTypography>
    {/* Texto adicional */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O atletismo universitário pode ajudar os atletas a atenderem a essas condições. Oferece aos atletas a chance de mostrar seu talento, aprimorar suas habilidades, aumentar sua experiência e expandir sua rede de contatos. O atletismo universitário também pode servir como um trampolim para o nível profissional, expondo os atletas a níveis elevados de competição, atenção da mídia e interesse público.
    </MKTypography>
  </MKBox>

  {/* Subcapítulo 3.2 */}
  <MKBox id="3.2" mb={2}>
    {/* Título do Subcapítulo 3.2 */}
    <MKTypography variant="h4" mb={1}>
      3.2 Relação com o Atletismo Profissional
    </MKTypography>
    {/* Texto do Subcapítulo 3.2 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O atletismo universitário tem uma relação próxima com o atletismo profissional. Os dois níveis se influenciam de várias maneiras, como:
    </MKTypography>
    {/* Lista de maneiras */}
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Desenvolvimento de talentos: O atletismo universitário é uma grande fonte de talentos para o atletismo profissional. Muitos atletas profissionais começaram como atletas universitários e lançaram as bases para seu sucesso posterior lá.
        </li>
        <li>
          Observação de talentos: Organizações esportivas profissionais monitoram de perto o atletismo universitário para descobrir e atrair potenciais estrelas. Eles oferecem contratos, patrocínios e orientação para atrair os atletas universitários a se tornarem profissionais.
        </li>
        <li>
          Intercâmbio de talentos: O atletismo universitário também está aberto a atletas profissionais que ainda estão estudando ou voltando à escola. Alguns atletas profissionais optam por concluir ou retomar seus estudos enquanto continuam sua carreira esportiva.
        </li>
      </ul>
    </MKTypography>
    {/* Texto adicional */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Portanto, o atletismo universitário não é apenas um ponto final, mas também um trampolim para os atletas que buscam uma carreira profissional. Oferece aos atletas a oportunidade de se prepararem para o nível profissional, mas também de explorarem outros caminhos.
    </MKTypography>
  </MKBox>
</MKBox>










                                              






                                  








{/* Caixa para o Capítulo 4 */}
<MKBox id="Chapter 4" mb={3}>
  {/* Título do Capítulo 4 */}
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 4: Influências e Oportunidades Internacionais
  </MKTypography>
  {/* Texto do Capítulo 4 */}
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O atletismo universitário não é apenas um esporte americano, mas também um esporte internacional. É influenciado por e oferece oportunidades para atletas de diversos países e culturas. O atletismo universitário é um esporte que reflete a diversidade e a interconexão do mundo.
  </MKTypography>

  {/* Subcapítulo 4.1 */}
  <MKBox id="4.1" mb={2}>
    {/* Título do Subcapítulo 4.1 */}
    <MKTypography variant="h4" mb={1}>
      4.1 Atletas Estrangeiros e Competições
    </MKTypography>
    {/* Texto do Subcapítulo 4.1 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Uma das maneiras pelas quais o atletismo universitário é internacional é através da presença e desempenho de atletas estrangeiros. Todo ano, centenas de atletas de outros países vêm para os Estados Unidos para estudar e competir no nível universitário. Esses atletas trazem seu próprio talento, estilo e cultura para as universidades americanas, enriquecendo a comunidade de atletismo com suas perspectivas e experiências.
      <br/>
      <br/>
      Os atletas estrangeiros também têm um impacto significativo nos resultados e classificações do atletismo universitário. Muitos atletas estrangeiros estão entre os melhores em seu evento, frequentemente ganhando medalhas, títulos e recordes. Alguns atletas estrangeiros são tão bons que superam ou desafiam os atletas americanos.
      <br/>
      <br/>
      Exemplos de atletas estrangeiros bem-sucedidos em competições universitárias americanas incluem:
    </MKTypography>
    {/* Lista de atletas estrangeiros */}
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Andre De Grasse: um velocista canadense que se destacou no Coffeyville Community College e na University of Southern California (USC), ganhando vários títulos NJCAA e NCAA em eventos de velocidade. Suas performances na USC estabeleceram sua reputação como um dos principais velocistas. Após sua carreira universitária, De Grasse conquistou várias medalhas olímpicas, incluindo ouro nos 200 metros nas Olimpíadas de Tóquio de 2020, mostrando-se como um dos principais velocistas do mundo.
        </li>
        <li>
          Kerron Clement: um atleta trinitário-americano que se destaca nos 400 metros com barreiras. Ele estudou na University of Florida, onde ganhou títulos da NCAA e se tornou um barreirista de classe mundial. Clement conquistou ouro nas Olimpíadas de 2008 e 2016 e tem vários campeonatos mundiais em seu nome, tornando-se um dos barreiristas mais bem-sucedidos de sua geração.
        </li>
      </ul>
    </MKTypography>
    {/* Texto adicional */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Os atletas estrangeiros influenciam não apenas o atletismo universitário, mas também outras competições e eventos internacionais. Muitos atletas estrangeiros que competiram no nível universitário depois participam dos Jogos Olímpicos, Campeonatos Mundiais ou outros campeonatos regionais ou continentais. Eles representam seu próprio país, mas também têm laços com sua universidade americana.
    </MKTypography>
  </MKBox>

  {/* Subcapítulo 4.2 */}
  <MKBox id="4.2" mb={2}>
    {/* Título do Subcapítulo 4.2 */}
    <MKTypography variant="h4" mb={1}>
      4.2 Atletas Americanos no Exterior
    </MKTypography>
    {/* Texto do Subcapítulo 4.2 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Outra maneira pela qual o atletismo universitário é internacional é através das oportunidades e desafios que oferece para os atletas americanos competirem no exterior. Muitos atletas americanos que competiram no nível universitário têm a chance de ampliar seus horizontes e testar suas habilidades contra outros atletas de diferentes países e culturas.
      <br/>
      <br/>
      Uma das principais oportunidades para os atletas americanos competirem no exterior é, é claro, os Jogos Olímpicos. Os Jogos Olímpicos são o maior e mais prestigiado evento esportivo do mundo, onde milhares de atletas de mais de 200 países se reúnem para competir por ouro, prata e bronze. Os Jogos Olímpicos também são uma chance para os atletas americanos representarem seu país, mostrarem seu patriotismo e compartilharem seu orgulho nacional.
      <br/>
      <br/>
      Outro evento importante para os atletas americanos competirem no exterior são os Campeonatos Mundiais de Atletismo. Os Campeonatos Mundiais de Atletismo são o evento de atletismo mais significativo após as Olimpíadas, onde os melhores atletas do mundo se encontram para competir pelo título mundial. Os Campeonatos Mundiais de Atletismo também são uma chance para os atletas americanos medirem seu nível, alimentarem suas rivalidades e estabelecerem sua reputação.
      <br/>
      <br/>
      Além desses eventos principais, também existem outras oportunidades para os atletas americanos competirem no exterior, como a Diamond League, o Continental Tour, os Revezamentos Mundiais de Atletismo ou outros campeonatos regionais ou continentais. Esses eventos oferecem aos atletas americanos a oportunidade de aumentar sua experiência, ganhos e base de fãs.
      <br/>
      <br/>
      O atletismo universitário desempenha um papel importante no desenvolvimento e sucesso dos atletas americanos que competem no exterior. Prepara os atletas para o nível internacional, fornecendo-lhes treinamento, coaching e competição de alta qualidade. O atletismo universitário também apoia os atletas que competem no exterior, oferecendo-lhes uma rede de contatos, patrocinadores e fãs.
    </MKTypography>
  </MKBox>
</MKBox>











                                              






                                  




<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    As bolsas de estudo esportivas oferecem inúmeras oportunidades para jovens atletas mostrarem seu talento e desenvolverem suas habilidades. Uma das oportunidades mais significativas é obter uma bolsa de estudos esportiva para estudar e competir no nível universitário. Neste capítulo, discutiremos a importância das bolsas de estudo esportivas e como a Sport Scholarships America pode ajudá-lo a realizar seu sonho.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 A Importância das Bolsas de Estudo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Uma bolsa de estudos esportiva é um suporte financeiro fornecido por uma universidade a um estudante-atleta para combinar estudos e esportes. Pode cobrir os custos de mensalidades, livros, moradia, refeições e outras despesas. Além disso, pode oferecer benefícios como tutoria acadêmica, cuidados médicos, instalações de treinamento e equipamentos.
      <br />
      <br />
      Obter uma bolsa de estudos esportiva é competitivo, com muitos fatores desempenhando um papel no processo de concessão, incluindo realizações acadêmicas, desempenho atlético, personalidade, motivação, liderança e adaptabilidade. Portanto, é importante se preparar bem e se destacar da concorrência.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Como a Sport Scholarships America Pode Ajudar
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo da Sport Scholarships America, mostrando equipamentos esportivos e um capelo de formatura, representando o foco em bolsas de estudo esportivas para estudantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo da Sport Scholarships America
        </figcaption>
      </figure>
      A Sport Scholarships America é uma organização especializada em orientar jovens talentos esportivos para bolsas de estudo esportivas. Com especialistas apaixonados e experientes, a Sport Scholarships America apoia estudantes-atletas em cada etapa da jornada, desde a orientação inicial até a colocação final. Seus serviços incluem:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Uma entrevista de admissão pessoal para discutir os desejos, possibilidades e expectativas do estudante-atleta.</li>
        <li>Uma avaliação profissional do nível acadêmico e atlético do estudante-atleta.</li>
        <li>Uma extensa rede de contatos com treinadores, universidades e faculdades.</li>
        <li>Promoção eficaz do perfil e vídeos do estudante-atleta para treinadores potenciais.</li>
        <li>Conselhos especializados sobre as melhores opções com base nas preferências, metas e orçamento do estudante-atleta.</li>
        <li>Negociação das melhores ofertas de bolsas de estudo em nome do estudante-atleta.</li>
        <li>Auxílio na solicitação de documentos necessários, como visto, histórico escolar, diploma e testes de admissão.</li>
        <li>Preparação para a vida do estudante-atleta durante a bolsa de estudos, incluindo cultura, idioma, regras e dicas.</li>
        <li>Acompanhamento e acompanhamento durante o período da bolsa de estudos.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Quer explorar oportunidades de bolsas de estudo e alcançar seus objetivos atléticos e acadêmicos? <Link to="/pt/Serviços-Produtos">Veja a gama completa</Link> de serviços oferecidos pela Sport Scholarships America e agende uma consulta hoje.
    </MKTypography>
  </MKBox>
</MKBox>



          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;