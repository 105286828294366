/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Volleybal1.avif";
import Image2 from "assets/images/Sports_Images/volleybal2.webp";
import Image3 from "assets/images/Sports_Images/Volleybal3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images
import { Link } from 'react-router-dom';



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Volleybollstipendium: Hur Kan Du Studera och Spela Volleyboll i Amerika?', isSubheader: false },
  
    { id: 'Kapitel 1', title: 'Kapitel 1: Historien och Utvecklingen av Volleyboll i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Tidiga År och Rollen för Utbildningsinstitutioner', isSubheader: true },
    { id: '1.2', title: '1.2 Tillväxt och Professionalisering', isSubheader: true },
  
    { id: 'Kapitel 2', title: 'Kapitel 2: Universitetsvolleyboll idag', isSubheader: false },
    { id: '2.1', title: '2.1 Stora Ligor och Konferenser', isSubheader: true },
    { id: '2.2', title: '2.2 Toppprogram och Prestigefyllda Lag', isSubheader: true },
  
    { id: 'Kapitel 3', title: 'Kapitel 3: Från College till Proffsen', isSubheader: false },
    { id: '3.1', title: '3.1 Vägen till Professionalism', isSubheader: true },
    { id: '3.2', title: '3.2 Professionella Ligor och Karriärmöjligheter', isSubheader: true },
  
    { id: 'Kapitel 4', title: 'Kapitel 4: Internationella Influenser och Tävlingar', isSubheader: false },
    { id: '4.1', title: '4.1 Utländska Influenser', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikaner på Världsarenan', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapitel 5: Idrottsstipendier och Framtiden för Idrottare', isSubheader: false },
    { id: '5.1', title: '5.1 Vikten av Stipendier', isSubheader: true },
    { id: '5.2', title: '5.2 Hur Sport Scholarships America Kan Hjälpa', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innehåll
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Dölj</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Visa</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
           <Grid item xs={12} md={9}>
























           <MKBox id="introduktion" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Volleybollstipendium: Hur Kan Du Studera och Spela Volleyboll i Amerika?
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Volleybollspel i Nebraska, besökt av mer än 92 000 åskådare."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        92 000 åskådare tittar på ett volleybollspel i Nebraska
      </figcaption>
    </figure>
    Volleyboll är en sport som kräver mycket av dina fysiska och mentala förmågor. Du måste reagera snabbt, samarbeta effektivt, tänka taktiskt och ha stark uthållighet. Dessutom är volleyboll en sport som ger mycket glädje och tillfredsställelse, särskilt när du spelar på en hög nivå. Men hur kan du, som volleybollstalang, följa dina idrottsliga ambitioner utan att försumma dina studier? Svaret är: genom att välja ett volleybollstipendium i Amerika.
    <br />
    <br />
    Ett volleybollstipendium är ett ekonomiskt stöd som erbjuds av ett amerikanskt universitet som gör att du kan studera och spela volleyboll där. Med ett volleybollstipendium kan du uppnå två mål samtidigt: du kan erhålla en erkänd examen från en prestigefylld utbildningsinstitution samtidigt som du vidareutvecklar dina volleybollsfärdigheter under professionell vägledning. Dessutom kan du njuta av den unika upplevelsen av att leva och lära i en annan kultur, med nya vänner och utmaningar.
    <br />
    <br />
    Men hur kan du kvalificera dig för ett volleybollstipendium? Vilka är kraven och förväntningarna? Och hur hittar du rätt universitet som passar dig? Det är alla frågor som Sportbeursamerika.nl kan assistera dig med. Sportbeursamerika.nl är specialisten inom sportstipendier i Amerika. Med många års erfarenhet och ett omfattande nätverk kan jag guida dig genom alla steg för att framgångsrikt ansöka om ett volleybollstipendium, från att skapa en onlineprofil till att hantera alla praktiska frågor.
    <br />
    <br />
    Om du vill veta mer om hur Sportbeursamerika.nl kan hjälpa dig att studera och spela volleyboll i Amerika, fortsätt läsa eller kontakta mig för ett förutsättningslöst inledande samtal. Jag skulle vara glad att berätta mer om möjligheterna och fördelarna med ett volleybollstipendium i Amerika.
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
 {/* Huvudkapitel och delkapitel med fiktiv text */}
<MKBox id="Kapitel 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Historien och Utvecklingen av Volleyboll i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Volleyboll är en sport som har funnits i mer än ett sekel, men den har upplevt betydande tillväxt i popularitet och professionalisering, särskilt under de senaste decennierna. I detta kapitel kommer vi att fördjupa oss i historien och utvecklingen av volleyboll i Amerika, från dess tidiga år till nutid.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidiga År och Rollen för Utbildningsinstitutioner
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Volleyboll uppfanns år 1895 av William G. Morgan, en lärare i idrott vid YMCA (Young Men’s Christian Association) i Holyoke, Massachusetts. Han syftade till att skapa ett spel som var mindre fysiskt krävande än basketboll, som hade uppfunnits ett år tidigare av hans kollega James Naismith. I början kallade han spelet "mintonette", men det blev snart känt som "volleyboll" eftersom spelarna var tvungna att volleya bollen över en nät.
      <br />
      <br />
      Spelet spred sig snabbt över hela USA och till andra länder, främst genom YMCA och andra utbildningsinstitutioner. År 1916 hölls en av de första officiella volleybollturneringarna vid University of California i Berkeley. År 1922 bildades United States Volleyball Association (USVBA), som senare blev USA Volleyball (USAV). Denna organisation blev ansvarig för att reglera och främja volleyboll i Amerika.
      <br />
      <br />
      År 1928 arrangerades den första nationella mästerskapet för män av USVBA, följt av den första nationella mästerskapet för kvinnor år 1949. År 1955 hölls den första herrarnas VM i Frankrike, där Amerika kom på sjätte plats. År 1956 ägde den första damernas VM rum i Frankrike, där Amerika kom på fjärde plats.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Tillväxt och Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Under 1960- och 1970-talet började volleyboll växa och professionaliseras i Amerika. Detta berodde delvis på införandet av nya regler, som antagandet av rally-point-systemet, där varje poäng räknas oavsett vem som servar. Volleyboll fick också mer uppmärksamhet genom TV-sändningar av olympiska spel, där volleyboll har varit en officiell sport sedan 1964.
      <br />
      <br />
      En av de ledande figurerna i volleybollens historia i Amerika är Doug Beal, som uppnådde framgång både som spelare och tränare. Han spelade för landslaget från 1970 till 1976 och vann guld vid Pan American Games 1975, bland andra framgångar. Han blev senare tränare för landslaget från 1977 till 1984 och ledde dem till deras första olympiska guld år 1984 i Los Angeles. Han var också involverad i grundandet av Major League Volleyball (MLV), en professionell damliga som existerade från 1987 till 1989.
      <br />
      <br />
      En annan betydelsefull händelse i volleybollens historia i Amerika var etableringen av Association of Volleyball Professionals (AVP) år 1983, som fokuserade på beachvolleyboll. Beachvolleyboll är en variant av volleyboll som spelas på sand med lag om två spelare. AVP organiserade turneringar och tävlingar för både män och kvinnor, vilket bidrog till att popularisera beachvolleyboll bland allmänheten. Några välkända beachvolleybollspelare inkluderar Karch Kiraly, Sinjin Smith, Randy Stoklos, Misty May-Treanor och Kerri Walsh Jennings.
    </MKTypography>
  </MKBox>
</MKBox>




                                  






<MKBox id="Kapitel 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Universitetsvolleyboll idag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Volleyboll är en av de mest populära och konkurrenskraftiga sporterna på universitetsnivå i Amerika. Varje år deltar tusentals lag i olika tävlingar och konferenser, vilket kulminerar i nationella mästerskap. I detta kapitel kommer vi att ge dig en översikt över de stora ligorna och konferenserna och diskutera några av de främsta programmen och prestigefyllda lagen inom universitetsvolleyboll.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Stora Ligor och Konferenser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Volleybollslag som firar en seger tillsammans."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Volleybollsspelare firar en seger
        </figcaption>
      </figure>
      Den primära organisationen som ansvarar för att organisera och reglera universitetsvolleyboll i Amerika är National Collegiate Athletic Association (NCAA). NCAA delar universiteten i tre divisioner baserat på deras storlek, budget och idrottsliga prestationer. Divisionerna är:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Division I: Detta är den högsta divisionen där de flesta stora och välkända universiteten är belägna. Lagen i denna division får mest uppmärksamhet, mediebevakning och stipendier. Det finns cirka 330 universitet i denna division, uppdelade i 32 konferenser. En konferens är en grupp av universitet som är geografiskt eller historiskt anslutna och tävlar mot varandra. Några exempel på konferenser i Division I inkluderar Big Ten, Pac-12, Southeastern Conference (SEC) och Atlantic Coast Conference (ACC).
        </li>
        <li>
          Division II: Detta är den mellersta divisionen där universiteten är något mindre och mindre kända än i Division I, men de upprätthåller ändå en hög nivå av volleyboll. Lagen i denna division får mindre uppmärksamhet, mediebevakning och stipendier än i Division I men mer än i Division III. Det finns cirka 300 universitet i denna division, uppdelade i 23 konferenser. Några exempel på konferenser i Division II inkluderar Great Lakes Valley Conference (GLVC), California Collegiate Athletic Association (CCAA) och Gulf South Conference (GSC).
        </li>
        <li>
          Division III: Detta är den lägsta divisionen där universiteten främst fokuserar på akademisk excellens snarare än idrottslig prestation. Lagen i denna division får inte stipendier för sina idrottare men erbjuder ekonomiskt stöd baserat på akademisk förtjänst. Det finns cirka 450 universitet i denna division, uppdelade i 43 konferenser. Några exempel på konferenser i Division III inkluderar New England Small College Athletic Conference (NESCAC), University Athletic Association (UAA) och Centennial Conference.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Varje år tävlar lag inom sin division och konferens i matcher för att kvalificera sig för de nationella mästerskapen, också kända som NCAA-turneringen. Detta är en utslagsturnering där de bästa lagen från varje division tävlar om titeln som nationell mästare. Turneringen hålls på våren för herrar och på hösten för damer.
    </MKTypography>

  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Toppprogram och Prestigefyllda Lag
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Universitetsvolleyboll i Amerika präglas av en hög nivå av konkurrens, och många lag utmärker sig inom sina respektive divisioner och konferenser. Vissa lag har dock fått ett speciellt rykte och historia på grund av deras konsekventa prestationer, talangfulla spelare och tränare samt en stor supporterkår. Här är några av de bästa programmen och prestigefyllda lagen inom universitetsvolleyboll:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          UCLA Bruins: Representanter för University of California, Los Angeles, är UCLA Bruins kända för sin starka tradition inom både herr- och damvolleyboll i NCAA Division I. Historiskt sett har de vunnit flera nationella mästerskap och har producerat några av de mest respekterade spelarna inom sporten. Detta lag är en framstående medlem i Pac-12 Conference.
        </li>
        <li>
          Stanford Cardinal: Damvolleybollslaget från Stanford University, beläget i Stanford, Kalifornien, har utmärkt sig genom att vinna ett imponerande antal nationella mästerskap i NCAA Division I, mer än något annat lag i deras kategori. De har konsekvent producerat talangfulla spelare och tävlar i Pac-12 Conference.
        </li>
        <li>
          Penn State Nittany Lions: Damvolleybollslaget från Pennsylvania State University i University Park, Pennsylvania, har ett rykte om sig för excellens i NCAA Division I, med talrika nationella mästerskap på sitt namn. De är kända för sina efterföljande titlar och har tränat enastående spelare under åren. Detta lag är en betydande medlem i Big Ten Conference.
        </li>
        <li>
          BYU Cougars: Brigham Young University i Provo, Utah, har ett respekterat herrvolleybollslag i NCAA Division I, som har vunnit nationella mästerskap flera gånger. Laget har konsekvent utmärkt sig i att utveckla toppvolleybollsspelare och är en del av Mountain Pacific Sports Federation (MPSF).
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dessa program har varit framträdande och inflytelserika inom universitetsvolleyboll i USA under åren, kända för sina starka traditioner, talangfulla spelare och inflytelserika tränare.
    </MKTypography>
  </MKBox>
</MKBox>
                                     






                                  







<MKBox id="Kapitel 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Från College till Proffsen
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    För många studentidrottare som spelar volleyboll på universitetsnivå är det en dröm att också spela professionell volleyboll efter examen. Men hur realistisk är denna dröm, och vilka steg måste du ta för att uppnå den? I detta kapitel kommer vi att diskutera vägen till professionalism, professionella ligor och karriärmöjligheter för volleybollspelare.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Vägen till Professionalism
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Att spela volleyboll på universitetsnivå är ett utmärkt sätt att förbättra dina volleybollsfärdigheter, ta din akademiska examen och utvecklas personligen. Det är dock också en mycket konkurrenskraftig och krävande miljö där du måste investera mycket tid och energi i både din sport och dina studier. Därför är det viktigt att vara realistisk om dina chanser att spela professionell volleyboll efter din collegekarriär.
      <br />
      <br />
      Till skillnad från vissa andra sporter som basket eller fotboll finns det ingen professionell volleybollsliga i Amerika som rekryterar direkt från universitet. Det betyder att som examensspelare måste du söka andra möjligheter att starta din professionella karriär, vanligtvis utomlands. Det finns olika professionella volleybollsligor i Europa, Asien, Sydamerika och andra regioner där du kan spela för klubbar som erbjuder dig en lön och andra förmåner.
      <br />
      <br />
      Men hur får du kontakt med dessa klubbar, och hur ser du till att sticka ut bland de många andra spelarna som också letar efter ett kontrakt? Här är några tips som kan hjälpa dig att hitta en professionell volleybollsklubb:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Skapa en onlineprofil på en plattform som Volleyball Agency eller Volleyball Market, där du kan dela dina videor, statistik, referenser och kontaktinformation med potentiella klubbar och agenter.
        </li>
        <li>
          Kontakta dina tidigare tränare, lagkamrater eller andra kontakter som har erfarenhet av professionell volleyboll eller har kopplingar till klubbar eller agenter. De kan ge dig råd, göra rekommendationer eller introducera dig för rätt personer.
        </li>
        <li>
          Delta i provspel, uppvisningar eller läger organiserade av professionella klubbar eller organisationer. Detta är evenemang där du kan visa upp dina färdigheter för scouter, tränare eller manager som letar efter nya spelare. Se till att du är väl förberedd, både fysiskt och mentalt, och lämna ett positivt intryck.
        </li>
        <li>
          Var flexibel och villig att flytta till ett annat land eller kontinent. Att spela professionell volleyboll innebär ofta att bo i en annan kultur, med ett annat språk, klimat och sedvänjor. Detta kan vara en betydande utmaning men också en bra möjlighet att lära och växa som person.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professionella Ligor och Karriärmöjligheter
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Om du lyckas säkra ett kontrakt med en professionell volleybollsklubb kan du njuta av fördelarna med att spela på den högsta nivån inom sporten. Du kan delta i spännande ligor, tävla mot de bästa spelarna i världen, resa till olika länder och städer och tjäna pengar på din passion. Men vilka är de främsta professionella ligorna du kan delta i, och vilka är de andra karriärmöjligheterna för volleybollspelare?
      <br />
      <br />
      Här är några av de mest prestigefyllda och lukrativa professionella volleybollsligorna i världen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Italienska Serie A: Detta är den högsta divisionen inom italiensk volleyboll och består av 14 lag för herrar och 12 lag för damer. Serie A är känd som en av de starkaste och mest konkurrenskraftiga ligorna i världen och har många internationella stjärnor och höga löner. Mästaren i Serie A kvalificerar sig också för Champions League, den främsta klubbturneringen i Europa.
        </li>
        <li>
          Turkiska Süper Lig: Detta är den högsta divisionen inom turkisk volleyboll och består av 12 lag för herrar och 14 lag för damer. Süper Lig är också en av de ledande ligorna i världen med betydande investeringar, medieuppmärksamhet och talangfulla spelare. Mästaren i Süper Lig kvalificerar sig också för Champions League.
        </li>
        <li>
          Brasilianska Superliga: Detta är den högsta divisionen inom brasiliansk volleyboll och består av 12 lag för herrar och 12 lag för damer. Superliga är den dominerande ligan i Sydamerika, känd för sin passion, atmosfär och kvalitet. Mästaren i Superliga kvalificerar sig också för FIVB Volleyboll Men's Club World Championship, den främsta klubbturneringen i världen.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Förutom att spela i professionella ligor finns det andra möjligheter för volleybollspelare att fortsätta eller utöka sina karriärer. Här är några exempel:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Coachning eller träning av andra spelare: Om du vill avsluta eller minska din spelarkarriär kan du också föra vidare dina kunskaper och erfarenheter till andra spelare som tränare eller tränare. Du kan arbeta för en professionell klubb, ett universitet, en skola eller en annan organisation som erbjuder volleyboll. Du kan också starta din egen volleybollskola eller akademi, där du kan träna och mentorera unga talanger.
        </li>
        <li>
          Arbeta inom media eller branscher relaterade till volleyboll: Om du är intresserad av media eller branscher relaterade till volleyboll kan du också bygga en karriär där. Du kan arbeta som kommentator, analytiker, journalist, författare, fotograf eller videografer för olika medier som täcker volleyboll. Du kan också arbeta som representant, rådgivare, marknadsförare eller designer för olika företag som erbjuder volleybollsprodukter eller tjänster.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>






                                  





<MKBox id="Kapitel 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationella Influenser och Tävlingar
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Volleyboll är en global sport som spelas och följs av miljontals människor i olika länder och världsdelar. Universitetsvolleyboll i Amerika är inte isolerad utan påverkas och utmanas av den internationella volleybollsgemenskapen. I detta kapitel kommer vi att diskutera de utländska influenser och internationella tävlingar som spelar en roll i universitetsvolleybollen.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Utländska Influenser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Missouri Volleyball Stadium fyllt med entusiastiska åskådare."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          En match på University of Missouri
        </figcaption>
      </figure>
      Universitetsvolleybollen i Amerika är mycket skyldig till de utländska influenser som har berikat och förbättrat sporten. En av de mest betydande influenserna är invandringen av spelare, tränare och tränare från andra länder som har fört med sig sin kunskap, erfarenhet och kultur till Amerika. Vissa av dessa invandrare har till och med blivit legender inom universitetsvolleybollen, såsom:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Arie Selinger: Han är en israelisk tränare som flyttade till Amerika 1969 för att träna det nationella damlandslaget. Han ledde laget till deras första olympiska medalj, en silvermedalj 1984. Han tränade också flera universitetslag, inklusive Long Beach State, där han blev fyra gånger nationalmästare.
        </li>
        <li>
          Andy Banachowski: Han är en polsk spelare som emigrerade till Amerika 1951. Han spelade för UCLA, där han blev två gånger nationalmästare. Han blev senare tränare för UCLA:s damlag och vann sex nationella mästerskap och över 1000 matcher.
        </li>
        <li>
          Marv Dunphy: Han är en kanadensisk spelare som kom till Amerika 1972 för att spela för Pepperdine University. Han blev senare tränare för Pepperdines herrlag och vann fyra nationella mästerskap. Han tränade också det nationella herrlandslaget, som vann guld på OS 1988.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      En annan viktig influens är populariteten och nivån på internationell volleyboll, som ger inspiration och motivation för universitetsvolleybollen. Internationell volleyboll inkluderar olika regionala och globala turneringar där de bästa lagen och spelarna i världen möts. Några av dessa turneringar inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Olympiska Spelen: Detta är den största och mest prestigefyllda idrottsevenemanget i världen, som hålls vart fjärde år. Volleyboll har varit en officiell olympisk sport sedan 1964 för både herrar och damer. USA har totalt vunnit 9 olympiska medaljer i volleyboll, inklusive 3 guld, 3 silver och 3 brons.
        </li>
        <li>
          VM: Detta är den viktigaste turneringen för landslag, som hålls vart fjärde år. Den organiseras av Internationella Volleybollförbundet (FIVB), den globala styrelseorganet för volleyboll. VM har hållits sedan 1949 för herrar och sedan 1952 för damer. USA har totalt vunnit 5 VM-medaljer i volleyboll, inklusive 2 guld, 1 silver och 2 brons.
        </li>
        <li>
          Världscupen: Detta är en annan turnering för landslag, som hålls vart fjärde år. Den organiseras också av FIVB men skiljer sig från VM i antalet deltagande lag (12 istället för 24) och kvalificeringssystemet (baserat på kontinentala mästerskap). Världscupen har hållits sedan 1965 för herrar och sedan 1973 för damer. USA har totalt vunnit 7 VM-medaljer i volleyboll, inklusive 2 guld, 3 silver och 2 brons.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikaner på Världsarenan
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Universitetsvolleybollen i Amerika har inte bara lärt av internationell volleyboll utan har också bidragit betydligt till internationell volleyboll. Många amerikanska spelare som tävlade på universitetsnivå har också framgångsrikt deltagit i internationella tävlingar, både för sina nationella lag och professionella klubbar. Här är några exempel på amerikanska spelare som har utmärkt sig på världsarenan:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Karch Kiraly: Han är en av de största volleybollspelarna genom tiderna och har utmärkt sig både inomhus och på beachvolleyboll. Han spelade för UCLA, där han blev tre gånger nationalmästare. Han spelade också för det nationella laget och vann två olympiska guldmedaljer i inomhusvolleyboll (1984 och 1988) och en i beachvolleyboll (1996). Han spelade också professionellt i Italien, där han vann fyra italienska titlar och två Champions League-titlar.
        </li>
        <li>
          Logan Tom: Hon är en av de största kvinnliga volleybollspelarna genom tiderna och spelar både som anfallare och libero. Hon spelade för Stanford, där hon blev nationalmästare och fyra gånger All-American. Hon spelade också för det nationella laget, deltog i fyra olympiska spel och vann två silvermedaljer (2008 och 2012). Hon spelade också professionellt i olika länder, inklusive Brasilien, Turkiet, Japan och Kina.
        </li>
        <li>
          Matt Anderson: Han är en av de bästa manliga volleybollspelarna från den nuvarande generationen och kan spela både som högerspiker och vänsterspiker. Han spelade för Penn State, där han blev nationalmästare 2008 och blev utvald till All-American 2007 och 2008. Han spelar också för det nationella laget, vann brons på OS 2016 och guld på VM 2015. Han spelade också professionellt i Ryssland, där han vann tre ryska titlar och två Champions League-titlar.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>





                                  






<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Idrottsstipendier och Framtiden för Idrottare
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrottsstipendier erbjuder många möjligheter för unga idrottare att visa upp sin talang och utveckla sina färdigheter. En av de mest betydelsefulla möjligheterna är att få ett idrottsstipendium för att studera och tävla på collegenivå. I det här kapitlet kommer vi att diskutera vikten av idrottsstipendier och hur Sport Scholarships America kan hjälpa dig att förverkliga din dröm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vikten av Stipendier
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ett idrottsstipendium är ekonomiskt stöd som tillhandahålls av ett universitet till en idrottare för att kombinera studier och idrott. Det kan täcka kostnaderna för undervisning, böcker, boende, måltider och andra utgifter. Dessutom kan det erbjuda fördelar som akademisk handledning, sjukvård, träningsanläggningar och utrustning.
      <br />
      <br />
      Att få ett idrottsstipendium är konkurrenskraftigt, med många faktorer som spelar en roll i tilldelningsprocessen, inklusive akademiska prestationer, idrottsprestationer, personlighet, motivation, ledarskap och anpassningsförmåga. Därför är det viktigt att förbereda sig väl och skilja sig från konkurrensen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hur Sport Scholarships America Kan Hjälpa
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotyp för Sport Scholarships America, med sportutrustning och en studentmössa, som representerar fokus på idrottsstipendier för idrottare."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotyp för Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America är en organisation specialiserad på att vägleda unga idrottstalanger till idrottsstipendier. Med passionerade och erfarna experter stöder Sport Scholarships America idrottare i varje steg av resan, från initial orientering till slutlig placering. Deras tjänster inkluderar:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intagningsintervju för att diskutera idrottarens önskemål, möjligheter och förväntningar.</li>
        <li>En professionell utvärdering av idrottarens akademiska och idrottsliga nivå.</li>
        <li>Ett omfattande nätverk av kontakter med tränare, universitet och högskolor.</li>
        <li>Effektiv marknadsföring av idrottarens profil och videor till potentiella tränare.</li>
        <li>Expertrådgivning om de bästa alternativen baserat på idrottarens preferenser, mål och budget.</li>
        <li>Förhandling om de bästa stipendieerbjudandena för idrottaren.</li>
        <li>Hjälp med att ansöka om nödvändiga dokument, såsom visum, betyg, examensbevis och antagningsprov.</li>
        <li>Förberedelse för idrottarens liv under stipendieperioden, inklusive kultur, språk, regler och tips.</li>
        <li>Uppföljning och uppföljning under stipendieperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vill du utforska möjligheter till stipendier och uppnå dina idrottsliga och akademiska mål? <Link to="/sv/Tjänster-Produkter">Se det fullständiga utbudet</Link> av tjänster som erbjuds av Sport Scholarships America och boka en konsultation idag.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;