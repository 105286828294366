import { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MKTypography from "components/MKTypography";

import MKBox from "components/MKBox";

import SAT from "assets/images/Producten/SAT_kaft.png";
import ACT from "assets/images/Producten/ACT_kaft.png";
import TOEFL from "assets/images/Producten/Toefl_kaft.png";
import Hoodie from "assets/images/Producten/Hoodie_front.png";
import Shirt from "assets/images/Producten/Shirt_front.png";
import { Link } from 'react-scroll';



import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

function Produkter() {
  const [aktivFlik, setAktivFlik] = useState(1);
  const [flikTyp, setFlikTyp] = useState("månadsvis");

  const hanteraFlikTyp = ({ currentTarget }, newValue) => {
    setAktivFlik(newValue);
    setFlikTyp(currentTarget.id);
  };

  
  return (
    <section id="produkterSektion">
      <Container sx={{ pb: { xs: 12, lg: 8 }, pt: 0 }}>
      <Grid container item xs={12} justifyContent="center" md={8} mx="auto" textAlign="center">
  {/* Omslut varje MKTypography i sitt eget Grid-element */}
  <Grid item xs={12}> {/* Full bredd för rubriken */}
    <MKTypography variant="h3" color="text">Produkter</MKTypography>
  </Grid>
  <Grid item xs={12}> {/* Full bredd för textkroppen */}
    <MKTypography variant="body2" color="text">
    Från studieguider till merchandise från Sport Scholarships America





</MKTypography>
  </Grid>
  <Grid
          container
          item
          xs={12}
          md={10}
          lg={8}
          justifyContent="center"
          textAlign="center"
          mx="auto"
          mt={6}
        >
          <AppBar position="static">
          <Tabs value={aktivFlik} onChange={hanteraFlikTyp}>
  <Tab
    id="månadsvis"
    label={
      <Link 
        to="tjansterSektion" 
        spy={true}
        smooth={true}
        duration={500}
        offset={-70}
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <MKBox py={0.5} px={2} color="inherit">
          Tjänster
        </MKBox>
      </Link>
    }
  />
  <Tab
    id="årsvis"
    label={
      <Link 
        to="produkterSektion" 
        spy={true}
        smooth={true}
        duration={500}
        offset={-70}
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <MKBox py={0.5} px={2} color="inherit">
          Produkter
        </MKBox>
      </Link>
    }
  />
</Tabs>
          </AppBar>
        </Grid>
          
          
        <Grid container spacing={3} mt={6} mb={6}>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="SAT Studieguide"
      image={SAT}
      description="Förbered dig för SAT"
      action={{ type: "internal", route: "/sv/Tjänster-Produkter/SAT-guide", label: "Beställ", color: "primary" }}
    />
  </Grid>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="ACT Studieguide"
      image={ACT}
      description="Förbered dig för ACT"
      action={{ type: "internal", route: "/sv/Tjänster-Produkter/ACT-guide", label: "Beställ", color: "primary" }}
    />
  </Grid>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="TOEFL Studieguide"
      image={TOEFL}
      description="Förbered dig för TOEFL"
      action={{ type: "internal", route: "/sv/Tjänster-Produkter/TOEFL-guide", label: "Beställ", color: "primary" }}
    />
  </Grid>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="Hoodie"
      image={Hoodie}
      description="Sport Scholarships America Hoodie"
      action={{ type: "internal", route: "/sv/Tjänster-Produkter/Hoodie", label: "Beställ", color: "primary" }}
    />
  </Grid>
  <Grid item xs={12} sm={6} lg={4} mb={6}>
    <CenteredBlogCard
      color="dark"
      title="T-shirt"
      image={Shirt}
      description="Sport Scholarships America T-shirt"
      action={{ type: "internal", route: "/sv/Tjänster-Produkter/T-shirt", label: "Beställ", color: "primary" }}
    />
  </Grid>
</Grid>

          
        
        </Grid>
      </Container>
    </section>
  );
}

export default Produkter;