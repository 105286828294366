/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


// @mui material components
import React, { useState } from 'react'; // Step 1: Import useState


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Logos/NJCAA.png";
import post3 from "assets/images/Logos/NAIA.png";



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {

  const articleStructure = [
    { id: 'intro', title: 'NCAA: Hjertet af College Sport i Amerika', isSubheader: false },
  
    { id: 'Kapitel 1', title: 'NCAA\'s Rødder og Vækst', isSubheader: true },
    { id: '1.1', title: 'NCAA\'s Tre Ansigter: Divisioner I, II og III', isSubheader: true },
    { id: '1.2', title: 'Akademikers Rolle', isSubheader: true },
  
    { id: 'Kapitel 2', title: 'Finanser, Medier og Marketing', isSubheader: true },
    { id: '2.1', title: 'Udfordringer og Fremtid', isSubheader: true },
    { id: '2.2', title: 'Afslutning', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
     <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="intro" mb={3}>


              
<MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>NCAA: Hjertet af College Sport i Amerika</MKTypography>
</MKBox>
<MKBox id="Kapitel 1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
NCAA's Rødder og Vækst
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
National Collegiate Athletic Association, bedre kendt som NCAA, har været centrum for det amerikanske collegiale sportslandskab i over et århundrede. Grundlagt i 1906 har denne organisation udviklet sig fra en lille, regulerende enhed til en magtfuld og indflydelsesrig organisation, der berører livet for tusinder af studerende-udøvere, trænere og sportsentusiaster.
<br />
<br />
I NCAA's tidlige år var fokus primært på at fastsætte regler og sikre sikkerheden i sporten. Men som tiden gik, udvidede NCAA's rolle sig, og de blev mere involveret i reguleringen af alle aspekter af collegial atletik, lige fra akademiske krav til økonomiske spørgsmål.
</MKTypography>

<MKBox id="1.1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
NCAA's Tre Ansigter: Divisioner I, II og III
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
NCAA er opdelt i tre divisioner, hver med deres eget unikke karakter og konkurrenceniveau:
<br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division I:{' '}
</MKTypography>
er kendt som højdepunktet af collegial sport, hvor skoler med de største budgetter, de bedste faciliteter og de mest talentfulde atleter mødes. Universiteterne i denne division er ofte store og har betydelige ressourcer til rådighed. De tilbyder fulde og delvise atletikstipendier og tiltrækker topatleter fra hele landet og verden. Konkurrencen er intens, og presset for at præstere er højt. Alligevel er det også en scene, hvor stjerner bliver født, og hvor unge atleters drømme kan blive til virkelighed. Divisionen er opdelt i tre kategorier: Football Bowl Subdivision (FBS), Football Championship Subdivision (FCS) og skoler uden fodbold på Division I-niveau.  <br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division II:{' '}
</MKTypography>
repræsenterer en mere afbalanceret tilgang til collegial sport, hvor atletik, akademiske præstationer og campusengagement går hånd i hånd. Universiteterne i denne division er ofte mindre end dem i Division I, men de tilbyder stadig et højt niveau af konkurrence. Atleter i Division II kan forvente støtte både på banen og i klasseværelset med det formål at tilbyde en fuld collegial oplevelse.  <br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division III:{' '}
</MKTypography>
lægger endelig vægt på den overordnede udvikling af studerende-udøveren. Sport er vigtig, men det er kun en del af den bredere collegiale oplevelse. Der tilbydes ingen atletikstipendier i Division III, men atleter har mulighed for at deltage i konkurrenceprægede sportsgrene, mens de også er fuldt integreret i det akademiske og sociale liv på campus.</MKTypography>

<MKBox id="1.2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Akademikers Rolle
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
NCAA tager sine studerende-udøveres akademiske præstationer meget alvorligt. Alle atleter skal opfylde strenge akademiske krav for at være berettiget til konkurrence, og universiteterne selv er ansvarlige for at tilbyde den nødvendige støtte for at sikre, at atleterne lykkes i klasseværelset. Dette inkluderer akademiske rådgivere, tutorer og specielle studieprogrammer, alle designet til at hjælpe studerende-udøvere med at balancere deres sportslige og akademiske forpligtelser.                <br />
<br />
</MKTypography>






                                  







<MKBox id="Kapitel 2" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Finanser, Medier og Marketing
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              NCAA er også en økonomisk sværvægter, med milliarder af dollars, der årligt strømmer gennem organisationen takket være tv-kontrakter, sponsorer og merchandise. Disse indtægter bruges til at finansiere legater, forbedre faciliteterne og forbedre den samlede atletikoplevelse for studenteratleter. Samtidig har populariteten af universitetssport ført til lukrative marketing- og medieaftaler, hvilket har gjort universitetsatletik til en fast bestanddel af den amerikanske sportskultur.

<br />
                </MKTypography>

                <MKBox id="2.1" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Udfordringer og Fremtid
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              På trods af sin succes står NCAA også over for betydelige udfordringer. Emner som amatørstatus for studenteratleter, atleters mentale helbred og stræben efter lighed inden for sport er blot nogle af de emner, organisationen skal tackle i sit arbejde for en bedre fremtid for universitetsatletikken.
<br />
<br />
NCAA forbliver en afgørende aktør i det amerikanske uddannelsessystem, og dens indflydelse på unge atleters liv og den bredere sportsverden er uomtvistelig. Mens den ser fremad mod fremtiden, er det klart, at organisationen vil forblive en nøglespiller i formningen af det universitære sportslandskab i Amerika.


                </MKTypography>

                <MKBox id="2.2" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Afslutning
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              National Collegiate Athletic Association er mere end bare en sportsorganisation; det er en afgørende del af det amerikanske uddannelses- og sportslandskab. Med sin rige historie, komplekse struktur og dybe indflydelse på livet for utallige individer forbliver NCAA en fascinerende og vital del af den amerikanske kultur. Dens fremtid vil utvivlsomt være fyldt med udfordringer og muligheder, men én ting er sikker: NCAA vil fortsætte med at stræbe efter excellence, både på banen og i klasseværelset.




<br />


                </MKTypography>


              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="NJCAA"
                    description="NJCAA: Springbrættet til Store Colleges og NCAA"
                    action={{
                      type: "internal",
                      route: "/da/NJCAA",
                      color: "info",
                      label: "Læs Mere",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="NAIA"
                    description="NAIA: En Alternativ Rute i College Sport i Amerika"
                    action={{
                      type: "internal",
                      route: "/da/NAIA",
                      color: "info",
                      label: "Læs Mere",
                    }}
                    />
                  </Grid>
                </Grid>
  
              </Grid>
          </Grid>
        </Container>
      </MKBox>
    );
  }
  
  export default Beschrijving;