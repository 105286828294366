/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState } from 'react'; // Step 1: Import useState


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";


// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Logos/NCAA.png";
import post3 from "assets/images/Logos/NJCAA.png";



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {

  const articleStructure = [
    { id: 'intro', title: 'NAIA: En Alternativ Rute i College Sport i Amerika', isSubheader: false },
  
    { id: 'Hoofdstuk 1', title: 'Oprindelse og Udvikling af NAIA', isSubheader: true },
    { id: '1.1', title: 'NAIA og dens Unikke Divisioner', isSubheader: true },
    { id: '1.2', title: 'Den Akademiske Mission', isSubheader: true },
  
    { id: 'Hoofdstuk 2', title: 'Økonomi, Medier og Synlighed', isSubheader: true },
    { id: '2.1', title: 'Udfordringer og Vejen Frem', isSubheader: true },
    { id: '2.2', title: 'Afslutning', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
     <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="intro" mb={3}>
              <MKTypography variant="h1" className="h1-as-h3" mb={2}>NAIA: En Alternativ Rute i College Sport i Amerika

</MKTypography>
              </MKBox>
              <MKBox id="Hoofdstuk 1" mb={3}>

              <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Oprindelse og Udvikling af NAIA
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              National Association of Intercollegiate Athletics, eller NAIA, er en afgørende enhed i det amerikanske collegiale sportslandskab, selvom den er mindre kendt end NCAA. Grundlagt i 1937 har NAIA udviklet sig til en anerkendt organisation, der giver studerende-atleter en unik mulighed for at realisere deres sportslige og akademiske ambitioner.

<br />
                <br />
                Fra begyndelsen har NAIA fokuseret på at tilbyde muligheder for mindre uddannelsesinstitutioner med fokus på at skabe en afbalanceret oplevelse for studerende-atleter. Organisationen fremmer både konkurrencedygtige sportsgrene og akademisk fremragende, idet den tror på, at de to kan gå hånd i hånd.

</MKTypography>
<MKBox id="1.1" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
                <br />
                NAIA og dens Unikke Divisioner
                </MKTypography>
                </MKBox>

                <MKTypography variant="body1" fontWeight="light" color="text"> 
                Modsat NCAA er NAIA mere kompakt organiseret med en opdeling i to divisioner, der begge tilbyder en rig blanding af sport og uddannelse:

<br /><br />
  <MKTypography component="span" variant="body1" fontWeight="bold">
    Division I:{' '}
  </MKTypography>
  I denne division finder vi skoler, der tilbyder et højere niveau af atletiske stipendier og hvor sportskonkurrencen er meget konkurrencepræget. På trods af skolernes mindre størrelse i forhold til NCAA Division I-institutioner giver NAIA Division I en platform for topatleter til at vise deres færdigheder og udvikle sig.
  <br />
  <br />
  <MKTypography component="span" variant="body1" fontWeight="bold">
    Division II:{' '}
  </MKTypography>
  Denne division lægger endnu mere vægt på balancen mellem sport, akademikere og campusengagement. Atletiske stipendier er mere begrænsede, men mulighederne for studerende-atleter for at udmærke sig både i deres sport og studier er rigelige.
  </MKTypography>

  <MKBox id="1.2" mb={3}>

  <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Den Akademiske Mission
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              NAIA lægger stor vægt på de akademiske præstationer hos dens studerende-atleter. Der er fastsat strenge akademiske standarder for at sikre deltagelse i sportsgrene, og de tilknyttede skoler er dedikerede til at tilbyde den nødvendige akademiske støtte. Dette resulterer i et miljø, hvor studerende-atleter opfordres til at stræbe efter fremragenhed i enhver del af deres liv.

                <br />
                </MKTypography>





                                  






                <MKBox id="Hoofdstuk 2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Økonomi, Medier og Synlighed
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
Selvom NAIA måske ikke kan konkurrere økonomisk med NCAA's gigantiske indtægter, spiller den stadig en vigtig rolle i finansiering af atletiske stipendier og forbedring af faciliteter. Derudover vinder NAIA synlighed gennem streamingtjenester og sociale medier, hvilket hjælper med at sprede budskabet om balanceret sport og akademikere.


<br />
</MKTypography>
<MKBox id="2.1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Udfordringer og Vejen Frem
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
Ligegyldigt med NCAA står NAIA over for udfordringer, herunder at navigere gennem kompleksiteten af amatørstatus for studerende-atleter og sikre lighed i sport. Dog er NAIA, med sin unikke position og forpligtelse til udvikling af studerende-atleter, godt positioneret til fortsat at have en positiv indvirkning på mange unge atleters liv.

</MKTypography>
<MKBox id="2.2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Afslutning
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
National Association of Intercollegiate Athletics tilbyder et værdifuldt og unikt alternativ i den amerikanske collegiale sportsverden. Med sit fokus på balance, akademisk fremragende og atletisk konkurrence forbliver NAIA en væsentlig kraft i formningen af fremtiden for studerende-atleter. Mens den ser fremad og fortsætter med at udvikle sig, forbliver dens engagement i udviklingen af unge mennesker ubøjeligt, hvilket giver en vedvarende og positiv indflydelse på den amerikanske sports- og uddannelsesverden.
<br />


</MKTypography>


              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="NCAA"
                    description="NCAA: Hjertet af College Sport i Amerika"
                    action={{
                      type: "internal",
                      route: "/da/NCAA",
                      color: "info",
                      label: "Læs Mere",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="NJCAA"
                    description="De Springplank naar Grote Colleges en de NCAA "
                    action={{
                      type: "internal",
                      route: "/da/NJCAA",
                      color: "info",
                      label: "Læs Mere",
                    }}
                  />
                </Grid>
              </Grid>

            </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;