/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";


// Material Kit 2 PRO React examples
import RaisedBlogCard from "examples/Cards/BlogCards/RaisedBlogCard";


// Images
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import NCAA from "assets/images/Logos/NCAA_Transparent.png"
import NJCAA from "assets/images/Logos/NJCAA_Transparent.png"
import NAIA from "assets/images/Logos/NAIA_Transparent.png"

function BlogPostThree() {
  const post1 = (NCAA)
  const post2 = (NJCAA)
  const post3 = (NAIA)

  return (
    <>
<MKBox position="relative" variant="gradient" bgColor="dark" mt={{ xs: 0, lg: 8 }} mx={-2}>
        <MKBox
          component="img"
          src={bgPattern}
          alt="background-pattern"
          position="absolute"
          top={0}
          left={0}
          width={{ xs: "100%", lg: "100%" }}
          height={{ xs: "auto", lg: "auto" }}
          opacity={0.6}
        />
        <Container>
          <Grid container justifyContent="center" sx={{ pt: 6, pb: 18 }}>
            <Grid item xs={12} md={7} sx={{ textAlign: "center" }}>
            
              <MKTypography variant="h2" color="white" mb={1}>
              Oportunidades em todos os níveis   </MKTypography>
              <MKTypography variant="body2" color="white">
              Em todos os esportes, em todos os níveis acadêmicos: explore um mundo de oportunidades em inúmeras universidades americanas para cada estudante-atleta.                            </MKTypography>
            </Grid>
          </Grid>
        </Container>
      </MKBox>

    <MKBox component="section" mt={-10}>
     

      
      <Container>
        
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4} mb={{ xs: 3, lg: 0 }}>
            <RaisedBlogCard
              image={post1}
              category={{ color: "primary", label: "house" }}
              title="NCAA"
              subtitle="National Collegiate Athletic Association"
              description=
              "A NCAA gerencia esportes universitários nos EUA e supervisiona competições para mais de 1.100 universidades afiliadas. Como uma organização líder, desempenha um papel crucial na paisagem esportiva colegial americana."
            
              action={{ 
                type: "internal",
                route: "/pt/NCAA",
                color: "info",
              label: "Mais informações", }}
              imageStyle={{ marginTop: "-44px" }}
              shadowTop="-30px"  // Move the shadow down by 50px to match the image's movement

            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} mb={{ xs: 3, lg: 0 }}>
            <RaisedBlogCard
              image={post2}
              category={{ color: "primary", label: "house" }}
              title="NJCAA"
              subtitle="Associação Nacional de Faculdades Júnior"
              description="A NJCAA regula esportes em faculdades comunitárias nos EUA e gerencia competições para centenas de instituições afiliadas. Como uma organização central, é essencial para o esporte de dois anos nas faculdades americanas, onde o talento é desenvolvido para níveis mais altos."
              
              action={{ 
                type: "internal", 
                route: "/pt/NJCAA",
                color: "info",
                label: "Mais informações", }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} mb={{ xs: 3, lg: 0 }}>
              <RaisedBlogCard
                image={post3}
                category={{ color: "primary", label: "house" }}
                title="NAIA"
                subtitle="Associação Nacional de Atletismo Interuniversitário"
                description=
                "A NAIA desempenha um papel central na gestão de esportes em universidades de pequeno porte nos EUA, com centenas de instituições afiliadas. Como uma organização líder, desempenha um papel vital na paisagem esportiva interuniversitária americana, promovendo talento e integridade."
                action={{ 
                  type: "internal", 
                  route: "/pt/NAIA", 
                  color: "info",
                  label: "Mais informações"}}
                imageStyle={{ marginTop: "50px" }}
                shadowTop="none"
                paddingBelowImage="120px"
              />
            </Grid>
          </Grid>
        </Container>
    </MKBox>
    </>
  );
}

export default BlogPostThree;
