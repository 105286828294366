/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Swimming1.jpg";
import Image2 from "assets/images/Sports_Images/Swimming2.jpeg";
import Image3 from "assets/images/Sports_Images/Swimming3.avif";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Svømming i Amerika: Hvordan Få et Idrettsstipend og Bli En Del av En Prestisjetung Idrettshistorie', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapittel 1: Historien og Utviklingen av Svømming i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Tidlige År og Universitetsrollen', isSubheader: true },
    { id: '1.2', title: '1.2 Vekst og Profesjonalisering', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapittel 2: Collegiate Svømming i Dag', isSubheader: false },
    { id: '2.1', title: '2.1 Viktige Konkurranser og Konferanser', isSubheader: true },
    { id: '2.2', title: '2.2 Topp Programmer og Prestisjefylte Lag', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapittel 3: Fra College til Profesjonelt Nivå', isSubheader: false },
    { id: '3.1', title: '3.1 Veien til Profesjonalitet', isSubheader: true },
    { id: '3.2', title: '3.2 Profesjonell Svømming og OL', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapittel 4: Internasjonale Påvirkninger og Konkurranser', isSubheader: false },
    { id: '4.1', title: '4.1 Utenlandske Påvirkninger', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanere i Utlandet', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapittel 5: Idrettsstipender og Fremtiden til Utøvere', isSubheader: false },
    { id: '5.1', title: '5.1 Viktigheten av Stipender', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Scholarships America Kan Hjelpe', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Svømming i Amerika: Hvordan Få et Idrettsstipend og Bli En Del av En Prestisjetung Idrettshistorie
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Svømmestadion fylt med tilskuere under en collegekonkurranse."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Et stadion under en kamp
      </figcaption>
    </figure>
    Svømming er en av de mest populære og konkurransedyktige idrettene i verden. Millioner av mennesker nyter svømming, både rekreasjonelt og profesjonelt. Men visste du at svømming i Amerika har nådd et helt annet nivå? Svømming i Amerika er ikke bare en idrett; det er en kultur, en tradisjon og en livsstil.
    <br />
    <br />
    I denne artikkelen vil vi fortelle deg alt om utviklingen av svømming som en konkurranseidrett i USA, betydningen av collegiat svømming for fremme av sporten, og hvordan du får et idrettsstipend for å studere og svømme i Amerika. Vi vil også dykke ned i rollen til Sportbeursamerika.nl, en organisasjon som hjelper unge svømmere med å realisere drømmene sine.
    <br />
    <br />
    Svømming er en idrett som har blitt praktisert i århundrer, men det var ikke før på 1800-tallet at den begynte å utvikle seg som en organisert og regulert aktivitet. De første moderne olympiske lekene i 1896 inkluderte svømmekonkurranser som en av hovedbegivenhetene, og siden den gang har svømming vært en fast bestanddel av den olympiske agendaen.
    <br />
    <br />
    Men hvorfor er svømming så populært og vellykket i Amerika? Hva gjør det så spesielt og unikt? Svaret ligger i universitetenes rolle i å fremme svømming som en akademisk og idrettslig disiplin. Amerikanske universiteter tilbyr ikke bare utdanning av høy kvalitet, men også utmerkede fasiliteter, trening og veiledning for student-idrettsutøvere som ønsker å spesialisere seg i svømming. Dessuten gir de økonomisk støtte i form av idrettsstipend, slik at talentfulle svømmere kan realisere sitt potensial uten å bekymre seg for kostnadene ved utdannelsen.
    <br />
    <br />
    Imidlertid er idrettsstipender ikke enkle å få. De er svært konkurransedyktige og krever høy grad av dedikasjon, disiplin og prestasjon. Derfor er det viktig å være godt forberedt og søke profesjonell hjelp når du søker om et idrettsstipend. Det er her Sportbeursamerika.nl kommer inn i bildet.
    <br />
    <br />
    Sportbeursamerika.nl er en organisasjon spesialisert på å veilede unge svømmere til idrettsstipender i Amerika. De har et team av eksperter som kan hjelpe deg med alle aspekter av prosessen, som å velge riktig universitet, forberede CVen og motivasjonsbrevet ditt, forberede deg til opptaksprøver og intervjuer, ordne visumet og forsikringen din, og mye mer. De har også forbindelser med hundrevis av universiteter og trenere i Amerika, slik at de kan matche deg med de beste alternativene for din profil og dine mål.
    <br />
    <br />
    Så hvis du drømmer om å studere og svømme i Amerika, er Sportbeursamerika.nl din beste allierte. De har allerede hjulpet hundrevis av svømmere med å realisere drømmene sine, og de kan hjelpe deg også. Men før du kontakter dem, la oss først se på historien og utviklingen av svømming i Amerika og hvorfor det er en fantastisk mulighet for deg.
  </MKTypography>
</MKBox>

<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 1: Historien og Utviklingen av Svømming i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Svømming har en lang og rik historie i Amerika. Alt begynte på slutten av 1800-tallet da universiteter begynte å danne sine egne svømmelag og konkurranser. Dette markerte begynnelsen på en tradisjon som ville føre til fremveksten av svømming som en nasjonal sport.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidlige År og Universitetsrollen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Det første universitetet som etablerte et offisielt svømmelag var Harvard i 1878. Kort tid etter fulgte andre prestisjefylte universiteter som Yale, Princeton, Columbia og Cornell. Disse universitetene organiserte interkollegiale kamper og turneringer som fikk offentlig og medieoppmerksomhet. De introduserte også nye regler og teknikker for svømming, inkludert brystsvømming, ryggsvømming og butterfly.
      <br />
      <br />
      I 1902 ble Intercollegiate Swimming Association (ISA) grunnlagt, og ble den første nasjonale organisasjonen for collegiat svømming. ISA organiserte årlige mesterskap for menn, der de beste lagene og individuelle svømmere ble hedret. I 1916 ble National Collegiate Athletic Association (NCAA) etablert som den overordnede organisasjonen for all collegiat idrett. NCAA tok ansvar for å organisere de nasjonale mesterskapene for collegiat svømming, som fortsetter den dag i dag.
      <br />
      <br />
      Collegiat svømming begrenset seg ikke til menn. Kvinner begynte også å konkurrere på collegiatnivå tidlig på 1900-tallet, selv om de møtte flere restriksjoner og diskriminering. De første kvinnelige collegiat-svømmelagene ble dannet i 1914 ved University of Chicago og i 1916 ved University of Wisconsin. I 1922 ble Women’s Intercollegiate Swimming Association (WISA) grunnlagt, og fungerte som ekvivalenten til ISA for kvinner. WISA organiserte også årlige mesterskap for kvinner, som fikk betydelig popularitet.
      <br />
      <br />
      Collegiat svømming spilte en avgjørende rolle i å fremme svømming som en profesjonell sport i Amerika. Mange collegiat svømmere gikk videre til profesjonelle ligaer og de olympiske lekene, der de representerte landet sitt og vant medaljer. Noen av de mest kjente navnene inkluderer Johnny Weissmuller, Mark Spitz, Matt Biondi, Janet Evans, Michael Phelps og Katie Ledecky.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Vekst og Profesjonalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      I tillegg til collegiat svømming, oppstod det andre former for profesjonell svømming i Amerika. En av dem var masters svømming, som fokuserte på voksne svømmere som ønsket å forbedre ferdighetene sine eller opprettholde formen. Masters svømming begynte i 1970 med etableringen av United States Masters Swimming (USMS), en organisasjon som tilbyr lokale klubber, konkurranser og programmer for voksne svømmere. Masters svømming vokste raskt til å bli en global bevegelse, med millioner av medlemmer i over 100 land.
      <br />
      <br />
      En annen form for profesjonell svømming var åpen vann-svømming, som foregikk i naturlige vannkropper som innsjøer, elver og hav. Åpen vann-svømming var en utfordrende og eventyrlig sport som kreve utholdenhet, navigasjonsferdigheter og tilpasningsevne. Åpen vann-svømming fikk mer anerkjennelse i 2008 da det ble lagt til som en olympisk øvelse. Noen av de mest kjente åpen vann-svømmerne inkluderer Gertrude Ederle, Lynne Cox, Maarten van der Weijden og Ous Mellouli.
      <br />
      <br />
      Profesjonell svømming nådde sitt høydepunkt under de olympiske lekene, der amerikanske svømmere dominerte. Siden 1896 har Amerika vunnet over 500 medaljer i svømming, inkludert mer enn 200 gullmedaljer. Dette gjør Amerika til langt den mest suksessrike nasjonen i olympisk svømming.
      <br />
      <br />
      Men hvordan kan du også bli en del av denne glorifiserte idrettshistorien? Hvordan kan du studere og svømme i Amerika med et idrettsstipend? Det vil vi fortelle deg i neste kapittel.
    </MKTypography>
  </MKBox>
</MKBox>

                                              





                                  






<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 2: Collegiate Svømming i Dag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Som du har sett, har collegiate svømming en lang og stolt historie i Amerika. Men hva med nåtiden? Hvordan er collegiate svømming organisert og strukturert i dag? Hva er de viktigste konkurransene og konferansene? Hvilke er de beste programmene og prestisjetunge lagene? La oss ta en nærmere titt.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Viktige Konkurranser og Konferanser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Katie Ledecky svømmer for Stanford i en collegekonkurranse."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Katie Ledecky i aksjon som svømmer for Stanford
        </figcaption>
      </figure>
      Collegiate svømming i Amerika reguleres primært av NCAA, som har tre divisjoner: Division I, Division II og Division III. Hver divisjon har sine egne regler, kriterier og mesterskap. Generelt sett er Division I den mest konkurransedyktige og prestisjefylte divisjonen, med de beste svømmerne, trenerne og fasilitetene. Division II og III er mindre konkurransedyktige, men tilbyr fortsatt høykvalitets utdanning og idrett.
      <br />
      <br />
      Innenfor hver divisjon er det ulike konferanser, som er grupper av universiteter som er geografisk eller historisk tilknyttet. Hver konferanse organiserer sine egne mesterskap, der de beste lagene og individuelle svømmere kvalifiserer seg til NCAA nasjonale mesterskap. Noen av de mest kjente konferansene innen collegiate svømming inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Southeastern Conference (SEC), som består av 14 universiteter i sørøstlige USA, som Alabama, Florida, Georgia, Kentucky, LSU, Tennessee og Texas A&M. SEC er kjent for sin dominans innen svømming, spesielt i herrearrangementer. SEC har vunnet mer enn 50 nasjonale titler i svømming, med over 40 av dem fra herrelaget på Florida.
        </li>
        <li>
          Pacific-12 Conference (Pac-12), som består av 12 universiteter på vestkysten av Amerika, inkludert Arizona, California, Stanford, UCLA, USC og Washington. Pac-12 er kjent for sin fremragende svømming, spesielt i kvinners arrangementer. Pac-12 har vunnet mer enn 40 nasjonale titler i svømming, med over 30 av dem tilhørende kvinneteamet på Stanford.
        </li>
        <li>
          Big Ten Conference (Big Ten), som består av 14 universiteter i Midtvesten og nordøstlige USA, som Indiana, Michigan, Minnesota, Ohio State, Penn State og Wisconsin. Big Ten er kjent for sin mangfoldighet og balanse i svømming, med sterke lag i både herre- og kvinnearrangementer. Big Ten har vunnet mer enn 30 nasjonale titler i svømming, med over 20 av dem sikret av herrelaget på Michigan.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dette er bare noen få eksempler på de mange konferansene som eksisterer innen collegiate svømming. Hver konferanse har sin egen kultur, rivaliseringer og tradisjoner, som bidrar til spenningen innen collegiate svømming.
    </MKTypography>

  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Topp Programmer og Prestisjefylte Lag
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      I tillegg til konferanser er det individuelle universiteter som skiller seg ut på grunn av sine topp programmer og prestisjetunge svømmelag. Disse universitetene tilbyr ikke bare utmerket utdanning til studentene sine, men gir også førsteklasses trening, coaching og fasiliteter til idrettsutøverne sine. De tiltrekker også mange talentfulle svømmere fra både nasjonale og internasjonale bakgrunner som ønsker å studere og svømme på høyeste nivå.
      <br />
      <br />
      Noen av disse toppuniversitetene inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Stanford University: Stanford er et av de mest anerkjente universitetene i verden både akademisk og idrettslig. Stanford har et av de beste kvinnesvømmeprogrammene i Amerika, med over 10 nasjonale titler og mer enn 100 olympiske medaljer. Stanford har også et sterkt herresvømmeprogram, med over 5 nasjonale titler og mer enn 50 olympiske medaljer. Noen kjente alumni fra Stanford inkluderer John Hencken, Pablo Morales, Jenny Thompson, Summer Sanders, Misty Hyman, Janet Evans og Katie Ledecky.
        </li>
        <li>
          University of California: California er et annet toppuniversitet både akademisk og idrettslig. California har et av de beste herresvømmeprogrammene i Amerika, med over 10 nasjonale titler og mer enn 100 olympiske medaljer. California har også et sterkt kvinnesvømmeprogram, med over 5 nasjonale titler og mer enn 50 olympiske medaljer. Noen kjente alumni fra California inkluderer Matt Biondi, Anthony Ervin, Natalie Coughlin, Nathan Adrian, Dana Vollmer og Ryan Murphy.
        </li>
        <li>
          University of Texas: Texas er et av de største og mest innflytelsesrike universitetene i Amerika, med et sterkt rykte innen akademisk og idrettslig. Texas har et av de beste herresvømmeprogrammene i Amerika, med over 15 nasjonale titler og mer enn 100 olympiske medaljer. Texas har også et sterkt kvinnesvømmeprogram, med over 5 nasjonale titler og mer enn 50 olympiske medaljer. Noen kjente alumni fra Texas inkluderer Josh Davis, Aaron Peirsol, Ian Crocker, Brendan Hansen, Ricky Berens og Joseph Schooling.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dette er bare noen få av de mange toppuniversitetene innen collegiate svømming. Hvert universitet har sin egen historie, filosofi og visjon som bidrar til utviklingen av svømmere.
      <br />
      <br />
      Som du kan se, er collegiate svømming i dag en svært dynamisk og konkurransedyktig idrett som tilbyr mange muligheter for student-idrettsutøvere som ønsker å utmerke seg både akademisk og idrettslig. Men hvordan kan du få tilgang til disse mulighetene? Hvordan kan du få et idrettsstipend for å studere og svømme i Amerika? Det vil vi fortelle deg i neste kapittel.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  








<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 3: Fra College til Profesjonelt Nivå
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Å studere og svømme i Amerika er ikke bare en flott opplevelse, men også en fantastisk mulighet til å kickstarte karrieren din som profesjonell svømmer. Mange collegiate svømmere går over til det profesjonelle nivået, der de representerer landet sitt i internasjonale konkurranser og OL. Men hvordan tar du steget fra college til det profesjonelle nivået? Hva er utfordringene og fordelene med denne overgangen? La oss ta en nærmere titt.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Veien til Profesjonalitet
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      For å gjøre overgangen fra college til det profesjonelle nivået, må du først oppfylle kravene til NCAA. NCAA har strenge regler angående amatørstatusen til student-idrettsutøvere, noe som betyr at du ikke kan tjene eller akseptere penger for idrettsprestasjonene dine. Dette betyr at du ikke kan inngå sponsoravtaler, avtaler om pengepremier eller andre former for kompensasjon mens du fortsatt studerer og svømmer på collegenivå.
      <br />
      <br />
      Imidlertid, når du har fullført studiene dine eller bestemmer deg for å avslutte utdannelsen din, kan du gi avkall på amatørstatusen din og bli en profesjonell svømmer. Dette betyr at du kan bli med i en profesjonell svømmeorganisasjon, for eksempel USA Swimming, som er den nasjonale styringsorganisasjonen for svømming i Amerika. USA Swimming gir deg muligheten til å delta i profesjonelle svømmekonkurranser som Pro Swim Series, US Open, US Nationals og Olympic Trials. Disse konkurransene er designet for å forbedre ferdighetene dine, øke rangeringen din og kvalifisere deg for internasjonale arrangementer.
      <br />
      <br />
      Som profesjonell svømmer kan du også dra nytte av sponsoravtaler, pengepremier og andre former for kompensasjon for idrettsprestasjonene dine. Dette kan bidra til å forbedre økonomien din, støtte livsstilen din og øke motivasjonen din. Imidlertid kommer det å bli en profesjonell svømmer også med flere ansvar og utfordringer. For eksempel må du administrere din egen treningsplan, ernæringsplan, reiseutgifter, medisinsk behandling og skatt. Du må også håndtere økt konkurranse, press og forventninger fra deg selv og andre.
      <br />
      <br />
      Derfor er det avgjørende å være godt forberedt på overgangen fra college til det profesjonelle nivået. Du må ikke bare ha utmerkede svømmeferdigheter, men også ha en sterk mental holdning, klare mål og god støtte. Du må også være realistisk med tanke på sjansene og mulighetene dine, og være villig til å jobbe hardt og gjøre ofre.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Profesjonell Svømming og OL
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      En av de største drømmene til enhver profesjonell svømmer er å delta i OL, den mest prestisjefylte og betydningsfulle sportsbegivenheten i verden. OL tilbyr ikke bare en sjanse til å representere landet ditt og vinne medaljer, men også til å skrive historie og bli en legende.
      <br />
      <br />
      Imidlertid må du for å kvalifisere deg for OL først oppfylle kravene til Den Internasjonale Olympiske Komité (IOC) og Det Internasjonale Svømmeforbundet (FINA). Disse organisasjonene har strenge regler angående kvalifiseringskriterier, antall deltakere per land, antidopingkontroller og andre aspekter av olympisk svømming.
      <br />
      <br />
      En av de viktigste kravene for å kvalifisere seg til OL er å oppnå en bestemt tidsstandard i en anerkjent konkurranse. Denne tidsstandarden fastsettes av FINA basert på gjennomsnittet av de 16 beste prestasjonene globalt i hver disiplin de siste fire årene. Tidsstandarden varierer etter kjønn, aldersgruppe og svømmestil.
      <br />
      <br />
      Et annet viktig krav for å kvalifisere seg til OL er å bli valgt av ditt nasjonale svømmeforbund, for eksempel USA Swimming. Hvert nasjonale svømmeforbund har sin egen utvelgelsesprosess, som vanligvis innebærer en serie prøver eller mesterskap der de beste svømmerne velges til å representere landet. Hvert nasjonale svømmeforbund har også et begrenset antall plasser per disiplin, avhengig av antall kvalifiserte svømmere og kvotene som er fastsatt av IOC og FINA.
      <br />
      <br />
      Hvis du klarer å oppfylle disse kravene og kvalifisere deg til OL, er du en av de heldige få som får muligheten til å være en del av verdens største sportsarrangement. Du vil kunne konkurrere mot de beste svømmerne i verden, utfordre deg selv og potensielt vinne en medalje og skrive deg inn i historiebøkene.
      <br />
      <br />
      Som du kan se, er overgangen fra college til det profesjonelle nivået en spennende og givende reise som tilbyr mange muligheter for svømmekarrieren din. Men hvordan kan du starte denne reisen? Hvordan kan du få et idrettsstipend for å studere og svømme i Amerika? Det vil vi fortelle deg i neste kapittel.
    </MKTypography>
  </MKBox>
</MKBox>





                                  









<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 4: Internasjonale Påvirkninger og Konkurranser
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Svømming i Amerika er ikke bare en nasjonal sport, men også en internasjonal. Svømming i Amerika påvirkes av og påvirker andre land og kulturer, hver med sine egne stiler, teknikker og tradisjoner innen svømming. Svømming i Amerika utfordres og berikes også av andre internasjonale konkurranser, og gir muligheter til å lære av og konkurrere mot svømmere fra hele verden. Men hvordan oppsto og utviklet disse internasjonale påvirkningene og konkurransene seg? Hvordan påvirker de collegiate- og profesjonell svømming i Amerika? La oss ta en nærmere titt.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Utenlandske Påvirkninger
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Svømmer i aksjon under NCAA nasjonalmesterskapet."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          NCAA nasjonalmesterskapet
        </figcaption>
      </figure>
      Svømming er en universell sport som praktiseres i nesten hvert land i verden. Hvert land har sin egen historie, kultur og identitet innen svømming, noe som gjenspeiles i hvordan de svømmer, trener og coacher. Noen land har hatt en betydelig innflytelse på svømming i Amerika, både positivt og negativt.
      <br />
      <br />
      En av de positive påvirkningene er Australia, som er en av Amerikas største rivaler og allierte innen svømming. Australia har en lang og stolt tradisjon innen svømming, med mer enn 100 olympiske medaljer og over 20 verdensrekorder. Australia er kjent for sin innovasjon og kreativitet innen svømming, og introduserer nye teknikker, strategier og metoder. Australia har også produsert mange talentfulle og karismatiske svømmere, som Dawn Fraser, Shane Gould, Ian Thorpe, Grant Hackett, Libby Trickett og Cate Campbell.
      <br />
      <br />
      Australia har påvirket svømming i Amerika ved å skape sunn konkurranse og samarbeid som motiverer begge land til å forbedre seg og lære av hverandre. Mange australske og amerikanske svømmere har trent, studert eller jobbet sammen, og forbedret ferdighetene, kunnskapen og vennskapene sine. Noen eksempler inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          I 1976 trente Mark Spitz, den amerikanske stjernen som vant syv gullmedaljer i München, sammen med John Devitt, den australske legenden som vant to gullmedaljer i Melbourne og Roma.
        </li>
        <li>
          I 1988 ble Matt Biondi, den amerikanske sensasjonen som vant syv medaljer i Seoul, ferdig med studiene ved University of California med en grad i politisk økonomi.
        </li>
        <li>
          I 2016 ble Joseph Schooling, den singaporeanske overraskelsen som vant den første gullmedaljen for landet sitt på 100m butterfly i Rio de Janeiro, ferdig med studiene ved University of Texas med en grad i økonomi.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dette er bare noen få eksempler på hvordan utenlandske påvirkninger har påvirket svømming i Amerika. Mange flere land og kulturer har satt sitt preg på svømming, inkludert Japan, Kina, Russland, Frankrike, Brasil og Sør-Afrika. Svømming i Amerika er ikke bare en nasjonal sport, men også en internasjonal som stadig utvikler seg og endres gjennom interaksjon med andre land og kulturer.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikanere i Utlandet
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Svømming i Amerika påvirkes ikke bare av og påvirker andre land og kulturer, men utfordres og berikes også av andre internasjonale konkurranser. Svømming i Amerika gir muligheten til å lære av og konkurrere mot svømmere fra hele verden, hver med sine egne stiler, teknikker og tradisjoner innen svømming. Svømming i Amerika tilbyr også muligheten til å reise til og oppleve forskjellige steder, mennesker og kulturer, som utvider horisonten din og endrer perspektivet ditt.
      <br />
      <br />
      En av de viktigste internasjonale konkurransene der amerikanske svømmere deltar, er Verdensmesterskapet i svømming, som holdes hvert annet år av FINA. Verdensmesterskapet i svømming er en begivenhet som omfatter alle svømmedisipliner, inkludert langbane, kortbane, åpent vann, synkronsvømming og stuping. Verdensmesterskapet i svømming gir mulighet til å konkurrere mot verdens beste svømmere, forbedre rangeringen din og øke kvalifiseringen din for andre arrangementer.
      <br />
      <br />
      En annen betydelig internasjonal konkurranse som amerikanske svømmere deltar i, er Verdenscupen i svømming, som holdes årlig av FINA. Verdenscupen i svømming er en serie med kortbanearrangementer som finner sted i forskjellige byer rundt om i verden. Verdenscupen i svømming er en mulighet til å konkurrere mot forskjellige motstandere, teste ferdighetene dine og tjene premiepenger.
      <br />
      <br />
      En tredje betydelig internasjonal konkurranse der amerikanske svømmere deltar, er Panamerikanske leker, som holdes hvert fjerde år av Panamerikanske sportsorganisasjon (PASO). Panamerikanske leker er en multisportbegivenhet som samler utøvere fra Nord-, Sør- og Mellom-Amerika. Panamerikanske leker er en sjanse til å representere kontinentet ditt, vise solidaritet og vinne medaljer.
      <br />
      <br />
      Dette er bare noen få eksempler på de mange internasjonale konkurransene der amerikanske svømmere deltar. Det er mange flere internasjonale konkurranser innen svømming, som Samveldelekene, EM, Asialekene og Afrikalekene.
    </MKTypography>
  </MKBox>
</MKBox>
                                






                                  






<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 5: Idrettsstipender og Fremtiden til Utøvere
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrettsstipender gir mange muligheter for unge utøvere til å vise sitt talent og utvikle sine ferdigheter. En av de mest betydningsfulle mulighetene er å få et idrettsstipend for å studere og konkurrere på college-nivå. I dette kapittelet vil vi diskutere viktigheten av idrettsstipender og hvordan Sport Scholarships America kan hjelpe deg med å oppfylle drømmen din.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Viktigheten av Stipender
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et idrettsstipend er økonomisk støtte gitt av et universitet til en studentutøver for å kombinere studier og idrett. Det kan dekke kostnadene for skolepenger, bøker, bolig, måltider og andre utgifter. I tillegg kan det tilby fordeler som akademisk veiledning, medisinsk hjelp, treningsfasiliteter og utstyr.
      <br />
      <br />
      Å få et idrettsstipend er konkurransedyktig, med mange faktorer som spiller en rolle i tildelingsprosessen, inkludert akademiske prestasjoner, idrettslige resultater, personlighet, motivasjon, lederegenskaper og tilpasningsevne. Det er derfor viktig å forberede seg godt og skille seg ut fra konkurransen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Scholarships America Kan Hjelpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo for Sport Scholarships America, som viser sportsutstyr og en eksamenskappe, symboliserer fokuset på idrettsstipender for studentutøvere."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo for Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America er en organisasjon spesialisert i å veilede unge idrettstalenter til idrettsstipender. Med lidenskapelige og erfarne eksperter støtter Sport Scholarships America studentutøvere på hvert trinn i reisen, fra første orientering til endelig plassering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig inntaksintervju for å diskutere studentutøverens ønsker, muligheter og forventninger.</li>
        <li>En profesjonell evaluering av studentutøverens akademiske og idrettslige nivå.</li>
        <li>Et omfattende nettverk av kontakter med trenere, universiteter og høyskoler.</li>
        <li>Effektiv markedsføring av studentutøverens profil og videoer til potensielle trenere.</li>
        <li>Ekspertråd om de beste alternativene basert på studentutøverens preferanser, mål og budsjett.</li>
        <li>Forhandlinger om de beste stipendtilbudene på vegne av studentutøveren.</li>
        <li>Hjelp til å søke om nødvendige dokumenter, som visum, transkripsjon, diploma og opptaksprøver.</li>
        <li>Forberedelse til studentutøverens liv under stipendet, inkludert kultur, språk, regler og tips.</li>
        <li>Oppfølging og oppfølging under stipendperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vil du utforske stipendmuligheter og oppnå dine idrettslige og akademiske mål? <Link to="/no/Tjenester-Produkter">Se det komplette utvalget</Link> av tjenester som tilbys av Sport Scholarships America og planlegg en konsultasjon i dag.
    </MKTypography>
  </MKBox>
</MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;