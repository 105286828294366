// Materielle komponenter fra @mui
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Komponenter fra Material Kit 2 PRO React
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";
import "../../../../H1asH3.css"

// Bilder
import profilePicture from "assets/images/Profielfoto_Kay_Duit.jpeg";

function Profil() {
  return (
    <MKBox component="section" pt={{ xs: 20, sm: 20,  md: 6}} pb={{ xs: 6, sm: 0}}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
            <MKAvatar src={profilePicture} alt="Profilbilde av Kay Duit, grunnlegger og eier" size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox 
                display="flex" 
                flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} 
                justifyContent="space-between" 
                alignItems="center" 
                mb={1}
              >
                <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Kay Duit</MKTypography>
                <MKBox display="flex" flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} gap={1}>
                <a href="https://www.linkedin.com/in/kayduit/" target="_blank" rel="noopener noreferrer">
                <MKSocialButton color="linkedin" style={{ width: '140px' }}>
                    <MKBox component="i" color="inherit" mr={0.5} className="fab fa-linkedin" />
                    LinkedIn
                  </MKSocialButton>
                  </a>
                  <a href="https://www.instagram.com/kayduit/" target="_blank" rel="noopener noreferrer">
                  <MKSocialButton color="instagram" style={{ width: '140px' }}>
                    <MKBox component="i" color="inherit" mr={0.5} className="fab fa-instagram" />
                    Instagram
                  </MKSocialButton>
                  </a>
                  <a href="/Podcast">
                  <MKSocialButton color="pinterest" style={{ width: '140px' }}>
                    <MKBox component="i" color="inherit" mr={0.5} className="fas fa-podcast" />
                    Podcast
                  </MKSocialButton>
                  </a>
                </MKBox>
              </MKBox>
              <br />
              <MKBox>
  <MKTypography variant="body1" fontWeight="light" color="text">
    En varm sommerkveld i Amerika sto jeg ved sidelinjen på fotballbanen, og ventet på fløytesignalet for å entre banen. Hjertet mitt banket i halsen, ikke på grunn av den kommende kampen, men på grunn av erkjennelsen av hvor langt jeg hadde kommet. Tre år tidligere hadde jeg tatt et fly fra hjemlandet mitt til USA, et sprang ut i det ukjente, på jakt etter eventyr og muligheten til å realisere både mine idrettslige og akademiske drømmer.
    <br /><br />
    I løpet av de fire årene hadde jeg opplevd mye mer enn bare fotballkamper og studier. Det var sene middager etter store seire, flyreiser til bortekamper i fjerne stater, vennskapene jeg hadde bygget, og de utallige gangene jeg hadde innsett hvor mye større verden var enn det lille stedet jeg kom fra.
    <br /><br />
    Den kvelden, mens nasjonalsangen spilte og tribunene var fylt med tusenvis av jublende fans, tenkte jeg på alle de unge studentene fra hele verden som, akkurat som meg, drømte om slike muligheter. Og jeg forpliktet meg til å hjelpe disse aspirerende studentene, uavhengig av hvor de kom fra, med å realisere drømmene sine, etter at min reise i Amerika var over.
  </MKTypography>
</MKBox>

              <MKTypography
                component="a"
                href="#"
                variant="body1"
                fontWeight="light"
                color="info"
                mt={3}
                sx={{
                  width: "max-content",
                  display: "flex",
                  alignItems: "center",
                  "& .material-icons-round": {
                    transform: `translateX(3px)`,
                    transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                  },
                  "&:hover .material-icons-round, &:focus .material-icons-round": {
                    transform: `translateX(6px)`,
                  },
                }}
              >
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profil;
