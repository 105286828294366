/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Page du produit : https://www.creative-tim.com/product/material-kit-pro-react
* Droits d'auteur 2023 Creative Tim (https://www.creative-tim.com)

Codé par www.creative-tim.com

 =========================================================

* L'avis de droits d'auteur ci-dessus et cet avis d'autorisation doivent être inclus dans toutes les copies ou portions substantielles du logiciel.
*/

import { useState } from "react";

// Composants matériels @mui
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Composants Material Kit 2 PRO React
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton"; // Importer MKButton
import { Link } from 'react-router-dom';

// Composants de la page de location
import FaqCollapse from "Languages/Francais/pages/Homepage/LandingPage/sections/FAQfunction";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <MKBox component="section" py={8}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
              Questions fréquemment posées
            </MKTypography>
            <MKBox mb={2}>
              <MKTypography variant="body2" align="center" color="text">
              Tout ce que vous voulez savoir : réponses aux questions les plus fréquemment posées sur les études et le sport en Amérique et sur mes services. Si votre question ne figure pas dans la liste, veuillez nous contacter.              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={10}>
          <FaqCollapse
   title="Combien coûte l'étude en Amérique ?"
   open={collapse === 1}
   onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
>
   Les frais de scolarité et le coût des études en Amérique varient considérablement en fonction de l'université, du programme d'études et de l'emplacement. En moyenne, les étudiants internationaux peuvent s'attendre à des dépenses annuelles totales d'environ 30 000 $ à plus de 70 000 $ ou plus. Cependant, il est à noter que de nombreux étudiants peuvent couvrir une partie importante (voire la totalité) de ces coûts grâce à des bourses sportives, des bourses académiques ou une combinaison des deux. Les universités américaines offrent souvent des bourses sportives aux athlètes talentueux, fournissant un soutien financier en échange de leur participation aux programmes sportifs collégiaux. Cela permet aux étudiants-athlètes de poursuivre leurs objectifs académiques et sportifs sans de lourdes charges financières.
</FaqCollapse>

<FaqCollapse
   title="Quelle est la différence entre un collège et une université en Amérique ?"
   open={collapse === 2}
   onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
>
   La terminologie peut être déroutante, en particulier pour les personnes en dehors des États-Unis. En pratique, "collège" et "université" ne désignent pas réellement la même chose. Une "université" est un terme global pour une institution d'enseignement supérieur qui propose des programmes à tous les niveaux, y compris les licences, les maîtrises et les doctorats.

   Un "collège", en revanche, fait généralement partie d'une université et se concentre principalement sur les programmes de premier cycle, tels que les licences. En d'autres termes, un collège est un département au sein d'une université spécifiquement dédié à l'éducation de premier cycle. Les universités proposent généralement à la fois des programmes de premier cycle et des programmes de troisième cycle, tandis que les collèges se concentrent sur le niveau de premier cycle.

   Cependant, cette distinction peut varier d'une institution à l'autre, et parfois les termes sont utilisés de manière interchangeable. Il est donc utile d'examiner les détails spécifiques et les programmes d'une institution éducative pour comprendre ce qu'ils proposent.
</FaqCollapse>

<FaqCollapse
   title="Quels athlètes sont guidés par Sport Scholarships America ?"
   open={collapse === 3}
   onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
>
   Je guide des athlètes de divers sports. Mon solide réseau et mes partenariats en Amérique me permettent de soutenir des athlètes dans diverses disciplines. Ainsi, que vous pratiquiez le soccer, le basketball, le tennis, le golf ou tout autre sport, je suis prêt à vous aider à poursuivre vos objectifs académiques et sportifs aux États-Unis. Pour une liste complète des sports, <Link to="/fr/Tous-les-sports" style={{ fontWeight: 'bold' }}>cliquez ici</Link>.
</FaqCollapse>

<FaqCollapse
   title="Quel est le processus de candidature typique et combien de temps à l'avance devrais-je commencer ?"
   open={collapse === 4}
   onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
>
   Le processus commence généralement par une réunion d'introduction complète où nous discutons de tous les détails et comprenons votre situation et vos objectifs spécifiques. J'expliquerai également davantage mon approche. La durée de l'ensemble du processus peut varier, mais en général, je recommande de commencer au moins 6 à 12 mois à l'avance. Il est important de souligner que plus vous commencez tôt, plus d'opportunités peuvent se présenter, alors n'hésitez pas à commencer tôt.

   Pendant cette période, nous collaborerons pour créer un plan personnalisé qui prend en compte tous les aspects, tels que les tests d'admission, les demandes de visa, la création de vidéos sportives, etc. Chaque parcours est unique et sur mesure, car de nombreux facteurs doivent être pris en compte. Pour un aperçu détaillé de l'ensemble du processus, vous pouvez <Link to="/fr/Processus" style={{ fontWeight: 'bold' }}>cliquer ici</Link>. Cela vous donne un aperçu étape par étape de ce à quoi vous pouvez vous attendre lors de votre voyage pour étudier et compétitionner aux États-Unis.
</FaqCollapse>

<FaqCollapse
   title="Quels sont les frais pour les services que vous proposez ?"
   open={collapse === 5}
   onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
>
   Les coûts de mes services varient en fonction de vos besoins et objectifs spécifiques. L'entretien initial est une étape importante où nous discutons de votre situation et développons un plan personnalisé. C'est aussi l'occasion de déterminer si nous souhaitons continuer à travailler ensemble.

   Après cette conversation, je serai en mesure de comprendre clairement vos souhaits et besoins, et sur cette base, je pourrai vous fournir une estimation des coûts appropriée.

   Pour une vue d'ensemble complète des services et produits que j'offre, vous pouvez <Link to="/fr/Services-Produits" style={{ fontWeight: 'bold' }}>cliquer ici</Link>. Vous y trouverez des informations détaillées sur la manière dont je peux vous aider dans votre démarche pour étudier et compétitionner aux États-Unis.
</FaqCollapse>

<FaqCollapse
   title="Pourquoi devrais-je choisir d'être guidé par Sport Scholarships America ?"
   open={collapse === 6}
   onClick={() => (collapse === 6 ? setCollapse(false) : setCollapse(6))}
>
   Lorsque vous êtes à un carrefour pour réaliser vos rêves, comme je l'ai été autrefois, de nombreuses questions et doutes peuvent surgir. Le choix de le faire vous-même, de collaborer avec une agence, voire d'abandonner toute l'idée peut être accablant. Je comprends profondément cette incertitude car je l'ai moi-même vécue.

   Ce serait dommage que ces doutes empêchent quelqu'un de poursuivre ce qui est vraiment possible - une expérience fantastique qui attend d'être découverte. C'est pourquoi je suis dévoué à aider les autres à réaliser leurs rêves.

   Je mets en pratique mes expériences personnelles en étant directement impliqué dans chaque projet. Je comprends les questions que vous pourriez avoir et m'efforce de fournir une approche personnalisée qui correspond à vos besoins uniques. Ensemble, nous pouvons donner vie à votre vision, soutenus par les connaissances et la compréhension qui découlent d'une expérience réelle et vécue.
</FaqCollapse>

          </Grid>
        </Grid>
        {/* Section des boutons */}
        <Grid container justifyContent="center" mt={4}>
          <Grid item xs={12} md={3}>
          <MKButton variant="gradient" color="light" fullWidth component={Link} to="/fr/FAQs">
    Voir la FAQ complète
</MKButton>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Faq;
