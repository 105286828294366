/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Wrestling1.jpeg";
import Image2 from "assets/images/Sports_Images/Wrestling2.webp";
import Image3 from "assets/images/Sports_Images/Wrestling3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Bolsas de Estudo em Luta Livre nos EUA: Como Estudar e Lutar nos Estados Unidos Pode Tornar Seus Sonhos Realidade', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capítulo 1: História e Desenvolvimento da Luta Livre na América', isSubheader: false },
    { id: '1.1', title: '1.1 Primeiros Anos', isSubheader: true },
    { id: '1.2', title: '1.2 Crescimento e Evolução', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capítulo 2: Luta Livre Universitária Hoje', isSubheader: false },
    { id: '2.1', title: '2.1 Competições e Conferências Importantes', isSubheader: true },
    { id: '2.2', title: '2.2 Programas Principais e Equipes de Prestígio', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capítulo 3: Do Universitário ao Nível Olímpico e Ligas Profissionais', isSubheader: false },
    { id: '3.1', title: '3.1 O Caminho para o Topo', isSubheader: true },
    { id: '3.2', title: '3.2 Luta Livre Profissional e as Olimpíadas', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capítulo 4: Influências e Competições Internacionais', isSubheader: false },
    { id: '4.1', title: '4.1 Estilos e Tradições de Luta Livre Estrangeiros', isSubheader: true },
    { id: '4.2', title: '4.2 Americanos no Palco Mundial', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 A Importância das Bolsas de Estudo', isSubheader: true },
    { id: '5.2', title: '5.2 Como a Sport Scholarships America Pode Ajudar', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Conteúdo
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Esconder</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>























{/* Introdução */}
<MKBox id="intro" mb={3}>
  {/* Título da Introdução */}
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Bolsas de Estudo em Luta Livre nos EUA: Como Estudar e Lutar nos Estados Unidos Pode Tornar Seus Sonhos Realidade
  </MKTypography>
  {/* Texto da Introdução */}
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Luta livre é um esporte praticado há milhares de anos em todo o mundo. É uma forma de combate físico no qual dois oponentes tentam dominar um ao outro através de golpes, arremessos, imobilizações e outras técnicas. A luta livre exige não apenas força física, velocidade, resistência e flexibilidade, mas também agilidade mental, estratégia, disciplina e perseverança.
    <br />
    <br />
    Nos Estados Unidos, a luta livre é um dos esportes mais praticados e assistidos. Tem uma história longa e cativante que remonta aos tempos coloniais. Desde então, desenvolveu-se em um fenômeno nacional com milhões de fãs e praticantes. A luta livre universitária americana representa o ápice desse desenvolvimento. É o nível onde os melhores lutadores do país se encontram em competições emocionantes que muitas vezes servem como trampolim para carreiras profissionais ou olímpicas.
    <br />
    <br />
    A importância da luta livre universitária no desenvolvimento dos atletas não pode ser subestimada. Lutadores universitários não apenas têm a oportunidade de mostrar e aprimorar seus talentos atléticos, mas também de estudar em universidades renomadas, onde podem adquirir valiosas habilidades e conhecimentos acadêmicos. Além disso, podem se beneficiar de bolsas de estudo que podem cobrir uma parte significativa ou total de seus custos de mensalidade.
    <br />
    <br />
    No entanto, obter uma bolsa de estudos em luta livre não é uma tarefa fácil. Requer muita preparação, dedicação e orientação. Felizmente, você não precisa fazer isso sozinho. Sportbeursamerika.nl é uma organização especializada em ajudar jovens talentos da luta livre a realizar o sonho de estudar e lutar nos Estados Unidos. Ofereço a você conselhos profissionais, apoio pessoal e acesso à minha extensa rede de contatos no mundo da luta livre americana.
    Gostaria de saber mais sobre como posso ajudá-lo a garantir uma bolsa de estudos em luta livre nos EUA? Continue lendo! Neste artigo, vou lhe contar mais sobre:
  </MKTypography>
  {/* Adicionar lista com marcadores ou outro conteúdo, conforme necessário */}
</MKBox>

{/* Capítulos e subcapítulos principais com texto fictício */}
<MKBox id="Chapter 1" mb={3}>
  {/* Título do Capítulo 1 */}
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 1: História e Desenvolvimento da Luta Livre na América
  </MKTypography>
  {/* Texto do Capítulo 1 */}
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    A luta livre é um esporte praticado desde os tempos antigos em várias culturas. É uma das formas mais antigas de competição organizada, mencionada na mitologia grega e na Bíblia. Também foi um dos primeiros esportes incluídos nos antigos Jogos Olímpicos.
    <br />
    <br />
    Nos Estados Unidos, a luta livre teve sua própria história e evolução intimamente ligadas ao contexto social e político do país. Neste capítulo, forneceremos uma visão geral das principais fases e eventos na história da luta livre americana e como eles contribuíram para a formação da luta livre universitária moderna.
  </MKTypography>

  {/* Subcapítulo 1.1: Primeiros Anos */}
  <MKBox id="1.1" mb={2}>
    {/* Título do Subcapítulo 1.1 */}
    <MKTypography variant="h4" mb={1}>
      1.1 Primeiros Anos
    </MKTypography>
    {/* Texto do Subcapítulo 1.1 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Os primórdios da luta livre na América remontam aos tempos coloniais, quando os colonos europeus trouxeram seus próprios estilos e tradições de luta livre para o novo continente. Esses estilos eram baseados principalmente na luta livre inglesa catch-as-catch-can, que permitia golpes em qualquer parte do corpo exceto a cabeça. Era uma forma popular de entretenimento e desafio entre os colonos, que frequentemente organizavam combates em feriados ou dias de mercado.
      <br />
      <br />
      Um dos lutadores mais antigos conhecidos na América foi George Washington, que, quando jovem, era conhecido por suas habilidades de luta livre. Ele uma vez derrotou sete oponentes em um único dia durante um combate na Virgínia. Outro lutador famoso deste período foi Abraham Lincoln, que venceu várias lutas ainda adolescente em Illinois.
      <br />
      <br />
      A primeira equipe universitária de luta livre foi formada em 1780 na Universidade de Yale, mas levou mais de um século para que a luta livre universitária verdadeiramente decolasse. O primeiro combate intercolegial oficial ocorreu em 1889 entre Yale e a Universidade de Columbia, com vitória de Yale por 5 a 0. Em 1892, o primeiro campeonato nacional foi realizado na cidade de Nova York, com oito equipes de diferentes estados. O vencedor foi a Universidade de Cornell, que venceu todas as sete lutas.
    </MKTypography>
  </MKBox>

  {/* Subcapítulo 1.2: Crescimento e Evolução */}
  <MKBox id="1.2" mb={2}>
    {/* Título do Subcapítulo 1.2 */}
    <MKTypography variant="h4" mb={1}>
      1.2 Crescimento e Evolução
    </MKTypography>
    {/* Texto do Subcapítulo 1.2 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      No final do século XIX e início do século XX, a luta livre começou a se espalhar e diversificar na América. Surgiram diversos estilos regionais, como a luta livre estilo Iowa, que enfatizava velocidade e agilidade, ou a luta livre estilo Oklahoma, que se concentrava em força e controle. Novas regras também foram introduzidas para tornar a luta livre mais segura e justa, como a proibição de golpes perigosos ou a introdução de limites de tempo.
      <br />
      <br />
      A luta livre universitária também cresceu em popularidade e prestígio, com mais equipes, competições e fãs. Em 1928, a National Collegiate Athletic Association (NCAA) foi fundada para regular e promover a luta livre universitária. A NCAA organizou campeonatos nacionais anuais para diferentes divisões com base no tamanho e nível das universidades. A divisão mais prestigiosa era a Divisão I, que compreendia aproximadamente 80 equipes.
      <br />
      <br />
      A luta livre universitária também produziu muitas estrelas e lendas que alcançaram sucesso nacional e internacional. Alguns exemplos incluem:
    </MKTypography>

    {/* Lista de exemplos de lutadores */}
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Dan Gable: Um dos maiores lutadores de todos os tempos, que dominou tanto como atleta quanto como treinador. Ele ganhou três títulos da NCAA pela Iowa State University, uma medalha de ouro nas Olimpíadas de 1972 sem perder um único ponto, e 15 títulos da NCAA como treinador da University of Iowa.
        </li>
        <li>
          John Smith: Um seis vezes campeão mundial e duas vezes campeão olímpico conhecido por suas técnicas e táticas inovadoras. Ele ganhou dois títulos da NCAA pela Oklahoma State University e mais tarde se tornou o treinador principal da mesma equipe.
        </li>
        <li>
          Cael Sanderson: O único lutador a ganhar quatro títulos da NCAA sem perder uma única luta. Ele teve um recorde perfeito de 159-0 pela Iowa State University e também ganhou uma medalha de ouro nas Olimpíadas de 2004. Atualmente, ele é o treinador principal da Penn State University, onde conquistou oito títulos da NCAA.
        </li>
      </ul>
    </MKTypography>

    {/* Continuação do texto do Subcapítulo 1.2 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estes são apenas alguns exemplos do crescimento e evolução da luta livre na América, que levaram à atual cena da luta livre universitária, considerada um dos esportes mais competitivos e emocionantes do país.
    </MKTypography>
  </MKBox>
</MKBox>




                                  






{/* Capítulo 2: Luta Livre Universitária Hoje */}
<MKBox id="Chapter 2" mb={3}>
  {/* Título do Capítulo 2 */}
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 2: Luta Livre Universitária Hoje
  </MKTypography>
  {/* Texto do Capítulo 2 */}
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    A luta livre universitária é um dos esportes mais populares e prestigiados nos Estados Unidos hoje. Atrai milhões de fãs e espectadores que acompanham as emocionantes lutas pela televisão, internet ou ao vivo nas arenas. A luta livre universitária também oferece inúmeras oportunidades para estudantes-atletas que podem se beneficiar de bolsas de estudo, apoio acadêmico e orientação profissional.
    <br />
    <br />
    Neste capítulo, vamos contar mais sobre as principais competições e conferências na luta livre universitária e os principais programas e equipes de prestígio que dominam o esporte.
  </MKTypography>

  {/* Subcapítulo 2.1: Competições e Conferências Importantes */}
  <MKBox id="2.1" mb={2}>
    {/* Título do Subcapítulo 2.1 */}
    <MKTypography variant="h4" mb={1}>
      2.1 Competições e Conferências Importantes
    </MKTypography>
    {/* Texto do Subcapítulo 2.1 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Dois lutadores universitários em um duelo intenso no tatame."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Dois lutadores em ação
        </figcaption>
      </figure>
      A luta livre universitária é organizada pela NCAA, que possui três divisões: Divisão I, Divisão II e Divisão III. Cada divisão tem suas próprias regras, critérios e campeonatos. A divisão mais competitiva e conhecida é a Divisão I, que consiste em aproximadamente 80 equipes de diferentes estados.
      <br />
      <br />
      As equipes da Divisão I são divididas em várias conferências, formando grupos regionais ou temáticos. As conferências organizam suas próprias competições e campeonatos, que servem como qualificações para o campeonato nacional. As conferências mais proeminentes na luta livre universitária incluem:
    </MKTypography>
    {/* Lista de conferências */}
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Conferência Big Ten: A maior e mais forte conferência na luta livre universitária, composta por 14 equipes das regiões Centro-Oeste e Nordeste do país. A Big Ten produziu a maioria dos campeões nacionais, incluindo Penn State, Iowa, Ohio State, Michigan e Minnesota.
        </li>
        <li>
          Conferência Big 12: A segunda maior e mais forte conferência na luta livre universitária, composta por 12 equipes das regiões Sul e Oeste do país. A Big 12 também produziu muitos campeões nacionais, incluindo Oklahoma State, Oklahoma, Iowa State e Missouri.
        </li>
        <li>
          Conferência da Costa Atlântica (ACC): Uma conferência emergente na luta livre universitária, composta por seis equipes da região Sudeste do país. A ACC ganhou mais atenção recentemente devido às performances de equipes como Virginia Tech, North Carolina State e North Carolina.
        </li>
        <li>
          Associação Intercolegial de Luta Livre do Leste (EIWA): Uma conferência histórica na luta livre universitária, composta por 16 equipes da região Leste do país. A EIWA é a mais antiga conferência de luta livre na América, fundada em 1905. A EIWA também produziu alguns campeões nacionais, incluindo Cornell, Lehigh, Princeton e Navy.
        </li>
      </ul>
    </MKTypography>
    {/* Continuação do texto do Subcapítulo 2.1 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estas são apenas algumas das muitas conferências na luta livre universitária, cada uma com suas próprias rivalidades, tradições e fãs. No entanto, as conferências não são o único fator que determina o quão bem-sucedida é uma equipe. Existem outros aspectos que desempenham um papel no sucesso de um programa de luta livre.
    </MKTypography>
  </MKBox>

  {/* Subcapítulo 2.2: Programas Principais e Equipes de Prestígio */}
  <MKBox id="2.2" mb={2}>
    {/* Título do Subcapítulo 2.2 */}
    <MKTypography variant="h4" mb={1}>
      2.2 Programas Principais e Equipes de Prestígio
    </MKTypography>
    {/* Texto do Subcapítulo 2.2 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Um programa de luta livre é um termo usado para se referir à organização e estrutura de uma equipe de luta livre universitária. Um programa de luta livre inclui aspectos como o treinador, equipe, instalações, recrutamento, treinamento, financiamento e cultura da equipe.
      <br />
      <br />
      Um bom programa de luta livre pode fazer uma diferença significativa no desenvolvimento dos estudantes-atletas. Um bom programa de luta livre pode fornecer aos estudantes-atletas:
    </MKTypography>
    {/* Lista de benefícios de um bom programa de luta livre */}
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Um treinador experiente e qualificado que possa ensiná-los a melhorar sua técnica, táticas, condicionamento e mentalidade.
        </li>
        <li>
          Uma equipe dedicada e profissional que possa apoiá-los em áreas como nutrição, prevenção de lesões, recuperação, orientação acadêmica e planejamento de carreira.
        </li>
        <li>
          Instalações modernas e bem equipadas onde possam treinar em um nível elevado com equipamentos e materiais adequados.
        </li>
        <li>
          Uma estratégia de recrutamento eficaz e ética que possa ajudá-los a atrair e reter lutadores talentosos e motivados.
        </li>
        <li>
          Uma agenda de treinamento intensiva e variada que possa desafiá-los e prepará-los para diferentes lutas e situações.
        </li>
        <li>
          Financiamento adequado e estável que possa ajudá-los a cobrir os custos da luta livre, como viagens, equipamentos, bolsas de estudo e outras despesas.
        </li>
        <li>
          Uma cultura positiva e inspiradora que possa motivá-los e incentivá-los a alcançar seu potencial, buscar seus objetivos e compartilhar sua paixão pelo esporte.
        </li>
      </ul>
    </MKTypography>
    {/* Continuação do texto do Subcapítulo 2.2 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Existem muitas universidades na América que têm excelentes programas de luta livre que têm sido bem-sucedidos na luta livre universitária por anos. Alguns desses programas incluem:
    </MKTypography>
    {/* Lista de programas principais e equipes de prestígio */}
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Penn State: A equipe líder atual na luta livre universitária, que ganhou o campeonato nacional oito vezes desde 2011. Penn State é conhecida por seu estilo agressivo e espetacular de luta livre, liderado pelo treinador Cael Sanderson, um dos maiores lutadores de todos os tempos.
        </li>
        <li>
          Iowa: A equipe mais bem-sucedida na história da luta livre universitária, tendo ganhado o campeonato nacional mais de 20 vezes desde 1975. Iowa é conhecida por seu estilo agressivo e dominante de luta livre, liderado pelo treinador Tom Brands, um ex-campeão olímpico.
        </li>
        <li>
          Oklahoma State: A segunda equipe mais bem-sucedida na história da luta livre universitária, tendo ganho o campeonato nacional mais de 20 vezes. Oklahoma State é conhecida por seu estilo técnico e tático de luta livre, liderado pelo treinador John Smith, um dos maiores lutadores de todos os tempos.
        </li>
      </ul>
    </MKTypography>
    {/* Continuação do texto do Subcapítulo 2.2 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estes são apenas alguns dos muitos programas principais e equipes de prestígio na luta livre universitária, cada um com suas próprias características, realizações e fãs. No entanto, não é fácil fazer parte desses programas e equipes. É preciso muito talento, trabalho árduo e dedicação para garantir uma bolsa de estudos na luta livre.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  







{/* Capítulo 3: Do Universitário ao Nível Olímpico e Ligas Profissionais */}
<MKBox id="Chapter 3" mb={3}>
  {/* Título do Capítulo 3 */}
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 3: Do Universitário ao Nível Olímpico e Ligas Profissionais
  </MKTypography>
  {/* Texto do Capítulo 3 */}
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    A luta livre universitária não é o ponto final para muitos estudantes-atletas que sonham com uma carreira no esporte. É mais uma pedra de toque para níveis mais altos onde podem competir com os melhores lutadores do mundo. Neste capítulo, vamos contar mais sobre o caminho para o topo na luta livre profissional ou olímpica e os sucessos e desafios dos lutadores americanos no palco mundial.
  </MKTypography>

  {/* Subcapítulo 3.1: O Caminho para o Topo */}
  <MKBox id="3.1" mb={2}>
    {/* Título do Subcapítulo 3.1 */}
    <MKTypography variant="h4" mb={1}>
      3.1 O Caminho para o Topo
    </MKTypography>
    {/* Texto do Subcapítulo 3.1 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A luta livre universitária é um dos esportes mais competitivos e exigentes da América. Requer muito talento, trabalho árduo e dedicação para garantir uma bolsa de estudos na luta livre, e ainda mais para brilhar em competições nacionais. Mas mesmo se você for um dos melhores lutadores universitários, não é garantia de sucesso no nível profissional ou olímpico.
      <br />
      <br />
      Existem vários fatores que tornam desafiadora a transição da luta livre universitária para a luta livre profissional ou olímpica. Alguns desses fatores incluem:
    </MKTypography>
    {/* Lista de desafios */}
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Diferenças de Estilo: A luta livre universitária é praticada de acordo com as regras do folkstyle, únicas para a América. Essas regras se concentram em controlar e dominar o oponente, com pontos concedidos por domínio, escape, reversão e quedas quase contadas. A luta livre profissional ou olímpica, por outro lado, é praticada de acordo com as regras do estilo livre ou greco-romano, usadas internacionalmente. Essas regras enfatizam marcar pontos através de arremessos, tombos, exposições e pinos. Isso significa que os lutadores universitários precisam adaptar sua técnica, táticas e estratégia para competir com lutadores de outros países que têm mais experiência com esses estilos.
        </li>
        <li>
          Diferenças de Idade: A luta livre universitária é um esporte para jovens atletas, geralmente entre 18 e 23 anos. A luta livre profissional ou olímpica, no entanto, é um esporte para atletas adultos, geralmente entre 25 e 35 anos. Isso significa que os lutadores universitários têm que competir com lutadores mais velhos e experientes que têm maior força física, maturidade mental e refinamento técnico.
        </li>
        <li>
          Diferenças Financeiras: A luta livre universitária é um esporte apoiado por universidades, que fornecem aos estudantes-atletas bolsas de estudo, instalações, treinamento e outros benefícios. A luta livre profissional ou olímpica, no entanto, recebe pouco apoio financeiro do governo ou patrocinadores. Isso significa que os lutadores universitários precisam buscar outras fontes de renda para cobrir seus treinamentos, viagens, equipamentos e despesas de vida.
        </li>
      </ul>
    </MKTypography>
    {/* Continuação do texto do Subcapítulo 3.1 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estes são apenas alguns dos muitos desafios que os lutadores universitários precisam superar para seguir o sonho de uma carreira profissional ou olímpica. No entanto, apesar desses desafios, também existem muitos sucessos e histórias inspiradoras de lutadores americanos que chegaram ao mais alto nível.
    </MKTypography>
  </MKBox>

  {/* Subcapítulo 3.2: Luta Livre Profissional e as Olimpíadas */}
  <MKBox id="3.2" mb={2}>
    {/* Título do Subcapítulo 3.2 */}
    <MKTypography variant="h4" mb={1}>
      3.2 Luta Livre Profissional e as Olimpíadas
    </MKTypography>
    {/* Texto do Subcapítulo 3.2 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A luta livre profissional ou olímpica é o auge do esporte, onde os melhores lutadores do mundo competem em competições e eventos de prestígio. Existem duas formas principais de luta livre profissional ou olímpica: estilo livre e greco-romano.
      <br />
      <br />
      A luta livre estilo livre é uma forma de luta livre onde todos os tipos de agarrões em todo o corpo são permitidos, desde que não sejam perigosos ou inadequados. A luta livre estilo livre é praticada tanto por homens quanto por mulheres, em várias categorias de peso. A luta livre estilo livre é um dos esportes mais antigos nos Jogos Olímpicos, realizados desde 1904 para homens e desde 2004 para mulheres.
      <br />
      <br />
      A luta livre greco-romana é uma forma de luta livre onde apenas agarrões acima da cintura são permitidos, e o uso das pernas para agarrar ou derrubar o oponente é proibido. A luta livre greco-romana é praticada apenas por homens, em várias categorias de peso. A luta livre greco-romana é a forma mais antiga de luta livre nos Jogos Olímpicos, realizada desde 1896.
      <br />
      <br />
      Os lutadores americanos alcançaram muitos sucessos tanto na luta livre estilo livre quanto na luta livre greco-romana, nas Olimpíadas, bem como em outras competições internacionais como os Campeonatos Mundiais, os Jogos Pan-Americanos ou a Copa do Mundo. Alguns dos lutadores americanos mais bem-sucedidos e conhecidos incluem:
    </MKTypography>
    {/* Lista de lutadores */}
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Jordan Burroughs: Um pentacampeão mundial e campeão olímpico na luta livre estilo livre, conhecido por seu estilo explosivo e dinâmico. Ele ganhou uma medalha de ouro nos Jogos Olímpicos de 2012 em Londres e quatro títulos mundiais em 2011, 2013, 2015 e 2017. Ele também ganhou dois títulos da NCAA pela Universidade de Nebraska.
        </li>
        <li>
          Kyle Snyder: Um tricampeão mundial e campeão olímpico na luta livre estilo livre, conhecido por sua força e técnica. Ele ganhou uma medalha de ouro nos Jogos Olímpicos de 2016 no Rio de Janeiro e três títulos mundiais em 2015, 2017 e 2019. Ele também ganhou três títulos da NCAA pela Universidade Estadual de Ohio.
        </li>
      </ul>
    </MKTypography>
    {/* Continuação do texto do Subcapítulo 3.2 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estes são apenas alguns dos muitos sucessos e histórias inspiradoras de lutadores americanos no nível profissional ou olímpico. Eles mostram que é possível alcançar seu sonho se estiver disposto a trabalhar duro, aprender e crescer.
    </MKTypography>
  </MKBox>
</MKBox>



                                  





{/* Capítulo 4: Influências e Competições Internacionais */}
<MKBox id="Chapter 4" mb={3}>
  {/* Título do Capítulo 4 */}
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 4: Influências e Competições Internacionais
  </MKTypography>
  {/* Texto do Capítulo 4 */}
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    A luta livre é um esporte universal, praticado e apreciado em muitos países e culturas. A luta livre também possui muitos estilos e tradições que se originaram e se desenvolveram em diferentes regiões e períodos. Neste capítulo, vamos contar mais sobre as influências e competições internacionais na luta livre e como elas influenciam e desafiam os lutadores americanos.
  </MKTypography>
  {/* Subcapítulo 4.1: Estilos e Tradições de Luta Livre Estrangeiros */}
  <MKBox id="4.1" mb={2}>
    {/* Título do Subcapítulo 4.1 */}
    <MKTypography variant="h4" mb={1}>
      4.1 Estilos e Tradições de Luta Livre Estrangeiros
    </MKTypography>
    {/* Texto do Subcapítulo 4.1 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      {/* Imagem no Subcapítulo 4.1 */}
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Visão aérea de uma partida de luta livre da NCAA em um estádio lotado."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Uma partida no torneio da NCAA
        </figcaption>
      </figure>
      A luta livre é um esporte praticado desde os tempos antigos em várias partes do mundo. É uma das formas mais antigas de expressão física, frequentemente associada a significados religiosos, culturais ou sociais. Existem muitos tipos de luta livre, cada um com suas próprias características, regras e técnicas. Alguns exemplos incluem:
    </MKTypography>
    {/* Lista de estilos de luta livre estrangeiros */}
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Sumô: Um estilo tradicional de luta livre japonês considerado um esporte nacional e um ritual sagrado. O sumô é praticado por lutadores pesados e fortes que tentam empurrar uns aos outros para fora de um anel circular ou derrubá-los no chão.
        </li>
        <li>
          Luta livre mongol: Um estilo tradicional de luta livre mongol considerado um símbolo de coragem e força. A luta livre mongol é praticada por lutadores musculosos e ágeis que tentam derrubar uns aos outros através de agarrões no corpo superior ou pernas.
        </li>
        <li>
          Luta livre turca com óleo: Um estilo tradicional de luta livre turca considerado um patrimônio e uma arte. A luta livre turca com óleo é praticada por lutadores oleados e musculosos que tentam derrotar uns aos outros através de agarrões nas calças ou cinto.
        </li>
        <li>
          Luta livre senegalesa: Um estilo tradicional de luta livre senegalês considerado um esporte popular e lucrativo. A luta livre senegalesa é praticada por lutadores atléticos e coloridos que tentam derrubar uns aos outros através de arremessos, socos ou chutes.
        </li>
      </ul>
    </MKTypography>
    {/* Continuação do texto do Subcapítulo 4.1 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estes são apenas alguns dos muitos estilos e tradições de luta livre estrangeiros, cada um com sua própria história, cultura e fãs. Esses estilos também influenciaram a luta livre universitária americana, introduzindo novas técnicas, táticas ou desafios. Muitos lutadores universitários americanos viajaram ou treinaram em outros países para ampliar seus horizontes e aprimorar suas habilidades.
    </MKTypography>
  </MKBox>
  {/* Subcapítulo 4.2: Americanos no Palco Mundial */}
  <MKBox id="4.2" mb={2}>
    {/* Título do Subcapítulo 4.2 */}
    <MKTypography variant="h4" mb={1}>
      4.2 Americanos no Palco Mundial
    </MKTypography>
    {/* Texto do Subcapítulo 4.2 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A luta livre universitária americana não se limita apenas a competições nacionais, mas também a competições internacionais. Existem muitos eventos internacionais nos quais os lutadores universitários americanos podem participar para competir com os melhores lutadores do mundo. Alguns desses eventos incluem:
    </MKTypography>
    {/* Lista de eventos internacionais */}
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Os Jogos Olímpicos: O maior e mais prestigiado evento na luta livre profissional ou olímpica, realizado a cada quatro anos. Os Jogos Olímpicos são o objetivo final para muitos lutadores universitários que sonham em ganhar uma medalha de ouro para seu país.
        </li>
        <li>
          Os Campeonatos Mundiais: O segundo maior e mais prestigiado evento na luta livre profissional ou olímpica, realizado anualmente. Os Campeonatos Mundiais são uma oportunidade significativa para os lutadores universitários se qualificarem para as Olimpíadas ou estabelecerem sua reputação no palco mundial.
        </li>
        <li>
          A Copa do Mundo: O segundo maior e mais prestigiado evento na luta livre regional, realizado anualmente. A Copa do Mundo é uma oportunidade significativa para os lutadores universitários fazerem parte de uma seleção nacional que compete contra outras seleções nacionais em formato de equipe.
        </li>
      </ul>
    </MKTypography>
    {/* Continuação do texto do Subcapítulo 4.2 */}
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estes são apenas alguns dos muitos eventos internacionais nos quais os lutadores universitários americanos podem participar para mostrar seus talentos e perseguir seus sonhos. No entanto, esses eventos não são apenas uma fonte de honra e glória, mas também uma fonte de desafio e dificuldade. Os lutadores universitários devem competir com lutadores de outros países que muitas vezes têm mais experiência, estilo ou motivação.
    </MKTypography>
  </MKBox>
</MKBox>






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    As bolsas de estudo esportivas oferecem inúmeras oportunidades para jovens atletas mostrarem seu talento e desenvolverem suas habilidades. Uma das oportunidades mais significativas é obter uma bolsa de estudos esportiva para estudar e competir no nível universitário. Neste capítulo, discutiremos a importância das bolsas de estudo esportivas e como a Sport Scholarships America pode ajudá-lo a realizar seu sonho.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 A Importância das Bolsas de Estudo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Uma bolsa de estudos esportiva é um suporte financeiro fornecido por uma universidade a um estudante-atleta para combinar estudos e esportes. Pode cobrir os custos de mensalidades, livros, moradia, refeições e outras despesas. Além disso, pode oferecer benefícios como tutoria acadêmica, cuidados médicos, instalações de treinamento e equipamentos.
      <br />
      <br />
      Obter uma bolsa de estudos esportiva é competitivo, com muitos fatores desempenhando um papel no processo de concessão, incluindo realizações acadêmicas, desempenho atlético, personalidade, motivação, liderança e adaptabilidade. Portanto, é importante se preparar bem e se destacar da concorrência.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Como a Sport Scholarships America Pode Ajudar
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo da Sport Scholarships America, mostrando equipamentos esportivos e um capelo de formatura, representando o foco em bolsas de estudo esportivas para estudantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo da Sport Scholarships America
        </figcaption>
      </figure>
      A Sport Scholarships America é uma organização especializada em orientar jovens talentos esportivos para bolsas de estudo esportivas. Com especialistas apaixonados e experientes, a Sport Scholarships America apoia estudantes-atletas em cada etapa da jornada, desde a orientação inicial até a colocação final. Seus serviços incluem:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Uma entrevista de admissão pessoal para discutir os desejos, possibilidades e expectativas do estudante-atleta.</li>
        <li>Uma avaliação profissional do nível acadêmico e atlético do estudante-atleta.</li>
        <li>Uma extensa rede de contatos com treinadores, universidades e faculdades.</li>
        <li>Promoção eficaz do perfil e vídeos do estudante-atleta para treinadores potenciais.</li>
        <li>Conselhos especializados sobre as melhores opções com base nas preferências, metas e orçamento do estudante-atleta.</li>
        <li>Negociação das melhores ofertas de bolsas de estudo em nome do estudante-atleta.</li>
        <li>Auxílio na solicitação de documentos necessários, como visto, histórico escolar, diploma e testes de admissão.</li>
        <li>Preparação para a vida do estudante-atleta durante a bolsa de estudos, incluindo cultura, idioma, regras e dicas.</li>
        <li>Acompanhamento e acompanhamento durante o período da bolsa de estudos.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Quer explorar oportunidades de bolsas de estudo e alcançar seus objetivos atléticos e acadêmicos? <Link to="/pt/Serviços-Produtos">Veja a gama completa</Link> de serviços oferecidos pela Sport Scholarships America e agende uma consulta hoje.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;