/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


// @mui material components
import React, { useState } from 'react'; // Step 1: Import useState


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Logos/NJCAA.png";
import post3 from "assets/images/Logos/NAIA.png";



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {

  const articleStructure = [
    { id: 'intro', title: 'NCAA : Le Cœur du Sport Universitaire en Amérique', isSubheader: false },
  
    { id: 'Chapitre 1', title: 'Les Origines et la Croissance de la NCAA', isSubheader: true },
    { id: '1.1', title: 'Les Trois Visages de la NCAA : Divisions I, II et III', isSubheader: true },
    { id: '1.2', title: 'Le Rôle des Académiciens', isSubheader: true },
  
    { id: 'Chapitre 2', title: 'Finances, Médias et Marketing', isSubheader: true },
    { id: '2.1', title: 'Défis et Avenir', isSubheader: true },
    { id: '2.2', title: 'Conclusion', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
     <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contents
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Cacher</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Afficher</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="intro" mb={3}>


              
<MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>NCAA : Le Cœur du Sport Universitaire en Amérique</MKTypography>
</MKBox>
<MKBox id="Chapitre 1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Les Origines et la Croissance de la NCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
La National Collegiate Athletic Association, mieux connue sous le nom de NCAA, est au centre du paysage sportif universitaire américain depuis plus d'un siècle. Fondée en 1906, cette organisation est passée d'une petite entité régulatrice à une organisation puissante et influente qui impacte la vie de milliers d'étudiants-athlètes, d'entraîneurs et de passionnés de sport.
<br />
<br />
Dans les premières années de la NCAA, l'accent était principalement mis sur l'établissement de règles et la garantie de la sécurité dans le sport. Cependant, avec le temps, le rôle de la NCAA s'est élargi, devenant de plus en plus impliqué dans la régulation de tous les aspects du sport universitaire, des exigences académiques aux questions financières.
</MKTypography>

<MKBox id="1.1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Les Trois Visages de la NCAA : Divisions I, II et III
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
La NCAA est divisée en trois divisions, chacune avec son propre caractère unique et son niveau de compétition :
<br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division I :{' '}
</MKTypography>
est considérée comme le sommet du sport universitaire, où les écoles avec les plus gros budgets, les meilleures installations et les athlètes les plus talentueux se retrouvent. Les universités de cette division sont souvent grandes et disposent de ressources importantes. Elles offrent des bourses sportives complètes et partielles et attirent les meilleurs athlètes du pays et du monde entier. La compétition est intense et la pression pour performer est élevée. Pourtant, c'est aussi une scène où naissent les étoiles et où les rêves des jeunes athlètes peuvent devenir réalité. La division est subdivisée en trois catégories : la Football Bowl Subdivision (FBS), la Football Championship Subdivision (FCS) et les écoles sans football au niveau de la Division I.  <br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division II :{' '}
</MKTypography>
représente une approche plus équilibrée du sport universitaire, où le sport, la performance académique et l'engagement sur le campus vont de pair. Les universités de cette division sont souvent plus petites que celles de la Division I mais offrent tout de même un niveau élevé de compétition. Les athlètes de la Division II peuvent s'attendre à un soutien à la fois sur le terrain et en classe, dans le but de fournir une expérience universitaire complète.  <br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division III :{' '}
</MKTypography>
met enfin l'accent sur le développement global de l'étudiant-athlète. Le sport est important, mais il n'est qu'une partie de l'expérience universitaire plus large. Aucune bourse sportive n'est offerte en Division III, mais les athlètes ont l'opportunité de participer à des sports compétitifs tout en s'intégrant pleinement à la vie académique et sociale sur le campus.</MKTypography>

<MKBox id="1.2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Le Rôle des Académiciens
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
La NCAA prend très au sérieux la performance académique de ses étudiants-athlètes. Tous les athlètes doivent respecter des exigences académiques strictes pour être éligibles à la compétition, et les universités elles-mêmes sont responsables de fournir le soutien nécessaire pour garantir que les athlètes réussissent en classe. Cela inclut des conseillers académiques, des tuteurs et des programmes d'études spéciaux, tous conçus pour aider les étudiants-athlètes à équilibrer leurs obligations sportives et académiques.                <br />
<br />
</MKTypography>




                                  







<MKBox id="Chapitre 2" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Finances, Médias et Marketing
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              La NCAA est également une puissance financière, avec des milliards de dollars qui circulent chaque année au sein de l'organisation grâce à des contrats de télévision, des sponsors et des marchandises. Ces revenus sont utilisés pour financer des bourses, améliorer les installations et améliorer l'expérience sportive globale des étudiants-athlètes. En même temps, la popularité du sport universitaire a conduit à des accords lucratifs en matière de marketing et de médias, faisant du sport universitaire un élément incontournable de la culture sportive américaine.

<br />
                </MKTypography>

                <MKBox id="2.1" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Défis et Avenir
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              Malgré son succès, la NCAA doit également relever des défis importants. Des questions telles que le statut amateur des étudiants-athlètes, la santé mentale des athlètes et la recherche de l'égalité dans le sport ne sont que quelques-uns des sujets que l'organisation doit aborder dans sa quête d'un avenir meilleur pour le sport universitaire.
<br />
<br />
La NCAA reste un acteur crucial dans le système éducatif américain, et son impact sur la vie des jeunes athlètes et sur le monde du sport en général est indéniable. Alors qu'elle regarde vers l'avenir, il est clair que l'organisation continuera de jouer un rôle clé dans la formation du paysage sportif universitaire en Amérique.


                </MKTypography>

                <MKBox id="2.2" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Conclusion
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              La National Collegiate Athletic Association est plus qu'une simple organisation sportive ; c'est une partie cruciale du paysage éducatif et sportif américain. Avec son histoire riche, sa structure complexe et son impact profond sur la vie de nombreuses personnes, la NCAA reste une partie fascinante et vitale de la culture américaine. Son avenir sera sans aucun doute rempli de défis et d'opportunités, mais une chose est sûre : la NCAA continuera de s'efforcer d'exceller, tant sur le terrain que dans la salle de classe.




<br />


                </MKTypography>



              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="NJCAA"
                    description="NJCAA : La Porte d'Entrée vers les Grandes Universités et la NCAA"
                    action={{
                      type: "internal",
                      route: "/fr/NJCAA",
                      color: "info",
                      label: "Lire La Suite",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="NAIA"
                    description="La NAIA : Une Alternative dans le Sport Universitaire en Amérique"
                    action={{
                      type: "internal",
                      route: "/fr/NAIA",
                      color: "info",
                      label: "Lire La Suite",
                    }}
                    />
                  </Grid>
                </Grid>
  
              </Grid>
          </Grid>
        </Container>
      </MKBox>
    );
  }
  
  export default Beschrijving;