import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import SchoolIcon from '@mui/icons-material/School';
import StarIcon from '@mui/icons-material/Star';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2'; 
import Filter3Icon from '@mui/icons-material/Filter3'; 
import Filter4Icon from '@mui/icons-material/Filter4'; 
import Filter5Icon from '@mui/icons-material/Filter5'; 
import Filter6Icon from '@mui/icons-material/Filter6'; 
import Filter7Icon from '@mui/icons-material/Filter7'; 
import Filter8Icon from '@mui/icons-material/Filter8'; 
import Filter9Icon from '@mui/icons-material/Filter9';  // Importer ikonet for tallet 1
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import ScheduleIcon from '@mui/icons-material/Schedule';
import VideocamIcon from '@mui/icons-material/Videocam';
import QuizIcon from '@mui/icons-material/Quiz';
import RuleIcon from '@mui/icons-material/Rule';
import ChecklistIcon from '@mui/icons-material/Checklist';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import DescriptionIcon from '@mui/icons-material/Description';



const boxStyle = {
  boxShadow: '0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15)',
  background: '#f5f5f5', // Lysgrå bakgrunn
  color: '#000', // Tekstfarge satt til svart for kontrast
};

const arrowStyle = {
  borderRight: '7px solid white',
};

function Timeline() {
  return (
    <VerticalTimeline lineColor='rgb(33, 150, 243)'>
      <VerticalTimelineElement
        iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
        icon={<StarIcon />}
      />
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ ...boxStyle, position: 'relative' }} // Legg til position relative til stilen
        contentArrowStyle={arrowStyle}
        date="Steg 1"
        iconStyle={{ 
          background: 'rgb(33, 150, 243)', 
          color: '#fff', 
          boxShadow: '0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15)', // Sørg for at skyggen påføres her
          border: '2px solid white' // Behold kanten
        }}
        icon={<RecordVoiceOverIcon />}
      >
        <div style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          opacity: 0.5 // Redusert opasitet for en vannmerkeeffekt
        }}>
          <Filter1Icon style={{ color: 'grey', fontSize: '48px' }} />
        </div>

        <h3 className="vertical-timeline-element-title">Innledende konsultasjon:</h3>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // La til Roboto som skriftfamilie
          fontSize: '17px', // Endret skriftstørrelse til 18px
          fontWeight: '400', // Sørg for at skriftvekten er normal
          marginBottom: '10px', // Juster verdien etter behov
        }}>
          Dette er ditt utgangspunkt – øyeblikket vi tilpasser dine drømmer og mål med en realistisk vei til et sportsstipend i Amerika.
        </p>

        <ul style={{ 
          listStyleType: 'disc', 
          marginLeft: '20px', 
          fontSize: '17px', 
        }}>  
          <li>
            <strong>Personlige Mål:</strong> Avklare dine ambisjoner og hva du søker i et sportsstipend og universitetsopplevelse.
          </li>
          <li>
            <strong>Sportslige Ferdigheter:</strong> Evaluering av din nåværende sportsprestasjon og potensial for konkurranse på universitetsnivå.
          </li>
          <li>
            <strong>Akademiske Aspirasjoner:</strong> Forståelse av dine akademiske interesser og mål, og hvordan integrere dem med din idrett.
          </li>
          <li>
            <strong>Personlige Mål:</strong> Basert på samtalen, vil jeg utarbeide en innledende strategi og tidsplan for din reise til et sportsstipend.
          </li>
        </ul>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // La til Roboto som skriftfamilie
          fontSize: '17px', // Endret skriftstørrelse til 18px
          fontWeight: '400', // Sørg for at skriftvekten er normal
        }}>
          Sammen legger vi grunnlaget for en vellykket match med et amerikansk universitet og setter kursen for de neste stegene i prosessen.
        </p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ ...boxStyle, position: 'relative' }} // Legg til position relative til stilen
        contentArrowStyle={arrowStyle}
        date="Steg 2"
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        icon={<ScheduleIcon />}
      >
        <div style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          opacity: 0.5 // Redusert opasitet for en vannmerkeeffekt
        }}>
          <Filter2Icon style={{ color: 'grey', fontSize: '48px' }} />
        </div>
        <h3 className="vertical-timeline-element-title">Vurdering og Planlegging:</h3>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // La til Roboto som skriftfamilie
          fontSize: '17px', // Endret skriftstørrelse til 18px
          fontWeight: '400', // Sørg for at skriftvekten er normal
          marginBottom: '10px', // Juster verdien etter behov
        }}>
          Etter din innledende konsultasjon, tar jeg meg tid til en grundig vurdering og utarbeiding av en personlig plan.
        </p>

        <ul style={{ 
          listStyleType: 'disc', 
          marginLeft: '20px', 
          fontSize: '17px', 
        }}>  
          <li>
            <strong>Potensialanalyse:</strong> Jeg vurderer din sportslige og akademiske bakgrunn for å måle dine utsikter i USA.
          </li>
          <li>
            <strong>Handlingsplan:</strong> Jeg utvikler en skreddersydd plan, inkludert en tidsplan og milepæler, for å oppnå dine mål.
          </li>
          <li>
            <strong>Strategisk Rådgivning:</strong> Råd om hvordan du kan forbedre dine sports- og akademiske prestasjoner for å øke sjansene for stipend.
          </li>
          <li>
            <strong>Måluniversiteter:</strong> Sammen identifiserer vi en liste over måluniversiteter som passer med din profil og ambisjoner.
          </li>
        </ul>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // La til Roboto som skriftfamilie
          fontSize: '17px', // Endret skriftstørrelse til 18px
          fontWeight: '400', // Sørg for at skriftvekten er normal
        }}>
          Denne detaljerte planen danner grunnlaget for din reise til et sportsstipend og sørger for at du er forberedt på hvert påfølgende steg.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ ...boxStyle, position: 'relative' }} // Legg til position relative til stilen
        contentArrowStyle={arrowStyle}
        date="Steg 3"
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        icon={<VideocamIcon />}
      >
        <div style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          opacity: 0.5 // Redusert opasitet for en vannmerkeeffekt
        }}>
          <Filter3Icon style={{ color: 'grey', fontSize: '48px' }} />
        </div>
        <h3 className="vertical-timeline-element-title">Videomateriale og Sportsportefølje:</h3>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // La til Roboto som skriftfamilie
          fontSize: '17px', // Endret skriftstørrelse til 18px
          fontWeight: '400', // Sørg for at skriftvekten er normal
          marginBottom: '10px', // Juster verdien etter behov
        }}>
          Å lage en overbevisende sportsportefølje og profesjonelt videomateriale er avgjørende for din synlighet overfor trenere.
        </p>

        <ul style={{ 
          listStyleType: 'disc', 
          marginLeft: '20px', 
          fontSize: '17px', 
        }}>  
          <li>
            <strong>Produksjon av Video:</strong> Jeg gir råd om innhold og overvåker produksjonen av en høydepunktsvideo som optimalt viser frem dine ferdigheter.
          </li>
          <li>
            <strong>Utvikling av Portefølje:</strong> Jeg bistår i å sette sammen en omfattende sportsportefølje, inkludert statistikk, prestasjoner og anbefalinger.
          </li>
          <li>
            <strong>Personlig Historie:</strong> Din atletiske reise og prestasjoner veves inn i en personlig fortelling som skiller deg ut fra andre.
          </li>
          <li>
            <strong>Digital Presentasjon:</strong> Jeg sørger for at din portefølje og video er digitalt tilgjengelig og delebar for trenere og speiderteam.
          </li>
        </ul>
        <p style={{ 
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // La til Roboto som skriftfamilie
          fontSize: '17px', // Endret skriftstørrelse til 18px
          fontWeight: '400', // Sørg for at skriftvekten er normal
        }}>
          Med dette steget sikrer vi at ditt talent utvilsomt blir fremhevet og tiltrekker seg oppmerksomheten til amerikanske universiteter.
        </p>
      </VerticalTimelineElement>





      <VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Legg til position relative til stilen
  contentArrowStyle={arrowStyle}
  date="Steg 4"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<QuizIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Redusert opasitet for en vannmerkeeffekt
  }}>
    <Filter4Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Akademisk Forberedelse:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // La til Roboto som skriftfamilie
    fontSize: '17px', // Endret skriftstørrelse til 18px
    fontWeight: '400', // Sørg for at skriftvekten er normal
    marginBottom: '10px', // Juster verdien etter behov
  }}>
    God akademisk forberedelse er avgjørende for opptak og suksess i USA.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Testforberedelser:</strong> Jeg tilbyr studiematerialer og veiledning for SAT/ACT og TOEFL/IELTS, essensielle tester for ditt opptak.
    </li>
    <li>
      <strong>Studieplanlegging:</strong> Sammen utvikler vi en studieplan for å forberede deg til eksamenene, uten å forsømme din sportstrening.
    </li>
    <li>
      <strong>Søknadsdokumenter:</strong> Jeg hjelper til med å forberede og revidere dine søknadsoppgaver og annen nødvendig dokumentasjon.
    </li>
    <li>
      <strong>Akademisk Rådgivning:</strong> Du får råd om akademiske krav og hvordan du kan styrke din akademiske profil.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // La til Roboto som skriftfamilie
    fontSize: '17px', // Endret skriftstørrelse til 18px
    fontWeight: '400', // Sørg for at skriftvekten er normal
  }}>
    Målet mitt er å sikre at du er akademisk forberedt ikke bare for å bli akseptert, men for å utmerke deg på universitetet.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Legg til position relative til stilen
  contentArrowStyle={arrowStyle}
  date="Steg 5"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<SchoolIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Redusert opasitet for en vannmerkeeffekt
  }}>
    <Filter5Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Promotering ved Universiteter:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // La til Roboto som skriftfamilie
    fontSize: '17px', // Endret skriftstørrelse til 18px
    fontWeight: '400', // Sørg for at skriftvekten er normal
    marginBottom: '10px', // Juster verdien etter behov
  }}>
    Å promotere din profil ved universiteter er et nøkkelsteg hvor min ekspertise og omfattende nettverk kommer til anvendelse.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Nettverkets Nøkkelrolle:</strong> Mitt omfattende nettverk innen amerikanske universiteter er uvurderlig i å bringe din kandidatur til oppmerksomhet.
    </li>
    <li>
      <strong>Strategisk Posisjonering:</strong> Jeg bruker min kunnskap og erfaring til å strategisk posisjonere din profil for å skille seg ut til de riktige sportsprogrammene og akademiske institusjonene.
    </li>
    <li>
      <strong>Tilpassbar Promoteringsplan:</strong> Jeg lager en fleksibel og skreddersydd promoteringsplan som fremhever de unike aspektene ved din sports- og akademiske profil.
    </li>
    <li>
      <strong>Maksimering av Muligheter:</strong> Gjennom målrettet promotering sikrer jeg at dine sjanser for å oppnå et stipend maksimeres, med fokus på det beste tilpasset dine personlige og atletiske ambisjoner.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // La til Roboto som skriftfamilie
    fontSize: '17px', // Endret skriftstørrelse til 18px
    fontWeight: '400', // Sørg for at skriftvekten er normal
  }}>
    Min merverdi ligger i effektivt å sette søkelys på ditt talent og aspirasjoner, noe som betydelig øker sjansene for suksess. Her gjør mitt nettverk, min forståelse av det amerikanske universitetssystemet, og min personlige tilnærming en avgjørende forskjell.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Legg til position relative til stilen
  contentArrowStyle={arrowStyle}
  date="Steg 6"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<RecordVoiceOverIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Redusert opasitet for en vannmerkeeffekt
  }}>
    <Filter6Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Kommunikasjon med Trenere:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    Effektiv kommunikasjon med trenere er essensielt. Jeg fungerer som din personlige representant for å gjøre et sterkt førsteinntrykk.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Direkte Introduksjoner:</strong> Personlige introduksjoner til trenere i mitt nettverk, tilpasset din sportsdisiplin.
    </li>
    <li>
      <strong>Kommunikasjonsstrategi:</strong> Utvikling av en effektiv kommunikasjonsstrategi for å fremheve dine kvaliteter og ambisjoner.
    </li>
    <li>
      <strong>Veiledning i Forhandlinger:</strong> Veiledning i kommunikasjon med trenere under forhandlingsfasen for sportsstipend.
    </li>
    <li>
      <strong>Løpende Støtte:</strong> Kontinuerlig støtte og råd i all kommunikasjon for å sikre at du gjør et positivt og varig inntrykk.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Min rolle er å fungere som en bro mellom deg og de amerikanske trenerne, og å skape en kommunikasjonskanal som maksimerer sjansene dine for et vellykket sportsstipend.
  </p>
  
</VerticalTimelineElement>



<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Legg til position relative til stilen
  contentArrowStyle={arrowStyle}
  date="Steg 7"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<RuleIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Redusert opasitet for en vannmerkeeffekt
  }}>
    <Filter7Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Utvelgelse og Stipendtilbud:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    Utvelgelsesprosessen er avgjørende; dette er hvor fruktene av vårt felles arbeid kommer til syne i form av stipendtilbud.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Vurdering av Tilbud:</strong> Sammen går vi gjennom de mottatte tilbudene og diskuterer fordeler og ulemper ved hver mulighet.
    </li>
    <li>
      <strong>Strategisk Rådgivning:</strong> Jeg gir strategiske råd for å hjelpe deg med å ta det beste valget for din sports- og akademiske fremtid.
    </li>
    <li>
      <strong>Støtte i Forhandlinger:</strong> Min erfaring med forhandlinger brukes for å realisere de mest gunstige betingelsene for deg.
    </li>
    <li>
      <strong>Endelig Valg:</strong> Med min hjelp tar du et velinformert endelig valg der din sportskarriere og utdanning i USA begynner.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Min veiledning i denne fasen er rettet mot å sikre det best mulige utfallet for dine ambisjoner og sikre din fremtid ved et toppuniversitet i Amerika.
  </p>
  
</VerticalTimelineElement>

<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Legg til position relative til stilen
  contentArrowStyle={arrowStyle}
  date="Steg 8"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<DescriptionIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Redusert opasitet for en vannmerkeeffekt
  }}>
    <Filter8Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Visumsøknad og Innmeldingsprosess:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    Etter utvelgelsen begynner en kritisk fase: å fullføre din visumsøknad og innmeldingsprosedyrer.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Visumsøknad:</strong> Veiledning i å søke om det riktige studentvisumet, inkludert å samle nødvendig dokumentasjon.
    </li>
    <li>
      <strong>Hjelp til Innmelding:</strong> Hjelp til å fullføre alle innmeldingsskjemaer og dokumenter for ditt valgte universitet og konkurranse.
    </li>
    <li>
      <strong>Finansiell Planlegging:</strong> Råd om finansielle saker som stipendavgifter, skolepenger og levekostnader i USA.
    </li>
    <li>
      <strong>Siste Sjekk:</strong> En grundig siste sjekk for å sikre at alle administrative og logistiske aspekter er adressert.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Min veiledning i forberedelsene sikrer at du starter ditt amerikanske eventyr med selvtillit og godt informert.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Legg til position relative til stilen
  contentArrowStyle={arrowStyle}
  date="Steg 9"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<ChecklistIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Redusert opasitet for en vannmerkeeffekt
  }}>
    <Filter8Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Forberedelser til Avreise:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    En smidig overgang til USA krever nøye forberedelser; de siste ordningene blir nå gjort.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Sjekkliste:</strong> En omfattende sjekkliste for å sikre at ingenting er glemt, fra essensielle dokumenter til personlige nødvendigheter.
    </li>
    <li>
      <strong>Bolig:</strong> Assistanse med å finne passende bolig nær ditt universitet og med de riktige fasilitetene.
    </li>
    <li>
      <strong>Forsikring:</strong> Råd om de riktige forsikringene for helse, reise og opphold.
    </li>
    <li>
      <strong>Orientering:</strong> En orienteringssesjon om kulturelle forskjeller, livet i USA og praktiske tips for dagliglivet.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Min veiledning i forberedelsene sikrer at du starter ditt amerikanske eventyr med selvtillit og godt informert.
  </p>
  
</VerticalTimelineElement>
<VerticalTimelineElement
  className="vertical-timeline-element--work"
  contentStyle={{ ...boxStyle, position: 'relative' }} // Legg til position relative til stilen
  contentArrowStyle={arrowStyle}
  date="Steg 10"
  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  icon={<FlightTakeoffIcon />}
>
  <div style={{
    position: 'absolute',
    top: '20px',
    right: '20px',
    opacity: 0.5 // Redusert opasitet for en vannmerkeeffekt
  }}>
    <Filter9Icon style={{ color: 'grey', fontSize: '48px' }} />
  </div>
  <h3 className="vertical-timeline-element-title">Avreise og Ankomst i USA:</h3>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
    marginBottom: '10px',
  }}>
    Det endelige steget i forberedelsene dine er her: reisen til Amerika.
  </p>

  <ul style={{ 
    listStyleType: 'disc', 
    marginLeft: '20px', 
    fontSize: '17px', 
  }}>  
    <li>
      <strong>Forberedelse til Avreise:</strong> Jeg sikrer at du er fullstendig forberedt til avreisen din, med en detaljert sjekkliste og endelig rådgivning.
    </li>
    <li>
      <strong>Farveløyeblikket:</strong> Et øyeblikk for å reflektere over denne betydningsfulle milepælen og for å si farvel før du forfølger drømmen din.
    </li>
    <li>
      <strong>Ankomst i USA:</strong> Ved ankomst tilbyr jeg støtte for å hjelpe deg med å finne deg til rette og navigere i ditt nye miljø.
    </li>
    <li>
      <strong>De Første Dagene:</strong> De første dagene er spennende, og jeg sørger for at du mottar støtten du trenger for å komme godt i gang.
    </li>
  </ul>
  <p style={{ 
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '17px',
    fontWeight: '400',
  }}>
    Jeg står ved din side ved hvert steg, fra avgang i Nederland til din ankomst på campus i USA, klar til å starte dine studier og idrettskarriere.
  </p>
  
</VerticalTimelineElement>

<VerticalTimelineElement
  iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
  icon={<SportsScoreIcon />}
/>
</VerticalTimeline>

  );
}

export default Timeline;
