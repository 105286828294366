import { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import SimplePricingCard from "examples/Cards/PricingCards/SimplePricingCard";
import SimplePricingCardRibbon from "examples/Cards/PricingCards/SimplePricingCardRibbon";
import { PopupButton } from "react-calendly";
import { Link } from 'react-scroll';
import TextField from "@mui/material/TextField"; // Aggiungi questa riga
import Box from '@mui/material/Box'; // Assicurati di importare Box
import emailjs from 'emailjs-com';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Link as RouterLink } from 'react-router-dom';

function Servizi() {
  const [activeTab, setActiveTab] = useState(0);
  const [tabType, setTabType] = useState("mensile");
  const [showModal, setShowModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [email, setEmail] = useState("");
  const [isAgreed, setIsAgreed] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  const handleTabType = ({ currentTarget }, newValue) => {
    setActiveTab(newValue);
    setTabType(currentTarget.id);
  };

  const handleEmailSubmit = async () => {
    if (email && isAgreed) {
      const emailParams = {
        from_email: email,
        // altri parametri se necessario
      };
  
      emailjs.send('service_sj82src', 'template_2eyxhno', emailParams, 'xOBnOFlYt964jvT3q')
        .then((response) => {
          console.log('Email inviata con successo', response.status, response.text);
          alert('Link richiesto, controlla la tua casella di posta.');
          setShowEmailModal(false);
          setEmail("");
          setIsAgreed(false);
        }, (error) => {
          console.log('Invio email non riuscito', error);
          alert('Richiesta fallita, riprova.');
        });
    } else if (!isAgreed) {
      alert('Si prega di accettare l\'informativa sulla privacy.');
    } else {
      alert('Inserisci un indirizzo email, per favore');
    }
  };

  return (
    <section id="dienstenSection">
      <Container sx={{ pb: { xs: 4, lg: 4 }, pt: 12 }}>
        {/* Modale di inserimento dell'email */}
        <Modal
          open={showEmailModal}
          onClose={() => setShowEmailModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              width: { xs: '90%', sm: '70%', md: '50%', lg: '40%' },
              maxWidth: '500px',
              maxHeight: '90vh',
              overflowY: 'auto',
            }}
          >
            <TextField
              label="Indirizzo Email"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ mb: 2 }}
            />
            {/* Checkbox per l'accordo sulla privacy */}
            <FormControlLabel
              control={<Checkbox checked={isAgreed} onChange={(e) => setIsAgreed(e.target.checked)} />}
              label={
                <span>
                  Ho letto la{' '}
                  <RouterLink 
                    to="/Privacy-Policy" 
                    component="a" 
                    target="_blank" 
                    style={{ textDecoration: 'none' }}>
                     informativa sulla privacy
                  </RouterLink> e accetto.
                </span>
              }
              sx={{ my: 2 }}
            />

            <MKButton variant="gradient" color="dark" onClick={handleEmailSubmit}>
              Invia
            </MKButton>
          </Box>
        </Modal>

        <Grid container item xs={12} justifyContent="center" md={8} mx="auto" textAlign="center">
          <Grid item xs={12}> {/* Larghezza intera per l'intestazione */}
            <MKTypography variant="h3" color="text">Servizi</MKTypography>
          </Grid>
          <Grid item xs={12}> {/* Larghezza intera per il testo */}
            <MKTypography variant="body2" color="text">
              Tutti i servizi offerti da Sport Scholarships America
            </MKTypography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={10}
          lg={8}
          justifyContent="center"
          textAlign="center"
          mx="auto"
          mt={6}
        >
          <AppBar position="static">
            <Tabs value={activeTab} onChange={handleTabType}>
              <Tab
                id="mensile"
                label={
                  <Link 
                    to="dienstenSection" 
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-70}
                    style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    <MKBox py={0.5} px={2} color="inherit">
                      Servizi
                    </MKBox>
                  </Link>
                }
              />
              <Tab
                id="annuale"
                label={
                  <Link 
                    to="productenSection" 
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-70}
                    style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    <MKBox py={0.5} px={2} color="inherit">
                      Prodotti
                    </MKBox>
                  </Link>
                }
              />
            </Tabs>
          </AppBar>
        </Grid>
        <Grid container spacing={3} mt={6}>
          <Modal open={showModal} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
            <Slide direction="down" in={showModal} timeout={500}>
              <MKBox
                position="relative"
                width="500px"
                display="flex"
                flexDirection="column"
                borderRadius="xl"
                bgColor="white"
                shadow="xl"
              >
                <MKBox display="flex" alginItems="center" justifyContent="space-between" p={2}>
                  <MKTypography variant="h5">
                    Scegli una consultazione iniziale
                  </MKTypography>
                  <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
                </MKBox>
                <Divider sx={{ my: 0 }} />
                <MKBox p={2}>
                  <MKTypography variant="body2" color="secondary" fontWeight="regular">
                    Gentile cliente,
                    <br />
                    <br />
                    Sono lieto che tu abbia interesse nei miei servizi standard! Per assicurarti che i miei servizi si adattino perfettamente alle tue esigenze specifiche, ti consiglio vivamente di prenotare prima una consultazione iniziale gratuita e senza impegno.
                    <br />
                    <br />
                    Durante questa conversazione, potremo discutere dettagliatamente la tua situazione e determinare insieme quale approccio sia meglio per te. Questo garantisce un'esperienza più personalizzata e mi aiuta a ottimizzare il servizio per la tua situazione unica.
                  </MKTypography>
                </MKBox>
                <Divider sx={{ my: 0 }} />
                <MKBox display="flex" justifyContent="space-between" p={1.5}>
                  <MKButton variant="gradient" color="dark" onClick={toggleModal}>
                    Chiudi
                  </MKButton>
                  <PopupButton
                    url="https://calendly.com/kay-duit"
                    rootElement={document.getElementById("root")}
                    text="PRENOTA APPUNTAMENTO"
                    className="custom-calendly-button"
                  />
                </MKBox>
              </MKBox>
            </Slide>
          </Modal>
          <Grid item xs={12} sm={6} lg={3}>
            <SimplePricingCard
              color="dark"
              title="Guida alle informazioni"
              description="Guida completa alle informazioni in PDF"
              price={{ value: "Gratuita", type: "" }}
              action={{
                type: "downloadPDF",
                label: "Scarica PDF",
                onClick: () => setShowEmailModal(true) // Funzione per mostrare il modal di inserimento dell'email
              }}
              specifications={["Guida completa alle informazioni in PDF"]}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <SimplePricingCardRibbon
              color="dark"
              title="Colloquio di ammissione"
              description="Consulenza online di 60 minuti"
              price={{ value: "€ 14,99*", type: "" }}
              action={{
                type: "external", // Cambia in "external" per Calendly
                route: "https://calendly.com/kay-duit", // Il tuo link Calendly
                label: "PRENOTA APPUNTAMENTO"
              }}
              specifications={[
                "Allineamento di obiettivi e ambizioni",
                "Valutazione delle prestazioni sportive",
                "Piano di integrazione accademica",
                "Strategia di percorso individuale",
                "Analisi delle possibilità di successo negli Stati Uniti"
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <SimplePricingCard
              color="dark"
              title="Standard"
              description="Il tuo percorso verso gli Stati Uniti"
              price={{ value: "Su richiesta", type: "" }}
              action={{
                type: "modal",
                label: "Richiedi",
                onClick: toggleModal
              }}
              specifications={[
                "Piano personalizzato",
                "Supporto nella creazione di un portfolio sportivo",
                "Supporto nella preparazione accademica",
                "Promozione presso le università",
                "Negoziazione di borse di studio sportive",
                "Consigli sulla selezione di università e borse di studio",
                "Supporto nella richiesta del visto",
                "Supporto nell'iscrizione",
                "Supporto nelle questioni pratiche",
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <SimplePricingCard
              color="dark"
              title="Premium"
              description="Il tuo percorso verso gli Stati Uniti"
              price={{ value: "Su richiesta", type: "" }}
              action={{
                type: "modal",
                label: "Richiedi",
                onClick: toggleModal
              }}
              specifications={[
                "Piano personalizzato",
                "Supporto nella creazione di un portfolio sportivo",
                "Supporto nella preparazione accademica",
                "Promozione presso le università",
                "Negoziazione di borse di studio sportive",
                "Consigli sulla selezione di università e borse di studio",
                "Supporto nella richiesta del visto",
                "Supporto nell'iscrizione",
                "Supporto nelle questioni pratiche",
                "Libri di studio SAT/ACT",
                "Chiamata Zoom bisettimanale",
              ]}
            />
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid item xs={12} style={{ marginBottom: '80px' }}>
          <MKTypography variant="body2" color="text">
            {/* Il tuo testo va qui */}
            *In passato offrivo il colloquio di ammissione gratuitamente. Purtroppo, ciò ha portato a molti appuntamenti con persone non serie, il che era costoso in termini di tempo. Per garantire la qualità dei miei servizi e assicurare che le conversazioni siano valide per entrambe le parti, ora chiedo una piccola tariffa di €14,99. Questo importo aiuta a filtrare le richieste serie, mantenendo al contempo la conversazione informale e focalizzata sulle tue esigenze specifiche.

Apprezzo la tua comprensione e non vedo l'ora di avere una conversazione produttiva e illuminante con te.
          </MKTypography>
        </Grid>
      </Container>
    </section>
  );
}

export default Servizi;
