


import React from "react";


// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbarNorsk";

import { Helmet } from 'react-helmet';

// Coworking page sections
import ContactUsTwo from "layouts/sections/input-areas/contact-sections/components/ContactUsTwoNorsk";

// Routes
import routes from "routes_Norsk";import footerRoutes from "footer.routes_Norsk";

// Images
import DetailedFooter from "examples/Footers/DetailedFooter";

function Contactformulier() {
  return (
    <>
<Helmet>
    <title>Kontakt Meg - Sport Scholarships America</title>
    <meta name="description" content="Har du spørsmål eller ønsker mer informasjon om idrettsstipend i Amerika? Bruk kontaktskjemaet for å komme i direkte kontakt med meg." />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta charset="UTF-8" />
    <meta name="robots" content="index, follow" />
    
    {/* Open Graph Tags */}
    
    
    
    
    
    {/* Favicon */}
    <link rel="icon" href="https://sportscholarshipsamerica.com/32x32Sportbeurs.png" />
</Helmet>


  <MKBox pt={0}>  {/* Apply padding here */}
  <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://calendly.com/sportbeurs-amerika/intake",
          label: "MAAK AFSPRAAK",
          color: "info",
        }}
      
         
      />
  </MKBox>

     
     <MKBox pt={10}>  {/* Adjust the value as needed */}
  <ContactUsTwo />
</MKBox>

<MKBox pt={0} px={1} mt={-10}>
 <DetailedFooter content={footerRoutes} />
</MKBox>
    </>
  );
}

export default Contactformulier;
