/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Pagina del prodotto: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Codificato da www.creative-tim.com

 =========================================================

* La notifica di copyright sopra riportata e questa notifica di autorizzazione devono essere incluse in tutte le copie o porzioni sostanziali del Software.
*/

import { useState } from "react";

// Componenti materiali @mui
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Componenti Material Kit 2 PRO React
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton"; // Importa MKButton
import { Link } from 'react-router-dom';

// Componenti della pagina di noleggio
import FaqCollapse from "Languages/Italiano/pages/Homepage/LandingPage/sections/FAQfunction";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <MKBox component="section" py={8}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
              Domande frequenti
            </MKTypography>
            <MKBox mb={2}>
              <MKTypography variant="body2" align="center" color="text">
              Tutto quello che vuoi sapere: risposte alle domande più frequenti sullo studio e lo sport in America e sui miei servizi. Se la tua domanda non è elencata, ti preghiamo di contattarci.              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={10}>
          <FaqCollapse
   title="Quanto costa studiare in America?"
   open={collapse === 1}
   onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
>
   Le tasse universitarie e il costo degli studi in America variano notevolmente a seconda dell'università, del programma di studio e della posizione. In media, gli studenti internazionali possono aspettarsi una spesa annuale totale di circa $30.000 fino a $70.000 o più. Tuttavia, vale la pena notare che molti studenti possono coprire una parte significativa (o talvolta l'intero importo) di questi costi attraverso borse di studio sportive, borse di studio accademiche o una combinazione di entrambe. Le università americane offrono spesso borse di studio sportive ad atleti talentuosi, fornendo supporto finanziario in cambio della loro partecipazione ai programmi sportivi universitari. Ciò consente agli studenti-atleti di perseguire i loro obiettivi accademici e sportivi senza gravose spese finanziarie.
</FaqCollapse>

<FaqCollapse
   title="Qual è la differenza tra un college e un'università in America?"
   open={collapse === 2}
   onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
>
   La terminologia può essere confusa, specialmente per le persone al di fuori degli Stati Uniti. Nella pratica, "college" e "università" non si riferiscono effettivamente alla stessa cosa. Un "università" è un termine completo per un'istituzione di istruzione superiore che offre programmi a tutti i livelli, compresi lauree, master e dottorati.

   Un "college", d'altra parte, di solito fa parte di un'università e si concentra principalmente su programmi per studenti universitari, come lauree triennali. In altre parole, un college è un dipartimento all'interno di un'università dedicato specificamente all'istruzione per studenti universitari. Le università offrono tipicamente programmi per studenti universitari e programmi per laureati, mentre i college si concentrano sul livello universitario.

   Tuttavia, questa distinzione può variare tra diverse istituzioni e talvolta i termini vengono usati in modo intercambiabile. Pertanto, è utile esaminare i dettagli specifici e i programmi di un'istituzione educativa per capire cosa offrono.
</FaqCollapse>

<FaqCollapse
   title="Quali atleti sono guidati da Sport Scholarships America?"
   open={collapse === 3}
   onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
>
   Guido atleti in una vasta gamma di sport. La mia solida rete e le mie partnership in America mi permettono di sostenere atleti in diverse discipline. Quindi, che tu giochi a calcio, basket, tennis, golf o qualsiasi altro sport, sono pronto ad aiutarti a perseguire i tuoi obiettivi accademici e sportivi negli Stati Uniti. Per un elenco completo degli sport, <Link to="/it/Tutti-gli-sport" style={{ fontWeight: 'bold' }}>clicca qui</Link>.
</FaqCollapse>

<FaqCollapse
   title="Qual è il processo di candidatura tipico e quanto tempo prima dovrei iniziare?"
   open={collapse === 4}
   onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
>
   Il processo inizia di solito con un incontro introduttivo completo in cui discutiamo tutti i dettagli e comprendiamo la tua situazione e i tuoi obiettivi specifici. Spiegherò anche ulteriormente il mio approccio. La durata dell'intero processo può variare, ma in generale, raccomando di iniziare almeno 6-12 mesi prima. È importante sottolineare che più inizi presto, più opportunità potrebbero presentarsi, quindi non esitare a iniziare il processo in anticipo.

   Durante questo periodo, collaboreremo per creare un piano personalizzato che tenga conto di tutti gli aspetti, come i test di ammissione, le domande di visto, la creazione di video sportivi e altro ancora. Ogni percorso è unico e su misura, poiché ci sono molti fattori da considerare. Per una panoramica dettagliata dell'intero processo, puoi <Link to="/it/Processo" style={{ fontWeight: 'bold' }}>cliccare qui</Link>. Questo fornisce una visione passo dopo passo di cosa aspettarsi durante il tuo percorso per studiare e competere negli Stati Uniti.
</FaqCollapse>

<FaqCollapse
   title="Quali sono le tariffe per i servizi che offri?"
   open={collapse === 5}
   onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
>
   I costi dei miei servizi variano in base alle tue esigenze e obiettivi specifici. La conversazione iniziale è un passo importante in cui discutiamo della tua situazione e sviluppiamo un piano personalizzato. È anche l'opportunità per stabilire se vogliamo procedere a lavorare insieme.

   Dopo questa conversazione, sarò in grado di avere una comprensione chiara dei tuoi desideri e delle tue esigenze e, in base a ciò, potrò fornirti una stima dei costi appropriata.

   Per una panoramica completa dei servizi e dei prodotti che offro, puoi <Link to="/it/Servizi-Prodotti" style={{ fontWeight: 'bold' }}>cliccare qui</Link>. Qui troverai informazioni dettagliate su come posso aiutarti nella tua ricerca di studio e competizione negli Stati Uniti.
</FaqCollapse>

<FaqCollapse
   title="Perché dovrei scegliere di essere guidato da Sport Scholarships America?"
   open={collapse === 6}
   onClick={() => (collapse === 6 ? setCollapse(false) : setCollapse(6))}
>
   Quando ti trovi a un bivio nel realizzare i tuoi sogni, come lo ero io, possono sorgere molte domande e dubbi. La scelta di farlo da soli, di collaborare con un'agenzia o addirittura di abbandonare l'intera idea può essere travolgente. Capisco profondamente questa incertezza perché l'ho vissuta personalmente.

   Sarebbe un peccato se questi dubbi impedissero a qualcuno di perseguire ciò che è veramente possibile: un'esperienza fantastica che aspetta di essere scoperta. Ecco perché sono dedicato ad aiutare gli altri a realizzare i loro sogni.

   Metto in pratica le mie esperienze personali essendo direttamente coinvolto in ogni progetto. Capisco le domande che potresti avere e mi sforzo di fornire un approccio personalizzato che sia in linea con le tue esigenze uniche. Insieme, possiamo dare vita alla tua visione, sostenuti dalla conoscenza e dalla comprensione che provengono da un'esperienza reale e vissuta.
</FaqCollapse>

          </Grid>
        </Grid>
        {/* Sezione dei pulsanti */}
        <Grid container justifyContent="center" mt={4}>
          <Grid item xs={12} md={3}>
          <MKButton variant="gradient" color="light" fullWidth component={Link} to="/it/FAQs">
    Visualizza l'intera FAQ
</MKButton>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Faq;
