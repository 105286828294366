import Alabama from "assets/images/Staten_Images/Alabama.webp"
import Florida from "assets/images/Staten_Images/Florida.jpeg"
import Connecticut from "assets/images/Staten_Images/Connecticut.jpeg"
import Kentucky from "assets/images/Staten_Images/Kentucky.webp"
import Michigan from "assets/images/Staten_Images/Michigan.jpeg"
import North_Carolina from "assets/images/Staten_Images/North_Carolina.jpg"
import Texas from "assets/images/Staten_Images/Texas.jpeg"



const states = [
  {
    "name": "Alabama",
    image: Alabama,
    "imageDescription": "Alabama vs Auburn é uma das maiores rivalidades do futebol universitário.",

    "description": [
      "Os esportes universitários são parte integrante da cultura em Alabama, com um foco especial no futebol americano. A University of Alabama (UA) em Tuscaloosa e a Auburn University em Auburn dominam o cenário esportivo no estado.",
      "O time de futebol americano da UA, o Crimson Tide, tem uma história rica e conquistou inúmeros campeonatos nacionais. Os jogos em casa no imenso Bryant-Denny Stadium podem atrair até 100.000 fãs. A paixão dos torcedores é enorme, e o coro 'Roll Tide' é conhecido em todo o Alabama.",
      "Por outro lado, temos a Auburn University com seu time de futebol americano, os Tigers. Eles, junto com a UA, compõem o Iron Bowl, uma das rivalidades mais intensas nos esportes universitários. Este confronto anual vira o estado de cabeça para baixo e garante emoção e espetáculo.",
      "Além do futebol americano, Alabama tem programas fortes em basquete, beisebol e outros esportes, apoiados por um forte senso de espírito comunitário. Em Alabama, os esportes são mais do que apenas passatempos; são uma parte essencial do estilo de vida e identidade do estado."
    ]
  },

  {
    "name": "Alaska",
    "description": [
      "Ao contrário de muitos outros estados americanos, os esportes universitários desempenham um papel mais modesto no Alasca, principalmente devido à sua localização remota e clima único. Aqui, o foco está mais nos esportes de inverno e atividades ao ar livre do que nos esportes tradicionais americanos como futebol e beisebol.",
      "A University of Alaska Anchorage (UAA) e a University of Alaska Fairbanks (UAF) são as principais instituições com programas esportivos ativos. UAA tem um forte time de esqui e patinação, enquanto UAF é conhecida por seu time de hóquei no gelo, os Nanooks, que têm um fiel seguimento local.",
      "No Alasca, os esportes universitários são mais sobre a experiência e a comunidade do que sobre eventos em grande escala. Os jogos proporcionam uma oportunidade para a comunidade se reunir, torcer pelos atletas e celebrar a cultura esportiva única do estado.",
      "Apesar da escala menor, os esportes universitários no Alasca têm seu próprio charme e oferecem uma comunidade acolhedora no ambiente frio do estado."
    ]
  },

  {
    "name": "Arizona",
    "description": [
      "Os esportes universitários são imensamente populares em Arizona, com uma rica tradição tanto no futebol americano quanto no basquete. A Arizona State University (ASU) em Tempe e a University of Arizona (UA) em Tucson são os pilares do cenário esportivo do estado.",
      "O time de futebol americano da ASU, os Sun Devils, desfruta de um seguidor apaixonado, enquanto seu time de basquete brilha regularmente em torneios da NCAA. UA, com seus Wildcats, é conhecida por seu bem-sucedido programa de basquete e intensa rivalidade com ASU, culminando no jogo anual 'Duel in the Desert' de futebol americano.",
      "Além dos principais esportes, Arizona também se destaca no beisebol, softbol e golfe, com times universitários frequentemente em destaque nacional. O apoio da comunidade é esmagador, e os eventos esportivos são considerados ocasiões sociais significativas.",
      "Os esportes universitários em Arizona incorporam o espírito de competição e comunidade, com rivalidade intensa e paixão dos fãs contribuindo para uma cultura esportiva vibrante e animada."
    ]
  },

  {
    "name": "Arkansas",
    "description": [
      "No Arkansas, os esportes universitários desempenham um papel central na vida comunitária, com um foco especial no futebol americano e basquete. A University of Arkansas em Fayetteville é uma potência no estado, conhecida por seus Razorbacks.",
      "O time de futebol americano dos Razorbacks desfruta de reconhecimento regional e nacional, com uma base de fãs dedicada que segue apaixonadamente cada jogo. Seus jogos em casa no impressionante Razorback Stadium são um espetáculo, onde o coro 'Woo Pig Sooie' é ecoado por dezenas de milhares de fãs.",
      "O basquete também é uma parte significativa da cultura esportiva no Arkansas, com os Razorbacks participando regularmente do torneio da NCAA. A emoção e a energia na Bud Walton Arena durante a temporada de basquete são palpáveis.",
      "Além dos principais esportes, os Razorbacks também têm programas fortes em atletismo, beisebol e softbol. Essa diversidade esportiva enriquece a cultura esportiva no Arkansas, onde os esportes universitários são uma fonte de orgulho, espírito comunitário e entretenimento."
    ]
  },
  
  {
    "name": "California",
    "description": [
      "Os esportes universitários na Califórnia são diversos e altamente competitivos, com inúmeras universidades se destacando em uma variedade de esportes. Do futebol americano à natação, o estado tem de tudo.",
      "A conferência Pac-12, com escolas proeminentes como a University of California, Los Angeles (UCLA) e a University of Southern California (USC), é conhecida por seu alto nível esportivo. O time de basquete da UCLA tem uma história rica, enquanto a USC tradicionalmente se destaca no futebol.",
      "Além dos times conhecidos, também existem escolas menores com programas esportivos impressionantes, como a Stanford University, conhecida por sua excelência na natação e atletismo. Essa diversidade cria um ambiente esportivo dinâmico e emocionante no estado.",
      "O clima agradável da Califórnia e suas extensas instalações esportivas promovem a participação ativa e o entusiasmo pelos esportes universitários. Seja um jogo de futebol americano em um estádio lotado ou uma competição local de natação, os esportes são parte integrante do estilo de vida californiano."
    ]
  },

  {
    "name": "Colorado",
    "description": [
      "Os esportes universitários no Colorado desfrutam de um seguimento vibrante e entusiasmado, em parte devido aos impressionantes arredores naturais e ao estilo de vida ao ar livre ativo. O estado tem uma rica tradição no futebol americano, basquete e esportes de inverno.",
      "A University of Colorado Boulder (CU) e a Colorado State University (CSU) são jogadores proeminentes no cenário esportivo universitário, com o jogo anual de futebol 'Rocky Mountain Showdown' como destaque de sua rivalidade.",
      "O time de futebol americano da CU, os Buffaloes, têm uma base de fãs apaixonada, enquanto seu time de esqui se destaca em competições nacionais, beneficiando-se das excelentes instalações de esportes de inverno do Colorado. A CSU também é competitiva em diversos esportes, com uma forte presença no futebol americano e basquete.",
      "Além das universidades maiores, faculdades menores no Colorado também têm programas esportivos ativos, contribuindo para a cultura esportiva rica e diversificada no estado. Os esportes universitários no Colorado representam a comunidade, perseverança e a celebração dos esportes em todas as formas."
    ]
  },

  {
    "name": "Connecticut",
    image: Connecticut,
    "imageDescription": "O time de basquete feminino da UConn é um dos times de esportes universitários mais dominantes de todos os tempos",

    "description": [
      "Os esportes universitários em Connecticut são dominados pelo basquete, com um foco especial nos programas bem-sucedidos da University of Connecticut (UConn). O estado tem uma rica tradição e uma base de fãs apaixonada profundamente investida nos esportes universitários.",
      "O time de basquete feminino da UConn é nacionalmente reconhecido e ganhou múltiplos campeonatos da NCAA, tornando-os uma potência no esporte. O time masculino também alcançou performances impressionantes, tornando o basquete uma parte central da cultura esportiva em Connecticut.",
      "Enquanto o basquete reina supremo, também existem programas fortes em outros esportes, como hóquei no gelo, futebol americano e lacrosse, com várias universidades sendo competitivas em nível nacional.",
      "Os esportes universitários em Connecticut incorporam o orgulho e o espírito comunitário do estado, onde eventos esportivos proporcionam uma oportunidade para os fãs se reunirem, torcerem pelos times e participarem de uma rica tradição esportiva."
    ]
  },
  
  {
    "name": "Delaware",
    "description": [
      "Apesar de ser um dos estados mais pequenos dos EUA, Delaware tem uma vibrante cena de esportes universitários com forte envolvimento da comunidade. A University of Delaware (UD) em Newark está no centro da ação, onde os Blue Hens se destacam em diversos esportes.",
      "O time de futebol americano da UD tem uma rica história e uma base de fãs dedicada, com emocionantes jogos em casa no Delaware Stadium. Basquete e lacrosse também são esportes populares, com times competindo regularmente por reconhecimento regional e nacional.",
      "Apesar de seu tamanho pequeno, Delaware tem programas fortes em atletismo, natação e outros esportes, todos contribuindo para a cultura esportiva dinâmica no estado. Os esportes universitários aqui são mais do que apenas um passatempo; são uma parte importante da identidade comunitária, promovendo uma forte conexão entre a universidade e a comunidade em geral.",
      "Em Delaware, os esportes universitários unem as pessoas, com a escala pequena criando uma comunidade esportiva unida e apaixonada."
    ]
  },
  {
    "name": "Florida",
    image: Florida,
    "imageDescription": "Ben Hill Griffin Stadium é o lar dos Florida Gators",

    "description": [
      "A Flórida é um centro de esportes universitários, com alguns dos programas mais competitivos e apaixonados do país. O futebol americano e o basquete dominam a cena esportiva, mas também há espaço para uma variedade de outros esportes.",
      "Times como os Florida Gators e os Florida State Seminoles têm reconhecimento nacional e uma rica história tanto no futebol americano quanto no basquete. Sua intensa rivalidade atrai milhares de fãs anualmente, e os jogos são destaques da temporada esportiva.",
      "Além disso, também existem universidades e faculdades menores com programas esportivos fortes que contribuem para a rica cultura esportiva no estado. Beisebol, atletismo e natação também são populares, e as excelentes instalações e clima ensolarado tornam a Flórida um lugar ideal para atletas universitários.",
      "Na Flórida, os esportes universitários são mais do que apenas competição; são um modo de vida, uma fonte de orgulho e uma parte essencial da identidade comunitária."
    ]
  },
  {
    "name": "Georgia",
    "description": [
      "Os esportes universitários na Geórgia são caracterizados por paixão intensa e rivalidades profundamente enraizadas, com o futebol americano como o rei indiscutível. O estado é lar de alguns dos programas mais prestigiados do país, com os fãs experimentando apaixonadamente cada jogo.",
      "A University of Georgia Bulldogs em Athens e a Georgia Tech Yellow Jackets em Atlanta são os principais jogadores, com sua rivalidade anual 'Clean, Old-Fashioned Hate' dominando o estado. Jogos de futebol, especialmente no enorme Sanford Stadium da UGA, atraem dezenas de milhares de fãs.",
      "Além do futebol americano, a Geórgia também se destaca no basquete, atletismo e beisebol, com atletas talentosos e apoiadores dedicados. Os programas esportivos em escolas e faculdades menores contribuem para um cenário esportivo rico e diversificado em todo o estado.",
      "Na Geórgia, o esporte é mais do que competição; é um modo de vida, uma fonte de espírito comunitário e uma parte indispensável da identidade regional."
    ]
  },
  {
    "name": "Hawaii",
    "description": [
      "Os esportes universitários no Havaí oferecem uma experiência única inspirada pela beleza da ilha e pela forte cultura local. Apesar de estar geograficamente isolado, equipes e fãs de esportes no Havaí são extraordinariamente apaixonados e engajados.",
      "A University of Hawaii at Manoa desempenha um papel central na cena esportiva universitária, com seu time de futebol americano, os Rainbow Warriors, como um ponto focal significativo. Seus jogos em casa no Aloha Stadium criam uma atmosfera vibrante e festiva, com um forte espírito da ilha.",
      "Além do futebol americano, vôlei, surfe e canoagem também são populares, refletindo a cultura esportiva única e a paisagem natural do Havaí. Os Rainbow Warriors também são competitivos no basquete e beisebol, com uma dedicação compartilhada por toda a comunidade.",
      "No Havaí, os esportes universitários representam o espírito Aloha, com equipes e fãs abraçando a cultura da ilha, comunidade e orgulho, criando uma experiência esportiva única e vibrante juntos."
    ]
  },
  {
    "name": "Idaho",
    "description": [
      "Os esportes universitários em Idaho têm uma forte tradição, com foco especial no futebol americano e basquete. O senso de comunidade e envolvimento em equipes esportivas é notável, mesmo que o estado não hospede grandes universidades.",
      "A Boise State University, famosa pelo seu campo de futebol azul, desempenha um papel proeminente na paisagem esportiva de Idaho. O time de futebol americano ganhou atenção nacional com seu sucesso e estilo de jogo inovador, e os jogos em casa são um espetáculo em si.",
      "Além da Boise State, escolas e faculdades menores também contribuem para a rica cultura esportiva, com programas em esportes como beisebol, atletismo e esportes de inverno se beneficiando do ambiente natural único de Idaho.",
      "Em Idaho, os esportes universitários são mais do que apenas um passatempo; são um modo de vida e uma parte essencial do estilo de vida e identidade do estado, com comunidades se unindo para apoiar suas equipes."
    ]
  },
  
  {
    "name": "Illinois",
    "description": [
      "Illinois tem uma rica história e profundo amor pelos esportes universitários, com programas poderosos e fãs dedicados. O futebol americano e o basquete atraem atenção significativa ao longo do ano, mas também há uma ampla gama de outros esportes prósperos.",
      "A University of Illinois Fighting Illini e a Northwestern University Wildcats lideram o caminho, com rivalidades intensas e ligas competitivas mantendo o estado vibrante. O Memorial Stadium e o Ryan Field são templos dos esportes, onde cada jogo é uma experiência inesquecível.",
      "O basquete também é proeminente, com o estado regularmente produzindo talentos que se destacam tanto nacional quanto internacionalmente. Os Illinois Fighting Illini têm uma rica tradição no basquete e são uma ameaça constante nos torneios da NCAA.",
      "De pequenas faculdades a grandes universidades, os esportes em Illinois servem como um elo, uma fonte de orgulho e uma parte essencial da cultura e identidade do estado."
    ]
  },
  {
    "name": "Indiana",
    "description": [
      "Indiana é conhecida por seu profundo amor pelo basquete, e isso é evidente na cultura de esportes universitários do estado. A rivalidade, paixão e dedicação tanto de jogadores quanto de fãs são incomparáveis.",
      "Programas como os Indiana Hoosiers e os Purdue Boilermakers são reconhecidos nacionalmente, especialmente no basquete. Uma atmosfera elétrica paira em locais como Assembly Hall e Mackey Arena ao longo do ano, onde história e tradição se unem.",
      "Enquanto o basquete lidera, outros esportes também prosperam em Indiana. O futebol americano, beisebol e atletismo têm programas fortes e desfrutam de amplo apoio da comunidade.",
      "O esporte em Indiana é mais do que apenas um jogo; é um modo de vida, um catalisador para o espírito comunitário e uma tradição orgulhosa transmitida através das gerações. Aqui, no coração do Meio-Oeste, os esportes universitários são uma parte integral da identidade do estado."
    ]
  },
  {
    "name": "Iowa",
    "description": [
      "Os esportes universitários em Iowa são fonte de orgulho e espírito comunitário, com uma rica tradição tanto no futebol americano quanto na luta livre. A rivalidade entre os Iowa Hawkeyes e os Iowa State Cyclones é central e proporciona momentos inesquecíveis a cada temporada.",
      "O Kinnick Stadium em Iowa City e o Jack Trice Stadium em Ames são os locais de jogos de futebol americano intensos a cada outono, atraindo dezenas de milhares de fãs. A paixão e dedicação dos fãs são características da cultura esportiva do estado.",
      "A luta livre é outro esporte no qual Iowa se destaca, com os Hawkeyes regularmente conquistando títulos nacionais. Este esporte tem uma tradição enraizada em Iowa e desfruta de um apoio leal.",
      "De faculdades pequenas a grandes universidades, os esportes em Iowa são mais do que competição; são um modo de vida, uma fonte de espírito comunitário e uma parte importante da identidade do estado."
    ]
  },
  {
    "name": "Kansas",
    "description": [
      "Os esportes universitários em Kansas estão impregnados de tradição e espírito comunitário, com o basquete como o destaque incontestável. O estado é lar de alguns dos programas mais icônicos do país, e a rivalidade e paixão são intensas.",
      "Os Kansas Jayhawks e os Kansas State Wildcats lideram o caminho, com seu 'Sunflower Showdown' anual sendo um dos destaques do ano esportivo. A Allen Fieldhouse, lar dos Jayhawks, é conhecida como uma das arenas mais intimidadoras do basquete universitário.",
      "Enquanto o basquete forma o coração da cultura esportiva em Kansas, o futebol americano, beisebol e atletismo também desempenham um papel significativo. Os fãs são incomparáveis em sua dedicação e contribuem para a atmosfera vibrante durante os jogos.",
      "Os esportes em Kansas são mais do que apenas competição; são uma fonte de orgulho, um catalisador para a comunidade e uma parte essencial da identidade do estado."
    ]
  },
  {
    "name": "Kentucky",
    image: Kentucky,
    "imageDescription": "Karl-Anthony Towns e Devin Booker jogaram juntos pelo Kentucky e depois se tornaram grandes estrelas da NBA",
  
    "description": [
      "Os esportes universitários em Kentucky são sinônimo de basquete, apresentando uma rica tradição e uma base de fãs apaixonada. A rivalidade entre os Kentucky Wildcats e os Louisville Cardinals é uma das mais intensas e assistidas do país.",
      "A Rupp Arena, o lar dos Wildcats, é um verdadeiro templo do basquete, onde a história e o orgulho do programa são palpáveis. Os Wildcats têm vários campeonatos nacionais em seu nome e são uma instituição no basquete universitário.",
      "Além do basquete, Kentucky também tem programas fortes em futebol americano, esportes equestres e tiro, todos os quais enriquecem a cultura esportiva do estado. O Kentucky Derby, uma lendária corrida de cavalos, destaca a rica tradição nos esportes equestres.",
      "Em Kentucky, os esportes são mais do que um passatempo; são um modo de vida, uma fonte de comunidade e orgulho, e uma parte crucial da identidade do estado."
    ]
  },
  {
    "name": "Louisiana",
    "description": [
      "Na Louisiana, os esportes universitários são uma parte integrante da cultura, com uma paixão profundamente enraizada pelo futebol americano e beisebol. Os LSU Tigers da Louisiana State University (LSU) são uma potência, atraindo multidões enormes e criando uma atmosfera incomparável.",
      "O Tiger Stadium, também conhecido como 'Death Valley', é famoso por seus fãs barulhentos e atmosfera intimidadora, tornando-se um dos campos de casa mais temidos no futebol universitário. 'Sábado à Noite no Vale da Morte' é mais do que um jogo; é um evento, uma tradição, uma parte da alma da Louisiana.",
      "Além da LSU, universidades e faculdades menores também contribuem para a rica paisagem esportiva, com programas fortes em diversos esportes. O beisebol também desfruta de grande popularidade, com a LSU competindo regularmente pelo título nacional.",
      "Os esportes na Louisiana são uma celebração da comunidade, uma fonte de orgulho e um elo que une as pessoas, independentemente de sua origem. É uma parte vibrante e essencial do estilo de vida e identidade do estado."
    ]
  },
  {
    "name": "Maine",
    "description": [
      "Os esportes universitários no Maine refletem a natureza única do estado e o senso de comunidade, com um forte foco em esportes de inverno e atletismo. A University of Maine Black Bears lidera o caminho, representando orgulhosamente em várias divisões da NCAA.",
      "O Alfond Stadium em Orono é o coração do futebol americano no estado, enquanto a Harold Alfond Sports Arena desempenha um papel central na temporada de hóquei no gelo. Os Black Bears têm uma orgulhosa tradição no hóquei no gelo, com fãs entusiasmados enfrentando o frio para apoiar sua equipe.",
      "Além do hóquei no gelo e do futebol americano, também existem programas fortes em atletismo, remo e outros esportes que enriquecem a paisagem atlética do Maine. O estado também desfruta de competições vibrantes de esportes em escolas secundárias e clubes, tornando os esportes uma parte integral da comunidade.",
      "Os esportes no Maine são mais do que apenas competição; eles são uma fonte de orgulho, uma forma de celebrar o espírito único do estado e um elo que une as pessoas, independentemente da estação."
    ]
  },
  {
    "name": "Maryland",
    "description": [
      "Os esportes universitários em Maryland prosperam com uma forte tradição em basquete, lacrosse e futebol americano. A University of Maryland Terrapins, ou simplesmente Terps, é central para a cultura esportiva do estado.",
      "Xfinity Center e Maryland Stadium são as arenas orgulhosas para basquete e futebol americano, onde os Terps jogam suas partidas em casa. Os fãs são fervorosos e contribuem para uma atmosfera eletrizante durante os jogos. Maryland é conhecida por seu programa competitivo de basquete, com múltiplas aparições no torneio da NCAA.",
      "O lacrosse é outro esporte em que Maryland se destaca, com os Terps competindo regularmente por títulos nacionais. Este esporte tem um lugar especial nos corações dos residentes, e rivalidades universitárias intensas tornam cada temporada um espetáculo.",
      "Os esportes em Maryland são uma expressão de comunidade, orgulho e tradição, desempenhando um papel crucial em reunir as pessoas e celebrar a cultura única do estado."
    ]
  },
  {
    "name": "Massachusetts",
    "description": [
      "Em Massachusetts, os esportes universitários desempenham um papel significativo na vida cotidiana, com uma rica história em basquete, futebol americano e hóquei no gelo. As universidades aqui têm programas esportivos fortes e fãs apaixonados.",
      "O basquete ocupa um lugar especial nos corações dos residentes de Massachusetts, pois o jogo foi inventado aqui. Os Minutemen da University of Massachusetts, conhecidos como UMass Minutemen, são orgulhosos representantes na Divisão I da NCAA no basquete.",
      "O futebol americano e o hóquei no gelo também são populares, com intensas rivalidades locais proporcionando competições emocionantes. O jogo de futebol entre Harvard e Yale, conhecido como 'The Game', é um destaque anual.",
      "Os esportes em Massachusetts são sobre tradição, comunidade e competição. Eles unem as pessoas, independentemente de sua origem, e são uma parte essencial da identidade cultural do estado."
    ]
  },
  {
    "name": "Michigan",
    image: Michigan,
    "imageDescription": "Estádio Michigan: o terceiro maior estádio do mundo e lar dos Michigan Wolverines. Pode abrigar 107.601 fãs.",
    "description": [
      "Michigan tem uma rica tradição em esportes universitários, especialmente em futebol americano e basquete. A rivalidade entre os Michigan Wolverines da University of Michigan e os Michigan State Spartans é lendária e cria uma atmosfera elétrica em todo o estado.",
      "Michigan Stadium, também conhecido como 'The Big House', é um dos maiores estádios do mundo, acomodando mais de 100.000 fãs. Os Wolverines têm uma história gloriosa, com múltiplos títulos nacionais no futebol.",
      "O basquete também é muito popular, com Michigan e Michigan State competindo regularmente no torneio da NCAA. A paixão e dedicação dos fãs são incomparáveis, e os dias de jogo são verdadeiros eventos em Michigan.",
      "Além desses esportes principais, Michigan também tem programas fortes em hóquei no gelo, atletismo e outros esportes. Os esportes em Michigan são mais do que apenas um passatempo; são uma parte integral do estilo de vida e identidade do estado."
    ]
  },
    
  {
    "name": "Minnesota",
    "description": [
      "Os esportes universitários em Minnesota têm um caráter único, com um forte foco em hóquei no gelo, futebol americano e basquete. Os Minnesota Golden Gophers da University of Minnesota desempenham um papel central na comunidade esportiva do estado.",
      "O TCF Bank Stadium e a Williams Arena são centros movimentados de atividades durante as temporadas de futebol americano e basquete, enquanto a Mariucci Arena é um reduto para o hóquei no gelo. Os Golden Gophers são representantes orgulhosos na NCAA, com uma história rica e fãs devotados.",
      "O hóquei no gelo é particularmente popular em Minnesota, muitas vezes referido como 'O Estado do Hóquei'. As equipes universitárias desfrutam de um forte apoio e proporcionam ação emocionante, especialmente durante o famoso torneio 'Frozen Four' da NCAA.",
      "Os esportes em Minnesota estão profundamente entrelaçados com a cultura e identidade do estado, oferecendo uma fonte de orgulho, espírito de comunidade e rivalidades animadas que unem pessoas de todas as idades."
    ]
  },
  {
    "name": "Mississippi",
    "description": [
      "Os esportes universitários são uma parte integrante da cultura em Mississippi, com uma paixão intensa pelo futebol americano, basquete e beisebol. A rivalidade entre os Rebels da University of Mississippi (Ole Miss) e os Bulldogs da Mississippi State é uma das mais intensas do país.",
      "O Vaught-Hemingway Stadium em Oxford e o Davis Wade Stadium em Starkville são verdadeiros templos para os fãs de futebol americano, onde a tensão e paixão do jogo são palpáveis. O Egg Bowl anual, o confronto entre Ole Miss e Mississippi State, é um destaque no calendário esportivo.",
      "Basquete e beisebol também são significativos, com ambas as universidades construindo times fortes e tradições. Os fãs são leais e vocais, criando uma atmosfera elétrica nos jogos.",
      "Os esportes em Mississippi são sobre comunidade, orgulho e tradição. Eles unem as pessoas, criam momentos inesquecíveis e são uma parte essencial da identidade do estado."
    ]
  },
  {
    "name": "Missouri",
    "description": [
      "Os esportes universitários em Missouri são sinônimos de paixão, comunidade e competição. O estado tem uma rica história esportiva, com o futebol americano, basquete e beisebol liderando.",
      "Os Tigers da University of Missouri, também conhecidos como Mizzou, e os Bears da Missouri State são dois representantes proeminentes no cenário esportivo universitário. O Faurot Field do Mizzou e a Mizzou Arena são epicentros de excitação e espírito de equipe nos dias de jogo.",
      "Os Tigers têm uma forte presença na SEC, uma das conferências mais competitivas no futebol americano universitário. Seu time de basquete também é uma presença constante no torneio da NCAA, com os fãs fornecendo apoio inabalável.",
      "Os esportes em Missouri fortalecem os laços comunitários e unem as pessoas. Não se trata apenas do jogo; trata-se da experiência, das tradições e do vínculo inquebrável entre os times e seus torcedores."
    ]
  },
  {
    "name": "Montana",
    "description": [
      "Em Montana, os esportes universitários desempenham um papel crucial na vida comunitária, com futebol americano, basquete e rodeio como esportes populares. Os Grizzlies de Montana e os Bobcats de Montana State dominam o cenário esportivo, e sua rivalidade é intensa.",
      "O Washington-Grizzly Stadium dos Grizzlies e o Bobcat Stadium dos Bobcats são orgulhosos arenas de futebol americano em Montana. Aqui, os fãs se reúnem para apoiar suas equipes, independentemente do clima. O jogo anual Brawl of the Wild entre os Grizzlies e os Bobcats é um evento imperdível.",
      "O basquete também é popular, com jogos animados e uma forte base de fãs. O rodeio, único em Montana, também atrai muita atenção e apreciação, refletindo a cultura e tradições do estado.",
      "Os esportes universitários em Montana são sobre comunidade, orgulho e amor pelo jogo. Eles unem as pessoas, criam memórias para a vida toda e contribuem para a identidade única do Big Sky Country."
    ]
  },
  {
    "name": "Nebraska",
    "description": [
      "Em Nebraska, os esportes universitários são caracterizados por uma paixão profunda e lealdade, com um foco especial no futebol americano. Os Cornhuskers de Nebraska, sediados na University of Nebraska-Lincoln, são o coração da cultura esportiva do estado.",
      "O Memorial Stadium, lar dos Cornhuskers, também é chamado de 'The Sea of Red', onde os fãs com seus trajes vermelhos distintivos se reúnem para apoiar sua equipe. O estádio é conhecido pelo seu barulho ensurdecedor e atmosfera única nos dias de jogo.",
      "Além do futebol americano, o basquete também desempenha um papel significativo, com equipes masculinas e femininas competindo em alto nível. Os fãs são tão dedicados e criam uma atmosfera vibrante em cada jogo.",
      "Os esportes universitários em Nebraska vão além do simples jogo; é uma maneira de unir comunidades, celebrar tradições e criar um senso de orgulho e união no 'Estado do Cornhusker'."
    ]
  },
    
  {
    "name": "Nevada",
    "description": [
      "Os esportes universitários em Nevada oferecem uma mistura única de entretenimento e competição, com atenção especial ao basquete e futebol americano. A University of Nevada, Las Vegas (UNLV), e a University of Nevada, Reno são as duas principais instituições esportivas.",
      "O time de basquete da UNLV, os Runnin' Rebels, têm uma história rica e são conhecidos nacionalmente. Seus jogos em casa são uma verdadeira atração, completos com performances energéticas e uma plateia entusiasmada. O time de futebol americano do Nevada Wolf Pack também contribui para a cultura esportiva do estado, com jogos emocionantes e uma base de fãs leal.",
      "Em Nevada, também há um interesse vibrante em outros esportes universitários, como beisebol, softbol e futebol. Esses esportes atraem comunidades locais e fornecem amplas oportunidades para os atletas se desenvolverem.",
      "No geral, os esportes universitários em Nevada são uma parte essencial do estado, fornecendo não apenas entretenimento, mas também unindo comunidades e enriquecendo a cultura local."
    ]
  },
  {
    "name": "New Hampshire",
    "description": [
      "No pitoresco estado de New Hampshire, os esportes universitários desempenham um papel crucial na vida comunitária. Embora o estado seja menor do que muitos outros, a dedicação aos esportes é imensa.",
      "A University of New Hampshire (UNH) domina o cenário esportivo, especialmente no hóquei, onde os Wildcats consistentemente se apresentam em alto nível. A Whittemore Center Arena, o estádio doméstico do time de hóquei, é conhecida por sua atmosfera vibrante e fãs apaixonados.",
      "Futebol americano e basquete também são esportes populares dentro da UNH, atraindo grandes multidões durante a temporada. A comunidade se orgulha de seus times, e os estudantes estão intimamente envolvidos nos eventos esportivos.",
      "A abordagem de New Hampshire aos esportes universitários é focada na comunidade, com o objetivo de unir as pessoas, celebrar sucessos e criar momentos inesquecíveis nos arredores pitorescos do estado."
    ]
  },
  {
    "name": "New Jersey",
    "description": [
      "Os esportes universitários em New Jersey desfrutam de uma atmosfera vibrante e competitiva, com várias universidades competindo em alto nível. O estado abriga alguns programas líderes que são reconhecidos tanto regionalmente quanto nacionalmente.",
      "Um dos times universitários mais proeminentes é o Rutgers Scarlet Knights, parte da prestigiada Big Ten Conference. Seus jogos de futebol americano e basquete atraem grandes multidões e geram entusiasmo por todo o estado.",
      "New Jersey também é conhecido por seus fortes programas de lacrosse e futebol, atraindo e desenvolvendo jovens talentos. Esses esportes desempenham um papel significativo na cultura esportiva universitária do estado, com competições emocionantes e fãs dedicados.",
      "A comunidade em New Jersey abraça suas equipes esportivas universitárias, resultando em uma atmosfera de apoio e paixão em cada evento. Os esportes universitários em New Jersey são mais do que apenas jogos; eles são uma parte essencial do tecido cultural do estado."
    ]
  },
  {
    "name": "New Mexico",
    "description": [
      "New Mexico, com seu background cultural e geográfico único, tem uma relação especial com os esportes universitários. O estado abriga alguns programas universitários proeminentes que competem em nível nacional.",
      "Os Lobos da University of New Mexico em Albuquerque são um ponto central de orgulho. Seu time de basquete atrai regularmente grandes multidões para The Pit, uma das arenas mais intimidadoras do país.",
      "Os Aggies da New Mexico State em Las Cruces também são contendores nacionais, especialmente no basquete e futebol americano, onde têm ferozes rivalidades com os Lobos, culminando na Rio Grande Rivalry.",
      "Apoiados por uma história rica e uma base de fãs apaixonados, os esportes universitários em New Mexico não apenas proporcionam entretenimento, mas também promovem o espírito comunitário e a identidade estadual. Neste estado desértico, o esporte é uma celebração de talento, perseverança e laços comunitários."
    ]
  },
  {
    "name": "New York",
    "description": [
      "No movimentado estado de Nova York, os esportes universitários desempenham um papel dinâmico e versátil. O estado é o lar de numerosas universidades, cada uma com suas próprias tradições esportivas únicas.",
      "A Syracuse University se destaca com seu time de basquete, o Orange, que frequentemente compete no mais alto nível nacional. O estádio Carrier Dome, conhecido por sua capacidade impressionante e atmosfera animada, é um ícone no mundo dos esportes universitários.",
      "Além disso, universidades e faculdades menores também desempenham um papel significativo, com programas fortes em esportes como lacrosse, futebol e beisebol. Esses programas menores nutrem o espírito competitivo no estado, criando um cenário esportivo rico e diversificado.",
      "O envolvimento da comunidade nos esportes universitários em Nova York é imenso, com fãs apoiando suas equipes nos bons e maus momentos. De cidades movimentadas a áreas rurais mais tranquilas, o esporte une os nova-iorquinos e incute um senso de orgulho e união."
    ]
  },
  {
    "name": "North Carolina",
    image: North_Carolina,
    "imageDescription": "A rivalidade icônica: DUKE vs. UNC em ação",
    "description": [
      "A Carolina do Norte tem uma rica tradição e uma paixão incomparável quando se trata de esportes universitários, especialmente no basquete. A rivalidade entre os Tar Heels da Universidade da Carolina do Norte e os Blue Devils da Universidade Duke é mundialmente famosa e garante confrontos emocionantes.",
      "Os Tar Heels e os Blue Devils não apenas dominam a temporada de basquete, mas também trazem uma onda de excitação e espírito comunitário por todo o estado. É uma cultura esportiva profundamente enraizada na identidade da Carolina do Norte.",
      "Além do basquete, o futebol americano também desempenha um papel proeminente, com times fortes e torcidas apaixonadas. E na primavera, o amor pelo beisebol floresce, com equipes universitárias e universidades competindo nos campos.",
      "Os esportes universitários na Carolina do Norte vão além dos campos e salões esportivos; são um modo de vida, uma fonte de orgulho e um meio para as comunidades se reunirem e celebrarem seu estado."
    ]
  },
  {
    "name": "North Dakota",
    "description": [
      "Os esportes universitários no Dakota do Norte oferecem uma experiência única, com comunidades intimamente envolvidas com suas equipes universitárias. O senso de orgulho e união é claramente visível durante os eventos esportivos, apesar de o estado ter menos universidades proeminentes do que outras regiões americanas.",
      "O time de futebol americano da Universidade Estadual do Dakota do Norte, os Bisões, é conhecido por seus sucessos na Divisão I da NCAA, o que dá um impulso significativo à cultura esportiva do estado. Os fãs se reúnem no Fargodome para apoiar sua equipe, criando uma atmosfera intensa.",
      "O hóquei no gelo também desempenha um papel importante no Dakota do Norte, com os Fighting Hawks da Universidade do Norte do Dakota como um time líder. A paixão por esse esporte de inverno é evidente durante a temporada, com fãs lotando a arena.",
      "Os esportes universitários no Dakota do Norte, especialmente o futebol americano e o hóquei no gelo, unem comunidades, criam orgulho local forte e contribuem para a vibrante cultura esportiva do estado."
    ]
  },
  {
    "name": "Ohio",
    "description": [
      "Os esportes universitários são parte integrante da cultura em Ohio, com um foco especial no futebol americano e no basquete. A Universidade Estadual de Ohio (OSU) e a Universidade de Cincinnati são duas das principais instituições esportivas.",
      "O time de futebol americano da OSU, os Buckeyes, desfruta de fama nacional e é uma verdadeira força na NCAA. Seus jogos no imenso Ohio Stadium atraem mais de 100.000 fãs, criando uma atmosfera incomparável durante os jogos em casa. 'O-H-I-O' é um cântico conhecido em todo o estado.",
      "O basquete também é destaque, com equipes masculinas e femininas competindo em alto nível. O estado de Ohio é o lar de várias equipes universitárias que participam regularmente do torneio da NCAA, adicionando à loucura pelo basquete.",
      "Os esportes universitários em Ohio oferecem mais do que apenas competição; são uma fonte de orgulho, tradição e espírito comunitário, unindo pessoas de todas as esferas da vida."
    ]
  },
  {
    "name": "Oklahoma",
    "description": [
      "Em Oklahoma, os esportes universitários ocupam um lugar central nos corações de seus moradores, com uma paixão enraizada pelo futebol americano. A Universidade de Oklahoma (OU) e a Universidade Estadual de Oklahoma (OSU) são as joias do mundo esportivo do estado.",
      "O time de futebol americano da OU, os Sooners, tem uma história rica e inúmeros sucessos, criando uma atmosfera vibrante em torno de seus jogos. O Gaylord Family Oklahoma Memorial Stadium é uma fortaleza para a equipe, onde os fãs cantam alto 'Boomer Sooner'.",
      "A OSU não fica atrás da OU, com fortes desempenhos tanto no futebol americano quanto em outros esportes, onde seu time de futebol americano, os Cowboys, desempenha um papel significativo. A dedicação dos fãs durante a 'Série Bedlam', o confronto anual entre OU e OSU, é enorme.",
      "Os esportes universitários em Oklahoma simbolizam a luta, perseverança e senso de comunidade do estado, tornando-o muito mais do que apenas um jogo."
    ]
  },
  {
    "name": "Oregon",
    "description": [
      "Os esportes universitários em Oregon são uma parte essencial do estado, com foco tanto no futebol americano quanto no atletismo. A Universidade de Oregon (UO) em Eugene é central nessa paixão esportiva e construiu uma reputação nacional.",
      "O time de futebol americano da UO, os Ducks, são conhecidos por seu jogo rápido e uniformes únicos, enquanto o programa de atletismo é um dos melhores do país. O evento atlético anual Prefontaine Classic, realizado no famoso Hayward Field, destaca a dedicação de Oregon ao atletismo.",
      "A Universidade Estadual do Oregon (OSU), com seu time de futebol americano, os Beavers, oferece forte concorrência, especialmente durante a 'Guerra Civil', o confronto anual com os Ducks. Isso é mais do que um jogo; é uma tradição estadual que une comunidades.",
      "Além desses esportes, o basquete também é popular, e os fãs apaixonados fazem dos esportes universitários em Oregon uma experiência que você não quer perder. É uma celebração do talento atlético, da comunidade e da cultura esportiva única do estado."
    ]
  },

  {
    "name": "Pennsylvania",
    "description": [
      "A Pensilvânia possui uma profunda apreciação pelos esportes universitários, com forte ênfase no futebol americano, basquete e atletismo. Times como os Nittany Lions da Universidade Penn State e os Panthers da Universidade de Pittsburgh dominam o cenário esportivo e criam rivalidades emocionantes.",
      "O Beaver Stadium, lar dos Nittany Lions, é conhecido por sua atmosfera avassaladora e experiência intensa para os fãs, especialmente durante os jogos 'White Out'. Esses momentos são o destaque do calendário esportivo e atraem fãs de todo o estado.",
      "Na quadra de basquete, os Wildcats de Villanova recentemente alcançaram sucesso nacional, com múltiplos campeonatos que empolgaram o estado. Suas performances colocaram a Pensilvânia no mapa como uma potência no basquete.",
      "O atletismo também desempenha um papel crucial, com os prestigiosos Penn Relays atraindo os melhores atletas para Filadélfia todos os anos. Essa rica cultura esportiva fortalece o espírito comunitário e contribui para o orgulho da Pensilvânia."
    ]
  },
  {
    "name": "Rhode Island",
    "description": [
      "Apesar de seu tamanho pequeno, Rhode Island tem uma cena vibrante de esportes universitários, com o basquete e a vela sendo notavelmente populares. O estado tem times que jogam com coração e alma, criando momentos emocionantes.",
      "Times como os Rams de Rhode Island e os Friars de Providence têm uma base de fãs leal e entregam jogos cativantes. O Dunkin' Donuts Center em Providence se torna uma arena movimentada durante os jogos de basquete, com os moradores comparecendo em massa para apoiar suas equipes.",
      "Além do basquete, Rhode Island é conhecido por seus programas excelentes de vela, com a bela costa proporcionando condições perfeitas para o esporte. Universidades como Brown e URI têm equipes de vela fortes que competem nacionalmente.",
      "Esses esportes contribuem para a cultura vibrante de Rhode Island, onde cada jogo é uma oportunidade para celebrar o espírito único deste estado pequeno, mas orgulhoso."
    ]
  },
  {
    "name": "South Carolina",
    "description": [
      "Os esportes universitários na Carolina do Sul respiram tradição e paixão, com o futebol americano e o basquete no centro das atenções. A intensa rivalidade entre os Gamecocks da Universidade da Carolina do Sul e os Tigers da Universidade de Clemson domina o cenário esportivo.",
      "Durante o confronto anual de futebol americano, 'The Palmetto Bowl', todo o estado ganha vida, com a linha entre granada e laranja claramente desenhada. A energia e paixão dos fãs são incomparáveis, tornando cada jogo uma experiência inesquecível.",
      "O basquete também ocupa um lugar de destaque nos corações dos fãs de esportes na Carolina do Sul, com times competindo no topo tanto nas ligas masculinas quanto femininas. Por exemplo, o time feminino de basquete dos Gamecocks alcançou sucesso nacional, aumentando ainda mais o orgulho e envolvimento da comunidade.",
      "A cultura esportiva na Carolina do Sul vai além dos campos de jogo; é um modo de vida, uma fonte de orgulho e uma parte indispensável da identidade do estado."
    ]
  },
  {
    "name": "South Dakota",
    "description": [
      "Os esportes universitários em Dakota do Sul oferecem uma experiência única e apaixonante, com ênfase no basquete, futebol americano e atletismo. Os Jackrabbits da Universidade Estadual de Dakota do Sul e os Coyotes da Universidade do Sul de Dakota são jogadores proeminentes no cenário esportivo.",
      "Jogos de basquete, especialmente durante os encontros entre os Jackrabbits e os Coyotes, atraem multidões entusiasmadas e criam uma atmosfera eletrizante. O estado torce por times masculinos e femininos, com as performances em campo sendo fonte de orgulho para as comunidades locais.",
      "O futebol americano também desfruta de grande popularidade, com jogos emocionantes e forte envolvimento dos fãs. Os programas de atletismo em Dakota do Sul se destacam, com atletas competindo nacionalmente e representando com orgulho o estado.",
      "Os esportes universitários em Dakota do Sul são mais do que apenas jogos; são uma parte essencial da comunidade, fortalecendo os laços entre os moradores e contribuindo para a cultura vibrante do estado."
    ]
  },
  {
    "name": "Tennessee",
    "description": [
      "No Tennessee, os esportes universitários desempenham um papel crucial na comunidade, com uma rica tradição no futebol americano, basquete e atletismo. Os Volunteers da Universidade do Tennessee e os Commodores da Universidade Vanderbilt são times líderes no cenário esportivo do estado.",
      "Os Volunteers, com sua cor laranja icônica, atraem grandes multidões para o Neyland Stadium, um dos maiores estádios de futebol americano do país. A paixão dos fãs é incomparável, e 'Rocky Top' ressoa alto durante cada jogo.",
      "O basquete também é uma atração importante no Tennessee, com times masculinos e femininos recebendo reconhecimento nacional. O estado tem uma rica história na produção de atletas de elite e contribuiu para o crescimento do esporte em nível nacional.",
      "Os esportes universitários no Tennessee são uma parte essencial da cultura e identidade do estado, reunindo pessoas, celebrando tradições e inspirando a próxima geração de atletas."
    ]
  },
  {
    "name": "Texas",
    image: Texas,
    "imageDescription": "Rivalidade Tradicional: Texas Longhorns enfrentam Texas A&M",
    "description": [
      "Os esportes universitários no Texas são sinônimos de grandeza, paixão e rica tradição, com o futebol americano liderando. Times como os Longhorns do Texas e os Aggies do Texas A&M têm seguidores massivos, e seus jogos são destaques da temporada.",
      "O Darrell K Royal–Texas Memorial Stadium em Austin e o Kyle Field em College Station são templos esportivos onde cada jogo em casa se torna um evento espetacular. A rivalidade entre vários times universitários é intensa e contribui para a cultura esportiva única do estado.",
      "Além do futebol americano, o basquete e o beisebol também são populares, com programas fortes competindo nacionalmente. O apoio aos esportes femininos está crescendo constantemente, com destaque para o basquete e o atletismo.",
      "Os esportes universitários no Texas são mais do que apenas um passatempo; é um modo de vida, uma fonte de orgulho e uma força unificadora que reúne comunidades."
    ]
  },
  {
    "name": "Utah",
    "description": [
      "Em Utah, os esportes universitários têm um lugar significativo dentro da comunidade, com o basquete e o futebol americano sendo os esportes mais populares. A Universidade de Utah Utes e a Brigham Young University Cougars se envolvem em uma competição acirrada em várias disciplinas.",
      "O duelo anual de futebol americano entre os Utes e os Cougars, conhecido como 'Holy War', é um dos eventos esportivos mais esperados no estado. Ele reúne os fãs para uma celebração de espírito esportivo, rivalidade e glória estadual.",
      "O basquete também tem uma rica tradição em Utah, com temporadas emocionantes e momentos memoráveis tanto nas ligas masculinas quanto femininas. Os times buscam a excelência, e o apoio dos fãs é inabalável.",
      "O impacto dos esportes universitários em Utah vai além do campo de jogo. Ele promove o espírito comunitário, o orgulho escolar e contribui significativamente para a identidade cultural do estado."
    ]
  },
  {
    "name": "Vermont",
    "description": [
      "Em Vermont, onde a natureza predomina e as comunidades são unidas, os esportes universitários desempenham um papel único em reunir as pessoas. Esportes de inverno como esqui e snowboard são destaque, dadas as montanhas e o clima frio.",
      "A Universidade de Vermont Catamounts é o centro da cena esportiva universitária, com times excelentes em diversos esportes, incluindo hóquei no gelo e basquete. Os Catamounts têm uma base de fãs leal, e a atmosfera nos jogos em casa é intensa.",
      "O basquete desfruta de grande popularidade, e os jogos dos Catamounts são imperdíveis para os entusiastas do esporte. O time participou do torneio da NCAA várias vezes, colocando o estado no mapa no mundo do basquete universitário.",
      "Os esportes universitários em Vermont são mais do que apenas um jogo; são uma forma de reunir comunidades, desenvolver talentos e celebrar a cultura esportiva única do estado."
    ]
  },
  {
    "name": "Virginia",
    "description": [
      "Na Virgínia, os esportes universitários são uma parte integral da cultura local, com um forte foco no futebol americano, basquete e na crescente popularidade do futebol. Os Cavaliers da Universidade da Virgínia e os Hokies da Universidade Virginia Tech são equipes universitárias líderes que trazem paixão e rivalidade para o campo.",
      "O futebol americano atrai multidões aos estádios, onde a atmosfera nos dias de jogo é eletrizante. O investimento em programas de basquete também deu resultados, com times competindo nacionalmente e tendo uma base de fãs dedicada.",
      "O futebol está ganhando espaço na Virgínia, com times masculinos e femininos se desenvolvendo em forças competitivas. As universidades estão investindo em instalações e treinamento, enquanto academias locais produzem jovens talentos.",
      "Essa mistura de esportes tradicionais e emergentes enriquece a cena esportiva universitária na Virgínia, cria uma comunidade esportiva vibrante e proporciona a atletas e fãs experiências inesquecíveis e um senso de orgulho e união."
    ]
  },
  {
    "name": "Washington",
    "description": [
      "Os esportes universitários em Washington prosperam com uma mistura de tradição e entusiasmo, com o futebol americano, basquete e remo assumindo o centro do palco. A Universidade de Washington Huskies e a Washington State University Cougars são pioneiras na cultura esportiva do estado.",
      "O Husky Stadium em Seattle e o Martin Stadium em Pullman transbordam de entusiasmo durante os jogos de futebol americano, onde fãs de toda a área se reúnem para apoiar suas equipes. A 'Apple Cup', o confronto anual entre os Huskies e os Cougars, é o destaque da rivalidade.",
      "O basquete também desempenha um papel significativo, com times masculinos e femininos competindo em alto nível. Os Huskies têm um forte legado neste esporte e atraem torcedores apaixonados.",
      "O remo é outra disciplina notável, com os Huskies ganhando inúmeros campeonatos nacionais. Os esportes universitários em Washington refletem a diversidade e paixão do estado, onde o espírito esportivo, a competição e a camaradagem são altamente valorizados."
    ]
  },


  {
    "name": "West Virginia",
    "description": [
      "Os esportes universitários são o coração pulsante da Virgínia Ocidental, com um forte foco no futebol americano e basquete. Os Mountaineers da Universidade da Virgínia Ocidental são os orgulhosos representantes do estado, e seus jogos unem a comunidade.",
      "O Milan Puskar Stadium em Morgantown ganha vida durante os jogos em casa dos Mountaineers, com os fãs vestindo as icônicas cores azul e dourado e torcendo alto. O 'Backyard Brawl', uma rivalidade histórica com a Universidade de Pittsburgh, adiciona emoção extra à temporada.",
      "O basquete é outra paixão, com jogos emocionantes mantendo os fãs à beira de seus assentos. Os Mountaineers têm times masculinos e femininos competindo em nível nacional.",
      "Os esportes universitários na Virgínia Ocidental são uma fonte de orgulho e união, refletindo o forte senso de comunidade e tradições do estado."
    ]
  },
  {
    "name": "Wisconsin",
    "description": [
      "Os esportes universitários estão profundamente enraizados na cultura do Oeste de Wisconsin, com o futebol americano, basquete e hóquei no gelo como disciplinas proeminentes. A Universidade de Wisconsin Badgers é a força motriz por trás da cultura esportiva no estado.",
      "O Camp Randall Stadium em Madison vibra de excitação durante os jogos de futebol americano, com os fãs se reunindo para cantar 'Jump Around' e torcer por sua equipe. A rivalidade com equipes como os Michigan Wolverines da Universidade de Michigan é lendária e leva a jogos intensos.",
      "O basquete também tem uma rica tradição, com os Badgers competindo regularmente pelas primeiras posições nos torneios da NCAA. O hóquei no gelo ocupa um lugar especial no coração do estado, com jogos emocionantes iluminando as noites de inverno.",
      "Os esportes universitários no Oeste de Wisconsin vão além do campo de jogo; são uma fonte de orgulho, camaradagem e fortalecem os laços entre as comunidades do estado."
    ]
  },
  {
    "name": "Wyoming",
    "description": [
      "Os esportes universitários em Wyoming têm um caráter único, com um forte foco em esportes ao ar livre como esqui, snowboard e montanhismo. Os Cowboys e Cowgirls da Universidade de Wyoming são os porta-estandartes desta cultura esportiva aventureira.",
      "O War Memorial Stadium em Laramie ganha vida durante os jogos de futebol americano, onde os fãs se reúnem para mostrar seu apoio aos Cowboys. As deslumbrantes montanhas servem de pano de fundo para esses eventos esportivos.",
      "A universidade também tem uma forte tradição em esqui e snowboard, com atletas dominando competições nacionais e internacionais. A vasta natureza selvagem de Wyoming serve como playground para aventureiros e entusiastas ao ar livre.",
      "Os esportes universitários em Wyoming celebram a beleza da natureza e o espírito aventureiro do estado, onde o espírito esportivo, a paixão e o amor pela natureza se unem."
    ]
  }



];

export default states;