/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import Image1 from "assets/images/Sports_Images/Tennis1.jpg";
import Image2 from "assets/images/Sports_Images/Tennis2.jpeg";
import Image3 from "assets/images/Sports_Images/Tennis3.jpg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'USA Tennisstipendier: Hur man Studerar och Spelar Tennis i Amerika', isSubheader: false },
  
    { id: 'Kapitel 1', title: 'Kapitel 1: Historia och Utveckling av Tennis i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Tidiga År och Universitetens Påverkan', isSubheader: true },
    { id: '1.2', title: '1.2 Tillväxt och Professionalisering', isSubheader: true },
  
    { id: 'Kapitel 2', title: 'Kapitel 2: Universitetstennis Idag', isSubheader: false },
    { id: '2.1', title: '2.1 Viktiga Tävlingar och Konferenser', isSubheader: true },
    { id: '2.2', title: '2.2 Toppprogram och Prestigefyllda Lag', isSubheader: true },
  
    { id: 'Kapitel 3', title: 'Kapitel 3: Från College till Proffsen', isSubheader: false },
    { id: '3.1', title: '3.1 Vägen till Professionalism', isSubheader: true },
    { id: '3.2', title: '3.2 Professionella Tenniscirklar', isSubheader: true },
  
    { id: 'Kapitel 4', title: 'Kapitel 4: Internationella Influenser', isSubheader: false },
    { id: '4.1', title: '4.1 Utländska Spelare på Collegescenen', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikaner på den Internationella Scenen', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapitel 5: Idrottsstipendier och Framtiden för Idrottare', isSubheader: false },
    { id: '5.1', title: '5.1 Vikten av Stipendier', isSubheader: true },
    { id: '5.2', title: '5.2 Hur Sport Scholarships America Kan Hjälpa', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innehåll
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Dölj</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Visa</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>






















          <MKBox id="introduction" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    USA Tennisstipendier: Hur man Studerar och Spelar Tennis i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Tenniskomplexet i Georgia med fulla läktare under en collegematch."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Fulla läktare vid tenniskomplexet i Georgia.
      </figcaption>
    </figure>
    Tennis är en sport som har spelats i USA i över ett sekel. Det började som en fritidsaktivitet för eliten men utvecklades snabbt till en konkurrenskraftig och professionell sport som lockade miljoner fans och spelare över hela världen. En av de nyckelfaktorer som bidragit till tillväxten och utvecklingen av tennis i Amerika är collegiat-systemet. Universitet erbjuder inte bara högkvalitativ utbildning utan också utmärkta faciliteter, coaching, vägledning och stipendier för talangfulla tennisspelare. Att spela tennis på collegenivå gör att spelare kan förbättra sina färdigheter, utöka sitt nätverk, öka sina chanser till en professionell karriär och samtidigt få en värdefull examen.
    <br />
    <br />
    Men hur kan en ung tennisspelare från Nederländerna eller Belgien åka till Amerika för att studera och spela tennis? Svaret är med ett tennisstipendium. Ett tennisstipendium är ekonomiskt stöd som ges av ett universitet till en spelare som uppfyller vissa akademiska och idrottsliga kriterier. Med ett tennisstipendium kan du täcka eller minska dina studieavgifter, böcker, boende, måltider och andra utgifter. Ett tennisstipendium kan variera från ett fullt stipendium som täcker alla kostnader till ett delstipendium som täcker en del av kostnaderna.
    <br />
    <br />
    Men hur får man ett tennisstipendium? Det är där Sportbeursamerika.nl kan hjälpa dig. Sportbeursamerika.nl är en plattform som hjälper unga tennisspelare att förverkliga sin dröm om att åka till Amerika. Sportbeursamerika.nl ger råd, vägledning, förmedling och stöd för att hitta det bästa universitetet och stipendiet för dig. Sportbeursamerika.nl har ett team av experter som förstår hur det amerikanska collegiat-systemet fungerar och har kontakter med hundratals tränare och universitet. Sportbeursamerika.nl har redan hjälpt många spelare att uppnå sin potential och förändra sina liv.
    <br />
    <br />
    Vill du lära dig mer om tennis i Amerika och hur Sportbeursamerika.nl kan hjälpa dig? Fortsätt läsa!
  </MKTypography>
</MKBox>

<MKBox id="Kapitel 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Historia och Utveckling av Tennis i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Tennis är en sport som härstammar från Europa men snabbt spred sig till andra delar av världen, särskilt till USA. I det här kapitlet kommer vi att utforska hur tennis har utvecklats i USA och hur universitet har spelat en betydande roll i sportens utveckling och professionalisering.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidiga År och Universitetens Påverkan
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Tennis introducerades först i USA i början av 1800-talet av välbärgade amerikaner som hade lärt sig spelet under sina resor till Europa. Det blev snabbt en populär fritidsaktivitet för eliten, som byggde tennisbanor på sina egendomar och klubbar. Den första officiella tennismatchen i USA spelades 1874 mellan två engelska bröder, James Dwight och Fred Renshaw, båda studenter vid Harvard University. De spelade på en tennisbana de hade byggt på sin farbrors egendom i Nahant, Massachusetts. De första nationella mästerskapen, då kända som US National Lawn Tennis Association Men's Singles Championship, hölls 1881 i Newport, Rhode Island.
      <br />
      <br />
      Universitet var bland de första institutionerna som omfamnade tennis som en tävlingssport. De organiserade intercollegiate turneringar och bildade lag för att tävla mot varandra. De första intercollegiate tennis mästerskapen hölls 1883 med deltagande från Harvard, Yale, Princeton och Columbia, och 1885 erkändes det första officiella intercollegiate tennis mästerskapet. Dessa universitet blev snabbt kända som "Big Four" inom amerikansk collegiat tennis. De dominerade sporten fram till början av 1900-talet när andra universitet som Stanford, California, Chicago och Cornell anslöt sig till dem.
      <br />
      <br />
      Universitet spelade också en betydande roll i att främja och sprida tennis bland olika grupper av människor. De erbjöd möjligheter för kvinnor, afroamerikaner, invandrare och andra minoriteter att träna och utmärka sig inom sporten. Ett av de mest kända exemplen är Althea Gibson, som 1950 blev den första afroamerikanska kvinnan att bli antagen till mästerskapen. Hon vann sin första Grand Slam-titel vid Franska Mästerskapen 1956 efter att ha tagit examen från Florida A&M University och anslutit sig till American Tennis Association, en organisation för svarta tennisspelare. Hon vann senare Wimbledon två gånger och US Open två gånger.
    </MKTypography>
  </MKBox>

  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Tillväxt och Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Den andra hälften av 1900-talet såg en betydande tillväxt och professionalisering av tennis i Amerika. Detta möjliggjordes delvis av framväxten av televisionen, vilket gav sporten mer synlighet och popularitet. Dessutom introducerades nya teknologier och material, vilket gjorde spelet snabbare och mer spännande. Dessutom etablerades nya organisationer och kretsar för att reglera och främja tennis, inklusive International Tennis Federation (ITF), Association of Tennis Professionals (ATP) och Women's Tennis Association (WTA).
      <br />
      <br />
      Dessa förändringar påverkade också collegiat tennis, som var tvungen att anpassa sig till den nya verkligheten med professionell tennis. Universitet var tvungna att konkurrera med andra alternativ för att locka och behålla talangfulla spelare. De var också tvungna att förbättra sina program för att förbereda spelare för en potentiell professionell karriär. En av de viktigaste stegen universitet tog var skapandet av National Collegiate Athletic Association (NCAA), en övergripande organisation ansvarig för att organisera och reglera collegiat idrott i USA. NCAA delade upp universitet i tre divisioner baserat på deras storlek, budget och tävlingsnivå. NCAA organiserar årliga nationella mästerskap för varje division, både för lag och individer.
      <br />
      <br />
      En annan konsekvens av tennisens professionalisering var att många amerikanska spelare utmärkte sig på den högsta nivån av sporten. Några av dem hade en collegiat bakgrund och bevisade att collegiat tennis kan ge en solid grund för en framgångsrik professionell karriär. Några av de mest välkända amerikanska tennisspelarna som spelade på universitetsnivå inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Arthur Ashe, som studerade vid UCLA och var den första afroamerikanska mannen som vann Wimbledon, US Open och Australian Open.
        </li>
        <li>
          John McEnroe, som gick på Stanford och vann sju Grand Slam singeltitlar och nio i dubbel.
        </li>
        <li>
          Jimmy Connors, som studerade vid UCLA och vann åtta Grand Slam singeltitlar och två i dubbel.
        </li>
        <li>
          Billie Jean King, som gick på California State University, Los Angeles, och vann 12 Grand Slam singeltitlar och 16 i dubbel. Hon var också en pionjär i kampen för könsjämlikhet inom idrotten.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  







<MKBox id="Kapitel 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Universitetstennis Idag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Tennis förblir en av de mest populära och prestigefyllda sporterna inom det amerikanska collegiat-systemet. Varje år deltar tusentals spelare i olika tävlingar och konferenser och visar det bästa av sporten. I det här kapitlet kommer vi att utforska de största tävlingarna och konferenserna inom collegiat tennis och de bästa programmen och prestigefyllda lagen som dominerar sporten.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Viktiga Tävlingar och Konferenser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Cameron Norrie spelar för TCU i collegestennis."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Cameron Norrie i aktion som TCU-spelare
        </figcaption>
      </figure>
      Som vi såg i det föregående kapitlet är NCAA den primära organisationen ansvarig för att organisera och reglera collegiat idrott i USA. NCAA delar upp universitet i tre divisioner: Division I, Division II och Division III. Varje division har sina egna regler, krav och mästerskap. För tennis finns det två stora tävlingar som hålls av NCAA: lagmästerskapet och individuella mästerskapet.
      <br />
      <br />
      Lagmästerskapet är en utslagsturnering som spelas bland de bästa lagen i varje division. Lag väljs utifrån deras prestation under säsongen, deras ranking och deras regionala resultat. Lagmästerskapet består av två faser: den regionala fasen och den nationella fasen. Den regionala fasen spelas mellan lag från samma region, som är uppdelade i fyra geografiska zoner: Northeast, Southeast, Midwest och West. Vinnarna i varje region går vidare till den nationella fasen, som spelas mellan 16 lag från varje division. Lagmästerskapet följer formatet för Davis Cup, vilket innebär att varje match består av tre dubbelmatcher och sex singelmatcher.
      <br />
      <br />
      Det individuella mästerskapet är en utslagsturnering som spelas bland de bästa spelarna i varje division. Spelare väljs utifrån deras ranking, deras prestation i lagmästerskapet och deras resultat i andra turneringar. Det individuella mästerskapet består av två kategorier: singel och dubbel. Varje kategori har 64 spelare i Division I, 48 spelare i Division II och 32 spelare i Division III.
      <br />
      <br />
      Förutom NCAA-mästerskapen finns det också andra tävlingar som spelas mellan universitet som tillhör samma konferens. En konferens är en grupp universitet som samarbetar inom akademiska, idrottsliga och administrativa områden. Det finns över 30 konferenser i USA, var och en med sina egna regler, scheman och mästerskap. Några av de mest kända konferenserna inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          The Atlantic Coast Conference (ACC), bestående av 15 universitet från östkusten, som Duke, North Carolina, Virginia och Florida State.
        </li>
        <li>
          The Big Ten Conference, bestående av 14 universitet från Mellanvästern, som Michigan, Ohio State, Illinois och Wisconsin.
        </li>
        <li>
          The Pacific-12 Conference (Pac-12), bestående av 12 universitet från västkusten, som Stanford, California, UCLA och USC.
        </li>
        <li>
          The Southeastern Conference (SEC), bestående av 14 universitet från sydöstra USA, som Georgia, Florida, Kentucky och Vanderbilt.
        </li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dessa konferenser är viktiga inte bara för den idrottsliga rivaliteten mellan universitet utan också för deras akademiska rykte och prestige. Dessutom ger de fler möjligheter för spelare att utmärka sig och kvalificera sig för nationella mästerskap.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Toppprogram och Prestigefyllda Lag
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Nivån på konkurrensen inom collegiat tennis är extremt hög, vilket innebär att endast de bästa spelarna och lagen kan blomstra och lyckas. Vissa universitet har emellertid byggt en lång tradition och ett starkt rykte inom tennis, vilket leder till toppprogram som uppnår utmärkta resultat år efter år. Dessa universitet har inte bara de bästa faciliteterna, tränarna och stipendierna utan också utmärker sig i rekrytering, träning och handledning av sina spelare. Några av de mest framgångsrika och prestigefyllda collegiat tennisprogrammen inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Stanford University, som har vunnit 21 NCAA-lagtitlar i damtennis och 17 i herrtennis, mer än något annat universitet. Stanford har också vunnit 25 NCAA-individuella titlar i damtennis och 14 i herrtennis. Några av de mest kända spelarna som spelade för Stanford inkluderar John McEnroe, Bob och Mike Bryan, Nicole Gibbs och Mallory Burdette.
        </li>
        <li>
          USC (University of Southern California), som har vunnit nästan lika många NCAA-lagtitlar som Stanford i herrtennis och några i damtennis. USC har också vunnit fler NCAA-individuella titlar än något annat universitet i herrtennis och några i damtennis. Några av de mest kända spelarna som spelade för USC inkluderar Stan Smith, Alex Olmedo, Steve Johnson och Danielle Collins.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>





                                  








<MKBox id="Kapitel 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Från College till Proffsen
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    En av de största drömmarna för många unga tennisspelare är att följa en professionell karriär och lysa på den internationella scenen. Men hur kan du göra den drömmen till verklighet? Är collegiatennis en bra förberedelse för proffscirkeln? I det här kapitlet kommer vi att titta på vägen till professionalism och hur collegiatennis kan hjälpa spelare att ta det steget.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Vägen till Professionalism
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Att bli en professionell tennisspelare är ingen lätt uppgift. Det kräver inte bara mycket talang utan också engagemang, disciplin, hårt arbete och uppoffringar. Det kräver också att man fattar viktiga beslut som kan påverka ens framtid. Ett av de viktigaste besluten du måste fatta är om du ska gå på college eller inte.
      <br />
      <br />
      Det finns för- och nackdelar med båda alternativen. Om du väljer att bli professionell direkt efter gymnasiet kan du fokusera helt på din tennis och försöka säkra en plats i rankingen så snabbt som möjligt. Du kan också dra nytta av din ungdom, energi och potential. Men du kan också möta många risker, som skador, utbrändhet, ekonomiska problem eller brist på resultat. Dessutom kan du behöva ge upp eller skjuta upp din akademiska utbildning, vilket begränsar dina möjligheter om tennisen inte fungerar.
      <br />
      <br />
      Om du väljer att gå på college kan du dra nytta av de många fördelarna som collegiatennis erbjuder. Inte bara kan du få en högkvalitativ utbildning som kan hjälpa dig att tjäna en examen och ha en alternativ karriär, men du kan också njuta av en fantastisk livserfarenhet som kan hjälpa dig att växa som person. Du kan också dra nytta av de utmärkta faciliteterna, tränarna, vägledningen och stipendierna som universiteten tillhandahåller. Men du kan också stöta på nackdelar, som att förlora tid och möjligheter att bli professionell, hamna efter andra spelare som började tidigare eller ha svårt att gå från college till proffsen.
      <br />
      <br />
      Det finns inget universalsvar på om du bör gå på college eller inte. Det beror på din personliga situation, mål, preferenser och möjligheter. Vissa spelare har lyckats genom att bli professionella direkt, medan andra har gynnats av sin collegiateniserfarenhet. Det viktigaste är att fatta ett informerat beslut som passar dig.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professionella Tenniscirklar
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Om du bestämmer dig för att bli en professionell tennisspelare måste du förstå hur de professionella tenniscirklarna fungerar och hur du tar dig dit. Det finns olika cirklar för män och kvinnor, var och en med sina egna organisationer, regler och turneringar.
      <br />
      <br />
      För män finns det två stora cirklar: ATP-touren och ITF World Tennis Tour. ATP-touren är den högsta nivån av herrarnas professionella tennis, där de bästa spelarna i världen tävlar om prestigefyllda titlar och prispengar. ATP-touren består av fyra kategorier av turneringar: Grand Slam, ATP Finals, ATP Masters 1000 och ATP 250/500. För att delta i dessa turneringar måste du ha en hög ranking, som bestäms av antalet poäng du tjänar genom att vinna matcher.
      <br />
      <br />
      ITF World Tennis Tour är den lägre nivån av herrarnas professionella tennis, där spelare strävar efter att förbättra sin ranking och kvalificera sig för ATP-touren. ITF World Tennis Tour består av tre kategorier av turneringar: ITF World Tennis Tour M25/M15, ITF World Tennis Tour M25/M15+H och ITF World Tennis Tour M15/M15+H. För att delta i dessa turneringar måste du ha en ITF-ranking, som bestäms av antalet poäng du tjänar genom att vinna matcher.
      <br />
      <br />
      För kvinnor finns det också två stora cirklar: WTA-touren och ITF World Tennis Tour. WTA-touren är den högsta nivån av damernas professionella tennis, där de bästa spelarna i världen tävlar om prestigefyllda titlar och prispengar. WTA-touren består av fyra kategorier av turneringar: Grand Slam, WTA Finals, WTA Premier och WTA International. För att delta i dessa turneringar måste du ha en hög ranking, som bestäms av antalet poäng du tjänar genom att vinna matcher.
      <br />
      <br />
      ITF World Tennis Tour är den lägre nivån av damernas professionella tennis, där spelare strävar efter att förbättra sin ranking och kvalificera sig för WTA-touren. ITF World Tennis Tour består av tre kategorier av turneringar: ITF World Tennis Tour W100/W60/W25/W15, ITF World Tennis Tour W100/W60/W25/W15+H och ITF World Tennis Tour W15/W15+H. För att delta i dessa turneringar måste du ha en ITF-ranking, som bestäms av antalet poäng du tjänar genom att vinna matcher.
      <br />
      <br />
      För att gå från collegiatennis till professionell tennis måste du först bygga en ranking i ITF World Tennis Tour och sedan försöka kvalificera dig för ATP- eller WTA-touren. Detta kan vara en utmanande och lång process som kräver mycket ansträngning, tålamod och beslutsamhet. Men det är inte omöjligt, och det finns många exempel på spelare som framgångsrikt har gjort övergången och nu lyser på den professionella cirkeln.
    </MKTypography>
  </MKBox>
</MKBox>





                                  






<MKBox id="Kapitel 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationella Influenser
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Tennis är en global sport som förenar människor från olika nationaliteter, kulturer och bakgrunder. Detta gäller också för collegiatennis i Amerika, som har en betydande mångfald och rikedom av internationella influenser. I det här kapitlet kommer vi att utforska hur utländska spelare har påverkat collegescenen och hur amerikanska spelare har nått den internationella scenen.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Utländska Spelare på Collegescenen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Spelare från Virginia firar nationell mästerskap i collegestennis."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Spelare från Virginia firar nationellt mästerskap
        </figcaption>
      </figure>
      En av de mest märkbara funktionerna i collegiatennis i Amerika är den höga andelen utländska spelare som deltar i sporten. Enligt Intercollegiate Tennis Association (ITA) fanns det år 2020 över 3 000 utländska spelare som tävlade för amerikanska universitet, vilket motsvarade ungefär 40% av det totala antalet spelare. Dessa spelare kom från över 100 länder, med de vanligaste länderna som: Storbritannien, Tyskland, Frankrike, Spanien, Australien, Kanada, Brasilien, Japan, Kina och Indien.
      <br />
      <br />
      Anledningarna till varför utländska spelare väljer att komma till Amerika för att studera och spela tennis är mångfacetterade. Vissa söker en bättre akademisk utbildning som de inte kan hitta i sitt eget land. Andra letar efter bättre sportutvecklingsmöjligheter som är otillgängliga i sitt hemland. Ännu andra söker en ny livserfarenhet som de inte kan ha i sitt eget land. Oavsett deras motivation ger utländska spelare många fördelar till amerikansk collegiatennis.
      <br />
      <br />
      En av fördelarna är att utländska spelare höjer nivån och kvaliteten på spelet. De bringar nya färdigheter, stilar, taktiker och strategier som gör spelet mer mångsidigt och intressant. De utmanar också amerikanska spelare att göra sitt bästa och anpassa sig till olika situationer. Dessutom bidrar de till universitetens prestige och rykte, vilket ger mer uppmärksamhet och erkännande.
      <br />
      <br />
      En annan fördel är att utländska spelare berikar kulturen och atmosfären i spelet. De bringar nya perspektiv, idéer, värderingar och traditioner som gör spelet mer öppet och inkluderande. De skapar också en känsla av gemenskap och vänskap bland spelare från olika bakgrunder som samarbetar och stöder varandra. Dessutom lär de av varandra och delar sina erfarenheter, breddar sina vyer och främjar personlig tillväxt.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikaner på den Internationella Scenen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Medan utländska spelare har en betydande påverkan på amerikansk collegiatennis har amerikanska spelare också haft en stor inverkan på internationell tennis. Många amerikanska spelare som spelade på college uppnådde senare framgång på den professionella cirkeln och representerade sitt land på den internationella scenen. Några av dessa spelare inkluderar:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          John Isner, som gick på University of Georgia och var fyra gånger All-American. Han är känd för sin kraftfulla serve och långa matcher, som den längsta matchen i historien mot Nicolas Mahut på Wimbledon 2010. Han har vunnit 15 ATP-titlar och nått fjärde plats i världsrankingen.
        </li>
        <li>
          Kevin Anderson, som gick på University of Illinois och var två gånger All-American. Han är känd för sitt starka baslinjespel och konsekventa prestationer. Han har vunnit sex ATP-titlar och nått två Grand Slam-finaler: US Open 2017 och Wimbledon 2018.
        </li>
        <li>
          Danielle Collins, som gick på University of Virginia och var två gånger NCAA-mästare. Hon är känd för sin aggressiva och uttrycksfulla spelstil och sin beslutsamhet. Hon har vunnit en WTA-titel och nått semifinalerna i Australian Open 2019.
        </li>
        <li>
          Jennifer Brady, som gick på UCLA och var en gång All-American. Hon är känd för sina kraftfulla slag och förbättrad rörlighet. Hon har vunnit en WTA-titel och nått finalen i Australian Open 2021.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  






<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Idrottsstipendier och Framtiden för Idrottare
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrottsstipendier erbjuder många möjligheter för unga idrottare att visa upp sin talang och utveckla sina färdigheter. En av de mest betydelsefulla möjligheterna är att få ett idrottsstipendium för att studera och tävla på collegenivå. I det här kapitlet kommer vi att diskutera vikten av idrottsstipendier och hur Sport Scholarships America kan hjälpa dig att förverkliga din dröm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vikten av Stipendier
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ett idrottsstipendium är ekonomiskt stöd som tillhandahålls av ett universitet till en idrottare för att kombinera studier och idrott. Det kan täcka kostnaderna för undervisning, böcker, boende, måltider och andra utgifter. Dessutom kan det erbjuda fördelar som akademisk handledning, sjukvård, träningsanläggningar och utrustning.
      <br />
      <br />
      Att få ett idrottsstipendium är konkurrenskraftigt, med många faktorer som spelar en roll i tilldelningsprocessen, inklusive akademiska prestationer, idrottsprestationer, personlighet, motivation, ledarskap och anpassningsförmåga. Därför är det viktigt att förbereda sig väl och skilja sig från konkurrensen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hur Sport Scholarships America Kan Hjälpa
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotyp för Sport Scholarships America, med sportutrustning och en studentmössa, som representerar fokus på idrottsstipendier för idrottare."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotyp för Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America är en organisation specialiserad på att vägleda unga idrottstalanger till idrottsstipendier. Med passionerade och erfarna experter stöder Sport Scholarships America idrottare i varje steg av resan, från initial orientering till slutlig placering. Deras tjänster inkluderar:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intagningsintervju för att diskutera idrottarens önskemål, möjligheter och förväntningar.</li>
        <li>En professionell utvärdering av idrottarens akademiska och idrottsliga nivå.</li>
        <li>Ett omfattande nätverk av kontakter med tränare, universitet och högskolor.</li>
        <li>Effektiv marknadsföring av idrottarens profil och videor till potentiella tränare.</li>
        <li>Expertrådgivning om de bästa alternativen baserat på idrottarens preferenser, mål och budget.</li>
        <li>Förhandling om de bästa stipendieerbjudandena för idrottaren.</li>
        <li>Hjälp med att ansöka om nödvändiga dokument, såsom visum, betyg, examensbevis och antagningsprov.</li>
        <li>Förberedelse för idrottarens liv under stipendieperioden, inklusive kultur, språk, regler och tips.</li>
        <li>Uppföljning och uppföljning under stipendieperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vill du utforska möjligheter till stipendier och uppnå dina idrottsliga och akademiska mål? <Link to="/sv/Tjänster-Produkter">Se det fullständiga utbudet</Link> av tjänster som erbjuds av Sport Scholarships America och boka en konsultation idag.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;