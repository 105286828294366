/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import Image1 from "assets/images/Sports_Images/Ice Hockey1.jpeg";
import Image2 from "assets/images/Sports_Images/Ice Hockey2.jpg";
import Image3 from "assets/images/Sports_Images/Ice Hockey3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images

import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Eishockey-Stipendium USA: Wie man in Amerika Eishockey studiert und spielt', isSubheader: false },
  
    { id: 'Kapitel 1', title: 'Kapitel 1: Die Geschichte und Entwicklung des Eishockeys in Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Frühe Jahre und die Rolle der Universitäten', isSubheader: true },
    { id: '1.2', title: '1.2 Wachstum und Professionalisierung', isSubheader: true },
  
    { id: 'Kapitel 2', title: 'Kapitel 2: Das heutige College-Eishockey', isSubheader: false },
    { id: '2.1', title: '2.1 Hauptligen und Konferenzen', isSubheader: true },
    { id: '2.2', title: '2.2 Top-Programme und prestigeträchtige Teams', isSubheader: true },
  
    { id: 'Kapitel 3', title: 'Kapitel 3: Vom College zum Profi', isSubheader: false },
    { id: '3.1', title: '3.1 Der Weg zum Profisport', isSubheader: true },
    { id: '3.2', title: '3.2 Der NHL-Draft und andere Möglichkeiten', isSubheader: true },
  
    { id: 'Kapitel 4', title: 'Kapitel 4: Internationale Einflüsse', isSubheader: false },
    { id: '4.1', title: '4.1 Ausländische Spieler im College-Eishockey', isSubheader: true },
  
    { id: 'Kapitel 5', title: 'Kapitel 5: Sportstipendien und die Zukunft der Athleten', isSubheader: false },
    { id: '5.1', title: '5.1 Die Bedeutung von Stipendien', isSubheader: true },
    { id: '5.2', title: '5.2 Wie Sportstipendien Amerika helfen können', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhaltsverzeichnis
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verstecken</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Anzeigen</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>

























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Eishockey-Stipendium USA: Wie man in Amerika Eishockey studiert und spielt
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Luftaufnahme eines voll besetzten Stadions bei einem Eishockeyspiel an einem College."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Ein volles Stadion während eines College-Spiels
      </figcaption>
    </figure>
    Eishockey ist eine der beliebtesten Sportarten in Amerika. Es ist ein schneller, körperlicher und aufregender Sport, der Millionen von Fans anzieht. Eishockey wird auf verschiedenen Ebenen gespielt, von Amateur bis Profi. Aber eine der wichtigsten und angesehensten Ebenen ist das College-Eishockey.
    <br />
    <br />
    College-Eishockey ist die Version von Eishockey, die von Studenten von Universitäten und Colleges in Amerika gespielt wird. Es ist Teil des amerikanischen Bildungssystems, das Sport und Akademik verbindet. College-Eishockey bietet jungen Spielern die Möglichkeit, sich sowohl akademisch als auch sportlich weiterzuentwickeln und dabei das Studentenleben zu genießen.
    <br />
    <br />
    College-Eishockey ist auch ein Sprungbrett in die professionelle Welt. Viele Spieler, die in der NHL (National Hockey League) oder anderen Profiligen spielen, haben zuerst College-Eishockey gespielt. College-Eishockey ist eine ausgezeichnete Möglichkeit, sich auf eine Karriere im Sport vorzubereiten.
    <br />
    <br />
    Aber wie kann ein junger Eishockeyspieler sich für College-Eishockey qualifizieren? Dabei kann Sportbeursamerika.nl Ihnen helfen. Ich bin spezialisiert auf die Beratung von Studenten-Athleten, die ein Sportstipendium in Amerika erhalten möchten. Ein Sportstipendium ist eine finanzielle Belohnung, die Sie von einer Universität oder Hochschule erhalten, um dort zu studieren und Sport zu treiben. Mit einem Sportstipendium können Sie in Amerika Eishockey spielen und studieren, ohne hohe Kosten zu verursachen.
    <br />
    <br />
    In diesem Artikel werden wir Ihnen weitere Informationen über College-Eishockey in Amerika, seine Geschichte, Entwicklung, Ligen, Teams, Möglichkeiten, internationale Einflüsse und natürlich Stipendien zur Verfügung stellen. Ich werde Ihnen auch erzählen, wie ich Ihnen helfen kann, Ihren Traum wahr werden zu lassen. Sind Sie bereit, mehr über Eishockey-Stipendien in den USA zu erfahren? Lesen Sie weiter!
  </MKTypography>
</MKBox>

<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Die Geschichte und Entwicklung des Eishockeys in Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Eishockey ist eine Sportart, die seit über einem Jahrhundert in Amerika existiert. Es hat eine reiche und faszinierende Geschichte, die eng mit der Rolle der Universitäten verbunden ist. In diesem Kapitel tauchen wir tiefer in die Ursprünge, das Wachstum und die Professionalisierung des Eishockeys in Amerika ein.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Frühe Jahre und die Rolle der Universitäten
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Eishockey entstand in Kanada, wo es im 19. Jahrhundert erstmals gespielt wurde. Das Spiel verbreitete sich schnell in den Vereinigten Staaten und wurde besonders in den nordöstlichen Bundesstaaten populär. Die ersten Universitätsteams wurden Ende des 19. und Anfang des 20. Jahrhunderts gebildet, darunter Yale, Harvard, Princeton und Dartmouth.
      <br />
      <br />
      Diese Teams spielten interkollegiale Matches, die oft große Menschenmengen anzogen. Sie spielten auch gegen kanadische Teams, die in der Regel stärker waren. Um das Spielniveau zu verbessern, begannen einige Universitäten, professionelle Spieler zu rekrutieren, die sich als Studenten einschrieben. Diese Praxis stieß auf Kontroverse und Kritik, da sie den Amateurstatus des Sports gefährdete.
      <br />
      <br />
      Um dieser Praxis ein Ende zu setzen, wurde 1898 die Intercollegiate Hockey Association (IHA) gegründet, die erste Sammlung von College-Eishockeyprogrammen in Amerika. Die IHA, manchmal auch als Intercollegiate Hockey League bezeichnet, umfasste Teams wie Brown, Columbia, die University of Pennsylvania und Yale. Diese Organisation war damals die einzige Meisterschaftsbehörde für College-Eishockey und bestimmte den de facto nationalen Meister basierend auf der Saisonleistung. Die IHA existierte bis 1913 weiter.
      <br />
      <br />
      Nach der Auflösung der IHA wurde 1912 die Intercollegiate Hockey League (IHL) von Harvard, Princeton und Yale gegründet. Diese Liga war eine Reaktion auf die Notwendigkeit, dass die besten College-Teams weiterhin gegeneinander antreten konnten. Die IHL existierte bis 1917, als die Aktivitäten aufgrund des Ersten Weltkriegs ausgesetzt wurden.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Wachstum und Professionalisierung
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Im Laufe des 20. Jahrhunderts wuchs das College-Eishockey zu einem nationalen Sport heran, der von Hunderten von Teams in verschiedenen Divisionen und Konferenzen gespielt wurde. Das College-Eishockey wurde auch zunehmend durch den Aufstieg von professionellen Eishockeyligen beeinflusst, wie der NHL (gegründet 1917) und der World Hockey Association (gegründet 1972).
      <br />
      <br />
      Diese professionellen Ligen boten talentierten College-Spielern die Möglichkeit, auf eine höhere Ebene aufzusteigen. Viele College-Spieler wurden beim NHL Draft ausgewählt, dem jährlichen Ereignis, bei dem NHL-Teams neue Spieler auswählen. Der erste NHL-Draft, bei dem ein College-Spieler ausgewählt wurde, fand 1967 statt. Al Karlander, ein Spieler von Michigan Tech, wurde von Detroit als insgesamt 17. Pick ausgewählt. Seitdem wurden über tausend College-Spieler von NHL-Teams gedraftet.
      <br />
      <br />
      Das College-Eishockey erlebte auch bedeutende Momente in der Geschichte des Sports. Einer der denkwürdigsten war das "Wunder auf dem Eis" im Jahr 1980, als das amerikanische Olympiateam, das hauptsächlich aus College-Spielern bestand, die Sowjetunion in einem historischen Spiel während der Olympischen Winterspiele in Lake Placid besiegte. Dies war eine bemerkenswerte Leistung, da die Sowjets zu dieser Zeit als beste Eishockeymannschaft der Welt galten.
      <br />
      <br />
      Das College-Eishockey hat daher eine lange und beeindruckende Geschichte, die von der Leidenschaft und dem Talent der amerikanischen Spieler zeugt. Das College-Eishockey hat auch zur Entwicklung und Innovation des Sports beigetragen, indem es neue Regeln, Taktiken und Stile eingeführt hat.
    </MKTypography>
  </MKBox>
</MKBox>



                                              





                                  







<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Das heutige College-Eishockey
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College-Eishockey ist heute einer der wettbewerbsfähigsten und aufregendsten Sportarten in Amerika. Es wird von über 400 Teams gespielt, die in drei NCAA-Divisionen, verschiedenen Konferenzen und regionalen Zonen unterteilt sind. In diesem Kapitel geben wir einen Überblick über die wichtigsten Ligen und Konferenzen, Top-Programme und prestigeträchtige Teams sowie die Struktur der Saison und der Meisterschaft.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Hauptligen und Konferenzen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Spieler aus Massachusetts feiern einen Sieg im College-Eishockey."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Spieler aus Massachusetts feiern einen Sieg
        </figcaption>
      </figure>
      Die NCAA (National Collegiate Athletic Association) ist die Organisation, die das College-Eishockey reguliert. Die NCAA teilt die Teams in drei Divisionen ein, basierend auf dem Niveau, Budget und der Anzahl der Stipendien, die sie anbieten. Die Divisionen sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Division I: Dies ist die höchste Division, in der die besten Teams und Spieler antreten. Division I umfasst 60 Teams, aufgeteilt in sechs Konferenzen: Atlantic Hockey, Big Ten, ECAC Hockey, Hockey East, NCHC (National Collegiate Hockey Conference) und WCHA (Western Collegiate Hockey Association). Diese Konferenzen organisieren ihre eigenen Wettbewerbe und Turniere während der Saison.
        </li>
        <li>
          Division II: Dies ist eine Zwischendivision mit weniger Teams und Spielern. In Division II gibt es nur sieben Teams, und sie haben keine eigene Konferenz. Sie treten in der Regel gegen Teams aus Division III oder anderen Ligen an.
        </li>
        <li>
          Division III: Dies ist die niedrigste Division, in der die meisten Teams und Spieler teilnehmen. Division III besteht aus 84 Teams, aufgeteilt in sieben Konferenzen: CCC (Commonwealth Coast Conference), MASCAC (Massachusetts State Collegiate Athletic Conference), MIAC (Minnesota Intercollegiate Athletic Conference), NCHA (Northern Collegiate Hockey Association), NEHC (New England Hockey Conference), SUNYAC (State University of New York Athletic Conference) und UCHC (United Collegiate Hockey Conference). Auch diese Konferenzen organisieren ihre eigenen Wettbewerbe und Turniere während der Saison.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Neben den NCAA-Divisionen und Konferenzen gibt es auch andere Ligen und Organisationen, die College-Eishockey anbieten.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Top-Programme und prestigeträchtige Teams
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      College-Eishockey hat mehrere Top-Programme und prestigeträchtige Teams, die aufgrund ihres Erfolgs, ihrer Tradition, ihres Rufs und ihres Einflusses herausragen. Einige dieser Programme und Teams sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          University of North Dakota: Eines der erfolgreichsten College-Eishockeyprogramme in Amerika, das in der NCHC-Konferenz in Division I antritt. Die Universität hat mehrere NCAA-Meisterschaften gewonnen und hat zahlreiche Spieler hervorgebracht, die in der NHL gespielt haben, darunter bekannte Namen wie Jonathan Toews, Zach Parise und T.J. Oshie.
        </li>
        <li>
          Boston College: Ein prominentes College-Eishockeyprogramm in Amerika, das in der Hockey East-Konferenz in Division I spielt. Boston College hat mehrere nationale Meisterschaften errungen. Das Programm ist auch dafür bekannt, über 80 NHL-Spieler entwickelt zu haben, darunter bekannte Absolventen wie Johnny Gaudreau, Patrick Eaves und Brian Gionta.
        </li>
        <li>
          University of Minnesota: Dieses historische College-Eishockeyprogramm, das in der Big Ten-Konferenz in Division I antritt, hat eine beeindruckende Anzahl von nationalen Meisterschaften errungen. Es ist bekannt dafür, eine Rekordzahl von Spielern hervorgebracht zu haben, die in der NHL gespielt haben, darunter Stars wie Phil Kessel, Paul Martin und Blake Wheeler.
        </li>
        <li>
          University of Wisconsin: Eines der angesehensten College-Eishockeyprogramme in Amerika, das in der Big Ten-Konferenz in Division I antritt. Wisconsin hat mehrere nationale Meisterschaften gewonnen und hat auch eine bedeutende Anzahl von Spielern ausgebildet, die in der NHL gespielt haben, darunter Ryan Suter, Joe Pavelski und Dany Heatley.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Diese Programme und Teams sind nur einige Beispiele von vielen, die das College-Eishockey zu bieten hat. Es gibt viele weitere Teams, die ihre eigene Geschichte, Kultur und Identität haben. College-Eishockey ist eine vielfältige und dynamische Sportart, die Spielern und Fans viel zu bieten hat.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  









<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Vom College zum Profi
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College-Eishockey ist nicht nur ein Sport; es ist auch ein Lernfeld. Viele Spieler, die am College-Eishockey teilnehmen, haben Ambitionen, in die professionelle Welt aufzusteigen. In diesem Kapitel werden wir besprechen, wie College-Eishockey als Sprungbrett zur NHL oder anderen professionellen Ligen dienen kann, welche Alumni es in die professionelle Eishockeywelt geschafft haben und welche Möglichkeiten jenseits der NHL existieren.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Der Weg zum Profisport
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      College-Eishockey ist eine ausgezeichnete Möglichkeit, sich auf eine Karriere im Sport vorzubereiten. Durch das Spielen von College-Eishockey können Sie Ihre Fähigkeiten, Ihr Wissen und Ihre Erfahrung verbessern, während Sie einen Abschluss erwerben. College-Eishockey bietet Ihnen auch die Möglichkeit, gegen andere talentierte Spieler anzutreten, die Sie herausfordern und inspirieren.
      <br />
      <br />
      Einer der Hauptvorteile von College-Eishockey ist, dass es Ihre Sichtbarkeit und Exposition erhöht. Das Spielen von College-Eishockey kann die Aufmerksamkeit von Scouts, Trainern und Managern von Profiteams auf sich ziehen, die nach neuen Spielern suchen. Sie können auch an Showcases, Camps und Kliniken teilnehmen, um Ihre Fähigkeiten potenziellen Arbeitgebern zu präsentieren.
      <br />
      <br />
      Ein weiterer Vorteil von College-Eishockey ist, dass es Flexibilität und Optionen bietet. Durch das Spielen von College-Eishockey können Sie die Entscheidung über Ihre Zukunft aufschieben. Sie können wählen, nach Ihrem ersten, zweiten, dritten oder vierten Jahr zu einem Profiteam zu wechseln, abhängig von Ihrer Situation und Ihren Vorlieben. Sie können auch wählen, Ihr Studium abzuschließen, bevor Sie professionell werden.
      <br />
      <br />
      College-Eishockey hat viele Spieler hervorgebracht, die in der professionellen Eishockeywelt erfolgreich waren. Einige dieser Spieler sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Jack Eichel: Er spielte eine Saison für die Boston University in der Hockey East-Konferenz in Division I. Er gewann 2015 den Hobey Baker Award als bester Spieler des Jahres. Er wurde im NHL-Draft 2015 von den Buffalo Sabres an zweiter Stelle ausgewählt, wo er zum Kapitän und Star-Spieler wurde. 2023 gewann er den Stanley Cup mit den Vegas Golden Knights.
        </li>
        <li>
          Cale Makar: Er spielte zwei Saisons für UMass Amherst in der Hockey East-Konferenz in Division I. Auch er gewann 2019 den Hobey Baker Award als bester Spieler des Jahres. Er wurde im NHL-Draft 2017 von den Colorado Avalanche an vierter Stelle ausgewählt, wo er derzeit als einer der besten Verteidiger der Liga spielt.
        </li>
        <li>
          Hilary Knight: Hilary Knight spielte vier Saisons für die University of Wisconsin in der WCHA-Konferenz in Division I. Sie war eine hochtalentierte Spielerin und wurde 2009, 2011 und 2012 als eine der zehn besten Finalisten für den Patty Kazmaier Memorial Award nominiert. Obwohl sie die Auszeichnung nicht gewann, war sie zweimal First Team All-American. Knight spielte später für die Boston Pride in der NWHL (National Women's Hockey League) und für das US-Nationalteam, mit dem sie zwei olympische Silbermedaillen und mehrere Weltmeisterschaften gewann.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Diese Spieler sind nur einige Beispiele von vielen, die ihre College-Eishockeykarrieren in professionelle Karrieren umgewandelt haben. College-Eishockey ist für viele Spieler ein bewährter Weg zum Profisport.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Der NHL-Draft und andere Möglichkeiten
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Die NHL (National Hockey League) ist die führende professionelle Eishockeyliga der Welt. Es ist der Traum vieler junger Spieler, eines Tages in der NHL zu spielen. Eine Möglichkeit, dies zu erreichen, ist durch den NHL-Draft, das jährliche Ereignis, bei dem NHL-Teams neue Spieler auswählen.
      <br />
      <br />
      Der NHL-Draft ist für viele College-Spieler ein aufregender und bedeutender Moment. Es ist eine Gelegenheit, von einem NHL-Team ausgewählt zu werden, das Ihnen einen Vertrag zum Spielen anbietet. Es würdigt auch Ihr Talent, Ihr Potenzial und Ihre harte Arbeit.
      <br />
      <br />
      Der NHL-Draft besteht aus sieben Runden, in denen jedes NHL-Team abwechselnd einen Spieler auswählt. Die Auswahlreihenfolge wird durch eine Lotterie bestimmt, wobei Teams, die in der vorherigen Saison die schlechtesten Ergebnisse erzielt haben, eine bessere Chance haben, zuerst zu wählen. Der NHL-Draft findet normalerweise im Juni oder Juli nach dem Ende der College-Eishockeysaison statt.
      <br />
      <br />
      Um für den NHL-Draft berechtigt zu sein, müssen Sie bestimmte Kriterien erfüllen. Sie müssen ein nordamerikanischer Spieler zwischen 18 und 20 Jahren oder ein internationaler Spieler zwischen 18 und 21 Jahren sein. Sie dürfen zuvor nicht von einem NHL-Team gedraftet worden sein oder Ihre Rechte aufgegeben haben.
      <br />
      <br />
      Von einem NHL-Team gedraftet zu werden, bedeutet nicht, dass Sie sofort für sie spielen müssen. Sie können wählen, zu Ihrem College-Team zurückzukehren und Ihr Studium abzuschließen, während Sie die Rechte des NHL-Teams behalten. Sie können auch wählen, dem NHL-Team oder seinem Farmteam beizutreten, wo Sie sich weiterentwickeln und trainieren können.
      <br />
      <br />
      Der NHL-Draft ist jedoch nicht der einzige Weg, um in die NHL zu gelangen. Es gibt andere Möglichkeiten wie Free Agency. Dies ist, wenn Sie als Spieler keinen Vertrag mit einem NHL-Team haben und frei sind, mit jedem Team zu verhandeln, das Interesse an Ihnen hat. Dies kann passieren, wenn Sie nicht gedraftet wurden oder wenn Ihr Vertrag mit einem NHL-Team abläuft oder aufgelöst wird. Als Free Agent können Sie versuchen, einen Vertrag mit einem NHL-Team abzuschließen, das Interesse an Ihnen hat.
      <br />
      <br />
      Dennoch ist die NHL nicht die einzige professionelle Eishockeyliga der Welt. Es gibt andere Ligen, in denen Sie als Spieler spielen und Ihren Lebensunterhalt verdienen können. Einige dieser Ligen sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          KHL (Kontinentale Hockey-Liga): Dies ist die führende professionelle Eishockeyliga in Europa und Asien. Sie besteht aus 24 Teams aus acht Ländern: Russland, Finnland, Weißrussland, Kasachstan, China, Lettland, der Slowakei und der Tschechischen Republik. Sie bietet ein hohes Spielniveau und Gehalt und zieht Spieler aus verschiedenen Ländern an.
        </li>
        <li>
          SHL (Schwedische Hockey-Liga): Dies ist die führende professionelle Eishockeyliga in Schweden. Sie besteht aus 14 Teams, die um die nationale Meisterschaft kämpfen. Sie ist für ihr schnelles und technisches Spiel bekannt und hat viele Spieler hervorgebracht, die in der NHL gespielt haben.
        </li>
        <li>
          Liiga (Finnische Hockey-Liga): Dies ist die führende professionelle Eishockeyliga in Finnland. Sie besteht aus 15 Teams, die um die nationale Meisterschaft kämpfen. Sie ist für ihr körperbetontes und taktisches Spiel bekannt und hat ebenfalls viele Spieler hervorgebracht, die in der NHL gespielt haben.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Diese Ligen sind nur einige Beispiele von vielen Ligen weltweit. Es gibt viele weitere Ligen in anderen Ländern, darunter die Schweiz, Deutschland, Frankreich, Norwegen, Dänemark, Österreich, Polen, Japan und Australien. Diese Ligen bieten Spielern unterschiedliche Spielniveaus, Gehälter und Lebensstile.
      <br />
      <br />
      College-Eishockey ist daher nicht das Ende des Weges für viele Spieler. Stattdessen ist es der Beginn einer aufregenden Reise in die professionelle Welt. College-Eishockey bietet Spielern, die ihren Träumen nachgehen, viele Möglichkeiten und Optionen.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  






<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationale Einflüsse
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College-Eishockey ist ein Sport, der nicht nur amerikanisch, sondern auch international ist. College-Eishockey wird durch die Anwesenheit und Leistung ausländischer Spieler in amerikanischen College-Wettbewerben beeinflusst. College-Eishockey beeinflusst auch die Möglichkeiten und die Entwicklung von amerikanischen Spielern im Ausland. In diesem Kapitel werden wir die Rolle und Auswirkungen internationaler Spieler im College-Eishockey sowie die Möglichkeiten für amerikanische Spieler diskutieren, international zu spielen.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Ausländische Spieler im College-Eishockey
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Quinnipiac-Eishockeyspieler feiern ein Tor."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Quinnipiac-Spieler feiern ein Tor
        </figcaption>
      </figure>
      College-Eishockey ist ein Sport, der für Spieler aus der ganzen Welt offen ist. Es gibt viele ausländische Spieler, die nach Amerika kommen, um College-Eishockey zu spielen, mit verschiedenen Hintergründen, Kulturen und Nationalitäten. Einige dieser Spieler sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Daryl Watts: Sie ist eine kanadische Eishockeyspielerin, die für Boston College und Wisconsin in der NCAA Division I zwei Spielzeiten spielte. Sie gewann 2018 als Freshman den Patty Kazmaier Award und war damit die erste Freshman, die die Auszeichnung gewann. Watts spielte dann für die Toronto Six in der Premier Hockey Federation (PHF), früher bekannt als die National Women’s Hockey League (NWHL), und unterzeichnete 2023 einen Rekordvertrag mit den Toronto Six.
        </li>
        <li>
          Rod Brind'Amour: Geboren in Kanada, spielte er College-Eishockey an der Michigan State University, bevor er in die NHL wechselte. Er hatte eine äußerst erfolgreiche Profikarriere, mit Höhepunkten wie dem Gewinn des Stanley Cups.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Diese Spieler sind nur einige Beispiele von vielen ausländischen Spielern, die College-Eishockey spielen oder gespielt haben. Es gibt viele weitere Spieler aus anderen Ländern wie Schweden, Russland, der Tschechischen Republik, Deutschland, Frankreich, der Schweiz, Dänemark, Japan und Australien.
      <br />
      <br />
      Diese ausländischen Spieler haben eine wichtige Rolle und Auswirkungen im College-Eishockey. Sie bringen ihren eigenen Stil, ihre Fähigkeiten und ihre Erfahrung in amerikanische College-Wettbewerbe ein. Sie bereichern das Niveau und die Vielfalt des Spiels. Sie erzeugen auch mehr Interesse und Aufmerksamkeit für College-Eishockey in ihren eigenen Ländern und Regionen.
      <br />
      <br />
      College-Eishockey ist ein Sport, der von internationalen Einflüssen profitiert. Es ist ein Sport, der Spieler aus der ganzen Welt willkommen heißt und schätzt.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  



                                
<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Sportstipendien und die Zukunft der Athleten
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sportstipendien bieten zahlreiche Möglichkeiten für junge Athleten, ihr Talent zu zeigen und ihre Fähigkeiten zu entwickeln. Eine der bedeutendsten Möglichkeiten ist der Erhalt eines Sportstipendiums, um auf Hochschulebene zu studieren und zu konkurrieren. In diesem Kapitel werden wir die Bedeutung von Sportstipendien diskutieren und wie Sportstipendien Amerika Ihnen helfen kann, Ihren Traum zu verwirklichen.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Die Bedeutung von Stipendien
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ein Sportstipendium ist finanzielle Unterstützung, die von einer Universität einem Studenten-Athleten zur Verfügung gestellt wird, um Studium und Sport zu kombinieren. Es kann die Kosten für Studiengebühren, Bücher, Unterkunft, Mahlzeiten und andere Ausgaben abdecken. Darüber hinaus kann es auch Vorteile wie akademische Nachhilfe, medizinische Versorgung, Trainingsmöglichkeiten und Ausrüstung bieten.
      <br />
      <br />
      Die Erlangung eines Sportstipendiums ist wettbewerbsfähig, wobei viele Faktoren eine Rolle im Zuteilungsprozess spielen, einschließlich akademischer Leistungen, sportlicher Leistung, Persönlichkeit, Motivation, Führung und Anpassungsfähigkeit. Daher ist es wichtig, sich gut vorzubereiten und sich von der Konkurrenz abzuheben.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Wie Sportstipendien Amerika helfen können
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo von Sportstipendien Amerika, das Sportausrüstung und eine Abschlusskappe zeigt, die den Fokus auf Sportstipendien für Studenten-Athleten repräsentiert."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo von Sportstipendien Amerika
        </figcaption>
      </figure>
      Sportstipendien Amerika ist eine Organisation, die sich auf die Anleitung junger Sporttalente zu Sportstipendien spezialisiert hat. Mit leidenschaftlichen und erfahrenen Experten unterstützt Sportstipendien Amerika Studenten-Athleten auf jedem Schritt der Reise, von der ersten Orientierung bis zur endgültigen Platzierung. Ihre Dienstleistungen umfassen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Ein persönliches Aufnahmegespräch, um die Wünsche, Möglichkeiten und Erwartungen des Studenten-Athleten zu besprechen.</li>
        <li>Eine professionelle Bewertung des sportlichen und akademischen Niveaus des Studenten-Athleten.</li>
        <li>Ein umfangreiches Netzwerk von Kontakten zu Trainern, Universitäten und Hochschulen.</li>
        <li>Effektive Werbung für das Profil und die Videos des Studenten-Athleten bei potenziellen Trainern.</li>
        <li>Expertenberatung zu den besten Optionen basierend auf den Vorlieben, Zielen und dem Budget des Studenten-Athleten.</li>
        <li>Verhandlung der besten Stipendienangebote im Namen des Studenten-Athleten.</li>
        <li>Hilfe bei der Beantragung der erforderlichen Dokumente wie Visum, Zeugnis, Diplom und Zulassungstests.</li>
        <li>Vorbereitung auf das Leben des Studenten-Athleten während seines Stipendiums, einschließlich Kultur, Sprache, Regeln und Tipps.</li>
        <li>Nachsorge und Follow-up während der Stipendiumszeit.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Möchten Sie Stipendienmöglichkeiten erkunden und Ihre sportlichen und akademischen Ziele erreichen? <Link to="/de/Dienstleistungen-Produkte">Sehen Sie sich die vollständige Palette</Link> der von Sportstipendien Amerika angebotenen Dienstleistungen an und vereinbaren Sie noch heute eine Beratung.
    </MKTypography>
  </MKBox>
</MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;