/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


// @mui material components
import React, { useState } from 'react'; // Step 1: Import useState


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Logos/NCAA.png";
import post3 from "assets/images/Logos/NAIA.png";



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {

  const articleStructure = [
    { id: 'intro', title: 'NJCAA: The Gateway to Major Colleges and the NCAA', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Origin and Growth of the NJCAA', isSubheader: true },
    { id: '1.1', title: 'The Three Divisions of the NJCAA', isSubheader: true },
    { id: '1.2', title: 'Transitioning to Major Colleges and the NCAA', isSubheader: true },
  
    { id: 'Chapter 2', title: 'The Role of Academics', isSubheader: true },
    { id: '2.1', title: 'Finance and Visibility', isSubheader: true },
    { id: '2.2', title: 'Challenges and the Future', isSubheader: true },
    { id: '2.3', title: 'Conclusion', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
     <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Content
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Hide</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Show</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="intro" mb={3}>

<MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>NJCAA: The Gateway to Major Colleges and the NCAA</MKTypography>
</MKBox>

<MKBox id="Chapter 1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Origin and Growth of the NJCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
The National Junior College Athletic Association (NJCAA) was founded in 1938 and has since grown into a prominent player in the American college sports landscape. The organization provides sports opportunities for junior colleges, smaller two-year institutions that provide students with a pathway to higher education and athletic excellence.

<br />
<br />
In its early years, the NJCAA was limited in scope, but it has grown and adapted to meet the needs of a diverse and evolving student body. The focus is on providing access to athletic competition while also promoting academic progress.

</MKTypography>

<MKBox id="1.1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
The Three Divisions of the NJCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
The NJCAA is divided into three divisions, each designed to meet the different needs and opportunities of student-athletes:

<br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division I:{' '}
</MKTypography>
This division includes schools capable of offering full athletic scholarships, resulting in a higher level of competition. Student-athletes here have the opportunity to develop their skills and compete at an intensive level, often as a stepping stone to NCAA divisions.

<br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division II:{' '}
</MKTypography>
Here, we find a balance between athletic competition and academic responsibilities, with schools allowed to offer partial scholarships. This ensures a healthy balance and gives student-athletes the opportunity to excel in their sport while also being prepared for academic success.   <br /><br />
<MKTypography component="span" variant="body1" fontWeight="bold">
Division III:{' '}
</MKTypography>
In this division, the emphasis is on providing competitive sports opportunities without athletic scholarships. Student-athletes have the opportunity to participate in sports while fully engaging in academic life, resulting in a well-rounded college experience.  </MKTypography>


<MKBox id="1.2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Transitioning to Major Colleges and the NCAA
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
The NJCAA plays a key role as a stepping stone for student-athletes looking to continue their academic and athletic careers at larger four-year institutions, including those in the NCAA. Many student-athletes begin their journey in the NJCAA to develop their skills, improve academic qualifications, and showcase themselves to recruiters from larger programs.


<br />
<br />
By proving themselves in the competitive environment of the NJCAA, student-athletes can attract attention from NCAA schools and potentially earn scholarships to continue their education and sports careers. This process is not only beneficial for the athletes themselves but also contributes to strengthening sports programs across the country.


</MKTypography>






                                  






<MKBox id="Chapter 2" mb={3}>
  
  
  <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              The Role of Academics
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              The NJCAA is strongly committed to the academic success and development of its student-athletes. There are rigorous academic standards, and the affiliated schools provide essential guidance to ensure that athletes excel both in the classroom and in their sport. This illustrates the crucial role of a balanced approach within the college sports landscape, although the level may be slightly lower than at some other educational institutions.
<br />
                <br />
                </MKTypography>

                <MKBox id="2.1" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Finance and Visibility
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              Despite financial resources being more limited compared to the NCAA, the NJCAA plays a crucial role in supporting athletic programs and promoting opportunities for student-athletes. The organization also works on increasing its visibility and reach with the aim of reaching and inspiring more students and athletes.


<br />
                </MKTypography>

                <MKBox id="2.2" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Challenges and the Future
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              The NJCAA, like other athletic organizations, faces challenges including navigating issues of equality in sports and supporting the mental health of student-athletes. However, with its commitment to promoting opportunities and supporting student-athletes, the organization is well positioned to continue making a positive impact.



                </MKTypography>

                <MKBox id="2.3" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Conclusion
              </MKTypography>

              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              The National Junior College Athletic Association is an essential component of the American college sports landscape, providing unique opportunities for student-athletes to compete, learn, and grow. With its commitment to academic and athletic excellence, the NJCAA remains an influential force in shaping the future of young athletes in America.








<br />


                </MKTypography>


              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="NCAA"
                    description="Het Hart van College Sport in Amerika"
                    action={{
                      type: "internal",
                      route: "/NCAA",
                      color: "info",
                      label: "lees meer",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="NAIA"
                    description="Een Alternatieve Route in College Sport in Amerika"
                    action={{
                      type: "internal",
                      route: "/NAIA",
                      color: "info",
                      label: "lees meer",
                     }}
                    />
                  </Grid>
                </Grid>
  
              </Grid>
          </Grid>
        </Container>
      </MKBox>
    );
  }
  
  export default Beschrijving;