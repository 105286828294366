/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 PRO React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';

// Pages
import Faq from "Languages/Italiano/pages/Contact/FAQs";
import Author from "Languages/Italiano/pages/Over/Overmij/Profiel";
import VirtualRealityPage from "Languages/Italiano/pages/Content/Podcast";
import AllSports from "Languages/Italiano/pages/All Sports/Overzicht";
import Contactformulier from "Languages/Italiano/pages/Contact/Contactformulier"
import Jeugd from "Languages/Italiano/pages/Over/Overmij/Jeugd";
import Amerika from "Languages/Italiano/pages/Over/Overmij/Amerika";
import NaUSA from "Languages/Italiano/pages/Over/Overmij/NaUSA";
import DienstenProducten from "Languages/Italiano/pages/Aanbod/Diensten_producten";

import Timeline from "Languages/Italiano/pages/Aanbod/Traject";
import AmericanFootball from "Languages/Italiano/pages/All Sports/Sports/AmericanFootball";
import Athletics from "Languages/Italiano/pages/All Sports/Sports/Track and Field";
import Basketball from "Languages/Italiano/pages/All Sports/Sports/Basketball";
import Golf from "Languages/Italiano/pages/All Sports/Sports/Golf";
import Hockey from "Languages/Italiano/pages/All Sports/Sports/Field Hockey";
import Baseball from "Languages/Italiano/pages/All Sports/Sports/Baseball";
import Icehockey from "Languages/Italiano/pages/All Sports/Sports/Ice Hockey";
import Rowing from "Languages/Italiano/pages/All Sports/Sports/Rowing";
import Softbal from "Languages/Italiano/pages/All Sports/Sports/Softbal";
import Tennis from "Languages/Italiano/pages/All Sports/Sports/Tennis";
import Gymnastics from "Languages/Italiano/pages/All Sports/Sports/Gymnastics";
import Soccer from "Languages/Italiano/pages/All Sports/Sports/Soccer";
import Volleyball from "Languages/Italiano/pages/All Sports/Sports/Volleyball";
import Waterpolo from "Languages/Italiano/pages/All Sports/Sports/Waterpolo";
import Wrestling from "Languages/Italiano/pages/All Sports/Sports/Wrestling";
import Swimming from "Languages/Italiano/pages/All Sports/Sports/Swimming";
import CompanyTimeline from "Languages/Italiano/pages/Over/Over Sport Scholarships America";
import Kenniscentrum from "Languages/Italiano/pages/Content/Kenniscentrum";





const routes = [
  {
    name: "Sport",
    icon: <SportsSoccerIcon></SportsSoccerIcon>,
    collapse: [
      {
        name: "Tutti gli Sport",
        description: "Scopri Tutti gli Sport",
        route: "/it/Tutti-gli-sport",
        component: <AllSports />,
        dropdown: true,
        collapse: [
          {
            name: "Atletica",
            route: "/it/Tutti-gli-sport/Atletica",
            component: <Athletics />,
          },
          {
            name: "Baseball",
            route: "/it/Tutti-gli-sport/Baseball",
            component: <Baseball />,
          },
          {
            name: "Basket",
            route: "/it/Tutti-gli-sport/Basket",
            component: <Basketball />,
          },
          {
            name: "Calcio",
            route: "/it/Tutti-gli-sport/Calcio",
            component: <Soccer />,
          },
          {
            name: "Canottaggio",
            route: "/it/Tutti-gli-sport/Canottaggio",
            component: <Rowing />,
          },
          {
            name: "Football Americano",
            route: "/it/Tutti-gli-sport/Football-Americano",
            component: <AmericanFootball />,
          },
          {
            name: "Ginnastica",
            route: "/it/Tutti-gli-sport/Ginnastica",
            component: <Gymnastics />,
          },
          {
            name: "Golf",
            route: "/it/Tutti-gli-sport/Golf",
            component: <Golf />,
          },
          {
            name: "Hockey",
            route: "/it/Tutti-gli-sport/Hockey",
            component: <Hockey />,
          },
          {
            name: "Ginnastica",
            route: "/it/Tutti-gli-sport/Hockey-su-ghiaccio",
            component: <Icehockey />,
          },
          {
            name: "Lotta",
            route: "/it/Tutti-gli-sport/Lotta",
            component: <Wrestling />,
          },
          {
            name: "Nuoto",
            route: "/it/Tutti-gli-sport/Nuoto",
            component: <Swimming />,
          },
          {
            name: "Pallanuoto",
            route: "/it/Tutti-gli-sport/Pallanuoto",
            component: <Waterpolo />,
          },
          {
            name: "Pallavolo",
            route: "/it/Tutti-gli-sport/Pallavolo",
            component: <Volleyball />,
          },
          {
            name: "Softball",
            route: "/it/Tutti-gli-sport/Softball",
            component: <Softbal />, // Note: Typo in component name `<Softbal />`, should it be `<Softball />`?
          },
          {
            name: "Tennis",
            route: "/it/Tutti-gli-sport/Tennis",
            component: <Tennis />,
          },
        ],
      },
   

],
  },     

    {
      name: "Informazioni",
      icon: <Icon>person</Icon>,
    
      collapse: [
        {
          name: "Chi Sono",
          description: "Un'introduzione",
          route: "/it/Chi-sono",
          component: <Author />,
        
          dropdown: true,
          collapse: [
            {
              name: "Parte 1: Crescere nei Paesi Bassi",
              route: "/it/Chi-sono/Crescere",
              component: <Jeugd />,
            },

            {
              name: "Parte 2: Il Mio Tempo in America",
              route: "/it/Chi-sono/Il-mio-tempo-in-America",
              component: <Amerika />,
            },

            {
              name: "Parte 3: Tornando in Olanda",
              route: "/it/Chi-sono/Il-mio-ritorno-ai-Paesi-Bassi",
              component: <NaUSA />,
            },
             ],
         },
         {
          name: "Chi Sport Scholarships America",
          description: "La storia dell'azienda",
          route: "/it/Chi-Sport-Scholarships-America",
          component: <CompanyTimeline />,
        
          dropdown: false,
          
         },
    ],
},    
 
  {
    name: "Offerte",
    icon: <Icon>monetization_on</Icon>,
    collapse: [
      {
        name: "Servizi & Prodotti",
        description: "Scopri tutti i servizi e i prodotti",
        route: "/it/Servizi-Prodotti",
        component: <DienstenProducten />,
        dropdown: false,
      
      },
      {
        name: "Processo",
        description: "Panoramica del processo",
        route: "/it/Processo",
        component: <Timeline />,
        dropdown: false,
       
      },
      
    ],
  },
  {
    name: "Contenuto",
    icon: <Icon>article</Icon>,
    collapse: [
      {
        name: "Podcast",
        route: "/it/Podcast",
        description: "Ascolta il mio podcast",
        component: <VirtualRealityPage />
      },
      {
        name: "Centro di Conoscenza",
        route: "/it/Centro-di-conoscenza",
        description: "Articoli e collegamenti utili",
        component: <Kenniscentrum />
      },
     
    ],
  },
  {
    name: "Contatti",
    icon: <Icon>mail</Icon>,
    collapse: [
      {
        name: "FAQ",
        description:"Risposte alle Domande Frequenti",
        route: "/it/FAQs",
        component: <Faq />,
      },
      {
        name: "Modulo di Contatto",
        route: "/it/Modulo-di-contatto",
        component: <Contactformulier />,
        description: "Prendi contatto",
      },
  
     
    ],
  },
];

export default routes;
