/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton"; // Import MKButton
import { Link } from 'react-router-dom';



// Rental page components
import FaqCollapse from "Languages/English/pages/Homepage/LandingPage/sections/FAQfunction";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <MKBox component="section" py={8}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
              Frequently Asked Questions
            </MKTypography>
            <MKBox mb={2}>
              <MKTypography variant="body2" align="center" color="text">
              Everything you want to know: answers to the most frequently asked questions about studying and sports in America and my services. If your question isn't listed, please get in touch.              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={10}>
          <FaqCollapse
   title="How much does it cost to study in America?"
   open={collapse === 1}
   onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
>
   The tuition fees and the cost of studying in America vary greatly depending on the university, the study program, and the location. On average, international students can expect a total annual expenditure of approximately $30,000 to as much as $70,000 or more. However, it's worth noting that many students can cover a significant portion (or sometimes the entirety) of these costs through athletic scholarships, academic scholarships, or a combination of both. American universities often offer athletic scholarships to talented athletes, providing financial support in exchange for their participation in collegiate sports programs. This allows student-athletes to pursue their academic and athletic goals without significant financial burdens.
</FaqCollapse>

<FaqCollapse
   title="What is the difference between a college and a university in America?"
   open={collapse === 2}
   onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
>
   The terminology can be confusing, especially for people outside the United States. In practice, "college" and "university" do not actually refer to the same thing. A "university" is a comprehensive term for a higher education institution that offers programs at all levels, including bachelor's, master's, and doctoral studies.

   A "college," on the other hand, is usually a part of a university and primarily focuses on undergraduate programs, such as bachelor's degrees. In other words, a college is a department within a university specifically dedicated to undergraduate education. Universities typically offer both undergraduate and graduate programs, while colleges concentrate on the undergraduate level.

   However, this distinction can vary between different institutions, and sometimes the terms are used interchangeably. Therefore, it's helpful to examine the specific details and programs of an educational institution to understand what they offer.
</FaqCollapse>

<FaqCollapse
   title="Which athletes are guided by Sport Scholarships America?"
   open={collapse === 3}
   onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
>
   I guide athletes from a wide range of sports. My strong network and partnerships in America allow me to support athletes in various disciplines. So, whether you play soccer, basketball, tennis, golf, or any other sport, I am ready to help you pursue your academic and athletic goals in the United States. For a complete list of sports, <Link to="/All-Sports" style={{ fontWeight: 'bold' }}>click here</Link>.
</FaqCollapse>

<FaqCollapse
   title="What is the typical application process, and how far in advance should I start?"
   open={collapse === 4}
   onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
>
   The process usually begins with a comprehensive introductory meeting where we discuss all the details and understand your specific situation and goals. I will also explain my approach further. The duration of the entire process can vary, but generally, I recommend starting at least 6 to 12 months in advance. It's important to emphasize that the earlier you start, the more opportunities may arise, so don't hesitate to begin the process early.

   During this period, we will collaborate to create a personalized plan that takes into account all aspects, such as admission tests, visa applications, creating sports videos, and more. Each journey is unique and tailor-made, as there are many factors to consider. For a detailed overview of the entire process, you can <Link to="/Roadmap" style={{ fontWeight: 'bold' }}>click here</Link>. This provides a step-by-step insight into what to expect during your journey to study and compete in the United States.
</FaqCollapse>

<FaqCollapse
   title="What are the fees for the services you provide?"
   open={collapse === 5}
   onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
>
   The costs for my services vary depending on your specific needs and goals. The intake conversation is an important step where we discuss your situation and develop a customized plan. It's also the opportunity to determine if we want to proceed with working together.

   After this conversation, I will be able to have a clear understanding of your wishes and needs, and based on that, I can provide you with an appropriate cost estimate.

   For a complete overview of the services and products I offer, you can <Link to="/Services-Products" style={{ fontWeight: 'bold' }}>click here</Link>. Here you will find detailed information on how I can assist you in your pursuit of studying and competing in the United States.
</FaqCollapse>

<FaqCollapse
   title="Why should I choose to be guided by Sport Scholarships America?"
   open={collapse === 6}
   onClick={() => (collapse === 6 ? setCollapse(false) : setCollapse(6))}
>
   When you are at a crossroads in realizing your dreams, as I once was, many questions and doubts may arise. The choice to do it yourself, collaborate with an agency, or even give up the whole idea can be overwhelming. I deeply understand this uncertainty because I went through it myself.

   It would be a shame if these doubts prevent someone from pursuing what is truly possible - a fantastic experience waiting to be discovered. That's why I am dedicated to helping others realize their dreams.

   I put my personal experiences into practice by being directly involved in every project. I understand the questions you might have and strive to provide a personalized approach that aligns with your unique needs. Together, we can bring your vision to life, backed by the knowledge and understanding that comes from real, lived experience.
</FaqCollapse>

          </Grid>
        </Grid>
        {/* Button Section */}
        <Grid container justifyContent="center" mt={4}>
          <Grid item xs={12} md={3}>
          <MKButton variant="gradient" color="light" fullWidth component={Link} to="/FAQs">
    View full FAQ
</MKButton>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Faq;

