import React, { useState } from 'react';
import jsonp from 'jsonp';

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

// Material Kit 2 PRO React components
import MKBox from 'components/MKBox';
import MKButton from 'components/MKButton';
import MKTypography from 'components/MKTypography';

function NewsletterOne() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    const url =
      'https://gmail.us21.list-manage.com/subscribe/post-json?u=beee14e4f9b951d6808340453&id=e3e7c3e834&f_id=00f4eee6f0';

    jsonp(`${url}&EMAIL=${email}`, { param: 'c' }, (err, data) => {
      if (err) {
        console.error(err);
        setMessage('Er is iets misgegaan. Probeer later opnieuw.');
        setStatus('error');
      } else if (data.result !== 'success') {
        setMessage(data.msg);
        setStatus('error');
      } else {
        setMessage('Je bent succesvol geabonneerd!');
        setStatus('success');
        setEmail('');
      }
    });
  };

  return (
    <MKBox component="section" py={8}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={5} mr="auto">
            <MKTypography variant="h4" mb={1}>
              Stay up to date!
            </MKTypography>
            <MKTypography variant="body2" color="text">
            Subscribe to the newsletter and receive the latest news, updates, and interviews directly in your inbox!            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6} flexDirection="column" justifyContent="center" ml="auto">
            <MKBox component="form" method="" action="" onSubmit={onSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                  <TextField
                    label="Your Email..."
                    fullWidth
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MKButton type="submit" variant="gradient" color="info" fullWidth sx={{ height: '100%' }}>
                    Subscribe
                  </MKButton>
                </Grid>
              </Grid>
              {message && (
                <MKTypography variant="body2" color={status === 'success' ? 'success' : 'error'} mt={2}>
                  {message}
                </MKTypography>
              )}
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default NewsletterOne;
