/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Track and Field1.jpg";
import Image2 from "assets/images/Sports_Images/Track and Field2.jpeg";
import Image3 from "assets/images/Sports_Images/Track and Field3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Atletica leggera e studio negli USA: un sogno realizzabile', isSubheader: false },
  
    { id: 'Capitolo 1', title: 'Capitolo 1: Storia e sviluppo dell\'atletica leggera a livello collegiale', isSubheader: false },
    { id: '1.1', title: '1.1 Gli anni iniziali e le origini', isSubheader: true },
    { id: '1.2', title: '1.2 Crescita e professionalizzazione', isSubheader: true },
  
    { id: 'Capitolo 2', title: 'Capitolo 2: L\'atletica leggera collegiale oggi', isSubheader: false },
    { id: '2.1', title: '2.1 Principali competizioni e conferenze', isSubheader: true },
    { id: '2.2', title: '2.2 Programmi di spicco e squadre prestigiose', isSubheader: true },
  
    { id: 'Capitolo 3', title: 'Capitolo 3: Dal collegio al professionismo', isSubheader: false },
    { id: '3.1', title: '3.1 Il cammino verso il professionismo', isSubheader: true },
    { id: '3.2', title: '3.2 Il rapporto con l\'atletica professionistica', isSubheader: true },
  
    { id: 'Capitolo 4', title: 'Capitolo 4: Influenze e opportunità internazionali', isSubheader: false },
    { id: '4.1', title: '4.1 Atleti stranieri e competizioni', isSubheader: true },
    { id: '4.2', title: '4.2 Atleti americani all\'estero', isSubheader: true },
  
    { id: 'Capitolo 5', title: 'Capitolo 5: Borse di studio sportive e il futuro dell\'atletica leggera', isSubheader: false },
    { id: '5.1', title: '5.1 L\'importanza delle borse di studio', isSubheader: true },
    { id: '5.2', title: '5.2 Come Sport Scholarships America può aiutare', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contents
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Nascondi</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                      key={section.id}
                      onClick={() => scrollTo(section.id)}
                      color="secondary"
                      variant="gradient"
                      sx={{ 
                        justifyContent: "flex-start",
                        pl: section.isSubheader ? 4 : 2,
                        textTransform: "none",
                        fontWeight: section.isSubheader ? "normal" : "bold",
                        borderTopLeftRadius: index === 0 ? '20px' : '0',
                        borderTopRightRadius: index === 0 ? '20px' : '0',
                        borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                        borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                        textAlign: "left",
                        width: '100%',
                      }}
                    >
                      {section.title}
                    </MKButton>
                  ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostra</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>























          <>
  <MKBox id="intro" mb={3}>
    <MKTypography variant="h1" className="h1-as-h3" mb={2}>
      Atletica leggera e Studio in America: Un Sogno Che Può Diventare Realtà
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image1}
          alt="Atleti in azione durante i Penn State Relays, un importante evento di atletica leggera universitaria."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal1(Image1)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Azione ai Penn State Relays.
        </figcaption>
      </figure>
      L'atletica leggera è uno degli sport più popolari e significativi negli Stati Uniti. Milioni di persone praticano l'atletica leggera come hobby, come parte del programma scolastico o universitario, o come atleti professionisti. L'atletica leggera è anche uno sport che offre molte opportunità per i giovani talenti di svilupparsi, competere ed eccellere al più alto livello.
      <br/>
      <br/>
      Uno dei modi migliori per sfruttare queste opportunità è ottenere una borsa di studio per l'atletica leggera negli Stati Uniti. Una borsa di studio per l'atletica leggera è il supporto finanziario che ricevi da un'università per studiare e partecipare all'atletica leggera lì. Con una borsa di studio per l'atletica leggera, puoi non solo ottenere il tuo diploma accademico ma anche partecipare a uno dei programmi di atletica leggera più prestigiosi e competitivi al mondo.
      <br/>
      <br/>
      Ma come si ottiene una borsa di studio per l'atletica leggera negli Stati Uniti? Quali sono i benefici e le sfide dello studio e della competizione a livello universitario? E come puoi prepararti per una carriera di successo come atleta dopo gli studi? Queste sono alcune delle domande a cui risponderemo in questo articolo.
      <br/>
      <br/>
      Ti diremo anche come Sportbeursamerika.nl può aiutarti a realizzare il tuo sogno. Sportbeursamerika.nl è un'organizzazione specializzata nella guida dei giovani atleti alle borse di studio sportive negli Stati Uniti. Ho molta esperienza nel trovare la migliore corrispondenza tra atleti e università. Offro assistenza personale, consigli professionali e supporto pratico ad ogni passo del processo.
      <br/>
      <br/>
      Se sei interessato a ottenere una borsa di studio per l'atletica leggera negli Stati Uniti, questo articolo fa per te. Continua a leggere e scopri tutto quello che c'è da sapere sull'atletica leggera a livello universitario negli Stati Uniti.
    </MKTypography>
    {/* Aggiungi elenco puntato o altro contenuto se necessario */}
  </MKBox>
  {/* Principali capitoli e sottocapitoli con testo di esempio */}
  <MKBox id="Chapter 1" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
      Capitolo 1: Storia e Sviluppo dell'Atletica Leggera a Livello Universitario
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      L'atletica leggera a livello universitario ha una lunga e ricca storia negli Stati Uniti. È iniziata nel XIX secolo come modo per promuovere la salute fisica e mentale degli studenti. È diventata uno sport importante che ha attratto milioni di spettatori e ha prodotto molti atleti che in seguito sono diventati famosi in tutto il mondo.
    </MKTypography>

    <MKBox id="1.1" mb={2}>
      <MKTypography variant="h4" mb={1}>
        1.1 Anni Iniziali e Origini
      </MKTypography>
      <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        La prima competizione di atletica leggera nelle università si è svolta nel 1864, quando gli studenti di Yale e Oxford si sfidarono in un incontro a Londra. La competizione consisteva in quattro eventi: corsa, salto in lungo, salto in alto e salto con l'asta. Yale vinse l'incontro 7-3.
        <br/>
        <br/>
        Questo incontro ispirò altre università a formare squadre di atletica leggera e a sfidarsi a vicenda. Nel 1873 fu fondata la prima associazione intercollegiale di atletica leggera, l'Intercollegiate Association of Amateur Athletes of America (IAAAA). L'IAAAA organizzava campionati annuali per le università nella parte orientale degli Stati Uniti.
        <br/>
        <br/>
        La fine del XIX secolo fu un'epoca importante per lo sviluppo dell'atletica leggera. Fu segnata dalla formalizzazione delle regole e dall'emergere di figure influenti come James E. Sullivan, cofondatore dell'Unione Atletica Amatoriale negli Stati Uniti. Ha svolto un ruolo significativo nella popolarizzazione dell'atletica leggera e nell'organizzazione di eventi importanti. L'introduzione dei moderni Giochi Olimpici nel 1896 ha dato una piattaforma internazionale allo sport, portando alla standardizzazione delle regole e degli eventi. Inoltre, i progressi tecnologici, come il miglioramento delle calzature e delle piste di corsa, hanno contribuito all'evoluzione dell'atletica leggera durante questo periodo.
      </MKTypography>
    </MKBox>
    <MKBox id="1.2" mb={2}>
      <MKTypography variant="h4" mb={1}>
        1.2 Crescita e Professionalizzazione
      </MKTypography>
      <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Nel XX secolo, l'atletica leggera è diventata sempre più popolare e importante nelle università. Il numero di programmi, competizioni e borse di studio di atletica leggera è aumentato. Anche il livello degli atleti è aumentato, grazie in parte a una migliore formazione, strutture e allenatori.
        <br/>
        <br/>
        Uno dei fattori che hanno contribuito alla crescita e alla professionalizzazione dell'atletica leggera a livello universitario è stata la fondazione della National Collegiate Athletic Association (NCAA) nel 1906. La NCAA è un'organizzazione che supervisiona le attività sportive di più di 1000 università negli Stati Uniti. La NCAA regola, tra le altre cose, le regole, le qualifiche, le borse di studio e le sanzioni per l'atletica leggera a livello universitario.
        <br/>
        <br/>
        Un altro fattore che ha influenzato lo sviluppo dell'atletica leggera a livello universitario è stata la relazione con l'atletica leggera professionistica. Molti atleti che hanno avuto successo a livello universitario sono poi passati al circuito professionistico. Alcuni esempi sono Jesse Owens, Carl Lewis, Jackie Joyner-Kersee e Michael Johnson.
      </MKTypography>
    </MKBox>
  </MKBox>
</>







                                              





                                  




<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 2: Atletica Leggera Universitaria Oggi
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    L'atletica leggera universitaria è oggi uno degli sport più popolari e prestigiosi negli Stati Uniti. Ogni anno, migliaia di atleti partecipano a varie competizioni ed eventi, attirando milioni di fan e l'attenzione dei media. L'atletica leggera universitaria offre anche molte opportunità agli atleti per svilupparsi, esibirsi e godersi il loro sport.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Principali Competizioni e Conferenze
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Sydney McLaughlin, eccellente atleta per l'Università del Kentucky, in uniforme da competizione."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Sydney McLaughlin ha studiato presso l'Università del Kentucky
        </figcaption>
      </figure>
      L'organizzazione principale che regola l'atletica leggera universitaria è la NCAA. La NCAA suddivide le università in tre divisioni, in base alla loro dimensione, al budget e al livello di competizione. La Divisione I è la divisione più alta, dove si trovano la maggior parte delle migliori università e degli atleti. Le Divisioni II e III sono divisioni inferiori, dove le università hanno meno borse di studio e risorse, ma pongono maggiore enfasi sul rendimento accademico.
      <br/>
      <br/>
      All'interno di ciascuna divisione, ci sono diverse conferenze, che sono gruppi di università che sono geograficamente o storicamente connesse. Ogni conferenza organizza i propri campionati e competizioni per i propri membri. Alcune delle conferenze più conosciute sono la Big Ten, la Pac-12, la SEC, la ACC e la Ivy League.
      <br/>
      <br/>
      Gli eventi principali nella stagione dell'atletica leggera universitaria sono i campionati NCAA, che si tengono annualmente a marzo (indoor) e giugno (outdoor). Questi campionati determinano quali università e atleti sono i migliori in ciascuna divisione ed evento. I campionati attirano molti spettatori, sponsor e attenzione mediatica.
    </MKTypography>
  </MKBox>

  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programmi di Eccellenza e Squadre Prestigiose
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ci sono molte università note per i loro eccellenti programmi di atletica leggera. Queste università hanno una lunga tradizione di successo, allenatori e strutture di alta qualità, e un forte appeal per gli atleti talentuosi. Alcune di queste università sono:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Oregon: Oregon è una delle università più di successo nell'atletica leggera, specialmente nella corsa. Oregon ha vinto più di 30 titoli NCAA e ha prodotto molti atleti famosi, come Steve Prefontaine, Alberto Salazar, Galen Rupp e Matthew Centrowitz.
        </li>
        <li>
          USC: USC è un'altra università di primo piano nell'atletica leggera, con un forte focus sulla corsa, il salto e il lancio. USC ha vinto più di 25 titoli NCAA e ha prodotto molti medagliati olimpici, come Quincy Watts, Andre De Grasse e Michael Norman.
        </li>
        <li>
          Arkansas: Arkansas è un'università dominante nell'atletica leggera, specialmente durante la stagione indoor. Arkansas ha vinto più di 40 titoli NCAA, di cui più di 30 indoor. Arkansas è nota per la sua profondità e versatilità in vari eventi.
        </li>
        <li>
          Texas: Texas è una delle università più versatili nell'atletica leggera, con un buon equilibrio tra squadre maschili e femminili e tra eventi individuali e staffette. Texas ha vinto più di 20 titoli NCAA e ha prodotto molti atleti di livello mondiale, come Sanya Richards-Ross, Trey Hardee, Michelle Carter e Courtney Okolo.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Queste università offrono non solo un alto livello di competizione e allenamento per i loro atleti, ma anche un'ottima atmosfera e cultura. Gli atleti fanno parte di una comunità unita che li sostiene e li motiva a raggiungere il loro potenziale.
    </MKTypography>
  </MKBox>
</MKBox>











                                              






                                  







<MKBox id="Chapter 3" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Capitolo 3: Dal College al Professionismo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        L'atletica leggera universitaria non è solo uno sport ma anche un trampolino di lancio per gli atleti che aspirano a una carriera professionale. Molti atleti che si sono distinti nel college successivamente sono passati al circuito professionale. L'atletica leggera universitaria offre agli atleti l'opportunità di svilupparsi, sperimentare e creare contatti con altri atleti, allenatori e organizzazioni.
    </MKTypography>

    <MKBox id="3.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.1 Il Percorso verso il Professionismo
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Per molti atleti, l'obiettivo è diventare atleti professionisti dopo gli studi. Ciò significa praticare il loro sport come professione e guadagnare denaro attraverso prestazioni, contratti e sponsorizzazioni. Per diventare atleti professionisti, gli atleti devono soddisfare diverse condizioni, come:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Avere un alto livello di performance e potenziale. Gli atleti professionisti devono essere in grado di competere con i migliori atleti del mondo, e migliorare continuamente e adattarsi alle condizioni mutevoli.
                </li>
                <li>
                    Mantenere una buona salute e forma fisica. Gli atleti professionisti devono prendersi cura del loro corpo e proteggersi da infortuni, malattie e sovrallenamento. Devono anche trovare un buon equilibrio tra allenamento, riposo e nutrizione.
                </li>
                <li>
                    Avere una buona guida e supporto. Gli atleti professionisti dovrebbero poter fare affidamento su un team di esperti per aiutarli con tecnica, tattiche, preparazione mentale, questioni legali, questioni finanziarie e marketing.
                </li>
                <li>
                    Avere una buona pianificazione e strategia. Gli atleti professionisti devono sapere quali obiettivi vogliono raggiungere, quali competizioni vogliono partecipare, come vogliono qualificarsi per eventi importanti e come vogliono gestire la loro carriera.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            L'atletica leggera universitaria può aiutare gli atleti a soddisfare queste condizioni. Offre agli atleti la possibilità di mostrare il proprio talento, migliorare le proprie abilità, aumentare la propria esperienza ed espandere la propria rete di contatti. L'atletica leggera universitaria può anche servire da trampolino di lancio per il livello professionale esponendo gli atleti a livelli elevati di competizione, attenzione mediatica e interesse pubblico.
        </MKTypography>
    </MKBox>
    <MKBox id="3.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.2 Relazione con l'Atletica Professionistica
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            L'atletica leggera universitaria ha una stretta relazione con l'atletica professionistica. I due livelli si influenzano reciprocamente in vari modi, come:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Sviluppo del talento: L'atletica leggera universitaria è una delle principali fonti di talento per l'atletica professionistica. Molti atleti professionisti hanno iniziato come atleti universitari e hanno gettato le basi per il loro successo futuro.
                </li>
                <li>
                    Scouting del talento: Le organizzazioni atletiche professionistiche monitorano attentamente l'atletica universitaria per scoprire e attrarre potenziali stelle. Offrono agli atleti universitari contratti, sponsorizzazioni e orientamento per convincerli a diventare professionisti.
                </li>
                <li>
                    Scambio di talenti: L'atletica leggera universitaria è aperta anche agli atleti professionisti che stanno ancora studiando o che ritornano a scuola. Alcuni atleti professionisti scelgono di completare o riprendere i loro studi mentre continuano la loro carriera sportiva.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            L'atletica leggera universitaria non è quindi solo un punto di arrivo ma anche un trampolino di lancio per gli atleti che perseguono una carriera professionale. Offre agli atleti la possibilità di prepararsi per il livello professionale, ma anche di esplorare altre strade.
        </MKTypography>
    </MKBox>
</MKBox>











                                              






                                  








<MKBox id="Chapter 4" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Capitolo 4: Influenze e Opportunità Internazionali
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        L'atletica leggera universitaria non è solo uno sport americano ma anche internazionale. È influenzata e offre opportunità agli atleti provenienti da vari paesi e culture. L'atletica leggera universitaria è uno sport che riflette la diversità e l'interconnessione del mondo.
    </MKTypography>

    <MKBox id="4.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.1 Atleti Stranieri e Competizioni
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image3}
                    alt="Lo stadio atletico famoso dell'Oregon, sede di numerosi eventi di atletica leggera universitaria."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal3(Image3)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Stadio atletico dell'Oregon, un'icona dello sport universitario.
                </figcaption>
            </figure>
            Uno dei modi in cui l'atletica leggera universitaria è internazionale è attraverso la presenza e le prestazioni degli atleti stranieri. Ogni anno, centinaia di atleti provenienti da altri paesi vengono negli Stati Uniti per studiare e competere a livello universitario. Questi atleti portano il proprio talento, stile e cultura alle università americane, arricchendo la comunità di atletica leggera con le loro prospettive ed esperienze.
            <br/>
            <br/>
            Gli atleti stranieri hanno anche un impatto significativo sui risultati e sui ranking dell'atletica leggera universitaria. Molti atleti stranieri sono tra i migliori atleti nella loro disciplina, vincendo spesso medaglie, titoli e record. Alcuni atleti stranieri sono persino così bravi da superare o sfidare gli atleti americani.
            <br/>
            <br/>
            Esempi di atleti stranieri di successo nelle competizioni universitarie americane includono:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Andre De Grasse: un velocista canadese che si è distinto al Coffeyville Community College e all'Università della California del Sud (USC), vincendo numerosi titoli NJCAA e NCAA nelle gare di velocità. Le sue prestazioni alla USC hanno stabilito la sua reputazione come uno dei principali velocisti. Dopo la sua carriera universitaria, De Grasse ha vinto diverse medaglie olimpiche, tra cui l'oro nei 200 metri alle Olimpiadi di Tokyo 2020, mostrandosi come uno dei migliori velocisti al mondo.
                </li>

                <li>
                    Kerron Clement: un atleta trinidadiano-americano eccellente nei 400 metri ostacoli. Ha studiato all'Università della Florida, dove ha vinto titoli NCAA e si è sviluppato in un ostacolista di classe mondiale. Clement ha conquistato l'oro alle Olimpiadi del 2008 e del 2016 e ha diversi campionati del mondo al suo attivo, diventando uno degli ostacolisti più vincenti della sua generazione.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Gli atleti stranieri influenzano non solo l'atletica leggera universitaria ma anche altre competizioni ed eventi internazionali. Molti atleti stranieri che hanno gareggiato a livello universitario partecipano in seguito ai Giochi Olimpici, ai Campionati del Mondo o ad altri campionati regionali o continentali. Rappresentano il proprio paese ma hanno anche legami con la loro università americana.
        </MKTypography>
    </MKBox>
    <MKBox id="4.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.2 Atleti Americani All'estero
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Un altro modo in cui l'atletica leggera universitaria è internazionale è attraverso le opportunità e le sfide che offre agli atleti americani per competere all'estero. Molti atleti americani che hanno gareggiato a livello universitario hanno la possibilità di ampliare i loro orizzonti e mettere alla prova le loro abilità contro altri atleti provenienti da paesi e culture diverse.
            <br/>
            <br/>
            Una delle principali opportunità per gli atleti americani di competere all'estero sono, ovviamente, i Giochi Olimpici. Le Olimpiadi sono il più grande e prestigioso evento sportivo al mondo, dove migliaia di atleti provenienti da oltre 200 paesi si riuniscono per competere per l'oro, l'argento e il bronzo. Le Olimpiadi sono anche un'occasione per gli atleti americani di rappresentare il loro paese, mostrare il loro patriottismo e condividere il loro orgoglio nazionale.
            <br/>
            <br/>
            Un altro evento importante per gli atleti americani per competere all'estero sono i Campionati del Mondo di Atletica Leggera. I Campionati del Mondo di Atletica Leggera sono l'evento più significativo dopo le Olimpiadi, dove si incontrano i migliori atleti del mondo per competere per il titolo mondiale. I Campionati del Mondo di Atletica Leggera sono anche un'opportunità per gli atleti americani di misurare il loro livello, alimentare le loro rivalità e stabilire la loro reputazione.
            <br/>
            <br/>
            Oltre a questi eventi principali, ci sono anche altre opportunità per gli atleti americani di competere all'estero, come la Diamond League, il Continental Tour, i World Athletics Relays o altri campionati regionali o continentali. Questi eventi offrono agli atleti americani l'opportunità di aumentare la loro esperienza, guadagni e fanbase.
            <br/>
            <br/>
            L'atletica leggera universitaria gioca un ruolo importante nello sviluppo e nel successo degli atleti americani che competono all'estero. Prepara gli atleti per il livello internazionale fornendo loro allenamenti, coaching e competizioni di alta qualità. L'atletica leggera universitaria supporta anche gli atleti che competono all'estero offrendo loro una rete di contatti, sponsor e fan.
        </MKTypography>
    </MKBox>
</MKBox>










                                              






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 5: Borse di Studio Sportive e il Futuro degli Atleti
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le borse di studio sportive offrono numerose opportunità ai giovani atleti di mostrare il loro talento e sviluppare le loro abilità. Una delle opportunità più significative è ottenere una borsa di studio sportiva per studiare e competere a livello universitario. In questo capitolo, discuteremo dell'importanza delle borse di studio sportive e di come Sport Scholarships America può aiutarti a realizzare il tuo sogno.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 L'Importanza delle Borse di Studio
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Una borsa di studio sportiva è un sostegno finanziario fornito da un'università a uno studente-atleta per combinare studio e sport. Può coprire i costi di iscrizione, libri, alloggio, pasti e altre spese. Inoltre, può offrire vantaggi come il tutorato accademico, l'assistenza medica, le strutture di allenamento e l'attrezzatura.
      <br />
      <br />
      Ottenere una borsa di studio sportiva è competitivo, con molti fattori che influenzano il processo di assegnazione, tra cui risultati accademici, prestazioni sportive, personalità, motivazione, leadership e adattabilità. È quindi importante prepararsi bene e distinguersi dalla concorrenza.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Come Sport Scholarships America Può Aiutare
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo di Sport Scholarships America, raffigurante attrezzature sportive e una toga, simbolo del focus sulle borse di studio sportive per gli studenti-atleti."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo di Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America è un'organizzazione specializzata nell'orientamento dei giovani talenti sportivi verso borse di studio sportive. Con esperti appassionati ed esperti, Sport Scholarships America supporta gli studenti-atleti in ogni fase del percorso, dall'orientamento iniziale alla collocazione finale. I loro servizi includono:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Un'intervista personale per discutere i desideri, le possibilità e le aspettative dello studente-atleta.</li>
        <li>Una valutazione professionale del livello accademico e sportivo dello studente-atleta.</li>
        <li>Una vasta rete di contatti con allenatori, università e college.</li>
        <li>Promozione efficace del profilo e dei video dello studente-atleta presso allenatori potenziali.</li>
        <li>Consulenza esperta sulle migliori opzioni in base alle preferenze, agli obiettivi e al budget dello studente-atleta.</li>
        <li>Negoziazione delle migliori offerte di borse di studio per conto dello studente-atleta.</li>
        <li>Assistenza nella richiesta dei documenti necessari, come visto, trascrizione, diploma e test di ammissione.</li>
        <li>Preparazione alla vita dello studente-atleta durante la borsa di studio, compresa cultura, lingua, regole e consigli.</li>
        <li>Assistenza e monitoraggio durante il periodo di borsa di studio.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vuoi esplorare le opportunità di borse di studio e raggiungere i tuoi obiettivi sportivi e accademici? <Link to="/it/Servizi-Prodotti">Consulta la gamma completa</Link> dei servizi offerti da Sport Scholarships America e prenota una consultazione oggi stesso.
    </MKTypography>
  </MKBox>
</MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;