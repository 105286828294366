/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Wrestling1.jpeg";
import Image2 from "assets/images/Sports_Images/Wrestling2.webp";
import Image3 from "assets/images/Sports_Images/Wrestling3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Boksestipendier i USA: Sådan kan Studier og Boksning i Amerika Gøre Dine Drømme til Virkelighed', isSubheader: false },
    
    { id: 'Chapter 1', title: 'Kapitel 1: Boksningens Historie og Udvikling i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Tidlige År', isSubheader: true },
    { id: '1.2', title: '1.2 Vækst og Udvikling', isSubheader: true },
    
    { id: 'Chapter 2', title: 'Kapitel 2: College-boksning i dag', isSubheader: false },
    { id: '2.1', title: '2.1 Vigtige Konkurrencer og Konferencer', isSubheader: true },
    { id: '2.2', title: '2.2 Topprogrammer og Prestigefyldte Hold', isSubheader: true },
    
    { id: 'Chapter 3', title: 'Kapitel 3: Fra College til Olympisk Niveau og Professionelle Ligaer', isSubheader: false },
    { id: '3.1', title: '3.1 Vejen til Toppen', isSubheader: true },
    { id: '3.2', title: '3.2 Professionel Boksning og Olympiaden', isSubheader: true },
    
    { id: 'Chapter 4', title: 'Kapitel 4: Internationale Indflydelser og Konkurrencer', isSubheader: false },
    { id: '4.1', title: '4.1 Udenlandske Boksestilarter og Traditioner', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanere på Verdensscenen', isSubheader: true },
    
    { id: 'Chapter 5', title: 'Kapitel 5: Sportslegater og fremtiden for atleter', isSubheader: false },
    { id: '5.1', title: '5.1 Vigtigheden af legater', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Legater Amerika Kan Hjælpe', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>






















          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Boksestipendier i USA: Sådan kan Studier og Boksning i Amerika Gøre Dine Drømme til Virkelighed
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Boksning championship-kamp"
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Det Nationale Mesterskab
      </figcaption>
    </figure>
    Boksning er en sport, der har været praktiseret i tusinder af år over hele verden. Det er en form for fysisk kamp, hvor to modstandere forsøger at dominere hinanden gennem greb, kast, pinning og andre teknikker. Boksning kræver ikke kun fysisk styrke, hastighed, udholdenhed og smidighed, men også mental skarphed, strategi, disciplin og udholdenhed.
    <br />
    <br />
    I USA er boksning en af de mest praktiserede og sete sportsgrene. Det har en lang og fascinerende historie, der går tilbage til kolonitiden. Siden da har det udviklet sig til en national begivenhed med millioner af fans og udøvere. Amerikansk collegeboksning repræsenterer toppen af denne udvikling. Det er niveauet, hvor landets bedste boksere mødes i spændende konkurrencer, der ofte fungerer som springbræt til professionelle eller olympiske karrierer.
    <br />
    <br />
    Betydningen af college-niveau boksning i udviklingen af atleter kan ikke undervurderes. College-boksere får ikke kun mulighed for at vise og forbedre deres atletiske talenter, men også for at studere på velrenommerede universiteter, hvor de kan erhverve værdifulde akademiske færdigheder og viden. Derudover kan de nyde godt af stipendier, der kan dække en betydelig del eller hele deres undervisningsomkostninger.
    <br />
    <br />
    Dog er det ikke en nem opgave at få et boksestipendium. Det kræver meget forberedelse, dedikation og vejledning. Heldigvis behøver du ikke gøre det alene. Sportbeursamerika.nl er en organisation, der specialiserer sig i at hjælpe unge boksetalenter med at realisere deres drøm om at studere og bokse i Amerika. Jeg tilbyder dig professionel rådgivning, personlig støtte og adgang til mit omfattende netværk af kontakter i den amerikanske bokseverden.
    Vil du gerne vide mere om, hvordan jeg kan hjælpe dig med at sikre et boksestipendium i USA? Læs videre! I denne artikel vil jeg fortælle dig mere om:
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Boksningens Historie og Udvikling i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Boksning er en sport, der har været praktiseret siden oldtiden i forskellige kulturer. Det er en af de ældste former for organiseret konkurrence, nævnt i græsk mytologi og Bibelen. Det var også en af de første sportsgrene inkluderet i de antikke olympiske lege.
    <br />
    <br />
    I USA har boksning haft sin egen historie og udvikling tæt knyttet til landets sociale og politiske kontekst. I dette kapitel vil vi give et overblik over de vigtigste faser og begivenheder i amerikansk boksehistorie og hvordan de bidrog til dannelsen af moderne college-boksning.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidlige År
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Begyndelsen på boksning i Amerika kan spores tilbage til kolonitiden, hvor europæiske bosættere bragte deres egne boksestile og traditioner til det nye kontinent. Disse stilarter var primært baseret på engelsk catch-as-catch-can boksning, som tillod greb på alle dele af kroppen undtagen hovedet. Det var en populær form for underholdning og udfordring blandt kolonisterne, der ofte arrangerede kampe på helligdage eller markedsdage.
      <br />
      <br />
      En af de tidligste kendte boksere i Amerika var George Washington, der som ung var kendt for sine boksetalenter. Han besejrede engang syv modstandere på en dag under en kamp i Virginia. En anden berømt bokser fra denne periode var Abraham Lincoln, der vandt flere kampe som teenager i Illinois.
      <br />
      <br />
      Det første college-boksehold blev dannet i 1780 på Yale University, men det tog mere end et århundrede, før college-boksning virkelig tog fart. Den første officielle interkollegiale kamp fandt sted i 1889 mellem Yale og Columbia University, hvor Yale vandt 5-0. I 1892 blev det første nationale mesterskab afholdt i New York City, med deltagelse af otte hold fra forskellige stater. Vinderen var Cornell University, der vandt alle syv kampe.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Vækst og Udvikling
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      I slutningen af det 19. og begyndelsen af det 20. århundrede begyndte boksning at sprede sig og diversificere sig i Amerika. Forskellige regionale stilarter opstod, såsom Iowa-stil boksning, der lagde vægt på hastighed og smidighed, eller Oklahoma-stil boksning, der fokuserede på styrke og kontrol. Nye regler blev også indført for at gøre boksning sikrere og mere retfærdig, såsom forbud mod farlige greb eller indførelse af tidsbegrænsninger.
      <br />
      <br />
      College-boksning voksede også i popularitet og prestige, med flere hold, konkurrencer og fans. I 1928 blev National Collegiate Athletic Association (NCAA) grundlagt for at regulere og fremme college-boksning. NCAA organiserede årlige nationale mesterskaber for forskellige divisioner baseret på universiteternes størrelse og niveau. Den mest prestigefyldte division var Division I, der omfattede cirka 80 hold.
      <br />
      <br />
      College-boksning producerede også mange stjerner og legender, der opnåede både national og international succes. Nogle eksempler inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Dan Gable: En af de største boksere gennem alle tider, der dominerede både som atlet og træner. Han vandt tre NCAA-titler for Iowa State University, en olympisk guldmedalje ved OL i 1972 uden at miste et eneste point, og 15 NCAA-titler som træner for University of Iowa.
        </li>
        <li>
          John Smith: En seksdobbelte verdensmester og to gange olympisk mester kendt for sine innovative teknikker og taktikker. Han vandt to NCAA-titler for Oklahoma State University og blev senere cheftræner for det samme hold.
        </li>
        <li>
          Cael Sanderson: Den eneste bokser, der har vundet fire NCAA-titler uden at tabe en eneste kamp. Han havde en perfekt rekord på 159-0 for Iowa State University og vandt også en olympisk guldmedalje ved OL i 2004. Han er i øjeblikket cheftræner på Penn State University, hvor han har vundet otte NCAA-titler.
        </li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dette er blot nogle eksempler på væksten og udviklingen af boksning i Amerika, som har ført til det nuværende college-boksemiljø, der betragtes som en af de mest konkurrenceprægede og spændende sportsgrene i landet.
    </MKTypography>
  </MKBox>
</MKBox>




                                  







<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: College-boksning i dag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College-boksning er en af de mest populære og prestigefyldte sportsgrene i Amerika i dag. Det tiltrækker millioner af fans og tilskuere, der følger de spændende kampe gennem fjernsynet, internettet eller live i arenaerne. College-boksning tilbyder også talrige muligheder for studerende-atleter, der kan nyde godt af stipendier, akademisk støtte og professionel vejledning.
    <br />
    <br />
    I dette kapitel vil vi fortælle dig mere om de vigtigste konkurrencer og konferencer inden for college-boksning samt de øverste programmer og prestigefyldte hold, der dominerer sporten.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Vigtige Konkurrencer og Konferencer
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="To college-boksere i en intens duel på matten."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          To boksere i aktion
        </figcaption>
      </figure>
      College-boksning organiseres af NCAA, som har tre divisioner: Division I, Division II og Division III. Hver division har sine egne regler, kriterier og mesterskaber. Den mest konkurrencedygtige og kendte division er Division I, som består af cirka 80 hold fra forskellige stater.
      <br />
      <br />
      Holdene i Division I er opdelt i forskellige konferencer, der danner regionale eller tematiske grupper. Konferencerne organiserer deres egne konkurrencer og mesterskaber, som fungerer som kvalifikationer til det nationale mesterskab. De mest fremtrædende konferencer inden for college-boksning inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Big Ten Conference: Den største og stærkeste konference inden for college-boksning, der består af 14 hold fra Midtvesten og Nordøstregionerne i landet. Big Ten har produceret de fleste nationale mestre, herunder Penn State, Iowa, Ohio State, Michigan og Minnesota.
        </li>
        <li>
          Big 12 Conference: Den næststørste og stærkeste konference inden for college-boksning, der består af 12 hold fra Syd- og Vestregionerne i landet. Big 12 har også produceret mange nationale mestre, herunder Oklahoma State, Oklahoma, Iowa State og Missouri.
        </li>
        <li>
          Atlantic Coast Conference (ACC): En fremadstormende konference inden for college-boksning, der består af seks hold fra Sydøstregionen i landet. ACC har fået mere opmærksomhed på grund af præstationerne fra hold som Virginia Tech, North Carolina State og North Carolina.
        </li>
        <li>
          Eastern Intercollegiate Wrestling Association (EIWA): En historisk konference inden for college-boksning, der består af 16 hold fra Østregionen i landet. EIWA er den ældste boksekonference i Amerika, grundlagt i 1905. EIWA har også produceret nogle nationale mestre, herunder Cornell, Lehigh, Princeton og Navy.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dette er blot nogle få af de mange konferencer inden for college-boksning, hver med deres egne rivaliseringer, traditioner og fans. Konferencer er imidlertid ikke den eneste faktor, der bestemmer, hvor succesfuldt et hold er. Der er andre aspekter, der spiller en rolle for succesen af et bokseprogram.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Topprogrammer og Prestigefyldte Hold
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et bokseprogram er en betegnelse, der bruges til at henvise til organisationen og strukturen af et universitetets boksehold. Et bokseprogram inkluderer aspekter såsom træner, personale, faciliteter, rekruttering, træning, finansiering og holdets kultur.
      <br />
      <br />
      Et godt bokseprogram kan gøre en betydelig forskel i udviklingen af ​​studerende-atleter. Et godt bokseprogram kan give studerende-atleter:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          En erfaren og kvalificeret træner, der kan lære dem at forbedre deres teknik, taktik, kondition og sindstilstand.
        </li>
        <li>
          Et dedikeret og professionelt personale, der kan støtte dem på områder som ernæring, forebyggelse af skader, restitution, akademisk vejledning og karriereplanlægning.
        </li>
        <li>
          Moderne og veludstyrede faciliteter, hvor de kan træne på et højt niveau med passende udstyr og materialer.
        </li>
        <li>
          En effektiv og etisk rekrutteringsstrategi, der kan hjælpe dem med at tiltrække og fastholde talentfulde og motiverede boksere.
        </li>
        <li>
          En intens og varieret træningsplan, der kan udfordre dem og forberede dem på forskellige kampe og situationer.
        </li>
        <li>
          Tilstrækkelig og stabil finansiering, der kan hjælpe dem med at dække omkostningerne ved boksning, såsom rejser, udstyr, stipendier og andre udgifter.
        </li>
        <li>
          En positiv og inspirerende kultur, der kan motivere og opmuntre dem til at nå deres potentiale, forfølge deres mål og dele deres passion for sporten.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Der er mange universiteter i Amerika, der har fremragende bokseprogrammer, der har haft succes i college-boksning i årevis. Nogle af disse programmer inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Penn State: Det nuværende top-hold i college-boksning, som har vundet det nationale mesterskab otte gange siden 2011. Penn State er kendt for sin aggressive og spektakulære boksestil, ledet af træner Cael Sanderson, en af de største boksere gennem alle tider.
        </li>
        <li>
          Iowa: Det mest succesrige hold i historien om college-boksning, der har vundet det nationale mesterskab over 20 gange siden 1975. Iowa er kendt for sin aggressive og dominerende boksestil, ledet af træner Tom Brands, en tidligere olympisk mester.
        </li>
        <li>
          Oklahoma State: Det næst mest succesrige hold i historien om college-boksning, der har vundet det nationale mesterskab over 20 gange. Oklahoma State er kendt for sin tekniske og taktiske boksestil, ledet af træner John Smith, en af de største boksere gennem alle tider.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dette er blot nogle få af de mange top-programmer og prestigefyldte hold inden for college-boksning, hver med sine egne karakteristika, præstationer og fans. Det er imidlertid ikke let at være en del af disse programmer og hold. Det kræver meget talent, hårdt arbejde og dedikation at sikre sig et boksestipendium.
    </MKTypography>
  </MKBox>
</MKBox>
                          






                                  








<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Fra College til Olympisk Niveau og Professionelle Ligaer
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College-boksning er ikke endestationen for mange studerende-atleter, der drømmer om en karriere inden for sporten. Det er snarere et springbræt til højere niveauer, hvor de kan konkurrere med de bedste boksere i verden. I dette kapitel vil vi fortælle dig mere om vejen til toppen inden for professionel eller olympisk boksning og om amerikanske bokseres succeser og udfordringer på verdensplan.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Vejen til Toppen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      College-boksning er en af de mest konkurrencedygtige og krævende sportsgrene i Amerika. Det kræver meget talent, hårdt arbejde og dedikation at sikre sig et boksestipendium, og endnu mere at skinne i nationale konkurrencer. Men selvom du er en af de bedste college-boksere, er det ikke en garanti for succes på professionelt eller olympisk niveau.
      <br />
      <br />
      Der er flere faktorer, der gør det udfordrende at gå fra college-boksning til professionel eller olympisk boksning. Nogle af disse faktorer inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Stilforskelle: College-boksning praktiseres ifølge folkstilregler, der er unikke for Amerika. Disse regler fokuserer på at kontrollere og dominere modstanderen, hvor der gives point for at ride, undslippe, vende og nær falde. Professionel eller olympisk boksning derimod praktiseres ifølge fristil- eller græsk-romerske regler, der anvendes internationalt. Disse regler lægger vægt på at score point gennem kast, vip, udsættelser og fald. Det betyder, at college-boksere skal tilpasse deres teknik, taktik og strategi for at konkurrere med boksere fra andre lande, der har mere erfaring med disse stilarter.
        </li>
        <li>
          Aldersforskelle: College-boksning er en sport for unge atleter, normalt mellem 18 og 23 år gamle. Professionel eller olympisk boksning er imidlertid en sport for voksne atleter, normalt mellem 25 og 35 år gamle. Det betyder, at college-boksere skal konkurrere med ældre og mere erfarne boksere, der har større fysisk styrke, mental modenhed og teknisk forfinelse.
        </li>
        <li>
          Finansielle forskelle: College-boksning er en sport, der støttes af universiteter, der giver studerende-atleter stipendier, faciliteter, træning og andre fordele. Professionel eller olympisk boksning modtager imidlertid kun begrænset økonomisk støtte fra regeringen eller sponsorer. Det betyder, at college-boksere skal søge andre indtægtskilder for at dække deres træning, rejser, udstyr og leveomkostninger.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dette er blot nogle af de mange udfordringer, som college-boksere skal overvinde for at forfølge deres drøm om en professionel eller olympisk karriere. Dog, på trods af disse udfordringer, er der også mange succeser og inspirerende historier om amerikanske boksere, der har nået det højeste niveau.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professionel Boksning og Olympiaden
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Professionel boksning eller olympisk boksning er sportens højdepunkt, hvor de bedste boksere i verden konkurrerer i prestigefyldte konkurrencer og begivenheder. Der er to hovedformer for professionel eller olympisk boksning: fristil og græsk-romersk.
      <br />
      <br />
      Fristil-boksning er en form for boksning, hvor alle typer greb på hele kroppen er tilladt, så længe de ikke er farlige eller upassende. Fristil-boksning praktiseres af både mænd og kvinder, i forskellige vægtklasser. Fristil-boksning er en af de ældste sportsgrene i de olympiske lege, afholdt siden 1904 for mænd og siden 2004 for kvinder.
      <br />
      <br />
      Græsk-romersk boksning er en form for boksning, hvor kun greb over taljen er tilladt, og brugen af benene til at gribe eller kaste modstanderen er forbudt. Græsk-romersk boksning praktiseres kun af mænd, i forskellige vægtklasser. Græsk-romersk boksning er den ældste form for boksning i de olympiske lege, afholdt siden 1896.
      <br />
      <br />
      Amerikanske boksere har opnået mange succeser både i fristil- og græsk-romersk boksning, både ved OL og i andre internationale konkurrencer som VM, Panamerikanske Lege eller Verdenscuppen. Nogle af de mest succesrige og kendte amerikanske boksere inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Jordan Burroughs: En femdobbel verdensmester og olympisk mester i fristil-boksning, kendt for sin eksplosive og dynamiske stil. Han vandt en guldmedalje ved OL i 2012 i London og fire verdensmesterskaber i 2011, 2013, 2015 og 2017. Han vandt også to NCAA-titler for University of Nebraska.
        </li>
        <li>
          Kyle Snyder: En tredobbelt verdensmester og olympisk mester i fristil-boksning, kendt for sin styrke og teknik. Han vandt en guldmedalje ved OL i 2016 i Rio de Janeiro og tre verdensmesterskaber i 2015, 2017 og 2019. Han vandt også tre NCAA-titler for Ohio State University.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dette er blot nogle få af de mange succeser og inspirerende historier om amerikanske boksere på professionelt eller olympisk niveau. De viser, at det er muligt at opnå din drøm, hvis du er villig til at arbejde hårdt, lære og vokse.
    </MKTypography>
  </MKBox>
</MKBox>
                            






                                  





<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationale Indflydelser og Konkurrencer
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Boksning er en universel sport, praktiseret og værdsat i mange lande og kulturer. Boksning har også mange stilarter og traditioner, der har oprindelse og udvikling i forskellige regioner og perioder. I dette kapitel vil vi fortælle dig mere om de internationale indflydelser og konkurrencer i boksning og hvordan de påvirker og udfordrer amerikanske boksere.
  </MKTypography>
  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Udenlandske Boksestilarter og Traditioner
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Luftbillede af en NCAA-boksekamp i et fyldt stadion."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          En kamp i NCAA-turneringen
        </figcaption>
      </figure>
      Boksning er en sport, der har været praktiseret siden oldtiden i forskellige dele af verden. Det er en af de ældste former for fysisk udtryk, ofte forbundet med religiøse, kulturelle eller sociale betydninger. Der er mange typer af boksning, hver med sine egne karakteristika, regler og teknikker. Nogle eksempler inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Sumobrydning: En traditionel japansk boksestil betragtes som en national sport og en hellig ritual. Sumobrydning praktiseres af tunge og stærke boksere, der forsøger at skubbe hinanden ud af en cirkulær ring eller kaste dem til jorden.
        </li>
        <li>
          Mongolsk brydning: En traditionel mongolsk boksestil betragtes som et symbol på mod og styrke. Mongolsk brydning praktiseres af muskuløse og adrætte boksere, der forsøger at bringe hinanden til jorden gennem greb på overkroppen eller benene.
        </li>
        <li>
          Tyrkisk oliebrydning: En traditionel tyrkisk boksestil betragtes som et kulturarv og en kunstart. Tyrkisk oliebrydning praktiseres af olierede og muskuløse boksere, der forsøger at besejre hinanden gennem greb på bukserne eller bæltet.
        </li>
        <li>
          Senegalesisk brydning: En traditionel senegalesisk boksestil betragtes som en populær og lukrativ sport. Senegalesisk brydning praktiseres af atletiske og farverige boksere, der forsøger at vælte hinanden gennem kast, slag eller spark.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dette er blot nogle få af de mange udenlandske boksestilarter og traditioner, hver med sin egen historie, kultur og fans. Disse stilarter har også påvirket amerikansk college-boksning ved at introducere nye teknikker, taktikker eller udfordringer. Mange amerikanske college-boksere har rejst eller trænet i andre lande for at udvide deres horisonter og forbedre deres færdigheder.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikanere på Verdensscenen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Amerikansk college-boksning fokuserer ikke kun på nationale konkurrencer, men også på internationale konkurrencer. Der er mange internationale begivenheder, hvor amerikanske college-boksere kan deltage for at konkurrere med de bedste boksere i verden. Nogle af disse begivenheder inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Olympiske Lege: Den største og mest prestigefyldte begivenhed inden for professionel eller olympisk boksning, afholdt hvert fjerde år. De Olympiske Lege er det ultimative mål for mange college-boksere, der drømmer om at vinde en guldmedalje for deres land.
        </li>
        <li>
          Verdensmesterskaberne: Den næststørste og mest prestigefyldte begivenhed inden for professionel eller olympisk boksning, afholdt årligt. Verdensmesterskaberne er en betydelig mulighed for college-boksere for at kvalificere sig til OL eller etablere deres ry på verdensscenen.
        </li>
        <li>
          Verdenscuppen: Den næststørste og mest prestigefyldte begivenhed inden for regional boksning, afholdt årligt. Verdenscuppen er en betydelig mulighed for college-boksere for at være en del af et nationalt hold, der konkurrerer mod andre nationale hold i et holdformat.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Dette er blot nogle få af de mange internationale begivenheder, hvor amerikanske college-boksere kan deltage for at vise deres talenter og forfølge deres drømme. Disse begivenheder er imidlertid ikke kun en kilde til ære og herlighed, men også en kilde til udfordring og vanskelighed. College-boksere skal konkurrere med boksere fra andre lande, der ofte har mere erfaring, stil eller motivation.
    </MKTypography>
  </MKBox>
</MKBox>
                         






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Sportslegater og fremtiden for atleter
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sportslegater tilbyder talrige muligheder for unge atleter til at vise deres talent og udvikle deres færdigheder. En af de mest betydningsfulde muligheder er at opnå et sportslegat for at studere og konkurrere på kollegialt niveau. I dette kapitel vil vi diskutere vigtigheden af sportslegater og hvordan Sport Legater Amerika kan hjælpe dig med at realisere din drøm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vigtigheden af legater
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et sportslegat er økonomisk støtte, som et universitet tilbyder til en studerende-atlet for at kombinere studier og sport. Det kan dække omkostningerne til undervisning, bøger, bolig, måltider og andre udgifter. Derudover kan det også tilbyde fordele såsom akademisk vejledning, lægehjælp, træningsfaciliteter og udstyr.
      <br />
      <br />
      At opnå et sportslegat er konkurrencepræget, hvor mange faktorer spiller en rolle i tildelingsprocessen, herunder akademiske præstationer, atletisk præstation, personlighed, motivation, lederskab og tilpasningsevne. Derfor er det vigtigt at forberede sig godt og adskille sig fra konkurrencen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Legater Amerika Kan Hjælpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo af Sport Legater Amerika, der viser sportsudstyr og en eksamenshue, der repræsenterer fokus på sportslegater for studerende-atleter."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo af Sport Legater Amerika
        </figcaption>
      </figure>
      Sport Legater Amerika er en organisation specialiseret i at guide unge sports talenter til sportslegater. Med passionerede og erfarne eksperter støtter Sport Legater Amerika studerende-atleter på hvert trin af rejsen, fra indledende orientering til endelig placering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intake-interview for at diskutere den studerende-athlets ønsker, muligheder og forventninger.</li>
        <li>En professionel evaluering af den studerende-athlets atletiske og akademiske niveau.</li>
        <li>Et omfattende netværk af kontakter med trænere, universiteter og kollegier.</li>
        <li>Effektiv markedsføring af den studerende-athlets profil og videoer til potentielle trænere.</li>
        <li>Ekspertrådgivning om de bedste muligheder baseret på den studerende-athlets præferencer, mål og budget.</li>
        <li>Forhandling af de bedste legat tilbud på vegne af den studerende-atlet.</li>
        <li>Hjælp til ansøgning om nødvendige dokumenter, såsom visum, transkript, eksamensbevis og optagelsesprøver.</li>
        <li>Forberedelse til den studerende-atlets liv under deres legat, herunder kultur, sprog, regler og tips.</li>
        <li>Efterpleje og opfølgning under legatperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ønsker du at udforske legatmuligheder og opnå dine sportslige og akademiske mål? <Link to="/da/Tjenester-Produkter">Se det fulde udvalg</Link> af tjenester tilbudt af Sport Legater Amerika og planlæg en konsultation i dag.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;