/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Página del producto: https://www.creative-tim.com/product/material-kit-pro-react
* Derechos de autor 2023 Creative Tim (https://www.creative-tim.com)

Codificado por www.creative-tim.com

 =========================================================

* El aviso de derechos de autor anterior y este aviso de permisos deben incluirse en todas las copias o partes sustanciales del software.
*/

import { useState } from "react";

// Componentes materiales de @mui
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Componentes de Material Kit 2 PRO React
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton"; // Importar MKButton
import { Link } from 'react-router-dom';

// Componentes de la página de alquiler
import FaqCollapse from "Languages/Espanol/pages/Homepage/LandingPage/sections/FAQfunction";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <MKBox component="section" py={8}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
              Preguntas frecuentes
            </MKTypography>
            <MKBox mb={2}>
              <MKTypography variant="body2" align="center" color="text">
              Todo lo que desea saber: respuestas a las preguntas más frecuentes sobre estudios y deportes en Estados Unidos y mis servicios. Si su pregunta no está en la lista, por favor póngase en contacto.
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={10}>
          <FaqCollapse
   title="¿Cuánto cuesta estudiar en Estados Unidos?"
   open={collapse === 1}
   onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
>
   Las tasas de matrícula y el costo de estudiar en Estados Unidos varían mucho según la universidad, el programa de estudios y la ubicación. En promedio, los estudiantes internacionales pueden esperar un gasto anual total de aproximadamente $30,000 hasta $70,000 o más. Sin embargo, vale la pena señalar que muchos estudiantes pueden cubrir una parte significativa (o a veces la totalidad) de estos costos a través de becas deportivas, becas académicas o una combinación de ambas. Las universidades estadounidenses a menudo ofrecen becas deportivas a atletas talentosos, brindando apoyo financiero a cambio de su participación en programas deportivos universitarios. Esto permite a los estudiantes atletas perseguir sus objetivos académicos y deportivos sin cargas financieras significativas.
</FaqCollapse>

<FaqCollapse
   title="¿Cuál es la diferencia entre un college y una universidad en Estados Unidos?"
   open={collapse === 2}
   onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
>
   La terminología puede ser confusa, especialmente para personas fuera de Estados Unidos. En la práctica, "college" y "university" no se refieren realmente a lo mismo. Una "universidad" es un término completo para una institución de educación superior que ofrece programas en todos los niveles, incluyendo licenciatura, maestría y doctorado.

   Un "college", por otro lado, suele ser una parte de una universidad y se enfoca principalmente en programas de pregrado, como licenciaturas. En otras palabras, un college es un departamento dentro de una universidad dedicado específicamente a la educación de pregrado. Las universidades suelen ofrecer tanto programas de pregrado como de posgrado, mientras que los colleges se concentran en el nivel de pregrado.

   Sin embargo, esta distinción puede variar entre diferentes instituciones y a veces los términos se utilizan indistintamente. Por lo tanto, es útil examinar los detalles específicos y los programas de una institución educativa para comprender lo que ofrecen.
</FaqCollapse>

<FaqCollapse
   title="¿Qué atletas son guiados por Sport Scholarships America?"
   open={collapse === 3}
   onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
>
   Guío a atletas de una amplia gama de deportes. Mi sólida red y colaboraciones en Estados Unidos me permiten apoyar a atletas en diversas disciplinas. Entonces, ya sea que juegue al fútbol, baloncesto, tenis, golf o cualquier otro deporte, estoy listo para ayudarlo a perseguir sus objetivos académicos y deportivos en Estados Unidos. Para obtener una lista completa de deportes, <Link to="/es/Todos-los-deportes" style={{ fontWeight: 'bold' }}>haga clic aquí</Link>.
</FaqCollapse>

<FaqCollapse
   title="¿Cuál es el proceso de solicitud típico y cuánto tiempo de anticipación debo comenzar?"
   open={collapse === 4}
   onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
>
   El proceso generalmente comienza con una reunión introductoria integral en la que discutimos todos los detalles y comprendemos su situación y objetivos específicos. También explicaré mi enfoque en detalle. La duración de todo el proceso puede variar, pero en general, recomiendo comenzar al menos 6 a 12 meses de anticipación. Es importante enfatizar que cuanto antes comience, más oportunidades pueden surgir, así que no dude en comenzar el proceso temprano.

   Durante este período, colaboraremos para crear un plan personalizado que tenga en cuenta todos los aspectos, como exámenes de admisión, solicitudes de visa, creación de videos deportivos y más. Cada viaje es único y a medida, ya que hay muchos factores a considerar. Para obtener una descripción detallada de todo el proceso, puede <Link to="/es/Proceso" style={{ fontWeight: 'bold' }}>hacer clic aquí</Link>. Esto proporciona una visión paso a paso de qué esperar durante su viaje para estudiar y competir en Estados Unidos.
</FaqCollapse>

<FaqCollapse
   title="¿Cuáles son las tarifas por los servicios que proporciona?"
   open={collapse === 5}
   onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
>
   Los costos de mis servicios varían según sus necesidades y objetivos específicos. La conversación inicial es un paso importante en el que discutimos su situación y desarrollamos un plan personalizado. También es una oportunidad para determinar si deseamos continuar trabajando juntos.

   Después de esta conversación, podré comprender claramente sus deseos y necesidades, y en función de eso, podré proporcionarle una estimación de costos adecuada.

   Para obtener una descripción completa de los servicios y productos que ofrezco, puede <Link to="/es/Servicios-Productos" style={{ fontWeight: 'bold' }}>hacer clic aquí</Link>. Aquí encontrará información detallada sobre cómo puedo ayudarlo en su búsqueda de estudiar y competir en Estados Unidos.
</FaqCollapse>

<FaqCollapse
   title="¿Por qué debería elegir ser guiado por Sport Scholarships America?"
   open={collapse === 6}
   onClick={() => (collapse === 6 ? setCollapse(false) : setCollapse(6))}
>
   Cuando te encuentras en una encrucijada para hacer realidad tus sueños, como yo una vez estuve, pueden surgir muchas preguntas y dudas. La elección de hacerlo usted mismo, colaborar con una agencia o incluso abandonar toda la idea puede ser abrumadora. Comprendo profundamente esta incertidumbre porque yo mismo la experimenté.

   Sería una lástima si estas dudas impidieran que alguien persiga lo que realmente es posible, una experiencia fantástica que espera ser descubierta. Por eso me dedico a ayudar a otros a hacer realidad sus sueños.

   Pongo mis experiencias personales en práctica al estar directamente involucrado en cada proyecto. Comprendo las preguntas que podrías tener y me esfuerzo por proporcionar un enfoque personalizado que se alinee con tus necesidades únicas. Juntos, podemos dar vida a tu visión, respaldados por el conocimiento y la comprensión que provienen de experiencias reales y vividas.
</FaqCollapse>

          </Grid>
        </Grid>
        {/* Sección de botones */}
        <Grid container justifyContent="center" mt={4}>
          <Grid item xs={12} md={3}>
          <MKButton variant="gradient" color="light" fullWidth component={Link} to="/es/FAQs">
    Ver FAQ completo
</MKButton>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Faq;
