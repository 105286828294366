/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Wrestling1.jpeg";
import Image2 from "assets/images/Sports_Images/Wrestling2.webp";
import Image3 from "assets/images/Sports_Images/Wrestling3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Borse di studio per il wrestling negli Stati Uniti: Come Studiare e Fare Wrestling in America può Realizzare i Tuoi Sogni', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capitolo 1: La Storia e lo Sviluppo del Wrestling in America', isSubheader: false },
    { id: '1.1', title: '1.1 Anni Iniziali', isSubheader: true },
    { id: '1.2', title: '1.2 Crescita ed Evoluzione', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capitolo 2: La Lotta Universitaria Oggi', isSubheader: false },
    { id: '2.1', title: '2.1 Competizioni e Conferenze Principali', isSubheader: true },
    { id: '2.2', title: '2.2 Programmi di punta e Squadre Prestigiose', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capitolo 3: Dal College al Livello Olimpico e alle Leghe Professionistiche', isSubheader: false },
    { id: '3.1', title: '3.1 Il Percorso per la Vetra', isSubheader: true },
    { id: '3.2', title: '3.2 Lotta Professionistica e Olimpica', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capitolo 4: Influenze e Competizioni Internazionali', isSubheader: false },
    { id: '4.1', title: '4.1 Stili e Tradizioni di Lotta Stranieri', isSubheader: true },
    { id: '4.2', title: '4.2 Americani sul Palcoscenico Mondiale', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capitolo 5: Borse di Studio Sportive e il Futuro degli Atleti', isSubheader: false },
    { id: '5.1', title: '5.1 L\'Importanza delle Borse di Studio', isSubheader: true },
    { id: '5.2', title: '5.2 Come Sport Scholarships America Può Aiutare', isSubheader: true },
  ];
  
  // Other components remain unchanged.
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indice
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Nascondi</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostra</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Borse di studio per il wrestling negli Stati Uniti: Come Studiare e Fare Wrestling in America può Realizzare i Tuoi Sogni
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Vista aerea di uno stadio affollato durante una partita di wrestling universitario."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Il Campionato Nazionale
      </figcaption>
    </figure>
    Il wrestling è uno sport praticato da migliaia di anni in tutto il mondo. È una forma di combattimento fisico in cui due avversari cercano di dominarsi a vicenda attraverso prese, lanci, schienamenti e altre tecniche. Il wrestling richiede non solo forza fisica, velocità, resistenza e flessibilità, ma anche lucidità mentale, strategia, disciplina e perseveranza.
    <br />
    <br />
    Negli Stati Uniti, il wrestling è uno degli sport più praticati e seguiti. Ha una lunga e affascinante storia che risale ai tempi coloniali. Da allora, si è sviluppato in un fenomeno nazionale con milioni di fan e praticanti. Il wrestling universitario americano rappresenta il culmine di questo sviluppo. È il livello in cui i migliori wrestler del paese si incontrano in competizioni emozionanti che spesso fungono da trampolino di lancio per carriere professionali o olimpiche.
    <br />
    <br />
    La significatività del wrestling universitario nello sviluppo degli atleti non può essere sottovalutata. I wrestler universitari non solo hanno l'opportunità di mostrare e migliorare i loro talenti atletici, ma anche di studiare presso università rinomate, dove possono acquisire preziose competenze e conoscenze accademiche. Inoltre, possono beneficiare di borse di studio che possono coprire una parte significativa o l'intero costo della loro retta universitaria.
    <br />
    <br />
    Tuttavia, ottenere una borsa di studio per il wrestling non è un compito facile. Richiede molta preparazione, dedizione e orientamento. Fortunatamente, non devi farlo da solo. Sportbeursamerika.nl è un'organizzazione specializzata nell'aiutare giovani talenti del wrestling a realizzare il loro sogno di studiare e fare wrestling in America. Ti offro consigli professionali, supporto personale e accesso alla mia vasta rete di contatti nel mondo del wrestling americano.
    Vuoi sapere di più su come posso aiutarti a ottenere una borsa di studio per il wrestling negli USA? Continua a leggere! In questo articolo, ti parlerò di più su:
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 1: La Storia e lo Sviluppo del Wrestling in America
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Il wrestling è uno sport praticato fin dai tempi antichi in varie culture. È una delle forme più antiche di competizione organizzata, menzionata nella mitologia greca e nella Bibbia. Era anche uno dei primi sport inclusi nei giochi olimpici antichi.
    <br />
    <br />
    Negli Stati Uniti, il wrestling ha avuto una propria storia ed evoluzione strettamente legate al contesto sociale e politico del paese. In questo capitolo, forniremo una panoramica delle principali fasi ed eventi della storia del wrestling americano e di come hanno contribuito alla formazione del wrestling universitario moderno.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Anni Iniziali
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Gli inizi del wrestling in America possono essere fatti risalire ai tempi coloniali quando i coloni europei portarono i propri stili e tradizioni di wrestling al nuovo continente. Questi stili erano principalmente basati sul wrestling inglese catch-as-catch-can, che consentiva prese su qualsiasi parte del corpo tranne la testa. Era una forma di intrattenimento e sfida popolare tra i coloni che spesso organizzavano incontri durante le festività o nei giorni di mercato.
      <br />
      <br />
      Uno dei primi wrestler conosciuti in America fu George Washington, che, da giovane, era noto per le sue abilità nel wrestling. Una volta sconfisse sette avversari in un giorno durante un incontro in Virginia. Un altro famoso wrestler di questo periodo fu Abraham Lincoln, che vinse più incontri da adolescente in Illinois.
      <br />
      <br />
      La prima squadra di wrestling universitaria fu formata nel 1780 presso la Yale University, ma ci volle più di un secolo perché il wrestling universitario decollasse veramente. Il primo incontro intercollegiale ufficiale ebbe luogo nel 1889 tra Yale e la Columbia University, con Yale che vinse 5-0. Nel 1892, si tenne il primo campionato nazionale a New York City, con otto squadre provenienti da
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crescita ed Evoluzione
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Verso la fine del XIX secolo e all'inizio del XX secolo, il wrestling cominciò a diffondersi e a diversificarsi in America. Emergono vari stili regionali, come il wrestling in stile Iowa, che enfatizzava velocità e agilità, o il wrestling in stile Oklahoma, che si concentrava sulla forza e il controllo. Furono anche introdotte nuove regole per rendere il wrestling più sicuro ed equo, come il divieto di prese pericolose o l'introduzione dei limiti di tempo.
      <br />
      <br />
      Il wrestling universitario crebbe anche in popolarità e prestigio, con più squadre, competizioni e fan. Nel 1928, fu fondata la National Collegiate Athletic Association (NCAA) per regolare e promuovere il wrestling universitario. La NCAA organizzava campionati nazionali annuali per diverse divisioni basate sulle dimensioni e il livello delle università. La divisione più prestigiosa era la Division I, che comprendeva circa 80 squadre.
      <br />
      <br />
      Il wrestling universitario ha anche prodotto molte stelle e leggende che hanno ottenuto successo nazionale e internazionale. Alcuni esempi includono:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Dan Gable: Uno dei più grandi wrestler di tutti i tempi, che ha dominato sia come atleta che come allenatore. Ha vinto tre titoli NCAA per la Iowa State University, una medaglia d'oro alle Olimpiadi del 1972 senza perdere un solo punto e 15 titoli NCAA come allenatore per la University of Iowa.
        </li>
        <li>
          John Smith: Campione mondiale sei volte e campione olimpico due volte, noto per le sue tecniche e tattiche innovative. Ha vinto due titoli NCAA per l'Oklahoma State University e in seguito è diventato il capo allenatore della stessa squadra.
        </li>
        <li>
          Cael Sanderson: L'unico wrestler a vincere quattro titoli NCAA senza perdere un singolo incontro. Ha un record perfetto di 159-0 per la Iowa State University e ha anche vinto una medaglia d'oro alle Olimpiadi del 2004. Attualmente è il capo allenatore della Penn State University, dove ha vinto otto titoli NCAA.
        </li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Questi sono solo alcuni esempi della crescita e dell'evoluzione del wrestling in America, che hanno portato alla attuale scena del wrestling universitario, considerato uno degli sport più competitivi ed emozionanti del paese.
    </MKTypography>
  </MKBox>
</MKBox>





                                  








<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 2: College Wrestling Today
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    College wrestling is one of the most popular and prestigious sports in America today. It attracts millions of fans and spectators who follow the exciting matches through television, the internet, or live in the arenas. College wrestling also offers numerous opportunities for student-athletes who can benefit from scholarships, academic support, and professional guidance.
    <br />
    <br />
    In this chapter, we will tell you more about the main competitions and conferences in college wrestling and the top programs and prestigious teams that dominate the sport.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Important Competitions and Conferences
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Two college wrestlers in an intense duel on the mat."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Two wrestlers in action
        </figcaption>
      </figure>
      College wrestling is organized by the NCAA, which has three divisions: Division I, Division II, and Division III. Each division has its own rules, criteria, and championships. The most competitive and well-known division is Division I, which consists of approximately 80 teams from different states.
      <br />
      <br />
      The teams in Division I are divided into various conferences, forming regional or thematic groups. The conferences organize their own competitions and championships, which serve as qualifications for the national championship. The most prominent conferences in college wrestling include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Big Ten Conference: The largest and strongest conference in college wrestling, consisting of 14 teams from the Midwest and Northeast regions of the country. The Big Ten has produced the most national champions, including Penn State, Iowa, Ohio State, Michigan, and Minnesota.
        </li>
        <li>
          Big 12 Conference: The second-largest and strongest conference in college wrestling, consisting of 12 teams from the South and West regions of the country. The Big 12 has also produced many national champions, including Oklahoma State, Oklahoma, Iowa State, and Missouri.
        </li>
        <li>
          Atlantic Coast Conference (ACC): An emerging conference in college wrestling, consisting of six teams from the Southeast region of the country. The ACC has gained more attention recently due to the performances of teams like Virginia Tech, North Carolina State, and North Carolina.
        </li>
        <li>
          Eastern Intercollegiate Wrestling Association (EIWA): A historic conference in college wrestling, consisting of 16 teams from the Eastern region of the country. The EIWA is the oldest wrestling conference in America, founded in 1905. The EIWA has also produced some national champions, including Cornell, Lehigh, Princeton, and Navy.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      These are just a few of the many conferences in college wrestling, each with its own rivalries, traditions, and fans. However, conferences are not the only factor that determines how successful a team is. There are other aspects that play a role in the success of a wrestling program.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Top Programs and Prestigious Teams
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A wrestling program is a term used to refer to the organization and structure of a university wrestling team. A wrestling program includes aspects such as the coach, staff, facilities, recruitment, training, funding, and the team's culture.
      <br />
      <br />
      A good wrestling program can make a significant difference in the development of student-athletes. A good wrestling program can provide student-athletes with:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          An experienced and qualified coach who can teach them how to improve their technique, tactics, conditioning, and mindset.
        </li>
        <li>
          A dedicated and professional staff that can support them in areas such as nutrition, injury prevention, recovery, academic guidance, and career planning.
        </li>
        <li>
          Modern and well-equipped facilities where they can train at a high level with suitable equipment and materials.
        </li>
        <li>
          An effective and ethical recruitment strategy that can help them attract and retain talented and motivated wrestlers.
        </li>
        <li>
          An intensive and varied training schedule that can challenge them and prepare them for different matches and situations.
        </li>
        <li>
          Adequate and stable funding that can help them cover the costs of wrestling, such as travel, equipment, scholarships, and other expenses.
        </li>
        <li>
          A positive and inspiring culture that can motivate and encourage them to reach their potential, pursue their goals, and share their passion for the sport.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      There are many universities in America that have excellent wrestling programs that have been successful in college wrestling for years. Some of these programs include:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Penn State: The current top team in college wrestling, which has won the national championship eight times since 2011. Penn State is known for its aggressive and spectacular wrestling style, led by coach Cael Sanderson, one of the greatest wrestlers of all time.
        </li>
        <li>
          Iowa: The most successful team in the history of college wrestling, having won the national championship over 20 times since 1975. Iowa is known for its aggressive and dominant wrestling style, led by coach Tom Brands, a former Olympic champion.
        </li>
        <li>
          Oklahoma State: The second most successful team in the history of college wrestling, having won the national championship over 20 times. Oklahoma State is known for its technical and tactical wrestling style, led by coach John Smith, one of the greatest wrestlers of all time.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      These are just a few of the many top programs and prestigious teams in college wrestling, each with its own characteristics, achievements, and fans. However, it is not easy to be part of these programs and teams. It takes a lot of talent, hard work, and dedication to secure a wrestling scholarship.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  








<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 3: Dal College al Livello Olimpico e alle Leghe Professionistiche
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    La lotta universitaria non è il punto di arrivo per molti studenti-atleti che sognano una carriera nello sport. È piuttosto un trampolino di lancio per livelli più alti dove possono competere con i migliori lottatori del mondo. In questo capitolo, ti parleremo maggiormente del percorso per raggiungere la vetta nella lotta professionistica o olimpica e dei successi e delle sfide dei lottatori americani sul palcoscenico mondiale.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Il Percorso per la Vetra
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La lotta universitaria è uno degli sport più competitivi e impegnativi in America. Richiede molto talento, duro lavoro e dedizione per ottenere una borsa di studio per la lotta, e ancora di più per brillare nelle competizioni nazionali. Ma anche se sei uno dei migliori lottatori universitari, non è una garanzia di successo al livello professionistico o olimpico.
      <br />
      <br />
      Ci sono diversi fattori che rendono difficile la transizione dalla lotta universitaria alla lotta professionistica o olimpica. Alcuni di questi fattori includono:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Differenze di Stile: La lotta universitaria è praticata secondo le regole dello stile folk, uniche in America. Queste regole si concentrano sul controllare e dominare l'avversario, con punti assegnati per cavalcate, fuggite, ribaltamenti e quasi cadute. La lotta professionistica o olimpica, d'altra parte, è praticata secondo le regole del libero o greco-romano, usate a livello internazionale. Queste regole enfatizzano il segnare punti attraverso lanci, ribaltamenti, esposizioni e schienate. Questo significa che i lottatori universitari devono adattare la loro tecnica, tattica e strategia per competere con i lottatori di altri paesi che hanno più esperienza con questi stili.
        </li>
        <li>
          Differenze di Età: La lotta universitaria è uno sport per giovani atleti, di solito tra i 18 e i 23 anni. La lotta professionistica o olimpica, tuttavia, è uno sport per atleti adulti, di solito tra i 25 e i 35 anni. Questo significa che i lottatori universitari devono competere con lottatori più anziani e più esperti che hanno maggiore forza fisica, maturità mentale e raffinatezza tecnica.
        </li>
        <li>
          Differenze Finanziarie: La lotta universitaria è uno sport supportato dalle università, che forniscono agli studenti-atleti borse di studio, strutture, allenatori e altri benefici. La lotta professionistica o olimpica, tuttavia, riceve poco supporto finanziario dal governo o dagli sponsor. Questo significa che i lottatori universitari devono cercare altre fonti di reddito per coprire la loro formazione, viaggi, attrezzature e spese di vita.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Queste sono solo alcune delle molte sfide che i lottatori universitari devono superare per perseguire il loro sogno di una carriera professionistica o olimpica. Tuttavia, nonostante queste sfide, ci sono anche molti successi e storie ispiratrici di lottatori americani che hanno raggiunto il più alto livello.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Lotta Professionistica e Olimpica
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La lotta professionistica o olimpica è il culmine dello sport, dove i migliori lottatori del mondo competono in competizioni ed eventi prestigiosi. Ci sono due principali forme di lotta professionistica o olimpica: libero e greco-romana.
      <br />
      <br />
      La lotta libera è una forma di lotta in cui sono consentiti tutti i tipi di prese su tutto il corpo, purché non siano pericolosi o inappropriati. La lotta libera è praticata sia dagli uomini che dalle donne, in varie categorie di peso. La lotta libera è uno degli sport più antichi delle Olimpiadi, disputato dal 1904 per gli uomini e dal 2004 per le donne.
      <br />
      <br />
      La lotta greco-romana è una forma di lotta in cui sono consentite solo prese sopra la vita, e l'uso delle gambe per afferrare o lanciare l'avversario è proibito. La lotta greco-romana è praticata solo dagli uomini, in varie categorie di peso. La lotta greco-romana è la forma più antica di lotta nelle Olimpiadi, disputata dal 1896.
      <br />
      <br />
      I lottatori americani hanno ottenuto molti successi sia nella lotta libera che nella lotta greco-romana, alle Olimpiadi così come in altre competizioni internazionali come i Campionati del Mondo, i Giochi Panamericani o la Coppa del Mondo. Alcuni dei lottatori americani più di successo e conosciuti includono:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Jordan Burroughs: Campione mondiale per cinque volte e campione olimpico nella lotta libera, noto per il suo stile esplosivo e dinamico. Ha vinto una medaglia d'oro alle Olimpiadi del 2012 a Londra e quattro titoli mondiali nel 2011, 2013, 2015 e 2017. Ha anche vinto due titoli NCAA per l'Università del Nebraska.
        </li>
        <li>
          Kyle Snyder: Campione mondiale per tre volte e campione olimpico nella lotta libera, noto per la sua forza e tecnica. Ha vinto una medaglia d'oro alle Olimpiadi del 2016 a Rio de Janeiro e tre titoli mondiali nel 2015, 2017 e 2019. Ha anche vinto tre titoli NCAA per l'Università dell'Ohio.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Questi sono solo alcuni dei molti successi e storie ispiratrici dei lottatori americani al livello professionistico o olimpico. Dimostrano che è possibile realizzare il proprio sogno se si è disposti a lavorare sodo, imparare e crescere.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  







<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 4: Influenze e Competizioni Internazionali
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    La lotta è uno sport universale, praticato e apprezzato in molti paesi e culture. La lotta ha anche molti stili e tradizioni che hanno avuto origine e sviluppato in diverse regioni e periodi. In questo capitolo, ti parleremo maggiormente delle influenze e delle competizioni internazionali nella lotta e di come influenzano e sfidano i lottatori americani.
  </MKTypography>
  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Stili e Tradizioni di Lotta Stranieri
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Vista aerea di un match di lotta NCAA in uno stadio affollato."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Un match nel torneo NCAA
        </figcaption>
      </figure>
      La lotta è uno sport praticato fin dall'antichità in varie parti del mondo. È una delle forme più antiche di espressione fisica, spesso associata a significati religiosi, culturali o sociali. Ci sono molti tipi di lotta, ognuno con le proprie caratteristiche, regole e tecniche. Alcuni esempi includono:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Sumo: Uno stile tradizionale di lotta giapponese considerato uno sport nazionale e un rituale sacro. Il sumo è praticato da lottatori pesanti e forti che cercano di spingere l'altro fuori da un anello circolare o di gettarlo a terra.
        </li>
        <li>
          Lotta mongola: Uno stile tradizionale di lotta mongola considerato simbolo di coraggio e forza. La lotta mongola è praticata da lottatori muscolosi e agili che cercano di portare l'altro a terra attraverso prese sulla parte superiore del corpo o delle gambe.
        </li>
        <li>
          Lotta turca con l'olio: Uno stile tradizionale di lotta turca considerato patrimonio e arte. La lotta turca con l'olio è praticata da lottatori oleati e muscolosi che cercano di sconfiggere l'altro attraverso prese sui pantaloni o la cintura.
        </li>
        <li>
          Lotta senegalese: Uno stile tradizionale di lotta senegalese considerato uno sport popolare e redditizio. La lotta senegalese è praticata da lottatori atletici e colorati che cercano di mettere a terra l'altro attraverso lanci, pugni o calci.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Questi sono solo alcuni dei molti stili e tradizioni di lotta stranieri, ognuno con la propria storia, cultura e fan. Questi stili hanno anche influenzato la lotta universitaria americana introducendo nuove tecniche, tattiche o sfide. Molti lottatori universitari americani hanno viaggiato o si sono allenati in altri paesi per ampliare i loro orizzonti e migliorare le loro abilità.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Americani sul Palcoscenico Mondiale
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La lotta universitaria americana non è concentrata solo sulle competizioni nazionali, ma anche sulle competizioni internazionali. Ci sono molti eventi internazionali in cui i lottatori universitari americani possono partecipare per competere con i migliori lottatori del mondo. Alcuni di questi eventi includono:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          I Giochi Olimpici: Il più grande e prestigioso evento nella lotta professionistica o olimpica, tenuto ogni quattro anni. I Giochi Olimpici sono l'obiettivo finale per molti lottatori universitari che sognano di vincere una medaglia d'oro per il loro paese.
        </li>
        <li>
          I Campionati del Mondo: Il secondo più grande e prestigioso evento nella lotta professionistica o olimpica, tenuto annualmente. I Campionati del Mondo sono una significativa opportunità per i lottatori universitari di qualificarsi per le Olimpiadi o stabilire la propria reputazione sul palcoscenico mondiale.
        </li>
        <li>
          La Coppa del Mondo: Il secondo più grande e prestigioso evento nella lotta regionale, tenuto annualmente. La Coppa del Mondo è una significativa opportunità per i lottatori universitari di far parte di una squadra nazionale che compete contro altre squadre nazionali in un formato a squadre.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Questi sono solo alcuni dei molti eventi internazionali in cui i lottatori universitari americani possono partecipare per mostrare i loro talenti e perseguire i loro sogni. Tuttavia, questi eventi non sono solo fonte di onore e gloria, ma anche di sfida e difficoltà. I lottatori universitari devono competere con lottatori di altri paesi che spesso hanno più esperienza, stile o motivazione.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  






<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 5: Borse di Studio Sportive e il Futuro degli Atleti
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le borse di studio sportive offrono numerose opportunità ai giovani atleti di mostrare il loro talento e sviluppare le loro abilità. Una delle opportunità più significative è ottenere una borsa di studio sportiva per studiare e competere a livello universitario. In questo capitolo, discuteremo dell'importanza delle borse di studio sportive e di come Sport Scholarships America può aiutarti a realizzare il tuo sogno.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 L'Importanza delle Borse di Studio
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Una borsa di studio sportiva è un sostegno finanziario fornito da un'università a uno studente-atleta per combinare studio e sport. Può coprire i costi di iscrizione, libri, alloggio, pasti e altre spese. Inoltre, può offrire vantaggi come il tutorato accademico, l'assistenza medica, le strutture di allenamento e l'attrezzatura.
      <br />
      <br />
      Ottenere una borsa di studio sportiva è competitivo, con molti fattori che influenzano il processo di assegnazione, tra cui risultati accademici, prestazioni sportive, personalità, motivazione, leadership e adattabilità. È quindi importante prepararsi bene e distinguersi dalla concorrenza.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Come Sport Scholarships America Può Aiutare
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo di Sport Scholarships America, raffigurante attrezzature sportive e una toga, simbolo del focus sulle borse di studio sportive per gli studenti-atleti."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo di Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America è un'organizzazione specializzata nell'orientamento dei giovani talenti sportivi verso borse di studio sportive. Con esperti appassionati ed esperti, Sport Scholarships America supporta gli studenti-atleti in ogni fase del percorso, dall'orientamento iniziale alla collocazione finale. I loro servizi includono:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Un'intervista personale per discutere i desideri, le possibilità e le aspettative dello studente-atleta.</li>
        <li>Una valutazione professionale del livello accademico e sportivo dello studente-atleta.</li>
        <li>Una vasta rete di contatti con allenatori, università e college.</li>
        <li>Promozione efficace del profilo e dei video dello studente-atleta presso allenatori potenziali.</li>
        <li>Consulenza esperta sulle migliori opzioni in base alle preferenze, agli obiettivi e al budget dello studente-atleta.</li>
        <li>Negoziazione delle migliori offerte di borse di studio per conto dello studente-atleta.</li>
        <li>Assistenza nella richiesta dei documenti necessari, come visto, trascrizione, diploma e test di ammissione.</li>
        <li>Preparazione alla vita dello studente-atleta durante la borsa di studio, compresa cultura, lingua, regole e consigli.</li>
        <li>Assistenza e monitoraggio durante il periodo di borsa di studio.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vuoi esplorare le opportunità di borse di studio e raggiungere i tuoi obiettivi sportivi e accademici? <Link to="/it/Servizi-Prodotti">Consulta la gamma completa</Link> dei servizi offerti da Sport Scholarships America e prenota una consultazione oggi stesso.
    </MKTypography>
  </MKBox>
</MKBox>
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;