/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import React, { useState } from 'react'; // Step 1: Import useState
import { Link } from 'react-router-dom';


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Football1.jpg";
import Image2 from "assets/images/Sports_Images/Football2.webp";
import Image3 from "assets/images/Sports_Images/Football3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";
import "../../../../H1asH3.css"


// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'American Football Scholarships in the USA: How to Secure a Spot on a University Team', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Chapter 1: The History and Development of American Football', isSubheader: false },
    { id: '1.1', title: '1.1 The Early Years', isSubheader: true },
    { id: '1.2', title: '1.2 Growth and Professionalization', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Chapter 2: College Football', isSubheader: false },
    { id: '2.1', title: '2.1 Major Leagues and Conferences', isSubheader: true },
    { id: '2.2', title: '2.2 Player Development', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Chapter 3: From College to the NFL', isSubheader: false },
    { id: '3.1', title: '3.1 The Path to the NFL', isSubheader: true },
    { id: '3.2', title: '3.2 Other Professional Opportunities', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Chapter 4: The Influence of American Football on American Culture', isSubheader: false },
    { id: '4.1', title: '4.1 Cultural Impact', isSubheader: true },
    { id: '4.2', title: '4.2 Role Models and Inspiration', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Chapter 5: Sports Scholarships and the Future of American Football', isSubheader: false },
    { id: '5.1', title: '5.1 The Importance of Scholarships', isSubheader: true },
    { id: '5.2', title: '5.2 How Sport Scholarships America Can Help', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Content
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Hide</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Show</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>


























            <MKBox id="intro" mb={3}>
            <MKTypography variant="h1" className="h1-as-h3" mb={2}>
  American Football Scholarships in the USA: How to Secure a Spot on a University Team
</MKTypography>
<MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
  <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
    <img
      src={Image1}
      alt="Aerial view of a crowded college football game at Indiana University, with players in action on the field and fans in the stands."
      style={{
        maxWidth: "100%",
        border: "2px solid #fff",
        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
        cursor: "pointer",
        outline: "none",
      }}
      onClick={() => openModal1(Image1)}
    />
    <figcaption style={{ 
      textAlign: 'left', 
      fontWeight: 'bold', 
      fontSize: '12px', 
      wordWrap: 'break-word', 
     }}>
      Indiana University football game from the stands.
    </figcaption>
  </figure>
  American Football is a sport that originated in the United States in the late 19th century. It is a team sport in which two teams of eleven players try to advance an oval ball across the opponent's goal line or kick it between the goalposts. The game is characterized by physical contact, tactics, strategy, and spectacle.
  <br/>
  <br/>
  American Football is not just a sport; it's also a culture. It's an essential part of American identity, values, and traditions. It's a source of pride, passion, and inspiration for millions of people. It's also an industry that generates billions of dollars and creates thousands of jobs.
  <br/>
  <br/>
  But before you can make it as a professional player in the NFL, you need to showcase your talent at the collegiate level. College football is a crucial part of player development, offering young athletes the opportunity to improve their skills, receive an education, and build a network.
  <br/>
  <br/>
  However, college football is not easily accessible to everyone. There are thousands of teams and players competing for a limited number of spots and scholarships. Moreover, there are many rules and requirements you must meet to qualify for a university team.
  <br/>
  <br/>
  That's where Sportbeursamerika.nl can assist you. I specialize in matching young talents with university teams looking for reinforcements. I can help you find a sports scholarship, arrange your visa, prepare for your admission exams, and negotiate with coaches. With my assistance, you can fulfill your dream of studying and playing in America.
  <br/>
  <br/>
  Want to know how? Keep reading!
</MKTypography>
</MKBox>





{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1: The History and Development of American Football" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 1: The History and Development of American Football
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    American Football is a sport that originated in the United States in the late 19th century. It is a descendant of rugby and soccer, two sports that were popular in Europe. In this chapter, we will tell you more about the early years and the growth and professionalization of American Football.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 The Early Years
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      The origin of American Football is often attributed to a match between two university teams in 1869: Rutgers and Princeton. However, this match was more like a form of soccer than the modern American Football. There were 25 players per team, the ball could not be touched with the hands, and there were no goalposts.
      <br/>
      <br/>
      The first game that resembled modern American Football took place in 1874 between Harvard and McGill. This game was a combination of rugby and soccer, allowing the ball to be carried with hands and featuring goalposts. Harvard won the game 3-0.
      <br/>
      <br/>
      The role of universities in forming the first teams and leagues was crucial. They were the pioneers who defined the rules and style of the game. In 1873, the first intercollegiate football association was established, consisting of Yale, Columbia, Princeton, and Harvard. In 1880, Walter Camp, a player and coach from Yale, was considered the "Father of American Football" because he introduced significant innovations, such as reducing the number of players to 11, introducing the scrimmage line, and the downs system.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Growth and Professionalization
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      The evolution of American Football into a national phenomenon began in the late 19th and early 20th centuries. The game gained popularity among students, spectators, and the media. It also became increasingly violent and dangerous, leading to numerous injuries and even deaths. In 1905, President Theodore Roosevelt threatened to ban the game if reforms were not implemented. This led to the establishment of the National Collegiate Athletic Association (NCAA) in 1906, an organization responsible for regulating and organizing college sports.
      <br/>
      <br/>
      The influence of professional American football on the college game was significant as well. The first professional American football league was founded in 1892 but became truly successful in 1920 with the formation of the American Professional Football Association (APFA), later renamed the National Football League (NFL). The NFL attracted many talented players away from colleges, forcing them to raise their level to remain competitive. The NFL also became a major source of income and exposure for universities, often renting their stadiums to professional teams.
      <br/>
      <br/>
      In this chapter, we have told you more about the history and development of American Football. In the next chapter, we will provide further insights into American Football at the university level.
    </MKTypography>
  </MKBox>
</MKBox>








                                              





                                  








<MKBox id="Chapter 2" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Chapter 2: College Football
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        College football is one of the most popular and competitive sports in the United States. Each year, more than a thousand teams and hundreds of thousands of players compete for the national title, regional championships, and prestigious bowl games. In this chapter, we will tell you more about the major leagues and conferences in college football, and the development of players at the college level.
    </MKTypography>

    <MKBox id="2.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.1 Major Leagues and Conferences
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>

            An overview of the NCAA divisions and major conferences in college football. The NCAA is the organization responsible for regulating and organizing college sports in the United States. The NCAA divides teams into three divisions: Division I, Division II, and Division III. Division I is the highest division and consists of two subdivisions: the Football Bowl Subdivision (FBS) and the Football Championship Subdivision (FCS). FBS teams are the most prestigious and lucrative teams, competing for a spot in the College Football Playoff, a system that has determined the national champion since 2014. FCS teams are less well-known and wealthy but have their own playoff system to determine their champion.
            <br/>
            <br/>
            Within each division and subdivision, there are various conferences, which are groups of teams that are geographically or historically connected. Each conference has its own schedule, rules, and championship. The major conferences in FBS are: Atlantic Coast Conference (ACC), Big Ten Conference, Big 12 Conference, Pac-12 Conference, Southeastern Conference (SEC), and American Athletic Conference (AAC). The major conferences in FCS are: Big Sky Conference, Colonial Athletic Association (CAA), Missouri Valley Football Conference (MVFC), and Southern Conference (SoCon).
            <br/>
            <br/>
            The most prestigious college programs and their impact on the sport. Some colleges have a long and successful history in college football, dating back to the early years of the sport. They have produced many national titles, bowl games, individual awards, and professional players. They also have many fans, media attention, and financial resources. Some examples of these colleges are: Alabama, Notre Dame, Ohio State, Oklahoma, USC, Michigan, Texas, Clemson, Florida State, and LSU.
        </MKTypography>
    </MKBox>
    <MKBox id="2.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.2 Player Development
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image2}
                    alt="Young Tom Brady in action as quarterback for the University of Michigan during a college football game, dressed in the team's blue and yellow uniform."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal2(Image2)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Tom Brady in action for the University of Michigan.
                </figcaption>
            </figure>
            How college teams contribute to the technical and physical development of players. College teams offer young players the opportunity to improve their skills under the guidance of experienced coaches and staff members. They also provide access to high-quality facilities, equipment, and medical care. Moreover, they expose players to a high level of competition, where they face other talented players from across the country.
            <br/>
            <br/>
            Examples of famous players who honed their skills at the college level. Many professional players began their careers at the college level, where they stood out for their performance and potential. Some players have even won awards like the Heisman Trophy, the most prestigious individual award in college football. Examples of these players include: Tom Brady (Michigan), Peyton Manning (Tennessee), Joe Montana (Notre Dame), Barry Sanders (Oklahoma State), Jerry Rice (Mississippi Valley State), and Tim Tebow (Florida).
            <br/>
            <br/>
            In this chapter, we have told you more about college football. In the next chapter, we will tell you more about the path to the NFL and other professional opportunities.

        </MKTypography>
    </MKBox>
</MKBox>












                                              






                                  









<MKBox id="Chapter 3" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Chapter 3: From College to the NFL
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        College football is a stepping stone to the National Football League (NFL), the highest professional league in the world. Every year, hundreds of players are selected by NFL teams in the NFL Draft, an event where teams take turns choosing a player from a pool of college players. In this chapter, we will tell you more about the path to the NFL and other professional opportunities.
    </MKTypography>

    <MKBox id="3.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.1 The Path to the NFL
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            How college football functions as a springboard to the National Football League (NFL). College football is the main source of talent for the NFL. Most players in the NFL have first played at the college level. College football offers players the chance to prove themselves against other top athletes, develop their skills, improve their stats, and increase their draft value. College football also gives players exposure and recognition by scouts, coaches, and media.
            <br/>
            <br/>
            About 250 players are chosen each year in the NFL Draft, which usually takes place in April. The draft consists of seven rounds, each with 32 picks (one for each team). The order of the picks is determined by the result of the previous season, with the worst team choosing first and the best team last. The draft is an exciting and unpredictable event, with some players being chosen higher or lower than expected, and some not being chosen at all. Examples of famous players selected in the NFL Draft include: John Elway (first pick in 1983), Troy Aikman (first pick in 1989), Peyton Manning (first pick in 1998), Tom Brady (199th pick in 2000), Aaron Rodgers (24th pick in 2005), and Patrick Mahomes (10th pick in 2017).
        </MKTypography>
    </MKBox>
    <MKBox id="3.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.2 Other Professional Opportunities
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Alternative paths to a professional career in American Football outside the NFL. Not all players who play at the college level make it to the NFL. Some players are not chosen in the draft, or are chosen but fail to secure a place in a team. Some players get injured or lose their motivation or passion. But this does not mean they have to give up their dream of playing professionally. There are other professional opportunities outside the NFL, such as:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    The Canadian Football League (CFL): This is a professional league consisting of nine teams that play in Canada. The CFL has some differences from the NFL, such as a larger field, more players per team, and fewer downs per drive. The CFL attracts many players who cannot play in the NFL, but still want to play at a high level.
                </li>
                <li>
                    The XFL: This is a new professional league established in 2020 as an alternative to the NFL. The XFL consists of eight teams that play in the United States. The XFL aims to provide a faster, more exciting, and more innovative game than the NFL. The XFL also offers more opportunities for players who have been overlooked or ignored by the NFL.
                </li>
                <li>
                    The European League of Football (ELF): This is a new professional league established in 2021 as an attempt to promote and develop American Football in Europe. The ELF consists of eight teams that play in Germany, Poland, Spain, and France. The ELF aims to provide a platform for European players to showcase their talent and grow as athletes.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            In this chapter, we have told you more about the path to the NFL and other professional opportunities. In the next chapter, we will tell you more about the influence of American Football on American culture.
        </MKTypography>
    </MKBox>
</MKBox>



                                              






                                  






<MKBox id="Chapter 4" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Chapter 4: The Influence of American Football on American Culture
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        American Football is not just a sport, but also a culture. It is a significant part of American identity, values, and traditions. It is a source of pride, passion, and inspiration for millions of people. It is also an industry that generates billions of dollars and creates thousands of jobs. In this chapter, we will tell you more about the cultural impact of American Football, and the role models and inspiration it provides.
    </MKTypography>

    <MKBox id="4.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.1 Cultural Impact
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image3}
                    alt="View from the stands at the vibrant and packed Wisconsin college football stadium during an exciting game, with fans cheering on their team."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal3(Image3)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Game day in the packed Wisconsin stadium
                </figcaption>
            </figure>
            The importance of American Football in American society and culture. American Football is one of the most popular and influential sports in the United States. It attracts millions of fans, spectators, and media every year. It is part of the daily life, education, politics, economy, and entertainment of many Americans. It is also a symbol of national unity, diversity, and pride.
            <br/>
            <br/>
            Major events and their impact on national identity, such as the Super Bowl. The Super Bowl is the annual championship of the NFL, usually held in February. It is the biggest and most watched sporting event in the world, with over 100 million viewers in the United States alone. The Super Bowl is more than a sporting event; it is a cultural phenomenon. It is an opportunity for family, friends, and colleagues to gather, party, bet, and cheer. It is also a stage for music, advertising, art, and politics. The Super Bowl is often seen as a reflection of American culture, values, and trends.
        </MKTypography>
    </MKBox>
    <MKBox id="4.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.2 Role Models and Inspiration
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            American Football players are often role models and sources of inspiration for young athletes pursuing their dream of playing at the college or professional level. Some players have extraordinary stories of adversity, perseverance, and success that motivate and inspire others. Some players also positively impact their community, fans, and country through their actions, words, and deeds. Examples of such players include: Colin Kaepernick (who knelt during the national anthem to raise awareness of racial injustice), Russell Wilson (who led his team to the Super Bowl as an underdog), JJ Watt (who raised millions of dollars for the victims of Hurricane Harvey), Drew Brees (who helped his city New Orleans recover after Hurricane Katrina), and Tom Brady (who still plays at a top level at the age of 43).
            <br/>
            <br/>
            In this chapter, we have told you more about the influence of American Football on American culture. In the next chapter, we will tell you more about sports scholarships and the future of American Football.
        </MKTypography>
    </MKBox>
</MKBox>












                                              






                                  



<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapter 5: Sports Scholarships and the Future of Athletes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sports scholarships offer numerous opportunities for young athletes to showcase their talent and develop their skills. One of the most significant opportunities is obtaining a sports scholarship to study and compete at the college level. In this chapter, we will discuss the importance of sports scholarships and how Sport Scholarships America can help you make your dream come true.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 The Importance of Scholarships
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A sports scholarship is financial support provided by a university to a student-athlete to combine studies and sports. It can cover the costs of tuition, books, housing, meals, and other expenses. Additionally, it may offer benefits such as academic tutoring, medical care, training facilities, and equipment.
      <br />
      <br />
      Obtaining a sports scholarship is competitive, with many factors playing a role in the awarding process, including academic achievements, athletic performance, personality, motivation, leadership, and adaptability. Therefore, it's essential to prepare well and distinguish yourself from the competition.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 How Sport Scholarships America Can Help
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo of Sport Scholarships America, featuring sports equipment and a graduation cap, representing the focus on sports scholarships for student-athletes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo of Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America is an organization specialized in guiding young sports talents to sports scholarships. With passionate and experienced experts, Sport Scholarships America supports student-athletes at every step of the journey, from initial orientation to final placement. Their services include:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>A personal intake interview to discuss the student-athlete's wishes, possibilities, and expectations.</li>
        <li>A professional evaluation of the student-athlete's athletic and academic level.</li>
        <li>An extensive network of contacts with coaches, universities, and colleges.</li>
        <li>Effective promotion of the student-athlete's profile and videos to potential coaches.</li>
        <li>Expert advice on the best options based on the student-athlete's preferences, goals, and budget.</li>
        <li>Negotiation of the best scholarship offers on behalf of the student-athlete.</li>
        <li>Assistance in applying for necessary documents, such as visa, transcript, diploma, and admission tests.</li>
        <li>Preparation for student-athletes' life during their scholarship, including culture, language, rules, and tips.</li>
        <li>Aftercare and follow-up during the scholarship period.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Want to explore scholarship opportunities and achieve your athletic and academic goals? <Link to="/Services-Products">View the full range</Link> of services offered by Sport Scholarships America and schedule a consultation today.
    </MKTypography>
  </MKBox>
</MKBox>



          
        









         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;