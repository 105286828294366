/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Golf1.jpeg";
import Image2 from "assets/images/Sports_Images/Golf2.webp";
import Image3 from "assets/images/Sports_Images/Golf3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"


// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Bolsa de Estudos de Golfe: Como Estudar e Jogar Golfe na América', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capítulo 1: História e Desenvolvimento do Golfe Universitário', isSubheader: false },
    { id: '1.1', title: '1.1 Primeiros Anos', isSubheader: true },
    { id: '1.2', title: '1.2 Crescimento e Profissionalização', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capítulo 2: O Panorama Atual do Golfe Universitário', isSubheader: false },
    { id: '2.1', title: '2.1 Principais Competições e Conferências', isSubheader: true },
    { id: '2.2', title: '2.2 Programas de Elite e Equipes Prestigiadas', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capítulo 3: Da Faculdade para o Profissionalismo', isSubheader: false },
    { id: '3.1', title: '3.1 O Caminho para o Circuito Profissional', isSubheader: true },
    { id: '3.2', title: '3.2 Rotas Alternativas para o Profissionalismo', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capítulo 4: Influências e Oportunidades Internacionais', isSubheader: false },
    { id: '4.1', title: '4.1 Jogadores Estrangeiros no Golfe Universitário', isSubheader: true },
    { id: '4.2', title: '4.2 Jogadores Americanos no Exterior', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 A Importância das Bolsas de Estudo', isSubheader: true },
    { id: '5.2', title: '5.2 Como a Sport Scholarships America Pode Ajudar', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };





  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Conteúdo
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Esconder</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Bolsa de Estudos de Golfe: Como Estudar e Jogar Golfe na América
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Campo de golfe durante o campeonato da NCAA, com jogadores e espectadores ao redor do campo"
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Campeonato de Golfe da NCAA
      </figcaption>
    </figure>
    O golfe é um dos esportes mais populares no nível universitário nos Estados Unidos. Todos os anos, milhares de estudantes-atletas competem por títulos nacionais, prêmios prestigiados e um lugar no golfe profissional. O golfe universitário não oferece apenas a chance de aprimorar suas habilidades atléticas, mas também de obter um diploma acadêmico, construir uma rede de contatos e ter uma experiência inesquecível.
    <br/>
    <br/>
    No entanto, o golfe universitário não é para todos. Requer um alto nível de comprometimento, disciplina e talento. Além disso, não é fácil ser admitido em uma universidade com um bom programa de golfe. A competição é acirrada e os custos são altos.
    <br/>
    <br/>
    Felizmente, existem oportunidades para realizar seu sonho com a ajuda de uma bolsa de estudos de golfe. Uma bolsa de estudos de golfe é o apoio financeiro que você recebe de uma universidade para estudar e jogar golfe lá. Com uma bolsa de estudos de golfe, você pode reduzir ou até mesmo cobrir totalmente os custos de seus estudos enquanto desfruta de todos os benefícios do golfe universitário.
    <br/>
    <br/>
    Mas como conseguir uma bolsa de estudos de golfe? E o que você deve considerar ao escolher uma universidade? É aí que o Sportbeursamerika.nl pode ajudá-lo. O Sportbeursamerika.nl é o especialista em orientar estudantes para bolsas de estudos esportivas na América. Tenho experiência e conhecimento do sistema educacional e esportivo americano, e já ajudei muitos estudantes-atletas a realizarem seu sonho.
    <br/>
    <br/>
    Neste artigo, fornecemos mais informações sobre o golfe universitário e mostramos como o Sportbeursamerika.nl pode apoiá-lo na busca pela bolsa de estudos de golfe perfeita para você.
    <br/>
    <br/>
    Quer saber mais? Continue lendo!
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Hoofdstuk 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 1: História e Desenvolvimento do Golfe Universitário
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O golfe universitário tem uma história longa e rica que remonta ao início do século 20. Neste capítulo, vamos olhar para os primeiros anos e o crescimento e profissionalização do golfe universitário nos Estados Unidos.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Primeiros Anos
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      As primeiras competições de golfe em universidades e faculdades nos EUA ocorreram na década de 1890. A primeira partida oficial de golfe intercolegial foi entre Harvard e Yale em 1897. Em 1901, foi fundada a Associação de Golfe Intercolegial (IGA), que organizou o primeiro campeonato nacional em 1902. Yale venceu o primeiro título e continuou a dominar até a década de 1920.
      <br/>
      <br/>
      Os primeiros programas de golfe em universidades tinham como foco principal os aspectos sociais e recreativos do esporte. Os estudantes jogavam principalmente em campos locais e tinham pouca orientação ou instalações. As equipes de golfe geralmente consistiam em quatro ou cinco jogadores que se classificavam através de torneios internos.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crescimento e Profissionalização
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Na década de 1930, o golfe universitário começou a crescer e se profissionalizar significativamente. Este período viu um aumento no número de programas de golfe universitário e uma melhoria na qualidade de treinamento e instalações. A profissionalização foi ainda mais estimulada pela crescente atenção ao esporte e pelo reconhecimento crescente do potencial do golfe no nível universitário. Durante esse tempo, a Associação Atlética Universitária Nacional (NCAA) desempenhou um papel de liderança na organização e regulamentação do golfe universitário, contribuindo para o desenvolvimento e popularidade ainda maiores do esporte nos Estados Unidos.
      <br/>
      <br/>
      A NCAA assumiu a organização do campeonato nacional da IGA e introduziu novas regras e formatos. A NCAA dividiu as universidades em diferentes divisões, com base em seu tamanho e nível atlético. A NCAA também organizou campeonatos regionais para facilitar a qualificação para o campeonato nacional.
      <br/>
      <br/>
      As universidades também começaram a investir mais em seus programas de golfe, contratando treinadores profissionais, construindo ou melhorando campos de golfe e oferecendo bolsas de estudos para jogadores talentosos. As equipes de golfe se tornaram maiores e mais fortes, e a competição se tornou mais intensa.
      <br/>
      <br/>
      Nas décadas de 1940 e 1950, foram adicionados mais torneios importantes, como a Walker Cup, a Palmer Cup e o Troféu Eisenhower. Esses torneios deram aos golfistas universitários a chance de competir com jogadores internacionais e melhorar suas habilidades.
      <br/>
      <br/>
      Neste capítulo, vimos como o golfe universitário se originou e cresceu nos Estados Unidos. No próximo capítulo, vamos examinar a situação atual do golfe universitário e quais competições e programas estão disponíveis.
    </MKTypography>
  </MKBox>
</MKBox>













                                              





                                  






<MKBox id="Chapter 2" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Capítulo 2: O Panorama Atual do Golfe Universitário
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Hoje, o golfe universitário é um dos esportes mais competitivos e prestigiados no nível universitário nos Estados Unidos. Neste capítulo, vamos dar uma olhada nas principais competições e conferências, bem como nos programas de elite e equipes prestigiadas no golfe universitário.
    </MKTypography>

    <MKBox id="2.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.1 Principais Competições e Conferências
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image2}
                    alt="Tiger Woods representando Stanford no golfe universitário, mostrado em concentração de jogo."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal2(Image2)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Tiger Woods em ação por Stanford.
                </figcaption>
            </figure>
            A NCAA é a maior e mais importante organização para o golfe universitário nos EUA. A NCAA divide as universidades em três divisões: Divisão I, Divisão II e Divisão III. Cada divisão tem seu próprio campeonato, realizado anualmente em maio ou junho. Os campeonatos consistem em um torneio de equipe e um individual, onde os melhores jogadores e equipes de cada divisão competem pelo título nacional.
            <br/>
            <br/>
            A NCAA também tem várias conferências regionais, constituídas por universidades que estão geográfica ou historicamente conectadas. Cada conferência tem seu próprio campeonato, servindo como um torneio de qualificação para o campeonato nacional. Algumas conferências são mais fortes que outras e têm mais influência no panorama do golfe universitário.
            <br/>
            <br/>
            Algumas das conferências mais conhecidas incluem:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    A Conferência da Costa Atlântica (ACC), composta por 15 universidades na Costa Leste, como Duke, Carolina do Norte e Clemson.
                </li>
                <li>
                    A Conferência do Big Ten, composta por 14 universidades no Meio-Oeste, como Michigan, Ohio State e Illinois.
                </li>
                <li>
                    A Conferência do Pac-12, composta por 12 universidades na Costa Oeste, como Stanford, Califórnia e Arizona State.
                </li>
                <li>
                    A Conferência do Sudeste (SEC), composta por 14 universidades no Sudeste, como Alabama, Geórgia e Flórida.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Além da NCAA, também existem outras organizações que organizam competições de golfe universitário, como a Associação Nacional de Atletismo Intercolegial (NAIA), a Associação Atlética Universitária Júnior Nacional (NJCAA) e a Associação Atlética Universitária Cristã Nacional (NCCAA). Essas organizações têm suas próprias divisões, conferências e campeonatos, que geralmente são menores e menos prestigiados do que os da NCAA.
        </MKTypography>
    </MKBox>
    <MKBox id="2.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.2 Programas de Elite e Equipes Prestigiadas
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            O golfe universitário não é apenas um esporte, mas também uma educação. As universidades que oferecem golfe universitário têm diversos programas, variando em qualidade, reputação e instalações. Alguns programas são líderes e prestigiados, atraindo os melhores jogadores e treinadores. Outros programas são menos conhecidos ou bem-sucedidos, mas ainda oferecem boas oportunidades para os estudantes-atletas.
            <br/>
            <br/>
            Alguns dos fatores que determinam um programa incluem:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    A história e tradição do programa.
                </li>
                <li>
                    O nível e resultados da equipe.
                </li>
                <li>
                    A qualidade e experiência do treinador.
                </li>
                <li>
                    As instalações e equipamentos do programa.
                </li>
                <li>
                    Os padrões acadêmicos e requisitos do programa.
                </li>
                <li>
                    A atmosfera e cultura do programa.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Alguns dos programas de golfe universitário mais proeminentes incluem:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Universidade Stanford: Stanford tem um dos programas de golfe mais antigos e bem-sucedidos nos EUA, com múltiplos títulos nacionais no golfe masculino. O programa produziu ex-alunos famosos como Tiger Woods e Tom Watson. A equipe de golfe feminino de Stanford também ganhou reconhecimento nacional com vários títulos da NCAA e campeãs individuais.
                </li>
                <li>
                    Universidade Estadual de Oklahoma: Oklahoma State tem um programa de golfe dominante, especialmente conhecido por sua equipe masculina, com múltiplos títulos nacionais. O programa produziu golfistas de ponta como Rickie Fowler e Viktor Hovland.
                </li>
                <li>
                    Universidade Duke: Duke é conhecida por seu forte programa de golfe feminino, com vários títulos da NCAA. A equipe de golfe masculino de Duke tem se saído bem nos campeonatos da conferência e tem uma história respeitável nas competições da NCAA. Duke produziu golfistas talentosos, incluindo Amanda Blumenherst e Brittany Lang.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Neste capítulo, vimos como é o panorama atual do golfe universitário e quais competições e programas estão disponíveis. No próximo capítulo, vamos examinar como ir da faculdade para o profissionalismo e quais rotas alternativas estão disponíveis.
        </MKTypography>
    </MKBox>
</MKBox>












                                              






                                  









<MKBox id="Chapter 3" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Capítulo 3: Da Faculdade para o Profissionalismo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        O golfe universitário não é apenas um esporte, mas também uma trajetória de carreira. Muitos golfistas universitários aspiram a fazer a transição para o circuito profissional e jogar nos mais altos níveis do golfe. Neste capítulo, exploraremos como o golfe universitário pode servir como uma ponte para o golfe profissional e as rotas alternativas disponíveis.
    </MKTypography>

    <MKBox id="3.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.1 O Caminho para o Circuito Profissional
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            O golfe universitário é uma excelente preparação para uma carreira profissional no golfe. Os golfistas universitários têm a oportunidade de se desenvolver atlética, acadêmica e pessoalmente. Eles aprendem a lidar com pressão, competição e expectativas. Eles jogam em campos desafiadores contra oponentes fortes e em diversas condições. Eles também têm acesso a instalações de alta qualidade, treinadores e orientação.
            <br/>
            <br/>
            Os golfistas universitários também têm uma vantagem sobre outros jogadores que não frequentam a universidade. Eles têm um diploma que pode ajudá-los a encontrar um emprego ou um patrocinador se não tiverem sucesso como profissionais. Eles também têm uma rede de contatos e amigos que podem apoiá-los ou aconselhá-los em suas carreiras.
            <br/>
            <br/>
            Muitos golfistas universitários fazem a transição para o circuito profissional após completarem seus estudos. Alguns o fazem mais cedo se acreditarem que estão prontos. Para se tornarem profissionais, eles precisam se qualificar para um circuito, como o PGA Tour, o LPGA Tour ou o European Tour, por meio de torneios de classificação como o Q-School ou o Korn Ferry Tour.
            <br/>
            <br/>
            Muitos ex-golfistas universitários tiveram sucesso no circuito profissional. Exemplos incluem:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Phil Mickelson: Mickelson jogou golfe universitário pela Universidade Estadual do Arizona, onde ganhou o campeonato da NCAA três vezes. Ele se tornou profissional em 1992 e desde então conquistou inúmeras vitórias no PGA Tour, incluindo vários majors.
                </li>
                <li>
                    Annika Sörenstam: Sörenstam jogou golfe universitário pela Universidade do Arizona, onde ganhou o campeonato da NCAA em 1991. Ela se tornou profissional em 1992 e desde então conquistou inúmeras vitórias profissionais, incluindo vários majors.
                </li>
                <li>
                    Jon Rahm: Rahm jogou golfe universitário pela Universidade Estadual do Arizona, onde ganhou o prêmio Ben Hogan duas vezes como o melhor golfista universitário. Ele se tornou profissional em 2016 e desde então conquistou várias vitórias profissionais, incluindo majors.
                </li>
            </ul>
        </MKTypography>
    </MKBox>
    <MKBox id="3.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.2 Rotas Alternativas para o Profissionalismo
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Embora o golfe universitário seja uma rota comum para o circuito profissional, não é a única. Existem outros caminhos que os jogadores podem seguir para alcançar seu sonho. Alguns jogadores optam por não ir para a universidade, mas se tornar profissionais diretamente após o ensino médio. Outros frequentam a universidade, mas saem cedo para se concentrar inteiramente em seu esporte.
            <br/>
            <br/>
            Exemplos de jogadores que seguiram uma rota alternativa incluem:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Tiger Woods: Woods jogou golfe universitário pela Universidade Stanford, onde ganhou o campeonato da NCAA duas vezes. No entanto, ele se tornou profissional em 1996 após dois anos de estudo. Desde então, ele conquistou inúmeras vitórias no PGA Tour, incluindo vários majors.
                </li>
                <li>
                    Lydia Ko: Ko nunca jogou golfe universitário, mas se tornou profissional em 2013 aos 16 anos. Desde então, ela conquistou múltiplas vitórias no LPGA Tour.
                </li>
                <li>
                    Jordan Spieth: Spieth jogou golfe universitário pela Universidade do Texas, onde ganhou o campeonato da NCAA em 2012. No entanto, ele se tornou profissional em 2012 após um ano de estudo. Desde então, ele conquistou múltiplas vitórias no PGA Tour, incluindo vários majors.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Neste capítulo, vimos como fazer a transição da faculdade para o profissionalismo e quais rotas alternativas estão disponíveis. No próximo capítulo, exploraremos as influências e oportunidades internacionais no golfe universitário.
        </MKTypography>
    </MKBox>
</MKBox>







                                              






                                  







<MKBox id="Chapter 4" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Capítulo 4: Influências e Oportunidades Internacionais
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        O golfe universitário não é apenas um esporte americano, mas também internacional. Existem muitos jogadores estrangeiros que vêm para os EUA estudar e jogar golfe, e também muitos jogadores americanos que jogam no exterior. Neste capítulo, vamos examinar o impacto e as contribuições dos estudantes-atletas internacionais e as oportunidades para os golfistas universitários americanos jogarem internacionalmente.
    </MKTypography>

    <MKBox id="4.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.1 Jogadores Estrangeiros no Golfe Universitário
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            O golfe universitário é uma opção atrativa para muitos jogadores estrangeiros que procuram uma combinação de esportes e educação. Eles podem se beneficiar da alta qualidade das universidades americanas, das excelentes instalações e orientação dos programas de golfe e da forte competição e exposição dos torneios de golfe universitários.
            <br/>
            <br/>
            Os jogadores estrangeiros também oferecem muito às universidades americanas. Eles trazem diversidade, cultura e talento para suas equipes. Eles também podem compartilhar suas experiências e perspectivas com seus companheiros de equipe e treinadores, contribuindo para o seu desenvolvimento.
            <br/>
            <br/>
            Muitos jogadores estrangeiros têm sido bem-sucedidos no golfe universitário, se destacando como estudantes-atletas. Exemplos incluem:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Maria Fassi: Fassi jogou golfe universitário na Universidade de Arkansas, onde ganhou o prêmio ANNIKA duas vezes como a melhor golfista universitária feminina. Ela se tornou profissional em 2019 e desde então conquistou várias vitórias profissionais.
                </li>
                <li>
                    Viktor Hovland: Hovland jogou golfe universitário na Universidade Estadual de Oklahoma, onde ganhou o campeonato da NCAA em 2018. Ele se tornou profissional em 2019 e desde então conquistou várias vitórias profissionais.
                </li>
            </ul>
        </MKTypography>
    </MKBox>
    <MKBox id="4.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.2 Jogadores Americanos no Exterior
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            O golfe universitário não é a única forma de se desenvolver como golfista. Existem também muitas oportunidades para golfistas universitários americanos jogarem internacionalmente, experimentando outras culturas e campos. Isso pode ampliar seus horizontes, aumentar sua adaptabilidade e melhorar seu jogo.
            <br/>
            <br/>
            Os golfistas universitários americanos podem jogar internacionalmente por meio de diversos programas, como:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    The Palmer Cup: Um torneio anual entre equipes de golfistas universitários americanos e internacionais, nomeado em homenagem a Arnold Palmer, um famoso golfista que também jogou golfe universitário. O torneio é realizado em diferentes locais ao redor do mundo, como França, Irlanda, Inglaterra e Suécia.
                </li>
                <li>
                    The Eisenhower Trophy: Um torneio bienal entre equipes de golfistas amadores de diferentes países, nomeado em homenagem a Dwight D. Eisenhower, ex-presidente dos EUA e ávido golfista. O torneio é realizado em vários locais ao redor do mundo, como Austrália, Japão, México e Turquia.
                </li>
                <li>
                    O Programa de Estudos no Exterior: Um programa acadêmico que oferece aos alunos a oportunidade de estudar por um semestre ou um ano em uma universidade estrangeira. Algumas universidades também têm programas de golfe que colaboram com universidades estrangeiras, permitindo que estudantes-atletas joguem golfe durante sua estadia.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Muitos golfistas universitários americanos se beneficiaram de jogar no exterior, elevando seu jogo a um nível mais alto. Exemplos incluem:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Collin Morikawa: Morikawa jogou golfe universitário na Universidade da Califórnia, Berkeley, onde se tornou um All-American quatro vezes. Ele também jogou na Palmer Cup em 2017 e 2018, e na Eisenhower Trophy em 2018. Ele se tornou profissional em 2019 e desde então conquistou múltiplas vitórias profissionais.
                </li>
                <li>
                    Jennifer Kupcho: Kupcho jogou golfe universitário na Universidade de Wake Forest, onde ganhou o campeonato da NCAA em 2018. Ela também jogou na Palmer Cup em 2018, e na Curtis Cup em 2016 e 2018. Ela se tornou profissional em 2019 e desde então conquistou múltiplas vitórias profissionais.
                </li>
                <li>
                    Patrick Reed: Reed jogou golfe universitário na Universidade da Geórgia e na Universidade Estadual de Augusta, onde ganhou o campeonato da NCAA duas vezes. Ele também jogou na Palmer Cup em 2011 e estudou um semestre na Universidade de Stirling, na Escócia. Ele se tornou profissional em 2011 e desde então conquistou múltiplas vitórias profissionais.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Neste capítulo, vimos como o golfe universitário é um esporte internacional, e as influências e oportunidades para jogadores estrangeiros e americanos. No próximo capítulo, vamos analisar a importância das bolsas de estudo de golfe e o papel do Sportbeursamerika.nl.
        </MKTypography>
    </MKBox>
</MKBox>











                                              






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    As bolsas de estudo esportivas oferecem inúmeras oportunidades para jovens atletas mostrarem seu talento e desenvolverem suas habilidades. Uma das oportunidades mais significativas é obter uma bolsa de estudos esportiva para estudar e competir no nível universitário. Neste capítulo, discutiremos a importância das bolsas de estudo esportivas e como a Sport Scholarships America pode ajudá-lo a realizar seu sonho.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 A Importância das Bolsas de Estudo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Uma bolsa de estudos esportiva é um suporte financeiro fornecido por uma universidade a um estudante-atleta para combinar estudos e esportes. Pode cobrir os custos de mensalidades, livros, moradia, refeições e outras despesas. Além disso, pode oferecer benefícios como tutoria acadêmica, cuidados médicos, instalações de treinamento e equipamentos.
      <br />
      <br />
      Obter uma bolsa de estudos esportiva é competitivo, com muitos fatores desempenhando um papel no processo de concessão, incluindo realizações acadêmicas, desempenho atlético, personalidade, motivação, liderança e adaptabilidade. Portanto, é importante se preparar bem e se destacar da concorrência.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Como a Sport Scholarships America Pode Ajudar
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo da Sport Scholarships America, mostrando equipamentos esportivos e um capelo de formatura, representando o foco em bolsas de estudo esportivas para estudantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo da Sport Scholarships America
        </figcaption>
      </figure>
      A Sport Scholarships America é uma organização especializada em orientar jovens talentos esportivos para bolsas de estudo esportivas. Com especialistas apaixonados e experientes, a Sport Scholarships America apoia estudantes-atletas em cada etapa da jornada, desde a orientação inicial até a colocação final. Seus serviços incluem:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Uma entrevista de admissão pessoal para discutir os desejos, possibilidades e expectativas do estudante-atleta.</li>
        <li>Uma avaliação profissional do nível acadêmico e atlético do estudante-atleta.</li>
        <li>Uma extensa rede de contatos com treinadores, universidades e faculdades.</li>
        <li>Promoção eficaz do perfil e vídeos do estudante-atleta para treinadores potenciais.</li>
        <li>Conselhos especializados sobre as melhores opções com base nas preferências, metas e orçamento do estudante-atleta.</li>
        <li>Negociação das melhores ofertas de bolsas de estudo em nome do estudante-atleta.</li>
        <li>Auxílio na solicitação de documentos necessários, como visto, histórico escolar, diploma e testes de admissão.</li>
        <li>Preparação para a vida do estudante-atleta durante a bolsa de estudos, incluindo cultura, idioma, regras e dicas.</li>
        <li>Acompanhamento e acompanhamento durante o período da bolsa de estudos.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Quer explorar oportunidades de bolsas de estudo e alcançar seus objetivos atléticos e acadêmicos? <Link to="/pt/Serviços-Produtos">Veja a gama completa</Link> de serviços oferecidos pela Sport Scholarships America e agende uma consulta hoje.
    </MKTypography>
  </MKBox>
</MKBox>



          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;