// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";
import "../../../../H1asH3.css"

// Images
import profilePicture from "assets/images/Profielfoto_Kay_Duit.jpeg";

function Profil() {
  return (
    <MKBox component="section" pt={{ xs: 20, sm: 20,  md: 6}} pb={{ xs: 6, sm: 0}}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
            <MKAvatar src={profilePicture} alt="Profilbillede af Kay Duit, stifter og ejer" size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox 
                display="flex" 
                flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} 
                justifyContent="space-between" 
                alignItems="center" 
                mb={1}
              >
                <MKTypography variant="h1" className="h1-som-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Kay Duit</MKTypography>
                <MKBox display="flex" flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} gap={1}>
                  <a href="https://www.linkedin.com/in/kayduit/" target="_blank" rel="noopener noreferrer">
                    <MKSocialButton color="linkedin" style={{ width: '140px' }}>
                      <MKBox component="i" color="inherit" mr={0.5} className="fab fa-linkedin" />
                      LinkedIn
                    </MKSocialButton>
                  </a>
                  <a href="https://www.instagram.com/kayduit/" target="_blank" rel="noopener noreferrer">
                    <MKSocialButton color="instagram" style={{ width: '140px' }}>
                      <MKBox component="i" color="inherit" mr={0.5} className="fab fa-instagram" />
                      Instagram
                    </MKSocialButton>
                  </a>
                  <a href="/Podcast">
                    <MKSocialButton color="pinterest" style={{ width: '140px' }}>
                      <MKBox component="i" color="inherit" mr={0.5} className="fas fa-podcast" />
                      Podcast
                    </MKSocialButton>
                  </a>
                </MKBox>
              </MKBox>
              <br />
              <MKBox>
                <MKTypography variant="body1" fontWeight="light" color="text">
                  På en varm sommeraften i Amerika stod jeg på fodboldbanens sidelinje og ventede på fløjtesignalet for at storme ind på banen. Mit hjerte hamrede i halsen, ikke på grund af den kommende kamp, men på grund af erkendelsen af, hvor langt jeg var kommet. Tre år tidligere havde jeg taget et fly fra mit hjemland til USA, et spring ud i det ukendte, i søgen efter eventyr og muligheden for at realisere både mine sportslige og akademiske drømme.
                  <br /><br />
                  I løbet af de fire år havde jeg oplevet meget mere end bare fodboldkampe og studier. Der var de sene middage efter en stor sejr, flyrejser til udekampe i fjerne stater, de venskaber, jeg havde opbygget, og de utallige gange, hvor jeg havde indset, hvor meget større verden var end det lille sted, jeg kom fra.
                  <br /><br />
                  Den aften, mens nationalhymnen spillede, og tribunerne var fyldt med tusindvis af jublende fans, tænkte jeg på alle de unge studerende fra hele verden, der, ligesom mig, drømte om sådanne muligheder. Og jeg forpligtede mig til, at når min rejse i Amerika var afsluttet, ville jeg række en hjælpende hånd ud til disse håbefulde studerende, uanset hvor de kom fra, og hjælpe dem med at realisere drømme, der ligner mine.
                </MKTypography>
              </MKBox>

              <MKTypography
                component="a"
                href="#"
                variant="body1"
                fontWeight="light"
                color="info"
                mt={3}
                sx={{
                  width: "max-content",
                  display: "flex",
                  alignItems: "center",
                  "& .material-icons-round": {
                    transform: `translateX(3px)`,
                    transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                  },
                  "&:hover .material-icons-round, &:focus .material-icons-round": {
                    transform: `translateX(6px)`,
                  },
                }}
              >
                {/* Add the translation for the text here */}
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profil;
