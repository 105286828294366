/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Hockey1.jpg";
import Image2 from "assets/images/Sports_Images/Hockey2.jpeg";
import Image3 from "assets/images/Sports_Images/Hockey3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");




  
  
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Hockey på Græs Stipendier i Amerika: Hvordan Hockey på Græs Kan Hjælpe Dig Med at Opnå Dine Drømme', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapitel 1: Historie og Udvikling af Hockey på Græs i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Tidlig Historie', isSubheader: true },
    { id: '1.2', title: '1.2 Udvikling og Professionalisering', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapitel 2: Hockey på Græs på Universitetsniveau', isSubheader: false },
    { id: '2.1', title: '2.1 Konkurrencer og Konferencer', isSubheader: true },
    { id: '2.2', title: '2.2 Indflydelse på Spillerudvikling', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapitel 3: Overgang fra Universitet til Professionel', isSubheader: false },
    { id: '3.1', title: '3.1 Fra College til Professionelt Spil', isSubheader: true },
    { id: '3.2', title: '3.2 Professionelle Muligheder og Ligaer', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapitel 4: Internationale Indflydelser og Muligheder', isSubheader: false },
    { id: '4.1', title: '4.1 Globale Indflydelser', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanere på Verdensscenen', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapitel 5: Sportslegater og Fremtiden for Atleter', isSubheader: false },
    { id: '5.1', title: '5.1 Vigtigheden af Legater', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Legater Amerika Kan Hjælpe', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  const [,] = useState("");


  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>






















          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
  Hockey på Græs Stipendier i Amerika: Hvordan Hockey på Græs Kan Hjælpe Dig Med at Opnå Dine Drømme
    </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Spiller i aktion under en Colgate college field hockey kamp."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
      Spiller i aktion under en kamp
      </figcaption>
    </figure>

    Field hockey er en af de hurtigst voksende sportsgrene i Amerika. Flere og flere unge atleter opdager fordelene og nydelsen ved denne dynamiske og udfordrende sport. Men vidste du, at field hockey også kan hjælpe dig med at opnå dine akademiske og professionelle mål?
    <br />
    <br />

    Hvis du brænder for field hockey, har du en unik mulighed for at spille på universitetsniveau i Amerika. Det betyder, at du kan studere på et af verdens bedste universiteter, samtidig med at du udvikler dine sportslige færdigheder under professionel vejledning. Derudover kan du kvalificere dig til et sportsstipendium, der giver økonomisk støtte til din uddannelse.

    <br />
    <br />
    Men hvordan får du sådan et stipendium? Og hvordan forbereder du dig på at spille field hockey i Amerika? Det er her Sportbeursamerika.nl kan hjælpe dig. Sportbeursamerika.nl er en specialiseret virksomhed, der hjælper unge talenter med at opfylde deres drøm om at studere og spille hockey i Amerika. De tilbyder personlig vejledning, rådgivning og support til at finde det rette universitet, ansøge om et stipendium, håndtere alle praktiske spørgsmål og integrere sig i den amerikanske kultur.
    <br />
    <br />
    I denne artikel vil jeg fortælle dig mere om historien og udviklingen af field hockey i Amerika, mulighederne og fordelene ved at spille på universitetsniveau, overgangen fra college til professionel spil, de internationale indflydelser og muligheder inden for sporten, og selvfølgelig vigtigheden af stipendier og rollen Sportbeursamerika.nl. Efter at have læst denne artikel vil du have en bedre forståelse af, hvad field hockey i Amerika indebærer, og hvordan du kan forberede dig på denne spændende udfordring.
    <br />
    <br />
    Er du klar til at lære mere om field hockey i Amerika? Fortsæt med at læse!
  </MKTypography>
  {/* Tilføj punktopstilling eller andet indhold efter behov */}
</MKBox>


<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Historie og Udvikling af Hockey på Græs i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Hockey på græs er en sport med en lang og rig historie. Men hvordan opstod den og voksede den i Amerika? I dette kapitel tager vi dig med på en rejse gennem tiden, fra den tidlige introduktion til den nuværende professionalisering af sporten.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidlig Historie
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Hockey på græs blev først introduceret i USA i slutningen af det 19. århundrede af britiske immigranter og turister. Spillet vandt hurtigt popularitet blandt kvinder, der så det som en måde at motionere og socialisere på. De første collegiale hold blev dannet i 1901 på Vassar College i New York og Bryn Mawr College i Pennsylvania. Disse hold spillede primært mod hinanden eller lokale klubber.
      <br />
      <br />
      I 1922 blev United States Field Hockey Association (USFHA) etableret som den nationale organisation for sporten. USFHA organiserede de første nationale mesterskaber for kvinder i 1925 og for mænd i 1930. USFHA var også ansvarlig for at udvælge og træne nationale hold, der deltog i internationale turneringer som OL og verdensmesterskaberne.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Udvikling og Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      I anden halvdel af det 20. århundrede fortsatte hockey på græs med at vokse i popularitet og kvalitet i Amerika. Spillet spredte sig yderligere over hele landet, især i Nordøst, Midtvesten og Vesten. Antallet af collegiale hold steg, og der blev dannet flere ligaer og konferencer. Nogle af de mest prestigefyldte og konkurrencedygtige programmer inkluderer Princeton University, University of Maryland, University of North Carolina, Stanford University og Harvard University.
      <br />
      <br />
      Niveauet af collegialt hockey på græs steg også takket være forbedrede faciliteter, træning og rekruttering. Mange universiteter tilbyder nu sportslegater til talentfulde hockey på græs spillere, hvilket giver dem mulighed for at studere uden økonomiske bekymringer. Desuden fungerer collegialt hockey på græs som et avlsgrund for professionel hockey på græs, som også bliver mere udviklet og professionaliseret.
      <br />
      <br />
      Professionel hockey på græs har også påvirket det nationale hold, som har klaret sig bedre på den internationale scene. For eksempel har det amerikanske kvindelandshold vundet bronze to gange ved OL (i 1984 og 1996) og sølv to gange ved Panamerikanske Lege (i 2011 og 2015). Det amerikanske herrelandshold har også deltaget i flere OL og verdensmesterskaber, men har endnu ikke sikret sig nogen medaljer.
      <br />
      <br />
      Som du kan se, har hockey på græs haft en lang vej i Amerika, fra en rekreativ aktivitet til en professionel sport. Men hvad indebærer det at spille hockey på græs på collegeniveau? Det vil vi fortælle dig i det næste kapitel.
    </MKTypography>
  </MKBox>
</MKBox>






                                              





                                  








<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Field Hockey på Universitetsniveau
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    At spille field hockey på universitetsniveau er en af de mest udfordrende og givende oplevelser for en ung atlet. Det giver dig mulighed for at kombinere dit sportstalent med dine akademiske ambitioner og være en del af et tæt og mangfoldigt fællesskab. I dette kapitel vil vi fortælle dig mere om konkurrencerne og konferencerne, indflydelsen på spillerudvikling og fordelene ved at spille field hockey på universitetsniveau.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Konkurrencer og Konferencer
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Luftfoto af Boston College field hockey stadion"
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
        Stadionet på Boston College
        </figcaption>
      </figure>
      Field hockey på universitetsniveau reguleres af National Collegiate Athletic Association (NCAA), som opdeler sporten i tre divisioner: Division I, Division II og Division III. Hver division har sine egne regler, krav og stipendier. Generelt set er Division I det højeste konkurrenceniveau, der tilbyder de bedste hold og spillere samt flest stipendier. Division II og III er mindre konkurrenceprægede, men giver stadig rigeligt med muligheder for talentfulde spillere.
      <br />
      <br />
      Inden for hver division findes der forskellige konferencer, der består af universiteter, der er geografisk eller historisk forbundet. Hver konference har sit eget program, rangeringer og mesterskab. Nogle af de mest velkendte konferencer inkluderer Big Ten Conference, Atlantic Coast Conference, Ivy League, Patriot League og America East Conference.
      <br />
      <br />
      De bedste hold og spillere fra hver konference kan kvalificere sig til det nationale mesterskab, der afholdes årligt i november. Det nationale mesterskab indebærer en knockout-turnering, hvor vinderen kåres som national mester.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Indflydelse på Spillerudvikling
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      At spille field hockey på universitetsniveau har en betydelig indflydelse på spillerudviklingen. Det giver spillerne mulighed for at forbedre deres færdigheder under professionel træning, at træne med topmoderne faciliteter og udstyr, at konkurrere mod andre topatleter og at lære af deres holdkammerater og modstandere.
      <br />
      <br />
      Derudover tilbyder spillet af field hockey på universitetsniveau også spillerne mange personlige fordele. Det hjælper spillerne med at øge deres selvtillid, disciplin, ledelse, teamwork, kommunikation og problemløsningsfærdigheder. Det hjælper også spillerne med at forbedre deres tidsstyring, studievaner, stresshåndtering og generelle velbefindende. Derudover udsætter det spillerne for forskellige kulturer, perspektiver og oplevelser, der udvider deres horisonter.
      <br />
      <br />
      Historien om hollandske kvindelige hockeyspillere, der modtager stipendier for at spille på universitetsniveau i Amerika, tjener som et eksempel på, hvordan denne oplevelse kan forbedre deres udvikling som spillere. Mange har spillet for prestigefyldte universitetshold i USA, hvor de ikke kun har vundet mesterskaber og individuelle titler, men også lært værdifulde lektioner.
      <br />
      <br />
      Oplevelsen i Amerika giver disse atleter en unik mulighed for at fordybe sig i en anden kultur, nyde nye sociale miljøer og opleve et andet uddannelsessystem. Samspillet med trænere, der udfordrer og støtter dem, bidrager til at forfine deres færdigheder og udvide deres taktiske forståelse af spillet.
      <br />
      <br />
      Når de vender tilbage til Holland, har disse hockeyspillere brugt deres erhvervede viden og erfaringer til at brillere på professionelt niveau og i nogle tilfælde indtage ledende roller inden for det nationale hold. Deres tid i Amerika har ofte forberedt dem på presset og forventningerne, der følger med internationale konkurrencer, og dannet et fundament for deres fremtidige succes.
      <br />
      <br />
      Som du kan se, kan spillet af field hockey på universitetsniveau tilbyde dig mange fordele, både sportsligt og personligt. Men hvordan skifter du fra college til professionelt spil? Det vil vi forklare i det næste kapitel.
    </MKTypography>
  </MKBox>
</MKBox>




                                  







<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Overgang fra Universitet til Professionel
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Field hockey på universitetsniveau er en fantastisk måde at udfolde dit sportslige potentiale på, men det behøver ikke at være afslutningen på din karriere. Hvis du har ambitionerne og passionen for at gå videre, kan du skifte til professionelt spil. I dette kapitel vil vi fortælle dig mere om, hvordan du går fra college til professionelt spil, og hvilke professionelle muligheder og ligaer der findes for field hockey spillere.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Fra College til Professionelt Spil
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Overgangen fra universitet til professionelt spil er ikke let, men det er heller ikke umuligt. Det kræver meget dedikation, hårdt arbejde, talent og lidt held. Det mest afgørende aspekt er at vise dig selv frem for de rette personer, der kan hjælpe dig med at opnå din drøm.
      <br />
      <br />
      En måde at vise dig selv frem på er ved at deltage i prøvetræninger. Prøvetræninger er åbne træningssessioner eller kampe, hvor professionelle hold kan skue og vurdere spillere. Prøvetræninger afholdes typisk før eller efter sæsonen og kan annonceres gennem hjemmesider, sociale medier eller mund til mund. Prøvetræninger kan være en fremragende mulighed for at vise dine færdigheder frem og modtage feedback fra trænere og ledere. Prøvetræninger kan afholdes af professionelle hold over hele verden.
      <br />
      <br />
      En anden måde at gøre dig selv kendt på er ved at udnytte dit netværk. Dit netværk består af alle de mennesker, du kender eller møder, der er forbundet med field hockey, herunder trænere, holdkammerater, modstandere, venner, familie, lærere, alumner osv. Dit netværk kan hjælpe dig med at holde dig informeret om de seneste nyheder, muligheder og tips i field hockey-verdenen. Det kan også hjælpe dig med at oprette forbindelse til personer, der har indflydelse eller forbindelser i den professionelle scene.
      <br />
      <br />
      Derudover vender mange europæiske hockeyspillere tilbage til Europa for at prøve lykken der. Som du kan se, er der forskellige måder at blive bemærket af professionelle hold på. Men hvilke slags hold findes der, og hvilke typer ligaer eksisterer der? Det vil vi forklare i næste afsnit.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professionelle Muligheder og Ligaer
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      For en field hockey spiller, der har gennemgået det amerikanske collegesystem, er dørene til en professionel karriere ofte vidt åbne. Denne erfaring er mere end bare en trædesten; det er en omfattende forberedelse, der har udviklet dem på mange områder - fysisk, taktisk og mentalt.
      <br />
      <br />
      En spiller, der udmærker sig i collegeligaen, kan fange opmærksomheden fra scouter og professionelle hold, ikke kun på grund af deres præstation på banen, men også på grund af den disciplin og alsidighed, de har udviklet ved at studere og spille samtidig. Professionelle hockeyligaer over hele verden, såsom dem i Holland, Belgien, Tyskland og Australien, er kendt for at rekruttere talentfulde spillere med en solid baggrund i collegeligaen.
      <br />
      <br />
      Den konkurrencemæssige natur af collegeligaen i Amerika sikrer også, at spillere er vant til et højt niveau af træning og fysisk forberedelse, hvilket er afgørende for det professionelle spil. Desuden har de ofte erfaring med at spille i vigtige kampe og mesterskaber, hvilket hjælper dem med at håndtere presset fra professionelle konkurrencer.
      <br />
      <br />
      Derudover giver den internationale erfaring og eksponering for forskellige spillestile i Amerika disse spillere et unikt perspektiv, de kan tage med sig ind i deres professionelle karrierer. Det hjælper dem med at tilpasse sig hurtigt til nye hold og strategier og giver en bredere forståelse af spillet.
      <br />
      <br />
      Professionelle hold værdsætter kombinationen af akademisk disciplin og sportslig fremragenhed, som en tidligere college spiller bringer. Disse spillere er ofte godt forberedte på at møde udfordringerne i det professionelle liv, herunder at balancere sport med andre livsforpligtelser.
      <br />
      <br />
      Som du kan se, er der mange professionelle muligheder og ligaer for field hockey spillere i Amerika. Men hvad med resten af verden? Hvordan sammenligner field hockey i Amerika sig med international field hockey? Det vil vi forklare i næste kapitel.
    </MKTypography>
  </MKBox>
</MKBox>





                                  








<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationale Indflydelser og Muligheder
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Field hockey i Amerika er ikke isoleret, men er en del af en større og mere mangfoldig verden af field hockey. Field hockey er en global sport, der spilles i mere end 100 lande på alle kontinenter. Field hockey i Amerika påvirkes af og påvirker international field hockey. I dette kapitel vil vi fortælle dig mere om globale indflydelser, amerikanere på verdensscenen og internationale muligheder for field hockey spillere.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Globale Indflydelser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Field hockey i Amerika påvirkes af field hockey, der spilles i andre lande, især i Europa og Asien. Disse lande har en længere og rigere tradition inden for field hockey og har ofte et højere spilniveau og en bedre organisering. Ved at observere og lære af disse lande kan field hockey i Amerika yderligere udvikle sig og forbedre sig.
      <br />
      <br />
      En måde, hvorpå field hockey i Amerika påvirkes af international field hockey, er ved at adoptere forskellige stilarter og taktikker. For eksempel er hollandsk field hockey kendt for sit hurtige og angribende spil med fokus på boldbesiddelse, kombinationer og mål forsøg. Tysk field hockey er kendt for sin fysiske og defensive stil, der indeholder højt pres, dueller og kontrangreb. Indisk field hockey er kendt for sit tekniske og kreative spil med fokus på driblinger, pasninger og tricks.
      <br />
      <br />
      Ved at lære af disse stilarter og taktikker kan field hockey i Amerika diversificere sit eget spil og tilpasse sig forskellige situationer, hvilket tilføjer mere variation, fleksibilitet og effektivitet.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikanere på Verdensscenen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Field hockey i Amerika påvirker også international field hockey ved at give spillere, der deltager i de største og mest betydningsfulde turneringer globalt. Disse spillere repræsenterer ikke kun deres land, men også deres universitet, hold og sport. Gennem deres præstationer demonstrerer de, hvad field hockey i Amerika har at tilbyde, og inspirerer andre til at deltage i denne sport.
      <br />
      <br />
      Et fremragende eksempel på, hvordan amerikanske spillere træder ind på verdensscenen, er historien om Lauren Crandall. Lauren er en amerikansk field hockey spiller, der modtog et stipendium for at spille for Wake Forest University. Hun spillede for holdet i fire sæsoner og vandt NCAA Division I-titlen to gange.
      <br />
      <br />
      Lauren var også en del af det amerikanske landshold og deltog i tre olympiske lege (i 2008, 2012 og 2016), to verdensmesterskaber (i 2010 og 2014) og fire Panamerikanske lege (i 2007, 2011, 2015 og 2019). Hun fungerede også som holdkaptajn fra 2013 til 2016.
      <br />
      <br />
      Lauren siger, at hendes tid i Amerika hjalp hende betydeligt med at realisere sin drøm om at spille på det højeste niveau. Hun lærte meget af sin træner, Jennifer Averill, der støttede og motiverede hende. Hun nød også kulturen, menneskene og uddannelsen i Amerika. Hun er stolt af at repræsentere sit land og være et forbillede for unge spillere.
      <br />
      <br />
      Som du kan se, kan field hockey i Amerika tilbyde dig mange internationale indflydelser og muligheder, både som spiller og som individ. Men hvordan kan du gribe disse muligheder? Og hvordan kan du få et stipendium til at studere og spille i Amerika? Det vil vi fortælle dig i næste kapitel.
    </MKTypography>
  </MKBox>
</MKBox>
                       






                                  




<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Sportslegater og fremtiden for atleter
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sportslegater tilbyder talrige muligheder for unge atleter til at vise deres talent og udvikle deres færdigheder. En af de mest betydningsfulde muligheder er at opnå et sportslegat for at studere og konkurrere på kollegialt niveau. I dette kapitel vil vi diskutere vigtigheden af sportslegater og hvordan Sport Legater Amerika kan hjælpe dig med at realisere din drøm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vigtigheden af legater
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et sportslegat er økonomisk støtte, som et universitet tilbyder til en studerende-atlet for at kombinere studier og sport. Det kan dække omkostningerne til undervisning, bøger, bolig, måltider og andre udgifter. Derudover kan det også tilbyde fordele såsom akademisk vejledning, lægehjælp, træningsfaciliteter og udstyr.
      <br />
      <br />
      At opnå et sportslegat er konkurrencepræget, hvor mange faktorer spiller en rolle i tildelingsprocessen, herunder akademiske præstationer, atletisk præstation, personlighed, motivation, lederskab og tilpasningsevne. Derfor er det vigtigt at forberede sig godt og adskille sig fra konkurrencen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Legater Amerika Kan Hjælpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo af Sport Legater Amerika, der viser sportsudstyr og en eksamenshue, der repræsenterer fokus på sportslegater for studerende-atleter."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo af Sport Legater Amerika
        </figcaption>
      </figure>
      Sport Legater Amerika er en organisation specialiseret i at guide unge sports talenter til sportslegater. Med passionerede og erfarne eksperter støtter Sport Legater Amerika studerende-atleter på hvert trin af rejsen, fra indledende orientering til endelig placering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intake-interview for at diskutere den studerende-athlets ønsker, muligheder og forventninger.</li>
        <li>En professionel evaluering af den studerende-athlets atletiske og akademiske niveau.</li>
        <li>Et omfattende netværk af kontakter med trænere, universiteter og kollegier.</li>
        <li>Effektiv markedsføring af den studerende-athlets profil og videoer til potentielle trænere.</li>
        <li>Ekspertrådgivning om de bedste muligheder baseret på den studerende-athlets præferencer, mål og budget.</li>
        <li>Forhandling af de bedste legat tilbud på vegne af den studerende-atlet.</li>
        <li>Hjælp til ansøgning om nødvendige dokumenter, såsom visum, transkript, eksamensbevis og optagelsesprøver.</li>
        <li>Forberedelse til den studerende-atlets liv under deres legat, herunder kultur, sprog, regler og tips.</li>
        <li>Efterpleje og opfølgning under legatperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ønsker du at udforske legatmuligheder og opnå dine sportslige og akademiske mål? <Link to="/da/Tjenester-Produkter">Se det fulde udvalg</Link> af tjenester tilbudt af Sport Legater Amerika og planlæg en konsultation i dag.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;