/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Produktsida: https://www.creative-tim.com/product/material-kit-pro-react
* Upphovsrätt 2023 Creative Tim (https://www.creative-tim.com)

Kodad av www.creative-tim.com

=========================================================

* Ovanstående upphovsrättsmeddelande och detta tillståndsmeddelande ska inkluderas i alla kopior eller väsentliga delar av programvaran.
*/

import { useState } from "react";

// @mui-materialkomponenter
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React-komponenter
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton"; // Importera MKButton
import { Link } from 'react-router-dom';

// Komponenter för uthyrningssidan
import FaqCollapse from "Languages/Svenska/pages/Homepage/LandingPage/sections/FAQfunction";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <MKBox component="section" py={8}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
              Vanliga frågor
            </MKTypography>
            <MKBox mb={2}>
              <MKTypography variant="body2" align="center" color="text">
                Allt du vill veta: svar på de vanligaste frågorna om studier och sport i Amerika och mina tjänster. Om din fråga inte finns med, kontakta oss gärna.
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={10}>
          <FaqCollapse
   title="Hur mycket kostar det att studera i Amerika?"
   open={collapse === 1}
   onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
>
   Studieavgifterna och kostnaderna för att studera i Amerika varierar mycket beroende på universitetet, studieprogrammet och platsen. I genomsnitt kan internationella studenter förvänta sig en total årlig kostnad på cirka $30 000 upp till så mycket som $70 000 eller mer. Det är dock värt att notera att många studenter kan täcka en betydande del (ibland hela) av dessa kostnader genom idrottsstipendier, akademiska stipendier eller en kombination av båda. Amerikanska universitet erbjuder ofta idrottsstipendier till talangfulla idrottare och ger ekonomiskt stöd i utbyte mot deras deltagande i universitetets idrottsprogram. Detta gör att studentidrottare kan följa sina akademiska och idrottsliga mål utan betydande ekonomiska bördor.
</FaqCollapse>

<FaqCollapse
   title="Vad är skillnaden mellan en högskola och ett universitet i Amerika?"
   open={collapse === 2}
   onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
>
   Terminologin kan vara förvirrande, särskilt för personer utanför USA. I praktiken refererar "högskola" och "universitet" faktiskt inte till samma sak. Ett "universitet" är en omfattande term för en högre utbildningsinstitution som erbjuder program på alla nivåer, inklusive kandidatexamen, master och doktorandstudier.

   En "högskola" är däremot vanligtvis en del av ett universitet och fokuserar främst på grundutbildningsprogram, som kandidatexamen. Med andra ord är en högskola en avdelning inom ett universitet som är specifikt dedikerad till grundutbildning. Universitet erbjuder vanligtvis både grund- och forskarutbildningsprogram, medan högskolor koncentrerar sig på grundnivån.

   Denna åtskillnad kan dock variera mellan olika institutioner, och ibland används termerna omväxlande. Därför är det användbart att undersöka de specifika detaljerna och programmen vid en utbildningsinstitution för att förstå vad de erbjuder.
</FaqCollapse>

<FaqCollapse
   title="Vilka idrottare vägleds av Sport Scholarships America?"
   open={collapse === 3}
   onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
>
   Jag vägleder idrottare från en bred skala av sporter. Mitt omfattande nätverk och partnerskap i Amerika gör det möjligt för mig att stödja idrottare inom olika discipliner. Så oavsett om du spelar fotboll, basket, tennis, golf eller någon annan sport, är jag redo att hjälpa dig att följa dina akademiska och idrottsliga mål i USA. För en komplett lista över sporter, <Link to="/sv/Alla-sporter" style={{ fontWeight: 'bold' }}>klicka här</Link>.
</FaqCollapse>

<FaqCollapse
   title="Vad är den typiska ansökningsprocessen, och hur långt i förväg ska jag börja?"
   open={collapse === 4}
   onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
>
   Processen börjar vanligtvis med ett omfattande introduktionsmöte där vi diskuterar alla detaljer och förstår din specifika situation och dina mål. Jag kommer också att förklara min metod närmare. Varaktigheten av hela processen kan variera, men generellt sett rekommenderar jag att du börjar minst 6 till 12 månader i förväg. Det är viktigt att betona att ju tidigare du börjar, desto fler möjligheter kan uppstå, så tveka inte att börja processen tidigt.

   Under denna period kommer vi att samarbeta för att skapa en personlig plan som tar hänsyn till alla aspekter, som antagningsprov, visumansökningar, skapande av idrottsvideor och mer. Varje resa är unik och skräddarsydd, eftersom det finns många faktorer att beakta. För en detaljerad översikt över hela processen kan du <Link to="/sv/Process" style={{ fontWeight: 'bold' }}>klicka här</Link>. Detta ger dig en steg-för-steg-insikt i vad du kan förvänta dig under din resa för att studera och tävla i USA.
</FaqCollapse>

<FaqCollapse
   title="Vad är kostnaderna för de tjänster du tillhandahåller?"
   open={collapse === 5}
   onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
>
   Kostnaderna för mina tjänster varierar beroende på dina specifika behov och mål. Intervjun är ett viktigt steg där vi diskuterar din situation och utvecklar en anpassad plan. Det är också möjligheten att avgöra om vi vill fortsätta att arbeta tillsammans.

   Efter den här konversationen kommer jag att kunna få en tydlig förståelse för dina önskemål och behov, och baserat på det kan jag ge dig en lämplig kostnadsberäkning.

   För en fullständig översikt över de tjänster och produkter jag erbjuder kan du <Link to="/sv/Tjänster-Produkter" style={{ fontWeight: 'bold' }}>klicka här</Link>. Här hittar du detaljerad information om hur jag kan assistera dig i din strävan att studera och tävla i USA.
</FaqCollapse>

<FaqCollapse
   title="Varför ska jag välja att bli vägledd av Sport Scholarships America?"
   open={collapse === 6}
   onClick={() => (collapse === 6 ? setCollapse(false) : setCollapse(6))}
>
   När du befinner dig vid en vägkorsning i förverkligandet av dina drömmar, som jag en gång var, kan många frågor och tvivel uppstå. Valet att göra det själv, samarbeta med en byrå eller till och med ge upp hela idén kan vara överväldigande. Jag förstår djupt den här osäkerheten eftersom jag själv gick igenom den.

   Det vore synd om dessa tvivel hindrade någon från att följa det som verkligen är möjligt - en fantastisk upplevelse som väntar på att upptäckas. Det är därför jag är dedikerad att hjälpa andra att förverkliga sina drömmar.

   Jag sätter mina personliga erfarenheter i praktiken genom att vara direkt involverad i varje projekt. Jag förstår de frågor du kan ha och strävar efter att erbjuda en personlig strategi som är i linje med dina unika behov. Tillsammans kan vi förverkliga din vision, stödd av den kunskap och förståelse som kommer från verklig, upplevd erfarenhet.
</FaqCollapse>

          </Grid>
        </Grid>
        {/* Sektion för knappar */}
        <Grid container justifyContent="center" mt={4}>
          <Grid item xs={12} md={3}>
          <MKButton variant="gradient" color="light" fullWidth component={Link} to="/sv/FAQs">
            Visa alla vanliga frågor
          </MKButton>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Faq;
