/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import Image1 from "assets/images/Sports_Images/Ice Hockey1.jpeg";
import Image2 from "assets/images/Sports_Images/Ice Hockey2.jpg";
import Image3 from "assets/images/Sports_Images/Ice Hockey3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images

import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: "Borsa di Studio per l'Hockey su Ghiaccio negli Stati Uniti: Come Studiare e Giocare a Hockey su Ghiaccio in America", isSubheader: false },
  
    { id: 'Chapter 1', title: "Capitolo 1: La Storia e lo Sviluppo dell'Hockey su Ghiaccio in America", isSubheader: false },
    { id: '1.1', title: '1.1 Gli Anni Iniziali e il Ruolo dei College', isSubheader: true },
    { id: '1.2', title: '1.2 Crescita e Professionalizzazione', isSubheader: true },
  
    { id: 'Chapter 2', title: "Capitolo 2: L'Hockey su Ghiaccio Universitario Oggi", isSubheader: false },
    { id: '2.1', title: '2.1 Principali Leghe e Conference', isSubheader: true },
    { id: '2.2', title: '2.2 Programmi di Punta e Squadre Prestigiose', isSubheader: true },
  
    { id: 'Chapter 3', title: "Capitolo 3: Dal College ai Professionisti", isSubheader: false },
    { id: '3.1', title: '3.1 Il Percorso verso la Professionalità', isSubheader: true },
    { id: '3.2', title: '3.2 Il Draft NHL e Altre Opportunità', isSubheader: true },
  
    { id: 'Chapter 4', title: "Capitolo 4: Influenze Internazionali", isSubheader: false },
    { id: '4.1', title: '4.1 Giocatori Stranieri nell’Hockey su Ghiaccio Universitario', isSubheader: true },
  
    { id: 'Chapter 5', title: "Capitolo 5: Borse di Studio Sportive e il Futuro degli Atleti", isSubheader: false },
    { id: '5.1', title: '5.1 L’Importanza delle Borse di Studio', isSubheader: true },
    { id: '5.2', title: '5.2 Come Sport Scholarships America Può Aiutare', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indice
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Nascondi</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostra</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>

























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Borsa di Studio per l'Hockey su Ghiaccio negli Stati Uniti: Come Studiare e Giocare a Hockey su Ghiaccio in America
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Vista aerea di un palazzetto dello sport affollato durante una partita universitaria di hockey su ghiaccio."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        Un palazzetto dello sport affollato durante una partita universitaria
      </figcaption>
    </figure>
    L'hockey su ghiaccio è uno degli sport più popolari in America. È uno sport veloce, fisico ed emozionante che attira milioni di fan. L'hockey su ghiaccio viene praticato a vari livelli, dall'amatoriale al professionale. Ma uno dei livelli più importanti e prestigiosi è l'hockey su ghiaccio universitario.
    <br />
    <br />
    L'hockey su ghiaccio universitario è la versione dell'hockey su ghiaccio giocata dagli studenti delle università e dei college in America. Fa parte del sistema educativo americano, combinando lo sport e l'istruzione. L'hockey su ghiaccio universitario offre ai giovani giocatori l'opportunità di svilupparsi sia academicamente che atleticamente mentre godono della vita da studente.
    <br />
    <br />
    L'hockey su ghiaccio universitario è anche un trampolino di lancio per il mondo professionale. Molti giocatori che giocano nella NHL (National Hockey League) o in altre leghe professionistiche hanno prima giocato nell'hockey su ghiaccio universitario. L'hockey su ghiaccio universitario è un ottimo modo per prepararsi per una carriera nello sport.
    <br />
    <br />
    Ma come può un giovane giocatore di hockey su ghiaccio qualificarsi per l'hockey su ghiaccio universitario? È qui che Sportbeursamerika.nl può aiutarti. Sono specializzato nell'accompagnare gli studenti-atleti che desiderano ottenere una borsa di studio sportiva in America. Una borsa di studio sportiva è un premio finanziario che ricevi da un'università o un college per studiare e praticare sport lì. Con una borsa di studio sportiva, puoi studiare e giocare a hockey su ghiaccio in America senza sostenere costi elevati.
    <br />
    <br />
    In questo articolo, ti forniremo ulteriori informazioni sull'hockey su ghiaccio universitario in America, sulla sua storia, sul suo sviluppo, sulle leghe, sulle squadre, sulle opportunità, sulle influenze internazionali e, naturalmente, sulle borse di studio. Ti dirò anche come posso aiutarti a realizzare il tuo sogno. Sei pronto per saperne di più sulle borse di studio per l'hockey su ghiaccio negli Stati Uniti? Continua a leggere!
  </MKTypography>
</MKBox>
{/* Capitoli principali e sottocapitoli con testo segnaposto */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 1: La Storia e lo Sviluppo dell'Hockey su Ghiaccio in America
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    L'hockey su ghiaccio è uno sport che esiste in America da oltre un secolo. Ha una storia ricca e affascinante strettamente legata al ruolo dei college. In questo capitolo, approfondiamo le origini, la crescita e la professionalizzazione dell'hockey su ghiaccio in America.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Gli Anni Iniziali e il Ruolo dei College
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      L'hockey su ghiaccio ha origine in Canada, dove è stato giocato per la prima volta nel XIX secolo. Il gioco si diffuse rapidamente negli Stati Uniti, diventando particolarmente popolare negli stati del nord-est. Le prime squadre universitarie furono formate alla fine del XIX e all'inizio del XX secolo, tra cui Yale, Harvard, Princeton e Dartmouth.
      <br />
      <br />
      Queste squadre giocavano partite intercollegiali, attirando spesso folle numerose. Si confrontavano anche con le squadre canadesi, che erano solitamente più forti. Per elevare il livello di gioco, alcune università iniziarono a reclutare giocatori professionisti che si iscrivevano come studenti. Questa pratica sollevò polemiche e critiche poiché comprometteva lo status amatoriale dello sport.
      <br />
      <br />
      Per porre fine a questa pratica, fu fondata nel 1898 l'Intercollegiate Hockey Association (IHA), il primo insieme di programmi di hockey su ghiaccio universitario in America. L'IHA, talvolta indicata come Intercollegiate Hockey League, includeva squadre come Brown, Columbia, l'Università della Pennsylvania e Yale. Questa organizzazione era l'unica autorità del campionato per l'hockey su ghiaccio universitario all'epoca, determinando il campione nazionale de facto in base alle prestazioni della stagione. L'IHA continuò ad esistere fino al 1913.
      <br />
      <br />
      Dopo lo scioglimento dell'IHA, nel 1912 fu istituita l'Intercollegiate Hockey League (IHL) da Harvard, Princeton e Yale. Questa lega era una risposta alla necessità per le migliori squadre universitarie di continuare a competere tra di loro. L'IHL esistette fino al 1917, quando le attività furono sospese a causa della Prima Guerra Mondiale.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crescita e Professionalizzazione
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Durante il XX secolo, l'hockey su ghiaccio universitario si è sviluppato in uno sport nazionale, giocato da centinaia di squadre in varie divisioni e conference. L'hockey su ghiaccio universitario è stato anche sempre più influenzato dalla crescita delle leghe professionistiche di hockey su ghiaccio, come la NHL (fondata nel 1917) e la World Hockey Association (fondata nel 1972).
      <br />
      <br />
      Queste leghe professionistiche hanno fornito opportunità per i talentuosi giocatori universitari di avanzare a un livello superiore. Molti giocatori universitari sono stati selezionati nel NHL Draft, l'evento annuale in cui le squadre NHL scelgono nuovi giocatori. Il primo NHL draft in cui è stato selezionato un giocatore universitario si è svolto nel 1967. Al Karlander, un giocatore della Michigan Tech, è stato selezionato dai Detroit come 17ª scelta assoluta quell'anno. Da allora, oltre mille giocatori universitari sono stati selezionati dalle squadre NHL.
      <br />
      <br />
      L'hockey su ghiaccio universitario ha anche assistito a momenti significativi nella storia dello sport. Uno dei più memorabili è stato il "Miracolo sul Ghiaccio" nel 1980 quando la squadra olimpica americana, composta principalmente da giocatori universitari, ha sconfitto l'Unione Sovietica in una partita storica durante le Olimpiadi Invernali a Lake Placid. Questo è stato un risultato straordinario poiché i sovietici erano considerati la migliore squadra di hockey su ghiaccio al mondo all'epoca.
      <br />
      <br />
      L'hockey su ghiaccio universitario, quindi, ha una lunga e impressionante storia, testimone della passione e del talento dei giocatori americani. L'hockey su ghiaccio universitario ha anche contribuito allo sviluppo e all'innovazione dello sport introducendo nuove regole, tattiche e stili.
    </MKTypography>
  </MKBox>
</MKBox>


                                              





                                  








<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 2: L'Hockey su Ghiaccio Universitario Oggi
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    L'hockey su ghiaccio universitario è uno degli sport più competitivi ed emozionanti in America oggi. È praticato da oltre 400 squadre, divise in tre divisioni NCAA, varie conference e zone regionali. In questo capitolo, forniremo una panoramica delle principali leghe e conference, dei programmi di punta e delle squadre prestigiose, e della struttura della stagione e del campionato.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Principali Leghe e Conference
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Giocatori del Massachusetts che festeggiano una vittoria nell'hockey su ghiaccio universitario."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Giocatori del Massachusetts che festeggiano una vittoria
        </figcaption>
      </figure>
      La NCAA (National Collegiate Athletic Association) è l'organizzazione che regola l'hockey su ghiaccio universitario. La NCAA divide le squadre in tre divisioni in base al livello, al budget e al numero di borse di studio offerte. Le divisioni sono:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Divisione I: Questa è la divisione più alta dove competono le migliori squadre e giocatori. La Divisione I comprende 60 squadre, divise in sei conference: Atlantic Hockey, Big Ten, ECAC Hockey, Hockey East, NCHC (National Collegiate Hockey Conference) e WCHA (Western Collegiate Hockey Association). Queste conference organizzano le proprie competizioni e tornei durante la stagione.
        </li>
        <li>
          Divisione II: Questa è una divisione intermedia con meno squadre e giocatori. Ci sono solo sette squadre nella Divisione II, e non hanno una propria conference. Di solito competono contro squadre della Divisione III o di altre leghe.
        </li>
        <li>
          Divisione III: Questa è la divisione più bassa dove partecipano la maggior parte delle squadre e dei giocatori. La Divisione III è composta da 84 squadre, divise in sette conference: CCC (Commonwealth Coast Conference), MASCAC (Massachusetts State Collegiate Athletic Conference), MIAC (Minnesota Intercollegiate Athletic Conference), NCHA (Northern Collegiate Hockey Association), NEHC (New England Hockey Conference), SUNYAC (State University of New York Athletic Conference) e UCHC (United Collegiate Hockey Conference). Anche queste conference organizzano le proprie competizioni e tornei durante la stagione.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Oltre alle divisioni e conference NCAA, ci sono anche altre leghe e organizzazioni che offrono l'hockey su ghiaccio universitario.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programmi di Punta e Squadre Prestigiose
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      L'hockey su ghiaccio universitario ha diversi programmi di punta e squadre prestigiose che si distinguono per il loro successo, tradizione, reputazione e influenza. Alcuni di questi programmi e squadre includono:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          University of North Dakota: Uno dei programmi di hockey su ghiaccio universitari più riusciti in America, che competono nella conference NCHC nella Divisione I. L'università ha vinto più campionati NCAA e ha prodotto numerosi giocatori che hanno giocato nella NHL, tra cui nomi famosi come Jonathan Toews, Zach Parise e T.J. Oshie.
        </li>
        <li>
          Boston College: Un programma prominente di hockey su ghiaccio universitario in America, che competono nella conference Hockey East nella Divisione I. Boston College ha diversi campionati nazionali al suo attivo. Il programma è anche noto per aver sviluppato oltre 80 giocatori della NHL, con ex allievi di rilievo come Johnny Gaudreau, Patrick Eaves e Brian Gionta.
        </li>
        <li>
          University of Minnesota: Questo storico programma di hockey su ghiaccio universitario, che competono nella conference Big Ten nella Divisione I, ha ottenuto un impressionante numero di campionati nazionali. È noto per aver prodotto un numero record di giocatori che hanno giocato nella NHL, tra cui stelle come Phil Kessel, Paul Martin e Blake Wheeler.
        </li>
        <li>
          University of Wisconsin: Uno dei programmi di hockey su ghiaccio universitari più prestigiosi in America, che competono nella conference Big Ten nella Divisione I. Wisconsin ha vinto più campionati nazionali ed ha anche allenato un numero significativo di giocatori che hanno giocato nella NHL, tra cui Ryan Suter, Joe Pavelski e Dany Heatley.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Questi programmi e squadre sono solo alcuni esempi dei molti che l'hockey su ghiaccio universitario ha da offrire. Ci sono molte altre squadre che hanno la propria storia, cultura e identità. L'hockey su ghiaccio universitario è uno sport diversificato e dinamico con molto da offrire ai giocatori e ai fan.
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  









<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 3: Dal College ai Professionisti
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    L'hockey su ghiaccio universitario non è solo uno sport; è anche una palestra di apprendimento. Molti giocatori che partecipano all'hockey su ghiaccio universitario hanno ambizioni di avanzare nel mondo professionale. In questo capitolo, discuteremo come l'hockey su ghiaccio universitario possa servire da trampolino di lancio per la NHL o altre leghe professionistiche, quali ex studenti hanno avuto successo nel mondo professionale dell'hockey su ghiaccio, e quali opportunità esistono al di là della NHL.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Il Percorso verso la Professionalità
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      L'hockey su ghiaccio universitario è un ottimo modo per prepararsi a una carriera nello sport. Giocando all'hockey su ghiaccio universitario, puoi migliorare le tue abilità, conoscenze ed esperienze mentre ottieni un diploma. L'hockey su ghiaccio universitario ti offre anche l'opportunità di competere contro altri giocatori talentuosi che ti sfidano e ti ispirano.
      <br />
      <br />
      Uno dei principali vantaggi dell'hockey su ghiaccio universitario è che aumenta la tua visibilità e esposizione. Giocare all'hockey su ghiaccio universitario può attirare l'attenzione di talent scout, allenatori e manager delle squadre professionistiche che cercano nuovi giocatori. Puoi anche partecipare a eventi, campus e cliniche per mostrare le tue abilità a potenziali datori di lavoro.
      <br />
      <br />
      Un altro vantaggio dell'hockey su ghiaccio universitario è che offre flessibilità e opzioni. Giocando all'hockey su ghiaccio universitario, puoi ritardare la decisione sul tuo futuro. Puoi scegliere di passare a una squadra professionistica dopo il primo, secondo, terzo o quarto anno, a seconda della tua situazione e preferenza. Puoi anche optare per completare i tuoi studi prima di diventare professionista.
      <br />
      <br />
      L'hockey su ghiaccio universitario ha prodotto molti giocatori che hanno avuto successo nel mondo professionale dell'hockey su ghiaccio. Alcuni di questi giocatori includono:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Jack Eichel: Ha giocato una stagione per la Boston University nella conference Hockey East della Divisione I. Ha vinto l'Hobey Baker Award come miglior giocatore dell'anno nel 2015. È stato selezionato al secondo posto nel Draft NHL del 2015 dai Buffalo Sabres, dove è diventato il capitano e il giocatore di punta. Nel 2023, ha vinto la Stanley Cup con i Vegas Golden Knights.
        </li>
        <li>
          Cale Makar: Ha giocato due stagioni per UMass Amherst nella conference Hockey East della Divisione I. Ha anche vinto l'Hobey Baker Award come miglior giocatore dell'anno nel 2019. È stato selezionato al quarto posto nel Draft NHL del 2017 dai Colorado Avalanche, dove attualmente gioca come uno dei migliori difensori della lega.
        </li>
        <li>
          Hilary Knight: Hilary Knight ha giocato quattro stagioni per l'Università del Wisconsin nella conference WCHA della Divisione I. Era una giocatrice altamente talentuosa e è stata nominata tra i primi 10 finalisti per il Patty Kazmaier Memorial Award nel 2009, 2011 e 2012. Anche se non ha vinto il premio, è stata due volte All-American di prima squadra. In seguito, Knight ha giocato per i Boston Pride nella NWHL (National Women's Hockey League) e per la nazionale degli Stati Uniti, con cui ha vinto due medaglie d'argento olimpiche e numerosi campionati mondiali.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Questi giocatori sono solo alcuni esempi dei molti che hanno trasformato le loro carriere nell'hockey su ghiaccio universitario in carriere professionali. L'hockey su ghiaccio universitario è un percorso comprovato verso la professionalità per molti giocatori.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Il Draft NHL e Altre Opportunità
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      La NHL (National Hockey League) è la principale lega professionistica di hockey su ghiaccio al mondo. È il sogno di molti giovani giocatori giocare un giorno nella NHL. Uno dei modi per realizzare questo sogno è attraverso il Draft NHL, l'evento annuale in cui le squadre NHL selezionano nuovi giocatori.
      <br />
      <br />
      Il Draft NHL è un momento emozionante e significativo per molti giocatori universitari. È un'opportunità di essere scelti da una squadra NHL, che ti offre un contratto per giocare per loro. Riconosce anche il tuo talento, il tuo potenziale e il tuo duro lavoro.
      <br />
      <br />
      Il Draft NHL consiste in sette turni in cui ogni squadra NHL seleziona a turno un giocatore. L'ordine di selezione è determinato da una lotteria, con le squadre che hanno ottenuto i peggiori risultati nella stagione precedente che hanno una migliore possibilità di selezionare per prime. Il Draft NHL di solito si tiene in giugno o luglio dopo la fine della stagione dell'hockey su ghiaccio universitario.
      <br />
      <br />
      Per essere ammessi al Draft NHL, è necessario soddisfare determinati criteri. Devi essere un giocatore nordamericano tra i 18 e i 20 anni o un giocatore internazionale tra i 18 e i 21 anni. Non devi essere stato precedentemente selezionato da una squadra NHL o aver rinunciato ai tuoi diritti.
      <br />
      <br />
      Essere selezionati da una squadra NHL non significa che devi immediatamente giocare per loro. Puoi scegliere di tornare alla tua squadra universitaria e completare i tuoi studi conservando i diritti della squadra NHL. Puoi anche scegliere di unirti alla squadra NHL o alla sua affiliate della lega minore, dove puoi ulteriormente svilupparti e allenarti.
      <br />
      <br />
      Tuttavia, il Draft NHL non è l'unico modo per arrivare nella NHL. Ci sono altre possibilità, come l'agenzia libera. Questo accade quando, come giocatore, non hai un contratto con una squadra NHL e sei libero di negoziare con qualsiasi squadra desideri. Questo può accadere se non sei stato selezionato o se il tuo contratto con una squadra NHL scade o viene terminato. Come free agent, puoi cercare di ottenere un contratto con una squadra NHL che è interessata a te.
      <br />
      <br />
      Tuttavia, la NHL non è l'unica lega professionistica di hockey su ghiaccio al mondo. Ci sono altre leghe in cui puoi giocare come giocatore e guadagnarti da vivere. Alcune di queste leghe includono:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          KHL (Kontinental Hockey League): Questa è la principale lega professionistica di hockey su ghiaccio in Europa e Asia. È composta da 24 squadre di otto paesi: Russia, Finlandia, Bielorussia, Kazakhstan, Cina, Lettonia, Slovacchia e Repubblica Ceca. Offre un alto livello di gioco e stipendi, attirando giocatori da vari paesi.
        </li>
        <li>
          SHL (Swedish Hockey League): Questa è la principale lega professionistica di hockey su ghiaccio in Svezia. È composta da 14 squadre che competono per il campionato nazionale. È nota per il suo gioco veloce e tecnico e ha prodotto molti giocatori che hanno giocato nella NHL.
        </li>
        <li>
          Liiga (Finnish Hockey League): Questa è la principale lega professionistica di hockey su ghiaccio in Finlandia. È composta da 15 squadre che competono per il campionato nazionale. È nota per il suo gioco fisico e tattico e ha anche prodotto molti giocatori che hanno giocato nella NHL.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Queste leghe sono solo alcuni esempi delle molte leghe in tutto il mondo. Ci sono molte altre leghe in altri paesi, tra cui Svizzera, Germania, Francia, Norvegia, Danimarca, Austria, Polonia, Giappone e Australia. Queste leghe offrono diversi livelli di gioco, stipendi e stili di vita ai giocatori.
      <br />
      <br />
      L'hockey su ghiaccio universitario, quindi, non è la fine della strada per molti giocatori. È invece l'inizio di un viaggio emozionante nel mondo professionale. L'hockey su ghiaccio universitario offre molte opportunità e opzioni per i giocatori che perseguono i loro sogni.
    </MKTypography>
  </MKBox>
</MKBox>
                                  






                                  




<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 4: Influenze Internazionali
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    L'hockey su ghiaccio universitario è uno sport che non è solo americano, ma anche internazionale. L'hockey su ghiaccio universitario è influenzato dalla presenza e dalle prestazioni dei giocatori stranieri nelle competizioni universitarie americane. L'hockey su ghiaccio universitario influisce anche sulle opportunità e sullo sviluppo dei giocatori americani all'estero. In questo capitolo, discuteremo del ruolo e dell'impatto dei giocatori internazionali nell'hockey su ghiaccio universitario e delle opportunità per i giocatori americani di giocare a livello internazionale.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Giocatori Stranieri nell'Hockey su Ghiaccio Universitario
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Giocatori di hockey su ghiaccio di Quinnipiac che festeggiano un gol."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Giocatori di Quinnipiac che festeggiano un gol
        </figcaption>
      </figure>
      L'hockey su ghiaccio universitario è uno sport aperto a giocatori provenienti da tutto il mondo. Ci sono molti giocatori stranieri che vengono in America per giocare all'hockey su ghiaccio universitario, con vari background, culture e nazionalità. Alcuni di questi giocatori includono:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Daryl Watts: È una giocatrice canadese di hockey su ghiaccio che ha giocato sia per il Boston College che per il Wisconsin nella NCAA Division I per due stagioni. Ha vinto il Patty Kazmaier Award nel 2018 da matricola, diventando così la prima matricola a vincere il premio. Watts ha poi giocato per i Toronto Six nella Premier Hockey Federation (PHF), precedentemente conosciuta come National Women’s Hockey League (NWHL), e ha firmato un contratto record con i Toronto Six nel 2023.
        </li>
        <li>
          Rod Brind'Amour: Nato in Canada, ha giocato all'hockey su ghiaccio universitario presso la Michigan State University prima di passare alla NHL. Ha avuto una carriera professionistica altamente riuscita, con momenti salienti tra cui la vittoria della Stanley Cup.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Questi giocatori sono solo alcuni esempi dei molti giocatori stranieri che giocano o hanno giocato all'hockey su ghiaccio universitario. Ci sono molti altri giocatori provenienti da altri paesi come Svezia, Russia, Repubblica Ceca, Germania, Francia, Svizzera, Danimarca, Giappone e Australia.
      <br />
      <br />
      Questi giocatori stranieri hanno un ruolo e un impatto importanti nell'hockey su ghiaccio universitario. Portano il loro stile, le loro abilità e la loro esperienza nelle competizioni universitarie americane. Arricchiscono il livello e la diversità del gioco. Creano anche più interesse e attenzione per l'hockey su ghiaccio universitario nei loro paesi e regioni.
      <br />
      <br />
      L'hockey su ghiaccio universitario è uno sport che beneficia delle influenze internazionali. È uno sport che accoglie e apprezza i giocatori provenienti da tutto il mondo.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  






<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 5: Borse di Studio Sportive e il Futuro degli Atleti
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le borse di studio sportive offrono numerose opportunità ai giovani atleti di mostrare il loro talento e sviluppare le loro abilità. Una delle opportunità più significative è ottenere una borsa di studio sportiva per studiare e competere a livello universitario. In questo capitolo, discuteremo dell'importanza delle borse di studio sportive e di come Sport Scholarships America può aiutarti a realizzare il tuo sogno.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 L'Importanza delle Borse di Studio
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Una borsa di studio sportiva è un sostegno finanziario fornito da un'università a uno studente-atleta per combinare studio e sport. Può coprire i costi di iscrizione, libri, alloggio, pasti e altre spese. Inoltre, può offrire vantaggi come il tutorato accademico, l'assistenza medica, le strutture di allenamento e l'attrezzatura.
      <br />
      <br />
      Ottenere una borsa di studio sportiva è competitivo, con molti fattori che influenzano il processo di assegnazione, tra cui risultati accademici, prestazioni sportive, personalità, motivazione, leadership e adattabilità. È quindi importante prepararsi bene e distinguersi dalla concorrenza.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Come Sport Scholarships America Può Aiutare
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo di Sport Scholarships America, raffigurante attrezzature sportive e una toga, simbolo del focus sulle borse di studio sportive per gli studenti-atleti."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo di Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America è un'organizzazione specializzata nell'orientamento dei giovani talenti sportivi verso borse di studio sportive. Con esperti appassionati ed esperti, Sport Scholarships America supporta gli studenti-atleti in ogni fase del percorso, dall'orientamento iniziale alla collocazione finale. I loro servizi includono:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Un'intervista personale per discutere i desideri, le possibilità e le aspettative dello studente-atleta.</li>
        <li>Una valutazione professionale del livello accademico e sportivo dello studente-atleta.</li>
        <li>Una vasta rete di contatti con allenatori, università e college.</li>
        <li>Promozione efficace del profilo e dei video dello studente-atleta presso allenatori potenziali.</li>
        <li>Consulenza esperta sulle migliori opzioni in base alle preferenze, agli obiettivi e al budget dello studente-atleta.</li>
        <li>Negoziazione delle migliori offerte di borse di studio per conto dello studente-atleta.</li>
        <li>Assistenza nella richiesta dei documenti necessari, come visto, trascrizione, diploma e test di ammissione.</li>
        <li>Preparazione alla vita dello studente-atleta durante la borsa di studio, compresa cultura, lingua, regole e consigli.</li>
        <li>Assistenza e monitoraggio durante il periodo di borsa di studio.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vuoi esplorare le opportunità di borse di studio e raggiungere i tuoi obiettivi sportivi e accademici? <Link to="/it/Servizi-Prodotti">Consulta la gamma completa</Link> dei servizi offerti da Sport Scholarships America e prenota una consultazione oggi stesso.
    </MKTypography>
  </MKBox>
</MKBox>

          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;