/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import React, { useState } from 'react'; // Step 1: Import useState
import { Link } from 'react-router-dom';


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Football1.jpg";
import Image2 from "assets/images/Sports_Images/Football2.webp";
import Image3 from "assets/images/Sports_Images/Football3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";
import "../../../../H1asH3.css"


// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Amerikanske Fodboldlegater i USA: Sådan Sikrer du Dig en Plads på et Universitetshold', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapitel 1: Historien og Udviklingen af Amerikansk Fodbold', isSubheader: false },
    { id: '1.1', title: '1.1 De Tidlige År', isSubheader: true },
    { id: '1.2', title: '1.2 Vækst og Professionalisering', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapitel 2: College Fodbold', isSubheader: false },
    { id: '2.1', title: '2.1 Store Ligaer og Konferencer', isSubheader: true },
    { id: '2.2', title: '2.2 Spillerudvikling', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapitel 3: Fra College til NFL', isSubheader: false },
    { id: '3.1', title: '3.1 Vejen til NFL', isSubheader: true },
    { id: '3.2', title: '3.2 Andre Professionelle Muligheder', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapitel 4: Påvirkningen af Amerikansk Fodbold på Amerikansk Kultur', isSubheader: false },
    { id: '4.1', title: '4.1 Kulturel Indflydelse', isSubheader: true },
    { id: '4.2', title: '4.2 Rollemodeller og Inspiration', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapitel 5: Sportslegater og fremtiden for atleter', isSubheader: false },
    { id: '5.1', title: '5.1 Vigtigheden af legater', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Legater Amerika Kan Hjælpe', isSubheader: true },
  ];
  
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>


























          <MKBox id="intro" mb={3}>
    <MKTypography variant="h1" className="h1-as-h3" mb={2}>
        Amerikanske Fodboldlegater i USA: Sådan Sikrer du Dig en Plads på et Universitetshold
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
            <img
                src={Image1}
                alt="Fugleperspektiv af en fyldt college-fodboldkamp på Indiana University, med spillere i aktion på banen og fans på lægterne."
                style={{
                    maxWidth: "100%",
                    border: "2px solid #fff",
                    boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                    cursor: "pointer",
                    outline: "none",
                }}
                onClick={() => openModal1(Image1)}
            />
            <figcaption style={{ 
                textAlign: 'left', 
                fontWeight: 'bold', 
                fontSize: '12px', 
                wordWrap: 'break-word', 
            }}>
                Indiana University-fodboldkamp fra lægterne.
            </figcaption>
        </figure>
        Amerikansk fodbold er en sport, der opstod i USA i slutningen af det 19. århundrede. Det er en holdsport, hvor to hold af elleve spillere forsøger at bringe en oval bold over modstanderens mållinje eller sparke den mellem målstolperne. Spillet er kendetegnet ved fysisk kontakt, taktik, strategi og spektakel.
        <br/>
        <br/>
        Amerikansk fodbold er ikke bare en sport; det er også en kultur. Det er en væsentlig del af den amerikanske identitet, værdier og traditioner. Det er en kilde til stolthed, lidenskab og inspiration for millioner af mennesker. Det er også en industri, der genererer milliarder af dollars og skaber tusindvis af job.
        <br/>
        <br/>
        Men før du kan nå det som professionel spiller i NFL, skal du vise dit talent på collegeniveau. College-fodbold er en vigtig del af spillerudviklingen og tilbyder unge atleter muligheden for at forbedre deres færdigheder, få en uddannelse og opbygge et netværk.
        <br/>
        <br/>
        Dog er college-fodbold ikke let tilgængelig for alle. Der er tusindvis af hold og spillere, der konkurrerer om et begrænset antal pladser og legater. Derudover er der mange regler og krav, du skal opfylde for at kvalificere dig til et universitetshold.
        <br/>
        <br/>
        Det er her, Sportbeursamerika.nl kan hjælpe dig. Jeg specialiserer mig i at matche unge talenter med universitetshold, der leder efter forstærkninger. Jeg kan hjælpe dig med at finde et sportslegat, arrangere din visa, forberede dig til dine optagelsesprøver og forhandle med trænere. Med min assistance kan du opfylde din drøm om at studere og spille i Amerika.
        <br/>
        <br/>
        Vil du vide hvordan? Læs videre!
    </MKTypography>
</MKBox>





{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapter 1: The History and Development of American Football" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 1: Historien og Udviklingen af Amerikansk Fodbold
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Amerikansk fodbold er en sport, der opstod i USA i slutningen af det 19. århundrede. Den er en efterkommer af rugby og fodbold, to sportsgrene der var populære i Europa. I dette kapitel vil vi fortælle dig mere om de tidlige år og væksten og professionaliseringen af amerikansk fodbold.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 De Tidlige År
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Oprindelsen af amerikansk fodbold tilskrives ofte en kamp mellem to universitetshold i 1869: Rutgers og Princeton. Dog mindede denne kamp mere om en form for fodbold end den moderne amerikanske fodbold. Der var 25 spillere pr. hold, bolden måtte ikke berøres med hænderne, og der var ingen målstolper.
      <br/>
      <br/>
      Den første kamp, der lignede moderne amerikansk fodbold, fandt sted i 1874 mellem Harvard og McGill. Denne kamp var en kombination af rugby og fodbold, hvilket tillod bolden at blive båret med hænderne og indeholdt målstolper. Harvard vandt kampen 3-0.
      <br/>
      <br/>
      Universiteternes rolle i dannelse af de første hold og ligaer var afgørende. De var pionererne, der definerede reglerne og stilen af spillet. I 1873 blev den første interkollegiale fodboldforening etableret, bestående af Yale, Columbia, Princeton og Harvard. I 1880 blev Walter Camp, en spiller og træner fra Yale, betragtet som "Faderen til Amerikansk Fodbold", fordi han introducerede betydelige innovationer, såsom at reducere antallet af spillere til 11, indførelse af scrimmage-linjen og nedsystemet.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Vækst og Professionalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Udviklingen af amerikansk fodbold til en national begivenhed begyndte i slutningen af det 19. og begyndelsen af det 20. århundrede. Spillet vandt popularitet blandt studerende, tilskuere og medierne. Det blev også stadig mere voldeligt og farligt, hvilket førte til talrige skader og endda dødsfald. I 1905 truede præsident Theodore Roosevelt med at forbyde spillet, hvis der ikke blev gennemført reformer. Dette førte til etableringen af ​​National Collegiate Athletic Association (NCAA) i 1906, en organisation ansvarlig for regulering og organisation af collegesport.
      <br/>
      <br/>
      Indflydelsen af professionel amerikansk fodbold på collegespillet var også betydelig. Den første professionelle amerikanske fodboldliga blev grundlagt i 1892, men blev virkelig succesfuld i 1920 med dannelse af American Professional Football Association (APFA), senere omdøbt til National Football League (NFL). NFL tiltrak mange talentfulde spillere væk fra colleges, hvilket tvang dem til at hæve deres niveau for at forblive konkurrencedygtige. NFL blev også en stor kilde til indtægt og eksponering for universiteterne, der ofte udlejede deres stadioner til professionelle hold.
      <br/>
      <br/>
      I dette kapitel har vi fortalt dig mere om historien og udviklingen af amerikansk fodbold. I det næste kapitel vil vi give yderligere indblik i amerikansk fodbold på universitetsniveau.
    </MKTypography>
  </MKBox>
</MKBox>







                                              





                                  








<MKBox id="Chapter 2" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Kapitel 2: College Fodbold
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        College fodbold er en af de mest populære og konkurrenceprægede sportsgrene i USA. Hvert år konkurrerer mere end tusind hold og hundredtusindvis af spillere om det nationale mesterskab, regionale mesterskaber og prestigefyldte skålspil. I dette kapitel vil vi fortælle dig mere om de store ligaer og konferencer inden for college fodbold og udviklingen af spillere på collegeniveau.
    </MKTypography>

    <MKBox id="2.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.1 Store Ligaer og Konferencer
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Et overblik over NCAA-divisionerne og de store konferencer inden for college fodbold. NCAA er organisationen, der er ansvarlig for regulering og organisering af collegesport i USA. NCAA opdeler holdene i tre divisioner: Division I, Division II og Division III. Division I er den højeste division og består af to underafdelinger: Football Bowl Subdivision (FBS) og Football Championship Subdivision (FCS). FBS-holdene er de mest prestigefyldte og indbringende hold, der kæmper om en plads i College Football Playoff, et system der har bestemt den nationale mester siden 2014. FCS-holdene er mindre kendte og velhavende, men har deres eget playoff-system til at afgøre deres mester.
            <br/>
            <br/>
            Inden for hver division og underafdeling er der forskellige konferencer, der er grupper af hold, der er geografisk eller historisk forbundet. Hver konference har sin egen tidsplan, regler og mesterskab. De store konferencer i FBS er: Atlantic Coast Conference (ACC), Big Ten Conference, Big 12 Conference, Pac-12 Conference, Southeastern Conference (SEC) og American Athletic Conference (AAC). De store konferencer i FCS er: Big Sky Conference, Colonial Athletic Association (CAA), Missouri Valley Football Conference (MVFC) og Southern Conference (SoCon).
            <br/>
            <br/>
            De mest prestigefyldte college-programmer og deres indflydelse på sporten. Nogle colleges har en lang og succesrig historie inden for college fodbold, der går tilbage til sportens tidlige år. De har produceret mange nationale titler, skålspil, individuelle priser og professionelle spillere. De har også mange fans, medieopmærksomhed og økonomiske ressourcer. Nogle eksempler på disse colleges er: Alabama, Notre Dame, Ohio State, Oklahoma, USC, Michigan, Texas, Clemson, Florida State og LSU.
        </MKTypography>
    </MKBox>
    <MKBox id="2.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.2 Spillerudvikling
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Hvordan colleg hold bidrager til spillernes tekniske og fysiske udvikling. Colleg-hold tilbyder unge spillere muligheden for at forbedre deres færdigheder under vejledning af erfarne trænere og personale. De giver også adgang til faciliteter af høj kvalitet, udstyr og lægehjælp. Derudover udsætter de spillere for et højt niveau af konkurrence, hvor de står over for andre talentfulde spillere fra hele landet.
            <br/>
            <br/>
            Eksempler på berømte spillere, der har slebet deres færdigheder på collegeniveau. Mange professionelle spillere begyndte deres karriere på collegeniveau, hvor de udmærkede sig med deres præstationer og potentiale. Nogle spillere har endda vundet priser som Heisman Trophy, den mest prestigefyldte individuelle pris inden for college fodbold. Eksempler på disse spillere inkluderer: Tom Brady (Michigan), Peyton Manning (Tennessee), Joe Montana (Notre Dame), Barry Sanders (Oklahoma State), Jerry Rice (Mississippi Valley State) og Tim Tebow (Florida).
            <br/>
            <br/>
            I dette kapitel har vi fortalt dig mere om college fodbold. I det næste kapitel vil vi fortælle dig mere om vejen til NFL og andre professionelle muligheder.
        </MKTypography>
    </MKBox>
</MKBox>










                                              






                                  









<MKBox id="Chapter 3" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Kapitel 3: Fra College til NFL
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        College fodbold er et springbræt til National Football League (NFL), den højeste professionelle liga i verden. Hvert år bliver hundredvis af spillere udvalgt af NFL-hold i NFL Draft, en begivenhed hvor holdene skiftes til at vælge en spiller fra en pulje af collegespillere. I dette kapitel vil vi fortælle dig mere om vejen til NFL og andre professionelle muligheder.
    </MKTypography>

    <MKBox id="3.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.1 Vejen til NFL
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Hvordan college fodbold fungerer som et springbræt til National Football League (NFL). College fodbold er den primære kilde til talent for NFL. De fleste spillere i NFL har først spillet på collegeniveau. College fodbold giver spillere muligheden for at bevise sig selv mod andre topatleter, udvikle deres færdigheder, forbedre deres statistikker og øge deres draftværdi. College fodbold giver også spillere eksponering og anerkendelse af scouts, trænere og medier.
            <br/>
            <br/>
            Cirka 250 spillere vælges hvert år i NFL Draft, som normalt finder sted i april. Udkastet består af syv runder, hver med 32 valg (et for hvert hold). Rækkefølgen af valgene bestemmes af resultatet af den foregående sæson, hvor det dårligste hold vælger først og det bedste hold sidst. Udkastet er en spændende og uforudsigelig begivenhed, hvor nogle spillere bliver valgt højere eller lavere end forventet, og nogle slet ikke bliver valgt. Eksempler på berømte spillere udvalgt i NFL Draft inkluderer: John Elway (første valg i 1983), Troy Aikman (første valg i 1989), Peyton Manning (første valg i 1998), Tom Brady (199. valg i 2000), Aaron Rodgers (24. valg i 2005) og Patrick Mahomes (10. valg i 2017).
        </MKTypography>
    </MKBox>
    <MKBox id="3.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.2 Andre Professionelle Muligheder
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Alternative veje til en professionel karriere inden for amerikansk fodbold uden for NFL. Ikke alle spillere, der spiller på collegeniveau, når NFL. Nogle spillere bliver ikke valgt i udkastet, eller bliver valgt men formår ikke at sikre en plads på et hold. Nogle spillere bliver skadet eller mister deres motivation eller lidenskab. Men det betyder ikke, at de behøver at opgive deres drøm om at spille professionelt. Der er andre professionelle muligheder uden for NFL, såsom:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Canadian Football League (CFL): Dette er en professionel liga bestående af ni hold, der spiller i Canada. CFL har nogle forskelle fra NFL, såsom en større bane, flere spillere pr. hold og færre downs pr. drive. CFL tiltrækker mange spillere, der ikke kan spille i NFL, men stadig ønsker at spille på et højt niveau.
                </li>
                <li>
                    XFL: Dette er en ny professionel liga etableret i 2020 som et alternativ til NFL. XFL består af otte hold, der spiller i USA. XFL sigter mod at tilbyde et hurtigere, mere spændende og mere innovativt spil end NFL. XFL tilbyder også flere muligheder for spillere, der er blevet overset eller ignoreret af NFL.
                </li>
                <li>
                    European League of Football (ELF): Dette er en ny professionel liga etableret i 2021 som et forsøg på at fremme og udvikle amerikansk fodbold i Europa. ELF består af otte hold, der spiller i Tyskland, Polen, Spanien og Frankrig. ELF sigter mod at tilbyde en platform for europæiske spillere til at vise deres talent og vokse som atleter.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            I dette kapitel har vi fortalt dig mere om vejen til NFL og andre professionelle muligheder. I det næste kapitel vil vi fortælle dig mere om amerikansk fodbolds indflydelse på amerikansk kultur.
        </MKTypography>
    </MKBox>
</MKBox>

                                              






                                  



<MKBox id="Chapter 4" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Kapitel 4: Påvirkningen af Amerikansk Fodbold på Amerikansk Kultur
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Amerikansk fodbold er ikke bare en sport, men også en kultur. Det er en betydelig del af den amerikanske identitet, værdier og traditioner. Det er en kilde til stolthed, lidenskab og inspiration for millioner af mennesker. Det er også en industri, der genererer milliarder af dollars og skaber tusinder af job. I dette kapitel vil vi fortælle dig mere om den kulturelle indflydelse af amerikansk fodbold, og de rollemodeller og inspiration, den giver.
    </MKTypography>

    <MKBox id="4.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.1 Kulturel Indflydelse
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image3}
                    alt="Udsigt fra tribunerne på den livlige og fyldte Wisconsin-college-fodboldstadion under en spændende kamp, med fans der hepper på deres hold."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal3(Image3)}
                />
                <figcaption style={{ 
                    textAlign: 'left', 
                    fontWeight: 'bold', 
                    fontSize: '12px', 
                    wordWrap: 'break-word', 
                }}>
                    Kampdag på det fyldte Wisconsin-stadion
                </figcaption>
            </figure>
            Betydningen af amerikansk fodbold i amerikansk samfund og kultur. Amerikansk fodbold er en af de mest populære og indflydelsesrige sportsgrene i USA. Den tiltrækker millioner af fans, tilskuere og medier hvert år. Det er en del af mange amerikaneres daglige liv, uddannelse, politik, økonomi og underholdning. Det er også et symbol på national enhed, mangfoldighed og stolthed.
            <br/>
            <br/>
            Store begivenheder og deres indflydelse på national identitet, såsom Super Bowl. Super Bowl er årligt mesterskab i NFL, som normalt afholdes i februar. Det er den største og mest sete sportsbegivenhed i verden, med over 100 millioner seere alene i USA. Super Bowl er mere end en sportsbegivenhed; det er et kulturelt fænomen. Det er en mulighed for familie, venner og kolleger at samles, feste, satse og heppe. Det er også en scene for musik, reklamer, kunst og politik. Super Bowl ses ofte som en refleksion af amerikansk kultur, værdier og tendenser.
        </MKTypography>
    </MKBox>
    <MKBox id="4.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.2 Rollemodeller og Inspiration
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Amerikansk fodboldspillere er ofte rollemodeller og kilder til inspiration for unge atleter, der forfølger deres drøm om at spille på college- eller professionelt niveau. Nogle spillere har ekstraordinære historier om modgang, udholdenhed og succes, der motiverer og inspirerer andre. Nogle spillere påvirker også positivt deres samfund, fans og land gennem deres handlinger, ord og gerninger. Eksempler på sådanne spillere inkluderer: Colin Kaepernick (der knælede under nationalhymnen for at øge opmærksomheden omkring racial uretfærdighed), Russell Wilson (der førte sit hold til Super Bowl som underdog), JJ Watt (der rejste millioner af dollars til ofrene for orkanen Harvey), Drew Brees (der hjalp sin by New Orleans med at komme sig efter orkanen Katrina), og Tom Brady (der stadig spiller på et topniveau i en alder af 43 år).
            <br/>
            <br/>
            I dette kapitel har vi fortalt dig mere om påvirkningen af amerikansk fodbold på amerikansk kultur. I det næste kapitel vil vi fortælle dig mere om sportslegater og fremtiden for amerikansk fodbold.
        </MKTypography>
    </MKBox>
</MKBox>












                                              






                                  

<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Sportslegater og fremtiden for atleter
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sportslegater tilbyder talrige muligheder for unge atleter til at vise deres talent og udvikle deres færdigheder. En af de mest betydningsfulde muligheder er at opnå et sportslegat for at studere og konkurrere på kollegialt niveau. I dette kapitel vil vi diskutere vigtigheden af sportslegater og hvordan Sport Legater Amerika kan hjælpe dig med at realisere din drøm.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Vigtigheden af legater
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et sportslegat er økonomisk støtte, som et universitet tilbyder til en studerende-atlet for at kombinere studier og sport. Det kan dække omkostningerne til undervisning, bøger, bolig, måltider og andre udgifter. Derudover kan det også tilbyde fordele såsom akademisk vejledning, lægehjælp, træningsfaciliteter og udstyr.
      <br />
      <br />
      At opnå et sportslegat er konkurrencepræget, hvor mange faktorer spiller en rolle i tildelingsprocessen, herunder akademiske præstationer, atletisk præstation, personlighed, motivation, lederskab og tilpasningsevne. Derfor er det vigtigt at forberede sig godt og adskille sig fra konkurrencen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Legater Amerika Kan Hjælpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo af Sport Legater Amerika, der viser sportsudstyr og en eksamenshue, der repræsenterer fokus på sportslegater for studerende-atleter."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo af Sport Legater Amerika
        </figcaption>
      </figure>
      Sport Legater Amerika er en organisation specialiseret i at guide unge sports talenter til sportslegater. Med passionerede og erfarne eksperter støtter Sport Legater Amerika studerende-atleter på hvert trin af rejsen, fra indledende orientering til endelig placering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig intake-interview for at diskutere den studerende-athlets ønsker, muligheder og forventninger.</li>
        <li>En professionel evaluering af den studerende-athlets atletiske og akademiske niveau.</li>
        <li>Et omfattende netværk af kontakter med trænere, universiteter og kollegier.</li>
        <li>Effektiv markedsføring af den studerende-athlets profil og videoer til potentielle trænere.</li>
        <li>Ekspertrådgivning om de bedste muligheder baseret på den studerende-athlets præferencer, mål og budget.</li>
        <li>Forhandling af de bedste legat tilbud på vegne af den studerende-atlet.</li>
        <li>Hjælp til ansøgning om nødvendige dokumenter, såsom visum, transkript, eksamensbevis og optagelsesprøver.</li>
        <li>Forberedelse til den studerende-atlets liv under deres legat, herunder kultur, sprog, regler og tips.</li>
        <li>Efterpleje og opfølgning under legatperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ønsker du at udforske legatmuligheder og opnå dine sportslige og akademiske mål? <Link to="/da/Tjenester-Produkter">Se det fulde udvalg</Link> af tjenester tilbudt af Sport Legater Amerika og planlæg en konsultation i dag.
    </MKTypography>
  </MKBox>
</MKBox>


          
        









         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;