/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState } from 'react'; // Step 1: Import useState


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";


// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Logos/NCAA.png";
import post3 from "assets/images/Logos/NJCAA.png";



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {

  const articleStructure = [
    { id: 'intro', title: 'A NAIA: Uma Rota Alternativa no Esporte Universitário na América', isSubheader: false },
  
    { id: 'Hoofdstuk 1', title: 'A Origem e Desenvolvimento da NAIA', isSubheader: true },
    { id: '1.1', title: 'A NAIA e Suas Divisões Únicas', isSubheader: true },
    { id: '1.2', title: 'A Missão Acadêmica', isSubheader: true },
  
    { id: 'Hoofdstuk 2', title: 'Finanças, Mídia e Visibilidade', isSubheader: true },
    { id: '2.1', title: 'Desafios e o Caminho a Seguir', isSubheader: true },
    { id: '2.2', title: 'Para Concluir', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
     <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contents
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Esconder</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="intro" mb={3}>
    <MKTypography variant="h1" className="h1-as-h3" mb={2}>A NAIA: Uma Rota Alternativa no Esporte Universitário na América

    </MKTypography>
</MKBox>
<MKBox id="Hoofdstuk 1" mb={3}>

    <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
        <br />
        A Origem e Desenvolvimento da NAIA
    </MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text">
    A National Association of Intercollegiate Athletics, ou NAIA, é uma entidade crucial no cenário esportivo universitário americano, embora seja menos conhecida do que a NCAA. Fundada em 1937, a NAIA se tornou uma organização renomada que oferece aos estudantes-atletas uma oportunidade única de realizar suas aspirações esportivas e acadêmicas.

    <br />
    <br />
    Desde o início, a NAIA tem se concentrado em oferecer oportunidades para instituições de ensino menores, com ênfase na criação de uma experiência equilibrada para os estudantes-atletas. A organização promove tanto esportes competitivos quanto excelência acadêmica, com a convicção de que os dois podem andar juntos.

</MKTypography>
<MKBox id="1.1" mb={3}>

    <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
        <br />
        A NAIA e Suas Divisões Únicas
    </MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text">
    Ao contrário da NCAA, a NAIA é mais compacta, com uma divisão em duas divisões que oferecem uma rica mistura de esportes e educação:

    <br /><br />
    <MKTypography component="span" variant="body1" fontWeight="bold">
        Divisão I:{' '}
    </MKTypography>
    Nesta divisão, encontramos escolas que oferecem um nível mais alto de bolsas de estudo atléticas e onde a competição esportiva é muito acirrada. Apesar do tamanho menor das escolas em comparação com as instituições da Divisão I da NCAA, a Divisão I da NAIA oferece um palco para os principais atletas mostrarem suas habilidades e se desenvolverem.
    <br />
    <br />
    <MKTypography component="span" variant="body1" fontWeight="bold">
        Divisão II:{' '}
    </MKTypography>
    Esta divisão enfatiza ainda mais o equilíbrio entre esporte, acadêmico e envolvimento no campus. As bolsas de estudo atléticas são mais limitadas, mas as oportunidades para os estudantes-atletas se destacarem tanto em seus esportes quanto em seus estudos são abundantes.
</MKTypography>

<MKBox id="1.2" mb={3}>

    <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
        <br />
        A Missão Acadêmica
    </MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text">
    A NAIA valoriza muito o desempenho acadêmico de seus estudantes-atletas. Normas acadêmicas rigorosas foram estabelecidas para garantir a participação nos esportes, e as escolas afiliadas estão comprometidas em fornecer o apoio acadêmico necessário. Isso resulta em um ambiente onde os estudantes-atletas são incentivados a buscar a excelência em todos os aspectos de suas vidas.

    <br />
</MKTypography>

                                  







<MKBox id="Hoofdstuk 2" mb={3}>

    <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
        <br />
        Finanças, Mídia e Visibilidade
    </MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
    Embora a NAIA, financeiramente falando, talvez não consiga competir com as enormes receitas da NCAA, ela ainda desempenha um papel importante no financiamento de bolsas de estudo atléticas e na melhoria das instalações. Além disso, a NAIA ganha visibilidade graças aos serviços de streaming e às redes sociais, o que ajuda a disseminar a mensagem de um equilíbrio entre esportes e acadêmicos.

    <br />
</MKTypography>

<MKBox id="2.1" mb={3}>

    <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
        <br />
        Desafios e o Caminho a Seguir
    </MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
    Assim como a NCAA, a NAIA enfrenta desafios, incluindo a navegação pela complexidade do status amador dos estudantes-atletas e a garantia de igualdade nos esportes. No entanto, com sua posição única e dedicação ao desenvolvimento dos estudantes-atletas, a NAIA está bem posicionada para continuar tendo um impacto positivo nas vidas de muitos jovens atletas.

</MKTypography>

<MKBox id="2.2" mb={3}>

    <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
        <br />
        Para Concluir
    </MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
    A Associação Nacional de Atletismo Intercolegial oferece uma alternativa valiosa e única no mundo do esporte universitário americano. Com seu foco em equilíbrio, excelência acadêmica e competição atlética, a NAIA continua sendo uma força essencial na moldagem do futuro dos estudantes-atletas. Enquanto olha para o futuro e continua evoluindo, seu compromisso com o desenvolvimento dos jovens permanece inabalável, proporcionando um impacto duradouro e positivo no mundo do esporte e da educação nos Estados Unidos.

    <br />
</MKTypography>


              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="NCAA"
                    description="NCAA: O Coração do Esporte Universitário na América"
                    action={{
                      type: "internal",
                      route: "/pt/NCAA",
                      color: "info",
                      label: "Leia Mais",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="NJCAA"
                    description="NJCAA: A Porta de Entrada para Grandes Universidades e a NCAA"
                    action={{
                      type: "internal",
                      route: "/pt/NJCAA",
                      color: "info",
                      label: "Leia Mais",
                    }}
                  />
                </Grid>
              </Grid>

            </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;