/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Baseball1.jpg";
import Image2 from "assets/images/Sports_Images/Baseball2.jpeg";
import Image3 from "assets/images/Sports_Images/Baseball3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';

import "../../../../H1asH3.css"

const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Béisbol y Estudiar en Estados Unidos: Un Sueño que Puede Hacerse Realidad', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capítulo 1: Historia y Desarrollo del Béisbol Universitario', isSubheader: false },
    { id: '1.1', title: '1.1 Primeros Años y el Rol de las Universidades', isSubheader: true },
    { id: '1.2', title: '1.2 Crecimiento y Profesionalización', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capítulo 2: El Béisbol Universitario Hoy', isSubheader: false },
    { id: '2.1', title: '2.1 Ligas Principales y Conferencias', isSubheader: true },
    { id: '2.2', title: '2.2 Programas Destacados y Equipos Prestigiosos', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capítulo 3: Desde la Universidad hasta las Grandes Ligas', isSubheader: false },
    { id: '3.1', title: '3.1 El Camino hacia el Profesionalismo', isSubheader: true },
    { id: '3.2', title: '3.2 El Draft de la MLB y Otras Oportunidades Profesionales', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capítulo 4: Influencias Internacionales', isSubheader: false },
    { id: '4.1', title: '4.1 Jugadores Extranjeros en el Béisbol Universitario', isSubheader: true },
    { id: '4.2', title: '4.2 Estadounidenses en el Extranjero', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capítulo 5: Becas Deportivas y el Futuro de los Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 La Importancia de las Becas', isSubheader: true },
    { id: '5.2', title: '5.2 Cómo Puede Ayudar Sport Scholarships America', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Contenidos
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Ocultar</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























{/* Sección de introducción sobre el béisbol universitario */}
<MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Béisbol y Estudiar en Estados Unidos: Un Sueño que Puede Hacerse Realidad
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Vista aérea del estadio durante la Serie Mundial Universitaria."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '12px',
        wordWrap: 'break-word',
      }}>
        La Serie Mundial Universitaria
      </figcaption>
    </figure>
    El béisbol es uno de los deportes más populares y significativos en Estados Unidos. Millones de personas siguen la Major League Baseball (MLB), la liga de béisbol profesional más importante del mundo. Pero antes de llegar a la MLB, primero debes desarrollar y demostrar tus habilidades en otro nivel: el béisbol universitario.
    <br />
    <br />
    El béisbol universitario es el béisbol practicado por estudiantes en universidades y colegios estadounidenses. Es un deporte altamente competitivo y prestigioso que atrae mucha atención y talento. El béisbol universitario sirve como un paso crucial para los jugadores que buscan jugar profesionalmente y también como una oportunidad para recibir una buena educación.
    <br />
    <br />
    Pero, ¿cómo puedes entrar al béisbol universitario? ¿Y cómo puedes obtener una beca para estudiar y jugar en Estados Unidos? Aquí es donde Sportbeursamerika.nl puede ayudarte. Sportbeursamerika.nl es una organización especializada en guiar y apoyar a estudiantes-atletas que quieren hacer realidad sus sueños. Puedo ayudarte con todo lo que necesitas para encontrar una universidad adecuada, obtener una beca y prepararte para la vida en Estados Unidos.
    <br />
    <br />
    En este artículo, te contaremos todo lo que necesitas saber sobre el béisbol universitario en Estados Unidos. Profundizaremos en la historia y desarrollo de este deporte, las ligas y conferencias clave, los programas principales y equipos prestigiosos, el camino hacia los profesionales, las influencias internacionales y, por supuesto, la importancia de las becas. Después de leer este artículo, tendrás una mejor comprensión de lo que implica el béisbol universitario y cómo puedes aumentar tus posibilidades de ser parte de él.
    <br />
    <br />
    ¿Estás listo para hacer realidad tu sueño? ¡Comencemos!
  </MKTypography>
</MKBox>

{/* Capítulo 1: Historia y Desarrollo del Béisbol Universitario */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 1: Historia y Desarrollo del Béisbol Universitario
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El béisbol es un deporte que ha existido por más de un siglo. Pero, ¿cómo se originó y creció para convertirse en uno de los deportes más populares y significativos en los Estados Unidos? En este capítulo, te proporcionaremos un breve resumen de la historia y desarrollo del béisbol universitario.
  </MKTypography>

  {/* Sección 1.1: Primeros Años y el Rol de las Universidades */}
  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Primeros Años y el Rol de las Universidades
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      El origen del béisbol no está completamente claro. Hay varias teorías sobre cómo y dónde comenzó el juego. Algunos dicen que descendió de antiguos juegos europeos como el cricket, el rounders o el stoolball. Otros afirman que se originó a partir de un juego de los nativos americanos llamado lacrosse. Aun otros sugieren que fue inventado por un soldado estadounidense llamado Abner Doubleday en 1839.
      <br />
      <br />
      Lo que es cierto es que el béisbol rápidamente se volvió popular en los Estados Unidos, especialmente en ciudades de la Costa Este. Fue jugado por aficionados, clubes, trabajadores de fábricas y soldados. También fue jugado por estudiantes en universidades y colegios, quienes tenían sus propios equipos y reglas.
      <br />
      <br />
      El primer juego intercolegial de béisbol conocido en los Estados Unidos tuvo lugar en 1859 entre el Amherst College y el Williams College, dos respetadas instituciones académicas en Massachusetts. Este juego es históricamente significativo como uno de los primeros ejemplos de juegos de béisbol a nivel universitario, un precursor del posterior desarrollo del béisbol universitario. Aunque el resultado exacto y el curso del juego pueden variar en las fuentes históricas, este evento marca un punto de partida importante en la historia del béisbol intercolegial. Ilustra cómo el béisbol se volvió popular entre los estudiantes en los primeros días y cómo las universidades se convirtieron en lugares importantes para el crecimiento y desarrollo del deporte en los Estados Unidos.
      <br />
      <br />
      En las décadas de 1860 y 1870, el número de equipos universitarios creció constantemente. Algunos de los primeros equipos incluyeron a Harvard, Yale, Princeton, Dartmouth, Brown y Columbia. Estos equipos principalmente jugaron entre sí o contra clubes locales.
      <br />
      <br />
      En 1879, se celebró la primera conferencia intercolegial de béisbol en Springfield, Massachusetts. El objetivo era estandarizar y mejorar las reglas y estándares del juego. La conferencia llevó a la formación de la Asociación de Béisbol Colegiado Estadounidense, con seis prestigiosas instituciones educativas: Harvard, Yale, Princeton, Amherst, Dartmouth y Brown. Esto marcó el comienzo de una larga tradición de campeonatos nacionales en el béisbol universitario.
    </MKTypography>
  </MKBox>

  {/* Sección 1.2: Crecimiento y Profesionalización */}
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crecimiento y Profesionalización
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      A finales del siglo XIX y principios del siglo XX, el béisbol se volvió cada vez más popular y profesional en los Estados Unidos. La Liga Nacional (NL) y la Liga Americana (AL) fueron establecidas como las principales ligas profesionales de béisbol, atrayendo a muchos jugadores, aficionados y dinero.
      <br />
      <br />
      Esto también tuvo un impacto en el béisbol universitario. Muchos jugadores universitarios fueron reclutados o firmados por equipos profesionales. Algunos dejaron sus universidades para jugar profesionalmente, mientras que otros continuaron sus estudios pero también jugaron a tiempo parcial para equipos profesionales.
      <br />
      <br />
      Esto llevó a varios problemas y conflictos en el béisbol universitario. Algunas universidades se opusieron a la idea de que sus jugadores fueran pagados por jugar, considerándolo contrario al espíritu amateur y la integridad académica de los deportes universitarios. También querían retener a sus mejores jugadores para sus propios equipos.
      <br />
      <br />
      Otras universidades fueron más tolerantes o pragmáticas sobre el fenómeno de los jugadores profesionales. Reconocieron que el béisbol era un deporte lucrativo y prestigioso que podría proporcionar muchos beneficios a sus instituciones. También querían competir con otras universidades al más alto nivel.
      <br />
      <br />
      Para abordar estos problemas, se tomaron diversas medidas por parte de diferentes organizaciones. La Asociación Intercolegial de Béisbol (IBA) fue reemplazada por la Asociación Nacional Atlética Colegiada (NCAA) en 1906. La NCAA asumió la responsabilidad de regular y organizar el béisbol universitario y otros deportes. La NCAA también estableció reglas y pautas para la elegibilidad y el comportamiento de los jugadores universitarios.
      <br />
      <br />
      La NCAA también organizó la primera Serie Mundial Universitaria (CWS) en 1947. Este fue el nuevo campeonato nacional de béisbol universitario, celebrado anualmente en Omaha, Nebraska. La CWS rápidamente se convirtió en uno de los eventos más prestigiosos y populares en los deportes universitarios.
      <br />
      <br />
      Además de la NCAA, hubo otras organizaciones involucradas en el béisbol universitario. Una de ellas fue la Asociación Nacional de Atletismo Intercolegial (NAIA), establecida en 1937. La NAIA fue una alternativa a la NCAA, enfocándose en universidades y colegios más pequeños. La NAIA también organizó su propio campeonato nacional de béisbol universitario.
      <br />
      <br />
      Otra organización fue la Asociación Atlética de Colegios Comunitarios (NJCAA), fundada en 1938. La NJCAA fue una organización para colegios comunitarios que ofrecían programas de dos años. La NJCAA también organizó su propio campeonato nacional de béisbol universitario.
      <br />
      <br />
      Estas organizaciones contribuyeron al crecimiento y profesionalización del béisbol universitario. Crearon más oportunidades y diversidad para jugadores, entrenadores, escuelas y aficionados. También hicieron del béisbol universitario una parte integral del sistema de béisbol estadounidense.
    </MKTypography>
  </MKBox>
</MKBox>

                                              





                                  






{/* Capítulo 2: El Béisbol Universitario Hoy */}
<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 2: El Béisbol Universitario Hoy
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El béisbol universitario es uno de los deportes más populares y significativos en los Estados Unidos hoy en día. Atrae a millones de aficionados, atención de los medios y patrocinadores. Produce miles de jugadores, entrenadores y oficiales. Ofrece cientos de becas, premios y honores.
    <br />
    <br />
    Pero, ¿cómo es el béisbol universitario hoy en día? ¿Cómo está organizado el sistema? ¿Cuáles son las ligas y conferencias principales? ¿Cuáles son los programas destacados y los equipos prestigiosos? En este capítulo, te proporcionaremos un resumen del béisbol universitario hoy en día.
  </MKTypography>

  {/* Sección 2.1: Ligas Principales y Conferencias */}
  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Ligas Principales y Conferencias
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Barry Bonds en acción para Arizona State en el béisbol universitario."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Barry Bonds como jugador para Arizona State
        </figcaption>
      </figure>
      Como vimos en el capítulo anterior, varias organizaciones están involucradas en el béisbol universitario. La más importante de estas es la NCAA, que representa a más de 1,200 universidades y colegios. La NCAA está dividida en tres divisiones: División I, División II y División III.
      <br />
      <br />
      La División I es el nivel más alto del béisbol universitario, compuesta por aproximadamente 300 escuelas, divididas en 31 conferencias. Cada conferencia tiene sus propias reglas, horarios y campeonatos. Las conferencias más conocidas incluyen la Conferencia de la Costa Atlántica (ACC), la Conferencia del Big Ten (B1G), la Conferencia del Big 12 (B12), la Conferencia Pac-12 (P12) y la Conferencia del Sureste (SEC).
      <br />
      <br />
      La División II es el nivel intermedio del béisbol universitario, compuesta por aproximadamente 270 escuelas, divididas en 23 conferencias. Las conferencias suelen estar orientadas regionalmente, como la Asociación Atlética Colegial de California (CCAA), la Conferencia del Golfo Sur (GSC) y la Conferencia del Noreste-10 (NE10).
      <br />
      <br />
      La División III es el nivel más bajo del béisbol universitario, compuesta por aproximadamente 380 escuelas, divididas en 43 conferencias. Las conferencias también suelen estar orientadas regionalmente, como la Conferencia Atlética Colegial de Nueva Inglaterra (NESCAC), la Conferencia Atlética Colegial del Sur de California (SCIAC) y la Conferencia Atlética Colegial de Wisconsin (WIAC).
      <br />
      <br />
      Además de la NCAA, hay otras organizaciones involucradas en el béisbol universitario. Una de ellas es la NAIA, que representa aproximadamente 190 escuelas. La NAIA está dividida en 21 conferencias, como la Conferencia Atlética Centroamericana (AMC), la Conferencia Atlética Dorada del Estado (GSAC) y la Conferencia Atlética del Corazón de América (HAAC).
      <br />
      <br />
      Otra organización es la NJCAA, que representa aproximadamente 500 colegios comunitarios. La NJCAA está dividida en tres divisiones: División I, División II y División III. Cada división está subdividida en distritos y regiones.
      <br />
      <br />
      Estas organizaciones celebran sus propios campeonatos nacionales de béisbol universitario cada año. El campeonato de la División I de la NCAA es el evento más conocido y prestigioso, a menudo llamado Serie Mundial Universitaria (CWS).
      <br />
      <br />
      La CWS consiste en un torneo de doble eliminación de 64 equipos seleccionados en función de su desempeño en la temporada regular y los campeonatos de conferencia. Los equipos se dividen en 16 grupos regionales de cuatro equipos cada uno. Los ganadores de cada grupo regional avanzan a la ronda super regional, donde compiten contra otro equipo en una serie al mejor de tres juegos. Los ganadores de cada ronda super regional avanzan al torneo final de la CWS, que consiste en dos grupos de cuatro equipos cada uno. Los ganadores de cada grupo se enfrentan en una serie al mejor de tres juegos para determinar el campeón nacional.
      <br />
      <br />
      El torneo final de la CWS se celebra anualmente en junio en Omaha, Nebraska. Se considera uno de los eventos más grandes y emocionantes en los deportes universitarios, atrayendo a miles de aficionados, medios de comunicación y cazatalentos.
      <br />
      <br />
      Los campeonatos de la División II y la División III de la NCAA siguen un formato similar pero con menos equipos. Los campeonatos de la NAIA y la NJCAA también tienen sus propios formatos, que varían según el número de equipos participantes.
    </MKTypography>
  </MKBox>

  {/* Sección 2.2: Programas Destacados y Equipos Prestigiosos */}
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programas Destacados y Equipos Prestigiosos
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      El béisbol universitario es un deporte altamente competitivo y prestigioso con una rica tradición y un gran talento. Hay muchos programas y equipos que se distinguen por su éxito y reputación. Algunos de estos programas y equipos incluyen:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Texas Longhorns: El equipo de béisbol de la Universidad de Texas en Austin, compitiendo en la Conferencia del Big 12. Este programa tiene un historial impresionante con numerosas apariciones en la Serie Mundial Universitaria y múltiples campeonatos. Algunos exalumnos destacados incluyen a Roger Clemens, Burt Hooton y Huston Street.
        </li>
        <li>
          LSU Tigers: El equipo de béisbol de la Universidad Estatal de Luisiana en Baton Rouge, activo en la SEC. LSU es conocido por su dominio en el béisbol universitario y ha ganado múltiples campeonatos. Algunos exalumnos destacados incluyen a Albert Belle, Aaron Hill y DJ LeMahieu.
        </li>
        <li>
          USC Trojans: El equipo de béisbol de la Universidad del Sur de California en Los Ángeles, miembro de la Conferencia Pac-12. USC tiene una rica tradición con numerosos campeonatos y ha producido muchos jugadores talentosos. Algunos exalumnos famosos incluyen a Tom Seaver, Randy Johnson y Mark McGwire.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Estos son solo algunos ejemplos de programas destacados y equipos prestigiosos en el béisbol universitario. Hay muchos más programas y equipos, cada uno con su propia historia, cultura e identidad.
      <br />
      <br />
      Estos programas y equipos contribuyen al desarrollo de los jugadores de diversas maneras. Les proporcionan un alto nivel de entrenamiento, instalaciones y competencia. También les ayudan a alcanzar sus metas académicas, atléticas y personales.
    </MKTypography>
  </MKBox>
</MKBox>
      






                                  








{/* Capítulo 3: Desde la Universidad hasta las Grandes Ligas */}
<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 3: Desde la Universidad hasta las Grandes Ligas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El béisbol universitario no es solo un deporte; también es un camino hacia el profesionalismo. Muchos jugadores de béisbol universitario tienen la ambición de jugar profesionalmente, ya sea en las Grandes Ligas de Béisbol (MLB) u otras ligas profesionales. Pero, ¿cómo es ese camino? ¿Cómo puedes pasar de la universidad a las ligas mayores? En este capítulo, te proporcionaremos un resumen del camino hacia el profesionalismo en el béisbol.
  </MKTypography>

  {/* Sección 3.1: El Camino hacia el Profesionalismo */}
  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 El Camino hacia el Profesionalismo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      El camino hacia el profesionalismo en el béisbol generalmente comienza con el Draft de la MLB. El Draft de la MLB es un evento anual donde los 30 equipos de la MLB seleccionan jugadores de diversas fuentes, incluido el béisbol universitario, el béisbol de secundaria, el béisbol internacional y el béisbol independiente.
      <br />
      <br />
      El Draft de la MLB consta de 40 rondas, con cada equipo autorizado a elegir un jugador por ronda. El orden de las selecciones se determina por la inversión de las clasificaciones de la temporada anterior de la MLB, con algunas excepciones para selecciones de compensación y selecciones de lotería.
      <br />
      <br />
      El Draft de la MLB es una oportunidad significativa para que los jugadores de béisbol universitario comiencen sus carreras profesionales. Aproximadamente 1,200 jugadores son seleccionados en el Draft de la MLB cada año, con alrededor del 60% proveniente del béisbol universitario.
      <br />
      <br />
      Si eres seleccionado en el Draft de la MLB, tienes dos opciones: puedes firmar un contrato con el equipo que te seleccionó, o puedes optar por no firmar y regresar a tu universidad u otra fuente.
      <br />
      <br />
      Si eliges firmar un contrato, recibirás un bono de firma basado en la ronda en la que fuiste seleccionado. Los bonos varían desde varios millones de dólares para la primera ronda hasta varios miles de dólares para las rondas posteriores.
      <br />
      <br />
      Cuando firmas un contrato, serás asignado a uno de los equipos de ligas menores del equipo que te seleccionó. Las ligas menores son los niveles inferiores del béisbol profesional, sirviendo como un sistema de desarrollo para la MLB. Hay seis niveles en las ligas menores: Rookie, Clase A Corta, Clase A, Clase A Avanzada, Doble-A y Triple-A.
      <br />
      <br />
      Mientras juegas en las ligas menores, debes mejorar tus habilidades y demostrar tu rendimiento para avanzar a niveles superiores. También tienes que competir con otros jugadores que comparten el mismo sueño: llegar a las ligas mayores.
      <br />
      <br />
      Alcanzar las ligas mayores es el objetivo final para todo jugador de béisbol profesional. Es el nivel más alto del béisbol profesional, donde puedes competir contra los mejores jugadores del mundo, frente a los mayores aficionados y por los salarios más altos.
      <br />
      <br />
      Sin embargo, llegar a las ligas mayores no es fácil. Solo alrededor del 10% de los jugadores seleccionados en el Draft de la MLB eventualmente llegan a las ligas mayores, y solo alrededor del 1% de esos seleccionados tienen carreras largas y exitosas en las ligas mayores.
      <br />
      <br />
      Entonces, el camino hacia el profesionalismo en el béisbol es un viaje largo y desafiante que requiere dedicación, trabajo duro y algo de suerte. Pero también es un camino que puede ofrecer muchas recompensas, oportunidades y sueños.
    </MKTypography>
  </MKBox>

  {/* Sección 3.2: El Draft de la MLB y Oportunidades Profesionales Adicionales */}
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 El Draft de la MLB y Otras Oportunidades Profesionales
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      El Draft de la MLB no es la única forma de jugar profesionalmente en el béisbol. También hay otras oportunidades profesionales fuera de la MLB que pueden ser atractivas para los jugadores de béisbol universitario.
      <br />
      <br />
      Una de ellas es la liga independiente. La liga independiente es una liga de béisbol profesional que no está afiliada a la MLB o las ligas menores. La liga independiente consta de varias ligas, como la Liga Atlántica, la Asociación Americana y la Liga Frontier.
      <br />
      <br />
      La liga independiente ofrece una alternativa para los jugadores que no son seleccionados en el Draft de la MLB o no reciben ofertas de contrato de un equipo de la MLB. La liga independiente también ofrece una oportunidad para los jugadores que son liberados de las ligas menores o quieren extender sus carreras.
      <br />
      <br />
      La liga independiente tiene un nivel de juego y salario más bajo en comparación con la MLB o las ligas menores, pero también ofrece un nivel más alto de libertad y disfrute. La liga independiente es conocida por su creatividad, diversidad y experimentos. También ha producido algunos jugadores que eventualmente llegaron a las ligas mayores, como José Bautista, Rich Hill y Max Muncy.
      <br />
      <br />
      Otra oportunidad profesional es la liga internacional. La liga internacional es un término colectivo para las ligas de béisbol profesional jugadas fuera de los Estados Unidos. La liga internacional consta de varias ligas, como la Liga Profesional de Béisbol Nippon (NPB) en Japón, la Organización Coreana de Béisbol (KBO) en Corea del Sur, la Liga de Béisbol Profesional China (CPBL) en Taiwán y la Liga Mexicana de Béisbol (LMB) en México.
      <br />
      <br />
      La liga internacional ofrece una oportunidad para los jugadores que desean ampliar sus horizontes y experimentar una cultura diferente. La liga internacional también brinda una oportunidad para los jugadores que buscan un nivel de juego y salario más alto que la liga independiente pero que pueden no poder llegar a la MLB o las ligas menores.
      <br />
      <br />
      Estos son solo algunos ejemplos de oportunidades profesionales fuera de la MLB. Hay muchas más oportunidades, como ligas de invierno, ligas de verano universitarias y ligas amateur.
      <br />
      <br />
      Estas oportunidades ofrecen diferentes ventajas y desventajas para los jugadores de béisbol universitario. Proporcionan diferentes niveles de competencia, recompensa, desafío y disfrute. Ofrecen diversos caminos hacia el profesionalismo en el béisbol.
    </MKTypography>
  </MKBox>
</MKBox>






                                  







{/* Capítulo 4: Influencias Internacionales */}
<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 4: Influencias Internacionales
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    El béisbol universitario no es solo un deporte estadounidense; también es un deporte internacional influenciado por jugadores, entrenadores y aficionados de diversos países y culturas. En este capítulo, te proporcionaremos un resumen de las influencias internacionales en el béisbol universitario.
  </MKTypography>

  {/* Sección 4.1: Jugadores Extranjeros en el Béisbol Universitario */}
  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Jugadores Extranjeros en el Béisbol Universitario
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Equipo de béisbol de USC celebrando una victoria."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Jugadores de USC celebrando una victoria
        </figcaption>
      </figure>
      Una de las influencias internacionales más significativas en el béisbol universitario es la presencia de jugadores extranjeros. Los jugadores extranjeros son aquellos que no nacieron en Estados Unidos o no tienen ciudadanía estadounidense. Provienen de diferentes países, como Canadá, México, Japón, Corea del Sur, Taiwán, Cuba, Venezuela, la República Dominicana y los Países Bajos.
      <br />
      <br />
      Los jugadores extranjeros juegan un papel vital en el béisbol universitario. Aportan sus propias habilidades, estilos y perspectivas al juego. Enriquecen la diversidad y calidad del béisbol universitario. También aumentan el reconocimiento internacional y el atractivo del béisbol universitario.
      <br />
      <br />
      Hay muchas historias de éxito de jugadores extranjeros que han triunfado en el mundo del béisbol universitario estadounidense. Un ejemplo destacado es Nomar Garciaparra. Nomar Garciaparra fue un paracorto mexicoamericano que jugó para el Instituto de Tecnología de Georgia. Fue seleccionado en la primera ronda del Draft de la MLB en 1994 por los Boston Red Sox. Jugó 14 temporadas en la MLB, donde fue seleccionado seis veces para el Juego de Estrellas y ganó el título de bateo de la Liga Americana dos veces. Hay muchos más ejemplos de jugadores extranjeros que han dejado su huella en el béisbol universitario.
    </MKTypography>
  </MKBox>

  {/* Sección 4.2: Estadounidenses en el Extranjero */}
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Estadounidenses en el Extranjero
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Otra influencia internacional en el béisbol universitario es la presencia de estadounidenses en el extranjero. Los estadounidenses en el extranjero son jugadores que nacieron en Estados Unidos o tienen ciudadanía estadounidense pero juegan o han jugado en ligas profesionales fuera de Estados Unidos.
      <br />
      <br />
      Los estadounidenses en el extranjero también juegan un papel importante en el béisbol universitario. Aportan sus propias experiencias, conocimientos y redes al juego. También aprenden de otras culturas y estilos de béisbol. Mejoran el intercambio y la colaboración internacional en el béisbol universitario.
      <br />
      <br />
      Estas oportunidades ofrecen muchos beneficios a los jugadores de béisbol universitario estadounidenses. Proporcionan un nivel más alto de competencia, exposición y recompensa. También ayudan a ampliar sus horizontes, aumentar la adaptabilidad y expandir sus redes.
      <br />
      <br />
      La experiencia internacional puede ser beneficiosa para el desarrollo de un jugador. Puede ayudarlos a aprender nuevas habilidades, técnicas y estrategias. También puede ayudarlos a mejorar sus debilidades, aprovechar sus fortalezas y maximizar su potencial.
    </MKTypography>
  </MKBox>
</MKBox>

      






                                  



<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Becas Deportivas y el Futuro de los Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Las becas deportivas ofrecen numerosas oportunidades para que los jóvenes atletas muestren su talento y desarrollen sus habilidades. Una de las oportunidades más significativas es obtener una beca deportiva para estudiar y competir a nivel universitario. En este capítulo, discutiremos la importancia de las becas deportivas y cómo Sport Scholarships America puede ayudarte a hacer realidad tu sueño.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 La Importancia de las Becas
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Una beca deportiva es un apoyo financiero proporcionado por una universidad a un estudiante-atleta para combinar estudios y deporte. Puede cubrir los costos de matrícula, libros, alojamiento, comidas y otros gastos. Además, puede ofrecer beneficios como tutoría académica, atención médica, instalaciones de entrenamiento y equipo.
      <br />
      <br />
      Obtener una beca deportiva es competitivo, con muchos factores que influyen en el proceso de adjudicación, incluyendo logros académicos, rendimiento deportivo, personalidad, motivación, liderazgo y adaptabilidad. Por lo tanto, es importante prepararse bien y destacarse entre la competencia.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Cómo Puede Ayudar Sport Scholarships America
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo de Sport Scholarships America, que muestra equipamiento deportivo y una gorra de graduación, representando el enfoque en becas deportivas para estudiantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America es una organización especializada en guiar a jóvenes talentos deportivos hacia becas deportivas. Con expertos apasionados y experimentados, Sport Scholarships America apoya a los estudiantes-atletas en cada paso del camino, desde la orientación inicial hasta la colocación final. Sus servicios incluyen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Una entrevista personal para discutir los deseos, posibilidades y expectativas del estudiante-atleta.</li>
        <li>Una evaluación profesional del nivel académico y deportivo del estudiante-atleta.</li>
        <li>Una extensa red de contactos con entrenadores, universidades y colegios.</li>
        <li>Promoción efectiva del perfil y videos del estudiante-atleta ante entrenadores potenciales.</li>
        <li>Asesoramiento experto sobre las mejores opciones basadas en las preferencias, metas y presupuesto del estudiante-atleta.</li>
        <li>Negociación de las mejores ofertas de becas en nombre del estudiante-atleta.</li>
        <li>Asistencia en la solicitud de documentos necesarios, como visa, expediente académico, diploma y pruebas de admisión.</li>
        <li>Preparación para la vida del estudiante-atleta durante su beca, incluyendo cultura, idioma, reglas y consejos.</li>
        <li>Seguimiento y atención posterior durante el período de la beca.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      ¿Quieres explorar oportunidades de becas y alcanzar tus metas deportivas y académicas? <Link to="/es/Servicios-Productos">Consulta la gama completa</Link> de servicios ofrecidos por Sport Scholarships America y programa una consulta hoy mismo.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;