/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Gymnastics1.jpeg";
import Image2 from "assets/images/Sports_Images/Gymnastics2.jpg";
import Image3 from "assets/images/Sports_Images/Gymnastics3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images

import { Link } from 'react-router-dom';


const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Turnen und Studieren in Amerika: Eine einzigartige Gelegenheit für junge Turnerinnen und Turner', isSubheader: false },
  
    { id: 'Kapitel 1', title: 'Kapitel 1: Geschichte und Entwicklung des Turnens in Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 Frühe Jahre und die Rolle der Universitäten', isSubheader: true },
    { id: '1.2', title: '1.2 Wachstum und Professionalisierung', isSubheader: true },
  
    { id: 'Kapitel 2', title: 'Kapitel 2: Hochschulturnen heute', isSubheader: false },
    { id: '2.1', title: '2.1 Hauptwettbewerbe und Konferenzen', isSubheader: true },
    { id: '2.2', title: '2.2 Top-Programme und angesehene Teams', isSubheader: true },
  
    { id: 'Kapitel 3', title: 'Kapitel 3: Vom College zu den Profis', isSubheader: false },
    { id: '3.1', title: '3.1 Der Weg zum Profisport', isSubheader: true },
    { id: '3.2', title: '3.2 Professionelle Karrieren und Möglichkeiten', isSubheader: true },
  
    { id: 'Kapitel 4', title: 'Kapitel 4: Internationale Einflüsse', isSubheader: false },
    { id: '4.1', title: '4.1 Ausländische Einflüsse und Austausch', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikaner im Ausland', isSubheader: true },
  
    { id: 'Kapitel 5', title: 'Kapitel 5: Sportstipendien und die Zukunft der Athleten', isSubheader: false },
    { id: '5.1', title: '5.1 Die Bedeutung von Stipendien', isSubheader: true },
    { id: '5.2', title: '5.2 Wie Sportstipendien Amerika helfen können', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhaltsverzeichnis
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verstecken</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Anzeigen</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>























          <MKBox id="Einführung" mb={3}>
    <MKTypography variant="h1" className="h1-as-h3" mb={2}>
        Turnen und Studieren in Amerika: Eine einzigartige Gelegenheit für junge Turnerinnen und Turner
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
            <img
                src={Image1}
                alt="Blick von den Tribünen bei einem College-Turnwettkampf in einem großen Stadion."
                style={{
                    maxWidth: "100%",
                    border: "2px solid #fff",
                    boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                    cursor: "pointer",
                    outline: "none",
                }}
                onClick={() => openModal1(Image1)}
            />
            <figcaption style={{
                textAlign: 'left',
                fontWeight: 'bold',
                fontSize: '12px',
                wordWrap: 'break-word',
            }}>
                Eine große Arena während eines NCAA-Turnwettkampfs
            </figcaption>
        </figure>
        Turnen hat eine lange und reiche Geschichte in den Vereinigten Staaten. Seit dem 19. Jahrhundert wird Turnen an Universitäten praktiziert, wo Turnerinnen und Turner ihre Fähigkeiten entwickeln und ihre Talente präsentieren. Turnen ist nicht nur ein Sport; es ist auch eine Kunstform, eine Wissenschaft und eine Lebensweise. Turnerinnen und Turner lernen Disziplin, Ausdauer, Kreativität und Teamarbeit. Sie trainieren hart, haben aber auch viel Spaß.
        <br />
        <br />
        An Turnwettkämpfen auf Hochschulebene teilzunehmen, ist eine einzigartige Gelegenheit für junge Turnerinnen und Turner, ihre Träume zu verwirklichen. Durch ein Sportstipendium können Sie an einer renommierten Universität studieren und gleichzeitig an aufregenden Wettkämpfen gegen andere Spitzen-Turnerinnen und -Turner teilnehmen. Sie werden Teil einer eng verbundenen Gemeinschaft von Studenten-Athleten, die sich gegenseitig unterstützen und motivieren. Sie erhalten Zugang zu den besten Einrichtungen, Trainern und Mentoren, die Ihnen helfen, Ihr Potenzial auszuschöpfen. Und Sie sammeln unvergessliche Erfahrungen, die Sie auf Ihre zukünftige Karriere vorbereiten.
        <br />
        <br />
        Aber wie qualifizieren Sie sich für ein Sportstipendium? Was sind die Anforderungen und Vorteile? Wie finden Sie die richtige Universität für sich? Und wie ist das Leben eines Studenten-Turners? Diese und viele weitere Fragen kann Ihnen Sportbeursamerika.nl beantworten. Sportbeursamerika.nl ist eine Plattform, die jungen Turnerinnen und Turnern hilft, ihren Weg nach Amerika zu finden. Ich biete persönliche Betreuung, Beratung und Unterstützung während des gesamten Bewerbungs-, Auswahl-, Vorbereitungs- und Abreiseprozesses. Ich habe Verbindungen zu Hunderten von Universitäten und Trainern, die nach turnerischem Talent suchen. Ich kenne die Vor- und Nachteile des amerikanischen Bildungssystems und des Hochschul-Turnens. Und ich habe persönliche Erfahrung mit Sport und Studium in Amerika.
        <br />
        <br />
        Sind Sie neugierig auf die Möglichkeiten, Turnen und Studium in Amerika zu kombinieren? Lesen Sie weiter und entdecken Sie alles, was Sie über diese einzigartige Gelegenheit wissen müssen.
    </MKTypography>
    {/* Weitere Listen oder Inhalte je nach Bedarf hinzufügen */}
</MKBox>

<MKBox id="Kapitel 1" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Kapitel 1: Geschichte und Entwicklung des Turnens in Amerika
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Turnen ist einer der ältesten Sportarten der Welt, mit Ursprüngen in antiken Zivilisationen wie Griechenland, Rom und China. Turnen wurde verwendet, um den Körper zu trainieren, den Geist zu schärfen und die Seele zu erheben. Es wurde auch als Ausdrucksform, Kunst und Schönheit angesehen.
        <br />
        <br />
        In den Vereinigten Staaten begann das Turnen im 19. Jahrhundert zu entwickeln, als europäische Einwanderer ihr Wissen und ihre Fähigkeiten mitbrachten. Das Turnen wurde schnell in Schulen, Vereinen und Verbänden populär, wo es als Mittel zur Förderung von Gesundheit, Charakter und Staatsbürgerschaft angesehen wurde. Das Turnen wurde auch eine Quelle des nationalen Stolzes, insbesondere nach dem Unabhängigkeitskrieg.
    </MKTypography>

    <MKBox id="1.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            1.1 Frühe Jahre und die Rolle der Universitäten
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Die ersten Universitäts-Turnmannschaften wurden Ende des 19. Jahrhunderts gebildet, als Studenten ihre eigenen Vereine gründeten und interkollegiale Wettkämpfe organisierten. Der erste offizielle Universitäts-Turnwettkampf fand 1897 statt, als die Yale University gegen das Springfield College antrat. Das Springfield College gilt als Geburtsort des amerikanischen Turnens, da es die erste Schule war, die Turnen als akademisches Fach anbot. Viele renommierte Turntrainer und -führer haben am Springfield College studiert oder unterrichtet.
            <br />
            <br />
            Im frühen 20. Jahrhundert setzte sich das Universitäts-Turnen mit der Gründung regionaler und nationaler Organisationen wie der Eastern Intercollegiate Gymnastics League (EIGL) im Jahr 1902 und der National Collegiate Athletic Association (NCAA) im Jahr 1906 fort. Die NCAA begann 1938 mit der Organisation von nationalen Meisterschaften für Männer und 1982 für Frauen. Die NCAA bleibt die wichtigste Organisation für das Hochschulturnen in Amerika.
        </MKTypography>
    </MKBox>

    <MKBox id="1.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            1.2 Wachstum und Professionalisierung
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Die Popularität und das Niveau des Universitäts-Turnens stiegen nach dem Zweiten Weltkrieg, als viele Veteranen an Universitäten zurückkehrten und ihre sportlichen Ambitionen verfolgten. Auch der Einfluss europäischer Trainer und Athleten, die nach Amerika kamen, um dem Kommunismus oder dem Nationalsozialismus zu entkommen, trug zur Entwicklung des Universitäts-Turnens bei. Eine solche Figur war George Gulack, ein ungarischer Turner, der 1936 bei den Olympischen Spielen in Berlin Gold gewann. Gulack spielte eine bedeutende Rolle im Olympischen Komitee und in amerikanischen und internationalen Turnorganisationen und war von 1934 bis 1958 im Amerikanischen Olympischen Komitee tätig.
            <br />
            <br />
            In den 1950er und 60er Jahren begann sich das professionelle Turnen vom Universitäts-Turnen zu unterscheiden, mit dem Aufstieg internationaler Stars wie Larisa Latynina, Olga Korbut und Nadia Comaneci. Diese Turnerinnen inspirierten viele junge Amerikaner, sich privaten Vereinen anzuschließen und sich auf individuelle Leistungen zu konzentrieren. Eine von ihnen war Cathy Rigby, die 1970 die erste amerikanische Frau wurde, die bei einer Weltmeisterschaft eine Medaille gewann. Sie wurde später eine äußerst erfolgreiche Schauspielerin.
            <br />
            <br />
            Das professionelle Turnen erreichte 1978 seinen Höhepunkt, als der amerikanische Turner Kurt Thomas der erste Mann wurde, der bei einer Weltmeisterschaft eine Goldmedaille gewann. Er studierte an der Indiana State University und wurde später Schauspieler. Im selben Jahr feierte Mary Lou Retton ihr Debüt auf der nationalen Bühne. Sie wurde die erste amerikanische Frau, die 1984 bei den Olympischen Spielen im Mehrkampf Gold gewann.
            <br />
            <br />
            Das Universitäts-Turnen blieb jedoch insbesondere für Frauen relevant und einflussreich. Viele Turnerinnen entschieden sich dafür, ihre Karrieren auf Hochschulebene fortzusetzen, weil sie dort mehr Möglichkeiten und Vorteile erhielten als im professionellen Bereich.
            <br />
            <br />
            Das Universitäts-Turnen spielte daher eine wichtige Rolle in der Geschichte und Entwicklung des Turnens in Amerika. Es hat viele Athleten hervorgebracht, die sich nicht nur in ihrem Sport, sondern auch in ihren Studien und späteren Karrieren ausgezeichnet haben. Es hat auch zur Verbreitung und Förderung des Turnens als Sport für jeden, unabhängig von Alter, Geschlecht oder Hintergrund, beigetragen. Das Universitäts-Turnen ist immer noch ein lebendiger und dynamischer Sport, der jedes Jahr Tausende von Fans und Zuschauern anzieht.
        </MKTypography>
    </MKBox>
</MKBox>
                                   





                                  








<MKBox id="Kapitel 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 2: Hochschulturnen heute
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Turnen ist eine der beliebtesten und wettbewerbsfähigsten Sportarten auf Hochschulebene in Amerika. Jedes Jahr kämpfen Hunderte von Teams und Tausende von Athleten um die begehrten Titel und Trophäen. Das Hochschulturnen bietet eine einzigartige Kombination aus Athletik, Spektakel und akademischer Exzellenz. Es ist ein Sport, der viel verlangt, aber auch viel zurückgibt.
  </MKTypography>
  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Hauptwettbewerbe und Konferenzen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Eine Turnerin von Auburn bei einer Bodenübung während eines College-Wettbewerbs."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Eine Turnerin von Auburn in Aktion
        </figcaption>
      </figure>
      Das Hochschulturnen wird von der NCAA regiert, die die Regeln, Qualifikationen und Meisterschaften festlegt. Die NCAA teilt das Hochschulturnen in drei Divisionen ein, basierend auf der Anzahl der Stipendien, dem Budget und dem Teamniveau. Division I ist die höchste Division, an der die meisten Top-Turner und Teams teilnehmen. Division II und III sind niedrigere Divisionen mit weniger angebotenen Stipendien und mehr Betonung auf akademische Leistung.
      <br />
      <br />
      Innerhalb jeder Division gibt es verschiedene Konferenzen, die regionale oder thematische Gruppen von Universitäten bilden. Konferenzen organisieren ihre eigenen Wettkämpfe und Meisterschaften, die als Vorläufe für die nationalen Meisterschaften dienen. Die wichtigsten Konferenzen für das Hochschulturnen sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Die Big Ten Conference, bestehend aus 14 Universitäten im Mittleren Westen und Nordosten Amerikas. Die Big Ten ist eine der ältesten und angesehensten Konferenzen, bekannt für ihre akademische und sportliche Exzellenz. Die Big Ten hat einige der besten Männer- und Frauen-Turnprogramme, darunter Michigan, Minnesota, Nebraska und Penn State.
        </li>
        <li>
          Die Southeastern Conference (SEC), bestehend aus 14 Universitäten im Südosten Amerikas. Die SEC ist eine der dominantesten und einflussreichsten Konferenzen, bekannt für ihre leidenschaftlichen und loyalen Fans. Die SEC hat einige der besten Frauen-Turnprogramme, wie Alabama, Florida, Georgia und LSU.
        </li>
        <li>
          Die Pac-12 Conference, bestehend aus 12 Universitäten im Westen der Vereinigten Staaten. Die Pac-12 ist eine der innovativsten und vielfältigsten Konferenzen, bekannt für ihre Kreativität und Kultur. Die Pac-12 hat einige der besten Männer- und Frauen-Turnprogramme, darunter California, Stanford, UCLA und Utah.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Die nationalen Meisterschaften sind der Höhepunkt der Hochschulturnsaison, bei denen die besten Teams und Einzelpersonen um Ehre und Ruhm kämpfen. Die nationalen Meisterschaften finden jedes Jahr im April an verschiedenen Orten im ganzen Land statt. Die nationalen Meisterschaften bestehen aus zwei Runden: den regionalen Meisterschaften und den nationalen Finals. Die regionalen Meisterschaften sind die Qualifikationsrunden, bei denen 36 Teams für Männer und 36 Teams für Frauen in sechs Regionen aufgeteilt werden. Die beiden besten Teams aus jeder Region kommen in die nationalen Finals. Die nationalen Finals sind die entscheidenden Runden, bei denen 12 Teams für Männer und 12 Teams für Frauen in zwei Sessions aufgeteilt werden. Die vier besten Teams aus jeder Session kommen in das Superfinale (Super Six), wo sie um den nationalen Mannschaftsmeistertitel kämpfen. Zusätzlich werden individuelle Titel für den Mehrkampf und die Gerätefinals vergeben.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Top-Programme und angesehene Teams
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Das Hochschulturnen hat mehrere Spitzenprogramme hervorgebracht, die sich durch ihre Beständigkeit, Qualität und Tradition auszeichnen. Diese Programme haben mehrere nationale Titel gewonnen, viele talentierte Athleten ausgebildet und zahlreiche Fans angezogen. Einige dieser Programme sind:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          UCLA Bruins: Das Turnprogramm der UCLA gehört zu den erfolgreichsten und angesehensten Programmen im Land. Das Turnprogramm der UCLA hat mehr als 20 nationale Titel gewonnen, darunter mehrere Titel für Männer und die Mehrheit für Frauen. Das Programm hat auch zahlreiche Olympiasieger und Weltmeister hervorgebracht, wie Peter Vidmar, Tim Daggett, Jamie Dantzscher, Mohini Bhardwaj, Tasha Schwikert, Samantha Peszek und Kyla Ross.
        </li>
        <li>
          Oklahoma Sooners: Das Turnprogramm der Oklahoma gehört zu den dominantesten und beeindruckendsten Programmen der Nation. Das Turnprogramm der Oklahoma hat mehr als 10 nationale Titel gewonnen, darunter mehrere Titel für Männer und fast genauso viele für Frauen. Das Programm hat viele NCAA-Champions hervorgebracht, darunter Jonathan Horton, Jake Dalton, Maggie Nichols, Brenna Dowell und Anastasia Webb.
        </li>
        <li>
          Utah Utes: Das Turnprogramm der Utah gehört zu den historischsten und treuesten Programmen des Landes. Das Turnprogramm der Utah hat mehr als 10 nationale Titel gewonnen, alle für Frauen. Das Programm hat auch legendäre Trainer und Athleten hervorgebracht, darunter Greg Marsden, Megan Marsden, Missy Marlowe, Theresa Kulikowski, Ashley Postell und MyKayla Skinner.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Das Hochschulturnen bietet jungen Turnern eine einzigartige Möglichkeit, ihren Sport auf höchstem Niveau auszuüben, während sie eine wertvolle Ausbildung erhalten. Das Hochschulturnen ist ein Sport, der viele Herausforderungen und Belohnungen sowohl auf als auch neben dem Boden bietet. Das Hochschulturnen ist ein Sport, der dein Leben verändern kann.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  







<MKBox id="Kapitel 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 3: Vom College zu den Profis
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Turnen ist eine Sportart, die viel Talent, Hingabe und Ausdauer erfordert. Turnerinnen beginnen oft schon in jungen Jahren mit dem Training und träumen von einer professionellen Karriere. Aber wie gelingt der Übergang vom Hochschulturnen zum Profi-Turnen? Und welche Chancen und Herausforderungen erwarten dich?
  </MKTypography>
  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Der Weg zum Profisport
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Das Hochschulturnen ist eine ausgezeichnete Vorbereitung auf den Profi-Turnsport, weil es dir die Möglichkeit bietet, deine Fähigkeiten zu verbessern, Erfahrungen zu sammeln und dein Netzwerk zu erweitern. Viele Hochschulturnerinnen haben an professionellen Wettkämpfen wie den Olympischen Spielen, Weltmeisterschaften und dem Weltcup teilgenommen. Einige von ihnen haben sogar Medaillen gewonnen.
      <br />
      <br />
      Um jedoch an professionellen Wettkämpfen teilnehmen zu können, müssen Hochschulturnerinnen bestimmte Regeln und Bedingungen einhalten. Sie müssen ihren Amateurstatus aufrechterhalten, was bedeutet, dass sie kein Geld mit ihrem Sport verdienen dürfen. Sie dürfen auch keine Sponsoren oder Agenten haben, es sei denn, sie erhalten die Genehmigung der NCAA. Sie müssen auch die akademischen Anforderungen ihrer Universität erfüllen, was manchmal schwierig sein kann, mit ihrem Trainingsplan in Einklang zu bringen.
      <br />
      <br />
      Darüber hinaus müssen Hochschulturnerinnen auch ihr Alter und ihren körperlichen Zustand berücksichtigen. Turnen ist eine Sportart, die hohe Anforderungen an den Körper stellt und oft zu Verletzungen führt. Viele Turnerinnen erreichen ihren Höhepunkt in ihren Zwanzigern und haben möglicherweise Schwierigkeiten, ihr Niveau danach aufrechtzuerhalten. Einige Turnerinnen entscheiden sich dafür, ihre professionellen Ambitionen aufzugeben und sich auf ihr Studium oder ihre Karriere außerhalb des Sports zu konzentrieren.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Professionelle Karrieren und Möglichkeiten
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Für diejenigen, die sich entscheiden, mit dem Profi-Turnen fortzufahren, gibt es verschiedene Möglichkeiten und Chancen, ihre Leidenschaft zu verfolgen. Eine der angesehensten und lukrativsten Optionen besteht darin, an den Olympischen Spielen teilzunehmen, die alle vier Jahre stattfinden. Die Olympischen Spiele sind der Höhepunkt jeder Sportkarriere und bieten die Möglichkeit, Ihr Land zu vertreten, gegen die besten Turnerinnen der Welt anzutreten und eine Medaille zu gewinnen. Die Olympischen Spiele bringen auch Ruhm und Anerkennung mit sich, die zu Sponsorenverträgen, Medienaufmerksamkeit und anderen Vorteilen führen können.
      <br />
      <br />
      Eine weitere Option besteht darin, an den Weltmeisterschaften teilzunehmen, die jedes Jahr (außer in Olympischen Jahren) stattfinden. Die Weltmeisterschaften sind ein bedeutender Maßstab für das Niveau und die Entwicklung des Turnens weltweit. Die Weltmeisterschaften bieten auch die Möglichkeit, sich für die Olympischen Spiele zu qualifizieren oder eine Wildcard zu erhalten. Die Weltmeisterschaften sind eine Quelle des Stolzes und des Prestiges, die zu Preisgeldern, Stipendien und anderen Belohnungen führen können.
      <br />
      <br />
      Eine dritte Option besteht darin, am Weltcup teilzunehmen, der aus einer Reihe von Einzelwettkämpfen auf der ganzen Welt besteht. Der Weltcup ist eine attraktive Option für Turnerinnen, die mehr Flexibilität und Vielfalt in ihrer Sportkarriere suchen. Der Weltcup bietet auch die Möglichkeit, verschiedene Länder und Kulturen zu erkunden, neue Freunde zu finden und Ihren Horizont zu erweitern. Der Weltcup ist auch eine Quelle von Spaß und Abenteuer, die zu Reisekosten, Boni und anderen Anreizen führen kann.
      <br />
      <br />
      Neben diesen internationalen Wettbewerben gibt es auch andere professionelle Möglichkeiten für Turnerinnen, wie zum Beispiel:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Durchführung von Kliniken, Workshops oder Vorführungen, bei denen Sie Ihr Wissen und Ihre Erfahrung mit anderen Turnerinnen, Trainern oder Fans teilen.
        </li>
        <li>
          Arbeit als Trainer, Trainer oder Berater, um anderen Turnerinnen zu helfen, ihre Ziele zu erreichen, ihre Technik zu verbessern oder ihre Probleme zu lösen.
        </li>
        <li>
          Arbeit als Kommentator, Analyst oder Journalist, um Ihre Meinung und Einsicht in das Turnen, Wettbewerbe oder aktuelle Ereignisse anzubieten.
        </li>
        <li>
          Arbeit als Model, Schauspieler oder Influencer, um Ihr Aussehen, Ihre Persönlichkeit oder Ihre Popularität zu nutzen, um Produkte, Dienstleistungen oder Marken zu bewerben.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Professionelles Turnen ist eine aufregende und anspruchsvolle Karriere, die viele Möglichkeiten und Chancen bietet. Es ist jedoch auch eine anspruchsvolle und unsichere Karriere, die mit Risiken und Hindernissen verbunden ist. Die Wahl einer Karriere im Profi-Turnen erfordert sowohl Ihr Herz als auch Ihren Verstand.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  






<MKBox id="Kapitel 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 4: Internationale Einflüsse
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Turnen ist eine Sportart ohne Grenzen. Turnen ist eine Sportart, die Menschen aus der ganzen Welt zusammenbringt, inspiriert und bereichert. Turnen ist eine Sportart, die von verschiedenen Kulturen, Stilen und Traditionen beeinflusst wird. In diesem Kapitel werden wir uns mit den internationalen Einflüssen auf das Hochschulturnen in Amerika befassen und wie diese Einflüsse das Hochschulturnen geprägt und transformiert haben.
  </MKTypography>
  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Ausländische Einflüsse und Austausch
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Stanford gymnast in action on the pommel horse at a college competition."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Ein Turner von Stanford in Aktion
        </figcaption>
      </figure>
      Im faszinierenden Universum des Hochschulturnens in Amerika spielt der internationale Austausch eine entscheidende Rolle. Wussten Sie, dass ausländische Turner eine einzigartige Dynamik und Techniken mitbringen, die das Wettbewerbsniveau erhöhen? Diese Athleten bringen oft innovative Stile und Methoden mit, die ihre amerikanischen Kollegen inspirieren und herausfordern. Dies macht den Sport nicht nur vielfältiger, sondern auch wettbewerbsfähiger.
      <br />
      <br />
      Ein Beispiel dafür ist die Auswirkung von Turnerinnen aus Osteuropa in den 80er und 90er Jahren. Ihr Kommen in die USA brachte neue Techniken und Trainingsmethoden mit sich, was zu einer bedeutenden Entwicklung im amerikanischen Turnen führte. Diese Einflüsse sind auch heute noch im Stil und in der Technik vieler Spitzen-Turnerinnen sichtbar.
      <br />
      <br />
      Die Vielfalt, die internationale Turnerinnen mitbringen, bereichert die Sportkultur auf den Campus. Sie schaffen einen Schmelztiegel aus Kulturen und Ideen, der nur dem Sport zugutekommt. Darüber hinaus bietet es amerikanischen Studenten und Trainern die Möglichkeit, aus verschiedenen kulturellen Perspektiven zu lernen und ihre eigenen Fähigkeiten und Taktiken zu schärfen.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikaner im Ausland
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Auf der anderen Seite haben amerikanische Turnerinnen auch einen bedeutenden Einfluss auf die internationale Turnwelt gehabt. Zahlreiche amerikanische Studenten-Athleten haben nach ihrer Hochschulkarriere internationalen Erfolg erzielt. Ihre Leistungen bei Weltmeisterschaften und den Olympischen Spielen resultieren oft aus dem Training und der Erfahrung, die sie im Hochschulbereich gesammelt haben.
      <br />
      <br />
      Diese Turnerinnen fungieren als Botschafter der amerikanischen Turnkultur. Ihre Erfolge inspirieren junge Turnerinnen weltweit, Teil eines amerikanischen Universitätsteams zu sein. Dies schafft einen positiven Kreislauf, in dem Talent und Wissen über Grenzen hinweg ausgetauscht werden.
      <br />
      <br />
      Die Wege, die diese Turnerinnen eingeschlagen haben, zeigen, wie das Hochschulturnen eine ausgezeichnete Grundlage für eine internationale Karriere sein kann. Die Erfahrungen, die sie während ihres Studiums sammeln - von der Teilnahme an hochrangigen Wettbewerben bis hin zum Training unter Spitzen-Trainern - bereiten sie auf die Herausforderungen der professionellen Sportwelt vor.
    </MKTypography>
  </MKBox>
</MKBox>


                                              






                                  

                                
<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapitel 5: Sportstipendien und die Zukunft der Athleten
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Sportstipendien bieten zahlreiche Möglichkeiten für junge Athleten, ihr Talent zu zeigen und ihre Fähigkeiten zu entwickeln. Eine der bedeutendsten Möglichkeiten ist der Erhalt eines Sportstipendiums, um auf Hochschulebene zu studieren und zu konkurrieren. In diesem Kapitel werden wir die Bedeutung von Sportstipendien diskutieren und wie Sportstipendien Amerika Ihnen helfen kann, Ihren Traum zu verwirklichen.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Die Bedeutung von Stipendien
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ein Sportstipendium ist finanzielle Unterstützung, die von einer Universität einem Studenten-Athleten zur Verfügung gestellt wird, um Studium und Sport zu kombinieren. Es kann die Kosten für Studiengebühren, Bücher, Unterkunft, Mahlzeiten und andere Ausgaben abdecken. Darüber hinaus kann es auch Vorteile wie akademische Nachhilfe, medizinische Versorgung, Trainingsmöglichkeiten und Ausrüstung bieten.
      <br />
      <br />
      Die Erlangung eines Sportstipendiums ist wettbewerbsfähig, wobei viele Faktoren eine Rolle im Zuteilungsprozess spielen, einschließlich akademischer Leistungen, sportlicher Leistung, Persönlichkeit, Motivation, Führung und Anpassungsfähigkeit. Daher ist es wichtig, sich gut vorzubereiten und sich von der Konkurrenz abzuheben.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Wie Sportstipendien Amerika helfen können
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo von Sportstipendien Amerika, das Sportausrüstung und eine Abschlusskappe zeigt, die den Fokus auf Sportstipendien für Studenten-Athleten repräsentiert."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo von Sportstipendien Amerika
        </figcaption>
      </figure>
      Sportstipendien Amerika ist eine Organisation, die sich auf die Anleitung junger Sporttalente zu Sportstipendien spezialisiert hat. Mit leidenschaftlichen und erfahrenen Experten unterstützt Sportstipendien Amerika Studenten-Athleten auf jedem Schritt der Reise, von der ersten Orientierung bis zur endgültigen Platzierung. Ihre Dienstleistungen umfassen:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Ein persönliches Aufnahmegespräch, um die Wünsche, Möglichkeiten und Erwartungen des Studenten-Athleten zu besprechen.</li>
        <li>Eine professionelle Bewertung des sportlichen und akademischen Niveaus des Studenten-Athleten.</li>
        <li>Ein umfangreiches Netzwerk von Kontakten zu Trainern, Universitäten und Hochschulen.</li>
        <li>Effektive Werbung für das Profil und die Videos des Studenten-Athleten bei potenziellen Trainern.</li>
        <li>Expertenberatung zu den besten Optionen basierend auf den Vorlieben, Zielen und dem Budget des Studenten-Athleten.</li>
        <li>Verhandlung der besten Stipendienangebote im Namen des Studenten-Athleten.</li>
        <li>Hilfe bei der Beantragung der erforderlichen Dokumente wie Visum, Zeugnis, Diplom und Zulassungstests.</li>
        <li>Vorbereitung auf das Leben des Studenten-Athleten während seines Stipendiums, einschließlich Kultur, Sprache, Regeln und Tipps.</li>
        <li>Nachsorge und Follow-up während der Stipendiumszeit.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Möchten Sie Stipendienmöglichkeiten erkunden und Ihre sportlichen und akademischen Ziele erreichen? <Link to="/de/Dienstleistungen-Produkte">Sehen Sie sich die vollständige Palette</Link> der von Sportstipendien Amerika angebotenen Dienstleistungen an und vereinbaren Sie noch heute eine Beratung.
    </MKTypography>
  </MKBox>
</MKBox>


          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;