/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Softbal1.jpeg";
import Image2 from "assets/images/Sports_Images/Softbal2.jpg";
import Image3 from "assets/images/Sports_Images/Softbal3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Bolsas de Softbol nos EUA: Como Estudar e Jogar Softbol na América', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capítulo 1: História e Desenvolvimento do Softbol na América', isSubheader: false },
    { id: '1.1', title: '1.1 Primeiros Anos e o Papel das Escolas e Universidades', isSubheader: true },
    { id: '1.2', title: '1.2 Crescimento e Profissionalização', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capítulo 2: Softbol Universitário Hoje', isSubheader: false },
    { id: '2.1', title: '2.1 Ligas e Conferências Importantes', isSubheader: true },
    { id: '2.2', title: '2.2 Programas Principais e Times Prestigiados', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capítulo 3: Da Faculdade para o Profissionalismo', isSubheader: false },
    { id: '3.1', title: '3.1 O Caminho para o Profissionalismo', isSubheader: true },
    { id: '3.2', title: '3.2 Softbol Profissional e Outras Oportunidades de Carreira', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capítulo 4: Influências Internacionais e Eventos', isSubheader: false },
    { id: '4.1', title: '4.1 Influências Estrangeiras', isSubheader: true },
    { id: '4.2', title: '4.2 Americanos no Cenário Mundial', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas', isSubheader: false },
    { id: '5.1', title: '5.1 A Importância das Bolsas de Estudo', isSubheader: true },
    { id: '5.2', title: '5.2 Como a Sport Scholarships America Pode Ajudar', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };
  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Conteúdo
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Esconder</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostrar</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>





















{/* Caixa de introdução */}
<MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Bolsas de Softbol nos EUA: Como Estudar e Jogar Softbol na América
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Visão aérea de um estádio lotado em uma partida de softbol universitário."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Um estádio cheio durante um jogo
      </figcaption>
    </figure>
    O softbol é um esporte que teve origem nos Estados Unidos no final do século XIX. É uma variação do beisebol, mas com uma bola maior, um campo menor e entradas mais curtas. O softbol é jogado por dois times de nove jogadores, alternando entre bater e defender. O objetivo é marcar mais corridas do que o time adversário ao correr pelas quatro bases após rebater a bola.
    <br />
    <br />
    O softbol rapidamente ganhou popularidade nos EUA, especialmente entre escolas e universidades. Ele era visto como um esporte que promovia a aptidão física, a agilidade mental e a interação social. Também atraiu muito talento e competição, principalmente no nível universitário. O softbol universitário representa o mais alto nível de softbol nos EUA, onde os estudantes-atletas competem por suas universidades ou faculdades. O softbol universitário oferece prestígio, reconhecimento e bolsas de estudo para os melhores jogadores.
    <br />
    <br />
    O Sportbeursamerika.nl é uma organização dedicada a ajudar jovens atletas a garantir bolsas de estudo de softbol. O Sportbeursamerika.nl possui uma equipe de especialistas que podem orientá-lo na busca da melhor universidade ou faculdade com base no seu perfil acadêmico e atlético. Eles também podem ajudá-lo a preparar sua inscrição, criar seu vídeo, entrar em contato com treinadores e providenciar seu visto. O Sportbeursamerika.nl já ajudou muitos casos de sucesso a alcançarem o sonho de estudar e jogar softbol na América.
    <br />
    <br />
    Se você quiser saber mais sobre o softbol na América e como conseguir uma bolsa de estudos de softbol, continue lendo!
  </MKTypography>
  {/* Adicione uma lista de marcadores ou outro conteúdo conforme necessário */}
</MKBox>

{/* Capítulos principais e subcapítulos com texto de espaço reservado */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 1: História e Desenvolvimento do Softbol na América
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O softbol é um esporte que teve origem nos Estados Unidos, mas se espalhou pelo mundo. É um esporte que passou por muitas mudanças e inovações, com numerosas figuras e eventos influentes. Neste capítulo, forneceremos uma visão geral da história e desenvolvimento do softbol na América, desde seus primeiros anos até os dias atuais.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Primeiros Anos e o Papel das Escolas e Universidades
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O softbol foi jogado pela primeira vez em 1887 em Chicago como uma variante indoor do beisebol. Foi concebido por um grupo de homens celebrando o Dia de Ação de Graças em um ginásio. Eles usavam uma luva de boxe como bola e um cabo de vassoura como taco. O jogo rapidamente ganhou popularidade entre vários grupos de pessoas, incluindo bombeiros, trabalhadores de fábricas e membros da igreja.
      <br />
      <br />
      Nos primeiros anos do softbol, não havia regras padronizadas ou dimensões. O jogo era adaptado ao espaço disponível e ao número de jogadores. Havia várias variantes de softbol, como mushball, kittenball, diamond ball e indoor baseball. O termo "softbol" foi oficialmente utilizado em 1926.
      <br />
      <br />
      As escolas e universidades desempenharam um papel significativo no desenvolvimento do softbol. Elas viam o softbol como um esporte adequado tanto para meninos quanto para meninas, promovendo a educação física e a recreação. Elas também organizaram as primeiras equipes escolares e universitárias e as primeiras competições organizadas. Uma das primeiras competições escolares foi a Public Schools Athletic League em Nova York, que começou o softbol para meninas em 1913. Uma das primeiras competições universitárias foi a National Collegiate Athletic Association (NCAA), que começou o softbol feminino em 1957.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Crescimento e Profissionalização
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O softbol se tornou um esporte popular e prestigiado na América, especialmente entre as mulheres. Isso se deve em parte à introdução do Título IX em 1972, uma lei que garantia oportunidades iguais para as mulheres na educação, incluindo bolsas de estudo esportivas. Isso proporcionou mais oportunidades para as estudantes-atletas jogarem softbol no nível universitário.
      <br />
      <br />
      Além do softbol universitário, ligas profissionais de softbol surgiram na América. A primeira liga profissional de softbol feminino foi a International Women's Professional Softball Association (IWPSA), fundada em 1976. Essa liga era composta por dez equipes de diferentes cidades, como Nova York, Chicago e Los Angeles. A liga durou quatro temporadas, até 1980.
      <br />
      <br />
      A segunda liga profissional de softbol feminino foi a Women's Pro Softball League (WPSL), estabelecida em 1997. Essa liga contava com seis equipes de diversos estados, incluindo Flórida, Geórgia e Virgínia. A liga posteriormente mudou seu nome para National Pro Fastpitch (NPF). A NPF esteve ativa até 2021 e passou por duas temporadas canceladas devido à COVID-19. Uma nova liga de softbol, a Women's Professional Fastpitch (WPF), foi lançada em 2022.
      <br />
      <br />
      As ligas profissionais de softbol tiveram um impacto significativo no softbol universitário. Muitas jogadoras profissionais eram ex-estudantes-atletas que continuaram suas carreiras após a formatura. Algumas jogadoras profissionais também se tornaram treinadoras ou instrutoras de equipes universitárias ou de jogadores individuais. Além disso, muitas jogadoras profissionais se destacaram como modelos e fontes de inspiração para jovens jogadoras de softbol.
      <br />
      <br />
      Alguns exemplos de figuras-chave no mundo do softbol são:

    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Jennie Finch: Ex-arremessadora do Arizona Wildcats e da seleção nacional dos EUA. Ela conquistou duas medalhas olímpicas, ouro em 2004 e prata em 2008. Ela também jogou pelo Chicago Bandits na NPF. Ela é considerada uma das maiores arremessadoras de todos os tempos e um ícone do esporte.
        </li>
        <li>
          Cat Osterman: Ex-arremessadora do Texas Longhorns e da seleção nacional dos EUA. Ela também conquistou duas medalhas olímpicas, ouro em 2004 e prata em 2008. Ela jogou pelo Rockford Thunder e pelo Houston Scrap Yard Dawgs na NPF. Ela posteriormente se tornou treinadora do Texas State Bobcats.
        </li>
        <li>
          Monica Abbott: Ex-arremessadora do Tennessee Volunteers e da seleção nacional dos EUA. Ela conquistou uma medalha olímpica de prata em 2008. Ela posteriormente jogou pelo Scrap Yard Fast Pitch na NPF e pelo Toyota Red Terriers no Japão. Ela é a primeira jogadora de softbol feminino a assinar um contrato milionário.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>
                                





                                  







{/* Capítulo 2: Softbol Universitário Hoje */}
<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 2: Softbol Universitário Hoje
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O softbol é um dos esportes mais populares e competitivos no nível universitário nos Estados Unidos. Todos os anos, mais de mil equipes participam da temporada universitária de softbol, que ocorre de fevereiro a junho. O destaque da temporada é a Women's College World Series (WCWS), o campeonato nacional de softbol universitário.
  </MKTypography>

  {/* Seção 2.1: Ligas e Conferências Importantes */}
  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Ligas e Conferências Importantes
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Jogadoras de Tennessee e Alabama em ação durante a College World Series."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Jogadoras em ação durante a College World Series
        </figcaption>
      </figure>
      O softbol universitário é regulado pela National Collegiate Athletic Association (NCAA), uma organização responsável por organizar e gerenciar programas esportivos para universidades e faculdades nos Estados Unidos. A NCAA divide as universidades em três divisões com base em seu tamanho, orçamento e desempenho atlético. Estas divisões são:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Divisão I: A divisão mais alta e prestigiosa, com mais de 300 universidades e mais de 10.000 estudantes-atletas. A Divisão I oferece mais bolsas de estudo, as melhores instalações e a competição mais forte. A Divisão I é composta por 32 conferências, grupos regionais de universidades que disputam partidas entre si. Alguns exemplos de conferências são a Southeastern Conference (SEC), a Pac-12 Conference e a Big Ten Conference.
        </li>
        <li>
          Divisão II: A divisão intermediária, com mais de 300 universidades e mais de 8.000 estudantes-atletas. A Divisão II oferece menos bolsas de estudo, mas ainda fornece boas instalações e competição forte. A Divisão II é composta por 23 conferências, como a Great Lakes Valley Conference (GLVC), a Sunshine State Conference e a California Collegiate Athletic Association (CCAA).
        </li>
        <li>
          Divisão III: A divisão mais baixa, com mais de 400 universidades e mais de 6.000 estudantes-atletas. A Divisão III não oferece bolsas de estudo, mas fornece programas acadêmicos fortes e uma experiência esportiva. A Divisão III é composta por 43 conferências, incluindo a New England Small College Athletic Conference (NESCAC), a Centennial Conference e a University Athletic Association (UAA).
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Cada divisão tem seu próprio campeonato no final da temporada, onde as melhores equipes de cada conferência se classificam para um torneio no estilo mata-mata. O campeonato da Divisão I é chamado de Women's College World Series (WCWS), realizado em Oklahoma City. O campeonato da Divisão II é chamado de NCAA Division II Softball Championship, realizado em Denver. O campeonato da Divisão III é chamado de NCAA Division III Softball Championship, realizado em Salem.
    </MKTypography>
  </MKBox>

  {/* Seção 2.2: Programas Principais e Times Prestigiados */}
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programas Principais e Times Prestigiados
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O softbol universitário produziu muitos programas principais e times prestigiados que se distinguiram por sua história de sucesso, jogadoras talentosas e treinadores influentes. Alguns desses programas e times incluem:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          UCLA Bruins: Este programa é considerado um dos mais bem-sucedidos na história do softbol universitário, com um número impressionante de títulos nacionais em seu nome. Ao longo dos anos, eles produziram medalhistas olímpicos, incluindo nomes conhecidos como Lisa Fernandez, Natasha Watley e Stacey Nuveman.
        </li>
        <li>
          Oklahoma Sooners: Os Oklahoma Sooners são um time notavelmente forte e têm entregado consistentemente performances de alto nível. Eles ganharam vários títulos nacionais e tiveram impressionantes sequências de vitórias no passado.
        </li>
        <li>
          Arizona Wildcats: O programa de softbol dos Arizona Wildcats possui uma lista impressionante de títulos nacionais. Eles também têm um técnico respeitado, Mike Candrea, com um excelente histórico de inúmeras vitórias e até mesmo ouro olímpico como treinador da seleção nacional de softbol dos EUA.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Esses programas e times contribuem para o desenvolvimento de jogadoras de softbol oferecendo-lhes um alto nível de treinamento, coaching e competição. Eles também ajudam as jogadoras a aprimorar suas habilidades, conhecimentos e mentalidade, preparando-as para o próximo passo em suas carreiras.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  






{/* Capítulo 3: Da Faculdade para o Profissionalismo */}
<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 3: Da Faculdade para o Profissionalismo
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O softbol é um esporte que oferece muitas oportunidades para estudantes-atletas continuarem suas carreiras após o período universitário. Alguns estudantes-atletas escolhem jogar softbol profissionalmente, enquanto outros exploram outras possibilidades de carreira. Neste capítulo, discutimos o caminho para o profissionalismo, ligas de softbol profissional e outras opções de carreira para jogadoras de softbol.
  </MKTypography>

  {/* Seção 3.1: O Caminho para o Profissionalismo */}
  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 O Caminho para o Profissionalismo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Jogar softbol universitário é uma boa preparação para jogar softbol profissional. Muitos estudantes-atletas que se destacam no softbol universitário são observados ou recrutados por equipes profissionais. Isso significa que eles são oferecidos um contrato para jogar por uma equipe específica, geralmente em troca de um salário e outros benefícios.
      <br />
      <br />
      No entanto, jogar softbol profissional não é fácil. A competição é muito alta, e as jogadoras precisam se adaptar constantemente a novas situações, oponentes e desafios. Além disso, os salários das jogadoras de softbol profissional não são muito altos em comparação com outros esportes. De acordo com um relatório de 2016, o salário médio de uma jogadora da NPF era aproximadamente de $5.000 a $6.000 por temporada. Isso significa que muitas jogadoras profissionais precisam ter um segundo emprego ou outra fonte de renda para se sustentarem.
      <br />
      <br />
      Portanto, algumas estudantes-atletas optam por não jogar softbol profissionalmente, mas sim completar seus estudos ou seguir outra carreira. Isso também pode ser uma boa escolha porque o softbol universitário desenvolve muitas habilidades e qualidades úteis em outros campos, como liderança, trabalho em equipe, comunicação, disciplina e resolução de problemas.
    </MKTypography>
  </MKBox>

  {/* Seção 3.2: Softbol Profissional e Outras Oportunidades de Carreira */}
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Softbol Profissional e Outras Oportunidades de Carreira
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Como mencionado anteriormente, a principal liga de softbol profissional na América é a National Pro Fastpitch (NPF). A NPF atualmente consiste em cinco equipes: as Aussie Peppers de Minnesota, as Canadian Wild de Southern Illinois, os Chicago Bandits, os Cleveland Comets e o USSSA Pride. A NPF também tem uma parceria com a Associação Chinesa de Softbol (CSA), que envia duas equipes para a NPF: as Beijing Eagles e as Shanghai Shengli.
      <br />
      <br />
      No entanto, a NPF não é a única liga de softbol profissional no mundo. Existem também outras ligas em países como Japão, Austrália, Itália e México. Algumas jogadoras americanas optam por jogar nessas ligas porque são melhor remuneradas ou porque desejam experimentar uma cultura diferente.
      <br />
      <br />
      Além de jogar softbol profissionalmente, existem outras oportunidades de carreira para jogadoras de softbol. Alguns exemplos incluem:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Treinamento: Algumas jogadoras se tornam treinadoras de equipes escolares, universitárias ou profissionais. Elas usam seu conhecimento e experiência para treinar, motivar e aconselhar outras jogadoras.
        </li>
        <li>
          Educação: Algumas jogadoras se tornam professoras ou professoras em vários níveis e campos. Elas usam sua formação acadêmica e habilidades pedagógicas para educar e orientar estudantes.
        </li>
        <li>
          Mídia: Algumas jogadoras se tornam jornalistas, comentaristas ou analistas para vários meios de comunicação. Elas usam suas habilidades de comunicação e seu conhecimento sobre o esporte para reportar notícias e eventos de softbol.
        </li>
        <li>
          Empreendedorismo: Algumas jogadoras se tornam empresárias ou gestoras em diferentes setores e indústrias. Elas usam suas habilidades criativas e de negócios para desenvolver e vender produtos ou serviços.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Como você pode ver, existem muitas oportunidades para jogadoras de softbol usarem sua paixão e talento em diferentes áreas. A chave é definir seus objetivos, explorar suas opções e perseguir seus sonhos.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  








{/* Capítulo 4: Influências Internacionais e Eventos */}
<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 4: Influências Internacionais e Eventos
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    O softbol não é apenas um esporte americano, mas também internacional. O softbol é praticado em mais de 140 países em todo o mundo e teve um impacto significativo na cultura esportiva e no desenvolvimento em várias regiões. Neste capítulo, discutiremos as influências estrangeiras sobre o softbol na América e as influências americanas sobre o softbol no cenário mundial.
  </MKTypography>

  {/* Seção 4.1: Influências Estrangeiras */}
  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Influências Estrangeiras
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Equipe de softbol da Universidade de Oklahoma comemora a conquista do título da NCAA."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Jogadoras de Oklahoma comemoram o campeonato nacional
        </figcaption>
      </figure>
      O softbol na América deve muito às influências estrangeiras que enriqueceram e desafiaram o esporte. Algumas dessas influências incluem:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Japão: O Japão é um dos países mais fortes no softbol, tanto no nível profissional quanto universitário. O Japão tem uma longa tradição de softbol desde a década de 1930. O Japão também possui sua própria liga profissional de softbol, a Japan Softball League (JSL), composta por 12 equipes. O Japão teve um impacto significativo no softbol americano ao introduzir novas técnicas, táticas e estilos de jogo. O Japão também possui muitos programas de intercâmbio e parcerias com universidades e equipes americanas, proporcionando muitas jogadoras a oportunidade de aprender umas com as outras.
        </li>
        <li>
          Austrália: A Austrália é outro país que desempenha um papel significativo no softbol, especialmente no nível internacional. A Austrália tem sua própria seleção nacional de softbol, o Aussie Spirit, que conquistou múltiplas medalhas nas Olimpíadas, Campeonatos Mundiais e Copa do Mundo de Softbol. A Austrália também possui muitas jogadoras que competem na NPF, como Stacey Porter, Kaia Parnaby e Taylah Tsitsikronis. A Austrália teve uma influência substancial no softbol americano ao mostrar um alto nível de atletismo, perseverança e trabalho em equipe.
        </li>
        <li>
          Canadá: O Canadá, como país vizinho aos Estados Unidos, também possui uma forte cultura de softbol. O Canadá tem sua própria seleção nacional de softbol, o Canadian Wild, que também participa da NPF. O Canadá também possui muitas jogadoras que jogaram ou atualmente jogam em universidades americanas, como Danielle Lawrie, Jenn Salling e Sara Groenewegen. O Canadá teve um impacto significativo no softbol americano ao compartilhar uma paixão comum, visão e apreciação pelo esporte.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Essas influências estrangeiras contribuíram para o desenvolvimento do softbol na América, criando mais diversidade, competição e colaboração. Elas também contribuíram para o desenvolvimento de jogadoras universitárias ao expô-las a diferentes culturas, perspectivas e experiências.
    </MKTypography>
  </MKBox>

  {/* Seção 4.2: Americanos no Cenário Mundial */}
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Americanos no Cenário Mundial
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      O softbol na América também teve um impacto significativo no softbol no cenário mundial. Os Estados Unidos têm sido um país líder no softbol por muitos anos, tanto profissional quanto universitariamente. Os Estados Unidos produziram muitas histórias de sucesso e fontes de inspiração para outros países e jogadoras.
      <br />
      <br />
      Essas histórias de sucesso tiveram um impacto significativo no softbol no cenário mundial ao demonstrar um alto nível de desempenho, excelência e liderança. Elas também ganharam muito respeito e admiração de outros países e jogadoras, que as veem como exemplos e desafiantes.
      <br />
      <br />
      O papel do softbol universitário na preparação para competições internacionais é crucial. O softbol universitário fornece às jogadoras uma base sólida de habilidades, conhecimento e experiência que elas podem usar para competir com as melhores jogadoras do mundo. O softbol universitário também ajuda a descobrir, desenvolver e promover o talento e o potencial das jogadoras para que possam se destacar no mais alto nível.
    </MKTypography>
  </MKBox>
</MKBox>






                                  





<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capítulo 5: Bolsas de Estudo Esportivas e o Futuro dos Atletas
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    As bolsas de estudo esportivas oferecem inúmeras oportunidades para jovens atletas mostrarem seu talento e desenvolverem suas habilidades. Uma das oportunidades mais significativas é obter uma bolsa de estudos esportiva para estudar e competir no nível universitário. Neste capítulo, discutiremos a importância das bolsas de estudo esportivas e como a Sport Scholarships America pode ajudá-lo a realizar seu sonho.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 A Importância das Bolsas de Estudo
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Uma bolsa de estudos esportiva é um suporte financeiro fornecido por uma universidade a um estudante-atleta para combinar estudos e esportes. Pode cobrir os custos de mensalidades, livros, moradia, refeições e outras despesas. Além disso, pode oferecer benefícios como tutoria acadêmica, cuidados médicos, instalações de treinamento e equipamentos.
      <br />
      <br />
      Obter uma bolsa de estudos esportiva é competitivo, com muitos fatores desempenhando um papel no processo de concessão, incluindo realizações acadêmicas, desempenho atlético, personalidade, motivação, liderança e adaptabilidade. Portanto, é importante se preparar bem e se destacar da concorrência.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Como a Sport Scholarships America Pode Ajudar
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logotipo da Sport Scholarships America, mostrando equipamentos esportivos e um capelo de formatura, representando o foco em bolsas de estudo esportivas para estudantes-atletas."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logotipo da Sport Scholarships America
        </figcaption>
      </figure>
      A Sport Scholarships America é uma organização especializada em orientar jovens talentos esportivos para bolsas de estudo esportivas. Com especialistas apaixonados e experientes, a Sport Scholarships America apoia estudantes-atletas em cada etapa da jornada, desde a orientação inicial até a colocação final. Seus serviços incluem:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Uma entrevista de admissão pessoal para discutir os desejos, possibilidades e expectativas do estudante-atleta.</li>
        <li>Uma avaliação profissional do nível acadêmico e atlético do estudante-atleta.</li>
        <li>Uma extensa rede de contatos com treinadores, universidades e faculdades.</li>
        <li>Promoção eficaz do perfil e vídeos do estudante-atleta para treinadores potenciais.</li>
        <li>Conselhos especializados sobre as melhores opções com base nas preferências, metas e orçamento do estudante-atleta.</li>
        <li>Negociação das melhores ofertas de bolsas de estudo em nome do estudante-atleta.</li>
        <li>Auxílio na solicitação de documentos necessários, como visto, histórico escolar, diploma e testes de admissão.</li>
        <li>Preparação para a vida do estudante-atleta durante a bolsa de estudos, incluindo cultura, idioma, regras e dicas.</li>
        <li>Acompanhamento e acompanhamento durante o período da bolsa de estudos.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Quer explorar oportunidades de bolsas de estudo e alcançar seus objetivos atléticos e acadêmicos? <Link to="/pt/Serviços-Produtos">Veja a gama completa</Link> de serviços oferecidos pela Sport Scholarships America e agende uma consulta hoje.
    </MKTypography>
  </MKBox>
</MKBox>


        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;