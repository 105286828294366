/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React components
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Jeugd.png";
import post2 from "assets/images/TijdUS.png";
import post3 from "assets/images/Deal.png";

function Post2() {
  return (
    <MKBox component="section" py={0}>
      <Container>
        <Grid container item xs={12} lg={4}>
          <MKTypography variant="h3" mb={6}>
          Lee mi historia          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
        <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <TransparentBlogCard
              image={post1}
              title="Parte 1: Creciendo en los Países Bajos"
              description="En el entorno encantador de Nijmegen, comenzó mi historia de amor de toda la vida con el fútbol. ..."
              action={{
                type: "internal",
                route: "/es/Acerca-de-mí/Creciendo",
                color: "info",
                label: "Leer Más",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <TransparentBlogCard
              image={post2}
              title="Parte 2: Mi Tiempo en América"
              description="Mi tiempo en América fue como un viaje impresionante, lleno de experiencias inolvidables y crecimiento personal. ..."
              action={{
                type: "internal",
                route: "/es/Acerca-de-mí/Mi-tiempo-en-América",
                color: "info",
                label: "Leer Más",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <TransparentBlogCard
              image={post3}
              title="Parte 3: Mi Regreso a Los-Países-Bajos"
              description="Al regresar a los Países Bajos, experimenté una mezcla de nostalgia y determinación. Mi profunda pasión ..."
              action={{
                type: "internal",
                route: "/es/Acerca-de-mí/Mi-regreso-a-los-Países-Bajos",
                color: "info",
                label: "Leer Más",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Post2;

