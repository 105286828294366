/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Volleybal1.avif";
import Image2 from "assets/images/Sports_Images/volleybal2.webp";
import Image3 from "assets/images/Sports_Images/Volleybal3.webp";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import "../../../../H1asH3.css"


// Images
import { Link } from 'react-router-dom';



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Borsa di Studio per Pallavolo: Come Studiare e Giocare a Pallavolo in America?', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Capitolo 1: La Storia e lo Sviluppo della Pallavolo in America', isSubheader: false },
    { id: '1.1', title: '1.1 Primi Anni e il Ruolo delle Istituzioni Educativa', isSubheader: true },
    { id: '1.2', title: '1.2 Crescita e Professionalizzazione', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Capitolo 2: La Pallavolo Universitaria Oggi', isSubheader: false },
    { id: '2.1', title: '2.1 Principali Leghe e Conferenze', isSubheader: true },
    { id: '2.2', title: '2.2 Programmi Top e Squadre Prestigiose', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Capitolo 3: Dal College ai Professionisti', isSubheader: false },
    { id: '3.1', title: '3.1 Il Percorso verso il Professionismo', isSubheader: true },
    { id: '3.2', title: '3.2 Leghe Professionistiche e Opportunità di Carriera', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Capitolo 4: Influenze e Competizioni Internazionali', isSubheader: false },
    { id: '4.1', title: '4.1 Influenze Straniere', isSubheader: true },
    { id: '4.2', title: '4.2 Gli Americani sulla Scena Internazionale', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Capitolo 5: Borse di Studio Sportive e il Futuro degli Atleti', isSubheader: false },
    { id: '5.1', title: '5.1 L\'Importanza delle Borse di Studio', isSubheader: true },
    { id: '5.2', title: '5.2 Come Sport Scholarships America Può Aiutare', isSubheader: true },
  ];
  
  // Other components remain unchanged.
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Indice
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Nascondi</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Mostra</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
           <Grid item xs={12} md={9}>

























           <MKBox id="intro" mb={3}>
    <MKTypography variant="h1" className="h1-as-h3" mb={2}>
        Borsa di Studio per Pallavolo: Come Studiare e Giocare a Pallavolo in America?
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
            <img
                src={Image1}
                alt="Partita di pallavolo in Nebraska, con più di 92.000 spettatori."
                style={{
                    maxWidth: "100%",
                    border: "2px solid #fff",
                    boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                    cursor: "pointer",
                    outline: "none",
                }}
                onClick={() => openModal1(Image1)}
            />
            <figcaption style={{ 
                textAlign: 'left', 
                fontWeight: 'bold', 
                fontSize: '12px', 
                wordWrap: 'break-word', 
            }}>
                92.000 spettatori guardano una partita di pallavolo in Nebraska
            </figcaption>
        </figure>
        La pallavolo è uno sport che richiede molto dalle tue capacità fisiche e mentali. Devi reagire rapidamente, collaborare efficacemente, pensare tatticamente e avere una forte resistenza. Inoltre, la pallavolo è uno sport che offre molta gioia e soddisfazione, specialmente quando si gioca a un livello elevato. Ma come puoi, come talento della pallavolo, perseguire le tue ambizioni atletiche senza trascurare i tuoi studi? La risposta è: optando per una borsa di studio per la pallavolo in America.
        <br />
        <br />
        Una borsa di studio per la pallavolo è un aiuto finanziario offerto da un'università americana che ti permette di studiare e giocare a pallavolo lì. Con una borsa di studio per la pallavolo, puoi raggiungere due obiettivi contemporaneamente: puoi ottenere un diploma riconosciuto da un istituto educativo prestigioso mentre sviluppi ulteriormente le tue abilità nella pallavolo sotto la guida professionale. Inoltre, puoi goderti l'esperienza unica di vivere e imparare in una cultura diversa, con nuovi amici e sfide.
        <br />
        <br />
        Ma come puoi qualificarti per una borsa di studio per la pallavolo? Quali sono i requisiti e le aspettative? E come trovi l'università giusta che fa per te? Queste sono tutte domande a cui Sportbeursamerika.nl può aiutarti a rispondere. Sportbeursamerika.nl è lo specialista nel campo delle borse di studio sportive in America. Con anni di esperienza e una vasta rete, posso guidarti attraverso tutti i passaggi per applicare con successo per una borsa di studio per la pallavolo, dalla creazione di un profilo online alla gestione di tutte le questioni pratiche.
        <br />
        <br />
        Se vuoi saperne di più su come Sportbeursamerika.nl può aiutarti a studiare e giocare a pallavolo in America, continua a leggere o contattami per una conversazione introduttiva senza impegno. Sarei felice di raccontarti di più sulle possibilità e i vantaggi di una borsa di studio per la pallavolo in America.
    </MKTypography>
    {/* Aggiungi elenco puntato o altro contenuto se necessario */}
</MKBox>
{/* Capitoli e sottocapitoli principali con testo segnaposto */}
<MKBox id="Chapter 1" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Capitolo 1: La Storia e lo Sviluppo della Pallavolo in America
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        La pallavolo è uno sport che esiste da più di un secolo, ma ha conosciuto un significativo aumento di popolarità e professionalità, soprattutto negli ultimi decenni. In questo capitolo, approfondiremo la storia e lo sviluppo della pallavolo in America, dai suoi primi anni fino ad oggi.
    </MKTypography>

    <MKBox id="1.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            1.1 Primi Anni e il Ruolo delle Istituzioni Educativa
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            La pallavolo è stata inventata nel 1895 da William G. Morgan, un insegnante di educazione fisica presso la YMCA (Young Men's Christian Association) a Holyoke, Massachusetts. Aveva l'obiettivo di creare un gioco meno impegnativo fisicamente rispetto al basket, inventato un anno prima dal suo collega James Naismith. Inizialmente, chiamò il gioco "mintonette", ma presto divenne noto come "pallavolo" perché i giocatori dovevano palleggiare la palla sopra una rete.
            <br />
            <br />
            Il gioco si diffuse rapidamente negli Stati Uniti e in altri paesi, principalmente attraverso la YMCA e altre istituzioni educative. Nel 1916, uno dei primi tornei ufficiali di pallavolo si tenne presso l'Università della California a Berkeley. Nel 1922 fu istituita l'United States Volleyball Association (USVBA), che in seguito divenne USA Volleyball (USAV). Questa organizzazione divenne responsabile della regolamentazione e della promozione della pallavolo in America.
            <br />
            <br />
            Nel 1928 fu organizzato il primo campionato nazionale maschile dall'USVBA, seguito dal primo campionato nazionale femminile nel 1949. Nel 1955 si tenne il primo campionato mondiale maschile in Francia, dove l'America si classificò sesta. Nel 1956 si svolse il primo campionato mondiale femminile in Francia, con l'America al quarto posto.
        </MKTypography>
    </MKBox>
    <MKBox id="1.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            1.2 Crescita e Professionalizzazione
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Negli anni '60 e '70, la pallavolo iniziò a crescere e professionalizzarsi in America. Questo fu in parte dovuto all'introduzione di nuove regole, come l'adozione del sistema a punteggio di rally, dove ogni punto conta indipendentemente da chi sta servendo. La pallavolo guadagnò anche più attenzione attraverso le trasmissioni televisive dei Giochi Olimpici, dove la pallavolo è uno sport ufficiale dal 1964.
            <br />
            <br />
            Una delle figure chiave nella storia della pallavolo in America è Doug Beal, che ottenne successo sia come giocatore che come allenatore. Ha giocato per la nazionale dal 1970 al 1976 e ha vinto l'oro ai Giochi Panamericani nel 1975, tra gli altri successi. Successivamente divenne l'allenatore della nazionale dal 1977 al 1984 e li guidò al loro primo oro olimpico nel 1984 a Los Angeles. È stato anche coinvolto nella fondazione della Major League Volleyball (MLV), una lega professionale femminile che è esistita dal 1987 al 1989.
            <br />
            <br />
            Un altro evento significativo nella storia della pallavolo in America è stata la fondazione dell'Association of Volleyball Professionals (AVP) nel 1983, focalizzata sulla pallavolo da spiaggia. La pallavolo da spiaggia è una variante della pallavolo giocata sulla sabbia con squadre di due giocatori. L'AVP ha organizzato tornei e competizioni sia per uomini che per donne, contribuendo a popolarizzare la pallavolo da spiaggia con il pubblico. Alcuni noti giocatori di pallavolo da spiaggia includono Karch Kiraly, Sinjin Smith, Randy Stoklos, Misty May-Treanor e Kerri Walsh Jennings.
        </MKTypography>
    </MKBox>
</MKBox>




                                  







<MKBox id="Chapter 2" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Capitolo 2: La Pallavolo Universitaria Oggi
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        La pallavolo è uno degli sport più popolari e competitivi a livello universitario in America. Ogni anno, migliaia di squadre partecipano a varie competizioni e conferenze, culminando nei campionati nazionali. In questo capitolo, ti forniremo una panoramica delle principali leghe e conferenze e discuteremo alcuni dei programmi migliori e delle squadre prestigiose nella pallavolo universitaria.
    </MKTypography>

    <MKBox id="2.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.1 Principali Leghe e Conferenze
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
                <img
                    src={Image2}
                    alt="Squadra di pallavolo che celebra una vittoria insieme."
                    style={{
                        maxWidth: "100%",
                        border: "2px solid #fff",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                        cursor: "pointer",
                        outline: "none",
                    }}
                    onClick={() => openModal2(Image2)}
                />
                <figcaption style={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    wordWrap: 'break-word',
                }}>
                    Giocatori di pallavolo che celebrano una vittoria
                </figcaption>
            </figure>
            L'organizzazione principale responsabile dell'organizzazione e della regolamentazione della pallavolo universitaria in America è la National Collegiate Athletic Association (NCAA). La NCAA divide le università in tre divisioni in base alle loro dimensioni, al budget e alle prestazioni atletiche. Le divisioni sono:
        </MKTypography>

        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Divisione I: Questa è la divisione più alta, dove si trovano la maggior parte delle grandi e conosciute università. Le squadre in questa divisione ricevono maggiore attenzione, copertura mediatica e borse di studio. Ci sono circa 330 università in questa divisione, divise in 32 conferenze. Una conferenza è un gruppo di università che sono geograficamente o storicamente collegate e competono tra loro. Alcuni esempi di conferenze nella Division I includono il Big Ten, il Pac-12, il Southeastern Conference (SEC) e l'Atlantic Coast Conference (ACC).
                </li>
                <li>
                    Divisione II: Questa è la divisione intermedia, dove le università sono leggermente più piccole e meno conosciute rispetto alla Divisione I, ma mantengono comunque un alto livello di pallavolo. Le squadre in questa divisione ricevono meno attenzione, copertura mediatica e borse di studio rispetto alla Divisione I ma più rispetto alla Divisione III. Ci sono circa 300 università in questa divisione, divise in 23 conferenze. Alcuni esempi di conferenze nella Divisione II includono la Great Lakes Valley Conference (GLVC), la California Collegiate Athletic Association (CCAA) e la Gulf South Conference (GSC).
                </li>
                <li>
                    Divisione III: Questa è la divisione più bassa, dove le università si concentrano principalmente sull'eccellenza accademica piuttosto che sulle prestazioni atletiche. Le squadre in questa divisione non ricevono borse di studio per i loro atleti ma forniscono aiuti finanziari basati sul merito accademico. Ci sono circa 450 università in questa divisione, divise in 43 conferenze. Alcuni esempi di conferenze nella Divisione III includono la New England Small College Athletic Conference (NESCAC), la University Athletic Association (UAA) e la Centennial Conference.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Ogni anno, le squadre all'interno della loro divisione e conferenza competono in partite per qualificarsi per i campionati nazionali, anche conosciuti come Torneo NCAA. Si tratta di un torneo ad eliminazione diretta dove le migliori squadre di ogni divisione competono per il titolo di campione nazionale. Il torneo si tiene in primavera per gli uomini e in autunno per le donne.
        </MKTypography>

    </MKBox>
    <MKBox id="2.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            2.2 Programmi Top e Squadre Prestigiose
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            La pallavolo universitaria in America vanta un alto livello di competizione, e molte squadre eccellono nelle rispettive divisioni e conferenze. Tuttavia, alcune squadre si sono guadagnate una reputazione speciale e una storia grazie alle loro prestazioni costanti, ai giocatori e allenatori talentuosi e a un grande numero di tifosi. Ecco alcuni dei programmi migliori e delle squadre prestigiose nella pallavolo universitaria:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    UCLA Bruins: Rappresentando l'Università della California, Los Angeles, gli UCLA Bruins sono noti per la loro forte tradizione sia nella pallavolo maschile che femminile nella NCAA Division I. Storicamente, hanno vinto più campionati nazionali e hanno prodotto alcuni dei giocatori più rispettati nello sport. Questa squadra è un membro prominente del Pac-12 Conference.
                </li>
                <li>
                    Stanford Cardinal: La squadra femminile di pallavolo della Stanford University, situata a Stanford, in California, si è distinta vincendo un impressionante numero di campionati nazionali nella NCAA Division I, più di qualsiasi altra squadra nella loro categoria. Hanno costantemente prodotto giocatori talentuosi e competono nel Pac-12 Conference.
                </li>
                <li>
                    Penn State Nittany Lions: La squadra femminile di pallavolo della Pennsylvania State University a University Park, Pennsylvania, ha una reputazione di eccellenza nella NCAA Division I, con numerosi campionati nazionali al loro attivo. Sono noti per i loro titoli consecutivi e hanno formato giocatori eccezionali nel corso degli anni. Questa squadra è un membro significativo del Big Ten Conference.
                </li>
                <li>
                    BYU Cougars: La Brigham Young University a Provo, Utah, ha una rispettata squadra maschile di pallavolo nella NCAA Division I, che ha vinto il campionato nazionale più volte. La squadra ha costantemente eccelso nello sviluppo di top giocatori di pallavolo e fa parte della Mountain Pacific Sports Federation (MPSF).
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Questi programmi sono stati prominenti e influenti nella pallavolo universitaria negli Stati Uniti nel corso degli anni, noti per le loro forti tradizioni, i giocatori talentuosi e gli allenatori influenti.
        </MKTypography>
    </MKBox>
</MKBox>


                                              






                                  





<MKBox id="Chapter 3" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Capitolo 3: Dal College ai Professionisti
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        Per molti studenti-atleti che giocano a pallavolo a livello universitario, è un sogno giocare anche a pallavolo professionistica dopo la laurea. Ma quanto è realistico questo sogno e quali sono i passi da compiere per realizzarlo? In questo capitolo, parleremo del percorso verso il professionismo, delle leghe professionistiche e delle opportunità di carriera per i giocatori di pallavolo.
    </MKTypography>

    <MKBox id="3.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.1 Il Percorso verso il Professionismo
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Giocare a pallavolo a livello universitario è un ottimo modo per migliorare le tue abilità nella pallavolo, ottenere il tuo diploma accademico e svilupparsi personalmente. Tuttavia, è anche un ambiente altamente competitivo e impegnativo in cui devi investire molto tempo ed energia sia nello sport che nello studio. Pertanto, è importante essere realistici riguardo alle tue possibilità di giocare a pallavolo professionistica dopo la tua carriera universitaria.
            <br />
            <br />
            A differenza di altri sport come il basket o il calcio, non esiste una lega professionistica di pallavolo in America che recluta direttamente dalle università. Ciò significa che, come giocatore laureato, dovrai cercare altre opportunità per iniziare la tua carriera professionistica, di solito all'estero. Ci sono varie leghe professionistiche di pallavolo in Europa, Asia, America del Sud e altre regioni dove puoi giocare per club che ti offrono uno stipendio e altri vantaggi.
            <br />
            <br />
            Ma come entri in contatto con questi club e come assicuri di distinguerti tra gli altri giocatori che stanno cercando un contratto? Ecco alcuni consigli per aiutarti a trovare un club professionistico di pallavolo:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Crea un profilo online su una piattaforma come Volleyball Agency o Volleyball Market, dove puoi condividere i tuoi video, le statistiche, i riferimenti e le informazioni di contatto con i potenziali club e agenti.
                </li>
                <li>
                    Contatta i tuoi ex allenatori, compagni di squadra o altri contatti che hanno esperienza nella pallavolo professionistica o hanno contatti con club o agenti. Possono darti consigli, fare raccomandazioni o presentarti alle persone giuste.
                </li>
                <li>
                    Partecipa a provini, showcase o camp organizzati da club o organizzazioni professionistiche. Questi sono eventi in cui puoi mostrare le tue abilità a scout, allenatori o manager alla ricerca di nuovi giocatori. Assicurati di essere ben preparato, sia fisicamente che mentalmente, e lascia un'impressione positiva.
                </li>
                <li>
                    Sii flessibile e disposto a trasferirti in un altro paese o continente. Giocare a pallavolo professionistica spesso significa vivere in una cultura diversa, con una lingua, un clima e costumi diversi. Questo può essere una sfida significativa ma anche una grande opportunità per imparare e crescere come persona.
                </li>
            </ul>
        </MKTypography>
    </MKBox>
    <MKBox id="3.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            3.2 Leghe Professionistiche e Opportunità di Carriera
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Se riesci a ottenere un contratto con un club professionistico di pallavolo, puoi godere dei vantaggi di giocare al livello più alto dello sport. Puoi partecipare a emozionanti leghe, competere contro i migliori giocatori del mondo, viaggiare in diversi paesi e città e guadagnare denaro dalla tua passione. Ma quali sono le principali leghe professionistiche a cui puoi partecipare e quali sono le altre opportunità di carriera per i giocatori di pallavolo?
            <br />
            <br />
            Ecco alcune delle leghe professionistiche più prestigiose e lucrative al mondo:
        </MKTypography>

        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    La Serie A italiana: Questa è la divisione più alta della pallavolo italiana, composta da 14 squadre per gli uomini e 12 squadre per le donne. La Serie A è nota come una delle leghe più forti e competitive al mondo, con molti stelle internazionali e alti salari. Il campione della Serie A si qualifica anche per la Champions League, il torneo principale per club in Europa.
                </li>
                <li>
                    La Süper Lig turca: Questa è la divisione più alta della pallavolo turca, composta da 12 squadre per gli uomini e 14 squadre per le donne. La Süper Lig è anche una delle leghe principali al mondo, con significativi investimenti, attenzione mediatica e giocatori talentuosi. Il campione della Süper Lig si qualifica anche per la Champions League.
                </li>
                <li>
                    La Superliga brasiliana: Questa è la divisione più alta della pallavolo brasiliana, composta da 12 squadre per gli uomini e 12 squadre per le donne. La Superliga è la lega dominante in America del Sud, nota per la sua passione, atmosfera e qualità. Il campione della Superliga si qualifica anche per il Campionato del Mondo per club di pallavolo maschile FIVB, il principale torneo per club al mondo.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Oltre a giocare in leghe professionistiche, ci sono altre opportunità per i giocatori di pallavolo di continuare o ampliare le loro carriere. Ecco alcuni esempi:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Allenare o addestrare altri giocatori: Se vuoi concludere o ridurre la tua carriera da giocatore, puoi anche trasmettere le tue conoscenze ed esperienze ad altri giocatori come allenatore o istruttore. Puoi lavorare per un club professionistico, un'università, una scuola o un'altra organizzazione che offre pallavolo. Puoi anche fondare la tua scuola o accademia di pallavolo, dove puoi allenare e guidare giovani talenti.
                </li>
                <li>
                    Lavorare nei media o nell'industria: Se hai interesse nei media o nelle industrie legate alla pallavolo, puoi anche costruire una carriera lì. Puoi lavorare come commentatore, analista, giornalista, scrittore, fotografo o videomaker per vari media che coprono la pallavolo. Puoi anche lavorare come rappresentante, consulente, marketer o designer per diverse aziende che offrono prodotti o servizi di pallavolo.
                </li>
            </ul>
        </MKTypography>
    </MKBox>
</MKBox>

                                              






                                  






<MKBox id="Chapter 4" mb={3}>
    <MKTypography variant="h2" className="h1-as-h3" mb={2}>
        Capitolo 4: Influenze e Competizioni Internazionali
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
        La pallavolo è uno sport globale, giocato e seguito da milioni di persone in diversi paesi e continenti. La pallavolo universitaria in America non è isolata, ma è influenzata e sfidata dalla comunità internazionale della pallavolo. In questo capitolo, parleremo delle influenze straniere e delle competizioni internazionali che giocano un ruolo nella pallavolo universitaria.
    </MKTypography>

    <MKBox id="4.1" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.1 Influenze Straniere
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            La pallavolo universitaria in America deve molto alle influenze straniere che hanno arricchito e migliorato lo sport. Una delle influenze più significative è l'immigrazione di giocatori, allenatori e preparatori fisici da altri paesi che hanno portato le loro conoscenze, esperienze e cultura in America. Alcuni di questi immigrati sono diventati persino leggende nella pallavolo universitaria, come:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Arie Selinger: È un allenatore israeliano che si trasferì in America nel 1969 per allenare la nazionale femminile. Ha guidato la squadra alla loro prima medaglia olimpica, un argento nel 1984. Ha anche allenato diverse squadre universitarie, tra cui Long Beach State, dove è diventato quattro volte campione nazionale.
                </li>
                <li>
                    Andy Banachowski: È un giocatore polacco emigrato in America nel 1951. Ha giocato per UCLA, dove è diventato due volte campione nazionale. In seguito è diventato l'allenatore della squadra femminile di UCLA, vincendo sei campionati nazionali e più di 1000 partite.
                </li>
                <li>
                    Marv Dunphy: È un giocatore canadese arrivato in America nel 1972 per giocare per la Pepperdine University. Successivamente è diventato l'allenatore della squadra maschile di Pepperdine, vincendo quattro campionati nazionali. Ha anche allenato la nazionale maschile, che ha vinto l'oro alle Olimpiadi del 1988.
                </li>
            </ul>
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            Un'altra influenza importante è la popolarità e il livello della pallavolo internazionale, che fornisce ispirazione e motivazione per la pallavolo universitaria. La pallavolo internazionale include vari tornei regionali e globali in cui si incontrano le migliori squadre e giocatori del mondo. Alcuni di questi tornei includono:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    I Giochi Olimpici: Questo è il più grande e prestigioso evento sportivo al mondo, tenuto ogni quattro anni. La pallavolo è uno sport olimpico ufficiale dal 1964 sia per gli uomini che per le donne. Gli Stati Uniti hanno vinto un totale di 9 medaglie olimpiche nella pallavolo, tra cui 3 d'oro, 3 d'argento e 3 di bronzo.
                </li>
                <li>
                    Il Campionato del Mondo: Questo è il torneo più importante per le nazionali, tenuto ogni quattro anni. È organizzato dalla Federazione Internazionale di Pallavolo (FIVB), l'organo di governo globale per la pallavolo. Il Campionato del Mondo si tiene dal 1949 per gli uomini e dal 1952 per le donne. Gli Stati Uniti hanno vinto un totale di 5 medaglie ai Campionati del Mondo nella pallavolo, tra cui 2 d'oro, 1 d'argento e 2 di bronzo.
                </li>
                <li>
                    La Coppa del Mondo: Questo è un altro torneo per le nazionali, tenuto ogni quattro anni. È anche organizzato dalla FIVB ma differisce dal Campionato del Mondo per il numero di squadre partecipanti (12 invece di 24) e il sistema di qualificazione (basato sui campionati continentali). La Coppa del Mondo si tiene dal 1965 per gli uomini e dal 1973 per le donne. Gli Stati Uniti hanno vinto un totale di 7 medaglie alla Coppa del Mondo nella pallavolo, tra cui 2 d'oro, 3 d'argento e 2 di bronzo.
                </li>
            </ul>
        </MKTypography>
    </MKBox>
    <MKBox id="4.2" mb={2}>
        <MKTypography variant="h4" mb={1}>
            4.2 Gli Americani sulla Scena Internazionale
        </MKTypography>
        <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
            La pallavolo universitaria in America non solo ha imparato dalla pallavolo internazionale, ma ha anche contribuito significativamente alla pallavolo internazionale. Molti giocatori americani che hanno gareggiato a livello universitario hanno anche partecipato con successo a competizioni internazionali, sia per le loro nazionali che per club professionistici. Ecco alcuni esempi di giocatori americani che si sono distinti sulla scena internazionale:
        </MKTypography>
        <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
            <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
                <li>
                    Karch Kiraly: È uno dei più grandi giocatori di pallavolo di tutti i tempi, eccellendo sia nella pallavolo indoor che sulla spiaggia. Ha giocato per UCLA, dove è diventato tre volte campione nazionale. Ha anche giocato per la nazionale, vincendo due medaglie d'oro olimpiche nella pallavolo indoor (1984 e 1988) e una nella pallavolo sulla spiaggia (1996). Ha anche giocato professionalmente in Italia, dove ha vinto quattro titoli italiani e due titoli di Champions League.
                </li>
                <li>
                    Logan Tom: È una delle più grandi giocatrici di pallavolo di tutti i tempi, giocando sia come attaccante che come libero. Ha giocato per Stanford, dove è diventata campionessa nazionale e quattro volte All-American. Ha anche giocato per la nazionale, partecipando a quattro Giochi Olimpici e vincendo due medaglie d'argento (2008 e 2012). Ha anche giocato professionalmente in vari paesi, tra cui Brasile, Turchia, Giappone e Cina.
                </li>
                <li>
                    Matt Anderson: È uno dei migliori giocatori di pallavolo maschile dell'attuale generazione, capace di giocare sia come opposto che come schiacciatore esterno. Ha giocato per Penn State, dove è diventato campione nazionale nel 2008 e è stato nominato All-American nel 2007 e nel 2008. Gioca anche per la nazionale, vincendo il bronzo alle Olimpiadi del 2016 e l'oro alla Coppa del Mondo del 2015. Ha anche giocato professionalmente in Russia, dove ha vinto tre titoli russi e due titoli di Champions League.
                </li>
            </ul>
        </MKTypography>
    </MKBox>
</MKBox>

                      






                                  






<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Capitolo 5: Borse di Studio Sportive e il Futuro degli Atleti
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le borse di studio sportive offrono numerose opportunità ai giovani atleti di mostrare il loro talento e sviluppare le loro abilità. Una delle opportunità più significative è ottenere una borsa di studio sportiva per studiare e competere a livello universitario. In questo capitolo, discuteremo dell'importanza delle borse di studio sportive e di come Sport Scholarships America può aiutarti a realizzare il tuo sogno.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 L'Importanza delle Borse di Studio
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Una borsa di studio sportiva è un sostegno finanziario fornito da un'università a uno studente-atleta per combinare studio e sport. Può coprire i costi di iscrizione, libri, alloggio, pasti e altre spese. Inoltre, può offrire vantaggi come il tutorato accademico, l'assistenza medica, le strutture di allenamento e l'attrezzatura.
      <br />
      <br />
      Ottenere una borsa di studio sportiva è competitivo, con molti fattori che influenzano il processo di assegnazione, tra cui risultati accademici, prestazioni sportive, personalità, motivazione, leadership e adattabilità. È quindi importante prepararsi bene e distinguersi dalla concorrenza.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Come Sport Scholarships America Può Aiutare
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo di Sport Scholarships America, raffigurante attrezzature sportive e una toga, simbolo del focus sulle borse di studio sportive per gli studenti-atleti."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo di Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America è un'organizzazione specializzata nell'orientamento dei giovani talenti sportivi verso borse di studio sportive. Con esperti appassionati ed esperti, Sport Scholarships America supporta gli studenti-atleti in ogni fase del percorso, dall'orientamento iniziale alla collocazione finale. I loro servizi includono:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Un'intervista personale per discutere i desideri, le possibilità e le aspettative dello studente-atleta.</li>
        <li>Una valutazione professionale del livello accademico e sportivo dello studente-atleta.</li>
        <li>Una vasta rete di contatti con allenatori, università e college.</li>
        <li>Promozione efficace del profilo e dei video dello studente-atleta presso allenatori potenziali.</li>
        <li>Consulenza esperta sulle migliori opzioni in base alle preferenze, agli obiettivi e al budget dello studente-atleta.</li>
        <li>Negoziazione delle migliori offerte di borse di studio per conto dello studente-atleta.</li>
        <li>Assistenza nella richiesta dei documenti necessari, come visto, trascrizione, diploma e test di ammissione.</li>
        <li>Preparazione alla vita dello studente-atleta durante la borsa di studio, compresa cultura, lingua, regole e consigli.</li>
        <li>Assistenza e monitoraggio durante il periodo di borsa di studio.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vuoi esplorare le opportunità di borse di studio e raggiungere i tuoi obiettivi sportivi e accademici? <Link to="/it/Servizi-Prodotti">Consulta la gamma completa</Link> dei servizi offerti da Sport Scholarships America e prenota una consultazione oggi stesso.
    </MKTypography>
  </MKBox>
</MKBox>
          
        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;