import React, { useState, useRef } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import states from './statesData';
import { useMediaQuery } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import ImageModal from '../../../../../components/ImageModal';

function Beschrijving() {
  const descriptionContainerRef = useRef(null);
  const isSmallScreen = useMediaQuery("(max-width:800px)");

  // States for modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedState, setSelectedState] = useState(null);

  // Function to open modal
  const openModal = (state) => {
    setSelectedState(state);
    setIsModalOpen(true);
  };

  // Function to close modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedState(null);
  };

  const scrollToState = (stateName) => {
    const stateElement = document.getElementById(stateName);
    if (stateElement) {
      stateElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <MKBox component="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container justifyContent="center" alignItems="flex-start" py={6}>
          <Grid item xs={12} md={3}>
            {isSmallScreen ? (
              <div style={{ marginBottom: "10px" }}>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel htmlFor="state-select">Select a state</InputLabel>
                  <Select
                    native
                    label="Select a state"
                    inputProps={{
                      name: "state",
                      id: "state-select",
                    }}
                    onChange={(event) => scrollToState(event.target.value)}
                  >
                    <option value="" disabled>
                      Select a state
                    </option>
                    {states.map((state, index) => (
                      <option key={index} value={state.name}>
                        {state.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
            ) : (
              <div>
                {states.map((state, index) => (
                  <div
                    key={index}
                    onClick={() => scrollToState(state.name)}
                    style={{
                      cursor: "pointer",
                      marginBottom: "10px",
                      fontSize: "14px", // Adjust the font size as needed
                    }}
                  >
                    {state.name}
                  </div>
                ))}
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={9}>
            <div ref={descriptionContainerRef}>
              {states.map((state, index) => (
                <MKBox key={index} mb={3}>
                  <div id={state.name}>
                    <MKTypography variant="h4" mb={1}>
                      {state.name}
                    </MKTypography>
                    {/* Conditional rendering for image with square wrapping */}
                    {state.image && (
                      <figure
                        style={{
                          float: 'right', // or 'left' for left alignment
                          margin: '0 10px 10px 0', // Adjust margins for spacing
                          maxWidth: '40%', // Adjust the size of the bounding box
                        }}
                      >
                         <img
            src={state.image}
            alt={state.imageDescription} // Dynamic alt text based on state data
            style={{
              maxWidth: '100%',
              border: '2px solid #fff',
              boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.4)',
              cursor: 'pointer',
              outline: 'none',
            }}
            onClick={() => openModal(state)}
          />
          <figcaption style={{
            textAlign: 'left',
            fontWeight: 'bold',
            fontSize: '12px',
            wordWrap: 'break-word',
          }}>
            {state.imageDescription}
          </figcaption>
        </figure>
                    )}

                    {/* Text description */}
                    {state.description.map((paragraph, idx) => (
                      <MKTypography
                        key={idx}
                        variant="body1"
                        fontWeight="light"
                        color="text"
                        mb={2}
                      >
                        {paragraph}
                      </MKTypography>
                    ))}
                  </div>
                </MKBox>
              ))}
            </div>
          </Grid>
          <ImageModal
  open={isModalOpen}
  onClose={closeModal}
  imageSrc={selectedState?.image}
  altText={selectedState?.imageDescription} // Dynamic alt text for the modal image
/>

        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;
