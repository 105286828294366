/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================
* Página del producto: https://www.creative-tim.com/product/material-kit-pro-react
* Derechos de autor 2023 Creative Tim (https://www.creative-tim.com)
Codificado por www.creative-tim.com
=========================================================
*/

// Componentes materiales de @mui
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Componentes de Material Kit 2 PRO React
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Imágenes
import post2 from "assets/images/TijdUS.png";
import "../../../../H1asH3.css"

function Perfil() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center" />
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} justifyContent="space-between" alignItems="center" mb={1}>
                <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Parte 1: Creciendo en los Países Bajos</MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text">
  <br />
  En el entorno encantador de Nijmegen, comenzó mi historia de amor de toda la vida con el fútbol. Como niño de las calles de Nijmegen, sentí la magia del deporte con cada paso que daba en el césped verde. Mi primer club fue BVC '12, donde como joven experimenté mis primeros dribles y goles. Fue una época de inocencia, donde descubrí mi pasión por el deporte y donde se sentaron las bases para mi futuro.
  <br /><br />

  Después de mi aventura en BVC '12, emprendí un viaje a través de clubes de fútbol locales como Quick, NEC y Union. Este viaje formó la base de mi educación futbolística y me acercó a mi sueño: llegar a la cima del fútbol juvenil holandés. Como joven futbolista, soñaba con jugar en grandes estadios rodeado de una multitud de fanáticos que vitoreaban. Era un sueño compartido por muchos jóvenes talentos en los Países Bajos, y estaba decidido a hacer todo lo posible para que este sueño se hiciera realidad.
  <br /><br />

  Pero a medida que desarrollaba mi talento futbolístico, también empecé a darme cuenta de lo crucial que es una buena educación para un futuro sólido. En los Países Bajos, a veces parecía que mis ambiciones en el fútbol y la educación eran contradictorias. Era un desafío equilibrar ambas pasiones.
  <br /><br />

  La diversidad deslumbrante y las enormes oportunidades de América siempre me habían fascinado, y esta fascinación solo creció durante unas vacaciones con mi familia. Estaba casi seguro de que regresaría a este país, tal vez por un período prolongado. Más tarde, a través de conocidos, escuché historias sobre las oportunidades de combinar una carrera en el fútbol con estudios universitarios en América, y mi deseo de seguir el mismo camino creció exponencialmente.
  <br /><br />

  Sin embargo, no fue fácil organizarlo todo. El complejo proceso de becas deportivas y oportunidades de estudio en América inicialmente parecía abrumador. En algún lugar sabía que podría ser más sencillo y accesible, lo que finalmente fue mi motivación para crear esta empresa años después.
  <br /><br />

  Después de contactar con varios entrenadores, el entrenador de la Universidad de Tulsa tenía una historia particularmente convincente para compartir. Hablaba de un equipo que siempre competía por premios, consistentemente clasificado entre los 25 mejores del país y participaba en grandes competiciones. Sus palabras me dieron la confianza de que este era el lugar donde podía cumplir mis sueños tanto en el fútbol como en las ambiciones académicas.
  <br /><br />

  Y así sucedió, dos semanas después de cumplir 18 años, mi capítulo en los Países Bajos había llegado a su fin (temporalmente). Abordé el avión hacia América, listo para la aventura que me esperaba. Decidido a unir mis pasiones por el fútbol y la educación, me encontraba al principio de un emocionante viaje. Más sobre mis experiencias en América, estaré encantado de compartirlas contigo en la parte 2 de mi historia, donde profundizaré en mis aventuras en los Estados Unidos.
</MKTypography>
<hr style={{ margin: '20px 0'}} />

<MKTypography component="a" href="#" variant="body1" fontWeight="light" color="info" mt={3}>
  <Grid container justifyContent="flex-end"> {/* Esta línea ha sido actualizada */}
    <Grid item xs={12} lg={6} mb={{ xs: 3, lg: 0 }}>
      <TransparentBlogCard
        image={post2}
        title="Parte 2: Mi Tiempo en América"
        action={{
          type: "internal",
          route: "/es/Acerca-de-mí/Mi-tiempo-en-América",
          color: "info",
          label: "leer más",
        }}
      />
    </Grid>
  </Grid>
</MKTypography>

</Grid>
</Grid>
</Grid>
</Container>
</MKBox>
);
}

export default Perfil;
