/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Softbal1.jpeg";
import Image2 from "assets/images/Sports_Images/Softbal2.jpg";
import Image3 from "assets/images/Sports_Images/Softbal3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";



// Images
import { Link } from 'react-router-dom';
import "../../../../H1asH3.css"



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Softball-stipendier i USA: Hvordan studere og spille softball i Amerika', isSubheader: false },
  
    { id: 'Chapter 1', title: 'Kapittel 1: Historien og utviklingen av softball i Amerika', isSubheader: false },
    { id: '1.1', title: '1.1 De tidlige årene og skolenes og universitetenes rolle', isSubheader: true },
    { id: '1.2', title: '1.2 Vekst og profesjonalisering', isSubheader: true },
  
    { id: 'Chapter 2', title: 'Kapittel 2: Universitetssoftball i dag', isSubheader: false },
    { id: '2.1', title: '2.1 Viktige ligaer og konferanser', isSubheader: true },
    { id: '2.2', title: '2.2 Topp programmer og prestisjefylte lag', isSubheader: true },
  
    { id: 'Chapter 3', title: 'Kapittel 3: Fra college til profesjonelle', isSubheader: false },
    { id: '3.1', title: '3.1 Veien til profesjonalisme', isSubheader: true },
    { id: '3.2', title: '3.2 Profesjonell softball og andre karrieremuligheter', isSubheader: true },
  
    { id: 'Chapter 4', title: 'Kapittel 4: Internasjonale innflytelser og hendelser', isSubheader: false },
    { id: '4.1', title: '4.1 Utenlandske innflytelser', isSubheader: true },
    { id: '4.2', title: '4.2 Amerikanere på verdensscenen', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Kapittel 5: Idrettsstipender og fremtiden for utøvere', isSubheader: false },
    { id: '5.1', title: '5.1 Stipendienes betydning', isSubheader: true },
    { id: '5.2', title: '5.2 Hvordan Sport Scholarships America kan hjelpe', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };
  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };
  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Innhold
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Skjul</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Vis</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Softball-stipendier i USA: Hvordan studere og spille softball i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Flyfoto av et fullpakket stadion under en colleget-ballkamp."
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Et fullpakket stadion under en kamp
      </figcaption>
    </figure>
    Softball er en sport som oppstod i USA på slutten av 1800-tallet. Det er en variant av baseball, men med en større ball, et mindre felt og kortere innings. Softball spilles av to lag med ni spillere, som bytter på å slå og forsvare. Målet er å score flere løp enn motstanderlaget ved å løpe rundt de fire basene etter å ha slått ballen.
    <br />
    <br />
    Softball fikk raskt popularitet i USA, spesielt blant skoler og universiteter. Det ble sett på som en sport som fremmet fysisk form, mental skarphet og sosial interaksjon. Det tiltrekke også mye talent og konkurranse, spesielt på collegiat nivå. Collegiat softball representerer det høyeste nivået av softball i USA, der student-idrettsutøvere konkurrerer for universitetet eller høyskolen sin. Collegiat softball tilbyr prestisje, anerkjennelse og stipendier for toppspillere.
    <br />
    <br />
    Sportbeursamerika.nl er en organisasjon dedikert til å hjelpe unge idrettsutøvere med å sikre softball-stipendier. Sportbeursamerika.nl har et team av eksperter som kan veilede deg i å finne det beste universitetet eller høyskolen basert på din akademiske og idrettslige profil. De kan også hjelpe deg med å forberede søknaden din, lage videoen din, koble deg med trenere og ordne visumet ditt. Sportbeursamerika.nl har allerede hjulpet mange suksesshistorier med å oppfylle drømmen om å studere og spille softball i Amerika.
    <br />
    <br />
    Hvis du vil lære mer om softball i Amerika og hvordan du får et softball-stipend, fortsett å lese!
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>

{/* Hovedkapitler og underkapitler med plassholder tekst */}
<MKBox id="Chapter 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 1: Historie og Utvikling av Softball i Amerika
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Softball er en sport som oppsto i USA, men har spredt seg over hele verden. Det er en sport som har gjennomgått mange endringer og innovasjoner, med mange innflytelsesrike personer og hendelser. I dette kapittelet vil vi gi deg en oversikt over historien og utviklingen av softball i Amerika, fra de tidlige årene til i dag.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Tidlige År og Skolens og Universitetenes Rolle
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Softball ble først spilt i 1887 i Chicago som en innendørs variant av baseball. Det ble konseptualisert av en gruppe menn som feiret Thanksgiving i et gymnasium. De brukte en boksehanske som ball og en kosteskaft som kølle. Spillet fikk raskt popularitet blant forskjellige grupper, inkludert brannmenn, fabrikkarbeidere og kirkegjengere.
      <br />
      <br />
      I de tidlige årene av softball var det ingen standardiserte regler eller dimensjoner. Spillet ble tilpasset tilgjengelig plass og antall spillere. Det var forskjellige varianter av softball, som mushball, kittenball, diamond ball og indoor baseball. Termen "softball" ble offisielt brukt i 1926.
      <br />
      <br />
      Skoler og universiteter spilte en betydelig rolle i utviklingen av softball. De så på softball som en sport egnet for både gutter og jenter, som fremmet fysisk utdanning og rekreasjon. De organiserte også de første skole- og universitetslagene og de første organiserte konkurransene. En av de første skolekonkurransene var Public Schools Athletic League i New York City, som startet softball for jenter i 1913. En av de første universitetskonkurransene var National Collegiate Athletic Association (NCAA), som begynte med softball for kvinner i 1957.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Vekst og Profesjonalisering
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Softball vokste til å bli en populær og prestisjefylt sport i Amerika, spesielt blant kvinner. Dette skyldtes delvis innføringen av Title IX i 1972, en lov som garanterte like muligheter for kvinner i utdanning, inkludert idrettsstipend. Dette ga flere kvinnelige studentidrettsutøvere muligheten til å spille softball på collegiat nivå.
      <br />
      <br />
      I tillegg til collegiat softball, oppstod det profesjonelle softball-ligaer i Amerika. Den første profesjonelle kvinnelige softball-ligaen var International Women’s Professional Softball Association (IWPSA), grunnlagt i 1976. Denne ligaen besto av ti lag fra forskjellige byer, som New York, Chicago og Los Angeles. Ligaen varte i fire sesonger, til 1980.
      <br />
      <br />
      Den andre profesjonelle kvinnelige softball-ligaen var Women’s Pro Softball League (WPSL), etablert i 1997. Denne ligaen hadde seks lag fra forskjellige stater, inkludert Florida, Georgia og Virginia. Ligaen endret senere navn til National Pro Fastpitch (NPF). NPF var aktiv til 2021 og gjennomgikk to avlyste sesonger på grunn av COVID-19. En ny softball-liga, Women's Professional Fastpitch (WPF), ble lansert i 2022.
      <br />
      <br />
      De profesjonelle softball-ligaene hadde en betydelig innvirkning på collegiat softball. Mange profesjonelle spillere var tidligere studentidrettsutøvere som fortsatte karrierene sine etter endt utdanning. Noen profesjonelle spillere ble også trenere eller instruktører for collegiat-lag eller individuelle spillere. I tillegg utmerket mange profesjonelle spillere seg som rollemodeller og inspirasjonskilder for unge softballspillere.
      <br />
      <br />
      Noen eksempler på sentrale figurer innen softball-verdenen er:

    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Jennie Finch: En tidligere pitcher for Arizona Wildcats og det amerikanske landslaget. Hun vant to olympiske medaljer, gull i 2004 og sølv i 2008. Hun spilte også for Chicago Bandits i NPF. Hun anses som en av tidenes beste pitchere og en ikon for sporten.
        </li>
        <li>
          Cat Osterman: En tidligere pitcher for Texas Longhorns og det amerikanske landslaget. Hun vant også to olympiske medaljer, gull i 2004 og sølv i 2008. Hun spilte for Rockford Thunder og Houston Scrap Yard Dawgs i NPF. Hun ble senere trener for Texas State Bobcats.
        </li>
        <li>
          Monica Abbott: En tidligere pitcher for Tennessee Volunteers og det amerikanske landslaget. Hun vant en sølv-OL-medalje i 2008. Senere spilte hun for Scrap Yard Fast Pitch i NPF og Toyota Red Terriers i Japan. Hun er den første kvinnelige softballspilleren som signerte en million-dollar kontrakt.
        </li>
      </ul>
    </MKTypography>
  </MKBox>
</MKBox>

                                              





                                  






<MKBox id="Chapter 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 2: Universitetssoftball i dag
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Softball er en av de mest populære og konkurransedyktige idrettene på universitetsnivå i Amerika. Hvert år deltar mer enn tusen lag i universitetssesongen, som løper fra februar til juni. Høydepunktet i sesongen er Women's College World Series (WCWS), nasjonalmesterskapet for universitetssoftball.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Viktige Ligaer og Konferanser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Spillere fra Tennessee og Alabama i aksjon under College World Series."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
         }}>
          Spillere i aksjon under College World Series
        </figcaption>
      </figure>
      Universitetssoftball reguleres av National Collegiate Athletic Association (NCAA), en organisasjon som er ansvarlig for å organisere og administrere idrettsprogrammer for universiteter og høyskoler i Amerika. NCAA deler universitetene inn i tre divisjoner basert på størrelse, budsjett og idrettslige prestasjoner. Disse divisjonene er:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Divisjon I: Den høyeste og mest prestisjefylte divisjonen, med mer enn 300 universiteter og over 10 000 student-idrettsutøvere. Divisjon I tilbyr flest stipendier, de beste fasilitetene og den sterkeste konkurransen. Divisjon I består av 32 konferanser, regionale grupper av universiteter som spiller kamper mot hverandre. Noen eksempler på konferanser er Southeastern Conference (SEC), Pac-12 Conference og Big Ten Conference.
        </li>
        <li>
          Divisjon II: Midtdelvisjonen, med mer enn 300 universiteter og over 8000 student-idrettsutøvere. Divisjon II tilbyr færre stipendier, men gir fortsatt gode fasiliteter og sterk konkurranse. Divisjon II består av 23 konferanser, som Great Lakes Valley Conference (GLVC), Sunshine State Conference og California Collegiate Athletic Association (CCAA).
        </li>
        <li>
          Divisjon III: Den laveste divisjonen, med mer enn 400 universiteter og over 6000 student-idrettsutøvere. Divisjon III tilbyr ikke stipendier, men gir sterke akademiske programmer og en idrettsopplevelse. Divisjon III består av 43 konferanser, inkludert New England Small College Athletic Conference (NESCAC), Centennial Conference og University Athletic Association (UAA).
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Hver divisjon har sitt eget mesterskap i slutten av sesongen, der de beste lagene fra hver konferanse kvalifiserer seg for en utslagsstilturnering. Mesterskapet i Divisjon I kalles Women's College World Series (WCWS), som holdes i Oklahoma City. Mesterskapet i Divisjon II kalles NCAA Division II Softball Championship, holdt i Denver. Mesterskapet i Divisjon III kalles NCAA Division III Softball Championship, holdt i Salem.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Topp Programmer og Prestisjetunge Lag
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Universitetssoftball har produsert mange topp programmer og prestisjetunge lag som har utmerket seg gjennom sin vellykkede historie, talentfulle spillere og innflytelsesrike trenere. Noen av disse programmene og lagene inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          UCLA Bruins: Dette programmet regnes som et av de mest vellykkede i historien til universitetssoftball, med et imponerende antall nasjonale titler på deres navn. Gjennom årene har de produsert olympiske medaljevinnere, inkludert velkjente navn som Lisa Fernandez, Natasha Watley og Stacey Nuveman.
        </li>
        <li>
          Oklahoma Sooners: Oklahoma Sooners er et bemerkelsesverdig sterkt lag og har jevnlig levert topprestasjoner. De har vunnet flere nasjonale titler og har hatt imponerende seiersrekker tidligere.
        </li>
        <li>
          Arizona Wildcats: Softballprogrammet til Arizona Wildcats skryter av en imponerende liste over nasjonale titler. De har også en respektert trener, Mike Candrea, med en utmerket historie med mange seire og til og med olympisk gull som trener for det amerikanske nasjonale softballaget.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Disse programmene og lagene bidrar til utviklingen av softballspillere ved å tilby dem et høyt nivå av trening, coaching og konkurranse. De hjelper også spillerne med å forbedre ferdighetene sine, kunnskapen og mentaliteten, og forbereder dem på neste steg i karrieren deres.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  






<MKBox id="Chapter 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 3: Fra College til Profesjonelle
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Softball er en idrett som tilbyr mange muligheter for student-idrettsutøvere til å fortsette karrieren etter college-perioden. Noen student-idrettsutøvere velger å spille profesjonell softball, mens andre utforsker andre karrieremuligheter. I dette kapittelet diskuterer vi veien til profesjonalisme, profesjonelle softball-ligaer og andre karrieremuligheter for softball-spillere.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Veien til Profesjonalisme
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Å spille college-softball er god forberedelse for å spille profesjonell softball. Mange student-idrettsutøvere som utmerker seg i college-softball blir speidet eller draftet av profesjonelle lag. Dette betyr at de tilbys kontrakt for å spille for et bestemt lag, vanligvis mot en lønn og andre fordeler.
      <br />
      <br />
      Imidlertid er det ikke enkelt å spille profesjonell softball. Konkurransen er svært høy, og spillerne må stadig tilpasse seg nye situasjoner, motstandere og utfordringer. Dessuten er lønnene til profesjonelle softballspillere ikke veldig høye sammenlignet med andre idretter. Ifølge en rapport fra 2016 var gjennomsnittslønnen for en NPF-spiller omtrent $ 5000 til $ 6000 per sesong. Dette betyr at mange profesjonelle spillere trenger å ha en annen jobb eller en annen inntektskilde for å få endene til å møtes.
      <br />
      <br />
      Derfor velger noen student-idrettsutøvere å ikke spille profesjonell softball, men å fullføre studiene eller forfølge en annen karriere. Dette kan også være et godt valg fordi college-softball utvikler mange ferdigheter og egenskaper som er nyttige på andre områder, som ledelse, samarbeid, kommunikasjon, disiplin og problemløsning.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Profesjonell Softball og Andre Karrieremuligheter
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Som nevnt tidligere er den viktigste profesjonelle softball-ligaen i Amerika National Pro Fastpitch (NPF). NPF består for øyeblikket av fem lag: Aussie Peppers of Minnesota, Canadian Wild of Southern Illinois, Chicago Bandits, Cleveland Comets og USSSA Pride. NPF har også et samarbeid med Chinese Softball Association (CSA), som sender to lag til NPF: Beijing Eagles og Shanghai Shengli.
      <br />
      <br />
      Imidlertid er ikke NPF den eneste profesjonelle softball-ligaen i verden. Det er også andre ligaer i land som Japan, Australia, Italia og Mexico. Noen amerikanske spillere velger å spille i disse ligaene fordi de er bedre betalt eller fordi de ønsker å oppleve en annen kultur.
      <br />
      <br />
      Bortsett fra å spille profesjonell softball, er det andre karrieremuligheter for softball-spillere. Noen eksempler inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Coaching: Noen spillere blir trenere for skole-, college- eller profesjonelle lag. De bruker kunnskapen og erfaringen sin til å trene, motivere og veilede andre spillere.
        </li>
        <li>
          Utdanning: Noen spillere blir lærere eller professorer på ulike nivåer og innen ulike felt. De bruker akademisk bakgrunn og pedagogiske ferdigheter til å utdanne og veilede studenter.
        </li>
        <li>
          Media: Noen spillere blir journalister, kommentatorer eller analytikere for ulike medieorganisasjoner. De bruker kommunikasjonsferdighetene og innsikten sin i sporten til å rapportere om softball-nyheter og arrangementer.
        </li>
        <li>
          Entreprenørskap: Noen spillere blir gründere eller ledere innen ulike sektorer og bransjer. De bruker kreative og forretningsmessige ferdigheter til å utvikle og selge produkter eller tjenester.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Som du kan se, er det mange muligheter for softball-spillere å bruke lidenskapen og talentet sitt i ulike områder. Nøkkelen er å sette målene dine, utforske alternativene dine og forfølge drømmene dine.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  







<MKBox id="Chapter 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 4: Internasjonale Innflytelser og Hendelser
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Softball er ikke bare en amerikansk sport, men også en internasjonal. Softball spilles i mer enn 140 land over hele verden og har hatt betydelig innvirkning på idrettskulturen og utviklingen i ulike regioner. I dette kapittelet vil vi diskutere utenlandske innflytelser på softball i Amerika og amerikanske innflytelser på softball på verdensscenen.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Utenlandske Innflytelser
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image3}
          alt="Oklahoma University softball team celebrates winning the NCAA title."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal3(Image3)}
        />
        <figcaption style={{
          textAlign: 'left',
          fontWeight: 'bold',
          fontSize: '12px',
          wordWrap: 'break-word',
        }}>
          Oklahoma-spillere feirer nasjonalt mesterskap
        </figcaption>
      </figure>
      Softball i Amerika skylder mye på de utenlandske innflytelsene som har beriket og utfordret sporten. Noen av disse innflytelsene inkluderer:
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Japan: Japan er en av de sterkeste landene innen softball, både på profesjonelt og universitetsnivå. Japan har en lang tradisjon med softball som går tilbake til 1930-tallet. Japan har også sin egen profesjonelle softball-liga, Japan Softball League (JSL), som består av 12 lag. Japan har hatt betydelig innvirkning på amerikansk softball ved å introdusere nye teknikker, taktikker og spillestiler. Japan har også mange utvekslingsprogrammer og partnerskap med amerikanske universiteter og lag, noe som gir mange spillere muligheten til å lære av hverandre.
        </li>
        <li>
          Australia: Australia er et annet land som spiller en betydelig rolle i softball, spesielt på internasjonalt nivå. Australia har sitt eget nasjonale softball-lag, Aussie Spirit, som har vunnet flere medaljer i OL, verdensmesterskapet og Softballens Verdenscup. Australia har også mange spillere som konkurrerer i NPF, som Stacey Porter, Kaia Parnaby og Taylah Tsitsikronis. Australia har hatt betydelig innflytelse på amerikansk softball ved å vise frem et høyt nivå av atletikk, utholdenhet og samarbeid.
        </li>
        <li>
          Canada: Canada, som et naboland til Amerika, har også en sterk softballkultur. Canada har sitt eget nasjonale softball-lag, Canadian Wild, som også deltar i NPF. Canada har også mange spillere som har spilt eller for øyeblikket spiller for amerikanske universiteter, som Danielle Lawrie, Jenn Salling og Sara Groenewegen. Canada har hatt betydelig innflytelse på amerikansk softball ved å dele en felles lidenskap, visjon og verdsettelse for sporten.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Disse utenlandske innflytelsene har bidratt til utviklingen av softball i Amerika ved å skape mer mangfold, konkurranse og samarbeid. De har også bidratt til utviklingen av universitetsspillere ved å eksponere dem for ulike kulturer, perspektiver og erfaringer.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Amerikanere på Verdensscenen
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Softball i Amerika har også hatt betydelig innvirkning på softball på verdensscenen. Amerika har vært et ledende land innen softball i mange år, både på profesjonelt og universitetsnivå. Amerika har produsert mange suksesshistorier og inspirasjonskilder for andre land og spillere.
      <br />
      <br />
      Disse suksesshistoriene har hatt betydelig innflytelse på softball på verdensscenen ved å vise frem et høyt nivå av prestasjon, fremragende kvalitet og lederskap. De har også fått mye respekt og beundring fra andre land og spillere, som ser dem som eksempler og utfordrere.
      <br />
      <br />
      Rollen til universitetssoftball i forberedelsene til internasjonale konkurranser er avgjørende. Universitetssoftball gir spillerne en solid base av ferdigheter, kunnskap og erfaring som de kan bruke til å konkurrere med de beste spillerne i verden. Universitetssoftball hjelper også med å oppdage, utvikle og fremme talentet og potensialet til spillere slik at de kan utmerke seg på det høyeste nivået.
    </MKTypography>
  </MKBox>
</MKBox>

                                              






                                  




<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Kapittel 5: Idrettsstipender og Fremtiden til Utøvere
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Idrettsstipender gir mange muligheter for unge utøvere til å vise sitt talent og utvikle sine ferdigheter. En av de mest betydningsfulle mulighetene er å få et idrettsstipend for å studere og konkurrere på college-nivå. I dette kapittelet vil vi diskutere viktigheten av idrettsstipender og hvordan Sport Scholarships America kan hjelpe deg med å oppfylle drømmen din.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 Viktigheten av Stipender
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Et idrettsstipend er økonomisk støtte gitt av et universitet til en studentutøver for å kombinere studier og idrett. Det kan dekke kostnadene for skolepenger, bøker, bolig, måltider og andre utgifter. I tillegg kan det tilby fordeler som akademisk veiledning, medisinsk hjelp, treningsfasiliteter og utstyr.
      <br />
      <br />
      Å få et idrettsstipend er konkurransedyktig, med mange faktorer som spiller en rolle i tildelingsprosessen, inkludert akademiske prestasjoner, idrettslige resultater, personlighet, motivasjon, lederegenskaper og tilpasningsevne. Det er derfor viktig å forberede seg godt og skille seg ut fra konkurransen.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Hvordan Sport Scholarships America Kan Hjelpe
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo for Sport Scholarships America, som viser sportsutstyr og en eksamenskappe, symboliserer fokuset på idrettsstipender for studentutøvere."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo for Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America er en organisasjon spesialisert i å veilede unge idrettstalenter til idrettsstipender. Med lidenskapelige og erfarne eksperter støtter Sport Scholarships America studentutøvere på hvert trinn i reisen, fra første orientering til endelig plassering. Deres tjenester inkluderer:
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>En personlig inntaksintervju for å diskutere studentutøverens ønsker, muligheter og forventninger.</li>
        <li>En profesjonell evaluering av studentutøverens akademiske og idrettslige nivå.</li>
        <li>Et omfattende nettverk av kontakter med trenere, universiteter og høyskoler.</li>
        <li>Effektiv markedsføring av studentutøverens profil og videoer til potensielle trenere.</li>
        <li>Ekspertråd om de beste alternativene basert på studentutøverens preferanser, mål og budsjett.</li>
        <li>Forhandlinger om de beste stipendtilbudene på vegne av studentutøveren.</li>
        <li>Hjelp til å søke om nødvendige dokumenter, som visum, transkripsjon, diploma og opptaksprøver.</li>
        <li>Forberedelse til studentutøverens liv under stipendet, inkludert kultur, språk, regler og tips.</li>
        <li>Oppfølging og oppfølging under stipendperioden.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vil du utforske stipendmuligheter og oppnå dine idrettslige og akademiske mål? <Link to="/no/Tjenester-Produkter">Se det komplette utvalget</Link> av tjenester som tilbys av Sport Scholarships America og planlegg en konsultasjon i dag.
    </MKTypography>
  </MKBox>
</MKBox>

        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;