
import { LinkedIn } from "@mui/icons-material";
import { Instagram } from "@mui/icons-material";
import { Twitter } from "@mui/icons-material";


// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images//Logos/Sportbeurs.png";


const date = new Date().getFullYear();

export default {
  brand: {
    name: "Sport Scholarships America",
    image: logoCT,
    route: "/sv/",
  },
  socials: [
    {
      icon: <LinkedIn />,
      link: "https://www.linkedin.com/company/sportbeurs-amerika-nl",
    },
    {
      icon: <Instagram />,
      link: "",
    },
    {
      icon: <Twitter />,
      link: "",
    },
  
  ],
  menus: [
    {
      name: "Företag",
      items: [
        { name: "Om Mig", route: "/sv/Om-mig" },
        { name: "Om Sport Scholarships America", route: "/sv/Om-Sport-Scholarships-America" },
        { name: "Tjänster & Produkter", route: "/sv/Tjänster-Produkter" },
      ],
    },
    {
      name: "Källor",
      items: [
        { name: "Podcast", route: "/sv/Podcast" },
        { name: "Kunskapscenter", route: "/sv/Kunskapscenter" },
      ],
    },
    {
      name: "Hjälp & Support",
      items: [
        { name: "Kontaktformulär", route: "/sv/Kontaktformulär" },
        { name: "FAQ's", route: "/sv/FAQs" },
      ],
    },
    {
      name: "Juridik",
      items: [
        { name: "Privacy Policy", route: "/Privacy-Policy" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
All rights reserved. Copyright © {date} Sport Scholarships America    </MKTypography>
  ),
};