import americanFootball from "assets/images/SportsIcons/americanfootball.png";
import athletics from "assets/images/SportsIcons/atletiek.png";
import basketball from "assets/images/SportsIcons/basketbal.png";
import golf from "assets/images/SportsIcons/golf.png";
import fieldHockey from "assets/images/SportsIcons/hockey.png"; // Updated to Field Hockey
import baseball from "assets/images/SportsIcons/honkbal.png";
import rowing from "assets/images/SportsIcons/roeien.png";
import softball from "assets/images/SportsIcons/softbal.png"
import tennis from "assets/images/SportsIcons/tennis.png";
import gymnastics from "assets/images/SportsIcons/turnen.png";
import soccer from "assets/images/SportsIcons/voetbal.png";
import volleyball from "assets/images/SportsIcons/volleybal.png";
import waterPolo from "assets/images/SportsIcons/waterpolo.png";
import wrestling from "assets/images/SportsIcons/worstelen.png";
import swimming from "assets/images/SportsIcons/zwemmen.png";
import iceHockey from "assets/images/SportsIcons/ijshockey.png";

export default [
  {
    title: "Desportos",
    description: "Uma seleção de 16 desportos para os quais ofereço orientação para uma bolsa de estudos desportiva",
    items: [
      {
        image: americanFootball,
        name: "Futebol Americano",
        badgeContent: "Homens",
        secondBadgeContent: "",
        route: "/pt/Todos-os-esportes/Futebol-Americano",
        popularity: 13,
        altText: "Ícone de futebol americano"
      },
      {
        image: baseball,
        name: "Beisebol",
        badgeContent: "Homens",
        route: "/pt/Todos-os-esportes/Beisebol",
        popularity: 12,
        altText: "Ícone de Beisebol"
      },
      {
        image: basketball,
        name: "Basquetebol",
        badgeContent: "Homens",
        secondBadgeContent: "Mulheres",
        route: "/pt/Todos-os-esportes/Basquetebol",
        popularity: 4,
        altText: "Ícone de basquetebol"
      },
      {
        image: golf,
        name: "Golfe",
        badgeContent: "Homens",
        secondBadgeContent: "Mulheres",
        route: "/pt/Todos-os-esportes/Golfe",
        popularity: 6,
        altText: "Ícone de golfe"
      },
      {
        image: gymnastics,
        name: "Ginástica",
        badgeContent: "Homens",
        secondBadgeContent: "Mulheres",
        route: "/pt/Todos-os-esportes/Ginástica",
        popularity: 8,
        altText: "Ícone de ginástica"
      },
      {
        image: iceHockey,
        name: "Hóquei no Gelo",
        badgeContent: "Homens",
        secondBadgeContent: "Mulheres",
        route: "/pt/Todos-os-esportes/Hóquei-no-gelo",
        popularity: 15,
        altText: "Ícone de hóquei no gelo"
      },
      {
        image: fieldHockey, // Atualizado para Hóquei em Campo
        name: "Hóquei em Campo", // Atualizado para Hóquei em Campo
        badgeContent: "",
        secondBadgeContent: "Mulheres",
        route: "/pt/Todos-os-esportes/Hóquei", // Atualizado para Hóquei em Campo
        popularity: 14,
        altText: "Ícone de hóquei em campo" // Atualizado para Hóquei em Campo
      },
      {
        image: rowing,
        name: "Remo",
        badgeContent: "Homens",
        secondBadgeContent: "Mulheres",
        route: "/pt/Todos-os-esportes/Remo",
        popularity: 7,
        altText: "Ícone de remo"
      },
      {
        image: soccer,
        name: "Futebol",
        badgeContent: "Homens",
        secondBadgeContent: "Mulheres",
        route: "/pt/Todos-os-esportes/Futebol",
        popularity: 1,
        altText: "Ícone de futebol"
      },
      {
        image: softball,
        name: "Softbol",
        badgeContent: "",
        secondBadgeContent: "Mulheres",
        route: "/pt/Todos-os-esportes/Softball",
        popularity: 11,
        altText: "Ícone de softbol"
      },
      {
        image: swimming,
        name: "Natação",
        badgeContent: "Homens",
        secondBadgeContent: "Mulheres",
        route: "/pt/Todos-os-esportes/Natação",
        popularity: 2,
        altText: "Ícone de natação"
      },
      {
        image: tennis,
        name: "Ténis",
        badgeContent: "Homens",
        secondBadgeContent: "Mulheres",
        route: "/pt/Todos-os-esportes/Ténis",
        popularity: 10,
        altText: "Ícone de ténis"
      },
      {
        image: athletics,
        name: "Atletismo",
        badgeContent: "Homens",
        secondBadgeContent: "Mulheres",
        route: "/pt/Todos-os-esportes/Atletismo",
        popularity: 3,
        altText: "Ícone de atletismo"
      },
      {
        image: volleyball,
        name: "Voleibol",
        badgeContent: "Homens",
        secondBadgeContent: "Mulheres",
        route: "/pt/Todos-os-esportes/Voleibol",
        popularity: 5,
        altText: "Ícone de voleibol"
      },
      {
        image: waterPolo,
        name: "Pólo Aquático",
        badgeContent: "Homens",
        secondBadgeContent: "Mulheres",
        route: "/pt/Todos-os-esportes/Pólo-Aquático",
        popularity: 9,
        altText: "Ícone de pólo aquático"
      },
      {
        image: wrestling,
        name: "Luta",
        badgeContent: "Homens",
        secondBadgeContent: "Mulheres",
        route: "/pt/Todos-os-esportes/Luta",
        popularity: 16,
        altText: "Ícone de luta"
      },
    ],
  },
];
