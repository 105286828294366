/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================
* Produktsida: https://www.creative-tim.com/product/material-kit-pro-react
* Upphovsrätt 2023 Creative Tim (https://www.creative-tim.com)
Kodad av www.creative-tim.com
=========================================================
*/

// @mui materialkomponenter
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Komponenter från Material Kit 2 PRO React
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Bilder
import post2 from "assets/images/TijdUS.png";
import "../../../../H1asH3.css"

// Funktionen Profil
function Profil() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 0 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox 
                display="flex" 
                flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }} 
                justifyContent="space-between" 
                alignItems="center" 
                mb={1}
              >
                <MKTypography variant="h1" className="h1-as-h3" mb={{ xs: 1, sm: 1, md: 0 }}>Del 3: Tillbaka i Nederländerna</MKTypography>
              </MKBox>
              <MKTypography variant="body1" fontWeight="light" color="text">
                <br />
                Vid återkomsten till Nederländerna upplevde jag en blandning av nostalgi och beslutsamhet. Min djupt rotade passion för fotboll förenades nu med en förnyad iver för akademiska utmaningar. Amsterdam blev bakgrunden för mitt nästa äventyr, där jag följde en masterexamen i kvantitativ finans, en området som väckte både min analytiska sida och mina ambitioner.
                <br /><br />
                Efter avslutade studier började jag arbeta som konsult. Även om världen av siffror och analys fascinerade mig, kände jag att något saknades. Ekona från min amerikanska resa fortsatte att resonera inom mig och påminde mig om den unika möjlighet jag hade upplevt, en möjlighet jag önskade för andra att ha också.
                <br /><br />
                Därför bestämde jag mig för att använda mina erfarenheter och kunskap för att vägleda andra unga, ambitiösa idrottare. Innan jag startade detta företag, utnyttjade jag mitt omfattande nätverk i Amerika genom att samarbeta med tränare, idrottare, tidigare idrottare och andra. Dessa diskussioner hjälpte mig och framtida studenter som jag skulle stödja att förstå det bästa tillvägagångssättet för dessa möjligheter.
                <br /><br />
                Under den utmanande COVID-19-pandemin samarbetade jag med ett ledande företag i Amerika. Även om jag kunde hjälpa färre internationella studenter på grund av begränsad tillgänglighet av stipendier, använde jag min kompetens för att hjälpa amerikanska studenter. Den här erfarenheten utvidgade inte bara mitt nätverk avsevärt utan gav också insikter om den amerikanska stipendie marknaden, som jag nu kunde tillämpa mer effektivt i Europa.
                <br /><br />
                Utmaningarna under pandemin stärkte min mission att guida unga talanger mot amerikanska universitet och idrottsstipendier. Trots svårigheterna gav det en känsla av uppfyllelse som jag aldrig tidigare hade upplevt. Det var mitt sätt att ge tillbaka till samhället som en gång varmt välkomnade mig i Förenta staterna.
                <br /><br />
                Detta är min historia, och jag är stolt över att jag nu kan hjälpa andra att skriva sina egna historier i det land av oändliga möjligheter. Om du vill veta mer om mitt företag och mina kunders resor när de förverkligar sina drömmar, fortsätt läsa på min webbplats. Om du har några frågor eller vill kontakta mig, tveka inte att göra det.
              </MKTypography>
              <hr style={{ margin: '20px 0' }} />
              <Grid item xs={12} lg={6} mb={{ xs: 3, lg: 0 }}>
                <TransparentBlogCard
                  image={post2}
                  title="Del 2: Min Tid i Amerika"
                  action={{
                    type: "internal",
                    route: "/sv/Om-mig/Min-tid-i-Amerika",
                    color: "info",
                    label: "Läs mer",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

// Exportera Profil
export default Profil;
