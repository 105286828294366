import americanFootball from "assets/images/SportsIcons/americanfootball.png";
import athletics from "assets/images/SportsIcons/atletiek.png";
import basketball from "assets/images/SportsIcons/basketbal.png";
import golf from "assets/images/SportsIcons/golf.png";
import fieldHockey from "assets/images/SportsIcons/hockey.png"; // Updated to Field Hockey
import baseball from "assets/images/SportsIcons/honkbal.png";
import rowing from "assets/images/SportsIcons/roeien.png";
import softball from "assets/images/SportsIcons/softbal.png"
import tennis from "assets/images/SportsIcons/tennis.png";
import gymnastics from "assets/images/SportsIcons/turnen.png";
import soccer from "assets/images/SportsIcons/voetbal.png";
import volleyball from "assets/images/SportsIcons/volleybal.png";
import waterPolo from "assets/images/SportsIcons/waterpolo.png";
import wrestling from "assets/images/SportsIcons/worstelen.png";
import swimming from "assets/images/SportsIcons/zwemmen.png";
import iceHockey from "assets/images/SportsIcons/ijshockey.png";

export default [
  {
    title: "Sporter",
    description: "Ett urval av 16 sporter för vilka jag erbjuder vägledning mot ett idrottsstipendium",
    items: [
      {
        image: americanFootball,
        name: "Amerikansk Fotboll",
        badgeContent: "Herrar",
        secondBadgeContent: "",
        route: "/sv/Alla-sporter/Amerikansk-Fotboll",
        popularity: 13,
        altText: "Ikon för amerikansk fotboll"
      },
      {
        image: baseball,
        name: "Baseball",
        badgeContent: "Herrar",
        route: "/sv/Alla-sporter/Baseball",
        popularity: 15,
        altText: "Ikon för Baseball"
      },
      {
        image: basketball,
        name: "Basketboll",
        badgeContent: "Herrar",
        secondBadgeContent: "Damer",
        route: "/sv/Alla-sporter/Basketboll",
        popularity: 7,
        altText: "Ikon för basketboll"
      },
      {
        image: golf,
        name: "Golf",
        badgeContent: "Herrar",
        secondBadgeContent: "Damer",
        route: "/sv/Alla-sporter/Golf",
        popularity: 6,
        altText: "Ikon för golf"
      },
      {
        image: gymnastics,
        name: "Gymnastik",
        badgeContent: "Herrar",
        secondBadgeContent: "Damer",
        route: "/sv/Alla-sporter/Gymnastik",
        popularity: 9,
        altText: "Ikon för gymnastik"
      },
      {
        image: iceHockey,
        name: "Ishockey",
        badgeContent: "Herrar",
        secondBadgeContent: "Damer",
        route: "/sv/Alla-sporter/Ishockey",
        popularity: 2,
        altText: "Ikon för ishockey"
      },
      {
        image: fieldHockey, // Uppdaterat till Fält-Hockey
        name: "Fält-Hockey", // Uppdaterat till Fält-Hockey
        badgeContent: "",
        secondBadgeContent: "Damer",
        route: "/sv/Alla-sporter/Fält-Hockey", // Uppdaterat till Fält-Hockey
        popularity: 12,
        altText: "Ikon för fälthockey" // Uppdaterat till Fält-Hockey
      },
      {
        image: rowing,
        name: "Roddning",
        badgeContent: "Herrar",
        secondBadgeContent: "Damer",
        route: "/sv/Alla-sporter/Roddning",
        popularity: 10,
        altText: "Ikon för roddning"
      },
      {
        image: soccer,
        name: "Fotboll",
        badgeContent: "Herrar",
        secondBadgeContent: "Damer",
        route: "/sv/Alla-sporter/Fotboll",
        popularity: 1,
        altText: "Ikon för fotboll"
      },
      {
        image: softball,
        name: "Softboll",
        badgeContent: "",
        secondBadgeContent: "Damer",
        route: "/sv/Alla-sporter/Softball",
        popularity: 14,
        altText: "Ikon för softboll"
      },
      {
        image: swimming,
        name: "Simning",
        badgeContent: "Herrar",
        secondBadgeContent: "Damer",
        route: "/sv/Alla-sporter/Simning",
        popularity: 4,
        altText: "Ikon för simning"
      },
      {
        image: tennis,
        name: "Tennis",
        badgeContent: "Herrar",
        secondBadgeContent: "Damer",
        route: "/sv/Alla-sporter/Tennis",
        popularity: 5,
        altText: "Ikon för tennis"
      },
      {
        image: athletics,
        name: "Friidrott",
        badgeContent: "Herrar",
        secondBadgeContent: "Damer",
        route: "/sv/Alla-sporter/Friidrott",
        popularity: 3,
        altText: "Ikon för friidrott"
      },
      {
        image: volleyball,
        name: "Volleyboll",
        badgeContent: "Herrar",
        secondBadgeContent: "Damer",
        route: "/sv/Alla-sporter/Volleyboll",
        popularity: 8,
        altText: "Ikon för volleyboll"
      },
      {
        image: waterPolo,
        name: "Vattenpolo",
        badgeContent: "Herrar",
        secondBadgeContent: "Damer",
        route: "/sv/Alla-sporter/Vattenpolo",
        popularity: 11,
        altText: "Ikon för vattenpolo"
      },
      {
        image: wrestling,
        name: "Brottning",
        badgeContent: "Herrar",
        secondBadgeContent: "Damer",
        route: "/sv/Alla-sporter/Brottning",
        popularity: 16,
        altText: "Ikon för brottning"
      },
    ],
  },
];
