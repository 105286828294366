/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";


// Material Kit 2 PRO React examples
import RaisedBlogCard from "examples/Cards/BlogCards/RaisedBlogCard";


// Images
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import NCAA from "assets/images/Logos/NCAA_Transparent.png"
import NJCAA from "assets/images/Logos/NJCAA_Transparent.png"
import NAIA from "assets/images/Logos/NAIA_Transparent.png"

function BlogPostThree() {
  const post1 = (NCAA)
  const post2 = (NJCAA)
  const post3 = (NAIA)

  return (
    <>
      <MKBox position="relative" variant="gradient" bgColor="dark" mt={{ xs: 0, lg: 8 }} mx={-2}>
        <MKBox
          component="img"
          src={bgPattern}
          alt="motif de fond"
          position="absolute"
          top={0}
          left={0}
          width={{ xs: "100%", lg: "100%" }}
          height={{ xs: "auto", lg: "auto" }}
          opacity={0.6}
        />
        <Container>
          <Grid container justifyContent="center" sx={{ pt: 6, pb: 18 }}>
            <Grid item xs={12} md={7} sx={{ textAlign: "center" }}>
              <MKTypography variant="h2" color="white" mb={1}>
                Opportunités à tous les niveaux
              </MKTypography>
              <MKTypography variant="body2" color="white">
              Chaque sport, chaque niveau académique : explorez un monde d'opportunités dans d'innombrables universités américaines pour chaque étudiant-athlète.              </MKTypography>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
  
      <MKBox component="section" mt={-10}>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4} mb={{ xs: 3, lg: 0 }}>
              <RaisedBlogCard
                image={post1}
                category={{ color: "primary", label: "Maison" }}
                title="NCAA"
                subtitle="National Collegiate Athletic Association"
                description=
                  "La NCAA gère le sport universitaire aux États-Unis et supervise les compétitions pour plus de 1 100 universités affiliées. En tant qu'organisation de premier plan, elle joue un rôle crucial dans le paysage sportif universitaire américain."
                action={{
                  type: "internal",
                  route: "/fr/NCAA",
                  color: "info",
                  label: "Plus d'informations",
                }}
                imageStyle={{ marginTop: "-44px" }}
                shadowTop="-30px" // Déplacez l'ombre vers le bas de 50px pour correspondre au mouvement de l'image
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} mb={{ xs: 3, lg: 0 }}>
              <RaisedBlogCard
                image={post2}
                category={{ color: "primary", label: "Maison" }}
                title="NJCAA"
                subtitle="National Junior College Association"
                description="La NJCAA régule le sport dans les collèges communautaires aux États-Unis et gère les compétitions pour des centaines d'institutions affiliées. En tant qu'organisation centrale, elle est essentielle pour le sport de deux ans en Amérique, où le talent est développé pour des niveaux supérieurs."
                action={{
                  type: "internal",
                  route: "/fr/NJCAA",
                  color: "info",
                  label: "Plus d'informations",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} mb={{ xs: 3, lg: 0 }}>
              <RaisedBlogCard
                image={post3}
                category={{ color: "primary", label: "Maison" }}
                title="NAIA"
                subtitle="National Association of Intercollegiate Athletics"
                description=
                  "La NAIA joue un rôle central dans la gestion du sport dans les petites universités aux États-Unis, avec des centaines d'institutions affiliées. En tant qu'organisation de premier plan, elle joue un rôle vital dans le paysage sportif interuniversitaire américain, promouvant le talent et l'intégrité."
                action={{
                  type: "internal",
                  route: "/fr/NAIA",
                  color: "info",
                  label: "Plus d'informations",
                }}
                imageStyle={{ marginTop: "50px" }}
                shadowTop="none"
                paddingBelowImage="120px"
              />
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
              }  

export default BlogPostThree;
