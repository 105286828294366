/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState } from 'react'; // Step 1: Import useState


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";


// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/Logos/NCAA.png";
import post3 from "assets/images/Logos/NJCAA.png";



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {

  const articleStructure = [
    { id: 'intro', title: 'Die NAIA: Ein Alternativer Weg im College-Sport in Amerika', isSubheader: false },
  
    { id: 'Kapitel 1', title: 'Die Ursprünge und Entwicklung der NAIA', isSubheader: true },
    { id: '1.1', title: 'Die NAIA und ihre Einzigartigen Divisionen', isSubheader: true },
    { id: '1.2', title: 'Die Akademische Mission', isSubheader: true },
  
    { id: 'Kapitel 2', title: 'Finanzen, Medien und Sichtbarkeit', isSubheader: true },
    { id: '2.1', title: 'Herausforderungen und der Weg nach vorn', isSubheader: true },
    { id: '2.2', title: 'Abschließend', isSubheader: true },
  ];
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };


  return (
     <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Inhaltsverzeichnis
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Verstecken</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Anzeigen</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>
          <MKBox id="intro" mb={3}>
              <MKTypography variant="h1" className="h1-as-h3" mb={2}>Die NAIA: Ein Alternativer Weg im College-Sport in Amerika

</MKTypography>
              </MKBox>
              <MKBox id="Kapitel 1" mb={3}>

              <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Die Ursprünge und Entwicklung der NAIA
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              Die National Association of Intercollegiate Athletics, oder NAIA, ist eine entscheidende Entität in der amerikanischen College-Sportlandschaft, wenn auch weniger bekannt als die NCAA. Gegründet im Jahr 1937, hat sich die NAIA zu einer renommierten Organisation entwickelt, die Studenten-Athleten eine einzigartige Chance bietet, ihre sportlichen und akademischen Ambitionen zu verwirklichen.

<br />
                <br />
                Von Anfang an hat sich die NAIA darauf konzentriert, Möglichkeiten für kleinere Bildungseinrichtungen zu schaffen, wobei der Schwerpunkt auf der Schaffung einer ausgewogenen Erfahrung für Studenten-Athleten liegt. Die Organisation fördert sowohl den Wettkampfsport als auch die akademische Exzellenz mit der Überzeugung, dass beides Hand in Hand gehen kann.

</MKTypography>
<MKBox id="1.1" mb={3}>

                <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
                <br />
                Die NAIA und ihre Einzigartigen Divisionen
                </MKTypography>
                </MKBox>

                <MKTypography variant="body1" fontWeight="light" color="text"> 
                Im Gegensatz zur NCAA ist die NAIA kompakter organisiert und in zwei Divisionen unterteilt, die beide eine reiche Mischung aus Sport und Bildung bieten:

<br /><br />
  <MKTypography component="span" variant="body1" fontWeight="bold">
    Division I:{' '}
  </MKTypography>
  In dieser Division finden wir Schulen, die ein höheres Niveau an sportlichen Stipendien anbieten und in denen der Sportwettbewerb sehr konkurrenzfähig ist. Trotz der kleineren Größe der Schulen im Vergleich zu den NCAA-Division-I-Institutionen bietet die NAIA Division I eine Bühne für Spitzenathleten, um ihre Fähigkeiten zu zeigen und sich zu entwickeln.  
  <br />
  <br />
  <MKTypography component="span" variant="body1" fontWeight="bold">
    Division II:{' '}
  </MKTypography>
  Diese Division legt noch mehr Wert auf das Gleichgewicht zwischen Sport, Akademik und Campusengagement. Die sportlichen Stipendien sind begrenzter, aber die Möglichkeiten für Studenten-Athleten, sowohl im Sport als auch im Studium zu glänzen, sind reichlich vorhanden. 
  </MKTypography>

  <MKBox id="1.2" mb={3}>

  <MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
              <br />
              Die Akademische Mission
              </MKTypography>
              </MKBox>

              <MKTypography variant="body1" fontWeight="light" color="text"> 
              Die NAIA legt großen Wert auf die akademischen Leistungen ihrer Studenten-Athleten. Es wurden strenge akademische Standards festgelegt, um die Teilnahme am Sport zu gewährleisten, und die angeschlossenen Schulen sind bestrebt, die notwendige akademische Unterstützung zu bieten. Dies führt zu einem Umfeld, in dem Studenten-Athleten ermutigt werden, in jedem Aspekt ihres Lebens nach Exzellenz zu streben.

                <br />
                </MKTypography>

                                                  





                                  







                <MKBox id="Kapitel 2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Finanzen, Medien und Sichtbarkeit
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
Obwohl die NAIA finanziell vielleicht nicht mit den gigantischen Einnahmen der NCAA konkurrieren kann, spielt sie dennoch eine wichtige Rolle bei der Finanzierung von Sportstipendien und der Verbesserung von Einrichtungen. Darüber hinaus gewinnt die NAIA an Sichtbarkeit durch Streaming-Dienste und soziale Medien, was dazu beiträgt, die Botschaft von ausgewogenem Sport und akademischer Exzellenz zu verbreiten.


<br />
</MKTypography>
<MKBox id="2.1" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Herausforderungen und der Weg nach vorn
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
Ähnlich wie die NCAA steht auch die NAIA vor Herausforderungen, darunter die Navigation durch die Komplexität des Amateurstatus von Studenten-Athleten und die Sicherstellung von Gleichheit im Sport. Mit ihrer einzigartigen Position und ihrer Hingabe zur Entwicklung von Studenten-Athleten ist die NAIA jedoch gut positioniert, um weiterhin einen positiven Einfluss auf das Leben vieler junger Athleten zu haben.

</MKTypography>
<MKBox id="2.2" mb={3}>

<MKTypography variant="h4" mb={{ xs: 1, sm: 1, md: 0 }}>
<br />
Abschließend
</MKTypography>
</MKBox>

<MKTypography variant="body1" fontWeight="light" color="text"> 
Der Nationalverband für interkollegialen Sport bietet eine wertvolle und einzigartige Alternative in der Welt des amerikanischen Collegesports. Mit ihrem Fokus auf Ausgewogenheit, akademischer Exzellenz und sportlichem Wettbewerb bleibt die NAIA eine wesentliche Kraft bei der Gestaltung der Zukunft von Studenten-Athleten. Während sie nach vorne schaut und sich weiterentwickelt, bleibt ihr Engagement für die Entwicklung junger Menschen unerschütterlich und hinterlässt einen bleibenden und positiven Einfluss auf die amerikanische Sport- und Bildungswelt.
<br />


</MKTypography>


              <hr style={{ margin: '20px 0'}} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post1}
                    title="NCAA"
                    description="NCAA: Das Herz des College-Sports in Amerika"
                    action={{
                      type: "internal",
                      route: "/de/NCAA",
                      color: "info",
                      label: "Mehr Lesen",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TransparentBlogCard
                    image={post3}
                    title="NJCAA"
                    description="NJCAA: Die Sprungbrett zu Großen Colleges und der NCAA"
                    action={{
                      type: "internal",
                      route: "/de/NJCAA",
                      color: "info",
                      label: "Mehr Lesen",
                    }}
                  />
                </Grid>
              </Grid>

            </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;