import Alabama from "assets/images/Staten_Images/Alabama.webp"
import Florida from "assets/images/Staten_Images/Florida.jpeg"
import Connecticut from "assets/images/Staten_Images/Connecticut.jpeg"
import Kentucky from "assets/images/Staten_Images/Kentucky.webp"
import Michigan from "assets/images/Staten_Images/Michigan.jpeg"
import North_Carolina from "assets/images/Staten_Images/North_Carolina.jpg"
import Texas from "assets/images/Staten_Images/Texas.jpeg"



const states = [
  {
    name: "Alabama",
    image: Alabama,
    imageDescription: "Alabama vs Auburn is one of the biggest college football rivalries.",


    description: [
      "College sports are an integral part of the culture in Alabama, with a special emphasis on American football. The University of Alabama (UA) in Tuscaloosa and Auburn University in Auburn dominate the sports landscape in the state.",
      "UA's football team, the Crimson Tide, has a rich history and has won numerous national championships. Home games at the immense Bryant-Denny Stadium can attract up to 100,000 fans. The fans' passion is enormous, and the 'Roll Tide' chant is known throughout Alabama.",
      "On the other hand, we have Auburn University with their football team, the Tigers. They, along with UA, make up the Iron Bowl, one of the most intense rivalries in college sports. This annual clash turns the entire state upside down and guarantees excitement and spectacle.",
      "Besides football, Alabama has strong programs in basketball, baseball, and other sports, supported by a powerful sense of community spirit. In Alabama, sports are more than just pastimes; they are an essential part of the state's lifestyle and identity.",
    ],
  },

  {
    name: "Alaska",
    description: [
      "Unlike many other American states, college sports play a more modest role in Alaska, mainly due to its remote location and unique climate. Here, the emphasis is more on winter sports and outdoor activities than on traditional American sports like football and baseball.",
      "The University of Alaska Anchorage (UAA) and the University of Alaska Fairbanks (UAF) are the primary institutions with active sports programs. UAA has a strong ski and skating team, while UAF is known for its ice hockey team, the Nanooks, who have a loyal local following.",
      "In Alaska, college sports are more about the experience and the community than large-scale events. Games provide an opportunity for the community to come together, cheer on the athletes, and celebrate the state's unique sports culture.",
      "Despite the smaller scale, college sports in Alaska have their own charm and offer a warm community in the cold environment of the state.",
    ],
  },

  {
    name: "Arizona",
    description: [
      "College sports are immensely popular in Arizona, with a rich tradition in both American football and basketball. The Arizona State University (ASU) in Tempe and the University of Arizona (UA) in Tucson are the pillars of the state's sports landscape.",
      "ASU's football team, the Sun Devils, enjoys a passionate following, while their basketball team regularly shines in NCAA tournaments. UA, with their Wildcats, is known for their successful basketball program and intense rivalry with ASU, culminating in the annual 'Duel in the Desert' football game.",
      "In addition to the major sports, Arizona also excels in baseball, softball, and golf, with university teams often in the national spotlight. Community support is overwhelming, and sports events are considered significant social occasions.",
      "College sports in Arizona embody the spirit of competition and community, with intense rivalry and fan passion contributing to a vibrant and lively sports culture.",
    ],
  },



    {
      name: "Arkansas",
      description: [
        "In Arkansas, college sports play a central role in community life, with a special emphasis on American football and basketball. The University of Arkansas in Fayetteville is a powerhouse in the state, known for its Razorbacks.",
        "The Razorbacks' football team enjoys regional and national recognition, with a dedicated fan base that passionately follows every game. Their home games at the impressive Razorback Stadium are a spectacle, where the 'Woo Pig Sooie' chant is echoed by tens of thousands of fans.",
        "Basketball is also a significant part of the sports culture in Arkansas, with the Razorbacks regularly participating in the NCAA tournament. The excitement and energy in Bud Walton Arena during the basketball season are palpable.",
        "Beyond the major sports, the Razorbacks also have strong programs in track and field, baseball, and softball. This diversity in sports enriches the sports culture in Arkansas, where college sports are a source of pride, community spirit, and entertainment.",
      ],
    },
  
    {
      name: "California",
      description: [
        "College sports in California are diverse and highly competitive, with numerous universities excelling in a variety of sports. From American football to swimming, the state has it all.",
        "The Pac-12 conference, with prominent schools like the University of California, Los Angeles (UCLA) and the University of Southern California (USC), is known for its high level of sports. UCLA's basketball team has a rich history, while USC traditionally excels in football.",
        "In addition to the well-known teams, there are also smaller schools with impressive sports programs, such as Stanford University, known for their excellence in swimming and athletics. This diversity creates a dynamic and exciting sports environment in the state.",
        "California's pleasant climate and extensive sports facilities promote active participation and enthusiasm for college sports. Whether it's a football game in a packed stadium or a local swimming competition, sports are an integral part of the Californian lifestyle.",
      ],
    },
  
    {
      name: "Colorado",
      description: [
        "College sports in Colorado enjoy a vibrant and enthusiastic following, partly due to the impressive natural surroundings and active outdoor lifestyle. The state has a rich tradition in American football, basketball, and winter sports.",
        "The University of Colorado Boulder (CU) and Colorado State University (CSU) are prominent players in the college sports scene, with the annual 'Rocky Mountain Showdown' football game as the highlight of their rivalry.",
        "CU's football team, the Buffaloes, has a passionate fan base, while their ski team excels in national competitions, benefiting from Colorado's excellent winter sports facilities. CSU is also competitive in various sports, with a strong presence in American football and basketball.",
        "In addition to the larger universities, smaller colleges in Colorado also have active sports programs, contributing to the rich and diverse sports culture in the state. College sports in Colorado stand for community, perseverance, and the celebration of sports in all forms.",
      ],
    },
  
    {
      name: "Connecticut",
      image: Connecticut,
      imageDescription: "UConn's women's basketball team is one of the most dominant college sports teams of all time",
  
      description: [
        "College sports in Connecticut are dominated by basketball, with a special emphasis on the successful programs of the University of Connecticut (UConn). The state has a rich tradition and a passionate fan base deeply invested in college sports.",
        "UConn's women's basketball team is nationally renowned and has won multiple NCAA championships, making them a powerhouse in the sport. The men's team has also achieved impressive performances, making basketball a central part of the sports culture in Connecticut.",
        "While basketball reigns supreme, there are also strong programs in other sports such as ice hockey, football, and lacrosse, with various universities being competitive at the national level.",
        "College sports in Connecticut embody the pride and community spirit of the state, where sports events provide an opportunity for fans to come together, cheer on the teams, and participate in a rich sports tradition.",
      ],
    },
  
    {
      name: "Delaware",
      description: [
        "Despite being one of the smallest states in the US, Delaware has a vibrant college sports scene with strong community involvement. The University of Delaware (UD) in Newark is at the center of the action, where the Blue Hens excel in various sports.",
        "UD's football team has a rich history and a dedicated fan base, with exciting home games at Delaware Stadium. Basketball and lacrosse are also popular sports, with teams regularly competing for regional and national recognition.",
        "Despite its small size, Delaware has strong programs in athletics, swimming, and other sports, all contributing to the dynamic sports culture in the state. College sports here are more than just a pastime; they are an important part of community identity, fostering a strong connection between the university and the broader community.",
        "In Delaware, college sports bring people together, with the small scale creating a close-knit and passionate sports community.",
      ],
    },
  
  


    {
      name: "Florida",
      image: Florida,
      imageDescription: "Ben Hill Griffin Stadium is the home of the Florida Gators",
  
      description: [
        "Florida is a hotspot for college sports, with some of the most competitive and passionate programs in the country. American football and basketball dominate the sports scene, but there is also room for a variety of other sports.",
        "Teams like the Florida Gators and the Florida State Seminoles have national recognition and a rich history in both football and basketball. Their intense rivalry attracts thousands of fans annually, and the games are highlights of the sports season.",
        "In addition, there are also smaller universities and colleges with strong sports programs that contribute to the rich sports culture in the state. Baseball, athletics, and swimming are also popular, and the excellent facilities and sunny climate make Florida an ideal place for college athletes.",
        "In Florida, college sports are more than just competition; they are a way of life, a source of pride, and an essential part of community identity.",
      ],
    },
  
    {
      name: "Georgia",
      description: [
        "College sports in Georgia are characterized by intense passion and deep-rooted rivalries, with American football as the undisputed king. The state is home to some of the most prestigious programs in the country, with fans passionately experiencing every game.",
        "The University of Georgia Bulldogs in Athens and the Georgia Tech Yellow Jackets in Atlanta are the major players, with their annual 'Clean, Old-Fashioned Hate' rivalry gripping the state. Football games, especially in UGA's massive Sanford Stadium, attract tens of thousands of fans.",
        "In addition to football, Georgia also excels in basketball, athletics, and baseball, with talented athletes and dedicated supporters. The sports programs at smaller schools and colleges contribute to a rich and diverse sports landscape throughout the state.",
        "In Georgia, sport is more than competition; it is a way of life, a source of community spirit, and an indispensable part of regional identity.",
      ],
    },
  
    {
      name: "Hawaii",
      description: [
        "College sports in Hawaii offer a unique experience inspired by the island beauty and strong local culture. Despite being geographically isolated, sports teams and fans in Hawaii are extraordinarily passionate and engaged.",
        "The University of Hawaii at Manoa plays a central role in the college sports scene, with its football team, the Rainbow Warriors, as a significant focal point. Their home games at the Aloha Stadium create a vibrant and festive atmosphere, with a strong island spirit.",
        "In addition to American football, volleyball, surfing, and canoe racing are also popular, reflecting the unique sports culture and natural landscape of Hawaii. The Rainbow Warriors are also competitive in basketball and baseball, with a dedication shared by the entire community.",
        "In Hawaii, college sports represent the Aloha spirit, with teams and fans embracing island culture, community, and pride, creating a unique and vibrant sports experience together.",
      ],
    },
  
    {
      name: "Idaho",
      description: [
        "College sports in Idaho have a strong tradition, with a particular focus on American football and basketball. The sense of community and involvement in sports teams is remarkable, even though the state does not host huge universities.",
        "Boise State University, famous for its blue football field, plays a prominent role in Idaho's sports landscape. The football team has gained national attention with its success and innovative style of play, and the home games are a spectacle in themselves.",
        "In addition to Boise State, smaller schools and colleges also contribute to the rich sports culture, with programs in sports such as baseball, athletics, and winter sports benefiting from Idaho's unique natural environment.",
        "In Idaho, college sports are more than just a pastime; they are a way of life and an essential part of the state's lifestyle and identity, with communities coming together to support their teams.",
      ],
    },
  
    {
      name: "Illinois",
      description: [
        "Illinois has a rich history and deep love for college sports, with powerful programs and dedicated fans. American football and basketball draw significant attention throughout the year, but there is also a wide range of other sports thriving.",
        "The University of Illinois Fighting Illini and the Northwestern University Wildcats lead the way, with intense rivalries and competitive leagues keeping the state vibrant. Memorial Stadium and Ryan Field are temples of sports, where every game is an unforgettable experience.",
        "Basketball is also prominent, with the state regularly producing talent that excels both nationally and internationally. The Illinois Fighting Illini have a rich basketball tradition and are a constant threat in NCAA tournaments.",
        "From small colleges to large universities, sports in Illinois serve as a bond, a source of pride, and an essential part of the culture and identity of the state.",
      ],
    },
  
    {
      name: "Indiana",
      description: [
        "Indiana is known for its deep-rooted love for basketball, and this is evident in the state's college sports culture. The rivalry, passion, and dedication of both players and fans are unmatched.",
        "Programs like the Indiana Hoosiers and the Purdue Boilermakers are nationally recognized, especially in basketball. An electric atmosphere hangs in venues like Assembly Hall and Mackey Arena throughout the year, where history and tradition come together.",
        "While basketball takes the lead, other sports also thrive in Indiana. American football, baseball, and athletics each have strong programs and enjoy broad community support.",
        "Sport in Indiana is more than just a game; it is a way of life, a catalyst for community spirit, and a proud tradition passed down through generations. Here, in the heart of the Midwest, college sports are an integral part of the state's identity.",
      ],
    },
  
    {
      name: "Iowa",
      description: [
        "College sports in Iowa are a source of pride and community spirit, with a rich tradition in both American football and wrestling. The rivalry between the Iowa Hawkeyes and the Iowa State Cyclones is central and provides unforgettable moments each season.",
        "Kinnick Stadium in Iowa City and Jack Trice Stadium in Ames are the venues for intense football games every fall, attracting tens of thousands of fans. The passion and dedication of the fans are characteristic of the state's sports culture.",
        "Wrestling is another sport in which Iowa excels, with the Hawkeyes regularly winning national titles. This sport has a deep-rooted tradition in Iowa and enjoys loyal support.",
        "From small colleges to large universities, sports in Iowa are more than competition; they are a way of life, a source of community spirit, and an important part of the state's identity.",
      ],
    },

    {
      name: "Kansas",
      description: [
        "College sports in Kansas are steeped in tradition and community spirit, with basketball as the undisputed highlight. The state is home to some of the most iconic programs in the country, and the rivalry and passion are intense.",
        "The Kansas Jayhawks and the Kansas State Wildcats lead the charge, with their annual 'Sunflower Showdown' being one of the highlights of the sports year. Allen Fieldhouse, home to the Jayhawks, is known as one of the most intimidating arenas in college basketball.",
        "While basketball forms the heart of the sports culture in Kansas, American football, baseball, and athletics also play a significant role. The fans are unparalleled in their dedication and contribute to the vibrant atmosphere during games.",
        "Sports in Kansas are more than just competition; they are a source of pride, a catalyst for community, and an essential part of the state's identity.",
      ],
    },
  
    {
      name: "Kentucky",
      image: Kentucky,
      imageDescription: "Karl-Anthony Towns and Devin Booker played together for Kentucky and later became major NBA stars",
  
      description: [
        "College sports in Kentucky are synonymous with basketball, featuring a rich tradition and passionate fan base. The rivalry between the Kentucky Wildcats and the Louisville Cardinals is one of the most intense and watched in the country.",
        "Rupp Arena, the home of the Wildcats, is a true basketball temple, where the program's history and pride are palpable. The Wildcats have multiple national championships to their name and are a fixture in college basketball.",
        "In addition to basketball, Kentucky also has strong programs in American football, equestrian sports, and shooting, all of which enrich the state's sports culture. The Kentucky Derby, a legendary horse race, underscores the rich tradition in equestrian sports.",
        "In Kentucky, sports are more than a pastime; they are a way of life, a source of community and pride, and a crucial part of the state's identity.",
      ],
    },
  
    {
      name: "Louisiana",
      description: [
        "In Louisiana, college sports are an integral part of the culture, with a deep-seated passion for American football and baseball. The Louisiana State University (LSU) Tigers are a powerhouse, attracting massive crowds and creating an unparalleled atmosphere.",
        "Tiger Stadium, also known as 'Death Valley,' is notorious for its raucous fans and intimidating atmosphere, making it one of the most feared home fields in college football. 'Saturday Night in Death Valley' is more than a game; it's an event, a tradition, a part of Louisiana's soul.",
        "In addition to LSU, smaller universities and colleges also contribute to the rich sports landscape, with strong programs in various sports. Baseball also enjoys great popularity, with LSU regularly competing for the national title.",
        "Sports in Louisiana are a celebration of community, a source of pride, and a bond that brings people together, regardless of their background. It is a vibrant and essential part of the state's lifestyle and identity.",
      ],
    },
  
    {
      name: "Maine",
      description: [
        "College sports in Maine reflect the state's unique nature and sense of community, with a strong focus on winter sports and athletics. The University of Maine Black Bears leads the way, proudly representing in various NCAA divisions.",
        "Alfond Stadium in Orono is the heart of American football in the state, while the Harold Alfond Sports Arena plays a central role in the ice hockey season. The Black Bears have a proud ice hockey tradition, with enthusiastic fans braving the cold to support their team.",
        "In addition to ice hockey and football, there are also strong programs in athletics, rowing, and other sports that enrich Maine's athletic landscape. The state also enjoys vibrant high school and club sports competitions, making sports an integral part of the community.",
        "Sports in Maine are more than just competition; they are a source of pride, a way to celebrate the state's unique spirit, and a bond that brings people together, regardless of the season.",
      ],
    },
  
    {
      name: "Maryland",
      description: [
        "College sports in Maryland thrive with a strong tradition in basketball, lacrosse, and American football. The University of Maryland Terrapins, or simply the Terps, are central to the state's sports culture.",
        "Xfinity Center and Maryland Stadium are the proud arenas for basketball and football, where the Terps play their home games. Fans are fervent and contribute to an electrifying atmosphere during games. Maryland is known for its competitive basketball program, with multiple NCAA tournament appearances.",
        "Lacrosse is another sport in which Maryland excels, with the Terps regularly competing for national titles. This sport holds a special place in the hearts of residents, and intense college rivalries make each season a spectacle.",
        "Sports in Maryland are an expression of community, pride, and tradition, playing a crucial role in bringing people together and celebrating the state's unique culture.",
      ],
    },
  
    {
      name: "Massachusetts",
      description: [
        "In Massachusetts, college sports play a significant role in daily life, with a rich history in basketball, American football, and hockey. The universities here have strong sports programs and passionate fans.",
        "Basketball holds a special place in the hearts of Massachusetts residents, as the game was invented here. The University of Massachusetts Minutemen, known as the UMass Minutemen, are proud representatives in NCAA Division I basketball.",
        "American football and hockey are also popular, with intense local rivalries providing exciting competition. The Harvard-Yale football game, known as 'The Game,' is an annual highlight.",
        "Sports in Massachusetts are about tradition, community, and competition. It brings people together, regardless of their background, and is an essential part of the state's cultural identity.",
      ],
    },

  
      {
        name: "Michigan",
        image: Michigan,
        imageDescription: "Michigan Stadium: the third largest stadium in the world and home to the Michigan Wolverines. It can hold 107,601 fans.",
    
        description: [
          "Michigan has a rich tradition in college sports, especially in American football and basketball. The rivalry between the University of Michigan Wolverines and the Michigan State Spartans is legendary and creates an electric atmosphere throughout the state.",
          "Michigan Stadium, also known as 'The Big House,' is one of the largest stadiums in the world, accommodating over 100,000 fans. The Wolverines have a glorious history, with multiple national titles in football.",
          "Basketball is also hugely popular, with both Michigan and Michigan State regularly competing in the NCAA tournament. The passion and dedication of the fans are unparalleled, and game days are true events in Michigan.",
          "In addition to these major sports, Michigan also has strong programs in ice hockey, athletics, and other sports. Sports in Michigan are more than just a pastime; they are an integral part of the lifestyle and identity of the state.",
        ],
      },
    
      {
        name: "Minnesota",
        description: [
          "College sports in Minnesota have a unique character, with a strong emphasis on ice hockey, American football, and basketball. The University of Minnesota Golden Gophers plays a central role in the state's sports community.",
          "TCF Bank Stadium and Williams Arena are bustling centers of activity during football and basketball seasons, while Mariucci Arena is a stronghold for ice hockey. The Golden Gophers are proud representatives in the NCAA, with a rich history and devoted fans.",
          "Ice hockey is particularly popular in Minnesota, often referred to as 'The State of Hockey.' College teams enjoy strong support and provide thrilling action, especially during the famous 'Frozen Four' NCAA tournament.",
          "Sports in Minnesota are deeply intertwined with the culture and identity of the state, offering a source of pride, community spirit, and lively rivalries that bring people of all ages together.",
        ],
      },
    
      {
        name: "Mississippi",
        description: [
          "College sports are an integral part of the culture in Mississippi, with intense passion for American football, basketball, and baseball. The rivalry between the University of Mississippi (Ole Miss) Rebels and the Mississippi State Bulldogs is one of the most intense in the country.",
          "Vaught-Hemingway Stadium in Oxford and Davis Wade Stadium in Starkville are true temples for football fans, where the tension and passion of the game are palpable. The annual Egg Bowl, the clash between Ole Miss and Mississippi State, is a highlight in the sports calendar.",
          "Basketball and baseball are also significant, with both universities building strong teams and traditions. The fans are loyal and vocal, creating an electric atmosphere at games.",
          "Sports in Mississippi are about community, pride, and tradition. It brings people together, creates unforgettable moments, and is an essential part of the state's identity.",
        ],
      },
    
      {
        name: "Missouri",
        description: [
          "College sports in Missouri are synonymous with passion, community, and competition. The state has a rich sports history, with American football, basketball, and baseball taking the lead.",
          "The University of Missouri Tigers, also known as Mizzou, and the Missouri State Bears are two prominent representatives in the college sports landscape. Mizzou's Faurot Field and the Mizzou Arena are epicenters of excitement and team spirit on game days.",
          "The Tigers have a strong presence in the SEC, one of the most competitive conferences in college football. Their basketball team is also a fixture in the NCAA tournament, with fans providing unwavering support.",
          "Sports in Missouri strengthen community bonds and bring people together. It's not just about the game; it's about the experience, the traditions, and the unbreakable bond between the teams and their supporters.",
        ],
      },
    
      {
        name: "Montana",
        description: [
          "In Montana, college sports play a crucial role in community life, with American football, basketball, and rodeo as popular sports. The Montana Grizzlies and the Montana State Bobcats dominate the sports landscape, and their rivalry is intense.",
          "The Grizzlies' Washington-Grizzly Stadium and the Bobcats' Bobcat Stadium are proud football arenas in Montana. Here, fans come together to support their teams, regardless of the weather. The annual Brawl of the Wild game between the Grizzlies and the Bobcats is a must-see event.",
          "Basketball is also popular, with lively games and a strong fan base. Rodeo, unique to Montana, also attracts a lot of attention and appreciation, reflecting the culture and traditions of the state.",
          "College sports in Montana are about community, pride, and a love for the game. They bring people together, create lifelong memories, and contribute to the unique identity of Big Sky Country.",
        ],
      },
    
      {
        name: "Nebraska",
        description: [
          "In Nebraska, college sports are characterized by deep passion and loyalty, with a special focus on American football. The Nebraska Cornhuskers, based at the University of Nebraska-Lincoln, are the heart of the state's sports culture.",
          "Memorial Stadium, home to the Cornhuskers, is also called 'The Sea of Red,' where fans in their distinctive red attire come together to support their team. The stadium is known for its deafening noise and unique atmosphere on game days.",
          "In addition to football, basketball also plays a significant role, with both men's and women's teams competing at a high level. The fans are just as dedicated and create a vibrant atmosphere at every game.",
          "College sports in Nebraska go beyond just the sport; it's a way to bring communities together, celebrate traditions, and create a sense of pride and togetherness in 'The Cornhusker State.'",
        ],
      },
    
      {
        name: "Nevada",
        description: [
          "College sports in Nevada offer a unique blend of entertainment and competition, with special attention to basketball and American football. The University of Nevada, Las Vegas (UNLV), and the University of Nevada, Reno are the two main institutions in sports.",
          "UNLV's basketball team, the Runnin' Rebels, has a rich history and is known nationwide. Their home games are a true attraction, complete with energetic performances and an enthusiastic audience. The Nevada Wolf Pack's football team also contributes to the sports culture in the state, with exciting games and a loyal fan base.",
          "In Nevada, there is also a vibrant interest in other college sports such as baseball, softball, and soccer. These sports attract local communities and provide ample opportunities for athletes to develop.",
          "Overall, college sports in Nevada are an essential part of the state, providing not only entertainment but also bringing communities together and enriching the local culture.",
        ],
      },
    
      {
        name: "New Hampshire",
        description: [
          "In the picturesque state of New Hampshire, college sports play a crucial role in community life. Although the state is smaller than many others, the dedication to sports is immense.",
          "The University of New Hampshire (UNH) dominates the sports landscape, especially in hockey, where the Wildcats consistently perform at a high level. The Whittemore Center Arena, the home stadium of the hockey team, is known for its vibrant atmosphere and passionate fans.",
          "Football and basketball are also popular sports within UNH, attracting large crowds during the season. The community takes pride in their teams, and students are closely involved in sports events.",
          "New Hampshire's approach to college sports is community-focused, with the aim of bringing people together, celebrating successes, and creating unforgettable moments in the state's picturesque surroundings.",
        ],
      },
    
      {
        name: "New Jersey",
        description: [
          "College sports in New Jersey enjoy a vibrant and competitive atmosphere, with various universities competing at a high level. The state hosts some leading programs that are recognized both regionally and nationally.",
          "One of the most prominent university teams is the Rutgers Scarlet Knights, part of the prestigious Big Ten Conference. Their football and basketball games draw large crowds and generate excitement throughout the state.",
          "New Jersey is also known for its strong lacrosse and soccer programs, attracting and developing young talent. These sports play a significant role in the state's college sports culture, with exciting competitions and dedicated fans.",
          "The community in New Jersey embraces its college sports teams, resulting in a supportive and passionate atmosphere at every event. College sports in New Jersey are more than just games; they are an essential part of the state's cultural fabric.",
        ],
      },
    
    

      {
        "name": "New Mexico",
        "description": [
            "New Mexico, with its unique cultural and geographical background, has a special relationship with college sports. The state is home to some prominent university sports programs that compete at the national level.",
            "The University of New Mexico Lobos in Albuquerque is a central point of pride. Their basketball team regularly draws large crowds to The Pit, one of the most intimidating arenas in the country.",
            "The New Mexico State Aggies from Las Cruces are also national contenders, especially in basketball and football, where they have fierce rivalries with the Lobos, culminating in the Rio Grande Rivalry.",
            "Supported by a rich history and a passionate fanbase, college sports in New Mexico not only provide entertainment but also promote community spirit and state identity. In this desert state, sport is a celebration of talent, perseverance, and community bonds."
        ],
    },
    {
        "name": "New York",
        "description": [
            "In the bustling state of New York, college sports play a dynamic and versatile role. The state is home to numerous universities, each with its own unique sports traditions.",
            "Syracuse University stands out with its basketball team, the Orange, which often competes at the highest national level. The Carrier Dome stadium, known for its impressive capacity and lively atmosphere, is an icon in the college sports world.",
            "Additionally, smaller universities and colleges also play a significant role, with strong programs in sports such as lacrosse, soccer, and baseball. These smaller programs nurture the competitive spirit in the state, creating a rich and diverse sports landscape.",
            "Community involvement in college sports in New York is immense, with fans supporting their teams through thick and thin. From bustling cities to quieter rural areas, sport unites New Yorkers and instills a sense of pride and togetherness."
        ],
    },
    {
        "name": "North Carolina",
        image: North_Carolina,
        imageDescription: "The iconic rivalry: DUKE vs. UNC in action",
        "description": [
            "North Carolina has a rich tradition and unparalleled passion when it comes to college sports, particularly in basketball. The rivalry between the University of North Carolina Tar Heels and the Duke University Blue Devils is world-famous and guarantees exciting showdowns.",
            "The Tar Heels and Blue Devils not only dominate the basketball season but also bring a wave of excitement and community spirit throughout the state. It's a sports culture deeply embedded in North Carolina's identity.",
            "In addition to basketball, American football also plays a prominent role, with strong teams and passionate fanbases. And in the spring, the love for baseball blooms, with both university and college teams competing on the fields.",
            "College sports in North Carolina go beyond the sports fields and halls; they are a way of life, a source of pride, and a means for communities to come together and celebrate their state."
        ],
    },

    {
      "name": "North Dakota",
      "description": [
          "College sports in North Dakota offer a unique experience, with communities closely involved with their university teams. The sense of pride and togetherness is clearly visible during sporting events, despite the state having fewer prominent universities than other American regions.",
          "The American football team of North Dakota State University, the Bison, is known for its successes in the NCAA Division I Football Championship Subdivision, which gives a significant boost to the state's sports culture. Fans gather at the Fargodome to support their team, creating an intense atmosphere.",
          "Ice hockey also plays a major role in North Dakota, with the University of North Dakota Fighting Hawks as a leading team. The passion for this winter sport is evident during the season, with fans flocking to the arena.",
          "College sports in North Dakota, especially American football and ice hockey, bring communities together, create strong local pride, and contribute to the vibrant sports culture of the state."
      ],
  },
  {
      "name": "Ohio",
      "description": [
          "College sports are an integral part of the culture in Ohio, with a special emphasis on American football and basketball. The Ohio State University (OSU) and the University of Cincinnati are two leading institutions in sports.",
          "OSU's football team, the Buckeyes, enjoys national fame and is a true force in the NCAA. Their games at the massive Ohio Stadium attract more than 100,000 fans, creating an unparalleled atmosphere during home games. 'O-H-I-O' is a chant known throughout the state.",
          "Basketball is also prominently featured, with both men's and women's teams competing at a high level. The state of Ohio is home to multiple university teams that regularly participate in the NCAA tournament, adding to the basketball frenzy.",
          "College sports in Ohio offer more than just competition; they are a source of pride, tradition, and community spirit, uniting people from all walks of life."
      ],
  },
  {
      "name": "Oklahoma",
      "description": [
          "In Oklahoma, college sports hold a central place in the hearts of its residents, with a deep-rooted passion for American football. The University of Oklahoma (OU) and Oklahoma State University (OSU) are the jewels of the state's sports world.",
          "OU's football team, the Sooners, has a rich history and numerous successes, creating a vibrant atmosphere around their games. The Gaylord Family Oklahoma Memorial Stadium is a fortress for the team, where fans loudly chant 'Boomer Sooner.'",
          "OSU is not far behind OU, with strong performances in both football and other sports, where their American football team, the Cowboys, plays a significant role. The dedication of fans during the 'Bedlam Series,' the annual battle between OU and OSU, is enormous.",
          "College sports in Oklahoma symbolize the struggle, perseverance, and sense of community of the state, making it much more than just a game."
      ],
  },
  {
      "name": "Oregon",
      "description": [
          "College sports in Oregon are an essential part of the state, with a focus on both American football and athletics. The University of Oregon (UO) in Eugene is central to this sporting passion and has built a national reputation.",
          "UO's football team, the Ducks, is known for their fast-paced play and unique uniforms, while the athletics program is one of the best in the country. The annual Prefontaine Classic athletic event, held at the famous Hayward Field, highlights Oregon's dedication to athletics.",
          "Oregon State University (OSU) with their football team, the Beavers, provides strong competition, especially during the 'Civil War,' the annual clash with the Ducks. This is more than a game; it's a state tradition that brings communities together.",
          "In addition to these sports, basketball also enjoys popularity, and passionate fans make college sports in Oregon an experience you don't want to miss. It's a celebration of athletic talent, community, and the state's unique sports culture."
      ],
  },
  {
      "name": "Pennsylvania",
      "description": [
          "Pennsylvania holds a deep appreciation for college sports, with a strong emphasis on American football, basketball, and athletics. Teams like the Penn State Nittany Lions and the Pittsburgh Panthers dominate the sports landscape and create exciting rivalries.",
          "Beaver Stadium, the home of the Nittany Lions, is known for its overwhelming atmosphere and intense fan experience, especially during 'White Out' games. These moments are the highlight of the sports calendar and attract fans from across the state.",
          "On the basketball court, the Villanova Wildcats have recently achieved national success, with multiple championships that have thrilled the state. Their performances have put Pennsylvania on the map as a basketball powerhouse.",
          "Athletics also play a crucial role, with the prestigious Penn Relays drawing top athletes to Philadelphia every year. This rich sports culture strengthens community spirit and contributes to the pride of Pennsylvania."
      ],
  },
  {
      "name": "Rhode Island",
      "description": [
          "Despite its small size, Rhode Island has a vibrant college sports scene, with basketball and sailing being notably popular. The state has teams that play with heart and soul, creating exciting moments.",
          "Teams like the Rhode Island Rams and the Providence Friars have a loyal fan base and deliver captivating games. The Dunkin' Donuts Center in Providence becomes a bustling arena during basketball games, with locals turning out in droves to support their teams.",
          "In addition to basketball, Rhode Island is known for its outstanding sailing programs, with the beautiful coastline providing perfect conditions for the sport. Universities like Brown and URI have strong sailing teams that compete nationally.",
          "These sports contribute to Rhode Island's vibrant culture, where every game is an opportunity to celebrate the unique spirit of this small but proud state."
      ],
  },

  {
    "name": "South Carolina",
    "description": [
        "College sports in South Carolina breathe tradition and passion, with American football and basketball at the center of attention. The intense rivalry between the University of South Carolina Gamecocks and the Clemson University Tigers dominates the sports landscape.",
        "During the annual football showdown, 'The Palmetto Bowl,' the entire state comes alive, with the line between garnet and orange clearly drawn. The energy and passion of the fans are unparalleled, making every game an unforgettable experience.",
        "Basketball also holds a prominent place in the hearts of sports fans in South Carolina, with teams competing at the top in both men's and women's leagues. For example, the Gamecocks' women's basketball team has achieved national success, further enhancing community pride and involvement.",
        "The sports culture in South Carolina goes beyond the playing fields; it's a way of life, a source of pride, and an indispensable part of the state's identity."
    ],
},
{
    "name": "South Dakota",
    "description": [
        "College sports in South Dakota offer a unique and passionate experience, with an emphasis on basketball, American football, and athletics. The South Dakota State University Jackrabbits and the University of South Dakota Coyotes are prominent players in the sports landscape.",
        "Basketball games, especially during the rival encounters between the Jackrabbits and the Coyotes, attract enthusiastic crowds and create an electrifying atmosphere. The state cheers for both men's and women's teams, with on-field performances a source of pride for local communities.",
        "American football also enjoys great popularity, with exciting games and strong fan engagement. Athletic programs in South Dakota excel, with athletes competing nationally and proudly representing the state.",
        "College sports in South Dakota are more than just games; they are an essential part of the community, strengthening bonds among residents and contributing to the state's vibrant culture."
    ],
},
{
    "name": "Tennessee",
    "description": [
        "In Tennessee, college sports play a crucial role in the community, with a rich tradition in American football, basketball, and athletics. The University of Tennessee Volunteers and the Vanderbilt University Commodores are leading teams in the state's sports landscape.",
        "The Volunteers, with their iconic orange color, draw huge crowds to Neyland Stadium, one of the largest American football stadiums in the country. The fans' passion is unmatched, and 'Rocky Top' resounds loudly during every game.",
        "Basketball is also a major attraction in Tennessee, with both men's and women's teams receiving national recognition. The state has a rich history of producing top athletes and has contributed to the growth of the sport at the national level.",
        "College sports in Tennessee are an essential part of the state's culture and identity, bringing people together, celebrating traditions, and inspiring the next generation of athletes."
    ],
},
{
    "name": "Texas",
    image: Texas,
    imageDescription: "Traditional Rivalry: Texas Longhorns face off against Texas A&M",
    "description": [
        "College sports in Texas are synonymous with size, passion, and rich tradition, with American football taking the lead. Teams like the Texas Longhorns and the Texas A&M Aggies have massive followings, and their games are season highlights.",
        "The Darrell K Royal–Texas Memorial Stadium in Austin and Kyle Field in College Station are sports temples where every home game turns into a spectacular event. The rivalry between various university teams is intense and contributes to the state's unique sports culture.",
        "In addition to football, basketball and baseball are also popular, with strong programs competing nationally. Support for women's sports is steadily growing, with basketball and athletics standing out in particular.",
        "College sports in Texas are more than just a pastime; it's a way of life, a source of pride, and a binding force that brings communities together."
    ],
},
{
    "name": "Utah",
    "description": [
        "In Utah, college sports hold a significant place within the community, with basketball and American football being the most popular sports. The University of Utah Utes and the Brigham Young University Cougars engage in fierce competition in various disciplines.",
        "The annual football duel between the Utes and the Cougars, known as the 'Holy War,' is one of the most anticipated sports events in the state. It brings fans together for a celebration of sportsmanship, rivalry, and state glory.",
        "Basketball also has a rich tradition in Utah, with exciting seasons and memorable moments in both men's and women's leagues. The teams strive for excellence, and fan support is unwavering.",
        "The impact of college sports in Utah goes beyond the playing field. It promotes community spirit, school pride, and significantly contributes to the cultural identity of the state."
    ],
},
{
    "name": "Vermont",
    "description": [
        "In Vermont, where nature predominates, and communities are close-knit, college sports play a unique role in bringing people together. Winter sports such as skiing and snowboarding are prominently featured, given the mountainous terrain and cold climate.",
        "The University of Vermont Catamounts is the centerpiece of the college sports scene, with teams excelling in various sports, including ice hockey and basketball. The Catamounts have a loyal following, and the atmosphere at home games is intense.",
        "Basketball enjoys great popularity, and the Catamounts' games are a must-see for sports enthusiasts. The team has participated in the NCAA tournament multiple times, putting the state on the map in the college basketball world.",
        "College sports in Vermont are more than just a game; they are a way to bring communities together, develop talent, and celebrate the state's unique sports culture."
    ],
},

{
  "name": "Virginia",
  "description": [
      "In Virginia, college sports are an integral part of the local culture, with a strong focus on American football, basketball, and the emerging popularity of soccer. The Virginia Cavaliers and Virginia Tech Hokies are leading university teams that bring passion and rivalry to the field.",
      "American football draws crowds of fans to the stadiums, where the atmosphere on game days is electric. Investment in basketball programs has also paid off, with teams competing nationally and having a dedicated following.",
      "Soccer is gaining ground in Virginia, with both men's and women's teams developing into competitive forces. Universities are investing in facilities and coaching, while local academies produce young talent.",
      "This mix of traditional and emerging sports enriches the college sports scene in Virginia, creates a vibrant sports community, and provides athletes and fans with unforgettable experiences and a sense of pride and unity."
  ],
},
{
  "name": "Washington",
  "description": [
      "College sports in Washington thrive with a blend of tradition and enthusiasm, with American football, basketball, and rowing taking center stage. The University of Washington Huskies and the Washington State University Cougars are pioneers of the state's sports culture.",
      "Husky Stadium in Seattle and Martin Stadium in Pullman buzz with excitement during American football games, where fans from the entire area come together to support their teams. The 'Apple Cup,' the annual showdown between the Huskies and the Cougars, is a highlight of the rivalry.",
      "Basketball also plays a significant role, with both men's and women's teams competing at a high level. The Huskies have a strong legacy in this sport and attract passionate supporters.",
      "Rowing is another notable discipline, with the Huskies winning numerous national championships. College sports in Washington reflect the diversity and passion of the state, where sportsmanship, competition, and camaraderie are highly valued."
  ],
},
{
  "name": "West Virginia",
  "description": [
      "College sports are the beating heart of West Virginia, with a strong emphasis on American football and basketball. The West Virginia University Mountaineers are the proud representatives of the state, and their games bring the community together.",
      "Milan Puskar Stadium in Morgantown comes to life during the Mountaineers' home games, with fans wearing the iconic gold and blue colors and cheering loudly. The 'Backyard Brawl,' a historic rivalry with the University of Pittsburgh, adds extra excitement to the season.",
      "Basketball is another passion, with thrilling games keeping fans on the edge of their seats. The Mountaineers have both men's and women's teams competing at the national level.",
      "College sports in West Virginia are a source of pride and unity, reflecting the strong sense of community and traditions of the state."
  ],
},
{
  "name": "Wisconsin",
  "description": [
      "College sports are deeply rooted in the culture of West Wisconsin, with American football, basketball, and ice hockey as prominent disciplines. The University of Wisconsin Badgers are the driving force behind the sports culture in the state.",
      "Camp Randall Stadium in Madison buzzes with excitement during American football games, with fans gathering to sing 'Jump Around' and cheer for their team. The rivalry with teams like the University of Michigan Wolverines is legendary and leads to intense matches.",
      "Basketball also has a rich tradition, with the Badgers regularly competing for the top spots in NCAA tournaments. Ice hockey holds a special place in the state's heart, with exciting games lighting up the winter evenings.",
      "College sports in West Wisconsin go beyond the playing field; they are a source of pride, camaraderie, and strengthen the bonds between communities in the state."
  ],
},
{
  "name": "Wyoming",
  "description": [
      "College sports in Wyoming have a unique character, with a strong emphasis on outdoor sports such as skiing, snowboarding, and mountaineering. The University of Wyoming Cowboys and Cowgirls are the standard-bearers of this adventurous sports culture.",
      "War Memorial Stadium in Laramie comes to life during American football games, where fans gather to show their support for the Cowboys. The breathtaking mountains serve as a backdrop for these sporting events.",
      "The university also has a strong tradition in skiing and snowboarding, with athletes dominating national and international competitions. Wyoming's vast wilderness serves as a playground for adventurers and outdoor enthusiasts.",
      "College sports in Wyoming celebrate the beauty of nature and the adventurous spirit of the state, where sportsmanship, passion, and love for the outdoors come together."
  ],
},



];

export default states;