/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import React, { useState } from 'react'; // Step 1: Import useState
import ImageModal from '../../../../../../../components/ImageModal'; // Import the modal component

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";


// Images
import Image1 from "assets/images/Sports_Images/Basketbal1.jpeg";
import Image2 from "assets/images/Sports_Images/Basketbal2.webp";
import Image3 from "assets/images/Sports_Images/Basketbal3.jpeg";
import Image4 from "assets/images/Logos/Sportbeurs.png";

import { Link } from 'react-router-dom';

import "../../../../H1asH3.css"

// Images



const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else {
    console.error(`No element with id ${id} found`);
  }
};

function Beschrijving() {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [, setModalImageUrl1] = useState("");
  
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [, setModalImageUrl2] = useState("");

  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [, setModalImageUrl3] = useState("");
  
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [, setModalImageUrl4] = useState("");
  // Define your article structure with headers and subheaders for navigation
  const articleStructure = [
    { id: 'intro', title: 'Bourse de Basketball aux États-Unis : Comment Étudier et Jouer au Basketball en Amérique', isSubheader: false },
  
    { id: 'Chapitre 1', title: 'Chapitre 1 : Histoire et Développement du Basketball Universitaire', isSubheader: false },
    { id: '1.1', title: '1.1 Premières Années', isSubheader: true },
    { id: '1.2', title: '1.2 Croissance et Professionnalisation', isSubheader: true },
  
    { id: 'Chapitre 2', title: 'Chapitre 2 : Le Basketball Universitaire Aujourd\'hui', isSubheader: false },
    { id: '2.1', title: '2.1 Compétitions et Conférences Importantes', isSubheader: true },
    { id: '2.2', title: '2.2 Programmes de Premier Plan et Équipes Prestigieuses', isSubheader: true },
  
    { id: 'Chapitre 3', title: 'Chapitre 3 : Du Collège à la NBA', isSubheader: false },
    { id: '3.1', title: '3.1 Le Chemin vers la NBA', isSubheader: true },
    { id: '3.2', title: '3.2 Autres Opportunités Professionnelles', isSubheader: true },
  
    { id: 'Chapitre 4', title: 'Chapitre 4 : Influences et Opportunités Internationales', isSubheader: false },
    { id: '4.1', title: '4.1 Joueurs Étrangers dans le Basketball Universitaire', isSubheader: true },
    { id: '4.2', title: '4.2 Joueurs Américains à l\'Étranger', isSubheader: true },
  
    { id: 'Chapter 5', title: 'Chapitre 5 : Bourses Sportives et l\'Avenir des Athlètes', isSubheader: false },
    { id: '5.1', title: '5.1 L\'Importance des Bourses', isSubheader: true },
    { id: '5.2', title: '5.2 Comment Sport Scholarships America Peut Aider', isSubheader: true },
  ];
  
  

  const [showNav, setShowNav] = useState(true);

  // Step 3: Event handler to toggle visibility
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const openModal1 = (imageUrl) => {
    setModalImageUrl1(imageUrl);
    setIsModalOpen1(true);
  };
  
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  
  const openModal2 = (imageUrl) => {
    setModalImageUrl2(imageUrl);
    setIsModalOpen2(true);
  };
  
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const openModal3 = (imageUrl) => {
    setModalImageUrl3(imageUrl);
    setIsModalOpen3(true);
  };
  
  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const openModal4 = (imageUrl) => {
    setModalImageUrl4(imageUrl);
    setIsModalOpen4(true);
  };
  
  const closeModal4 = () => {
    setIsModalOpen4(false);
  };





  
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <MKBox position="sticky" top="20px" display="flex" flexDirection="column">
              
              {/* Conditional rendering of navigation header and buttons */}
              {showNav && (
                <>
                  {/* Navigation Header */}
                  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                    <MKTypography variant="h5">
                      Sommaire
                    </MKTypography>
                    <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                      <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                      <span style={{ color: "blue" }}>Cacher</span>
                      <span style={{ fontWeight: "bold" }}>{" ]"}</span>
                    </MKTypography>
                  </MKBox>
                  
                  {/* Navigation buttons */}
                  {articleStructure.map((section, index) => (
                    <MKButton
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  color="secondary"
                  variant="gradient"
                  sx={{ 
                    justifyContent: "flex-start",
                    pl: section.isSubheader ? 4 : 2,
                    textTransform: "none",
                    fontWeight: section.isSubheader ? "normal" : "bold",
                    borderTopLeftRadius: index === 0 ? '20px' : '0',
                    borderTopRightRadius: index === 0 ? '20px' : '0',
                    borderBottomLeftRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    borderBottomRightRadius: index === articleStructure.length - 1 ? '20px' : '0',
                    textAlign: "left",
                    width: '100%',
                  }}
                >
                    {section.title}
                  </MKButton>
                ))}
              </>
            )}
            
            {/* Toggle text when nav is hidden */}
            {!showNav && (
              <MKTypography variant="body2" style={{ cursor: "pointer" }} onClick={toggleNav}>
                <span style={{ fontWeight: "bold" }}>{"[ "}</span>
                <span style={{ color: "blue" }}>Afficher</span>
                <span style={{ fontWeight: "bold" }}>{" ]"}</span>
              </MKTypography>
            )}
          </MKBox>
          </Grid>
          <Grid item xs={12} md={9}>























          <MKBox id="intro" mb={3}>
  <MKTypography variant="h1" className="h1-as-h3" mb={2}>
    Bourse de Basketball aux États-Unis : Comment Étudier et Jouer au Basketball en Amérique
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
      <img
        src={Image1}
        alt="Stade vibrant de l'Université de l'Arizona lors d'un match de basketball universitaire"
        style={{
          maxWidth: "100%",
          border: "2px solid #fff",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          outline: "none",
        }}
        onClick={() => openModal1(Image1)}
      />
      <figcaption style={{ 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '12px', 
        wordWrap: 'break-word', 
      }}>
        Jour de match au stade de l'Université de l'Arizona
      </figcaption>
    </figure>
    Le basketball est l'un des sports les plus populaires aux États-Unis. Des millions de personnes suivent les matchs de la NBA, la ligue professionnelle de basketball, et de la NCAA, la ligue de basketball universitaire. Le basketball n'est pas seulement un jeu excitant à regarder, mais aussi un excellent moyen de se développer en tant que joueur et en tant que personne.
    <br/>
    <br/>
    Si vous êtes un jeune joueur de basketball rêvant d'une carrière dans la NBA, ou simplement envie de jouer au basketball à un niveau élevé, alors une bourse de basketball en Amérique pourrait être pour vous. Une bourse de basketball est un soutien financier que vous recevez d'une université américaine pour y étudier et y jouer au basketball. Avec une bourse de basketball, vous pouvez non seulement obtenir votre diplôme académique, mais aussi améliorer vos talents en basketball sous une direction professionnelle.
    <br/>
    <br/>
    Mais comment obtenir une bourse de basketball en Amérique ? Quels sont les avantages et les défis de jouer au basketball universitaire ? Et quelles sont les possibilités après votre carrière universitaire ? Dans cet article, nous répondrons à toutes ces questions et vous dirons tout ce que vous devez savoir sur les bourses de basketball en Amérique. Nous discuterons également du rôle de Sportbeursamerika.nl, une organisation spécialisée dans l'aide aux jeunes joueurs à réaliser leur rêve d'une bourse de basketball.
    <br/>
    <br/>
    Vous voulez en savoir plus sur les bourses de basketball en Amérique ? Lisez la suite !
  </MKTypography>
  {/* Add bulleted list or other content as needed */}
</MKBox>
{/* Main chapters and subchapters with placeholder text */}
<MKBox id="Chapitre 1" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 1 : Histoire et Développement du Basketball Universitaire
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le basketball est un sport relativement jeune, inventé en 1891 par James Naismith, un enseignant d'éducation physique canadien. Il a conçu le jeu comme une activité en salle pour ses élèves pendant les mois d'hiver. Le jeu est rapidement devenu populaire et s'est répandu dans diverses écoles et universités en Amérique du Nord.
  </MKTypography>

  <MKBox id="1.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.1 Premières Années
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Le premier match de basketball de niveau universitaire officiel a eu lieu en 1895 entre l'Université de Chicago et l'Université de l'Iowa. En 1901, la première conférence interuniversitaire a été établie, la Western Conference, plus tard connue sous le nom de Big Ten Conference. En 1906, le premier championnat national a été organisé par l'Union athlétique amateur (AAU), qui dominait le basketball universitaire jusqu'en 1936.
      <br/>
      <br/>
      En 1939, le premier tournoi de la NCAA a eu lieu, avec huit équipes en compétition pour le titre. Le tournoi a été remporté par l'Oregon, qui a battu Ohio State 46-33. Le tournoi est devenu un événement annuel qui attirait de plus en plus l'attention des fans et des médias.
    </MKTypography>
  </MKBox>
  <MKBox id="1.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      1.2 Croissance et Professionnalisation
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Après la Seconde Guerre mondiale, le basketball universitaire a augmenté en popularité et en qualité. De nouvelles stars ont émergé, telles que Bill Russell, Wilt Chamberlain, Oscar Robertson et Kareem Abdul-Jabbar, qui se sont ensuite également fait un nom dans la NBA. De nouvelles compétitions et conférences ont été formées, telles que le National Invitation Tournament (NIT), l'Atlantic Coast Conference (ACC) et la Southeastern Conference (SEC).
      <br/>
      <br/>
      En 1957, l'un des matchs les plus mémorables de l'histoire du basketball universitaire a eu lieu lorsque les North Carolina Tar Heels ont battu les Kansas Jayhawks 54-53 après trois prolongations. Le match est considéré comme un tournant dans le basketball universitaire, montrant à quel point le jeu pouvait être excitant et dramatique.
      <br/>
      <br/>
      Un autre match historique a eu lieu en 1966 lorsque les Texas Western Miners ont battu les Kentucky Wildcats 72-65. Le match était révolutionnaire car Texas Western était la première équipe à être entièrement composée de joueurs noirs, tandis que Kentucky était entièrement blanche. Le match est considéré comme un symbole de la lutte pour les droits civiques et l'intégration en Amérique.
      <br/>
      <br/>
      En 1979, le premier tournoi de la NCAA diffusé en direct a eu lieu, avec un nombre record de téléspectateurs. La finale opposait les Michigan State Spartans aux Indiana State Sycamores, dirigés respectivement par les futures légendes de la NBA Magic Johnson et Larry Bird. Michigan State a remporté le match 75-64 et est considéré comme l'une des meilleures finales de tous les temps.
      <br/>
      <br/>
      Dans les années 80 et 90, le basketball universitaire a continué à croître et à évoluer, avec de nouvelles règles, styles et stratégies. De nouvelles rivalités ont émergé, telles que Duke contre North Carolina, Georgetown contre Syracuse et UCLA contre Arizona. Il y avait aussi de nouvelles stars, comme Michael Jordan, Patrick Ewing, Shaquille O'Neal et Tim Duncan, qui ont élevé le basketball universitaire à un niveau supérieur.
    </MKTypography>
  </MKBox>
</MKBox>














                                              





                                  







<MKBox id="Chapitre 2" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 2 : Le Basketball Universitaire Aujourd'hui
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le basketball universitaire est l'un des sports les plus populaires et prestigieux en Amérique aujourd'hui. Chaque année, les matchs attirent des millions de fans dans les stades et devant les écrans de télévision. Le basketball universitaire est également une source majeure de talent pour la NBA, recrutant des centaines de joueurs chaque année dans les équipes universitaires.
  </MKTypography>

  <MKBox id="2.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.1 Compétitions et Conférences Importantes
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '300px' }}>
        <img
          src={Image2}
          alt="Steph Curry en action pour Davidson College, excellent dans le basketball universitaire."
          style={{
            maxWidth: "100%",
            border: "2px solid #fff",
            boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
          }}
          onClick={() => openModal2(Image2)}
        />
        <figcaption style={{ 
          textAlign: 'left', 
          fontWeight: 'bold', 
          fontSize: '12px', 
          wordWrap: 'break-word', 
        }}>
          Stephen Curry en action en tant que joueur pour Davidson College
        </figcaption>
      </figure>
      Le basketball universitaire est principalement organisé par la National Collegiate Athletic Association (NCAA), qui supervise plus de 1 000 universités et collèges. La NCAA divise les équipes en trois divisions, la Division I étant la plus élevée. La Division I se compose de 32 conférences, chacune regroupant des équipes qui sont géographiquement ou historiquement liées.
      <br/>
      <br/>
      Les conférences sont importantes car elles déterminent quelles équipes s'affrontent lors de la saison régulière, qui se déroule de novembre à mars. Chaque conférence a son propre calendrier, ses propres règles et son propre championnat. Les champions de conférence gagnent automatiquement une place dans le tournoi de la NCAA, le point culminant du basketball universitaire.
      <br/>
      <br/>
      Le tournoi de la NCAA, également connu sous le nom de March Madness, est un tournoi à élimination directe organisé chaque année en mars et avril. Il se compose de 68 équipes, dont 32 sont des champions de conférence, et 36 sont des invitations à large spectre, sélectionnées par un comité en fonction de leurs performances pendant la saison. Les équipes sont réparties en quatre régions et classées de 1 à 16.
      <br/>
      <br/>
      Le tournoi commence par quatre matchs de qualification, où les équipes les moins bien classées s'affrontent pour les quatre dernières places dans le tournoi principal. Le tournoi principal se compose de six tours, éliminant une équipe à chaque tour jusqu'à ce qu'il n'en reste plus qu'une. Les quatre dernières équipes, appelées le Final Four, jouent dans un lieu neutre pour déterminer le champion national.
      <br/>
      <br/>
      Le tournoi de la NCAA est l'un des événements sportifs les plus excitants et imprévisibles au monde. Chaque année, il y a des surprises, des drames et des sensations, connus sous le nom de coups de théâtre, de paniers de la dernière seconde et d'histoires de Cendrillon. Le tournoi attire des millions de téléspectateurs et de parieurs, qui tentent de prédire le vainqueur.
      <br/>
      <br/>
      Outre le tournoi de la NCAA, il existe d'autres compétitions importantes dans le basketball universitaire, telles que le NIT, le CBI, le CIT et le tournoi de la NAIA. Ces compétitions offrent des opportunités aux équipes qui ne sont pas qualifiées pour le tournoi de la NCAA ou qui souhaitent acquérir une expérience supplémentaire.
    </MKTypography>
  </MKBox>
  <MKBox id="2.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      2.2 Programmes de Premier Plan et Équipes Prestigieuses
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Le basketball universitaire compte de nombreux programmes de premier plan et équipes prestigieuses, distingués par leur histoire, leur tradition, leur succès et leur influence. Certains de ces programmes sont :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          UCLA Bruins : Le programme le plus réussi de l'histoire du basketball universitaire, avec 11 titres nationaux, dont 10 sous la direction de l'entraîneur John Wooden dans les années 60 et 70. UCLA a produit de nombreux joueurs célèbres, tels que Kareem Abdul-Jabbar, Bill Walton et Reggie Miller.
        </li>
        <li>
          Kentucky Wildcats : Le deuxième programme le plus réussi de l'histoire du basketball universitaire, avec huit titres nationaux et 17 apparitions en Final Four. Kentucky est connu pour ses fans fervents et sa capacité à attirer les meilleurs recrues.
        </li>
        <li>
          North Carolina Tar Heels : Le troisième programme le plus réussi de l'histoire du basketball universitaire, avec six titres nationaux et 20 apparitions en Final Four. North Carolina a eu de nombreux entraîneurs légendaires, tels que Dean Smith et Roy Williams, et des joueurs comme Michael Jordan, James Worthy et Vince Carter.
        </li>
        <li>
          Duke Blue Devils : L'un des programmes les plus dominants dans le basketball universitaire moderne, avec cinq titres nationaux et 16 apparitions en Final Four depuis 1986. Duke est connu pour son entraîneur Mike Krzyzewski, l'entraîneur le plus victorieux de l'histoire du basketball universitaire, et des joueurs comme Christian Laettner, Grant Hill et Zion Williamson.
        </li>
        <li>
          Kansas Jayhawks : L'un des programmes les plus traditionnels et influents dans le basketball universitaire, avec trois titres nationaux et 15 apparitions en Final Four. Kansas est le berceau du jeu, car James Naismith en était le premier entraîneur. Kansas a également eu de nombreux grands entraîneurs, comme Phog Allen, Larry Brown et Bill Self, et des joueurs, tels que Wilt Chamberlain, Danny Manning et Paul Pierce.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ces programmes ne sont que quelques exemples parmi les nombreuses équipes excellentes qui enrichissent le basketball universitaire. Chaque année, de nouveaux challengers et surprises rendent le jeu excitant et imprévisible.
    </MKTypography>
  </MKBox>
</MKBox>










                                              






                                  





<MKBox id="Chapitre 3" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 3 : Du Collège à la NBA
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le basketball universitaire n'est pas seulement un sport mais aussi une éducation. De nombreux joueurs qui participent au basketball universitaire visent à atteindre la NBA, la meilleure et la plus prestigieuse ligue de basketball professionnelle au monde. Cependant, la NBA n'est pas facile à atteindre et nécessite beaucoup de talent, de travail acharné et de chance.
  </MKTypography>

  <MKBox id="3.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.1 Le Chemin vers la NBA
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Pour être éligible à la NBA, un joueur doit avoir au moins 19 ans et avoir obtenu son diplôme d'études secondaires au moins un an auparavant. Cela signifie que la plupart des joueurs aspirant à atteindre la NBA vont d'abord à l'université et jouent pendant au moins un an. C'est aussi ce qu'on appelle la règle du one-and-done car de nombreux joueurs entrent dans la draft de la NBA après seulement un an d'université.
      <br />
      <br />
      La draft de la NBA est un événement annuel où les 30 équipes de la NBA sélectionnent à tour de rôle un joueur parmi un groupe de joueurs disponibles. La draft se compose de deux tours, chaque équipe ayant un choix par tour. L'ordre des choix est déterminé par un tirage au sort pour les équipes qui n'ont pas participé aux séries éliminatoires la saison précédente et par un classement inversé pour les équipes des séries éliminatoires.
      <br />
      <br />
      La draft est un moment important pour les joueurs car elle détermine quelle équipe détient leurs droits et combien d'argent ils peuvent gagner. Les joueurs choisis au premier tour reçoivent un contrat garanti d'au moins deux ans, tandis que ceux sélectionnés au deuxième tour n'ont aucune garantie. Les joueurs qui ne sont pas choisis peuvent essayer de décrocher un contrat via l'agence libre ou en rejoignant la G League, la ligue de développement de la NBA.
      <br />
      <br />
      Cependant, la draft n'est pas une garantie de succès dans la NBA. Beaucoup de joueurs choisis très tôt ne répondent pas aux attentes ou subissent des blessures. D'un autre côté, il y a aussi beaucoup de joueurs qui sont choisis tardivement ou pas du tout mais excellent néanmoins dans la NBA. Un exemple bien connu est Giannis Antetokounmpo, qui a été sélectionné en 15e position au total en 2013 et a été nommé MVP et champion de la NBA en 2021.
    </MKTypography>
  </MKBox>
  <MKBox id="3.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      3.2 Autres Opportunités Professionnelles
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Tous les joueurs qui participent au basketball universitaire ne visent pas à aller en NBA. Certains joueurs choisissent de terminer leurs études académiques et de poursuivre d'autres carrières. D'autres optent pour jouer au basketball professionnel dans d'autres pays ou ligues.
      <br />
      <br />
      Il existe de nombreuses ligues de basketball professionnelles dans le monde, offrant différents niveaux de qualité, de salaire et de style de vie. Certaines de ces ligues comprennent :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          EuroLeague : La compétition de clubs européens la plus élevée et la plus prestigieuse, comprenant 18 équipes de 10 pays. L'EuroLeague est considérée comme la deuxième meilleure ligue au monde après la NBA.
        </li>
        <li>
          Chinese Basketball Association (CBA) : La compétition de clubs chinois la plus élevée et la plus populaire, avec 20 équipes venues de toute la Chine. La CBA est connue pour ses salaires élevés et ses grands audiences.
        </li>
        <li>
          Australian National Basketball League (NBL) : La compétition de clubs australiens la plus élevée et la plus compétitive, avec neuf équipes d'Australie et de Nouvelle-Zélande. La NBL est connue pour son jeu rapide et physique.
        </li>
        <li>
          Basketball Africa League (BAL) : La compétition de clubs africains la plus récente et la plus ambitieuse, comprenant 12 équipes de 12 pays. La BAL est soutenue par la NBA et la FIBA et vise à développer le basketball sur le continent.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ces ligues ne sont que quelques exemples parmi les nombreuses opportunités disponibles pour les joueurs de basketball professionnels en dehors de la NBA. De nombreux joueurs apprécient de vivre et de jouer dans différentes cultures tout en continuant à poursuivre leur passion pour le jeu.
    </MKTypography>
  </MKBox>
</MKBox>









                                              






                                  







<MKBox id="Chapitre 4" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 4 : Influences et Opportunités Internationales
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Le basketball est un sport mondial qui rassemble des personnes de différentes nationalités, cultures et origines. Le basketball universitaire en est un exemple parfait, car il attire à la fois des joueurs étrangers et américains, offrant des opportunités. Dans ce chapitre, nous explorerons les influences et opportunités internationales dans le basketball universitaire.
  </MKTypography>

  <MKBox id="4.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.1 Joueurs Étrangers dans le Basketball Universitaire
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Le basketball universitaire a toujours accueilli des joueurs étrangers qui ont apporté leur propre style, leurs compétences et leurs perspectives au jeu. Selon la NCAA, lors de la saison 2019-2020, il y avait plus de 1 200 joueurs étrangers en Division I, originaires de 101 pays. Les pays d'origine les plus courants étaient le Canada, l'Australie, le Nigeria, la France et le Royaume-Uni.
      <br />
      <br />
      Les joueurs étrangers choisissent le basketball universitaire pour diverses raisons. Certains veulent combiner leur éducation académique avec leurs ambitions sportives. D'autres visent à s'adapter à la culture et au mode de vie américains. D'autres encore cherchent à améliorer leurs chances d'atteindre la NBA ou de représenter leurs équipes nationales.
      <br />
      <br />
      Les joueurs étrangers ont eu un impact significatif sur le basketball universitaire, tant individuellement que collectivement. Certains des joueurs étrangers les plus réussis et influents de l'histoire du basketball universitaire incluent :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Hakeem Olajuwon : Un joueur nigérian qui a joué pour les Houston Cougars de 1981 à 1984. Il a mené son équipe à trois apparitions consécutives en Final Four et a remporté le titre de joueur national de l'année en 1984. Il a été le premier choix absolu de la draft de la NBA en 1984 et est devenu l'un des plus grands pivots de tous les temps.
        </li>
        <li>
          Andrew Bogut : Un joueur australien qui a joué pour les Utah Utes de 2003 à 2005. Il a remporté le titre de joueur national de l'année en 2005 et a mené son équipe jusqu'aux Sweet Sixteen. Il a été le premier choix absolu de la draft de la NBA en 2005 et est devenu un All-Star et champion.
        </li>
        <li>
          Luka Garza : Un joueur américain avec des racines bosniaques qui a joué pour les Iowa Hawkeyes de 2017 à 2021. Il a remporté le titre de joueur national de l'année deux fois, en 2020 et 2021, et est devenu le meilleur marqueur de tous les temps de son université. Il a été sélectionné au 52e rang de la draft de la NBA en 2021 et est considéré comme l'un des plus grands joueurs universitaires de tous les temps.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ces joueurs ne sont que quelques exemples parmi les nombreux joueurs étrangers qui se sont distingués dans le basketball universitaire. Chaque année, de nouveaux talents émergent, enrichissant le jeu.
    </MKTypography>
  </MKBox>
  <MKBox id="4.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      4.2 Joueurs Américains à l'Étranger
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Non seulement les joueurs étrangers viennent en Amérique pour étudier et jouer au basketball, mais les joueurs américains vont également à l'étranger pour les mêmes raisons. Certains joueurs américains choisissent de sauter ou d'écourter leur carrière au lycée ou à l'université pour jouer professionnellement dans d'autres pays ou ligues.
      <br />
      <br />
      Les raisons de ce choix peuvent varier, mais certaines incluent :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Financière : Certains joueurs veulent gagner de l'argent avec leur talent au lieu d'attendre un contrat NBA ou une bourse.
        </li>
        <li>
          Athlétique : Certains joueurs veulent se développer contre des adversaires plus forts au lieu de dominer à leur niveau.
        </li>
        <li>
          Personnel : Certains joueurs veulent voyager, apprendre de nouvelles cultures ou maintenir des liens familiaux.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Les joueurs américains partant à l'étranger ont des expériences et des résultats différents. Certains réussissent à réaliser leur rêve NBA, tandis que d'autres jouissent d'une carrière longue et réussie. Certains s'adaptent bien à leur nouvel environnement, tandis que d'autres ont du mal avec la langue, la nourriture ou le mode de vie.
      <br />
      <br />
      Quelques exemples de joueurs américains partis à l'étranger incluent :
    </MKTypography>
    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>
          Brandon Jennings : Un joueur qui a sauté son lycée pour jouer pour Lottomatica Roma en Italie en 2008. Il a été le 10e choix absolu de la draft de la NBA en 2009 et a joué pendant 10 saisons dans la NBA.
        </li>
        <li>
          Emmanuel Mudiay : Un joueur qui a sauté son université pour jouer pour les Guangdong Southern Tigers en Chine en 2014. Il a été le 7e choix absolu de la draft de la NBA en 2015 et joue actuellement pour le Utah Jazz.
        </li>
        <li>
          LaMelo Ball : Un joueur qui a sauté son lycée et son université pour jouer pour les Illawarra Hawks en Australie en 2019. Il a été le 3e choix absolu de la draft de la NBA en 2020 et a remporté le titre de Rookie de l'année.
        </li>
      </ul>
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Ces joueurs ne sont que quelques exemples parmi les nombreux joueurs américains ayant choisi une voie alternative vers le basketball professionnel. Chaque année, de nouveaux aventuriers internationalisent le jeu.
    </MKTypography>
  </MKBox>
</MKBox>










                                              






                                  

<MKBox id="Chapter 5" mb={3}>
  <MKTypography variant="h2" className="h1-as-h3" mb={2}>
    Chapitre 5 : Bourses Sportives et l'Avenir des Athlètes
  </MKTypography>
  <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
    Les bourses sportives offrent de nombreuses opportunités aux jeunes athlètes de montrer leur talent et de développer leurs compétences. L'une des opportunités les plus significatives est d'obtenir une bourse sportive pour étudier et compétitionner au niveau collégial. Dans ce chapitre, nous discuterons de l'importance des bourses sportives et de comment Sport Scholarships America peut vous aider à réaliser votre rêve.
  </MKTypography>

  <MKBox id="5.1" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.1 L'Importance des Bourses
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Une bourse sportive est un soutien financier fourni par une université à un étudiant-athlète pour combiner études et sport. Elle peut couvrir les frais de scolarité, les livres, le logement, les repas et autres dépenses. De plus, elle peut offrir des avantages tels que du tutorat académique, des soins médicaux, des installations d'entraînement et du matériel.
      <br />
      <br />
      Obtenir une bourse sportive est compétitif, avec de nombreux facteurs qui influent sur le processus d'attribution, notamment les performances académiques, sportives, la personnalité, la motivation, le leadership et l'adaptabilité. Il est donc important de bien se préparer et de se démarquer de la concurrence.
    </MKTypography>
  </MKBox>

  <MKBox id="5.2" mb={2}>
    <MKTypography variant="h4" mb={1}>
      5.2 Comment Sport Scholarships America Peut Aider
    </MKTypography>
    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      <figure style={{ float: 'right', margin: '10px', maxWidth: '310px' }}>
        <img
          src={Image4}
          alt="Logo de Sport Scholarships America, représentant du matériel sportif et une casquette de graduation, symbolisant le focus sur les bourses sportives pour les étudiants-athlètes."
          style={{
            maxWidth: "100%",
            height: "auto",
            border: "2px solid #fff",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
            cursor: "pointer",
            outline: "none",
            borderRadius: "50%",
            marginTop: "0px",
          }}
          onClick={() => openModal4(Image4)}
        />
        <figcaption style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '12px', wordWrap: 'break-word' }}>
          Logo de Sport Scholarships America
        </figcaption>
      </figure>
      Sport Scholarships America est une organisation spécialisée dans l'orientation des jeunes talents sportifs vers des bourses sportives. Avec des experts passionnés et expérimentés, Sport Scholarships America soutient les étudiants-athlètes à chaque étape du parcours, de l'orientation initiale à la placement final. Leurs services comprennent :
    </MKTypography>

    <MKTypography variant="body1" component="div" fontWeight="light" color="text" mb={2}>
      <ul style={{ paddingLeft: '40px', listStyleType: 'disc', color: 'inherit' }}>
        <li>Un entretien personnel pour discuter des souhaits, des possibilités et des attentes de l'étudiant-athlète.</li>
        <li>Une évaluation professionnelle du niveau académique et sportif de l'étudiant-athlète.</li>
        <li>Un vaste réseau de contacts avec des entraîneurs, des universités et des collèges.</li>
        <li>Promotion efficace du profil et des vidéos de l'étudiant-athlète auprès des entraîneurs potentiels.</li>
        <li>Conseils d'experts sur les meilleures options en fonction des préférences, des objectifs et du budget de l'étudiant-athlète.</li>
        <li>Négociation des meilleures offres de bourses au nom de l'étudiant-athlète.</li>
        <li>Aide à la demande de documents nécessaires, tels que visa, relevé de notes, diplôme et tests d'admission.</li>
        <li>Préparation à la vie d'étudiant-athlète pendant sa bourse, y compris culture, langue, règles et conseils.</li>
        <li>Suivi et accompagnement pendant la période de la bourse.</li>
      </ul>
    </MKTypography>

    <MKTypography variant="body1" fontWeight="light" color="text" mb={2}>
      Vous voulez explorer les opportunités de bourses et atteindre vos objectifs sportifs et académiques ? <Link to="/fr/Services-Produits">Consultez la gamme complète</Link> des services offerts par Sport Scholarships America et planifiez une consultation dès aujourd'hui.
    </MKTypography>
  </MKBox>
</MKBox>


          










        

         

              <ImageModal open={isModalOpen1} onClose={closeModal1} imageSrc={Image1} />
              <ImageModal open={isModalOpen2} onClose={closeModal2} imageSrc={Image2} />
              <ImageModal open={isModalOpen3} onClose={closeModal3} imageSrc={Image3} />
              <ImageModal open={isModalOpen4} onClose={closeModal4} imageSrc={Image4} />

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Beschrijving;