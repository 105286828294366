/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Produktssida: https://www.creative-tim.com/product/material-kit-pro-react
* Upphovsrätt 2023 Creative Tim (https://www.creative-tim.com)

Kodat av www.creative-tim.com

 =========================================================

* Ovanstående upphovsrättsmeddelande och denna tillståndsanmärkning ska inkluderas i alla kopior eller betydande delar av programvaran.
*/

// Komponenter för material i MUI
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React-komponenter
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Exempel från Material Kit 2 PRO React
import RaisedBlogCard from "examples/Cards/BlogCards/RaisedBlogCard";

// Bilder
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import NCAA from "assets/images/Logos/NCAA_Transparent.png";
import NJCAA from "assets/images/Logos/NJCAA_Transparent.png";
import NAIA from "assets/images/Logos/NAIA_Transparent.png";

function BlogPostThree() {
  const post1 = NCAA;
  const post2 = NJCAA;
  const post3 = NAIA;

  return (
    <>
      <MKBox
        position="relative"
        variant="gradient"
        bgColor="dark"
        mt={{ xs: 0, lg: 8 }}
        mx={-2}
      >
        <MKBox
          component="img"
          src={bgPattern}
          alt="bakgrundsmönster"
          position="absolute"
          top={0}
          left={0}
          width={{ xs: "100%", lg: "100%" }}
          height={{ xs: "auto", lg: "auto" }}
          opacity={0.6}
        />
        <Container>
          <Grid
            container
            justifyContent="center"
            sx={{ pt: 6, pb: 18 }}
          >
            <Grid item xs={12} md={7} sx={{ textAlign: "center" }}>
              <MKTypography variant="h2" color="white" mb={1}>
                Möjligheter på alla nivåer
              </MKTypography>
              <MKTypography variant="body2" color="white">
                Varje sport, varje akademisk nivå: utforska en värld av möjligheter vid otaliga amerikanska universitet för varje student-idrottare
              </MKTypography>
            </Grid>
          </Grid>
        </Container>
      </MKBox>

      <MKBox component="section" mt={-10}>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4} mb={{ xs: 3, lg: 0 }}>
              <RaisedBlogCard
                image={post1}
                category={{ color: "primary", label: "house" }}
                title="NCAA"
                subtitle="National Collegiate Athletic Association"
                description="NCAA hanterar collegesporter i USA och övervakar tävlingar för mer än 1 100 anslutna universitet. Som en ledande organisation spelar den en avgörande roll i den amerikanska collegesporthandlingen."
                action={{
                  type: "internal",
                  route: "/sv/NCAA",
                  color: "info",
                  label: "Mer information",
                }}
                imageStyle={{ marginTop: "-44px" }}
                shadowTop="-30px" // Flytta skuggan nedåt med 50px för att matcha bildens rörelse
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} mb={{ xs: 3, lg: 0 }}>
              <RaisedBlogCard
                image={post2}
                category={{ color: "primary", label: "house" }}
                title="NJCAA"
                subtitle="National Junior College Association"
                description="NJCAA reglerar idrott på community colleges i USA och hanterar tävlingar för hundratals anslutna institutioner. Som en central organisation är den avgörande för collegespel på två år i Amerika, där talang utvecklas för högre nivåer."
                action={{
                  type: "internal",
                  route: "/sv/NJCAA",
                  color: "info",
                  label: "Mer information",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} mb={{ xs: 3, lg: 0 }}>
              <RaisedBlogCard
                image={post3}
                category={{ color: "primary", label: "house" }}
                title="NAIA"
                subtitle="National Association of Intercollegiate Athletics"
                description="NAIA spelar en central roll i hanteringen av idrott vid småskaliga universitet i USA, med hundratals anslutna institutioner. Som en ledande organisation spelar den en avgörande roll i den amerikanska intercollegiate sportslandskapet och främjar talang och integritet."
                action={{
                  type: "internal",
                  route: "/sv/NAIA",
                  color: "info",
                  label: "Mer information",
                }}
                imageStyle={{ marginTop: "50px" }}
                shadowTop="none"
                paddingBelowImage="120px"
              />
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default BlogPostThree;
